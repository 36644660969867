import React from "react";

function TreeFamilies(props: any) {
  const [popUpActive, setPopUpActive] = React.useState<boolean>(false);
  const [popUpFamily, setPopUpFamily] = React.useState<string>('');

  /*
  React.useEffect(() => {
    if(popUpFamily !== '') {
      setPopUpActive(true);
    }
    else {
      setPopUpActive(false);
    }
  }, [popUpFamily])
  */

  return (
    <div>
      <svg height={685} width={800} style={{border: 'solid 0px red'}}>
        <g>
          <rect x={61} y={35} height={455} width={20} stroke="black" strokeWidth={1} fill="#769DCC" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Haplorrhini'); }} />
          <rect x={61} y={494} height={175} width={20} stroke="black" strokeWidth={1} fill="#EF6B6E" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Strepshirrini'); }} />


          <rect x={1} y={35} height={45} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Great Apes'); }} />
          <rect x={1} y={82} height={34} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Gibbons'); }} />
          <rect x={1} y={118} height={167} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Old World Monkeys'); }} />
          <rect x={1} y={287} height={201} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('New World Monkeys'); }} />
          <rect x={1} y={490} height={130} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Lemurs, AyeAye'); }} />
          <rect x={1} y={622} height={47} width={56} stroke="#ABABAB" strokeWidth={1} fill="#EFEFEF" style={{cursor: 'default'}} onClick={() => { setPopUpFamily('Pottos, Lorises, Galagos'); }} />
        </g>
        <g>
          <text x={-190} y={76} fill="white" textAnchor="middle" transform="rotate(-90 45,45)" style={{pointerEvents: 'none'}} >Haplorrhini</text>
          <text x={-490} y={76} fill="white" textAnchor="middle" transform="rotate(-90 45,45)" style={{pointerEvents: 'none'}} >Strepshirrini</text>

          <text x={29} y={55} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Great</text>
          <text x={29} y={70} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Apes</text>

          <text x={29} y={103} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Gibbons</text>

          <text x={29} y={185} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Old</text>
          <text x={29} y={200} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >World</text>
          <text x={29} y={215} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Monkeys</text>

          <text x={29} y={375} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >New</text>
          <text x={29} y={390} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >World</text>
          <text x={29} y={405} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Monkeys</text>

          <text x={29} y={555} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Lemurs,</text>
          <text x={29} y={570} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >AyeAye</text>

          <text x={29} y={636} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Pottos,</text>
          <text x={29} y={649} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Lorises,</text>
          <text x={29} y={663} fontSize={12} fill="black" textAnchor="middle" style={{pointerEvents: 'none'}} >Galagos</text>
        </g>
      </svg>
      <div style={{ zIndex: 2000, width: 1110, height: 585, padding: 50, display: popUpActive ? 'block' : 'none', position: 'absolute', top: 0, left: -7, backgroundColor: "#000000AA"}}>
        <div style={{padding: 30, marginTop: 20, border: 'solid 2px white', borderRadius: 10, backgroundColor: "#EFEFEF"}}>
          <div style={{textAlign: 'center', padding: 10, marginBottom: 20, borderBottom: 'solid 1px #02547A', fontSize: 16, fontWeight: 'bold', position: 'relative'}}>
            {popUpFamily}
            <div style={{padding: 10, borderRadius: 10, position: 'absolute', textAlign: 'center', width: 25, left: '100%', top: -25, marginLeft: -20, cursor: 'pointer', backgroundColor: 'white'}} onClick={() => { setPopUpFamily(''); setPopUpActive(false); }}>
              X
            </div>
          </div>
          <div>
            Info Here.
          </div>
        </div>
      </div>
    </div>
  )
}

export default TreeFamilies;
