// The idea is that this will come from a database
const GeneKeys = [
  { key: "NM_000014.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2", geneDisplay: "A2M" },
  { key: "NM_000015.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10", geneDisplay: "NAT2" },
  { key: "NM_000016.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "34", geneDisplay: "ACADM" },
  { key: "NM_000017.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "35", geneDisplay: "ACADS" },
  { key: "NM_000018.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "37", geneDisplay: "ACADVL" },
  { key: "NM_000019.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "38", geneDisplay: "ACAT1" },
  { key: "NM_000020.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "94", geneDisplay: "ACVRL1" },
  { key: "NM_000021.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5663", geneDisplay: "PSEN1" },
  { key: "NM_000022.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "100", geneDisplay: "ADA" },
  { key: "NM_000023.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6442", geneDisplay: "SGCA" },
  { key: "NM_000024.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "154", geneDisplay: "ADRB2" },
  { key: "NM_000025.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "155", geneDisplay: "ADRB3" },
  { key: "NM_000026.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "158", geneDisplay: "ADSL" },
  { key: "NM_000027.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "175", geneDisplay: "AGA" },
  { key: "NM_000030.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "189", geneDisplay: "AGXT" },
  { key: "NM_000031.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "210", geneDisplay: "ALAD" },
  { key: "NM_000032.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "212", geneDisplay: "ALAS2" },
  { key: "NM_000033.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "215", geneDisplay: "ABCD1" },
  { key: "NM_000035.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "229", geneDisplay: "ALDOB" },
  { key: "NM_000036.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "270", geneDisplay: "AMPD1" },
  { key: "NM_000037.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286", geneDisplay: "ANK1" },
  { key: "NM_000038.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "324", geneDisplay: "APC" },
  { key: "NM_000039.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "335", geneDisplay: "APOA1" },
  { key: "NM_000040.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "345", geneDisplay: "APOC3" },
  { key: "NM_000041.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "348", geneDisplay: "APOE" },
  { key: "NM_000042.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "350", geneDisplay: "APOH" },
  { key: "NM_000043.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "355", geneDisplay: "FAS" },
  { key: "NM_000044.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "367", geneDisplay: "AR" },
  { key: "NM_000045.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "383", geneDisplay: "ARG1" },
  { key: "NM_000046.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "411", geneDisplay: "ARSB" },
  { key: "NM_000047.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "415", geneDisplay: "ARSL" },
  { key: "NM_000048.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "435", geneDisplay: "ASL" },
  { key: "NM_000049.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "443", geneDisplay: "ASPA" },
  { key: "NM_000051.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "472", geneDisplay: "ATM" },
  { key: "NM_000052.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "538", geneDisplay: "ATP7A" },
  { key: "NM_000053.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "540", geneDisplay: "ATP7B" },
  { key: "NM_000054.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "554", geneDisplay: "AVPR2" },
  { key: "NM_000055.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "590", geneDisplay: "BCHE" },
  { key: "NM_000057.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "641", geneDisplay: "BLM" },
  { key: "NM_000059.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "675", geneDisplay: "BRCA2" },
  { key: "NM_000061.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "695", geneDisplay: "BTK" },
  { key: "NM_000062.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "710", geneDisplay: "SERPING1" },
  { key: "NM_000063.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "717", geneDisplay: "C2" },
  { key: "NM_000064.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "718", geneDisplay: "C3" },
  { key: "NM_000065.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "729", geneDisplay: "C6" },
  { key: "NM_000066.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "732", geneDisplay: "C8B" },
  { key: "NM_000067.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "760", geneDisplay: "CA2" },
  { key: "NM_000069.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "779", geneDisplay: "CACNA1S" },
  { key: "NM_000070.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "825", geneDisplay: "CAPN3" },
  { key: "NM_000071.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "875", geneDisplay: "CBS" },
  { key: "NM_000073.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "917", geneDisplay: "CD3G" },
  { key: "NM_000074.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "959", geneDisplay: "CD40LG" },
  { key: "NM_000075.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1019", geneDisplay: "CDK4" },
  { key: "NM_000077.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1029", geneDisplay: "CDKN2A" },
  { key: "NM_000078.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1071", geneDisplay: "CETP" },
  { key: "NM_000079.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1134", geneDisplay: "CHRNA1" },
  { key: "NM_000080.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1145", geneDisplay: "CHRNE" },
  { key: "NM_000081.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1130", geneDisplay: "LYST" },
  { key: "NM_000082.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1161", geneDisplay: "ERCC8" },
  { key: "NM_000083.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1180", geneDisplay: "CLCN1" },
  { key: "NM_000085.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1188", geneDisplay: "CLCNKB" },
  { key: "NM_000088.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1277", geneDisplay: "COL1A1" },
  { key: "NM_000089.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1278", geneDisplay: "COL1A2" },
  { key: "NM_000090.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1281", geneDisplay: "COL3A1" },
  { key: "NM_000091.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1285", geneDisplay: "COL4A3" },
  { key: "NM_000092.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1286", geneDisplay: "COL4A4" },
  { key: "NM_000093.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1289", geneDisplay: "COL5A1" },
  { key: "NM_000094.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1294", geneDisplay: "COL7A1" },
  { key: "NM_000095.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1311", geneDisplay: "COMP" },
  { key: "NM_000096.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1356", geneDisplay: "CP" },
  { key: "NM_000097.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1371", geneDisplay: "CPOX" },
  { key: "NM_000098.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1376", geneDisplay: "CPT2" },
  { key: "NM_000099.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1471", geneDisplay: "CST3" },
  { key: "NM_000100.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1476", geneDisplay: "CSTB" },
  { key: "NM_000101.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1535", geneDisplay: "CYBA" },
  { key: "NM_000102.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1586", geneDisplay: "CYP17A1" },
  { key: "NM_000103.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1588", geneDisplay: "CYP19A1" },
  { key: "NM_000104.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1545", geneDisplay: "CYP1B1" },
  { key: "NM_000106.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1565", geneDisplay: "CYP2D6" },
  { key: "NM_000107.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1643", geneDisplay: "DDB2" },
  { key: "NM_000108.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1738", geneDisplay: "DLD" },
  { key: "NM_000110.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1806", geneDisplay: "DPYD" },
  { key: "NM_000111.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1811", geneDisplay: "SLC26A3" },
  { key: "NM_000112.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1836", geneDisplay: "SLC26A2" },
  { key: "NM_000113.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1861", geneDisplay: "TOR1A" },
  { key: "NM_000116.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6901", geneDisplay: "TAFAZZIN" },
  { key: "NM_000117.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2010", geneDisplay: "EMD" },
  { key: "NM_000121.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2057", geneDisplay: "EPOR" },
  { key: "NM_000122.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2071", geneDisplay: "ERCC3" },
  { key: "NM_000123.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2073", geneDisplay: "ERCC5" },
  { key: "NM_000124.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2074", geneDisplay: "ERCC6" },
  { key: "NM_000125.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2099", geneDisplay: "ESR1" },
  { key: "NM_000126.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2108", geneDisplay: "ETFA" },
  { key: "NM_000127.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2131", geneDisplay: "EXT1" },
  { key: "NM_000128.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2160", geneDisplay: "F11" },
  { key: "NM_000129.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2162", geneDisplay: "F13A1" },
  { key: "NM_000130.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2153", geneDisplay: "F5" },
  { key: "NM_000132.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2157", geneDisplay: "F8" },
  { key: "NM_000133.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2158", geneDisplay: "F9" },
  { key: "NM_000134.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2169", geneDisplay: "FABP2" },
  { key: "NM_000135.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2175", geneDisplay: "FANCA" },
  { key: "NM_000136.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2176", geneDisplay: "FANCC" },
  { key: "NM_000137.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2184", geneDisplay: "FAH" },
  { key: "NM_000138.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2200", geneDisplay: "FBN1" },
  { key: "NM_000139.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2206", geneDisplay: "MS4A2" },
  { key: "NM_000140.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2235", geneDisplay: "FECH" },
  { key: "NM_000141.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2263", geneDisplay: "FGFR2" },
  { key: "NM_000142.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2261", geneDisplay: "FGFR3" },
  { key: "NM_000143.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2271", geneDisplay: "FH" },
  { key: "NM_000144.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2395", geneDisplay: "FXN" },
  { key: "NM_000145.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2492", geneDisplay: "FSHR" },
  { key: "NM_000146.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2512", geneDisplay: "FTL" },
  { key: "NM_000147.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2517", geneDisplay: "FUCA1" },
  { key: "NM_000150.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2528", geneDisplay: "FUT6" },
  { key: "NM_000151.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2538", geneDisplay: "G6PC1" },
  { key: "NM_000152.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2548", geneDisplay: "GAA" },
  { key: "NM_000153.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2581", geneDisplay: "GALC" },
  { key: "NM_000154.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2584", geneDisplay: "GALK1" },
  { key: "NM_000155.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2592", geneDisplay: "GALT" },
  { key: "NM_000156.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2593", geneDisplay: "GAMT" },
  { key: "NM_000157.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2629", geneDisplay: "GBA" },
  { key: "NM_000158.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2632", geneDisplay: "GBE1" },
  { key: "NM_000159.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2639", geneDisplay: "GCDH" },
  { key: "NM_000160.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2642", geneDisplay: "GCGR" },
  { key: "NM_000161.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2643", geneDisplay: "GCH1" },
  { key: "NM_000162.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2645", geneDisplay: "GCK" },
  { key: "NM_000163.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2690", geneDisplay: "GHR" },
  { key: "NM_000164.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2696", geneDisplay: "GIPR" },
  { key: "NM_000165.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2697", geneDisplay: "GJA1" },
  { key: "NM_000166.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2705", geneDisplay: "GJB1" },
  { key: "NM_000168.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2737", geneDisplay: "GLI3" },
  { key: "NM_000169.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2717", geneDisplay: "GLA" },
  { key: "NM_000170.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2731", geneDisplay: "GLDC" },
  { key: "NM_000171.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2741", geneDisplay: "GLRA1" },
  { key: "NM_000173.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2811", geneDisplay: "GP1BA" },
  { key: "NM_000174.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2815", geneDisplay: "GP9" },
  { key: "NM_000175.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2821", geneDisplay: "GPI" },
  { key: "NM_000176.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2908", geneDisplay: "NR3C1" },
  { key: "NM_000178.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2937", geneDisplay: "GSS" },
  { key: "NM_000179.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2956", geneDisplay: "MSH6" },
  { key: "NM_000180.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3000", geneDisplay: "GUCY2D" },
  { key: "NM_000181.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2990", geneDisplay: "GUSB" },
  { key: "NM_000182.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3030", geneDisplay: "HADHA" },
  { key: "NM_000183.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3032", geneDisplay: "HADHB" },
  { key: "NM_000184.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3048", geneDisplay: "HBG2" },
  { key: "NM_000185.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3053", geneDisplay: "SERPIND1" },
  { key: "NM_000186.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3075", geneDisplay: "CFH" },
  { key: "NM_000187.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3081", geneDisplay: "HGD" },
  { key: "NM_000188.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3098", geneDisplay: "HK1" },
  { key: "NM_000189.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3099", geneDisplay: "HK2" },
  { key: "NM_000190.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3145", geneDisplay: "HMBS" },
  { key: "NM_000191.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3155", geneDisplay: "HMGCL" },
  { key: "NM_000193.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6469", geneDisplay: "SHH" },
  { key: "NM_000194.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3251", geneDisplay: "HPRT1" },
  { key: "NM_000195.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3257", geneDisplay: "HPS1" },
  { key: "NM_000196.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3291", geneDisplay: "HSD11B2" },
  { key: "NM_000197.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3293", geneDisplay: "HSD17B3" },
  { key: "NM_000198.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3284", geneDisplay: "HSD3B2" },
  { key: "NM_000199.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6448", geneDisplay: "SGSH" },
  { key: "NM_000200.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3347", geneDisplay: "HTN3" },
  { key: "NM_000201.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3383", geneDisplay: "ICAM1" },
  { key: "NM_000202.8", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3423", geneDisplay: "IDS" },
  { key: "NM_000203.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3425", geneDisplay: "IDUA" },
  { key: "NM_000204.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3426", geneDisplay: "CFI" },
  { key: "NM_000206.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3561", geneDisplay: "IL2RG" },
  { key: "NM_000207.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3630", geneDisplay: "INS" },
  { key: "NM_000208.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3643", geneDisplay: "INSR" },
  { key: "NM_000209.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3651", geneDisplay: "PDX1" },
  { key: "NM_000210.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3655", geneDisplay: "ITGA6" },
  { key: "NM_000211.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3689", geneDisplay: "ITGB2" },
  { key: "NM_000212.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3690", geneDisplay: "ITGB3" },
  { key: "NM_000213.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3691", geneDisplay: "ITGB4" },
  { key: "NM_000214.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "182", geneDisplay: "JAG1" },
  { key: "NM_000215.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3718", geneDisplay: "JAK3" },
  { key: "NM_000216.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3730", geneDisplay: "ANOS1" },
  { key: "NM_000217.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3736", geneDisplay: "KCNA1" },
  { key: "NM_000218.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3784", geneDisplay: "KCNQ1" },
  { key: "NM_000219.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3753", geneDisplay: "KCNE1" },
  { key: "NM_000222.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3815", geneDisplay: "KIT" },
  { key: "NM_000223.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3859", geneDisplay: "KRT12" },
  { key: "NM_000224.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3875", geneDisplay: "KRT18" },
  { key: "NM_000226.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3857", geneDisplay: "KRT9" },
  { key: "NM_000228.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3914", geneDisplay: "LAMB3" },
  { key: "NM_000229.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3931", geneDisplay: "LCAT" },
  { key: "NM_000230.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3952", geneDisplay: "LEP" },
  { key: "NM_000231.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6445", geneDisplay: "SGCG" },
  { key: "NM_000232.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6443", geneDisplay: "SGCB" },
  { key: "NM_000233.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3973", geneDisplay: "LHCGR" },
  { key: "NM_000234.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3978", geneDisplay: "LIG1" },
  { key: "NM_000235.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3988", geneDisplay: "LIPA" },
  { key: "NM_000236.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3990", geneDisplay: "LIPC" },
  { key: "NM_000237.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4023", geneDisplay: "LPL" },
  { key: "NM_000238.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3757", geneDisplay: "KCNH2" },
  { key: "NM_000239.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4069", geneDisplay: "LYZ" },
  { key: "NM_000240.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4128", geneDisplay: "MAOA" },
  { key: "NM_000243.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4210", geneDisplay: "MEFV" },
  { key: "NM_000245.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4233", geneDisplay: "MET" },
  { key: "NM_000246.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4261", geneDisplay: "CIITA" },
  { key: "NM_000249.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4292", geneDisplay: "MLH1" },
  { key: "NM_000250.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4353", geneDisplay: "MPO" },
  { key: "NM_000251.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4436", geneDisplay: "MSH2" },
  { key: "NM_000252.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4534", geneDisplay: "MTM1" },
  { key: "NM_000254.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4548", geneDisplay: "MTR" },
  { key: "NM_000255.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4594", geneDisplay: "MMUT" },
  { key: "NM_000256.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4607", geneDisplay: "MYBPC3" },
  { key: "NM_000257.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4625", geneDisplay: "MYH7" },
  { key: "NM_000258.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4634", geneDisplay: "MYL3" },
  { key: "NM_000260.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4647", geneDisplay: "MYO7A" },
  { key: "NM_000261.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4653", geneDisplay: "MYOC" },
  { key: "NM_000262.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4668", geneDisplay: "NAGA" },
  { key: "NM_000263.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4669", geneDisplay: "NAGLU" },
  { key: "NM_000264.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5727", geneDisplay: "PTCH1" },
  { key: "NM_000265.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "653361", geneDisplay: "NCF1" },
  { key: "NM_000266.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4693", geneDisplay: "NDP" },
  { key: "NM_000268.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4771", geneDisplay: "NF2" },
  { key: "NM_000269.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4830", geneDisplay: "NME1" },
  { key: "NM_000270.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4860", geneDisplay: "PNP" },
  { key: "NM_000271.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4864", geneDisplay: "NPC1" },
  { key: "NM_000273.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4935", geneDisplay: "GPR143" },
  { key: "NM_000274.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4942", geneDisplay: "OAT" },
  { key: "NM_000275.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4948", geneDisplay: "OCA2" },
  { key: "NM_000276.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4952", geneDisplay: "OCRL" },
  { key: "NM_000277.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5053", geneDisplay: "PAH", startMSAPos: 55 },
  { key: "NM_000278.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5076", geneDisplay: "PAX2" },
  { key: "NM_000281.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5092", geneDisplay: "PCBD1" },
  { key: "NM_000282.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5095", geneDisplay: "PCCA" },
  { key: "NM_000283.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5158", geneDisplay: "PDE6B" },
  { key: "NM_000284.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5160", geneDisplay: "PDHA1" },
  { key: "NM_000285.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5184", geneDisplay: "PEPD" },
  { key: "NM_000286.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5193", geneDisplay: "PEX12" },
  { key: "NM_000287.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5190", geneDisplay: "PEX6" },
  { key: "NM_000288.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5191", geneDisplay: "PEX7" },
  { key: "NM_000289.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5213", geneDisplay: "PFKM" },
  { key: "NM_000290.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5224", geneDisplay: "PGAM2" },
  { key: "NM_000291.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5230", geneDisplay: "PGK1" },
  { key: "NM_000292.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5256", geneDisplay: "PHKA2" },
  { key: "NM_000293.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5257", geneDisplay: "PHKB" },
  { key: "NM_000294.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5261", geneDisplay: "PHKG2" },
  { key: "NM_000295.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5265", geneDisplay: "SERPINA1" },
  { key: "NM_000297.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5311", geneDisplay: "PKD2" },
  { key: "NM_000298.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5313", geneDisplay: "PKLR" },
  { key: "NM_000301.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5340", geneDisplay: "PLG" },
  { key: "NM_000302.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5351", geneDisplay: "PLOD1" },
  { key: "NM_000303.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5373", geneDisplay: "PMM2" },
  { key: "NM_000304.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5376", geneDisplay: "PMP22" },
  { key: "NM_000305.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5445", geneDisplay: "PON2" },
  { key: "NM_000306.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5449", geneDisplay: "POU1F1" },
  { key: "NM_000307.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5456", geneDisplay: "POU3F4" },
  { key: "NM_000308.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5476", geneDisplay: "CTSA" },
  { key: "NM_000310.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5538", geneDisplay: "PPT1" },
  { key: "NM_000311.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5621", geneDisplay: "PRNP" },
  { key: "NM_000312.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5624", geneDisplay: "PROC" },
  { key: "NM_000313.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5627", geneDisplay: "PROS1" },
  { key: "NM_000314.8", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5728", geneDisplay: "PTEN" },
  { key: "NM_000315.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5741", geneDisplay: "PTH" },
  { key: "NM_000316.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5745", geneDisplay: "PTH1R" },
  { key: "NM_000317.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5805", geneDisplay: "PTS" },
  { key: "NM_000318.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5828", geneDisplay: "PEX2" },
  { key: "NM_000320.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5860", geneDisplay: "QDPR" },
  { key: "NM_000321.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5925", geneDisplay: "RB1" },
  { key: "NM_000322.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5961", geneDisplay: "PRPH2" },
  { key: "NM_000324.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6005", geneDisplay: "RHAG" },
  { key: "NM_000325.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5308", geneDisplay: "PITX2" },
  { key: "NM_000326.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6017", geneDisplay: "RLBP1" },
  { key: "NM_000327.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6094", geneDisplay: "ROM1" },
  { key: "NM_000328.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6103", geneDisplay: "RPGR" },
  { key: "NM_000329.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6121", geneDisplay: "RPE65" },
  { key: "NM_000330.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6247", geneDisplay: "RS1" },
  { key: "NM_000334.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6329", geneDisplay: "SCN4A" },
  { key: "NM_000335.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6331", geneDisplay: "SCN5A" },
  { key: "NM_000336.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6338", geneDisplay: "SCNN1B" },
  { key: "NM_000337.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6444", geneDisplay: "SGCD" },
  { key: "NM_000338.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6557", geneDisplay: "SLC12A1" },
  { key: "NM_000340.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6514", geneDisplay: "SLC2A2" },
  { key: "NM_000341.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6519", geneDisplay: "SLC3A1" },
  { key: "NM_000342.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6521", geneDisplay: "SLC4A1" },
  { key: "NM_000343.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6523", geneDisplay: "SLC5A1" },
  { key: "NM_000344.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6606", geneDisplay: "SMN1" },
  { key: "NM_000345.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6622", geneDisplay: "SNCA" },
  { key: "NM_000346.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6662", geneDisplay: "SOX9" },
  { key: "NM_000348.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6716", geneDisplay: "SRD5A2" },
  { key: "NM_000349.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6770", geneDisplay: "STAR" },
  { key: "NM_000350.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "24", geneDisplay: "ABCA4" },
  { key: "NM_000352.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6833", geneDisplay: "ABCC8" },
  { key: "NM_000353.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6898", geneDisplay: "TAT" },
  { key: "NM_000354.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6906", geneDisplay: "SERPINA7" },
  { key: "NM_000355.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6948", geneDisplay: "TCN2" },
  { key: "NM_000358.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7045", geneDisplay: "TGFBI" },
  { key: "NM_000359.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7051", geneDisplay: "TGM1" },
  { key: "NM_000360.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7054", geneDisplay: "TH" },
  { key: "NM_000361.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7056", geneDisplay: "THBD" },
  { key: "NM_000362.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7078", geneDisplay: "TIMP3" },
  { key: "NM_000363.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7137", geneDisplay: "TNNI3" },
  { key: "NM_000365.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7167", geneDisplay: "TPI1" },
  { key: "NM_000367.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7172", geneDisplay: "TPMT" },
  { key: "NM_000368.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7248", geneDisplay: "TSC1" },
  { key: "NM_000369.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7253", geneDisplay: "TSHR" },
  { key: "NM_000370.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7274", geneDisplay: "TTPA" },
  { key: "NM_000371.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7276", geneDisplay: "TTR" },
  { key: "NM_000372.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7299", geneDisplay: "TYR" },
  { key: "NM_000373.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7372", geneDisplay: "UMPS" },
  { key: "NM_000374.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7389", geneDisplay: "UROD" },
  { key: "NM_000375.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7390", geneDisplay: "UROS" },
  { key: "NM_000376.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7421", geneDisplay: "VDR" },
  { key: "NM_000377.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7454", geneDisplay: "WAS" },
  { key: "NM_000379.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7498", geneDisplay: "XDH" },
  { key: "NM_000380.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7507", geneDisplay: "XPA" },
  { key: "NM_000381.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4281", geneDisplay: "MID1" },
  { key: "NM_000382.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "224", geneDisplay: "ALDH3A2" },
  { key: "NM_000383.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "326", geneDisplay: "AIRE" },
  { key: "NM_000384.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "338", geneDisplay: "APOB" },
  { key: "NM_000386.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "642", geneDisplay: "BLMH" },
  { key: "NM_000387.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "788", geneDisplay: "SLC25A20" },
  { key: "NM_000388.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "846", geneDisplay: "CASR" },
  { key: "NM_000389.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1026", geneDisplay: "CDKN1A" },
  { key: "NM_000390.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1121", geneDisplay: "CHM" },
  { key: "NM_000391.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1200", geneDisplay: "TPP1" },
  { key: "NM_000392.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1244", geneDisplay: "ABCC2" },
  { key: "NM_000393.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1290", geneDisplay: "COL5A2" },
  { key: "NM_000394.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1409", geneDisplay: "CRYAA" },
  { key: "NM_000395.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1439", geneDisplay: "CSF2RB" },
  { key: "NM_000396.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1513", geneDisplay: "CTSK" },
  { key: "NM_000397.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1536", geneDisplay: "CYBB" },
  { key: "NM_000398.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1727", geneDisplay: "CYB5R3" },
  { key: "NM_000399.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1959", geneDisplay: "EGR2" },
  { key: "NM_000400.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2068", geneDisplay: "ERCC2" },
  { key: "NM_000404.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2720", geneDisplay: "GLB1" },
  { key: "NM_000405.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2760", geneDisplay: "GM2A" },
  { key: "NM_000406.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2798", geneDisplay: "GNRHR" },
  { key: "NM_000407.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2812", geneDisplay: "GP1BB" },
  { key: "NM_000408.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2820", geneDisplay: "GPD2" },
  { key: "NM_000410.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3077", geneDisplay: "HFE" },
  { key: "NM_000412.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3273", geneDisplay: "HRG" },
  { key: "NM_000413.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3292", geneDisplay: "HSD17B1" },
  { key: "NM_000414.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3295", geneDisplay: "HSD17B4" },
  { key: "NM_000415.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3375", geneDisplay: "IAPP" },
  { key: "NM_000416.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3459", geneDisplay: "IFNGR1" },
  { key: "NM_000417.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3559", geneDisplay: "IL2RA" },
  { key: "NM_000418.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3566", geneDisplay: "IL4R" },
  { key: "NM_000419.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3674", geneDisplay: "ITGA2B" },
  { key: "NM_000420.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3792", geneDisplay: "KEL" },
  { key: "NM_000421.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3858", geneDisplay: "KRT10" },
  { key: "NM_000422.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3872", geneDisplay: "KRT17" },
  { key: "NM_000423.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3849", geneDisplay: "KRT2" },
  { key: "NM_000424.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3852", geneDisplay: "KRT5" },
  { key: "NM_000426.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3908", geneDisplay: "LAMA2" },
  { key: "NM_000427.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4014", geneDisplay: "LORICRIN" },
  { key: "NM_000428.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4053", geneDisplay: "LTBP2" },
  { key: "NM_000429.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4143", geneDisplay: "MAT1A" },
  { key: "NM_000430.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5048", geneDisplay: "PAFAH1B1" },
  { key: "NM_000431.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4598", geneDisplay: "MVK" },
  { key: "NM_000432.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4633", geneDisplay: "MYL2" },
  { key: "NM_000433.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4688", geneDisplay: "NCF2" },
  { key: "NM_000434.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4758", geneDisplay: "NEU1" },
  { key: "NM_000435.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4854", geneDisplay: "NOTCH3" },
  { key: "NM_000436.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5019", geneDisplay: "OXCT1" },
  { key: "NM_000437.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5051", geneDisplay: "PAFAH2" },
  { key: "NM_000439.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5122", geneDisplay: "PCSK1" },
  { key: "NM_000440.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5145", geneDisplay: "PDE6A" },
  { key: "NM_000441.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5172", geneDisplay: "SLC26A4" },
  { key: "NM_000442.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5175", geneDisplay: "PECAM1" },
  { key: "NM_000443.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5244", geneDisplay: "ABCB4" },
  { key: "NM_000444.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5251", geneDisplay: "PHEX" },
  { key: "NM_000446.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5444", geneDisplay: "PON1" },
  { key: "NM_000447.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5664", geneDisplay: "PSEN2" },
  { key: "NM_000448.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5896", geneDisplay: "RAG1" },
  { key: "NM_000450.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6401", geneDisplay: "SELE" },
  { key: "NM_000452.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6555", geneDisplay: "SLC10A2" },
  { key: "NM_000453.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6528", geneDisplay: "SLC5A5" },
  { key: "NM_000454.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6647", geneDisplay: "SOD1" },
  { key: "NM_000455.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6794", geneDisplay: "STK11" },
  { key: "NM_000458.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6928", geneDisplay: "HNF1B" },
  { key: "NM_000459.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7010", geneDisplay: "TEK" },
  { key: "NM_000460.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7066", geneDisplay: "THPO" },
  { key: "NM_000463.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54658", geneDisplay: "UGT1A1" },
  { key: "NM_000465.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "580", geneDisplay: "BARD1" },
  { key: "NM_000466.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5189", geneDisplay: "PEX1" },
  { key: "NM_000474.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7291", geneDisplay: "TWIST1" },
  { key: "NM_000475.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "190", geneDisplay: "NR0B1" },
  { key: "NM_000476.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203", geneDisplay: "AK1" },
  { key: "NM_000477.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "213", geneDisplay: "ALB" },
  { key: "NM_000478.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "249", geneDisplay: "ALPL" },
  { key: "NM_000479.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "268", geneDisplay: "AMH" },
  { key: "NM_000481.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "275", geneDisplay: "AMT" },
  { key: "NM_000482.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "337", geneDisplay: "APOA4" },
  { key: "NM_000483.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "344", geneDisplay: "APOC2" },
  { key: "NM_000484.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "351", geneDisplay: "APP" },
  { key: "NM_000485.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "353", geneDisplay: "APRT" },
  { key: "NM_000486.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "359", geneDisplay: "AQP2" },
  { key: "NM_000487.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "410", geneDisplay: "ARSA" },
  { key: "NM_000488.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "462", geneDisplay: "SERPINC1" },
  { key: "NM_000489.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "546", geneDisplay: "ATRX" },
  { key: "NM_000490.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "551", geneDisplay: "AVP" },
  { key: "NM_000492.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1080", geneDisplay: "CFTR" },
  { key: "NM_000493.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1300", geneDisplay: "COL10A1" },
  { key: "NM_000494.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1308", geneDisplay: "COL17A1" },
  { key: "NM_000496.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1415", geneDisplay: "CRYBB2" },
  { key: "NM_000497.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1584", geneDisplay: "CYP11B1" },
  { key: "NM_000498.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1585", geneDisplay: "CYP11B2" },
  { key: "NM_000500.9", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1589", geneDisplay: "CYP21A2" },
  { key: "NM_000501.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2006", geneDisplay: "ELN" },
  { key: "NM_000502.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8288", geneDisplay: "EPX" },
  { key: "NM_000503.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2138", geneDisplay: "EYA1" },
  { key: "NM_000504.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2159", geneDisplay: "F10" },
  { key: "NM_000505.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2161", geneDisplay: "F12" },
  { key: "NM_000506.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2147", geneDisplay: "F2" },
  { key: "NM_000507.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2203", geneDisplay: "FBP1" },
  { key: "NM_000511.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2524", geneDisplay: "FUT2" },
  { key: "NM_000512.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2588", geneDisplay: "GALNS" },
  { key: "NM_000513.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2652", geneDisplay: "OPN1MW" },
  { key: "NM_000514.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2668", geneDisplay: "GDNF" },
  { key: "NM_000515.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2688", geneDisplay: "GH1" },
  { key: "NM_000516.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2778", geneDisplay: "GNAS" },
  { key: "NM_000517.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3040", geneDisplay: "HBA2" },
  { key: "NM_000518.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3043", geneDisplay: "HBB" },
  { key: "NM_000519.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3045", geneDisplay: "HBD" },
  { key: "NM_000520.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3073", geneDisplay: "HEXA" },
  { key: "NM_000521.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3074", geneDisplay: "HEXB" },
  { key: "NM_000522.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3209", geneDisplay: "HOXA13" },
  { key: "NM_000523.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3239", geneDisplay: "HOXD13" },
  { key: "NM_000524.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3350", geneDisplay: "HTR1A" },
  { key: "NM_000525.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3767", geneDisplay: "KCNJ11" },
  { key: "NM_000526.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3861", geneDisplay: "KRT14" },
  { key: "NM_000527.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3949", geneDisplay: "LDLR" },
  { key: "NM_000528.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4125", geneDisplay: "MAN2B1" },
  { key: "NM_000529.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4158", geneDisplay: "MC2R" },
  { key: "NM_000530.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4359", geneDisplay: "MPZ" },
  { key: "NM_000531.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5009", geneDisplay: "OTC" },
  { key: "NM_000532.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5096", geneDisplay: "PCCB" },
  { key: "NM_000533.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5354", geneDisplay: "PLP1" },
  { key: "NM_000534.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5378", geneDisplay: "PMS1" },
  { key: "NM_000535.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5395", geneDisplay: "PMS2" },
  { key: "NM_000536.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5897", geneDisplay: "RAG2" },
  { key: "NM_000537.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5972", geneDisplay: "REN" },
  { key: "NM_000538.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5994", geneDisplay: "RFXAP" },
  { key: "NM_000539.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6010", geneDisplay: "RHO" },
  { key: "NM_000540.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6261", geneDisplay: "RYR1" },
  { key: "NM_000541.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6295", geneDisplay: "SAG" },
  { key: "NM_000542.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6439", geneDisplay: "SFTPB" },
  { key: "NM_000543.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6609", geneDisplay: "SMPD1" },
  { key: "NM_000545.8", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6927", geneDisplay: "HNF1A" },
  { key: "NM_000546.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7157", geneDisplay: "TP53" },
  { key: "NM_000548.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7249", geneDisplay: "TSC2" },
  { key: "NM_000549.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7252", geneDisplay: "TSHB" },
  { key: "NM_000550.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7306", geneDisplay: "TYRP1" },
  { key: "NM_000551.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7428", geneDisplay: "VHL" },
  { key: "NM_000552.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7450", geneDisplay: "VWF" },
  { key: "NM_000553.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7486", geneDisplay: "WRN" },
  { key: "NM_000554.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1406", geneDisplay: "CRX" },
  { key: "NM_000557.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8200", geneDisplay: "GDF5" },
  { key: "NM_000558.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3039", geneDisplay: "HBA1" },
  { key: "NM_000559.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3047", geneDisplay: "HBG1" },
  { key: "NM_000560.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "963", geneDisplay: "CD53" },
  { key: "NM_000561.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2944", geneDisplay: "GSTM1" },
  { key: "NM_000562.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "731", geneDisplay: "C8A" },
  { key: "NM_000565.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3570", geneDisplay: "IL6R" },
  { key: "NM_000566.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2209", geneDisplay: "FCGR1A" },
  { key: "NM_000567.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1401", geneDisplay: "CRP" },
  { key: "NM_000569.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2214", geneDisplay: "FCGR3A" },
  { key: "NM_000572.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3586", geneDisplay: "IL10" },
  { key: "NM_000574.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1604", geneDisplay: "CD55" },
  { key: "NM_000575.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3552", geneDisplay: "IL1A" },
  { key: "NM_000576.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3553", geneDisplay: "IL1B" },
  { key: "NM_000578.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6556", geneDisplay: "SLC11A1" },
  { key: "NM_000581.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2876", geneDisplay: "GPX1" },
  { key: "NM_000583.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2638", geneDisplay: "GC" },
  { key: "NM_000584.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3576", geneDisplay: "CXCL8" },
  { key: "NM_000585.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3600", geneDisplay: "IL15" },
  { key: "NM_000586.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3558", geneDisplay: "IL2" },
  { key: "NM_000587.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "730", geneDisplay: "C7" },
  { key: "NM_000588.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3562", geneDisplay: "IL3" },
  { key: "NM_000589.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3565", geneDisplay: "IL4" },
  { key: "NM_000590.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3578", geneDisplay: "IL9" },
  { key: "NM_000591.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "929", geneDisplay: "CD14" },
  { key: "NM_000593.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6890", geneDisplay: "TAP1" },
  { key: "NM_000594.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7124", geneDisplay: "TNF" },
  { key: "NM_000595.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4049", geneDisplay: "LTA" },
  { key: "NM_000596.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3484", geneDisplay: "IGFBP1" },
  { key: "NM_000597.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3485", geneDisplay: "IGFBP2" },
  { key: "NM_000598.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3486", geneDisplay: "IGFBP3" },
  { key: "NM_000599.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3488", geneDisplay: "IGFBP5" },
  { key: "NM_000600.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3569", geneDisplay: "IL6" },
  { key: "NM_000601.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3082", geneDisplay: "HGF" },
  { key: "NM_000602.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5054", geneDisplay: "SERPINE1" },
  { key: "NM_000603.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4846", geneDisplay: "NOS3" },
  { key: "NM_000605.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3440", geneDisplay: "IFNA2" },
  { key: "NM_000606.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "733", geneDisplay: "C8G" },
  { key: "NM_000607.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5004", geneDisplay: "ORM1" },
  { key: "NM_000608.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5005", geneDisplay: "ORM2" },
  { key: "NM_000610.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "960", geneDisplay: "CD44" },
  { key: "NM_000611.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "966", geneDisplay: "CD59" },
  { key: "NM_000612.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3481", geneDisplay: "IGF2" },
  { key: "NM_000613.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3263", geneDisplay: "HPX" },
  { key: "NM_000614.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1270", geneDisplay: "CNTF" },
  { key: "NM_000616.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "920", geneDisplay: "CD4" },
  { key: "NM_000617.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4891", geneDisplay: "SLC11A2" },
  { key: "NM_000618.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3479", geneDisplay: "IGF1" },
  { key: "NM_000619.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3458", geneDisplay: "IFNG" },
  { key: "NM_000620.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4842", geneDisplay: "NOS1" },
  { key: "NM_000621.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3356", geneDisplay: "HTR2A" },
  { key: "NM_000624.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5104", geneDisplay: "SERPINA5" },
  { key: "NM_000625.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4843", geneDisplay: "NOS2" },
  { key: "NM_000626.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "974", geneDisplay: "CD79B" },
  { key: "NM_000628.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3588", geneDisplay: "IL10RB" },
  { key: "NM_000629.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3454", geneDisplay: "IFNAR1" },
  { key: "NM_000631.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4689", geneDisplay: "NCF4" },
  { key: "NM_000632.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3684", geneDisplay: "ITGAM" },
  { key: "NM_000633.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "596", geneDisplay: "BCL2" },
  { key: "NM_000634.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3577", geneDisplay: "CXCR1" },
  { key: "NM_000635.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5990", geneDisplay: "RFX2" },
  { key: "NM_000636.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6648", geneDisplay: "SOD2" },
  { key: "NM_000637.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2936", geneDisplay: "GSR" },
  { key: "NM_000638.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7448", geneDisplay: "VTN" },
  { key: "NM_000639.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "356", geneDisplay: "FASLG" },
  { key: "NM_000640.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3598", geneDisplay: "IL13RA2" },
  { key: "NM_000641.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3589", geneDisplay: "IL11" },
  { key: "NM_000642.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "178", geneDisplay: "AGL" },
  { key: "NM_000651.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1378", geneDisplay: "CR1" },
  { key: "NM_000655.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6402", geneDisplay: "SELL" },
  { key: "NM_000660.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7040", geneDisplay: "TGFB1" },
  { key: "NM_000661.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6133", geneDisplay: "RPL9" },
  { key: "NM_000662.8", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9", geneDisplay: "NAT1" },
  { key: "NM_000665.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "43", geneDisplay: "ACHE" },
  { key: "NM_000666.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "95", geneDisplay: "ACY1" },
  { key: "NM_000667.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "124", geneDisplay: "ADH1A" },
  { key: "NM_000668.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "125", geneDisplay: "ADH1B" },
  { key: "NM_000669.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "126", geneDisplay: "ADH1C" },
  { key: "NM_000670.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "127", geneDisplay: "ADH4" },
  { key: "NM_000671.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "128", geneDisplay: "ADH5" },
  { key: "NM_000673.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "131", geneDisplay: "ADH7" },
  { key: "NM_000674.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "134", geneDisplay: "ADORA1" },
  { key: "NM_000675.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "135", geneDisplay: "ADORA2A" },
  { key: "NM_000676.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "136", geneDisplay: "ADORA2B" },
  { key: "NM_000677.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "140", geneDisplay: "ADORA3" },
  { key: "NM_000678.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "146", geneDisplay: "ADRA1D" },
  { key: "NM_000679.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "147", geneDisplay: "ADRA1B" },
  { key: "NM_000680.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "148", geneDisplay: "ADRA1A" },
  { key: "NM_000681.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "150", geneDisplay: "ADRA2A" },
  { key: "NM_000682.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151", geneDisplay: "ADRA2B" },
  { key: "NM_000683.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152", geneDisplay: "ADRA2C" },
  { key: "NM_000684.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "153", geneDisplay: "ADRB1" },
  { key: "NM_000685.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "185", geneDisplay: "AGTR1" },
  { key: "NM_000686.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "186", geneDisplay: "AGTR2" },
  { key: "NM_000687.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "191", geneDisplay: "AHCY" },
  { key: "NM_000688.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "211", geneDisplay: "ALAS1" },
  { key: "NM_000689.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "216", geneDisplay: "ALDH1A1" },
  { key: "NM_000690.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "217", geneDisplay: "ALDH2" },
  { key: "NM_000691.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "218", geneDisplay: "ALDH3A1" },
  { key: "NM_000692.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "219", geneDisplay: "ALDH1B1" },
  { key: "NM_000693.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "220", geneDisplay: "ALDH1A3" },
  { key: "NM_000694.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "221", geneDisplay: "ALDH3B1" },
  { key: "NM_000696.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "223", geneDisplay: "ALDH9A1" },
  { key: "NM_000697.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "239", geneDisplay: "ALOX12" },
  { key: "NM_000698.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "240", geneDisplay: "ALOX5" },
  { key: "NM_000699.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "279", geneDisplay: "AMY2A" },
  { key: "NM_000700.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "301", geneDisplay: "ANXA1" },
  { key: "NM_000701.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "476", geneDisplay: "ATP1A1" },
  { key: "NM_000702.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "477", geneDisplay: "ATP1A2" },
  { key: "NM_000704.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "495", geneDisplay: "ATP4A" },
  { key: "NM_000705.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "496", geneDisplay: "ATP4B" },
  { key: "NM_000706.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "552", geneDisplay: "AVPR1A" },
  { key: "NM_000707.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "553", geneDisplay: "AVPR1B" },
  { key: "NM_000709.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "593", geneDisplay: "BCKDHA" },
  { key: "NM_000710.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "623", geneDisplay: "BDKRB1" },
  { key: "NM_000712.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "644", geneDisplay: "BLVRA" },
  { key: "NM_000713.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "645", geneDisplay: "BLVRB" },
  { key: "NM_000714.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "706", geneDisplay: "TSPO" },
  { key: "NM_000715.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "722", geneDisplay: "C4BPA" },
  { key: "NM_000717.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "762", geneDisplay: "CA4" },
  { key: "NM_000718.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "774", geneDisplay: "CACNA1B" },
  { key: "NM_000719.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "775", geneDisplay: "CACNA1C" },
  { key: "NM_000722.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "781", geneDisplay: "CACNA2D1" },
  { key: "NM_000723.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "782", geneDisplay: "CACNB1" },
  { key: "NM_000725.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "784", geneDisplay: "CACNB3" },
  { key: "NM_000726.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "785", geneDisplay: "CACNB4" },
  { key: "NM_000727.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "786", geneDisplay: "CACNG1" },
  { key: "NM_000728.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "797", geneDisplay: "CALCB" },
  { key: "NM_000729.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "885", geneDisplay: "CCK" },
  { key: "NM_000730.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "886", geneDisplay: "CCKAR" },
  { key: "NM_000732.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "915", geneDisplay: "CD3D" },
  { key: "NM_000733.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "916", geneDisplay: "CD3E" },
  { key: "NM_000735.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1081", geneDisplay: "CGA" },
  { key: "NM_000737.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1082", geneDisplay: "CGB3" },
  { key: "NM_000738.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1128", geneDisplay: "CHRM1" },
  { key: "NM_000741.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1132", geneDisplay: "CHRM4" },
  { key: "NM_000742.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1135", geneDisplay: "CHRNA2" },
  { key: "NM_000743.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1136", geneDisplay: "CHRNA3" },
  { key: "NM_000744.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1137", geneDisplay: "CHRNA4" },
  { key: "NM_000745.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1138", geneDisplay: "CHRNA5" },
  { key: "NM_000746.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1139", geneDisplay: "CHRNA7" },
  { key: "NM_000747.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1140", geneDisplay: "CHRNB1" },
  { key: "NM_000748.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1141", geneDisplay: "CHRNB2" },
  { key: "NM_000749.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1142", geneDisplay: "CHRNB3" },
  { key: "NM_000750.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1143", geneDisplay: "CHRNB4" },
  { key: "NM_000751.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1144", geneDisplay: "CHRND" },
  { key: "NM_000754.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1312", geneDisplay: "COMT" },
  { key: "NM_000755.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1384", geneDisplay: "CRAT" },
  { key: "NM_000756.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1392", geneDisplay: "CRH" },
  { key: "NM_000757.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1435", geneDisplay: "CSF1" },
  { key: "NM_000758.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1437", geneDisplay: "CSF2" },
  { key: "NM_000760.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1441", geneDisplay: "CSF3R" },
  { key: "NM_000761.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1544", geneDisplay: "CYP1A2" },
  { key: "NM_000762.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1548", geneDisplay: "CYP2A6" },
  { key: "NM_000764.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1549", geneDisplay: "CYP2A7" },
  { key: "NM_000765.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1551", geneDisplay: "CYP3A7" },
  { key: "NM_000766.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1553", geneDisplay: "CYP2A13" },
  { key: "NM_000767.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1555", geneDisplay: "CYP2B6" },
  { key: "NM_000769.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1557", geneDisplay: "CYP2C19" },
  { key: "NM_000770.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1558", geneDisplay: "CYP2C8" },
  { key: "NM_000771.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1559", geneDisplay: "CYP2C9" },
  { key: "NM_000772.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1562", geneDisplay: "CYP2C18" },
  { key: "NM_000773.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1571", geneDisplay: "CYP2E1" },
  { key: "NM_000774.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1572", geneDisplay: "CYP2F1" },
  { key: "NM_000775.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1573", geneDisplay: "CYP2J2" },
  { key: "NM_000777.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1577", geneDisplay: "CYP3A5" },
  { key: "NM_000778.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1579", geneDisplay: "CYP4A11" },
  { key: "NM_000780.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1581", geneDisplay: "CYP7A1" },
  { key: "NM_000781.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1583", geneDisplay: "CYP11A1" },
  { key: "NM_000782.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1591", geneDisplay: "CYP24A1" },
  { key: "NM_000783.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1592", geneDisplay: "CYP26A1" },
  { key: "NM_000784.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1593", geneDisplay: "CYP27A1" },
  { key: "NM_000785.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1594", geneDisplay: "CYP27B1" },
  { key: "NM_000786.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1595", geneDisplay: "CYP51A1" },
  { key: "NM_000787.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1621", geneDisplay: "DBH" },
  { key: "NM_000788.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1633", geneDisplay: "DCK" },
  { key: "NM_000789.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1636", geneDisplay: "ACE" },
  { key: "NM_000791.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1719", geneDisplay: "DHFR" },
  { key: "NM_000792.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1733", geneDisplay: "DIO1" },
  { key: "NM_000794.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1812", geneDisplay: "DRD1" },
  { key: "NM_000795.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1813", geneDisplay: "DRD2" },
  { key: "NM_000796.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1814", geneDisplay: "DRD3" },
  { key: "NM_000797.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1815", geneDisplay: "DRD4" },
  { key: "NM_000798.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1816", geneDisplay: "DRD5" },
  { key: "NM_000799.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2056", geneDisplay: "EPO" },
  { key: "NM_000800.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2246", geneDisplay: "FGF1" },
  { key: "NM_000801.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2280", geneDisplay: "FKBP1A" },
  { key: "NM_000803.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2350", geneDisplay: "FOLR2" },
  { key: "NM_000804.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2352", geneDisplay: "FOLR3" },
  { key: "NM_000805.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2520", geneDisplay: "GAST" },
  { key: "NM_000807.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2555", geneDisplay: "GABRA2" },
  { key: "NM_000808.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2556", geneDisplay: "GABRA3" },
  { key: "NM_000809.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2557", geneDisplay: "GABRA4" },
  { key: "NM_000810.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2558", geneDisplay: "GABRA5" },
  { key: "NM_000811.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2559", geneDisplay: "GABRA6" },
  { key: "NM_000812.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2560", geneDisplay: "GABRB1" },
  { key: "NM_000814.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2562", geneDisplay: "GABRB3" },
  { key: "NM_000815.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2563", geneDisplay: "GABRD" },
  { key: "NM_000817.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2571", geneDisplay: "GAD1" },
  { key: "NM_000819.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "2618", geneDisplay: "GART" },
  { key: "NM_000820.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2621", geneDisplay: "GAS6" },
  { key: "NM_000821.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2677", geneDisplay: "GGCX" },
  { key: "NM_000823.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2692", geneDisplay: "GHRHR" },
  { key: "NM_000824.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2743", geneDisplay: "GLRB" },
  { key: "NM_000827.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2890", geneDisplay: "GRIA1" },
  { key: "NM_000829.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2893", geneDisplay: "GRIA4" },
  { key: "NM_000831.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2899", geneDisplay: "GRIK3" },
  { key: "NM_000834.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2904", geneDisplay: "GRIN2B" },
  { key: "NM_000835.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2905", geneDisplay: "GRIN2C" },
  { key: "NM_000836.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2906", geneDisplay: "GRIN2D" },
  { key: "NM_000839.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2912", geneDisplay: "GRM2" },
  { key: "NM_000840.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2913", geneDisplay: "GRM3" },
  { key: "NM_000841.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2914", geneDisplay: "GRM4" },
  { key: "NM_000843.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2916", geneDisplay: "GRM6" },
  { key: "NM_000844.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2917", geneDisplay: "GRM7" },
  { key: "NM_000845.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2918", geneDisplay: "GRM8" },
  { key: "NM_000846.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2939", geneDisplay: "GSTA2" },
  { key: "NM_000847.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2940", geneDisplay: "GSTA3" },
  { key: "NM_000848.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2946", geneDisplay: "GSTM2" },
  { key: "NM_000849.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2947", geneDisplay: "GSTM3" },
  { key: "NM_000850.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2948", geneDisplay: "GSTM4" },
  { key: "NM_000851.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2949", geneDisplay: "GSTM5" },
  { key: "NM_000852.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2950", geneDisplay: "GSTP1" },
  { key: "NM_000855.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2977", geneDisplay: "GUCY1A2" },
  { key: "NM_000857.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2983", geneDisplay: "GUCY1B1" },
  { key: "NM_000858.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2987", geneDisplay: "GUK1" },
  { key: "NM_000859.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3156", geneDisplay: "HMGCR" },
  { key: "NM_000860.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3248", geneDisplay: "HPGD" },
  { key: "NM_000862.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3283", geneDisplay: "HSD3B1" },
  { key: "NM_000863.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3351", geneDisplay: "HTR1B" },
  { key: "NM_000864.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3352", geneDisplay: "HTR1D" },
  { key: "NM_000865.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3354", geneDisplay: "HTR1E" },
  { key: "NM_000867.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3357", geneDisplay: "HTR2B" },
  { key: "NM_000868.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3358", geneDisplay: "HTR2C" },
  { key: "NM_000869.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3359", geneDisplay: "HTR3A" },
  { key: "NM_000870.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3360", geneDisplay: "HTR4" },
  { key: "NM_000871.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3362", geneDisplay: "HTR6" },
  { key: "NM_000875.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3480", geneDisplay: "IGF1R" },
  { key: "NM_000876.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3482", geneDisplay: "IGF2R" },
  { key: "NM_000877.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3554", geneDisplay: "IL1R1" },
  { key: "NM_000878.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3560", geneDisplay: "IL2RB" },
  { key: "NM_000879.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3567", geneDisplay: "IL5" },
  { key: "NM_000880.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3574", geneDisplay: "IL7" },
  { key: "NM_000882.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3592", geneDisplay: "IL12A" },
  { key: "NM_000883.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3614", geneDisplay: "IMPDH1" },
  { key: "NM_000884.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3615", geneDisplay: "IMPDH2" },
  { key: "NM_000885.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3676", geneDisplay: "ITGA4" },
  { key: "NM_000887.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3687", geneDisplay: "ITGAX" },
  { key: "NM_000888.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3694", geneDisplay: "ITGB6" },
  { key: "NM_000889.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3695", geneDisplay: "ITGB7" },
  { key: "NM_000890.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3762", geneDisplay: "KCNJ5" },
  { key: "NM_000891.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3759", geneDisplay: "KCNJ2" },
  { key: "NM_000892.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3818", geneDisplay: "KLKB1" },
  { key: "NM_000894.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3972", geneDisplay: "LHB" },
  { key: "NM_000895.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4048", geneDisplay: "LTA4H" },
  { key: "NM_000896.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4051", geneDisplay: "CYP4F3" },
  { key: "NM_000898.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4129", geneDisplay: "MAOB" },
  { key: "NM_000899.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4254", geneDisplay: "KITLG" },
  { key: "NM_000900.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4256", geneDisplay: "MGP" },
  { key: "NM_000901.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4306", geneDisplay: "NR3C2" },
  { key: "NM_000903.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1728", geneDisplay: "NQO1" },
  { key: "NM_000904.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4835", geneDisplay: "NQO2" },
  { key: "NM_000905.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4852", geneDisplay: "NPY" },
  { key: "NM_000906.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4881", geneDisplay: "NPR1" },
  { key: "NM_000909.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4886", geneDisplay: "NPY1R" },
  { key: "NM_000910.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4887", geneDisplay: "NPY2R" },
  { key: "NM_000911.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4985", geneDisplay: "OPRD1" },
  { key: "NM_000912.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4986", geneDisplay: "OPRK1" },
  { key: "NM_000914.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4988", geneDisplay: "OPRM1" },
  { key: "NM_000915.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5020", geneDisplay: "OXT" },
  { key: "NM_000916.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5021", geneDisplay: "OXTR" },
  { key: "NM_000918.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5034", geneDisplay: "P4HB" },
  { key: "NM_000921.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5139", geneDisplay: "PDE3A" },
  { key: "NM_000922.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5140", geneDisplay: "PDE3B" },
  { key: "NM_000924.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5153", geneDisplay: "PDE1B" },
  { key: "NM_000925.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5162", geneDisplay: "PDHB" },
  { key: "NM_000926.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5241", geneDisplay: "PGR" },
  { key: "NM_000928.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5319", geneDisplay: "PLA2G1B" },
  { key: "NM_000929.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5322", geneDisplay: "PLA2G5" },
  { key: "NM_000930.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5327", geneDisplay: "PLAT" },
  { key: "NM_000932.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5331", geneDisplay: "PLCB3" },
  { key: "NM_000934.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5345", geneDisplay: "SERPINF2" },
  { key: "NM_000936.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5406", geneDisplay: "PNLIP" },
  { key: "NM_000937.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5430", geneDisplay: "POLR2A" },
  { key: "NM_000938.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5431", geneDisplay: "POLR2B" },
  { key: "NM_000939.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5443", geneDisplay: "POMC" },
  { key: "NM_000940.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5446", geneDisplay: "PON3" },
  { key: "NM_000942.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5479", geneDisplay: "PPIB" },
  { key: "NM_000943.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5480", geneDisplay: "PPIC" },
  { key: "NM_000944.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5530", geneDisplay: "PPP3CA" },
  { key: "NM_000945.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5534", geneDisplay: "PPP3R1" },
  { key: "NM_000946.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5557", geneDisplay: "PRIM1" },
  { key: "NM_000947.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5558", geneDisplay: "PRIM2" },
  { key: "NM_000948.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5617", geneDisplay: "PRL" },
  { key: "NM_000949.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5618", geneDisplay: "PRLR" },
  { key: "NM_000951.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5639", geneDisplay: "PRRG2" },
  { key: "NM_000952.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5724", geneDisplay: "PTAFR" },
  { key: "NM_000953.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5729", geneDisplay: "PTGDR" },
  { key: "NM_000954.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5730", geneDisplay: "PTGDS" },
  { key: "NM_000955.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5731", geneDisplay: "PTGER1" },
  { key: "NM_000956.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5732", geneDisplay: "PTGER2" },
  { key: "NM_000958.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5734", geneDisplay: "PTGER4" },
  { key: "NM_000959.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5737", geneDisplay: "PTGFR" },
  { key: "NM_000960.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5739", geneDisplay: "PTGIR" },
  { key: "NM_000961.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5740", geneDisplay: "PTGIS" },
  { key: "NM_000962.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5742", geneDisplay: "PTGS1" },
  { key: "NM_000963.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5743", geneDisplay: "PTGS2" },
  { key: "NM_000964.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5914", geneDisplay: "RARA" },
  { key: "NM_000965.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5915", geneDisplay: "RARB" },
  { key: "NM_000966.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5916", geneDisplay: "RARG" },
  { key: "NM_000967.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6122", geneDisplay: "RPL3" },
  { key: "NM_000968.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6124", geneDisplay: "RPL4" },
  { key: "NM_000969.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6125", geneDisplay: "RPL5" },
  { key: "NM_000970.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6128", geneDisplay: "RPL6" },
  { key: "NM_000971.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6129", geneDisplay: "RPL7" },
  { key: "NM_000972.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6130", geneDisplay: "RPL7A" },
  { key: "NM_000975.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6135", geneDisplay: "RPL11" },
  { key: "NM_000976.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6136", geneDisplay: "RPL12" },
  { key: "NM_000977.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6137", geneDisplay: "RPL13" },
  { key: "NM_000978.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9349", geneDisplay: "RPL23" },
  { key: "NM_000979.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6141", geneDisplay: "RPL18" },
  { key: "NM_000980.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6142", geneDisplay: "RPL18A" },
  { key: "NM_000981.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6143", geneDisplay: "RPL19" },
  { key: "NM_000982.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6144", geneDisplay: "RPL21" },
  { key: "NM_000983.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6146", geneDisplay: "RPL22" },
  { key: "NM_000984.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6147", geneDisplay: "RPL23A" },
  { key: "NM_000986.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6152", geneDisplay: "RPL24" },
  { key: "NM_000987.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6154", geneDisplay: "RPL26" },
  { key: "NM_000988.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6155", geneDisplay: "RPL27" },
  { key: "NM_000989.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6156", geneDisplay: "RPL30" },
  { key: "NM_000990.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6157", geneDisplay: "RPL27A" },
  { key: "NM_000991.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6158", geneDisplay: "RPL28" },
  { key: "NM_000992.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6159", geneDisplay: "RPL29" },
  { key: "NM_000993.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6160", geneDisplay: "RPL31" },
  { key: "NM_000994.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6161", geneDisplay: "RPL32" },
  { key: "NM_000996.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6165", geneDisplay: "RPL35A" },
  { key: "NM_000997.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6167", geneDisplay: "RPL37" },
  { key: "NM_000998.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6168", geneDisplay: "RPL37A" },
  { key: "NM_000999.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6169", geneDisplay: "RPL38" },
  { key: "NM_001000.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6170", geneDisplay: "RPL39" },
  { key: "NM_001001.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6166", geneDisplay: "RPL36AL" },
  { key: "NM_001002.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6175", geneDisplay: "RPLP0" },
  { key: "NM_001003.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6176", geneDisplay: "RPLP1" },
  { key: "NM_001004.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6181", geneDisplay: "RPLP2" },
  { key: "NM_001005.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6188", geneDisplay: "RPS3" },
  { key: "NM_001006.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6189", geneDisplay: "RPS3A" },
  { key: "NM_001007.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6191", geneDisplay: "RPS4X" },
  { key: "NM_001008.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "6192", geneDisplay: "RPS4Y1" },
  { key: "NM_001009.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6193", geneDisplay: "RPS5" },
  { key: "NM_001010.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6194", geneDisplay: "RPS6" },
  { key: "NM_001011.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6201", geneDisplay: "RPS7" },
  { key: "NM_001012.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6202", geneDisplay: "RPS8" },
  { key: "NM_001013.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6203", geneDisplay: "RPS9" },
  { key: "NM_001014.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6204", geneDisplay: "RPS10" },
  { key: "NM_001015.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6205", geneDisplay: "RPS11" },
  { key: "NM_001016.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6206", geneDisplay: "RPS12" },
  { key: "NM_001017.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6207", geneDisplay: "RPS13" },
  { key: "NM_001018.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6209", geneDisplay: "RPS15" },
  { key: "NM_001019.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6210", geneDisplay: "RPS15A" },
  { key: "NM_001020.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6217", geneDisplay: "RPS16" },
  { key: "NM_001021.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6218", geneDisplay: "RPS17" },
  { key: "NM_001022.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6223", geneDisplay: "RPS19" },
  { key: "NM_001023.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6224", geneDisplay: "RPS20" },
  { key: "NM_001024.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6227", geneDisplay: "RPS21" },
  { key: "NM_001025.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6228", geneDisplay: "RPS23" },
  { key: "NM_001028.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6230", geneDisplay: "RPS25" },
  { key: "NM_001029.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6231", geneDisplay: "RPS26" },
  { key: "NM_001030.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6232", geneDisplay: "RPS27" },
  { key: "NM_001031.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6234", geneDisplay: "RPS28" },
  { key: "NM_001032.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6235", geneDisplay: "RPS29" },
  { key: "NM_001033.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6240", geneDisplay: "RRM1" },
  { key: "NM_001034.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6241", geneDisplay: "RRM2" },
  { key: "NM_001035.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6262", geneDisplay: "RYR2" },
  { key: "NM_001036.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6263", geneDisplay: "RYR3" },
  { key: "NM_001037.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6324", geneDisplay: "SCN1B" },
  { key: "NM_001038.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6337", geneDisplay: "SCNN1A" },
  { key: "NM_001039.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6340", geneDisplay: "SCNN1G" },
  { key: "NM_001040.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6462", geneDisplay: "SHBG" },
  { key: "NM_001041.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6476", geneDisplay: "SI" },
  { key: "NM_001042.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6517", geneDisplay: "SLC2A4" },
  { key: "NM_001044.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6531", geneDisplay: "SLC6A3" },
  { key: "NM_001045.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6532", geneDisplay: "SLC6A4" },
  { key: "NM_001046.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6558", geneDisplay: "SLC12A2" },
  { key: "NM_001047.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6715", geneDisplay: "SRD5A1" },
  { key: "NM_001048.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6750", geneDisplay: "SST" },
  { key: "NM_001049.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6751", geneDisplay: "SSTR1" },
  { key: "NM_001050.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6752", geneDisplay: "SSTR2" },
  { key: "NM_001051.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6753", geneDisplay: "SSTR3" },
  { key: "NM_001052.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6754", geneDisplay: "SSTR4" },
  { key: "NM_001054.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6799", geneDisplay: "SULT1A2" },
  { key: "NM_001055.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6817", geneDisplay: "SULT1A1" },
  { key: "NM_001056.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6819", geneDisplay: "SULT1C2" },
  { key: "NM_001057.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6865", geneDisplay: "TACR2" },
  { key: "NM_001058.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6869", geneDisplay: "TACR1" },
  { key: "NM_001059.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6870", geneDisplay: "TACR3" },
  { key: "NM_001060.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6915", geneDisplay: "TBXA2R" },
  { key: "NM_001061.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6916", geneDisplay: "TBXAS1" },
  { key: "NM_001062.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6947", geneDisplay: "TCN1" },
  { key: "NM_001063.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7018", geneDisplay: "TF" },
  { key: "NM_001064.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7086", geneDisplay: "TKT" },
  { key: "NM_001065.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7132", geneDisplay: "TNFRSF1A" },
  { key: "NM_001066.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7133", geneDisplay: "TNFRSF1B" },
  { key: "NM_001067.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7153", geneDisplay: "TOP2A" },
  { key: "NM_001069.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7280", geneDisplay: "TUBB2A" },
  { key: "NM_001070.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7283", geneDisplay: "TUBG1" },
  { key: "NM_001071.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7298", geneDisplay: "TYMS" },
  { key: "NM_001072.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54578", geneDisplay: "UGT1A6" },
  { key: "NM_001073.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10720", geneDisplay: "UGT2B11" },
  { key: "NM_001074.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7364", geneDisplay: "UGT2B7" },
  { key: "NM_001075.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7365", geneDisplay: "UGT2B10" },
  { key: "NM_001076.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7366", geneDisplay: "UGT2B15" },
  { key: "NM_001077.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7367", geneDisplay: "UGT2B17" },
  { key: "NM_001078.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7412", geneDisplay: "VCAM1" },
  { key: "NM_001079.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7535", geneDisplay: "ZAP70" },
  { key: "NM_001080.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7915", geneDisplay: "ALDH5A1" },
  { key: "NM_001081.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8029", geneDisplay: "CUBN" },
  { key: "NM_001082.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8529", geneDisplay: "CYP4F2" },
  { key: "NM_001083.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8654", geneDisplay: "PDE5A" },
  { key: "NM_001084.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8985", geneDisplay: "PLOD3" },
  { key: "NM_001085.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "12", geneDisplay: "SERPINA3" },
  { key: "NM_001086.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "13", geneDisplay: "AADAC" },
  { key: "NM_001087.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "14", geneDisplay: "AAMP" },
  { key: "NM_001088.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "15", geneDisplay: "AANAT" },
  { key: "NM_001089.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "21", geneDisplay: "ABCA3" },
  { key: "NM_001091.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26", geneDisplay: "AOC1" },
  { key: "NM_001093.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "32", geneDisplay: "ACACB" },
  { key: "NM_001095.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "41", geneDisplay: "ASIC1" },
  { key: "NM_001096.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "47", geneDisplay: "ACLY" },
  { key: "NM_001097.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "49", geneDisplay: "ACR" },
  { key: "NM_001098.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "50", geneDisplay: "ACO2" },
  { key: "NM_001099.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55", geneDisplay: "ACP3" },
  { key: "NM_001100.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58", geneDisplay: "ACTA1" },
  { key: "NM_001101.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "60", geneDisplay: "ACTB" },
  { key: "NM_001103.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "88", geneDisplay: "ACTN2" },
  { key: "NM_001104.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "89", geneDisplay: "ACTN3" },
  { key: "NM_001106.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "93", geneDisplay: "ACVR2B" },
  { key: "NM_001107.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "97", geneDisplay: "ACYP1" },
  { key: "NM_001109.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "101", geneDisplay: "ADAM8" },
  { key: "NM_001110.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "102", geneDisplay: "ADAM10" },
  { key: "NM_001111.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "103", geneDisplay: "ADAR" },
  { key: "NM_001112.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "104", geneDisplay: "ADARB1" },
  { key: "NM_001114.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "113", geneDisplay: "ADCY7" },
  { key: "NM_001115.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114", geneDisplay: "ADCY8" },
  { key: "NM_001116.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "115", geneDisplay: "ADCY9" },
  { key: "NM_001118.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "117", geneDisplay: "ADCYAP1R1" },
  { key: "NM_001122.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "123", geneDisplay: "PLIN2" },
  { key: "NM_001124.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "133", geneDisplay: "ADM" },
  { key: "NM_001125.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "141", geneDisplay: "ADPRH" },
  { key: "NM_001126.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "159", geneDisplay: "ADSS2" },
  { key: "NM_001127.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "162", geneDisplay: "AP1B1" },
  { key: "NM_001128.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "164", geneDisplay: "AP1G1" },
  { key: "NM_001129.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "165", geneDisplay: "AEBP1" },
  { key: "NM_001130.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "166", geneDisplay: "TLE5" },
  { key: "NM_001131.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "167", geneDisplay: "CRISP1" },
  { key: "NM_001133.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "173", geneDisplay: "AFM" },
  { key: "NM_001134.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "174", geneDisplay: "AFP" },
  { key: "NM_001136.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "177", geneDisplay: "AGER" },
  { key: "NM_001138.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "181", geneDisplay: "AGRP" },
  { key: "NM_001139.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "242", geneDisplay: "ALOX12B" },
  { key: "NM_001140.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "246", geneDisplay: "ALOX15" },
  { key: "NM_001141.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "247", geneDisplay: "ALOX15B" },
  { key: "NM_001142.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "265", geneDisplay: "AMELX" },
  { key: "NM_001143.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "266", geneDisplay: "AMELY" },
  { key: "NM_001144.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "267", geneDisplay: "AMFR" },
  { key: "NM_001146.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "284", geneDisplay: "ANGPT1" },
  { key: "NM_001148.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "287", geneDisplay: "ANK2" },
  { key: "NM_001150.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "290", geneDisplay: "ANPEP" },
  { key: "NM_001151.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "291", geneDisplay: "SLC25A4" },
  { key: "NM_001152.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "292", geneDisplay: "SLC25A5" },
  { key: "NM_001153.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "307", geneDisplay: "ANXA4" },
  { key: "NM_001154.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "308", geneDisplay: "ANXA5" },
  { key: "NM_001155.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "309", geneDisplay: "ANXA6" },
  { key: "NM_001156.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "310", geneDisplay: "ANXA7" },
  { key: "NM_001159.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "316", geneDisplay: "AOX1" },
  { key: "NM_001161.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "318", geneDisplay: "NUDT2" },
  { key: "NM_001163.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "320", geneDisplay: "APBA1" },
  { key: "NM_001164.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "322", geneDisplay: "APBB1" },
  { key: "NM_001165.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "330", geneDisplay: "BIRC3" },
  { key: "NM_001166.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "329", geneDisplay: "BIRC2" },
  { key: "NM_001167.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "331", geneDisplay: "XIAP" },
  { key: "NM_001168.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "332", geneDisplay: "BIRC5" },
  { key: "NM_001169.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "343", geneDisplay: "AQP8" },
  { key: "NM_001170.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "364", geneDisplay: "AQP7" },
  { key: "NM_001171.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "368", geneDisplay: "ABCC6" },
  { key: "NM_001172.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "384", geneDisplay: "ARG2" },
  { key: "NM_001175.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "397", geneDisplay: "ARHGDIB" },
  { key: "NM_001176.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "398", geneDisplay: "ARHGDIG" },
  { key: "NM_001177.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "400", geneDisplay: "ARL1" },
  { key: "NM_001182.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "501", geneDisplay: "ALDH7A1" },
  { key: "NM_001183.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "537", geneDisplay: "ATP6AP1" },
  { key: "NM_001184.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "545", geneDisplay: "ATR" },
  { key: "NM_001185.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "563", geneDisplay: "AZGP1" },
  { key: "NM_001186.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "571", geneDisplay: "BACH1" },
  { key: "NM_001188.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "578", geneDisplay: "BAK1" },
  { key: "NM_001189.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "579", geneDisplay: "NKX3-2" },
  { key: "NM_001190.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "587", geneDisplay: "BCAT2" },
  { key: "NM_001192.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "608", geneDisplay: "TNFRSF17" },
  { key: "NM_001194.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "610", geneDisplay: "HCN2" },
  { key: "NM_001195.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "631", geneDisplay: "BFSP1" },
  { key: "NM_001196.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "637", geneDisplay: "BID" },
  { key: "NM_001197.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "638", geneDisplay: "BIK" },
  { key: "NM_001198.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "639", geneDisplay: "PRDM1" },
  { key: "NM_001200.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "650", geneDisplay: "BMP2" },
  { key: "NM_001201.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "651", geneDisplay: "BMP3" },
  { key: "NM_001202.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "652", geneDisplay: "BMP4" },
  { key: "NM_001203.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "658", geneDisplay: "BMPR1B" },
  { key: "NM_001204.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "659", geneDisplay: "BMPR2" },
  { key: "NM_001205.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "662", geneDisplay: "BNIP1" },
  { key: "NM_001206.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "687", geneDisplay: "KLF9" },
  { key: "NM_001211.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "701", geneDisplay: "BUB1B" },
  { key: "NM_001212.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "708", geneDisplay: "C1QBP" },
  { key: "NM_001215.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "765", geneDisplay: "CA6" },
  { key: "NM_001216.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "768", geneDisplay: "CA9" },
  { key: "NM_001217.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "770", geneDisplay: "CA11" },
  { key: "NM_001218.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "771", geneDisplay: "CA12" },
  { key: "NM_001219.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "813", geneDisplay: "CALU" },
  { key: "NM_001220.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "816", geneDisplay: "CAMK2B" },
  { key: "NM_001225.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "837", geneDisplay: "CASP4" },
  { key: "NM_001226.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "839", geneDisplay: "CASP6" },
  { key: "NM_001227.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "840", geneDisplay: "CASP7" },
  { key: "NM_001229.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "842", geneDisplay: "CASP9" },
  { key: "NM_001231.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "844", geneDisplay: "CASQ1" },
  { key: "NM_001232.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "845", geneDisplay: "CASQ2" },
  { key: "NM_001233.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "858", geneDisplay: "CAV2" },
  { key: "NM_001235.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "871", geneDisplay: "SERPINH1" },
  { key: "NM_001236.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "874", geneDisplay: "CBR3" },
  { key: "NM_001237.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "890", geneDisplay: "CCNA2" },
  { key: "NM_001238.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "898", geneDisplay: "CCNE1" },
  { key: "NM_001239.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "902", geneDisplay: "CCNH" },
  { key: "NM_001240.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "904", geneDisplay: "CCNT1" },
  { key: "NM_001242.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "939", geneDisplay: "CD27" },
  { key: "NM_001243.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "943", geneDisplay: "TNFRSF8" },
  { key: "NM_001244.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "944", geneDisplay: "TNFSF8" },
  { key: "NM_001245.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "946", geneDisplay: "SIGLEC6" },
  { key: "NM_001247.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "955", geneDisplay: "ENTPD6" },
  { key: "NM_001248.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "956", geneDisplay: "ENTPD3" },
  { key: "NM_001249.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "957", geneDisplay: "ENTPD5" },
  { key: "NM_001250.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "958", geneDisplay: "CD40" },
  { key: "NM_001251.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "968", geneDisplay: "CD68" },
  { key: "NM_001252.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "970", geneDisplay: "CD70" },
  { key: "NM_001253.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "988", geneDisplay: "CDC5L" },
  { key: "NM_001254.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "990", geneDisplay: "CDC6" },
  { key: "NM_001255.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "991", geneDisplay: "CDC20" },
  { key: "NM_001256.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "996", geneDisplay: "CDC27" },
  { key: "NM_001257.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1012", geneDisplay: "CDH13" },
  { key: "NM_001258.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1018", geneDisplay: "CDK3" },
  { key: "NM_001260.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1024", geneDisplay: "CDK8" },
  { key: "NM_001261.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1025", geneDisplay: "CDK9" },
  { key: "NM_001263.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1040", geneDisplay: "CDS1" },
  { key: "NM_001264.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1041", geneDisplay: "CDSN" },
  { key: "NM_001265.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1045", geneDisplay: "CDX2" },
  { key: "NM_001267.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1101", geneDisplay: "CHAD" },
  { key: "NM_001268.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1102", geneDisplay: "RCBTB2" },
  { key: "NM_001270.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1105", geneDisplay: "CHD1" },
  { key: "NM_001271.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1106", geneDisplay: "CHD2" },
  { key: "NM_001273.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1108", geneDisplay: "CHD4" },
  { key: "NM_001275.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1113", geneDisplay: "CHGA" },
  { key: "NM_001276.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1116", geneDisplay: "CHI3L1" },
  { key: "NM_001277.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1119", geneDisplay: "CHKA" },
  { key: "NM_001278.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1147", geneDisplay: "CHUK" },
  { key: "NM_001279.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1149", geneDisplay: "CIDEA" },
  { key: "NM_001281.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1155", geneDisplay: "TBCB" },
  { key: "NM_001283.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1174", geneDisplay: "AP1S1" },
  { key: "NM_001284.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1176", geneDisplay: "AP3S1" },
  { key: "NM_001285.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1179", geneDisplay: "CLCA1" },
  { key: "NM_001286.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1185", geneDisplay: "CLCN6" },
  { key: "NM_001287.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1186", geneDisplay: "CLCN7" },
  { key: "NM_001288.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1192", geneDisplay: "CLIC1" },
  { key: "NM_001289.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1193", geneDisplay: "CLIC2" },
  { key: "NM_001290.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9079", geneDisplay: "LDB2" },
  { key: "NM_001293.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1207", geneDisplay: "CLNS1A" },
  { key: "NM_001294.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1209", geneDisplay: "CLPTM1" },
  { key: "NM_001295.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1230", geneDisplay: "CCR1" },
  { key: "NM_001296.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1238", geneDisplay: "ACKR2" },
  { key: "NM_001297.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1258", geneDisplay: "CNGB1" },
  { key: "NM_001298.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1261", geneDisplay: "CNGA3" },
  { key: "NM_001299.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1264", geneDisplay: "CNN1" },
  { key: "NM_001300.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1316", geneDisplay: "KLF6" },
  { key: "NM_001302.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1325", geneDisplay: "CORT" },
  { key: "NM_001303.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1352", geneDisplay: "COX10" },
  { key: "NM_001304.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1362", geneDisplay: "CPD" },
  { key: "NM_001305.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1364", geneDisplay: "CLDN4" },
  { key: "NM_001306.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1365", geneDisplay: "CLDN3" },
  { key: "NM_001307.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1366", geneDisplay: "CLDN7" },
  { key: "NM_001308.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1369", geneDisplay: "CPN1" },
  { key: "NM_001310.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1389", geneDisplay: "CREBL2" },
  { key: "NM_001311.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1396", geneDisplay: "CRIP1" },
  { key: "NM_001312.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1397", geneDisplay: "CRIP2" },
  { key: "NM_001316.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1434", geneDisplay: "CSE1L" },
  { key: "NM_001317.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1442", geneDisplay: "CSH1" },
  { key: "NM_001319.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1455", geneDisplay: "CSNK1G2" },
  { key: "NM_001320.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1460", geneDisplay: "CSNK2B" },
  { key: "NM_001321.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1466", geneDisplay: "CSRP2" },
  { key: "NM_001322.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1470", geneDisplay: "CST2" },
  { key: "NM_001323.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1474", geneDisplay: "CST6" },
  { key: "NM_001324.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1477", geneDisplay: "CSTF1" },
  { key: "NM_001325.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1478", geneDisplay: "CSTF2" },
  { key: "NM_001326.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1479", geneDisplay: "CSTF3" },
  { key: "NM_001327.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1485", geneDisplay: "CTAG1B" },
  { key: "NM_001330.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1489", geneDisplay: "CTF1" },
  { key: "NM_001332.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1501", geneDisplay: "CTNND2" },
  { key: "NM_001333.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1515", geneDisplay: "CTSV" },
  { key: "NM_001334.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1519", geneDisplay: "CTSO" },
  { key: "NM_001335.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1521", geneDisplay: "CTSW" },
  { key: "NM_001336.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1522", geneDisplay: "CTSZ" },
  { key: "NM_001337.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1524", geneDisplay: "CX3CR1" },
  { key: "NM_001338.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1525", geneDisplay: "CXADR" },
  { key: "NM_001340.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1539", geneDisplay: "CYLC2" },
  { key: "NM_001343.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1601", geneDisplay: "DAB2" },
  { key: "NM_001344.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1603", geneDisplay: "DAD1" },
  { key: "NM_001345.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1606", geneDisplay: "DGKA" },
  { key: "NM_001346.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1608", geneDisplay: "DGKG" },
  { key: "NM_001347.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1609", geneDisplay: "DGKQ" },
  { key: "NM_001348.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1613", geneDisplay: "DAPK3" },
  { key: "NM_001349.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1615", geneDisplay: "DARS1" },
  { key: "NM_001351.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1618", geneDisplay: "DAZL" },
  { key: "NM_001352.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1628", geneDisplay: "DBP" },
  { key: "NM_001353.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1645", geneDisplay: "AKR1C1" },
  { key: "NM_001356.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1654", geneDisplay: "DDX3X" },
  { key: "NM_001357.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1660", geneDisplay: "DHX9" },
  { key: "NM_001358.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1665", geneDisplay: "DHX15" },
  { key: "NM_001359.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1666", geneDisplay: "DECR1" },
  { key: "NM_001360.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1717", geneDisplay: "DHCR7" },
  { key: "NM_001361.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1723", geneDisplay: "DHODH" },
  { key: "NM_001362.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1735", geneDisplay: "DIO3" },
  { key: "NM_001363.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1736", geneDisplay: "DKC1" },
  { key: "NM_001369.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1767", geneDisplay: "DNAH5" },
  { key: "NM_001370.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1768", geneDisplay: "DNAH6" },
  { key: "NM_001372.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1770", geneDisplay: "DNAH9" },
  { key: "NM_001374.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1775", geneDisplay: "DNASE1L2" },
  { key: "NM_001375.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1777", geneDisplay: "DNASE2" },
  { key: "NM_001376.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1778", geneDisplay: "DYNC1H1" },
  { key: "NM_001377.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79659", geneDisplay: "DYNC2H1" },
  { key: "NM_001378.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1781", geneDisplay: "DYNC1I2" },
  { key: "NM_001381.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1796", geneDisplay: "DOK1" },
  { key: "NM_001382.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1798", geneDisplay: "DPAGT1" },
  { key: "NM_001383.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1801", geneDisplay: "DPH1" },
  { key: "NM_001384.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1802", geneDisplay: "DPH2" },
  { key: "NM_001385.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1807", geneDisplay: "DPYS" },
  { key: "NM_001388.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1819", geneDisplay: "DRG2" },
  { key: "NM_001389.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1826", geneDisplay: "DSCAM" },
  { key: "NM_001393.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1842", geneDisplay: "ECM2" },
  { key: "NM_001394.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1846", geneDisplay: "DUSP4" },
  { key: "NM_001397.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1889", geneDisplay: "ECE1" },
  { key: "NM_001398.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1891", geneDisplay: "ECH1" },
  { key: "NM_001399.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1896", geneDisplay: "EDA" },
  { key: "NM_001400.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1901", geneDisplay: "S1PR1" },
  { key: "NM_001402.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1915", geneDisplay: "EEF1A1" },
  { key: "NM_001404.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1937", geneDisplay: "EEF1G" },
  { key: "NM_001405.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1943", geneDisplay: "EFNA2" },
  { key: "NM_001406.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1949", geneDisplay: "EFNB3" },
  { key: "NM_001407.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1951", geneDisplay: "CELSR3" },
  { key: "NM_001408.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1952", geneDisplay: "CELSR2" },
  { key: "NM_001409.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1953", geneDisplay: "MEGF6" },
  { key: "NM_001412.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1964", geneDisplay: "EIF1AX" },
  { key: "NM_001414.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1967", geneDisplay: "EIF2B1" },
  { key: "NM_001415.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1968", geneDisplay: "EIF2S3" },
  { key: "NM_001416.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1973", geneDisplay: "EIF4A1" },
  { key: "NM_001417.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1975", geneDisplay: "EIF4B" },
  { key: "NM_001418.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1982", geneDisplay: "EIF4G2" },
  { key: "NM_001419.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1994", geneDisplay: "ELAVL1" },
  { key: "NM_001420.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1995", geneDisplay: "ELAVL3" },
  { key: "NM_001421.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2000", geneDisplay: "ELF4" },
  { key: "NM_001422.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2001", geneDisplay: "ELF5" },
  { key: "NM_001423.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2012", geneDisplay: "EMP1" },
  { key: "NM_001424.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2013", geneDisplay: "EMP2" },
  { key: "NM_001425.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2014", geneDisplay: "EMP3" },
  { key: "NM_001426.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2019", geneDisplay: "EN1" },
  { key: "NM_001427.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2020", geneDisplay: "EN2" },
  { key: "NM_001428.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2023", geneDisplay: "ENO1" },
  { key: "NM_001429.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2033", geneDisplay: "EP300" },
  { key: "NM_001430.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2034", geneDisplay: "EPAS1" },
  { key: "NM_001431.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2037", geneDisplay: "EPB41L2" },
  { key: "NM_001432.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2069", geneDisplay: "EREG" },
  { key: "NM_001433.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2081", geneDisplay: "ERN1" },
  { key: "NM_001436.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2091", geneDisplay: "FBL" },
  { key: "NM_001437.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2100", geneDisplay: "ESR2" },
  { key: "NM_001438.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2104", geneDisplay: "ESRRG" },
  { key: "NM_001440.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2137", geneDisplay: "EXTL3" },
  { key: "NM_001441.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2166", geneDisplay: "FAAH" },
  { key: "NM_001442.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2167", geneDisplay: "FABP4" },
  { key: "NM_001443.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2168", geneDisplay: "FABP1" },
  { key: "NM_001444.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2171", geneDisplay: "FABP5" },
  { key: "NM_001445.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2172", geneDisplay: "FABP6" },
  { key: "NM_001446.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2173", geneDisplay: "FABP7" },
  { key: "NM_001447.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2196", geneDisplay: "FAT2" },
  { key: "NM_001448.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2239", geneDisplay: "GPC4" },
  { key: "NM_001451.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2294", geneDisplay: "FOXF1" },
  { key: "NM_001452.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2295", geneDisplay: "FOXF2" },
  { key: "NM_001453.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2296", geneDisplay: "FOXC1" },
  { key: "NM_001454.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2302", geneDisplay: "FOXJ1" },
  { key: "NM_001455.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2309", geneDisplay: "FOXO3" },
  { key: "NM_001457.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2317", geneDisplay: "FLNB" },
  { key: "NM_001458.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2318", geneDisplay: "FLNC" },
  { key: "NM_001459.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2323", geneDisplay: "FLT3LG" },
  { key: "NM_001460.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2327", geneDisplay: "FMO2" },
  { key: "NM_001461.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2330", geneDisplay: "FMO5" },
  { key: "NM_001463.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2487", geneDisplay: "FRZB" },
  { key: "NM_001464.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2515", geneDisplay: "ADAM2" },
  { key: "NM_001465.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2533", geneDisplay: "FYB1" },
  { key: "NM_001466.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2535", geneDisplay: "FZD2" },
  { key: "NM_001469.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2547", geneDisplay: "XRCC6" },
  { key: "NM_001470.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2550", geneDisplay: "GABBR1" },
  { key: "NM_001478.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2583", geneDisplay: "B4GALNT1" },
  { key: "NM_001480.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2587", geneDisplay: "GALR1" },
  { key: "NM_001481.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2622", geneDisplay: "GAS8" },
  { key: "NM_001482.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2628", geneDisplay: "GATM" },
  { key: "NM_001483.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2631", geneDisplay: "NIPSNAP2" },
  { key: "NM_001485.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2637", geneDisplay: "GBX2" },
  { key: "NM_001486.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2646", geneDisplay: "GCKR" },
  { key: "NM_001487.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2647", geneDisplay: "BLOC1S1" },
  { key: "NM_001490.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2650", geneDisplay: "GCNT1" },
  { key: "NM_001492.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2657", geneDisplay: "GDF1" },
  { key: "NM_001493.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2664", geneDisplay: "GDI1" },
  { key: "NM_001494.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2665", geneDisplay: "GDI2" },
  { key: "NM_001495.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2675", geneDisplay: "GFRA2" },
  { key: "NM_001496.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2676", geneDisplay: "GFRA3" },
  { key: "NM_001497.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2683", geneDisplay: "B4GALT1" },
  { key: "NM_001498.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2729", geneDisplay: "GCLC" },
  { key: "NM_001500.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2762", geneDisplay: "GMDS" },
  { key: "NM_001502.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2813", geneDisplay: "GP2" },
  { key: "NM_001503.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2822", geneDisplay: "GPLD1" },
  { key: "NM_001504.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2833", geneDisplay: "CXCR3" },
  { key: "NM_001506.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2854", geneDisplay: "GPR32" },
  { key: "NM_001507.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2862", geneDisplay: "MLNR" },
  { key: "NM_001508.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2863", geneDisplay: "GPR39" },
  { key: "NM_001509.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2880", geneDisplay: "GPX5" },
  { key: "NM_001510.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2895", geneDisplay: "GRID2" },
  { key: "NM_001511.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2919", geneDisplay: "CXCL1" },
  { key: "NM_001512.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2941", geneDisplay: "GSTA4" },
  { key: "NM_001514.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2959", geneDisplay: "GTF2B" },
  { key: "NM_001515.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2966", geneDisplay: "GTF2H2" },
  { key: "NM_001516.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2967", geneDisplay: "GTF2H3" },
  { key: "NM_001517.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2968", geneDisplay: "GTF2H4" },
  { key: "NM_001519.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2972", geneDisplay: "BRF1" },
  { key: "NM_001520.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2975", geneDisplay: "GTF3C1" },
  { key: "NM_001522.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2986", geneDisplay: "GUCY2F" },
  { key: "NM_001524.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3060", geneDisplay: "HCRT" },
  { key: "NM_001525.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3061", geneDisplay: "HCRTR1" },
  { key: "NM_001527.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3066", geneDisplay: "HDAC2" },
  { key: "NM_001528.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3083", geneDisplay: "HGFAC" },
  { key: "NM_001530.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3091", geneDisplay: "HIF1A" },
  { key: "NM_001532.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3177", geneDisplay: "SLC29A2" },
  { key: "NM_001533.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3191", geneDisplay: "HNRNPL" },
  { key: "NM_001536.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3276", geneDisplay: "PRMT1" },
  { key: "NM_001537.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3281", geneDisplay: "HSBP1" },
  { key: "NM_001539.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3301", geneDisplay: "DNAJA1" },
  { key: "NM_001540.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3315", geneDisplay: "HSPB1" },
  { key: "NM_001541.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3316", geneDisplay: "HSPB2" },
  { key: "NM_001543.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3340", geneDisplay: "NDST1" },
  { key: "NM_001544.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3386", geneDisplay: "ICAM4" },
  { key: "NM_001545.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3396", geneDisplay: "MRPL58" },
  { key: "NM_001546.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3400", geneDisplay: "ID4" },
  { key: "NM_001547.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3433", geneDisplay: "IFIT2" },
  { key: "NM_001548.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3434", geneDisplay: "IFIT1" },
  { key: "NM_001549.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3437", geneDisplay: "IFIT3" },
  { key: "NM_001550.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3475", geneDisplay: "IFRD1" },
  { key: "NM_001551.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3476", geneDisplay: "IGBP1" },
  { key: "NM_001552.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3487", geneDisplay: "IGFBP4" },
  { key: "NM_001553.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3490", geneDisplay: "IGFBP7" },
  { key: "NM_001554.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3491", geneDisplay: "CCN1" },
  { key: "NM_001555.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3547", geneDisplay: "IGSF1" },
  { key: "NM_001556.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3551", geneDisplay: "IKBKB" },
  { key: "NM_001557.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3579", geneDisplay: "CXCR2" },
  { key: "NM_001558.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3587", geneDisplay: "IL10RA" },
  { key: "NM_001560.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3597", geneDisplay: "IL13RA1" },
  { key: "NM_001561.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3604", geneDisplay: "TNFRSF9" },
  { key: "NM_001562.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3606", geneDisplay: "IL18" },
  { key: "NM_001563.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3617", geneDisplay: "IMPG1" },
  { key: "NM_001564.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3622", geneDisplay: "ING2" },
  { key: "NM_001565.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3627", geneDisplay: "CXCL10" },
  { key: "NM_001567.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3636", geneDisplay: "INPPL1" },
  { key: "NM_001568.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3646", geneDisplay: "EIF3E" },
  { key: "NM_001569.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3654", geneDisplay: "IRAK1" },
  { key: "NM_001570.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3656", geneDisplay: "IRAK2" },
  { key: "NM_001571.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3661", geneDisplay: "IRF3" },
  { key: "NM_001572.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3665", geneDisplay: "IRF7" },
  { key: "NM_001584.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "744", geneDisplay: "MPPED2" },
  { key: "NM_001586.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1527", geneDisplay: "TEX28" },
  { key: "NM_001605.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "16", geneDisplay: "AARS1" },
  { key: "NM_001606.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "20", geneDisplay: "ABCA2" },
  { key: "NM_001607.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "30", geneDisplay: "ACAA1" },
  { key: "NM_001608.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "33", geneDisplay: "ACADL" },
  { key: "NM_001609.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "36", geneDisplay: "ACADSB" },
  { key: "NM_001610.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53", geneDisplay: "ACP2" },
  { key: "NM_001611.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54", geneDisplay: "ACP5" },
  { key: "NM_001612.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56", geneDisplay: "ACRV1" },
  { key: "NM_001613.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "59", geneDisplay: "ACTA2" },
  { key: "NM_001614.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "71", geneDisplay: "ACTG1" },
  { key: "NM_001615.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "72", geneDisplay: "ACTG2" },
  { key: "NM_001616.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92", geneDisplay: "ACVR2A" },
  { key: "NM_001617.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "119", geneDisplay: "ADD2" },
  { key: "NM_001618.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "142", geneDisplay: "PARP1" },
  { key: "NM_001619.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "156", geneDisplay: "GRK2" },
  { key: "NM_001620.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79026", geneDisplay: "AHNAK" },
  { key: "NM_001621.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "196", geneDisplay: "AHR" },
  { key: "NM_001622.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "197", geneDisplay: "AHSG" },
  { key: "NM_001623.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "199", geneDisplay: "AIF1" },
  { key: "NM_001625.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "204", geneDisplay: "AK2" },
  { key: "NM_001626.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "208", geneDisplay: "AKT2" },
  { key: "NM_001627.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "214", geneDisplay: "ALCAM" },
  { key: "NM_001628.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "231", geneDisplay: "AKR1B1" },
  { key: "NM_001629.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "241", geneDisplay: "ALOX5AP" },
  { key: "NM_001631.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "248", geneDisplay: "ALPI" },
  { key: "NM_001632.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "250", geneDisplay: "ALPP" },
  { key: "NM_001633.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "259", geneDisplay: "AMBP" },
  { key: "NM_001634.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "262", geneDisplay: "AMD1" },
  { key: "NM_001635.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "273", geneDisplay: "AMPH" },
  { key: "NM_001637.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "313", geneDisplay: "AOAH" },
  { key: "NM_001638.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "319", geneDisplay: "APOF" },
  { key: "NM_001639.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "325", geneDisplay: "APCS" },
  { key: "NM_001640.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "327", geneDisplay: "APEH" },
  { key: "NM_001641.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "328", geneDisplay: "APEX1" },
  { key: "NM_001643.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "336", geneDisplay: "APOA2" },
  { key: "NM_001644.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "339", geneDisplay: "APOBEC1" },
  { key: "NM_001645.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "341", geneDisplay: "APOC1" },
  { key: "NM_001646.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "346", geneDisplay: "APOC4" },
  { key: "NM_001647.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "347", geneDisplay: "APOD" },
  { key: "NM_001648.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "354", geneDisplay: "KLK3" },
  { key: "NM_001649.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "357", geneDisplay: "SHROOM2" },
  { key: "NM_001650.7", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "361", geneDisplay: "AQP4" },
  { key: "NM_001651.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "362", geneDisplay: "AQP5" },
  { key: "NM_001652.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "363", geneDisplay: "AQP6" },
  { key: "NM_001654.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "369", geneDisplay: "ARAF" },
  { key: "NM_001655.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "372", geneDisplay: "ARCN1" },
  { key: "NM_001656.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "373", geneDisplay: "TRIM23" },
  { key: "NM_001657.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "374", geneDisplay: "AREG" },
  { key: "NM_001658.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375", geneDisplay: "ARF1" },
  { key: "NM_001659.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "377", geneDisplay: "ARF3" },
  { key: "NM_001660.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "378", geneDisplay: "ARF4" },
  { key: "NM_001661.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "379", geneDisplay: "ARL4D" },
  { key: "NM_001662.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "381", geneDisplay: "ARF5" },
  { key: "NM_001663.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "382", geneDisplay: "ARF6" },
  { key: "NM_001664.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "387", geneDisplay: "RHOA" },
  { key: "NM_001665.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "391", geneDisplay: "RHOG" },
  { key: "NM_001666.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "393", geneDisplay: "ARHGAP4" },
  { key: "NM_001667.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "402", geneDisplay: "ARL2" },
  { key: "NM_001668.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "405", geneDisplay: "ARNT" },
  { key: "NM_001669.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "414", geneDisplay: "ARSD" },
  { key: "NM_001670.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "421", geneDisplay: "ARVCF" },
  { key: "NM_001671.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "432", geneDisplay: "ASGR1" },
  { key: "NM_001672.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "434", geneDisplay: "ASIP" },
  { key: "NM_001673.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "440", geneDisplay: "ASNS" },
  { key: "NM_001674.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "467", geneDisplay: "ATF3" },
  { key: "NM_001676.7", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "479", geneDisplay: "ATP12A" },
  { key: "NM_001677.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "481", geneDisplay: "ATP1B1" },
  { key: "NM_001678.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "482", geneDisplay: "ATP1B2" },
  { key: "NM_001679.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "483", geneDisplay: "ATP1B3" },
  { key: "NM_001680.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "486", geneDisplay: "FXYD2" },
  { key: "NM_001684.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "493", geneDisplay: "ATP2B4" },
  { key: "NM_001686.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "506", geneDisplay: "ATP5F1B" },
  { key: "NM_001687.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "513", geneDisplay: "ATP5F1D" },
  { key: "NM_001688.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "515", geneDisplay: "ATP5PB" },
  { key: "NM_001689.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "518", geneDisplay: "ATP5MC3" },
  { key: "NM_001690.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "523", geneDisplay: "ATP6V1A" },
  { key: "NM_001692.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "525", geneDisplay: "ATP6V1B1" },
  { key: "NM_001693.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "526", geneDisplay: "ATP6V1B2" },
  { key: "NM_001694.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "527", geneDisplay: "ATP6V0C" },
  { key: "NM_001695.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "528", geneDisplay: "ATP6V1C1" },
  { key: "NM_001696.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "529", geneDisplay: "ATP6V1E1" },
  { key: "NM_001697.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "539", geneDisplay: "ATP5PO" },
  { key: "NM_001698.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "549", geneDisplay: "AUH" },
  { key: "NM_001700.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "566", geneDisplay: "AZU1" },
  { key: "NM_001701.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "570", geneDisplay: "BAAT" },
  { key: "NM_001702.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "575", geneDisplay: "ADGRB1" },
  { key: "NM_001704.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "577", geneDisplay: "ADGRB3" },
  { key: "NM_001706.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "604", geneDisplay: "BCL6" },
  { key: "NM_001707.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9275", geneDisplay: "BCL7B" },
  { key: "NM_001709.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "627", geneDisplay: "BDNF" },
  { key: "NM_001710.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "629", geneDisplay: "CFB" },
  { key: "NM_001711.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "633", geneDisplay: "BGN" },
  { key: "NM_001712.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "634", geneDisplay: "CEACAM1" },
  { key: "NM_001713.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "635", geneDisplay: "BHMT" },
  { key: "NM_001714.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "636", geneDisplay: "BICD1" },
  { key: "NM_001715.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "640", geneDisplay: "BLK" },
  { key: "NM_001716.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643", geneDisplay: "CXCR5" },
  { key: "NM_001717.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "646", geneDisplay: "BNC1" },
  { key: "NM_001718.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "654", geneDisplay: "BMP6" },
  { key: "NM_001719.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "655", geneDisplay: "BMP7" },
  { key: "NM_001720.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "656", geneDisplay: "BMP8B" },
  { key: "NM_001722.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "661", geneDisplay: "POLR3D" },
  { key: "NM_001724.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "669", geneDisplay: "BPGM" },
  { key: "NM_001725.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "671", geneDisplay: "BPI" },
  { key: "NM_001727.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "680", geneDisplay: "BRS3" },
  { key: "NM_001728.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "682", geneDisplay: "BSG" },
  { key: "NM_001729.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "685", geneDisplay: "BTC" },
  { key: "NM_001730.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "688", geneDisplay: "KLF5" },
  { key: "NM_001731.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "694", geneDisplay: "BTG1" },
  { key: "NM_001732.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "696", geneDisplay: "BTN1A1" },
  { key: "NM_001733.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "715", geneDisplay: "C1R" },
  { key: "NM_001734.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "716", geneDisplay: "C1S" },
  { key: "NM_001735.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "727", geneDisplay: "C5" },
  { key: "NM_001736.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "728", geneDisplay: "C5AR1" },
  { key: "NM_001737.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "735", geneDisplay: "C9" },
  { key: "NM_001739.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "763", geneDisplay: "CA5A" },
  { key: "NM_001740.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "794", geneDisplay: "CALB2" },
  { key: "NM_001741.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "796", geneDisplay: "CALCA" },
  { key: "NM_001742.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "799", geneDisplay: "CALCR" },
  { key: "NM_001743.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "805", geneDisplay: "CALM2" },
  { key: "NM_001744.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "814", geneDisplay: "CAMK4" },
  { key: "NM_001745.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "819", geneDisplay: "CAMLG" },
  { key: "NM_001746.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "821", geneDisplay: "CANX" },
  { key: "NM_001747.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "822", geneDisplay: "CAPG" },
  { key: "NM_001748.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "824", geneDisplay: "CAPN2" },
  { key: "NM_001749.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "826", geneDisplay: "CAPNS1" },
  { key: "NM_001750.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "831", geneDisplay: "CAST" },
  { key: "NM_001752.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "847", geneDisplay: "CAT" },
  { key: "NM_001753.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "857", geneDisplay: "CAV1" },
  { key: "NM_001754.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "861", geneDisplay: "RUNX1" },
  { key: "NM_001756.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "866", geneDisplay: "SERPINA6" },
  { key: "NM_001757.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "873", geneDisplay: "CBR1" },
  { key: "NM_001759.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "894", geneDisplay: "CCND2" },
  { key: "NM_001760.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "896", geneDisplay: "CCND3" },
  { key: "NM_001761.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "899", geneDisplay: "CCNF" },
  { key: "NM_001762.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "908", geneDisplay: "CCT6A" },
  { key: "NM_001763.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "909", geneDisplay: "CD1A" },
  { key: "NM_001764.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "910", geneDisplay: "CD1B" },
  { key: "NM_001765.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "911", geneDisplay: "CD1C" },
  { key: "NM_001767.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "914", geneDisplay: "CD2" },
  { key: "NM_001768.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "925", geneDisplay: "CD8A" },
  { key: "NM_001769.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "928", geneDisplay: "CD9" },
  { key: "NM_001770.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "930", geneDisplay: "CD19" },
  { key: "NM_001771.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "933", geneDisplay: "CD22" },
  { key: "NM_001772.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "945", geneDisplay: "CD33" },
  { key: "NM_001774.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "951", geneDisplay: "CD37" },
  { key: "NM_001775.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "952", geneDisplay: "CD38" },
  { key: "NM_001776.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "953", geneDisplay: "ENTPD1" },
  { key: "NM_001777.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "961", geneDisplay: "CD47" },
  { key: "NM_001778.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "962", geneDisplay: "CD48" },
  { key: "NM_001779.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "965", geneDisplay: "CD58" },
  { key: "NM_001780.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "967", geneDisplay: "CD63" },
  { key: "NM_001781.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "969", geneDisplay: "CD69" },
  { key: "NM_001782.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "971", geneDisplay: "CD72" },
  { key: "NM_001783.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "973", geneDisplay: "CD79A" },
  { key: "NM_001785.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "978", geneDisplay: "CDA" },
  { key: "NM_001786.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "983", geneDisplay: "CDK1" },
  { key: "NM_001788.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "989", geneDisplay: "SEPTIN7" },
  { key: "NM_001789.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "993", geneDisplay: "CDC25A" },
  { key: "NM_001790.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "995", geneDisplay: "CDC25C" },
  { key: "NM_001791.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "998", geneDisplay: "CDC42" },
  { key: "NM_001792.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1000", geneDisplay: "CDH2" },
  { key: "NM_001793.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1001", geneDisplay: "CDH3" },
  { key: "NM_001794.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1002", geneDisplay: "CDH4" },
  { key: "NM_001795.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1003", geneDisplay: "CDH5" },
  { key: "NM_001796.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1006", geneDisplay: "CDH8" },
  { key: "NM_001797.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1009", geneDisplay: "CDH11" },
  { key: "NM_001798.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1017", geneDisplay: "CDK2" },
  { key: "NM_001799.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1022", geneDisplay: "CDK7" },
  { key: "NM_001800.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1032", geneDisplay: "CDKN2D" },
  { key: "NM_001801.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1036", geneDisplay: "CDO1" },
  { key: "NM_001802.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1039", geneDisplay: "CDR2" },
  { key: "NM_001803.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1043", geneDisplay: "CD52" },
  { key: "NM_001804.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1044", geneDisplay: "CDX1" },
  { key: "NM_001805.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1053", geneDisplay: "CEBPE" },
  { key: "NM_001806.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1054", geneDisplay: "CEBPG" },
  { key: "NM_001807.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1056", geneDisplay: "CEL" },
  { key: "NM_001809.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1058", geneDisplay: "CENPA" },
  { key: "NM_001810.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1059", geneDisplay: "CENPB" },
  { key: "NM_001812.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1060", geneDisplay: "CENPC" },
  { key: "NM_001813.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1062", geneDisplay: "CENPE" },
  { key: "NM_001814.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1075", geneDisplay: "CTSC" },
  { key: "NM_001815.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1084", geneDisplay: "CEACAM3" },
  { key: "NM_001816.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1088", geneDisplay: "CEACAM8" },
  { key: "NM_001817.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1089", geneDisplay: "CEACAM4" },
  { key: "NM_001818.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1109", geneDisplay: "AKR1C4" },
  { key: "NM_001819.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1114", geneDisplay: "CHGB" },
  { key: "NM_001822.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1123", geneDisplay: "CHN1" },
  { key: "NM_001823.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1152", geneDisplay: "CKB" },
  { key: "NM_001824.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1158", geneDisplay: "CKM" },
  { key: "NM_001826.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1163", geneDisplay: "CKS1B" },
  { key: "NM_001827.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1164", geneDisplay: "CKS2" },
  { key: "NM_001828.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1178", geneDisplay: "CLC" },
  { key: "NM_001829.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1182", geneDisplay: "CLCN3" },
  { key: "NM_001830.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1183", geneDisplay: "CLCN4" },
  { key: "NM_001831.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1191", geneDisplay: "CLU" },
  { key: "NM_001832.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1208", geneDisplay: "CLPS" },
  { key: "NM_001833.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1211", geneDisplay: "CLTA" },
  { key: "NM_001836.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1215", geneDisplay: "CMA1" },
  { key: "NM_001838.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1236", geneDisplay: "CCR7" },
  { key: "NM_001839.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1266", geneDisplay: "CNN3" },
  { key: "NM_001841.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1269", geneDisplay: "CNR2" },
  { key: "NM_001843.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1272", geneDisplay: "CNTN1" },
  { key: "NM_001844.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1280", geneDisplay: "COL2A1" },
  { key: "NM_001845.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1282", geneDisplay: "COL4A1" },
  { key: "NM_001846.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1284", geneDisplay: "COL4A2" },
  { key: "NM_001848.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1291", geneDisplay: "COL6A1" },
  { key: "NM_001849.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1292", geneDisplay: "COL6A2" },
  { key: "NM_001851.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1297", geneDisplay: "COL9A1" },
  { key: "NM_001852.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1298", geneDisplay: "COL9A2" },
  { key: "NM_001853.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1299", geneDisplay: "COL9A3" },
  { key: "NM_001854.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1301", geneDisplay: "COL11A1" },
  { key: "NM_001855.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1306", geneDisplay: "COL15A1" },
  { key: "NM_001856.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1307", geneDisplay: "COL16A1" },
  { key: "NM_001858.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1310", geneDisplay: "COL19A1" },
  { key: "NM_001859.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1317", geneDisplay: "SLC31A1" },
  { key: "NM_001860.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1318", geneDisplay: "SLC31A2" },
  { key: "NM_001861.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1327", geneDisplay: "COX4I1" },
  { key: "NM_001862.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1329", geneDisplay: "COX5B" },
  { key: "NM_001863.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1340", geneDisplay: "COX6B1" },
  { key: "NM_001864.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1346", geneDisplay: "COX7A1" },
  { key: "NM_001866.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1349", geneDisplay: "COX7B" },
  { key: "NM_001867.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1350", geneDisplay: "COX7C" },
  { key: "NM_001868.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1357", geneDisplay: "CPA1" },
  { key: "NM_001869.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1358", geneDisplay: "CPA2" },
  { key: "NM_001870.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1359", geneDisplay: "CPA3" },
  { key: "NM_001871.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1360", geneDisplay: "CPB1" },
  { key: "NM_001872.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1361", geneDisplay: "CPB2" },
  { key: "NM_001873.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1363", geneDisplay: "CPE" },
  { key: "NM_001875.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1373", geneDisplay: "CPS1" },
  { key: "NM_001876.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1374", geneDisplay: "CPT1A" },
  { key: "NM_001878.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1382", geneDisplay: "CRABP2" },
  { key: "NM_001880.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1386", geneDisplay: "ATF2" },
  { key: "NM_001882.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1393", geneDisplay: "CRHBP" },
  { key: "NM_001883.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1395", geneDisplay: "CRHR2" },
  { key: "NM_001884.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1404", geneDisplay: "HAPLN1" },
  { key: "NM_001886.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1413", geneDisplay: "CRYBA4" },
  { key: "NM_001887.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1414", geneDisplay: "CRYBB1" },
  { key: "NM_001889.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1429", geneDisplay: "CRYZ" },
  { key: "NM_001890.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1446", geneDisplay: "CSN1S1" },
  { key: "NM_001891.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1447", geneDisplay: "CSN2" },
  { key: "NM_001892.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1452", geneDisplay: "CSNK1A1" },
  { key: "NM_001893.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1453", geneDisplay: "CSNK1D" },
  { key: "NM_001896.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1459", geneDisplay: "CSNK2A2" },
  { key: "NM_001897.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1464", geneDisplay: "CSPG4" },
  { key: "NM_001898.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1469", geneDisplay: "CST1" },
  { key: "NM_001899.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1472", geneDisplay: "CST4" },
  { key: "NM_001900.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1473", geneDisplay: "CST5" },
  { key: "NM_001901.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1490", geneDisplay: "CCN2" },
  { key: "NM_001902.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1491", geneDisplay: "CTH" },
  { key: "NM_001903.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1495", geneDisplay: "CTNNA1" },
  { key: "NM_001904.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1499", geneDisplay: "CTNNB1" },
  { key: "NM_001905.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1503", geneDisplay: "CTPS1" },
  { key: "NM_001906.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1504", geneDisplay: "CTRB1" },
  { key: "NM_001907.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1506", geneDisplay: "CTRL" },
  { key: "NM_001908.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1508", geneDisplay: "CTSB" },
  { key: "NM_001909.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1509", geneDisplay: "CTSD" },
  { key: "NM_001910.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1510", geneDisplay: "CTSE" },
  { key: "NM_001911.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1511", geneDisplay: "CTSG" },
  { key: "NM_001912.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1514", geneDisplay: "CTSL" },
  { key: "NM_001915.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1534", geneDisplay: "CYB561" },
  { key: "NM_001916.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1537", geneDisplay: "CYC1" },
  { key: "NM_001917.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1610", geneDisplay: "DAO" },
  { key: "NM_001918.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1629", geneDisplay: "DBT" },
  { key: "NM_001919.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1632", geneDisplay: "ECI1" },
  { key: "NM_001920.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1634", geneDisplay: "DCN" },
  { key: "NM_001921.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1635", geneDisplay: "DCTD" },
  { key: "NM_001922.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1638", geneDisplay: "DCT" },
  { key: "NM_001923.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1642", geneDisplay: "DDB1" },
  { key: "NM_001924.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1647", geneDisplay: "GADD45A" },
  { key: "NM_001925.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1669", geneDisplay: "DEFA4" },
  { key: "NM_001926.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1671", geneDisplay: "DEFA6" },
  { key: "NM_001927.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1674", geneDisplay: "DES" },
  { key: "NM_001928.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1675", geneDisplay: "CFD" },
  { key: "NM_001930.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1725", geneDisplay: "DHPS" },
  { key: "NM_001931.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1737", geneDisplay: "DLAT" },
  { key: "NM_001932.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4356", geneDisplay: "MPP3" },
  { key: "NM_001933.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1743", geneDisplay: "DLST" },
  { key: "NM_001935.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1803", geneDisplay: "DPP4" },
  { key: "NM_001937.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1805", geneDisplay: "DPT" },
  { key: "NM_001938.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1810", geneDisplay: "DR1" },
  { key: "NM_001939.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1821", geneDisplay: "DRP2" },
  { key: "NM_001940.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1822", geneDisplay: "ATN1" },
  { key: "NM_001941.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1825", geneDisplay: "DSC3" },
  { key: "NM_001942.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1828", geneDisplay: "DSG1" },
  { key: "NM_001943.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1829", geneDisplay: "DSG2" },
  { key: "NM_001944.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1830", geneDisplay: "DSG3" },
  { key: "NM_001945.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1839", geneDisplay: "HBEGF" },
  { key: "NM_001946.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1848", geneDisplay: "DUSP6" },
  { key: "NM_001947.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1849", geneDisplay: "DUSP7" },
  { key: "NM_001949.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1871", geneDisplay: "E2F3" },
  { key: "NM_001950.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1874", geneDisplay: "E2F4" },
  { key: "NM_001951.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1875", geneDisplay: "E2F5" },
  { key: "NM_001953.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1890", geneDisplay: "TYMP" },
  { key: "NM_001955.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1906", geneDisplay: "EDN1" },
  { key: "NM_001956.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1907", geneDisplay: "EDN2" },
  { key: "NM_001957.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1909", geneDisplay: "EDNRA" },
  { key: "NM_001958.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1917", geneDisplay: "EEF1A2" },
  { key: "NM_001959.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1933", geneDisplay: "EEF1B2" },
  { key: "NM_001961.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1938", geneDisplay: "EEF2" },
  { key: "NM_001962.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1946", geneDisplay: "EFNA5" },
  { key: "NM_001963.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1950", geneDisplay: "EGF" },
  { key: "NM_001964.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1958", geneDisplay: "EGR1" },
  { key: "NM_001965.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1961", geneDisplay: "EGR4" },
  { key: "NM_001966.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1962", geneDisplay: "EHHADH" },
  { key: "NM_001967.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1974", geneDisplay: "EIF4A2" },
  { key: "NM_001968.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1977", geneDisplay: "EIF4E" },
  { key: "NM_001969.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1983", geneDisplay: "EIF5" },
  { key: "NM_001970.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1984", geneDisplay: "EIF5A" },
  { key: "NM_001971.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1990", geneDisplay: "CELA1" },
  { key: "NM_001972.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1991", geneDisplay: "ELANE" },
  { key: "NM_001973.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2005", geneDisplay: "ELK4" },
  { key: "NM_001974.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2015", geneDisplay: "ADGRE1" },
  { key: "NM_001975.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2026", geneDisplay: "ENO2" },
  { key: "NM_001977.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2028", geneDisplay: "ENPEP" },
  { key: "NM_001979.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2053", geneDisplay: "EPHX2" },
  { key: "NM_001981.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2060", geneDisplay: "EPS15" },
  { key: "NM_001982.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2065", geneDisplay: "ERBB3" },
  { key: "NM_001983.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2067", geneDisplay: "ERCC1" },
  { key: "NM_001984.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2098", geneDisplay: "ESD" },
  { key: "NM_001985.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2109", geneDisplay: "ETFB" },
  { key: "NM_001987.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2120", geneDisplay: "ETV6" },
  { key: "NM_001988.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2125", geneDisplay: "EVPL" },
  { key: "NM_001989.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2128", geneDisplay: "EVX1" },
  { key: "NM_001990.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2140", geneDisplay: "EYA3" },
  { key: "NM_001991.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2145", geneDisplay: "EZH1" },
  { key: "NM_001992.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2149", geneDisplay: "F2R" },
  { key: "NM_001993.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2152", geneDisplay: "F3" },
  { key: "NM_001994.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2165", geneDisplay: "F13B" },
  { key: "NM_001995.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2180", geneDisplay: "ACSL1" },
  { key: "NM_001997.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2197", geneDisplay: "FAU" },
  { key: "NM_001999.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2201", geneDisplay: "FBN2" },
  { key: "NM_002000.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2204", geneDisplay: "FCAR" },
  { key: "NM_002003.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2219", geneDisplay: "FCN1" },
  { key: "NM_002004.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2224", geneDisplay: "FDPS" },
  { key: "NM_002005.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2242", geneDisplay: "FES" },
  { key: "NM_002007.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2249", geneDisplay: "FGF4" },
  { key: "NM_002009.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2252", geneDisplay: "FGF7" },
  { key: "NM_002010.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2254", geneDisplay: "FGF9" },
  { key: "NM_002012.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2272", geneDisplay: "FHIT" },
  { key: "NM_002013.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2287", geneDisplay: "FKBP3" },
  { key: "NM_002014.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2288", geneDisplay: "FKBP4" },
  { key: "NM_002015.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2308", geneDisplay: "FOXO1" },
  { key: "NM_002016.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2312", geneDisplay: "FLG" },
  { key: "NM_002017.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2313", geneDisplay: "FLI1" },
  { key: "NM_002018.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2314", geneDisplay: "FLII" },
  { key: "NM_002019.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2321", geneDisplay: "FLT1" },
  { key: "NM_002022.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2329", geneDisplay: "FMO4" },
  { key: "NM_002023.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2331", geneDisplay: "FMOD" },
  { key: "NM_002024.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2332", geneDisplay: "FMR1" },
  { key: "NM_002025.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2334", geneDisplay: "AFF2" },
  { key: "NM_002027.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2339", geneDisplay: "FNTA" },
  { key: "NM_002028.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2342", geneDisplay: "FNTB" },
  { key: "NM_002029.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2357", geneDisplay: "FPR1" },
  { key: "NM_002030.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2359", geneDisplay: "FPR3" },
  { key: "NM_002031.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2444", geneDisplay: "FRK" },
  { key: "NM_002032.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2495", geneDisplay: "FTH1" },
  { key: "NM_002033.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2526", geneDisplay: "FUT4" },
  { key: "NM_002034.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2527", geneDisplay: "FUT5" },
  { key: "NM_002035.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2531", geneDisplay: "KDSR" },
  { key: "NM_002036.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2532", geneDisplay: "ACKR1" },
  { key: "NM_002037.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2534", geneDisplay: "FYN" },
  { key: "NM_002038.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2537", geneDisplay: "IFI6" },
  { key: "NM_002039.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2549", geneDisplay: "GAB1" },
  { key: "NM_002040.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "2551", geneDisplay: "GABPA" },
  { key: "NM_002042.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2569", geneDisplay: "GABRR1" },
  { key: "NM_002043.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2570", geneDisplay: "GABRR2" },
  { key: "NM_002044.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2585", geneDisplay: "GALK2" },
  { key: "NM_002045.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2596", geneDisplay: "GAP43" },
  { key: "NM_002046.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2597", geneDisplay: "GAPDH" },
  { key: "NM_002047.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2617", geneDisplay: "GARS1" },
  { key: "NM_002048.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2619", geneDisplay: "GAS1" },
  { key: "NM_002049.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2623", geneDisplay: "GATA1" },
  { key: "NM_002053.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2633", geneDisplay: "GBP1" },
  { key: "NM_002054.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2641", geneDisplay: "GCG" },
  { key: "NM_002055.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2670", geneDisplay: "GFAP" },
  { key: "NM_002059.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2689", geneDisplay: "GH2" },
  { key: "NM_002060.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2701", geneDisplay: "GJA4" },
  { key: "NM_002061.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2730", geneDisplay: "GCLM" },
  { key: "NM_002062.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2740", geneDisplay: "GLP1R" },
  { key: "NM_002063.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2742", geneDisplay: "GLRA2" },
  { key: "NM_002066.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2765", geneDisplay: "GML" },
  { key: "NM_002067.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2767", geneDisplay: "GNA11" },
  { key: "NM_002068.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2769", geneDisplay: "GNA15" },
  { key: "NM_002069.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2770", geneDisplay: "GNAI1" },
  { key: "NM_002070.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2771", geneDisplay: "GNAI2" },
  { key: "NM_002072.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2776", geneDisplay: "GNAQ" },
  { key: "NM_002073.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2781", geneDisplay: "GNAZ" },
  { key: "NM_002074.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2782", geneDisplay: "GNB1" },
  { key: "NM_002075.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2784", geneDisplay: "GNB3" },
  { key: "NM_002076.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2799", geneDisplay: "GNS" },
  { key: "NM_002077.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2800", geneDisplay: "GOLGA1" },
  { key: "NM_002078.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2803", geneDisplay: "GOLGA4" },
  { key: "NM_002079.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2805", geneDisplay: "GOT1" },
  { key: "NM_002080.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2806", geneDisplay: "GOT2" },
  { key: "NM_002081.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2817", geneDisplay: "GPC1" },
  { key: "NM_002083.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2877", geneDisplay: "GPX2" },
  { key: "NM_002084.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2878", geneDisplay: "GPX3" },
  { key: "NM_002085.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2879", geneDisplay: "GPX4" },
  { key: "NM_002086.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2885", geneDisplay: "GRB2" },
  { key: "NM_002087.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2896", geneDisplay: "GRN" },
  { key: "NM_002088.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2901", geneDisplay: "GRIK5" },
  { key: "NM_002089.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2920", geneDisplay: "CXCL2" },
  { key: "NM_002090.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2921", geneDisplay: "CXCL3" },
  { key: "NM_002091.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2922", geneDisplay: "GRP" },
  { key: "NM_002092.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2926", geneDisplay: "GRSF1" },
  { key: "NM_002094.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2935", geneDisplay: "GSPT1" },
  { key: "NM_002095.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2961", geneDisplay: "GTF2E2" },
  { key: "NM_002096.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2962", geneDisplay: "GTF2F1" },
  { key: "NM_002097.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2971", geneDisplay: "GTF3A" },
  { key: "NM_002098.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2979", geneDisplay: "GUCA1B" },
  { key: "NM_002099.8", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2993", geneDisplay: "GYPA" },
  { key: "NM_002100.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2994", geneDisplay: "GYPB" },
  { key: "NM_002101.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2995", geneDisplay: "GYPC" },
  { key: "NM_002103.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2997", geneDisplay: "GYS1" },
  { key: "NM_002104.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3003", geneDisplay: "GZMK" },
  { key: "NM_002105.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3014", geneDisplay: "H2AX" },
  { key: "NM_002106.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3015", geneDisplay: "H2AZ1" },
  { key: "NM_002107.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3020", geneDisplay: "H3-3A" },
  { key: "NM_002108.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3034", geneDisplay: "HAL" },
  { key: "NM_002109.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3035", geneDisplay: "HARS1" },
  { key: "NM_002110.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3055", geneDisplay: "HCK" },
  { key: "NM_002112.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3067", geneDisplay: "HDC" },
  { key: "NM_002113.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3078", geneDisplay: "CFHR1" },
  { key: "NM_002114.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3096", geneDisplay: "HIVEP1" },
  { key: "NM_002115.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3101", geneDisplay: "HK3" },
  { key: "NM_002116.8", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3105", geneDisplay: "HLA-A" },
  { key: "NM_002117.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3107", geneDisplay: "HLA-C" },
  { key: "NM_002118.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3109", geneDisplay: "HLA-DMB" },
  { key: "NM_002119.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3111", geneDisplay: "HLA-DOA" },
  { key: "NM_002120.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3112", geneDisplay: "HLA-DOB" },
  { key: "NM_002121.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3115", geneDisplay: "HLA-DPB1" },
  { key: "NM_002122.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3117", geneDisplay: "HLA-DQA1" },
  { key: "NM_002123.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3119", geneDisplay: "HLA-DQB1" },
  { key: "NM_002124.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3123", geneDisplay: "HLA-DRB1" },
  { key: "NM_002125.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3127", geneDisplay: "HLA-DRB5" },
  { key: "NM_002126.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3131", geneDisplay: "HLF" },
  { key: "NM_002128.7", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3146", geneDisplay: "HMGB1" },
  { key: "NM_002129.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3148", geneDisplay: "HMGB2" },
  { key: "NM_002133.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3162", geneDisplay: "HMOX1" },
  { key: "NM_002134.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3163", geneDisplay: "HMOX2" },
  { key: "NM_002137.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3181", geneDisplay: "HNRNPA2B1" },
  { key: "NM_002139.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27316", geneDisplay: "RBMX" },
  { key: "NM_002141.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3201", geneDisplay: "HOXA4" },
  { key: "NM_002143.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3208", geneDisplay: "HPCA" },
  { key: "NM_002144.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3211", geneDisplay: "HOXB1" },
  { key: "NM_002145.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3212", geneDisplay: "HOXB2" },
  { key: "NM_002147.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3215", geneDisplay: "HOXB5" },
  { key: "NM_002148.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3236", geneDisplay: "HOXD10" },
  { key: "NM_002149.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3241", geneDisplay: "HPCAL1" },
  { key: "NM_002150.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3242", geneDisplay: "HPD" },
  { key: "NM_002152.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3270", geneDisplay: "HRC" },
  { key: "NM_002153.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3294", geneDisplay: "HSD17B2" },
  { key: "NM_002154.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3308", geneDisplay: "HSPA4" },
  { key: "NM_002155.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3310", geneDisplay: "HSPA6" },
  { key: "NM_002156.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3329", geneDisplay: "HSPD1" },
  { key: "NM_002157.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3336", geneDisplay: "HSPE1" },
  { key: "NM_002158.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3344", geneDisplay: "FOXN2" },
  { key: "NM_002159.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3346", geneDisplay: "HTN1" },
  { key: "NM_002160.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3371", geneDisplay: "TNC" },
  { key: "NM_002161.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3376", geneDisplay: "IARS1" },
  { key: "NM_002162.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3385", geneDisplay: "ICAM3" },
  { key: "NM_002163.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3394", geneDisplay: "IRF8" },
  { key: "NM_002164.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3620", geneDisplay: "IDO1" },
  { key: "NM_002165.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3397", geneDisplay: "ID1" },
  { key: "NM_002166.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3398", geneDisplay: "ID2" },
  { key: "NM_002167.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3399", geneDisplay: "ID3" },
  { key: "NM_002168.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3418", geneDisplay: "IDH2" },
  { key: "NM_002169.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3442", geneDisplay: "IFNA5" },
  { key: "NM_002170.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3445", geneDisplay: "IFNA8" },
  { key: "NM_002171.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3446", geneDisplay: "IFNA10" },
  { key: "NM_002172.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3448", geneDisplay: "IFNA14" },
  { key: "NM_002173.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3449", geneDisplay: "IFNA16" },
  { key: "NM_002175.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3452", geneDisplay: "IFNA21" },
  { key: "NM_002176.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3456", geneDisplay: "IFNB1" },
  { key: "NM_002177.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3467", geneDisplay: "IFNW1" },
  { key: "NM_002178.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3489", geneDisplay: "IGFBP6" },
  { key: "NM_002180.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3508", geneDisplay: "IGHMBP2" },
  { key: "NM_002181.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3549", geneDisplay: "IHH" },
  { key: "NM_002182.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3556", geneDisplay: "IL1RAP" },
  { key: "NM_002184.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3572", geneDisplay: "IL6ST" },
  { key: "NM_002185.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3575", geneDisplay: "IL7R" },
  { key: "NM_002187.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3593", geneDisplay: "IL12B" },
  { key: "NM_002188.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3596", geneDisplay: "IL13" },
  { key: "NM_002189.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3601", geneDisplay: "IL15RA" },
  { key: "NM_002190.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3605", geneDisplay: "IL17A" },
  { key: "NM_002191.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3623", geneDisplay: "INHA" },
  { key: "NM_002192.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3624", geneDisplay: "INHBA" },
  { key: "NM_002193.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3625", geneDisplay: "INHBB" },
  { key: "NM_002195.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3641", geneDisplay: "INSL4" },
  { key: "NM_002196.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3642", geneDisplay: "INSM1" },
  { key: "NM_002197.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "48", geneDisplay: "ACO1" },
  { key: "NM_002198.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3659", geneDisplay: "IRF1" },
  { key: "NM_002199.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3660", geneDisplay: "IRF2" },
  { key: "NM_002201.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3669", geneDisplay: "ISG20" },
  { key: "NM_002202.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3670", geneDisplay: "ISL1" },
  { key: "NM_002203.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3673", geneDisplay: "ITGA2" },
  { key: "NM_002204.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3675", geneDisplay: "ITGA3" },
  { key: "NM_002205.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3678", geneDisplay: "ITGA5" },
  { key: "NM_002206.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3679", geneDisplay: "ITGA7" },
  { key: "NM_002207.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3680", geneDisplay: "ITGA9" },
  { key: "NM_002208.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3682", geneDisplay: "ITGAE" },
  { key: "NM_002209.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3683", geneDisplay: "ITGAL" },
  { key: "NM_002210.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3685", geneDisplay: "ITGAV" },
  { key: "NM_002211.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3688", geneDisplay: "ITGB1" },
  { key: "NM_002212.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3692", geneDisplay: "EIF6" },
  { key: "NM_002213.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3693", geneDisplay: "ITGB5" },
  { key: "NM_002214.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3696", geneDisplay: "ITGB8" },
  { key: "NM_002215.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3697", geneDisplay: "ITIH1" },
  { key: "NM_002216.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3698", geneDisplay: "ITIH2" },
  { key: "NM_002217.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3699", geneDisplay: "ITIH3" },
  { key: "NM_002218.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3700", geneDisplay: "ITIH4" },
  { key: "NM_002220.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3706", geneDisplay: "ITPKA" },
  { key: "NM_002221.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3707", geneDisplay: "ITPKB" },
  { key: "NM_002223.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3709", geneDisplay: "ITPR2" },
  { key: "NM_002224.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3710", geneDisplay: "ITPR3" },
  { key: "NM_002225.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3712", geneDisplay: "IVD" },
  { key: "NM_002226.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3714", geneDisplay: "JAG2" },
  { key: "NM_002227.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3716", geneDisplay: "JAK1" },
  { key: "NM_002228.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3725", geneDisplay: "JUN" },
  { key: "NM_002229.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3726", geneDisplay: "JUNB" },
  { key: "NM_002230.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3728", geneDisplay: "JUP" },
  { key: "NM_002231.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3732", geneDisplay: "CD82" },
  { key: "NM_002232.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3738", geneDisplay: "KCNA3" },
  { key: "NM_002233.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3739", geneDisplay: "KCNA4" },
  { key: "NM_002234.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3741", geneDisplay: "KCNA5" },
  { key: "NM_002235.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3742", geneDisplay: "KCNA6" },
  { key: "NM_002236.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3754", geneDisplay: "KCNF1" },
  { key: "NM_002237.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3755", geneDisplay: "KCNG1" },
  { key: "NM_002239.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3760", geneDisplay: "KCNJ3" },
  { key: "NM_002240.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3763", geneDisplay: "KCNJ6" },
  { key: "NM_002241.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3766", geneDisplay: "KCNJ10" },
  { key: "NM_002242.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3769", geneDisplay: "KCNJ13" },
  { key: "NM_002245.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3775", geneDisplay: "KCNK1" },
  { key: "NM_002246.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3777", geneDisplay: "KCNK3" },
  { key: "NM_002249.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3782", geneDisplay: "KCNN3" },
  { key: "NM_002250.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3783", geneDisplay: "KCNN4" },
  { key: "NM_002252.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3790", geneDisplay: "KCNS3" },
  { key: "NM_002253.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3791", geneDisplay: "KDR" },
  { key: "NM_002254.8", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3797", geneDisplay: "KIF3C" },
  { key: "NM_002256.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3814", geneDisplay: "KISS1" },
  { key: "NM_002257.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3816", geneDisplay: "KLK1" },
  { key: "NM_002258.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3820", geneDisplay: "KLRB1" },
  { key: "NM_002259.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3821", geneDisplay: "KLRC1" },
  { key: "NM_002260.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3822", geneDisplay: "KLRC2" },
  { key: "NM_002261.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3823", geneDisplay: "KLRC3" },
  { key: "NM_002262.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3824", geneDisplay: "KLRD1" },
  { key: "NM_002263.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3833", geneDisplay: "KIFC1" },
  { key: "NM_002264.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3836", geneDisplay: "KPNA1" },
  { key: "NM_002265.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3837", geneDisplay: "KPNB1" },
  { key: "NM_002266.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3838", geneDisplay: "KPNA2" },
  { key: "NM_002267.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3839", geneDisplay: "KPNA3" },
  { key: "NM_002268.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3840", geneDisplay: "KPNA4" },
  { key: "NM_002270.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3842", geneDisplay: "TNPO1" },
  { key: "NM_002271.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3843", geneDisplay: "IPO5" },
  { key: "NM_002272.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3851", geneDisplay: "KRT4" },
  { key: "NM_002273.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3856", geneDisplay: "KRT8" },
  { key: "NM_002275.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3866", geneDisplay: "KRT15" },
  { key: "NM_002276.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3880", geneDisplay: "KRT19" },
  { key: "NM_002277.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3881", geneDisplay: "KRT31" },
  { key: "NM_002278.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3882", geneDisplay: "KRT32" },
  { key: "NM_002279.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3884", geneDisplay: "KRT33B" },
  { key: "NM_002280.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3886", geneDisplay: "KRT35" },
  { key: "NM_002281.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3887", geneDisplay: "KRT81" },
  { key: "NM_002282.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3889", geneDisplay: "KRT83" },
  { key: "NM_002283.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3891", geneDisplay: "KRT85" },
  { key: "NM_002286.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3902", geneDisplay: "LAG3" },
  { key: "NM_002287.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3903", geneDisplay: "LAIR1" },
  { key: "NM_002288.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3904", geneDisplay: "LAIR2" },
  { key: "NM_002289.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3906", geneDisplay: "LALBA" },
  { key: "NM_002291.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3912", geneDisplay: "LAMB1" },
  { key: "NM_002292.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3913", geneDisplay: "LAMB2" },
  { key: "NM_002293.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3915", geneDisplay: "LAMC1" },
  { key: "NM_002294.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3920", geneDisplay: "LAMP2" },
  { key: "NM_002295.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3921", geneDisplay: "RPSA" },
  { key: "NM_002296.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3930", geneDisplay: "LBR" },
  { key: "NM_002297.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3933", geneDisplay: "LCN1" },
  { key: "NM_002298.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3936", geneDisplay: "LCP1" },
  { key: "NM_002299.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3938", geneDisplay: "LCT" },
  { key: "NM_002300.8", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3945", geneDisplay: "LDHB" },
  { key: "NM_002302.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3950", geneDisplay: "LECT2" },
  { key: "NM_002303.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3953", geneDisplay: "LEPR" },
  { key: "NM_002305.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3956", geneDisplay: "LGALS1" },
  { key: "NM_002306.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3958", geneDisplay: "LGALS3" },
  { key: "NM_002307.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3963", geneDisplay: "LGALS7" },
  { key: "NM_002309.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3976", geneDisplay: "LIF" },
  { key: "NM_002313.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3983", geneDisplay: "ABLIM1" },
  { key: "NM_002314.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3984", geneDisplay: "LIMK1" },
  { key: "NM_002315.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4004", geneDisplay: "LMO1" },
  { key: "NM_002317.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4015", geneDisplay: "LOX" },
  { key: "NM_002318.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4017", geneDisplay: "LOXL2" },
  { key: "NM_002319.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4034", geneDisplay: "LRCH4" },
  { key: "NM_002332.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4035", geneDisplay: "LRP1" },
  { key: "NM_002333.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4037", geneDisplay: "LRP3" },
  { key: "NM_002334.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4038", geneDisplay: "LRP4" },
  { key: "NM_002335.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4041", geneDisplay: "LRP5" },
  { key: "NM_002336.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4040", geneDisplay: "LRP6" },
  { key: "NM_002337.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4043", geneDisplay: "LRPAP1" },
  { key: "NM_002338.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4045", geneDisplay: "LSAMP" },
  { key: "NM_002339.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4046", geneDisplay: "LSP1" },
  { key: "NM_002340.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "4047", geneDisplay: "LSS" },
  { key: "NM_002341.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4050", geneDisplay: "LTB" },
  { key: "NM_002342.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4055", geneDisplay: "LTBR" },
  { key: "NM_002343.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4057", geneDisplay: "LTF" },
  { key: "NM_002344.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4058", geneDisplay: "LTK" },
  { key: "NM_002345.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4060", geneDisplay: "LUM" },
  { key: "NM_002346.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4061", geneDisplay: "LY6E" },
  { key: "NM_002348.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4063", geneDisplay: "LY9" },
  { key: "NM_002349.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4065", geneDisplay: "LY75" },
  { key: "NM_002350.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4067", geneDisplay: "LYN" },
  { key: "NM_002351.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4068", geneDisplay: "SH2D1A" },
  { key: "NM_002353.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4070", geneDisplay: "TACSTD2" },
  { key: "NM_002354.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4072", geneDisplay: "EPCAM" },
  { key: "NM_002355.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4074", geneDisplay: "M6PR" },
  { key: "NM_002356.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4082", geneDisplay: "MARCKS" },
  { key: "NM_002357.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4084", geneDisplay: "MXD1" },
  { key: "NM_002358.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4085", geneDisplay: "MAD2L1" },
  { key: "NM_002359.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4097", geneDisplay: "MAFG" },
  { key: "NM_002360.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7975", geneDisplay: "MAFK" },
  { key: "NM_002361.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4099", geneDisplay: "MAG" },
  { key: "NM_002364.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4113", geneDisplay: "MAGEB2" },
  { key: "NM_002365.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4114", geneDisplay: "MAGEB3" },
  { key: "NM_002367.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4115", geneDisplay: "MAGEB4" },
  { key: "NM_002370.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4116", geneDisplay: "MAGOH" },
  { key: "NM_002371.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4118", geneDisplay: "MAL" },
  { key: "NM_002372.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4124", geneDisplay: "MAN2A1" },
  { key: "NM_002373.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4130", geneDisplay: "MAP1A" },
  { key: "NM_002377.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4142", geneDisplay: "MAS1" },
  { key: "NM_002379.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4146", geneDisplay: "MATN1" },
  { key: "NM_002380.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4147", geneDisplay: "MATN2" },
  { key: "NM_002381.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4148", geneDisplay: "MATN3" },
  { key: "NM_002382.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4149", geneDisplay: "MAX" },
  { key: "NM_002383.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4150", geneDisplay: "MAZ" },
  { key: "NM_002386.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4157", geneDisplay: "MC1R" },
  { key: "NM_002388.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4172", geneDisplay: "MCM3" },
  { key: "NM_002390.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4185", geneDisplay: "ADAM11" },
  { key: "NM_002391.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4192", geneDisplay: "MDK" },
  { key: "NM_002392.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4193", geneDisplay: "MDM2" },
  { key: "NM_002393.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4194", geneDisplay: "MDM4" },
  { key: "NM_002395.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4199", geneDisplay: "ME1" },
  { key: "NM_002396.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4200", geneDisplay: "ME2" },
  { key: "NM_002397.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4208", geneDisplay: "MEF2C" },
  { key: "NM_002398.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4211", geneDisplay: "MEIS1" },
  { key: "NM_002401.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4215", geneDisplay: "MAP3K3" },
  { key: "NM_002402.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4232", geneDisplay: "MEST" },
  { key: "NM_002403.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4237", geneDisplay: "MFAP2" },
  { key: "NM_002404.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4239", geneDisplay: "MFAP4" },
  { key: "NM_002405.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4242", geneDisplay: "MFNG" },
  { key: "NM_002406.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4245", geneDisplay: "MGAT1" },
  { key: "NM_002407.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4246", geneDisplay: "SCGB2A1" },
  { key: "NM_002408.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4247", geneDisplay: "MGAT2" },
  { key: "NM_002409.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4248", geneDisplay: "MGAT3" },
  { key: "NM_002410.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4249", geneDisplay: "MGAT5" },
  { key: "NM_002411.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4250", geneDisplay: "SCGB2A2" },
  { key: "NM_002412.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4255", geneDisplay: "MGMT" },
  { key: "NM_002413.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4258", geneDisplay: "MGST2" },
  { key: "NM_002415.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4282", geneDisplay: "MIF" },
  { key: "NM_002416.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4283", geneDisplay: "CXCL9" },
  { key: "NM_002417.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4288", geneDisplay: "MKI67" },
  { key: "NM_002418.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4295", geneDisplay: "MLN" },
  { key: "NM_002419.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4296", geneDisplay: "MAP3K11" },
  { key: "NM_002421.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4312", geneDisplay: "MMP1" },
  { key: "NM_002422.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4314", geneDisplay: "MMP3" },
  { key: "NM_002423.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4316", geneDisplay: "MMP7" },
  { key: "NM_002424.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4317", geneDisplay: "MMP8" },
  { key: "NM_002425.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4319", geneDisplay: "MMP10" },
  { key: "NM_002426.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4321", geneDisplay: "MMP12" },
  { key: "NM_002427.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4322", geneDisplay: "MMP13" },
  { key: "NM_002428.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4324", geneDisplay: "MMP15" },
  { key: "NM_002429.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4327", geneDisplay: "MMP19" },
  { key: "NM_002430.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4330", geneDisplay: "MN1" },
  { key: "NM_002431.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4331", geneDisplay: "MNAT1" },
  { key: "NM_002432.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4332", geneDisplay: "MNDA" },
  { key: "NM_002435.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4351", geneDisplay: "MPI" },
  { key: "NM_002436.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4354", geneDisplay: "MPP1" },
  { key: "NM_002437.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4358", geneDisplay: "MPV17" },
  { key: "NM_002438.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4360", geneDisplay: "MRC1" },
  { key: "NM_002439.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4437", geneDisplay: "MSH3" },
  { key: "NM_002440.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4438", geneDisplay: "MSH4" },
  { key: "NM_002442.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4440", geneDisplay: "MSI1" },
  { key: "NM_002443.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4477", geneDisplay: "MSMB" },
  { key: "NM_002444.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4478", geneDisplay: "MSN" },
  { key: "NM_002446.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4294", geneDisplay: "MAP3K10" },
  { key: "NM_002447.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4486", geneDisplay: "MST1R" },
  { key: "NM_002448.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4487", geneDisplay: "MSX1" },
  { key: "NM_002449.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4488", geneDisplay: "MSX2" },
  { key: "NM_002451.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4507", geneDisplay: "MTAP" },
  { key: "NM_002452.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4521", geneDisplay: "NUDT1" },
  { key: "NM_002453.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4528", geneDisplay: "MTIF2" },
  { key: "NM_002454.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4552", geneDisplay: "MTRR" },
  { key: "NM_002455.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4580", geneDisplay: "MTX1" },
  { key: "NM_002457.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4583", geneDisplay: "MUC2" },
  { key: "NM_002458.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "727897", geneDisplay: "MUC5B" },
  { key: "NM_002460.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3662", geneDisplay: "IRF4" },
  { key: "NM_002461.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4597", geneDisplay: "MVD" },
  { key: "NM_002462.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "4599", geneDisplay: "MX1" },
  { key: "NM_002463.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "4600", geneDisplay: "MX2" },
  { key: "NM_002465.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4604", geneDisplay: "MYBPC1" },
  { key: "NM_002466.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4605", geneDisplay: "MYBL2" },
  { key: "NM_002467.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4609", geneDisplay: "MYC" },
  { key: "NM_002468.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4615", geneDisplay: "MYD88" },
  { key: "NM_002469.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4618", geneDisplay: "MYF6" },
  { key: "NM_002470.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4621", geneDisplay: "MYH3" },
  { key: "NM_002471.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4624", geneDisplay: "MYH6" },
  { key: "NM_002472.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4626", geneDisplay: "MYH8" },
  { key: "NM_002473.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4627", geneDisplay: "MYH9" },
  { key: "NM_002474.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4629", geneDisplay: "MYH11" },
  { key: "NM_002475.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "140465", geneDisplay: "MYL6B" },
  { key: "NM_002476.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4635", geneDisplay: "MYL4" },
  { key: "NM_002477.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4636", geneDisplay: "MYL5" },
  { key: "NM_002478.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4654", geneDisplay: "MYOD1" },
  { key: "NM_002479.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4656", geneDisplay: "MYOG" },
  { key: "NM_002480.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4659", geneDisplay: "PPP1R12A" },
  { key: "NM_002481.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4660", geneDisplay: "PPP1R12B" },
  { key: "NM_002482.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4678", geneDisplay: "NASP" },
  { key: "NM_002483.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4680", geneDisplay: "CEACAM6" },
  { key: "NM_002484.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4682", geneDisplay: "NUBP1" },
  { key: "NM_002485.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4683", geneDisplay: "NBN" },
  { key: "NM_002486.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4686", geneDisplay: "NCBP1" },
  { key: "NM_002487.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4692", geneDisplay: "NDN" },
  { key: "NM_002488.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4695", geneDisplay: "NDUFA2" },
  { key: "NM_002489.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4697", geneDisplay: "NDUFA4" },
  { key: "NM_002490.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4700", geneDisplay: "NDUFA6" },
  { key: "NM_002491.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4709", geneDisplay: "NDUFB3" },
  { key: "NM_002492.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4711", geneDisplay: "NDUFB5" },
  { key: "NM_002493.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4712", geneDisplay: "NDUFB6" },
  { key: "NM_002495.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4724", geneDisplay: "NDUFS4" },
  { key: "NM_002496.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4728", geneDisplay: "NDUFS8" },
  { key: "NM_002497.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4751", geneDisplay: "NEK2" },
  { key: "NM_002498.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "4752", geneDisplay: "NEK3" },
  { key: "NM_002499.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4756", geneDisplay: "NEO1" },
  { key: "NM_002500.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4760", geneDisplay: "NEUROD1" },
  { key: "NM_002503.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4793", geneDisplay: "NFKBIB" },
  { key: "NM_002504.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4799", geneDisplay: "NFX1" },
  { key: "NM_002505.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4800", geneDisplay: "NFYA" },
  { key: "NM_002506.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4803", geneDisplay: "NGF" },
  { key: "NM_002507.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4804", geneDisplay: "NGFR" },
  { key: "NM_002508.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4811", geneDisplay: "NID1" },
  { key: "NM_002509.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4821", geneDisplay: "NKX2-2" },
  { key: "NM_002510.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10457", geneDisplay: "GPNMB" },
  { key: "NM_002511.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4829", geneDisplay: "NMBR" },
  { key: "NM_002512.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4831", geneDisplay: "NME2" },
  { key: "NM_002513.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4832", geneDisplay: "NME3" },
  { key: "NM_002514.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4856", geneDisplay: "CCN3" },
  { key: "NM_002515.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4857", geneDisplay: "NOVA1" },
  { key: "NM_002516.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4858", geneDisplay: "NOVA2" },
  { key: "NM_002517.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4861", geneDisplay: "NPAS1" },
  { key: "NM_002518.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4862", geneDisplay: "NPAS2" },
  { key: "NM_002519.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4863", geneDisplay: "NPAT" },
  { key: "NM_002520.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4869", geneDisplay: "NPM1" },
  { key: "NM_002521.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4879", geneDisplay: "NPPB" },
  { key: "NM_002522.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4884", geneDisplay: "NPTX1" },
  { key: "NM_002523.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4885", geneDisplay: "NPTX2" },
  { key: "NM_002524.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4893", geneDisplay: "NRAS" },
  { key: "NM_002526.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4907", geneDisplay: "NT5E" },
  { key: "NM_002528.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4913", geneDisplay: "NTHL1" },
  { key: "NM_002529.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4914", geneDisplay: "NTRK1" },
  { key: "NM_002531.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4923", geneDisplay: "NTSR1" },
  { key: "NM_002532.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4927", geneDisplay: "NUP88" },
  { key: "NM_002533.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4931", geneDisplay: "NVL" },
  { key: "NM_002535.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4939", geneDisplay: "OAS2" },
  { key: "NM_002536.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4943", geneDisplay: "TBC1D25" },
  { key: "NM_002537.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4947", geneDisplay: "OAZ2" },
  { key: "NM_002539.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4953", geneDisplay: "ODC1" },
  { key: "NM_002541.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4967", geneDisplay: "OGDH" },
  { key: "NM_002542.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4968", geneDisplay: "OGG1" },
  { key: "NM_002543.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4973", geneDisplay: "OLR1" },
  { key: "NM_002544.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4974", geneDisplay: "OMG" },
  { key: "NM_002546.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4982", geneDisplay: "TNFRSF11B" },
  { key: "NM_002547.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4983", geneDisplay: "OPHN1" },
  { key: "NM_002548.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4991", geneDisplay: "OR1D2" },
  { key: "NM_002550.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4994", geneDisplay: "OR3A1" },
  { key: "NM_002551.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4995", geneDisplay: "OR3A2" },
  { key: "NM_002553.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5001", geneDisplay: "ORC5" },
  { key: "NM_002555.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5002", geneDisplay: "SLC22A18" },
  { key: "NM_002556.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5007", geneDisplay: "OSBP" },
  { key: "NM_002557.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5016", geneDisplay: "OVGP1" },
  { key: "NM_002558.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5023", geneDisplay: "P2RX1" },
  { key: "NM_002559.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5024", geneDisplay: "P2RX3" },
  { key: "NM_002560.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5025", geneDisplay: "P2RX4" },
  { key: "NM_002561.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5026", geneDisplay: "P2RX5" },
  { key: "NM_002562.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5027", geneDisplay: "P2RX7" },
  { key: "NM_002563.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5028", geneDisplay: "P2RY1" },
  { key: "NM_002564.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5029", geneDisplay: "P2RY2" },
  { key: "NM_002565.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5030", geneDisplay: "P2RY4" },
  { key: "NM_002566.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5032", geneDisplay: "P2RY11" },
  { key: "NM_002567.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5037", geneDisplay: "PEBP1" },
  { key: "NM_002568.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26986", geneDisplay: "PABPC1" },
  { key: "NM_002569.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5045", geneDisplay: "FURIN" },
  { key: "NM_002570.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5046", geneDisplay: "PCSK6" },
  { key: "NM_002571.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5047", geneDisplay: "PAEP" },
  { key: "NM_002572.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5049", geneDisplay: "PAFAH1B2" },
  { key: "NM_002573.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5050", geneDisplay: "PAFAH1B3" },
  { key: "NM_002575.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5055", geneDisplay: "SERPINB2" },
  { key: "NM_002576.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5058", geneDisplay: "PAK1" },
  { key: "NM_002577.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5062", geneDisplay: "PAK2" },
  { key: "NM_002578.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5063", geneDisplay: "PAK3" },
  { key: "NM_002579.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5064", geneDisplay: "PALM" },
  { key: "NM_002580.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5068", geneDisplay: "REG3A" },
  { key: "NM_002581.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5069", geneDisplay: "PAPPA" },
  { key: "NM_002582.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5073", geneDisplay: "PARN" },
  { key: "NM_002583.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5074", geneDisplay: "PAWR" },
  { key: "NM_002585.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5087", geneDisplay: "PBX1" },
  { key: "NM_002586.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5089", geneDisplay: "PBX2" },
  { key: "NM_002588.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5098", geneDisplay: "PCDHGC3" },
  { key: "NM_002590.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5100", geneDisplay: "PCDH8" },
  { key: "NM_002591.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5105", geneDisplay: "PCK1" },
  { key: "NM_002593.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5118", geneDisplay: "PCOLCE" },
  { key: "NM_002594.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5126", geneDisplay: "PCSK2" },
  { key: "NM_002595.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5128", geneDisplay: "CDK17" },
  { key: "NM_002597.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5132", geneDisplay: "PDC" },
  { key: "NM_002598.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5134", geneDisplay: "PDCD2" },
  { key: "NM_002599.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5138", geneDisplay: "PDE2A" },
  { key: "NM_002600.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5142", geneDisplay: "PDE4B" },
  { key: "NM_002601.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5147", geneDisplay: "PDE6D" },
  { key: "NM_002602.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5148", geneDisplay: "PDE6G" },
  { key: "NM_002605.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5151", geneDisplay: "PDE8A" },
  { key: "NM_002606.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5152", geneDisplay: "PDE9A" },
  { key: "NM_002608.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5155", geneDisplay: "PDGFB" },
  { key: "NM_002609.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5159", geneDisplay: "PDGFRB" },
  { key: "NM_002610.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5163", geneDisplay: "PDK1" },
  { key: "NM_002611.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5164", geneDisplay: "PDK2" },
  { key: "NM_002612.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5166", geneDisplay: "PDK4" },
  { key: "NM_002613.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5170", geneDisplay: "PDPK1" },
  { key: "NM_002615.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5176", geneDisplay: "SERPINF1" },
  { key: "NM_002616.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5187", geneDisplay: "PER1" },
  { key: "NM_002617.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5192", geneDisplay: "PEX10" },
  { key: "NM_002618.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5194", geneDisplay: "PEX13" },
  { key: "NM_002619.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5196", geneDisplay: "PF4" },
  { key: "NM_002620.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5197", geneDisplay: "PF4V1" },
  { key: "NM_002622.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5201", geneDisplay: "PFDN1" },
  { key: "NM_002623.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5203", geneDisplay: "PFDN4" },
  { key: "NM_002624.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5204", geneDisplay: "PFDN5" },
  { key: "NM_002625.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5207", geneDisplay: "PFKFB1" },
  { key: "NM_002626.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5211", geneDisplay: "PFKL" },
  { key: "NM_002627.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5214", geneDisplay: "PFKP" },
  { key: "NM_002629.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5223", geneDisplay: "PGAM1" },
  { key: "NM_002630.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5225", geneDisplay: "PGC" },
  { key: "NM_002631.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5226", geneDisplay: "PGD" },
  { key: "NM_002632.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5228", geneDisplay: "PGF" },
  { key: "NM_002633.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5236", geneDisplay: "PGM1" },
  { key: "NM_002634.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5245", geneDisplay: "PHB1" },
  { key: "NM_002635.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5250", geneDisplay: "SLC25A3" },
  { key: "NM_002637.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5255", geneDisplay: "PHKA1" },
  { key: "NM_002638.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5266", geneDisplay: "PI3" },
  { key: "NM_002639.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5268", geneDisplay: "SERPINB5" },
  { key: "NM_002640.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5271", geneDisplay: "SERPINB8" },
  { key: "NM_002641.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5277", geneDisplay: "PIGA" },
  { key: "NM_002643.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5281", geneDisplay: "PIGF" },
  { key: "NM_002644.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5284", geneDisplay: "PIGR" },
  { key: "NM_002645.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5286", geneDisplay: "PIK3C2A" },
  { key: "NM_002647.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5289", geneDisplay: "PIK3C3" },
  { key: "NM_002648.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5292", geneDisplay: "PIM1" },
  { key: "NM_002652.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5304", geneDisplay: "PIP" },
  { key: "NM_002653.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5307", geneDisplay: "PITX1" },
  { key: "NM_002654.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5315", geneDisplay: "PKM" },
  { key: "NM_002655.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5324", geneDisplay: "PLAG1" },
  { key: "NM_002657.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5326", geneDisplay: "PLAGL2" },
  { key: "NM_002658.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5328", geneDisplay: "PLAU" },
  { key: "NM_002659.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5329", geneDisplay: "PLAUR" },
  { key: "NM_002660.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5335", geneDisplay: "PLCG1" },
  { key: "NM_002661.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5336", geneDisplay: "PLCG2" },
  { key: "NM_002662.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5337", geneDisplay: "PLD1" },
  { key: "NM_002663.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5338", geneDisplay: "PLD2" },
  { key: "NM_002664.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5341", geneDisplay: "PLEK" },
  { key: "NM_002665.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5342", geneDisplay: "PLGLB2" },
  { key: "NM_002666.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5346", geneDisplay: "PLIN1" },
  { key: "NM_002667.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5350", geneDisplay: "PLN" },
  { key: "NM_002668.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5355", geneDisplay: "PLP2" },
  { key: "NM_002669.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5356", geneDisplay: "PLRG1" },
  { key: "NM_002674.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5367", geneDisplay: "PMCH" },
  { key: "NM_002676.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5372", geneDisplay: "PMM1" },
  { key: "NM_002677.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5375", geneDisplay: "PMP2" },
  { key: "NM_002686.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5409", geneDisplay: "PNMT" },
  { key: "NM_002687.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5411", geneDisplay: "PNN" },
  { key: "NM_002688.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5413", geneDisplay: "SEPTIN5" },
  { key: "NM_002689.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23649", geneDisplay: "POLA2" },
  { key: "NM_002690.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5423", geneDisplay: "POLB" },
  { key: "NM_002691.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5424", geneDisplay: "POLD1" },
  { key: "NM_002692.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5427", geneDisplay: "POLE2" },
  { key: "NM_002693.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5428", geneDisplay: "POLG" },
  { key: "NM_002695.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5434", geneDisplay: "POLR2E" },
  { key: "NM_002696.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5436", geneDisplay: "POLR2G" },
  { key: "NM_002697.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5451", geneDisplay: "POU2F1" },
  { key: "NM_002699.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5453", geneDisplay: "POU3F1" },
  { key: "NM_002700.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5459", geneDisplay: "POU4F3" },
  { key: "NM_002701.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5460", geneDisplay: "POU5F1" },
  { key: "NM_002703.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5471", geneDisplay: "PPAT" },
  { key: "NM_002704.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5473", geneDisplay: "PPBP" },
  { key: "NM_002705.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5493", geneDisplay: "PPL" },
  { key: "NM_002706.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5495", geneDisplay: "PPM1B" },
  { key: "NM_002708.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5499", geneDisplay: "PPP1CA" },
  { key: "NM_002709.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5500", geneDisplay: "PPP1CB" },
  { key: "NM_002710.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5501", geneDisplay: "PPP1CC" },
  { key: "NM_002711.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5506", geneDisplay: "PPP1R3A" },
  { key: "NM_002712.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5510", geneDisplay: "PPP1R7" },
  { key: "NM_002714.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5514", geneDisplay: "PPP1R10" },
  { key: "NM_002715.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5515", geneDisplay: "PPP2CA" },
  { key: "NM_002716.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5519", geneDisplay: "PPP2R1B" },
  { key: "NM_002717.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5520", geneDisplay: "PPP2R2A" },
  { key: "NM_002718.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5523", geneDisplay: "PPP2R3A" },
  { key: "NM_002720.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5531", geneDisplay: "PPP4C" },
  { key: "NM_002721.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5537", geneDisplay: "PPP6C" },
  { key: "NM_002722.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5539", geneDisplay: "PPY" },
  { key: "NM_002723.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5545", geneDisplay: "PRB4" },
  { key: "NM_002725.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5549", geneDisplay: "PRELP" },
  { key: "NM_002726.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5550", geneDisplay: "PREP" },
  { key: "NM_002727.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5552", geneDisplay: "SRGN" },
  { key: "NM_002728.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5553", geneDisplay: "PRG2" },
  { key: "NM_002729.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3087", geneDisplay: "HHEX" },
  { key: "NM_002730.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5566", geneDisplay: "PRKACA" },
  { key: "NM_002732.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5568", geneDisplay: "PRKACG" },
  { key: "NM_002733.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5571", geneDisplay: "PRKAG1" },
  { key: "NM_002734.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5573", geneDisplay: "PRKAR1A" },
  { key: "NM_002736.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5577", geneDisplay: "PRKAR2B" },
  { key: "NM_002737.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5578", geneDisplay: "PRKCA" },
  { key: "NM_002738.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5579", geneDisplay: "PRKCB" },
  { key: "NM_002739.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5582", geneDisplay: "PRKCG" },
  { key: "NM_002740.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5584", geneDisplay: "PRKCI" },
  { key: "NM_002741.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5585", geneDisplay: "PKN1" },
  { key: "NM_002742.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5587", geneDisplay: "PRKD1" },
  { key: "NM_002744.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5590", geneDisplay: "PRKCZ" },
  { key: "NM_002745.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5594", geneDisplay: "MAPK1" },
  { key: "NM_002746.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5595", geneDisplay: "MAPK3" },
  { key: "NM_002747.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5596", geneDisplay: "MAPK4" },
  { key: "NM_002748.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5597", geneDisplay: "MAPK6" },
  { key: "NM_002749.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5598", geneDisplay: "MAPK7" },
  { key: "NM_002751.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5600", geneDisplay: "MAPK11" },
  { key: "NM_002752.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5601", geneDisplay: "MAPK9" },
  { key: "NM_002754.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5603", geneDisplay: "MAPK13" },
  { key: "NM_002755.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5604", geneDisplay: "MAP2K1" },
  { key: "NM_002758.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5608", geneDisplay: "MAP2K6" },
  { key: "NM_002761.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5619", geneDisplay: "PRM1" },
  { key: "NM_002762.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5620", geneDisplay: "PRM2" },
  { key: "NM_002764.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5631", geneDisplay: "PRPS1" },
  { key: "NM_002765.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5634", geneDisplay: "PRPS2" },
  { key: "NM_002766.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5635", geneDisplay: "PRPSAP1" },
  { key: "NM_002767.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5636", geneDisplay: "PRPSAP2" },
  { key: "NM_002768.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5119", geneDisplay: "CHMP1A" },
  { key: "NM_002769.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5644", geneDisplay: "PRSS1" },
  { key: "NM_002770.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5645", geneDisplay: "PRSS2" },
  { key: "NM_002771.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5646", geneDisplay: "PRSS3" },
  { key: "NM_002772.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5651", geneDisplay: "TMPRSS15" },
  { key: "NM_002773.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5652", geneDisplay: "PRSS8" },
  { key: "NM_002774.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5653", geneDisplay: "KLK6" },
  { key: "NM_002775.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5654", geneDisplay: "HTRA1" },
  { key: "NM_002777.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5657", geneDisplay: "PRTN3" },
  { key: "NM_002778.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5660", geneDisplay: "PSAP" },
  { key: "NM_002779.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5662", geneDisplay: "PSD" },
  { key: "NM_002780.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5672", geneDisplay: "PSG4" },
  { key: "NM_002781.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5673", geneDisplay: "PSG5" },
  { key: "NM_002783.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5676", geneDisplay: "PSG7" },
  { key: "NM_002784.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5678", geneDisplay: "PSG9" },
  { key: "NM_002785.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5680", geneDisplay: "PSG11" },
  { key: "NM_002786.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5682", geneDisplay: "PSMA1" },
  { key: "NM_002787.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5683", geneDisplay: "PSMA2" },
  { key: "NM_002788.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5684", geneDisplay: "PSMA3" },
  { key: "NM_002789.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5685", geneDisplay: "PSMA4" },
  { key: "NM_002790.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5686", geneDisplay: "PSMA5" },
  { key: "NM_002791.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5687", geneDisplay: "PSMA6" },
  { key: "NM_002792.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5688", geneDisplay: "PSMA7" },
  { key: "NM_002793.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5689", geneDisplay: "PSMB1" },
  { key: "NM_002794.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5690", geneDisplay: "PSMB2" },
  { key: "NM_002795.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5691", geneDisplay: "PSMB3" },
  { key: "NM_002796.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5692", geneDisplay: "PSMB4" },
  { key: "NM_002797.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5693", geneDisplay: "PSMB5" },
  { key: "NM_002798.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5694", geneDisplay: "PSMB6" },
  { key: "NM_002799.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5695", geneDisplay: "PSMB7" },
  { key: "NM_002800.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5698", geneDisplay: "PSMB9" },
  { key: "NM_002801.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5699", geneDisplay: "PSMB10" },
  { key: "NM_002802.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5700", geneDisplay: "PSMC1" },
  { key: "NM_002803.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5701", geneDisplay: "PSMC2" },
  { key: "NM_002804.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5702", geneDisplay: "PSMC3" },
  { key: "NM_002805.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5705", geneDisplay: "PSMC5" },
  { key: "NM_002806.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5706", geneDisplay: "PSMC6" },
  { key: "NM_002807.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5707", geneDisplay: "PSMD1" },
  { key: "NM_002808.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5708", geneDisplay: "PSMD2" },
  { key: "NM_002809.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5709", geneDisplay: "PSMD3" },
  { key: "NM_002810.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5710", geneDisplay: "PSMD4" },
  { key: "NM_002811.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5713", geneDisplay: "PSMD7" },
  { key: "NM_002812.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5714", geneDisplay: "PSMD8" },
  { key: "NM_002813.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5715", geneDisplay: "PSMD9" },
  { key: "NM_002814.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5716", geneDisplay: "PSMD10" },
  { key: "NM_002815.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5717", geneDisplay: "PSMD11" },
  { key: "NM_002816.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5718", geneDisplay: "PSMD12" },
  { key: "NM_002817.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5719", geneDisplay: "PSMD13" },
  { key: "NM_002818.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5721", geneDisplay: "PSME2" },
  { key: "NM_002819.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5725", geneDisplay: "PTBP1" },
  { key: "NM_002821.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5754", geneDisplay: "PTK7" },
  { key: "NM_002822.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5756", geneDisplay: "TWF1" },
  { key: "NM_002823.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5757", geneDisplay: "PTMA" },
  { key: "NM_002824.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5763", geneDisplay: "PTMS" },
  { key: "NM_002825.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5764", geneDisplay: "PTN" },
  { key: "NM_002826.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5768", geneDisplay: "QSOX1" },
  { key: "NM_002827.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5770", geneDisplay: "PTPN1" },
  { key: "NM_002828.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5771", geneDisplay: "PTPN2" },
  { key: "NM_002829.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5774", geneDisplay: "PTPN3" },
  { key: "NM_002830.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5775", geneDisplay: "PTPN4" },
  { key: "NM_002831.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5777", geneDisplay: "PTPN6" },
  { key: "NM_002832.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5778", geneDisplay: "PTPN7" },
  { key: "NM_002833.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5780", geneDisplay: "PTPN9" },
  { key: "NM_002834.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5781", geneDisplay: "PTPN11" },
  { key: "NM_002835.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5782", geneDisplay: "PTPN12" },
  { key: "NM_002838.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5788", geneDisplay: "PTPRC" },
  { key: "NM_002839.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5789", geneDisplay: "PTPRD" },
  { key: "NM_002840.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5792", geneDisplay: "PTPRF" },
  { key: "NM_002841.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5793", geneDisplay: "PTPRG" },
  { key: "NM_002842.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5794", geneDisplay: "PTPRH" },
  { key: "NM_002843.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5795", geneDisplay: "PTPRJ" },
  { key: "NM_002844.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5796", geneDisplay: "PTPRK" },
  { key: "NM_002846.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5798", geneDisplay: "PTPRN" },
  { key: "NM_002847.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5799", geneDisplay: "PTPRN2" },
  { key: "NM_002849.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5801", geneDisplay: "PTPRR" },
  { key: "NM_002850.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5802", geneDisplay: "PTPRS" },
  { key: "NM_002851.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5803", geneDisplay: "PTPRZ1" },
  { key: "NM_002852.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5806", geneDisplay: "PTX3" },
  { key: "NM_002853.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5810", geneDisplay: "RAD1" },
  { key: "NM_002855.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5818", geneDisplay: "NECTIN1" },
  { key: "NM_002857.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5824", geneDisplay: "PEX19" },
  { key: "NM_002858.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5825", geneDisplay: "ABCD3" },
  { key: "NM_002860.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5832", geneDisplay: "ALDH18A1" },
  { key: "NM_002861.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5833", geneDisplay: "PCYT2" },
  { key: "NM_002862.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5834", geneDisplay: "PYGB" },
  { key: "NM_002863.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5836", geneDisplay: "PYGL" },
  { key: "NM_002864.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5858", geneDisplay: "PZP" },
  { key: "NM_002865.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5862", geneDisplay: "RAB2A" },
  { key: "NM_002866.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5864", geneDisplay: "RAB3A" },
  { key: "NM_002867.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5865", geneDisplay: "RAB3B" },
  { key: "NM_002868.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5869", geneDisplay: "RAB5B" },
  { key: "NM_002870.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5872", geneDisplay: "RAB13" },
  { key: "NM_002871.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5877", geneDisplay: "RABIF" },
  { key: "NM_002872.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5880", geneDisplay: "RAC2" },
  { key: "NM_002874.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5887", geneDisplay: "RAD23B" },
  { key: "NM_002875.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5888", geneDisplay: "RAD51" },
  { key: "NM_002878.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5892", geneDisplay: "RAD51D" },
  { key: "NM_002880.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5894", geneDisplay: "RAF1" },
  { key: "NM_002881.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5899", geneDisplay: "RALB" },
  { key: "NM_002883.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5905", geneDisplay: "RANGAP1" },
  { key: "NM_002884.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5906", geneDisplay: "RAP1A" },
  { key: "NM_002885.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5909", geneDisplay: "RAP1GAP" },
  { key: "NM_002886.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5912", geneDisplay: "RAP2B" },
  { key: "NM_002887.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5917", geneDisplay: "RARS1" },
  { key: "NM_002889.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5919", geneDisplay: "RARRES2" },
  { key: "NM_002890.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5921", geneDisplay: "RASA1" },
  { key: "NM_002892.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5926", geneDisplay: "ARID4A" },
  { key: "NM_002893.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5931", geneDisplay: "RBBP7" },
  { key: "NM_002894.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5932", geneDisplay: "RBBP8" },
  { key: "NM_002895.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5933", geneDisplay: "RBL1" },
  { key: "NM_002896.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5936", geneDisplay: "RBM4" },
  { key: "NM_002898.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5939", geneDisplay: "RBMS2" },
  { key: "NM_002899.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5947", geneDisplay: "RBP1" },
  { key: "NM_002900.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5949", geneDisplay: "RBP3" },
  { key: "NM_002901.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5954", geneDisplay: "RCN1" },
  { key: "NM_002902.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5955", geneDisplay: "RCN2" },
  { key: "NM_002903.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5957", geneDisplay: "RCVRN" },
  { key: "NM_002904.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7936", geneDisplay: "NELFE" },
  { key: "NM_002905.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5959", geneDisplay: "RDH5" },
  { key: "NM_002906.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5962", geneDisplay: "RDX" },
  { key: "NM_002907.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5965", geneDisplay: "RECQL" },
  { key: "NM_002909.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5967", geneDisplay: "REG1A" },
  { key: "NM_002910.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5973", geneDisplay: "RENBP" },
  { key: "NM_002911.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5976", geneDisplay: "UPF1" },
  { key: "NM_002913.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5981", geneDisplay: "RFC1" },
  { key: "NM_002915.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5983", geneDisplay: "RFC3" },
  { key: "NM_002916.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5984", geneDisplay: "RFC4" },
  { key: "NM_002917.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5986", geneDisplay: "RFNG" },
  { key: "NM_002918.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5989", geneDisplay: "RFX1" },
  { key: "NM_002922.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5996", geneDisplay: "RGS1" },
  { key: "NM_002923.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5997", geneDisplay: "RGS2" },
  { key: "NM_002927.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6003", geneDisplay: "RGS13" },
  { key: "NM_002928.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6004", geneDisplay: "RGS16" },
  { key: "NM_002929.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6011", geneDisplay: "GRK1" },
  { key: "NM_002930.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6014", geneDisplay: "RIT2" },
  { key: "NM_002931.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6015", geneDisplay: "RING1" },
  { key: "NM_002933.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6035", geneDisplay: "RNASE1" },
  { key: "NM_002934.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6036", geneDisplay: "RNASE2" },
  { key: "NM_002935.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6037", geneDisplay: "RNASE3" },
  { key: "NM_002936.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "246243", geneDisplay: "RNASEH1" },
  { key: "NM_002937.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6038", geneDisplay: "RNASE4" },
  { key: "NM_002938.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6047", geneDisplay: "RNF4" },
  { key: "NM_002940.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6059", geneDisplay: "ABCE1" },
  { key: "NM_002941.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6091", geneDisplay: "ROBO1" },
  { key: "NM_002945.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6117", geneDisplay: "RPA1" },
  { key: "NM_002946.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6118", geneDisplay: "RPA2" },
  { key: "NM_002947.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6119", geneDisplay: "RPA3" },
  { key: "NM_002948.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6138", geneDisplay: "RPL15" },
  { key: "NM_002949.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6182", geneDisplay: "MRPL12" },
  { key: "NM_002950.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6184", geneDisplay: "RPN1" },
  { key: "NM_002951.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6185", geneDisplay: "RPN2" },
  { key: "NM_002952.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6187", geneDisplay: "RPS2" },
  { key: "NM_002953.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6195", geneDisplay: "RPS6KA1" },
  { key: "NM_002954.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6233", geneDisplay: "RPS27A" },
  { key: "NM_002957.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6256", geneDisplay: "RXRA" },
  { key: "NM_002958.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6259", geneDisplay: "RYK" },
  { key: "NM_002959.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6272", geneDisplay: "SORT1" },
  { key: "NM_002960.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6274", geneDisplay: "S100A3" },
  { key: "NM_002961.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6275", geneDisplay: "S100A4" },
  { key: "NM_002963.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6278", geneDisplay: "S100A7" },
  { key: "NM_002964.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6279", geneDisplay: "S100A8" },
  { key: "NM_002965.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6280", geneDisplay: "S100A9" },
  { key: "NM_002966.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6281", geneDisplay: "S100A10" },
  { key: "NM_002968.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6299", geneDisplay: "SALL1" },
  { key: "NM_002969.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6300", geneDisplay: "MAPK12" },
  { key: "NM_002970.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6303", geneDisplay: "SAT1" },
  { key: "NM_002971.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6304", geneDisplay: "SATB1" },
  { key: "NM_002972.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6305", geneDisplay: "SBF1" },
  { key: "NM_002974.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6318", geneDisplay: "SERPINB4" },
  { key: "NM_002975.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6320", geneDisplay: "CLEC11A" },
  { key: "NM_002976.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6332", geneDisplay: "SCN7A" },
  { key: "NM_002979.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6342", geneDisplay: "SCP2" },
  { key: "NM_002980.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6344", geneDisplay: "SCTR" },
  { key: "NM_002981.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6346", geneDisplay: "CCL1" },
  { key: "NM_002982.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6347", geneDisplay: "CCL2" },
  { key: "NM_002983.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6348", geneDisplay: "CCL3" },
  { key: "NM_002984.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6351", geneDisplay: "CCL4" },
  { key: "NM_002986.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6356", geneDisplay: "CCL11" },
  { key: "NM_002987.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6361", geneDisplay: "CCL17" },
  { key: "NM_002988.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6362", geneDisplay: "CCL18" },
  { key: "NM_002989.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6366", geneDisplay: "CCL21" },
  { key: "NM_002990.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6367", geneDisplay: "CCL22" },
  { key: "NM_002991.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6369", geneDisplay: "CCL24" },
  { key: "NM_002993.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6372", geneDisplay: "CXCL6" },
  { key: "NM_002994.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6374", geneDisplay: "CXCL5" },
  { key: "NM_002995.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6375", geneDisplay: "XCL1" },
  { key: "NM_002996.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6376", geneDisplay: "CX3CL1" },
  { key: "NM_002997.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6382", geneDisplay: "SDC1" },
  { key: "NM_002998.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6383", geneDisplay: "SDC2" },
  { key: "NM_002999.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6385", geneDisplay: "SDC4" },
  { key: "NM_003000.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6390", geneDisplay: "SDHB" },
  { key: "NM_003001.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6391", geneDisplay: "SDHC" },
  { key: "NM_003002.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6392", geneDisplay: "SDHD" },
  { key: "NM_003004.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6398", geneDisplay: "SECTM1" },
  { key: "NM_003005.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6403", geneDisplay: "SELP" },
  { key: "NM_003006.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6404", geneDisplay: "SELPLG" },
  { key: "NM_003007.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6406", geneDisplay: "SEMG1" },
  { key: "NM_003008.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6407", geneDisplay: "SEMG2" },
  { key: "NM_003009.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6415", geneDisplay: "SELENOW" },
  { key: "NM_003010.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6416", geneDisplay: "MAP2K4" },
  { key: "NM_003011.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6418", geneDisplay: "SET" },
  { key: "NM_003012.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6422", geneDisplay: "SFRP1" },
  { key: "NM_003013.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6423", geneDisplay: "SFRP2" },
  { key: "NM_003014.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6424", geneDisplay: "SFRP4" },
  { key: "NM_003015.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6425", geneDisplay: "SFRP5" },
  { key: "NM_003017.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6428", geneDisplay: "SRSF3" },
  { key: "NM_003019.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6441", geneDisplay: "SFTPD" },
  { key: "NM_003021.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6449", geneDisplay: "SGTA" },
  { key: "NM_003022.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6451", geneDisplay: "SH3BGRL" },
  { key: "NM_003024.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6453", geneDisplay: "ITSN1" },
  { key: "NM_003025.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6455", geneDisplay: "SH3GL1" },
  { key: "NM_003026.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6456", geneDisplay: "SH3GL2" },
  { key: "NM_003027.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6457", geneDisplay: "SH3GL3" },
  { key: "NM_003028.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6461", geneDisplay: "SHB" },
  { key: "NM_003031.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6477", geneDisplay: "SIAH1" },
  { key: "NM_003034.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6489", geneDisplay: "ST8SIA1" },
  { key: "NM_003036.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6497", geneDisplay: "SKI" },
  { key: "NM_003037.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6504", geneDisplay: "SLAMF1" },
  { key: "NM_003038.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6509", geneDisplay: "SLC1A4" },
  { key: "NM_003039.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6518", geneDisplay: "SLC2A5" },
  { key: "NM_003040.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6522", geneDisplay: "SLC4A2" },
  { key: "NM_003041.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6524", geneDisplay: "SLC5A2" },
  { key: "NM_003042.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6529", geneDisplay: "SLC6A1" },
  { key: "NM_003043.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6533", geneDisplay: "SLC6A6" },
  { key: "NM_003045.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6541", geneDisplay: "SLC7A1" },
  { key: "NM_003047.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6548", geneDisplay: "SLC9A1" },
  { key: "NM_003048.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6549", geneDisplay: "SLC9A2" },
  { key: "NM_003049.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6554", geneDisplay: "SLC10A1" },
  { key: "NM_003051.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6566", geneDisplay: "SLC16A1" },
  { key: "NM_003052.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6569", geneDisplay: "SLC34A1" },
  { key: "NM_003053.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6570", geneDisplay: "SLC18A1" },
  { key: "NM_003054.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6571", geneDisplay: "SLC18A2" },
  { key: "NM_003055.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6572", geneDisplay: "SLC18A3" },
  { key: "NM_003057.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6580", geneDisplay: "SLC22A1" },
  { key: "NM_003058.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6582", geneDisplay: "SLC22A2" },
  { key: "NM_003059.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6583", geneDisplay: "SLC22A4" },
  { key: "NM_003060.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6584", geneDisplay: "SLC22A5" },
  { key: "NM_003061.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6585", geneDisplay: "SLIT1" },
  { key: "NM_003062.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6586", geneDisplay: "SLIT3" },
  { key: "NM_003063.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6588", geneDisplay: "SLN" },
  { key: "NM_003064.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6590", geneDisplay: "SLPI" },
  { key: "NM_003068.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6591", geneDisplay: "SNAI2" },
  { key: "NM_003070.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6595", geneDisplay: "SMARCA2" },
  { key: "NM_003071.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6596", geneDisplay: "HLTF" },
  { key: "NM_003072.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6597", geneDisplay: "SMARCA4" },
  { key: "NM_003073.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6598", geneDisplay: "SMARCB1" },
  { key: "NM_003074.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6599", geneDisplay: "SMARCC1" },
  { key: "NM_003076.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6602", geneDisplay: "SMARCD1" },
  { key: "NM_003079.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6605", geneDisplay: "SMARCE1" },
  { key: "NM_003080.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6610", geneDisplay: "SMPD2" },
  { key: "NM_003082.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6617", geneDisplay: "SNAPC1" },
  { key: "NM_003083.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6618", geneDisplay: "SNAPC2" },
  { key: "NM_003085.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6620", geneDisplay: "SNCB" },
  { key: "NM_003086.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6621", geneDisplay: "SNAPC4" },
  { key: "NM_003087.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6623", geneDisplay: "SNCG" },
  { key: "NM_003088.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6624", geneDisplay: "FSCN1" },
  { key: "NM_003089.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6625", geneDisplay: "SNRNP70" },
  { key: "NM_003090.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6627", geneDisplay: "SNRPA1" },
  { key: "NM_003091.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6628", geneDisplay: "SNRPB" },
  { key: "NM_003092.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6629", geneDisplay: "SNRPB2" },
  { key: "NM_003093.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6631", geneDisplay: "SNRPC" },
  { key: "NM_003094.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6635", geneDisplay: "SNRPE" },
  { key: "NM_003095.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6636", geneDisplay: "SNRPF" },
  { key: "NM_003096.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6637", geneDisplay: "SNRPG" },
  { key: "NM_003097.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6638", geneDisplay: "SNRPN" },
  { key: "NM_003098.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6640", geneDisplay: "SNTA1" },
  { key: "NM_003099.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6642", geneDisplay: "SNX1" },
  { key: "NM_003100.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6643", geneDisplay: "SNX2" },
  { key: "NM_003101.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6646", geneDisplay: "SOAT1" },
  { key: "NM_003102.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6649", geneDisplay: "SOD3" },
  { key: "NM_003104.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6652", geneDisplay: "SORD" },
  { key: "NM_003105.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6653", geneDisplay: "SORL1" },
  { key: "NM_003106.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6657", geneDisplay: "SOX2" },
  { key: "NM_003107.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6659", geneDisplay: "SOX4" },
  { key: "NM_003108.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6664", geneDisplay: "SOX11" },
  { key: "NM_003110.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6668", geneDisplay: "SP2" },
  { key: "NM_003111.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6670", geneDisplay: "SP3" },
  { key: "NM_003112.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6671", geneDisplay: "SP4" },
  { key: "NM_003114.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6674", geneDisplay: "SPAG1" },
  { key: "NM_003116.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6676", geneDisplay: "SPAG4" },
  { key: "NM_003118.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6678", geneDisplay: "SPARC" },
  { key: "NM_003119.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6687", geneDisplay: "SPG7" },
  { key: "NM_003120.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6688", geneDisplay: "SPI1" },
  { key: "NM_003121.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6689", geneDisplay: "SPIB" },
  { key: "NM_003123.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6693", geneDisplay: "SPN" },
  { key: "NM_003124.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6697", geneDisplay: "SPR" },
  { key: "NM_003125.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6699", geneDisplay: "SPRR1B" },
  { key: "NM_003126.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6708", geneDisplay: "SPTA1" },
  { key: "NM_003128.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6711", geneDisplay: "SPTBN1" },
  { key: "NM_003129.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6713", geneDisplay: "SQLE" },
  { key: "NM_003130.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6717", geneDisplay: "SRI" },
  { key: "NM_003131.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6722", geneDisplay: "SRF" },
  { key: "NM_003132.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6723", geneDisplay: "SRM" },
  { key: "NM_003133.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6726", geneDisplay: "SRP9" },
  { key: "NM_003134.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6727", geneDisplay: "SRP14" },
  { key: "NM_003135.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6728", geneDisplay: "SRP19" },
  { key: "NM_003136.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6729", geneDisplay: "SRP54" },
  { key: "NM_003137.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6732", geneDisplay: "SRPK1" },
  { key: "NM_003139.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6734", geneDisplay: "SRPRA" },
  { key: "NM_003140.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "6736", geneDisplay: "SRY" },
  { key: "NM_003141.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6737", geneDisplay: "TRIM21" },
  { key: "NM_003142.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6741", geneDisplay: "SSB" },
  { key: "NM_003143.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6742", geneDisplay: "SSBP1" },
  { key: "NM_003144.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6745", geneDisplay: "SSR1" },
  { key: "NM_003145.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6746", geneDisplay: "SSR2" },
  { key: "NM_003146.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6749", geneDisplay: "SSRP1" },
  { key: "NM_003149.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6769", geneDisplay: "STAC" },
  { key: "NM_003151.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6775", geneDisplay: "STAT4" },
  { key: "NM_003153.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6778", geneDisplay: "STAT6" },
  { key: "NM_003154.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6779", geneDisplay: "STATH" },
  { key: "NM_003155.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6781", geneDisplay: "STC1" },
  { key: "NM_003157.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6787", geneDisplay: "NEK4" },
  { key: "NM_003161.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6198", geneDisplay: "RPS6KB1" },
  { key: "NM_003162.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6801", geneDisplay: "STRN" },
  { key: "NM_003164.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6811", geneDisplay: "STX5" },
  { key: "NM_003167.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6822", geneDisplay: "SULT2A1" },
  { key: "NM_003168.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6827", geneDisplay: "SUPT4H1" },
  { key: "NM_003170.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6830", geneDisplay: "SUPT6H" },
  { key: "NM_003171.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6832", geneDisplay: "SUPV3L1" },
  { key: "NM_003172.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6834", geneDisplay: "SURF1" },
  { key: "NM_003173.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6839", geneDisplay: "SUV39H1" },
  { key: "NM_003175.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6846", geneDisplay: "XCL2" },
  { key: "NM_003176.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6847", geneDisplay: "SYCP1" },
  { key: "NM_003177.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6850", geneDisplay: "SYK" },
  { key: "NM_003179.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6855", geneDisplay: "SYP" },
  { key: "NM_003180.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6861", geneDisplay: "SYT5" },
  { key: "NM_003182.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6863", geneDisplay: "TAC1" },
  { key: "NM_003183.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6868", geneDisplay: "ADAM17" },
  { key: "NM_003184.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6873", geneDisplay: "TAF2" },
  { key: "NM_003185.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6874", geneDisplay: "TAF4" },
  { key: "NM_003186.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6876", geneDisplay: "TAGLN" },
  { key: "NM_003187.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6880", geneDisplay: "TAF9" },
  { key: "NM_003190.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6892", geneDisplay: "TAPBP" },
  { key: "NM_003192.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6903", geneDisplay: "TBCC" },
  { key: "NM_003193.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6905", geneDisplay: "TBCE" },
  { key: "NM_003194.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6908", geneDisplay: "TBP" },
  { key: "NM_003195.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6919", geneDisplay: "TCEA2" },
  { key: "NM_003196.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6920", geneDisplay: "TCEA3" },
  { key: "NM_003198.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6924", geneDisplay: "ELOA" },
  { key: "NM_003200.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6929", geneDisplay: "TCF3" },
  { key: "NM_003201.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7019", geneDisplay: "TFAM" },
  { key: "NM_003202.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6932", geneDisplay: "TCF7" },
  { key: "NM_003203.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6936", geneDisplay: "GCFC2" },
  { key: "NM_003204.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4779", geneDisplay: "NFE2L1" },
  { key: "NM_003206.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6943", geneDisplay: "TCF21" },
  { key: "NM_003211.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6996", geneDisplay: "TDG" },
  { key: "NM_003212.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6997", geneDisplay: "TDGF1" },
  { key: "NM_003213.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7004", geneDisplay: "TEAD4" },
  { key: "NM_003214.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7005", geneDisplay: "TEAD3" },
  { key: "NM_003215.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7006", geneDisplay: "TEC" },
  { key: "NM_003216.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7008", geneDisplay: "TEF" },
  { key: "NM_003217.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7009", geneDisplay: "TMBIM6" },
  { key: "NM_003221.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7021", geneDisplay: "TFAP2B" },
  { key: "NM_003222.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7022", geneDisplay: "TFAP2C" },
  { key: "NM_003223.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7023", geneDisplay: "TFAP4" },
  { key: "NM_003225.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7031", geneDisplay: "TFF1" },
  { key: "NM_003226.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7033", geneDisplay: "TFF3" },
  { key: "NM_003227.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7036", geneDisplay: "TFR2" },
  { key: "NM_003235.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7038", geneDisplay: "TG" },
  { key: "NM_003236.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7039", geneDisplay: "TGFA" },
  { key: "NM_003238.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7042", geneDisplay: "TGFB2" },
  { key: "NM_003239.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7043", geneDisplay: "TGFB3" },
  { key: "NM_003240.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7044", geneDisplay: "LEFTY2" },
  { key: "NM_003241.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7047", geneDisplay: "TGM4" },
  { key: "NM_003242.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7048", geneDisplay: "TGFBR2" },
  { key: "NM_003243.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7049", geneDisplay: "TGFBR3" },
  { key: "NM_003244.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7050", geneDisplay: "TGIF1" },
  { key: "NM_003245.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7053", geneDisplay: "TGM3" },
  { key: "NM_003246.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7057", geneDisplay: "THBS1" },
  { key: "NM_003247.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7058", geneDisplay: "THBS2" },
  { key: "NM_003248.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7060", geneDisplay: "THBS4" },
  { key: "NM_003249.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7064", geneDisplay: "THOP1" },
  { key: "NM_003251.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7069", geneDisplay: "THRSP" },
  { key: "NM_003252.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7073", geneDisplay: "TIAL1" },
  { key: "NM_003254.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7076", geneDisplay: "TIMP1" },
  { key: "NM_003255.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7077", geneDisplay: "TIMP2" },
  { key: "NM_003256.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7079", geneDisplay: "TIMP4" },
  { key: "NM_003258.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7083", geneDisplay: "TK1" },
  { key: "NM_003259.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7087", geneDisplay: "ICAM5" },
  { key: "NM_003260.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7089", geneDisplay: "TLE2" },
  { key: "NM_003262.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7095", geneDisplay: "SEC62" },
  { key: "NM_003263.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7096", geneDisplay: "TLR1" },
  { key: "NM_003265.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7098", geneDisplay: "TLR3" },
  { key: "NM_003268.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7100", geneDisplay: "TLR5" },
  { key: "NM_003269.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7101", geneDisplay: "NR2E1" },
  { key: "NM_003270.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7105", geneDisplay: "TSPAN6" },
  { key: "NM_003271.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7106", geneDisplay: "TSPAN4" },
  { key: "NM_003272.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7107", geneDisplay: "GPR137B" },
  { key: "NM_003273.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7108", geneDisplay: "TM7SF2" },
  { key: "NM_003274.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7109", geneDisplay: "TRAPPC10" },
  { key: "NM_003275.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7111", geneDisplay: "TMOD1" },
  { key: "NM_003278.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7123", geneDisplay: "CLEC3B" },
  { key: "NM_003279.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7125", geneDisplay: "TNNC2" },
  { key: "NM_003280.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7134", geneDisplay: "TNNC1" },
  { key: "NM_003281.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7135", geneDisplay: "TNNI1" },
  { key: "NM_003282.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7136", geneDisplay: "TNNI2" },
  { key: "NM_003283.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7138", geneDisplay: "TNNT1" },
  { key: "NM_003284.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7141", geneDisplay: "TNP1" },
  { key: "NM_003285.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7143", geneDisplay: "TNR" },
  { key: "NM_003286.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7150", geneDisplay: "TOP1" },
  { key: "NM_003287.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7164", geneDisplay: "TPD52L1" },
  { key: "NM_003288.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7165", geneDisplay: "TPD52L2" },
  { key: "NM_003289.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7169", geneDisplay: "TPM2" },
  { key: "NM_003290.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7171", geneDisplay: "TPM4" },
  { key: "NM_003292.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7175", geneDisplay: "TPR" },
  { key: "NM_003294.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7177", geneDisplay: "TPSAB1" },
  { key: "NM_003295.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7178", geneDisplay: "TPT1" },
  { key: "NM_003296.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7180", geneDisplay: "CRISP2" },
  { key: "NM_003297.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7181", geneDisplay: "NR2C1" },
  { key: "NM_003299.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7184", geneDisplay: "HSP90B1" },
  { key: "NM_003301.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7201", geneDisplay: "TRHR" },
  { key: "NM_003302.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7205", geneDisplay: "TRIP6" },
  { key: "NM_003307.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7226", geneDisplay: "TRPM2" },
  { key: "NM_003308.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "7258", geneDisplay: "TSPY1" },
  { key: "NM_003309.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7259", geneDisplay: "TSPYL1" },
  { key: "NM_003310.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7260", geneDisplay: "EIPR1" },
  { key: "NM_003311.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7262", geneDisplay: "PHLDA2" },
  { key: "NM_003312.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7263", geneDisplay: "TST" },
  { key: "NM_003313.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7264", geneDisplay: "GFUS" },
  { key: "NM_003314.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7265", geneDisplay: "TTC1" },
  { key: "NM_003315.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7266", geneDisplay: "DNAJC7" },
  { key: "NM_003318.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7272", geneDisplay: "TTK" },
  { key: "NM_003321.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7284", geneDisplay: "TUFM" },
  { key: "NM_003322.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7287", geneDisplay: "TULP1" },
  { key: "NM_003323.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7288", geneDisplay: "TULP2" },
  { key: "NM_003324.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7289", geneDisplay: "TULP3" },
  { key: "NM_003325.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7290", geneDisplay: "HIRA" },
  { key: "NM_003326.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7292", geneDisplay: "TNFSF4" },
  { key: "NM_003327.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7293", geneDisplay: "TNFRSF4" },
  { key: "NM_003328.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7294", geneDisplay: "TXK" },
  { key: "NM_003329.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7295", geneDisplay: "TXN" },
  { key: "NM_003331.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7297", geneDisplay: "TYK2" },
  { key: "NM_003332.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7305", geneDisplay: "TYROBP" },
  { key: "NM_003334.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7317", geneDisplay: "UBA1" },
  { key: "NM_003335.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7318", geneDisplay: "UBA7" },
  { key: "NM_003336.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7319", geneDisplay: "UBE2A" },
  { key: "NM_003337.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7320", geneDisplay: "UBE2B" },
  { key: "NM_003338.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7321", geneDisplay: "UBE2D1" },
  { key: "NM_003339.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7322", geneDisplay: "UBE2D2" },
  { key: "NM_003341.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7324", geneDisplay: "UBE2E1" },
  { key: "NM_003342.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7326", geneDisplay: "UBE2G1" },
  { key: "NM_003343.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7327", geneDisplay: "UBE2G2" },
  { key: "NM_003344.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7328", geneDisplay: "UBE2H" },
  { key: "NM_003345.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7329", geneDisplay: "UBE2I" },
  { key: "NM_003347.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7332", geneDisplay: "UBE2L3" },
  { key: "NM_003348.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7334", geneDisplay: "UBE2N" },
  { key: "NM_003350.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7336", geneDisplay: "UBE2V2" },
  { key: "NM_003352.8", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7341", geneDisplay: "SUMO1" },
  { key: "NM_003353.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7349", geneDisplay: "UCN" },
  { key: "NM_003355.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7351", geneDisplay: "UCP2" },
  { key: "NM_003356.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7352", geneDisplay: "UCP3" },
  { key: "NM_003357.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7356", geneDisplay: "SCGB1A1" },
  { key: "NM_003358.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7357", geneDisplay: "UGCG" },
  { key: "NM_003359.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7358", geneDisplay: "UGDH" },
  { key: "NM_003361.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7369", geneDisplay: "UMOD" },
  { key: "NM_003363.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7375", geneDisplay: "USP4" },
  { key: "NM_003364.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7378", geneDisplay: "UPP1" },
  { key: "NM_003365.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7384", geneDisplay: "UQCRC1" },
  { key: "NM_003366.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7385", geneDisplay: "UQCRC2" },
  { key: "NM_003367.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7392", geneDisplay: "USF2" },
  { key: "NM_003368.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7398", geneDisplay: "USP1" },
  { key: "NM_003369.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7405", geneDisplay: "UVRAG" },
  { key: "NM_003370.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7408", geneDisplay: "VASP" },
  { key: "NM_003372.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7411", geneDisplay: "VBP1" },
  { key: "NM_003374.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7416", geneDisplay: "VDAC1" },
  { key: "NM_003376.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7422", geneDisplay: "VEGFA" },
  { key: "NM_003377.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7423", geneDisplay: "VEGFB" },
  { key: "NM_003378.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7425", geneDisplay: "VGF" },
  { key: "NM_003380.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7431", geneDisplay: "VIM" },
  { key: "NM_003381.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7432", geneDisplay: "VIP" },
  { key: "NM_003382.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7434", geneDisplay: "VIPR2" },
  { key: "NM_003383.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7436", geneDisplay: "VLDLR" },
  { key: "NM_003384.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7443", geneDisplay: "VRK1" },
  { key: "NM_003385.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7447", geneDisplay: "VSNL1" },
  { key: "NM_003386.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7455", geneDisplay: "ZAN" },
  { key: "NM_003388.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7461", geneDisplay: "CLIP2" },
  { key: "NM_003390.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7465", geneDisplay: "WEE1" },
  { key: "NM_003391.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7472", geneDisplay: "WNT2" },
  { key: "NM_003392.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7474", geneDisplay: "WNT5A" },
  { key: "NM_003393.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7479", geneDisplay: "WNT8B" },
  { key: "NM_003394.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7480", geneDisplay: "WNT10B" },
  { key: "NM_003395.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7483", geneDisplay: "WNT9A" },
  { key: "NM_003396.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7484", geneDisplay: "WNT9B" },
  { key: "NM_003399.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7512", geneDisplay: "XPNPEP2" },
  { key: "NM_003400.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7514", geneDisplay: "XPO1" },
  { key: "NM_003401.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7518", geneDisplay: "XRCC4" },
  { key: "NM_003403.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7528", geneDisplay: "YY1" },
  { key: "NM_003405.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7533", geneDisplay: "YWHAH" },
  { key: "NM_003407.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7538", geneDisplay: "ZFP36" },
  { key: "NM_003408.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7539", geneDisplay: "ZFP37" },
  { key: "NM_003410.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7543", geneDisplay: "ZFX" },
  { key: "NM_003411.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "7544", geneDisplay: "ZFY" },
  { key: "NM_003412.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7545", geneDisplay: "ZIC1" },
  { key: "NM_003413.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7547", geneDisplay: "ZIC3" },
  { key: "NM_003414.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10308", geneDisplay: "ZNF267" },
  { key: "NM_003415.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10795", geneDisplay: "ZNF268" },
  { key: "NM_003416.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7553", geneDisplay: "ZNF7" },
  { key: "NM_003417.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9422", geneDisplay: "ZNF264" },
  { key: "NM_003418.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7555", geneDisplay: "CNBP" },
  { key: "NM_003420.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7584", geneDisplay: "ZNF35" },
  { key: "NM_003423.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7594", geneDisplay: "ZNF43" },
  { key: "NM_003425.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7596", geneDisplay: "ZNF45" },
  { key: "NM_003426.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7625", geneDisplay: "ZNF74" },
  { key: "NM_003427.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7629", geneDisplay: "ZNF76" },
  { key: "NM_003429.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7639", geneDisplay: "ZNF85" },
  { key: "NM_003430.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7644", geneDisplay: "ZNF91" },
  { key: "NM_003433.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7691", geneDisplay: "ZNF132" },
  { key: "NM_003435.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7693", geneDisplay: "ZNF134" },
  { key: "NM_003437.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7695", geneDisplay: "ZNF136" },
  { key: "NM_003439.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7586", geneDisplay: "ZKSCAN1" },
  { key: "NM_003440.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7699", geneDisplay: "ZNF140" },
  { key: "NM_003441.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7700", geneDisplay: "ZNF141" },
  { key: "NM_003442.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7702", geneDisplay: "ZNF143" },
  { key: "NM_003443.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7709", geneDisplay: "ZBTB17" },
  { key: "NM_003446.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7712", geneDisplay: "ZNF157" },
  { key: "NM_003449.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7726", geneDisplay: "TRIM26" },
  { key: "NM_003450.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7727", geneDisplay: "ZNF174" },
  { key: "NM_003452.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7743", geneDisplay: "ZNF189" },
  { key: "NM_003455.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7753", geneDisplay: "ZNF202" },
  { key: "NM_003458.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8927", geneDisplay: "BSN" },
  { key: "NM_003459.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7781", geneDisplay: "SLC30A3" },
  { key: "NM_003461.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7791", geneDisplay: "ZYX" },
  { key: "NM_003462.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7802", geneDisplay: "DNALI1" },
  { key: "NM_003463.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7803", geneDisplay: "PTP4A1" },
  { key: "NM_003465.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1118", geneDisplay: "CHIT1" },
  { key: "NM_003466.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7849", geneDisplay: "PAX8" },
  { key: "NM_003467.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7852", geneDisplay: "CXCR4" },
  { key: "NM_003468.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7855", geneDisplay: "FZD5" },
  { key: "NM_003469.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7857", geneDisplay: "SCG2" },
  { key: "NM_003470.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7874", geneDisplay: "USP7" },
  { key: "NM_003472.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7913", geneDisplay: "DEK" },
  { key: "NM_003473.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8027", geneDisplay: "STAM" },
  { key: "NM_003475.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8045", geneDisplay: "RASSF7" },
  { key: "NM_003476.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8048", geneDisplay: "CSRP3" },
  { key: "NM_003477.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8050", geneDisplay: "PDHX" },
  { key: "NM_003478.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8065", geneDisplay: "CUL5" },
  { key: "NM_003480.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8076", geneDisplay: "MFAP5" },
  { key: "NM_003482.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8085", geneDisplay: "KMT2D" },
  { key: "NM_003483.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8091", geneDisplay: "HMGA2" },
  { key: "NM_003486.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8140", geneDisplay: "SLC7A5" },
  { key: "NM_003488.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8165", geneDisplay: "AKAP1" },
  { key: "NM_003489.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8204", geneDisplay: "NRIP1" },
  { key: "NM_003490.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8224", geneDisplay: "SYN3" },
  { key: "NM_003491.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8260", geneDisplay: "NAA10" },
  { key: "NM_003492.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8269", geneDisplay: "TMEM187" },
  { key: "NM_003493.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8290", geneDisplay: "H3-4" },
  { key: "NM_003495.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8294", geneDisplay: "H4C9" },
  { key: "NM_003498.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8303", geneDisplay: "SNN" },
  { key: "NM_003500.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8309", geneDisplay: "ACOX2" },
  { key: "NM_003501.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8310", geneDisplay: "ACOX3" },
  { key: "NM_003502.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8312", geneDisplay: "AXIN1" },
  { key: "NM_003503.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8317", geneDisplay: "CDC7" },
  { key: "NM_003504.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8318", geneDisplay: "CDC45" },
  { key: "NM_003505.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8321", geneDisplay: "FZD1" },
  { key: "NM_003506.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8323", geneDisplay: "FZD6" },
  { key: "NM_003507.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8324", geneDisplay: "FZD7" },
  { key: "NM_003508.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8326", geneDisplay: "FZD9" },
  { key: "NM_003509.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8329", geneDisplay: "H2AC13" },
  { key: "NM_003510.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8330", geneDisplay: "H2AC15" },
  { key: "NM_003511.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8332", geneDisplay: "H2AC16" },
  { key: "NM_003512.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8334", geneDisplay: "H2AC6" },
  { key: "NM_003513.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8335", geneDisplay: "H2AC4" },
  { key: "NM_003514.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8336", geneDisplay: "H2AC17" },
  { key: "NM_003516.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8337", geneDisplay: "H2AC18" },
  { key: "NM_003517.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8338", geneDisplay: "H2AC20" },
  { key: "NM_003518.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8339", geneDisplay: "H2BC8" },
  { key: "NM_003519.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8340", geneDisplay: "H2BC13" },
  { key: "NM_003520.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8341", geneDisplay: "H2BC15" },
  { key: "NM_003521.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8342", geneDisplay: "H2BC14" },
  { key: "NM_003522.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8343", geneDisplay: "H2BC7" },
  { key: "NM_003523.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8344", geneDisplay: "H2BC6" },
  { key: "NM_003524.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8345", geneDisplay: "H2BC9" },
  { key: "NM_003525.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8346", geneDisplay: "H2BC10" },
  { key: "NM_003526.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8347", geneDisplay: "H2BC4" },
  { key: "NM_003527.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8348", geneDisplay: "H2BC17" },
  { key: "NM_003528.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8349", geneDisplay: "H2BC21" },
  { key: "NM_003529.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8350", geneDisplay: "H3C1" },
  { key: "NM_003531.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8352", geneDisplay: "H3C3" },
  { key: "NM_003532.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8353", geneDisplay: "H3C6" },
  { key: "NM_003533.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8354", geneDisplay: "H3C11" },
  { key: "NM_003534.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8355", geneDisplay: "H3C8" },
  { key: "NM_003535.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8356", geneDisplay: "H3C12" },
  { key: "NM_003536.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8357", geneDisplay: "H3C10" },
  { key: "NM_003537.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8358", geneDisplay: "H3C2" },
  { key: "NM_003538.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8359", geneDisplay: "H4C1" },
  { key: "NM_003539.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8360", geneDisplay: "H4C4" },
  { key: "NM_003540.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8361", geneDisplay: "H4C6" },
  { key: "NM_003541.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8362", geneDisplay: "H4C12" },
  { key: "NM_003542.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8364", geneDisplay: "H4C3" },
  { key: "NM_003543.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8365", geneDisplay: "H4C8" },
  { key: "NM_003544.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8366", geneDisplay: "H4C2" },
  { key: "NM_003545.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8367", geneDisplay: "H4C5" },
  { key: "NM_003546.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8368", geneDisplay: "H4C13" },
  { key: "NM_003547.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8369", geneDisplay: "H4C7" },
  { key: "NM_003548.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8370", geneDisplay: "H4C14" },
  { key: "NM_003549.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8372", geneDisplay: "HYAL3" },
  { key: "NM_003551.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8382", geneDisplay: "NME5" },
  { key: "NM_003553.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8387", geneDisplay: "OR1E1" },
  { key: "NM_003554.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8388", geneDisplay: "OR1E2" },
  { key: "NM_003555.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8390", geneDisplay: "OR1G1" },
  { key: "NM_003558.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8395", geneDisplay: "PIP5K1B" },
  { key: "NM_003559.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8396", geneDisplay: "PIP4K2B" },
  { key: "NM_003560.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8398", geneDisplay: "PLA2G6" },
  { key: "NM_003561.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8399", geneDisplay: "PLA2G10" },
  { key: "NM_003562.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8402", geneDisplay: "SLC25A11" },
  { key: "NM_003564.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8407", geneDisplay: "TAGLN2" },
  { key: "NM_003565.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8408", geneDisplay: "ULK1" },
  { key: "NM_003566.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8411", geneDisplay: "EEA1" },
  { key: "NM_003567.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8412", geneDisplay: "BCAR3" },
  { key: "NM_003568.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8416", geneDisplay: "ANXA9" },
  { key: "NM_003569.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8417", geneDisplay: "STX7" },
  { key: "NM_003571.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8419", geneDisplay: "BFSP2" },
  { key: "NM_003575.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8427", geneDisplay: "ZNF282" },
  { key: "NM_003577.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8433", geneDisplay: "UTF1" },
  { key: "NM_003578.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8435", geneDisplay: "SOAT2" },
  { key: "NM_003579.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8438", geneDisplay: "RAD54L" },
  { key: "NM_003580.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8439", geneDisplay: "NSMAF" },
  { key: "NM_003581.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8440", geneDisplay: "NCK2" },
  { key: "NM_003582.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8444", geneDisplay: "DYRK3" },
  { key: "NM_003584.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8446", geneDisplay: "DUSP11" },
  { key: "NM_003585.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8447", geneDisplay: "DOC2B" },
  { key: "NM_003586.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8448", geneDisplay: "DOC2A" },
  { key: "NM_003587.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8449", geneDisplay: "DHX16" },
  { key: "NM_003590.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8452", geneDisplay: "CUL3" },
  { key: "NM_003591.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8453", geneDisplay: "CUL2" },
  { key: "NM_003592.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8454", geneDisplay: "CUL1" },
  { key: "NM_003594.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8458", geneDisplay: "TTF2" },
  { key: "NM_003595.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8459", geneDisplay: "TPST2" },
  { key: "NM_003596.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8460", geneDisplay: "TPST1" },
  { key: "NM_003597.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8462", geneDisplay: "KLF11" },
  { key: "NM_003599.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8464", geneDisplay: "SUPT3H" },
  { key: "NM_003601.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8467", geneDisplay: "SMARCA5" },
  { key: "NM_003602.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8468", geneDisplay: "FKBP6" },
  { key: "NM_003608.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8477", geneDisplay: "GPR65" },
  { key: "NM_003609.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8479", geneDisplay: "HIRIP3" },
  { key: "NM_003610.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8480", geneDisplay: "RAE1" },
  { key: "NM_003611.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8481", geneDisplay: "OFD1" },
  { key: "NM_003612.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8482", geneDisplay: "SEMA7A" },
  { key: "NM_003613.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8483", geneDisplay: "CILP" },
  { key: "NM_003614.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8484", geneDisplay: "GALR3" },
  { key: "NM_003616.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8487", geneDisplay: "GEMIN2" },
  { key: "NM_003617.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8490", geneDisplay: "RGS5" },
  { key: "NM_003618.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8491", geneDisplay: "MAP4K3" },
  { key: "NM_003619.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8492", geneDisplay: "PRSS12" },
  { key: "NM_003620.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8493", geneDisplay: "PPM1D" },
  { key: "NM_003621.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8495", geneDisplay: "PPFIBP2" },
  { key: "NM_003622.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8496", geneDisplay: "PPFIBP1" },
  { key: "NM_003625.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8499", geneDisplay: "PPFIA2" },
  { key: "NM_003626.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8500", geneDisplay: "PPFIA1" },
  { key: "NM_003627.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8501", geneDisplay: "SLC43A1" },
  { key: "NM_003628.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8502", geneDisplay: "PKP4" },
  { key: "NM_003629.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8503", geneDisplay: "PIK3R3" },
  { key: "NM_003630.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8504", geneDisplay: "PEX3" },
  { key: "NM_003631.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8505", geneDisplay: "PARG" },
  { key: "NM_003632.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8506", geneDisplay: "CNTNAP1" },
  { key: "NM_003633.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8507", geneDisplay: "ENC1" },
  { key: "NM_003634.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8508", geneDisplay: "NIPSNAP1" },
  { key: "NM_003635.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8509", geneDisplay: "NDST2" },
  { key: "NM_003637.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8515", geneDisplay: "ITGA10" },
  { key: "NM_003638.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8516", geneDisplay: "ITGA8" },
  { key: "NM_003640.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8518", geneDisplay: "ELP1" },
  { key: "NM_003641.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8519", geneDisplay: "IFITM1" },
  { key: "NM_003642.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8520", geneDisplay: "HAT1" },
  { key: "NM_003643.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8521", geneDisplay: "GCM1" },
  { key: "NM_003645.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11001", geneDisplay: "SLC27A2" },
  { key: "NM_003647.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8526", geneDisplay: "DGKE" },
  { key: "NM_003650.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8530", geneDisplay: "CST7" },
  { key: "NM_003651.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8531", geneDisplay: "YBX3" },
  { key: "NM_003653.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8533", geneDisplay: "COPS3" },
  { key: "NM_003654.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8534", geneDisplay: "CHST1" },
  { key: "NM_003655.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8535", geneDisplay: "CBX4" },
  { key: "NM_003656.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8536", geneDisplay: "CAMK1" },
  { key: "NM_003658.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8538", geneDisplay: "BARX2" },
  { key: "NM_003659.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8540", geneDisplay: "AGPS" },
  { key: "NM_003660.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8541", geneDisplay: "PPFIA3" },
  { key: "NM_003661.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8542", geneDisplay: "APOL1" },
  { key: "NM_003664.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8546", geneDisplay: "AP3B1" },
  { key: "NM_003665.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8547", geneDisplay: "FCN3" },
  { key: "NM_003667.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8549", geneDisplay: "LGR5" },
  { key: "NM_003668.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8550", geneDisplay: "MAPKAPK5" },
  { key: "NM_003670.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8553", geneDisplay: "BHLHE40" },
  { key: "NM_003672.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8556", geneDisplay: "CDC14A" },
  { key: "NM_003673.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8557", geneDisplay: "TCAP" },
  { key: "NM_003675.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8559", geneDisplay: "PRPF18" },
  { key: "NM_003676.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8560", geneDisplay: "DEGS1" },
  { key: "NM_003677.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8562", geneDisplay: "DENR" },
  { key: "NM_003678.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8563", geneDisplay: "THOC5" },
  { key: "NM_003679.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8564", geneDisplay: "KMO" },
  { key: "NM_003680.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8565", geneDisplay: "YARS1" },
  { key: "NM_003681.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8566", geneDisplay: "PDXK" },
  { key: "NM_003682.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8567", geneDisplay: "MADD" },
  { key: "NM_003683.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8568", geneDisplay: "RRP1" },
  { key: "NM_003687.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8572", geneDisplay: "PDLIM4" },
  { key: "NM_003689.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8574", geneDisplay: "AKR7A2" },
  { key: "NM_003690.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8575", geneDisplay: "PRKRA" },
  { key: "NM_003692.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8577", geneDisplay: "TMEFF1" },
  { key: "NM_003693.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8578", geneDisplay: "SCARF1" },
  { key: "NM_003695.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8581", geneDisplay: "LY6D" },
  { key: "NM_003696.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8590", geneDisplay: "OR6A2" },
  { key: "NM_003697.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338674", geneDisplay: "OR5F1" },
  { key: "NM_003700.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120776", geneDisplay: "OR2D2" },
  { key: "NM_003701.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8600", geneDisplay: "TNFSF11" },
  { key: "NM_003705.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8604", geneDisplay: "SLC25A12" },
  { key: "NM_003706.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8605", geneDisplay: "PLA2G4C" },
  { key: "NM_003707.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8607", geneDisplay: "RUVBL1" },
  { key: "NM_003708.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8608", geneDisplay: "RDH16" },
  { key: "NM_003709.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8609", geneDisplay: "KLF7" },
  { key: "NM_003710.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6692", geneDisplay: "SPINT1" },
  { key: "NM_003711.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8611", geneDisplay: "PLPP1" },
  { key: "NM_003712.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8612", geneDisplay: "PLPP2" },
  { key: "NM_003713.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8613", geneDisplay: "PLPP3" },
  { key: "NM_003714.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8614", geneDisplay: "STC2" },
  { key: "NM_003715.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8615", geneDisplay: "USO1" },
  { key: "NM_003716.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8618", geneDisplay: "CADPS" },
  { key: "NM_003717.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8620", geneDisplay: "NPFF" },
  { key: "NM_003718.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8621", geneDisplay: "CDK13" },
  { key: "NM_003719.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8622", geneDisplay: "PDE8B" },
  { key: "NM_003720.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8624", geneDisplay: "PSMG1" },
  { key: "NM_003721.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8625", geneDisplay: "RFXANK" },
  { key: "NM_003722.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8626", geneDisplay: "TP63" },
  { key: "NM_003724.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8629", geneDisplay: "JRK" },
  { key: "NM_003725.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8630", geneDisplay: "HSD17B6" },
  { key: "NM_003726.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8631", geneDisplay: "SKAP1" },
  { key: "NM_003728.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8633", geneDisplay: "UNC5C" },
  { key: "NM_003729.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8634", geneDisplay: "RTCA" },
  { key: "NM_003730.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8635", geneDisplay: "RNASET2" },
  { key: "NM_003731.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8636", geneDisplay: "SSNA1" },
  { key: "NM_003732.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8637", geneDisplay: "EIF4EBP3" },
  { key: "NM_003733.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8638", geneDisplay: "OASL" },
  { key: "NM_003734.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8639", geneDisplay: "AOC3" },
  { key: "NM_003735.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26025", geneDisplay: "PCDHGA12" },
  { key: "NM_003736.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8641", geneDisplay: "PCDHGB4" },
  { key: "NM_003737.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8642", geneDisplay: "DCHS1" },
  { key: "NM_003738.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8643", geneDisplay: "PTCH2" },
  { key: "NM_003739.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8644", geneDisplay: "AKR1C3" },
  { key: "NM_003740.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8645", geneDisplay: "KCNK5" },
  { key: "NM_003741.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8646", geneDisplay: "CHRD" },
  { key: "NM_003742.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8647", geneDisplay: "ABCB11" },
  { key: "NM_003743.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8648", geneDisplay: "NCOA1" },
  { key: "NM_003745.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8651", geneDisplay: "SOCS1" },
  { key: "NM_003746.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8655", geneDisplay: "DYNLL1" },
  { key: "NM_003747.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8658", geneDisplay: "TNKS" },
  { key: "NM_003748.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8659", geneDisplay: "ALDH4A1" },
  { key: "NM_003749.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8660", geneDisplay: "IRS2" },
  { key: "NM_003750.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8661", geneDisplay: "EIF3A" },
  { key: "NM_003752.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8663", geneDisplay: "EIF3C" },
  { key: "NM_003753.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8664", geneDisplay: "EIF3D" },
  { key: "NM_003754.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8665", geneDisplay: "EIF3F" },
  { key: "NM_003755.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8666", geneDisplay: "EIF3G" },
  { key: "NM_003756.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8667", geneDisplay: "EIF3H" },
  { key: "NM_003757.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8668", geneDisplay: "EIF3I" },
  { key: "NM_003758.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8669", geneDisplay: "EIF3J" },
  { key: "NM_003761.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8673", geneDisplay: "VAMP8" },
  { key: "NM_003762.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8674", geneDisplay: "VAMP4" },
  { key: "NM_003764.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8676", geneDisplay: "STX11" },
  { key: "NM_003765.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8677", geneDisplay: "STX10" },
  { key: "NM_003768.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8682", geneDisplay: "PEA15" },
  { key: "NM_003769.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8683", geneDisplay: "SRSF9" },
  { key: "NM_003770.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8688", geneDisplay: "KRT37" },
  { key: "NM_003771.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8689", geneDisplay: "KRT36" },
  { key: "NM_003773.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8692", geneDisplay: "HYAL2" },
  { key: "NM_003774.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8693", geneDisplay: "GALNT4" },
  { key: "NM_003775.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8698", geneDisplay: "S1PR4" },
  { key: "NM_003776.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "64976", geneDisplay: "MRPL40" },
  { key: "NM_003778.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8702", geneDisplay: "B4GALT4" },
  { key: "NM_003779.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8703", geneDisplay: "B4GALT3" },
  { key: "NM_003780.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8704", geneDisplay: "B4GALT2" },
  { key: "NM_003781.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8706", geneDisplay: "B3GALNT1" },
  { key: "NM_003782.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8705", geneDisplay: "B3GALT4" },
  { key: "NM_003783.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8707", geneDisplay: "B3GALT2" },
  { key: "NM_003784.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8710", geneDisplay: "SERPINB7" },
  { key: "NM_003785.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8712", geneDisplay: "PAGE1" },
  { key: "NM_003786.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8714", geneDisplay: "ABCC3" },
  { key: "NM_003787.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8715", geneDisplay: "NOL4" },
  { key: "NM_003789.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8717", geneDisplay: "TRADD" },
  { key: "NM_003790.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8718", geneDisplay: "TNFRSF25" },
  { key: "NM_003791.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8720", geneDisplay: "MBTPS1" },
  { key: "NM_003792.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8721", geneDisplay: "EDF1" },
  { key: "NM_003793.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8722", geneDisplay: "CTSF" },
  { key: "NM_003794.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8723", geneDisplay: "SNX4" },
  { key: "NM_003795.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8724", geneDisplay: "SNX3" },
  { key: "NM_003796.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8725", geneDisplay: "URI1" },
  { key: "NM_003797.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8726", geneDisplay: "EED" },
  { key: "NM_003798.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8727", geneDisplay: "CTNNAL1" },
  { key: "NM_003799.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8731", geneDisplay: "RNMT" },
  { key: "NM_003800.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8732", geneDisplay: "RNGTT" },
  { key: "NM_003801.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8733", geneDisplay: "GPAA1" },
  { key: "NM_003802.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8735", geneDisplay: "MYH13" },
  { key: "NM_003803.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8736", geneDisplay: "MYOM1" },
  { key: "NM_003805.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8738", geneDisplay: "CRADD" },
  { key: "NM_003806.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8739", geneDisplay: "HRK" },
  { key: "NM_003808.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8741", geneDisplay: "TNFSF13" },
  { key: "NM_003809.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8742", geneDisplay: "TNFSF12" },
  { key: "NM_003810.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8743", geneDisplay: "TNFSF10" },
  { key: "NM_003811.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8744", geneDisplay: "TNFSF9" },
  { key: "NM_003812.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8745", geneDisplay: "ADAM23" },
  { key: "NM_003813.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8747", geneDisplay: "ADAM21" },
  { key: "NM_003814.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8748", geneDisplay: "ADAM20" },
  { key: "NM_003816.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8754", geneDisplay: "ADAM9" },
  { key: "NM_003817.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8756", geneDisplay: "ADAM7" },
  { key: "NM_003818.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8760", geneDisplay: "CDS2" },
  { key: "NM_003820.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8764", geneDisplay: "TNFRSF14" },
  { key: "NM_003821.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8767", geneDisplay: "RIPK2" },
  { key: "NM_003823.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8771", geneDisplay: "TNFRSF6B" },
  { key: "NM_003824.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8772", geneDisplay: "FADD" },
  { key: "NM_003825.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8773", geneDisplay: "SNAP23" },
  { key: "NM_003826.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8774", geneDisplay: "NAPG" },
  { key: "NM_003827.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8775", geneDisplay: "NAPA" },
  { key: "NM_003830.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8778", geneDisplay: "SIGLEC5" },
  { key: "NM_003831.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8780", geneDisplay: "RIOK3" },
  { key: "NM_003835.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8787", geneDisplay: "RGS9" },
  { key: "NM_003836.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8788", geneDisplay: "DLK1" },
  { key: "NM_003837.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8789", geneDisplay: "FBP2" },
  { key: "NM_003838.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8790", geneDisplay: "FPGT" },
  { key: "NM_003839.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8792", geneDisplay: "TNFRSF11A" },
  { key: "NM_003840.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8793", geneDisplay: "TNFRSF10D" },
  { key: "NM_003841.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8794", geneDisplay: "TNFRSF10C" },
  { key: "NM_003842.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8795", geneDisplay: "TNFRSF10B" },
  { key: "NM_003844.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8797", geneDisplay: "TNFRSF10A" },
  { key: "NM_003846.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8799", geneDisplay: "PEX11B" },
  { key: "NM_003847.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8800", geneDisplay: "PEX11A" },
  { key: "NM_003848.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8801", geneDisplay: "SUCLG2" },
  { key: "NM_003849.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8802", geneDisplay: "SUCLG1" },
  { key: "NM_003850.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8803", geneDisplay: "SUCLA2" },
  { key: "NM_003851.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8804", geneDisplay: "CREG1" },
  { key: "NM_003854.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8808", geneDisplay: "IL1RL2" },
  { key: "NM_003855.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8809", geneDisplay: "IL18R1" },
  { key: "NM_003857.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8811", geneDisplay: "GALR2" },
  { key: "NM_003859.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8813", geneDisplay: "DPM1" },
  { key: "NM_003860.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8815", geneDisplay: "BANF1" },
  { key: "NM_003861.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8816", geneDisplay: "DCAF5" },
  { key: "NM_003862.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8817", geneDisplay: "FGF18" },
  { key: "NM_003863.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8818", geneDisplay: "DPM2" },
  { key: "NM_003864.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8819", geneDisplay: "SAP30" },
  { key: "NM_003865.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8820", geneDisplay: "HESX1" },
  { key: "NM_003867.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8822", geneDisplay: "FGF17" },
  { key: "NM_003868.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8823", geneDisplay: "FGF16" },
  { key: "NM_003870.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8826", geneDisplay: "IQGAP1" },
  { key: "NM_003872.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8828", geneDisplay: "NRP2" },
  { key: "NM_003873.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8829", geneDisplay: "NRP1" },
  { key: "NM_003874.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8832", geneDisplay: "CD84" },
  { key: "NM_003875.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8833", geneDisplay: "GMPS" },
  { key: "NM_003876.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8834", geneDisplay: "TMEM11" },
  { key: "NM_003878.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8836", geneDisplay: "GGH" },
  { key: "NM_003879.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8837", geneDisplay: "CFLAR" },
  { key: "NM_003881.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8839", geneDisplay: "CCN5" },
  { key: "NM_003882.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8840", geneDisplay: "CCN4" },
  { key: "NM_003883.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8841", geneDisplay: "HDAC3" },
  { key: "NM_003884.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8850", geneDisplay: "KAT2B" },
  { key: "NM_003885.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8851", geneDisplay: "CDK5R1" },
  { key: "NM_003886.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8852", geneDisplay: "AKAP4" },
  { key: "NM_003887.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8853", geneDisplay: "ASAP2" },
  { key: "NM_003888.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8854", geneDisplay: "ALDH1A2" },
  { key: "NM_003889.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8856", geneDisplay: "NR1I2" },
  { key: "NM_003890.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8857", geneDisplay: "FCGBP" },
  { key: "NM_003891.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8858", geneDisplay: "PROZ" },
  { key: "NM_003896.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8869", geneDisplay: "ST3GAL5" },
  { key: "NM_003897.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8870", geneDisplay: "IER3" },
  { key: "NM_003898.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8871", geneDisplay: "SYNJ2" },
  { key: "NM_003900.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8878", geneDisplay: "SQSTM1" },
  { key: "NM_003901.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8879", geneDisplay: "SGPL1" },
  { key: "NM_003902.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8880", geneDisplay: "FUBP1" },
  { key: "NM_003904.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8882", geneDisplay: "ZPR1" },
  { key: "NM_003905.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8883", geneDisplay: "NAE1" },
  { key: "NM_003906.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8888", geneDisplay: "MCM3AP" },
  { key: "NM_003907.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8893", geneDisplay: "EIF2B5" },
  { key: "NM_003908.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8894", geneDisplay: "EIF2S2" },
  { key: "NM_003909.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8895", geneDisplay: "CPNE3" },
  { key: "NM_003910.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8896", geneDisplay: "BUD31" },
  { key: "NM_003913.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8899", geneDisplay: "PRPF4B" },
  { key: "NM_003914.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8900", geneDisplay: "CCNA1" },
  { key: "NM_003917.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8906", geneDisplay: "AP1G2" },
  { key: "NM_003919.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8910", geneDisplay: "SGCE" },
  { key: "NM_003920.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8914", geneDisplay: "TIMELESS" },
  { key: "NM_003921.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8915", geneDisplay: "BCL10" },
  { key: "NM_003922.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8925", geneDisplay: "HERC1" },
  { key: "NM_003923.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8928", geneDisplay: "FOXH1" },
  { key: "NM_003924.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8929", geneDisplay: "PHOX2B" },
  { key: "NM_003927.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8932", geneDisplay: "MBD2" },
  { key: "NM_003929.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8934", geneDisplay: "RAB29" },
  { key: "NM_003930.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8935", geneDisplay: "SKAP2" },
  { key: "NM_003931.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8936", geneDisplay: "WASF1" },
  { key: "NM_003932.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6767", geneDisplay: "ST13" },
  { key: "NM_003934.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8939", geneDisplay: "FUBP3" },
  { key: "NM_003936.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8941", geneDisplay: "CDK5R2" },
  { key: "NM_003937.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8942", geneDisplay: "KYNU" },
  { key: "NM_003940.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8975", geneDisplay: "USP13" },
  { key: "NM_003941.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8976", geneDisplay: "WASL" },
  { key: "NM_003942.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8986", geneDisplay: "RPS6KA4" },
  { key: "NM_003943.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8987", geneDisplay: "STBD1" },
  { key: "NM_003944.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8991", geneDisplay: "SELENBP1" },
  { key: "NM_003945.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8992", geneDisplay: "ATP6V0E1" },
  { key: "NM_003950.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9002", geneDisplay: "F2RL3" },
  { key: "NM_003952.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6199", geneDisplay: "RPS6KB2" },
  { key: "NM_003953.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9019", geneDisplay: "MPZL1" },
  { key: "NM_003954.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9020", geneDisplay: "MAP3K14" },
  { key: "NM_003955.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9021", geneDisplay: "SOCS3" },
  { key: "NM_003956.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9023", geneDisplay: "CH25H" },
  { key: "NM_003958.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9025", geneDisplay: "RNF8" },
  { key: "NM_003959.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9026", geneDisplay: "HIP1R" },
  { key: "NM_003960.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9027", geneDisplay: "NAT8" },
  { key: "NM_003963.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9032", geneDisplay: "TM4SF5" },
  { key: "NM_003965.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9034", geneDisplay: "CCRL2" },
  { key: "NM_003966.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9037", geneDisplay: "SEMA5A" },
  { key: "NM_003967.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9038", geneDisplay: "TAAR5" },
  { key: "NM_003968.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9039", geneDisplay: "UBA3" },
  { key: "NM_003969.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9040", geneDisplay: "UBE2M" },
  { key: "NM_003970.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9172", geneDisplay: "MYOM2" },
  { key: "NM_003972.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9044", geneDisplay: "BTAF1" },
  { key: "NM_003974.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9046", geneDisplay: "DOK2" },
  { key: "NM_003975.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9047", geneDisplay: "SH2D2A" },
  { key: "NM_003977.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9049", geneDisplay: "AIP" },
  { key: "NM_003978.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9051", geneDisplay: "PSTPIP1" },
  { key: "NM_003979.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9052", geneDisplay: "GPRC5A" },
  { key: "NM_003980.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9053", geneDisplay: "MAP7" },
  { key: "NM_003981.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9055", geneDisplay: "PRC1" },
  { key: "NM_003982.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9056", geneDisplay: "SLC7A7" },
  { key: "NM_003983.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9057", geneDisplay: "SLC7A6" },
  { key: "NM_003984.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9058", geneDisplay: "SLC13A2" },
  { key: "NM_003985.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8711", geneDisplay: "TNK1" },
  { key: "NM_003986.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8424", geneDisplay: "BBOX1" },
  { key: "NM_003995.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4882", geneDisplay: "NPR2" },
  { key: "NM_003998.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4790", geneDisplay: "NFKB1" },
  { key: "NM_003999.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9180", geneDisplay: "OSMR" },
  { key: "NM_004000.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1117", geneDisplay: "CHI3L2" },
  { key: "NM_004004.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2706", geneDisplay: "GJB2" },
  { key: "NM_004006.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1756", geneDisplay: "DMD" },
  { key: "NM_004035.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51", geneDisplay: "ACOX1" },
  { key: "NM_004036.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "109", geneDisplay: "ADCY3" },
  { key: "NM_004038.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "276", geneDisplay: "AMY1A" },
  { key: "NM_004039.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "302", geneDisplay: "ANXA2" },
  { key: "NM_004040.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388", geneDisplay: "RHOB" },
  { key: "NM_004041.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "408", geneDisplay: "ARRB1" },
  { key: "NM_004044.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "471", geneDisplay: "ATIC" },
  { key: "NM_004045.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "475", geneDisplay: "ATOX1" },
  { key: "NM_004046.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "498", geneDisplay: "ATP5F1A" },
  { key: "NM_004047.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "533", geneDisplay: "ATP6V0B" },
  { key: "NM_004048.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "567", geneDisplay: "B2M" },
  { key: "NM_004049.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "597", geneDisplay: "BCL2A1" },
  { key: "NM_004050.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "599", geneDisplay: "BCL2L2" },
  { key: "NM_004052.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "664", geneDisplay: "BNIP3" },
  { key: "NM_004053.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "705", geneDisplay: "BYSL" },
  { key: "NM_004054.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "719", geneDisplay: "C3AR1" },
  { key: "NM_004055.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "726", geneDisplay: "CAPN5" },
  { key: "NM_004056.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "767", geneDisplay: "CA8" },
  { key: "NM_004057.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "795", geneDisplay: "S100G" },
  { key: "NM_004058.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "828", geneDisplay: "CAPS" },
  { key: "NM_004059.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "883", geneDisplay: "KYAT1" },
  { key: "NM_004060.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "900", geneDisplay: "CCNG1" },
  { key: "NM_004061.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1010", geneDisplay: "CDH12" },
  { key: "NM_004062.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1014", geneDisplay: "CDH16" },
  { key: "NM_004063.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1015", geneDisplay: "CDH17" },
  { key: "NM_004064.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1027", geneDisplay: "CDKN1B" },
  { key: "NM_004066.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1068", geneDisplay: "CETN1" },
  { key: "NM_004067.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1124", geneDisplay: "CHN2" },
  { key: "NM_004068.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1173", geneDisplay: "AP2M1" },
  { key: "NM_004069.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1175", geneDisplay: "AP2S1" },
  { key: "NM_004070.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1187", geneDisplay: "CLCNKA" },
  { key: "NM_004071.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1195", geneDisplay: "CLK1" },
  { key: "NM_004073.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1263", geneDisplay: "PLK3" },
  { key: "NM_004074.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1351", geneDisplay: "COX8A" },
  { key: "NM_004075.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1407", geneDisplay: "CRY1" },
  { key: "NM_004076.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1417", geneDisplay: "CRYBB3" },
  { key: "NM_004077.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1431", geneDisplay: "CS" },
  { key: "NM_004078.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1465", geneDisplay: "CSRP1" },
  { key: "NM_004079.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1520", geneDisplay: "CTSS" },
  { key: "NM_004081.7", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "1617", geneDisplay: "DAZ1" },
  { key: "NM_004082.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1639", geneDisplay: "DCTN1" },
  { key: "NM_004083.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1649", geneDisplay: "DDIT3" },
  { key: "NM_004084.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1667", geneDisplay: "DEFA1" },
  { key: "NM_004085.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1678", geneDisplay: "TIMM8A" },
  { key: "NM_004086.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1690", geneDisplay: "COCH" },
  { key: "NM_004088.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1791", geneDisplay: "DNTT" },
  { key: "NM_004090.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1845", geneDisplay: "DUSP3" },
  { key: "NM_004091.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1870", geneDisplay: "E2F2" },
  { key: "NM_004092.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1892", geneDisplay: "ECHS1" },
  { key: "NM_004093.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1948", geneDisplay: "EFNB2" },
  { key: "NM_004094.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1965", geneDisplay: "EIF2S1" },
  { key: "NM_004095.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1978", geneDisplay: "EIF4EBP1" },
  { key: "NM_004096.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1979", geneDisplay: "EIF4EBP2" },
  { key: "NM_004097.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2016", geneDisplay: "EMX1" },
  { key: "NM_004098.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2018", geneDisplay: "EMX2" },
  { key: "NM_004099.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2040", geneDisplay: "STOM" },
  { key: "NM_004100.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2070", geneDisplay: "EYA4" },
  { key: "NM_004101.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2151", geneDisplay: "F2RL2" },
  { key: "NM_004102.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2170", geneDisplay: "FABP3" },
  { key: "NM_004104.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2194", geneDisplay: "FASN" },
  { key: "NM_004106.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2207", geneDisplay: "FCER1G" },
  { key: "NM_004108.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2220", geneDisplay: "FCN2" },
  { key: "NM_004109.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2230", geneDisplay: "FDX1" },
  { key: "NM_004111.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2237", geneDisplay: "FEN1" },
  { key: "NM_004112.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2256", geneDisplay: "FGF11" },
  { key: "NM_004113.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2257", geneDisplay: "FGF12" },
  { key: "NM_004114.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2258", geneDisplay: "FGF13" },
  { key: "NM_004115.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2259", geneDisplay: "FGF14" },
  { key: "NM_004116.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2281", geneDisplay: "FKBP1B" },
  { key: "NM_004117.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2289", geneDisplay: "FKBP5" },
  { key: "NM_004118.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2307", geneDisplay: "FOXS1" },
  { key: "NM_004119.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2322", geneDisplay: "FLT3" },
  { key: "NM_004120.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2634", geneDisplay: "GBP2" },
  { key: "NM_004121.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2687", geneDisplay: "GGT5" },
  { key: "NM_004123.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2695", geneDisplay: "GIP" },
  { key: "NM_004124.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2764", geneDisplay: "GMFB" },
  { key: "NM_004126.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2791", geneDisplay: "GNG11" },
  { key: "NM_004128.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2963", geneDisplay: "GTF2F2" },
  { key: "NM_004130.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2992", geneDisplay: "GYG1" },
  { key: "NM_004131.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3002", geneDisplay: "GZMB" },
  { key: "NM_004132.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3026", geneDisplay: "HABP2" },
  { key: "NM_004133.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3174", geneDisplay: "HNF4G" },
  { key: "NM_004134.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3313", geneDisplay: "HSPA9" },
  { key: "NM_004135.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3421", geneDisplay: "IDH3G" },
  { key: "NM_004136.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3658", geneDisplay: "IREB2" },
  { key: "NM_004137.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3779", geneDisplay: "KCNMB1" },
  { key: "NM_004138.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3883", geneDisplay: "KRT33A" },
  { key: "NM_004139.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3929", geneDisplay: "LBP" },
  { key: "NM_004140.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3996", geneDisplay: "LLGL1" },
  { key: "NM_004145.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4650", geneDisplay: "MYO9B" },
  { key: "NM_004146.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4713", geneDisplay: "NDUFB7" },
  { key: "NM_004147.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4733", geneDisplay: "DRG1" },
  { key: "NM_004148.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4814", geneDisplay: "NINJ1" },
  { key: "NM_004152.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4946", geneDisplay: "OAZ1" },
  { key: "NM_004153.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4998", geneDisplay: "ORC1" },
  { key: "NM_004155.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5272", geneDisplay: "SERPINB9" },
  { key: "NM_004157.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5576", geneDisplay: "PRKAR2A" },
  { key: "NM_004158.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5623", geneDisplay: "PSPN" },
  { key: "NM_004161.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5861", geneDisplay: "RAB1A" },
  { key: "NM_004162.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5868", geneDisplay: "RAB5A" },
  { key: "NM_004163.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5874", geneDisplay: "RAB27B" },
  { key: "NM_004164.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5948", geneDisplay: "RBP2" },
  { key: "NM_004165.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6236", geneDisplay: "RRAD" },
  { key: "NM_004168.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6389", geneDisplay: "SDHA" },
  { key: "NM_004169.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6470", geneDisplay: "SHMT1" },
  { key: "NM_004170.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6505", geneDisplay: "SLC1A1" },
  { key: "NM_004171.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6506", geneDisplay: "SLC1A2" },
  { key: "NM_004172.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6507", geneDisplay: "SLC1A3" },
  { key: "NM_004173.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6545", geneDisplay: "SLC7A4" },
  { key: "NM_004174.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6550", geneDisplay: "SLC9A3" },
  { key: "NM_004175.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6634", geneDisplay: "SNRPD3" },
  { key: "NM_004176.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6720", geneDisplay: "SREBF1" },
  { key: "NM_004177.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6809", geneDisplay: "STX3" },
  { key: "NM_004179.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7166", geneDisplay: "TPH1" },
  { key: "NM_004181.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7345", geneDisplay: "UCHL1" },
  { key: "NM_004183.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7439", geneDisplay: "BEST1" },
  { key: "NM_004184.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7453", geneDisplay: "WARS1" },
  { key: "NM_004186.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6405", geneDisplay: "SEMA3F" },
  { key: "NM_004187.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8242", geneDisplay: "KDM5C" },
  { key: "NM_004189.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8403", geneDisplay: "SOX14" },
  { key: "NM_004190.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8513", geneDisplay: "LIPF" },
  { key: "NM_004195.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8784", geneDisplay: "TNFRSF18" },
  { key: "NM_004196.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8814", geneDisplay: "CDKL1" },
  { key: "NM_004197.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8859", geneDisplay: "STK19" },
  { key: "NM_004198.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8973", geneDisplay: "CHRNA6" },
  { key: "NM_004202.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9087", geneDisplay: "TMSB4Y" },
  { key: "NM_004203.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9088", geneDisplay: "PKMYT1" },
  { key: "NM_004204.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9091", geneDisplay: "PIGQ" },
  { key: "NM_004205.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9099", geneDisplay: "USP2" },
  { key: "NM_004207.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9123", geneDisplay: "SLC16A3" },
  { key: "NM_004208.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9131", geneDisplay: "AIFM1" },
  { key: "NM_004209.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9143", geneDisplay: "SYNGR3" },
  { key: "NM_004210.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9148", geneDisplay: "NEURL1" },
  { key: "NM_004211.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9152", geneDisplay: "SLC6A5" },
  { key: "NM_004212.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9153", geneDisplay: "SLC28A2" },
  { key: "NM_004213.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9154", geneDisplay: "SLC28A1" },
  { key: "NM_004214.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9158", geneDisplay: "FIBP" },
  { key: "NM_004215.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9166", geneDisplay: "EBAG9" },
  { key: "NM_004217.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9212", geneDisplay: "AURKB" },
  { key: "NM_004218.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9230", geneDisplay: "RAB11B" },
  { key: "NM_004219.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9232", geneDisplay: "PTTG1" },
  { key: "NM_004220.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7760", geneDisplay: "ZNF213" },
  { key: "NM_004223.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9246", geneDisplay: "UBE2L6" },
  { key: "NM_004224.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9248", geneDisplay: "GPR50" },
  { key: "NM_004225.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9258", geneDisplay: "MFHAS1" },
  { key: "NM_004226.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9262", geneDisplay: "STK17B" },
  { key: "NM_004227.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9265", geneDisplay: "CYTH3" },
  { key: "NM_004228.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9266", geneDisplay: "CYTH2" },
  { key: "NM_004229.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9282", geneDisplay: "MED14" },
  { key: "NM_004230.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9294", geneDisplay: "S1PR2" },
  { key: "NM_004231.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9296", geneDisplay: "ATP6V1F" },
  { key: "NM_004232.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9306", geneDisplay: "SOCS6" },
  { key: "NM_004233.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9308", geneDisplay: "CD83" },
  { key: "NM_004234.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9310", geneDisplay: "ZNF235" },
  { key: "NM_004235.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9314", geneDisplay: "KLF4" },
  { key: "NM_004236.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9318", geneDisplay: "COPS2" },
  { key: "NM_004237.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9319", geneDisplay: "TRIP13" },
  { key: "NM_004239.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9321", geneDisplay: "TRIP11" },
  { key: "NM_004246.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9340", geneDisplay: "GLP2R" },
  { key: "NM_004247.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9343", geneDisplay: "EFTUD2" },
  { key: "NM_004248.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2834", geneDisplay: "PRLHR" },
  { key: "NM_004251.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9367", geneDisplay: "RAB9A" },
  { key: "NM_004252.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9368", geneDisplay: "SLC9A3R1" },
  { key: "NM_004254.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9376", geneDisplay: "SLC22A8" },
  { key: "NM_004255.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9377", geneDisplay: "COX5A" },
  { key: "NM_004256.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9390", geneDisplay: "SLC22A13" },
  { key: "NM_004257.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9392", geneDisplay: "TGFBRAP1" },
  { key: "NM_004259.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9400", geneDisplay: "RECQL5" },
  { key: "NM_004260.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9401", geneDisplay: "RECQL4" },
  { key: "NM_004261.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9403", geneDisplay: "SELENOF" },
  { key: "NM_004262.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9407", geneDisplay: "TMPRSS11D" },
  { key: "NM_004263.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10505", geneDisplay: "SEMA4F" },
  { key: "NM_004264.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9412", geneDisplay: "MED21" },
  { key: "NM_004265.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9415", geneDisplay: "FADS2" },
  { key: "NM_004267.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9435", geneDisplay: "CHST2" },
  { key: "NM_004268.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9440", geneDisplay: "MED17" },
  { key: "NM_004269.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9442", geneDisplay: "MED27" },
  { key: "NM_004270.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9443", geneDisplay: "MED7" },
  { key: "NM_004271.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9450", geneDisplay: "LY86" },
  { key: "NM_004272.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9456", geneDisplay: "HOMER1" },
  { key: "NM_004273.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9469", geneDisplay: "CHST3" },
  { key: "NM_004274.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9472", geneDisplay: "AKAP6" },
  { key: "NM_004275.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9477", geneDisplay: "MED20" },
  { key: "NM_004277.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9481", geneDisplay: "SLC25A27" },
  { key: "NM_004278.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9487", geneDisplay: "PIGL" },
  { key: "NM_004279.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9512", geneDisplay: "PMPCB" },
  { key: "NM_004280.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9521", geneDisplay: "EEF1E1" },
  { key: "NM_004281.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9531", geneDisplay: "BAG3" },
  { key: "NM_004282.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9532", geneDisplay: "BAG2" },
  { key: "NM_004283.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9545", geneDisplay: "RAB3D" },
  { key: "NM_004284.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9557", geneDisplay: "CHD1L" },
  { key: "NM_004285.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9563", geneDisplay: "H6PD" },
  { key: "NM_004286.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9567", geneDisplay: "GTPBP1" },
  { key: "NM_004287.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9570", geneDisplay: "GOSR2" },
  { key: "NM_004288.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9595", geneDisplay: "CYTIP" },
  { key: "NM_004289.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9603", geneDisplay: "NFE2L3" },
  { key: "NM_004290.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9604", geneDisplay: "RNF14" },
  { key: "NM_004291.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9607", geneDisplay: "CARTPT" },
  { key: "NM_004292.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9610", geneDisplay: "RIN1" },
  { key: "NM_004293.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9615", geneDisplay: "GDA" },
  { key: "NM_004294.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9617", geneDisplay: "MTRF1" },
  { key: "NM_004295.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9618", geneDisplay: "TRAF4" },
  { key: "NM_004297.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9630", geneDisplay: "GNA14" },
  { key: "NM_004300.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "52", geneDisplay: "ACP1" },
  { key: "NM_004301.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "86", geneDisplay: "ACTL6A" },
  { key: "NM_004302.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91", geneDisplay: "ACVR1B" },
  { key: "NM_004304.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "238", geneDisplay: "ALK" },
  { key: "NM_004306.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "312", geneDisplay: "ANXA13" },
  { key: "NM_004307.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "323", geneDisplay: "APBB2" },
  { key: "NM_004308.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "392", geneDisplay: "ARHGAP1" },
  { key: "NM_004309.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "396", geneDisplay: "ARHGDIA" },
  { key: "NM_004310.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "399", geneDisplay: "RHOH" },
  { key: "NM_004311.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "403", geneDisplay: "ARL3" },
  { key: "NM_004312.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "407", geneDisplay: "ARR3" },
  { key: "NM_004313.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "409", geneDisplay: "ARRB2" },
  { key: "NM_004314.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "417", geneDisplay: "ART1" },
  { key: "NM_004316.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "429", geneDisplay: "ASCL1" },
  { key: "NM_004317.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "439", geneDisplay: "GET3" },
  { key: "NM_004318.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "444", geneDisplay: "ASPH" },
  { key: "NM_004319.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "460", geneDisplay: "ASTN1" },
  { key: "NM_004320.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "487", geneDisplay: "ATP2A1" },
  { key: "NM_004323.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "573", geneDisplay: "BAG1" },
  { key: "NM_004326.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "607", geneDisplay: "BCL9" },
  { key: "NM_004327.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "613", geneDisplay: "BCR" },
  { key: "NM_004329.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "657", geneDisplay: "BMPR1A" },
  { key: "NM_004330.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "663", geneDisplay: "BNIP2" },
  { key: "NM_004331.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "665", geneDisplay: "BNIP3L" },
  { key: "NM_004332.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "670", geneDisplay: "BPHL" },
  { key: "NM_004333.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "673", geneDisplay: "BRAF" },
  { key: "NM_004334.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "683", geneDisplay: "BST1" },
  { key: "NM_004335.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "684", geneDisplay: "BST2" },
  { key: "NM_004336.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "699", geneDisplay: "BUB1" },
  { key: "NM_004339.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "754", geneDisplay: "PTTG1IP" },
  { key: "NM_004341.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "790", geneDisplay: "CAD" },
  { key: "NM_004343.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "811", geneDisplay: "CALR" },
  { key: "NM_004344.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1069", geneDisplay: "CETN2" },
  { key: "NM_004345.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "820", geneDisplay: "CAMP" },
  { key: "NM_004346.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "836", geneDisplay: "CASP3" },
  { key: "NM_004347.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "838", geneDisplay: "CASP5" },
  { key: "NM_004350.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "864", geneDisplay: "RUNX3" },
  { key: "NM_004352.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "869", geneDisplay: "CBLN1" },
  { key: "NM_004354.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "901", geneDisplay: "CCNG2" },
  { key: "NM_004356.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "975", geneDisplay: "CD81" },
  { key: "NM_004357.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "977", geneDisplay: "CD151" },
  { key: "NM_004359.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "997", geneDisplay: "CDC34" },
  { key: "NM_004360.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "999", geneDisplay: "CDH1" },
  { key: "NM_004361.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1005", geneDisplay: "CDH7" },
  { key: "NM_004362.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1047", geneDisplay: "CLGN" },
  { key: "NM_004363.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1048", geneDisplay: "CEACAM5" },
  { key: "NM_004364.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1050", geneDisplay: "CEBPA" },
  { key: "NM_004365.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1070", geneDisplay: "CETN3" },
  { key: "NM_004366.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1181", geneDisplay: "CLCN2" },
  { key: "NM_004368.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1265", geneDisplay: "CNN2" },
  { key: "NM_004369.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1293", geneDisplay: "COL6A3" },
  { key: "NM_004370.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1303", geneDisplay: "COL12A1" },
  { key: "NM_004371.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1314", geneDisplay: "COPA" },
  { key: "NM_004373.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1337", geneDisplay: "COX6A1" },
  { key: "NM_004374.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1345", geneDisplay: "COX6C" },
  { key: "NM_004375.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1353", geneDisplay: "COX11" },
  { key: "NM_004378.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1381", geneDisplay: "CRABP1" },
  { key: "NM_004379.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1385", geneDisplay: "CREB1" },
  { key: "NM_004380.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1387", geneDisplay: "CREBBP" },
  { key: "NM_004381.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1388", geneDisplay: "ATF6B" },
  { key: "NM_004382.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1394", geneDisplay: "CRHR1" },
  { key: "NM_004383.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1445", geneDisplay: "CSK" },
  { key: "NM_004385.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1462", geneDisplay: "VCAN" },
  { key: "NM_004386.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1463", geneDisplay: "NCAN" },
  { key: "NM_004387.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1482", geneDisplay: "NKX2-5" },
  { key: "NM_004388.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1486", geneDisplay: "CTBS" },
  { key: "NM_004390.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1512", geneDisplay: "CTSH" },
  { key: "NM_004391.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1582", geneDisplay: "CYP8B1" },
  { key: "NM_004393.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1605", geneDisplay: "DAG1" },
  { key: "NM_004394.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1611", geneDisplay: "DAP" },
  { key: "NM_004396.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1655", geneDisplay: "DDX5" },
  { key: "NM_004397.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1656", geneDisplay: "DDX6" },
  { key: "NM_004398.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1662", geneDisplay: "DDX10" },
  { key: "NM_004401.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1676", geneDisplay: "DFFA" },
  { key: "NM_004402.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1677", geneDisplay: "DFFB" },
  { key: "NM_004404.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4735", geneDisplay: "SEPTIN2" },
  { key: "NM_004405.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1746", geneDisplay: "DLX2" },
  { key: "NM_004407.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1758", geneDisplay: "DMP1" },
  { key: "NM_004408.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1759", geneDisplay: "DNM1" },
  { key: "NM_004409.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1760", geneDisplay: "DMPK" },
  { key: "NM_004412.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1787", geneDisplay: "TRDMT1" },
  { key: "NM_004414.7", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1827", geneDisplay: "RCAN1" },
  { key: "NM_004415.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1832", geneDisplay: "DSP" },
  { key: "NM_004416.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1840", geneDisplay: "DTX1" },
  { key: "NM_004417.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1843", geneDisplay: "DUSP1" },
  { key: "NM_004418.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1844", geneDisplay: "DUSP2" },
  { key: "NM_004419.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1847", geneDisplay: "DUSP5" },
  { key: "NM_004420.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1850", geneDisplay: "DUSP8" },
  { key: "NM_004422.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1856", geneDisplay: "DVL2" },
  { key: "NM_004423.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1857", geneDisplay: "DVL3" },
  { key: "NM_004424.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1877", geneDisplay: "E4F1" },
  { key: "NM_004425.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1893", geneDisplay: "ECM1" },
  { key: "NM_004426.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1911", geneDisplay: "PHC1" },
  { key: "NM_004428.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1942", geneDisplay: "EFNA1" },
  { key: "NM_004429.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1947", geneDisplay: "EFNB1" },
  { key: "NM_004430.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1960", geneDisplay: "EGR3" },
  { key: "NM_004431.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1969", geneDisplay: "EPHA2" },
  { key: "NM_004432.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1993", geneDisplay: "ELAVL2" },
  { key: "NM_004433.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1999", geneDisplay: "ELF3" },
  { key: "NM_004434.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2009", geneDisplay: "EML1" },
  { key: "NM_004435.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2021", geneDisplay: "ENDOG" },
  { key: "NM_004436.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2029", geneDisplay: "ENSA" },
  { key: "NM_004438.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2043", geneDisplay: "EPHA4" },
  { key: "NM_004440.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2045", geneDisplay: "EPHA7" },
  { key: "NM_004441.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2047", geneDisplay: "EPHB1" },
  { key: "NM_004443.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2049", geneDisplay: "EPHB3" },
  { key: "NM_004444.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2050", geneDisplay: "EPHB4" },
  { key: "NM_004445.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2051", geneDisplay: "EPHB6" },
  { key: "NM_004446.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2058", geneDisplay: "EPRS1" },
  { key: "NM_004447.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2059", geneDisplay: "EPS8" },
  { key: "NM_004448.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2064", geneDisplay: "ERBB2" },
  { key: "NM_004450.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2079", geneDisplay: "ERH" },
  { key: "NM_004451.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2101", geneDisplay: "ESRRA" },
  { key: "NM_004453.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2110", geneDisplay: "ETFDH" },
  { key: "NM_004454.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2119", geneDisplay: "ETV5" },
  { key: "NM_004455.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2134", geneDisplay: "EXTL1" },
  { key: "NM_004456.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2146", geneDisplay: "EZH2" },
  { key: "NM_004457.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2181", geneDisplay: "ACSL3" },
  { key: "NM_004460.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2191", geneDisplay: "FAP" },
  { key: "NM_004461.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2193", geneDisplay: "FARSA" },
  { key: "NM_004462.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2222", geneDisplay: "FDFT1" },
  { key: "NM_004463.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2245", geneDisplay: "FGD1" },
  { key: "NM_004464.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2250", geneDisplay: "FGF5" },
  { key: "NM_004465.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2255", geneDisplay: "FGF10" },
  { key: "NM_004466.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2262", geneDisplay: "GPC5" },
  { key: "NM_004467.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2267", geneDisplay: "FGL1" },
  { key: "NM_004468.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2275", geneDisplay: "FHL3" },
  { key: "NM_004469.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2277", geneDisplay: "VEGFD" },
  { key: "NM_004470.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2286", geneDisplay: "FKBP2" },
  { key: "NM_004472.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2297", geneDisplay: "FOXD1" },
  { key: "NM_004473.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2304", geneDisplay: "FOXE1" },
  { key: "NM_004474.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2306", geneDisplay: "FOXD2" },
  { key: "NM_004475.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2319", geneDisplay: "FLOT2" },
  { key: "NM_004476.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2346", geneDisplay: "FOLH1" },
  { key: "NM_004477.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2483", geneDisplay: "FRG1" },
  { key: "NM_004479.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2529", geneDisplay: "FUT7" },
  { key: "NM_004481.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2590", geneDisplay: "GALNT2" },
  { key: "NM_004482.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2591", geneDisplay: "GALNT3" },
  { key: "NM_004483.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2653", geneDisplay: "GCSH" },
  { key: "NM_004484.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2719", geneDisplay: "GPC3" },
  { key: "NM_004488.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2814", geneDisplay: "GP5" },
  { key: "NM_004489.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2874", geneDisplay: "GPS2" },
  { key: "NM_004490.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2888", geneDisplay: "GRB14" },
  { key: "NM_004491.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2909", geneDisplay: "ARHGAP35" },
  { key: "NM_004492.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2958", geneDisplay: "GTF2A2" },
  { key: "NM_004493.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3028", geneDisplay: "HSD17B10" },
  { key: "NM_004494.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3068", geneDisplay: "HDGF" },
  { key: "NM_004496.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3169", geneDisplay: "FOXA1" },
  { key: "NM_004497.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3171", geneDisplay: "FOXA3" },
  { key: "NM_004498.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3175", geneDisplay: "ONECUT1" },
  { key: "NM_004500.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3183", geneDisplay: "HNRNPC" },
  { key: "NM_004502.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3217", geneDisplay: "HOXB7" },
  { key: "NM_004503.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3223", geneDisplay: "HOXC6" },
  { key: "NM_004504.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3267", geneDisplay: "AGFG1" },
  { key: "NM_004506.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3298", geneDisplay: "HSF2" },
  { key: "NM_004507.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3364", geneDisplay: "HUS1" },
  { key: "NM_004508.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3422", geneDisplay: "IDI1" },
  { key: "NM_004514.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3607", geneDisplay: "FOXK2" },
  { key: "NM_004515.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3608", geneDisplay: "ILF2" },
  { key: "NM_004517.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3611", geneDisplay: "ILK" },
  { key: "NM_004519.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3786", geneDisplay: "KCNQ3" },
  { key: "NM_004521.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3799", geneDisplay: "KIF5B" },
  { key: "NM_004522.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3800", geneDisplay: "KIF5C" },
  { key: "NM_004523.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3832", geneDisplay: "KIF11" },
  { key: "NM_004525.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4036", geneDisplay: "LRP2" },
  { key: "NM_004526.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4171", geneDisplay: "MCM2" },
  { key: "NM_004527.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4222", geneDisplay: "MEOX1" },
  { key: "NM_004528.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4259", geneDisplay: "MGST3" },
  { key: "NM_004529.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4300", geneDisplay: "MLLT3" },
  { key: "NM_004530.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4313", geneDisplay: "MMP2" },
  { key: "NM_004531.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4338", geneDisplay: "MOCS2" },
  { key: "NM_004533.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4606", geneDisplay: "MYBPC2" },
  { key: "NM_004535.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4661", geneDisplay: "MYT1" },
  { key: "NM_004536.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4671", geneDisplay: "NAIP" },
  { key: "NM_004537.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4673", geneDisplay: "NAP1L1" },
  { key: "NM_004538.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4675", geneDisplay: "NAP1L3" },
  { key: "NM_004539.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4677", geneDisplay: "NARS1" },
  { key: "NM_004540.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "4685", geneDisplay: "NCAM2" },
  { key: "NM_004541.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4694", geneDisplay: "NDUFA1" },
  { key: "NM_004542.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4696", geneDisplay: "NDUFA3" },
  { key: "NM_004544.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4705", geneDisplay: "NDUFA10" },
  { key: "NM_004545.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4707", geneDisplay: "NDUFB1" },
  { key: "NM_004546.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4708", geneDisplay: "NDUFB2" },
  { key: "NM_004547.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4710", geneDisplay: "NDUFB4" },
  { key: "NM_004548.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4716", geneDisplay: "NDUFB10" },
  { key: "NM_004549.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4718", geneDisplay: "NDUFC2" },
  { key: "NM_004551.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4722", geneDisplay: "NDUFS3" },
  { key: "NM_004552.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4725", geneDisplay: "NDUFS5" },
  { key: "NM_004553.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4726", geneDisplay: "NDUFS6" },
  { key: "NM_004554.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4776", geneDisplay: "NFATC4" },
  { key: "NM_004556.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4794", geneDisplay: "NFKBIE" },
  { key: "NM_004557.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4855", geneDisplay: "NOTCH4" },
  { key: "NM_004558.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4902", geneDisplay: "NRTN" },
  { key: "NM_004559.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4904", geneDisplay: "YBX1" },
  { key: "NM_004560.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4920", geneDisplay: "ROR2" },
  { key: "NM_004561.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5017", geneDisplay: "OVOL1" },
  { key: "NM_004562.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5071", geneDisplay: "PRKN" },
  { key: "NM_004563.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5106", geneDisplay: "PCK2" },
  { key: "NM_004564.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5188", geneDisplay: "GATB" },
  { key: "NM_004565.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5195", geneDisplay: "PEX14" },
  { key: "NM_004566.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5209", geneDisplay: "PFKFB3" },
  { key: "NM_004567.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5210", geneDisplay: "PFKFB4" },
  { key: "NM_004568.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5269", geneDisplay: "SERPINB6" },
  { key: "NM_004569.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5283", geneDisplay: "PIGH" },
  { key: "NM_004571.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5316", geneDisplay: "PKNOX1" },
  { key: "NM_004573.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5330", geneDisplay: "PLCB2" },
  { key: "NM_004575.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5458", geneDisplay: "POU4F2" },
  { key: "NM_004577.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5723", geneDisplay: "PSPH" },
  { key: "NM_004578.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5867", geneDisplay: "RAB4A" },
  { key: "NM_004579.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5871", geneDisplay: "MAP4K2" },
  { key: "NM_004582.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5876", geneDisplay: "RABGGTB" },
  { key: "NM_004583.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5878", geneDisplay: "RAB5C" },
  { key: "NM_004584.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5883", geneDisplay: "RAD9A" },
  { key: "NM_004585.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5920", geneDisplay: "PLAAT4" },
  { key: "NM_004586.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6197", geneDisplay: "RPS6KA3" },
  { key: "NM_004588.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6327", geneDisplay: "SCN2B" },
  { key: "NM_004589.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6341", geneDisplay: "SCO1" },
  { key: "NM_004590.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6360", geneDisplay: "CCL16" },
  { key: "NM_004591.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6364", geneDisplay: "CCL20" },
  { key: "NM_004592.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6433", geneDisplay: "SFSWAP" },
  { key: "NM_004593.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6434", geneDisplay: "TRA2B" },
  { key: "NM_004594.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6553", geneDisplay: "SLC9A5" },
  { key: "NM_004595.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6611", geneDisplay: "SMS" },
  { key: "NM_004596.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6626", geneDisplay: "SNRPA" },
  { key: "NM_004598.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6695", geneDisplay: "SPOCK1" },
  { key: "NM_004599.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6721", geneDisplay: "SREBF2" },
  { key: "NM_004603.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6804", geneDisplay: "STX1A" },
  { key: "NM_004604.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6810", geneDisplay: "STX4" },
  { key: "NM_004606.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6872", geneDisplay: "TAF1" },
  { key: "NM_004607.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6902", geneDisplay: "TBCA" },
  { key: "NM_004608.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6911", geneDisplay: "TBX6" },
  { key: "NM_004609.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6939", geneDisplay: "TCF15" },
  { key: "NM_004612.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7046", geneDisplay: "TGFBR1" },
  { key: "NM_004613.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7052", geneDisplay: "TGM2" },
  { key: "NM_004614.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7084", geneDisplay: "TK2" },
  { key: "NM_004615.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7102", geneDisplay: "TSPAN7" },
  { key: "NM_004616.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7103", geneDisplay: "TSPAN8" },
  { key: "NM_004617.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7104", geneDisplay: "TM4SF4" },
  { key: "NM_004618.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7156", geneDisplay: "TOP3A" },
  { key: "NM_004620.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7189", geneDisplay: "TRAF6" },
  { key: "NM_004621.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7225", geneDisplay: "TRPC6" },
  { key: "NM_004622.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7247", geneDisplay: "TSN" },
  { key: "NM_004623.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7268", geneDisplay: "TTC4" },
  { key: "NM_004624.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7433", geneDisplay: "VIPR1" },
  { key: "NM_004625.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7476", geneDisplay: "WNT7A" },
  { key: "NM_004626.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7481", geneDisplay: "WNT11" },
  { key: "NM_004627.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7485", geneDisplay: "GET1" },
  { key: "NM_004628.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7508", geneDisplay: "XPC" },
  { key: "NM_004629.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2189", geneDisplay: "FANCG" },
  { key: "NM_004630.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7536", geneDisplay: "SF1" },
  { key: "NM_004631.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7804", geneDisplay: "LRP8" },
  { key: "NM_004632.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7818", geneDisplay: "DAP3" },
  { key: "NM_004633.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7850", geneDisplay: "IL1R2" },
  { key: "NM_004637.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7879", geneDisplay: "RAB7A" },
  { key: "NM_004638.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7916", geneDisplay: "PRRC2A" },
  { key: "NM_004640.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7919", geneDisplay: "DDX39B" },
  { key: "NM_004642.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8099", geneDisplay: "CDK2AP1" },
  { key: "NM_004643.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8106", geneDisplay: "PABPN1" },
  { key: "NM_004645.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8161", geneDisplay: "COIL" },
  { key: "NM_004646.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4868", geneDisplay: "NPHS1" },
  { key: "NM_004649.8", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8209", geneDisplay: "GATD3" },
  { key: "NM_004650.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8228", geneDisplay: "PNPLA4" },
  { key: "NM_004653.5", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "8284", geneDisplay: "KDM5D" },
  { key: "NM_004654.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "8287", geneDisplay: "USP9Y" },
  { key: "NM_004655.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8313", geneDisplay: "AXIN2" },
  { key: "NM_004656.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8314", geneDisplay: "BAP1" },
  { key: "NM_004657.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8436", geneDisplay: "CAVIN2" },
  { key: "NM_004660.5", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "8653", geneDisplay: "DDX3Y" },
  { key: "NM_004661.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8697", geneDisplay: "CDC23" },
  { key: "NM_004663.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8766", geneDisplay: "RAB11A" },
  { key: "NM_004664.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8825", geneDisplay: "LIN7A" },
  { key: "NM_004665.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8875", geneDisplay: "VNN2" },
  { key: "NM_004666.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8876", geneDisplay: "VNN1" },
  { key: "NM_004667.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8924", geneDisplay: "HERC2" },
  { key: "NM_004669.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9022", geneDisplay: "CLIC3" },
  { key: "NM_004671.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9063", geneDisplay: "PIAS2" },
  { key: "NM_004672.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9064", geneDisplay: "MAP3K6" },
  { key: "NM_004673.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9068", geneDisplay: "ANGPTL1" },
  { key: "NM_004674.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9070", geneDisplay: "ASH2L" },
  { key: "NM_004675.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9077", geneDisplay: "DIRAS3" },
  { key: "NM_004676.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9081", geneDisplay: "PRY" },
  { key: "NM_004678.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9083", geneDisplay: "BPY2" },
  { key: "NM_004679.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9084", geneDisplay: "VCY" },
  { key: "NM_004680.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9085", geneDisplay: "CDY1" },
  { key: "NM_004681.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9086", geneDisplay: "EIF1AY" },
  { key: "NM_004684.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8404", geneDisplay: "SPARCL1" },
  { key: "NM_004685.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9107", geneDisplay: "MTMR6" },
  { key: "NM_004686.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9108", geneDisplay: "MTMR7" },
  { key: "NM_004688.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9111", geneDisplay: "NMI" },
  { key: "NM_004689.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9112", geneDisplay: "MTA1" },
  { key: "NM_004690.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9113", geneDisplay: "LATS1" },
  { key: "NM_004691.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9114", geneDisplay: "ATP6V0D1" },
  { key: "NM_004693.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9119", geneDisplay: "KRT75" },
  { key: "NM_004694.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9120", geneDisplay: "SLC16A6" },
  { key: "NM_004695.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9121", geneDisplay: "SLC16A5" },
  { key: "NM_004696.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9122", geneDisplay: "SLC16A4" },
  { key: "NM_004698.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9129", geneDisplay: "PRPF3" },
  { key: "NM_004699.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9130", geneDisplay: "FAM50A" },
  { key: "NM_004700.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9132", geneDisplay: "KCNQ4" },
  { key: "NM_004701.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9133", geneDisplay: "CCNB2" },
  { key: "NM_004703.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9135", geneDisplay: "RABEP1" },
  { key: "NM_004704.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9136", geneDisplay: "RRP9" },
  { key: "NM_004705.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5612", geneDisplay: "THAP12" },
  { key: "NM_004706.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9138", geneDisplay: "ARHGEF1" },
  { key: "NM_004707.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9140", geneDisplay: "ATG12" },
  { key: "NM_004708.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9141", geneDisplay: "PDCD5" },
  { key: "NM_004710.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9144", geneDisplay: "SYNGR2" },
  { key: "NM_004711.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9145", geneDisplay: "SYNGR1" },
  { key: "NM_004712.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9146", geneDisplay: "HGS" },
  { key: "NM_004713.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9147", geneDisplay: "NEMF" },
  { key: "NM_004714.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9149", geneDisplay: "DYRK1B" },
  { key: "NM_004715.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9150", geneDisplay: "CTDP1" },
  { key: "NM_004716.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9159", geneDisplay: "PCSK7" },
  { key: "NM_004718.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9167", geneDisplay: "COX7A2L" },
  { key: "NM_004719.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9169", geneDisplay: "SCAF11" },
  { key: "NM_004721.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9175", geneDisplay: "MAP3K13" },
  { key: "NM_004722.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9179", geneDisplay: "AP4M1" },
  { key: "NM_004724.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9183", geneDisplay: "ZW10" },
  { key: "NM_004725.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9184", geneDisplay: "BUB3" },
  { key: "NM_004726.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9185", geneDisplay: "REPS2" },
  { key: "NM_004727.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9187", geneDisplay: "SLC24A1" },
  { key: "NM_004728.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9188", geneDisplay: "DDX21" },
  { key: "NM_004730.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2107", geneDisplay: "ETF1" },
  { key: "NM_004732.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9196", geneDisplay: "KCNAB3" },
  { key: "NM_004733.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9197", geneDisplay: "SLC33A1" },
  { key: "NM_004736.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9213", geneDisplay: "XPR1" },
  { key: "NM_004738.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9217", geneDisplay: "VAPB" },
  { key: "NM_004739.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9219", geneDisplay: "MTA2" },
  { key: "NM_004741.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9221", geneDisplay: "NOLC1" },
  { key: "NM_004744.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9227", geneDisplay: "LRAT" },
  { key: "NM_004746.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9229", geneDisplay: "DLGAP1" },
  { key: "NM_004747.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9231", geneDisplay: "DLG5" },
  { key: "NM_004749.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9238", geneDisplay: "TBRG4" },
  { key: "NM_004750.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9244", geneDisplay: "CRLF1" },
  { key: "NM_004751.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9245", geneDisplay: "GCNT3" },
  { key: "NM_004752.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9247", geneDisplay: "GCM2" },
  { key: "NM_004753.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9249", geneDisplay: "DHRS3" },
  { key: "NM_004755.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9252", geneDisplay: "RPS6KA5" },
  { key: "NM_004756.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9253", geneDisplay: "NUMBL" },
  { key: "NM_004758.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9256", geneDisplay: "TSPOAP1" },
  { key: "NM_004760.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9263", geneDisplay: "STK17A" },
  { key: "NM_004761.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5863", geneDisplay: "RGL2" },
  { key: "NM_004762.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9267", geneDisplay: "CYTH1" },
  { key: "NM_004763.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9270", geneDisplay: "ITGB1BP1" },
  { key: "NM_004764.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9271", geneDisplay: "PIWIL1" },
  { key: "NM_004765.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9274", geneDisplay: "BCL7C" },
  { key: "NM_004766.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9276", geneDisplay: "COPB2" },
  { key: "NM_004767.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9283", geneDisplay: "GPR37L1" },
  { key: "NM_004769.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9311", geneDisplay: "ASIC3" },
  { key: "NM_004770.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9312", geneDisplay: "KCNB2" },
  { key: "NM_004771.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9313", geneDisplay: "MMP20" },
  { key: "NM_004772.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9315", geneDisplay: "NREP" },
  { key: "NM_004773.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9326", geneDisplay: "ZNHIT3" },
  { key: "NM_004774.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5469", geneDisplay: "MED1" },
  { key: "NM_004775.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9331", geneDisplay: "B4GALT6" },
  { key: "NM_004776.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9334", geneDisplay: "B4GALT5" },
  { key: "NM_004778.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11251", geneDisplay: "PTGDR2" },
  { key: "NM_004780.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9338", geneDisplay: "TCEAL1" },
  { key: "NM_004781.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9341", geneDisplay: "VAMP3" },
  { key: "NM_004782.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9342", geneDisplay: "SNAP29" },
  { key: "NM_004784.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9348", geneDisplay: "NDST3" },
  { key: "NM_004786.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9352", geneDisplay: "TXNL1" },
  { key: "NM_004787.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9353", geneDisplay: "SLIT2" },
  { key: "NM_004789.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9355", geneDisplay: "LHX2" },
  { key: "NM_004791.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9358", geneDisplay: "ITGBL1" },
  { key: "NM_004792.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9360", geneDisplay: "PPIG" },
  { key: "NM_004793.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9361", geneDisplay: "LONP1" },
  { key: "NM_004794.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9363", geneDisplay: "RAB33A" },
  { key: "NM_004795.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9365", geneDisplay: "KL" },
  { key: "NM_004797.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9370", geneDisplay: "ADIPOQ" },
  { key: "NM_004798.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9371", geneDisplay: "KIF3B" },
  { key: "NM_004799.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9372", geneDisplay: "ZFYVE9" },
  { key: "NM_004800.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9375", geneDisplay: "TM9SF2" },
  { key: "NM_004804.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9391", geneDisplay: "CIAO1" },
  { key: "NM_004805.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5433", geneDisplay: "POLR2D" },
  { key: "NM_004807.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9394", geneDisplay: "HS6ST1" },
  { key: "NM_004808.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9397", geneDisplay: "NMT2" },
  { key: "NM_004809.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9399", geneDisplay: "STOML1" },
  { key: "NM_004810.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9402", geneDisplay: "GRAP2" },
  { key: "NM_004811.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9404", geneDisplay: "LPXN" },
  { key: "NM_004813.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9409", geneDisplay: "PEX16" },
  { key: "NM_004814.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9410", geneDisplay: "SNRNP40" },
  { key: "NM_004815.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9411", geneDisplay: "ARHGAP29" },
  { key: "NM_004817.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9414", geneDisplay: "TJP2" },
  { key: "NM_004818.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9416", geneDisplay: "DDX23" },
  { key: "NM_004819.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8189", geneDisplay: "SYMPK" },
  { key: "NM_004820.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9420", geneDisplay: "CYP7B1" },
  { key: "NM_004821.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9421", geneDisplay: "HAND1" },
  { key: "NM_004822.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9423", geneDisplay: "NTN1" },
  { key: "NM_004823.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9424", geneDisplay: "KCNK6" },
  { key: "NM_004824.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9425", geneDisplay: "CDYL" },
  { key: "NM_004825.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "9426", geneDisplay: "CDY2A" },
  { key: "NM_004826.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9427", geneDisplay: "ECEL1" },
  { key: "NM_004827.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9429", geneDisplay: "ABCG2" },
  { key: "NM_004828.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9436", geneDisplay: "NCR2" },
  { key: "NM_004829.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9437", geneDisplay: "NCR1" },
  { key: "NM_004830.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9439", geneDisplay: "MED23" },
  { key: "NM_004831.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9441", geneDisplay: "MED26" },
  { key: "NM_004832.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9446", geneDisplay: "GSTO1" },
  { key: "NM_004833.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9447", geneDisplay: "AIM2" },
  { key: "NM_004836.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9451", geneDisplay: "EIF2AK3" },
  { key: "NM_004837.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9453", geneDisplay: "GGPS1" },
  { key: "NM_004838.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9454", geneDisplay: "HOMER3" },
  { key: "NM_004839.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9455", geneDisplay: "HOMER2" },
  { key: "NM_004840.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9459", geneDisplay: "ARHGEF6" },
  { key: "NM_004843.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9466", geneDisplay: "IL27RA" },
  { key: "NM_004844.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9467", geneDisplay: "SH3BP5" },
  { key: "NM_004845.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9468", geneDisplay: "PCYT1B" },
  { key: "NM_004846.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9470", geneDisplay: "EIF4E2" },
  { key: "NM_004849.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9474", geneDisplay: "ATG5" },
  { key: "NM_004850.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9475", geneDisplay: "ROCK2" },
  { key: "NM_004851.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9476", geneDisplay: "NAPSA" },
  { key: "NM_004852.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9480", geneDisplay: "ONECUT2" },
  { key: "NM_004853.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9482", geneDisplay: "STX8" },
  { key: "NM_004854.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9486", geneDisplay: "CHST10" },
  { key: "NM_004855.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9488", geneDisplay: "PIGB" },
  { key: "NM_004857.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9495", geneDisplay: "AKAP5" },
  { key: "NM_004859.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1213", geneDisplay: "CLTC" },
  { key: "NM_004860.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9513", geneDisplay: "FXR2" },
  { key: "NM_004863.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9517", geneDisplay: "SPTLC2" },
  { key: "NM_004864.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9518", geneDisplay: "GDF15" },
  { key: "NM_004865.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9519", geneDisplay: "TBPL1" },
  { key: "NM_004866.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9522", geneDisplay: "SCAMP1" },
  { key: "NM_004867.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9452", geneDisplay: "ITM2A" },
  { key: "NM_004869.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9525", geneDisplay: "VPS4B" },
  { key: "NM_004870.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9526", geneDisplay: "MPDU1" },
  { key: "NM_004872.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9528", geneDisplay: "TMEM59" },
  { key: "NM_004874.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9530", geneDisplay: "BAG4" },
  { key: "NM_004877.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9535", geneDisplay: "GMFG" },
  { key: "NM_004878.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9536", geneDisplay: "PTGES" },
  { key: "NM_004879.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9538", geneDisplay: "EI24" },
  { key: "NM_004881.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9540", geneDisplay: "TP53I3" },
  { key: "NM_004882.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9541", geneDisplay: "CIR1" },
  { key: "NM_004883.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9542", geneDisplay: "NRG2" },
  { key: "NM_004884.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9543", geneDisplay: "IGDCC3" },
  { key: "NM_004885.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10886", geneDisplay: "NPFFR2" },
  { key: "NM_004886.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9546", geneDisplay: "APBA3" },
  { key: "NM_004887.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9547", geneDisplay: "CXCL14" },
  { key: "NM_004888.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9550", geneDisplay: "ATP6V1G1" },
  { key: "NM_004889.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9551", geneDisplay: "ATP5MF" },
  { key: "NM_004890.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9552", geneDisplay: "SPAG7" },
  { key: "NM_004891.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9553", geneDisplay: "MRPL33" },
  { key: "NM_004892.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9554", geneDisplay: "SEC22B" },
  { key: "NM_004894.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9556", geneDisplay: "ATP5MJ" },
  { key: "NM_004896.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9559", geneDisplay: "VPS26A" },
  { key: "NM_004897.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9562", geneDisplay: "MINPP1" },
  { key: "NM_004898.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9575", geneDisplay: "CLOCK" },
  { key: "NM_004900.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9582", geneDisplay: "APOBEC3B" },
  { key: "NM_004901.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9583", geneDisplay: "ENTPD4" },
  { key: "NM_004905.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9588", geneDisplay: "PRDX6" },
  { key: "NM_004907.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9592", geneDisplay: "IER2" },
  { key: "NM_004910.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9600", geneDisplay: "PITPNM1" },
  { key: "NM_004911.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9601", geneDisplay: "PDIA4" },
  { key: "NM_004913.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9605", geneDisplay: "VPS9D1" },
  { key: "NM_004914.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9609", geneDisplay: "RAB36" },
  { key: "NM_004917.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9622", geneDisplay: "KLK4" },
  { key: "NM_004918.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9623", geneDisplay: "TCL1B" },
  { key: "NM_004923.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9633", geneDisplay: "TESMIN" },
  { key: "NM_004924.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81", geneDisplay: "ACTN4" },
  { key: "NM_004925.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "360", geneDisplay: "AQP3" },
  { key: "NM_004926.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "677", geneDisplay: "ZFP36L1" },
  { key: "NM_004927.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "740", geneDisplay: "MRPL49" },
  { key: "NM_004928.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "755", geneDisplay: "CFAP410" },
  { key: "NM_004929.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "793", geneDisplay: "CALB1" },
  { key: "NM_004930.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "832", geneDisplay: "CAPZB" },
  { key: "NM_004931.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "926", geneDisplay: "CD8B" },
  { key: "NM_004932.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1004", geneDisplay: "CDH6" },
  { key: "NM_004933.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1013", geneDisplay: "CDH15" },
  { key: "NM_004934.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1016", geneDisplay: "CDH18" },
  { key: "NM_004935.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1020", geneDisplay: "CDK5" },
  { key: "NM_004936.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1030", geneDisplay: "CDKN2B" },
  { key: "NM_004937.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1497", geneDisplay: "CTNS" },
  { key: "NM_004938.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1612", geneDisplay: "DAPK1" },
  { key: "NM_004939.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1653", geneDisplay: "DDX1" },
  { key: "NM_004941.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1659", geneDisplay: "DHX8" },
  { key: "NM_004942.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1673", geneDisplay: "DEFB4A" },
  { key: "NM_004943.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1762", geneDisplay: "DMWD" },
  { key: "NM_004944.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1776", geneDisplay: "DNASE1L3" },
  { key: "NM_004946.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1794", geneDisplay: "DOCK2" },
  { key: "NM_004947.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1795", geneDisplay: "DOCK3" },
  { key: "NM_004950.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1833", geneDisplay: "EPYC" },
  { key: "NM_004951.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1880", geneDisplay: "GPR183" },
  { key: "NM_004952.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1944", geneDisplay: "EFNA3" },
  { key: "NM_004956.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2115", geneDisplay: "ETV1" },
  { key: "NM_004957.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2356", geneDisplay: "FPGS" },
  { key: "NM_004958.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2475", geneDisplay: "MTOR" },
  { key: "NM_004959.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2516", geneDisplay: "NR5A1" },
  { key: "NM_004960.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2521", geneDisplay: "FUS" },
  { key: "NM_004961.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2564", geneDisplay: "GABRE" },
  { key: "NM_004962.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2662", geneDisplay: "GDF10" },
  { key: "NM_004963.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2984", geneDisplay: "GUCY2C" },
  { key: "NM_004964.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3065", geneDisplay: "HDAC1" },
  { key: "NM_004965.7", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3150", geneDisplay: "HMGN1" },
  { key: "NM_004967.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3381", geneDisplay: "IBSP" },
  { key: "NM_004969.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3416", geneDisplay: "IDE" },
  { key: "NM_004970.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3483", geneDisplay: "IGFALS" },
  { key: "NM_004972.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3717", geneDisplay: "JAK2" },
  { key: "NM_004973.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3720", geneDisplay: "JARID2" },
  { key: "NM_004974.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3737", geneDisplay: "KCNA2" },
  { key: "NM_004975.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3745", geneDisplay: "KCNB1" },
  { key: "NM_004977.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3748", geneDisplay: "KCNC3" },
  { key: "NM_004979.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3750", geneDisplay: "KCND1" },
  { key: "NM_004982.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3764", geneDisplay: "KCNJ8" },
  { key: "NM_004983.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3765", geneDisplay: "KCNJ9" },
  { key: "NM_004984.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3798", geneDisplay: "KIF5A" },
  { key: "NM_004985.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3845", geneDisplay: "KRAS" },
  { key: "NM_004988.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4100", geneDisplay: "MAGEA1" },
  { key: "NM_004990.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4141", geneDisplay: "MARS1" },
  { key: "NM_004991.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2122", geneDisplay: "MECOM" },
  { key: "NM_004993.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4287", geneDisplay: "ATXN3" },
  { key: "NM_004994.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4318", geneDisplay: "MMP9" },
  { key: "NM_004995.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4323", geneDisplay: "MMP14" },
  { key: "NM_004996.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4363", geneDisplay: "ABCC1" },
  { key: "NM_004997.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4608", geneDisplay: "MYBPH" },
  { key: "NM_004998.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4643", geneDisplay: "MYO1E" },
  { key: "NM_004999.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4646", geneDisplay: "MYO6" },
  { key: "NM_005000.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4698", geneDisplay: "NDUFA5" },
  { key: "NM_005001.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4701", geneDisplay: "NDUFA7" },
  { key: "NM_005002.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4704", geneDisplay: "NDUFA9" },
  { key: "NM_005003.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4706", geneDisplay: "NDUFAB1" },
  { key: "NM_005004.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4714", geneDisplay: "NDUFB8" },
  { key: "NM_005005.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4715", geneDisplay: "NDUFB9" },
  { key: "NM_005006.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4719", geneDisplay: "NDUFS1" },
  { key: "NM_005007.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4795", geneDisplay: "NFKBIL1" },
  { key: "NM_005009.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4833", geneDisplay: "NME4" },
  { key: "NM_005011.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4899", geneDisplay: "NRF1" },
  { key: "NM_005012.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4919", geneDisplay: "ROR1" },
  { key: "NM_005013.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4925", geneDisplay: "NUCB2" },
  { key: "NM_005014.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4958", geneDisplay: "OMD" },
  { key: "NM_005015.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5018", geneDisplay: "OXA1L" },
  { key: "NM_005018.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5133", geneDisplay: "PDCD1" },
  { key: "NM_005021.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5169", geneDisplay: "ENPP3" },
  { key: "NM_005022.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5216", geneDisplay: "PFN1" },
  { key: "NM_005023.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5229", geneDisplay: "PGGT1B" },
  { key: "NM_005024.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5273", geneDisplay: "SERPINB10" },
  { key: "NM_005026.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5293", geneDisplay: "PIK3CD" },
  { key: "NM_005027.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5296", geneDisplay: "PIK3R2" },
  { key: "NM_005028.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5305", geneDisplay: "PIP4K2A" },
  { key: "NM_005029.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5309", geneDisplay: "PITX3" },
  { key: "NM_005030.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5347", geneDisplay: "PLK1" },
  { key: "NM_005032.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5358", geneDisplay: "PLS3" },
  { key: "NM_005033.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5393", geneDisplay: "EXOSC9" },
  { key: "NM_005034.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5440", geneDisplay: "POLR2K" },
  { key: "NM_005035.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5442", geneDisplay: "POLRMT" },
  { key: "NM_005036.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5465", geneDisplay: "PPARA" },
  { key: "NM_005038.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5481", geneDisplay: "PPID" },
  { key: "NM_005040.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5547", geneDisplay: "PRCP" },
  { key: "NM_005044.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5613", geneDisplay: "PRKX" },
  { key: "NM_005045.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5649", geneDisplay: "RELN" },
  { key: "NM_005046.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5650", geneDisplay: "KLK7" },
  { key: "NM_005047.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5711", geneDisplay: "PSMD5" },
  { key: "NM_005048.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5746", geneDisplay: "PTH2R" },
  { key: "NM_005049.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5822", geneDisplay: "PWP2" },
  { key: "NM_005050.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5826", geneDisplay: "ABCD4" },
  { key: "NM_005051.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5859", geneDisplay: "QARS1" },
  { key: "NM_005052.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5881", geneDisplay: "RAC3" },
  { key: "NM_005053.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5886", geneDisplay: "RAD23A" },
  { key: "NM_005054.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84220", geneDisplay: "RGPD5" },
  { key: "NM_005055.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5913", geneDisplay: "RAPSN" },
  { key: "NM_005057.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5929", geneDisplay: "RBBP5" },
  { key: "NM_005058.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "5940", geneDisplay: "RBMY1A1" },
  { key: "NM_005060.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6097", geneDisplay: "RORC" },
  { key: "NM_005061.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6123", geneDisplay: "RPL3L" },
  { key: "NM_005063.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6319", geneDisplay: "SCD" },
  { key: "NM_005064.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6368", geneDisplay: "CCL23" },
  { key: "NM_005065.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6400", geneDisplay: "SEL1L" },
  { key: "NM_005066.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6421", geneDisplay: "SFPQ" },
  { key: "NM_005067.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6478", geneDisplay: "SIAH2" },
  { key: "NM_005068.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6492", geneDisplay: "SIM1" },
  { key: "NM_005069.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6493", geneDisplay: "SIM2" },
  { key: "NM_005070.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6508", geneDisplay: "SLC4A3" },
  { key: "NM_005071.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6511", geneDisplay: "SLC1A6" },
  { key: "NM_005072.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6560", geneDisplay: "SLC12A4" },
  { key: "NM_005073.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6564", geneDisplay: "SLC15A1" },
  { key: "NM_005074.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6568", geneDisplay: "SLC17A1" },
  { key: "NM_005076.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6900", geneDisplay: "CNTN2" },
  { key: "NM_005077.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7088", geneDisplay: "TLE1" },
  { key: "NM_005082.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7706", geneDisplay: "TRIM25" },
  { key: "NM_005084.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7941", geneDisplay: "PLA2G7" },
  { key: "NM_005085.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8021", geneDisplay: "NUP214" },
  { key: "NM_005086.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8082", geneDisplay: "SSPN" },
  { key: "NM_005087.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8087", geneDisplay: "FXR1" },
  { key: "NM_005089.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8233", geneDisplay: "ZRSR2" },
  { key: "NM_005091.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8993", geneDisplay: "PGLYRP1" },
  { key: "NM_005092.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8995", geneDisplay: "TNFSF18" },
  { key: "NM_005094.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10999", geneDisplay: "SLC27A4" },
  { key: "NM_005095.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9202", geneDisplay: "ZMYM4" },
  { key: "NM_005097.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9211", geneDisplay: "LGI1" },
  { key: "NM_005098.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9242", geneDisplay: "MSC" },
  { key: "NM_005099.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9507", geneDisplay: "ADAMTS4" },
  { key: "NM_005100.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9590", geneDisplay: "AKAP12" },
  { key: "NM_005101.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9636", geneDisplay: "ISG15" },
  { key: "NM_005102.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9637", geneDisplay: "FEZ2" },
  { key: "NM_005103.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9638", geneDisplay: "FEZ1" },
  { key: "NM_005104.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6046", geneDisplay: "BRD2" },
  { key: "NM_005105.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9939", geneDisplay: "RBM8A" },
  { key: "NM_005107.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9941", geneDisplay: "EXOG" },
  { key: "NM_005108.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9942", geneDisplay: "XYLB" },
  { key: "NM_005109.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9943", geneDisplay: "OXSR1" },
  { key: "NM_005110.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9945", geneDisplay: "GFPT2" },
  { key: "NM_005113.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9950", geneDisplay: "GOLGA5" },
  { key: "NM_005114.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9957", geneDisplay: "HS3ST1" },
  { key: "NM_005115.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9961", geneDisplay: "MVP" },
  { key: "NM_005116.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9962", geneDisplay: "SLC23A2" },
  { key: "NM_005117.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9965", geneDisplay: "FGF19" },
  { key: "NM_005118.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9966", geneDisplay: "TNFSF15" },
  { key: "NM_005119.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9967", geneDisplay: "THRAP3" },
  { key: "NM_005120.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9968", geneDisplay: "MED12" },
  { key: "NM_005121.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9969", geneDisplay: "MED13" },
  { key: "NM_005122.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9970", geneDisplay: "NR1I3" },
  { key: "NM_005124.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9972", geneDisplay: "NUP153" },
  { key: "NM_005125.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9973", geneDisplay: "CCS" },
  { key: "NM_005126.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9975", geneDisplay: "NR1D2" },
  { key: "NM_005127.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9976", geneDisplay: "CLEC2B" },
  { key: "NM_005130.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9982", geneDisplay: "FGFBP1" },
  { key: "NM_005131.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9984", geneDisplay: "THOC1" },
  { key: "NM_005133.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9986", geneDisplay: "RCE1" },
  { key: "NM_005137.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9993", geneDisplay: "DGCR2" },
  { key: "NM_005138.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9997", geneDisplay: "SCO2" },
  { key: "NM_005139.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "306", geneDisplay: "ANXA3" },
  { key: "NM_005140.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1260", geneDisplay: "CNGA2" },
  { key: "NM_005141.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2244", geneDisplay: "FGB" },
  { key: "NM_005142.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2694", geneDisplay: "CBLIF" },
  { key: "NM_005143.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3240", geneDisplay: "HP" },
  { key: "NM_005144.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55806", geneDisplay: "HR" },
  { key: "NM_005146.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9092", geneDisplay: "SART1" },
  { key: "NM_005147.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9093", geneDisplay: "DNAJA3" },
  { key: "NM_005148.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9094", geneDisplay: "UNC119" },
  { key: "NM_005149.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9095", geneDisplay: "TBX19" },
  { key: "NM_005151.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9097", geneDisplay: "USP14" },
  { key: "NM_005153.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9100", geneDisplay: "USP10" },
  { key: "NM_005154.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9101", geneDisplay: "USP8" },
  { key: "NM_005155.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9374", geneDisplay: "PPT2" },
  { key: "NM_005157.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25", geneDisplay: "ABL1" },
  { key: "NM_005159.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "70", geneDisplay: "ACTC1" },
  { key: "NM_005160.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "157", geneDisplay: "GRK3" },
  { key: "NM_005161.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "187", geneDisplay: "APLNR" },
  { key: "NM_005164.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "225", geneDisplay: "ABCD2" },
  { key: "NM_005165.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "230", geneDisplay: "ALDOC" },
  { key: "NM_005167.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "333926", geneDisplay: "PPM1J" },
  { key: "NM_005168.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "390", geneDisplay: "RND3" },
  { key: "NM_005169.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "401", geneDisplay: "PHOX2A" },
  { key: "NM_005170.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "430", geneDisplay: "ASCL2" },
  { key: "NM_005171.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "466", geneDisplay: "ATF1" },
  { key: "NM_005172.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "474", geneDisplay: "ATOH1" },
  { key: "NM_005173.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "489", geneDisplay: "ATP2A3" },
  { key: "NM_005175.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "516", geneDisplay: "ATP5MC1" },
  { key: "NM_005176.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "517", geneDisplay: "ATP5MC2" },
  { key: "NM_005178.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "602", geneDisplay: "BCL3" },
  { key: "NM_005180.9", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "648", geneDisplay: "BMI1" },
  { key: "NM_005181.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "761", geneDisplay: "CA3" },
  { key: "NM_005182.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "766", geneDisplay: "CA7" },
  { key: "NM_005184.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "808", geneDisplay: "CALM3" },
  { key: "NM_005185.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "810", geneDisplay: "CALML3" },
  { key: "NM_005186.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "823", geneDisplay: "CAPN1" },
  { key: "NM_005187.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "863", geneDisplay: "CBFA2T3" },
  { key: "NM_005188.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "867", geneDisplay: "CBL" },
  { key: "NM_005189.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84733", geneDisplay: "CBX2" },
  { key: "NM_005190.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "892", geneDisplay: "CCNC" },
  { key: "NM_005191.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "941", geneDisplay: "CD80" },
  { key: "NM_005192.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1033", geneDisplay: "CDKN3" },
  { key: "NM_005193.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1046", geneDisplay: "CDX4" },
  { key: "NM_005194.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1051", geneDisplay: "CEBPB" },
  { key: "NM_005195.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1052", geneDisplay: "CEBPD" },
  { key: "NM_005197.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1112", geneDisplay: "FOXN3" },
  { key: "NM_005198.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1120", geneDisplay: "CHKB" },
  { key: "NM_005199.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1146", geneDisplay: "CHRNG" },
  { key: "NM_005201.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1237", geneDisplay: "CCR8" },
  { key: "NM_005202.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1296", geneDisplay: "COL8A2" },
  { key: "NM_005204.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1326", geneDisplay: "MAP3K8" },
  { key: "NM_005205.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1339", geneDisplay: "COX6A2" },
  { key: "NM_005207.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1399", geneDisplay: "CRKL" },
  { key: "NM_005208.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1411", geneDisplay: "CRYBA1" },
  { key: "NM_005210.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1419", geneDisplay: "CRYGB" },
  { key: "NM_005213.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1475", geneDisplay: "CSTA" },
  { key: "NM_005214.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1493", geneDisplay: "CTLA4" },
  { key: "NM_005215.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1630", geneDisplay: "DCC" },
  { key: "NM_005216.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1650", geneDisplay: "DDOST" },
  { key: "NM_005217.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1668", geneDisplay: "DEFA3" },
  { key: "NM_005218.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1672", geneDisplay: "DEFB1" },
  { key: "NM_005219.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1729", geneDisplay: "DIAPH1" },
  { key: "NM_005220.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1747", geneDisplay: "DLX3" },
  { key: "NM_005221.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1749", geneDisplay: "DLX5" },
  { key: "NM_005222.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1750", geneDisplay: "DLX6" },
  { key: "NM_005223.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1773", geneDisplay: "DNASE1" },
  { key: "NM_005224.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1820", geneDisplay: "ARID3A" },
  { key: "NM_005225.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1869", geneDisplay: "E2F1" },
  { key: "NM_005226.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1903", geneDisplay: "S1PR3" },
  { key: "NM_005227.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1945", geneDisplay: "EFNA4" },
  { key: "NM_005228.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1956", geneDisplay: "EGFR" },
  { key: "NM_005230.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2004", geneDisplay: "ELK3" },
  { key: "NM_005231.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2017", geneDisplay: "CTTN" },
  { key: "NM_005232.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2041", geneDisplay: "EPHA1" },
  { key: "NM_005233.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2042", geneDisplay: "EPHA3" },
  { key: "NM_005234.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2063", geneDisplay: "NR2F6" },
  { key: "NM_005235.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2066", geneDisplay: "ERBB4" },
  { key: "NM_005236.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2072", geneDisplay: "ERCC4" },
  { key: "NM_005239.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "2114", geneDisplay: "ETS2" },
  { key: "NM_005242.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2150", geneDisplay: "F2RL1" },
  { key: "NM_005243.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2130", geneDisplay: "EWSR1" },
  { key: "NM_005244.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2139", geneDisplay: "EYA2" },
  { key: "NM_005245.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2195", geneDisplay: "FAT1" },
  { key: "NM_005246.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2241", geneDisplay: "FER" },
  { key: "NM_005247.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2248", geneDisplay: "FGF3" },
  { key: "NM_005248.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2268", geneDisplay: "FGR" },
  { key: "NM_005249.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2290", geneDisplay: "FOXG1" },
  { key: "NM_005250.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2300", geneDisplay: "FOXL1" },
  { key: "NM_005251.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2303", geneDisplay: "FOXC2" },
  { key: "NM_005252.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2353", geneDisplay: "FOS" },
  { key: "NM_005253.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2355", geneDisplay: "FOSL2" },
  { key: "NM_005255.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2580", geneDisplay: "GAK" },
  { key: "NM_005257.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2627", geneDisplay: "GATA6" },
  { key: "NM_005258.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2644", geneDisplay: "GCHFR" },
  { key: "NM_005259.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2660", geneDisplay: "MSTN" },
  { key: "NM_005260.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2661", geneDisplay: "GDF9" },
  { key: "NM_005261.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2669", geneDisplay: "GEM" },
  { key: "NM_005262.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2671", geneDisplay: "GFER" },
  { key: "NM_005263.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2672", geneDisplay: "GFI1" },
  { key: "NM_005264.8", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2674", geneDisplay: "GFRA1" },
  { key: "NM_005267.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2703", geneDisplay: "GJA8" },
  { key: "NM_005268.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2709", geneDisplay: "GJB5" },
  { key: "NM_005269.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2735", geneDisplay: "GLI1" },
  { key: "NM_005271.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2746", geneDisplay: "GLUD1" },
  { key: "NM_005273.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2783", geneDisplay: "GNB2" },
  { key: "NM_005274.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2787", geneDisplay: "GNG5" },
  { key: "NM_005275.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2794", geneDisplay: "GNL1" },
  { key: "NM_005276.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2819", geneDisplay: "GPD1" },
  { key: "NM_005281.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2827", geneDisplay: "GPR3" },
  { key: "NM_005282.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2828", geneDisplay: "GPR4" },
  { key: "NM_005284.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2830", geneDisplay: "GPR6" },
  { key: "NM_005285.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2831", geneDisplay: "NPBWR1" },
  { key: "NM_005286.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2832", geneDisplay: "NPBWR2" },
  { key: "NM_005288.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2835", geneDisplay: "GPR12" },
  { key: "NM_005290.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2838", geneDisplay: "GPR15" },
  { key: "NM_005293.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2843", geneDisplay: "GPR20" },
  { key: "NM_005294.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2844", geneDisplay: "GPR21" },
  { key: "NM_005295.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2845", geneDisplay: "GPR22" },
  { key: "NM_005297.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2847", geneDisplay: "MCHR1" },
  { key: "NM_005298.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2848", geneDisplay: "GPR25" },
  { key: "NM_005299.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2853", geneDisplay: "GPR31" },
  { key: "NM_005301.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2859", geneDisplay: "GPR35" },
  { key: "NM_005302.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2861", geneDisplay: "GPR37" },
  { key: "NM_005303.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2864", geneDisplay: "FFAR1" },
  { key: "NM_005304.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2865", geneDisplay: "FFAR3" },
  { key: "NM_005308.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2869", geneDisplay: "GRK5" },
  { key: "NM_005309.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2875", geneDisplay: "GPT" },
  { key: "NM_005310.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2886", geneDisplay: "GRB7" },
  { key: "NM_005313.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2923", geneDisplay: "PDIA3" },
  { key: "NM_005314.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2925", geneDisplay: "GRPR" },
  { key: "NM_005315.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2928", geneDisplay: "GSC2" },
  { key: "NM_005316.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2965", geneDisplay: "GTF2H1" },
  { key: "NM_005317.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3004", geneDisplay: "GZMM" },
  { key: "NM_005318.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3005", geneDisplay: "H1-0" },
  { key: "NM_005319.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3006", geneDisplay: "H1-2" },
  { key: "NM_005320.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3007", geneDisplay: "H1-3" },
  { key: "NM_005321.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3008", geneDisplay: "H1-4" },
  { key: "NM_005322.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3009", geneDisplay: "H1-5" },
  { key: "NM_005323.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3010", geneDisplay: "H1-6" },
  { key: "NM_005324.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3021", geneDisplay: "H3-3B" },
  { key: "NM_005325.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3024", geneDisplay: "H1-1" },
  { key: "NM_005326.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3029", geneDisplay: "HAGH" },
  { key: "NM_005327.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3033", geneDisplay: "HADH" },
  { key: "NM_005328.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3037", geneDisplay: "HAS2" },
  { key: "NM_005330.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3046", geneDisplay: "HBE1" },
  { key: "NM_005331.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3049", geneDisplay: "HBQ1" },
  { key: "NM_005332.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3050", geneDisplay: "HBZ" },
  { key: "NM_005333.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3052", geneDisplay: "HCCS" },
  { key: "NM_005334.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3054", geneDisplay: "HCFC1" },
  { key: "NM_005335.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3059", geneDisplay: "HCLS1" },
  { key: "NM_005336.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3069", geneDisplay: "HDLBP" },
  { key: "NM_005337.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3071", geneDisplay: "NCKAP1L" },
  { key: "NM_005338.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3092", geneDisplay: "HIP1" },
  { key: "NM_005339.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3093", geneDisplay: "UBE2K" },
  { key: "NM_005340.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3094", geneDisplay: "HINT1" },
  { key: "NM_005341.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3104", geneDisplay: "ZBTB48" },
  { key: "NM_005342.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3149", geneDisplay: "HMGB3" },
  { key: "NM_005343.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3265", geneDisplay: "HRAS" },
  { key: "NM_005345.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3303", geneDisplay: "HSPA1A" },
  { key: "NM_005346.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3304", geneDisplay: "HSPA1B" },
  { key: "NM_005347.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3309", geneDisplay: "HSPA5" },
  { key: "NM_005348.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3320", geneDisplay: "HSP90AA1" },
  { key: "NM_005353.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3681", geneDisplay: "ITGAD" },
  { key: "NM_005354.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3727", geneDisplay: "JUND" },
  { key: "NM_005356.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3932", geneDisplay: "LCK" },
  { key: "NM_005357.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3991", geneDisplay: "LIPE" },
  { key: "NM_005359.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4089", geneDisplay: "SMAD4" },
  { key: "NM_005360.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4094", geneDisplay: "MAF" },
  { key: "NM_005362.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4102", geneDisplay: "MAGEA3" },
  { key: "NM_005363.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4105", geneDisplay: "MAGEA6" },
  { key: "NM_005364.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4107", geneDisplay: "MAGEA8" },
  { key: "NM_005365.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4108", geneDisplay: "MAGEA9" },
  { key: "NM_005366.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4110", geneDisplay: "MAGEA11" },
  { key: "NM_005368.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4151", geneDisplay: "MB" },
  { key: "NM_005370.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4218", geneDisplay: "RAB8A" },
  { key: "NM_005371.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4234", geneDisplay: "METTL1" },
  { key: "NM_005372.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4342", geneDisplay: "MOS" },
  { key: "NM_005373.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4352", geneDisplay: "MPL" },
  { key: "NM_005374.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4355", geneDisplay: "MPP2" },
  { key: "NM_005378.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4613", geneDisplay: "MYCN" },
  { key: "NM_005379.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4640", geneDisplay: "MYO1A" },
  { key: "NM_005380.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4681", geneDisplay: "NBL1" },
  { key: "NM_005381.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4691", geneDisplay: "NCL" },
  { key: "NM_005382.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4741", geneDisplay: "NEFM" },
  { key: "NM_005383.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4759", geneDisplay: "NEU2" },
  { key: "NM_005384.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4783", geneDisplay: "NFIL3" },
  { key: "NM_005385.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4820", geneDisplay: "NKTR" },
  { key: "NM_005386.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4826", geneDisplay: "NNAT" },
  { key: "NM_005388.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5082", geneDisplay: "PDCL" },
  { key: "NM_005390.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5161", geneDisplay: "PDHA2" },
  { key: "NM_005391.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5165", geneDisplay: "PDK3" },
  { key: "NM_005392.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5253", geneDisplay: "PHF2" },
  { key: "NM_005393.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5365", geneDisplay: "PLXNB3" },
  { key: "NM_005398.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5507", geneDisplay: "PPP1R3C" },
  { key: "NM_005399.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5565", geneDisplay: "PRKAB2" },
  { key: "NM_005400.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5581", geneDisplay: "PRKCE" },
  { key: "NM_005401.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5784", geneDisplay: "PTPN14" },
  { key: "NM_005402.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5898", geneDisplay: "RALA" },
  { key: "NM_005406.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6093", geneDisplay: "ROCK1" },
  { key: "NM_005408.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6357", geneDisplay: "CCL13" },
  { key: "NM_005409.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6373", geneDisplay: "CXCL11" },
  { key: "NM_005410.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6414", geneDisplay: "SELENOP" },
  { key: "NM_005411.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "653509", geneDisplay: "SFTPA1" },
  { key: "NM_005412.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6472", geneDisplay: "SHMT2" },
  { key: "NM_005413.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6496", geneDisplay: "SIX3" },
  { key: "NM_005414.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6498", geneDisplay: "SKIL" },
  { key: "NM_005415.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6574", geneDisplay: "SLC20A1" },
  { key: "NM_005419.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6773", geneDisplay: "STAT2" },
  { key: "NM_005420.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6783", geneDisplay: "SULT1E1" },
  { key: "NM_005421.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6887", geneDisplay: "TAL2" },
  { key: "NM_005422.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7007", geneDisplay: "TECTA" },
  { key: "NM_005423.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7032", geneDisplay: "TFF2" },
  { key: "NM_005424.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7075", geneDisplay: "TIE1" },
  { key: "NM_005425.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7142", geneDisplay: "TNP2" },
  { key: "NM_005427.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7161", geneDisplay: "TP73" },
  { key: "NM_005428.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7409", geneDisplay: "VAV1" },
  { key: "NM_005429.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7424", geneDisplay: "VEGFC" },
  { key: "NM_005430.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7471", geneDisplay: "WNT1" },
  { key: "NM_005431.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7516", geneDisplay: "XRCC2" },
  { key: "NM_005432.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7517", geneDisplay: "XRCC3" },
  { key: "NM_005433.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7525", geneDisplay: "YES1" },
  { key: "NM_005434.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7851", geneDisplay: "MALL" },
  { key: "NM_005435.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7984", geneDisplay: "ARHGEF5" },
  { key: "NM_005436.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8030", geneDisplay: "CCDC6" },
  { key: "NM_005438.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8061", geneDisplay: "FOSL1" },
  { key: "NM_005440.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8153", geneDisplay: "RND2" },
  { key: "NM_005441.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8208", geneDisplay: "CHAF1B" },
  { key: "NM_005443.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9061", geneDisplay: "PAPSS1" },
  { key: "NM_005444.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9125", geneDisplay: "CNOT9" },
  { key: "NM_005445.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9126", geneDisplay: "SMC3" },
  { key: "NM_005446.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9127", geneDisplay: "P2RX6" },
  { key: "NM_005447.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9182", geneDisplay: "RASSF9" },
  { key: "NM_005448.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9210", geneDisplay: "BMP15" },
  { key: "NM_005449.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9214", geneDisplay: "FCMR" },
  { key: "NM_005450.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9241", geneDisplay: "NOG" },
  { key: "NM_005451.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9260", geneDisplay: "PDLIM7" },
  { key: "NM_005452.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9277", geneDisplay: "WDR46" },
  { key: "NM_005453.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9278", geneDisplay: "ZBTB22" },
  { key: "NM_005454.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9350", geneDisplay: "CER1" },
  { key: "NM_005456.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9479", geneDisplay: "MAPK8IP1" },
  { key: "NM_005458.8", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9568", geneDisplay: "GABBR2" },
  { key: "NM_005459.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9626", geneDisplay: "GUCA1C" },
  { key: "NM_005460.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9627", geneDisplay: "SNCAIP" },
  { key: "NM_005461.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9935", geneDisplay: "MAFB" },
  { key: "NM_005462.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9947", geneDisplay: "MAGEC1" },
  { key: "NM_005465.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10000", geneDisplay: "AKT3" },
  { key: "NM_005466.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10001", geneDisplay: "MED6" },
  { key: "NM_005467.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10003", geneDisplay: "NAALAD2" },
  { key: "NM_005468.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10004", geneDisplay: "NAALADL1" },
  { key: "NM_005469.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10005", geneDisplay: "ACOT8" },
  { key: "NM_005471.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10007", geneDisplay: "GNPDA1" },
  { key: "NM_005472.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10008", geneDisplay: "KCNE3" },
  { key: "NM_005474.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10014", geneDisplay: "HDAC5" },
  { key: "NM_005475.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10019", geneDisplay: "SH2B3" },
  { key: "NM_005476.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10020", geneDisplay: "GNE" },
  { key: "NM_005477.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10021", geneDisplay: "HCN4" },
  { key: "NM_005478.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10022", geneDisplay: "INSL5" },
  { key: "NM_005479.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10023", geneDisplay: "FRAT1" },
  { key: "NM_005480.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10024", geneDisplay: "TROAP" },
  { key: "NM_005481.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10025", geneDisplay: "MED16" },
  { key: "NM_005482.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10026", geneDisplay: "PIGK" },
  { key: "NM_005483.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10036", geneDisplay: "CHAF1A" },
  { key: "NM_005486.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10040", geneDisplay: "TOM1L1" },
  { key: "NM_005488.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10043", geneDisplay: "TOM1" },
  { key: "NM_005490.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10045", geneDisplay: "SH2D3A" },
  { key: "NM_005491.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10046", geneDisplay: "MAMLD1" },
  { key: "NM_005492.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10047", geneDisplay: "CST8" },
  { key: "NM_005493.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10048", geneDisplay: "RANBP9" },
  { key: "NM_005495.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10050", geneDisplay: "SLC17A4" },
  { key: "NM_005497.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10052", geneDisplay: "GJC1" },
  { key: "NM_005498.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10053", geneDisplay: "AP1M2" },
  { key: "NM_005499.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10054", geneDisplay: "UBA2" },
  { key: "NM_005500.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10055", geneDisplay: "SAE1" },
  { key: "NM_005502.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "19", geneDisplay: "ABCA1" },
  { key: "NM_005504.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "586", geneDisplay: "BCAT1" },
  { key: "NM_005505.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "949", geneDisplay: "SCARB1" },
  { key: "NM_005506.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "950", geneDisplay: "SCARB2" },
  { key: "NM_005507.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1072", geneDisplay: "CFL1" },
  { key: "NM_005508.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1233", geneDisplay: "CCR4" },
  { key: "NM_005510.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1797", geneDisplay: "DXO" },
  { key: "NM_005511.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2315", geneDisplay: "MLANA" },
  { key: "NM_005513.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2960", geneDisplay: "GTF2E1" },
  { key: "NM_005514.8", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3106", geneDisplay: "HLA-B" },
  { key: "NM_005515.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3110", geneDisplay: "MNX1" },
  { key: "NM_005516.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3133", geneDisplay: "HLA-E" },
  { key: "NM_005517.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3151", geneDisplay: "HMGN2" },
  { key: "NM_005518.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3158", geneDisplay: "HMGCS2" },
  { key: "NM_005519.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3167", geneDisplay: "HMX2" },
  { key: "NM_005521.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3195", geneDisplay: "TLX1" },
  { key: "NM_005522.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3198", geneDisplay: "HOXA1" },
  { key: "NM_005523.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3207", geneDisplay: "HOXA11" },
  { key: "NM_005524.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3280", geneDisplay: "HES1" },
  { key: "NM_005525.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3290", geneDisplay: "HSD11B1" },
  { key: "NM_005526.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3297", geneDisplay: "HSF1" },
  { key: "NM_005527.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3305", geneDisplay: "HSPA1L" },
  { key: "NM_005528.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3338", geneDisplay: "DNAJC4" },
  { key: "NM_005529.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3339", geneDisplay: "HSPG2" },
  { key: "NM_005530.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3419", geneDisplay: "IDH3A" },
  { key: "NM_005534.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3460", geneDisplay: "IFNGR2" },
  { key: "NM_005535.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3594", geneDisplay: "IL12RB1" },
  { key: "NM_005536.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3612", geneDisplay: "IMPA1" },
  { key: "NM_005538.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3626", geneDisplay: "INHBC" },
  { key: "NM_005539.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3632", geneDisplay: "INPP5A" },
  { key: "NM_005540.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3633", geneDisplay: "INPP5B" },
  { key: "NM_005542.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3638", geneDisplay: "INSIG1" },
  { key: "NM_005543.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3640", geneDisplay: "INSL3" },
  { key: "NM_005544.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3667", geneDisplay: "IRS1" },
  { key: "NM_005545.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3671", geneDisplay: "ISLR" },
  { key: "NM_005546.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3702", geneDisplay: "ITK" },
  { key: "NM_005547.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3713", geneDisplay: "IVL" },
  { key: "NM_005548.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3735", geneDisplay: "KARS1" },
  { key: "NM_005549.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3744", geneDisplay: "KCNA10" },
  { key: "NM_005551.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3817", geneDisplay: "KLK2" },
  { key: "NM_005553.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3846", geneDisplay: "KRTAP5-9" },
  { key: "NM_005554.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3853", geneDisplay: "KRT6A" },
  { key: "NM_005555.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3854", geneDisplay: "KRT6B" },
  { key: "NM_005556.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3855", geneDisplay: "KRT7" },
  { key: "NM_005557.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3868", geneDisplay: "KRT16" },
  { key: "NM_005558.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3898", geneDisplay: "LAD1" },
  { key: "NM_005559.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284217", geneDisplay: "LAMA1" },
  { key: "NM_005560.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3911", geneDisplay: "LAMA5" },
  { key: "NM_005561.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3916", geneDisplay: "LAMP1" },
  { key: "NM_005562.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3918", geneDisplay: "LAMC2" },
  { key: "NM_005563.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3925", geneDisplay: "STMN1" },
  { key: "NM_005564.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3934", geneDisplay: "LCN2" },
  { key: "NM_005565.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3937", geneDisplay: "LCP2" },
  { key: "NM_005566.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3939", geneDisplay: "LDHA" },
  { key: "NM_005567.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3959", geneDisplay: "LGALS3BP" },
  { key: "NM_005568.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3975", geneDisplay: "LHX1" },
  { key: "NM_005569.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3985", geneDisplay: "LIMK2" },
  { key: "NM_005570.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "3998", geneDisplay: "LMAN1" },
  { key: "NM_005573.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4001", geneDisplay: "LMNB1" },
  { key: "NM_005574.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4005", geneDisplay: "LMO2" },
  { key: "NM_005575.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4012", geneDisplay: "LNPEP" },
  { key: "NM_005576.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4016", geneDisplay: "LOXL1" },
  { key: "NM_005577.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4018", geneDisplay: "LPA" },
  { key: "NM_005581.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4059", geneDisplay: "BCAM" },
  { key: "NM_005582.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4064", geneDisplay: "CD180" },
  { key: "NM_005583.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4066", geneDisplay: "LYL1" },
  { key: "NM_005584.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "4081", geneDisplay: "MAB21L1" },
  { key: "NM_005585.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4091", geneDisplay: "SMAD6" },
  { key: "NM_005586.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4188", geneDisplay: "MDFI" },
  { key: "NM_005588.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4224", geneDisplay: "MEP1A" },
  { key: "NM_005589.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4329", geneDisplay: "ALDH6A1" },
  { key: "NM_005591.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4361", geneDisplay: "MRE11" },
  { key: "NM_005592.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4593", geneDisplay: "MUSK" },
  { key: "NM_005593.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4617", geneDisplay: "MYF5" },
  { key: "NM_005598.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4807", geneDisplay: "NHLH1" },
  { key: "NM_005599.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4808", geneDisplay: "NHLH2" },
  { key: "NM_005600.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4817", geneDisplay: "NIT1" },
  { key: "NM_005601.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4818", geneDisplay: "NKG7" },
  { key: "NM_005602.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5010", geneDisplay: "CLDN11" },
  { key: "NM_005604.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5454", geneDisplay: "POU3F2" },
  { key: "NM_005605.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5533", geneDisplay: "PPP3CC" },
  { key: "NM_005606.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5641", geneDisplay: "LGMN" },
  { key: "NM_005608.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5790", geneDisplay: "PTPRCAP" },
  { key: "NM_005609.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5837", geneDisplay: "PYGM" },
  { key: "NM_005610.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5928", geneDisplay: "RBBP4" },
  { key: "NM_005611.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5934", geneDisplay: "RBL2" },
  { key: "NM_005612.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5978", geneDisplay: "REST" },
  { key: "NM_005613.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5999", geneDisplay: "RGS4" },
  { key: "NM_005614.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6009", geneDisplay: "RHEB" },
  { key: "NM_005615.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6039", geneDisplay: "RNASE6" },
  { key: "NM_005617.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6208", geneDisplay: "RPS14" },
  { key: "NM_005618.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "28514", geneDisplay: "DLL1" },
  { key: "NM_005619.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6253", geneDisplay: "RTN2" },
  { key: "NM_005620.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6282", geneDisplay: "S100A11" },
  { key: "NM_005621.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6283", geneDisplay: "S100A12" },
  { key: "NM_005622.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6296", geneDisplay: "ACSM3" },
  { key: "NM_005623.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6355", geneDisplay: "CCL8" },
  { key: "NM_005624.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6370", geneDisplay: "CCL25" },
  { key: "NM_005625.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6386", geneDisplay: "SDCBP" },
  { key: "NM_005626.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6429", geneDisplay: "SRSF4" },
  { key: "NM_005628.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6510", geneDisplay: "SLC1A5" },
  { key: "NM_005629.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6535", geneDisplay: "SLC6A8" },
  { key: "NM_005630.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6578", geneDisplay: "SLCO2A1" },
  { key: "NM_005631.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6608", geneDisplay: "SMO" },
  { key: "NM_005632.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6650", geneDisplay: "CAPN15" },
  { key: "NM_005633.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6654", geneDisplay: "SOS1" },
  { key: "NM_005634.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6658", geneDisplay: "SOX3" },
  { key: "NM_005635.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6756", geneDisplay: "SSX1" },
  { key: "NM_005636.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6759", geneDisplay: "SSX4" },
  { key: "NM_005639.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6857", geneDisplay: "SYT1" },
  { key: "NM_005640.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6875", geneDisplay: "TAF4B" },
  { key: "NM_005642.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6879", geneDisplay: "TAF7" },
  { key: "NM_005643.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6882", geneDisplay: "TAF11" },
  { key: "NM_005644.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6883", geneDisplay: "TAF12" },
  { key: "NM_005645.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6884", geneDisplay: "TAF13" },
  { key: "NM_005646.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6894", geneDisplay: "TARBP1" },
  { key: "NM_005647.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6907", geneDisplay: "TBL1X" },
  { key: "NM_005648.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6921", geneDisplay: "ELOC" },
  { key: "NM_005649.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6940", geneDisplay: "ZNF354A" },
  { key: "NM_005651.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6999", geneDisplay: "TDO2" },
  { key: "NM_005652.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7014", geneDisplay: "TERF2" },
  { key: "NM_005653.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7024", geneDisplay: "TFCP2" },
  { key: "NM_005654.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7025", geneDisplay: "NR2F1" },
  { key: "NM_005655.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7071", geneDisplay: "KLF10" },
  { key: "NM_005656.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7113", geneDisplay: "TMPRSS2" },
  { key: "NM_005658.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7185", geneDisplay: "TRAF1" },
  { key: "NM_005659.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7353", geneDisplay: "UFD1" },
  { key: "NM_005660.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7355", geneDisplay: "SLC35A2" },
  { key: "NM_005662.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7419", geneDisplay: "VDAC3" },
  { key: "NM_005663.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7469", geneDisplay: "NELFA" },
  { key: "NM_005664.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7681", geneDisplay: "MKRN3" },
  { key: "NM_005666.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3080", geneDisplay: "CFHR2" },
  { key: "NM_005667.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7844", geneDisplay: "RNF103" },
  { key: "NM_005668.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7903", geneDisplay: "ST8SIA4" },
  { key: "NM_005669.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7905", geneDisplay: "REEP5" },
  { key: "NM_005670.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7957", geneDisplay: "EPM2A" },
  { key: "NM_005671.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7993", geneDisplay: "UBXN8" },
  { key: "NM_005672.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8000", geneDisplay: "PSCA" },
  { key: "NM_005675.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8214", geneDisplay: "DGCR6" },
  { key: "NM_005676.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8241", geneDisplay: "RBM10" },
  { key: "NM_005677.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8292", geneDisplay: "COLQ" },
  { key: "NM_005680.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9014", geneDisplay: "TAF1B" },
  { key: "NM_005681.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9015", geneDisplay: "TAF1A" },
  { key: "NM_005683.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9290", geneDisplay: "GPR55" },
  { key: "NM_005684.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9293", geneDisplay: "GPR52" },
  { key: "NM_005685.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9569", geneDisplay: "GTF2IRD1" },
  { key: "NM_005686.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9580", geneDisplay: "SOX13" },
  { key: "NM_005687.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10056", geneDisplay: "FARSB" },
  { key: "NM_005688.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10057", geneDisplay: "ABCC5" },
  { key: "NM_005689.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10058", geneDisplay: "ABCB6" },
  { key: "NM_005693.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10062", geneDisplay: "NR1H3" },
  { key: "NM_005694.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10063", geneDisplay: "COX17" },
  { key: "NM_005697.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10066", geneDisplay: "SCAMP2" },
  { key: "NM_005698.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10067", geneDisplay: "SCAMP3" },
  { key: "NM_005701.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10073", geneDisplay: "SNUPN" },
  { key: "NM_005702.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26284", geneDisplay: "ERAL1" },
  { key: "NM_005706.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10078", geneDisplay: "TSSC4" },
  { key: "NM_005707.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10081", geneDisplay: "PDCD7" },
  { key: "NM_005708.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10082", geneDisplay: "GPC6" },
  { key: "NM_005711.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10085", geneDisplay: "EDIL3" },
  { key: "NM_005715.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10090", geneDisplay: "UST" },
  { key: "NM_005716.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10755", geneDisplay: "GIPC1" },
  { key: "NM_005717.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10092", geneDisplay: "ARPC5" },
  { key: "NM_005718.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10093", geneDisplay: "ARPC4" },
  { key: "NM_005720.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10095", geneDisplay: "ARPC1B" },
  { key: "NM_005721.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10096", geneDisplay: "ACTR3" },
  { key: "NM_005722.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10097", geneDisplay: "ACTR2" },
  { key: "NM_005723.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10098", geneDisplay: "TSPAN5" },
  { key: "NM_005724.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10099", geneDisplay: "TSPAN3" },
  { key: "NM_005725.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10100", geneDisplay: "TSPAN2" },
  { key: "NM_005726.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10102", geneDisplay: "TSFM" },
  { key: "NM_005727.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10103", geneDisplay: "TSPAN1" },
  { key: "NM_005729.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10105", geneDisplay: "PPIF" },
  { key: "NM_005730.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10106", geneDisplay: "CTDSP2" },
  { key: "NM_005732.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10111", geneDisplay: "RAD50" },
  { key: "NM_005733.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10112", geneDisplay: "KIF20A" },
  { key: "NM_005734.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10114", geneDisplay: "HIPK3" },
  { key: "NM_005735.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10120", geneDisplay: "ACTR1B" },
  { key: "NM_005736.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10121", geneDisplay: "ACTR1A" },
  { key: "NM_005738.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10124", geneDisplay: "ARL4A" },
  { key: "NM_005739.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10125", geneDisplay: "RASGRP1" },
  { key: "NM_005740.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10126", geneDisplay: "DNAL4" },
  { key: "NM_005741.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10127", geneDisplay: "ZNF263" },
  { key: "NM_005742.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10130", geneDisplay: "PDIA6" },
  { key: "NM_005744.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "25820", geneDisplay: "ARIH1" },
  { key: "NM_005746.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10135", geneDisplay: "NAMPT" },
  { key: "NM_005747.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10136", geneDisplay: "CELA3A" },
  { key: "NM_005748.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10138", geneDisplay: "YAF2" },
  { key: "NM_005749.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10140", geneDisplay: "TOB1" },
  { key: "NM_005751.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10142", geneDisplay: "AKAP9" },
  { key: "NM_005752.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10143", geneDisplay: "CLEC3A" },
  { key: "NM_005754.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10146", geneDisplay: "G3BP1" },
  { key: "NM_005755.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10148", geneDisplay: "EBI3" },
  { key: "NM_005760.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10153", geneDisplay: "CEBPZ" },
  { key: "NM_005761.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10154", geneDisplay: "PLXNC1" },
  { key: "NM_005762.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10155", geneDisplay: "TRIM28" },
  { key: "NM_005763.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10157", geneDisplay: "AASS" },
  { key: "NM_005764.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10158", geneDisplay: "PDZK1IP1" },
  { key: "NM_005765.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10159", geneDisplay: "ATP6AP2" },
  { key: "NM_005766.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10160", geneDisplay: "FARP1" },
  { key: "NM_005768.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10162", geneDisplay: "LPCAT3" },
  { key: "NM_005772.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10171", geneDisplay: "RCL1" },
  { key: "NM_005773.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10172", geneDisplay: "ZNF256" },
  { key: "NM_005775.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10174", geneDisplay: "SORBS3" },
  { key: "NM_005776.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10175", geneDisplay: "CNIH1" },
  { key: "NM_005777.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10180", geneDisplay: "RBM6" },
  { key: "NM_005778.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10181", geneDisplay: "RBM5" },
  { key: "NM_005779.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10184", geneDisplay: "LHFPL2" },
  { key: "NM_005780.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10186", geneDisplay: "LHFPL6" },
  { key: "NM_005782.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10189", geneDisplay: "ALYREF" },
  { key: "NM_005783.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10190", geneDisplay: "TXNDC9" },
  { key: "NM_005785.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10193", geneDisplay: "RNF41" },
  { key: "NM_005787.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10195", geneDisplay: "ALG3" },
  { key: "NM_005788.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10196", geneDisplay: "PRMT3" },
  { key: "NM_005789.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10197", geneDisplay: "PSME3" },
  { key: "NM_005791.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10199", geneDisplay: "MPHOSPH10" },
  { key: "NM_005792.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10200", geneDisplay: "MPHOSPH6" },
  { key: "NM_005794.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10202", geneDisplay: "DHRS2" },
  { key: "NM_005795.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10203", geneDisplay: "CALCRL" },
  { key: "NM_005796.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10204", geneDisplay: "NUTF2" },
  { key: "NM_005797.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10205", geneDisplay: "MPZL2" },
  { key: "NM_005800.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10208", geneDisplay: "USPL1" },
  { key: "NM_005801.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10209", geneDisplay: "EIF1" },
  { key: "NM_005802.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10210", geneDisplay: "TOPORS" },
  { key: "NM_005803.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10211", geneDisplay: "FLOT1" },
  { key: "NM_005804.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10212", geneDisplay: "DDX39A" },
  { key: "NM_005805.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10213", geneDisplay: "PSMD14" },
  { key: "NM_005806.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10215", geneDisplay: "OLIG2" },
  { key: "NM_005807.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10216", geneDisplay: "PRG4" },
  { key: "NM_005809.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7001", geneDisplay: "PRDX2" },
  { key: "NM_005810.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10219", geneDisplay: "KLRG1" },
  { key: "NM_005811.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10220", geneDisplay: "GDF11" },
  { key: "NM_005813.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23683", geneDisplay: "PRKD3" },
  { key: "NM_005814.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10223", geneDisplay: "GPA33" },
  { key: "NM_005815.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10224", geneDisplay: "ZNF443" },
  { key: "NM_005816.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10225", geneDisplay: "CD96" },
  { key: "NM_005817.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10226", geneDisplay: "PLIN3" },
  { key: "NM_005819.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10228", geneDisplay: "STX6" },
  { key: "NM_005823.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10232", geneDisplay: "MSLN" },
  { key: "NM_005826.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10236", geneDisplay: "HNRNPR" },
  { key: "NM_005827.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10237", geneDisplay: "SLC35B1" },
  { key: "NM_005828.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10238", geneDisplay: "DCAF7" },
  { key: "NM_005829.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10239", geneDisplay: "AP3S2" },
  { key: "NM_005830.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10240", geneDisplay: "MRPS31" },
  { key: "NM_005831.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10241", geneDisplay: "CALCOCO2" },
  { key: "NM_005833.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10244", geneDisplay: "RABEPK" },
  { key: "NM_005836.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10247", geneDisplay: "RIDA" },
  { key: "NM_005837.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10248", geneDisplay: "POP7" },
  { key: "NM_005839.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10250", geneDisplay: "SRRM1" },
  { key: "NM_005842.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10253", geneDisplay: "SPRY2" },
  { key: "NM_005843.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10254", geneDisplay: "STAM2" },
  { key: "NM_005845.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10257", geneDisplay: "ABCC4" },
  { key: "NM_005847.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9963", geneDisplay: "SLC23A1" },
  { key: "NM_005849.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10261", geneDisplay: "IGSF6" },
  { key: "NM_005850.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10262", geneDisplay: "SF3B4" },
  { key: "NM_005851.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10263", geneDisplay: "CDK2AP2" },
  { key: "NM_005853.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10265", geneDisplay: "IRX5" },
  { key: "NM_005854.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10266", geneDisplay: "RAMP2" },
  { key: "NM_005855.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10267", geneDisplay: "RAMP1" },
  { key: "NM_005856.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10268", geneDisplay: "RAMP3" },
  { key: "NM_005857.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10269", geneDisplay: "ZMPSTE24" },
  { key: "NM_005858.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10270", geneDisplay: "AKAP8" },
  { key: "NM_005859.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5813", geneDisplay: "PURA" },
  { key: "NM_005860.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10272", geneDisplay: "FSTL3" },
  { key: "NM_005861.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10273", geneDisplay: "STUB1" },
  { key: "NM_005862.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10274", geneDisplay: "STAG1" },
  { key: "NM_005864.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10278", geneDisplay: "EFS" },
  { key: "NM_005865.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10279", geneDisplay: "PRSS16" },
  { key: "NM_005866.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10280", geneDisplay: "SIGMAR1" },
  { key: "NM_005868.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10282", geneDisplay: "BET1" },
  { key: "NM_005869.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10283", geneDisplay: "CWC27" },
  { key: "NM_005870.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10284", geneDisplay: "SAP18" },
  { key: "NM_005871.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10285", geneDisplay: "SMNDC1" },
  { key: "NM_005872.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10286", geneDisplay: "BCAS2" },
  { key: "NM_005873.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10287", geneDisplay: "RGS19" },
  { key: "NM_005875.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10289", geneDisplay: "EIF1B" },
  { key: "NM_005876.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10290", geneDisplay: "SPEG" },
  { key: "NM_005877.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10291", geneDisplay: "SF3A1" },
  { key: "NM_005879.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10293", geneDisplay: "TRAIP" },
  { key: "NM_005880.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10294", geneDisplay: "DNAJA2" },
  { key: "NM_005881.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10295", geneDisplay: "BCKDK" },
  { key: "NM_005883.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10297", geneDisplay: "APC2" },
  { key: "NM_005884.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10298", geneDisplay: "PAK4" },
  { key: "NM_005885.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10299", geneDisplay: "MARCHF6" },
  { key: "NM_005886.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10300", geneDisplay: "KATNB1" },
  { key: "NM_005891.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "39", geneDisplay: "ACAT2" },
  { key: "NM_005892.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "752", geneDisplay: "FMNL1" },
  { key: "NM_005893.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "881", geneDisplay: "CCIN" },
  { key: "NM_005894.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "922", geneDisplay: "CD5L" },
  { key: "NM_005896.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3417", geneDisplay: "IDH1" },
  { key: "NM_005897.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3652", geneDisplay: "IPP" },
  { key: "NM_005898.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4076", geneDisplay: "CAPRIN1" },
  { key: "NM_005899.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4077", geneDisplay: "NBR1" },
  { key: "NM_005900.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4086", geneDisplay: "SMAD1" },
  { key: "NM_005901.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4087", geneDisplay: "SMAD2" },
  { key: "NM_005902.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4088", geneDisplay: "SMAD3" },
  { key: "NM_005903.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4090", geneDisplay: "SMAD5" },
  { key: "NM_005904.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4092", geneDisplay: "SMAD7" },
  { key: "NM_005907.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4121", geneDisplay: "MAN1A1" },
  { key: "NM_005908.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4126", geneDisplay: "MANBA" },
  { key: "NM_005909.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4131", geneDisplay: "MAP1B" },
  { key: "NM_005911.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4144", geneDisplay: "MAT2A" },
  { key: "NM_005912.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4160", geneDisplay: "MC4R" },
  { key: "NM_005913.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4161", geneDisplay: "MC5R" },
  { key: "NM_005915.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4175", geneDisplay: "MCM6" },
  { key: "NM_005916.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4176", geneDisplay: "MCM7" },
  { key: "NM_005917.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4190", geneDisplay: "MDH1" },
  { key: "NM_005918.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4191", geneDisplay: "MDH2" },
  { key: "NM_005920.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4209", geneDisplay: "MEF2D" },
  { key: "NM_005921.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4214", geneDisplay: "MAP3K1" },
  { key: "NM_005922.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4216", geneDisplay: "MAP3K4" },
  { key: "NM_005923.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4217", geneDisplay: "MAP3K5" },
  { key: "NM_005924.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4223", geneDisplay: "MEOX2" },
  { key: "NM_005925.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4225", geneDisplay: "MEP1B" },
  { key: "NM_005926.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4236", geneDisplay: "MFAP1" },
  { key: "NM_005927.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4238", geneDisplay: "MFAP3" },
  { key: "NM_005928.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4240", geneDisplay: "MFGE8" },
  { key: "NM_005929.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4241", geneDisplay: "MELTF" },
  { key: "NM_005931.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4277", geneDisplay: "MICB" },
  { key: "NM_005932.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "4285", geneDisplay: "MIPEP" },
  { key: "NM_005934.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4298", geneDisplay: "MLLT1" },
  { key: "NM_005937.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4302", geneDisplay: "MLLT6" },
  { key: "NM_005938.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4303", geneDisplay: "FOXO4" },
  { key: "NM_005940.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4320", geneDisplay: "MMP11" },
  { key: "NM_005941.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4325", geneDisplay: "MMP16" },
  { key: "NM_005944.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4345", geneDisplay: "CD200" },
  { key: "NM_005946.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4489", geneDisplay: "MT1A" },
  { key: "NM_005947.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4490", geneDisplay: "MT1B" },
  { key: "NM_005949.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4494", geneDisplay: "MT1F" },
  { key: "NM_005951.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4496", geneDisplay: "MT1H" },
  { key: "NM_005952.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4501", geneDisplay: "MT1X" },
  { key: "NM_005953.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4502", geneDisplay: "MT2A" },
  { key: "NM_005954.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4504", geneDisplay: "MT3" },
  { key: "NM_005955.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4520", geneDisplay: "MTF1" },
  { key: "NM_005956.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4522", geneDisplay: "MTHFD1" },
  { key: "NM_005957.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4524", geneDisplay: "MTHFR" },
  { key: "NM_005958.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4543", geneDisplay: "MTNR1A" },
  { key: "NM_005959.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4544", geneDisplay: "MTNR1B" },
  { key: "NM_005960.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4584", geneDisplay: "MUC3A" },
  { key: "NM_005961.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4588", geneDisplay: "MUC6" },
  { key: "NM_005963.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4619", geneDisplay: "MYH1" },
  { key: "NM_005966.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4664", geneDisplay: "NAB1" },
  { key: "NM_005967.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4665", geneDisplay: "NAB2" },
  { key: "NM_005968.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4670", geneDisplay: "HNRNPM" },
  { key: "NM_005969.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4676", geneDisplay: "NAP1L4" },
  { key: "NM_005971.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5349", geneDisplay: "FXYD3" },
  { key: "NM_005972.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5540", geneDisplay: "NPY4R" },
  { key: "NM_005973.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5546", geneDisplay: "PRCC" },
  { key: "NM_005975.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5753", geneDisplay: "PTK6" },
  { key: "NM_005977.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6049", geneDisplay: "RNF6" },
  { key: "NM_005978.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6273", geneDisplay: "S100A2" },
  { key: "NM_005980.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6286", geneDisplay: "S100P" },
  { key: "NM_005981.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6302", geneDisplay: "TSPAN31" },
  { key: "NM_005982.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6495", geneDisplay: "SIX1" },
  { key: "NM_005983.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6502", geneDisplay: "SKP2" },
  { key: "NM_005984.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6576", geneDisplay: "SLC25A1" },
  { key: "NM_005985.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6615", geneDisplay: "SNAI1" },
  { key: "NM_005986.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "6656", geneDisplay: "SOX1" },
  { key: "NM_005987.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6698", geneDisplay: "SPRR1A" },
  { key: "NM_005988.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6700", geneDisplay: "SPRR2A" },
  { key: "NM_005989.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6718", geneDisplay: "AKR1D1" },
  { key: "NM_005990.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6793", geneDisplay: "STK10" },
  { key: "NM_005993.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6904", geneDisplay: "TBCD" },
  { key: "NM_005994.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6909", geneDisplay: "TBX2" },
  { key: "NM_005995.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "347853", geneDisplay: "TBX10" },
  { key: "NM_005996.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6926", geneDisplay: "TBX3" },
  { key: "NM_005997.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6944", geneDisplay: "VPS72" },
  { key: "NM_005998.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7203", geneDisplay: "CCT3" },
  { key: "NM_005999.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7257", geneDisplay: "TSNAX" },
  { key: "NM_006000.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7277", geneDisplay: "TUBA4A" },
  { key: "NM_006001.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7278", geneDisplay: "TUBA3C" },
  { key: "NM_006002.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7347", geneDisplay: "UCHL3" },
  { key: "NM_006003.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7386", geneDisplay: "UQCRFS1" },
  { key: "NM_006004.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7388", geneDisplay: "UQCRH" },
  { key: "NM_006005.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7466", geneDisplay: "WFS1" },
  { key: "NM_006006.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7704", geneDisplay: "ZBTB16" },
  { key: "NM_006009.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7846", geneDisplay: "TUBA1A" },
  { key: "NM_006010.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7873", geneDisplay: "MANF" },
  { key: "NM_006011.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8128", geneDisplay: "ST8SIA2" },
  { key: "NM_006012.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8192", geneDisplay: "CLPP" },
  { key: "NM_006013.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6134", geneDisplay: "RPL10" },
  { key: "NM_006014.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8270", geneDisplay: "LAGE3" },
  { key: "NM_006015.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8289", geneDisplay: "ARID1A" },
  { key: "NM_006016.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8763", geneDisplay: "CD164" },
  { key: "NM_006017.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8842", geneDisplay: "PROM1" },
  { key: "NM_006018.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8843", geneDisplay: "HCAR3" },
  { key: "NM_006019.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10312", geneDisplay: "TCIRG1" },
  { key: "NM_006020.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8846", geneDisplay: "ALKBH1" },
  { key: "NM_006023.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8872", geneDisplay: "CDC123" },
  { key: "NM_006024.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8887", geneDisplay: "TAX1BP1" },
  { key: "NM_006026.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8971", geneDisplay: "H1-10" },
  { key: "NM_006028.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9177", geneDisplay: "HTR3B" },
  { key: "NM_006029.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9240", geneDisplay: "PNMA1" },
  { key: "NM_006030.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9254", geneDisplay: "CACNA2D2" },
  { key: "NM_006031.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5116", geneDisplay: "PCNT" },
  { key: "NM_006032.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9362", geneDisplay: "CPNE6" },
  { key: "NM_006033.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9388", geneDisplay: "LIPG" },
  { key: "NM_006034.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9537", geneDisplay: "TP53I11" },
  { key: "NM_006035.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9578", geneDisplay: "CDC42BPB" },
  { key: "NM_006038.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9825", geneDisplay: "SPATA2" },
  { key: "NM_006039.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9902", geneDisplay: "MRC2" },
  { key: "NM_006040.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9951", geneDisplay: "HS3ST4" },
  { key: "NM_006041.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9953", geneDisplay: "HS3ST3B1" },
  { key: "NM_006042.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9955", geneDisplay: "HS3ST3A1" },
  { key: "NM_006043.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9956", geneDisplay: "HS3ST2" },
  { key: "NM_006044.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10013", geneDisplay: "HDAC6" },
  { key: "NM_006045.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10079", geneDisplay: "ATP9A" },
  { key: "NM_006047.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10137", geneDisplay: "RBM12" },
  { key: "NM_006052.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10311", geneDisplay: "VPS26C" },
  { key: "NM_006055.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10314", geneDisplay: "LANCL1" },
  { key: "NM_006056.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10316", geneDisplay: "NMUR1" },
  { key: "NM_006058.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10318", geneDisplay: "TNIP1" },
  { key: "NM_006059.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10319", geneDisplay: "LAMC3" },
  { key: "NM_006060.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10320", geneDisplay: "IKZF1" },
  { key: "NM_006061.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10321", geneDisplay: "CRISP3" },
  { key: "NM_006062.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10322", geneDisplay: "SMYD5" },
  { key: "NM_006063.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10324", geneDisplay: "KLHL41" },
  { key: "NM_006064.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10325", geneDisplay: "RRAGB" },
  { key: "NM_006065.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10326", geneDisplay: "SIRPB1" },
  { key: "NM_006067.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10328", geneDisplay: "EMC8" },
  { key: "NM_006068.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10333", geneDisplay: "TLR6" },
  { key: "NM_006070.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10342", geneDisplay: "TFG" },
  { key: "NM_006071.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10343", geneDisplay: "PKDREJ" },
  { key: "NM_006073.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10345", geneDisplay: "TRDN" },
  { key: "NM_006074.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10346", geneDisplay: "TRIM22" },
  { key: "NM_006076.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3268", geneDisplay: "AGFG2" },
  { key: "NM_006078.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10369", geneDisplay: "CACNG2" },
  { key: "NM_006079.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10370", geneDisplay: "CITED2" },
  { key: "NM_006080.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10371", geneDisplay: "SEMA3A" },
  { key: "NM_006082.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10376", geneDisplay: "TUBA1B" },
  { key: "NM_006083.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3550", geneDisplay: "IK" },
  { key: "NM_006084.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10379", geneDisplay: "IRF9" },
  { key: "NM_006085.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10380", geneDisplay: "BPNT1" },
  { key: "NM_006086.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10381", geneDisplay: "TUBB3" },
  { key: "NM_006087.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10382", geneDisplay: "TUBB4A" },
  { key: "NM_006088.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10383", geneDisplay: "TUBB4B" },
  { key: "NM_006089.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10389", geneDisplay: "SCML2" },
  { key: "NM_006090.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10390", geneDisplay: "CEPT1" },
  { key: "NM_006091.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10391", geneDisplay: "CORO2B" },
  { key: "NM_006092.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10392", geneDisplay: "NOD1" },
  { key: "NM_006093.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10394", geneDisplay: "PRG3" },
  { key: "NM_006095.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10396", geneDisplay: "ATP8A1" },
  { key: "NM_006096.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10397", geneDisplay: "NDRG1" },
  { key: "NM_006097.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10398", geneDisplay: "MYL9" },
  { key: "NM_006098.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10399", geneDisplay: "RACK1" },
  { key: "NM_006099.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10401", geneDisplay: "PIAS3" },
  { key: "NM_006101.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10403", geneDisplay: "NDC80" },
  { key: "NM_006103.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10406", geneDisplay: "WFDC2" },
  { key: "NM_006108.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10418", geneDisplay: "SPON1" },
  { key: "NM_006109.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10419", geneDisplay: "PRMT5" },
  { key: "NM_006110.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10421", geneDisplay: "CD2BP2" },
  { key: "NM_006111.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10449", geneDisplay: "ACAA2" },
  { key: "NM_006112.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10450", geneDisplay: "PPIE" },
  { key: "NM_006113.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10451", geneDisplay: "VAV3" },
  { key: "NM_006116.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10454", geneDisplay: "TAB1" },
  { key: "NM_006118.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10456", geneDisplay: "HAX1" },
  { key: "NM_006120.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3108", geneDisplay: "HLA-DMA" },
  { key: "NM_006121.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3848", geneDisplay: "KRT1" },
  { key: "NM_006122.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4122", geneDisplay: "MAN2A2" },
  { key: "NM_006129.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "649", geneDisplay: "BMP1" },
  { key: "NM_006133.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "747", geneDisplay: "DAGLA" },
  { key: "NM_006134.7", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "757", geneDisplay: "TMEM50B" },
  { key: "NM_006135.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "829", geneDisplay: "CAPZA1" },
  { key: "NM_006136.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "830", geneDisplay: "CAPZA2" },
  { key: "NM_006137.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "924", geneDisplay: "CD7" },
  { key: "NM_006138.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "932", geneDisplay: "MS4A3" },
  { key: "NM_006139.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "940", geneDisplay: "CD28" },
  { key: "NM_006141.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1783", geneDisplay: "DYNC1LI2" },
  { key: "NM_006142.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2810", geneDisplay: "SFN" },
  { key: "NM_006143.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2842", geneDisplay: "GPR19" },
  { key: "NM_006144.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3001", geneDisplay: "GZMA" },
  { key: "NM_006145.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3337", geneDisplay: "DNAJB1" },
  { key: "NM_006147.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3664", geneDisplay: "IRF6" },
  { key: "NM_006148.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3927", geneDisplay: "LASP1" },
  { key: "NM_006149.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3960", geneDisplay: "LGALS4" },
  { key: "NM_006150.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4007", geneDisplay: "PRICKLE3" },
  { key: "NM_006151.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4025", geneDisplay: "LPO" },
  { key: "NM_006154.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4734", geneDisplay: "NEDD4" },
  { key: "NM_006156.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4738", geneDisplay: "NEDD8" },
  { key: "NM_006157.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4745", geneDisplay: "NELL1" },
  { key: "NM_006158.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4747", geneDisplay: "NEFL" },
  { key: "NM_006160.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4761", geneDisplay: "NEUROD2" },
  { key: "NM_006161.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4762", geneDisplay: "NEUROG1" },
  { key: "NM_006164.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4780", geneDisplay: "NFE2L2" },
  { key: "NM_006166.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4801", geneDisplay: "NFYB" },
  { key: "NM_006167.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4824", geneDisplay: "NKX3-1" },
  { key: "NM_006168.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4825", geneDisplay: "NKX6-1" },
  { key: "NM_006169.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4837", geneDisplay: "NNMT" },
  { key: "NM_006172.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4878", geneDisplay: "NPPA" },
  { key: "NM_006174.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4889", geneDisplay: "NPY5R" },
  { key: "NM_006176.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4900", geneDisplay: "NRGN" },
  { key: "NM_006178.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4905", geneDisplay: "NSF" },
  { key: "NM_006179.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4909", geneDisplay: "NTF4" },
  { key: "NM_006180.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4915", geneDisplay: "NTRK2" },
  { key: "NM_006181.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4917", geneDisplay: "NTN3" },
  { key: "NM_006182.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4921", geneDisplay: "DDR2" },
  { key: "NM_006183.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4922", geneDisplay: "NTS" },
  { key: "NM_006184.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4924", geneDisplay: "NUCB1" },
  { key: "NM_006185.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4926", geneDisplay: "NUMA1" },
  { key: "NM_006186.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4929", geneDisplay: "NR4A2" },
  { key: "NM_006187.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4940", geneDisplay: "OAS3" },
  { key: "NM_006188.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4951", geneDisplay: "OCM2" },
  { key: "NM_006189.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4975", geneDisplay: "OMP" },
  { key: "NM_006190.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4999", geneDisplay: "ORC2" },
  { key: "NM_006191.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5036", geneDisplay: "PA2G4" },
  { key: "NM_006195.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5090", geneDisplay: "PBX3" },
  { key: "NM_006196.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5093", geneDisplay: "PCBP1" },
  { key: "NM_006197.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5108", geneDisplay: "PCM1" },
  { key: "NM_006198.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "5121", geneDisplay: "PCP4" },
  { key: "NM_006201.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5127", geneDisplay: "CDK16" },
  { key: "NM_006204.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5146", geneDisplay: "PDE6C" },
  { key: "NM_006205.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5149", geneDisplay: "PDE6H" },
  { key: "NM_006206.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5156", geneDisplay: "PDGFRA" },
  { key: "NM_006208.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5167", geneDisplay: "ENPP1" },
  { key: "NM_006210.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5178", geneDisplay: "PEG3" },
  { key: "NM_006212.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5208", geneDisplay: "PFKFB2" },
  { key: "NM_006213.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5260", geneDisplay: "PHKG1" },
  { key: "NM_006214.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5264", geneDisplay: "PHYH" },
  { key: "NM_006215.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5267", geneDisplay: "SERPINA4" },
  { key: "NM_006217.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5276", geneDisplay: "SERPINI2" },
  { key: "NM_006218.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5290", geneDisplay: "PIK3CA" },
  { key: "NM_006219.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5291", geneDisplay: "PIK3CB" },
  { key: "NM_006221.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5300", geneDisplay: "PIN1" },
  { key: "NM_006223.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5303", geneDisplay: "PIN4" },
  { key: "NM_006224.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5306", geneDisplay: "PITPNA" },
  { key: "NM_006225.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5333", geneDisplay: "PLCD1" },
  { key: "NM_006226.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5334", geneDisplay: "PLCL1" },
  { key: "NM_006227.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5360", geneDisplay: "PLTP" },
  { key: "NM_006228.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5368", geneDisplay: "PNOC" },
  { key: "NM_006229.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5407", geneDisplay: "PNLIPRP1" },
  { key: "NM_006230.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5425", geneDisplay: "POLD2" },
  { key: "NM_006231.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5426", geneDisplay: "POLE" },
  { key: "NM_006232.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5437", geneDisplay: "POLR2H" },
  { key: "NM_006233.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5438", geneDisplay: "POLR2I" },
  { key: "NM_006234.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5439", geneDisplay: "POLR2J" },
  { key: "NM_006235.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5450", geneDisplay: "POU2AF1" },
  { key: "NM_006236.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5455", geneDisplay: "POU3F3" },
  { key: "NM_006237.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5457", geneDisplay: "POU4F1" },
  { key: "NM_006238.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5467", geneDisplay: "PPARD" },
  { key: "NM_006239.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5470", geneDisplay: "PPEF2" },
  { key: "NM_006241.8", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5504", geneDisplay: "PPP1R2" },
  { key: "NM_006242.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5509", geneDisplay: "PPP1R3D" },
  { key: "NM_006243.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5525", geneDisplay: "PPP2R5A" },
  { key: "NM_006244.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5526", geneDisplay: "PPP2R5B" },
  { key: "NM_006245.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5528", geneDisplay: "PPP2R5D" },
  { key: "NM_006246.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5529", geneDisplay: "PPP2R5E" },
  { key: "NM_006247.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5536", geneDisplay: "PPP5C" },
  { key: "NM_006248.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "653247", geneDisplay: "PRB2" },
  { key: "NM_006251.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5562", geneDisplay: "PRKAA1" },
  { key: "NM_006252.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5563", geneDisplay: "PRKAA2" },
  { key: "NM_006253.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5564", geneDisplay: "PRKAB1" },
  { key: "NM_006254.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5580", geneDisplay: "PRKCD" },
  { key: "NM_006255.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5583", geneDisplay: "PRKCH" },
  { key: "NM_006256.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5586", geneDisplay: "PKN2" },
  { key: "NM_006257.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5588", geneDisplay: "PRKCQ" },
  { key: "NM_006258.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5592", geneDisplay: "PRKG1" },
  { key: "NM_006259.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5593", geneDisplay: "PRKG2" },
  { key: "NM_006260.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5611", geneDisplay: "DNAJC3" },
  { key: "NM_006261.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5626", geneDisplay: "PROP1" },
  { key: "NM_006262.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5630", geneDisplay: "PRPH" },
  { key: "NM_006263.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5720", geneDisplay: "PSME1" },
  { key: "NM_006265.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5885", geneDisplay: "RAD21" },
  { key: "NM_006266.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5900", geneDisplay: "RALGDS" },
  { key: "NM_006267.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5903", geneDisplay: "RANBP2" },
  { key: "NM_006268.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5977", geneDisplay: "DPF2" },
  { key: "NM_006269.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6101", geneDisplay: "RP1" },
  { key: "NM_006270.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6237", geneDisplay: "RRAS" },
  { key: "NM_006271.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6271", geneDisplay: "S100A1" },
  { key: "NM_006272.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6285", geneDisplay: "S100B" },
  { key: "NM_006273.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6354", geneDisplay: "CCL7" },
  { key: "NM_006274.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6363", geneDisplay: "CCL19" },
  { key: "NM_006275.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6431", geneDisplay: "SRSF6" },
  { key: "NM_006277.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50618", geneDisplay: "ITSN2" },
  { key: "NM_006279.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6487", geneDisplay: "ST3GAL3" },
  { key: "NM_006280.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6748", geneDisplay: "SSR4" },
  { key: "NM_006281.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6788", geneDisplay: "STK3" },
  { key: "NM_006282.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6789", geneDisplay: "STK4" },
  { key: "NM_006283.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6867", geneDisplay: "TACC1" },
  { key: "NM_006284.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6881", geneDisplay: "TAF10" },
  { key: "NM_006285.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7016", geneDisplay: "TESK1" },
  { key: "NM_006287.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7035", geneDisplay: "TFPI" },
  { key: "NM_006288.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7070", geneDisplay: "THY1" },
  { key: "NM_006289.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7094", geneDisplay: "TLN1" },
  { key: "NM_006291.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7127", geneDisplay: "TNFAIP2" },
  { key: "NM_006292.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7251", geneDisplay: "TSG101" },
  { key: "NM_006293.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7301", geneDisplay: "TYRO3" },
  { key: "NM_006294.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7381", geneDisplay: "UQCRB" },
  { key: "NM_006295.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7407", geneDisplay: "VARS1" },
  { key: "NM_006296.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7444", geneDisplay: "VRK2" },
  { key: "NM_006297.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7515", geneDisplay: "XRCC1" },
  { key: "NM_006298.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7745", geneDisplay: "ZKSCAN8" },
  { key: "NM_006299.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7746", geneDisplay: "ZSCAN9" },
  { key: "NM_006300.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7773", geneDisplay: "ZNF230" },
  { key: "NM_006302.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7841", geneDisplay: "MOGS" },
  { key: "NM_006303.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7965", geneDisplay: "AIMP2" },
  { key: "NM_006304.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7979", geneDisplay: "SEM1" },
  { key: "NM_006305.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8125", geneDisplay: "ANP32A" },
  { key: "NM_006306.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8243", geneDisplay: "SMC1A" },
  { key: "NM_006307.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8406", geneDisplay: "SRPX" },
  { key: "NM_006308.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8988", geneDisplay: "HSPB3" },
  { key: "NM_006309.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9209", geneDisplay: "LRRFIP2" },
  { key: "NM_006310.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9520", geneDisplay: "NPEPPS" },
  { key: "NM_006311.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9611", geneDisplay: "NCOR1" },
  { key: "NM_006312.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9612", geneDisplay: "NCOR2" },
  { key: "NM_006314.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10256", geneDisplay: "CNKSR1" },
  { key: "NM_006315.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10336", geneDisplay: "PCGF3" },
  { key: "NM_006317.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10409", geneDisplay: "BASP1" },
  { key: "NM_006319.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10423", geneDisplay: "CDIPT" },
  { key: "NM_006320.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10424", geneDisplay: "PGRMC2" },
  { key: "NM_006321.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10425", geneDisplay: "ARIH2" },
  { key: "NM_006322.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10426", geneDisplay: "TUBGCP3" },
  { key: "NM_006323.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10427", geneDisplay: "SEC24B" },
  { key: "NM_006324.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10428", geneDisplay: "CFDP1" },
  { key: "NM_006325.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5901", geneDisplay: "RAN" },
  { key: "NM_006327.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100287932", geneDisplay: "TIMM23" },
  { key: "NM_006328.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10432", geneDisplay: "RBM14" },
  { key: "NM_006329.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10516", geneDisplay: "FBLN5" },
  { key: "NM_006330.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10434", geneDisplay: "LYPLA1" },
  { key: "NM_006331.8", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10436", geneDisplay: "EMG1" },
  { key: "NM_006332.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10437", geneDisplay: "IFI30" },
  { key: "NM_006335.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10440", geneDisplay: "TIMM17A" },
  { key: "NM_006336.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10444", geneDisplay: "ZER1" },
  { key: "NM_006337.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10445", geneDisplay: "MCRS1" },
  { key: "NM_006339.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10362", geneDisplay: "HMG20B" },
  { key: "NM_006341.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10459", geneDisplay: "MAD2L2" },
  { key: "NM_006342.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10460", geneDisplay: "TACC3" },
  { key: "NM_006343.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10461", geneDisplay: "MERTK" },
  { key: "NM_006345.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10463", geneDisplay: "SLC30A9" },
  { key: "NM_006346.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10464", geneDisplay: "PIBF1" },
  { key: "NM_006347.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10465", geneDisplay: "PPIH" },
  { key: "NM_006348.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10466", geneDisplay: "COG5" },
  { key: "NM_006349.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10467", geneDisplay: "ZNHIT1" },
  { key: "NM_006351.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10469", geneDisplay: "TIMM44" },
  { key: "NM_006353.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10473", geneDisplay: "HMGN4" },
  { key: "NM_006354.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10474", geneDisplay: "TADA3" },
  { key: "NM_006355.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10475", geneDisplay: "TRIM38" },
  { key: "NM_006356.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10476", geneDisplay: "ATP5PD" },
  { key: "NM_006357.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10477", geneDisplay: "UBE2E3" },
  { key: "NM_006358.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10478", geneDisplay: "SLC25A17" },
  { key: "NM_006360.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10480", geneDisplay: "EIF3M" },
  { key: "NM_006361.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10481", geneDisplay: "HOXB13" },
  { key: "NM_006362.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10482", geneDisplay: "NXF1" },
  { key: "NM_006363.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10483", geneDisplay: "SEC23B" },
  { key: "NM_006364.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10484", geneDisplay: "SEC23A" },
  { key: "NM_006366.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10486", geneDisplay: "CAP2" },
  { key: "NM_006367.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10487", geneDisplay: "CAP1" },
  { key: "NM_006368.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10488", geneDisplay: "CREB3" },
  { key: "NM_006369.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10489", geneDisplay: "LRRC41" },
  { key: "NM_006370.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10490", geneDisplay: "VTI1B" },
  { key: "NM_006371.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10491", geneDisplay: "CRTAP" },
  { key: "NM_006372.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10492", geneDisplay: "SYNCRIP" },
  { key: "NM_006373.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10493", geneDisplay: "VAT1" },
  { key: "NM_006375.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10495", geneDisplay: "ENOX2" },
  { key: "NM_006379.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10512", geneDisplay: "SEMA3C" },
  { key: "NM_006380.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10513", geneDisplay: "APPBP2" },
  { key: "NM_006382.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "374286", geneDisplay: "FBXW10B" },
  { key: "NM_006383.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10518", geneDisplay: "CIB2" },
  { key: "NM_006384.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10519", geneDisplay: "CIB1" },
  { key: "NM_006385.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10520", geneDisplay: "ZNF211" },
  { key: "NM_006386.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10521", geneDisplay: "DDX17" },
  { key: "NM_006387.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10523", geneDisplay: "CHERP" },
  { key: "NM_006389.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10525", geneDisplay: "HYOU1" },
  { key: "NM_006390.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10526", geneDisplay: "IPO8" },
  { key: "NM_006391.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10527", geneDisplay: "IPO7" },
  { key: "NM_006392.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10528", geneDisplay: "NOP56" },
  { key: "NM_006393.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10529", geneDisplay: "NEBL" },
  { key: "NM_006396.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10534", geneDisplay: "ZNRD2" },
  { key: "NM_006397.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10535", geneDisplay: "RNASEH2A" },
  { key: "NM_006398.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10537", geneDisplay: "UBD" },
  { key: "NM_006399.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10538", geneDisplay: "BATF" },
  { key: "NM_006401.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10541", geneDisplay: "ANP32B" },
  { key: "NM_006403.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4739", geneDisplay: "NEDD9" },
  { key: "NM_006404.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10544", geneDisplay: "PROCR" },
  { key: "NM_006405.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10548", geneDisplay: "TM9SF1" },
  { key: "NM_006406.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10549", geneDisplay: "PRDX4" },
  { key: "NM_006407.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10550", geneDisplay: "ARL6IP5" },
  { key: "NM_006408.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10551", geneDisplay: "AGR2" },
  { key: "NM_006409.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10552", geneDisplay: "ARPC1A" },
  { key: "NM_006411.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10554", geneDisplay: "AGPAT1" },
  { key: "NM_006412.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10555", geneDisplay: "AGPAT2" },
  { key: "NM_006413.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10556", geneDisplay: "RPP30" },
  { key: "NM_006415.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10558", geneDisplay: "SPTLC1" },
  { key: "NM_006416.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10559", geneDisplay: "SLC35A1" },
  { key: "NM_006417.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10561", geneDisplay: "IFI44" },
  { key: "NM_006418.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10562", geneDisplay: "OLFM4" },
  { key: "NM_006420.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10564", geneDisplay: "ARFGEF2" },
  { key: "NM_006421.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10565", geneDisplay: "ARFGEF1" },
  { key: "NM_006423.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10567", geneDisplay: "RABAC1" },
  { key: "NM_006424.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10568", geneDisplay: "SLC34A2" },
  { key: "NM_006425.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10569", geneDisplay: "SLU7" },
  { key: "NM_006426.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10570", geneDisplay: "DPYSL4" },
  { key: "NM_006427.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10572", geneDisplay: "SIVA1" },
  { key: "NM_006428.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10573", geneDisplay: "MRPL28" },
  { key: "NM_006429.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10574", geneDisplay: "CCT7" },
  { key: "NM_006430.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10575", geneDisplay: "CCT4" },
  { key: "NM_006431.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10576", geneDisplay: "CCT2" },
  { key: "NM_006432.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10577", geneDisplay: "NPC2" },
  { key: "NM_006433.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10578", geneDisplay: "GNLY" },
  { key: "NM_006435.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10581", geneDisplay: "IFITM2" },
  { key: "NM_006437.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "143", geneDisplay: "PARP4" },
  { key: "NM_006438.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10584", geneDisplay: "COLEC10" },
  { key: "NM_006439.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10586", geneDisplay: "MAB21L2" },
  { key: "NM_006440.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10587", geneDisplay: "TXNRD2" },
  { key: "NM_006441.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10588", geneDisplay: "MTHFS" },
  { key: "NM_006442.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10589", geneDisplay: "DRAP1" },
  { key: "NM_006443.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10591", geneDisplay: "DNPH1" },
  { key: "NM_006444.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10592", geneDisplay: "SMC2" },
  { key: "NM_006445.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10594", geneDisplay: "PRPF8" },
  { key: "NM_006446.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10599", geneDisplay: "SLCO1B1" },
  { key: "NM_006447.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10600", geneDisplay: "USP16" },
  { key: "NM_006449.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10602", geneDisplay: "CDC42EP3" },
  { key: "NM_006451.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10605", geneDisplay: "PAIP1" },
  { key: "NM_006453.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10607", geneDisplay: "TBL3" },
  { key: "NM_006454.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10608", geneDisplay: "MXD4" },
  { key: "NM_006455.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10609", geneDisplay: "P3H4" },
  { key: "NM_006456.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10610", geneDisplay: "ST6GALNAC2" },
  { key: "NM_006457.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10611", geneDisplay: "PDLIM5" },
  { key: "NM_006459.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10613", geneDisplay: "ERLIN1" },
  { key: "NM_006460.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10614", geneDisplay: "HEXIM1" },
  { key: "NM_006461.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10615", geneDisplay: "SPAG5" },
  { key: "NM_006464.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10618", geneDisplay: "TGOLN2" },
  { key: "NM_006465.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10620", geneDisplay: "ARID3B" },
  { key: "NM_006466.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10621", geneDisplay: "POLR3F" },
  { key: "NM_006467.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10622", geneDisplay: "POLR3G" },
  { key: "NM_006468.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10623", geneDisplay: "POLR3C" },
  { key: "NM_006469.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10625", geneDisplay: "IVNS1ABP" },
  { key: "NM_006471.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10627", geneDisplay: "MYL12A" },
  { key: "NM_006472.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10628", geneDisplay: "TXNIP" },
  { key: "NM_006473.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10629", geneDisplay: "TAF6L" },
  { key: "NM_006474.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10630", geneDisplay: "PDPN" },
  { key: "NM_006475.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10631", geneDisplay: "POSTN" },
  { key: "NM_006476.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10632", geneDisplay: "ATP5MG" },
  { key: "NM_006477.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10633", geneDisplay: "RASL10A" },
  { key: "NM_006479.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10635", geneDisplay: "RAD51AP1" },
  { key: "NM_006480.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10636", geneDisplay: "RGS14" },
  { key: "NM_006482.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8445", geneDisplay: "DYRK2" },
  { key: "NM_006486.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2192", geneDisplay: "FBLN1" },
  { key: "NM_006488.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3795", geneDisplay: "KHK" },
  { key: "NM_006492.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257", geneDisplay: "ALX3" },
  { key: "NM_006493.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1203", geneDisplay: "CLN5" },
  { key: "NM_006494.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2077", geneDisplay: "ERF" },
  { key: "NM_006495.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2124", geneDisplay: "EVI2B" },
  { key: "NM_006496.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2773", geneDisplay: "GNAI3" },
  { key: "NM_006497.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3090", geneDisplay: "HIC1" },
  { key: "NM_006498.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3957", geneDisplay: "LGALS2" },
  { key: "NM_006500.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4162", geneDisplay: "MCAM" },
  { key: "NM_006502.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5429", geneDisplay: "POLH" },
  { key: "NM_006503.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5704", geneDisplay: "PSMC4" },
  { key: "NM_006504.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5791", geneDisplay: "PTPRE" },
  { key: "NM_006505.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5817", geneDisplay: "PVR" },
  { key: "NM_006506.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5922", geneDisplay: "RASA2" },
  { key: "NM_006507.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5968", geneDisplay: "REG1B" },
  { key: "NM_006509.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5971", geneDisplay: "RELB" },
  { key: "NM_006510.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5987", geneDisplay: "TRIM27" },
  { key: "NM_006511.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6248", geneDisplay: "RSC1A1" },
  { key: "NM_006512.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6291", geneDisplay: "SAA4" },
  { key: "NM_006513.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6301", geneDisplay: "SARS1" },
  { key: "NM_006514.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6336", geneDisplay: "SCN10A" },
  { key: "NM_006515.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6419", geneDisplay: "SETMAR" },
  { key: "NM_006516.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6513", geneDisplay: "SLC2A1" },
  { key: "NM_006517.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6567", geneDisplay: "SLC16A2" },
  { key: "NM_006519.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6993", geneDisplay: "DYNLT1" },
  { key: "NM_006520.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6990", geneDisplay: "DYNLT3" },
  { key: "NM_006521.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7030", geneDisplay: "TFE3" },
  { key: "NM_006522.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7475", geneDisplay: "WNT6" },
  { key: "NM_006526.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7764", geneDisplay: "ZNF217" },
  { key: "NM_006527.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7884", geneDisplay: "SLBP" },
  { key: "NM_006528.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7980", geneDisplay: "TFPI2" },
  { key: "NM_006529.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8001", geneDisplay: "GLRA3" },
  { key: "NM_006530.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8089", geneDisplay: "YEATS4" },
  { key: "NM_006531.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8100", geneDisplay: "IFT88" },
  { key: "NM_006532.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8178", geneDisplay: "ELL" },
  { key: "NM_006533.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8190", geneDisplay: "MIA" },
  { key: "NM_006536.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9635", geneDisplay: "CLCA2" },
  { key: "NM_006537.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9960", geneDisplay: "USP3" },
  { key: "NM_006539.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10368", geneDisplay: "CACNG3" },
  { key: "NM_006540.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10499", geneDisplay: "NCOA2" },
  { key: "NM_006541.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10539", geneDisplay: "GLRX3" },
  { key: "NM_006544.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10640", geneDisplay: "EXOC5" },
  { key: "NM_006545.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10641", geneDisplay: "NPRL2" },
  { key: "NM_006546.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10642", geneDisplay: "IGF2BP1" },
  { key: "NM_006547.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10643", geneDisplay: "IGF2BP3" },
  { key: "NM_006548.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10644", geneDisplay: "IGF2BP2" },
  { key: "NM_006551.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10647", geneDisplay: "SCGB1D2" },
  { key: "NM_006552.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10648", geneDisplay: "SCGB1D1" },
  { key: "NM_006554.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10651", geneDisplay: "MTX2" },
  { key: "NM_006555.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10652", geneDisplay: "YKT6" },
  { key: "NM_006556.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10654", geneDisplay: "PMVK" },
  { key: "NM_006558.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10656", geneDisplay: "KHDRBS3" },
  { key: "NM_006559.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10657", geneDisplay: "KHDRBS1" },
  { key: "NM_006562.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10660", geneDisplay: "LBX1" },
  { key: "NM_006563.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10661", geneDisplay: "KLF1" },
  { key: "NM_006564.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10663", geneDisplay: "CXCR6" },
  { key: "NM_006565.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10664", geneDisplay: "CTCF" },
  { key: "NM_006567.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10667", geneDisplay: "FARS2" },
  { key: "NM_006568.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10668", geneDisplay: "CGRRF1" },
  { key: "NM_006569.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10669", geneDisplay: "CGREF1" },
  { key: "NM_006570.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10670", geneDisplay: "RRAGA" },
  { key: "NM_006571.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10671", geneDisplay: "DCTN6" },
  { key: "NM_006572.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10672", geneDisplay: "GNA13" },
  { key: "NM_006573.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10673", geneDisplay: "TNFSF13B" },
  { key: "NM_006574.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10675", geneDisplay: "CSPG5" },
  { key: "NM_006575.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11183", geneDisplay: "MAP4K5" },
  { key: "NM_006576.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10677", geneDisplay: "AVIL" },
  { key: "NM_006577.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10678", geneDisplay: "B3GNT2" },
  { key: "NM_006579.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10682", geneDisplay: "EBP" },
  { key: "NM_006580.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10686", geneDisplay: "CLDN16" },
  { key: "NM_006581.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10690", geneDisplay: "FUT9" },
  { key: "NM_006583.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10692", geneDisplay: "RRH" },
  { key: "NM_006584.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10693", geneDisplay: "CCT6B" },
  { key: "NM_006585.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10694", geneDisplay: "CCT8" },
  { key: "NM_006586.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10695", geneDisplay: "CNPY3" },
  { key: "NM_006587.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10699", geneDisplay: "CORIN" },
  { key: "NM_006588.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27233", geneDisplay: "SULT1C4" },
  { key: "NM_006589.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10712", geneDisplay: "FAM189B" },
  { key: "NM_006590.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10713", geneDisplay: "USP39" },
  { key: "NM_006591.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10714", geneDisplay: "POLD3" },
  { key: "NM_006593.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10716", geneDisplay: "TBR1" },
  { key: "NM_006597.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3312", geneDisplay: "HSPA8" },
  { key: "NM_006598.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10723", geneDisplay: "SLC12A7" },
  { key: "NM_006600.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10726", geneDisplay: "NUDC" },
  { key: "NM_006601.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10728", geneDisplay: "PTGES3" },
  { key: "NM_006602.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10732", geneDisplay: "TCFL5" },
  { key: "NM_006606.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10741", geneDisplay: "RBBP9" },
  { key: "NM_006607.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10744", geneDisplay: "PTTG2" },
  { key: "NM_006610.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10747", geneDisplay: "MASP2" },
  { key: "NM_006612.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10749", geneDisplay: "KIF1C" },
  { key: "NM_006613.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10750", geneDisplay: "GRAP" },
  { key: "NM_006614.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10752", geneDisplay: "CHL1" },
  { key: "NM_006615.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10753", geneDisplay: "CAPN9" },
  { key: "NM_006617.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10763", geneDisplay: "NES" },
  { key: "NM_006618.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10765", geneDisplay: "KDM5B" },
  { key: "NM_006620.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10767", geneDisplay: "HBS1L" },
  { key: "NM_006621.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10768", geneDisplay: "AHCYL1" },
  { key: "NM_006622.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10769", geneDisplay: "PLK2" },
  { key: "NM_006623.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26227", geneDisplay: "PHGDH" },
  { key: "NM_006626.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10773", geneDisplay: "ZBTB6" },
  { key: "NM_006627.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10775", geneDisplay: "POP4" },
  { key: "NM_006628.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10776", geneDisplay: "ARPP19" },
  { key: "NM_006630.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10780", geneDisplay: "ZNF234" },
  { key: "NM_006633.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10788", geneDisplay: "IQGAP2" },
  { key: "NM_006634.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10791", geneDisplay: "VAMP5" },
  { key: "NM_006635.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10794", geneDisplay: "ZNF460" },
  { key: "NM_006636.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10797", geneDisplay: "MTHFD2" },
  { key: "NM_006637.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10798", geneDisplay: "OR5I1" },
  { key: "NM_006638.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10799", geneDisplay: "RPP40" },
  { key: "NM_006639.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10800", geneDisplay: "CYSLTR1" },
  { key: "NM_006642.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10806", geneDisplay: "SDCCAG8" },
  { key: "NM_006644.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10808", geneDisplay: "HSPH1" },
  { key: "NM_006645.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10809", geneDisplay: "STARD10" },
  { key: "NM_006646.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10810", geneDisplay: "WASF3" },
  { key: "NM_006648.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "65268", geneDisplay: "WNK2" },
  { key: "NM_006649.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10813", geneDisplay: "UTP14A" },
  { key: "NM_006651.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10815", geneDisplay: "CPLX1" },
  { key: "NM_006652.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10816", geneDisplay: "SPINT3" },
  { key: "NM_006653.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10817", geneDisplay: "FRS3" },
  { key: "NM_006656.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10825", geneDisplay: "NEU3" },
  { key: "NM_006658.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10842", geneDisplay: "PPP1R17" },
  { key: "NM_006659.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10844", geneDisplay: "TUBGCP2" },
  { key: "NM_006660.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10845", geneDisplay: "CLPX" },
  { key: "NM_006662.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10847", geneDisplay: "SRCAP" },
  { key: "NM_006663.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10848", geneDisplay: "PPP1R13L" },
  { key: "NM_006664.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10850", geneDisplay: "CCL27" },
  { key: "NM_006666.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10856", geneDisplay: "RUVBL2" },
  { key: "NM_006667.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10857", geneDisplay: "PGRMC1" },
  { key: "NM_006668.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10858", geneDisplay: "CYP46A1" },
  { key: "NM_006671.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6512", geneDisplay: "SLC1A7" },
  { key: "NM_006675.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10867", geneDisplay: "TSPAN9" },
  { key: "NM_006678.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10871", geneDisplay: "CD300C" },
  { key: "NM_006681.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10874", geneDisplay: "NMU" },
  { key: "NM_006682.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10875", geneDisplay: "FGL2" },
  { key: "NM_006683.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10876", geneDisplay: "EDDM3A" },
  { key: "NM_006685.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10879", geneDisplay: "SMR3B" },
  { key: "NM_006686.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10880", geneDisplay: "ACTL7B" },
  { key: "NM_006687.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10881", geneDisplay: "ACTL7A" },
  { key: "NM_006688.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10882", geneDisplay: "C1QL1" },
  { key: "NM_006690.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10893", geneDisplay: "MMP24" },
  { key: "NM_006691.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10894", geneDisplay: "LYVE1" },
  { key: "NM_006693.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10898", geneDisplay: "CPSF4" },
  { key: "NM_006694.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10899", geneDisplay: "JTB" },
  { key: "NM_006698.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10904", geneDisplay: "BLCAP" },
  { key: "NM_006699.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10905", geneDisplay: "MAN1A2" },
  { key: "NM_006700.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10906", geneDisplay: "TRAFD1" },
  { key: "NM_006701.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10907", geneDisplay: "TXNL4A" },
  { key: "NM_006703.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11165", geneDisplay: "NUDT3" },
  { key: "NM_006704.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10910", geneDisplay: "SUGT1" },
  { key: "NM_006705.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10912", geneDisplay: "GADD45G" },
  { key: "NM_006708.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2739", geneDisplay: "GLO1" },
  { key: "NM_006709.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10919", geneDisplay: "EHMT2" },
  { key: "NM_006710.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10920", geneDisplay: "COPS8" },
  { key: "NM_006712.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10922", geneDisplay: "FASTK" },
  { key: "NM_006713.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10923", geneDisplay: "SUB1" },
  { key: "NM_006714.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10924", geneDisplay: "SMPDL3A" },
  { key: "NM_006715.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4123", geneDisplay: "MAN2C1" },
  { key: "NM_006716.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10926", geneDisplay: "DBF4" },
  { key: "NM_006717.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10927", geneDisplay: "SPIN1" },
  { key: "NM_006721.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "132", geneDisplay: "ADK" },
  { key: "NM_006725.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "923", geneDisplay: "CD6" },
  { key: "NM_006727.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1008", geneDisplay: "CDH10" },
  { key: "NM_006729.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1730", geneDisplay: "DIAPH2" },
  { key: "NM_006732.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2354", geneDisplay: "FOSB" },
  { key: "NM_006734.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3097", geneDisplay: "HIVEP2" },
  { key: "NM_006735.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3199", geneDisplay: "HOXA2" },
  { key: "NM_006736.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3300", geneDisplay: "DNAJB2" },
  { key: "NM_006737.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3812", geneDisplay: "KIR3DL2" },
  { key: "NM_006739.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4174", geneDisplay: "MCM5" },
  { key: "NM_006741.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5502", geneDisplay: "PPP1R1A" },
  { key: "NM_006742.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5681", geneDisplay: "PSKH1" },
  { key: "NM_006743.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5935", geneDisplay: "RBM3" },
  { key: "NM_006744.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5950", geneDisplay: "RBP4" },
  { key: "NM_006745.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6307", geneDisplay: "MSMO1" },
  { key: "NM_006747.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6494", geneDisplay: "SIPA1" },
  { key: "NM_006750.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6645", geneDisplay: "SNTB2" },
  { key: "NM_006753.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6838", geneDisplay: "SURF6" },
  { key: "NM_006755.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6888", geneDisplay: "TALDO1" },
  { key: "NM_006756.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6917", geneDisplay: "TCEA1" },
  { key: "NM_006757.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7140", geneDisplay: "TNNT3" },
  { key: "NM_006758.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7307", geneDisplay: "U2AF1" },
  { key: "NM_006759.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7360", geneDisplay: "UGP2" },
  { key: "NM_006760.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7379", geneDisplay: "UPK2" },
  { key: "NM_006761.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7531", geneDisplay: "YWHAE" },
  { key: "NM_006762.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7805", geneDisplay: "LAPTM5" },
  { key: "NM_006763.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7832", geneDisplay: "BTG2" },
  { key: "NM_006764.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7866", geneDisplay: "IFRD2" },
  { key: "NM_006765.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7991", geneDisplay: "TUSC3" },
  { key: "NM_006766.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7994", geneDisplay: "KAT6A" },
  { key: "NM_006767.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8216", geneDisplay: "LZTR1" },
  { key: "NM_006768.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8315", geneDisplay: "BRAP" },
  { key: "NM_006769.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8543", geneDisplay: "LMO4" },
  { key: "NM_006770.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8685", geneDisplay: "MARCO" },
  { key: "NM_006771.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8687", geneDisplay: "KRT38" },
  { key: "NM_006772.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8831", geneDisplay: "SYNGAP1" },
  { key: "NM_006773.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8886", geneDisplay: "DDX18" },
  { key: "NM_006774.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11185", geneDisplay: "INMT" },
  { key: "NM_006775.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9444", geneDisplay: "QKI" },
  { key: "NM_006778.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10107", geneDisplay: "TRIM10" },
  { key: "NM_006779.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10435", geneDisplay: "CDC42EP2" },
  { key: "NM_006782.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7542", geneDisplay: "ZFPL1" },
  { key: "NM_006784.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10885", geneDisplay: "WDR3" },
  { key: "NM_006785.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10892", geneDisplay: "MALT1" },
  { key: "NM_006786.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10911", geneDisplay: "UTS2" },
  { key: "NM_006788.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10928", geneDisplay: "RALBP1" },
  { key: "NM_006789.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10930", geneDisplay: "APOBEC2" },
  { key: "NM_006790.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9499", geneDisplay: "MYOT" },
  { key: "NM_006791.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10933", geneDisplay: "MORF4L1" },
  { key: "NM_006793.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10935", geneDisplay: "PRDX3" },
  { key: "NM_006794.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10936", geneDisplay: "GPR75" },
  { key: "NM_006795.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10938", geneDisplay: "EHD1" },
  { key: "NM_006796.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10939", geneDisplay: "AFG3L2" },
  { key: "NM_006799.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10942", geneDisplay: "PRSS21" },
  { key: "NM_006801.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10945", geneDisplay: "KDELR1" },
  { key: "NM_006802.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10946", geneDisplay: "SF3A3" },
  { key: "NM_006803.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10947", geneDisplay: "AP3M2" },
  { key: "NM_006804.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10948", geneDisplay: "STARD3" },
  { key: "NM_006805.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10949", geneDisplay: "HNRNPA0" },
  { key: "NM_006806.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10950", geneDisplay: "BTG3" },
  { key: "NM_006808.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10952", geneDisplay: "SEC61B" },
  { key: "NM_006809.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10953", geneDisplay: "TOMM34" },
  { key: "NM_006810.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10954", geneDisplay: "PDIA5" },
  { key: "NM_006811.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10955", geneDisplay: "SERINC3" },
  { key: "NM_006812.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10956", geneDisplay: "OS9" },
  { key: "NM_006813.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10957", geneDisplay: "PNRC1" },
  { key: "NM_006814.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9491", geneDisplay: "PSMF1" },
  { key: "NM_006815.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10959", geneDisplay: "TMED2" },
  { key: "NM_006816.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10960", geneDisplay: "LMAN2" },
  { key: "NM_006817.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10961", geneDisplay: "ERP29" },
  { key: "NM_006818.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10962", geneDisplay: "MLLT11" },
  { key: "NM_006819.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10963", geneDisplay: "STIP1" },
  { key: "NM_006820.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10964", geneDisplay: "IFI44L" },
  { key: "NM_006821.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10965", geneDisplay: "ACOT2" },
  { key: "NM_006822.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10966", geneDisplay: "RAB40B" },
  { key: "NM_006823.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5569", geneDisplay: "PKIA" },
  { key: "NM_006824.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10969", geneDisplay: "EBNA1BP2" },
  { key: "NM_006825.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10970", geneDisplay: "CKAP4" },
  { key: "NM_006826.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10971", geneDisplay: "YWHAQ" },
  { key: "NM_006827.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10972", geneDisplay: "TMED10" },
  { key: "NM_006828.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10973", geneDisplay: "ASCC3" },
  { key: "NM_006829.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10974", geneDisplay: "ADIRF" },
  { key: "NM_006830.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10975", geneDisplay: "UQCR11" },
  { key: "NM_006831.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10978", geneDisplay: "CLP1" },
  { key: "NM_006832.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10979", geneDisplay: "FERMT2" },
  { key: "NM_006833.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10980", geneDisplay: "COPS6" },
  { key: "NM_006834.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10981", geneDisplay: "RAB32" },
  { key: "NM_006835.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10983", geneDisplay: "CCNI" },
  { key: "NM_006836.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10985", geneDisplay: "GCN1" },
  { key: "NM_006837.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10987", geneDisplay: "COPS5" },
  { key: "NM_006838.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10988", geneDisplay: "METAP2" },
  { key: "NM_006839.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10989", geneDisplay: "IMMT" },
  { key: "NM_006841.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10991", geneDisplay: "SLC38A3" },
  { key: "NM_006842.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10992", geneDisplay: "SF3B2" },
  { key: "NM_006843.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10993", geneDisplay: "SDS" },
  { key: "NM_006844.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10994", geneDisplay: "ILVBL" },
  { key: "NM_006845.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11004", geneDisplay: "KIF2C" },
  { key: "NM_006846.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11005", geneDisplay: "SPINK5" },
  { key: "NM_006848.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11007", geneDisplay: "CCDC85B" },
  { key: "NM_006849.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64714", geneDisplay: "PDIA2" },
  { key: "NM_006850.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11009", geneDisplay: "IL24" },
  { key: "NM_006851.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11010", geneDisplay: "GLIPR1" },
  { key: "NM_006852.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11011", geneDisplay: "TLK2" },
  { key: "NM_006854.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11014", geneDisplay: "KDELR2" },
  { key: "NM_006855.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11015", geneDisplay: "KDELR3" },
  { key: "NM_006856.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11016", geneDisplay: "ATF7" },
  { key: "NM_006857.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11017", geneDisplay: "SNRNP27" },
  { key: "NM_006858.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11018", geneDisplay: "TMED1" },
  { key: "NM_006859.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11019", geneDisplay: "LIAS" },
  { key: "NM_006861.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11021", geneDisplay: "RAB35" },
  { key: "NM_006863.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11024", geneDisplay: "LILRA1" },
  { key: "NM_006864.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11025", geneDisplay: "LILRB3" },
  { key: "NM_006868.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "11031", geneDisplay: "RAB31" },
  { key: "NM_006869.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11033", geneDisplay: "ADAP1" },
  { key: "NM_006870.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11034", geneDisplay: "DSTN" },
  { key: "NM_006871.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11035", geneDisplay: "RIPK3" },
  { key: "NM_006872.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11036", geneDisplay: "GTF2A1L" },
  { key: "NM_006873.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11037", geneDisplay: "STON1" },
  { key: "NM_006875.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11040", geneDisplay: "PIM2" },
  { key: "NM_006876.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11041", geneDisplay: "B4GAT1" },
  { key: "NM_006877.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2766", geneDisplay: "GMPR" },
  { key: "NM_006885.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "463", geneDisplay: "ZFHX3" },
  { key: "NM_006886.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "514", geneDisplay: "ATP5F1E" },
  { key: "NM_006887.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "678", geneDisplay: "ZFP36L2" },
  { key: "NM_006888.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "801", geneDisplay: "CALM1" },
  { key: "NM_006891.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1421", geneDisplay: "CRYGD" },
  { key: "NM_006892.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1789", geneDisplay: "DNMT3B" },
  { key: "NM_006893.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1939", geneDisplay: "EIF2D" },
  { key: "NM_006895.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3176", geneDisplay: "HNMT" },
  { key: "NM_006896.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3204", geneDisplay: "HOXA7" },
  { key: "NM_006897.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3225", geneDisplay: "HOXC9" },
  { key: "NM_006898.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3232", geneDisplay: "HOXD3" },
  { key: "NM_006899.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3420", geneDisplay: "IDH3B" },
  { key: "NM_006900.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3447", geneDisplay: "IFNA13" },
  { key: "NM_006901.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4649", geneDisplay: "MYO9A" },
  { key: "NM_006904.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5591", geneDisplay: "PRKDC" },
  { key: "NM_006906.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84867", geneDisplay: "PTPN5" },
  { key: "NM_006907.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5831", geneDisplay: "PYCR1" },
  { key: "NM_006908.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5879", geneDisplay: "RAC1" },
  { key: "NM_006909.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5924", geneDisplay: "RASGRF2" },
  { key: "NM_006910.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5930", geneDisplay: "RBBP6" },
  { key: "NM_006911.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6013", geneDisplay: "RLN1" },
  { key: "NM_006912.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6016", geneDisplay: "RIT1" },
  { key: "NM_006913.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6048", geneDisplay: "RNF5" },
  { key: "NM_006914.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6096", geneDisplay: "RORB" },
  { key: "NM_006915.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6102", geneDisplay: "RP2" },
  { key: "NM_006917.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6258", geneDisplay: "RXRG" },
  { key: "NM_006918.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6309", geneDisplay: "SC5D" },
  { key: "NM_006919.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6317", geneDisplay: "SERPINB3" },
  { key: "NM_006922.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6328", geneDisplay: "SCN3A" },
  { key: "NM_006923.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6388", geneDisplay: "SDF2" },
  { key: "NM_006924.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6426", geneDisplay: "SRSF1" },
  { key: "NM_006927.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6483", geneDisplay: "ST3GAL2" },
  { key: "NM_006929.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6499", geneDisplay: "SKIV2L" },
  { key: "NM_006931.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6515", geneDisplay: "SLC2A3" },
  { key: "NM_006933.7", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6526", geneDisplay: "SLC5A3" },
  { key: "NM_006936.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6612", geneDisplay: "SUMO3" },
  { key: "NM_006937.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6613", geneDisplay: "SUMO2" },
  { key: "NM_006938.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6632", geneDisplay: "SNRPD1" },
  { key: "NM_006939.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6655", geneDisplay: "SOS2" },
  { key: "NM_006940.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6660", geneDisplay: "SOX5" },
  { key: "NM_006941.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6663", geneDisplay: "SOX10" },
  { key: "NM_006942.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6665", geneDisplay: "SOX15" },
  { key: "NM_006943.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6666", geneDisplay: "SOX12" },
  { key: "NM_006944.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6694", geneDisplay: "SPP2" },
  { key: "NM_006945.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6703", geneDisplay: "SPRR2D" },
  { key: "NM_006946.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6712", geneDisplay: "SPTBN2" },
  { key: "NM_006947.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6731", geneDisplay: "SRP72" },
  { key: "NM_006948.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6782", geneDisplay: "HSPA13" },
  { key: "NM_006949.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6813", geneDisplay: "STXBP2" },
  { key: "NM_006950.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6853", geneDisplay: "SYN1" },
  { key: "NM_006951.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6877", geneDisplay: "TAF5" },
  { key: "NM_006952.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7348", geneDisplay: "UPK1B" },
  { key: "NM_006953.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7380", geneDisplay: "UPK3A" },
  { key: "NM_006954.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7581", geneDisplay: "ZNF33A" },
  { key: "NM_006955.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7582", geneDisplay: "ZNF33B" },
  { key: "NM_006958.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7564", geneDisplay: "ZNF16" },
  { key: "NM_006961.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7567", geneDisplay: "ZNF19" },
  { key: "NM_006963.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7570", geneDisplay: "ZNF22" },
  { key: "NM_006965.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7572", geneDisplay: "ZNF24" },
  { key: "NM_006969.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7576", geneDisplay: "ZNF28" },
  { key: "NM_006973.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7580", geneDisplay: "ZNF32" },
  { key: "NM_006977.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7597", geneDisplay: "ZBTB25" },
  { key: "NM_006978.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7737", geneDisplay: "RNF113A" },
  { key: "NM_006979.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7922", geneDisplay: "SLC39A7" },
  { key: "NM_006980.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7978", geneDisplay: "MTERF1" },
  { key: "NM_006981.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8013", geneDisplay: "NR4A3" },
  { key: "NM_006982.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8092", geneDisplay: "ALX1" },
  { key: "NM_006983.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8510", geneDisplay: "MMP23B" },
  { key: "NM_006984.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9071", geneDisplay: "CLDN10" },
  { key: "NM_006985.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9284", geneDisplay: "NPIPA1" },
  { key: "NM_006986.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9500", geneDisplay: "MAGED1" },
  { key: "NM_006987.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9501", geneDisplay: "RPH3AL" },
  { key: "NM_006988.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9510", geneDisplay: "ADAMTS1" },
  { key: "NM_006989.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10156", geneDisplay: "RASA4" },
  { key: "NM_006990.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10163", geneDisplay: "WASF2" },
  { key: "NM_006991.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10168", geneDisplay: "ZNF197" },
  { key: "NM_006993.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10360", geneDisplay: "NPM3" },
  { key: "NM_006994.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10384", geneDisplay: "BTN3A3" },
  { key: "NM_006995.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10385", geneDisplay: "BTN2A2" },
  { key: "NM_006996.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10560", geneDisplay: "SLC19A2" },
  { key: "NM_006998.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10590", geneDisplay: "SCGN" },
  { key: "NM_006999.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11044", geneDisplay: "TENT4A" },
  { key: "NM_007000.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11045", geneDisplay: "UPK1A" },
  { key: "NM_007001.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11046", geneDisplay: "SLC35D2" },
  { key: "NM_007002.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11047", geneDisplay: "ADRM1" },
  { key: "NM_007003.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9506", geneDisplay: "PAGE4" },
  { key: "NM_007005.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7091", geneDisplay: "TLE4" },
  { key: "NM_007006.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11051", geneDisplay: "NUDT21" },
  { key: "NM_007007.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11052", geneDisplay: "CPSF6" },
  { key: "NM_007009.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11055", geneDisplay: "ZPBP" },
  { key: "NM_007010.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11056", geneDisplay: "DDX52" },
  { key: "NM_007013.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11059", geneDisplay: "WWP1" },
  { key: "NM_007015.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11061", geneDisplay: "CNMD" },
  { key: "NM_007018.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11064", geneDisplay: "CNTRL" },
  { key: "NM_007019.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11065", geneDisplay: "UBE2C" },
  { key: "NM_007021.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11067", geneDisplay: "DEPP1" },
  { key: "NM_007023.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11069", geneDisplay: "RAPGEF4" },
  { key: "NM_007024.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11070", geneDisplay: "TMEM115" },
  { key: "NM_007026.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11072", geneDisplay: "DUSP14" },
  { key: "NM_007027.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11073", geneDisplay: "TOPBP1" },
  { key: "NM_007028.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11074", geneDisplay: "TRIM31" },
  { key: "NM_007029.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11075", geneDisplay: "STMN2" },
  { key: "NM_007030.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11076", geneDisplay: "TPPP" },
  { key: "NM_007031.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "11077", geneDisplay: "HSF2BP" },
  { key: "NM_007033.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11079", geneDisplay: "RER1" },
  { key: "NM_007034.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11080", geneDisplay: "DNAJB4" },
  { key: "NM_007035.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11081", geneDisplay: "KERA" },
  { key: "NM_007036.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11082", geneDisplay: "ESM1" },
  { key: "NM_007037.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11095", geneDisplay: "ADAMTS8" },
  { key: "NM_007038.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "11096", geneDisplay: "ADAMTS5" },
  { key: "NM_007039.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11099", geneDisplay: "PTPN21" },
  { key: "NM_007040.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11100", geneDisplay: "HNRNPUL1" },
  { key: "NM_007042.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11102", geneDisplay: "RPP14" },
  { key: "NM_007043.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11103", geneDisplay: "KRR1" },
  { key: "NM_007044.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11104", geneDisplay: "KATNA1" },
  { key: "NM_007045.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11116", geneDisplay: "CEP43" },
  { key: "NM_007046.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11117", geneDisplay: "EMILIN1" },
  { key: "NM_007047.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11118", geneDisplay: "BTN3A2" },
  { key: "NM_007048.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11119", geneDisplay: "BTN3A1" },
  { key: "NM_007049.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11120", geneDisplay: "BTN2A1" },
  { key: "NM_007050.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11122", geneDisplay: "PTPRT" },
  { key: "NM_007051.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11124", geneDisplay: "FAF1" },
  { key: "NM_007052.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27035", geneDisplay: "NOX1" },
  { key: "NM_007053.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11126", geneDisplay: "CD160" },
  { key: "NM_007055.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11128", geneDisplay: "POLR3A" },
  { key: "NM_007056.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11129", geneDisplay: "CLASRP" },
  { key: "NM_007057.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11130", geneDisplay: "ZWINT" },
  { key: "NM_007058.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11131", geneDisplay: "CAPN11" },
  { key: "NM_007059.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11133", geneDisplay: "KPTN" },
  { key: "NM_007062.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11137", geneDisplay: "PWP1" },
  { key: "NM_007065.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11140", geneDisplay: "CDC37" },
  { key: "NM_007067.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11143", geneDisplay: "KAT7" },
  { key: "NM_007068.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11144", geneDisplay: "DMC1" },
  { key: "NM_007074.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11151", geneDisplay: "CORO1A" },
  { key: "NM_007076.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11153", geneDisplay: "FICD" },
  { key: "NM_007078.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11155", geneDisplay: "LDB3" },
  { key: "NM_007080.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11157", geneDisplay: "LSM6" },
  { key: "NM_007083.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11162", geneDisplay: "NUDT6" },
  { key: "NM_007084.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11166", geneDisplay: "SOX21" },
  { key: "NM_007085.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11167", geneDisplay: "FSTL1" },
  { key: "NM_007086.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11169", geneDisplay: "WDHD1" },
  { key: "NM_007097.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1212", geneDisplay: "CLTB" },
  { key: "NM_007098.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8218", geneDisplay: "CLTCL1" },
  { key: "NM_007100.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "521", geneDisplay: "ATP5ME" },
  { key: "NM_007102.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2981", geneDisplay: "GUCA2B" },
  { key: "NM_007103.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4723", geneDisplay: "NDUFV1" },
  { key: "NM_007104.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4736", geneDisplay: "RPL10A" },
  { key: "NM_007106.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5412", geneDisplay: "UBL3" },
  { key: "NM_007107.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6747", geneDisplay: "SSR3" },
  { key: "NM_007108.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6923", geneDisplay: "ELOB" },
  { key: "NM_007109.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6941", geneDisplay: "TCF19" },
  { key: "NM_007110.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7011", geneDisplay: "TEP1" },
  { key: "NM_007111.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7027", geneDisplay: "TFDP1" },
  { key: "NM_007112.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7059", geneDisplay: "THBS3" },
  { key: "NM_007113.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7062", geneDisplay: "TCHH" },
  { key: "NM_007114.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7110", geneDisplay: "TMF1" },
  { key: "NM_007115.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7130", geneDisplay: "TNFAIP6" },
  { key: "NM_007117.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7200", geneDisplay: "TRH" },
  { key: "NM_007118.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7204", geneDisplay: "TRIO" },
  { key: "NM_007120.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54657", geneDisplay: "UGT1A4" },
  { key: "NM_007121.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7376", geneDisplay: "NR1H2" },
  { key: "NM_007122.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7391", geneDisplay: "USF1" },
  { key: "NM_007124.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7402", geneDisplay: "UTRN" },
  { key: "NM_007126.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7415", geneDisplay: "VCP" },
  { key: "NM_007127.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7429", geneDisplay: "VIL1" },
  { key: "NM_007128.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7441", geneDisplay: "VPREB1" },
  { key: "NM_007129.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7546", geneDisplay: "ZIC2" },
  { key: "NM_007131.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7626", geneDisplay: "ZNF75D" },
  { key: "NM_007135.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7633", geneDisplay: "ZNF79" },
  { key: "NM_007136.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7634", geneDisplay: "ZNF80" },
  { key: "NM_007137.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347344", geneDisplay: "ZNF81" },
  { key: "NM_007138.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7643", geneDisplay: "ZNF90" },
  { key: "NM_007144.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7703", geneDisplay: "PCGF2" },
  { key: "NM_007145.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7705", geneDisplay: "ZNF146" },
  { key: "NM_007146.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7716", geneDisplay: "VEZF1" },
  { key: "NM_007147.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7728", geneDisplay: "ZNF175" },
  { key: "NM_007148.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7732", geneDisplay: "RNF112" },
  { key: "NM_007153.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7757", geneDisplay: "ZNF208" },
  { key: "NM_007156.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7789", geneDisplay: "ZXDA" },
  { key: "NM_007157.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158586", geneDisplay: "ZXDB" },
  { key: "NM_007160.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7932", geneDisplay: "OR2H2" },
  { key: "NM_007163.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "8170", geneDisplay: "SLC14A2" },
  { key: "NM_007165.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8175", geneDisplay: "SF3A2" },
  { key: "NM_007166.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8301", geneDisplay: "PICALM" },
  { key: "NM_007167.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9204", geneDisplay: "ZMYM6" },
  { key: "NM_007170.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10420", geneDisplay: "TESK2" },
  { key: "NM_007172.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10762", geneDisplay: "NUP50" },
  { key: "NM_007173.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11098", geneDisplay: "PRSS23" },
  { key: "NM_007175.8", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11160", geneDisplay: "ERLIN2" },
  { key: "NM_007176.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11161", geneDisplay: "ERG28" },
  { key: "NM_007178.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11171", geneDisplay: "STRAP" },
  { key: "NM_007179.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11172", geneDisplay: "INSL6" },
  { key: "NM_007180.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11181", geneDisplay: "TREH" },
  { key: "NM_007182.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11186", geneDisplay: "RASSF1" },
  { key: "NM_007183.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11187", geneDisplay: "PKP3" },
  { key: "NM_007184.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11188", geneDisplay: "NISCH" },
  { key: "NM_007185.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11189", geneDisplay: "CELF3" },
  { key: "NM_007186.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11190", geneDisplay: "CEP250" },
  { key: "NM_007187.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11193", geneDisplay: "WBP4" },
  { key: "NM_007188.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11194", geneDisplay: "ABCB8" },
  { key: "NM_007189.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10061", geneDisplay: "ABCF2" },
  { key: "NM_007190.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11196", geneDisplay: "SEC23IP" },
  { key: "NM_007191.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11197", geneDisplay: "WIF1" },
  { key: "NM_007192.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11198", geneDisplay: "SUPT16H" },
  { key: "NM_007193.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11199", geneDisplay: "ANXA10" },
  { key: "NM_007194.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11200", geneDisplay: "CHEK2" },
  { key: "NM_007195.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "11201", geneDisplay: "POLI" },
  { key: "NM_007196.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11202", geneDisplay: "KLK8" },
  { key: "NM_007197.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11211", geneDisplay: "FZD10" },
  { key: "NM_007198.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11212", geneDisplay: "PLPBP" },
  { key: "NM_007199.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11213", geneDisplay: "IRAK3" },
  { key: "NM_007200.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11214", geneDisplay: "AKAP13" },
  { key: "NM_007202.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11216", geneDisplay: "AKAP10" },
  { key: "NM_007203.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "445815", geneDisplay: "PALM2AKAP2" },
  { key: "NM_007204.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11218", geneDisplay: "DDX20" },
  { key: "NM_007207.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11221", geneDisplay: "DUSP10" },
  { key: "NM_007208.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11222", geneDisplay: "MRPL3" },
  { key: "NM_007209.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11224", geneDisplay: "RPL35" },
  { key: "NM_007210.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11226", geneDisplay: "GALNT6" },
  { key: "NM_007212.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6045", geneDisplay: "RNF2" },
  { key: "NM_007213.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11230", geneDisplay: "PRAF2" },
  { key: "NM_007214.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11231", geneDisplay: "SEC63" },
  { key: "NM_007215.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11232", geneDisplay: "POLG2" },
  { key: "NM_007217.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11235", geneDisplay: "PDCD10" },
  { key: "NM_007218.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11236", geneDisplay: "RNF139" },
  { key: "NM_007220.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11238", geneDisplay: "CA5B" },
  { key: "NM_007221.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11243", geneDisplay: "PMF1" },
  { key: "NM_007222.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11244", geneDisplay: "ZHX1" },
  { key: "NM_007223.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11245", geneDisplay: "GPR176" },
  { key: "NM_007224.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11247", geneDisplay: "NXPH4" },
  { key: "NM_007225.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11248", geneDisplay: "NXPH3" },
  { key: "NM_007226.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11249", geneDisplay: "NXPH2" },
  { key: "NM_007227.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11250", geneDisplay: "GPR45" },
  { key: "NM_007231.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11254", geneDisplay: "SLC6A14" },
  { key: "NM_007232.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11255", geneDisplay: "HRH3" },
  { key: "NM_007234.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11258", geneDisplay: "DCTN3" },
  { key: "NM_007235.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11260", geneDisplay: "XPOT" },
  { key: "NM_007236.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11261", geneDisplay: "CHP1" },
  { key: "NM_007237.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11262", geneDisplay: "SP140" },
  { key: "NM_007238.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11264", geneDisplay: "PXMP4" },
  { key: "NM_007240.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11266", geneDisplay: "DUSP12" },
  { key: "NM_007241.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11267", geneDisplay: "SNF8" },
  { key: "NM_007242.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11269", geneDisplay: "DDX19B" },
  { key: "NM_007244.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11272", geneDisplay: "PRR4" },
  { key: "NM_007245.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11273", geneDisplay: "ATXN2L" },
  { key: "NM_007246.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11275", geneDisplay: "KLHL2" },
  { key: "NM_007247.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11276", geneDisplay: "SYNRG" },
  { key: "NM_007249.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11278", geneDisplay: "KLF12" },
  { key: "NM_007250.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11279", geneDisplay: "KLF8" },
  { key: "NM_007253.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11283", geneDisplay: "CYP4F8" },
  { key: "NM_007254.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11284", geneDisplay: "PNKP" },
  { key: "NM_007255.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11285", geneDisplay: "B4GALT7" },
  { key: "NM_007256.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11309", geneDisplay: "SLCO2B1" },
  { key: "NM_007257.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10687", geneDisplay: "PNMA2" },
  { key: "NM_007259.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11311", geneDisplay: "VPS45" },
  { key: "NM_007260.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11313", geneDisplay: "LYPLA2" },
  { key: "NM_007261.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11314", geneDisplay: "CD300A" },
  { key: "NM_007262.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11315", geneDisplay: "PARK7" },
  { key: "NM_007263.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11316", geneDisplay: "COPE" },
  { key: "NM_007264.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11318", geneDisplay: "GPR182" },
  { key: "NM_007265.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11319", geneDisplay: "ECD" },
  { key: "NM_007266.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11321", geneDisplay: "GPN1" },
  { key: "NM_007268.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11326", geneDisplay: "VSIG4" },
  { key: "NM_007269.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6814", geneDisplay: "STXBP3" },
  { key: "NM_007270.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11328", geneDisplay: "FKBP9" },
  { key: "NM_007271.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11329", geneDisplay: "STK38" },
  { key: "NM_007272.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11330", geneDisplay: "CTRC" },
  { key: "NM_007274.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11332", geneDisplay: "ACOT7" },
  { key: "NM_007275.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11334", geneDisplay: "TUSC2" },
  { key: "NM_007277.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11336", geneDisplay: "EXOC3" },
  { key: "NM_007278.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11337", geneDisplay: "GABARAP" },
  { key: "NM_007279.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11338", geneDisplay: "U2AF2" },
  { key: "NM_007280.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11339", geneDisplay: "OIP5" },
  { key: "NM_007281.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11341", geneDisplay: "SCRG1" },
  { key: "NM_007283.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11343", geneDisplay: "MGLL" },
  { key: "NM_007284.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11344", geneDisplay: "TWF2" },
  { key: "NM_007285.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11345", geneDisplay: "GABARAPL2" },
  { key: "NM_007286.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11346", geneDisplay: "SYNPO" },
  { key: "NM_007289.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4311", geneDisplay: "MME" },
  { key: "NM_007293.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "720", geneDisplay: "C4A" },
  { key: "NM_007294.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "672", geneDisplay: "BRCA1" },
  { key: "NM_007314.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27", geneDisplay: "ABL2" },
  { key: "NM_007315.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6772", geneDisplay: "STAT1" },
  { key: "NM_007317.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3835", geneDisplay: "KIF22" },
  { key: "NM_007322.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8498", geneDisplay: "RANBP3" },
  { key: "NM_007325.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2892", geneDisplay: "GRIA3" },
  { key: "NM_007327.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2902", geneDisplay: "GRIN1" },
  { key: "NM_007332.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8989", geneDisplay: "TRPA1" },
  { key: "NM_007335.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9940", geneDisplay: "DLEC1" },
  { key: "NM_007341.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6450", geneDisplay: "SH3BGR" },
  { key: "NM_007342.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11097", geneDisplay: "NUP42" },
  { key: "NM_007344.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7270", geneDisplay: "TTF1" },
  { key: "NM_007346.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11054", geneDisplay: "OGFR" },
  { key: "NM_007347.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23431", geneDisplay: "AP4E1" },
  { key: "NM_007348.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22926", geneDisplay: "ATF6" },
  { key: "NM_007349.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "22976", geneDisplay: "PAXIP1" },
  { key: "NM_007350.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22822", geneDisplay: "PHLDA1" },
  { key: "NM_007351.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "22915", geneDisplay: "MMRN1" },
  { key: "NM_007352.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23436", geneDisplay: "CELA3B" },
  { key: "NM_007353.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2768", geneDisplay: "GNA12" },
  { key: "NM_007355.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3326", geneDisplay: "HSP90AB1" },
  { key: "NM_007356.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "22798", geneDisplay: "LAMB4" },
  { key: "NM_007357.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22796", geneDisplay: "COG2" },
  { key: "NM_007358.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22823", geneDisplay: "MTF2" },
  { key: "NM_007359.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22794", geneDisplay: "CASC3" },
  { key: "NM_007360.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22914", geneDisplay: "KLRK1" },
  { key: "NM_007361.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22795", geneDisplay: "NID2" },
  { key: "NM_007362.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22916", geneDisplay: "NCBP2" },
  { key: "NM_007363.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4841", geneDisplay: "NONO" },
  { key: "NM_007364.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23423", geneDisplay: "TMED3" },
  { key: "NM_007365.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11240", geneDisplay: "PADI2" },
  { key: "NM_007366.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22925", geneDisplay: "PLA2R1" },
  { key: "NM_007368.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "22821", geneDisplay: "RASA3" },
  { key: "NM_007370.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5985", geneDisplay: "RFC5" },
  { key: "NM_007371.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8019", geneDisplay: "BRD3" },
  { key: "NM_007373.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8036", geneDisplay: "SHOC2" },
  { key: "NM_007374.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4990", geneDisplay: "SIX6" },
  { key: "NM_007375.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23435", geneDisplay: "TARDBP" },
  { key: "NM_009587.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3965", geneDisplay: "LGALS9" },
  { key: "NM_009590.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "314", geneDisplay: "AOC2" },
  { key: "NM_012062.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10059", geneDisplay: "DNM1L" },
  { key: "NM_012064.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4284", geneDisplay: "MIP" },
  { key: "NM_012067.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22977", geneDisplay: "AKR7A3" },
  { key: "NM_012071.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23412", geneDisplay: "COMMD3" },
  { key: "NM_012072.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22918", geneDisplay: "CD93" },
  { key: "NM_012073.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22948", geneDisplay: "CCT5" },
  { key: "NM_012079.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8694", geneDisplay: "DGAT1" },
  { key: "NM_012080.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8226", geneDisplay: "PUDP" },
  { key: "NM_012081.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22936", geneDisplay: "ELL2" },
  { key: "NM_012082.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23414", geneDisplay: "ZFPM2" },
  { key: "NM_012083.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23401", geneDisplay: "FRAT2" },
  { key: "NM_012084.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2747", geneDisplay: "GLUD2" },
  { key: "NM_012086.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9330", geneDisplay: "GTF3C3" },
  { key: "NM_012087.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9328", geneDisplay: "GTF3C5" },
  { key: "NM_012088.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25796", geneDisplay: "PGLS" },
  { key: "NM_012089.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23456", geneDisplay: "ABCB10" },
  { key: "NM_012092.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29851", geneDisplay: "ICOS" },
  { key: "NM_012094.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25824", geneDisplay: "PRDX5" },
  { key: "NM_012095.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26985", geneDisplay: "AP3M1" },
  { key: "NM_012096.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26060", geneDisplay: "APPL1" },
  { key: "NM_012097.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26225", geneDisplay: "ARL5A" },
  { key: "NM_012098.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23452", geneDisplay: "ANGPTL2" },
  { key: "NM_012099.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10849", geneDisplay: "POLR1G" },
  { key: "NM_012100.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23549", geneDisplay: "DNPEP" },
  { key: "NM_012101.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23650", geneDisplay: "TRIM29" },
  { key: "NM_012104.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23621", geneDisplay: "BACE1" },
  { key: "NM_012105.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "25825", geneDisplay: "BACE2" },
  { key: "NM_012106.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23568", geneDisplay: "ARL2BP" },
  { key: "NM_012108.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26228", geneDisplay: "STAP1" },
  { key: "NM_012109.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25789", geneDisplay: "TMEM59L" },
  { key: "NM_012110.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26511", geneDisplay: "CHIC2" },
  { key: "NM_012111.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10598", geneDisplay: "AHSA1" },
  { key: "NM_012112.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22974", geneDisplay: "TPX2" },
  { key: "NM_012113.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23632", geneDisplay: "CA14" },
  { key: "NM_012114.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23581", geneDisplay: "CASP14" },
  { key: "NM_012116.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23624", geneDisplay: "CBLC" },
  { key: "NM_012117.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23468", geneDisplay: "CBX5" },
  { key: "NM_012118.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "25819", geneDisplay: "NOCT" },
  { key: "NM_012120.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23607", geneDisplay: "CD2AP" },
  { key: "NM_012121.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23580", geneDisplay: "CDC42EP4" },
  { key: "NM_012123.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25821", geneDisplay: "MTO1" },
  { key: "NM_012124.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26973", geneDisplay: "CHORDC1" },
  { key: "NM_012125.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1133", geneDisplay: "CHRM5" },
  { key: "NM_012128.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22802", geneDisplay: "CLCA4" },
  { key: "NM_012131.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "26285", geneDisplay: "CLDN17" },
  { key: "NM_012133.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26958", geneDisplay: "COPG2" },
  { key: "NM_012134.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25802", geneDisplay: "LMOD1" },
  { key: "NM_012135.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26240", geneDisplay: "FAM50B" },
  { key: "NM_012137.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23576", geneDisplay: "DDAH1" },
  { key: "NM_012138.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26574", geneDisplay: "AATF" },
  { key: "NM_012139.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26297", geneDisplay: "SERGEF" },
  { key: "NM_012140.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1468", geneDisplay: "SLC25A10" },
  { key: "NM_012141.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26512", geneDisplay: "INTS6" },
  { key: "NM_012142.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23582", geneDisplay: "CCNDBP1" },
  { key: "NM_012143.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "24144", geneDisplay: "TFIP11" },
  { key: "NM_012144.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27019", geneDisplay: "DNAI1" },
  { key: "NM_012145.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1841", geneDisplay: "DTYMK" },
  { key: "NM_012151.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8263", geneDisplay: "F8A1" },
  { key: "NM_012152.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23566", geneDisplay: "LPAR3" },
  { key: "NM_012153.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26298", geneDisplay: "EHF" },
  { key: "NM_012154.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27161", geneDisplay: "AGO2" },
  { key: "NM_012155.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "24139", geneDisplay: "EML2" },
  { key: "NM_012156.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2036", geneDisplay: "EPB41L1" },
  { key: "NM_012157.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25827", geneDisplay: "FBXL2" },
  { key: "NM_012158.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26224", geneDisplay: "FBXL3" },
  { key: "NM_012161.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26234", geneDisplay: "FBXL5" },
  { key: "NM_012162.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26233", geneDisplay: "FBXL6" },
  { key: "NM_012164.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26190", geneDisplay: "FBXW2" },
  { key: "NM_012166.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26267", geneDisplay: "FBXO10" },
  { key: "NM_012168.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26232", geneDisplay: "FBXO2" },
  { key: "NM_012175.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26273", geneDisplay: "FBXO3" },
  { key: "NM_012176.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26272", geneDisplay: "FBXO4" },
  { key: "NM_012177.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26271", geneDisplay: "FBXO5" },
  { key: "NM_012179.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25793", geneDisplay: "FBXO7" },
  { key: "NM_012180.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26269", geneDisplay: "FBXO8" },
  { key: "NM_012181.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23770", geneDisplay: "FKBP8" },
  { key: "NM_012182.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "27023", geneDisplay: "FOXB1" },
  { key: "NM_012183.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27022", geneDisplay: "FOXD3" },
  { key: "NM_012184.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200350", geneDisplay: "FOXD4L1" },
  { key: "NM_012186.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2301", geneDisplay: "FOXE3" },
  { key: "NM_012188.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2299", geneDisplay: "FOXI1" },
  { key: "NM_012190.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10840", geneDisplay: "ALDH1L1" },
  { key: "NM_012192.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26515", geneDisplay: "TIMM10B" },
  { key: "NM_012193.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8322", geneDisplay: "FZD4" },
  { key: "NM_012194.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25758", geneDisplay: "KIAA1549L" },
  { key: "NM_012197.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23637", geneDisplay: "RABGAP1" },
  { key: "NM_012198.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25801", geneDisplay: "GCA" },
  { key: "NM_012199.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26523", geneDisplay: "AGO1" },
  { key: "NM_012200.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26229", geneDisplay: "B3GAT3" },
  { key: "NM_012202.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2785", geneDisplay: "GNG3" },
  { key: "NM_012203.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9380", geneDisplay: "GRHPR" },
  { key: "NM_012204.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9329", geneDisplay: "GTF3C4" },
  { key: "NM_012205.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23498", geneDisplay: "HAAO" },
  { key: "NM_012207.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3189", geneDisplay: "HNRNPH3" },
  { key: "NM_012208.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23438", geneDisplay: "HARS2" },
  { key: "NM_012210.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22954", geneDisplay: "TRIM32" },
  { key: "NM_012213.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23417", geneDisplay: "MLYCD" },
  { key: "NM_012214.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11320", geneDisplay: "MGAT4A" },
  { key: "NM_012215.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10724", geneDisplay: "OGA" },
  { key: "NM_012216.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11043", geneDisplay: "MID2" },
  { key: "NM_012217.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23430", geneDisplay: "TPSD1" },
  { key: "NM_012225.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10101", geneDisplay: "NUBP2" },
  { key: "NM_012228.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22921", geneDisplay: "MSRB2" },
  { key: "NM_012230.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "22932", geneDisplay: "POMZP3" },
  { key: "NM_012232.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284119", geneDisplay: "CAVIN1" },
  { key: "NM_012233.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22930", geneDisplay: "RAB3GAP1" },
  { key: "NM_012234.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23429", geneDisplay: "RYBP" },
  { key: "NM_012235.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22937", geneDisplay: "SCAP" },
  { key: "NM_012237.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22933", geneDisplay: "SIRT2" },
  { key: "NM_012238.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23411", geneDisplay: "SIRT1" },
  { key: "NM_012239.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23410", geneDisplay: "SIRT3" },
  { key: "NM_012240.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23409", geneDisplay: "SIRT4" },
  { key: "NM_012241.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23408", geneDisplay: "SIRT5" },
  { key: "NM_012242.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22943", geneDisplay: "DKK1" },
  { key: "NM_012243.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23443", geneDisplay: "SLC35A3" },
  { key: "NM_012244.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23428", geneDisplay: "SLC7A8" },
  { key: "NM_012245.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22938", geneDisplay: "SNW1" },
  { key: "NM_012247.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22929", geneDisplay: "SEPHS1" },
  { key: "NM_012248.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "22928", geneDisplay: "SEPHS2" },
  { key: "NM_012249.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23433", geneDisplay: "RHOQ" },
  { key: "NM_012250.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22800", geneDisplay: "RRAS2" },
  { key: "NM_012252.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "22797", geneDisplay: "TFEC" },
  { key: "NM_012253.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8277", geneDisplay: "TKTL1" },
  { key: "NM_012254.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10998", geneDisplay: "SLC27A5" },
  { key: "NM_012255.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22803", geneDisplay: "XRN2" },
  { key: "NM_012256.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7988", geneDisplay: "ZNF212" },
  { key: "NM_012257.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26959", geneDisplay: "HBP1" },
  { key: "NM_012258.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23462", geneDisplay: "HEY1" },
  { key: "NM_012259.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23493", geneDisplay: "HEY2" },
  { key: "NM_012260.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26061", geneDisplay: "HACL1" },
  { key: "NM_012261.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "24141", geneDisplay: "LAMP5" },
  { key: "NM_012262.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9653", geneDisplay: "HS2ST1" },
  { key: "NM_012263.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25809", geneDisplay: "TTLL1" },
  { key: "NM_012264.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25829", geneDisplay: "TMEM184B" },
  { key: "NM_012265.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25807", geneDisplay: "RHBDD3" },
  { key: "NM_012267.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23640", geneDisplay: "HSPBP1" },
  { key: "NM_012268.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23646", geneDisplay: "PLD3" },
  { key: "NM_012269.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23553", geneDisplay: "HYAL4" },
  { key: "NM_012274.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "25763", geneDisplay: "H2AP" },
  { key: "NM_012275.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26525", geneDisplay: "IL36RN" },
  { key: "NM_012276.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23547", geneDisplay: "LILRA4" },
  { key: "NM_012278.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "26548", geneDisplay: "ITGB1BP2" },
  { key: "NM_012279.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23567", geneDisplay: "ZNF346" },
  { key: "NM_012280.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "24140", geneDisplay: "FTSJ1" },
  { key: "NM_012281.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3751", geneDisplay: "KCND2" },
  { key: "NM_012282.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23630", geneDisplay: "KCNE5" },
  { key: "NM_012283.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "26251", geneDisplay: "KCNG2" },
  { key: "NM_012284.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23416", geneDisplay: "KCNH3" },
  { key: "NM_012285.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23415", geneDisplay: "KCNH4" },
  { key: "NM_012286.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9643", geneDisplay: "MORF4L2" },
  { key: "NM_012287.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23527", geneDisplay: "ACAP2" },
  { key: "NM_012288.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9697", geneDisplay: "TRAM2" },
  { key: "NM_012290.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9874", geneDisplay: "TLK1" },
  { key: "NM_012291.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9700", geneDisplay: "ESPL1" },
  { key: "NM_012292.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23526", geneDisplay: "ARHGAP45" },
  { key: "NM_012293.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7837", geneDisplay: "PXDN" },
  { key: "NM_012294.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9771", geneDisplay: "RAPGEF5" },
  { key: "NM_012295.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23523", geneDisplay: "CABIN1" },
  { key: "NM_012301.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9863", geneDisplay: "MAGI2" },
  { key: "NM_012304.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23194", geneDisplay: "FBXL7" },
  { key: "NM_012305.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "161", geneDisplay: "AP2A2" },
  { key: "NM_012306.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23017", geneDisplay: "FAIM2" },
  { key: "NM_012307.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23136", geneDisplay: "EPB41L3" },
  { key: "NM_012308.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22992", geneDisplay: "KDM2A" },
  { key: "NM_012309.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22941", geneDisplay: "SHANK2" },
  { key: "NM_012310.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "24137", geneDisplay: "KIF4A" },
  { key: "NM_012311.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22944", geneDisplay: "KIN" },
  { key: "NM_012315.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284366", geneDisplay: "KLK9" },
  { key: "NM_012316.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23633", geneDisplay: "KPNA6" },
  { key: "NM_012317.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23641", geneDisplay: "LDOC1" },
  { key: "NM_012318.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3954", geneDisplay: "LETM1" },
  { key: "NM_012319.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "25800", geneDisplay: "SLC39A6" },
  { key: "NM_012320.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23659", geneDisplay: "PLA2G15" },
  { key: "NM_012321.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25804", geneDisplay: "LSM4" },
  { key: "NM_012322.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23658", geneDisplay: "LSM5" },
  { key: "NM_012323.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23764", geneDisplay: "MAFF" },
  { key: "NM_012324.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23542", geneDisplay: "MAPK8IP2" },
  { key: "NM_012325.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22919", geneDisplay: "MAPRE1" },
  { key: "NM_012326.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22924", geneDisplay: "MAPRE3" },
  { key: "NM_012328.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4189", geneDisplay: "DNAJB9" },
  { key: "NM_012329.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23531", geneDisplay: "MMD" },
  { key: "NM_012330.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23522", geneDisplay: "KAT6B" },
  { key: "NM_012331.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4482", geneDisplay: "MSRA" },
  { key: "NM_012333.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26292", geneDisplay: "MYCBP" },
  { key: "NM_012334.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4651", geneDisplay: "MYO10" },
  { key: "NM_012335.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4542", geneDisplay: "MYO1F" },
  { key: "NM_012336.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26502", geneDisplay: "NARF" },
  { key: "NM_012337.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25790", geneDisplay: "CFAP45" },
  { key: "NM_012338.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23554", geneDisplay: "TSPAN12" },
  { key: "NM_012339.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23555", geneDisplay: "TSPAN15" },
  { key: "NM_012340.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4773", geneDisplay: "NFATC2" },
  { key: "NM_012341.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23560", geneDisplay: "GTPBP4" },
  { key: "NM_012342.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "25805", geneDisplay: "BAMBI" },
  { key: "NM_012344.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23620", geneDisplay: "NTSR2" },
  { key: "NM_012345.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26747", geneDisplay: "NUFIP1" },
  { key: "NM_012351.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26476", geneDisplay: "OR10J1" },
  { key: "NM_012352.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26189", geneDisplay: "OR1A2" },
  { key: "NM_012353.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26188", geneDisplay: "OR1C1" },
  { key: "NM_012363.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138883", geneDisplay: "OR1N1" },
  { key: "NM_012364.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158131", geneDisplay: "OR1Q1" },
  { key: "NM_012365.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "393046", geneDisplay: "OR2A5" },
  { key: "NM_012367.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26212", geneDisplay: "OR2B6" },
  { key: "NM_012368.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4993", geneDisplay: "OR2C1" },
  { key: "NM_012369.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26211", geneDisplay: "OR2F1" },
  { key: "NM_012375.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23538", geneDisplay: "OR52A1" },
  { key: "NM_012377.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26658", geneDisplay: "OR7C2" },
  { key: "NM_012378.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26493", geneDisplay: "OR8B8" },
  { key: "NM_012381.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23595", geneDisplay: "ORC3" },
  { key: "NM_012382.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23548", geneDisplay: "TTC33" },
  { key: "NM_012383.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26578", geneDisplay: "OSTF1" },
  { key: "NM_012384.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26205", geneDisplay: "GMEB2" },
  { key: "NM_012385.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26471", geneDisplay: "NUPR1" },
  { key: "NM_012387.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23569", geneDisplay: "PADI4" },
  { key: "NM_012388.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26258", geneDisplay: "BLOC1S6" },
  { key: "NM_012390.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26952", geneDisplay: "SMR3A" },
  { key: "NM_012391.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25803", geneDisplay: "SPDEF" },
  { key: "NM_012392.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "553115", geneDisplay: "PEF1" },
  { key: "NM_012393.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5198", geneDisplay: "PFAS" },
  { key: "NM_012394.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5202", geneDisplay: "PFDN2" },
  { key: "NM_012396.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23612", geneDisplay: "PHLDA3" },
  { key: "NM_012397.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5275", geneDisplay: "SERPINB13" },
  { key: "NM_012398.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23396", geneDisplay: "PIP5K1C" },
  { key: "NM_012399.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23760", geneDisplay: "PITPNB" },
  { key: "NM_012400.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26279", geneDisplay: "PLA2G2D" },
  { key: "NM_012401.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23654", geneDisplay: "PLXNB2" },
  { key: "NM_012404.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23519", geneDisplay: "ANP32D" },
  { key: "NM_012405.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23463", geneDisplay: "ICMT" },
  { key: "NM_012406.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11108", geneDisplay: "PRDM4" },
  { key: "NM_012407.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9463", geneDisplay: "PICK1" },
  { key: "NM_012409.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23627", geneDisplay: "PRND" },
  { key: "NM_012412.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "94239", geneDisplay: "H2AZ2" },
  { key: "NM_012413.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25797", geneDisplay: "QPCT" },
  { key: "NM_012414.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25782", geneDisplay: "RAB3GAP2" },
  { key: "NM_012415.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "25788", geneDisplay: "RAD54B" },
  { key: "NM_012416.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26953", geneDisplay: "RANBP6" },
  { key: "NM_012417.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26207", geneDisplay: "PITPNC1" },
  { key: "NM_012418.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "25794", geneDisplay: "FSCN2" },
  { key: "NM_012419.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26575", geneDisplay: "RGS17" },
  { key: "NM_012420.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "24138", geneDisplay: "IFIT5" },
  { key: "NM_012421.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6018", geneDisplay: "RLF" },
  { key: "NM_012423.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23521", geneDisplay: "RPL13A" },
  { key: "NM_012424.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26750", geneDisplay: "RPS6KC1" },
  { key: "NM_012425.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6251", geneDisplay: "RSU1" },
  { key: "NM_012426.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23450", geneDisplay: "SF3B3" },
  { key: "NM_012427.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25818", geneDisplay: "KLK5" },
  { key: "NM_012428.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "27020", geneDisplay: "NPTN" },
  { key: "NM_012429.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23541", geneDisplay: "SEC14L2" },
  { key: "NM_012430.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26984", geneDisplay: "SEC22A" },
  { key: "NM_012431.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9723", geneDisplay: "SEMA3E" },
  { key: "NM_012433.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23451", geneDisplay: "SF3B1" },
  { key: "NM_012434.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26503", geneDisplay: "SLC17A5" },
  { key: "NM_012435.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25759", geneDisplay: "SHC2" },
  { key: "NM_012437.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23557", geneDisplay: "SNAPIN" },
  { key: "NM_012443.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9576", geneDisplay: "SPAG6" },
  { key: "NM_012444.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23626", geneDisplay: "SPO11" },
  { key: "NM_012445.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10417", geneDisplay: "SPON2" },
  { key: "NM_012446.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23635", geneDisplay: "SSBP2" },
  { key: "NM_012448.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6777", geneDisplay: "STAT5B" },
  { key: "NM_012449.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26872", geneDisplay: "STEAP1" },
  { key: "NM_012451.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23546", geneDisplay: "SYNGR4" },
  { key: "NM_012452.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23495", geneDisplay: "TNFRSF13B" },
  { key: "NM_012453.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26608", geneDisplay: "TBL2" },
  { key: "NM_012454.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26230", geneDisplay: "TIAM2" },
  { key: "NM_012455.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23550", geneDisplay: "PSD4" },
  { key: "NM_012456.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26519", geneDisplay: "TIMM10" },
  { key: "NM_012458.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26517", geneDisplay: "TIMM13" },
  { key: "NM_012459.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26521", geneDisplay: "TIMM8B" },
  { key: "NM_012460.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26520", geneDisplay: "TIMM9" },
  { key: "NM_012463.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23545", geneDisplay: "ATP6V0A2" },
  { key: "NM_012464.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7092", geneDisplay: "TLL1" },
  { key: "NM_012465.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7093", geneDisplay: "TLL2" },
  { key: "NM_012467.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25823", geneDisplay: "TPSG1" },
  { key: "NM_012469.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "24148", geneDisplay: "PRPF6" },
  { key: "NM_012470.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23534", geneDisplay: "TNPO3" },
  { key: "NM_012471.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7224", geneDisplay: "TRPC5" },
  { key: "NM_012472.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23639", geneDisplay: "DNAAF11" },
  { key: "NM_012473.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25828", geneDisplay: "TXN2" },
  { key: "NM_012474.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7371", geneDisplay: "UCK2" },
  { key: "NM_012476.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25806", geneDisplay: "VAX2" },
  { key: "NM_012477.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23559", geneDisplay: "WBP1" },
  { key: "NM_012478.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23558", geneDisplay: "WBP2" },
  { key: "NM_012479.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7532", geneDisplay: "YWHAG" },
  { key: "NM_012481.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22806", geneDisplay: "IKZF3" },
  { key: "NM_013231.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23768", geneDisplay: "FLRT2" },
  { key: "NM_013232.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10016", geneDisplay: "PDCD6" },
  { key: "NM_013233.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27347", geneDisplay: "STK39" },
  { key: "NM_013234.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27335", geneDisplay: "EIF3K" },
  { key: "NM_013236.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25814", geneDisplay: "ATXN10" },
  { key: "NM_013237.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27166", geneDisplay: "PRELID1" },
  { key: "NM_013238.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "29103", geneDisplay: "DNAJC15" },
  { key: "NM_013240.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "29104", geneDisplay: "N6AMT1" },
  { key: "NM_013241.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29109", geneDisplay: "FHOD1" },
  { key: "NM_013242.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29105", geneDisplay: "CFAP20" },
  { key: "NM_013243.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "29106", geneDisplay: "SCG3" },
  { key: "NM_013245.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27183", geneDisplay: "VPS4A" },
  { key: "NM_013246.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23529", geneDisplay: "CLCF1" },
  { key: "NM_013247.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27429", geneDisplay: "HTRA2" },
  { key: "NM_013248.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "29107", geneDisplay: "NXT1" },
  { key: "NM_013249.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7761", geneDisplay: "ZNF214" },
  { key: "NM_013250.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7762", geneDisplay: "ZNF215" },
  { key: "NM_013251.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6866", geneDisplay: "TAC3" },
  { key: "NM_013252.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23601", geneDisplay: "CLEC5A" },
  { key: "NM_013254.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29110", geneDisplay: "TBK1" },
  { key: "NM_013255.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4289", geneDisplay: "MKLN1" },
  { key: "NM_013258.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29108", geneDisplay: "PYCARD" },
  { key: "NM_013260.8", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29115", geneDisplay: "SAP30BP" },
  { key: "NM_013261.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10891", geneDisplay: "PPARGC1A" },
  { key: "NM_013262.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29116", geneDisplay: "MYLIP" },
  { key: "NM_013263.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29117", geneDisplay: "BRD7" },
  { key: "NM_013264.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29118", geneDisplay: "DDX25" },
  { key: "NM_013265.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "738", geneDisplay: "VPS51" },
  { key: "NM_013266.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "29119", geneDisplay: "CTNNA3" },
  { key: "NM_013267.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27165", geneDisplay: "GLS2" },
  { key: "NM_013268.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29124", geneDisplay: "LGALS13" },
  { key: "NM_013269.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29121", geneDisplay: "CLEC2D" },
  { key: "NM_013270.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29122", geneDisplay: "PRSS50" },
  { key: "NM_013271.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27344", geneDisplay: "PCSK1N" },
  { key: "NM_013272.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "28232", geneDisplay: "SLCO3A1" },
  { key: "NM_013275.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29123", geneDisplay: "ANKRD11" },
  { key: "NM_013276.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23729", geneDisplay: "SHPK" },
  { key: "NM_013278.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27189", geneDisplay: "IL17C" },
  { key: "NM_013280.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23769", geneDisplay: "FLRT1" },
  { key: "NM_013283.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27430", geneDisplay: "MAT2B" },
  { key: "NM_013284.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27434", geneDisplay: "POLM" },
  { key: "NM_013285.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29889", geneDisplay: "GNL2" },
  { key: "NM_013286.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29890", geneDisplay: "RBM15B" },
  { key: "NM_013291.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29894", geneDisplay: "CPSF1" },
  { key: "NM_013292.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29895", geneDisplay: "MYLPF" },
  { key: "NM_013293.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29896", geneDisplay: "TRA2A" },
  { key: "NM_013296.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29899", geneDisplay: "GPSM2" },
  { key: "NM_013299.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29901", geneDisplay: "SAC3D1" },
  { key: "NM_013300.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29902", geneDisplay: "FAM216A" },
  { key: "NM_013302.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29904", geneDisplay: "EEF2K" },
  { key: "NM_013305.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "29906", geneDisplay: "ST8SIA5" },
  { key: "NM_013306.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29907", geneDisplay: "SNX15" },
  { key: "NM_013308.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29909", geneDisplay: "GPR171" },
  { key: "NM_013309.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7782", geneDisplay: "SLC30A4" },
  { key: "NM_013312.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29911", geneDisplay: "HOOK2" },
  { key: "NM_013313.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29799", geneDisplay: "YPEL1" },
  { key: "NM_013314.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "29760", geneDisplay: "BLNK" },
  { key: "NM_013318.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84726", geneDisplay: "PRRC2B" },
  { key: "NM_013319.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29914", geneDisplay: "UBIAD1" },
  { key: "NM_013320.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29915", geneDisplay: "HCFC2" },
  { key: "NM_013321.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29886", geneDisplay: "SNX8" },
  { key: "NM_013322.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29887", geneDisplay: "SNX10" },
  { key: "NM_013323.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29916", geneDisplay: "SNX11" },
  { key: "NM_013325.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23192", geneDisplay: "ATG4B" },
  { key: "NM_013326.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "29919", geneDisplay: "RMC1" },
  { key: "NM_013327.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29780", geneDisplay: "PARVB" },
  { key: "NM_013328.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29920", geneDisplay: "PYCR2" },
  { key: "NM_013330.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29922", geneDisplay: "NME7" },
  { key: "NM_013332.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29923", geneDisplay: "HILPDA" },
  { key: "NM_013335.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29926", geneDisplay: "GMPPA" },
  { key: "NM_013336.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29927", geneDisplay: "SEC61A1" },
  { key: "NM_013337.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29928", geneDisplay: "TIMM22" },
  { key: "NM_013338.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "29880", geneDisplay: "ALG5" },
  { key: "NM_013339.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29929", geneDisplay: "ALG6" },
  { key: "NM_013340.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "29930", geneDisplay: "PCDHB1" },
  { key: "NM_013341.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29789", geneDisplay: "OLA1" },
  { key: "NM_013342.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29844", geneDisplay: "TFPT" },
  { key: "NM_013345.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29933", geneDisplay: "GPR132" },
  { key: "NM_013346.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29934", geneDisplay: "SNX12" },
  { key: "NM_013347.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29935", geneDisplay: "RPA4" },
  { key: "NM_013348.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3770", geneDisplay: "KCNJ14" },
  { key: "NM_013349.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29937", geneDisplay: "NENF" },
  { key: "NM_013351.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "30009", geneDisplay: "TBX21" },
  { key: "NM_013352.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29940", geneDisplay: "DSE" },
  { key: "NM_013353.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29765", geneDisplay: "TMOD4" },
  { key: "NM_013354.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29883", geneDisplay: "CNOT7" },
  { key: "NM_013355.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29941", geneDisplay: "PKN3" },
  { key: "NM_013356.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23539", geneDisplay: "SLC16A8" },
  { key: "NM_013358.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29943", geneDisplay: "PADI1" },
  { key: "NM_013361.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7766", geneDisplay: "ZNF223" },
  { key: "NM_013362.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7768", geneDisplay: "ZNF225" },
  { key: "NM_013363.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26577", geneDisplay: "PCOLCE2" },
  { key: "NM_013364.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29944", geneDisplay: "PNMA3" },
  { key: "NM_013365.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "26088", geneDisplay: "GGA1" },
  { key: "NM_013366.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29882", geneDisplay: "ANAPC2" },
  { key: "NM_013367.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "29945", geneDisplay: "ANAPC4" },
  { key: "NM_013372.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26585", geneDisplay: "GREM1" },
  { key: "NM_013373.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29801", geneDisplay: "ZDHHC8" },
  { key: "NM_013374.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10015", geneDisplay: "PDCD6IP" },
  { key: "NM_013375.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29777", geneDisplay: "ABT1" },
  { key: "NM_013376.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29950", geneDisplay: "SERTAD1" },
  { key: "NM_013378.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29802", geneDisplay: "VPREB3" },
  { key: "NM_013379.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29952", geneDisplay: "DPP7" },
  { key: "NM_013380.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7771", geneDisplay: "ZNF112" },
  { key: "NM_013381.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29953", geneDisplay: "TRHDE" },
  { key: "NM_013382.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29954", geneDisplay: "POMT2" },
  { key: "NM_013385.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27128", geneDisplay: "CYTH4" },
  { key: "NM_013386.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29957", geneDisplay: "SLC25A24" },
  { key: "NM_013387.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29796", geneDisplay: "UQCR10" },
  { key: "NM_013388.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10113", geneDisplay: "PREB" },
  { key: "NM_013390.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23670", geneDisplay: "CEMIP2" },
  { key: "NM_013391.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "29958", geneDisplay: "DMGDH" },
  { key: "NM_013392.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29959", geneDisplay: "NRBP1" },
  { key: "NM_013393.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29960", geneDisplay: "MRM2" },
  { key: "NM_013397.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29964", geneDisplay: "PRICKLE4" },
  { key: "NM_013399.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29965", geneDisplay: "CDIP1" },
  { key: "NM_013401.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5866", geneDisplay: "RAB3IL1" },
  { key: "NM_013402.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3992", geneDisplay: "FADS1" },
  { key: "NM_013403.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29888", geneDisplay: "STRN4" },
  { key: "NM_013409.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10468", geneDisplay: "FST" },
  { key: "NM_013410.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "205", geneDisplay: "AK4" },
  { key: "NM_013427.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "395", geneDisplay: "ARHGAP6" },
  { key: "NM_013431.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8302", geneDisplay: "KLRC4" },
  { key: "NM_013432.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4796", geneDisplay: "TONSL" },
  { key: "NM_013434.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "30818", geneDisplay: "KCNIP3" },
  { key: "NM_013435.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "30062", geneDisplay: "RAX" },
  { key: "NM_013436.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10787", geneDisplay: "NCKAP1" },
  { key: "NM_013437.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29967", geneDisplay: "LRP12" },
  { key: "NM_013438.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29979", geneDisplay: "UBQLN1" },
  { key: "NM_013439.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29992", geneDisplay: "PILRA" },
  { key: "NM_013441.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11123", geneDisplay: "RCAN3" },
  { key: "NM_013442.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "30968", geneDisplay: "STOML2" },
  { key: "NM_013443.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "30815", geneDisplay: "ST6GALNAC6" },
  { key: "NM_013444.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29978", geneDisplay: "UBQLN2" },
  { key: "NM_013446.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23608", geneDisplay: "MKRN1" },
  { key: "NM_013447.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "30817", geneDisplay: "ADGRE2" },
  { key: "NM_013448.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11177", geneDisplay: "BAZ1A" },
  { key: "NM_013450.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29994", geneDisplay: "BAZ2B" },
  { key: "NM_013451.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26509", geneDisplay: "MYOF" },
  { key: "NM_013453.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "30014", geneDisplay: "SPANXA1" },
  { key: "NM_013936.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26529", geneDisplay: "OR12D2" },
  { key: "NM_013938.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26532", geneDisplay: "OR10H3" },
  { key: "NM_013939.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26538", geneDisplay: "OR10H2" },
  { key: "NM_013940.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26539", geneDisplay: "OR10H1" },
  { key: "NM_013941.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442194", geneDisplay: "OR10C1" },
  { key: "NM_013943.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25932", geneDisplay: "CLIC4" },
  { key: "NM_013964.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3084", geneDisplay: "NRG1" },
  { key: "NM_013975.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3980", geneDisplay: "LIG3" },
  { key: "NM_013989.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1734", geneDisplay: "DIO2" },
  { key: "NM_014000.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7414", geneDisplay: "VCL" },
  { key: "NM_014002.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9641", geneDisplay: "IKBKE" },
  { key: "NM_014003.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9785", geneDisplay: "DHX38" },
  { key: "NM_014007.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23099", geneDisplay: "ZBTB43" },
  { key: "NM_014008.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "28952", geneDisplay: "CCDC22" },
  { key: "NM_014009.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "50943", geneDisplay: "FOXP3" },
  { key: "NM_014012.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "28954", geneDisplay: "REM1" },
  { key: "NM_014014.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23020", geneDisplay: "SNRNP200" },
  { key: "NM_014015.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "28955", geneDisplay: "DEXI" },
  { key: "NM_014016.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22908", geneDisplay: "SACM1L" },
  { key: "NM_014017.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "28956", geneDisplay: "LAMTOR2" },
  { key: "NM_014018.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "28957", geneDisplay: "MRPS28" },
  { key: "NM_014023.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22884", geneDisplay: "WDR37" },
  { key: "NM_014026.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "28960", geneDisplay: "DCPS" },
  { key: "NM_014028.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "28962", geneDisplay: "OSTM1" },
  { key: "NM_014030.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "28964", geneDisplay: "GIT1" },
  { key: "NM_014033.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25840", geneDisplay: "METTL7A" },
  { key: "NM_014034.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25842", geneDisplay: "ASF1A" },
  { key: "NM_014035.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "28966", geneDisplay: "SNX24" },
  { key: "NM_014037.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "28968", geneDisplay: "SLC6A16" },
  { key: "NM_014038.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "28969", geneDisplay: "BZW2" },
  { key: "NM_014041.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "28972", geneDisplay: "SPCS1" },
  { key: "NM_014042.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25906", geneDisplay: "ANAPC15" },
  { key: "NM_014043.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25978", geneDisplay: "CHMP2B" },
  { key: "NM_014044.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25972", geneDisplay: "UNC50" },
  { key: "NM_014045.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26020", geneDisplay: "LRP10" },
  { key: "NM_014046.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "28973", geneDisplay: "MRPS18B" },
  { key: "NM_014047.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "28974", geneDisplay: "C19orf53" },
  { key: "NM_014049.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "28976", geneDisplay: "ACAD9" },
  { key: "NM_014050.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "28977", geneDisplay: "MRPL42" },
  { key: "NM_014051.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "28978", geneDisplay: "TMEM14A" },
  { key: "NM_014053.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "28982", geneDisplay: "FLVCR1" },
  { key: "NM_014055.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "28981", geneDisplay: "IFT81" },
  { key: "NM_014056.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25994", geneDisplay: "HIGD1A" },
  { key: "NM_014057.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4969", geneDisplay: "OGN" },
  { key: "NM_014058.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "28983", geneDisplay: "TMPRSS11E" },
  { key: "NM_014059.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "28984", geneDisplay: "RGCC" },
  { key: "NM_014060.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "28985", geneDisplay: "MCTS1" },
  { key: "NM_014061.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "28986", geneDisplay: "MAGEH1" },
  { key: "NM_014062.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "28987", geneDisplay: "NOB1" },
  { key: "NM_014064.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "28989", geneDisplay: "NTMT1" },
  { key: "NM_014065.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "28990", geneDisplay: "ASTE1" },
  { key: "NM_014066.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "28991", geneDisplay: "COMMD5" },
  { key: "NM_014067.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "28992", geneDisplay: "MACROD1" },
  { key: "NM_014068.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "170679", geneDisplay: "PSORS1C1" },
  { key: "NM_014069.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "170680", geneDisplay: "PSORS1C2" },
  { key: "NM_014070.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29113", geneDisplay: "C6orf15" },
  { key: "NM_014071.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23054", geneDisplay: "NCOA6" },
  { key: "NM_014077.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26017", geneDisplay: "FAM32A" },
  { key: "NM_014078.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "28998", geneDisplay: "MRPL13" },
  { key: "NM_014079.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "28999", geneDisplay: "KLF15" },
  { key: "NM_014089.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9818", geneDisplay: "NUP58" },
  { key: "NM_014106.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54989", geneDisplay: "ZNF770" },
  { key: "NM_014109.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29028", geneDisplay: "ATAD2" },
  { key: "NM_014110.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5511", geneDisplay: "PPP1R8" },
  { key: "NM_014112.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7227", geneDisplay: "TRPS1" },
  { key: "NM_014117.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29035", geneDisplay: "C16orf72" },
  { key: "NM_014140.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50485", geneDisplay: "SMARCAL1" },
  { key: "NM_014141.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26047", geneDisplay: "CNTNAP2" },
  { key: "NM_014142.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11164", geneDisplay: "NUDT5" },
  { key: "NM_014143.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29126", geneDisplay: "CD274" },
  { key: "NM_014149.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29062", geneDisplay: "WDR91" },
  { key: "NM_014153.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29066", geneDisplay: "ZC3H7A" },
  { key: "NM_014157.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29070", geneDisplay: "CCDC113" },
  { key: "NM_014159.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29072", geneDisplay: "SETD2" },
  { key: "NM_014160.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23609", geneDisplay: "MKRN2" },
  { key: "NM_014161.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29074", geneDisplay: "MRPL18" },
  { key: "NM_014164.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53827", geneDisplay: "FXYD5" },
  { key: "NM_014165.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29078", geneDisplay: "NDUFAF4" },
  { key: "NM_014166.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "29079", geneDisplay: "MED4" },
  { key: "NM_014167.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29080", geneDisplay: "CCDC59" },
  { key: "NM_014168.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29081", geneDisplay: "METTL5" },
  { key: "NM_014169.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29082", geneDisplay: "CHMP4A" },
  { key: "NM_014170.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29083", geneDisplay: "GTPBP8" },
  { key: "NM_014171.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9419", geneDisplay: "CRIPT" },
  { key: "NM_014172.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29085", geneDisplay: "PHPT1" },
  { key: "NM_014173.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29086", geneDisplay: "BABAM1" },
  { key: "NM_014174.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29087", geneDisplay: "THYN1" },
  { key: "NM_014175.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29088", geneDisplay: "MRPL15" },
  { key: "NM_014176.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29089", geneDisplay: "UBE2T" },
  { key: "NM_014177.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "29090", geneDisplay: "TIMM21" },
  { key: "NM_014180.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "29093", geneDisplay: "MRPL22" },
  { key: "NM_014181.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29094", geneDisplay: "LGALSL" },
  { key: "NM_014182.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29095", geneDisplay: "ORMDL2" },
  { key: "NM_014183.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "83658", geneDisplay: "DYNLRB1" },
  { key: "NM_014184.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29097", geneDisplay: "CNIH4" },
  { key: "NM_014186.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29099", geneDisplay: "COMMD9" },
  { key: "NM_014187.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29100", geneDisplay: "TMEM208" },
  { key: "NM_014188.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29101", geneDisplay: "SSU72" },
  { key: "NM_014205.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "741", geneDisplay: "ZNHIT2" },
  { key: "NM_014206.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "746", geneDisplay: "TMEM258" },
  { key: "NM_014207.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "921", geneDisplay: "CD5" },
  { key: "NM_014208.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1834", geneDisplay: "DSPP" },
  { key: "NM_014209.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2116", geneDisplay: "ETV2" },
  { key: "NM_014210.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2123", geneDisplay: "EVI2A" },
  { key: "NM_014211.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2568", geneDisplay: "GABRP" },
  { key: "NM_014212.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3227", geneDisplay: "HOXC11" },
  { key: "NM_014213.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3235", geneDisplay: "HOXD9" },
  { key: "NM_014214.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "3613", geneDisplay: "IMPA2" },
  { key: "NM_014215.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3645", geneDisplay: "INSRR" },
  { key: "NM_014216.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3705", geneDisplay: "ITPK1" },
  { key: "NM_014218.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3802", geneDisplay: "KIR2DL1" },
  { key: "NM_014220.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4071", geneDisplay: "TM4SF1" },
  { key: "NM_014222.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4702", geneDisplay: "NDUFA8" },
  { key: "NM_014223.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4802", geneDisplay: "NFYC" },
  { key: "NM_014224.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5222", geneDisplay: "PGA5" },
  { key: "NM_014225.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5518", geneDisplay: "PPP2R1A" },
  { key: "NM_014226.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5891", geneDisplay: "MOK" },
  { key: "NM_014227.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6527", geneDisplay: "SLC5A4" },
  { key: "NM_014228.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6534", geneDisplay: "SLC6A7" },
  { key: "NM_014229.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6538", geneDisplay: "SLC6A11" },
  { key: "NM_014230.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6730", geneDisplay: "SRP68" },
  { key: "NM_014231.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6843", geneDisplay: "VAMP1" },
  { key: "NM_014232.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6844", geneDisplay: "VAMP2" },
  { key: "NM_014233.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7343", geneDisplay: "UBTF" },
  { key: "NM_014234.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7923", geneDisplay: "HSD17B8" },
  { key: "NM_014235.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8266", geneDisplay: "UBL4A" },
  { key: "NM_014236.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8443", geneDisplay: "GNPAT" },
  { key: "NM_014237.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8749", geneDisplay: "ADAM18" },
  { key: "NM_014239.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8892", geneDisplay: "EIF2B2" },
  { key: "NM_014240.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8994", geneDisplay: "LIMD1" },
  { key: "NM_014241.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9200", geneDisplay: "HACD1" },
  { key: "NM_014243.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9508", geneDisplay: "ADAMTS3" },
  { key: "NM_014244.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9509", geneDisplay: "ADAMTS2" },
  { key: "NM_014245.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9616", geneDisplay: "RNF7" },
  { key: "NM_014248.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9978", geneDisplay: "RBX1" },
  { key: "NM_014249.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10002", geneDisplay: "NR2E3" },
  { key: "NM_014251.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10165", geneDisplay: "SLC25A13" },
  { key: "NM_014252.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10166", geneDisplay: "SLC25A15" },
  { key: "NM_014254.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10329", geneDisplay: "RXYLT1" },
  { key: "NM_014255.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10330", geneDisplay: "CNPY2" },
  { key: "NM_014256.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10331", geneDisplay: "B3GNT3" },
  { key: "NM_014257.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10332", geneDisplay: "CLEC4M" },
  { key: "NM_014258.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10388", geneDisplay: "SYCP2" },
  { key: "NM_014262.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10536", geneDisplay: "P3H3" },
  { key: "NM_014263.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10730", geneDisplay: "YME1L1" },
  { key: "NM_014264.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10733", geneDisplay: "PLK4" },
  { key: "NM_014265.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10863", geneDisplay: "ADAM28" },
  { key: "NM_014266.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10870", geneDisplay: "HCST" },
  { key: "NM_014267.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10944", geneDisplay: "C11orf58" },
  { key: "NM_014268.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10982", geneDisplay: "MAPRE2" },
  { key: "NM_014269.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11086", geneDisplay: "ADAM29" },
  { key: "NM_014270.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11136", geneDisplay: "SLC7A9" },
  { key: "NM_014271.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11141", geneDisplay: "IL1RAPL1" },
  { key: "NM_014272.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11173", geneDisplay: "ADAMTS7" },
  { key: "NM_014275.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11282", geneDisplay: "MGAT4B" },
  { key: "NM_014276.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11317", geneDisplay: "RBPJL" },
  { key: "NM_014278.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "22824", geneDisplay: "HSPA4L" },
  { key: "NM_014280.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22826", geneDisplay: "DNAJC8" },
  { key: "NM_014282.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22927", geneDisplay: "HABP4" },
  { key: "NM_014283.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51430", geneDisplay: "SUCO" },
  { key: "NM_014284.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23154", geneDisplay: "NCDN" },
  { key: "NM_014285.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23404", geneDisplay: "EXOSC2" },
  { key: "NM_014286.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23413", geneDisplay: "NCS1" },
  { key: "NM_014287.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23420", geneDisplay: "NOMO1" },
  { key: "NM_014288.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23421", geneDisplay: "ITGB3BP" },
  { key: "NM_014289.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "827", geneDisplay: "CAPN6" },
  { key: "NM_014290.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23424", geneDisplay: "TDRD7" },
  { key: "NM_014291.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23464", geneDisplay: "GCAT" },
  { key: "NM_014292.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23466", geneDisplay: "CBX6" },
  { key: "NM_014293.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23467", geneDisplay: "NPTXR" },
  { key: "NM_014294.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23471", geneDisplay: "TRAM1" },
  { key: "NM_014296.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23473", geneDisplay: "CAPN7" },
  { key: "NM_014297.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23474", geneDisplay: "ETHE1" },
  { key: "NM_014298.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23475", geneDisplay: "QPRT" },
  { key: "NM_014300.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23478", geneDisplay: "SEC11A" },
  { key: "NM_014302.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23480", geneDisplay: "SEC61G" },
  { key: "NM_014303.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23481", geneDisplay: "PES1" },
  { key: "NM_014305.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23483", geneDisplay: "TGDS" },
  { key: "NM_014306.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51493", geneDisplay: "RTCB" },
  { key: "NM_014310.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23551", geneDisplay: "RASD2" },
  { key: "NM_014312.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23584", geneDisplay: "VSIG2" },
  { key: "NM_014313.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23585", geneDisplay: "TMEM50A" },
  { key: "NM_014314.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23586", geneDisplay: "DDX58" },
  { key: "NM_014315.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23588", geneDisplay: "KLHDC2" },
  { key: "NM_014316.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23589", geneDisplay: "CARHSP1" },
  { key: "NM_014317.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23590", geneDisplay: "PDSS1" },
  { key: "NM_014319.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23592", geneDisplay: "LEMD3" },
  { key: "NM_014320.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23593", geneDisplay: "HEBP2" },
  { key: "NM_014321.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23594", geneDisplay: "ORC6" },
  { key: "NM_014322.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23596", geneDisplay: "OPN3" },
  { key: "NM_014323.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23598", geneDisplay: "PATZ1" },
  { key: "NM_014324.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23600", geneDisplay: "AMACR" },
  { key: "NM_014325.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23603", geneDisplay: "CORO1C" },
  { key: "NM_014326.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23604", geneDisplay: "DAPK2" },
  { key: "NM_014329.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23644", geneDisplay: "EDC4" },
  { key: "NM_014330.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23645", geneDisplay: "PPP1R15A" },
  { key: "NM_014331.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23657", geneDisplay: "SLC7A11" },
  { key: "NM_014332.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23676", geneDisplay: "SMPX" },
  { key: "NM_014334.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23732", geneDisplay: "FRRS1L" },
  { key: "NM_014335.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23741", geneDisplay: "EID1" },
  { key: "NM_014336.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23746", geneDisplay: "AIPL1" },
  { key: "NM_014337.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23759", geneDisplay: "PPIL2" },
  { key: "NM_014339.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23765", geneDisplay: "IL17RA" },
  { key: "NM_014342.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23788", geneDisplay: "MTCH2" },
  { key: "NM_014343.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "24146", geneDisplay: "CLDN15" },
  { key: "NM_014344.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "24147", geneDisplay: "FJX1" },
  { key: "NM_014345.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "24149", geneDisplay: "ZNF318" },
  { key: "NM_014346.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25771", geneDisplay: "TBC1D22A" },
  { key: "NM_014347.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25799", geneDisplay: "ZNF324" },
  { key: "NM_014350.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "25816", geneDisplay: "TNFAIP8" },
  { key: "NM_014351.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25830", geneDisplay: "SULT4A1" },
  { key: "NM_014352.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25833", geneDisplay: "POU2F3" },
  { key: "NM_014353.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25837", geneDisplay: "RAB26" },
  { key: "NM_014357.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26239", geneDisplay: "LCE2B" },
  { key: "NM_014358.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "26253", geneDisplay: "CLEC4E" },
  { key: "NM_014359.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26254", geneDisplay: "OPTC" },
  { key: "NM_014360.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26257", geneDisplay: "NKX2-8" },
  { key: "NM_014361.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53942", geneDisplay: "CNTN5" },
  { key: "NM_014362.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26275", geneDisplay: "HIBCH" },
  { key: "NM_014363.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26278", geneDisplay: "SACS" },
  { key: "NM_014364.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26330", geneDisplay: "GAPDHS" },
  { key: "NM_014365.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "26353", geneDisplay: "HSPB8" },
  { key: "NM_014366.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26354", geneDisplay: "GNL3" },
  { key: "NM_014367.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26355", geneDisplay: "FAM162A" },
  { key: "NM_014368.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26468", geneDisplay: "LHX6" },
  { key: "NM_014369.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26469", geneDisplay: "PTPN18" },
  { key: "NM_014370.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "26576", geneDisplay: "SRPK3" },
  { key: "NM_014371.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26993", geneDisplay: "AKAP8L" },
  { key: "NM_014372.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26994", geneDisplay: "RNF11" },
  { key: "NM_014373.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26996", geneDisplay: "GPR160" },
  { key: "NM_014375.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26998", geneDisplay: "FETUB" },
  { key: "NM_014377.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27000", geneDisplay: "DNAJC2" },
  { key: "NM_014379.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27012", geneDisplay: "KCNV1" },
  { key: "NM_014383.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27033", geneDisplay: "ZBTB32" },
  { key: "NM_014384.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27034", geneDisplay: "ACAD8" },
  { key: "NM_014385.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27036", geneDisplay: "SIGLEC7" },
  { key: "NM_014388.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27042", geneDisplay: "UTP25" },
  { key: "NM_014389.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27043", geneDisplay: "PELP1" },
  { key: "NM_014390.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27044", geneDisplay: "SND1" },
  { key: "NM_014391.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27063", geneDisplay: "ANKRD1" },
  { key: "NM_014392.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27065", geneDisplay: "NSG1" },
  { key: "NM_014394.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27069", geneDisplay: "GHITM" },
  { key: "NM_014395.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27071", geneDisplay: "DAPP1" },
  { key: "NM_014396.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27072", geneDisplay: "VPS41" },
  { key: "NM_014397.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10783", geneDisplay: "NEK6" },
  { key: "NM_014398.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27074", geneDisplay: "LAMP3" },
  { key: "NM_014399.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27075", geneDisplay: "TSPAN13" },
  { key: "NM_014400.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27076", geneDisplay: "LYPD3" },
  { key: "NM_014402.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27089", geneDisplay: "UQCRQ" },
  { key: "NM_014405.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27092", geneDisplay: "CACNG4" },
  { key: "NM_014406.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150160", geneDisplay: "CCT8L2" },
  { key: "NM_014408.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27095", geneDisplay: "TRAPPC3" },
  { key: "NM_014409.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27097", geneDisplay: "TAF5L" },
  { key: "NM_014412.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27101", geneDisplay: "CACYBP" },
  { key: "NM_014413.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27102", geneDisplay: "EIF2AK1" },
  { key: "NM_014415.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27107", geneDisplay: "ZBTB11" },
  { key: "NM_014417.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27113", geneDisplay: "BBC3" },
  { key: "NM_014419.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27120", geneDisplay: "DKKL1" },
  { key: "NM_014420.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27121", geneDisplay: "DKK4" },
  { key: "NM_014421.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27123", geneDisplay: "DKK2" },
  { key: "NM_014423.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27125", geneDisplay: "AFF4" },
  { key: "NM_014424.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27129", geneDisplay: "HSPB7" },
  { key: "NM_014425.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27130", geneDisplay: "INVS" },
  { key: "NM_014426.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "27131", geneDisplay: "SNX5" },
  { key: "NM_014429.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27136", geneDisplay: "MORC1" },
  { key: "NM_014431.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27143", geneDisplay: "PALD1" },
  { key: "NM_014432.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "53832", geneDisplay: "IL20RA" },
  { key: "NM_014433.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27156", geneDisplay: "RSPH14" },
  { key: "NM_014434.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27158", geneDisplay: "NDOR1" },
  { key: "NM_014435.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27163", geneDisplay: "NAAA" },
  { key: "NM_014438.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27177", geneDisplay: "IL36B" },
  { key: "NM_014439.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27178", geneDisplay: "IL37" },
  { key: "NM_014440.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27179", geneDisplay: "IL36A" },
  { key: "NM_014441.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27180", geneDisplay: "SIGLEC9" },
  { key: "NM_014442.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27181", geneDisplay: "SIGLEC8" },
  { key: "NM_014443.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27190", geneDisplay: "IL17B" },
  { key: "NM_014444.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "27229", geneDisplay: "TUBGCP4" },
  { key: "NM_014445.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27230", geneDisplay: "SERP1" },
  { key: "NM_014448.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27237", geneDisplay: "ARHGEF16" },
  { key: "NM_014450.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27240", geneDisplay: "SIT1" },
  { key: "NM_014452.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27242", geneDisplay: "TNFRSF21" },
  { key: "NM_014453.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27243", geneDisplay: "CHMP2A" },
  { key: "NM_014454.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27244", geneDisplay: "SESN1" },
  { key: "NM_014455.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27246", geneDisplay: "RNF115" },
  { key: "NM_014456.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27250", geneDisplay: "PDCD4" },
  { key: "NM_014458.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27252", geneDisplay: "KLHL20" },
  { key: "NM_014460.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27254", geneDisplay: "CSDC2" },
  { key: "NM_014462.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27257", geneDisplay: "LSM1" },
  { key: "NM_014463.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27258", geneDisplay: "LSM3" },
  { key: "NM_014464.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27283", geneDisplay: "TINAG" },
  { key: "NM_014465.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27284", geneDisplay: "SULT1B1" },
  { key: "NM_014466.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27285", geneDisplay: "TEKT2" },
  { key: "NM_014467.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27286", geneDisplay: "SRPX2" },
  { key: "NM_014468.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27287", geneDisplay: "VENTX" },
  { key: "NM_014469.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27288", geneDisplay: "RBMXL2" },
  { key: "NM_014470.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27289", geneDisplay: "RND1" },
  { key: "NM_014471.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27290", geneDisplay: "SPINK4" },
  { key: "NM_014473.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27292", geneDisplay: "DIMT1" },
  { key: "NM_014474.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27293", geneDisplay: "SMPDL3B" },
  { key: "NM_014475.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27294", geneDisplay: "DHDH" },
  { key: "NM_014476.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27295", geneDisplay: "PDLIM3" },
  { key: "NM_014477.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "27296", geneDisplay: "TP53TG5" },
  { key: "NM_014478.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27297", geneDisplay: "CRCP" },
  { key: "NM_014479.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27299", geneDisplay: "ADAMDEC1" },
  { key: "NM_014480.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27300", geneDisplay: "ZNF544" },
  { key: "NM_014481.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27301", geneDisplay: "APEX2" },
  { key: "NM_014482.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27302", geneDisplay: "BMP10" },
  { key: "NM_014484.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "27304", geneDisplay: "MOCS3" },
  { key: "NM_014485.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27306", geneDisplay: "HPGDS" },
  { key: "NM_014487.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27309", geneDisplay: "ZNF330" },
  { key: "NM_014489.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27315", geneDisplay: "PGAP2" },
  { key: "NM_014491.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "93986", geneDisplay: "FOXP2" },
  { key: "NM_014494.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27327", geneDisplay: "TNRC6A" },
  { key: "NM_014495.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27329", geneDisplay: "ANGPTL3" },
  { key: "NM_014496.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27330", geneDisplay: "RPS6KA6" },
  { key: "NM_014497.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27332", geneDisplay: "ZNF638" },
  { key: "NM_014498.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27333", geneDisplay: "GOLIM4" },
  { key: "NM_014499.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27334", geneDisplay: "P2RY10" },
  { key: "NM_014500.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27336", geneDisplay: "HTATSF1" },
  { key: "NM_014501.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27338", geneDisplay: "UBE2S" },
  { key: "NM_014502.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27339", geneDisplay: "PRPF19" },
  { key: "NM_014503.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27340", geneDisplay: "UTP20" },
  { key: "NM_014504.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27342", geneDisplay: "RABGEF1" },
  { key: "NM_014505.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27345", geneDisplay: "KCNMB4" },
  { key: "NM_014506.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27348", geneDisplay: "TOR1B" },
  { key: "NM_014508.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27350", geneDisplay: "APOBEC3C" },
  { key: "NM_014509.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "253190", geneDisplay: "SERHL2" },
  { key: "NM_014515.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4848", geneDisplay: "CNOT2" },
  { key: "NM_014516.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4849", geneDisplay: "CNOT3" },
  { key: "NM_014517.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7342", geneDisplay: "UBP1" },
  { key: "NM_014518.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7772", geneDisplay: "ZNF229" },
  { key: "NM_014519.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7775", geneDisplay: "ZNF232" },
  { key: "NM_014520.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10514", geneDisplay: "MYBBP1A" },
  { key: "NM_014521.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23677", geneDisplay: "SH3BP4" },
  { key: "NM_014547.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "29766", geneDisplay: "TMOD3" },
  { key: "NM_014548.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "29767", geneDisplay: "TMOD2" },
  { key: "NM_014550.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29775", geneDisplay: "CARD10" },
  { key: "NM_014553.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29842", geneDisplay: "TFCP2L1" },
  { key: "NM_014555.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29850", geneDisplay: "TRPM5" },
  { key: "NM_014562.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5013", geneDisplay: "OTX1" },
  { key: "NM_014565.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8383", geneDisplay: "OR1A1" },
  { key: "NM_014566.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8386", geneDisplay: "OR1D5" },
  { key: "NM_014567.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9564", geneDisplay: "BCAR1" },
  { key: "NM_014568.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11227", geneDisplay: "GALNT5" },
  { key: "NM_014570.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "26286", geneDisplay: "ARFGAP3" },
  { key: "NM_014571.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26508", geneDisplay: "HEYL" },
  { key: "NM_014572.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26524", geneDisplay: "LATS2" },
  { key: "NM_014573.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27346", geneDisplay: "TMEM97" },
  { key: "NM_014575.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29970", geneDisplay: "SCHIP1" },
  { key: "NM_014576.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "29974", geneDisplay: "A1CF" },
  { key: "NM_014578.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29984", geneDisplay: "RHOD" },
  { key: "NM_014579.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29986", geneDisplay: "SLC39A2" },
  { key: "NM_014580.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29988", geneDisplay: "SLC2A8" },
  { key: "NM_014581.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29989", geneDisplay: "OBP2B" },
  { key: "NM_014582.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29991", geneDisplay: "OBP2A" },
  { key: "NM_014583.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29995", geneDisplay: "LMCD1" },
  { key: "NM_014584.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "30001", geneDisplay: "ERO1A" },
  { key: "NM_014585.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "30061", geneDisplay: "SLC40A1" },
  { key: "NM_014586.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "30811", geneDisplay: "HUNK" },
  { key: "NM_014587.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "30812", geneDisplay: "SOX8" },
  { key: "NM_014588.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "30813", geneDisplay: "VSX1" },
  { key: "NM_014589.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "30814", geneDisplay: "PLA2G2E" },
  { key: "NM_014592.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "30820", geneDisplay: "KCNIP1" },
  { key: "NM_014593.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "30827", geneDisplay: "CXXC1" },
  { key: "NM_014594.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "30832", geneDisplay: "ZNF354C" },
  { key: "NM_014595.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "30833", geneDisplay: "NT5C" },
  { key: "NM_014597.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "30836", geneDisplay: "DNTTIP2" },
  { key: "NM_014598.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "30837", geneDisplay: "SOCS7" },
  { key: "NM_014600.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "30845", geneDisplay: "EHD3" },
  { key: "NM_014601.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "30846", geneDisplay: "EHD2" },
  { key: "NM_014602.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "30849", geneDisplay: "PIK3R4" },
  { key: "NM_014603.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "30850", geneDisplay: "CDR2L" },
  { key: "NM_014604.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "30851", geneDisplay: "TAX1BP3" },
  { key: "NM_014606.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8916", geneDisplay: "HERC3" },
  { key: "NM_014607.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23190", geneDisplay: "UBXN4" },
  { key: "NM_014608.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23191", geneDisplay: "CYFIP1" },
  { key: "NM_014611.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23195", geneDisplay: "MDN1" },
  { key: "NM_014612.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23196", geneDisplay: "FAM120A" },
  { key: "NM_014613.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23197", geneDisplay: "FAF2" },
  { key: "NM_014614.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23198", geneDisplay: "PSME4" },
  { key: "NM_014615.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23199", geneDisplay: "GSE1" },
  { key: "NM_014616.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23200", geneDisplay: "ATP11B" },
  { key: "NM_014617.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1418", geneDisplay: "CRYGA" },
  { key: "NM_014618.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1620", geneDisplay: "BRINP1" },
  { key: "NM_014619.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2900", geneDisplay: "GRIK4" },
  { key: "NM_014621.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3233", geneDisplay: "HOXD4" },
  { key: "NM_014623.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4201", geneDisplay: "MEA1" },
  { key: "NM_014624.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6277", geneDisplay: "S100A6" },
  { key: "NM_014625.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7827", geneDisplay: "NPHS2" },
  { key: "NM_014628.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9587", geneDisplay: "MAD2L1BP" },
  { key: "NM_014629.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9639", geneDisplay: "ARHGEF10" },
  { key: "NM_014630.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9640", geneDisplay: "ZNF592" },
  { key: "NM_014633.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9646", geneDisplay: "CTR9" },
  { key: "NM_014634.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9647", geneDisplay: "PPM1F" },
  { key: "NM_014636.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9649", geneDisplay: "RALGPS1" },
  { key: "NM_014637.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9650", geneDisplay: "MTFR1" },
  { key: "NM_014638.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9651", geneDisplay: "PLCH2" },
  { key: "NM_014639.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9652", geneDisplay: "TTC37" },
  { key: "NM_014640.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9654", geneDisplay: "TTLL4" },
  { key: "NM_014641.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9656", geneDisplay: "MDC1" },
  { key: "NM_014643.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9658", geneDisplay: "ZNF516" },
  { key: "NM_014647.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9665", geneDisplay: "MARF1" },
  { key: "NM_014648.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9666", geneDisplay: "DZIP3" },
  { key: "NM_014649.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9667", geneDisplay: "SAFB2" },
  { key: "NM_014650.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9668", geneDisplay: "ZNF432" },
  { key: "NM_014652.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9670", geneDisplay: "IPO13" },
  { key: "NM_014653.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9671", geneDisplay: "WSCD2" },
  { key: "NM_014654.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9672", geneDisplay: "SDC3" },
  { key: "NM_014655.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9673", geneDisplay: "SLC25A44" },
  { key: "NM_014656.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9674", geneDisplay: "KIAA0040" },
  { key: "NM_014661.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9679", geneDisplay: "FAM53B" },
  { key: "NM_014663.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9682", geneDisplay: "KDM4A" },
  { key: "NM_014665.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9684", geneDisplay: "LRRC14" },
  { key: "NM_014666.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9685", geneDisplay: "CLINT1" },
  { key: "NM_014668.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9687", geneDisplay: "GREB1" },
  { key: "NM_014669.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9688", geneDisplay: "NUP93" },
  { key: "NM_014671.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9690", geneDisplay: "UBE3C" },
  { key: "NM_014672.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9692", geneDisplay: "PRORP" },
  { key: "NM_014673.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9694", geneDisplay: "EMC2" },
  { key: "NM_014674.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9695", geneDisplay: "EDEM1" },
  { key: "NM_014675.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9696", geneDisplay: "CROCC" },
  { key: "NM_014679.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9702", geneDisplay: "CEP57" },
  { key: "NM_014680.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9703", geneDisplay: "KIAA0100" },
  { key: "NM_014681.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9704", geneDisplay: "DHX34" },
  { key: "NM_014683.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9706", geneDisplay: "ULK2" },
  { key: "NM_014685.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9709", geneDisplay: "HERPUD1" },
  { key: "NM_014686.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9710", geneDisplay: "GARRE1" },
  { key: "NM_014687.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9711", geneDisplay: "RUBCN" },
  { key: "NM_014688.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9712", geneDisplay: "USP6NL" },
  { key: "NM_014689.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55619", geneDisplay: "DOCK10" },
  { key: "NM_014691.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9716", geneDisplay: "AQR" },
  { key: "NM_014692.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9717", geneDisplay: "SEC14L5" },
  { key: "NM_014694.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9719", geneDisplay: "ADAMTSL2" },
  { key: "NM_014697.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9722", geneDisplay: "NOS1AP" },
  { key: "NM_014698.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9725", geneDisplay: "TMEM63A" },
  { key: "NM_014699.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9726", geneDisplay: "ZNF646" },
  { key: "NM_014700.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9727", geneDisplay: "RAB11FIP3" },
  { key: "NM_014702.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9729", geneDisplay: "KIAA0408" },
  { key: "NM_014704.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9731", geneDisplay: "CEP104" },
  { key: "NM_014706.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9733", geneDisplay: "SART3" },
  { key: "NM_014708.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9735", geneDisplay: "KNTC1" },
  { key: "NM_014709.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9736", geneDisplay: "USP34" },
  { key: "NM_014712.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9739", geneDisplay: "SETD1A" },
  { key: "NM_014713.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9741", geneDisplay: "LAPTM4A" },
  { key: "NM_014714.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9742", geneDisplay: "IFT140" },
  { key: "NM_014716.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9744", geneDisplay: "ACAP1" },
  { key: "NM_014717.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9745", geneDisplay: "ZNF536" },
  { key: "NM_014718.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9746", geneDisplay: "CLSTN3" },
  { key: "NM_014719.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9747", geneDisplay: "TCAF1" },
  { key: "NM_014720.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9748", geneDisplay: "SLK" },
  { key: "NM_014727.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9757", geneDisplay: "KMT2B" },
  { key: "NM_014729.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9760", geneDisplay: "TOX" },
  { key: "NM_014730.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9761", geneDisplay: "MLEC" },
  { key: "NM_014734.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9766", geneDisplay: "SUSD6" },
  { key: "NM_014735.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9767", geneDisplay: "JADE3" },
  { key: "NM_014736.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9768", geneDisplay: "PCLAF" },
  { key: "NM_014737.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9770", geneDisplay: "RASSF2" },
  { key: "NM_014738.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9772", geneDisplay: "TMEM94" },
  { key: "NM_014739.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9774", geneDisplay: "BCLAF1" },
  { key: "NM_014740.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9775", geneDisplay: "EIF4A3" },
  { key: "NM_014742.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9777", geneDisplay: "TM9SF4" },
  { key: "NM_014743.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9778", geneDisplay: "KIAA0232" },
  { key: "NM_014746.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9781", geneDisplay: "RNF144A" },
  { key: "NM_014747.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9783", geneDisplay: "RIMS3" },
  { key: "NM_014748.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9784", geneDisplay: "SNX17" },
  { key: "NM_014750.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9787", geneDisplay: "DLGAP5" },
  { key: "NM_014751.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9788", geneDisplay: "MTSS1" },
  { key: "NM_014752.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9789", geneDisplay: "SPCS2" },
  { key: "NM_014753.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9790", geneDisplay: "BMS1" },
  { key: "NM_014754.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9791", geneDisplay: "PTDSS1" },
  { key: "NM_014755.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9792", geneDisplay: "SERTAD2" },
  { key: "NM_014757.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9794", geneDisplay: "MAML1" },
  { key: "NM_014758.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399979", geneDisplay: "SNX19" },
  { key: "NM_014759.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9796", geneDisplay: "PHYHIP" },
  { key: "NM_014760.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9797", geneDisplay: "TATDN2" },
  { key: "NM_014762.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1718", geneDisplay: "DHCR24" },
  { key: "NM_014763.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9801", geneDisplay: "MRPL19" },
  { key: "NM_014764.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9802", geneDisplay: "DAZAP2" },
  { key: "NM_014765.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9804", geneDisplay: "TOMM20" },
  { key: "NM_014766.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9805", geneDisplay: "SCRN1" },
  { key: "NM_014771.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9810", geneDisplay: "RNF40" },
  { key: "NM_014772.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9811", geneDisplay: "CTIF" },
  { key: "NM_014773.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9812", geneDisplay: "DELE1" },
  { key: "NM_014774.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9813", geneDisplay: "EFCAB14" },
  { key: "NM_014777.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9816", geneDisplay: "URB2" },
  { key: "NM_014780.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9820", geneDisplay: "CUL7" },
  { key: "NM_014781.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9821", geneDisplay: "RB1CC1" },
  { key: "NM_014783.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9824", geneDisplay: "ARHGAP11A" },
  { key: "NM_014786.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9828", geneDisplay: "ARHGEF17" },
  { key: "NM_014788.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9830", geneDisplay: "TRIM14" },
  { key: "NM_014791.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9833", geneDisplay: "MELK" },
  { key: "NM_014793.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9836", geneDisplay: "LCMT2" },
  { key: "NM_014795.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9839", geneDisplay: "ZEB2" },
  { key: "NM_014797.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9841", geneDisplay: "ZBTB24" },
  { key: "NM_014798.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9842", geneDisplay: "PLEKHM1" },
  { key: "NM_014800.11", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9844", geneDisplay: "ELMO1" },
  { key: "NM_014801.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80003", geneDisplay: "PCNX2" },
  { key: "NM_014804.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9851", geneDisplay: "KIAA0753" },
  { key: "NM_014805.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9852", geneDisplay: "EPM2AIP1" },
  { key: "NM_014806.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9853", geneDisplay: "RUSC2" },
  { key: "NM_014808.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9855", geneDisplay: "FARP2" },
  { key: "NM_014809.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9856", geneDisplay: "KIAA0319" },
  { key: "NM_014810.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9857", geneDisplay: "CEP350" },
  { key: "NM_014811.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9858", geneDisplay: "PPP1R26" },
  { key: "NM_014812.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9859", geneDisplay: "CEP170" },
  { key: "NM_014813.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9860", geneDisplay: "LRIG2" },
  { key: "NM_014814.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9861", geneDisplay: "PSMD6" },
  { key: "NM_014815.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9862", geneDisplay: "MED24" },
  { key: "NM_014817.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9865", geneDisplay: "TRIL" },
  { key: "NM_014819.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9867", geneDisplay: "PJA2" },
  { key: "NM_014820.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9868", geneDisplay: "TOMM70" },
  { key: "NM_014822.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9871", geneDisplay: "SEC24D" },
  { key: "NM_014824.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9873", geneDisplay: "FCHSD2" },
  { key: "NM_014825.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9875", geneDisplay: "URB1" },
  { key: "NM_014828.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9878", geneDisplay: "TOX4" },
  { key: "NM_014830.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9880", geneDisplay: "ZBTB39" },
  { key: "NM_014832.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9882", geneDisplay: "TBC1D4" },
  { key: "NM_014834.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9884", geneDisplay: "LRRC37A" },
  { key: "NM_014836.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9886", geneDisplay: "RHOBTB1" },
  { key: "NM_014838.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9889", geneDisplay: "ZBED4" },
  { key: "NM_014839.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9890", geneDisplay: "PLPPR4" },
  { key: "NM_014840.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9891", geneDisplay: "NUAK1" },
  { key: "NM_014844.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9895", geneDisplay: "TECPR2" },
  { key: "NM_014845.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9896", geneDisplay: "FIG4" },
  { key: "NM_014846.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9897", geneDisplay: "WASHC5" },
  { key: "NM_014847.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9898", geneDisplay: "UBAP2L" },
  { key: "NM_014849.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9900", geneDisplay: "SV2A" },
  { key: "NM_014850.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9901", geneDisplay: "SRGAP3" },
  { key: "NM_014851.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9903", geneDisplay: "KLHL21" },
  { key: "NM_014853.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9905", geneDisplay: "SGSM2" },
  { key: "NM_014855.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9907", geneDisplay: "AP5Z1" },
  { key: "NM_014856.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9909", geneDisplay: "DENND4B" },
  { key: "NM_014858.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9911", geneDisplay: "TMCC2" },
  { key: "NM_014859.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9912", geneDisplay: "ARHGAP44" },
  { key: "NM_014860.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9913", geneDisplay: "SUPT7L" },
  { key: "NM_014861.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9914", geneDisplay: "ATP2C2" },
  { key: "NM_014862.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9915", geneDisplay: "ARNT2" },
  { key: "NM_014864.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9917", geneDisplay: "FAM20B" },
  { key: "NM_014865.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9918", geneDisplay: "NCAPD2" },
  { key: "NM_014866.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9919", geneDisplay: "SEC16A" },
  { key: "NM_014867.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9920", geneDisplay: "KBTBD11" },
  { key: "NM_014868.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9921", geneDisplay: "RNF10" },
  { key: "NM_014870.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9923", geneDisplay: "ZBTB40" },
  { key: "NM_014871.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9924", geneDisplay: "PAN2" },
  { key: "NM_014872.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9925", geneDisplay: "ZBTB5" },
  { key: "NM_014873.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9926", geneDisplay: "LPGAT1" },
  { key: "NM_014874.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9927", geneDisplay: "MFN2" },
  { key: "NM_014875.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9928", geneDisplay: "KIF14" },
  { key: "NM_014877.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9931", geneDisplay: "HELZ" },
  { key: "NM_014878.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9933", geneDisplay: "PUM3" },
  { key: "NM_014879.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9934", geneDisplay: "P2RY14" },
  { key: "NM_014880.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9936", geneDisplay: "CD302" },
  { key: "NM_014881.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9937", geneDisplay: "DCLRE1A" },
  { key: "NM_014883.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10144", geneDisplay: "FAM13A" },
  { key: "NM_014886.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10412", geneDisplay: "NSA2" },
  { key: "NM_014887.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10443", geneDisplay: "N4BP2L2" },
  { key: "NM_014888.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10447", geneDisplay: "FAM3C" },
  { key: "NM_014889.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10531", geneDisplay: "PITRM1" },
  { key: "NM_014891.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11333", geneDisplay: "PDAP1" },
  { key: "NM_014892.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22828", geneDisplay: "SCAF8" },
  { key: "NM_014895.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22832", geneDisplay: "CEP162" },
  { key: "NM_014899.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22836", geneDisplay: "RHOBTB3" },
  { key: "NM_014901.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22838", geneDisplay: "RNF44" },
  { key: "NM_014904.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22841", geneDisplay: "RAB11FIP2" },
  { key: "NM_014905.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2744", geneDisplay: "GLS" },
  { key: "NM_014906.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22843", geneDisplay: "PPM1E" },
  { key: "NM_014907.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22844", geneDisplay: "FRMPD1" },
  { key: "NM_014908.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22845", geneDisplay: "DOLK" },
  { key: "NM_014909.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22846", geneDisplay: "VASH1" },
  { key: "NM_014911.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22848", geneDisplay: "AAK1" },
  { key: "NM_014912.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22849", geneDisplay: "CPEB3" },
  { key: "NM_014913.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "22850", geneDisplay: "ADNP2" },
  { key: "NM_014915.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22852", geneDisplay: "ANKRD26" },
  { key: "NM_014916.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "22853", geneDisplay: "LMTK2" },
  { key: "NM_014918.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "22856", geneDisplay: "CHSY1" },
  { key: "NM_014920.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22858", geneDisplay: "CILK1" },
  { key: "NM_014921.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22859", geneDisplay: "ADGRL1" },
  { key: "NM_014924.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22863", geneDisplay: "ATG14" },
  { key: "NM_014927.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "22866", geneDisplay: "CNKSR2" },
  { key: "NM_014930.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22869", geneDisplay: "ZNF510" },
  { key: "NM_014931.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22870", geneDisplay: "PPP6R1" },
  { key: "NM_014935.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22874", geneDisplay: "PLEKHA6" },
  { key: "NM_014936.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22875", geneDisplay: "ENPP4" },
  { key: "NM_014937.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22876", geneDisplay: "INPP5F" },
  { key: "NM_014938.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22877", geneDisplay: "MLXIP" },
  { key: "NM_014939.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "22878", geneDisplay: "TRAPPC8" },
  { key: "NM_014940.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "22879", geneDisplay: "MON1B" },
  { key: "NM_014943.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "22882", geneDisplay: "ZHX2" },
  { key: "NM_014945.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22885", geneDisplay: "ABLIM3" },
  { key: "NM_014946.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6683", geneDisplay: "SPAST" },
  { key: "NM_014947.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22887", geneDisplay: "FOXJ3" },
  { key: "NM_014948.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22888", geneDisplay: "UBOX5" },
  { key: "NM_014949.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22889", geneDisplay: "KHDC4" },
  { key: "NM_014951.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22891", geneDisplay: "ZNF365" },
  { key: "NM_014952.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "22893", geneDisplay: "BAHD1" },
  { key: "NM_014953.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "22894", geneDisplay: "DIS3" },
  { key: "NM_014956.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22897", geneDisplay: "CEP164" },
  { key: "NM_014962.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22903", geneDisplay: "BTBD3" },
  { key: "NM_014963.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22904", geneDisplay: "SBNO2" },
  { key: "NM_014964.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22905", geneDisplay: "EPN2" },
  { key: "NM_014967.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "22909", geneDisplay: "FAN1" },
  { key: "NM_014970.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22920", geneDisplay: "KIFAP3" },
  { key: "NM_014971.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22979", geneDisplay: "EFR3B" },
  { key: "NM_014972.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "22980", geneDisplay: "TCF25" },
  { key: "NM_014974.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22982", geneDisplay: "DIP2C" },
  { key: "NM_014975.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22983", geneDisplay: "MAST1" },
  { key: "NM_014976.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22984", geneDisplay: "PDCD11" },
  { key: "NM_014978.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22986", geneDisplay: "SORCS3" },
  { key: "NM_014979.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22987", geneDisplay: "SV2C" },
  { key: "NM_014981.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22989", geneDisplay: "MYH15" },
  { key: "NM_014982.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22990", geneDisplay: "PCNX1" },
  { key: "NM_014983.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "22993", geneDisplay: "HMGXB3" },
  { key: "NM_014984.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22994", geneDisplay: "CEP131" },
  { key: "NM_014989.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22999", geneDisplay: "RIMS1" },
  { key: "NM_014991.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23001", geneDisplay: "WDFY3" },
  { key: "NM_014994.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23005", geneDisplay: "MAPKBP1" },
  { key: "NM_014996.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23007", geneDisplay: "PLCH1" },
  { key: "NM_014997.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23008", geneDisplay: "KLHDC10" },
  { key: "NM_014999.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23011", geneDisplay: "RAB21" },
  { key: "NM_015000.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23012", geneDisplay: "STK38L" },
  { key: "NM_015001.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23013", geneDisplay: "SPEN" },
  { key: "NM_015002.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23014", geneDisplay: "FBXO21" },
  { key: "NM_015004.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23016", geneDisplay: "EXOSC7" },
  { key: "NM_015009.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23024", geneDisplay: "PDZRN3" },
  { key: "NM_015012.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "440026", geneDisplay: "TMEM41B" },
  { key: "NM_015014.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23029", geneDisplay: "RBM34" },
  { key: "NM_015015.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23030", geneDisplay: "KDM4B" },
  { key: "NM_015018.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23033", geneDisplay: "DOP1A" },
  { key: "NM_015020.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23035", geneDisplay: "PHLPP2" },
  { key: "NM_015021.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23036", geneDisplay: "ZNF292" },
  { key: "NM_015024.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23039", geneDisplay: "XPO7" },
  { key: "NM_015026.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23041", geneDisplay: "MON2" },
  { key: "NM_015027.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23042", geneDisplay: "PDXDC1" },
  { key: "NM_015028.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23043", geneDisplay: "TNIK" },
  { key: "NM_015030.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285527", geneDisplay: "FRYL" },
  { key: "NM_015032.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23047", geneDisplay: "PDS5B" },
  { key: "NM_015033.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23048", geneDisplay: "FNBP1" },
  { key: "NM_015036.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23052", geneDisplay: "ENDOD1" },
  { key: "NM_015039.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23057", geneDisplay: "NMNAT2" },
  { key: "NM_015040.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200576", geneDisplay: "PIKFYVE" },
  { key: "NM_015041.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23059", geneDisplay: "CLUAP1" },
  { key: "NM_015042.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23060", geneDisplay: "ZNF609" },
  { key: "NM_015043.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23061", geneDisplay: "TBC1D9B" },
  { key: "NM_015044.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23062", geneDisplay: "GGA2" },
  { key: "NM_015045.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23063", geneDisplay: "WAPL" },
  { key: "NM_015046.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23064", geneDisplay: "SETX" },
  { key: "NM_015047.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23065", geneDisplay: "EMC1" },
  { key: "NM_015049.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "66008", geneDisplay: "TRAK2" },
  { key: "NM_015050.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23070", geneDisplay: "CMTR1" },
  { key: "NM_015051.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23071", geneDisplay: "ERP44" },
  { key: "NM_015052.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23072", geneDisplay: "HECW1" },
  { key: "NM_015054.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23074", geneDisplay: "UHRF1BP1L" },
  { key: "NM_015055.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23075", geneDisplay: "SWAP70" },
  { key: "NM_015056.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23076", geneDisplay: "RRP1B" },
  { key: "NM_015057.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23077", geneDisplay: "MYCBP2" },
  { key: "NM_015058.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23078", geneDisplay: "VWA8" },
  { key: "NM_015059.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "83660", geneDisplay: "TLN2" },
  { key: "NM_015060.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23080", geneDisplay: "AVL9" },
  { key: "NM_015061.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23081", geneDisplay: "KDM4C" },
  { key: "NM_015062.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23082", geneDisplay: "PPRC1" },
  { key: "NM_015063.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6543", geneDisplay: "SLC8A2" },
  { key: "NM_015065.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23086", geneDisplay: "EXPH5" },
  { key: "NM_015072.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23093", geneDisplay: "TTLL5" },
  { key: "NM_015073.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23094", geneDisplay: "SIPA1L3" },
  { key: "NM_015076.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23097", geneDisplay: "CDK19" },
  { key: "NM_015077.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23098", geneDisplay: "SARM1" },
  { key: "NM_015078.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23101", geneDisplay: "MCF2L2" },
  { key: "NM_015080.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9379", geneDisplay: "NRXN2" },
  { key: "NM_015082.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23105", geneDisplay: "FSTL4" },
  { key: "NM_015084.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23107", geneDisplay: "MRPS27" },
  { key: "NM_015085.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23108", geneDisplay: "RAP1GAP2" },
  { key: "NM_015086.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23109", geneDisplay: "DDN" },
  { key: "NM_015087.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23111", geneDisplay: "SPART" },
  { key: "NM_015089.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23113", geneDisplay: "CUL9" },
  { key: "NM_015092.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23049", geneDisplay: "SMG1" },
  { key: "NM_015094.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23119", geneDisplay: "HIC2" },
  { key: "NM_015099.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23125", geneDisplay: "CAMTA2" },
  { key: "NM_015100.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23126", geneDisplay: "POGZ" },
  { key: "NM_015101.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23127", geneDisplay: "COLGALT2" },
  { key: "NM_015102.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "261734", geneDisplay: "NPHP4" },
  { key: "NM_015103.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23129", geneDisplay: "PLXND1" },
  { key: "NM_015104.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23130", geneDisplay: "ATG2A" },
  { key: "NM_015106.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23132", geneDisplay: "RAD54L2" },
  { key: "NM_015107.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23133", geneDisplay: "PHF8" },
  { key: "NM_015110.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23137", geneDisplay: "SMC5" },
  { key: "NM_015111.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23138", geneDisplay: "N4BP3" },
  { key: "NM_015112.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23139", geneDisplay: "MAST2" },
  { key: "NM_015113.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23140", geneDisplay: "ZZEF1" },
  { key: "NM_015114.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23141", geneDisplay: "ANKLE2" },
  { key: "NM_015117.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23144", geneDisplay: "ZC3H3" },
  { key: "NM_015122.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23149", geneDisplay: "FCHO1" },
  { key: "NM_015123.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23150", geneDisplay: "FRMD4B" },
  { key: "NM_015124.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23151", geneDisplay: "GRAMD4" },
  { key: "NM_015130.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23158", geneDisplay: "TBC1D9" },
  { key: "NM_015131.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23160", geneDisplay: "WDR43" },
  { key: "NM_015132.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23161", geneDisplay: "SNX13" },
  { key: "NM_015135.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23165", geneDisplay: "NUP205" },
  { key: "NM_015136.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23166", geneDisplay: "STAB1" },
  { key: "NM_015137.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23167", geneDisplay: "EFR3A" },
  { key: "NM_015138.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23168", geneDisplay: "RTF1" },
  { key: "NM_015139.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23169", geneDisplay: "SLC35D1" },
  { key: "NM_015140.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23170", geneDisplay: "TTLL12" },
  { key: "NM_015141.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23171", geneDisplay: "GPD1L" },
  { key: "NM_015143.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23173", geneDisplay: "METAP1" },
  { key: "NM_015144.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23174", geneDisplay: "ZCCHC14" },
  { key: "NM_015147.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23177", geneDisplay: "CEP68" },
  { key: "NM_015148.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23178", geneDisplay: "PASK" },
  { key: "NM_015150.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23180", geneDisplay: "RFTN1" },
  { key: "NM_015151.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23181", geneDisplay: "DIP2A" },
  { key: "NM_015154.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23184", geneDisplay: "MESD" },
  { key: "NM_015155.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23185", geneDisplay: "LARP4B" },
  { key: "NM_015156.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23186", geneDisplay: "RCOR1" },
  { key: "NM_015158.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23189", geneDisplay: "KANK1" },
  { key: "NM_015159.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23201", geneDisplay: "FAM168A" },
  { key: "NM_015160.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23203", geneDisplay: "PMPCA" },
  { key: "NM_015161.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23204", geneDisplay: "ARL6IP1" },
  { key: "NM_015162.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23205", geneDisplay: "ACSBG1" },
  { key: "NM_015164.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23207", geneDisplay: "PLEKHM2" },
  { key: "NM_015166.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23209", geneDisplay: "MLC1" },
  { key: "NM_015167.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23210", geneDisplay: "JMJD6" },
  { key: "NM_015168.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23211", geneDisplay: "ZC3H4" },
  { key: "NM_015169.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23212", geneDisplay: "RRS1" },
  { key: "NM_015171.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23214", geneDisplay: "XPO6" },
  { key: "NM_015174.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23217", geneDisplay: "ZFR2" },
  { key: "NM_015175.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23218", geneDisplay: "NBEAL2" },
  { key: "NM_015176.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23219", geneDisplay: "FBXO28" },
  { key: "NM_015177.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23220", geneDisplay: "DTX4" },
  { key: "NM_015178.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23221", geneDisplay: "RHOBTB2" },
  { key: "NM_015179.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23223", geneDisplay: "RRP12" },
  { key: "NM_015187.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23231", geneDisplay: "SEL1L3" },
  { key: "NM_015188.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23232", geneDisplay: "TBC1D12" },
  { key: "NM_015189.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23233", geneDisplay: "EXOC6B" },
  { key: "NM_015190.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23234", geneDisplay: "DNAJC9" },
  { key: "NM_015191.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23235", geneDisplay: "SIK2" },
  { key: "NM_015192.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23236", geneDisplay: "PLCB1" },
  { key: "NM_015193.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23237", geneDisplay: "ARC" },
  { key: "NM_015194.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4642", geneDisplay: "MYO1D" },
  { key: "NM_015198.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23242", geneDisplay: "COBL" },
  { key: "NM_015201.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23246", geneDisplay: "BOP1" },
  { key: "NM_015202.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23247", geneDisplay: "KATNIP" },
  { key: "NM_015203.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23248", geneDisplay: "RPRD2" },
  { key: "NM_015204.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221981", geneDisplay: "THSD7A" },
  { key: "NM_015205.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23250", geneDisplay: "ATP11A" },
  { key: "NM_015206.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23251", geneDisplay: "MINAR1" },
  { key: "NM_015207.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23252", geneDisplay: "OTUD3" },
  { key: "NM_015208.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23253", geneDisplay: "ANKRD12" },
  { key: "NM_015213.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23258", geneDisplay: "DENND5A" },
  { key: "NM_015214.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23259", geneDisplay: "DDHD2" },
  { key: "NM_015215.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23261", geneDisplay: "CAMTA1" },
  { key: "NM_015221.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23268", geneDisplay: "DNMBP" },
  { key: "NM_015225.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158471", geneDisplay: "PRUNE2" },
  { key: "NM_015226.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23274", geneDisplay: "CLEC16A" },
  { key: "NM_015230.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "116984", geneDisplay: "ARAP2" },
  { key: "NM_015231.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23279", geneDisplay: "NUP160" },
  { key: "NM_015235.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23283", geneDisplay: "CSTF2T" },
  { key: "NM_015238.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23286", geneDisplay: "WWC1" },
  { key: "NM_015241.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "57553", geneDisplay: "MICAL3" },
  { key: "NM_015245.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23294", geneDisplay: "ANKS1A" },
  { key: "NM_015246.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23295", geneDisplay: "MGRN1" },
  { key: "NM_015251.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23300", geneDisplay: "ATMIN" },
  { key: "NM_015253.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23302", geneDisplay: "WSCD1" },
  { key: "NM_015254.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23303", geneDisplay: "KIF13B" },
  { key: "NM_015258.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23307", geneDisplay: "FKBP15" },
  { key: "NM_015259.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23308", geneDisplay: "ICOSLG" },
  { key: "NM_015261.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23310", geneDisplay: "NCAPD3" },
  { key: "NM_015266.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23315", geneDisplay: "SLC9A8" },
  { key: "NM_015267.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23316", geneDisplay: "CUX2" },
  { key: "NM_015268.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23317", geneDisplay: "DNAJC13" },
  { key: "NM_015270.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "112", geneDisplay: "ADCY6" },
  { key: "NM_015271.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23321", geneDisplay: "TRIM2" },
  { key: "NM_015272.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23322", geneDisplay: "RPGRIP1L" },
  { key: "NM_015274.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23324", geneDisplay: "MAN2B2" },
  { key: "NM_015275.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23325", geneDisplay: "WASHC4" },
  { key: "NM_015276.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23326", geneDisplay: "USP22" },
  { key: "NM_015278.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23328", geneDisplay: "SASH1" },
  { key: "NM_015279.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23329", geneDisplay: "TBC1D30" },
  { key: "NM_015285.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23335", geneDisplay: "WDR7" },
  { key: "NM_015289.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23339", geneDisplay: "VPS39" },
  { key: "NM_015291.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23341", geneDisplay: "DNAJC16" },
  { key: "NM_015292.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23344", geneDisplay: "ESYT1" },
  { key: "NM_015294.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4591", geneDisplay: "TRIM37" },
  { key: "NM_015295.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23347", geneDisplay: "SMCHD1" },
  { key: "NM_015297.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23349", geneDisplay: "PHF24" },
  { key: "NM_015299.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23351", geneDisplay: "KHNYN" },
  { key: "NM_015302.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23354", geneDisplay: "HAUS5" },
  { key: "NM_015305.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23357", geneDisplay: "ANGEL1" },
  { key: "NM_015306.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23358", geneDisplay: "USP24" },
  { key: "NM_015307.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23359", geneDisplay: "FAM189A1" },
  { key: "NM_015308.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23360", geneDisplay: "FNBP4" },
  { key: "NM_015310.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23362", geneDisplay: "PSD3" },
  { key: "NM_015311.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23363", geneDisplay: "OBSL1" },
  { key: "NM_015313.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23365", geneDisplay: "ARHGEF12" },
  { key: "NM_015316.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23368", geneDisplay: "PPP1R13B" },
  { key: "NM_015317.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23369", geneDisplay: "PUM2" },
  { key: "NM_015321.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23373", geneDisplay: "CRTC1" },
  { key: "NM_015322.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10116", geneDisplay: "FEM1B" },
  { key: "NM_015323.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23376", geneDisplay: "UFL1" },
  { key: "NM_015324.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23378", geneDisplay: "RRP8" },
  { key: "NM_015325.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23379", geneDisplay: "ICE1" },
  { key: "NM_015326.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23380", geneDisplay: "SRGAP2" },
  { key: "NM_015327.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23381", geneDisplay: "SMG5" },
  { key: "NM_015328.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23382", geneDisplay: "AHCYL2" },
  { key: "NM_015329.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23383", geneDisplay: "MAU2" },
  { key: "NM_015330.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23384", geneDisplay: "SPECC1L" },
  { key: "NM_015331.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23385", geneDisplay: "NCSTN" },
  { key: "NM_015332.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23386", geneDisplay: "NUDCD3" },
  { key: "NM_015335.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23389", geneDisplay: "MED13L" },
  { key: "NM_015336.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23390", geneDisplay: "ZDHHC17" },
  { key: "NM_015338.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "171023", geneDisplay: "ASXL1" },
  { key: "NM_015340.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23395", geneDisplay: "LARS2" },
  { key: "NM_015341.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23397", geneDisplay: "NCAPH" },
  { key: "NM_015342.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23398", geneDisplay: "PPWD1" },
  { key: "NM_015344.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23484", geneDisplay: "LEPROTL1" },
  { key: "NM_015346.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23503", geneDisplay: "ZFYVE26" },
  { key: "NM_015348.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23505", geneDisplay: "TMEM131" },
  { key: "NM_015351.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23508", geneDisplay: "TTC9" },
  { key: "NM_015352.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23509", geneDisplay: "POFUT1" },
  { key: "NM_015353.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23510", geneDisplay: "KCTD2" },
  { key: "NM_015354.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23511", geneDisplay: "NUP188" },
  { key: "NM_015355.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23512", geneDisplay: "SUZ12" },
  { key: "NM_015358.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23515", geneDisplay: "MORC3" },
  { key: "NM_015360.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23517", geneDisplay: "MTREX" },
  { key: "NM_015364.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23643", geneDisplay: "LY96" },
  { key: "NM_015365.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9949", geneDisplay: "AMMECR1" },
  { key: "NM_015367.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23786", geneDisplay: "BCL2L13" },
  { key: "NM_015368.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "24145", geneDisplay: "PANX1" },
  { key: "NM_015370.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25770", geneDisplay: "C22orf31" },
  { key: "NM_015373.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25776", geneDisplay: "CBY1" },
  { key: "NM_015374.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25777", geneDisplay: "SUN2" },
  { key: "NM_015375.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25778", geneDisplay: "DSTYK" },
  { key: "NM_015378.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55187", geneDisplay: "VPS13D" },
  { key: "NM_015379.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25798", geneDisplay: "BRI3" },
  { key: "NM_015380.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25813", geneDisplay: "SAMM50" },
  { key: "NM_015382.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "25831", geneDisplay: "HECTD1" },
  { key: "NM_015386.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25839", geneDisplay: "COG4" },
  { key: "NM_015387.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25843", geneDisplay: "MOB4" },
  { key: "NM_015388.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25844", geneDisplay: "YIPF3" },
  { key: "NM_015391.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25847", geneDisplay: "ANAPC13" },
  { key: "NM_015392.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56654", geneDisplay: "NPDC1" },
  { key: "NM_015393.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "25849", geneDisplay: "PARM1" },
  { key: "NM_015394.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7556", geneDisplay: "ZNF10" },
  { key: "NM_015395.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25851", geneDisplay: "TECPR1" },
  { key: "NM_015397.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25853", geneDisplay: "DCAF12" },
  { key: "NM_015399.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25855", geneDisplay: "BRMS1" },
  { key: "NM_015401.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51564", geneDisplay: "HDAC7" },
  { key: "NM_015404.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25861", geneDisplay: "WHRN" },
  { key: "NM_015407.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25864", geneDisplay: "ABHD14A" },
  { key: "NM_015409.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57634", geneDisplay: "EP400" },
  { key: "NM_015411.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25870", geneDisplay: "SUMF2" },
  { key: "NM_015412.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25871", geneDisplay: "NEPRO" },
  { key: "NM_015416.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25875", geneDisplay: "LETMD1" },
  { key: "NM_015417.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "25876", geneDisplay: "SPEF1" },
  { key: "NM_015419.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "25878", geneDisplay: "MXRA5" },
  { key: "NM_015420.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "25879", geneDisplay: "DCAF13" },
  { key: "NM_015421.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25880", geneDisplay: "TMEM186" },
  { key: "NM_015423.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "60496", geneDisplay: "AASDHPPT" },
  { key: "NM_015425.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25885", geneDisplay: "POLR1A" },
  { key: "NM_015426.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25886", geneDisplay: "POC1A" },
  { key: "NM_015428.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25888", geneDisplay: "ZNF473" },
  { key: "NM_015431.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25893", geneDisplay: "TRIM58" },
  { key: "NM_015433.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25895", geneDisplay: "EEF1AKMT3" },
  { key: "NM_015434.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25896", geneDisplay: "INTS7" },
  { key: "NM_015436.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "25898", geneDisplay: "RCHY1" },
  { key: "NM_015439.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25901", geneDisplay: "CCDC28A" },
  { key: "NM_015440.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25902", geneDisplay: "MTHFD1L" },
  { key: "NM_015441.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25903", geneDisplay: "OLFML2B" },
  { key: "NM_015442.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25904", geneDisplay: "CNOT10" },
  { key: "NM_015443.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284058", geneDisplay: "KANSL1" },
  { key: "NM_015444.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25907", geneDisplay: "TMEM158" },
  { key: "NM_015447.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "157922", geneDisplay: "CAMSAP1" },
  { key: "NM_015448.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "25911", geneDisplay: "DPCD" },
  { key: "NM_015450.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25913", geneDisplay: "POT1" },
  { key: "NM_015456.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25920", geneDisplay: "NELFB" },
  { key: "NM_015457.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25921", geneDisplay: "ZDHHC5" },
  { key: "NM_015458.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "66036", geneDisplay: "MTMR9" },
  { key: "NM_015459.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25923", geneDisplay: "ATL3" },
  { key: "NM_015460.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25924", geneDisplay: "MYRIP" },
  { key: "NM_015461.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "25925", geneDisplay: "ZNF521" },
  { key: "NM_015462.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "25926", geneDisplay: "NOL11" },
  { key: "NM_015463.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25927", geneDisplay: "CNRIP1" },
  { key: "NM_015464.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25928", geneDisplay: "SOSTDC1" },
  { key: "NM_015465.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "25929", geneDisplay: "GEMIN5" },
  { key: "NM_015466.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25930", geneDisplay: "PTPN23" },
  { key: "NM_015469.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25934", geneDisplay: "NIPSNAP3A" },
  { key: "NM_015471.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25936", geneDisplay: "NSL1" },
  { key: "NM_015472.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25937", geneDisplay: "WWTR1" },
  { key: "NM_015473.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "25938", geneDisplay: "HEATR5A" },
  { key: "NM_015474.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "25939", geneDisplay: "SAMHD1" },
  { key: "NM_015475.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25940", geneDisplay: "FAM98A" },
  { key: "NM_015476.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "25941", geneDisplay: "TPGS2" },
  { key: "NM_015480.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25945", geneDisplay: "NECTIN3" },
  { key: "NM_015481.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25946", geneDisplay: "ZNF385A" },
  { key: "NM_015482.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63027", geneDisplay: "SLC22A23" },
  { key: "NM_015483.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "25948", geneDisplay: "KBTBD2" },
  { key: "NM_015484.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25949", geneDisplay: "SYF2" },
  { key: "NM_015485.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25950", geneDisplay: "RWDD3" },
  { key: "NM_015488.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25953", geneDisplay: "PNKD" },
  { key: "NM_015490.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "25956", geneDisplay: "SEC31B" },
  { key: "NM_015492.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56905", geneDisplay: "C15orf39" },
  { key: "NM_015496.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "25962", geneDisplay: "VIRMA" },
  { key: "NM_015497.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "25963", geneDisplay: "TMEM87A" },
  { key: "NM_015500.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "25966", geneDisplay: "C2CD2" },
  { key: "NM_015506.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25974", geneDisplay: "MMACHC" },
  { key: "NM_015507.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "25975", geneDisplay: "EGFL6" },
  { key: "NM_015508.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25976", geneDisplay: "TIPARP" },
  { key: "NM_015509.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25977", geneDisplay: "NECAP1" },
  { key: "NM_015510.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "25979", geneDisplay: "DHRS7B" },
  { key: "NM_015512.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25981", geneDisplay: "DNAH1" },
  { key: "NM_015515.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "25984", geneDisplay: "KRT23" },
  { key: "NM_015516.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25987", geneDisplay: "TSKU" },
  { key: "NM_015523.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25996", geneDisplay: "REXO2" },
  { key: "NM_015525.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25998", geneDisplay: "IBTK" },
  { key: "NM_015526.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25999", geneDisplay: "CLIP3" },
  { key: "NM_015527.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26000", geneDisplay: "TBC1D10B" },
  { key: "NM_015528.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26001", geneDisplay: "RNF167" },
  { key: "NM_015529.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26002", geneDisplay: "MOXD1" },
  { key: "NM_015530.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26003", geneDisplay: "GORASP2" },
  { key: "NM_015532.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "81488", geneDisplay: "POLR2M" },
  { key: "NM_015533.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26007", geneDisplay: "TKFC" },
  { key: "NM_015534.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26009", geneDisplay: "ZZZ3" },
  { key: "NM_015540.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26015", geneDisplay: "RPAP1" },
  { key: "NM_015541.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26018", geneDisplay: "LRIG1" },
  { key: "NM_015542.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26019", geneDisplay: "UPF2" },
  { key: "NM_015544.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26022", geneDisplay: "TMEM98" },
  { key: "NM_015545.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26024", geneDisplay: "PTCD1" },
  { key: "NM_015550.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26031", geneDisplay: "OSBPL3" },
  { key: "NM_015551.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26032", geneDisplay: "SUSD5" },
  { key: "NM_015554.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26035", geneDisplay: "GLCE" },
  { key: "NM_015557.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26038", geneDisplay: "CHD5" },
  { key: "NM_015559.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "26040", geneDisplay: "SETBP1" },
  { key: "NM_015562.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26043", geneDisplay: "UBXN7" },
  { key: "NM_015564.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26045", geneDisplay: "LRRTM2" },
  { key: "NM_015565.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "26046", geneDisplay: "LTN1" },
  { key: "NM_015568.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26051", geneDisplay: "PPP1R16B" },
  { key: "NM_015569.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26052", geneDisplay: "DNM3" },
  { key: "NM_015570.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26053", geneDisplay: "AUTS2" },
  { key: "NM_015571.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26054", geneDisplay: "SENP6" },
  { key: "NM_015576.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26059", geneDisplay: "ERC2" },
  { key: "NM_015577.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26064", geneDisplay: "RAI14" },
  { key: "NM_015578.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26065", geneDisplay: "LSM14A" },
  { key: "NM_015584.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26073", geneDisplay: "POLDIP2" },
  { key: "NM_015585.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26074", geneDisplay: "CFAP61" },
  { key: "NM_015589.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23034", geneDisplay: "SAMD4A" },
  { key: "NM_015595.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26084", geneDisplay: "ARHGEF26" },
  { key: "NM_015596.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26085", geneDisplay: "KLK13" },
  { key: "NM_015599.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5238", geneDisplay: "PGM3" },
  { key: "NM_015601.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26091", geneDisplay: "HERC4" },
  { key: "NM_015602.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26092", geneDisplay: "TOR1AIP1" },
  { key: "NM_015603.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26093", geneDisplay: "CCDC9" },
  { key: "NM_015604.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26094", geneDisplay: "DCAF4" },
  { key: "NM_015607.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26097", geneDisplay: "CHTOP" },
  { key: "NM_015610.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26100", geneDisplay: "WIPI2" },
  { key: "NM_015613.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26103", geneDisplay: "LRIT1" },
  { key: "NM_015621.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26112", geneDisplay: "CCDC69" },
  { key: "NM_015622.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51622", geneDisplay: "CCZ1" },
  { key: "NM_015626.10", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26118", geneDisplay: "WSB1" },
  { key: "NM_015627.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26119", geneDisplay: "LDLRAP1" },
  { key: "NM_015629.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26121", geneDisplay: "PRPF31" },
  { key: "NM_015630.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26122", geneDisplay: "EPC2" },
  { key: "NM_015631.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26123", geneDisplay: "TCTN3" },
  { key: "NM_015633.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "26127", geneDisplay: "FGFR1OP2" },
  { key: "NM_015634.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26128", geneDisplay: "KIFBP" },
  { key: "NM_015638.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26133", geneDisplay: "TRPC4AP" },
  { key: "NM_015641.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26136", geneDisplay: "TES" },
  { key: "NM_015649.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26145", geneDisplay: "IRF2BP1" },
  { key: "NM_015650.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26146", geneDisplay: "TRAF3IP1" },
  { key: "NM_015651.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26147", geneDisplay: "PHF19" },
  { key: "NM_015653.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "26150", geneDisplay: "RIBC2" },
  { key: "NM_015654.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26151", geneDisplay: "NAT9" },
  { key: "NM_015655.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26152", geneDisplay: "ZNF337" },
  { key: "NM_015656.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26153", geneDisplay: "KIF26A" },
  { key: "NM_015658.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26155", geneDisplay: "NOC2L" },
  { key: "NM_015659.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26156", geneDisplay: "RSL1D1" },
  { key: "NM_015660.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26157", geneDisplay: "GIMAP2" },
  { key: "NM_015662.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26160", geneDisplay: "IFT172" },
  { key: "NM_015665.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8086", geneDisplay: "AAAS" },
  { key: "NM_015666.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26164", geneDisplay: "MTG2" },
  { key: "NM_015667.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26165", geneDisplay: "SPATA31A7" },
  { key: "NM_015668.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26166", geneDisplay: "RGS22" },
  { key: "NM_015669.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26167", geneDisplay: "PCDHB5" },
  { key: "NM_015670.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26168", geneDisplay: "SENP3" },
  { key: "NM_015672.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85376", geneDisplay: "RIMBP3" },
  { key: "NM_015675.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4616", geneDisplay: "GADD45B" },
  { key: "NM_015677.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26751", geneDisplay: "SH3YL1" },
  { key: "NM_015679.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26995", geneDisplay: "TRUB2" },
  { key: "NM_015680.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27013", geneDisplay: "CNPPD1" },
  { key: "NM_015681.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27077", geneDisplay: "B9D1" },
  { key: "NM_015683.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27106", geneDisplay: "ARRDC2" },
  { key: "NM_015686.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27112", geneDisplay: "NALF2" },
  { key: "NM_015687.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27145", geneDisplay: "FILIP1" },
  { key: "NM_015688.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27146", geneDisplay: "FAM184B" },
  { key: "NM_015689.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27147", geneDisplay: "DENND2A" },
  { key: "NM_015690.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27148", geneDisplay: "STK36" },
  { key: "NM_015691.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55841", geneDisplay: "WWC3" },
  { key: "NM_015692.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27151", geneDisplay: "CPAMD8" },
  { key: "NM_015693.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27152", geneDisplay: "INTU" },
  { key: "NM_015694.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27153", geneDisplay: "ZNF777" },
  { key: "NM_015695.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27154", geneDisplay: "BRPF3" },
  { key: "NM_015696.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2882", geneDisplay: "GPX7" },
  { key: "NM_015698.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27238", geneDisplay: "GPKOW" },
  { key: "NM_015701.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27248", geneDisplay: "ERLEC1" },
  { key: "NM_015702.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27249", geneDisplay: "MMADHC" },
  { key: "NM_015703.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27341", geneDisplay: "RRP7A" },
  { key: "NM_015704.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27351", geneDisplay: "DESI1" },
  { key: "NM_015705.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27352", geneDisplay: "SGSM3" },
  { key: "NM_015710.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29997", geneDisplay: "NOP53" },
  { key: "NM_015713.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "50484", geneDisplay: "RRM2B" },
  { key: "NM_015714.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50486", geneDisplay: "G0S2" },
  { key: "NM_015715.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "50487", geneDisplay: "PLA2G3" },
  { key: "NM_015717.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50489", geneDisplay: "CD207" },
  { key: "NM_015718.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "50508", geneDisplay: "NOX3" },
  { key: "NM_015719.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "50509", geneDisplay: "COL5A3" },
  { key: "NM_015720.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "50512", geneDisplay: "PODXL2" },
  { key: "NM_015721.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "50628", geneDisplay: "GEMIN4" },
  { key: "NM_015722.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "50632", geneDisplay: "CALY" },
  { key: "NM_015725.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "50700", geneDisplay: "RDH8" },
  { key: "NM_015726.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50717", geneDisplay: "DCAF8" },
  { key: "NM_015836.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10352", geneDisplay: "WARS2" },
  { key: "NM_015846.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4152", geneDisplay: "MBD1" },
  { key: "NM_015848.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51350", geneDisplay: "KRT76" },
  { key: "NM_015849.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51032", geneDisplay: "CELA2B" },
  { key: "NM_015852.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51351", geneDisplay: "ZNF117" },
  { key: "NM_015859.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2957", geneDisplay: "GTF2A1" },
  { key: "NM_015865.7", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6563", geneDisplay: "SLC14A1" },
  { key: "NM_015868.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3804", geneDisplay: "KIR2DL3" },
  { key: "NM_015871.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51042", geneDisplay: "ZNF593" },
  { key: "NM_015873.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "50853", geneDisplay: "VILL" },
  { key: "NM_015874.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3516", geneDisplay: "RBPJ" },
  { key: "NM_015879.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "51046", geneDisplay: "ST8SIA3" },
  { key: "NM_015884.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51360", geneDisplay: "MBTPS2" },
  { key: "NM_015886.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51050", geneDisplay: "PI15" },
  { key: "NM_015888.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51361", geneDisplay: "HOOK1" },
  { key: "NM_015891.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51362", geneDisplay: "CDC40" },
  { key: "NM_015893.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51052", geneDisplay: "PRLH" },
  { key: "NM_015894.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "50861", geneDisplay: "STMN3" },
  { key: "NM_015895.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51053", geneDisplay: "GMNN" },
  { key: "NM_015896.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51364", geneDisplay: "ZMYND10" },
  { key: "NM_015897.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51588", geneDisplay: "PIAS4" },
  { key: "NM_015898.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51341", geneDisplay: "ZBTB7A" },
  { key: "NM_015900.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51365", geneDisplay: "PLA1A" },
  { key: "NM_015901.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "25961", geneDisplay: "NUDT13" },
  { key: "NM_015902.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51366", geneDisplay: "UBR5" },
  { key: "NM_015904.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9669", geneDisplay: "EIF5B" },
  { key: "NM_015905.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8805", geneDisplay: "TRIM24" },
  { key: "NM_015906.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51592", geneDisplay: "TRIM33" },
  { key: "NM_015907.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51056", geneDisplay: "LAP3" },
  { key: "NM_015908.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51593", geneDisplay: "SRRT" },
  { key: "NM_015909.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51594", geneDisplay: "NBAS" },
  { key: "NM_015910.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51057", geneDisplay: "WDPCP" },
  { key: "NM_015912.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51059", geneDisplay: "FAM135B" },
  { key: "NM_015913.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51060", geneDisplay: "TXNDC12" },
  { key: "NM_015914.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51061", geneDisplay: "TXNDC11" },
  { key: "NM_015915.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51062", geneDisplay: "ATL1" },
  { key: "NM_015916.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51063", geneDisplay: "CALHM2" },
  { key: "NM_015917.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "373156", geneDisplay: "GSTK1" },
  { key: "NM_015918.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51367", geneDisplay: "POP5" },
  { key: "NM_015920.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51065", geneDisplay: "RPS27L" },
  { key: "NM_015922.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "50814", geneDisplay: "NSDHL" },
  { key: "NM_015926.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51368", geneDisplay: "TEX264" },
  { key: "NM_015932.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51371", geneDisplay: "POMP" },
  { key: "NM_015933.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51372", geneDisplay: "TMA7" },
  { key: "NM_015934.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51602", geneDisplay: "NOP58" },
  { key: "NM_015935.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51603", geneDisplay: "METTL13" },
  { key: "NM_015937.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51604", geneDisplay: "PIGT" },
  { key: "NM_015938.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51068", geneDisplay: "NMD3" },
  { key: "NM_015939.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51605", geneDisplay: "TRMT6" },
  { key: "NM_015941.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51606", geneDisplay: "ATP6V1H" },
  { key: "NM_015942.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51001", geneDisplay: "MTERF3" },
  { key: "NM_015945.12", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51006", geneDisplay: "SLC35C2" },
  { key: "NM_015946.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "53918", geneDisplay: "PELO" },
  { key: "NM_015949.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51608", geneDisplay: "GET4" },
  { key: "NM_015950.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51069", geneDisplay: "MRPL2" },
  { key: "NM_015952.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51389", geneDisplay: "RWDD1" },
  { key: "NM_015954.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51071", geneDisplay: "DERA" },
  { key: "NM_015956.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51073", geneDisplay: "MRPL4" },
  { key: "NM_015957.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51074", geneDisplay: "APIP" },
  { key: "NM_015958.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51611", geneDisplay: "DPH5" },
  { key: "NM_015959.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51075", geneDisplay: "TMX2" },
  { key: "NM_015960.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51076", geneDisplay: "CUTC" },
  { key: "NM_015962.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51077", geneDisplay: "FCF1" },
  { key: "NM_015963.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51078", geneDisplay: "THAP4" },
  { key: "NM_015964.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51673", geneDisplay: "TPPP3" },
  { key: "NM_015965.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51079", geneDisplay: "NDUFA13" },
  { key: "NM_015966.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51614", geneDisplay: "ERGIC3" },
  { key: "NM_015967.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26191", geneDisplay: "PTPN22" },
  { key: "NM_015969.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51373", geneDisplay: "MRPS17" },
  { key: "NM_015971.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51081", geneDisplay: "MRPS7" },
  { key: "NM_015972.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51082", geneDisplay: "POLR1D" },
  { key: "NM_015973.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51083", geneDisplay: "GAL" },
  { key: "NM_015974.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51084", geneDisplay: "CRYL1" },
  { key: "NM_015975.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51616", geneDisplay: "TAF9B" },
  { key: "NM_015976.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51375", geneDisplay: "SNX7" },
  { key: "NM_015978.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51086", geneDisplay: "TNNI3K" },
  { key: "NM_015980.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51617", geneDisplay: "NSG2" },
  { key: "NM_015981.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "815", geneDisplay: "CAMK2A" },
  { key: "NM_015982.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51087", geneDisplay: "YBX2" },
  { key: "NM_015983.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51619", geneDisplay: "UBE2D4" },
  { key: "NM_015985.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51378", geneDisplay: "ANGPT4" },
  { key: "NM_015986.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51379", geneDisplay: "CRLF3" },
  { key: "NM_015987.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50865", geneDisplay: "HEBP1" },
  { key: "NM_015990.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51088", geneDisplay: "KLHL5" },
  { key: "NM_015991.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "712", geneDisplay: "C1QA" },
  { key: "NM_015993.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51090", geneDisplay: "PLLP" },
  { key: "NM_015994.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51382", geneDisplay: "ATP6V1D" },
  { key: "NM_015995.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51621", geneDisplay: "KLF13" },
  { key: "NM_015997.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51093", geneDisplay: "METTL25B" },
  { key: "NM_015999.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51094", geneDisplay: "ADIPOR1" },
  { key: "NM_016001.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51096", geneDisplay: "UTP18" },
  { key: "NM_016002.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51097", geneDisplay: "SCCPDH" },
  { key: "NM_016004.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51098", geneDisplay: "IFT52" },
  { key: "NM_016006.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51099", geneDisplay: "ABHD5" },
  { key: "NM_016008.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51626", geneDisplay: "DYNC2LI1" },
  { key: "NM_016009.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51100", geneDisplay: "SH3GLB1" },
  { key: "NM_016010.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51101", geneDisplay: "ZC2HC1A" },
  { key: "NM_016011.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51102", geneDisplay: "MECR" },
  { key: "NM_016013.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51103", geneDisplay: "NDUFAF1" },
  { key: "NM_016018.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51105", geneDisplay: "PHF20L1" },
  { key: "NM_016019.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51631", geneDisplay: "LUC7L2" },
  { key: "NM_016020.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51106", geneDisplay: "TFB1M" },
  { key: "NM_016021.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51465", geneDisplay: "UBE2J1" },
  { key: "NM_016023.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51633", geneDisplay: "OTUD6B" },
  { key: "NM_016024.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51634", geneDisplay: "RBMX2" },
  { key: "NM_016025.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51108", geneDisplay: "METTL9" },
  { key: "NM_016026.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51109", geneDisplay: "RDH11" },
  { key: "NM_016027.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51110", geneDisplay: "LACTB2" },
  { key: "NM_016029.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51635", geneDisplay: "DHRS7" },
  { key: "NM_016030.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51112", geneDisplay: "TRAPPC12" },
  { key: "NM_016032.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51114", geneDisplay: "ZDHHC9" },
  { key: "NM_016033.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51115", geneDisplay: "RMDN1" },
  { key: "NM_016034.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51116", geneDisplay: "MRPS2" },
  { key: "NM_016035.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51117", geneDisplay: "COQ4" },
  { key: "NM_016037.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51118", geneDisplay: "UTP11" },
  { key: "NM_016038.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51119", geneDisplay: "SBDS" },
  { key: "NM_016039.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51637", geneDisplay: "RTRAF" },
  { key: "NM_016040.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50999", geneDisplay: "TMED5" },
  { key: "NM_016041.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51009", geneDisplay: "DERL2" },
  { key: "NM_016042.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51010", geneDisplay: "EXOSC3" },
  { key: "NM_016044.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51011", geneDisplay: "FAHD2A" },
  { key: "NM_016045.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51012", geneDisplay: "PRELID3B" },
  { key: "NM_016046.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51013", geneDisplay: "EXOSC1" },
  { key: "NM_016047.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51639", geneDisplay: "SF3B6" },
  { key: "NM_016048.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51015", geneDisplay: "ISOC1" },
  { key: "NM_016049.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51016", geneDisplay: "EMC9" },
  { key: "NM_016050.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "65003", geneDisplay: "MRPL11" },
  { key: "NM_016052.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51018", geneDisplay: "RRP15" },
  { key: "NM_016053.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51019", geneDisplay: "WASHC3" },
  { key: "NM_016055.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51642", geneDisplay: "MRPL48" },
  { key: "NM_016056.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51643", geneDisplay: "TMBIM4" },
  { key: "NM_016057.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22818", geneDisplay: "COPZ1" },
  { key: "NM_016058.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51002", geneDisplay: "TPRKB" },
  { key: "NM_016059.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51645", geneDisplay: "PPIL1" },
  { key: "NM_016060.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51003", geneDisplay: "MED31" },
  { key: "NM_016061.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51646", geneDisplay: "YPEL5" },
  { key: "NM_016062.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51647", geneDisplay: "CIAO2B" },
  { key: "NM_016063.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51020", geneDisplay: "HDDC2" },
  { key: "NM_016065.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51021", geneDisplay: "MRPS16" },
  { key: "NM_016067.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51023", geneDisplay: "MRPS18C" },
  { key: "NM_016068.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51024", geneDisplay: "FIS1" },
  { key: "NM_016069.11", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51025", geneDisplay: "PAM16" },
  { key: "NM_016070.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51649", geneDisplay: "MRPS23" },
  { key: "NM_016072.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51026", geneDisplay: "GOLT1B" },
  { key: "NM_016073.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "50810", geneDisplay: "HDGFL3" },
  { key: "NM_016074.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51027", geneDisplay: "BOLA1" },
  { key: "NM_016075.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51028", geneDisplay: "VPS36" },
  { key: "NM_016076.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51029", geneDisplay: "DESI2" },
  { key: "NM_016077.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51651", geneDisplay: "PTRH2" },
  { key: "NM_016078.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51030", geneDisplay: "TVP23B" },
  { key: "NM_016079.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51652", geneDisplay: "CHMP3" },
  { key: "NM_016080.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51031", geneDisplay: "GLOD4" },
  { key: "NM_016083.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1268", geneDisplay: "CNR1" },
  { key: "NM_016084.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51655", geneDisplay: "RASD1" },
  { key: "NM_016089.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51385", geneDisplay: "ZNF589" },
  { key: "NM_016091.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51386", geneDisplay: "EIF3L" },
  { key: "NM_016093.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51121", geneDisplay: "RPL26L1" },
  { key: "NM_016094.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51122", geneDisplay: "COMMD2" },
  { key: "NM_016095.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51659", geneDisplay: "GINS2" },
  { key: "NM_016096.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51123", geneDisplay: "ZNF706" },
  { key: "NM_016097.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "51124", geneDisplay: "IER3IP1" },
  { key: "NM_016098.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51660", geneDisplay: "MPC1" },
  { key: "NM_016100.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51126", geneDisplay: "NAA20" },
  { key: "NM_016101.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51388", geneDisplay: "NIP7" },
  { key: "NM_016102.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51127", geneDisplay: "TRIM17" },
  { key: "NM_016103.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51128", geneDisplay: "SAR1B" },
  { key: "NM_016106.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23256", geneDisplay: "SCFD1" },
  { key: "NM_016107.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51663", geneDisplay: "ZFR" },
  { key: "NM_016108.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51390", geneDisplay: "AIG1" },
  { key: "NM_016111.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9894", geneDisplay: "TELO2" },
  { key: "NM_016112.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9033", geneDisplay: "PKD2L1" },
  { key: "NM_016113.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51393", geneDisplay: "TRPV2" },
  { key: "NM_016115.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51130", geneDisplay: "ASB3" },
  { key: "NM_016116.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51666", geneDisplay: "ASB4" },
  { key: "NM_016120.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51132", geneDisplay: "RLIM" },
  { key: "NM_016121.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51133", geneDisplay: "KCTD3" },
  { key: "NM_016122.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51134", geneDisplay: "CEP83" },
  { key: "NM_016123.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51135", geneDisplay: "IRAK4" },
  { key: "NM_016124.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6007", geneDisplay: "RHD" },
  { key: "NM_016125.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51136", geneDisplay: "RNFT1" },
  { key: "NM_016126.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51668", geneDisplay: "HSPB11" },
  { key: "NM_016127.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51669", geneDisplay: "SARAF" },
  { key: "NM_016128.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22820", geneDisplay: "COPG1" },
  { key: "NM_016129.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51138", geneDisplay: "COPS4" },
  { key: "NM_016131.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10890", geneDisplay: "RAB10" },
  { key: "NM_016132.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "50804", geneDisplay: "MYEF2" },
  { key: "NM_016133.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51141", geneDisplay: "INSIG2" },
  { key: "NM_016134.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10404", geneDisplay: "CPQ" },
  { key: "NM_016135.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51513", geneDisplay: "ETV7" },
  { key: "NM_016138.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10229", geneDisplay: "COQ7" },
  { key: "NM_016139.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51142", geneDisplay: "CHCHD2" },
  { key: "NM_016141.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51143", geneDisplay: "DYNC1LI1" },
  { key: "NM_016142.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51144", geneDisplay: "HSD17B12" },
  { key: "NM_016143.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55968", geneDisplay: "NSFL1C" },
  { key: "NM_016144.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51397", geneDisplay: "COMMD10" },
  { key: "NM_016145.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51398", geneDisplay: "WDR83OS" },
  { key: "NM_016146.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51399", geneDisplay: "TRAPPC4" },
  { key: "NM_016147.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51400", geneDisplay: "PPME1" },
  { key: "NM_016148.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "50944", geneDisplay: "SHANK1" },
  { key: "NM_016151.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9344", geneDisplay: "TAOK2" },
  { key: "NM_016153.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100506164", geneDisplay: "HSFX1" },
  { key: "NM_016154.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53916", geneDisplay: "RAB4B" },
  { key: "NM_016155.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4326", geneDisplay: "MMP17" },
  { key: "NM_016156.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8898", geneDisplay: "MTMR2" },
  { key: "NM_016161.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51146", geneDisplay: "A4GNT" },
  { key: "NM_016162.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51147", geneDisplay: "ING4" },
  { key: "NM_016166.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8554", geneDisplay: "PIAS1" },
  { key: "NM_016167.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51406", geneDisplay: "NOL7" },
  { key: "NM_016169.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51684", geneDisplay: "SUFU" },
  { key: "NM_016170.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3196", geneDisplay: "TLX2" },
  { key: "NM_016172.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10422", geneDisplay: "UBAC1" },
  { key: "NM_016173.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51409", geneDisplay: "HEMK1" },
  { key: "NM_016174.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51148", geneDisplay: "CERCAM" },
  { key: "NM_016175.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51149", geneDisplay: "MRNIP" },
  { key: "NM_016176.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51150", geneDisplay: "SDF4" },
  { key: "NM_016178.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51686", geneDisplay: "OAZ3" },
  { key: "NM_016179.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7223", geneDisplay: "TRPC4" },
  { key: "NM_016180.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51151", geneDisplay: "SLC45A2" },
  { key: "NM_016183.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51154", geneDisplay: "MRTO4" },
  { key: "NM_016184.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50856", geneDisplay: "CLEC4A" },
  { key: "NM_016185.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51155", geneDisplay: "JPT1" },
  { key: "NM_016188.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51412", geneDisplay: "ACTL6B" },
  { key: "NM_016190.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "49860", geneDisplay: "CRNN" },
  { key: "NM_016192.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23671", geneDisplay: "TMEFF2" },
  { key: "NM_016194.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10681", geneDisplay: "GNB5" },
  { key: "NM_016196.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9904", geneDisplay: "RBM19" },
  { key: "NM_016199.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51690", geneDisplay: "LSM7" },
  { key: "NM_016200.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51691", geneDisplay: "LSM8" },
  { key: "NM_016201.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51421", geneDisplay: "AMOTL2" },
  { key: "NM_016203.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51422", geneDisplay: "PRKAG2" },
  { key: "NM_016204.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2658", geneDisplay: "GDF2" },
  { key: "NM_016205.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56034", geneDisplay: "PDGFC" },
  { key: "NM_016206.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389136", geneDisplay: "VGLL3" },
  { key: "NM_016207.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51692", geneDisplay: "CPSF3" },
  { key: "NM_016208.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51160", geneDisplay: "VPS28" },
  { key: "NM_016210.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51161", geneDisplay: "C3orf18" },
  { key: "NM_016212.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "24150", geneDisplay: "TP53TG3" },
  { key: "NM_016213.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9325", geneDisplay: "TRIP4" },
  { key: "NM_016215.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51162", geneDisplay: "EGFL7" },
  { key: "NM_016216.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51163", geneDisplay: "DBR1" },
  { key: "NM_016217.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51696", geneDisplay: "HECA" },
  { key: "NM_016218.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51426", geneDisplay: "POLK" },
  { key: "NM_016219.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11253", geneDisplay: "MAN1B1" },
  { key: "NM_016221.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51164", geneDisplay: "DCTN4" },
  { key: "NM_016222.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51428", geneDisplay: "DDX41" },
  { key: "NM_016223.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29763", geneDisplay: "PACSIN3" },
  { key: "NM_016224.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51429", geneDisplay: "SNX9" },
  { key: "NM_016226.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51699", geneDisplay: "VPS29" },
  { key: "NM_016228.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51166", geneDisplay: "AADAT" },
  { key: "NM_016229.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51700", geneDisplay: "CYB5R2" },
  { key: "NM_016230.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51167", geneDisplay: "CYB5R4" },
  { key: "NM_016231.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51701", geneDisplay: "NLK" },
  { key: "NM_016232.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9173", geneDisplay: "IL1RL1" },
  { key: "NM_016233.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51702", geneDisplay: "PADI3" },
  { key: "NM_016235.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51704", geneDisplay: "GPRC5B" },
  { key: "NM_016237.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51433", geneDisplay: "ANAPC5" },
  { key: "NM_016238.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51434", geneDisplay: "ANAPC7" },
  { key: "NM_016239.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51168", geneDisplay: "MYO15A" },
  { key: "NM_016240.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51435", geneDisplay: "SCARA3" },
  { key: "NM_016242.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51705", geneDisplay: "EMCN" },
  { key: "NM_016243.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51706", geneDisplay: "CYB5R1" },
  { key: "NM_016245.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51170", geneDisplay: "HSD17B11" },
  { key: "NM_016246.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51171", geneDisplay: "HSD17B14" },
  { key: "NM_016247.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "50939", geneDisplay: "IMPG2" },
  { key: "NM_016248.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11215", geneDisplay: "AKAP11" },
  { key: "NM_016249.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51438", geneDisplay: "MAGEC2" },
  { key: "NM_016252.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57448", geneDisplay: "BIRC6" },
  { key: "NM_016255.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51439", geneDisplay: "FAM8A1" },
  { key: "NM_016256.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51172", geneDisplay: "NAGPA" },
  { key: "NM_016257.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51440", geneDisplay: "HPCAL4" },
  { key: "NM_016258.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51441", geneDisplay: "YTHDF2" },
  { key: "NM_016261.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51174", geneDisplay: "TUBD1" },
  { key: "NM_016262.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51175", geneDisplay: "TUBE1" },
  { key: "NM_016263.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51343", geneDisplay: "FZR1" },
  { key: "NM_016264.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51710", geneDisplay: "ZNF44" },
  { key: "NM_016265.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7559", geneDisplay: "ZNF12" },
  { key: "NM_016267.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51442", geneDisplay: "VGLL1" },
  { key: "NM_016269.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51176", geneDisplay: "LEF1" },
  { key: "NM_016270.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10365", geneDisplay: "KLF2" },
  { key: "NM_016271.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "51444", geneDisplay: "RNF138" },
  { key: "NM_016272.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10766", geneDisplay: "TOB2" },
  { key: "NM_016274.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51177", geneDisplay: "PLEKHO1" },
  { key: "NM_016275.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51714", geneDisplay: "SELENOT" },
  { key: "NM_016277.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51715", geneDisplay: "RAB23" },
  { key: "NM_016279.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1007", geneDisplay: "CDH9" },
  { key: "NM_016281.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51347", geneDisplay: "TAOK3" },
  { key: "NM_016282.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "50808", geneDisplay: "AK3" },
  { key: "NM_016283.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "102157402", geneDisplay: "AK6" },
  { key: "NM_016284.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23019", geneDisplay: "CNOT1" },
  { key: "NM_016286.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51181", geneDisplay: "DCXR" },
  { key: "NM_016289.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51719", geneDisplay: "CAB39" },
  { key: "NM_016291.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51447", geneDisplay: "IP6K2" },
  { key: "NM_016292.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10131", geneDisplay: "TRAP1" },
  { key: "NM_016293.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51411", geneDisplay: "BIN2" },
  { key: "NM_016297.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51449", geneDisplay: "PCYOX1" },
  { key: "NM_016298.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51725", geneDisplay: "FBXO40" },
  { key: "NM_016299.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51182", geneDisplay: "HSPA14" },
  { key: "NM_016301.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51184", geneDisplay: "GPN3" },
  { key: "NM_016302.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51185", geneDisplay: "CRBN" },
  { key: "NM_016303.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51186", geneDisplay: "TCEAL9" },
  { key: "NM_016304.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51187", geneDisplay: "RSL24D1" },
  { key: "NM_016306.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51726", geneDisplay: "DNAJB11" },
  { key: "NM_016307.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51450", geneDisplay: "PRRX2" },
  { key: "NM_016308.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51727", geneDisplay: "CMPK1" },
  { key: "NM_016309.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51451", geneDisplay: "LCMT1" },
  { key: "NM_016310.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51728", geneDisplay: "POLR3K" },
  { key: "NM_016311.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93974", geneDisplay: "ATP5IF1" },
  { key: "NM_016312.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51729", geneDisplay: "WBP11" },
  { key: "NM_016315.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51454", geneDisplay: "GULP1" },
  { key: "NM_016316.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51455", geneDisplay: "REV1" },
  { key: "NM_016320.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4928", geneDisplay: "NUP98" },
  { key: "NM_016321.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51458", geneDisplay: "RHCG" },
  { key: "NM_016322.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51552", geneDisplay: "RAB14" },
  { key: "NM_016323.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51191", geneDisplay: "HERC5" },
  { key: "NM_016327.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51733", geneDisplay: "UPB1" },
  { key: "NM_016329.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51460", geneDisplay: "SFMBT1" },
  { key: "NM_016332.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51734", geneDisplay: "MSRB1" },
  { key: "NM_016333.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23524", geneDisplay: "SRRM2" },
  { key: "NM_016334.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51463", geneDisplay: "GPR89B" },
  { key: "NM_016335.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5625", geneDisplay: "PRODH" },
  { key: "NM_016337.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51466", geneDisplay: "EVL" },
  { key: "NM_016338.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51194", geneDisplay: "IPO11" },
  { key: "NM_016339.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51195", geneDisplay: "RAPGEFL1" },
  { key: "NM_016340.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51735", geneDisplay: "RAPGEF6" },
  { key: "NM_016341.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51196", geneDisplay: "PLCE1" },
  { key: "NM_016343.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1063", geneDisplay: "CENPF" },
  { key: "NM_016352.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51200", geneDisplay: "CPA4" },
  { key: "NM_016353.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51201", geneDisplay: "ZDHHC2" },
  { key: "NM_016354.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "28231", geneDisplay: "SLCO4A1" },
  { key: "NM_016355.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51202", geneDisplay: "DDX47" },
  { key: "NM_016356.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51473", geneDisplay: "DCDC2" },
  { key: "NM_016357.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51474", geneDisplay: "LIMA1" },
  { key: "NM_016358.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "50805", geneDisplay: "IRX4" },
  { key: "NM_016359.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51203", geneDisplay: "NUSAP1" },
  { key: "NM_016360.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51204", geneDisplay: "TACO1", startMSAPos: 22 },
  { key: "NM_016361.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51205", geneDisplay: "ACP6" },
  { key: "NM_016362.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51738", geneDisplay: "GHRL" },
  { key: "NM_016366.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51475", geneDisplay: "CABP2" },
  { key: "NM_016368.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51477", geneDisplay: "ISYNA1" },
  { key: "NM_016369.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51208", geneDisplay: "CLDN18" },
  { key: "NM_016370.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51209", geneDisplay: "RAB9B" },
  { key: "NM_016371.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51478", geneDisplay: "HSD17B7" },
  { key: "NM_016373.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51741", geneDisplay: "WWOX" },
  { key: "NM_016374.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51742", geneDisplay: "ARID4B" },
  { key: "NM_016377.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9465", geneDisplay: "AKAP7" },
  { key: "NM_016379.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51481", geneDisplay: "VCX3A" },
  { key: "NM_016378.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51480", geneDisplay: "VCX2" },
  { key: "NM_016382.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51744", geneDisplay: "CD244" },
  { key: "NM_016383.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51213", geneDisplay: "LUZP4" },
  { key: "NM_016388.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "50852", geneDisplay: "TRAT1" },
  { key: "NM_016390.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51490", geneDisplay: "SPOUT1" },
  { key: "NM_016391.8", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51491", geneDisplay: "NOP16" },
  { key: "NM_016395.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51495", geneDisplay: "HACD3" },
  { key: "NM_016396.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51496", geneDisplay: "CTDSPL2" },
  { key: "NM_016399.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51499", geneDisplay: "TRIAP1" },
  { key: "NM_016400.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "25764", geneDisplay: "HYPK" },
  { key: "NM_016401.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51501", geneDisplay: "HIKESHI" },
  { key: "NM_016403.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51503", geneDisplay: "CWC15" },
  { key: "NM_016404.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51504", geneDisplay: "TRMT112" },
  { key: "NM_016406.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51506", geneDisplay: "UFC1" },
  { key: "NM_016407.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51507", geneDisplay: "RTF2" },
  { key: "NM_016408.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51654", geneDisplay: "CDK5RAP1" },
  { key: "NM_016410.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51510", geneDisplay: "CHMP5" },
  { key: "NM_016417.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51218", geneDisplay: "GLRX5" },
  { key: "NM_016422.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50862", geneDisplay: "RNF141" },
  { key: "NM_016423.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51222", geneDisplay: "ZNF219" },
  { key: "NM_016424.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51747", geneDisplay: "LUC7L3" },
  { key: "NM_016426.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51512", geneDisplay: "GTSE1" },
  { key: "NM_016427.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "51224", geneDisplay: "ELOA2" },
  { key: "NM_016428.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51225", geneDisplay: "ABI3" },
  { key: "NM_016429.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51226", geneDisplay: "COPZ2" },
  { key: "NM_016433.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51228", geneDisplay: "GLTP" },
  { key: "NM_016436.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51230", geneDisplay: "PHF20" },
  { key: "NM_016437.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27175", geneDisplay: "TUBG2" },
  { key: "NM_016438.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51751", geneDisplay: "HIGD1B" },
  { key: "NM_016440.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51231", geneDisplay: "VRK3" },
  { key: "NM_016441.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51232", geneDisplay: "CRIM1" },
  { key: "NM_016445.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26499", geneDisplay: "PLEK2" },
  { key: "NM_016448.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51514", geneDisplay: "DTL" },
  { key: "NM_016449.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51233", geneDisplay: "DRICH1" },
  { key: "NM_016453.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51517", geneDisplay: "NCKIPSD" },
  { key: "NM_016454.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51234", geneDisplay: "EMC4" },
  { key: "NM_016457.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25865", geneDisplay: "PRKD2" },
  { key: "NM_016458.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51236", geneDisplay: "HGH1" },
  { key: "NM_016459.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51237", geneDisplay: "MZB1" },
  { key: "NM_016462.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51522", geneDisplay: "TMEM14C" },
  { key: "NM_016463.9", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51523", geneDisplay: "CXXC5" },
  { key: "NM_016464.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51524", geneDisplay: "TMEM138" },
  { key: "NM_016466.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51239", geneDisplay: "ANKRD39" },
  { key: "NM_016467.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "94101", geneDisplay: "ORMDL1" },
  { key: "NM_016468.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51241", geneDisplay: "COX16" },
  { key: "NM_016470.8", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51526", geneDisplay: "OSER1" },
  { key: "NM_016472.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51527", geneDisplay: "GSKIP" },
  { key: "NM_016474.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51244", geneDisplay: "CCDC174" },
  { key: "NM_016475.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51528", geneDisplay: "JKAMP" },
  { key: "NM_016478.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51530", geneDisplay: "ZC3HC1" },
  { key: "NM_016479.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51246", geneDisplay: "SHISA5" },
  { key: "NM_016480.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51247", geneDisplay: "PAIP2" },
  { key: "NM_016481.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51531", geneDisplay: "TRMO" },
  { key: "NM_016483.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51533", geneDisplay: "PHF7" },
  { key: "NM_016484.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51248", geneDisplay: "PDZD11" },
  { key: "NM_016485.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51534", geneDisplay: "VTA1" },
  { key: "NM_016486.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51249", geneDisplay: "TMEM69" },
  { key: "NM_016487.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51250", geneDisplay: "MTRES1" },
  { key: "NM_016491.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51253", geneDisplay: "MRPL37" },
  { key: "NM_016492.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29098", geneDisplay: "RANGRF" },
  { key: "NM_016494.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51255", geneDisplay: "RNF181" },
  { key: "NM_016495.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51256", geneDisplay: "TBC1D7" },
  { key: "NM_016497.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51258", geneDisplay: "MRPL51" },
  { key: "NM_016498.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51537", geneDisplay: "MTFP1" },
  { key: "NM_016500.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51260", geneDisplay: "PBDC1" },
  { key: "NM_016504.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51264", geneDisplay: "MRPL27" },
  { key: "NM_016505.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51538", geneDisplay: "ZCCHC17" },
  { key: "NM_016507.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51755", geneDisplay: "CDK12" },
  { key: "NM_016509.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51266", geneDisplay: "CLEC1B" },
  { key: "NM_016510.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51540", geneDisplay: "SCLY" },
  { key: "NM_016511.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51267", geneDisplay: "CLEC1A" },
  { key: "NM_016516.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51542", geneDisplay: "VPS54" },
  { key: "NM_016518.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51268", geneDisplay: "PIPOX" },
  { key: "NM_016519.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "258", geneDisplay: "AMBN" },
  { key: "NM_016520.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51759", geneDisplay: "C9orf78" },
  { key: "NM_016521.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51270", geneDisplay: "TFDP3" },
  { key: "NM_016523.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51348", geneDisplay: "KLRF1" },
  { key: "NM_016524.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51760", geneDisplay: "SYT17" },
  { key: "NM_016525.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51271", geneDisplay: "UBAP1" },
  { key: "NM_016527.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51179", geneDisplay: "HAO2" },
  { key: "NM_016529.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51761", geneDisplay: "ATP8A2" },
  { key: "NM_016530.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51762", geneDisplay: "RAB8B" },
  { key: "NM_016531.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51274", geneDisplay: "KLF3" },
  { key: "NM_016532.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51763", geneDisplay: "INPP5K" },
  { key: "NM_016533.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4815", geneDisplay: "NINJ2" },
  { key: "NM_016535.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51545", geneDisplay: "ZNF581" },
  { key: "NM_016536.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51276", geneDisplay: "ZNF571" },
  { key: "NM_016538.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51547", geneDisplay: "SIRT7" },
  { key: "NM_016539.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51548", geneDisplay: "SIRT6" },
  { key: "NM_016540.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10888", geneDisplay: "GPR83" },
  { key: "NM_016541.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51764", geneDisplay: "GNG13" },
  { key: "NM_016542.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51765", geneDisplay: "STK26" },
  { key: "NM_016544.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51277", geneDisplay: "DNAJC27" },
  { key: "NM_016545.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51278", geneDisplay: "IER5" },
  { key: "NM_016546.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51279", geneDisplay: "C1RL" },
  { key: "NM_016548.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51280", geneDisplay: "GOLM1" },
  { key: "NM_016551.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51768", geneDisplay: "TM7SF3" },
  { key: "NM_016553.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23636", geneDisplay: "NUP62" },
  { key: "NM_016556.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29893", geneDisplay: "PSMC3IP" },
  { key: "NM_016557.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51554", geneDisplay: "ACKR4" },
  { key: "NM_016559.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51555", geneDisplay: "PEX5L" },
  { key: "NM_016561.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51283", geneDisplay: "BFAR" },
  { key: "NM_016562.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51284", geneDisplay: "TLR7" },
  { key: "NM_016563.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51285", geneDisplay: "RASL12" },
  { key: "NM_016564.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51286", geneDisplay: "CEND1" },
  { key: "NM_016565.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51287", geneDisplay: "COA4" },
  { key: "NM_016568.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51289", geneDisplay: "RXFP3" },
  { key: "NM_016570.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51290", geneDisplay: "ERGIC2" },
  { key: "NM_016571.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51557", geneDisplay: "LGSN" },
  { key: "NM_016573.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51291", geneDisplay: "GMIP" },
  { key: "NM_016577.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51560", geneDisplay: "RAB6B" },
  { key: "NM_016578.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51773", geneDisplay: "RSF1" },
  { key: "NM_016579.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51293", geneDisplay: "CD320" },
  { key: "NM_016580.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51294", geneDisplay: "PCDH12" },
  { key: "NM_016581.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51295", geneDisplay: "ECSIT" },
  { key: "NM_016582.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51296", geneDisplay: "SLC15A3" },
  { key: "NM_016584.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51561", geneDisplay: "IL23A" },
  { key: "NM_016585.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51298", geneDisplay: "THEG" },
  { key: "NM_016586.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51562", geneDisplay: "MBIP" },
  { key: "NM_016587.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11335", geneDisplay: "CBX3" },
  { key: "NM_016588.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51299", geneDisplay: "NRN1" },
  { key: "NM_016589.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51300", geneDisplay: "TIMMDC1" },
  { key: "NM_016593.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51302", geneDisplay: "CYP39A1" },
  { key: "NM_016594.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51303", geneDisplay: "FKBP11" },
  { key: "NM_016599.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51778", geneDisplay: "MYOZ2" },
  { key: "NM_016602.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2826", geneDisplay: "CCR10" },
  { key: "NM_016604.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51780", geneDisplay: "KDM3B" },
  { key: "NM_016605.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51307", geneDisplay: "FAM53C" },
  { key: "NM_016608.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51309", geneDisplay: "ARMCX1" },
  { key: "NM_016609.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51310", geneDisplay: "SLC22A17" },
  { key: "NM_016612.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51312", geneDisplay: "SLC25A37" },
  { key: "NM_016614.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51567", geneDisplay: "TDP2" },
  { key: "NM_016615.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6540", geneDisplay: "SLC6A13" },
  { key: "NM_016616.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51314", geneDisplay: "NME8" },
  { key: "NM_016617.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51569", geneDisplay: "UFM1" },
  { key: "NM_016618.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51315", geneDisplay: "KRCC1" },
  { key: "NM_016619.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51316", geneDisplay: "PLAC8" },
  { key: "NM_016622.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51318", geneDisplay: "MRPL35" },
  { key: "NM_016626.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "51320", geneDisplay: "MEX3C" },
  { key: "NM_016627.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51321", geneDisplay: "AMZ2" },
  { key: "NM_016628.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51322", geneDisplay: "WAC" },
  { key: "NM_016630.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51324", geneDisplay: "SPG21" },
  { key: "NM_016631.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "94104", geneDisplay: "PAXBP1" },
  { key: "NM_016633.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51327", geneDisplay: "AHSP" },
  { key: "NM_016639.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51330", geneDisplay: "TNFRSF12A" },
  { key: "NM_016640.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10884", geneDisplay: "MRPS30" },
  { key: "NM_016641.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51573", geneDisplay: "GDE1" },
  { key: "NM_016642.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51332", geneDisplay: "SPTBN5" },
  { key: "NM_016647.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51337", geneDisplay: "THEM6" },
  { key: "NM_016648.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51574", geneDisplay: "LARP7" },
  { key: "NM_016653.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51776", geneDisplay: "MAP3K20" },
  { key: "NM_016654.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2553", geneDisplay: "GABPB1" },
  { key: "NM_016729.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2348", geneDisplay: "FOLR1" },
  { key: "NM_016732.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22913", geneDisplay: "RALY" },
  { key: "NM_016734.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5079", geneDisplay: "PAX5" },
  { key: "NM_016816.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4938", geneDisplay: "OAS1" },
  { key: "NM_016818.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9619", geneDisplay: "ABCG1" },
  { key: "NM_016823.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1398", geneDisplay: "CRK" },
  { key: "NM_016824.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "120", geneDisplay: "ADD3" },
  { key: "NM_016836.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5937", geneDisplay: "RBMS1" },
  { key: "NM_016848.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "53358", geneDisplay: "SHC3" },
  { key: "NM_016929.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "53405", geneDisplay: "CLIC5" },
  { key: "NM_016930.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "53407", geneDisplay: "STX18" },
  { key: "NM_016931.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50507", geneDisplay: "NOX4" },
  { key: "NM_016932.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10736", geneDisplay: "SIX2" },
  { key: "NM_016938.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "30008", geneDisplay: "EFEMP2" },
  { key: "NM_016940.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10069", geneDisplay: "RWDD2B" },
  { key: "NM_016943.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "50831", geneDisplay: "TAS2R3" },
  { key: "NM_016944.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "50832", geneDisplay: "TAS2R4" },
  { key: "NM_016945.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "50833", geneDisplay: "TAS2R16" },
  { key: "NM_016946.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50848", geneDisplay: "F11R" },
  { key: "NM_016951.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51192", geneDisplay: "CKLF" },
  { key: "NM_016953.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50940", geneDisplay: "PDE11A" },
  { key: "NM_016955.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51091", geneDisplay: "SEPSECS" },
  { key: "NM_017409.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3226", geneDisplay: "HOXC10" },
  { key: "NM_017410.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3229", geneDisplay: "HOXC13" },
  { key: "NM_017411.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6607", geneDisplay: "SMN2" },
  { key: "NM_017412.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7976", geneDisplay: "FZD3" },
  { key: "NM_017413.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8862", geneDisplay: "APLN" },
  { key: "NM_017414.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11274", geneDisplay: "USP18" },
  { key: "NM_017415.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26249", geneDisplay: "KLHL3" },
  { key: "NM_017416.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "26280", geneDisplay: "IL1RAPL2" },
  { key: "NM_017417.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "26290", geneDisplay: "GALNT8" },
  { key: "NM_017419.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51802", geneDisplay: "ASIC5" },
  { key: "NM_017420.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51804", geneDisplay: "SIX4" },
  { key: "NM_017421.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51805", geneDisplay: "COQ3" },
  { key: "NM_017422.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51806", geneDisplay: "CALML5" },
  { key: "NM_017423.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51809", geneDisplay: "GALNT7" },
  { key: "NM_017425.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53340", geneDisplay: "SPA17" },
  { key: "NM_017426.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "53371", geneDisplay: "NUP54" },
  { key: "NM_017429.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "53630", geneDisplay: "BCO1" },
  { key: "NM_017431.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "53632", geneDisplay: "PRKAG3" },
  { key: "NM_017433.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "53904", geneDisplay: "MYO3A" },
  { key: "NM_017435.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "53919", geneDisplay: "SLCO1C1" },
  { key: "NM_017436.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "53947", geneDisplay: "A4GALT" },
  { key: "NM_017437.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "53981", geneDisplay: "CPSF2" },
  { key: "NM_017438.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54093", geneDisplay: "SETD4" },
  { key: "NM_017439.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54103", geneDisplay: "GSAP" },
  { key: "NM_017442.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54106", geneDisplay: "TLR9" },
  { key: "NM_017443.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54107", geneDisplay: "POLE3" },
  { key: "NM_017444.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54108", geneDisplay: "CHRAC1" },
  { key: "NM_017445.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54145", geneDisplay: "H2BC12L" },
  { key: "NM_017446.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54148", geneDisplay: "MRPL39" },
  { key: "NM_017448.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3948", geneDisplay: "LDHC" },
  { key: "NM_017449.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2048", geneDisplay: "EPHB2" },
  { key: "NM_017453.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6780", geneDisplay: "STAU1" },
  { key: "NM_017460.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1576", geneDisplay: "CYP3A4" },
  { key: "NM_017481.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50613", geneDisplay: "UBQLN3" },
  { key: "NM_017489.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7013", geneDisplay: "TERF1" },
  { key: "NM_017491.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9948", geneDisplay: "WDR1" },
  { key: "NM_017495.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55544", geneDisplay: "RBM38" },
  { key: "NM_017503.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6835", geneDisplay: "SURF2" },
  { key: "NM_017504.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26245", geneDisplay: "OR2M4" },
  { key: "NM_017506.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26659", geneDisplay: "OR7A5" },
  { key: "NM_017509.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55554", geneDisplay: "KLK15" },
  { key: "NM_017510.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54732", geneDisplay: "TMED9" },
  { key: "NM_017512.7", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55556", geneDisplay: "ENOSF1" },
  { key: "NM_017514.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55558", geneDisplay: "PLXNA3" },
  { key: "NM_017515.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54733", geneDisplay: "SLC35F2" },
  { key: "NM_017516.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54734", geneDisplay: "RAB39A" },
  { key: "NM_017520.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "54737", geneDisplay: "MPHOSPH8" },
  { key: "NM_017521.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54738", geneDisplay: "FEV" },
  { key: "NM_017523.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54739", geneDisplay: "XAF1" },
  { key: "NM_017525.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55561", geneDisplay: "CDC42BPG" },
  { key: "NM_017526.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54741", geneDisplay: "LEPROT" },
  { key: "NM_017527.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54742", geneDisplay: "LY6K" },
  { key: "NM_017528.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "114049", geneDisplay: "BUD23" },
  { key: "NM_017533.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4622", geneDisplay: "MYH4" },
  { key: "NM_017534.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4620", geneDisplay: "MYH2" },
  { key: "NM_017541.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1427", geneDisplay: "CRYGS" },
  { key: "NM_017542.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57645", geneDisplay: "POGK" },
  { key: "NM_017544.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55922", geneDisplay: "NKRF" },
  { key: "NM_017545.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54363", geneDisplay: "HAO1" },
  { key: "NM_017546.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55571", geneDisplay: "CNOT11" },
  { key: "NM_017547.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55572", geneDisplay: "FOXRED1" },
  { key: "NM_017548.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55573", geneDisplay: "CDV3" },
  { key: "NM_017549.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54749", geneDisplay: "EPDR1" },
  { key: "NM_017550.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54531", geneDisplay: "MIER2" },
  { key: "NM_017551.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2894", geneDisplay: "GRID1" },
  { key: "NM_017552.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54454", geneDisplay: "ATAD2B" },
  { key: "NM_017553.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54617", geneDisplay: "INO80" },
  { key: "NM_017554.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54625", geneDisplay: "PARP14" },
  { key: "NM_017556.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54751", geneDisplay: "FBLIM1" },
  { key: "NM_017559.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54752", geneDisplay: "FNDC8" },
  { key: "NM_017560.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54753", geneDisplay: "ZNF853" },
  { key: "NM_017561.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54754", geneDisplay: "NUTM2F" },
  { key: "NM_017563.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54756", geneDisplay: "IL17RD" },
  { key: "NM_017564.10", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55576", geneDisplay: "STAB2" },
  { key: "NM_017565.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54757", geneDisplay: "FAM20A" },
  { key: "NM_017566.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54758", geneDisplay: "KLHDC4" },
  { key: "NM_017567.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55577", geneDisplay: "NAGK" },
  { key: "NM_017570.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26873", geneDisplay: "OPLAH" },
  { key: "NM_017573.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54760", geneDisplay: "PCSK4" },
  { key: "NM_017575.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23293", geneDisplay: "SMG6" },
  { key: "NM_017576.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55582", geneDisplay: "KIF27" },
  { key: "NM_017577.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54762", geneDisplay: "GRAMD1C" },
  { key: "NM_017580.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54764", geneDisplay: "ZRANB1" },
  { key: "NM_017581.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55584", geneDisplay: "CHRNA9" },
  { key: "NM_017582.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55585", geneDisplay: "UBE2Q1" },
  { key: "NM_017583.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54765", geneDisplay: "TRIM44" },
  { key: "NM_017584.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55586", geneDisplay: "MIOX" },
  { key: "NM_017585.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11182", geneDisplay: "SLC2A6" },
  { key: "NM_017586.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11094", geneDisplay: "CACFD1" },
  { key: "NM_017588.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11091", geneDisplay: "WDR5" },
  { key: "NM_017590.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23264", geneDisplay: "ZC3H7B" },
  { key: "NM_017592.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55588", geneDisplay: "MED29" },
  { key: "NM_017594.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54769", geneDisplay: "DIRAS2" },
  { key: "NM_017595.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "28511", geneDisplay: "NKIRAS2" },
  { key: "NM_017599.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55591", geneDisplay: "VEZT" },
  { key: "NM_017607.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54776", geneDisplay: "PPP1R12C" },
  { key: "NM_017610.8", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54778", geneDisplay: "RNF111" },
  { key: "NM_017612.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55596", geneDisplay: "ZCCHC8" },
  { key: "NM_017613.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "29980", geneDisplay: "DONSON" },
  { key: "NM_017614.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23743", geneDisplay: "BHMT2" },
  { key: "NM_017615.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54780", geneDisplay: "NSMCE4A" },
  { key: "NM_017617.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4851", geneDisplay: "NOTCH1" },
  { key: "NM_017619.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55599", geneDisplay: "RNPC3" },
  { key: "NM_017620.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3609", geneDisplay: "ILF3" },
  { key: "NM_017621.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54784", geneDisplay: "ALKBH4" },
  { key: "NM_017622.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54785", geneDisplay: "BORCS6" },
  { key: "NM_017623.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26505", geneDisplay: "CNNM3" },
  { key: "NM_017625.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55600", geneDisplay: "ITLN1" },
  { key: "NM_017626.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54788", geneDisplay: "DNAJB12" },
  { key: "NM_017629.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "192670", geneDisplay: "AGO4" },
  { key: "NM_017631.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55601", geneDisplay: "DDX60" },
  { key: "NM_017632.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55602", geneDisplay: "CDKN2AIP" },
  { key: "NM_017633.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55603", geneDisplay: "TENT5A" },
  { key: "NM_017634.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54793", geneDisplay: "KCTD9" },
  { key: "NM_017635.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51111", geneDisplay: "KMT5B" },
  { key: "NM_017636.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54795", geneDisplay: "TRPM4" },
  { key: "NM_017637.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54796", geneDisplay: "BNC2" },
  { key: "NM_017638.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54797", geneDisplay: "MED18" },
  { key: "NM_017640.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55604", geneDisplay: "CARMIL1" },
  { key: "NM_017643.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54799", geneDisplay: "MBTD1" },
  { key: "NM_017644.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54800", geneDisplay: "KLHL24" },
  { key: "NM_017645.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54801", geneDisplay: "HAUS6" },
  { key: "NM_017646.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54802", geneDisplay: "TRIT1" },
  { key: "NM_017647.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "117246", geneDisplay: "FTSJ3" },
  { key: "NM_017649.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54805", geneDisplay: "CNNM2" },
  { key: "NM_017652.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54807", geneDisplay: "ZNF586" },
  { key: "NM_017654.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54809", geneDisplay: "SAMD9" },
  { key: "NM_017655.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54810", geneDisplay: "GIPC2" },
  { key: "NM_017658.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "54813", geneDisplay: "KLHL28" },
  { key: "NM_017659.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54814", geneDisplay: "QPCTL" },
  { key: "NM_017661.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54816", geneDisplay: "ZNF280D" },
  { key: "NM_017662.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "140803", geneDisplay: "TRPM6" },
  { key: "NM_017664.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55608", geneDisplay: "ANKRD10" },
  { key: "NM_017666.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55609", geneDisplay: "ZNF280C" },
  { key: "NM_017667.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55610", geneDisplay: "VPS50" },
  { key: "NM_017668.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54820", geneDisplay: "NDE1" },
  { key: "NM_017669.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54821", geneDisplay: "ERCC6L" },
  { key: "NM_017670.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55611", geneDisplay: "OTUB1" },
  { key: "NM_017671.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55612", geneDisplay: "FERMT1" },
  { key: "NM_017672.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54822", geneDisplay: "TRPM7" },
  { key: "NM_017673.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54823", geneDisplay: "SWT1" },
  { key: "NM_017675.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54825", geneDisplay: "CDHR2" },
  { key: "NM_017676.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54826", geneDisplay: "GIN1" },
  { key: "NM_017677.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55613", geneDisplay: "MTMR8" },
  { key: "NM_017679.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54828", geneDisplay: "BCAS3" },
  { key: "NM_017680.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54829", geneDisplay: "ASPN" },
  { key: "NM_017681.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54830", geneDisplay: "NUP62CL" },
  { key: "NM_017682.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54831", geneDisplay: "BEST2" },
  { key: "NM_017686.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54834", geneDisplay: "GDAP2" },
  { key: "NM_017688.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54836", geneDisplay: "BSPRY" },
  { key: "NM_017691.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54839", geneDisplay: "LRRC49" },
  { key: "NM_017693.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "54841", geneDisplay: "BIVM" },
  { key: "NM_017694.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54842", geneDisplay: "MFSD6" },
  { key: "NM_017696.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "254394", geneDisplay: "MCM9" },
  { key: "NM_017697.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54845", geneDisplay: "ESRP1" },
  { key: "NM_017699.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54847", geneDisplay: "SIDT1" },
  { key: "NM_017703.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54850", geneDisplay: "FBXL12" },
  { key: "NM_017704.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54851", geneDisplay: "ANKRD49" },
  { key: "NM_017705.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54852", geneDisplay: "PAQR5" },
  { key: "NM_017706.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54853", geneDisplay: "WDR55" },
  { key: "NM_017707.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55616", geneDisplay: "ASAP3" },
  { key: "NM_017708.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54854", geneDisplay: "FAM83E" },
  { key: "NM_017709.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54855", geneDisplay: "TENT5C" },
  { key: "NM_017711.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54857", geneDisplay: "GDPD2" },
  { key: "NM_017712.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54858", geneDisplay: "PGPEP1" },
  { key: "NM_017714.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55617", geneDisplay: "TASP1" },
  { key: "NM_017716.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54860", geneDisplay: "MS4A12" },
  { key: "NM_017719.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54861", geneDisplay: "SNRK" },
  { key: "NM_017721.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54862", geneDisplay: "CC2D1A" },
  { key: "NM_017723.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54863", geneDisplay: "TOR4A" },
  { key: "NM_017726.8", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54866", geneDisplay: "PPP1R14D" },
  { key: "NM_017727.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54867", geneDisplay: "TMEM214" },
  { key: "NM_017728.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54868", geneDisplay: "TMEM104" },
  { key: "NM_017734.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54873", geneDisplay: "PALMD" },
  { key: "NM_017735.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55622", geneDisplay: "TTC27" },
  { key: "NM_017736.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55623", geneDisplay: "THUMPD1" },
  { key: "NM_017738.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54875", geneDisplay: "CNTLN" },
  { key: "NM_017739.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55624", geneDisplay: "POMGNT1" },
  { key: "NM_017740.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55625", geneDisplay: "ZDHHC7" },
  { key: "NM_017741.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54876", geneDisplay: "DCAF16" },
  { key: "NM_017742.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "54877", geneDisplay: "ZCCHC2" },
  { key: "NM_017744.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54879", geneDisplay: "ST7L" },
  { key: "NM_017746.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54881", geneDisplay: "TEX10" },
  { key: "NM_017747.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54882", geneDisplay: "ANKHD1" },
  { key: "NM_017748.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54883", geneDisplay: "CWC25" },
  { key: "NM_017750.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54884", geneDisplay: "RETSAT" },
  { key: "NM_017752.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54885", geneDisplay: "TBC1D8B" },
  { key: "NM_017754.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54887", geneDisplay: "UHRF1BP1" },
  { key: "NM_017755.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54888", geneDisplay: "NSUN2" },
  { key: "NM_017757.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55628", geneDisplay: "ZNF407" },
  { key: "NM_017758.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54890", geneDisplay: "ALKBH5" },
  { key: "NM_017759.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54891", geneDisplay: "INO80D" },
  { key: "NM_017760.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54892", geneDisplay: "NCAPG2" },
  { key: "NM_017761.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55629", geneDisplay: "PNRC2" },
  { key: "NM_017762.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54893", geneDisplay: "MTMR10" },
  { key: "NM_017763.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54894", geneDisplay: "RNF43" },
  { key: "NM_017768.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55631", geneDisplay: "LRRC40" },
  { key: "NM_017769.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55632", geneDisplay: "G2E3" },
  { key: "NM_017770.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54898", geneDisplay: "ELOVL2" },
  { key: "NM_017771.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54899", geneDisplay: "PXK" },
  { key: "NM_017772.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55633", geneDisplay: "TBC1D22B" },
  { key: "NM_017773.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54900", geneDisplay: "LAX1" },
  { key: "NM_017774.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54901", geneDisplay: "CDKAL1" },
  { key: "NM_017775.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54902", geneDisplay: "TTC19" },
  { key: "NM_017777.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54903", geneDisplay: "MKS1" },
  { key: "NM_017780.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55636", geneDisplay: "CHD7" },
  { key: "NM_017781.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54905", geneDisplay: "CYP2W1" },
  { key: "NM_017784.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "114884", geneDisplay: "OSBPL10" },
  { key: "NM_017785.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54908", geneDisplay: "SPDL1" },
  { key: "NM_017789.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54910", geneDisplay: "SEMA4C" },
  { key: "NM_017791.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55640", geneDisplay: "FLVCR2" },
  { key: "NM_017793.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54913", geneDisplay: "RPP25" },
  { key: "NM_017797.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55643", geneDisplay: "BTBD2" },
  { key: "NM_017798.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54915", geneDisplay: "YTHDF1" },
  { key: "NM_017799.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "54916", geneDisplay: "TMEM260" },
  { key: "NM_017801.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54918", geneDisplay: "CMTM6" },
  { key: "NM_017802.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54919", geneDisplay: "DNAAF5" },
  { key: "NM_017803.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54920", geneDisplay: "DUS2" },
  { key: "NM_017805.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54922", geneDisplay: "RASIP1" },
  { key: "NM_017806.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54923", geneDisplay: "LIME1" },
  { key: "NM_017807.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55644", geneDisplay: "OSGEP" },
  { key: "NM_017811.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54926", geneDisplay: "UBE2R2" },
  { key: "NM_017812.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54927", geneDisplay: "CHCHD3" },
  { key: "NM_017813.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54928", geneDisplay: "BPNT2" },
  { key: "NM_017814.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54929", geneDisplay: "TMEM161A" },
  { key: "NM_017816.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55646", geneDisplay: "LYAR" },
  { key: "NM_017817.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55647", geneDisplay: "RAB20" },
  { key: "NM_017818.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "49856", geneDisplay: "WRAP73" },
  { key: "NM_017819.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54931", geneDisplay: "TRMT10C" },
  { key: "NM_017820.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54932", geneDisplay: "EXD3" },
  { key: "NM_017821.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54933", geneDisplay: "RHBDL2" },
  { key: "NM_017822.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54934", geneDisplay: "KANSL2" },
  { key: "NM_017824.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54708", geneDisplay: "MARCHF5" },
  { key: "NM_017825.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54936", geneDisplay: "ADPRS" },
  { key: "NM_017826.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "54937", geneDisplay: "SOHLH2" },
  { key: "NM_017827.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54938", geneDisplay: "SARS2" },
  { key: "NM_017828.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54939", geneDisplay: "COMMD4" },
  { key: "NM_017830.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54940", geneDisplay: "OCIAD1" },
  { key: "NM_017831.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "54941", geneDisplay: "RNF125" },
  { key: "NM_017832.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54942", geneDisplay: "ABITRAM" },
  { key: "NM_017836.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54946", geneDisplay: "SLC41A3" },
  { key: "NM_017837.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55650", geneDisplay: "PIGV" },
  { key: "NM_017838.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55651", geneDisplay: "NHP2" },
  { key: "NM_017839.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54947", geneDisplay: "LPCAT2" },
  { key: "NM_017840.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54948", geneDisplay: "MRPL16" },
  { key: "NM_017841.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54949", geneDisplay: "SDHAF2" },
  { key: "NM_017842.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55652", geneDisplay: "SLC48A1" },
  { key: "NM_017845.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54951", geneDisplay: "COMMD8" },
  { key: "NM_017846.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54952", geneDisplay: "TRNAU1AP" },
  { key: "NM_017847.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54953", geneDisplay: "ODR4" },
  { key: "NM_017848.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54954", geneDisplay: "FAM120C" },
  { key: "NM_017849.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55654", geneDisplay: "TMEM127" },
  { key: "NM_017852.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55655", geneDisplay: "NLRP2" },
  { key: "NM_017853.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54957", geneDisplay: "TXNL4B" },
  { key: "NM_017854.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54958", geneDisplay: "TMEM160" },
  { key: "NM_017855.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54959", geneDisplay: "ODAM" },
  { key: "NM_017857.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54961", geneDisplay: "SSH3" },
  { key: "NM_017858.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54962", geneDisplay: "TIPIN" },
  { key: "NM_017859.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54963", geneDisplay: "UCKL1" },
  { key: "NM_017860.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54964", geneDisplay: "C1orf56" },
  { key: "NM_017861.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54965", geneDisplay: "PIGX" },
  { key: "NM_017864.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55656", geneDisplay: "INTS8" },
  { key: "NM_017865.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55657", geneDisplay: "ZNF692" },
  { key: "NM_017866.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54968", geneDisplay: "TMEM70" },
  { key: "NM_017867.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54969", geneDisplay: "HPF1" },
  { key: "NM_017868.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54970", geneDisplay: "TTC12" },
  { key: "NM_017871.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54973", geneDisplay: "INTS11" },
  { key: "NM_017872.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54974", geneDisplay: "THG1L" },
  { key: "NM_017873.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "140459", geneDisplay: "ASB6" },
  { key: "NM_017875.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54977", geneDisplay: "SLC25A38" },
  { key: "NM_017877.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54978", geneDisplay: "SLC35F6" },
  { key: "NM_017878.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54979", geneDisplay: "PLAAT2" },
  { key: "NM_017879.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55659", geneDisplay: "ZNF416" },
  { key: "NM_017880.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54980", geneDisplay: "C2orf42" },
  { key: "NM_017881.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54981", geneDisplay: "NMRK1" },
  { key: "NM_017882.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54982", geneDisplay: "CLN6" },
  { key: "NM_017884.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54984", geneDisplay: "PINX1" },
  { key: "NM_017885.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54985", geneDisplay: "HCFC1R1" },
  { key: "NM_017886.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54986", geneDisplay: "ULK4" },
  { key: "NM_017887.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54987", geneDisplay: "CZIB" },
  { key: "NM_017888.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54988", geneDisplay: "ACSM5" },
  { key: "NM_017891.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54991", geneDisplay: "C1orf159" },
  { key: "NM_017893.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57715", geneDisplay: "SEMA4G" },
  { key: "NM_017895.8", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55661", geneDisplay: "DDX27" },
  { key: "NM_017896.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54994", geneDisplay: "GID8" },
  { key: "NM_017897.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54995", geneDisplay: "OXSM" },
  { key: "NM_017898.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54996", geneDisplay: "MTARC2" },
  { key: "NM_017899.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54997", geneDisplay: "TESC" },
  { key: "NM_017900.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54998", geneDisplay: "AURKAIP1" },
  { key: "NM_017901.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "53373", geneDisplay: "TPCN1" },
  { key: "NM_017902.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55662", geneDisplay: "HIF1AN" },
  { key: "NM_017905.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55002", geneDisplay: "TMCO3" },
  { key: "NM_017906.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55003", geneDisplay: "PAK1IP1" },
  { key: "NM_017907.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55004", geneDisplay: "LAMTOR1" },
  { key: "NM_017908.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55663", geneDisplay: "ZNF446" },
  { key: "NM_017909.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55005", geneDisplay: "RMND1" },
  { key: "NM_017910.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55006", geneDisplay: "TRMT61B" },
  { key: "NM_017911.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55007", geneDisplay: "FAM118A" },
  { key: "NM_017912.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55008", geneDisplay: "HERC6" },
  { key: "NM_017913.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55664", geneDisplay: "CDC37L1" },
  { key: "NM_017914.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55009", geneDisplay: "FAM174C" },
  { key: "NM_017915.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55010", geneDisplay: "PARPBP" },
  { key: "NM_017916.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55011", geneDisplay: "PIH1D1" },
  { key: "NM_017917.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55012", geneDisplay: "PPP2R3C" },
  { key: "NM_017918.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55013", geneDisplay: "MCUB" },
  { key: "NM_017919.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55014", geneDisplay: "STX17" },
  { key: "NM_017921.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55666", geneDisplay: "NPLOC4" },
  { key: "NM_017922.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55015", geneDisplay: "PRPF39" },
  { key: "NM_017924.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55017", geneDisplay: "C14orf119" },
  { key: "NM_017926.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55668", geneDisplay: "GPATCH2L" },
  { key: "NM_017931.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55020", geneDisplay: "TTC38" },
  { key: "NM_017934.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55023", geneDisplay: "PHIP" },
  { key: "NM_017935.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55024", geneDisplay: "BANK1" },
  { key: "NM_017940.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55672", geneDisplay: "NBPF1" },
  { key: "NM_017943.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55030", geneDisplay: "FBXO34" },
  { key: "NM_017945.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55032", geneDisplay: "SLC35A5" },
  { key: "NM_017946.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55033", geneDisplay: "FKBP14" },
  { key: "NM_017947.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55034", geneDisplay: "MOCOS" },
  { key: "NM_017948.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55035", geneDisplay: "NOL8" },
  { key: "NM_017950.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55036", geneDisplay: "CCDC40" },
  { key: "NM_017951.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55627", geneDisplay: "SMPD4" },
  { key: "NM_017952.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55037", geneDisplay: "PTCD3" },
  { key: "NM_017953.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54680", geneDisplay: "ZNHIT6" },
  { key: "NM_017954.11", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "93664", geneDisplay: "CADPS2" },
  { key: "NM_017955.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55038", geneDisplay: "CDCA4" },
  { key: "NM_017956.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55039", geneDisplay: "TRMT12" },
  { key: "NM_017957.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55040", geneDisplay: "EPN3" },
  { key: "NM_017964.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55676", geneDisplay: "SLC30A6" },
  { key: "NM_017966.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55048", geneDisplay: "VPS37C" },
  { key: "NM_017969.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55677", geneDisplay: "IWS1" },
  { key: "NM_017970.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55051", geneDisplay: "NRDE2" },
  { key: "NM_017971.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55052", geneDisplay: "MRPL20" },
  { key: "NM_017975.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55055", geneDisplay: "ZWILCH" },
  { key: "NM_017982.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55061", geneDisplay: "SUSD4" },
  { key: "NM_017983.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55062", geneDisplay: "WIPI1" },
  { key: "NM_017986.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55065", geneDisplay: "SLC52A1" },
  { key: "NM_017988.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55681", geneDisplay: "SCYL2" },
  { key: "NM_017990.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55066", geneDisplay: "PDPR" },
  { key: "NM_017994.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55069", geneDisplay: "TMEM248" },
  { key: "NM_017998.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55071", geneDisplay: "C9orf40" },
  { key: "NM_017999.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55072", geneDisplay: "RNF31" },
  { key: "NM_018000.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55686", geneDisplay: "MREG" },
  { key: "NM_018003.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55075", geneDisplay: "UACA" },
  { key: "NM_018004.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55076", geneDisplay: "TMEM45A" },
  { key: "NM_018006.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55687", geneDisplay: "TRMU" },
  { key: "NM_018008.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55079", geneDisplay: "FEZF2" },
  { key: "NM_018009.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55080", geneDisplay: "TAPBPL" },
  { key: "NM_018010.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55081", geneDisplay: "IFT57" },
  { key: "NM_018011.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55082", geneDisplay: "ARGLU1" },
  { key: "NM_018012.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55083", geneDisplay: "KIF26B" },
  { key: "NM_018013.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55084", geneDisplay: "SOBP" },
  { key: "NM_018015.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55086", geneDisplay: "RADX" },
  { key: "NM_018017.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55088", geneDisplay: "CCDC186" },
  { key: "NM_018018.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55089", geneDisplay: "SLC38A4" },
  { key: "NM_018019.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55090", geneDisplay: "MED9" },
  { key: "NM_018023.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55689", geneDisplay: "YEATS2" },
  { key: "NM_018024.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55093", geneDisplay: "NTAQ1" },
  { key: "NM_018025.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55094", geneDisplay: "GPATCH1" },
  { key: "NM_018026.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55690", geneDisplay: "PACS1" },
  { key: "NM_018027.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55691", geneDisplay: "FRMD4A" },
  { key: "NM_018029.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55096", geneDisplay: "EBLN2" },
  { key: "NM_018031.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11180", geneDisplay: "WDR6" },
  { key: "NM_018034.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55100", geneDisplay: "WDR70" },
  { key: "NM_018035.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55101", geneDisplay: "DMAC2" },
  { key: "NM_018036.7", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55102", geneDisplay: "ATG2B" },
  { key: "NM_018039.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55693", geneDisplay: "KDM4D" },
  { key: "NM_018040.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55105", geneDisplay: "GPATCH2" },
  { key: "NM_018042.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55106", geneDisplay: "SLFN12" },
  { key: "NM_018043.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55107", geneDisplay: "ANO1" },
  { key: "NM_018045.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55108", geneDisplay: "BSDC1" },
  { key: "NM_018046.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55109", geneDisplay: "AGGF1" },
  { key: "NM_018047.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55696", geneDisplay: "RBM22" },
  { key: "NM_018048.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55110", geneDisplay: "MAGOHB" },
  { key: "NM_018049.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55111", geneDisplay: "PLEKHJ1" },
  { key: "NM_018050.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54682", geneDisplay: "MANSC1" },
  { key: "NM_018051.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55112", geneDisplay: "DYNC2I1" },
  { key: "NM_018052.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55697", geneDisplay: "VAC14" },
  { key: "NM_018053.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55113", geneDisplay: "XKR8" },
  { key: "NM_018055.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4838", geneDisplay: "NODAL" },
  { key: "NM_018056.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55116", geneDisplay: "TMEM39B" },
  { key: "NM_018058.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55118", geneDisplay: "CRTAC1" },
  { key: "NM_018059.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55698", geneDisplay: "RADIL" },
  { key: "NM_018060.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55699", geneDisplay: "IARS2" },
  { key: "NM_018061.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55119", geneDisplay: "PRPF38B" },
  { key: "NM_018062.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55120", geneDisplay: "FANCL" },
  { key: "NM_018063.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3070", geneDisplay: "HELLS" },
  { key: "NM_018064.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55122", geneDisplay: "AKIRIN2" },
  { key: "NM_018066.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54707", geneDisplay: "GPN2" },
  { key: "NM_018068.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55124", geneDisplay: "PIWIL2" },
  { key: "NM_018071.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55701", geneDisplay: "ARHGEF40" },
  { key: "NM_018072.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55127", geneDisplay: "HEATR1" },
  { key: "NM_018073.8", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55128", geneDisplay: "TRIM68" },
  { key: "NM_018074.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55702", geneDisplay: "YJU2" },
  { key: "NM_018075.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55129", geneDisplay: "ANO10" },
  { key: "NM_018076.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55130", geneDisplay: "ODAD2" },
  { key: "NM_018077.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55131", geneDisplay: "RBM28" },
  { key: "NM_018078.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55132", geneDisplay: "LARP1B" },
  { key: "NM_018079.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55133", geneDisplay: "SRBD1" },
  { key: "NM_018082.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55703", geneDisplay: "POLR3B" },
  { key: "NM_018083.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "140467", geneDisplay: "ZNF358" },
  { key: "NM_018085.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55705", geneDisplay: "IPO9" },
  { key: "NM_018086.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55137", geneDisplay: "FIGN" },
  { key: "NM_018087.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55706", geneDisplay: "NDC1" },
  { key: "NM_018088.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55138", geneDisplay: "FAM90A1" },
  { key: "NM_018089.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55139", geneDisplay: "ANKZF1" },
  { key: "NM_018090.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55707", geneDisplay: "NECAP2" },
  { key: "NM_018091.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55140", geneDisplay: "ELP3" },
  { key: "NM_018092.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81831", geneDisplay: "NETO2" },
  { key: "NM_018094.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23708", geneDisplay: "GSPT2" },
  { key: "NM_018095.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55709", geneDisplay: "KBTBD4" },
  { key: "NM_018096.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54475", geneDisplay: "NLE1" },
  { key: "NM_018097.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55142", geneDisplay: "HAUS2" },
  { key: "NM_018100.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114327", geneDisplay: "EFHC1" },
  { key: "NM_018105.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55145", geneDisplay: "THAP1" },
  { key: "NM_018109.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55149", geneDisplay: "MTPAP" },
  { key: "NM_018110.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55715", geneDisplay: "DOK4" },
  { key: "NM_018111.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55150", geneDisplay: "C19orf73" },
  { key: "NM_018112.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55151", geneDisplay: "TMEM38B" },
  { key: "NM_018113.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55716", geneDisplay: "LMBR1L" },
  { key: "NM_018115.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55153", geneDisplay: "SDAD1" },
  { key: "NM_018116.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55154", geneDisplay: "MSTO1" },
  { key: "NM_018117.12", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55717", geneDisplay: "WDR11" },
  { key: "NM_018119.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55718", geneDisplay: "POLR3E" },
  { key: "NM_018120.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55156", geneDisplay: "ARMC1" },
  { key: "NM_018121.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55719", geneDisplay: "SLF2" },
  { key: "NM_018122.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55157", geneDisplay: "DARS2" },
  { key: "NM_018124.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55159", geneDisplay: "RFWD3" },
  { key: "NM_018125.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55160", geneDisplay: "ARHGEF10L" },
  { key: "NM_018126.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55161", geneDisplay: "TMEM33" },
  { key: "NM_018127.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "60528", geneDisplay: "ELAC2" },
  { key: "NM_018128.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55720", geneDisplay: "TSR1" },
  { key: "NM_018129.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55163", geneDisplay: "PNPO" },
  { key: "NM_018130.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55164", geneDisplay: "SHQ1" },
  { key: "NM_018131.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55165", geneDisplay: "CEP55" },
  { key: "NM_018132.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55166", geneDisplay: "CENPQ" },
  { key: "NM_018133.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55167", geneDisplay: "MSL2" },
  { key: "NM_018134.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55721", geneDisplay: "IQCC" },
  { key: "NM_018135.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55168", geneDisplay: "MRPS18A" },
  { key: "NM_018136.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "259266", geneDisplay: "ASPM" },
  { key: "NM_018137.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55170", geneDisplay: "PRMT6" },
  { key: "NM_018138.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55171", geneDisplay: "TBCCD1" },
  { key: "NM_018139.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55172", geneDisplay: "DNAAF2" },
  { key: "NM_018140.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55722", geneDisplay: "CEP72" },
  { key: "NM_018141.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55173", geneDisplay: "MRPS10" },
  { key: "NM_018142.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55174", geneDisplay: "INTS10" },
  { key: "NM_018143.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55175", geneDisplay: "KLHL11" },
  { key: "NM_018144.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55176", geneDisplay: "SEC61A2" },
  { key: "NM_018145.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55177", geneDisplay: "RMDN3" },
  { key: "NM_018146.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55178", geneDisplay: "MRM3" },
  { key: "NM_018149.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55181", geneDisplay: "SMG8" },
  { key: "NM_018150.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55182", geneDisplay: "RNF220" },
  { key: "NM_018151.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55183", geneDisplay: "RIF1" },
  { key: "NM_018154.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55723", geneDisplay: "ASF1B" },
  { key: "NM_018158.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22950", geneDisplay: "SLC4A1AP" },
  { key: "NM_018159.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55190", geneDisplay: "NUDT11" },
  { key: "NM_018161.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55191", geneDisplay: "NADSYN1" },
  { key: "NM_018163.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55192", geneDisplay: "DNAJC17" },
  { key: "NM_018164.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55726", geneDisplay: "INTS13" },
  { key: "NM_018168.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55195", geneDisplay: "CCDC198" },
  { key: "NM_018169.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55196", geneDisplay: "RESF1" },
  { key: "NM_018170.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55197", geneDisplay: "RPRD1A" },
  { key: "NM_018171.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55198", geneDisplay: "APPL2" },
  { key: "NM_018174.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55201", geneDisplay: "MAP1S" },
  { key: "NM_018176.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55203", geneDisplay: "LGI2" },
  { key: "NM_018177.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55728", geneDisplay: "N4BP2" },
  { key: "NM_018178.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55204", geneDisplay: "GOLPH3L" },
  { key: "NM_018179.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55729", geneDisplay: "ATF7IP" },
  { key: "NM_018180.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55760", geneDisplay: "DHX32" },
  { key: "NM_018184.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55207", geneDisplay: "ARL8B" },
  { key: "NM_018189.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55211", geneDisplay: "DPPA4" },
  { key: "NM_018191.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55213", geneDisplay: "RCBTB1" },
  { key: "NM_018192.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55214", geneDisplay: "P3H2" },
  { key: "NM_018194.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55733", geneDisplay: "HHAT" },
  { key: "NM_018195.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55216", geneDisplay: "NKAPD1" },
  { key: "NM_018196.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55217", geneDisplay: "TMLHE" },
  { key: "NM_018197.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55734", geneDisplay: "ZFP64" },
  { key: "NM_018198.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55735", geneDisplay: "DNAJC11" },
  { key: "NM_018201.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54662", geneDisplay: "TBC1D13" },
  { key: "NM_018202.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55219", geneDisplay: "MACO1" },
  { key: "NM_018203.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55220", geneDisplay: "KLHDC8A" },
  { key: "NM_018204.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26586", geneDisplay: "CKAP2" },
  { key: "NM_018206.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55737", geneDisplay: "VPS35" },
  { key: "NM_018207.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55223", geneDisplay: "TRIM62" },
  { key: "NM_018208.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55224", geneDisplay: "ETNK2" },
  { key: "NM_018209.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55738", geneDisplay: "ARFGAP1" },
  { key: "NM_018212.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55740", geneDisplay: "ENAH" },
  { key: "NM_018214.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55227", geneDisplay: "LRRC1" },
  { key: "NM_018215.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55228", geneDisplay: "PNMA8A" },
  { key: "NM_018216.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55229", geneDisplay: "PANK4" },
  { key: "NM_018217.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55741", geneDisplay: "EDEM2" },
  { key: "NM_018219.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55231", geneDisplay: "CCDC87" },
  { key: "NM_018221.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55233", geneDisplay: "MOB1A" },
  { key: "NM_018222.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55742", geneDisplay: "PARVA" },
  { key: "NM_018224.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55744", geneDisplay: "COA1" },
  { key: "NM_018225.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55234", geneDisplay: "SMU1" },
  { key: "NM_018226.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57140", geneDisplay: "RNPEPL1" },
  { key: "NM_018227.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55236", geneDisplay: "UBA6" },
  { key: "NM_018228.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55237", geneDisplay: "VRTN" },
  { key: "NM_018229.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55745", geneDisplay: "AP5M1" },
  { key: "NM_018230.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55746", geneDisplay: "NUP133" },
  { key: "NM_018231.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55238", geneDisplay: "SLC38A7" },
  { key: "NM_018233.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55239", geneDisplay: "OGFOD1" },
  { key: "NM_018235.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55748", geneDisplay: "CNDP2" },
  { key: "NM_018237.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55749", geneDisplay: "CCAR1" },
  { key: "NM_018238.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55750", geneDisplay: "AGK" },
  { key: "NM_018240.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55243", geneDisplay: "KIRREL1" },
  { key: "NM_018241.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55751", geneDisplay: "TMEM184C" },
  { key: "NM_018242.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55244", geneDisplay: "SLC47A1" },
  { key: "NM_018243.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55752", geneDisplay: "SEPTIN11" },
  { key: "NM_018244.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55245", geneDisplay: "UQCC1" },
  { key: "NM_018245.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55753", geneDisplay: "OGDHL" },
  { key: "NM_018246.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55246", geneDisplay: "CCDC25" },
  { key: "NM_018247.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55754", geneDisplay: "TMEM30A" },
  { key: "NM_018248.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55247", geneDisplay: "NEIL3" },
  { key: "NM_018249.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55755", geneDisplay: "CDK5RAP2" },
  { key: "NM_018250.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55756", geneDisplay: "INTS9" },
  { key: "NM_018252.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55248", geneDisplay: "PACC1" },
  { key: "NM_018255.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55250", geneDisplay: "ELP2" },
  { key: "NM_018256.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55759", geneDisplay: "WDR12" },
  { key: "NM_018257.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55251", geneDisplay: "PCMTD2" },
  { key: "NM_018259.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55761", geneDisplay: "TTC17" },
  { key: "NM_018260.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55762", geneDisplay: "ZNF701" },
  { key: "NM_018263.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55252", geneDisplay: "ASXL2" },
  { key: "NM_018264.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55253", geneDisplay: "TYW1" },
  { key: "NM_018266.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55254", geneDisplay: "TMEM39A" },
  { key: "NM_018268.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55255", geneDisplay: "WDR41" },
  { key: "NM_018269.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55256", geneDisplay: "ADI1" },
  { key: "NM_018270.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55257", geneDisplay: "MRGBP" },
  { key: "NM_018271.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55258", geneDisplay: "THNSL2" },
  { key: "NM_018272.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55259", geneDisplay: "DNAI7" },
  { key: "NM_018273.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55260", geneDisplay: "TMEM143" },
  { key: "NM_018275.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55262", geneDisplay: "TRAPPC14" },
  { key: "NM_018279.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55266", geneDisplay: "TMEM19" },
  { key: "NM_018283.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55270", geneDisplay: "NUDT15" },
  { key: "NM_018284.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2635", geneDisplay: "GBP3" },
  { key: "NM_018285.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55272", geneDisplay: "IMP3" },
  { key: "NM_018286.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55273", geneDisplay: "TMEM100" },
  { key: "NM_018287.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "94134", geneDisplay: "ARHGAP12" },
  { key: "NM_018288.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55274", geneDisplay: "PHF10" },
  { key: "NM_018290.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55276", geneDisplay: "PGM2" },
  { key: "NM_018291.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55277", geneDisplay: "FGGY" },
  { key: "NM_018292.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55278", geneDisplay: "QRSL1" },
  { key: "NM_018294.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55280", geneDisplay: "CWF19L1" },
  { key: "NM_018295.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55281", geneDisplay: "TMEM140" },
  { key: "NM_018296.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55282", geneDisplay: "LRRC36" },
  { key: "NM_018297.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55768", geneDisplay: "NGLY1" },
  { key: "NM_018298.11", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55283", geneDisplay: "MCOLN3" },
  { key: "NM_018299.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55284", geneDisplay: "UBE2W" },
  { key: "NM_018303.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55770", geneDisplay: "EXOC2" },
  { key: "NM_018304.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55771", geneDisplay: "PRR11" },
  { key: "NM_018306.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55287", geneDisplay: "TMEM40" },
  { key: "NM_018310.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55290", geneDisplay: "BRF2" },
  { key: "NM_018316.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55295", geneDisplay: "KLHL26" },
  { key: "NM_018317.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55296", geneDisplay: "TBC1D19" },
  { key: "NM_018318.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55297", geneDisplay: "CCDC91" },
  { key: "NM_018319.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55775", geneDisplay: "TDP1" },
  { key: "NM_018320.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55298", geneDisplay: "RNF121" },
  { key: "NM_018321.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55299", geneDisplay: "BRIX1" },
  { key: "NM_018322.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55776", geneDisplay: "SAYSD1" },
  { key: "NM_018323.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55300", geneDisplay: "PI4K2B" },
  { key: "NM_018325.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203228", geneDisplay: "C9orf72" },
  { key: "NM_018326.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55303", geneDisplay: "GIMAP4" },
  { key: "NM_018327.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55304", geneDisplay: "SPTLC3" },
  { key: "NM_018332.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55308", geneDisplay: "DDX19A" },
  { key: "NM_018335.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55778", geneDisplay: "ZNF839" },
  { key: "NM_018337.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55311", geneDisplay: "ZNF444" },
  { key: "NM_018339.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55312", geneDisplay: "RFK" },
  { key: "NM_018340.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55313", geneDisplay: "CPPED1" },
  { key: "NM_018341.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55780", geneDisplay: "ERMARD" },
  { key: "NM_018342.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55314", geneDisplay: "TMEM144" },
  { key: "NM_018343.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55781", geneDisplay: "RIOK2" },
  { key: "NM_018344.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55315", geneDisplay: "SLC29A3" },
  { key: "NM_018346.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55316", geneDisplay: "RSAD1" },
  { key: "NM_018347.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55317", geneDisplay: "AP5S1" },
  { key: "NM_018348.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55783", geneDisplay: "CMTR2" },
  { key: "NM_018351.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55785", geneDisplay: "FGD6" },
  { key: "NM_018352.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55319", geneDisplay: "TMA16" },
  { key: "NM_018353.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55320", geneDisplay: "MIS18BP1" },
  { key: "NM_018355.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55786", geneDisplay: "ZNF415" },
  { key: "NM_018356.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55322", geneDisplay: "C5orf22" },
  { key: "NM_018357.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55323", geneDisplay: "LARP6" },
  { key: "NM_018358.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55324", geneDisplay: "ABCF3" },
  { key: "NM_018359.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55325", geneDisplay: "UFSP2" },
  { key: "NM_018360.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55787", geneDisplay: "TXLNG" },
  { key: "NM_018361.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55326", geneDisplay: "AGPAT5" },
  { key: "NM_018362.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55327", geneDisplay: "LIN7C" },
  { key: "NM_018364.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54665", geneDisplay: "RSBN1" },
  { key: "NM_018365.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55329", geneDisplay: "MNS1" },
  { key: "NM_018366.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55330", geneDisplay: "BLOC1S4" },
  { key: "NM_018367.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55331", geneDisplay: "ACER3" },
  { key: "NM_018368.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55788", geneDisplay: "LMBRD1" },
  { key: "NM_018369.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55789", geneDisplay: "DEPDC1B" },
  { key: "NM_018370.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55332", geneDisplay: "DRAM1" },
  { key: "NM_018372.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55791", geneDisplay: "LRIF1" },
  { key: "NM_018373.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55333", geneDisplay: "SYNJ2BP" },
  { key: "NM_018375.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55334", geneDisplay: "SLC39A9" },
  { key: "NM_018376.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55335", geneDisplay: "NIPSNAP3B" },
  { key: "NM_018378.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55336", geneDisplay: "FBXL8" },
  { key: "NM_018380.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55794", geneDisplay: "DDX28" },
  { key: "NM_018381.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55337", geneDisplay: "SHFL" },
  { key: "NM_018383.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55339", geneDisplay: "WDR33" },
  { key: "NM_018384.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55340", geneDisplay: "GIMAP5" },
  { key: "NM_018385.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55341", geneDisplay: "LSG1" },
  { key: "NM_018387.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55342", geneDisplay: "STRBP" },
  { key: "NM_018389.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55343", geneDisplay: "SLC35C1" },
  { key: "NM_018392.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55345", geneDisplay: "ZGRF1" },
  { key: "NM_018393.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55346", geneDisplay: "TCP11L1" },
  { key: "NM_018394.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55347", geneDisplay: "ABHD10" },
  { key: "NM_018396.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55798", geneDisplay: "METTL2B" },
  { key: "NM_018397.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55349", geneDisplay: "CHDH" },
  { key: "NM_018398.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55799", geneDisplay: "CACNA2D3" },
  { key: "NM_018401.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55351", geneDisplay: "STK32B" },
  { key: "NM_018402.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55801", geneDisplay: "IL26" },
  { key: "NM_018403.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55802", geneDisplay: "DCP1A" },
  { key: "NM_018404.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55803", geneDisplay: "ADAP2" },
  { key: "NM_018405.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55352", geneDisplay: "COPRS" },
  { key: "NM_018406.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4585", geneDisplay: "MUC4" },
  { key: "NM_018407.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55353", geneDisplay: "LAPTM4B" },
  { key: "NM_018410.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55355", geneDisplay: "HJURP" },
  { key: "NM_018413.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50515", geneDisplay: "CHST11" },
  { key: "NM_018414.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55808", geneDisplay: "ST6GALNAC1" },
  { key: "NM_018416.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55810", geneDisplay: "FOXJ2" },
  { key: "NM_018417.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55811", geneDisplay: "ADCY10" },
  { key: "NM_018418.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55812", geneDisplay: "SPATA7" },
  { key: "NM_018419.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54345", geneDisplay: "SOX18" },
  { key: "NM_018420.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55356", geneDisplay: "SLC22A15" },
  { key: "NM_018423.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55359", geneDisplay: "STYK1" },
  { key: "NM_018425.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55361", geneDisplay: "PI4K2A" },
  { key: "NM_018426.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55362", geneDisplay: "TMEM63B" },
  { key: "NM_018427.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54700", geneDisplay: "RRN3" },
  { key: "NM_018428.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55813", geneDisplay: "UTP6" },
  { key: "NM_018429.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55814", geneDisplay: "BDP1" },
  { key: "NM_018431.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55816", geneDisplay: "DOK5" },
  { key: "NM_018433.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55818", geneDisplay: "KDM3A" },
  { key: "NM_018434.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55819", geneDisplay: "RNF130" },
  { key: "NM_018436.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55821", geneDisplay: "ALLC" },
  { key: "NM_018438.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26270", geneDisplay: "FBXO6" },
  { key: "NM_018439.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55364", geneDisplay: "IMPACT" },
  { key: "NM_018440.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55824", geneDisplay: "PAG1" },
  { key: "NM_018441.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55825", geneDisplay: "PECR" },
  { key: "NM_018444.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54704", geneDisplay: "PDP1" },
  { key: "NM_018445.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55829", geneDisplay: "SELENOS" },
  { key: "NM_018446.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55830", geneDisplay: "GLT8D1" },
  { key: "NM_018448.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55832", geneDisplay: "CAND1" },
  { key: "NM_018451.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55835", geneDisplay: "CENPJ" },
  { key: "NM_018452.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "729515", geneDisplay: "TMEM242" },
  { key: "NM_018453.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55837", geneDisplay: "EAPP" },
  { key: "NM_018456.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55840", geneDisplay: "EAF2" },
  { key: "NM_018457.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54458", geneDisplay: "PRR13" },
  { key: "NM_018460.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55843", geneDisplay: "ARHGAP15" },
  { key: "NM_018461.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55844", geneDisplay: "PPP2R2D" },
  { key: "NM_018462.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55845", geneDisplay: "BRK1" },
  { key: "NM_018463.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55846", geneDisplay: "ITFG2" },
  { key: "NM_018464.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55847", geneDisplay: "CISD1" },
  { key: "NM_018465.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55848", geneDisplay: "PLGRKT" },
  { key: "NM_018467.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55850", geneDisplay: "USE1" },
  { key: "NM_018471.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55854", geneDisplay: "ZC3H15" },
  { key: "NM_018473.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55856", geneDisplay: "ACOT13" },
  { key: "NM_018474.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55857", geneDisplay: "KIZ" },
  { key: "NM_018475.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55858", geneDisplay: "TMEM165" },
  { key: "NM_018476.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55859", geneDisplay: "BEX1" },
  { key: "NM_018477.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55860", geneDisplay: "ACTR10" },
  { key: "NM_018480.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55863", geneDisplay: "TMEM126B" },
  { key: "NM_018482.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "50807", geneDisplay: "ASAP1" },
  { key: "NM_018484.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55867", geneDisplay: "SLC22A11" },
  { key: "NM_018486.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55869", geneDisplay: "HDAC8" },
  { key: "NM_018487.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55365", geneDisplay: "TMEM176A" },
  { key: "NM_018489.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55870", geneDisplay: "ASH1L" },
  { key: "NM_018490.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55366", geneDisplay: "LGR4" },
  { key: "NM_018491.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55871", geneDisplay: "CBWD1" },
  { key: "NM_018492.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55872", geneDisplay: "PBK" },
  { key: "NM_018502.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55374", geneDisplay: "TMCO6" },
  { key: "NM_018509.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55379", geneDisplay: "LRRC59" },
  { key: "NM_018518.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55388", geneDisplay: "MCM10" },
  { key: "NM_018556.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55423", geneDisplay: "SIRPG" },
  { key: "NM_018557.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "53353", geneDisplay: "LRP1B" },
  { key: "NM_018558.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55879", geneDisplay: "GABRQ" },
  { key: "NM_018559.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55425", geneDisplay: "GPALPP1" },
  { key: "NM_018566.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55432", geneDisplay: "YOD1" },
  { key: "NM_018569.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55435", geneDisplay: "AP1AR" },
  { key: "NM_018571.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55437", geneDisplay: "STRADB" },
  { key: "NM_018584.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55450", geneDisplay: "CAMK2N1" },
  { key: "NM_018590.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55454", geneDisplay: "CSGALNACT2" },
  { key: "NM_018593.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "117247", geneDisplay: "SLC16A10" },
  { key: "NM_018622.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55486", geneDisplay: "PARL" },
  { key: "NM_018638.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55500", geneDisplay: "ETNK1" },
  { key: "NM_018639.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55884", geneDisplay: "WSB2" },
  { key: "NM_018640.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55885", geneDisplay: "LMO3" },
  { key: "NM_018641.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55501", geneDisplay: "CHST12" },
  { key: "NM_018643.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54210", geneDisplay: "TREM1" },
  { key: "NM_018645.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55502", geneDisplay: "HES6" },
  { key: "NM_018646.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55503", geneDisplay: "TRPV6" },
  { key: "NM_018648.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55505", geneDisplay: "NOP10" },
  { key: "NM_018649.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55506", geneDisplay: "MACROH2A2" },
  { key: "NM_018650.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4139", geneDisplay: "MARK1" },
  { key: "NM_018652.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55889", geneDisplay: "GOLGA6B" },
  { key: "NM_018654.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55507", geneDisplay: "GPRC5D" },
  { key: "NM_018656.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55508", geneDisplay: "SLC35E3" },
  { key: "NM_018657.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55892", geneDisplay: "MYNN" },
  { key: "NM_018659.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54360", geneDisplay: "CYTL1" },
  { key: "NM_018660.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55893", geneDisplay: "ZNF395" },
  { key: "NM_018661.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55894", geneDisplay: "DEFB103B" },
  { key: "NM_018662.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27185", geneDisplay: "DISC1" },
  { key: "NM_018663.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5827", geneDisplay: "PXMP2" },
  { key: "NM_018664.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55509", geneDisplay: "BATF3" },
  { key: "NM_018665.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55510", geneDisplay: "DDX43" },
  { key: "NM_018667.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55512", geneDisplay: "SMPD3" },
  { key: "NM_018668.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26276", geneDisplay: "VPS33B" },
  { key: "NM_018669.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10785", geneDisplay: "WDR4" },
  { key: "NM_018670.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55897", geneDisplay: "MESP1" },
  { key: "NM_018671.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55898", geneDisplay: "UNC45A" },
  { key: "NM_018674.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55515", geneDisplay: "ASIC4" },
  { key: "NM_018676.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55901", geneDisplay: "THSD1" },
  { key: "NM_018677.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55902", geneDisplay: "ACSS2" },
  { key: "NM_018683.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55905", geneDisplay: "RNF114" },
  { key: "NM_018684.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55906", geneDisplay: "ZC4H2" },
  { key: "NM_018685.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54443", geneDisplay: "ANLN" },
  { key: "NM_018686.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55907", geneDisplay: "CMAS" },
  { key: "NM_018687.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55908", geneDisplay: "ANGPTL8" },
  { key: "NM_018688.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55909", geneDisplay: "BIN3" },
  { key: "NM_018690.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55911", geneDisplay: "APOBR" },
  { key: "NM_018691.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10827", geneDisplay: "FAM114A2" },
  { key: "NM_018694.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51329", geneDisplay: "ARL6IP4" },
  { key: "NM_018696.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55520", geneDisplay: "ELAC1" },
  { key: "NM_018697.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55915", geneDisplay: "LANCL2" },
  { key: "NM_018699.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "11107", geneDisplay: "PRDM5" },
  { key: "NM_018702.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "105", geneDisplay: "ADARB2" },
  { key: "NM_018704.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55917", geneDisplay: "CTTNBP2NL" },
  { key: "NM_018706.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55526", geneDisplay: "DHTKD1" },
  { key: "NM_018708.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55527", geneDisplay: "FEM1A" },
  { key: "NM_018710.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55529", geneDisplay: "PIP4P2" },
  { key: "NM_018711.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55530", geneDisplay: "SVOP" },
  { key: "NM_018712.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55531", geneDisplay: "ELMOD1" },
  { key: "NM_018713.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55532", geneDisplay: "SLC30A10" },
  { key: "NM_018714.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9382", geneDisplay: "COG1" },
  { key: "NM_018715.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55920", geneDisplay: "RCC2" },
  { key: "NM_018717.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55534", geneDisplay: "MAML3" },
  { key: "NM_018718.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "95681", geneDisplay: "CEP41" },
  { key: "NM_018719.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55536", geneDisplay: "CDCA7L" },
  { key: "NM_018723.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54715", geneDisplay: "RBFOX1" },
  { key: "NM_018724.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50604", geneDisplay: "IL20" },
  { key: "NM_018725.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55540", geneDisplay: "IL17RB" },
  { key: "NM_018728.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55930", geneDisplay: "MYO5C" },
  { key: "NM_018834.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9782", geneDisplay: "MATR3" },
  { key: "NM_018836.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55966", geneDisplay: "AJAP1" },
  { key: "NM_018838.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55967", geneDisplay: "NDUFA12" },
  { key: "NM_018840.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55969", geneDisplay: "RAB5IF" },
  { key: "NM_018841.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55970", geneDisplay: "GNG12" },
  { key: "NM_018842.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55971", geneDisplay: "BAIAP2L1" },
  { key: "NM_018843.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55972", geneDisplay: "SLC25A40" },
  { key: "NM_018844.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55973", geneDisplay: "BCAP29" },
  { key: "NM_018845.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55974", geneDisplay: "SLC50A1" },
  { key: "NM_018847.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55958", geneDisplay: "KLHL9" },
  { key: "NM_018896.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8913", geneDisplay: "CACNA1G" },
  { key: "NM_018897.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56171", geneDisplay: "DNAH7" },
  { key: "NM_018898.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56135", geneDisplay: "PCDHAC1" },
  { key: "NM_018899.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56134", geneDisplay: "PCDHAC2" },
  { key: "NM_018900.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56147", geneDisplay: "PCDHA1" },
  { key: "NM_018901.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56139", geneDisplay: "PCDHA10" },
  { key: "NM_018902.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56138", geneDisplay: "PCDHA11" },
  { key: "NM_018903.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56137", geneDisplay: "PCDHA12" },
  { key: "NM_018904.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56136", geneDisplay: "PCDHA13" },
  { key: "NM_018905.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56146", geneDisplay: "PCDHA2" },
  { key: "NM_018906.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56145", geneDisplay: "PCDHA3" },
  { key: "NM_018907.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56144", geneDisplay: "PCDHA4" },
  { key: "NM_018908.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56143", geneDisplay: "PCDHA5" },
  { key: "NM_018909.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56142", geneDisplay: "PCDHA6" },
  { key: "NM_018910.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56141", geneDisplay: "PCDHA7" },
  { key: "NM_018911.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56140", geneDisplay: "PCDHA8" },
  { key: "NM_018912.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56114", geneDisplay: "PCDHGA1" },
  { key: "NM_018913.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56106", geneDisplay: "PCDHGA10" },
  { key: "NM_018914.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56105", geneDisplay: "PCDHGA11" },
  { key: "NM_018915.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56113", geneDisplay: "PCDHGA2" },
  { key: "NM_018916.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56112", geneDisplay: "PCDHGA3" },
  { key: "NM_018917.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56111", geneDisplay: "PCDHGA4" },
  { key: "NM_018918.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56110", geneDisplay: "PCDHGA5" },
  { key: "NM_018919.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56109", geneDisplay: "PCDHGA6" },
  { key: "NM_018920.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56108", geneDisplay: "PCDHGA7" },
  { key: "NM_018921.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56107", geneDisplay: "PCDHGA9" },
  { key: "NM_018922.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56104", geneDisplay: "PCDHGB1" },
  { key: "NM_018923.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56103", geneDisplay: "PCDHGB2" },
  { key: "NM_018924.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56102", geneDisplay: "PCDHGB3" },
  { key: "NM_018925.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56101", geneDisplay: "PCDHGB5" },
  { key: "NM_018926.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56100", geneDisplay: "PCDHGB6" },
  { key: "NM_018927.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56099", geneDisplay: "PCDHGB7" },
  { key: "NM_018928.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56098", geneDisplay: "PCDHGC4" },
  { key: "NM_018929.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56097", geneDisplay: "PCDHGC5" },
  { key: "NM_018930.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56126", geneDisplay: "PCDHB10" },
  { key: "NM_018931.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56125", geneDisplay: "PCDHB11" },
  { key: "NM_018932.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56124", geneDisplay: "PCDHB12" },
  { key: "NM_018933.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56123", geneDisplay: "PCDHB13" },
  { key: "NM_018934.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56122", geneDisplay: "PCDHB14" },
  { key: "NM_018935.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56121", geneDisplay: "PCDHB15" },
  { key: "NM_018936.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56133", geneDisplay: "PCDHB2" },
  { key: "NM_018937.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56132", geneDisplay: "PCDHB3" },
  { key: "NM_018938.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56131", geneDisplay: "PCDHB4" },
  { key: "NM_018939.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56130", geneDisplay: "PCDHB6" },
  { key: "NM_018940.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56129", geneDisplay: "PCDHB7" },
  { key: "NM_018941.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2055", geneDisplay: "CLN8" },
  { key: "NM_018942.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3166", geneDisplay: "HMX1" },
  { key: "NM_018943.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51807", geneDisplay: "TUBA8" },
  { key: "NM_018944.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54069", geneDisplay: "MIS18A" },
  { key: "NM_018945.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27115", geneDisplay: "PDE7B" },
  { key: "NM_018946.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54187", geneDisplay: "NANS" },
  { key: "NM_018947.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54205", geneDisplay: "CYCS" },
  { key: "NM_018948.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54206", geneDisplay: "ERRFI1" },
  { key: "NM_018949.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2837", geneDisplay: "UTS2R" },
  { key: "NM_018951.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3206", geneDisplay: "HOXA10" },
  { key: "NM_018952.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3216", geneDisplay: "HOXB6" },
  { key: "NM_018953.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3222", geneDisplay: "HOXC5" },
  { key: "NM_018955.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7314", geneDisplay: "UBB" },
  { key: "NM_018957.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23616", geneDisplay: "SH3BP1" },
  { key: "NM_018958.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23742", geneDisplay: "NPAP1" },
  { key: "NM_018959.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26528", geneDisplay: "DAZAP1" },
  { key: "NM_018960.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "27232", geneDisplay: "GNMT" },
  { key: "NM_018961.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "53347", geneDisplay: "UBASH3A" },
  { key: "NM_018962.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "53820", geneDisplay: "RIPPLY3" },
  { key: "NM_018965.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54209", geneDisplay: "TREM2" },
  { key: "NM_018967.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54212", geneDisplay: "SNTG1" },
  { key: "NM_018968.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54221", geneDisplay: "SNTG2" },
  { key: "NM_018969.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54328", geneDisplay: "GPR173" },
  { key: "NM_018971.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2850", geneDisplay: "GPR27" },
  { key: "NM_018972.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54332", geneDisplay: "GDAP1" },
  { key: "NM_018974.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54346", geneDisplay: "UNC93A" },
  { key: "NM_018975.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54386", geneDisplay: "TERF2IP" },
  { key: "NM_018976.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54407", geneDisplay: "SLC38A2" },
  { key: "NM_018979.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65125", geneDisplay: "WNK1" },
  { key: "NM_018980.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54429", geneDisplay: "TAS2R5" },
  { key: "NM_018981.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54431", geneDisplay: "DNAJC10" },
  { key: "NM_018982.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54432", geneDisplay: "YIPF1" },
  { key: "NM_018983.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54433", geneDisplay: "GAR1" },
  { key: "NM_018984.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54434", geneDisplay: "SSH1" },
  { key: "NM_018986.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54436", geneDisplay: "SH3TC1" },
  { key: "NM_018988.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54438", geneDisplay: "GFOD1" },
  { key: "NM_018989.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54439", geneDisplay: "RBM27" },
  { key: "NM_018990.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54440", geneDisplay: "SASH3" },
  { key: "NM_018992.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54442", geneDisplay: "KCTD5" },
  { key: "NM_018993.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54453", geneDisplay: "RIN2" },
  { key: "NM_018994.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54455", geneDisplay: "FBXO42" },
  { key: "NM_018995.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "54456", geneDisplay: "MOV10L1" },
  { key: "NM_018998.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54461", geneDisplay: "FBXW5" },
  { key: "NM_019002.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54465", geneDisplay: "ETAA1" },
  { key: "NM_019003.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54466", geneDisplay: "SPIN2A" },
  { key: "NM_019004.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54467", geneDisplay: "ANKIB1" },
  { key: "NM_019005.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54468", geneDisplay: "MIOS" },
  { key: "NM_019006.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54469", geneDisplay: "ZFAND6" },
  { key: "NM_019007.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54470", geneDisplay: "ARMCX6" },
  { key: "NM_019008.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "54471", geneDisplay: "MIEF1" },
  { key: "NM_019009.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54472", geneDisplay: "TOLLIP" },
  { key: "NM_019010.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54474", geneDisplay: "KRT20" },
  { key: "NM_019013.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54478", geneDisplay: "PIMREG" },
  { key: "NM_019014.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84172", geneDisplay: "POLR1B" },
  { key: "NM_019015.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54480", geneDisplay: "CHPF2" },
  { key: "NM_019016.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "192666", geneDisplay: "KRT24" },
  { key: "NM_019018.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54491", geneDisplay: "OTULINL" },
  { key: "NM_019020.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125058", geneDisplay: "TBC1D16" },
  { key: "NM_019022.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "54495", geneDisplay: "TMX3" },
  { key: "NM_019023.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54496", geneDisplay: "PRMT7" },
  { key: "NM_019024.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54497", geneDisplay: "HEATR5B" },
  { key: "NM_019026.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54499", geneDisplay: "TMCO1" },
  { key: "NM_019028.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54503", geneDisplay: "ZDHHC13" },
  { key: "NM_019030.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54505", geneDisplay: "DHX29" },
  { key: "NM_019032.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54507", geneDisplay: "ADAMTSL4" },
  { key: "NM_019034.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54509", geneDisplay: "RHOF" },
  { key: "NM_019035.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54510", geneDisplay: "PCDH18" },
  { key: "NM_019037.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54512", geneDisplay: "EXOSC4" },
  { key: "NM_019040.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26610", geneDisplay: "ELP4" },
  { key: "NM_019041.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54516", geneDisplay: "MTRF1L" },
  { key: "NM_019042.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54517", geneDisplay: "PUS7" },
  { key: "NM_019043.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54518", geneDisplay: "APBB1IP" },
  { key: "NM_019044.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54520", geneDisplay: "CCDC93" },
  { key: "NM_019045.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54521", geneDisplay: "WDR44" },
  { key: "NM_019046.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54522", geneDisplay: "ANKRD16" },
  { key: "NM_019048.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54529", geneDisplay: "ASNSD1" },
  { key: "NM_019051.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54534", geneDisplay: "MRPL50" },
  { key: "NM_019053.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54536", geneDisplay: "EXOC6" },
  { key: "NM_019055.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54538", geneDisplay: "ROBO4" },
  { key: "NM_019058.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54541", geneDisplay: "DDIT4" },
  { key: "NM_019059.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54543", geneDisplay: "TOMM7" },
  { key: "NM_019060.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54544", geneDisplay: "CRCT1" },
  { key: "NM_019062.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54546", geneDisplay: "RNF186" },
  { key: "NM_019063.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27436", geneDisplay: "EML4" },
  { key: "NM_019065.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54550", geneDisplay: "NECAB2" },
  { key: "NM_019066.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54551", geneDisplay: "MAGEL2" },
  { key: "NM_019069.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54554", geneDisplay: "WDR5B" },
  { key: "NM_019070.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54555", geneDisplay: "DDX49" },
  { key: "NM_019071.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54556", geneDisplay: "ING3" },
  { key: "NM_019072.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54557", geneDisplay: "SGTB" },
  { key: "NM_019073.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54558", geneDisplay: "SPATA6" },
  { key: "NM_019074.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54567", geneDisplay: "DLL4" },
  { key: "NM_019075.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54575", geneDisplay: "UGT1A10" },
  { key: "NM_019076.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54576", geneDisplay: "UGT1A8" },
  { key: "NM_019077.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54577", geneDisplay: "UGT1A7" },
  { key: "NM_019078.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54579", geneDisplay: "UGT1A5" },
  { key: "NM_019079.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54596", geneDisplay: "L1TD1" },
  { key: "NM_019080.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "54602", geneDisplay: "NDFIP2" },
  { key: "NM_019082.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54606", geneDisplay: "DDX56" },
  { key: "NM_019083.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54482", geneDisplay: "TRMT13" },
  { key: "NM_019086.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54621", geneDisplay: "VSIG10" },
  { key: "NM_019087.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54622", geneDisplay: "ARL15" },
  { key: "NM_019088.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54623", geneDisplay: "PAF1" },
  { key: "NM_019089.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54626", geneDisplay: "HES2" },
  { key: "NM_019090.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54627", geneDisplay: "MAP10" },
  { key: "NM_019091.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65977", geneDisplay: "PLEKHA3" },
  { key: "NM_019093.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54659", geneDisplay: "UGT1A3" },
  { key: "NM_019094.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11163", geneDisplay: "NUDT4" },
  { key: "NM_019095.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54675", geneDisplay: "CRLS1" },
  { key: "NM_019096.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54676", geneDisplay: "GTPBP2" },
  { key: "NM_019098.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54714", geneDisplay: "CNGB3" },
  { key: "NM_019099.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55924", geneDisplay: "INKA2" },
  { key: "NM_019100.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55929", geneDisplay: "DMAP1" },
  { key: "NM_019101.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55937", geneDisplay: "APOM" },
  { key: "NM_019102.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3202", geneDisplay: "HOXA5" },
  { key: "NM_019104.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55957", geneDisplay: "LIN37" },
  { key: "NM_019107.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56005", geneDisplay: "MYDGF" },
  { key: "NM_019108.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56006", geneDisplay: "SMG9" },
  { key: "NM_019109.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "56052", geneDisplay: "ALG1" },
  { key: "NM_019110.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387032", geneDisplay: "ZKSCAN4" },
  { key: "NM_019111.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3122", geneDisplay: "HLA-DRA" },
  { key: "NM_019112.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10347", geneDisplay: "ABCA7" },
  { key: "NM_019113.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26291", geneDisplay: "FGF21" },
  { key: "NM_019114.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54566", geneDisplay: "EPB41L4B" },
  { key: "NM_019116.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "56061", geneDisplay: "UBFD1" },
  { key: "NM_019117.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56062", geneDisplay: "KLHL4" },
  { key: "NM_019118.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56063", geneDisplay: "TMEM234" },
  { key: "NM_019119.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56127", geneDisplay: "PCDHB9" },
  { key: "NM_019120.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56128", geneDisplay: "PCDHB8" },
  { key: "NM_019121.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284352", geneDisplay: "PPP1R37" },
  { key: "NM_019555.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "50650", geneDisplay: "ARHGEF3" },
  { key: "NM_019556.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56180", geneDisplay: "MOSPD1" },
  { key: "NM_019558.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3234", geneDisplay: "HOXD8" },
  { key: "NM_019589.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56252", geneDisplay: "YLPM1" },
  { key: "NM_019590.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56243", geneDisplay: "KIAA1217" },
  { key: "NM_019591.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7574", geneDisplay: "ZNF26" },
  { key: "NM_019592.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56254", geneDisplay: "RNF20" },
  { key: "NM_019593.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56261", geneDisplay: "GPCPD1" },
  { key: "NM_019594.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56262", geneDisplay: "LRRC8A" },
  { key: "NM_019597.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3188", geneDisplay: "HNRNPH2" },
  { key: "NM_019599.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "50834", geneDisplay: "TAS2R1" },
  { key: "NM_019601.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "56241", geneDisplay: "SUSD2" },
  { key: "NM_019604.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56253", geneDisplay: "CRTAM" },
  { key: "NM_019605.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56256", geneDisplay: "SERTAD4" },
  { key: "NM_019606.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56257", geneDisplay: "MEPCE" },
  { key: "NM_019609.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56265", geneDisplay: "CPXM1" },
  { key: "NM_019612.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56269", geneDisplay: "IRGC" },
  { key: "NM_019613.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56270", geneDisplay: "WDR45B" },
  { key: "NM_019616.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2155", geneDisplay: "F7" },
  { key: "NM_019617.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56287", geneDisplay: "GKN1" },
  { key: "NM_019618.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56300", geneDisplay: "IL36G" },
  { key: "NM_019625.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23457", geneDisplay: "ABCB9" },
  { key: "NM_019644.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56311", geneDisplay: "ANKRD7" },
  { key: "NM_019839.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56413", geneDisplay: "LTB4R2" },
  { key: "NM_019841.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56302", geneDisplay: "TRPV5" },
  { key: "NM_019842.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56479", geneDisplay: "KCNQ5" },
  { key: "NM_019843.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "56478", geneDisplay: "EIF4ENIF1" },
  { key: "NM_019844.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "28234", geneDisplay: "SLCO1B3" },
  { key: "NM_019845.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56475", geneDisplay: "RPRM" },
  { key: "NM_019848.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8273", geneDisplay: "SLC10A3" },
  { key: "NM_019849.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56301", geneDisplay: "SLC7A10" },
  { key: "NM_019850.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25791", geneDisplay: "NGEF" },
  { key: "NM_019851.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26281", geneDisplay: "FGF20" },
  { key: "NM_019852.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56339", geneDisplay: "METTL3" },
  { key: "NM_019854.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56341", geneDisplay: "PRMT8" },
  { key: "NM_019855.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56344", geneDisplay: "CABP5" },
  { key: "NM_019858.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27239", geneDisplay: "GPR162" },
  { key: "NM_019859.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3363", geneDisplay: "HTR7" },
  { key: "NM_019884.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2931", geneDisplay: "GSK3A" },
  { key: "NM_019885.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56603", geneDisplay: "CYP26B1" },
  { key: "NM_019886.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56548", geneDisplay: "CHST7" },
  { key: "NM_019888.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4159", geneDisplay: "MC3R" },
  { key: "NM_019891.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56605", geneDisplay: "ERO1B" },
  { key: "NM_019892.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56623", geneDisplay: "INPP5E" },
  { key: "NM_019893.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56624", geneDisplay: "ASAH2" },
  { key: "NM_019894.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56649", geneDisplay: "TMPRSS4" },
  { key: "NM_019896.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56655", geneDisplay: "POLE4" },
  { key: "NM_019897.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56656", geneDisplay: "OR2S2" },
  { key: "NM_020041.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56606", geneDisplay: "SLC2A9" },
  { key: "NM_020056.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3118", geneDisplay: "HLA-DQA2" },
  { key: "NM_020061.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5956", geneDisplay: "OPN1LW" },
  { key: "NM_020062.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56731", geneDisplay: "SLC2A4RG" },
  { key: "NM_020063.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343472", geneDisplay: "BARHL2" },
  { key: "NM_020064.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56751", geneDisplay: "BARHL1" },
  { key: "NM_020066.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56776", geneDisplay: "FMN2" },
  { key: "NM_020070.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3543", geneDisplay: "IGLL1" },
  { key: "NM_020116.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56884", geneDisplay: "FSTL5" },
  { key: "NM_020117.11", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51520", geneDisplay: "LARS1" },
  { key: "NM_020119.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56829", geneDisplay: "ZC3HAV1" },
  { key: "NM_020120.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56886", geneDisplay: "UGGT1" },
  { key: "NM_020121.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55757", geneDisplay: "UGGT2" },
  { key: "NM_020122.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56888", geneDisplay: "KCMF1" },
  { key: "NM_020123.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56889", geneDisplay: "TM9SF3" },
  { key: "NM_020124.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56832", geneDisplay: "IFNK" },
  { key: "NM_020125.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56833", geneDisplay: "SLAMF8" },
  { key: "NM_020126.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56848", geneDisplay: "SPHK2" },
  { key: "NM_020127.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7286", geneDisplay: "TUFT1" },
  { key: "NM_020129.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56891", geneDisplay: "LGALS14" },
  { key: "NM_020130.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "56892", geneDisplay: "TCIM" },
  { key: "NM_020131.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56893", geneDisplay: "UBQLN4" },
  { key: "NM_020132.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "56894", geneDisplay: "AGPAT3" },
  { key: "NM_020133.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56895", geneDisplay: "AGPAT4" },
  { key: "NM_020134.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56896", geneDisplay: "DPYSL5" },
  { key: "NM_020135.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56897", geneDisplay: "WRNIP1" },
  { key: "NM_020137.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56850", geneDisplay: "GRIPAP1" },
  { key: "NM_020139.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56898", geneDisplay: "BDH2" },
  { key: "NM_020141.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56900", geneDisplay: "TMEM167B" },
  { key: "NM_020143.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56902", geneDisplay: "PNO1" },
  { key: "NM_020144.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56903", geneDisplay: "PAPOLB" },
  { key: "NM_020145.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56904", geneDisplay: "SH3GLB2" },
  { key: "NM_020147.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56906", geneDisplay: "THAP10" },
  { key: "NM_020150.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56681", geneDisplay: "SAR1A" },
  { key: "NM_020151.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56910", geneDisplay: "STARD7" },
  { key: "NM_020154.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56851", geneDisplay: "EMC7" },
  { key: "NM_020156.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56913", geneDisplay: "C1GALT1" },
  { key: "NM_020157.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56914", geneDisplay: "OTOR" },
  { key: "NM_020158.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56915", geneDisplay: "EXOSC5" },
  { key: "NM_020159.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56916", geneDisplay: "SMARCAD1" },
  { key: "NM_020162.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56919", geneDisplay: "DHX33" },
  { key: "NM_020163.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56920", geneDisplay: "SEMA3G" },
  { key: "NM_020165.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56852", geneDisplay: "RAD18" },
  { key: "NM_020166.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56922", geneDisplay: "MCCC1" },
  { key: "NM_020167.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56923", geneDisplay: "NMUR2" },
  { key: "NM_020169.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56925", geneDisplay: "LXN" },
  { key: "NM_020170.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56926", geneDisplay: "NCLN" },
  { key: "NM_020175.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56931", geneDisplay: "DUS3L" },
  { key: "NM_020177.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56929", geneDisplay: "FEM1C" },
  { key: "NM_020178.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56934", geneDisplay: "CA10" },
  { key: "NM_020179.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56935", geneDisplay: "SMCO4" },
  { key: "NM_020180.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "56853", geneDisplay: "CELF4" },
  { key: "NM_020182.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56937", geneDisplay: "PMEPA1" },
  { key: "NM_020183.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56938", geneDisplay: "ARNTL2" },
  { key: "NM_020184.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26504", geneDisplay: "CNNM4" },
  { key: "NM_020186.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57001", geneDisplay: "SDHAF3" },
  { key: "NM_020187.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56941", geneDisplay: "HMCES" },
  { key: "NM_020188.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "56942", geneDisplay: "CMC2" },
  { key: "NM_020189.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "56943", geneDisplay: "ENY2" },
  { key: "NM_020190.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56944", geneDisplay: "OLFML3" },
  { key: "NM_020191.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56945", geneDisplay: "MRPS22" },
  { key: "NM_020192.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57002", geneDisplay: "YAE1" },
  { key: "NM_020195.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56948", geneDisplay: "SDR39U1" },
  { key: "NM_020196.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56949", geneDisplay: "XAB2" },
  { key: "NM_020197.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56950", geneDisplay: "SMYD2" },
  { key: "NM_020198.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57003", geneDisplay: "CCDC47" },
  { key: "NM_020199.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56951", geneDisplay: "C5orf15" },
  { key: "NM_020200.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56952", geneDisplay: "PRTFDC1" },
  { key: "NM_020201.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56953", geneDisplay: "NT5M" },
  { key: "NM_020202.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56954", geneDisplay: "NIT2" },
  { key: "NM_020203.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56955", geneDisplay: "MEPE" },
  { key: "NM_020204.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56956", geneDisplay: "LHX9" },
  { key: "NM_020205.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56957", geneDisplay: "OTUD7B" },
  { key: "NM_020207.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375748", geneDisplay: "ERCC6L2" },
  { key: "NM_020208.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54716", geneDisplay: "SLC6A20" },
  { key: "NM_020209.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56961", geneDisplay: "SHD" },
  { key: "NM_020211.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56963", geneDisplay: "RGMA" },
  { key: "NM_020212.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56964", geneDisplay: "WDR93" },
  { key: "NM_020216.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6051", geneDisplay: "RNPEP" },
  { key: "NM_020223.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56975", geneDisplay: "FAM20C" },
  { key: "NM_020225.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56977", geneDisplay: "STOX2" },
  { key: "NM_020227.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56979", geneDisplay: "PRDM9" },
  { key: "NM_020230.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56342", geneDisplay: "PPAN" },
  { key: "NM_020232.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "56984", geneDisplay: "PSMG2" },
  { key: "NM_020233.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56985", geneDisplay: "ADPRM" },
  { key: "NM_020236.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "65008", geneDisplay: "MRPL1" },
  { key: "NM_020239.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56882", geneDisplay: "CDC42SE1" },
  { key: "NM_020242.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56992", geneDisplay: "KIF15" },
  { key: "NM_020243.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "56993", geneDisplay: "TOMM22" },
  { key: "NM_020244.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56994", geneDisplay: "CHPT1" },
  { key: "NM_020245.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56995", geneDisplay: "TULP4" },
  { key: "NM_020246.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56996", geneDisplay: "SLC12A9" },
  { key: "NM_020247.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56997", geneDisplay: "COQ8A" },
  { key: "NM_020248.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56998", geneDisplay: "CTNNBIP1" },
  { key: "NM_020297.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10060", geneDisplay: "ABCC9" },
  { key: "NM_020299.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57016", geneDisplay: "AKR1B10" },
  { key: "NM_020300.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4257", geneDisplay: "MGST1" },
  { key: "NM_020307.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57018", geneDisplay: "CCNL1" },
  { key: "NM_020309.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57030", geneDisplay: "SLC17A7" },
  { key: "NM_020310.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4335", geneDisplay: "MNT" },
  { key: "NM_020311.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57007", geneDisplay: "ACKR3" },
  { key: "NM_020312.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57017", geneDisplay: "COQ9" },
  { key: "NM_020313.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57019", geneDisplay: "CIAPIN1" },
  { key: "NM_020314.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57020", geneDisplay: "VPS35L" },
  { key: "NM_020315.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "57026", geneDisplay: "PDXP" },
  { key: "NM_020317.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57035", geneDisplay: "RSRP1" },
  { key: "NM_020318.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "60676", geneDisplay: "PAPPA2" },
  { key: "NM_020319.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57037", geneDisplay: "ANKMY2" },
  { key: "NM_020320.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57038", geneDisplay: "RARS2" },
  { key: "NM_020335.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57216", geneDisplay: "VANGL2" },
  { key: "NM_020336.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57148", geneDisplay: "RALGAPB" },
  { key: "NM_020337.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57182", geneDisplay: "ANKRD50" },
  { key: "NM_020338.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57178", geneDisplay: "ZMIZ1" },
  { key: "NM_020340.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57221", geneDisplay: "ARFGEF3" },
  { key: "NM_020342.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57181", geneDisplay: "SLC39A10" },
  { key: "NM_020343.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57186", geneDisplay: "RALGAPA2" },
  { key: "NM_020344.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25769", geneDisplay: "SLC24A2" },
  { key: "NM_020345.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "28512", geneDisplay: "NKIRAS1" },
  { key: "NM_020346.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57084", geneDisplay: "SLC17A6" },
  { key: "NM_020347.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54585", geneDisplay: "LZTFL1" },
  { key: "NM_020348.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26507", geneDisplay: "CNNM1" },
  { key: "NM_020350.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57085", geneDisplay: "AGTRAP" },
  { key: "NM_020351.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1295", geneDisplay: "COL8A1" },
  { key: "NM_020353.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57088", geneDisplay: "PLSCR4" },
  { key: "NM_020354.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57089", geneDisplay: "ENTPD7" },
  { key: "NM_020356.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57091", geneDisplay: "CASS4" },
  { key: "NM_020357.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57092", geneDisplay: "PCNP" },
  { key: "NM_020358.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57093", geneDisplay: "TRIM49" },
  { key: "NM_020360.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57048", geneDisplay: "PLSCR3" },
  { key: "NM_020361.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57094", geneDisplay: "CPA6" },
  { key: "NM_020362.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57095", geneDisplay: "PITHD1" },
  { key: "NM_020364.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "57054", geneDisplay: "DAZ3" },
  { key: "NM_020365.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8891", geneDisplay: "EIF2B3" },
  { key: "NM_020366.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57096", geneDisplay: "RPGRIP1" },
  { key: "NM_020367.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57097", geneDisplay: "PARP11" },
  { key: "NM_020368.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57050", geneDisplay: "UTP3" },
  { key: "NM_020369.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29999", geneDisplay: "FSCN3" },
  { key: "NM_020370.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "53831", geneDisplay: "GPR84" },
  { key: "NM_020371.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57099", geneDisplay: "AVEN" },
  { key: "NM_020374.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57102", geneDisplay: "C12orf4" },
  { key: "NM_020375.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57103", geneDisplay: "TIGAR" },
  { key: "NM_020376.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57104", geneDisplay: "PNPLA2" },
  { key: "NM_020378.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57106", geneDisplay: "NAT14" },
  { key: "NM_020379.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57134", geneDisplay: "MAN1C1" },
  { key: "NM_020381.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57107", geneDisplay: "PDSS2" },
  { key: "NM_020382.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387893", geneDisplay: "KMT5A" },
  { key: "NM_020383.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7511", geneDisplay: "XPNPEP1" },
  { key: "NM_020384.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9075", geneDisplay: "CLDN2" },
  { key: "NM_020385.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57109", geneDisplay: "REXO4" },
  { key: "NM_020386.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57110", geneDisplay: "PLAAT1" },
  { key: "NM_020387.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57111", geneDisplay: "RAB25" },
  { key: "NM_020389.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57113", geneDisplay: "TRPC7" },
  { key: "NM_020390.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56648", geneDisplay: "EIF5A2" },
  { key: "NM_020393.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57115", geneDisplay: "PGLYRP4" },
  { key: "NM_020394.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57116", geneDisplay: "ZNF695" },
  { key: "NM_020395.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57117", geneDisplay: "INTS12" },
  { key: "NM_020396.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10017", geneDisplay: "BCL2L10" },
  { key: "NM_020398.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57119", geneDisplay: "EPPIN" },
  { key: "NM_020399.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57120", geneDisplay: "GOPC" },
  { key: "NM_020400.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57121", geneDisplay: "LPAR5" },
  { key: "NM_020401.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57122", geneDisplay: "NUP107" },
  { key: "NM_020402.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57053", geneDisplay: "CHRNA10" },
  { key: "NM_020404.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57124", geneDisplay: "CD248" },
  { key: "NM_020405.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57125", geneDisplay: "PLXDC1" },
  { key: "NM_020406.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57126", geneDisplay: "CD177" },
  { key: "NM_020407.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57127", geneDisplay: "RHBG" },
  { key: "NM_020408.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57128", geneDisplay: "LYRM4" },
  { key: "NM_020409.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57129", geneDisplay: "MRPL47" },
  { key: "NM_020410.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57130", geneDisplay: "ATP13A1" },
  { key: "NM_020412.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57132", geneDisplay: "CHMP1B" },
  { key: "NM_020414.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57062", geneDisplay: "DDX24" },
  { key: "NM_020415.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56729", geneDisplay: "RETN" },
  { key: "NM_020416.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5522", geneDisplay: "PPP2R2C" },
  { key: "NM_020421.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57143", geneDisplay: "ADCK1" },
  { key: "NM_020423.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57147", geneDisplay: "SCYL3" },
  { key: "NM_020426.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57151", geneDisplay: "LYZL6" },
  { key: "NM_020427.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57152", geneDisplay: "SLURP1" },
  { key: "NM_020428.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57153", geneDisplay: "SLC44A2" },
  { key: "NM_020431.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57156", geneDisplay: "TMEM63C" },
  { key: "NM_020433.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57158", geneDisplay: "JPH2" },
  { key: "NM_020435.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57165", geneDisplay: "GJC2" },
  { key: "NM_020436.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57167", geneDisplay: "SALL4" },
  { key: "NM_020437.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "57168", geneDisplay: "ASPHD2" },
  { key: "NM_020438.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57171", geneDisplay: "DOLPP1" },
  { key: "NM_020439.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57172", geneDisplay: "CAMK1G" },
  { key: "NM_020440.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5738", geneDisplay: "PTGFRN" },
  { key: "NM_020441.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57175", geneDisplay: "CORO1B" },
  { key: "NM_020442.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57176", geneDisplay: "VARS2" },
  { key: "NM_020444.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57179", geneDisplay: "KIAA1191" },
  { key: "NM_020445.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57180", geneDisplay: "ACTR3B" },
  { key: "NM_020447.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57184", geneDisplay: "FAM219B" },
  { key: "NM_020448.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57185", geneDisplay: "NIPAL3" },
  { key: "NM_020451.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57190", geneDisplay: "SELENON" },
  { key: "NM_020453.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57205", geneDisplay: "ATP10D" },
  { key: "NM_020456.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "57213", geneDisplay: "SPRYD7" },
  { key: "NM_020457.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57215", geneDisplay: "THAP11" },
  { key: "NM_020458.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57217", geneDisplay: "TTC7A" },
  { key: "NM_020459.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "400961", geneDisplay: "PAIP2B" },
  { key: "NM_020461.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85378", geneDisplay: "TUBGCP6" },
  { key: "NM_020466.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57226", geneDisplay: "LYRM2" },
  { key: "NM_020469.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "28", geneDisplay: "ABO" },
  { key: "NM_020470.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10897", geneDisplay: "YIF1A" },
  { key: "NM_020474.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2589", geneDisplay: "GALNT1" },
  { key: "NM_020485.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6006", geneDisplay: "RHCE" },
  { key: "NM_020524.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57326", geneDisplay: "PBXIP1" },
  { key: "NM_020525.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50616", geneDisplay: "IL22" },
  { key: "NM_020526.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2046", geneDisplay: "EPHA8" },
  { key: "NM_020529.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4792", geneDisplay: "NFKBIA" },
  { key: "NM_020530.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5008", geneDisplay: "OSM" },
  { key: "NM_020531.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57136", geneDisplay: "APMAP" },
  { key: "NM_020532.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57142", geneDisplay: "RTN4" },
  { key: "NM_020533.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57192", geneDisplay: "MCOLN1" },
  { key: "NM_020546.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "108", geneDisplay: "ADCY2" },
  { key: "NM_020547.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "269", geneDisplay: "AMHR2" },
  { key: "NM_020549.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1103", geneDisplay: "CHAT" },
  { key: "NM_020631.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57449", geneDisplay: "PLEKHG5" },
  { key: "NM_020632.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "50617", geneDisplay: "ATP6V0A4" },
  { key: "NM_020633.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57191", geneDisplay: "VN1R1" },
  { key: "NM_020634.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9573", geneDisplay: "GDF3" },
  { key: "NM_020637.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27006", geneDisplay: "FGF22" },
  { key: "NM_020638.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8074", geneDisplay: "FGF23" },
  { key: "NM_020639.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54101", geneDisplay: "RIPK4" },
  { key: "NM_020640.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54165", geneDisplay: "DCUN1D1" },
  { key: "NM_020641.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54586", geneDisplay: "EQTN" },
  { key: "NM_020642.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56672", geneDisplay: "AKIP1" },
  { key: "NM_020643.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56673", geneDisplay: "C11orf16" },
  { key: "NM_020644.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56674", geneDisplay: "TMEM9B" },
  { key: "NM_020645.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56675", geneDisplay: "NRIP3" },
  { key: "NM_020646.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56676", geneDisplay: "ASCL3" },
  { key: "NM_020647.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "56704", geneDisplay: "JPH1" },
  { key: "NM_020648.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57045", geneDisplay: "TWSG1" },
  { key: "NM_020649.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57332", geneDisplay: "CBX8" },
  { key: "NM_020650.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57333", geneDisplay: "RCN3" },
  { key: "NM_020651.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57162", geneDisplay: "PELI1" },
  { key: "NM_020653.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57336", geneDisplay: "ZNF287" },
  { key: "NM_020654.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57337", geneDisplay: "SENP7" },
  { key: "NM_020655.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57338", geneDisplay: "JPH3" },
  { key: "NM_020657.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57343", geneDisplay: "ZNF304" },
  { key: "NM_020659.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57348", geneDisplay: "TTYH1" },
  { key: "NM_020660.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57369", geneDisplay: "GJD2" },
  { key: "NM_020661.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57379", geneDisplay: "AICDA" },
  { key: "NM_020662.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57380", geneDisplay: "MRS2" },
  { key: "NM_020663.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57381", geneDisplay: "RHOJ" },
  { key: "NM_020664.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26063", geneDisplay: "DECR2" },
  { key: "NM_020665.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57393", geneDisplay: "CLTRN" },
  { key: "NM_020666.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57396", geneDisplay: "CLK4" },
  { key: "NM_020672.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57402", geneDisplay: "S100A14" },
  { key: "NM_020673.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57403", geneDisplay: "RAB22A" },
  { key: "NM_020675.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57405", geneDisplay: "SPC25" },
  { key: "NM_020677.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57407", geneDisplay: "NMRAL1" },
  { key: "NM_020678.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57408", geneDisplay: "LRTM1" },
  { key: "NM_020680.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57410", geneDisplay: "SCYL1" },
  { key: "NM_020682.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57412", geneDisplay: "AS3MT" },
  { key: "NM_020683.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57413", geneDisplay: "TMIGD3" },
  { key: "NM_020686.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "18", geneDisplay: "ABAT" },
  { key: "NM_020689.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57419", geneDisplay: "SLC24A3" },
  { key: "NM_020693.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57453", geneDisplay: "DSCAML1" },
  { key: "NM_020695.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57455", geneDisplay: "REXO1" },
  { key: "NM_020696.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57456", geneDisplay: "KIAA1143" },
  { key: "NM_020697.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "3788", geneDisplay: "KCNS2" },
  { key: "NM_020698.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57458", geneDisplay: "TMCC3" },
  { key: "NM_020699.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57459", geneDisplay: "GATAD2B" },
  { key: "NM_020700.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57460", geneDisplay: "PPM1H" },
  { key: "NM_020701.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57461", geneDisplay: "ISY1" },
  { key: "NM_020702.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57462", geneDisplay: "MYORG" },
  { key: "NM_020703.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57463", geneDisplay: "AMIGO1" },
  { key: "NM_020704.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57464", geneDisplay: "STRIP2" },
  { key: "NM_020706.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "57466", geneDisplay: "SCAF4" },
  { key: "NM_020707.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57467", geneDisplay: "HHATL" },
  { key: "NM_020708.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57468", geneDisplay: "SLC12A5" },
  { key: "NM_020709.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57469", geneDisplay: "PNMA8B" },
  { key: "NM_020710.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57470", geneDisplay: "LRRC47" },
  { key: "NM_020711.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57471", geneDisplay: "ERMN" },
  { key: "NM_020713.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57473", geneDisplay: "ZNF512B" },
  { key: "NM_020714.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57474", geneDisplay: "ZNF490" },
  { key: "NM_020715.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57475", geneDisplay: "PLEKHH1" },
  { key: "NM_020717.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57477", geneDisplay: "SHROOM4" },
  { key: "NM_020718.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57478", geneDisplay: "USP31" },
  { key: "NM_020719.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57479", geneDisplay: "PRR12" },
  { key: "NM_020724.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57484", geneDisplay: "RNF150" },
  { key: "NM_020725.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222255", geneDisplay: "ATXN7L1" },
  { key: "NM_020726.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57486", geneDisplay: "NLN" },
  { key: "NM_020733.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57493", geneDisplay: "HEG1" },
  { key: "NM_020737.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57497", geneDisplay: "LRFN2" },
  { key: "NM_020738.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57498", geneDisplay: "KIDINS220" },
  { key: "NM_020745.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57505", geneDisplay: "AARS2" },
  { key: "NM_020746.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57506", geneDisplay: "MAVS" },
  { key: "NM_020747.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57507", geneDisplay: "ZNF608" },
  { key: "NM_020750.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57510", geneDisplay: "XPO5" },
  { key: "NM_020751.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "57511", geneDisplay: "COG6" },
  { key: "NM_020752.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57512", geneDisplay: "GPR158" },
  { key: "NM_020753.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57513", geneDisplay: "CASKIN2" },
  { key: "NM_020754.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57514", geneDisplay: "ARHGAP31" },
  { key: "NM_020755.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57515", geneDisplay: "SERINC1" },
  { key: "NM_020759.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57519", geneDisplay: "STARD9" },
  { key: "NM_020761.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57521", geneDisplay: "RPTOR" },
  { key: "NM_020762.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57522", geneDisplay: "SRGAP1" },
  { key: "NM_020764.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57524", geneDisplay: "CASKIN1" },
  { key: "NM_020765.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23352", geneDisplay: "UBR4" },
  { key: "NM_020768.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57528", geneDisplay: "KCTD16" },
  { key: "NM_020770.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57530", geneDisplay: "CGN" },
  { key: "NM_020771.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57531", geneDisplay: "HACE1" },
  { key: "NM_020772.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57532", geneDisplay: "NUFIP2" },
  { key: "NM_020773.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57533", geneDisplay: "TBC1D14" },
  { key: "NM_020774.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57534", geneDisplay: "MIB1" },
  { key: "NM_020775.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57535", geneDisplay: "ELAPOR1" },
  { key: "NM_020776.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57536", geneDisplay: "KIAA1328" },
  { key: "NM_020777.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57537", geneDisplay: "SORCS2" },
  { key: "NM_020778.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57538", geneDisplay: "ALPK3" },
  { key: "NM_020779.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57539", geneDisplay: "WDR35" },
  { key: "NM_020780.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57540", geneDisplay: "DISP3" },
  { key: "NM_020782.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57542", geneDisplay: "KLHL42" },
  { key: "NM_020783.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6860", geneDisplay: "SYT4" },
  { key: "NM_020784.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57544", geneDisplay: "TXNDC16" },
  { key: "NM_020786.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57546", geneDisplay: "PDP2" },
  { key: "NM_020787.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57547", geneDisplay: "ZNF624" },
  { key: "NM_020791.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57551", geneDisplay: "TAOK1" },
  { key: "NM_020792.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57552", geneDisplay: "NCEH1" },
  { key: "NM_020795.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57555", geneDisplay: "NLGN2" },
  { key: "NM_020796.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57556", geneDisplay: "SEMA6A" },
  { key: "NM_020798.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57558", geneDisplay: "USP35" },
  { key: "NM_020799.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57559", geneDisplay: "STAMBPL1" },
  { key: "NM_020800.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57560", geneDisplay: "IFT80" },
  { key: "NM_020801.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57561", geneDisplay: "ARRDC3" },
  { key: "NM_020802.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57562", geneDisplay: "CEP126" },
  { key: "NM_020803.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57563", geneDisplay: "KLHL8" },
  { key: "NM_020804.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "29993", geneDisplay: "PACSIN1" },
  { key: "NM_020805.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57565", geneDisplay: "KLHL14" },
  { key: "NM_020806.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10243", geneDisplay: "GPHN" },
  { key: "NM_020807.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57567", geneDisplay: "ZNF319" },
  { key: "NM_020808.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57568", geneDisplay: "SIPA1L2" },
  { key: "NM_020810.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57570", geneDisplay: "TRMT5" },
  { key: "NM_020812.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57572", geneDisplay: "DOCK6" },
  { key: "NM_020813.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57573", geneDisplay: "ZNF471" },
  { key: "NM_020814.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57574", geneDisplay: "MARCHF4" },
  { key: "NM_020817.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57577", geneDisplay: "CCDC191" },
  { key: "NM_020820.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57580", geneDisplay: "PREX1" },
  { key: "NM_020821.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54832", geneDisplay: "VPS13C" },
  { key: "NM_020822.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57582", geneDisplay: "KCNT1" },
  { key: "NM_020824.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57584", geneDisplay: "ARHGAP21" },
  { key: "NM_020825.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57585", geneDisplay: "CRAMP1" },
  { key: "NM_020826.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57586", geneDisplay: "SYT13" },
  { key: "NM_020827.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57587", geneDisplay: "CFAP97" },
  { key: "NM_020828.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "140612", geneDisplay: "ZFP28" },
  { key: "NM_020829.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57589", geneDisplay: "RIC1" },
  { key: "NM_020830.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57590", geneDisplay: "WDFY1" },
  { key: "NM_020831.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "57591", geneDisplay: "MRTFA" },
  { key: "NM_020832.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57592", geneDisplay: "ZNF687" },
  { key: "NM_020834.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57594", geneDisplay: "HOMEZ" },
  { key: "NM_020839.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57599", geneDisplay: "WDR48" },
  { key: "NM_020840.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57600", geneDisplay: "FNIP2" },
  { key: "NM_020841.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "114882", geneDisplay: "OSBPL8" },
  { key: "NM_020843.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "49855", geneDisplay: "SCAPER" },
  { key: "NM_020844.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57604", geneDisplay: "TRMT9B" },
  { key: "NM_020845.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57605", geneDisplay: "PITPNM2" },
  { key: "NM_020846.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57606", geneDisplay: "SLAIN2" },
  { key: "NM_020848.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57608", geneDisplay: "JCAD" },
  { key: "NM_020850.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57610", geneDisplay: "RANBP10" },
  { key: "NM_020851.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57611", geneDisplay: "ISLR2" },
  { key: "NM_020853.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57613", geneDisplay: "FAM234B" },
  { key: "NM_020855.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57615", geneDisplay: "ZNF492" },
  { key: "NM_020856.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57616", geneDisplay: "TSHZ3" },
  { key: "NM_020857.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57617", geneDisplay: "VPS18" },
  { key: "NM_020859.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57619", geneDisplay: "SHROOM3" },
  { key: "NM_020860.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57620", geneDisplay: "STIM2" },
  { key: "NM_020861.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57621", geneDisplay: "ZBTB2" },
  { key: "NM_020862.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57622", geneDisplay: "LRFN1" },
  { key: "NM_020863.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57623", geneDisplay: "ZFAT" },
  { key: "NM_020865.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "170506", geneDisplay: "DHX36" },
  { key: "NM_020866.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "57626", geneDisplay: "KLHL1" },
  { key: "NM_020868.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57628", geneDisplay: "DPP10" },
  { key: "NM_020870.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57630", geneDisplay: "SH3RF1" },
  { key: "NM_020871.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57631", geneDisplay: "LRCH2" },
  { key: "NM_020872.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5067", geneDisplay: "CNTN3" },
  { key: "NM_020873.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57633", geneDisplay: "LRRN1" },
  { key: "NM_020877.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146754", geneDisplay: "DNAH2" },
  { key: "NM_020879.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57639", geneDisplay: "CCDC146" },
  { key: "NM_020882.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57642", geneDisplay: "COL20A1" },
  { key: "NM_020883.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57643", geneDisplay: "ZSWIM5" },
  { key: "NM_020884.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57644", geneDisplay: "MYH7B" },
  { key: "NM_020888.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57648", geneDisplay: "KIAA1522" },
  { key: "NM_020890.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57650", geneDisplay: "CIP2A" },
  { key: "NM_020893.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100499483", geneDisplay: "CCDC180" },
  { key: "NM_020894.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57654", geneDisplay: "UVSSA" },
  { key: "NM_020895.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57655", geneDisplay: "GRAMD1A" },
  { key: "NM_020896.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114879", geneDisplay: "OSBPL5" },
  { key: "NM_020897.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57657", geneDisplay: "HCN3" },
  { key: "NM_020898.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57658", geneDisplay: "CALCOCO1" },
  { key: "NM_020902.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57662", geneDisplay: "CAMSAP3" },
  { key: "NM_020903.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57663", geneDisplay: "USP29" },
  { key: "NM_020904.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57664", geneDisplay: "PLEKHA4" },
  { key: "NM_020905.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57665", geneDisplay: "RDH14" },
  { key: "NM_020909.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57669", geneDisplay: "EPB41L5" },
  { key: "NM_020911.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "91584", geneDisplay: "PLXNA4" },
  { key: "NM_020917.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57677", geneDisplay: "ZFP14" },
  { key: "NM_020919.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57679", geneDisplay: "ALS2" },
  { key: "NM_020921.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51199", geneDisplay: "NIN" },
  { key: "NM_020922.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "65267", geneDisplay: "WNK3" },
  { key: "NM_020923.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57683", geneDisplay: "ZDBF2" },
  { key: "NM_020924.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57684", geneDisplay: "ZBTB26" },
  { key: "NM_020925.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57685", geneDisplay: "CACHD1" },
  { key: "NM_020927.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57687", geneDisplay: "VAT1L" },
  { key: "NM_020928.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57688", geneDisplay: "ZSWIM6" },
  { key: "NM_020931.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57691", geneDisplay: "KIAA1586" },
  { key: "NM_020932.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57692", geneDisplay: "MAGEE1" },
  { key: "NM_020933.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57693", geneDisplay: "ZNF317" },
  { key: "NM_020935.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57695", geneDisplay: "USP37" },
  { key: "NM_020936.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57696", geneDisplay: "DDX55" },
  { key: "NM_020937.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57697", geneDisplay: "FANCM" },
  { key: "NM_020939.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57699", geneDisplay: "CPNE5" },
  { key: "NM_020940.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57700", geneDisplay: "FHIP2A" },
  { key: "NM_020943.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57703", geneDisplay: "CWC22" },
  { key: "NM_020944.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57704", geneDisplay: "GBA2" },
  { key: "NM_020947.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57707", geneDisplay: "MEAK7" },
  { key: "NM_020949.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57709", geneDisplay: "SLC7A14" },
  { key: "NM_020950.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57710", geneDisplay: "KIAA1614" },
  { key: "NM_020951.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57711", geneDisplay: "ZNF529" },
  { key: "NM_020957.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57717", geneDisplay: "PCDHB16" },
  { key: "NM_020959.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57719", geneDisplay: "ANO8" },
  { key: "NM_020960.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57720", geneDisplay: "GPR107" },
  { key: "NM_020961.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57721", geneDisplay: "METTL14" },
  { key: "NM_020962.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57722", geneDisplay: "IGDCC4" },
  { key: "NM_020964.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57724", geneDisplay: "EPG5" },
  { key: "NM_020967.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57727", geneDisplay: "NCOA5" },
  { key: "NM_020971.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57731", geneDisplay: "SPTBN4" },
  { key: "NM_020972.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57732", geneDisplay: "ZFYVE28" },
  { key: "NM_020975.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5979", geneDisplay: "RET" },
  { key: "NM_020980.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "366", geneDisplay: "AQP9" },
  { key: "NM_020981.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8708", geneDisplay: "B3GALT1" },
  { key: "NM_020982.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9080", geneDisplay: "CLDN9" },
  { key: "NM_020987.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "288", geneDisplay: "ANK3" },
  { key: "NM_020988.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2775", geneDisplay: "GNAO1" },
  { key: "NM_020989.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1420", geneDisplay: "CRYGC" },
  { key: "NM_020991.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1443", geneDisplay: "CSH2" },
  { key: "NM_020992.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9124", geneDisplay: "PDLIM1" },
  { key: "NM_020995.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3250", geneDisplay: "HPR" },
  { key: "NM_020996.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2251", geneDisplay: "FGF6" },
  { key: "NM_020997.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10637", geneDisplay: "LEFTY1" },
  { key: "NM_020998.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4485", geneDisplay: "MST1" },
  { key: "NM_020999.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "50674", geneDisplay: "NEUROG3" },
  { key: "NM_021002.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3443", geneDisplay: "IFNA6" },
  { key: "NM_021003.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5494", geneDisplay: "PPM1A" },
  { key: "NM_021004.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10901", geneDisplay: "DHRS4" },
  { key: "NM_021005.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7026", geneDisplay: "NR2F2" },
  { key: "NM_021008.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10522", geneDisplay: "DEAF1" },
  { key: "NM_021009.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7316", geneDisplay: "UBC" },
  { key: "NM_021010.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1670", geneDisplay: "DEFA5" },
  { key: "NM_021012.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3768", geneDisplay: "KCNJ12" },
  { key: "NM_021014.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10214", geneDisplay: "SSX3" },
  { key: "NM_021016.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5671", geneDisplay: "PSG3" },
  { key: "NM_021018.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8968", geneDisplay: "H3C7" },
  { key: "NM_021019.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4637", geneDisplay: "MYL6" },
  { key: "NM_021020.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11178", geneDisplay: "LZTS1" },
  { key: "NM_021021.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6641", geneDisplay: "SNTB1" },
  { key: "NM_021023.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10878", geneDisplay: "CFHR3" },
  { key: "NM_021025.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "30012", geneDisplay: "TLX3" },
  { key: "NM_021027.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54600", geneDisplay: "UGT1A9" },
  { key: "NM_021029.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6173", geneDisplay: "RPL36A" },
  { key: "NM_021030.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7561", geneDisplay: "ZNF14" },
  { key: "NM_021033.7", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5911", geneDisplay: "RAP2A" },
  { key: "NM_021034.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10410", geneDisplay: "IFITM3" },
  { key: "NM_021035.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57169", geneDisplay: "ZNFX1" },
  { key: "NM_021038.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4154", geneDisplay: "MBNL1" },
  { key: "NM_021044.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50846", geneDisplay: "DHH" },
  { key: "NM_021045.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57209", geneDisplay: "ZNF248" },
  { key: "NM_021046.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57830", geneDisplay: "KRTAP5-8" },
  { key: "NM_021047.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56242", geneDisplay: "ZNF253" },
  { key: "NM_021048.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4109", geneDisplay: "MAGEA10" },
  { key: "NM_021052.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3012", geneDisplay: "H2AC8" },
  { key: "NM_021057.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3444", geneDisplay: "IFNA7" },
  { key: "NM_021058.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8970", geneDisplay: "H2BC11" },
  { key: "NM_021059.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126961", geneDisplay: "H3C14" },
  { key: "NM_021062.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3018", geneDisplay: "H2BC3" },
  { key: "NM_021063.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3017", geneDisplay: "H2BC5" },
  { key: "NM_021064.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8969", geneDisplay: "H2AC11" },
  { key: "NM_021065.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3013", geneDisplay: "H2AC7" },
  { key: "NM_021066.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8331", geneDisplay: "H2AC14" },
  { key: "NM_021067.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9837", geneDisplay: "GINS1" },
  { key: "NM_021068.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3441", geneDisplay: "IFNA4" },
  { key: "NM_021071.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "420", geneDisplay: "ART4" },
  { key: "NM_021072.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "348980", geneDisplay: "HCN1" },
  { key: "NM_021073.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "653", geneDisplay: "BMP5" },
  { key: "NM_021074.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4729", geneDisplay: "NDUFV2" },
  { key: "NM_021075.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "4731", geneDisplay: "NDUFV3" },
  { key: "NM_021076.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4744", geneDisplay: "NEFH" },
  { key: "NM_021077.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4828", geneDisplay: "NMB" },
  { key: "NM_021078.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2648", geneDisplay: "KAT2A" },
  { key: "NM_021079.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4836", geneDisplay: "NMT1" },
  { key: "NM_021081.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2691", geneDisplay: "GHRH" },
  { key: "NM_021082.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6565", geneDisplay: "SLC15A2" },
  { key: "NM_021083.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7504", geneDisplay: "XK" },
  { key: "NM_021088.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7549", geneDisplay: "ZNF2" },
  { key: "NM_021089.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7554", geneDisplay: "ZNF8" },
  { key: "NM_021090.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8897", geneDisplay: "MTMR3" },
  { key: "NM_021095.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8884", geneDisplay: "SLC5A6" },
  { key: "NM_021096.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8911", geneDisplay: "CACNA1I" },
  { key: "NM_021097.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6546", geneDisplay: "SLC8A1" },
  { key: "NM_021098.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8912", geneDisplay: "CACNA1H" },
  { key: "NM_021100.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9054", geneDisplay: "NFS1" },
  { key: "NM_021101.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9076", geneDisplay: "CLDN1" },
  { key: "NM_021102.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10653", geneDisplay: "SPINT2" },
  { key: "NM_021103.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9168", geneDisplay: "TMSB10" },
  { key: "NM_021105.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5359", geneDisplay: "PLSCR1" },
  { key: "NM_021109.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7114", geneDisplay: "TMSB4X" },
  { key: "NM_021110.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7373", geneDisplay: "COL14A1" },
  { key: "NM_021111.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8434", geneDisplay: "RECK" },
  { key: "NM_021115.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23544", geneDisplay: "SEZ6L" },
  { key: "NM_021116.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "107", geneDisplay: "ADCY1" },
  { key: "NM_021117.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1408", geneDisplay: "CRY2" },
  { key: "NM_021118.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1538", geneDisplay: "CYLC1" },
  { key: "NM_021120.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1741", geneDisplay: "DLG3" },
  { key: "NM_021126.8", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4357", geneDisplay: "MPST" },
  { key: "NM_021127.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5366", geneDisplay: "PMAIP1" },
  { key: "NM_021128.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5441", geneDisplay: "POLR2L" },
  { key: "NM_021129.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5464", geneDisplay: "PPA1" },
  { key: "NM_021130.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5478", geneDisplay: "PPIA" },
  { key: "NM_021132.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5532", geneDisplay: "PPP3CB" },
  { key: "NM_021133.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6041", geneDisplay: "RNASEL" },
  { key: "NM_021134.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6150", geneDisplay: "MRPL23" },
  { key: "NM_021135.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6196", geneDisplay: "RPS6KA2" },
  { key: "NM_021136.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6252", geneDisplay: "RTN1" },
  { key: "NM_021137.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7126", geneDisplay: "TNFAIP1" },
  { key: "NM_021138.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7186", geneDisplay: "TRAF2" },
  { key: "NM_021139.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7363", geneDisplay: "UGT2B4" },
  { key: "NM_021141.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7520", geneDisplay: "XRCC5" },
  { key: "NM_021143.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7568", geneDisplay: "ZNF20" },
  { key: "NM_021146.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10218", geneDisplay: "ANGPTL7" },
  { key: "NM_021147.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10309", geneDisplay: "CCNO" },
  { key: "NM_021148.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10793", geneDisplay: "ZNF273" },
  { key: "NM_021149.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23406", geneDisplay: "COTL1" },
  { key: "NM_021151.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54677", geneDisplay: "CROT" },
  { key: "NM_021153.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "28513", geneDisplay: "CDH19" },
  { key: "NM_021155.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "30835", geneDisplay: "CD209" },
  { key: "NM_021156.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56255", geneDisplay: "TMX4" },
  { key: "NM_021158.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57761", geneDisplay: "TRIB3" },
  { key: "NM_021160.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7920", geneDisplay: "ABHD16A" },
  { key: "NM_021163.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57786", geneDisplay: "RBAK" },
  { key: "NM_021165.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57795", geneDisplay: "BRINP2" },
  { key: "NM_021167.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57798", geneDisplay: "GATAD1" },
  { key: "NM_021168.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57799", geneDisplay: "RAB40C" },
  { key: "NM_021170.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57801", geneDisplay: "HES4" },
  { key: "NM_021173.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57804", geneDisplay: "POLD4" },
  { key: "NM_021175.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57817", geneDisplay: "HAMP" },
  { key: "NM_021176.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57818", geneDisplay: "G6PC2" },
  { key: "NM_021177.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57819", geneDisplay: "LSM2" },
  { key: "NM_021178.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57820", geneDisplay: "CCNB1IP1" },
  { key: "NM_021181.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57823", geneDisplay: "SLAMF7" },
  { key: "NM_021182.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57824", geneDisplay: "HMHB1" },
  { key: "NM_021184.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57827", geneDisplay: "C6orf47" },
  { key: "NM_021185.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57828", geneDisplay: "CATSPERG" },
  { key: "NM_021186.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57829", geneDisplay: "ZP4" },
  { key: "NM_021187.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57834", geneDisplay: "CYP4F11" },
  { key: "NM_021188.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57862", geneDisplay: "ZNF410" },
  { key: "NM_021190.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58155", geneDisplay: "PTBP2" },
  { key: "NM_021191.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "58158", geneDisplay: "NEUROD4" },
  { key: "NM_021192.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3237", geneDisplay: "HOXD11" },
  { key: "NM_021193.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3238", geneDisplay: "HOXD12" },
  { key: "NM_021194.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7779", geneDisplay: "SLC30A1" },
  { key: "NM_021195.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9074", geneDisplay: "CLDN6" },
  { key: "NM_021197.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "58189", geneDisplay: "WFDC1" },
  { key: "NM_021198.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "58190", geneDisplay: "CTDSP1" },
  { key: "NM_021199.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "58472", geneDisplay: "SQOR" },
  { key: "NM_021200.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "58473", geneDisplay: "PLEKHB1" },
  { key: "NM_021201.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "58475", geneDisplay: "MS4A7" },
  { key: "NM_021202.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "58476", geneDisplay: "TP53INP2" },
  { key: "NM_021204.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "58478", geneDisplay: "ENOPH1" },
  { key: "NM_021205.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58480", geneDisplay: "RHOU" },
  { key: "NM_021210.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "58485", geneDisplay: "TRAPPC1" },
  { key: "NM_021213.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "58488", geneDisplay: "PCTP" },
  { key: "NM_021214.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "58489", geneDisplay: "ABHD17C" },
  { key: "NM_021215.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "58490", geneDisplay: "RPRD1B" },
  { key: "NM_021217.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58492", geneDisplay: "ZNF77" },
  { key: "NM_021218.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "58493", geneDisplay: "INIP" },
  { key: "NM_021219.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "58494", geneDisplay: "JAM2" },
  { key: "NM_021220.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "58495", geneDisplay: "OVOL2" },
  { key: "NM_021221.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "58496", geneDisplay: "LY6G5B" },
  { key: "NM_021222.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58497", geneDisplay: "PRUNE1" },
  { key: "NM_021223.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "58498", geneDisplay: "MYL7" },
  { key: "NM_021224.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "58499", geneDisplay: "ZNF462" },
  { key: "NM_021225.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "58503", geneDisplay: "OPRPN" },
  { key: "NM_021226.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "58504", geneDisplay: "ARHGAP22" },
  { key: "NM_021227.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "58505", geneDisplay: "OSTC" },
  { key: "NM_021228.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58506", geneDisplay: "SCAF1" },
  { key: "NM_021229.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "59277", geneDisplay: "NTN4" },
  { key: "NM_021232.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58510", geneDisplay: "PRODH2" },
  { key: "NM_021233.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58511", geneDisplay: "DNASE2B" },
  { key: "NM_021237.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "58515", geneDisplay: "SELENOK" },
  { key: "NM_021239.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "58517", geneDisplay: "RBM25" },
  { key: "NM_021240.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "58524", geneDisplay: "DMRT3" },
  { key: "NM_021242.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "58526", geneDisplay: "MID1IP1" },
  { key: "NM_021243.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "58527", geneDisplay: "ABRACL" },
  { key: "NM_021244.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "58528", geneDisplay: "RRAGD" },
  { key: "NM_021245.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "58529", geneDisplay: "MYOZ1" },
  { key: "NM_021246.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "58530", geneDisplay: "LY6G6D" },
  { key: "NM_021247.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "58531", geneDisplay: "PRM3" },
  { key: "NM_021248.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64405", geneDisplay: "CDH22" },
  { key: "NM_021250.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "353514", geneDisplay: "LILRA5" },
  { key: "NM_021252.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22931", geneDisplay: "RAB18" },
  { key: "NM_021254.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "56683", geneDisplay: "CFAP298" },
  { key: "NM_021255.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57161", geneDisplay: "PELI2" },
  { key: "NM_021257.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "58157", geneDisplay: "NGB" },
  { key: "NM_021258.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58985", geneDisplay: "IL22RA1" },
  { key: "NM_021259.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "58986", geneDisplay: "PGAP6" },
  { key: "NM_021260.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "53349", geneDisplay: "ZFYVE1" },
  { key: "NM_021267.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10715", geneDisplay: "CERS1" },
  { key: "NM_021268.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3451", geneDisplay: "IFNA17" },
  { key: "NM_021269.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7562", geneDisplay: "ZNF708" },
  { key: "NM_021570.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "56033", geneDisplay: "BARX1" },
  { key: "NM_021571.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "59082", geneDisplay: "CARD18" },
  { key: "NM_021614.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3781", geneDisplay: "KCNN2" },
  { key: "NM_021615.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4166", geneDisplay: "CHST6" },
  { key: "NM_021616.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53840", geneDisplay: "TRIM34" },
  { key: "NM_021619.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "59335", geneDisplay: "PRDM12" },
  { key: "NM_021620.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "59336", geneDisplay: "PRDM13" },
  { key: "NM_021623.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "59339", geneDisplay: "PLEKHA2" },
  { key: "NM_021624.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "59340", geneDisplay: "HRH4" },
  { key: "NM_021625.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "59341", geneDisplay: "TRPV4" },
  { key: "NM_021626.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "59342", geneDisplay: "SCPEP1" },
  { key: "NM_021627.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "59343", geneDisplay: "SENP2" },
  { key: "NM_021628.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "59344", geneDisplay: "ALOXE3" },
  { key: "NM_021629.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "59345", geneDisplay: "GNB4" },
  { key: "NM_021632.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "59348", geneDisplay: "ZNF350" },
  { key: "NM_021633.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "59349", geneDisplay: "KLHL12" },
  { key: "NM_021634.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "59350", geneDisplay: "RXFP1" },
  { key: "NM_021635.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "59351", geneDisplay: "PBOV1" },
  { key: "NM_021637.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "59353", geneDisplay: "TMEM35A" },
  { key: "NM_021639.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "60313", geneDisplay: "GPBP1L1" },
  { key: "NM_021640.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "60314", geneDisplay: "MYG1" },
  { key: "NM_021643.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "28951", geneDisplay: "TRIB2" },
  { key: "NM_021645.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9724", geneDisplay: "UTP14C" },
  { key: "NM_021646.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26048", geneDisplay: "ZNF500" },
  { key: "NM_021647.8", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9848", geneDisplay: "MFAP3L" },
  { key: "NM_021648.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23270", geneDisplay: "TSPYL4" },
  { key: "NM_021649.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "353376", geneDisplay: "TICAM2" },
  { key: "NM_021724.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9572", geneDisplay: "NR1D1" },
  { key: "NM_021727.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3995", geneDisplay: "FADS3" },
  { key: "NM_021728.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5015", geneDisplay: "OTX2" },
  { key: "NM_021729.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55823", geneDisplay: "VPS11" },
  { key: "NM_021732.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "60370", geneDisplay: "AVPI1" },
  { key: "NM_021733.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "60385", geneDisplay: "TSKS" },
  { key: "NM_021738.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6840", geneDisplay: "SVIL" },
  { key: "NM_021783.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "60401", geneDisplay: "EDA2R" },
  { key: "NM_021784.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3170", geneDisplay: "FOXA2" },
  { key: "NM_021785.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10742", geneDisplay: "RAI2" },
  { key: "NM_021794.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11085", geneDisplay: "ADAM30" },
  { key: "NM_021796.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10761", geneDisplay: "PLAC1" },
  { key: "NM_021800.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56521", geneDisplay: "DNAJC12" },
  { key: "NM_021801.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56547", geneDisplay: "MMP26" },
  { key: "NM_021803.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "59067", geneDisplay: "IL21" },
  { key: "NM_021806.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "60343", geneDisplay: "FAM3A" },
  { key: "NM_021807.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "60412", geneDisplay: "EXOC4" },
  { key: "NM_021809.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "60436", geneDisplay: "TGIF2" },
  { key: "NM_021813.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "60468", geneDisplay: "BACH2" },
  { key: "NM_021814.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "60481", geneDisplay: "ELOVL5" },
  { key: "NM_021815.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "60482", geneDisplay: "SLC5A7" },
  { key: "NM_021817.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "60484", geneDisplay: "HAPLN2" },
  { key: "NM_021818.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "60485", geneDisplay: "SAV1" },
  { key: "NM_021819.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79748", geneDisplay: "LMAN1L" },
  { key: "NM_021821.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "60488", geneDisplay: "MRPS35" },
  { key: "NM_021822.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "60489", geneDisplay: "APOBEC3G" },
  { key: "NM_021823.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "60490", geneDisplay: "PPCDC" },
  { key: "NM_021825.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "60492", geneDisplay: "CCDC90B" },
  { key: "NM_021826.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "60493", geneDisplay: "FASTKD5" },
  { key: "NM_021828.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "60495", geneDisplay: "HPSE2" },
  { key: "NM_021830.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56652", geneDisplay: "TWNK" },
  { key: "NM_021831.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "60509", geneDisplay: "AGBL5" },
  { key: "NM_021833.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7350", geneDisplay: "UCP1" },
  { key: "NM_021870.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2266", geneDisplay: "FGG" },
  { key: "NM_021871.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2243", geneDisplay: "FGA" },
  { key: "NM_021873.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "994", geneDisplay: "CDC25B" },
  { key: "NM_021902.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5348", geneDisplay: "FXYD1" },
  { key: "NM_021907.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1838", geneDisplay: "DTNB" },
  { key: "NM_021913.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "558", geneDisplay: "AXL" },
  { key: "NM_021916.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7621", geneDisplay: "ZNF70" },
  { key: "NM_021920.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6343", geneDisplay: "SCT" },
  { key: "NM_021922.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2178", geneDisplay: "FANCE" },
  { key: "NM_021924.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53841", geneDisplay: "CDHR5" },
  { key: "NM_021925.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "60526", geneDisplay: "LDAH" },
  { key: "NM_021926.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "60529", geneDisplay: "ALX4" },
  { key: "NM_021927.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "60558", geneDisplay: "GUF1" },
  { key: "NM_021928.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "60559", geneDisplay: "SPCS3" },
  { key: "NM_021930.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "60561", geneDisplay: "RINT1" },
  { key: "NM_021931.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "60625", geneDisplay: "DHX35" },
  { key: "NM_021933.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "60672", geneDisplay: "MIIP" },
  { key: "NM_021934.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "60673", geneDisplay: "ATG101" },
  { key: "NM_021937.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "60678", geneDisplay: "EEFSEC" },
  { key: "NM_021938.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "60680", geneDisplay: "CELF5" },
  { key: "NM_021939.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "60681", geneDisplay: "FKBP10" },
  { key: "NM_021942.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "60684", geneDisplay: "TRAPPC11" },
  { key: "NM_021943.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "60685", geneDisplay: "ZFAND3" },
  { key: "NM_021944.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "60686", geneDisplay: "C14orf93" },
  { key: "NM_021947.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "63826", geneDisplay: "SRR" },
  { key: "NM_021948.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63827", geneDisplay: "BCAN" },
  { key: "NM_021951.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1761", geneDisplay: "DMRT1" },
  { key: "NM_021953.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2305", geneDisplay: "FOXM1" },
  { key: "NM_021954.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2700", geneDisplay: "GJA3" },
  { key: "NM_021955.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2792", geneDisplay: "GNGT1" },
  { key: "NM_021956.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2898", geneDisplay: "GRIK2" },
  { key: "NM_021957.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2998", geneDisplay: "GYS2" },
  { key: "NM_021958.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3142", geneDisplay: "HLX" },
  { key: "NM_021959.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6992", geneDisplay: "PPP1R11" },
  { key: "NM_021960.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4170", geneDisplay: "MCL1" },
  { key: "NM_021961.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7003", geneDisplay: "TEAD1" },
  { key: "NM_021962.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "29", geneDisplay: "ABR" },
  { key: "NM_021963.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4674", geneDisplay: "NAP1L2" },
  { key: "NM_021964.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7707", geneDisplay: "ZNF148" },
  { key: "NM_021965.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5239", geneDisplay: "PGM5" },
  { key: "NM_021966.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8115", geneDisplay: "TCL1A" },
  { key: "NM_021968.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8363", geneDisplay: "H4C11" },
  { key: "NM_021969.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8431", geneDisplay: "NR0B2" },
  { key: "NM_021970.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8649", geneDisplay: "LAMTOR3" },
  { key: "NM_021971.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29925", geneDisplay: "GMPPB" },
  { key: "NM_021973.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9464", geneDisplay: "HAND2" },
  { key: "NM_021974.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5435", geneDisplay: "POLR2F" },
  { key: "NM_021975.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5970", geneDisplay: "RELA" },
  { key: "NM_021976.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6257", geneDisplay: "RXRB" },
  { key: "NM_021977.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6581", geneDisplay: "SLC22A3" },
  { key: "NM_021978.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6768", geneDisplay: "ST14" },
  { key: "NM_021979.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3306", geneDisplay: "HSPA2" },
  { key: "NM_021982.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10802", geneDisplay: "SEC24A" },
  { key: "NM_021992.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11013", geneDisplay: "TMSB15A" },
  { key: "NM_021994.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11179", geneDisplay: "ZNF277" },
  { key: "NM_021996.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26301", geneDisplay: "GBGT1" },
  { key: "NM_021999.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9445", geneDisplay: "ITM2B" },
  { key: "NM_022003.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53826", geneDisplay: "FXYD6" },
  { key: "NM_022006.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53822", geneDisplay: "FXYD7" },
  { key: "NM_022034.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "50624", geneDisplay: "CUZD1" },
  { key: "NM_022036.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55890", geneDisplay: "GPRC5C" },
  { key: "NM_022039.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6468", geneDisplay: "FBXW4" },
  { key: "NM_022041.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8139", geneDisplay: "GAN" },
  { key: "NM_022042.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10861", geneDisplay: "SLC26A1" },
  { key: "NM_022044.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23753", geneDisplay: "SDF2L1" },
  { key: "NM_022045.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27085", geneDisplay: "MTBP" },
  { key: "NM_022047.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "50619", geneDisplay: "DEF6" },
  { key: "NM_022048.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "53944", geneDisplay: "CSNK1G1" },
  { key: "NM_022049.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54112", geneDisplay: "GPR88" },
  { key: "NM_022051.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54583", geneDisplay: "EGLN1" },
  { key: "NM_022052.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56000", geneDisplay: "NXF3" },
  { key: "NM_022053.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56001", geneDisplay: "NXF2" },
  { key: "NM_022054.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56659", geneDisplay: "KCNK13" },
  { key: "NM_022055.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56660", geneDisplay: "KCNK12" },
  { key: "NM_022060.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "63874", geneDisplay: "ABHD4" },
  { key: "NM_022061.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "63875", geneDisplay: "MRPL17" },
  { key: "NM_022063.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "63877", geneDisplay: "FAM204A" },
  { key: "NM_022064.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "63891", geneDisplay: "RNF123" },
  { key: "NM_022065.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "63892", geneDisplay: "THADA" },
  { key: "NM_022066.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "63893", geneDisplay: "UBE2O" },
  { key: "NM_022070.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "63897", geneDisplay: "HEATR6" },
  { key: "NM_022071.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "63898", geneDisplay: "SH2D4A" },
  { key: "NM_022072.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "63899", geneDisplay: "NSUN3" },
  { key: "NM_022073.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "112399", geneDisplay: "EGLN3" },
  { key: "NM_022075.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29956", geneDisplay: "CERS2" },
  { key: "NM_022076.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "63904", geneDisplay: "DUSP21" },
  { key: "NM_022078.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63906", geneDisplay: "GPATCH3" },
  { key: "NM_022080.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63908", geneDisplay: "NAPB" },
  { key: "NM_022081.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "89781", geneDisplay: "HPS4" },
  { key: "NM_022082.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63910", geneDisplay: "SLC17A9" },
  { key: "NM_022087.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "63917", geneDisplay: "GALNT11" },
  { key: "NM_022089.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23400", geneDisplay: "ATP13A2" },
  { key: "NM_022090.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "63920", geneDisplay: "ZBED8" },
  { key: "NM_022092.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "63922", geneDisplay: "CHTF18" },
  { key: "NM_022093.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63923", geneDisplay: "TNN" },
  { key: "NM_022095.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63925", geneDisplay: "ZNF335" },
  { key: "NM_022096.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63926", geneDisplay: "ANKEF1" },
  { key: "NM_022097.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "63928", geneDisplay: "CHP2" },
  { key: "NM_022098.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "63929", geneDisplay: "XPNPEP3" },
  { key: "NM_022100.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63931", geneDisplay: "MRPS14" },
  { key: "NM_022101.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "63932", geneDisplay: "STEEP1" },
  { key: "NM_022104.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63935", geneDisplay: "PCIF1" },
  { key: "NM_022106.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63939", geneDisplay: "FAM217B" },
  { key: "NM_022110.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63943", geneDisplay: "FKBPL" },
  { key: "NM_022111.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63967", geneDisplay: "CLSPN" },
  { key: "NM_022112.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "63970", geneDisplay: "TP53AIP1" },
  { key: "NM_022113.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63971", geneDisplay: "KIF13A" },
  { key: "NM_022114.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63976", geneDisplay: "PRDM16" },
  { key: "NM_022117.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64061", geneDisplay: "TSPYL2" },
  { key: "NM_022119.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64063", geneDisplay: "PRSS22" },
  { key: "NM_022120.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64064", geneDisplay: "OXCT2" },
  { key: "NM_022121.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64065", geneDisplay: "PERP" },
  { key: "NM_022122.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64066", geneDisplay: "MMP27" },
  { key: "NM_022124.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64072", geneDisplay: "CDH23" },
  { key: "NM_022126.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64077", geneDisplay: "LHPP" },
  { key: "NM_022128.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64080", geneDisplay: "RBKS" },
  { key: "NM_022129.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64081", geneDisplay: "PBLD" },
  { key: "NM_022130.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64083", geneDisplay: "GOLPH3" },
  { key: "NM_022131.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64084", geneDisplay: "CLSTN2" },
  { key: "NM_022132.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64087", geneDisplay: "MCCC2" },
  { key: "NM_022134.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64090", geneDisplay: "GAL3ST2" },
  { key: "NM_022136.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "64092", geneDisplay: "SAMSN1" },
  { key: "NM_022139.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64096", geneDisplay: "GFRA4" },
  { key: "NM_022140.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64097", geneDisplay: "EPB41L4A" },
  { key: "NM_022141.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "64098", geneDisplay: "PARVG" },
  { key: "NM_022142.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64100", geneDisplay: "ELSPBP1" },
  { key: "NM_022143.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64101", geneDisplay: "LRRC4" },
  { key: "NM_022144.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64102", geneDisplay: "TNMD" },
  { key: "NM_022145.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64105", geneDisplay: "CENPK" },
  { key: "NM_022146.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64106", geneDisplay: "NPFFR1" },
  { key: "NM_022147.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64108", geneDisplay: "RTP4" },
  { key: "NM_022149.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64110", geneDisplay: "MAGEF1" },
  { key: "NM_022150.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64111", geneDisplay: "NPVF" },
  { key: "NM_022151.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64112", geneDisplay: "MOAP1" },
  { key: "NM_022152.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64114", geneDisplay: "TMBIM1" },
  { key: "NM_022153.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64115", geneDisplay: "VSIR" },
  { key: "NM_022156.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64118", geneDisplay: "DUS1L" },
  { key: "NM_022157.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64121", geneDisplay: "RRAGC" },
  { key: "NM_022158.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64122", geneDisplay: "FN3K" },
  { key: "NM_022159.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64123", geneDisplay: "ADGRL4" },
  { key: "NM_022160.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "63951", geneDisplay: "DMRTA1" },
  { key: "NM_022163.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26589", geneDisplay: "MRPL46" },
  { key: "NM_022164.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64129", geneDisplay: "TINAGL1" },
  { key: "NM_022165.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64130", geneDisplay: "LIN7B" },
  { key: "NM_022166.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64131", geneDisplay: "XYLT1" },
  { key: "NM_022167.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64132", geneDisplay: "XYLT2" },
  { key: "NM_022168.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64135", geneDisplay: "IFIH1" },
  { key: "NM_022169.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64137", geneDisplay: "ABCG4" },
  { key: "NM_022170.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7458", geneDisplay: "EIF4H" },
  { key: "NM_022171.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6988", geneDisplay: "TCTA" },
  { key: "NM_022173.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7072", geneDisplay: "TIA1" },
  { key: "NM_022336.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10913", geneDisplay: "EDAR" },
  { key: "NM_022337.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23682", geneDisplay: "RAB38" },
  { key: "NM_022338.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "53838", geneDisplay: "C11orf24" },
  { key: "NM_022340.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64145", geneDisplay: "RBSN" },
  { key: "NM_022341.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64146", geneDisplay: "PDF" },
  { key: "NM_022343.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "152007", geneDisplay: "GLIPR2" },
  { key: "NM_022344.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64149", geneDisplay: "C17orf75" },
  { key: "NM_022346.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64151", geneDisplay: "NCAPG" },
  { key: "NM_022349.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64231", geneDisplay: "MS4A6A" },
  { key: "NM_022350.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64167", geneDisplay: "ERAP2" },
  { key: "NM_022351.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64168", geneDisplay: "NECAB1" },
  { key: "NM_022353.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64172", geneDisplay: "OSGEPL1" },
  { key: "NM_022355.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64174", geneDisplay: "DPEP2" },
  { key: "NM_022356.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64175", geneDisplay: "P3H1" },
  { key: "NM_022358.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "60598", geneDisplay: "KCNK15" },
  { key: "NM_022360.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64184", geneDisplay: "EDDM3B" },
  { key: "NM_022361.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64208", geneDisplay: "POPDC3" },
  { key: "NM_022362.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64210", geneDisplay: "MMS19" },
  { key: "NM_022363.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64211", geneDisplay: "LHX5" },
  { key: "NM_022365.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64215", geneDisplay: "DNAJC1" },
  { key: "NM_022366.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64216", geneDisplay: "TFB2M" },
  { key: "NM_022367.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64218", geneDisplay: "SEMA4A" },
  { key: "NM_022369.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64220", geneDisplay: "STRA6" },
  { key: "NM_022370.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64221", geneDisplay: "ROBO3" },
  { key: "NM_022371.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64222", geneDisplay: "TOR3A" },
  { key: "NM_022372.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64223", geneDisplay: "MLST8" },
  { key: "NM_022373.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64224", geneDisplay: "HERPUD2" },
  { key: "NM_022436.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64240", geneDisplay: "ABCG5" },
  { key: "NM_022437.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64241", geneDisplay: "ABCG8" },
  { key: "NM_022444.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6561", geneDisplay: "SLC13A1" },
  { key: "NM_022445.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27010", geneDisplay: "TPK1" },
  { key: "NM_022449.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64284", geneDisplay: "RAB17" },
  { key: "NM_022450.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64285", geneDisplay: "RHBDF1" },
  { key: "NM_022451.11", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64318", geneDisplay: "NOC3L" },
  { key: "NM_022453.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64320", geneDisplay: "RNF25" },
  { key: "NM_022454.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64321", geneDisplay: "SOX17" },
  { key: "NM_022455.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64324", geneDisplay: "NSD1" },
  { key: "NM_022456.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "117177", geneDisplay: "RAB3IP" },
  { key: "NM_022457.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64326", geneDisplay: "COP1" },
  { key: "NM_022458.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64327", geneDisplay: "LMBR1" },
  { key: "NM_022459.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "64328", geneDisplay: "XPO4" },
  { key: "NM_022460.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64342", geneDisplay: "HS1BP3" },
  { key: "NM_022461.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64343", geneDisplay: "AZI2" },
  { key: "NM_022463.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64359", geneDisplay: "NXN" },
  { key: "NM_022464.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64374", geneDisplay: "SIL1" },
  { key: "NM_022465.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64375", geneDisplay: "IKZF4" },
  { key: "NM_022468.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64386", geneDisplay: "MMP25" },
  { key: "NM_022469.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64388", geneDisplay: "GREM2" },
  { key: "NM_022470.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64393", geneDisplay: "ZMAT3" },
  { key: "NM_022474.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64398", geneDisplay: "PALS1" },
  { key: "NM_022475.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64399", geneDisplay: "HHIP" },
  { key: "NM_022476.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64400", geneDisplay: "AKTIP" },
  { key: "NM_022479.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64409", geneDisplay: "GALNT17" },
  { key: "NM_022480.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64410", geneDisplay: "KLHL25" },
  { key: "NM_022481.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64411", geneDisplay: "ARAP3" },
  { key: "NM_022482.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64412", geneDisplay: "GZF1" },
  { key: "NM_022483.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64417", geneDisplay: "TMEM267" },
  { key: "NM_022484.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64418", geneDisplay: "TMEM168" },
  { key: "NM_022486.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64420", geneDisplay: "SUSD1" },
  { key: "NM_022488.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64422", geneDisplay: "ATG3" },
  { key: "NM_022489.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64423", geneDisplay: "INF2" },
  { key: "NM_022490.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64425", geneDisplay: "POLR1E" },
  { key: "NM_022491.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64426", geneDisplay: "SUDS3" },
  { key: "NM_022492.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64427", geneDisplay: "TTC31" },
  { key: "NM_022493.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64428", geneDisplay: "CIAO3" },
  { key: "NM_022494.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64429", geneDisplay: "ZDHHC6" },
  { key: "NM_022496.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64431", geneDisplay: "ACTR6" },
  { key: "NM_022497.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64432", geneDisplay: "MRPS25" },
  { key: "NM_022551.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6222", geneDisplay: "RPS18" },
  { key: "NM_022552.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1788", geneDisplay: "DNMT3A" },
  { key: "NM_022553.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6293", geneDisplay: "VPS52" },
  { key: "NM_022566.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "59274", geneDisplay: "TLNRD1" },
  { key: "NM_022568.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64577", geneDisplay: "ALDH8A1" },
  { key: "NM_022569.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64579", geneDisplay: "NDST4" },
  { key: "NM_022571.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64582", geneDisplay: "GPR135" },
  { key: "NM_022573.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "64591", geneDisplay: "TSPY2" },
  { key: "NM_022575.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64601", geneDisplay: "VPS16" },
  { key: "NM_022579.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1444", geneDisplay: "CSHL1" },
  { key: "NM_022658.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3224", geneDisplay: "HOXC8" },
  { key: "NM_022659.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64641", geneDisplay: "EBF2" },
  { key: "NM_022661.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64663", geneDisplay: "SPANXC" },
  { key: "NM_022662.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64682", geneDisplay: "ANAPC1" },
  { key: "NM_022716.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5396", geneDisplay: "PRRX1" },
  { key: "NM_022717.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11066", geneDisplay: "SNRNP35" },
  { key: "NM_022719.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8220", geneDisplay: "ESS2" },
  { key: "NM_022720.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "54487", geneDisplay: "DGCR8" },
  { key: "NM_022725.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2188", geneDisplay: "FANCF" },
  { key: "NM_022726.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6785", geneDisplay: "ELOVL4" },
  { key: "NM_022727.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27037", geneDisplay: "TRMT2A" },
  { key: "NM_022728.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "63974", geneDisplay: "NEUROD6" },
  { key: "NM_022730.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64708", geneDisplay: "COPS7B" },
  { key: "NM_022731.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64710", geneDisplay: "NUCKS1" },
  { key: "NM_022733.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64744", geneDisplay: "SMAP2" },
  { key: "NM_022734.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64745", geneDisplay: "METTL17" },
  { key: "NM_022735.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64746", geneDisplay: "ACBD3" },
  { key: "NM_022736.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64747", geneDisplay: "MFSD1" },
  { key: "NM_022739.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64750", geneDisplay: "SMURF2" },
  { key: "NM_022740.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "28996", geneDisplay: "HIPK2" },
  { key: "NM_022742.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64753", geneDisplay: "CCDC136" },
  { key: "NM_022744.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64755", geneDisplay: "RUSF1" },
  { key: "NM_022746.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64757", geneDisplay: "MTARC1" },
  { key: "NM_022748.12", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64759", geneDisplay: "TNS3" },
  { key: "NM_022749.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64760", geneDisplay: "FHIP2B" },
  { key: "NM_022750.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64761", geneDisplay: "PARP12" },
  { key: "NM_022752.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64763", geneDisplay: "ZNF574" },
  { key: "NM_022753.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64766", geneDisplay: "S100PBP" },
  { key: "NM_022754.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "94081", geneDisplay: "SFXN1" },
  { key: "NM_022755.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64768", geneDisplay: "IPPK" },
  { key: "NM_022760.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64773", geneDisplay: "PCED1A" },
  { key: "NM_022761.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64776", geneDisplay: "C11orf1" },
  { key: "NM_022762.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64777", geneDisplay: "RMND5B" },
  { key: "NM_022763.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64778", geneDisplay: "FNDC3B" },
  { key: "NM_022765.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64780", geneDisplay: "MICAL1" },
  { key: "NM_022766.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "64781", geneDisplay: "CERK" },
  { key: "NM_022767.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64782", geneDisplay: "AEN" },
  { key: "NM_022768.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64783", geneDisplay: "RBM15" },
  { key: "NM_022769.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64784", geneDisplay: "CRTC3" },
  { key: "NM_022770.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64785", geneDisplay: "GINS3" },
  { key: "NM_022772.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64787", geneDisplay: "EPS8L2" },
  { key: "NM_022773.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64788", geneDisplay: "LMF1" },
  { key: "NM_022776.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "114885", geneDisplay: "OSBPL11" },
  { key: "NM_022777.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64792", geneDisplay: "IFT22" },
  { key: "NM_022779.9", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64794", geneDisplay: "DDX31" },
  { key: "NM_022780.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64795", geneDisplay: "RMND5A" },
  { key: "NM_022781.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "152006", geneDisplay: "RNF38" },
  { key: "NM_022782.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10198", geneDisplay: "MPHOSPH9" },
  { key: "NM_022783.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64798", geneDisplay: "DEPTOR" },
  { key: "NM_022785.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "64800", geneDisplay: "EFCAB6" },
  { key: "NM_022786.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64801", geneDisplay: "ARV1" },
  { key: "NM_022787.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64802", geneDisplay: "NMNAT1" },
  { key: "NM_022788.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64805", geneDisplay: "P2RY12" },
  { key: "NM_022802.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1488", geneDisplay: "CTBP2" },
  { key: "NM_022817.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8864", geneDisplay: "PER2" },
  { key: "NM_022818.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81631", geneDisplay: "MAP1LC3B" },
  { key: "NM_022819.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64600", geneDisplay: "PLA2G2F" },
  { key: "NM_022821.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64834", geneDisplay: "ELOVL1" },
  { key: "NM_022823.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64838", geneDisplay: "FNDC4" },
  { key: "NM_022827.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64847", geneDisplay: "SPATA20" },
  { key: "NM_022828.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64848", geneDisplay: "YTHDC2" },
  { key: "NM_022829.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64849", geneDisplay: "SLC13A3" },
  { key: "NM_022830.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64852", geneDisplay: "TUT1" },
  { key: "NM_022831.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64853", geneDisplay: "AIDA" },
  { key: "NM_022832.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64854", geneDisplay: "USP46" },
  { key: "NM_022833.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64855", geneDisplay: "NIBAN2" },
  { key: "NM_022834.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64856", geneDisplay: "VWA1" },
  { key: "NM_022835.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64857", geneDisplay: "PLEKHG2" },
  { key: "NM_022836.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64858", geneDisplay: "DCLRE1B" },
  { key: "NM_022839.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64963", geneDisplay: "MRPS11" },
  { key: "NM_022840.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "64863", geneDisplay: "METTL4" },
  { key: "NM_022841.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64864", geneDisplay: "RFX7" },
  { key: "NM_022842.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64866", geneDisplay: "CDCP1" },
  { key: "NM_022843.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "64881", geneDisplay: "PCDH20" },
  { key: "NM_022845.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "865", geneDisplay: "CBFB" },
  { key: "NM_022893.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "53335", geneDisplay: "BCL11A" },
  { key: "NM_022894.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64895", geneDisplay: "PAPOLG" },
  { key: "NM_022895.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64897", geneDisplay: "C12orf43" },
  { key: "NM_022896.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64900", geneDisplay: "LPIN3" },
  { key: "NM_022897.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64901", geneDisplay: "RANBP17" },
  { key: "NM_022899.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "93973", geneDisplay: "ACTR8" },
  { key: "NM_022900.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64921", geneDisplay: "CASD1" },
  { key: "NM_022901.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64922", geneDisplay: "LRRC19" },
  { key: "NM_022902.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64924", geneDisplay: "SLC30A5" },
  { key: "NM_022903.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64925", geneDisplay: "CCDC71" },
  { key: "NM_022904.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64926", geneDisplay: "RASAL3" },
  { key: "NM_022909.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64946", geneDisplay: "CENPH" },
  { key: "NM_022911.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "65010", geneDisplay: "SLC26A6" },
  { key: "NM_022913.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "65056", geneDisplay: "GPBP1" },
  { key: "NM_022915.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65080", geneDisplay: "MRPL44" },
  { key: "NM_022916.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65082", geneDisplay: "VPS33A" },
  { key: "NM_022917.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "65083", geneDisplay: "NOL6" },
  { key: "NM_022918.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "65084", geneDisplay: "TMEM135" },
  { key: "NM_022968.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8293", geneDisplay: "SERF1A" },
  { key: "NM_022978.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "728492", geneDisplay: "SERF1B" },
  { key: "NM_023002.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "404037", geneDisplay: "HAPLN4" },
  { key: "NM_023003.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "53346", geneDisplay: "TM6SF1" },
  { key: "NM_023004.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "65078", geneDisplay: "RTN4R" },
  { key: "NM_023007.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65094", geneDisplay: "JMJD4" },
  { key: "NM_023008.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "65095", geneDisplay: "KRI1" },
  { key: "NM_023009.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65108", geneDisplay: "MARCKSL1" },
  { key: "NM_023011.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "65110", geneDisplay: "UPF3A" },
  { key: "NM_023012.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65117", geneDisplay: "RSRC2" },
  { key: "NM_023013.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65121", geneDisplay: "PRAMEF1" },
  { key: "NM_023014.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65122", geneDisplay: "PRAMEF2" },
  { key: "NM_023015.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65123", geneDisplay: "INTS3" },
  { key: "NM_023016.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65124", geneDisplay: "SOWAHC" },
  { key: "NM_023018.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65220", geneDisplay: "NADK" },
  { key: "NM_023034.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "54904", geneDisplay: "NSD3" },
  { key: "NM_023036.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64446", geneDisplay: "DNAI2" },
  { key: "NM_023037.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10129", geneDisplay: "FRY" },
  { key: "NM_023039.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57763", geneDisplay: "ANKRA2" },
  { key: "NM_023067.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "668", geneDisplay: "FOXL2" },
  { key: "NM_023068.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6614", geneDisplay: "SIGLEC1" },
  { key: "NM_023070.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65243", geneDisplay: "ZFP69B" },
  { key: "NM_023071.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65244", geneDisplay: "SPATS2" },
  { key: "NM_023074.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "65251", geneDisplay: "ZNF649" },
  { key: "NM_023075.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "65258", geneDisplay: "MPPE1" },
  { key: "NM_023077.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65260", geneDisplay: "COA7" },
  { key: "NM_023078.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "65263", geneDisplay: "PYCR3" },
  { key: "NM_023079.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "65264", geneDisplay: "UBE2Z" },
  { key: "NM_023080.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "65265", geneDisplay: "C8orf33" },
  { key: "NM_023083.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11132", geneDisplay: "CAPN10" },
  { key: "NM_023110.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2260", geneDisplay: "FGFR1" },
  { key: "NM_023112.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "78990", geneDisplay: "OTUB2" },
  { key: "NM_023915.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "53836", geneDisplay: "GPR87" },
  { key: "NM_023917.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50835", geneDisplay: "TAS2R9" },
  { key: "NM_023918.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50836", geneDisplay: "TAS2R8" },
  { key: "NM_023919.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50837", geneDisplay: "TAS2R7" },
  { key: "NM_023920.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50838", geneDisplay: "TAS2R13" },
  { key: "NM_023921.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50839", geneDisplay: "TAS2R10" },
  { key: "NM_023922.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50840", geneDisplay: "TAS2R14" },
  { key: "NM_023924.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "65980", geneDisplay: "BRD9" },
  { key: "NM_023927.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "65983", geneDisplay: "GRAMD2B" },
  { key: "NM_023928.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65985", geneDisplay: "AACS" },
  { key: "NM_023930.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "65987", geneDisplay: "KCTD14" },
  { key: "NM_023932.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "65989", geneDisplay: "DLK2" },
  { key: "NM_023933.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "65990", geneDisplay: "ANTKMT" },
  { key: "NM_023934.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "65991", geneDisplay: "FUNDC2" },
  { key: "NM_023935.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "65992", geneDisplay: "DDRGK1" },
  { key: "NM_023936.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "65993", geneDisplay: "MRPS34" },
  { key: "NM_023937.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64981", geneDisplay: "MRPL34" },
  { key: "NM_023938.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79098", geneDisplay: "C1orf116" },
  { key: "NM_023940.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "65997", geneDisplay: "RASL11B" },
  { key: "NM_023943.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "66000", geneDisplay: "TMEM108" },
  { key: "NM_023944.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "66002", geneDisplay: "CYP4F12" },
  { key: "NM_023945.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64232", geneDisplay: "MS4A5" },
  { key: "NM_023947.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "66005", geneDisplay: "CHID1" },
  { key: "NM_023948.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64598", geneDisplay: "MOSPD3" },
  { key: "NM_024006.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79001", geneDisplay: "VKORC1" },
  { key: "NM_024007.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1879", geneDisplay: "EBF1" },
  { key: "NM_024009.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2707", geneDisplay: "GJB3" },
  { key: "NM_024011.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728642", geneDisplay: "CDK11A" },
  { key: "NM_024012.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3361", geneDisplay: "HTR5A" },
  { key: "NM_024013.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3439", geneDisplay: "IFNA1" },
  { key: "NM_024014.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3203", geneDisplay: "HOXA6" },
  { key: "NM_024015.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3214", geneDisplay: "HOXB4" },
  { key: "NM_024016.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3218", geneDisplay: "HOXB8" },
  { key: "NM_024017.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3219", geneDisplay: "HOXB9" },
  { key: "NM_024019.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "63973", geneDisplay: "NEUROG2" },
  { key: "NM_024025.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "78986", geneDisplay: "DUSP26" },
  { key: "NM_024026.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "78988", geneDisplay: "MRPL57" },
  { key: "NM_024027.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "78989", geneDisplay: "COLEC11" },
  { key: "NM_024028.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "78991", geneDisplay: "PCYOX1L" },
  { key: "NM_024031.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "78994", geneDisplay: "PRR14" },
  { key: "NM_024033.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "78996", geneDisplay: "CYREN" },
  { key: "NM_024034.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "78997", geneDisplay: "GDAP1L1" },
  { key: "NM_024036.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "78999", geneDisplay: "LRFN4" },
  { key: "NM_024037.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79000", geneDisplay: "AUNIP" },
  { key: "NM_024038.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79002", geneDisplay: "TRIR" },
  { key: "NM_024040.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79004", geneDisplay: "CUEDC2" },
  { key: "NM_024041.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79005", geneDisplay: "SCNM1" },
  { key: "NM_024042.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79006", geneDisplay: "METRN" },
  { key: "NM_024044.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79008", geneDisplay: "SLX1B" },
  { key: "NM_024045.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79009", geneDisplay: "DDX50" },
  { key: "NM_024046.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79012", geneDisplay: "CAMKV" },
  { key: "NM_024047.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "53343", geneDisplay: "NUDT9" },
  { key: "NM_024050.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79016", geneDisplay: "DDA1" },
  { key: "NM_024051.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79017", geneDisplay: "GGCT" },
  { key: "NM_024052.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79018", geneDisplay: "GID4" },
  { key: "NM_024053.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79019", geneDisplay: "CENPM" },
  { key: "NM_024057.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79023", geneDisplay: "NUP37" },
  { key: "NM_024058.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79024", geneDisplay: "SMIM2" },
  { key: "NM_024063.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79029", geneDisplay: "SPATA5L1" },
  { key: "NM_024065.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79031", geneDisplay: "PDCL3" },
  { key: "NM_024066.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79033", geneDisplay: "ERI3" },
  { key: "NM_024067.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79034", geneDisplay: "INTS15" },
  { key: "NM_024068.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79035", geneDisplay: "NABP2" },
  { key: "NM_024069.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79036", geneDisplay: "KXD1" },
  { key: "NM_024071.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79038", geneDisplay: "ZFYVE21" },
  { key: "NM_024072.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79039", geneDisplay: "DDX54" },
  { key: "NM_024074.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79041", geneDisplay: "TMEM38A" },
  { key: "NM_024077.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79048", geneDisplay: "SECISBP2" },
  { key: "NM_024078.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79050", geneDisplay: "NOC4L" },
  { key: "NM_024079.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79053", geneDisplay: "ALG8" },
  { key: "NM_024080.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79054", geneDisplay: "TRPM8" },
  { key: "NM_024081.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79056", geneDisplay: "PRRG4" },
  { key: "NM_024083.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79058", geneDisplay: "ASPSCR1" },
  { key: "NM_024086.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79066", geneDisplay: "METTL16" },
  { key: "NM_024089.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79070", geneDisplay: "POGLUT2" },
  { key: "NM_024090.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79071", geneDisplay: "ELOVL6" },
  { key: "NM_024091.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79072", geneDisplay: "FASTKD3" },
  { key: "NM_024092.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79073", geneDisplay: "TMEM109" },
  { key: "NM_024093.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79074", geneDisplay: "C2orf49" },
  { key: "NM_024094.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79075", geneDisplay: "DSCC1" },
  { key: "NM_024095.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "140461", geneDisplay: "ASB8" },
  { key: "NM_024096.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79077", geneDisplay: "DCTPP1" },
  { key: "NM_024097.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79078", geneDisplay: "C1orf50" },
  { key: "NM_024098.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79080", geneDisplay: "CCDC86" },
  { key: "NM_024099.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79081", geneDisplay: "LBHD1" },
  { key: "NM_024100.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57418", geneDisplay: "WDR18" },
  { key: "NM_024101.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79083", geneDisplay: "MLPH" },
  { key: "NM_024102.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79084", geneDisplay: "WDR77" },
  { key: "NM_024103.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79085", geneDisplay: "SLC25A23" },
  { key: "NM_024104.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79086", geneDisplay: "SMIM7" },
  { key: "NM_024105.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79087", geneDisplay: "ALG12" },
  { key: "NM_024106.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79088", geneDisplay: "ZNF426" },
  { key: "NM_024109.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79091", geneDisplay: "METTL22" },
  { key: "NM_024111.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79094", geneDisplay: "CHAC1" },
  { key: "NM_024112.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79095", geneDisplay: "BBLN" },
  { key: "NM_024113.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79096", geneDisplay: "CSTPP1" },
  { key: "NM_024114.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79097", geneDisplay: "TRIM48" },
  { key: "NM_024116.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79101", geneDisplay: "TAF1D" },
  { key: "NM_024119.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79132", geneDisplay: "DHX58" },
  { key: "NM_024120.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79133", geneDisplay: "NDUFAF5" },
  { key: "NM_024121.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79134", geneDisplay: "TMEM185B" },
  { key: "NM_024122.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79135", geneDisplay: "APOO" },
  { key: "NM_024164.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64499", geneDisplay: "TPSB2" },
  { key: "NM_024165.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5252", geneDisplay: "PHF1" },
  { key: "NM_024293.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79137", geneDisplay: "RETREG2" },
  { key: "NM_024294.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64771", geneDisplay: "ILRUN" },
  { key: "NM_024295.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79139", geneDisplay: "DERL1" },
  { key: "NM_024296.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79140", geneDisplay: "CCDC28B" },
  { key: "NM_024297.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79142", geneDisplay: "PHF23" },
  { key: "NM_024298.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79143", geneDisplay: "MBOAT7" },
  { key: "NM_024299.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79144", geneDisplay: "PPDPF" },
  { key: "NM_024301.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79147", geneDisplay: "FKRP" },
  { key: "NM_024302.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79148", geneDisplay: "MMP28" },
  { key: "NM_024306.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79152", geneDisplay: "FA2H" },
  { key: "NM_024307.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79153", geneDisplay: "GDPD3" },
  { key: "NM_024308.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79154", geneDisplay: "DHRS11" },
  { key: "NM_024309.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79155", geneDisplay: "TNIP2" },
  { key: "NM_024310.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79156", geneDisplay: "PLEKHF1" },
  { key: "NM_024312.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79158", geneDisplay: "GNPTAB" },
  { key: "NM_024313.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79159", geneDisplay: "NOL12" },
  { key: "NM_024315.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79161", geneDisplay: "TMEM243" },
  { key: "NM_024316.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79165", geneDisplay: "LENG1" },
  { key: "NM_024319.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79169", geneDisplay: "C1orf35" },
  { key: "NM_024320.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79170", geneDisplay: "PRR15L" },
  { key: "NM_024321.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79171", geneDisplay: "RBM42" },
  { key: "NM_024324.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79174", geneDisplay: "CRELD2" },
  { key: "NM_024325.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79175", geneDisplay: "ZNF343" },
  { key: "NM_024326.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79176", geneDisplay: "FBXL15" },
  { key: "NM_024328.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79178", geneDisplay: "THTPA" },
  { key: "NM_024329.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79180", geneDisplay: "EFHD2" },
  { key: "NM_024330.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11000", geneDisplay: "SLC27A3" },
  { key: "NM_024333.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79187", geneDisplay: "FSD1" },
  { key: "NM_024334.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79188", geneDisplay: "TMEM43" },
  { key: "NM_024335.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79190", geneDisplay: "IRX6" },
  { key: "NM_024336.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79191", geneDisplay: "IRX3" },
  { key: "NM_024337.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79192", geneDisplay: "IRX1" },
  { key: "NM_024339.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79228", geneDisplay: "THOC6" },
  { key: "NM_024341.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79230", geneDisplay: "ZNF557" },
  { key: "NM_024345.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79269", geneDisplay: "DCAF10" },
  { key: "NM_024407.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374291", geneDisplay: "NDUFS7" },
  { key: "NM_024408.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4853", geneDisplay: "NOTCH2" },
  { key: "NM_024409.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4880", geneDisplay: "NPPC" },
  { key: "NM_024410.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4956", geneDisplay: "ODF1" },
  { key: "NM_024411.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5173", geneDisplay: "PDYN" },
  { key: "NM_024415.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54514", geneDisplay: "DDX4" },
  { key: "NM_024417.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2232", geneDisplay: "FDXR" },
  { key: "NM_024419.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9489", geneDisplay: "PGS1" },
  { key: "NM_024420.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5321", geneDisplay: "PLA2G4A" },
  { key: "NM_024421.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1823", geneDisplay: "DSC1" },
  { key: "NM_024422.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1824", geneDisplay: "DSC2" },
  { key: "NM_024426.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7490", geneDisplay: "WT1" },
  { key: "NM_024430.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9050", geneDisplay: "PSTPIP2" },
  { key: "NM_024490.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57194", geneDisplay: "ATP10A" },
  { key: "NM_024491.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80321", geneDisplay: "CEP70" },
  { key: "NM_024493.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80317", geneDisplay: "ZKSCAN3" },
  { key: "NM_024494.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7482", geneDisplay: "WNT2B" },
  { key: "NM_024496.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64207", geneDisplay: "IRF2BPL" },
  { key: "NM_024501.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3231", geneDisplay: "HOXD1" },
  { key: "NM_024503.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "59269", geneDisplay: "HIVEP3" },
  { key: "NM_024504.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "63978", geneDisplay: "PRDM14" },
  { key: "NM_024505.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79400", geneDisplay: "NOX5" },
  { key: "NM_024508.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79413", geneDisplay: "ZBED2" },
  { key: "NM_024509.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79414", geneDisplay: "LRFN3" },
  { key: "NM_024512.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79442", geneDisplay: "LRRC2" },
  { key: "NM_024513.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79443", geneDisplay: "FYCO1" },
  { key: "NM_024514.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120227", geneDisplay: "CYP2R1" },
  { key: "NM_024516.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79447", geneDisplay: "PAGR1" },
  { key: "NM_024518.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79465", geneDisplay: "ULBP3" },
  { key: "NM_024519.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79567", geneDisplay: "RIPOR1" },
  { key: "NM_024522.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79570", geneDisplay: "NKAIN1" },
  { key: "NM_024523.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79571", geneDisplay: "GCC1" },
  { key: "NM_024525.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79573", geneDisplay: "TTC13" },
  { key: "NM_024527.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79575", geneDisplay: "ABHD8" },
  { key: "NM_024528.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79576", geneDisplay: "NKAP" },
  { key: "NM_024529.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79577", geneDisplay: "CDC73" },
  { key: "NM_024532.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79582", geneDisplay: "SPAG16" },
  { key: "NM_024533.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23563", geneDisplay: "CHST5" },
  { key: "NM_024535.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79585", geneDisplay: "CORO7" },
  { key: "NM_024536.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79586", geneDisplay: "CHPF" },
  { key: "NM_024537.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79587", geneDisplay: "CARS2" },
  { key: "NM_024541.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79591", geneDisplay: "ARMH3" },
  { key: "NM_024544.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79594", geneDisplay: "MUL1" },
  { key: "NM_024546.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79596", geneDisplay: "OBI1" },
  { key: "NM_024548.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79598", geneDisplay: "CEP97" },
  { key: "NM_024551.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79602", geneDisplay: "ADIPOR2" },
  { key: "NM_024552.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79603", geneDisplay: "CERS4" },
  { key: "NM_024556.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79607", geneDisplay: "FAM118B" },
  { key: "NM_024558.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79609", geneDisplay: "VCPKMT" },
  { key: "NM_024560.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79611", geneDisplay: "ACSS3" },
  { key: "NM_024561.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79612", geneDisplay: "NAA16" },
  { key: "NM_024562.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79613", geneDisplay: "TANGO6" },
  { key: "NM_024570.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79621", geneDisplay: "RNASEH2B" },
  { key: "NM_024571.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79622", geneDisplay: "SNRNP25" },
  { key: "NM_024572.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79623", geneDisplay: "GALNT14" },
  { key: "NM_024573.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79624", geneDisplay: "ARMT1" },
  { key: "NM_024574.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79625", geneDisplay: "NDNF" },
  { key: "NM_024575.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79626", geneDisplay: "TNFAIP8L2" },
  { key: "NM_024576.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79627", geneDisplay: "OGFRL1" },
  { key: "NM_024577.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79628", geneDisplay: "SH3TC2" },
  { key: "NM_024578.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79629", geneDisplay: "OCEL1" },
  { key: "NM_024579.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79630", geneDisplay: "C1orf54" },
  { key: "NM_024580.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79631", geneDisplay: "EFL1" },
  { key: "NM_024581.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79632", geneDisplay: "FAM184A" },
  { key: "NM_024583.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79634", geneDisplay: "SCRN3" },
  { key: "NM_024584.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79635", geneDisplay: "CCDC121" },
  { key: "NM_024585.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79637", geneDisplay: "ARMC7" },
  { key: "NM_024586.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114883", geneDisplay: "OSBPL9" },
  { key: "NM_024587.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79639", geneDisplay: "TMEM53" },
  { key: "NM_024589.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79641", geneDisplay: "ROGDI" },
  { key: "NM_024590.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79642", geneDisplay: "ARSJ" },
  { key: "NM_024591.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79643", geneDisplay: "CHMP6" },
  { key: "NM_024592.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79644", geneDisplay: "SRD5A3" },
  { key: "NM_024593.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79645", geneDisplay: "CLXN" },
  { key: "NM_024594.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79646", geneDisplay: "PANK3" },
  { key: "NM_024595.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79647", geneDisplay: "AKIRIN1" },
  { key: "NM_024596.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79648", geneDisplay: "MCPH1" },
  { key: "NM_024597.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79649", geneDisplay: "MAP7D3" },
  { key: "NM_024598.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79650", geneDisplay: "USB1" },
  { key: "NM_024600.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79652", geneDisplay: "TMEM204" },
  { key: "NM_024602.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79654", geneDisplay: "HECTD3" },
  { key: "NM_024603.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79656", geneDisplay: "BEND5" },
  { key: "NM_024604.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79657", geneDisplay: "RPAP3" },
  { key: "NM_024605.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79658", geneDisplay: "ARHGAP10" },
  { key: "NM_024607.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79660", geneDisplay: "PPP1R3B" },
  { key: "NM_024608.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79661", geneDisplay: "NEIL1" },
  { key: "NM_024610.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79663", geneDisplay: "HSPBAP1" },
  { key: "NM_024611.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79664", geneDisplay: "ICE2" },
  { key: "NM_024612.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79665", geneDisplay: "DHX40" },
  { key: "NM_024613.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79666", geneDisplay: "PLEKHF2" },
  { key: "NM_024615.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79668", geneDisplay: "PARP8" },
  { key: "NM_024616.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79669", geneDisplay: "C3orf52" },
  { key: "NM_024617.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79670", geneDisplay: "TUT7" },
  { key: "NM_024619.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79672", geneDisplay: "FN3KRP" },
  { key: "NM_024620.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79673", geneDisplay: "ZNF329" },
  { key: "NM_024622.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79675", geneDisplay: "FASTKD1" },
  { key: "NM_024626.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79679", geneDisplay: "VTCN1" },
  { key: "NM_024627.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79680", geneDisplay: "RTL10" },
  { key: "NM_024628.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84561", geneDisplay: "SLC12A8" },
  { key: "NM_024629.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79682", geneDisplay: "CENPU" },
  { key: "NM_024630.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79683", geneDisplay: "ZDHHC14" },
  { key: "NM_024632.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79685", geneDisplay: "SAP30L" },
  { key: "NM_024635.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "60560", geneDisplay: "NAA35" },
  { key: "NM_024636.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79689", geneDisplay: "STEAP4" },
  { key: "NM_024637.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79690", geneDisplay: "GAL3ST4" },
  { key: "NM_024638.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79691", geneDisplay: "QTRT2" },
  { key: "NM_024639.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79692", geneDisplay: "ZNF322" },
  { key: "NM_024640.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79693", geneDisplay: "YRDC" },
  { key: "NM_024641.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79694", geneDisplay: "MANEA" },
  { key: "NM_024642.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79695", geneDisplay: "GALNT12" },
  { key: "NM_024643.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79696", geneDisplay: "ZC2HC1C" },
  { key: "NM_024644.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79697", geneDisplay: "RIOX1" },
  { key: "NM_024645.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79698", geneDisplay: "ZMAT4" },
  { key: "NM_024646.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79699", geneDisplay: "ZYG11B" },
  { key: "NM_024648.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79701", geneDisplay: "OGFOD3" },
  { key: "NM_024649.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "582", geneDisplay: "BBS1" },
  { key: "NM_024652.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79705", geneDisplay: "LRRK1" },
  { key: "NM_024653.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79706", geneDisplay: "PRKRIP1" },
  { key: "NM_024654.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79707", geneDisplay: "NOL9" },
  { key: "NM_024656.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79709", geneDisplay: "COLGALT1" },
  { key: "NM_024657.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79710", geneDisplay: "MORC4" },
  { key: "NM_024658.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79711", geneDisplay: "IPO4" },
  { key: "NM_024660.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79713", geneDisplay: "IGFLR1" },
  { key: "NM_024662.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55226", geneDisplay: "NAT10" },
  { key: "NM_024663.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79716", geneDisplay: "NPEPL1" },
  { key: "NM_024664.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79717", geneDisplay: "PPCS" },
  { key: "NM_024665.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79718", geneDisplay: "TBL1XR1" },
  { key: "NM_024666.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79719", geneDisplay: "AAGAB" },
  { key: "NM_024667.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79720", geneDisplay: "VPS37B" },
  { key: "NM_024669.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79722", geneDisplay: "ANKRD55" },
  { key: "NM_024671.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79724", geneDisplay: "ZNF768" },
  { key: "NM_024672.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79725", geneDisplay: "THAP9" },
  { key: "NM_024674.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79727", geneDisplay: "LIN28A" },
  { key: "NM_024675.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79728", geneDisplay: "PALB2" },
  { key: "NM_024677.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79730", geneDisplay: "NSUN7" },
  { key: "NM_024678.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79731", geneDisplay: "NARS2" },
  { key: "NM_024680.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79733", geneDisplay: "E2F8" },
  { key: "NM_024682.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79735", geneDisplay: "TBC1D17" },
  { key: "NM_024683.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79736", geneDisplay: "TEFM" },
  { key: "NM_024684.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "28971", geneDisplay: "AAMDC" },
  { key: "NM_024685.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79738", geneDisplay: "BBS10" },
  { key: "NM_024686.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79739", geneDisplay: "TTLL7" },
  { key: "NM_024690.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94025", geneDisplay: "MUC16" },
  { key: "NM_024691.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79744", geneDisplay: "ZNF419" },
  { key: "NM_024692.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79745", geneDisplay: "CLIP4" },
  { key: "NM_024693.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79746", geneDisplay: "ECHDC3" },
  { key: "NM_024694.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79747", geneDisplay: "ADGB" },
  { key: "NM_024697.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79750", geneDisplay: "ZNF385D" },
  { key: "NM_024699.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79752", geneDisplay: "ZFAND1" },
  { key: "NM_024700.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79753", geneDisplay: "SNIP1" },
  { key: "NM_024701.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79754", geneDisplay: "ASB13" },
  { key: "NM_024702.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79755", geneDisplay: "ZNF750" },
  { key: "NM_024704.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55614", geneDisplay: "KIF16B" },
  { key: "NM_024706.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79759", geneDisplay: "ZNF668" },
  { key: "NM_024707.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79760", geneDisplay: "GEMIN7" },
  { key: "NM_024709.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79762", geneDisplay: "C1orf115" },
  { key: "NM_024711.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "474344", geneDisplay: "GIMAP6" },
  { key: "NM_024712.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79767", geneDisplay: "ELMO3" },
  { key: "NM_024713.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79768", geneDisplay: "KATNBL1" },
  { key: "NM_024715.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79770", geneDisplay: "TXNDC15" },
  { key: "NM_024717.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79772", geneDisplay: "MCTP1" },
  { key: "NM_024718.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55684", geneDisplay: "RABL6" },
  { key: "NM_024719.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79774", geneDisplay: "GRTP1" },
  { key: "NM_024721.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79776", geneDisplay: "ZFHX4" },
  { key: "NM_024725.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79780", geneDisplay: "CCDC82" },
  { key: "NM_024726.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79781", geneDisplay: "IQCA1" },
  { key: "NM_024727.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79782", geneDisplay: "LRRC31" },
  { key: "NM_024731.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79786", geneDisplay: "KLHL36" },
  { key: "NM_024733.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79788", geneDisplay: "ZNF665" },
  { key: "NM_024734.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79789", geneDisplay: "CLMN" },
  { key: "NM_024735.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79791", geneDisplay: "FBXO31" },
  { key: "NM_024736.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79792", geneDisplay: "GSDMD" },
  { key: "NM_024738.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79794", geneDisplay: "SPRING1" },
  { key: "NM_024740.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79796", geneDisplay: "ALG9" },
  { key: "NM_024741.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79797", geneDisplay: "ZNF408" },
  { key: "NM_024743.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79799", geneDisplay: "UGT2A3" },
  { key: "NM_024744.17", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79800", geneDisplay: "CARF" },
  { key: "NM_024745.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79801", geneDisplay: "SHCBP1" },
  { key: "NM_024746.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79802", geneDisplay: "HHIPL2" },
  { key: "NM_024747.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79803", geneDisplay: "HPS6" },
  { key: "NM_024753.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79809", geneDisplay: "TTC21B" },
  { key: "NM_024754.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79810", geneDisplay: "PTCD2" },
  { key: "NM_024755.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79811", geneDisplay: "SLTM" },
  { key: "NM_024756.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79812", geneDisplay: "MMRN2" },
  { key: "NM_024757.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79813", geneDisplay: "EHMT1" },
  { key: "NM_024758.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79814", geneDisplay: "AGMAT" },
  { key: "NM_024761.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79817", geneDisplay: "MOB3B" },
  { key: "NM_024762.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79818", geneDisplay: "ZNF552" },
  { key: "NM_024763.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79819", geneDisplay: "DNAI4" },
  { key: "NM_024764.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79820", geneDisplay: "CATSPERB" },
  { key: "NM_024766.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79823", geneDisplay: "CAMKMT" },
  { key: "NM_024769.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79827", geneDisplay: "CLMP" },
  { key: "NM_024771.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79829", geneDisplay: "NAA40" },
  { key: "NM_024772.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79830", geneDisplay: "ZMYM1" },
  { key: "NM_024773.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79831", geneDisplay: "KDM8" },
  { key: "NM_024775.10", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79833", geneDisplay: "GEMIN6" },
  { key: "NM_024779.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79837", geneDisplay: "PIP4K2C" },
  { key: "NM_024781.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "79839", geneDisplay: "CCDC102B" },
  { key: "NM_024782.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79840", geneDisplay: "NHEJ1" },
  { key: "NM_024783.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79841", geneDisplay: "AGBL2" },
  { key: "NM_024786.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79844", geneDisplay: "ZDHHC11" },
  { key: "NM_024787.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79845", geneDisplay: "RNF122" },
  { key: "NM_024789.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79847", geneDisplay: "MFSD13A" },
  { key: "NM_024792.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79850", geneDisplay: "TLCD3A" },
  { key: "NM_024794.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79852", geneDisplay: "EPHX3" },
  { key: "NM_024795.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79853", geneDisplay: "TM4SF20" },
  { key: "NM_024798.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79856", geneDisplay: "SNX22" },
  { key: "NM_024800.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79858", geneDisplay: "NEK11" },
  { key: "NM_024803.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79861", geneDisplay: "TUBAL3" },
  { key: "NM_024805.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "79863", geneDisplay: "RBFA" },
  { key: "NM_024806.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79864", geneDisplay: "JHY" },
  { key: "NM_024807.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79865", geneDisplay: "TREML2" },
  { key: "NM_024808.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79866", geneDisplay: "BORA" },
  { key: "NM_024809.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79867", geneDisplay: "TCTN2" },
  { key: "NM_024812.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79870", geneDisplay: "BAALC" },
  { key: "NM_024813.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79871", geneDisplay: "RPAP2" },
  { key: "NM_024814.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79872", geneDisplay: "CBLL1" },
  { key: "NM_024815.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79873", geneDisplay: "NUDT18" },
  { key: "NM_024816.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79874", geneDisplay: "RABEP2" },
  { key: "NM_024817.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79875", geneDisplay: "THSD4" },
  { key: "NM_024818.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79876", geneDisplay: "UBA5" },
  { key: "NM_024821.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79879", geneDisplay: "CCDC134" },
  { key: "NM_024824.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79882", geneDisplay: "ZC3H14" },
  { key: "NM_024827.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79885", geneDisplay: "HDAC11" },
  { key: "NM_024828.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79886", geneDisplay: "CAAP1" },
  { key: "NM_024829.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79887", geneDisplay: "PLBD1" },
  { key: "NM_024830.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79888", geneDisplay: "LPCAT1" },
  { key: "NM_024831.8", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "96764", geneDisplay: "TGS1" },
  { key: "NM_024832.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79890", geneDisplay: "RIN3" },
  { key: "NM_024833.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79891", geneDisplay: "ZNF671" },
  { key: "NM_024835.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79893", geneDisplay: "GGNBP2" },
  { key: "NM_024836.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79894", geneDisplay: "ZNF672" },
  { key: "NM_024837.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79895", geneDisplay: "ATP8B4" },
  { key: "NM_024838.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79896", geneDisplay: "THNSL1" },
  { key: "NM_024839.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79897", geneDisplay: "RPP21" },
  { key: "NM_024843.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79901", geneDisplay: "CYBRD1" },
  { key: "NM_024844.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79902", geneDisplay: "NUP85" },
  { key: "NM_024847.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79905", geneDisplay: "TMC7" },
  { key: "NM_024848.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79906", geneDisplay: "MORN1" },
  { key: "NM_024852.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "192669", geneDisplay: "AGO3" },
  { key: "NM_024854.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79912", geneDisplay: "PYROXD1" },
  { key: "NM_024855.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79913", geneDisplay: "ACTR5" },
  { key: "NM_024857.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79915", geneDisplay: "ATAD5" },
  { key: "NM_024859.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79917", geneDisplay: "MAGIX" },
  { key: "NM_024864.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79922", geneDisplay: "MRM1" },
  { key: "NM_024865.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79923", geneDisplay: "NANOG" },
  { key: "NM_024867.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79925", geneDisplay: "SPEF2" },
  { key: "NM_024869.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79927", geneDisplay: "FAM110D" },
  { key: "NM_024870.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80243", geneDisplay: "PREX2" },
  { key: "NM_024871.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79929", geneDisplay: "MAP6D1" },
  { key: "NM_024873.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79931", geneDisplay: "TNIP3" },
  { key: "NM_024874.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79932", geneDisplay: "KIAA0319L" },
  { key: "NM_024876.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79934", geneDisplay: "COQ8B" },
  { key: "NM_024877.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79935", geneDisplay: "CCNP" },
  { key: "NM_024881.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79939", geneDisplay: "SLC35E1" },
  { key: "NM_024884.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79944", geneDisplay: "L2HGDH" },
  { key: "NM_024893.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79953", geneDisplay: "SYNDIG1" },
  { key: "NM_024894.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79954", geneDisplay: "NOL10" },
  { key: "NM_024896.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79956", geneDisplay: "ERMP1" },
  { key: "NM_024898.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79958", geneDisplay: "DENND1C" },
  { key: "NM_024899.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "79959", geneDisplay: "CEP76" },
  { key: "NM_024901.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79961", geneDisplay: "DENND2D" },
  { key: "NM_024907.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115290", geneDisplay: "FBXO17" },
  { key: "NM_024908.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79968", geneDisplay: "WDR76" },
  { key: "NM_024911.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79971", geneDisplay: "WLS" },
  { key: "NM_024913.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79974", geneDisplay: "CPED1" },
  { key: "NM_024915.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79977", geneDisplay: "GRHL2" },
  { key: "NM_024917.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79979", geneDisplay: "TRMT2B" },
  { key: "NM_024919.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79981", geneDisplay: "FRMD1" },
  { key: "NM_024921.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79983", geneDisplay: "POF1B" },
  { key: "NM_024922.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23491", geneDisplay: "CES3" },
  { key: "NM_024923.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23225", geneDisplay: "NUP210" },
  { key: "NM_024926.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79989", geneDisplay: "TTC26" },
  { key: "NM_024927.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79990", geneDisplay: "PLEKHH3" },
  { key: "NM_024928.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79991", geneDisplay: "STN1" },
  { key: "NM_024930.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79993", geneDisplay: "ELOVL7" },
  { key: "NM_024936.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "29063", geneDisplay: "ZCCHC4" },
  { key: "NM_024939.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80004", geneDisplay: "ESRP2" },
  { key: "NM_024940.8", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80005", geneDisplay: "DOCK5" },
  { key: "NM_024941.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80006", geneDisplay: "TRAPPC13" },
  { key: "NM_024942.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80007", geneDisplay: "C10orf88" },
  { key: "NM_024943.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80008", geneDisplay: "TMEM156" },
  { key: "NM_024944.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "140578", geneDisplay: "CHODL" },
  { key: "NM_024946.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80011", geneDisplay: "PSME3IP1" },
  { key: "NM_024947.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80012", geneDisplay: "PHC3" },
  { key: "NM_024948.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80013", geneDisplay: "MINDY3" },
  { key: "NM_024949.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80014", geneDisplay: "WWC2" },
  { key: "NM_024953.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80018", geneDisplay: "NAA25" },
  { key: "NM_024954.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80019", geneDisplay: "UBTD1" },
  { key: "NM_024956.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80021", geneDisplay: "TMEM62" },
  { key: "NM_024963.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "80028", geneDisplay: "FBXL18" },
  { key: "NM_024967.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80032", geneDisplay: "ZNF556" },
  { key: "NM_024980.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80045", geneDisplay: "GPR157" },
  { key: "NM_024989.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80055", geneDisplay: "PGAP1" },
  { key: "NM_024996.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "85476", geneDisplay: "GFM1" },
  { key: "NM_025000.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80067", geneDisplay: "DCAF17" },
  { key: "NM_025003.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80070", geneDisplay: "ADAMTS20" },
  { key: "NM_025004.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80071", geneDisplay: "CCDC15" },
  { key: "NM_025009.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9662", geneDisplay: "CEP135" },
  { key: "NM_025010.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23276", geneDisplay: "KLHL18" },
  { key: "NM_025015.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "259217", geneDisplay: "HSPA12A" },
  { key: "NM_025029.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80097", geneDisplay: "MZT2B" },
  { key: "NM_025040.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80110", geneDisplay: "ZNF614" },
  { key: "NM_025045.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80115", geneDisplay: "BAIAP2L2" },
  { key: "NM_025047.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80117", geneDisplay: "ARL14" },
  { key: "NM_025052.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80122", geneDisplay: "MAP3K19" },
  { key: "NM_025054.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80124", geneDisplay: "VCPIP1" },
  { key: "NM_025055.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80125", geneDisplay: "CCDC33" },
  { key: "NM_025057.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80127", geneDisplay: "BBOF1" },
  { key: "NM_025058.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80128", geneDisplay: "TRIM46" },
  { key: "NM_025059.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80129", geneDisplay: "CCDC170" },
  { key: "NM_025061.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80131", geneDisplay: "LRRC8E" },
  { key: "NM_025065.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80135", geneDisplay: "RPF1" },
  { key: "NM_025069.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80139", geneDisplay: "ZNF703" },
  { key: "NM_025072.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80142", geneDisplay: "PTGES2" },
  { key: "NM_025073.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80143", geneDisplay: "SIKE1" },
  { key: "NM_025074.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80144", geneDisplay: "FRAS1" },
  { key: "NM_025075.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80145", geneDisplay: "THOC7" },
  { key: "NM_025077.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114034", geneDisplay: "TOE1" },
  { key: "NM_025078.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "80148", geneDisplay: "SLC66A2" },
  { key: "NM_025079.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80149", geneDisplay: "ZC3H12A" },
  { key: "NM_025081.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57523", geneDisplay: "NYNRIN" },
  { key: "NM_025082.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80152", geneDisplay: "CENPT" },
  { key: "NM_025083.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80153", geneDisplay: "EDC3" },
  { key: "NM_025087.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80157", geneDisplay: "CWH43" },
  { key: "NM_025092.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80162", geneDisplay: "PGGHG" },
  { key: "NM_025098.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80168", geneDisplay: "MOGAT2" },
  { key: "NM_025099.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80169", geneDisplay: "CTC1" },
  { key: "NM_025103.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80173", geneDisplay: "IFT74" },
  { key: "NM_025106.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80176", geneDisplay: "SPSB1" },
  { key: "NM_025107.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80177", geneDisplay: "MYCT1" },
  { key: "NM_025108.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80178", geneDisplay: "TEDC2" },
  { key: "NM_025112.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79364", geneDisplay: "ZXDC" },
  { key: "NM_025113.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "80183", geneDisplay: "RUBCNL" },
  { key: "NM_025114.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80184", geneDisplay: "CEP290" },
  { key: "NM_025124.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80194", geneDisplay: "TMEM134" },
  { key: "NM_025125.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80195", geneDisplay: "TMEM254" },
  { key: "NM_025126.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80196", geneDisplay: "RNF34" },
  { key: "NM_025128.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80198", geneDisplay: "MUS81" },
  { key: "NM_025129.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80199", geneDisplay: "FUZ" },
  { key: "NM_025130.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80201", geneDisplay: "HKDC1" },
  { key: "NM_025132.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57728", geneDisplay: "WDR19" },
  { key: "NM_025136.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80207", geneDisplay: "OPA3" },
  { key: "NM_025137.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80208", geneDisplay: "SPG11" },
  { key: "NM_025138.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "80209", geneDisplay: "PROSER1" },
  { key: "NM_025140.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80212", geneDisplay: "CCDC92" },
  { key: "NM_025144.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80216", geneDisplay: "ALPK1" },
  { key: "NM_025145.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80217", geneDisplay: "CFAP43" },
  { key: "NM_025146.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80218", geneDisplay: "NAA50" },
  { key: "NM_025147.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80219", geneDisplay: "COQ10B" },
  { key: "NM_025149.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80221", geneDisplay: "ACSF2" },
  { key: "NM_025150.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80222", geneDisplay: "TARS2" },
  { key: "NM_025152.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80224", geneDisplay: "NUBPL" },
  { key: "NM_025153.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23120", geneDisplay: "ATP10B" },
  { key: "NM_025155.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80227", geneDisplay: "PAAF1" },
  { key: "NM_025158.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80230", geneDisplay: "RUFY1" },
  { key: "NM_025159.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80231", geneDisplay: "TASL" },
  { key: "NM_025161.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80233", geneDisplay: "FAAP100" },
  { key: "NM_025163.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80235", geneDisplay: "PIGZ" },
  { key: "NM_025165.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80237", geneDisplay: "ELL3" },
  { key: "NM_025176.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22981", geneDisplay: "NINL" },
  { key: "NM_025179.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5362", geneDisplay: "PLXNA2" },
  { key: "NM_025181.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80255", geneDisplay: "SLC35F5" },
  { key: "NM_025182.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80256", geneDisplay: "FAM214B" },
  { key: "NM_025184.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80258", geneDisplay: "EFHC2" },
  { key: "NM_025187.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80262", geneDisplay: "PHAF1" },
  { key: "NM_025188.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80263", geneDisplay: "TRIM45" },
  { key: "NM_025189.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80264", geneDisplay: "ZNF430" },
  { key: "NM_025191.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80267", geneDisplay: "EDEM3" },
  { key: "NM_025193.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80270", geneDisplay: "HSD3B7" },
  { key: "NM_025194.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80271", geneDisplay: "ITPKC" },
  { key: "NM_025195.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10221", geneDisplay: "TRIB1" },
  { key: "NM_025196.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80273", geneDisplay: "GRPEL1" },
  { key: "NM_025201.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80301", geneDisplay: "PLEKHO2" },
  { key: "NM_025202.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80303", geneDisplay: "EFHD1" },
  { key: "NM_025203.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80304", geneDisplay: "WDCP" },
  { key: "NM_025205.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80306", geneDisplay: "MED28" },
  { key: "NM_025207.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80308", geneDisplay: "FLAD1" },
  { key: "NM_025208.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80310", geneDisplay: "PDGFD" },
  { key: "NM_025210.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80316", geneDisplay: "PPP1R2C" },
  { key: "NM_025211.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80318", geneDisplay: "GKAP1" },
  { key: "NM_025212.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80319", geneDisplay: "CXXC4" },
  { key: "NM_025214.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "80323", geneDisplay: "CCDC68" },
  { key: "NM_025215.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80324", geneDisplay: "PUS1" },
  { key: "NM_025216.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80326", geneDisplay: "WNT10A" },
  { key: "NM_025217.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80328", geneDisplay: "ULBP2" },
  { key: "NM_025218.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80329", geneDisplay: "ULBP1" },
  { key: "NM_025219.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80331", geneDisplay: "DNAJC5" },
  { key: "NM_025220.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80332", geneDisplay: "ADAM33" },
  { key: "NM_025221.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80333", geneDisplay: "KCNIP4" },
  { key: "NM_025222.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80335", geneDisplay: "WDR82" },
  { key: "NM_025225.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80339", geneDisplay: "PNPLA3" },
  { key: "NM_025227.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80341", geneDisplay: "BPIFB2" },
  { key: "NM_025228.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80342", geneDisplay: "TRAF3IP3" },
  { key: "NM_025229.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80343", geneDisplay: "SEL1L2" },
  { key: "NM_025230.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80344", geneDisplay: "DCAF11" },
  { key: "NM_025231.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80345", geneDisplay: "ZSCAN16" },
  { key: "NM_025232.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80346", geneDisplay: "REEP4" },
  { key: "NM_025233.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80347", geneDisplay: "COASY" },
  { key: "NM_025234.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80349", geneDisplay: "WDR61" },
  { key: "NM_025235.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80351", geneDisplay: "TNKS2" },
  { key: "NM_025236.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80352", geneDisplay: "RNF39" },
  { key: "NM_025237.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "50964", geneDisplay: "SOST" },
  { key: "NM_025238.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "53339", geneDisplay: "BTBD1" },
  { key: "NM_025239.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80380", geneDisplay: "PDCD1LG2" },
  { key: "NM_025241.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80700", geneDisplay: "UBXN6" },
  { key: "NM_025243.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80704", geneDisplay: "SLC19A3" },
  { key: "NM_025244.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80705", geneDisplay: "TSGA10" },
  { key: "NM_025245.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80714", geneDisplay: "PBX4" },
  { key: "NM_025246.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80723", geneDisplay: "SLC35G2" },
  { key: "NM_025247.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80724", geneDisplay: "ACAD10" },
  { key: "NM_025248.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80725", geneDisplay: "SRCIN1" },
  { key: "NM_025250.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "80727", geneDisplay: "TTYH3" },
  { key: "NM_025251.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80728", geneDisplay: "ARHGAP39" },
  { key: "NM_025257.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80736", geneDisplay: "SLC44A4" },
  { key: "NM_025258.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80737", geneDisplay: "VWA7" },
  { key: "NM_025261.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80740", geneDisplay: "LY6G6C" },
  { key: "NM_025262.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80741", geneDisplay: "LY6G5C" },
  { key: "NM_025263.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80742", geneDisplay: "PRR3" },
  { key: "NM_025264.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80745", geneDisplay: "THUMPD2" },
  { key: "NM_025265.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80746", geneDisplay: "TSEN2" },
  { key: "NM_025268.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80757", geneDisplay: "TMEM121" },
  { key: "NM_030567.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80758", geneDisplay: "PRR7" },
  { key: "NM_030568.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80759", geneDisplay: "KHDC1" },
  { key: "NM_030569.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80760", geneDisplay: "ITIH5" },
  { key: "NM_030571.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80762", geneDisplay: "NDFIP1" },
  { key: "NM_030572.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80763", geneDisplay: "SPX" },
  { key: "NM_030573.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80764", geneDisplay: "THAP7" },
  { key: "NM_030576.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80774", geneDisplay: "LIMD2" },
  { key: "NM_030577.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80775", geneDisplay: "TMEM177" },
  { key: "NM_030578.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80776", geneDisplay: "B9D2" },
  { key: "NM_030579.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80777", geneDisplay: "CYB5B" },
  { key: "NM_030581.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79726", geneDisplay: "WDR59" },
  { key: "NM_030613.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80108", geneDisplay: "ZFP2" },
  { key: "NM_030615.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3834", geneDisplay: "KIF25" },
  { key: "NM_030622.8", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29785", geneDisplay: "CYP2S1" },
  { key: "NM_030624.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80311", geneDisplay: "KLHL15" },
  { key: "NM_030625.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80312", geneDisplay: "TET1" },
  { key: "NM_030626.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80313", geneDisplay: "LRRC27" },
  { key: "NM_030627.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "80315", geneDisplay: "CPEB4" },
  { key: "NM_030628.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80789", geneDisplay: "INTS5" },
  { key: "NM_030630.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "283987", geneDisplay: "HID1" },
  { key: "NM_030631.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "89874", geneDisplay: "SLC25A21" },
  { key: "NM_030632.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "80816", geneDisplay: "ASXL3" },
  { key: "NM_030636.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "80820", geneDisplay: "EEPD1" },
  { key: "NM_030640.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80824", geneDisplay: "DUSP16" },
  { key: "NM_030641.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80830", geneDisplay: "APOL6" },
  { key: "NM_030642.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80831", geneDisplay: "APOL5" },
  { key: "NM_030645.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80851", geneDisplay: "SH3BP5L" },
  { key: "NM_030647.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "80853", geneDisplay: "KDM7A" },
  { key: "NM_030648.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80854", geneDisplay: "SETD7" },
  { key: "NM_030649.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116983", geneDisplay: "ACAP3" },
  { key: "NM_030650.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80856", geneDisplay: "LNPK" },
  { key: "NM_030651.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80863", geneDisplay: "PRRT1" },
  { key: "NM_030652.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80864", geneDisplay: "EGFL8" },
  { key: "NM_030653.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1663", geneDisplay: "DDX11" },
  { key: "NM_030662.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5605", geneDisplay: "MAP2K2" },
  { key: "NM_030663.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4184", geneDisplay: "SMCP" },
  { key: "NM_030665.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10743", geneDisplay: "RAI1" },
  { key: "NM_030666.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1992", geneDisplay: "SERPINB1" },
  { key: "NM_030667.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5800", geneDisplay: "PTPRO" },
  { key: "NM_030674.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "81539", geneDisplay: "SLC38A1" },
  { key: "NM_030752.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6950", geneDisplay: "TCP1" },
  { key: "NM_030753.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7473", geneDisplay: "WNT3" },
  { key: "NM_030754.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6289", geneDisplay: "SAA2" },
  { key: "NM_030755.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "81542", geneDisplay: "TMX1" },
  { key: "NM_030758.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23762", geneDisplay: "OSBP2" },
  { key: "NM_030759.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "29982", geneDisplay: "NRBF2" },
  { key: "NM_030760.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53637", geneDisplay: "S1PR5" },
  { key: "NM_030761.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54361", geneDisplay: "WNT4" },
  { key: "NM_030762.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79365", geneDisplay: "BHLHE41" },
  { key: "NM_030763.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79366", geneDisplay: "HMGN5" },
  { key: "NM_030764.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79368", geneDisplay: "FCRL2" },
  { key: "NM_030765.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79369", geneDisplay: "B3GNT4" },
  { key: "NM_030768.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80895", geneDisplay: "ILKAP" },
  { key: "NM_030769.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80896", geneDisplay: "NPL" },
  { key: "NM_030770.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80975", geneDisplay: "TMPRSS5" },
  { key: "NM_030771.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91057", geneDisplay: "CCDC34" },
  { key: "NM_030772.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81025", geneDisplay: "GJA9" },
  { key: "NM_030773.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81027", geneDisplay: "TUBB1" },
  { key: "NM_030774.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81285", geneDisplay: "OR51E2" },
  { key: "NM_030776.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81030", geneDisplay: "ZBP1" },
  { key: "NM_030777.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81031", geneDisplay: "SLC2A10" },
  { key: "NM_030780.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81034", geneDisplay: "SLC25A32" },
  { key: "NM_030782.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81037", geneDisplay: "CLPTM1L" },
  { key: "NM_030783.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81490", geneDisplay: "PTDSS2" },
  { key: "NM_030784.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81491", geneDisplay: "GPR63" },
  { key: "NM_030785.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81492", geneDisplay: "RSPH6A" },
  { key: "NM_030786.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81493", geneDisplay: "SYNC" },
  { key: "NM_030787.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81494", geneDisplay: "CFHR5" },
  { key: "NM_030788.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81501", geneDisplay: "DCSTAMP" },
  { key: "NM_030790.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81533", geneDisplay: "ITFG1" },
  { key: "NM_030791.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "81537", geneDisplay: "SGPP1" },
  { key: "NM_030792.8", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81544", geneDisplay: "GDPD5" },
  { key: "NM_030795.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81551", geneDisplay: "STMN4" },
  { key: "NM_030796.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "81552", geneDisplay: "VOPP1" },
  { key: "NM_030797.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "81553", geneDisplay: "CYRIA" },
  { key: "NM_030798.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "81554", geneDisplay: "RCC1L" },
  { key: "NM_030799.9", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81555", geneDisplay: "YIPF5" },
  { key: "NM_030801.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "81557", geneDisplay: "MAGED4B" },
  { key: "NM_030802.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81558", geneDisplay: "FAM117A" },
  { key: "NM_030803.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55054", geneDisplay: "ATG16L1" },
  { key: "NM_030805.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "81562", geneDisplay: "LMAN2L" },
  { key: "NM_030806.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81563", geneDisplay: "C1orf21" },
  { key: "NM_030808.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81565", geneDisplay: "NDEL1" },
  { key: "NM_030809.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "81566", geneDisplay: "CSRNP2" },
  { key: "NM_030810.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81567", geneDisplay: "TXNDC5" },
  { key: "NM_030811.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "64949", geneDisplay: "MRPS26" },
  { key: "NM_030812.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81569", geneDisplay: "ACTL8" },
  { key: "NM_030815.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81572", geneDisplay: "PDRG1" },
  { key: "NM_030816.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81573", geneDisplay: "ANKRD13C" },
  { key: "NM_030817.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "81575", geneDisplay: "APOLD1" },
  { key: "NM_030818.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81576", geneDisplay: "YJU2B" },
  { key: "NM_030819.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81577", geneDisplay: "GFOD2" },
  { key: "NM_030820.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81578", geneDisplay: "COL21A1" },
  { key: "NM_030821.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "81579", geneDisplay: "PLA2G12A" },
  { key: "NM_030824.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79973", geneDisplay: "ZNF442" },
  { key: "NM_030876.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81696", geneDisplay: "OR5V1" },
  { key: "NM_030877.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "56259", geneDisplay: "CTNNBL1" },
  { key: "NM_030882.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23780", geneDisplay: "APOL2" },
  { key: "NM_030883.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26716", geneDisplay: "OR2H1" },
  { key: "NM_030891.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "81543", geneDisplay: "LRRC3" },
  { key: "NM_030893.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "913", geneDisplay: "CD1E" },
  { key: "NM_030895.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79943", geneDisplay: "ZNF696" },
  { key: "NM_030899.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64288", geneDisplay: "ZSCAN31" },
  { key: "NM_030901.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26333", geneDisplay: "OR7A17" },
  { key: "NM_030903.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26692", geneDisplay: "OR2W1" },
  { key: "NM_030904.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26696", geneDisplay: "OR2T1" },
  { key: "NM_030905.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26707", geneDisplay: "OR2J2" },
  { key: "NM_030907.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79363", geneDisplay: "CPLANE2" },
  { key: "NM_030908.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79541", geneDisplay: "OR2A4" },
  { key: "NM_030911.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "81602", geneDisplay: "CDADC1" },
  { key: "NM_030912.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "81603", geneDisplay: "TRIM8" },
  { key: "NM_030913.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10500", geneDisplay: "SEMA6C" },
  { key: "NM_030914.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "81605", geneDisplay: "URM1" },
  { key: "NM_030915.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "81606", geneDisplay: "LBH" },
  { key: "NM_030916.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81607", geneDisplay: "NECTIN4" },
  { key: "NM_030917.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "81608", geneDisplay: "FIP1L1" },
  { key: "NM_030919.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81610", geneDisplay: "FAM83D" },
  { key: "NM_030920.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81611", geneDisplay: "ANP32E" },
  { key: "NM_030922.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "81614", geneDisplay: "NIPA2" },
  { key: "NM_030923.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "81615", geneDisplay: "TMEM163" },
  { key: "NM_030924.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81616", geneDisplay: "ACSBG2" },
  { key: "NM_030926.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "81618", geneDisplay: "ITM2C" },
  { key: "NM_030927.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "81619", geneDisplay: "TSPAN14" },
  { key: "NM_030928.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81620", geneDisplay: "CDT1" },
  { key: "NM_030929.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "81621", geneDisplay: "KAZALD1" },
  { key: "NM_030930.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81622", geneDisplay: "UNC93B1" },
  { key: "NM_030931.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81623", geneDisplay: "DEFB126" },
  { key: "NM_030933.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81626", geneDisplay: "SHCBP1L" },
  { key: "NM_030934.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81627", geneDisplay: "TRMT1L" },
  { key: "NM_030935.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "81628", geneDisplay: "TSC22D4" },
  { key: "NM_030936.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "140545", geneDisplay: "RNF32" },
  { key: "NM_030937.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81669", geneDisplay: "CCNL2" },
  { key: "NM_030938.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81671", geneDisplay: "VMP1" },
  { key: "NM_030939.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81688", geneDisplay: "C6orf62" },
  { key: "NM_030940.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "81689", geneDisplay: "ISCA1" },
  { key: "NM_030941.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81691", geneDisplay: "REXO5" },
  { key: "NM_030943.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "81693", geneDisplay: "AMN" },
  { key: "NM_030946.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442191", geneDisplay: "OR14J1" },
  { key: "NM_030948.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221692", geneDisplay: "PHACTR1" },
  { key: "NM_030949.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81706", geneDisplay: "PPP1R14C" },
  { key: "NM_030952.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81788", geneDisplay: "NUAK2" },
  { key: "NM_030953.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81789", geneDisplay: "TIGD6" },
  { key: "NM_030954.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81790", geneDisplay: "RNF170" },
  { key: "NM_030955.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81792", geneDisplay: "ADAMTS12" },
  { key: "NM_030956.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "81793", geneDisplay: "TLR10" },
  { key: "NM_030957.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81794", geneDisplay: "ADAMTS10" },
  { key: "NM_030958.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81796", geneDisplay: "SLCO5A1" },
  { key: "NM_030959.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81797", geneDisplay: "OR12D3" },
  { key: "NM_030960.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81833", geneDisplay: "SPACA1" },
  { key: "NM_030961.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "81844", geneDisplay: "TRIM56" },
  { key: "NM_030962.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81846", geneDisplay: "SBF2" },
  { key: "NM_030965.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81849", geneDisplay: "ST6GALNAC5" },
  { key: "NM_030966.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81850", geneDisplay: "KRTAP1-3" },
  { key: "NM_030967.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81851", geneDisplay: "KRTAP1-1" },
  { key: "NM_030968.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114897", geneDisplay: "C1QTNF1" },
  { key: "NM_030969.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81853", geneDisplay: "TMEM14B" },
  { key: "NM_030971.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "81855", geneDisplay: "SFXN3" },
  { key: "NM_030973.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81857", geneDisplay: "MED25" },
  { key: "NM_030974.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "81858", geneDisplay: "SHARPIN" },
  { key: "NM_030976.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81871", geneDisplay: "KRTAP4-6" },
  { key: "NM_030978.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "81873", geneDisplay: "ARPC5L" },
  { key: "NM_030979.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5042", geneDisplay: "PABPC3" },
  { key: "NM_030981.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81876", geneDisplay: "RAB1B" },
  { key: "NM_031157.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3178", geneDisplay: "HNRNPA1" },
  { key: "NM_031200.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10803", geneDisplay: "CCR9" },
  { key: "NM_031206.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "81887", geneDisplay: "LAS1L" },
  { key: "NM_031208.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "81889", geneDisplay: "FAHD1" },
  { key: "NM_031209.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81890", geneDisplay: "QTRT1" },
  { key: "NM_031210.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "81892", geneDisplay: "SLIRP" },
  { key: "NM_031212.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "81894", geneDisplay: "SLC25A28" },
  { key: "NM_031215.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81928", geneDisplay: "CABLES2" },
  { key: "NM_031217.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81930", geneDisplay: "KIF18A" },
  { key: "NM_031218.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81931", geneDisplay: "ZNF93" },
  { key: "NM_031220.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83394", geneDisplay: "PITPNM3" },
  { key: "NM_031229.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10616", geneDisplay: "RBCK1" },
  { key: "NM_031232.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63941", geneDisplay: "NECAB3" },
  { key: "NM_031246.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5670", geneDisplay: "PSG2" },
  { key: "NM_031263.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3190", geneDisplay: "HNRNPK" },
  { key: "NM_031266.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3182", geneDisplay: "HNRNPAB" },
  { key: "NM_031272.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56155", geneDisplay: "TEX14" },
  { key: "NM_031273.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56156", geneDisplay: "TEX13B" },
  { key: "NM_031274.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56157", geneDisplay: "TEX13A" },
  { key: "NM_031275.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56158", geneDisplay: "TEX12" },
  { key: "NM_031276.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56159", geneDisplay: "TEX11" },
  { key: "NM_031277.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "56163", geneDisplay: "RNF17" },
  { key: "NM_031279.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64850", geneDisplay: "ETNPPL" },
  { key: "NM_031280.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64960", geneDisplay: "MRPS15" },
  { key: "NM_031281.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83416", geneDisplay: "FCRL5" },
  { key: "NM_031282.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83417", geneDisplay: "FCRL4" },
  { key: "NM_031283.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "83439", geneDisplay: "TCF7L1" },
  { key: "NM_031286.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83442", geneDisplay: "SH3BGRL3" },
  { key: "NM_031287.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83443", geneDisplay: "SF3B5" },
  { key: "NM_031288.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "83444", geneDisplay: "INO80B" },
  { key: "NM_031290.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "83446", geneDisplay: "CCDC70" },
  { key: "NM_031291.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83447", geneDisplay: "SLC25A31" },
  { key: "NM_031292.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83448", geneDisplay: "PUS7L" },
  { key: "NM_031293.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83449", geneDisplay: "PMFBP1" },
  { key: "NM_031294.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83450", geneDisplay: "DRC3" },
  { key: "NM_031296.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83452", geneDisplay: "RAB33B" },
  { key: "NM_031297.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "727800", geneDisplay: "RNF208" },
  { key: "NM_031298.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83460", geneDisplay: "EMC6" },
  { key: "NM_031299.7", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83461", geneDisplay: "CDCA3" },
  { key: "NM_031300.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83463", geneDisplay: "MXD3" },
  { key: "NM_031301.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "83464", geneDisplay: "APH1B" },
  { key: "NM_031307.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83480", geneDisplay: "PUS3" },
  { key: "NM_031308.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83481", geneDisplay: "EPPK1" },
  { key: "NM_031309.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83482", geneDisplay: "SCRT1" },
  { key: "NM_031310.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83483", geneDisplay: "PLVAP" },
  { key: "NM_031311.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54504", geneDisplay: "CPVL" },
  { key: "NM_031313.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "251", geneDisplay: "ALPG" },
  { key: "NM_031370.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3184", geneDisplay: "HNRNPD" },
  { key: "NM_031372.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9987", geneDisplay: "HNRNPDL" },
  { key: "NM_031407.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10075", geneDisplay: "HUWE1" },
  { key: "NM_031409.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1235", geneDisplay: "CCR6" },
  { key: "NM_031412.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23710", geneDisplay: "GABARAPL1" },
  { key: "NM_031414.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "56164", geneDisplay: "STK31" },
  { key: "NM_031415.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "56169", geneDisplay: "GSDMC" },
  { key: "NM_031418.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "63982", geneDisplay: "ANO3" },
  { key: "NM_031419.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64332", geneDisplay: "NFKBIZ" },
  { key: "NM_031420.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65005", geneDisplay: "MRPL9" },
  { key: "NM_031421.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83538", geneDisplay: "ODAD4" },
  { key: "NM_031422.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "83539", geneDisplay: "CHST9" },
  { key: "NM_031426.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "83543", geneDisplay: "AIF1L" },
  { key: "NM_031427.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "83544", geneDisplay: "DNAL1" },
  { key: "NM_031430.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83547", geneDisplay: "RILP" },
  { key: "NM_031431.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "83548", geneDisplay: "COG3" },
  { key: "NM_031432.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "83549", geneDisplay: "UCK1" },
  { key: "NM_031433.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83552", geneDisplay: "MFRP" },
  { key: "NM_031435.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83591", geneDisplay: "THAP2" },
  { key: "NM_031438.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83594", geneDisplay: "NUDT12" },
  { key: "NM_031439.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83595", geneDisplay: "SOX7" },
  { key: "NM_031440.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "83597", geneDisplay: "RTP3" },
  { key: "NM_031442.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "83604", geneDisplay: "TMEM47" },
  { key: "NM_031443.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83605", geneDisplay: "CCM2" },
  { key: "NM_031446.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "83608", geneDisplay: "C18orf21" },
  { key: "NM_031448.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83636", geneDisplay: "C19orf12" },
  { key: "NM_031449.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83637", geneDisplay: "ZMIZ2" },
  { key: "NM_031452.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "83640", geneDisplay: "RAMAC" },
  { key: "NM_031453.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83641", geneDisplay: "FAM107B" },
  { key: "NM_031454.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83642", geneDisplay: "SELENOO" },
  { key: "NM_031455.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83643", geneDisplay: "CCDC3" },
  { key: "NM_031457.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83661", geneDisplay: "MS4A8" },
  { key: "NM_031459.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83667", geneDisplay: "SESN2" },
  { key: "NM_031460.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "89822", geneDisplay: "KCNK17" },
  { key: "NM_031461.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83690", geneDisplay: "CRISPLD1" },
  { key: "NM_031462.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "83692", geneDisplay: "CD99L2" },
  { key: "NM_031463.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83693", geneDisplay: "HSDL1" },
  { key: "NM_031464.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "83694", geneDisplay: "RPS6KL1" },
  { key: "NM_031467.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83697", geneDisplay: "SLC4A9" },
  { key: "NM_031468.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83698", geneDisplay: "CALN1" },
  { key: "NM_031469.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83699", geneDisplay: "SH3BGRL2" },
  { key: "NM_031471.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83706", geneDisplay: "FERMT3" },
  { key: "NM_031474.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83714", geneDisplay: "NRIP2" },
  { key: "NM_031475.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83715", geneDisplay: "ESPN" },
  { key: "NM_031476.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83716", geneDisplay: "CRISPLD2" },
  { key: "NM_031477.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83719", geneDisplay: "YPEL3" },
  { key: "NM_031478.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83723", geneDisplay: "TLCD3B" },
  { key: "NM_031479.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83729", geneDisplay: "INHBE" },
  { key: "NM_031480.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83732", geneDisplay: "RIOK1" },
  { key: "NM_031481.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83733", geneDisplay: "SLC25A18" },
  { key: "NM_031482.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83734", geneDisplay: "ATG10" },
  { key: "NM_031483.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "83737", geneDisplay: "ITCH" },
  { key: "NM_031484.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83742", geneDisplay: "MARVELD1" },
  { key: "NM_031485.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83743", geneDisplay: "GRWD1" },
  { key: "NM_031486.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "83744", geneDisplay: "ZNF484" },
  { key: "NM_031488.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83746", geneDisplay: "L3MBTL2" },
  { key: "NM_031490.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83752", geneDisplay: "LONP2" },
  { key: "NM_031491.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83758", geneDisplay: "RBP5" },
  { key: "NM_031492.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83759", geneDisplay: "RBM4B" },
  { key: "NM_031844.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3192", geneDisplay: "HNRNPU" },
  { key: "NM_031854.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83755", geneDisplay: "KRTAP4-12" },
  { key: "NM_031857.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9752", geneDisplay: "PCDHA9" },
  { key: "NM_031866.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8325", geneDisplay: "FZD8" },
  { key: "NM_031885.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "583", geneDisplay: "BBS2" },
  { key: "NM_031886.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3743", geneDisplay: "KCNA7" },
  { key: "NM_031889.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10117", geneDisplay: "ENAM" },
  { key: "NM_031890.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27439", geneDisplay: "TMEM121B" },
  { key: "NM_031891.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "28316", geneDisplay: "CDH20" },
  { key: "NM_031892.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "30011", geneDisplay: "SH3KBP1" },
  { key: "NM_031894.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "53940", geneDisplay: "FTHL17" },
  { key: "NM_031895.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "59283", geneDisplay: "CACNG8" },
  { key: "NM_031896.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "59284", geneDisplay: "CACNG7" },
  { key: "NM_031898.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64518", geneDisplay: "TEKT3" },
  { key: "NM_031899.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64689", geneDisplay: "GORASP1" },
  { key: "NM_031900.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64902", geneDisplay: "AGXT2" },
  { key: "NM_031901.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54460", geneDisplay: "MRPS21" },
  { key: "NM_031902.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64969", geneDisplay: "MRPS5" },
  { key: "NM_031903.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64983", geneDisplay: "MRPL32" },
  { key: "NM_031904.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83786", geneDisplay: "FRMD8" },
  { key: "NM_031905.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83787", geneDisplay: "ARMC10" },
  { key: "NM_031907.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "83844", geneDisplay: "USP26" },
  { key: "NM_031908.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "114898", geneDisplay: "C1QTNF2" },
  { key: "NM_031909.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114900", geneDisplay: "C1QTNF4" },
  { key: "NM_031910.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "114904", geneDisplay: "C1QTNF6" },
  { key: "NM_031911.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "114905", geneDisplay: "C1QTNF7" },
  { key: "NM_031912.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83849", geneDisplay: "SYT15" },
  { key: "NM_031913.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "83850", geneDisplay: "ESYT3" },
  { key: "NM_031916.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83853", geneDisplay: "ROPN1L" },
  { key: "NM_031917.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83854", geneDisplay: "ANGPTL6" },
  { key: "NM_031918.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83855", geneDisplay: "KLF16" },
  { key: "NM_031921.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83858", geneDisplay: "ATAD3B" },
  { key: "NM_031923.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83860", geneDisplay: "TAF3" },
  { key: "NM_031924.8", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83861", geneDisplay: "RSPH3" },
  { key: "NM_031925.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83862", geneDisplay: "TMEM120A" },
  { key: "NM_031934.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83871", geneDisplay: "RAB34" },
  { key: "NM_031935.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83872", geneDisplay: "HMCN1" },
  { key: "NM_031937.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83874", geneDisplay: "TBC1D10A" },
  { key: "NM_031938.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83875", geneDisplay: "BCO2" },
  { key: "NM_031939.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "83876", geneDisplay: "MRO" },
  { key: "NM_031941.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83878", geneDisplay: "USHBP1" },
  { key: "NM_031942.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "83879", geneDisplay: "CDCA7" },
  { key: "NM_031944.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83881", geneDisplay: "MIXL1" },
  { key: "NM_031946.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "116988", geneDisplay: "AGAP3" },
  { key: "NM_031947.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83884", geneDisplay: "SLC25A2" },
  { key: "NM_031948.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83886", geneDisplay: "PRSS27" },
  { key: "NM_031949.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83887", geneDisplay: "TTLL2" },
  { key: "NM_031950.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83888", geneDisplay: "FGFBP2" },
  { key: "NM_031952.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83890", geneDisplay: "SPATA9" },
  { key: "NM_031954.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83892", geneDisplay: "KCTD10" },
  { key: "NM_031955.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "83893", geneDisplay: "SPATA16" },
  { key: "NM_031956.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83894", geneDisplay: "TTC29" },
  { key: "NM_031957.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83895", geneDisplay: "KRTAP1-5" },
  { key: "NM_031958.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83896", geneDisplay: "KRTAP3-1" },
  { key: "NM_031959.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83897", geneDisplay: "KRTAP3-2" },
  { key: "NM_031960.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "728224", geneDisplay: "KRTAP4-8" },
  { key: "NM_031961.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83899", geneDisplay: "KRTAP9-2" },
  { key: "NM_031962.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83900", geneDisplay: "KRTAP9-3" },
  { key: "NM_031963.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83901", geneDisplay: "KRTAP9-8" },
  { key: "NM_031964.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83902", geneDisplay: "KRTAP17-1" },
  { key: "NM_031965.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83903", geneDisplay: "HASPIN" },
  { key: "NM_031966.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "891", geneDisplay: "CCNB1" },
  { key: "NM_031989.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5094", geneDisplay: "PCBP2" },
  { key: "NM_032012.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23731", geneDisplay: "TMEM245" },
  { key: "NM_032013.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57446", geneDisplay: "NDRG3" },
  { key: "NM_032014.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64951", geneDisplay: "MRPS24" },
  { key: "NM_032015.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79102", geneDisplay: "RNF26" },
  { key: "NM_032016.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83930", geneDisplay: "STARD3NL" },
  { key: "NM_032018.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83932", geneDisplay: "SPRTN" },
  { key: "NM_032019.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83933", geneDisplay: "HDAC10" },
  { key: "NM_032020.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2519", geneDisplay: "FUCA2" },
  { key: "NM_032023.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83937", geneDisplay: "RASSF4" },
  { key: "NM_032025.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "83939", geneDisplay: "EIF2A" },
  { key: "NM_032026.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83940", geneDisplay: "TATDN1" },
  { key: "NM_032027.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83941", geneDisplay: "TM2D1" },
  { key: "NM_032028.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83942", geneDisplay: "TSSK1B" },
  { key: "NM_032036.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "83982", geneDisplay: "IFI27L2" },
  { key: "NM_032037.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83983", geneDisplay: "TSSK6" },
  { key: "NM_032038.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83985", geneDisplay: "SPNS1" },
  { key: "NM_032039.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83986", geneDisplay: "FAM234A" },
  { key: "NM_032040.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83987", geneDisplay: "CCDC8" },
  { key: "NM_032041.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83988", geneDisplay: "NCALD" },
  { key: "NM_032042.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "83989", geneDisplay: "FAM172A" },
  { key: "NM_032043.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83990", geneDisplay: "BRIP1" },
  { key: "NM_032044.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83998", geneDisplay: "REG4" },
  { key: "NM_032047.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84002", geneDisplay: "B3GNT5" },
  { key: "NM_032048.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84034", geneDisplay: "EMILIN2" },
  { key: "NM_032088.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9708", geneDisplay: "PCDHGA8" },
  { key: "NM_032102.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10929", geneDisplay: "SRSF8" },
  { key: "NM_032108.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10501", geneDisplay: "SEMA6B" },
  { key: "NM_032109.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23440", geneDisplay: "OTP" },
  { key: "NM_032110.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63950", geneDisplay: "DMRTA2" },
  { key: "NM_032111.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64928", geneDisplay: "MRPL14" },
  { key: "NM_032112.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84545", geneDisplay: "MRPL43" },
  { key: "NM_032116.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84056", geneDisplay: "KATNAL1" },
  { key: "NM_032117.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84057", geneDisplay: "MND1" },
  { key: "NM_032118.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84058", geneDisplay: "WDR54" },
  { key: "NM_032119.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84059", geneDisplay: "ADGRV1" },
  { key: "NM_032120.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84060", geneDisplay: "RBM48" },
  { key: "NM_032122.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84062", geneDisplay: "DTNBP1" },
  { key: "NM_032124.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84064", geneDisplay: "HDHD2" },
  { key: "NM_032125.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84065", geneDisplay: "TMEM222" },
  { key: "NM_032126.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84066", geneDisplay: "TEX35" },
  { key: "NM_032129.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84069", geneDisplay: "PLEKHN1" },
  { key: "NM_032130.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84070", geneDisplay: "FAM186B" },
  { key: "NM_032131.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84071", geneDisplay: "ARMC2" },
  { key: "NM_032132.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84072", geneDisplay: "HORMAD1" },
  { key: "NM_032133.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84073", geneDisplay: "MYCBPAP" },
  { key: "NM_032135.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84075", geneDisplay: "FSCB" },
  { key: "NM_032136.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84076", geneDisplay: "TKTL2" },
  { key: "NM_032137.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84077", geneDisplay: "C3orf20" },
  { key: "NM_032138.7", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84078", geneDisplay: "KBTBD7" },
  { key: "NM_032139.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84079", geneDisplay: "ANKRD27" },
  { key: "NM_032140.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84080", geneDisplay: "ENKD1" },
  { key: "NM_032141.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84081", geneDisplay: "NSRP1" },
  { key: "NM_032142.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55125", geneDisplay: "CEP192" },
  { key: "NM_032143.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84083", geneDisplay: "ZRANB3" },
  { key: "NM_032144.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84084", geneDisplay: "RAB6C" },
  { key: "NM_032145.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84085", geneDisplay: "FBXO30" },
  { key: "NM_032147.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84101", geneDisplay: "USP44" },
  { key: "NM_032149.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84103", geneDisplay: "C4orf17" },
  { key: "NM_032151.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84105", geneDisplay: "PCBD2" },
  { key: "NM_032152.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84106", geneDisplay: "PRAM1" },
  { key: "NM_032153.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84107", geneDisplay: "ZIC4" },
  { key: "NM_032160.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "92126", geneDisplay: "DSEL" },
  { key: "NM_032164.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84124", geneDisplay: "ZNF394" },
  { key: "NM_032167.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "92017", geneDisplay: "SNX29" },
  { key: "NM_032168.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84128", geneDisplay: "WDR75" },
  { key: "NM_032169.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84129", geneDisplay: "ACAD11" },
  { key: "NM_032172.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84132", geneDisplay: "USP42" },
  { key: "NM_032174.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84134", geneDisplay: "TOMM40L" },
  { key: "NM_032175.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84135", geneDisplay: "UTP15" },
  { key: "NM_032177.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51808", geneDisplay: "PHAX" },
  { key: "NM_032178.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84138", geneDisplay: "SLC7A6OS" },
  { key: "NM_032182.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23172", geneDisplay: "ABRAXAS2" },
  { key: "NM_032184.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84144", geneDisplay: "SYDE2" },
  { key: "NM_032188.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84148", geneDisplay: "KAT8" },
  { key: "NM_032192.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84152", geneDisplay: "PPP1R1B" },
  { key: "NM_032193.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84153", geneDisplay: "RNASEH2C" },
  { key: "NM_032194.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84154", geneDisplay: "RPF2" },
  { key: "NM_032199.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84159", geneDisplay: "ARID5B" },
  { key: "NM_032204.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84164", geneDisplay: "ASCC2" },
  { key: "NM_032207.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84167", geneDisplay: "C19orf44" },
  { key: "NM_032208.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84168", geneDisplay: "ANTXR1" },
  { key: "NM_032211.7", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84171", geneDisplay: "LOXL4" },
  { key: "NM_032214.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84174", geneDisplay: "SLA2" },
  { key: "NM_032217.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "26057", geneDisplay: "ANKRD17" },
  { key: "NM_032219.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84179", geneDisplay: "SLC49A3" },
  { key: "NM_032221.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84181", geneDisplay: "CHD6" },
  { key: "NM_032222.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84182", geneDisplay: "MINDY4" },
  { key: "NM_032223.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399909", geneDisplay: "PCNX3" },
  { key: "NM_032226.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84186", geneDisplay: "ZCCHC7" },
  { key: "NM_032227.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84187", geneDisplay: "TMEM164" },
  { key: "NM_032228.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84188", geneDisplay: "FAR1" },
  { key: "NM_032229.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84189", geneDisplay: "SLITRK6" },
  { key: "NM_032230.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84190", geneDisplay: "METTL25" },
  { key: "NM_032231.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84191", geneDisplay: "CIAO2A" },
  { key: "NM_032233.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84193", geneDisplay: "SETD3" },
  { key: "NM_032236.8", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84196", geneDisplay: "USP48" },
  { key: "NM_032237.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84197", geneDisplay: "POMK" },
  { key: "NM_032242.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5361", geneDisplay: "PLXNA1" },
  { key: "NM_032243.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84203", geneDisplay: "TXNDC2" },
  { key: "NM_032246.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84206", geneDisplay: "MEX3B" },
  { key: "NM_032250.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84210", geneDisplay: "ANKRD20A1" },
  { key: "NM_032251.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283234", geneDisplay: "CCDC88B" },
  { key: "NM_032256.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84216", geneDisplay: "TMEM117" },
  { key: "NM_032257.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84217", geneDisplay: "ZMYND12" },
  { key: "NM_032258.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84218", geneDisplay: "TBC1D3F" },
  { key: "NM_032259.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84219", geneDisplay: "WDR24" },
  { key: "NM_032263.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84223", geneDisplay: "IQCG" },
  { key: "NM_032264.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84224", geneDisplay: "NBPF3" },
  { key: "NM_032266.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84226", geneDisplay: "C2orf16" },
  { key: "NM_032268.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84937", geneDisplay: "ZNRF1" },
  { key: "NM_032270.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84230", geneDisplay: "LRRC8C" },
  { key: "NM_032271.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84231", geneDisplay: "TRAF7" },
  { key: "NM_032272.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84232", geneDisplay: "MAF1" },
  { key: "NM_032273.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84233", geneDisplay: "TMEM126A" },
  { key: "NM_032279.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84239", geneDisplay: "ATP13A4" },
  { key: "NM_032283.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84243", geneDisplay: "ZDHHC18" },
  { key: "NM_032286.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84246", geneDisplay: "MED10" },
  { key: "NM_032287.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84247", geneDisplay: "RTL6" },
  { key: "NM_032288.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84248", geneDisplay: "FYTTD1" },
  { key: "NM_032289.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84249", geneDisplay: "PSD2" },
  { key: "NM_032290.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84250", geneDisplay: "SLF1" },
  { key: "NM_032291.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84251", geneDisplay: "SGIP1" },
  { key: "NM_032293.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84253", geneDisplay: "GARNL3" },
  { key: "NM_032294.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84254", geneDisplay: "CAMKK1" },
  { key: "NM_032299.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84259", geneDisplay: "DCUN1D5" },
  { key: "NM_032301.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84261", geneDisplay: "FBXW9" },
  { key: "NM_032302.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84262", geneDisplay: "PSMG3" },
  { key: "NM_032303.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84263", geneDisplay: "HSDL2" },
  { key: "NM_032304.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84264", geneDisplay: "HAGHL" },
  { key: "NM_032305.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84265", geneDisplay: "POLR3GL" },
  { key: "NM_032306.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84266", geneDisplay: "ALKBH7" },
  { key: "NM_032307.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84267", geneDisplay: "C9orf64" },
  { key: "NM_032309.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84269", geneDisplay: "CHCHD5" },
  { key: "NM_032310.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84270", geneDisplay: "CARD19" },
  { key: "NM_032311.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84271", geneDisplay: "POLDIP3" },
  { key: "NM_032312.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84272", geneDisplay: "YIPF4" },
  { key: "NM_032313.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84273", geneDisplay: "NOA1" },
  { key: "NM_032314.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84274", geneDisplay: "COQ5" },
  { key: "NM_032315.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84275", geneDisplay: "SLC25A33" },
  { key: "NM_032316.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84276", geneDisplay: "NICN1" },
  { key: "NM_032317.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84277", geneDisplay: "DNAJC30" },
  { key: "NM_032319.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84279", geneDisplay: "PRADC1" },
  { key: "NM_032320.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84280", geneDisplay: "BTBD10" },
  { key: "NM_032322.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84282", geneDisplay: "RNF135" },
  { key: "NM_032323.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84283", geneDisplay: "TMEM79" },
  { key: "NM_032324.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84284", geneDisplay: "NTPCR" },
  { key: "NM_032326.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84286", geneDisplay: "TMEM175" },
  { key: "NM_032328.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84288", geneDisplay: "EFCAB2" },
  { key: "NM_032329.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84289", geneDisplay: "ING5" },
  { key: "NM_032330.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84290", geneDisplay: "CAPNS2" },
  { key: "NM_032331.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "110599564", geneDisplay: "EEF1AKMT4" },
  { key: "NM_032333.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84293", geneDisplay: "PRXL2A" },
  { key: "NM_032334.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84294", geneDisplay: "UTP23" },
  { key: "NM_032336.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84296", geneDisplay: "GINS4" },
  { key: "NM_032338.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84298", geneDisplay: "LLPH" },
  { key: "NM_032339.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84299", geneDisplay: "MIEN1" },
  { key: "NM_032340.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84300", geneDisplay: "UQCC2" },
  { key: "NM_032341.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84301", geneDisplay: "DDI2" },
  { key: "NM_032342.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84302", geneDisplay: "PGAP4" },
  { key: "NM_032343.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84303", geneDisplay: "CHCHD6" },
  { key: "NM_032344.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84304", geneDisplay: "NUDT22" },
  { key: "NM_032345.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84305", geneDisplay: "PYM1" },
  { key: "NM_032346.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84306", geneDisplay: "PDCD2L" },
  { key: "NM_032348.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54587", geneDisplay: "MXRA8" },
  { key: "NM_032349.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84309", geneDisplay: "NUDT16L1" },
  { key: "NM_032351.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84311", geneDisplay: "MRPL45" },
  { key: "NM_032352.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84312", geneDisplay: "BRMS1L" },
  { key: "NM_032353.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84313", geneDisplay: "VPS25" },
  { key: "NM_032355.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84315", geneDisplay: "MON1A" },
  { key: "NM_032357.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84317", geneDisplay: "CCDC115" },
  { key: "NM_032358.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84318", geneDisplay: "CCDC77" },
  { key: "NM_032359.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84319", geneDisplay: "CMSS1" },
  { key: "NM_032360.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84320", geneDisplay: "ACBD6" },
  { key: "NM_032361.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84321", geneDisplay: "THOC3" },
  { key: "NM_032364.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "85406", geneDisplay: "DNAJC14" },
  { key: "NM_032366.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84326", geneDisplay: "METTL26" },
  { key: "NM_032367.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84327", geneDisplay: "ZBED3" },
  { key: "NM_032368.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84328", geneDisplay: "LZIC" },
  { key: "NM_032369.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84329", geneDisplay: "HVCN1" },
  { key: "NM_032372.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84332", geneDisplay: "DYDC2" },
  { key: "NM_032373.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84333", geneDisplay: "PCGF5" },
  { key: "NM_032376.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84336", geneDisplay: "TMEM101" },
  { key: "NM_032377.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84337", geneDisplay: "ELOF1" },
  { key: "NM_032380.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84340", geneDisplay: "GFM2" },
  { key: "NM_032382.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84342", geneDisplay: "COG8" },
  { key: "NM_032383.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84343", geneDisplay: "HPS3" },
  { key: "NM_032385.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10826", geneDisplay: "FAXDC2" },
  { key: "NM_032387.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "65266", geneDisplay: "WNK4" },
  { key: "NM_032389.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84364", geneDisplay: "ARFGAP2" },
  { key: "NM_032390.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84365", geneDisplay: "NIFK" },
  { key: "NM_032391.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84366", geneDisplay: "PRAC1" },
  { key: "NM_032408.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9031", geneDisplay: "BAZ1B" },
  { key: "NM_032409.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65018", geneDisplay: "PINK1" },
  { key: "NM_032410.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84376", geneDisplay: "HOOK3" },
  { key: "NM_032411.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84417", geneDisplay: "ECRG4" },
  { key: "NM_032412.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84418", geneDisplay: "CYSTM1" },
  { key: "NM_032414.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84432", geneDisplay: "PROK1" },
  { key: "NM_032415.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84433", geneDisplay: "CARD11" },
  { key: "NM_032417.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64648", geneDisplay: "SPANXD" },
  { key: "NM_032420.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5097", geneDisplay: "PCDH1" },
  { key: "NM_032423.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84436", geneDisplay: "ZNF528" },
  { key: "NM_032424.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84437", geneDisplay: "MSANTD4" },
  { key: "NM_032427.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84441", geneDisplay: "MAML2" },
  { key: "NM_032430.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84446", geneDisplay: "BRSK1" },
  { key: "NM_032433.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84449", geneDisplay: "ZNF333" },
  { key: "NM_032434.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84450", geneDisplay: "ZNF512" },
  { key: "NM_032435.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84451", geneDisplay: "MAP3K21" },
  { key: "NM_032436.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "283489", geneDisplay: "CHAMP1" },
  { key: "NM_032437.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84455", geneDisplay: "EFCAB7" },
  { key: "NM_032438.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84456", geneDisplay: "L3MBTL3" },
  { key: "NM_032439.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84457", geneDisplay: "PHYHIPL" },
  { key: "NM_032442.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84461", geneDisplay: "NEURL4" },
  { key: "NM_032444.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84464", geneDisplay: "SLX4" },
  { key: "NM_032447.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84467", geneDisplay: "FBN3" },
  { key: "NM_032448.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84498", geneDisplay: "FAM120B" },
  { key: "NM_032450.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "727957", geneDisplay: "MROH1" },
  { key: "NM_032451.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84501", geneDisplay: "SPIRE2" },
  { key: "NM_032453.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84503", geneDisplay: "ZNF527" },
  { key: "NM_032461.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728695", geneDisplay: "SPANXB1" },
  { key: "NM_032464.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7462", geneDisplay: "LAT2" },
  { key: "NM_032476.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "64968", geneDisplay: "MRPS6" },
  { key: "NM_032477.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64975", geneDisplay: "MRPL41" },
  { key: "NM_032478.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64978", geneDisplay: "MRPL38" },
  { key: "NM_032479.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64979", geneDisplay: "MRPL36" },
  { key: "NM_032482.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84444", geneDisplay: "DOT1L" },
  { key: "NM_032484.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84514", geneDisplay: "GHDC" },
  { key: "NM_032485.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84515", geneDisplay: "MCM8" },
  { key: "NM_032486.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84516", geneDisplay: "DCTN5" },
  { key: "NM_032487.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84517", geneDisplay: "ACTRT3" },
  { key: "NM_032488.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84518", geneDisplay: "CNFN" },
  { key: "NM_032489.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84519", geneDisplay: "ACRBP" },
  { key: "NM_032490.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84520", geneDisplay: "GON7" },
  { key: "NM_032492.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84522", geneDisplay: "JAGN1" },
  { key: "NM_032493.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8907", geneDisplay: "AP1M1" },
  { key: "NM_032494.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84524", geneDisplay: "ZC3H8" },
  { key: "NM_032495.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84525", geneDisplay: "HOPX" },
  { key: "NM_032496.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64333", geneDisplay: "ARHGAP9" },
  { key: "NM_032497.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84527", geneDisplay: "ZNF559" },
  { key: "NM_032498.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84528", geneDisplay: "RHOXF2" },
  { key: "NM_032501.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84532", geneDisplay: "ACSS1" },
  { key: "NM_032505.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84541", geneDisplay: "KBTBD8" },
  { key: "NM_032507.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84547", geneDisplay: "PGBD1" },
  { key: "NM_032508.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84548", geneDisplay: "TMEM185A" },
  { key: "NM_032509.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84549", geneDisplay: "MAK16" },
  { key: "NM_032510.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84552", geneDisplay: "PARD6G" },
  { key: "NM_032511.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84553", geneDisplay: "FAXC" },
  { key: "NM_032514.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84557", geneDisplay: "MAP1LC3A" },
  { key: "NM_032515.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "666", geneDisplay: "BOK" },
  { key: "NM_032517.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84569", geneDisplay: "LYZL1" },
  { key: "NM_032520.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84572", geneDisplay: "GNPTG" },
  { key: "NM_032521.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84612", geneDisplay: "PARD6B" },
  { key: "NM_032523.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114880", geneDisplay: "OSBPL6" },
  { key: "NM_032524.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84616", geneDisplay: "KRTAP4-4" },
  { key: "NM_032525.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84617", geneDisplay: "TUBB6" },
  { key: "NM_032526.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84618", geneDisplay: "NT5C1A" },
  { key: "NM_032530.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84622", geneDisplay: "ZNF594" },
  { key: "NM_032531.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84623", geneDisplay: "KIRREL3" },
  { key: "NM_032532.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84624", geneDisplay: "FNDC1" },
  { key: "NM_032536.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84628", geneDisplay: "NTNG2" },
  { key: "NM_032538.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84630", geneDisplay: "TTBK1" },
  { key: "NM_032539.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84631", geneDisplay: "SLITRK2" },
  { key: "NM_032545.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55997", geneDisplay: "CFC1" },
  { key: "NM_032549.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83943", geneDisplay: "IMMP2L" },
  { key: "NM_032551.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84634", geneDisplay: "KISS1R" },
  { key: "NM_032553.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84636", geneDisplay: "GPR174" },
  { key: "NM_032554.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "27198", geneDisplay: "HCAR1" },
  { key: "NM_032557.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84640", geneDisplay: "USP38" },
  { key: "NM_032558.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84641", geneDisplay: "MFSD14B" },
  { key: "NM_032559.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84643", geneDisplay: "KIF2B" },
  { key: "NM_032561.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84645", geneDisplay: "C22orf23" },
  { key: "NM_032562.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84647", geneDisplay: "PLA2G12B" },
  { key: "NM_032563.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84648", geneDisplay: "LCE3D" },
  { key: "NM_032564.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84649", geneDisplay: "DGAT2" },
  { key: "NM_032565.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84650", geneDisplay: "EBPL" },
  { key: "NM_032566.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84651", geneDisplay: "SPINK7" },
  { key: "NM_032567.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84654", geneDisplay: "SPZ1" },
  { key: "NM_032569.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84656", geneDisplay: "GLYR1" },
  { key: "NM_032571.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84658", geneDisplay: "ADGRE3" },
  { key: "NM_032572.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84659", geneDisplay: "RNASE7" },
  { key: "NM_032575.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84662", geneDisplay: "GLIS2" },
  { key: "NM_032578.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84665", geneDisplay: "MYPN" },
  { key: "NM_032579.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84666", geneDisplay: "RETNLB" },
  { key: "NM_032581.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84668", geneDisplay: "FAM126A" },
  { key: "NM_032582.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84669", geneDisplay: "USP32" },
  { key: "NM_032584.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84671", geneDisplay: "ZNF347" },
  { key: "NM_032587.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84674", geneDisplay: "CARD6" },
  { key: "NM_032588.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84676", geneDisplay: "TRIM63" },
  { key: "NM_032590.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84678", geneDisplay: "KDM2B" },
  { key: "NM_032592.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84680", geneDisplay: "ACCS" },
  { key: "NM_032593.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84681", geneDisplay: "HINT2" },
  { key: "NM_032594.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84684", geneDisplay: "INSM2" },
  { key: "NM_032595.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84687", geneDisplay: "PPP1R9B" },
  { key: "NM_032596.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84688", geneDisplay: "C9orf24" },
  { key: "NM_032597.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84689", geneDisplay: "MS4A14" },
  { key: "NM_032600.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84692", geneDisplay: "CCDC54" },
  { key: "NM_032601.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84693", geneDisplay: "MCEE" },
  { key: "NM_032602.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84694", geneDisplay: "GJA10" },
  { key: "NM_032603.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84695", geneDisplay: "LOXL3" },
  { key: "NM_032604.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84696", geneDisplay: "ABHD1" },
  { key: "NM_032607.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84699", geneDisplay: "CREB3L3" },
  { key: "NM_032608.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84700", geneDisplay: "MYO18B" },
  { key: "NM_032609.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84701", geneDisplay: "COX4I2" },
  { key: "NM_032611.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11156", geneDisplay: "PTP4A3" },
  { key: "NM_032620.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84705", geneDisplay: "GTPBP3" },
  { key: "NM_032621.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84707", geneDisplay: "BEX2" },
  { key: "NM_032623.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84709", geneDisplay: "MGARP" },
  { key: "NM_032627.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "170463", geneDisplay: "SSBP4" },
  { key: "NM_032630.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51550", geneDisplay: "CINP" },
  { key: "NM_032632.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10914", geneDisplay: "PAPOLA" },
  { key: "NM_032634.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84720", geneDisplay: "PIGO" },
  { key: "NM_032635.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10430", geneDisplay: "TMEM147" },
  { key: "NM_032638.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2624", geneDisplay: "GATA2" },
  { key: "NM_032641.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84727", geneDisplay: "SPSB2" },
  { key: "NM_032642.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "81029", geneDisplay: "WNT5B" },
  { key: "NM_032646.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "94015", geneDisplay: "TTYH2" },
  { key: "NM_032648.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84734", geneDisplay: "FAM167B" },
  { key: "NM_032649.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84735", geneDisplay: "CNDP1" },
  { key: "NM_032656.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57647", geneDisplay: "DHX37" },
  { key: "NM_032663.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84749", geneDisplay: "USP30" },
  { key: "NM_032664.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84750", geneDisplay: "FUT10" },
  { key: "NM_032673.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84759", geneDisplay: "PCGF1" },
  { key: "NM_032681.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84767", geneDisplay: "TRIM51" },
  { key: "NM_032683.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84769", geneDisplay: "MPV17L2" },
  { key: "NM_032689.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84775", geneDisplay: "ZNF607" },
  { key: "NM_032693.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84779", geneDisplay: "NAA11" },
  { key: "NM_032701.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84787", geneDisplay: "KMT5C" },
  { key: "NM_032704.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84790", geneDisplay: "TUBA1C" },
  { key: "NM_032709.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84795", geneDisplay: "PYROXD2" },
  { key: "NM_032717.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84803", geneDisplay: "GPAT3" },
  { key: "NM_032718.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84804", geneDisplay: "MFSD9" },
  { key: "NM_032725.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84811", geneDisplay: "BUD13" },
  { key: "NM_032726.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84812", geneDisplay: "PLCD4" },
  { key: "NM_032727.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9118", geneDisplay: "INA" },
  { key: "NM_032728.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84814", geneDisplay: "PLPP7" },
  { key: "NM_032730.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84816", geneDisplay: "RTN4IP1" },
  { key: "NM_032731.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84817", geneDisplay: "TXNDC17" },
  { key: "NM_032735.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144453", geneDisplay: "BEST3" },
  { key: "NM_032737.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84823", geneDisplay: "LMNB2" },
  { key: "NM_032738.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84824", geneDisplay: "FCRLA" },
  { key: "NM_032740.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84826", geneDisplay: "SFT2D3" },
  { key: "NM_032744.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84830", geneDisplay: "ADTRP" },
  { key: "NM_032752.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84838", geneDisplay: "ZNF496" },
  { key: "NM_032756.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84842", geneDisplay: "HPDL" },
  { key: "NM_032758.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84844", geneDisplay: "PHF5A" },
  { key: "NM_032772.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84858", geneDisplay: "ZNF503" },
  { key: "NM_032775.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84861", geneDisplay: "KLHL22" },
  { key: "NM_032776.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221037", geneDisplay: "JMJD1C" },
  { key: "NM_032777.10", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "25960", geneDisplay: "ADGRA2" },
  { key: "NM_032780.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84866", geneDisplay: "TMEM25" },
  { key: "NM_032782.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84868", geneDisplay: "HAVCR2" },
  { key: "NM_032783.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84869", geneDisplay: "CBR4" },
  { key: "NM_032784.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84870", geneDisplay: "RSPO3" },
  { key: "NM_032785.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84871", geneDisplay: "AGBL4" },
  { key: "NM_032786.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84872", geneDisplay: "ZC3H10" },
  { key: "NM_032787.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84873", geneDisplay: "ADGRG7" },
  { key: "NM_032788.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84874", geneDisplay: "ZNF514" },
  { key: "NM_032789.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84875", geneDisplay: "PARP10" },
  { key: "NM_032790.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84876", geneDisplay: "ORAI1" },
  { key: "NM_032793.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84879", geneDisplay: "MFSD2A" },
  { key: "NM_032795.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84881", geneDisplay: "RPUSD4" },
  { key: "NM_032796.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "94056", geneDisplay: "SYAP1" },
  { key: "NM_032797.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84883", geneDisplay: "AIFM2" },
  { key: "NM_032799.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84885", geneDisplay: "ZDHHC12" },
  { key: "NM_032800.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84886", geneDisplay: "C1orf198" },
  { key: "NM_032801.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83700", geneDisplay: "JAM3" },
  { key: "NM_032802.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84888", geneDisplay: "SPPL2A" },
  { key: "NM_032803.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84889", geneDisplay: "SLC7A3" },
  { key: "NM_032804.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84890", geneDisplay: "ADO" },
  { key: "NM_032805.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84891", geneDisplay: "ZSCAN10" },
  { key: "NM_032806.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84892", geneDisplay: "POMGNT2" },
  { key: "NM_032808.7", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84894", geneDisplay: "LINGO1" },
  { key: "NM_032811.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84897", geneDisplay: "TBRG1" },
  { key: "NM_032812.9", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84898", geneDisplay: "PLXDC2" },
  { key: "NM_032813.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84899", geneDisplay: "TMTC4" },
  { key: "NM_032815.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84901", geneDisplay: "NFATC2IP" },
  { key: "NM_032816.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84902", geneDisplay: "CEP89" },
  { key: "NM_032818.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84904", geneDisplay: "ARHGEF39" },
  { key: "NM_032824.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84910", geneDisplay: "TMEM87B" },
  { key: "NM_032825.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84911", geneDisplay: "ZNF382" },
  { key: "NM_032826.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84912", geneDisplay: "SLC35B4" },
  { key: "NM_032827.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84913", geneDisplay: "ATOH8" },
  { key: "NM_032828.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84914", geneDisplay: "ZNF587" },
  { key: "NM_032829.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84915", geneDisplay: "FAM222A" },
  { key: "NM_032830.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84916", geneDisplay: "UTP4" },
  { key: "NM_032832.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84918", geneDisplay: "LRP11" },
  { key: "NM_032833.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84919", geneDisplay: "PPP1R15B" },
  { key: "NM_032834.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84920", geneDisplay: "ALG10" },
  { key: "NM_032836.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84922", geneDisplay: "FIZ1" },
  { key: "NM_032839.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84925", geneDisplay: "SLC49A4" },
  { key: "NM_032840.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84926", geneDisplay: "SPRYD3" },
  { key: "NM_032842.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84928", geneDisplay: "TMEM209" },
  { key: "NM_032843.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84929", geneDisplay: "FIBCD1" },
  { key: "NM_032846.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84932", geneDisplay: "RAB2B" },
  { key: "NM_032847.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84933", geneDisplay: "C8orf76" },
  { key: "NM_032848.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84934", geneDisplay: "RITA1" },
  { key: "NM_032849.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84935", geneDisplay: "MEDAG" },
  { key: "NM_032852.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84938", geneDisplay: "ATG4C" },
  { key: "NM_032854.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84940", geneDisplay: "CORO6" },
  { key: "NM_032856.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84942", geneDisplay: "WDR73" },
  { key: "NM_032857.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "114294", geneDisplay: "LACTB" },
  { key: "NM_032858.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84944", geneDisplay: "MAEL" },
  { key: "NM_032859.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "84945", geneDisplay: "ABHD13" },
  { key: "NM_032860.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84946", geneDisplay: "LTV1" },
  { key: "NM_032861.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84947", geneDisplay: "SERAC1" },
  { key: "NM_032862.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84948", geneDisplay: "TIGD5" },
  { key: "NM_032864.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84950", geneDisplay: "PRPF38A" },
  { key: "NM_032865.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84951", geneDisplay: "TNS4" },
  { key: "NM_032866.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84952", geneDisplay: "CGNL1" },
  { key: "NM_032869.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84955", geneDisplay: "NUDCD1" },
  { key: "NM_032870.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25957", geneDisplay: "PNISR" },
  { key: "NM_032873.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84959", geneDisplay: "UBASH3B" },
  { key: "NM_032875.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84961", geneDisplay: "FBXL20" },
  { key: "NM_032876.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84962", geneDisplay: "AJUBA" },
  { key: "NM_032878.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84964", geneDisplay: "ALKBH6" },
  { key: "NM_032880.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84966", geneDisplay: "IGSF21" },
  { key: "NM_032881.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84967", geneDisplay: "LSM10" },
  { key: "NM_032882.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84968", geneDisplay: "PNMA6A" },
  { key: "NM_032885.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84971", geneDisplay: "ATG4D" },
  { key: "NM_032888.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "85301", geneDisplay: "COL27A1" },
  { key: "NM_032889.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84975", geneDisplay: "MFSD5" },
  { key: "NM_032892.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84978", geneDisplay: "FRMD5" },
  { key: "NM_032898.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84984", geneDisplay: "CEP19" },
  { key: "NM_032900.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84986", geneDisplay: "ARHGAP19" },
  { key: "NM_032901.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84987", geneDisplay: "COX14" },
  { key: "NM_032905.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84991", geneDisplay: "RBM17" },
  { key: "NM_032906.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100996939", geneDisplay: "PYURF" },
  { key: "NM_032907.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84993", geneDisplay: "UBL7" },
  { key: "NM_032918.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "85004", geneDisplay: "RERG" },
  { key: "NM_032924.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7551", geneDisplay: "ZNF3" },
  { key: "NM_032926.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "85012", geneDisplay: "TCEAL3" },
  { key: "NM_032928.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "85014", geneDisplay: "TMEM141" },
  { key: "NM_032930.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85016", geneDisplay: "CFAP300" },
  { key: "NM_032932.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84440", geneDisplay: "RAB11FIP4" },
  { key: "NM_032933.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "85019", geneDisplay: "TMEM241" },
  { key: "NM_032935.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84560", geneDisplay: "MT4" },
  { key: "NM_032936.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "85025", geneDisplay: "TMEM60" },
  { key: "NM_032940.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5432", geneDisplay: "POLR2C" },
  { key: "NM_032947.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "85027", geneDisplay: "SMIM3" },
  { key: "NM_032951.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51085", geneDisplay: "MLXIPL" },
  { key: "NM_032959.7", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "246721", geneDisplay: "POLR2J2" },
  { key: "NM_032960.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9261", geneDisplay: "MAPKAPK2" },
  { key: "NM_032961.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57575", geneDisplay: "PCDH10" },
  { key: "NM_032963.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6358", geneDisplay: "CCL14" },
  { key: "NM_032965.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6359", geneDisplay: "CCL15" },
  { key: "NM_032968.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27328", geneDisplay: "PCDH11X" },
  { key: "NM_032970.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9117", geneDisplay: "SEC22C" },
  { key: "NM_032977.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "843", geneDisplay: "CASP10" },
  { key: "NM_032982.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "835", geneDisplay: "CASP2" },
  { key: "NM_032989.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "572", geneDisplay: "BAD" },
  { key: "NM_032998.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9191", geneDisplay: "DEDD" },
  { key: "NM_032999.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2969", geneDisplay: "GTF2I" },
  { key: "NM_033004.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22861", geneDisplay: "NLRP1" },
  { key: "NM_033022.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6229", geneDisplay: "RPS24" },
  { key: "NM_033023.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5154", geneDisplay: "PDGFA" },
  { key: "NM_033025.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "85360", geneDisplay: "SYDE1" },
  { key: "NM_033026.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27445", geneDisplay: "PCLO" },
  { key: "NM_033027.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64651", geneDisplay: "CSRNP1" },
  { key: "NM_033028.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "585", geneDisplay: "BBS4" },
  { key: "NM_033030.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "66037", geneDisplay: "BOLL" },
  { key: "NM_033031.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "85417", geneDisplay: "CCNB3" },
  { key: "NM_033032.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "728279", geneDisplay: "KRTAP2-2" },
  { key: "NM_033033.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3888", geneDisplay: "KRT82" },
  { key: "NM_033034.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85363", geneDisplay: "TRIM5" },
  { key: "NM_033035.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "85480", geneDisplay: "TSLP" },
  { key: "NM_033036.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "89792", geneDisplay: "GAL3ST3" },
  { key: "NM_033043.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93659", geneDisplay: "CGB5" },
  { key: "NM_033045.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3890", geneDisplay: "KRT84" },
  { key: "NM_033048.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "53336", geneDisplay: "CPXCR1" },
  { key: "NM_033049.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56667", geneDisplay: "MUC13" },
  { key: "NM_033050.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56670", geneDisplay: "SUCNR1" },
  { key: "NM_033051.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57864", geneDisplay: "SLC46A2" },
  { key: "NM_033053.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "63947", geneDisplay: "DMRTC1" },
  { key: "NM_033054.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64005", geneDisplay: "MYO1G" },
  { key: "NM_033055.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64645", geneDisplay: "MFSD14A" },
  { key: "NM_033057.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81697", geneDisplay: "OR2B2" },
  { key: "NM_033059.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "653240", geneDisplay: "KRTAP4-11" },
  { key: "NM_033061.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100132476", geneDisplay: "KRTAP4-7" },
  { key: "NM_033062.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85291", geneDisplay: "KRTAP4-2" },
  { key: "NM_033063.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4135", geneDisplay: "MAP6" },
  { key: "NM_033064.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "85300", geneDisplay: "ATCAY" },
  { key: "NM_033066.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "58538", geneDisplay: "MPP4" },
  { key: "NM_033067.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63948", geneDisplay: "DMRTB1" },
  { key: "NM_033068.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93650", geneDisplay: "ACP4" },
  { key: "NM_033070.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27440", geneDisplay: "HDHD5" },
  { key: "NM_033082.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84324", geneDisplay: "SARNP" },
  { key: "NM_033083.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "85403", geneDisplay: "EAF1" },
  { key: "NM_033085.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "89885", geneDisplay: "FATE1" },
  { key: "NM_033087.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "85365", geneDisplay: "ALG2" },
  { key: "NM_033088.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "85369", geneDisplay: "STRIP1" },
  { key: "NM_033089.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "85364", geneDisplay: "ZCCHC3" },
  { key: "NM_033091.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "89122", geneDisplay: "TRIM4" },
  { key: "NM_033100.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "92211", geneDisplay: "CDHR1" },
  { key: "NM_033101.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85329", geneDisplay: "LGALS12" },
  { key: "NM_033102.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "85414", geneDisplay: "SLC45A3" },
  { key: "NM_033103.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "85415", geneDisplay: "RHPN2" },
  { key: "NM_033105.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "85479", geneDisplay: "DNAJC5B" },
  { key: "NM_033106.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "85569", geneDisplay: "GALP" },
  { key: "NM_033107.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "85865", geneDisplay: "GTPBP10" },
  { key: "NM_033108.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "86614", geneDisplay: "HSFY1" },
  { key: "NM_033109.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "87178", geneDisplay: "PNPT1" },
  { key: "NM_033112.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "88745", geneDisplay: "RRP36" },
  { key: "NM_033113.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "89887", geneDisplay: "ZNF628" },
  { key: "NM_033114.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "85437", geneDisplay: "ZCRB1" },
  { key: "NM_033116.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91754", geneDisplay: "NEK9" },
  { key: "NM_033117.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92400", geneDisplay: "RBM18" },
  { key: "NM_033118.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "85366", geneDisplay: "MYLK2" },
  { key: "NM_033119.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "85407", geneDisplay: "NKD1" },
  { key: "NM_033120.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "85409", geneDisplay: "NKD2" },
  { key: "NM_033121.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "88455", geneDisplay: "ANKRD13A" },
  { key: "NM_033122.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "85438", geneDisplay: "CABS1" },
  { key: "NM_033123.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "89869", geneDisplay: "PLCZ1" },
  { key: "NM_033124.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "85478", geneDisplay: "CCDC65" },
  { key: "NM_033125.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85413", geneDisplay: "SLC22A16" },
  { key: "NM_033126.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "85481", geneDisplay: "PSKH2" },
  { key: "NM_033127.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89866", geneDisplay: "SEC16B" },
  { key: "NM_033129.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "85508", geneDisplay: "SCRT2" },
  { key: "NM_033130.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "89790", geneDisplay: "SIGLEC10" },
  { key: "NM_033131.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89780", geneDisplay: "WNT3A" },
  { key: "NM_033132.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "85416", geneDisplay: "ZIC5" },
  { key: "NM_033133.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1267", geneDisplay: "CNP" },
  { key: "NM_033138.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "800", geneDisplay: "CALD1" },
  { key: "NM_033159.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3373", geneDisplay: "HYAL1" },
  { key: "NM_033160.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26149", geneDisplay: "ZNF658" },
  { key: "NM_033161.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6836", geneDisplay: "SURF4" },
  { key: "NM_033163.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2253", geneDisplay: "FGF8" },
  { key: "NM_033176.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "644524", geneDisplay: "NKX2-4" },
  { key: "NM_033177.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7918", geneDisplay: "GPANK1" },
  { key: "NM_033179.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79339", geneDisplay: "OR51B4" },
  { key: "NM_033180.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79345", geneDisplay: "OR51B2" },
  { key: "NM_033182.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93611", geneDisplay: "FBXO44" },
  { key: "NM_033183.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94115", geneDisplay: "CGB8" },
  { key: "NM_033184.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85294", geneDisplay: "KRTAP2-4" },
  { key: "NM_033185.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85293", geneDisplay: "KRTAP3-3" },
  { key: "NM_033187.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85290", geneDisplay: "KRTAP4-3" },
  { key: "NM_033188.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85289", geneDisplay: "KRTAP4-5" },
  { key: "NM_033191.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85280", geneDisplay: "KRTAP9-4" },
  { key: "NM_033194.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "94086", geneDisplay: "HSPB9" },
  { key: "NM_033195.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "92483", geneDisplay: "LDHAL6B" },
  { key: "NM_033196.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91120", geneDisplay: "ZNF682" },
  { key: "NM_033197.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "92747", geneDisplay: "BPIFB1" },
  { key: "NM_033198.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "94005", geneDisplay: "PIGS" },
  { key: "NM_033199.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "90226", geneDisplay: "UCN2" },
  { key: "NM_033200.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91289", geneDisplay: "LMF2" },
  { key: "NM_033201.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "89927", geneDisplay: "BMERB1" },
  { key: "NM_033204.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94039", geneDisplay: "ZNF101" },
  { key: "NM_033207.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "93377", geneDisplay: "OPALIN" },
  { key: "NM_033208.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "91151", geneDisplay: "TIGD7" },
  { key: "NM_033211.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90355", geneDisplay: "MACIR" },
  { key: "NM_033212.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "92922", geneDisplay: "CCDC102A" },
  { key: "NM_033213.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93474", geneDisplay: "ZNF670" },
  { key: "NM_033214.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2712", geneDisplay: "GK2" },
  { key: "NM_033215.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "89801", geneDisplay: "PPP1R3F" },
  { key: "NM_033222.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11168", geneDisplay: "PSIP1" },
  { key: "NM_033223.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2567", geneDisplay: "GABRG3" },
  { key: "NM_033224.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5814", geneDisplay: "PURB" },
  { key: "NM_033225.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64478", geneDisplay: "CSMD1" },
  { key: "NM_033229.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "89870", geneDisplay: "TRIM15" },
  { key: "NM_033238.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5371", geneDisplay: "PML" },
  { key: "NM_033253.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "93034", geneDisplay: "NT5C1B" },
  { key: "NM_033255.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "94240", geneDisplay: "EPSTI1" },
  { key: "NM_033256.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94274", geneDisplay: "PPP1R14A" },
  { key: "NM_033257.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85359", geneDisplay: "DGCR6L" },
  { key: "NM_033258.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94235", geneDisplay: "GNG8" },
  { key: "NM_033259.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "94032", geneDisplay: "CAMK2N2" },
  { key: "NM_033260.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "94234", geneDisplay: "FOXQ1" },
  { key: "NM_033261.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "91734", geneDisplay: "IDI2" },
  { key: "NM_033266.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10595", geneDisplay: "ERN2" },
  { key: "NM_033267.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153572", geneDisplay: "IRX2" },
  { key: "NM_033272.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90134", geneDisplay: "KCNH7" },
  { key: "NM_033274.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8728", geneDisplay: "ADAM19" },
  { key: "NM_033276.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91419", geneDisplay: "ATP23" },
  { key: "NM_033277.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "90070", geneDisplay: "LACRT" },
  { key: "NM_033278.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10612", geneDisplay: "TRIM3" },
  { key: "NM_033280.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "90701", geneDisplay: "SEC11C" },
  { key: "NM_033281.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "92259", geneDisplay: "MRPS36" },
  { key: "NM_033282.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "94233", geneDisplay: "OPN4" },
  { key: "NM_033284.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "90665", geneDisplay: "TBL1Y" },
  { key: "NM_033285.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "94241", geneDisplay: "TP53INP1" },
  { key: "NM_033286.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90417", geneDisplay: "KNSTRN" },
  { key: "NM_033296.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "93621", geneDisplay: "MRFAP1" },
  { key: "NM_033305.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23230", geneDisplay: "VPS13A" },
  { key: "NM_033309.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84752", geneDisplay: "B3GNT9" },
  { key: "NM_033310.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50801", geneDisplay: "KCNK4" },
  { key: "NM_033315.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91608", geneDisplay: "RASL10B" },
  { key: "NM_033317.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93099", geneDisplay: "DMKN" },
  { key: "NM_033318.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91689", geneDisplay: "SMDT1" },
  { key: "NM_033328.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "93661", geneDisplay: "CAPZA3" },
  { key: "NM_033331.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8555", geneDisplay: "CDC14B" },
  { key: "NM_033334.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2649", geneDisplay: "NR6A1" },
  { key: "NM_033337.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "859", geneDisplay: "CAV3" },
  { key: "NM_033343.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89884", geneDisplay: "LHX4" },
  { key: "NM_033347.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10089", geneDisplay: "KCNK7" },
  { key: "NM_033362.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6183", geneDisplay: "MRPS12" },
  { key: "NM_033364.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "89876", geneDisplay: "CFAP91" },
  { key: "NM_033377.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114335", geneDisplay: "CGB1" },
  { key: "NM_033378.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114336", geneDisplay: "CGB2" },
  { key: "NM_033380.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1287", geneDisplay: "COL4A5" },
  { key: "NM_033386.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85377", geneDisplay: "MICALL1" },
  { key: "NM_033387.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286336", geneDisplay: "FAM78A" },
  { key: "NM_033388.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "89849", geneDisplay: "ATG16L2" },
  { key: "NM_033390.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85463", geneDisplay: "ZC3H12C" },
  { key: "NM_033394.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "85461", geneDisplay: "TANC1" },
  { key: "NM_033395.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85459", geneDisplay: "CEP295" },
  { key: "NM_033396.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85456", geneDisplay: "TNKS1BP1" },
  { key: "NM_033400.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "85446", geneDisplay: "ZFHX2" },
  { key: "NM_033401.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "85445", geneDisplay: "CNTNAP4" },
  { key: "NM_033402.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "85444", geneDisplay: "LRRCC1" },
  { key: "NM_033409.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "113278", geneDisplay: "SLC52A3" },
  { key: "NM_033411.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "112611", geneDisplay: "RWDD2A" },
  { key: "NM_033412.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92014", geneDisplay: "SLC25A51" },
  { key: "NM_033413.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "90506", geneDisplay: "LRRC46" },
  { key: "NM_033414.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90441", geneDisplay: "ZNF622" },
  { key: "NM_033416.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92856", geneDisplay: "IMP4" },
  { key: "NM_033417.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93323", geneDisplay: "HAUS8" },
  { key: "NM_033418.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92342", geneDisplay: "METTL18" },
  { key: "NM_033419.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "93210", geneDisplay: "PGAP3" },
  { key: "NM_033421.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "90203", geneDisplay: "SNX21" },
  { key: "NM_033423.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2999", geneDisplay: "GZMH" },
  { key: "NM_033426.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "85457", geneDisplay: "CIPC" },
  { key: "NM_033427.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83992", geneDisplay: "CTTNBP2" },
  { key: "NM_033428.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "90871", geneDisplay: "DMAC1" },
  { key: "NM_033429.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "91860", geneDisplay: "CALML4" },
  { key: "NM_033438.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89886", geneDisplay: "SLAMF9" },
  { key: "NM_033439.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "90865", geneDisplay: "IL33" },
  { key: "NM_033440.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "63036", geneDisplay: "CELA2A" },
  { key: "NM_033445.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92815", geneDisplay: "H2AW" },
  { key: "NM_033446.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "89853", geneDisplay: "MVB12B" },
  { key: "NM_033448.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "112802", geneDisplay: "KRT71" },
  { key: "NM_033449.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "89848", geneDisplay: "FCHSD1" },
  { key: "NM_033452.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91107", geneDisplay: "TRIM47" },
  { key: "NM_033453.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3704", geneDisplay: "ITPA" },
  { key: "NM_033467.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79258", geneDisplay: "MMEL1" },
  { key: "NM_033468.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113835", geneDisplay: "ZNF257" },
  { key: "NM_033480.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26268", geneDisplay: "FBXO9" },
  { key: "NM_033482.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "94026", geneDisplay: "POM121L2" },
  { key: "NM_033486.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "984", geneDisplay: "CDK11B" },
  { key: "NM_033504.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "113452", geneDisplay: "TMEM54" },
  { key: "NM_033505.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "85465", geneDisplay: "SELENOI" },
  { key: "NM_033506.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26261", geneDisplay: "FBXO24" },
  { key: "NM_033510.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "85455", geneDisplay: "DISP2" },
  { key: "NM_033512.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "85453", geneDisplay: "TSPYL5" },
  { key: "NM_033513.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91978", geneDisplay: "TPGS1" },
  { key: "NM_033515.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "93663", geneDisplay: "ARHGAP18" },
  { key: "NM_033518.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "92745", geneDisplay: "SLC38A5" },
  { key: "NM_033520.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64073", geneDisplay: "C19orf33" },
  { key: "NM_033540.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55669", geneDisplay: "MFN1" },
  { key: "NM_033542.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "90196", geneDisplay: "SYS1" },
  { key: "NM_033546.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "103910", geneDisplay: "MYL12B" },
  { key: "NM_033547.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "92105", geneDisplay: "INTS4" },
  { key: "NM_033549.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90933", geneDisplay: "TRIM41" },
  { key: "NM_033550.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "112858", geneDisplay: "TP53RK" },
  { key: "NM_033551.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23367", geneDisplay: "LARP1" },
  { key: "NM_033553.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2980", geneDisplay: "GUCA2A" },
  { key: "NM_033554.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3113", geneDisplay: "HLA-DPA1" },
  { key: "NM_033629.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11277", geneDisplay: "TREX1" },
  { key: "NM_033630.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51282", geneDisplay: "SCAND1" },
  { key: "NM_033637.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8945", geneDisplay: "BTRC" },
  { key: "NM_033641.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1288", geneDisplay: "COL4A6" },
  { key: "NM_033643.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25873", geneDisplay: "RPL36" },
  { key: "NM_033655.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79937", geneDisplay: "CNTNAP3" },
  { key: "NM_033656.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54014", geneDisplay: "BRWD1" },
  { key: "NM_052813.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64170", geneDisplay: "CARD9" },
  { key: "NM_052818.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "90634", geneDisplay: "N4BP2L1" },
  { key: "NM_052820.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7464", geneDisplay: "CORO2A" },
  { key: "NM_052831.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116843", geneDisplay: "SLC18B1" },
  { key: "NM_052832.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "115111", geneDisplay: "SLC26A7" },
  { key: "NM_052839.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "56666", geneDisplay: "PANX2" },
  { key: "NM_052840.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "60677", geneDisplay: "CELF6" },
  { key: "NM_052841.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81629", geneDisplay: "TSSK3" },
  { key: "NM_052844.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "89891", geneDisplay: "DYNC2I2" },
  { key: "NM_052845.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "326625", geneDisplay: "MMAB" },
  { key: "NM_052846.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "90187", geneDisplay: "EMILIN3" },
  { key: "NM_052847.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2788", geneDisplay: "GNG7" },
  { key: "NM_052848.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90324", geneDisplay: "CCDC97" },
  { key: "NM_052850.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90480", geneDisplay: "GADD45GIP1" },
  { key: "NM_052852.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90649", geneDisplay: "ZNF486" },
  { key: "NM_052853.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "90956", geneDisplay: "ADCK2" },
  { key: "NM_052854.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "90993", geneDisplay: "CREB3L1" },
  { key: "NM_052855.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91369", geneDisplay: "ANKRD40" },
  { key: "NM_052857.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91603", geneDisplay: "ZNF830" },
  { key: "NM_052858.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "91862", geneDisplay: "MARVELD3" },
  { key: "NM_052859.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "91869", geneDisplay: "RFT1" },
  { key: "NM_052860.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91975", geneDisplay: "ZNF300" },
  { key: "NM_052862.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92241", geneDisplay: "RCSD1" },
  { key: "NM_052863.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "92304", geneDisplay: "SCGB3A1" },
  { key: "NM_052864.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "92610", geneDisplay: "TIFA" },
  { key: "NM_052865.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "92667", geneDisplay: "MGME1" },
  { key: "NM_052867.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "259232", geneDisplay: "NALCN" },
  { key: "NM_052868.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93185", geneDisplay: "IGSF8" },
  { key: "NM_052872.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "112744", geneDisplay: "IL17F" },
  { key: "NM_052874.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112755", geneDisplay: "STX1B" },
  { key: "NM_052875.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "112936", geneDisplay: "VPS26B" },
  { key: "NM_052876.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "112939", geneDisplay: "NACC1" },
  { key: "NM_052879.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "113251", geneDisplay: "LARP4" },
  { key: "NM_052880.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "113791", geneDisplay: "PIK3IP1" },
  { key: "NM_052882.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114026", geneDisplay: "ZIM3" },
  { key: "NM_052883.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "114112", geneDisplay: "TXNRD3" },
  { key: "NM_052884.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114132", geneDisplay: "SIGLEC11" },
  { key: "NM_052885.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "114134", geneDisplay: "SLC2A13" },
  { key: "NM_052886.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114569", geneDisplay: "MAL2" },
  { key: "NM_052889.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114769", geneDisplay: "CARD16" },
  { key: "NM_052890.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114770", geneDisplay: "PGLYRP2" },
  { key: "NM_052891.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114771", geneDisplay: "PGLYRP3" },
  { key: "NM_052897.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "114785", geneDisplay: "MBD6" },
  { key: "NM_052898.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114786", geneDisplay: "XKR4" },
  { key: "NM_052899.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "114787", geneDisplay: "GPRIN1" },
  { key: "NM_052902.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114790", geneDisplay: "STK11IP" },
  { key: "NM_052903.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "114791", geneDisplay: "TUBGCP5" },
  { key: "NM_052904.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114792", geneDisplay: "KLHL32" },
  { key: "NM_052905.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114793", geneDisplay: "FMNL2" },
  { key: "NM_052906.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "114794", geneDisplay: "ELFN2" },
  { key: "NM_052909.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153478", geneDisplay: "PLEKHG4B" },
  { key: "NM_052911.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "114799", geneDisplay: "ESCO1" },
  { key: "NM_052916.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114804", geneDisplay: "RNF157" },
  { key: "NM_052917.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114805", geneDisplay: "GALNT13" },
  { key: "NM_052918.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "114815", geneDisplay: "SORCS1" },
  { key: "NM_052920.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114818", geneDisplay: "KLHL29" },
  { key: "NM_052923.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114821", geneDisplay: "ZBED9" },
  { key: "NM_052924.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114822", geneDisplay: "RHPN1" },
  { key: "NM_052925.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114823", geneDisplay: "LENG8" },
  { key: "NM_052928.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114826", geneDisplay: "SMYD4" },
  { key: "NM_052932.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114908", geneDisplay: "TMEM123" },
  { key: "NM_052933.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "114960", geneDisplay: "TSGA13" },
  { key: "NM_052934.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115019", geneDisplay: "SLC26A9" },
  { key: "NM_052935.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "115024", geneDisplay: "NT5C3B" },
  { key: "NM_052936.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "115201", geneDisplay: "ATG4A" },
  { key: "NM_052937.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "115294", geneDisplay: "PCMTD1" },
  { key: "NM_052938.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115350", geneDisplay: "FCRL1" },
  { key: "NM_052939.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115352", geneDisplay: "FCRL3" },
  { key: "NM_052941.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115361", geneDisplay: "GBP4" },
  { key: "NM_052942.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115362", geneDisplay: "GBP5" },
  { key: "NM_052943.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115572", geneDisplay: "TENT5B" },
  { key: "NM_052945.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "115650", geneDisplay: "TNFRSF13C" },
  { key: "NM_052947.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "115701", geneDisplay: "ALPK2" },
  { key: "NM_052950.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "115825", geneDisplay: "WDFY2" },
  { key: "NM_052951.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "116092", geneDisplay: "DNTTIP1" },
  { key: "NM_052953.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116135", geneDisplay: "LRRC3B" },
  { key: "NM_052955.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "116179", geneDisplay: "TGM7" },
  { key: "NM_052957.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "93953", geneDisplay: "GCNA" },
  { key: "NM_052958.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "116328", geneDisplay: "C8orf34" },
  { key: "NM_052959.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116337", geneDisplay: "PANX3" },
  { key: "NM_052960.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116362", geneDisplay: "RBP7" },
  { key: "NM_052961.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116369", geneDisplay: "SLC26A8" },
  { key: "NM_052962.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116379", geneDisplay: "IL22RA2" },
  { key: "NM_052963.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "116447", geneDisplay: "TOP1MT" },
  { key: "NM_052964.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "116449", geneDisplay: "CLNK" },
  { key: "NM_052965.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116461", geneDisplay: "TSEN15" },
  { key: "NM_052966.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116496", geneDisplay: "NIBAN1" },
  { key: "NM_052967.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116511", geneDisplay: "MAS1L" },
  { key: "NM_052969.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116832", geneDisplay: "RPL39L" },
  { key: "NM_052970.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "116835", geneDisplay: "HSPA12B" },
  { key: "NM_052971.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "116842", geneDisplay: "LEAP2" },
  { key: "NM_052972.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "116844", geneDisplay: "LRG1" },
  { key: "NM_052988.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8558", geneDisplay: "CDK10" },
  { key: "NM_052989.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55764", geneDisplay: "IFT122" },
  { key: "NM_052997.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "91074", geneDisplay: "ANKRD30A" },
  { key: "NM_052998.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "113451", geneDisplay: "AZIN2" },
  { key: "NM_052999.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "113540", geneDisplay: "CMTM1" },
  { key: "NM_053003.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "89858", geneDisplay: "SIGLEC12" },
  { key: "NM_053004.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "54584", geneDisplay: "GNB1L" },
  { key: "NM_053006.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23617", geneDisplay: "TSSK2" },
  { key: "NM_053013.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2027", geneDisplay: "ENO3" },
  { key: "NM_053017.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116969", geneDisplay: "ART5" },
  { key: "NM_053023.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80829", geneDisplay: "ZFP91" },
  { key: "NM_053024.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5217", geneDisplay: "PFN2" },
  { key: "NM_053025.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4638", geneDisplay: "MYLK" },
  { key: "NM_053035.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51650", geneDisplay: "MRPS33" },
  { key: "NM_053039.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54490", geneDisplay: "UGT2B28" },
  { key: "NM_053041.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149951", geneDisplay: "COMMD7" },
  { key: "NM_053042.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "85460", geneDisplay: "ZNF518B" },
  { key: "NM_053043.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155435", geneDisplay: "RBM33" },
  { key: "NM_053044.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "94031", geneDisplay: "HTRA3" },
  { key: "NM_053045.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "94107", geneDisplay: "TMEM203" },
  { key: "NM_053049.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "114131", geneDisplay: "UCN3" },
  { key: "NM_053050.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "116540", geneDisplay: "MRPL53" },
  { key: "NM_053051.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "116840", geneDisplay: "CNTROB" },
  { key: "NM_053052.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116841", geneDisplay: "SNAP47" },
  { key: "NM_053053.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "117143", geneDisplay: "TADA1" },
  { key: "NM_053054.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117144", geneDisplay: "CATSPER1" },
  { key: "NM_053055.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "117145", geneDisplay: "THEM4" },
  { key: "NM_053056.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "595", geneDisplay: "CCND1" },
  { key: "NM_053064.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "54331", geneDisplay: "GNG2" },
  { key: "NM_053274.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11146", geneDisplay: "GLMN" },
  { key: "NM_053276.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5212", geneDisplay: "VIT" },
  { key: "NM_053277.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54102", geneDisplay: "CLIC6" },
  { key: "NM_053278.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83551", geneDisplay: "TAAR8" },
  { key: "NM_053279.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83648", geneDisplay: "FAM167A" },
  { key: "NM_053280.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "113746", geneDisplay: "ODF3" },
  { key: "NM_053281.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "117154", geneDisplay: "DACH2" },
  { key: "NM_053282.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "117157", geneDisplay: "SH2D1B" },
  { key: "NM_053283.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "117159", geneDisplay: "DCD" },
  { key: "NM_053284.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "117166", geneDisplay: "WFIKKN1" },
  { key: "NM_053285.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83659", geneDisplay: "TEKT1" },
  { key: "NM_054012.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "445", geneDisplay: "ASS1" },
  { key: "NM_054016.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10772", geneDisplay: "SRSF10" },
  { key: "NM_054021.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "83550", geneDisplay: "GPR101" },
  { key: "NM_054023.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "117156", geneDisplay: "SCGB3A2" },
  { key: "NM_054025.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27087", geneDisplay: "B3GAT1" },
  { key: "NM_054027.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56172", geneDisplay: "ANKH" },
  { key: "NM_054028.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83650", geneDisplay: "SLC35G5" },
  { key: "NM_054030.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117194", geneDisplay: "MRGPRX2" },
  { key: "NM_054032.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117196", geneDisplay: "MRGPRX4" },
  { key: "NM_054105.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341416", geneDisplay: "OR6C2" },
  { key: "NM_054106.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "81050", geneDisplay: "OR5AC2" },
  { key: "NM_054107.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26740", geneDisplay: "OR1J2" },
  { key: "NM_054110.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "117248", geneDisplay: "GALNT15" },
  { key: "NM_054111.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "117283", geneDisplay: "IP6K3" },
  { key: "NM_054112.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "117285", geneDisplay: "DEFB118" },
  { key: "NM_054113.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "117286", geneDisplay: "CIB3" },
  { key: "NM_054114.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "117289", geneDisplay: "TAGAP" },
  { key: "NM_057088.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3850", geneDisplay: "KRT3" },
  { key: "NM_057091.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9048", geneDisplay: "ARTN" },
  { key: "NM_057093.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1412", geneDisplay: "CRYBA2" },
  { key: "NM_057095.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64816", geneDisplay: "CYP3A43" },
  { key: "NM_057161.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116138", geneDisplay: "KLHDC3" },
  { key: "NM_057168.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51384", geneDisplay: "WNT16" },
  { key: "NM_057169.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9815", geneDisplay: "GIT2" },
  { key: "NM_057175.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80155", geneDisplay: "NAA15" },
  { key: "NM_057176.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7809", geneDisplay: "BSND" },
  { key: "NM_057749.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9134", geneDisplay: "CCNE2" },
  { key: "NM_058004.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5297", geneDisplay: "PI4KA" },
  { key: "NM_058163.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90121", geneDisplay: "TSR2" },
  { key: "NM_058164.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93145", geneDisplay: "OLFM2" },
  { key: "NM_058165.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "116255", geneDisplay: "MOGAT1" },
  { key: "NM_058167.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "118424", geneDisplay: "UBE2J2" },
  { key: "NM_058169.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "118426", geneDisplay: "BORCS5" },
  { key: "NM_058170.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "118427", geneDisplay: "OLFM3" },
  { key: "NM_058172.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "118429", geneDisplay: "ANTXR2" },
  { key: "NM_058173.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "118430", geneDisplay: "MUCL1" },
  { key: "NM_058179.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "29968", geneDisplay: "PSAT1" },
  { key: "NM_058180.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54058", geneDisplay: "C21orf58" },
  { key: "NM_058182.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54065", geneDisplay: "SMIM11" },
  { key: "NM_058186.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54097", geneDisplay: "FAM3B" },
  { key: "NM_058187.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "59271", geneDisplay: "EVA1C" },
  { key: "NM_058190.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "85395", geneDisplay: "SLX9" },
  { key: "NM_058192.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "113000", geneDisplay: "RPUSD1" },
  { key: "NM_058201.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10407", geneDisplay: "SPAG11B" },
  { key: "NM_058216.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5889", geneDisplay: "RAD51C" },
  { key: "NM_058219.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "118460", geneDisplay: "EXOSC6" },
  { key: "NM_058222.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6975", geneDisplay: "TECTB" },
  { key: "NM_058229.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114907", geneDisplay: "FBXO32" },
  { key: "NM_058230.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "117608", geneDisplay: "ZNF354B" },
  { key: "NM_058237.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57718", geneDisplay: "PPP4R4" },
  { key: "NM_058238.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7477", geneDisplay: "WNT7B" },
  { key: "NM_058241.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "905", geneDisplay: "CCNT2" },
  { key: "NM_058246.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10049", geneDisplay: "DNAJB6" },
  { key: "NM_078468.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56647", geneDisplay: "BCCIP" },
  { key: "NM_078470.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1355", geneDisplay: "COX15" },
  { key: "NM_078471.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "399687", geneDisplay: "MYO18A" },
  { key: "NM_078473.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83877", geneDisplay: "TM2D2" },
  { key: "NM_078474.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80213", geneDisplay: "TM2D3" },
  { key: "NM_078480.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "22827", geneDisplay: "PUF60" },
  { key: "NM_078481.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "976", geneDisplay: "ADGRE5" },
  { key: "NM_078483.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "206358", geneDisplay: "SLC36A1" },
  { key: "NM_078626.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1031", geneDisplay: "CDKN2C" },
  { key: "NM_078629.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10943", geneDisplay: "MSL3" },
  { key: "NM_079420.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4632", geneDisplay: "MYL1" },
  { key: "NM_079834.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113178", geneDisplay: "SCAMP4" },
  { key: "NM_080283.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10350", geneDisplay: "ABCA9" },
  { key: "NM_080284.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23460", geneDisplay: "ABCA6" },
  { key: "NM_080385.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "93979", geneDisplay: "CPA5" },
  { key: "NM_080386.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "113457", geneDisplay: "TUBA3D" },
  { key: "NM_080387.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338339", geneDisplay: "CLEC4D" },
  { key: "NM_080388.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "140576", geneDisplay: "S100A16" },
  { key: "NM_080389.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "140596", geneDisplay: "DEFB104A" },
  { key: "NM_080390.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "140597", geneDisplay: "TCEAL2" },
  { key: "NM_080391.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8073", geneDisplay: "PTP4A2" },
  { key: "NM_080424.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3431", geneDisplay: "SP110" },
  { key: "NM_080429.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89872", geneDisplay: "AQP10" },
  { key: "NM_080430.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "140606", geneDisplay: "SELENOM" },
  { key: "NM_080431.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "140625", geneDisplay: "ACTRT2" },
  { key: "NM_080473.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140628", geneDisplay: "GATA5" },
  { key: "NM_080476.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128869", geneDisplay: "PIGU" },
  { key: "NM_080489.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "27111", geneDisplay: "SDCBP2" },
  { key: "NM_080491.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9846", geneDisplay: "GAB2" },
  { key: "NM_080546.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23446", geneDisplay: "SLC44A1" },
  { key: "NM_080552.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140679", geneDisplay: "SLC32A1" },
  { key: "NM_080574.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140683", geneDisplay: "BPIFA2" },
  { key: "NM_080594.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10921", geneDisplay: "RNPS1" },
  { key: "NM_080596.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85235", geneDisplay: "H2AC12" },
  { key: "NM_080597.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "114876", geneDisplay: "OSBPL1A" },
  { key: "NM_080603.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "90204", geneDisplay: "ZSWIM1" },
  { key: "NM_080605.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126792", geneDisplay: "B3GALT6" },
  { key: "NM_080606.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128408", geneDisplay: "BHLHE23" },
  { key: "NM_080607.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128434", geneDisplay: "VSTM2L" },
  { key: "NM_080608.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128497", geneDisplay: "SPATA25" },
  { key: "NM_080610.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128821", geneDisplay: "CST9L" },
  { key: "NM_080611.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128853", geneDisplay: "DUSP15" },
  { key: "NM_080614.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140686", geneDisplay: "WFDC3" },
  { key: "NM_080617.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140689", geneDisplay: "CBLN4" },
  { key: "NM_080621.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140700", geneDisplay: "SAMD10" },
  { key: "NM_080622.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140701", geneDisplay: "ABHD16B" },
  { key: "NM_080626.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "140707", geneDisplay: "BRI3BP" },
  { key: "NM_080627.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140710", geneDisplay: "SOGA1" },
  { key: "NM_080628.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140711", geneDisplay: "TLDC2" },
  { key: "NM_080632.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "65109", geneDisplay: "UPF3B" },
  { key: "NM_080650.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "89978", geneDisplay: "DPH6" },
  { key: "NM_080651.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "90390", geneDisplay: "MED30" },
  { key: "NM_080652.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "90407", geneDisplay: "TMEM41A" },
  { key: "NM_080655.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "91283", geneDisplay: "MSANTD3" },
  { key: "NM_080656.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91368", geneDisplay: "CDKN2AIPNL" },
  { key: "NM_080657.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91543", geneDisplay: "RSAD2" },
  { key: "NM_080658.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91703", geneDisplay: "ACY3" },
  { key: "NM_080659.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91894", geneDisplay: "C11orf52" },
  { key: "NM_080660.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "92092", geneDisplay: "ZC3HAV1L" },
  { key: "NM_080662.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92960", geneDisplay: "PEX11G" },
  { key: "NM_080664.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "112487", geneDisplay: "DTD2" },
  { key: "NM_080666.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "112840", geneDisplay: "WDR89" },
  { key: "NM_080667.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "112942", geneDisplay: "CFAP36" },
  { key: "NM_080668.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "113130", geneDisplay: "CDCA5" },
  { key: "NM_080669.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "113235", geneDisplay: "SLC46A1" },
  { key: "NM_080670.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "113829", geneDisplay: "SLC35A4" },
  { key: "NM_080671.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23704", geneDisplay: "KCNE4" },
  { key: "NM_080672.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "116154", geneDisplay: "PHACTR3" },
  { key: "NM_080675.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140732", geneDisplay: "SUN5" },
  { key: "NM_080677.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "140735", geneDisplay: "DYNLL2" },
  { key: "NM_080678.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "140739", geneDisplay: "UBE2F" },
  { key: "NM_080680.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1302", geneDisplay: "COL11A2" },
  { key: "NM_080683.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5783", geneDisplay: "PTPN13" },
  { key: "NM_080701.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11219", geneDisplay: "TREX2" },
  { key: "NM_080704.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7442", geneDisplay: "TRPV1" },
  { key: "NM_080720.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "83740", geneDisplay: "H2AB3" },
  { key: "NM_080721.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128506", geneDisplay: "OCSTAMP" },
  { key: "NM_080722.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "140766", geneDisplay: "ADAMTS14" },
  { key: "NM_080723.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "140767", geneDisplay: "NRSN1" },
  { key: "NM_080725.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140809", geneDisplay: "SRXN1" },
  { key: "NM_080732.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "112398", geneDisplay: "EGLN2" },
  { key: "NM_080740.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "129025", geneDisplay: "ZNF280A" },
  { key: "NM_080742.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135152", geneDisplay: "B3GAT2" },
  { key: "NM_080743.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135295", geneDisplay: "SRSF12" },
  { key: "NM_080744.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136853", geneDisplay: "SSC4D" },
  { key: "NM_080746.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "140801", geneDisplay: "RPL10L" },
  { key: "NM_080747.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "140807", geneDisplay: "KRT72" },
  { key: "NM_080748.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140823", geneDisplay: "ROMO1" },
  { key: "NM_080749.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140825", geneDisplay: "NEURL2" },
  { key: "NM_080751.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "117532", geneDisplay: "TMC2" },
  { key: "NM_080752.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140831", geneDisplay: "ZSWIM3" },
  { key: "NM_080753.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140832", geneDisplay: "WFDC10A" },
  { key: "NM_080759.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1602", geneDisplay: "DACH1" },
  { key: "NM_080764.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "140883", geneDisplay: "ZNF280B" },
  { key: "NM_080817.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27197", geneDisplay: "GPR82" },
  { key: "NM_080819.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27201", geneDisplay: "GPR78" },
  { key: "NM_080820.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "92675", geneDisplay: "DTD1" },
  { key: "NM_080821.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "116151", geneDisplay: "FAM210B" },
  { key: "NM_080822.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124641", geneDisplay: "OVCA2" },
  { key: "NM_080823.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6725", geneDisplay: "SRMS" },
  { key: "NM_080825.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128864", geneDisplay: "C20orf144" },
  { key: "NM_080826.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140862", geneDisplay: "ISM1" },
  { key: "NM_080827.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140870", geneDisplay: "WFDC6" },
  { key: "NM_080831.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140881", geneDisplay: "DEFB129" },
  { key: "NM_080832.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "140886", geneDisplay: "PABPC5" },
  { key: "NM_080833.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140893", geneDisplay: "RBBP8NL" },
  { key: "NM_080836.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140901", geneDisplay: "STK35" },
  { key: "NM_080859.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392392", geneDisplay: "OR1K1" },
  { key: "NM_080860.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "89765", geneDisplay: "RSPH1" },
  { key: "NM_080861.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "90864", geneDisplay: "SPSB3" },
  { key: "NM_080862.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "92369", geneDisplay: "SPSB4" },
  { key: "NM_080863.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92591", geneDisplay: "ASB16" },
  { key: "NM_080864.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "117579", geneDisplay: "RLN3" },
  { key: "NM_080865.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "118442", geneDisplay: "GPR62" },
  { key: "NM_080866.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114571", geneDisplay: "SLC22A9" },
  { key: "NM_080868.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127247", geneDisplay: "ASB17" },
  { key: "NM_080869.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128488", geneDisplay: "WFDC12" },
  { key: "NM_080870.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135656", geneDisplay: "MUCL3" },
  { key: "NM_080872.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137970", geneDisplay: "UNC5D" },
  { key: "NM_080873.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "140456", geneDisplay: "ASB11" },
  { key: "NM_080874.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "140458", geneDisplay: "ASB5" },
  { key: "NM_080876.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "142679", geneDisplay: "DUSP19" },
  { key: "NM_080878.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "142683", geneDisplay: "ITLN2" },
  { key: "NM_080879.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "142684", geneDisplay: "RAB40A" },
  { key: "NM_080911.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7374", geneDisplay: "UNG" },
  { key: "NM_080916.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1716", geneDisplay: "DGUOK" },
  { key: "NM_080927.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131566", geneDisplay: "DCBLD2" },
  { key: "NM_130384.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84126", geneDisplay: "ATRIP" },
  { key: "NM_130385.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10335", geneDisplay: "IRAG1" },
  { key: "NM_130386.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "81035", geneDisplay: "COLEC12" },
  { key: "NM_130388.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "142689", geneDisplay: "ASB12" },
  { key: "NM_130398.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9156", geneDisplay: "EXO1" },
  { key: "NM_130434.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54878", geneDisplay: "DPP8" },
  { key: "NM_130439.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4601", geneDisplay: "MXI1" },
  { key: "NM_130443.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10072", geneDisplay: "DPP3" },
  { key: "NM_130446.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "89857", geneDisplay: "KLHL6" },
  { key: "NM_130463.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "534", geneDisplay: "ATP6V1G2" },
  { key: "NM_130464.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23117", geneDisplay: "NPIPB3" },
  { key: "NM_130465.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26262", geneDisplay: "TSPAN17" },
  { key: "NM_130466.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "89910", geneDisplay: "UBE3B" },
  { key: "NM_130468.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "113189", geneDisplay: "CHST14" },
  { key: "NM_130759.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "170575", geneDisplay: "GIMAP1" },
  { key: "NM_130760.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8174", geneDisplay: "MADCAM1" },
  { key: "NM_130767.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134526", geneDisplay: "ACOT12" },
  { key: "NM_130768.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136991", geneDisplay: "ASZ1" },
  { key: "NM_130769.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "170589", geneDisplay: "GPHA2" },
  { key: "NM_130770.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "170572", geneDisplay: "HTR3C" },
  { key: "NM_130772.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "170591", geneDisplay: "S100Z" },
  { key: "NM_130777.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9502", geneDisplay: "XAGE2" },
  { key: "NM_130782.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64407", geneDisplay: "RGS18" },
  { key: "NM_130783.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "90139", geneDisplay: "TSPAN18" },
  { key: "NM_130786.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1", geneDisplay: "A1BG" },
  { key: "NM_130787.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "160", geneDisplay: "AP2A1" },
  { key: "NM_130794.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140880", geneDisplay: "CST11" },
  { key: "NM_130797.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1804", geneDisplay: "DPP6" },
  { key: "NM_130806.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122042", geneDisplay: "RXFP2" },
  { key: "NM_130807.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126308", geneDisplay: "MOB3A" },
  { key: "NM_130808.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131034", geneDisplay: "CPNE4" },
  { key: "NM_130809.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133619", geneDisplay: "PRRC1" },
  { key: "NM_130810.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161582", geneDisplay: "DNAAF4" },
  { key: "NM_130811.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6616", geneDisplay: "SNAP25" },
  { key: "NM_130830.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131578", geneDisplay: "LRRC15" },
  { key: "NM_130837.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4976", geneDisplay: "OPA1" },
  { key: "NM_130839.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7337", geneDisplay: "UBE3A" },
  { key: "NM_130847.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "154810", geneDisplay: "AMOTL1" },
  { key: "NM_130848.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "140947", geneDisplay: "DCANP1" },
  { key: "NM_130849.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55630", geneDisplay: "SLC39A4" },
  { key: "NM_130852.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51297", geneDisplay: "BPIFA1" },
  { key: "NM_130896.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "90199", geneDisplay: "WFDC8" },
  { key: "NM_130897.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "83657", geneDisplay: "DYNLRB2" },
  { key: "NM_130899.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153745", geneDisplay: "GARIN3" },
  { key: "NM_130900.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154064", geneDisplay: "RAET1L" },
  { key: "NM_130902.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "170712", geneDisplay: "COX7B2" },
  { key: "NM_130906.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "53938", geneDisplay: "PPIL3" },
  { key: "NM_133169.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126014", geneDisplay: "OSCAR" },
  { key: "NM_133171.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63916", geneDisplay: "ELMO2" },
  { key: "NM_133173.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10307", geneDisplay: "APBB3" },
  { key: "NM_133178.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10076", geneDisplay: "PTPRU" },
  { key: "NM_133179.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170626", geneDisplay: "XAGE3" },
  { key: "NM_133180.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54869", geneDisplay: "EPS8L1" },
  { key: "NM_133181.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79574", geneDisplay: "EPS8L3" },
  { key: "NM_133259.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10128", geneDisplay: "LRPPRC" },
  { key: "NM_133261.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126326", geneDisplay: "GIPC3" },
  { key: "NM_133263.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133522", geneDisplay: "PPARGC1B" },
  { key: "NM_133264.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147179", geneDisplay: "WIPF2" },
  { key: "NM_133267.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "170825", geneDisplay: "GSX2" },
  { key: "NM_133328.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162989", geneDisplay: "DEDD2" },
  { key: "NM_133329.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "170850", geneDisplay: "KCNG3" },
  { key: "NM_133338.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5884", geneDisplay: "RAD17" },
  { key: "NM_133367.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85315", geneDisplay: "PAQR8" },
  { key: "NM_133368.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "89970", geneDisplay: "RSPRY1" },
  { key: "NM_133369.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90249", geneDisplay: "UNC5A" },
  { key: "NM_133372.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "96459", geneDisplay: "FNIP1" },
  { key: "NM_133373.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "113026", geneDisplay: "PLCD3" },
  { key: "NM_133433.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "25836", geneDisplay: "NIPBL" },
  { key: "NM_133443.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84706", geneDisplay: "GPT2" },
  { key: "NM_133444.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "116115", geneDisplay: "ZNF526" },
  { key: "NM_133445.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "116443", geneDisplay: "GRIN3A" },
  { key: "NM_133448.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121256", geneDisplay: "TMEM132D" },
  { key: "NM_133450.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124401", geneDisplay: "ANKS3" },
  { key: "NM_133452.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125950", geneDisplay: "RAVER1" },
  { key: "NM_133455.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "129080", geneDisplay: "EMID1" },
  { key: "NM_133459.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147372", geneDisplay: "CCBE1" },
  { key: "NM_133460.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147686", geneDisplay: "ZNF418" },
  { key: "NM_133462.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151613", geneDisplay: "TTC14" },
  { key: "NM_133464.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158399", geneDisplay: "ZNF483" },
  { key: "NM_133465.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158405", geneDisplay: "KIAA1958" },
  { key: "NM_133466.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284406", geneDisplay: "ZFP82" },
  { key: "NM_133467.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163732", geneDisplay: "CITED4" },
  { key: "NM_133471.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "170954", geneDisplay: "PPP1R18" },
  { key: "NM_133473.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "170959", geneDisplay: "ZNF431" },
  { key: "NM_133474.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "170960", geneDisplay: "ZNF721" },
  { key: "NM_133477.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "171024", geneDisplay: "SYNPO2" },
  { key: "NM_133478.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57835", geneDisplay: "SLC4A5" },
  { key: "NM_133491.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "112483", geneDisplay: "SAT2" },
  { key: "NM_133492.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125981", geneDisplay: "ACER1" },
  { key: "NM_133493.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135228", geneDisplay: "CD109" },
  { key: "NM_133494.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "140609", geneDisplay: "NEK7" },
  { key: "NM_133496.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148867", geneDisplay: "SLC30A7" },
  { key: "NM_133497.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169522", geneDisplay: "KCNV2" },
  { key: "NM_133498.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "171169", geneDisplay: "SPACA4" },
  { key: "NM_133502.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10782", geneDisplay: "ZNF274" },
  { key: "NM_133510.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5890", geneDisplay: "RAD51B" },
  { key: "NM_133625.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6854", geneDisplay: "SYN2" },
  { key: "NM_133635.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23275", geneDisplay: "POFUT2" },
  { key: "NM_133636.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "113510", geneDisplay: "HELQ" },
  { key: "NM_133637.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165545", geneDisplay: "DQX1" },
  { key: "NM_133638.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "171019", geneDisplay: "ADAMTS19" },
  { key: "NM_133639.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "171177", geneDisplay: "RHOV" },
  { key: "NM_133640.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6837", geneDisplay: "MED22" },
  { key: "NM_133642.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9215", geneDisplay: "LARGE1" },
  { key: "NM_134261.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6095", geneDisplay: "RORA" },
  { key: "NM_134268.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114757", geneDisplay: "CYGB" },
  { key: "NM_134269.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6525", geneDisplay: "SMTN" },
  { key: "NM_134323.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6895", geneDisplay: "TARBP2" },
  { key: "NM_134424.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5893", geneDisplay: "RAD52" },
  { key: "NM_134441.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6019", geneDisplay: "RLN2" },
  { key: "NM_134444.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147945", geneDisplay: "NLRP4" },
  { key: "NM_138272.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "80739", geneDisplay: "MPIG6B" },
  { key: "NM_138278.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149428", geneDisplay: "BNIPL" },
  { key: "NM_138281.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1748", geneDisplay: "DLX4" },
  { key: "NM_138283.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128817", geneDisplay: "CSTL1" },
  { key: "NM_138285.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129401", geneDisplay: "NUP35" },
  { key: "NM_138286.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148213", geneDisplay: "ZNF681" },
  { key: "NM_138287.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151636", geneDisplay: "DTX3L" },
  { key: "NM_138288.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "171546", geneDisplay: "SPTSSA" },
  { key: "NM_138289.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139741", geneDisplay: "ACTRT1" },
  { key: "NM_138294.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "160065", geneDisplay: "PATE1" },
  { key: "NM_138295.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168507", geneDisplay: "PKD1L1" },
  { key: "NM_138296.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "171558", geneDisplay: "PTCRA" },
  { key: "NM_138300.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90780", geneDisplay: "PYGO2" },
  { key: "NM_138317.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "54207", geneDisplay: "KCNK10" },
  { key: "NM_138326.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130013", geneDisplay: "ACMSD" },
  { key: "NM_138327.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134864", geneDisplay: "TAAR1" },
  { key: "NM_138328.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162494", geneDisplay: "RHBDL3" },
  { key: "NM_138330.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "171392", geneDisplay: "ZNF675" },
  { key: "NM_138331.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122665", geneDisplay: "RNASE8" },
  { key: "NM_138333.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "116224", geneDisplay: "PABIR1" },
  { key: "NM_138335.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132789", geneDisplay: "GNPDA2" },
  { key: "NM_138337.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160364", geneDisplay: "CLEC12A" },
  { key: "NM_138340.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "171586", geneDisplay: "ABHD3" },
  { key: "NM_138346.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90231", geneDisplay: "KIAA2013" },
  { key: "NM_138347.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90233", geneDisplay: "ZNF551" },
  { key: "NM_138348.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90268", geneDisplay: "OTULIN" },
  { key: "NM_138349.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "90313", geneDisplay: "TP53I13" },
  { key: "NM_138352.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90378", geneDisplay: "SAMD1" },
  { key: "NM_138353.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90379", geneDisplay: "DCAF15" },
  { key: "NM_138355.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "90507", geneDisplay: "SCRN2" },
  { key: "NM_138357.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "90550", geneDisplay: "MCU" },
  { key: "NM_138358.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90580", geneDisplay: "TIMM29" },
  { key: "NM_138360.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90668", geneDisplay: "CARMIL3" },
  { key: "NM_138363.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "90799", geneDisplay: "CEP95" },
  { key: "NM_138364.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "90826", geneDisplay: "PRMT9" },
  { key: "NM_138367.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "90987", geneDisplay: "ZNF251" },
  { key: "NM_138368.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91056", geneDisplay: "AP5B1" },
  { key: "NM_138369.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91272", geneDisplay: "BOD1" },
  { key: "NM_138370.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91461", geneDisplay: "PKDCC" },
  { key: "NM_138371.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91523", geneDisplay: "PCED1B" },
  { key: "NM_138373.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91663", geneDisplay: "MYADM" },
  { key: "NM_138374.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91664", geneDisplay: "ZNF845" },
  { key: "NM_138376.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91875", geneDisplay: "TTC5" },
  { key: "NM_138378.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91893", geneDisplay: "FDXACB1" },
  { key: "NM_138379.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91937", geneDisplay: "TIMD4" },
  { key: "NM_138381.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "92106", geneDisplay: "OXNAD1" },
  { key: "NM_138382.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "92129", geneDisplay: "RIPPLY1" },
  { key: "NM_138383.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "92154", geneDisplay: "MTSS2" },
  { key: "NM_138384.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "92170", geneDisplay: "MTG1" },
  { key: "NM_138386.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "92345", geneDisplay: "NAF1" },
  { key: "NM_138387.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92579", geneDisplay: "G6PC3" },
  { key: "NM_138389.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "92689", geneDisplay: "FAM114A1" },
  { key: "NM_138391.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92703", geneDisplay: "TMEM183A" },
  { key: "NM_138392.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92799", geneDisplay: "SHKBP1" },
  { key: "NM_138393.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92840", geneDisplay: "REEP6" },
  { key: "NM_138394.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92906", geneDisplay: "HNRNPLL" },
  { key: "NM_138395.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92935", geneDisplay: "MARS2" },
  { key: "NM_138396.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "92979", geneDisplay: "MARCHF9" },
  { key: "NM_138400.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64434", geneDisplay: "NOM1" },
  { key: "NM_138401.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93343", geneDisplay: "MVB12A" },
  { key: "NM_138402.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "93349", geneDisplay: "SP140L" },
  { key: "NM_138403.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "93408", geneDisplay: "MYL10" },
  { key: "NM_138408.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "112495", geneDisplay: "GTF3C6" },
  { key: "NM_138409.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "112609", geneDisplay: "MRAP2" },
  { key: "NM_138410.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "112616", geneDisplay: "CMTM7" },
  { key: "NM_138413.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "112817", geneDisplay: "HOGA1" },
  { key: "NM_138414.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112869", geneDisplay: "SGF29" },
  { key: "NM_138415.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "112885", geneDisplay: "PHF21B" },
  { key: "NM_138417.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "112970", geneDisplay: "KTI12" },
  { key: "NM_138418.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84331", geneDisplay: "MCRIP2" },
  { key: "NM_138420.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "113146", geneDisplay: "AHNAK2" },
  { key: "NM_138421.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "113174", geneDisplay: "SAAL1" },
  { key: "NM_138422.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113179", geneDisplay: "ADAT3" },
  { key: "NM_138423.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "113201", geneDisplay: "GOLM2" },
  { key: "NM_138425.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "113246", geneDisplay: "C12orf57" },
  { key: "NM_138426.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "113263", geneDisplay: "GLCCI1" },
  { key: "NM_138428.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "113444", geneDisplay: "SMIM12" },
  { key: "NM_138431.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "113655", geneDisplay: "MFSD3" },
  { key: "NM_138433.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "113730", geneDisplay: "KLHDC7B" },
  { key: "NM_138435.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "113828", geneDisplay: "FAM83F" },
  { key: "NM_138439.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "114984", geneDisplay: "FLYWCH2" },
  { key: "NM_138440.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "114990", geneDisplay: "VASN" },
  { key: "NM_138441.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "115004", geneDisplay: "CGAS" },
  { key: "NM_138443.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "115106", geneDisplay: "HAUS1" },
  { key: "NM_138444.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "115207", geneDisplay: "KCTD12" },
  { key: "NM_138446.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "115416", geneDisplay: "MALSU1" },
  { key: "NM_138447.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "115509", geneDisplay: "ZNF689" },
  { key: "NM_138450.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "115761", geneDisplay: "ARL11" },
  { key: "NM_138453.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "115827", geneDisplay: "RAB3C" },
  { key: "NM_138454.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115861", geneDisplay: "NXNL1" },
  { key: "NM_138455.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "115908", geneDisplay: "CTHRC1" },
  { key: "NM_138456.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116071", geneDisplay: "BATF2" },
  { key: "NM_138458.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "116143", geneDisplay: "DNAAF10" },
  { key: "NM_138459.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116150", geneDisplay: "NUS1" },
  { key: "NM_138461.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116211", geneDisplay: "TM4SF19" },
  { key: "NM_138462.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "116225", geneDisplay: "ZMYND19" },
  { key: "NM_138463.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "116238", geneDisplay: "TLCD1" },
  { key: "NM_138465.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2738", geneDisplay: "GLI4" },
  { key: "NM_138466.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "116412", geneDisplay: "ZNF837" },
  { key: "NM_138467.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127253", geneDisplay: "TYW3" },
  { key: "NM_138471.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144097", geneDisplay: "SPINDOC" },
  { key: "NM_138473.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6667", geneDisplay: "SP1" },
  { key: "NM_138476.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145553", geneDisplay: "MDP1" },
  { key: "NM_138477.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "146059", geneDisplay: "CDAN1" },
  { key: "NM_138481.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150356", geneDisplay: "CHADL" },
  { key: "NM_138493.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154467", geneDisplay: "CCDC167" },
  { key: "NM_138494.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79027", geneDisplay: "ZNF655" },
  { key: "NM_138499.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "170394", geneDisplay: "PWWP2B" },
  { key: "NM_138501.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9524", geneDisplay: "TECR" },
  { key: "NM_138554.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7099", geneDisplay: "TLR4" },
  { key: "NM_138571.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135114", geneDisplay: "HINT3" },
  { key: "NM_138572.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "129685", geneDisplay: "TAF8" },
  { key: "NM_138573.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145957", geneDisplay: "NRG4" },
  { key: "NM_138574.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154150", geneDisplay: "HDGFL1" },
  { key: "NM_138576.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64919", geneDisplay: "BCL11B" },
  { key: "NM_138578.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "598", geneDisplay: "BCL2L1" },
  { key: "NM_138610.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9555", geneDisplay: "MACROH2A1" },
  { key: "NM_138615.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22907", geneDisplay: "DHX30" },
  { key: "NM_138619.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23163", geneDisplay: "GGA3" },
  { key: "NM_138621.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10018", geneDisplay: "BCL2L11" },
  { key: "NM_138636.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51311", geneDisplay: "TLR8" },
  { key: "NM_138638.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1073", geneDisplay: "CFL2" },
  { key: "NM_138639.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83596", geneDisplay: "BCL2L12" },
  { key: "NM_138689.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26472", geneDisplay: "PPP1R14B" },
  { key: "NM_138690.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "116444", geneDisplay: "GRIN3B" },
  { key: "NM_138691.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "117531", geneDisplay: "TMC1" },
  { key: "NM_138693.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136259", geneDisplay: "KLF14" },
  { key: "NM_138694.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5314", geneDisplay: "PKHD1" },
  { key: "NM_138697.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80835", geneDisplay: "TAS1R1" },
  { key: "NM_138699.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "93622", geneDisplay: "LOC93622" },
  { key: "NM_138701.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136647", geneDisplay: "MPLKIP" },
  { key: "NM_138702.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139081", geneDisplay: "MAGEC3" },
  { key: "NM_138703.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139599", geneDisplay: "MAGEE2" },
  { key: "NM_138704.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56160", geneDisplay: "NSMCE3" },
  { key: "NM_138705.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163688", geneDisplay: "CALML6" },
  { key: "NM_138706.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "192134", geneDisplay: "B3GNT6" },
  { key: "NM_138711.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5468", geneDisplay: "PPARG" },
  { key: "NM_138713.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10725", geneDisplay: "NFAT5" },
  { key: "NM_138715.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4481", geneDisplay: "MSR1" },
  { key: "NM_138723.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79370", geneDisplay: "BCL2L14" },
  { key: "NM_138733.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5232", geneDisplay: "PGK2" },
  { key: "NM_138761.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "581", geneDisplay: "BAX" },
  { key: "NM_138769.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "89941", geneDisplay: "RHOT2" },
  { key: "NM_138771.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "90693", geneDisplay: "CCDC126" },
  { key: "NM_138773.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91137", geneDisplay: "SLC25A46" },
  { key: "NM_138774.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91300", geneDisplay: "R3HDM4" },
  { key: "NM_138775.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91801", geneDisplay: "ALKBH8" },
  { key: "NM_138777.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92399", geneDisplay: "MRRF" },
  { key: "NM_138778.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92715", geneDisplay: "DPH7" },
  { key: "NM_138779.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "93081", geneDisplay: "TEX30" },
  { key: "NM_138780.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "94122", geneDisplay: "SYTL5" },
  { key: "NM_138782.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "115548", geneDisplay: "FCHO2" },
  { key: "NM_138783.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115950", geneDisplay: "ZNF653" },
  { key: "NM_138785.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116254", geneDisplay: "GINM1" },
  { key: "NM_138786.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116441", geneDisplay: "TM4SF18" },
  { key: "NM_138787.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119710", geneDisplay: "IFTAP" },
  { key: "NM_138788.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120224", geneDisplay: "TMEM45B" },
  { key: "NM_138789.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120379", geneDisplay: "PIH1D2" },
  { key: "NM_138790.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122618", geneDisplay: "PLD4" },
  { key: "NM_138792.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123169", geneDisplay: "LEO1" },
  { key: "NM_138794.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127018", geneDisplay: "LYPLAL1" },
  { key: "NM_138795.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127829", geneDisplay: "ARL8A" },
  { key: "NM_138796.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128153", geneDisplay: "SPATA17" },
  { key: "NM_138797.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "129138", geneDisplay: "ANKRD54" },
  { key: "NM_138798.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129531", geneDisplay: "MITD1" },
  { key: "NM_138799.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129642", geneDisplay: "MBOAT2" },
  { key: "NM_138800.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129868", geneDisplay: "TRIM43" },
  { key: "NM_138801.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130589", geneDisplay: "GALM" },
  { key: "NM_138802.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130617", geneDisplay: "ZFAND2B" },
  { key: "NM_138803.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130940", geneDisplay: "CCDC148" },
  { key: "NM_138805.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131177", geneDisplay: "FAM3D" },
  { key: "NM_138806.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131450", geneDisplay: "CD200R1" },
  { key: "NM_138809.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134147", geneDisplay: "CMBL" },
  { key: "NM_138811.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136895", geneDisplay: "C7orf31" },
  { key: "NM_138813.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148229", geneDisplay: "ATP8B3" },
  { key: "NM_138814.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150379", geneDisplay: "PNPLA5" },
  { key: "NM_138815.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151871", geneDisplay: "DPPA2" },
  { key: "NM_138817.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157724", geneDisplay: "SLC7A13" },
  { key: "NM_138820.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "192286", geneDisplay: "HIGD2A" },
  { key: "NM_138927.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6651", geneDisplay: "SON" },
  { key: "NM_138959.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81839", geneDisplay: "VANGL1" },
  { key: "NM_138960.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90316", geneDisplay: "TGIF2LX" },
  { key: "NM_138961.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "90952", geneDisplay: "ESAM" },
  { key: "NM_138962.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124540", geneDisplay: "MSI2" },
  { key: "NM_138964.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10887", geneDisplay: "PROKR1" },
  { key: "NM_138966.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "81832", geneDisplay: "NETO1" },
  { key: "NM_138967.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "192683", geneDisplay: "SCAMP5" },
  { key: "NM_138969.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "195814", geneDisplay: "SDR16C5" },
  { key: "NM_138982.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5602", geneDisplay: "MAPK10" },
  { key: "NM_138983.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "116448", geneDisplay: "OLIG1" },
  { key: "NM_138995.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "140469", geneDisplay: "MYO3B" },
  { key: "NM_139012.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1432", geneDisplay: "MAPK14" },
  { key: "NM_139015.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121665", geneDisplay: "SPPL3" },
  { key: "NM_139017.7", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133396", geneDisplay: "IL31RA" },
  { key: "NM_139018.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146722", geneDisplay: "CD300LF" },
  { key: "NM_139021.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "225689", geneDisplay: "MAPK15" },
  { key: "NM_139022.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10077", geneDisplay: "TSPAN32" },
  { key: "NM_139027.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11093", geneDisplay: "ADAMTS13" },
  { key: "NM_139055.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "170689", geneDisplay: "ADAMTS15" },
  { key: "NM_139056.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "170690", geneDisplay: "ADAMTS16" },
  { key: "NM_139057.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "170691", geneDisplay: "ADAMTS17" },
  { key: "NM_139058.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170302", geneDisplay: "ARX" },
  { key: "NM_139072.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92737", geneDisplay: "DNER" },
  { key: "NM_139073.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130560", geneDisplay: "SPATA3" },
  { key: "NM_139074.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140850", geneDisplay: "DEFB127" },
  { key: "NM_139075.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219931", geneDisplay: "TPCN2" },
  { key: "NM_139076.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84142", geneDisplay: "ABRAXAS1" },
  { key: "NM_139119.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55249", geneDisplay: "YY1AP1" },
  { key: "NM_139125.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5648", geneDisplay: "MASP1" },
  { key: "NM_139126.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85313", geneDisplay: "PPIL4" },
  { key: "NM_139137.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3747", geneDisplay: "KCNC2" },
  { key: "NM_139159.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91039", geneDisplay: "DPP9" },
  { key: "NM_139161.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92359", geneDisplay: "CRB3" },
  { key: "NM_139162.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125170", geneDisplay: "MIEF2" },
  { key: "NM_139164.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134429", geneDisplay: "STARD4" },
  { key: "NM_139166.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137735", geneDisplay: "ABRA" },
  { key: "NM_139167.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137868", geneDisplay: "SGCZ" },
  { key: "NM_139169.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "142940", geneDisplay: "TRUB1" },
  { key: "NM_139170.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146562", geneDisplay: "DNAAF8" },
  { key: "NM_139171.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147323", geneDisplay: "STARD6" },
  { key: "NM_139172.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147744", geneDisplay: "TMEM190" },
  { key: "NM_139173.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "150159", geneDisplay: "SLC9B1" },
  { key: "NM_139175.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168433", geneDisplay: "RNF133" },
  { key: "NM_139177.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201266", geneDisplay: "SLC39A11" },
  { key: "NM_139178.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "221120", geneDisplay: "ALKBH3" },
  { key: "NM_139179.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221955", geneDisplay: "DAGLB" },
  { key: "NM_139199.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10902", geneDisplay: "BRD8" },
  { key: "NM_139209.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131890", geneDisplay: "GRK7" },
  { key: "NM_139214.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "90655", geneDisplay: "TGIF2LY" },
  { key: "NM_139215.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8148", geneDisplay: "TAF15" },
  { key: "NM_139239.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84807", geneDisplay: "NFKBID" },
  { key: "NM_139240.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92346", geneDisplay: "C1orf105" },
  { key: "NM_139242.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123263", geneDisplay: "MTFMT" },
  { key: "NM_139243.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132612", geneDisplay: "ADAD1" },
  { key: "NM_139245.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151742", geneDisplay: "PPM1L" },
  { key: "NM_139246.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158427", geneDisplay: "TSTD2" },
  { key: "NM_139248.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200879", geneDisplay: "LIPH" },
  { key: "NM_139249.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "245802", geneDisplay: "MS4A6E" },
  { key: "NM_139250.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "246100", geneDisplay: "CTAG1A" },
  { key: "NM_139265.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "30844", geneDisplay: "EHD4" },
  { key: "NM_139276.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6774", geneDisplay: "STAT3" },
  { key: "NM_139278.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203190", geneDisplay: "LGI3" },
  { key: "NM_139279.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90411", geneDisplay: "MCFD2" },
  { key: "NM_139280.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "94103", geneDisplay: "ORMDL3" },
  { key: "NM_139281.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134430", geneDisplay: "WDR36" },
  { key: "NM_139282.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158800", geneDisplay: "RHOXF1" },
  { key: "NM_139283.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160760", geneDisplay: "PPTC7" },
  { key: "NM_139284.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163175", geneDisplay: "LGI4" },
  { key: "NM_139285.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "246176", geneDisplay: "GAS2L2" },
  { key: "NM_139286.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "246184", geneDisplay: "CDC26" },
  { key: "NM_139314.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51129", geneDisplay: "ANGPTL4" },
  { key: "NM_139315.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6878", geneDisplay: "TAF6" },
  { key: "NM_139317.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79444", geneDisplay: "BIRC7" },
  { key: "NM_139318.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "27133", geneDisplay: "KCNH5" },
  { key: "NM_139319.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "246213", geneDisplay: "SLC17A8" },
  { key: "NM_139320.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "89832", geneDisplay: "CHRFAM7A" },
  { key: "NM_139321.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8455", geneDisplay: "ATRN" },
  { key: "NM_139323.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7529", geneDisplay: "YWHAB" },
  { key: "NM_139343.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "274", geneDisplay: "BIN1" },
  { key: "NM_139355.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4145", geneDisplay: "MATK" },
  { key: "NM_144498.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9885", geneDisplay: "OSBPL2" },
  { key: "NM_144499.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2779", geneDisplay: "GNAT1" },
  { key: "NM_144508.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57082", geneDisplay: "KNL1" },
  { key: "NM_144563.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22934", geneDisplay: "RPIA" },
  { key: "NM_144564.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29985", geneDisplay: "SLC39A3" },
  { key: "NM_144566.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90592", geneDisplay: "ZNF700" },
  { key: "NM_144567.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90806", geneDisplay: "ANGEL2" },
  { key: "NM_144568.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90809", geneDisplay: "PIP4P1" },
  { key: "NM_144569.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90853", geneDisplay: "SPOCD1" },
  { key: "NM_144570.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "90861", geneDisplay: "JPT2" },
  { key: "NM_144571.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "246175", geneDisplay: "CNOT6L" },
  { key: "NM_144572.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23102", geneDisplay: "TBC1D2B" },
  { key: "NM_144573.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91624", geneDisplay: "NEXN" },
  { key: "NM_144574.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91833", geneDisplay: "WDR20" },
  { key: "NM_144575.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92291", geneDisplay: "CAPN13" },
  { key: "NM_144576.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "93058", geneDisplay: "COQ10A" },
  { key: "NM_144578.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "93487", geneDisplay: "MAPK1IP1L" },
  { key: "NM_144579.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "94097", geneDisplay: "SFXN5" },
  { key: "NM_144580.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "112770", geneDisplay: "GLMP" },
  { key: "NM_144581.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "112849", geneDisplay: "L3HYPDH" },
  { key: "NM_144582.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "113419", geneDisplay: "TEX261" },
  { key: "NM_144585.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116085", geneDisplay: "SLC22A12" },
  { key: "NM_144586.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "116372", geneDisplay: "LYPD1" },
  { key: "NM_144587.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118663", geneDisplay: "BTBD16" },
  { key: "NM_144589.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118881", geneDisplay: "COMTD1" },
  { key: "NM_144590.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118932", geneDisplay: "ANKRD22" },
  { key: "NM_144593.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121268", geneDisplay: "RHEBL1" },
  { key: "NM_144594.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121355", geneDisplay: "GTSF1" },
  { key: "NM_144596.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123016", geneDisplay: "TTC8" },
  { key: "NM_144597.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123207", geneDisplay: "C15orf40" },
  { key: "NM_144598.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123355", geneDisplay: "LRRC28" },
  { key: "NM_144599.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123606", geneDisplay: "NIPA1" },
  { key: "NM_144600.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123811", geneDisplay: "CEP20" },
  { key: "NM_144602.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123970", geneDisplay: "C16orf78" },
  { key: "NM_144604.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124245", geneDisplay: "ZC3H18" },
  { key: "NM_144605.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124404", geneDisplay: "SEPTIN12" },
  { key: "NM_144607.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124637", geneDisplay: "CYB5D1" },
  { key: "NM_144609.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124808", geneDisplay: "CCDC43" },
  { key: "NM_144611.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124936", geneDisplay: "CYB5D2" },
  { key: "NM_144613.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125965", geneDisplay: "COX6B2" },
  { key: "NM_144614.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125997", geneDisplay: "MBD3L2" },
  { key: "NM_144616.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126306", geneDisplay: "JSRP1" },
  { key: "NM_144617.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126393", geneDisplay: "HSPB6" },
  { key: "NM_144618.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126626", geneDisplay: "GABPB2" },
  { key: "NM_144620.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127495", geneDisplay: "LRRC39" },
  { key: "NM_144622.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127579", geneDisplay: "DCST2" },
  { key: "NM_144626.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128218", geneDisplay: "TMEM125" },
  { key: "NM_144628.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128637", geneDisplay: "TBC1D20" },
  { key: "NM_144629.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130132", geneDisplay: "RFTN2" },
  { key: "NM_144631.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130557", geneDisplay: "ZNF513" },
  { key: "NM_144632.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130827", geneDisplay: "TMEM182" },
  { key: "NM_144633.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131096", geneDisplay: "KCNH8" },
  { key: "NM_144634.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131375", geneDisplay: "LYZL4" },
  { key: "NM_144635.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131408", geneDisplay: "FAM131A" },
  { key: "NM_144638.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131616", geneDisplay: "TMEM42" },
  { key: "NM_144639.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131669", geneDisplay: "UROC1" },
  { key: "NM_144641.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132160", geneDisplay: "PPM1M" },
  { key: "NM_144643.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132320", geneDisplay: "SCLT1" },
  { key: "NM_144644.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132851", geneDisplay: "SPATA4" },
  { key: "NM_144645.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132989", geneDisplay: "C4orf36" },
  { key: "NM_144646.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3512", geneDisplay: "JCHAIN" },
  { key: "NM_144648.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136332", geneDisplay: "LRGUK" },
  { key: "NM_144650.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137872", geneDisplay: "ADHFE1" },
  { key: "NM_144651.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137902", geneDisplay: "PXDNL" },
  { key: "NM_144653.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138151", geneDisplay: "NACC2" },
  { key: "NM_144658.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139818", geneDisplay: "DOCK11" },
  { key: "NM_144659.7", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "140290", geneDisplay: "TCP10L" },
  { key: "NM_144664.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143684", geneDisplay: "FAM76B" },
  { key: "NM_144665.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143686", geneDisplay: "SESN3" },
  { key: "NM_144666.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144132", geneDisplay: "DNHD1" },
  { key: "NM_144668.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144406", geneDisplay: "CFAP251" },
  { key: "NM_144669.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144423", geneDisplay: "GLT1D1" },
  { key: "NM_144670.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144568", geneDisplay: "A2ML1" },
  { key: "NM_144671.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144717", geneDisplay: "PHETA1" },
  { key: "NM_144672.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146183", geneDisplay: "OTOA" },
  { key: "NM_144673.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146225", geneDisplay: "CMTM2" },
  { key: "NM_144674.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146279", geneDisplay: "TEKT5" },
  { key: "NM_144676.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146456", geneDisplay: "TMED6" },
  { key: "NM_144681.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146849", geneDisplay: "CCDC42" },
  { key: "NM_144682.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146857", geneDisplay: "SLFN13" },
  { key: "NM_144683.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147015", geneDisplay: "DHRS13" },
  { key: "NM_144684.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147657", geneDisplay: "ZNF480" },
  { key: "NM_144685.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147746", geneDisplay: "HIPK4" },
  { key: "NM_144686.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147798", geneDisplay: "TMC4" },
  { key: "NM_144687.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91662", geneDisplay: "NLRP12" },
  { key: "NM_144688.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147872", geneDisplay: "KASH5" },
  { key: "NM_144689.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147923", geneDisplay: "ZNF420" },
  { key: "NM_144691.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147968", geneDisplay: "CAPN12" },
  { key: "NM_144693.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148156", geneDisplay: "ZNF558" },
  { key: "NM_144694.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148268", geneDisplay: "ZNF570" },
  { key: "NM_144695.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148362", geneDisplay: "BROX" },
  { key: "NM_144696.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126859", geneDisplay: "AXDND1" },
  { key: "NM_144697.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148523", geneDisplay: "CIART" },
  { key: "NM_144698.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148741", geneDisplay: "ANKRD35" },
  { key: "NM_144699.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "480", geneDisplay: "ATP1A4" },
  { key: "NM_144701.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149233", geneDisplay: "IL23R" },
  { key: "NM_144702.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149499", geneDisplay: "LRRC71" },
  { key: "NM_144703.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149986", geneDisplay: "LSM14B" },
  { key: "NM_144705.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150483", geneDisplay: "TEKT4" },
  { key: "NM_144706.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150590", geneDisplay: "C2orf15" },
  { key: "NM_144709.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150962", geneDisplay: "PUS10" },
  { key: "NM_144710.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151011", geneDisplay: "SEPTIN10" },
  { key: "NM_144711.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151230", geneDisplay: "KLHL23" },
  { key: "NM_144715.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151651", geneDisplay: "EFHB" },
  { key: "NM_144717.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "53833", geneDisplay: "IL20RB" },
  { key: "NM_144718.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152185", geneDisplay: "SPICE1" },
  { key: "NM_144719.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152206", geneDisplay: "CCDC13" },
  { key: "NM_144721.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152815", geneDisplay: "THAP6" },
  { key: "NM_144723.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153527", geneDisplay: "ZMAT2" },
  { key: "NM_144725.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153657", geneDisplay: "TTC23L" },
  { key: "NM_144727.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155051", geneDisplay: "CRYGN" },
  { key: "NM_144736.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55471", geneDisplay: "NDUFAF7" },
  { key: "NM_144770.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54033", geneDisplay: "RBM11" },
  { key: "NM_144772.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128240", geneDisplay: "NAXE" },
  { key: "NM_144773.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128674", geneDisplay: "PROKR2" },
  { key: "NM_144775.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "140775", geneDisplay: "SMCR8" },
  { key: "NM_144777.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8796", geneDisplay: "SCEL" },
  { key: "NM_144949.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9655", geneDisplay: "SOCS5" },
  { key: "NM_144962.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157310", geneDisplay: "PEBP4" },
  { key: "NM_144963.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157769", geneDisplay: "FAM91A1" },
  { key: "NM_144964.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158234", geneDisplay: "TRMT10B" },
  { key: "NM_144965.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158248", geneDisplay: "TTC16" },
  { key: "NM_144967.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158763", geneDisplay: "ARHGAP36" },
  { key: "NM_144969.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158866", geneDisplay: "ZDHHC15" },
  { key: "NM_144970.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "159013", geneDisplay: "CXorf38" },
  { key: "NM_144972.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "160287", geneDisplay: "LDHAL6A" },
  { key: "NM_144973.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160518", geneDisplay: "DENND5B" },
  { key: "NM_144974.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "160857", geneDisplay: "CCDC122" },
  { key: "NM_144975.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162394", geneDisplay: "SLFN5" },
  { key: "NM_144976.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163050", geneDisplay: "ZNF564" },
  { key: "NM_144978.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165055", geneDisplay: "CCDC138" },
  { key: "NM_144979.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166863", geneDisplay: "RBM46" },
  { key: "NM_144980.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "168090", geneDisplay: "C6orf118" },
  { key: "NM_144982.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196441", geneDisplay: "ZFC3H1" },
  { key: "NM_144985.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64403", geneDisplay: "CDH24" },
  { key: "NM_144988.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199857", geneDisplay: "ALG14" },
  { key: "NM_144990.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200172", geneDisplay: "SLFNL1" },
  { key: "NM_144991.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54084", geneDisplay: "TSPEAR" },
  { key: "NM_144992.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200403", geneDisplay: "VWA3B" },
  { key: "NM_144994.8", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200539", geneDisplay: "ANKRD23" },
  { key: "NM_144997.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201163", geneDisplay: "FLCN" },
  { key: "NM_144999.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201255", geneDisplay: "LRRC45" },
  { key: "NM_145000.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202151", geneDisplay: "RANBP3L" },
  { key: "NM_145003.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203062", geneDisplay: "TSNARE1" },
  { key: "NM_145004.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203102", geneDisplay: "ADAM32" },
  { key: "NM_145006.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203328", geneDisplay: "SUSD3" },
  { key: "NM_145008.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219539", geneDisplay: "YPEL4" },
  { key: "NM_145010.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219670", geneDisplay: "ENKUR" },
  { key: "NM_145011.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219749", geneDisplay: "ZNF25" },
  { key: "NM_145012.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219771", geneDisplay: "CCNY" },
  { key: "NM_145015.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116535", geneDisplay: "MRGPRF" },
  { key: "NM_145016.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219970", geneDisplay: "GLYATL2" },
  { key: "NM_145017.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220004", geneDisplay: "PPP1R32" },
  { key: "NM_145018.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220042", geneDisplay: "DDIAS" },
  { key: "NM_145020.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "220136", geneDisplay: "CFAP53" },
  { key: "NM_145027.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221458", geneDisplay: "KIF6" },
  { key: "NM_145030.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221908", geneDisplay: "PPP1R35" },
  { key: "NM_145037.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "91775", geneDisplay: "NXPE3" },
  { key: "NM_145038.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92749", geneDisplay: "DRC1" },
  { key: "NM_145040.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "112464", geneDisplay: "CAVIN3" },
  { key: "NM_145041.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "113277", geneDisplay: "TMEM106A" },
  { key: "NM_145043.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "252969", geneDisplay: "NEIL2" },
  { key: "NM_145045.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115948", geneDisplay: "ODAD3" },
  { key: "NM_145046.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125972", geneDisplay: "CALR3" },
  { key: "NM_145047.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127700", geneDisplay: "OSCP1" },
  { key: "NM_145049.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134510", geneDisplay: "UBLCP1" },
  { key: "NM_145052.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139596", geneDisplay: "UPRT" },
  { key: "NM_145053.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143630", geneDisplay: "UBQLNL" },
  { key: "NM_145054.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146845", geneDisplay: "CFAP52" },
  { key: "NM_145055.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147339", geneDisplay: "C18orf25" },
  { key: "NM_145056.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147906", geneDisplay: "DACT3" },
  { key: "NM_145057.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148170", geneDisplay: "CDC42EP5" },
  { key: "NM_145058.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196383", geneDisplay: "RILPL2" },
  { key: "NM_145059.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197258", geneDisplay: "FCSK" },
  { key: "NM_145060.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "220134", geneDisplay: "SKA1" },
  { key: "NM_145061.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "221150", geneDisplay: "SKA3" },
  { key: "NM_145062.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221302", geneDisplay: "ZUP1" },
  { key: "NM_145064.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "246329", geneDisplay: "STAC3" },
  { key: "NM_145065.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "246330", geneDisplay: "PELI3" },
  { key: "NM_145068.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162514", geneDisplay: "TRPV3" },
  { key: "NM_145071.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1154", geneDisplay: "CISH" },
  { key: "NM_145080.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197370", geneDisplay: "NSMCE1" },
  { key: "NM_145102.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23660", geneDisplay: "ZKSCAN5" },
  { key: "NM_145109.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5606", geneDisplay: "MAP2K3" },
  { key: "NM_145111.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221786", geneDisplay: "FAM200A" },
  { key: "NM_145115.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221785", geneDisplay: "ZSCAN25" },
  { key: "NM_145117.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "89797", geneDisplay: "NAV2" },
  { key: "NM_145160.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5607", geneDisplay: "MAP2K5" },
  { key: "NM_145166.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "92999", geneDisplay: "ZBTB47" },
  { key: "NM_145167.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93183", geneDisplay: "PIGM" },
  { key: "NM_145168.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "93517", geneDisplay: "SDR42E1" },
  { key: "NM_145169.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "113402", geneDisplay: "SFT2D1" },
  { key: "NM_145171.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122876", geneDisplay: "GPHB5" },
  { key: "NM_145172.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126820", geneDisplay: "DNAI3" },
  { key: "NM_145173.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148252", geneDisplay: "DIRAS1" },
  { key: "NM_145174.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150353", geneDisplay: "DNAJB7" },
  { key: "NM_145175.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151354", geneDisplay: "LRATD1" },
  { key: "NM_145176.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154091", geneDisplay: "SLC2A12" },
  { key: "NM_145178.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220202", geneDisplay: "ATOH7" },
  { key: "NM_145185.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5609", geneDisplay: "MAP2K7" },
  { key: "NM_145199.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51601", geneDisplay: "LIPT1" },
  { key: "NM_145200.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57010", geneDisplay: "CABP4" },
  { key: "NM_145201.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "93100", geneDisplay: "NAPRT" },
  { key: "NM_145202.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118471", geneDisplay: "PRAP1" },
  { key: "NM_145203.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122011", geneDisplay: "CSNK1A1L" },
  { key: "NM_145204.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123228", geneDisplay: "SENP8" },
  { key: "NM_145206.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143187", geneDisplay: "VTI1A" },
  { key: "NM_145207.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166378", geneDisplay: "SPATA5" },
  { key: "NM_145212.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51263", geneDisplay: "MRPL30" },
  { key: "NM_145214.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81559", geneDisplay: "TRIM11" },
  { key: "NM_145230.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155066", geneDisplay: "ATP6V0E2" },
  { key: "NM_145231.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90141", geneDisplay: "EFCAB11" },
  { key: "NM_145232.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90353", geneDisplay: "CTU1" },
  { key: "NM_145233.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90589", geneDisplay: "ZNF625" },
  { key: "NM_145235.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "92565", geneDisplay: "FANK1" },
  { key: "NM_145236.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "93010", geneDisplay: "B3GNT7" },
  { key: "NM_145239.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112476", geneDisplay: "PRRT2" },
  { key: "NM_145243.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115209", geneDisplay: "OMA1" },
  { key: "NM_145244.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "115265", geneDisplay: "DDIT4L" },
  { key: "NM_145246.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118924", geneDisplay: "FRA10AC1" },
  { key: "NM_145248.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122258", geneDisplay: "SPACA7" },
  { key: "NM_145251.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6815", geneDisplay: "STYX" },
  { key: "NM_145252.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124220", geneDisplay: "ZG16B" },
  { key: "NM_145253.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124402", geneDisplay: "UBALD1" },
  { key: "NM_145254.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124491", geneDisplay: "TMEM170A" },
  { key: "NM_145255.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124995", geneDisplay: "MRPL10" },
  { key: "NM_145256.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126364", geneDisplay: "LRRC25" },
  { key: "NM_145257.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126731", geneDisplay: "CCSAP" },
  { key: "NM_145259.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130399", geneDisplay: "ACVR1C" },
  { key: "NM_145260.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130497", geneDisplay: "OSR1" },
  { key: "NM_145261.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131118", geneDisplay: "DNAJC19" },
  { key: "NM_145262.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132158", geneDisplay: "GLYCTK" },
  { key: "NM_145263.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132671", geneDisplay: "SPATA18" },
  { key: "NM_145265.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133957", geneDisplay: "CCDC127" },
  { key: "NM_145266.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134492", geneDisplay: "NUDCD2" },
  { key: "NM_145267.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135154", geneDisplay: "SDHAF4" },
  { key: "NM_145268.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136263", geneDisplay: "SSMEM1" },
  { key: "NM_145269.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137392", geneDisplay: "CIBAR1" },
  { key: "NM_145270.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146325", geneDisplay: "PRR35" },
  { key: "NM_145271.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146542", geneDisplay: "ZNF688" },
  { key: "NM_145272.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146853", geneDisplay: "C17orf50" },
  { key: "NM_145273.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146894", geneDisplay: "CD300LG" },
  { key: "NM_145276.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147837", geneDisplay: "ZNF563" },
  { key: "NM_145278.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148823", geneDisplay: "GCSAML" },
  { key: "NM_145285.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "159296", geneDisplay: "NKX2-3" },
  { key: "NM_145286.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "161003", geneDisplay: "STOML3" },
  { key: "NM_145287.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "162655", geneDisplay: "ZNF519" },
  { key: "NM_145288.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162979", geneDisplay: "ZNF296" },
  { key: "NM_145290.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166647", geneDisplay: "ADGRA3" },
  { key: "NM_145291.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166793", geneDisplay: "ZBTB49" },
  { key: "NM_145292.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168391", geneDisplay: "GALNTL5" },
  { key: "NM_145294.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197335", geneDisplay: "WDR90" },
  { key: "NM_145295.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199692", geneDisplay: "ZNF627" },
  { key: "NM_145296.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199731", geneDisplay: "CADM4" },
  { key: "NM_145298.6", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "200316", geneDisplay: "APOBEC3F" },
  { key: "NM_145304.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "202865", geneDisplay: "C7orf33" },
  { key: "NM_145305.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203427", geneDisplay: "SLC25A43" },
  { key: "NM_145306.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219738", geneDisplay: "FAM241B" },
  { key: "NM_145307.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219790", geneDisplay: "RTKN2" },
  { key: "NM_145309.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120356739", geneDisplay: "LRRC51" },
  { key: "NM_145312.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220992", geneDisplay: "ZNF485" },
  { key: "NM_145313.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221002", geneDisplay: "RASGEF1A" },
  { key: "NM_145314.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221044", geneDisplay: "UCMA" },
  { key: "NM_145315.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "246269", geneDisplay: "AFG1L" },
  { key: "NM_145331.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6885", geneDisplay: "MAP3K7" },
  { key: "NM_145639.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80833", geneDisplay: "APOL3" },
  { key: "NM_145647.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "93594", geneDisplay: "TBC1D31" },
  { key: "NM_145648.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121260", geneDisplay: "SLC15A4" },
  { key: "NM_145649.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2651", geneDisplay: "GCNT2" },
  { key: "NM_145651.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "147199", geneDisplay: "SCGB1C1" },
  { key: "NM_145652.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149708", geneDisplay: "WFDC5" },
  { key: "NM_145654.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201299", geneDisplay: "RDM1" },
  { key: "NM_145657.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "219409", geneDisplay: "GSX1" },
  { key: "NM_145658.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "246777", geneDisplay: "SPESP1" },
  { key: "NM_145659.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "246778", geneDisplay: "IL27" },
  { key: "NM_145662.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728712", geneDisplay: "SPANXA2" },
  { key: "NM_145663.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80174", geneDisplay: "DBF4B" },
  { key: "NM_145690.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7534", geneDisplay: "YWHAZ" },
  { key: "NM_145691.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91647", geneDisplay: "ATPAF2" },
  { key: "NM_145697.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83540", geneDisplay: "NUF2" },
  { key: "NM_145698.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "91452", geneDisplay: "ACBD5" },
  { key: "NM_145699.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "200315", geneDisplay: "APOBEC3A" },
  { key: "NM_145702.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200765", geneDisplay: "TIGD1" },
  { key: "NM_145715.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166815", geneDisplay: "TIGD2" },
  { key: "NM_145719.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220359", geneDisplay: "TIGD3" },
  { key: "NM_145720.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201798", geneDisplay: "TIGD4" },
  { key: "NM_145725.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7187", geneDisplay: "TRAF3" },
  { key: "NM_145728.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23336", geneDisplay: "SYNM" },
  { key: "NM_145729.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79590", geneDisplay: "MRPL24" },
  { key: "NM_145740.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2938", geneDisplay: "GSTA1" },
  { key: "NM_145798.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114881", geneDisplay: "OSBPL7" },
  { key: "NM_145799.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23157", geneDisplay: "SEPTIN6" },
  { key: "NM_145804.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25841", geneDisplay: "ABTB2" },
  { key: "NM_145805.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64843", geneDisplay: "ISL2" },
  { key: "NM_145806.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118472", geneDisplay: "ZNF511" },
  { key: "NM_145807.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126147", geneDisplay: "NTN5" },
  { key: "NM_145808.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136319", geneDisplay: "MTPN" },
  { key: "NM_145811.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "27091", geneDisplay: "CACNG5" },
  { key: "NM_145814.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "59285", geneDisplay: "CACNG6" },
  { key: "NM_145858.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9946", geneDisplay: "CRYZL1" },
  { key: "NM_145861.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128178", geneDisplay: "EDARADD" },
  { key: "NM_145865.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "257629", geneDisplay: "ANKS4B" },
  { key: "NM_145867.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4056", geneDisplay: "LTC4S" },
  { key: "NM_145868.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "311", geneDisplay: "ANXA11" },
  { key: "NM_145870.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2954", geneDisplay: "GSTZ1" },
  { key: "NM_145886.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55367", geneDisplay: "PIDD1" },
  { key: "NM_145888.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5655", geneDisplay: "KLK10" },
  { key: "NM_145899.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3159", geneDisplay: "HMGA1" },
  { key: "NM_145912.8", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150372", geneDisplay: "NFAM1" },
  { key: "NM_145913.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160728", geneDisplay: "SLC5A8" },
  { key: "NM_145914.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7589", geneDisplay: "ZSCAN21" },
  { key: "NM_147127.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132884", geneDisplay: "EVC2" },
  { key: "NM_147128.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "223082", geneDisplay: "ZNRF2" },
  { key: "NM_147129.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "259173", geneDisplay: "ALS2CL" },
  { key: "NM_147130.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "259197", geneDisplay: "NCR3" },
  { key: "NM_147156.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "259230", geneDisplay: "SGMS1" },
  { key: "NM_147161.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26027", geneDisplay: "ACOT11" },
  { key: "NM_147164.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1271", geneDisplay: "CNTFR" },
  { key: "NM_147180.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5535", geneDisplay: "PPP3R2" },
  { key: "NM_147188.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26263", geneDisplay: "FBXO22" },
  { key: "NM_147190.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91012", geneDisplay: "CERS5" },
  { key: "NM_147191.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118856", geneDisplay: "MMP21" },
  { key: "NM_147195.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "253650", geneDisplay: "ANKRD18A" },
  { key: "NM_147196.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "259236", geneDisplay: "TMIE" },
  { key: "NM_147197.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "259239", geneDisplay: "WFDC11" },
  { key: "NM_147198.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "259240", geneDisplay: "WFDC9" },
  { key: "NM_147686.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10758", geneDisplay: "TRAF3IP2" },
  { key: "NM_148172.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10400", geneDisplay: "PEMT" },
  { key: "NM_148174.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51582", geneDisplay: "AZIN1" },
  { key: "NM_148178.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138716", geneDisplay: "RPP25L" },
  { key: "NM_148672.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56477", geneDisplay: "CCL28" },
  { key: "NM_148674.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27127", geneDisplay: "SMC1B" },
  { key: "NM_148893.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "258010", geneDisplay: "SVIP" },
  { key: "NM_148894.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "259282", geneDisplay: "BOD1L1" },
  { key: "NM_148896.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "256933", geneDisplay: "NPB" },
  { key: "NM_148897.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121214", geneDisplay: "SDR9C7" },
  { key: "NM_148912.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83451", geneDisplay: "ABHD11" },
  { key: "NM_148919.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5696", geneDisplay: "PSMB8" },
  { key: "NM_148923.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1528", geneDisplay: "CYB5A" },
  { key: "NM_148956.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55695", geneDisplay: "NSUN5" },
  { key: "NM_148957.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55504", geneDisplay: "TNFRSF19" },
  { key: "NM_148959.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135458", geneDisplay: "HUS1B" },
  { key: "NM_148960.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149461", geneDisplay: "CLDN19" },
  { key: "NM_148961.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150677", geneDisplay: "OTOS" },
  { key: "NM_148962.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165140", geneDisplay: "OXER1" },
  { key: "NM_148963.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222545", geneDisplay: "GPRC6A" },
  { key: "NM_148975.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51338", geneDisplay: "MS4A4A" },
  { key: "NM_148977.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "53354", geneDisplay: "PANK1" },
  { key: "NM_152219.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125111", geneDisplay: "GJD3" },
  { key: "NM_152221.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1454", geneDisplay: "CSNK1E" },
  { key: "NM_152222.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84957", geneDisplay: "RELT" },
  { key: "NM_152228.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83756", geneDisplay: "TAS1R3" },
  { key: "NM_152230.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "253430", geneDisplay: "IPMK" },
  { key: "NM_152232.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80834", geneDisplay: "TAS1R2" },
  { key: "NM_152233.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "58533", geneDisplay: "SNX6" },
  { key: "NM_152243.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11135", geneDisplay: "CDC42EP1" },
  { key: "NM_152246.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1375", geneDisplay: "CPT1B" },
  { key: "NM_152250.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "245908", geneDisplay: "DEFB105A" },
  { key: "NM_152251.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "245909", geneDisplay: "DEFB106A" },
  { key: "NM_152259.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90381", geneDisplay: "TICRR" },
  { key: "NM_152260.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "27079", geneDisplay: "RPUSD2" },
  { key: "NM_152261.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "90488", geneDisplay: "TMEM263" },
  { key: "NM_152263.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7170", geneDisplay: "TPM3" },
  { key: "NM_152265.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91408", geneDisplay: "BTF3L4" },
  { key: "NM_152266.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91442", geneDisplay: "FAAP24" },
  { key: "NM_152267.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91445", geneDisplay: "RNF185" },
  { key: "NM_152268.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25973", geneDisplay: "PARS2" },
  { key: "NM_152269.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91574", geneDisplay: "MTRFR" },
  { key: "NM_152271.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "91694", geneDisplay: "LONRF1" },
  { key: "NM_152272.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "91782", geneDisplay: "CHMP7" },
  { key: "NM_152274.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "92002", geneDisplay: "CCNQ" },
  { key: "NM_152275.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92104", geneDisplay: "TTC30A" },
  { key: "NM_152277.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "92181", geneDisplay: "UBTD2" },
  { key: "NM_152278.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56849", geneDisplay: "TCEAL7" },
  { key: "NM_152279.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92285", geneDisplay: "ZNF585B" },
  { key: "NM_152280.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23208", geneDisplay: "SYT11" },
  { key: "NM_152281.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92344", geneDisplay: "GORAB" },
  { key: "NM_152284.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "92421", geneDisplay: "CHMP4C" },
  { key: "NM_152285.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92714", geneDisplay: "ARRDC1" },
  { key: "NM_152288.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "93129", geneDisplay: "ORAI3" },
  { key: "NM_152289.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93134", geneDisplay: "ZNF561" },
  { key: "NM_152290.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93190", geneDisplay: "CFAP107" },
  { key: "NM_152291.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4589", geneDisplay: "MUC7" },
  { key: "NM_152293.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "93624", geneDisplay: "TADA2B" },
  { key: "NM_152295.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6897", geneDisplay: "TARS1" },
  { key: "NM_152296.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "478", geneDisplay: "ATP1A3" },
  { key: "NM_152299.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "29781", geneDisplay: "NCAPH2" },
  { key: "NM_152305.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56983", geneDisplay: "POGLUT1" },
  { key: "NM_152307.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "115708", geneDisplay: "TRMT61A" },
  { key: "NM_152308.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "116028", geneDisplay: "RMI2" },
  { key: "NM_152309.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118788", geneDisplay: "PIK3AP1" },
  { key: "NM_152310.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83401", geneDisplay: "ELOVL3" },
  { key: "NM_152311.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119467", geneDisplay: "CLRN3" },
  { key: "NM_152313.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120103", geneDisplay: "SLC36A4" },
  { key: "NM_152316.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120534", geneDisplay: "ARL14EP" },
  { key: "NM_152318.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121053", geneDisplay: "NOPCHAP1" },
  { key: "NM_152319.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121273", geneDisplay: "C12orf54" },
  { key: "NM_152321.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121506", geneDisplay: "ERP27" },
  { key: "NM_152323.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121599", geneDisplay: "SPIC" },
  { key: "NM_152324.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "121793", geneDisplay: "TEX29" },
  { key: "NM_152325.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122046", geneDisplay: "TEX26" },
  { key: "NM_152326.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122416", geneDisplay: "ANKRD9" },
  { key: "NM_152327.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122481", geneDisplay: "AK7" },
  { key: "NM_152328.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122622", geneDisplay: "ADSS1" },
  { key: "NM_152329.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122769", geneDisplay: "LRR1" },
  { key: "NM_152331.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122970", geneDisplay: "ACOT4" },
  { key: "NM_152334.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123283", geneDisplay: "TARS3" },
  { key: "NM_152335.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123591", geneDisplay: "TMEM266" },
  { key: "NM_152337.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123775", geneDisplay: "C16orf46" },
  { key: "NM_152338.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "653808", geneDisplay: "ZG16" },
  { key: "NM_152339.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124044", geneDisplay: "SPATA2L" },
  { key: "NM_152341.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124222", geneDisplay: "PAQR4" },
  { key: "NM_152342.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124359", geneDisplay: "CDYL2" },
  { key: "NM_152343.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124783", geneDisplay: "SPATA32" },
  { key: "NM_152345.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124930", geneDisplay: "ANKRD13B" },
  { key: "NM_152346.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124935", geneDisplay: "SLC43A2" },
  { key: "NM_152347.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124989", geneDisplay: "EFCAB13" },
  { key: "NM_152349.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125113", geneDisplay: "KRT222" },
  { key: "NM_152352.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "125228", geneDisplay: "FAM210A" },
  { key: "NM_152353.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125875", geneDisplay: "CLDND2" },
  { key: "NM_152354.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26974", geneDisplay: "ZNF285" },
  { key: "NM_152355.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126068", geneDisplay: "ZNF441" },
  { key: "NM_152356.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126069", geneDisplay: "ZNF491" },
  { key: "NM_152357.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126070", geneDisplay: "ZNF440" },
  { key: "NM_152358.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126123", geneDisplay: "IZUMO2" },
  { key: "NM_152361.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126272", geneDisplay: "EID2B" },
  { key: "NM_152362.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126282", geneDisplay: "TNFAIP8L1" },
  { key: "NM_152363.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126549", geneDisplay: "ANKLE1" },
  { key: "NM_152365.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126695", geneDisplay: "KDF1" },
  { key: "NM_152366.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126823", geneDisplay: "KLHDC9" },
  { key: "NM_152367.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126868", geneDisplay: "MAB21L3" },
  { key: "NM_152371.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127281", geneDisplay: "PRXL2B" },
  { key: "NM_152372.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127294", geneDisplay: "MYOM3" },
  { key: "NM_152373.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127396", geneDisplay: "ZNF684" },
  { key: "NM_152374.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127703", geneDisplay: "C1orf216" },
  { key: "NM_152375.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127707", geneDisplay: "KLHDC7A" },
  { key: "NM_152376.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127733", geneDisplay: "UBXN10" },
  { key: "NM_152377.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127795", geneDisplay: "C1orf87" },
  { key: "NM_152379.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128061", geneDisplay: "C1orf131" },
  { key: "NM_152381.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129446", geneDisplay: "XIRP2" },
  { key: "NM_152383.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129563", geneDisplay: "DIS3L2" },
  { key: "NM_152384.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129880", geneDisplay: "BBS5" },
  { key: "NM_152385.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130162", geneDisplay: "CLHC1" },
  { key: "NM_152386.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130367", geneDisplay: "SGPP2" },
  { key: "NM_152387.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130535", geneDisplay: "KCTD18" },
  { key: "NM_152390.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130733", geneDisplay: "TMEM178A" },
  { key: "NM_152391.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130814", geneDisplay: "SLC66A3" },
  { key: "NM_152393.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131377", geneDisplay: "KLHL40" },
  { key: "NM_152394.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131831", geneDisplay: "ERICH6" },
  { key: "NM_152395.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131870", geneDisplay: "NUDT16" },
  { key: "NM_152396.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131965", geneDisplay: "METTL6" },
  { key: "NM_152397.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132141", geneDisplay: "IQCF1" },
  { key: "NM_152400.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132720", geneDisplay: "FAM241A" },
  { key: "NM_152401.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132954", geneDisplay: "PDCL2" },
  { key: "NM_152402.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "133022", geneDisplay: "TRAM1L1" },
  { key: "NM_152403.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133584", geneDisplay: "EGFLAM" },
  { key: "NM_152404.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133688", geneDisplay: "UGT3A1" },
  { key: "NM_152405.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133746", geneDisplay: "JMY" },
  { key: "NM_152406.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134265", geneDisplay: "AFAP1L1" },
  { key: "NM_152407.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134266", geneDisplay: "GRPEL2" },
  { key: "NM_152411.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136051", geneDisplay: "ZNF786" },
  { key: "NM_152412.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137209", geneDisplay: "ZNF572" },
  { key: "NM_152413.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137362", geneDisplay: "GOT1L1" },
  { key: "NM_152414.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27319", geneDisplay: "BHLHE22" },
  { key: "NM_152415.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137492", geneDisplay: "VPS37A" },
  { key: "NM_152416.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137682", geneDisplay: "NDUFAF6" },
  { key: "NM_152418.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "138009", geneDisplay: "DCAF4L2" },
  { key: "NM_152419.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "138050", geneDisplay: "HGSNAT" },
  { key: "NM_152420.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138199", geneDisplay: "CARNMT1" },
  { key: "NM_152421.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138311", geneDisplay: "DIPK1B" },
  { key: "NM_152424.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139285", geneDisplay: "AMER1" },
  { key: "NM_152426.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "140564", geneDisplay: "APOBEC3D" },
  { key: "NM_152429.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143282", geneDisplay: "FGFBP3" },
  { key: "NM_152430.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143503", geneDisplay: "OR51E1" },
  { key: "NM_152431.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143689", geneDisplay: "PIWIL4" },
  { key: "NM_152432.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143872", geneDisplay: "ARHGAP42" },
  { key: "NM_152434.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143884", geneDisplay: "CWF19L2" },
  { key: "NM_152435.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144193", geneDisplay: "AMDHD1" },
  { key: "NM_152437.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144348", geneDisplay: "ZNF664" },
  { key: "NM_152440.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144577", geneDisplay: "KICS2" },
  { key: "NM_152441.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144699", geneDisplay: "FBXL14" },
  { key: "NM_152443.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145226", geneDisplay: "RDH12" },
  { key: "NM_152445.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145483", geneDisplay: "FAM161B" },
  { key: "NM_152446.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145508", geneDisplay: "CEP128" },
  { key: "NM_152447.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145581", geneDisplay: "LRFN5" },
  { key: "NM_152448.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145645", geneDisplay: "TERB2" },
  { key: "NM_152450.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145773", geneDisplay: "FAM81A" },
  { key: "NM_152453.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145942", geneDisplay: "TMCO5A" },
  { key: "NM_152457.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146434", geneDisplay: "ZNF597" },
  { key: "NM_152458.7", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146540", geneDisplay: "ZNF785" },
  { key: "NM_152462.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146861", geneDisplay: "SLC35G3" },
  { key: "NM_152463.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146956", geneDisplay: "EME1" },
  { key: "NM_152464.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147007", geneDisplay: "TMEM199" },
  { key: "NM_152467.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "317719", geneDisplay: "KLHL10" },
  { key: "NM_152468.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147138", geneDisplay: "TMC8" },
  { key: "NM_152470.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "494470", geneDisplay: "RNF165" },
  { key: "NM_152473.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147664", geneDisplay: "ERVV-1" },
  { key: "NM_152474.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147685", geneDisplay: "C19orf18" },
  { key: "NM_152475.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147687", geneDisplay: "ZNF417" },
  { key: "NM_152476.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147741", geneDisplay: "ZNF560" },
  { key: "NM_152477.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147929", geneDisplay: "ZNF565" },
  { key: "NM_152478.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147949", geneDisplay: "ZNF583" },
  { key: "NM_152479.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148014", geneDisplay: "TTC9B" },
  { key: "NM_152481.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148109", geneDisplay: "FAM187B" },
  { key: "NM_152482.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148223", geneDisplay: "C19orf25" },
  { key: "NM_152484.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148266", geneDisplay: "ZNF569" },
  { key: "NM_152485.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148304", geneDisplay: "C1orf74" },
  { key: "NM_152487.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148534", geneDisplay: "TLCD4" },
  { key: "NM_152490.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148789", geneDisplay: "B3GALNT2" },
  { key: "NM_152491.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148811", geneDisplay: "PM20D1" },
  { key: "NM_152492.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148870", geneDisplay: "CCDC27" },
  { key: "NM_152493.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149076", geneDisplay: "ZNF362" },
  { key: "NM_152494.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149095", geneDisplay: "DCST1" },
  { key: "NM_152495.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149111", geneDisplay: "CNIH3" },
  { key: "NM_152499.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149473", geneDisplay: "CCDC24" },
  { key: "NM_152501.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149628", geneDisplay: "PYHIN1" },
  { key: "NM_152503.7", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140699", geneDisplay: "MROH8" },
  { key: "NM_152504.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149840", geneDisplay: "SHLD1" },
  { key: "NM_152505.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "150082", geneDisplay: "LCA5L" },
  { key: "NM_152510.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150280", geneDisplay: "HORMAD2" },
  { key: "NM_152511.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150290", geneDisplay: "DUSP18" },
  { key: "NM_152512.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150350", geneDisplay: "ENTHD1" },
  { key: "NM_152513.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150365", geneDisplay: "MEI1" },
  { key: "NM_152515.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150468", geneDisplay: "CKAP2L" },
  { key: "NM_152516.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150684", geneDisplay: "COMMD1" },
  { key: "NM_152517.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150737", geneDisplay: "TTC30B" },
  { key: "NM_152519.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151050", geneDisplay: "KANSL1L" },
  { key: "NM_152520.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151126", geneDisplay: "ZNF385B" },
  { key: "NM_152522.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151188", geneDisplay: "ARL6IP6" },
  { key: "NM_152524.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151246", geneDisplay: "SGO2" },
  { key: "NM_152527.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151473", geneDisplay: "SLC16A14" },
  { key: "NM_152529.7", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151556", geneDisplay: "GPR155" },
  { key: "NM_152531.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152002", geneDisplay: "XXYLT1" },
  { key: "NM_152533.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152065", geneDisplay: "C3orf22" },
  { key: "NM_152536.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152273", geneDisplay: "FGD5" },
  { key: "NM_152539.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152405", geneDisplay: "TEX55" },
  { key: "NM_152540.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152579", geneDisplay: "SCFD2" },
  { key: "NM_152542.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152926", geneDisplay: "PPM1K" },
  { key: "NM_152543.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152940", geneDisplay: "C4orf45" },
  { key: "NM_152544.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152992", geneDisplay: "TRMT44" },
  { key: "NM_152545.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "153020", geneDisplay: "RASGEF1B" },
  { key: "NM_152546.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153443", geneDisplay: "SRFBP1" },
  { key: "NM_152547.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153579", geneDisplay: "BTNL9" },
  { key: "NM_152548.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153643", geneDisplay: "FAM81B" },
  { key: "NM_152550.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153769", geneDisplay: "SH3RF2" },
  { key: "NM_152551.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154007", geneDisplay: "SNRNP48" },
  { key: "NM_152556.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154743", geneDisplay: "BMT2" },
  { key: "NM_152558.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23288", geneDisplay: "IQCE" },
  { key: "NM_152559.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155368", geneDisplay: "METTL27" },
  { key: "NM_152562.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157313", geneDisplay: "CDCA2" },
  { key: "NM_152564.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157680", geneDisplay: "VPS13B" },
  { key: "NM_152565.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "245972", geneDisplay: "ATP6V0D2" },
  { key: "NM_152572.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158067", geneDisplay: "AK8" },
  { key: "NM_152573.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158158", geneDisplay: "RASEF" },
  { key: "NM_152574.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158219", geneDisplay: "TTC39B" },
  { key: "NM_152577.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158506", geneDisplay: "CBLL2" },
  { key: "NM_152578.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158521", geneDisplay: "FMR1NB" },
  { key: "NM_152581.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158747", geneDisplay: "MOSPD2" },
  { key: "NM_152582.7", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728911", geneDisplay: "CT45A2" },
  { key: "NM_152585.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "159163", geneDisplay: "RBMY1F" },
  { key: "NM_152587.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "160140", geneDisplay: "C11orf65" },
  { key: "NM_152588.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160335", geneDisplay: "TMTC2" },
  { key: "NM_152589.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160419", geneDisplay: "C12orf50" },
  { key: "NM_152591.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160762", geneDisplay: "CCDC63" },
  { key: "NM_152592.6", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161176", geneDisplay: "SYNE3" },
  { key: "NM_152594.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161742", geneDisplay: "SPRED1" },
  { key: "NM_152595.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161779", geneDisplay: "PGBD4" },
  { key: "NM_152597.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161835", geneDisplay: "FSIP1" },
  { key: "NM_152598.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162333", geneDisplay: "MARCHF10" },
  { key: "NM_152599.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162387", geneDisplay: "MFSD6L" },
  { key: "NM_152600.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163033", geneDisplay: "ZNF579" },
  { key: "NM_152601.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163051", geneDisplay: "ZNF709" },
  { key: "NM_152608.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163859", geneDisplay: "SDE2" },
  { key: "NM_152609.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163882", geneDisplay: "CNST" },
  { key: "NM_152610.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "164127", geneDisplay: "CCDC185" },
  { key: "NM_152611.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "164312", geneDisplay: "LRRN4" },
  { key: "NM_152612.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164592", geneDisplay: "CCDC116" },
  { key: "NM_152613.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164684", geneDisplay: "WBP2NL" },
  { key: "NM_152614.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165100", geneDisplay: "TEX44" },
  { key: "NM_152616.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "287015", geneDisplay: "TRIM42" },
  { key: "NM_152617.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165918", geneDisplay: "RNF168" },
  { key: "NM_152618.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166379", geneDisplay: "BBS12" },
  { key: "NM_152620.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166655", geneDisplay: "TRIM60" },
  { key: "NM_152624.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167227", geneDisplay: "DCP2" },
  { key: "NM_152625.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167465", geneDisplay: "ZNF366" },
  { key: "NM_152626.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168374", geneDisplay: "ZNF92" },
  { key: "NM_152627.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168448", geneDisplay: "CDC14C" },
  { key: "NM_152628.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169166", geneDisplay: "SNX31" },
  { key: "NM_152630.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "169966", geneDisplay: "TENT5D" },
  { key: "NM_152631.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170062", geneDisplay: "FAM47B" },
  { key: "NM_152635.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "170392", geneDisplay: "OIT3" },
  { key: "NM_152637.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196410", geneDisplay: "METTL7B" },
  { key: "NM_152638.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196477", geneDisplay: "CCER1" },
  { key: "NM_152640.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196513", geneDisplay: "DCP1B" },
  { key: "NM_152641.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196528", geneDisplay: "ARID2" },
  { key: "NM_152643.8", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "85442", geneDisplay: "KNDC1" },
  { key: "NM_152644.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "196792", geneDisplay: "FAM24B" },
  { key: "NM_152647.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "196951", geneDisplay: "FAM227B" },
  { key: "NM_152649.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197259", geneDisplay: "MLKL" },
  { key: "NM_152653.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7325", geneDisplay: "UBE2E2" },
  { key: "NM_152654.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199699", geneDisplay: "DAND5" },
  { key: "NM_152657.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199720", geneDisplay: "GGN" },
  { key: "NM_152658.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199745", geneDisplay: "THAP8" },
  { key: "NM_152660.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199870", geneDisplay: "FAM76A" },
  { key: "NM_152663.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55103", geneDisplay: "RALGPS2" },
  { key: "NM_152665.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200132", geneDisplay: "DYNLT5" },
  { key: "NM_152667.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140838", geneDisplay: "NANP" },
  { key: "NM_152670.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200523", geneDisplay: "TEX37" },
  { key: "NM_152672.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200931", geneDisplay: "SLC51A" },
  { key: "NM_152677.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "201516", geneDisplay: "ZSCAN4" },
  { key: "NM_152678.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201627", geneDisplay: "DENND6A" },
  { key: "NM_152679.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201780", geneDisplay: "SLC10A4" },
  { key: "NM_152680.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201799", geneDisplay: "TMEM154" },
  { key: "NM_152682.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201965", geneDisplay: "RWDD4" },
  { key: "NM_152683.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201973", geneDisplay: "PRIMPOL" },
  { key: "NM_152686.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202052", geneDisplay: "DNAJC18" },
  { key: "NM_152688.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "202559", geneDisplay: "KHDRBS2" },
  { key: "NM_152694.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203430", geneDisplay: "RTL3" },
  { key: "NM_152695.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203523", geneDisplay: "ZNF449" },
  { key: "NM_152698.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "205147", geneDisplay: "AMER3" },
  { key: "NM_152699.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "205564", geneDisplay: "SENP5" },
  { key: "NM_152701.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154664", geneDisplay: "ABCA13" },
  { key: "NM_152703.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "219285", geneDisplay: "SAMD9L" },
  { key: "NM_152704.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "219287", geneDisplay: "AMER2" },
  { key: "NM_152706.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "219557", geneDisplay: "TEX47" },
  { key: "NM_152707.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8034", geneDisplay: "SLC25A16" },
  { key: "NM_152709.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219736", geneDisplay: "STOX1" },
  { key: "NM_152713.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3703", geneDisplay: "STT3A" },
  { key: "NM_152716.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219988", geneDisplay: "PATL1" },
  { key: "NM_152718.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220001", geneDisplay: "VWCE" },
  { key: "NM_152719.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "220082", geneDisplay: "CBY2" },
  { key: "NM_152721.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "220164", geneDisplay: "DOK6" },
  { key: "NM_152722.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220296", geneDisplay: "HEPACAM" },
  { key: "NM_152723.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220388", geneDisplay: "CCDC89" },
  { key: "NM_152726.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "221154", geneDisplay: "MICU2" },
  { key: "NM_152727.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "221184", geneDisplay: "CPNE2" },
  { key: "NM_152729.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221294", geneDisplay: "NT5DC1" },
  { key: "NM_152730.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221322", geneDisplay: "TBC1D32" },
  { key: "NM_152731.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221336", geneDisplay: "BEND6" },
  { key: "NM_152732.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221421", geneDisplay: "RSPH9" },
  { key: "NM_152735.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221504", geneDisplay: "ZBTB9" },
  { key: "NM_152737.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221687", geneDisplay: "RNF182" },
  { key: "NM_152739.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3205", geneDisplay: "HOXA9" },
  { key: "NM_152740.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11112", geneDisplay: "HIBADH" },
  { key: "NM_152742.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221914", geneDisplay: "GPC2" },
  { key: "NM_152743.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221927", geneDisplay: "BRAT1" },
  { key: "NM_152744.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221935", geneDisplay: "SDK1" },
  { key: "NM_152745.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "30010", geneDisplay: "NXPH1" },
  { key: "NM_152750.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222256", geneDisplay: "CDHR3" },
  { key: "NM_152753.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222663", geneDisplay: "SCUBE3" },
  { key: "NM_152755.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "245812", geneDisplay: "CNPY4" },
  { key: "NM_152756.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "253260", geneDisplay: "RICTOR" },
  { key: "NM_152758.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "253943", geneDisplay: "YTHDF3" },
  { key: "NM_152760.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254122", geneDisplay: "SNX32" },
  { key: "NM_152761.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "254158", geneDisplay: "CXorf58" },
  { key: "NM_152762.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254187", geneDisplay: "TSGA10IP" },
  { key: "NM_152763.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "254268", geneDisplay: "AKNAD1" },
  { key: "NM_152765.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "254778", geneDisplay: "VXN" },
  { key: "NM_152766.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "254863", geneDisplay: "TMEM256" },
  { key: "NM_152770.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "255119", geneDisplay: "CFAP299" },
  { key: "NM_152772.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "255394", geneDisplay: "TCP11L2" },
  { key: "NM_152773.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "255758", geneDisplay: "DYNLT2B" },
  { key: "NM_152775.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "256309", geneDisplay: "CCDC110" },
  { key: "NM_152781.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "256957", geneDisplay: "HEATR9" },
  { key: "NM_152783.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728294", geneDisplay: "D2HGDH" },
  { key: "NM_152784.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "257062", geneDisplay: "CATSPERD" },
  { key: "NM_152785.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "257144", geneDisplay: "GCSAM" },
  { key: "NM_152787.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "257397", geneDisplay: "TAB3" },
  { key: "NM_152789.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "257415", geneDisplay: "FAM133B" },
  { key: "NM_152791.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148254", geneDisplay: "ZNF555" },
  { key: "NM_152792.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151516", geneDisplay: "ASPRV1" },
  { key: "NM_152793.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222166", geneDisplay: "MTURN" },
  { key: "NM_152795.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64344", geneDisplay: "HIF3A" },
  { key: "NM_152833.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "90120", geneDisplay: "TMEM250" },
  { key: "NM_152834.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129787", geneDisplay: "TMEM18" },
  { key: "NM_152835.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149420", geneDisplay: "PDIK1L" },
  { key: "NM_152836.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64089", geneDisplay: "SNX16" },
  { key: "NM_152862.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10109", geneDisplay: "ARPC2" },
  { key: "NM_152864.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128414", geneDisplay: "NKAIN4" },
  { key: "NM_152866.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "931", geneDisplay: "MS4A1" },
  { key: "NM_152868.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "3761", geneDisplay: "KCNJ4" },
  { key: "NM_152869.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9104", geneDisplay: "RGN" },
  { key: "NM_152879.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8527", geneDisplay: "DGKD" },
  { key: "NM_152888.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169044", geneDisplay: "COL22A1" },
  { key: "NM_152889.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "166012", geneDisplay: "CHST13" },
  { key: "NM_152890.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "255631", geneDisplay: "COL24A1" },
  { key: "NM_152891.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "260429", geneDisplay: "PRSS33" },
  { key: "NM_152892.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222229", geneDisplay: "LRWD1" },
  { key: "NM_152896.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "115426", geneDisplay: "UHRF2" },
  { key: "NM_152898.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222894", geneDisplay: "FERD3L" },
  { key: "NM_152899.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "259307", geneDisplay: "IL4I1" },
  { key: "NM_152901.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "260434", geneDisplay: "PYDC1" },
  { key: "NM_152902.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "261726", geneDisplay: "TIPRL" },
  { key: "NM_152903.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "89890", geneDisplay: "KBTBD6" },
  { key: "NM_152905.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121441", geneDisplay: "NEDD1" },
  { key: "NM_152906.7", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "128989", geneDisplay: "TANGO2" },
  { key: "NM_152911.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "196743", geneDisplay: "PAOX" },
  { key: "NM_152912.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "219402", geneDisplay: "MTIF3" },
  { key: "NM_152913.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222865", geneDisplay: "TMEM130" },
  { key: "NM_152914.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "256302", geneDisplay: "NATD1" },
  { key: "NM_152924.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "11057", geneDisplay: "ABHD2" },
  { key: "NM_152925.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8904", geneDisplay: "CPNE1" },
  { key: "NM_152945.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129831", geneDisplay: "RBM45" },
  { key: "NM_152988.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56928", geneDisplay: "SPPL2B" },
  { key: "NM_152990.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222659", geneDisplay: "PXT1" },
  { key: "NM_152996.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "256435", geneDisplay: "ST6GALNAC3" },
  { key: "NM_152997.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "260436", geneDisplay: "FDCSP" },
  { key: "NM_153000.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147495", geneDisplay: "APCDD1" },
  { key: "NM_153002.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165829", geneDisplay: "GPR156" },
  { key: "NM_153006.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162417", geneDisplay: "NAGS" },
  { key: "NM_153007.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146852", geneDisplay: "ODF4" },
  { key: "NM_153015.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157753", geneDisplay: "TMEM74" },
  { key: "NM_153018.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124961", geneDisplay: "ZFP3" },
  { key: "NM_153021.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151056", geneDisplay: "PLB1" },
  { key: "NM_153026.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144165", geneDisplay: "PRICKLE1" },
  { key: "NM_153029.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9683", geneDisplay: "N4BP1" },
  { key: "NM_153033.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154881", geneDisplay: "KCTD7" },
  { key: "NM_153034.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118738", geneDisplay: "ZNF488" },
  { key: "NM_153035.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127428", geneDisplay: "TCEANC2" },
  { key: "NM_153045.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203197", geneDisplay: "TMEM268" },
  { key: "NM_153046.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122402", geneDisplay: "TDRD9" },
  { key: "NM_153182.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84864", geneDisplay: "RIOX2" },
  { key: "NM_153189.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6677", geneDisplay: "SPAM1" },
  { key: "NM_153207.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121536", geneDisplay: "AEBP2" },
  { key: "NM_153208.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124152", geneDisplay: "IQCK" },
  { key: "NM_153209.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124602", geneDisplay: "KIF19" },
  { key: "NM_153210.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124739", geneDisplay: "USP43" },
  { key: "NM_153212.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127534", geneDisplay: "GJB4" },
  { key: "NM_153213.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128272", geneDisplay: "ARHGEF19" },
  { key: "NM_153214.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129804", geneDisplay: "FBLN7" },
  { key: "NM_153215.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132228", geneDisplay: "LSMEM2" },
  { key: "NM_153216.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134187", geneDisplay: "POU5F2" },
  { key: "NM_153217.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134288", geneDisplay: "TMEM174" },
  { key: "NM_153218.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "144811", geneDisplay: "LACC1" },
  { key: "NM_153219.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147807", geneDisplay: "ZNF524" },
  { key: "NM_153221.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148113", geneDisplay: "CILP2" },
  { key: "NM_153225.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157869", geneDisplay: "SBSPON" },
  { key: "NM_153229.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162461", geneDisplay: "TMEM92" },
  { key: "NM_153230.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162517", geneDisplay: "FBXO39" },
  { key: "NM_153232.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163126", geneDisplay: "EID2" },
  { key: "NM_153234.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167410", geneDisplay: "LIX1" },
  { key: "NM_153235.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "167838", geneDisplay: "TXLNB" },
  { key: "NM_153236.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168537", geneDisplay: "GIMAP7" },
  { key: "NM_153237.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169693", geneDisplay: "TMEM252" },
  { key: "NM_153240.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27031", geneDisplay: "NPHP3" },
  { key: "NM_153247.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222962", geneDisplay: "SLC29A4" },
  { key: "NM_153252.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "254065", geneDisplay: "BRWD3" },
  { key: "NM_153256.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "254427", geneDisplay: "PROSER2" },
  { key: "NM_153257.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92283", geneDisplay: "ZNF461" },
  { key: "NM_153259.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "255231", geneDisplay: "MCOLN2" },
  { key: "NM_153260.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "255252", geneDisplay: "LRRC57" },
  { key: "NM_153265.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256364", geneDisplay: "EML3" },
  { key: "NM_153266.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256472", geneDisplay: "TMEM151A" },
  { key: "NM_153267.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "256691", geneDisplay: "MAMDC2" },
  { key: "NM_153269.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140680", geneDisplay: "C20orf96" },
  { key: "NM_153270.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "257240", geneDisplay: "KLHL34" },
  { key: "NM_153271.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "257364", geneDisplay: "SNX33" },
  { key: "NM_153273.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9807", geneDisplay: "IP6K1" },
  { key: "NM_153274.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "266675", geneDisplay: "BEST4" },
  { key: "NM_153276.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9356", geneDisplay: "SLC22A6" },
  { key: "NM_153289.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245932", geneDisplay: "DEFB119" },
  { key: "NM_153320.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10864", geneDisplay: "SLC22A7" },
  { key: "NM_153324.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245936", geneDisplay: "DEFB123" },
  { key: "NM_153325.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245938", geneDisplay: "DEFB125" },
  { key: "NM_153326.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10327", geneDisplay: "AKR1A1" },
  { key: "NM_153329.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126133", geneDisplay: "ALDH16A1" },
  { key: "NM_153330.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165721", geneDisplay: "DNAJB8" },
  { key: "NM_153332.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "90459", geneDisplay: "ERI1" },
  { key: "NM_153333.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90843", geneDisplay: "TCEAL8" },
  { key: "NM_153339.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126789", geneDisplay: "PUSL1" },
  { key: "NM_153343.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "133121", geneDisplay: "ENPP6" },
  { key: "NM_153346.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139105", geneDisplay: "BEND2" },
  { key: "NM_153347.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144110", geneDisplay: "TMEM86A" },
  { key: "NM_153348.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "26259", geneDisplay: "FBXW8" },
  { key: "NM_153350.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146330", geneDisplay: "FBXL16" },
  { key: "NM_153354.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153396", geneDisplay: "TMEM161B" },
  { key: "NM_153356.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161514", geneDisplay: "TBC1D21" },
  { key: "NM_153358.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163049", geneDisplay: "ZNF791" },
  { key: "NM_153360.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "164284", geneDisplay: "APCDD1L" },
  { key: "NM_153361.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167359", geneDisplay: "NIM1K" },
  { key: "NM_153362.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "167681", geneDisplay: "PRSS35" },
  { key: "NM_153363.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168417", geneDisplay: "ZNF679" },
  { key: "NM_153364.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196472", geneDisplay: "GARIN6" },
  { key: "NM_153365.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "202018", geneDisplay: "TAPT1" },
  { key: "NM_153366.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79987", geneDisplay: "SVEP1" },
  { key: "NM_153367.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219654", geneDisplay: "ZCCHC24" },
  { key: "NM_153368.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219770", geneDisplay: "GJD4" },
  { key: "NM_153369.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "91749", geneDisplay: "MFSD4B" },
  { key: "NM_153370.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221476", geneDisplay: "PI16" },
  { key: "NM_153371.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "222484", geneDisplay: "LNX2" },
  { key: "NM_153373.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "85007", geneDisplay: "PHYKPL" },
  { key: "NM_153374.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "256586", geneDisplay: "LYSMD2" },
  { key: "NM_153376.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "257236", geneDisplay: "CCDC96" },
  { key: "NM_153377.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121227", geneDisplay: "LRIG3" },
  { key: "NM_153442.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2849", geneDisplay: "GPR26" },
  { key: "NM_153443.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115653", geneDisplay: "KIR3DL3" },
  { key: "NM_153444.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120065", geneDisplay: "OR5P2" },
  { key: "NM_153445.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120066", geneDisplay: "OR5P3" },
  { key: "NM_153447.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126206", geneDisplay: "NLRP5" },
  { key: "NM_153448.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80712", geneDisplay: "ESX1" },
  { key: "NM_153451.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220064", geneDisplay: "LTO1" },
  { key: "NM_153456.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "266722", geneDisplay: "HS6ST3" },
  { key: "NM_153460.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84818", geneDisplay: "IL17RC" },
  { key: "NM_153477.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8409", geneDisplay: "UXT" },
  { key: "NM_153480.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132014", geneDisplay: "IL17RE" },
  { key: "NM_153485.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9631", geneDisplay: "NUP155" },
  { key: "NM_153487.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "266727", geneDisplay: "MDGA1" },
  { key: "NM_153490.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3860", geneDisplay: "KRT13" },
  { key: "NM_153498.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57118", geneDisplay: "CAMK1D" },
  { key: "NM_153603.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "91949", geneDisplay: "COG7" },
  { key: "NM_153605.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131544", geneDisplay: "CRYBG3" },
  { key: "NM_153606.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149647", geneDisplay: "GARIN4" },
  { key: "NM_153607.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153222", geneDisplay: "CREBRF" },
  { key: "NM_153608.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163071", geneDisplay: "ZNF114" },
  { key: "NM_153610.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202333", geneDisplay: "CMYA5" },
  { key: "NM_153611.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220002", geneDisplay: "CYB561A3" },
  { key: "NM_153612.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222537", geneDisplay: "HS3ST5" },
  { key: "NM_153613.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "254531", geneDisplay: "LPCAT4" },
  { key: "NM_153614.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374407", geneDisplay: "DNAJB13" },
  { key: "NM_153615.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "266747", geneDisplay: "RGL4" },
  { key: "NM_153631.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3200", geneDisplay: "HOXA3" },
  { key: "NM_153633.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3221", geneDisplay: "HOXC4" },
  { key: "NM_153634.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144402", geneDisplay: "CPNE8" },
  { key: "NM_153635.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151835", geneDisplay: "CPNE9" },
  { key: "NM_153636.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27132", geneDisplay: "CPNE7" },
  { key: "NM_153646.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123041", geneDisplay: "SLC24A4" },
  { key: "NM_153676.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10083", geneDisplay: "USH1C" },
  { key: "NM_153682.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "51227", geneDisplay: "PIGP" },
  { key: "NM_153687.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121457", geneDisplay: "IKBIP" },
  { key: "NM_153688.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "162239", geneDisplay: "ZFP1" },
  { key: "NM_153689.6", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "205327", geneDisplay: "C2orf69" },
  { key: "NM_153690.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131583", geneDisplay: "FAM43A" },
  { key: "NM_153692.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203100", geneDisplay: "HTRA4" },
  { key: "NM_153695.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "195828", geneDisplay: "ZNF367" },
  { key: "NM_153698.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "195827", geneDisplay: "PRXL2C" },
  { key: "NM_153699.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221357", geneDisplay: "GSTA5" },
  { key: "NM_153700.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161497", geneDisplay: "STRC" },
  { key: "NM_153702.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "255520", geneDisplay: "ELMOD2" },
  { key: "NM_153703.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127435", geneDisplay: "PODN" },
  { key: "NM_153704.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "91147", geneDisplay: "TMEM67" },
  { key: "NM_153705.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143888", geneDisplay: "POGLUT3" },
  { key: "NM_153706.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133383", geneDisplay: "SETD9" },
  { key: "NM_153707.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158297", geneDisplay: "SAXO1" },
  { key: "NM_153708.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132112", geneDisplay: "RTP1" },
  { key: "NM_153710.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169436", geneDisplay: "STKLD1" },
  { key: "NM_153711.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "254228", geneDisplay: "CALHM5" },
  { key: "NM_153712.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150465", geneDisplay: "TTL" },
  { key: "NM_153713.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128077", geneDisplay: "LIX1L" },
  { key: "NM_153716.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "159119", geneDisplay: "HSFY2" },
  { key: "NM_153717.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2121", geneDisplay: "EVC" },
  { key: "NM_153741.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54344", geneDisplay: "DPM3" },
  { key: "NM_153747.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5279", geneDisplay: "PIGC" },
  { key: "NM_153756.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "252995", geneDisplay: "FNDC5" },
  { key: "NM_153757.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "266812", geneDisplay: "NAP1L5" },
  { key: "NM_153758.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "29949", geneDisplay: "IL19" },
  { key: "NM_153766.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3758", geneDisplay: "KCNJ1" },
  { key: "NM_153769.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "26256", geneDisplay: "CABYR" },
  { key: "NM_153809.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138474", geneDisplay: "TAF1L" },
  { key: "NM_153810.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143384", geneDisplay: "CACUL1" },
  { key: "NM_153811.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145389", geneDisplay: "SLC38A6" },
  { key: "NM_153812.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148479", geneDisplay: "PHF13" },
  { key: "NM_153813.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "161882", geneDisplay: "ZFPM1" },
  { key: "NM_153816.6", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57231", geneDisplay: "SNX14" },
  { key: "NM_153827.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "50488", geneDisplay: "MINK1" },
  { key: "NM_153833.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132243", geneDisplay: "H1-8" },
  { key: "NM_153834.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139378", geneDisplay: "ADGRG4" },
  { key: "NM_153836.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200407", geneDisplay: "CREG2" },
  { key: "NM_153838.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221393", geneDisplay: "ADGRF4" },
  { key: "NM_153839.7", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222611", geneDisplay: "ADGRF2" },
  { key: "NM_153840.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "266977", geneDisplay: "ADGRF1" },
  { key: "NM_170587.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "8601", geneDisplay: "RGS20" },
  { key: "NM_170600.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10044", geneDisplay: "SH2D3C" },
  { key: "NM_170601.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54414", geneDisplay: "SIAE" },
  { key: "NM_170604.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115727", geneDisplay: "RASGRP4" },
  { key: "NM_170606.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "58508", geneDisplay: "KMT2C" },
  { key: "NM_170610.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "255626", geneDisplay: "H2BC1" },
  { key: "NM_170662.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "868", geneDisplay: "CBLB" },
  { key: "NM_170665.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "488", geneDisplay: "ATP2A2" },
  { key: "NM_170675.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4212", geneDisplay: "MEIS2" },
  { key: "NM_170678.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27231", geneDisplay: "NMRK2" },
  { key: "NM_170679.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6500", geneDisplay: "SKP1" },
  { key: "NM_170682.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22953", geneDisplay: "P2RX2" },
  { key: "NM_170686.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57541", geneDisplay: "ZNF398" },
  { key: "NM_170692.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9462", geneDisplay: "RASAL2" },
  { key: "NM_170693.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10110", geneDisplay: "SGK2" },
  { key: "NM_170699.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151306", geneDisplay: "GPBAR1" },
  { key: "NM_170707.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4000", geneDisplay: "LMNA" },
  { key: "NM_170725.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "267002", geneDisplay: "PGBD2" },
  { key: "NM_170736.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3772", geneDisplay: "KCNJ15" },
  { key: "NM_170741.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3773", geneDisplay: "KCNJ16" },
  { key: "NM_170743.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163702", geneDisplay: "IFNLR1" },
  { key: "NM_170744.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219699", geneDisplay: "UNC5B" },
  { key: "NM_170745.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221613", geneDisplay: "H2AC1" },
  { key: "NM_170746.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "280636", geneDisplay: "SELENOH" },
  { key: "NM_170753.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "267004", geneDisplay: "PGBD3" },
  { key: "NM_170754.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23371", geneDisplay: "TNS2" },
  { key: "NM_170776.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "222487", geneDisplay: "ADGRG3" },
  { key: "NM_170783.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "30834", geneDisplay: "POLR1H" },
  { key: "NM_170784.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8195", geneDisplay: "MKKS" },
  { key: "NM_171830.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27094", geneDisplay: "KCNMB3" },
  { key: "NM_171982.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23087", geneDisplay: "TRIM35" },
  { key: "NM_171998.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "116442", geneDisplay: "RAB39B" },
  { key: "NM_171999.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "27164", geneDisplay: "SALL3" },
  { key: "NM_172000.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127670", geneDisplay: "TEDDM1" },
  { key: "NM_172002.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150274", geneDisplay: "HSCB" },
  { key: "NM_172003.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150472", geneDisplay: "CBWD2" },
  { key: "NM_172005.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "164237", geneDisplay: "WFDC13" },
  { key: "NM_172006.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "280664", geneDisplay: "WFDC10B" },
  { key: "NM_172027.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80325", geneDisplay: "ABTB1" },
  { key: "NM_172037.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157506", geneDisplay: "RDH10" },
  { key: "NM_172069.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130271", geneDisplay: "PLEKHH2" },
  { key: "NM_172070.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130507", geneDisplay: "UBR3" },
  { key: "NM_172071.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149041", geneDisplay: "RC3H1" },
  { key: "NM_172095.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "117155", geneDisplay: "CATSPER2" },
  { key: "NM_172107.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3785", geneDisplay: "KCNQ2" },
  { key: "NM_172138.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "282616", geneDisplay: "IFNL2" },
  { key: "NM_172139.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "282617", geneDisplay: "IFNL3" },
  { key: "NM_172140.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "282618", geneDisplay: "IFNL1" },
  { key: "NM_172160.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7881", geneDisplay: "KCNAB1" },
  { key: "NM_172166.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4439", geneDisplay: "MSH5" },
  { key: "NM_172167.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124056", geneDisplay: "NOXO1" },
  { key: "NM_172193.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122773", geneDisplay: "KLHDC1" },
  { key: "NM_172194.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "441669", geneDisplay: "OR4Q3" },
  { key: "NM_172201.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9992", geneDisplay: "KCNE2" },
  { key: "NM_172217.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "3603", geneDisplay: "IL16" },
  { key: "NM_172219.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1440", geneDisplay: "CSF3" },
  { key: "NM_172225.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127343", geneDisplay: "DMBX1" },
  { key: "NM_172229.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79412", geneDisplay: "KREMEN2" },
  { key: "NM_172230.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84447", geneDisplay: "SYVN1" },
  { key: "NM_172231.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57794", geneDisplay: "SUGP1" },
  { key: "NM_172232.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23461", geneDisplay: "ABCA5" },
  { key: "NM_172238.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83741", geneDisplay: "TFAP2D" },
  { key: "NM_172240.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "282809", geneDisplay: "POC1B" },
  { key: "NM_172241.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "64693", geneDisplay: "CTAGE1" },
  { key: "NM_172250.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166785", geneDisplay: "MMAA" },
  { key: "NM_172251.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "116541", geneDisplay: "MRPL54" },
  { key: "NM_172314.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64806", geneDisplay: "IL25" },
  { key: "NM_172341.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55851", geneDisplay: "PSENEN" },
  { key: "NM_172347.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "93107", geneDisplay: "KCNG4" },
  { key: "NM_172351.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4179", geneDisplay: "CD46" },
  { key: "NM_172362.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3756", geneDisplay: "KCNH1" },
  { key: "NM_172364.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "93589", geneDisplay: "CACNA2D4" },
  { key: "NM_172365.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145376", geneDisplay: "PPP1R36" },
  { key: "NM_172366.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157574", geneDisplay: "FBXO16" },
  { key: "NM_172367.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "286753", geneDisplay: "TRARG1" },
  { key: "NM_172369.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "714", geneDisplay: "C1QC" },
  { key: "NM_172370.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "267012", geneDisplay: "DAOA" },
  { key: "NM_172373.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1997", geneDisplay: "ELF1" },
  { key: "NM_172377.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "30848", geneDisplay: "CTAG2" },
  { key: "NM_173039.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "282679", geneDisplay: "AQP11" },
  { key: "NM_173050.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80274", geneDisplay: "SCUBE1" },
  { key: "NM_173076.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26154", geneDisplay: "ABCA12" },
  { key: "NM_173077.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130749", geneDisplay: "CPO" },
  { key: "NM_173079.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146923", geneDisplay: "RUNDC1" },
  { key: "NM_173080.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163778", geneDisplay: "SPRR4" },
  { key: "NM_173081.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219681", geneDisplay: "ARMC3" },
  { key: "NM_173084.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "286827", geneDisplay: "TRIM59" },
  { key: "NM_173086.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "286887", geneDisplay: "KRT6C" },
  { key: "NM_173157.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3164", geneDisplay: "NR4A1" },
  { key: "NM_173160.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "53828", geneDisplay: "FXYD4" },
  { key: "NM_173161.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84639", geneDisplay: "IL1F10" },
  { key: "NM_173165.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4775", geneDisplay: "NFATC3" },
  { key: "NM_173176.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2185", geneDisplay: "PTK2B" },
  { key: "NM_173177.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10438", geneDisplay: "C1D" },
  { key: "NM_173191.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "30819", geneDisplay: "KCNIP2" },
  { key: "NM_173216.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6480", geneDisplay: "ST6GAL1" },
  { key: "NM_173344.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6482", geneDisplay: "ST3GAL1" },
  { key: "NM_173348.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "317662", geneDisplay: "FAM149B1" },
  { key: "NM_173351.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150681", geneDisplay: "OR6B3" },
  { key: "NM_173352.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196374", geneDisplay: "KRT78" },
  { key: "NM_173353.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121278", geneDisplay: "TPH2" },
  { key: "NM_173354.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "150094", geneDisplay: "SIK1" },
  { key: "NM_173355.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151531", geneDisplay: "UPP2" },
  { key: "NM_173358.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "280658", geneDisplay: "SSX7" },
  { key: "NM_173465.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91522", geneDisplay: "COL23A1" },
  { key: "NM_173467.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27349", geneDisplay: "MCAT" },
  { key: "NM_173468.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "92597", geneDisplay: "MOB1B" },
  { key: "NM_173469.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "92912", geneDisplay: "UBE2Q2" },
  { key: "NM_173470.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "93380", geneDisplay: "MMGT1" },
  { key: "NM_173473.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119504", geneDisplay: "ANAPC16" },
  { key: "NM_173474.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123803", geneDisplay: "NTAN1" },
  { key: "NM_173475.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123879", geneDisplay: "DCUN1D3" },
  { key: "NM_173477.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124590", geneDisplay: "USH1G" },
  { key: "NM_173478.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124817", geneDisplay: "CNTD1" },
  { key: "NM_173479.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126248", geneDisplay: "WDR88" },
  { key: "NM_173480.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126295", geneDisplay: "ZNF57" },
  { key: "NM_173481.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126353", geneDisplay: "MISP" },
  { key: "NM_173482.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126402", geneDisplay: "CCDC105" },
  { key: "NM_173483.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126410", geneDisplay: "CYP4F22" },
  { key: "NM_173484.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128209", geneDisplay: "KLF17" },
  { key: "NM_173485.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128553", geneDisplay: "TSHZ2" },
  { key: "NM_173488.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133482", geneDisplay: "SLCO6A1" },
  { key: "NM_173489.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133558", geneDisplay: "MROH2B" },
  { key: "NM_173490.8", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134285", geneDisplay: "TMEM171" },
  { key: "NM_173491.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134353", geneDisplay: "LSM11" },
  { key: "NM_173493.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139135", geneDisplay: "PASD1" },
  { key: "NM_173494.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139212", geneDisplay: "DNAAF6" },
  { key: "NM_173495.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139411", geneDisplay: "PTCHD1" },
  { key: "NM_173500.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "146057", geneDisplay: "TTBK2" },
  { key: "NM_173502.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146547", geneDisplay: "PRSS36" },
  { key: "NM_173503.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146779", geneDisplay: "EFCAB3" },
  { key: "NM_173505.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147463", geneDisplay: "ANKRD29" },
  { key: "NM_173506.7", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147719", geneDisplay: "LYPD4" },
  { key: "NM_173508.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148641", geneDisplay: "SLC35F3" },
  { key: "NM_173509.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148753", geneDisplay: "FAM163A" },
  { key: "NM_173510.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150275", geneDisplay: "CCDC117" },
  { key: "NM_173511.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150864", geneDisplay: "FAM117B" },
  { key: "NM_173514.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153129", geneDisplay: "SLC38A9" },
  { key: "NM_173515.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154043", geneDisplay: "CNKSR3" },
  { key: "NM_173517.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154807", geneDisplay: "VKORC1L1" },
  { key: "NM_173518.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157777", geneDisplay: "MCMDC2" },
  { key: "NM_173519.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157807", geneDisplay: "CLVS1" },
  { key: "NM_173523.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158809", geneDisplay: "MAGEB6" },
  { key: "NM_173525.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "160298", geneDisplay: "C11orf42" },
  { key: "NM_173527.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161253", geneDisplay: "REM2" },
  { key: "NM_173528.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161502", geneDisplay: "CFAP161" },
  { key: "NM_173531.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163227", geneDisplay: "ZNF100" },
  { key: "NM_173535.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165530", geneDisplay: "CLEC4F" },
  { key: "NM_173536.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2565", geneDisplay: "GABRG1" },
  { key: "NM_173537.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84163", geneDisplay: "GTF2IRD2" },
  { key: "NM_173538.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "168975", geneDisplay: "CNBD1" },
  { key: "NM_173540.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "170384", geneDisplay: "FUT11" },
  { key: "NM_173542.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196463", geneDisplay: "PLBD2" },
  { key: "NM_173543.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "199221", geneDisplay: "DZIP1L" },
  { key: "NM_173545.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200558", geneDisplay: "APLF" },
  { key: "NM_173546.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200942", geneDisplay: "KLHDC8B" },
  { key: "NM_173547.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201292", geneDisplay: "TRIM65" },
  { key: "NM_173548.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "201514", geneDisplay: "ZNF584" },
  { key: "NM_173549.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203111", geneDisplay: "ERICH5" },
  { key: "NM_173550.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203238", geneDisplay: "CCDC171" },
  { key: "NM_173551.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203286", geneDisplay: "ANKS6" },
  { key: "NM_173552.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "205428", geneDisplay: "DIPK2A" },
  { key: "NM_173553.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "205860", geneDisplay: "TRIML2" },
  { key: "NM_173555.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219743", geneDisplay: "TYSND1" },
  { key: "NM_173557.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "220441", geneDisplay: "RNF152" },
  { key: "NM_173558.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221472", geneDisplay: "FGD2" },
  { key: "NM_173560.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222546", geneDisplay: "RFX6" },
  { key: "NM_173561.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222643", geneDisplay: "UNC5CL" },
  { key: "NM_173562.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222658", geneDisplay: "KCTD20" },
  { key: "NM_173563.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222826", geneDisplay: "FAM217A" },
  { key: "NM_173564.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222950", geneDisplay: "NYAP1" },
  { key: "NM_173565.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222967", geneDisplay: "RSPH10B" },
  { key: "NM_173566.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "253143", geneDisplay: "PRR14L" },
  { key: "NM_173567.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "253152", geneDisplay: "EPHX4" },
  { key: "NM_173569.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "254048", geneDisplay: "UBN2" },
  { key: "NM_173571.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "255313", geneDisplay: "CT47A11" },
  { key: "NM_173573.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256329", geneDisplay: "LMNTD2" },
  { key: "NM_173575.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282974", geneDisplay: "STK32C" },
  { key: "NM_173576.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "283078", geneDisplay: "MKX" },
  { key: "NM_173582.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283209", geneDisplay: "PGM2L1" },
  { key: "NM_173587.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283248", geneDisplay: "RCOR2" },
  { key: "NM_173588.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283284", geneDisplay: "IGSF22" },
  { key: "NM_173593.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283358", geneDisplay: "B4GALNT3" },
  { key: "NM_173595.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283373", geneDisplay: "ANKRD52" },
  { key: "NM_173596.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283375", geneDisplay: "SLC39A5" },
  { key: "NM_173598.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283455", geneDisplay: "KSR2" },
  { key: "NM_173600.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283463", geneDisplay: "MUC19" },
  { key: "NM_173601.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283464", geneDisplay: "GXYLT1" },
  { key: "NM_173602.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57609", geneDisplay: "DIP2B" },
  { key: "NM_173605.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "283518", geneDisplay: "KCNRG" },
  { key: "NM_173607.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283635", geneDisplay: "FAM177A1" },
  { key: "NM_173611.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283742", geneDisplay: "FAM98B" },
  { key: "NM_173614.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283820", geneDisplay: "NOMO2" },
  { key: "NM_173615.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146177", geneDisplay: "VWA3A" },
  { key: "NM_173618.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283899", geneDisplay: "INO80E" },
  { key: "NM_173619.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283971", geneDisplay: "CLEC18C" },
  { key: "NM_173625.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284099", geneDisplay: "C17orf78" },
  { key: "NM_173627.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284131", geneDisplay: "ENDOV" },
  { key: "NM_173628.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8632", geneDisplay: "DNAH17" },
  { key: "NM_173629.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284254", geneDisplay: "DYNAP" },
  { key: "NM_173630.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "25914", geneDisplay: "RTTN" },
  { key: "NM_173631.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284306", geneDisplay: "ZNF547" },
  { key: "NM_173632.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284309", geneDisplay: "ZNF776" },
  { key: "NM_173633.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284339", geneDisplay: "TMEM145" },
  { key: "NM_173637.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284427", geneDisplay: "SLC25A41" },
  { key: "NM_173642.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284716", geneDisplay: "RIMKLA" },
  { key: "NM_173647.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "284996", geneDisplay: "RNF149" },
  { key: "NM_173648.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285025", geneDisplay: "CCDC141" },
  { key: "NM_173650.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285126", geneDisplay: "DNAJC5G" },
  { key: "NM_173651.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "401024", geneDisplay: "FSIP2" },
  { key: "NM_173653.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285195", geneDisplay: "SLC9A9" },
  { key: "NM_173658.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285349", geneDisplay: "ZNF660" },
  { key: "NM_173659.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285367", geneDisplay: "RPUSD3" },
  { key: "NM_173660.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285489", geneDisplay: "DOK7" },
  { key: "NM_173662.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285533", geneDisplay: "RNF175" },
  { key: "NM_173663.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285596", geneDisplay: "FAM153A" },
  { key: "NM_173664.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285598", geneDisplay: "ARL10" },
  { key: "NM_173666.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285605", geneDisplay: "DTWD2" },
  { key: "NM_173672.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285755", geneDisplay: "PPIL6" },
  { key: "NM_173680.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285971", geneDisplay: "ZNF775" },
  { key: "NM_173683.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286046", geneDisplay: "XKR6" },
  { key: "NM_173685.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286053", geneDisplay: "NSMCE2" },
  { key: "NM_173689.7", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286204", geneDisplay: "CRB2" },
  { key: "NM_173699.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286514", geneDisplay: "MAGEB18" },
  { key: "NM_173728.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22899", geneDisplay: "ARHGEF15" },
  { key: "NM_173791.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118987", geneDisplay: "PDZD8" },
  { key: "NM_173793.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "128977", geneDisplay: "C22orf39" },
  { key: "NM_173794.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139341", geneDisplay: "FUNDC1" },
  { key: "NM_173798.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170261", geneDisplay: "ZCCHC12" },
  { key: "NM_173799.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201633", geneDisplay: "TIGIT" },
  { key: "NM_173800.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "206338", geneDisplay: "LVRN" },
  { key: "NM_173801.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219990", geneDisplay: "OOSP2" },
  { key: "NM_173804.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "255043", geneDisplay: "TMEM86B" },
  { key: "NM_173808.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257194", geneDisplay: "NEGR1" },
  { key: "NM_173809.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282991", geneDisplay: "BLOC1S2" },
  { key: "NM_173810.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283237", geneDisplay: "TTC9C" },
  { key: "NM_173811.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283254", geneDisplay: "HARBI1" },
  { key: "NM_173812.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283417", geneDisplay: "DPY19L2" },
  { key: "NM_173814.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283659", geneDisplay: "PRTG" },
  { key: "NM_173821.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285093", geneDisplay: "RTP5" },
  { key: "NM_173824.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285237", geneDisplay: "C3orf38" },
  { key: "NM_173825.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285282", geneDisplay: "RABL3" },
  { key: "NM_173826.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285343", geneDisplay: "TCAIM" },
  { key: "NM_173828.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285613", geneDisplay: "RELL2" },
  { key: "NM_173829.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285672", geneDisplay: "SREK1IP1" },
  { key: "NM_173832.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286128", geneDisplay: "ZFP41" },
  { key: "NM_173833.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286133", geneDisplay: "SCARA5" },
  { key: "NM_173834.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286451", geneDisplay: "YIPF6" },
  { key: "NM_173842.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3557", geneDisplay: "IL1RN" },
  { key: "NM_173846.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122664", geneDisplay: "TPPP2" },
  { key: "NM_173847.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124912", geneDisplay: "SPACA3" },
  { key: "NM_173848.7", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "138046", geneDisplay: "RALYL" },
  { key: "NM_173849.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145258", geneDisplay: "GSC" },
  { key: "NM_173851.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169026", geneDisplay: "SLC30A8" },
  { key: "NM_173852.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200185", geneDisplay: "KRTCAP2" },
  { key: "NM_173853.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200634", geneDisplay: "KRTCAP3" },
  { key: "NM_173854.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "254428", geneDisplay: "SLC41A1" },
  { key: "NM_173855.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283385", geneDisplay: "MORN3" },
  { key: "NM_173856.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "317701", geneDisplay: "VN1R2" },
  { key: "NM_173857.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "317703", geneDisplay: "VN1R4" },
  { key: "NM_173860.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3228", geneDisplay: "HOXC12" },
  { key: "NM_174858.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26289", geneDisplay: "AK5" },
  { key: "NM_174878.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7401", geneDisplay: "CLRN1" },
  { key: "NM_174889.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91942", geneDisplay: "NDUFAF2" },
  { key: "NM_174890.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "93550", geneDisplay: "ZFAND4" },
  { key: "NM_174891.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122616", geneDisplay: "CLBA1" },
  { key: "NM_174892.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124599", geneDisplay: "CD300LB" },
  { key: "NM_174893.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124944", geneDisplay: "C17orf49" },
  { key: "NM_174895.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126006", geneDisplay: "PCP2" },
  { key: "NM_174897.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128859", geneDisplay: "BPIFB6" },
  { key: "NM_174898.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129530", geneDisplay: "LYG1" },
  { key: "NM_174899.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130888", geneDisplay: "FBXO36" },
  { key: "NM_174900.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132625", geneDisplay: "ZFP42" },
  { key: "NM_174901.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "171484", geneDisplay: "FAM9C" },
  { key: "NM_174902.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143458", geneDisplay: "LDLRAD3" },
  { key: "NM_174903.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146310", geneDisplay: "RNF151" },
  { key: "NM_174905.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147965", geneDisplay: "FAM98C" },
  { key: "NM_174907.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151987", geneDisplay: "PPP4R2" },
  { key: "NM_174909.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153339", geneDisplay: "TMEM167A" },
  { key: "NM_174910.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6991", geneDisplay: "DYNLT2" },
  { key: "NM_174911.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157638", geneDisplay: "LRATD2" },
  { key: "NM_174912.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158584", geneDisplay: "FAAH2" },
  { key: "NM_174913.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161424", geneDisplay: "NOP9" },
  { key: "NM_174914.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167127", geneDisplay: "UGT3A2" },
  { key: "NM_174916.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "197131", geneDisplay: "UBR1" },
  { key: "NM_174918.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199675", geneDisplay: "MCEMP1" },
  { key: "NM_174921.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201895", geneDisplay: "SMIM14" },
  { key: "NM_174922.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203054", geneDisplay: "ADCK5" },
  { key: "NM_174923.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203260", geneDisplay: "CCDC107" },
  { key: "NM_174924.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "204474", geneDisplay: "PDILT" },
  { key: "NM_174927.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219938", geneDisplay: "SPATA19" },
  { key: "NM_174931.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "253635", geneDisplay: "GPATCH11" },
  { key: "NM_174932.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "254240", geneDisplay: "BPIFC" },
  { key: "NM_174934.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6330", geneDisplay: "SCN4B" },
  { key: "NM_174936.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "255738", geneDisplay: "PCSK9" },
  { key: "NM_174937.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "256536", geneDisplay: "TCERG1L" },
  { key: "NM_174938.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "257019", geneDisplay: "FRMD3" },
  { key: "NM_174941.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283316", geneDisplay: "CD163L1" },
  { key: "NM_174942.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283431", geneDisplay: "GAS2L3" },
  { key: "NM_174945.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284346", geneDisplay: "ZNF575" },
  { key: "NM_174951.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "171482", geneDisplay: "FAM9A" },
  { key: "NM_174952.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285555", geneDisplay: "STPG2" },
  { key: "NM_174975.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "266629", geneDisplay: "SEC14L3" },
  { key: "NM_174976.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283576", geneDisplay: "ZDHHC22" },
  { key: "NM_174977.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "284904", geneDisplay: "SEC14L4" },
  { key: "NM_174978.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "317761", geneDisplay: "C14orf39" },
  { key: "NM_174981.6", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "317754", geneDisplay: "POTED" },
  { key: "NM_174983.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126321", geneDisplay: "MFSD12" },
  { key: "NM_175039.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27090", geneDisplay: "ST6GALNAC4" },
  { key: "NM_175053.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121391", geneDisplay: "KRT74" },
  { key: "NM_175054.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121504", geneDisplay: "H4-16" },
  { key: "NM_175055.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128312", geneDisplay: "H2BU1" },
  { key: "NM_175057.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134860", geneDisplay: "TAAR9" },
  { key: "NM_175060.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161198", geneDisplay: "CLEC14A" },
  { key: "NM_175061.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221895", geneDisplay: "JAZF1" },
  { key: "NM_175062.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "255426", geneDisplay: "RASGEF1C" },
  { key: "NM_175065.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "317772", geneDisplay: "H2AC21" },
  { key: "NM_175066.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "317781", geneDisplay: "DDX51" },
  { key: "NM_175067.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "319100", geneDisplay: "TAAR6" },
  { key: "NM_175068.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "319101", geneDisplay: "KRT73" },
  { key: "NM_175078.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "374454", geneDisplay: "KRT77" },
  { key: "NM_175571.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155038", geneDisplay: "GIMAP8" },
  { key: "NM_175575.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124857", geneDisplay: "WFIKKN2" },
  { key: "NM_175607.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152330", geneDisplay: "CNTN4" },
  { key: "NM_175614.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126328", geneDisplay: "NDUFA11" },
  { key: "NM_175619.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "326340", geneDisplay: "ZAR1" },
  { key: "NM_175698.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6757", geneDisplay: "SSX2" },
  { key: "NM_175709.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23492", geneDisplay: "CBX7" },
  { key: "NM_175710.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1379", geneDisplay: "CR1L" },
  { key: "NM_175723.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6758", geneDisplay: "SSX5" },
  { key: "NM_175726.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3568", geneDisplay: "IL5RA" },
  { key: "NM_175732.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114971", geneDisplay: "PTPMT1" },
  { key: "NM_175733.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143425", geneDisplay: "SYT9" },
  { key: "NM_175734.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201243", geneDisplay: "SPEM2" },
  { key: "NM_175735.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "254773", geneDisplay: "LYG2" },
  { key: "NM_175736.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91010", geneDisplay: "FMNL3" },
  { key: "NM_175737.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152831", geneDisplay: "KLB" },
  { key: "NM_175739.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "327657", geneDisplay: "SERPINA9" },
  { key: "NM_175744.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "389", geneDisplay: "RHOC" },
  { key: "NM_175747.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "167826", geneDisplay: "OLIG3" },
  { key: "NM_175748.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55148", geneDisplay: "UBR7" },
  { key: "NM_175769.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150921", geneDisplay: "TCF23" },
  { key: "NM_175834.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338785", geneDisplay: "KRT79" },
  { key: "NM_175839.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54498", geneDisplay: "SMOX" },
  { key: "NM_175852.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200081", geneDisplay: "TXLNA" },
  { key: "NM_175854.8", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "255967", geneDisplay: "PAN3" },
  { key: "NM_175856.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "337876", geneDisplay: "CHSY3" },
  { key: "NM_175857.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337879", geneDisplay: "KRTAP8-1" },
  { key: "NM_175858.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337880", geneDisplay: "KRTAP11-1" },
  { key: "NM_175859.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56474", geneDisplay: "CTPS2" },
  { key: "NM_175862.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "942", geneDisplay: "CD86" },
  { key: "NM_175866.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127933", geneDisplay: "UHMK1" },
  { key: "NM_175867.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "29947", geneDisplay: "DNMT3L" },
  { key: "NM_175871.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126074", geneDisplay: "SWSAP1" },
  { key: "NM_175872.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126375", geneDisplay: "ZNF792" },
  { key: "NM_175873.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134548", geneDisplay: "SOWAHA" },
  { key: "NM_175874.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144608", geneDisplay: "C12orf60" },
  { key: "NM_175875.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147912", geneDisplay: "SIX5" },
  { key: "NM_175876.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149371", geneDisplay: "EXOC8" },
  { key: "NM_175881.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161753", geneDisplay: "ODF3L1" },
  { key: "NM_175882.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162540", geneDisplay: "SPPL2C" },
  { key: "NM_175883.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162998", geneDisplay: "OR7D2" },
  { key: "NM_175884.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168455", geneDisplay: "CCDC71L" },
  { key: "NM_175885.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220382", geneDisplay: "FAM181B" },
  { key: "NM_175886.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221823", geneDisplay: "PRPS1L1" },
  { key: "NM_175887.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222171", geneDisplay: "PRR15" },
  { key: "NM_175888.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "253639", geneDisplay: "ZNF620" },
  { key: "NM_175900.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283897", geneDisplay: "C16orf54" },
  { key: "NM_175907.6", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284273", geneDisplay: "ZADH2" },
  { key: "NM_175914.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3172", geneDisplay: "HNF4A" },
  { key: "NM_175921.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285636", geneDisplay: "RIMOC1" },
  { key: "NM_175922.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285800", geneDisplay: "PRR18" },
  { key: "NM_175940.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "53905", geneDisplay: "DUOX1" },
  { key: "NM_176096.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80279", geneDisplay: "CDK5RAP3" },
  { key: "NM_176782.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "338094", geneDisplay: "FAM151A" },
  { key: "NM_176787.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23556", geneDisplay: "PIGN" },
  { key: "NM_176791.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149699", geneDisplay: "GTSF1L" },
  { key: "NM_176810.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126204", geneDisplay: "NLRP13" },
  { key: "NM_176811.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126205", geneDisplay: "NLRP8" },
  { key: "NM_176812.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128866", geneDisplay: "CHMP4B" },
  { key: "NM_176813.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155465", geneDisplay: "AGR3" },
  { key: "NM_176814.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168850", geneDisplay: "ZNF800" },
  { key: "NM_176815.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200895", geneDisplay: "DHFR2" },
  { key: "NM_176816.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202243", geneDisplay: "CCDC125" },
  { key: "NM_176817.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5726", geneDisplay: "TAS2R38" },
  { key: "NM_176818.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283459", geneDisplay: "GATC" },
  { key: "NM_176819.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79742", geneDisplay: "DIPK2B" },
  { key: "NM_176820.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "338321", geneDisplay: "NLRP9" },
  { key: "NM_176822.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338323", geneDisplay: "NLRP14" },
  { key: "NM_176823.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "338324", geneDisplay: "S100A7A" },
  { key: "NM_176824.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55212", geneDisplay: "BBS7" },
  { key: "NM_176869.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27068", geneDisplay: "PPA2" },
  { key: "NM_176870.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4499", geneDisplay: "MT1M" },
  { key: "NM_176875.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "887", geneDisplay: "CCKBR" },
  { key: "NM_176880.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126382", geneDisplay: "NR2C2AP" },
  { key: "NM_176881.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "259285", geneDisplay: "TAS2R39" },
  { key: "NM_176882.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "259286", geneDisplay: "TAS2R40" },
  { key: "NM_176883.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "259287", geneDisplay: "TAS2R41" },
  { key: "NM_176884.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259289", geneDisplay: "TAS2R43" },
  { key: "NM_176885.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259290", geneDisplay: "TAS2R31" },
  { key: "NM_176887.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259292", geneDisplay: "TAS2R46" },
  { key: "NM_176888.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259294", geneDisplay: "TAS2R19" },
  { key: "NM_176889.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259295", geneDisplay: "TAS2R20" },
  { key: "NM_176890.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259296", geneDisplay: "TAS2R50" },
  { key: "NM_176891.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "338376", geneDisplay: "IFNE" },
  { key: "NM_176894.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "53829", geneDisplay: "P2RY13" },
  { key: "NM_177398.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4009", geneDisplay: "LMX1A" },
  { key: "NM_177400.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84504", geneDisplay: "NKX6-2" },
  { key: "NM_177402.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127833", geneDisplay: "SYT2" },
  { key: "NM_177404.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4112", geneDisplay: "MAGEB1" },
  { key: "NM_177417.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147700", geneDisplay: "KLC3" },
  { key: "NM_177424.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23673", geneDisplay: "STX12" },
  { key: "NM_177433.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10916", geneDisplay: "MAGED2" },
  { key: "NM_177437.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "338398", geneDisplay: "TAS2R60" },
  { key: "NM_177438.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23405", geneDisplay: "DICER1" },
  { key: "NM_177454.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165215", geneDisplay: "FAM171B" },
  { key: "NM_177455.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168620", geneDisplay: "BHLHA15" },
  { key: "NM_177458.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "432355", geneDisplay: "SLURP2" },
  { key: "NM_177477.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "66004", geneDisplay: "LYNX1" },
  { key: "NM_177478.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "94033", geneDisplay: "FTMT" },
  { key: "NM_177531.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "93035", geneDisplay: "PKHD1L1" },
  { key: "NM_177532.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166824", geneDisplay: "RASSF6" },
  { key: "NM_177533.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "256281", geneDisplay: "NUDT14" },
  { key: "NM_177538.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57404", geneDisplay: "CYP20A1" },
  { key: "NM_177550.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284111", geneDisplay: "SLC13A5" },
  { key: "NM_177551.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338442", geneDisplay: "HCAR2" },
  { key: "NM_177552.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6818", geneDisplay: "SULT1A3" },
  { key: "NM_177559.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1457", geneDisplay: "CSNK2A1" },
  { key: "NM_177924.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "427", geneDisplay: "ASAH1" },
  { key: "NM_177925.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55766", geneDisplay: "H2AJ" },
  { key: "NM_177939.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54681", geneDisplay: "P4HTM" },
  { key: "NM_177947.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "51566", geneDisplay: "ARMCX3" },
  { key: "NM_177949.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9823", geneDisplay: "ARMCX2" },
  { key: "NM_177963.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91683", geneDisplay: "SYT12" },
  { key: "NM_177964.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130576", geneDisplay: "LYPD6B" },
  { key: "NM_177965.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157657", geneDisplay: "CFAP418" },
  { key: "NM_177966.7", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201626", geneDisplay: "PDE12" },
  { key: "NM_177972.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7275", geneDisplay: "TUB" },
  { key: "NM_177973.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6820", geneDisplay: "SULT2B1" },
  { key: "NM_177977.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9001", geneDisplay: "HAP1" },
  { key: "NM_177980.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "60437", geneDisplay: "CDH26" },
  { key: "NM_177983.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5496", geneDisplay: "PPM1G" },
  { key: "NM_177986.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147409", geneDisplay: "DSG4" },
  { key: "NM_177987.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "347688", geneDisplay: "TUBB8" },
  { key: "NM_177990.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57144", geneDisplay: "PAK5" },
  { key: "NM_177998.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "133060", geneDisplay: "OTOP1" },
  { key: "NM_178000.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5524", geneDisplay: "PTPA" },
  { key: "NM_178006.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "90627", geneDisplay: "STARD13" },
  { key: "NM_178009.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "160851", geneDisplay: "DGKH" },
  { key: "NM_178011.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "347731", geneDisplay: "LRRTM3" },
  { key: "NM_178012.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "347733", geneDisplay: "TUBB2B" },
  { key: "NM_178013.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145270", geneDisplay: "PRIMA1" },
  { key: "NM_178014.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "203068", geneDisplay: "TUBB" },
  { key: "NM_178019.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "347732", geneDisplay: "CATSPER3" },
  { key: "NM_178026.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2686", geneDisplay: "GGT7" },
  { key: "NM_178031.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54972", geneDisplay: "TMEM132A" },
  { key: "NM_178033.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "260293", geneDisplay: "CYP4X1" },
  { key: "NM_178034.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283748", geneDisplay: "PLA2G4D" },
  { key: "NM_178040.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23085", geneDisplay: "ERC1" },
  { key: "NM_178120.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1745", geneDisplay: "DLX1" },
  { key: "NM_178123.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91404", geneDisplay: "SESTD1" },
  { key: "NM_178125.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135892", geneDisplay: "TRIM50" },
  { key: "NM_178126.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162427", geneDisplay: "RETREG3" },
  { key: "NM_178127.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "253935", geneDisplay: "ANGPTL5" },
  { key: "NM_178128.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "283985", geneDisplay: "FADS6" },
  { key: "NM_178134.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199974", geneDisplay: "CYP4Z1" },
  { key: "NM_178135.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345275", geneDisplay: "HSD17B13" },
  { key: "NM_178138.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8022", geneDisplay: "LHX3" },
  { key: "NM_178140.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23037", geneDisplay: "PDZD2" },
  { key: "NM_178148.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "347734", geneDisplay: "SLC35B2" },
  { key: "NM_178150.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84893", geneDisplay: "FBH1" },
  { key: "NM_178160.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92736", geneDisplay: "OTOP2" },
  { key: "NM_178161.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "256297", geneDisplay: "PTF1A" },
  { key: "NM_178167.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "90850", geneDisplay: "ZNF598" },
  { key: "NM_178168.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144124", geneDisplay: "OR10A5" },
  { key: "NM_178169.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283349", geneDisplay: "RASSF3" },
  { key: "NM_178170.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284086", geneDisplay: "NEK8" },
  { key: "NM_178171.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284110", geneDisplay: "GSDMA" },
  { key: "NM_178172.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "338328", geneDisplay: "GPIHBP1" },
  { key: "NM_178174.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340205", geneDisplay: "TREML1" },
  { key: "NM_178175.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340596", geneDisplay: "LHFPL1" },
  { key: "NM_178176.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346606", geneDisplay: "MOGAT3" },
  { key: "NM_178229.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128239", geneDisplay: "IQGAP3" },
  { key: "NM_178232.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145864", geneDisplay: "HAPLN3" },
  { key: "NM_178238.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29990", geneDisplay: "PILRB" },
  { key: "NM_178310.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "333929", geneDisplay: "SNAI3" },
  { key: "NM_178311.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "92086", geneDisplay: "GGTLC1" },
  { key: "NM_178314.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "353116", geneDisplay: "RILPL1" },
  { key: "NM_178329.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1232", geneDisplay: "CCR3" },
  { key: "NM_178331.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "2797", geneDisplay: "GNRH2" },
  { key: "NM_178335.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152137", geneDisplay: "CCDC50" },
  { key: "NM_178348.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353131", geneDisplay: "LCE1A" },
  { key: "NM_178349.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353132", geneDisplay: "LCE1B" },
  { key: "NM_178351.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353133", geneDisplay: "LCE1C" },
  { key: "NM_178352.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353134", geneDisplay: "LCE1D" },
  { key: "NM_178353.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353135", geneDisplay: "LCE1E" },
  { key: "NM_178354.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353137", geneDisplay: "LCE1F" },
  { key: "NM_178422.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "164091", geneDisplay: "PAQR7" },
  { key: "NM_178424.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11063", geneDisplay: "SOX30" },
  { key: "NM_178425.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9734", geneDisplay: "HDAC9" },
  { key: "NM_178428.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353139", geneDisplay: "LCE2A" },
  { key: "NM_178429.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353140", geneDisplay: "LCE2C" },
  { key: "NM_178430.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353141", geneDisplay: "LCE2D" },
  { key: "NM_178431.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353142", geneDisplay: "LCE3A" },
  { key: "NM_178433.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353143", geneDisplay: "LCE3B" },
  { key: "NM_178434.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353144", geneDisplay: "LCE3C" },
  { key: "NM_178435.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353145", geneDisplay: "LCE3E" },
  { key: "NM_178438.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "254910", geneDisplay: "LCE5A" },
  { key: "NM_178439.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64395", geneDisplay: "GMCL1" },
  { key: "NM_178448.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "89958", geneDisplay: "SAPCD2" },
  { key: "NM_178449.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113091", geneDisplay: "PTH2" },
  { key: "NM_178450.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "115123", geneDisplay: "MARCHF3" },
  { key: "NM_178452.6", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123872", geneDisplay: "DNAAF1" },
  { key: "NM_178455.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "391253", geneDisplay: "SPINT4" },
  { key: "NM_178456.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128602", geneDisplay: "C20orf85" },
  { key: "NM_178457.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128611", geneDisplay: "ZNF831" },
  { key: "NM_178460.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128646", geneDisplay: "SIRPD" },
  { key: "NM_178466.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128861", geneDisplay: "BPIFA3" },
  { key: "NM_178468.6", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128876", geneDisplay: "FAM83C" },
  { key: "NM_178469.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138307", geneDisplay: "LCN8" },
  { key: "NM_178470.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139170", geneDisplay: "DCAF12L1" },
  { key: "NM_178471.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139760", geneDisplay: "GPR119" },
  { key: "NM_178477.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140836", geneDisplay: "BANF2" },
  { key: "NM_178483.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140856", geneDisplay: "SCP2D1" },
  { key: "NM_178491.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140902", geneDisplay: "R3HDML" },
  { key: "NM_178493.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147111", geneDisplay: "NOTUM" },
  { key: "NM_178496.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151963", geneDisplay: "MB21D2" },
  { key: "NM_178497.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152816", geneDisplay: "ODAPH" },
  { key: "NM_178498.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "159963", geneDisplay: "SLC5A12" },
  { key: "NM_178499.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160777", geneDisplay: "CCDC60" },
  { key: "NM_178500.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162466", geneDisplay: "PHOSPHO1" },
  { key: "NM_178502.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196403", geneDisplay: "DTX3" },
  { key: "NM_178505.8", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219623", geneDisplay: "TMEM26" },
  { key: "NM_178507.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220323", geneDisplay: "OAF" },
  { key: "NM_178509.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "252983", geneDisplay: "STXBP4" },
  { key: "NM_178510.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "255239", geneDisplay: "ANKK1" },
  { key: "NM_178511.6", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "255783", geneDisplay: "INAFM1" },
  { key: "NM_178516.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283849", geneDisplay: "EXOC3L1" },
  { key: "NM_178518.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284114", geneDisplay: "TMEM102" },
  { key: "NM_178523.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90317", geneDisplay: "ZNF616" },
  { key: "NM_178525.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284382", geneDisplay: "ACTL9" },
  { key: "NM_178526.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284439", geneDisplay: "SLC25A42" },
  { key: "NM_178527.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284525", geneDisplay: "SLC9C2" },
  { key: "NM_178536.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286256", geneDisplay: "LCN12" },
  { key: "NM_178537.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338707", geneDisplay: "B4GALNT4" },
  { key: "NM_178539.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338811", geneDisplay: "TAFA2" },
  { key: "NM_178540.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "338872", geneDisplay: "C1QTNF9" },
  { key: "NM_178543.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339221", geneDisplay: "ENPP7" },
  { key: "NM_178544.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339327", geneDisplay: "ZNF546" },
  { key: "NM_178545.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339456", geneDisplay: "TMEM52" },
  { key: "NM_178547.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339487", geneDisplay: "ZBTB8OS" },
  { key: "NM_178548.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339488", geneDisplay: "TFAP2E" },
  { key: "NM_178553.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339779", geneDisplay: "PRR30" },
  { key: "NM_178554.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "339855", geneDisplay: "KY" },
  { key: "NM_178556.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "339976", geneDisplay: "TRIML1" },
  { key: "NM_178557.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "339983", geneDisplay: "NAT8L" },
  { key: "NM_178558.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340252", geneDisplay: "ZNF680" },
  { key: "NM_178561.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340307", geneDisplay: "CTAGE6" },
  { key: "NM_178562.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340348", geneDisplay: "TSPAN33" },
  { key: "NM_178563.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340351", geneDisplay: "AGBL3" },
  { key: "NM_178564.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340371", geneDisplay: "NRBP2" },
  { key: "NM_178565.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340419", geneDisplay: "RSPO2" },
  { key: "NM_178566.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "340481", geneDisplay: "ZDHHC21" },
  { key: "NM_178568.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146760", geneDisplay: "RTN4RL1" },
  { key: "NM_178570.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "349667", geneDisplay: "RTN4RL2" },
  { key: "NM_178581.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "81502", geneDisplay: "HM13" },
  { key: "NM_178812.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "92140", geneDisplay: "MTDH" },
  { key: "NM_178813.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158798", geneDisplay: "AKAP14" },
  { key: "NM_178815.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221079", geneDisplay: "ARL5B" },
  { key: "NM_178819.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137964", geneDisplay: "GPAT4" },
  { key: "NM_178820.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126433", geneDisplay: "FBXO27" },
  { key: "NM_178821.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "164781", geneDisplay: "DAW1" },
  { key: "NM_178822.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285313", geneDisplay: "IGSF10" },
  { key: "NM_178827.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154865", geneDisplay: "IQUB" },
  { key: "NM_178828.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286234", geneDisplay: "SPATA31E1" },
  { key: "NM_178832.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118812", geneDisplay: "MORN4" },
  { key: "NM_178833.7", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "133308", geneDisplay: "SLC9B2" },
  { key: "NM_178834.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143903", geneDisplay: "LAYN" },
  { key: "NM_178836.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201164", geneDisplay: "PLD6" },
  { key: "NM_178839.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "347730", geneDisplay: "LRRTM1" },
  { key: "NM_178840.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149563", geneDisplay: "SRARP" },
  { key: "NM_178841.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "115992", geneDisplay: "RNF166" },
  { key: "NM_178844.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197358", geneDisplay: "NLRC3" },
  { key: "NM_178857.6", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "94137", geneDisplay: "RP1L1" },
  { key: "NM_178858.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118980", geneDisplay: "SFXN2" },
  { key: "NM_178859.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123264", geneDisplay: "SLC51B" },
  { key: "NM_178860.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124925", geneDisplay: "SEZ6" },
  { key: "NM_178861.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "140432", geneDisplay: "RNF113B" },
  { key: "NM_178862.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201595", geneDisplay: "STT3B" },
  { key: "NM_178863.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "253980", geneDisplay: "KCTD13" },
  { key: "NM_178864.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "266743", geneDisplay: "NPAS4" },
  { key: "NM_178865.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "347735", geneDisplay: "SERINC2" },
  { key: "NM_178868.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152189", geneDisplay: "CMTM8" },
  { key: "NM_180982.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122704", geneDisplay: "MRPL52" },
  { key: "NM_180989.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "160897", geneDisplay: "GPR180" },
  { key: "NM_180990.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "353174", geneDisplay: "ZACN" },
  { key: "NM_180991.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "353189", geneDisplay: "SLCO4C1" },
  { key: "NM_181077.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23015", geneDisplay: "GOLGA8A" },
  { key: "NM_181078.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "50615", geneDisplay: "IL21R" },
  { key: "NM_181265.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "116966", geneDisplay: "WDR17" },
  { key: "NM_181303.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54413", geneDisplay: "NLGN3" },
  { key: "NM_181332.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57502", geneDisplay: "NLGN4X" },
  { key: "NM_181333.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55615", geneDisplay: "PRR5" },
  { key: "NM_181335.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23779", geneDisplay: "ARHGAP8" },
  { key: "NM_181336.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221496", geneDisplay: "LEMD2" },
  { key: "NM_181337.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "353219", geneDisplay: "KAAG1" },
  { key: "NM_181342.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51661", geneDisplay: "FKBP7" },
  { key: "NM_181349.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57154", geneDisplay: "SMURF1" },
  { key: "NM_181351.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4684", geneDisplay: "NCAM1" },
  { key: "NM_181361.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10242", geneDisplay: "KCNMB2" },
  { key: "NM_181426.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "339829", geneDisplay: "CCDC39" },
  { key: "NM_181429.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "353164", geneDisplay: "TAS2R42" },
  { key: "NM_181435.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "114899", geneDisplay: "C1QTNF3" },
  { key: "NM_181449.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342510", geneDisplay: "CD300E" },
  { key: "NM_181453.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9648", geneDisplay: "GCC2" },
  { key: "NM_181458.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5077", geneDisplay: "PAX3" },
  { key: "NM_181463.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128308", geneDisplay: "MRPL55" },
  { key: "NM_181471.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5982", geneDisplay: "RFC2" },
  { key: "NM_181485.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84619", geneDisplay: "ZGPAT" },
  { key: "NM_181486.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6910", geneDisplay: "TBX5" },
  { key: "NM_181489.6", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "353274", geneDisplay: "ZNF445" },
  { key: "NM_181501.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3672", geneDisplay: "ITGA1" },
  { key: "NM_181503.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "11340", geneDisplay: "EXOSC8" },
  { key: "NM_181506.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100130733", geneDisplay: "LRRC70" },
  { key: "NM_181507.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11234", geneDisplay: "HPS5" },
  { key: "NM_181514.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219927", geneDisplay: "MRPL21" },
  { key: "NM_181521.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146223", geneDisplay: "CMTM4" },
  { key: "NM_181523.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5295", geneDisplay: "PIK3R1" },
  { key: "NM_181532.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3266", geneDisplay: "ERAS" },
  { key: "NM_181534.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147183", geneDisplay: "KRT25" },
  { key: "NM_181535.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162605", geneDisplay: "KRT28" },
  { key: "NM_181536.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "342372", geneDisplay: "PKD1L3" },
  { key: "NM_181537.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342574", geneDisplay: "KRT27" },
  { key: "NM_181538.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "349149", geneDisplay: "GJC3" },
  { key: "NM_181539.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "353288", geneDisplay: "KRT26" },
  { key: "NM_181552.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1523", geneDisplay: "CUX1" },
  { key: "NM_181553.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123920", geneDisplay: "CMTM3" },
  { key: "NM_181575.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "550", geneDisplay: "AUP1" },
  { key: "NM_181581.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11062", geneDisplay: "DUS4L" },
  { key: "NM_181599.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "140258", geneDisplay: "KRTAP13-1" },
  { key: "NM_181600.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "284827", geneDisplay: "KRTAP13-4" },
  { key: "NM_181602.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337966", geneDisplay: "KRTAP6-1" },
  { key: "NM_181604.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337967", geneDisplay: "KRTAP6-2" },
  { key: "NM_181605.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337968", geneDisplay: "KRTAP6-3" },
  { key: "NM_181606.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337878", geneDisplay: "KRTAP7-1" },
  { key: "NM_181607.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337882", geneDisplay: "KRTAP19-1" },
  { key: "NM_181608.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337969", geneDisplay: "KRTAP19-2" },
  { key: "NM_181609.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337970", geneDisplay: "KRTAP19-3" },
  { key: "NM_181610.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337971", geneDisplay: "KRTAP19-4" },
  { key: "NM_181611.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337972", geneDisplay: "KRTAP19-5" },
  { key: "NM_181612.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337973", geneDisplay: "KRTAP19-6" },
  { key: "NM_181614.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337974", geneDisplay: "KRTAP19-7" },
  { key: "NM_181615.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337975", geneDisplay: "KRTAP20-1" },
  { key: "NM_181616.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337976", geneDisplay: "KRTAP20-2" },
  { key: "NM_181617.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337978", geneDisplay: "KRTAP21-2" },
  { key: "NM_181619.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337977", geneDisplay: "KRTAP21-1" },
  { key: "NM_181620.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337979", geneDisplay: "KRTAP22-1" },
  { key: "NM_181621.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337959", geneDisplay: "KRTAP13-2" },
  { key: "NM_181622.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337960", geneDisplay: "KRTAP13-3" },
  { key: "NM_181623.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "254950", geneDisplay: "KRTAP15-1" },
  { key: "NM_181624.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337963", geneDisplay: "KRTAP23-1" },
  { key: "NM_181643.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128344", geneDisplay: "PIFO" },
  { key: "NM_181644.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148808", geneDisplay: "MFSD4A" },
  { key: "NM_181645.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "159989", geneDisplay: "DEUP1" },
  { key: "NM_181646.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "219578", geneDisplay: "ZNF804B" },
  { key: "NM_181654.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "339302", geneDisplay: "CPLX4" },
  { key: "NM_181659.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8202", geneDisplay: "NCOA3" },
  { key: "NM_181672.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8473", geneDisplay: "OGT" },
  { key: "NM_181675.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5521", geneDisplay: "PPP2R2B" },
  { key: "NM_181684.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "353323", geneDisplay: "KRTAP12-2" },
  { key: "NM_181686.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "353332", geneDisplay: "KRTAP12-1" },
  { key: "NM_181688.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "353333", geneDisplay: "KRTAP10-10" },
  { key: "NM_181697.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5052", geneDisplay: "PRDX1" },
  { key: "NM_181701.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169714", geneDisplay: "QSOX2" },
  { key: "NM_181703.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2702", geneDisplay: "GJA5" },
  { key: "NM_181705.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "90624", geneDisplay: "LYRM7" },
  { key: "NM_181706.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120526", geneDisplay: "DNAJC24" },
  { key: "NM_181707.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124773", geneDisplay: "C17orf64" },
  { key: "NM_181708.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144233", geneDisplay: "BCDIN3D" },
  { key: "NM_181710.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148066", geneDisplay: "ZNRF4" },
  { key: "NM_181711.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160622", geneDisplay: "TAMALIN" },
  { key: "NM_181712.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163782", geneDisplay: "KANK4" },
  { key: "NM_181713.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165324", geneDisplay: "UBXN2A" },
  { key: "NM_181715.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200186", geneDisplay: "CRTC2" },
  { key: "NM_181716.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201161", geneDisplay: "CENPV" },
  { key: "NM_181718.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "253982", geneDisplay: "ASPHD1" },
  { key: "NM_181719.7", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "255104", geneDisplay: "TMCO4" },
  { key: "NM_181721.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283150", geneDisplay: "FOXR1" },
  { key: "NM_181723.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286097", geneDisplay: "MICU3" },
  { key: "NM_181724.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338773", geneDisplay: "TMEM119" },
  { key: "NM_181725.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339175", geneDisplay: "METTL2A" },
  { key: "NM_181726.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "353322", geneDisplay: "ANKRD37" },
  { key: "NM_181727.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "353324", geneDisplay: "SPATA12" },
  { key: "NM_181741.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5000", geneDisplay: "ORC4" },
  { key: "NM_181744.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221391", geneDisplay: "OPN5" },
  { key: "NM_181773.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164668", geneDisplay: "APOBEC3H" },
  { key: "NM_181774.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285641", geneDisplay: "SLC36A3" },
  { key: "NM_181776.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153201", geneDisplay: "SLC36A2" },
  { key: "NM_181780.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151888", geneDisplay: "BTLA" },
  { key: "NM_181782.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135112", geneDisplay: "NCOA7" },
  { key: "NM_181783.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160418", geneDisplay: "TMTC3" },
  { key: "NM_181784.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200734", geneDisplay: "SPRED2" },
  { key: "NM_181785.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "283537", geneDisplay: "SLC46A3" },
  { key: "NM_181787.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286148", geneDisplay: "DPY19L4" },
  { key: "NM_181788.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341567", geneDisplay: "H1-7" },
  { key: "NM_181789.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "342035", geneDisplay: "GLDN" },
  { key: "NM_181795.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5570", geneDisplay: "PKIB" },
  { key: "NM_181806.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132949", geneDisplay: "AASDH" },
  { key: "NM_181808.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "353497", geneDisplay: "POLN" },
  { key: "NM_181809.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353500", geneDisplay: "BMP8A" },
  { key: "NM_181836.6", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51014", geneDisplay: "TMED7" },
  { key: "NM_181840.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "338567", geneDisplay: "KCNK18" },
  { key: "NM_181842.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221527", geneDisplay: "ZBTB12" },
  { key: "NM_181844.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "255877", geneDisplay: "BCL6B" },
  { key: "NM_181845.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284349", geneDisplay: "ZNF283" },
  { key: "NM_181846.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342945", geneDisplay: "ZSCAN22" },
  { key: "NM_181861.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "317", geneDisplay: "APAF1" },
  { key: "NM_181872.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10655", geneDisplay: "DMRT2" },
  { key: "NM_181877.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54993", geneDisplay: "ZSCAN2" },
  { key: "NM_181880.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "353513", geneDisplay: "VCY1B" },
  { key: "NM_181882.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57716", geneDisplay: "PRX" },
  { key: "NM_181885.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339403", geneDisplay: "RXFP4" },
  { key: "NM_181891.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7323", geneDisplay: "UBE2D3" },
  { key: "NM_181900.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80765", geneDisplay: "STARD5" },
  { key: "NM_182476.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51004", geneDisplay: "COQ6" },
  { key: "NM_182487.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169611", geneDisplay: "OLFML2A" },
  { key: "NM_182488.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "219333", geneDisplay: "USP12" },
  { key: "NM_182490.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7770", geneDisplay: "ZNF227" },
  { key: "NM_182491.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "90637", geneDisplay: "ZFAND2A" },
  { key: "NM_182493.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "91807", geneDisplay: "MYLK3" },
  { key: "NM_182495.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120406", geneDisplay: "NXPE2" },
  { key: "NM_182496.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "120935", geneDisplay: "CCDC38" },
  { key: "NM_182498.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126299", geneDisplay: "ZNF428" },
  { key: "NM_182499.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126668", geneDisplay: "TDRD10" },
  { key: "NM_182501.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130916", geneDisplay: "MTERF4" },
  { key: "NM_182502.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132724", geneDisplay: "TMPRSS11B" },
  { key: "NM_182503.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134637", geneDisplay: "ADAT2" },
  { key: "NM_182504.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135886", geneDisplay: "TMEM270" },
  { key: "NM_182505.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138241", geneDisplay: "C9orf85" },
  { key: "NM_182506.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139422", geneDisplay: "MAGEB10" },
  { key: "NM_182507.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144501", geneDisplay: "KRT80" },
  { key: "NM_182511.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147381", geneDisplay: "CBLN2" },
  { key: "NM_182513.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147841", geneDisplay: "SPC24" },
  { key: "NM_182515.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148206", geneDisplay: "ZNF714" },
  { key: "NM_182517.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149466", geneDisplay: "C1orf210" },
  { key: "NM_182519.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149954", geneDisplay: "BPIFB4" },
  { key: "NM_182520.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150248", geneDisplay: "C22orf15" },
  { key: "NM_182521.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151112", geneDisplay: "ZSWIM2" },
  { key: "NM_182522.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151647", geneDisplay: "TAFA4" },
  { key: "NM_182523.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152100", geneDisplay: "CMC1" },
  { key: "NM_182524.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152687", geneDisplay: "ZNF595" },
  { key: "NM_182527.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164633", geneDisplay: "CABP7" },
  { key: "NM_182528.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165257", geneDisplay: "C1QL2" },
  { key: "NM_182530.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196446", geneDisplay: "MYRFL" },
  { key: "NM_182532.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199964", geneDisplay: "TMEM61" },
  { key: "NM_182533.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199990", geneDisplay: "FAAP20" },
  { key: "NM_182536.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200504", geneDisplay: "GKN2" },
  { key: "NM_182538.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201305", geneDisplay: "SPNS3" },
  { key: "NM_182539.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "202500", geneDisplay: "TCTE1" },
  { key: "NM_182541.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203562", geneDisplay: "TMEM31" },
  { key: "NM_182542.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "220081", geneDisplay: "ERICH6B" },
  { key: "NM_182543.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221078", geneDisplay: "NSUN6" },
  { key: "NM_182547.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222068", geneDisplay: "TMED4" },
  { key: "NM_182548.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222662", geneDisplay: "LHFPL5" },
  { key: "NM_182552.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "253769", geneDisplay: "WDR27" },
  { key: "NM_182553.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254263", geneDisplay: "CNIH2" },
  { key: "NM_182556.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283130", geneDisplay: "SLC25A45" },
  { key: "NM_182559.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283471", geneDisplay: "TMPRSS12" },
  { key: "NM_182563.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283870", geneDisplay: "BRICD5" },
  { key: "NM_182565.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "283991", geneDisplay: "UBALD2" },
  { key: "NM_182566.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284013", geneDisplay: "VMO1" },
  { key: "NM_182569.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284161", geneDisplay: "GDPD1" },
  { key: "NM_182572.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284312", geneDisplay: "ZSCAN1" },
  { key: "NM_182575.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284359", geneDisplay: "IZUMO1" },
  { key: "NM_182577.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284451", geneDisplay: "ODF3L2" },
  { key: "NM_182578.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284486", geneDisplay: "THEM5" },
  { key: "NM_182580.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284613", geneDisplay: "CYB561D1" },
  { key: "NM_182581.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284680", geneDisplay: "SPATA46" },
  { key: "NM_182584.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "284805", geneDisplay: "C20orf203" },
  { key: "NM_182588.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285190", geneDisplay: "RGPD4" },
  { key: "NM_182592.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285525", geneDisplay: "YIPF7" },
  { key: "NM_182595.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285877", geneDisplay: "POM121L12" },
  { key: "NM_182597.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "286006", geneDisplay: "LSMEM1" },
  { key: "NM_182607.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340547", geneDisplay: "VSIG1" },
  { key: "NM_182608.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341405", geneDisplay: "ANKRD33" },
  { key: "NM_182609.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342926", geneDisplay: "ZNF677" },
  { key: "NM_182612.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "347862", geneDisplay: "GATD1" },
  { key: "NM_182614.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "348013", geneDisplay: "TMEM255B" },
  { key: "NM_182616.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "348110", geneDisplay: "ARPIN" },
  { key: "NM_182620.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "348235", geneDisplay: "SKA2" },
  { key: "NM_182623.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "348487", geneDisplay: "FAM131C" },
  { key: "NM_182627.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "348793", geneDisplay: "WDR53" },
  { key: "NM_182628.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "348807", geneDisplay: "CFAP100" },
  { key: "NM_182632.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "348932", geneDisplay: "SLC6A18" },
  { key: "NM_182633.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "349075", geneDisplay: "ZNF713" },
  { key: "NM_182640.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64965", geneDisplay: "MRPS9" },
  { key: "NM_182641.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2186", geneDisplay: "BPTF" },
  { key: "NM_182643.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10395", geneDisplay: "DLC1" },
  { key: "NM_182645.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "245806", geneDisplay: "VGLL2" },
  { key: "NM_182647.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "4987", geneDisplay: "OPRL1" },
  { key: "NM_182649.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5111", geneDisplay: "PCNA" },
  { key: "NM_182663.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83593", geneDisplay: "RASSF5" },
  { key: "NM_182679.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54865", geneDisplay: "GPATCH4" },
  { key: "NM_182692.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6733", geneDisplay: "SRPK2" },
  { key: "NM_182699.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "168400", geneDisplay: "DDX53" },
  { key: "NM_182700.6", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221833", geneDisplay: "SP8" },
  { key: "NM_182701.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "257202", geneDisplay: "GPX6" },
  { key: "NM_182703.6", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "348094", geneDisplay: "ANKDD1A" },
  { key: "NM_182704.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "348303", geneDisplay: "SELENOV" },
  { key: "NM_182705.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "359845", geneDisplay: "RFLNB" },
  { key: "NM_182706.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23513", geneDisplay: "SCRIB" },
  { key: "NM_182707.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "440533", geneDisplay: "PSG8" },
  { key: "NM_182710.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10524", geneDisplay: "KAT5" },
  { key: "NM_182715.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6856", geneDisplay: "SYPL1" },
  { key: "NM_182746.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4173", geneDisplay: "MCM4" },
  { key: "NM_182752.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127262", geneDisplay: "TPRG1L" },
  { key: "NM_182756.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "245711", geneDisplay: "SPDYA" },
  { key: "NM_182757.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "255488", geneDisplay: "RNF144B" },
  { key: "NM_182758.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "256764", geneDisplay: "WDR72" },
  { key: "NM_182759.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284467", geneDisplay: "TAFA3" },
  { key: "NM_182760.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285362", geneDisplay: "SUMF1" },
  { key: "NM_182762.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346389", geneDisplay: "MACC1" },
  { key: "NM_182765.6", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143279", geneDisplay: "HECTD2" },
  { key: "NM_182767.6", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55117", geneDisplay: "SLC6A15" },
  { key: "NM_182810.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "468", geneDisplay: "ATF4" },
  { key: "NM_182828.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151449", geneDisplay: "GDF7" },
  { key: "NM_182833.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220032", geneDisplay: "GDPD4" },
  { key: "NM_182836.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5875", geneDisplay: "RABGGTA" },
  { key: "NM_182854.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124460", geneDisplay: "SNX20" },
  { key: "NM_182894.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "338917", geneDisplay: "VSX2" },
  { key: "NM_182895.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91179", geneDisplay: "SCARF2" },
  { key: "NM_182898.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9586", geneDisplay: "CREB5" },
  { key: "NM_182902.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64147", geneDisplay: "KIF9" },
  { key: "NM_182904.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283208", geneDisplay: "P4HA3" },
  { key: "NM_182914.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23224", geneDisplay: "SYNE2" },
  { key: "NM_182915.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55240", geneDisplay: "STEAP3" },
  { key: "NM_182916.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51095", geneDisplay: "TRNT1" },
  { key: "NM_182918.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "2078", geneDisplay: "ERG" },
  { key: "NM_182919.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148022", geneDisplay: "TICAM1" },
  { key: "NM_182920.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56999", geneDisplay: "ADAMTS9" },
  { key: "NM_182922.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55027", geneDisplay: "HEATR3" },
  { key: "NM_182924.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79778", geneDisplay: "MICALL2" },
  { key: "NM_182925.5", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2324", geneDisplay: "FLT4" },
  { key: "NM_182931.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55904", geneDisplay: "KMT2E" },
  { key: "NM_182932.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6547", geneDisplay: "SLC8A3" },
  { key: "NM_182943.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5352", geneDisplay: "PLOD2" },
  { key: "NM_182947.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "115557", geneDisplay: "ARHGEF25" },
  { key: "NM_182948.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5567", geneDisplay: "PRKACB" },
  { key: "NM_182961.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23345", geneDisplay: "SYNE1" },
  { key: "NM_182970.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140730", geneDisplay: "RIMS4" },
  { key: "NM_182971.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "341947", geneDisplay: "COX8C" },
  { key: "NM_182972.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "359948", geneDisplay: "IRF2BP2" },
  { key: "NM_182974.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "360203", geneDisplay: "GLT6D1" },
  { key: "NM_182976.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284695", geneDisplay: "ZNF326" },
  { key: "NM_182977.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23530", geneDisplay: "NNT" },
  { key: "NM_182978.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "2774", geneDisplay: "GNAL" },
  { key: "NM_182981.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29948", geneDisplay: "OSGIN1" },
  { key: "NM_182982.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2868", geneDisplay: "GRK4" },
  { key: "NM_182985.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "140691", geneDisplay: "TRIM69" },
  { key: "NM_183005.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10557", geneDisplay: "RPP38" },
  { key: "NM_183011.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1390", geneDisplay: "CREM" },
  { key: "NM_183050.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "594", geneDisplay: "BCKDHB" },
  { key: "NM_183058.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119180", geneDisplay: "LYZL2" },
  { key: "NM_183061.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285335", geneDisplay: "SLC9C1" },
  { key: "NM_183062.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339501", geneDisplay: "PRSS38" },
  { key: "NM_183065.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84314", geneDisplay: "TMEM107" },
  { key: "NM_183075.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "113612", geneDisplay: "CYP2U1" },
  { key: "NM_183235.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5873", geneDisplay: "RAB27A" },
  { key: "NM_183238.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "100289635", geneDisplay: "ZNF605" },
  { key: "NM_183239.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119391", geneDisplay: "GSTO2" },
  { key: "NM_183240.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "140738", geneDisplay: "TMEM37" },
  { key: "NM_183241.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286257", geneDisplay: "PAXX" },
  { key: "NM_183337.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8786", geneDisplay: "RGS11" },
  { key: "NM_183352.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6396", geneDisplay: "SEC13" },
  { key: "NM_183357.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "111", geneDisplay: "ADCY5" },
  { key: "NM_183373.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221749", geneDisplay: "PXDC1" },
  { key: "NM_183374.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340665", geneDisplay: "CYP26C1" },
  { key: "NM_183375.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345062", geneDisplay: "PRSS48" },
  { key: "NM_183376.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "91947", geneDisplay: "ARRDC4" },
  { key: "NM_183377.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "40", geneDisplay: "ASIC2" },
  { key: "NM_183381.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11342", geneDisplay: "RNF13" },
  { key: "NM_183387.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161436", geneDisplay: "EML5" },
  { key: "NM_183419.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "25897", geneDisplay: "RNF19A" },
  { key: "NM_183420.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "26260", geneDisplay: "FBXO25" },
  { key: "NM_183422.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8848", geneDisplay: "TSC22D1" },
  { key: "NM_184085.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84675", geneDisplay: "TRIM55" },
  { key: "NM_184234.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9584", geneDisplay: "RBM39" },
  { key: "NM_187841.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57159", geneDisplay: "TRIM54" },
  { key: "NM_194071.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64764", geneDisplay: "CREB3L2" },
  { key: "NM_194247.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "220988", geneDisplay: "HNRNPA3" },
  { key: "NM_194248.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9381", geneDisplay: "OTOF" },
  { key: "NM_194249.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "373863", geneDisplay: "DND1" },
  { key: "NM_194250.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91752", geneDisplay: "ZNF804A" },
  { key: "NM_194251.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134391", geneDisplay: "GPR151" },
  { key: "NM_194255.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "6573", geneDisplay: "SLC19A1" },
  { key: "NM_194272.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "348093", geneDisplay: "RBPMS2" },
  { key: "NM_194277.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90167", geneDisplay: "FRMD7" },
  { key: "NM_194279.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122961", geneDisplay: "ISCA2" },
  { key: "NM_194281.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "125476", geneDisplay: "INO80C" },
  { key: "NM_194282.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132660", geneDisplay: "LIN54" },
  { key: "NM_194284.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137075", geneDisplay: "CLDN23" },
  { key: "NM_194285.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144108", geneDisplay: "SPTY2D1" },
  { key: "NM_194286.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84530", geneDisplay: "SRRM4" },
  { key: "NM_194291.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157378", geneDisplay: "TMEM65" },
  { key: "NM_194292.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163786", geneDisplay: "SASS6" },
  { key: "NM_194293.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165904", geneDisplay: "XIRP1" },
  { key: "NM_194294.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169355", geneDisplay: "IDO2" },
  { key: "NM_194296.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202051", geneDisplay: "SPATA24" },
  { key: "NM_194298.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220963", geneDisplay: "SLC16A9" },
  { key: "NM_194302.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "255101", geneDisplay: "CFAP65" },
  { key: "NM_194312.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339768", geneDisplay: "ESPNL" },
  { key: "NM_194313.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347240", geneDisplay: "KIF24" },
  { key: "NM_194314.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "360023", geneDisplay: "ZBTB41" },
  { key: "NM_194317.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130574", geneDisplay: "LYPD6" },
  { key: "NM_194318.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "145173", geneDisplay: "B3GLCT" },
  { key: "NM_194320.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169841", geneDisplay: "ZNF169" },
  { key: "NM_194324.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286527", geneDisplay: "TMSB15B" },
  { key: "NM_194325.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90075", geneDisplay: "ZNF30" },
  { key: "NM_194326.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91582", geneDisplay: "RPS19BP1" },
  { key: "NM_194356.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2054", geneDisplay: "STX2" },
  { key: "NM_194434.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9218", geneDisplay: "VAPA" },
  { key: "NM_194436.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197257", geneDisplay: "LDHD" },
  { key: "NM_194449.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23239", geneDisplay: "PHLPP1" },
  { key: "NM_194454.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "889", geneDisplay: "KRIT1" },
  { key: "NM_194460.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55658", geneDisplay: "RNF126" },
  { key: "NM_194463.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79589", geneDisplay: "RNF128" },
  { key: "NM_197941.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11174", geneDisplay: "ADAMTS6" },
  { key: "NM_197947.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64581", geneDisplay: "CLEC7A" },
  { key: "NM_197955.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84419", geneDisplay: "C15orf48" },
  { key: "NM_197956.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203245", geneDisplay: "NAIF1" },
  { key: "NM_197962.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51022", geneDisplay: "GLRX2" },
  { key: "NM_197964.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154791", geneDisplay: "FMC1" },
  { key: "NM_197965.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345274", geneDisplay: "SLC10A6" },
  { key: "NM_197968.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7750", geneDisplay: "ZMYM2" },
  { key: "NM_197975.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10917", geneDisplay: "BTNL3" },
  { key: "NM_197978.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55363", geneDisplay: "HEMGN" },
  { key: "NM_198046.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84287", geneDisplay: "ZDHHC16" },
  { key: "NM_198053.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "919", geneDisplay: "CD247" },
  { key: "NM_198055.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7593", geneDisplay: "MZF1" },
  { key: "NM_198057.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1831", geneDisplay: "TSC22D3" },
  { key: "NM_198060.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4892", geneDisplay: "NRAP" },
  { key: "NM_198066.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64841", geneDisplay: "GNPNAT1" },
  { key: "NM_198074.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81472", geneDisplay: "OR2C3" },
  { key: "NM_198075.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "115399", geneDisplay: "LRRC56" },
  { key: "NM_198076.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "116228", geneDisplay: "COX20" },
  { key: "NM_198077.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148423", geneDisplay: "C1orf52" },
  { key: "NM_198081.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "256380", geneDisplay: "SCML4" },
  { key: "NM_198083.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "317749", geneDisplay: "DHRS4L2" },
  { key: "NM_198085.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "378925", geneDisplay: "RNF148" },
  { key: "NM_198088.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7752", geneDisplay: "ZNF200" },
  { key: "NM_198089.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7711", geneDisplay: "ZNF155" },
  { key: "NM_198097.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221960", geneDisplay: "CCZ1B" },
  { key: "NM_198098.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "358", geneDisplay: "AQP1" },
  { key: "NM_198123.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114788", geneDisplay: "CSMD3" },
  { key: "NM_198129.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "3909", geneDisplay: "LAMA3" },
  { key: "NM_198137.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "378807", geneDisplay: "CATSPER4" },
  { key: "NM_198141.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2595", geneDisplay: "GANC" },
  { key: "NM_198147.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "116236", geneDisplay: "ABHD15" },
  { key: "NM_198148.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119587", geneDisplay: "CPXM2" },
  { key: "NM_198149.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149345", geneDisplay: "SHISA4" },
  { key: "NM_198150.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153642", geneDisplay: "ARSK" },
  { key: "NM_198152.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "257313", geneDisplay: "UTS2B" },
  { key: "NM_198153.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285852", geneDisplay: "TREML4" },
  { key: "NM_198173.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57822", geneDisplay: "GRHL3" },
  { key: "NM_198179.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84109", geneDisplay: "QRFPR" },
  { key: "NM_198180.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347148", geneDisplay: "QRFP" },
  { key: "NM_198181.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440295", geneDisplay: "GOLGA6L9" },
  { key: "NM_198182.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "29841", geneDisplay: "GRHL1" },
  { key: "NM_198184.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344901", geneDisplay: "OSTN" },
  { key: "NM_198185.7", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341277", geneDisplay: "OVCH2" },
  { key: "NM_198204.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6945", geneDisplay: "MLX" },
  { key: "NM_198215.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220965", geneDisplay: "FAM13C" },
  { key: "NM_198219.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3621", geneDisplay: "ING1" },
  { key: "NM_198236.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9826", geneDisplay: "ARHGEF11" },
  { key: "NM_198239.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8838", geneDisplay: "CCN6" },
  { key: "NM_198241.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1981", geneDisplay: "EIF4G1" },
  { key: "NM_198243.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "140460", geneDisplay: "ASB7" },
  { key: "NM_198252.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2934", geneDisplay: "GSN" },
  { key: "NM_198253.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7015", geneDisplay: "TERT" },
  { key: "NM_198256.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1876", geneDisplay: "E2F6" },
  { key: "NM_198268.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "204851", geneDisplay: "HIPK1" },
  { key: "NM_198271.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56203", geneDisplay: "LMOD3" },
  { key: "NM_198273.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "116068", geneDisplay: "LYSMD3" },
  { key: "NM_198274.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150572", geneDisplay: "SMYD1" },
  { key: "NM_198275.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "196264", geneDisplay: "MPZL3" },
  { key: "NM_198276.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200728", geneDisplay: "TMEM17" },
  { key: "NM_198281.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285513", geneDisplay: "GPRIN3" },
  { key: "NM_198282.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340061", geneDisplay: "STING1" },
  { key: "NM_198285.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "349136", geneDisplay: "WDR86" },
  { key: "NM_198291.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6714", geneDisplay: "SRC" },
  { key: "NM_198317.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339451", geneDisplay: "KLHL17" },
  { key: "NM_198320.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1368", geneDisplay: "CPM" },
  { key: "NM_198321.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55568", geneDisplay: "GALNT10" },
  { key: "NM_198334.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23193", geneDisplay: "GANAB" },
  { key: "NM_198353.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "386617", geneDisplay: "KCTD8" },
  { key: "NM_198390.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80790", geneDisplay: "CMIP" },
  { key: "NM_198391.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23767", geneDisplay: "FLRT3" },
  { key: "NM_198402.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201562", geneDisplay: "HACD2" },
  { key: "NM_198403.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221938", geneDisplay: "MMD2" },
  { key: "NM_198404.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "386618", geneDisplay: "KCTD4" },
  { key: "NM_198406.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79957", geneDisplay: "PAQR6" },
  { key: "NM_198407.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2693", geneDisplay: "GHSR" },
  { key: "NM_198428.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "27241", geneDisplay: "BBS9" },
  { key: "NM_198437.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6790", geneDisplay: "AURKA" },
  { key: "NM_198439.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143879", geneDisplay: "KBTBD3" },
  { key: "NM_198441.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122183", geneDisplay: "PRR20A" },
  { key: "NM_198443.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123904", geneDisplay: "NRN1L" },
  { key: "NM_198446.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127687", geneDisplay: "C1orf122" },
  { key: "NM_198447.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127845", geneDisplay: "GOLT1A" },
  { key: "NM_198449.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133418", geneDisplay: "EMB" },
  { key: "NM_198450.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139322", geneDisplay: "APOOL" },
  { key: "NM_198451.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139628", geneDisplay: "FOXR2" },
  { key: "NM_198458.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162968", geneDisplay: "ZNF497" },
  { key: "NM_198460.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163351", geneDisplay: "GBP6" },
  { key: "NM_198461.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "164832", geneDisplay: "LONRF2" },
  { key: "NM_198464.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203074", geneDisplay: "PRSS55" },
  { key: "NM_198465.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203447", geneDisplay: "NRK" },
  { key: "NM_198467.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222194", geneDisplay: "RSBN1L" },
  { key: "NM_198469.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "254956", geneDisplay: "MORN5" },
  { key: "NM_198471.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "256949", geneDisplay: "KANK3" },
  { key: "NM_198474.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283298", geneDisplay: "OLFML1" },
  { key: "NM_198475.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284069", geneDisplay: "FAM171A2" },
  { key: "NM_198476.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284325", geneDisplay: "C19orf54" },
  { key: "NM_198477.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284340", geneDisplay: "CXCL17" },
  { key: "NM_198478.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284353", geneDisplay: "NKPD1" },
  { key: "NM_198481.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284415", geneDisplay: "VSTM1" },
  { key: "NM_198483.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285180", geneDisplay: "RUFY4" },
  { key: "NM_198484.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285268", geneDisplay: "ZNF621" },
  { key: "NM_198485.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285386", geneDisplay: "TPRG1" },
  { key: "NM_198488.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286077", geneDisplay: "FAM83H" },
  { key: "NM_198489.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338657", geneDisplay: "CENATAC" },
  { key: "NM_198490.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "339122", geneDisplay: "RAB43" },
  { key: "NM_198491.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "339145", geneDisplay: "CIBAR2" },
  { key: "NM_198492.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339390", geneDisplay: "CLEC4G" },
  { key: "NM_198493.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339416", geneDisplay: "ANKRD45" },
  { key: "NM_198495.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100128553", geneDisplay: "CTAGE4" },
  { key: "NM_198498.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341032", geneDisplay: "C11orf53" },
  { key: "NM_198503.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343450", geneDisplay: "KCNT2" },
  { key: "NM_198504.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344838", geneDisplay: "PAQR9" },
  { key: "NM_198505.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344905", geneDisplay: "ATP13A5" },
  { key: "NM_198506.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345193", geneDisplay: "LRIT3" },
  { key: "NM_198507.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345757", geneDisplay: "FAM174A" },
  { key: "NM_198508.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346689", geneDisplay: "KLRG2" },
  { key: "NM_198510.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347365", geneDisplay: "ITIH6" },
  { key: "NM_198512.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347516", geneDisplay: "DGAT2L6" },
  { key: "NM_198514.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "374354", geneDisplay: "NHLRC2" },
  { key: "NM_198515.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "374355", geneDisplay: "CCDC172" },
  { key: "NM_198516.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374378", geneDisplay: "GALNT18" },
  { key: "NM_198521.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "374470", geneDisplay: "C12orf42" },
  { key: "NM_198525.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "374654", geneDisplay: "KIF7" },
  { key: "NM_198526.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "374655", geneDisplay: "ZNF710" },
  { key: "NM_198529.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "374786", geneDisplay: "EFCAB5" },
  { key: "NM_198530.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "439921", geneDisplay: "MXRA7" },
  { key: "NM_198531.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "374868", geneDisplay: "ATP9B" },
  { key: "NM_198532.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374872", geneDisplay: "PEAK3" },
  { key: "NM_198534.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374877", geneDisplay: "TEX45" },
  { key: "NM_198535.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374879", geneDisplay: "ZNF699" },
  { key: "NM_198536.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374882", geneDisplay: "TMEM205" },
  { key: "NM_198537.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374887", geneDisplay: "YJEFN3" },
  { key: "NM_198539.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374900", geneDisplay: "ZNF568" },
  { key: "NM_198541.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374918", geneDisplay: "IGFL1" },
  { key: "NM_198542.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374928", geneDisplay: "ZNF773" },
  { key: "NM_198545.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374946", geneDisplay: "DRAXIN" },
  { key: "NM_198546.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374955", geneDisplay: "SPATA21" },
  { key: "NM_198549.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374986", geneDisplay: "MIGA1" },
  { key: "NM_198551.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375056", geneDisplay: "MIA3" },
  { key: "NM_198552.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375061", geneDisplay: "FAM89A" },
  { key: "NM_198557.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375287", geneDisplay: "RBM43" },
  { key: "NM_198559.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375307", geneDisplay: "CATIP" },
  { key: "NM_198560.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "375323", geneDisplay: "LHFPL4" },
  { key: "NM_198562.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "375341", geneDisplay: "C3orf62" },
  { key: "NM_198563.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "375346", geneDisplay: "STIMATE" },
  { key: "NM_198565.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "375387", geneDisplay: "NRROS" },
  { key: "NM_198566.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "375444", geneDisplay: "C5orf34" },
  { key: "NM_198568.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "375519", geneDisplay: "GJB7" },
  { key: "NM_198569.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57211", geneDisplay: "ADGRG6" },
  { key: "NM_198570.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375567", geneDisplay: "VWC2" },
  { key: "NM_198571.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375607", geneDisplay: "NAT16" },
  { key: "NM_198572.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "375686", geneDisplay: "SPATC1" },
  { key: "NM_198573.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375704", geneDisplay: "ENHO" },
  { key: "NM_198576.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375790", geneDisplay: "AGRN" },
  { key: "NM_198578.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "120892", geneDisplay: "LRRK2" },
  { key: "NM_198580.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "376497", geneDisplay: "SLC27A1" },
  { key: "NM_198581.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "376940", geneDisplay: "ZC3H6" },
  { key: "NM_198582.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "377007", geneDisplay: "KLHL30" },
  { key: "NM_198584.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "377677", geneDisplay: "CA13" },
  { key: "NM_198586.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "378884", geneDisplay: "NHLRC1" },
  { key: "NM_198597.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9632", geneDisplay: "SEC24C" },
  { key: "NM_198682.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2996", geneDisplay: "GYPE" },
  { key: "NM_198687.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386672", geneDisplay: "KRTAP10-4" },
  { key: "NM_198688.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386674", geneDisplay: "KRTAP10-6" },
  { key: "NM_198689.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386675", geneDisplay: "KRTAP10-7" },
  { key: "NM_198690.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386676", geneDisplay: "KRTAP10-9" },
  { key: "NM_198691.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386677", geneDisplay: "KRTAP10-1" },
  { key: "NM_198692.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386678", geneDisplay: "KRTAP10-11" },
  { key: "NM_198693.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386679", geneDisplay: "KRTAP10-2" },
  { key: "NM_198694.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386680", geneDisplay: "KRTAP10-5" },
  { key: "NM_198695.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386681", geneDisplay: "KRTAP10-8" },
  { key: "NM_198696.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386682", geneDisplay: "KRTAP10-3" },
  { key: "NM_198697.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386683", geneDisplay: "KRTAP12-3" },
  { key: "NM_198698.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386684", geneDisplay: "KRTAP12-4" },
  { key: "NM_198699.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "386685", geneDisplay: "KRTAP10-12" },
  { key: "NM_198706.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374875", geneDisplay: "HSD11B1L" },
  { key: "NM_198719.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5733", geneDisplay: "PTGER3" },
  { key: "NM_198721.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84570", geneDisplay: "COL25A1" },
  { key: "NM_198722.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "386724", geneDisplay: "AMIGO3" },
  { key: "NM_198799.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55653", geneDisplay: "BCAS4" },
  { key: "NM_198827.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283383", geneDisplay: "ADGRD1" },
  { key: "NM_198834.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "31", geneDisplay: "ACACA" },
  { key: "NM_198841.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158293", geneDisplay: "FAM120AOS" },
  { key: "NM_198849.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "283514", geneDisplay: "SIAH3" },
  { key: "NM_198850.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "653583", geneDisplay: "PHLDB3" },
  { key: "NM_198853.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "378108", geneDisplay: "TRIM74" },
  { key: "NM_198859.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "166336", geneDisplay: "PRICKLE2" },
  { key: "NM_198880.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54870", geneDisplay: "QRICH1" },
  { key: "NM_198887.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "348995", geneDisplay: "NUP43" },
  { key: "NM_198892.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55589", geneDisplay: "BMP2K" },
  { key: "NM_198896.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5870", geneDisplay: "RAB6A" },
  { key: "NM_198904.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2566", geneDisplay: "GABRG2" },
  { key: "NM_198920.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "90025", geneDisplay: "UBE3D" },
  { key: "NM_198923.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116512", geneDisplay: "MRGPRD" },
  { key: "NM_198924.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375593", geneDisplay: "TRIM73" },
  { key: "NM_198925.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10509", geneDisplay: "SEMA4B" },
  { key: "NM_198935.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26039", geneDisplay: "SS18L1" },
  { key: "NM_198946.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158062", geneDisplay: "LCN6" },
  { key: "NM_198947.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374393", geneDisplay: "FAM111B" },
  { key: "NM_198963.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90957", geneDisplay: "DHX57" },
  { key: "NM_198965.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5744", geneDisplay: "PTHLH" },
  { key: "NM_198968.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "22873", geneDisplay: "DZIP1" },
  { key: "NM_198971.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25988", geneDisplay: "HINFP" },
  { key: "NM_198976.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51497", geneDisplay: "NELFCD" },
  { key: "NM_198992.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341359", geneDisplay: "SYT10" },
  { key: "NM_198993.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342667", geneDisplay: "STAC2" },
  { key: "NM_198994.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "343641", geneDisplay: "TGM6" },
  { key: "NM_198998.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375318", geneDisplay: "AQP12A" },
  { key: "NM_198999.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375611", geneDisplay: "SLC26A5" },
  { key: "NM_199000.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375612", geneDisplay: "LHFPL3" },
  { key: "NM_199001.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375791", geneDisplay: "CYSRT1" },
  { key: "NM_199044.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "387338", geneDisplay: "NSUN4" },
  { key: "NM_199047.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "387332", geneDisplay: "TBPL2" },
  { key: "NM_199051.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339479", geneDisplay: "BRINP3" },
  { key: "NM_199054.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2872", geneDisplay: "MKNK2" },
  { key: "NM_199069.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25915", geneDisplay: "NDUFAF3" },
  { key: "NM_199127.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91227", geneDisplay: "GGTLC2" },
  { key: "NM_199129.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "387521", geneDisplay: "PEDS1" },
  { key: "NM_199133.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134145", geneDisplay: "ATPSCKMT" },
  { key: "NM_199135.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286380", geneDisplay: "FOXD4L3" },
  { key: "NM_199136.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340277", geneDisplay: "FAM221A" },
  { key: "NM_199141.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10498", geneDisplay: "CARM1" },
  { key: "NM_199161.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6288", geneDisplay: "SAA1" },
  { key: "NM_199168.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6387", geneDisplay: "CXCL12" },
  { key: "NM_199173.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "632", geneDisplay: "BGLAP" },
  { key: "NM_199180.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84063", geneDisplay: "KIRREL2" },
  { key: "NM_199191.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9577", geneDisplay: "BABAM2" },
  { key: "NM_199227.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "254042", geneDisplay: "METAP1D" },
  { key: "NM_199229.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6120", geneDisplay: "RPE" },
  { key: "NM_199242.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201294", geneDisplay: "UNC13D" },
  { key: "NM_199243.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285601", geneDisplay: "GPR150" },
  { key: "NM_199244.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "349334", geneDisplay: "FOXD4L4" },
  { key: "NM_199261.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7179", geneDisplay: "TPTE" },
  { key: "NM_199280.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165186", geneDisplay: "TOGARAM2" },
  { key: "NM_199285.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284338", geneDisplay: "PRR19" },
  { key: "NM_199286.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "359787", geneDisplay: "DPPA3" },
  { key: "NM_199287.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339230", geneDisplay: "CCDC137" },
  { key: "NM_199290.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342538", geneDisplay: "NACA2" },
  { key: "NM_199294.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "378708", geneDisplay: "CENPS" },
  { key: "NM_199296.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145501", geneDisplay: "ISM2" },
  { key: "NM_199320.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79960", geneDisplay: "JADE1" },
  { key: "NM_199321.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124626", geneDisplay: "ZPBP2" },
  { key: "NM_199328.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9073", geneDisplay: "CLDN8" },
  { key: "NM_199329.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29015", geneDisplay: "SLC43A3" },
  { key: "NM_199334.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7067", geneDisplay: "THRA" },
  { key: "NM_199337.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374395", geneDisplay: "TMEM179B" },
  { key: "NM_199339.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "374768", geneDisplay: "SPEM1" },
  { key: "NM_199340.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "374819", geneDisplay: "LRRC37A3" },
  { key: "NM_199341.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374920", geneDisplay: "ZSWIM9" },
  { key: "NM_199342.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374969", geneDisplay: "SVBP" },
  { key: "NM_199344.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375035", geneDisplay: "SFT2D2" },
  { key: "NM_199346.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375189", geneDisplay: "PFN4" },
  { key: "NM_199350.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375759", geneDisplay: "C9orf50" },
  { key: "NM_199351.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "387597", geneDisplay: "ILDR2" },
  { key: "NM_199352.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387601", geneDisplay: "SLC22A25" },
  { key: "NM_199355.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "170692", geneDisplay: "ADAMTS18" },
  { key: "NM_199420.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10721", geneDisplay: "POLQ" },
  { key: "NM_199421.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122809", geneDisplay: "SOCS4" },
  { key: "NM_199437.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56980", geneDisplay: "PRDM10" },
  { key: "NM_199456.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "374739", geneDisplay: "TEPP" },
  { key: "NM_199461.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340719", geneDisplay: "NANOS1" },
  { key: "NM_199511.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151887", geneDisplay: "CCDC80" },
  { key: "NM_201253.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23418", geneDisplay: "CRB1" },
  { key: "NM_201269.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84146", geneDisplay: "ZNF644" },
  { key: "NM_201280.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63915", geneDisplay: "BLOC1S5" },
  { key: "NM_201286.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158880", geneDisplay: "USP51" },
  { key: "NM_201384.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5339", geneDisplay: "PLEC" },
  { key: "NM_201400.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "196483", geneDisplay: "EEF2KMT" },
  { key: "NM_201402.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "377630", geneDisplay: "USP17L2" },
  { key: "NM_201403.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148932", geneDisplay: "MOB3C" },
  { key: "NM_201412.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55692", geneDisplay: "LUC7L" },
  { key: "NM_201433.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8522", geneDisplay: "GAS7" },
  { key: "NM_201435.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84660", geneDisplay: "CCDC62" },
  { key: "NM_201439.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51535", geneDisplay: "PPHLN1" },
  { key: "NM_201453.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "445571", geneDisplay: "CBWD3" },
  { key: "NM_201521.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "89953", geneDisplay: "KLC4" },
  { key: "NM_201525.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9289", geneDisplay: "ADGRG1" },
  { key: "NM_201542.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "112950", geneDisplay: "MED8" },
  { key: "NM_201544.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3964", geneDisplay: "LGALS8" },
  { key: "NM_201548.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375298", geneDisplay: "CERKL" },
  { key: "NM_201550.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "376132", geneDisplay: "LRRC10" },
  { key: "NM_201566.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201232", geneDisplay: "SLC16A13" },
  { key: "NM_201589.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389692", geneDisplay: "MAFA" },
  { key: "NM_201591.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2823", geneDisplay: "GPM6A" },
  { key: "NM_201596.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "783", geneDisplay: "CACNB2" },
  { key: "NM_201599.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9203", geneDisplay: "ZMYM3" },
  { key: "NM_201624.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23032", geneDisplay: "USP33" },
  { key: "NM_201628.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23254", geneDisplay: "KAZN" },
  { key: "NM_201630.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10446", geneDisplay: "LRRN2" },
  { key: "NM_201631.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9333", geneDisplay: "TGM5" },
  { key: "NM_201648.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10249", geneDisplay: "GLYAT" },
  { key: "NM_201653.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27159", geneDisplay: "CHIA" },
  { key: "NM_203281.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "660", geneDisplay: "BMX" },
  { key: "NM_203282.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9534", geneDisplay: "ZNF254" },
  { key: "NM_203288.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6100", geneDisplay: "RP9" },
  { key: "NM_203290.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9533", geneDisplay: "POLR1C" },
  { key: "NM_203293.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81786", geneDisplay: "TRIM7" },
  { key: "NM_203298.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118487", geneDisplay: "CHCHD1" },
  { key: "NM_203299.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138724", geneDisplay: "C9orf131" },
  { key: "NM_203301.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "254170", geneDisplay: "FBXO33" },
  { key: "NM_203303.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389874", geneDisplay: "ZCCHC13" },
  { key: "NM_203304.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "399664", geneDisplay: "MEX3D" },
  { key: "NM_203306.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "399668", geneDisplay: "SMIM10L2A" },
  { key: "NM_203314.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "622", geneDisplay: "BDH1" },
  { key: "NM_203344.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29946", geneDisplay: "SERTAD3" },
  { key: "NM_203347.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389812", geneDisplay: "LCN15" },
  { key: "NM_203349.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "399694", geneDisplay: "SHC4" },
  { key: "NM_203350.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9406", geneDisplay: "ZRANB2" },
  { key: "NM_203370.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389119", geneDisplay: "INKA1" },
  { key: "NM_203371.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387758", geneDisplay: "FIBIN" },
  { key: "NM_203374.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147808", geneDisplay: "ZNF784" },
  { key: "NM_203376.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388730", geneDisplay: "TMEM81" },
  { key: "NM_203379.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51703", geneDisplay: "ACSL5" },
  { key: "NM_203387.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6050", geneDisplay: "RNH1" },
  { key: "NM_203394.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144455", geneDisplay: "E2F7" },
  { key: "NM_203395.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389434", geneDisplay: "IYD" },
  { key: "NM_203397.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "255374", geneDisplay: "MBLAC1" },
  { key: "NM_203400.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388394", geneDisplay: "RPRML" },
  { key: "NM_203402.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161247", geneDisplay: "FITM1" },
  { key: "NM_203403.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286343", geneDisplay: "LURAP1L" },
  { key: "NM_203405.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "388818", geneDisplay: "KRTAP26-1" },
  { key: "NM_203406.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153364", geneDisplay: "MBLAC2" },
  { key: "NM_203407.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340602", geneDisplay: "EZHIP" },
  { key: "NM_203408.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158724", geneDisplay: "FAM47A" },
  { key: "NM_203411.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92162", geneDisplay: "TMEM88" },
  { key: "NM_203412.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "164153", geneDisplay: "UBL4B" },
  { key: "NM_203414.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23587", geneDisplay: "ELP5" },
  { key: "NM_203416.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9332", geneDisplay: "CD163" },
  { key: "NM_203422.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "221091", geneDisplay: "LRRN4CL" },
  { key: "NM_203424.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389123", geneDisplay: "IQCF2" },
  { key: "NM_203434.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389792", geneDisplay: "IER5L" },
  { key: "NM_203436.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121549", geneDisplay: "ASCL4" },
  { key: "NM_203437.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "54812", geneDisplay: "AFTPH" },
  { key: "NM_203446.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "8867", geneDisplay: "SYNJ1" },
  { key: "NM_203447.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "81704", geneDisplay: "DOCK8" },
  { key: "NM_203451.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "400120", geneDisplay: "SERTM1" },
  { key: "NM_203453.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "403313", geneDisplay: "PLPP6" },
  { key: "NM_203454.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "403314", geneDisplay: "APOBEC4" },
  { key: "NM_203458.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388677", geneDisplay: "NOTCH2NLA" },
  { key: "NM_203459.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23271", geneDisplay: "CAMSAP2" },
  { key: "NM_203462.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "114932", geneDisplay: "MRFAP1L1" },
  { key: "NM_203463.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "253782", geneDisplay: "CERS6" },
  { key: "NM_203468.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "954", geneDisplay: "ENTPD2" },
  { key: "NM_203475.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64840", geneDisplay: "PORCN" },
  { key: "NM_203486.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10683", geneDisplay: "DLL3" },
  { key: "NM_203487.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "5101", geneDisplay: "PCDH9" },
  { key: "NM_203494.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "373509", geneDisplay: "USP50" },
  { key: "NM_203495.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "170622", geneDisplay: "COMMD6" },
  { key: "NM_203499.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11325", geneDisplay: "DDX42" },
  { key: "NM_203500.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9817", geneDisplay: "KEAP1" },
  { key: "NM_203505.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9908", geneDisplay: "G3BP2" },
  { key: "NM_205545.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137797", geneDisplay: "LYPD2" },
  { key: "NM_205548.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167555", geneDisplay: "FAM151B" },
  { key: "NM_205767.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125988", geneDisplay: "MICOS13" },
  { key: "NM_205768.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10472", geneDisplay: "ZBTB18" },
  { key: "NM_205834.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51599", geneDisplay: "LSR" },
  { key: "NM_205836.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81545", geneDisplay: "FBXO38" },
  { key: "NM_205839.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7940", geneDisplay: "LST1" },
  { key: "NM_205841.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "404203", geneDisplay: "SPINK6" },
  { key: "NM_205846.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153768", geneDisplay: "PRELID2" },
  { key: "NM_205849.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "171483", geneDisplay: "FAM9B" },
  { key: "NM_205850.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283652", geneDisplay: "SLC24A5" },
  { key: "NM_205853.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389125", geneDisplay: "MUSTN1" },
  { key: "NM_205854.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389376", geneDisplay: "SFTA2" },
  { key: "NM_205855.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "389558", geneDisplay: "FAM180A" },
  { key: "NM_205859.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26248", geneDisplay: "OR2K2" },
  { key: "NM_205860.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2494", geneDisplay: "NR5A2" },
  { key: "NM_205861.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79947", geneDisplay: "DHDDS" },
  { key: "NM_206538.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284361", geneDisplay: "EMC10" },
  { key: "NM_206808.5", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "171425", geneDisplay: "CLYBL" },
  { key: "NM_206809.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4340", geneDisplay: "MOG" },
  { key: "NM_206827.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "387496", geneDisplay: "RASL11A" },
  { key: "NM_206831.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285381", geneDisplay: "DPH3" },
  { key: "NM_206832.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388364", geneDisplay: "TMIGD1" },
  { key: "NM_206833.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "404217", geneDisplay: "CTXN1" },
  { key: "NM_206836.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10455", geneDisplay: "ECI2" },
  { key: "NM_206858.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153743", geneDisplay: "PPP1R2B" },
  { key: "NM_206862.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10579", geneDisplay: "TACC2" },
  { key: "NM_206880.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285659", geneDisplay: "OR2V2" },
  { key: "NM_206893.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341116", geneDisplay: "MS4A10" },
  { key: "NM_206894.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388536", geneDisplay: "ZNF790" },
  { key: "NM_206899.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121130", geneDisplay: "OR10P1" },
  { key: "NM_206917.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "27018", geneDisplay: "BEX3" },
  { key: "NM_206918.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123099", geneDisplay: "DEGS2" },
  { key: "NM_206920.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158056", geneDisplay: "MAMDC4" },
  { key: "NM_206922.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401262", geneDisplay: "CRIP3" },
  { key: "NM_206923.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "404281", geneDisplay: "YY2" },
  { key: "NM_206927.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54843", geneDisplay: "SYTL2" },
  { key: "NM_206933.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7399", geneDisplay: "USH2A" },
  { key: "NM_206937.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "3981", geneDisplay: "LIG4" },
  { key: "NM_206943.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4052", geneDisplay: "LTBP1" },
  { key: "NM_206949.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122509", geneDisplay: "IFI27L1" },
  { key: "NM_206956.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23532", geneDisplay: "PRAME" },
  { key: "NM_206962.4", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3275", geneDisplay: "PRMT2" },
  { key: "NM_206963.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5918", geneDisplay: "RARRES1" },
  { key: "NM_206965.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10841", geneDisplay: "FTCD" },
  { key: "NM_206966.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389336", geneDisplay: "C5orf46" },
  { key: "NM_206967.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "404550", geneDisplay: "C16orf74" },
  { key: "NM_206996.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200162", geneDisplay: "SPAG17" },
  { key: "NM_206997.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390212", geneDisplay: "GPR152" },
  { key: "NM_206998.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "404552", geneDisplay: "SCGB1D4" },
  { key: "NM_207009.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "404636", geneDisplay: "DENND10" },
  { key: "NM_207015.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "254827", geneDisplay: "NAALADL2" },
  { key: "NM_207034.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "1908", geneDisplay: "EDN3" },
  { key: "NM_207037.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6938", geneDisplay: "TCF12" },
  { key: "NM_207102.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285231", geneDisplay: "FBXW12" },
  { key: "NM_207103.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388325", geneDisplay: "SCIMP" },
  { key: "NM_207111.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54476", geneDisplay: "RNF216" },
  { key: "NM_207113.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84255", geneDisplay: "SLC37A3" },
  { key: "NM_207115.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51157", geneDisplay: "ZNF580" },
  { key: "NM_207117.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283600", geneDisplay: "SLC25A47" },
  { key: "NM_207118.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "404672", geneDisplay: "GTF2H5" },
  { key: "NM_207122.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2132", geneDisplay: "EXT2" },
  { key: "NM_207163.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "442721", geneDisplay: "LMOD2" },
  { key: "NM_207172.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "387129", geneDisplay: "NPSR1" },
  { key: "NM_207186.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283297", geneDisplay: "OR10A4" },
  { key: "NM_207189.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "676", geneDisplay: "BRDT" },
  { key: "NM_207197.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8751", geneDisplay: "ADAM15" },
  { key: "NM_207299.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54886", geneDisplay: "PLPPR1" },
  { key: "NM_207303.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26033", geneDisplay: "ATRNL1" },
  { key: "NM_207305.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2298", geneDisplay: "FOXD4" },
  { key: "NM_207307.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "90288", geneDisplay: "EFCAB12" },
  { key: "NM_207308.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91181", geneDisplay: "NUP210L" },
  { key: "NM_207309.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "91373", geneDisplay: "UAP1L1" },
  { key: "NM_207312.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "112714", geneDisplay: "TUBA3E" },
  { key: "NM_207315.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129607", geneDisplay: "CMPK2" },
  { key: "NM_207316.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131920", geneDisplay: "TMEM207" },
  { key: "NM_207317.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133923", geneDisplay: "ZNF474" },
  { key: "NM_207318.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139231", geneDisplay: "FAM199X" },
  { key: "NM_207319.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139420", geneDisplay: "PPP4R3C" },
  { key: "NM_207320.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139562", geneDisplay: "OTUD6A" },
  { key: "NM_207321.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "142827", geneDisplay: "ACSM6" },
  { key: "NM_207322.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145741", geneDisplay: "C2CD4A" },
  { key: "NM_207327.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150383", geneDisplay: "CDPF1" },
  { key: "NM_207330.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152519", geneDisplay: "NIPAL1" },
  { key: "NM_207332.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157697", geneDisplay: "ERICH1" },
  { key: "NM_207334.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163933", geneDisplay: "FAM43B" },
  { key: "NM_207335.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "166348", geneDisplay: "KBTBD12" },
  { key: "NM_207336.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168544", geneDisplay: "ZNF467" },
  { key: "NM_207338.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "197021", geneDisplay: "LCTL" },
  { key: "NM_207339.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203569", geneDisplay: "PAGE2" },
  { key: "NM_207340.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254359", geneDisplay: "ZDHHC24" },
  { key: "NM_207341.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22917", geneDisplay: "ZP1" },
  { key: "NM_207343.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "257160", geneDisplay: "RNF214" },
  { key: "NM_207344.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283377", geneDisplay: "SPRYD4" },
  { key: "NM_207345.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283420", geneDisplay: "CLEC9A" },
  { key: "NM_207346.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "283989", geneDisplay: "TSEN54" },
  { key: "NM_207348.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284723", geneDisplay: "SLC25A34" },
  { key: "NM_207351.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285368", geneDisplay: "PRRT3" },
  { key: "NM_207352.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285440", geneDisplay: "CYP4V2" },
  { key: "NM_207354.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338692", geneDisplay: "ANKRD13D" },
  { key: "NM_207355.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "102724631", geneDisplay: "POTEB3" },
  { key: "NM_207356.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339448", geneDisplay: "C1orf174" },
  { key: "NM_207359.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "339896", geneDisplay: "GADL1" },
  { key: "NM_207360.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340152", geneDisplay: "ZC3H12D" },
  { key: "NM_207361.6", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "341640", geneDisplay: "FREM2" },
  { key: "NM_207362.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "343990", geneDisplay: "CRACDL" },
  { key: "NM_207363.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344148", geneDisplay: "NCKAP5" },
  { key: "NM_207364.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344561", geneDisplay: "GPR148" },
  { key: "NM_207365.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344752", geneDisplay: "AADACL2" },
  { key: "NM_207366.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346288", geneDisplay: "SEPTIN14" },
  { key: "NM_207368.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "348262", geneDisplay: "MCRIP1" },
  { key: "NM_207370.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "387509", geneDisplay: "GPR153" },
  { key: "NM_207371.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387640", geneDisplay: "SKIDA1" },
  { key: "NM_207373.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387695", geneDisplay: "C10orf99" },
  { key: "NM_207374.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81341", geneDisplay: "OR10W1" },
  { key: "NM_207377.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "387990", geneDisplay: "TOMM20L" },
  { key: "NM_207380.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "388115", geneDisplay: "CCDC9B" },
  { key: "NM_207386.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388336", geneDisplay: "SHISA6" },
  { key: "NM_207391.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388531", geneDisplay: "RGS9BP" },
  { key: "NM_207392.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388533", geneDisplay: "KRTDAP" },
  { key: "NM_207393.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388555", geneDisplay: "IGFL3" },
  { key: "NM_207395.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388569", geneDisplay: "ZNF324B" },
  { key: "NM_207396.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388591", geneDisplay: "RNF207" },
  { key: "NM_207398.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388646", geneDisplay: "GBP7" },
  { key: "NM_207404.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389114", geneDisplay: "ZNF662" },
  { key: "NM_207406.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "389206", geneDisplay: "BEND4" },
  { key: "NM_207407.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "389208", geneDisplay: "TMPRSS11F" },
  { key: "NM_207408.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389320", geneDisplay: "TEX43" },
  { key: "NM_207409.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389383", geneDisplay: "CLPSL2" },
  { key: "NM_207410.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389400", geneDisplay: "GFRAL" },
  { key: "NM_207411.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389610", geneDisplay: "XKR5" },
  { key: "NM_207413.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389658", geneDisplay: "ALKAL1" },
  { key: "NM_207416.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389762", geneDisplay: "SPATA31D3" },
  { key: "NM_207418.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728833", geneDisplay: "FAM72D" },
  { key: "NM_207419.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "390664", geneDisplay: "C1QTNF8" },
  { key: "NM_207420.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "155184", geneDisplay: "SLC2A7" },
  { key: "NM_207421.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353238", geneDisplay: "PADI6" },
  { key: "NM_207426.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "399823", geneDisplay: "FOXI2" },
  { key: "NM_207446.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "400451", geneDisplay: "FAM174B" },
  { key: "NM_207459.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "400629", geneDisplay: "TEX19" },
  { key: "NM_207469.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "400830", geneDisplay: "DEFB132" },
  { key: "NM_207506.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "401474", geneDisplay: "SAMD12" },
  { key: "NM_207510.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401562", geneDisplay: "LCNL1" },
  { key: "NM_207517.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57188", geneDisplay: "ADAMTSL3" },
  { key: "NM_207581.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "405753", geneDisplay: "DUOXA2" },
  { key: "NM_207582.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "405754", geneDisplay: "ERVFRD-1" },
  { key: "NM_207644.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "388886", geneDisplay: "LRRC75B" },
  { key: "NM_207645.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399947", geneDisplay: "C11orf87" },
  { key: "NM_212481.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10865", geneDisplay: "ARID5A" },
  { key: "NM_212482.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2335", geneDisplay: "FN1" },
  { key: "NM_212502.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5129", geneDisplay: "CDK18" },
  { key: "NM_212550.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388552", geneDisplay: "BLOC1S3" },
  { key: "NM_212551.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388695", geneDisplay: "LYSMD1" },
  { key: "NM_212552.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388962", geneDisplay: "BOLA3" },
  { key: "NM_212554.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "399818", geneDisplay: "EEF1AKMT2" },
  { key: "NM_212555.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399967", geneDisplay: "PATE2" },
  { key: "NM_212556.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "401036", geneDisplay: "ASB18" },
  { key: "NM_212557.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401138", geneDisplay: "AMTN" },
  { key: "NM_212558.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401498", geneDisplay: "TMEM215" },
  { key: "NM_212559.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "402415", geneDisplay: "XKRX" },
  { key: "NM_213589.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65059", geneDisplay: "RAPH1" },
  { key: "NM_213590.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10206", geneDisplay: "TRIM13" },
  { key: "NM_213594.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5992", geneDisplay: "RFX4" },
  { key: "NM_213595.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23479", geneDisplay: "ISCU" },
  { key: "NM_213596.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121643", geneDisplay: "FOXN4" },
  { key: "NM_213598.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125919", geneDisplay: "ZNF543" },
  { key: "NM_213599.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "203859", geneDisplay: "ANO5" },
  { key: "NM_213600.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "255189", geneDisplay: "PLA2G4F" },
  { key: "NM_213601.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283578", geneDisplay: "TMED8" },
  { key: "NM_213602.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284266", geneDisplay: "SIGLEC15" },
  { key: "NM_213603.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285989", geneDisplay: "ZNF789" },
  { key: "NM_213604.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339366", geneDisplay: "ADAMTSL5" },
  { key: "NM_213605.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340385", geneDisplay: "ZNF517" },
  { key: "NM_213606.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387700", geneDisplay: "SLC16A12" },
  { key: "NM_213607.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388389", geneDisplay: "CCDC103" },
  { key: "NM_213608.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "401027", geneDisplay: "C2orf66" },
  { key: "NM_213609.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "407738", geneDisplay: "TAFA1" },
  { key: "NM_213618.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6764", geneDisplay: "DENND2B" },
  { key: "NM_213622.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10617", geneDisplay: "STAMBP" },
  { key: "NM_213647.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2264", geneDisplay: "FGFR4" },
  { key: "NM_213649.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119559", geneDisplay: "SFXN4" },
  { key: "NM_213653.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148738", geneDisplay: "HJV" },
  { key: "NM_213656.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "390792", geneDisplay: "KRT39" },
  { key: "NM_213720.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "400916", geneDisplay: "CHCHD10" },
  { key: "NM_214462.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "168002", geneDisplay: "DACT2" },
  { key: "NM_214711.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401137", geneDisplay: "PRR27" },
  { key: "NM_001001317.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136541", geneDisplay: "PRSS58" },
  { key: "NM_001001325.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "408187", geneDisplay: "SPINK14" },
  { key: "NM_001001330.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221035", geneDisplay: "REEP3" },
  { key: "NM_001001331.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "491", geneDisplay: "ATP2B2" },
  { key: "NM_001001343.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "408263", geneDisplay: "FNDC9" },
  { key: "NM_001001344.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "492", geneDisplay: "ATP2B3" },
  { key: "NM_001001346.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "49861", geneDisplay: "CLDN20" },
  { key: "NM_001001410.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "115939", geneDisplay: "TSR3" },
  { key: "NM_001001411.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163223", geneDisplay: "ZNF676" },
  { key: "NM_001001412.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "255022", geneDisplay: "CALHM1" },
  { key: "NM_001001413.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283767", geneDisplay: "GOLGA6L1" },
  { key: "NM_001001414.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342897", geneDisplay: "NCCRP1" },
  { key: "NM_001001415.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "353088", geneDisplay: "ZNF429" },
  { key: "NM_001001417.7", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "414059", geneDisplay: "TBC1D3B" },
  { key: "NM_001001418.6", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "414060", geneDisplay: "TBC1D3C" },
  { key: "NM_001001433.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8675", geneDisplay: "STX16" },
  { key: "NM_001001436.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "388272", geneDisplay: "C16orf87" },
  { key: "NM_001001437.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "414062", geneDisplay: "CCL3L3" },
  { key: "NM_001001479.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "339665", geneDisplay: "SLC35E4" },
  { key: "NM_001001480.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "439915", geneDisplay: "KRTAP5-5" },
  { key: "NM_001001520.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84717", geneDisplay: "HDGFL2" },
  { key: "NM_001001524.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53345", geneDisplay: "TM6SF2" },
  { key: "NM_001001548.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "948", geneDisplay: "CD36" },
  { key: "NM_001001551.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "414328", geneDisplay: "IDNK" },
  { key: "NM_001001557.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "392255", geneDisplay: "GDF6" },
  { key: "NM_001001563.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "92609", geneDisplay: "TIMM50" },
  { key: "NM_001001656.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "130075", geneDisplay: "OR9A4" },
  { key: "NM_001001657.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134083", geneDisplay: "OR2Y1" },
  { key: "NM_001001658.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135924", geneDisplay: "OR9A2" },
  { key: "NM_001001659.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135941", geneDisplay: "OR2A14" },
  { key: "NM_001001660.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144363", geneDisplay: "ETFRF1" },
  { key: "NM_001001661.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155054", geneDisplay: "ZNF425" },
  { key: "NM_001001662.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158431", geneDisplay: "ZNF782" },
  { key: "NM_001001664.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339745", geneDisplay: "SPOPL" },
  { key: "NM_001001667.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346517", geneDisplay: "OR6V1" },
  { key: "NM_001001668.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388566", geneDisplay: "ZNF470" },
  { key: "NM_001001669.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389337", geneDisplay: "ARHGEF37" },
  { key: "NM_001001670.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389763", geneDisplay: "SPATA31D1" },
  { key: "NM_001001671.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389840", geneDisplay: "MAP3K15" },
  { key: "NM_001001674.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390649", geneDisplay: "OR4F15" },
  { key: "NM_001001683.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "400569", geneDisplay: "MED11" },
  { key: "NM_001001701.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401152", geneDisplay: "C4orf3" },
  { key: "NM_001001710.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401565", geneDisplay: "FAM166A" },
  { key: "NM_001001711.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "414301", geneDisplay: "DDI1" },
  { key: "NM_001001712.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "414332", geneDisplay: "LCN10" },
  { key: "NM_001001722.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "203611", geneDisplay: "CDY2B" },
  { key: "NM_001001786.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "414899", geneDisplay: "BLID" },
  { key: "NM_001001788.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "353091", geneDisplay: "RAET1G" },
  { key: "NM_001001790.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401505", geneDisplay: "TOMM5" },
  { key: "NM_001001794.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "414918", geneDisplay: "DENND6B" },
  { key: "NM_001001795.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "414919", geneDisplay: "C8orf82" },
  { key: "NM_001001802.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "402317", geneDisplay: "OR2A42" },
  { key: "NM_001001821.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127068", geneDisplay: "OR2T34" },
  { key: "NM_001001824.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "403239", geneDisplay: "OR2T27" },
  { key: "NM_001001827.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "403244", geneDisplay: "OR2T35" },
  { key: "NM_001001850.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "415117", geneDisplay: "STX19" },
  { key: "NM_001001852.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "415116", geneDisplay: "PIM3" },
  { key: "NM_001001872.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145407", geneDisplay: "ARMH4" },
  { key: "NM_001001874.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "89882", geneDisplay: "TPD52L3" },
  { key: "NM_001001888.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "425054", geneDisplay: "VCX3B" },
  { key: "NM_001001912.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26686", geneDisplay: "OR4E2" },
  { key: "NM_001001913.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79473", geneDisplay: "OR52N1" },
  { key: "NM_001001914.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81469", geneDisplay: "OR2G3" },
  { key: "NM_001001915.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81470", geneDisplay: "OR2G2" },
  { key: "NM_001001916.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119679", geneDisplay: "OR52J3" },
  { key: "NM_001001918.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127066", geneDisplay: "OR14C36" },
  { key: "NM_001001919.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138804", geneDisplay: "OR13C4" },
  { key: "NM_001001920.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81309", geneDisplay: "OR4C15" },
  { key: "NM_001001921.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219447", geneDisplay: "OR5AS1" },
  { key: "NM_001001923.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392391", geneDisplay: "OR5C1" },
  { key: "NM_001001936.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84632", geneDisplay: "AFAP1L2" },
  { key: "NM_001001952.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219438", geneDisplay: "OR5D18" },
  { key: "NM_001001953.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219870", geneDisplay: "OR10G9" },
  { key: "NM_001001954.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219981", geneDisplay: "OR5A2" },
  { key: "NM_001001955.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283092", geneDisplay: "OR4C13" },
  { key: "NM_001001956.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286362", geneDisplay: "OR13C9" },
  { key: "NM_001001957.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343171", geneDisplay: "OR2W3" },
  { key: "NM_001001958.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390883", geneDisplay: "OR7G3" },
  { key: "NM_001001959.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391189", geneDisplay: "OR11L1" },
  { key: "NM_001001960.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390148", geneDisplay: "OR5W2" },
  { key: "NM_001001961.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138803", geneDisplay: "OR13C3" },
  { key: "NM_001001963.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391190", geneDisplay: "OR2L8" },
  { key: "NM_001001964.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127077", geneDisplay: "OR2T11" },
  { key: "NM_001001965.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219875", geneDisplay: "OR4D5" },
  { key: "NM_001001966.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284532", geneDisplay: "OR14A16" },
  { key: "NM_001001967.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390142", geneDisplay: "OR5D13" },
  { key: "NM_001001968.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "341799", geneDisplay: "OR6S1" },
  { key: "NM_001001973.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "509", geneDisplay: "ATP5F1C" },
  { key: "NM_001001974.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "59338", geneDisplay: "PLEKHA1" },
  { key: "NM_001001976.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11101", geneDisplay: "ATE1" },
  { key: "NM_001001991.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25891", geneDisplay: "PAMR1" },
  { key: "NM_001001995.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2824", geneDisplay: "GPM6B" },
  { key: "NM_001001998.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5394", geneDisplay: "EXOSC10" },
  { key: "NM_001002002.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51292", geneDisplay: "GMPR2" },
  { key: "NM_001002010.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51251", geneDisplay: "NT5C3A" },
  { key: "NM_001002029.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "721", geneDisplay: "C4B" },
  { key: "NM_001002030.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55862", geneDisplay: "ECHDC1" },
  { key: "NM_001002034.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150368", geneDisplay: "PHETA2" },
  { key: "NM_001002035.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "245911", geneDisplay: "DEFB108B" },
  { key: "NM_001002036.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "431705", geneDisplay: "ASTL" },
  { key: "NM_001002248.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51529", geneDisplay: "ANAPC11" },
  { key: "NM_001002254.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158835", geneDisplay: "AWAT2" },
  { key: "NM_001002255.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387082", geneDisplay: "SUMO4" },
  { key: "NM_001002257.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "253558", geneDisplay: "LCLAT1" },
  { key: "NM_001002294.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2328", geneDisplay: "FMO3" },
  { key: "NM_001002295.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2625", geneDisplay: "GATA3" },
  { key: "NM_001002296.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51125", geneDisplay: "GOLGA7" },
  { key: "NM_001002755.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "27247", geneDisplay: "NFU1" },
  { key: "NM_001002758.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "442862", geneDisplay: "PRY2" },
  { key: "NM_001002759.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119392", geneDisplay: "SFR1" },
  { key: "NM_001002760.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "442867", geneDisplay: "BPY2B" },
  { key: "NM_001002761.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "442868", geneDisplay: "BPY2C" },
  { key: "NM_001002800.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10051", geneDisplay: "SMC4" },
  { key: "NM_001002814.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80223", geneDisplay: "RAB11FIP1" },
  { key: "NM_001002836.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126208", geneDisplay: "ZNF787" },
  { key: "NM_001002847.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "113455421", geneDisplay: "DERPC" },
  { key: "NM_001002860.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55727", geneDisplay: "BTBD7" },
  { key: "NM_001002862.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91319", geneDisplay: "DERL3" },
  { key: "NM_001002901.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127943", geneDisplay: "FCRLB" },
  { key: "NM_001002905.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26494", geneDisplay: "OR8G1" },
  { key: "NM_001002907.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390157", geneDisplay: "OR8K1" },
  { key: "NM_001002909.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23131", geneDisplay: "GPATCH8" },
  { key: "NM_001002911.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124274", geneDisplay: "GPR139" },
  { key: "NM_001002912.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127254", geneDisplay: "ERICH3" },
  { key: "NM_001002913.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138428", geneDisplay: "PTRH1" },
  { key: "NM_001002916.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158983", geneDisplay: "H2BW1" },
  { key: "NM_001002917.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283159", geneDisplay: "OR8D1" },
  { key: "NM_001002918.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283160", geneDisplay: "OR8D2" },
  { key: "NM_001002919.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285016", geneDisplay: "ALKAL2" },
  { key: "NM_001002923.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "444882", geneDisplay: "IGFL4" },
  { key: "NM_001002925.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338675", geneDisplay: "OR5AP2" },
  { key: "NM_001002926.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221830", geneDisplay: "POLR1F" },
  { key: "NM_001003443.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390083", geneDisplay: "OR56A3" },
  { key: "NM_001003665.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375057", geneDisplay: "STUM" },
  { key: "NM_001003681.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10042", geneDisplay: "HMGXB4" },
  { key: "NM_001003682.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "399474", geneDisplay: "TMEM200B" },
  { key: "NM_001003692.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55954", geneDisplay: "ZMAT5" },
  { key: "NM_001003693.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "259215", geneDisplay: "LY6G6F" },
  { key: "NM_001003694.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7862", geneDisplay: "BRPF1" },
  { key: "NM_001003699.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6239", geneDisplay: "RREB1" },
  { key: "NM_001003702.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "445328", geneDisplay: "ARHGEF35" },
  { key: "NM_001003703.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "522", geneDisplay: "ATP5PF" },
  { key: "NM_001003722.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2733", geneDisplay: "GLE1" },
  { key: "NM_001003745.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26496", geneDisplay: "OR10A3" },
  { key: "NM_001003750.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120586", geneDisplay: "OR8I2" },
  { key: "NM_001003760.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401265", geneDisplay: "KLHL31" },
  { key: "NM_001003787.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92335", geneDisplay: "STRADA" },
  { key: "NM_001003793.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27303", geneDisplay: "RBMS3" },
  { key: "NM_001003795.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "389524", geneDisplay: "GTF2IRD2B" },
  { key: "NM_001003796.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "4809", geneDisplay: "SNU13" },
  { key: "NM_001003800.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23299", geneDisplay: "BICD2" },
  { key: "NM_001003801.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "6604", geneDisplay: "SMARCD3" },
  { key: "NM_001003806.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "445347", geneDisplay: "TARP" },
  { key: "NM_001003818.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117854", geneDisplay: "TRIM6" },
  { key: "NM_001003841.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340024", geneDisplay: "SLC6A19" },
  { key: "NM_001003845.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389058", geneDisplay: "SP5" },
  { key: "NM_001003891.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51586", geneDisplay: "MED15" },
  { key: "NM_001003892.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "338599", geneDisplay: "DUSP29" },
  { key: "NM_001003894.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "253175", geneDisplay: "CDY1B" },
  { key: "NM_001003897.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "63905", geneDisplay: "MANBAL" },
  { key: "NM_001003931.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10039", geneDisplay: "PARP3" },
  { key: "NM_001003937.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388951", geneDisplay: "TSPYL6" },
  { key: "NM_001003938.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3042", geneDisplay: "HBM" },
  { key: "NM_001003940.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90427", geneDisplay: "BMF" },
  { key: "NM_001004019.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2199", geneDisplay: "FBLN2" },
  { key: "NM_001004051.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "114928", geneDisplay: "GPRASP2" },
  { key: "NM_001004052.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "255725", geneDisplay: "OR52B2" },
  { key: "NM_001004058.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219453", geneDisplay: "OR8K5" },
  { key: "NM_001004059.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219431", geneDisplay: "OR4S2" },
  { key: "NM_001004063.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79544", geneDisplay: "OR4K1" },
  { key: "NM_001004064.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81168", geneDisplay: "OR8J3" },
  { key: "NM_001004067.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "408050", geneDisplay: "NOMO3" },
  { key: "NM_001004106.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2870", geneDisplay: "GRK6" },
  { key: "NM_001004124.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81300", geneDisplay: "OR4P4" },
  { key: "NM_001004125.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286319", geneDisplay: "TUSC1" },
  { key: "NM_001004127.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "440138", geneDisplay: "ALG11" },
  { key: "NM_001004134.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121275", geneDisplay: "OR10AD1" },
  { key: "NM_001004135.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346525", geneDisplay: "OR2A12" },
  { key: "NM_001004136.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "401992", geneDisplay: "OR2T2" },
  { key: "NM_001004137.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119772", geneDisplay: "OR52M1" },
  { key: "NM_001004297.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79290", geneDisplay: "OR13A1" },
  { key: "NM_001004301.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126017", geneDisplay: "ZNF813" },
  { key: "NM_001004303.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199920", geneDisplay: "FYB2" },
  { key: "NM_001004304.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283337", geneDisplay: "ZNF740" },
  { key: "NM_001004309.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "342132", geneDisplay: "ZNF774" },
  { key: "NM_001004310.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343413", geneDisplay: "FCRL6" },
  { key: "NM_001004311.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344018", geneDisplay: "FIGLA" },
  { key: "NM_001004312.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344892", geneDisplay: "RTP2" },
  { key: "NM_001004313.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388335", geneDisplay: "TMEM220" },
  { key: "NM_001004316.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389170", geneDisplay: "LEKR1" },
  { key: "NM_001004317.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389421", geneDisplay: "LIN28B" },
  { key: "NM_001004318.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390928", geneDisplay: "ACP7" },
  { key: "NM_001004319.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391104", geneDisplay: "VHLL" },
  { key: "NM_001004320.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "392636", geneDisplay: "AGMO" },
  { key: "NM_001004323.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "402573", geneDisplay: "C7orf61" },
  { key: "NM_001004325.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "440021", geneDisplay: "KRTAP5-2" },
  { key: "NM_001004328.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440077", geneDisplay: "ZNF705A" },
  { key: "NM_001004329.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440097", geneDisplay: "DBX2" },
  { key: "NM_001004333.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "440400", geneDisplay: "RNASEK" },
  { key: "NM_001004334.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "440435", geneDisplay: "GPR179" },
  { key: "NM_001004339.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440590", geneDisplay: "ZYG11A" },
  { key: "NM_001004341.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440695", geneDisplay: "ETV3L" },
  { key: "NM_001004342.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440730", geneDisplay: "TRIM67" },
  { key: "NM_001004343.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440738", geneDisplay: "MAP1LC3C" },
  { key: "NM_001004351.5", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441272", geneDisplay: "SPDYE3" },
  { key: "NM_001004353.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "441476", geneDisplay: "STPG3" },
  { key: "NM_001004354.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "441478", geneDisplay: "NRARP" },
  { key: "NM_001004356.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "53834", geneDisplay: "FGFRL1" },
  { key: "NM_001004416.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "89766", geneDisplay: "UMODL1" },
  { key: "NM_001004431.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284207", geneDisplay: "METRNL" },
  { key: "NM_001004432.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339398", geneDisplay: "LINGO4" },
  { key: "NM_001004434.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7780", geneDisplay: "SLC30A2" },
  { key: "NM_001004439.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "22801", geneDisplay: "ITGA11" },
  { key: "NM_001004441.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340120", geneDisplay: "ANKRD34B" },
  { key: "NM_001004450.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347169", geneDisplay: "OR1B1" },
  { key: "NM_001004451.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347168", geneDisplay: "OR1J1" },
  { key: "NM_001004452.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26219", geneDisplay: "OR1J4" },
  { key: "NM_001004453.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392390", geneDisplay: "OR1L6" },
  { key: "NM_001004454.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138881", geneDisplay: "OR1L8" },
  { key: "NM_001004456.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125963", geneDisplay: "OR1M1" },
  { key: "NM_001004457.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138882", geneDisplay: "OR1N2" },
  { key: "NM_001004458.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219959", geneDisplay: "OR1S1" },
  { key: "NM_001004459.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219958", geneDisplay: "OR1S2" },
  { key: "NM_001004460.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341276", geneDisplay: "OR10A2" },
  { key: "NM_001004461.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390093", geneDisplay: "OR10A6" },
  { key: "NM_001004462.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390264", geneDisplay: "OR10G4" },
  { key: "NM_001004463.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390265", geneDisplay: "OR10G7" },
  { key: "NM_001004464.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219869", geneDisplay: "OR10G8" },
  { key: "NM_001004465.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126541", geneDisplay: "OR10H4" },
  { key: "NM_001004466.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284433", geneDisplay: "OR10H5" },
  { key: "NM_001004469.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127385", geneDisplay: "OR10J5" },
  { key: "NM_001004470.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "338596", geneDisplay: "ST8SIA6" },
  { key: "NM_001004471.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219960", geneDisplay: "OR10Q1" },
  { key: "NM_001004473.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391109", geneDisplay: "OR10K1" },
  { key: "NM_001004474.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219873", geneDisplay: "OR10S1" },
  { key: "NM_001004475.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128360", geneDisplay: "OR10T2" },
  { key: "NM_001004476.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391107", geneDisplay: "OR10K2" },
  { key: "NM_001004477.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128367", geneDisplay: "OR10X1" },
  { key: "NM_001004478.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128368", geneDisplay: "OR10Z1" },
  { key: "NM_001004479.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390442", geneDisplay: "OR11H4" },
  { key: "NM_001004480.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122748", geneDisplay: "OR11H6" },
  { key: "NM_001004481.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392376", geneDisplay: "OR13C2" },
  { key: "NM_001004482.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138799", geneDisplay: "OR13C5" },
  { key: "NM_001004483.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138802", geneDisplay: "OR13C8" },
  { key: "NM_001004484.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286365", geneDisplay: "OR13D1" },
  { key: "NM_001004485.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138805", geneDisplay: "OR13F1" },
  { key: "NM_001004486.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347468", geneDisplay: "OR13H1" },
  { key: "NM_001004487.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392309", geneDisplay: "OR13J1" },
  { key: "NM_001004489.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144125", geneDisplay: "OR2AG1" },
  { key: "NM_001004490.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338755", geneDisplay: "OR2AG2" },
  { key: "NM_001004491.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391191", geneDisplay: "OR2AK2" },
  { key: "NM_001004492.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127623", geneDisplay: "OR2B11" },
  { key: "NM_001004684.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120775", geneDisplay: "OR2D3" },
  { key: "NM_001004685.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135948", geneDisplay: "OR2F2" },
  { key: "NM_001004687.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391192", geneDisplay: "OR2L3" },
  { key: "NM_001004688.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391194", geneDisplay: "OR2M2" },
  { key: "NM_001004689.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127062", geneDisplay: "OR2M3" },
  { key: "NM_001004690.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127059", geneDisplay: "OR2M5" },
  { key: "NM_001004691.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391196", geneDisplay: "OR2M7" },
  { key: "NM_001004692.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127064", geneDisplay: "OR2T12" },
  { key: "NM_001004693.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127069", geneDisplay: "OR2T10" },
  { key: "NM_001004694.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343563", geneDisplay: "OR2T29" },
  { key: "NM_001004695.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391195", geneDisplay: "OR2T33" },
  { key: "NM_001004696.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127074", geneDisplay: "OR2T4" },
  { key: "NM_001004697.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "401993", geneDisplay: "OR2T5" },
  { key: "NM_001004699.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284383", geneDisplay: "OR2Z1" },
  { key: "NM_001004700.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219429", geneDisplay: "OR4C11" },
  { key: "NM_001004701.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219428", geneDisplay: "OR4C16" },
  { key: "NM_001004702.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256144", geneDisplay: "OR4C3" },
  { key: "NM_001004703.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119749", geneDisplay: "OR4C46" },
  { key: "NM_001004704.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219432", geneDisplay: "OR4C6" },
  { key: "NM_001004705.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390197", geneDisplay: "OR4D10" },
  { key: "NM_001004706.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219986", geneDisplay: "OR4D11" },
  { key: "NM_001004707.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124538", geneDisplay: "OR4D2" },
  { key: "NM_001004708.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219983", geneDisplay: "OR4D6" },
  { key: "NM_001004711.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390199", geneDisplay: "OR4D9" },
  { key: "NM_001004712.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122740", geneDisplay: "OR4K14" },
  { key: "NM_001004713.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126370", geneDisplay: "OR1I1" },
  { key: "NM_001004714.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390433", geneDisplay: "OR4K13" },
  { key: "NM_001004715.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390436", geneDisplay: "OR4K17" },
  { key: "NM_001004717.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122742", geneDisplay: "OR4L1" },
  { key: "NM_001004719.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390538", geneDisplay: "OR4M2" },
  { key: "NM_001004723.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390429", geneDisplay: "OR4N2" },
  { key: "NM_001004724.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390437", geneDisplay: "OR4N5" },
  { key: "NM_001004725.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256148", geneDisplay: "OR4S1" },
  { key: "NM_001004726.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390113", geneDisplay: "OR4X1" },
  { key: "NM_001004727.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119764", geneDisplay: "OR4X2" },
  { key: "NM_001004728.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219982", geneDisplay: "OR5A1" },
  { key: "NM_001004729.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390195", geneDisplay: "OR5AN1" },
  { key: "NM_001004730.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219493", geneDisplay: "OR5AR1" },
  { key: "NM_001004731.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390445", geneDisplay: "OR5AU1" },
  { key: "NM_001004732.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343170", geneDisplay: "OR14K1" },
  { key: "NM_001004733.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390191", geneDisplay: "OR5B12" },
  { key: "NM_001004734.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "401994", geneDisplay: "OR14I1" },
  { key: "NM_001004735.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219436", geneDisplay: "OR5D14" },
  { key: "NM_001004736.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26339", geneDisplay: "OR5K1" },
  { key: "NM_001004737.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "402135", geneDisplay: "OR5K2" },
  { key: "NM_001004738.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219437", geneDisplay: "OR5L1" },
  { key: "NM_001004739.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26338", geneDisplay: "OR5L2" },
  { key: "NM_001004740.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390168", geneDisplay: "OR5M1" },
  { key: "NM_001004741.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390167", geneDisplay: "OR5M10" },
  { key: "NM_001004742.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219482", geneDisplay: "OR5M3" },
  { key: "NM_001004743.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390162", geneDisplay: "OR5M9" },
  { key: "NM_001004744.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219479", geneDisplay: "OR8U3" },
  { key: "NM_001004745.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390155", geneDisplay: "OR5T1" },
  { key: "NM_001004746.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219464", geneDisplay: "OR5T2" },
  { key: "NM_001004747.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390154", geneDisplay: "OR5T3" },
  { key: "NM_001004748.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "401667", geneDisplay: "OR51A2" },
  { key: "NM_001004749.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119687", geneDisplay: "OR51A7" },
  { key: "NM_001004750.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390058", geneDisplay: "OR51B6" },
  { key: "NM_001004751.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390038", geneDisplay: "OR51D1" },
  { key: "NM_001004752.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "256892", geneDisplay: "OR51F1" },
  { key: "NM_001004753.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119694", geneDisplay: "OR51F2" },
  { key: "NM_001004754.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390064", geneDisplay: "OR51I2" },
  { key: "NM_001004755.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119682", geneDisplay: "OR51L1" },
  { key: "NM_001004756.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390059", geneDisplay: "OR51M1" },
  { key: "NM_001004757.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390061", geneDisplay: "OR51Q1" },
  { key: "NM_001004758.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119692", geneDisplay: "OR51S1" },
  { key: "NM_001004759.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "401665", geneDisplay: "OR51T1" },
  { key: "NM_001004760.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283111", geneDisplay: "OR51V1" },
  { key: "NM_001005160.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390054", geneDisplay: "OR52A5" },
  { key: "NM_001005161.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143496", geneDisplay: "OR52B4" },
  { key: "NM_001005162.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "340980", geneDisplay: "OR52B6" },
  { key: "NM_001005163.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390066", geneDisplay: "OR52D1" },
  { key: "NM_001005164.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119678", geneDisplay: "OR52E2" },
  { key: "NM_001005165.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390081", geneDisplay: "OR52E4" },
  { key: "NM_001005166.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390082", geneDisplay: "OR52E5" },
  { key: "NM_001005167.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390078", geneDisplay: "OR52E6" },
  { key: "NM_001005168.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390079", geneDisplay: "OR52E8" },
  { key: "NM_001005169.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390037", geneDisplay: "OR52I1" },
  { key: "NM_001005170.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143502", geneDisplay: "OR52I2" },
  { key: "NM_001005171.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390036", geneDisplay: "OR52K1" },
  { key: "NM_001005172.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119774", geneDisplay: "OR52K2" },
  { key: "NM_001005173.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338751", geneDisplay: "OR52L1" },
  { key: "NM_001005174.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390077", geneDisplay: "OR52N2" },
  { key: "NM_001005175.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390072", geneDisplay: "OR52N4" },
  { key: "NM_001005177.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119695", geneDisplay: "OR52R1" },
  { key: "NM_001005178.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120787", geneDisplay: "OR52W1" },
  { key: "NM_001005179.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120793", geneDisplay: "OR56A4" },
  { key: "NM_001005180.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387748", geneDisplay: "OR56B1" },
  { key: "NM_001005181.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "196335", geneDisplay: "OR56B4" },
  { key: "NM_001005182.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "390321", geneDisplay: "OR6C1" },
  { key: "NM_001005183.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "390326", geneDisplay: "OR6C76" },
  { key: "NM_001005184.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128371", geneDisplay: "OR6K6" },
  { key: "NM_001005185.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128372", geneDisplay: "OR6N1" },
  { key: "NM_001005186.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219952", geneDisplay: "OR6Q1" },
  { key: "NM_001005187.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219874", geneDisplay: "OR6T1" },
  { key: "NM_001005188.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390260", geneDisplay: "OR6X1" },
  { key: "NM_001005189.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391112", geneDisplay: "OR6Y1" },
  { key: "NM_001005190.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390892", geneDisplay: "OR7A10" },
  { key: "NM_001005191.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125958", geneDisplay: "OR7D4" },
  { key: "NM_001005192.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125962", geneDisplay: "OR7G1" },
  { key: "NM_001005193.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390882", geneDisplay: "OR7G2" },
  { key: "NM_001005194.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390275", geneDisplay: "OR8A1" },
  { key: "NM_001005195.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219858", geneDisplay: "OR8B12" },
  { key: "NM_001005196.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283162", geneDisplay: "OR8B4" },
  { key: "NM_001005197.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338662", geneDisplay: "OR8D4" },
  { key: "NM_001005198.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219865", geneDisplay: "OR8G5" },
  { key: "NM_001005199.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219469", geneDisplay: "OR8H1" },
  { key: "NM_001005201.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390152", geneDisplay: "OR8H3" },
  { key: "NM_001005202.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219473", geneDisplay: "OR8K3" },
  { key: "NM_001005204.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219417", geneDisplay: "OR8U1" },
  { key: "NM_001005205.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219477", geneDisplay: "OR8J1" },
  { key: "NM_001005209.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130612", geneDisplay: "TMEM198" },
  { key: "NM_001005210.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219527", geneDisplay: "LRRC55" },
  { key: "NM_001005211.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219954", geneDisplay: "OR9I1" },
  { key: "NM_001005212.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219956", geneDisplay: "OR9Q1" },
  { key: "NM_001005213.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390174", geneDisplay: "OR9G1" },
  { key: "NM_001005214.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440699", geneDisplay: "LRRC52" },
  { key: "NM_001005216.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442186", geneDisplay: "OR2J3" },
  { key: "NM_001005218.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219968", geneDisplay: "OR5B21" },
  { key: "NM_001005221.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "729759", geneDisplay: "OR4F29" },
  { key: "NM_001005224.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26683", geneDisplay: "OR4F3" },
  { key: "NM_001005226.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442184", geneDisplay: "OR2B3" },
  { key: "NM_001005234.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26735", geneDisplay: "OR1L3" },
  { key: "NM_001005235.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "254973", geneDisplay: "OR1L4" },
  { key: "NM_001005236.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26737", geneDisplay: "OR1L1" },
  { key: "NM_001005237.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79324", geneDisplay: "OR51G1" },
  { key: "NM_001005238.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81282", geneDisplay: "OR51G2" },
  { key: "NM_001005239.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "81061", geneDisplay: "OR11H1" },
  { key: "NM_001005240.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81099", geneDisplay: "OR4F17" },
  { key: "NM_001005241.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283694", geneDisplay: "OR4N4" },
  { key: "NM_001005242.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5318", geneDisplay: "PKP2" },
  { key: "NM_001005243.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "441639", geneDisplay: "OR9K2" },
  { key: "NM_001005245.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219487", geneDisplay: "OR5M11" },
  { key: "NM_001005270.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283093", geneDisplay: "OR4C12" },
  { key: "NM_001005272.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81318", geneDisplay: "OR4A5" },
  { key: "NM_001005273.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1107", geneDisplay: "CHD3" },
  { key: "NM_001005274.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81327", geneDisplay: "OR4A16" },
  { key: "NM_001005275.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81328", geneDisplay: "OR4A15" },
  { key: "NM_001005276.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "81392", geneDisplay: "OR2AE1" },
  { key: "NM_001005277.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81399", geneDisplay: "OR4F16" },
  { key: "NM_001005278.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81442", geneDisplay: "OR6N2" },
  { key: "NM_001005279.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81448", geneDisplay: "OR6K2" },
  { key: "NM_001005280.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121364", geneDisplay: "OR10A7" },
  { key: "NM_001005281.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135946", geneDisplay: "OR6B1" },
  { key: "NM_001005282.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219484", geneDisplay: "OR5M8" },
  { key: "NM_001005283.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219957", geneDisplay: "OR9Q2" },
  { key: "NM_001005284.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283189", geneDisplay: "OR9G4" },
  { key: "NM_001005285.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341152", geneDisplay: "OR2AT4" },
  { key: "NM_001005286.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343169", geneDisplay: "OR6F1" },
  { key: "NM_001005287.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346528", geneDisplay: "OR2A1" },
  { key: "NM_001005288.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390063", geneDisplay: "OR51I1" },
  { key: "NM_001005289.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390067", geneDisplay: "OR52H1" },
  { key: "NM_001005323.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390181", geneDisplay: "OR5AK2" },
  { key: "NM_001005324.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390201", geneDisplay: "OR10V1" },
  { key: "NM_001005325.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390261", geneDisplay: "OR6M1" },
  { key: "NM_001005326.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390648", geneDisplay: "OR4F6" },
  { key: "NM_001005327.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391114", geneDisplay: "OR6K3" },
  { key: "NM_001005328.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "401427", geneDisplay: "OR2A7" },
  { key: "NM_001005329.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "401666", geneDisplay: "OR51A4" },
  { key: "NM_001005337.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5317", geneDisplay: "PKP1" },
  { key: "NM_001005338.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26341", geneDisplay: "OR5H1" },
  { key: "NM_001005339.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6001", geneDisplay: "RGS10" },
  { key: "NM_001005356.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "404785", geneDisplay: "POTEG" },
  { key: "NM_001005361.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1785", geneDisplay: "DNM2" },
  { key: "NM_001005365.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340441", geneDisplay: "POTEA" },
  { key: "NM_001005373.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "90678", geneDisplay: "LRSAM1" },
  { key: "NM_001005388.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23114", geneDisplay: "NFASC" },
  { key: "NM_001005404.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388403", geneDisplay: "YPEL2" },
  { key: "NM_001005405.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "440051", geneDisplay: "KRTAP5-11" },
  { key: "NM_001005415.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51257", geneDisplay: "MARCHF2" },
  { key: "NM_001005464.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "333932", geneDisplay: "H3C15" },
  { key: "NM_001005465.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26533", geneDisplay: "OR10G3" },
  { key: "NM_001005466.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26534", geneDisplay: "OR10G2" },
  { key: "NM_001005467.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390271", geneDisplay: "OR8B3" },
  { key: "NM_001005468.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26595", geneDisplay: "OR8B2" },
  { key: "NM_001005469.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "441608", geneDisplay: "OR5B3" },
  { key: "NM_001005470.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "119765", geneDisplay: "OR4B1" },
  { key: "NM_001005471.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "254879", geneDisplay: "OR2T6" },
  { key: "NM_001005473.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345557", geneDisplay: "PLCXD3" },
  { key: "NM_001005479.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79295", geneDisplay: "OR5H6" },
  { key: "NM_001005480.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "442361", geneDisplay: "OR2A2" },
  { key: "NM_001005482.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79310", geneDisplay: "OR5H2" },
  { key: "NM_001005483.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79317", geneDisplay: "OR4K5" },
  { key: "NM_001005484.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79501", geneDisplay: "OR4F5" },
  { key: "NM_001005486.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "81127", geneDisplay: "OR4K15" },
  { key: "NM_001005487.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "441933", geneDisplay: "OR13G1" },
  { key: "NM_001005489.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219965", geneDisplay: "OR5B17" },
  { key: "NM_001005490.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "254783", geneDisplay: "OR6C74" },
  { key: "NM_001005491.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "282770", geneDisplay: "OR10AG1" },
  { key: "NM_001005492.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "282775", geneDisplay: "OR5J2" },
  { key: "NM_001005493.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283365", geneDisplay: "OR6C6" },
  { key: "NM_001005494.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341418", geneDisplay: "OR6C4" },
  { key: "NM_001005495.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343173", geneDisplay: "OR2T3" },
  { key: "NM_001005496.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390144", geneDisplay: "OR5D16" },
  { key: "NM_001005497.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "390323", geneDisplay: "OR6C75" },
  { key: "NM_001005498.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79651", geneDisplay: "RHBDF2" },
  { key: "NM_001005499.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "390327", geneDisplay: "OR6C70" },
  { key: "NM_001005500.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "441670", geneDisplay: "OR4M1" },
  { key: "NM_001005501.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390431", geneDisplay: "OR4K2" },
  { key: "NM_001005504.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441308", geneDisplay: "OR4F21" },
  { key: "NM_001005512.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "403253", geneDisplay: "OR4A47" },
  { key: "NM_001005514.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "403273", geneDisplay: "OR5H14" },
  { key: "NM_001005515.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "403274", geneDisplay: "OR5H15" },
  { key: "NM_001005516.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "403277", geneDisplay: "OR5K3" },
  { key: "NM_001005517.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "403278", geneDisplay: "OR5K4" },
  { key: "NM_001005518.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "403282", geneDisplay: "OR6C65" },
  { key: "NM_001005519.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "403284", geneDisplay: "OR6C68" },
  { key: "NM_001005522.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343172", geneDisplay: "OR2T8" },
  { key: "NM_001005566.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390190", geneDisplay: "OR5B2" },
  { key: "NM_001005738.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2358", geneDisplay: "FPR2" },
  { key: "NM_001005743.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8650", geneDisplay: "NUMB" },
  { key: "NM_001005751.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387680", geneDisplay: "WASHC2A" },
  { key: "NM_001005850.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90485", geneDisplay: "ZNF835" },
  { key: "NM_001005851.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163131", geneDisplay: "ZNF780B" },
  { key: "NM_001005853.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389090", geneDisplay: "OR6B2" },
  { key: "NM_001005920.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "339123", geneDisplay: "JMJD8" },
  { key: "NM_001005922.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387264", geneDisplay: "KRTAP5-1" },
  { key: "NM_001006117.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "378951", geneDisplay: "RBMY1J" },
  { key: "NM_001006118.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "378950", geneDisplay: "RBMY1E" },
  { key: "NM_001006120.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "378949", geneDisplay: "RBMY1D" },
  { key: "NM_001006121.4", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "378948", geneDisplay: "RBMY1B" },
  { key: "NM_001006605.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388650", geneDisplay: "DIPK1A" },
  { key: "NM_001006607.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "474170", geneDisplay: "LRRC37A2" },
  { key: "NM_001006617.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "79109", geneDisplay: "MAPKAP1" },
  { key: "NM_001006630.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1129", geneDisplay: "CHRM2" },
  { key: "NM_001006634.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55114", geneDisplay: "ARHGAP17" },
  { key: "NM_001006638.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "326624", geneDisplay: "RAB37" },
  { key: "NM_001006658.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1380", geneDisplay: "CR2" },
  { key: "NM_001006681.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "474343", geneDisplay: "SPIN2B" },
  { key: "NM_001006935.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79921", geneDisplay: "TCEAL4" },
  { key: "NM_001006938.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158931", geneDisplay: "TCEAL6" },
  { key: "NM_001007025.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9527", geneDisplay: "GOSR1" },
  { key: "NM_001007033.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "93978", geneDisplay: "CLEC6A" },
  { key: "NM_001007088.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7569", geneDisplay: "ZNF182" },
  { key: "NM_001007089.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389075", geneDisplay: "RESP18" },
  { key: "NM_001007090.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157773", geneDisplay: "C8orf48" },
  { key: "NM_001007122.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123722", geneDisplay: "FSD2" },
  { key: "NM_001007157.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9678", geneDisplay: "PHF14" },
  { key: "NM_001007189.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "492311", geneDisplay: "IGIP" },
  { key: "NM_001007228.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8405", geneDisplay: "SPOP" },
  { key: "NM_001007231.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9938", geneDisplay: "ARHGAP25" },
  { key: "NM_001007237.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3321", geneDisplay: "IGSF3" },
  { key: "NM_001007248.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148103", geneDisplay: "ZNF599" },
  { key: "NM_001007253.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2086", geneDisplay: "ERV3-1" },
  { key: "NM_001007467.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9814", geneDisplay: "SFI1" },
  { key: "NM_001007523.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "474383", geneDisplay: "F8A2" },
  { key: "NM_001007524.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "474384", geneDisplay: "F8A3" },
  { key: "NM_001007525.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284434", geneDisplay: "NWD1" },
  { key: "NM_001007527.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "92255", geneDisplay: "LMBRD2" },
  { key: "NM_001007530.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146822", geneDisplay: "CDRT15" },
  { key: "NM_001007531.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222698", geneDisplay: "NKAPL" },
  { key: "NM_001007532.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "246744", geneDisplay: "STH" },
  { key: "NM_001007533.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "116729", geneDisplay: "PPP1R27" },
  { key: "NM_001007534.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285311", geneDisplay: "C3orf56" },
  { key: "NM_001007537.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "387911", geneDisplay: "C1QTNF9B" },
  { key: "NM_001007538.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "387914", geneDisplay: "SHISA2" },
  { key: "NM_001007540.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389118", geneDisplay: "CDHR4" },
  { key: "NM_001007544.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440712", geneDisplay: "RHEX" },
  { key: "NM_001007551.6", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441521", geneDisplay: "CT45A5" },
  { key: "NM_001007553.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7812", geneDisplay: "CSDE1" },
  { key: "NM_001007559.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6760", geneDisplay: "SS18" },
  { key: "NM_001007561.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126298", geneDisplay: "IRGQ" },
  { key: "NM_001007563.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347252", geneDisplay: "IGFBPL1" },
  { key: "NM_001007595.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "388125", geneDisplay: "C2CD4B" },
  { key: "NM_001008212.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10133", geneDisplay: "OPTN" },
  { key: "NM_001008215.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "493753", geneDisplay: "COA5" },
  { key: "NM_001008216.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2582", geneDisplay: "GALE" },
  { key: "NM_001008218.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "277", geneDisplay: "AMY1B" },
  { key: "NM_001008219.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "278", geneDisplay: "AMY1C" },
  { key: "NM_001008220.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10814", geneDisplay: "CPLX2" },
  { key: "NM_001008223.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "338761", geneDisplay: "C1QL4" },
  { key: "NM_001008237.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130502", geneDisplay: "TTC32" },
  { key: "NM_001008269.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "440955", geneDisplay: "TMEM89" },
  { key: "NM_001008270.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136242", geneDisplay: "PRSS37" },
  { key: "NM_001008272.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "29114", geneDisplay: "TAGLN3" },
  { key: "NM_001008274.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "493829", geneDisplay: "TRIM72" },
  { key: "NM_001008387.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130120", geneDisplay: "REG3G" },
  { key: "NM_001008388.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "493856", geneDisplay: "CISD2" },
  { key: "NM_001008390.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8545", geneDisplay: "CGGBP1" },
  { key: "NM_001008391.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "493860", geneDisplay: "CCDC73" },
  { key: "NM_001008392.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10217", geneDisplay: "CTDSPL" },
  { key: "NM_001008393.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201725", geneDisplay: "C4orf46" },
  { key: "NM_001008394.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "493861", geneDisplay: "EID3" },
  { key: "NM_001008395.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "389541", geneDisplay: "LAMTOR4" },
  { key: "NM_001008397.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "493869", geneDisplay: "GPX8" },
  { key: "NM_001008404.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "400258", geneDisplay: "C14orf180" },
  { key: "NM_001008409.5", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "164395", geneDisplay: "TTLL9" },
  { key: "NM_001008489.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "493911", geneDisplay: "PHOSPHO2" },
  { key: "NM_001008495.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169200", geneDisplay: "TMEM64" },
  { key: "NM_001008536.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126637", geneDisplay: "TCHHL1" },
  { key: "NM_001008537.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340533", geneDisplay: "NEXMIF" },
  { key: "NM_001008661.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56267", geneDisplay: "KYAT3" },
  { key: "NM_001008693.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128822", geneDisplay: "CST9" },
  { key: "NM_001008703.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221491", geneDisplay: "SMIM29" },
  { key: "NM_001008708.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "494143", geneDisplay: "CHAC2" },
  { key: "NM_001008710.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "11030", geneDisplay: "RBPMS" },
  { key: "NM_001008723.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "159686", geneDisplay: "CFAP58" },
  { key: "NM_001008727.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7675", geneDisplay: "ZNF121" },
  { key: "NM_001008739.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "441150", geneDisplay: "C6orf226" },
  { key: "NM_001008740.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127391", geneDisplay: "TMCO2" },
  { key: "NM_001008747.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441294", geneDisplay: "CTAGE15" },
  { key: "NM_001008749.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "401409", geneDisplay: "RAB19" },
  { key: "NM_001008777.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "494188", geneDisplay: "FBXO47" },
  { key: "NM_001008778.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387778", geneDisplay: "SPDYC" },
  { key: "NM_001008783.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340146", geneDisplay: "SLC35D3" },
  { key: "NM_001008801.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90333", geneDisplay: "ZNF468" },
  { key: "NM_001008895.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8451", geneDisplay: "CUL4A" },
  { key: "NM_001008938.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9793", geneDisplay: "CKAP5" },
  { key: "NM_001008949.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150771", geneDisplay: "ITPRIPL1" },
  { key: "NM_001009184.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2907", geneDisplay: "GRINA" },
  { key: "NM_001009185.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23305", geneDisplay: "ACSL6" },
  { key: "NM_001009552.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5516", geneDisplay: "PPP2CB" },
  { key: "NM_001009565.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344387", geneDisplay: "CDKL4" },
  { key: "NM_001009566.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22883", geneDisplay: "CLSTN1" },
  { key: "NM_001009606.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64711", geneDisplay: "HS3ST6" },
  { key: "NM_001009608.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128710", geneDisplay: "SLX4IP" },
  { key: "NM_001009609.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139067", geneDisplay: "SPANXN3" },
  { key: "NM_001009611.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400735", geneDisplay: "PRAMEF4" },
  { key: "NM_001009613.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441525", geneDisplay: "SPANXN4" },
  { key: "NM_001009614.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "494118", geneDisplay: "SPANXN1" },
  { key: "NM_001009615.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "494119", geneDisplay: "SPANXN2" },
  { key: "NM_001009616.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "494197", geneDisplay: "SPANXN5" },
  { key: "NM_001009880.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23313", geneDisplay: "KIAA0930" },
  { key: "NM_001009881.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23318", geneDisplay: "TUT4" },
  { key: "NM_001009894.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "91298", geneDisplay: "C12orf29" },
  { key: "NM_001009899.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "205717", geneDisplay: "USF3" },
  { key: "NM_001009905.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146712", geneDisplay: "B3GNTL1" },
  { key: "NM_001009909.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338645", geneDisplay: "LUZP2" },
  { key: "NM_001009921.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23355", geneDisplay: "VPS8" },
  { key: "NM_001009923.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "29058", geneDisplay: "TMEM230" },
  { key: "NM_001009931.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388697", geneDisplay: "HRNR" },
  { key: "NM_001009944.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "5310", geneDisplay: "PKD1" },
  { key: "NM_001009955.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23648", geneDisplay: "SSBP3" },
  { key: "NM_001009984.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "25943", geneDisplay: "DNAAF9" },
  { key: "NM_001009992.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127665", geneDisplay: "ZNF648" },
  { key: "NM_001009993.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130074", geneDisplay: "FAM168B" },
  { key: "NM_001009994.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134701", geneDisplay: "RIPPLY2" },
  { key: "NM_001009996.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55152", geneDisplay: "DALRD3" },
  { key: "NM_001009997.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "414157", geneDisplay: "C10orf62" },
  { key: "NM_001009999.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23028", geneDisplay: "KDM1A" },
  { key: "NM_001010844.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134728", geneDisplay: "IRAK1BP1" },
  { key: "NM_001010846.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126669", geneDisplay: "SHE" },
  { key: "NM_001010847.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126755", geneDisplay: "LRRC38" },
  { key: "NM_001010848.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10718", geneDisplay: "NRG3" },
  { key: "NM_001010851.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90321", geneDisplay: "ZNF766" },
  { key: "NM_001010852.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134829", geneDisplay: "CLVS2" },
  { key: "NM_001010853.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135293", geneDisplay: "PM20D2" },
  { key: "NM_001010854.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145567", geneDisplay: "TTC7B" },
  { key: "NM_001010855.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146850", geneDisplay: "PIK3R6" },
  { key: "NM_001010857.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149018", geneDisplay: "LELP1" },
  { key: "NM_001010858.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149603", geneDisplay: "RNF187" },
  { key: "NM_001010859.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150297", geneDisplay: "C22orf42" },
  { key: "NM_001010860.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161394", geneDisplay: "SAMD15" },
  { key: "NM_001010862.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "169981", geneDisplay: "SPIN3" },
  { key: "NM_001010867.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200205", geneDisplay: "IBA57" },
  { key: "NM_001010868.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "206412", geneDisplay: "C6orf163" },
  { key: "NM_001010870.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221400", geneDisplay: "TDRD6" },
  { key: "NM_001010872.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222584", geneDisplay: "FAM83B" },
  { key: "NM_001010873.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222642", geneDisplay: "TSPO2" },
  { key: "NM_001010874.5", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "253017", geneDisplay: "TECRL" },
  { key: "NM_001010875.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "253512", geneDisplay: "SLC25A30" },
  { key: "NM_001010876.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "253582", geneDisplay: "TMEM244" },
  { key: "NM_001010879.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284307", geneDisplay: "ZIK1" },
  { key: "NM_001010881.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284498", geneDisplay: "C1orf167" },
  { key: "NM_001010883.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284611", geneDisplay: "FAM102B" },
  { key: "NM_001010886.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340204", geneDisplay: "CLPSL1" },
  { key: "NM_001010887.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "340485", geneDisplay: "ACER2" },
  { key: "NM_001010888.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340554", geneDisplay: "ZC3H12B" },
  { key: "NM_001010889.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440561", geneDisplay: "PRAMEF6" },
  { key: "NM_001010890.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343070", geneDisplay: "PRAMEF9" },
  { key: "NM_001010892.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "345895", geneDisplay: "RSPH4A" },
  { key: "NM_001010893.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "347051", geneDisplay: "SLC10A5" },
  { key: "NM_001010897.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "387923", geneDisplay: "SERP2" },
  { key: "NM_001010898.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388662", geneDisplay: "SLC6A17" },
  { key: "NM_001010903.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389384", geneDisplay: "BNIP5" },
  { key: "NM_001010904.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389396", geneDisplay: "GLYATL3" },
  { key: "NM_001010905.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "352999", geneDisplay: "C6orf58" },
  { key: "NM_001010906.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389643", geneDisplay: "NUGGC" },
  { key: "NM_001010908.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "389941", geneDisplay: "C1QL3" },
  { key: "NM_001010909.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "394263", geneDisplay: "MUC21" },
  { key: "NM_001010912.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "399814", geneDisplay: "C10orf120" },
  { key: "NM_001010915.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401494", geneDisplay: "HACD4" },
  { key: "NM_001010917.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "401647", geneDisplay: "GOLGA7B" },
  { key: "NM_001010919.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "441168", geneDisplay: "CALHM6" },
  { key: "NM_001010922.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440603", geneDisplay: "BCL2L15" },
  { key: "NM_001010923.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387357", geneDisplay: "THEMIS" },
  { key: "NM_001010924.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221061", geneDisplay: "FAM171A1" },
  { key: "NM_001010926.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388585", geneDisplay: "HES5" },
  { key: "NM_001010939.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "142910", geneDisplay: "LIPJ" },
  { key: "NM_001010940.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138255", geneDisplay: "CFAP95" },
  { key: "NM_001010942.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5908", geneDisplay: "RAP1B" },
  { key: "NM_001010969.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284541", geneDisplay: "CYP4A22" },
  { key: "NM_001010977.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "196541", geneDisplay: "METTL21C" },
  { key: "NM_001010978.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388633", geneDisplay: "LDLRAD1" },
  { key: "NM_001010979.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388701", geneDisplay: "CFAP141" },
  { key: "NM_001010980.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400746", geneDisplay: "NCMAP" },
  { key: "NM_001010982.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125061", geneDisplay: "AFMID" },
  { key: "NM_001010985.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343263", geneDisplay: "MYBPHL" },
  { key: "NM_001010987.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "439996", geneDisplay: "IFIT1B" },
  { key: "NM_001011547.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200010", geneDisplay: "SLC5A9" },
  { key: "NM_001011548.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4103", geneDisplay: "MAGEA4" },
  { key: "NM_001011551.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29071", geneDisplay: "C1GALT1C1" },
  { key: "NM_001011552.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389015", geneDisplay: "SLC9A4" },
  { key: "NM_001011655.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "93109", geneDisplay: "TMEM44" },
  { key: "NM_001011658.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6399", geneDisplay: "TRAPPC2" },
  { key: "NM_001011663.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84108", geneDisplay: "PCGF6" },
  { key: "NM_001011671.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79145", geneDisplay: "CHCHD7" },
  { key: "NM_001011700.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401250", geneDisplay: "MCCD1" },
  { key: "NM_001011709.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119548", geneDisplay: "PNLIPRP3" },
  { key: "NM_001011713.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122830", geneDisplay: "NAA30" },
  { key: "NM_001011717.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129521", geneDisplay: "NMS" },
  { key: "NM_001011718.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "343702", geneDisplay: "XKR7" },
  { key: "NM_001011719.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347527", geneDisplay: "ARSH" },
  { key: "NM_001011720.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389668", geneDisplay: "XKR9" },
  { key: "NM_001011878.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245934", geneDisplay: "DEFB121" },
  { key: "NM_001012264.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "440163", geneDisplay: "RNASE13" },
  { key: "NM_001012267.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401541", geneDisplay: "CENPP" },
  { key: "NM_001012276.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391002", geneDisplay: "PRAMEF8" },
  { key: "NM_001012277.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "441871", geneDisplay: "PRAMEF7" },
  { key: "NM_001012279.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387104", geneDisplay: "SOGA3" },
  { key: "NM_001012301.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340075", geneDisplay: "ARSI" },
  { key: "NM_001012302.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338440", geneDisplay: "ANO9" },
  { key: "NM_001012338.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4916", geneDisplay: "NTRK3" },
  { key: "NM_001012339.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134218", geneDisplay: "DNAJC21" },
  { key: "NM_001012361.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "114987", geneDisplay: "WDR31" },
  { key: "NM_001012393.5", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4978", geneDisplay: "OPCML" },
  { key: "NM_001012414.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "391712", geneDisplay: "TRIM61" },
  { key: "NM_001012416.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "440023", geneDisplay: "KRTAP5-6" },
  { key: "NM_001012417.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "503497", geneDisplay: "MS4A13" },
  { key: "NM_001012418.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340156", geneDisplay: "MYLK4" },
  { key: "NM_001012425.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388649", geneDisplay: "C1orf146" },
  { key: "NM_001012426.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "116113", geneDisplay: "FOXP4" },
  { key: "NM_001012446.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392307", geneDisplay: "FAM221B" },
  { key: "NM_001012455.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222696", geneDisplay: "ZSCAN23" },
  { key: "NM_001012502.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286207", geneDisplay: "CFAP157" },
  { key: "NM_001012503.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "440050", geneDisplay: "KRTAP5-7" },
  { key: "NM_001012507.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387103", geneDisplay: "CENPW" },
  { key: "NM_001012614.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1487", geneDisplay: "CTBP1" },
  { key: "NM_001012642.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "196996", geneDisplay: "GRAMD2A" },
  { key: "NM_001012643.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339344", geneDisplay: "MYPOP" },
  { key: "NM_001012659.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "503582", geneDisplay: "ARGFX" },
  { key: "NM_001012708.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387266", geneDisplay: "KRTAP5-3" },
  { key: "NM_001012710.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387273", geneDisplay: "KRTAP5-10" },
  { key: "NM_001012720.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5995", geneDisplay: "RGR" },
  { key: "NM_001012728.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "503834", geneDisplay: "DPRX" },
  { key: "NM_001012729.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "503835", geneDisplay: "DUXA" },
  { key: "NM_001012750.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10006", geneDisplay: "ABI1" },
  { key: "NM_001012754.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "387921", geneDisplay: "NHLRC3" },
  { key: "NM_001012755.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "401612", geneDisplay: "SLC25A53" },
  { key: "NM_001012758.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200035", geneDisplay: "NUDT17" },
  { key: "NM_001012759.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "348180", geneDisplay: "CTU2" },
  { key: "NM_001012967.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "91351", geneDisplay: "DDX60L" },
  { key: "NM_001012968.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139886", geneDisplay: "SPIN4" },
  { key: "NM_001012970.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200159", geneDisplay: "C1orf100" },
  { key: "NM_001012971.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "200232", geneDisplay: "FAM209A" },
  { key: "NM_001012973.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219348", geneDisplay: "PLAC9" },
  { key: "NM_001012974.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221424", geneDisplay: "LRRC73" },
  { key: "NM_001012977.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340529", geneDisplay: "PABPC1L2A" },
  { key: "NM_001012978.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340542", geneDisplay: "BEX5" },
  { key: "NM_001012979.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340543", geneDisplay: "TCEAL5" },
  { key: "NM_001012981.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "342357", geneDisplay: "ZKSCAN2" },
  { key: "NM_001012984.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "388284", geneDisplay: "C16orf86" },
  { key: "NM_001012991.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "400506", geneDisplay: "KNOP1" },
  { key: "NM_001012993.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401546", geneDisplay: "C9orf152" },
  { key: "NM_001012994.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401548", geneDisplay: "SNX30" },
  { key: "NM_001013251.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6520", geneDisplay: "SLC3A2" },
  { key: "NM_001013354.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "440153", geneDisplay: "OR11H12" },
  { key: "NM_001013355.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391211", geneDisplay: "OR2G6" },
  { key: "NM_001013403.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347487", geneDisplay: "CXorf66" },
  { key: "NM_001013407.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343068", geneDisplay: "PRAMEF5" },
  { key: "NM_001013435.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90737", geneDisplay: "PAGE5" },
  { key: "NM_001013437.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "81929", geneDisplay: "SEH1L" },
  { key: "NM_001013579.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158833", geneDisplay: "AWAT1" },
  { key: "NM_001013615.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "541468", geneDisplay: "LURAP1" },
  { key: "NM_001013619.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123688", geneDisplay: "HYKK" },
  { key: "NM_001013620.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144245", geneDisplay: "ALG10B" },
  { key: "NM_001013623.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "153918", geneDisplay: "ZC2HC1B" },
  { key: "NM_001013625.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257177", geneDisplay: "CFAP126" },
  { key: "NM_001013627.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340527", geneDisplay: "NHSL2" },
  { key: "NM_001013628.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340578", geneDisplay: "DCAF12L2" },
  { key: "NM_001013630.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343066", geneDisplay: "AADACL4" },
  { key: "NM_001013631.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343069", geneDisplay: "HNRNPCL1" },
  { key: "NM_001013635.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387856", geneDisplay: "CCDC184" },
  { key: "NM_001013641.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388595", geneDisplay: "TMEM82" },
  { key: "NM_001013642.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388610", geneDisplay: "TRNP1" },
  { key: "NM_001013646.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "388799", geneDisplay: "FAM209B" },
  { key: "NM_001013647.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "646851", geneDisplay: "FAM227A" },
  { key: "NM_001013649.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388969", geneDisplay: "C2orf68" },
  { key: "NM_001013650.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389151", geneDisplay: "PRR23B" },
  { key: "NM_001013653.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389816", geneDisplay: "LRRC26" },
  { key: "NM_001013657.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390637", geneDisplay: "GDPGP1" },
  { key: "NM_001013659.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390927", geneDisplay: "ZNF793" },
  { key: "NM_001013661.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391123", geneDisplay: "VSIG8" },
  { key: "NM_001013663.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "391356", geneDisplay: "PTRHD1" },
  { key: "NM_001013672.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "400566", geneDisplay: "C17orf97" },
  { key: "NM_001013693.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "401944", geneDisplay: "LDLRAD2" },
  { key: "NM_001013694.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "402055", geneDisplay: "SRRD" },
  { key: "NM_001013698.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440087", geneDisplay: "SMCO3" },
  { key: "NM_001013699.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440093", geneDisplay: "H3-5" },
  { key: "NM_001013703.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440275", geneDisplay: "EIF2AK4" },
  { key: "NM_001013706.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "440503", geneDisplay: "PLIN5" },
  { key: "NM_001013734.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442247", geneDisplay: "RFPL4B" },
  { key: "NM_001013735.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "442425", geneDisplay: "FOXB2" },
  { key: "NM_001013736.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "442444", geneDisplay: "FAM47C" },
  { key: "NM_001013742.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139189", geneDisplay: "DGKK" },
  { key: "NM_001013743.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338661", geneDisplay: "TMEM225" },
  { key: "NM_001013836.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8379", geneDisplay: "MAD1L1" },
  { key: "NM_001013838.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146206", geneDisplay: "CARMIL2" },
  { key: "NM_001013839.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23265", geneDisplay: "EXOC7" },
  { key: "NM_001013841.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55620", geneDisplay: "STAP2" },
  { key: "NM_001013842.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "541565", geneDisplay: "C8orf58" },
  { key: "NM_001013845.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "541578", geneDisplay: "EOLA2" },
  { key: "NM_001014279.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389289", geneDisplay: "ANXA2R" },
  { key: "NM_001014283.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55208", geneDisplay: "DCUN1D2" },
  { key: "NM_001014286.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55578", geneDisplay: "SUPT20H" },
  { key: "NM_001014291.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6706", geneDisplay: "SPRR2G" },
  { key: "NM_001014336.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "386653", geneDisplay: "IL31" },
  { key: "NM_001014342.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388698", geneDisplay: "FLG2" },
  { key: "NM_001014436.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "28988", geneDisplay: "DBNL" },
  { key: "NM_001014437.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "833", geneDisplay: "CARS1" },
  { key: "NM_001014440.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "440836", geneDisplay: "ODF3B" },
  { key: "NM_001014443.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27005", geneDisplay: "USP21" },
  { key: "NM_001014446.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132299", geneDisplay: "OCIAD2" },
  { key: "NM_001014447.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8532", geneDisplay: "CPZ" },
  { key: "NM_001014450.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6705", geneDisplay: "SPRR2F" },
  { key: "NM_001014809.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1400", geneDisplay: "CRMP1" },
  { key: "NM_001014840.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51596", geneDisplay: "CUTA" },
  { key: "NM_001014979.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "90835", geneDisplay: "CFAP119" },
  { key: "NM_001014980.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388581", geneDisplay: "C1QTNF12" },
  { key: "NM_001014985.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388323", geneDisplay: "GLTPD2" },
  { key: "NM_001014987.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27040", geneDisplay: "LAT" },
  { key: "NM_001014999.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "548593", geneDisplay: "SLX1A" },
  { key: "NM_001015038.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389860", geneDisplay: "PAGE2B" },
  { key: "NM_001015048.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9529", geneDisplay: "BAG5" },
  { key: "NM_001015052.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4350", geneDisplay: "MPG" },
  { key: "NM_001015055.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6242", geneDisplay: "RTKN" },
  { key: "NM_001015072.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "402682", geneDisplay: "UFSP1" },
  { key: "NM_001015877.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84295", geneDisplay: "PHF6" },
  { key: "NM_001015878.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6795", geneDisplay: "AURKC" },
  { key: "NM_001015880.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9060", geneDisplay: "PAPSS2" },
  { key: "NM_001015882.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "548645", geneDisplay: "DNAJC25" },
  { key: "NM_001015887.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152404", geneDisplay: "IGSF11" },
  { key: "NM_001017361.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154288", geneDisplay: "KHDC3L" },
  { key: "NM_001017363.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138715", geneDisplay: "ARID3C" },
  { key: "NM_001017365.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "725", geneDisplay: "C4BPB" },
  { key: "NM_001017368.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "117584", geneDisplay: "RFFL" },
  { key: "NM_001017372.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "28965", geneDisplay: "SLC27A6" },
  { key: "NM_001017373.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154075", geneDisplay: "SAMD3" },
  { key: "NM_001017390.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "445329", geneDisplay: "SULT1A4" },
  { key: "NM_001017392.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10147", geneDisplay: "SUGP2" },
  { key: "NM_001017395.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23023", geneDisplay: "TMCC1" },
  { key: "NM_001017403.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "59352", geneDisplay: "LGR6" },
  { key: "NM_001017405.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10296", geneDisplay: "MAEA" },
  { key: "NM_001017417.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "541466", geneDisplay: "CT45A1" },
  { key: "NM_001017420.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157570", geneDisplay: "ESCO2" },
  { key: "NM_001017425.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3776", geneDisplay: "KCNK2" },
  { key: "NM_001017437.5", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "550631", geneDisplay: "CCDC157" },
  { key: "NM_001017438.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "541465", geneDisplay: "CT45A6" },
  { key: "NM_001017915.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3635", geneDisplay: "INPP5D" },
  { key: "NM_001017919.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "91433", geneDisplay: "RCCD1" },
  { key: "NM_001017920.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92196", geneDisplay: "DAPL1" },
  { key: "NM_001017921.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "400673", geneDisplay: "VMAC" },
  { key: "NM_001017922.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114625", geneDisplay: "ERMAP" },
  { key: "NM_001017923.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122525", geneDisplay: "C14orf28" },
  { key: "NM_001017924.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340745", geneDisplay: "LRIT2" },
  { key: "NM_001017928.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131076", geneDisplay: "MIX23" },
  { key: "NM_001017930.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139425", geneDisplay: "DCAF8L1" },
  { key: "NM_001017931.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139793", geneDisplay: "PAGE3" },
  { key: "NM_001017961.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149297", geneDisplay: "FAM78B" },
  { key: "NM_001017962.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5033", geneDisplay: "P4HA1" },
  { key: "NM_001017964.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150223", geneDisplay: "YDJC" },
  { key: "NM_001017969.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158358", geneDisplay: "KIAA2026" },
  { key: "NM_001017970.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161291", geneDisplay: "TMEM30B" },
  { key: "NM_001017974.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "8974", geneDisplay: "P4HA2" },
  { key: "NM_001017975.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "164045", geneDisplay: "HFM1" },
  { key: "NM_001017978.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203413", geneDisplay: "CT83" },
  { key: "NM_001017979.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9364", geneDisplay: "RAB28" },
  { key: "NM_001017980.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203547", geneDisplay: "VMA21" },
  { key: "NM_001017981.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "200312", geneDisplay: "RNF215" },
  { key: "NM_001017990.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "474382", geneDisplay: "H2AB1" },
  { key: "NM_001017991.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "474381", geneDisplay: "H2AB2" },
  { key: "NM_001017992.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345651", geneDisplay: "ACTBL2" },
  { key: "NM_001017995.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285590", geneDisplay: "SH3PXD2B" },
  { key: "NM_001017998.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2790", geneDisplay: "GNG10" },
  { key: "NM_001018005.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7168", geneDisplay: "TPM1" },
  { key: "NM_001018024.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100272147", geneDisplay: "CMC4" },
  { key: "NM_001018025.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4515", geneDisplay: "MTCP1" },
  { key: "NM_001018050.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "171568", geneDisplay: "POLR3H" },
  { key: "NM_001018055.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79184", geneDisplay: "BRCC3" },
  { key: "NM_001018057.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27122", geneDisplay: "DKK3" },
  { key: "NM_001018069.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26135", geneDisplay: "SERBP1" },
  { key: "NM_001018071.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143162", geneDisplay: "FRMPD2" },
  { key: "NM_001018072.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121551", geneDisplay: "BTBD11" },
  { key: "NM_001018100.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100820829", geneDisplay: "MYZAP" },
  { key: "NM_001018108.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10169", geneDisplay: "SERF2" },
  { key: "NM_001018109.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8544", geneDisplay: "PIR" },
  { key: "NM_001018111.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5420", geneDisplay: "PODXL" },
  { key: "NM_001018113.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2187", geneDisplay: "FANCB" },
  { key: "NM_001018115.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2177", geneDisplay: "FANCD2" },
  { key: "NM_001018116.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347273", geneDisplay: "CAVIN4" },
  { key: "NM_001020658.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9698", geneDisplay: "PUM1" },
  { key: "NM_001023560.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7741", geneDisplay: "ZSCAN26" },
  { key: "NM_001023561.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388567", geneDisplay: "ZNF749" },
  { key: "NM_001023563.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390980", geneDisplay: "ZNF805" },
  { key: "NM_001023567.5", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440270", geneDisplay: "GOLGA8B" },
  { key: "NM_001023570.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9657", geneDisplay: "IQCB1" },
  { key: "NM_001024209.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6704", geneDisplay: "SPRR2E" },
  { key: "NM_001024211.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6284", geneDisplay: "S100A13" },
  { key: "NM_001024383.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "89795", geneDisplay: "NAV3" },
  { key: "NM_001024401.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "388228", geneDisplay: "SBK1" },
  { key: "NM_001024455.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340526", geneDisplay: "RTL5" },
  { key: "NM_001024593.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118490", geneDisplay: "MSS51" },
  { key: "NM_001024594.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388722", geneDisplay: "C1orf53" },
  { key: "NM_001024598.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "390992", geneDisplay: "HES3" },
  { key: "NM_001024599.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440689", geneDisplay: "H2BC18" },
  { key: "NM_001024601.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149643", geneDisplay: "SPATA45" },
  { key: "NM_001024611.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "339977", geneDisplay: "LRRC66" },
  { key: "NM_001024613.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "389549", geneDisplay: "FEZF1" },
  { key: "NM_001024630.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "860", geneDisplay: "RUNX2" },
  { key: "NM_001024644.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2829", geneDisplay: "XCR1" },
  { key: "NM_001024661.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "729528", geneDisplay: "PRAMEF14" },
  { key: "NM_001024674.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91750", geneDisplay: "LIN52" },
  { key: "NM_001024675.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "170487", geneDisplay: "ACTL10" },
  { key: "NM_001024678.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441381", geneDisplay: "LRRC24" },
  { key: "NM_001024679.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100129271", geneDisplay: "C1orf68" },
  { key: "NM_001024680.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "554251", geneDisplay: "FBXO48" },
  { key: "NM_001024736.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80381", geneDisplay: "CD276" },
  { key: "NM_001024807.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "333", geneDisplay: "APLP1" },
  { key: "NM_001024808.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "605", geneDisplay: "BCL7A" },
  { key: "NM_001024822.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "493901", geneDisplay: "RNASE12" },
  { key: "NM_001024845.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6536", geneDisplay: "SLC6A9" },
  { key: "NM_001024924.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55763", geneDisplay: "EXOC1" },
  { key: "NM_001024939.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "66035", geneDisplay: "SLC2A11" },
  { key: "NM_001025091.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23", geneDisplay: "ABCF1" },
  { key: "NM_001025096.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "143471", geneDisplay: "PSMA8" },
  { key: "NM_001025101.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4155", geneDisplay: "MBP" },
  { key: "NM_001025109.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "947", geneDisplay: "CD34" },
  { key: "NM_001025159.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "972", geneDisplay: "CD74" },
  { key: "NM_001025195.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1066", geneDisplay: "CES1" },
  { key: "NM_001025200.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440387", geneDisplay: "CTRB2" },
  { key: "NM_001025231.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "448834", geneDisplay: "KPRP" },
  { key: "NM_001025248.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1854", geneDisplay: "DUT" },
  { key: "NM_001025253.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "7163", geneDisplay: "TPD52" },
  { key: "NM_001025265.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158830", geneDisplay: "CXorf65" },
  { key: "NM_001025266.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285382", geneDisplay: "C3orf70" },
  { key: "NM_001025290.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "340168", geneDisplay: "DPPA5" },
  { key: "NM_001025295.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387733", geneDisplay: "IFITM5" },
  { key: "NM_001025300.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "201475", geneDisplay: "RAB12" },
  { key: "NM_001025356.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196527", geneDisplay: "ANO6" },
  { key: "NM_001025357.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441376", geneDisplay: "AARD" },
  { key: "NM_001025389.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "272", geneDisplay: "AMPD3" },
  { key: "NM_001025591.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284402", geneDisplay: "SCGB2B2" },
  { key: "NM_001025595.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27236", geneDisplay: "ARFIP1" },
  { key: "NM_001025598.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257106", geneDisplay: "ARHGAP30" },
  { key: "NM_001025603.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5993", geneDisplay: "RFX5" },
  { key: "NM_001025616.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83478", geneDisplay: "ARHGAP24" },
  { key: "NM_001025780.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51104", geneDisplay: "ABHD17B" },
  { key: "NM_001029858.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "222553", geneDisplay: "SLC35F1" },
  { key: "NM_001029859.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283219", geneDisplay: "KCTD21" },
  { key: "NM_001029860.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286151", geneDisplay: "FBXO43" },
  { key: "NM_001029861.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339345", geneDisplay: "NANOS2" },
  { key: "NM_001029863.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387263", geneDisplay: "C6orf120" },
  { key: "NM_001029864.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "85449", geneDisplay: "KIAA1755" },
  { key: "NM_001029865.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120237", geneDisplay: "DBX1" },
  { key: "NM_001029869.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153770", geneDisplay: "PLAC8L1" },
  { key: "NM_001029870.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345079", geneDisplay: "SOWAHB" },
  { key: "NM_001029871.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "343637", geneDisplay: "RSPO4" },
  { key: "NM_001029874.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387849", geneDisplay: "REP15" },
  { key: "NM_001029875.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "401190", geneDisplay: "RGS7BP" },
  { key: "NM_001029881.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130106", geneDisplay: "CIB4" },
  { key: "NM_001029883.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388939", geneDisplay: "PCARE" },
  { key: "NM_001029884.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57480", geneDisplay: "PLEKHG1" },
  { key: "NM_001029885.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80772", geneDisplay: "CPTP" },
  { key: "NM_001029886.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345456", geneDisplay: "PFN3" },
  { key: "NM_001029888.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118670", geneDisplay: "FAM24A" },
  { key: "NM_001029891.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441531", geneDisplay: "PGAM4" },
  { key: "NM_001029896.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "11152", geneDisplay: "WDR45" },
  { key: "NM_001029954.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "441549", geneDisplay: "CDNF" },
  { key: "NM_001029955.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285429", geneDisplay: "DCAF4L1" },
  { key: "NM_001029997.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339318", geneDisplay: "ZNF181" },
  { key: "NM_001029998.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84068", geneDisplay: "SLC10A7" },
  { key: "NM_001030005.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "594855", geneDisplay: "CPLX3" },
  { key: "NM_001030006.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "163", geneDisplay: "AP2B1" },
  { key: "NM_001030013.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "594857", geneDisplay: "NPS" },
  { key: "NM_001030019.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "256979", geneDisplay: "SUN3" },
  { key: "NM_001030055.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "394", geneDisplay: "ARHGAP5" },
  { key: "NM_001030059.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "196051", geneDisplay: "PLPP4" },
  { key: "NM_001030060.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389432", geneDisplay: "SAMD5" },
  { key: "NM_001031617.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "90639", geneDisplay: "COX19" },
  { key: "NM_001031623.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26036", geneDisplay: "ZNF451" },
  { key: "NM_001031628.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57228", geneDisplay: "SMAGP" },
  { key: "NM_001031672.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "606495", geneDisplay: "CYB5RL" },
  { key: "NM_001031677.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "53917", geneDisplay: "RAB24" },
  { key: "NM_001031679.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "253827", geneDisplay: "MSRB3" },
  { key: "NM_001031684.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6432", geneDisplay: "SRSF7" },
  { key: "NM_001031685.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7159", geneDisplay: "TP53BP2" },
  { key: "NM_001031689.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9373", geneDisplay: "PLAA" },
  { key: "NM_001031690.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9715", geneDisplay: "FAM131B" },
  { key: "NM_001031692.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10234", geneDisplay: "LRRC17" },
  { key: "NM_001031698.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25766", geneDisplay: "PRPF40B" },
  { key: "NM_001031701.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51559", geneDisplay: "NT5DC3" },
  { key: "NM_001031702.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54437", geneDisplay: "SEMA5B" },
  { key: "NM_001031703.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54859", geneDisplay: "ELP6" },
  { key: "NM_001031705.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54967", geneDisplay: "CT55" },
  { key: "NM_001031709.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55328", geneDisplay: "RNLS" },
  { key: "NM_001031710.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55975", geneDisplay: "KLHL7" },
  { key: "NM_001031711.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57222", geneDisplay: "ERGIC1" },
  { key: "NM_001031712.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "60487", geneDisplay: "TRMT11" },
  { key: "NM_001031713.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63933", geneDisplay: "MCUR1" },
  { key: "NM_001031715.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64799", geneDisplay: "IQCH" },
  { key: "NM_001031716.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64859", geneDisplay: "NABP1" },
  { key: "NM_001031721.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79898", geneDisplay: "ZNF613" },
  { key: "NM_001031722.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "79969", geneDisplay: "ATAT1" },
  { key: "NM_001031723.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79982", geneDisplay: "DNAJB14" },
  { key: "NM_001031725.6", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83479", geneDisplay: "DDX59" },
  { key: "NM_001031727.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84245", geneDisplay: "MRI1" },
  { key: "NM_001031732.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "91746", geneDisplay: "YTHDC1" },
  { key: "NM_001031738.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129303", geneDisplay: "TMEM150A" },
  { key: "NM_001031739.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "140462", geneDisplay: "ASB9" },
  { key: "NM_001031743.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154313", geneDisplay: "CFAP206" },
  { key: "NM_001031745.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158787", geneDisplay: "RIBC1" },
  { key: "NM_001031746.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "196740", geneDisplay: "VSTM4" },
  { key: "NM_001031748.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283461", geneDisplay: "C12orf40" },
  { key: "NM_001031749.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284348", geneDisplay: "LYPD5" },
  { key: "NM_001031803.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3993", geneDisplay: "LLGL2" },
  { key: "NM_001031827.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "552900", geneDisplay: "BOLA2" },
  { key: "NM_001031834.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "282808", geneDisplay: "RAB40AL" },
  { key: "NM_001031836.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157855", geneDisplay: "KCNU1" },
  { key: "NM_001031850.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5675", geneDisplay: "PSG6" },
  { key: "NM_001031854.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390110", geneDisplay: "ACCSL" },
  { key: "NM_001031855.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79836", geneDisplay: "LONRF3" },
  { key: "NM_001032221.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6812", geneDisplay: "STXBP1" },
  { key: "NM_001032283.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7112", geneDisplay: "TMPO" },
  { key: "NM_001032288.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7335", geneDisplay: "UBE2V1" },
  { key: "NM_001032291.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84722", geneDisplay: "PSRC1" },
  { key: "NM_001032296.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8428", geneDisplay: "STK24" },
  { key: "NM_001032363.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440574", geneDisplay: "MICOS10" },
  { key: "NM_001032373.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7769", geneDisplay: "ZNF226" },
  { key: "NM_001032382.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10084", geneDisplay: "PQBP1" },
  { key: "NM_001032386.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6821", geneDisplay: "SUOX" },
  { key: "NM_001032392.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5343", geneDisplay: "PLGLB1" },
  { key: "NM_001032396.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64219", geneDisplay: "PJA1" },
  { key: "NM_001032999.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9139", geneDisplay: "CBFA2T2" },
  { key: "NM_001033002.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84268", geneDisplay: "RPAIN" },
  { key: "NM_001033017.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "613209", geneDisplay: "DEFB135" },
  { key: "NM_001033018.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "613210", geneDisplay: "DEFB136" },
  { key: "NM_001033025.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2135", geneDisplay: "EXTL2" },
  { key: "NM_001033026.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91304", geneDisplay: "TMEM259" },
  { key: "NM_001033031.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55179", geneDisplay: "FAIM" },
  { key: "NM_001033044.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2752", geneDisplay: "GLUL" },
  { key: "NM_001033046.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79415", geneDisplay: "CYBC1" },
  { key: "NM_001033047.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "255743", geneDisplay: "NPNT" },
  { key: "NM_001033050.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80298", geneDisplay: "MTERF2" },
  { key: "NM_001033057.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9223", geneDisplay: "MAGI1" },
  { key: "NM_001033080.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9287", geneDisplay: "TAAR2" },
  { key: "NM_001033081.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4610", geneDisplay: "MYCL" },
  { key: "NM_001033088.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "51335", geneDisplay: "NGRN" },
  { key: "NM_001033113.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "377841", geneDisplay: "ENTPD8" },
  { key: "NM_001033561.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57649", geneDisplay: "PHF12" },
  { key: "NM_001033564.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "619208", geneDisplay: "FAM229B" },
  { key: "NM_001033566.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55288", geneDisplay: "RHOT1" },
  { key: "NM_001033578.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23678", geneDisplay: "SGK3" },
  { key: "NM_001033602.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23281", geneDisplay: "MTUS2" },
  { key: "NM_001033677.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9478", geneDisplay: "CABP1" },
  { key: "NM_001033678.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83707", geneDisplay: "TRPT1" },
  { key: "NM_001033719.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342908", geneDisplay: "ZNF404" },
  { key: "NM_001033723.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "619279", geneDisplay: "ZNF704" },
  { key: "NM_001033855.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64421", geneDisplay: "DCLRE1C" },
  { key: "NM_001033910.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7188", geneDisplay: "TRAF5" },
  { key: "NM_001033930.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7311", geneDisplay: "UBA52" },
  { key: "NM_001034077.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "554313", geneDisplay: "H4C15" },
  { key: "NM_001034116.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8890", geneDisplay: "EIF2B4" },
  { key: "NM_001034172.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "147407", geneDisplay: "SLC25A52" },
  { key: "NM_001034173.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160428", geneDisplay: "ALDH1L2" },
  { key: "NM_001034832.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "548313", geneDisplay: "SSX4B" },
  { key: "NM_001034841.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "162073", geneDisplay: "ITPRIPL2" },
  { key: "NM_001034845.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "442117", geneDisplay: "GALNTL6" },
  { key: "NM_001034850.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54463", geneDisplay: "RETREG1" },
  { key: "NM_001034852.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64093", geneDisplay: "SMOC1" },
  { key: "NM_001034954.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10580", geneDisplay: "SORBS1" },
  { key: "NM_001034996.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9045", geneDisplay: "RPL14" },
  { key: "NM_001035005.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "497661", geneDisplay: "C18orf32" },
  { key: "NM_001035006.5", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6139", geneDisplay: "RPL17" },
  { key: "NM_001035223.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57139", geneDisplay: "RGL3" },
  { key: "NM_001035235.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10011", geneDisplay: "SRA1" },
  { key: "NM_001035254.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "399665", geneDisplay: "FAM102A" },
  { key: "NM_001035267.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6171", geneDisplay: "RPL41" },
  { key: "NM_001035521.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2976", geneDisplay: "GTF3C2" },
  { key: "NM_001037131.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "116987", geneDisplay: "AGAP1" },
  { key: "NM_001037132.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4897", geneDisplay: "NRCAM" },
  { key: "NM_001037160.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "192668", geneDisplay: "CYS1" },
  { key: "NM_001037161.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "641371", geneDisplay: "ACOT1" },
  { key: "NM_001037163.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84792", geneDisplay: "FAM220A" },
  { key: "NM_001037165.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221937", geneDisplay: "FOXK1" },
  { key: "NM_001037171.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23597", geneDisplay: "ACOT9" },
  { key: "NM_001037172.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "92370", geneDisplay: "PXYLP1" },
  { key: "NM_001037225.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283129", geneDisplay: "MAJIN" },
  { key: "NM_001037232.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374899", geneDisplay: "ZNF829" },
  { key: "NM_001037281.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "50855", geneDisplay: "PARD6A" },
  { key: "NM_001037283.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8662", geneDisplay: "EIF3B" },
  { key: "NM_001037317.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163404", geneDisplay: "PLPPR5" },
  { key: "NM_001037329.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1262", geneDisplay: "CNGA4" },
  { key: "NM_001037331.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284274", geneDisplay: "SMIM21" },
  { key: "NM_001037333.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26999", geneDisplay: "CYFIP2" },
  { key: "NM_001037335.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "85441", geneDisplay: "HELZ2" },
  { key: "NM_001037380.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "641517", geneDisplay: "DEFB109B" },
  { key: "NM_001037442.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "22902", geneDisplay: "RUFY3" },
  { key: "NM_001037497.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "245913", geneDisplay: "DEFB110" },
  { key: "NM_001037499.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "245928", geneDisplay: "DEFB114" },
  { key: "NM_001037500.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245937", geneDisplay: "DEFB124" },
  { key: "NM_001037501.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728841", geneDisplay: "NBPF8" },
  { key: "NM_001037540.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6322", geneDisplay: "SCML1" },
  { key: "NM_001037582.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79966", geneDisplay: "SCD5" },
  { key: "NM_001037637.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "689", geneDisplay: "BTF3" },
  { key: "NM_001037666.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "652968", geneDisplay: "CASTOR1" },
  { key: "NM_001037668.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "245910", geneDisplay: "DEFB107A" },
  { key: "NM_001037729.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "245927", geneDisplay: "DEFB113" },
  { key: "NM_001037730.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245929", geneDisplay: "DEFB115" },
  { key: "NM_001037731.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245930", geneDisplay: "DEFB116" },
  { key: "NM_001037732.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "245939", geneDisplay: "DEFB128" },
  { key: "NM_001037763.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340267", geneDisplay: "COL28A1" },
  { key: "NM_001037804.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "245940", geneDisplay: "DEFB130A" },
  { key: "NM_001037806.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57701", geneDisplay: "NCKAP5L" },
  { key: "NM_001037813.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342909", geneDisplay: "ZNF284" },
  { key: "NM_001037814.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "128954", geneDisplay: "GAB4" },
  { key: "NM_001037954.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "85458", geneDisplay: "DIXDC1" },
  { key: "NM_001037984.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124565", geneDisplay: "SLC38A10" },
  { key: "NM_001038603.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153562", geneDisplay: "MARVELD2" },
  { key: "NM_001038640.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "342096", geneDisplay: "GOLGA6A" },
  { key: "NM_001038705.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344758", geneDisplay: "GPR149" },
  { key: "NM_001039029.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "654429", geneDisplay: "LRTM2" },
  { key: "NM_001039083.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100506084", geneDisplay: "ARL17B" },
  { key: "NM_001039111.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131405", geneDisplay: "TRIM71" },
  { key: "NM_001039112.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "654463", geneDisplay: "FER1L6" },
  { key: "NM_001039127.6", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "255403", geneDisplay: "ZNF718" },
  { key: "NM_001039141.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11078", geneDisplay: "TRIOBP" },
  { key: "NM_001039152.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "431704", geneDisplay: "RGS21" },
  { key: "NM_001039165.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116534", geneDisplay: "MRGPRE" },
  { key: "NM_001039182.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "654483", geneDisplay: "BOLA2B" },
  { key: "NM_001039199.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79183", geneDisplay: "TTPAL" },
  { key: "NM_001039211.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "219293", geneDisplay: "ATAD3C" },
  { key: "NM_001039213.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388551", geneDisplay: "CEACAM16" },
  { key: "NM_001039348.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2202", geneDisplay: "EFEMP1" },
  { key: "NM_001039355.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123096", geneDisplay: "SLC25A29" },
  { key: "NM_001039361.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343071", geneDisplay: "PRAMEF10" },
  { key: "NM_001039362.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "245973", geneDisplay: "ATP6V1C2" },
  { key: "NM_001039372.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "253012", geneDisplay: "HEPACAM2" },
  { key: "NM_001039374.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84960", geneDisplay: "CCDC183" },
  { key: "NM_001039396.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219972", geneDisplay: "MPEG1" },
  { key: "NM_001039397.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "254272", geneDisplay: "TBC1D28" },
  { key: "NM_001039464.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374977", geneDisplay: "MROH7" },
  { key: "NM_001039469.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2011", geneDisplay: "MARK2" },
  { key: "NM_001039479.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9870", geneDisplay: "AREL1" },
  { key: "NM_001039496.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25858", geneDisplay: "CATSPERZ" },
  { key: "NM_001039500.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127731", geneDisplay: "VWA5B1" },
  { key: "NM_001039503.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "339105", geneDisplay: "PRSS53" },
  { key: "NM_001039547.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "256356", geneDisplay: "GK5" },
  { key: "NM_001039548.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283212", geneDisplay: "KLHL35" },
  { key: "NM_001039567.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "140032", geneDisplay: "RPS4Y2" },
  { key: "NM_001039569.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130340", geneDisplay: "AP1S3" },
  { key: "NM_001039570.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83999", geneDisplay: "KREMEN1" },
  { key: "NM_001039574.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3749", geneDisplay: "KCNC4" },
  { key: "NM_001039580.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79884", geneDisplay: "MAP9" },
  { key: "NM_001039591.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8239", geneDisplay: "USP9X" },
  { key: "NM_001039592.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26206", geneDisplay: "SPAG8" },
  { key: "NM_001039613.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285148", geneDisplay: "IAH1" },
  { key: "NM_001039614.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "388135", geneDisplay: "INSYN1" },
  { key: "NM_001039615.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "728957", geneDisplay: "ZNF705D" },
  { key: "NM_001039617.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131540", geneDisplay: "ZDHHC19" },
  { key: "NM_001039618.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "58487", geneDisplay: "CREBZF" },
  { key: "NM_001039651.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401251", geneDisplay: "SAPCD1" },
  { key: "NM_001039660.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10068", geneDisplay: "IL18BP" },
  { key: "NM_001039672.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90522", geneDisplay: "YIF1B" },
  { key: "NM_001039690.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54921", geneDisplay: "CHTF8" },
  { key: "NM_001039693.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129450", geneDisplay: "TYW5" },
  { key: "NM_001039697.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6619", geneDisplay: "SNAPC3" },
  { key: "NM_001039702.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55301", geneDisplay: "OLAH" },
  { key: "NM_001039705.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7216", geneDisplay: "TRO" },
  { key: "NM_001039706.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79846", geneDisplay: "CFAP69" },
  { key: "NM_001039707.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10807", geneDisplay: "ENTR1" },
  { key: "NM_001039724.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "115677", geneDisplay: "NOSTRIN" },
  { key: "NM_001039752.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387775", geneDisplay: "SLC22A10" },
  { key: "NM_001039753.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "400954", geneDisplay: "EML6" },
  { key: "NM_001039762.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "642938", geneDisplay: "INSYN2A" },
  { key: "NM_001039763.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "642987", geneDisplay: "TMEM232" },
  { key: "NM_001039770.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "643853", geneDisplay: "TMPPE" },
  { key: "NM_001039771.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "643866", geneDisplay: "CBLN3" },
  { key: "NM_001039775.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55057", geneDisplay: "CRYBG2" },
  { key: "NM_001039780.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "645121", geneDisplay: "CCNI2" },
  { key: "NM_001039792.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "646962", geneDisplay: "HRCT1" },
  { key: "NM_001039803.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23552", geneDisplay: "CDK20" },
  { key: "NM_001039840.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "53344", geneDisplay: "CHIC1" },
  { key: "NM_001039841.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "89839", geneDisplay: "ARHGAP11B" },
  { key: "NM_001039842.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339229", geneDisplay: "OXLD1" },
  { key: "NM_001039844.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "414149", geneDisplay: "ACBD7" },
  { key: "NM_001039845.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130752", geneDisplay: "MDH1B" },
  { key: "NM_001039846.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113177", geneDisplay: "IZUMO4" },
  { key: "NM_001039876.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163183", geneDisplay: "SYNE4" },
  { key: "NM_001039886.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388558", geneDisplay: "ZNF808" },
  { key: "NM_001039888.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284615", geneDisplay: "ANKRD34A" },
  { key: "NM_001039958.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145873", geneDisplay: "MESP2" },
  { key: "NM_001039960.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9498", geneDisplay: "SLC4A8" },
  { key: "NM_001039999.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "644815", geneDisplay: "FAM83G" },
  { key: "NM_001040023.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140885", geneDisplay: "SIRPA" },
  { key: "NM_001040025.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339231", geneDisplay: "ARL16" },
  { key: "NM_001040032.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203076", geneDisplay: "C8orf74" },
  { key: "NM_001040058.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6696", geneDisplay: "SPP1" },
  { key: "NM_001040061.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "401089", geneDisplay: "FOXL2NB" },
  { key: "NM_001040078.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "654346", geneDisplay: "LGALS9C" },
  { key: "NM_001040084.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "653145", geneDisplay: "ANXA8" },
  { key: "NM_001040092.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5168", geneDisplay: "ENPP2" },
  { key: "NM_001040100.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165679", geneDisplay: "SPTSSB" },
  { key: "NM_001040105.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "140453", geneDisplay: "MUC17" },
  { key: "NM_001040108.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "27030", geneDisplay: "MLH3" },
  { key: "NM_001040118.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116985", geneDisplay: "ARAP1" },
  { key: "NM_001040125.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54896", geneDisplay: "SLC66A1" },
  { key: "NM_001040129.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153218", geneDisplay: "SPINK13" },
  { key: "NM_001040142.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6326", geneDisplay: "SCN2A" },
  { key: "NM_001040151.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55800", geneDisplay: "SCN3B" },
  { key: "NM_001040157.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80817", geneDisplay: "CEP44" },
  { key: "NM_001040159.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "50859", geneDisplay: "SPOCK3" },
  { key: "NM_001040167.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3955", geneDisplay: "LFNG" },
  { key: "NM_001040177.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83592", geneDisplay: "AKR1E2" },
  { key: "NM_001040179.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "84539", geneDisplay: "MCHR2" },
  { key: "NM_001040181.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56650", geneDisplay: "CLDND1" },
  { key: "NM_001040185.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91661", geneDisplay: "ZNF765" },
  { key: "NM_001040192.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54943", geneDisplay: "DNAJC28" },
  { key: "NM_001040202.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152559", geneDisplay: "PAQR3" },
  { key: "NM_001040214.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154215", geneDisplay: "NKAIN2" },
  { key: "NM_001040260.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166614", geneDisplay: "DCLK2" },
  { key: "NM_001040272.6", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "92949", geneDisplay: "ADAMTSL1" },
  { key: "NM_001040274.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221711", geneDisplay: "SYCP2L" },
  { key: "NM_001040283.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "63946", geneDisplay: "DMRTC2" },
  { key: "NM_001040402.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23142", geneDisplay: "DCUN1D4" },
  { key: "NM_001040424.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "63977", geneDisplay: "PRDM15" },
  { key: "NM_001040425.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199746", geneDisplay: "U2AF1L4" },
  { key: "NM_001040429.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "27253", geneDisplay: "PCDH17" },
  { key: "NM_001040431.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "28958", geneDisplay: "COA3" },
  { key: "NM_001040432.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152098", geneDisplay: "ZCWPW2" },
  { key: "NM_001040433.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "643394", geneDisplay: "SPINK9" },
  { key: "NM_001040436.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51067", geneDisplay: "YARS2" },
  { key: "NM_001040440.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153733", geneDisplay: "CCDC112" },
  { key: "NM_001040441.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653121", geneDisplay: "ZBTB8A" },
  { key: "NM_001040443.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "51131", geneDisplay: "PHF11" },
  { key: "NM_001040445.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51665", geneDisplay: "ASB1" },
  { key: "NM_001040446.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54545", geneDisplay: "MTMR12" },
  { key: "NM_001040448.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "644414", geneDisplay: "DEFB131A" },
  { key: "NM_001040450.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54629", geneDisplay: "MINDY2" },
  { key: "NM_001040455.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51092", geneDisplay: "SIDT2" },
  { key: "NM_001040456.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57414", geneDisplay: "RHBDD2" },
  { key: "NM_001040458.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51752", geneDisplay: "ERAP1" },
  { key: "NM_001040462.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79908", geneDisplay: "BTNL8" },
  { key: "NM_001040616.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55180", geneDisplay: "LINS1" },
  { key: "NM_001040663.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2543", geneDisplay: "GAGE1" },
  { key: "NM_001040694.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3619", geneDisplay: "INCENP" },
  { key: "NM_001040697.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55293", geneDisplay: "UEVLD" },
  { key: "NM_001040702.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "503618", geneDisplay: "DEFB104B" },
  { key: "NM_001040703.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "504180", geneDisplay: "DEFB105B" },
  { key: "NM_001040704.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "503841", geneDisplay: "DEFB106B" },
  { key: "NM_001040705.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "503614", geneDisplay: "DEFB107B" },
  { key: "NM_001040709.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284612", geneDisplay: "SYPL2" },
  { key: "NM_001040710.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653140", geneDisplay: "FAM228A" },
  { key: "NM_001040715.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "653319", geneDisplay: "KIAA0895L" },
  { key: "NM_001040716.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5091", geneDisplay: "PC" },
  { key: "NM_001040874.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "723790", geneDisplay: "H2AC19" },
  { key: "NM_001042353.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "83541", geneDisplay: "FAM110A" },
  { key: "NM_001042357.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26095", geneDisplay: "PTPN20" },
  { key: "NM_001042367.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283677", geneDisplay: "REC114" },
  { key: "NM_001042371.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283871", geneDisplay: "PGP" },
  { key: "NM_001042388.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9989", geneDisplay: "PPP4R1" },
  { key: "NM_001042406.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54511", geneDisplay: "HMGCLL1" },
  { key: "NM_001042413.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169792", geneDisplay: "GLIS3" },
  { key: "NM_001042416.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "169270", geneDisplay: "ZNF596" },
  { key: "NM_001042424.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7468", geneDisplay: "NSD2" },
  { key: "NM_001042427.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282966", geneDisplay: "C10orf53" },
  { key: "NM_001042428.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7755", geneDisplay: "ZNF205" },
  { key: "NM_001042432.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1201", geneDisplay: "CLN3" },
  { key: "NM_001042450.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125206", geneDisplay: "SLC5A10" },
  { key: "NM_001042454.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7041", geneDisplay: "TGFB1I1" },
  { key: "NM_001042462.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126003", geneDisplay: "TRAPPC5" },
  { key: "NM_001042463.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283232", geneDisplay: "TMEM80" },
  { key: "NM_001042472.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26090", geneDisplay: "ABHD12" },
  { key: "NM_001042475.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "387119", geneDisplay: "CEP85L" },
  { key: "NM_001042479.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54960", geneDisplay: "GEMIN8" },
  { key: "NM_001042492.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4763", geneDisplay: "NF1" },
  { key: "NM_001042493.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57150", geneDisplay: "SMIM8" },
  { key: "NM_001042500.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "728358", geneDisplay: "DEFA1B" },
  { key: "NM_001042506.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "645974", geneDisplay: "PABPC1L2B" },
  { key: "NM_001042507.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "653499", geneDisplay: "LGALS7B" },
  { key: "NM_001042517.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "81624", geneDisplay: "DIAPH3" },
  { key: "NM_001042519.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84281", geneDisplay: "C2orf88" },
  { key: "NM_001042536.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387755", geneDisplay: "INSC" },
  { key: "NM_001042545.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8425", geneDisplay: "LTBP4" },
  { key: "NM_001042552.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128387", geneDisplay: "TATDN3" },
  { key: "NM_001042573.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "64772", geneDisplay: "ENGASE" },
  { key: "NM_001042590.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "51754", geneDisplay: "TMEM8B" },
  { key: "NM_001042600.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11184", geneDisplay: "MAP4K1" },
  { key: "NM_001042603.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5927", geneDisplay: "KDM5A" },
  { key: "NM_001042610.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79007", geneDisplay: "DBNDD1" },
  { key: "NM_001042616.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84992", geneDisplay: "PIGY" },
  { key: "NM_001042618.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "10038", geneDisplay: "PARP2" },
  { key: "NM_001042625.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133690", geneDisplay: "CAPSL" },
  { key: "NM_001042631.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "644096", geneDisplay: "SDHAF1" },
  { key: "NM_001042635.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "25983", geneDisplay: "NGDN" },
  { key: "NM_001042646.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22906", geneDisplay: "TRAK1" },
  { key: "NM_001042681.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "473", geneDisplay: "RERE" },
  { key: "NM_001042683.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "257218", geneDisplay: "SHPRH" },
  { key: "NM_001042690.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345222", geneDisplay: "MSANTD1" },
  { key: "NM_001042693.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "348378", geneDisplay: "SHISAL2A" },
  { key: "NM_001042697.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125150", geneDisplay: "ZSWIM7" },
  { key: "NM_001042702.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "494513", geneDisplay: "PJVK" },
  { key: "NM_001042706.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "654502", geneDisplay: "IQCJ" },
  { key: "NM_001042724.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5819", geneDisplay: "NECTIN2" },
  { key: "NM_001042750.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10735", geneDisplay: "STAG2" },
  { key: "NM_001043229.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "751071", geneDisplay: "CSKMT" },
  { key: "NM_001044264.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "692094", geneDisplay: "MSMP" },
  { key: "NM_001044305.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "60682", geneDisplay: "SMAP1" },
  { key: "NM_001044369.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "125704", geneDisplay: "DIPK1C" },
  { key: "NM_001044370.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "758", geneDisplay: "MPPED1" },
  { key: "NM_001044385.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65062", geneDisplay: "TMEM237" },
  { key: "NM_001045476.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "401551", geneDisplay: "WDR38" },
  { key: "NM_001045556.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6503", geneDisplay: "SLA" },
  { key: "NM_001047160.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10276", geneDisplay: "NET1" },
  { key: "NM_001048166.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6491", geneDisplay: "STIL" },
  { key: "NM_001048174.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4595", geneDisplay: "MUTYH" },
  { key: "NM_001048181.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728458", geneDisplay: "OPN1MW2" },
  { key: "NM_001048183.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "65979", geneDisplay: "PHACTR4" },
  { key: "NM_001048201.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29128", geneDisplay: "UHRF1" },
  { key: "NM_001048205.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9985", geneDisplay: "REC8" },
  { key: "NM_001048212.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "752014", geneDisplay: "CEMP1" },
  { key: "NM_001048225.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55861", geneDisplay: "DBNDD2" },
  { key: "NM_001048241.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "59286", geneDisplay: "UBL5" },
  { key: "NM_001048249.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "643155", geneDisplay: "SMIM15" },
  { key: "NM_001048252.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "613212", geneDisplay: "CTXN3" },
  { key: "NM_001048265.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138162", geneDisplay: "PIERCE1" },
  { key: "NM_001071775.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "440145", geneDisplay: "MZT1" },
  { key: "NM_001076674.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79089", geneDisplay: "TMUB2" },
  { key: "NM_001076675.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199777", geneDisplay: "ZNF626" },
  { key: "NM_001076678.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284443", geneDisplay: "ZNF493" },
  { key: "NM_001076680.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201229", geneDisplay: "LYRM9" },
  { key: "NM_001076778.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11170", geneDisplay: "FAM107A" },
  { key: "NM_001076781.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "346157", geneDisplay: "ZNF391" },
  { key: "NM_001076786.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79832", geneDisplay: "QSER1" },
  { key: "NM_001077195.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80818", geneDisplay: "ZNF436" },
  { key: "NM_001077198.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79065", geneDisplay: "ATG9A" },
  { key: "NM_001077199.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "140890", geneDisplay: "SREK1" },
  { key: "NM_001077207.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "22872", geneDisplay: "SEC31A" },
  { key: "NM_001077242.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91614", geneDisplay: "DEPDC7" },
  { key: "NM_001077263.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84000", geneDisplay: "TMPRSS13" },
  { key: "NM_001077268.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84936", geneDisplay: "ZFYVE19" },
  { key: "NM_001077350.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8131", geneDisplay: "NPRL3" },
  { key: "NM_001077351.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55147", geneDisplay: "RBM23" },
  { key: "NM_001077365.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10585", geneDisplay: "POMT1" },
  { key: "NM_001077415.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "78987", geneDisplay: "CRELD1" },
  { key: "NM_001077418.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79583", geneDisplay: "TMEM231" },
  { key: "NM_001077446.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79042", geneDisplay: "TSEN34" },
  { key: "NM_001077498.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55731", geneDisplay: "FAM222B" },
  { key: "NM_001077506.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "255061", geneDisplay: "TAC4" },
  { key: "NM_001077525.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64419", geneDisplay: "MTMR14" },
  { key: "NM_001077594.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91828", geneDisplay: "EXOC3L4" },
  { key: "NM_001077619.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137886", geneDisplay: "UBXN2B" },
  { key: "NM_001077620.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "768206", geneDisplay: "PRCD" },
  { key: "NM_001077621.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155382", geneDisplay: "VPS37D" },
  { key: "NM_001077624.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162993", geneDisplay: "ZNF846" },
  { key: "NM_001077628.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51107", geneDisplay: "APH1A" },
  { key: "NM_001077637.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150786", geneDisplay: "RAB6D" },
  { key: "NM_001077639.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54827", geneDisplay: "NXPE4" },
  { key: "NM_001077653.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57057", geneDisplay: "TBX20" },
  { key: "NM_001077657.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "255798", geneDisplay: "SMCO1" },
  { key: "NM_001077663.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55665", geneDisplay: "URGCP" },
  { key: "NM_001077665.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "414189", geneDisplay: "AGAP6" },
  { key: "NM_001077693.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "641700", geneDisplay: "ECSCR" },
  { key: "NM_001077697.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "728137", geneDisplay: "TSPY3" },
  { key: "NM_001077700.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57708", geneDisplay: "MIER1" },
  { key: "NM_001077706.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "345930", geneDisplay: "ECT2L" },
  { key: "NM_001077710.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "642273", geneDisplay: "FAM110C" },
  { key: "NM_001077711.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "643812", geneDisplay: "KRTAP27-1" },
  { key: "NM_001078170.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "729857", geneDisplay: "RGPD2" },
  { key: "NM_001078171.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8933", geneDisplay: "RTL8C" },
  { key: "NM_001078172.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "26071", geneDisplay: "RTL8A" },
  { key: "NM_001078173.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441518", geneDisplay: "RTL8B" },
  { key: "NM_001078645.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8881", geneDisplay: "CDC16" },
  { key: "NM_001079512.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "780776", geneDisplay: "TVP23A" },
  { key: "NM_001079514.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29855", geneDisplay: "UBN1" },
  { key: "NM_001079520.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51339", geneDisplay: "DACT1" },
  { key: "NM_001079521.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3895", geneDisplay: "KTN1" },
  { key: "NM_001079524.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10606", geneDisplay: "PAICS" },
  { key: "NM_001079530.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653275", geneDisplay: "CFC1B" },
  { key: "NM_001079537.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122553", geneDisplay: "TRAPPC6B" },
  { key: "NM_001079539.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7494", geneDisplay: "XBP1" },
  { key: "NM_001079559.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "221092", geneDisplay: "HNRNPUL2" },
  { key: "NM_001079668.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "7080", geneDisplay: "NKX2-1" },
  { key: "NM_001079670.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "81617", geneDisplay: "CAB39L" },
  { key: "NM_001079673.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "22862", geneDisplay: "FNDC3A" },
  { key: "NM_001079675.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2118", geneDisplay: "ETV4" },
  { key: "NM_001079802.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2218", geneDisplay: "FKTN" },
  { key: "NM_001079807.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643834", geneDisplay: "PGA3" },
  { key: "NM_001079808.6", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643847", geneDisplay: "PGA4" },
  { key: "NM_001079827.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "645104", geneDisplay: "CLRN2" },
  { key: "NM_001079843.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54897", geneDisplay: "CASZ1" },
  { key: "NM_001079855.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8908", geneDisplay: "GYG2" },
  { key: "NM_001079858.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10149", geneDisplay: "ADGRG2" },
  { key: "NM_001079862.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1622", geneDisplay: "DBI" },
  { key: "NM_001079866.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "617", geneDisplay: "BCS1L" },
  { key: "NM_001079872.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8450", geneDisplay: "CUL4B" },
  { key: "NM_001079900.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729201", geneDisplay: "SPACA5B" },
  { key: "NM_001079906.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55422", geneDisplay: "ZNF331" },
  { key: "NM_001079910.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84125", geneDisplay: "LRRIQ1" },
  { key: "NM_001079935.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26648", geneDisplay: "OR7E24" },
  { key: "NM_001080137.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728036", geneDisplay: "CT47A10" },
  { key: "NM_001080138.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728042", geneDisplay: "CT47A9" },
  { key: "NM_001080139.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728049", geneDisplay: "CT47A8" },
  { key: "NM_001080140.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "653282", geneDisplay: "CT47A7" },
  { key: "NM_001080141.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728062", geneDisplay: "CT47A6" },
  { key: "NM_001080142.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728072", geneDisplay: "CT47A5" },
  { key: "NM_001080143.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728075", geneDisplay: "CT47A4" },
  { key: "NM_001080144.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728082", geneDisplay: "CT47A3" },
  { key: "NM_001080145.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728090", geneDisplay: "CT47A2" },
  { key: "NM_001080146.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728096", geneDisplay: "CT47A1" },
  { key: "NM_001080379.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135138", geneDisplay: "PACRG" },
  { key: "NM_001080391.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6672", geneDisplay: "SP100" },
  { key: "NM_001080392.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57189", geneDisplay: "DENND11" },
  { key: "NM_001080393.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "727936", geneDisplay: "GXYLT2" },
  { key: "NM_001080394.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23514", geneDisplay: "SPIDR" },
  { key: "NM_001080395.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9625", geneDisplay: "AATK" },
  { key: "NM_001080396.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "728215", geneDisplay: "NALF1" },
  { key: "NM_001080397.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50651", geneDisplay: "SLC45A1" },
  { key: "NM_001080399.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "729330", geneDisplay: "OC90" },
  { key: "NM_001080401.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147699", geneDisplay: "PPM1N" },
  { key: "NM_001080404.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729747", geneDisplay: "ZNF878" },
  { key: "NM_001080406.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "728858", geneDisplay: "C12orf71" },
  { key: "NM_001080407.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "112937", geneDisplay: "GLB1L3" },
  { key: "NM_001080409.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7652", geneDisplay: "ZNF99" },
  { key: "NM_001080413.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135935", geneDisplay: "NOBOX" },
  { key: "NM_001080414.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "440193", geneDisplay: "CCDC88C" },
  { key: "NM_001080415.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23350", geneDisplay: "U2SURP" },
  { key: "NM_001080416.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4603", geneDisplay: "MYBL1" },
  { key: "NM_001080417.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23361", geneDisplay: "ZNF629" },
  { key: "NM_001080418.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "58512", geneDisplay: "DLGAP3" },
  { key: "NM_001080419.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85451", geneDisplay: "UNK" },
  { key: "NM_001080421.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23025", geneDisplay: "UNC13A" },
  { key: "NM_001080423.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80852", geneDisplay: "GRIP2" },
  { key: "NM_001080425.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "56271", geneDisplay: "BEX4" },
  { key: "NM_001080426.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92235", geneDisplay: "STYXL2" },
  { key: "NM_001080430.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "27324", geneDisplay: "TOX3" },
  { key: "NM_001080432.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79068", geneDisplay: "FTO" },
  { key: "NM_001080433.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "114800", geneDisplay: "CCDC85A" },
  { key: "NM_001080435.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123720", geneDisplay: "WHAMM" },
  { key: "NM_001080436.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126374", geneDisplay: "WTIP" },
  { key: "NM_001080437.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25992", geneDisplay: "SNED1" },
  { key: "NM_001080438.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127550", geneDisplay: "A3GALT2" },
  { key: "NM_001080439.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124535", geneDisplay: "HSF5" },
  { key: "NM_001080440.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131149", geneDisplay: "OTOL1" },
  { key: "NM_001080441.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143941", geneDisplay: "TTC36" },
  { key: "NM_001080442.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146167", geneDisplay: "SLC38A8" },
  { key: "NM_001080444.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "150084", geneDisplay: "IGSF5" },
  { key: "NM_001080446.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143678", geneDisplay: "C11orf94" },
  { key: "NM_001080448.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285220", geneDisplay: "EPHA6" },
  { key: "NM_001080449.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1763", geneDisplay: "DNA2" },
  { key: "NM_001080451.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "256394", geneDisplay: "SERPINA11" },
  { key: "NM_001080452.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56927", geneDisplay: "GPR108" },
  { key: "NM_001080453.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26173", geneDisplay: "INTS1" },
  { key: "NM_001080454.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341392", geneDisplay: "ACSM4" },
  { key: "NM_001080456.5", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342933", geneDisplay: "ZSCAN5B" },
  { key: "NM_001080457.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94030", geneDisplay: "LRRC4B" },
  { key: "NM_001080458.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344191", geneDisplay: "EVX2" },
  { key: "NM_001080460.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344657", geneDisplay: "LRRIQ4" },
  { key: "NM_001080461.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340260", geneDisplay: "UNCX" },
  { key: "NM_001080462.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "338949", geneDisplay: "TMEM202" },
  { key: "NM_001080464.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "374569", geneDisplay: "ASPG" },
  { key: "NM_001080465.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388381", geneDisplay: "C17orf98" },
  { key: "NM_001080466.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388419", geneDisplay: "BTBD17" },
  { key: "NM_001080467.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4645", geneDisplay: "MYO5B" },
  { key: "NM_001080468.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342898", geneDisplay: "SYCN" },
  { key: "NM_001080469.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23403", geneDisplay: "FBXO46" },
  { key: "NM_001080470.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90874", geneDisplay: "ZNF697" },
  { key: "NM_001080471.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "375033", geneDisplay: "PEAR1" },
  { key: "NM_001080472.4", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128486", geneDisplay: "FITM2" },
  { key: "NM_001080474.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388960", geneDisplay: "C2orf78" },
  { key: "NM_001080475.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389072", geneDisplay: "PLEKHM3" },
  { key: "NM_001080476.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "389207", geneDisplay: "GRXCR1" },
  { key: "NM_001080477.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55714", geneDisplay: "TENM3" },
  { key: "NM_001080478.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389257", geneDisplay: "LRRC14B" },
  { key: "NM_001080480.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154141", geneDisplay: "MBOAT1" },
  { key: "NM_001080482.5", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389813", geneDisplay: "AJM1" },
  { key: "NM_001080483.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389827", geneDisplay: "MYMK" },
  { key: "NM_001080487.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "390748", geneDisplay: "PABPN1L" },
  { key: "NM_001080488.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390874", geneDisplay: "ONECUT3" },
  { key: "NM_001080489.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "392465", geneDisplay: "GLOD5" },
  { key: "NM_001080493.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55552", geneDisplay: "ZNF823" },
  { key: "NM_001080495.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84629", geneDisplay: "TNRC18" },
  { key: "NM_001080496.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9827", geneDisplay: "RGP1" },
  { key: "NM_001080497.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1955", geneDisplay: "MEGF9" },
  { key: "NM_001080500.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "402117", geneDisplay: "VWC2L" },
  { key: "NM_001080501.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79064", geneDisplay: "TMEM223" },
  { key: "NM_001080503.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126075", geneDisplay: "CCDC159" },
  { key: "NM_001080504.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375316", geneDisplay: "RBM44" },
  { key: "NM_001080505.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152573", geneDisplay: "SHISA3" },
  { key: "NM_001080506.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "441027", geneDisplay: "TMEM150C" },
  { key: "NM_001080507.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "441161", geneDisplay: "OOEP" },
  { key: "NM_001080508.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9096", geneDisplay: "TBX18" },
  { key: "NM_001080510.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124512", geneDisplay: "METTL23" },
  { key: "NM_001080511.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154790", geneDisplay: "CLEC2L" },
  { key: "NM_001080512.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80114", geneDisplay: "BICC1" },
  { key: "NM_001080513.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1370", geneDisplay: "CPN2" },
  { key: "NM_001080514.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "642658", geneDisplay: "SCX" },
  { key: "NM_001080515.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "642968", geneDisplay: "FAM163B" },
  { key: "NM_001080516.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "643226", geneDisplay: "GRXCR2" },
  { key: "NM_001080517.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55209", geneDisplay: "SETD5" },
  { key: "NM_001080518.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "643414", geneDisplay: "LIPK" },
  { key: "NM_001080521.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "644943", geneDisplay: "RASSF10" },
  { key: "NM_001080523.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "645432", geneDisplay: "ARRDC5" },
  { key: "NM_001080524.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "646174", geneDisplay: "C16orf90" },
  { key: "NM_001080525.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "646424", geneDisplay: "SPINK8" },
  { key: "NM_001080526.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "646480", geneDisplay: "FABP9" },
  { key: "NM_001080528.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "646498", geneDisplay: "C3orf84" },
  { key: "NM_001080529.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "644150", geneDisplay: "WIPF3" },
  { key: "NM_001080531.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "646603", geneDisplay: "C4orf51" },
  { key: "NM_001080532.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "342125", geneDisplay: "TMC3" },
  { key: "NM_001080533.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84747", geneDisplay: "UNC119B" },
  { key: "NM_001080534.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440279", geneDisplay: "UNC13C" },
  { key: "NM_001080537.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132203", geneDisplay: "SNTN" },
  { key: "NM_001080538.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441282", geneDisplay: "AKR1B15" },
  { key: "NM_001080539.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "284992", geneDisplay: "CCDC150" },
  { key: "NM_001080543.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58509", geneDisplay: "CACTIN" },
  { key: "NM_001080545.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151242", geneDisplay: "PPP1R1C" },
  { key: "NM_001080555.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83445", geneDisplay: "GSG1" },
  { key: "NM_001080770.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3805", geneDisplay: "KIR2DL4" },
  { key: "NM_001080779.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4641", geneDisplay: "MYO1C" },
  { key: "NM_001080790.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728269", geneDisplay: "MAGEA9B" },
  { key: "NM_001080792.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90416", geneDisplay: "CCDC32" },
  { key: "NM_001080821.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90576", geneDisplay: "ZNF799" },
  { key: "NM_001080825.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144404", geneDisplay: "TMEM120B" },
  { key: "NM_001080826.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157285", geneDisplay: "PRAG1" },
  { key: "NM_001080830.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "390999", geneDisplay: "PRAMEF12" },
  { key: "NM_001080836.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "644890", geneDisplay: "MEIG1" },
  { key: "NM_001080837.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "645832", geneDisplay: "SEBOX" },
  { key: "NM_001080843.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "653689", geneDisplay: "GSTT2B" },
  { key: "NM_001080849.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "728489", geneDisplay: "DNLZ" },
  { key: "NM_001080978.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10288", geneDisplay: "LILRB2" },
  { key: "NM_001080998.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "441581", geneDisplay: "FRG2B" },
  { key: "NM_001081442.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10990", geneDisplay: "LILRB5" },
  { key: "NM_001081550.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57187", geneDisplay: "THOC2" },
  { key: "NM_001081551.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "414325", geneDisplay: "DEFB103A" },
  { key: "NM_001081573.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139716", geneDisplay: "GAB3" },
  { key: "NM_001081637.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10859", geneDisplay: "LILRB1" },
  { key: "NM_001081675.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340359", geneDisplay: "KLHL38" },
  { key: "NM_001082486.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "65057", geneDisplay: "ACD" },
  { key: "NM_001082538.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79600", geneDisplay: "TCTN1" },
  { key: "NM_001082967.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25817", geneDisplay: "TAFA5" },
  { key: "NM_001082968.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146691", geneDisplay: "TOM1L2" },
  { key: "NM_001082971.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1644", geneDisplay: "DDC" },
  { key: "NM_001083111.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2796", geneDisplay: "GNRH1" },
  { key: "NM_001083116.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5551", geneDisplay: "PRF1" },
  { key: "NM_001083124.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "727830", geneDisplay: "SPATA31A3" },
  { key: "NM_001083308.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152138", geneDisplay: "PYDC2" },
  { key: "NM_001083536.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "89846", geneDisplay: "FGD3" },
  { key: "NM_001083537.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "85002", geneDisplay: "FAM86B1" },
  { key: "NM_001083538.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "445582", geneDisplay: "POTEE" },
  { key: "NM_001083601.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79903", geneDisplay: "NAA60" },
  { key: "NM_001083613.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124446", geneDisplay: "TMEM219" },
  { key: "NM_001083614.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124454", geneDisplay: "EARS2" },
  { key: "NM_001083619.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2891", geneDisplay: "GRIA2" },
  { key: "NM_001083893.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29966", geneDisplay: "STRN3" },
  { key: "NM_001083899.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51206", geneDisplay: "GP6" },
  { key: "NM_001083909.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84435", geneDisplay: "ADGRA1" },
  { key: "NM_001083913.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54838", geneDisplay: "WBP1L" },
  { key: "NM_001083926.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "80150", geneDisplay: "ASRGL1" },
  { key: "NM_001083961.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284403", geneDisplay: "WDR62" },
  { key: "NM_001083962.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "6925", geneDisplay: "TCF4" },
  { key: "NM_001083965.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "11022", geneDisplay: "TDRKH" },
  { key: "NM_001084392.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "1652", geneDisplay: "DDT" },
  { key: "NM_001084393.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "100037417", geneDisplay: "DDTL" },
  { key: "NM_001085049.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22808", geneDisplay: "MRAS" },
  { key: "NM_001085347.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "27433", geneDisplay: "TOR2A" },
  { key: "NM_001085365.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653784", geneDisplay: "MZT2A" },
  { key: "NM_001085372.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "790955", geneDisplay: "UQCC3" },
  { key: "NM_001085375.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400793", geneDisplay: "C1orf226" },
  { key: "NM_001085377.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4163", geneDisplay: "MCC" },
  { key: "NM_001085382.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "768239", geneDisplay: "PSAPL1" },
  { key: "NM_001085384.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7710", geneDisplay: "ZNF154" },
  { key: "NM_001085400.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "768211", geneDisplay: "RELL1" },
  { key: "NM_001085411.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133686", geneDisplay: "NADK2" },
  { key: "NM_001085420.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389158", geneDisplay: "PLSCR5" },
  { key: "NM_001085423.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284021", geneDisplay: "MILR1" },
  { key: "NM_001085429.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155006", geneDisplay: "TMEM213" },
  { key: "NM_001085430.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339210", geneDisplay: "C17orf67" },
  { key: "NM_001085437.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79919", geneDisplay: "MAB21L4" },
  { key: "NM_001085447.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129881", geneDisplay: "CFAP210" },
  { key: "NM_001085451.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "348801", geneDisplay: "LNP1" },
  { key: "NM_001085452.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "647060", geneDisplay: "SPATA31A1" },
  { key: "NM_001085455.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "643803", geneDisplay: "KRTAP24-1" },
  { key: "NM_001085457.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "644019", geneDisplay: "CBWD6" },
  { key: "NM_001085458.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1500", geneDisplay: "CTNND1" },
  { key: "NM_001085474.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "646627", geneDisplay: "LYPD8" },
  { key: "NM_001085476.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "653404", geneDisplay: "FOXD4L6" },
  { key: "NM_001085480.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221303", geneDisplay: "FAM162B" },
  { key: "NM_001085481.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "643246", geneDisplay: "MAP1LC3B2" },
  { key: "NM_001085487.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114803", geneDisplay: "MYSM1" },
  { key: "NM_001086521.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284184", geneDisplay: "NDUFAF8" },
  { key: "NM_001089584.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134121", geneDisplay: "C5orf49" },
  { key: "NM_001089591.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440567", geneDisplay: "UQCRHL" },
  { key: "NM_001093725.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "92312", geneDisplay: "MEX3A" },
  { key: "NM_001093730.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "391475", geneDisplay: "DYTN" },
  { key: "NM_001093771.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7296", geneDisplay: "TXNRD1" },
  { key: "NM_001097577.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283", geneDisplay: "ANG" },
  { key: "NM_001097579.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2857", geneDisplay: "GPR34" },
  { key: "NM_001097589.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6707", geneDisplay: "SPRR3" },
  { key: "NM_001097594.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "653220", geneDisplay: "XAGE1A" },
  { key: "NM_001097604.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "653067", geneDisplay: "XAGE1B" },
  { key: "NM_001097610.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "653486", geneDisplay: "SCGB1C2" },
  { key: "NM_001097612.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653519", geneDisplay: "GPR89A" },
  { key: "NM_001097615.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "548644", geneDisplay: "POLR2J3" },
  { key: "NM_001097620.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "202915", geneDisplay: "TMEM184A" },
  { key: "NM_001097622.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "654231", geneDisplay: "OCM" },
  { key: "NM_001097639.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2525", geneDisplay: "FUT3" },
  { key: "NM_001097643.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "259293", geneDisplay: "TAS2R30" },
  { key: "NM_001098169.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390259", geneDisplay: "BSX" },
  { key: "NM_001098173.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11105", geneDisplay: "PRDM7" },
  { key: "NM_001098200.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "2841", geneDisplay: "GPR18" },
  { key: "NM_001098201.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2852", geneDisplay: "GPER1" },
  { key: "NM_001098204.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3185", geneDisplay: "HNRNPF" },
  { key: "NM_001098212.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3269", geneDisplay: "HRH1" },
  { key: "NM_001098272.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3157", geneDisplay: "HMGCS1" },
  { key: "NM_001098376.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653619", geneDisplay: "PRAMEF15" },
  { key: "NM_001098402.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "49854", geneDisplay: "ZBTB21" },
  { key: "NM_001098405.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100008586", geneDisplay: "GAGE12F" },
  { key: "NM_001098406.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729396", geneDisplay: "GAGE12J" },
  { key: "NM_001098408.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729422", geneDisplay: "GAGE12C" },
  { key: "NM_001098409.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "645073", geneDisplay: "GAGE12G" },
  { key: "NM_001098410.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729442", geneDisplay: "GAGE12H" },
  { key: "NM_001098412.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "645051", geneDisplay: "GAGE13" },
  { key: "NM_001098413.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102724473", geneDisplay: "GAGE10" },
  { key: "NM_001098418.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729431", geneDisplay: "GAGE12E" },
  { key: "NM_001098426.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6603", geneDisplay: "SMARCD2" },
  { key: "NM_001098479.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3134", geneDisplay: "HLA-F" },
  { key: "NM_001098483.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282969", geneDisplay: "FUOM" },
  { key: "NM_001098484.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8671", geneDisplay: "SLC4A4" },
  { key: "NM_001098486.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10786", geneDisplay: "SLC17A3" },
  { key: "NM_001098497.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "129049", geneDisplay: "SGSM1" },
  { key: "NM_001098502.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131474", geneDisplay: "CHCHD4" },
  { key: "NM_001098506.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90273", geneDisplay: "CEACAM21" },
  { key: "NM_001098507.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7756", geneDisplay: "ZNF207" },
  { key: "NM_001098511.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3796", geneDisplay: "KIF2A" },
  { key: "NM_001098514.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146556", geneDisplay: "C16orf89" },
  { key: "NM_001098518.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221395", geneDisplay: "ADGRF5" },
  { key: "NM_001098519.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "254050", geneDisplay: "LRRC43" },
  { key: "NM_001098522.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10553", geneDisplay: "HTATIP2" },
  { key: "NM_001098526.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120425", geneDisplay: "JAML" },
  { key: "NM_001098531.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10411", geneDisplay: "RAPGEF3" },
  { key: "NM_001098535.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10738", geneDisplay: "RFPL3" },
  { key: "NM_001098536.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8078", geneDisplay: "USP5" },
  { key: "NM_001098537.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375775", geneDisplay: "PNPLA7" },
  { key: "NM_001098540.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10855", geneDisplay: "HPSE" },
  { key: "NM_001098612.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100049587", geneDisplay: "SIGLEC14" },
  { key: "NM_001098616.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25912", geneDisplay: "C1orf43" },
  { key: "NM_001098621.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26175", geneDisplay: "TMEM251" },
  { key: "NM_001098622.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342977", geneDisplay: "NANOS3" },
  { key: "NM_001098626.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148198", geneDisplay: "ZNF98" },
  { key: "NM_001098629.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3663", geneDisplay: "IRF5" },
  { key: "NM_001098633.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84335", geneDisplay: "AKT1S1" },
  { key: "NM_001098634.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54502", geneDisplay: "RBM47" },
  { key: "NM_001098638.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254225", geneDisplay: "RNF169" },
  { key: "NM_001098668.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "729238", geneDisplay: "SFTPA2" },
  { key: "NM_001098671.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10235", geneDisplay: "RASGRP2" },
  { key: "NM_001098672.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341208", geneDisplay: "HEPHL1" },
  { key: "NM_001098722.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2786", geneDisplay: "GNG4" },
  { key: "NM_001098785.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23625", geneDisplay: "FAM89B" },
  { key: "NM_001098787.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51272", geneDisplay: "BET1L" },
  { key: "NM_001098794.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84067", geneDisplay: "FHIP1B" },
  { key: "NM_001098797.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84969", geneDisplay: "TOX2" },
  { key: "NM_001098811.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23176", geneDisplay: "SEPTIN8" },
  { key: "NM_001098814.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6345", geneDisplay: "SRL" },
  { key: "NM_001098816.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26011", geneDisplay: "TENM4" },
  { key: "NM_001098818.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5143", geneDisplay: "PDE4C" },
  { key: "NM_001098821.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "641649", geneDisplay: "TMEM91" },
  { key: "NM_001098832.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84923", geneDisplay: "FAM104A" },
  { key: "NM_001098834.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2636", geneDisplay: "GBX1" },
  { key: "NM_001098835.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219995", geneDisplay: "MS4A15" },
  { key: "NM_001098844.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "653567", geneDisplay: "TMEM236" },
  { key: "NM_001098845.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "728113", geneDisplay: "ANXA8L1" },
  { key: "NM_001099218.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "729475", geneDisplay: "RAD51AP2" },
  { key: "NM_001099219.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "728299", geneDisplay: "KRTAP19-8" },
  { key: "NM_001099220.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "643641", geneDisplay: "ZNF862" },
  { key: "NM_001099221.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "497189", geneDisplay: "TIFAB" },
  { key: "NM_001099269.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "440515", geneDisplay: "ZNF506" },
  { key: "NM_001099270.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "403341", geneDisplay: "ZBTB34" },
  { key: "NM_001099271.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134359", geneDisplay: "POC5" },
  { key: "NM_001099272.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114781", geneDisplay: "BTBD9" },
  { key: "NM_001099274.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26277", geneDisplay: "TINF2" },
  { key: "NM_001099282.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8187", geneDisplay: "ZNF239" },
  { key: "NM_001099286.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "113115", geneDisplay: "MTFR2" },
  { key: "NM_001099287.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "348938", geneDisplay: "NIPAL4" },
  { key: "NM_001099289.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344558", geneDisplay: "SH3RF3" },
  { key: "NM_001099293.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285643", geneDisplay: "KIF4B" },
  { key: "NM_001099294.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85352", geneDisplay: "SHISAL1" },
  { key: "NM_001099334.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389073", geneDisplay: "C2orf80" },
  { key: "NM_001099338.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "728118", geneDisplay: "NUTM2A" },
  { key: "NM_001099402.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8812", geneDisplay: "CCNK" },
  { key: "NM_001099403.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "56978", geneDisplay: "PRDM8" },
  { key: "NM_001099408.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "253314", geneDisplay: "EIF4E1B" },
  { key: "NM_001099409.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254102", geneDisplay: "EHBP1L1" },
  { key: "NM_001099415.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100101267", geneDisplay: "POM121C" },
  { key: "NM_001099433.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152789", geneDisplay: "JAKMIP1" },
  { key: "NM_001099434.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149069", geneDisplay: "DCDC2B" },
  { key: "NM_001099436.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "25989", geneDisplay: "ULK3" },
  { key: "NM_001099439.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284656", geneDisplay: "EPHA10" },
  { key: "NM_001099456.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283869", geneDisplay: "NPW" },
  { key: "NM_001099625.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "56181", geneDisplay: "MTFR1L" },
  { key: "NM_001099645.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200916", geneDisplay: "RPL22L1" },
  { key: "NM_001099646.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146802", geneDisplay: "SLC47A2" },
  { key: "NM_001099652.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283554", geneDisplay: "GPR137C" },
  { key: "NM_001099658.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54674", geneDisplay: "LRRN3" },
  { key: "NM_001099666.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375743", geneDisplay: "PTAR1" },
  { key: "NM_001099667.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387715", geneDisplay: "ARMS2" },
  { key: "NM_001099673.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "401466", geneDisplay: "RBIS" },
  { key: "NM_001099678.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116064", geneDisplay: "LRRC58" },
  { key: "NM_001099685.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "727940", geneDisplay: "RHOXF2B" },
  { key: "NM_001099686.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728343", geneDisplay: "NXF2B" },
  { key: "NM_001099687.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "729355", geneDisplay: "TP53TG3B" },
  { key: "NM_001099692.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143244", geneDisplay: "EIF5AL1" },
  { key: "NM_001099694.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147660", geneDisplay: "ZNF578" },
  { key: "NM_001099695.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29803", geneDisplay: "REPIN1" },
  { key: "NM_001099697.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "728194", geneDisplay: "RSPH10B2" },
  { key: "NM_001099733.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "116", geneDisplay: "ADCYAP1" },
  { key: "NM_001099735.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1160", geneDisplay: "CKMT2" },
  { key: "NM_001099737.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84292", geneDisplay: "WDR83" },
  { key: "NM_001099754.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55638", geneDisplay: "SYBU" },
  { key: "NM_001099771.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728378", geneDisplay: "POTEF" },
  { key: "NM_001099772.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1580", geneDisplay: "CYP4B1" },
  { key: "NM_001099780.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122706", geneDisplay: "PSMB11" },
  { key: "NM_001099783.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132321", geneDisplay: "C4orf33" },
  { key: "NM_001099789.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3384", geneDisplay: "ICAM2" },
  { key: "NM_001099790.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645414", geneDisplay: "PRAMEF19" },
  { key: "NM_001099850.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391003", geneDisplay: "PRAMEF18" },
  { key: "NM_001099851.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391004", geneDisplay: "PRAMEF17" },
  { key: "NM_001099852.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645425", geneDisplay: "PRAMEF20" },
  { key: "NM_001099857.5", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8517", geneDisplay: "IKBKG" },
  { key: "NM_001099858.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79020", geneDisplay: "C7orf25" },
  { key: "NM_001099922.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79868", geneDisplay: "ALG13" },
  { key: "NM_001100121.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9718", geneDisplay: "ECE2" },
  { key: "NM_001100159.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136288", geneDisplay: "C7orf57" },
  { key: "NM_001100164.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9749", geneDisplay: "PHACTR2" },
  { key: "NM_001100388.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399949", geneDisplay: "HOATZ" },
  { key: "NM_001100389.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "201931", geneDisplay: "TMEM192" },
  { key: "NM_001100396.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129852", geneDisplay: "C2orf73" },
  { key: "NM_001100399.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23244", geneDisplay: "PDS5A" },
  { key: "NM_001100418.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55049", geneDisplay: "REX1BD" },
  { key: "NM_001100420.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54149", geneDisplay: "C21orf91" },
  { key: "NM_001100423.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26010", geneDisplay: "SPATS2L" },
  { key: "NM_001100427.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5910", geneDisplay: "RAP1GDS1" },
  { key: "NM_001100588.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54542", geneDisplay: "RC3H2" },
  { key: "NM_001100598.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286075", geneDisplay: "ZNF707" },
  { key: "NM_001100607.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51156", geneDisplay: "SERPINA10" },
  { key: "NM_001100619.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "91768", geneDisplay: "CABLES1" },
  { key: "NM_001100623.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55041", geneDisplay: "PLEKHB2" },
  { key: "NM_001100624.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55839", geneDisplay: "CENPN" },
  { key: "NM_001100818.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55022", geneDisplay: "PID1" },
  { key: "NM_001100829.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100113407", geneDisplay: "TMEM170B" },
  { key: "NM_001100876.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "254295", geneDisplay: "PHYHD1" },
  { key: "NM_001100878.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "642475", geneDisplay: "MROH6" },
  { key: "NM_001100910.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653820", geneDisplay: "FAM72B" },
  { key: "NM_001100913.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23241", geneDisplay: "PACS2" },
  { key: "NM_001100915.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146212", geneDisplay: "KCTD19" },
  { key: "NM_001100916.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "619373", geneDisplay: "MBOAT4" },
  { key: "NM_001100917.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144448", geneDisplay: "TSPAN19" },
  { key: "NM_001101312.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "28959", geneDisplay: "TMEM176B" },
  { key: "NM_001101330.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728819", geneDisplay: "C1GALT1C1L" },
  { key: "NM_001101338.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "169834", geneDisplay: "ZNF883" },
  { key: "NM_001101340.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199800", geneDisplay: "ADM5" },
  { key: "NM_001101362.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390594", geneDisplay: "KBTBD13" },
  { key: "NM_001101372.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "402665", geneDisplay: "IGLON5" },
  { key: "NM_001101376.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440585", geneDisplay: "FAM183A" },
  { key: "NM_001101387.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "644139", geneDisplay: "PIRT" },
  { key: "NM_001101389.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "644672", geneDisplay: "CLDN25" },
  { key: "NM_001101391.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "645191", geneDisplay: "LINGO3" },
  { key: "NM_001101404.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "646892", geneDisplay: "SH2D7" },
  { key: "NM_001101421.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283446", geneDisplay: "MYO1H" },
  { key: "NM_001101426.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "729920", geneDisplay: "CRPPA" },
  { key: "NM_001101648.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29881", geneDisplay: "NPC1L1" },
  { key: "NM_001101662.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4898", geneDisplay: "NRDC" },
  { key: "NM_001101669.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8821", geneDisplay: "INPP4B" },
  { key: "NM_001101677.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "402381", geneDisplay: "SOHLH1" },
  { key: "NM_001102368.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80017", geneDisplay: "DGLUCY" },
  { key: "NM_001102371.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80020", geneDisplay: "FOXRED2" },
  { key: "NM_001102386.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346562", geneDisplay: "GNAT3" },
  { key: "NM_001102401.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80185", geneDisplay: "TTI2" },
  { key: "NM_001102416.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3827", geneDisplay: "KNG1" },
  { key: "NM_001102420.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7763", geneDisplay: "ZFAND5" },
  { key: "NM_001102450.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "85397", geneDisplay: "RGS8" },
  { key: "NM_001102467.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653437", geneDisplay: "AQP12B" },
  { key: "NM_001102469.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "643418", geneDisplay: "LIPN" },
  { key: "NM_001102470.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "130", geneDisplay: "ADH6" },
  { key: "NM_001102559.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84513", geneDisplay: "PLPP5" },
  { key: "NM_001102562.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "441061", geneDisplay: "MARCHF11" },
  { key: "NM_001102564.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "112752", geneDisplay: "IFT43" },
  { key: "NM_001102566.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "654790", geneDisplay: "PCP4L1" },
  { key: "NM_001102575.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "112574", geneDisplay: "SNX18" },
  { key: "NM_001102576.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "158511", geneDisplay: "CSAG1" },
  { key: "NM_001102592.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "113802", geneDisplay: "HENMT1" },
  { key: "NM_001102594.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "113878", geneDisplay: "DTX2" },
  { key: "NM_001102597.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125931", geneDisplay: "CEACAM20" },
  { key: "NM_001102601.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126661", geneDisplay: "CCDC163" },
  { key: "NM_001102608.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131873", geneDisplay: "COL6A6" },
  { key: "NM_001102609.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133874", geneDisplay: "C5orf58" },
  { key: "NM_001102614.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "643664", geneDisplay: "SLC35G6" },
  { key: "NM_001102651.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115196", geneDisplay: "ZNF554" },
  { key: "NM_001102654.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4908", geneDisplay: "NTF3" },
  { key: "NM_001102657.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162962", geneDisplay: "ZNF836" },
  { key: "NM_001102659.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200726", geneDisplay: "FAM237A" },
  { key: "NM_001103146.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26058", geneDisplay: "GIGYF2" },
  { key: "NM_001103161.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400745", geneDisplay: "SH2D5" },
  { key: "NM_001103167.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100125288", geneDisplay: "ZGLP1" },
  { key: "NM_001103170.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126767", geneDisplay: "AADACL3" },
  { key: "NM_001104544.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55026", geneDisplay: "TMEM255A" },
  { key: "NM_001104629.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55286", geneDisplay: "C4orf19" },
  { key: "NM_001104631.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5144", geneDisplay: "PDE4D" },
  { key: "NM_001104647.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55186", geneDisplay: "SLC25A36" },
  { key: "NM_001105069.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "348158", geneDisplay: "ACSM2B" },
  { key: "NM_001105079.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64319", geneDisplay: "FBRS" },
  { key: "NM_001105192.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7090", geneDisplay: "TLE3" },
  { key: "NM_001105203.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23623", geneDisplay: "RUSC1" },
  { key: "NM_001105206.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3910", geneDisplay: "LAMA4" },
  { key: "NM_001105244.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5797", geneDisplay: "PTPRM" },
  { key: "NM_001105247.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79798", geneDisplay: "ARMC5" },
  { key: "NM_001105281.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "646486", geneDisplay: "FABP12" },
  { key: "NM_001105519.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339778", geneDisplay: "FAM166C" },
  { key: "NM_001105520.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388327", geneDisplay: "C17orf100" },
  { key: "NM_001105522.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "729384", geneDisplay: "TRIM49D2" },
  { key: "NM_001105528.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "374864", geneDisplay: "CCDC178" },
  { key: "NM_001105539.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "65986", geneDisplay: "ZBTB10" },
  { key: "NM_001105556.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9473", geneDisplay: "THEMIS2" },
  { key: "NM_001105558.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "494551", geneDisplay: "WEE2" },
  { key: "NM_001105562.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10277", geneDisplay: "UBE4B" },
  { key: "NM_001105564.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54535", geneDisplay: "CCHCR1" },
  { key: "NM_001105565.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219537", geneDisplay: "SMTNL1" },
  { key: "NM_001105569.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "343930", geneDisplay: "MSGN1" },
  { key: "NM_001105570.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390916", geneDisplay: "NUDT19" },
  { key: "NM_001105571.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201140", geneDisplay: "DHRS7C" },
  { key: "NM_001105573.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200933", geneDisplay: "FBXO45" },
  { key: "NM_001105574.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340784", geneDisplay: "HMX3" },
  { key: "NM_001105576.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347454", geneDisplay: "SOWAHD" },
  { key: "NM_001105577.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "646625", geneDisplay: "URAD" },
  { key: "NM_001105578.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "256126", geneDisplay: "SYCE2" },
  { key: "NM_001105579.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "646658", geneDisplay: "SYNDIG1L" },
  { key: "NM_001105580.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200959", geneDisplay: "GABRR3" },
  { key: "NM_001105581.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "339291", geneDisplay: "LRRC30" },
  { key: "NM_001105659.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127255", geneDisplay: "LRRIQ3" },
  { key: "NM_001105663.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283927", geneDisplay: "NUDT7" },
  { key: "NM_001105669.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "164118", geneDisplay: "TTC24" },
  { key: "NM_001105677.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "574537", geneDisplay: "UGT2A2" },
  { key: "NM_001109619.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "613227", geneDisplay: "HIGD1C" },
  { key: "NM_001109659.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146378", geneDisplay: "C16orf92" },
  { key: "NM_001109689.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "58500", geneDisplay: "ZNF250" },
  { key: "NM_001109754.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5787", geneDisplay: "PTPRB" },
  { key: "NM_001109763.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146395", geneDisplay: "GSG1L" },
  { key: "NM_001109809.5", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "346171", geneDisplay: "ZFP57" },
  { key: "NM_001109878.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "50945", geneDisplay: "TBX22" },
  { key: "NM_001109977.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "729830", geneDisplay: "FHIP1A" },
  { key: "NM_001110199.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222183", geneDisplay: "SRRM3" },
  { key: "NM_001110213.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5555", geneDisplay: "PRH2" },
  { key: "NM_001110219.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10804", geneDisplay: "GJB6" },
  { key: "NM_001110303.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10868", geneDisplay: "USP20" },
  { key: "NM_001110354.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7784", geneDisplay: "ZP3" },
  { key: "NM_001110533.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163747", geneDisplay: "LEXM" },
  { key: "NM_001110556.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2316", geneDisplay: "FLNA" },
  { key: "NM_001110792.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4204", geneDisplay: "MECP2" },
  { key: "NM_001111020.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6829", geneDisplay: "SUPT5H" },
  { key: "NM_001111067.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90", geneDisplay: "ACVR1" },
  { key: "NM_001111077.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7430", geneDisplay: "EZR" },
  { key: "NM_001111125.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23096", geneDisplay: "IQSEC2" },
  { key: "NM_001111307.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5141", geneDisplay: "PDE4A" },
  { key: "NM_001111319.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "53842", geneDisplay: "CLDN22" },
  { key: "NM_001112704.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "11023", geneDisplay: "VAX1" },
  { key: "NM_001112706.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "85477", geneDisplay: "SCIN" },
  { key: "NM_001112724.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202374", geneDisplay: "STK32A" },
  { key: "NM_001112726.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283638", geneDisplay: "CEP170B" },
  { key: "NM_001112732.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23263", geneDisplay: "MCF2L" },
  { key: "NM_001112734.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "100101467", geneDisplay: "ZSCAN30" },
  { key: "NM_001112741.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "3746", geneDisplay: "KCNC1" },
  { key: "NM_001113207.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100131187", geneDisplay: "TSTD1" },
  { key: "NM_001113226.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22854", geneDisplay: "NTNG1" },
  { key: "NM_001113324.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100134934", geneDisplay: "TEN1" },
  { key: "NM_001113378.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55215", geneDisplay: "FANCI" },
  { key: "NM_001113402.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196394", geneDisplay: "AMN1" },
  { key: "NM_001113407.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8861", geneDisplay: "LDB1" },
  { key: "NM_001113475.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122945", geneDisplay: "NOXRED1" },
  { key: "NM_001113482.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149175", geneDisplay: "MANEAL" },
  { key: "NM_001113490.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "154796", geneDisplay: "AMOT" },
  { key: "NM_001113491.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10801", geneDisplay: "SEPTIN9" },
  { key: "NM_001113498.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161357", geneDisplay: "MDGA2" },
  { key: "NM_001113523.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "165631", geneDisplay: "PARP15" },
  { key: "NM_001113525.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "92822", geneDisplay: "ZNF276" },
  { key: "NM_001113528.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "196074", geneDisplay: "METTL15" },
  { key: "NM_001113541.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "727905", geneDisplay: "SPATA31A5" },
  { key: "NM_001113561.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285671", geneDisplay: "RNF180" },
  { key: "NM_001113567.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388341", geneDisplay: "LRRC75A" },
  { key: "NM_001113575.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51265", geneDisplay: "CDKL3" },
  { key: "NM_001113738.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51326", geneDisplay: "ARL17A" },
  { key: "NM_001114092.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25917", geneDisplay: "THUMPD3" },
  { key: "NM_001114106.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126969", geneDisplay: "SLC44A3" },
  { key: "NM_001114108.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55001", geneDisplay: "TTC22" },
  { key: "NM_001114118.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55421", geneDisplay: "NCBP3" },
  { key: "NM_001114120.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55635", geneDisplay: "DEPDC1" },
  { key: "NM_001114122.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1111", geneDisplay: "CHEK1" },
  { key: "NM_001114123.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2002", geneDisplay: "ELK1" },
  { key: "NM_001114133.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79933", geneDisplay: "SYNPO2L" },
  { key: "NM_001114134.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "2038", geneDisplay: "EPB42" },
  { key: "NM_001114357.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "441054", geneDisplay: "C4orf47" },
  { key: "NM_001114387.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "339967", geneDisplay: "TMPRSS11A" },
  { key: "NM_001114394.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "167153", geneDisplay: "TENT2" },
  { key: "NM_001114403.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100134938", geneDisplay: "UPK3BL1" },
  { key: "NM_001114598.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "554235", geneDisplay: "ASPDH" },
  { key: "NM_001114600.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26099", geneDisplay: "SZRD1" },
  { key: "NM_001114632.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100137047", geneDisplay: "JMJD7" },
  { key: "NM_001114633.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100137049", geneDisplay: "PLA2G4B" },
  { key: "NM_001114734.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132430", geneDisplay: "PABPC4L" },
  { key: "NM_001114748.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339453", geneDisplay: "TMEM240" },
  { key: "NM_001114753.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2022", geneDisplay: "ENG" },
  { key: "NM_001114759.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257101", geneDisplay: "ZNF683" },
  { key: "NM_001114938.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149483", geneDisplay: "CCDC17" },
  { key: "NM_001114974.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342527", geneDisplay: "SMTNL2" },
  { key: "NM_001115016.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55683", geneDisplay: "KANSL3" },
  { key: "NM_001115116.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79998", geneDisplay: "ANKRD53" },
  { key: "NM_001115152.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100131439", geneDisplay: "CD300LD" },
  { key: "NM_001118887.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "285", geneDisplay: "ANGPT2" },
  { key: "NM_001118890.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2745", geneDisplay: "GLRX" },
  { key: "NM_001122630.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1028", geneDisplay: "CDKN1C" },
  { key: "NM_001122636.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50614", geneDisplay: "GALNT9" },
  { key: "NM_001122646.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51252", geneDisplay: "FAM178B" },
  { key: "NM_001122659.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "1910", geneDisplay: "EDNRB" },
  { key: "NM_001122681.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6452", geneDisplay: "SH3BP2" },
  { key: "NM_001122716.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100130613", geneDisplay: "PRR32" },
  { key: "NM_001122752.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5274", geneDisplay: "SERPINI1" },
  { key: "NM_001122764.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5498", geneDisplay: "PPOX" },
  { key: "NM_001122769.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "167691", geneDisplay: "LCA5" },
  { key: "NM_001122770.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84614", geneDisplay: "ZBTB37" },
  { key: "NM_001122772.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "116986", geneDisplay: "AGAP2" },
  { key: "NM_001122779.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79843", geneDisplay: "FAM124B" },
  { key: "NM_001122819.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57576", geneDisplay: "KIF17" },
  { key: "NM_001122838.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222236", geneDisplay: "NAPEPLD" },
  { key: "NM_001122848.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6539", geneDisplay: "SLC6A12" },
  { key: "NM_001122853.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "91977", geneDisplay: "MYOZ3" },
  { key: "NM_001122955.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26580", geneDisplay: "BSCL2" },
  { key: "NM_001122962.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "284759", geneDisplay: "SIRPB2" },
  { key: "NM_001122964.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57223", geneDisplay: "PPP4R3B" },
  { key: "NM_001122965.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126638", geneDisplay: "RPTN" },
  { key: "NM_001123068.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "644591", geneDisplay: "PPIAL4G" },
  { key: "NM_001123168.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "729533", geneDisplay: "FAM72A" },
  { key: "NM_001123225.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "644186", geneDisplay: "SYCE3" },
  { key: "NM_001123329.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22890", geneDisplay: "ZBTB1" },
  { key: "NM_001123363.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "729540", geneDisplay: "RGPD6" },
  { key: "NM_001123364.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "728464", geneDisplay: "METTL24" },
  { key: "NM_001123366.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284293", geneDisplay: "HMSD" },
  { key: "NM_001123375.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653604", geneDisplay: "H3C13" },
  { key: "NM_001123376.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "643236", geneDisplay: "TMEM72" },
  { key: "NM_001123385.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54880", geneDisplay: "BCOR" },
  { key: "NM_001123387.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81872", geneDisplay: "KRTAP2-1" },
  { key: "NM_001123391.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "729873", geneDisplay: "TBC1D3" },
  { key: "NM_001123392.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "729877", geneDisplay: "TBC1D3H" },
  { key: "NM_001123396.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "729230", geneDisplay: "CCR2" },
  { key: "NM_001124758.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124976", geneDisplay: "SPNS2" },
  { key: "NM_001124759.5", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100288801", geneDisplay: "FRG2C" },
  { key: "NM_001124767.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "440957", geneDisplay: "SMIM4" },
  { key: "NM_001126049.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100144748", geneDisplay: "KLLN" },
  { key: "NM_001126063.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100129128", geneDisplay: "KHDC1L" },
  { key: "NM_001126108.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6559", geneDisplay: "SLC12A3" },
  { key: "NM_001126111.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "734", geneDisplay: "OSGIN2" },
  { key: "NM_001126121.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "60386", geneDisplay: "SLC25A19" },
  { key: "NM_001126128.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "60675", geneDisplay: "PROK2" },
  { key: "NM_001126328.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84708", geneDisplay: "LNX1" },
  { key: "NM_001126334.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "653427", geneDisplay: "FOXD4L5" },
  { key: "NM_001126340.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "80228", geneDisplay: "ORAI2" },
  { key: "NM_001127173.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57863", geneDisplay: "CADM3" },
  { key: "NM_001127178.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54872", geneDisplay: "PIGG" },
  { key: "NM_001127198.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "11322", geneDisplay: "TMC6" },
  { key: "NM_001127199.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100132399", geneDisplay: "GAGE12D" },
  { key: "NM_001127202.4", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55795", geneDisplay: "PCID2" },
  { key: "NM_001127208.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54790", geneDisplay: "TET2" },
  { key: "NM_001127211.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57698", geneDisplay: "SHTN1" },
  { key: "NM_001127212.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729447", geneDisplay: "GAGE2A" },
  { key: "NM_001127217.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "4093", geneDisplay: "SMAD9" },
  { key: "NM_001127222.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "773", geneDisplay: "CACNA1A" },
  { key: "NM_001127228.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10951", geneDisplay: "CBX1" },
  { key: "NM_001127255.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199713", geneDisplay: "NLRP7" },
  { key: "NM_001127258.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84439", geneDisplay: "HHIPL1" },
  { key: "NM_001127266.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "92305", geneDisplay: "TMEM129" },
  { key: "NM_001127345.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "729428", geneDisplay: "GAGE12B" },
  { key: "NM_001127358.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57157", geneDisplay: "PHTF2" },
  { key: "NM_001127391.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130540", geneDisplay: "FLACC1" },
  { key: "NM_001127392.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "745", geneDisplay: "MYRF" },
  { key: "NM_001127395.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151194", geneDisplay: "METTL21A" },
  { key: "NM_001127453.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1687", geneDisplay: "GSDME" },
  { key: "NM_001127496.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "81848", geneDisplay: "SPRY4" },
  { key: "NM_001127644.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2554", geneDisplay: "GABRA1" },
  { key: "NM_001127649.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55670", geneDisplay: "PEX26" },
  { key: "NM_001127671.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3977", geneDisplay: "LIFR" },
  { key: "NM_001127715.4", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "134957", geneDisplay: "STXBP5" },
  { key: "NM_001127893.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56971", geneDisplay: "CEACAM19" },
  { key: "NM_001127895.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64377", geneDisplay: "CHST8" },
  { key: "NM_001127898.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1184", geneDisplay: "CLCN5" },
  { key: "NM_001128077.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "337985", geneDisplay: "KRTAP20-3" },
  { key: "NM_001128126.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "11154", geneDisplay: "AP4S1" },
  { key: "NM_001128148.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7037", geneDisplay: "TFRC" },
  { key: "NM_001128159.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55275", geneDisplay: "VPS53" },
  { key: "NM_001128164.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6310", geneDisplay: "ATXN1" },
  { key: "NM_001128174.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7368", geneDisplay: "UGT8" },
  { key: "NM_001128178.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4867", geneDisplay: "NPHP1" },
  { key: "NM_001128202.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387718", geneDisplay: "TEX36" },
  { key: "NM_001128203.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "11145", geneDisplay: "PLAAT3" },
  { key: "NM_001128205.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23213", geneDisplay: "SULF1" },
  { key: "NM_001128212.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151525", geneDisplay: "WDSUB1" },
  { key: "NM_001128214.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200845", geneDisplay: "KCTD6" },
  { key: "NM_001128215.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340654", geneDisplay: "LIPM" },
  { key: "NM_001128219.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9686", geneDisplay: "VGLL4" },
  { key: "NM_001128225.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "91252", geneDisplay: "SLC39A13" },
  { key: "NM_001128228.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286262", geneDisplay: "TPRN" },
  { key: "NM_001128302.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57149", geneDisplay: "LYRM1" },
  { key: "NM_001128326.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100131137", geneDisplay: "BSPH1" },
  { key: "NM_001128423.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "255027", geneDisplay: "MPV17L" },
  { key: "NM_001128424.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "51313", geneDisplay: "GASK1B" },
  { key: "NM_001128431.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "23516", geneDisplay: "SLC39A14" },
  { key: "NM_001128591.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "389362", geneDisplay: "PSMG4" },
  { key: "NM_001128596.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123036", geneDisplay: "TC2N" },
  { key: "NM_001128598.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "100131902", geneDisplay: "KRTAP25-1" },
  { key: "NM_001128600.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "448835", geneDisplay: "LCE6A" },
  { key: "NM_001128618.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138240", geneDisplay: "C9orf57" },
  { key: "NM_001128626.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "56907", geneDisplay: "SPIRE1" },
  { key: "NM_001128633.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150221", geneDisplay: "RIMBP3C" },
  { key: "NM_001128635.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "440804", geneDisplay: "RIMBP3B" },
  { key: "NM_001128636.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "392617", geneDisplay: "ELFN1" },
  { key: "NM_001128831.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "759", geneDisplay: "CA1" },
  { key: "NM_001128833.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57659", geneDisplay: "ZBTB4" },
  { key: "NM_001128840.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "776", geneDisplay: "CACNA1D" },
  { key: "NM_001128917.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10452", geneDisplay: "TOMM40" },
  { key: "NM_001128918.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4140", geneDisplay: "MARK3" },
  { key: "NM_001128922.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2615", geneDisplay: "LRRC32" },
  { key: "NM_001128926.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346653", geneDisplay: "GARIN1A" },
  { key: "NM_001128928.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3628", geneDisplay: "INPP1" },
  { key: "NM_001129729.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25894", geneDisplay: "PLEKHG4" },
  { key: "NM_001129742.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119395", geneDisplay: "CALHM3" },
  { key: "NM_001129778.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "400581", geneDisplay: "GRAPL" },
  { key: "NM_001129820.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "342618", geneDisplay: "SLFN14" },
  { key: "NM_001129828.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389903", geneDisplay: "CSAG3" },
  { key: "NM_001129883.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100169851", geneDisplay: "PATE3" },
  { key: "NM_001129885.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "642843", geneDisplay: "CPSF4L" },
  { key: "NM_001129890.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100130742", geneDisplay: "LRRC69" },
  { key: "NM_001129891.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100131897", geneDisplay: "INSYN2B" },
  { key: "NM_001129898.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55634", geneDisplay: "KRBOX4" },
  { key: "NM_001129908.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "729085", geneDisplay: "GASK1A" },
  { key: "NM_001129979.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100130958", geneDisplay: "SYCE1L" },
  { key: "NM_001129993.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84542", geneDisplay: "SANBR" },
  { key: "NM_001129994.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79047", geneDisplay: "KCTD15" },
  { key: "NM_001129996.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7673", geneDisplay: "ZNF222" },
  { key: "NM_001129998.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387837", geneDisplay: "CLEC12B" },
  { key: "NM_001130003.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132204", geneDisplay: "SYNPR" },
  { key: "NM_001130004.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "87", geneDisplay: "ACTN1" },
  { key: "NM_001130009.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "348654", geneDisplay: "GEN1" },
  { key: "NM_001130011.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83639", geneDisplay: "TEX101" },
  { key: "NM_001130012.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9351", geneDisplay: "SLC9A3R2" },
  { key: "NM_001130016.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "419", geneDisplay: "ART3" },
  { key: "NM_001130021.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "535", geneDisplay: "ATP6V0A1" },
  { key: "NM_001130028.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1198", geneDisplay: "CLK3" },
  { key: "NM_001130031.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54811", geneDisplay: "ZNF562" },
  { key: "NM_001130040.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6464", geneDisplay: "SHC1" },
  { key: "NM_001130045.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "254173", geneDisplay: "TTLL10" },
  { key: "NM_001130053.5", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1936", geneDisplay: "EEF1D" },
  { key: "NM_001130058.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "204962", geneDisplay: "SLC44A5" },
  { key: "NM_001130072.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29924", geneDisplay: "EPN1" },
  { key: "NM_001130082.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5364", geneDisplay: "PLXNB1" },
  { key: "NM_001130083.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84448", geneDisplay: "ABLIM2" },
  { key: "NM_001130100.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3801", geneDisplay: "KIFC3" },
  { key: "NM_001130111.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81926", geneDisplay: "ABHD17A" },
  { key: "NM_001130142.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4013", geneDisplay: "VWA5A" },
  { key: "NM_001130144.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4054", geneDisplay: "LTBP3" },
  { key: "NM_001130145.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10413", geneDisplay: "YAP1" },
  { key: "NM_001130158.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4430", geneDisplay: "MYO1B" },
  { key: "NM_001130173.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4602", geneDisplay: "MYB" },
  { key: "NM_001130182.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55466", geneDisplay: "DNAJA4" },
  { key: "NM_001130404.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "729233", geneDisplay: "PRR20B" },
  { key: "NM_001130405.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "729240", geneDisplay: "PRR20C" },
  { key: "NM_001130406.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "729246", geneDisplay: "PRR20D" },
  { key: "NM_001130407.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "729250", geneDisplay: "PRR20E" },
  { key: "NM_001130413.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6339", geneDisplay: "SCNN1D" },
  { key: "NM_001130438.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "6709", geneDisplay: "SPTAN1" },
  { key: "NM_001130445.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6744", geneDisplay: "ITPRID2" },
  { key: "NM_001130448.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "643338", geneDisplay: "C15orf62" },
  { key: "NM_001130475.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168451", geneDisplay: "THAP5" },
  { key: "NM_001130514.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100170765", geneDisplay: "ERICH4" },
  { key: "NM_001130520.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "7748", geneDisplay: "ZNF195" },
  { key: "NM_001130528.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9043", geneDisplay: "SPAG9" },
  { key: "NM_001130677.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100170841", geneDisplay: "EPOP" },
  { key: "NM_001130682.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2982", geneDisplay: "GUCY1A1" },
  { key: "NM_001130698.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7222", geneDisplay: "TRPC3" },
  { key: "NM_001130700.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26034", geneDisplay: "IPCEF1" },
  { key: "NM_001130711.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387836", geneDisplay: "CLEC2A" },
  { key: "NM_001130823.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1786", geneDisplay: "DNMT1" },
  { key: "NM_001130842.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57335", geneDisplay: "ZNF286A" },
  { key: "NM_001130864.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "114825", geneDisplay: "PWWP2A" },
  { key: "NM_001130913.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124411", geneDisplay: "KRBOX5" },
  { key: "NM_001130915.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284358", geneDisplay: "MAMSTR" },
  { key: "NM_001130917.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11027", geneDisplay: "LILRA2" },
  { key: "NM_001130918.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284076", geneDisplay: "TTLL6" },
  { key: "NM_001130919.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11158", geneDisplay: "RABL2B" },
  { key: "NM_001130924.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199953", geneDisplay: "TMEM201" },
  { key: "NM_001130925.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "30816", geneDisplay: "ERVW-1" },
  { key: "NM_001130929.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "647087", geneDisplay: "STMP1" },
  { key: "NM_001130957.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "257044", geneDisplay: "CATSPERE" },
  { key: "NM_001130963.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23306", geneDisplay: "NEMP1" },
  { key: "NM_001130965.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23353", geneDisplay: "SUN1" },
  { key: "NM_001130969.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26012", geneDisplay: "NSMF" },
  { key: "NM_001130987.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8291", geneDisplay: "DYSF" },
  { key: "NM_001131007.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23240", geneDisplay: "TMEM131L" },
  { key: "NM_001131016.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25792", geneDisplay: "CIZ1" },
  { key: "NM_001131034.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285498", geneDisplay: "RNF212" },
  { key: "NM_001131035.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84240", geneDisplay: "ZCCHC9" },
  { key: "NM_001131066.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "317671", geneDisplay: "RFESD" },
  { key: "NM_001134225.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3631", geneDisplay: "INPP4A" },
  { key: "NM_001134231.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64943", geneDisplay: "NT5DC2" },
  { key: "NM_001134232.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54664", geneDisplay: "TMEM106B" },
  { key: "NM_001134316.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163154", geneDisplay: "PRR22" },
  { key: "NM_001134337.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11237", geneDisplay: "RNF24" },
  { key: "NM_001134363.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282996", geneDisplay: "RBM20" },
  { key: "NM_001134366.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "2572", geneDisplay: "GAD2" },
  { key: "NM_001134375.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54619", geneDisplay: "CCNJ" },
  { key: "NM_001134382.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9922", geneDisplay: "IQSEC1" },
  { key: "NM_001134389.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55146", geneDisplay: "ZDHHC4" },
  { key: "NM_001134398.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "7410", geneDisplay: "VAV2" },
  { key: "NM_001134405.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "154661", geneDisplay: "RUNDC3B" },
  { key: "NM_001134407.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2903", geneDisplay: "GRIN2A" },
  { key: "NM_001134438.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "90102", geneDisplay: "PHLDB2" },
  { key: "NM_001134442.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "91392", geneDisplay: "ZNF502" },
  { key: "NM_001134462.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344022", geneDisplay: "NOTO" },
  { key: "NM_001134479.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55144", geneDisplay: "LRRC8D" },
  { key: "NM_001134647.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "60312", geneDisplay: "AFAP1" },
  { key: "NM_001134651.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "317649", geneDisplay: "EIF4E3" },
  { key: "NM_001134657.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389152", geneDisplay: "PRR23C" },
  { key: "NM_001134658.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "159371", geneDisplay: "SLC35G1" },
  { key: "NM_001134659.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "729627", geneDisplay: "PRR23A" },
  { key: "NM_001134663.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148418", geneDisplay: "SAMD13" },
  { key: "NM_001134665.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "93587", geneDisplay: "TRMT10A" },
  { key: "NM_001134673.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4774", geneDisplay: "NFIA" },
  { key: "NM_001134693.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100128731", geneDisplay: "OST4" },
  { key: "NM_001134707.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1757", geneDisplay: "SARDH" },
  { key: "NM_001134734.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84970", geneDisplay: "C1orf94" },
  { key: "NM_001134745.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80059", geneDisplay: "LRRTM4" },
  { key: "NM_001134793.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219844", geneDisplay: "HYLS1" },
  { key: "NM_001134831.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "54806", geneDisplay: "AHI1" },
  { key: "NM_001134848.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100129792", geneDisplay: "CCDC152" },
  { key: "NM_001134888.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "388015", geneDisplay: "RTL1" },
  { key: "NM_001135022.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84173", geneDisplay: "ELMOD3" },
  { key: "NM_001135032.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84141", geneDisplay: "EVA1A" },
  { key: "NM_001135036.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201158", geneDisplay: "TVP23C" },
  { key: "NM_001135048.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122953", geneDisplay: "JDP2" },
  { key: "NM_001135050.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57549", geneDisplay: "IGSF9" },
  { key: "NM_001135054.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "59307", geneDisplay: "SIGIRR" },
  { key: "NM_001135091.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143662", geneDisplay: "MUC15" },
  { key: "NM_001135106.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "83795", geneDisplay: "KCNK16" },
  { key: "NM_001135113.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147920", geneDisplay: "IGFL2" },
  { key: "NM_001135146.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "64116", geneDisplay: "SLC39A8" },
  { key: "NM_001135155.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8193", geneDisplay: "DPF1" },
  { key: "NM_001135178.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "84307", geneDisplay: "ZNF397" },
  { key: "NM_001135179.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51304", geneDisplay: "ZDHHC3" },
  { key: "NM_001135196.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118461", geneDisplay: "C10orf71" },
  { key: "NM_001135197.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "339834", geneDisplay: "IHO1" },
  { key: "NM_001135217.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10233", geneDisplay: "LRRC23" },
  { key: "NM_001135219.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138429", geneDisplay: "PIP5KL1" },
  { key: "NM_001135254.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5081", geneDisplay: "PAX7" },
  { key: "NM_001135553.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8569", geneDisplay: "MKNK1" },
  { key: "NM_001135556.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1780", geneDisplay: "DYNC1I1" },
  { key: "NM_001135570.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "728568", geneDisplay: "C12orf73" },
  { key: "NM_001135575.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221710", geneDisplay: "SMIM13" },
  { key: "NM_001135580.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100128569", geneDisplay: "C19orf71" },
  { key: "NM_001135586.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134553", geneDisplay: "C5orf24" },
  { key: "NM_001135608.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23092", geneDisplay: "ARHGAP26" },
  { key: "NM_001135629.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129285", geneDisplay: "PPP1R21" },
  { key: "NM_001135635.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "83638", geneDisplay: "C11orf68" },
  { key: "NM_001135638.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8394", geneDisplay: "PIP5K1A" },
  { key: "NM_001135649.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344167", geneDisplay: "FOXI3" },
  { key: "NM_001135651.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5610", geneDisplay: "EIF2AK2" },
  { key: "NM_001135653.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8761", geneDisplay: "PABPC4" },
  { key: "NM_001135655.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "4062", geneDisplay: "LY6H" },
  { key: "NM_001135673.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64225", geneDisplay: "ATL2" },
  { key: "NM_001135674.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "114926", geneDisplay: "SMIM19" },
  { key: "NM_001135686.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "441864", geneDisplay: "TARM1" },
  { key: "NM_001135690.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5179", geneDisplay: "PENK" },
  { key: "NM_001135705.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79777", geneDisplay: "ACBD4" },
  { key: "NM_001135726.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79618", geneDisplay: "HMBOX1" },
  { key: "NM_001135789.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653598", geneDisplay: "PPIAL4C" },
  { key: "NM_001135812.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "58516", geneDisplay: "SINHCAF" },
  { key: "NM_001135863.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "254013", geneDisplay: "ETFBKMT" },
  { key: "NM_001135924.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "221806", geneDisplay: "VWDE" },
  { key: "NM_001135993.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "125488", geneDisplay: "TTC39C" },
  { key: "NM_001135995.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "92552", geneDisplay: "ATXN3L" },
  { key: "NM_001135998.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54539", geneDisplay: "NDUFB11" },
  { key: "NM_001136002.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "730130", geneDisplay: "TMEM229A" },
  { key: "NM_001136018.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2052", geneDisplay: "EPHX1" },
  { key: "NM_001136019.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2217", geneDisplay: "FCGRT" },
  { key: "NM_001136020.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "3382", geneDisplay: "ICA1" },
  { key: "NM_001136023.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4778", geneDisplay: "NFE2" },
  { key: "NM_001136030.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9840", geneDisplay: "TESPA1" },
  { key: "NM_001136032.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11012", geneDisplay: "KLK11" },
  { key: "NM_001136035.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55621", geneDisplay: "TRMT1" },
  { key: "NM_001136044.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "83590", geneDisplay: "TMUB1" },
  { key: "NM_001136046.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84225", geneDisplay: "ZMYND15" },
  { key: "NM_001136049.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "89782", geneDisplay: "LMLN" },
  { key: "NM_001136050.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "115817", geneDisplay: "DHRS1" },
  { key: "NM_001136053.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131601", geneDisplay: "TPRA1" },
  { key: "NM_001136103.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "92293", geneDisplay: "TMEM132C" },
  { key: "NM_001136107.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149281", geneDisplay: "NTMT2" },
  { key: "NM_001136108.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "203069", geneDisplay: "R3HCC1" },
  { key: "NM_001136116.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345462", geneDisplay: "ZNF879" },
  { key: "NM_001136152.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "644974", geneDisplay: "ALG1L2" },
  { key: "NM_001136156.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22847", geneDisplay: "ZNF507" },
  { key: "NM_001136157.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55593", geneDisplay: "OTUD5" },
  { key: "NM_001136180.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "440498", geneDisplay: "HSBP1L1" },
  { key: "NM_001136191.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25959", geneDisplay: "KANK2" },
  { key: "NM_001136193.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22868", geneDisplay: "FASTKD2" },
  { key: "NM_001136200.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119032", geneDisplay: "BORCS7" },
  { key: "NM_001136201.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79763", geneDisplay: "ISOC2" },
  { key: "NM_001136203.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115098", geneDisplay: "CCDC124" },
  { key: "NM_001136213.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23784", geneDisplay: "POTEH" },
  { key: "NM_001136218.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55092", geneDisplay: "TMEM51" },
  { key: "NM_001136219.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2212", geneDisplay: "FCGR2A" },
  { key: "NM_001136223.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55758", geneDisplay: "RCOR3" },
  { key: "NM_001136233.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170067", geneDisplay: "SUPT20HL2" },
  { key: "NM_001136234.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100130302", geneDisplay: "SUPT20HL1" },
  { key: "NM_001136239.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "93166", geneDisplay: "PRDM6" },
  { key: "NM_001136262.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "552889", geneDisplay: "ATXN7L3B" },
  { key: "NM_001136263.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126567", geneDisplay: "C2CD4C" },
  { key: "NM_001136265.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "126917", geneDisplay: "IFFO2" },
  { key: "NM_001136271.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137814", geneDisplay: "NKX2-6" },
  { key: "NM_001136273.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139735", geneDisplay: "ZFP92" },
  { key: "NM_001136472.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9516", geneDisplay: "LITAF" },
  { key: "NM_001136482.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "255809", geneDisplay: "C19orf38" },
  { key: "NM_001136483.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284067", geneDisplay: "CFAP97D1" },
  { key: "NM_001136486.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120146", geneDisplay: "TRIM64" },
  { key: "NM_001136498.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284106", geneDisplay: "CISD3" },
  { key: "NM_001136499.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284371", geneDisplay: "ZNF841" },
  { key: "NM_001136501.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284391", geneDisplay: "ZNF844" },
  { key: "NM_001136503.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284422", geneDisplay: "SMIM24" },
  { key: "NM_001136505.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283847", geneDisplay: "TERB1" },
  { key: "NM_001136506.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283238", geneDisplay: "SLC22A24" },
  { key: "NM_001136507.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284428", geneDisplay: "MBD3L5" },
  { key: "NM_001136508.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284546", geneDisplay: "C1orf185" },
  { key: "NM_001136509.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283933", geneDisplay: "ZNF843" },
  { key: "NM_001136528.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5270", geneDisplay: "SERPINE2" },
  { key: "NM_001136534.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387890", geneDisplay: "TMEM233" },
  { key: "NM_001136537.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149478", geneDisplay: "BTBD19" },
  { key: "NM_001136561.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440563", geneDisplay: "HNRNPCL2" },
  { key: "NM_001136570.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100129583", geneDisplay: "FAM47E" },
  { key: "NM_001136571.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "646799", geneDisplay: "ZAR1L" },
  { key: "NM_001137548.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "644054", geneDisplay: "FAM25C" },
  { key: "NM_001137549.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100133093", geneDisplay: "FAM25G" },
  { key: "NM_001137550.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9208", geneDisplay: "LRRFIP1" },
  { key: "NM_001137560.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "441151", geneDisplay: "TMEM151B" },
  { key: "NM_001137601.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "100128927", geneDisplay: "ZBTB42" },
  { key: "NM_001137608.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "654254", geneDisplay: "ZNF732" },
  { key: "NM_001137610.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "653333", geneDisplay: "FAM86B2" },
  { key: "NM_001137667.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9994", geneDisplay: "CASP8AP2" },
  { key: "NM_001137669.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "353299", geneDisplay: "RGSL1" },
  { key: "NM_001137671.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "388468", geneDisplay: "POTEC" },
  { key: "NM_001137674.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344787", geneDisplay: "ZNF860" },
  { key: "NM_001137675.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "342371", geneDisplay: "ATXN1L" },
  { key: "NM_001139442.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158135", geneDisplay: "TTLL11" },
  { key: "NM_001139444.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100128327", geneDisplay: "TRAPPC3L" },
  { key: "NM_001139456.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136306", geneDisplay: "SVOPL" },
  { key: "NM_001139488.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "25780", geneDisplay: "RASGRP3" },
  { key: "NM_001141917.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "259308", geneDisplay: "FAM205A" },
  { key: "NM_001141919.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7499", geneDisplay: "XG" },
  { key: "NM_001141947.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285331", geneDisplay: "CCDC66" },
  { key: "NM_001141969.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1616", geneDisplay: "DAXX" },
  { key: "NM_001141980.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7158", geneDisplay: "TP53BP1" },
  { key: "NM_001142276.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "334", geneDisplay: "APLP2" },
  { key: "NM_001142286.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79677", geneDisplay: "SMC6" },
  { key: "NM_001142305.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51333", geneDisplay: "ZNF771" },
  { key: "NM_001142308.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340895", geneDisplay: "MALRD1" },
  { key: "NM_001142311.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "92691", geneDisplay: "TMEM169" },
  { key: "NM_001142327.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9988", geneDisplay: "DMTF1" },
  { key: "NM_001142343.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "1240", geneDisplay: "CMKLR1" },
  { key: "NM_001142351.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84620", geneDisplay: "ST6GAL2" },
  { key: "NM_001142395.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5638", geneDisplay: "PRRG1" },
  { key: "NM_001142405.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10650", geneDisplay: "PRELID3A" },
  { key: "NM_001142416.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9255", geneDisplay: "AIMP1" },
  { key: "NM_001142447.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23439", geneDisplay: "ATP1B4" },
  { key: "NM_001142459.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136371", geneDisplay: "ASB10" },
  { key: "NM_001142462.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "116039", geneDisplay: "OSR2" },
  { key: "NM_001142503.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9754", geneDisplay: "STARD8" },
  { key: "NM_001142524.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "80823", geneDisplay: "BHLHB9" },
  { key: "NM_001142551.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22911", geneDisplay: "WDR47" },
  { key: "NM_001142556.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3161", geneDisplay: "HMMR" },
  { key: "NM_001142565.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79869", geneDisplay: "CPSF7" },
  { key: "NM_001142568.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "56987", geneDisplay: "BBX" },
  { key: "NM_001142569.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55765", geneDisplay: "INAVA" },
  { key: "NM_001142572.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79862", geneDisplay: "ZNF669" },
  { key: "NM_001142578.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284323", geneDisplay: "ZNF780A" },
  { key: "NM_001142601.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8877", geneDisplay: "SPHK1" },
  { key: "NM_001142616.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23301", geneDisplay: "EHBP1" },
  { key: "NM_001142633.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23533", geneDisplay: "PIK3R5" },
  { key: "NM_001142640.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57690", geneDisplay: "TNRC6C" },
  { key: "NM_001142644.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80309", geneDisplay: "SPHKAP" },
  { key: "NM_001142645.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100131211", geneDisplay: "NEMP2" },
  { key: "NM_001142651.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54492", geneDisplay: "NEURL1B" },
  { key: "NM_001142684.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9205", geneDisplay: "ZMYM5" },
  { key: "NM_001142699.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1740", geneDisplay: "DLG2" },
  { key: "NM_001142725.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112479", geneDisplay: "ERI2" },
  { key: "NM_001142730.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284252", geneDisplay: "KCTD1" },
  { key: "NM_001142733.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "142686", geneDisplay: "ASB14" },
  { key: "NM_001142749.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222223", geneDisplay: "ELAPOR2" },
  { key: "NM_001142782.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "260425", geneDisplay: "MAGI3" },
  { key: "NM_001142784.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "3590", geneDisplay: "IL11RA" },
  { key: "NM_001142800.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "346007", geneDisplay: "EYS" },
  { key: "NM_001142807.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55289", geneDisplay: "ACOXL" },
  { key: "NM_001142854.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "84221", geneDisplay: "SPATC1L" },
  { key: "NM_001142864.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9780", geneDisplay: "PIEZO1" },
  { key: "NM_001142928.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "65999", geneDisplay: "LRRC61" },
  { key: "NM_001142930.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8539", geneDisplay: "API5" },
  { key: "NM_001142958.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "201456", geneDisplay: "FBXO15" },
  { key: "NM_001142966.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "80000", geneDisplay: "GREB1L" },
  { key: "NM_001143667.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "58486", geneDisplay: "ZBED5" },
  { key: "NM_001143674.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25874", geneDisplay: "MPC2" },
  { key: "NM_001143676.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6446", geneDisplay: "SGK1" },
  { key: "NM_001143685.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "221223", geneDisplay: "CES5A" },
  { key: "NM_001143688.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "115752", geneDisplay: "DIS3L" },
  { key: "NM_001143764.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "93426", geneDisplay: "SYCE1" },
  { key: "NM_001143768.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220929", geneDisplay: "ZNF438" },
  { key: "NM_001143775.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23399", geneDisplay: "CTDNEP1" },
  { key: "NM_001143780.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51629", geneDisplay: "SLC25A39" },
  { key: "NM_001143820.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2113", geneDisplay: "ETS1" },
  { key: "NM_001143830.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2620", geneDisplay: "GAS2" },
  { key: "NM_001143831.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2915", geneDisplay: "GRM5" },
  { key: "NM_001143835.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4798", geneDisplay: "NFRKB" },
  { key: "NM_001143842.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79022", geneDisplay: "TMEM106C" },
  { key: "NM_001143852.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84260", geneDisplay: "TCHP" },
  { key: "NM_001143854.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22895", geneDisplay: "RPH3A" },
  { key: "NM_001143883.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653505", geneDisplay: "PPIAL4A" },
  { key: "NM_001143909.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "729020", geneDisplay: "RPEL1" },
  { key: "NM_001143919.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "1241", geneDisplay: "LTB4R" },
  { key: "NM_001143936.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145853", geneDisplay: "C15orf61" },
  { key: "NM_001143938.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147658", geneDisplay: "ZNF534" },
  { key: "NM_001143942.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221662", geneDisplay: "RBM24" },
  { key: "NM_001143959.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339804", geneDisplay: "C2orf74" },
  { key: "NM_001143962.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388743", geneDisplay: "CAPN8" },
  { key: "NM_001143967.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "388795", geneDisplay: "EFCAB8" },
  { key: "NM_001143968.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "390790", geneDisplay: "ARL5C" },
  { key: "NM_001143975.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "642623", geneDisplay: "UBTFL1" },
  { key: "NM_001143978.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "644353", geneDisplay: "ZCCHC18" },
  { key: "NM_001143980.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "645811", geneDisplay: "CCDC154" },
  { key: "NM_001143981.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "91851", geneDisplay: "CHRDL1" },
  { key: "NM_001143986.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79816", geneDisplay: "TLE6" },
  { key: "NM_001143988.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653149", geneDisplay: "NBPF6" },
  { key: "NM_001143989.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148545", geneDisplay: "NBPF4" },
  { key: "NM_001143992.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55135", geneDisplay: "WRAP53" },
  { key: "NM_001143998.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6397", geneDisplay: "SEC14L1" },
  { key: "NM_001144000.4", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "729092", geneDisplay: "AGAP5" },
  { key: "NM_001144013.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653489", geneDisplay: "RGPD3" },
  { key: "NM_001144032.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "730262", geneDisplay: "PPIAL4E" },
  { key: "NM_001144060.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57224", geneDisplay: "NHSL1" },
  { key: "NM_001144061.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1315", geneDisplay: "COPB1" },
  { key: "NM_001144064.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100129239", geneDisplay: "CXorf51A" },
  { key: "NM_001144068.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "400720", geneDisplay: "ZNF772" },
  { key: "NM_001144072.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "337867", geneDisplay: "UBAC2" },
  { key: "NM_001144074.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55070", geneDisplay: "DET1" },
  { key: "NM_001144382.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23228", geneDisplay: "PLCL2" },
  { key: "NM_001144757.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "6447", geneDisplay: "SCG5" },
  { key: "NM_001144758.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23187", geneDisplay: "PHLDB1" },
  { key: "NM_001144774.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1996", geneDisplay: "ELAVL4" },
  { key: "NM_001144825.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10900", geneDisplay: "RUNDC3A" },
  { key: "NM_001144831.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11331", geneDisplay: "PHB2" },
  { key: "NM_001144869.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387787", geneDisplay: "LIPT2" },
  { key: "NM_001144871.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387804", geneDisplay: "VSTM5" },
  { key: "NM_001144872.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387885", geneDisplay: "CFAP73" },
  { key: "NM_001144874.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399968", geneDisplay: "PATE4" },
  { key: "NM_001144877.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "286205", geneDisplay: "SCAI" },
  { key: "NM_001144887.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4435", geneDisplay: "CITED1" },
  { key: "NM_001144888.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10458", geneDisplay: "BAIAP2" },
  { key: "NM_001144889.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151295", geneDisplay: "SLC23A3" },
  { key: "NM_001144936.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "65998", geneDisplay: "ZFTA" },
  { key: "NM_001144937.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163479", geneDisplay: "FNDC7" },
  { key: "NM_001144950.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284297", geneDisplay: "SSC5D" },
  { key: "NM_001144952.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "54549", geneDisplay: "SDK2" },
  { key: "NM_001144954.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "133491", geneDisplay: "C5orf47" },
  { key: "NM_001144955.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56986", geneDisplay: "DTWD1" },
  { key: "NM_001144956.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284485", geneDisplay: "RIIAD1" },
  { key: "NM_001144958.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84766", geneDisplay: "CRACR2A" },
  { key: "NM_001144960.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389161", geneDisplay: "ANKUB1" },
  { key: "NM_001144967.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23327", geneDisplay: "NEDD4L" },
  { key: "NM_001144978.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "441024", geneDisplay: "MTHFD2L" },
  { key: "NM_001144981.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "728591", geneDisplay: "CCDC169" },
  { key: "NM_001144989.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "730051", geneDisplay: "ZNF814" },
  { key: "NM_001144990.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57495", geneDisplay: "NWD2" },
  { key: "NM_001144994.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "257407", geneDisplay: "C2orf72" },
  { key: "NM_001144995.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "317762", geneDisplay: "CCDC85C" },
  { key: "NM_001145004.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "727832", geneDisplay: "GOLGA6L6" },
  { key: "NM_001145011.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "342346", geneDisplay: "C16orf96" },
  { key: "NM_001145014.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342931", geneDisplay: "RFPL4A" },
  { key: "NM_001145018.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283152", geneDisplay: "CCDC153" },
  { key: "NM_001145020.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "347744", geneDisplay: "C6orf52" },
  { key: "NM_001145024.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "349633", geneDisplay: "PLET1" },
  { key: "NM_001145026.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "374462", geneDisplay: "PTPRQ" },
  { key: "NM_001145028.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342979", geneDisplay: "PALM3" },
  { key: "NM_001145030.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "375337", geneDisplay: "TOPAZ1" },
  { key: "NM_001145033.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387763", geneDisplay: "C11orf96" },
  { key: "NM_001145051.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388946", geneDisplay: "TMEM247" },
  { key: "NM_001145059.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389124", geneDisplay: "IQCF5" },
  { key: "NM_001145064.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "729438", geneDisplay: "CASTOR2" },
  { key: "NM_001145065.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401145", geneDisplay: "CCSER1" },
  { key: "NM_001145073.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389856", geneDisplay: "USP27X" },
  { key: "NM_001145077.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390205", geneDisplay: "LRRC10B" },
  { key: "NM_001145080.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284071", geneDisplay: "MEIOC" },
  { key: "NM_001145093.4", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285267", geneDisplay: "ZNF619" },
  { key: "NM_001145095.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10086", geneDisplay: "HHLA1" },
  { key: "NM_001145101.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643376", geneDisplay: "BTBD18" },
  { key: "NM_001145108.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4753", geneDisplay: "NELL2" },
  { key: "NM_001145113.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "255275", geneDisplay: "MYADML2" },
  { key: "NM_001145115.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "648791", geneDisplay: "PPP1R3G" },
  { key: "NM_001145118.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "392862", geneDisplay: "GRID2IP" },
  { key: "NM_001145122.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "440854", geneDisplay: "CAPN14" },
  { key: "NM_001145124.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "441452", geneDisplay: "SPATA31C1" },
  { key: "NM_001145127.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "645027", geneDisplay: "EVPLL" },
  { key: "NM_001145128.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221264", geneDisplay: "AK9" },
  { key: "NM_001145132.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100190949", geneDisplay: "C5orf52" },
  { key: "NM_001145139.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100132994", geneDisplay: "CXorf49B" },
  { key: "NM_001145140.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100130361", geneDisplay: "CXorf49" },
  { key: "NM_001145143.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200909", geneDisplay: "HTR3D" },
  { key: "NM_001145161.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134111", geneDisplay: "UBE2QL1" },
  { key: "NM_001145165.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83475", geneDisplay: "DOHH" },
  { key: "NM_001145176.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729956", geneDisplay: "SHISA7" },
  { key: "NM_001145191.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285550", geneDisplay: "FAM200B" },
  { key: "NM_001145194.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "642597", geneDisplay: "AKAIN1" },
  { key: "NM_001145195.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "221074", geneDisplay: "SLC39A12" },
  { key: "NM_001145196.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389730", geneDisplay: "SPATA31A6" },
  { key: "NM_001145197.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389761", geneDisplay: "SPATA31D4" },
  { key: "NM_001145199.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "387882", geneDisplay: "C12orf75" },
  { key: "NM_001145204.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "729993", geneDisplay: "SHISA9" },
  { key: "NM_001145206.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85379", geneDisplay: "KIAA1671" },
  { key: "NM_001145210.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "441869", geneDisplay: "ANKRD65" },
  { key: "NM_001145224.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653643", geneDisplay: "GOLGA6D" },
  { key: "NM_001145250.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100131390", geneDisplay: "SP9" },
  { key: "NM_001145252.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5199", geneDisplay: "CFP" },
  { key: "NM_001145263.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8031", geneDisplay: "NCOA4" },
  { key: "NM_001145268.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222234", geneDisplay: "FAM185A" },
  { key: "NM_001145290.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219855", geneDisplay: "SLC37A2" },
  { key: "NM_001145304.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80726", geneDisplay: "IQCN" },
  { key: "NM_001145306.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1021", geneDisplay: "CDK6" },
  { key: "NM_001145312.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2117", geneDisplay: "ETV3" },
  { key: "NM_001145313.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "83856", geneDisplay: "FSD1L" },
  { key: "NM_001145315.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79980", geneDisplay: "DSN1" },
  { key: "NM_001145319.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5357", geneDisplay: "PLS1" },
  { key: "NM_001145344.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84924", geneDisplay: "ZNF566" },
  { key: "NM_001145346.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139804", geneDisplay: "RBMXL3" },
  { key: "NM_001145347.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79177", geneDisplay: "ZNF576" },
  { key: "NM_001145350.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140873", geneDisplay: "C20orf173" },
  { key: "NM_001145358.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "25942", geneDisplay: "SIN3A" },
  { key: "NM_001145365.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22834", geneDisplay: "ZNF652" },
  { key: "NM_001145373.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220213", geneDisplay: "OTUD1" },
  { key: "NM_001145374.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121642", geneDisplay: "ALKBH2" },
  { key: "NM_001145400.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "161931", geneDisplay: "ADAD2" },
  { key: "NM_001145402.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284418", geneDisplay: "GARIN5B" },
  { key: "NM_001145418.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23331", geneDisplay: "TTC28" },
  { key: "NM_001145432.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "389203", geneDisplay: "SMIM20" },
  { key: "NM_001145434.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "400713", geneDisplay: "ZNF880" },
  { key: "NM_001145440.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441250", geneDisplay: "TYW1B" },
  { key: "NM_001145442.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "641455", geneDisplay: "POTEM" },
  { key: "NM_001145450.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "729967", geneDisplay: "MORN2" },
  { key: "NM_001145451.5", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100271715", geneDisplay: "ARHGEF33" },
  { key: "NM_001145465.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "360030", geneDisplay: "NANOGNB" },
  { key: "NM_001145474.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "374973", geneDisplay: "TEX38" },
  { key: "NM_001145475.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121006", geneDisplay: "FAM186A" },
  { key: "NM_001145536.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100130311", geneDisplay: "C17orf107" },
  { key: "NM_001145543.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "65982", geneDisplay: "ZSCAN18" },
  { key: "NM_001145545.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "162083", geneDisplay: "C16orf82" },
  { key: "NM_001145636.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339541", geneDisplay: "ARMH1" },
  { key: "NM_001145638.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26086", geneDisplay: "GPSM1" },
  { key: "NM_001145641.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100170229", geneDisplay: "SRRM5" },
  { key: "NM_001145643.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "644844", geneDisplay: "PHGR1" },
  { key: "NM_001145648.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "5923", geneDisplay: "RASGRF1" },
  { key: "NM_001145652.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135398", geneDisplay: "C6orf141" },
  { key: "NM_001145659.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "643854", geneDisplay: "CTAGE9" },
  { key: "NM_001145664.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "731220", geneDisplay: "RFX8" },
  { key: "NM_001145667.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "2734", geneDisplay: "GLG1" },
  { key: "NM_001145668.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "399697", geneDisplay: "CTXN2" },
  { key: "NM_001145678.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285600", geneDisplay: "KIAA0825" },
  { key: "NM_001145710.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375190", geneDisplay: "FAM228B" },
  { key: "NM_001145712.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "389493", geneDisplay: "NUPR2" },
  { key: "NM_001145715.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "402569", geneDisplay: "KPNA7" },
  { key: "NM_001145718.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "643311", geneDisplay: "CT47B1" },
  { key: "NM_001145720.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728116", geneDisplay: "ZBTB8B" },
  { key: "NM_001145728.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "160492", geneDisplay: "LMNTD1" },
  { key: "NM_001145784.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729991", geneDisplay: "BORCS8" },
  { key: "NM_001145785.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100271849", geneDisplay: "MEF2B" },
  { key: "NM_001145805.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345611", geneDisplay: "IRGM" },
  { key: "NM_001145809.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79784", geneDisplay: "MYH14" },
  { key: "NM_001145860.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10940", geneDisplay: "POP1" },
  { key: "NM_001145862.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10903", geneDisplay: "MTMR11" },
  { key: "NM_001145971.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "112724", geneDisplay: "RDH13" },
  { key: "NM_001146028.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84502", geneDisplay: "JPH4" },
  { key: "NM_001146033.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390084", geneDisplay: "OR56A5" },
  { key: "NM_001146041.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100132386", geneDisplay: "KRTAP4-9" },
  { key: "NM_001146069.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10227", geneDisplay: "MFSD10" },
  { key: "NM_001146070.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "81550", geneDisplay: "TDRD3" },
  { key: "NM_001146079.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "23562", geneDisplay: "CLDN14" },
  { key: "NM_001146105.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "83666", geneDisplay: "PARP9" },
  { key: "NM_001146108.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "22949", geneDisplay: "PTGR1" },
  { key: "NM_001146154.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145482", geneDisplay: "PTGR2" },
  { key: "NM_001146156.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2932", geneDisplay: "GSK3B" },
  { key: "NM_001146157.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "643161", geneDisplay: "FAM25A" },
  { key: "NM_001146162.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390231", geneDisplay: "TRIM77" },
  { key: "NM_001146175.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84330", geneDisplay: "ZNF414" },
  { key: "NM_001146182.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100505753", geneDisplay: "KRTAP16-1" },
  { key: "NM_001146197.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "643677", geneDisplay: "CCDC168" },
  { key: "NM_001146210.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "729597", geneDisplay: "SPDYE6" },
  { key: "NM_001146213.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "64786", geneDisplay: "TBC1D15" },
  { key: "NM_001146221.5", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "100287284", geneDisplay: "MANSC4" },
  { key: "NM_001146262.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "255928", geneDisplay: "SYT14" },
  { key: "NM_001146267.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "54329", geneDisplay: "GPR85" },
  { key: "NM_001146312.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "93649", geneDisplay: "MYOCD" },
  { key: "NM_001146314.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84836", geneDisplay: "ABHD14B" },
  { key: "NM_001146334.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23148", geneDisplay: "NACAD" },
  { key: "NM_001146336.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283953", geneDisplay: "TMEM114" },
  { key: "NM_001146339.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342865", geneDisplay: "VSTM2B" },
  { key: "NM_001146340.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "390010", geneDisplay: "NKX1-2" },
  { key: "NM_001146341.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390616", geneDisplay: "ANKRD34C" },
  { key: "NM_001146344.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440560", geneDisplay: "PRAMEF11" },
  { key: "NM_001146684.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "643904", geneDisplay: "RNF222" },
  { key: "NM_001146685.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "643965", geneDisplay: "TMEM88B" },
  { key: "NM_001146686.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "647309", geneDisplay: "GMNC" },
  { key: "NM_001146729.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117245", geneDisplay: "PLAAT5" },
  { key: "NM_001153484.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "60592", geneDisplay: "SCOC" },
  { key: "NM_001156474.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "60494", geneDisplay: "CCDC81" },
  { key: "NM_001159279.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441234", geneDisplay: "ZNF716" },
  { key: "NM_001159293.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100129842", geneDisplay: "ZNF737" },
  { key: "NM_001159377.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64779", geneDisplay: "MTHFSD" },
  { key: "NM_001159387.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124872", geneDisplay: "B4GALNT2" },
  { key: "NM_001159522.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "442319", geneDisplay: "ZNF727" },
  { key: "NM_001159524.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "730291", geneDisplay: "ZNF735" },
  { key: "NM_001159542.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5462", geneDisplay: "POU5F1B" },
  { key: "NM_001159699.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2273", geneDisplay: "FHL1" },
  { key: "NM_001159773.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124583", geneDisplay: "CANT1" },
  { key: "NM_001159944.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115704", geneDisplay: "EVI5L" },
  { key: "NM_001160148.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "80821", geneDisplay: "DDHD1" },
  { key: "NM_001160167.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79899", geneDisplay: "PRR5L" },
  { key: "NM_001160305.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79918", geneDisplay: "SETD6" },
  { key: "NM_001160308.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "83852", geneDisplay: "SETDB2" },
  { key: "NM_001160325.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128366", geneDisplay: "OR6P1" },
  { key: "NM_001160329.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84258", geneDisplay: "SYT3" },
  { key: "NM_001160372.4", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "83696", geneDisplay: "TRAPPC9" },
  { key: "NM_001161346.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55743", geneDisplay: "CHFR" },
  { key: "NM_001161352.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "3778", geneDisplay: "KCNMA1" },
  { key: "NM_001161376.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221545", geneDisplay: "C6orf136" },
  { key: "NM_001161403.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55679", geneDisplay: "LIMS2" },
  { key: "NM_001161417.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2840", geneDisplay: "GPR17" },
  { key: "NM_001161425.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162963", geneDisplay: "ZNF610" },
  { key: "NM_001161476.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79446", geneDisplay: "WDR25" },
  { key: "NM_001161498.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "400224", geneDisplay: "PLEKHD1" },
  { key: "NM_001161499.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "81856", geneDisplay: "ZNF611" },
  { key: "NM_001161528.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "401387", geneDisplay: "LRRD1" },
  { key: "NM_001161546.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389333", geneDisplay: "PROB1" },
  { key: "NM_001161586.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10873", geneDisplay: "ME3" },
  { key: "NM_001161625.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158046", geneDisplay: "NXNL2" },
  { key: "NM_001161630.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390245", geneDisplay: "KDM4E" },
  { key: "NM_001161748.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3982", geneDisplay: "LIM2" },
  { key: "NM_001161834.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100130988", geneDisplay: "SPATA48" },
  { key: "NM_001162371.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "728392", geneDisplay: "LOC728392" },
  { key: "NM_001162383.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9181", geneDisplay: "ARHGEF2" },
  { key: "NM_001162435.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100287718", geneDisplay: "ANKRD66" },
  { key: "NM_001162491.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "392509", geneDisplay: "ARL13A" },
  { key: "NM_001162495.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "56245", geneDisplay: "C21orf62" },
  { key: "NM_001162498.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10161", geneDisplay: "LPAR6" },
  { key: "NM_001162499.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23066", geneDisplay: "CAND2" },
  { key: "NM_001162501.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23112", geneDisplay: "TNRC6B" },
  { key: "NM_001162529.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57579", geneDisplay: "FAM135A" },
  { key: "NM_001162530.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79729", geneDisplay: "SH3D21" },
  { key: "NM_001162536.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "494115", geneDisplay: "RBMXL1" },
  { key: "NM_001162914.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100130274", geneDisplay: "CCDC166" },
  { key: "NM_001162936.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100132963", geneDisplay: "SMIM9" },
  { key: "NM_001162995.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "643008", geneDisplay: "SMIM5" },
  { key: "NM_001162997.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100130933", geneDisplay: "SMIM6" },
  { key: "NM_001163075.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100141515", geneDisplay: "C17orf99" },
  { key: "NM_001163278.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10178", geneDisplay: "TENM1" },
  { key: "NM_001163315.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64839", geneDisplay: "FBXL17" },
  { key: "NM_001163321.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90060", geneDisplay: "CCDC120" },
  { key: "NM_001163391.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9753", geneDisplay: "ZSCAN12" },
  { key: "NM_001163424.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150678", geneDisplay: "COPS9" },
  { key: "NM_001163435.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "93627", geneDisplay: "TBCK" },
  { key: "NM_001163438.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "644538", geneDisplay: "SMIM10" },
  { key: "NM_001163560.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "254528", geneDisplay: "MEIOB" },
  { key: "NM_001163561.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285051", geneDisplay: "STPG4" },
  { key: "NM_001163629.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80133", geneDisplay: "MROH9" },
  { key: "NM_001163678.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6474", geneDisplay: "SHOX2" },
  { key: "NM_001163692.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390595", geneDisplay: "UBAP1L" },
  { key: "NM_001163735.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80179", geneDisplay: "MYO19" },
  { key: "NM_001163788.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9991", geneDisplay: "PTBP3" },
  { key: "NM_001163809.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124997", geneDisplay: "WDR81" },
  { key: "NM_001163857.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "339669", geneDisplay: "TEX33" },
  { key: "NM_001163922.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147645", geneDisplay: "VSIG10L" },
  { key: "NM_001163941.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "340273", geneDisplay: "ABCB5" },
  { key: "NM_001164094.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50813", geneDisplay: "COPS7A" },
  { key: "NM_001164161.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55291", geneDisplay: "PPP6R3" },
  { key: "NM_001164211.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23143", geneDisplay: "LRCH1" },
  { key: "NM_001164257.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92340", geneDisplay: "PRR29" },
  { key: "NM_001164261.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645142", geneDisplay: "PPIAL4D" },
  { key: "NM_001164262.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728945", geneDisplay: "PPIAL4F" },
  { key: "NM_001164273.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23269", geneDisplay: "MGA" },
  { key: "NM_001164277.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2542", geneDisplay: "SLC37A4" },
  { key: "NM_001164310.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "730112", geneDisplay: "FAM166B" },
  { key: "NM_001164375.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "414152", geneDisplay: "C10orf105" },
  { key: "NM_001164377.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "386746", geneDisplay: "MRGPRG" },
  { key: "NM_001164379.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399888", geneDisplay: "FAM180B" },
  { key: "NM_001164380.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "27067", geneDisplay: "STAU2" },
  { key: "NM_001164397.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "642446", geneDisplay: "TRIM64B" },
  { key: "NM_001164399.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "729665", geneDisplay: "CCDC175" },
  { key: "NM_001164404.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653641", geneDisplay: "GOLGA6C" },
  { key: "NM_001164405.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "727857", geneDisplay: "BHLHA9" },
  { key: "NM_001164407.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "727910", geneDisplay: "TLCD2" },
  { key: "NM_001164415.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100130086", geneDisplay: "HSFX2" },
  { key: "NM_001164417.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "727837", geneDisplay: "SSX2B" },
  { key: "NM_001164419.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "653656", geneDisplay: "MBD3L4" },
  { key: "NM_001164425.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "653657", geneDisplay: "MBD3L3" },
  { key: "NM_001164431.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "343578", geneDisplay: "ARHGAP40" },
  { key: "NM_001164434.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "100288287", geneDisplay: "KRTAP22-2" },
  { key: "NM_001164435.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "100288323", geneDisplay: "KRTAP21-3" },
  { key: "NM_001164436.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "389177", geneDisplay: "TMEM212" },
  { key: "NM_001164440.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "651746", geneDisplay: "ANKRD33B" },
  { key: "NM_001164442.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100132916", geneDisplay: "SHISAL2B" },
  { key: "NM_001164444.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "646019", geneDisplay: "CBY3" },
  { key: "NM_001164446.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "647024", geneDisplay: "C6orf132" },
  { key: "NM_001164447.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441328", geneDisplay: "FAM90A10" },
  { key: "NM_001164448.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441327", geneDisplay: "FAM90A9" },
  { key: "NM_001164449.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "728753", geneDisplay: "FAM90A19" },
  { key: "NM_001164450.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441324", geneDisplay: "FAM90A8" },
  { key: "NM_001164451.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441326", geneDisplay: "FAM90A18" },
  { key: "NM_001164452.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "645651", geneDisplay: "FAM90A14" },
  { key: "NM_001164457.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100131980", geneDisplay: "ZNF705G" },
  { key: "NM_001164458.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "653857", geneDisplay: "ACTR3C" },
  { key: "NM_001164462.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10071", geneDisplay: "MUC12" },
  { key: "NM_001164463.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "727851", geneDisplay: "RGPD8" },
  { key: "NM_001164464.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653192", geneDisplay: "TRIM43B" },
  { key: "NM_001164465.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "647042", geneDisplay: "GOLGA6L10" },
  { key: "NM_001164471.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "728395", geneDisplay: "TSPY4" },
  { key: "NM_001164473.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54874", geneDisplay: "FNBP1L" },
  { key: "NM_001164478.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "401207", geneDisplay: "C5orf63" },
  { key: "NM_001164484.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "170370", geneDisplay: "FAM170B" },
  { key: "NM_001164496.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55779", geneDisplay: "CFAP44" },
  { key: "NM_001164508.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4703", geneDisplay: "NEB" },
  { key: "NM_001164522.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "338382", geneDisplay: "RAB7B" },
  { key: "NM_001164579.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "730094", geneDisplay: "MOSMO" },
  { key: "NM_001164586.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91156", geneDisplay: "IGFN1" },
  { key: "NM_001164595.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29951", geneDisplay: "PDZRN4" },
  { key: "NM_001164664.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "375449", geneDisplay: "MAST4" },
  { key: "NM_001164665.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57670", geneDisplay: "KIAA1549" },
  { key: "NM_001164688.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343035", geneDisplay: "RD3" },
  { key: "NM_001164749.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64067", geneDisplay: "NPAS3" },
  { key: "NM_001164760.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5575", geneDisplay: "PRKAR1B" },
  { key: "NM_001164811.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "100303755", geneDisplay: "PET117" },
  { key: "NM_001164839.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "115795", geneDisplay: "FANCD2OS" },
  { key: "NM_001165252.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "730755", geneDisplay: "KRTAP2-3" },
  { key: "NM_001165877.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "267020", geneDisplay: "ATP5MGL" },
  { key: "NM_001165958.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55876", geneDisplay: "GSDMB" },
  { key: "NM_001165963.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6323", geneDisplay: "SCN1A" },
  { key: "NM_001165967.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84667", geneDisplay: "HES7" },
  { key: "NM_001165978.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150696", geneDisplay: "PROM2" },
  { key: "NM_001166034.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374897", geneDisplay: "SBSN" },
  { key: "NM_001166037.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "339324", geneDisplay: "ZNF260" },
  { key: "NM_001166105.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6871", geneDisplay: "TADA2A" },
  { key: "NM_001166108.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23022", geneDisplay: "PALLD" },
  { key: "NM_001166114.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10908", geneDisplay: "PNPLA6" },
  { key: "NM_001166160.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55607", geneDisplay: "PPP1R9A" },
  { key: "NM_001166222.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57571", geneDisplay: "CARNS1" },
  { key: "NM_001166269.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "54930", geneDisplay: "HAUS4" },
  { key: "NM_001166271.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "221178", geneDisplay: "SPATA13" },
  { key: "NM_001166293.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "117178", geneDisplay: "SSX2IP" },
  { key: "NM_001166339.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100310812", geneDisplay: "SPDYE2B" },
  { key: "NM_001166345.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "29969", geneDisplay: "MDFIC" },
  { key: "NM_001166347.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284129", geneDisplay: "SLC26A11" },
  { key: "NM_001166387.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4111", geneDisplay: "MAGEA12" },
  { key: "NM_001166395.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "10164", geneDisplay: "CHST4" },
  { key: "NM_001166412.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "64094", geneDisplay: "SMOC2" },
  { key: "NM_001166692.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100131378", geneDisplay: "C11orf91" },
  { key: "NM_001166693.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4299", geneDisplay: "AFF1" },
  { key: "NM_001166701.4", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90736", geneDisplay: "FAM104B" },
  { key: "NM_001167600.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129807", geneDisplay: "NEU4" },
  { key: "NM_001167608.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84236", geneDisplay: "RHBDD1" },
  { key: "NM_001167670.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "100288797", geneDisplay: "TMEM239" },
  { key: "NM_001167675.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "253559", geneDisplay: "CADM2" },
  { key: "NM_001167676.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100128071", geneDisplay: "FAM229A" },
  { key: "NM_001167740.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64754", geneDisplay: "SMYD3" },
  { key: "NM_001167856.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55206", geneDisplay: "SBNO1" },
  { key: "NM_001167902.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145814", geneDisplay: "PGPEP1L" },
  { key: "NM_001167912.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79674", geneDisplay: "VEPH1" },
  { key: "NM_001168214.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "401097", geneDisplay: "C3orf80" },
  { key: "NM_001168221.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151254", geneDisplay: "C2CD6" },
  { key: "NM_001168235.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166752", geneDisplay: "FREM3" },
  { key: "NM_001168241.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150946", geneDisplay: "GAREM2" },
  { key: "NM_001168243.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401115", geneDisplay: "C4orf48" },
  { key: "NM_001168302.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90293", geneDisplay: "KLHL13" },
  { key: "NM_001168368.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57452", geneDisplay: "GALNT16" },
  { key: "NM_001168465.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "256714", geneDisplay: "MAP7D2" },
  { key: "NM_001168468.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79849", geneDisplay: "PDZD3" },
  { key: "NM_001168474.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54457", geneDisplay: "TAF7L" },
  { key: "NM_001168478.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64860", geneDisplay: "ARMCX5" },
  { key: "NM_001168618.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56912", geneDisplay: "IFT46" },
  { key: "NM_001169126.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126259", geneDisplay: "TMIGD2" },
  { key: "NM_001170331.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347404", geneDisplay: "LANCL3" },
  { key: "NM_001170402.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "166979", geneDisplay: "CDC20B" },
  { key: "NM_001170535.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55210", geneDisplay: "ATAD3A" },
  { key: "NM_001170543.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "192111", geneDisplay: "PGAM5" },
  { key: "NM_001170629.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57680", geneDisplay: "CHD8" },
  { key: "NM_001170631.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83953", geneDisplay: "FCAMR" },
  { key: "NM_001170633.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "115749", geneDisplay: "C12orf56" },
  { key: "NM_001170687.4", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "142678", geneDisplay: "MIB2" },
  { key: "NM_001170692.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285782", geneDisplay: "CAGE1" },
  { key: "NM_001170698.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84690", geneDisplay: "SPATA22" },
  { key: "NM_001170700.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "401124", geneDisplay: "DTHD1" },
  { key: "NM_001170738.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440073", geneDisplay: "IQSEC3" },
  { key: "NM_001170741.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "441457", geneDisplay: "NUTM2G" },
  { key: "NM_001170754.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148345", geneDisplay: "C1orf127" },
  { key: "NM_001170791.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151393", geneDisplay: "RMDN2" },
  { key: "NM_001170795.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51374", geneDisplay: "ATRAID" },
  { key: "NM_001170798.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "729025", geneDisplay: "SLC15A5" },
  { key: "NM_001170820.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "402778", geneDisplay: "IFITM10" },
  { key: "NM_001170880.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56834", geneDisplay: "GPR137" },
  { key: "NM_001170905.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "728927", geneDisplay: "ZNF736" },
  { key: "NM_001171020.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139221", geneDisplay: "PWWP3B" },
  { key: "NM_001171109.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286499", geneDisplay: "FAM133A" },
  { key: "NM_001171155.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100131801", geneDisplay: "PET100" },
  { key: "NM_001171183.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285588", geneDisplay: "EFCAB9" },
  { key: "NM_001171251.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "78995", geneDisplay: "HROB" },
  { key: "NM_001171613.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9581", geneDisplay: "PREPL" },
  { key: "NM_001171797.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286144", geneDisplay: "TRIQK" },
  { key: "NM_001171876.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4168", geneDisplay: "MCF2" },
  { key: "NM_001171907.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "91966", geneDisplay: "EOLA1" },
  { key: "NM_001172173.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80034", geneDisplay: "CSRNP3" },
  { key: "NM_001172223.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81532", geneDisplay: "MOB2" },
  { key: "NM_001172225.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163255", geneDisplay: "ZNF540" },
  { key: "NM_001172303.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84930", geneDisplay: "MASTL" },
  { key: "NM_001172437.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23089", geneDisplay: "PEG10" },
  { key: "NM_001172439.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8909", geneDisplay: "ENDOU" },
  { key: "NM_001172501.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6530", geneDisplay: "SLC6A2" },
  { key: "NM_001172509.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23314", geneDisplay: "SATB2" },
  { key: "NM_001172560.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "6755", geneDisplay: "SSTR5" },
  { key: "NM_001172638.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "643836", geneDisplay: "ZFP62" },
  { key: "NM_001172651.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7730", geneDisplay: "ZNF177" },
  { key: "NM_001172679.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "92595", geneDisplay: "ZNF764" },
  { key: "NM_001172681.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121274", geneDisplay: "ZNF641" },
  { key: "NM_001172690.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126231", geneDisplay: "ZNF573" },
  { key: "NM_001172700.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "134549", geneDisplay: "SHROOM1" },
  { key: "NM_001172773.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147694", geneDisplay: "ZNF548" },
  { key: "NM_001172774.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147991", geneDisplay: "DPY19L3" },
  { key: "NM_001172779.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151827", geneDisplay: "LRRC34" },
  { key: "NM_001173393.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26762", geneDisplay: "HAVCR1" },
  { key: "NM_001173464.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55605", geneDisplay: "KIF21A" },
  { key: "NM_001173467.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121340", geneDisplay: "SP7" },
  { key: "NM_001173523.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "5099", geneDisplay: "PCDH7" },
  { key: "NM_001173524.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6738", geneDisplay: "RO60" },
  { key: "NM_001173990.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51259", geneDisplay: "TMEM216" },
  { key: "NM_001174070.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152877", geneDisplay: "FAM53A" },
  { key: "NM_001174072.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "256987", geneDisplay: "SERINC5" },
  { key: "NM_001174084.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27343", geneDisplay: "POLL" },
  { key: "NM_001174089.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "83959", geneDisplay: "SLC4A11" },
  { key: "NM_001174096.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6935", geneDisplay: "ZEB1" },
  { key: "NM_001174100.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57060", geneDisplay: "PCBP4" },
  { key: "NM_001174103.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124923", geneDisplay: "RSKR" },
  { key: "NM_001174123.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "644041", geneDisplay: "C18orf63" },
  { key: "NM_001174147.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4010", geneDisplay: "LMX1B" },
  { key: "NM_001174150.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200894", geneDisplay: "ARL13B" },
  { key: "NM_001174156.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "142891", geneDisplay: "SAMD8" },
  { key: "NM_001174164.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "401399", geneDisplay: "PRRT4" },
  { key: "NM_001177306.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "5066", geneDisplay: "PAM" },
  { key: "NM_001177316.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "142680", geneDisplay: "SLC34A3" },
  { key: "NM_001177382.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132864", geneDisplay: "CPEB2" },
  { key: "NM_001177479.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139324", geneDisplay: "HDX" },
  { key: "NM_001177519.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100507436", geneDisplay: "MICA" },
  { key: "NM_001177676.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8111", geneDisplay: "GPR68" },
  { key: "NM_001177693.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64283", geneDisplay: "ARHGEF28" },
  { key: "NM_001177701.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11020", geneDisplay: "IFT27" },
  { key: "NM_001177949.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "50511", geneDisplay: "SYCP3" },
  { key: "NM_001178015.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57282", geneDisplay: "SLC4A10" },
  { key: "NM_001178020.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146227", geneDisplay: "BEAN1" },
  { key: "NM_001178089.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285676", geneDisplay: "ZNF454" },
  { key: "NM_001178126.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "100423062", geneDisplay: "IGLL5" },
  { key: "NM_001178139.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7029", geneDisplay: "TFDP2" },
  { key: "NM_001184714.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114836", geneDisplay: "SLAMF6" },
  { key: "NM_001184727.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9737", geneDisplay: "GPRASP1" },
  { key: "NM_001184739.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283629", geneDisplay: "TSSK4" },
  { key: "NM_001184742.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10009", geneDisplay: "ZBTB33" },
  { key: "NM_001184749.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139065", geneDisplay: "SLITRK4" },
  { key: "NM_001184785.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56288", geneDisplay: "PARD3" },
  { key: "NM_001184819.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "54552", geneDisplay: "GNL3L" },
  { key: "NM_001184825.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5669", geneDisplay: "PSG1" },
  { key: "NM_001184880.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57526", geneDisplay: "PCDH19" },
  { key: "NM_001184900.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22900", geneDisplay: "CARD8" },
  { key: "NM_001184924.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "114824", geneDisplay: "PNMA5" },
  { key: "NM_001184940.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "203259", geneDisplay: "FAM219A" },
  { key: "NM_001184970.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "11252", geneDisplay: "PACSIN2" },
  { key: "NM_001184989.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4717", geneDisplay: "NDUFC1" },
  { key: "NM_001185072.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9069", geneDisplay: "CLDN12" },
  { key: "NM_001185106.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "257068", geneDisplay: "PLCXD2" },
  { key: "NM_001185149.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100132463", geneDisplay: "CLDN24" },
  { key: "NM_001185181.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10471", geneDisplay: "PFDN6" },
  { key: "NM_001190228.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "643596", geneDisplay: "RNF224" },
  { key: "NM_001190274.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80204", geneDisplay: "FBXO11" },
  { key: "NM_001190417.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "641339", geneDisplay: "ZNF674" },
  { key: "NM_001190441.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148003", geneDisplay: "LGALS16" },
  { key: "NM_001190460.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "728318", geneDisplay: "KRTAP9-1" },
  { key: "NM_001190462.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643037", geneDisplay: "C11orf97" },
  { key: "NM_001190467.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80164", geneDisplay: "PRR36" },
  { key: "NM_001190479.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100131244", geneDisplay: "ANKRD63" },
  { key: "NM_001190737.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4781", geneDisplay: "NFIB" },
  { key: "NM_001190764.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388564", geneDisplay: "TMEM238" },
  { key: "NM_001190765.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "100431172", geneDisplay: "KLRF2" },
  { key: "NM_001190766.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401236", geneDisplay: "STMND1" },
  { key: "NM_001190787.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345643", geneDisplay: "MCIDAS" },
  { key: "NM_001190790.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "256223", geneDisplay: "CDRT15L2" },
  { key: "NM_001190810.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "642517", geneDisplay: "AGAP9" },
  { key: "NM_001190844.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100130519", geneDisplay: "TMEM221" },
  { key: "NM_001190850.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "4850", geneDisplay: "CNOT4" },
  { key: "NM_001190880.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81888", geneDisplay: "HYI" },
  { key: "NM_001190946.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54540", geneDisplay: "FAM193B" },
  { key: "NM_001190972.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100127983", geneDisplay: "C8orf88" },
  { key: "NM_001191055.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100271846", geneDisplay: "ERVV-2" },
  { key: "NM_001191057.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5137", geneDisplay: "PDE1C" },
  { key: "NM_001191061.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79751", geneDisplay: "SLC25A22" },
  { key: "NM_001193282.4", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "402160", geneDisplay: "CFAP99" },
  { key: "NM_001193308.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84958", geneDisplay: "SYTL1" },
  { key: "NM_001193313.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79783", geneDisplay: "SUGCT" },
  { key: "NM_001193315.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "63894", geneDisplay: "VIPAS39" },
  { key: "NM_001193329.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84909", geneDisplay: "AOPEP" },
  { key: "NM_001193336.4", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "730005", geneDisplay: "SEC14L6" },
  { key: "NM_001193360.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55218", geneDisplay: "EXD2" },
  { key: "NM_001193369.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11083", geneDisplay: "DIDO1" },
  { key: "NM_001193424.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79723", geneDisplay: "SUV39H2" },
  { key: "NM_001193451.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83857", geneDisplay: "TMTC1" },
  { key: "NM_001193457.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25900", geneDisplay: "IFFO1" },
  { key: "NM_001193483.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3987", geneDisplay: "LIMS1" },
  { key: "NM_001193489.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9728", geneDisplay: "SECISBP2L" },
  { key: "NM_001193502.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100129654", geneDisplay: "TCF24" },
  { key: "NM_001193511.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7786", geneDisplay: "MAP3K12" },
  { key: "NM_001193531.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "89894", geneDisplay: "TMEM116" },
  { key: "NM_001193532.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115273", geneDisplay: "RAB42" },
  { key: "NM_001193552.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342892", geneDisplay: "ZNF850" },
  { key: "NM_001193621.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390940", geneDisplay: "PINLYP" },
  { key: "NM_001193623.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147646", geneDisplay: "C19orf84" },
  { key: "NM_001193628.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147670", geneDisplay: "SMIM17" },
  { key: "NM_001193630.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100132396", geneDisplay: "ZNF705B" },
  { key: "NM_001193646.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22809", geneDisplay: "ATF5" },
  { key: "NM_001194958.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100134444", geneDisplay: "KCNJ18" },
  { key: "NM_001194986.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388630", geneDisplay: "TRABD2B" },
  { key: "NM_001194998.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "22995", geneDisplay: "CEP152" },
  { key: "NM_001195076.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342918", geneDisplay: "C19orf81" },
  { key: "NM_001195081.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100288814", geneDisplay: "CLDN34" },
  { key: "NM_001195082.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "647310", geneDisplay: "TEX22" },
  { key: "NM_001195087.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "87769", geneDisplay: "GGACT" },
  { key: "NM_001195124.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100506581", geneDisplay: "C16orf95" },
  { key: "NM_001195129.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "646960", geneDisplay: "PRSS56" },
  { key: "NM_001195131.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100130890", geneDisplay: "TSTD3" },
  { key: "NM_001195135.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "646862", geneDisplay: "RNF225" },
  { key: "NM_001195138.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100507096", geneDisplay: "GIMD1" },
  { key: "NM_001195144.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91526", geneDisplay: "ANKRD44" },
  { key: "NM_001195150.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100130705", geneDisplay: "ATP6V1FNB" },
  { key: "NM_001195156.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100506144", geneDisplay: "TMEM35B" },
  { key: "NM_001195190.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "730159", geneDisplay: "TEX50" },
  { key: "NM_001195215.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163486", geneDisplay: "DENND1B" },
  { key: "NM_001195220.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100289678", geneDisplay: "ZNF783" },
  { key: "NM_001195234.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "642612", geneDisplay: "TRIM49C" },
  { key: "NM_001195243.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100287482", geneDisplay: "SMKR1" },
  { key: "NM_001195248.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54840", geneDisplay: "APTX" },
  { key: "NM_001195256.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100507003", geneDisplay: "GFY" },
  { key: "NM_001195257.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100133267", geneDisplay: "DEFB130B" },
  { key: "NM_001195259.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100507588", geneDisplay: "TGFBR3L" },
  { key: "NM_001195263.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79955", geneDisplay: "PDZD7" },
  { key: "NM_001195272.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100129520", geneDisplay: "TEX13C" },
  { key: "NM_001195278.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100507421", geneDisplay: "TMEM178B" },
  { key: "NM_001195279.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100129480", geneDisplay: "MKRN2OS" },
  { key: "NM_001195280.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100506049", geneDisplay: "LRRC72" },
  { key: "NM_001195305.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "92482", geneDisplay: "BBIP1" },
  { key: "NM_001195427.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6427", geneDisplay: "SRSF2" },
  { key: "NM_001195442.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100132146", geneDisplay: "FAM240A" },
  { key: "NM_001195518.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10367", geneDisplay: "MICU1" },
  { key: "NM_001195520.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "100507055", geneDisplay: "LRCOL1" },
  { key: "NM_001195522.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347411", geneDisplay: "MPC1L" },
  { key: "NM_001195528.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100507050", geneDisplay: "TPBGL" },
  { key: "NM_001195535.4", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100505841", geneDisplay: "LOC100505841" },
  { key: "NM_001195541.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100289187", geneDisplay: "TMEM225B" },
  { key: "NM_001195545.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100505591", geneDisplay: "LRRC3C" },
  { key: "NM_001195553.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1641", geneDisplay: "DCX" },
  { key: "NM_001195571.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "574414", geneDisplay: "PRR9" },
  { key: "NM_001195578.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100329135", geneDisplay: "TRPC5OS" },
  { key: "NM_001195581.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "644100", geneDisplay: "ARL14EPL" },
  { key: "NM_001195597.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100507203", geneDisplay: "SMLR1" },
  { key: "NM_001195605.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100507290", geneDisplay: "ZNF865" },
  { key: "NM_001195626.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8028", geneDisplay: "MLLT10" },
  { key: "NM_001195637.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100500938", geneDisplay: "CCDC179" },
  { key: "NM_001195753.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90326", geneDisplay: "THAP3" },
  { key: "NM_001195755.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "338557", geneDisplay: "FFAR4" },
  { key: "NM_001195833.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126432", geneDisplay: "RINL" },
  { key: "NM_001197026.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84725", geneDisplay: "PLEKHA8" },
  { key: "NM_001197104.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4297", geneDisplay: "KMT2A" },
  { key: "NM_001197184.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2856", geneDisplay: "GPR33" },
  { key: "NM_001197287.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79334", geneDisplay: "OR11H2" },
  { key: "NM_001197293.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "1808", geneDisplay: "DPYSL2" },
  { key: "NM_001197294.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1809", geneDisplay: "DPYSL3" },
  { key: "NM_001198533.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55074", geneDisplay: "OXR1" },
  { key: "NM_001198568.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "196883", geneDisplay: "ADCY4" },
  { key: "NM_001198671.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219902", geneDisplay: "TLCD5" },
  { key: "NM_001198679.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "862", geneDisplay: "RUNX1T1" },
  { key: "NM_001198754.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2793", geneDisplay: "GNGT2" },
  { key: "NM_001198784.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145788", geneDisplay: "PIERCE2" },
  { key: "NM_001198800.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51008", geneDisplay: "ASCC1" },
  { key: "NM_001198934.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "89845", geneDisplay: "ABCC10" },
  { key: "NM_001198950.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23026", geneDisplay: "MYO16" },
  { key: "NM_001198956.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55827", geneDisplay: "DCAF6" },
  { key: "NM_001198961.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55268", geneDisplay: "ECHDC2" },
  { key: "NM_001199013.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "90529", geneDisplay: "STPG1" },
  { key: "NM_001199050.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "93273", geneDisplay: "LEMD1" },
  { key: "NM_001199085.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163589", geneDisplay: "TDRD5" },
  { key: "NM_001199097.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8938", geneDisplay: "BAIAP3" },
  { key: "NM_001199103.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100526794", geneDisplay: "NT5C1B-RDH14" },
  { key: "NM_001199107.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57465", geneDisplay: "TBC1D24" },
  { key: "NM_001199135.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10010", geneDisplay: "TANK" },
  { key: "NM_001199138.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "58484", geneDisplay: "NLRC4" },
  { key: "NM_001199140.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "83607", geneDisplay: "AMMECR1L" },
  { key: "NM_001199161.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10869", geneDisplay: "USP19" },
  { key: "NM_001199165.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201134", geneDisplay: "CEP112" },
  { key: "NM_001199172.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146664", geneDisplay: "MGAT5B" },
  { key: "NM_001199196.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93436", geneDisplay: "ARMC6" },
  { key: "NM_001199198.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55773", geneDisplay: "TBC1D23" },
  { key: "NM_001199201.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79740", geneDisplay: "ZBBX" },
  { key: "NM_001199206.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390243", geneDisplay: "IZUMO1R" },
  { key: "NM_001199215.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344807", geneDisplay: "CD200R1L" },
  { key: "NM_001199233.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100505478", geneDisplay: "TEX48" },
  { key: "NM_001199251.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151648", geneDisplay: "SGO1" },
  { key: "NM_001199260.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163590", geneDisplay: "TOR1AIP2" },
  { key: "NM_001199261.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51377", geneDisplay: "UCHL5" },
  { key: "NM_001199267.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8525", geneDisplay: "DGKZ" },
  { key: "NM_001199280.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3038", geneDisplay: "HAS3" },
  { key: "NM_001199295.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "256051", geneDisplay: "ZNF549" },
  { key: "NM_001199298.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51720", geneDisplay: "UIMC1" },
  { key: "NM_001199324.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284370", geneDisplay: "ZNF615" },
  { key: "NM_001199383.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153830", geneDisplay: "RNF145" },
  { key: "NM_001199397.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4750", geneDisplay: "NEK1" },
  { key: "NM_001199417.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57636", geneDisplay: "ARHGAP23" },
  { key: "NM_001199563.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11149", geneDisplay: "BVES" },
  { key: "NM_001199633.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "64078", geneDisplay: "SLC28A3" },
  { key: "NM_001199706.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23366", geneDisplay: "KIAA0895" },
  { key: "NM_001199753.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126129", geneDisplay: "CPT1C" },
  { key: "NM_001199799.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "286676", geneDisplay: "ILDR1" },
  { key: "NM_001199824.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100130827", geneDisplay: "SBK3" },
  { key: "NM_001199862.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8514", geneDisplay: "KCNAB2" },
  { key: "NM_001199867.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "57787", geneDisplay: "MARK4" },
  { key: "NM_001200016.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "24142", geneDisplay: "NAA80" },
  { key: "NM_001200049.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54777", geneDisplay: "CFAP46" },
  { key: "NM_001201325.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5174", geneDisplay: "PDZK1" },
  { key: "NM_001201338.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6294", geneDisplay: "SAFB" },
  { key: "NM_001201352.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "433", geneDisplay: "ASGR2" },
  { key: "NM_001201363.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9324", geneDisplay: "HMGN3" },
  { key: "NM_001201380.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "728577", geneDisplay: "CNTNAP3B" },
  { key: "NM_001201407.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197320", geneDisplay: "ZNF778" },
  { key: "NM_001201427.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23500", geneDisplay: "DAAM2" },
  { key: "NM_001201539.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "416", geneDisplay: "ARSF" },
  { key: "NM_001201543.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84140", geneDisplay: "FAM161A" },
  { key: "NM_001201550.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10877", geneDisplay: "CFHR4" },
  { key: "NM_001201552.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55565", geneDisplay: "ZNF821" },
  { key: "NM_001202429.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "51676", geneDisplay: "ASB2" },
  { key: "NM_001202438.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26098", geneDisplay: "EDRF1" },
  { key: "NM_001202439.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374383", geneDisplay: "NCR3LG1" },
  { key: "NM_001202457.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "125893", geneDisplay: "ZNF816" },
  { key: "NM_001204077.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9354", geneDisplay: "UBE4A" },
  { key: "NM_001204118.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388512", geneDisplay: "CLEC17A" },
  { key: "NM_001204375.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "4883", geneDisplay: "NPR3" },
  { key: "NM_001204424.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9628", geneDisplay: "RGS6" },
  { key: "NM_001204450.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9236", geneDisplay: "CCPG1" },
  { key: "NM_001204477.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284040", geneDisplay: "CDRT4" },
  { key: "NM_001205019.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2710", geneDisplay: "GK" },
  { key: "NM_001205252.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "401934", geneDisplay: "RNF223" },
  { key: "NM_001205254.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100506658", geneDisplay: "OCLN" },
  { key: "NM_001205259.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "653550", geneDisplay: "TP53TG3C" },
  { key: "NM_001205266.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100289462", geneDisplay: "DEFB4B" },
  { key: "NM_001205272.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100506243", geneDisplay: "KRBOX1" },
  { key: "NM_001205280.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147710", geneDisplay: "IGSF23" },
  { key: "NM_001205293.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "777", geneDisplay: "CACNA1E" },
  { key: "NM_001206427.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84833", geneDisplay: "ATP5MK" },
  { key: "NM_001206626.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283116", geneDisplay: "TRIM49B" },
  { key: "NM_001206631.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "646754", geneDisplay: "TRIM64C" },
  { key: "NM_001206641.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388753", geneDisplay: "COA6" },
  { key: "NM_001206671.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79608", geneDisplay: "RIC3" },
  { key: "NM_001206673.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145447", geneDisplay: "ABHD12B" },
  { key: "NM_001206744.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7173", geneDisplay: "TPO" },
  { key: "NM_001206847.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100507341", geneDisplay: "SMIM18" },
  { key: "NM_001206927.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1769", geneDisplay: "DNAH8" },
  { key: "NM_001206979.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9971", geneDisplay: "NR1H4" },
  { key: "NM_001206998.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "84133", geneDisplay: "ZNRF3" },
  { key: "NM_001206999.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11113", geneDisplay: "CIT" },
  { key: "NM_001207005.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "353355", geneDisplay: "ZNF233" },
  { key: "NM_001207020.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "440829", geneDisplay: "SHISA8" },
  { key: "NM_001207067.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9689", geneDisplay: "BZW1" },
  { key: "NM_001207073.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90050", geneDisplay: "FAM181A" },
  { key: "NM_001214909.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197407", geneDisplay: "ZNF48" },
  { key: "NM_001220484.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "399671", geneDisplay: "HEATR4" },
  { key: "NM_001220500.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2208", geneDisplay: "FCER2" },
  { key: "NM_001242312.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "220108", geneDisplay: "FAM124A" },
  { key: "NM_001242313.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "728229", geneDisplay: "TMEM191B" },
  { key: "NM_001242318.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5150", geneDisplay: "PDE7A" },
  { key: "NM_001242326.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728373", geneDisplay: "USP17L25" },
  { key: "NM_001242327.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728369", geneDisplay: "USP17L24" },
  { key: "NM_001242328.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728379", geneDisplay: "USP17L26" },
  { key: "NM_001242329.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728386", geneDisplay: "USP17L5" },
  { key: "NM_001242330.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728393", geneDisplay: "USP17L27" },
  { key: "NM_001242331.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728400", geneDisplay: "USP17L28" },
  { key: "NM_001242332.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728405", geneDisplay: "USP17L29" },
  { key: "NM_001242394.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "94120", geneDisplay: "SYTL3" },
  { key: "NM_001242409.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "64762", geneDisplay: "GAREM1" },
  { key: "NM_001242472.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "25850", geneDisplay: "ZNF345" },
  { key: "NM_001242481.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "84285", geneDisplay: "EIF1AD" },
  { key: "NM_001242521.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "729059", geneDisplay: "TEX46" },
  { key: "NM_001242532.5", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79157", geneDisplay: "MFSD11" },
  { key: "NM_001242617.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55916", geneDisplay: "NXT2" },
  { key: "NM_001242659.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "643988", geneDisplay: "FNDC10" },
  { key: "NM_001242672.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100287898", geneDisplay: "TTC34" },
  { key: "NM_001242680.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100287226", geneDisplay: "ZNF729" },
  { key: "NM_001242690.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100288413", geneDisplay: "ERVMER34-1" },
  { key: "NM_001242699.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387712", geneDisplay: "ENO4" },
  { key: "NM_001242729.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54848", geneDisplay: "ARHGEF38" },
  { key: "NM_001242739.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51058", geneDisplay: "ZNF691" },
  { key: "NM_001242792.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9892", geneDisplay: "SNAP91" },
  { key: "NM_001242809.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "22881", geneDisplay: "ANKRD6" },
  { key: "NM_001242818.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54849", geneDisplay: "DEF8" },
  { key: "NM_001242835.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "65009", geneDisplay: "NDRG4" },
  { key: "NM_001242850.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "81847", geneDisplay: "RNF146" },
  { key: "NM_001242853.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100129216", geneDisplay: "DEFB131B" },
  { key: "NM_001242868.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "122060", geneDisplay: "SLAIN1" },
  { key: "NM_001242882.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55739", geneDisplay: "NAXD" },
  { key: "NM_001242896.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9681", geneDisplay: "DEPDC5" },
  { key: "NM_001242898.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9701", geneDisplay: "PPP6R2" },
  { key: "NM_001242908.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284654", geneDisplay: "RSPO1" },
  { key: "NM_001242922.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100507170", geneDisplay: "CT47A12" },
  { key: "NM_001242957.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4117", geneDisplay: "MAK" },
  { key: "NM_001243007.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283571", geneDisplay: "PROX2" },
  { key: "NM_001243079.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126520", geneDisplay: "PLK5" },
  { key: "NM_001243133.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114548", geneDisplay: "NLRP3" },
  { key: "NM_001243156.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9013", geneDisplay: "TAF1C" },
  { key: "NM_001243177.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "226", geneDisplay: "ALDOA" },
  { key: "NM_001243212.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "643669", geneDisplay: "CCER2" },
  { key: "NM_001243237.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100130301", geneDisplay: "C8orf89" },
  { key: "NM_001243279.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197322", geneDisplay: "ACSF3" },
  { key: "NM_001243332.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "26470", geneDisplay: "SEZ6L2" },
  { key: "NM_001243351.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51667", geneDisplay: "NUB1" },
  { key: "NM_001243439.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "92521", geneDisplay: "SPECC1" },
  { key: "NM_001243540.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100653515", geneDisplay: "CEP295NL" },
  { key: "NM_001243702.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7541", geneDisplay: "ZBTB14" },
  { key: "NM_001243721.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "728403", geneDisplay: "TSPY8" },
  { key: "NM_001243722.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "729264", geneDisplay: "TP53TG3D" },
  { key: "NM_001243750.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254552", geneDisplay: "NUDT8" },
  { key: "NM_001243787.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23583", geneDisplay: "SMUG1" },
  { key: "NM_001243925.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7867", geneDisplay: "MAPKAPK3" },
  { key: "NM_001244008.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "547", geneDisplay: "KIF1A" },
  { key: "NM_001244014.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196500", geneDisplay: "PIANP" },
  { key: "NM_001244038.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "730087", geneDisplay: "ZNF726" },
  { key: "NM_001244705.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "51380", geneDisplay: "CSAD" },
  { key: "NM_001244710.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2673", geneDisplay: "GFPT1" },
  { key: "NM_001244753.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2215", geneDisplay: "FCGR3B" },
  { key: "NM_001244892.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100133053", geneDisplay: "CXorf51B" },
  { key: "NM_001244926.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9128", geneDisplay: "PRPF4" },
  { key: "NM_001244944.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "261729", geneDisplay: "STEAP2" },
  { key: "NM_001244949.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57678", geneDisplay: "GPAM" },
  { key: "NM_001244950.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9806", geneDisplay: "SPOCK2" },
  { key: "NM_001245002.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4782", geneDisplay: "NFIC" },
  { key: "NM_001245025.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127003", geneDisplay: "CFAP276" },
  { key: "NM_001247997.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6249", geneDisplay: "CLIP1" },
  { key: "NM_001251845.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7220", geneDisplay: "TRPC1" },
  { key: "NM_001251974.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10231", geneDisplay: "RCAN2" },
  { key: "NM_001252024.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4308", geneDisplay: "TRPM1" },
  { key: "NM_001252078.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9958", geneDisplay: "USP15" },
  { key: "NM_001252102.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23046", geneDisplay: "KIF21B" },
  { key: "NM_001252275.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10941", geneDisplay: "UGT2A1" },
  { key: "NM_001252499.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83695", geneDisplay: "RHNO1" },
  { key: "NM_001252507.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56967", geneDisplay: "C14orf132" },
  { key: "NM_001252657.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151649", geneDisplay: "PP2D1" },
  { key: "NM_001253697.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55914", geneDisplay: "ERBIN" },
  { key: "NM_001253772.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148281", geneDisplay: "SYT6" },
  { key: "NM_001253794.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440335", geneDisplay: "SMIM22" },
  { key: "NM_001253829.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138639", geneDisplay: "PTPDC1" },
  { key: "NM_001253845.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79924", geneDisplay: "ADM2" },
  { key: "NM_001253852.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10717", geneDisplay: "AP4B1" },
  { key: "NM_001253875.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80146", geneDisplay: "UXS1" },
  { key: "NM_001254757.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6484", geneDisplay: "ST3GAL4" },
  { key: "NM_001255975.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "440822", geneDisplay: "PIWIL3" },
  { key: "NM_001255978.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148327", geneDisplay: "CREB3L4" },
  { key: "NM_001256007.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "50640", geneDisplay: "PNPLA8" },
  { key: "NM_001256012.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4628", geneDisplay: "MYH10" },
  { key: "NM_001256067.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10811", geneDisplay: "NOXA1" },
  { key: "NM_001256071.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57674", geneDisplay: "RNF213" },
  { key: "NM_001256106.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9398", geneDisplay: "CD101" },
  { key: "NM_001256114.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "431707", geneDisplay: "LHX8" },
  { key: "NM_001256141.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100861412", geneDisplay: "FSBP" },
  { key: "NM_001256155.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100131755", geneDisplay: "ARMCX4" },
  { key: "NM_001256317.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "64699", geneDisplay: "TMPRSS3" },
  { key: "NM_001256378.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79892", geneDisplay: "MCMBP" },
  { key: "NM_001256404.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "163259", geneDisplay: "DENND2C" },
  { key: "NM_001256409.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "115353", geneDisplay: "LRRC42" },
  { key: "NM_001256441.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126526", geneDisplay: "C19orf47" },
  { key: "NM_001256447.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10134", geneDisplay: "BCAP31" },
  { key: "NM_001256455.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "51043", geneDisplay: "ZBTB7B" },
  { key: "NM_001256470.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "54477", geneDisplay: "PLEKHA5" },
  { key: "NM_001256475.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100506564", geneDisplay: "THEGL" },
  { key: "NM_001256538.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "128653", geneDisplay: "C20orf141" },
  { key: "NM_001256545.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84466", geneDisplay: "MEGF10" },
  { key: "NM_001256597.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "492307", geneDisplay: "PPDPFL" },
  { key: "NM_001256613.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285242", geneDisplay: "HTR3E" },
  { key: "NM_001256627.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9024", geneDisplay: "BRSK2" },
  { key: "NM_001256660.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8463", geneDisplay: "TEAD2" },
  { key: "NM_001256686.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283106", geneDisplay: "CSNK2A3" },
  { key: "NM_001256706.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10393", geneDisplay: "ANAPC10" },
  { key: "NM_001256715.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "352909", geneDisplay: "DNAAF3" },
  { key: "NM_001256720.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "728276", geneDisplay: "CLEC19A" },
  { key: "NM_001256748.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51066", geneDisplay: "SSUH2" },
  { key: "NM_001256789.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "778", geneDisplay: "CACNA1F" },
  { key: "NM_001256798.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140688", geneDisplay: "NOL4L" },
  { key: "NM_001256829.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100862671", geneDisplay: "TMEM265" },
  { key: "NM_001256852.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287144", geneDisplay: "USP17L10" },
  { key: "NM_001256853.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287205", geneDisplay: "USP17L12" },
  { key: "NM_001256854.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287178", geneDisplay: "USP17L11" },
  { key: "NM_001256855.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287238", geneDisplay: "USP17L13" },
  { key: "NM_001256857.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287327", geneDisplay: "USP17L17" },
  { key: "NM_001256859.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287364", geneDisplay: "USP17L18" },
  { key: "NM_001256860.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287404", geneDisplay: "USP17L19" },
  { key: "NM_001256861.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287441", geneDisplay: "USP17L20" },
  { key: "NM_001256862.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287478", geneDisplay: "USP17L21" },
  { key: "NM_001256863.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287513", geneDisplay: "USP17L22" },
  { key: "NM_001256864.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9829", geneDisplay: "DNAJC6" },
  { key: "NM_001256867.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "728419", geneDisplay: "USP17L30" },
  { key: "NM_001256869.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "392197", geneDisplay: "USP17L7" },
  { key: "NM_001256871.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "645836", geneDisplay: "USP17L3" },
  { key: "NM_001256872.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "392188", geneDisplay: "USP17L8" },
  { key: "NM_001256873.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "401447", geneDisplay: "USP17L1" },
  { key: "NM_001256874.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "645402", geneDisplay: "USP17L4" },
  { key: "NM_001256875.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55143", geneDisplay: "CDCA8" },
  { key: "NM_001256894.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100288520", geneDisplay: "USP17L15" },
  { key: "NM_001256964.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79714", geneDisplay: "CCDC51" },
  { key: "NM_001257096.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5075", geneDisplay: "PAX1" },
  { key: "NM_001257118.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "834", geneDisplay: "CASP1" },
  { key: "NM_001257180.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6575", geneDisplay: "SLC20A2" },
  { key: "NM_001257268.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "647286", geneDisplay: "RD3L" },
  { key: "NM_001257291.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84679", geneDisplay: "SLC9A7" },
  { key: "NM_001257293.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3187", geneDisplay: "HNRNPH1" },
  { key: "NM_001257305.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "728255", geneDisplay: "KRTAP1-4" },
  { key: "NM_001257308.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "100127206", geneDisplay: "MINAR2" },
  { key: "NM_001257309.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100533177", geneDisplay: "KRTAP29-1" },
  { key: "NM_001257357.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "401258", geneDisplay: "RAB44" },
  { key: "NM_001257359.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201191", geneDisplay: "SAMD14" },
  { key: "NM_001257370.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "197342", geneDisplay: "EME2" },
  { key: "NM_001257964.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "284257", geneDisplay: "BOD1L2" },
  { key: "NM_001257967.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "223075", geneDisplay: "ITPRID1" },
  { key: "NM_001258031.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "619189", geneDisplay: "SERINC4" },
  { key: "NM_001258038.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10252", geneDisplay: "SPRY1" },
  { key: "NM_001258217.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79003", geneDisplay: "MIS12" },
  { key: "NM_001258244.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219854", geneDisplay: "TMEM218" },
  { key: "NM_001258248.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80320", geneDisplay: "SP6" },
  { key: "NM_001258249.2", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "7404", geneDisplay: "UTY" },
  { key: "NM_001258277.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114801", geneDisplay: "TMEM200A" },
  { key: "NM_001258280.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "115560", geneDisplay: "ZNF501" },
  { key: "NM_001258282.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158038", geneDisplay: "LINGO2" },
  { key: "NM_001258283.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26693", geneDisplay: "OR2V1" },
  { key: "NM_001258284.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81466", geneDisplay: "OR2L5" },
  { key: "NM_001258285.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121129", geneDisplay: "OR2AP1" },
  { key: "NM_001258306.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90557", geneDisplay: "CCDC74A" },
  { key: "NM_001258307.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "91409", geneDisplay: "CCDC74B" },
  { key: "NM_001258308.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4839", geneDisplay: "NOP2" },
  { key: "NM_001258311.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79605", geneDisplay: "PGBD5" },
  { key: "NM_001258315.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1894", geneDisplay: "ECT2" },
  { key: "NM_001258345.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "133015", geneDisplay: "PACRGL" },
  { key: "NM_001258374.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58513", geneDisplay: "EPS15L1" },
  { key: "NM_001258392.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "81570", geneDisplay: "CLPB" },
  { key: "NM_001258400.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100528020", geneDisplay: "FAM187A" },
  { key: "NM_001258406.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "730249", geneDisplay: "ACOD1" },
  { key: "NM_001258419.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57689", geneDisplay: "LRRC4C" },
  { key: "NM_001258429.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "54976", geneDisplay: "C20orf27" },
  { key: "NM_001261413.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10540", geneDisplay: "DCTN2" },
  { key: "NM_001261430.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100885848", geneDisplay: "PTGES3L" },
  { key: "NM_001261434.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80755", geneDisplay: "AARSD1" },
  { key: "NM_001261826.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8943", geneDisplay: "AP3D1" },
  { key: "NM_001261833.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8690", geneDisplay: "JRKL" },
  { key: "NM_001261836.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9317", geneDisplay: "PTER" },
  { key: "NM_001261841.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "79838", geneDisplay: "TMC5" },
  { key: "NM_001261843.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9831", geneDisplay: "ZNF623" },
  { key: "NM_001265577.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146909", geneDisplay: "KIF18B" },
  { key: "NM_001265589.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10313", geneDisplay: "RTN3" },
  { key: "NM_001265615.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202134", geneDisplay: "FAM153B" },
  { key: "NM_001267046.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122786", geneDisplay: "FRMD6" },
  { key: "NM_001267052.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146862", geneDisplay: "UNC45B" },
  { key: "NM_001267536.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "643707", geneDisplay: "GOLGA6L4" },
  { key: "NM_001267547.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "10139", geneDisplay: "ARFRP1" },
  { key: "NM_001267550.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7273", geneDisplay: "TTN" },
  { key: "NM_001267560.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27134", geneDisplay: "TJP3" },
  { key: "NM_001267571.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55357", geneDisplay: "TBC1D2" },
  { key: "NM_001267585.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10517", geneDisplay: "FBXW10" },
  { key: "NM_001267594.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29843", geneDisplay: "SENP1" },
  { key: "NM_001267716.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388523", geneDisplay: "ZNF728" },
  { key: "NM_001267723.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729440", geneDisplay: "CCDC61" },
  { key: "NM_001267727.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "22901", geneDisplay: "ARSG" },
  { key: "NM_001267776.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "90410", geneDisplay: "IFT20" },
  { key: "NM_001269053.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143241", geneDisplay: "DYDC1" },
  { key: "NM_001270366.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79948", geneDisplay: "PLPPR3" },
  { key: "NM_001270377.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157567", geneDisplay: "ANKRD46" },
  { key: "NM_001270396.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144321", geneDisplay: "GLIPR1L2" },
  { key: "NM_001270441.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83546", geneDisplay: "RTBDN" },
  { key: "NM_001270454.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "11060", geneDisplay: "WWP2" },
  { key: "NM_001270471.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8835", geneDisplay: "SOCS2" },
  { key: "NM_001270485.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10645", geneDisplay: "CAMKK2" },
  { key: "NM_001270508.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7128", geneDisplay: "TNFAIP3" },
  { key: "NM_001270520.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23002", geneDisplay: "DAAM1" },
  { key: "NM_001270531.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9589", geneDisplay: "WTAP" },
  { key: "NM_001270601.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "647219", geneDisplay: "ASCL5" },
  { key: "NM_001270616.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5629", geneDisplay: "PROX1" },
  { key: "NM_001270623.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9194", geneDisplay: "SLC16A7" },
  { key: "NM_001270639.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "126119", geneDisplay: "JOSD2" },
  { key: "NM_001270764.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51363", geneDisplay: "CHST15" },
  { key: "NM_001270875.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64769", geneDisplay: "MEAF6" },
  { key: "NM_001270891.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79090", geneDisplay: "TRAPPC6A" },
  { key: "NM_001270941.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9832", geneDisplay: "JAKMIP2" },
  { key: "NM_001270960.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "51070", geneDisplay: "NOSIP" },
  { key: "NM_001270974.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54768", geneDisplay: "HYDIN" },
  { key: "NM_001270975.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9798", geneDisplay: "IST1" },
  { key: "NM_001270989.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "255324", geneDisplay: "EPGN" },
  { key: "NM_001271006.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201254", geneDisplay: "CENPX" },
  { key: "NM_001271049.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200373", geneDisplay: "CFAP221" },
  { key: "NM_001271186.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57826", geneDisplay: "RAP2C" },
  { key: "NM_001271458.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120376", geneDisplay: "COLCA2" },
  { key: "NM_001271507.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "56936", geneDisplay: "CCDC177" },
  { key: "NM_001271560.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389895", geneDisplay: "LOC389895" },
  { key: "NM_001271562.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54494", geneDisplay: "C11orf71" },
  { key: "NM_001271592.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "100129361", geneDisplay: "SMIM10L1" },
  { key: "NM_001271639.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7697", geneDisplay: "ZNF138" },
  { key: "NM_001271641.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23787", geneDisplay: "MTCH1" },
  { key: "NM_001271675.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "441155", geneDisplay: "LOC441155" },
  { key: "NM_001271696.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "22", geneDisplay: "ABCB7" },
  { key: "NM_001271700.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100310846", geneDisplay: "ANKRD61" },
  { key: "NM_001271718.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6691", geneDisplay: "SPINK2" },
  { key: "NM_001271749.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "27202", geneDisplay: "C5AR2" },
  { key: "NM_001271752.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347541", geneDisplay: "MAGEB5" },
  { key: "NM_001271783.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55711", geneDisplay: "FAR2" },
  { key: "NM_001271803.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51308", geneDisplay: "REEP2" },
  { key: "NM_001271838.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51319", geneDisplay: "RSRC1" },
  { key: "NM_001271852.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285753", geneDisplay: "CEP57L1" },
  { key: "NM_001271862.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154197", geneDisplay: "PNLDC1" },
  { key: "NM_001271870.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "647135", geneDisplay: "SRGAP2B" },
  { key: "NM_001271874.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "25980", geneDisplay: "AAR2" },
  { key: "NM_001271875.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "404093", geneDisplay: "CUEDC1" },
  { key: "NM_001271893.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "117581", geneDisplay: "TWIST2" },
  { key: "NM_001271907.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124045", geneDisplay: "SPATA33" },
  { key: "NM_001271938.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1954", geneDisplay: "MEGF8" },
  { key: "NM_001271944.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7942", geneDisplay: "TFEB" },
  { key: "NM_001271958.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27173", geneDisplay: "SLC39A1" },
  { key: "NM_001271977.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10494", geneDisplay: "STK25" },
  { key: "NM_001272004.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "80314", geneDisplay: "EPC1" },
  { key: "NM_001272005.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "347741", geneDisplay: "OTOP3" },
  { key: "NM_001272013.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "85450", geneDisplay: "ITPRIP" },
  { key: "NM_001272046.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340706", geneDisplay: "VWA2" },
  { key: "NM_001272051.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283951", geneDisplay: "C16orf91" },
  { key: "NM_001272063.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728239", geneDisplay: "MAGED4" },
  { key: "NM_001272071.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8905", geneDisplay: "AP1S2" },
  { key: "NM_001276252.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23038", geneDisplay: "WDTC1" },
  { key: "NM_001276266.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90527", geneDisplay: "DUOXA1" },
  { key: "NM_001276270.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8930", geneDisplay: "MBD4" },
  { key: "NM_001276277.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23262", geneDisplay: "PPIP5K2" },
  { key: "NM_001276309.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8996", geneDisplay: "NOL3" },
  { key: "NM_001276318.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90673", geneDisplay: "PPP1R3E" },
  { key: "NM_001276343.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "119016", geneDisplay: "AGAP4" },
  { key: "NM_001276345.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7139", geneDisplay: "TNNT2" },
  { key: "NM_001276351.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400757", geneDisplay: "C1orf141" },
  { key: "NM_001276366.4", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389766", geneDisplay: "C9orf153" },
  { key: "NM_001276380.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51575", geneDisplay: "ESF1" },
  { key: "NM_001276451.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "644168", geneDisplay: "DRGX" },
  { key: "NM_001276501.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "63940", geneDisplay: "GPSM3" },
  { key: "NM_001276687.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645745", geneDisplay: "MT1HL1" },
  { key: "NM_001276700.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "171389", geneDisplay: "NLRP6" },
  { key: "NM_001276713.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "728780", geneDisplay: "ANKDD1B" },
  { key: "NM_001277053.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129293", geneDisplay: "TRABD2A" },
  { key: "NM_001277062.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "56947", geneDisplay: "MFF" },
  { key: "NM_001277074.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151903", geneDisplay: "CCDC12" },
  { key: "NM_001277075.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84215", geneDisplay: "ZNF541" },
  { key: "NM_001277083.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653781", geneDisplay: "POTEJ" },
  { key: "NM_001277115.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8701", geneDisplay: "DNAH11" },
  { key: "NM_001277204.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5031", geneDisplay: "P2RY6" },
  { key: "NM_001277285.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "22997", geneDisplay: "IGSF9B" },
  { key: "NM_001277291.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "101060200", geneDisplay: "ZNF891" },
  { key: "NM_001277303.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100287399", geneDisplay: "POTEB2" },
  { key: "NM_001277304.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100996331", geneDisplay: "POTEB" },
  { key: "NM_001277307.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "645864", geneDisplay: "MAGEB17" },
  { key: "NM_001277308.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "728047", geneDisplay: "GOLGA8O" },
  { key: "NM_001277313.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "342184", geneDisplay: "FMN1" },
  { key: "NM_001277325.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100288332", geneDisplay: "NPIPA5" },
  { key: "NM_001277331.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100507608", geneDisplay: "KRTAP9-6" },
  { key: "NM_001277332.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100505724", geneDisplay: "KRTAP9-7" },
  { key: "NM_001277333.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "342850", geneDisplay: "ANKRD62" },
  { key: "NM_001277353.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152586", geneDisplay: "MGAT4D" },
  { key: "NM_001277372.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100652824", geneDisplay: "KIAA2012" },
  { key: "NM_001277378.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "646457", geneDisplay: "C19orf67" },
  { key: "NM_001277397.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729974", geneDisplay: "RFPL4AL1" },
  { key: "NM_001277403.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100129543", geneDisplay: "ZNF730" },
  { key: "NM_001277406.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "653269", geneDisplay: "POTEI" },
  { key: "NM_001277423.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100996648", geneDisplay: "TCP11X2" },
  { key: "NM_001277945.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55769", geneDisplay: "ZNF83" },
  { key: "NM_001278000.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2846", geneDisplay: "LPAR4" },
  { key: "NM_001278063.4", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "652991", geneDisplay: "SKOR2" },
  { key: "NM_001278064.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2911", geneDisplay: "GRM1" },
  { key: "NM_001278116.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "3897", geneDisplay: "L1CAM" },
  { key: "NM_001278141.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149013", geneDisplay: "NBPF12" },
  { key: "NM_001278182.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8320", geneDisplay: "EOMES" },
  { key: "NM_001278212.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55222", geneDisplay: "LRRC20" },
  { key: "NM_001278293.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84100", geneDisplay: "ARL6" },
  { key: "NM_001278298.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "256076", geneDisplay: "COL6A5" },
  { key: "NM_001278309.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10566", geneDisplay: "AKAP3" },
  { key: "NM_001278356.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10818", geneDisplay: "FRS2" },
  { key: "NM_001278392.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729767", geneDisplay: "CEACAM18" },
  { key: "NM_001278426.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "11006", geneDisplay: "LILRB4" },
  { key: "NM_001278431.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114902", geneDisplay: "C1QTNF5" },
  { key: "NM_001278473.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "25884", geneDisplay: "CHRDL2" },
  { key: "NM_001278495.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "729262", geneDisplay: "NUTM2B" },
  { key: "NM_001278507.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100142659", geneDisplay: "CTAGE8" },
  { key: "NM_001278509.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7733", geneDisplay: "ZNF180" },
  { key: "NM_001278512.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8120", geneDisplay: "AP3B2" },
  { key: "NM_001278556.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "10094", geneDisplay: "ARPC3" },
  { key: "NM_001278559.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100131017", geneDisplay: "ZNF316" },
  { key: "NM_001278563.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "136227", geneDisplay: "COL26A1" },
  { key: "NM_001278624.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152518", geneDisplay: "NFXL1" },
  { key: "NM_001278628.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51340", geneDisplay: "CRNKL1" },
  { key: "NM_001278629.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "257169", geneDisplay: "C9orf43" },
  { key: "NM_001278639.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5902", geneDisplay: "RANBP1" },
  { key: "NM_001278669.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "4772", geneDisplay: "NFATC1" },
  { key: "NM_001278688.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "195977", geneDisplay: "ANTXRL" },
  { key: "NM_001278689.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285203", geneDisplay: "EOGT" },
  { key: "NM_001278716.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26235", geneDisplay: "FBXL4" },
  { key: "NM_001278720.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9028", geneDisplay: "RHBDL1" },
  { key: "NM_001278736.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6352", geneDisplay: "CCL5" },
  { key: "NM_001278919.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "81033", geneDisplay: "KCNH6" },
  { key: "NM_001280542.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "8110", geneDisplay: "DPF3" },
  { key: "NM_001280561.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340393", geneDisplay: "TMEM249" },
  { key: "NM_001281293.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23528", geneDisplay: "ZNF281" },
  { key: "NM_001281445.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "11142", geneDisplay: "PKIG" },
  { key: "NM_001281453.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53615", geneDisplay: "MBD3" },
  { key: "NM_001281503.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "114798", geneDisplay: "SLITRK1" },
  { key: "NM_001281740.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "80206", geneDisplay: "FHOD3" },
  { key: "NM_001281747.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "90523", geneDisplay: "MLIP" },
  { key: "NM_001281766.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2044", geneDisplay: "EPHA5" },
  { key: "NM_001281775.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23613", geneDisplay: "ZMYND8" },
  { key: "NM_001281789.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "255919", geneDisplay: "CNEP1R1" },
  { key: "NM_001281956.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114784", geneDisplay: "CSMD2" },
  { key: "NM_001282011.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284417", geneDisplay: "TMEM150B" },
  { key: "NM_001282112.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "8940", geneDisplay: "TOP3B" },
  { key: "NM_001282116.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5991", geneDisplay: "RFX3" },
  { key: "NM_001282129.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85464", geneDisplay: "SSH2" },
  { key: "NM_001282144.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79671", geneDisplay: "NLRX1" },
  { key: "NM_001282195.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9016", geneDisplay: "SLC25A14" },
  { key: "NM_001282201.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57232", geneDisplay: "ZNF630" },
  { key: "NM_001282225.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "51816", geneDisplay: "ADA2" },
  { key: "NM_001282275.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "360205", geneDisplay: "PRAC2" },
  { key: "NM_001282290.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201176", geneDisplay: "ARHGAP27" },
  { key: "NM_001282300.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "646000", geneDisplay: "SLC35G4" },
  { key: "NM_001282322.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "100507650", geneDisplay: "RNF212B" },
  { key: "NM_001282359.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51427", geneDisplay: "ZNF107" },
  { key: "NM_001282426.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5294", geneDisplay: "PIK3CG" },
  { key: "NM_001282430.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "85474", geneDisplay: "LBX2" },
  { key: "NM_001282431.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10123", geneDisplay: "ARL4C" },
  { key: "NM_001282442.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100131303", geneDisplay: "DPEP2NB" },
  { key: "NM_001282448.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100130348", geneDisplay: "TMEM262" },
  { key: "NM_001282460.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "220416", geneDisplay: "LRRC63" },
  { key: "NM_001282468.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653720", geneDisplay: "GOLGA8M" },
  { key: "NM_001282469.3", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "100289087", geneDisplay: "TSPY10" },
  { key: "NM_001282472.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653073", geneDisplay: "GOLGA8J" },
  { key: "NM_001282477.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100505993", geneDisplay: "TMEM210" },
  { key: "NM_001282478.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100133251", geneDisplay: "PRR23D2" },
  { key: "NM_001282479.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "100131608", geneDisplay: "PRR23D1" },
  { key: "NM_001282484.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "101059918", geneDisplay: "GOLGA8R" },
  { key: "NM_001282490.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "728498", geneDisplay: "GOLGA8H" },
  { key: "NM_001282492.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "101928603", geneDisplay: "ARMH2" },
  { key: "NM_001282493.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653125", geneDisplay: "GOLGA8K" },
  { key: "NM_001282494.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "643699", geneDisplay: "GOLGA8N" },
  { key: "NM_001282506.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200958", geneDisplay: "MUC20" },
  { key: "NM_001282507.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "101059938", geneDisplay: "NPIPA7" },
  { key: "NM_001282509.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26526", geneDisplay: "TSPAN16" },
  { key: "NM_001282511.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "101059953", geneDisplay: "NPIPA8" },
  { key: "NM_001282531.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23394", geneDisplay: "ADNP" },
  { key: "NM_001282534.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51305", geneDisplay: "KCNK9" },
  { key: "NM_001282537.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "101928147", geneDisplay: "C21orf140" },
  { key: "NM_001282544.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101927581", geneDisplay: "CCDC182" },
  { key: "NM_001282547.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83931", geneDisplay: "STK40" },
  { key: "NM_001282556.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11148", geneDisplay: "HHLA2" },
  { key: "NM_001282597.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "1496", geneDisplay: "CTNNA2" },
  { key: "NM_001282611.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10439", geneDisplay: "OLFM1" },
  { key: "NM_001282659.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55031", geneDisplay: "USP47" },
  { key: "NM_001282663.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9645", geneDisplay: "MICAL2" },
  { key: "NM_001282680.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "26130", geneDisplay: "GAPVD1" },
  { key: "NM_001282684.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "79734", geneDisplay: "KCTD17" },
  { key: "NM_001282693.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2326", geneDisplay: "FMO1" },
  { key: "NM_001282717.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10734", geneDisplay: "STAG3" },
  { key: "NM_001282771.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51281", geneDisplay: "ANKMY1" },
  { key: "NM_001282788.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84691", geneDisplay: "GARIN1B" },
  { key: "NM_001282805.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "64844", geneDisplay: "MARCHF7" },
  { key: "NM_001282857.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54464", geneDisplay: "XRN1" },
  { key: "NM_001282860.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54856", geneDisplay: "GON4L" },
  { key: "NM_001282866.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "220972", geneDisplay: "MARCHF8" },
  { key: "NM_001282874.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6594", geneDisplay: "SMARCA1" },
  { key: "NM_001282876.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135932", geneDisplay: "TMEM139" },
  { key: "NM_001282933.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "84905", geneDisplay: "ZNF341" },
  { key: "NM_001282957.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "389799", geneDisplay: "CFAP77" },
  { key: "NM_001283009.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "51750", geneDisplay: "RTEL1" },
  { key: "NM_001283041.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "29761", geneDisplay: "USP25" },
  { key: "NM_001284230.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4293", geneDisplay: "MAP3K9" },
  { key: "NM_001284236.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9765", geneDisplay: "ZFYVE16" },
  { key: "NM_001284240.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54462", geneDisplay: "CCSER2" },
  { key: "NM_001284254.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "83606", geneDisplay: "GUCD1" },
  { key: "NM_001284259.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9585", geneDisplay: "KIF20B" },
  { key: "NM_001284285.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27124", geneDisplay: "INPP5J" },
  { key: "NM_001284292.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "256646", geneDisplay: "NUTM1" },
  { key: "NM_001284401.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132001", geneDisplay: "TAMM41" },
  { key: "NM_001284417.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "145748", geneDisplay: "LYSMD4" },
  { key: "NM_001284527.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54925", geneDisplay: "ZSCAN32" },
  { key: "NM_001285485.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "93082", geneDisplay: "NEURL3" },
  { key: "NM_001286045.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10179", geneDisplay: "RBM7" },
  { key: "NM_001286060.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "27314", geneDisplay: "RAB30" },
  { key: "NM_001286069.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "28970", geneDisplay: "C11orf54" },
  { key: "NM_001286077.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51035", geneDisplay: "UBXN1" },
  { key: "NM_001286086.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "102288414", geneDisplay: "C11orf98" },
  { key: "NM_001286123.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10246", geneDisplay: "SLC17A2" },
  { key: "NM_001286134.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "60626", geneDisplay: "RIC8A" },
  { key: "NM_001286159.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "220047", geneDisplay: "CCDC83" },
  { key: "NM_001286176.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "9847", geneDisplay: "C2CD5" },
  { key: "NM_001286201.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79785", geneDisplay: "RERGL" },
  { key: "NM_001286234.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144195", geneDisplay: "SLC2A14" },
  { key: "NM_001286389.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "388021", geneDisplay: "TMEM179" },
  { key: "NM_001286398.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "154214", geneDisplay: "RNF217" },
  { key: "NM_001286401.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221468", geneDisplay: "TMEM217" },
  { key: "NM_001286423.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79411", geneDisplay: "GLB1L" },
  { key: "NM_001286441.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161829", geneDisplay: "EXD1" },
  { key: "NM_001286445.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9750", geneDisplay: "RIPOR2" },
  { key: "NM_001286451.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "374659", geneDisplay: "HDDC3" },
  { key: "NM_001286474.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10665", geneDisplay: "TSBP1" },
  { key: "NM_001286496.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80119", geneDisplay: "PIF1" },
  { key: "NM_001286535.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144715", geneDisplay: "RAD9B" },
  { key: "NM_001286554.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "25862", geneDisplay: "USP49" },
  { key: "NM_001286555.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56940", geneDisplay: "DUSP22" },
  { key: "NM_001286574.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221481", geneDisplay: "ARMC12" },
  { key: "NM_001286577.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26005", geneDisplay: "C2CD3" },
  { key: "NM_001286581.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57661", geneDisplay: "PHRF1" },
  { key: "NM_001286606.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283229", geneDisplay: "CRACR2B" },
  { key: "NM_001286611.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85021", geneDisplay: "REPS1" },
  { key: "NM_001286615.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121601", geneDisplay: "ANO4" },
  { key: "NM_001286620.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "56911", geneDisplay: "MAP3K7CL" },
  { key: "NM_001286633.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135644", geneDisplay: "TRIM40" },
  { key: "NM_001286635.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221477", geneDisplay: "C6orf89" },
  { key: "NM_001286646.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57210", geneDisplay: "SLC45A4" },
  { key: "NM_001286657.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137695", geneDisplay: "TMEM68" },
  { key: "NM_001286680.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "10361", geneDisplay: "NPM2" },
  { key: "NM_001286769.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "80778", geneDisplay: "ZNF34" },
  { key: "NM_001286811.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100996693", geneDisplay: "SPEGNB" },
  { key: "NM_001286819.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137994", geneDisplay: "LETM2" },
  { key: "NM_001286820.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "448831", geneDisplay: "FRG2" },
  { key: "NM_001286946.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "255220", geneDisplay: "TXNDC8" },
  { key: "NM_001287135.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5218", geneDisplay: "CDK14" },
  { key: "NM_001287250.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100507607", geneDisplay: "NPIPB9" },
  { key: "NM_001287349.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285346", geneDisplay: "ZNF852" },
  { key: "NM_001287385.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "554282", geneDisplay: "FAM72C" },
  { key: "NM_001287444.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728597", geneDisplay: "DCDC2C" },
  { key: "NM_001287491.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "200424", geneDisplay: "TET3" },
  { key: "NM_001287492.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "63979", geneDisplay: "FIGNL1" },
  { key: "NM_001287737.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "646817", geneDisplay: "SETSIP" },
  { key: "NM_001288565.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "252839", geneDisplay: "TMEM9" },
  { key: "NM_001288583.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388588", geneDisplay: "SMIM1" },
  { key: "NM_001288590.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55888", geneDisplay: "ZKSCAN7" },
  { key: "NM_001288615.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64927", geneDisplay: "TTC23" },
  { key: "NM_001288622.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130026", geneDisplay: "ICA1L" },
  { key: "NM_001288655.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79877", geneDisplay: "DCAKD" },
  { key: "NM_001288702.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124975", geneDisplay: "GGT6" },
  { key: "NM_001288705.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1436", geneDisplay: "CSF1R" },
  { key: "NM_001288718.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "6776", geneDisplay: "STAT5A" },
  { key: "NM_001288732.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55852", geneDisplay: "TEX2" },
  { key: "NM_001288740.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "170371", geneDisplay: "TMEM273" },
  { key: "NM_001288746.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "116173", geneDisplay: "CMTM5" },
  { key: "NM_001288772.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5288", geneDisplay: "PIK3C2G" },
  { key: "NM_001288800.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199704", geneDisplay: "ZNF585A" },
  { key: "NM_001288833.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "2678", geneDisplay: "GGT1" },
  { key: "NM_001288962.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9322", geneDisplay: "TRIP10" },
  { key: "NM_001288973.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8038", geneDisplay: "ADAM12" },
  { key: "NM_001288980.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "162681", geneDisplay: "C18orf54" },
  { key: "NM_001288985.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10351", geneDisplay: "ABCA8" },
  { key: "NM_001288990.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55815", geneDisplay: "TSNAXIP1" },
  { key: "NM_001289080.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27255", geneDisplay: "CNTN6" },
  { key: "NM_001289104.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5589", geneDisplay: "PRKCSH" },
  { key: "NM_001289110.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390443", geneDisplay: "RNASE9" },
  { key: "NM_001289125.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3455", geneDisplay: "IFNAR2" },
  { key: "NM_001289162.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84229", geneDisplay: "DRC7" },
  { key: "NM_001289187.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55900", geneDisplay: "ZNF302" },
  { key: "NM_001289401.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7694", geneDisplay: "ZNF135" },
  { key: "NM_001289808.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "1410", geneDisplay: "CRYAB" },
  { key: "NM_001289951.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388561", geneDisplay: "ZNF761" },
  { key: "NM_001289970.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "644994", geneDisplay: "SMIM23" },
  { key: "NM_001289971.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "7637", geneDisplay: "ZNF84" },
  { key: "NM_001289974.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "646262", geneDisplay: "LACTBL1" },
  { key: "NM_001290030.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285141", geneDisplay: "ERICH2" },
  { key: "NM_001290043.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6891", geneDisplay: "TAP2" },
  { key: "NM_001290047.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "27443", geneDisplay: "CECR2" },
  { key: "NM_001290060.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7869", geneDisplay: "SEMA3B" },
  { key: "NM_001290072.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "59084", geneDisplay: "ENPP5" },
  { key: "NM_001290079.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54729", geneDisplay: "NKX1-1" },
  { key: "NM_001290117.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100652748", geneDisplay: "TIMM23B" },
  { key: "NM_001290187.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84626", geneDisplay: "KRBA1" },
  { key: "NM_001290208.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100131827", geneDisplay: "ZNF717" },
  { key: "NM_001290212.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "83882", geneDisplay: "TSPAN10" },
  { key: "NM_001290223.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1793", geneDisplay: "DOCK1" },
  { key: "NM_001290258.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "142685", geneDisplay: "ASB15" },
  { key: "NM_001290264.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728661", geneDisplay: "SLC35E2B" },
  { key: "NM_001290268.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140876", geneDisplay: "RIPOR3" },
  { key: "NM_001290321.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1657", geneDisplay: "DMXL1" },
  { key: "NM_001290403.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6886", geneDisplay: "TAL1" },
  { key: "NM_001290474.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9854", geneDisplay: "C2CD2L" },
  { key: "NM_001290693.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "441925", geneDisplay: "BECN2" },
  { key: "NM_001291006.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "400891", geneDisplay: "LRRC74B" },
  { key: "NM_001291088.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "83889", geneDisplay: "WDR87" },
  { key: "NM_001291281.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100132074", geneDisplay: "FOXO6" },
  { key: "NM_001291284.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11068", geneDisplay: "CYB561D2" },
  { key: "NM_001291291.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "113230", geneDisplay: "MISP3" },
  { key: "NM_001291303.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79633", geneDisplay: "FAT4" },
  { key: "NM_001291380.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400736", geneDisplay: "PRAMEF13" },
  { key: "NM_001291381.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645382", geneDisplay: "PRAMEF33" },
  { key: "NM_001291415.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7403", geneDisplay: "KDM6A" },
  { key: "NM_001291462.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101060321", geneDisplay: "TBC1D3G" },
  { key: "NM_001291463.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "102724862", geneDisplay: "TBC1D3I" },
  { key: "NM_001291464.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101060351", geneDisplay: "TBC1D3K" },
  { key: "NM_001291465.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101060389", geneDisplay: "TBC1D3D" },
  { key: "NM_001291466.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "102723859", geneDisplay: "TBC1D3E" },
  { key: "NM_001291475.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9560", geneDisplay: "CCL4L2" },
  { key: "NM_001291485.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1087", geneDisplay: "CEACAM7" },
  { key: "NM_001291529.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102723631", geneDisplay: "CT45A10" },
  { key: "NM_001291535.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102723737", geneDisplay: "CT45A8" },
  { key: "NM_001291540.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102723680", geneDisplay: "CT45A9" },
  { key: "NM_001291694.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7182", geneDisplay: "NR2C2" },
  { key: "NM_001291746.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5966", geneDisplay: "REL" },
  { key: "NM_001291815.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "256158", geneDisplay: "HMCN2" },
  { key: "NM_001291832.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151176", geneDisplay: "ERFE" },
  { key: "NM_001291867.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4810", geneDisplay: "NHS" },
  { key: "NM_001291941.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57415", geneDisplay: "C3orf14" },
  { key: "NM_001291978.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8602", geneDisplay: "NOP14" },
  { key: "NM_001291999.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4690", geneDisplay: "NCK1" },
  { key: "NM_001292034.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23118", geneDisplay: "TAB2" },
  { key: "NM_001292063.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "340990", geneDisplay: "OTOG" },
  { key: "NM_001293083.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90342", geneDisplay: "FER1L5" },
  { key: "NM_001293291.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26579", geneDisplay: "MYEOV" },
  { key: "NM_001293298.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "57214", geneDisplay: "CEMIP" },
  { key: "NM_001293626.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "93432", geneDisplay: "MGAM2" },
  { key: "NM_001294338.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1196", geneDisplay: "CLK2" },
  { key: "NM_001297436.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3036", geneDisplay: "HAS1" },
  { key: "NM_001297550.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100506013", geneDisplay: "APELA" },
  { key: "NM_001297551.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "85013", geneDisplay: "TMEM128" },
  { key: "NM_001297563.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170082", geneDisplay: "TCEANC" },
  { key: "NM_001297568.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7678", geneDisplay: "ZNF124" },
  { key: "NM_001297588.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7638", geneDisplay: "ZNF221" },
  { key: "NM_001297595.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23309", geneDisplay: "SIN3B" },
  { key: "NM_001297599.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "166968", geneDisplay: "MIER3" },
  { key: "NM_001297654.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "780", geneDisplay: "DDR1" },
  { key: "NM_001297663.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22996", geneDisplay: "TTC39A" },
  { key: "NM_001297671.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23179", geneDisplay: "RGL1" },
  { key: "NM_001297719.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "406", geneDisplay: "ARNTL" },
  { key: "NM_001297732.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285521", geneDisplay: "COX18" },
  { key: "NM_001297776.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57494", geneDisplay: "RIMKLB" },
  { key: "NM_001300721.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120071", geneDisplay: "LARGE2" },
  { key: "NM_001300759.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "55521", geneDisplay: "TRIM36" },
  { key: "NM_001300781.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "391723", geneDisplay: "HELT" },
  { key: "NM_001300783.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51334", geneDisplay: "PRR16" },
  { key: "NM_001300790.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3120", geneDisplay: "HLA-DQB2" },
  { key: "NM_001300791.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "11127", geneDisplay: "KIF3A" },
  { key: "NM_001300816.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "54819", geneDisplay: "ZCCHC10" },
  { key: "NM_001300826.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127544", geneDisplay: "RNF19B" },
  { key: "NM_001300829.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "1153", geneDisplay: "CIRBP" },
  { key: "NM_001300834.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128346", geneDisplay: "C1orf162" },
  { key: "NM_001300860.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9879", geneDisplay: "DDX46" },
  { key: "NM_001300862.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84954", geneDisplay: "MPND" },
  { key: "NM_001300891.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101929983", geneDisplay: "PRAMEF27" },
  { key: "NM_001300905.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11176", geneDisplay: "BAZ2A" },
  { key: "NM_001300921.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "27039", geneDisplay: "PKD2L2" },
  { key: "NM_001300939.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7478", geneDisplay: "WNT8A" },
  { key: "NM_001300942.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56946", geneDisplay: "EMSY" },
  { key: "NM_001300969.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57821", geneDisplay: "CCDC181" },
  { key: "NM_001300975.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338699", geneDisplay: "ANKRD42" },
  { key: "NM_001301009.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222008", geneDisplay: "VSTM2A" },
  { key: "NM_001301043.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23705", geneDisplay: "CADM1" },
  { key: "NM_001301056.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "79805", geneDisplay: "VASH2" },
  { key: "NM_001301059.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "56917", geneDisplay: "MEIS3" },
  { key: "NM_001301073.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "9337", geneDisplay: "CNOT8" },
  { key: "NM_001301098.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29068", geneDisplay: "ZBTB44" },
  { key: "NM_001301159.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283948", geneDisplay: "NHLRC4" },
  { key: "NM_001301202.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8437", geneDisplay: "RASAL1" },
  { key: "NM_001301267.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4495", geneDisplay: "MT1G" },
  { key: "NM_001301268.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100505573", geneDisplay: "INAFM2" },
  { key: "NM_001301771.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57146", geneDisplay: "LDAF1" },
  { key: "NM_001301782.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94059", geneDisplay: "LENG9" },
  { key: "NM_001301833.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "51072", geneDisplay: "MEMO1" },
  { key: "NM_001302084.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79703", geneDisplay: "C11orf80" },
  { key: "NM_001302109.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7627", geneDisplay: "ZNF75A" },
  { key: "NM_001302348.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "729852", geneDisplay: "UMAD1" },
  { key: "NM_001302371.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100132406", geneDisplay: "NBPF10" },
  { key: "NM_001302551.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101060301", geneDisplay: "HNRNPCL4" },
  { key: "NM_001302695.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "613211", geneDisplay: "DEFB134" },
  { key: "NM_001302757.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "728361", geneDisplay: "OVOL3" },
  { key: "NM_001302769.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "117583", geneDisplay: "PARD3B" },
  { key: "NM_001302998.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "149998", geneDisplay: "LIPI" },
  { key: "NM_001303007.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23564", geneDisplay: "DDAH2" },
  { key: "NM_001303037.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51678", geneDisplay: "PALS2" },
  { key: "NM_001303052.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23040", geneDisplay: "MYT1L" },
  { key: "NM_001303143.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79441", geneDisplay: "HAUS3" },
  { key: "NM_001303256.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "22880", geneDisplay: "MORC2" },
  { key: "NM_001303264.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9819", geneDisplay: "TSC22D2" },
  { key: "NM_001303281.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "7566", geneDisplay: "ZNF18" },
  { key: "NM_001303426.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51193", geneDisplay: "ZNF639" },
  { key: "NM_001303441.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124790", geneDisplay: "HEXIM2" },
  { key: "NM_001303457.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9675", geneDisplay: "TTI1" },
  { key: "NM_001303473.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "115330", geneDisplay: "GPR146" },
  { key: "NM_001303508.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "91464", geneDisplay: "ISX" },
  { key: "NM_001303512.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57595", geneDisplay: "PDZD4" },
  { key: "NM_001303618.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10666", geneDisplay: "CD226" },
  { key: "NM_001303620.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1774", geneDisplay: "DNASE1L1" },
  { key: "NM_001303622.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "728637", geneDisplay: "MEIKIN" },
  { key: "NM_001304274.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "196294", geneDisplay: "IMMP1L" },
  { key: "NM_001304284.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9098", geneDisplay: "USP6" },
  { key: "NM_001304331.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8497", geneDisplay: "PPFIA4" },
  { key: "NM_001304359.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4586", geneDisplay: "MUC5AC" },
  { key: "NM_001304360.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "85452", geneDisplay: "CFAP74" },
  { key: "NM_001304366.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344658", geneDisplay: "SAMD7" },
  { key: "NM_001304369.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140731", geneDisplay: "ANKRD60" },
  { key: "NM_001304376.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "221188", geneDisplay: "ADGRG5" },
  { key: "NM_001304388.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283685", geneDisplay: "GOLGA6L2" },
  { key: "NM_001304419.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "392843", geneDisplay: "IQCA1L" },
  { key: "NM_001304431.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "202309", geneDisplay: "GAPT" },
  { key: "NM_001304438.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124842", geneDisplay: "TMEM132E" },
  { key: "NM_001304504.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10363", geneDisplay: "HMG20A" },
  { key: "NM_001304509.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "81932", geneDisplay: "HDHD3" },
  { key: "NM_001304533.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286183", geneDisplay: "NKAIN3" },
  { key: "NM_001304548.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286464", geneDisplay: "CFAP47" },
  { key: "NM_001304561.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56244", geneDisplay: "BTNL2" },
  { key: "NM_001304731.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100507027", geneDisplay: "MRLN" },
  { key: "NM_001304748.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55321", geneDisplay: "TMEM74B" },
  { key: "NM_001304762.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55194", geneDisplay: "EVA1B" },
  { key: "NM_001304808.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23774", geneDisplay: "BRD1" },
  { key: "NM_001304817.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128229", geneDisplay: "TSACC" },
  { key: "NM_001304833.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79676", geneDisplay: "OGFOD2" },
  { key: "NM_001304944.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "79962", geneDisplay: "DNAJC22" },
  { key: "NM_001304947.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124751", geneDisplay: "KRBA2" },
  { key: "NM_001304963.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170685", geneDisplay: "NUDT10" },
  { key: "NM_001304964.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "256710", geneDisplay: "GLIPR1L1" },
  { key: "NM_001304993.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "113675", geneDisplay: "SDSL" },
  { key: "NM_001305018.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "65988", geneDisplay: "ZNF747" },
  { key: "NM_001305173.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "221191", geneDisplay: "PRSS54" },
  { key: "NM_001305203.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146198", geneDisplay: "ZFP90" },
  { key: "NM_001305581.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "83938", geneDisplay: "LRMDA" },
  { key: "NM_001306068.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100288687", geneDisplay: "DUX4" },
  { key: "NM_001306072.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "645359", geneDisplay: "PRAMEF26" },
  { key: "NM_001306080.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "4008", geneDisplay: "LMO7" },
  { key: "NM_001306084.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144535", geneDisplay: "CFAP54" },
  { key: "NM_001306087.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "341880", geneDisplay: "SLC35F4" },
  { key: "NM_001306089.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "7776", geneDisplay: "ZNF236" },
  { key: "NM_001306094.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440348", geneDisplay: "NPIPB15" },
  { key: "NM_001306135.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "220107", geneDisplay: "DLEU7" },
  { key: "NM_001306137.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100129278", geneDisplay: "TDRD15" },
  { key: "NM_001306141.4", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "442590", geneDisplay: "SPDYE5" },
  { key: "NM_001306144.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8776", geneDisplay: "MTMR1" },
  { key: "NM_001306158.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11159", geneDisplay: "RABL2A" },
  { key: "NM_001306215.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152485", geneDisplay: "ZNF827" },
  { key: "NM_001307928.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "89777", geneDisplay: "SERPINB12" },
  { key: "NM_001308027.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79684", geneDisplay: "MSANTD2" },
  { key: "NM_001308068.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84256", geneDisplay: "FLYWCH1" },
  { key: "NM_001308093.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2626", geneDisplay: "GATA4" },
  { key: "NM_001308120.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "23116", geneDisplay: "TOGARAM1" },
  { key: "NM_001308142.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "57496", geneDisplay: "MRTFB" },
  { key: "NM_001308147.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26030", geneDisplay: "PLEKHG3" },
  { key: "NM_001308154.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "376267", geneDisplay: "RAB15" },
  { key: "NM_001308172.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "123876", geneDisplay: "ACSM2A" },
  { key: "NM_001308173.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79616", geneDisplay: "CCNJL" },
  { key: "NM_001308195.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "375484", geneDisplay: "SIMC1" },
  { key: "NM_001308209.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "400668", geneDisplay: "PRSS57" },
  { key: "NM_001308210.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "10194", geneDisplay: "TSHZ1" },
  { key: "NM_001308229.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "285315", geneDisplay: "C3orf33" },
  { key: "NM_001308236.3", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "79930", geneDisplay: "DOK3" },
  { key: "NM_001308313.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152015", geneDisplay: "ROPN1B" },
  { key: "NM_001308319.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80205", geneDisplay: "CHD9" },
  { key: "NM_001308330.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9515", geneDisplay: "STXBP5L" },
  { key: "NM_001308348.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163059", geneDisplay: "ZNF433" },
  { key: "NM_001308421.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "101928527", geneDisplay: "PIGBOS1" },
  { key: "NM_001308426.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10201", geneDisplay: "NME6" },
  { key: "NM_001308429.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "112703", geneDisplay: "GARIN5A" },
  { key: "NM_001308476.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "57105", geneDisplay: "CYSLTR2" },
  { key: "NM_001308491.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "90625", geneDisplay: "ERVH48-1" },
  { key: "NM_001309427.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100129969", geneDisplay: "FAM205C" },
  { key: "NM_001310124.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388553", geneDisplay: "MEIOSIN" },
  { key: "NM_001310134.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "441873", geneDisplay: "PRAMEF25" },
  { key: "NM_001310135.5", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "319089", geneDisplay: "TTC6" },
  { key: "NM_001310136.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "728734", geneDisplay: "NPIPB8" },
  { key: "NM_001310137.5", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "728888", geneDisplay: "NPIPB11" },
  { key: "NM_001310219.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "644070", geneDisplay: "GSG1L2" },
  { key: "NM_001311175.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "388121", geneDisplay: "TNFAIP8L3" },
  { key: "NM_001312653.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85236", geneDisplay: "H2BC12" },
  { key: "NM_001312673.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "5130", geneDisplay: "PCYT1A" },
  { key: "NM_001312909.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "63901", geneDisplay: "FAM111A" },
  { key: "NM_001313692.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102723553", geneDisplay: "LOC102723553" },
  { key: "NM_001313998.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8678", geneDisplay: "BECN1" },
  { key: "NM_001314025.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54059", geneDisplay: "YBEY" },
  { key: "NM_001314050.5", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724652", geneDisplay: "LOC102724652" },
  { key: "NM_001315494.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "101929726", geneDisplay: "MYMX" },
  { key: "NM_001315532.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5816", geneDisplay: "PVALB" },
  { key: "NM_001316309.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "340390", geneDisplay: "WDR97" },
  { key: "NM_001316349.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80731", geneDisplay: "THSD7B" },
  { key: "NM_001316692.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "105372280", geneDisplay: "GNG14" },
  { key: "NM_001316764.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388963", geneDisplay: "C2orf81" },
  { key: "NM_001316897.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "11092", geneDisplay: "SPACA9" },
  { key: "NM_001316943.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "54956", geneDisplay: "PARP16" },
  { key: "NM_001316972.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147650", geneDisplay: "SPACA6" },
  { key: "NM_001316979.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84878", geneDisplay: "ZBTB45" },
  { key: "NM_001317056.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285973", geneDisplay: "ATG9B" },
  { key: "NM_001317078.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219541", geneDisplay: "MED19" },
  { key: "NM_001317107.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26687", geneDisplay: "OR4E1" },
  { key: "NM_001317162.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5325", geneDisplay: "PLAGL1" },
  { key: "NM_001317774.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "54763", geneDisplay: "ROPN1" },
  { key: "NM_001317778.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6440", geneDisplay: "SFTPC" },
  { key: "NM_001317782.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6132", geneDisplay: "RPL8" },
  { key: "NM_001317785.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "51657", geneDisplay: "STYXL1" },
  { key: "NM_001317857.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "728689", geneDisplay: "EIF3CL" },
  { key: "NM_001317938.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "728586", geneDisplay: "CCDC192" },
  { key: "NM_001317950.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80709", geneDisplay: "AKNA" },
  { key: "NM_001318042.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "114991", geneDisplay: "ZNF618" },
  { key: "NM_001318063.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "90423", geneDisplay: "ATP6V1E2" },
  { key: "NM_001318089.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "375757", geneDisplay: "SWI5" },
  { key: "NM_001318100.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84445", geneDisplay: "LZTS2" },
  { key: "NM_001318104.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9514", geneDisplay: "GAL3ST1" },
  { key: "NM_001318170.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143098", geneDisplay: "MPP7" },
  { key: "NM_001318192.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "26266", geneDisplay: "SLC13A4" },
  { key: "NM_001318234.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9751", geneDisplay: "SNPH" },
  { key: "NM_001318241.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219793", geneDisplay: "TBATA" },
  { key: "NM_001318252.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "84310", geneDisplay: "C7orf50" },
  { key: "NM_001318503.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "1852", geneDisplay: "DUSP9" },
  { key: "NM_001318510.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2182", geneDisplay: "ACSL4" },
  { key: "NM_001318525.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51693", geneDisplay: "TRAPPC2L" },
  { key: "NM_001318734.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "64837", geneDisplay: "KLC2" },
  { key: "NM_001318777.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114609", geneDisplay: "TIRAP" },
  { key: "NM_001318789.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "7097", geneDisplay: "TLR2" },
  { key: "NM_001318810.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22865", geneDisplay: "SLITRK3" },
  { key: "NM_001318841.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "201501", geneDisplay: "ZBTB7C" },
  { key: "NM_001318852.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23162", geneDisplay: "MAPK8IP3" },
  { key: "NM_001318890.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "116285", geneDisplay: "ACSM1" },
  { key: "NM_001318891.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7738", geneDisplay: "ZNF184" },
  { key: "NM_001318895.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2274", geneDisplay: "FHL2" },
  { key: "NM_001318932.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "144809", geneDisplay: "FAM216B" },
  { key: "NM_001318939.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "221143", geneDisplay: "EEF1AKMT1" },
  { key: "NM_001319074.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84839", geneDisplay: "RAX2" },
  { key: "NM_001319152.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "79025", geneDisplay: "FNDC11" },
  { key: "NM_001319193.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85302", geneDisplay: "FBF1" },
  { key: "NM_001319206.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4205", geneDisplay: "MEF2A" },
  { key: "NM_001319217.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1543", geneDisplay: "CYP1A1" },
  { key: "NM_001319236.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6164", geneDisplay: "RPL34" },
  { key: "NM_001319658.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54935", geneDisplay: "DUSP23" },
  { key: "NM_001319674.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10691", geneDisplay: "GMEB1" },
  { key: "NM_001319944.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64793", geneDisplay: "CEP85" },
  { key: "NM_001319967.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286430", geneDisplay: "NLRP2B" },
  { key: "NM_001319999.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "29127", geneDisplay: "RACGAP1" },
  { key: "NM_001320010.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "107181291", geneDisplay: "PYDC5" },
  { key: "NM_001320033.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9389", geneDisplay: "SLC22A14" },
  { key: "NM_001320038.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "730098", geneDisplay: "LOC730098" },
  { key: "NM_001320047.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55732", geneDisplay: "C1orf112" },
  { key: "NM_001320126.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57406", geneDisplay: "ABHD6" },
  { key: "NM_001320179.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339559", geneDisplay: "ZFP69" },
  { key: "NM_001320198.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "3892", geneDisplay: "KRT86" },
  { key: "NM_001320214.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6430", geneDisplay: "SRSF5" },
  { key: "NM_001320329.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57447", geneDisplay: "NDRG2" },
  { key: "NM_001320371.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "147948", geneDisplay: "ZNF582" },
  { key: "NM_001320436.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339168", geneDisplay: "TMEM95" },
  { key: "NM_001320466.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "254887", geneDisplay: "ZDHHC23" },
  { key: "NM_001320485.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80305", geneDisplay: "TRABD" },
  { key: "NM_001320510.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "349565", geneDisplay: "NMNAT3" },
  { key: "NM_001320537.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54020", geneDisplay: "SLC37A1" },
  { key: "NM_001320586.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "98", geneDisplay: "ACYP2" },
  { key: "NM_001320643.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724428", geneDisplay: "LOC102724428" },
  { key: "NM_001320646.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724594", geneDisplay: "LOC102724594" },
  { key: "NM_001320669.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "22835", geneDisplay: "ZFP30" },
  { key: "NM_001320714.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "9980", geneDisplay: "DOP1B" },
  { key: "NM_001320752.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "412", geneDisplay: "STS" },
  { key: "NM_001320768.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "116159", geneDisplay: "CYYR1" },
  { key: "NM_001320835.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10260", geneDisplay: "DENND4A" },
  { key: "NM_001320848.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151313", geneDisplay: "FAHD2B" },
  { key: "NM_001320870.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "399512", geneDisplay: "SLC25A35" },
  { key: "NM_001320878.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "442038", geneDisplay: "SULT1C3" },
  { key: "NM_001320925.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84316", geneDisplay: "NAA38" },
  { key: "NM_001320973.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8548", geneDisplay: "BLZF1" },
  { key: "NM_001321075.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "1742", geneDisplay: "DLG4" },
  { key: "NM_001321092.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2873", geneDisplay: "GPS1" },
  { key: "NM_001321103.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9497", geneDisplay: "SLC4A7" },
  { key: "NM_001321120.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9496", geneDisplay: "TBX4" },
  { key: "NM_001321142.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "63924", geneDisplay: "CIDEC" },
  { key: "NM_001321154.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79828", geneDisplay: "METTL8" },
  { key: "NM_001321178.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "727866", geneDisplay: "FAM156B" },
  { key: "NM_001321209.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84661", geneDisplay: "DPY30" },
  { key: "NM_001321324.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4343", geneDisplay: "MOV10" },
  { key: "NM_001321350.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "114659", geneDisplay: "LRRC37B" },
  { key: "NM_001321356.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "63934", geneDisplay: "ZNF667" },
  { key: "NM_001321439.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "78992", geneDisplay: "YIPF2" },
  { key: "NM_001321527.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150763", geneDisplay: "GPAT2" },
  { key: "NM_001321571.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "817", geneDisplay: "CAMK2D" },
  { key: "NM_001321623.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285172", geneDisplay: "FAM126B" },
  { key: "NM_001321635.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79815", geneDisplay: "NIPAL2" },
  { key: "NM_001321645.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7767", geneDisplay: "ZNF224" },
  { key: "NM_001321708.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9162", geneDisplay: "DGKI" },
  { key: "NM_001321739.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130951", geneDisplay: "M1AP" },
  { key: "NM_001321759.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84529", geneDisplay: "CDIN1" },
  { key: "NM_001321783.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54906", geneDisplay: "TASOR2" },
  { key: "NM_001321827.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "199786", geneDisplay: "NIBAN3" },
  { key: "NM_001321866.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162966", geneDisplay: "ZNF600" },
  { key: "NM_001321926.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "548596", geneDisplay: "CKMT1A" },
  { key: "NM_001321958.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "653308", geneDisplay: "ASAH2B" },
  { key: "NM_001321967.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84896", geneDisplay: "ATAD1" },
  { key: "NM_001321971.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "165082", geneDisplay: "ADGRF3" },
  { key: "NM_001321981.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "348327", geneDisplay: "ZNF530" },
  { key: "NM_001322064.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79149", geneDisplay: "ZSCAN5A" },
  { key: "NM_001322101.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79172", geneDisplay: "CENPO" },
  { key: "NM_001322131.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90338", geneDisplay: "ZNF160" },
  { key: "NM_001322209.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3355", geneDisplay: "HTR1F" },
  { key: "NM_001322244.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "101929926", geneDisplay: "TEX51" },
  { key: "NM_001322247.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "101928601", geneDisplay: "MEI4" },
  { key: "NM_001322255.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148930", geneDisplay: "KNCN" },
  { key: "NM_001322286.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "252884", geneDisplay: "ZNF396" },
  { key: "NM_001322331.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130355", geneDisplay: "C2orf76" },
  { key: "NM_001322374.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100505876", geneDisplay: "CEBPZOS" },
  { key: "NM_001322466.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "9457", geneDisplay: "FHL5" },
  { key: "NM_001322799.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "3787", geneDisplay: "KCNS1" },
  { key: "NM_001322917.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163081", geneDisplay: "ZNF567" },
  { key: "NM_001322934.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4791", geneDisplay: "NFKB2" },
  { key: "NM_001323032.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9899", geneDisplay: "SV2B" },
  { key: "NM_001323043.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10745", geneDisplay: "PHTF1" },
  { key: "NM_001323075.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101059915", geneDisplay: "LOC101059915" },
  { key: "NM_001323079.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101928917", geneDisplay: "HSFX3" },
  { key: "NM_001323087.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "282973", geneDisplay: "JAKMIP3" },
  { key: "NM_001323289.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6792", geneDisplay: "CDKL5" },
  { key: "NM_001323311.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "29942", geneDisplay: "PURG" },
  { key: "NM_001323329.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "5599", geneDisplay: "MAPK8" },
  { key: "NM_001323342.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25909", geneDisplay: "AHCTF1" },
  { key: "NM_001323368.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10402", geneDisplay: "ST3GAL6" },
  { key: "NM_001323532.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56965", geneDisplay: "PARP6" },
  { key: "NM_001323572.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9738", geneDisplay: "CCP110" },
  { key: "NM_001323627.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "29800", geneDisplay: "ZDHHC1" },
  { key: "NM_001323682.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80023", geneDisplay: "NRSN2" },
  { key: "NM_001324073.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100130520", geneDisplay: "CD300H" },
  { key: "NM_001324116.5", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6675", geneDisplay: "UAP1" },
  { key: "NM_001324144.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7592", geneDisplay: "ZNF41" },
  { key: "NM_001324242.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55285", geneDisplay: "RBM41" },
  { key: "NM_001324250.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7587", geneDisplay: "ZNF37A" },
  { key: "NM_001324366.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161823", geneDisplay: "ADAL" },
  { key: "NM_001324418.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "53616", geneDisplay: "ADAM22" },
  { key: "NM_001324445.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23536", geneDisplay: "ADAT1" },
  { key: "NM_001326342.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10659", geneDisplay: "CELF2" },
  { key: "NM_001326411.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23761", geneDisplay: "PISD" },
  { key: "NM_001328608.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "390667", geneDisplay: "PTX4" },
  { key: "NM_001328655.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "110117498", geneDisplay: "P3R3URF" },
  { key: "NM_001329214.4", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4253", geneDisplay: "MIA2" },
  { key: "NM_001329443.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84988", geneDisplay: "PPP1R16A" },
  { key: "NM_001329615.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "10302", geneDisplay: "SNAPC5" },
  { key: "NM_001329630.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "144100", geneDisplay: "PLEKHA7" },
  { key: "NM_001329686.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221443", geneDisplay: "OARD1" },
  { key: "NM_001329752.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84908", geneDisplay: "FAM136A" },
  { key: "NM_001329788.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "131368", geneDisplay: "ZPLD1" },
  { key: "NM_001329943.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9786", geneDisplay: "KIAA0586" },
  { key: "NM_001329958.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "29125", geneDisplay: "C11orf21" },
  { key: "NM_001329984.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "653464", geneDisplay: "SRGAP2C" },
  { key: "NM_001329990.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84895", geneDisplay: "MIGA2" },
  { key: "NM_001329998.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9881", geneDisplay: "TRANK1" },
  { key: "NM_001330059.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "253832", geneDisplay: "ZDHHC20" },
  { key: "NM_001330061.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "102724101", geneDisplay: "TP53TG3E" },
  { key: "NM_001330063.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "51479", geneDisplay: "ANKFY1" },
  { key: "NM_001330066.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "102724127", geneDisplay: "TP53TG3F" },
  { key: "NM_001330067.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101060233", geneDisplay: "OPN1MW3" },
  { key: "NM_001330070.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10462", geneDisplay: "CLEC10A" },
  { key: "NM_001330071.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "9201", geneDisplay: "DCLK1" },
  { key: "NM_001330074.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "253725", geneDisplay: "WASHC2C" },
  { key: "NM_001330078.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9378", geneDisplay: "NRXN1" },
  { key: "NM_001330103.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "55680", geneDisplay: "RUFY2" },
  { key: "NM_001330112.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "54537", geneDisplay: "SHLD2" },
  { key: "NM_001330145.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55188", geneDisplay: "RIC8B" },
  { key: "NM_001330177.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "64430", geneDisplay: "PCNX4" },
  { key: "NM_001330195.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "9369", geneDisplay: "NRXN3" },
  { key: "NM_001330213.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8576", geneDisplay: "STK16" },
  { key: "NM_001330218.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151195", geneDisplay: "CCNYL1" },
  { key: "NM_001330230.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3430", geneDisplay: "IFI35" },
  { key: "NM_001330236.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "107984640", geneDisplay: "LBHD2" },
  { key: "NM_001330239.4", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "7082", geneDisplay: "TJP1" },
  { key: "NM_001330260.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6334", geneDisplay: "SCN8A" },
  { key: "NM_001330288.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6601", geneDisplay: "SMARCC2" },
  { key: "NM_001330301.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79595", geneDisplay: "SAP130" },
  { key: "NM_001330311.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1855", geneDisplay: "DVL1" },
  { key: "NM_001330348.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "11138", geneDisplay: "TBC1D8" },
  { key: "NM_001330360.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5422", geneDisplay: "POLA1" },
  { key: "NM_001330422.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5463", geneDisplay: "POU6F1" },
  { key: "NM_001330442.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57504", geneDisplay: "MTA3" },
  { key: "NM_001330448.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388611", geneDisplay: "CD164L2" },
  { key: "NM_001330449.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "51005", geneDisplay: "AMDHD2" },
  { key: "NM_001330452.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "115811", geneDisplay: "IQCD" },
  { key: "NM_001330542.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284004", geneDisplay: "HEXD" },
  { key: "NM_001330559.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "91133", geneDisplay: "L3MBTL4" },
  { key: "NM_001330564.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23091", geneDisplay: "ZC3H13" },
  { key: "NM_001330574.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7552", geneDisplay: "ZNF711" },
  { key: "NM_001330585.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200014", geneDisplay: "CC2D1B" },
  { key: "NM_001330588.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "7174", geneDisplay: "TPP2" },
  { key: "NM_001330617.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7565", geneDisplay: "ZNF17" },
  { key: "NM_001330618.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "50626", geneDisplay: "CYHR1" },
  { key: "NM_001330640.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55667", geneDisplay: "DENND4C" },
  { key: "NM_001330668.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "220869", geneDisplay: "CBWD5" },
  { key: "NM_001330672.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "22998", geneDisplay: "LIMCH1" },
  { key: "NM_001330677.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6913", geneDisplay: "TBX15" },
  { key: "NM_001330683.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7267", geneDisplay: "TTC3" },
  { key: "NM_001330691.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "84131", geneDisplay: "CEP78" },
  { key: "NM_001330700.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7155", geneDisplay: "TOP2B" },
  { key: "NM_001330701.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23287", geneDisplay: "AGTPBP1" },
  { key: "NM_001330707.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "7690", geneDisplay: "ZNF131" },
  { key: "NM_001330723.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81609", geneDisplay: "SNX27" },
  { key: "NM_001330724.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8999", geneDisplay: "CDKL2" },
  { key: "NM_001330736.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9849", geneDisplay: "ZNF518A" },
  { key: "NM_001330988.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "114789", geneDisplay: "SLC25A25" },
  { key: "NM_001330994.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "2897", geneDisplay: "GRIK1" },
  { key: "NM_001331036.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1998", geneDisplay: "ELF2" },
  { key: "NM_001331076.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "350383", geneDisplay: "GPR142" },
  { key: "NM_001345843.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79173", geneDisplay: "BRME1" },
  { key: "NM_001346022.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "85015", geneDisplay: "USP45" },
  { key: "NM_001346048.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "84851", geneDisplay: "TRIM52" },
  { key: "NM_001346194.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "27199", geneDisplay: "OXGR1" },
  { key: "NM_001346231.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "57614", geneDisplay: "RELCH" },
  { key: "NM_001346249.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "253959", geneDisplay: "RALGAPA1" },
  { key: "NM_001346252.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57646", geneDisplay: "USP28" },
  { key: "NM_001346311.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9776", geneDisplay: "ATG13" },
  { key: "NM_001346413.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51585", geneDisplay: "PCF11" },
  { key: "NM_001346516.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "84458", geneDisplay: "LCOR" },
  { key: "NM_001346754.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284098", geneDisplay: "PIGW" },
  { key: "NM_001346793.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "26287", geneDisplay: "ANKRD2" },
  { key: "NM_001346810.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9228", geneDisplay: "DLGAP2" },
  { key: "NM_001346880.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "388931", geneDisplay: "MFSD2B" },
  { key: "NM_001346953.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64789", geneDisplay: "EXO5" },
  { key: "NM_001347217.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "64743", geneDisplay: "WDR13" },
  { key: "NM_001347674.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "387267", geneDisplay: "KRTAP5-4" },
  { key: "NM_001347684.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "105375355", geneDisplay: "UPK3B" },
  { key: "NM_001347721.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "1859", geneDisplay: "DYRK1A" },
  { key: "NM_001347885.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100506127", geneDisplay: "GVQW3" },
  { key: "NM_001347886.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "55567", geneDisplay: "DNAH3" },
  { key: "NM_001347969.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55068", geneDisplay: "ENOX1" },
  { key: "NM_001347995.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "9413", geneDisplay: "FAM189A2" },
  { key: "NM_001348022.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "80095", geneDisplay: "ZNF606" },
  { key: "NM_001348070.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100129407", geneDisplay: "FAM236A" },
  { key: "NM_001348072.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100132304", geneDisplay: "FAM236B" },
  { key: "NM_001348107.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158376", geneDisplay: "SPAAR" },
  { key: "NM_001348119.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10626", geneDisplay: "TRIM16" },
  { key: "NM_001348129.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "550643", geneDisplay: "NBDY" },
  { key: "NM_001348156.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "170958", geneDisplay: "ZNF525" },
  { key: "NM_001348195.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2866", geneDisplay: "GPR42" },
  { key: "NM_001348203.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "105373251", geneDisplay: "FAM236D" },
  { key: "NM_001348223.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79346", geneDisplay: "OR4C5" },
  { key: "NM_001348224.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79470", geneDisplay: "OR51J1" },
  { key: "NM_001348233.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "79549", geneDisplay: "OR6J1" },
  { key: "NM_001348255.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "644596", geneDisplay: "SMIM10L2B" },
  { key: "NM_001348294.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442185", geneDisplay: "OR2J1" },
  { key: "NM_001348323.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9320", geneDisplay: "TRIP12" },
  { key: "NM_001348364.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "400709", geneDisplay: "SIGLEC16" },
  { key: "NM_001348372.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "392517", geneDisplay: "NCBP2L" },
  { key: "NM_001348484.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9699", geneDisplay: "RIMS2" },
  { key: "NM_001348543.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161145", geneDisplay: "TMEM229B" },
  { key: "NM_001348680.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100316904", geneDisplay: "SAP25" },
  { key: "NM_001348699.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283726", geneDisplay: "SAXO2" },
  { key: "NM_001348712.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "109703458", geneDisplay: "HTD2" },
  { key: "NM_001348716.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23135", geneDisplay: "KDM6B" },
  { key: "NM_001348719.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90594", geneDisplay: "ZNF439" },
  { key: "NM_001348768.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57520", geneDisplay: "HECW2" },
  { key: "NM_001348800.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26137", geneDisplay: "ZBTB20" },
  { key: "NM_001348946.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5243", geneDisplay: "ABCB1" },
  { key: "NM_001348957.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "388789", geneDisplay: "SMIM26" },
  { key: "NM_001349008.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387707", geneDisplay: "CC2D2B" },
  { key: "NM_001349018.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "347736", geneDisplay: "NME9" },
  { key: "NM_001349074.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9779", geneDisplay: "TBC1D5" },
  { key: "NM_001349206.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23175", geneDisplay: "LPIN1" },
  { key: "NM_001349232.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10533", geneDisplay: "ATG7" },
  { key: "NM_001349253.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11280", geneDisplay: "SCN11A" },
  { key: "NM_001349278.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23243", geneDisplay: "ANKRD28" },
  { key: "NM_001349336.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153328", geneDisplay: "SLC25A48" },
  { key: "NM_001349338.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27086", geneDisplay: "FOXP1" },
  { key: "NM_001349723.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "25822", geneDisplay: "DNAJB5" },
  { key: "NM_001349726.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "646864", geneDisplay: "ZNF723" },
  { key: "NM_001349727.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "927", geneDisplay: "CD8B2" },
  { key: "NM_001349798.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55294", geneDisplay: "FBXW7" },
  { key: "NM_001349884.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128338", geneDisplay: "DRAM2" },
  { key: "NM_001349999.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "23543", geneDisplay: "RBFOX2" },
  { key: "NM_001350134.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55279", geneDisplay: "ZNF654" },
  { key: "NM_001350145.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10207", geneDisplay: "PATJ" },
  { key: "NM_001350162.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "56154", geneDisplay: "TEX15" },
  { key: "NM_001350175.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127002", geneDisplay: "ATXN7L2" },
  { key: "NM_001350197.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7813", geneDisplay: "EVI5" },
  { key: "NM_001350317.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164714", geneDisplay: "TTLL8" },
  { key: "NM_001350451.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146713", geneDisplay: "RBFOX3" },
  { key: "NM_001350562.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "93643", geneDisplay: "TJAP1" },
  { key: "NM_001350599.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "253714", geneDisplay: "MMS22L" },
  { key: "NM_001350605.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9295", geneDisplay: "SRSF11" },
  { key: "NM_001350709.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "1607", geneDisplay: "DGKB" },
  { key: "NM_001350770.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "54955", geneDisplay: "C1orf109" },
  { key: "NM_001350814.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "2887", geneDisplay: "GRB10" },
  { key: "NM_001350919.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283768", geneDisplay: "GOLGA8G" },
  { key: "NM_001350920.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100132565", geneDisplay: "GOLGA8F" },
  { key: "NM_001350921.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118611", geneDisplay: "C10orf90" },
  { key: "NM_001350931.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "143379", geneDisplay: "C10orf82" },
  { key: "NM_001350932.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123346", geneDisplay: "HIGD2B" },
  { key: "NM_001350977.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "100151643", geneDisplay: "KRTAP20-4" },
  { key: "NM_001350978.3", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "645961", geneDisplay: "SPATA31C2" },
  { key: "NM_001350994.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "389332", geneDisplay: "SMIM32" },
  { key: "NM_001351003.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "283521", geneDisplay: "TMEM272" },
  { key: "NM_001351015.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "27291", geneDisplay: "R3HCC1L" },
  { key: "NM_001351111.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "109729126", geneDisplay: "FAM236C" },
  { key: "NM_001351114.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101927685", geneDisplay: "HSFX4" },
  { key: "NM_001351123.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "255411", geneDisplay: "TEX49" },
  { key: "NM_001351132.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5830", geneDisplay: "PEX5" },
  { key: "NM_001351169.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "22978", geneDisplay: "NT5C2" },
  { key: "NM_001351264.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55028", geneDisplay: "C17orf80" },
  { key: "NM_001351281.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "646951", geneDisplay: "MINDY4B" },
  { key: "NM_001351288.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "25834", geneDisplay: "MGAT4C" },
  { key: "NM_001351303.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "653082", geneDisplay: "ZDHHC11B" },
  { key: "NM_001351305.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728763", geneDisplay: "CROCC2" },
  { key: "NM_001351307.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100033411", geneDisplay: "DUXB" },
  { key: "NM_001351349.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100996746", geneDisplay: "SPDYE11" },
  { key: "NM_001351351.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "102723849", geneDisplay: "SPDYE17" },
  { key: "NM_001351365.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101060226", geneDisplay: "NBPF19" },
  { key: "NM_001351368.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "728537", geneDisplay: "C2orf92" },
  { key: "NM_001351411.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "1902", geneDisplay: "LPAR1" },
  { key: "NM_001351537.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "151258", geneDisplay: "SLC38A11" },
  { key: "NM_001351574.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "644145", geneDisplay: "EXOC1L" },
  { key: "NM_001351576.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "440184", geneDisplay: "CCDC196" },
  { key: "NM_001351578.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "4957", geneDisplay: "ODF2" },
  { key: "NM_001351589.3", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "647166", geneDisplay: "C13orf42" },
  { key: "NM_001351596.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "256369", geneDisplay: "CCDC197" },
  { key: "NM_001351601.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "203522", geneDisplay: "INTS6L" },
  { key: "NM_001351622.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "401081", geneDisplay: "C3orf85" },
  { key: "NM_001351661.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140733", geneDisplay: "MACROD2" },
  { key: "NM_001351695.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57508", geneDisplay: "INTS2" },
  { key: "NM_001351732.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "110354863", geneDisplay: "ZNF660-ZNF197" },
  { key: "NM_001351774.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162967", geneDisplay: "ZNF320" },
  { key: "NM_001352005.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50863", geneDisplay: "NTM" },
  { key: "NM_001352027.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "51317", geneDisplay: "PHF21A" },
  { key: "NM_001352129.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100507537", geneDisplay: "STRIT1" },
  { key: "NM_001352171.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84102", geneDisplay: "SLC41A2" },
  { key: "NM_001352186.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56899", geneDisplay: "ANKS1B" },
  { key: "NM_001352248.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "115584", geneDisplay: "SLC5A11" },
  { key: "NM_001352389.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "65975", geneDisplay: "STK33" },
  { key: "NM_001352452.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "7692", geneDisplay: "ZNF133" },
  { key: "NM_001352514.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "3141", geneDisplay: "HLCS" },
  { key: "NM_001352688.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "401397", geneDisplay: "SMIM30" },
  { key: "NM_001352702.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5747", geneDisplay: "PTK2" },
  { key: "NM_001352754.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80210", geneDisplay: "ARMC9" },
  { key: "NM_001352837.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "9705", geneDisplay: "ST18" },
  { key: "NM_001352885.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100505989", geneDisplay: "SMIM31" },
  { key: "NM_001352888.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100996492", geneDisplay: "CTXND1" },
  { key: "NM_001352890.3", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "22898", geneDisplay: "DENND3" },
  { key: "NM_001352913.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5527", geneDisplay: "PPP2R5C" },
  { key: "NM_001352964.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "57706", geneDisplay: "DENND1A" },
  { key: "NM_001353108.3", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "80254", geneDisplay: "CEP63" },
  { key: "NM_001353179.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341350", geneDisplay: "OVCH1" },
  { key: "NM_001353214.3", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "54808", geneDisplay: "DYM" },
  { key: "NM_001353258.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "51571", geneDisplay: "CYRIB" },
  { key: "NM_001353345.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23067", geneDisplay: "SETD1B" },
  { key: "NM_001353425.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "198437", geneDisplay: "LKAAEAR1" },
  { key: "NM_001353450.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347442", geneDisplay: "DCAF8L2" },
  { key: "NM_001353453.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347475", geneDisplay: "CCDC160" },
  { key: "NM_001353486.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55064", geneDisplay: "SPATA6L" },
  { key: "NM_001353493.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "110599588", geneDisplay: "ASDURF" },
  { key: "NM_001353554.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "254439", geneDisplay: "C11orf86" },
  { key: "NM_001353655.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200008", geneDisplay: "CDCP2" },
  { key: "NM_001353694.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "7074", geneDisplay: "TIAM1" },
  { key: "NM_001353788.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "321", geneDisplay: "APBA2" },
  { key: "NM_001353803.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284459", geneDisplay: "ZNF875" },
  { key: "NM_001353812.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286410", geneDisplay: "ATP11C" },
  { key: "NM_001353824.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55713", geneDisplay: "ZNF334" },
  { key: "NM_001353921.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "23229", geneDisplay: "ARHGEF9" },
  { key: "NM_001354009.3", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724560", geneDisplay: "LOC102724560" },
  { key: "NM_001354046.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "8874", geneDisplay: "ARHGEF7" },
  { key: "NM_001354435.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285556", geneDisplay: "C4orf54" },
  { key: "NM_001354471.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "728588", geneDisplay: "MS4A18" },
  { key: "NM_001354473.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "401613", geneDisplay: "SERTM2" },
  { key: "NM_001354483.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "55790", geneDisplay: "CSGALNACT1" },
  { key: "NM_001354587.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "375248", geneDisplay: "ANKRD36" },
  { key: "NM_001354602.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100129924", geneDisplay: "TMEM269" },
  { key: "NM_001354604.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4286", geneDisplay: "MITF" },
  { key: "NM_001354631.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "345051", geneDisplay: "REELD1" },
  { key: "NM_001354639.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388428", geneDisplay: "PVALEF" },
  { key: "NM_001354640.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "100128908", geneDisplay: "CIROP" },
  { key: "NM_001354645.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "105376230", geneDisplay: "TEX53" },
  { key: "NM_001354658.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100506374", geneDisplay: "EDDM13" },
  { key: "NM_001354712.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7068", geneDisplay: "THRB" },
  { key: "NM_001354761.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "118", geneDisplay: "ADD1" },
  { key: "NM_001354768.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "4901", geneDisplay: "NRL" },
  { key: "NM_001354909.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "55269", geneDisplay: "PSPC1" },
  { key: "NM_001354930.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8737", geneDisplay: "RIPK1" },
  { key: "NM_001354969.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56890", geneDisplay: "MDM1" },
  { key: "NM_001354980.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "105373377", geneDisplay: "PNMA6F" },
  { key: "NM_001355003.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "101410538", geneDisplay: "MMP24OS" },
  { key: "NM_001355024.4", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84698", geneDisplay: "CAPS2" },
  { key: "NM_001355042.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387723", geneDisplay: "C10orf143" },
  { key: "NM_001355197.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7617", geneDisplay: "ZNF66" },
  { key: "NM_001355204.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10597", geneDisplay: "TRAPPC2B" },
  { key: "NM_001355213.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "26497", geneDisplay: "OR10D3" },
  { key: "NM_001355219.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79490", geneDisplay: "OR10G6" },
  { key: "NM_001355221.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "80086", geneDisplay: "TUBA4B" },
  { key: "NM_001355235.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127608", geneDisplay: "OR2AJ1" },
  { key: "NM_001355236.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "132200", geneDisplay: "C3orf49" },
  { key: "NM_001355237.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148203", geneDisplay: "ZNF738" },
  { key: "NM_001355243.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152217", geneDisplay: "NCBP2AS2" },
  { key: "NM_001355247.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "171222", geneDisplay: "UBE2L5" },
  { key: "NM_001355256.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "266655", geneDisplay: "BRD3OS" },
  { key: "NM_001355258.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "391769", geneDisplay: "H3Y1" },
  { key: "NM_001355263.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "283008", geneDisplay: "NUTM2E" },
  { key: "NM_001355274.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345630", geneDisplay: "FBLL1" },
  { key: "NM_001355276.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347549", geneDisplay: "CENPVL3" },
  { key: "NM_001355277.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389857", geneDisplay: "CENPVL1" },
  { key: "NM_001355278.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441495", geneDisplay: "CENPVL2" },
  { key: "NM_001355281.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "388112", geneDisplay: "NANOGP8" },
  { key: "NM_001355283.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388524", geneDisplay: "RPSAP58" },
  { key: "NM_001355292.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "388761", geneDisplay: "OR14A2" },
  { key: "NM_001355404.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "440519", geneDisplay: "ZNF724" },
  { key: "NM_001355407.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440672", geneDisplay: "NUDT4B" },
  { key: "NM_001355436.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6710", geneDisplay: "SPTB" },
  { key: "NM_001355444.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "642819", geneDisplay: "ZNF487" },
  { key: "NM_001355457.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "643136", geneDisplay: "ZC3H11B" },
  { key: "NM_001355469.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653075", geneDisplay: "GOLGA8T" },
  { key: "NM_001355476.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "727909", geneDisplay: "GOLGA8Q" },
  { key: "NM_001355479.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "728226", geneDisplay: "GGTLC3" },
  { key: "NM_001355519.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100129515", geneDisplay: "ETDB" },
  { key: "NM_001355522.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101928677", geneDisplay: "ETDA" },
  { key: "NM_001355526.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100130771", geneDisplay: "EFCAB10" },
  { key: "NM_001355534.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100132015", geneDisplay: "TEX13D" },
  { key: "NM_001355566.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100287520", geneDisplay: "GLYATL1B" },
  { key: "NM_001355570.4", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100506540", geneDisplay: "SPTY2D1OS" },
  { key: "NM_001355573.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101928991", geneDisplay: "CCDC92B" },
  { key: "NM_001355613.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "105371346", geneDisplay: "CPHXL" },
  { key: "NM_001356336.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "10317", geneDisplay: "B3GALT5" },
  { key: "NM_001357734.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "255308", geneDisplay: "EIF2S3B" },
  { key: "NM_001358008.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "64396", geneDisplay: "GMCL2" },
  { key: "NM_001358235.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54798", geneDisplay: "DCHS2" },
  { key: "NM_001358291.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80010", geneDisplay: "RMI1" },
  { key: "NM_001358345.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "80024", geneDisplay: "SLC8B1" },
  { key: "NM_001358351.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "80031", geneDisplay: "SEMA6D" },
  { key: "NM_001358410.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100126572", geneDisplay: "GJE1" },
  { key: "NM_001358413.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "649137", geneDisplay: "ZSCAN5C" },
  { key: "NM_001358416.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "554226", geneDisplay: "ANKRD30BL" },
  { key: "NM_001358418.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "100287045", geneDisplay: "FAM90A26" },
  { key: "NM_001358438.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "105378952", geneDisplay: "KLF18" },
  { key: "NM_001358449.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "110806299", geneDisplay: "ETDC" },
  { key: "NM_001358451.3", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "80167", geneDisplay: "ABHD18" },
  { key: "NM_001358530.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4337", geneDisplay: "MOCS1" },
  { key: "NM_001358661.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "110806298", geneDisplay: "C17orf113" },
  { key: "NM_001358664.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "25774", geneDisplay: "GSTT4" },
  { key: "NM_001358683.3", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "55018", geneDisplay: "ANKRD40CL" },
  { key: "NM_001358689.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "260334", geneDisplay: "TUBB8B" },
  { key: "NM_001358699.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284023", geneDisplay: "RNF227" },
  { key: "NM_001358921.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "27235", geneDisplay: "COQ2" },
  { key: "NM_001359084.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100133941", geneDisplay: "CD24" },
  { key: "NM_001359228.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10603", geneDisplay: "SH2B2" },
  { key: "NM_001360016.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2539", geneDisplay: "G6PD" },
  { key: "NM_001360236.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9929", geneDisplay: "JOSD1" },
  { key: "NM_001360452.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5110", geneDisplay: "PCMT1" },
  { key: "NM_001361041.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391059", geneDisplay: "FRRS1" },
  { key: "NM_001361665.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2247", geneDisplay: "FGF2" },
  { key: "NM_001362796.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "112441426", geneDisplay: "TMEM271" },
  { key: "NM_001362810.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "100419008", geneDisplay: "PRR20G" },
  { key: "NM_001362985.3", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10634", geneDisplay: "GAS2L1" },
  { key: "NM_001363059.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57509", geneDisplay: "MTUS1" },
  { key: "NM_001363066.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "7122", geneDisplay: "CLDN5" },
  { key: "NM_001363118.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79581", geneDisplay: "SLC52A2" },
  { key: "NM_001363254.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100874261", geneDisplay: "CCDC200" },
  { key: "NM_001363506.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "107983993", geneDisplay: "UPK3BL2" },
  { key: "NM_001363507.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "285423", geneDisplay: "IQCM" },
  { key: "NM_001363511.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "105378220", geneDisplay: "MARCOL" },
  { key: "NM_001363514.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "51207", geneDisplay: "DUSP13" },
  { key: "NM_001363519.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "255762", geneDisplay: "PDZD9" },
  { key: "NM_001363531.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118672", geneDisplay: "PSTK" },
  { key: "NM_001363538.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285966", geneDisplay: "TCAF2" },
  { key: "NM_001363540.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9732", geneDisplay: "DOCK4" },
  { key: "NM_001363541.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1627", geneDisplay: "DBN1" },
  { key: "NM_001363555.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4493", geneDisplay: "MT1E" },
  { key: "NM_001363562.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "256130", geneDisplay: "TMEM196" },
  { key: "NM_001363580.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79946", geneDisplay: "C10orf95" },
  { key: "NM_001363642.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147040", geneDisplay: "KCTD11" },
  { key: "NM_001363644.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219899", geneDisplay: "TBCEL" },
  { key: "NM_001363705.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23304", geneDisplay: "UBR2" },
  { key: "NM_001363711.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "50506", geneDisplay: "DUOX2" },
  { key: "NM_001363758.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724023", geneDisplay: "LOC102724023" },
  { key: "NM_001363764.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "146705", geneDisplay: "TEPSIN" },
  { key: "NM_001363770.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102723996", geneDisplay: "LOC102723996" },
  { key: "NM_001363794.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132946", geneDisplay: "ARL9" },
  { key: "NM_001363807.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347517", geneDisplay: "RAB41" },
  { key: "NM_001363818.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "345778", geneDisplay: "MTX3" },
  { key: "NM_001363830.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100506736", geneDisplay: "SLFN12L" },
  { key: "NM_001363845.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "55964", geneDisplay: "SEPTIN3" },
  { key: "NM_001363871.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "5136", geneDisplay: "PDE1A" },
  { key: "NM_001363886.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "348751", geneDisplay: "FTCDNL1" },
  { key: "NM_001363941.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25852", geneDisplay: "ARMC8" },
  { key: "NM_001364008.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100996763", geneDisplay: "NOTCH2NLB" },
  { key: "NM_001364013.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100996717", geneDisplay: "NOTCH2NLC" },
  { key: "NM_001364140.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "1456", geneDisplay: "CSNK1G3" },
  { key: "NM_001364171.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "93233", geneDisplay: "ODAD1" },
  { key: "NM_001364461.3", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "112577516", geneDisplay: "LOC112577516" },
  { key: "NM_001364564.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "6297", geneDisplay: "SALL2" },
  { key: "NM_001364614.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221656", geneDisplay: "KDM1B" },
  { key: "NM_001364669.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "110806296", geneDisplay: "C1orf232" },
  { key: "NM_001364674.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729458", geneDisplay: "MBD3L2B" },
  { key: "NM_001364677.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "107986096", geneDisplay: "MDFIC2" },
  { key: "NM_001364689.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "389197", geneDisplay: "C4orf50" },
  { key: "NM_001364708.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "107983988", geneDisplay: "SPEM3" },
  { key: "NM_001364711.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102723451", geneDisplay: "LOC102723451" },
  { key: "NM_001364716.4", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23164", geneDisplay: "MPRIP" },
  { key: "NM_001364730.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "7620", geneDisplay: "ZNF69" },
  { key: "NM_001364782.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283848", geneDisplay: "CES4A" },
  { key: "NM_001364791.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57101", geneDisplay: "ANO2" },
  { key: "NM_001364818.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "120863", geneDisplay: "DEPDC4" },
  { key: "NM_001364841.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157848", geneDisplay: "NKX6-3" },
  { key: "NM_001364857.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "576", geneDisplay: "ADGRB2" },
  { key: "NM_001364886.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6000", geneDisplay: "RGS7" },
  { key: "NM_001364905.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "987", geneDisplay: "LRBA" },
  { key: "NM_001364910.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "286187", geneDisplay: "PPP1R42" },
  { key: "NM_001364929.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23392", geneDisplay: "ECPAS" },
  { key: "NM_001365008.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100129669", geneDisplay: "IZUMO3" },
  { key: "NM_001365068.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "23245", geneDisplay: "ASTN2" },
  { key: "NM_001365077.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338667", geneDisplay: "VSIG10L2" },
  { key: "NM_001365088.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9990", geneDisplay: "SLC12A6" },
  { key: "NM_001365103.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "390937", geneDisplay: "ERFL" },
  { key: "NM_001365156.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "144347", geneDisplay: "RFLNA" },
  { key: "NM_001365174.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "101929469", geneDisplay: "TEX52" },
  { key: "NM_001365197.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "111064649", geneDisplay: "SMIM33" },
  { key: "NM_001365225.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "83440", geneDisplay: "ADPGK" },
  { key: "NM_001365242.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64506", geneDisplay: "CPEB1" },
  { key: "NM_001365276.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7148", geneDisplay: "TNXB" },
  { key: "NM_001365304.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112694756", geneDisplay: "LOC112694756" },
  { key: "NM_001365308.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "168667", geneDisplay: "BMPER" },
  { key: "NM_001365310.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "105371045", geneDisplay: "PERCC1" },
  { key: "NM_001365324.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64282", geneDisplay: "TENT4B" },
  { key: "NM_001365341.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "112441434", geneDisplay: "SHLD3" },
  { key: "NM_001365371.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "728310", geneDisplay: "GOLGA6L7" },
  { key: "NM_001365373.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "100132202", geneDisplay: "LOC100132202" },
  { key: "NM_001365389.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "105369274", geneDisplay: "OR4N4C" },
  { key: "NM_001365405.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "8824", geneDisplay: "CES2" },
  { key: "NM_001365443.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "102723899", geneDisplay: "PTTG1IP2" },
  { key: "NM_001365455.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "100507747", geneDisplay: "C13orf46" },
  { key: "NM_001365479.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55230", geneDisplay: "USP40" },
  { key: "NM_001365480.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55704", geneDisplay: "CCDC88A" },
  { key: "NM_001365536.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "6335", geneDisplay: "SCN9A" },
  { key: "NM_001365552.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "341676", geneDisplay: "NEK5" },
  { key: "NM_001365575.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "84865", geneDisplay: "CCDC142" },
  { key: "NM_001365588.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "22829", geneDisplay: "NLGN4Y" },
  { key: "NM_001365597.4", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55660", geneDisplay: "PRPF40A" },
  { key: "NM_001365613.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "6238", geneDisplay: "RRBP1" },
  { key: "NM_001365618.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "9762", geneDisplay: "LZTS3" },
  { key: "NM_001365621.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "22839", geneDisplay: "DLGAP4" },
  { key: "NM_001365631.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23122", geneDisplay: "CLASP2" },
  { key: "NM_001365635.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "23272", geneDisplay: "TASOR" },
  { key: "NM_001365672.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22837", geneDisplay: "COBLL1" },
  { key: "NM_001365692.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140706", geneDisplay: "CCM2L" },
  { key: "NM_001365693.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8972", geneDisplay: "MGAM" },
  { key: "NM_001365709.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140894", geneDisplay: "CNBD2" },
  { key: "NM_001365715.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "84859", geneDisplay: "LRCH3" },
  { key: "NM_001365788.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "641372", geneDisplay: "ACOT6" },
  { key: "NM_001365790.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "123103", geneDisplay: "KLHL33" },
  { key: "NM_001365792.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1600", geneDisplay: "DAB1" },
  { key: "NM_001365809.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9066", geneDisplay: "SYT7" },
  { key: "NM_001365892.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "388849", geneDisplay: "CCDC188" },
  { key: "NM_001365896.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4666", geneDisplay: "NACA" },
  { key: "NM_001365902.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "4784", geneDisplay: "NFIX" },
  { key: "NM_001365906.3", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "89932", geneDisplay: "PAPLN" },
  { key: "NM_001365915.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "390598", geneDisplay: "SKOR1" },
  { key: "NM_001365919.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "339287", geneDisplay: "MSL1" },
  { key: "NM_001365925.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "22871", geneDisplay: "NLGN1" },
  { key: "NM_001365951.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23095", geneDisplay: "KIF1B" },
  { key: "NM_001365977.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1731", geneDisplay: "SEPTIN1" },
  { key: "NM_001365999.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23334", geneDisplay: "SZT2" },
  { key: "NM_001366006.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23266", geneDisplay: "ADGRL2" },
  { key: "NM_001366028.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "201625", geneDisplay: "DNAH12" },
  { key: "NM_001366057.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54726", geneDisplay: "OTUD4" },
  { key: "NM_001366078.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221301", geneDisplay: "CALHM4" },
  { key: "NM_001366102.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "91646", geneDisplay: "TDRD12" },
  { key: "NM_001366110.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5078", geneDisplay: "PAX4" },
  { key: "NM_001366122.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "375616", geneDisplay: "KCP" },
  { key: "NM_001366145.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "80036", geneDisplay: "TRPM3" },
  { key: "NM_001366157.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "151790", geneDisplay: "WDR49" },
  { key: "NM_001366165.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55225", geneDisplay: "RAVER2" },
  { key: "NM_001366178.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "115703", geneDisplay: "ARHGAP33" },
  { key: "NM_001366207.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1739", geneDisplay: "DLG1" },
  { key: "NM_001366230.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "79822", geneDisplay: "ARHGAP28" },
  { key: "NM_001366232.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148581", geneDisplay: "UBE2U" },
  { key: "NM_001366244.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2801", geneDisplay: "GOLGA2" },
  { key: "NM_001366245.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "286826", geneDisplay: "LIN9" },
  { key: "NM_001366282.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "2804", geneDisplay: "GOLGB1" },
  { key: "NM_001366285.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6862", geneDisplay: "TBXT" },
  { key: "NM_001366293.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "1347", geneDisplay: "COX7A2" },
  { key: "NM_001366298.2", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8537", geneDisplay: "BCAS1" },
  { key: "NM_001366299.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8570", geneDisplay: "KHSRP" },
  { key: "NM_001366301.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "147011", geneDisplay: "PROCA1" },
  { key: "NM_001366306.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3841", geneDisplay: "KPNA5" },
  { key: "NM_001366318.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8603", geneDisplay: "FAM193A" },
  { key: "NM_001366335.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64770", geneDisplay: "CCDC14" },
  { key: "NM_001366385.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "79092", geneDisplay: "CARD14" },
  { key: "NM_001366386.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65061", geneDisplay: "CDK15" },
  { key: "NM_001366418.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9869", geneDisplay: "SETDB1" },
  { key: "NM_001366432.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "55671", geneDisplay: "PPP4R3A" },
  { key: "NM_001366446.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9910", geneDisplay: "RABGAP1L" },
  { key: "NM_001366458.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285761", geneDisplay: "DCBLD1" },
  { key: "NM_001366481.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285855", geneDisplay: "RPL7L1" },
  { key: "NM_001366508.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "285704", geneDisplay: "RGMB" },
  { key: "NM_001366521.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "490", geneDisplay: "ATP2B1" },
  { key: "NM_001366544.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "4033", geneDisplay: "IRAG2" },
  { key: "NM_001366661.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "23277", geneDisplay: "CLUH" },
  { key: "NM_001366673.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "23333", geneDisplay: "DPY19L1" },
  { key: "NM_001366683.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "23348", geneDisplay: "DOCK9" },
  { key: "NM_001366686.3", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23387", geneDisplay: "SIK3" },
  { key: "NM_001366722.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23426", geneDisplay: "GRIP1" },
  { key: "NM_001366735.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "64062", geneDisplay: "RBM26" },
  { key: "NM_001366737.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51301", geneDisplay: "GCNT4" },
  { key: "NM_001366781.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57489", geneDisplay: "ODF2L" },
  { key: "NM_001366845.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "64397", geneDisplay: "ZNF106" },
  { key: "NM_001366854.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "114795", geneDisplay: "TMEM132B" },
  { key: "NM_001366900.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "199223", geneDisplay: "TTC21A" },
  { key: "NM_001366906.2", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "219621", geneDisplay: "CABCOCO1" },
  { key: "NM_001366977.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139728", geneDisplay: "PNCK" },
  { key: "NM_001367172.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284390", geneDisplay: "ZNF763" },
  { key: "NM_001367178.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "283643", geneDisplay: "TEDC1" },
  { key: "NM_001367233.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9843", geneDisplay: "HEPH" },
  { key: "NM_001367292.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284194", geneDisplay: "LGALS9B" },
  { key: "NM_001367314.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57673", geneDisplay: "BEND3" },
  { key: "NM_001367348.2", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "388820", geneDisplay: "SMIM34" },
  { key: "NM_001367365.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "102723796", geneDisplay: "TLE7" },
  { key: "NM_001367479.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127602", geneDisplay: "DNAH14" },
  { key: "NM_001367482.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "128025", geneDisplay: "WDR64" },
  { key: "NM_001367484.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148979", geneDisplay: "GLIS1" },
  { key: "NM_001367493.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50649", geneDisplay: "ARHGEF4" },
  { key: "NM_001367498.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "129684", geneDisplay: "CNTNAP5" },
  { key: "NM_001367502.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339761", geneDisplay: "CYP27C1" },
  { key: "NM_001367534.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "818", geneDisplay: "CAMK2G" },
  { key: "NM_001367549.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79572", geneDisplay: "ATP13A3" },
  { key: "NM_001367551.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "391365", geneDisplay: "SULT6B1" },
  { key: "NM_001367561.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "85440", geneDisplay: "DOCK7" },
  { key: "NM_001367607.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "374860", geneDisplay: "ANKRD30B" },
  { key: "NM_001367614.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55184", geneDisplay: "DZANK1" },
  { key: "NM_001367624.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84627", geneDisplay: "ZNF469" },
  { key: "NM_001367656.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "83851", geneDisplay: "SYT16" },
  { key: "NM_001367710.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91748", geneDisplay: "MIDEAS" },
  { key: "NM_001367711.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "3274", geneDisplay: "HRH2" },
  { key: "NM_001367721.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8573", geneDisplay: "CASK" },
  { key: "NM_001367757.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10838", geneDisplay: "ZNF275" },
  { key: "NM_001367767.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100271927", geneDisplay: "RASA4B" },
  { key: "NM_001367770.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "649238", geneDisplay: "PNMA6E" },
  { key: "NM_001367773.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "57488", geneDisplay: "ESYT2" },
  { key: "NM_001367774.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "256643", geneDisplay: "BCLAF3" },
  { key: "NM_001367799.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "23053", geneDisplay: "ZSWIM8" },
  { key: "NM_001367801.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118491", geneDisplay: "CFAP70" },
  { key: "NM_001367805.3", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9493", geneDisplay: "KIF23" },
  { key: "NM_001367806.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "26108", geneDisplay: "PYGO1" },
  { key: "NM_001367807.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "283807", geneDisplay: "FBXL22" },
  { key: "NM_001367823.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23370", geneDisplay: "ARHGEF18" },
  { key: "NM_001367834.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "65249", geneDisplay: "ZSWIM4" },
  { key: "NM_001367856.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "148137", geneDisplay: "PROSER3" },
  { key: "NM_001367857.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340562", geneDisplay: "SATL1" },
  { key: "NM_001367868.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729359", geneDisplay: "PLIN4" },
  { key: "NM_001367871.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "57666", geneDisplay: "FBRSL1" },
  { key: "NM_001367873.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55553", geneDisplay: "SOX6" },
  { key: "NM_001367886.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "92558", geneDisplay: "BICDL1" },
  { key: "NM_001367909.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339500", geneDisplay: "ZNF678" },
  { key: "NM_001367916.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84061", geneDisplay: "MAGT1" },
  { key: "NM_001367943.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "6934", geneDisplay: "TCF7L2" },
  { key: "NM_001367949.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120114", geneDisplay: "FAT3" },
  { key: "NM_001367956.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340069", geneDisplay: "FAM170A" },
  { key: "NM_001367969.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "391013", geneDisplay: "PLA2G2C" },
  { key: "NM_001367975.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54766", geneDisplay: "BTG4" },
  { key: "NM_001367977.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57758", geneDisplay: "SCUBE2" },
  { key: "NM_001368120.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "64236", geneDisplay: "PDLIM2" },
  { key: "NM_001368128.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "105371242", geneDisplay: "PPIAL4H" },
  { key: "NM_001368135.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "2310", geneDisplay: "FOXO3B" },
  { key: "NM_001368158.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "100996631", geneDisplay: "TCP11X1" },
  { key: "NM_001368160.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "101928108", geneDisplay: "LY6L" },
  { key: "NM_001368163.3", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "110806279", geneDisplay: "SMIM28" },
  { key: "NM_001368165.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "111064647", geneDisplay: "CSNKA2IP" },
  { key: "NM_001368238.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102724159", geneDisplay: "LOC102724159" },
  { key: "NM_001368369.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "440956", geneDisplay: "IQCF6" },
  { key: "NM_001368397.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "9758", geneDisplay: "FRMPD4" },
  { key: "NM_001368771.2", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5414", geneDisplay: "SEPTIN4" },
  { key: "NM_001368809.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "271", geneDisplay: "AMPD2" },
  { key: "NM_001368882.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1305", geneDisplay: "COL13A1" },
  { key: "NM_001368894.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "5080", geneDisplay: "PAX6" },
  { key: "NM_001369057.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "245915", geneDisplay: "DEFB112" },
  { key: "NM_001369125.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "114483833", geneDisplay: "H2BK1" },
  { key: "NM_001369201.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "107984345", geneDisplay: "SMIM38" },
  { key: "NM_001369203.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "113523636", geneDisplay: "SMIM40" },
  { key: "NM_001369216.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "113523638", geneDisplay: "SMIM41" },
  { key: "NM_001369268.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "176", geneDisplay: "ACAN" },
  { key: "NM_001369369.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8456", geneDisplay: "FOXN1" },
  { key: "NM_001369441.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "60491", geneDisplay: "NIF3L1" },
  { key: "NM_001369450.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "54663", geneDisplay: "WDR74" },
  { key: "NM_001369496.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "374403", geneDisplay: "TBC1D10C" },
  { key: "NM_001369500.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101060376", geneDisplay: "TBC1D3L" },
  { key: "NM_001369521.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "56658", geneDisplay: "TRIM39" },
  { key: "NM_001369598.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "7982", geneDisplay: "ST7" },
  { key: "NM_001369623.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5298", geneDisplay: "PI4KB" },
  { key: "NM_001369667.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146439", geneDisplay: "BICDL2" },
  { key: "NM_001369741.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140685", geneDisplay: "ZBTB46" },
  { key: "NM_001369769.2", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "90990", geneDisplay: "KIFC2" },
  { key: "NM_001369775.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "43847", geneDisplay: "KLK14" },
  { key: "NM_001369783.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4291", geneDisplay: "MLF1" },
  { key: "NM_001369789.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84939", geneDisplay: "PWWP3A" },
  { key: "NM_001369817.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23507", geneDisplay: "LRRC8B" },
  { key: "NM_001369863.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "222389", geneDisplay: "BEND7" },
  { key: "NM_001369869.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "102723475", geneDisplay: "LOC102723475" },
  { key: "NM_001369919.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "64091", geneDisplay: "POPDC2" },
  { key: "NM_001370062.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "55833", geneDisplay: "UBAP2" },
  { key: "NM_001370087.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2867", geneDisplay: "FFAR2" },
  { key: "NM_001370095.3", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "79883", geneDisplay: "PODNL1" },
  { key: "NM_001370096.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "646643", geneDisplay: "SBK2" },
  { key: "NM_001370100.5", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "10771", geneDisplay: "ZMYND11" },
  { key: "NM_001370125.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "43849", geneDisplay: "KLK12" },
  { key: "NM_001370129.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "90827", geneDisplay: "ZNF479" },
  { key: "NM_001370148.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "441519", geneDisplay: "CT45A3" },
  { key: "NM_001370150.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "81875", geneDisplay: "ISG20L2" },
  { key: "NM_001370158.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "139604", geneDisplay: "MAGEB16" },
  { key: "NM_001370165.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "94121", geneDisplay: "SYTL4" },
  { key: "NM_001370181.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "79807", geneDisplay: "GSTCD" },
  { key: "NM_001370184.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "102724488", geneDisplay: "SYT15B" },
  { key: "NM_001370198.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64180", geneDisplay: "DPEP3" },
  { key: "NM_001370215.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58491", geneDisplay: "ZNF71" },
  { key: "NM_001370259.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "4221", geneDisplay: "MEN1" },
  { key: "NM_001370285.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "92797", geneDisplay: "HELB" },
  { key: "NM_001370298.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "121512", geneDisplay: "FGD4" },
  { key: "NM_001370299.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "347902", geneDisplay: "AMIGO2" },
  { key: "NM_001370300.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "51188", geneDisplay: "SS18L2" },
  { key: "NM_001370302.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "441631", geneDisplay: "TSPAN11" },
  { key: "NM_001370326.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162282", geneDisplay: "ANKFN1" },
  { key: "NM_001370338.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "6542", geneDisplay: "SLC7A2" },
  { key: "NM_001370348.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23469", geneDisplay: "PHF3" },
  { key: "NM_001370368.2", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "647264", geneDisplay: "LOC647264" },
  { key: "NM_001370374.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "10781", geneDisplay: "ZNF266" },
  { key: "NM_001370414.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "114841035", geneDisplay: "LOC114841035" },
  { key: "NM_001370449.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84765", geneDisplay: "ZNF577" },
  { key: "NM_001370461.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "89944", geneDisplay: "GLB1L2" },
  { key: "NM_001370464.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "117195", geneDisplay: "MRGPRX3" },
  { key: "NM_001370465.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285193", geneDisplay: "DUSP28" },
  { key: "NM_001370466.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64127", geneDisplay: "NOD2" },
  { key: "NM_001370470.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29903", geneDisplay: "CCDC106" },
  { key: "NM_001370472.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57472", geneDisplay: "CNOT6" },
  { key: "NM_001370475.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "89778", geneDisplay: "SERPINB11" },
  { key: "NM_001370476.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "51000", geneDisplay: "SLC35B3" },
  { key: "NM_001370485.4", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "26664", geneDisplay: "OR7C1" },
  { key: "NM_001370497.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "85320", geneDisplay: "ABCC11" },
  { key: "NM_001370523.4", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "348174", geneDisplay: "CLEC18A" },
  { key: "NM_001370549.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "162515", geneDisplay: "SLC16A11" },
  { key: "NM_001370592.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57409", geneDisplay: "MIF4GD" },
  { key: "NM_001370595.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "84334", geneDisplay: "COA8" },
  { key: "NM_001370597.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57198", geneDisplay: "ATP8B2" },
  { key: "NM_001370640.3", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "4992", geneDisplay: "OR1F1" },
  { key: "NM_001370658.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "686", geneDisplay: "BTD" },
  { key: "NM_001370687.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6954", geneDisplay: "TCP11" },
  { key: "NM_001370694.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "50636", geneDisplay: "ANO7" },
  { key: "NM_001370704.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "400499", geneDisplay: "LOC400499" },
  { key: "NM_001370785.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "57554", geneDisplay: "LRRC7" },
  { key: "NM_001370809.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "79842", geneDisplay: "ZBTB3" },
  { key: "NM_001370959.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "11281", geneDisplay: "POU6F2" },
  { key: "NM_001371072.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8237", geneDisplay: "USP11" },
  { key: "NM_001371116.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "85462", geneDisplay: "FHDC1" },
  { key: "NM_001371189.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10497", geneDisplay: "UNC13B" },
  { key: "NM_001371194.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "10507", geneDisplay: "SEMA4D" },
  { key: "NM_001371237.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "138065", geneDisplay: "RNF183" },
  { key: "NM_001371242.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "202", geneDisplay: "CRYBG1" },
  { key: "NM_001371272.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "26056", geneDisplay: "RAB11FIP5" },
  { key: "NM_001371273.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57624", geneDisplay: "NYAP2" },
  { key: "NM_001371279.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65055", geneDisplay: "REEP1" },
  { key: "NM_001371321.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "130813", geneDisplay: "C2orf50" },
  { key: "NM_001371333.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56616", geneDisplay: "DIABLO" },
  { key: "NM_001371340.3", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100288695", geneDisplay: "LIMS4" },
  { key: "NM_001371389.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150726", geneDisplay: "FBXO41" },
  { key: "NM_001371390.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "170482", geneDisplay: "CLEC4C" },
  { key: "NM_001371395.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "54532", geneDisplay: "USP53" },
  { key: "NM_001371415.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "59272", geneDisplay: "ACE2" },
  { key: "NM_001371417.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "400935", geneDisplay: "IL17REL" },
  { key: "NM_001371445.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "124801", geneDisplay: "LSM12" },
  { key: "NM_001371533.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2530", geneDisplay: "FUT8" },
  { key: "NM_001371558.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "10563", geneDisplay: "CXCL13" },
  { key: "NM_001371589.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "58525", geneDisplay: "WIZ" },
  { key: "NM_001371596.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "256471", geneDisplay: "MFSD8" },
  { key: "NM_001371623.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6949", geneDisplay: "TCOF1" },
  { key: "NM_001371720.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4582", geneDisplay: "MUC1" },
  { key: "NM_001371727.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "2561", geneDisplay: "GABRB2" },
  { key: "NM_001371762.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "912", geneDisplay: "CD1D" },
  { key: "NM_001371904.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "116519", geneDisplay: "APOA5" },
  { key: "NM_001371909.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "256815", geneDisplay: "C10orf67" },
  { key: "NM_001371910.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10746", geneDisplay: "MAP3K2" },
  { key: "NM_001371919.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "340096", geneDisplay: "H3Y2" },
  { key: "NM_001371928.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "27245", geneDisplay: "AHDC1" },
  { key: "NM_001371938.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "10344", geneDisplay: "CCL26" },
  { key: "NM_001371986.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285175", geneDisplay: "UNC80" },
  { key: "NM_001372043.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5125", geneDisplay: "PCSK5" },
  { key: "NM_001372044.2", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "85358", geneDisplay: "SHANK3" },
  { key: "NM_001372051.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "841", geneDisplay: "CASP8" },
  { key: "NM_001372052.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "50809", geneDisplay: "HP1BP3" },
  { key: "NM_001372053.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "256006", geneDisplay: "ANKRD31" },
  { key: "NM_001372060.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "647589", geneDisplay: "ANHX" },
  { key: "NM_001372062.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200150", geneDisplay: "PLD5" },
  { key: "NM_001372066.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7020", geneDisplay: "TFAP2A" },
  { key: "NM_001372073.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "5157", geneDisplay: "PDGFRL" },
  { key: "NM_001372076.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "5083", geneDisplay: "PAX9" },
  { key: "NM_001372078.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "5980", geneDisplay: "REV3L" },
  { key: "NM_001372080.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "146050", geneDisplay: "ZSCAN29" },
  { key: "NM_001372081.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "221409", geneDisplay: "SPATS1" },
  { key: "NM_001372105.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "440686", geneDisplay: "H3-7" },
  { key: "NM_001372106.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "196385", geneDisplay: "DNAH10" },
  { key: "NM_001372107.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "64718", geneDisplay: "UNKL" },
  { key: "NM_001372108.2", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8528", geneDisplay: "DDO" },
  { key: "NM_001372123.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "64376", geneDisplay: "IKZF5" },
  { key: "NM_001372163.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "79057", geneDisplay: "PRRG3" },
  { key: "NM_001372179.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80336", geneDisplay: "PABPC1L" },
  { key: "NM_001372327.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2030", geneDisplay: "SLC29A1" },
  { key: "NM_001372574.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6311", geneDisplay: "ATXN2" },
  { key: "NM_001374259.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3595", geneDisplay: "IL12RB2" },
  { key: "NM_001374353.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2736", geneDisplay: "GLI2" },
  { key: "NM_001374385.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "5205", geneDisplay: "ATP8B1" },
  { key: "NM_001374504.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "164656", geneDisplay: "TMPRSS6" },
  { key: "NM_001374623.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "285848", geneDisplay: "PNPLA1" },
  { key: "NM_001374675.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "3299", geneDisplay: "HSF4" },
  { key: "NM_001374736.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "667", geneDisplay: "DST" },
  { key: "NM_001374828.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57492", geneDisplay: "ARID1B" },
  { key: "NM_001374838.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "116033993", geneDisplay: "FOXL3" },
  { key: "NM_001375380.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "253738", geneDisplay: "EBF3" },
  { key: "NM_001375405.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "153241", geneDisplay: "CEP120" },
  { key: "NM_001375462.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4026", geneDisplay: "LPP" },
  { key: "NM_001375484.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "1159", geneDisplay: "CKMT1B" },
  { key: "NM_001375505.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4133", geneDisplay: "MAP2" },
  { key: "NM_001375524.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "8295", geneDisplay: "TRRAP" },
  { key: "NM_001375547.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "25890", geneDisplay: "ABI3BP" },
  { key: "NM_001375567.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "54914", geneDisplay: "FOCAD" },
  { key: "NM_001375584.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9887", geneDisplay: "SMG7" },
  { key: "NM_001375635.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "56990", geneDisplay: "CDC42SE2" },
  { key: "NM_001375657.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "102723971", geneDisplay: "LOC102723971" },
  { key: "NM_001375670.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10152", geneDisplay: "ABI2" },
  { key: "NM_001375765.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "64599", geneDisplay: "GIGYF1" },
  { key: "NM_001375808.2", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "9663", geneDisplay: "LPIN2" },
  { key: "NM_001375834.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7456", geneDisplay: "WIPF1" },
  { key: "NM_001375847.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100133315", geneDisplay: "XNDC1N" },
  { key: "NM_001375883.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23432", geneDisplay: "GPR161" },
  { key: "NM_001375905.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "166929", geneDisplay: "SGMS2" },
  { key: "NM_001375912.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "55205", geneDisplay: "ZNF532" },
  { key: "NM_001375978.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1131", geneDisplay: "CHRM3" },
  { key: "NM_001376000.2", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "728340", geneDisplay: "GTF2H2C" },
  { key: "NM_001376007.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "91607", geneDisplay: "SLFN11" },
  { key: "NM_001376013.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2035", geneDisplay: "EPB41" },
  { key: "NM_001376049.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "26049", geneDisplay: "FAM169A" },
  { key: "NM_001376113.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "253461", geneDisplay: "ZBTB38" },
  { key: "NM_001376131.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284697", geneDisplay: "BTBD8" },
  { key: "NM_001376223.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "100293516", geneDisplay: "ZNF587B" },
  { key: "NM_001376232.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7783", geneDisplay: "ZP2" },
  { key: "NM_001376256.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1428", geneDisplay: "CRYM" },
  { key: "NM_001376312.2", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79712", geneDisplay: "GTDC1" },
  { key: "NM_001376342.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9877", geneDisplay: "ZC3H11A" },
  { key: "NM_001376376.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "10658", geneDisplay: "CELF1" },
  { key: "NM_001376491.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7718", geneDisplay: "ZNF165" },
  { key: "NM_001376558.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "23647", geneDisplay: "ARFIP2" },
  { key: "NM_001376587.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3428", geneDisplay: "IFI16" },
  { key: "NM_001376665.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55793", geneDisplay: "MINDY1" },
  { key: "NM_001376852.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "57583", geneDisplay: "TMEM181" },
  { key: "NM_001376861.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127124", geneDisplay: "ATP6V1G3" },
  { key: "NM_001376887.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "8740", geneDisplay: "TNFSF14" },
  { key: "NM_001376922.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7162", geneDisplay: "TPBG" },
  { key: "NM_001376923.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9235", geneDisplay: "IL32" },
  { key: "NM_001376924.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "10170", geneDisplay: "DHRS9" },
  { key: "NM_001376932.3", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "359710", geneDisplay: "BPIFB3" },
  { key: "NM_001376937.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "8351", geneDisplay: "H3C4" },
  { key: "NM_001377137.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "8729", geneDisplay: "GBF1" },
  { key: "NM_001377142.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5332", geneDisplay: "PLCB4" },
  { key: "NM_001377229.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84976", geneDisplay: "DISP1" },
  { key: "NM_001377236.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57491", geneDisplay: "AHRR" },
  { key: "NM_001377265.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "4137", geneDisplay: "MAPT" },
  { key: "NM_001377275.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8863", geneDisplay: "PER3" },
  { key: "NM_001377295.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2780", geneDisplay: "GNAT2" },
  { key: "NM_001377299.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "4720", geneDisplay: "NDUFS2" },
  { key: "NM_001377303.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "26013", geneDisplay: "L3MBTL1" },
  { key: "NM_001377304.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8328", geneDisplay: "GFI1B" },
  { key: "NM_001377321.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "10349", geneDisplay: "ABCA10" },
  { key: "NM_001377329.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "440107", geneDisplay: "PLEKHG7" },
  { key: "NM_001377334.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5287", geneDisplay: "PIK3C2B" },
  { key: "NM_001377376.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7579", geneDisplay: "ZSCAN20" },
  { key: "NM_001377405.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6314", geneDisplay: "ATXN7" },
  { key: "NM_001377440.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55805", geneDisplay: "LRP2BP" },
  { key: "NM_001377448.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57597", geneDisplay: "BAHCC1" },
  { key: "NM_001377458.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23155", geneDisplay: "CLCC1" },
  { key: "NM_001377500.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "79825", geneDisplay: "EFCC1" },
  { key: "NM_001377530.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1755", geneDisplay: "DMBT1" },
  { key: "NM_001377533.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "79758", geneDisplay: "DHRS12" },
  { key: "NM_001377534.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343521", geneDisplay: "DYNLT4" },
  { key: "NM_001377540.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "7871", geneDisplay: "SLMAP" },
  { key: "NM_001377935.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2889", geneDisplay: "RAPGEF1" },
  { key: "NM_001377960.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "389677", geneDisplay: "RBM12B" },
  { key: "NM_001377989.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "90362", geneDisplay: "FAM110B" },
  { key: "NM_001377996.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "5475", geneDisplay: "PPEF1" },
  { key: "NM_001378024.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9743", geneDisplay: "ARHGAP32" },
  { key: "NM_001378026.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "65065", geneDisplay: "NBEAL1" },
  { key: "NM_001378030.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "124093", geneDisplay: "CCDC78" },
  { key: "NM_001378034.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "83891", geneDisplay: "SNX25" },
  { key: "NM_001378067.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9110", geneDisplay: "MTMR4" },
  { key: "NM_001378068.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "150709", geneDisplay: "ANKAR" },
  { key: "NM_001378074.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "91653", geneDisplay: "BOC" },
  { key: "NM_001378090.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100287171", geneDisplay: "WASHC1" },
  { key: "NM_001378100.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "753", geneDisplay: "LDLRAD4" },
  { key: "NM_001378102.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "474354", geneDisplay: "LRRC18" },
  { key: "NM_001378107.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23518", geneDisplay: "R3HDM1" },
  { key: "NM_001378120.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "55777", geneDisplay: "MBD5" },
  { key: "NM_001378122.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "152503", geneDisplay: "SH3D19" },
  { key: "NM_001378156.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "713", geneDisplay: "C1QB" },
  { key: "NM_001378157.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "143570", geneDisplay: "XRRA1" },
  { key: "NM_001378183.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "63895", geneDisplay: "PIEZO2" },
  { key: "NM_001378188.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "399693", geneDisplay: "CCDC187" },
  { key: "NM_001378189.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "149465", geneDisplay: "CFAP57" },
  { key: "NM_001378204.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343099", geneDisplay: "CCDC18" },
  { key: "NM_001378211.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158401", geneDisplay: "SHOC1" },
  { key: "NM_001378213.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "283149", geneDisplay: "BCL9L" },
  { key: "NM_001378328.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "9620", geneDisplay: "CELSR1" },
  { key: "NM_001378373.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "4153", geneDisplay: "MBL2" },
  { key: "NM_001378414.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9759", geneDisplay: "HDAC4" },
  { key: "NM_001378418.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6942", geneDisplay: "TCF20" },
  { key: "NM_001378423.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285955", geneDisplay: "SPDYE1" },
  { key: "NM_001378452.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "3708", geneDisplay: "ITPR1" },
  { key: "NM_001378454.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7840", geneDisplay: "ALMS1" },
  { key: "NM_001378457.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "23312", geneDisplay: "DMXL2" },
  { key: "NM_001378477.3", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "60506", geneDisplay: "NYX" },
  { key: "NM_001378600.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "105372267", geneDisplay: "NFILZ" },
  { key: "NM_001378609.3", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283310", geneDisplay: "OTOGL" },
  { key: "NM_001378615.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57545", geneDisplay: "CC2D2A" },
  { key: "NM_001378687.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "27032", geneDisplay: "ATP2C1" },
  { key: "NM_001378743.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1540", geneDisplay: "CYLD" },
  { key: "NM_001378778.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "8777", geneDisplay: "MPDZ" },
  { key: "NM_001378785.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100421372", geneDisplay: "LOC100421372" },
  { key: "NM_001378789.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "204219", geneDisplay: "CERS3" },
  { key: "NM_001378902.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "6098", geneDisplay: "ROS1" },
  { key: "NM_001378964.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "50937", geneDisplay: "CDON" },
  { key: "NM_001378969.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3752", geneDisplay: "KCND3" },
  { key: "NM_001378974.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23291", geneDisplay: "FBXW11" },
  { key: "NM_001379029.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10087", geneDisplay: "CERT1" },
  { key: "NM_001379081.2", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "158326", geneDisplay: "FREM1" },
  { key: "NM_001379110.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10479", geneDisplay: "SLC9A6" },
  { key: "NM_001379150.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "8471", geneDisplay: "IRS4" },
  { key: "NM_001379180.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "2103", geneDisplay: "ESRRB" },
  { key: "NM_001379200.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "6899", geneDisplay: "TBX1" },
  { key: "NM_001379210.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "115286", geneDisplay: "SLC25A26" },
  { key: "NM_001379228.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "56246", geneDisplay: "MRAP" },
  { key: "NM_001379270.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1259", geneDisplay: "CNGA1" },
  { key: "NM_001379286.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "23090", geneDisplay: "ZNF423" },
  { key: "NM_001379291.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23476", geneDisplay: "BRD4" },
  { key: "NM_001379301.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127540", geneDisplay: "HMGB4" },
  { key: "NM_001379313.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "58477", geneDisplay: "SRPRB" },
  { key: "NM_001379403.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "80232", geneDisplay: "WDR26" },
  { key: "NM_001379451.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "63035", geneDisplay: "BCORL1" },
  { key: "NM_001379500.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "80781", geneDisplay: "COL18A1" },
  { key: "NM_001379610.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "6690", geneDisplay: "SPINK1" },
  { key: "NM_001379659.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7701", geneDisplay: "ZNF142" },
  { key: "NM_001379692.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "624", geneDisplay: "BDKRB2" },
  { key: "NM_001381853.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1122", geneDisplay: "CHML" },
  { key: "NM_001381865.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1104", geneDisplay: "RCC1" },
  { key: "NM_001381874.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "642574", geneDisplay: "IQANK1" },
  { key: "NM_001381902.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55511", geneDisplay: "SAGE1" },
  { key: "NM_001381946.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "353345", geneDisplay: "GPR141" },
  { key: "NM_001381984.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "7571", geneDisplay: "ZNF23" },
  { key: "NM_001382000.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9720", geneDisplay: "CCDC144A" },
  { key: "NM_001382226.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8079", geneDisplay: "MLF2" },
  { key: "NM_001382241.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "30000", geneDisplay: "TNPO2" },
  { key: "NM_001382266.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "84900", geneDisplay: "RNFT2" },
  { key: "NM_001382267.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145264", geneDisplay: "SERPINA12" },
  { key: "NM_001382273.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10188", geneDisplay: "TNK2" },
  { key: "NM_001382280.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "105378803", geneDisplay: "LRRC53" },
  { key: "NM_001382289.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "2488", geneDisplay: "FSHB" },
  { key: "NM_001382293.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "10542", geneDisplay: "LAMTOR5" },
  { key: "NM_001382294.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100188893", geneDisplay: "TOMM6" },
  { key: "NM_001382304.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "728130", geneDisplay: "NUTM2D" },
  { key: "NM_001382309.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "56970", geneDisplay: "ATXN7L3" },
  { key: "NM_001382323.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "63876", geneDisplay: "PKNOX2" },
  { key: "NM_001382344.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "400966", geneDisplay: "RGPD1" },
  { key: "NM_001382345.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "342900", geneDisplay: "LEUTX" },
  { key: "NM_001382347.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "4644", geneDisplay: "MYO5A" },
  { key: "NM_001382358.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "649330", geneDisplay: "HNRNPCL3" },
  { key: "NM_001382359.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284018", geneDisplay: "C17orf58" },
  { key: "NM_001382360.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "282890", geneDisplay: "ZNF311" },
  { key: "NM_001382368.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "285095", geneDisplay: "FAM240C" },
  { key: "NM_001382391.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "79848", geneDisplay: "CSPP1" },
  { key: "NM_001382403.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "137835", geneDisplay: "TMEM71" },
  { key: "NM_001382417.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "84941", geneDisplay: "HSH2D" },
  { key: "NM_001382422.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90332", geneDisplay: "EXOC3L2" },
  { key: "NM_001382430.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "207", geneDisplay: "AKT1" },
  { key: "NM_001382446.2", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "102723623", geneDisplay: "LOC102723623" },
  { key: "NM_001382447.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "256227", geneDisplay: "STEAP1B" },
  { key: "NM_001382496.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "135927", geneDisplay: "LLCFC1" },
  { key: "NM_001382497.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "100509620", geneDisplay: "AQP7B" },
  { key: "NM_001382505.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "643862", geneDisplay: "SPDYE10" },
  { key: "NM_001382507.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "27109", geneDisplay: "DMAC2L" },
  { key: "NM_001382508.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "29102", geneDisplay: "DROSHA" },
  { key: "NM_001382525.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "728524", geneDisplay: "SPDYE8" },
  { key: "NM_001382547.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "105180391", geneDisplay: "SPDYE15" },
  { key: "NM_001382548.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "10915", geneDisplay: "TCERG1" },
  { key: "NM_001382554.3", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "643909", geneDisplay: "SPDYE9" },
  { key: "NM_001382555.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100101268", geneDisplay: "SPDYE12" },
  { key: "NM_001382563.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "105180390", geneDisplay: "SPDYE13" },
  { key: "NM_001382567.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "6786", geneDisplay: "STIM1" },
  { key: "NM_001382585.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "2681", geneDisplay: "GGTA1" },
  { key: "NM_001382637.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "161725", geneDisplay: "OTUD7A" },
  { key: "NM_001382683.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "10150", geneDisplay: "MBNL2" },
  { key: "NM_001382715.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "442572", geneDisplay: "SPDYE21" },
  { key: "NM_001382779.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54620", geneDisplay: "FBXL19" },
  { key: "NM_001384125.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "84162", geneDisplay: "KIAA1109" },
  { key: "NM_001384133.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3249", geneDisplay: "HPN" },
  { key: "NM_001384134.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "205251", geneDisplay: "MTLN" },
  { key: "NM_001384140.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "65217", geneDisplay: "PCDH15" },
  { key: "NM_001384156.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "54039", geneDisplay: "PCBP3" },
  { key: "NM_001384189.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100996521", geneDisplay: "CTXND2" },
  { key: "NM_001384237.2", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "107986818", geneDisplay: "FAM237B" },
  { key: "NM_001384253.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442213", geneDisplay: "PTCHD4" },
  { key: "NM_001384272.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3062", geneDisplay: "HCRTR2" },
  { key: "NM_001384290.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "3135", geneDisplay: "HLA-G" },
  { key: "NM_001384317.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "23051", geneDisplay: "ZHX3" },
  { key: "NM_001384332.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "132332", geneDisplay: "SMIM43" },
  { key: "NM_001384355.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "642636", geneDisplay: "RAD21L1" },
  { key: "NM_001384359.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "2523", geneDisplay: "FUT1" },
  { key: "NM_001384361.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6490", geneDisplay: "PMEL" },
  { key: "NM_001384369.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "11270", geneDisplay: "NRM" },
  { key: "NM_001384474.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "125336", geneDisplay: "LOXHD1" },
  { key: "NM_001384479.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "183", geneDisplay: "AGT" },
  { key: "NM_001384528.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "54815", geneDisplay: "GATAD2A" },
  { key: "NM_001384574.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "55095", geneDisplay: "SAMD4B" },
  { key: "NM_001384597.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "105372440", geneDisplay: "LOC105372440" },
  { key: "NM_001384598.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "55200", geneDisplay: "PLEKHG6" },
  { key: "NM_001384609.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26050", geneDisplay: "SLITRK5" },
  { key: "NM_001384647.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "6633", geneDisplay: "SNRPD2" },
  { key: "NM_001384648.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "56981", geneDisplay: "PRDM11" },
  { key: "NM_001384657.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57569", geneDisplay: "ARHGAP20" },
  { key: "NM_001384711.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "83468", geneDisplay: "GLT8D2" },
  { key: "NM_001384732.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "65250", geneDisplay: "CPLANE1" },
  { key: "NM_001384743.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155185", geneDisplay: "AMZ1" },
  { key: "NM_001384749.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3213", geneDisplay: "HOXB3" },
  { key: "NM_001384763.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146429", geneDisplay: "SLC22A31" },
  { key: "NM_001384896.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "120939", geneDisplay: "TMEM52B" },
  { key: "NM_001384899.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "157695", geneDisplay: "TDRP" },
  { key: "NM_001384900.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "223117", geneDisplay: "SEMA3D" },
  { key: "NM_001384910.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "2978", geneDisplay: "GUCA1A" },
  { key: "NM_001384911.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "399939", geneDisplay: "TRIM49D1" },
  { key: "NM_001384950.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "84166", geneDisplay: "NLRC5" },
  { key: "NM_001384980.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440345", geneDisplay: "NPIPB4" },
  { key: "NM_001384994.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "114841037", geneDisplay: "GUCA1ANB" },
  { key: "NM_001384995.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "401720", geneDisplay: "FIGNL2" },
  { key: "NM_001385001.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "55784", geneDisplay: "MCTP2" },
  { key: "NM_001385012.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "26960", geneDisplay: "NBEA" },
  { key: "NM_001385016.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56204", geneDisplay: "FAM214A" },
  { key: "NM_001385026.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "79834", geneDisplay: "PEAK1" },
  { key: "NM_001385028.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "84465", geneDisplay: "MEGF11" },
  { key: "NM_001385079.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "10846", geneDisplay: "PDE10A" },
  { key: "NM_001385089.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57596", geneDisplay: "BEGAIN" },
  { key: "NM_001385106.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145497", geneDisplay: "LRRC74A" },
  { key: "NM_001385109.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "1912", geneDisplay: "PHC2" },
  { key: "NM_001385125.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "611", geneDisplay: "OPN1SW" },
  { key: "NM_001385161.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "3140", geneDisplay: "MR1" },
  { key: "NM_001385174.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "57602", geneDisplay: "USP36" },
  { key: "NM_001385193.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "497190", geneDisplay: "CLEC18B" },
  { key: "NM_001385224.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "53342", geneDisplay: "IL17D" },
  { key: "NM_001385261.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "94027", geneDisplay: "CGB7" },
  { key: "NM_001385282.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9721", geneDisplay: "GPRIN2" },
  { key: "NM_001385305.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "5786", geneDisplay: "PTPRA" },
  { key: "NM_001385408.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284565", geneDisplay: "NBPF15" },
  { key: "NM_001385449.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57529", geneDisplay: "RTL9" },
  { key: "NM_001385465.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284369", geneDisplay: "SIGLECL1" },
  { key: "NM_001385469.3", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "200030", geneDisplay: "NBPF11" },
  { key: "NM_001385482.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55559", geneDisplay: "HAUS7" },
  { key: "NM_001385503.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "65981", geneDisplay: "CAPRIN2" },
  { key: "NM_001385562.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "10777", geneDisplay: "ARPP21" },
  { key: "NM_001385641.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "148398", geneDisplay: "SAMD11" },
  { key: "NM_001385648.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "374907", geneDisplay: "B3GNT8" },
  { key: "NM_001385662.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390075", geneDisplay: "OR52N5" },
  { key: "NM_001385682.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4134", geneDisplay: "MAP4" },
  { key: "NM_001385745.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "171017", geneDisplay: "ZNF384" },
  { key: "NM_001385855.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "26246", geneDisplay: "OR2L2" },
  { key: "NM_001385875.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "118813", geneDisplay: "ZFYVE27" },
  { key: "NM_001385981.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5829", geneDisplay: "PXN" },
  { key: "NM_001385994.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "51306", geneDisplay: "FAM13B" },
  { key: "NM_001386010.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "55063", geneDisplay: "ZCWPW1" },
  { key: "NM_001386014.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "3885", geneDisplay: "KRT34" },
  { key: "NM_001386033.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "390439", geneDisplay: "OR11G2" },
  { key: "NM_001386064.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390151", geneDisplay: "OR8H2" },
  { key: "NM_001386094.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123624", geneDisplay: "AGBL1" },
  { key: "NM_001386095.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26689", geneDisplay: "OR4D1" },
  { key: "NM_001386096.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "392138", geneDisplay: "OR2A25" },
  { key: "NM_001386098.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8392", geneDisplay: "OR3A3" },
  { key: "NM_001386125.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84033", geneDisplay: "OBSCN" },
  { key: "NM_001386130.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "4101", geneDisplay: "MAGEA2" },
  { key: "NM_001386132.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "266740", geneDisplay: "MAGEA2B" },
  { key: "NM_001386135.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "3899", geneDisplay: "AFF3" },
  { key: "NM_001386140.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "4547", geneDisplay: "MTTP" },
  { key: "NM_001386188.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "2491", geneDisplay: "CENPI" },
  { key: "NM_001386206.2", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "338879", geneDisplay: "RNASE10" },
  { key: "NM_001386298.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23152", geneDisplay: "CIC" },
  { key: "NM_001386375.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "647174", geneDisplay: "SERPINE3" },
  { key: "NM_001386393.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "80025", geneDisplay: "PANK2" },
  { key: "NM_001386514.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "102725191", geneDisplay: "LOC102725191" },
  { key: "NM_001386737.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "112163659", geneDisplay: "TMDD1" },
  { key: "NM_001386793.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "110806277", geneDisplay: "PNMA8C" },
  { key: "NM_001386794.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "111064650", geneDisplay: "C19orf85" },
  { key: "NM_001386795.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "1837", geneDisplay: "DTNA" },
  { key: "NM_001386800.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "111644133", geneDisplay: "THSD8" },
  { key: "NM_001386809.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "58191", geneDisplay: "CXCL16" },
  { key: "NM_001386814.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150209", geneDisplay: "AIFM3" },
  { key: "NM_001386820.1", chrDisplay: "21", chrTranscript: "NC_000021.9", geneUID: "107983987", geneDisplay: "LOC107983987" },
  { key: "NM_001386823.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100288842", geneDisplay: "B3GALT9" },
  { key: "NM_001386841.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85285", geneDisplay: "KRTAP4-1" },
  { key: "NM_001386863.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "22985", geneDisplay: "ACIN1" },
  { key: "NM_001386879.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "6579", geneDisplay: "SLCO1A2" },
  { key: "NM_001386885.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "80832", geneDisplay: "APOL4" },
  { key: "NM_001386888.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "4301", geneDisplay: "AFDN" },
  { key: "NM_001386889.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "55796", geneDisplay: "MBNL3" },
  { key: "NM_001386928.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "91612", geneDisplay: "CHURC1" },
  { key: "NM_001386936.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "26037", geneDisplay: "SIPA1L1" },
  { key: "NM_001386955.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "150165", geneDisplay: "XKR3" },
  { key: "NM_001386970.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "170627", geneDisplay: "XAGE5" },
  { key: "NM_001386972.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728656", geneDisplay: "DMRTC1B" },
  { key: "NM_001386974.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "3780", geneDisplay: "KCNN1" },
  { key: "NM_001386991.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "54971", geneDisplay: "BANP" },
  { key: "NM_001386993.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "140690", geneDisplay: "CTCFL" },
  { key: "NM_001387010.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "284739", geneDisplay: "C20orf204" },
  { key: "NM_001387011.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "55626", geneDisplay: "AMBRA1" },
  { key: "NM_001387025.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "57476", geneDisplay: "GRAMD1B" },
  { key: "NM_001387048.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "55959", geneDisplay: "SULF2" },
  { key: "NM_001387220.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "22807", geneDisplay: "IKZF2" },
  { key: "NM_001387222.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "199834", geneDisplay: "LCE4A" },
  { key: "NM_001387263.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "197135", geneDisplay: "PATL2" },
  { key: "NM_001387274.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "341019", geneDisplay: "DCDC1" },
  { key: "NM_001387280.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2205", geneDisplay: "FCER1A" },
  { key: "NM_001387287.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91687", geneDisplay: "CENPL" },
  { key: "NM_001387340.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "100529063", geneDisplay: "BCL2L2-PABPN1" },
  { key: "NM_001387356.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23619", geneDisplay: "ZIM2" },
  { key: "NM_001387360.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "114088", geneDisplay: "TRIM9" },
  { key: "NM_001387430.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "25970", geneDisplay: "SH2B1" },
  { key: "NM_001387437.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "280", geneDisplay: "AMY2B" },
  { key: "NM_001387446.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "26140", geneDisplay: "TTLL3" },
  { key: "NM_001387468.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "159090", geneDisplay: "PABIR2" },
  { key: "NM_001387552.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23284", geneDisplay: "ADGRL3" },
  { key: "NM_001387564.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "100129250", geneDisplay: "SMIM27" },
  { key: "NM_001387567.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "90135", geneDisplay: "BTBD6" },
  { key: "NM_001387579.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "9730", geneDisplay: "DCAF1" },
  { key: "NM_001387601.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "163087", geneDisplay: "ZNF383" },
  { key: "NM_001387690.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "83473", geneDisplay: "KATNAL2" },
  { key: "NM_001387691.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "9883", geneDisplay: "POM121" },
  { key: "NM_001387706.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "29057", geneDisplay: "FAM156A" },
  { key: "NM_001387751.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "2039", geneDisplay: "DMTN" },
  { key: "NM_001387777.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "7145", geneDisplay: "TNS1" },
  { key: "NM_001387844.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23215", geneDisplay: "PRRC2C" },
  { key: "NM_001387850.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "11259", geneDisplay: "FILIP1L" },
  { key: "NM_001387889.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57713", geneDisplay: "SFMBT2" },
  { key: "NM_001387994.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "7917", geneDisplay: "BAG6" },
  { key: "NM_001388022.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "9866", geneDisplay: "TRIM66" },
  { key: "NM_001388067.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "145282", geneDisplay: "MIPOL1" },
  { key: "NM_001388185.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "23338", geneDisplay: "JADE2" },
  { key: "NM_001388198.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6701", geneDisplay: "SPRR2B" },
  { key: "NM_001388199.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "255349", geneDisplay: "LHFPL7" },
  { key: "NM_001388272.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "387694", geneDisplay: "SH2D4B" },
  { key: "NM_001388303.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "283450", geneDisplay: "HECTD4" },
  { key: "NM_001388306.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "90007", geneDisplay: "MIDN" },
  { key: "NM_001388308.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "113220", geneDisplay: "KIF12" },
  { key: "NM_001388354.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "645426", geneDisplay: "TMEM191C" },
  { key: "NM_001388359.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "9764", geneDisplay: "KIAA0513" },
  { key: "NM_001388367.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400818", geneDisplay: "NBPF9" },
  { key: "NM_001388419.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "8997", geneDisplay: "KALRN" },
  { key: "NM_001388428.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "100289255", geneDisplay: "TMEM238L" },
  { key: "NM_001388447.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "159091", geneDisplay: "PABIR3" },
  { key: "NM_001388453.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "84074", geneDisplay: "QRICH2" },
  { key: "NM_001388459.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84443", geneDisplay: "FRMPD3" },
  { key: "NM_001388464.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "286436", geneDisplay: "H2BW2" },
  { key: "NM_001388465.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "353149", geneDisplay: "TBC1D26" },
  { key: "NM_001388484.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "57135", geneDisplay: "DAZ4" },
  { key: "NM_001388485.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "114783", geneDisplay: "LMTK3" },
  { key: "NM_001388488.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120796", geneDisplay: "OR56A1" },
  { key: "NM_001388490.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55700", geneDisplay: "MAP7D1" },
  { key: "NM_001388492.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "3064", geneDisplay: "HTT" },
  { key: "NM_001388498.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "254786", geneDisplay: "OR6C3" },
  { key: "NM_001389244.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "125115", geneDisplay: "KRT40" },
  { key: "NM_001389303.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "57055", geneDisplay: "DAZ2" },
  { key: "NM_001389320.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "144983", geneDisplay: "HNRNPA1L2" },
  { key: "NM_001389445.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "2825", geneDisplay: "CMKLR2" },
  { key: "NM_001389466.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "1800", geneDisplay: "DPEP1" },
  { key: "NM_001389556.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "91544", geneDisplay: "UBXN11" },
  { key: "NM_001389617.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "89796", geneDisplay: "NAV1" },
  { key: "NM_001389625.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "400073", geneDisplay: "C12orf76" },
  { key: "NM_001389683.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "2802", geneDisplay: "GOLGA3" },
  { key: "NM_001389712.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "92292", geneDisplay: "GLYATL1" },
  { key: "NM_001390846.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "90113", geneDisplay: "VWA5B2" },
  { key: "NM_001390849.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341568", geneDisplay: "OR8S1" },
  { key: "NM_001391906.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8672", geneDisplay: "EIF4G3" },
  { key: "NM_001391940.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "110806290", geneDisplay: "MYOCOS" },
  { key: "NM_001391956.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "159195", geneDisplay: "USP54" },
  { key: "NM_001391957.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "114827", geneDisplay: "FHAD1" },
  { key: "NM_001391958.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "338322", geneDisplay: "NLRP10" },
  { key: "NM_001391963.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "7417", geneDisplay: "VDAC2" },
  { key: "NM_001391974.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "503542", geneDisplay: "SPRN" },
  { key: "NM_001392013.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "201181", geneDisplay: "ZNF385C" },
  { key: "NM_001392073.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57325", geneDisplay: "KAT14" },
  { key: "NM_001393339.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "27141", geneDisplay: "CIDEB" },
  { key: "NM_001393344.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "27098", geneDisplay: "CLUL1" },
  { key: "NM_001393381.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "57482", geneDisplay: "CRACD" },
  { key: "NM_001393391.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "643680", geneDisplay: "MS4A4E" },
  { key: "NM_001393392.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "1646", geneDisplay: "AKR1C2" },
  { key: "NM_001393402.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "222", geneDisplay: "ALDH3B2" },
  { key: "NM_001393487.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "8807", geneDisplay: "IL18RAP" },
  { key: "NM_001393494.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "146433", geneDisplay: "IL34" },
  { key: "NM_001393499.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "23506", geneDisplay: "BICRAL" },
  { key: "NM_001393500.2", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120356740", geneDisplay: "TOMT" },
  { key: "NM_001393504.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "23031", geneDisplay: "MAST3" },
  { key: "NM_001393530.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "8785", geneDisplay: "MATN4" },
  { key: "NM_001393532.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "85509", geneDisplay: "MBD3L1" },
  { key: "NM_001393578.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "259249", geneDisplay: "MRGPRX1" },
  { key: "NM_001393586.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "4648", geneDisplay: "MYO7B" },
  { key: "NM_001393611.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "441459", geneDisplay: "ANKRD18B" },
  { key: "NM_001393612.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "5988", geneDisplay: "RFPL1" },
  { key: "NM_001393629.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "23504", geneDisplay: "RIMBP2" },
  { key: "NM_001393655.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "284958", geneDisplay: "NT5DC4" },
  { key: "NM_001393661.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "392399", geneDisplay: "LCN9" },
  { key: "NM_001393662.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "26609", geneDisplay: "VCX" },
  { key: "NM_001393663.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "285888", geneDisplay: "CNPY1" },
  { key: "NM_001393704.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "4336", geneDisplay: "MOBP" },
  { key: "NM_001393719.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "80063", geneDisplay: "ATF7IP2" },
  { key: "NM_001393769.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "116931", geneDisplay: "MED12L" },
  { key: "NM_001393797.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "94160", geneDisplay: "ABCC12" },
  { key: "NM_001393816.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "149685", geneDisplay: "ADIG" },
  { key: "NM_001393887.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "401067", geneDisplay: "IQCF3" },
  { key: "NM_001393892.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "64748", geneDisplay: "PLPPR2" },
  { key: "NM_001393907.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "83873", geneDisplay: "GPR61" },
  { key: "NM_001393918.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "255057", geneDisplay: "CBARP" },
  { key: "NM_001393938.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "388559", geneDisplay: "ZNF888" },
  { key: "NM_001393939.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "57730", geneDisplay: "ANKRD36B" },
  { key: "NM_001393982.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "400986", geneDisplay: "ANKRD36C" },
  { key: "NM_001393985.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "170961", geneDisplay: "ANKRD24" },
  { key: "NM_001393986.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7799", geneDisplay: "PRDM2" },
  { key: "NM_001393989.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5554", geneDisplay: "PRH1" },
  { key: "NM_001393997.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "57805", geneDisplay: "CCAR2" },
  { key: "NM_001394010.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "53635", geneDisplay: "PTOV1" },
  { key: "NM_001394014.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "8476", geneDisplay: "CDC42BPA" },
  { key: "NM_001394015.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "9644", geneDisplay: "SH3PXD2A" },
  { key: "NM_001394028.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "5697", geneDisplay: "PYY" },
  { key: "NM_001394031.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "22864", geneDisplay: "R3HDM2" },
  { key: "NM_001394037.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "90525", geneDisplay: "SHF" },
  { key: "NM_001394057.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "135250", geneDisplay: "RAET1E" },
  { key: "NM_001394062.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "23499", geneDisplay: "MACF1" },
  { key: "NM_001394063.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "200844", geneDisplay: "CFAP20DC" },
  { key: "NM_001394065.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "339512", geneDisplay: "CCDC190" },
  { key: "NM_001394067.2", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "9693", geneDisplay: "RAPGEF2" },
  { key: "NM_001394072.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "90019", geneDisplay: "SYT8" },
  { key: "NM_001394073.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "90161", geneDisplay: "HS6ST2" },
  { key: "NM_001394090.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57501", geneDisplay: "CFAP92" },
  { key: "NM_001394098.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "11228", geneDisplay: "RASSF8" },
  { key: "NM_001394132.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "120766137", geneDisplay: "HRURF" },
  { key: "NM_001394154.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "6002", geneDisplay: "RGS12" },
  { key: "NM_001394165.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "100526771", geneDisplay: "SMIM35" },
  { key: "NM_001394167.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "5998", geneDisplay: "RGS3" },
  { key: "NM_001394190.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "122651", geneDisplay: "RNASE11" },
  { key: "NM_001394198.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "155061", geneDisplay: "ZNF746" },
  { key: "NM_001394206.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "389084", geneDisplay: "SNORC" },
  { key: "NM_001394232.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "6276", geneDisplay: "S100A5" },
  { key: "NM_001394298.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "389852", geneDisplay: "SPACA5" },
  { key: "NM_001394311.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "22955", geneDisplay: "SCMH1" },
  { key: "NM_001394319.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100288072", geneDisplay: "SDR42E2" },
  { key: "NM_001394334.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "8926", geneDisplay: "SNURF" },
  { key: "NM_001394336.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "399473", geneDisplay: "SPRED3" },
  { key: "NM_001394345.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400823", geneDisplay: "FAM177B" },
  { key: "NM_001394372.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "29998", geneDisplay: "BICRA" },
  { key: "NM_001394376.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "5452", geneDisplay: "POU2F2" },
  { key: "NM_001394390.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "85439", geneDisplay: "STON2" },
  { key: "NM_001394395.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "9677", geneDisplay: "PPIP5K1" },
  { key: "NM_001394396.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "84985", geneDisplay: "FAM83A" },
  { key: "NM_001394401.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "346673", geneDisplay: "STRA8" },
  { key: "NM_001394410.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "29091", geneDisplay: "STXBP6" },
  { key: "NM_001394446.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "254251", geneDisplay: "LCORL" },
  { key: "NM_001394463.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "284948", geneDisplay: "SH2D6" },
  { key: "NM_001394477.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "2213", geneDisplay: "FCGR2B" },
  { key: "NM_001394494.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "222235", geneDisplay: "FBXL13" },
  { key: "NM_001394530.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "55891", geneDisplay: "LENEP" },
  { key: "NM_001394531.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "57705", geneDisplay: "WDFY4" },
  { key: "NM_001394555.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "10739", geneDisplay: "RFPL2" },
  { key: "NM_001394560.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "84460", geneDisplay: "ZMAT1" },
  { key: "NM_001394565.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "64756", geneDisplay: "ATPAF1" },
  { key: "NM_001394583.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "8844", geneDisplay: "KSR1" },
  { key: "NM_001394591.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100191040", geneDisplay: "C2CD4D" },
  { key: "NM_001394639.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "339766", geneDisplay: "MROH2A" },
  { key: "NM_001394668.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "101060211", geneDisplay: "CT45A7" },
  { key: "NM_001394669.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "284001", geneDisplay: "CCDC57" },
  { key: "NM_001394672.2", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "85443", geneDisplay: "DCLK3" },
  { key: "NM_001394674.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55831", geneDisplay: "EMC3" },
  { key: "NM_001394713.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "84808", geneDisplay: "PERM1" },
  { key: "NM_001394755.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9755", geneDisplay: "TBKBP1" },
  { key: "NM_001394757.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340900", geneDisplay: "EBLN1" },
  { key: "NM_001394758.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "645202", geneDisplay: "LOC645202" },
  { key: "NM_001394779.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "8798", geneDisplay: "DYRK4" },
  { key: "NM_001394783.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "1234", geneDisplay: "CCR5" },
  { key: "NM_001394787.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "402635", geneDisplay: "GRIFIN" },
  { key: "NM_001394789.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388324", geneDisplay: "INCA1" },
  { key: "NM_001394796.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "81556", geneDisplay: "INTS14" },
  { key: "NM_001394807.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "113622", geneDisplay: "ADPRHL1" },
  { key: "NM_001394828.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "26531", geneDisplay: "OR11A1" },
  { key: "NM_001394837.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "3831", geneDisplay: "KLC1" },
  { key: "NM_001394862.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "5544", geneDisplay: "PRB3" },
  { key: "NM_001394867.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "55193", geneDisplay: "PBRM1" },
  { key: "NM_001394894.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "204801", geneDisplay: "NLRP11" },
  { key: "NM_001394901.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "96626", geneDisplay: "LIMS3" },
  { key: "NM_001394922.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "110806297", geneDisplay: "FAM240B" },
  { key: "NM_001394940.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "641776", geneDisplay: "SPDYE14" },
  { key: "NM_001394943.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "102723555", geneDisplay: "SPDYE16" },
  { key: "NM_001394953.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100505767", geneDisplay: "SPDYE18" },
  { key: "NM_001394954.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "339965", geneDisplay: "CCDC158" },
  { key: "NM_001394955.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "79568", geneDisplay: "MAIP1" },
  { key: "NM_001394956.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "388333", geneDisplay: "SPDYE4" },
  { key: "NM_001394958.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "400831", geneDisplay: "C20orf202" },
  { key: "NM_001394959.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "55016", geneDisplay: "MARCHF1" },
  { key: "NM_001394961.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "56901", geneDisplay: "NDUFA4L2" },
  { key: "NM_001394962.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "57481", geneDisplay: "KIAA1210" },
  { key: "NM_001394966.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "152110", geneDisplay: "NEK10" },
  { key: "NM_001394997.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "1448", geneDisplay: "CSN3" },
  { key: "NM_001394998.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "26115", geneDisplay: "TANC2" },
  { key: "NM_001395002.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "9448", geneDisplay: "MAP4K4" },
  { key: "NM_001395010.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "153090", geneDisplay: "DAB2IP" },
  { key: "NM_001395015.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79741", geneDisplay: "CCDC7" },
  { key: "NM_001395058.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "80022", geneDisplay: "MYO15B" },
  { key: "NM_001395068.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "79949", geneDisplay: "PLEKHS1" },
  { key: "NM_001395159.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "57578", geneDisplay: "UNC79" },
  { key: "NM_001395167.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "57593", geneDisplay: "EBF4" },
  { key: "NM_001395205.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "56165", geneDisplay: "TDRD1" },
  { key: "NM_001395207.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "8470", geneDisplay: "SORBS2" },
  { key: "NM_001395208.2", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "341346", geneDisplay: "SMCO2" },
  { key: "NM_001395222.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "110806280", geneDisplay: "CCDC194" },
  { key: "NM_001395229.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "101929355", geneDisplay: "CFAP97D2" },
  { key: "NM_001395252.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "282763", geneDisplay: "OR51B5" },
  { key: "NM_001395253.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "100996758", geneDisplay: "NPY4R2" },
  { key: "NM_001395254.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "7739", geneDisplay: "ZNF185" },
  { key: "NM_001395255.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "112577461", geneDisplay: "OOSP3" },
  { key: "NM_001395273.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "91050", geneDisplay: "CCDC149" },
  { key: "NM_001395275.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "728741", geneDisplay: "NPIPB6" },
  { key: "NM_001395276.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "255649", geneDisplay: "OOSP1" },
  { key: "NM_001395278.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "107987155", geneDisplay: "OOSP4B" },
  { key: "NM_001395294.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "25854", geneDisplay: "FAM149A" },
  { key: "NM_001395331.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "400797", geneDisplay: "CLEC20A" },
  { key: "NM_001395333.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "23255", geneDisplay: "MTCL1" },
  { key: "NM_001395334.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102723547", geneDisplay: "CSAG2" },
  { key: "NM_001395362.2", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "340595", geneDisplay: "RTL4" },
  { key: "NM_001395373.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "653061", geneDisplay: "GOLGA8S" },
  { key: "NM_001395400.1", chrDisplay: "18", chrTranscript: "NC_000018.10", geneUID: "645369", geneDisplay: "TMEM200C" },
  { key: "NM_001395413.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "5447", geneDisplay: "POR" },
  { key: "NM_001395414.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "100507679", geneDisplay: "MUC22" },
  { key: "NM_001395421.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "101927367", geneDisplay: "SMIM36" },
  { key: "NM_001395425.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "347088", geneDisplay: "ADGRD2" },
  { key: "NM_001395426.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "9659", geneDisplay: "PDE4DIP" },
  { key: "NM_001395430.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "56924", geneDisplay: "PAK6" },
  { key: "NM_001395433.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "101929989", geneDisplay: "LITAFD" },
  { key: "NM_001395436.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "102724657", geneDisplay: "MSANTD5" },
  { key: "NM_001395437.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "57047", geneDisplay: "PLSCR2" },
  { key: "NM_001395460.1", chrDisplay: "5", chrTranscript: "NC_000005.10", geneUID: "57451", geneDisplay: "TENM2" },
  { key: "NM_001395462.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "7798", geneDisplay: "LUZP1" },
  { key: "NM_001395463.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "5320", geneDisplay: "PLA2G2A" },
  { key: "NM_001395467.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "643382", geneDisplay: "TMEM253" },
  { key: "NM_001395484.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "653423", geneDisplay: "SPAG11A" },
  { key: "NM_001395485.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "642799", geneDisplay: "NPIPA2" },
  { key: "NM_001395487.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "642778", geneDisplay: "NPIPA3" },
  { key: "NM_001395490.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "55809", geneDisplay: "TRERF1" },
  { key: "NM_001395496.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "374618", geneDisplay: "TEX9" },
  { key: "NM_001395498.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "10245", geneDisplay: "TIMM17B" },
  { key: "NM_001395501.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "360226", geneDisplay: "PRSS41" },
  { key: "NM_001395503.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "283999", geneDisplay: "TMEM235" },
  { key: "NM_001395504.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120400", geneDisplay: "NXPE1" },
  { key: "NM_001395507.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "344805", geneDisplay: "TMPRSS7" },
  { key: "NM_001395513.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "360200", geneDisplay: "TMPRSS9" },
  { key: "NM_001395517.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "728621", geneDisplay: "CCDC30" },
  { key: "NM_001395548.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "123745", geneDisplay: "PLA2G4E" },
  { key: "NM_001395555.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "115482686", geneDisplay: "H2AL3" },
  { key: "NM_001395587.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "83259", geneDisplay: "PCDH11Y" },
  { key: "NM_001395631.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "25832", geneDisplay: "NBPF14" },
  { key: "NM_001395637.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101060684", geneDisplay: "NBPF26" },
  { key: "NM_001395643.1", chrDisplay: "9", chrTranscript: "NC_000009.12", geneUID: "642515", geneDisplay: "PRRT1B" },
  { key: "NM_001395648.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "341883", geneDisplay: "LRRC9" },
  { key: "NM_001395656.1", chrDisplay: "3", chrTranscript: "NC_000003.12", geneUID: "6092", geneDisplay: "ROBO2" },
  { key: "NM_001395660.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "9170", geneDisplay: "LPAR2" },
  { key: "NM_001395749.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "343406", geneDisplay: "OR10R2" },
  { key: "NM_001395847.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "219595", geneDisplay: "FOLH1B" },
  { key: "NM_001395849.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "100132247", geneDisplay: "NPIPB5" },
  { key: "NM_001395859.2", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "613037", geneDisplay: "NPIPB13" },
  { key: "NM_001395862.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "112268164", geneDisplay: "CPHXL2" },
  { key: "NM_001395891.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "23332", geneDisplay: "CLASP1" },
  { key: "NM_001395895.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100381270", geneDisplay: "ZBED6" },
  { key: "NM_001395907.1", chrDisplay: "14", chrTranscript: "NC_000014.9", geneUID: "161142", geneDisplay: "GARIN2" },
  { key: "NM_001395920.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "112267897", geneDisplay: "LOC112267897" },
  { key: "NM_001395931.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440353", geneDisplay: "NPIPB12" },
  { key: "NM_001395936.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "284521", geneDisplay: "OR2L13" },
  { key: "NM_001395940.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "339674", geneDisplay: "SMIM45" },
  { key: "NM_001395953.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "2713", geneDisplay: "GK3P" },
  { key: "NM_001395961.1", chrDisplay: "2", chrTranscript: "NC_000002.12", geneUID: "344065", geneDisplay: "LOC344065" },
  { key: "NM_001395966.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "645545", geneDisplay: "IGBP1P2" },
  { key: "NM_001395972.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "340811", geneDisplay: "AKR1C8" },
  { key: "NM_001395976.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "728743", geneDisplay: "LOC728743" },
  { key: "NM_001395978.1", chrDisplay: "13", chrTranscript: "NC_000013.11", geneUID: "93492", geneDisplay: "TPTE2" },
  { key: "NM_001395996.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "728317", geneDisplay: "PWWP4" },
  { key: "NM_001395999.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "353267", geneDisplay: "RAMACL" },
  { key: "NM_001396011.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "101059948", geneDisplay: "LOC101059948" },
  { key: "NM_001396012.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "100419780", geneDisplay: "ZNF722" },
  { key: "NM_001396013.1", chrDisplay: "22", chrTranscript: "NC_000022.11", geneUID: "112268293", geneDisplay: "LOC112268293" },
  { key: "NM_001396017.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440307", geneDisplay: "TTLL13" },
  { key: "NM_001396020.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "105371267", geneDisplay: "LOC105371267" },
  { key: "NM_001396022.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "347687", geneDisplay: "GNG5P2" },
  { key: "NM_001396029.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "392433", geneDisplay: "MAGEB6B" },
  { key: "NM_001396030.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "440350", geneDisplay: "NPIPB7" },
  { key: "NM_001396036.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "729966", geneDisplay: "LOC729966" },
  { key: "NM_001396050.1", chrDisplay: "10", chrTranscript: "NC_000010.11", geneUID: "619207", geneDisplay: "SCART1" },
  { key: "NM_001396051.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "401661", geneDisplay: "OR51C1P" },
  { key: "NM_001396056.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "9596", geneDisplay: "OR1R1P" },
  { key: "NM_001396057.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "390073", geneDisplay: "OR56B2P" },
  { key: "NM_001396058.1", chrDisplay: "6", chrTranscript: "NC_000006.12", geneUID: "442197", geneDisplay: "OR2I1P" },
  { key: "NM_001396059.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "8594", geneDisplay: "OR5D3P" },
  { key: "NM_001396061.1", chrDisplay: "12", chrTranscript: "NC_000012.12", geneUID: "390313", geneDisplay: "OR5BS1P" },
  { key: "NM_001396063.1", chrDisplay: "Y", chrTranscript: "NC_000024.10", geneUID: "728132", geneDisplay: "TSPY9" },
  { key: "NM_001396067.1", chrDisplay: "17", chrTranscript: "NC_000017.11", geneUID: "85354", geneDisplay: "KRTAP4-16" },
  { key: "NM_001396070.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "391714", geneDisplay: "TRIM75" },
  { key: "NM_001396071.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "105378696", geneDisplay: "TMEM275" },
  { key: "NM_001396072.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101929796", geneDisplay: "NOTCH2NLR" },
  { key: "NM_001396075.1", chrDisplay: "11", chrTranscript: "NC_000011.10", geneUID: "120824", geneDisplay: "TRIM51G" },
  { key: "NM_001396080.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "105372481", geneDisplay: "ERVK3-1" },
  { key: "NM_001396106.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "127617", geneDisplay: "OR14L1P" },
  { key: "NM_001396132.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "727838", geneDisplay: "LOC727838" },
  { key: "NM_001396241.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "642659", geneDisplay: "HNRNPA1L3" },
  { key: "NM_001396242.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "441273", geneDisplay: "SPDYE2" },
  { key: "NM_001396408.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "257000", geneDisplay: "TINCR" },
  { key: "NM_001396456.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "283875", geneDisplay: "GREP1" },
  { key: "NM_001396485.1", chrDisplay: "16", chrTranscript: "NC_000016.10", geneUID: "729978", geneDisplay: "NPIPB2" },
  { key: "NM_001396956.1", chrDisplay: "15", chrTranscript: "NC_000015.10", geneUID: "440243", geneDisplay: "GOLGA6L22" },
  { key: "NM_001396959.1", chrDisplay: "4", chrTranscript: "NC_000004.12", geneUID: "23216", geneDisplay: "TBC1D1" },
  { key: "NM_001397211.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100288142", geneDisplay: "NBPF20" },
  { key: "NM_001397246.1", chrDisplay: "7", chrTranscript: "NC_000007.14", geneUID: "79037", geneDisplay: "PVRIG" },
  { key: "NM_001397346.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "284355", geneDisplay: "TPRX1" },
  { key: "NM_001397347.2", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "503627", geneDisplay: "TPRX2" },
  { key: "NM_001397362.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "6998", geneDisplay: "TDGF1P3" },
  { key: "NM_001397372.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "162972", geneDisplay: "ZNF550" },
  { key: "NM_001397380.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "645572", geneDisplay: "FAM90A23" },
  { key: "NM_001397382.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "645558", geneDisplay: "FAM90A22" },
  { key: "NM_001397387.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441317", geneDisplay: "FAM90A7" },
  { key: "NM_001397391.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "728746", geneDisplay: "FAM90A17" },
  { key: "NM_001397396.1", chrDisplay: "8", chrTranscript: "NC_000008.11", geneUID: "441323", geneDisplay: "FAM90A16" },
  { key: "NM_001397406.1", chrDisplay: "19", chrTranscript: "NC_000019.10", geneUID: "112812", geneDisplay: "FDX2" },
  { key: "NM_001397426.2", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "101669762", geneDisplay: "BLACAT1" },
  { key: "NM_001397448.1", chrDisplay: "X", chrTranscript: "NC_000023.11", geneUID: "102723508", geneDisplay: "KANTR" },
  { key: "NM_001397487.1", chrDisplay: "1", chrTranscript: "NC_000001.11", geneUID: "100505741", geneDisplay: "SPATA1" },
  { key: "NM_001397496.1", chrDisplay: "20", chrTranscript: "NC_000020.11", geneUID: "100101490", geneDisplay: "MCTS2" }
];

export default GeneKeys;
