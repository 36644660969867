import React from 'react';
import { Home } from '../screens/home';
import { ValidateEmail } from '../screens/validate';
import { Routes, Route } from 'react-router-dom';
import { FourOhFour } from '../screens/fourohfour';
import PrivateRoute from './PrivateRoute';

export default function DefinedRoutes() {
  return (
    <>
      <Routes>
          <Route path='/' element={ <PrivateRoute/> } >
            <Route path='/' element={ <Home/> } />
            <Route path='/home' element={ <Home/> } />
            <Route path='/validate' element={ <ValidateEmail/> } />
            <Route path='/*' element={<FourOhFour />} />
          </Route>
          <Route path='/*' element={<FourOhFour />} />
      </Routes>
    </>
  );
}
