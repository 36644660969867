import React from "react";
import SummaryGraphData from "../utils/SummaryGraphData";
import GeneKeys from "../utils/GeneKeys";
//@ts-ignore
import * as d3 from "d3";

function SummaryHistogram(props: any) {

  const [listOfGenes, setListOfGenes] = React.useState<any>();
  const [listOfGenesMetadata, setListOfGenesMetadata] = React.useState<any>();
  const [showGeneList, setShowGeneList] = React.useState<boolean>(false);
  const geneLists = React.useRef<Array<any>>();
  if(geneLists.current === undefined || geneLists.current === null) {
    geneLists.current = new Array<any>();
    geneLists.current.push({
      binX0: 0,
      binX1: 0,
      geneList: Array<string>()
    });
  }

  React.useEffect(() => {
    setShowGeneList(false);
  }, [props.geneName]);

  const getGeneFriendlyName = (transcript: string) => {
    const retVal = GeneKeys.find((gene: any) => { return gene.key === transcript});
    return retVal ? retVal.geneDisplay : transcript;
  };

  const getGeneTrancript = (friendlyName: string) => {
    const retVal = GeneKeys.find((gene: any) => { return gene.geneDisplay === friendlyName});
    return retVal ? retVal.key : friendlyName;
  };

  const selectBin = (genes: any, xVal0: any, xVal1: any) => {
    setListOfGenesMetadata({
      totalCount: genes.length,
      xValue0: xVal0,
      xValue1: xVal1
    });

    // Do we have a gene list for this gene?
    const geneList = geneLists.current ? geneLists.current.find((x: any) => { return x.binX0 === xVal0 && x.binX1 === xVal1 }) : null;
    if(geneList) {
      // use the stored list
      if(props.geneName !== '' && geneList.geneList.includes(props.geneName.toUpperCase()) === false && genes.includes(props.geneName.toUpperCase())) {
        geneList.geneList.push(props.geneName.toUpperCase());
        geneList.geneList.sort();
        setListOfGenes(geneList.geneList);
      }
      else {
        setListOfGenes(geneList.geneList);
      }
    }
    else {
      const filteredGenes = Array<any>();

      // Filter some of them out
      const randomizedModulus = Math.floor(genes.length / 4);

      genes.sort().forEach((gene: any, idx: number) => {
        // If we have current gene, take that too
        if(props.geneName !== '' && gene.indexOf(props.geneName.toUpperCase()) > -1 ) {
          filteredGenes.push(gene);
        }
        else {
          // Filter handful
          if(idx % (randomizedModulus + 1) === 0) {
            filteredGenes.push(gene);
          }
        }

      });

      // Save for later
      if(geneLists.current) {
        geneLists.current.push({
          binX0: xVal0,
          binX1: xVal1,
          geneList: filteredGenes
        })
      }

      // Set the pop up
      setListOfGenes(filteredGenes);
    }
    
    setShowGeneList(true);
  }

  function renderGraph() {
    // Remove the last copy, if any
    if(props.divID) {
      d3.select(`#${props.divID}`).selectAll("*").remove();
    }

    const color = props.color ? props.color : "#FF00FF";

    // set the dimensions and margins of the graph
    const margin = {top: 20, right: 30, bottom: 55, left: 65};
    const width = 700 - margin.left - margin.right;
    const height = 325 - margin.top - margin.bottom;

    const clickFunction = (e: any) => { 
      const x0 = (e.target.__data__).x0; 
      const x1 = (e.target.__data__).x1; 
      const arr = (e.target.__data__).map((row: any) => { 
        return `${getGeneFriendlyName(row.geneTranscript)}`; 
      }); 
      
      // TODO:  the number of decimal places should be dynamic, passed in, etc., based on the binRange provided to Graph (binFunction)
      selectBin(arr, x0.toFixed(2), x1.toFixed(2)); 
    } 

    const mouseOverFunction = (e: any) => {
      e.target.setAttribute("stroke-width", 1);
      e.target.setAttribute("stroke", "#FF000099");
    };

    const mouseOutFunction = (e: any) => {
      e.target.setAttribute("stroke-width", 0);
    };

    const nonPercentageThreshold = props.binFunction ? props.binFunction(SummaryGraphData) : null;

    // X Axis
    const scaleX = d3.scaleLinear().range([0, width]);
    if(props.binFunction) {
      scaleX.domain([nonPercentageThreshold[0], nonPercentageThreshold[nonPercentageThreshold.length - 1]]);
    }
    else {
      scaleX.domain([0, 100]);
    }

    const thresholds = nonPercentageThreshold ? nonPercentageThreshold : scaleX.ticks(100);

    // append the svg object to the body of the page
    const div = document.getElementById(props.divID) as HTMLDivElement;
    if(!div) {
      return false;
    }

    const hasSVG = div ? div.childNodes ? div.childNodes.length > 0 : false : false;
    if(hasSVG) {
      if(div.firstChild) {
        // Don't run it a second time, but don't mark it as failed (return true)
        return true;
      }
    }

    const svg = d3.select(`#${props.divID}`)
                  .append("svg")
                  .attr("width", width + margin.left + margin.right)
                  .attr("height", height + margin.top + margin.bottom)
                  .attr("style", "cursor: pointer")
                  .append("g")
                  .attr("transform",
                        `translate(${margin.left},${margin.top})`);
  
    try {
      // Add the X Axis to the SVG
      const xAxis = svg.append("g")
          .attr("transform", `translate(0, ${height})`)
          .call(d3.axisBottom(scaleX));
      xAxis.attr("style", "font-size: 13px;");

      const histogram = d3.histogram()
          .value(props.fieldFunction)
          .domain(scaleX.domain())
          .thresholds(thresholds);

      const bins = histogram(SummaryGraphData);

      // Y Axis
      const scaleY = d3.scaleLinear();
      scaleY.range([height, 0]);
      scaleY.domain([0, d3.max(bins, (d: any) => { return d.length; })]);

      // Add Y Axis to SVG
      const yAxis = svg.append("g").call(d3.axisLeft(scaleY));
      yAxis.attr("style", "font-size: 13px;");

      // append the bar rectangles to the svg element
      svg.selectAll("rect")
        .data(bins)
        .join("rect")
          .attr("x", 1)
          .attr("transform", function(d: any) { return `translate(${scaleX(d.x0)} , ${scaleY(d.length)})`; })
          .attr("width", function(d: any) { const retVal = (scaleX(d.x1) - scaleX(d.x0) - 1); return retVal >= 0 ? retVal : 0; })
          .attr("height", function(d: any) { return height - scaleY(d.length); })
          .style("fill", `${color}77`)
          .on("click", clickFunction)
          .on("mouseover", mouseOverFunction)
          .on("mouseout", mouseOutFunction);

      // Find this gene in the bins
      const geneMetadataTrnxId = props.geneName ? getGeneTrancript(props.geneName.toUpperCase()) : '';
      const thisBin = bins.find((bin: any) => { return bin.find((binArray: any) => { return geneMetadataTrnxId ? binArray.geneTranscript === geneMetadataTrnxId : false; })} );

      if(thisBin) {
        // Draw another rectangle on top of the one already there, but this full opacity / alpha
        svg.append("rect")
          .data([thisBin])
          .join("rect")
            .attr("x", 1)
            .attr("transform", function(d: any) { return `translate(${scaleX(d.x0)} , ${scaleY(d.length)})`; })
            .attr("width", function(d: any) { const retVal = (scaleX(d.x1) - scaleX(d.x0) - 1); return retVal >= 0 ? retVal : 0; })
            .attr("height", function(d: any) { return height - scaleY(d.length); })
            .style("fill", `${color}`)
            .style("stroke", "black")
            .style("stroke-width", 1)
            .on("click", clickFunction)
            .on("mouseover", mouseOverFunction)
            .on("mouseout", mouseOutFunction);

        // The little red dot just under the X axis line, locator for the Gene
        svg.append("rect")
          .data([thisBin])
          .join("rect")
            .attr("x", 1)
            .attr("transform", function(d: any) { return `translate(${scaleX(d.x0)} , ${height + 2})`; })
            .attr("width", 4)
            .attr("height", 5)
            .style("fill", "red");
      }

      const xAxisLabelLine1 = props.xAxisLabelLine1;
      const xAxisLabelLine2 = props.xAxisLabelLine2;

      svg.append("text")
          .attr("x", height / 2)
          .attr("y", margin.left - 30)
          .text(xAxisLabelLine1)
          .attr("fill", "black")
          .attr("text-anchor", "middle")
          .attr("transform", "rotate(-90 90,180)")
          .style("font-size", "14px");
      svg.append("text")
          .attr("x", height / 2)
          .attr("y", margin.left - 15)
          .text(xAxisLabelLine2)
          .attr("fill", "black")
          .attr("text-anchor", "middle")
          .attr("transform", "rotate(-90 90,180)")
          .style("font-size", "14px");

      const yAxisLabelLine1 = props.yAxisLabelLine1;
      const yAxisLabelLine2 = props.yAxisLabelLine2;

      svg.append("text")
          .attr("x", width / 2)
          .attr("y", height + 37)
          .text(yAxisLabelLine1)
          .attr("fill", "black")
          .attr("text-anchor", "middle")
          .style("font-size", "14px");
      svg.append("text")
          .attr("x", width / 2)
          .attr("y", height + 52)
          .text(yAxisLabelLine2)
          .attr("fill", "black")
          .attr("text-anchor", "middle")
          .style("font-size", "14px");

      return true;
    }
    catch(e) {
        console.log(e);
        return false;
    }            
  }     

  function tryDelayRender(iteration: number) {
    // Slight delay, then render the graph.  Need to ensure DIV is there.
    window.setTimeout(() => { 
      const success = renderGraph();
      if(!success) {
        if(iteration < 10) {
          // Don't do this more than 10 times
          tryDelayRender(iteration + 1);
        }
      }
    }, 10);
  }

  tryDelayRender(1);

  return (
    <div style={{display: 'block', width: 700, maxWidth: 700, border: 'solid 0px red'}}>
      <div style={{position: 'relative', width: 700, maxWidth: 700, border: 'solid 0px blue'}}>
        <div id={props.divID}></div>
        <div style={{   position: 'absolute', 
                      zIndex: 1000, 
                      display: showGeneList ? 'block' : 'none', 
                      width: 300, 
                      height: 120, 
                      padding: 10,
                      left: '100%',
                      marginLeft: 90,
                      top: 275,
                      backgroundColor: '#DEDEDE',
                      border: 'solid 1px black',
                      fontSize: 10
                      }}>
          <div>
            <u>Branch Length Group</u>:&nbsp;&nbsp;&nbsp;<b>From:</b> {listOfGenesMetadata  &&
              `${listOfGenesMetadata.xValue0} | `}
            <b>To: </b> {listOfGenesMetadata  &&
              listOfGenesMetadata.xValue1}

            <br/><br/>
            Here are some samples of genes in this range group (bin).
          </div>
          <div  style={{    float: 'right',
                            marginTop: -111,
                            marginRight: 2,
                            fontFamily: 'Verdana',
                            fontSize: 14,
                            color: 'firebrick',
                            cursor: 'pointer',
                            display: 'none'
                }}
                onClick={() => { setShowGeneList(false); }}>
            X
          </div>
          <div style={{  
                      overflow: 'auto', 
                      height: 60,
                      marginTop: 6,
                      padding: 10,
                      backgroundColor: '#EFEFEF'
                      }}>
            { listOfGenes &&
              listOfGenes.sort().map((gene: any, idx: number) => {
                return (<div key={`geneList_${idx}`}>{gene}</div>);
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryHistogram;
