import React from 'react';

function GraphIcon(props: any) {
  return (
    <div    style={{  padding: 0,
                      border: 'solid 1px #EFEFEF',
                      height: 20,
                      width: 21,
                      display: 'block'
    }}>
      
      <svg id="fullActive" height="20" width="21" >
        <rect x={1} y={14} width={4} height={5} rx={1} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={6} y={9} width={4} height={10} rx={1} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={11} y={1} width={4} height={18} rx={1} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={16} y={9} width={4} height={10} rx={1} stroke="black" strokeWidth={1} fill="#F2BF3D" />

      </svg>
    </div>
  )
}

export default GraphIcon;
