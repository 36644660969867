const RulerCoords = [
  { key: "trim56", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "c1orf56", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "sdha", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "trip11", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "phf14", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "vtn", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "cdkal1", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "thap9", filterType: "FailedAllFilters", aaLeft: "767", aaRight: "791", ntLeft: "2299", ntRight: "2373" },
  { key: "tex49", filterType: "FourthBest", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "mab21l4", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "znf770", filterType: "FailedAllFilters", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "nufip2", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "myrip", filterType: "BestCase", aaLeft: "562", aaRight: "586", ntLeft: "1684", ntRight: "1758" },
  { key: "c20orf202", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "daxx", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "nudt9", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "speg", filterType: "BestCase", aaLeft: "2219", aaRight: "2243", ntLeft: "6655", ntRight: "6729" },
  { key: "blid", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "sulf2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "c11orf52", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "b4galt4", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "med6", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "ranbp3l", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "gpr88", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "ccdc57", filterType: "FailedAllFilters", aaLeft: "721", aaRight: "745", ntLeft: "2161", ntRight: "2235" },
  { key: "creb3l3", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "htr1f", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "lacc1", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "defb134", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ccdc117", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "pnkd", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "vsig1", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "slc27a4", filterType: "BestCase", aaLeft: "554", aaRight: "578", ntLeft: "1660", ntRight: "1734" },
  { key: "upb1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or8k1", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "fras1", filterType: "FailedAllFilters", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "zbtb8b", filterType: "BestCase", aaLeft: "427", aaRight: "451", ntLeft: "1279", ntRight: "1353" },
  { key: "phka2", filterType: "BestCase", aaLeft: "1161", aaRight: "1185", ntLeft: "3481", ntRight: "3555" },
  { key: "phf6", filterType: "SecondBest", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "tmem47", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "lrrc61", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "slc30a7", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "chst6", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "tsc22d2", filterType: "BestCase", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "bdh2", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "dnajc4", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ifitm5", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "tuba4b", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "tmem70", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "lrrc56", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "hccs", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cd300ld", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cmtm7", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "gabrr3", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "itgav", filterType: "BestCase", aaLeft: "875", aaRight: "899", ntLeft: "2623", ntRight: "2697" },
  { key: "snupn", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ccdc60", filterType: "BestCase", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "gprc6a", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "htr2a", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "ccdc120", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "sh3bgrl", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "mep1b", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "safb2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ak9", filterType: "BestCase", aaLeft: "1552", aaRight: "1576", ntLeft: "4654", ntRight: "4728" },
  { key: "dnph1", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "arpc1a", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "c11orf65", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "diaph1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "lin54", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "prpf18", filterType: "SecondBest", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "elk4", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "mfge8", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "kdm4b", filterType: "BestCase", aaLeft: "1064", aaRight: "1088", ntLeft: "3190", ntRight: "3264" },
  { key: "trim61", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "wapl", filterType: "BestCase", aaLeft: "1075", aaRight: "1099", ntLeft: "3223", ntRight: "3297" },
  { key: "phf23", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "klhl29", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "znf747", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "borcs6", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "or11h1", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "smim8", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf419", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "c1qtnf12", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "cd48", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "sox21", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "syf2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "gbp7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "tmem63a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "unc13a", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "znf106", filterType: "BestCase", aaLeft: "922", aaRight: "946", ntLeft: "2764", ntRight: "2838" },
  { key: "lmx1b", filterType: "SecondBest", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "smim21", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "wnt5b", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "stat2", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "barx1", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "rps15a", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "c8orf48", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "corin", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "bpnt2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "coil", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "pstpip1", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "ralgds", filterType: "BestCase", aaLeft: "613", aaRight: "637", ntLeft: "1837", ntRight: "1911" },
  { key: "impg1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "trappc8", filterType: "BestCase", aaLeft: "1210", aaRight: "1234", ntLeft: "3628", ntRight: "3702" },
  { key: "znf664", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ngly1", filterType: "BestCase", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "map2k4", filterType: "SecondBest", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "caprin2", filterType: "BestCase", aaLeft: "641", aaRight: "665", ntLeft: "1921", ntRight: "1995" },
  { key: "esam", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "zbtb26", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "ccnd3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mutyh", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "nppa", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "qrich1", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "ugt2b7", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "camk1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "apoa2", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "thada", filterType: "BestCase", aaLeft: "1518", aaRight: "1542", ntLeft: "4552", ntRight: "4626" },
  { key: "cdyl", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "znf653", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "p2ry2", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "gtpbp2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "snapc1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "zbtb11", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "klc2", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "samd15", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "lrp12", filterType: "BestCase", aaLeft: "804", aaRight: "828", ntLeft: "2410", ntRight: "2484" },
  { key: "mtmr6", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "kirrel2", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "rcor2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "znf804a", filterType: "FailedAllFilters", aaLeft: "865", aaRight: "889", ntLeft: "2593", ntRight: "2667" },
  { key: "alkbh8", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "znf131", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "rpl30", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "senp2", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "duoxa1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "fam151a", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "chrdl1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "opn1sw", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "ddx60", filterType: "FailedAllFilters", aaLeft: "1145", aaRight: "1169", ntLeft: "3433", ntRight: "3507" },
  { key: "dph6", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "mtfr1l", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "sf3b3", filterType: "SecondBest", aaLeft: "835", aaRight: "859", ntLeft: "2503", ntRight: "2577" },
  { key: "nrip2", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "sprtn", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "noa1", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "mbd6", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "atf7ip2", filterType: "FailedAllFilters", aaLeft: "608", aaRight: "632", ntLeft: "1822", ntRight: "1896" },
  { key: "klrd1", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "msrb1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "tmem25", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "galns", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "cx3cl1", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "ppp2r1a", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "panx1", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "krtap2-1", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "pde9a", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "or1d2", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "nup210", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "nbpf4", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ssr3", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ccdc175", filterType: "FailedAllFilters", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "mfsd14a", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "etv2", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "fjx1", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "aqp3", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "lilrb2", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "potem", filterType: "FailedAllFilters", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "cep41", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "scgb3a1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "pcdhga10", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "krtap10-10", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "trim34", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "lctl", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "cdk6", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "casq1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "fam133b", filterType: "SecondBest", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "cdk5rap1", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "tiam1", filterType: "BestCase", aaLeft: "1545", aaRight: "1569", ntLeft: "4633", ntRight: "4707" },
  { key: "tacr3", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "hs1bp3", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "entpd3", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "cdh1", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "ascc3", filterType: "BestCase", aaLeft: "741", aaRight: "765", ntLeft: "2221", ntRight: "2295" },
  { key: "scn5a", filterType: "BestCase", aaLeft: "996", aaRight: "1020", ntLeft: "2986", ntRight: "3060" },
  { key: "glmn", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "cckbr", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ctrc", filterType: "FailedAllFilters", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "uts2b", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "shmt2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "vkorc1l1", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "lilra5", filterType: "ThirdBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "pdlim3", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "cep76", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "rab31", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "snrpn", filterType: "SecondBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "rundc3b", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "ube2u", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "asphd1", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ccn3", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "neurod2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "shisa8", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "pcp4", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gal3st3", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "nhsl2", filterType: "BestCase", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "smo", filterType: "BestCase", aaLeft: "738", aaRight: "762", ntLeft: "2212", ntRight: "2286" },
  { key: "csta", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "capza3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "epha10", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "acrbp", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ccdc142", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "page2b", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "ttll6", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "ctnna3", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "slc8a1", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "bckdk", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "vps50", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nr1i3", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "pcolce", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "nrf1", filterType: "SecondBest", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "cetn1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "znf558", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "c20orf96", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "dcaf1", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "lrrc34", filterType: "FailedAllFilters", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "phc3", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "mettl3", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "znf606", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "dpp8", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "ccnb1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "zbtb44", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "kif14", filterType: "BestCase", aaLeft: "1161", aaRight: "1185", ntLeft: "3481", ntRight: "3555" },
  { key: "snx22", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "ptdss1", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "taldo1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "imp4", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ints10", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "rapgef6", filterType: "BestCase", aaLeft: "1557", aaRight: "1581", ntLeft: "4669", ntRight: "4743" },
  { key: "pias2", filterType: "BestCase", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "socs5", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "znf319", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "klf7", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "cebpb", filterType: "FailedAllFilters", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "smim43", filterType: "SecondBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "scara3", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "eif2ak1", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "c15orf61", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "xpnpep2", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "lypd2", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "col9a1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "polb", filterType: "SecondBest", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ythdf1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "inhbb", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "spi1", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "pcsk1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "decr2", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "hrh4", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "fxr1", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "rfesd", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "pex13", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "f12", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "cdc42ep5", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "rskr", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "fabp6", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "eif1b", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "akap7", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "slc9c2", filterType: "FailedAllFilters", aaLeft: "813", aaRight: "837", ntLeft: "2437", ntRight: "2511" },
  { key: "lrr1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "nsd2", filterType: "BestCase", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "dpm3", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "cenps", filterType: "FourthBest", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "lmo7", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "c5orf52", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "rfxap", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "cfap77", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "naga", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "sdr39u1", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "ints9", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "tmeff2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "nxt2", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "ctbp1", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "dync1li2", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "smn1", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "phax", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "sptlc3", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "ap2a2", filterType: "BestCase", aaLeft: "628", aaRight: "652", ntLeft: "1882", ntRight: "1956" },
  { key: "ccer1", filterType: "FailedAllFilters", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "dst", filterType: "BestCase", aaLeft: "3740", aaRight: "3764", ntLeft: "11218", ntRight: "11292" },
  { key: "barhl1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "nkain2", filterType: "SecondBest", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "bnip2", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "rpl7", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "eya4", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "h2ac4", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "rnps1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "nanos2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "mapk14", filterType: "SecondBest", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "myo9a", filterType: "BestCase", aaLeft: "1795", aaRight: "1819", ntLeft: "5383", ntRight: "5457" },
  { key: "skap1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "kif23", filterType: "BestCase", aaLeft: "748", aaRight: "772", ntLeft: "2242", ntRight: "2316" },
  { key: "snx15", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "cdy2b", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ormdl1", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "pogz", filterType: "BestCase", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "cars2", filterType: "FailedAllFilters", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "eml2", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "or5h1", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "camsap2", filterType: "BestCase", aaLeft: "1085", aaRight: "1109", ntLeft: "3253", ntRight: "3327" },
  { key: "hoxa4", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "pdilt", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "adm2", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "malt1", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "rbks", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "asic4", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "bcdin3d", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ankrd44", filterType: "BestCase", aaLeft: "846", aaRight: "870", ntLeft: "2536", ntRight: "2610" },
  { key: "sfrp1", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "sh2d2a", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "stac3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "dap", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "pcdhgc5", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "morc2", filterType: "BestCase", aaLeft: "673", aaRight: "697", ntLeft: "2017", ntRight: "2091" },
  { key: "rpl36al", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "khdrbs1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "trabd2a", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "actrt1", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "mgam2", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "slc2a6", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "git1", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "tmem234", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "hvcn1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "tenm4", filterType: "BestCase", aaLeft: "2430", aaRight: "2454", ntLeft: "7288", ntRight: "7362" },
  { key: "slc52a2", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "snph", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "dennd2c", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "slc10a1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "prlr", filterType: "FailedAllFilters", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "sec61g", filterType: "SecondBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "slc45a1", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "h2bc15", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ptprm", filterType: "BestCase", aaLeft: "1208", aaRight: "1232", ntLeft: "3622", ntRight: "3696" },
  { key: "plcd3", filterType: "BestCase", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "nr2c2", filterType: "SecondBest", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "vav3", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "cbll2", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "xylb", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "bsdc1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "or13f1", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "tasp1", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "bhlhe23", filterType: "FailedAllFilters", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "atp5pd", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "gria4", filterType: "BestCase", aaLeft: "563", aaRight: "587", ntLeft: "1687", ntRight: "1761" },
  { key: "or51f2", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "pqbp1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "syt11", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "or2m4", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "tmem203", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ddr1", filterType: "BestCase", aaLeft: "624", aaRight: "648", ntLeft: "1870", ntRight: "1944" },
  { key: "vkorc1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "myh8", filterType: "BestCase", aaLeft: "1230", aaRight: "1254", ntLeft: "3688", ntRight: "3762" },
  { key: "ribc1", filterType: "FailedAllFilters", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "loc102723475", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "ppp1r2c", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "tfpi", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "ffar3", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "chaf1b", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "crym", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "amer1", filterType: "BestCase", aaLeft: "631", aaRight: "655", ntLeft: "1891", ntRight: "1965" },
  { key: "ube2l3", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "leprotl1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cela3a", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "hoxb3", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "yeats4", filterType: "SecondBest", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "pef1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "znf484", filterType: "FailedAllFilters", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "nop2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "or12d2", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "pigl", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "filip1l", filterType: "BestCase", aaLeft: "831", aaRight: "855", ntLeft: "2491", ntRight: "2565" },
  { key: "clmp", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "dlgap2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "mef2d", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "sap30l", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "peli1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "tab3", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "dsg2", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "trpv3", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "krt14", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "s100a12", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "anapc10", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ropn1b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "c1galt1c1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "prss2", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "diras2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "mt1m", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "bcas3", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "cep89", filterType: "FailedAllFilters", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "pax6", filterType: "SecondBest", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "cops6", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "plekhg5", filterType: "BestCase", aaLeft: "824", aaRight: "848", ntLeft: "2470", ntRight: "2544" },
  { key: "arhgap18", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "ostm1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ndufc1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pik3ca", filterType: "SecondBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "lsmem1", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ezh2", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "chchd2", filterType: "FourthBest", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "gpr22", filterType: "SecondBest", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "fam24a", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "best1", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "tagap", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "pmpcb", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "rer1", filterType: "SecondBest", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "zfp36l2", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "cldn23", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "mark4", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "c19orf38", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "oxnad1", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "mrpl2", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "kiaa1755", filterType: "BestCase", aaLeft: "854", aaRight: "878", ntLeft: "2560", ntRight: "2634" },
  { key: "plxnb2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "foxh1", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "gpn1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "gla", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "cldn14", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "sts", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "h3y1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "evpll", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "clasrp", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "rhoc", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "ddit4", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "aldh18a1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "fbrsl1", filterType: "BestCase", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "focad", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "sag", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "c1orf109", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "ddx39a", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "rbpjl", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "odf3", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "gpr15", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "mthfs", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "scgn", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "trappc2l", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "fut4", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "rangrf", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "xpo7", filterType: "SecondBest", aaLeft: "938", aaRight: "962", ntLeft: "2812", ntRight: "2886" },
  { key: "abi3", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "anapc4", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "appbp2", filterType: "SecondBest", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "mta2", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "ly75", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "arsa", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "sult1c2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "bglap", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "dcdc2b", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "slco3a1", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "otud1", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "nsmce3", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "apbb2", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "nhej1", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "il12rb2", filterType: "FailedAllFilters", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "krt23", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nlrc5", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "polr3k", filterType: "SecondBest", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "laptm4b", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "septin5", filterType: "SecondBest", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "adam20", filterType: "FailedAllFilters", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "plcb1", filterType: "BestCase", aaLeft: "1121", aaRight: "1145", ntLeft: "3361", ntRight: "3435" },
  { key: "grid1", filterType: "BestCase", aaLeft: "800", aaRight: "824", ntLeft: "2398", ntRight: "2472" },
  { key: "hacl1", filterType: "FailedAllFilters", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "rgpd2", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "or13c4", filterType: "FourthBest", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "atp9a", filterType: "BestCase", aaLeft: "987", aaRight: "1011", ntLeft: "2959", ntRight: "3033" },
  { key: "tmem256", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or2h1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "ptpn14", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "s100a7", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gmps", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "dennd4a", filterType: "BestCase", aaLeft: "1170", aaRight: "1194", ntLeft: "3508", ntRight: "3582" },
  { key: "vill", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "prrt4", filterType: "BestCase", aaLeft: "769", aaRight: "793", ntLeft: "2305", ntRight: "2379" },
  { key: "pbk", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "ebln1", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "reeld1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "las1l", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "slc17a1", filterType: "FailedAllFilters", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ankrd26", filterType: "FailedAllFilters", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "hat1", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "npsr1", filterType: "FailedAllFilters", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "slc5a6", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "stk35", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "cd80", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "cntn5", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "nfatc2", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "hspb8", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "mrc1", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "prkag1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "st6galnac3", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "espn", filterType: "BestCase", aaLeft: "678", aaRight: "702", ntLeft: "2032", ntRight: "2106" },
  { key: "kiaa1614", filterType: "FailedAllFilters", aaLeft: "1033", aaRight: "1057", ntLeft: "3097", ntRight: "3171" },
  { key: "kiaa0408", filterType: "FailedAllFilters", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "foxd4l1", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "hoxd1", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "hnrnpa0", filterType: "SecondBest", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "immp2l", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "tbccd1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "apobec3d", filterType: "FailedAllFilters", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "ube2j1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "casp10", filterType: "FailedAllFilters", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "iqsec2", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "tekt5", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "gtf2h2c", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "mrps31", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "txlnb", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "fyco1", filterType: "BestCase", aaLeft: "754", aaRight: "778", ntLeft: "2260", ntRight: "2334" },
  { key: "uchl3", filterType: "SecondBest", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "marveld3", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "adh7", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "ankrd11", filterType: "FailedAllFilters", aaLeft: "1534", aaRight: "1558", ntLeft: "4600", ntRight: "4674" },
  { key: "prmt9", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "tor1b", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "atp1a2", filterType: "BestCase", aaLeft: "778", aaRight: "802", ntLeft: "2332", ntRight: "2406" },
  { key: "dtna", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "ptpn23", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "znf384", filterType: "SecondBest", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "eps15l1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "cbx6", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "adgrf3", filterType: "BestCase", aaLeft: "624", aaRight: "648", ntLeft: "1870", ntRight: "1944" },
  { key: "rasl10a", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "pdcd2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "rnf139", filterType: "FailedAllFilters", aaLeft: "595", aaRight: "619", ntLeft: "1783", ntRight: "1857" },
  { key: "drc3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "pomp", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "phlpp2", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "adam17", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ppp6r1", filterType: "BestCase", aaLeft: "632", aaRight: "656", ntLeft: "1894", ntRight: "1968" },
  { key: "vamp3", filterType: "SecondBest", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "bex5", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "bhlhe41", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "krit1", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "fam236b", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "syt6", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "fcf1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "ufsp1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "ifit1b", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "chrne", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "bhlha9", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "adamts3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "plekha7", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "gdf2", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "pofut2", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "akirin1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "nhp2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rpl7l1", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "mpzl3", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "grin2b", filterType: "BestCase", aaLeft: "1206", aaRight: "1230", ntLeft: "3616", ntRight: "3690" },
  { key: "rnf40", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "kntc1", filterType: "BestCase", aaLeft: "1477", aaRight: "1501", ntLeft: "4429", ntRight: "4503" },
  { key: "clec11a", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "nr4a1", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "lgi2", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "spdye18", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "gpatch11", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "gldc", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "lmln", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "postn", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ankzf1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "manba", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "krt76", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "trmt6", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "cwf19l1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "cdk5r1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "mfrp", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "gage12d", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "dqx1", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "krtap20-2", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "sgk3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "anxa2", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "cd1c", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ppp1r13b", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "gulp1", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "uroc1", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "pycr3", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slx4", filterType: "FailedAllFilters", aaLeft: "1651", aaRight: "1675", ntLeft: "4951", ntRight: "5025" },
  { key: "evx2", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "apoe", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "emilin2", filterType: "BestCase", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "h2aw", filterType: "SecondBest", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "mfsd6", filterType: "BestCase", aaLeft: "722", aaRight: "746", ntLeft: "2164", ntRight: "2238" },
  { key: "paqr6", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "akap14", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ap1m1", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "kcnk15", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "slco4a1", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "bnipl", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "ttc17", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ift80", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "col4a6", filterType: "BestCase", aaLeft: "766", aaRight: "790", ntLeft: "2296", ntRight: "2370" },
  { key: "rnd3", filterType: "SecondBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "arhgdib", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "tmem108", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "awat1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "taf4b", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "wdr87", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "lemd3", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "btn2a1", filterType: "FailedAllFilters", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "hsd17b11", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ptgis", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "foxn3", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "tspyl1", filterType: "FailedAllFilters", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "piezo2", filterType: "BestCase", aaLeft: "1883", aaRight: "1907", ntLeft: "5647", ntRight: "5721" },
  { key: "stx1b", filterType: "SecondBest", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "enoph1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "asb11", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "phyhip", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "espl1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "fam83h", filterType: "BestCase", aaLeft: "856", aaRight: "880", ntLeft: "2566", ntRight: "2640" },
  { key: "nedd8", filterType: "SecondBest", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rps16", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "etv3l", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "znf350", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "or2f2", filterType: "FailedAllFilters", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "sc5d", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "atp7b", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "f3", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ppfia2", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "inmt", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "plcl2", filterType: "BestCase", aaLeft: "905", aaRight: "929", ntLeft: "2713", ntRight: "2787" },
  { key: "btbd2", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "usf3", filterType: "FailedAllFilters", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "mybpc1", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "prdm7", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "cpne4", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "abhd11", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "crx", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mrm2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "snrpa1", filterType: "SecondBest", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "znf432", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "nemp2", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "depdc7", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "znf77", filterType: "FailedAllFilters", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "cd63", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "pld6", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "cers2", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "fbxo40", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "usp22", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "cstpp1", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "ip6k3", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "dntt", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "or2at4", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "tgm4", filterType: "FailedAllFilters", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "arr3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ptprs", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "psmb7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "fiz1", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "itgb1", filterType: "BestCase", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "saa1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "ltc4s", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "plagl1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "enpep", filterType: "FailedAllFilters", aaLeft: "754", aaRight: "778", ntLeft: "2260", ntRight: "2334" },
  { key: "chmp1b", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "crisp3", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "arfip2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pccb", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "usp15", filterType: "BestCase", aaLeft: "620", aaRight: "644", ntLeft: "1858", ntRight: "1932" },
  { key: "c10orf90", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "itga6", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "dclk2", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "ifna13", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "glyat", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "pdxk", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "map3k14", filterType: "BestCase", aaLeft: "629", aaRight: "653", ntLeft: "1885", ntRight: "1959" },
  { key: "ube2d2", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "btg4", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "a1bg", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "pgp", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "mettl2a", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "dip2a", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "retsat", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "capn10", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "dbi", filterType: "FourthBest", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "lztr1", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "znf678", filterType: "FailedAllFilters", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "olfm1", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "spry4", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "ct47a3", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "cyp2e1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "arfgef3", filterType: "BestCase", aaLeft: "1062", aaRight: "1086", ntLeft: "3184", ntRight: "3258" },
  { key: "psmd12", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "rasl12", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "kctd18", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "rps21", filterType: "SecondBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "opn1lw", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "spg11", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "nup107", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ticrr", filterType: "BestCase", aaLeft: "822", aaRight: "846", ntLeft: "2464", ntRight: "2538" },
  { key: "znf367", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "gucy1b1", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "tfec", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "irak2", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "ice2", filterType: "BestCase", aaLeft: "876", aaRight: "900", ntLeft: "2626", ntRight: "2700" },
  { key: "ifngr2", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "ugt2b15", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "tulp2", filterType: "FailedAllFilters", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "pigr", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "clmn", filterType: "FailedAllFilters", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "coa3", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "rmnd5b", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "anxa13", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "cbwd5", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "il27ra", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "slc35b3", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "toe1", filterType: "BestCase", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "cdkl4", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "ssc4d", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "dnajb8", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "lat", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "wrap73", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "wdr53", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "znf526", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "srrm4", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "hla-dqb1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "synm", filterType: "FailedAllFilters", aaLeft: "878", aaRight: "902", ntLeft: "2632", ntRight: "2706" },
  { key: "klhl35", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "nox3", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "angptl4", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "luzp2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "slc25a30", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "exosc5", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "a2ml1", filterType: "FailedAllFilters", aaLeft: "1061", aaRight: "1085", ntLeft: "3181", ntRight: "3255" },
  { key: "ngdn", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "clpsl1", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "xcl2", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "uncx", filterType: "FailedAllFilters", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "znf273", filterType: "FailedAllFilters", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "kics2", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "arnt", filterType: "BestCase", aaLeft: "660", aaRight: "684", ntLeft: "1978", ntRight: "2052" },
  { key: "ptpn4", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "chrna2", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "nbea", filterType: "BestCase", aaLeft: "1644", aaRight: "1668", ntLeft: "4930", ntRight: "5004" },
  { key: "capzb", filterType: "SecondBest", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "rsph4a", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "cttnbp2nl", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "chd6", filterType: "BestCase", aaLeft: "2675", aaRight: "2699", ntLeft: "8023", ntRight: "8097" },
  { key: "tex55", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "lyzl4", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "nipsnap3a", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "smarcd2", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "vmac", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "fgfbp3", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ccpg1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "tro", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "clip1", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "lbx2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "zmynd11", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "znf511", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "wdr64", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "slc22a7", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "tktl1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "susd4", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "defb128", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "crispld1", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "atmin", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "creb3", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "smad1", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "slc35a4", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "lamp5", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "usp40", filterType: "BestCase", aaLeft: "1076", aaRight: "1100", ntLeft: "3226", ntRight: "3300" },
  { key: "fbxo22", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "psmd5", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "art1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "id2", filterType: "SecondBest", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "krtap12-4", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "eif3i", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "tlx1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "strip1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "slc9a9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ctxn3", filterType: "SecondBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "cyp26c1", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "rxra", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "orai1", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "vn1r4", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "msantd2", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "mccc2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "phactr3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "rab11a", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "loc112268293", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "daam1", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "elmo2", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "rabep1", filterType: "BestCase", aaLeft: "763", aaRight: "787", ntLeft: "2287", ntRight: "2361" },
  { key: "azin2", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "prox2", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "b3galt1", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "dhx34", filterType: "BestCase", aaLeft: "842", aaRight: "866", ntLeft: "2524", ntRight: "2598" },
  { key: "prpf3", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "prkrip1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "sdcbp2", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "fam171b", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "agap6", filterType: "FailedAllFilters", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "card11", filterType: "BestCase", aaLeft: "1059", aaRight: "1083", ntLeft: "3175", ntRight: "3249" },
  { key: "klf17", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "dnlz", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "ccdc200", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "iqcd", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "arih1", filterType: "SecondBest", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ttc5", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "pcdhb8", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "app", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "med29", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "chuk", filterType: "BestCase", aaLeft: "670", aaRight: "694", ntLeft: "2008", ntRight: "2082" },
  { key: "c18orf32", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "c6", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gramd1a", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "drap1", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "pramef15", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "pdcl3", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "gstm1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "gle1", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "znf467", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "ubr4", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "cdhr3", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "spaca1", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "cd36", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "znf22", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "tmppe", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ipo13", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rps7", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "fbxo15", filterType: "FailedAllFilters", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "arl14epl", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "psmg2", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "cdca4", filterType: "SecondBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "fcgr3a", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "c8b", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "znf226", filterType: "FailedAllFilters", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "arl13a", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "ccr4", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "zfyve28", filterType: "BestCase", aaLeft: "759", aaRight: "783", ntLeft: "2275", ntRight: "2349" },
  { key: "mageb5", filterType: "ThirdBest", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "slc25a52", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "phtf1", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "map1b", filterType: "BestCase", aaLeft: "961", aaRight: "985", ntLeft: "2881", ntRight: "2955" },
  { key: "map1lc3b", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "gch1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ctso", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "cxcr3", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "gnl3l", filterType: "BestCase", aaLeft: "510", aaRight: "534", ntLeft: "1528", ntRight: "1602" },
  { key: "il20ra", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "rhbg", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "akr1c3", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "cox17", filterType: "SecondBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "wdr31", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "znf544", filterType: "FailedAllFilters", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "gpc5", filterType: "BestCase", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "syngr3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "cep162", filterType: "FailedAllFilters", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "lrrc28", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "pate4", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "rnasel", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "pou2f3", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "hbg2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cog1", filterType: "BestCase", aaLeft: "940", aaRight: "964", ntLeft: "2818", ntRight: "2892" },
  { key: "rsrc1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "rps27a", filterType: "FourthBest", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tspan14", filterType: "SecondBest", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "slc35d1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "siglec1", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "ncoa4", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "phospho2", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "nyap2", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "arhgef19", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "meox2", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ccl8", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "tnnt2", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "lims3", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "kcns2", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "slc35g6", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "tbc1d5", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "plekhj1", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "ccdc169", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "exoc7", filterType: "BestCase", aaLeft: "652", aaRight: "676", ntLeft: "1954", ntRight: "2028" },
  { key: "trim64c", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "sil1", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "xrcc4", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "oma1", filterType: "FailedAllFilters", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "or4d1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "cox20", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "znf573", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "hivep2", filterType: "BestCase", aaLeft: "743", aaRight: "767", ntLeft: "2227", ntRight: "2301" },
  { key: "crebrf", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "foxf2", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "pym1", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "cntrl", filterType: "BestCase", aaLeft: "1981", aaRight: "2005", ntLeft: "5941", ntRight: "6015" },
  { key: "unc79", filterType: "BestCase", aaLeft: "1713", aaRight: "1737", ntLeft: "5137", ntRight: "5211" },
  { key: "trim28", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "cfdp1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "urb2", filterType: "FailedAllFilters", aaLeft: "620", aaRight: "644", ntLeft: "1858", ntRight: "1932" },
  { key: "med12l", filterType: "BestCase", aaLeft: "1238", aaRight: "1262", ntLeft: "3712", ntRight: "3786" },
  { key: "myadml2", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "hypk", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "mfsd2a", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "lyz", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "tex37", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "mysm1", filterType: "BestCase", aaLeft: "734", aaRight: "758", ntLeft: "2200", ntRight: "2274" },
  { key: "sh3bp1", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "fam163a", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ssuh2", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "ly96", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gjb7", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "znf211", filterType: "FailedAllFilters", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "mto1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "fkbp14", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pla2g3", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "magea2", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "osbpl2", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "dmbx1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "h4c4", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cnot6", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "tspan7", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "ccdc196", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "c1orf115", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "arhgap33", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "snx7", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "or1j1", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "pde7b", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "ap3m2", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "elovl7", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "xab2", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "adam8", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "or10a4", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "mettl18", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "etaa1", filterType: "FailedAllFilters", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "natd1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "kif5c", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "krtap22-1", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "mms22l", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "c2cd4d", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "tspo2", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "bmp10", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "oprd1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "efcc1", filterType: "FailedAllFilters", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "arpc2", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "rem1", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "gpr6", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tldc2", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "col6a5", filterType: "FailedAllFilters", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "spin3", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "tm6sf1", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "tmcc1", filterType: "FailedAllFilters", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "kcna6", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "ly6g6d", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "sdf2l1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "cd5", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "tkfc", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "cdc40", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "bckdhb", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "pdzd7", filterType: "BestCase", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "cep95", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "crygn", filterType: "FourthBest", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "inf2", filterType: "BestCase", aaLeft: "988", aaRight: "1012", ntLeft: "2962", ntRight: "3036" },
  { key: "kcnb1", filterType: "FailedAllFilters", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "ly6g5c", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "acvr1b", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "tent5d", filterType: "FailedAllFilters", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "saal1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "grk7", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "faf2", filterType: "SecondBest", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "lrrc74a", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "mlf1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mrps2", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "disp1", filterType: "FailedAllFilters", aaLeft: "1345", aaRight: "1369", ntLeft: "4033", ntRight: "4107" },
  { key: "ccl14", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "nudt18", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "gtf2h3", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "tgfb1i1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "hes4", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mbd3l2b", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "catspere", filterType: "FailedAllFilters", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "galnt16", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "apcs", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "rad21l1", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "mtpap", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "macf1", filterType: "BestCase", aaLeft: "4446", aaRight: "4470", ntLeft: "13336", ntRight: "13410" },
  { key: "jph1", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "c1orf122", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "pacsin1", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "steap1b", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "f2rl1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "tmem141", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "atp2c2", filterType: "BestCase", aaLeft: "617", aaRight: "641", ntLeft: "1849", ntRight: "1923" },
  { key: "cidea", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "pttg2", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "or2l8", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "rpa3", filterType: "ThirdBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "praf2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "trip4", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "cpm", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "pmel", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "fam89b", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "faxdc2", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "plpp7", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ccnl2", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "olr1", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "igsf1", filterType: "BestCase", aaLeft: "1006", aaRight: "1030", ntLeft: "3016", ntRight: "3090" },
  { key: "tns4", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "nt5dc1", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "ptbp2", filterType: "SecondBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "mmd2", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "c3orf14", filterType: "FourthBest", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "phyh", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "stat5a", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "pold2", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "dcaf12l1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "champ1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "syn3", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "tmed7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ppp4r2", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "znf816", filterType: "FailedAllFilters", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "fam216a", filterType: "FourthBest", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "faim2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "smc5", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "sorcs2", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "tigd4", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "slc38a3", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "rab4a", filterType: "SecondBest", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "nanog", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "elfn1", filterType: "FailedAllFilters", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "gpatch4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "parp6", filterType: "SecondBest", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "sgpp1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "hsd3b7", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "ldlrad4", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "pxn", filterType: "BestCase", aaLeft: "702", aaRight: "726", ntLeft: "2104", ntRight: "2178" },
  { key: "lin7a", filterType: "SecondBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "rfpl4a", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "ecm2", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "chdh", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "prr23d1", filterType: "FailedAllFilters", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "znf821", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "tdrd10", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "radil", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "lrp2bp", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "uhrf1bp1l", filterType: "BestCase", aaLeft: "1243", aaRight: "1267", ntLeft: "3727", ntRight: "3801" },
  { key: "rnf170", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "zc3h14", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "ntsr1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "slc7a5", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "myo7b", filterType: "BestCase", aaLeft: "1086", aaRight: "1110", ntLeft: "3256", ntRight: "3330" },
  { key: "mrpl42", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "mpc2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc15a2", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "aspn", filterType: "SecondBest", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "sco1", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "insyn2a", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "c17orf100", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "arf5", filterType: "SecondBest", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "klk3", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "helt", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "brms1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "sh3glb1", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "cep57l1", filterType: "BestCase", aaLeft: "427", aaRight: "451", ntLeft: "1279", ntRight: "1353" },
  { key: "klk10", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "igfl1", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "dtx2", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "asb4", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "smr3b", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "gcnt3", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "lamb1", filterType: "BestCase", aaLeft: "1339", aaRight: "1363", ntLeft: "4015", ntRight: "4089" },
  { key: "cpn1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "kcng4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "ddx19b", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "hp", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "rab11fip4", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "stk38l", filterType: "SecondBest", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "gage10", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "znf793", filterType: "FailedAllFilters", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "ago2", filterType: "BestCase", aaLeft: "550", aaRight: "574", ntLeft: "1648", ntRight: "1722" },
  { key: "h3c4", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "mroh6", filterType: "BestCase", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "cdc7", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "or6j1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "vgf", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "sac3d1", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "or10g6", filterType: "FailedAllFilters", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "slco1a2", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "fgf19", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "kif3a", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "c19orf71", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "adh1c", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ebi3", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "rpgrip1", filterType: "BestCase", aaLeft: "774", aaRight: "798", ntLeft: "2320", ntRight: "2394" },
  { key: "cirbp", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "nbn", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "ppm1j", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "vstm5", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "tmem114", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "tm7sf2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "rp2", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "blcap", filterType: "SecondBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "or1l3", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "hs3st3a1", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "pcyox1", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "dock7", filterType: "BestCase", aaLeft: "1260", aaRight: "1284", ntLeft: "3778", ntRight: "3852" },
  { key: "pi3", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "sgsm1", filterType: "BestCase", aaLeft: "729", aaRight: "753", ntLeft: "2185", ntRight: "2259" },
  { key: "tmem123", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "rnf8", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "p2ry13", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "glmp", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "sytl4", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "tatdn2", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ccdc83", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "slc16a9", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "cytip", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "flcn", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "ifrd1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "dcp1a", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "accsl", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "ndufaf1", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ush2a", filterType: "BestCase", aaLeft: "1986", aaRight: "2010", ntLeft: "5956", ntRight: "6030" },
  { key: "grm5", filterType: "BestCase", aaLeft: "1129", aaRight: "1153", ntLeft: "3385", ntRight: "3459" },
  { key: "zswim6", filterType: "BestCase", aaLeft: "641", aaRight: "665", ntLeft: "1921", ntRight: "1995" },
  { key: "ube2k", filterType: "SecondBest", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "hmbs", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rhobtb3", filterType: "SecondBest", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "vcam1", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "mapk7", filterType: "BestCase", aaLeft: "683", aaRight: "707", ntLeft: "2047", ntRight: "2121" },
  { key: "cfhr5", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "pgap2", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "dppa5", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bax", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "cradd", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "cul9", filterType: "BestCase", aaLeft: "613", aaRight: "637", ntLeft: "1837", ntRight: "1911" },
  { key: "cacng8", filterType: "SecondBest", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "lmod1", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "desi2", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "limd2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "smco4", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "c11orf86", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ct45a10", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "kcnd3", filterType: "SecondBest", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "serpina9", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "hapln4", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "lmntd1", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "mtcp1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "il17rd", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "vwc2l", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tbx19", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fgd1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ywhah", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "scn10a", filterType: "BestCase", aaLeft: "1847", aaRight: "1871", ntLeft: "5539", ntRight: "5613" },
  { key: "mllt3", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "phlda3", filterType: "SecondBest", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "ocstamp", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "or2w1", filterType: "FailedAllFilters", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "cyren", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "mlx", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "gata1", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "esyt3", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "mcm3", filterType: "BestCase", aaLeft: "763", aaRight: "787", ntLeft: "2287", ntRight: "2361" },
  { key: "zmym4", filterType: "BestCase", aaLeft: "849", aaRight: "873", ntLeft: "2545", ntRight: "2619" },
  { key: "dnase1l1", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "stoml1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "vps4b", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "shroom1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "dapk2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rnf125", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "abca5", filterType: "BestCase", aaLeft: "1079", aaRight: "1103", ntLeft: "3235", ntRight: "3309" },
  { key: "bola1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "znf687", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "clhc1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "dynlt5", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "apol6", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "mrpl17", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "rnase2", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "arrdc3", filterType: "SecondBest", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "samm50", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "irf4", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "clk2", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "lhx5", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "ervk3-1", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "vwa5a", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "sema4b", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "dstyk", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ppp3r2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "znf398", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "nxnl2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "kyat1", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "myo6", filterType: "BestCase", aaLeft: "1141", aaRight: "1165", ntLeft: "3421", ntRight: "3495" },
  { key: "cenpm", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "atp10a", filterType: "BestCase", aaLeft: "1140", aaRight: "1164", ntLeft: "3418", ntRight: "3492" },
  { key: "kcnip4", filterType: "SecondBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "suv39h1", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "fsip1", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "fas", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "atp5f1e", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "nfib", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "zcchc2", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "myl1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "polr2g", filterType: "SecondBest", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "egln2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "rnf112", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "malsu1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "gale", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "mrpl20", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "csrnp2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "flt1", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "timm10b", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "slc12a2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "eloc", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "or9g1", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "scmh1", filterType: "BestCase", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "ifna2", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "rhbdf1", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "slc47a2", filterType: "FailedAllFilters", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "myh7b", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "prcc", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "slc50a1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or2t6", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "polr3f", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "septin8", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "abcc3", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "eme2", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "rnf103", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "dars2", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "etnk2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "gpam", filterType: "BestCase", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "nop14", filterType: "BestCase", aaLeft: "735", aaRight: "759", ntLeft: "2203", ntRight: "2277" },
  { key: "or13c9", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "tmem132e", filterType: "BestCase", aaLeft: "1038", aaRight: "1062", ntLeft: "3112", ntRight: "3186" },
  { key: "mc4r", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "sema6d", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "mmp10", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ksr1", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "zbed6", filterType: "BestCase", aaLeft: "809", aaRight: "833", ntLeft: "2425", ntRight: "2499" },
  { key: "atp6v1d", filterType: "SecondBest", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "abcb10", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "pcbp4", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "or2ag1", filterType: "FailedAllFilters", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "cadm2", filterType: "SecondBest", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "rcn3", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "smg1", filterType: "BestCase", aaLeft: "1553", aaRight: "1577", ntLeft: "4657", ntRight: "4731" },
  { key: "gemin4", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "acp4", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "tonsl", filterType: "BestCase", aaLeft: "1216", aaRight: "1240", ntLeft: "3646", ntRight: "3720" },
  { key: "chst13", filterType: "FailedAllFilters", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "fgf7", filterType: "SecondBest", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "fcer1a", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "pik3r1", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "afp", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "znf99", filterType: "FailedAllFilters", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "abhd17b", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "stk38", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "znf852", filterType: "FailedAllFilters", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "avpr1b", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "dnah10", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "or5m9", filterType: "FailedAllFilters", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "hspa2", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "tubgcp3", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "znf865", filterType: "FailedAllFilters", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "shkbp1", filterType: "BestCase", aaLeft: "676", aaRight: "700", ntLeft: "2026", ntRight: "2100" },
  { key: "sp7", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "apc", filterType: "BestCase", aaLeft: "1787", aaRight: "1811", ntLeft: "5359", ntRight: "5433" },
  { key: "top1mt", filterType: "FailedAllFilters", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "osgin2", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "rab3b", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "man2c1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "cecr2", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "mmp27", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "b2m", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "amh", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "ybx2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "cst2", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "utp3", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "uqcr10", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "poteb3", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "tln1", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "ganc", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "znf696", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "cacna2d1", filterType: "BestCase", aaLeft: "1066", aaRight: "1090", ntLeft: "3196", ntRight: "3270" },
  { key: "or5p2", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "unc5a", filterType: "BestCase", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "wsb1", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "trpm7", filterType: "BestCase", aaLeft: "1317", aaRight: "1341", ntLeft: "3949", ntRight: "4023" },
  { key: "lce1f", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "baz2b", filterType: "BestCase", aaLeft: "1499", aaRight: "1523", ntLeft: "4495", ntRight: "4569" },
  { key: "tmem82", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "cphxl2", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "acbd6", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "syncrip", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "slc39a12", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "tut1", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "crtc3", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "ramp1", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "ccdc92", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "klhl3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rprd1b", filterType: "SecondBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "chi3l1", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "c11orf97", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "dusp21", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "spata5", filterType: "BestCase", aaLeft: "806", aaRight: "830", ntLeft: "2416", ntRight: "2490" },
  { key: "ctps1", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "edaradd", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "kcne2", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "cblc", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "spib", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "spdye15", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "commd10", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "hnrnpcl2", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "prokr1", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "rgs3", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "gldn", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "slc4a11", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "foxp2", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "znf705d", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "armc1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "znf782", filterType: "FailedAllFilters", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "clic4", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "wbp4", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "yif1a", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "slx9", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "lsm14b", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "dusp16", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "apoh", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "sec24d", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "slc30a10", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "dpysl5", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "nkx2-2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "plin3", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "arhgap40", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "kcnk18", filterType: "FailedAllFilters", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "gzmb", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "sh2b1", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "retreg1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "c9orf64", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ppp1ca", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "dscaml1", filterType: "BestCase", aaLeft: "1989", aaRight: "2013", ntLeft: "5965", ntRight: "6039" },
  { key: "h3c13", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "clec2d", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "aqp10", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lce2a", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "pum3", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "ankef1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "fchsd1", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "jakmip3", filterType: "BestCase", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "elof1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "timm17a", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "hoxa9", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "mas1", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "eif4e2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "xkr7", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "znf830", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "nlrx1", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "cyp2s1", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "ankrd49", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "pabpc5", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "sertad1", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "wdr7", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "commd6", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "pafah1b2", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "fzd2", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ogn", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "mgme1", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "ntn5", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "tmem239", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "crk", filterType: "SecondBest", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "krtap19-6", filterType: "ThirdBest", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "aoc3", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "tsen54", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "abce1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "h2bc18", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pafah2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ube2r2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "hexa", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "mrpl53", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "ykt6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "defb108b", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "hal", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "uba1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "tuft1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "iqgap2", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "dapl1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "krbox5", filterType: "FourthBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "spag7", filterType: "SecondBest", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "mast1", filterType: "BestCase", aaLeft: "1451", aaRight: "1475", ntLeft: "4351", ntRight: "4425" },
  { key: "mvp", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "coro2b", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "gnl2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "amz2", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "sema3a", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "styx", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "jmy", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "reg3a", filterType: "FourthBest", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "igfbp3", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "or5d14", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "pgc", filterType: "FailedAllFilters", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "tasor2", filterType: "FailedAllFilters", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "derl2", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "rbck1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "pced1a", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "pglyrp3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rdh13", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ablim2", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "nutm2g", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "st8sia6", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "pla2g4e", filterType: "BestCase", aaLeft: "715", aaRight: "739", ntLeft: "2143", ntRight: "2217" },
  { key: "atad3b", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "matr3", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "bst1", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "sox2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "pole3", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "oc90", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "ppp4r3a", filterType: "BestCase", aaLeft: "808", aaRight: "832", ntLeft: "2422", ntRight: "2496" },
  { key: "mst1r", filterType: "BestCase", aaLeft: "722", aaRight: "746", ntLeft: "2164", ntRight: "2238" },
  { key: "atf6b", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "hmgcs2", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "inpp5a", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "map6", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "clstn2", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "usp3", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "piga", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "mybl1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "yju2", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ehf", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "noxred1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "stx4", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ascl1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "pnma1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "krt19", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "eif2b4", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "rpusd4", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "slc38a11", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "men1", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "ppt2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "alx1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "lrat", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ing3", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "ncor1", filterType: "BestCase", aaLeft: "1171", aaRight: "1195", ntLeft: "3511", ntRight: "3585" },
  { key: "ngrn", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ccdc144a", filterType: "FailedAllFilters", aaLeft: "983", aaRight: "1007", ntLeft: "2947", ntRight: "3021" },
  { key: "arhgap15", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tcf12", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "casp8ap2", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "c17orf67", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "atp2b3", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "rpia", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "lgr6", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "afg1l", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "gdpd1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "clec7a", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "atrip", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "wfdc13", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fam205c", filterType: "FourthBest", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "rnf208", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "dohh", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "taok3", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "fancb", filterType: "FailedAllFilters", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "swap70", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "flvcr1", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "slc5a12", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "zfand5", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "atg4a", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "aldh2", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "cldn19", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "clec4f", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "cmklr2", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "npy", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tcf4", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "dlg2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "gabpb2", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "kif4b", filterType: "FailedAllFilters", aaLeft: "931", aaRight: "955", ntLeft: "2791", ntRight: "2865" },
  { key: "lrrtm2", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "ppme1", filterType: "SecondBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ccdc187", filterType: "FailedAllFilters", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "arhgap22", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "tcf25", filterType: "BestCase", aaLeft: "648", aaRight: "672", ntLeft: "1942", ntRight: "2016" },
  { key: "fbxl7", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "slc39a6", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "papola", filterType: "BestCase", aaLeft: "641", aaRight: "665", ntLeft: "1921", ntRight: "1995" },
  { key: "emid1", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "gpr18", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "tafa5", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pde6c", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "fut9", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c17orf50", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "tlcd1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "hsf2", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "prm3", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "egr4", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "gabarapl1", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "xpr1", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "mxi1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "arsl", filterType: "FailedAllFilters", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "pms2", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "nol12", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "nrxn2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "zmiz1", filterType: "BestCase", aaLeft: "868", aaRight: "892", ntLeft: "2602", ntRight: "2676" },
  { key: "selenow", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "il11ra", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "lep", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "slc16a11", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "htr4", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "msh2", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "magix", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "znf562", filterType: "FailedAllFilters", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "wdr17", filterType: "BestCase", aaLeft: "1197", aaRight: "1221", ntLeft: "3589", ntRight: "3663" },
  { key: "loc400499", filterType: "FailedAllFilters", aaLeft: "3200", aaRight: "3224", ntLeft: "9598", ntRight: "9672" },
  { key: "ugt1a6", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "tmem198", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "itfg1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "tspan32", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "sh3d21", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ccdc38", filterType: "FailedAllFilters", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "neurl4", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mob1b", filterType: "SecondBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "vwde", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "ccdc178", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "wbp1l", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "spata21", filterType: "FailedAllFilters", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "klln", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "cdc25c", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "il17f", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "pradc1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "clta", filterType: "SecondBest", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "znf200", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "cnpy2", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "riox2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "npy1r", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "anp32b", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "trim39", filterType: "SecondBest", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "lgals3", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "smarcc1", filterType: "BestCase", aaLeft: "1020", aaRight: "1044", ntLeft: "3058", ntRight: "3132" },
  { key: "adarb1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "bdp1", filterType: "FailedAllFilters", aaLeft: "1684", aaRight: "1708", ntLeft: "5050", ntRight: "5124" },
  { key: "bcl2l2", filterType: "SecondBest", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "tex26", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "rtl1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "gas6", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "nme6", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "mad2l1bp", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "lsm4", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "znf728", filterType: "FailedAllFilters", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "kdm8", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "zim2", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "tex11", filterType: "FailedAllFilters", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "vdr", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "snrpc", filterType: "FourthBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "sephs1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "slc25a43", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "ppp1r42", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "stxbp4", filterType: "BestCase", aaLeft: "529", aaRight: "553", ntLeft: "1585", ntRight: "1659" },
  { key: "cyp4f3", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "c17orf98", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "spock1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "agpat1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "clec18a", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "shisa5", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "fahd2b", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "pgghg", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "kcnq4", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "nnat", filterType: "SecondBest", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "ccdc28b", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ift27", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "spata16", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "vapb", filterType: "SecondBest", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "olig2", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "prkd3", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "tob2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "smim10l1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "wdr20", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "znf555", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "krtap13-1", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "lrrc39", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "gna13", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "tmx4", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "foxd4", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "slc33a1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "chmp7", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "inip", filterType: "SecondBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "rgs10", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "mcm3ap", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "or9q1", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "kif19", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "sec14l1", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "slc66a1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cib1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "zbtb49", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "lrit1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "efcab6", filterType: "FailedAllFilters", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "slc24a2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "timm44", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "dusp3", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "pwwp2b", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "zbtb5", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "or2b6", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "mdfic2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "znf169", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "grhpr", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "caskin1", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "tgfbr3l", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "cmya5", filterType: "FailedAllFilters", aaLeft: "1310", aaRight: "1334", ntLeft: "3928", ntRight: "4002" },
  { key: "nsun6", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "ankub1", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "slc6a17", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "garre1", filterType: "BestCase", aaLeft: "608", aaRight: "632", ntLeft: "1822", ntRight: "1896" },
  { key: "chia", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "gnpda2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "mroh2b", filterType: "BestCase", aaLeft: "1141", aaRight: "1165", ntLeft: "3421", ntRight: "3495" },
  { key: "cd27", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nadk", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gsg1l2", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "znf441", filterType: "FailedAllFilters", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "slc6a20", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "mpp7", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "endou", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "hipk1", filterType: "BestCase", aaLeft: "957", aaRight: "981", ntLeft: "2869", ntRight: "2943" },
  { key: "cep295nl", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "mcrs1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "acod1", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "cplx1", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "hs6st3", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "zc2hc1a", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "rlf", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "usp51", filterType: "FailedAllFilters", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "ints4", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "fbxo33", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "tep1", filterType: "BestCase", aaLeft: "2034", aaRight: "2058", ntLeft: "6100", ntRight: "6174" },
  { key: "bloc1s2", filterType: "SecondBest", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "cert1", filterType: "BestCase", aaLeft: "596", aaRight: "620", ntLeft: "1786", ntRight: "1860" },
  { key: "pcbd2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "psme4", filterType: "BestCase", aaLeft: "1232", aaRight: "1256", ntLeft: "3694", ntRight: "3768" },
  { key: "hdac3", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "prdm14", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "il17rel", filterType: "FailedAllFilters", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "csnk1g1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "abhd2", filterType: "SecondBest", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "c16orf46", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "cbr1", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "adgrl4", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "tp53tg5", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "or2a1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "slfn11", filterType: "FailedAllFilters", aaLeft: "752", aaRight: "776", ntLeft: "2254", ntRight: "2328" },
  { key: "hace1", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "nectin2", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "rgma", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "snx18", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "atp8b4", filterType: "BestCase", aaLeft: "721", aaRight: "745", ntLeft: "2161", ntRight: "2235" },
  { key: "stx11", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "chrm1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "pramef33", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "prcp", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "tnfrsf17", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "poc1a", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "phf19", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "klhl13", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "thap4", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "btla", filterType: "FailedAllFilters", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "pcdhb11", filterType: "FailedAllFilters", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "podnl1", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "sirt1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "paxip1", filterType: "FailedAllFilters", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "tex44", filterType: "FailedAllFilters", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "ntan1", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "fn1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "brix1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "nudt4", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "arl6ip4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "bbs12", filterType: "FailedAllFilters", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "ppp1r17", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slc25a16", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "magef1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "fem1c", filterType: "SecondBest", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "rfpl4al1", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "otud7b", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "rbm43", filterType: "FailedAllFilters", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "gpr85", filterType: "SecondBest", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ugcg", filterType: "SecondBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "mtss2", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "mix23", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "tdrd1", filterType: "BestCase", aaLeft: "1030", aaRight: "1054", ntLeft: "3088", ntRight: "3162" },
  { key: "or2a25", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "c12orf42", filterType: "FailedAllFilters", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "ifi35", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "erich6", filterType: "FailedAllFilters", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "plekhm2", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "lfng", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "kcnt1", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "timm8b", filterType: "SecondBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "cdh17", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "ptcd1", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "eno3", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "tsn", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "fnta", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "sik2", filterType: "BestCase", aaLeft: "653", aaRight: "677", ntLeft: "1957", ntRight: "2031" },
  { key: "sost", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "cep126", filterType: "FailedAllFilters", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "kazald1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "foxa1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "dipk2b", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "znf500", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "wdr75", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "hip1", filterType: "BestCase", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "kmo", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "cdh20", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "csgalnact2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "dnajc9", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "mapkap1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "tmub1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "dand5", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "nav1", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "hmga2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "pkia", filterType: "SecondBest", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "rc3h2", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "spats1", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "krtdap", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "triqk", filterType: "SecondBest", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "htra4", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "or2a12", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "c12orf75", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ak4", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "gsr", filterType: "SecondBest", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "slc35c2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nog", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "tgfa", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cep19", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "heatr1", filterType: "BestCase", aaLeft: "1881", aaRight: "1905", ntLeft: "5641", ntRight: "5715" },
  { key: "acta2", filterType: "SecondBest", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "oosp4b", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "gask1a", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "nrl", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "nckap5l", filterType: "BestCase", aaLeft: "851", aaRight: "875", ntLeft: "2551", ntRight: "2625" },
  { key: "slc25a21", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "c11orf68", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "neil3", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "wnt2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "krt84", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "klhl24", filterType: "SecondBest", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "higd2a", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "dyrk3", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "smim5", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "homez", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "znf414", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "fcrl3", filterType: "FailedAllFilters", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "slc23a2", filterType: "BestCase", aaLeft: "626", aaRight: "650", ntLeft: "1876", ntRight: "1950" },
  { key: "ube2e3", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "dcst2", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "cdkn1c", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "rfc4", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "a1cf", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "sun1", filterType: "BestCase", aaLeft: "683", aaRight: "707", ntLeft: "2047", ntRight: "2121" },
  { key: "tbc1d15", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "ugt8", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "slc34a1", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "omd", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "dcaf16", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "fxyd7", filterType: "SecondBest", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "them4", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "mier3", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "dnmt3b", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "marchf6", filterType: "SecondBest", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "brme1", filterType: "FailedAllFilters", aaLeft: "427", aaRight: "451", ntLeft: "1279", ntRight: "1353" },
  { key: "ipo7", filterType: "BestCase", aaLeft: "943", aaRight: "967", ntLeft: "2827", ntRight: "2901" },
  { key: "ppig", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "srcap", filterType: "BestCase", aaLeft: "2881", aaRight: "2905", ntLeft: "8641", ntRight: "8715" },
  { key: "ercc2", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "tnfrsf10b", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "prrx1", filterType: "SecondBest", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "pnn", filterType: "SecondBest", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "hla-a", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "isoc1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "mesd", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "slc7a14", filterType: "BestCase", aaLeft: "724", aaRight: "748", ntLeft: "2170", ntRight: "2244" },
  { key: "alpp", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "znf669", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "opn1mw2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "trappc5", filterType: "SecondBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "spdye8", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "fyn", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "mccd1", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "folh1b", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "thsd8", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "grik3", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "cox6b1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ndufa13", filterType: "FourthBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "golga3", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "ttll11", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "dok1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "sass6", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "nmnat2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "brwd3", filterType: "BestCase", aaLeft: "1506", aaRight: "1530", ntLeft: "4516", ntRight: "4590" },
  { key: "gopc", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "ddx6", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "fam8a1", filterType: "SecondBest", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "tomm5", filterType: "SecondBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "ankrd13d", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "col18a1", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "tstd2", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "znf341", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "reep5", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "pabpn1l", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "pou5f1b", filterType: "FailedAllFilters", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "or2g3", filterType: "FailedAllFilters", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "nek1", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "ddias", filterType: "FailedAllFilters", aaLeft: "689", aaRight: "713", ntLeft: "2065", ntRight: "2139" },
  { key: "raf1", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "ms4a4a", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "grap", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "itgb1bp1", filterType: "SecondBest", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "rps19bp1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "actr3b", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "tnpo3", filterType: "SecondBest", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "rbms3", filterType: "SecondBest", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "c15orf39", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "alkbh5", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "usp33", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "ifit5", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "nkd2", filterType: "FailedAllFilters", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "psmc6", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "aldh1b1", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "pctp", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "add2", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "znf423", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "fgb", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "sap18", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ikzf1", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "plppr4", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "cer1", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "or5a1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "cenpvl2", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "h1-7", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "cdkn2d", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "reln", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "cd72", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "znf66", filterType: "FailedAllFilters", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "pa2g4", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "znf281", filterType: "FailedAllFilters", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "rbp7", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "mtf1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "tp53i3", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "asb9", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "mphosph6", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "serpinb3", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "nrp1", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "calhm3", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "gabrb1", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "dyrk1b", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "taf7", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "ppp1r12c", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "nrarp", filterType: "SecondBest", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "fgf14", filterType: "SecondBest", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "rgl3", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "gadl1", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "or4m1", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "dnai1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "smim10l2a", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "znf33a", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "zfpm1", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "rwdd3", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "c9orf78", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "ppm1g", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "tmem119", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "crnkl1", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "cryba2", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "isy1", filterType: "SecondBest", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ttc31", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "bag3", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "rnf5", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "slc16a4", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "myo18a", filterType: "BestCase", aaLeft: "2030", aaRight: "2054", ntLeft: "6088", ntRight: "6162" },
  { key: "ybey", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "pgam4", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ccdc71l", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "gltpd2", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "slc4a3", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "kifc3", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "fgf23", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "c2orf92", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "eif1ax", filterType: "SecondBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "recql5", filterType: "BestCase", aaLeft: "874", aaRight: "898", ntLeft: "2620", ntRight: "2694" },
  { key: "ube2f", filterType: "SecondBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "grm8", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "nphp1", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "or56b2p", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "gabra6", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "cacng5", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "glyr1", filterType: "SecondBest", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "raph1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "zdhhc6", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "cryaa", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "mtfmt", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "prr12", filterType: "BestCase", aaLeft: "1420", aaRight: "1444", ntLeft: "4258", ntRight: "4332" },
  { key: "stard7", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "serpina4", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "cyth1", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "parp11", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "srsf11", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "c10orf62", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "ywhae", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "fam241a", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "kcnj10", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "podn", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "col5a3", filterType: "BestCase", aaLeft: "1645", aaRight: "1669", ntLeft: "4933", ntRight: "5007" },
  { key: "ilvbl", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cnih4", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "ceacam1", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "znf879", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "tlr6", filterType: "FailedAllFilters", aaLeft: "733", aaRight: "757", ntLeft: "2197", ntRight: "2271" },
  { key: "bcl11b", filterType: "BestCase", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "tmem161a", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "nrgn", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "polq", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "rnf128", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "abca8", filterType: "BestCase", aaLeft: "1068", aaRight: "1092", ntLeft: "3202", ntRight: "3276" },
  { key: "fhip1a", filterType: "BestCase", aaLeft: "979", aaRight: "1003", ntLeft: "2935", ntRight: "3009" },
  { key: "sipa1l1", filterType: "BestCase", aaLeft: "856", aaRight: "880", ntLeft: "2566", ntRight: "2640" },
  { key: "pkn1", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "rxfp2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "hmhb1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lhx8", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tmem270", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "irf9", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "setbp1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "skiv2l", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "e2f6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "aadac", filterType: "FailedAllFilters", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "znf395", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "spag16", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "adgrg2", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "tmem247", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "ncaph", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "adora1", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "tti2", filterType: "FailedAllFilters", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "cnnm1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "zscan10", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "tectb", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "fam178b", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "or51b6", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "polr2j", filterType: "SecondBest", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "tcof1", filterType: "BestCase", aaLeft: "910", aaRight: "934", ntLeft: "2728", ntRight: "2802" },
  { key: "narf", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "hnrnpc", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "nlrp12", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "znf85", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "sgca", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "stk24", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "myeov", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "sypl2", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "ccnt1", filterType: "BestCase", aaLeft: "589", aaRight: "613", ntLeft: "1765", ntRight: "1839" },
  { key: "mrps17", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "aldh1l2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or2t33", filterType: "ThirdBest", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "fmnl2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "rpap3", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "tubb", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "ankrd37", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "clcn7", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "irx5", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "dpep1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "ciao2a", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "noxa1", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "nip7", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "plk4", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "jag2", filterType: "BestCase", aaLeft: "1195", aaRight: "1219", ntLeft: "3583", ntRight: "3657" },
  { key: "h4c9", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "alg1", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "ndufa7", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "nxph3", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "rhpn1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "odaph", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "ccdc7", filterType: "FailedAllFilters", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "nudt22", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "mettl15", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "znf581", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "alox12b", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "rnf223", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "acsl6", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "steap3", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "rusc2", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "robo2", filterType: "BestCase", aaLeft: "1268", aaRight: "1292", ntLeft: "3802", ntRight: "3876" },
  { key: "ppp1r14a", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "krtap9-3", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "enpp7", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "atp6ap1", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "krt32", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "pank4", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "lrch1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "unc119", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ajuba", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ift172", filterType: "BestCase", aaLeft: "1292", aaRight: "1316", ntLeft: "3874", ntRight: "3948" },
  { key: "rsph9", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "serpind1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "syvn1", filterType: "BestCase", aaLeft: "556", aaRight: "580", ntLeft: "1666", ntRight: "1740" },
  { key: "dnaaf3", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "wasf3", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "ppp3cb", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "fam53b", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "man1a1", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "edem1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ipcef1", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "sult1a4", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "ufc1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cnppd1", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "naa25", filterType: "BestCase", aaLeft: "724", aaRight: "748", ntLeft: "2170", ntRight: "2244" },
  { key: "arhgef6", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tnfsf14", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "itgae", filterType: "BestCase", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "crygc", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "slc44a4", filterType: "BestCase", aaLeft: "683", aaRight: "707", ntLeft: "2047", ntRight: "2121" },
  { key: "zic1", filterType: "SecondBest", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "tars3", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "dlx2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "jmjd7", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "fmn1", filterType: "BestCase", aaLeft: "782", aaRight: "806", ntLeft: "2344", ntRight: "2418" },
  { key: "tec", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mllt11", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "cabp1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "sparc", filterType: "SecondBest", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "ccl19", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "chn1", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "lpin2", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "nudt15", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "mettl22", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "slfn5", filterType: "FailedAllFilters", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "farp2", filterType: "BestCase", aaLeft: "979", aaRight: "1003", ntLeft: "2935", ntRight: "3009" },
  { key: "fastkd5", filterType: "BestCase", aaLeft: "608", aaRight: "632", ntLeft: "1822", ntRight: "1896" },
  { key: "rnf214", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "nol8", filterType: "FailedAllFilters", aaLeft: "1076", aaRight: "1100", ntLeft: "3226", ntRight: "3300" },
  { key: "spin2b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "usp9y", filterType: "BestCase", aaLeft: "1177", aaRight: "1201", ntLeft: "3529", ntRight: "3603" },
  { key: "smarcad1", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "ngf", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "dnal4", filterType: "SecondBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "hspbap1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gpr33", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "prr3", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "cyp46a1", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "or2l5", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "tmem212", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "matn4", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "ccin", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "rogdi", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "lyrm1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "hinfp", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "mepce", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "ankrd18b", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "c16orf92", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "scrn3", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "srsf7", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cip2a", filterType: "BestCase", aaLeft: "807", aaRight: "831", ntLeft: "2419", ntRight: "2493" },
  { key: "c21orf91", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "nup54", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "zc4h2", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "paep", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "or56a1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "setd7", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "rnpc3", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "pnp", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "chgb", filterType: "FailedAllFilters", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "pfkm", filterType: "BestCase", aaLeft: "704", aaRight: "728", ntLeft: "2110", ntRight: "2184" },
  { key: "slc22a24", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "or10ad1", filterType: "FailedAllFilters", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "ankrd62", filterType: "FailedAllFilters", aaLeft: "760", aaRight: "784", ntLeft: "2278", ntRight: "2352" },
  { key: "herc2", filterType: "BestCase", aaLeft: "3434", aaRight: "3458", ntLeft: "10300", ntRight: "10374" },
  { key: "dnajc24", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "or14a2", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "pcdhga3", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "ppp1r3b", filterType: "FailedAllFilters", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "prtfdc1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mustn1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "fstl5", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "trim2", filterType: "BestCase", aaLeft: "566", aaRight: "590", ntLeft: "1696", ntRight: "1770" },
  { key: "ankrd55", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "trerf1", filterType: "BestCase", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "fis1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "stab2", filterType: "BestCase", aaLeft: "1882", aaRight: "1906", ntLeft: "5644", ntRight: "5718" },
  { key: "dnajc13", filterType: "BestCase", aaLeft: "1576", aaRight: "1600", ntLeft: "4726", ntRight: "4800" },
  { key: "pex11a", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "pcdhgb4", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "gas2l1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "has2", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "cfap99", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "mip", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "osr1", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "gsta4", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "dedd2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "slc22a13", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "gli4", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "septin11", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "meis1", filterType: "SecondBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "dcstamp", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "adamts12", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cbln4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc7a8", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "p4htm", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "extl3", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "cldn7", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "mocs1", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "tmem225", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "kiaa0319", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "dhx9", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "mtch2", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "znf142", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "prpf40a", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "cacna1g", filterType: "BestCase", aaLeft: "2172", aaRight: "2196", ntLeft: "6514", ntRight: "6588" },
  { key: "blvra", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "ca13", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "psmd8", filterType: "SecondBest", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "fam156b", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "rsbn1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "fam90a1", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ppial4a", filterType: "FourthBest", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "eif3d", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "traf3ip3", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "mamld1", filterType: "BestCase", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "fndc3b", filterType: "BestCase", aaLeft: "1055", aaRight: "1079", ntLeft: "3163", ntRight: "3237" },
  { key: "slc9a4", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "znf18", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "znf620", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "defb106a", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "gse1", filterType: "BestCase", aaLeft: "592", aaRight: "616", ntLeft: "1774", ntRight: "1848" },
  { key: "med13", filterType: "BestCase", aaLeft: "1492", aaRight: "1516", ntLeft: "4474", ntRight: "4548" },
  { key: "lca5", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "aldob", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lrrc8a", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "kat6b", filterType: "BestCase", aaLeft: "1305", aaRight: "1329", ntLeft: "3913", ntRight: "3987" },
  { key: "aldh3b2", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "gnai3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "uvrag", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "tle7", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "rnf182", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ubl5", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "h3-4", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "atp6v0a2", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "rab40c", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "rmi1", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "pcdha2", filterType: "FailedAllFilters", aaLeft: "678", aaRight: "702", ntLeft: "2032", ntRight: "2106" },
  { key: "nid1", filterType: "BestCase", aaLeft: "861", aaRight: "885", ntLeft: "2581", ntRight: "2655" },
  { key: "slc36a2", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "myf6", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "cpt1a", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "mixl1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "rpn1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "cyp7b1", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "loc102724023", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "pcdhb5", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "ttc8", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "gnb1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "eola1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "med24", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "nrde2", filterType: "FailedAllFilters", aaLeft: "835", aaRight: "859", ntLeft: "2503", ntRight: "2577" },
  { key: "khdc3l", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ccdc85a", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "kif9", filterType: "BestCase", aaLeft: "711", aaRight: "735", ntLeft: "2131", ntRight: "2205" },
  { key: "miip", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "snx33", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "spcs2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "pramef18", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "lin28b", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "klhdc8a", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "ddx24", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "zmynd8", filterType: "BestCase", aaLeft: "781", aaRight: "805", ntLeft: "2341", ntRight: "2415" },
  { key: "acat2", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "hgfac", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "dym", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "znf175", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rbsn", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "hps3", filterType: "BestCase", aaLeft: "652", aaRight: "676", ntLeft: "1954", ntRight: "2028" },
  { key: "rasd2", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "pomc", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "efcab12", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "mpl", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ghrh", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pabpn1", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "pdgfa", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "gchfr", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "tmco4", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "loxl2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ccr9", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "rab20", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "actl6b", filterType: "SecondBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "rras2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "c11orf16", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "loc105371267", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "kdm3a", filterType: "BestCase", aaLeft: "784", aaRight: "808", ntLeft: "2350", ntRight: "2424" },
  { key: "plscr3", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "crls1", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "ctsb", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "ssrp1", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "vps41", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "nr1h2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "rit1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "fam90a10", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "znf549", filterType: "FailedAllFilters", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "mbd3l3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cux2", filterType: "BestCase", aaLeft: "1354", aaRight: "1378", ntLeft: "4060", ntRight: "4134" },
  { key: "phb2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "smyd1", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "lrrc25", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "atn1", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "lamtor5", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "ddx3x", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "atp6v1fnb", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "rps27l", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tspan19", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "ccdc13", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "sln", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lman1l", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "pak3", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "insyn1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "ccdc153", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "guca2a", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ccl5", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tat", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "hpdl", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "map4k1", filterType: "BestCase", aaLeft: "427", aaRight: "451", ntLeft: "1279", ntRight: "1353" },
  { key: "or52n5", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ccdc24", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "tbc1d8", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "cav3", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "tubg2", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "cxcl2", filterType: "FourthBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "frat2", filterType: "FailedAllFilters", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "sntb1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "hcrtr2", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "flii", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "myrfl", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "large2", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "atp13a3", filterType: "BestCase", aaLeft: "799", aaRight: "823", ntLeft: "2395", ntRight: "2469" },
  { key: "loc102724428", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "trim25", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "or6c1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "prss53", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "efr3b", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "cdin1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "thnsl1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "znf703", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "caln1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ndufs3", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "kcnn4", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "ctrb1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "parvg", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "ino80d", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "rab17", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "atp6v1c1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ano3", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "c11orf21", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "fam83e", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "mcfd2", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "psg1", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "gap43", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "ntf4", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "dlst", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "c2cd6", filterType: "FailedAllFilters", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "get3", filterType: "SecondBest", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "il13", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "capn7", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "slc49a3", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "gnao1", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "p2rx3", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "plod1", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "gng4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "greb1", filterType: "BestCase", aaLeft: "1154", aaRight: "1178", ntLeft: "3460", ntRight: "3534" },
  { key: "pgbd2", filterType: "FailedAllFilters", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "fam126b", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "csag3", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "egfr", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "ppef2", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "c1qtnf6", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "cpne9", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "ppcs", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "atp5mc3", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "gfra1", filterType: "BestCase", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "gp5", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "wfikkn1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "loc107983987", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rpl21", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cga", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "lpar1", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "adgrv1", filterType: "BestCase", aaLeft: "3983", aaRight: "4007", ntLeft: "11947", ntRight: "12021" },
  { key: "f13b", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "sft2d2", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "prrg1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "lsm12", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "hsfx1", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "erbin", filterType: "BestCase", aaLeft: "1218", aaRight: "1242", ntLeft: "3652", ntRight: "3726" },
  { key: "bach2", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "net1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "sstr2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "wars1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "znf117", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "ankrd36b", filterType: "FailedAllFilters", aaLeft: "1009", aaRight: "1033", ntLeft: "3025", ntRight: "3099" },
  { key: "slc26a1", filterType: "BestCase", aaLeft: "565", aaRight: "589", ntLeft: "1693", ntRight: "1767" },
  { key: "rho", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "ldb2", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "usp18", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "dgkk", filterType: "BestCase", aaLeft: "1114", aaRight: "1138", ntLeft: "3340", ntRight: "3414" },
  { key: "ppara", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "slc31a2", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "znf408", filterType: "BestCase", aaLeft: "652", aaRight: "676", ntLeft: "1954", ntRight: "2028" },
  { key: "ddx46", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "map3k19", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "sdc4", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "mmp9", filterType: "BestCase", aaLeft: "629", aaRight: "653", ntLeft: "1885", ntRight: "1959" },
  { key: "sox30", filterType: "BestCase", aaLeft: "673", aaRight: "697", ntLeft: "2017", ntRight: "2091" },
  { key: "cd59", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "synpo2l", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "tprn", filterType: "FailedAllFilters", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "rnase13", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "znf675", filterType: "FailedAllFilters", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "c3orf84", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "apoc4", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "mybph", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "oard1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "krtap4-4", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "cxcl11", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ccne2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "zbtb37", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "arl2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "smim30", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "kctd15", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cpvl", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "pvalef", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tmem14b", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "il24", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "ccdc71", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "defb112", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ppp2r5e", filterType: "SecondBest", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "otulinl", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "dll4", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "ntaq1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "chodl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dnajb5", filterType: "SecondBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "maco1", filterType: "SecondBest", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "babam2", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "lrrc47", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "tmem61", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "mylk3", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "aadacl2", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "golga8g", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "trpc4", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "ephx2", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "gnptg", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "kdm5c", filterType: "BestCase", aaLeft: "1324", aaRight: "1348", ntLeft: "3970", ntRight: "4044" },
  { key: "scn2b", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "klhl38", filterType: "FailedAllFilters", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "gpx1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "piwil2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "pabir3", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "zeb2", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "gpr151", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "strbp", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "grb7", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "pspn", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "kcnk1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "bco1", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "lrrn2", filterType: "BestCase", aaLeft: "682", aaRight: "706", ntLeft: "2044", ntRight: "2118" },
  { key: "lif", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "exosc8", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "rab42", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "ppdpfl", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "pof1b", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "cpa3", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "kcnh6", filterType: "BestCase", aaLeft: "769", aaRight: "793", ntLeft: "2305", ntRight: "2379" },
  { key: "bcl6", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "sptssb", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "myh13", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "anks1a", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "fbxl13", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "b4galnt3", filterType: "BestCase", aaLeft: "755", aaRight: "779", ntLeft: "2263", ntRight: "2337" },
  { key: "thrap3", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "lars2", filterType: "BestCase", aaLeft: "697", aaRight: "721", ntLeft: "2089", ntRight: "2163" },
  { key: "or4k13", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "map3k1", filterType: "BestCase", aaLeft: "878", aaRight: "902", ntLeft: "2632", ntRight: "2706" },
  { key: "oprm1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ptpn9", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "nes", filterType: "FailedAllFilters", aaLeft: "947", aaRight: "971", ntLeft: "2839", ntRight: "2913" },
  { key: "trim47", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "avl9", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "pitx3", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "znf761", filterType: "FailedAllFilters", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "ebf1", filterType: "SecondBest", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "tnks2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dmtf1", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "lnp1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "dnaja2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "gbgt1", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "dzip1l", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ltbp1", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "lrrc70", filterType: "FailedAllFilters", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "or10h4", filterType: "FailedAllFilters", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "klrc2", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "wnk2", filterType: "BestCase", aaLeft: "1714", aaRight: "1738", ntLeft: "5140", ntRight: "5214" },
  { key: "frg1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "kcnma1", filterType: "BestCase", aaLeft: "730", aaRight: "754", ntLeft: "2188", ntRight: "2262" },
  { key: "or1c1", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "paip2b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "yipf1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "defb125", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "dytn", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ccdc106", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cap1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "selenoi", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "c1orf185", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ptger4", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "mica", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "kmt5a", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "usp54", filterType: "BestCase", aaLeft: "1186", aaRight: "1210", ntLeft: "3556", ntRight: "3630" },
  { key: "ints1", filterType: "BestCase", aaLeft: "1470", aaRight: "1494", ntLeft: "4408", ntRight: "4482" },
  { key: "fbxo36", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "hdac6", filterType: "BestCase", aaLeft: "929", aaRight: "953", ntLeft: "2785", ntRight: "2859" },
  { key: "psme1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "prdm11", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "ggt5", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "pou3f3", filterType: "FailedAllFilters", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "gsto2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "mtrr", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "znf444", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "gxylt2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "tmem185a", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "mpp2", filterType: "BestCase", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "cplx4", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "hipk4", filterType: "BestCase", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "tas2r41", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "l3mbtl2", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "ripply1", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "znf639", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "or13j1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "tubb4a", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "rgs22", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "nup85", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "atp8b1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "chrm4", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "tmem41a", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "lhcgr", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "c6orf47", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "cbr4", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "sgcz", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "znf326", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "ublcp1", filterType: "SecondBest", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "ncapg2", filterType: "FailedAllFilters", aaLeft: "801", aaRight: "825", ntLeft: "2401", ntRight: "2475" },
  { key: "adgrl1", filterType: "BestCase", aaLeft: "730", aaRight: "754", ntLeft: "2188", ntRight: "2262" },
  { key: "or2a4", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "slfn14", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "tie1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mln", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "c16orf74", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "znf311", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "ankrd6", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "tmprss5", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "dusp6", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "rac1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "or2b3", filterType: "ThirdBest", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "rfx8", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "dynlrb1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znf286a", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "cib4", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "sec14l4", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "efcab3", filterType: "FailedAllFilters", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "tnfrsf25", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "cdk19", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "ptx4", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "nsun3", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "znf473", filterType: "FailedAllFilters", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "nol4l", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "ada2", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "slc6a12", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "agmo", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cd22", filterType: "FailedAllFilters", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "ct45a8", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "spice1", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "fosb", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "krtap6-2", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "btf3l4", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "ola1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "usp6nl", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "nisch", filterType: "BestCase", aaLeft: "1012", aaRight: "1036", ntLeft: "3034", ntRight: "3108" },
  { key: "flt3lg", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "rsbn1l", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "jazf1", filterType: "SecondBest", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "pcsk9", filterType: "BestCase", aaLeft: "652", aaRight: "676", ntLeft: "1954", ntRight: "2028" },
  { key: "gins2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "bclaf1", filterType: "BestCase", aaLeft: "768", aaRight: "792", ntLeft: "2302", ntRight: "2376" },
  { key: "acvrl1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "brd3", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "fam161b", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "ahdc1", filterType: "BestCase", aaLeft: "899", aaRight: "923", ntLeft: "2695", ntRight: "2769" },
  { key: "efhb", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "znf232", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "snrpf", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "slc25a46", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "rtl4", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "cdh9", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "ephb2", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "tnip3", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "nme3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "lsm1", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ccar2", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "garin1b", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tex14", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "bub1", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "or6c76", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cyp27c1", filterType: "FailedAllFilters", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "c1qa", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "mogat3", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "cd247", filterType: "SecondBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "znf550", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "ercc6l2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "wdr25", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "tmx1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "elp5", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "foxd1", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "hint1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "krtap13-4", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "disc1", filterType: "FailedAllFilters", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "crb2", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "stxbp1", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "agpat4", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "josd1", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "rack1", filterType: "SecondBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "nxpe2", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "kcnq1", filterType: "BestCase", aaLeft: "602", aaRight: "626", ntLeft: "1804", ntRight: "1878" },
  { key: "plekhh2", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "mzb1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "spata13", filterType: "BestCase", aaLeft: "1221", aaRight: "1245", ntLeft: "3661", ntRight: "3735" },
  { key: "ift22", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "lpa", filterType: "FailedAllFilters", aaLeft: "1743", aaRight: "1767", ntLeft: "5227", ntRight: "5301" },
  { key: "hbe1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "aurkc", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "nwd1", filterType: "BestCase", aaLeft: "1201", aaRight: "1225", ntLeft: "3601", ntRight: "3675" },
  { key: "eif2s2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "rbm24", filterType: "SecondBest", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "neurl1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "mymk", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "slc35f2", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "adcy5", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "tctn3", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "cnot11", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "spata24", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "or2a42", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "prkg1", filterType: "SecondBest", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "ifnar2", filterType: "FailedAllFilters", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "umad1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "pikfyve", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "calr3", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "slc16a14", filterType: "FailedAllFilters", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "arl16", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "trak1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "wdr12", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "znf567", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "sfxn2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ugt1a3", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "kiaa0930", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "ubald1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "h2ac18", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "epha5", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "tmem178a", filterType: "SecondBest", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "or1r1p", filterType: "FailedAllFilters", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "kir3dl3", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "il17c", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "gnas", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "alox12", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "ighmbp2", filterType: "BestCase", aaLeft: "899", aaRight: "923", ntLeft: "2695", ntRight: "2769" },
  { key: "nynrin", filterType: "BestCase", aaLeft: "964", aaRight: "988", ntLeft: "2890", ntRight: "2964" },
  { key: "potee", filterType: "FailedAllFilters", aaLeft: "715", aaRight: "739", ntLeft: "2143", ntRight: "2217" },
  { key: "znf205", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "mageb16", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "gjc3", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "gpat4", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "pml", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ankrd13a", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "hnrnpa1l2", filterType: "FourthBest", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "or2g6", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "nek4", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "ildr2", filterType: "BestCase", aaLeft: "566", aaRight: "590", ntLeft: "1696", ntRight: "1770" },
  { key: "osm", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "gsg1l", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "washc2a", filterType: "BestCase", aaLeft: "993", aaRight: "1017", ntLeft: "2977", ntRight: "3051" },
  { key: "efna5", filterType: "SecondBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "dok4", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "hla-dpb1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "pou4f3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "ropn1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "rps4y2", filterType: "FourthBest", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "mttp", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "ikzf4", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "plppr1", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "znf426", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "fgg", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "cops7b", filterType: "SecondBest", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "itgbl1", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "eri2", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "h1-2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "helb", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "eif4ebp1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "cdkn2a", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "or14j1", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "usp36", filterType: "BestCase", aaLeft: "1047", aaRight: "1071", ntLeft: "3139", ntRight: "3213" },
  { key: "rpl38", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "cfh", filterType: "FailedAllFilters", aaLeft: "1126", aaRight: "1150", ntLeft: "3376", ntRight: "3450" },
  { key: "cct6a", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "psmc3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "phrf1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "amn1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "ceacam20", filterType: "FailedAllFilters", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "fxyd2", filterType: "FourthBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "hax1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "dcaf13", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "sarnp", filterType: "SecondBest", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "csf3", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "marchf3", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "aldh1a3", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "ppil2", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "sepsecs", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "fcrl6", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "arhgap5", filterType: "FailedAllFilters", aaLeft: "1109", aaRight: "1133", ntLeft: "3325", ntRight: "3399" },
  { key: "misp", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "or5b3", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "tas2r14", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cd40", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rfc1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "mthfsd", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "triml2", filterType: "FailedAllFilters", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "ms4a8", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "syap1", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "slc7a11", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "znf280c", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "canx", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "rerg", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "efnb2", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "golgb1", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "ppib", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "nek10", filterType: "BestCase", aaLeft: "859", aaRight: "883", ntLeft: "2575", ntRight: "2649" },
  { key: "smim29", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "timm23", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "nf1", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "cyp21a2", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "taf11", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "vps26a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ccdc68", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "h2bc7", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "zscan5b", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "htra1", filterType: "SecondBest", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "ncstn", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "plcxd3", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ak1", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "iqcb1", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "suox", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "scgb1c1", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fam111b", filterType: "FailedAllFilters", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "dmxl1", filterType: "BestCase", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "itk", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "dnaaf11", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "c2orf74", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "katna1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ptges", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nuak2", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "znf532", filterType: "BestCase", aaLeft: "1229", aaRight: "1253", ntLeft: "3685", ntRight: "3759" },
  { key: "wdr47", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "tmc8", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "zfhx3", filterType: "BestCase", aaLeft: "1888", aaRight: "1912", ntLeft: "5662", ntRight: "5736" },
  { key: "or10w1", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "trim69", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "tceal8", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "rin1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "krt81", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "klhl21", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "spag11a", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "edar", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "gstcd", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "gpr148", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "heatr4", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "kcnj8", filterType: "SecondBest", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "lratd1", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "npy4r", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "agbl2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "ankrd30bl", filterType: "FailedAllFilters", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "slc25a24", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "uggt1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "znf250", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "ccsap", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "snorc", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "cysrt1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "spem3", filterType: "FailedAllFilters", aaLeft: "1056", aaRight: "1080", ntLeft: "3166", ntRight: "3240" },
  { key: "rgs9bp", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "nudt1", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "cyb561d1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "srek1ip1", filterType: "SecondBest", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "arl6ip1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "slc25a13", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "erbb3", filterType: "BestCase", aaLeft: "1069", aaRight: "1093", ntLeft: "3205", ntRight: "3279" },
  { key: "nfs1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gje1", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "znf267", filterType: "FailedAllFilters", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "rybp", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "krtap5-9", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "higd1c", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "thap1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "oas2", filterType: "FailedAllFilters", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "sirt4", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "fgfr1op2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "pcdhb14", filterType: "FailedAllFilters", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "znf778", filterType: "FailedAllFilters", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "dync2h1", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "cdh12", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "or6y1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "lrrc69", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "wdr70", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "flnb", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "rangap1", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "vim", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "zc3h12b", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "egfl6", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "rbm46", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "spata46", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "ifi30", filterType: "FailedAllFilters", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "c10orf120", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "nup62cl", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "shb", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "erich3", filterType: "FailedAllFilters", aaLeft: "649", aaRight: "673", ntLeft: "1945", ntRight: "2019" },
  { key: "pomzp3", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "coq4", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "glrx", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "apom", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "lrrc3", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "dusp13", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "sec24a", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "col28a1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "pde10a", filterType: "BestCase", aaLeft: "903", aaRight: "927", ntLeft: "2707", ntRight: "2781" },
  { key: "rspo2", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "rgp1", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "mars2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "clic1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "gpr180", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "znf787", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "or4q3", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "lrfn5", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "pkhd1", filterType: "BestCase", aaLeft: "1644", aaRight: "1668", ntLeft: "4930", ntRight: "5004" },
  { key: "wbp1", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "lonp1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "sry", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "gad2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "clec2a", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "alg10", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "lce2d", filterType: "FourthBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ep300", filterType: "BestCase", aaLeft: "892", aaRight: "916", ntLeft: "2674", ntRight: "2748" },
  { key: "c7orf33", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "adamtsl2", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "kctd5", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "glyatl2", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "ermap", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "foxo3b", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "dnah8", filterType: "BestCase", aaLeft: "1410", aaRight: "1434", ntLeft: "4228", ntRight: "4302" },
  { key: "flot2", filterType: "SecondBest", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "arhgap45", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ift88", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "tmem100", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "pla2r1", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "ttc28", filterType: "BestCase", aaLeft: "1267", aaRight: "1291", ntLeft: "3799", ntRight: "3873" },
  { key: "tut4", filterType: "BestCase", aaLeft: "1522", aaRight: "1546", ntLeft: "4564", ntRight: "4638" },
  { key: "kiaa1217", filterType: "BestCase", aaLeft: "1897", aaRight: "1921", ntLeft: "5689", ntRight: "5763" },
  { key: "slc1a5", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ccdc97", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "or52e6", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "trpm2", filterType: "BestCase", aaLeft: "1458", aaRight: "1482", ntLeft: "4372", ntRight: "4446" },
  { key: "slco1c1", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "lce1c", filterType: "FourthBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "slc46a2", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "pdik1l", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "atf5", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "kif1b", filterType: "BestCase", aaLeft: "1708", aaRight: "1732", ntLeft: "5122", ntRight: "5196" },
  { key: "ct55", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "or8g1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "acbd3", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "slc13a2", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "rgs6", filterType: "SecondBest", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "ier3", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "c4orf19", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "adal", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "vxn", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "prorp", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "cnrip1", filterType: "SecondBest", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "znf705a", filterType: "FailedAllFilters", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "rprm", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "adat2", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "klhl6", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "rassf8", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "spdye10", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "brsk2", filterType: "BestCase", aaLeft: "702", aaRight: "726", ntLeft: "2104", ntRight: "2178" },
  { key: "nckap5", filterType: "BestCase", aaLeft: "1318", aaRight: "1342", ntLeft: "3952", ntRight: "4026" },
  { key: "sp2", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "hsdl1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "prmt1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "rprd2", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "hoxd9", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "slc51a", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "g3bp1", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "tubgcp6", filterType: "BestCase", aaLeft: "1443", aaRight: "1467", ntLeft: "4327", ntRight: "4401" },
  { key: "cuzd1", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "cox4i1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "tas2r7", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "znf860", filterType: "FailedAllFilters", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "vars1", filterType: "BestCase", aaLeft: "964", aaRight: "988", ntLeft: "2890", ntRight: "2964" },
  { key: "rhbdd2", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "bdnf", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "cacna2d4", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "c3orf62", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "lrrc3b", filterType: "SecondBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "unc5d", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "krtap24-1", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "gcat", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "rbfa", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "nfil3", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "igf2bp2", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "tmem269", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "hmox1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "zmat3", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "lcmt2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "pramef6", filterType: "FailedAllFilters", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "cst7", filterType: "FourthBest", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "mosmo", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "utp6", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "jhy", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "c8orf88", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "mmp15", filterType: "BestCase", aaLeft: "600", aaRight: "624", ntLeft: "1798", ntRight: "1872" },
  { key: "zbed3", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "sema6a", filterType: "BestCase", aaLeft: "969", aaRight: "993", ntLeft: "2905", ntRight: "2979" },
  { key: "bcl2l12", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "atp6v1a", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "pcbp1", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "mospd3", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "wnt9b", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "mfap2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "dnttip1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "stil", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "polr3c", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "abcc6", filterType: "BestCase", aaLeft: "673", aaRight: "697", ntLeft: "2017", ntRight: "2091" },
  { key: "adam28", filterType: "BestCase", aaLeft: "675", aaRight: "699", ntLeft: "2023", ntRight: "2097" },
  { key: "mrpl34", filterType: "FourthBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "pnpla8", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "ulk2", filterType: "BestCase", aaLeft: "753", aaRight: "777", ntLeft: "2257", ntRight: "2331" },
  { key: "lmf2", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "btbd16", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "cables1", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "slc17a9", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "acp1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "prps1", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "mapre1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "fgf2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ren", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "pik3r4", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "gdf11", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "eras", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "fam76a", filterType: "SecondBest", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "zacn", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "ccdc182", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "sh2d6", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "arhgap27", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tcf20", filterType: "BestCase", aaLeft: "1276", aaRight: "1300", ntLeft: "3826", ntRight: "3900" },
  { key: "bmf", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "fbxl2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "slc39a3", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "atp2a1", filterType: "BestCase", aaLeft: "862", aaRight: "886", ntLeft: "2584", ntRight: "2658" },
  { key: "sorbs2", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "tlcd4", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "znf598", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "or8b4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "rps6kl1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "gimap6", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "tmem150c", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "mapkapk2", filterType: "SecondBest", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "fkbp9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "scn9a", filterType: "BestCase", aaLeft: "1059", aaRight: "1083", ntLeft: "3175", ntRight: "3249" },
  { key: "qrfpr", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "aplp2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "gps2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hsd17b8", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "b9d2", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "ep400", filterType: "BestCase", aaLeft: "1824", aaRight: "1848", ntLeft: "5470", ntRight: "5544" },
  { key: "egr1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "hdgfl1", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "minpp1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "pfdn6", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "qser1", filterType: "FailedAllFilters", aaLeft: "1423", aaRight: "1447", ntLeft: "4267", ntRight: "4341" },
  { key: "arsi", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "adck1", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "sec22c", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "tmem126a", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "srd5a2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "rpusd1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "h2bw1", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "rsrp1", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "afap1l1", filterType: "BestCase", aaLeft: "708", aaRight: "732", ntLeft: "2122", ntRight: "2196" },
  { key: "eif2b1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "slc41a2", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "hkdc1", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "mt1e", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "aifm2", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "alx4", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "slc14a2", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "ca5a", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "utp18", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "pdxdc1", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "acss3", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "fhad1", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "nab2", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "siah1", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "ascl4", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "slc6a5", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "cyc1", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "kansl2", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "arap3", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "gdpd4", filterType: "FailedAllFilters", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "mtif3", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "nom1", filterType: "FailedAllFilters", aaLeft: "821", aaRight: "845", ntLeft: "2461", ntRight: "2535" },
  { key: "fancg", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "trmt10a", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "atg4d", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "clps", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "rims2", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "espnl", filterType: "BestCase", aaLeft: "752", aaRight: "776", ntLeft: "2254", ntRight: "2328" },
  { key: "sufu", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "arhgap10", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "fbxo5", filterType: "FailedAllFilters", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "sprn", filterType: "FourthBest", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "pde5a", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "scamp5", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "sema3d", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "umps", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "mc1r", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fer", filterType: "BestCase", aaLeft: "798", aaRight: "822", ntLeft: "2392", ntRight: "2466" },
  { key: "zfp36", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "igfbp6", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "pgf", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "abcf3", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "rad51d", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "creld1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fasn", filterType: "BestCase", aaLeft: "1802", aaRight: "1826", ntLeft: "5404", ntRight: "5478" },
  { key: "hoga1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "dixdc1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "rdm1", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "myo5a", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "pga4", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "or4c12", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "cdsn", filterType: "FailedAllFilters", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "mast4", filterType: "BestCase", aaLeft: "1710", aaRight: "1734", ntLeft: "5128", ntRight: "5202" },
  { key: "defa1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "aunip", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "atp5mg", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "abhd12b", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "krtap23-1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cgb8", filterType: "FourthBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "pcyt1b", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "inpp5d", filterType: "BestCase", aaLeft: "955", aaRight: "979", ntLeft: "2863", ntRight: "2937" },
  { key: "usp6", filterType: "BestCase", aaLeft: "1124", aaRight: "1148", ntLeft: "3370", ntRight: "3444" },
  { key: "baat", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "asxl2", filterType: "BestCase", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "katnip", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "prrt1b", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "rdh16", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "rap1gap2", filterType: "BestCase", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "nutm2b", filterType: "FailedAllFilters", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "st8sia3", filterType: "SecondBest", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "sox7", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "prok2", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "commd3", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "sertad4", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "fzd7", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "rab6b", filterType: "SecondBest", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "cenatac", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "dtl", filterType: "BestCase", aaLeft: "416", aaRight: "440", ntLeft: "1246", ntRight: "1320" },
  { key: "itpkc", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "oasl", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "sfr1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "prdx5", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "znf835", filterType: "FailedAllFilters", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "fam214b", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "supt4h1", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "tlk1", filterType: "BestCase", aaLeft: "738", aaRight: "762", ntLeft: "2212", ntRight: "2286" },
  { key: "acad8", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "pex5", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "atxn3l", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "mppe1", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "ptpre", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "h3-3a", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "hexd", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "arg1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "krtap19-3", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "top3b", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "srpx", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "or4d9", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "rps6kb2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "fam90a22", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "u2af2", filterType: "SecondBest", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "mmaa", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "mrps18b", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "myo16", filterType: "BestCase", aaLeft: "1671", aaRight: "1695", ntLeft: "5011", ntRight: "5085" },
  { key: "lrrc17", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "arhgef11", filterType: "BestCase", aaLeft: "1281", aaRight: "1305", ntLeft: "3841", ntRight: "3915" },
  { key: "tmem181", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "rbm38", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "bet1l", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "map4k4", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "fam78b", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "guca1c", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "col8a1", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "alpk2", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "tmlhe", filterType: "FailedAllFilters", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "ndufs6", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "kcnn1", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "rabggta", filterType: "BestCase", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "parvb", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "otoa", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "cep55", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "rab12", filterType: "SecondBest", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "cluh", filterType: "BestCase", aaLeft: "924", aaRight: "948", ntLeft: "2770", ntRight: "2844" },
  { key: "znf219", filterType: "BestCase", aaLeft: "688", aaRight: "712", ntLeft: "2062", ntRight: "2136" },
  { key: "c11orf24", filterType: "FailedAllFilters", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "tnni1", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "lrrk2", filterType: "BestCase", aaLeft: "2341", aaRight: "2365", ntLeft: "7021", ntRight: "7095" },
  { key: "jade3", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "ano6", filterType: "BestCase", aaLeft: "749", aaRight: "773", ntLeft: "2245", ntRight: "2319" },
  { key: "casp5", filterType: "FailedAllFilters", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "tbcb", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "prss56", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "or6c4", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "ucn3", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "armh2", filterType: "FailedAllFilters", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "znf706", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "gpr101", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "srms", filterType: "FailedAllFilters", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "pfn3", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "pphln1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "trim17", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "trat1", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "fam131a", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "ell2", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "agxt2", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "atxn7l1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "ctsg", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "calm3", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ism2", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "tmco1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "homer3", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "rab25", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "gja8", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "rap2a", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "mgst2", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "rplp1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "arhgef26", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "or1f1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tsg101", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "adar", filterType: "BestCase", aaLeft: "697", aaRight: "721", ntLeft: "2089", ntRight: "2163" },
  { key: "siglec9", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "slk", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "pak6", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "dmbt1", filterType: "BestCase", aaLeft: "2275", aaRight: "2299", ntLeft: "6823", ntRight: "6897" },
  { key: "rps6ka5", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "rtp3", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "klrf2", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "siglec16", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "tuba3d", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "mbnl1", filterType: "SecondBest", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "lrrc20", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "smyd4", filterType: "FailedAllFilters", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "med21", filterType: "SecondBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "gnb4", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "ddc", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "pdcd11", filterType: "BestCase", aaLeft: "1254", aaRight: "1278", ntLeft: "3760", ntRight: "3834" },
  { key: "pygm", filterType: "BestCase", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "rpl13a", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "lax1", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "ttc30a", filterType: "FailedAllFilters", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "enosf1", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "tcf7l2", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "ppip5k2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "srp68", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "epb41l5", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "scgb2a1", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tom1l1", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "coq10b", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "plxdc1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "npat", filterType: "BestCase", aaLeft: "955", aaRight: "979", ntLeft: "2863", ntRight: "2937" },
  { key: "n4bp1", filterType: "BestCase", aaLeft: "831", aaRight: "855", ntLeft: "2491", ntRight: "2565" },
  { key: "hps6", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "ptn", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "mpi", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "pdgfd", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "dda1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "krtap3-1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "gage2a", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ddx21", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "trmt12", filterType: "FailedAllFilters", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "sycp2", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "spaca9", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "dram2", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "rhbdl3", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "tgfb1", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "mvb12a", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "cfap54", filterType: "FailedAllFilters", aaLeft: "2494", aaRight: "2518", ntLeft: "7480", ntRight: "7554" },
  { key: "ppial4d", filterType: "FourthBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "eif3a", filterType: "BestCase", aaLeft: "989", aaRight: "1013", ntLeft: "2965", ntRight: "3039" },
  { key: "hs3st5", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "slc9a1", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "lrp2", filterType: "BestCase", aaLeft: "1311", aaRight: "1335", ntLeft: "3931", ntRight: "4005" },
  { key: "sdf4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "c5orf46", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "usp48", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "rab8a", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "ankrd33b", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "pknox2", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "igflr1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "il23a", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "cacna1b", filterType: "BestCase", aaLeft: "1064", aaRight: "1088", ntLeft: "3190", ntRight: "3264" },
  { key: "msn", filterType: "SecondBest", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "scyl2", filterType: "BestCase", aaLeft: "795", aaRight: "819", ntLeft: "2383", ntRight: "2457" },
  { key: "nckipsd", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "gapt", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "b3galt9", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "kiaa2026", filterType: "BestCase", aaLeft: "1834", aaRight: "1858", ntLeft: "5500", ntRight: "5574" },
  { key: "otc", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "mat1a", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "haus8", filterType: "FailedAllFilters", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "pnma8a", filterType: "FailedAllFilters", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "macrod1", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "pcdha7", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "card19", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "med16", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "znf625", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "acox3", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "lrrc8d", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "vstm2l", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "tbxas1", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "h4c13", filterType: "SecondBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "stam", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "pcnp", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "rnf187", filterType: "SecondBest", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tle2", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "map3k4", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "kdm6a", filterType: "BestCase", aaLeft: "773", aaRight: "797", ntLeft: "2317", ntRight: "2391" },
  { key: "pabpc1l2b", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "trim42", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "tbata", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "bmx", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "ccdc92b", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "znf764", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "fscn2", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "sugt1", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "thoc5", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "als2", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "hspa13", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "exd1", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "npb", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cpa6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kcnh3", filterType: "BestCase", aaLeft: "907", aaRight: "931", ntLeft: "2719", ntRight: "2793" },
  { key: "nfe2l3", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "spdyc", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "bcl3", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "fbxl16", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "ldhc", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "rhou", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "alkal2", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ccdc43", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "slfnl1", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "yipf4", filterType: "SecondBest", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "colec11", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "smad9", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "vldlr", filterType: "BestCase", aaLeft: "735", aaRight: "759", ntLeft: "2203", ntRight: "2277" },
  { key: "hdac11", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "ccdc103", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "castor1", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "pan3", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "nmi", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ptger1", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "rpp40", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "ebf4", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "ltbp4", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "spink5", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "vps11", filterType: "BestCase", aaLeft: "565", aaRight: "589", ntLeft: "1693", ntRight: "1767" },
  { key: "lpxn", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "zmynd19", filterType: "SecondBest", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "sfta2", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "taar8", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "or10h1", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "tmem53", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "dll1", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "or8i2", filterType: "FailedAllFilters", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "sntg1", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "c2orf68", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "lrrc42", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "tmem64", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "tinagl1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "kcnmb3", filterType: "FailedAllFilters", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "golga8b", filterType: "FailedAllFilters", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "trpc1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "mrgpre", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "pigz", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "htr3e", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "ccdc134", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "pf4", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "dpy19l3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "spast", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "otop1", filterType: "FailedAllFilters", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "cpb1", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "vcy", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "kcnk4", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "ndufv3", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "rln2", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "fads1", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "c11orf71", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "slc25a38", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "magea9b", filterType: "FailedAllFilters", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "or6f1", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "trim75", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "yme1l1", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "gpx4", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "ctdp1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "tox2", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "vnn1", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "frmd7", filterType: "BestCase", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "grb2", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "spc25", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "optc", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "gtf3c5", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ddx43", filterType: "FailedAllFilters", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "znf883", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "gphb5", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ndor1", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "cfap36", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "znf48", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "grtp1", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "sdc1", filterType: "SecondBest", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "rab33a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "proc", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "dctn2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "hsfx4", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "slc26a4", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "donson", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "clrn3", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "rpl13", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "znf112", filterType: "FailedAllFilters", aaLeft: "794", aaRight: "818", ntLeft: "2380", ntRight: "2454" },
  { key: "ppard", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "psma8", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "prl", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cdc14a", filterType: "BestCase", aaLeft: "550", aaRight: "574", ntLeft: "1648", ntRight: "1722" },
  { key: "rcc2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "mvk", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "atp6v0d2", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "mien1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "smim35", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "psd3", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "arntl2", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "rps29", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kctd10", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "glis1", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "eef1a2", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "slc2a12", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "sumf2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "il21", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "irf2bp2", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "apoc1", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "defb103a", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "znf670", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "cyp24a1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "krtap4-1", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "or7d2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "izumo2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "catsper3", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "zbtb32", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "cxcl14", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "capn2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "vrk2", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "cuta", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "p2rx6", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "tvp23b", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "zdhhc19", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "chpf", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "anhx", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "marchf11", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "nodal", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "psg4", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "wnt7a", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "ears2", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "znf358", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "il16", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "c2cd3", filterType: "BestCase", aaLeft: "995", aaRight: "1019", ntLeft: "2983", ntRight: "3057" },
  { key: "echdc2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ahcyl1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fbxo48", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "lpar4", filterType: "FailedAllFilters", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "rasa2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rpl24", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "sos1", filterType: "BestCase", aaLeft: "1185", aaRight: "1209", ntLeft: "3553", ntRight: "3627" },
  { key: "vgll2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "ctdsp1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "sdr9c7", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "prrg4", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "stpg3", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "pitpnb", filterType: "SecondBest", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "fbxw10", filterType: "BestCase", aaLeft: "439", aaRight: "463", ntLeft: "1315", ntRight: "1389" },
  { key: "nelfa", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "c1qtnf3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "crp", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "yes1", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "nlrp5", filterType: "FailedAllFilters", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "gpha2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "c5orf24", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "slit1", filterType: "BestCase", aaLeft: "971", aaRight: "995", ntLeft: "2911", ntRight: "2985" },
  { key: "tbl3", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "ecpas", filterType: "BestCase", aaLeft: "1664", aaRight: "1688", ntLeft: "4990", ntRight: "5064" },
  { key: "washc5", filterType: "BestCase", aaLeft: "887", aaRight: "911", ntLeft: "2659", ntRight: "2733" },
  { key: "c22orf23", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "gfra4", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "fto", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "c3orf49", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "fam234a", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "galm", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "mrpl28", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "myo1h", filterType: "BestCase", aaLeft: "821", aaRight: "845", ntLeft: "2461", ntRight: "2535" },
  { key: "hnrnpf", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "tmem242", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "irag2", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "cnnm4", filterType: "BestCase", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "colgalt2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "myl9", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "park7", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "serhl2", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "atoh7", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "lipj", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "nr5a1", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "rcan1", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "wwc3", filterType: "BestCase", aaLeft: "960", aaRight: "984", ntLeft: "2878", ntRight: "2952" },
  { key: "clcn2", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "tor3a", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "plk1", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "fkbp1b", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "mstn", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "vwa5b2", filterType: "BestCase", aaLeft: "763", aaRight: "787", ntLeft: "2287", ntRight: "2361" },
  { key: "mtfr1", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "znf80", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "sgcd", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tnk2", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "ssna1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "mrps12", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "fbf1", filterType: "BestCase", aaLeft: "584", aaRight: "608", ntLeft: "1750", ntRight: "1824" },
  { key: "shprh", filterType: "BestCase", aaLeft: "632", aaRight: "656", ntLeft: "1894", ntRight: "1968" },
  { key: "dcp2", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "srpra", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "stk16", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "ceacam4", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tpcn1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "thumpd1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "cnih1", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "btn3a1", filterType: "FailedAllFilters", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "mfsd10", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "gabbr2", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "mrps25", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "tlr3", filterType: "FailedAllFilters", aaLeft: "586", aaRight: "610", ntLeft: "1756", ntRight: "1830" },
  { key: "stag2", filterType: "BestCase", aaLeft: "985", aaRight: "1009", ntLeft: "2953", ntRight: "3027" },
  { key: "cyth4", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "c10orf67", filterType: "FailedAllFilters", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "scart1", filterType: "FailedAllFilters", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "parp14", filterType: "FailedAllFilters", aaLeft: "1377", aaRight: "1401", ntLeft: "4129", ntRight: "4203" },
  { key: "theg", filterType: "FailedAllFilters", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "kcnj15", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "kiaa1328", filterType: "FailedAllFilters", aaLeft: "529", aaRight: "553", ntLeft: "1585", ntRight: "1659" },
  { key: "slco5a1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "tmem275", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "glt1d1", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "zscan22", filterType: "FailedAllFilters", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "or51a4", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "or2j2", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "sspn", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "cby2", filterType: "FailedAllFilters", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "e2f3", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "dennd6b", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "c1orf216", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "adgrg7", filterType: "FailedAllFilters", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "aco1", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "cenpe", filterType: "FailedAllFilters", aaLeft: "2621", aaRight: "2645", ntLeft: "7861", ntRight: "7935" },
  { key: "krtap21-2", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "polr1h", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ccs", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "vti1a", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "hjurp", filterType: "FailedAllFilters", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "shisal2b", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "pmepa1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "pgam1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "lingo2", filterType: "SecondBest", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "spr", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "cyp4f12", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "patl1", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "vsx2", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "erf", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "got1", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "azu1", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "b3gnt4", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ttc34", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "pak1ip1", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "slc43a1", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "rab3il1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "flad1", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "bag6", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "dffb", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "slc16a1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "rpsa", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "zdhhc3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "vhll", filterType: "FourthBest", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "nlgn3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "btd", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "rcsd1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "serpina1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "ift122", filterType: "BestCase", aaLeft: "812", aaRight: "836", ntLeft: "2434", ntRight: "2508" },
  { key: "ube2c", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "trmt1l", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "nphp4", filterType: "BestCase", aaLeft: "722", aaRight: "746", ntLeft: "2164", ntRight: "2238" },
  { key: "gabra3", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "psors1c1", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "depdc1b", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "cul1", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "ggct", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "glra3", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "adra2b", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "kif20b", filterType: "BestCase", aaLeft: "643", aaRight: "667", ntLeft: "1927", ntRight: "2001" },
  { key: "calhm6", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "u2surp", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "leap2", filterType: "SecondBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "pcdh7", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hcst", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "itm2a", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "dsc3", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "taf2", filterType: "BestCase", aaLeft: "1015", aaRight: "1039", ntLeft: "3043", ntRight: "3117" },
  { key: "mrgbp", filterType: "SecondBest", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "rbp2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ca8", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf284", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "ackr2", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "rbbp5", filterType: "SecondBest", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "tpgs1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "garin4", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "serpinb6", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "icam5", filterType: "BestCase", aaLeft: "631", aaRight: "655", ntLeft: "1891", ntRight: "1965" },
  { key: "tada3", filterType: "SecondBest", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "dnai4", filterType: "FailedAllFilters", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "ppm1b", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "tmem106b", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "gpr63", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "wdr93", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "mnd1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "dbr1", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "ntrk3", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "fgf11", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "pfkfb2", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "gli1", filterType: "BestCase", aaLeft: "755", aaRight: "779", ntLeft: "2263", ntRight: "2337" },
  { key: "znf829", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "gsta1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "slc22a16", filterType: "FailedAllFilters", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "ankrd50", filterType: "FailedAllFilters", aaLeft: "1308", aaRight: "1332", ntLeft: "3922", ntRight: "3996" },
  { key: "pip4k2a", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "dnajc16", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "sec16a", filterType: "FailedAllFilters", aaLeft: "1401", aaRight: "1425", ntLeft: "4201", ntRight: "4275" },
  { key: "paaf1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "pcdhgb1", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "or51d1", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "uqcc3", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "brca1", filterType: "FailedAllFilters", aaLeft: "1659", aaRight: "1683", ntLeft: "4975", ntRight: "5049" },
  { key: "tmem220", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "adgrb2", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "ptgr2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "septin14", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "drg2", filterType: "SecondBest", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "cbln1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "adamts17", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "tbx5", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "serac1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "bod1l2", filterType: "FourthBest", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ppy", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "pcdh11x", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "cldn2", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "arl5a", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cox7c", filterType: "FourthBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "drd5", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "srsf2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "slmap", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "ptpdc1", filterType: "BestCase", aaLeft: "780", aaRight: "804", ntLeft: "2338", ntRight: "2412" },
  { key: "cc2d1b", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "ehbp1l1", filterType: "BestCase", aaLeft: "1166", aaRight: "1190", ntLeft: "3496", ntRight: "3570" },
  { key: "adamts20", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "f11r", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "antkmt", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "igsf9", filterType: "BestCase", aaLeft: "975", aaRight: "999", ntLeft: "2923", ntRight: "2997" },
  { key: "slco2a1", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "nop58", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "bdkrb2", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "hira", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "setx", filterType: "BestCase", aaLeft: "1860", aaRight: "1884", ntLeft: "5578", ntRight: "5652" },
  { key: "trex2", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "cpe", filterType: "FailedAllFilters", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "tmem217", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "matn1", filterType: "BestCase", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "nkapl", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "lyrm4", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "snrk", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "spz1", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "dnajc21", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "csn2", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "kank2", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "tpd52l2", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "ppp1r3g", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "pcdhga6", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "lcn12", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "trim7", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "gigyf1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "or56a4", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "serinc2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "or5j2", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "arpin", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "glt6d1", filterType: "FailedAllFilters", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "setd2", filterType: "BestCase", aaLeft: "1936", aaRight: "1960", ntLeft: "5806", ntRight: "5880" },
  { key: "gatc", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "dscc1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "znf490", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "zic4", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "slc44a1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "samd7", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ube4a", filterType: "BestCase", aaLeft: "693", aaRight: "717", ntLeft: "2077", ntRight: "2151" },
  { key: "glce", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "tef", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "proser1", filterType: "FailedAllFilters", aaLeft: "873", aaRight: "897", ntLeft: "2617", ntRight: "2691" },
  { key: "nxf2b", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "tamm41", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "cabp4", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "xxylt1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "slc11a1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "nupr1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "gabrg1", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "sgo2", filterType: "FailedAllFilters", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "tpr", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "ripk2", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "naa20", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "ift140", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "sult1a1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "duox1", filterType: "BestCase", aaLeft: "968", aaRight: "992", ntLeft: "2902", ntRight: "2976" },
  { key: "arhgef3", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "tnfsf11", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "kpna5", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "plekhf1", filterType: "FailedAllFilters", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "scd", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "ndufb5", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dnal1", filterType: "SecondBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "fam180b", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "hadh", filterType: "FourthBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "nudt10", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "foxj2", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "irf2bpl", filterType: "FailedAllFilters", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "mettl27", filterType: "FourthBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ylpm1", filterType: "BestCase", aaLeft: "679", aaRight: "703", ntLeft: "2035", ntRight: "2109" },
  { key: "traf5", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "pnrc2", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "xpc", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "cisd2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "pdss2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "znf584", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "gabrp", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "popdc2", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "tnfrsf6b", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "acsl3", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "atraid", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "mocos", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "vpreb3", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "baalc", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "etnppl", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ndufa2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "celf5", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "tff2", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "galnt8", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "chic1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "c17orf49", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "hilpda", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "lrch4", filterType: "BestCase", aaLeft: "656", aaRight: "680", ntLeft: "1966", ntRight: "2040" },
  { key: "tbc1d3e", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "pank1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "mid1", filterType: "BestCase", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "c4b", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "pex5l", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "dnaaf6", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "c4orf50", filterType: "FailedAllFilters", aaLeft: "1447", aaRight: "1471", ntLeft: "4339", ntRight: "4413" },
  { key: "kantr", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mcam", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "ppp1r14d", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "krtap9-6", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "dclre1c", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "nr3c2", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "krt37", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "tmem176b", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "enpp2", filterType: "BestCase", aaLeft: "635", aaRight: "659", ntLeft: "1903", ntRight: "1977" },
  { key: "frs2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "nnmt", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "kifap3", filterType: "BestCase", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "zcwpw2", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "clec14a", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "fut1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "rnf10", filterType: "BestCase", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "lgals9b", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "or6p1", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "mcrip1", filterType: "SecondBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "cldn11", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "znf595", filterType: "FailedAllFilters", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "rhof", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cfc1", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "tnrc6b", filterType: "BestCase", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "fkbp4", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "otud4", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "oaz2", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "fam221a", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "npm1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "p4ha1", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "tas1r1", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "txnrd1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "krt26", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "anapc1", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "lbh", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "dmrtb1", filterType: "FourthBest", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "arsd", filterType: "FailedAllFilters", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "nxn", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "mt3", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "acvr1", filterType: "SecondBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "anapc15", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mt1h", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "dzip1", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "psapl1", filterType: "FailedAllFilters", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "pals1", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "ces4a", filterType: "FailedAllFilters", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "pax3", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "serpinf2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "bsg", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "phf21a", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "golga8q", filterType: "FailedAllFilters", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "brk1", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "utp15", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "tbcel", filterType: "SecondBest", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "mef2a", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "tdg", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "fmo5", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "ppp1r16b", filterType: "BestCase", aaLeft: "416", aaRight: "440", ntLeft: "1246", ntRight: "1320" },
  { key: "agtr2", filterType: "FailedAllFilters", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "slc6a8", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "trpv6", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "il4i1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "best4", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "schip1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "smcp", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "dnhd1", filterType: "BestCase", aaLeft: "4679", aaRight: "4703", ntLeft: "14035", ntRight: "14109" },
  { key: "kcnab1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "smtnl1", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "sbno2", filterType: "BestCase", aaLeft: "722", aaRight: "746", ntLeft: "2164", ntRight: "2238" },
  { key: "fam118b", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "mark1", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "rnf212b", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "cops3", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "tbc1d10c", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "abca13", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "fbxo8", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "chchd7", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "gpr27", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "pik3cd", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "loc102723553", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "taf1b", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "dnajb13", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "tmem158", filterType: "SecondBest", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "erap1", filterType: "BestCase", aaLeft: "788", aaRight: "812", ntLeft: "2362", ntRight: "2436" },
  { key: "syt14", filterType: "BestCase", aaLeft: "550", aaRight: "574", ntLeft: "1648", ntRight: "1722" },
  { key: "nfkbie", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ppp2cb", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "s1pr5", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "krba1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "pdxp", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ero1a", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "mus81", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "dennd1a", filterType: "BestCase", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "c16orf86", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "insc", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cd300a", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "csmd3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gria1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "plcg1", filterType: "BestCase", aaLeft: "1067", aaRight: "1091", ntLeft: "3199", ntRight: "3273" },
  { key: "or5k3", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "ube2l6", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "cgb5", filterType: "FourthBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "hoxb6", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "shc3", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "atp5mj", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "prkab1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "thbd", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "nkx1-1", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "dnajc30", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "hmg20b", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "parg", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "asic1", filterType: "SecondBest", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "sfrp4", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "tmem38a", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "ppp1r1a", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "esco1", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "mpdu1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "tp73", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "nadk2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "f8a2", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "hoxa1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "cntrob", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "tmprss11e", filterType: "FailedAllFilters", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "pkdcc", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "stambp", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "gclc", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "rnf169", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "nqo2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "emc8", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "slc10a4", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "sec61b", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "ciao3", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "rep15", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "h2bc10", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "hrct1", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ptprh", filterType: "FailedAllFilters", aaLeft: "988", aaRight: "1012", ntLeft: "2962", ntRight: "3036" },
  { key: "slc45a4", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "spata31e1", filterType: "FailedAllFilters", aaLeft: "790", aaRight: "814", ntLeft: "2368", ntRight: "2442" },
  { key: "hoxc12", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "nxf3", filterType: "FailedAllFilters", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "tmem231", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "slc2a3", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "tenm1", filterType: "BestCase", aaLeft: "2629", aaRight: "2653", ntLeft: "7885", ntRight: "7959" },
  { key: "fam43b", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "gkn2", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "bmp5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nrcam", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "coq9", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "mad2l1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "qtrt2", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "mfhas1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "mr1", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "eif6", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "pacrg", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "mfsd3", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "akap11", filterType: "FailedAllFilters", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "paqr3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gcc2", filterType: "BestCase", aaLeft: "988", aaRight: "1012", ntLeft: "2962", ntRight: "3036" },
  { key: "page3", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "kiaa0753", filterType: "BestCase", aaLeft: "872", aaRight: "896", ntLeft: "2614", ntRight: "2688" },
  { key: "anxa7", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "hpgds", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "wdr82", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "clec2l", filterType: "SecondBest", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "obp2b", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "tspyl4", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "otor", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "hsd17b14", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "smug1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "kctd8", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "asb14", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "sgip1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "tnfrsf1a", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "dnah5", filterType: "BestCase", aaLeft: "3048", aaRight: "3072", ntLeft: "9142", ntRight: "9216" },
  { key: "kcnk10", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "col4a3", filterType: "BestCase", aaLeft: "1264", aaRight: "1288", ntLeft: "3790", ntRight: "3864" },
  { key: "ttc12", filterType: "BestCase", aaLeft: "637", aaRight: "661", ntLeft: "1909", ntRight: "1983" },
  { key: "tusc2", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "scai", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "arhgdig", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "nalf2", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "gdf7", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "c1orf159", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "pih1d2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "plekha2", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "rhot1", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "efs", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "fam187a", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "gpr45", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ptms", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "coro7", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "foxm1", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "hmgn5", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "celsr2", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "luc7l3", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "stim1", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "layn", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "crlf3", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "adamts6", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "opn5", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "ptch1", filterType: "BestCase", aaLeft: "1339", aaRight: "1363", ntLeft: "4015", ntRight: "4089" },
  { key: "ube3b", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "tp53aip1", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "glod5", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "krt73", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ecsit", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "atp5mgl", filterType: "FourthBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "uri1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "zpbp", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "pus7", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "armc9", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "raet1l", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "rprml", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "fhdc1", filterType: "SecondBest", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "dusp29", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "rassf5", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "fam168b", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "lrriq3", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "hoxd4", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "skil", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "dnmbp", filterType: "BestCase", aaLeft: "1171", aaRight: "1195", ntLeft: "3511", ntRight: "3585" },
  { key: "foxd4l4", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "or5m1", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "apobec3a", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "upf3a", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "slitrk5", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "plekhg4b", filterType: "FailedAllFilters", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "txlng", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "or4n4c", filterType: "FailedAllFilters", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "hdlbp", filterType: "BestCase", aaLeft: "932", aaRight: "956", ntLeft: "2794", ntRight: "2868" },
  { key: "ryr2", filterType: "BestCase", aaLeft: "1769", aaRight: "1793", ntLeft: "5305", ntRight: "5379" },
  { key: "mrps34", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "luc7l", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "or2t10", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "card6", filterType: "FailedAllFilters", aaLeft: "792", aaRight: "816", ntLeft: "2374", ntRight: "2448" },
  { key: "fhit", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "nkx6-1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "pdcd7", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "galntl6", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "mpz", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "ulbp2", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "syt3", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "adam12", filterType: "BestCase", aaLeft: "818", aaRight: "842", ntLeft: "2452", ntRight: "2526" },
  { key: "spaca5b", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "armcx5", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "hpf1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "ccnh", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cphxl", filterType: "FailedAllFilters", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "cbx3", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "pros1", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "mrgprx3", filterType: "FourthBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tmem253", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "wls", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ptpn11", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "s100a2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "alyref", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "prrt1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "iqank1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "stmp1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ang", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "pcnx2", filterType: "BestCase", aaLeft: "1752", aaRight: "1776", ntLeft: "5254", ntRight: "5328" },
  { key: "adgre1", filterType: "BestCase", aaLeft: "700", aaRight: "724", ntLeft: "2098", ntRight: "2172" },
  { key: "tcim", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "vipr2", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "rad54l", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "nr2f2", filterType: "SecondBest", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "brox", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "mrpl39", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "il23r", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "hgf", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "plcb4", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "sub1", filterType: "SecondBest", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "dnpep", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "bin2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "slc5a3", filterType: "BestCase", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "sin3a", filterType: "BestCase", aaLeft: "843", aaRight: "867", ntLeft: "2527", ntRight: "2601" },
  { key: "pnpla5", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "hoxd12", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "otp", filterType: "SecondBest", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "il6", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "qsox1", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "crtap", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "nostrin", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "mxra7", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "emx1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "st6galnac6", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "or2t27", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "cnn1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "hcn2", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "cmbl", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "smg9", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "asl", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "slc17a4", filterType: "FailedAllFilters", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "ankrd23", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "txndc15", filterType: "SecondBest", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "mfn2", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "grsf1", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "samd10", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "smim13", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "zfp91", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "frem3", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "mif", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "edc4", filterType: "BestCase", aaLeft: "800", aaRight: "824", ntLeft: "2398", ntRight: "2472" },
  { key: "nek9", filterType: "BestCase", aaLeft: "859", aaRight: "883", ntLeft: "2575", ntRight: "2649" },
  { key: "pram1", filterType: "FailedAllFilters", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "scg5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "mtmr3", filterType: "BestCase", aaLeft: "865", aaRight: "889", ntLeft: "2593", ntRight: "2667" },
  { key: "st3gal2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "camk4", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "krtap10-2", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "snapc4", filterType: "FailedAllFilters", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "epn2", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "zbtb14", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "ccng1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "eif4b", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "acap3", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "bzw2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tanc2", filterType: "BestCase", aaLeft: "1517", aaRight: "1541", ntLeft: "4549", ntRight: "4623" },
  { key: "dph3", filterType: "SecondBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nsa2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "sox13", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "gfod2", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "ythdc1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "znf134", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "ankrd34a", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "rpl35", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "atat1", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "senp7", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "spryd4", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "il22ra2", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "magea10", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "lyg1", filterType: "FailedAllFilters", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "unc13d", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "ifnl3", filterType: "FourthBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "nrtn", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "or11h4", filterType: "FailedAllFilters", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "rad17", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "arhgap8", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "ddx52", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "swsap1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "tas2r19", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "mcoln2", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "isg20", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "gbp2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ms4a5", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "cylc2", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tyro3", filterType: "BestCase", aaLeft: "824", aaRight: "848", ntLeft: "2470", ntRight: "2544" },
  { key: "or5m10", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "tril", filterType: "BestCase", aaLeft: "507", aaRight: "531", ntLeft: "1519", ntRight: "1593" },
  { key: "fam124a", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "myzap", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "per2", filterType: "BestCase", aaLeft: "556", aaRight: "580", ntLeft: "1666", ntRight: "1740" },
  { key: "pdrg1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "map2k1", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "ajap1", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "mkrn3", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "cpxm1", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "smim24", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rrs1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "abo", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ddah2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "cshl1", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "trappc10", filterType: "BestCase", aaLeft: "1192", aaRight: "1216", ntLeft: "3574", ntRight: "3648" },
  { key: "or1a2", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "ccdc65", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "vps13b", filterType: "BestCase", aaLeft: "2053", aaRight: "2077", ntLeft: "6157", ntRight: "6231" },
  { key: "ets2", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "ccdc125", filterType: "FailedAllFilters", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "nod1", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "atg101", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tcerg1", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "mybphl", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "dnajc1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "lrrc53", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "xirp2", filterType: "FailedAllFilters", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "lancl3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "pdp1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "prss12", filterType: "BestCase", aaLeft: "597", aaRight: "621", ntLeft: "1789", ntRight: "1863" },
  { key: "bend5", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "elk1", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "tmc5", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "trim64", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "tceal5", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "uap1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ldaf1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cipc", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "pdcd1lg2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "folr3", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "dkk2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cfap221", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "heatr9", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "pyroxd2", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "kcnj5", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "sbf1", filterType: "BestCase", aaLeft: "1316", aaRight: "1340", ntLeft: "3946", ntRight: "4020" },
  { key: "ank2", filterType: "BestCase", aaLeft: "3632", aaRight: "3656", ntLeft: "10894", ntRight: "10968" },
  { key: "slc25a29", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "fosl2", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "lhb", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "dhdh", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "dvl2", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "plet1", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "b4galt1", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "ccser1", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "gfm2", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "rara", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "krtap5-4", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "nap1l5", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "prxl2b", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "a3galt2", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "impdh2", filterType: "SecondBest", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "sdhd", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "atxn1", filterType: "FailedAllFilters", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "phf11", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "c1orf53", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ncr3", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "mab21l1", filterType: "SecondBest", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "znf775", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "akt1", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "cyp1b1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "phf3", filterType: "BestCase", aaLeft: "1757", aaRight: "1781", ntLeft: "5269", ntRight: "5343" },
  { key: "pth1r", filterType: "BestCase", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "tmem42", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "chst3", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "zan", filterType: "BestCase", aaLeft: "1689", aaRight: "1713", ntLeft: "5065", ntRight: "5139" },
  { key: "slc30a2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "plaat2", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "txnl1", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "gprc5c", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "pitpnm2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "il36a", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "tdrd9", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "vsig4", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "ccdc112", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "slc27a1", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "sage1", filterType: "FailedAllFilters", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "hsp90aa1", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "mro", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "fcmr", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "clptm1l", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "csnka2ip", filterType: "FailedAllFilters", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "hnrnpu", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "acy1", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "lysmd3", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "fah", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "znf449", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "lmo2", filterType: "SecondBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "zc3hav1", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "onecut1", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "grip2", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "mapk11", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "nup88", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "pgm1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "npvf", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "snx10", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "atg12", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "stx19", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "sele", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "tdrkh", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "scarb1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "or2b11", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "galr1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "glyctk", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "h2ac1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "abra", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "eya1", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "rapgef3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "adm", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "tifab", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "ints15", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "klf2", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "cebpg", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rimbp3c", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "eif2ak4", filterType: "BestCase", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "crkl", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "cul4b", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "edf1", filterType: "SecondBest", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "kptn", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "mettl6", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "defb104b", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "vegfb", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "med30", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "rfx5", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "rgs18", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "aff2", filterType: "BestCase", aaLeft: "934", aaRight: "958", ntLeft: "2800", ntRight: "2874" },
  { key: "kif11", filterType: "BestCase", aaLeft: "1026", aaRight: "1050", ntLeft: "3076", ntRight: "3150" },
  { key: "snx27", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "fbp1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "zbtb41", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "or7c1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "cdk14", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "lats2", filterType: "BestCase", aaLeft: "952", aaRight: "976", ntLeft: "2854", ntRight: "2928" },
  { key: "en2", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "fabp3", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ahi1", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "tppp2", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "pcdha12", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "pcgf2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "dach2", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "ct45a5", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "zp3", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "qars1", filterType: "BestCase", aaLeft: "713", aaRight: "737", ntLeft: "2137", ntRight: "2211" },
  { key: "cfap45", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cenpv", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "adnp2", filterType: "FailedAllFilters", aaLeft: "862", aaRight: "886", ntLeft: "2584", ntRight: "2658" },
  { key: "mblac1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "polg", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "pi4k2b", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "pcsk4", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "hrh1", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "pex16", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "urgcp", filterType: "FailedAllFilters", aaLeft: "819", aaRight: "843", ntLeft: "2455", ntRight: "2529" },
  { key: "garem1", filterType: "BestCase", aaLeft: "668", aaRight: "692", ntLeft: "2002", ntRight: "2076" },
  { key: "asf1a", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "cs", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "rab34", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "pydc5", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "cdh4", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "entpd6", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "rtl9", filterType: "FailedAllFilters", aaLeft: "1107", aaRight: "1131", ntLeft: "3319", ntRight: "3393" },
  { key: "or6a2", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "ess2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tex19", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "crocc", filterType: "BestCase", aaLeft: "1471", aaRight: "1495", ntLeft: "4411", ntRight: "4485" },
  { key: "sf3a1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "l1cam", filterType: "BestCase", aaLeft: "1091", aaRight: "1115", ntLeft: "3271", ntRight: "3345" },
  { key: "niban1", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "dlk2", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "ndufb10", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "fam91a1", filterType: "BestCase", aaLeft: "697", aaRight: "721", ntLeft: "2089", ntRight: "2163" },
  { key: "lrrc31", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "tmem17", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "dcaf4", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "kl", filterType: "BestCase", aaLeft: "711", aaRight: "735", ntLeft: "2131", ntRight: "2205" },
  { key: "tap2", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ccn6", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "ppp2r2c", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "irgc", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "arhgef37", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "tnfaip2", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "pdia5", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "ethe1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "ttll3", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "duxa", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "ankle2", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "tube1", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "notch2nlb", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "tmem190", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "lpl", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "spred2", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "krtap2-4", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "ccdc30", filterType: "FailedAllFilters", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "serp1", filterType: "SecondBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "nbpf1", filterType: "FailedAllFilters", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "adcy8", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "pcmt1", filterType: "SecondBest", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "mmut", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "etv7", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "ctnnb1", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "palb2", filterType: "FailedAllFilters", aaLeft: "1070", aaRight: "1094", ntLeft: "3208", ntRight: "3282" },
  { key: "ccdc170", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "slamf8", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "sf3b6", filterType: "SecondBest", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "spopl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmem74b", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "gtf2e2", filterType: "SecondBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "smox", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "gck", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "mbd3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "tuba1b", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "atl2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "hcfc1r1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "cdk3", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "epha8", filterType: "BestCase", aaLeft: "733", aaRight: "757", ntLeft: "2197", ntRight: "2271" },
  { key: "trim31", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "h2ac15", filterType: "SecondBest", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "patz1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "kdm1b", filterType: "BestCase", aaLeft: "627", aaRight: "651", ntLeft: "1879", ntRight: "1953" },
  { key: "orc2", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "syne2", filterType: "BestCase", aaLeft: "6487", aaRight: "6511", ntLeft: "19459", ntRight: "19533" },
  { key: "slc20a1", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "ubxn7", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "znf717", filterType: "FailedAllFilters", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "cep44", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "aqp6", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "slc37a2", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "scaf8", filterType: "BestCase", aaLeft: "737", aaRight: "761", ntLeft: "2209", ntRight: "2283" },
  { key: "gatd1", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "cfap91", filterType: "BestCase", aaLeft: "620", aaRight: "644", ntLeft: "1858", ntRight: "1932" },
  { key: "msra", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "arid3c", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "foxred2", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "tdrd15", filterType: "FailedAllFilters", aaLeft: "709", aaRight: "733", ntLeft: "2125", ntRight: "2199" },
  { key: "gpatch1", filterType: "BestCase", aaLeft: "649", aaRight: "673", ntLeft: "1945", ntRight: "2019" },
  { key: "llgl2", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "parp3", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "pask", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "pxk", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "hsd3b2", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "ldlrad1", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "ptpmt1", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "wipf1", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "dus3l", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "cactin", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "h6pd", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "sec31a", filterType: "BestCase", aaLeft: "777", aaRight: "801", ntLeft: "2329", ntRight: "2403" },
  { key: "reg1b", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "clcnka", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "cnot6l", filterType: "SecondBest", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "cdcp2", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "klk6", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "mdn1", filterType: "BestCase", aaLeft: "3137", aaRight: "3161", ntLeft: "9409", ntRight: "9483" },
  { key: "capg", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "helq", filterType: "BestCase", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "mag", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "rnf175", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "tmem87a", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "sprr1a", filterType: "FourthBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "or5t3", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "fam13a", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "mrpl47", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "lcn2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "scpep1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "abcg2", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "mtbp", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "plpp2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "il10ra", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "sprr2f", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "camkk1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "nt5dc4", filterType: "FailedAllFilters", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "acot6", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "agfg2", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "tns1", filterType: "BestCase", aaLeft: "931", aaRight: "955", ntLeft: "2791", ntRight: "2865" },
  { key: "bccip", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "sumo2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "tfap2b", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "lyrm9", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "gpkow", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "ppan", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "uros", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "dcaf8l1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "sorl1", filterType: "BestCase", aaLeft: "1697", aaRight: "1721", ntLeft: "5089", ntRight: "5163" },
  { key: "spg7", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "sap30bp", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "trir", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "lca5l", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "bcorl1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "dcun1d3", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "hmx3", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "tigd1", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "znf182", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "slc38a6", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "vdac2", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "osbpl11", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "myoz3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmed2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "lrig1", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "eif4g1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "znf813", filterType: "FailedAllFilters", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "penk", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "tent5a", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "calb1", filterType: "SecondBest", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "sebox", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "grk2", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "tek", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "mrps7", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "ccl11", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "nup210l", filterType: "BestCase", aaLeft: "936", aaRight: "960", ntLeft: "2806", ntRight: "2880" },
  { key: "krt3", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "cdc45", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "pdzd2", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "hook2", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "itgam", filterType: "BestCase", aaLeft: "1023", aaRight: "1047", ntLeft: "3067", ntRight: "3141" },
  { key: "jph4", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "ndufb8", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "c1orf127", filterType: "FailedAllFilters", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "flrt1", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "pdzk1ip1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "adnp", filterType: "FailedAllFilters", aaLeft: "882", aaRight: "906", ntLeft: "2644", ntRight: "2718" },
  { key: "yars1", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "tmem144", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "ypel5", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "npr1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "znf354c", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "hes1", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "snap29", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "galnt13", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "or11h12", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "fh", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "ccdc74a", filterType: "FailedAllFilters", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "znf589", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "nr1d2", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "lman2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "prkar2b", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "c2cd4a", filterType: "FailedAllFilters", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "tmem151b", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "osbpl7", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "h4c1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cnot3", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "tspan2", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "alg9", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "arhgap36", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "snx2", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "elovl2", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "riok2", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "or1j4", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "chek2", filterType: "BestCase", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "galnt5", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "zranb1", filterType: "SecondBest", aaLeft: "681", aaRight: "705", ntLeft: "2041", ntRight: "2115" },
  { key: "fam98c", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "tbc1d3h", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "serpinb10", filterType: "FailedAllFilters", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "kiaa0232", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "fhl1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "hsd11b1l", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "amelx", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "smap2", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "rsph1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "brinp2", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "kcna3", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "whrn", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "apex2", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "sec23b", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "bmp15", filterType: "FailedAllFilters", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "bpifa2", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "orm1", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gpr3", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ccni2", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "polr2b", filterType: "SecondBest", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "tll2", filterType: "BestCase", aaLeft: "912", aaRight: "936", ntLeft: "2734", ntRight: "2808" },
  { key: "abcb7", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "cpox", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "tsr3", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "myo1e", filterType: "BestCase", aaLeft: "965", aaRight: "989", ntLeft: "2893", ntRight: "2967" },
  { key: "hnrnpk", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "atp10d", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "kcnip1", filterType: "SecondBest", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "prac2", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "oxer1", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "zcchc7", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "zscan18", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "c9orf152", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "rex1bd", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "myl4", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "gga2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "lipg", filterType: "BestCase", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "rad23b", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "hoxa11", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "rab1a", filterType: "SecondBest", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "gin1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "dock10", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "man2a2", filterType: "BestCase", aaLeft: "1015", aaRight: "1039", ntLeft: "3043", ntRight: "3117" },
  { key: "or2t3", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "phldb1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "flt4", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "agt", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "or9g4", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "pola2", filterType: "BestCase", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "slc12a7", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "mdga2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "znf846", filterType: "FailedAllFilters", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "cntnap2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "mcemp1", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "arid5a", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "ifna7", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "trnp1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "gata4", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "mcm6", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "mrps28", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "zmym1", filterType: "FailedAllFilters", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "eif4a3", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "slain2", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "il17ra", filterType: "FailedAllFilters", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "usp27x", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "srxn1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "fgd4", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "kcnj18", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "mgat5", filterType: "BestCase", aaLeft: "699", aaRight: "723", ntLeft: "2095", ntRight: "2169" },
  { key: "mllt6", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "fundc1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "irf1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "nkx3-2", filterType: "SecondBest", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "sema4g", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "ogg1", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "dner", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "cenph", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "polr1e", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cherp", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "shroom4", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "ttc39a", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "lhfpl5", filterType: "SecondBest", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "tsen15", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "emg1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "apol3", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "mrpl12", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "znf682", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "ghsr", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "rchy1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "rnase7", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "fibin", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ndufaf4", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "b3gnt9", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "dock2", filterType: "BestCase", aaLeft: "1770", aaRight: "1794", ntLeft: "5308", ntRight: "5382" },
  { key: "prr20e", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "actr2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "nbas", filterType: "BestCase", aaLeft: "1147", aaRight: "1171", ntLeft: "3439", ntRight: "3513" },
  { key: "itprid2", filterType: "BestCase", aaLeft: "830", aaRight: "854", ntLeft: "2488", ntRight: "2562" },
  { key: "ccdc86", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "sytl1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "taf6l", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "hpse", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "muc15", filterType: "FailedAllFilters", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "abcc10", filterType: "BestCase", aaLeft: "908", aaRight: "932", ntLeft: "2722", ntRight: "2796" },
  { key: "smco1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ttbk1", filterType: "BestCase", aaLeft: "1009", aaRight: "1033", ntLeft: "3025", ntRight: "3099" },
  { key: "tafazzin", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "lama3", filterType: "BestCase", aaLeft: "2966", aaRight: "2990", ntLeft: "8896", ntRight: "8970" },
  { key: "gpsm1", filterType: "BestCase", aaLeft: "638", aaRight: "662", ntLeft: "1912", ntRight: "1986" },
  { key: "krr1", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "wfdc2", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "sdr42e1", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "hapln1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "cdc27", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "hand2", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "mindy3", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "zswim3", filterType: "BestCase", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "ube2n", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cdca7l", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "nucb1", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "ska3", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "c1orf87", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tgif1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "phyhd1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "tpi1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nae1", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "six2", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "mlc1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ush1c", filterType: "BestCase", aaLeft: "634", aaRight: "658", ntLeft: "1900", ntRight: "1974" },
  { key: "carnmt1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "myadm", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "h3c1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "maml2", filterType: "FailedAllFilters", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "hic1", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "mymx", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "elf5", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "foxr1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "necap1", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "klk15", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "eys", filterType: "BestCase", aaLeft: "2353", aaRight: "2377", ntLeft: "7057", ntRight: "7131" },
  { key: "raver1", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "calml4", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "asb1", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "igfl4", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "pon2", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "ampd3", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "rbbp8", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "kcng1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "cnfn", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tmem121b", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "lamb4", filterType: "BestCase", aaLeft: "825", aaRight: "849", ntLeft: "2473", ntRight: "2547" },
  { key: "mzt1", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "rab11fip1", filterType: "FailedAllFilters", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "pklr", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "s100pbp", filterType: "FailedAllFilters", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "prr23b", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "mogs", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "pitpnc1", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "rbm4", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "axin1", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "or1l6", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "grwd1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mbl2", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "or5an1", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "lce3e", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "or10g3", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "macc1", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "naif1", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "arcn1", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "map3k9", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "ptpn1", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "prrc2a", filterType: "BestCase", aaLeft: "1504", aaRight: "1528", ntLeft: "4510", ntRight: "4584" },
  { key: "chrna7", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "chd3", filterType: "BestCase", aaLeft: "1575", aaRight: "1599", ntLeft: "4723", ntRight: "4797" },
  { key: "cmtr2", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "npffr2", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "lyzl1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "gcm1", filterType: "FailedAllFilters", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "actc1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "gatad1", filterType: "SecondBest", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "amigo3", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "sth", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ssh3", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "eps8l1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "pnlip", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "znf276", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "susd1", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "lnpep", filterType: "BestCase", aaLeft: "911", aaRight: "935", ntLeft: "2731", ntRight: "2805" },
  { key: "c12orf56", filterType: "FailedAllFilters", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "acer3", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "dcdc2", filterType: "FailedAllFilters", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "ahctf1", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "tuba8", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "smad4", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "slc35a1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "clip4", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "stn1", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "inca1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "spink8", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "nat2", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "tpx2", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "gucy2d", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "taar5", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "slc22a2", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "vhl", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "zcchc13", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "wdr61", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "znf514", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "cdkl1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "fam110c", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "far1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "tmem69", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "ptgdr", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "teddm1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "foxc2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "msl3", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "nipal2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "zcchc24", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "pigw", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "fam189b", filterType: "BestCase", aaLeft: "594", aaRight: "618", ntLeft: "1780", ntRight: "1854" },
  { key: "coa6", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "ifi16", filterType: "FailedAllFilters", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "plekho1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ociad2", filterType: "FailedAllFilters", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "kcnv2", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "mcts1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "fam166a", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "derpc", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "gsap", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "angptl1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "gskip", filterType: "SecondBest", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "kcnk9", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "slc25a35", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "maged2", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "flacc1", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "tbc1d9b", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "cct3", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "tyr", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "col19a1", filterType: "BestCase", aaLeft: "1118", aaRight: "1142", ntLeft: "3352", ntRight: "3426" },
  { key: "ckap5", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "srrm1", filterType: "BestCase", aaLeft: "836", aaRight: "860", ntLeft: "2506", ntRight: "2580" },
  { key: "klhl30", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tnks1bp1", filterType: "BestCase", aaLeft: "1184", aaRight: "1208", ntLeft: "3550", ntRight: "3624" },
  { key: "arfrp1", filterType: "SecondBest", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "tcp1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "amy2b", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "xdh", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "optn", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ifna16", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "map3k11", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "faap24", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "mmp1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "znf45", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "btg1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "actr1a", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "slc26a9", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "usp10", filterType: "BestCase", aaLeft: "762", aaRight: "786", ntLeft: "2284", ntRight: "2358" },
  { key: "c10orf95", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "eipr1", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "mzt2a", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "psma5", filterType: "SecondBest", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "itga3", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "smim38", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "fcho2", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "slx4ip", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "znf362", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "rps24", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "ctag1a", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "fam174b", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ltb4r2", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "sp140l", filterType: "FailedAllFilters", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "rapsn", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ugt2b10", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "fndc7", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "camk1d", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "myl10", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "capn15", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "zdhhc23", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "dazap1", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "use1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "obi1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "rad54l2", filterType: "BestCase", aaLeft: "1191", aaRight: "1215", ntLeft: "3571", ntRight: "3645" },
  { key: "olfm4", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "ct47a6", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "spry1", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "myom2", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "zdhhc14", filterType: "BestCase", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "pyy", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "heph", filterType: "BestCase", aaLeft: "1107", aaRight: "1131", ntLeft: "3319", ntRight: "3393" },
  { key: "sall3", filterType: "BestCase", aaLeft: "566", aaRight: "590", ntLeft: "1696", ntRight: "1770" },
  { key: "dmac2l", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "btbd7", filterType: "BestCase", aaLeft: "1024", aaRight: "1048", ntLeft: "3070", ntRight: "3144" },
  { key: "btnl9", filterType: "FailedAllFilters", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "ct47b1", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "prdm2", filterType: "BestCase", aaLeft: "936", aaRight: "960", ntLeft: "2806", ntRight: "2880" },
  { key: "resf1", filterType: "FailedAllFilters", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "wscd1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "psg9", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "upk3bl1", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "krtap15-1", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "gng13", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "xage3", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "tnfrsf12a", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "rps13", filterType: "SecondBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "or51m1", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "hectd2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "zkscan3", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "fbxo45", filterType: "SecondBest", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "pld3", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "rpl29", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "kmt2b", filterType: "BestCase", aaLeft: "1816", aaRight: "1840", ntLeft: "5446", ntRight: "5520" },
  { key: "tgm1", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "anxa8l1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "mylip", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "tmprss12", filterType: "FailedAllFilters", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "saa4", filterType: "FourthBest", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "surf2", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "gusb", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "psmb2", filterType: "SecondBest", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "itgb4", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "sftpa2", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "cpne1", filterType: "BestCase", aaLeft: "507", aaRight: "531", ntLeft: "1519", ntRight: "1593" },
  { key: "ifna21", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tssc4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "cd320", filterType: "FailedAllFilters", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "nmrk2", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "ankdd1b", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "xrcc1", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "gnpnat1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "znf576", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "prune2", filterType: "FailedAllFilters", aaLeft: "997", aaRight: "1021", ntLeft: "2989", ntRight: "3063" },
  { key: "ogdh", filterType: "BestCase", aaLeft: "804", aaRight: "828", ntLeft: "2410", ntRight: "2484" },
  { key: "gpld1", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "iqcf5", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "carmil1", filterType: "FailedAllFilters", aaLeft: "1084", aaRight: "1108", ntLeft: "3250", ntRight: "3324" },
  { key: "lim2", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "slc35g3", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "exoc2", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "utrn", filterType: "BestCase", aaLeft: "918", aaRight: "942", ntLeft: "2752", ntRight: "2826" },
  { key: "tyw1", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "slc25a2", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "gjb2", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "znf214", filterType: "FailedAllFilters", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "angel2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "pla2g6", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "npas2", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "fkbp11", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "rtn2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "npc2", filterType: "FourthBest", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "casp8", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "slc32a1", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "sh3bp4", filterType: "FailedAllFilters", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "cyp3a5", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "smpx", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "dnm3", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ilrun", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "ubtd2", filterType: "SecondBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "cxcr6", filterType: "FailedAllFilters", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "smarca2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "adora2b", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "sez6", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "stk32c", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "blmh", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "myrf", filterType: "BestCase", aaLeft: "1072", aaRight: "1096", ntLeft: "3214", ntRight: "3288" },
  { key: "vps33a", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "serpinh1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "or3a1", filterType: "FailedAllFilters", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "dchs2", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "ccr1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "rab28", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "gja5", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "c8g", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "pxmp2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "znf223", filterType: "FailedAllFilters", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "neto2", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "terf1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "papss1", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "cog4", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tspan11", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "pter", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ncoa1", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "scgb1d2", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mob3a", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "jak2", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "uqcrc2", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "tdp1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "gtf2ird1", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "stk4", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "fat3", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "nck1", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "fam90a18", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "znf541", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "itpr2", filterType: "BestCase", aaLeft: "937", aaRight: "961", ntLeft: "2809", ntRight: "2883" },
  { key: "kin", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "ucp2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "uqcrfs1", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "pate1", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "lurap1l", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "ddn", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "naxd", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "c3", filterType: "BestCase", aaLeft: "700", aaRight: "724", ntLeft: "2098", ntRight: "2172" },
  { key: "mtus2", filterType: "BestCase", aaLeft: "869", aaRight: "893", ntLeft: "2605", ntRight: "2679" },
  { key: "pramef10", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "cox8a", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "eaf2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "znf300", filterType: "FailedAllFilters", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "rtn4ip1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "nup160", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "klf12", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "timm50", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "itih2", filterType: "BestCase", aaLeft: "891", aaRight: "915", ntLeft: "2671", ntRight: "2745" },
  { key: "mgat4b", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "c8orf33", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "rragb", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "prtg", filterType: "BestCase", aaLeft: "1004", aaRight: "1028", ntLeft: "3010", ntRight: "3084" },
  { key: "morn3", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "rps2", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "cbl", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "fermt2", filterType: "BestCase", aaLeft: "584", aaRight: "608", ntLeft: "1750", ntRight: "1824" },
  { key: "fbxo10", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "qdpr", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "pomk", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "wdfy2", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "znf462", filterType: "BestCase", aaLeft: "753", aaRight: "777", ntLeft: "2257", ntRight: "2331" },
  { key: "snrpd1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "gstm4", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "spaca4", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "ubr1", filterType: "BestCase", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "krt6a", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "cd33", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "fam104b", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "sftpb", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "fam90a9", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "eif3l", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "krtap12-1", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "chkb", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "gsx2", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "znf10", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "tas2r50", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "ern1", filterType: "BestCase", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "usp45", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "pilrb", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "fbxo27", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "plb1", filterType: "FailedAllFilters", aaLeft: "969", aaRight: "993", ntLeft: "2905", ntRight: "2979" },
  { key: "chmp4b", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "msc", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "sccpdh", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "art4", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "znf337", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "kbtbd4", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "b3galt4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "hip1r", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "haus5", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "prpf6", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "agap3", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "card14", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "mipep", filterType: "FailedAllFilters", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "znf628", filterType: "FailedAllFilters", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "vstm2a", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "mettl7a", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cit", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "pxdnl", filterType: "BestCase", aaLeft: "739", aaRight: "763", ntLeft: "2215", ntRight: "2289" },
  { key: "pramef27", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "dab2ip", filterType: "BestCase", aaLeft: "754", aaRight: "778", ntLeft: "2260", ntRight: "2334" },
  { key: "nat10", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "or5h14", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "rps23", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "acd", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "znf365", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "tnfrsf11a", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "arfgef1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "psmd10", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ncbp2", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "ms4a6e", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "mlnr", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "ugt2b17", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "akr7a2", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "avp", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "capn12", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "zdhhc24", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "dip2c", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "zbtb38", filterType: "BestCase", aaLeft: "728", aaRight: "752", ntLeft: "2182", ntRight: "2256" },
  { key: "olfm3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ct47a1", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "tmem45b", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "prelid3a", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "scrg1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bmp2k", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "gstk1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "sdcbp", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "ddx49", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "znf407", filterType: "FailedAllFilters", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "camta1", filterType: "BestCase", aaLeft: "1280", aaRight: "1304", ntLeft: "3838", ntRight: "3912" },
  { key: "oscp1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "rnf144a", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "mcu", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "pgr", filterType: "BestCase", aaLeft: "662", aaRight: "686", ntLeft: "1984", ntRight: "2058" },
  { key: "etfdh", filterType: "FailedAllFilters", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "rpl19", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "crisp1", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "rrbp1", filterType: "BestCase", aaLeft: "1269", aaRight: "1293", ntLeft: "3805", ntRight: "3879" },
  { key: "psma2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "dnase1", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "ss18", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "itga4", filterType: "BestCase", aaLeft: "937", aaRight: "961", ntLeft: "2809", ntRight: "2883" },
  { key: "hbz", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "etfa", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "dgkd", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "melk", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "kmt2e", filterType: "BestCase", aaLeft: "1187", aaRight: "1211", ntLeft: "3559", ntRight: "3633" },
  { key: "ly6e", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "usp20", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "spring1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "cgn", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "bbs1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "pld4", filterType: "FailedAllFilters", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "tm4sf5", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tbc1d31", filterType: "BestCase", aaLeft: "649", aaRight: "673", ntLeft: "1945", ntRight: "2019" },
  { key: "fbxo42", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "psmb5", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "srebf2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "itgb3", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "ip6k1", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "tmprss15", filterType: "BestCase", aaLeft: "781", aaRight: "805", ntLeft: "2341", ntRight: "2415" },
  { key: "tgm6", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "ptprq", filterType: "BestCase", aaLeft: "1375", aaRight: "1399", ntLeft: "4123", ntRight: "4197" },
  { key: "ces2", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "abhd13", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "znf430", filterType: "FailedAllFilters", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "tcaf2", filterType: "BestCase", aaLeft: "854", aaRight: "878", ntLeft: "2560", ntRight: "2634" },
  { key: "rfpl2", filterType: "FailedAllFilters", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "cpne6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c1qtnf9", filterType: "FailedAllFilters", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "hars2", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "ppdpf", filterType: "FourthBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "map3k21", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "depdc5", filterType: "BestCase", aaLeft: "1105", aaRight: "1129", ntLeft: "3313", ntRight: "3387" },
  { key: "mlycd", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "caly", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "sall4", filterType: "FailedAllFilters", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "pierce1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "zdhhc13", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "tyms", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "capn8", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "camk2d", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "mybpc3", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "tiprl", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "prdm5", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "usf1", filterType: "SecondBest", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "rps14", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "spp1", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "pcare", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "nova2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "gng14", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "zkscan4", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rag2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "nt5c", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ttf2", filterType: "BestCase", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "tp53rk", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "trim49d2", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fam177b", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "susd6", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "clnk", filterType: "FailedAllFilters", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "creb1", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "tbl1y", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "efhd2", filterType: "SecondBest", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "otud6a", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "selenof", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "smad3", filterType: "SecondBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ifnk", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "clip3", filterType: "SecondBest", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "fgfbp1", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "or4b1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "zcchc14", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "znf513", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "slc22a5", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "tm2d2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "taar2", filterType: "FailedAllFilters", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tmem59", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "gucy2c", filterType: "BestCase", aaLeft: "1049", aaRight: "1073", ntLeft: "3145", ntRight: "3219" },
  { key: "cep135", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "necab2", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "frmpd2", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "trim48", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "chd4", filterType: "BestCase", aaLeft: "1585", aaRight: "1609", ntLeft: "4753", ntRight: "4827" },
  { key: "ptpn6", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "cox7a1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "tnmd", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "mfsd4a", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "garin5a", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "ubxn10", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "amy1b", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "lyzl6", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "bcl9", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "naa80", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "mctp1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "prpf31", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "wee1", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "tsnare1", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "angptl6", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "sdad1", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "exosc7", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "cct4", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "slc25a32", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "sars1", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "pou5f1", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "nelfcd", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ckap2", filterType: "FailedAllFilters", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "gcn1", filterType: "BestCase", aaLeft: "2494", aaRight: "2518", ntLeft: "7480", ntRight: "7554" },
  { key: "nox1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "cndp2", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "kitlg", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "mkln1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "acsf3", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "fam110d", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "znf524", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "ltb", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "hba2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "coa1", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "anxa11", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "pigp", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "slc35b1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "aqp12b", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "tubb8", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "fbxo17", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "morn4", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ipo11", filterType: "BestCase", aaLeft: "753", aaRight: "777", ntLeft: "2257", ntRight: "2331" },
  { key: "rps5", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "trib2", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "hdx", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "bcl9l", filterType: "BestCase", aaLeft: "877", aaRight: "901", ntLeft: "2629", ntRight: "2703" },
  { key: "tmem35b", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "cdhr1", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "spaca3", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "gstm3", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "bbip1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "ccnq", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "unc45b", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "purb", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "tas2r60", filterType: "FailedAllFilters", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "cd34", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "znf20", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "kif6", filterType: "BestCase", aaLeft: "760", aaRight: "784", ntLeft: "2278", ntRight: "2352" },
  { key: "gmnc", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "znf618", filterType: "BestCase", aaLeft: "838", aaRight: "862", ntLeft: "2512", ntRight: "2586" },
  { key: "dact2", filterType: "FailedAllFilters", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "s100g", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pramef17", filterType: "FailedAllFilters", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "asip", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "st18", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "gramd1c", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "vax2", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "klf15", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "pwwp3a", filterType: "FailedAllFilters", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "ssx2b", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "c8orf34", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "majin", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "itih5", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "bnc1", filterType: "BestCase", aaLeft: "648", aaRight: "672", ntLeft: "1942", ntRight: "2016" },
  { key: "c6orf141", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "nanp", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "dhx36", filterType: "BestCase", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "znf330", filterType: "SecondBest", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "kbtbd3", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "agap4", filterType: "FailedAllFilters", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "hif3a", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "sesn2", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "haus2", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "bphl", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "nup93", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "rpl10l", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "hacd2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "orai3", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "nle1", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "pramef20", filterType: "FailedAllFilters", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "phactr1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "eif3k", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "rpl37a", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tlx3", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "ptchd1", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "ctxn1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "glb1l2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cfap69", filterType: "BestCase", aaLeft: "909", aaRight: "933", ntLeft: "2725", ntRight: "2799" },
  { key: "fam107b", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "fignl2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "znf17", filterType: "FailedAllFilters", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "hrk", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "lrp8", filterType: "BestCase", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "c15orf48", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "usp42", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "pdhx", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "utf1", filterType: "FailedAllFilters", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "pacs2", filterType: "BestCase", aaLeft: "766", aaRight: "790", ntLeft: "2296", ntRight: "2370" },
  { key: "art3", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "cd200r1", filterType: "FailedAllFilters", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "sncb", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "psmd7", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "stam2", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "cacna1h", filterType: "BestCase", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "brf2", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "tmod2", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "srm", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "papln", filterType: "BestCase", aaLeft: "912", aaRight: "936", ntLeft: "2734", ntRight: "2808" },
  { key: "aldh7a1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "rab18", filterType: "SecondBest", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "gjb5", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "znf213", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "ncam2", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "minar1", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "slc25a5", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "zcrb1", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "pithd1", filterType: "SecondBest", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "tex35", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "scap", filterType: "BestCase", aaLeft: "922", aaRight: "946", ntLeft: "2764", ntRight: "2838" },
  { key: "il6r", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "acacb", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "pus10", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "xrcc6", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "ndc80", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "znf571", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "msi1", filterType: "SecondBest", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "lims1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "il18rap", filterType: "FailedAllFilters", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "spata32", filterType: "FailedAllFilters", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "exoc5", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "slc35g4", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "nrsn2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "tbc1d7", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "iqcf2", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "lipt1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "tefm", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "tspan16", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "pou2f1", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "hpgd", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "skida1", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "txndc9", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "hepacam2", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "cog3", filterType: "BestCase", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "afmid", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "folh1", filterType: "BestCase", aaLeft: "602", aaRight: "626", ntLeft: "1804", ntRight: "1878" },
  { key: "slc35d3", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "ncoa6", filterType: "BestCase", aaLeft: "1125", aaRight: "1149", ntLeft: "3373", ntRight: "3447" },
  { key: "tarbp2", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "sla", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "ptpa", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "akr1c1", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "cox15", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "ccnjl", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "stk3", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "fat4", filterType: "BestCase", aaLeft: "2432", aaRight: "2456", ntLeft: "7294", ntRight: "7368" },
  { key: "syngr1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "dbx1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "wdr33", filterType: "BestCase", aaLeft: "1116", aaRight: "1140", ntLeft: "3346", ntRight: "3420" },
  { key: "znf546", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "fcn2", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "tal2", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "tnfsf13b", filterType: "SecondBest", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "adig", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tmigd2", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "cxcr1", filterType: "ThirdBest", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "smarca5", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ufl1", filterType: "BestCase", aaLeft: "734", aaRight: "758", ntLeft: "2200", ntRight: "2274" },
  { key: "cep68", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "adtrp", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "znf224", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "ccr6", filterType: "FailedAllFilters", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "pacsin3", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "f2rl3", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "jph3", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "mamdc4", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "arhgap11a", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tmem143", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "cidec", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "loc102725191", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "or52b2", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "gtf2h1", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "hes6", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "tapt1", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "tedc2", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "ypel2", filterType: "SecondBest", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "gorab", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "catsperg", filterType: "BestCase", aaLeft: "897", aaRight: "921", ntLeft: "2689", ntRight: "2763" },
  { key: "nol7", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "prkar1b", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "galnt14", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "bpifb2", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "grk5", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "anks6", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "jmjd8", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "frrs1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "msx2", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ccl16", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "prim2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gsc", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "disp3", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "cd7", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "arhgef9", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "r3hcc1l", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "cdc42", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "cep97", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "krt4", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "adap1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "spin1", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "tmcc3", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "kcna4", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "ly6g6f", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "pibf1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "fcamr", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "sez6l2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "aggf1", filterType: "BestCase", aaLeft: "689", aaRight: "713", ntLeft: "2065", ntRight: "2139" },
  { key: "ccl21", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "gpr4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "prkcsh", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "tacstd2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "mtrex", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "paip2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "shoc2", filterType: "SecondBest", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "crppa", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "chp2", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "kiaa0319l", filterType: "BestCase", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "clns1a", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "or10a6", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "aif1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "kif5a", filterType: "BestCase", aaLeft: "961", aaRight: "985", ntLeft: "2881", ntRight: "2955" },
  { key: "bop1", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "ndufa8", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "arhgap31", filterType: "BestCase", aaLeft: "1251", aaRight: "1275", ntLeft: "3751", ntRight: "3825" },
  { key: "ndn", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "snx5", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "h4c6", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cnot4", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "tspan5", filterType: "SecondBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ccdc194", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "xkrx", filterType: "BestCase", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "tmem174", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "galnt2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "ccdc8", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "il1b", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "or52a5", filterType: "FailedAllFilters", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "mcmbp", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "elovl5", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "cxxc4", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "cln5", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "plp2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "col14a1", filterType: "BestCase", aaLeft: "1684", aaRight: "1708", ntLeft: "5050", ntRight: "5124" },
  { key: "larp1b", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "klk1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "slc7a7", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "pcid2", filterType: "FourthBest", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "mtap", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "osgep", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "abcd2", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "baiap2", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "pus7l", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "zfp2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "mrpl40", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "cldn8", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "tnp1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "rab39a", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "rngtt", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "rell1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "golt1a", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "myl12a", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "edrf1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "znf823", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "tdrd12", filterType: "FailedAllFilters", aaLeft: "666", aaRight: "690", ntLeft: "1996", ntRight: "2070" },
  { key: "gatad2a", filterType: "BestCase", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "cir1", filterType: "SecondBest", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "bmp8a", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "parp4", filterType: "BestCase", aaLeft: "827", aaRight: "851", ntLeft: "2479", ntRight: "2553" },
  { key: "lin7c", filterType: "SecondBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "crabp1", filterType: "SecondBest", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "dmac1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tigd6", filterType: "FailedAllFilters", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "lrrc10b", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "slc38a1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "znf185", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "dcun1d4", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "eral1", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "fryl", filterType: "BestCase", aaLeft: "1313", aaRight: "1337", ntLeft: "3937", ntRight: "4011" },
  { key: "inpp4b", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "syn1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "rtkn", filterType: "BestCase", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "tmed5", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rergl", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "manbal", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "znf814", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "ctu1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "grid2ip", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ptk6", filterType: "FailedAllFilters", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "sprr2a", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "plpp5", filterType: "FailedAllFilters", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "abcg5", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "rnf145", filterType: "BestCase", aaLeft: "609", aaRight: "633", ntLeft: "1825", ntRight: "1899" },
  { key: "srsf8", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "klhdc7a", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "igsf3", filterType: "BestCase", aaLeft: "988", aaRight: "1012", ntLeft: "2962", ntRight: "3036" },
  { key: "nt5dc3", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "acot1", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "ccnb1ip1", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "gucd1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "rpa1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "tfap2e", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "trip6", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "cpo", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "sp100", filterType: "FailedAllFilters", aaLeft: "627", aaRight: "651", ntLeft: "1879", ntRight: "1953" },
  { key: "vwa3a", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "mdm1", filterType: "FailedAllFilters", aaLeft: "622", aaRight: "646", ntLeft: "1864", ntRight: "1938" },
  { key: "tnnc1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "zdhhc9", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "wfdc5", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "stard8", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "cdc20", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "mmrn2", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "lama4", filterType: "BestCase", aaLeft: "1291", aaRight: "1315", ntLeft: "3871", ntRight: "3945" },
  { key: "kcnd1", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "rhobtb1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "or4s2", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "mindy4", filterType: "BestCase", aaLeft: "713", aaRight: "737", ntLeft: "2137", ntRight: "2211" },
  { key: "zswim4", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "lsr", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "grm7", filterType: "BestCase", aaLeft: "512", aaRight: "536", ntLeft: "1534", ntRight: "1608" },
  { key: "ube2i", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "lmod3", filterType: "FailedAllFilters", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "foxq1", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "sms", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "tmem91", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "or10d3", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "atg3", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "spx", filterType: "FourthBest", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "ndufaf3", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "c19orf44", filterType: "FailedAllFilters", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "lect2", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "sgsm3", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "smpd2", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "fam72b", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "actr5", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "c9orf40", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "leo1", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "dock5", filterType: "BestCase", aaLeft: "920", aaRight: "944", ntLeft: "2758", ntRight: "2832" },
  { key: "prr20b", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ptgfrn", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "ccdc81", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "tmem121", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "mtx2", filterType: "SecondBest", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "p2ry11", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "ppm1h", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "paxbp1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "mn1", filterType: "BestCase", aaLeft: "886", aaRight: "910", ntLeft: "2656", ntRight: "2730" },
  { key: "mon1b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "pde1b", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "taf5l", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "or1l1", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "akip1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "tmem116", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "rbm3", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "flywch2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "gtpbp10", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "or10g4", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "pelp1", filterType: "BestCase", aaLeft: "802", aaRight: "826", ntLeft: "2404", ntRight: "2478" },
  { key: "c7orf25", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "ralgapb", filterType: "BestCase", aaLeft: "1216", aaRight: "1240", ntLeft: "3646", ntRight: "3720" },
  { key: "lce3b", filterType: "FourthBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "c21orf140", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "tmsb4y", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "poglut2", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "kif3c", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "c19orf73", filterType: "ThirdBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "adh1a", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "kdf1", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "h3c6", filterType: "SecondBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "trmt2a", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "six5", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "r3hdm1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "znf791", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "naf1", filterType: "FailedAllFilters", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "taf8", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "elf2", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "zscan9", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "cfap97d1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "igfl3", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "spatc1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "mphosph9", filterType: "BestCase", aaLeft: "831", aaRight: "855", ntLeft: "2491", ntRight: "2565" },
  { key: "calml3", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "asb6", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "uhrf1", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "ca2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "klk12", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "hbp1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "dynap", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "hr", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "actl9", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "gcnt1", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "lamb3", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "sbk2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "sfswap", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "aak1", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "or2t4", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "slpi", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "eloa", filterType: "BestCase", aaLeft: "670", aaRight: "694", ntLeft: "2008", ntRight: "2082" },
  { key: "pkp2", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "abhd16a", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "flt3", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "carns1", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "znf841", filterType: "FailedAllFilters", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "cntnap5", filterType: "BestCase", aaLeft: "631", aaRight: "655", ntLeft: "1891", ntRight: "1965" },
  { key: "col13a1", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "polr2e", filterType: "SecondBest", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "myo1b", filterType: "SecondBest", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "hnrnpl", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "grem1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "mrpl22", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ncapg", filterType: "FailedAllFilters", aaLeft: "888", aaRight: "912", ntLeft: "2662", ntRight: "2736" },
  { key: "pkhd1l1", filterType: "BestCase", aaLeft: "851", aaRight: "875", ntLeft: "2551", ntRight: "2625" },
  { key: "tmem248", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "cnr1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "myl3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "irf6", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "maneal", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "zfp42", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "cenpo", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "pno1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "alk", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "uty", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "slc28a2", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "kyat3", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "prr15l", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "abca7", filterType: "BestCase", aaLeft: "1106", aaRight: "1130", ntLeft: "3316", ntRight: "3390" },
  { key: "bola3", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "lhfpl2", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "epc1", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "shroom3", filterType: "FailedAllFilters", aaLeft: "1736", aaRight: "1760", ntLeft: "5206", ntRight: "5280" },
  { key: "polr1b", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "arrdc1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "dmd", filterType: "BestCase", aaLeft: "2415", aaRight: "2439", ntLeft: "7243", ntRight: "7317" },
  { key: "mrpl15", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nsl1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "gata3", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "esyt1", filterType: "BestCase", aaLeft: "611", aaRight: "635", ntLeft: "1831", ntRight: "1905" },
  { key: "phf5a", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "hfm1", filterType: "BestCase", aaLeft: "1269", aaRight: "1293", ntLeft: "3805", ntRight: "3879" },
  { key: "angpt1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "tlr9", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "gnrh2", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "zmym6", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "dnase1l3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "stoml3", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "fam210a", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "mllt1", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "phlda1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "pclo", filterType: "BestCase", aaLeft: "2265", aaRight: "2289", ntLeft: "6793", ntRight: "6867" },
  { key: "or2w3", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "man2b2", filterType: "BestCase", aaLeft: "673", aaRight: "697", ntLeft: "2017", ntRight: "2091" },
  { key: "mgat2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "kifbp", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "fgd3", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "rab2a", filterType: "SecondBest", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "trim63", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "tceal2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "rimklb", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "scn3a", filterType: "BestCase", aaLeft: "1813", aaRight: "1837", ntLeft: "5437", ntRight: "5511" },
  { key: "tmc2", filterType: "BestCase", aaLeft: "804", aaRight: "828", ntLeft: "2410", ntRight: "2484" },
  { key: "bend2", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "rcbtb1", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "gpr142", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "fan1", filterType: "FailedAllFilters", aaLeft: "890", aaRight: "914", ntLeft: "2668", ntRight: "2742" },
  { key: "kcnj2", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "plekhs1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "copg2", filterType: "BestCase", aaLeft: "602", aaRight: "626", ntLeft: "1804", ntRight: "1878" },
  { key: "cry1", filterType: "BestCase", aaLeft: "554", aaRight: "578", ntLeft: "1660", ntRight: "1734" },
  { key: "ttpa", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "diaph3", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "htr2c", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "klhdc2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ccdc62", filterType: "FailedAllFilters", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "or52j3", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "mprip", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "nrbf2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ccdc122", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "kbtbd12", filterType: "FailedAllFilters", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "pik3ap1", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "pxdc1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "cyp1a1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "dnajc6", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "cd300lf", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "or5ac2", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "gabrr1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cmtm5", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "pth2r", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tmem72", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "jarid2", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "c2orf49", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "acadl", filterType: "FourthBest", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "cdh18", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "or8k3", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "slc30a5", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "idua", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "mthfd1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "plaat5", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "klrb1", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "mycbp2", filterType: "BestCase", aaLeft: "2318", aaRight: "2342", ntLeft: "6952", ntRight: "7026" },
  { key: "chst4", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "cd164l2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "lrrc63", filterType: "FailedAllFilters", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "htr1d", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "defb136", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "gprc5d", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ric8b", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "creb3l1", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "uba52", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "guf1", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "shh", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "slc27a6", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "ccdc115", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "rftn2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "racgap1", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "med4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ogfod2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mboat2", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "b4galt6", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "gabrd", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "ppp1r18", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "slc25a19", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "trim54", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "c1orf54", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sdhc", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "ndp", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "trip13", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "nap1l2", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "fam135b", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "prss22", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "krtap5-3", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "accs", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "znf772", filterType: "FailedAllFilters", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "styxl1", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "fsd1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "eif4e", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "sox14", filterType: "SecondBest", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "aip", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "cpsf4l", filterType: "FourthBest", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "znf69", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "rela", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "zg16b", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "gdap1l1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "h1-8", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "ldhal6a", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "ece1", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "naaa", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "ppbp", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "fry", filterType: "BestCase", aaLeft: "2550", aaRight: "2574", ntLeft: "7648", ntRight: "7722" },
  { key: "znf133", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "rpl32", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "cfb", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "or51q1", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "spryd3", filterType: "SecondBest", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "rilp", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "dut", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "rufy2", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "rrp1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "mia", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "palmd", filterType: "FailedAllFilters", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "fuca1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "lrp10", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "smim14", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "crnn", filterType: "FailedAllFilters", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "stra8", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "mtmr4", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "st3gal5", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "hemk1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "wwp1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "scg2", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "edc3", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "nppc", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "rbm12b", filterType: "BestCase", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "krtap10-5", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "snapc3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "gsdmd", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "kcna10", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "sppl2b", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "ugt2a2", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "daz1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "zbtb24", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "mtmr14", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ccnd1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "cystm1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ranbp2", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "map2k6", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "timm29", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ppih", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sdhaf2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "fam81a", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "smim23", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "il37", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "ubash3a", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "scd5", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "nalcn", filterType: "BestCase", aaLeft: "1683", aaRight: "1707", ntLeft: "5047", ntRight: "5121" },
  { key: "rax2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "unc13c", filterType: "BestCase", aaLeft: "675", aaRight: "699", ntLeft: "2023", ntRight: "2097" },
  { key: "trem2", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "cnksr2", filterType: "SecondBest", aaLeft: "901", aaRight: "925", ntLeft: "2701", ntRight: "2775" },
  { key: "neu2", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "snai2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "retnlb", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "marchf9", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "ipo8", filterType: "BestCase", aaLeft: "871", aaRight: "895", ntLeft: "2611", ntRight: "2685" },
  { key: "cpq", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "ddx55", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "elavl2", filterType: "SecondBest", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "kif18b", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "gbp5", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "tmem63c", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "ms4a2", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "lrrfip2", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "cfap20", filterType: "SecondBest", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "cetn3", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "micall2", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "slc8a3", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "rpgrip1l", filterType: "BestCase", aaLeft: "1264", aaRight: "1288", ntLeft: "3790", ntRight: "3864" },
  { key: "c1s", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "vps52", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "c5ar2", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "lrrc36", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "phc1", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "unc50", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gtf2f2", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "pdia2", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "or52l1", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "rbm19", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "sh3d19", filterType: "BestCase", aaLeft: "613", aaRight: "637", ntLeft: "1837", ntRight: "1911" },
  { key: "ube2w", filterType: "SecondBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "ccn1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ppp2r2d", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "spata19", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "ttll4", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "ctnna1", filterType: "BestCase", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "bad", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "gal3st1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "capza1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tex261", filterType: "SecondBest", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "arvcf", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "pdlim1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "brd9", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "vangl2", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "actl7a", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "mea1", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "ascc1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "rasip1", filterType: "BestCase", aaLeft: "870", aaRight: "894", ntLeft: "2608", ntRight: "2682" },
  { key: "nme9", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "kdm2b", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "entpd1", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "cdh3", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "pydc2", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "egf", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "znf727", filterType: "FailedAllFilters", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "sugp2", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "ap4e1", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "cdk5rap3", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "or6b2", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "orc5", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "trim36", filterType: "BestCase", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "h2ac12", filterType: "SecondBest", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "cdk4", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "nfam1", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "tex29", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "tacr1", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "eda", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "setdb2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "pgrmc2", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "znf710", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "aqp1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "pnpo", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "hbegf", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "cep43", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "chrna10", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "atp6v1b2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "krtap10-12", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "pcdhga12", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "panx3", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "krtap2-3", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "gpr137c", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "crim1", filterType: "BestCase", aaLeft: "721", aaRight: "745", ntLeft: "2161", ntRight: "2235" },
  { key: "ccdc177", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "marcksl1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "fam86b2", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "nbpf6", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ssr1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "upk3a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "c1rl", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "sf3b1", filterType: "SecondBest", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "phkg2", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "msrb3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "ugt1a9", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "stk17b", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "zap70", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "mbd4", filterType: "FailedAllFilters", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "wdr18", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "fbln1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "nhlrc2", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "ddhd1", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "defb107b", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "cldnd2", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ormdl3", filterType: "SecondBest", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "tnfrsf18", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "snx17", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "oxr1", filterType: "BestCase", aaLeft: "620", aaRight: "644", ntLeft: "1858", ntRight: "1932" },
  { key: "tomm40l", filterType: "SecondBest", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "hhipl2", filterType: "FailedAllFilters", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "srgap2", filterType: "BestCase", aaLeft: "872", aaRight: "896", ntLeft: "2614", ntRight: "2688" },
  { key: "eef1e1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "h2ac6", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "rpl5", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "hnrnpr", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "sptlc1", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "pex26", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "rexo4", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "agtpbp1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "lysmd4", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "cdkn2aip", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "slc6a18", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "lrtm2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "fabp4", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "f10", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "znf479", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "dpm1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "cenpq", filterType: "FailedAllFilters", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "akap5", filterType: "FailedAllFilters", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "cd28", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "pcgf5", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ct45a2", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "zp4", filterType: "FailedAllFilters", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "tecpr1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "msgn1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ervmer34-1", filterType: "FailedAllFilters", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "col9a3", filterType: "BestCase", aaLeft: "596", aaRight: "620", ntLeft: "1786", ntRight: "1860" },
  { key: "ythdf3", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "hsp90ab1", filterType: "BestCase", aaLeft: "597", aaRight: "621", ntLeft: "1789", ntRight: "1863" },
  { key: "ankar", filterType: "BestCase", aaLeft: "1160", aaRight: "1184", ntLeft: "3478", ntRight: "3552" },
  { key: "fgl2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "traip", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "slurp1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "klf5", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "smim41", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "rnaseh2b", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "ints12", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "rapgef4", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "socs7", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "eif2ak3", filterType: "BestCase", aaLeft: "735", aaRight: "759", ntLeft: "2203", ntRight: "2277" },
  { key: "impa1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "rfx2", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "crmp1", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "mettl1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "efcab9", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ubn1", filterType: "BestCase", aaLeft: "922", aaRight: "946", ntLeft: "2764", ntRight: "2838" },
  { key: "cdk13", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "ccnb3", filterType: "FailedAllFilters", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "zbtb46", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "snx20", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "shc4", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "map9", filterType: "FailedAllFilters", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "cgb2", filterType: "FourthBest", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "hoxb1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "amer3", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "erc2", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "or5k4", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znf486", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "znf808", filterType: "FailedAllFilters", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "fgfr2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "mbip", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "inka2", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "kcmf1", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "apcdd1l", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "abraxas2", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "syde2", filterType: "BestCase", aaLeft: "1043", aaRight: "1067", ntLeft: "3127", ntRight: "3201" },
  { key: "ffar1", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "dmrt2", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "nit1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "s1pr2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tmem201", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "sra1", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "syt13", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "nfkbib", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "veph1", filterType: "BestCase", aaLeft: "637", aaRight: "661", ntLeft: "1909", ntRight: "1983" },
  { key: "vwa1", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "metrnl", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "vav1", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "eftud2", filterType: "SecondBest", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "hbd", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "atp5pf", filterType: "FourthBest", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "prx", filterType: "FailedAllFilters", aaLeft: "863", aaRight: "887", ntLeft: "2587", ntRight: "2661" },
  { key: "spag8", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "gsk3b", filterType: "SecondBest", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "bicdl1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "dgkz", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "cox5b", filterType: "FourthBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "slc10a3", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "plcd1", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "tsga10", filterType: "BestCase", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "col11a2", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "rttn", filterType: "BestCase", aaLeft: "1648", aaRight: "1672", ntLeft: "4942", ntRight: "5016" },
  { key: "slc45a3", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "h2bc17", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ptpro", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "slc2a4", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "tmem236", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "prph2", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "khdrbs3", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "rbm4b", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "actrt3", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "dennd2a", filterType: "BestCase", aaLeft: "864", aaRight: "888", ntLeft: "2590", ntRight: "2664" },
  { key: "tmem229a", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "commd9", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "ankrd46", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "oga", filterType: "BestCase", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "ramac", filterType: "FourthBest", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "kiaa1549l", filterType: "BestCase", aaLeft: "1659", aaRight: "1683", ntLeft: "4975", ntRight: "5049" },
  { key: "amd1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "hoxa6", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "tssk2", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "prkaa1", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "xkr8", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "mink1", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "tgfbrap1", filterType: "BestCase", aaLeft: "611", aaRight: "635", ntLeft: "1831", ntRight: "1905" },
  { key: "tmprss11b", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "hcls1", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "dtwd2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "c4orf46", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "utp25", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "fkbp3", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "nsmce1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "otud3", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "or5d3p", filterType: "FailedAllFilters", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "sv2b", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "yy2", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "ppp1r15b", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "rpl3l", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "gltp", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "abi1", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "hsd17b2", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "smoc1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "drgx", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "sertm2", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "guca1anb", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "mt4", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "nosip", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "serpine2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mdfi", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "gyg2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "neb", filterType: "BestCase", aaLeft: "3645", aaRight: "3669", ntLeft: "10933", ntRight: "11007" },
  { key: "gipc2", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "slc39a9", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "fbxl8", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ccdc188", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "tmem168", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "os9", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "fut6", filterType: "FailedAllFilters", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "rnf17", filterType: "BestCase", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "tox", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "xpo5", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "hla-dob", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "odf1", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "gpr17", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "gpn3", filterType: "SecondBest", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "e4f1", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "cldn16", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf592", filterType: "BestCase", aaLeft: "1218", aaRight: "1242", ntLeft: "3652", ntRight: "3726" },
  { key: "gc", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "hdgf", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "pp2d1", filterType: "FailedAllFilters", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "rhoa", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "atrnl1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ncf2", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "acsm5", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "tcp11l2", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "mybbp1a", filterType: "BestCase", aaLeft: "895", aaRight: "919", ntLeft: "2683", ntRight: "2757" },
  { key: "znf518a", filterType: "FailedAllFilters", aaLeft: "1050", aaRight: "1074", ntLeft: "3148", ntRight: "3222" },
  { key: "or6s1", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "best3", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "mgarp", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "fancm", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "rnf207", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "tacc2", filterType: "BestCase", aaLeft: "2586", aaRight: "2610", ntLeft: "7756", ntRight: "7830" },
  { key: "plekhg7", filterType: "FailedAllFilters", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "filip1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "rps3a", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cops4", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "pmm1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "leprot", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "dnajb14", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rnf20", filterType: "BestCase", aaLeft: "529", aaRight: "553", ntLeft: "1585", ntRight: "1659" },
  { key: "spocd1", filterType: "FailedAllFilters", aaLeft: "1045", aaRight: "1069", ntLeft: "3133", ntRight: "3207" },
  { key: "clec17a", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "gpr20", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "dmrta1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "bcas1", filterType: "FailedAllFilters", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "tyrobp", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "pax4", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "fam222a", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "fmo2", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "fcrlb", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "pign", filterType: "BestCase", aaLeft: "566", aaRight: "590", ntLeft: "1696", ntRight: "1770" },
  { key: "ctnnbip1", filterType: "SecondBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "hyou1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "trpv1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "krt16", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "s100a10", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "peli3", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tab1", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "syce2", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "drc1", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "zfand2b", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "napb", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ect2", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "rflna", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "armcx2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "adam15", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "znf699", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "ppp6r3", filterType: "BestCase", aaLeft: "768", aaRight: "792", ntLeft: "2302", ntRight: "2376" },
  { key: "vamp1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "nfasc", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gmfb", filterType: "SecondBest", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "syt4", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "mmp28", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ptpn21", filterType: "BestCase", aaLeft: "1074", aaRight: "1098", ntLeft: "3220", ntRight: "3294" },
  { key: "ccno", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "tmem263", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "adgrf1", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "kiaa1143", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ubac1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "mrgprx4", filterType: "ThirdBest", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "cbx4", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "c10orf71", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "sp8", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "sh3bgr", filterType: "SecondBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "fam240b", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "apobec3f", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "slitrk2", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "foxd4l3", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "hoxd3", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "pygo1", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "adh5", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "marveld1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "tk1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "mrps33", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "uchl1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "pnpla2", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "slc5a4", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "gk3p", filterType: "FailedAllFilters", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "cd82", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "st6galnac1", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "tcta", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "prkag3", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "slc17a3", filterType: "FailedAllFilters", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "rasgrp2", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "ankrd24", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "sh2d4a", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "rasal2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "hp1bp3", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "txndc12", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fgf8", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ehd2", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "c8orf82", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "zbed9", filterType: "FailedAllFilters", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "s100a5", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "rai1", filterType: "BestCase", aaLeft: "1472", aaRight: "1496", ntLeft: "4414", ntRight: "4488" },
  { key: "tmem254", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "zfp69", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rfwd3", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "prop1", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "saxo1", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "nicn1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "dennd4c", filterType: "BestCase", aaLeft: "1313", aaRight: "1337", ntLeft: "3937", ntRight: "4011" },
  { key: "septin7", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "tasl", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "adam22", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "lurap1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "plcb3", filterType: "BestCase", aaLeft: "887", aaRight: "911", ntLeft: "2659", ntRight: "2733" },
  { key: "megf8", filterType: "BestCase", aaLeft: "2303", aaRight: "2327", ntLeft: "6907", ntRight: "6981" },
  { key: "hsd17b13", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "dmwd", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "foxn1", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "golm1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "lemd1", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "asb13", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "ppp4c", filterType: "SecondBest", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "col4a4", filterType: "BestCase", aaLeft: "1247", aaRight: "1271", ntLeft: "3739", ntRight: "3813" },
  { key: "plekhb2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "dnah2", filterType: "BestCase", aaLeft: "903", aaRight: "927", ntLeft: "2707", ntRight: "2781" },
  { key: "ggact", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "gzmm", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "kcnk17", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "nrn1l", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ier3ip1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "gpr75", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "fam184a", filterType: "BestCase", aaLeft: "609", aaRight: "633", ntLeft: "1825", ntRight: "1899" },
  { key: "cela1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "rnd1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "lrrc9", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "dusp19", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "mtg2", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "dop1b", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "pycr1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "bmp2", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "znf292", filterType: "BestCase", aaLeft: "889", aaRight: "913", ntLeft: "2665", ntRight: "2739" },
  { key: "paqr4", filterType: "SecondBest", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "eif1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "prr36", filterType: "FailedAllFilters", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "oscar", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "zfy", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "noc3l", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "gage12f", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "otub1", filterType: "SecondBest", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "lsg1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "loc100505841", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "or5ar1", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "thsd7b", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "sgk1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cd1a", filterType: "FailedAllFilters", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "pycard", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "nktr", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "kera", filterType: "SecondBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "hhla1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "myot", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "krt74", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "or1s1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "dgcr6", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "pprc1", filterType: "BestCase", aaLeft: "1412", aaRight: "1436", ntLeft: "4234", ntRight: "4308" },
  { key: "rassf2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "c11orf98", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ebag9", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "rabif", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "camkmt", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "lrriq4", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "tex13a", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "mrnip", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "actn2", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "syk", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "ttc22", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ervv-2", filterType: "FailedAllFilters", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "plekha5", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "bola2b", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "znf587b", filterType: "FailedAllFilters", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "gpr42", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "nifk", filterType: "FailedAllFilters", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "gnrhr", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "esrrb", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "nr4a3", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "mpzl1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ilf3", filterType: "BestCase", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "rpsap58", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ctss", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "ms4a15", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "hmgn2", filterType: "FourthBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "trpm8", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "ss18l1", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "adamts1", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "c2orf81", filterType: "FailedAllFilters", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "stc2", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "hprt1", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "chrng", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "pam16", filterType: "SecondBest", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "bcat2", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "mia3", filterType: "BestCase", aaLeft: "1491", aaRight: "1515", ntLeft: "4471", ntRight: "4545" },
  { key: "zdhhc4", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "stard5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "wfdc8", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "serpina6", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "btc", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "csad", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "c4bpb", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "srpx2", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "pcdhac2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "nphp3", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "sclt1", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "zswim9", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "cacng7", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "cd3g", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "mapk8", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "loc728743", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "gabra4", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ap4s1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ptrhd1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bmpr1b", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "rundc1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "or4n4", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "hnf1b", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "slc4a1", filterType: "BestCase", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "kifc1", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "fgf21", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "actr8", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "cxorf51b", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ttc33", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "tead2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "vtcn1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "b3gnt3", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "il1r1", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "dock8", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "slc16a6", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "pop4", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "bag1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "grin3a", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "rnf7", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rwdd1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "gorasp1", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "ppm1e", filterType: "BestCase", aaLeft: "637", aaRight: "661", ntLeft: "1909", ntRight: "1983" },
  { key: "st6gal2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "dnai3", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "shank2", filterType: "BestCase", aaLeft: "1125", aaRight: "1149", ntLeft: "3373", ntRight: "3447" },
  { key: "glud1", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "cyp4f22", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "or10g9", filterType: "FailedAllFilters", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "sult6b1", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "arrb2", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "gosr1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "fgf16", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "rgl1", filterType: "BestCase", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "comtd1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "calhm1", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "mroh9", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "maged4b", filterType: "FailedAllFilters", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "taf5", filterType: "SecondBest", aaLeft: "735", aaRight: "759", ntLeft: "2203", ntRight: "2277" },
  { key: "tchhl1", filterType: "FailedAllFilters", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "dmkn", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "adra1b", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "ppp1r12a", filterType: "BestCase", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "pard6a", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "zscan4", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "gabrb3", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "cfc1b", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "znf283", filterType: "BestCase", aaLeft: "507", aaRight: "531", ntLeft: "1519", ntRight: "1593" },
  { key: "rbp5", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "icam2", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "serpinb1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "garin3", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "ankrd35", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "glipr1", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "rpap1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "lipm", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "pogk", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "tex2", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "nt5c3a", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "clcn5", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "dpep3", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "tbx21", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "lypla1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "cd93", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "rock1", filterType: "BestCase", aaLeft: "1257", aaRight: "1281", ntLeft: "3769", ntRight: "3843" },
  { key: "dse", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "stk26", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "ednrb", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "kiaa0895", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mrps15", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "loc102724594", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "polr2h", filterType: "SecondBest", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "ndrg2", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "nlrp10", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "igbp1p2", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "adam33", filterType: "BestCase", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "poldip3", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "cnnm3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "zscan12", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "or51b4", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "tmem245", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "adora3", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "dennd5b", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "c1orf226", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "zscan25", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "tmem272", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "pip5k1b", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "cenpb", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "fam47a", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "e2f4", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "sntn", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "tcfl5", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "znf397", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "cort", filterType: "ThirdBest", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "rasl11b", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "gfap", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "pkn3", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "fam237a", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mrpl18", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "znf688", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "sipa1l3", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "urad", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ceacam3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mylpf", filterType: "SecondBest", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "scnn1a", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "laptm5", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "stk11", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "sirpd", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "tlr4", filterType: "FailedAllFilters", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "pip4p2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "or4a16", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "mrps22", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "cyth3", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "myc", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "tmsb15a", filterType: "FourthBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "serpina11", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "col5a1", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "ywhag", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "kcnj12", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "cct8l2", filterType: "FailedAllFilters", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "ndufb2", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "mapt", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "nmral1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "kpna2", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "rnf31", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "metap1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gpr31", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "h2bk1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "gne", filterType: "SecondBest", aaLeft: "678", aaRight: "702", ntLeft: "2032", ntRight: "2106" },
  { key: "traf2", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "nudt17", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "cep192", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "snap23", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "rnf216", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "raly", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "pifo", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "zic3", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "tars1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "mtrfr", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "gsn", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "hebp1", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "edem3", filterType: "BestCase", aaLeft: "817", aaRight: "841", ntLeft: "2449", ntRight: "2523" },
  { key: "rhex", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ap3s1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "arhgef4", filterType: "FailedAllFilters", aaLeft: "1432", aaRight: "1456", ntLeft: "4294", ntRight: "4368" },
  { key: "slc3a1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "cryga", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "krt9", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "pdzd8", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "spef2", filterType: "BestCase", aaLeft: "1645", aaRight: "1669", ntLeft: "4933", ntRight: "5007" },
  { key: "tbc1d3b", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "lrch3", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "cyp4a22", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "vit", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "wasf1", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "dnaaf1", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "nrbp2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "sult1b1", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "acin1", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "rsad2", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "naa10", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "enpp5", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "gpi", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "oca2", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "cacnb2", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "ppp1r14c", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "znf583", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "kel", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "cdx1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "mafg", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "tubal3", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "foxi2", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "mettl17", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "plxnc1", filterType: "BestCase", aaLeft: "772", aaRight: "796", ntLeft: "2314", ntRight: "2388" },
  { key: "hnf4g", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "acsl4", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "steap1", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "or4k1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ndufa5", filterType: "FourthBest", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "snx8", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "nxph1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "blm", filterType: "BestCase", aaLeft: "1160", aaRight: "1184", ntLeft: "3478", ntRight: "3552" },
  { key: "ippk", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "alg3", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "cnot9", filterType: "SecondBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ebp", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "tmem179", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hagh", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "adam7", filterType: "FailedAllFilters", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "celf2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "crybg2", filterType: "BestCase", aaLeft: "1081", aaRight: "1105", ntLeft: "3241", ntRight: "3315" },
  { key: "mocs3", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "dbndd2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "cln8", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "aspa", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "or4f21", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "meis3", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "adamts10", filterType: "BestCase", aaLeft: "1018", aaRight: "1042", ntLeft: "3052", ntRight: "3126" },
  { key: "cc2d2b", filterType: "FailedAllFilters", aaLeft: "653", aaRight: "677", ntLeft: "1957", ntRight: "2031" },
  { key: "uso1", filterType: "SecondBest", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "lcn8", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "lrpprc", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cldn5", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "slco2b1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "il1rap", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "extl1", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "tbx2", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "rap1gds1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "hdhd3", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "or56b4", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "rpl22l1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "fdxr", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "slc22a11", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "dnajc11", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "parp9", filterType: "FailedAllFilters", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "loc102723451", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "phpt1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "knl1", filterType: "FailedAllFilters", aaLeft: "1148", aaRight: "1172", ntLeft: "3442", ntRight: "3516" },
  { key: "pcdhgb6", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "gas2l3", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "prickle2", filterType: "BestCase", aaLeft: "615", aaRight: "639", ntLeft: "1843", ntRight: "1917" },
  { key: "odad2", filterType: "BestCase", aaLeft: "668", aaRight: "692", ntLeft: "2002", ntRight: "2076" },
  { key: "dct", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ankrd60", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "alpl", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "ddrgk1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "nipa1", filterType: "SecondBest", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "rnft1", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "b3galnt2", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "lcn15", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "pcdhga1", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "tmed8", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "setd5", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "cdon", filterType: "FailedAllFilters", aaLeft: "944", aaRight: "968", ntLeft: "2830", ntRight: "2904" },
  { key: "txnl4a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cela2b", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "or56a3", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "serinc5", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "znf497", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "m1ap", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "tmem167a", filterType: "FourthBest", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "prkca", filterType: "BestCase", aaLeft: "617", aaRight: "641", ntLeft: "1849", ntRight: "1923" },
  { key: "abcg8", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "scrn1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "rnf148", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "drd2", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "srsf5", filterType: "SecondBest", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "wdr5b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "col2a1", filterType: "BestCase", aaLeft: "1328", aaRight: "1352", ntLeft: "3982", ntRight: "4056" },
  { key: "tmem210", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "erlin2", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "or51g1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ptges3l", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "svbp", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lrrc37b", filterType: "FailedAllFilters", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "or4f16", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "c16orf90", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "adgra2", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "krtap4-12", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "olfml2b", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "efcab10", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "rps8", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "znf177", filterType: "FailedAllFilters", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "hps1", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "pdgfc", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "rnf113a", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "znf468", filterType: "FailedAllFilters", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "dync1i1", filterType: "SecondBest", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "fam219a", filterType: "SecondBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "cfap53", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "opcml", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "bean1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "slc9b1", filterType: "FailedAllFilters", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "amt", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "tmem9b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "c9", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ccdc85c", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "gnb3", filterType: "SecondBest", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "znf615", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "sgtb", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "brdt", filterType: "BestCase", aaLeft: "526", aaRight: "550", ntLeft: "1576", ntRight: "1650" },
  { key: "med26", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "saraf", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "gmnn", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "epb41l2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "catip", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "scube2", filterType: "BestCase", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "snx31", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "vmp1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "rasgrf2", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "cpt1c", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "gast", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "mme", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "eef2k", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "pcdhb7", filterType: "FailedAllFilters", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "krtcap3", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "sptbn4", filterType: "BestCase", aaLeft: "2196", aaRight: "2220", ntLeft: "6586", ntRight: "6660" },
  { key: "cd40lg", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "mat2a", filterType: "SecondBest", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "iqck", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "hus1b", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "zfyve9", filterType: "BestCase", aaLeft: "1256", aaRight: "1280", ntLeft: "3766", ntRight: "3840" },
  { key: "thra", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "magt1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "agap9", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "rab40a", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "lrrc8c", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "rpl10a", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "gnai1", filterType: "SecondBest", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "znf622", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "med11", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "trmo", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fam120b", filterType: "FailedAllFilters", aaLeft: "835", aaRight: "859", ntLeft: "2503", ntRight: "2577" },
  { key: "tle5", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "rnf180", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ubl7", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "h4c14", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "eif3f", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "tnrc18", filterType: "BestCase", aaLeft: "1206", aaRight: "1230", ntLeft: "3616", ntRight: "3690" },
  { key: "traf3ip1", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "ddx11", filterType: "BestCase", aaLeft: "758", aaRight: "782", ntLeft: "2272", ntRight: "2346" },
  { key: "cspp1", filterType: "BestCase", aaLeft: "827", aaRight: "851", ntLeft: "2479", ntRight: "2553" },
  { key: "ppial4c", filterType: "FourthBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "hla-drb5", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "tdrp", filterType: "FailedAllFilters", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "slc9a6", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "hs3st2", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "lrp5", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "hddc2", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "tmem200b", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "copa", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "znf140", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "rpl41", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fuz", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "cbfa2t3", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "ddb1", filterType: "SecondBest", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "cdc42se1", filterType: "SecondBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "cacna1e", filterType: "BestCase", aaLeft: "990", aaRight: "1014", ntLeft: "2968", ntRight: "3042" },
  { key: "ca11", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ar", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "otof", filterType: "BestCase", aaLeft: "1247", aaRight: "1271", ntLeft: "3739", ntRight: "3813" },
  { key: "ctage15", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "cmc4", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ndufs1", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "ano1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "rab15", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "rsu1", filterType: "SecondBest", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "rap1a", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "tbce", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "oprk1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "or6c3", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "casp2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tf", filterType: "BestCase", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "atp13a1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ttc21b", filterType: "BestCase", aaLeft: "1244", aaRight: "1268", ntLeft: "3730", ntRight: "3804" },
  { key: "trim27", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "pfn4", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "tex38", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "znf701", filterType: "FailedAllFilters", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "lrrc10", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "or1e1", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "pde8b", filterType: "BestCase", aaLeft: "746", aaRight: "770", ntLeft: "2236", ntRight: "2310" },
  { key: "map4k3", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "ap3b2", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "tmem186", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ccl7", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "arhgef16", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "exoc8", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "ccdc166", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "samhd1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "cav1", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "rpp25", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "pcmtd1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "rmdn2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "pak1", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "lef1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "myog", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "rps6ka2", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "cox18", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "nrg2", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "klhdc10", filterType: "SecondBest", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "setsip", filterType: "FourthBest", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "rwdd2b", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "tuba3c", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "smyd3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lrrc27", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "siglec11", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "c20orf85", filterType: "FourthBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "rtp4", filterType: "FailedAllFilters", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "mbd3l1", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "plscr1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "scn4b", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "zzz3", filterType: "BestCase", aaLeft: "439", aaRight: "463", ntLeft: "1315", ntRight: "1389" },
  { key: "sds", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "trim10", filterType: "ThirdBest", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "aimp1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "trank1", filterType: "BestCase", aaLeft: "1165", aaRight: "1189", ntLeft: "3493", ntRight: "3567" },
  { key: "znf736", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "tmco6", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fam171a1", filterType: "BestCase", aaLeft: "839", aaRight: "863", ntLeft: "2515", ntRight: "2589" },
  { key: "zfyve27", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "topbp1", filterType: "BestCase", aaLeft: "1173", aaRight: "1197", ntLeft: "3517", ntRight: "3591" },
  { key: "gfi1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "stau1", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "kctd17", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "chfr", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "smim32", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "psd4", filterType: "BestCase", aaLeft: "741", aaRight: "765", ntLeft: "2221", ntRight: "2295" },
  { key: "scarf2", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "krtap5-11", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "troap", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "il26", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "iqca1l", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cd160", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "znf677", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "nbr1", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "cxcl13", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "nfrkb", filterType: "BestCase", aaLeft: "808", aaRight: "832", ntLeft: "2422", ntRight: "2496" },
  { key: "catsper4", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "krtap4-6", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gtf3c2", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "pif1", filterType: "BestCase", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "insl6", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "kras", filterType: "SecondBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "bcap31", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "rpl14", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "adss1", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "asnsd1", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "slc26a3", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "lsm10", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "hsfx3", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "cdk11b", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "dctn5", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "itga9", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "idh3g", filterType: "SecondBest", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "dgki", filterType: "BestCase", aaLeft: "886", aaRight: "910", ntLeft: "2656", ntRight: "2730" },
  { key: "rpl23", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "lpar3", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "sapcd2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "ly6h", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "psmb8", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "prrg3", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "mplkip", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "stpg4", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ei24", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "nlrp2", filterType: "FailedAllFilters", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "gcdh", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "chtop", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "c1qtnf4", filterType: "FailedAllFilters", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "gfra3", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "bfsp2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tas2r38", filterType: "FailedAllFilters", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "atp5mc1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "paox", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "p2rx1", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "plod3", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "nceh1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "capn5", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "dcakd", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "prdm8", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "hs2st1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "or7g2", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "rps19", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "kctd20", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "plg", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "fam83g", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "psg3", filterType: "FailedAllFilters", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "get1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "il11", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "treml4", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "eef1b2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "yipf3", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "defb127", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "selenok", filterType: "SecondBest", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "dydc1", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "gpbp1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "c9orf85", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "igsf22", filterType: "BestCase", aaLeft: "1174", aaRight: "1198", ntLeft: "3520", ntRight: "3594" },
  { key: "vps16", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "c19orf81", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ltbp3", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "spink2", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "sec61a1", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "ebf3", filterType: "SecondBest", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "obscn", filterType: "BestCase", aaLeft: "4358", aaRight: "4382", ntLeft: "13072", ntRight: "13146" },
  { key: "slc22a8", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "hey1", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "nat8", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "lrrc72", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "tmem54", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "chd9", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "trim45", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "map3k3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "prss33", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "hyal2", filterType: "BestCase", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "thoc2", filterType: "BestCase", aaLeft: "1330", aaRight: "1354", ntLeft: "3988", ntRight: "4062" },
  { key: "pitx1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "anpep", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "znf763", filterType: "FailedAllFilters", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "inafm1", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "cpa1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "kcnh4", filterType: "BestCase", aaLeft: "960", aaRight: "984", ntLeft: "2878", ntRight: "2952" },
  { key: "hspa14", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "ninj2", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "ldhd", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "atp6v1e1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "myh11", filterType: "BestCase", aaLeft: "1755", aaRight: "1779", ntLeft: "5263", ntRight: "5337" },
  { key: "b4galnt1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "kcnk3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "larp7", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "ahrr", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "limk2", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "fads6", filterType: "FailedAllFilters", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "igf1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "gpx3", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "reg4", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "trim72", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "tbkbp1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "uck1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "kdm5a", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "c1qtnf9b", filterType: "FailedAllFilters", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "rad9b", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "ifitm10", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "ctxnd1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gpr153", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "pabir1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nell2", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "dnajb7", filterType: "FailedAllFilters", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "pet100", filterType: "FourthBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ndst2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "trpc6", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "supt20hl1", filterType: "FailedAllFilters", aaLeft: "676", aaRight: "700", ntLeft: "2026", ntRight: "2100" },
  { key: "gtf2a1", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ggh", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "znf529", filterType: "FailedAllFilters", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "kcnmb4", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "or10k1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "lrrc45", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "defb110", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "htr3b", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "ccdc73", filterType: "FailedAllFilters", aaLeft: "973", aaRight: "997", ntLeft: "2917", ntRight: "2991" },
  { key: "or52k2", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "xcr1", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "nccrp1", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "dpy19l4", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "fam189a1", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "unc5c", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "syt9", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "cacna2d3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "adam18", filterType: "FailedAllFilters", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "sema5a", filterType: "BestCase", aaLeft: "904", aaRight: "928", ntLeft: "2710", ntRight: "2784" },
  { key: "pramef1", filterType: "FailedAllFilters", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "zmat4", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "mmp25", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "bbc3", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "rpf2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "sinhcaf", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "gtdc1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "sp5", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "or2v2", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "pla2g2e", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "stradb", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "tfcp2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "prmt6", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "mecom", filterType: "BestCase", aaLeft: "889", aaRight: "913", ntLeft: "2665", ntRight: "2739" },
  { key: "dhrs2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "nr0b2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "dnah12", filterType: "BestCase", aaLeft: "2208", aaRight: "2232", ntLeft: "6622", ntRight: "6696" },
  { key: "p3h2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "zhx2", filterType: "FailedAllFilters", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "hspb7", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "dmc1", filterType: "SecondBest", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "lpcat2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slc5a9", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "comt", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "cyp2u1", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "cgref1", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "naalad2", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "pbx2", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "acp6", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "synpr", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "chst11", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ankrd29", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "rcn1", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "btbd11", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "gemin6", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "pla2g1b", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "pik3r3", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "serf1b", filterType: "FourthBest", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "adprh", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "fgf5", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "bcl2l15", filterType: "FailedAllFilters", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "s100a8", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "cnbd1", filterType: "FailedAllFilters", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "mmp12", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "zbed4", filterType: "FailedAllFilters", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "tmem259", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "slc26a11", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "atp11b", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "zfp64", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "mfap5", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ankrd20a1", filterType: "FailedAllFilters", aaLeft: "667", aaRight: "691", ntLeft: "1999", ntRight: "2073" },
  { key: "sphk1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "atp6v1f", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "abcc1", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "tsen34", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "polr3d", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "ifi6", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "aktip", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "nop16", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "mrpl33", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "c3orf52", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "lce2c", filterType: "ThirdBest", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "pum1", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "wdr88", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "rnf19b", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "or8d1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "dcbld2", filterType: "BestCase", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "zbtb7b", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "adamtsl5", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "kctd2", filterType: "SecondBest", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "kif2b", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "jakmip1", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "fggy", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "retreg3", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "arhgap42", filterType: "BestCase", aaLeft: "799", aaRight: "823", ntLeft: "2395", ntRight: "2469" },
  { key: "plin1", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "sh2b3", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "gpr78", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "h3c11", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rbl2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ppp1cc", filterType: "SecondBest", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "tmem107", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "dusp14", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lrrc4", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "cuedc1", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "hesx1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "coq3", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "pabpc4l", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "negr1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "h2ax", filterType: "SecondBest", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mfsd9", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "paqr9", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "vsig10l", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "frmd4b", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "lamc2", filterType: "BestCase", aaLeft: "1111", aaRight: "1135", ntLeft: "3331", ntRight: "3405" },
  { key: "lrfn2", filterType: "BestCase", aaLeft: "628", aaRight: "652", ntLeft: "1882", ntRight: "1956" },
  { key: "clic6", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "cggbp1", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "or6k2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "dbn1", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "mnx1", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "rgs1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "armc3", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "krt79", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "dusp23", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "spata7", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "lime1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "klhl1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ccl4l2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "eps15", filterType: "BestCase", aaLeft: "821", aaRight: "845", ntLeft: "2461", ntRight: "2535" },
  { key: "ergic2", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "spdye17", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "kiz", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "ap1s2", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "kiaa1210", filterType: "FailedAllFilters", aaLeft: "1338", aaRight: "1362", ntLeft: "4012", ntRight: "4086" },
  { key: "samd4a", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "map1s", filterType: "BestCase", aaLeft: "800", aaRight: "824", ntLeft: "2398", ntRight: "2472" },
  { key: "slc39a10", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "plekha8", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "crtc1", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "ramp3", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "slc1a2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tmem130", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "dmtn", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "lce1d", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "atf2", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "kiaa0513", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "tmem80", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "clec1a", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "trpm5", filterType: "BestCase", aaLeft: "700", aaRight: "724", ntLeft: "2098", ntRight: "2172" },
  { key: "slc13a5", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "acbd4", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "oxct1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "lox", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "atg2a", filterType: "BestCase", aaLeft: "905", aaRight: "929", ntLeft: "2713", ntRight: "2787" },
  { key: "khdc1", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "map4", filterType: "FailedAllFilters", aaLeft: "1145", aaRight: "1169", ntLeft: "3433", ntRight: "3507" },
  { key: "hspg2", filterType: "BestCase", aaLeft: "949", aaRight: "973", ntLeft: "2845", ntRight: "2919" },
  { key: "ppp4r3c", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "fam217b", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "wac", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "mex3b", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "znf805", filterType: "FailedAllFilters", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "arid1b", filterType: "BestCase", aaLeft: "1881", aaRight: "1905", ntLeft: "5641", ntRight: "5715" },
  { key: "usp1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "hexim1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "hjv", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bcl7b", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "clspn", filterType: "BestCase", aaLeft: "916", aaRight: "940", ntLeft: "2746", ntRight: "2820" },
  { key: "pglyrp1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "rdh11", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "cited4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fitm2", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "rab5b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sltm", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "loc344065", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "nutm2e", filterType: "FailedAllFilters", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "st8sia4", filterType: "SecondBest", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "tgfbr2", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "unc119b", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "actl10", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "tor1aip1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "prag1", filterType: "BestCase", aaLeft: "1059", aaRight: "1083", ntLeft: "3175", ntRight: "3249" },
  { key: "sema3c", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "scamp2", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "or5d16", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "myh7", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "carf", filterType: "BestCase", aaLeft: "693", aaRight: "717", ntLeft: "2077", ntRight: "2151" },
  { key: "igfbp1", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "h4-16", filterType: "SecondBest", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "rad51c", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "uba3", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "proz", filterType: "FailedAllFilters", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "spag5", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "mast3", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "or4c15", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "csf1r", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "tfdp2", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "atic", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "pga3", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "kxd1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pex2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "epg5", filterType: "BestCase", aaLeft: "726", aaRight: "750", ntLeft: "2176", ntRight: "2250" },
  { key: "emc2", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "defb1", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "mrpl51", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "prpf38b", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "ptprb", filterType: "BestCase", aaLeft: "634", aaRight: "658", ntLeft: "1900", ntRight: "1974" },
  { key: "zfp69b", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "apobec4", filterType: "FailedAllFilters", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "mc2r", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "pnma6e", filterType: "FailedAllFilters", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "slc2a9", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "pdf", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "cxadr", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "krtap19-4", filterType: "ThirdBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "aoc1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "c17orf113", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "sertad3", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "fez1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "wdr5", filterType: "SecondBest", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "il18r1", filterType: "FailedAllFilters", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "commd4", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "pbxip1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cracdl", filterType: "BestCase", aaLeft: "837", aaRight: "861", ntLeft: "2509", ntRight: "2583" },
  { key: "pdgfrb", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "xkr5", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "plac1", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "prdx2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "zxdb", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "scimp", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "nol10", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "ubqlnl", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "tpm2", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "morf4l2", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "pfdn1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pla2g12a", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "sord", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "plek", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "mrtfb", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "prm1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "mtarc2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "arhgap20", filterType: "BestCase", aaLeft: "989", aaRight: "1013", ntLeft: "2965", ntRight: "3039" },
  { key: "fbxl5", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "slc39a4", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ccdc185", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "fdx2", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tmem165", filterType: "SecondBest", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "prame", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "pde6a", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "clec4d", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "szt2", filterType: "BestCase", aaLeft: "1179", aaRight: "1203", ntLeft: "3535", ntRight: "3609" },
  { key: "klrk1", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "lce4a", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "xrra1", filterType: "BestCase", aaLeft: "749", aaRight: "773", ntLeft: "2245", ntRight: "2319" },
  { key: "mapkapk5", filterType: "SecondBest", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "ifi27l1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "krt33a", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "gimap1", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "lnx2", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "or8b3", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "wfdc11", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fam205a", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "c7orf61", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "gdpd3", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "slc5a10", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "hgh1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "atg4c", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "emc10", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "taok1", filterType: "BestCase", aaLeft: "820", aaRight: "844", ntLeft: "2458", ntRight: "2532" },
  { key: "arhgap17", filterType: "BestCase", aaLeft: "767", aaRight: "791", ntLeft: "2299", ntRight: "2373" },
  { key: "fbxo2", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "camk2n2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c1orf131", filterType: "FailedAllFilters", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "podxl2", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "micos10", filterType: "FourthBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "cops9", filterType: "FourthBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "sidt2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "atp2b1", filterType: "BestCase", aaLeft: "1115", aaRight: "1139", ntLeft: "3343", ntRight: "3417" },
  { key: "lgr4", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "mms19", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mt1b", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cilp", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "kcnc2", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "ing1", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "alx3", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "pax9", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "scly", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "aurkaip1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "lrrc75b", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "stx6", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "pigc", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ppwd1", filterType: "BestCase", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "xage1b", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc6a2", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "ascl3", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "pnma3", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "or4f5", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "srrt", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "prkd1", filterType: "BestCase", aaLeft: "882", aaRight: "906", ntLeft: "2644", ntRight: "2718" },
  { key: "krtap13-3", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "gna11", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "pdk2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dpyd", filterType: "BestCase", aaLeft: "889", aaRight: "913", ntLeft: "2665", ntRight: "2739" },
  { key: "elp2", filterType: "BestCase", aaLeft: "726", aaRight: "750", ntLeft: "2176", ntRight: "2250" },
  { key: "znf557", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "rbm14", filterType: "SecondBest", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "agpat3", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "spock3", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "tnfaip8", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "clec18c", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "shisa7", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "ube2z", filterType: "SecondBest", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "stxbp6", filterType: "SecondBest", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ttll9", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "carhsp1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "fcgr2b", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "actg2", filterType: "SecondBest", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "brd4", filterType: "BestCase", aaLeft: "1067", aaRight: "1091", ntLeft: "3199", ntRight: "3273" },
  { key: "cyb561a3", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "mx1", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "epb41", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "eif1ad", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "slc25a41", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "snrpa", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "znf235", filterType: "FailedAllFilters", aaLeft: "714", aaRight: "738", ntLeft: "2140", ntRight: "2214" },
  { key: "nme4", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "lmcd1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "fibp", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "rtl3", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "tmem50b", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ctrl", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "lsm6", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "lgals1", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "epha2", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "cdk9", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "grifin", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "cd8a", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "rbpms", filterType: "SecondBest", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "errfi1", filterType: "FailedAllFilters", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "poteb", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "fam162b", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "cdc25a", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "kcp", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "alox15", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "il17d", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "krtap29-1", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "l2hgdh", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "bves", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cltc", filterType: "BestCase", aaLeft: "874", aaRight: "898", ntLeft: "2620", ntRight: "2694" },
  { key: "znf202", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "rbm23", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "efhc1", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "sestd1", filterType: "SecondBest", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "adcy2", filterType: "BestCase", aaLeft: "668", aaRight: "692", ntLeft: "2002", ntRight: "2076" },
  { key: "rtn4r", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "slc35f5", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "hnrnpul1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "arl11", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "slc16a13", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "or4e2", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "fnip2", filterType: "FailedAllFilters", aaLeft: "1023", aaRight: "1047", ntLeft: "3067", ntRight: "3141" },
  { key: "cblif", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "sim2", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "ugt1a4", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "sfxn5", filterType: "SecondBest", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "foxg1", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "abitram", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "htr6", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "gca", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "znf560", filterType: "FailedAllFilters", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "ajm1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "slx1b", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "gadd45g", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "arl8b", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "cyp2a7", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "prodh2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "chrm3", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cbr3", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "mul1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "fam170a", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "slfn13", filterType: "FailedAllFilters", aaLeft: "762", aaRight: "786", ntLeft: "2284", ntRight: "2358" },
  { key: "rpl8", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "ovch1", filterType: "FailedAllFilters", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "fbxo31", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "foxl2nb", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "bptf", filterType: "BestCase", aaLeft: "2656", aaRight: "2680", ntLeft: "7966", ntRight: "8040" },
  { key: "hs6st1", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "zc2hc1c", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "cmpk1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "usp53", filterType: "FailedAllFilters", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "ints6", filterType: "BestCase", aaLeft: "704", aaRight: "728", ntLeft: "2110", ntRight: "2184" },
  { key: "csnk1g3", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "srgap2b", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "pou3f4", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "galt", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "hdac1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "prdm16", filterType: "BestCase", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "cpsf2", filterType: "SecondBest", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "vmo1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "znf443", filterType: "FailedAllFilters", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "tas2r46", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "hipk3", filterType: "BestCase", aaLeft: "1016", aaRight: "1040", ntLeft: "3046", ntRight: "3120" },
  { key: "dab2", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "cplx3", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "rbmxl1", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "carm1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fabp9", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "mtnr1b", filterType: "FourthBest", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "slc6a15", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "znf474", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "lad1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "nsun4", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "ticam2", filterType: "FailedAllFilters", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "akap8", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "s100a7a", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "hnrnph2", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "polm", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "irs1", filterType: "BestCase", aaLeft: "1109", aaRight: "1133", ntLeft: "3325", ntRight: "3399" },
  { key: "aph1b", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "pmvk", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "bcan", filterType: "BestCase", aaLeft: "861", aaRight: "885", ntLeft: "2581", ntRight: "2655" },
  { key: "psmf1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ncapd2", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "ankrd1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "klf8", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "znf316", filterType: "FailedAllFilters", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "mchr1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "or51i2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "twnk", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "col25a1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "naca2", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tmprss2", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "rrh", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "dusp1", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "rgs12", filterType: "BestCase", aaLeft: "1298", aaRight: "1322", ntLeft: "3892", ntRight: "3966" },
  { key: "dpp7", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "synrg", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "tagln2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "c18orf21", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "znf609", filterType: "BestCase", aaLeft: "1070", aaRight: "1094", ntLeft: "3208", ntRight: "3282" },
  { key: "cyp2a13", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "chmp5", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "ptx3", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "lrit3", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "sec14l3", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "slc66a3", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "atp8a1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "cib3", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "itch", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "krt86", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "vwf", filterType: "BestCase", aaLeft: "2551", aaRight: "2575", ntLeft: "7651", ntRight: "7725" },
  { key: "klhl26", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "zfhx4", filterType: "BestCase", aaLeft: "1839", aaRight: "1863", ntLeft: "5515", ntRight: "5589" },
  { key: "jdp2", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "radx", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cttn", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "spint2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "timmdc1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "il12a", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "gngt2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "cdc37l1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "heatr3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "znf257", filterType: "FailedAllFilters", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "antxr1", filterType: "SecondBest", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "pck2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "slc25a23", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "agbl5", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "neil1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ak6", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "gdi2", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "otol1", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "c2orf73", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "cdh22", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "rp1l1", filterType: "FailedAllFilters", aaLeft: "895", aaRight: "919", ntLeft: "2683", ntRight: "2757" },
  { key: "cmtm8", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "nav3", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "lrrc59", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "cdh15", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ptcd3", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "mical2", filterType: "BestCase", aaLeft: "817", aaRight: "841", ntLeft: "2449", ntRight: "2523" },
  { key: "zc3hc1", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "eno1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "chst9", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "manf", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "znf502", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "wdr77", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "slc30a8", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "katnb1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "foxa3", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "mcat", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "dio2", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "rbm41", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "gpr87", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "iqca1", filterType: "BestCase", aaLeft: "648", aaRight: "672", ntLeft: "1942", ntRight: "2016" },
  { key: "she", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "erich4", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "ltn1", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "tdrd3", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "eif3cl", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "c12orf40", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "enthd1", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "arl6ip6", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "bbs10", filterType: "FailedAllFilters", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "vps37b", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "gpa33", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "nudt6", filterType: "FourthBest", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "ap1b1", filterType: "BestCase", aaLeft: "600", aaRight: "624", ntLeft: "1798", ntRight: "1872" },
  { key: "cyyr1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "med9", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "znf260", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "erbb4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc25a14", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "fem1a", filterType: "FailedAllFilters", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "terb2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "tollip", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "klhl11", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "thap6", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "trim59", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "tshb", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "tcp11", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "smarce1", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "pcdhb13", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "sirt3", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "tex46", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ikzf3", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "a4gnt", filterType: "FailedAllFilters", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "fdps", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "eif4h", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "znf385b", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "inppl1", filterType: "BestCase", aaLeft: "1061", aaRight: "1085", ntLeft: "3181", ntRight: "3255" },
  { key: "cd70", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "caps", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ubqln2", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "h1-5", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "alkbh7", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "usp31", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "acadsb", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "tnpo1", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "tbc1d20", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "rbms1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "birc6", filterType: "BestCase", aaLeft: "3724", aaRight: "3748", ntLeft: "11170", ntRight: "11244" },
  { key: "psmc4", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "csnk1a1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "astn1", filterType: "BestCase", aaLeft: "689", aaRight: "713", ntLeft: "2065", ntRight: "2139" },
  { key: "znf343", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "timm13", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "smim19", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c6orf132", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "ddx4", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tomm7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "gpat3", filterType: "BestCase", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "mtmr9", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "slc19a2", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "mindy4b", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "nek3", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "grik1", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "csrp2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "grpel2", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "krtap10-8", filterType: "FailedAllFilters", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "ctc1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "cnp", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "brwd1", filterType: "BestCase", aaLeft: "1429", aaRight: "1453", ntLeft: "4285", ntRight: "4359" },
  { key: "prep", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "nono", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ndufa11", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "golga1", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "ttll13", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "efna2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "dok3", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "lzts2", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "znf280d", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "vps8", filterType: "BestCase", aaLeft: "1021", aaRight: "1045", ntLeft: "3061", ntRight: "3135" },
  { key: "c6orf15", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "pjvk", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "asns", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "hla-dpa1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "becn2", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "ppie", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "terf2ip", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "gprasp2", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "hla-c", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "krtap1-5", filterType: "FailedAllFilters", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "slc34a3", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "fxyd5", filterType: "FailedAllFilters", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "clcc1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "them6", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "nfyb", filterType: "SecondBest", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "nrap", filterType: "BestCase", aaLeft: "1700", aaRight: "1724", ntLeft: "5098", ntRight: "5172" },
  { key: "tbc1d17", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "or14i1", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "marchf4", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "ipo5", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "tfpi2", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "mier1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "nkg7", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "znf416", filterType: "FailedAllFilters", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "ddx58", filterType: "FailedAllFilters", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "suds3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fcrl1", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "smim7", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "chac2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "cdkn1a", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "cd47", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "sun3", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "cast", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "rtcb", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ube2e1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tas2r13", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "col22a1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "upf2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "upk1b", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "or6c68", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "elapor2", filterType: "BestCase", aaLeft: "784", aaRight: "808", ntLeft: "2350", ntRight: "2424" },
  { key: "rab27b", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "snta1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "cemip", filterType: "BestCase", aaLeft: "1238", aaRight: "1262", ntLeft: "3712", ntRight: "3786" },
  { key: "mbnl3", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "nkap", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "fam90a17", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "rtp1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "mbd3l4", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "siglec14", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "nefl", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "ccdc14", filterType: "FailedAllFilters", aaLeft: "824", aaRight: "848", ntLeft: "2470", ntRight: "2544" },
  { key: "twf1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "lamtor2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccdc154", filterType: "FailedAllFilters", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "pak4", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "fech", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "lactbl1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "il1rl1", filterType: "FailedAllFilters", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "homer1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "gip", filterType: "SecondBest", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tmco3", filterType: "BestCase", aaLeft: "653", aaRight: "677", ntLeft: "1957", ntRight: "2031" },
  { key: "rap2c", filterType: "SecondBest", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "fam131c", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "plscr4", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "trim15", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "esr1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "calm1", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "pkd2l1", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "atxn7l3", filterType: "SecondBest", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "ctse", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "casp7", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "prss54", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "or6c6", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "trim22", filterType: "FailedAllFilters", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "atp13a4", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "spanxn3", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "pfn1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "znf704", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "il5ra", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "ino80c", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "cep57", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "znrf2", filterType: "SecondBest", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "ndufs4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "kcnn3", filterType: "BestCase", aaLeft: "685", aaRight: "709", ntLeft: "2053", ntRight: "2127" },
  { key: "cmc1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "tnni3", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "jade1", filterType: "BestCase", aaLeft: "760", aaRight: "784", ntLeft: "2278", ntRight: "2352" },
  { key: "ano4", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "magea8", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "rab10", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tlcd3b", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "or52n2", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "bmper", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "ccl2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "guca1a", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "lman2l", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "son", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "elane", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "zc3h7b", filterType: "BestCase", aaLeft: "947", aaRight: "971", ntLeft: "2839", ntRight: "2913" },
  { key: "nat8l", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cxcl5", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "atg9b", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "loc102724652", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "atpaf1", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "tmem33", filterType: "SecondBest", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "lrrc15", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "znf579", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "c1galt1c1l", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "acox1", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "med14", filterType: "BestCase", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "cyp19a1", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "znf627", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "or7a5", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "rnf185", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "h4c11", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "adprs", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "trim51g", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "jun", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "stap1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ctnnal1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "pcdha5", filterType: "FailedAllFilters", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "h2ab2", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cyp7a1", filterType: "FailedAllFilters", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "pnma8c", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "fbxo28", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "wipi1", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "c15orf40", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "cad", filterType: "BestCase", aaLeft: "2104", aaRight: "2128", ntLeft: "6310", ntRight: "6384" },
  { key: "pdha2", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "ca14", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "eif3c", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "noto", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "rnmt", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "ppial4f", filterType: "FourthBest", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "rad51", filterType: "SecondBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cfap61", filterType: "BestCase", aaLeft: "856", aaRight: "880", ntLeft: "2566", ntRight: "2640" },
  { key: "pou6f2", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "hrc", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "slc9a3", filterType: "BestCase", aaLeft: "793", aaRight: "817", ntLeft: "2377", ntRight: "2451" },
  { key: "rhbdl1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ddx23", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "ccny", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "gfus", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "znf28", filterType: "FailedAllFilters", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "tgfb3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hps4", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "gid4", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "krtap3-3", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "gpm6b", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "phox2b", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "cybrd1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "satl1", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "aen", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "sptbn1", filterType: "BestCase", aaLeft: "1099", aaRight: "1123", ntLeft: "3295", ntRight: "3369" },
  { key: "n4bp3", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "pcdhb2", filterType: "FailedAllFilters", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "kiaa2013", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "iqcn", filterType: "FailedAllFilters", aaLeft: "1276", aaRight: "1300", ntLeft: "3826", ntRight: "3900" },
  { key: "aldh3a2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rab3gap2", filterType: "BestCase", aaLeft: "1218", aaRight: "1242", ntLeft: "3652", ntRight: "3726" },
  { key: "med23", filterType: "BestCase", aaLeft: "1336", aaRight: "1360", ntLeft: "4006", ntRight: "4080" },
  { key: "znf610", filterType: "FailedAllFilters", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "defb105a", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "hormad1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "gprin2", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "wrn", filterType: "FailedAllFilters", aaLeft: "710", aaRight: "734", ntLeft: "2128", ntRight: "2202" },
  { key: "cyp2c9", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "trim77", filterType: "FailedAllFilters", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "gpx6", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "dpcd", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "kdm5d", filterType: "BestCase", aaLeft: "1150", aaRight: "1174", ntLeft: "3448", ntRight: "3522" },
  { key: "frmd5", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "moxd1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "gpr156", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "vps35l", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "kcnk6", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "ndufv1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gnpat", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "otop3", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "grxcr2", filterType: "SecondBest", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "fads3", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "lgals3bp", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "ppp2r5b", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "cyb5rl", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "mrgprg", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "pigx", filterType: "FourthBest", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "dpy19l1", filterType: "FailedAllFilters", aaLeft: "708", aaRight: "732", ntLeft: "2122", ntRight: "2196" },
  { key: "nkrf", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "igsf10", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ccdc136", filterType: "BestCase", aaLeft: "768", aaRight: "792", ntLeft: "2302", ntRight: "2376" },
  { key: "dnajb2", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "yy1ap1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "dll3", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "tomm20l", filterType: "ThirdBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "wdr59", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "kcnmb1", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "trpc3", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "lrrc40", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "mylk4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "hcar2", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "c19orf84", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "lst1", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "ct83", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "spink7", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "tcn2", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "or10h3", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "tmem51", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "yipf6", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "il2ra", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ptger3", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "selenon", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "spesp1", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "cpa4", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "kcnh1", filterType: "BestCase", aaLeft: "781", aaRight: "805", ntLeft: "2341", ntRight: "2415" },
  { key: "nfe2l1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "spdya", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "exd3", filterType: "FailedAllFilters", aaLeft: "761", aaRight: "785", ntLeft: "2281", ntRight: "2355" },
  { key: "or4k14", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "ldha", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "prpf39", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "b4galnt4", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "fbxl14", filterType: "FailedAllFilters", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "mlxip", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "myh14", filterType: "BestCase", aaLeft: "660", aaRight: "684", ntLeft: "1978", ntRight: "2052" },
  { key: "clvs1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "trim40", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "scn1b", filterType: "SecondBest", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "map3k6", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "prss36", filterType: "BestCase", aaLeft: "811", aaRight: "835", ntLeft: "2431", ntRight: "2505" },
  { key: "znf766", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "thoc7", filterType: "SecondBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "gpr161", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "strap", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "aptx", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "ugt3a2", filterType: "FailedAllFilters", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "b3glct", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "nlrp7", filterType: "FailedAllFilters", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "znf438", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "fbxw12", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "c1qtnf1", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "eif5a", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "cd69", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "gp2", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "mis18bp1", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "slit3", filterType: "BestCase", aaLeft: "718", aaRight: "742", ntLeft: "2152", ntRight: "2226" },
  { key: "slc9a3r2", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "lpar6", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "letm1", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "usp28", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "rpl26", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "bbs9", filterType: "FailedAllFilters", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "stpg1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "fam83b", filterType: "FailedAllFilters", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "zfp82", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "psg6", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "otogl", filterType: "BestCase", aaLeft: "1226", aaRight: "1250", ntLeft: "3676", ntRight: "3750" },
  { key: "get4", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "ugt2b28", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "treml1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "ntf3", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "cutc", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "pgbd5", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "p2rx4", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "tbpl2", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "gng3", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "f9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "eif4enif1", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "slc49a4", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "rsc1a1", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "btbd8", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "pxt1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "afg3l2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tesmin", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "apoc3", filterType: "FourthBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "hpse2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf672", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "fut11", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "fndc8", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "gnptab", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "cxcl16", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "ct47a9", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "krtap4-3", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "rita1", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "eva1b", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "pes1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "catsper1", filterType: "FailedAllFilters", aaLeft: "662", aaRight: "686", ntLeft: "1984", ntRight: "2058" },
  { key: "kctd12", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "glis3", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "ndufa4l2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ermp1", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "znf780b", filterType: "FailedAllFilters", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "slc2a10", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "slc26a6", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "rpl11", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "clrn1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "cda", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "sstr5", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "msmp", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "idh3b", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ubap1", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "lmtk2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "ddx41", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "insr", filterType: "BestCase", aaLeft: "772", aaRight: "796", ntLeft: "2314", ntRight: "2388" },
  { key: "cyba", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "insl3", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "sdc3", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "cspg5", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "rnase8", filterType: "FourthBest", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "dstn", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "lrg1", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "zscan20", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "coq8b", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "map7d2", filterType: "BestCase", aaLeft: "642", aaRight: "666", ntLeft: "1924", ntRight: "1998" },
  { key: "atad1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "adgrg5", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "e2f1", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "parp16", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "csh2", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "des", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "ywhab", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ceacam6", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "thumpd3", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "scnn1d", filterType: "FailedAllFilters", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "cnih3", filterType: "SecondBest", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "or5l2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "tlr1", filterType: "FailedAllFilters", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "adi1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "sirpa", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "hsd11b2", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "btn3a3", filterType: "FailedAllFilters", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "mfsd12", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "mrps27", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "mcm9", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "slc12a8", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "ido2", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "cd96", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "or2t34", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "fam32a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ifna8", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ost4", filterType: "SecondBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "appl1", filterType: "BestCase", aaLeft: "668", aaRight: "692", ntLeft: "2002", ntRight: "2076" },
  { key: "mrps10", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "glo1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "gp1bb", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "rcan3", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "liph", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "plk3", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "irx2", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "wwc1", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "zcchc8", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "ryk", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "tmem240", filterType: "SecondBest", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "ttc9b", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "adgrd2", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "hecw2", filterType: "BestCase", aaLeft: "666", aaRight: "690", ntLeft: "1996", ntRight: "2070" },
  { key: "abcb8", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "polr2m", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "dpagt1", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "percc1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "hnrnpd", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "hfe", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "fhip2a", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "ntrk1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "casd1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "fam20b", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "begain", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "sar1b", filterType: "SecondBest", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "wdr91", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "ccndbp1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "agr3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fgf13", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "c2cd2l", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "myt1l", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "rwdd4", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "znf585a", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "helz2", filterType: "FailedAllFilters", aaLeft: "1107", aaRight: "1131", ntLeft: "3319", ntRight: "3393" },
  { key: "gpr61", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "eapp", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "haao", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "det1", filterType: "SecondBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "rbbp7", filterType: "SecondBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "grb10", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "mib1", filterType: "SecondBest", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "serpinb4", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "tada1", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "prr22", filterType: "FailedAllFilters", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "garin6", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "nphs1", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "calhm4", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "znf799", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "dsc1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "dlec1", filterType: "BestCase", aaLeft: "879", aaRight: "903", ntLeft: "2635", ntRight: "2709" },
  { key: "spns2", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "zscan1", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "tmem170b", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "ftmt", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "relch", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "itm2c", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "ogfr", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ube2a", filterType: "SecondBest", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "dnd1", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "cacng2", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "or5ap2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "gabra1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "cul3", filterType: "SecondBest", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "glra1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zdhhc1", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "n4bp2l2", filterType: "FailedAllFilters", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "naa40", filterType: "SecondBest", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "serpina3", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "fam169a", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "eid2", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "spaar", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "prr15", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ano10", filterType: "FailedAllFilters", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "nlgn1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ttc36", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "slc43a3", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "b3gnt6", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "mavs", filterType: "FailedAllFilters", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "pop1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "crybb2", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "ccdc89", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "fam186b", filterType: "FailedAllFilters", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "slc16a3", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "tmem129", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "atp23", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "rnf2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "bag4", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "foxl2", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "hras", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "slc4a4", filterType: "BestCase", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "hoxc5", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "lcp1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "npff", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "sirpb1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "znf492", filterType: "FailedAllFilters", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "dapp1", filterType: "SecondBest", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "slc22a23", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ubxn2a", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "qprt", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "sec63", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "herc5", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "ankrd65", filterType: "FailedAllFilters", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "alpi", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "slc38a9", filterType: "BestCase", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "trim5", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "clp1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ppp1r3e", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rbmx2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "spatc1l", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "pcdhga4", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "lcn10", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ston1", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "sod2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "dagla", filterType: "BestCase", aaLeft: "883", aaRight: "907", ntLeft: "2647", ntRight: "2721" },
  { key: "phaf1", filterType: "SecondBest", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "tmem215", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "matn3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "or2l2", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "cyb5r2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "calcr", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "fam114a1", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "aebp1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "tfap4", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "cycs", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "c16orf95", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "zfp28", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "tesk2", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "nif3l1", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "prkcd", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "arl5c", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "cnga2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "h1-10", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "numbl", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "acot9", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "cbln3", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "adamts15", filterType: "BestCase", aaLeft: "877", aaRight: "901", ntLeft: "2629", ntRight: "2703" },
  { key: "mrpl48", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "ralgps2", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "ktn1", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "lgals12", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "tmem222", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "uqcc1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ckmt1a", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "vwc2", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "klk9", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "pip4k2c", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "tmem39b", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "atm", filterType: "BestCase", aaLeft: "2316", aaRight: "2340", ntLeft: "6946", ntRight: "7020" },
  { key: "dnajc14", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "ankrd52", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "nbeal2", filterType: "BestCase", aaLeft: "2014", aaRight: "2038", ntLeft: "6040", ntRight: "6114" },
  { key: "pcdhgb3", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "dynll1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "or56b1", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "gli3", filterType: "BestCase", aaLeft: "1016", aaRight: "1040", ntLeft: "3046", ntRight: "3120" },
  { key: "gsta3", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "slc22a14", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "suclg1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "kif26b", filterType: "BestCase", aaLeft: "940", aaRight: "964", ntLeft: "2818", ntRight: "2892" },
  { key: "vash2", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "krt35", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "pnkp", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "cabs1", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "dclre1a", filterType: "FailedAllFilters", aaLeft: "818", aaRight: "842", ntLeft: "2452", ntRight: "2526" },
  { key: "lck", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "dmrtc2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "man1b1", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "tbc1d3g", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "pank3", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "fam50b", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "dnaaf4", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "naa15", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "cfap298", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "fign", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "arhgap39", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "nxph4", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "osbpl8", filterType: "BestCase", aaLeft: "713", aaRight: "737", ntLeft: "2137", ntRight: "2211" },
  { key: "vpreb1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "kiaa0040", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "znf3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "alg6", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "nbdy", filterType: "FailedAllFilters", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "adam2", filterType: "FailedAllFilters", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "clgn", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "hcfc1", filterType: "BestCase", aaLeft: "1128", aaRight: "1152", ntLeft: "3382", ntRight: "3456" },
  { key: "pik3c2a", filterType: "BestCase", aaLeft: "659", aaRight: "683", ntLeft: "1975", ntRight: "2049" },
  { key: "cdx4", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "mafb", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "znf586", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "asprv1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "fars2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "dhrs12", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "steap4", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "rnf224", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "acsl1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "sparcl1", filterType: "FailedAllFilters", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "fastkd2", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "traf7", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "mterf2", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "nudt12", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "mettl25", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "fam25g", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "seh1l", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "xpa", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "st7l", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "npdc1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rnf213", filterType: "FailedAllFilters", aaLeft: "3186", aaRight: "3210", ntLeft: "9556", ntRight: "9630" },
  { key: "ndufb7", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nus1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "kpna7", filterType: "FailedAllFilters", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "rnf34", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "hla-dma", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "lmbrd2", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "prr4", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gpr34", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "u2af1l4", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "actr10", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "nthl1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tjp2", filterType: "BestCase", aaLeft: "1021", aaRight: "1045", ntLeft: "3061", ntRight: "3135" },
  { key: "crygd", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "cfap20dc", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "arhgef1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "tnfsf13", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "serping1", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "zbp1", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "sult1a3", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "samd5", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "proser3", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "plbd1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "bcl2a1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "dlx5", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "slc44a3", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "gabrg3", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "xk", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "acyp1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "polh", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "soat2", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "irs4", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "pex19", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ctla4", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "nfkb1", filterType: "BestCase", aaLeft: "931", aaRight: "955", ntLeft: "2791", ntRight: "2865" },
  { key: "fbxw10b", filterType: "BestCase", aaLeft: "439", aaRight: "463", ntLeft: "1315", ntRight: "1389" },
  { key: "mri1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "znf471", filterType: "BestCase", aaLeft: "579", aaRight: "603", ntLeft: "1735", ntRight: "1809" },
  { key: "faah", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "znf34", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "parpbp", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "jund", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "rgs17", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "grin1", filterType: "SecondBest", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "mettl9", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "plch1", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "romo1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ctf1", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "sec14l6", filterType: "FourthBest", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "aldh6a1", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "dmap1", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "thpo", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "dhx15", filterType: "SecondBest", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "slc24a5", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "zbtb2", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "ager", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "ctage8", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "cacfd1", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "tmprss7", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "dusp4", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "rac3", filterType: "SecondBest", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "adgrl3", filterType: "BestCase", aaLeft: "1045", aaRight: "1069", ntLeft: "3133", ntRight: "3207" },
  { key: "crhr1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "mok", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "znf324", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ankmy2", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "ehmt1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ica1", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "tp53bp2", filterType: "BestCase", aaLeft: "834", aaRight: "858", ntLeft: "2500", ntRight: "2574" },
  { key: "rgs20", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "ervh48-1", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ripply3", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "gadd45b", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "sharpin", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "atp8b3", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "stx16", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "jtb", filterType: "FourthBest", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "mecr", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "ap5z1", filterType: "BestCase", aaLeft: "724", aaRight: "748", ntLeft: "2170", ntRight: "2244" },
  { key: "cpsf7", filterType: "SecondBest", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "fbn2", filterType: "BestCase", aaLeft: "2838", aaRight: "2862", ntLeft: "8512", ntRight: "8586" },
  { key: "lag3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "znf446", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "tas2r43", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "smkr1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nagk", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "fbxo34", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "tchp", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "nars2", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "bloc1s5", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "clcf1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "kmt5c", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "ints3", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "gen1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "abhd5", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "hdac4", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "psme3", filterType: "SecondBest", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "prdm13", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "ggt7", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "pou3f1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "aqp9", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "poteg", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "gnaq", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "il17a", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "nfu1", filterType: "SecondBest", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "znf207", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "gjc1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "crip2", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "rbx1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lgals4", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "prss48", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "lefty1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "epha7", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "anp32e", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rec8", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "loricrin", filterType: "FailedAllFilters", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "nlgn4x", filterType: "BestCase", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "znf718", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "setd1b", filterType: "BestCase", aaLeft: "1540", aaRight: "1564", ntLeft: "4618", ntRight: "4692" },
  { key: "nsdhl", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "ubxn8", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "arl14", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "esm1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "nusap1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "psen1", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "cfl1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "slamf7", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "dis3l", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "ugt1a1", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "litaf", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "psmb10", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "znf565", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "msh5", filterType: "BestCase", aaLeft: "783", aaRight: "807", ntLeft: "2347", ntRight: "2421" },
  { key: "pappa", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "rbm26", filterType: "BestCase", aaLeft: "608", aaRight: "632", ntLeft: "1822", ntRight: "1896" },
  { key: "abtb1", filterType: "FailedAllFilters", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "aurka", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "cmss1", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "banf1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "neurl3", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "adcy7", filterType: "BestCase", aaLeft: "641", aaRight: "665", ntLeft: "1921", ntRight: "1995" },
  { key: "tctn1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "shisa2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "stxbp3", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "arhgef38", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "ccdc148", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "ift20", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "mynn", filterType: "BestCase", aaLeft: "565", aaRight: "589", ntLeft: "1693", ntRight: "1767" },
  { key: "kcnq3", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "kiaa0895l", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "fam117b", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "or6c74", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "aaas", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "c1qc", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "rtl8b", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "kremen2", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "pinlyp", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "bub3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tmx3", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "rpl27a", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "foxd3", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "hint3", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "tmem18", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "gna14", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "mogat1", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "znf552", filterType: "FailedAllFilters", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "wdr27", filterType: "FailedAllFilters", aaLeft: "595", aaRight: "619", ntLeft: "1783", ntRight: "1857" },
  { key: "mapk8ip2", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "tnip1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "or10q1", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "nme1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "rtl6", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "gas1", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "ttc23l", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "klhl41", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "lsm3", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "grhl2", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "ap1g1", filterType: "BestCase", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "serpini2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "mks1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "bclaf3", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "spn", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "rrad", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "odf3l1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "brd1", filterType: "BestCase", aaLeft: "667", aaRight: "691", ntLeft: "1999", ntRight: "2073" },
  { key: "npy2r", filterType: "FailedAllFilters", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "slc25a44", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "magec3", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "znf230", filterType: "FailedAllFilters", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "timm21", filterType: "ThirdBest", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "dcaf4l1", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "ercc5", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "rae1", filterType: "SecondBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "vps26c", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "or2d3", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "hla-f", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "taf13", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "siglecl1", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "trappc2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "rere", filterType: "BestCase", aaLeft: "1063", aaRight: "1087", ntLeft: "3187", ntRight: "3261" },
  { key: "draxin", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "znf280a", filterType: "FailedAllFilters", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "nptx1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nagpa", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "defa1b", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "epb41l4b", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "cox6a1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "srp14", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "fcrl4", filterType: "FailedAllFilters", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "fmod", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "rad18", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "aldh1a1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "smim2", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rbpms2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "tmem255a", filterType: "SecondBest", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "rfc3", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "gng5p2", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tas2r16", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "prkcz", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "dcaf11", filterType: "BestCase", aaLeft: "512", aaRight: "536", ntLeft: "1534", ntRight: "1608" },
  { key: "tbc1d12", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "insig2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "csf1", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "marchf1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "usp34", filterType: "BestCase", aaLeft: "3438", aaRight: "3462", ntLeft: "10312", ntRight: "10386" },
  { key: "alkbh2", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "senp8", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "tbc1d25", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "birc3", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "psmc1", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "zyg11a", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "rrm2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ifit2", filterType: "FailedAllFilters", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "etdb", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "plppr3", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "tmem183a", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "eif4ebp3", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "itsn1", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "cdkn2c", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "tmtc2", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "nubp2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "h1-0", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "grik4", filterType: "BestCase", aaLeft: "854", aaRight: "878", ntLeft: "2560", ntRight: "2634" },
  { key: "smurf1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "washc2c", filterType: "BestCase", aaLeft: "993", aaRight: "1017", ntLeft: "2977", ntRight: "3051" },
  { key: "gtpbp8", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "tubb2a", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "pou4f1", filterType: "FailedAllFilters", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "wtip", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "dok6", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "hbs1l", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "golga4", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "reep2", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "b3gat2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "ankrd13c", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tnfrsf13b", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "rrp9", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "znf346", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ddx1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "ttc7a", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "dhx40", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "nek6", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "smlr1", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "cd99l2", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "cr2", filterType: "BestCase", aaLeft: "589", aaRight: "613", ntLeft: "1765", ntRight: "1839" },
  { key: "gpr82", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "rbm44", filterType: "FailedAllFilters", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "spdef", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "erich1", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "tdrd6", filterType: "FailedAllFilters", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "tmem191b", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "eno4", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "epgn", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "or4c5", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "cdh10", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "wdr72", filterType: "BestCase", aaLeft: "613", aaRight: "637", ntLeft: "1837", ntRight: "1911" },
  { key: "znf507", filterType: "BestCase", aaLeft: "858", aaRight: "882", ntLeft: "2572", ntRight: "2646" },
  { key: "tram1", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "myo15b", filterType: "BestCase", aaLeft: "2844", aaRight: "2868", ntLeft: "8530", ntRight: "8604" },
  { key: "aasdh", filterType: "BestCase", aaLeft: "1031", aaRight: "1055", ntLeft: "3091", ntRight: "3165" },
  { key: "ovgp1", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "hmgb2", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "higd1a", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "thap3", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "klhl14", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "micu2", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "pds5b", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "mreg", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "smpdl3a", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "sirt6", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "tex43", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "pja1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "pcdhb16", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "pheta2", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "nudt3", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "rspry1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "c11orf58", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "ppp1r10", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "slc25a11", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "smc1b", filterType: "BestCase", aaLeft: "982", aaRight: "1006", ntLeft: "2944", ntRight: "3018" },
  { key: "nrk", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "gspt2", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "heatr6", filterType: "BestCase", aaLeft: "1083", aaRight: "1107", ntLeft: "3247", ntRight: "3321" },
  { key: "fam167b", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "sh3tc2", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "cpxcr1", filterType: "FailedAllFilters", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "spem1", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "ppp1r27", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "slc25a26", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "magee1", filterType: "FailedAllFilters", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "scx", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "rin3", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "krt83", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "adprhl1", filterType: "FailedAllFilters", aaLeft: "1049", aaRight: "1073", ntLeft: "3145", ntRight: "3219" },
  { key: "klhl23", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "trnau1ap", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "dyrk4", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "c2orf76", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "znf530", filterType: "FailedAllFilters", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "wdr45", filterType: "SecondBest", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "gpd1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "cds2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "bivm", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "foxb1", filterType: "SecondBest", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "dipk1b", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "myocos", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ralb", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "rnh1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "htra3", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "h2bc5", filterType: "SecondBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "bgn", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "dennd10", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ak3", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "copz1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "spata2", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "mydgf", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "dusp26", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "stard10", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rasgef1b", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "klhl4", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "cdc34", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ofd1", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "spdye12", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "pm20d1", filterType: "FourthBest", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "kcne5", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "asap2", filterType: "BestCase", aaLeft: "852", aaRight: "876", ntLeft: "2554", ntRight: "2628" },
  { key: "rgs4", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "gipr", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "sel1l2", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "sobp", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "armc6", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "tph2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "prtn3", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "fndc10", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "c1orf94", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "lce1a", filterType: "FourthBest", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "atf7", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "tra2a", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "col12a1", filterType: "BestCase", aaLeft: "1489", aaRight: "1513", ntLeft: "4465", ntRight: "4539" },
  { key: "adamts9", filterType: "BestCase", aaLeft: "1529", aaRight: "1553", ntLeft: "4585", ntRight: "4659" },
  { key: "habp4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "khdc4", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "psca", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "tslp", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "hephl1", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "togaram1", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "ssx3", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "or1n2", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "rilpl1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "pde3a", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "or52e4", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "mrap", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "xaf1", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "tmem135", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "slc1a7", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "slf2", filterType: "BestCase", aaLeft: "861", aaRight: "885", ntLeft: "2581", ntRight: "2655" },
  { key: "blzf1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "rorb", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "h3c14", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "tmem102", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "lyn", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "alg12", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "baz1b", filterType: "BestCase", aaLeft: "929", aaRight: "953", ntLeft: "2785", ntRight: "2859" },
  { key: "c7orf31", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "fam200a", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "or8d4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tpsb2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "kctd7", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "c19orf67", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "sctr", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "epo", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "sh3pxd2b", filterType: "BestCase", aaLeft: "778", aaRight: "802", ntLeft: "2332", ntRight: "2406" },
  { key: "clic3", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "gpr182", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "znf785", filterType: "FailedAllFilters", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "emd", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "gdap1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "anxa8", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "apoo", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "lrrc1", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "dusp11", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "coq6", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "slc29a2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "cyp11b2", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "dpysl2", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "nkx2-5", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "plglb1", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "aasdhppt", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmem120a", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "cstf2", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "sec24c", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "c14orf28", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "fbxw9", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "xrn2", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "chst14", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "sgms2", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "patj", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "acp3", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "prom2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "smg6", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "pik3r6", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "bace1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "hmcn1", filterType: "BestCase", aaLeft: "3463", aaRight: "3487", ntLeft: "10387", ntRight: "10461" },
  { key: "adprm", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "jup", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "mapre3", filterType: "SecondBest", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "rel", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "ahsa1", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "hspb2", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "mxra8", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "arid4b", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "il9", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "sfmbt1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "abcc4", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "tada2b", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "polr3a", filterType: "BestCase", aaLeft: "1363", aaRight: "1387", ntLeft: "4087", ntRight: "4161" },
  { key: "rgpd8", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "cavin2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "mrpl36", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ucn", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mmp17", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "sema6c", filterType: "BestCase", aaLeft: "879", aaRight: "903", ntLeft: "2635", ntRight: "2709" },
  { key: "bcl2l10", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "lvrn", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "tmem132b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "pcbp3", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mff", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "mospd1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "kiss1", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "uprt", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "zmat1", filterType: "FailedAllFilters", aaLeft: "526", aaRight: "550", ntLeft: "1576", ntRight: "1650" },
  { key: "mmp20", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "pramef4", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "depp1", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "crbn", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "utp4", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "mpped2", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "cst5", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "rnf133", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "cybc1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "myo3a", filterType: "BestCase", aaLeft: "1459", aaRight: "1483", ntLeft: "4375", ntRight: "4449" },
  { key: "eqtn", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "znf691", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "pyurf", filterType: "FourthBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tubgcp4", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "dhrs7", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "eci1", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "tepsin", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "tas2r5", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "hspa5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dnah17", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "abhd14b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "scrt1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "lmbr1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "card9", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "znf862", filterType: "BestCase", aaLeft: "958", aaRight: "982", ntLeft: "2872", ntRight: "2946" },
  { key: "spon1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "zar1", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "ddi2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "stmn2", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "prmt3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "or4d10", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "maip1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "rfk", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "cdc42bpa", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "arhgap12", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tcf15", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "fbxo7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ckap2l", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "uevld", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "acan", filterType: "BestCase", aaLeft: "2279", aaRight: "2303", ntLeft: "6835", ntRight: "6909" },
  { key: "atp2b4", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "g6pc2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "alox5", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "acsm2a", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "dhfr", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "arap1", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "fmr1", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "mrrf", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "amfr", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "or8a1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "fance", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "trmt10c", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "stx3", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "runx2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tent4b", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "pigf", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "acss1", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "top1", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "slc6a7", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "vrtn", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "or6n2", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "siah3", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "sh3bgrl3", filterType: "SecondBest", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ambra1", filterType: "BestCase", aaLeft: "1195", aaRight: "1219", ntLeft: "3583", ntRight: "3657" },
  { key: "loc727838", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "wif1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "prss8", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "bhlha15", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "clxn", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "mt1g", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "col15a1", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "rpusd3", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "eif2b3", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "pou2af1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ing4", filterType: "SecondBest", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cyfip1", filterType: "BestCase", aaLeft: "892", aaRight: "916", ntLeft: "2674", ntRight: "2748" },
  { key: "yaf2", filterType: "SecondBest", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "cep83", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "exo5", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "evi2a", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "pmp2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "pabpc1l", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "hmsd", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "polr2j3", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "hdgfl3", filterType: "SecondBest", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "pfdn4", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "hla-dra", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "egr3", filterType: "SecondBest", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "sec22a", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "arsk", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kiaa1522", filterType: "FailedAllFilters", aaLeft: "676", aaRight: "700", ntLeft: "2026", ntRight: "2100" },
  { key: "pelo", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "nmu", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "selenop", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "synj2", filterType: "BestCase", aaLeft: "1208", aaRight: "1232", ntLeft: "3622", ntRight: "3696" },
  { key: "nubpl", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "clec4a", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "gab2", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "glrx3", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "nmt2", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "tgds", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "gk", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "gimap4", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "tmem150a", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "dlg5", filterType: "BestCase", aaLeft: "1458", aaRight: "1482", ntLeft: "4372", ntRight: "4446" },
  { key: "tysnd1", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "tcf3", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "arhgap25", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "slc39a1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccdc180", filterType: "BestCase", aaLeft: "1497", aaRight: "1521", ntLeft: "4489", ntRight: "4563" },
  { key: "atp2a3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tmem160", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "hsf5", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "chtf8", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "pde6d", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tafa2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hao2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "tango6", filterType: "BestCase", aaLeft: "820", aaRight: "844", ntLeft: "2458", ntRight: "2532" },
  { key: "isca2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "sat1", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "pdc", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "wfdc10a", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "krtap19-1", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "twist1", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tmem86b", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "mlph", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "rnf166", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "lmnb1", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "emc7", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "pex7", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "coro1b", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "mrpl54", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "apobec1", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ccdc88b", filterType: "BestCase", aaLeft: "1431", aaRight: "1455", ntLeft: "4291", ntRight: "4365" },
  { key: "dap3", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "acot12", filterType: "FailedAllFilters", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "ptprg", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "itpka", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "spire2", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "gpbp1l1", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "crot", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "znf837", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "pim2", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "commd1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fzd5", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "or2s2", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "mrto4", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "pglyrp4", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "rdh14", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "cited1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "tgif2lx", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "sox5", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "ddit4l", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "clasp1", filterType: "BestCase", aaLeft: "1272", aaRight: "1296", ntLeft: "3814", ntRight: "3888" },
  { key: "asgr1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "st8sia1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pla2g4b", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "sdk2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "atp5me", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cubn", filterType: "BestCase", aaLeft: "2483", aaRight: "2507", ntLeft: "7447", ntRight: "7521" },
  { key: "acr", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "hoxb9", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "inpp5f", filterType: "BestCase", aaLeft: "827", aaRight: "851", ntLeft: "2479", ntRight: "2553" },
  { key: "pole4", filterType: "SecondBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "scml2", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "igfbpl1", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "znf800", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "usp4", filterType: "BestCase", aaLeft: "586", aaRight: "610", ntLeft: "1756", ntRight: "1830" },
  { key: "epm2aip1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pcif1", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "ggnbp2", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "rnf151", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "abcf1", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "ncln", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "uba6", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "defa3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "myo5c", filterType: "BestCase", aaLeft: "1042", aaRight: "1066", ntLeft: "3124", ntRight: "3198" },
  { key: "ube2q2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "tmem209", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ovol2", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "sema3f", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "mcc", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "fth1", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "c16orf89", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "satb2", filterType: "BestCase", aaLeft: "706", aaRight: "730", ntLeft: "2116", ntRight: "2190" },
  { key: "or5d13", filterType: "FailedAllFilters", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "pgd", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "myh2", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "mief2", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "igfbp4", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "skor1", filterType: "BestCase", aaLeft: "718", aaRight: "742", ntLeft: "2152", ntRight: "2226" },
  { key: "hoxc8", filterType: "SecondBest", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "gbx1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "myoz1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "zc3hav1l", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "lrig3", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "eif4g3", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "kprp", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "adipor2", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "bicd1", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "smc2", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "frg2b", filterType: "FailedAllFilters", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "c3ar1", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "dcun1d1", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "hmx1", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "tigd3", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "slc38a4", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "znf180", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "trim8", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "pcdhga9", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "crem", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "tmem218", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "rpa4", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "sqle", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "isyna1", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "mdm4", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "spata6l", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "jaml", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "npnt", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "omp", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "prkci", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sprr2d", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "misp3", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "csnk1a1l", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "or5bs1p", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "ggtlc2", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "itgb3bp", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "acot4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "lrrc4c", filterType: "SecondBest", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "igsf6", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "tns3", filterType: "BestCase", aaLeft: "963", aaRight: "987", ntLeft: "2887", ntRight: "2961" },
  { key: "adamts18", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "slc7a2", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "zc3h13", filterType: "BestCase", aaLeft: "1038", aaRight: "1062", ntLeft: "3112", ntRight: "3186" },
  { key: "mrpl45", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "or5t1", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "fam13c", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "oprpn", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "cxxc1", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "slc15a5", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "clec20a", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "or4f29", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "cpeb2", filterType: "BestCase", aaLeft: "1008", aaRight: "1032", ntLeft: "3022", ntRight: "3096" },
  { key: "top2a", filterType: "BestCase", aaLeft: "1213", aaRight: "1237", ntLeft: "3637", ntRight: "3711" },
  { key: "klk4", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "c17orf107", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ldlrad3", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "dnajc19", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "gpatch3", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "nudc", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "parp1", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "gpaa1", filterType: "BestCase", aaLeft: "526", aaRight: "550", ntLeft: "1576", ntRight: "1650" },
  { key: "hspa1b", filterType: "BestCase", aaLeft: "544", aaRight: "568", ntLeft: "1630", ntRight: "1704" },
  { key: "rab7a", filterType: "SecondBest", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "rec114", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "thap11", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "wipf3", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "polg2", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "or9a4", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "hspe1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "megf10", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gatd3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "gstt2b", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "rhebl1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "bysl", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "kif13a", filterType: "BestCase", aaLeft: "1031", aaRight: "1055", ntLeft: "3091", ntRight: "3165" },
  { key: "arid3a", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "krt38", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "ccl24", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "arpc5", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "fhl3", filterType: "SecondBest", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "spin4", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "zranb3", filterType: "BestCase", aaLeft: "843", aaRight: "867", ntLeft: "2527", ntRight: "2601" },
  { key: "fam98a", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "col6a2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "serpinb12", filterType: "FailedAllFilters", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "kcna1", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "ly6g6c", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "dnaaf9", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "acvr2b", filterType: "SecondBest", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "rsph3", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "aagab", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "c1orf112", filterType: "FailedAllFilters", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "ftcd", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "ccdc191", filterType: "BestCase", aaLeft: "744", aaRight: "768", ntLeft: "2230", ntRight: "2304" },
  { key: "osbpl5", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "h4c3", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pag1", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "cnot1", filterType: "SecondBest", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "tmem171", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "galnt7", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "or10a3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "c2cd4c", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "pwp1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "pllp", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "prex2", filterType: "BestCase", aaLeft: "964", aaRight: "988", ntLeft: "2890", ntRight: "2964" },
  { key: "hes3", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "gtf2h4", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "npr3", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "znf354a", filterType: "FailedAllFilters", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "galnt11", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "catsperb", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "wnt10a", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "flrt3", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "iffo2", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "nabp1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tsks", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "rnf39", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "bbof1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "gpr39", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "prr9", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "or1i1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "pde4b", filterType: "BestCase", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "cd2", filterType: "FailedAllFilters", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "stbd1", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "cracr2b", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "krt1", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "dkc1", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "samd8", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "icmt", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "anks3", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "tent5c", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccl13", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "mrps5", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "lhfpl7", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "rnf122", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "abca2", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "polr1g", filterType: "FailedAllFilters", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "ttc39c", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "acrv1", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "ube2v1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "arrdc4", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "sphkap", filterType: "FailedAllFilters", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "dynlt2", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "apol1", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "mrpl10", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf680", filterType: "FailedAllFilters", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "nedd4l", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "edn2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "lhx2", filterType: "SecondBest", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "rmc1", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "irf3", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "dnali1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "cenpj", filterType: "BestCase", aaLeft: "1251", aaRight: "1275", ntLeft: "3751", ntRight: "3825" },
  { key: "larp4b", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "tbc1d22a", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "tm4sf19", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "il17rc", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "fgd6", filterType: "FailedAllFilters", aaLeft: "848", aaRight: "872", ntLeft: "2542", ntRight: "2616" },
  { key: "cnep1r1", filterType: "SecondBest", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ocm", filterType: "SecondBest", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "rbfox2", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "zc3h8", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "svep1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "gata6", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "angpt4", filterType: "FailedAllFilters", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "stk19", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "zmym3", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "eif4a1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "mcm4", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "dpf2", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "slc12a5", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "ttyh2", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "ifna5", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ermard", filterType: "FailedAllFilters", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "crct1", filterType: "FourthBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "znf844", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "gfpt2", filterType: "BestCase", aaLeft: "617", aaRight: "641", ntLeft: "1849", ntRight: "1923" },
  { key: "ptk2b", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "hoxa13", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "bud31", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "atoh8", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "lipe", filterType: "BestCase", aaLeft: "921", aaRight: "945", ntLeft: "2761", ntRight: "2835" },
  { key: "prcd", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or2t1", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "phldb3", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "gpr89a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "c13orf42", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "atp5f1b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "erlec1", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "kcnip3", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "wnt8a", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "myl6", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "atp6v0b", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mdh1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "nrdc", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "rnf115", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "abcb5", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "tsr1", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "myo1g", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "rcvrn", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ppargc1a", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "masp2", filterType: "BestCase", aaLeft: "590", aaRight: "614", ntLeft: "1768", ntRight: "1842" },
  { key: "mrpl27", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "cobll1", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "col1a2", filterType: "BestCase", aaLeft: "752", aaRight: "776", ntLeft: "2254", ntRight: "2328" },
  { key: "cadps", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "clec3b", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "c20orf27", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "dolk", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "fpr2", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "timp2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "csnk1e", filterType: "SecondBest", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "c9orf72", filterType: "SecondBest", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "ppm1m", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "sfn", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "ahcy", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "loc102723996", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "or1l4", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "bnip3l", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "rbm6", filterType: "BestCase", aaLeft: "1068", aaRight: "1092", ntLeft: "3202", ntRight: "3276" },
  { key: "calml6", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "asb3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "mbtd1", filterType: "SecondBest", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "ampd1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "ca7", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "rab11fip3", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "nprl2", filterType: "SecondBest", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "alas2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "serpinb9", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "kcng3", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "c14orf39", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "gcnt4", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "kdelr2", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "mroh1", filterType: "BestCase", aaLeft: "1417", aaRight: "1441", ntLeft: "4249", ntRight: "4323" },
  { key: "h3c3", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "r3hdm4", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "timm9", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "elmod2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "lpp", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "pcdh8", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "rabgap1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "pkmyt1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "bbx", filterType: "BestCase", aaLeft: "860", aaRight: "884", ntLeft: "2578", ntRight: "2652" },
  { key: "cald1", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "mindy1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "zswim1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "grm2", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "trim49c", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "ska1", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "nt5c1b-rdh14", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "cfhr2", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "apbb1ip", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "dppa2", filterType: "FailedAllFilters", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "smco3", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "abcc12", filterType: "BestCase", aaLeft: "1279", aaRight: "1303", ntLeft: "3835", ntRight: "3909" },
  { key: "gpsm3", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "trmt61b", filterType: "FailedAllFilters", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "hapln3", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "btk", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "prr18", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "lama1", filterType: "BestCase", aaLeft: "2864", aaRight: "2888", ntLeft: "8590", ntRight: "8664" },
  { key: "tnfaip8l2", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "mbtps2", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "dgat2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "prr20g", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "hs3st3b1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "smcr8", filterType: "BestCase", aaLeft: "590", aaRight: "614", ntLeft: "1768", ntRight: "1842" },
  { key: "pcdh19", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "sytl3", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "p2ry14", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "trappc6b", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "tkt", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "pecam1", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "tmem94", filterType: "BestCase", aaLeft: "1196", aaRight: "1220", ntLeft: "3586", ntRight: "3660" },
  { key: "or5b21", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "golga7b", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ndufaf6", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "pomgnt2", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "dlc1", filterType: "BestCase", aaLeft: "1299", aaRight: "1323", ntLeft: "3895", ntRight: "3969" },
  { key: "slc4a9", filterType: "BestCase", aaLeft: "924", aaRight: "948", ntLeft: "2770", ntRight: "2844" },
  { key: "csnk2b", filterType: "SecondBest", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "klhl32", filterType: "SecondBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "sowahb", filterType: "FailedAllFilters", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "srrm3", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "chrnb2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "frmd8", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "prkaca", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "nox4", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "cysltr1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "apobr", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "angptl3", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "gfi1b", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "fam166c", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "slc18a2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ubl4b", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "slu7", filterType: "SecondBest", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "nhlh1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "exosc2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "clpb", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "slc25a37", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ppp1r36", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "perp", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "coa4", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "defb118", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cbwd2", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "pigu", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "supv3l1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "trub1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "slc35b4", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "crebbp", filterType: "BestCase", aaLeft: "982", aaRight: "1006", ntLeft: "2944", ntRight: "3018" },
  { key: "jam2", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "nuggc", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "ppa2", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "vps29", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "cdkl3", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "fam110a", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "golga8m", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "msl1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "znf521", filterType: "BestCase", aaLeft: "1109", aaRight: "1133", ntLeft: "3325", ntRight: "3399" },
  { key: "wdr54", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "trh", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "c2orf50", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "aars2", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "col23a1", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "maob", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "skp2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "znf516", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "notch2", filterType: "BestCase", aaLeft: "1653", aaRight: "1677", ntLeft: "4957", ntRight: "5031" },
  { key: "gucy2f", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "adad2", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "susd3", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "bak1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "def8", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "lamp2", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "slc35a3", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "c12orf54", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "acer1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "smad6", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "ssh1", filterType: "BestCase", aaLeft: "854", aaRight: "878", ntLeft: "2560", ntRight: "2634" },
  { key: "eps8l3", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "camkv", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "mcf2l2", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "amigo1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "znf274", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "gjd2", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "rb1", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "fbxl19", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "chd1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "hla-dqa1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "ptpn3", filterType: "BestCase", aaLeft: "589", aaRight: "613", ntLeft: "1765", ntRight: "1839" },
  { key: "prrc2c", filterType: "BestCase", aaLeft: "2144", aaRight: "2168", ntLeft: "6430", ntRight: "6504" },
  { key: "chrna5", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "fam229b", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "dync2i2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "il1rapl1", filterType: "SecondBest", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "tex52", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "cpne3", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "cyria", filterType: "SecondBest", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "tyrp1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "znf70", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "tas2r30", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ube2g2", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "dhcr7", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "bbs4", filterType: "FailedAllFilters", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "clca2", filterType: "BestCase", aaLeft: "757", aaRight: "781", ntLeft: "2269", ntRight: "2343" },
  { key: "usp25", filterType: "BestCase", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "ppp1r9b", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "fbxo47", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "chmp2b", filterType: "SecondBest", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "pld1", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "cers5", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "tmem30b", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "itgb6", filterType: "BestCase", aaLeft: "634", aaRight: "658", ntLeft: "1900", ntRight: "1974" },
  { key: "tgm3", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "ptprt", filterType: "BestCase", aaLeft: "804", aaRight: "828", ntLeft: "2410", ntRight: "2484" },
  { key: "zwint", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "uxt", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rps11", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "spg21", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "gng11", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "zkscan1", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "il19", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "gucy1a1", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "acsbg2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "zdhhc16", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "maf1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "znf648", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "nipsnap1", filterType: "SecondBest", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "sall1", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "camk2a", filterType: "SecondBest", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "farsa", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "dmp1", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "cyp2f1", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "zdhhc21", filterType: "SecondBest", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ung", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cchcr1", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "fndc5", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "smchd1", filterType: "BestCase", aaLeft: "1971", aaRight: "1995", ntLeft: "5911", ntRight: "5985" },
  { key: "ankfn1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cdk2ap2", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ct47a4", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "miox", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "ncmap", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "rps26", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "trdmt1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "lrmda", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "rimbp2", filterType: "BestCase", aaLeft: "638", aaRight: "662", ntLeft: "1912", ntRight: "1986" },
  { key: "atp4b", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "csde1", filterType: "BestCase", aaLeft: "686", aaRight: "710", ntLeft: "2056", ntRight: "2130" },
  { key: "lgsn", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "cftr", filterType: "BestCase", aaLeft: "1442", aaRight: "1466", ntLeft: "4324", ntRight: "4398" },
  { key: "herpud1", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "usp12", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "tet2", filterType: "FailedAllFilters", aaLeft: "995", aaRight: "1019", ntLeft: "2983", ntRight: "3057" },
  { key: "h2az1", filterType: "SecondBest", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "rabl2a", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "bard1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "meikin", filterType: "FailedAllFilters", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "mvd", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "psma7", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "itga1", filterType: "BestCase", aaLeft: "819", aaRight: "843", ntLeft: "2455", ntRight: "2529" },
  { key: "oosp2", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "cfap161", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "dgka", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "galk1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "ripor2", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "ifna14", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cyp2c18", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "igll1", filterType: "ThirdBest", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "mmp3", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "btg3", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "cd53", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "map3k13", filterType: "BestCase", aaLeft: "695", aaRight: "719", ntLeft: "2083", ntRight: "2157" },
  { key: "sh3rf2", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "fam102b", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "itga10", filterType: "BestCase", aaLeft: "906", aaRight: "930", ntLeft: "2716", ntRight: "2790" },
  { key: "tsc2", filterType: "BestCase", aaLeft: "1435", aaRight: "1459", ntLeft: "4303", ntRight: "4377" },
  { key: "cox10", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "akr1c4", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "or6c65", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "fat1", filterType: "BestCase", aaLeft: "1031", aaRight: "1055", ntLeft: "3091", ntRight: "3165" },
  { key: "hspa12b", filterType: "BestCase", aaLeft: "617", aaRight: "641", ntLeft: "1849", ntRight: "1923" },
  { key: "pate3", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "rnasek", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "syngr4", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "golph3l", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "gpc2", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "cdt1", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "syp", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "znf543", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "wdr36", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "defb130b", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "or51c1p", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "tspan13", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "rrp1b", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "creg1", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "cog6", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "ccdc159", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ncoa3", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "mob3c", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "siglec6", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "or3a3", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "cabin1", filterType: "BestCase", aaLeft: "2038", aaRight: "2062", ntLeft: "6112", ntRight: "6186" },
  { key: "c14orf93", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "mageb2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "qki", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "ccr3", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "hadha", filterType: "BestCase", aaLeft: "641", aaRight: "665", ntLeft: "1921", ntRight: "1995" },
  { key: "znf221", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "dnm1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ocm2", filterType: "SecondBest", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "cyp3a7", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "pomt2", filterType: "BestCase", aaLeft: "682", aaRight: "706", ntLeft: "2044", ntRight: "2118" },
  { key: "batf2", filterType: "FailedAllFilters", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "tpst2", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "ctsh", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "cxcr4", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "gpr139", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "ina", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "stk32a", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "tssk1b", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "tmem179b", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "matk", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "capns2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "c14orf180", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "tex30", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "znf709", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "timd4", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "mycbp", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "col24a1", filterType: "BestCase", aaLeft: "1627", aaRight: "1651", ntLeft: "4879", ntRight: "4953" },
  { key: "epm2a", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ano9", filterType: "BestCase", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "rd3", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "lims4", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "sh3gl2", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "f2r", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "eif2s3b", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tyw3", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "tbc1d2", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "carmil3", filterType: "BestCase", aaLeft: "1288", aaRight: "1312", ntLeft: "3862", ntRight: "3936" },
  { key: "slc35g1", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "doc2b", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "or4d6", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "degs2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rtraf", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "cxcl8", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "xrcc3", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "rhag", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "cep152", filterType: "BestCase", aaLeft: "915", aaRight: "939", ntLeft: "2743", ntRight: "2817" },
  { key: "myo19", filterType: "BestCase", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "lrrc18", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "znf574", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "cys1", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "msantd5", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "pkd1", filterType: "BestCase", aaLeft: "3060", aaRight: "3084", ntLeft: "9178", ntRight: "9252" },
  { key: "med19", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "pramef25", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "dhrs7c", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "phactr4", filterType: "FailedAllFilters", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "gramd2a", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "zfyve1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "uxs1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "dhx33", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "znf335", filterType: "BestCase", aaLeft: "982", aaRight: "1006", ntLeft: "2944", ntRight: "3018" },
  { key: "kbtbd6", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "nup155", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "b3galt6", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "eef1d", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "kazn", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "agap1", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "pcdha8", filterType: "FailedAllFilters", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "card16", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "nacc1", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "esrp2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "haus7", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "prpf4", filterType: "SecondBest", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rfxank", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "fbxo25", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "cdrt15", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "klkb1", filterType: "FailedAllFilters", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "znf148", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "usp47", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "magi2", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "sncg", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "psmd2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "emp2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "mcph1", filterType: "FailedAllFilters", aaLeft: "772", aaRight: "796", ntLeft: "2314", ntRight: "2388" },
  { key: "rarres1", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "krtap12-3", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "ptchd4", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "gnat2", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "c5orf49", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "amotl2", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "tmem184b", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "znf12", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "spaca6", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "ntng2", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "ubr3", filterType: "BestCase", aaLeft: "1568", aaRight: "1592", ntLeft: "4702", ntRight: "4776" },
  { key: "cdhr4", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "znf460", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "snrpd3", filterType: "SecondBest", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "purg", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "krt6c", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "psg11", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf25", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "pi4ka", filterType: "BestCase", aaLeft: "1164", aaRight: "1188", ntLeft: "3490", ntRight: "3564" },
  { key: "ct47a11", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "morn1", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "cdca3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "rom1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "otx1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "znf302", filterType: "FailedAllFilters", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "gpank1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "klf10", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "iqcc", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "pygb", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gh1", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "pramef12", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "cox8c", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "s100b", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "glt8d2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tbc1d10a", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "npbwr1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "plekhg2", filterType: "BestCase", aaLeft: "705", aaRight: "729", ntLeft: "2113", ntRight: "2187" },
  { key: "dnajb11", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "rnf25", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "chchd5", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "gpr25", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fam181a", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "cldn24", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "xylt2", filterType: "BestCase", aaLeft: "782", aaRight: "806", ntLeft: "2344", ntRight: "2418" },
  { key: "kcnab3", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "uvssa", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "madd", filterType: "BestCase", aaLeft: "734", aaRight: "758", ntLeft: "2200", ntRight: "2274" },
  { key: "hk2", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "plxna2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "foxk1", filterType: "BestCase", aaLeft: "662", aaRight: "686", ntLeft: "1984", ntRight: "2058" },
  { key: "cand2", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "ralbp1", filterType: "BestCase", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "mark3", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "arfgap2", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "d2hgdh", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "mef2c", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "dlgap5", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "apba2", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "pigk", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "trpv4", filterType: "BestCase", aaLeft: "830", aaRight: "854", ntLeft: "2488", ntRight: "2562" },
  { key: "krt13", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "fyb1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "dzip3", filterType: "BestCase", aaLeft: "1114", aaRight: "1138", ntLeft: "3340", ntRight: "3414" },
  { key: "bcas4", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "b3gntl1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "kaag1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "golga8s", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "gnmt", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "jpt1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "pax1", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "plec", filterType: "BestCase", aaLeft: "783", aaRight: "807", ntLeft: "2347", ntRight: "2421" },
  { key: "hsd17b7", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "arsf", filterType: "FailedAllFilters", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "f13a1", filterType: "BestCase", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "fkbp6", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "col3a1", filterType: "BestCase", aaLeft: "1102", aaRight: "1126", ntLeft: "3304", ntRight: "3378" },
  { key: "pgm2l1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "lrwd1", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "mss51", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "nos2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "utp20", filterType: "BestCase", aaLeft: "2498", aaRight: "2522", ntLeft: "7492", ntRight: "7566" },
  { key: "txnrd3", filterType: "FailedAllFilters", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "krt24", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "npm3", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "p4ha3", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "tas1r3", filterType: "BestCase", aaLeft: "712", aaRight: "736", ntLeft: "2134", ntRight: "2208" },
  { key: "znf597", filterType: "FailedAllFilters", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "nps", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "znfx1", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "hsbp1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "gnb1l", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "tpp1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "rhod", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "ddit3", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "bcl2l2-pabpn1", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "asah1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "arhgap28", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "fut3", filterType: "FailedAllFilters", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "rsf1", filterType: "BestCase", aaLeft: "673", aaRight: "697", ntLeft: "2017", ntRight: "2091" },
  { key: "odf4", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "gpr12", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "tmem233", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "slc2a1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "umodl1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "pfas", filterType: "BestCase", aaLeft: "969", aaRight: "993", ntLeft: "2905", ntRight: "2979" },
  { key: "hoxc10", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "nxf1", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "or51e2", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "dennd2d", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "ptgs1", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "sgpl1", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "tenm3", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "ncaph2", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "ap2s1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ciao1", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "cyb5a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "prpsap2", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "slc10a6", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "c3orf38", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "plcd4", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "leng8", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "gmppa", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "h2bc12", filterType: "SecondBest", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ptprj", filterType: "BestCase", aaLeft: "941", aaRight: "965", ntLeft: "2821", ntRight: "2895" },
  { key: "idh1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "hoxa3", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "or5h6", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "exoc3l2", filterType: "BestCase", aaLeft: "766", aaRight: "790", ntLeft: "2296", ntRight: "2370" },
  { key: "plac9", filterType: "FourthBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "adm5", filterType: "FailedAllFilters", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "kiaa1671", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "calcoco2", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "zfc3h1", filterType: "BestCase", aaLeft: "1452", aaRight: "1476", ntLeft: "4354", ntRight: "4428" },
  { key: "cryl1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "mesp2", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "asic3", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "fzd8", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "jagn1", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "knstrn", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "gtf2i", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "ppp1r1c", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "dbp", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "sh2d1a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fam193b", filterType: "BestCase", aaLeft: "645", aaRight: "669", ntLeft: "1933", ntRight: "2007" },
  { key: "ffar4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "sox8", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "miga2", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "ibsp", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cgb7", filterType: "FourthBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "inpp5k", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "hoxb4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "shc1", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "or5k1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "shld2", filterType: "FailedAllFilters", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "znf483", filterType: "FailedAllFilters", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "mob4", filterType: "SecondBest", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "cd300c", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "csmd1", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "ino80", filterType: "BestCase", aaLeft: "926", aaRight: "950", ntLeft: "2776", ntRight: "2850" },
  { key: "krtap8-1", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "gria3", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "unc5cl", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "tprx2", filterType: "FailedAllFilters", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "ssbp2", filterType: "SecondBest", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "nup42", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "tmem204", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "syt16", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "or2m3", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "dennd1c", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "rad51ap2", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "naglu", filterType: "BestCase", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "ndfip1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "allc", filterType: "FourthBest", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "rassf7", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "abl1", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "klhl9", filterType: "BestCase", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "saysd1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "lrriq1", filterType: "FailedAllFilters", aaLeft: "1446", aaRight: "1470", ntLeft: "4336", ntRight: "4410" },
  { key: "tex13d", filterType: "FailedAllFilters", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "adrb2", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "rars1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pten", filterType: "SecondBest", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "reps2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "snip1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "rgs9", filterType: "BestCase", aaLeft: "639", aaRight: "663", ntLeft: "1915", ntRight: "1989" },
  { key: "or10x1", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "trmt1", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "krt71", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "myl6b", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tmem88", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "il13ra1", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "enc1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "adamts4", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "crlf1", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "ext1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "nudcd2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ttc27", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gdf5", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "efemp1", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "odc1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "esrrg", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "nacad", filterType: "FailedAllFilters", aaLeft: "1037", aaRight: "1061", ntLeft: "3109", ntRight: "3183" },
  { key: "ctsv", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "ms4a10", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "tmem138", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "col4a1", filterType: "BestCase", aaLeft: "1030", aaRight: "1054", ntLeft: "3088", ntRight: "3162" },
  { key: "polr3gl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dnah7", filterType: "BestCase", aaLeft: "1929", aaRight: "1953", ntLeft: "5785", ntRight: "5859" },
  { key: "ruvbl1", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "gzmh", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "kcnk12", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "clec12a", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "dtd2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "ehbp1", filterType: "BestCase", aaLeft: "886", aaRight: "910", ntLeft: "2656", ntRight: "2730" },
  { key: "tp53inp2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "foxn4", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "nomo2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tspyl6", filterType: "FailedAllFilters", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "or6v1", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "tcl1a", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "asb16", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "bhlhb9", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "ntmt1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "upp2", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "gage12c", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "page1", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "kif21a", filterType: "BestCase", aaLeft: "847", aaRight: "871", ntLeft: "2539", ntRight: "2613" },
  { key: "pi16", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "fam227a", filterType: "FailedAllFilters", aaLeft: "416", aaRight: "440", ntLeft: "1246", ntRight: "1320" },
  { key: "otulin", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "acly", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "rtf1", filterType: "SecondBest", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "sycn", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "tnfrsf8", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "cd1d", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "anxa5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "pom121l2", filterType: "FailedAllFilters", aaLeft: "668", aaRight: "692", ntLeft: "2002", ntRight: "2076" },
  { key: "pskh2", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "trps1", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "apob", filterType: "FailedAllFilters", aaLeft: "2934", aaRight: "2958", ntLeft: "8800", ntRight: "8874" },
  { key: "bmp7", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "tnfsf8", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "h2ap", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "nkx2-8", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mfsd1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "akap13", filterType: "BestCase", aaLeft: "1720", aaRight: "1744", ntLeft: "5158", ntRight: "5232" },
  { key: "slc17a6", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "iars2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "cnn3", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "btbd19", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "c1ql2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tcap", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "pcna", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "txndc17", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ralgapa2", filterType: "BestCase", aaLeft: "1487", aaRight: "1511", ntLeft: "4459", ntRight: "4533" },
  { key: "oaf", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "cntn2", filterType: "BestCase", aaLeft: "994", aaRight: "1018", ntLeft: "2980", ntRight: "3054" },
  { key: "pnpla7", filterType: "FailedAllFilters", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "hoxd10", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "mnt", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "znf93", filterType: "FailedAllFilters", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "immp1l", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "slc5a1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "reck", filterType: "BestCase", aaLeft: "934", aaRight: "958", ntLeft: "2800", ntRight: "2874" },
  { key: "mxra5", filterType: "FailedAllFilters", aaLeft: "2660", aaRight: "2684", ntLeft: "7978", ntRight: "8052" },
  { key: "ccdc102b", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "ap5s1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "st6galnac4", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "il4", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "mlkl", filterType: "FailedAllFilters", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "hibadh", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "septin2", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "abcc9", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "nkiras1", filterType: "SecondBest", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "or13c3", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "ocrl", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "rgpd5", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "cplane2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cacna1s", filterType: "BestCase", aaLeft: "1818", aaRight: "1842", ntLeft: "5452", ntRight: "5526" },
  { key: "hgd", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "ptpn13", filterType: "BestCase", aaLeft: "1280", aaRight: "1304", ntLeft: "3838", ntRight: "3912" },
  { key: "iglon5", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "tmem251", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "atp1b2", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "baiap2l1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "adgre3", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "prrt3", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "c1orf232", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "kcnrg", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "acad11", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "scfd1", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "pramef9", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "zscan31", filterType: "FailedAllFilters", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "tmem266", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "ccnj", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "adgrf4", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "cbx1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "mtcl1", filterType: "BestCase", aaLeft: "820", aaRight: "844", ntLeft: "2458", ntRight: "2532" },
  { key: "znf383", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "mrgprx1", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "cst8", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "nkx6-3", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "acaa2", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "znhit2", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "pdcd5", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "napg", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "dot1l", filterType: "BestCase", aaLeft: "955", aaRight: "979", ntLeft: "2863", ntRight: "2937" },
  { key: "syt1", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "gmfg", filterType: "SecondBest", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "adam10", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "bex2", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "vamp4", filterType: "SecondBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "hdc", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "or5m3", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "tas2r8", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "tekt2", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "hspa8", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "apobec3c", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "lonrf2", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "foxd4l6", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "heatr5a", filterType: "BestCase", aaLeft: "846", aaRight: "870", ntLeft: "2536", ntRight: "2610" },
  { key: "fezf1", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "or2t12", filterType: "ThirdBest", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "mrps36", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ankrd16", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "rlim", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "nt5c1b", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "bora", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "smim26", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "atp6v0e1", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "igsf9b", filterType: "BestCase", aaLeft: "648", aaRight: "672", ntLeft: "1942", ntRight: "2016" },
  { key: "ercc8", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "nmbr", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "psrc1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "pip5kl1", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "trappc12", filterType: "FailedAllFilters", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "cfap300", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "gvqw3", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "gsdma", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "aida", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "kat2a", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "daz4", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "zbtb21", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "itln1", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "mkrn1", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "mtmr11", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "srp19", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "elac2", filterType: "BestCase", aaLeft: "794", aaRight: "818", ntLeft: "2380", ntRight: "2454" },
  { key: "map2k3", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "sap25", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "ddx50", filterType: "BestCase", aaLeft: "646", aaRight: "670", ntLeft: "1936", ntRight: "2010" },
  { key: "ifnl1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "calca", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "macroh2a2", filterType: "SecondBest", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "or11h6", filterType: "FailedAllFilters", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "fcgbp", filterType: "BestCase", aaLeft: "615", aaRight: "639", ntLeft: "1843", ntRight: "1917" },
  { key: "ms4a7", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "amdhd1", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "insm2", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "znf101", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "magea12", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "frrs1l", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "nmur2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "znf136", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "ankrd34c", filterType: "FailedAllFilters", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "stox1", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "rpl37", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "senp5", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "usp39", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "cyb5d2", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "tbc1d28", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "adcyap1r1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "lekr1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "znf429", filterType: "FailedAllFilters", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "fn3k", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "lair2", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "acap1", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "fam177a1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "sox11", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "dph1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "isg15", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "znf654", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "apoa5", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "eef1akmt2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "camp", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "lrsam1", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "pgk1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "gstt4", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "zbtb16", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "frem1", filterType: "BestCase", aaLeft: "1027", aaRight: "1051", ntLeft: "3079", ntRight: "3153" },
  { key: "cd79a", filterType: "SecondBest", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "samd12", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "smim11", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "gmeb1", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "mtmr1", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "unc93b1", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "eddm3b", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "tprg1l", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ppfibp2", filterType: "BestCase", aaLeft: "839", aaRight: "863", ntLeft: "2515", ntRight: "2589" },
  { key: "htr1a", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "gprc5a", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "creb3l4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "ccdc50", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "or52i1", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "sohlh1", filterType: "FailedAllFilters", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "slc27a3", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "ptges2", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "ctnnd1", filterType: "BestCase", aaLeft: "941", aaRight: "965", ntLeft: "2821", ntRight: "2895" },
  { key: "ccdc110", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "cibar2", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "akt3", filterType: "SecondBest", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "ptrh2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "chst1", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "phf1", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "tmem40", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "lrrc66", filterType: "FailedAllFilters", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "ewsr1", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "phf13", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "atxn3", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "hnrnpdl", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "trim51", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mknk2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "wasl", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "rnaset2", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "prss27", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "krtap5-6", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "mab21l3", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "znf777", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "meiob", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "lyve1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "ncr1", filterType: "FailedAllFilters", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "prelid1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "gys1", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "insrr", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "znf268", filterType: "FailedAllFilters", aaLeft: "829", aaRight: "853", ntLeft: "2485", ntRight: "2559" },
  { key: "med1", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "mboat7", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "b4galt3", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "gns", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "folr1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "atp6v1g2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "prg3", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "erp27", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "tcp11x1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "phf24", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "trim66", filterType: "BestCase", aaLeft: "947", aaRight: "971", ntLeft: "2839", ntRight: "2913" },
  { key: "tceal7", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "kdm4e", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "bend7", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "elk3", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "tmc7", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "znf740", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "slc48a1", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "enox1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "vps35", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "dnajc3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "srpk2", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "wdr48", filterType: "SecondBest", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "lancl1", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "ifitm2", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "aim2", filterType: "FailedAllFilters", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "lrrc51", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "or10j5", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "h2bc8", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ccdc127", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "pole", filterType: "BestCase", aaLeft: "1525", aaRight: "1549", ntLeft: "4573", ntRight: "4647" },
  { key: "or51t1", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "agxt", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "lypd5", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "pex14", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "inhbe", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "pcsk6", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "hrh3", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "fabp1", filterType: "FourthBest", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "pcdha10", filterType: "FailedAllFilters", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "cdc42ep2", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "tm9sf2", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "cenpt", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "zbbx", filterType: "FailedAllFilters", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "ct45a7", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "zp1", filterType: "FailedAllFilters", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "dis3l2", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "mettl4", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "rfx7", filterType: "BestCase", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "cdy1b", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "cdk16", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "snx25", filterType: "BestCase", aaLeft: "904", aaRight: "928", ntLeft: "2710", ntRight: "2784" },
  { key: "ywhaq", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "zpbp2", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "zbtb43", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "ankrd9", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "cebpe", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "rapgef1", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "socs2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "ado", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "chat", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "imp3", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "dpt", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "dusp9", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "bnip5", filterType: "FailedAllFilters", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "galr3", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "katnal1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "atg16l2", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "znf329", filterType: "FailedAllFilters", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "ror1", filterType: "BestCase", aaLeft: "912", aaRight: "936", ntLeft: "2734", ntRight: "2808" },
  { key: "ginm1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "tfam", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "eya3", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "lbhd2", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "pgm3", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "loc112267897", filterType: "FailedAllFilters", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "mapk13", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "eml5", filterType: "BestCase", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "kif24", filterType: "BestCase", aaLeft: "554", aaRight: "578", ntLeft: "1660", ntRight: "1734" },
  { key: "snx12", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "atg10", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "ccna1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "spata5l1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "padi2", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "mmp23b", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "lysmd1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "cskmt", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "mcf2", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "nsg2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cfap70", filterType: "BestCase", aaLeft: "615", aaRight: "639", ntLeft: "1843", ntRight: "1917" },
  { key: "onecut3", filterType: "FailedAllFilters", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "thbs2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "fbxo39", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "jrkl", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "znf154", filterType: "BestCase", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "rab9b", filterType: "SecondBest", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "abhd8", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "acy3", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "itpripl1", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "rexo1", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "hdac9", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "potej", filterType: "FailedAllFilters", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "copb2", filterType: "BestCase", aaLeft: "834", aaRight: "858", ntLeft: "2500", ntRight: "2574" },
  { key: "aqp4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "lilrb5", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "cfap276", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "rtkn2", filterType: "BestCase", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "supt20h", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "erfl", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "lgals9", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "cdk1", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "trim33", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "h2ac17", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "or4x1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "cwh43", filterType: "BestCase", aaLeft: "646", aaRight: "670", ntLeft: "1936", ntRight: "2010" },
  { key: "cckar", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "cryzl1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "rps6kc1", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "slc8b1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "sf3b4", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "plgrkt", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "fam209a", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "synj2bp", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "spsb2", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "mbd1", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "znf568", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "or1d5", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "neurog2", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ccdc32", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "tmem192", filterType: "FailedAllFilters", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "etv5", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "ccdc172", filterType: "FailedAllFilters", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "ssr4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "zfat", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "ccn4", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "tshz2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "ppp2r2a", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "arhgef35", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "ttll1", filterType: "SecondBest", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "thy1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "me2", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "ptp4a2", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "tcea2", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "rsl24d1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gal3st4", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "nob1", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "fam3b", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "niban3", filterType: "FailedAllFilters", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "plat", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "nrros", filterType: "BestCase", aaLeft: "624", aaRight: "648", ntLeft: "1870", ntRight: "1944" },
  { key: "sf3a3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "stt3a", filterType: "SecondBest", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "kiaa0586", filterType: "BestCase", aaLeft: "1018", aaRight: "1042", ntLeft: "3052", ntRight: "3126" },
  { key: "dcaf6", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "fer1l6", filterType: "BestCase", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "klrg1", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "cd248", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "oplah", filterType: "BestCase", aaLeft: "711", aaRight: "735", ntLeft: "2131", ntRight: "2205" },
  { key: "nsmf", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "greb1l", filterType: "BestCase", aaLeft: "1207", aaRight: "1231", ntLeft: "3619", ntRight: "3693" },
  { key: "cdh6", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "rtn4rl2", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "entpd4", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "h2ac20", filterType: "SecondBest", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "dglucy", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "snrnp35", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "fshb", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "trmt9b", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "znf722", filterType: "FailedAllFilters", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "naaladl2", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "pdlim4", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "selplg", filterType: "FailedAllFilters", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "csnk2a2", filterType: "SecondBest", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "lilra2", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tex264", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "isl1", filterType: "SecondBest", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "apeh", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "rab36", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "tmem101", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dcps", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "rora", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "dnah9", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "arhgap44", filterType: "BestCase", aaLeft: "741", aaRight: "765", ntLeft: "2221", ntRight: "2295" },
  { key: "brs3", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "c1orf162", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "vta1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tnks", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "glyatl3", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "adamtsl3", filterType: "BestCase", aaLeft: "1528", aaRight: "1552", ntLeft: "4582", ntRight: "4656" },
  { key: "kctd4", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "asb18", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "lrpap1", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "cthrc1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "fam200b", filterType: "BestCase", aaLeft: "613", aaRight: "637", ntLeft: "1837", ntRight: "1911" },
  { key: "baz1a", filterType: "BestCase", aaLeft: "763", aaRight: "787", ntLeft: "2287", ntRight: "2361" },
  { key: "alg11", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "foxs1", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "gdap2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "znf786", filterType: "FailedAllFilters", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "sh3pxd2a", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "lrfn4", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "ncbp2l", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "kcnf1", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "zfr", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "xrn1", filterType: "BestCase", aaLeft: "1187", aaRight: "1211", ntLeft: "3559", ntRight: "3633" },
  { key: "tmem120b", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "cstf1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "rspo3", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "nkx2-6", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "plglb2", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "cyp11b1", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "coq5", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "slc29a1", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "exph5", filterType: "FailedAllFilters", aaLeft: "1524", aaRight: "1548", ntLeft: "4570", ntRight: "4644" },
  { key: "lrrc2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "dusp12", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "spdye11", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "pm20d2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "cdc37", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "klhl7", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ctns", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "rasgef1a", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "adat3", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "prh2", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "stard13", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "spata1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "rassf9", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "ptprcap", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "nhs", filterType: "BestCase", aaLeft: "1288", aaRight: "1312", ntLeft: "3862", ntRight: "3936" },
  { key: "armc5", filterType: "BestCase", aaLeft: "709", aaRight: "733", ntLeft: "2125", ntRight: "2199" },
  { key: "tph1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "rgs7", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "ier2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "mcee", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "asap1", filterType: "BestCase", aaLeft: "1013", aaRight: "1037", ntLeft: "3037", ntRight: "3111" },
  { key: "c19orf53", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "kif1c", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "slc13a3", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "trpm3", filterType: "BestCase", aaLeft: "1610", aaRight: "1634", ntLeft: "4828", ntRight: "4902" },
  { key: "tra2b", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "lce1b", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "slc46a3", filterType: "FailedAllFilters", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "atf4", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "slc1a4", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "slf1", filterType: "BestCase", aaLeft: "980", aaRight: "1004", ntLeft: "2938", ntRight: "3012" },
  { key: "mras", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "ccdc96", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "taf7l", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "or1n1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "rilpl2", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "pde3b", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "ficd", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "togaram2", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "ttc29", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cst6", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "mpped1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "igf2bp3", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "tmem268", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "pramef7", filterType: "FailedAllFilters", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "cdan1", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "zmat2", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ube2ql1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf692", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "lrrc3c", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "myo3b", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "ciapin1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "rnf130", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "spon2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "rhbdd3", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "scrt2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "hoxd8", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "abhd14a", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "p3h4", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "ocln", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "hspa6", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "dhrs4", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "eci2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "pla2g2c", filterType: "FourthBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "moap1", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "bche", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "sp3", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "stmn1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "bicc1", filterType: "BestCase", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "ddi1", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "star", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "fgf3", filterType: "SecondBest", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "agrp", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "gdf10", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pik3r5", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "bace2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "hmcn2", filterType: "BestCase", aaLeft: "1066", aaRight: "1090", ntLeft: "3196", ntRight: "3270" },
  { key: "prom1", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "btbd17", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "smg5", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "pbx4", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "bud23", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "slc17a8", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "sgms1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "rbmx", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "sfmbt2", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "arid4a", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "lpcat4", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "llph", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ulk3", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "hspb1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mrpl35", filterType: "FourthBest", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ggps1", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "adam29", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "or4c46", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "nop10", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cavin1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "rdh5", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "polr3b", filterType: "SecondBest", aaLeft: "1076", aaRight: "1100", ntLeft: "3226", ntRight: "3300" },
  { key: "treh", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "tada2a", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mospd2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "zfp62", filterType: "FailedAllFilters", aaLeft: "693", aaRight: "717", ntLeft: "2077", ntRight: "2151" },
  { key: "mfap3", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "tmem132a", filterType: "BestCase", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "bcl2l13", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "c8orf89", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "mmp14", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "zbed2", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "tbxt", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "srcin1", filterType: "BestCase", aaLeft: "841", aaRight: "865", ntLeft: "2521", ntRight: "2595" },
  { key: "basp1", filterType: "FailedAllFilters", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "arsh", filterType: "FailedAllFilters", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "sec22b", filterType: "SecondBest", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "map1lc3b2", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "synj1", filterType: "BestCase", aaLeft: "1254", aaRight: "1278", ntLeft: "3760", ntRight: "3834" },
  { key: "tpm4", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "selenos", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "fkbp8", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "slc4a1ap", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "cerk", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "uckl1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ndufab1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "soga3", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "rhoj", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "mapkapk3", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "gimap7", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "tmem150b", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "c7orf50", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "riiad1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "nmt1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gab1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "znf599", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "ctag2", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tafa1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "pde6g", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "abt1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "evc2", filterType: "BestCase", aaLeft: "817", aaRight: "841", ntLeft: "2449", ntRight: "2523" },
  { key: "sorbs3", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "cyp8b1", filterType: "FailedAllFilters", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "tlcd5", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "tmem163", filterType: "SecondBest", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "ccdc183", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "arhgap26", filterType: "BestCase", aaLeft: "629", aaRight: "653", ntLeft: "1885", ntRight: "1959" },
  { key: "tcf21", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "fbxl3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc39a2", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "sh2d7", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "c1orf100", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "fam149a", filterType: "FailedAllFilters", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "g6pc1", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "syce1l", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "rims3", filterType: "SecondBest", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "cdc42bpb", filterType: "BestCase", aaLeft: "1608", aaRight: "1632", ntLeft: "4822", ntRight: "4896" },
  { key: "wdtc1", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "fbxo4", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "dmrtc1b", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "mmp24os", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "fancf", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "bspry", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "zfand1", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "gdpd5", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "kansl3", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "arap2", filterType: "BestCase", aaLeft: "1593", aaRight: "1617", ntLeft: "4777", ntRight: "4851" },
  { key: "mtif2", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "acsm2b", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "pnma5", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "ascl5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "kidins220", filterType: "BestCase", aaLeft: "1293", aaRight: "1317", ntLeft: "3877", ntRight: "3951" },
  { key: "or6n1", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "slc6a4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "acss2", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "tent4a", filterType: "BestCase", aaLeft: "637", aaRight: "661", ntLeft: "1909", ntRight: "1983" },
  { key: "runx1", filterType: "SecondBest", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "aifm3", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "evi2b", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "kcnc4", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "cyfip2", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "slc41a3", filterType: "FailedAllFilters", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "srd5a3", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "trhr", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "st8sia2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "pla2g4a", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "asgr2", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "clasp2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "clc", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "sox6", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "ppp1r8", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "haspin", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "asxl3", filterType: "BestCase", aaLeft: "1533", aaRight: "1557", ntLeft: "4597", ntRight: "4671" },
  { key: "metap1d", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "rest", filterType: "FailedAllFilters", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "cited2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "lrif1", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "usp7", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "mex3d", filterType: "FailedAllFilters", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "coprs", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "sh3kbp1", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "inpp5e", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "sdk1", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "atp5mf", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "map2", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "dgkq", filterType: "BestCase", aaLeft: "544", aaRight: "568", ntLeft: "1630", ntRight: "1704" },
  { key: "pga5", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "ube2q1", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "or4c13", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "uba5", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "zfr2", filterType: "FailedAllFilters", aaLeft: "856", aaRight: "880", ntLeft: "2566", ntRight: "2640" },
  { key: "rnf152", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "abcf2", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "krbox1", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "igfbp7", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "zfp37", filterType: "FailedAllFilters", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "nos1ap", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "mief1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "myh1", filterType: "BestCase", aaLeft: "861", aaRight: "885", ntLeft: "2581", ntRight: "2655" },
  { key: "satb1", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "scamp4", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "sema3e", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "iba57", filterType: "FailedAllFilters", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "tlr10", filterType: "FailedAllFilters", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "ovol1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "fes", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "wiz", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "krtap19-2", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "wfdc10b", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sat2", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "isca1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ntn1", filterType: "SecondBest", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "hao1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ccdc88a", filterType: "BestCase", aaLeft: "1741", aaRight: "1765", ntLeft: "5221", ntRight: "5295" },
  { key: "acot11", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "ptprd", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "apobec2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mrpl57", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "rft1", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "coro1a", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "acad9", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "rnf165", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "lmnb2", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "emc4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "twist2", filterType: "SecondBest", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "tmem86a", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "pim1", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "prdx4", filterType: "SecondBest", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "pigbos1", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "itpkb", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "spire1", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "fzd6", filterType: "BestCase", aaLeft: "656", aaRight: "680", ntLeft: "1966", ntRight: "2040" },
  { key: "pabpc1", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "wdr3", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "commd2", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "ehmt2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "or2a5", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "ankmy1", filterType: "BestCase", aaLeft: "720", aaRight: "744", ntLeft: "2158", ntRight: "2232" },
  { key: "crhr2", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "ubap1l", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "chrm5", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "gadd45a", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "znf638", filterType: "BestCase", aaLeft: "1824", aaRight: "1848", ntLeft: "5470", ntRight: "5544" },
  { key: "leutx", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "l3mbtl3", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "tp53bp1", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "mycbpap", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "mpp3", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "cd14", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "tas2r40", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "spegnb", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "znf445", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "mon2", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "fbn1", filterType: "BestCase", aaLeft: "2741", aaRight: "2765", ntLeft: "8221", ntRight: "8295" },
  { key: "cyp3a43", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "cpsf4", filterType: "SecondBest", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "pou3f2", filterType: "SecondBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "hdac7", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "prdm10", filterType: "BestCase", aaLeft: "969", aaRight: "993", ntLeft: "2905", ntRight: "2979" },
  { key: "cfap126", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "abhd6", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "cacul1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "srp9", filterType: "FourthBest", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "nars1", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "bloc1s6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sae1", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "nfkb2", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "gins3", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "soat1", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "acyp2", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "polk", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ct45a9", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "krtap6-3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pgs1", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "nsun2", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "rpl35a", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "slc6a13", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "kiaa0825", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "sec14l5", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "cyp2b6", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "aup1", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "efcab2", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "cdk18", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "plch2", filterType: "BestCase", aaLeft: "1114", aaRight: "1138", ntLeft: "3340", ntRight: "3414" },
  { key: "chmp3", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "inava", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "rgs14", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "tmprss4", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "dusp7", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "mgat5b", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "hif1a", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "rbm8a", filterType: "SecondBest", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "zbtb1", filterType: "FailedAllFilters", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "or2b2", filterType: "FailedAllFilters", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "ada", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "tmem225b", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "dhx16", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ankrd7", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "plekhh3", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "cd8b2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "nxpe3", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "slc35e4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "avpi1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "spata12", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "crb3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tamalin", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "rbm12", filterType: "FailedAllFilters", aaLeft: "685", aaRight: "709", ntLeft: "2053", ntRight: "2127" },
  { key: "agpat5", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "mogat2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "znf551", filterType: "FailedAllFilters", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "wdr24", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "pdk4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "dcaf8", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "elp4", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "rtl8a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "vac14", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "kremen1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "or4f3", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "uqcrq", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "fam117a", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "grhl1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "syt15b", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "il4r", filterType: "FailedAllFilters", aaLeft: "756", aaRight: "780", ntLeft: "2266", ntRight: "2340" },
  { key: "mphosph10", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tex15", filterType: "FailedAllFilters", aaLeft: "1978", aaRight: "2002", ntLeft: "5932", ntRight: "6006" },
  { key: "klhl42", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "cdh8", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ephb3", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "rtl5", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "gas2", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "nme2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mapk8ip1", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "tnip2", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "snrpg", filterType: "FourthBest", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rab38", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "znf233", filterType: "FailedAllFilters", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "slc25a47", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "brd2", filterType: "BestCase", aaLeft: "579", aaRight: "603", ntLeft: "1735", ntRight: "1809" },
  { key: "odf3l2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "cnmd", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "serpini1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "ap1g2", filterType: "BestCase", aaLeft: "688", aaRight: "712", ntLeft: "2062", ntRight: "2136" },
  { key: "srfbp1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "crip1", filterType: "FourthBest", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "mageb17", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "gjc2", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "dnmt1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "il17b", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "poted", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "setd1a", filterType: "BestCase", aaLeft: "1179", aaRight: "1203", ntLeft: "3535", ntRight: "3609" },
  { key: "fmc1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "tex22", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "kir3dl2", filterType: "FailedAllFilters", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "epha4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dr1", filterType: "SecondBest", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "h2ac19", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lefty2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "lgals7", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ecscr", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "msh6", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "gcg", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "wdr13", filterType: "SecondBest", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "znf566", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "pnpt1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "sfxn3", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "psen2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cfl2", filterType: "SecondBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "akr1a1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "taco1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "adcy4", filterType: "BestCase", aaLeft: "681", aaRight: "705", ntLeft: "2041", ntRight: "2115" },
  { key: "tctn2", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "thyn1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "slc35f3", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "c10orf143", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "hmgxb3", filterType: "BestCase", aaLeft: "1001", aaRight: "1025", ntLeft: "3001", ntRight: "3075" },
  { key: "banf2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "cnot10", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "spata25", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "aurkb", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rbm25", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "abtb2", filterType: "BestCase", aaLeft: "987", aaRight: "1011", ntLeft: "2959", ntRight: "3033" },
  { key: "eif2s3", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "ifit1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "etda", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "rrm1", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "psmc2", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "zyg11b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ceacam16", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "znf138", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "rabl6", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "rpl39", filterType: "FourthBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "cfi", filterType: "FailedAllFilters", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "usp37", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "alkbh1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "pdcd6ip", filterType: "BestCase", aaLeft: "771", aaRight: "795", ntLeft: "2311", ntRight: "2385" },
  { key: "eri3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "nubp1", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "h1-3", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "itsn2", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "tmtc1", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "ubqln4", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "znf385d", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "tceanc2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "ikzf5", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "dtx3l", filterType: "FailedAllFilters", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "sqor", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "aspdh", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "golga7", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "efna4", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "dok5", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "pou4f2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "zbtb18", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "bahd1", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "tubb2b", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "smurf2", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "or51l1", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "c8orf76", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "nek5", filterType: "FailedAllFilters", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "alg10b", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "bmerb1", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "ttc7b", filterType: "BestCase", aaLeft: "726", aaRight: "750", ntLeft: "2176", ntRight: "2250" },
  { key: "ankra2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tbxa2r", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "hnrnpa1l3", filterType: "FourthBest", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "znf345", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "smu1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "reep1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "b3gat1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "fen1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "svip", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "hla-e", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "kynu", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "taf10", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "krtap1-3", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "nek11", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "smim28", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ppic", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ercc6", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "timm22", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dcaf4l2", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "cox6a2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "efnb3", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "epb41l4a", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "rax", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tsbp1", filterType: "FailedAllFilters", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "ranbp9", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "slc7a10", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "znf280b", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "nptx2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "trappc1", filterType: "SecondBest", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "pdyn", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "or5b2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "dag1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "strit1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "casr", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "sun5", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "ppil3", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "smim1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tmem255b", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "aldh1a2", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "arhgap4", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "znf410", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "oxt", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "cpz", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "csf2", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "marchf2", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "insig1", filterType: "SecondBest", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "ceacam21", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kng1", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "dcaf12", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "fxyd3", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "slc25a25", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "magee2", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pasd1", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "agbl3", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "erp29", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "tbc1d8b", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "spem2", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "znf251", filterType: "FailedAllFilters", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "fam167a", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "nobox", filterType: "FailedAllFilters", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "sh3tc1", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "gspt1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "cdc20b", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "kcnj9", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "fam149b1", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "dctd", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gpr149", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "spint4", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "zfhx2", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "wnt6", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "krt80", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "klhl20", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "trim68", filterType: "FailedAllFilters", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "tceal9", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "c1orf68", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "tmem79", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "foxb2", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "dipk1a", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mb", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "gpd2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "cds1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "wdr46", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cdh24", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "dnaaf10", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "ccm2l", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "plcxd2", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "copz2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "c12orf71", filterType: "FailedAllFilters", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "pxylp1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "plekhn1", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "zscan5c", filterType: "FailedAllFilters", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "vma21", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "ccdc69", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "h2bc6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "klhdc9", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "ltb4r", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "rala", filterType: "SecondBest", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "vsig8", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "tdrd5", filterType: "BestCase", aaLeft: "798", aaRight: "822", ntLeft: "2392", ntRight: "2466" },
  { key: "erich2", filterType: "FailedAllFilters", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "ist1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "rbm47", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "zc3h12c", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "egfl7", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cr1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "kir2dl1", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "hmgb1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "procr", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "flnc", filterType: "BestCase", aaLeft: "1738", aaRight: "1762", ntLeft: "5212", ntRight: "5286" },
  { key: "tram2", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "myo15a", filterType: "BestCase", aaLeft: "2482", aaRight: "2506", ntLeft: "7444", ntRight: "7518" },
  { key: "or4c6", filterType: "FourthBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "cdh13", filterType: "BestCase", aaLeft: "682", aaRight: "706", ntLeft: "2044", ntRight: "2118" },
  { key: "c2orf42", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "osbp2", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "pcdhb15", filterType: "FailedAllFilters", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "pja2", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "oas3", filterType: "FailedAllFilters", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "sirt5", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "smpdl3b", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "jsrp1", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "cish", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "krtap5-8", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "micu1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "pds5a", filterType: "BestCase", aaLeft: "1275", aaRight: "1299", ntLeft: "3823", ntRight: "3897" },
  { key: "klhl17", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "higd1b", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "epor", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "slc25a12", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "erbb2", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "smc1a", filterType: "SecondBest", aaLeft: "947", aaRight: "971", ntLeft: "2839", ntRight: "2913" },
  { key: "znf266", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "stkld1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "pheta1", filterType: "FailedAllFilters", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "spen", filterType: "BestCase", aaLeft: "2467", aaRight: "2491", ntLeft: "7399", ntRight: "7473" },
  { key: "vps37d", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "gp1ba", filterType: "FailedAllFilters", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "appl2", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "grep1", filterType: "FailedAllFilters", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "ido1", filterType: "FailedAllFilters", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "znf81", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "sgce", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "chtf18", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "loxhd1", filterType: "BestCase", aaLeft: "1650", aaRight: "1674", ntLeft: "4948", ntRight: "5022" },
  { key: "ebna1bp2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "gcsaml", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "clcn3", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "pappa2", filterType: "BestCase", aaLeft: "1315", aaRight: "1339", ntLeft: "3943", ntRight: "4017" },
  { key: "irx1", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "wwc2", filterType: "BestCase", aaLeft: "974", aaRight: "998", ntLeft: "2920", ntRight: "2994" },
  { key: "dlat", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "lipk", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "tm4sf20", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "phip", filterType: "BestCase", aaLeft: "1515", aaRight: "1539", ntLeft: "4543", ntRight: "4617" },
  { key: "ndnf", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "ankrd33", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "hecw1", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "adgrd1", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "tmem243", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "or51b2", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "fhip2b", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "ndrg4", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "rubcnl", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "setmar", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "deup1", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "pnliprp3", filterType: "FailedAllFilters", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "lsamp", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cby3", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "e2f2", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "znf391", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "adgrg6", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "atad2", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "map7d1", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "rrp12", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "or2j3", filterType: "FailedAllFilters", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "zscan23", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "coq8a", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "kcnj14", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "ckm", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "zc3h6", filterType: "BestCase", aaLeft: "1093", aaRight: "1117", ntLeft: "3277", ntRight: "3351" },
  { key: "tbx10", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "anln", filterType: "FailedAllFilters", aaLeft: "700", aaRight: "724", ntLeft: "2098", ntRight: "2172" },
  { key: "csh1", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "stag3", filterType: "BestCase", aaLeft: "1065", aaRight: "1089", ntLeft: "3193", ntRight: "3267" },
  { key: "parp15", filterType: "FailedAllFilters", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "tbrg4", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "mfsd11", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "mrps24", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "hsd11b1", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "tlr2", filterType: "FailedAllFilters", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "chad", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or5l1", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ceacam5", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "scnn1g", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "glra2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gabra2", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "cacng1", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "adra2c", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "irgq", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "psip1", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "ube2b", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pltp", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "pink1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "prr16", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "nlgn2", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "eid1", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "stard3", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "evi5l", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "n4bp2l1", filterType: "SecondBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "vcpkmt", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "zdhhc2", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "fshr", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "crybb1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "pcdh17", filterType: "BestCase", aaLeft: "1052", aaRight: "1076", ntLeft: "3154", ntRight: "3228" },
  { key: "fam186a", filterType: "FailedAllFilters", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "gpr55", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "col17a1", filterType: "BestCase", aaLeft: "1045", aaRight: "1069", ntLeft: "3133", ntRight: "3207" },
  { key: "lig4", filterType: "BestCase", aaLeft: "611", aaRight: "635", ntLeft: "1831", ntRight: "1905" },
  { key: "b3gnt5", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "ppp6c", filterType: "SecondBest", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "tead4", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "slc4a7", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "erg", filterType: "BestCase", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "ndufaf8", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "snap47", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "or4n2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "lingo3", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "foxl1", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "fgf10", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "sst", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gnal", filterType: "SecondBest", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "rev1", filterType: "BestCase", aaLeft: "1122", aaRight: "1146", ntLeft: "3364", ntRight: "3438" },
  { key: "sar1a", filterType: "SecondBest", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "fam20a", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "foxo6", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "acoxl", filterType: "BestCase", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "ntrk2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "tmem106c", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "fubp1", filterType: "SecondBest", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "gpr62", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "pcdh20", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "znf585b", filterType: "FailedAllFilters", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "icam4", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "serpinb7", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "exosc10", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "znf285", filterType: "FailedAllFilters", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "mib2", filterType: "BestCase", aaLeft: "799", aaRight: "823", ntLeft: "2395", ntRight: "2469" },
  { key: "uimc1", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "rbp3", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "ca9", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "ackr3", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "rbbp4", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "or1q1", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "spns1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "zscan2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tmem170a", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "dsc2", filterType: "BestCase", aaLeft: "715", aaRight: "739", ntLeft: "2143", ntRight: "2217" },
  { key: "taf3", filterType: "FailedAllFilters", aaLeft: "746", aaRight: "770", ntLeft: "2236", ntRight: "2310" },
  { key: "adra1d", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "pard6g", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "ide", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "szrd1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "nphs2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "c4orf33", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "fam199x", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ralgps1", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "tbx4", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "cldn3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "pcdh11y", filterType: "BestCase", aaLeft: "660", aaRight: "684", ntLeft: "1978", ntRight: "2052" },
  { key: "spata31d1", filterType: "FailedAllFilters", aaLeft: "1397", aaRight: "1421", ntLeft: "4189", ntRight: "4263" },
  { key: "adamts16", filterType: "BestCase", aaLeft: "1100", aaRight: "1124", ntLeft: "3298", ntRight: "3372" },
  { key: "lnpk", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "vat1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "tpmt", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "adgrb3", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "relt", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "ckmt1b", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "mak", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "aspg", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "cdpf1", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "uqcc2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "tmem221", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "ccz1b", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "prickle4", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "odad4", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "nbeal1", filterType: "BestCase", aaLeft: "1329", aaRight: "1353", ntLeft: "3985", ntRight: "4059" },
  { key: "hspa1l", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "tmem39a", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "dnajc17", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "cltcl1", filterType: "BestCase", aaLeft: "1053", aaRight: "1077", ntLeft: "3157", ntRight: "3231" },
  { key: "slc22a17", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "scaf4", filterType: "BestCase", aaLeft: "1093", aaRight: "1117", ntLeft: "3277", ntRight: "3351" },
  { key: "tp63", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "pfkfb3", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "dynll2", filterType: "SecondBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "hdhd5", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "ubxn2b", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "eepd1", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "znf491", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "sirpb2", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "or56a5", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "serinc3", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "hoxc6", filterType: "SecondBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "setd3", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "lcp2", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "gatb", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "pcdhga7", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "kank3", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "tpd52l3", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ppp1r3f", filterType: "BestCase", aaLeft: "730", aaRight: "754", ntLeft: "2188", ntRight: "2262" },
  { key: "hap1", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "ranbp10", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "trim6", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "fstl1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "aste1", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "ankrd66", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "herc6", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "mdp1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "csn3", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "tesk1", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "aebp2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "c16orf96", filterType: "FailedAllFilters", aaLeft: "1103", aaRight: "1127", ntLeft: "3307", ntRight: "3381" },
  { key: "cyb5r1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "fam114a2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "daglb", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tmem216", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ston2", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "sod1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "cpd", filterType: "BestCase", aaLeft: "875", aaRight: "899", ntLeft: "2623", ntRight: "2697" },
  { key: "nup50", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "igsf8", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ventx", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "sys1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "cnga1", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "prnp", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "cox7b", filterType: "FourthBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "drd4", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "srsf3", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "prkcg", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "snap25", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "wt1", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "cisd3", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "nudt11", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "foxj3", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "mettl26", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "mterf1", filterType: "FailedAllFilters", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "fra10ac1", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "ngfr", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "fastkd1", filterType: "FailedAllFilters", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "traf4", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "prr7", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "gpr37", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "hla-dmb", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "lmbrd1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "scel", filterType: "FailedAllFilters", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "kpna4", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ndufb4", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ngb", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ces5a", filterType: "FourthBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ripk3", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "arhgef2", filterType: "BestCase", aaLeft: "903", aaRight: "927", ntLeft: "2707", ntRight: "2781" },
  { key: "tnfsf10", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "tjp1", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "pard3b", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "cabp5", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "rif1", filterType: "BestCase", aaLeft: "2224", aaRight: "2248", ntLeft: "6670", ntRight: "6744" },
  { key: "sh3bp5l", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "zic5", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "dlx6", filterType: "SecondBest", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "col10a1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "plbd2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "trpc4ap", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cacnb4", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "krtap9-7", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "dclre1b", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "enpp3", filterType: "BestCase", aaLeft: "544", aaRight: "568", ntLeft: "1630", ntRight: "1704" },
  { key: "frs3", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "vash1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "krt36", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "trpt1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "c4orf51", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "msmo1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "kif26a", filterType: "BestCase", aaLeft: "586", aaRight: "610", ntLeft: "1756", ntRight: "1830" },
  { key: "suclg2", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "fam220a", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "naa16", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "lcor", filterType: "FailedAllFilters", aaLeft: "777", aaRight: "801", ntLeft: "2329", ntRight: "2403" },
  { key: "tbc1d3d", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "fam50a", filterType: "SecondBest", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "tst", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "dmrtc1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "cxorf58", filterType: "FailedAllFilters", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "celf4", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "tff3", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pik3c2b", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "hcfc2", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "fcer2", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "galnt9", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ciart", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "ccdc3", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "alg5", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "mcidas", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "ndufa3", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "blk", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "rnf227", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "gabrq", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "popdc3", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "hnf4a", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "dhrs11", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "or6q1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "mafa", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "znf707", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "pfn2", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "mst1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "cilk1", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "suco", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "trim21", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "c1orf21", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tbcc", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "prss57", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "ucn2", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "armh3", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "casp4", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "rab13", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "tlcd3a", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "jade2", filterType: "BestCase", aaLeft: "712", aaRight: "736", ntLeft: "2134", ntRight: "2208" },
  { key: "ano7", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "vcy1b", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "zfyve16", filterType: "BestCase", aaLeft: "971", aaRight: "995", ntLeft: "2911", ntRight: "2985" },
  { key: "srf", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "cmc2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znrf1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "pbrm1", filterType: "BestCase", aaLeft: "1106", aaRight: "1130", ntLeft: "3316", ntRight: "3390" },
  { key: "ndufs7", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "nub1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "snw1", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "nup205", filterType: "BestCase", aaLeft: "970", aaRight: "994", ntLeft: "2908", ntRight: "2982" },
  { key: "ccdc160", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "guca1b", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "alpk3", filterType: "BestCase", aaLeft: "1670", aaRight: "1694", ntLeft: "5008", ntRight: "5082" },
  { key: "arhgef10", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "meltf", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "g2e3", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "rbm39", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "dcxr", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "map4k5", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "or52n1", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "mrps18c", filterType: "FourthBest", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ik", filterType: "SecondBest", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "atpaf2", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "vps72", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "cxcl6", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "atg9a", filterType: "BestCase", aaLeft: "743", aaRight: "767", ntLeft: "2227", ntRight: "2301" },
  { key: "zc3h7a", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "fbll1", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "fam90a14", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "wdr38", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "rtp2", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "gal", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pianp", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "tuba3e", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "smyd5", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "vps45", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "rab27a", filterType: "SecondBest", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "rps6ka4", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "upf1", filterType: "BestCase", aaLeft: "1013", aaRight: "1037", ntLeft: "3037", ntRight: "3111" },
  { key: "upk1a", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "nrg4", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "elapor1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "mpst", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "lin9", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "rpp14", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "siglec8", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "ccdc157", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "tubd1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ppp2r3c", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "lamtor1", filterType: "SecondBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "cog8", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "rplp0", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "c17orf80", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "twf2", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "ccdc17", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "mxd1", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "rab24", filterType: "SecondBest", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "gja9", filterType: "FailedAllFilters", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "mgst3", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "zfyve21", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "mpeg1", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "rbbp8nl", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "loc389895", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "homer2", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "cep63", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "pdzk1", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "il1rl2", filterType: "FailedAllFilters", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "lcorl", filterType: "FailedAllFilters", aaLeft: "1285", aaRight: "1309", ntLeft: "3853", ntRight: "3927" },
  { key: "ctsf", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gpr137", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "stxbp5l", filterType: "BestCase", aaLeft: "903", aaRight: "927", ntLeft: "2707", ntRight: "2781" },
  { key: "pkd2l2", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "znf730", filterType: "FailedAllFilters", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "esr2", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "calm2", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "ptma", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "snrnp27", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "wdcp", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "mns1", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "trim16", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "ell3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "brms1l", filterType: "SecondBest", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "sde2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "porcn", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "trmt13", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "ddx20", filterType: "BestCase", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "sycp3", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "rhbdl2", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "phox2a", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "rce1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ghrl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gpm6a", filterType: "SecondBest", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "pdzrn3", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "iqcm", filterType: "FailedAllFilters", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "pcdhb1", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "sptbn2", filterType: "BestCase", aaLeft: "829", aaRight: "853", ntLeft: "2485", ntRight: "2559" },
  { key: "phf20l1", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "atxn7l3b", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "ferd3l", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "st13", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "dgat2l6", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "gprin1", filterType: "FailedAllFilters", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "hormad2", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "gnb5", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "znf613", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "defb105b", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "med20", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "tmpo", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "pdcd10", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "aldh3a1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rab3gap1", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "pygl", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "rd3l", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tmem88b", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "h4c12", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "agrn", filterType: "BestCase", aaLeft: "512", aaRight: "536", ntLeft: "1534", ntRight: "1608" },
  { key: "rnf186", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "tle3", filterType: "BestCase", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "c1galt1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "aftph", filterType: "BestCase", aaLeft: "769", aaRight: "793", ntLeft: "2305", ntRight: "2379" },
  { key: "qpct", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "znf624", filterType: "FailedAllFilters", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "med17", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "lrrc8e", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "nup98", filterType: "BestCase", aaLeft: "1056", aaRight: "1080", ntLeft: "3166", ntRight: "3240" },
  { key: "acox2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "rpe", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "pcdha6", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "card18", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "h2ab1", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "stap2", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "kbtbd8", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "cacna1c", filterType: "BestCase", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "scyl3", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "pdha1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "nasp", filterType: "BestCase", aaLeft: "627", aaRight: "651", ntLeft: "1879", ntRight: "1953" },
  { key: "znf146", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "usp49", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "wipi2", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "pou6f1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "hs3st4", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "lrp3", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "c5orf47", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "rad52", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "tipin", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "crat", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "ddx17", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ppial4e", filterType: "FourthBest", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "bahcc1", filterType: "BestCase", aaLeft: "1279", aaRight: "1303", ntLeft: "3835", ntRight: "3909" },
  { key: "or11l1", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "tmem52", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "taar9", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "tcn1", filterType: "FailedAllFilters", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "apip", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "faslg", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "hykk", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "spink4", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "pan2", filterType: "BestCase", aaLeft: "743", aaRight: "767", ntLeft: "2227", ntRight: "2301" },
  { key: "colec10", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hdac10", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "selenom", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "il2rb", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "cntf", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "def6", filterType: "BestCase", aaLeft: "596", aaRight: "620", ntLeft: "1786", ntRight: "1860" },
  { key: "golph3", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "ccdc42", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "lypd6b", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "defb121", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "yipf5", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "dis3", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "fbxl17", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "ikbkb", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "or4k17", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ldhb", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "cfap206", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "kcnh2", filterType: "BestCase", aaLeft: "1076", aaRight: "1100", ntLeft: "3226", ntRight: "3300" },
  { key: "nfe2l2", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "bcl2", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ugt3a1", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "fscn3", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "gpr162", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "ecd", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "znf765", filterType: "FailedAllFilters", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "map3k5", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "prss35", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "hyal4", filterType: "BestCase", aaLeft: "439", aaRight: "463", ntLeft: "1315", ntRight: "1389" },
  { key: "scn1a", filterType: "BestCase", aaLeft: "1841", aaRight: "1865", ntLeft: "5521", ntRight: "5595" },
  { key: "c1orf43", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "clvs2", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "trim43", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "lrcol1", filterType: "FailedAllFilters", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "tox3", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "dicer1", filterType: "BestCase", aaLeft: "1596", aaRight: "1620", ntLeft: "4786", ntRight: "4860" },
  { key: "gpr155", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "spc24", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "frmd6", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "tpsab1", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "hsf2bp", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "lrguk", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "gpx5", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "c1orf74", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "trim74", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "slc25a39", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "fbxl20", filterType: "SecondBest", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "prf1", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "larp1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "grxcr1", filterType: "FailedAllFilters", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "chit1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "nptxr", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "rln3", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "kcnk5", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "ndufv2", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "tubb3", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "madcam1", filterType: "FailedAllFilters", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "dpy19l2", filterType: "FailedAllFilters", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "mrgprd", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "defb116", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ppp2r5a", filterType: "SecondBest", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "htr3d", filterType: "FourthBest", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "lrrc43", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "hcar1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "tmem65", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "rgsl1", filterType: "FailedAllFilters", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "gm2a", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "sacs", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ggn", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "kcnmb2", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "ndst4", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "arl2bp", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "c2orf69", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "dnajb1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "catsper2", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "eva1a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "izumo3", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "trmt112", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cryz", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "zbtb33", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "znf671", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "c3orf80", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "znf780a", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "slc2a13", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "pde4dip", filterType: "BestCase", aaLeft: "2257", aaRight: "2281", ntLeft: "6769", ntRight: "6843" },
  { key: "gapdh", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "lins1", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "twsg1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "il20", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "arl6", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "tgs1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "smim34", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "psd2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "or2aj1", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "rps28", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "kctd11", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "c10orf99", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "kndc1", filterType: "BestCase", aaLeft: "1370", aaRight: "1394", ntLeft: "4108", ntRight: "4182" },
  { key: "phex", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ubap2", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "cbarp", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "idh3a", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "or51s1", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "dctn3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "aldh4a1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "clrn2", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "rpl12", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "slc26a5", filterType: "BestCase", aaLeft: "715", aaRight: "739", ntLeft: "2143", ntRight: "2217" },
  { key: "naca", filterType: "FailedAllFilters", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "cybb", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "c22orf15", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "drp2", filterType: "BestCase", aaLeft: "813", aaRight: "837", ntLeft: "2437", ntRight: "2511" },
  { key: "stard3nl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gtf3c4", filterType: "BestCase", aaLeft: "597", aaRight: "621", ntLeft: "1789", ntRight: "1863" },
  { key: "fip1l1", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "ddx42", filterType: "BestCase", aaLeft: "867", aaRight: "891", ntLeft: "2599", ntRight: "2673" },
  { key: "tbl2", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "bub1b", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "slc9a3r1", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "chml", filterType: "FailedAllFilters", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "washc4", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "c1qtnf2", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "eif5b", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "fbxw11", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "aven", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "nlrp4", filterType: "FailedAllFilters", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "abhd18", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "vgll3", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "itgb8", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "stpg2", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "echdc3", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "znf124", filterType: "FailedAllFilters", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "lpar5", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "rasa3", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "letm2", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "midn", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "treml2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "c2cd2", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "pou1f1", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "fam83a", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "dido1", filterType: "FailedAllFilters", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "nedd1", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "psg5", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cemp1", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "marchf10", filterType: "FailedAllFilters", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "ogt", filterType: "SecondBest", aaLeft: "743", aaRight: "767", ntLeft: "2227", ntRight: "2301" },
  { key: "capn3", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "p2rx7", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "tvp23c", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "tbpl1", filterType: "SecondBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "cd151", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "znf646", filterType: "BestCase", aaLeft: "1026", aaRight: "1050", ntLeft: "3076", ntRight: "3150" },
  { key: "zdhhc18", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "vrk3", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "calr", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "prr5l", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "or2z1", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "cyb5d1", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "jcad", filterType: "FailedAllFilters", aaLeft: "709", aaRight: "733", ntLeft: "2125", ntRight: "2199" },
  { key: "znf135", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "stox2", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "rpl34", filterType: "SecondBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cfd", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "senp6", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "c5orf34", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "dph2", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "sox12", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "max", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "hikeshi", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "eps8", filterType: "BestCase", aaLeft: "645", aaRight: "669", ntLeft: "1933", ntRight: "2007" },
  { key: "pid1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "acap2", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "lair1", filterType: "FailedAllFilters", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "rad21", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "epn3", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "snapc5", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pgk2", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "krtap10-3", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "eef1akmt1", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "thsd1", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "p2ry6", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "nek8", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "eddm3a", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "mtmr2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "st3gal3", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "gmeb2", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "agtrap", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "samd11", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "trafd1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "smim12", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zfp90", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "cd79b", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "frem2", filterType: "BestCase", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "rufy4", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "il31", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "trappc11", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "atp6v0e2", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "sdhaf4", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "stat6", filterType: "BestCase", aaLeft: "758", aaRight: "782", ntLeft: "2272", ntRight: "2346" },
  { key: "elac1", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "per3", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "spdye1", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "ap5b1", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "zbtb22", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "itln2", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "mtmr12", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "mkrn2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "swi5", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "arnt2", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "kat2b", filterType: "BestCase", aaLeft: "609", aaRight: "633", ntLeft: "1825", ntRight: "1899" },
  { key: "or5m11", filterType: "FailedAllFilters", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "znf660", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "cd177", filterType: "FailedAllFilters", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "mcoln3", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "insm1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "gbp3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "amdhd2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ect2l", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "elavl4", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ifnl2", filterType: "FourthBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "calcb", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "macroh2a1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "ddx53", filterType: "FailedAllFilters", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "arhgap9", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "c10orf88", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "neu4", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "nmur1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "magea11", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "ank3", filterType: "BestCase", aaLeft: "2654", aaRight: "2678", ntLeft: "7960", ntRight: "8034" },
  { key: "slc25a28", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "atp6v1g1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "folr2", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "dkk3", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "kcnj4", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "cst9l", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "kiaa1586", filterType: "FailedAllFilters", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "mfsd6l", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "tmc4", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "bend4", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "trim65", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "tceal4", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "tcp11x2", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "ifitm1", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "tmem74", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "lrrc52", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "lancl2", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "cmtm3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "srpk1", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "pet117", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "c2orf78", filterType: "FailedAllFilters", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "enox2", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "vps36", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ccdc124", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "cirop", filterType: "BestCase", aaLeft: "695", aaRight: "719", ntLeft: "2083", ntRight: "2157" },
  { key: "ski", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "spart", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "evi5", filterType: "BestCase", aaLeft: "777", aaRight: "801", ntLeft: "2329", ntRight: "2403" },
  { key: "vps13c", filterType: "BestCase", aaLeft: "1776", aaRight: "1800", ntLeft: "5326", ntRight: "5400" },
  { key: "klhdc4", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "ccdc113", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "cibar1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "pald1", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "ctnnd2", filterType: "BestCase", aaLeft: "1089", aaRight: "1113", ntLeft: "3265", ntRight: "3339" },
  { key: "sohlh2", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "epas1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "ophn1", filterType: "BestCase", aaLeft: "748", aaRight: "772", ntLeft: "2242", ntRight: "2316" },
  { key: "or52i2", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "gprc5b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "col26a1", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "ppfibp1", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "htr1b", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "pitpnm3", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "phf2", filterType: "BestCase", aaLeft: "955", aaRight: "979", ntLeft: "2863", ntRight: "2937" },
  { key: "tmem43", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "slc30a3", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "plaat3", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "oxa1l", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "chst2", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "amhr2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "ptrh1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "or8k5", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "mcm10", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "prelid2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "ncr2", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "znf774", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "gpr173", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "nap1l4", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "prxl2c", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "kdm7a", filterType: "BestCase", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "krtap5-5", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "trim52", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "itpk1", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "mknk1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "c1orf52", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "phf10", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "mboat4", filterType: "FailedAllFilters", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "myd88", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "ngef", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "dvl3", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "gys2", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "rpl3", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "gckr", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "dsp", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ror2", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "katnal2", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "atg16l1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "agk", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "il36rn", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ccna2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "stx18", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "kif27", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "snx11", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "loc101059948", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "atg13", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "eml6", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "plrg1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "dtymk", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "mapk10", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nhlrc4", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "lbhd1", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "thbs1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cd19", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "sycp2l", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "tbk1", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "nsg1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "cfap73", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "lmo3", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "lysmd2", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "nfix", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "padi1", filterType: "BestCase", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "eif2a", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "rexo2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "itpripl2", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "gpatch2l", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "rab9a", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "znf157", filterType: "FailedAllFilters", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "gtf2a1l", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "snrnp200", filterType: "SecondBest", aaLeft: "1367", aaRight: "1391", ntLeft: "4099", ntRight: "4173" },
  { key: "pcsk5", filterType: "BestCase", aaLeft: "979", aaRight: "1003", ntLeft: "2935", ntRight: "3009" },
  { key: "prrc1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "cdipt", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "ap2b1", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "znf160", filterType: "FailedAllFilters", aaLeft: "594", aaRight: "618", ntLeft: "1780", ntRight: "1854" },
  { key: "lypd6", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "acadvl", filterType: "BestCase", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "akap3", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "aadat", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "pcgf3", filterType: "SecondBest", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "zp2", filterType: "FailedAllFilters", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "cfap44", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "cenpw", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "cdc42ep1", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "ddx31", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "tm9sf1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tppp3", filterType: "SecondBest", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "pcdha13", filterType: "FailedAllFilters", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "fabp2", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "zbtb40", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "cdk15", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "med31", filterType: "SecondBest", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "rfx4", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "vegfc", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "aff3", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "rgs19", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "tmprss9", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "rimbp3b", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "ctage6", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "ints14", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "rapgef2", filterType: "BestCase", aaLeft: "896", aaRight: "920", ntLeft: "2686", ntRight: "2760" },
  { key: "c16orf78", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "socs1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "klf3", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "ptp4a1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tcea1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "me1", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "dolpp1", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "notch2nlc", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "ccdc146", filterType: "BestCase", aaLeft: "871", aaRight: "895", ntLeft: "2611", ntRight: "2685" },
  { key: "ttll2", filterType: "FailedAllFilters", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "nucks1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "zdbf2", filterType: "FailedAllFilters", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "tshz1", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "ppp2r2b", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "pdia4", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "neurod6", filterType: "SecondBest", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "tnfaip3", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "or1g1", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "fhod1", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "klrg2", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "dcaf5", filterType: "BestCase", aaLeft: "729", aaRight: "753", ntLeft: "2185", ntRight: "2259" },
  { key: "fer1l5", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "lrrc30", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "stt3b", filterType: "SecondBest", aaLeft: "801", aaRight: "825", ntLeft: "2401", ntRight: "2475" },
  { key: "vps54", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "ndufb11", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "fam3a", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf721", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "naaladl1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "pxdn", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "gper1", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "entpd7", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "set", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "cklf", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cdh5", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "rtn4rl1", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "tnik", filterType: "BestCase", aaLeft: "945", aaRight: "969", ntLeft: "2833", ntRight: "2907" },
  { key: "rab35", filterType: "SecondBest", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "isl2", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "ghr", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "csnk2a1", filterType: "SecondBest", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "lilra1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "pdlim7", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "cep72", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "slc37a3", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "aqp7", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "potei", filterType: "FailedAllFilters", aaLeft: "715", aaRight: "739", ntLeft: "2143", ntRight: "2217" },
  { key: "copb1", filterType: "SecondBest", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "znf716", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "phgr1", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ubxn6", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "or4x2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "h2ac14", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cdk2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "tmf1", filterType: "BestCase", aaLeft: "622", aaRight: "646", ntLeft: "1864", ntRight: "1938" },
  { key: "syne3", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "col21a1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "orc3", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "spsb1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "mbd2", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "fam209b", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "sdsl", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "fbln7", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "inpp1", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "atl3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tuba1c", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "slamf9", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "cep170b", filterType: "BestCase", aaLeft: "1037", aaRight: "1061", ntLeft: "3109", ntRight: "3183" },
  { key: "nup214", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "adcy9", filterType: "BestCase", aaLeft: "802", aaRight: "826", ntLeft: "2404", ntRight: "2478" },
  { key: "spats2l", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "ccdc171", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "myef2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "c12orf29", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "etv6", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "spred3", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "neurog1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "rbm28", filterType: "BestCase", aaLeft: "626", aaRight: "650", ntLeft: "1876", ntRight: "1950" },
  { key: "cd200r1l", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "hsd17b4", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "repin1", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "cxorf49", filterType: "FailedAllFilters", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "krt27", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "nos1", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "utp23", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "tnrc6c", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "fkbp5", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "otud5", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "oaz3", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "spo11", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "ncf4", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "or8b8", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "asah2", filterType: "BestCase", aaLeft: "704", aaRight: "728", ntLeft: "2110", ntRight: "2184" },
  { key: "acsm3", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "rhog", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "evc", filterType: "BestCase", aaLeft: "788", aaRight: "812", ntLeft: "2362", ntRight: "2436" },
  { key: "khsrp", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "tpp2", filterType: "BestCase", aaLeft: "1000", aaRight: "1024", ntLeft: "2998", ntRight: "3072" },
  { key: "cldn10", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf594", filterType: "FailedAllFilters", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "lgals9c", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "sanbr", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "rnf11", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "bicra", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "ash1l", filterType: "BestCase", aaLeft: "2881", aaRight: "2905", ntLeft: "8641", ntRight: "8715" },
  { key: "taf1c", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "chchd6", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fam181b", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "gpr26", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "dnajb12", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "rnf26", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "cops2", filterType: "SecondBest", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "znf37a", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "plekhg1", filterType: "BestCase", aaLeft: "805", aaRight: "829", ntLeft: "2413", ntRight: "2487" },
  { key: "tbc1d10b", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "abca12", filterType: "BestCase", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "npbwr2", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "fbxo9", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "fyttd1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "arfgap1", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "garnl3", filterType: "BestCase", aaLeft: "984", aaRight: "1008", ntLeft: "2950", ntRight: "3024" },
  { key: "cand1", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "arntl", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "plxna1", filterType: "BestCase", aaLeft: "1528", aaRight: "1552", ntLeft: "4582", ntRight: "4656" },
  { key: "foxk2", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "crhbp", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "hk1", filterType: "BestCase", aaLeft: "693", aaRight: "717", ntLeft: "2077", ntRight: "2151" },
  { key: "xylt1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "krt10", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "slc6a9", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "s100a16", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "pigh", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "adgb", filterType: "BestCase", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "fmo4", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "wdsub1", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "apba1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "naa30", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ndc1", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "jpt2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "pax2", filterType: "SecondBest", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "lenep", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "magel2", filterType: "FailedAllFilters", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "fyb2", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "miga1", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "fam193a", filterType: "BestCase", aaLeft: "1401", aaRight: "1425", ntLeft: "4201", ntRight: "4275" },
  { key: "sh2d1b", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "znf480", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "slc22a31", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "fgfr4", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "shld1", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "pdpn", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "or5k2", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "shc2", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "atp5mk", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "hoxb7", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "ssbp1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "hbb", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "rph3a", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "cox6c", filterType: "FourthBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "arl4a", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "csmd2", filterType: "BestCase", aaLeft: "2563", aaRight: "2587", ntLeft: "7687", ntRight: "7761" },
  { key: "ndfip2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "exoc6b", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "c16orf87", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rad51ap1", filterType: "FourthBest", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "vwa7", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "syt15", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "nfkbid", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "s1pr4", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tmem207", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "ptgs2", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "smndc1", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "hoxc13", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "nxf2", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "or51e1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "b4gat1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "slc2a2", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "tmem230", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "akr1b10", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "h2bc11", filterType: "SecondBest", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "gmppb", filterType: "SecondBest", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "rnf168", filterType: "FailedAllFilters", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "emc9", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "slc10a5", filterType: "FailedAllFilters", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "gfer", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "prpsap1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "cyb5b", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "calcoco1", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "tmprss11d", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "tssk4", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "znf839", filterType: "FailedAllFilters", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "f8a3", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "exoc3l1", filterType: "BestCase", aaLeft: "690", aaRight: "714", ntLeft: "2068", ntRight: "2142" },
  { key: "idh2", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "ankrd40", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "sfrp5", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ushbp1", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "mesp1", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "cytl1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "rgs7bp", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "tp53inp1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "clec12b", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "dtd1", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "gde1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ruvbl2", filterType: "SecondBest", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "gzmk", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "ttc13", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tusc3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "col4a2", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "kctd9", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "thop1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "asb15", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "ntmt2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "tcl1b", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "wdr83", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "otos", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "nomo1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tspyl5", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "pskh1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "hcrt", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "rtf2", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "anxa6", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "sipa1", filterType: "BestCase", aaLeft: "792", aaRight: "816", ntLeft: "2374", ntRight: "2448" },
  { key: "fam227b", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "pvalb", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "kif21b", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "pi15", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "upp1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "page2", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "prr30", filterType: "FailedAllFilters", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "fbxw7", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "tagln", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "akap10", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "ipp", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "bmp4", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "rars2", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "adrb1", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "actn4", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "ccz1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "aplf", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rassf4", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "abl2", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "dusp28", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "glod4", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "czib", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "krt72", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "loc100421372", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "armc8", filterType: "SecondBest", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ube3c", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "reps1", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "opn4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "adamts7", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "il13ra2", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "grina", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "celsr3", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "luc7l2", filterType: "SecondBest", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "hmgn4", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "rnf44", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "coro6", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "rhoxf1", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "plekha3", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "gdf6", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "efemp2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ext2", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "nudcd1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "ttc24", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "mrgprx2", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "pah", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "cbx2", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "ccni", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "tmem265", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "zscan32", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "scfd2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "or2k2", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "bex1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "ulbp3", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "syt2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "armcx4", filterType: "FailedAllFilters", aaLeft: "811", aaRight: "835", ntLeft: "2431", ntRight: "2505" },
  { key: "cbs", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "mkrn2os", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "znhit1", filterType: "SecondBest", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "pdcd6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "drc7", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "acaa1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "oxld1", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ryr3", filterType: "BestCase", aaLeft: "4134", aaRight: "4158", ntLeft: "12400", ntRight: "12474" },
  { key: "mrps35", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "ctr9", filterType: "BestCase", aaLeft: "963", aaRight: "987", ntLeft: "2887", ntRight: "2961" },
  { key: "fezf2", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "or2t11", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "heatr5b", filterType: "BestCase", aaLeft: "1553", aaRight: "1577", ntLeft: "4657", ntRight: "4731" },
  { key: "foxd4l5", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "dhrs9", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "casp14", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "slitrk4", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "lonrf1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tekt1", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "nim1k", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "nt5c1a", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "sec13", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ralgapa1", filterType: "BestCase", aaLeft: "1023", aaRight: "1047", ntLeft: "3067", ntRight: "3141" },
  { key: "c1ql1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ch25h", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tor2a", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "hcn3", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "smg8", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "iars1", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "sec11c", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc17a5", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "rasgrp4", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ankrd22", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "pthlh", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "gcfc2", filterType: "BestCase", aaLeft: "579", aaRight: "603", ntLeft: "1735", ntRight: "1809" },
  { key: "il7", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ccdc102a", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "bin3", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "slc5a2", filterType: "BestCase", aaLeft: "573", aaRight: "597", ntLeft: "1717", ntRight: "1791" },
  { key: "stk31", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "cd84", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "heca", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "znf90", filterType: "FailedAllFilters", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "cntn1", filterType: "BestCase", aaLeft: "836", aaRight: "860", ntLeft: "2506", ntRight: "2580" },
  { key: "pnpla4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "hoxd13", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "mrpl38", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "rgpd6", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "cplane1", filterType: "BestCase", aaLeft: "3202", aaRight: "3226", ntLeft: "9604", ntRight: "9678" },
  { key: "lrrcc1", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "cat", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "rdh8", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "nkiras2", filterType: "SecondBest", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "septin1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "incenp", filterType: "BestCase", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "pcnx3", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "bhmt2", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "baiap2l2", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "wfs1", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "tmem252", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "atp1b1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ehd4", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "mmp19", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "s100a3", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "zmynd15", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "cacybp", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "notch1", filterType: "BestCase", aaLeft: "2279", aaRight: "2303", ntLeft: "6835", ntRight: "6909" },
  { key: "or6x1", filterType: "FailedAllFilters", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "skp1", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "zcchc12", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "slc22a3", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "maoa", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "aars1", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "spink9", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "cflar", filterType: "FailedAllFilters", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "smad5", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "c12orf57", filterType: "SecondBest", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "acer2", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "pmf1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "yap1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "lamp1", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "adad1", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "znf277", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "amigo2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "tcirg1", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "bod1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ssh2", filterType: "BestCase", aaLeft: "1137", aaRight: "1161", ntLeft: "3409", ntRight: "3483" },
  { key: "tfb1m", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "aknad1", filterType: "FailedAllFilters", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "tex51", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "il1rapl2", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "znf768", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "fam229a", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "dync2i1", filterType: "FailedAllFilters", aaLeft: "1024", aaRight: "1048", ntLeft: "3070", ntRight: "3144" },
  { key: "hla-dqa2", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "chrna6", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "map3k8", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "prss38", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "frmpd4", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "chd2", filterType: "BestCase", aaLeft: "1340", aaRight: "1364", ntLeft: "4018", ntRight: "4092" },
  { key: "acmsd", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "snrnp48", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "cysltr2", filterType: "FailedAllFilters", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "prkacb", filterType: "SecondBest", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "gpr158", filterType: "BestCase", aaLeft: "943", aaRight: "967", ntLeft: "2827", ntRight: "2901" },
  { key: "chrnb1", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "or10v1", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "ckap4", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "klhl31", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "gpx8", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "sowaha", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "npy5r", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "pimreg", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "slc25a34", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "ppp1r35", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "nhlh2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "exosc1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "clock", filterType: "BestCase", aaLeft: "592", aaRight: "616", ntLeft: "1774", ntRight: "1848" },
  { key: "cct2", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "ubl4a", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "slc18a1", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "actbl2", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ppa1", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "ccdc138", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "c12orf60", filterType: "FailedAllFilters", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "cage1", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "trub2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ccdc78", filterType: "FailedAllFilters", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "pigv", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "taf9b", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "coa7", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "cbwd1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "tmem68", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "ptgds", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "cep104", filterType: "BestCase", aaLeft: "808", aaRight: "832", ntLeft: "2422", ntRight: "2496" },
  { key: "gtse1", filterType: "FailedAllFilters", aaLeft: "714", aaRight: "738", ntLeft: "2140", ntRight: "2214" },
  { key: "nipal3", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "golga8n", filterType: "FailedAllFilters", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "etfbkmt", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "nfat5", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "msl2", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "bsx", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "fam110b", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cdk2ap1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "ct47a7", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "capn14", filterType: "FailedAllFilters", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "zdhhc22", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "c18orf54", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "herpud2", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "fam174c", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "atp4a", filterType: "BestCase", aaLeft: "816", aaRight: "840", ntLeft: "2446", ntRight: "2520" },
  { key: "meiosin", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "ugt2b11", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "gpbar1", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "psat1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "rps25", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or7e24", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "c20orf173", filterType: "ThirdBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "galk2", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "oosp1", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "dgkb", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "psma4", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "itga2", filterType: "BestCase", aaLeft: "907", aaRight: "931", ntLeft: "2719", ntRight: "2793" },
  { key: "deptor", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "rabl2b", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tet1", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "h2az2", filterType: "SecondBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "slc26a8", filterType: "BestCase", aaLeft: "785", aaRight: "809", ntLeft: "2353", ntRight: "2427" },
  { key: "usp11", filterType: "BestCase", aaLeft: "886", aaRight: "910", ntLeft: "2656", ntRight: "2730" },
  { key: "dctpp1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "tsc1", filterType: "BestCase", aaLeft: "1108", aaRight: "1132", ntLeft: "3322", ntRight: "3396" },
  { key: "map3k10", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "sh3rf1", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "fam102a", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "znf44", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "ghrhr", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "mid1ip1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "ecel1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "nfkbiz", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "ripor1", filterType: "BestCase", aaLeft: "863", aaRight: "887", ntLeft: "2587", ntRight: "2661" },
  { key: "ifna17", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "snrpb2", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "ube2g1", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cyrib", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "nolc1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "abhd15", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "nlrp9", filterType: "FailedAllFilters", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "znf436", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "afap1", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "c20orf144", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "tmprss13", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "psmb3", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "itgb5", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "tmem30a", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "or14k1", filterType: "FailedAllFilters", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "prlh", filterType: "FourthBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "chmp2a", filterType: "SecondBest", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "pld2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "cers6", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "ppp1r9a", filterType: "BestCase", aaLeft: "1108", aaRight: "1132", ntLeft: "3322", ntRight: "3396" },
  { key: "fbxo44", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "clca1", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "kmt2c", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "usp26", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "rpl28", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "bbs7", filterType: "BestCase", aaLeft: "624", aaRight: "648", ntLeft: "1870", ntRight: "1944" },
  { key: "nt5e", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "gucy1a2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "zkscan2", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "arpp19", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hectd3", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "gng12", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "xage2", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "kiaa1191", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "psg8", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rps12", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "btbd6", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "loc102724560", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "myom3", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "f7", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "camk2b", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "eid2b", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "farsb", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "sardh", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "nipsnap2", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "sall2", filterType: "BestCase", aaLeft: "841", aaRight: "865", ntLeft: "2521", ntRight: "2595" },
  { key: "acsbg1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "zdhhc15", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "aamdc", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "c18orf63", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "tdgf1p3", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "als2cl", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "tex33", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "aprt", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "sh3bp5", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "tmem52b", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "capns1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "rtn3", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "rfng", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "casp9", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "gjb3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "znf215", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "slc25a3", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "nexn", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "fkbp10", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "pla2g7", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "npas3", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "magea6", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "nckap1l", filterType: "BestCase", aaLeft: "679", aaRight: "703", ntLeft: "2035", ntRight: "2109" },
  { key: "nvl", filterType: "BestCase", aaLeft: "794", aaRight: "818", ntLeft: "2380", ntRight: "2454" },
  { key: "arpc5l", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "cse1l", filterType: "SecondBest", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "tmod4", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "slc35g2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "doc2a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "mylk", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tbc1d1", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "exoc3", filterType: "BestCase", aaLeft: "710", aaRight: "734", ntLeft: "2128", ntRight: "2202" },
  { key: "ptf1a", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "rbm34", filterType: "FailedAllFilters", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "sh3gl1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "znf577", filterType: "FailedAllFilters", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "tespa1", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "pmfbp1", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "tigit", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "degs1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "or4d5", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "fam90a19", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "znf540", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "itpr3", filterType: "BestCase", aaLeft: "1813", aaRight: "1837", ntLeft: "5437", ntRight: "5511" },
  { key: "wdr35", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "gaa", filterType: "BestCase", aaLeft: "783", aaRight: "807", ntLeft: "2347", ntRight: "2421" },
  { key: "defb130a", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "gpc1", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "foxe1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ucp3", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "nlrp2b", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "hspa12a", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "lyar", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "fat2", filterType: "BestCase", aaLeft: "2623", aaRight: "2647", ntLeft: "7867", ntRight: "7941" },
  { key: "cyp27b1", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "siglec5", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "fibcd1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "dbf4b", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "jak3", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "tincr", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "creg2", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "ccp110", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "cog5", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ninl", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "tapbpl", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "tspan10", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "rab29", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "gja4", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "hadhb", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "znf222", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "bicral", filterType: "BestCase", aaLeft: "849", aaRight: "873", ntLeft: "2545", ntRight: "2619" },
  { key: "mageb1", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "prps1l1", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "stk32b", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "ctsk", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lhpp", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "gpr37l1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "pomt1", filterType: "BestCase", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "tpst1", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cyp3a4", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ftcdnl1", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "dnm2", filterType: "BestCase", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "nmd3", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "oxsr1", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "znf26", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "sftpc", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "cfap58", filterType: "BestCase", aaLeft: "765", aaRight: "789", ntLeft: "2293", ntRight: "2367" },
  { key: "gstm5", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "spaca5", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ntng1", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "wdfy3", filterType: "BestCase", aaLeft: "3289", aaRight: "3313", ntLeft: "9865", ntRight: "9939" },
  { key: "brpf1", filterType: "BestCase", aaLeft: "754", aaRight: "778", ntLeft: "2260", ntRight: "2334" },
  { key: "pspc1", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "cfap100", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "fermt3", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "morn2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "rps3", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "ct47a12", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "pi4kb", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "fbxo11", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "itih3", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "or2c3", filterType: "ThirdBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ttc1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "neo1", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "rragc", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "fam172a", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "mgat4c", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "klf13", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "otx2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "glt8d1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "pramef11", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "tspoap1", filterType: "BestCase", aaLeft: "1584", aaRight: "1608", ntLeft: "4750", ntRight: "4824" },
  { key: "gh2", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "cd109", filterType: "BestCase", aaLeft: "1198", aaRight: "1222", ntLeft: "3592", ntRight: "3666" },
  { key: "ddo", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "islr", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "vezt", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "c2", filterType: "BestCase", aaLeft: "510", aaRight: "534", ntLeft: "1528", ntRight: "1602" },
  { key: "cks1b", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "naxe", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "gramd2b", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "pramef26", filterType: "FailedAllFilters", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "gk5", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "hacd4", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "znf629", filterType: "FailedAllFilters", aaLeft: "811", aaRight: "835", ntLeft: "2431", ntRight: "2505" },
  { key: "pkd2", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "haus4", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "nacc2", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "esrp1", filterType: "BestCase", aaLeft: "649", aaRight: "673", ntLeft: "1945", ntRight: "2019" },
  { key: "agap2", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "eef1g", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "mideas", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "b3galt5", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "alad", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "dhx30", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "psmd1", filterType: "SecondBest", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "magi1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "art5", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "usp44", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "chmp4c", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "tmem184a", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "dpp10", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "amotl1", filterType: "BestCase", aaLeft: "849", aaRight: "873", ntLeft: "2545", ntRight: "2619" },
  { key: "gnat1", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "fam90a8", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "ppial4h", filterType: "FourthBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "znf454", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "rarres2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "eif3m", filterType: "SecondBest", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "emp1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "or5t2", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "csf3r", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "mtr", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "mrpl46", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "abcd4", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "zc3h10", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "slc7a1", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "isg20l2", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "atxn2l", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "etfrf1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "arf1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cpeb1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "top2b", filterType: "BestCase", aaLeft: "1497", aaRight: "1521", ntLeft: "4489", ntRight: "4563" },
  { key: "klk7", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "maf", filterType: "FailedAllFilters", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "c9orf131", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "rhno1", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cln3", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "thap12", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "hmbox1", filterType: "SecondBest", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "rab7b", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "hspa1a", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "parp2", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "st7", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "arhgef10l", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "iah1", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "kif13b", filterType: "BestCase", aaLeft: "1782", aaRight: "1806", ntLeft: "5344", ntRight: "5418" },
  { key: "arid3b", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "tmbim1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "tprg1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "cptp", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "znf75a", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "myoz2", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "gbx2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "skor2", filterType: "FailedAllFilters", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "tmed3", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "dexi", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tomt", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "hmx2", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "slc38a7", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "vdac3", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "osbpl10", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "dcun1d2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "alpg", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "steep1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "bicd2", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "aplnr", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "adipor1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "gcsam", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "rsph10b", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "tcerg1l", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "pls3", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "tfap2c", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "sumo3", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "igsf5", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "acot7", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "ggtlc1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "vcpip1", filterType: "BestCase", aaLeft: "1065", aaRight: "1089", ntLeft: "3193", ntRight: "3267" },
  { key: "marco", filterType: "FailedAllFilters", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "sprr2g", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "plpp3", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "wnt10b", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "galnt12", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "znf354b", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "mb21d2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ypel4", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lce6a", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "dhodh", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "pcyt2", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "prex1", filterType: "BestCase", aaLeft: "1115", aaRight: "1139", ntLeft: "3343", ntRight: "3417" },
  { key: "pde4a", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "or52b4", filterType: "FailedAllFilters", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "tmem145", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sla2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "nabp2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "mamdc2", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "iffo1", filterType: "BestCase", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "c9orf24", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "meaf6", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "rpl26l1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "ndufb9", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "pdzd3", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "krt2", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "cracr2a", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "hook3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "itgal", filterType: "BestCase", aaLeft: "1014", aaRight: "1038", ntLeft: "3040", ntRight: "3114" },
  { key: "mrps6", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "pdx1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "ghitm", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pdzd11", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "magohb", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "bpifb4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "grk3", filterType: "BestCase", aaLeft: "660", aaRight: "684", ntLeft: "1978", ntRight: "2052" },
  { key: "scn8a", filterType: "BestCase", aaLeft: "1506", aaRight: "1530", ntLeft: "4516", ntRight: "4590" },
  { key: "ccl27", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "zmpste24", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "bpifa3", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "cdc73", filterType: "SecondBest", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "brinp3", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "amely", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "ipmk", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "kcna2", filterType: "SecondBest", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "acvr2a", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "serpinb11", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "lrrd1", filterType: "FailedAllFilters", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "fam98b", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "col6a1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "tbc1d3i", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "cinp", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "elovl3", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "riok3", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "galnt4", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tnr", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "osbpl6", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "cnot2", filterType: "SecondBest", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "tspan3", filterType: "SecondBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "alg8", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "napsa", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "ccdc192", filterType: "FourthBest", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "smtn", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "cgas", filterType: "FailedAllFilters", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "snx3", filterType: "SecondBest", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "pwp2", filterType: "BestCase", aaLeft: "767", aaRight: "791", ntLeft: "2299", ntRight: "2373" },
  { key: "asdurf", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "scp2d1", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "unc93a", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "adirf", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "gfpt1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "cntnap3", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "ifna6", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ttyh1", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "dpf1", filterType: "SecondBest", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "slc12a6", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "pkp4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "gpr89b", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "tex9", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "dock11", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "or2t2", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "lipf", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "hoxa10", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "gga3", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "mdh2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "wnt8b", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "c9orf153", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "myl5", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "atp5f1a", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "sema7a", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "prlhr", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hlcs", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "ppargc1b", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "masp1", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "mrpl24", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "myo1d", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "tsr2", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "polr2c", filterType: "SecondBest", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "cltrn", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "prkx", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "abcb6", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "znf683", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "dynlt1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "apol2", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "mrpl13", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ube2v2", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "rnase6", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "polr1d", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "rnf121", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "qpctl", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "abca1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "lhfpl4", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "tbc1d22b", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "exoc1l", filterType: "SecondBest", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ciita", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "hpx", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "cenpi", filterType: "BestCase", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "lhx1", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "edn1", filterType: "SecondBest", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "sema4f", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "rbfox1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "fgd5", filterType: "FailedAllFilters", aaLeft: "930", aaRight: "954", ntLeft: "2788", ntRight: "2862" },
  { key: "rcl1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "sult2b1", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "tent2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "cyp2w1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mcm7", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ifnb1", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "eif4a2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ltbr", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "ceacam8", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "gata5", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "mapk3", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "cfhr1", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "pgap6", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "ska2", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "ggcx", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mindy2", filterType: "SecondBest", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "grm1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "zswim2", filterType: "FailedAllFilters", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ube2o", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "mis12", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "lama2", filterType: "BestCase", aaLeft: "1206", aaRight: "1230", ntLeft: "3616", ntRight: "3690" },
  { key: "wfdc3", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "cdc26", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "trmt61a", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "cend1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "abcc11", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "znf324b", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "uqcrb", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "tmem127", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "trappc6a", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "rs1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "ccdc87", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "b3gnt8", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "dock3", filterType: "BestCase", aaLeft: "1696", aaRight: "1720", ntLeft: "5086", ntRight: "5160" },
  { key: "prr20d", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ttc38", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "mbtps1", filterType: "BestCase", aaLeft: "884", aaRight: "908", ntLeft: "2650", ntRight: "2724" },
  { key: "smpd4", filterType: "BestCase", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "axdnd1", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "dgat1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fam72d", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tnfaip8l1", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "actr3", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "ndufaf5", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "pomgnt1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tmem97", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "slco1b1", filterType: "FailedAllFilters", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "dennd3", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "atg5", filterType: "SecondBest", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "loc730098", filterType: "SecondBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "fpr1", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "timp1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "rabepk", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ramacl", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "clec3a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "atcay", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "or10g2", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "col1a1", filterType: "BestCase", aaLeft: "1319", aaRight: "1343", ntLeft: "3955", ntRight: "4029" },
  { key: "lce3d", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rbm5", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "tp53i11", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "prr23c", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "wnt11", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ppm1n", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "vstm1", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "alas1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pde11a", filterType: "SecondBest", aaLeft: "782", aaRight: "806", ntLeft: "2344", ntRight: "2418" },
  { key: "ca4", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "klk14", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "pon3", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "ampd2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "rbbp9", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "calml5", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "maml3", filterType: "BestCase", aaLeft: "1068", aaRight: "1092", ntLeft: "3202", ntRight: "3276" },
  { key: "ckmt2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "elf4", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "tinag", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "elmod1", filterType: "SecondBest", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "six3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "lmna", filterType: "BestCase", aaLeft: "638", aaRight: "662", ntLeft: "1912", ntRight: "1986" },
  { key: "kdelr1", filterType: "SecondBest", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "h2bc14", filterType: "SecondBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "osgepl1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "tsga13", filterType: "FailedAllFilters", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "gadd45gip1", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "col11a1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cox5a", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slc52a3", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "gramd4", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "tmem229b", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "dennd2b", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "htt", filterType: "BestCase", aaLeft: "1803", aaRight: "1827", ntLeft: "5407", ntRight: "5481" },
  { key: "trhde", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "pamr1", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "tmem235", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "akr1b15", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "slc2a7", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "pcdhgc4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pdgfrl", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "morc3", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "asic5", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "ankrd45", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "proca1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "stac2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "tmprss11a", filterType: "FailedAllFilters", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "aspscr1", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "prkaa2", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "slbp", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "rabgap1l", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "exoc3l4", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "camsap3", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "hoxa5", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "fgfr1", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "ankrd40cl", filterType: "FourthBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "znf485", filterType: "FailedAllFilters", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "mob2", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "erc1", filterType: "BestCase", aaLeft: "1055", aaRight: "1079", ntLeft: "3163", ntRight: "3237" },
  { key: "cgb1", filterType: "FourthBest", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "hoxb2", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "ccr10", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "nit2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "apc2", filterType: "FailedAllFilters", aaLeft: "1583", aaRight: "1607", ntLeft: "4747", ntRight: "4821" },
  { key: "ppp1r2b", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "dmrt1", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "ankh", filterType: "SecondBest", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "ffar2", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "abraxas1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "syde1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "inka1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "or5d18", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "vwa2", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "myh9", filterType: "BestCase", aaLeft: "1223", aaRight: "1247", ntLeft: "3667", ntRight: "3741" },
  { key: "c16orf82", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ust", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "syt10", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "mt1hl1", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "or2m5", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "nfkbia", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "tmem202", filterType: "FailedAllFilters", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "s1pr1", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "brcc3", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "bicdl2", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "ssbp4", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "gsk3a", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "bhlhe22", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "arl4d", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "ccbe1", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "cd300e", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "vav2", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "pcf11", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "endod1", filterType: "FailedAllFilters", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "tom1", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "tacc1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "utp14c", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "plxna4", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "dazl", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "cldn22", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "znf518b", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "tcp11l1", filterType: "FailedAllFilters", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "chchd3", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "kiss1r", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "or8b12", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "pmm2", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "arhgap19", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "plekhg4", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "gimd1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "memo1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "naa35", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "pax7", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "anapc11", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "dmrta2", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "bcas2", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "prss3", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "diras3", filterType: "FailedAllFilters", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "tab2", filterType: "FailedAllFilters", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "dsg3", filterType: "FailedAllFilters", aaLeft: "888", aaRight: "912", ntLeft: "2662", ntRight: "2736" },
  { key: "s100a13", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "trpv2", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "krt15", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "pigm", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "or12d3", filterType: "FailedAllFilters", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "utp11", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "fcrla", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "fam222b", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "stx8", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "fmo1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "dlgap3", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ppp1r15a", filterType: "FailedAllFilters", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "simc1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "sv2a", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "yy1", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "nsmce2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "c4orf45", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "dtwd1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "apbb3", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "cd2bp2", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "serpine1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "snd1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dcdc2c", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "smoc2", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "anapc5", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "sertm1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "abi2", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "hsd17b1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "mta3", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "odf2", filterType: "BestCase", aaLeft: "888", aaRight: "912", ntLeft: "2662", ntRight: "2736" },
  { key: "hla-doa", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "fut5", filterType: "FailedAllFilters", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "rnf14", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "xpo6", filterType: "BestCase", aaLeft: "764", aaRight: "788", ntLeft: "2290", ntRight: "2364" },
  { key: "mthfr", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "gipc1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "gyg1", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "acsm6", filterType: "FailedAllFilters", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "ncf1", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "aamp", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "rhob", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "plxnb3", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "dnase2b", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "mrpl3", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "cldn15", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "rai14", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "il2", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "st6galnac2", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "cd81", filterType: "SecondBest", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "mtres1", filterType: "FourthBest", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "slc5a7", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "hspb9", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "nfatc3", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "pnpla1", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "cntn4", filterType: "BestCase", aaLeft: "744", aaRight: "768", ntLeft: "2230", ntRight: "2304" },
  { key: "txndc11", filterType: "BestCase", aaLeft: "848", aaRight: "872", ntLeft: "2542", ntRight: "2616" },
  { key: "prpf4b", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "col16a1", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "alcam", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "c1ql4", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "rasal1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "gemin8", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "sh2d4b", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "rasgrp1", filterType: "BestCase", aaLeft: "595", aaRight: "619", ntLeft: "1783", ntRight: "1857" },
  { key: "ankrd27", filterType: "BestCase", aaLeft: "753", aaRight: "777", ntLeft: "2257", ntRight: "2331" },
  { key: "cstf2t", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "atp6v1h", filterType: "SecondBest", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "gmpr", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "saxo2", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "vipas39", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "adgre5", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "rai2", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "strn4", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "atp1b4", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ovca2", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "s100a6", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ehd1", filterType: "BestCase", aaLeft: "510", aaRight: "534", ntLeft: "1528", ntRight: "1602" },
  { key: "adam21", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "or13c5", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "igfals", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "rgpd3", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "sfi1", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "septin4", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "nr2f6", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "fam236c", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "cmip", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "syt7", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "vamp2", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "bex4", filterType: "ThirdBest", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "bhlhe40", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rflnb", filterType: "FourthBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "armcx1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "napa", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "dzank1", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "zfand2a", filterType: "FourthBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "syce1", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "rnf138", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "nr2e1", filterType: "SecondBest", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "harbi1", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "pam", filterType: "BestCase", aaLeft: "749", aaRight: "773", ntLeft: "2245", ntRight: "2319" },
  { key: "cbx7", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "igip", filterType: "SecondBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ubac2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "adgrf2", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "tmem260", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "atp1a3", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "mtpn", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ptpn22", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fam240a", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "prmt8", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "s100z", filterType: "FourthBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "ddt", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ankrd10", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "tk2", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "mrps30", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "marveld2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "adh6", filterType: "ThirdBest", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "hnrnpa1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "pygo2", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "pir", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "tekt4", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "slitrk1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "iqsec3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "snu13", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "krt77", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "pus3", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "hhla2", filterType: "FailedAllFilters", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "kit", filterType: "BestCase", aaLeft: "940", aaRight: "964", ntLeft: "2818", ntRight: "2892" },
  { key: "actn1", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "tex13b", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ctdnep1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "lgi3", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "c12orf4", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "or1s2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "spata9", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "rassf1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rnf41", filterType: "SecondBest", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "pitrm1", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "mpzl2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "grin2c", filterType: "BestCase", aaLeft: "785", aaRight: "809", ntLeft: "2353", ntRight: "2427" },
  { key: "nenf", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "esrra", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "tifa", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "gdf3", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "ervv-1", filterType: "FailedAllFilters", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "plekha6", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "hgsnat", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "chrnd", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "stc1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ss18l2", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "adamts2", filterType: "BestCase", aaLeft: "1125", aaRight: "1149", ntLeft: "3373", ntRight: "3447" },
  { key: "mov10l1", filterType: "BestCase", aaLeft: "1038", aaRight: "1062", ntLeft: "3112", ntRight: "3186" },
  { key: "hmgn1", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "shcbp1l", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "asb10", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "zer1", filterType: "BestCase", aaLeft: "724", aaRight: "748", ntLeft: "2170", ntRight: "2244" },
  { key: "or4l1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "wdr86", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "lemd2", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "crebzf", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "foxn2", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "plxnd1", filterType: "BestCase", aaLeft: "1020", aaRight: "1044", ntLeft: "3058", ntRight: "3132" },
  { key: "golm2", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "hsd17b10", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ptgir", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "rnd2", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "tmem109", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "fam184b", filterType: "BestCase", aaLeft: "746", aaRight: "770", ntLeft: "2236", ntRight: "2310" },
  { key: "supt5h", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "dnah1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "plekhb1", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "zfpl1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "pbld", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "ttc16", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "ift81", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "fbxw2", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "emilin3", filterType: "BestCase", aaLeft: "681", aaRight: "705", ntLeft: "2041", ntRight: "2115" },
  { key: "prr35", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "sult1e1", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "naa60", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "paqr7", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "map6d1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "bmp1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ttr", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "mtg1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dop1a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pycr2", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "apod", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "krtap20-3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sgk2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "anxa3", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "cd1b", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "bcr", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "krt40", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "thsd7a", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "gage12e", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "otub2", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "phf7", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "chst7", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "nyx", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "slc30a6", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "mthfd2", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "gem", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "aldh5a1", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "rftn1", filterType: "FailedAllFilters", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "ccdc116", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "slc27a5", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "or1b1", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "ric8a", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "kat5", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "creb3l2", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "mboat1", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "odr4", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "b4galt5", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "c11orf53", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "ogfod1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "med7", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "thegl", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "ctbs", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "c20orf203", filterType: "FailedAllFilters", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "nudt8", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "tex48", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "dscam", filterType: "BestCase", aaLeft: "1941", aaRight: "1965", ntLeft: "5821", ntRight: "5895" },
  { key: "gpr176", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "znf771", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "styxl2", filterType: "BestCase", aaLeft: "1042", aaRight: "1066", ntLeft: "3124", ntRight: "3198" },
  { key: "oprl1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "prss21", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "nap1l1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "fam135a", filterType: "BestCase", aaLeft: "1104", aaRight: "1128", ntLeft: "3310", ntRight: "3384" },
  { key: "trip10", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "thap8", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "huwe1", filterType: "BestCase", aaLeft: "2888", aaRight: "2912", ntLeft: "8662", ntRight: "8736" },
  { key: "trap1", filterType: "BestCase", aaLeft: "562", aaRight: "586", ntLeft: "1684", ntRight: "1758" },
  { key: "rcbtb2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "borcs7", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gpr141", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "znf746", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "kdm4c", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "prss16", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "scn3b", filterType: "SecondBest", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rimkla", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "tmc1", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "tax1bp1", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "klhl28", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "trim60", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "tceal1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "ahsp", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "catsperz", filterType: "FailedAllFilters", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "prpf19", filterType: "SecondBest", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "cry2", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "plaur", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "copg1", filterType: "BestCase", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "kcnj1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccdc121", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "kbtbd11", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "ak8", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "pinx1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ccdc61", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "klhdc1", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "p4hb", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "lum", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "tmem71", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "lrrc57", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cmtm6", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "gabrr2", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "amot", filterType: "BestCase", aaLeft: "1014", aaRight: "1038", ntLeft: "3040", ntRight: "3114" },
  { key: "tsc22d3", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "cyp1a2", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "dnajc5", filterType: "SecondBest", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "spdye4", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "map2k5", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ogdhl", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ccnd2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ranbp1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "sppl2a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ugt2a1", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "znf665", filterType: "BestCase", aaLeft: "507", aaRight: "531", ntLeft: "1519", ntRight: "1593" },
  { key: "chrd", filterType: "BestCase", aaLeft: "916", aaRight: "940", ntLeft: "2746", ntRight: "2820" },
  { key: "dcd", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "trappc9", filterType: "BestCase", aaLeft: "963", aaRight: "987", ntLeft: "2887", ntRight: "2961" },
  { key: "rccd1", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "il34", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "trappc14", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "loc728392", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ubash3b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fam81b", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "smim20", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "sdhaf1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "stat3", filterType: "SecondBest", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "atox1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "snai1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "cnksr1", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "tbc1d19", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "neu1", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "prkcq", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "znf107", filterType: "FailedAllFilters", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "trem1", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "lrrfip1", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "ppat", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "gbp6", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "vcx2", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ms4a1", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "smim9", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "elavl1", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "kif18a", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "znf418", filterType: "FailedAllFilters", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "ddx56", filterType: "BestCase", aaLeft: "523", aaRight: "547", ntLeft: "1567", ntRight: "1641" },
  { key: "ece2", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "dph7", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "tmie", filterType: "SecondBest", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ldhal6b", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "relb", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "sox17", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "tinf2", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "fsd2", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "dhfr2", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "kirrel3", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "rcor3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rpl31", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "senp3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "wwp2", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "mtmr7", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "st3gal6", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "fuca2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "samd14", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "smim17", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "klc3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rufy1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "lasp1", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "umod", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "rictor", filterType: "BestCase", aaLeft: "1170", aaRight: "1194", ntLeft: "3508", ntRight: "3582" },
  { key: "glb1l", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "jrk", filterType: "FailedAllFilters", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "zbtb10", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "znf652", filterType: "BestCase", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "thsd4", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "gtpbp3", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "eef1akmt4", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "spam1", filterType: "FailedAllFilters", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "setdb1", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "pgrmc1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "znf713", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "kiaa1958", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "tacr2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "cdk7", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "shfl", filterType: "SecondBest", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "c1orf35", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "trim35", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "idnk", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "h2ac11", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "or6b1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "orc6", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "nsmaf", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "cd163l1", filterType: "FailedAllFilters", aaLeft: "647", aaRight: "671", ntLeft: "1939", ntRight: "2013" },
  { key: "pcdhga11", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "atp6v1b1", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "krtap10-11", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "scand1", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "ambn", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "ssb", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "gnaz", filterType: "SecondBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "tyk2", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "pstk", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "aqp2", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "lilrb3", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "ssr2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "meak7", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "r3hcc1", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "fam86b1", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "etv3", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "ccdc174", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "ids", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "apmap", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "ccdc34", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "fbln2", filterType: "BestCase", aaLeft: "1108", aaRight: "1132", ntLeft: "3322", ntRight: "3396" },
  { key: "stk17a", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "spsb4", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "nrip3", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "phkg1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "sf3b2", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "upk3b", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "gtf2f1", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "znf559", filterType: "FailedAllFilters", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "phc2", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "c5ar1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "g6pd", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "dleu7", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "vps51", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "fam3d", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "micall1", filterType: "BestCase", aaLeft: "592", aaRight: "616", ntLeft: "1774", ntRight: "1848" },
  { key: "nr1i2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "faah2", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "capza2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "gal3st2", filterType: "FailedAllFilters", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "ttll7", filterType: "BestCase", aaLeft: "635", aaRight: "659", ntLeft: "1903", ntRight: "1977" },
  { key: "ctnna2", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "wwtr1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "ddtl", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ccn2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "ube2t", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "ssx2ip", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "arhgef33", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "srgn", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "mpig6b", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "tnfaip6", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "tspear", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "znf660-znf197", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "lynx1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "shisa9", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "uspl1", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "rab30", filterType: "SecondBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "actl7b", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "lin37", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "vangl1", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "gdpgp1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cma1", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "ro60", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "ltv1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "bcl10", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "znrd2", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "pdlim2", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "aif1l", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "lilra4", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "sugp1", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "lsm8", filterType: "SecondBest", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "znf724", filterType: "FailedAllFilters", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "mlh3", filterType: "FailedAllFilters", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "pydc1", filterType: "FourthBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "entpd2", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "il18bp", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "kdm2a", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "ascc2", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "pcgf6", filterType: "SecondBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "ct45a1", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "tecpr2", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "akap6", filterType: "BestCase", aaLeft: "1245", aaRight: "1269", ntLeft: "3733", ntRight: "3807" },
  { key: "mpc1l", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "nsd3", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "dpm2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cdc42ep4", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "tm9sf4", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "lrtm1", filterType: "FailedAllFilters", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "fabp7", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "slurp2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "inhbc", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "pex12", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "cfap119", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "mtdh", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "znf165", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "fgl1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "xpnpep3", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "lypd3", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "ten1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "zbtb9", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pias3", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "socs4", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "znf318", filterType: "BestCase", aaLeft: "1033", aaRight: "1057", ntLeft: "3097", ntRight: "3171" },
  { key: "rnaseh2a", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "wnt3a", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "ints11", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "klf6", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "kif15", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "rgr", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "zbtb45", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "srbd1", filterType: "BestCase", aaLeft: "794", aaRight: "818", ntLeft: "2380", ntRight: "2454" },
  { key: "ubn2", filterType: "BestCase", aaLeft: "718", aaRight: "742", ntLeft: "2152", ntRight: "2226" },
  { key: "cdk10", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "znf607", filterType: "FailedAllFilters", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "rfx1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "dpp9", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "impa2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kif22", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "snx14", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "eml3", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "ddhd2", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "defb107a", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "clul1", filterType: "FailedAllFilters", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "cldnd1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "mapk15", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "znf630", filterType: "FailedAllFilters", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "nanos3", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "nhlrc1", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "pgm5", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "rpl6", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "mertk", filterType: "BestCase", aaLeft: "873", aaRight: "897", ntLeft: "2617", ntRight: "2691" },
  { key: "trrap", filterType: "BestCase", aaLeft: "1745", aaRight: "1769", ntLeft: "5233", ntRight: "5307" },
  { key: "rev3l", filterType: "BestCase", aaLeft: "862", aaRight: "886", ntLeft: "2584", ntRight: "2658" },
  { key: "sh3yl1", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "neurl1b", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "srgap1", filterType: "BestCase", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "hhipl1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "dhx29", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "nkain3", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "bnip3", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "sptlc2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "rabgef1", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "ints8", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "peds1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "thbs4", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "sash3", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "rnls", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "padi4", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "eif2d", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "kcna7", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "magoh", filterType: "SecondBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "tbc1d3l", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "olfml1", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "fhl5", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "adap2", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "arpc3", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "paip1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "shoc1", filterType: "BestCase", aaLeft: "712", aaRight: "736", ntLeft: "2134", ntRight: "2208" },
  { key: "ccl22", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "c19orf12", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "kif5b", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "or10a5", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "gml", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "chp1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "elovl6", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "il1a", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "tmem177", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "galnt1", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "adam9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccdc197", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "osbpl3", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "h4c5", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf8", filterType: "FailedAllFilters", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "cnot7", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "tspan6", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "tshr", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "arhgap32", filterType: "BestCase", aaLeft: "1420", aaRight: "1444", ntLeft: "4258", ntRight: "4332" },
  { key: "snx6", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "pde4d", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tmem140", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "arhgap11b", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tsku", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "galnt17", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "igf2r", filterType: "BestCase", aaLeft: "1146", aaRight: "1170", ntLeft: "3436", ntRight: "3510" },
  { key: "catsperd", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "nol4", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "prkar1a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c19orf25", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "nudt19", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "ypel1", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "tedc1", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "hes5", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "clec6a", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "gtf2h2", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "prim1", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "wdr83os", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "msx1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "eny2", filterType: "SecondBest", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "bpifb1", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "grk6", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "krt7", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "tnfsf18", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "ly6g5b", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "acvr1c", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "cd4", filterType: "FailedAllFilters", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "atad2b", filterType: "BestCase", aaLeft: "1314", aaRight: "1338", ntLeft: "3940", ntRight: "4014" },
  { key: "ran", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "wdhd1", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "tigd5", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "slc38a2", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "dmac2", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "crabp2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "gmip", filterType: "BestCase", aaLeft: "656", aaRight: "680", ntLeft: "1966", ntRight: "2040" },
  { key: "dnajc28", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "sorcs3", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "smc4", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "erg28", filterType: "SecondBest", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "napepld", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "znf75d", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ctu2", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "pold3", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "inpp4a", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "syn2", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "tmed6", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "acot2", filterType: "FailedAllFilters", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "ptbp3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "nup58", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "hck", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "ivns1abp", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "rnf146", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "fank1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "plpp6", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "sprr2b", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mdm2", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "thg1l", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "sav1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "vwa3b", filterType: "BestCase", aaLeft: "1103", aaRight: "1127", ntLeft: "3307", ntRight: "3381" },
  { key: "rpa2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cpeb4", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "klk2", filterType: "ThirdBest", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "zfp14", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "kiaa1109", filterType: "BestCase", aaLeft: "1686", aaRight: "1710", ntLeft: "5056", ntRight: "5130" },
  { key: "arf4", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "plp1", filterType: "SecondBest", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "slc15a3", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "tecrl", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "cln6", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "csk", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "tnp2", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "mrpl43", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "lcn6", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "c3orf22", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "zc3h15", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "zfp1", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "abcd1", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "slc7a4", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "pth2", filterType: "SecondBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "apaf1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tmbim4", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "gatad2b", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "bmp8b", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "cyp2r1", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "or9a2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "myl12b", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "cfap95", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "golt1b", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "rell2", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "pnldc1", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "rab39b", filterType: "SecondBest", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "tfrc", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "phyhipl", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "cdr2l", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "aloxe3", filterType: "BestCase", aaLeft: "670", aaRight: "694", ntLeft: "2008", ntRight: "2082" },
  { key: "sel1l", filterType: "BestCase", aaLeft: "768", aaRight: "792", ntLeft: "2302", ntRight: "2376" },
  { key: "tm7sf3", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "tmem115", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "flywch1", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "pde1a", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "mon1a", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "vstm4", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "ppm1k", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "adh1b", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "timp4", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "fgf18", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "poglut1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ambp", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "tc2n", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tnni3k", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "fabp12", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "vcan", filterType: "FailedAllFilters", aaLeft: "1207", aaRight: "1231", ntLeft: "3619", ntRight: "3693" },
  { key: "lce3a", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "epsti1", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "or10g7", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "cfap97d2", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "cdc6", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "elf1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "six6", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "r3hdm2", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "auts2", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "znf792", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "ush1g", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "trmt2b", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "mroh7", filterType: "BestCase", aaLeft: "1277", aaRight: "1301", ntLeft: "3829", ntRight: "3903" },
  { key: "ago3", filterType: "SecondBest", aaLeft: "554", aaRight: "578", ntLeft: "1660", ntRight: "1734" },
  { key: "sbk1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "prr29", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "gcnt2", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "rab11fip5", filterType: "BestCase", aaLeft: "962", aaRight: "986", ntLeft: "2884", ntRight: "2958" },
  { key: "artn", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "klk11", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cop1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "uhrf2", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "ca1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "dtx3", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "asb5", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "kncn", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "kcnd2", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "mmrn1", filterType: "FailedAllFilters", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "cdc23", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "srrd", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "c11orf87", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "tnnc2", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "topors", filterType: "FailedAllFilters", aaLeft: "983", aaRight: "1007", ntLeft: "2947", ntRight: "3021" },
  { key: "chadl", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "mapk6", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "pgap3", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "dppa4", filterType: "FailedAllFilters", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "tprkb", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "tcp10l", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "grm4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "zswim7", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "nfilz", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rhobtb2", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "or4s1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "c19orf47", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "nrn1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "chsy3", filterType: "BestCase", aaLeft: "753", aaRight: "777", ntLeft: "2257", ntRight: "2331" },
  { key: "tmem92", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "p2ry12", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "grap2", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "mtx1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "tatdn3", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "ccdc82", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "slc16a8", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "sytl5", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "dnm1l", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "dock6", filterType: "BestCase", aaLeft: "1185", aaRight: "1209", ntLeft: "3553", ntRight: "3627" },
  { key: "prr20a", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "c1orf141", filterType: "FailedAllFilters", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "fam72a", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "actr6", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "c9orf43", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "smpd1", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "slc28a1", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "cenpl", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "zfp41", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "clk3", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "lhx4", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "irf5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "sema4c", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "dynlt4", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "mrpl16", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "rnase3", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "arrdc2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "dapk3", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "polr1a", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "lhfpl1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "epc2", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "abca4", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "fam210b", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "znf875", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "mcm2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "kif16b", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "zmym5", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "gnrh1", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "angpt2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "esyt2", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "rab2b", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "tbx18", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "agps", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "man2b1", filterType: "BestCase", aaLeft: "871", aaRight: "895", ntLeft: "2611", ntRight: "2685" },
  { key: "mgat1", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "phlda2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "il17re", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "ppcdc", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "evpl", filterType: "BestCase", aaLeft: "1031", aaRight: "1055", ntLeft: "3091", ntRight: "3165" },
  { key: "lipc", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "lexm", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "zar1l", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "nopchap1", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "pidd1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "abhd16b", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "elob", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "pkp1", filterType: "BestCase", aaLeft: "653", aaRight: "677", ntLeft: "1957", ntRight: "2031" },
  { key: "hibch", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "slc12a3", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "lepr", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "grem2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "mrpl21", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "pvr", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "trarg1", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "myo1a", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "csrnp3", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "polr2f", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gstz1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "cdkn2aipnl", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "enho", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "egln3", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "cnr2", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "endog", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "mga", filterType: "BestCase", aaLeft: "2859", aaRight: "2883", ntLeft: "8575", ntRight: "8649" },
  { key: "map3k7cl", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mc5r", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "zcchc3", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "atp5f1d", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nfic", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "tgm5", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "ptprr", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "ip6k2", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "c20orf141", filterType: "FailedAllFilters", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "surf6", filterType: "FourthBest", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "srebf1", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "psmb6", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fbxo41", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "cers3", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "tbc1d32", filterType: "BestCase", aaLeft: "1232", aaRight: "1256", ntLeft: "3694", ntRight: "3768" },
  { key: "haghl", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "bbs2", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "clca4", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "lzic", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "hars1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "znf76", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "gp9", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "rfpl1", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "cpne5", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "znf433", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "tcaf1", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "pkd1l1", filterType: "FailedAllFilters", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "ces1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "abhd10", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "tsga10ip", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "mrm3", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "usf2", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "btbd3", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "prdm6", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "camk2g", filterType: "SecondBest", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "tymp", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "f2", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "pierce2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "dao", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "gng8", filterType: "SecondBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ppfia3", filterType: "BestCase", aaLeft: "726", aaRight: "750", ntLeft: "2176", ntRight: "2250" },
  { key: "trim49d1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ttf1", filterType: "FailedAllFilters", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "mcur1", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "epcam", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "zkscan7", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "rag1", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "dhx57", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "nedd9", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "nova1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c6orf120", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "rps17", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "spp2", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "tia1", filterType: "SecondBest", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "irak3", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "pom121c", filterType: "BestCase", aaLeft: "310", aaRight: "334", ntLeft: "928", ntRight: "1002" },
  { key: "tfeb", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "was", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "ncbp1", filterType: "SecondBest", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "arfgef2", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "psmd13", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "znf366", filterType: "BestCase", aaLeft: "693", aaRight: "717", ntLeft: "2077", ntRight: "2151" },
  { key: "tnfrsf11b", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "kctd19", filterType: "BestCase", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "rps20", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "prelid3b", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "tomm40", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "krtap4-8", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "fxn", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "ct47a2", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "tmem45a", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "clu", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "rmnd1", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "capn11", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "dbh", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "znf679", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "map3k15", filterType: "BestCase", aaLeft: "979", aaRight: "1003", ntLeft: "2935", ntRight: "3009" },
  { key: "faap20", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "ube2d3", filterType: "SecondBest", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "znf41", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "rnf144b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cd55", filterType: "FailedAllFilters", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "dclk3", filterType: "FailedAllFilters", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "camta2", filterType: "BestCase", aaLeft: "897", aaRight: "921", ntLeft: "2689", ntRight: "2763" },
  { key: "znf404", filterType: "FailedAllFilters", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "etfb", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "dgkg", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "psma1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "dnase2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "itga7", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "eppin", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "crisp2", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "usp14", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "slc25a31", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "cct7", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "exosc4", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "angptl5", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "vcp", filterType: "SecondBest", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "cndp1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "prkacg", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "armc12", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "srrm5", filterType: "FailedAllFilters", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "chrnb4", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "klhl34", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "sowahd", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "sars2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "pou5f2", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "scaf11", filterType: "FailedAllFilters", aaLeft: "761", aaRight: "785", ntLeft: "2281", ntRight: "2355" },
  { key: "golga8k", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "gmpr2", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "znf527", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "cdkl5", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "or4a5", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "dnajb9", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "atxn10", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "aqp12a", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "slc35b2", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "pigs", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "ctnnbl1", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "tulp3", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "lta", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "hba1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tmco5a", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "mzf1", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "xiap", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "slc35a5", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "kcnu1", filterType: "FailedAllFilters", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "efhd1", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "sptan1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "otud6b", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "mypop", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "defb129", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "susd5", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "fktn", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "notch4", filterType: "BestCase", aaLeft: "1834", aaRight: "1858", ntLeft: "5500", ntRight: "5574" },
  { key: "arms2", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "necab1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "zmynd10", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "tm2d1", filterType: "SecondBest", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "taar1", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "slc22a6", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "lrba", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "zcchc17", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "gda", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "cd207", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "znf510", filterType: "FailedAllFilters", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "fgfbp2", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "mcub", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "vps18", filterType: "BestCase", aaLeft: "902", aaRight: "926", ntLeft: "2704", ntRight: "2778" },
  { key: "lbr", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "amy1a", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "smarcd3", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "mfsd4b", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "garin5b", filterType: "FailedAllFilters", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "ptpn5", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "cox7a2", filterType: "FourthBest", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "chrna3", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "chd7", filterType: "BestCase", aaLeft: "2185", aaRight: "2209", ntLeft: "6553", ntRight: "6627" },
  { key: "frmpd1", filterType: "BestCase", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "wee2", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "gjd4", filterType: "FailedAllFilters", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "mctp2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "loc102724159", filterType: "BestCase", aaLeft: "767", aaRight: "791", ntLeft: "2299", ntRight: "2373" },
  { key: "haus1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "sesn1", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "card10", filterType: "BestCase", aaLeft: "810", aaRight: "834", ntLeft: "2428", ntRight: "2502" },
  { key: "znf333", filterType: "BestCase", aaLeft: "600", aaRight: "624", ntLeft: "1798", ntRight: "1872" },
  { key: "brat1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "nup153", filterType: "BestCase", aaLeft: "1017", aaRight: "1041", ntLeft: "3049", ntRight: "3123" },
  { key: "dhx35", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "ace2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "nans", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "hoatz", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "phactr2", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "c6orf52", filterType: "FourthBest", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "nat14", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "elmo3", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "obsl1", filterType: "BestCase", aaLeft: "1576", aaRight: "1600", ntLeft: "4726", ntRight: "4800" },
  { key: "hacd1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "msantd3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "nudt4b", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "slc9a8", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "fignl1", filterType: "FailedAllFilters", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "znf14", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "fam107a", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "ctxn2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf451", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "r3hdml", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "id3", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "eif3h", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ankhd1", filterType: "BestCase", aaLeft: "1991", aaRight: "2015", ntLeft: "5971", ntRight: "6045" },
  { key: "mpv17l2", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "snca", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "psmd4", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "pacs1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "tmem35a", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "txnip", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "psmg3", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "hspb11", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cdca5", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "rps6", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "trib1", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "met", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cd37", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "znf23", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "pura", filterType: "SecondBest", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ddx28", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "unc45a", filterType: "BestCase", aaLeft: "905", aaRight: "929", ntLeft: "2713", ntRight: "2787" },
  { key: "ubr5", filterType: "BestCase", aaLeft: "2108", aaRight: "2132", ntLeft: "6322", ntRight: "6396" },
  { key: "cdhr2", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "vax1", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "c21orf62", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "pramef14", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "pdcl2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "trpc5os", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "tomm22", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "dact1", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "med28", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rab40al", filterType: "FailedAllFilters", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "c7", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "bnc2", filterType: "BestCase", aaLeft: "1008", aaRight: "1032", ntLeft: "3022", ntRight: "3096" },
  { key: "grapl", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "itih6", filterType: "FailedAllFilters", aaLeft: "690", aaRight: "714", ntLeft: "2068", ntRight: "2142" },
  { key: "iqce", filterType: "FourthBest", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "pwwp3b", filterType: "FailedAllFilters", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "pcdhb9", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "ttc4", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "znf304", filterType: "FailedAllFilters", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "klf16", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "nbpf11", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tarbp1", filterType: "FailedAllFilters", aaLeft: "837", aaRight: "861", ntLeft: "2509", ntRight: "2583" },
  { key: "ncoa5", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "pou2f2", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "tspan15", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "lipt2", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "clec19a", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "gpc4", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "fcn1", filterType: "FourthBest", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "cdip1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "dbx2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "syngr2", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "akr1c2", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "cox16", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "cxcr2", filterType: "FailedAllFilters", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "znf738", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "tmigd1", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "map1lc3c", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "rap1gap", filterType: "BestCase", aaLeft: "584", aaRight: "608", ntLeft: "1750", ntRight: "1824" },
  { key: "cntnap3b", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "tal1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "syndig1l", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "ccr5", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "gja1", filterType: "FailedAllFilters", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "znf227", filterType: "FailedAllFilters", aaLeft: "597", aaRight: "621", ntLeft: "1789", ntRight: "1863" },
  { key: "npap1", filterType: "FailedAllFilters", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "mageb4", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "slc25a53", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "oser1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ncam1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "minar2", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "gjb6", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "magea3", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "fkbp15", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "spink14", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "brf1", filterType: "SecondBest", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "tmod1", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "blnk", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "smarcb1", filterType: "SecondBest", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "gpr108", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "tex36", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "trim29", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "tbck", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "or10s1", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "th", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "msi2", filterType: "SecondBest", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "hivep3", filterType: "BestCase", aaLeft: "1934", aaRight: "1958", ntLeft: "5800", ntRight: "5874" },
  { key: "znf572", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "marf1", filterType: "BestCase", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "ttl", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "xrcc5", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "trim64b", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "aacs", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "drosha", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "acaca", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "pnisr", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "tbc1d4", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "iqcf1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "kcns3", filterType: "FailedAllFilters", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "nrsn1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "exoc6", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "tyw5", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "arhgef18", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "fsbp", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "lims2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "atp5if1", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "tnnt3", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "gpr119", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "aldh16a1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "cd8b", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "trim38", filterType: "FailedAllFilters", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "epha1", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "med13l", filterType: "BestCase", aaLeft: "1199", aaRight: "1223", ntLeft: "3595", ntRight: "3669" },
  { key: "lgals2", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "ly86", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "fbrs", filterType: "BestCase", aaLeft: "863", aaRight: "887", ntLeft: "2587", ntRight: "2661" },
  { key: "cnpy3", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "cdc25b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "lcat", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "fam162a", filterType: "FourthBest", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "potea", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "slc35f6", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "adcy1", filterType: "BestCase", aaLeft: "1094", aaRight: "1118", ntLeft: "3280", ntRight: "3354" },
  { key: "gars1", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "ccdc179", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "spata20", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "tmem199", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "thtpa", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "tsnax", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "ccdc39", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "rbm20", filterType: "BestCase", aaLeft: "744", aaRight: "768", ntLeft: "2230", ntRight: "2304" },
  { key: "tspan33", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "efhc2", filterType: "FailedAllFilters", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "cd274", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "mtrf1l", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "znf563", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "msh3", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "ugt1a7", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "armt1", filterType: "FailedAllFilters", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "sim1", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "slamf1", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "clptm1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "fnip1", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "hnrnpul2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "slc16a10", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "or4e1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "khk", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "znf554", filterType: "FailedAllFilters", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "elp1", filterType: "BestCase", aaLeft: "1136", aaRight: "1160", ntLeft: "3406", ntRight: "3480" },
  { key: "tspo", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "lrrc38", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "gna12", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "pdk1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "olig3", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "rhcg", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "prkd2", filterType: "BestCase", aaLeft: "837", aaRight: "861", ntLeft: "2509", ntRight: "2583" },
  { key: "scrib", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "or4f6", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "kcnq5", filterType: "BestCase", aaLeft: "714", aaRight: "738", ntLeft: "2140", ntRight: "2214" },
  { key: "slc35e1", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "spata17", filterType: "FourthBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "stxbp5", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "cyp4f2", filterType: "BestCase", aaLeft: "397", aaRight: "421", ntLeft: "1189", ntRight: "1263" },
  { key: "c17orf99", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "shisa4", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "rbm17", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "cx3cr1", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "znf236", filterType: "BestCase", aaLeft: "1413", aaRight: "1437", ntLeft: "4237", ntRight: "4311" },
  { key: "snrpb", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "slc25a42", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "brd7", filterType: "BestCase", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "epb42", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "mx2", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "fcgr2a", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "actg1", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "lsm5", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tex10", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "zim3", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "hopx", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tmem50a", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "hnrnpab", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "sart1", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "gas7", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ephb6", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "nme7", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "znf32", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "hnrnph1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "stub1", filterType: "SecondBest", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "hpn", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "ticam1", filterType: "FailedAllFilters", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "hus1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "nsun7", filterType: "FailedAllFilters", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "mtnr1a", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "slc6a16", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "bcam", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "pcp4l1", filterType: "SecondBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "or2ap1", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "irs2", filterType: "FailedAllFilters", aaLeft: "1190", aaRight: "1214", ntLeft: "3568", ntRight: "3642" },
  { key: "macir", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "aph1a", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "poln", filterType: "FailedAllFilters", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "dusp2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "syngap1", filterType: "SecondBest", aaLeft: "1037", aaRight: "1061", ntLeft: "3109", ntRight: "3183" },
  { key: "zbtb4", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "mchr2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "or51i1", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "slc24a3", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ibtk", filterType: "BestCase", aaLeft: "950", aaRight: "974", ntLeft: "2848", ntRight: "2922" },
  { key: "ankrd2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "atp8a2", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "zbtb48", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "ywhaz", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "efcab7", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "tnfrsf21", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "chpt1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "dek", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "chmp6", filterType: "SecondBest", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "junb", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "rgs11", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "dpp4", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "stx10", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "sell", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "pgpep1l", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "snx19", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "rdx", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "arl8a", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nectin3", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "slx1a", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ovch2", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "dkkl1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "pkdrej", filterType: "FailedAllFilters", aaLeft: "919", aaRight: "943", ntLeft: "2755", ntRight: "2829" },
  { key: "h2ac8", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "fam170b", filterType: "FailedAllFilters", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "znf322", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "hdac2", filterType: "SecondBest", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "prdm15", filterType: "BestCase", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "rab5if", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ggt1", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "tarp", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "abhd3", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "cmpk2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "usp50", filterType: "FourthBest", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "ints5", filterType: "BestCase", aaLeft: "773", aaRight: "797", ntLeft: "2317", ntRight: "2391" },
  { key: "hs6st2", filterType: "BestCase", aaLeft: "594", aaRight: "618", ntLeft: "1780", ntRight: "1854" },
  { key: "prkn", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "fbxo32", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "bloc1s3", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "rbmxl2", filterType: "FailedAllFilters", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "cyp26b1", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "dab1", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "dbnl", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "znf440", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "cpsf1", filterType: "BestCase", aaLeft: "900", aaRight: "924", ntLeft: "2698", ntRight: "2772" },
  { key: "hmgb4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "kir2dl4", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "znf501", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "wdr74", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "eno2", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "cdh16", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "or4c3", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "mical1", filterType: "BestCase", aaLeft: "785", aaRight: "809", ntLeft: "2353", ntRight: "2427" },
  { key: "cdkn3", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "sik3", filterType: "BestCase", aaLeft: "1235", aaRight: "1259", ntLeft: "3703", ntRight: "3777" },
  { key: "glul", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c12orf43", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "c1orf198", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "gpt", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "shf", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "plekhm3", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "gpr84", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "dio1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "kat8", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "rbm42", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "fem1b", filterType: "FailedAllFilters", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "terb1", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "slc25a17", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "znf263", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fnbp1l", filterType: "SecondBest", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "nudt5", filterType: "SecondBest", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "vps37a", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "arl6ip5", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "tex45", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "pcdhb10", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "or10t2", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "klhl12", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "thap5", filterType: "FailedAllFilters", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "dyrk2", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "zik1", filterType: "FailedAllFilters", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "or8u1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "spint1", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "wnt3", filterType: "SecondBest", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "krt85", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "klhl25", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "neil2", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "ppp1r21", filterType: "BestCase", aaLeft: "595", aaRight: "619", ntLeft: "1783", ntRight: "1857" },
  { key: "antxr2", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "pck1", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "slc25a20", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "znf254", filterType: "FailedAllFilters", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "gngt1", filterType: "SecondBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "il12b", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "nrm", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ache", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ftsj3", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "gdi1", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "katnbl1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ak5", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "gss", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "clba1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "rpain", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "zc3h11a", filterType: "BestCase", aaLeft: "562", aaRight: "586", ntLeft: "1684", ntRight: "1758" },
  { key: "h2bc3", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "wbp2nl", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "wdr43", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "znf536", filterType: "BestCase", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "adsl", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ssc5d", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "opa3", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "akr1d1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "dnajc8", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ctif", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "smarcal1", filterType: "BestCase", aaLeft: "918", aaRight: "942", ntLeft: "2752", ntRight: "2826" },
  { key: "spdye9", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "becn1", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "loc101059915", filterType: "FailedAllFilters", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "trappc4", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "znf668", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "lzts1", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "opn1mw3", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "gprasp1", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "pik3c3", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "taf15", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "ppif", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "ercc3", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "sectm1", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "opn1mw", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "glipr1l2", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "mier2", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "bscl2", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "psd", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "rrn3", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "marchf7", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tbc1d14", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "nfya", filterType: "SecondBest", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "gtsf1l", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "nras", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "fxyd6", filterType: "SecondBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "them5", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "lactb", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "dcaf17", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "urod", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "ube2e2", filterType: "SecondBest", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "tas2r10", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "cd44", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rfc5", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "rtca", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "chac1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cdkn1b", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "ppil6", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "smim4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fcrl2", filterType: "FailedAllFilters", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "slc23a3", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "arhgap1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "znf415", filterType: "FailedAllFilters", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "cd302", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "cenpvl3", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "h1-6", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "ubqln1", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "rida", filterType: "FourthBest", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tmtc4", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "znf385a", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "add3", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "plppr5", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "astn2", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "cfap141", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "birc5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tnpo2", filterType: "SecondBest", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "tbc1d23", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "rbms2", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "actr3c", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "api5", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "alkbh4", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "usp32", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "rabl3", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "or2g2", filterType: "ThirdBest", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "slc19a1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "stra6", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "naip", filterType: "FailedAllFilters", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "reep4", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "timm10", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "tstd3", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "efna1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "ndufa12", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "golga2", filterType: "FailedAllFilters", aaLeft: "519", aaRight: "543", ntLeft: "1555", ntRight: "1629" },
  { key: "ttll10", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "nmnat3", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "grpel1", filterType: "SecondBest", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "tradd", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "csrp1", filterType: "SecondBest", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "grik2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bet1", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "sbds", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "znf853", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "mgmt", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "mau2", filterType: "BestCase", aaLeft: "589", aaRight: "613", ntLeft: "1765", ntRight: "1839" },
  { key: "abhd17c", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "stk39", filterType: "SecondBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "lpcat1", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "znf98", filterType: "FailedAllFilters", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "perm1", filterType: "FailedAllFilters", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "zhx1", filterType: "FailedAllFilters", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "lgalsl", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "bhmt", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "cic", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "fgf6", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "serf1a", filterType: "FourthBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "gdf15", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "gemin5", filterType: "BestCase", aaLeft: "1463", aaRight: "1487", ntLeft: "4387", ntRight: "4461" },
  { key: "rcn2", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "clint1", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "chst12", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "pbx1", filterType: "SecondBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "acp5", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "abcb11", filterType: "BestCase", aaLeft: "970", aaRight: "994", ntLeft: "2908", ntRight: "2982" },
  { key: "igdcc4", filterType: "BestCase", aaLeft: "1050", aaRight: "1074", ntLeft: "3148", ntRight: "3222" },
  { key: "sphk2", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "msto1", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "cadm3", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "atp11a", filterType: "BestCase", aaLeft: "440", aaRight: "464", ntLeft: "1318", ntRight: "1392" },
  { key: "trit1", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "cth", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "tmem132d", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "mmp11", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "ptpn18", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "cnbd2", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "megf6", filterType: "BestCase", aaLeft: "1170", aaRight: "1194", ntLeft: "3508", ntRight: "3582" },
  { key: "mrpl30", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "cab39", filterType: "SecondBest", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "cavin4", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "or13c8", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "polr3g", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "abcc2", filterType: "BestCase", aaLeft: "648", aaRight: "672", ntLeft: "1942", ntRight: "2016" },
  { key: "septin9", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "cd180", filterType: "FailedAllFilters", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "znf697", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "or5p3", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "zg16", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "rnf135", filterType: "FailedAllFilters", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "ybx3", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "cst3", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "uqcr11", filterType: "FourthBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "poteb2", filterType: "FailedAllFilters", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "rpf1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "mmp26", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "sema5b", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "pramef2", filterType: "FailedAllFilters", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "prmt5", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "grpr", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "scn11a", filterType: "BestCase", aaLeft: "1373", aaRight: "1397", ntLeft: "4117", ntRight: "4191" },
  { key: "rab3c", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "strada", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "or2v1", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "pla2g2f", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "sp6", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "stmn4", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "rpe65", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "or11g2", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "dnah11", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "ifnlr1", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "p3h1", filterType: "BestCase", aaLeft: "702", aaRight: "726", ntLeft: "2104", ntRight: "2178" },
  { key: "cyld", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "dhrs1", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "tubgcp2", filterType: "BestCase", aaLeft: "804", aaRight: "828", ntLeft: "2410", ntRight: "2484" },
  { key: "apobec3h", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "nr0b1", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "or5m8", filterType: "FailedAllFilters", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "igbp1", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "tas2r3", filterType: "FailedAllFilters", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "slc4a10", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "tmem59l", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "foxp3", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "rgs2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fa2h", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "azi2", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "spdye14", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "kcne3", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "cblb", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "ergic1", filterType: "SecondBest", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "spic", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "nckap1", filterType: "SecondBest", aaLeft: "721", aaRight: "745", ntLeft: "2161", ntRight: "2235" },
  { key: "hnrnpcl3", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "raet1e", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "klhl2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "spata4", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "dgcr8", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "apln", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "c11orf96", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "crtc2", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "pcolce2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc1a1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rph3al", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "ccdc93", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "or52e2", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "slc39a13", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rnpep", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "samd4b", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "ap1s1", filterType: "SecondBest", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "habp2", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "atg2b", filterType: "BestCase", aaLeft: "1765", aaRight: "1789", ntLeft: "5293", ntRight: "5367" },
  { key: "oxct2", filterType: "FailedAllFilters", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "psenen", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "or8g5", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "acbd7", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "trpm6", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "mpnd", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "clec1b", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "atf1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "kctd1", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "kif2a", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "jakmip2", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "zbtb7a", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "rnf19a", filterType: "BestCase", aaLeft: "780", aaRight: "804", ntLeft: "2338", ntRight: "2412" },
  { key: "or8d2", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "dcbld1", filterType: "FailedAllFilters", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "fam9c", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "aqp11", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "alg14", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "eif5a2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pum2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "rbl1", filterType: "BestCase", aaLeft: "706", aaRight: "730", ntLeft: "2116", ntRight: "2190" },
  { key: "tmem104", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "h3c12", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "c1orf167", filterType: "FailedAllFilters", aaLeft: "681", aaRight: "705", ntLeft: "2041", ntRight: "2115" },
  { key: "plin2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "atpsckmt", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "lamc1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "lix1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "frmd4a", filterType: "BestCase", aaLeft: "579", aaRight: "603", ntLeft: "1735", ntRight: "1809" },
  { key: "dpysl4", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "nkx2-3", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "slc29a4", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "gpihbp1", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "cuedc2", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "lrrc7", filterType: "BestCase", aaLeft: "557", aaRight: "581", ntLeft: "1669", ntRight: "1743" },
  { key: "clic5", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "znf783", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "emb", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "lrfn1", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "gage12h", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "prpf38a", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ptpra", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "c3orf33", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "mrpl52", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "emc1", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "pex1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "krtap19-7", filterType: "FourthBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "aoc2", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "ntn4", filterType: "FailedAllFilters", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "pnma6f", filterType: "FailedAllFilters", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "tmem238", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "pdgfra", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "pafah1b3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "gtf2b", filterType: "SecondBest", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "shcbp1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "fzd3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "thrsp", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "parn", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "pabpc4", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "commd7", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "fez2", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "wdr6", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "rpl39l", filterType: "FourthBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "mettl21a", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "eif4e3", filterType: "SecondBest", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "zxda", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "prdx1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "znf831", filterType: "FailedAllFilters", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "xkr6", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "trnt1", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "stmnd1", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "clstn3", filterType: "BestCase", aaLeft: "913", aaRight: "937", ntLeft: "2737", ntRight: "2811" },
  { key: "usp2", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "arid1a", filterType: "BestCase", aaLeft: "1922", aaRight: "1946", ntLeft: "5764", ntRight: "5838" },
  { key: "znf488", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "fam217a", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "mex3a", filterType: "BestCase", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "gapdhs", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "pole2", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "scml4", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "map7", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "lias", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "antxrl", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "nutm2f", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "pla2g4d", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "tgfbr1", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "atad3c", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "sox3", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "znf197", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "fitm1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rab5a", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "sugct", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "pglyrp2", filterType: "FailedAllFilters", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "gypc", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "rdh12", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "ablim3", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "hexim2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "bcl7a", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "col7a1", filterType: "BestCase", aaLeft: "1185", aaRight: "1209", ntLeft: "3553", ntRight: "3627" },
  { key: "igfbp2", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "mtrf1", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "derl3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "myh4", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "scamp1", filterType: "SecondBest", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "palm2akap2", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "gcgr", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "yrdc", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fev", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "tor1aip2", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "or4c16", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "gnl3", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "tfdp1", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "spag6", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "defa5", filterType: "FailedAllFilters", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "rnf157", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "iqgap3", filterType: "BestCase", aaLeft: "643", aaRight: "667", ntLeft: "1927", ntRight: "2001" },
  { key: "krbox4", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cd300h", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "tpsg1", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "fancc", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "taok2", filterType: "BestCase", aaLeft: "1129", aaRight: "1153", ntLeft: "3385", ntRight: "3459" },
  { key: "gmcl1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "alox5ap", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "zfand4", filterType: "FailedAllFilters", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "wfdc12", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "dthd1", filterType: "FailedAllFilters", aaLeft: "818", aaRight: "842", ntLeft: "2452", ntRight: "2526" },
  { key: "tpd52", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "atp2b2", filterType: "BestCase", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "micos13", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "sidt1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "camk2n1", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "rab3ip", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "cdc42bpg", filterType: "BestCase", aaLeft: "1448", aaRight: "1472", ntLeft: "4342", ntRight: "4416" },
  { key: "cep85", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "naa38", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ing2", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "bsn", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "kcnc1", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "pirt", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "eif2b5", filterType: "BestCase", aaLeft: "494", aaRight: "518", ntLeft: "1480", ntRight: "1554" },
  { key: "slc38a10", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "mt1a", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "krt18", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "nnt", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "xage1a", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc6a1", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "stx5", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "lrrc75a", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "tpm1", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "morf4l1", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "nms", filterType: "FailedAllFilters", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "selenov", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "nfkbil1", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "c4orf48", filterType: "SecondBest", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "nrxn3", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "mtarc1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "adck5", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "bpi", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "mrtfa", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "dgcr6l", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "prm2", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "pla2g12b", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "pfdn2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pde6b", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "or1k1", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "dpep2nb", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "gpr19", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "tafa4", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "fdx1", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "fut8", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "ccdc186", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "lrrtm3", filterType: "BestCase", aaLeft: "533", aaRight: "557", ntLeft: "1597", ntRight: "1671" },
  { key: "litafd", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "c1orf105", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "figla", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "arhgap23", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "tcf24", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "fbxl6", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "slc39a7", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "dlg3", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "gimap2", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "lnx1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "ifi27l2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "krt33b", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "pipox", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "ldoc1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "cldn18", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "clec4g", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "gab4", filterType: "BestCase", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "glrx5", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rab40b", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "fpgs", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "pde12", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "pcdha3", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "thrb", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "dhx38", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "cript", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ret", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "c21orf58", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "pcnt", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "ncr3lg1", filterType: "FailedAllFilters", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "h3-5", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "fam120a", filterType: "BestCase", aaLeft: "1050", aaRight: "1074", ntLeft: "3148", ntRight: "3222" },
  { key: "tle6", filterType: "FailedAllFilters", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "rnf183", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "med12", filterType: "BestCase", aaLeft: "1816", aaRight: "1840", ntLeft: "5446", ntRight: "5520" },
  { key: "znf621", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "gnai2", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "synpo", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "rbm15b", filterType: "FailedAllFilters", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "aldoc", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cygb", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "znf19", filterType: "FailedAllFilters", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "slc9a5", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "hs3st1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "lrp6", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "prkra", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "traf3ip2", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "eif3e", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "ca12", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "psmd9", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "cdc42se2", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "cacna1f", filterType: "BestCase", aaLeft: "1752", aaRight: "1776", ntLeft: "5254", ntRight: "5328" },
  { key: "fate1", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "ddb2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "znf143", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "or51v1", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "tmem200a", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "letmd1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rnf113b", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "cdca8", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf174", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "efcab13", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "olfml2a", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "rasa4b", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "krtap4-11", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "slc9b2", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "ddx25", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "dync1i2", filterType: "SecondBest", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "fam219b", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "iws1", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "spcs3", filterType: "SecondBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "tram1l1", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "snx32", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "lelp1", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "scube1", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "col20a1", filterType: "FailedAllFilters", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "pramef19", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "epb41l1", filterType: "BestCase", aaLeft: "718", aaRight: "742", ntLeft: "2152", ntRight: "2226" },
  { key: "sgta", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "med25", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "cd101", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "znf616", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "iqch", filterType: "BestCase", aaLeft: "859", aaRight: "883", ntLeft: "2575", ntRight: "2649" },
  { key: "mat2b", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ttc9", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "pcdhb4", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "slc36a3", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "rasgrf1", filterType: "BestCase", aaLeft: "758", aaRight: "782", ntLeft: "2272", ntRight: "2346" },
  { key: "ccdc152", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "c6orf226", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "rmdn1", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "pak2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "lamtor4", filterType: "SecondBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ddx3y", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "ccdc12", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tspan18", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "znf548", filterType: "FailedAllFilters", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "mbd3l2", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "siglec12", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "lrrc24", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "eef2kmt", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "rwdd2a", filterType: "FailedAllFilters", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "nr1h3", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "nrg1", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "rps6ka1", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "znf735", filterType: "FailedAllFilters", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "ctsc", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "gpr132", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "aimp2", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "trim13", filterType: "FailedAllFilters", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "scn4a", filterType: "BestCase", aaLeft: "1761", aaRight: "1785", ntLeft: "5281", ntRight: "5355" },
  { key: "plscr2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rab21", filterType: "SecondBest", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "mxd4", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "loxl3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccr8", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "fam171a2", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "rap1b", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "rsph10b2", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "prc1", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "kansl1l", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "ano2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "znrf4", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ndufs2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "src", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ino80e", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "nkpd1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "tial1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tcaim", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "sgf29", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "hhatl", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "trim24", filterType: "BestCase", aaLeft: "1000", aaRight: "1024", ntLeft: "2998", ntRight: "3072" },
  { key: "ttc21a", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "trim43b", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "atp13a2", filterType: "BestCase", aaLeft: "797", aaRight: "821", ntLeft: "2389", ntRight: "2463" },
  { key: "casp1", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "fam90a26", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "aopep", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "cxcl3", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "glrb", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "tepp", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "pcmtd2", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "tbc1d9", filterType: "BestCase", aaLeft: "1071", aaRight: "1095", ntLeft: "3211", ntRight: "3285" },
  { key: "cav2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "arhgef15", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "ccl4", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "or52n4", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ap3b1", filterType: "BestCase", aaLeft: "1005", aaRight: "1029", ntLeft: "3013", ntRight: "3087" },
  { key: "ccdc25", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "ugdh", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "or1e2", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "pde8a", filterType: "BestCase", aaLeft: "699", aaRight: "723", ntLeft: "2095", ntRight: "2169" },
  { key: "sft2d3", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "sapcd1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "ly6k", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "znf121", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "atp5mc2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "washc1", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "ahr", filterType: "FailedAllFilters", aaLeft: "814", aaRight: "838", ntLeft: "2440", ntRight: "2514" },
  { key: "bfsp1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "nelfe", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cpne8", filterType: "SecondBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "c1qtnf7", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "nlrp1", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "or7g1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "csag2", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "lactb2", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "capn6", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "pgbd3", filterType: "FailedAllFilters", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "gsc2", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "p2rx2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "gng5", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "txn2", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "nt5m", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "fam83d", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "nedd4", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "ercc6l", filterType: "FailedAllFilters", aaLeft: "964", aaRight: "988", ntLeft: "2890", ntRight: "2964" },
  { key: "c8orf58", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "il25", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "tmem14c", filterType: "FourthBest", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "arl3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "smim31", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "scarf1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "kctd14", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "stau2", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "krtap4-5", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "peak1", filterType: "BestCase", aaLeft: "1436", aaRight: "1460", ntLeft: "4306", ntRight: "4380" },
  { key: "plvap", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "mrs2", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cxcl10", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mios", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "rnase12", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "c3orf85", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "znf674", filterType: "FailedAllFilters", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "cd163", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "insl5", filterType: "FailedAllFilters", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "mmp8", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "gtf3c1", filterType: "BestCase", aaLeft: "1665", aaRight: "1689", ntLeft: "4993", ntRight: "5067" },
  { key: "ddx47", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "col27a1", filterType: "BestCase", aaLeft: "1025", aaRight: "1049", ntLeft: "3073", ntRight: "3147" },
  { key: "cdk11a", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "dctn6", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "sstr3", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "ldb3", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "xbp1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "usp19", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "rpl17", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "adss2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gabpa", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "lrrn3", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "rnpepl1", filterType: "BestCase", aaLeft: "692", aaRight: "716", ntLeft: "2074", ntRight: "2148" },
  { key: "cercam", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "limk1", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "glp2r", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "exosc9", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "larp4", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "znf248", filterType: "FailedAllFilters", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "rab43", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "edil3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "actb", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "angptl8", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "nell1", filterType: "BestCase", aaLeft: "727", aaRight: "751", ntLeft: "2179", ntRight: "2253" },
  { key: "snrnp40", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "pabir2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ihh", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "frmd3", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "rad9a", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "ctxnd2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gpr150", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "uck2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "kdm5b", filterType: "BestCase", aaLeft: "1154", aaRight: "1178", ntLeft: "3460", ntRight: "3534" },
  { key: "trim71", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "igf2", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "aadacl3", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "or10k2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "lrrc46", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "tmem60", filterType: "SecondBest", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "mylk2", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "golga8f", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "cnbp", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "ephx3", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "trpc5", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "supt20hl2", filterType: "FailedAllFilters", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "gtf2a2", filterType: "SecondBest", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ndst1", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "or7a10", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "dnajb4", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "tubb6", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "dynlt2b", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "fam189a2", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "ccdc70", filterType: "FourthBest", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "or52k1", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "htr3a", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "arhgef40", filterType: "BestCase", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "defb113", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ppp2r5d", filterType: "SecondBest", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "igsf21", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "ccdc107", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dydc2", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "il2rg", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "selenoh", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "ccdc47", filterType: "SecondBest", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "defb124", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "scoc", filterType: "SecondBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "lrrc71", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "or10h5", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "nr2c2ap", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "klb", filterType: "BestCase", aaLeft: "877", aaRight: "901", ntLeft: "2629", ntRight: "2703" },
  { key: "hey2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "klrc3", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "wnk3", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "sec61a2", filterType: "SecondBest", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "or8j1", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "dnaja3", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "ivd", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rab22a", filterType: "SecondBest", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "ifne", filterType: "FailedAllFilters", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "pitx2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "thoc1", filterType: "BestCase", aaLeft: "563", aaRight: "587", ntLeft: "1687", ntRight: "1761" },
  { key: "hyal1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "tma7", filterType: "SecondBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ap4b1", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "trim46", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "fbxl12", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "mre11", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "b4galnt2", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "c11orf42", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "atp6v1e2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "psmc3ip", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "ikbkg", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "rhoq", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "ninj1", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "cpa2", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "kcnh7", filterType: "BestCase", aaLeft: "989", aaRight: "1013", ntLeft: "2965", ntRight: "3039" },
  { key: "inafm2", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "gls", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "glud2", filterType: "FailedAllFilters", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "cwc25", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "shank1", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "tardbp", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "st6gal1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "gorasp2", filterType: "BestCase", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "ppm1f", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "oxsm", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rgl2", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "gosr2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "arrb1", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "cyp39a1", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "wdr97", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "pdcd2l", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "foxo3", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "afdn", filterType: "BestCase", aaLeft: "1356", aaRight: "1380", ntLeft: "4066", ntRight: "4140" },
  { key: "ric1", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "or10z1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "pnmt", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "adra1a", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "ppp1r12b", filterType: "BestCase", aaLeft: "586", aaRight: "610", ntLeft: "1756", ntRight: "1830" },
  { key: "pard6b", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "taf6", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "eln", filterType: "FourthBest", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "h3c8", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "c4orf36", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "calhm2", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "serpinb2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "icam1", filterType: "FailedAllFilters", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "khnyn", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "asb8", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "prr13", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mak16", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "stard6", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "serpina5", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "zdhhc7", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "bcat1", filterType: "BestCase", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "plaa", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "cd3d", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "cul5", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "iftap", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cacng4", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "mthfd1l", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "smdt1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "pcdhac1", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "c4bpa", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "fgf22", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "hnf1a", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "slc4a2", filterType: "BestCase", aaLeft: "872", aaRight: "896", ntLeft: "2614", ntRight: "2688" },
  { key: "kifc2", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "esf1", filterType: "BestCase", aaLeft: "798", aaRight: "822", ntLeft: "2392", ntRight: "2466" },
  { key: "eif1ay", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "recql4", filterType: "BestCase", aaLeft: "931", aaRight: "955", ntLeft: "2791", ntRight: "2865" },
  { key: "bmpr1a", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "pgam5", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "or6t1", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "spout1", filterType: "BestCase", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "grin3b", filterType: "FailedAllFilters", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "rnf4", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "bag2", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "pop7", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "gpr50", filterType: "FailedAllFilters", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "pcdh12", filterType: "BestCase", aaLeft: "1148", aaRight: "1172", ntLeft: "3442", ntRight: "3516" },
  { key: "slc16a5", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "amtn", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "clec10a", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cadps2", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "lig1", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "il1r2", filterType: "FailedAllFilters", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "tcte1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "bik", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "tead1", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "cxorf51a", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "loc105372440", filterType: "FourthBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "mdk", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "znf394", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "e2f7", filterType: "BestCase", aaLeft: "860", aaRight: "884", ntLeft: "2578", ntRight: "2652" },
  { key: "spag17", filterType: "BestCase", aaLeft: "2004", aaRight: "2028", ntLeft: "6010", ntRight: "6084" },
  { key: "fam47b", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "adgrg3", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "cenpa", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "irf8", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "lhx9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmem271", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "pip5k1a", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "zscan26", filterType: "FailedAllFilters", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "fam237b", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rxfp3", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "nup37", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "rasl11a", filterType: "BestCase", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "crtac1", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "cyp2j2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "icoslg", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "abca9", filterType: "BestCase", aaLeft: "1316", aaRight: "1340", ntLeft: "3946", ntRight: "4020" },
  { key: "tbrg1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "znf878", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "or4a15", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "mrps21", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "ubfd1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "tlr7", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pip4p1", filterType: "SecondBest", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "tdgf1", filterType: "FourthBest", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "sirpg", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "map10", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "scnn1b", filterType: "BestCase", aaLeft: "586", aaRight: "610", ntLeft: "1756", ntRight: "1830" },
  { key: "kcnj11", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "zc3h3", filterType: "BestCase", aaLeft: "794", aaRight: "818", ntLeft: "2380", ntRight: "2454" },
  { key: "tbx15", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "col5a2", filterType: "BestCase", aaLeft: "1469", aaRight: "1493", ntLeft: "4405", ntRight: "4479" },
  { key: "serpina12", filterType: "FailedAllFilters", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "tmsb15b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hspa4l", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "parp10", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "srsf10", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "irx4", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "tbx22", filterType: "FailedAllFilters", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "clcn6", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "nt5c3b", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "plk5", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "glipr2", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "lipn", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "rpap2", filterType: "FailedAllFilters", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "sncaip", filterType: "BestCase", aaLeft: "759", aaRight: "783", ntLeft: "2275", ntRight: "2349" },
  { key: "fmnl3", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "mrps16", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "stk25", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "ednra", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "dcanp1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf84", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "mos", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "rock2", filterType: "BestCase", aaLeft: "744", aaRight: "768", ntLeft: "2230", ntRight: "2304" },
  { key: "dimt1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "chrac1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "il1f10", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "lypla2", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fcgrt", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "adam30", filterType: "FailedAllFilters", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "ubd", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "fgfrl1", filterType: "BestCase", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "ndrg1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "nlrp13", filterType: "FailedAllFilters", aaLeft: "1003", aaRight: "1027", ntLeft: "3007", ntRight: "3081" },
  { key: "polr2k", filterType: "FourthBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "zadh2", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "dennd5a", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "gapvd1", filterType: "BestCase", aaLeft: "1035", aaRight: "1059", ntLeft: "3103", ntRight: "3177" },
  { key: "nags", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "shisal1", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "rsad1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "bri3", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ppp3cc", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "nrbp1", filterType: "SecondBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "dnaaf2", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "wasf2", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "spef1", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "dna2", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "krtap9-2", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "itga2b", filterType: "BestCase", aaLeft: "843", aaRight: "867", ntLeft: "2527", ntRight: "2601" },
  { key: "urm1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "timm23b", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "cacnb1", filterType: "BestCase", aaLeft: "556", aaRight: "580", ntLeft: "1666", ntRight: "1740" },
  { key: "or5au1", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "enpp6", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "c4orf54", filterType: "BestCase", aaLeft: "1295", aaRight: "1319", ntLeft: "3883", ntRight: "3957" },
  { key: "noc4l", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "brip1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "steap2", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "or4k2", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "rnf222", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "robo3", filterType: "BestCase", aaLeft: "705", aaRight: "729", ntLeft: "2113", ntRight: "2187" },
  { key: "foxi1", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "mettl14", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "cdx2", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "znf580", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "celf1", filterType: "SecondBest", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "fam183a", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "pik3c2g", filterType: "BestCase", aaLeft: "1235", aaRight: "1259", ntLeft: "3703", ntRight: "3777" },
  { key: "crybg1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "ccdc6", filterType: "BestCase", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "trappc3l", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "h4c8", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mamstr", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ndufa6", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "nxph2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "mycl", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "gpr32", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "metap2", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "rnf32", filterType: "FailedAllFilters", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "kri1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "kpna1", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ndufb1", filterType: "FourthBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "ints6l", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "nol9", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "rnf215", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "usp9x", filterType: "BestCase", aaLeft: "1951", aaRight: "1975", ntLeft: "5851", ntRight: "5925" },
  { key: "mterf4", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "nudt14", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "mettl23", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "fam25a", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "traf1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "mllt10", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "ccl18", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "sqstm1", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "lpin3", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "nkapd1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "tars2", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "dlx3", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "slc44a5", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "samd3", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "jmjd6", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "etf1", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "hoxb13", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "gfy", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "crygb", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "arhgef7", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "tnfsf15", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slc3a2", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "itgad", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "ap3s2", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "fam53c", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "cd9", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "hebp2", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "bcar3", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "ppp1r3c", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "pcdhga2", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "nipa2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "rnft2", filterType: "SecondBest", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "b3galnt1", filterType: "FailedAllFilters", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "fstl4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "trim3", filterType: "SecondBest", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "sacm1l", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ankrd63", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "siva1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "mlxipl", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "dnajc25", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "herc3", filterType: "BestCase", aaLeft: "867", aaRight: "891", ntLeft: "2599", ntRight: "2673" },
  { key: "pfkl", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cela2a", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "syndig1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "txnl4b", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "setd6", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "odf2l", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "or13g1", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "gbe1", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "iscu", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "c3orf18", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "scrn2", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "cdyl2", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "drd1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "srsf6", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "cotl1", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cnga4", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "tmem167b", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "prkcb", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "adgra1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "or4f15", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "or51g2", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "cyb5r4", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "rbak", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "tmem213", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "erlin1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "oip5", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "dhx8", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "parm1", filterType: "FailedAllFilters", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "lpgat1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "lgals14", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "bpgm", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "dbndd1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "nup62", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "extl2", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "tbx1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "spata31d4", filterType: "FailedAllFilters", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "cldn6", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "heyl", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "septin10", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "zc3h18", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "cngb3", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "slc7a9", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "rgcc", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "cc2d2a", filterType: "BestCase", aaLeft: "1067", aaRight: "1091", ntLeft: "3199", ntRight: "3273" },
  { key: "adamts13", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "pptc7", filterType: "SecondBest", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "denr", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gsta5", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "slc22a12", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "or5i1", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "scaf1", filterType: "FailedAllFilters", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "lkaaear1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "sec23ip", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "prickle1", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "odad1", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "pcdhgb5", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "has3", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "mageb6b", filterType: "FailedAllFilters", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "ankrd54", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "efl1", filterType: "BestCase", aaLeft: "895", aaRight: "919", ntLeft: "2683", ntRight: "2757" },
  { key: "gpatch8", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "sh2d3a", filterType: "FailedAllFilters", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "dnajc12", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "nexmif", filterType: "BestCase", aaLeft: "913", aaRight: "937", ntLeft: "2737", ntRight: "2811" },
  { key: "btn3a2", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "gabbr1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "mrps26", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "mcm8", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "myct1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "cnih2", filterType: "SecondBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "ceacam7", filterType: "ThirdBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "tpcn2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "thumpd2", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "sigmar1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "kcnj16", filterType: "FailedAllFilters", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "zc3h4", filterType: "BestCase", aaLeft: "1014", aaRight: "1038", ntLeft: "3040", ntRight: "3114" },
  { key: "spty2d1", filterType: "FailedAllFilters", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "stag1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "aco2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "cenpf", filterType: "FailedAllFilters", aaLeft: "1371", aaRight: "1395", ntLeft: "4111", ntRight: "4185" },
  { key: "fam47e", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "adgrg4", filterType: "FailedAllFilters", aaLeft: "1713", aaRight: "1737", ntLeft: "5137", ntRight: "5211" },
  { key: "gdnf", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "cby1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "dennd6a", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "sting1", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "alb", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or2j1", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "zscan21", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "or51a7", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "shisal2a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rxfp4", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "rnase9", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "vti1b", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "pnliprp1", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cspg4", filterType: "BestCase", aaLeft: "1370", aaRight: "1394", ntLeft: "4108", ntRight: "4182" },
  { key: "krtap21-1", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "nlrp14", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "fam234b", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "fli1", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "ammecr1l", filterType: "SecondBest", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "abcb9", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "polr2l", filterType: "SecondBest", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "ftl", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ttc9c", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "sort1", filterType: "BestCase", aaLeft: "793", aaRight: "817", ntLeft: "2377", ntRight: "2451" },
  { key: "colgalt1", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "zcchc9", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "zscan16", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "irag1", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "tmem241", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "plk2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "kiaa0100", filterType: "BestCase", aaLeft: "1505", aaRight: "1529", ntLeft: "4513", ntRight: "4587" },
  { key: "fkbp1a", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "clcn1", filterType: "BestCase", aaLeft: "923", aaRight: "947", ntLeft: "2767", ntRight: "2841" },
  { key: "irx3", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "nr5a2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "c10orf53", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rcan2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ankrd31", filterType: "FailedAllFilters", aaLeft: "1322", aaRight: "1346", ntLeft: "3964", ntRight: "4038" },
  { key: "lipi", filterType: "FailedAllFilters", aaLeft: "395", aaRight: "419", ntLeft: "1183", ntRight: "1257" },
  { key: "srprb", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "or2t35", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "mrps11", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "tnk1", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "slc12a9", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "vwa5b1", filterType: "BestCase", aaLeft: "600", aaRight: "624", ntLeft: "1798", ntRight: "1872" },
  { key: "znf83", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "mtfr2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "sgcg", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mthfd2l", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "taf1", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "itm2b", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "spns3", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "cnst", filterType: "BestCase", aaLeft: "493", aaRight: "517", ntLeft: "1477", ntRight: "1551" },
  { key: "calhm5", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "kif20a", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "sucla2", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "serpinb5", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "mfap3l", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "ralyl", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "gtf2ird2b", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "tpgs2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ackr1", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "rbbp6", filterType: "BestCase", aaLeft: "1659", aaRight: "1683", ntLeft: "4975", ntRight: "5049" },
  { key: "znf287", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "rbp1", filterType: "SecondBest", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "cxorf38", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cryba4", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "fubp3", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "or1l8", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "rp9", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "tmem106a", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "ppm1a", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "dnai7", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "cps1", filterType: "BestCase", aaLeft: "837", aaRight: "861", ntLeft: "2509", ntRight: "2583" },
  { key: "cwc22", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "agr2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fgf12", filterType: "SecondBest", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "ankk1", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "fam20c", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "shox2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "foxo4", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "wdr90", filterType: "FailedAllFilters", aaLeft: "1240", aaRight: "1264", ntLeft: "3718", ntRight: "3792" },
  { key: "slc4a5", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "got2", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "vsx1", filterType: "SecondBest", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "rusf1", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "foxl3", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "cyp4f11", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "tmem98", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "patl2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "shpk", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "pgam2", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "lingo1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "crybb3", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "primpol", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "fblim1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "pcdh15", filterType: "BestCase", aaLeft: "1664", aaRight: "1688", ntLeft: "4990", ntRight: "5064" },
  { key: "dffa", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "slc16a2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "bag5", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "tmem128", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "churc1", filterType: "FourthBest", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "slc43a2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "tlnrd1", filterType: "FailedAllFilters", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "ttc37", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "slco4c1", filterType: "FailedAllFilters", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "cwc15", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "b3gnt7", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "eid3", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "musk", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "serf2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "prr14", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "c1d", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "cacng3", filterType: "SecondBest", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "notch2nlr", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "adra2a", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "cul2", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "psors1c2", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "bdkrb1", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "acot8", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "prkce", filterType: "SecondBest", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "cc2d1a", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "cnga3", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "arl5b", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "srsf1", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "npy4r2", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "lyrm7", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "tmem214", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "matn2", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "trex1", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "sod3", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "or2l3", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cyb5r3", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "slc38a8", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "trim4", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "fstl3", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "gigyf2", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "pcdhga5", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "kank1", filterType: "BestCase", aaLeft: "1131", aaRight: "1155", ntLeft: "3391", ntRight: "3465" },
  { key: "tpd52l1", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "ppp1r3d", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "herc4", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "krtap26-1", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "sec62", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "dnajc22", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "sike1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "rbis", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf493", filterType: "FailedAllFilters", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "hoxc4", filterType: "SecondBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "aicda", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "serinc1", filterType: "SecondBest", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "gsta2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "pla1a", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "slc22a15", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "gli2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "or4a47", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "frzb", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "idi1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "svil", filterType: "BestCase", aaLeft: "1418", aaRight: "1442", ntLeft: "4252", ntRight: "4326" },
  { key: "stk40", filterType: "BestCase", aaLeft: "383", aaRight: "407", ntLeft: "1147", ntRight: "1221" },
  { key: "pfkfb1", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "adipoq", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "pcdhgb2", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ugt1a10", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "themis", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "sec16b", filterType: "BestCase", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "pip4k2b", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "rxylt1", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "pex11g", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "dnajc15", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "ankrd53", filterType: "FailedAllFilters", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "nploc4", filterType: "BestCase", aaLeft: "544", aaRight: "568", ntLeft: "1630", ntRight: "1704" },
  { key: "ptgr1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "klk8", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "adgrb1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "tmem223", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "brca2", filterType: "FailedAllFilters", aaLeft: "3362", aaRight: "3386", ntLeft: "10084", ntRight: "10158" },
  { key: "lgals13", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "arid2", filterType: "BestCase", aaLeft: "1433", aaRight: "1457", ntLeft: "4297", ntRight: "4371" },
  { key: "mrpl49", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "cldn1", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "gbf1", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "bod1l1", filterType: "FailedAllFilters", aaLeft: "2081", aaRight: "2105", ntLeft: "6241", ntRight: "6315" },
  { key: "slc7a6os", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "dhh", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "spata31d3", filterType: "FailedAllFilters", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "or13d1", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "myod1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "tbx6", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "adamts14", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "wtap", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "cbln2", filterType: "SecondBest", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "drg1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "c19orf18", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "robo4", filterType: "BestCase", aaLeft: "747", aaRight: "771", ntLeft: "2239", ntRight: "2313" },
  { key: "dhrs13", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "rnf225", filterType: "FailedAllFilters", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "or4k5", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "znf587", filterType: "FailedAllFilters", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "phykpl", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "mettl13", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "pdss1", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "chic2", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "plac8l1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "celf6", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tff1", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ndufa1", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "hmgcll1", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "kpnb1", filterType: "SecondBest", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "osbpl9", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "znf2", filterType: "FailedAllFilters", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "vip", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "dnaaf5", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "cfap299", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "ctdspl", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "mid2", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "c4a", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "bbox1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tbc1d3f", filterType: "FailedAllFilters", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "pank2", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "enpp1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "krt34", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "tmem176a", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "ccl28", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "nr3c1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "prima1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "sgo1", filterType: "FailedAllFilters", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "gabrg2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "slc11a2", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "tac4", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "nupr2", filterType: "FourthBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "mrps9", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "cabp7", filterType: "SecondBest", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "proser2", filterType: "FailedAllFilters", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "ube4b", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "slc44a2", filterType: "BestCase", aaLeft: "518", aaRight: "542", ntLeft: "1552", ntRight: "1626" },
  { key: "dlx4", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "duox2", filterType: "BestCase", aaLeft: "1334", aaRight: "1358", ntLeft: "4000", ntRight: "4074" },
  { key: "tnfsf12", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "cd2ap", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "sult1a2", filterType: "FourthBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ripk1", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "bmt2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tjp3", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "cyp4a11", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "prr5", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "gpr35", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "fam180a", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "asz1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ndufb6", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "plekhf2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "kpna6", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cisd1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "txn", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rnf212", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pnrc1", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "fastkd3", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "traf6", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "cldn34", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "foxj1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "nudt13", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "mettl24", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "mterf3", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "snrnp25", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "fastk", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ctsd", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "gpr135", filterType: "FailedAllFilters", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "atxn7l2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "plscr5", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "fam131b", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "trim14", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "anks4b", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "mgst1", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "mxd3", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "loxl4", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "rab26", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "rap2b", filterType: "SecondBest", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "cfap251", filterType: "BestCase", aaLeft: "773", aaRight: "797", ntLeft: "2317", ntRight: "2391" },
  { key: "cebpzos", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "tmco2", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "ism1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "pak5", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "zgrf1", filterType: "FailedAllFilters", aaLeft: "1965", aaRight: "1989", ntLeft: "5893", ntRight: "5967" },
  { key: "pttg1ip2", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "myoc", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "nefm", filterType: "FailedAllFilters", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "ppp2r3a", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "lamtor3", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "rplp2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "arhgef25", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "mbnl2", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "lrrc23", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "klrf1", filterType: "FailedAllFilters", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "siglec15", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "immt", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "fam90a16", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "gan", filterType: "SecondBest", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "mbd3l5", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "rps6ka6", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "nr1h4", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "lrrc14", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "mmab", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "mrps18a", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "znf578", filterType: "FailedAllFilters", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "u2af1", filterType: "SecondBest", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "rps6kb1", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "scaper", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "npepps", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "fam78a", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "col8a2", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "alpk1", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "rpp21", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ccdc22", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tmem182", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "ccl3", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "arhgef12", filterType: "BestCase", aaLeft: "1306", aaRight: "1330", ntLeft: "3916", ntRight: "3990" },
  { key: "ano5", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "magea9", filterType: "FailedAllFilters", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "tnni2", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "lrrn4cl", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "lrrk1", filterType: "BestCase", aaLeft: "1873", aaRight: "1897", ntLeft: "5617", ntRight: "5691" },
  { key: "parva", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ino80b", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "rabggtb", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "znrf3", filterType: "FailedAllFilters", aaLeft: "885", aaRight: "909", ntLeft: "2653", ntRight: "2727" },
  { key: "ndufs5", filterType: "FourthBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "kcnn2", filterType: "BestCase", aaLeft: "743", aaRight: "767", ntLeft: "2227", ntRight: "2301" },
  { key: "spanxn2", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "eed", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tbca", filterType: "FourthBest", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "prss55", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "armh1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "cant1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "dbf4", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "scn7a", filterType: "BestCase", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "casp6", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "atp13a5", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "trim23", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "hla-drb1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "hs3st6", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "slc9a2", filterType: "BestCase", aaLeft: "752", aaRight: "776", ntLeft: "2254", ntRight: "2328" },
  { key: "lrp1", filterType: "BestCase", aaLeft: "4411", aaRight: "4435", ntLeft: "13231", ntRight: "13305" },
  { key: "eif3b", filterType: "BestCase", aaLeft: "762", aaRight: "786", ntLeft: "2284", ntRight: "2358" },
  { key: "rad50", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "fam90a7", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "ppial4g", filterType: "FourthBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "scyl1", filterType: "BestCase", aaLeft: "591", aaRight: "615", ntLeft: "1771", ntRight: "1845" },
  { key: "paics", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "cacna1a", filterType: "BestCase", aaLeft: "1422", aaRight: "1446", ntLeft: "4264", ntRight: "4338" },
  { key: "pknox1", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "kdsr", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "cope", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "rab8b", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "pcdha4", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "h2ab3", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "macrod2", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "pnma8b", filterType: "FailedAllFilters", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "fpgt", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "prpf8", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "cep85l", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "atp6v0a4", filterType: "BestCase", aaLeft: "763", aaRight: "787", ntLeft: "2287", ntRight: "2361" },
  { key: "akt1s1", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "ros1", filterType: "BestCase", aaLeft: "1395", aaRight: "1419", ntLeft: "4183", ntRight: "4257" },
  { key: "c6orf58", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "tle1", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "ubl3", filterType: "SecondBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "enah", filterType: "BestCase", aaLeft: "416", aaRight: "440", ntLeft: "1246", ntRight: "1320" },
  { key: "oat", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "znf626", filterType: "FailedAllFilters", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "med15", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "gprin3", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "amacr", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ppip5k1", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "cyp2c8", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "ttc30b", filterType: "FailedAllFilters", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "tcf7l1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "gstp1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "pawr", filterType: "SecondBest", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "znf611", filterType: "FailedAllFilters", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "med22", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "pcdhb3", filterType: "FailedAllFilters", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "n4bp2", filterType: "FailedAllFilters", aaLeft: "1692", aaRight: "1716", ntLeft: "5074", ntRight: "5148" },
  { key: "kiaa2012", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "plxdc2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "slc36a4", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "tom1l2", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "coq10a", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "krtap3-2", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "efcab14", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "hps5", filterType: "BestCase", aaLeft: "570", aaRight: "594", ntLeft: "1708", ntRight: "1782" },
  { key: "cfap57", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "cfap410", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "mvb12b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "tgfb2", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "sycp1", filterType: "BestCase", aaLeft: "682", aaRight: "706", ntLeft: "2044", ntRight: "2118" },
  { key: "dram1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "trmt11", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "golga8a", filterType: "FailedAllFilters", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "ephx4", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "lrrc41", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "hcar3", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "tmem67", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "aadacl4", filterType: "FailedAllFilters", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "zrsr2", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "mt1x", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "vps25", filterType: "SecondBest", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "sntg2", filterType: "FailedAllFilters", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "or7a17", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "areg", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "tubb1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "igsf11", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ccdc137", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "coa8", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "defb114", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "ppp2r5c", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ltk", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "pigy", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "mrgprf", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "ccdc77", filterType: "BestCase", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "osbpl1a", filterType: "BestCase", aaLeft: "408", aaRight: "432", ntLeft: "1222", ntRight: "1296" },
  { key: "rab44", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "lrrn4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "fads2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "fbxl22", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "tsnaxip1", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "rln1", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "cpb2", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "nsf", filterType: "SecondBest", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "kcnk7", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "otop2", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "psph", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "gpr157", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "vnn2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "znf750", filterType: "FailedAllFilters", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "piwil4", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "gpx7", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "fscn1", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "thoc6", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "gpr160", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "ncs1", filterType: "SecondBest", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "pebp4", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "snrnp70", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "msr1", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "pabpc1l2a", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "trim41", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "map3k7", filterType: "BestCase", aaLeft: "427", aaRight: "451", ntLeft: "1279", ntRight: "1353" },
  { key: "prss37", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "kdm6b", filterType: "BestCase", aaLeft: "954", aaRight: "978", ntLeft: "2860", ntRight: "2934" },
  { key: "chrna9", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "nipbl", filterType: "BestCase", aaLeft: "644", aaRight: "668", ntLeft: "1930", ntRight: "2004" },
  { key: "rhov", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "alkal1", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "glp1r", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "or4k15", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "myh15", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "fbxl15", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "cpa5", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "kalrn", filterType: "BestCase", aaLeft: "2282", aaRight: "2306", ntLeft: "6844", ntRight: "6918" },
  { key: "exd2", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "selenoo", filterType: "BestCase", aaLeft: "552", aaRight: "576", ntLeft: "1654", ntRight: "1728" },
  { key: "ptger2", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "colec12", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "castor2", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "defb123", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "yipf7", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "l1td1", filterType: "FailedAllFilters", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "ccdc40", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "or52h1", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "klrc4", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "wnk4", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "or10h2", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "dnaja4", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "c19orf85", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "spink6", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "unkl", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "btbd9", filterType: "BestCase", aaLeft: "579", aaRight: "603", ntLeft: "1735", ntRight: "1809" },
  { key: "p2rx5", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "tvp23a", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "znf644", filterType: "BestCase", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "gng2", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "vrk1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "pgbd4", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "capn1", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "f8", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "il15", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "specc1", filterType: "BestCase", aaLeft: "915", aaRight: "939", ntLeft: "2743", ntRight: "2817" },
  { key: "eif4e1b", filterType: "BestCase", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "wnt7b", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "enkd1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "fam83c", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "psg7", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "pitpna", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "ctdsp2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "vgll1", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "numb", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "rpl27", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "sos2", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "lgmn", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "rasa1", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "ly6l", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "mrfap1l1", filterType: "FourthBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "usp29", filterType: "FailedAllFilters", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "ahcyl2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "echdc1", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "cd68", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "dsel", filterType: "BestCase", aaLeft: "949", aaRight: "973", ntLeft: "2845", ntRight: "2919" },
  { key: "slit2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "trmt44", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "nlrp6", filterType: "FailedAllFilters", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "znf439", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "mlec", filterType: "SecondBest", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "meig1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "nelfb", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "sdc2", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "rab33b", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "or9k2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "znf880", filterType: "FailedAllFilters", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "gtf3c6", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "cdadc1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cdc14b", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "rcc1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "pparg", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "lmtk3", filterType: "BestCase", aaLeft: "1041", aaRight: "1065", ntLeft: "3121", ntRight: "3195" },
  { key: "rpl10", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "slc26a7", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "dalrd3", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "dctn1", filterType: "BestCase", aaLeft: "1123", aaRight: "1147", ntLeft: "3367", ntRight: "3441" },
  { key: "sstr4", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "arpp21", filterType: "BestCase", aaLeft: "615", aaRight: "639", ntLeft: "1843", ntRight: "1917" },
  { key: "sumf1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "il22", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "slc2a11", filterType: "FailedAllFilters", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "eef1a1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "kctd13", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "glis2", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "smim36", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "atp6v0d1", filterType: "SecondBest", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "cxcl17", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "ct47a8", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ncdn", filterType: "SecondBest", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "krtap4-2", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "eva1c", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "izumo1", filterType: "FailedAllFilters", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "gage1", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "defb103b", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "cd164", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "pzp", filterType: "BestCase", aaLeft: "1285", aaRight: "1309", ntLeft: "3853", ntRight: "3927" },
  { key: "unk", filterType: "BestCase", aaLeft: "713", aaRight: "737", ntLeft: "2137", ntRight: "2211" },
  { key: "apoc2", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "fndc9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ap1ar", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "fut10", filterType: "FailedAllFilters", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "irf2bp1", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "or52e5", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "ctsz", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "slc1a6", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "ins", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmem134", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "tut7", filterType: "BestCase", aaLeft: "1402", aaRight: "1426", ntLeft: "4204", ntRight: "4278" },
  { key: "gdf9", filterType: "FailedAllFilters", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "ssx2", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "slc39a14", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "slc13a1", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "adamts8", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "got1l1", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "ammecr1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "c2orf88", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "mturn", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "kif1a", filterType: "BestCase", aaLeft: "1638", aaRight: "1662", ntLeft: "4912", ntRight: "4986" },
  { key: "slc46a1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "atf6", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "enkur", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "mipol1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "trpm1", filterType: "BestCase", aaLeft: "1133", aaRight: "1157", ntLeft: "3397", ntRight: "3471" },
  { key: "uhrf1bp1", filterType: "BestCase", aaLeft: "935", aaRight: "959", ntLeft: "2803", ntRight: "2877" },
  { key: "foxp4", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znf705b", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "armc7", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "mansc1", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "fndc11", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "iapp", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "asap3", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "sel1l3", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "rgs5", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "hnrnpcl4", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "kcne4", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "spdye13", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "brsk1", filterType: "BestCase", aaLeft: "733", aaRight: "757", ntLeft: "2197", ntRight: "2271" },
  { key: "timeless", filterType: "BestCase", aaLeft: "846", aaRight: "870", ntLeft: "2536", ntRight: "2610" },
  { key: "c11orf91", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "spata3", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "rasef", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "klhl5", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "isx", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "rasgef1c", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "adat1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "nadsyn1", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "tnfsf4", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "rspo1", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "dpysl3", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "nkx2-4", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "fbxw8", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "cstf3", filterType: "BestCase", aaLeft: "657", aaRight: "681", ntLeft: "1969", ntRight: "2043" },
  { key: "sec24b", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "dusp10", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "coq7", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "slc29a3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "lyplal1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "or6k6", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "tnfrsf4", filterType: "FailedAllFilters", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "anxa9", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "lonp2", filterType: "BestCase", aaLeft: "594", aaRight: "618", ntLeft: "1780", ntRight: "1854" },
  { key: "wbp2", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "znf784", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "mars1", filterType: "BestCase", aaLeft: "820", aaRight: "844", ntLeft: "2458", ntRight: "2532" },
  { key: "clic2", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "gpr183", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "glyatl1", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "adamtsl1", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "kctd6", filterType: "SecondBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "ate1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "alg13", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "gad1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "clec2b", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "lclat1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "h3c15", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "rorc", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "flot1", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "plin5", filterType: "FailedAllFilters", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "dnttip2", filterType: "FailedAllFilters", aaLeft: "411", aaRight: "435", ntLeft: "1231", ntRight: "1305" },
  { key: "cox7a2l", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "cadm4", filterType: "SecondBest", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "uts2", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "mfap1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "tyw1b", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "igdcc3", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "wnt9a", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "ghdc", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "pcbp2", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "sema6b", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "bcl2l11", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "sp140", filterType: "FailedAllFilters", aaLeft: "797", aaRight: "821", ntLeft: "2389", ntRight: "2463" },
  { key: "mmp16", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "tmem132c", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "cavin3", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "mrpl37", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "c3orf56", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "rad54b", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "abcc5", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "or2t29", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "lmf1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "comp", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "ulk1", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "hspb3", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "dguok", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "tpbg", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "trmu", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "prps2", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "iqub", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "mapre2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "fgf1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "lrrc14b", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "dus4l", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "fcer1g", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "acp2", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "cables2", filterType: "BestCase", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "akap8l", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "chst15", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "smg7", filterType: "BestCase", aaLeft: "997", aaRight: "1021", ntLeft: "2989", ntRight: "3063" },
  { key: "gemin2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "pla2g2a", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "s100p", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "rab3d", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "prmt2", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "or4d11", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "hydin", filterType: "BestCase", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "hsdl2", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "eda2r", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "sp1", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "stmn3", filterType: "SecondBest", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "rhbdd1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "card8", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "vars2", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "atrn", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "tas2r4", filterType: "FailedAllFilters", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "hspa4", filterType: "BestCase", aaLeft: "813", aaRight: "837", ntLeft: "2437", ntRight: "2511" },
  { key: "tubgcp5", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "cox4i2", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "g3bp2", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "slc51b", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pts", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "vamp8", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ap5m1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "lap3", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "bmpr2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zfp57", filterType: "FailedAllFilters", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "amn", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "cst4", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "astl", filterType: "BestCase", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "lcmt1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "pramef5", filterType: "FailedAllFilters", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "hmox2", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mmp21", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "igf2bp1", filterType: "SecondBest", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "ccnf", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "atp12a", filterType: "BestCase", aaLeft: "531", aaRight: "555", ntLeft: "1591", ntRight: "1665" },
  { key: "ca5b", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "ing5", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "slc14a1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "aifm1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "mt1f", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tmem238l", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "pgpep1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "h2bw2", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "afap1l2", filterType: "BestCase", aaLeft: "704", aaRight: "728", ntLeft: "2110", ntRight: "2184" },
  { key: "eif2b2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "slc41a1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "srd5a1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "rpusd2", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "slc6a6", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "sult4a1", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "sh3bgrl2", filterType: "SecondBest", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "nab1", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "siah2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "gon7", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "runx3", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "stx2", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "pigg", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "nop9", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "c19orf33", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "zfand3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "clpp", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "trmt10b", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "eif5al1", filterType: "FourthBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "kansl1", filterType: "BestCase", aaLeft: "821", aaRight: "845", ntLeft: "2461", ntRight: "2535" },
  { key: "g6pc3", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "tmem156", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "fbxo6", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "rims1", filterType: "BestCase", aaLeft: "1067", aaRight: "1091", ntLeft: "3199", ntRight: "3273" },
  { key: "sorbs1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "atp2a2", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "hsf4", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "tafa3", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "or2i1p", filterType: "FailedAllFilters", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "arhgap24", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tcf23", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "rsl1d1", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "sh2d5", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "papolg", filterType: "BestCase", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "fam76b", filterType: "SecondBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "palm3", filterType: "FailedAllFilters", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "lrrtm4", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "ccdc181", filterType: "FailedAllFilters", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "paf1", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "soga1", filterType: "BestCase", aaLeft: "1575", aaRight: "1599", ntLeft: "4723", ntRight: "4797" },
  { key: "evl", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "vbp1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "rhoh", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "hepacam", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "gimap5", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "tpsd1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "dlg4", filterType: "SecondBest", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "gab3", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "glrx2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "kat14", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "mrpl9", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "hid1", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "krt28", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "aplp1", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "gps1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "icos", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "adck2", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "arsj", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "pfdn5", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "mt2a", filterType: "SecondBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "polr2j2", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "hdgfl2", filterType: "BestCase", aaLeft: "646", aaRight: "670", ntLeft: "1936", ntRight: "2010" },
  { key: "egr2", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "b9d1", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "rab6a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fzd4", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "or2l13", filterType: "ThirdBest", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "wdr1", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "fancd2os", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "prok1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "pabpc3", filterType: "ThirdBest", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "fam214a", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "gclm", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "prdx6", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "znf836", filterType: "FailedAllFilters", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "pim3", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "hyls1", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "hspd1", filterType: "BestCase", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "mrpl55", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "h3-3b", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "coro1c", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "ccdc88c", filterType: "BestCase", aaLeft: "1949", aaRight: "1973", ntLeft: "5845", ntRight: "5919" },
  { key: "acot13", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ptprf", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "stom", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "pex6", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "rnf167", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tlk2", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "emc6", filterType: "SecondBest", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "glyatl1b", filterType: "FailedAllFilters", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "top3a", filterType: "BestCase", aaLeft: "951", aaRight: "975", ntLeft: "2851", ntRight: "2925" },
  { key: "sdr16c5", filterType: "FailedAllFilters", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "arg2", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "pnma6a", filterType: "FailedAllFilters", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "entr1", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "ntn3", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "vwa8", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "myh3", filterType: "BestCase", aaLeft: "911", aaRight: "935", ntLeft: "2731", ntRight: "2805" },
  { key: "igfbp5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tmem208", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ovol3", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "reg3g", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "sema3g", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "spag1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "btn1a1", filterType: "BestCase", aaLeft: "304", aaRight: "328", ntLeft: "910", ntRight: "984" },
  { key: "hbm", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "or4c11", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "atp5po", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "myo5b", filterType: "BestCase", aaLeft: "817", aaRight: "841", ntLeft: "2449", ntRight: "2523" },
  { key: "creld2", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "uba7", filterType: "BestCase", aaLeft: "746", aaRight: "770", ntLeft: "2236", ntRight: "2310" },
  { key: "rnf150", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "pin4", filterType: "FourthBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "usp5", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "pcyt1a", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "chpf2", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "hoxb8", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "rinl", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "cdnf", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "sox4", filterType: "FailedAllFilters", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "nutm2a", filterType: "FailedAllFilters", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "asxl1", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "tgif2ly", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "tufm", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ap2m1", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "rb1cc1", filterType: "BestCase", aaLeft: "1020", aaRight: "1044", ntLeft: "3058", ntRight: "3132" },
  { key: "zbtb3", filterType: "BestCase", aaLeft: "328", aaRight: "352", ntLeft: "982", ntRight: "1056" },
  { key: "ctage9", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "tmprss6", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "dusp5", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "rac2", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "ptprz1", filterType: "BestCase", aaLeft: "1571", aaRight: "1595", ntLeft: "4711", ntRight: "4785" },
  { key: "ooep", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "c6orf163", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "slc24a4", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "rlbp1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "usb1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tomm34", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "snx29", filterType: "BestCase", aaLeft: "766", aaRight: "790", ntLeft: "2296", ntRight: "2370" },
  { key: "rtl10", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "dpp3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "rgs16", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "dynlrb2", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "mettl8", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "c18orf25", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "cenpx", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "cyp26a1", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "krtap6-1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "qrfp", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "znf35", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "lage3", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "slc6a11", filterType: "BestCase", aaLeft: "569", aaRight: "593", ntLeft: "1705", ntRight: "1779" },
  { key: "znf470", filterType: "FailedAllFilters", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "arv1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gins1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "poli", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "tex101", filterType: "FailedAllFilters", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "abhd4", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "ggt6", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "galp", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "hdac5", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "psme2", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "prdm12", filterType: "SecondBest", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "adamdec1", filterType: "FailedAllFilters", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "bloc1s4", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "micb", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "kmt5b", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "ints2", filterType: "BestCase", aaLeft: "947", aaRight: "971", ntLeft: "2839", ntRight: "2913" },
  { key: "tas2r42", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tmem185b", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "mpp1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "cpsf6", filterType: "SecondBest", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "gxylt1", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "glg1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "fbn3", filterType: "BestCase", aaLeft: "1326", aaRight: "1350", ntLeft: "3976", ntRight: "4050" },
  { key: "gsto1", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "tmem41b", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "atp8b2", filterType: "BestCase", aaLeft: "1159", aaRight: "1183", ntLeft: "3475", ntRight: "3549" },
  { key: "stx17", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "rgs21", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tubb4b", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "chrfam7a", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "l3mbtl1", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "nectin4", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ripply2", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "or2a7", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "dtnbp1", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "adgrl2", filterType: "BestCase", aaLeft: "1243", aaRight: "1267", ntLeft: "3727", ntRight: "3801" },
  { key: "svop", filterType: "SecondBest", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "pkm", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "dysf", filterType: "BestCase", aaLeft: "1753", aaRight: "1777", ntLeft: "5257", ntRight: "5331" },
  { key: "slirp", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "c4orf3", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "adcy6", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "neurl2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "slc35f1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "rbm27", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "rimoc1", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "nwd2", filterType: "FailedAllFilters", aaLeft: "1709", aaRight: "1733", ntLeft: "5125", ntRight: "5199" },
  { key: "limch1", filterType: "FailedAllFilters", aaLeft: "886", aaRight: "910", ntLeft: "2656", ntRight: "2730" },
  { key: "eif2s1", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "psmb11", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "sfxn1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "msh4", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "trak2", filterType: "BestCase", aaLeft: "840", aaRight: "864", ntLeft: "2518", ntRight: "2592" },
  { key: "tbl1xr1", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "wdr11", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "znf564", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "arl15", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "slamf6", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "prkg2", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "ifnar1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nlgn4y", filterType: "BestCase", aaLeft: "725", aaRight: "749", ntLeft: "2173", ntRight: "2247" },
  { key: "epha6", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "anp32d", filterType: "FourthBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "tmem178b", filterType: "SecondBest", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "ubald2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "cnpy4", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "crip3", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "potef", filterType: "FailedAllFilters", aaLeft: "1017", aaRight: "1041", ntLeft: "3049", ntRight: "3123" },
  { key: "pgls", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "aqp8", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "fcgr1a", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "dprx", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "slc25a45", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "magec2", filterType: "FailedAllFilters", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "snrpe", filterType: "FourthBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "mtm1", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "fam161a", filterType: "FailedAllFilters", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "dnajc5b", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "grhl3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "ccar1", filterType: "BestCase", aaLeft: "821", aaRight: "845", ntLeft: "2461", ntRight: "2535" },
  { key: "garin1a", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "lsm2", filterType: "SecondBest", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ereg", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "mapk8ip3", filterType: "BestCase", aaLeft: "929", aaRight: "953", ntLeft: "2785", ntRight: "2859" },
  { key: "klhl40", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "vsig10l2", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "rps4x", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ephb1", filterType: "BestCase", aaLeft: "634", aaRight: "658", ntLeft: "1900", ntRight: "1974" },
  { key: "entpd8", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "tmem19", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "hint2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "gna15", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "tmx2", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "elp6", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "foxd2", filterType: "FailedAllFilters", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "cd244", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "wdr26", filterType: "SecondBest", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "c1qb", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "or6c75", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "c2orf15", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "rtl8c", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ccdc149", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "plekhh1", filterType: "BestCase", aaLeft: "1286", aaRight: "1310", ntLeft: "3856", ntRight: "3930" },
  { key: "nxpe1", filterType: "BestCase", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "kcnq2", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "rbm10", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "josd2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "emsy", filterType: "BestCase", aaLeft: "1230", aaRight: "1254", ntLeft: "3688", ntRight: "3762" },
  { key: "shisa3", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "aatf", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "hemgn", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "irgm", filterType: "FailedAllFilters", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "crb1", filterType: "FailedAllFilters", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "stxbp2", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "arhgef39", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "mrfap1", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "dcaf10", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "fxyd1", filterType: "FourthBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tbc1d13", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "triml1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "mbp", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "btf3", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "rfc2", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "znf57", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "bcap29", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "arhgap6", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "swt1", filterType: "BestCase", aaLeft: "637", aaRight: "661", ntLeft: "1909", ntRight: "1983" },
  { key: "fcrl5", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "ppil1", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "smim3", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "mefv", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "cyp2d6", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "yjefn3", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "spidr", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "efnb1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "dcn", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "trappc3", filterType: "SecondBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "sgsh", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "vps26b", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "hla-g", filterType: "ThirdBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "taf12", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "or2d2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "krtap1-1", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "gpcpd1", filterType: "BestCase", aaLeft: "646", aaRight: "670", ntLeft: "1936", ntRight: "2010" },
  { key: "tnfrsf10d", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "nf2", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "sergef", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "ppia", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "ercc4", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "nfxl1", filterType: "BestCase", aaLeft: "550", aaRight: "574", ntLeft: "1648", ntRight: "1722" },
  { key: "mepe", filterType: "FailedAllFilters", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "vsir", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "ildr1", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "c8orf74", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "ntpcr", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "nek7", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ankrd13b", filterType: "BestCase", aaLeft: "562", aaRight: "586", ntLeft: "1684", ntRight: "1758" },
  { key: "tnfrsf13c", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "rrp8", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "znf347", filterType: "FailedAllFilters", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "reep3", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "b3gat3", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "cdrt4", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "c6orf136", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "nfatc2ip", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "golga5", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "dok7", filterType: "BestCase", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "znf658", filterType: "FailedAllFilters", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "atr", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "grik5", filterType: "BestCase", aaLeft: "900", aaRight: "924", ntLeft: "2698", ntRight: "2772" },
  { key: "eif4ebp2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cdkn2b", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "tmtc3", filterType: "BestCase", aaLeft: "874", aaRight: "898", ntLeft: "2620", ntRight: "2694" },
  { key: "cd74", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "ier5l", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "tas2r20", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "cops7a", filterType: "SecondBest", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "eri1", filterType: "FailedAllFilters", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "h1-1", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "znf425", filterType: "FailedAllFilters", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "plppr2", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "fbh1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "rps4y1", filterType: "ThirdBest", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "il31ra", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "tirap", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "birc2", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "cct6b", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "zpr1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ifit3", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "etdc", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "nanognb", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "usp35", filterType: "BestCase", aaLeft: "736", aaRight: "760", ntLeft: "2206", ntRight: "2280" },
  { key: "alkbh3", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "tbc1d24", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "cab39l", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "znf264", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "ppp1r11", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "epop", filterType: "FailedAllFilters", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "slc25a10", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "cyb561d2", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "nudt2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "tnc", filterType: "BestCase", aaLeft: "1151", aaRight: "1175", ntLeft: "3451", ntRight: "3525" },
  { key: "fam228b", filterType: "FailedAllFilters", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "oas1", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "sirt7", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "thap2", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "klhl15", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "micu3", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "epdr1", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "wdr73", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "znf506", filterType: "FailedAllFilters", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "flna", filterType: "BestCase", aaLeft: "1207", aaRight: "1231", ntLeft: "3619", ntRight: "3693" },
  { key: "znf512b", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "malrd1", filterType: "BestCase", aaLeft: "1572", aaRight: "1596", ntLeft: "4714", ntRight: "4788" },
  { key: "cep120", filterType: "BestCase", aaLeft: "614", aaRight: "638", ntLeft: "1840", ntRight: "1914" },
  { key: "kir2dl3", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "hmgb3", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "wrap53", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "tmem191c", filterType: "FailedAllFilters", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "lct", filterType: "BestCase", aaLeft: "853", aaRight: "877", ntLeft: "2557", ntRight: "2631" },
  { key: "cdh11", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "snap91", filterType: "BestCase", aaLeft: "809", aaRight: "833", ntLeft: "2425", ntRight: "2499" },
  { key: "uhmk1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tdrd7", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "ift74", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "spata45", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "rbm45", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "gpr83", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "zc3h12a", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "ak2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "scgb1c2", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ezhip", filterType: "FailedAllFilters", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "dennd11", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "or2a14", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "ift43", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "c12orf73", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "htra2", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "zscan5a", filterType: "FailedAllFilters", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "aard", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "h2bc4", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "cd226", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "wdr44", filterType: "BestCase", aaLeft: "857", aaRight: "881", ntLeft: "2569", ntRight: "2643" },
  { key: "bpifc", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "ankib1", filterType: "BestCase", aaLeft: "1029", aaRight: "1053", ntLeft: "3085", ntRight: "3159" },
  { key: "nuak1", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "dipk1c", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "virma", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "pkig", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "vps39", filterType: "BestCase", aaLeft: "617", aaRight: "641", ntLeft: "1849", ntRight: "1923" },
  { key: "pbdc1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tpo", filterType: "BestCase", aaLeft: "293", aaRight: "317", ntLeft: "877", ntRight: "951" },
  { key: "wwox", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "rheb", filterType: "SecondBest", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "cdh26", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "snapin", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fam111a", filterType: "FailedAllFilters", aaLeft: "561", aaRight: "585", ntLeft: "1681", ntRight: "1755" },
  { key: "dmxl2", filterType: "BestCase", aaLeft: "1932", aaRight: "1956", ntLeft: "5794", ntRight: "5868" },
  { key: "spag11b", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "epyc", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "wnt4", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "rin2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "krt82", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "klhl22", filterType: "BestCase", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "rsph6a", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "serbp1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "uggt2", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "znf253", filterType: "FailedAllFilters", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "ppp1r26", filterType: "FailedAllFilters", aaLeft: "968", aaRight: "992", ntLeft: "2902", ntRight: "2976" },
  { key: "slc25a27", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "or52w1", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "agbl1", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "vopp1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "lratd2", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "adrm1", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "nipal1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "wdr55", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "cdr2", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "foxc1", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "far2", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "c2orf66", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "vps28", filterType: "SecondBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "nt5c2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cdkl2", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "plek2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "c10orf105", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "kcnv1", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "plekho2", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "ociad1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "dele1", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "jam3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "n6amt1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "ift52", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "ltf", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "coa5", filterType: "FourthBest", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "defb119", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "cbwd3", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "ass1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "tulp4", filterType: "BestCase", aaLeft: "1006", aaRight: "1030", ntLeft: "3016", ntRight: "3090" },
  { key: "rrp7a", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "pigt", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "exosc3", filterType: "SecondBest", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "slc25a36", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "maged1", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "ppp1r37", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "luzp4", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "angptl2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "fam166b", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "slc18a3", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mcts2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "amy2a", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "nox5", filterType: "FailedAllFilters", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "klhl33", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "sowahc", filterType: "FailedAllFilters", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "srrm2", filterType: "BestCase", aaLeft: "2482", aaRight: "2506", ntLeft: "7444", ntRight: "7518" },
  { key: "cox7b2", filterType: "FourthBest", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "chrnb3", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "sostdc1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "gcm2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "tex53", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "lyzl2", filterType: "FourthBest", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "npffr1", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "cmtr1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ptpn2", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "prrc2b", filterType: "BestCase", aaLeft: "989", aaRight: "1013", ntLeft: "2965", ntRight: "3039" },
  { key: "chrna4", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "dhps", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "znf275", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "gjd3", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "fbxl18", filterType: "BestCase", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "npl", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "eps8l2", filterType: "BestCase", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "ica1l", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "ostf1", filterType: "SecondBest", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "slc35a2", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "lamp3", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "dcdc1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "smad7", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "samd9l", filterType: "BestCase", aaLeft: "1516", aaRight: "1540", ntLeft: "4546", ntRight: "4620" },
  { key: "susd2", filterType: "BestCase", aaLeft: "729", aaRight: "753", ntLeft: "2185", ntRight: "2259" },
  { key: "cyp4b1", filterType: "FailedAllFilters", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "creb5", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "zcchc10", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "wdr62", filterType: "BestCase", aaLeft: "1301", aaRight: "1325", ntLeft: "3901", ntRight: "3975" },
  { key: "znf517", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "cd200", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "slc22a1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "pvrig", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "taar6", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "nat1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "notch3", filterType: "BestCase", aaLeft: "976", aaRight: "1000", ntLeft: "2926", ntRight: "3000" },
  { key: "cep131", filterType: "BestCase", aaLeft: "978", aaRight: "1002", ntLeft: "2932", ntRight: "3006" },
  { key: "aipl1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "prdm1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "tomm70", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ppfia4", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "zdhhc17", filterType: "SecondBest", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "znf649", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "myom1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "f5", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "il18", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "hectd1", filterType: "BestCase", aaLeft: "1956", aaRight: "1980", ntLeft: "5866", ntRight: "5940" },
  { key: "pln", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "rps10", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "gng10", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "wscd2", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "faap100", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "upk3bl2", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "psmb1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "itgb7", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "surf1", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "tgm2", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "ptpru", filterType: "BestCase", aaLeft: "422", aaRight: "446", ntLeft: "1264", ntRight: "1338" },
  { key: "fzd10", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "cfap157", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "mgrn1", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "kmt2a", filterType: "BestCase", aaLeft: "3489", aaRight: "3513", ntLeft: "10465", ntRight: "10539" },
  { key: "usp24", filterType: "BestCase", aaLeft: "2300", aaRight: "2324", ntLeft: "6898", ntRight: "6972" },
  { key: "bbs5", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tm4sf1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "cers4", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "nutf2", filterType: "SecondBest", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "fbxo46", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "dera", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "znf71", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "nmrk1", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mkks", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "tas2r31", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "depdc1", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "tsacc", filterType: "FourthBest", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "cpne2", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "sftpa1", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "btg2", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "cd52", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "mmp2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "itga11", filterType: "BestCase", aaLeft: "967", aaRight: "991", ntLeft: "2899", ntRight: "2973" },
  { key: "or5c1", filterType: "FailedAllFilters", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "map3k12", filterType: "BestCase", aaLeft: "845", aaRight: "869", ntLeft: "2533", ntRight: "2607" },
  { key: "sh3rf3", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "ube2d4", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "ripor3", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "cyp2c19", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "psma6", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "cst11", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mzt2b", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "oosp3", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "rhd", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "usp13", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "tet3", filterType: "BestCase", aaLeft: "582", aaRight: "606", ntLeft: "1744", ntRight: "1818" },
  { key: "actr1b", filterType: "SecondBest", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "rptor", filterType: "BestCase", aaLeft: "890", aaRight: "914", ntLeft: "2668", ntRight: "2742" },
  { key: "rad1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "ms4a6a", filterType: "FailedAllFilters", aaLeft: "187", aaRight: "211", ntLeft: "559", ntRight: "633" },
  { key: "irak4", filterType: "BestCase", aaLeft: "409", aaRight: "433", ntLeft: "1225", ntRight: "1299" },
  { key: "fam174a", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ctag1b", filterType: "FailedAllFilters", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "rps27", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "fcho1", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "rimbp3", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "psmd14", filterType: "SecondBest", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "pdpr", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "ct47a5", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "spry2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dazap2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ilkap", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "zdhhc20", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "fndc4", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "tbr1", filterType: "SecondBest", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "pdap1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "camk1g", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "batf3", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "adora2a", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "ubtd1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "cxcr5", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "smarca1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "mrap2", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "or10p1", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "gar1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "terf2", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "mageb3", filterType: "FailedAllFilters", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "neto1", filterType: "BestCase", aaLeft: "446", aaRight: "470", ntLeft: "1336", ntRight: "1410" },
  { key: "ccr2", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "dchs1", filterType: "BestCase", aaLeft: "991", aaRight: "1015", ntLeft: "2971", ntRight: "3045" },
  { key: "psme3ip1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "or3a2", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "vps33b", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "ccdc158", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "jak1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "uqcrc1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "tdp2", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "mob3b", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "siglec7", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "ncoa2", filterType: "BestCase", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "scgb1d1", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ccdc18", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "tspan12", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "vat1l", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "arhgef28", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "papss2", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "cog7", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "foxe3", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "ucp1", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "argfx", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "cep164", filterType: "BestCase", aaLeft: "705", aaRight: "729", ntLeft: "2113", ntRight: "2187" },
  { key: "itpr1", filterType: "BestCase", aaLeft: "1898", aaRight: "1922", ntLeft: "5692", ntRight: "5766" },
  { key: "wdr37", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "gpc3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "opalin", filterType: "FailedAllFilters", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "cox11", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "nck2", filterType: "BestCase", aaLeft: "254", aaRight: "278", ntLeft: "760", ntRight: "834" },
  { key: "gtf2ird2", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "lrrc19", filterType: "FailedAllFilters", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "znf575", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "arl14ep", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "cxcl9", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ankdd1a", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "ttk", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "xrcc2", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "exoc1", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "tbc1d3", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "iqcf6", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "carmil2", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "sh3gl3", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "prune1", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "lgals7b", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "zfyve19", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "spink13", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "pla2g5", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "npas1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "magea4", filterType: "FailedAllFilters", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "ano8", filterType: "BestCase", aaLeft: "993", aaRight: "1017", ntLeft: "2977", ntRight: "3051" },
  { key: "ttpal", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "hhip", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "gjb1", filterType: "SecondBest", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "znf217", filterType: "FailedAllFilters", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "angel1", filterType: "BestCase", aaLeft: "543", aaRight: "567", ntLeft: "1627", ntRight: "1701" },
  { key: "cgrrf1", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "slc25a1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ndufs8", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "sri", filterType: "SecondBest", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ptov1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "keap1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "znf708", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "npc1", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "prss58", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "nampt", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "rtn1", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "ern2", filterType: "BestCase", aaLeft: "805", aaRight: "829", ntLeft: "2413", ntRight: "2487" },
  { key: "gnat3", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "tmem184c", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "gsx1", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "chka", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "krtap12-2", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "emp3", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ddx18", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "id4", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "apcdd1", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "psmd3", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "magi3", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "chmp4a", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "cxorf49b", filterType: "FailedAllFilters", aaLeft: "399", aaRight: "423", ntLeft: "1195", ntRight: "1269" },
  { key: "fbxo24", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "nrep", filterType: "FourthBest", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "usp46", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "fus", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "pilra", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "pcdha9", filterType: "FailedAllFilters", aaLeft: "320", aaRight: "344", ntLeft: "958", ntRight: "1032" },
  { key: "adpgk", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "crtam", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "haus6", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "dhx32", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "znf334", filterType: "FailedAllFilters", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "sf1", filterType: "BestCase", aaLeft: "548", aaRight: "572", ntLeft: "1642", ntRight: "1716" },
  { key: "tgoln2", filterType: "FailedAllFilters", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "kbtbd7", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "sap130", filterType: "BestCase", aaLeft: "692", aaRight: "716", ntLeft: "2074", ntRight: "2148" },
  { key: "stac", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dhrs7b", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "mettl7b", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "vstm2b", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "msantd4", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "mnda", filterType: "FailedAllFilters", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "rxrg", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "med18", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "pramef13", filterType: "FailedAllFilters", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "c6orf62", filterType: "SecondBest", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "eaf1", filterType: "FailedAllFilters", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "mtus1", filterType: "FailedAllFilters", aaLeft: "1052", aaRight: "1076", ntLeft: "3154", ntRight: "3228" },
  { key: "nptn", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "ncbp2as2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "hells", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "medag", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "or2c1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "ttc3", filterType: "BestCase", aaLeft: "1715", aaRight: "1739", ntLeft: "5143", ntRight: "5217" },
  { key: "rraga", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "mgat4a", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "itih1", filterType: "BestCase", aaLeft: "785", aaRight: "809", ntLeft: "2353", ntRight: "2427" },
  { key: "csdc2", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "ptafr", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "klf11", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "cdca2", filterType: "FailedAllFilters", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "wdfy1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "psmg4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "brpf3", filterType: "BestCase", aaLeft: "946", aaRight: "970", ntLeft: "2836", ntRight: "2910" },
  { key: "ct47a10", filterType: "FailedAllFilters", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "fermt1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "gid8", filterType: "SecondBest", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "fam104a", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "znf24", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "krt6b", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "ubr2", filterType: "BestCase", aaLeft: "1242", aaRight: "1266", ntLeft: "3724", ntRight: "3798" },
  { key: "cdhr5", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "spaca7", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "znf461", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "snrpd2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ggtlc3", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "msln", filterType: "FailedAllFilters", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "lrrc4b", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "agfg1", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "impact", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tns2", filterType: "BestCase", aaLeft: "875", aaRight: "899", ntLeft: "2623", ntRight: "2697" },
  { key: "camkk2", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "nop56", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "sprr2e", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "prkch", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "plpp1", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "il10rb", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "abcg1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rnf141", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "dcaf8l2", filterType: "FailedAllFilters", aaLeft: "299", aaRight: "323", ntLeft: "895", ntRight: "969" },
  { key: "tfap2a", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "il6st", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "sumo1", filterType: "SecondBest", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tmem219", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "pls1", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "tigd2", filterType: "BestCase", aaLeft: "457", aaRight: "481", ntLeft: "1369", ntRight: "1443" },
  { key: "slc38a5", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "znf181", filterType: "FailedAllFilters", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "vdac1", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "trim9", filterType: "BestCase", aaLeft: "739", aaRight: "763", ntLeft: "2215", ntRight: "2289" },
  { key: "frg2c", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "deaf1", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "secisbp2l", filterType: "BestCase", aaLeft: "910", aaRight: "934", ntLeft: "2728", ntRight: "2802" },
  { key: "pcdhga8", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "smc3", filterType: "SecondBest", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "ppp4r4", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "eif4g2", filterType: "BestCase", aaLeft: "855", aaRight: "879", ntLeft: "2563", ntRight: "2637" },
  { key: "ptk2", filterType: "BestCase", aaLeft: "805", aaRight: "829", ntLeft: "2413", ntRight: "2487" },
  { key: "cdrt15l2", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "lrig2", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "abr", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "hoxc9", filterType: "SecondBest", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "tmed1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "gatm", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "rreb1", filterType: "BestCase", aaLeft: "943", aaRight: "967", ntLeft: "2827", ntRight: "2901" },
  { key: "picalm", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "pold4", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "cyp17a1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "znf827", filterType: "BestCase", aaLeft: "623", aaRight: "647", ntLeft: "1867", ntRight: "1941" },
  { key: "foxred1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "slc22a18", filterType: "BestCase", aaLeft: "386", aaRight: "410", ntLeft: "1156", ntRight: "1230" },
  { key: "pik3ip1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ensa", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "tceanc", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "cfap92", filterType: "FailedAllFilters", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "ankfy1", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "megf11", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "thap10", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "wipf2", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "hsd3b1", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "ldlrad2", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "dnajc18", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "gpatch2", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "llgl1", filterType: "BestCase", aaLeft: "985", aaRight: "1009", ntLeft: "2953", ntRight: "3027" },
  { key: "tppp", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "arf3", filterType: "SecondBest", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cpeb3", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "fancd2", filterType: "BestCase", aaLeft: "967", aaRight: "991", ntLeft: "2899", ntRight: "2973" },
  { key: "clcnkb", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "cdcp1", filterType: "BestCase", aaLeft: "659", aaRight: "683", ntLeft: "1975", ntRight: "2049" },
  { key: "klk5", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "reg1a", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "cobl", filterType: "BestCase", aaLeft: "444", aaRight: "468", ntLeft: "1330", ntRight: "1404" },
  { key: "sec31b", filterType: "BestCase", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "slc15a4", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "arel1", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "asph", filterType: "BestCase", aaLeft: "584", aaRight: "608", ntLeft: "1750", ntRight: "1824" },
  { key: "mrpl44", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "lcn1", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "fam13b", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "hmgcl", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tmem87b", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "slc7a3", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "sprr1b", filterType: "FourthBest", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "adamts19", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "tmem151a", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "c2cd4b", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "igf1r", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "prkar2a", filterType: "BestCase", aaLeft: "296", aaRight: "320", ntLeft: "886", ntRight: "960" },
  { key: "nr1d1", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "lman1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "pepd", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "vezf1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "clec5a", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "unc80", filterType: "BestCase", aaLeft: "3191", aaRight: "3215", ntLeft: "9571", ntRight: "9645" },
  { key: "or10a2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "ccdc74b", filterType: "FailedAllFilters", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "galnt6", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "chek1", filterType: "FailedAllFilters", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "or52a1", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "elovl1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "riok1", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "arhgap35", filterType: "BestCase", aaLeft: "1435", aaRight: "1459", ntLeft: "4303", ntRight: "4377" },
  { key: "snx1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "h4c2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tspan1", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "ccdc190", filterType: "FailedAllFilters", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "apex1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "sec23a", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "dnaaf8", filterType: "FailedAllFilters", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "brinp1", filterType: "SecondBest", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "smap1", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "fnbp4", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "fhl2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "serpinb13", filterType: "FailedAllFilters", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "lima1", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "zranb2", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "tbc1d3k", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "col6a3", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "krt39", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "ccl25", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "hnrnpll", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "orm2", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "arpc4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ireb2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "bpifa1", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "cd5l", filterType: "FailedAllFilters", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "xg", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "samd9", filterType: "BestCase", aaLeft: "1486", aaRight: "1510", ntLeft: "4456", ntRight: "4530" },
  { key: "bpifb6", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "grk1", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "ddost", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "uap1l1", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "lyst", filterType: "FailedAllFilters", aaLeft: "2180", aaRight: "2204", ntLeft: "6538", ntRight: "6612" },
  { key: "tent5b", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "calb2", filterType: "SecondBest", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "hook1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "hrob", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "hhex", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "eprs1", filterType: "BestCase", aaLeft: "879", aaRight: "903", ntLeft: "2635", ntRight: "2709" },
  { key: "tmem147", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "rnf38", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "yars2", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "nfe2", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "pde4c", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "or52b6", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "flrt2", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "fsd1l", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "boc", filterType: "BestCase", aaLeft: "1083", aaRight: "1107", ntLeft: "3247", ntRight: "3321" },
  { key: "g0s2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "nol3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "galnt10", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "gtf2h5", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "hes2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "npr2", filterType: "BestCase", aaLeft: "672", aaRight: "696", ntLeft: "2014", ntRight: "2088" },
  { key: "mettl25b", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "zmym2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "plag1", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "mcm5", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "ech1", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "cebpz", filterType: "BestCase", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "crebl2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "mtln", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ckb", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "rbfox3", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "tm4sf18", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "slain1", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "il17rb", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "pmch", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "cenpk", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "or2ae1", filterType: "FailedAllFilters", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "gzf1", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "edn3", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "nkx3-1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "sema4d", filterType: "BestCase", aaLeft: "515", aaRight: "539", ntLeft: "1543", ntRight: "1617" },
  { key: "irf2", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "clk4", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "lhx3", filterType: "BestCase", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "fundc2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "rnase4", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "arrdc5", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "znf681", filterType: "FailedAllFilters", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "c3orf70", filterType: "FailedAllFilters", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "dynlt3", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "mrpl11", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "rnf123", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "abca3", filterType: "BestCase", aaLeft: "1319", aaRight: "1343", ntLeft: "3955", ntRight: "4029" },
  { key: "lhfpl6", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "ttc39b", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "polr1f", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "lacrt", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "myo1f", filterType: "BestCase", aaLeft: "975", aaRight: "999", ntLeft: "2923", ntRight: "2997" },
  { key: "daw1", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "cep290", filterType: "BestCase", aaLeft: "1212", aaRight: "1236", ntLeft: "3634", ntRight: "3708" },
  { key: "galc", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "rnf114", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "tll1", filterType: "BestCase", aaLeft: "524", aaRight: "548", ntLeft: "1570", ntRight: "1644" },
  { key: "abcb4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tfcp2l1", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "polr2a", filterType: "BestCase", aaLeft: "1842", aaRight: "1866", ntLeft: "5524", ntRight: "5598" },
  { key: "mlst8", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "cped1", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "atp6v0c", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "gga1", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "myl7", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "atp5f1c", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "zcchc4", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "kcnip2", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "fau", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "phldb2", filterType: "BestCase", aaLeft: "509", aaRight: "533", ntLeft: "1525", ntRight: "1599" },
  { key: "man2a1", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "rab1b", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "rad23a", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "arc", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "ttyh3", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "ifna4", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "arid5b", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "mdga1", filterType: "BestCase", aaLeft: "929", aaRight: "953", ntLeft: "2785", ntRight: "2859" },
  { key: "znf845", filterType: "FailedAllFilters", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "cntnap1", filterType: "BestCase", aaLeft: "1323", aaRight: "1347", ntLeft: "3967", ntRight: "4041" },
  { key: "colq", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "lta4h", filterType: "FailedAllFilters", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "dpf3", filterType: "SecondBest", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "slc12a4", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "tpra1", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "caap1", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "pola1", filterType: "BestCase", aaLeft: "722", aaRight: "746", ntLeft: "2164", ntRight: "2238" },
  { key: "necap2", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "foxr2", filterType: "FailedAllFilters", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "nepro", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "gpt2", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "pcdh9", filterType: "BestCase", aaLeft: "1021", aaRight: "1045", ntLeft: "3061", ntRight: "3135" },
  { key: "maml1", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "hic2", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "ago4", filterType: "BestCase", aaLeft: "608", aaRight: "632", ntLeft: "1822", ntRight: "1896" },
  { key: "h3c2", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "kdelr3", filterType: "SecondBest", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "elmod3", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "six1", filterType: "SecondBest", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "serpinb8", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "rab11fip2", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "nprl3", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "mpdz", filterType: "BestCase", aaLeft: "1554", aaRight: "1578", ntLeft: "4660", ntRight: "4734" },
  { key: "kcng2", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "pon1", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "raver2", filterType: "BestCase", aaLeft: "603", aaRight: "627", ntLeft: "1807", ntRight: "1881" },
  { key: "dtx4", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "asb2", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "ca6", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "vasp", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "tp53i13", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "pecr", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "axin2", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "rbm7", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "mif4gd", filterType: "SecondBest", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "lxn", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ppm1l", filterType: "SecondBest", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "prr23a", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "csnk1d", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "fpr3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "timp3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "camlg", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or5b17", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ndufaf7", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "mpv17l", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "erh", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "slc4a8", filterType: "BestCase", aaLeft: "948", aaRight: "972", ntLeft: "2842", ntRight: "2916" },
  { key: "tmem95", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "slco1b3", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "atg7", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "pde2a", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "sytl2", filterType: "FailedAllFilters", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "pcdh18", filterType: "BestCase", aaLeft: "953", aaRight: "977", ntLeft: "2857", ntRight: "2931" },
  { key: "tmem125", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "itprid1", filterType: "FailedAllFilters", aaLeft: "963", aaRight: "987", ntLeft: "2887", ntRight: "2961" },
  { key: "tnfaip8l3", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "guk1", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "dock1", filterType: "BestCase", aaLeft: "1821", aaRight: "1845", ntLeft: "5461", ntRight: "5535" },
  { key: "c1orf146", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "wfdc1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "sdr42e2", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "hapln2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "gpsm2", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "dpys", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "prr19", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "cdc123", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ttbk2", filterType: "BestCase", aaLeft: "862", aaRight: "886", ntLeft: "2584", ntRight: "2658" },
  { key: "smco2", filterType: "FailedAllFilters", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "c11orf80", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "rpl7a", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "mei4", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "casz1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "mapk1", filterType: "SecondBest", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "cfhr3", filterType: "FailedAllFilters", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "tgif2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "pgap4", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dppa3", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "htd2", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ccrl2", filterType: "FailedAllFilters", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "trim49b", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "nucb2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "vsnl1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "hand1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "grm3", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "ube2m", filterType: "SecondBest", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "chd1l", filterType: "BestCase", aaLeft: "686", aaRight: "710", ntLeft: "2056", ntRight: "2130" },
  { key: "exog", filterType: "BestCase", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "znf100", filterType: "FailedAllFilters", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "lyg2", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "il22ra1", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "gbp1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "cylc1", filterType: "FailedAllFilters", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "mcoln1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "smagp", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ddx51", filterType: "BestCase", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "znf891", filterType: "FailedAllFilters", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "zbtb20", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "mtmr10", filterType: "FailedAllFilters", aaLeft: "626", aaRight: "650", ntLeft: "1876", ntRight: "1950" },
  { key: "ranbp6", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "per1", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "spdye3", filterType: "FailedAllFilters", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "map2k2", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "trio", filterType: "BestCase", aaLeft: "2379", aaRight: "2403", ntLeft: "7135", ntRight: "7209" },
  { key: "fam124b", filterType: "FailedAllFilters", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "znf662", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "dcc", filterType: "BestCase", aaLeft: "1298", aaRight: "1322", ntLeft: "3892", ntRight: "3966" },
  { key: "il33", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "trappc13", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "stat4", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "ddah1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "or2ak2", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "cpxm2", filterType: "BestCase", aaLeft: "385", aaRight: "409", ntLeft: "1153", ntRight: "1227" },
  { key: "smim27", filterType: "FailedAllFilters", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "st3gal1", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "klc4", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "samd13", filterType: "SecondBest", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "smim10", filterType: "FourthBest", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "zfp92", filterType: "FailedAllFilters", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "zbtb17", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "ccng2", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "epn1", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "pusl1", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "abat", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "apoa4", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "eef1akmt3", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "znf655", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "p2ry4", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "gtpbp4", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "arglu1", filterType: "SecondBest", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "pf4v1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "krtap10-1", filterType: "FailedAllFilters", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "sox10", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "maz", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "c22orf31", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "gfod1", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "tanc1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "anxa2r", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "bzw1", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "znf428", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "nsrp1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "spryd7", filterType: "SecondBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "boll", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "prob1", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "hmgcr", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "gpnmb", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "usp38", filterType: "BestCase", aaLeft: "876", aaRight: "900", ntLeft: "2626", ntRight: "2700" },
  { key: "ankrd34b", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "rpl36", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "ythdc2", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "ceacam19", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "gfm1", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "rarb", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "ccser2", filterType: "BestCase", aaLeft: "791", aaRight: "815", ntLeft: "2371", ntRight: "2445" },
  { key: "c11orf54", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "b4galt2", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "dvl1", filterType: "BestCase", aaLeft: "630", aaRight: "654", ntLeft: "1888", ntRight: "1962" },
  { key: "c20orf204", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "mab21l2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf776", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "meioc", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "tnn", filterType: "BestCase", aaLeft: "482", aaRight: "506", ntLeft: "1444", ntRight: "1518" },
  { key: "gpr171", filterType: "FailedAllFilters", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "cep350", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "trim50", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "c1orf50", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "phf12", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "atxn2", filterType: "BestCase", aaLeft: "661", aaRight: "685", ntLeft: "1981", ntRight: "2055" },
  { key: "klhl18", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "prxl2a", filterType: "FailedAllFilters", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "impdh1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "krtap5-7", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "slc30a1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "plaat1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "c1qbp", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "akt2", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "nlk", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "ptges3", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "slc27a2", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "spata48", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "il36b", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "pitpnm1", filterType: "BestCase", aaLeft: "615", aaRight: "639", ntLeft: "1843", ntRight: "1917" },
  { key: "defb132", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "ccdc51", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "egfl8", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "fahd1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "rbm48", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ccdc126", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "ets1", filterType: "SecondBest", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "vps13a", filterType: "BestCase", aaLeft: "2165", aaRight: "2189", ntLeft: "6493", ntRight: "6567" },
  { key: "ccdc66", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "h2bc9", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "or1a1", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "wdr49", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "xirp1", filterType: "BestCase", aaLeft: "756", aaRight: "780", ntLeft: "2266", ntRight: "2340" },
  { key: "cd300lb", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "cmtm1", filterType: "FailedAllFilters", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "ifitm3", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "dnajc2", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tsc22d4", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "srpk3", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "fdft1", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "nod2", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "gpr146", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "trim67", filterType: "BestCase", aaLeft: "587", aaRight: "611", ntLeft: "1759", ntRight: "1833" },
  { key: "sct", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "tceal6", filterType: "FailedAllFilters", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "krt222", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "fam136a", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "tmc6", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "pdp2", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "kdm4d", filterType: "FailedAllFilters", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "bend6", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "prg2", filterType: "FailedAllFilters", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "fosl1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "poc5", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "lin52", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ank1", filterType: "BestCase", aaLeft: "1659", aaRight: "1683", ntLeft: "4975", ntRight: "5049" },
  { key: "atp6v1g3", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "kcnj6", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "sbf2", filterType: "BestCase", aaLeft: "1667", aaRight: "1691", ntLeft: "4999", ntRight: "5073" },
  { key: "pyroxd1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "dkk1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "rcc1l", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ctage4", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "cul4a", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "dusp8", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "hscb", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "klf1", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "cebpd", filterType: "FailedAllFilters", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "scara5", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "myg1", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "pias4", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "socs3", filterType: "SecondBest", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "or7c2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "cdk17", filterType: "SecondBest", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "fbp2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "zbtb42", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "kif12", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "snx24", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "aff1", filterType: "FailedAllFilters", aaLeft: "898", aaRight: "922", ntLeft: "2692", ntRight: "2766" },
  { key: "gsg1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "rfx6", filterType: "BestCase", aaLeft: "761", aaRight: "785", ntLeft: "2281", ntRight: "2355" },
  { key: "mettl5", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "defb104a", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "vegfa", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "znf600", filterType: "FailedAllFilters", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "c5orf63", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "cfap46", filterType: "BestCase", aaLeft: "1039", aaRight: "1063", ntLeft: "3115", ntRight: "3189" },
  { key: "cenpu", filterType: "FailedAllFilters", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "akap1", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "pcgf1", filterType: "SecondBest", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "dach1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "hpd", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ct45a6", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pcdha11", filterType: "FailedAllFilters", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "c11orf1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cdc42ep3", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "tm9sf3", filterType: "SecondBest", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "en1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "crcp", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "fbl", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "lats1", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "pcsk7", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "hrh2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "pi4k2a", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "lypd4", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "mblac2", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "ctcfl", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "ring1", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "hdac8", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "gmds", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "krtap17-1", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "fbxo38", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "znf155", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "grip1", filterType: "BestCase", aaLeft: "800", aaRight: "824", ntLeft: "2398", ntRight: "2472" },
  { key: "thbs3", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "iho1", filterType: "FailedAllFilters", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "onecut2", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "padi3", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "lmo1", filterType: "SecondBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "cdk20", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "eml4", filterType: "BestCase", aaLeft: "875", aaRight: "899", ntLeft: "2623", ntRight: "2697" },
  { key: "kif25", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "mapkbp1", filterType: "BestCase", aaLeft: "748", aaRight: "772", ntLeft: "2242", ntRight: "2316" },
  { key: "snx13", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "pgm2", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "khdc1l", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "mapk12", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "eya2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "galr2", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "nkain4", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "scarb2", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "mgll", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "tsen2", filterType: "FailedAllFilters", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "mog", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "cntfr", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "etv4", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "serp2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "snn", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "rpp30", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "neurog3", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "ccdc33", filterType: "BestCase", aaLeft: "602", aaRight: "626", ntLeft: "1804", ntRight: "1878" },
  { key: "notum", filterType: "BestCase", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "lpo", filterType: "BestCase", aaLeft: "575", aaRight: "599", ntLeft: "1723", ntRight: "1797" },
  { key: "spred1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "ccm2", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "atl1", filterType: "SecondBest", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "tuba1a", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "spsb3", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "znf569", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "gtf2e1", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "fbln5", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "qrsl1", filterType: "BestCase", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "sf3b5", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "vasn", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "ilk", filterType: "SecondBest", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "znf714", filterType: "FailedAllFilters", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "slc20a2", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "ubxn4", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "lgals8", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "kdm1a", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "orc1", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "trim32", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "h2ac16", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "pla2g15", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "slc37a1", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "mageb18", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "aqp5", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "tdo2", filterType: "FailedAllFilters", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "lilrb4", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "mypn", filterType: "BestCase", aaLeft: "770", aaRight: "794", ntLeft: "2308", ntRight: "2382" },
  { key: "cbfb", filterType: "SecondBest", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "cramp1", filterType: "BestCase", aaLeft: "708", aaRight: "732", ntLeft: "2122", ntRight: "2196" },
  { key: "erp44", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "slc25a48", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "rab37", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "asf1b", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "pdlim5", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "cep70", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "cp", filterType: "BestCase", aaLeft: "845", aaRight: "869", ntLeft: "2533", ntRight: "2607" },
  { key: "garem2", filterType: "BestCase", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "znf723", filterType: "FailedAllFilters", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "entpd5", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "h2ac21", filterType: "SecondBest", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "cdh7", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "dcaf7", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tap1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "nsmce4a", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "lrrc32", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "fhod3", filterType: "BestCase", aaLeft: "952", aaRight: "976", ntLeft: "2854", ntRight: "2928" },
  { key: "pagr1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "zfta", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "fam3c", filterType: "SecondBest", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "dlk1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "niban2", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "plau", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "sf3a2", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "tiparp", filterType: "BestCase", aaLeft: "419", aaRight: "443", ntLeft: "1255", ntRight: "1329" },
  { key: "notch2nla", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "ankle1", filterType: "BestCase", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "nin", filterType: "BestCase", aaLeft: "1602", aaRight: "1626", ntLeft: "4804", ntRight: "4878" },
  { key: "ptp4a3", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "tcea3", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "me3", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "pdia6", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "neurod4", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "aatk", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "tnfaip1", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "pcp2", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "hm13", filterType: "SecondBest", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "ube2s", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "lalba", filterType: "FailedAllFilters", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "cyp4f8", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "ccn5", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "tshz3", filterType: "BestCase", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "fam120aos", filterType: "FailedAllFilters", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "krcc1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "phf21b", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "golga8r", filterType: "FailedAllFilters", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "serpinf1", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "pals2", filterType: "SecondBest", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "anapc16", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "loc647264", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "islr2", filterType: "BestCase", aaLeft: "665", aaRight: "689", ntLeft: "1993", ntRight: "2067" },
  { key: "trpv5", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "krt12", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "s100a14", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "ppp1r16a", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "agtr1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "dsg4", filterType: "BestCase", aaLeft: "894", aaRight: "918", ntLeft: "2680", ntRight: "2754" },
  { key: "dlgap4", filterType: "BestCase", aaLeft: "689", aaRight: "713", ntLeft: "2065", ntRight: "2139" },
  { key: "apba3", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "mef2b", filterType: "SecondBest", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "mark2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fanci", filterType: "BestCase", aaLeft: "1107", aaRight: "1131", ntLeft: "3319", ntRight: "3393" },
  { key: "arfgap3", filterType: "BestCase", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "sbno1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "fam118a", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "smtnl2", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "hk3", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "cldn25", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "kcnab2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "plxna3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rnf24", filterType: "SecondBest", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "pik3cg", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "taf1a", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "chchd4", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "plekhg3", filterType: "BestCase", aaLeft: "1120", aaRight: "1144", ntLeft: "3358", ntRight: "3432" },
  { key: "abca10", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tcf19", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "fut2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "rnf13", filterType: "SecondBest", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "xpo1", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "pde6h", filterType: "SecondBest", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "zcwpw1", filterType: "FailedAllFilters", aaLeft: "389", aaRight: "413", ntLeft: "1165", ntRight: "1239" },
  { key: "runx1t1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "arhgap29", filterType: "BestCase", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "cpt2", filterType: "BestCase", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "a2m", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "dync2li1", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "gimap8", filterType: "FailedAllFilters", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "ctcf", filterType: "SecondBest", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "vsig10", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "mcrip2", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "cldn12", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "znf596", filterType: "FailedAllFilters", aaLeft: "463", aaRight: "487", ntLeft: "1387", ntRight: "1461" },
  { key: "mrpl4", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "casc3", filterType: "BestCase", aaLeft: "418", aaRight: "442", ntLeft: "1252", ntRight: "1326" },
  { key: "nsfl1c", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "chl1", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "krt25", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "txnrd2", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "tas1r2", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "npm2", filterType: "FourthBest", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "p4ha2", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "fam221b", filterType: "BestCase", aaLeft: "378", aaRight: "402", ntLeft: "1132", ntRight: "1206" },
  { key: "nos3", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "tnrc6a", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "fkbp7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "tecr", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "oaz1", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "arsg", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "sult1c4", filterType: "FailedAllFilters", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "hsd17b6", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "man1c1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "anapc2", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "fzd9", filterType: "BestCase", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "morc4", filterType: "BestCase", aaLeft: "632", aaRight: "656", ntLeft: "1894", ntRight: "1968" },
  { key: "ppp1r1b", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "esco2", filterType: "BestCase", aaLeft: "508", aaRight: "532", ntLeft: "1522", ntRight: "1596" },
  { key: "pcdhgc3", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tor4a", filterType: "BestCase", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "braf", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "tmem38b", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "oxgr1", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "asic2", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ankrd42", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "marcol", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "hmg20a", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "plac8", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "tssk6", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "tmprss11f", filterType: "FailedAllFilters", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "hoxa2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "f8a1", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "leng9", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "mrpl58", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "brd3os", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "h2bc13", filterType: "SecondBest", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ptprk", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "sprr4", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "nqo1", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "slc10a7", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gkn1", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "fam43a", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "loc112694756", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tenm2", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tmem232", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "hoxc11", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "dennd1b", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "sdccag8", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "ero1b", filterType: "SecondBest", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "krba2", filterType: "FailedAllFilters", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "tmem205", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "il15ra", filterType: "FailedAllFilters", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ppp2ca", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "erap2", filterType: "FailedAllFilters", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "syt17", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "srarp", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or2m2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "plcg2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "msmb", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "atp5pb", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "prepl", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "gria2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "h2bc12l", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ssbp3", filterType: "SecondBest", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "nup43", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "arl4c", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "prkab2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "usp8", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "yeats2", filterType: "BestCase", aaLeft: "997", aaRight: "1021", ntLeft: "2989", ntRight: "3063" },
  { key: "inpp5j", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "hoxb5", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "shld3", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "gpd1l", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "ube2l5", filterType: "FourthBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "sox9", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "ppp1r7", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "aldh8a1", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "cachd1", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "nkx1-2", filterType: "FailedAllFilters", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "supt6h", filterType: "BestCase", aaLeft: "649", aaRight: "673", ntLeft: "1945", ntRight: "2019" },
  { key: "fam187b", filterType: "FailedAllFilters", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "or52e8", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "l3hypdh", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "grin2d", filterType: "BestCase", aaLeft: "597", aaRight: "621", ntLeft: "1789", ntRight: "1863" },
  { key: "tmem139", filterType: "FailedAllFilters", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ctsw", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "rhot2", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "nudcd3", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "ttc26", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "pih1d1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "plekha1", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "sptb", filterType: "BestCase", aaLeft: "1350", aaRight: "1374", ntLeft: "4048", ntRight: "4122" },
  { key: "zwilch", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "ptch2", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "adamts5", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "stim2", filterType: "BestCase", aaLeft: "539", aaRight: "563", ntLeft: "1615", ntRight: "1689" },
  { key: "esd", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "celsr1", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "tmem89", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "hpcal1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "dhtkd1", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "c4orf17", filterType: "FailedAllFilters", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "irak1bp1", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "ube3a", filterType: "BestCase", aaLeft: "660", aaRight: "684", ntLeft: "1978", ntRight: "2052" },
  { key: "rgs8", filterType: "SecondBest", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "nde1", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "naa50", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "fam168a", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "adrb3", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "dgcr2", filterType: "BestCase", aaLeft: "473", aaRight: "497", ntLeft: "1417", ntRight: "1491" },
  { key: "tesc", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "rassf6", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "lgi4", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "bms1", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "klhl8", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "akap12", filterType: "FailedAllFilters", aaLeft: "1062", aaRight: "1086", ntLeft: "3184", ntRight: "3258" },
  { key: "tnfsf9", filterType: "FailedAllFilters", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "eif5", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "gpalpp1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "prr32", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "fbxw5", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "mad2l2", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "qtrt1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf296", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "bmp6", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "krtap20-4", filterType: "FailedAllFilters", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "tnfrsf9", filterType: "FailedAllFilters", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "cd1e", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "anxa4", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "gage12b", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "aarsd1", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "gcc1", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "znf789", filterType: "FailedAllFilters", aaLeft: "393", aaRight: "417", ntLeft: "1177", ntRight: "1251" },
  { key: "asb17", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "tnfrsf1b", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "nomo3", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "obp2a", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "wdr81", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "nalf1", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "nemf", filterType: "BestCase", aaLeft: "925", aaRight: "949", ntLeft: "2773", ntRight: "2847" },
  { key: "chid1", filterType: "BestCase", aaLeft: "292", aaRight: "316", ntLeft: "874", ntRight: "948" },
  { key: "tusc1", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "dnah6", filterType: "BestCase", aaLeft: "1341", aaRight: "1365", ntLeft: "4021", ntRight: "4095" },
  { key: "kcnk13", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "adgre2", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "pcnx1", filterType: "BestCase", aaLeft: "420", aaRight: "444", ntLeft: "1258", ntRight: "1332" },
  { key: "prrt2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ptpn12", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "s100a1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "rrp36", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "strn3", filterType: "BestCase", aaLeft: "658", aaRight: "682", ntLeft: "1972", ntRight: "2046" },
  { key: "tmem250", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "atp1b3", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "rgpd4", filterType: "BestCase", aaLeft: "491", aaRight: "515", ntLeft: "1471", ntRight: "1545" },
  { key: "pdhb", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "or13c2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "gba2", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "abcc8", filterType: "BestCase", aaLeft: "659", aaRight: "683", ntLeft: "1975", ntRight: "2049" },
  { key: "nr2f1", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "vipr1", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "septin3", filterType: "SecondBest", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "nlrc3", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "emx2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "st6galnac5", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "qsox2", filterType: "BestCase", aaLeft: "424", aaRight: "448", ntLeft: "1270", ntRight: "1344" },
  { key: "il5", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sin3b", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "cntn3", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "pnpla6", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "hoxd11", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "nfatc4", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "bin1", filterType: "BestCase", aaLeft: "401", aaRight: "425", ntLeft: "1201", ntRight: "1275" },
  { key: "stk33", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "cd86", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "znf92", filterType: "FailedAllFilters", aaLeft: "484", aaRight: "508", ntLeft: "1450", ntRight: "1524" },
  { key: "c1ql3", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "mfn1", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "ncald", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "txndc16", filterType: "BestCase", aaLeft: "584", aaRight: "608", ntLeft: "1750", ntRight: "1824" },
  { key: "slc17a7", filterType: "BestCase", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "puf60", filterType: "SecondBest", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "aox1", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "hcn1", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "btbd18", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "sec11a", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "cnn2", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "kash5", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "ankrd17", filterType: "BestCase", aaLeft: "2170", aaRight: "2194", ntLeft: "6508", ntRight: "6582" },
  { key: "ryr1", filterType: "BestCase", aaLeft: "3364", aaRight: "3388", ntLeft: "10090", ntRight: "10164" },
  { key: "uchl5", filterType: "SecondBest", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "upf3b", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "apobec3b", filterType: "FailedAllFilters", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "slitrk6", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "lonrf3", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "tas2r9", filterType: "FailedAllFilters", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "tekt3", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "hspa9", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "hyi", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "armcx6", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "adam11", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "bex3", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "vamp5", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "adcyap1", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "ulbp1", filterType: "FailedAllFilters", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "fam236d", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znhit3", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "pdcd4", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "galntl5", filterType: "FailedAllFilters", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "nkx6-2", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "crocc2", filterType: "FailedAllFilters", aaLeft: "890", aaRight: "914", ntLeft: "2668", ntRight: "2742" },
  { key: "adgrf5", filterType: "BestCase", aaLeft: "940", aaRight: "964", ntLeft: "2818", ntRight: "2892" },
  { key: "znf382", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "cst9", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "zscan30", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "acad10", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "pramef8", filterType: "FailedAllFilters", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "ccnk", filterType: "BestCase", aaLeft: "275", aaRight: "299", ntLeft: "823", ntRight: "897" },
  { key: "tmem267", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "atp1a4", filterType: "BestCase", aaLeft: "642", aaRight: "666", ntLeft: "1924", ntRight: "1998" },
  { key: "kcnb2", filterType: "BestCase", aaLeft: "677", aaRight: "701", ntLeft: "2029", ntRight: "2103" },
  { key: "snf8", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "nr6a1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "krt5", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "bckdha", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "pdzd4", filterType: "BestCase", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "cxorf65", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "cd6", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "disp2", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "lrrc74b", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "mlf2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "bpifb3", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "grk4", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "faf1", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "galnt15", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "nol6", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "hes7", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "or5ak2", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "mael", filterType: "BestCase", aaLeft: "185", aaRight: "209", ntLeft: "553", ntRight: "627" },
  { key: "fam204a", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ypel3", filterType: "SecondBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "cideb", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "bmi1", filterType: "SecondBest", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "mobp", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "c17orf75", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "atp2c1", filterType: "SecondBest", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "rtel1", filterType: "BestCase", aaLeft: "810", aaRight: "834", ntLeft: "2428", ntRight: "2502" },
  { key: "nudt16l1", filterType: "SecondBest", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "hnrnpa2b1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "f2rl2", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "pacsin2", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "eogt", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "jph2", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "wdpcp", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "tmem175", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "galnt3", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "ccdc9", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "ap3m1", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "elovl4", filterType: "SecondBest", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "or1j2", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "adcy10", filterType: "FailedAllFilters", aaLeft: "866", aaRight: "890", ntLeft: "2596", ntRight: "2670" },
  { key: "pde7a", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "ndufa9", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "arhgap30", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "snx4", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "c1orf116", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "h4c7", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "tspan4", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "krtap22-2", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tarm1", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "pmp22", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "mafk", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "lce5a", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "dmpk", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "or10a7", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "rem2", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ccl20", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "capsl", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "kcna5", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "tm6sf2", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "tmcc2", filterType: "BestCase", aaLeft: "498", aaRight: "522", ntLeft: "1492", ntRight: "1566" },
  { key: "fnbp1", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "kars1", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "cyp4v2", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "h2bu1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "col6a6", filterType: "FailedAllFilters", aaLeft: "1256", aaRight: "1280", ntLeft: "3766", ntRight: "3840" },
  { key: "slco6a1", filterType: "FailedAllFilters", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "olfml3", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "acads", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "lyl1", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "gtf3a", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "rfpl4b", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "preb", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "lin7b", filterType: "SecondBest", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "elfn2", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "sgpp2", filterType: "BestCase", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "svopl", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "tmbim6", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "xpot", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "prr23d2", filterType: "FailedAllFilters", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "selenbp1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "cfap97", filterType: "FailedAllFilters", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "ecm1", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "mrpl41", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "mpc1", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "c3orf20", filterType: "FailedAllFilters", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "cldn9", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "wrnip1", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "myo7a", filterType: "BestCase", aaLeft: "1526", aaRight: "1550", ntLeft: "4576", ntRight: "4650" },
  { key: "lat2", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "slc7a6", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "zfp3", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "ntsr2", filterType: "FailedAllFilters", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "abcd3", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "baiap3", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "sh3glb2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "slc40a1", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "arf6", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "fgr", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "insyn2b", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "cxxc5", filterType: "SecondBest", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "mc3r", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "sco2", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "aspm", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "wdr45b", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "slc15a1", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "mgam", filterType: "BestCase", aaLeft: "2700", aaRight: "2724", ntLeft: "8098", ntRight: "8172" },
  { key: "ocel1", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "fam89a", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "azgp1", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "sumo4", filterType: "ThirdBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "tfap2d", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "pttg1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or5w2", filterType: "FailedAllFilters", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "nop53", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "nt5dc2", filterType: "BestCase", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "ptbp1", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "atxn1l", filterType: "BestCase", aaLeft: "633", aaRight: "657", ntLeft: "1897", ntRight: "1971" },
  { key: "plpp4", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "ccnl1", filterType: "SecondBest", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "abcg4", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "srsf9", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "fam216b", filterType: "FourthBest", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "ppp4r1", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "mest", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "ptk7", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tmed4", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "setd9", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "pold1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "dcaf12l2", filterType: "FailedAllFilters", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "stat5b", filterType: "BestCase", aaLeft: "721", aaRight: "745", ntLeft: "2161", ntRight: "2235" },
  { key: "rab4b", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "dcun1d5", filterType: "SecondBest", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "tigd7", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "znf184", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "bricd5", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "bcl6b", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "dcx", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sorcs1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "smc6", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "calcrl", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "pars2", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "tatdn1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "ccdc80", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "or52d1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "p2ry10", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "mtx3", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "furin", filterType: "BestCase", aaLeft: "688", aaRight: "712", ntLeft: "2062", ntRight: "2136" },
  { key: "fam72c", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "sgsm2", filterType: "BestCase", aaLeft: "776", aaRight: "800", ntLeft: "2326", ntRight: "2400" },
  { key: "smpd3", filterType: "BestCase", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "ndel1", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "bid", filterType: "FailedAllFilters", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "zyx", filterType: "BestCase", aaLeft: "370", aaRight: "394", ntLeft: "1108", ntRight: "1182" },
  { key: "dock4", filterType: "BestCase", aaLeft: "1932", aaRight: "1956", ntLeft: "5794", ntRight: "5868" },
  { key: "prr20c", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "ndufaf2", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "dcp1b", filterType: "BestCase", aaLeft: "192", aaRight: "216", ntLeft: "574", ntRight: "648" },
  { key: "ifrd2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "rras", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "chsy1", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "lmod2", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "ermn", filterType: "FailedAllFilters", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "ccnyl1", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "mapk4", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "pgap1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zswim5", filterType: "BestCase", aaLeft: "629", aaRight: "653", ntLeft: "1885", ntRight: "1959" },
  { key: "lss", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "grm6", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "ube2h", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "stard9", filterType: "FailedAllFilters", aaLeft: "2158", aaRight: "2182", ntLeft: "6472", ntRight: "6546" },
  { key: "lama5", filterType: "BestCase", aaLeft: "2229", aaRight: "2253", ntLeft: "6685", ntRight: "6759" },
  { key: "limd1", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "mei1", filterType: "BestCase", aaLeft: "731", aaRight: "755", ntLeft: "2191", ntRight: "2265" },
  { key: "desi1", filterType: "SecondBest", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "zdhhc8", filterType: "BestCase", aaLeft: "724", aaRight: "748", ntLeft: "2170", ntRight: "2244" },
  { key: "ezr", filterType: "BestCase", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "actl8", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "ddx19a", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "cdc16", filterType: "BestCase", aaLeft: "563", aaRight: "587", ntLeft: "1687", ntRight: "1761" },
  { key: "cpn2", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "sbk3", filterType: "BestCase", aaLeft: "295", aaRight: "319", ntLeft: "883", ntRight: "957" },
  { key: "lamb2", filterType: "BestCase", aaLeft: "1374", aaRight: "1398", ntLeft: "4120", ntRight: "4194" },
  { key: "dtx1", filterType: "BestCase", aaLeft: "593", aaRight: "617", ntLeft: "1777", ntRight: "1851" },
  { key: "smr3a", filterType: "FailedAllFilters", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "asb7", filterType: "SecondBest", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "igfl2", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "mphosph8", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "klk13", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "ca3", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "taf9", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "elf3", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "tsfm", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ap4m1", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "c14orf119", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "ago1", filterType: "SecondBest", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "h3c7", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "apold1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "or4p4", filterType: "FailedAllFilters", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "six4", filterType: "BestCase", aaLeft: "621", aaRight: "645", ntLeft: "1861", ntRight: "1935" },
  { key: "gage13", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "znf790", filterType: "BestCase", aaLeft: "430", aaRight: "454", ntLeft: "1288", ntRight: "1362" },
  { key: "iyd", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "sem1", filterType: "SecondBest", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "tmsb4x", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "poglut3", filterType: "BestCase", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "kif3b", filterType: "BestCase", aaLeft: "638", aaRight: "662", ntLeft: "1912", ntRight: "1986" },
  { key: "lce3c", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "or5b12", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "gpr68", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pde1c", filterType: "BestCase", aaLeft: "553", aaRight: "577", ntLeft: "1657", ntRight: "1731" },
  { key: "rp1", filterType: "FailedAllFilters", aaLeft: "1506", aaRight: "1530", ntLeft: "4516", ntRight: "4590" },
  { key: "tmem117", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "wnt16", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "c1orf174", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "myl2", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "c13orf46", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "nfia", filterType: "SecondBest", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "tmem249", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "fsip2", filterType: "FailedAllFilters", aaLeft: "6493", aaRight: "6517", ntLeft: "19477", ntRight: "19551" },
  { key: "fap", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "atp10b", filterType: "BestCase", aaLeft: "689", aaRight: "713", ntLeft: "2065", ntRight: "2139" },
  { key: "mnat1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "suv39h2", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "cep295", filterType: "FailedAllFilters", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "myo1c", filterType: "BestCase", aaLeft: "995", aaRight: "1019", ntLeft: "2983", ntRight: "3057" },
  { key: "csrnp1", filterType: "BestCase", aaLeft: "526", aaRight: "550", ntLeft: "1576", ntRight: "1650" },
  { key: "hnrnpm", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mrpl23", filterType: "SecondBest", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "egln1", filterType: "BestCase", aaLeft: "163", aaRight: "187", ntLeft: "487", ntRight: "561" },
  { key: "rnf111", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "trdn", filterType: "FailedAllFilters", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "abcb1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "polr2d", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "rhbdf2", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "ifna1", filterType: "FailedAllFilters", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cttnbp2", filterType: "BestCase", aaLeft: "860", aaRight: "884", ntLeft: "2578", ntRight: "2652" },
  { key: "slc47a1", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "cntnap4", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "pkp3", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "slc12a1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "or2t5", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "sult2a1", filterType: "FailedAllFilters", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "zglp1", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "ankrd39", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lipa", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "mgat3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "fgd2", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "rgn", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "lmntd2", filterType: "BestCase", aaLeft: "379", aaRight: "403", ntLeft: "1135", ntRight: "1209" },
  { key: "dnase1l2", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "stoml2", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "avil", filterType: "BestCase", aaLeft: "666", aaRight: "690", ntLeft: "1996", ntRight: "2070" },
  { key: "tlr8", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "ucma", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "gata2", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "lifr", filterType: "BestCase", aaLeft: "989", aaRight: "1013", ntLeft: "2965", ntRight: "3039" },
  { key: "samsn1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "rnase1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "mrpl14", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "mdfic", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "znf684", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "bola2", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "lhfpl3", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "rnf126", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "abca6", filterType: "BestCase", aaLeft: "718", aaRight: "742", ntLeft: "2152", ntRight: "2226" },
  { key: "dapk1", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "polr1c", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "vps4a", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "shroom2", filterType: "BestCase", aaLeft: "1283", aaRight: "1307", ntLeft: "3847", ntRight: "3921" },
  { key: "cenpn", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "slc28a3", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "nxnl1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "e2f8", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "ppp3r1", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "sema4a", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "zscan29", filterType: "BestCase", aaLeft: "653", aaRight: "677", ntLeft: "1957", ntRight: "2031" },
  { key: "clk1", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "lhx6", filterType: "SecondBest", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "irf7", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "psma3", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "itga5", filterType: "BestCase", aaLeft: "855", aaRight: "879", ntLeft: "2563", ntRight: "2637" },
  { key: "dgke", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "rpl18", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "usp16", filterType: "BestCase", aaLeft: "407", aaRight: "431", ntLeft: "1219", ntRight: "1293" },
  { key: "arfip1", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "pcca", filterType: "BestCase", aaLeft: "634", aaRight: "658", ntLeft: "1900", ntRight: "1974" },
  { key: "chmp1a", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "mmp7", filterType: "FailedAllFilters", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "tmem9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf43", filterType: "FailedAllFilters", aaLeft: "714", aaRight: "738", ntLeft: "2140", ntRight: "2214" },
  { key: "ube2d1", filterType: "SecondBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "ifna10", filterType: "FailedAllFilters", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "znf888", filterType: "FailedAllFilters", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "dclk1", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "igll5", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "olfm2", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "zbtb39", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "tfpt", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "mov10", filterType: "BestCase", aaLeft: "966", aaRight: "990", ntLeft: "2896", ntRight: "2970" },
  { key: "capn13", filterType: "FailedAllFilters", aaLeft: "601", aaRight: "625", ntLeft: "1801", ntRight: "1875" },
  { key: "fndc1", filterType: "BestCase", aaLeft: "1643", aaRight: "1667", ntLeft: "4927", ntRight: "5001" },
  { key: "dip2b", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "mettl2b", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "fzr1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "ifngr1", filterType: "FailedAllFilters", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "ice1", filterType: "FailedAllFilters", aaLeft: "690", aaRight: "714", ntLeft: "2068", ntRight: "2142" },
  { key: "ncbp3", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "irak1", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "akr7a3", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "ace", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "or5h15", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "psmd11", filterType: "SecondBest", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "atp7a", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "hectd4", filterType: "BestCase", aaLeft: "3221", aaRight: "3245", ntLeft: "9661", ntRight: "9735" },
  { key: "zkscan5", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "or2f1", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ldlrap1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "nup133", filterType: "BestCase", aaLeft: "403", aaRight: "427", ntLeft: "1207", ntRight: "1281" },
  { key: "rps15", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "pard3", filterType: "BestCase", aaLeft: "1297", aaRight: "1321", ntLeft: "3889", ntRight: "3963" },
  { key: "xage5", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "faxc", filterType: "SecondBest", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "prdm4", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "mybpc2", filterType: "BestCase", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "btbd1", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "plcl1", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "zdhhc12", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "ppfia1", filterType: "BestCase", aaLeft: "816", aaRight: "840", ntLeft: "2446", ntRight: "2520" },
  { key: "capn9", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "znf74", filterType: "FailedAllFilters", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "map3k20", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "nemp1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "dmgdh", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "caps2", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "depdc4", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "nbl1", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "znf431", filterType: "FailedAllFilters", aaLeft: "487", aaRight: "511", ntLeft: "1459", ntRight: "1533" },
  { key: "pkd1l3", filterType: "BestCase", aaLeft: "1569", aaRight: "1593", ntLeft: "4705", ntRight: "4779" },
  { key: "ces3", filterType: "FailedAllFilters", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "abhd12", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "mrm1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "c1qtnf8", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "rfpl3", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "cpne7", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "surf4", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "saa2", filterType: "FourthBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "plagl2", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "psmb4", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "itgb2", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "tgm7", filterType: "BestCase", aaLeft: "475", aaRight: "499", ntLeft: "1423", ntRight: "1497" },
  { key: "ly6d", filterType: "FailedAllFilters", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "fst", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "abracl", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "usp21", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "fbxo43", filterType: "FailedAllFilters", aaLeft: "402", aaRight: "426", ntLeft: "1204", ntRight: "1278" },
  { key: "pld5", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "tm4sf4", filterType: "BestCase", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "cers1", filterType: "BestCase", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "tbc1d30", filterType: "SecondBest", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "clpsl2", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "kdr", filterType: "BestCase", aaLeft: "195", aaRight: "219", ntLeft: "583", ntRight: "657" },
  { key: "xcl1", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "kcnh8", filterType: "BestCase", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "cyp20a1", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "slc18b1", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "nipsnap3b", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "smarcd1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "ubxn11", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "amy1c", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "chd5", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "frmpd3", filterType: "BestCase", aaLeft: "845", aaRight: "869", ntLeft: "2533", ntRight: "2607" },
  { key: "trim49", filterType: "FailedAllFilters", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "oxtr", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "ptpn7", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "chrna1", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "naprt", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "slc22a4", filterType: "BestCase", aaLeft: "291", aaRight: "315", ntLeft: "871", ntRight: "945" },
  { key: "snurf", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cilp2", filterType: "BestCase", aaLeft: "417", aaRight: "441", ntLeft: "1249", ntRight: "1323" },
  { key: "znf512", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "rnaseh1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "gtsf1", filterType: "FourthBest", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "necab3", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "hif1an", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "rpl23a", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "tm2d3", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "zmynd12", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "lbx1", filterType: "BestCase", aaLeft: "227", aaRight: "251", ntLeft: "679", ntRight: "753" },
  { key: "clip2", filterType: "BestCase", aaLeft: "860", aaRight: "884", ntLeft: "2578", ntRight: "2652" },
  { key: "lbp", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "ddx60l", filterType: "FailedAllFilters", aaLeft: "469", aaRight: "493", ntLeft: "1405", ntRight: "1479" },
  { key: "scgb1a1", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "lto1", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "nmb", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "vwce", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "c12orf50", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "smad2", filterType: "SecondBest", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "crispld2", filterType: "BestCase", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "tbl1x", filterType: "BestCase", aaLeft: "207", aaRight: "231", ntLeft: "619", ntRight: "693" },
  { key: "tktl2", filterType: "FailedAllFilters", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "grp", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ift57", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hspbp1", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "aoah", filterType: "FailedAllFilters", aaLeft: "542", aaRight: "566", ntLeft: "1624", ntRight: "1698" },
  { key: "rmnd5a", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "anxa10", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "cbwd6", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "pigq", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "tulp1", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "spop", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "nipal4", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "znf525", filterType: "FailedAllFilters", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "lrrc49", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "sympk", filterType: "BestCase", aaLeft: "1091", aaRight: "1115", ntLeft: "3271", ntRight: "3345" },
  { key: "akr1e2", filterType: "FailedAllFilters", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "pea15", filterType: "SecondBest", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "acsf2", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "zw10", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "klhl36", filterType: "BestCase", aaLeft: "259", aaRight: "283", ntLeft: "775", ntRight: "849" },
  { key: "shbg", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "heg1", filterType: "FailedAllFilters", aaLeft: "1026", aaRight: "1050", ntLeft: "3076", ntRight: "3150" },
  { key: "armc10", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "hla-dqb2", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "cct5", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "exosc6", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "maged4", filterType: "FailedAllFilters", aaLeft: "604", aaRight: "628", ntLeft: "1810", ntRight: "1884" },
  { key: "slc25a33", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "ppp1r32", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "angptl7", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "luzp1", filterType: "BestCase", aaLeft: "1036", aaRight: "1060", ntLeft: "3106", ntRight: "3180" },
  { key: "mgat4d", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "arih2", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "ttc6", filterType: "FailedAllFilters", aaLeft: "1862", aaRight: "1886", ntLeft: "5584", ntRight: "5658" },
  { key: "rragd", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "itih4", filterType: "BestCase", aaLeft: "734", aaRight: "758", ntLeft: "2200", ntRight: "2274" },
  { key: "iqcg", filterType: "FailedAllFilters", aaLeft: "258", aaRight: "282", ntLeft: "772", ntRight: "846" },
  { key: "atrx", filterType: "BestCase", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "mitd1", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "klf14", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "uaca", filterType: "FailedAllFilters", aaLeft: "298", aaRight: "322", ntLeft: "892", ntRight: "966" },
  { key: "tomm20", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gramd1b", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "c5", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "kif7", filterType: "BestCase", aaLeft: "1196", aaRight: "1220", ntLeft: "3586", ntRight: "3660" },
  { key: "or13h1", filterType: "FailedAllFilters", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znf619", filterType: "FailedAllFilters", aaLeft: "497", aaRight: "521", ntLeft: "1489", ntRight: "1563" },
  { key: "dact3", filterType: "FailedAllFilters", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "hlf", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "cmas", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "cfap418", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "sftpd", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "resp18", filterType: "FailedAllFilters", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ubr7", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "gstm2", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "ccnp", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "cfap107", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "cdca7", filterType: "BestCase", aaLeft: "252", aaRight: "276", ntLeft: "754", ntRight: "828" },
  { key: "axl", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "wdfy4", filterType: "BestCase", aaLeft: "719", aaRight: "743", ntLeft: "2155", ntRight: "2229" },
  { key: "psmg1", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "fbxo16", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "morn5", filterType: "FourthBest", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "trib3", filterType: "BestCase", aaLeft: "322", aaRight: "346", ntLeft: "964", ntRight: "1038" },
  { key: "pudp", filterType: "FourthBest", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "gamt", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "cacna1i", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "psmd6", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "fbxo21", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "usp43", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "or5f1", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "mmel1", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "glb1l3", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "strip2", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "znf16", filterType: "FailedAllFilters", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "eif3j", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "tlx2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "hnmt", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "id1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "srp54", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "rabep2", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "nat16", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "elmo1", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "rab11b", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "daam2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "msantd1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "mccc1", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "orai2", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "gk2", filterType: "FailedAllFilters", aaLeft: "470", aaRight: "494", ntLeft: "1408", ntRight: "1482" },
  { key: "rxrb", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "hacd3", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "agap5", filterType: "FailedAllFilters", aaLeft: "618", aaRight: "642", ntLeft: "1852", ntRight: "1926" },
  { key: "haus3", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "sesn3", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "ssmem1", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "dhx37", filterType: "BestCase", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "znf331", filterType: "FailedAllFilters", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "prox1", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "kbtbd2", filterType: "SecondBest", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "c16orf54", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "b3galt2", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "azin1", filterType: "BestCase", aaLeft: "400", aaRight: "424", ntLeft: "1198", ntRight: "1272" },
  { key: "exoc4", filterType: "BestCase", aaLeft: "923", aaRight: "947", ntLeft: "2767", ntRight: "2841" },
  { key: "iqcf3", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "kcns1", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "slc35g5", filterType: "FailedAllFilters", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "mis18a", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "rbm33", filterType: "BestCase", aaLeft: "1036", aaRight: "1060", ntLeft: "3106", ntRight: "3180" },
  { key: "tnnt1", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "scin", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "meox1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "nyap1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "foxf1", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "h2aj", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "hivep1", filterType: "FailedAllFilters", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "znf570", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "gba", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "apool", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "or4d2", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "krtap7-1", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "cyp27a1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "sh3bp2", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "ostc", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "mfsd2b", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "urb1", filterType: "BestCase", aaLeft: "1079", aaRight: "1103", ntLeft: "3235", ntRight: "3309" },
  { key: "sucnr1", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "rtn4", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "npas4", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "magea1", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "diablo", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "epx", filterType: "BestCase", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "slc25a4", filterType: "BestCase", aaLeft: "146", aaRight: "170", ntLeft: "436", ntRight: "510" },
  { key: "rab19", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "gjb4", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "znf212", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "fam163b", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "tmod3", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "srl", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "osbp", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mageb6", filterType: "FailedAllFilters", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "slc25a51", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "ccr7", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "gja3", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "pxmp4", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "znf225", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "c8a", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "arl13b", filterType: "BestCase", aaLeft: "241", aaRight: "265", ntLeft: "721", ntRight: "795" },
  { key: "fcgr3b", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "il20rb", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "ctsl", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "smarca4", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "tmigd3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "map1lc3a", filterType: "SecondBest", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "phtf2", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "map1a", filterType: "BestCase", aaLeft: "1244", aaRight: "1268", ntLeft: "3730", ntRight: "3804" },
  { key: "gpc6", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "fcn3", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "znf547", filterType: "FailedAllFilters", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "cox14", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "phospho1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "ncoa7", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "scgb1d4", filterType: "FailedAllFilters", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "slc35d2", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "tspan17", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "rsrc2", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "txndc8", filterType: "FailedAllFilters", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "cog2", filterType: "BestCase", aaLeft: "436", aaRight: "460", ntLeft: "1306", ntRight: "1380" },
  { key: "hbg1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "bsnd", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "spag9", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "triap1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "h2bc21", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "tubb8b", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "cbll1", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "nr2c1", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "ddr2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "palm", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "s1pr3", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "aar2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "syt12", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "or2m7", filterType: "FailedAllFilters", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "or51f1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "chaf1a", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "scp2", filterType: "BestCase", aaLeft: "226", aaRight: "250", ntLeft: "676", ntRight: "750" },
  { key: "ppp1r2", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "nutm1", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "dmrt3", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "dbt", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "asah2b", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "ribc2", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "fgfr3", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "znf487", filterType: "FailedAllFilters", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "cgb3", filterType: "FourthBest", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "por", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "cela3b", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "milr1", filterType: "FailedAllFilters", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "amer2", filterType: "FailedAllFilters", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "xkr9", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "strn", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "tssk3", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "stimate", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "camsap1", filterType: "BestCase", aaLeft: "1578", aaRight: "1602", ntLeft: "4732", ntRight: "4806" },
  { key: "hoxa7", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "or5h2", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "engase", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "morc1", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "rpp25l", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "auh", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "commd8", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sfrp2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "krtap19-8", filterType: "ThirdBest", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "slc52a1", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "tmem237", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "slc2a5", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "git2", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "actrt2", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "khdrbs2", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "trabd2b", filterType: "BestCase", aaLeft: "376", aaRight: "400", ntLeft: "1126", ntRight: "1200" },
  { key: "ptprn", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "slc45a2", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "loc93622", filterType: "FailedAllFilters", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "slc10a2", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "acsm4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "h3y2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ehhadh", filterType: "BestCase", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "cldn17", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "znf593", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "cdy1", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "npw", filterType: "FailedAllFilters", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "gpn2", filterType: "BestCase", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "plxnb1", filterType: "BestCase", aaLeft: "1305", aaRight: "1329", ntLeft: "3913", ntRight: "3987" },
  { key: "mrpl1", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "fut7", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "xpo4", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "krtap27-1", filterType: "FailedAllFilters", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "tmem169", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "amph", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "ddx39b", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "supt3h", filterType: "BestCase", aaLeft: "289", aaRight: "313", ntLeft: "865", ntRight: "939" },
  { key: "ash2l", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "pot1", filterType: "BestCase", aaLeft: "605", aaRight: "629", ntLeft: "1813", ntRight: "1887" },
  { key: "gipc3", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "slc39a8", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "plekhd1", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "asrgl1", filterType: "BestCase", aaLeft: "280", aaRight: "304", ntLeft: "838", ntRight: "912" },
  { key: "tasor", filterType: "BestCase", aaLeft: "1565", aaRight: "1589", ntLeft: "4693", ntRight: "4767" },
  { key: "slc2a4rg", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "aanat", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "llcfc1", filterType: "FailedAllFilters", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "arsb", filterType: "BestCase", aaLeft: "478", aaRight: "502", ntLeft: "1432", ntRight: "1506" },
  { key: "serpine3", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "mta1", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "tbc1d2b", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "anapc7", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "hsd17b3", filterType: "FailedAllFilters", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "sv2c", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "krt20", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "il12rb1", filterType: "FailedAllFilters", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "apbb1", filterType: "BestCase", aaLeft: "321", aaRight: "345", ntLeft: "961", ntRight: "1035" },
  { key: "fkbp2", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "c4orf47", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "grn", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "s100a11", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "krt17", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "dsg1", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "echs1", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "peli2", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "csf2rb", filterType: "FailedAllFilters", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "hamp", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fmo3", filterType: "BestCase", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "dlgap1", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "pigo", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "ctdspl2", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "pax5", filterType: "SecondBest", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "diras1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "prss1", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "anapc13", filterType: "FourthBest", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "sfpq", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "bbln", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "chchd1", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "gpr21", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "pik3cb", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "lsmem2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "taf1d", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ezh1", filterType: "BestCase", aaLeft: "356", aaRight: "380", ntLeft: "1066", ntRight: "1140" },
  { key: "ndufc2", filterType: "FourthBest", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "plekhg6", filterType: "BestCase", aaLeft: "716", aaRight: "740", ntLeft: "2146", ntRight: "2220" },
  { key: "rabac1", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "nanogp8", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "cemip2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "cops5", filterType: "SecondBest", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "clpx", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "tacc3", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "utp14a", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "fancl", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zfp36l1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "cldn20", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "eefsec", filterType: "FailedAllFilters", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "vcl", filterType: "SecondBest", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "pmpca", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "best2", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "fam24b", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "adh4", filterType: "BestCase", aaLeft: "212", aaRight: "236", ntLeft: "634", ntRight: "708" },
  { key: "txlna", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "apobec3g", filterType: "FailedAllFilters", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "ube2j2", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "slitrk3", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "iqsec1", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "hnrnpa3", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "mtfp1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "vps9d1", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "rtbdn", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "tor1a", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "cabcoco1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "fam240c", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "cgnl1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "synpo2", filterType: "FailedAllFilters", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "ankrd12", filterType: "BestCase", aaLeft: "1439", aaRight: "1463", ntLeft: "4315", ntRight: "4389" },
  { key: "hlx", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "dpy30", filterType: "SecondBest", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "sp9", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "oit3", filterType: "BestCase", aaLeft: "520", aaRight: "544", ntLeft: "1558", ntRight: "1632" },
  { key: "gripap1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "cbx5", filterType: "SecondBest", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "mcmdc2", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "dtnb", filterType: "BestCase", aaLeft: "528", aaRight: "552", ntLeft: "1582", ntRight: "1656" },
  { key: "ptpn20", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "tmem131l", filterType: "BestCase", aaLeft: "610", aaRight: "634", ntLeft: "1828", ntRight: "1902" },
  { key: "tmem262", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "atp1a1", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "armcx3", filterType: "BestCase", aaLeft: "274", aaRight: "298", ntLeft: "820", ntRight: "894" },
  { key: "fam236a", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "syt5", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "ppp6r2", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "phlpp1", filterType: "BestCase", aaLeft: "711", aaRight: "735", ntLeft: "2131", ntRight: "2205" },
  { key: "syce3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znhit6", filterType: "BestCase", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "pdcd1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "nr2e3", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "prkdc", filterType: "BestCase", aaLeft: "2897", aaRight: "2921", ntLeft: "8689", ntRight: "8763" },
  { key: "rasl10b", filterType: "SecondBest", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "rgpd1", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "htatip2", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "megf9", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "grid2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "adam23", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "plcb2", filterType: "BestCase", aaLeft: "1150", aaRight: "1174", ntLeft: "3448", ntRight: "3522" },
  { key: "septin6", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "laptm4a", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "elspbp1", filterType: "FailedAllFilters", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "polr3h", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "pom121l12", filterType: "FailedAllFilters", aaLeft: "75", aaRight: "99", ntLeft: "223", ntRight: "297" },
  { key: "ebln2", filterType: "FailedAllFilters", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "dennd4b", filterType: "BestCase", aaLeft: "1203", aaRight: "1227", ntLeft: "3607", ntRight: "3681" },
  { key: "pcnx4", filterType: "BestCase", aaLeft: "1046", aaRight: "1070", ntLeft: "3136", ntRight: "3210" },
  { key: "or2h2", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "s100a4", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "ehd3", filterType: "BestCase", aaLeft: "380", aaRight: "404", ntLeft: "1138", ntRight: "1212" },
  { key: "zbed8", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "atp9b", filterType: "BestCase", aaLeft: "606", aaRight: "630", ntLeft: "1816", ntRight: "1890" },
  { key: "rasal3", filterType: "BestCase", aaLeft: "371", aaRight: "395", ntLeft: "1111", ntRight: "1185" },
  { key: "fgf9", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "slc17a2", filterType: "FailedAllFilters", aaLeft: "442", aaRight: "466", ntLeft: "1324", ntRight: "1398" },
  { key: "rasgrp3", filterType: "BestCase", aaLeft: "545", aaRight: "569", ntLeft: "1633", ntRight: "1707" },
  { key: "hcn4", filterType: "BestCase", aaLeft: "1003", aaRight: "1027", ntLeft: "3007", ntRight: "3081" },
  { key: "mrc2", filterType: "BestCase", aaLeft: "953", aaRight: "977", ntLeft: "2857", ntRight: "2931" },
  { key: "prkag2", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "nfatc1", filterType: "BestCase", aaLeft: "806", aaRight: "830", ntLeft: "2416", ntRight: "2490" },
  { key: "pnpla3", filterType: "FailedAllFilters", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "cntn6", filterType: "BestCase", aaLeft: "525", aaRight: "549", ntLeft: "1573", ntRight: "1647" },
  { key: "cd83", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "slc5a5", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "stk36", filterType: "BestCase", aaLeft: "991", aaRight: "1015", ntLeft: "2971", ntRight: "3045" },
  { key: "krtap20-1", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "anxa1", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "gage12g", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "page5", filterType: "FailedAllFilters", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "kiaa1549", filterType: "BestCase", aaLeft: "1428", aaRight: "1452", ntLeft: "4282", ntRight: "4356" },
  { key: "havcr2", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "ubtfl1", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "fkrp", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "ap1m2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "serpinc1", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "mfsd5", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "paqr5", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "zfx", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "emilin1", filterType: "BestCase", aaLeft: "788", aaRight: "812", ntLeft: "2362", ntRight: "2436" },
  { key: "safb", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "prodh", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "dusp18", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "evx1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "apof", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "bmp3", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "hhat", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "glcci1", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "adhfe1", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "awat2", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "arhgdia", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "col4a5", filterType: "BestCase", aaLeft: "429", aaRight: "453", ntLeft: "1285", ntRight: "1359" },
  { key: "ttc14", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "dnah3", filterType: "BestCase", aaLeft: "2485", aaRight: "2509", ntLeft: "7453", ntRight: "7527" },
  { key: "kcnk16", filterType: "BestCase", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "asb12", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tigar", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "stx1a", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "piezo1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "tspyl2", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "spty2d1os", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "hsd17b12", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "srr", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "btn2a2", filterType: "FailedAllFilters", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "alox15b", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "opn3", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "hunk", filterType: "BestCase", aaLeft: "529", aaRight: "553", ntLeft: "1585", ntRight: "1659" },
  { key: "c2orf80", filterType: "FailedAllFilters", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "hmgn3", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "hpcal4", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "ptar1", filterType: "BestCase", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "ufsp2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "nr4a2", filterType: "SecondBest", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "rnf43", filterType: "BestCase", aaLeft: "664", aaRight: "688", ntLeft: "1990", ntRight: "2064" },
  { key: "ms4a14", filterType: "FailedAllFilters", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "grin2a", filterType: "BestCase", aaLeft: "1135", aaRight: "1159", ntLeft: "3403", ntRight: "3477" },
  { key: "ilf2", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "akirin2", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "ttc23", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "gdf1", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "pofut1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "plekha4", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "actn3", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "ufd1", filterType: "SecondBest", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "rassf3", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "lgi1", filterType: "SecondBest", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "pus1", filterType: "BestCase", aaLeft: "359", aaRight: "383", ntLeft: "1075", ntRight: "1149" },
  { key: "cdk5r2", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "trmt5", filterType: "BestCase", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "cwf19l2", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "c14orf132", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "krt75", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "rpgr", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "rpel1", filterType: "FourthBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ube3d", filterType: "FailedAllFilters", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "cd300lg", filterType: "FailedAllFilters", aaLeft: "253", aaRight: "277", ntLeft: "757", ntRight: "831" },
  { key: "cmtm4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "crygs", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "btrc", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "tuba4a", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "or10j1", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "lrrc55", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "bdh1", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "dnajc7", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "tsc22d1", filterType: "FailedAllFilters", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "mep1a", filterType: "BestCase", aaLeft: "286", aaRight: "310", ntLeft: "856", ntRight: "930" },
  { key: "rapgefl1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "kbtbd13", filterType: "BestCase", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "klhdc3", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "tes", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "htr2b", filterType: "BestCase", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "vps13d", filterType: "BestCase", aaLeft: "1049", aaRight: "1073", ntLeft: "3145", ntRight: "3219" },
  { key: "ccdc63", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "polrmt", filterType: "BestCase", aaLeft: "963", aaRight: "987", ntLeft: "2887", ntRight: "2961" },
  { key: "spta1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "diaph2", filterType: "BestCase", aaLeft: "916", aaRight: "940", ntLeft: "2746", ntRight: "2820" },
  { key: "arpc1b", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "kcnj3", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "dkk4", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "avpr2", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "nuf2", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "borcs5", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "gpr143", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "ccdc90b", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "phf20", filterType: "BestCase", aaLeft: "784", aaRight: "808", ntLeft: "2350", ntRight: "2424" },
  { key: "trim62", filterType: "SecondBest", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "tceal3", filterType: "FailedAllFilters", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "chchd10", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "kdm4a", filterType: "BestCase", aaLeft: "486", aaRight: "510", ntLeft: "1456", ntRight: "1530" },
  { key: "bend3", filterType: "FailedAllFilters", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "pc", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tmc3", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "tax1bp3", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nufip1", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "gpr174", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "znf773", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "sdhb", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "trip12", filterType: "BestCase", aaLeft: "1034", aaRight: "1058", ntLeft: "3100", ntRight: "3174" },
  { key: "atxn7", filterType: "BestCase", aaLeft: "748", aaRight: "772", ntLeft: "2242", ntRight: "2316" },
  { key: "trim55", filterType: "BestCase", aaLeft: "506", aaRight: "530", ntLeft: "1516", ntRight: "1590" },
  { key: "prss23", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "krtap5-2", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "nap1l3", filterType: "BestCase", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "renbp", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "ogfod3", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "rarg", filterType: "BestCase", aaLeft: "210", aaRight: "234", ntLeft: "628", ntRight: "702" },
  { key: "slc25a18", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "b4galt7", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "gabre", filterType: "BestCase", aaLeft: "445", aaRight: "469", ntLeft: "1333", ntRight: "1407" },
  { key: "sulf1", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "cep20", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cfap210", filterType: "BestCase", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "eea1", filterType: "BestCase", aaLeft: "1000", aaRight: "1024", ntLeft: "2998", ntRight: "3072" },
  { key: "nln", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "vsig2", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "whamm", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "il36g", filterType: "FailedAllFilters", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "htr1e", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "pemt", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "kat7", filterType: "SecondBest", aaLeft: "433", aaRight: "457", ntLeft: "1297", ntRight: "1371" },
  { key: "loc102723971", filterType: "FailedAllFilters", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "ccdc54", filterType: "FailedAllFilters", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "nebl", filterType: "BestCase", aaLeft: "748", aaRight: "772", ntLeft: "2242", ntRight: "2316" },
  { key: "chst5", filterType: "BestCase", aaLeft: "161", aaRight: "185", ntLeft: "481", ntRight: "555" },
  { key: "trpa1", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "slc30a4", filterType: "BestCase", aaLeft: "237", aaRight: "261", ntLeft: "709", ntRight: "783" },
  { key: "plaat4", filterType: "FailedAllFilters", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "cep128", filterType: "BestCase", aaLeft: "891", aaRight: "915", ntLeft: "2671", ntRight: "2745" },
  { key: "tmem44", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "phka1", filterType: "BestCase", aaLeft: "632", aaRight: "656", ntLeft: "1894", ntRight: "1968" },
  { key: "zbtb8a", filterType: "FailedAllFilters", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "cdh19", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "acadm", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "hsbp1l1", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "zbtb12", filterType: "FailedAllFilters", aaLeft: "230", aaRight: "254", ntLeft: "688", ntRight: "762" },
  { key: "snapc2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "p2ry1", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "aqp7b", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "gtpbp1", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "apoa1", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "nppb", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "qrich2", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ugt2b4", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "scg3", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "fos", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "st3gal4", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "prph", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "rufy3", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "bri3bp", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "gabarap", filterType: "SecondBest", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "lrp11", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "smim15", filterType: "FourthBest", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "klc1", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "ppl", filterType: "BestCase", aaLeft: "1060", aaRight: "1084", ntLeft: "3178", ntRight: "3252" },
  { key: "fam151b", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "chrdl2", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "znf132", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "senp1", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "duoxa2", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "kirrel1", filterType: "BestCase", aaLeft: "655", aaRight: "679", ntLeft: "1963", ntRight: "2037" },
  { key: "rcor1", filterType: "BestCase", aaLeft: "443", aaRight: "467", ntLeft: "1327", ntRight: "1401" },
  { key: "znf804b", filterType: "FailedAllFilters", aaLeft: "1116", aaRight: "1140", ntLeft: "3346", ntRight: "3420" },
  { key: "sox15", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "dph5", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "or9i1", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "fadd", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "ciz1", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "fkbpl", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "gbp4", filterType: "BestCase", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "tmem63b", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "ms4a3", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "zup1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "ddx54", filterType: "BestCase", aaLeft: "650", aaRight: "674", ntLeft: "1948", ntRight: "2022" },
  { key: "elavl3", filterType: "SecondBest", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "or11h2", filterType: "FailedAllFilters", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "ebpl", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "marchf8", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "mmachc", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "ipo9", filterType: "BestCase", aaLeft: "871", aaRight: "895", ntLeft: "2611", ntRight: "2685" },
  { key: "snai3", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "lmx1a", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "dnmt3l", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "ptgdr2", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "unc13b", filterType: "BestCase", aaLeft: "3076", aaRight: "3100", ntLeft: "9226", ntRight: "9300" },
  { key: "cnksr3", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "or2y1", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "neu3", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "prnd", filterType: "FailedAllFilters", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "bpnt1", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "barx2", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "prelp", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "stat1", filterType: "BestCase", aaLeft: "588", aaRight: "612", ntLeft: "1762", ntRight: "1836" },
  { key: "wnt5a", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "smim22", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "cabyr", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "sdhaf3", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "zbtb25", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "ranbp3", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "caprin1", filterType: "BestCase", aaLeft: "577", aaRight: "601", ntLeft: "1729", ntRight: "1803" },
  { key: "map2k7", filterType: "BestCase", aaLeft: "390", aaRight: "414", ntLeft: "1168", ntRight: "1242" },
  { key: "znf667", filterType: "FailedAllFilters", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "gsdme", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "impg2", filterType: "BestCase", aaLeft: "1149", aaRight: "1173", ntLeft: "3445", ntRight: "3519" },
  { key: "atf7ip", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "sppl2c", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "pstpip2", filterType: "BestCase", aaLeft: "189", aaRight: "213", ntLeft: "565", ntRight: "639" },
  { key: "ugt2a3", filterType: "BestCase", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "shmt1", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "znf726", filterType: "FailedAllFilters", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "mlh1", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "nme8", filterType: "FailedAllFilters", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "cdh2", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "gas8", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "rundc3a", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "rab32", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "znf239", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pacrgl", filterType: "FailedAllFilters", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "cstl1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "brd8", filterType: "BestCase", aaLeft: "549", aaRight: "573", ntLeft: "1645", ntRight: "1719" },
  { key: "ttll5", filterType: "BestCase", aaLeft: "1215", aaRight: "1239", ntLeft: "3643", ntRight: "3717" },
  { key: "abi3bp", filterType: "BestCase", aaLeft: "889", aaRight: "913", ntLeft: "2665", ntRight: "2739" },
  { key: "ccdc141", filterType: "BestCase", aaLeft: "1354", aaRight: "1378", ntLeft: "4060", ntRight: "4134" },
  { key: "spata18", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "nhsl1", filterType: "BestCase", aaLeft: "781", aaRight: "805", ntLeft: "2341", ntRight: "2415" },
  { key: "cstb", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "rbm18", filterType: "SecondBest", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "pdia3", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "neurod1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "asphd2", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "plpbp", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "tmem11", filterType: "SecondBest", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "cetn2", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "tert", filterType: "FailedAllFilters", aaLeft: "896", aaRight: "920", ntLeft: "2686", ntRight: "2760" },
  { key: "c1r", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "vps53", filterType: "BestCase", aaLeft: "787", aaRight: "811", ntLeft: "2359", ntRight: "2433" },
  { key: "lsp1", filterType: "BestCase", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "slc8a2", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "ugt1a8", filterType: "BestCase", aaLeft: "479", aaRight: "503", ntLeft: "1435", ntRight: "1509" },
  { key: "tmem26", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "msrb2", filterType: "FailedAllFilters", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "mbd5", filterType: "BestCase", aaLeft: "1389", aaRight: "1413", ntLeft: "4165", ntRight: "4239" },
  { key: "wdr19", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "btaf1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "nrip1", filterType: "BestCase", aaLeft: "986", aaRight: "1010", ntLeft: "2956", ntRight: "3030" },
  { key: "as3mt", filterType: "FailedAllFilters", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "tub", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "mfsd14b", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "etv1", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "araf", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "vil1", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "krtap2-2", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "panx2", filterType: "BestCase", aaLeft: "576", aaRight: "600", ntLeft: "1726", ntRight: "1800" },
  { key: "ganab", filterType: "BestCase", aaLeft: "692", aaRight: "716", ntLeft: "2074", ntRight: "2148" },
  { key: "tmem196", filterType: "SecondBest", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "gpr137b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "ppp2r1b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "pla2g10", filterType: "FailedAllFilters", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "scgb3a2", filterType: "FailedAllFilters", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "cyp4x1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "slc37a4", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "lilrb1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "tex28", filterType: "FailedAllFilters", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "ubxn1", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "znf711", filterType: "BestCase", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "tiam2", filterType: "BestCase", aaLeft: "1536", aaRight: "1560", ntLeft: "4606", ntRight: "4680" },
  { key: "cdk5rap2", filterType: "BestCase", aaLeft: "942", aaRight: "966", ntLeft: "2824", ntRight: "2898" },
  { key: "or6b3", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "prss41", filterType: "FourthBest", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "orc4", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "casq2", filterType: "BestCase", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "fam133a", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "syne4", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "cdk5", filterType: "SecondBest", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "trim37", filterType: "BestCase", aaLeft: "779", aaRight: "803", ntLeft: "2335", ntRight: "2409" },
  { key: "h2ac13", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "cfap74", filterType: "BestCase", aaLeft: "1046", aaRight: "1070", ntLeft: "3136", ntRight: "3210" },
  { key: "xndc1n", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "padi6", filterType: "FailedAllFilters", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "sash1", filterType: "BestCase", aaLeft: "1179", aaRight: "1203", ntLeft: "3535", ntRight: "3609" },
  { key: "lmo4", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ap2a1", filterType: "BestCase", aaLeft: "546", aaRight: "570", ntLeft: "1636", ntRight: "1710" },
  { key: "dync1li1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "smn2", filterType: "BestCase", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "rexo5", filterType: "FailedAllFilters", aaLeft: "691", aaRight: "715", ntLeft: "2071", ntRight: "2145" },
  { key: "nxt1", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "ctbp2", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "tmeff1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "h2ac7", filterType: "SecondBest", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "htatsf1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "srgap3", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "rpl4", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "ly9", filterType: "FailedAllFilters", aaLeft: "180", aaRight: "204", ntLeft: "538", ntRight: "612" },
  { key: "barhl2", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "nkain1", filterType: "SecondBest", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "bnip1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "uqcrhl", filterType: "FailedAllFilters", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "agl", filterType: "BestCase", aaLeft: "434", aaRight: "458", ntLeft: "1300", ntRight: "1374" },
  { key: "ccer2", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "cars1", filterType: "BestCase", aaLeft: "612", aaRight: "636", ntLeft: "1834", ntRight: "1908" },
  { key: "eml1", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "cdy2a", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "ormdl2", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "tnfrsf19", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "snx16", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "atg14", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "mtor", filterType: "BestCase", aaLeft: "1815", aaRight: "1839", ntLeft: "5443", ntRight: "5517" },
  { key: "skap2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "nhlrc3", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "myo9b", filterType: "BestCase", aaLeft: "2111", aaRight: "2135", ntLeft: "6331", ntRight: "6405" },
  { key: "gphn", filterType: "SecondBest", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "nanos1", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "arx", filterType: "SecondBest", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "ptdss2", filterType: "BestCase", aaLeft: "404", aaRight: "428", ntLeft: "1210", ntRight: "1284" },
  { key: "zgpat", filterType: "BestCase", aaLeft: "262", aaRight: "286", ntLeft: "784", ntRight: "858" },
  { key: "efcab8", filterType: "FailedAllFilters", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "cdk12", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "kif17", filterType: "BestCase", aaLeft: "878", aaRight: "902", ntLeft: "2632", ntRight: "2706" },
  { key: "snx21", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "mmadhc", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "ccnb2", filterType: "BestCase", aaLeft: "374", aaRight: "398", ntLeft: "1120", ntRight: "1194" },
  { key: "zbtb47", filterType: "SecondBest", aaLeft: "492", aaRight: "516", ntLeft: "1474", ntRight: "1548" },
  { key: "tmem8b", filterType: "BestCase", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "aff4", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "shq1", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "retn", filterType: "FourthBest", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "vegfd", filterType: "BestCase", aaLeft: "329", aaRight: "353", ntLeft: "985", ntRight: "1059" },
  { key: "znf605", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "rfx3", filterType: "SecondBest", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "ccdc9b", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "ctage1", filterType: "FailedAllFilters", aaLeft: "651", aaRight: "675", ntLeft: "1951", ntRight: "2025" },
  { key: "ubox5", filterType: "BestCase", aaLeft: "361", aaRight: "385", ntLeft: "1081", ntRight: "1155" },
  { key: "eif2ak2", filterType: "FailedAllFilters", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "smim40", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "klf4", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "cebpa", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "rapgef5", filterType: "BestCase", aaLeft: "572", aaRight: "596", ntLeft: "1714", ntRight: "1788" },
  { key: "pias1", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "socs6", filterType: "BestCase", aaLeft: "317", aaRight: "341", ntLeft: "949", ntRight: "1023" },
  { key: "adk", filterType: "BestCase", aaLeft: "270", aaRight: "294", ntLeft: "808", ntRight: "882" },
  { key: "gart", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "rnaseh2c", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "ints13", filterType: "BestCase", aaLeft: "639", aaRight: "663", ntLeft: "1915", ntRight: "1989" },
  { key: "pex10", filterType: "BestCase", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "shtn1", filterType: "BestCase", aaLeft: "596", aaRight: "620", ntLeft: "1786", ntRight: "1860" },
  { key: "fxr2", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "inhba", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "colca2", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "pcsk2", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "decr1", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "col9a2", filterType: "BestCase", aaLeft: "240", aaRight: "264", ntLeft: "718", ntRight: "792" },
  { key: "ythdf2", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "xpnpep1", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "lypd1", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "c15orf62", filterType: "BestCase", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "nsd1", filterType: "BestCase", aaLeft: "797", aaRight: "821", ntLeft: "2389", ntRight: "2463" },
  { key: "cfap43", filterType: "BestCase", aaLeft: "1357", aaRight: "1381", ntLeft: "4069", ntRight: "4143" },
  { key: "cenpp", filterType: "BestCase", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "hsph1", filterType: "BestCase", aaLeft: "503", aaRight: "527", ntLeft: "1507", ntRight: "1581" },
  { key: "dxo", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "ct45a3", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "akap4", filterType: "FailedAllFilters", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "intu", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "fabp5", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "slc6a19", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "banp", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "f11", filterType: "BestCase", aaLeft: "388", aaRight: "412", ntLeft: "1162", ntRight: "1236" },
  { key: "dync1h1", filterType: "BestCase", aaLeft: "4579", aaRight: "4603", ntLeft: "13735", ntRight: "13809" },
  { key: "sptbn5", filterType: "BestCase", aaLeft: "2483", aaRight: "2507", ntLeft: "7447", ntRight: "7521" },
  { key: "pcdhb6", filterType: "FailedAllFilters", aaLeft: "315", aaRight: "339", ntLeft: "943", ntRight: "1017" },
  { key: "krtcap2", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "iqcj", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "rpn2", filterType: "BestCase", aaLeft: "53", aaRight: "77", ntLeft: "157", ntRight: "231" },
  { key: "pdzrn4", filterType: "BestCase", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "cpt1b", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "mcf2l", filterType: "BestCase", aaLeft: "1064", aaRight: "1088", ntLeft: "3190", ntRight: "3264" },
  { key: "myf5", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "wnt2b", filterType: "SecondBest", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "themis2", filterType: "FailedAllFilters", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "slc36a1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "il1rn", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "mmd", filterType: "SecondBest", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "fn3krp", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "lin28a", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "klhdc8b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "scube3", filterType: "BestCase", aaLeft: "455", aaRight: "479", ntLeft: "1363", ntRight: "1437" },
  { key: "epb41l3", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "st14", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "spcs1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "phgdh", filterType: "BestCase", aaLeft: "381", aaRight: "405", ntLeft: "1141", ntRight: "1215" },
  { key: "snx30", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "lix1l", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "ccdc85b", filterType: "BestCase", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "pdcl", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "phkb", filterType: "BestCase", aaLeft: "1028", aaRight: "1052", ntLeft: "3082", ntRight: "3156" },
  { key: "med27", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "gnb2", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "znf614", filterType: "FailedAllFilters", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "eola2", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cfap52", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "cd38", filterType: "FailedAllFilters", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "styk1", filterType: "BestCase", aaLeft: "345", aaRight: "369", ntLeft: "1033", ntRight: "1107" },
  { key: "acat1", filterType: "BestCase", aaLeft: "387", aaRight: "411", ntLeft: "1159", ntRight: "1233" },
  { key: "ddx27", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "znf469", filterType: "FailedAllFilters", aaLeft: "3281", aaRight: "3305", ntLeft: "9841", ntRight: "9915" },
  { key: "pdgfb", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "pth", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "mpo", filterType: "BestCase", aaLeft: "346", aaRight: "370", ntLeft: "1036", ntRight: "1110" },
  { key: "efcab11", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "rasd1", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "rps9", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "cbfa2t2", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "fam156a", filterType: "FailedAllFilters", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "hgs", filterType: "BestCase", aaLeft: "744", aaRight: "768", ntLeft: "2230", ntRight: "2304" },
  { key: "ca10", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "blvrb", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "cacna1d", filterType: "BestCase", aaLeft: "2104", aaRight: "2128", ntLeft: "6310", ntRight: "6384" },
  { key: "prpf40b", filterType: "BestCase", aaLeft: "829", aaRight: "853", ntLeft: "2485", ntRight: "2559" },
  { key: "ugp2", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "spindoc", filterType: "BestCase", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "tmem200c", filterType: "BestCase", aaLeft: "537", aaRight: "561", ntLeft: "1609", ntRight: "1683" },
  { key: "znf141", filterType: "FailedAllFilters", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "rbpj", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "cfap65", filterType: "BestCase", aaLeft: "956", aaRight: "980", ntLeft: "2866", ntRight: "2940" },
  { key: "coasy", filterType: "BestCase", aaLeft: "333", aaRight: "357", ntLeft: "997", ntRight: "1071" },
  { key: "hddc3", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "invs", filterType: "BestCase", aaLeft: "851", aaRight: "875", ntLeft: "2551", ntRight: "2625" },
  { key: "fndc3a", filterType: "BestCase", aaLeft: "694", aaRight: "718", ntLeft: "2080", ntRight: "2154" },
  { key: "hrg", filterType: "FailedAllFilters", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "slc9a7", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "sdf2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lrp4", filterType: "BestCase", aaLeft: "1626", aaRight: "1650", ntLeft: "4876", ntRight: "4950" },
  { key: "eif3g", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "ddx10", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "enam", filterType: "FailedAllFilters", aaLeft: "1107", aaRight: "1131", ntLeft: "3319", ntRight: "3393" },
  { key: "h3-7", filterType: "SecondBest", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ncan", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "fam120c", filterType: "BestCase", aaLeft: "453", aaRight: "477", ntLeft: "1357", ntRight: "1431" },
  { key: "tle4", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "rnf181", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "h4c15", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "suz12", filterType: "BestCase", aaLeft: "694", aaRight: "718", ntLeft: "2080", ntRight: "2154" },
  { key: "aldh3b1", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "lrrc8b", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "aldoa", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "kat6a", filterType: "BestCase", aaLeft: "1190", aaRight: "1214", ntLeft: "3568", ntRight: "3642" },
  { key: "med10", filterType: "SecondBest", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "znf623", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "defb106b", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "pcyox1l", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "odam", filterType: "FailedAllFilters", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "nid2", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "npepl1", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "pcdha1", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "tma16", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "rmi2", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "pick1", filterType: "BestCase", aaLeft: "363", aaRight: "387", ntLeft: "1087", ntRight: "1161" },
  { key: "atp6v0a1", filterType: "BestCase", aaLeft: "703", aaRight: "727", ntLeft: "2107", ntRight: "2181" },
  { key: "dus2", filterType: "BestCase", aaLeft: "460", aaRight: "484", ntLeft: "1378", ntRight: "1452" },
  { key: "afm", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ccdc167", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "tubg1", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "ervfrd-1", filterType: "FailedAllFilters", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "slc35e2b", filterType: "BestCase", aaLeft: "282", aaRight: "306", ntLeft: "844", ntRight: "918" },
  { key: "ccdc27", filterType: "FailedAllFilters", aaLeft: "541", aaRight: "565", ntLeft: "1621", ntRight: "1695" },
  { key: "map4k2", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "arhgef17", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "yod1", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "mecp2", filterType: "BestCase", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "tmem187", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "cetp", filterType: "FailedAllFilters", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "large1", filterType: "BestCase", aaLeft: "628", aaRight: "652", ntLeft: "1882", ntRight: "1956" },
  { key: "myo10", filterType: "BestCase", aaLeft: "1041", aaRight: "1065", ntLeft: "3121", ntRight: "3195" },
  { key: "tmem37", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "hcrtr1", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "frat1", filterType: "FailedAllFilters", aaLeft: "142", aaRight: "166", ntLeft: "424", ntRight: "498" },
  { key: "sntb2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "cxcl1", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "ostn", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "znf700", filterType: "FailedAllFilters", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "gpr107", filterType: "BestCase", aaLeft: "221", aaRight: "245", ntLeft: "661", ntRight: "735" },
  { key: "casp3", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "thnsl2", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "tg", filterType: "BestCase", aaLeft: "501", aaRight: "525", ntLeft: "1501", ntRight: "1575" },
  { key: "tbcd", filterType: "BestCase", aaLeft: "833", aaRight: "857", ntLeft: "2497", ntRight: "2571" },
  { key: "prss50", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "or6c2", filterType: "BestCase", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "armh4", filterType: "FailedAllFilters", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "efr3a", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "trim26", filterType: "FailedAllFilters", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "atp6v1c2", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "bap1", filterType: "BestCase", aaLeft: "311", aaRight: "335", ntLeft: "931", ntRight: "1005" },
  { key: "rab14", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "eef2", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "otog", filterType: "BestCase", aaLeft: "1324", aaRight: "1348", ntLeft: "3970", ntRight: "4044" },
  { key: "ctrb2", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "zfyve26", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "actl6a", filterType: "SecondBest", aaLeft: "398", aaRight: "422", ntLeft: "1192", ntRight: "1266" },
  { key: "rab23", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "loxl1", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "znf737", filterType: "FailedAllFilters", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "ctsa", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "cass4", filterType: "FailedAllFilters", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "kdm3b", filterType: "BestCase", aaLeft: "1167", aaRight: "1191", ntLeft: "3499", ntRight: "3573" },
  { key: "cntln", filterType: "BestCase", aaLeft: "1158", aaRight: "1182", ntLeft: "3472", ntRight: "3546" },
  { key: "fetub", filterType: "FailedAllFilters", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "trim11", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "phb1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "smyd2", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "lrrc26", filterType: "FailedAllFilters", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "rtp5", filterType: "FailedAllFilters", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "fdxacb1", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "gak", filterType: "BestCase", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "clec9a", filterType: "FailedAllFilters", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "cux1", filterType: "BestCase", aaLeft: "832", aaRight: "856", ntLeft: "2494", ntRight: "2568" },
  { key: "rit2", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "siglec10", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "nrg3", filterType: "BestCase", aaLeft: "441", aaRight: "465", ntLeft: "1321", ntRight: "1395" },
  { key: "rps6ka3", filterType: "BestCase", aaLeft: "231", aaRight: "255", ntLeft: "691", ntRight: "765" },
  { key: "cox19", filterType: "FourthBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "metrn", filterType: "FourthBest", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "ccdc150", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "guca2b", filterType: "FailedAllFilters", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pnoc", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "rmdn3", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "tfip11", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "krtap16-1", filterType: "BestCase", aaLeft: "367", aaRight: "391", ntLeft: "1099", ntRight: "1173" },
  { key: "myof", filterType: "BestCase", aaLeft: "1238", aaRight: "1262", ntLeft: "3712", ntRight: "3786" },
  { key: "zzef1", filterType: "BestCase", aaLeft: "1494", aaRight: "1518", ntLeft: "4480", ntRight: "4554" },
  { key: "or52m1", filterType: "FailedAllFilters", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "nefh", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "tbp", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "htr5a", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "txndc5", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "itga8", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "ssu72", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "dgkh", filterType: "BestCase", aaLeft: "680", aaRight: "704", ntLeft: "2038", ntRight: "2112" },
  { key: "slc26a2", filterType: "BestCase", aaLeft: "607", aaRight: "631", ntLeft: "1819", ntRight: "1893" },
  { key: "ldb1", filterType: "SecondBest", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "rpl15", filterType: "FourthBest", aaLeft: "168", aaRight: "192", ntLeft: "502", ntRight: "576" },
  { key: "wars2", filterType: "BestCase", aaLeft: "10", aaRight: "34", ntLeft: "28", ntRight: "102" },
  { key: "dctn4", filterType: "BestCase", aaLeft: "342", aaRight: "366", ntLeft: "1024", ntRight: "1098" },
  { key: "sstr1", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "gkap1", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "lsm11", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "hsfx2", filterType: "FailedAllFilters", aaLeft: "257", aaRight: "281", ntLeft: "769", ntRight: "843" },
  { key: "bach1", filterType: "BestCase", aaLeft: "672", aaRight: "696", ntLeft: "2014", ntRight: "2088" },
  { key: "c5orf15", filterType: "FailedAllFilters", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "gtf3c3", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "sap30", filterType: "SecondBest", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "slc31a1", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "knop1", filterType: "FailedAllFilters", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "ptcra", filterType: "BestCase", aaLeft: "37", aaRight: "61", ntLeft: "109", ntRight: "183" },
  { key: "ccne1", filterType: "FailedAllFilters", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "pttg1ip", filterType: "FailedAllFilters", aaLeft: "58", aaRight: "82", ntLeft: "172", ntRight: "246" },
  { key: "zbtb34", filterType: "BestCase", aaLeft: "465", aaRight: "489", ntLeft: "1393", ntRight: "1467" },
  { key: "cxcl12", filterType: "SecondBest", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "krtap4-7", filterType: "FailedAllFilters", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "izumo4", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "or7d4", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "peak3", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "znf676", filterType: "FailedAllFilters", aaLeft: "200", aaRight: "224", ntLeft: "598", ntRight: "672" },
  { key: "rnase10", filterType: "FailedAllFilters", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "cep250", filterType: "BestCase", aaLeft: "1848", aaRight: "1872", ntLeft: "5542", ntRight: "5616" },
  { key: "il27", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "tmem14a", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "slc2a14", filterType: "BestCase", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "kctd16", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "krtap5-10", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "c6orf118", filterType: "FailedAllFilters", aaLeft: "87", aaRight: "111", ntLeft: "259", ntRight: "333" },
  { key: "arl1", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "smim33", filterType: "FailedAllFilters", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "zkscan8", filterType: "BestCase", aaLeft: "279", aaRight: "303", ntLeft: "835", ntRight: "909" },
  { key: "il10", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "c2cd5", filterType: "BestCase", aaLeft: "437", aaRight: "461", ntLeft: "1309", ntRight: "1383" },
  { key: "fig4", filterType: "BestCase", aaLeft: "873", aaRight: "897", ntLeft: "2617", ntRight: "2691" },
  { key: "rps18", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "kctd21", filterType: "SecondBest", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "lztfl1", filterType: "FailedAllFilters", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "cept1", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "tapbp", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "palld", filterType: "FailedAllFilters", aaLeft: "1077", aaRight: "1101", ntLeft: "3229", ntRight: "3303" },
  { key: "dhx58", filterType: "BestCase", aaLeft: "527", aaRight: "551", ntLeft: "1579", ntRight: "1653" },
  { key: "fam83f", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "psg2", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "ifih1", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "prdm9", filterType: "BestCase", aaLeft: "708", aaRight: "732", ntLeft: "2122", ntRight: "2196" },
  { key: "or7g3", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "fam126a", filterType: "BestCase", aaLeft: "452", aaRight: "476", ntLeft: "1354", ntRight: "1428" },
  { key: "calu", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "brap", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "pgbd1", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "plod2", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "znf641", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "gng7", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "znf79", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "washc3", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "gp6", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "wfikkn2", filterType: "BestCase", aaLeft: "306", aaRight: "330", ntLeft: "916", ntRight: "990" },
  { key: "gfra2", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "c5orf22", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "tas2r39", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "pmaip1", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "nlrp3", filterType: "BestCase", aaLeft: "530", aaRight: "554", ntLeft: "1588", ntRight: "1662" },
  { key: "ppef1", filterType: "FailedAllFilters", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "c1qtnf5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "psmb9", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "prrg2", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "sft2d1", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "vgll4", filterType: "BestCase", aaLeft: "111", aaRight: "135", ntLeft: "331", ntRight: "405" },
  { key: "m6pr", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "rasa4", filterType: "BestCase", aaLeft: "215", aaRight: "239", ntLeft: "643", ntRight: "717" },
  { key: "lpar2", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "rpl22", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lars1", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "ikbke", filterType: "BestCase", aaLeft: "477", aaRight: "501", ntLeft: "1429", ntRight: "1503" },
  { key: "tfr2", filterType: "BestCase", aaLeft: "742", aaRight: "766", ntLeft: "2224", ntRight: "2298" },
  { key: "ecrg4", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "sptssa", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "myh10", filterType: "BestCase", aaLeft: "1420", aaRight: "1444", ntLeft: "4258", ntRight: "4332" },
  { key: "anks1b", filterType: "BestCase", aaLeft: "626", aaRight: "650", ntLeft: "1876", ntRight: "1950" },
  { key: "kcnh5", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "thoc3", filterType: "BestCase", aaLeft: "129", aaRight: "153", ntLeft: "385", ntRight: "459" },
  { key: "pebp1", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "trim44", filterType: "FailedAllFilters", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "chd8", filterType: "BestCase", aaLeft: "55", aaRight: "79", ntLeft: "163", ntRight: "237" },
  { key: "hyal3", filterType: "BestCase", aaLeft: "99", aaRight: "123", ntLeft: "295", ntRight: "369" },
  { key: "map3k2", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "frg2", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "zcchc18", filterType: "BestCase", aaLeft: "184", aaRight: "208", ntLeft: "550", ntRight: "624" },
  { key: "sarm1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "klrc1", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "wnk1", filterType: "BestCase", aaLeft: "1915", aaRight: "1939", ntLeft: "5743", ntRight: "5817" },
  { key: "numa1", filterType: "BestCase", aaLeft: "654", aaRight: "678", ntLeft: "1960", ntRight: "2034" },
  { key: "nat9", filterType: "BestCase", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "lrrc73", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "dnaja1", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "ltbp2", filterType: "BestCase", aaLeft: "1402", aaRight: "1426", ntLeft: "4204", ntRight: "4278" },
  { key: "ifng", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "ebf2", filterType: "BestCase", aaLeft: "462", aaRight: "486", ntLeft: "1384", ntRight: "1458" },
  { key: "or8j3", filterType: "FailedAllFilters", aaLeft: "245", aaRight: "269", ntLeft: "733", ntRight: "807" },
  { key: "cap2", filterType: "BestCase", aaLeft: "302", aaRight: "326", ntLeft: "904", ntRight: "978" },
  { key: "igsf23", filterType: "FailedAllFilters", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "ccdc105", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "mfsd13a", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "yipf2", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "ap3d1", filterType: "BestCase", aaLeft: "1012", aaRight: "1036", ntLeft: "3034", ntRight: "3108" },
  { key: "htr3c", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "znf528", filterType: "FailedAllFilters", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "mall", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "ephx1", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "trpc7", filterType: "BestCase", aaLeft: "750", aaRight: "774", ntLeft: "2248", ntRight: "2322" },
  { key: "babam1", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "tmem62", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "dnajb6", filterType: "FourthBest", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "ndst3", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "agmat", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "sit1", filterType: "BestCase", aaLeft: "46", aaRight: "70", ntLeft: "136", ntRight: "210" },
  { key: "frmd1", filterType: "FailedAllFilters", aaLeft: "176", aaRight: "200", ntLeft: "526", ntRight: "600" },
  { key: "hmmr", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "gpr152", filterType: "FailedAllFilters", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "tox4", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "krtap11-1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "gon4l", filterType: "BestCase", aaLeft: "2041", aaRight: "2065", ntLeft: "6121", ntRight: "6195" },
  { key: "piwil1", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zeb1", filterType: "BestCase", aaLeft: "555", aaRight: "579", ntLeft: "1663", ntRight: "1737" },
  { key: "trim73", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "sync", filterType: "FailedAllFilters", aaLeft: "276", aaRight: "300", ntLeft: "826", ntRight: "900" },
  { key: "cdo1", filterType: "BestCase", aaLeft: "13", aaRight: "37", ntLeft: "37", ntRight: "111" },
  { key: "gpx2", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "scn2a", filterType: "BestCase", aaLeft: "294", aaRight: "318", ntLeft: "880", ntRight: "954" },
  { key: "larp6", filterType: "FailedAllFilters", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "rab41", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "cct8", filterType: "BestCase", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "lrrn1", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "kcnk2", filterType: "BestCase", aaLeft: "232", aaRight: "256", ntLeft: "694", ntRight: "768" },
  { key: "bco2", filterType: "BestCase", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "gja10", filterType: "FailedAllFilters", aaLeft: "339", aaRight: "363", ntLeft: "1015", ntRight: "1089" },
  { key: "supt7l", filterType: "BestCase", aaLeft: "106", aaRight: "130", ntLeft: "316", ntRight: "390" },
  { key: "uts2r", filterType: "BestCase", aaLeft: "229", aaRight: "253", ntLeft: "685", ntRight: "759" },
  { key: "pop5", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "cerkl", filterType: "BestCase", aaLeft: "64", aaRight: "88", ntLeft: "190", ntRight: "264" },
  { key: "gpr52", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "pcdh10", filterType: "BestCase", aaLeft: "745", aaRight: "769", ntLeft: "2233", ntRight: "2307" },
  { key: "lmbr1l", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "slc16a7", filterType: "BestCase", aaLeft: "318", aaRight: "342", ntLeft: "952", ntRight: "1026" },
  { key: "rnf6", filterType: "FailedAllFilters", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "ttc32", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "tead3", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "sigirr", filterType: "BestCase", aaLeft: "358", aaRight: "382", ntLeft: "1072", ntRight: "1146" },
  { key: "lig3", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "b3gnt2", filterType: "FailedAllFilters", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "dock9", filterType: "BestCase", aaLeft: "1855", aaRight: "1879", ntLeft: "5563", ntRight: "5637" },
  { key: "or4n5", filterType: "FailedAllFilters", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "dhdds", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "akain1", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "pggt1b", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fgf20", filterType: "BestCase", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "ldlr", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "sned1", filterType: "BestCase", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "tpt1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "nts", filterType: "FourthBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "lingo4", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "myo18b", filterType: "BestCase", aaLeft: "2453", aaRight: "2477", ntLeft: "7357", ntRight: "7431" },
  { key: "cacng6", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "mapk9", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "gabra5", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "cul7", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "tgfbi", filterType: "BestCase", aaLeft: "532", aaRight: "556", ntLeft: "1594", ntRight: "1668" },
  { key: "zswim8", filterType: "BestCase", aaLeft: "681", aaRight: "705", ntLeft: "2041", ntRight: "2115" },
  { key: "stard4", filterType: "BestCase", aaLeft: "117", aaRight: "141", ntLeft: "349", ntRight: "423" },
  { key: "wfdc9", filterType: "FailedAllFilters", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "mapk1ip1l", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "serpina7", filterType: "ThirdBest", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "ky", filterType: "BestCase", aaLeft: "500", aaRight: "524", ntLeft: "1498", ntRight: "1572" },
  { key: "cryab", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "prr11", filterType: "FourthBest", aaLeft: "331", aaRight: "355", ntLeft: "991", ntRight: "1065" },
  { key: "uqcrh", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "zdhhc5", filterType: "BestCase", aaLeft: "625", aaRight: "649", ntLeft: "1873", ntRight: "1947" },
  { key: "izumo1r", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "mia2", filterType: "BestCase", aaLeft: "1314", aaRight: "1338", ntLeft: "3940", ntRight: "4014" },
  { key: "icam3", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "garin2", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "mageh1", filterType: "FailedAllFilters", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "hsp90b1", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "ackr4", filterType: "BestCase", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "rbp4", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "grb14", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "mtf2", filterType: "BestCase", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "znf282", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "scnm1", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "cartpt", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "dyrk1a", filterType: "SecondBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "or5as1", filterType: "FailedAllFilters", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "ric3", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "gabrb2", filterType: "SecondBest", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "sbspon", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "pcdh1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "noc2l", filterType: "BestCase", aaLeft: "581", aaRight: "605", ntLeft: "1741", ntRight: "1815" },
  { key: "dhrs4l2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "nrp2", filterType: "BestCase", aaLeft: "448", aaRight: "472", ntLeft: "1342", ntRight: "1416" },
  { key: "mroh8", filterType: "BestCase", aaLeft: "366", aaRight: "390", ntLeft: "1096", ntRight: "1170" },
  { key: "ell", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "fthl17", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "or4m2", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fgf17", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "or10g8", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "foxo1", filterType: "BestCase", aaLeft: "538", aaRight: "562", ntLeft: "1612", ntRight: "1686" },
  { key: "cryba1", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "gpr65", filterType: "FailedAllFilters", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "fam185a", filterType: "BestCase", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "bambi", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ppm1d", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "zfpm2", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "ppp5c", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "znf33b", filterType: "FailedAllFilters", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "cwc27", filterType: "BestCase", aaLeft: "336", aaRight: "360", ntLeft: "1006", ntRight: "1080" },
  { key: "shank3", filterType: "BestCase", aaLeft: "481", aaRight: "505", ntLeft: "1441", ntRight: "1515" },
  { key: "dnai2", filterType: "BestCase", aaLeft: "297", aaRight: "321", ntLeft: "889", ntRight: "963" },
  { key: "smim10l2b", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "tecta", filterType: "BestCase", aaLeft: "2128", aaRight: "2152", ntLeft: "6382", ntRight: "6456" },
  { key: "or51b5", filterType: "BestCase", aaLeft: "198", aaRight: "222", ntLeft: "592", ntRight: "666" },
  { key: "tti1", filterType: "BestCase", aaLeft: "571", aaRight: "595", ntLeft: "1711", ntRight: "1785" },
  { key: "cnnm2", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "tmem244", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ndrg3", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "adam32", filterType: "FailedAllFilters", aaLeft: "238", aaRight: "262", ntLeft: "712", ntRight: "786" },
  { key: "poldip2", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "cks2", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "polr2i", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "akna", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "aldh1l1", filterType: "BestCase", aaLeft: "756", aaRight: "780", ntLeft: "2266", ntRight: "2340" },
  { key: "stum", filterType: "SecondBest", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "mrps14", filterType: "SecondBest", aaLeft: "103", aaRight: "127", ntLeft: "307", ntRight: "381" },
  { key: "ccnt2", filterType: "BestCase", aaLeft: "244", aaRight: "268", ntLeft: "730", ntRight: "804" },
  { key: "itprip", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "sypl1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "p3r3urf", filterType: "BestCase", aaLeft: "35", aaRight: "59", ntLeft: "103", ntRight: "177" },
  { key: "sgcb", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "ydjc", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "or2t8", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "selp", filterType: "FailedAllFilters", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "chm", filterType: "BestCase", aaLeft: "326", aaRight: "350", ntLeft: "976", ntRight: "1050" },
  { key: "irx6", filterType: "BestCase", aaLeft: "325", aaRight: "349", ntLeft: "973", ntRight: "1047" },
  { key: "dpep2", filterType: "BestCase", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "ciao2b", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "tbx20", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "clcn4", filterType: "BestCase", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "atoh1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "fmnl1", filterType: "BestCase", aaLeft: "1014", aaRight: "1038", ntLeft: "3040", ntRight: "3114" },
  { key: "serpina10", filterType: "FailedAllFilters", aaLeft: "394", aaRight: "418", ntLeft: "1180", ntRight: "1254" },
  { key: "kcnj13", filterType: "BestCase", aaLeft: "170", aaRight: "194", ntLeft: "508", ntRight: "582" },
  { key: "fam241b", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "parp12", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "srsf12", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "myb", filterType: "BestCase", aaLeft: "449", aaRight: "473", ntLeft: "1345", ntRight: "1419" },
  { key: "cyth2", filterType: "SecondBest", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "tlr5", filterType: "FailedAllFilters", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "bcl11a", filterType: "SecondBest", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "faim", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "mrps23", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "ppox", filterType: "BestCase", aaLeft: "250", aaRight: "274", ntLeft: "748", ntRight: "822" },
  { key: "stk10", filterType: "BestCase", aaLeft: "707", aaRight: "731", ntLeft: "2119", ntRight: "2193" },
  { key: "fcsk", filterType: "BestCase", aaLeft: "759", aaRight: "783", ntLeft: "2275", ntRight: "2349" },
  { key: "rsph14", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "rxfp1", filterType: "FailedAllFilters", aaLeft: "596", aaRight: "620", ntLeft: "1786", ntRight: "1860" },
  { key: "nup35", filterType: "BestCase", aaLeft: "233", aaRight: "257", ntLeft: "697", ntRight: "771" },
  { key: "or13a1", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "pkn2", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "sppl3", filterType: "SecondBest", aaLeft: "284", aaRight: "308", ntLeft: "850", ntRight: "924" },
  { key: "znf689", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "sipa1l2", filterType: "BestCase", aaLeft: "1360", aaRight: "1384", ntLeft: "4078", ntRight: "4152" },
  { key: "il21r", filterType: "BestCase", aaLeft: "456", aaRight: "480", ntLeft: "1366", ntRight: "1440" },
  { key: "fhip1b", filterType: "BestCase", aaLeft: "619", aaRight: "643", ntLeft: "1855", ntRight: "1929" },
  { key: "mrpl19", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "rubcn", filterType: "BestCase", aaLeft: "256", aaRight: "280", ntLeft: "766", ntRight: "840" },
  { key: "tmem161b", filterType: "BestCase", aaLeft: "405", aaRight: "429", ntLeft: "1213", ntRight: "1287" },
  { key: "adgrg1", filterType: "BestCase", aaLeft: "261", aaRight: "285", ntLeft: "781", ntRight: "855" },
  { key: "cenpc", filterType: "FailedAllFilters", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "znf396", filterType: "FailedAllFilters", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "hpr", filterType: "FailedAllFilters", aaLeft: "248", aaRight: "272", ntLeft: "742", ntRight: "816" },
  { key: "e2f5", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "c1orf210", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "or51a2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "rrp15", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "atad5", filterType: "BestCase", aaLeft: "1091", aaRight: "1115", ntLeft: "3271", ntRight: "3345" },
  { key: "tmem273", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "pip5k1c", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "arhgef5", filterType: "BestCase", aaLeft: "1112", aaRight: "1136", ntLeft: "3334", ntRight: "3408" },
  { key: "pdzd9", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ripk4", filterType: "BestCase", aaLeft: "756", aaRight: "780", ntLeft: "2266", ntRight: "2340" },
  { key: "krt8", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "edem2", filterType: "BestCase", aaLeft: "534", aaRight: "558", ntLeft: "1600", ntRight: "1674" },
  { key: "bcar1", filterType: "BestCase", aaLeft: "489", aaRight: "513", ntLeft: "1465", ntRight: "1539" },
  { key: "man1a2", filterType: "BestCase", aaLeft: "144", aaRight: "168", ntLeft: "430", ntRight: "504" },
  { key: "osmr", filterType: "BestCase", aaLeft: "114", aaRight: "138", ntLeft: "340", ntRight: "414" },
  { key: "nfx1", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "fam53a", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "manea", filterType: "BestCase", aaLeft: "52", aaRight: "76", ntLeft: "154", ntRight: "228" },
  { key: "chn2", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "lpin1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "cabp2", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "tac1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "samd1", filterType: "BestCase", aaLeft: "255", aaRight: "279", ntLeft: "763", ntRight: "837" },
  { key: "jmjd4", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "zic2", filterType: "SecondBest", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "dlx1", filterType: "SecondBest", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ubtf", filterType: "BestCase", aaLeft: "674", aaRight: "698", ntLeft: "2020", ntRight: "2094" },
  { key: "spin2a", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "cyp51a1", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "txk", filterType: "FailedAllFilters", aaLeft: "474", aaRight: "498", ntLeft: "1420", ntRight: "1494" },
  { key: "ikbip", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "jmjd1c", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "galnt18", filterType: "BestCase", aaLeft: "413", aaRight: "437", ntLeft: "1237", ntRight: "1311" },
  { key: "rnf217", filterType: "SecondBest", aaLeft: "357", aaRight: "381", ntLeft: "1069", ntRight: "1143" },
  { key: "maea", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "traf3", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "farp1", filterType: "BestCase", aaLeft: "640", aaRight: "664", ntLeft: "1918", ntRight: "1992" },
  { key: "nudt16", filterType: "BestCase", aaLeft: "63", aaRight: "87", ntLeft: "187", ntRight: "261" },
  { key: "fam25c", filterType: "FailedAllFilters", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "c17orf78", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "clec16a", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "tfe3", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "ndufb3", filterType: "BestCase", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "bok", filterType: "BestCase", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "bank1", filterType: "BestCase", aaLeft: "372", aaRight: "396", ntLeft: "1114", ntRight: "1188" },
  { key: "kpna3", filterType: "SecondBest", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "celf3", filterType: "BestCase", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "rhpn2", filterType: "BestCase", aaLeft: "351", aaRight: "375", ntLeft: "1051", ntRight: "1125" },
  { key: "crybg3", filterType: "FailedAllFilters", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "ndufa4", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "snx9", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mycn", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "aldh9a1", filterType: "BestCase", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "znf7", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "tspan9", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "alg2", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "cnot8", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "ccdc198", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "jag1", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "pnck", filterType: "FailedAllFilters", aaLeft: "314", aaRight: "338", ntLeft: "940", ntRight: "1014" },
  { key: "rusc1", filterType: "BestCase", aaLeft: "698", aaRight: "722", ntLeft: "2092", ntRight: "2166" },
  { key: "robo1", filterType: "BestCase", aaLeft: "31", aaRight: "55", ntLeft: "91", ntRight: "165" },
  { key: "rnf220", filterType: "SecondBest", aaLeft: "327", aaRight: "351", ntLeft: "979", ntRight: "1053" },
  { key: "acsl5", filterType: "BestCase", aaLeft: "560", aaRight: "584", ntLeft: "1678", ntRight: "1752" },
  { key: "maff", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf582", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "foxi3", filterType: "FailedAllFilters", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "nudt21", filterType: "BestCase", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "mettl16", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "krt31", filterType: "BestCase", aaLeft: "307", aaRight: "331", ntLeft: "919", ntRight: "993" },
  { key: "gfral", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "enpp4", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "atp6ap2", filterType: "BestCase", aaLeft: "288", aaRight: "312", ntLeft: "862", ntRight: "936" },
  { key: "ppp1r14b", filterType: "BestCase", aaLeft: "42", aaRight: "66", ntLeft: "124", ntRight: "198" },
  { key: "cacnb3", filterType: "BestCase", aaLeft: "364", aaRight: "388", ntLeft: "1090", ntRight: "1164" },
  { key: "tfg", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "ppp3ca", filterType: "SecondBest", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "hirip3", filterType: "FailedAllFilters", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "naa11", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "ldah", filterType: "BestCase", aaLeft: "140", aaRight: "164", ntLeft: "418", ntRight: "492" },
  { key: "lrch2", filterType: "BestCase", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "cyb561", filterType: "BestCase", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "tbc1d3c", filterType: "FailedAllFilters", aaLeft: "391", aaRight: "415", ntLeft: "1171", ntRight: "1245" },
  { key: "has1", filterType: "BestCase", aaLeft: "514", aaRight: "538", ntLeft: "1540", ntRight: "1614" },
  { key: "prickle3", filterType: "BestCase", aaLeft: "568", aaRight: "592", ntLeft: "1702", ntRight: "1776" },
  { key: "odad3", filterType: "BestCase", aaLeft: "175", aaRight: "199", ntLeft: "523", ntRight: "597" },
  { key: "pcdhgb7", filterType: "BestCase", aaLeft: "120", aaRight: "144", ntLeft: "358", ntRight: "432" },
  { key: "gas2l2", filterType: "BestCase", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "stab1", filterType: "BestCase", aaLeft: "803", aaRight: "827", ntLeft: "2407", ntRight: "2481" },
  { key: "pex11b", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "dnajc10", filterType: "BestCase", aaLeft: "669", aaRight: "693", ntLeft: "2005", ntRight: "2079" },
  { key: "jchain", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "sh2d3c", filterType: "BestCase", aaLeft: "454", aaRight: "478", ntLeft: "1360", ntRight: "1434" },
  { key: "parp8", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "yju2b", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "osr2", filterType: "BestCase", aaLeft: "125", aaRight: "149", ntLeft: "373", ntRight: "447" },
  { key: "hdhd2", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "aire", filterType: "BestCase", aaLeft: "216", aaRight: "240", ntLeft: "646", ntRight: "720" },
  { key: "pfkfb4", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "pdpk1", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "plce1", filterType: "BestCase", aaLeft: "428", aaRight: "452", ntLeft: "1282", ntRight: "1356" },
  { key: "lcn9", filterType: "FailedAllFilters", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "cldn4", filterType: "BestCase", aaLeft: "84", aaRight: "108", ntLeft: "250", ntRight: "324" },
  { key: "tbx3", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "frk", filterType: "BestCase", aaLeft: "211", aaRight: "235", ntLeft: "631", ntRight: "705" },
  { key: "cluap1", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "meis2", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "cfp", filterType: "BestCase", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "septin12", filterType: "BestCase", aaLeft: "74", aaRight: "98", ntLeft: "220", ntRight: "294" },
  { key: "cngb1", filterType: "BestCase", aaLeft: "599", aaRight: "623", ntLeft: "1795", ntRight: "1869" },
  { key: "helz", filterType: "BestCase", aaLeft: "1512", aaRight: "1536", ntLeft: "4534", ntRight: "4608" },
  { key: "mal", filterType: "BestCase", aaLeft: "110", aaRight: "134", ntLeft: "328", ntRight: "402" },
  { key: "apela", filterType: "FailedAllFilters", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "mtch1", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "anos1", filterType: "BestCase", aaLeft: "558", aaRight: "582", ntLeft: "1672", ntRight: "1746" },
  { key: "rint1", filterType: "BestCase", aaLeft: "260", aaRight: "284", ntLeft: "778", ntRight: "852" },
  { key: "cpamd8", filterType: "BestCase", aaLeft: "1222", aaRight: "1246", ntLeft: "3664", ntRight: "3738" },
  { key: "lgals16", filterType: "FailedAllFilters", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "mocs2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "cask", filterType: "SecondBest", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "or4f17", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "ankrd18a", filterType: "FailedAllFilters", aaLeft: "512", aaRight: "536", ntLeft: "1534", ntRight: "1608" },
  { key: "c16orf91", filterType: "FailedAllFilters", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "adgra3", filterType: "BestCase", aaLeft: "1252", aaRight: "1276", ntLeft: "3754", ntRight: "3828" },
  { key: "lyrm2", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "mal2", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "paxx", filterType: "BestCase", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "gcsh", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "rnf149", filterType: "BestCase", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "drd3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "srsf4", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "znf496", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "itpa", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "peg3", filterType: "FailedAllFilters", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "chga", filterType: "BestCase", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "tmed9", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "setd4", filterType: "BestCase", aaLeft: "369", aaRight: "393", ntLeft: "1105", ntRight: "1179" },
  { key: "serinc4", filterType: "BestCase", aaLeft: "476", aaRight: "500", ntLeft: "1426", ntRight: "1500" },
  { key: "tp53", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "topaz1", filterType: "FailedAllFilters", aaLeft: "1307", aaRight: "1331", ntLeft: "3919", ntRight: "3993" },
  { key: "znf189", filterType: "BestCase", aaLeft: "425", aaRight: "449", ntLeft: "1273", ntRight: "1347" },
  { key: "ranbp17", filterType: "BestCase", aaLeft: "740", aaRight: "764", ntLeft: "2218", ntRight: "2292" },
  { key: "kank4", filterType: "FailedAllFilters", aaLeft: "800", aaRight: "824", ntLeft: "2398", ntRight: "2472" },
  { key: "ppp1r3a", filterType: "FailedAllFilters", aaLeft: "663", aaRight: "687", ntLeft: "1987", ntRight: "2061" },
  { key: "dnajc27", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "myt1", filterType: "BestCase", aaLeft: "678", aaRight: "702", ntLeft: "2032", ntRight: "2106" },
  { key: "herc1", filterType: "BestCase", aaLeft: "3647", aaRight: "3671", ntLeft: "10939", ntRight: "11013" },
  { key: "ankrd61", filterType: "FailedAllFilters", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "tex12", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "or8s1", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "lsm7", filterType: "SecondBest", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "nme5", filterType: "BestCase", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "sart3", filterType: "BestCase", aaLeft: "936", aaRight: "960", ntLeft: "2806", ntRight: "2880" },
  { key: "ephb4", filterType: "BestCase", aaLeft: "636", aaRight: "660", ntLeft: "1906", ntRight: "1980" },
  { key: "slc25a40", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "pcm1", filterType: "BestCase", aaLeft: "1231", aaRight: "1255", ntLeft: "3691", ntRight: "3765" },
  { key: "znf234", filterType: "FailedAllFilters", aaLeft: "179", aaRight: "203", ntLeft: "535", ntRight: "609" },
  { key: "sephs2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "cep78", filterType: "BestCase", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "ufm1", filterType: "SecondBest", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ntm", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dnajc5g", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "ccdc28a", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "ttll8", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "nxpe4", filterType: "BestCase", aaLeft: "222", aaRight: "246", ntLeft: "664", ntRight: "738" },
  { key: "slc35e3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "fahd2a", filterType: "BestCase", aaLeft: "43", aaRight: "67", ntLeft: "127", ntRight: "201" },
  { key: "pbov1", filterType: "FailedAllFilters", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "clec18b", filterType: "FailedAllFilters", aaLeft: "115", aaRight: "139", ntLeft: "343", ntRight: "417" },
  { key: "shisa6", filterType: "BestCase", aaLeft: "421", aaRight: "445", ntLeft: "1261", ntRight: "1335" },
  { key: "rbm15", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "agpat2", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "spock2", filterType: "BestCase", aaLeft: "123", aaRight: "147", ntLeft: "367", ntRight: "441" },
  { key: "cr1l", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "pclaf", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "elp3", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "krtap13-2", filterType: "FailedAllFilters", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "cep170", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "pdk3", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "tob1", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "hmces", filterType: "FourthBest", aaLeft: "153", aaRight: "177", ntLeft: "457", ntRight: "531" },
  { key: "or6c70", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "akr1b1", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "olig1", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "rhce", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "pcsk1n", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "vapa", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "myocd", filterType: "BestCase", aaLeft: "172", aaRight: "196", ntLeft: "514", ntRight: "588" },
  { key: "ugt1a5", filterType: "BestCase", aaLeft: "483", aaRight: "507", ntLeft: "1447", ntRight: "1521" },
  { key: "cd276", filterType: "BestCase", aaLeft: "459", aaRight: "483", ntLeft: "1375", ntRight: "1449" },
  { key: "znf561", filterType: "FailedAllFilters", aaLeft: "373", aaRight: "397", ntLeft: "1117", ntRight: "1191" },
  { key: "cdv3", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "htr7", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "spa17", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "arl10", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "slc16a12", filterType: "BestCase", aaLeft: "224", aaRight: "248", ntLeft: "670", ntRight: "744" },
  { key: "stk11ip", filterType: "BestCase", aaLeft: "882", aaRight: "906", ntLeft: "2644", ntRight: "2718" },
  { key: "spata22", filterType: "BestCase", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "mob1a", filterType: "SecondBest", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "hmgxb4", filterType: "BestCase", aaLeft: "348", aaRight: "372", ntLeft: "1042", ntRight: "1116" },
  { key: "slc35f4", filterType: "BestCase", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "rpp38", filterType: "FailedAllFilters", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "sybu", filterType: "BestCase", aaLeft: "559", aaRight: "583", ntLeft: "1675", ntRight: "1749" },
  { key: "adcy3", filterType: "BestCase", aaLeft: "784", aaRight: "808", ntLeft: "2350", ntRight: "2424" },
  { key: "rbm22", filterType: "BestCase", aaLeft: "347", aaRight: "371", ntLeft: "1039", ntRight: "1113" },
  { key: "tspan31", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "itfg2", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "cnpy1", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "riox1", filterType: "BestCase", aaLeft: "171", aaRight: "195", ntLeft: "511", ntRight: "585" },
  { key: "or52r1", filterType: "FailedAllFilters", aaLeft: "28", aaRight: "52", ntLeft: "82", ntRight: "156" },
  { key: "cltb", filterType: "SecondBest", aaLeft: "81", aaRight: "105", ntLeft: "241", ntRight: "315" },
  { key: "mageb10", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "mfng", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "potec", filterType: "FailedAllFilters", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "bcl2l1", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "smarcc2", filterType: "BestCase", aaLeft: "805", aaRight: "829", ntLeft: "2413", ntRight: "2487" },
  { key: "adarb2", filterType: "BestCase", aaLeft: "671", aaRight: "695", ntLeft: "2011", ntRight: "2085" },
  { key: "il7r", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "erfe", filterType: "BestCase", aaLeft: "285", aaRight: "309", ntLeft: "853", ntRight: "927" },
  { key: "psap", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "or10r2", filterType: "BestCase", aaLeft: "40", aaRight: "64", ntLeft: "118", ntRight: "192" },
  { key: "loc729966", filterType: "FailedAllFilters", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "epha3", filterType: "BestCase", aaLeft: "903", aaRight: "927", ntLeft: "2707", ntRight: "2781" },
  { key: "cdk8", filterType: "BestCase", aaLeft: "414", aaRight: "438", ntLeft: "1240", ntRight: "1314" },
  { key: "anp32a", filterType: "BestCase", aaLeft: "149", aaRight: "173", ntLeft: "445", ntRight: "519" },
  { key: "cplx2", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "hipk2", filterType: "BestCase", aaLeft: "934", aaRight: "958", ntLeft: "2800", ntRight: "2874" },
  { key: "mpp4", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "endov", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "mgp", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "pear1", filterType: "BestCase", aaLeft: "675", aaRight: "699", ntLeft: "2023", ntRight: "2097" },
  { key: "ubp1", filterType: "BestCase", aaLeft: "423", aaRight: "447", ntLeft: "1267", ntRight: "1341" },
  { key: "rpl36a", filterType: "BestCase", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "cpsf3", filterType: "SecondBest", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "znf442", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "csnk1g2", filterType: "BestCase", aaLeft: "337", aaRight: "361", ntLeft: "1009", ntRight: "1083" },
  { key: "abhd1", filterType: "FailedAllFilters", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "pcbd1", filterType: "SecondBest", aaLeft: "80", aaRight: "104", ntLeft: "238", ntRight: "312" },
  { key: "srgap2c", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "fbxo30", filterType: "BestCase", aaLeft: "220", aaRight: "244", ntLeft: "658", ntRight: "732" },
  { key: "bloc1s1", filterType: "BestCase", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "fuom", filterType: "FailedAllFilters", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "ints7", filterType: "BestCase", aaLeft: "312", aaRight: "336", ntLeft: "934", ntRight: "1008" },
  { key: "zc2hc1b", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "peg10", filterType: "FailedAllFilters", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "or2a2", filterType: "BestCase", aaLeft: "72", aaRight: "96", ntLeft: "214", ntRight: "288" },
  { key: "rpl9", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "pom121", filterType: "BestCase", aaLeft: "567", aaRight: "591", ntLeft: "1699", ntRight: "1773" },
  { key: "aga", filterType: "BestCase", aaLeft: "137", aaRight: "161", ntLeft: "409", ntRight: "483" },
  { key: "znf320", filterType: "FailedAllFilters", aaLeft: "197", aaRight: "221", ntLeft: "589", ntRight: "663" },
  { key: "tnfrsf14", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "stx12", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "chrm2", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "rgmb", filterType: "BestCase", aaLeft: "136", aaRight: "160", ntLeft: "406", ntRight: "480" },
  { key: "cyp2a6", filterType: "BestCase", aaLeft: "281", aaRight: "305", ntLeft: "841", ntRight: "915" },
  { key: "l3mbtl4", filterType: "BestCase", aaLeft: "495", aaRight: "519", ntLeft: "1483", ntRight: "1557" },
  { key: "nectin1", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "trabd", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "lrit2", filterType: "FailedAllFilters", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "efcab5", filterType: "BestCase", aaLeft: "861", aaRight: "885", ntLeft: "2581", ntRight: "2655" },
  { key: "srp72", filterType: "BestCase", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "ifnw1", filterType: "FailedAllFilters", aaLeft: "94", aaRight: "118", ntLeft: "280", ntRight: "354" },
  { key: "cib2", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "secisbp2", filterType: "BestCase", aaLeft: "480", aaRight: "504", ntLeft: "1438", ntRight: "1512" },
  { key: "ubap2l", filterType: "BestCase", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "sez6l", filterType: "BestCase", aaLeft: "536", aaRight: "560", ntLeft: "1606", ntRight: "1680" },
  { key: "sec14l2", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "slc66a2", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "or9q2", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "rgs13", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "dpp6", filterType: "BestCase", aaLeft: "635", aaRight: "659", ntLeft: "1903", ntRight: "1977" },
  { key: "marcks", filterType: "FailedAllFilters", aaLeft: "96", aaRight: "120", ntLeft: "286", ntRight: "360" },
  { key: "aqr", filterType: "BestCase", aaLeft: "377", aaRight: "401", ntLeft: "1129", ntRight: "1203" },
  { key: "chordc1", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tagln3", filterType: "SecondBest", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "znf608", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "zbtb6", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "pwwp2a", filterType: "BestCase", aaLeft: "547", aaRight: "571", ntLeft: "1639", ntRight: "1713" },
  { key: "tmprss3", filterType: "BestCase", aaLeft: "208", aaRight: "232", ntLeft: "622", ntRight: "696" },
  { key: "mlip", filterType: "FailedAllFilters", aaLeft: "954", aaRight: "978", ntLeft: "2860", ntRight: "2934" },
  { key: "pyhin1", filterType: "FailedAllFilters", aaLeft: "152", aaRight: "176", ntLeft: "454", ntRight: "528" },
  { key: "klf9", filterType: "SecondBest", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "ncapd3", filterType: "FailedAllFilters", aaLeft: "1366", aaRight: "1390", ntLeft: "4096", ntRight: "4170" },
  { key: "slc24a1", filterType: "FailedAllFilters", aaLeft: "368", aaRight: "392", ntLeft: "1102", ntRight: "1176" },
  { key: "c16orf72", filterType: "BestCase", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "znf317", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "gins4", filterType: "BestCase", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "noct", filterType: "FailedAllFilters", aaLeft: "309", aaRight: "333", ntLeft: "925", ntRight: "999" },
  { key: "caskin2", filterType: "BestCase", aaLeft: "704", aaRight: "728", ntLeft: "2110", ntRight: "2184" },
  { key: "poll", filterType: "BestCase", aaLeft: "213", aaRight: "237", ntLeft: "637", ntRight: "711" },
  { key: "cck", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "supt16h", filterType: "SecondBest", aaLeft: "375", aaRight: "399", ntLeft: "1123", ntRight: "1197" },
  { key: "cntd1", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "znf30", filterType: "BestCase", aaLeft: "349", aaRight: "373", ntLeft: "1045", ntRight: "1119" },
  { key: "akap9", filterType: "BestCase", aaLeft: "3314", aaRight: "3338", ntLeft: "9940", ntRight: "10014" },
  { key: "gnpda1", filterType: "BestCase", aaLeft: "159", aaRight: "183", ntLeft: "475", ntRight: "549" },
  { key: "mroh2a", filterType: "FailedAllFilters", aaLeft: "1180", aaRight: "1204", ntLeft: "3538", ntRight: "3612" },
  { key: "hnrnph3", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "cd24", filterType: "FourthBest", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "slc6a14", filterType: "BestCase", aaLeft: "540", aaRight: "564", ntLeft: "1618", ntRight: "1692" },
  { key: "mmgt1", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "nsun5", filterType: "BestCase", aaLeft: "308", aaRight: "332", ntLeft: "922", ntRight: "996" },
  { key: "wdr41", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "znf534", filterType: "FailedAllFilters", aaLeft: "490", aaRight: "514", ntLeft: "1468", ntRight: "1542" },
  { key: "mastl", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "itgax", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "rc3h1", filterType: "BestCase", aaLeft: "712", aaRight: "736", ntLeft: "2134", ntRight: "2208" },
  { key: "hmga1", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "pkib", filterType: "BestCase", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "cep112", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "nav2", filterType: "BestCase", aaLeft: "432", aaRight: "456", ntLeft: "1294", ntRight: "1368" },
  { key: "lrrc58", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "tmub2", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "c2orf72", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dhcr24", filterType: "BestCase", aaLeft: "243", aaRight: "267", ntLeft: "727", ntRight: "801" },
  { key: "pisd", filterType: "BestCase", aaLeft: "50", aaRight: "74", ntLeft: "148", ntRight: "222" },
  { key: "cdh23", filterType: "BestCase", aaLeft: "1671", aaRight: "1695", ntLeft: "5011", ntRight: "5085" },
  { key: "opa1", filterType: "BestCase", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "csgalnact1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "slc35c1", filterType: "BestCase", aaLeft: "19", aaRight: "43", ntLeft: "55", ntRight: "129" },
  { key: "ak7", filterType: "BestCase", aaLeft: "265", aaRight: "289", ntLeft: "793", ntRight: "867" },
  { key: "ftsj1", filterType: "BestCase", aaLeft: "206", aaRight: "230", ntLeft: "616", ntRight: "690" },
  { key: "ift46", filterType: "BestCase", aaLeft: "219", aaRight: "243", ntLeft: "655", ntRight: "729" },
  { key: "c12orf76", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "h2bc1", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "rrm2b", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "spats2", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "jkamp", filterType: "BestCase", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "znf256", filterType: "FailedAllFilters", aaLeft: "415", aaRight: "439", ntLeft: "1243", ntRight: "1317" },
  { key: "stambpl1", filterType: "BestCase", aaLeft: "194", aaRight: "218", ntLeft: "580", ntRight: "654" },
  { key: "agbl4", filterType: "BestCase", aaLeft: "468", aaRight: "492", ntLeft: "1402", ntRight: "1476" },
  { key: "slc25a22", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "gask1b", filterType: "FailedAllFilters", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "acta1", filterType: "SecondBest", aaLeft: "343", aaRight: "367", ntLeft: "1027", ntRight: "1101" },
  { key: "spint3", filterType: "FailedAllFilters", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "znf749", filterType: "FailedAllFilters", aaLeft: "684", aaRight: "708", ntLeft: "2050", ntRight: "2124" },
  { key: "borcs8", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "or8u3", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "higd2b", filterType: "FailedAllFilters", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "wnt1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "dsn1", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "tnf", filterType: "BestCase", aaLeft: "22", aaRight: "46", ntLeft: "64", ntRight: "138" },
  { key: "mdh1b", filterType: "FailedAllFilters", aaLeft: "352", aaRight: "376", ntLeft: "1054", ntRight: "1128" },
  { key: "gpr179", filterType: "FailedAllFilters", aaLeft: "1282", aaRight: "1306", ntLeft: "3844", ntRight: "3918" },
  { key: "sirt2", filterType: "BestCase", aaLeft: "44", aaRight: "68", ntLeft: "130", ntRight: "204" },
  { key: "tex47", filterType: "FailedAllFilters", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "pcdhb12", filterType: "FailedAllFilters", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "trim58", filterType: "FourthBest", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "klhl10", filterType: "BestCase", aaLeft: "574", aaRight: "598", ntLeft: "1720", ntRight: "1794" },
  { key: "thap7", filterType: "BestCase", aaLeft: "98", aaRight: "122", ntLeft: "292", ntRight: "366" },
  { key: "poc1b", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "si", filterType: "BestCase", aaLeft: "1304", aaRight: "1328", ntLeft: "3910", ntRight: "3984" },
  { key: "med8", filterType: "BestCase", aaLeft: "223", aaRight: "247", ntLeft: "667", ntRight: "741" },
  { key: "slc25a15", filterType: "BestCase", aaLeft: "239", aaRight: "263", ntLeft: "715", ntRight: "789" },
  { key: "vps37c", filterType: "BestCase", aaLeft: "264", aaRight: "288", ntLeft: "790", ntRight: "864" },
  { key: "nudt7", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "kcnt2", filterType: "BestCase", aaLeft: "970", aaRight: "994", ntLeft: "2908", ntRight: "2982" },
  { key: "timm8a", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "shd", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "erich5", filterType: "FailedAllFilters", aaLeft: "77", aaRight: "101", ntLeft: "229", ntRight: "303" },
  { key: "plekhm1", filterType: "BestCase", aaLeft: "277", aaRight: "301", ntLeft: "829", ntRight: "903" },
  { key: "hpca", filterType: "BestCase", aaLeft: "102", aaRight: "126", ntLeft: "304", ntRight: "378" },
  { key: "mtss1", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "ccdc59", filterType: "FailedAllFilters", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "zc3h12d", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "otud7a", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "dio3", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "znf503", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "wdr76", filterType: "BestCase", aaLeft: "563", aaRight: "587", ntLeft: "1687", ntRight: "1761" },
  { key: "slc30a9", filterType: "SecondBest", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "chst8", filterType: "BestCase", aaLeft: "150", aaRight: "174", ntLeft: "448", ntRight: "522" },
  { key: "foxa2", filterType: "BestCase", aaLeft: "316", aaRight: "340", ntLeft: "946", ntRight: "1020" },
  { key: "dipk2a", filterType: "SecondBest", aaLeft: "21", aaRight: "45", ntLeft: "61", ntRight: "135" },
  { key: "phf8", filterType: "BestCase", aaLeft: "583", aaRight: "607", ntLeft: "1747", ntRight: "1821" },
  { key: "ptgfr", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "fntb", filterType: "BestCase", aaLeft: "406", aaRight: "430", ntLeft: "1216", ntRight: "1290" },
  { key: "sik1", filterType: "BestCase", aaLeft: "290", aaRight: "314", ntLeft: "868", ntRight: "942" },
  { key: "ptcd2", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "mical3", filterType: "BestCase", aaLeft: "1381", aaRight: "1405", ntLeft: "4141", ntRight: "4215" },
  { key: "nmnat1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "efna3", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "dok2", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "cox6b2", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "ndufa10", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "ttll12", filterType: "BestCase", aaLeft: "247", aaRight: "271", ntLeft: "739", ntRight: "813" },
  { key: "csrp3", filterType: "BestCase", aaLeft: "88", aaRight: "112", ntLeft: "262", ntRight: "336" },
  { key: "krtap10-9", filterType: "FailedAllFilters", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "itgb1bp2", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "mtmr8", filterType: "FailedAllFilters", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "kti12", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "nek2", filterType: "BestCase", aaLeft: "384", aaRight: "408", ntLeft: "1150", ntRight: "1224" },
  { key: "slc19a3", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "reep6", filterType: "BestCase", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "spdl1", filterType: "BestCase", aaLeft: "134", aaRight: "158", ntLeft: "400", ntRight: "474" },
  { key: "tstd1", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "ddx5", filterType: "BestCase", aaLeft: "535", aaRight: "559", ntLeft: "1603", ntRight: "1677" },
  { key: "tomm6", filterType: "BestCase", aaLeft: "9", aaRight: "33", ntLeft: "25", ntRight: "99" },
  { key: "gpat2", filterType: "BestCase", aaLeft: "122", aaRight: "146", ntLeft: "364", ntRight: "438" },
  { key: "smim18", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "birc7", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "psmc5", filterType: "SecondBest", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "nkd1", filterType: "BestCase", aaLeft: "353", aaRight: "377", ntLeft: "1057", ntRight: "1131" },
  { key: "alkbh6", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "usp30", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "prap1", filterType: "FailedAllFilters", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "tbc1d21", filterType: "BestCase", aaLeft: "196", aaRight: "220", ntLeft: "586", ntRight: "660" },
  { key: "noxo1", filterType: "BestCase", aaLeft: "158", aaRight: "182", ntLeft: "472", ntRight: "546" },
  { key: "sox18", filterType: "BestCase", aaLeft: "269", aaRight: "293", ntLeft: "805", ntRight: "879" },
  { key: "c22orf39", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "ubqln3", filterType: "BestCase", aaLeft: "135", aaRight: "159", ntLeft: "403", ntRight: "477" },
  { key: "or5a2", filterType: "BestCase", aaLeft: "186", aaRight: "210", ntLeft: "556", ntRight: "630" },
  { key: "dad1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cenpvl1", filterType: "FailedAllFilters", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "h1-4", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "ikzf2", filterType: "BestCase", aaLeft: "426", aaRight: "450", ntLeft: "1276", ntRight: "1350" },
  { key: "znf420", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "fga", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "znf385c", filterType: "BestCase", aaLeft: "235", aaRight: "259", ntLeft: "703", ntRight: "777" },
  { key: "add1", filterType: "BestCase", aaLeft: "717", aaRight: "741", ntLeft: "2149", ntRight: "2223" },
  { key: "cd46", filterType: "FailedAllFilters", aaLeft: "165", aaRight: "189", ntLeft: "493", ntRight: "567" },
  { key: "sun2", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "dcst1", filterType: "BestCase", aaLeft: "319", aaRight: "343", ntLeft: "955", ntRight: "1029" },
  { key: "golim4", filterType: "FailedAllFilters", aaLeft: "521", aaRight: "545", ntLeft: "1561", ntRight: "1635" },
  { key: "ddx59", filterType: "BestCase", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "slc23a1", filterType: "BestCase", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "glb1", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "znf417", filterType: "FailedAllFilters", aaLeft: "461", aaRight: "485", ntLeft: "1381", ntRight: "1455" },
  { key: "ppil4", filterType: "BestCase", aaLeft: "447", aaRight: "471", ntLeft: "1339", ntRight: "1413" },
  { key: "smim6", filterType: "FailedAllFilters", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "marchf5", filterType: "SecondBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "ipo4", filterType: "BestCase", aaLeft: "776", aaRight: "800", ntLeft: "2326", ntRight: "2400" },
  { key: "dnmt3a", filterType: "BestCase", aaLeft: "61", aaRight: "85", ntLeft: "181", ntRight: "255" },
  { key: "cdc5l", filterType: "BestCase", aaLeft: "496", aaRight: "520", ntLeft: "1486", ntRight: "1560" },
  { key: "c10orf82", filterType: "FailedAllFilters", aaLeft: "124", aaRight: "148", ntLeft: "370", ntRight: "444" },
  { key: "fxyd4", filterType: "FailedAllFilters", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "slc34a2", filterType: "BestCase", aaLeft: "585", aaRight: "609", ntLeft: "1753", ntRight: "1827" },
  { key: "omg", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "dcaf15", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "tbc1d16", filterType: "BestCase", aaLeft: "127", aaRight: "151", ntLeft: "379", ntRight: "453" },
  { key: "nfyc", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "mpv17", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "eomes", filterType: "BestCase", aaLeft: "49", aaRight: "73", ntLeft: "145", ntRight: "219" },
  { key: "pfkp", filterType: "BestCase", aaLeft: "151", aaRight: "175", ntLeft: "451", ntRight: "525" },
  { key: "isoc2", filterType: "BestCase", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "krtap1-4", filterType: "FailedAllFilters", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "hla-b", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "prrx2", filterType: "BestCase", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "tnfrsf10a", filterType: "FailedAllFilters", aaLeft: "60", aaRight: "84", ntLeft: "178", ntRight: "252" },
  { key: "ppid", filterType: "BestCase", aaLeft: "48", aaRight: "72", ntLeft: "142", ntRight: "216" },
  { key: "ercc1", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "siae", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "zpld1", filterType: "BestCase", aaLeft: "360", aaRight: "384", ntLeft: "1078", ntRight: "1152" },
  { key: "bcor", filterType: "BestCase", aaLeft: "818", aaRight: "842", ntLeft: "2452", ntRight: "2526" },
  { key: "dck", filterType: "BestCase", aaLeft: "93", aaRight: "117", ntLeft: "277", ntRight: "351" },
  { key: "lzts3", filterType: "BestCase", aaLeft: "511", aaRight: "535", ntLeft: "1531", ntRight: "1605" },
  { key: "mcl1", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "dhrs3", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tas2r1", filterType: "FailedAllFilters", aaLeft: "65", aaRight: "89", ntLeft: "193", ntRight: "267" },
  { key: "p3h3", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "pla2g2d", filterType: "FailedAllFilters", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "telo2", filterType: "BestCase", aaLeft: "355", aaRight: "379", ntLeft: "1063", ntRight: "1137" },
  { key: "prmt7", filterType: "BestCase", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "rab3a", filterType: "BestCase", aaLeft: "188", aaRight: "212", ntLeft: "562", ntRight: "636" },
  { key: "bud13", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "osgin1", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "ptprn2", filterType: "BestCase", aaLeft: "687", aaRight: "711", ntLeft: "2059", ntRight: "2133" },
  { key: "srek1", filterType: "BestCase", aaLeft: "564", aaRight: "588", ntLeft: "1690", ntRight: "1764" },
  { key: "inha", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "sp4", filterType: "BestCase", aaLeft: "287", aaRight: "311", ntLeft: "859", ntRight: "933" },
  { key: "dux4", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "bfar", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "ybx1", filterType: "BestCase", aaLeft: "113", aaRight: "137", ntLeft: "337", ntRight: "411" },
  { key: "cst1", filterType: "FailedAllFilters", aaLeft: "107", aaRight: "131", ntLeft: "319", ntRight: "393" },
  { key: "prr14l", filterType: "FailedAllFilters", aaLeft: "1163", aaRight: "1187", ntLeft: "3487", ntRight: "3561" },
  { key: "hltf", filterType: "BestCase", aaLeft: "177", aaRight: "201", ntLeft: "529", ntRight: "603" },
  { key: "cbx8", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "zmat5", filterType: "BestCase", aaLeft: "112", aaRight: "136", ntLeft: "334", ntRight: "408" },
  { key: "mmp24", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "ccnc", filterType: "SecondBest", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "mitf", filterType: "BestCase", aaLeft: "451", aaRight: "475", ntLeft: "1351", ntRight: "1425" },
  { key: "unc5b", filterType: "BestCase", aaLeft: "505", aaRight: "529", ntLeft: "1513", ntRight: "1587" },
  { key: "cacna2d2", filterType: "BestCase", aaLeft: "789", aaRight: "813", ntLeft: "2365", ntRight: "2439" },
  { key: "adam19", filterType: "BestCase", aaLeft: "868", aaRight: "892", ntLeft: "2602", ntRight: "2676" },
  { key: "dld", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "recql", filterType: "BestCase", aaLeft: "517", aaRight: "541", ntLeft: "1549", ntRight: "1623" },
  { key: "syt8", filterType: "FailedAllFilters", aaLeft: "344", aaRight: "368", ntLeft: "1030", ntRight: "1104" },
  { key: "tln2", filterType: "BestCase", aaLeft: "2019", aaRight: "2043", ntLeft: "6055", ntRight: "6129" },
  { key: "rpl18a", filterType: "BestCase", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "etnk1", filterType: "BestCase", aaLeft: "283", aaRight: "307", ntLeft: "847", ntRight: "921" },
  { key: "mrpl32", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "dars1", filterType: "BestCase", aaLeft: "38", aaRight: "62", ntLeft: "112", ntRight: "186" },
  { key: "eme1", filterType: "BestCase", aaLeft: "334", aaRight: "358", ntLeft: "1000", ntRight: "1074" },
  { key: "tpbgl", filterType: "BestCase", aaLeft: "268", aaRight: "292", ntLeft: "802", ntRight: "876" },
  { key: "gls2", filterType: "BestCase", aaLeft: "8", aaRight: "32", ntLeft: "22", ntRight: "96" },
  { key: "polr3e", filterType: "BestCase", aaLeft: "471", aaRight: "495", ntLeft: "1411", ntRight: "1485" },
  { key: "mfap4", filterType: "BestCase", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "or2ag2", filterType: "FailedAllFilters", aaLeft: "128", aaRight: "152", ntLeft: "382", ntRight: "456" },
  { key: "spata2l", filterType: "BestCase", aaLeft: "272", aaRight: "296", ntLeft: "814", ntRight: "888" },
  { key: "cadm1", filterType: "SecondBest", aaLeft: "313", aaRight: "337", ntLeft: "937", ntRight: "1011" },
  { key: "wbp11", filterType: "BestCase", aaLeft: "526", aaRight: "550", ntLeft: "1576", ntRight: "1650" },
  { key: "mmp13", filterType: "BestCase", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "ksr2", filterType: "BestCase", aaLeft: "201", aaRight: "225", ntLeft: "601", ntRight: "675" },
  { key: "zbed5", filterType: "FailedAllFilters", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "bcl2l14", filterType: "FailedAllFilters", aaLeft: "234", aaRight: "258", ntLeft: "700", ntRight: "774" },
  { key: "s100a9", filterType: "FailedAllFilters", aaLeft: "11", aaRight: "35", ntLeft: "31", ntRight: "105" },
  { key: "bcs1l", filterType: "BestCase", aaLeft: "365", aaRight: "389", ntLeft: "1093", ntRight: "1167" },
  { key: "atp11c", filterType: "BestCase", aaLeft: "25", aaRight: "49", ntLeft: "73", ntRight: "147" },
  { key: "alg1l2", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "tmem258", filterType: "SecondBest", aaLeft: "41", aaRight: "65", ntLeft: "121", ntRight: "195" },
  { key: "pik3r2", filterType: "BestCase", aaLeft: "300", aaRight: "324", ntLeft: "898", ntRight: "972" },
  { key: "fgf4", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "chst10", filterType: "BestCase", aaLeft: "36", aaRight: "60", ntLeft: "106", ntRight: "180" },
  { key: "ankrd28", filterType: "BestCase", aaLeft: "14", aaRight: "38", ntLeft: "40", ntRight: "114" },
  { key: "pbx3", filterType: "BestCase", aaLeft: "330", aaRight: "354", ntLeft: "988", ntRight: "1062" },
  { key: "rassf10", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "acp7", filterType: "BestCase", aaLeft: "56", aaRight: "80", ntLeft: "166", ntRight: "240" },
  { key: "btbd10", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "gemin7", filterType: "BestCase", aaLeft: "17", aaRight: "41", ntLeft: "49", ntRight: "123" },
  { key: "avpr1a", filterType: "BestCase", aaLeft: "27", aaRight: "51", ntLeft: "79", ntRight: "153" },
  { key: "zhx3", filterType: "BestCase", aaLeft: "893", aaRight: "917", ntLeft: "2677", ntRight: "2751" },
  { key: "tank", filterType: "BestCase", aaLeft: "249", aaRight: "273", ntLeft: "745", ntRight: "819" },
  { key: "ulk4", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "hspb6", filterType: "BestCase", aaLeft: "131", aaRight: "155", ntLeft: "391", ntRight: "465" },
  { key: "abhd17a", filterType: "BestCase", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "slc5a8", filterType: "BestCase", aaLeft: "199", aaRight: "223", ntLeft: "595", ntRight: "669" },
  { key: "lpcat3", filterType: "BestCase", aaLeft: "396", aaRight: "420", ntLeft: "1186", ntRight: "1260" },
  { key: "ppp1r13l", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "or6k3", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "tpk1", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "yif1b", filterType: "BestCase", aaLeft: "45", aaRight: "69", ntLeft: "133", ntRight: "207" },
  { key: "rffl", filterType: "BestCase", aaLeft: "145", aaRight: "169", ntLeft: "433", ntRight: "507" },
  { key: "lrfn3", filterType: "BestCase", aaLeft: "467", aaRight: "491", ntLeft: "1399", ntRight: "1473" },
  { key: "gage12j", filterType: "FailedAllFilters", aaLeft: "16", aaRight: "40", ntLeft: "46", ntRight: "120" },
  { key: "nkx2-1", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "mfsd8", filterType: "FailedAllFilters", aaLeft: "485", aaRight: "509", ntLeft: "1453", ntRight: "1527" },
  { key: "paqr8", filterType: "BestCase", aaLeft: "301", aaRight: "325", ntLeft: "901", ntRight: "975" },
  { key: "rspo4", filterType: "BestCase", aaLeft: "156", aaRight: "180", ntLeft: "466", ntRight: "540" },
  { key: "lamc3", filterType: "BestCase", aaLeft: "488", aaRight: "512", ntLeft: "1462", ntRight: "1536" },
  { key: "specc1l", filterType: "BestCase", aaLeft: "66", aaRight: "90", ntLeft: "196", ntRight: "270" },
  { key: "dusp15", filterType: "BestCase", aaLeft: "141", aaRight: "165", ntLeft: "421", ntRight: "495" },
  { key: "coq2", filterType: "FailedAllFilters", aaLeft: "182", aaRight: "206", ntLeft: "544", ntRight: "618" },
  { key: "lsm14a", filterType: "BestCase", aaLeft: "148", aaRight: "172", ntLeft: "442", ntRight: "516" },
  { key: "hbq1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or1m1", filterType: "BestCase", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "h3c10", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "ppp1cb", filterType: "SecondBest", aaLeft: "70", aaRight: "94", ntLeft: "208", ntRight: "282" },
  { key: "henmt1", filterType: "FailedAllFilters", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "ttc19", filterType: "BestCase", aaLeft: "335", aaRight: "359", ntLeft: "1003", ntRight: "1077" },
  { key: "retreg2", filterType: "BestCase", aaLeft: "435", aaRight: "459", ntLeft: "1303", ntRight: "1377" },
  { key: "sh2b2", filterType: "BestCase", aaLeft: "338", aaRight: "362", ntLeft: "1012", ntRight: "1086" },
  { key: "ncl", filterType: "BestCase", aaLeft: "91", aaRight: "115", ntLeft: "271", ntRight: "345" },
  { key: "gzma", filterType: "FailedAllFilters", aaLeft: "26", aaRight: "50", ntLeft: "76", ntRight: "150" },
  { key: "adamtsl4", filterType: "BestCase", aaLeft: "701", aaRight: "725", ntLeft: "2101", ntRight: "2175" },
  { key: "kctd3", filterType: "BestCase", aaLeft: "692", aaRight: "716", ntLeft: "2074", ntRight: "2148" },
  { key: "kif2c", filterType: "BestCase", aaLeft: "598", aaRight: "622", ntLeft: "1792", ntRight: "1866" },
  { key: "zbtb7c", filterType: "BestCase", aaLeft: "190", aaRight: "214", ntLeft: "568", ntRight: "642" },
  { key: "fchsd2", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "lce2b", filterType: "ThirdBest", aaLeft: "78", aaRight: "102", ntLeft: "232", ntRight: "306" },
  { key: "wdr89", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "acbd5", filterType: "BestCase", aaLeft: "155", aaRight: "179", ntLeft: "463", ntRight: "537" },
  { key: "slc13a4", filterType: "BestCase", aaLeft: "218", aaRight: "242", ntLeft: "652", ntRight: "726" },
  { key: "c19orf54", filterType: "BestCase", aaLeft: "324", aaRight: "348", ntLeft: "970", ntRight: "1044" },
  { key: "tmem81", filterType: "FailedAllFilters", aaLeft: "23", aaRight: "47", ntLeft: "67", ntRight: "141" },
  { key: "lce1e", filterType: "FailedAllFilters", aaLeft: "82", aaRight: "106", ntLeft: "244", ntRight: "318" },
  { key: "baz2a", filterType: "BestCase", aaLeft: "504", aaRight: "528", ntLeft: "1510", ntRight: "1584" },
  { key: "atf3", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "trpm4", filterType: "BestCase", aaLeft: "472", aaRight: "496", ntLeft: "1414", ntRight: "1488" },
  { key: "wsb2", filterType: "BestCase", aaLeft: "101", aaRight: "125", ntLeft: "301", ntRight: "375" },
  { key: "ccdc91", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "tmem131", filterType: "BestCase", aaLeft: "1828", aaRight: "1852", ntLeft: "5482", ntRight: "5556" },
  { key: "ramp2", filterType: "BestCase", aaLeft: "92", aaRight: "116", ntLeft: "274", ntRight: "348" },
  { key: "slc1a3", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "tmed10", filterType: "BestCase", aaLeft: "12", aaRight: "36", ntLeft: "34", ntRight: "108" },
  { key: "c9orf50", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "ap1s3", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "ssx7", filterType: "FailedAllFilters", aaLeft: "157", aaRight: "181", ntLeft: "469", ntRight: "543" },
  { key: "slc39a11", filterType: "BestCase", aaLeft: "164", aaRight: "188", ntLeft: "490", ntRight: "564" },
  { key: "hnrnpcl1", filterType: "FailedAllFilters", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "spdye16", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "kcne1", filterType: "BestCase", aaLeft: "73", aaRight: "97", ntLeft: "217", ntRight: "291" },
  { key: "ergic3", filterType: "BestCase", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "spata6", filterType: "BestCase", aaLeft: "431", aaRight: "455", ntLeft: "1291", ntRight: "1365" },
  { key: "lcnl1", filterType: "FailedAllFilters", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "ctps2", filterType: "BestCase", aaLeft: "332", aaRight: "356", ntLeft: "994", ntRight: "1068" },
  { key: "c11orf94", filterType: "BestCase", aaLeft: "29", aaRight: "53", ntLeft: "85", ntRight: "159" },
  { key: "dusp22", filterType: "BestCase", aaLeft: "160", aaRight: "184", ntLeft: "478", ntRight: "552" },
  { key: "rprd1a", filterType: "SecondBest", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "chi3l2", filterType: "BestCase", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "cyp11a1", filterType: "BestCase", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "cyhr1", filterType: "BestCase", aaLeft: "236", aaRight: "260", ntLeft: "706", ntRight: "780" },
  { key: "ogfrl1", filterType: "SecondBest", aaLeft: "203", aaRight: "227", ntLeft: "607", ntRight: "681" },
  { key: "armc2", filterType: "BestCase", aaLeft: "705", aaRight: "729", ntLeft: "2113", ntRight: "2187" },
  { key: "a4galt", filterType: "FailedAllFilters", aaLeft: "147", aaRight: "171", ntLeft: "439", ntRight: "513" },
  { key: "foxp1", filterType: "BestCase", aaLeft: "7", aaRight: "31", ntLeft: "19", ntRight: "93" },
  { key: "mansc4", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "krt78", filterType: "BestCase", aaLeft: "464", aaRight: "488", ntLeft: "1390", ntRight: "1464" },
  { key: "ier5", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "stip1", filterType: "BestCase", aaLeft: "15", aaRight: "39", ntLeft: "43", ntRight: "117" },
  { key: "prokr2", filterType: "BestCase", aaLeft: "354", aaRight: "378", ntLeft: "1060", ntRight: "1134" },
  { key: "eng", filterType: "BestCase", aaLeft: "5", aaRight: "29", ntLeft: "13", ntRight: "87" },
  { key: "coro2a", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "gnl1", filterType: "BestCase", aaLeft: "450", aaRight: "474", ntLeft: "1348", ntRight: "1422" },
  { key: "tfdp3", filterType: "FailedAllFilters", aaLeft: "273", aaRight: "297", ntLeft: "817", ntRight: "891" },
  { key: "spag4", filterType: "BestCase", aaLeft: "108", aaRight: "132", ntLeft: "322", ntRight: "396" },
  { key: "mast2", filterType: "BestCase", aaLeft: "957", aaRight: "981", ntLeft: "2869", ntRight: "2943" },
  { key: "odf3b", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "iqgap1", filterType: "BestCase", aaLeft: "1437", aaRight: "1461", ntLeft: "4309", ntRight: "4383" },
  { key: "c6orf89", filterType: "BestCase", aaLeft: "138", aaRight: "162", ntLeft: "412", ntRight: "486" },
  { key: "rad51b", filterType: "BestCase", aaLeft: "39", aaRight: "63", ntLeft: "115", ntRight: "189" },
  { key: "uba2", filterType: "BestCase", aaLeft: "616", aaRight: "640", ntLeft: "1846", ntRight: "1920" },
  { key: "nup188", filterType: "BestCase", aaLeft: "1522", aaRight: "1546", ntLeft: "4564", ntRight: "4638" },
  { key: "derl1", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "myh6", filterType: "BestCase", aaLeft: "783", aaRight: "807", ntLeft: "2347", ntRight: "2421" },
  { key: "zfp30", filterType: "BestCase", aaLeft: "204", aaRight: "228", ntLeft: "610", ntRight: "684" },
  { key: "pacc1", filterType: "BestCase", aaLeft: "191", aaRight: "215", ntLeft: "571", ntRight: "645" },
  { key: "sp110", filterType: "FailedAllFilters", aaLeft: "580", aaRight: "604", ntLeft: "1738", ntRight: "1812" },
  { key: "dedd", filterType: "BestCase", aaLeft: "118", aaRight: "142", ntLeft: "352", ntRight: "426" },
  { key: "amz1", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "sema3b", filterType: "BestCase", aaLeft: "711", aaRight: "735", ntLeft: "2131", ntRight: "2205" },
  { key: "scamp3", filterType: "BestCase", aaLeft: "47", aaRight: "71", ntLeft: "139", ntRight: "213" },
  { key: "sox1", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "dus1l", filterType: "BestCase", aaLeft: "303", aaRight: "327", ntLeft: "907", ntRight: "981" },
  { key: "znf195", filterType: "FailedAllFilters", aaLeft: "214", aaRight: "238", ntLeft: "640", ntRight: "714" },
  { key: "rab5c", filterType: "BestCase", aaLeft: "173", aaRight: "197", ntLeft: "517", ntRight: "591" },
  { key: "batf", filterType: "BestCase", aaLeft: "79", aaRight: "103", ntLeft: "235", ntRight: "309" },
  { key: "tmsb10", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "bst2", filterType: "FailedAllFilters", aaLeft: "33", aaRight: "57", ntLeft: "97", ntRight: "171" },
  { key: "nutm2d", filterType: "FailedAllFilters", aaLeft: "225", aaRight: "249", ntLeft: "673", ntRight: "747" },
  { key: "st8sia5", filterType: "BestCase", aaLeft: "350", aaRight: "374", ntLeft: "1048", ntRight: "1122" },
  { key: "mad1l1", filterType: "BestCase", aaLeft: "119", aaRight: "143", ntLeft: "355", ntRight: "429" },
  { key: "pla2g4f", filterType: "BestCase", aaLeft: "266", aaRight: "290", ntLeft: "796", ntRight: "870" },
  { key: "atad3a", filterType: "BestCase", aaLeft: "458", aaRight: "482", ntLeft: "1372", ntRight: "1446" },
  { key: "tgfbr3", filterType: "BestCase", aaLeft: "392", aaRight: "416", ntLeft: "1174", ntRight: "1248" },
  { key: "rdh10", filterType: "SecondBest", aaLeft: "263", aaRight: "287", ntLeft: "787", ntRight: "861" },
  { key: "ablim1", filterType: "BestCase", aaLeft: "162", aaRight: "186", ntLeft: "484", ntRight: "558" },
  { key: "bcl7c", filterType: "BestCase", aaLeft: "132", aaRight: "156", ntLeft: "394", ntRight: "468" },
  { key: "pced1b", filterType: "FailedAllFilters", aaLeft: "183", aaRight: "207", ntLeft: "547", ntRight: "621" },
  { key: "mex3c", filterType: "BestCase", aaLeft: "305", aaRight: "329", ntLeft: "913", ntRight: "987" },
  { key: "clstn1", filterType: "BestCase", aaLeft: "166", aaRight: "190", ntLeft: "496", ntRight: "570" },
  { key: "egflam", filterType: "BestCase", aaLeft: "18", aaRight: "42", ntLeft: "52", ntRight: "126" },
  { key: "pin1", filterType: "BestCase", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "plcz1", filterType: "BestCase", aaLeft: "551", aaRight: "575", ntLeft: "1651", ntRight: "1725" },
  { key: "hmgcs1", filterType: "BestCase", aaLeft: "466", aaRight: "490", ntLeft: "1396", ntRight: "1470" },
  { key: "mkx", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "inpp5b", filterType: "BestCase", aaLeft: "732", aaRight: "756", ntLeft: "2194", ntRight: "2268" },
  { key: "ppp4r3b", filterType: "BestCase", aaLeft: "795", aaRight: "819", ntLeft: "2383", ntRight: "2457" },
  { key: "prdx3", filterType: "BestCase", aaLeft: "100", aaRight: "124", ntLeft: "298", ntRight: "372" },
  { key: "strc", filterType: "BestCase", aaLeft: "32", aaRight: "56", ntLeft: "94", ntRight: "168" },
  { key: "xkr4", filterType: "BestCase", aaLeft: "516", aaRight: "540", ntLeft: "1546", ntRight: "1620" },
  { key: "mettl21c", filterType: "BestCase", aaLeft: "154", aaRight: "178", ntLeft: "460", ntRight: "534" },
  { key: "zxdc", filterType: "FailedAllFilters", aaLeft: "578", aaRight: "602", ntLeft: "1732", ntRight: "1806" },
  { key: "tjap1", filterType: "BestCase", aaLeft: "438", aaRight: "462", ntLeft: "1312", ntRight: "1386" },
  { key: "timm17b", filterType: "BestCase", aaLeft: "143", aaRight: "167", ntLeft: "427", ntRight: "501" },
  { key: "fzd1", filterType: "BestCase", aaLeft: "562", aaRight: "586", ntLeft: "1684", ntRight: "1758" },
  { key: "pafah1b1", filterType: "BestCase", aaLeft: "341", aaRight: "365", ntLeft: "1021", ntRight: "1095" },
  { key: "npc1l1", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "commd5", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "sertad2", filterType: "BestCase", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "wdr4", filterType: "BestCase", aaLeft: "267", aaRight: "291", ntLeft: "799", ntRight: "873" },
  { key: "parl", filterType: "BestCase", aaLeft: "169", aaRight: "193", ntLeft: "505", ntRight: "579" },
  { key: "hsh2d", filterType: "FailedAllFilters", aaLeft: "95", aaRight: "119", ntLeft: "283", ntRight: "357" },
  { key: "mlana", filterType: "FailedAllFilters", aaLeft: "34", aaRight: "58", ntLeft: "100", ntRight: "174" },
  { key: "krtap19-5", filterType: "BestCase", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "crh", filterType: "BestCase", aaLeft: "24", aaRight: "48", ntLeft: "70", ntRight: "144" },
  { key: "slc2a8", filterType: "BestCase", aaLeft: "340", aaRight: "364", ntLeft: "1018", ntRight: "1092" },
  { key: "coch", filterType: "BestCase", aaLeft: "412", aaRight: "436", ntLeft: "1234", ntRight: "1308" },
  { key: "tango2", filterType: "BestCase", aaLeft: "174", aaRight: "198", ntLeft: "520", ntRight: "594" },
  { key: "leng1", filterType: "BestCase", aaLeft: "69", aaRight: "93", ntLeft: "205", ntRight: "279" },
  { key: "hexb", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "mrpl50", filterType: "FailedAllFilters", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "ubiad1", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cpped1", filterType: "BestCase", aaLeft: "167", aaRight: "191", ntLeft: "499", ntRight: "573" },
  { key: "ptprc", filterType: "BestCase", aaLeft: "802", aaRight: "826", ntLeft: "2404", ntRight: "2478" },
  { key: "emc3", filterType: "SecondBest", aaLeft: "85", aaRight: "109", ntLeft: "253", ntRight: "327" },
  { key: "pex3", filterType: "BestCase", aaLeft: "209", aaRight: "233", ntLeft: "625", ntRight: "699" },
  { key: "tmdd1", filterType: "FailedAllFilters", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "kif4a", filterType: "BestCase", aaLeft: "499", aaRight: "523", ntLeft: "1495", ntRight: "1569" },
  { key: "gabpb1", filterType: "BestCase", aaLeft: "71", aaRight: "95", ntLeft: "211", ntRight: "285" },
  { key: "dlg1", filterType: "BestCase", aaLeft: "67", aaRight: "91", ntLeft: "199", ntRight: "273" },
  { key: "or8b2", filterType: "BestCase", aaLeft: "205", aaRight: "229", ntLeft: "613", ntRight: "687" },
  { key: "tcf7", filterType: "BestCase", aaLeft: "90", aaRight: "114", ntLeft: "268", ntRight: "342" },
  { key: "upk2", filterType: "BestCase", aaLeft: "2", aaRight: "26", ntLeft: "4", ntRight: "78" },
  { key: "cmklr1", filterType: "BestCase", aaLeft: "178", aaRight: "202", ntLeft: "532", ntRight: "606" },
  { key: "clybl", filterType: "BestCase", aaLeft: "228", aaRight: "252", ntLeft: "682", ntRight: "756" },
  { key: "clec4e", filterType: "FailedAllFilters", aaLeft: "97", aaRight: "121", ntLeft: "289", ntRight: "363" },
  { key: "aig1", filterType: "BestCase", aaLeft: "202", aaRight: "226", ntLeft: "604", ntRight: "678" },
  { key: "tnxb", filterType: "BestCase", aaLeft: "3477", aaRight: "3501", ntLeft: "10429", ntRight: "10503" },
  { key: "c7orf57", filterType: "BestCase", aaLeft: "121", aaRight: "145", ntLeft: "361", ntRight: "435" },
  { key: "tmem164", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "hsf1", filterType: "BestCase", aaLeft: "242", aaRight: "266", ntLeft: "724", ntRight: "798" },
  { key: "trappc2b", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "tlcd2", filterType: "FailedAllFilters", aaLeft: "62", aaRight: "86", ntLeft: "184", ntRight: "258" },
  { key: "papolb", filterType: "BestCase", aaLeft: "522", aaRight: "546", ntLeft: "1564", ntRight: "1638" },
  { key: "arhgap21", filterType: "BestCase", aaLeft: "20", aaRight: "44", ntLeft: "58", ntRight: "132" },
  { key: "fbxl4", filterType: "BestCase", aaLeft: "246", aaRight: "270", ntLeft: "736", ntRight: "810" },
  { key: "slc39a5", filterType: "BestCase", aaLeft: "271", aaRight: "295", ntLeft: "811", ntRight: "885" },
  { key: "lrp1b", filterType: "BestCase", aaLeft: "2837", aaRight: "2861", ntLeft: "8509", ntRight: "8583" },
  { key: "ccdc184", filterType: "BestCase", aaLeft: "105", aaRight: "129", ntLeft: "313", ntRight: "387" },
  { key: "lrrtm1", filterType: "BestCase", aaLeft: "382", aaRight: "406", ntLeft: "1144", ntRight: "1218" },
  { key: "yae1", filterType: "SecondBest", aaLeft: "54", aaRight: "78", ntLeft: "160", ntRight: "234" },
  { key: "pms1", filterType: "BestCase", aaLeft: "696", aaRight: "720", ntLeft: "2086", ntRight: "2160" },
  { key: "magea2b", filterType: "FailedAllFilters", aaLeft: "83", aaRight: "107", ntLeft: "247", ntRight: "321" },
  { key: "gabarapl2", filterType: "SecondBest", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "zbtb8os", filterType: "BestCase", aaLeft: "59", aaRight: "83", ntLeft: "175", ntRight: "249" },
  { key: "selenot", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "tpm3", filterType: "BestCase", aaLeft: "130", aaRight: "154", ntLeft: "388", ntRight: "462" },
  { key: "or6m1", filterType: "FailedAllFilters", aaLeft: "126", aaRight: "150", ntLeft: "376", ntRight: "450" },
  { key: "zmiz2", filterType: "BestCase", aaLeft: "833", aaRight: "857", ntLeft: "2497", ntRight: "2571" },
  { key: "mrln", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "nrxn1", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "nol11", filterType: "FailedAllFilters", aaLeft: "6", aaRight: "30", ntLeft: "16", ntRight: "90" },
  { key: "slc6a3", filterType: "BestCase", aaLeft: "51", aaRight: "75", ntLeft: "151", ntRight: "225" },
  { key: "cracd", filterType: "FailedAllFilters", aaLeft: "193", aaRight: "217", ntLeft: "577", ntRight: "651" },
  { key: "ascl2", filterType: "BestCase", aaLeft: "104", aaRight: "128", ntLeft: "310", ntRight: "384" },
  { key: "pnma2", filterType: "BestCase", aaLeft: "109", aaRight: "133", ntLeft: "325", ntRight: "399" },
  { key: "myorg", filterType: "BestCase", aaLeft: "251", aaRight: "275", ntLeft: "751", ntRight: "825" },
  { key: "stx7", filterType: "SecondBest", aaLeft: "30", aaRight: "54", ntLeft: "88", ntRight: "162" },
  { key: "mybl2", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" },
  { key: "aass", filterType: "BestCase", aaLeft: "4", aaRight: "28", ntLeft: "10", ntRight: "84" },
  { key: "pigb", filterType: "BestCase", aaLeft: "513", aaRight: "537", ntLeft: "1537", ntRight: "1611" },
  { key: "ncor2", filterType: "BestCase", aaLeft: "1978", aaRight: "2002", ntLeft: "5932", ntRight: "6006" },
  { key: "kcnc3", filterType: "BestCase", aaLeft: "362", aaRight: "386", ntLeft: "1084", ntRight: "1158" },
  { key: "exo1", filterType: "BestCase", aaLeft: "755", aaRight: "779", ntLeft: "2263", ntRight: "2337" },
  { key: "pax8", filterType: "BestCase", aaLeft: "217", aaRight: "241", ntLeft: "649", ntRight: "723" },
  { key: "ppt1", filterType: "BestCase", aaLeft: "76", aaRight: "100", ntLeft: "226", ntRight: "300" },
  { key: "ropn1l", filterType: "BestCase", aaLeft: "57", aaRight: "81", ntLeft: "169", ntRight: "243" },
  { key: "lgr5", filterType: "BestCase", aaLeft: "3", aaRight: "27", ntLeft: "7", ntRight: "81" },
  { key: "c17orf64", filterType: "BestCase", aaLeft: "116", aaRight: "140", ntLeft: "346", ntRight: "420" },
  { key: "fbxo3", filterType: "FailedAllFilters", aaLeft: "1", aaRight: "25", ntLeft: "1", ntRight: "75" },
  { key: "cops8", filterType: "BestCase", aaLeft: "86", aaRight: "110", ntLeft: "256", ntRight: "330" },
  { key: "rims4", filterType: "SecondBest", aaLeft: "139", aaRight: "163", ntLeft: "415", ntRight: "489" },
  { key: "atg4b", filterType: "BestCase", aaLeft: "181", aaRight: "205", ntLeft: "541", ntRight: "615" },
  { key: "flvcr2", filterType: "BestCase", aaLeft: "278", aaRight: "302", ntLeft: "832", ntRight: "906" },
  { key: "slc5a11", filterType: "BestCase", aaLeft: "502", aaRight: "526", ntLeft: "1504", ntRight: "1578" },
  { key: "zfand6", filterType: "BestCase", aaLeft: "89", aaRight: "113", ntLeft: "265", ntRight: "339" },
  { key: "ccl3l3", filterType: "FailedAllFilters", aaLeft: "68", aaRight: "92", ntLeft: "202", ntRight: "276" },
  { key: "fanca", filterType: "FailedAllFilters", aaLeft: "410", aaRight: "434", ntLeft: "1228", ntRight: "1302" },
  { key: "or10c1", filterType: "FailedAllFilters", aaLeft: "133", aaRight: "157", ntLeft: "397", ntRight: "471" },
  { key: "gdpd2", filterType: "BestCase", aaLeft: "323", aaRight: "347", ntLeft: "967", ntRight: "1041" }
]

export default RulerCoords;
