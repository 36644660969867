import React from "react";
import { handleValidateEmailApiCall } from "../../utils/DataCall";
import LogoTransparent from '../../images/LogoTransparent.png';

export default function ValidateEmail() {

  const [showHamburgerMenu, setShowHamburgerMenu] = React.useState<boolean>(false);
  const [validationResults, setValidationResults] = React.useState<string>('Not Started');

  const doValidation = () => {
    // Get query string
    const fullQueryString = window.location.search.replace('%26', '&').substring(1);
    const splitQS = fullQueryString.split('&');

    //  demo.codexome.com/validate?some-guid%26you@me.com
    //  demo.codexome.com/validate?some-guid&you@me.com
   
    const email = splitQS && splitQS.length > 1 ? splitQS[1] : '';
    const validationID = splitQS && splitQS.length > 1 ? splitQS[0] : '';

    // Make API Call
    handleValidateEmailApiCall(email, validationID).then((result: any) => {
      if(result.success !== true) {
        setValidationResults('Validation Error');
      }
      else {
        setValidationResults('Validation Success');
      }
    });
  };

  doValidation();

  return (
    <div style={{width: '100%', height: '100%', backgroundColor: 'whitesmoke', padding: 0, margin: 0}}>
        <table cellPadding={0} cellSpacing={0} style={{width: '100%', height: '100%', padding: 0, margin: 0, borderCollapse: 'collapse'}}>
          <tbody>
            <tr>
              <td style={{border: 'solid 0px red', backgroundColor: '#945232'}}>
                <div style={{width: '100%', margin: 0, height: 105, zIndex: 0, backgroundColor: '#FFFFFFAA', position: 'fixed'}}>
                  <div style={{width: '98%', margin: '1%', height: 100}}>
                    <div style={{float: 'left',   border: 'solid 0px green', marginTop: 10, marginLeft: 76}}>
                      <img  src={LogoTransparent} 
                            alt="Logo"
                            width='284px'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com";
                            }} 
                      />
                    </div>
                    <div style={{float: 'right', border: 'solid 0px green', marginTop: 9, marginRight: 75}}>
                      <div style={{ float: 'left'}}>
                        <div style={{border: 'solid 0px red', marginTop: -3}}>
                          <svg  height={40} 
                                width={40} 
                                style={{display: 'inline-block', marginRight: 100, cursor: 'pointer'}}
                                onClick={() => {
                                  const show = showHamburgerMenu;
                                  setShowHamburgerMenu(!show);
                                }}
                          >
                            <rect x={10} y={20} height={2} width={40} fill={'#03537A'} stroke={'#03537A'} />
                            <rect x={10} y={28} height={2} width={40} fill={'#03537A'} stroke={'#03537A'} />
                            <rect x={10} y={36} height={2} width={40} fill={'#03537A'} stroke={'#03537A'} />
                          </svg>

                          <div style={{   display: showHamburgerMenu ? 'block' : 'none',
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        border: 'solid 2px gray',
                                        borderRadius: 5,
                                        marginLeft: -150,
                                        width: 350
                              }}
                          >
                            <ul style={{listStyle: 'none', textAlign: 'center', marginLeft: -40}}>
                              <li style={{marginBottom: 10, paddingLeft: 0}}>
                                <a href="https://codexome.com">
                                  CODEXOME&reg; ANALYTICAL SOFTWARE
                                </a>
                              </li>
                              <li style={{marginBottom: 10, paddingLeft: 0}}>
                                <a href="https://cornerstonegenomics.com/news/">
                                  NEWS
                                </a>
                              </li>
                              <li style={{marginBottom: 10, paddingLeft: 0}}>
                                <a href="https://cornerstonegenomics.com/team/">
                                  TEAM
                                </a>
                              </li>
                              <li style={{marginLeft: 65}}>
                               <div style={{   backgroundColor: '#02547A', 
                                                fontFamily: 'Arial', 
                                                fontSize: 12,
                                                width: 'fit-content', 
                                                border: 'solid 1px #333', 
                                                borderRadius: 5, 
                                                paddingTop: 17, 
                                                paddingBottom: 17,
                                                paddingLeft: 70,
                                                paddingRight: 70,
                                                color: 'white',
                                                cursor: 'pointer'
                                                }}
                                      onClick={() => {
                                        window.location.href= "https://cornerstonegenomics.com/contact-us/";
                                      }}
                                >
                                  CONTACT US
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div style={{   
                                      backgroundColor: '#03537A', 
                                      fontFamily: 'Arial', 
                                      fontSize: 12,
                                      float: 'right', 
                                      width: 'fit-content', 
                                      border: 'solid 1px #03537A', 
                                      borderRadius: 5, 
                                      paddingTop: 17, 
                                      paddingBottom: 17,
                                      paddingLeft: 70,
                                      paddingRight: 70,
                                      color: 'white',
                                      cursor: 'pointer'
                                      }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com/contact-us/";
                            }}
                      >
                        CONTACT
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: '1%', marginTop: 150}}>
                  <div style={{width: 800, marginLeft: 'auto', marginRight: 'auto'}}>
                    <div style={{ marginTop: 200, height: 110, backgroundColor: '#EFEFEFBB', borderRadius: 10, fontSize: 20, padding: 20, textAlign: 'center'}}>
                      <div id="loadingDiv" style={{display: validationResults === 'Not Started' ? 'block' : 'none', marginTop: 40}}>
                        Please wait, validating email ...
                      </div>

                      <div id="successDiv" style={{display: validationResults === 'Validation Success' ? 'block' : 'none', marginTop: 40}}>
                        Thank you, your email has been validated!
                      </div>

                      <div id="failureDiv" style={{display: validationResults === 'Validation Error' ? 'block' : 'none', marginTop: 30}}>
                        I'm sorry, there was an error validating your email.  Please try again or contact us 
                        at <a href="mailto:info@cornerstonegenomics.com">info@cornerstonegenomics.com</a> if this message persists.
                      </div>
                    </div>
                    <div style={{ marginTop: 100, marginBottom: 300, height: 110, backgroundColor: '#EFEFEFBB', borderRadius: 10, padding: 20}}>
                      <h2 style={{color: '#03537A', marginTop: 0}}>What Genes do <i>you</i> currently use in your research? </h2>
                      
                      Explore our extensive database of genes, and get a quick peek at our multiple sequence alignment, containing 54 genera, 70% of which represents diverse primates!

                      <div style={{marginTop: 20, marginLeft: 530, marginRight: -100, backgroundColor: '#03537A', borderRadius: 10, border: 'solid 1px #EFEFEF', padding: 15, width: 'fit-content'}}>
                        <a href="../home" style={{color: 'white', textDecorationLine: 'none'}}>EXPLORE OUR DATABASE NOW</a>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  )
}
