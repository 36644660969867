const GoodGenes: Array<string> = [
  'a1cf',
  'a3galt2',
  'aaas',
  'aacs',
  'aagab',
  'aak1',
  'aamdc',
  'aamp',
  'aanat',
  'aar2',
  'aars1',
  'aars2',
  'aarsd1',
  'aasdhppt',
  'aass',
  'aatf',
  'aatk',
  'abat',
  'abca1',
  'abca12',
  'abca3',
  'abca4',
  'abca5',
  'abca7',
  'abcb1',
  'abcb11',
  'abcb4',
  'abcb5',
  'abcb6',
  'abcb7',
  'abcb8',
  'abcb9',
  'abcc1',
  'abcc10',
  'abcc2',
  'abcc3',
  'abcc5',
  'abcc6',
  'abcc8',
  'abcc9',
  'abcd1',
  'abcd2',
  'abcd3',
  'abcd4',
  'abce1',
  'abcf1',
  'abcf2',
  'abcf3',
  'abcg1',
  'abcg4',
  'abcg5',
  'abcg8',
  'abhd10',
  'abhd11',
  'abhd12',
  'abhd13',
  'abhd14a',
  'abhd14b',
  'abhd15',
  'abhd16a',
  'abhd16b',
  'abhd17a',
  'abhd17b',
  'abhd17c',
  'abhd18',
  'abhd2',
  'abhd3',
  'abhd4',
  'abhd5',
  'abhd6',
  'abhd8',
  'abi1',
  'abi2',
  'abi3',
  'abitram',
  'abl1',
  'abl2',
  'ablim1',
  'ablim2',
  'ablim3',
  'abr',
  'abra',
  'abracl',
  'abraxas2',
  'abt1',
  'abtb2',
  'acaa1',
  'acaa2',
  'acaca',
  'acacb',
  'acad10',
  'acad11',
  'acad8',
  'acad9',
  'acadm',
  'acads',
  'acadvl',
  'acap1',
  'acap2',
  'acap3',
  'acat1',
  'acat2',
  'acbd3',
  'acbd4',
  'acbd5',
  'acbd6',
  'accs',
  'ace',
  'acer1',
  'acer2',
  'acer3',
  'ache',
  'acin1',
  'ackr1',
  'ackr2',
  'ackr3',
  'ackr4',
  'acly',
  'acmsd',
  'aco1',
  'aco2',
  'acod1',
  'acot11',
  'acot4',
  'acot6',
  'acot7',
  'acot8',
  'acot9',
  'acox1',
  'acox2',
  'acoxl',
  'acp2',
  'acp3',
  'acp4',
  'acp5',
  'acp6',
  'acp7',
  'acrbp',
  'acsbg1',
  'acsf2',
  'acsl1',
  'acsl3',
  'acsl4',
  'acsl6',
  'acsm3',
  'acsm4',
  'acsm5',
  'acss1',
  'acss2',
  'acss3',
  'acta1',
  'acta2',
  'actb',
  'actbl2',
  'actc1',
  'actg1',
  'actg2',
  'actl10',
  'actl6a',
  'actl6b',
  'actl7a',
  'actl7b',
  'actl8',
  'actl9',
  'actn1',
  'actn2',
  'actn3',
  'actn4',
  'actr10',
  'actr1a',
  'actr1b',
  'actr2',
  'actr3',
  'actr3b',
  'actr5',
  'actr6',
  'actr8',
  'actrt1',
  'actrt2',
  'acvr1',
  'acvr1b',
  'acvr1c',
  'acvr2a',
  'acvr2b',
  'acvrl1',
  'acy1',
  'acy3',
  'acyp1',
  'ada',
  'adad1',
  'adal',
  'adam10',
  'adam11',
  'adam15',
  'adam17',
  'adam19',
  'adam21',
  'adam22',
  'adam23',
  'adam33',
  'adam9',
  'adamts1',
  'adamts10',
  'adamts12',
  'adamts14',
  'adamts15',
  'adamts16',
  'adamts17',
  'adamts18',
  'adamts19',
  'adamts2',
  'adamts3',
  'adamts4',
  'adamts5',
  'adamts6',
  'adamts8',
  'adamts9',
  'adamtsl1',
  'adamtsl2',
  'adamtsl3',
  'adamtsl4',
  'adamtsl5',
  'adap1',
  'adap2',
  'adarb1',
  'adarb2',
  'adat1',
  'adat2',
  'adat3',
  'adck1',
  'adck2',
  'adcy1',
  'adcy2',
  'adcy3',
  'adcy4',
  'adcy5',
  'adcy6',
  'adcy7',
  'adcy8',
  'adcy9',
  'adcyap1',
  'adcyap1r1',
  'add1',
  'add2',
  'add3',
  'adgra1',
  'adgra2',
  'adgra3',
  'adgrb1',
  'adgrb2',
  'adgrb3',
  'adgrd2',
  'adgrf2',
  'adgrf3',
  'adgrf4',
  'adgrg1',
  'adgrg6',
  'adgrl1',
  'adgrl2',
  'adgrl3',
  'adgrv1',
  'adh1a',
  'adh1b',
  'adh1c',
  'adh5',
  'adh7',
  'adhfe1',
  'adi1',
  'adig',
  'adipoq',
  'adipor1',
  'adipor2',
  'adirf',
  'adk',
  'adm',
  'ado',
  'adora1',
  'adora2a',
  'adora2b',
  'adora3',
  'adpgk',
  'adprh',
  'adprm',
  'adprs',
  'adra1a',
  'adra1b',
  'adra1d',
  'adra2a',
  'adra2b',
  'adra2c',
  'adrb1',
  'adrb2',
  'adrb3',
  'adrm1',
  'adsl',
  'adss1',
  'adss2',
  'aebp1',
  'aebp2',
  'aen',
  'afap1',
  'afap1l1',
  'afap1l2',
  'afdn',
  'aff2',
  'aff3',
  'aff4',
  'afg1l',
  'afg3l2',
  'aftph',
  'aga',
  'agap1',
  'agap2',
  'agap3',
  'agbl4',
  'agbl5',
  'ager',
  'agfg1',
  'agfg2',
  'agk',
  'agl',
  'agmat',
  'agmo',
  'ago1',
  'ago2',
  'ago3',
  'ago4',
  'agpat1',
  'agpat2',
  'agpat3',
  'agpat4',
  'agpat5',
  'agps',
  'agr2',
  'agrn',
  'agrp',
  'agtpbp1',
  'agtr1',
  'agtrap',
  'agxt',
  'agxt2',
  'ahcy',
  'ahcyl1',
  'ahcyl2',
  'ahdc1',
  'ahsa1',
  'aicda',
  'aida',
  'aif1',
  'aif1l',
  'aifm1',
  'aifm2',
  'aifm3',
  'aig1',
  'aimp1',
  'aimp2',
  'aip',
  'ajap1',
  'ajm1',
  'ajuba',
  'ak1',
  'ak2',
  'ak3',
  'ak4',
  'ak5',
  'ak6',
  'ak8',
  'akap10',
  'akap3',
  'akap6',
  'akap8l',
  'akip1',
  'akirin1',
  'akirin2',
  'akna',
  'akr1a1',
  'akr1b1',
  'akr1d1',
  'akr7a2',
  'akr7a3',
  'akt1',
  'akt1s1',
  'akt2',
  'akt3',
  'aktip',
  'alad',
  'alas1',
  'alas2',
  'alcam',
  'aldh16a1',
  'aldh18a1',
  'aldh1a1',
  'aldh1a2',
  'aldh1a3',
  'aldh1l1',
  'aldh1l2',
  'aldh2',
  'aldh3a1',
  'aldh3a2',
  'aldh3b1',
  'aldh3b2',
  'aldh4a1',
  'aldh5a1',
  'aldh6a1',
  'aldh7a1',
  'aldh8a1',
  'aldh9a1',
  'aldoa',
  'aldob',
  'aldoc',
  'alg1',
  'alg11',
  'alg12',
  'alg14',
  'alg1l2',
  'alg2',
  'alg3',
  'alg5',
  'alg6',
  'alg8',
  'alg9',
  'alk',
  'alkbh1',
  'alkbh2',
  'alkbh3',
  'alkbh4',
  'alkbh5',
  'alkbh6',
  'alkbh7',
  'alkbh8',
  'alox12',
  'alox12b',
  'alox15',
  'alox5',
  'alox5ap',
  'aloxe3',
  'alpk3',
  'alpl',
  'als2',
  'als2cl',
  'alx1',
  'alx3',
  'alx4',
  'alyref',
  'amacr',
  'ambra1',
  'amd1',
  'amdhd2',
  'amelx',
  'amer1',
  'amer3',
  'amfr',
  'amhr2',
  'amigo1',
  'amigo2',
  'amigo3',
  'ammecr1',
  'ammecr1l',
  'amn1',
  'amot',
  'amotl1',
  'amotl2',
  'ampd1',
  'ampd2',
  'ampd3',
  'amph',
  'amt',
  'amy1a',
  'amy1b',
  'amy1c',
  'amy2a',
  'amy2b',
  'amz1',
  'amz2',
  'anapc1',
  'anapc10',
  'anapc11',
  'anapc13',
  'anapc15',
  'anapc16',
  'anapc2',
  'anapc4',
  'anapc5',
  'anapc7',
  'angel1',
  'angel2',
  'angpt1',
  'angptl1',
  'angptl2',
  'angptl4',
  'angptl5',
  'angptl6',
  'angptl7',
  'angptl8',
  'anhx',
  'ank1',
  'ank2',
  'ank3',
  'ankar',
  'ankdd1a',
  'ankdd1b',
  'ankfn1',
  'ankfy1',
  'ankh',
  'ankhd1',
  'ankib1',
  'ankmy2',
  'ankra2',
  'ankrd1',
  'ankrd10',
  'ankrd12',
  'ankrd13a',
  'ankrd13b',
  'ankrd13c',
  'ankrd13d',
  'ankrd17',
  'ankrd2',
  'ankrd23',
  'ankrd28',
  'ankrd29',
  'ankrd33b',
  'ankrd34a',
  'ankrd34b',
  'ankrd35',
  'ankrd39',
  'ankrd40',
  'ankrd42',
  'ankrd44',
  'ankrd45',
  'ankrd46',
  'ankrd49',
  'ankrd52',
  'ankrd54',
  'ankrd55',
  'ankrd6',
  'ankrd63',
  'ankrd66',
  'ankrd9',
  'anks1a',
  'anks1b',
  'anks3',
  'anks4b',
  'anks6',
  'ankub1',
  'ankzf1',
  'ano1',
  'ano2',
  'ano3',
  'ano4',
  'ano6',
  'ano8',
  'anp32a',
  'anp32d',
  'anp32e',
  'anpep',
  'antkmt',
  'antxr1',
  'anxa1',
  'anxa11',
  'anxa2',
  'anxa4',
  'anxa5',
  'anxa6',
  'anxa7',
  'anxa8',
  'anxa8l1',
  'anxa9',
  'aoc1',
  'aoc2',
  'aoc3',
  'aopep',
  'aox1',
  'ap1b1',
  'ap1g1',
  'ap1g2',
  'ap1m1',
  'ap1m2',
  'ap1s1',
  'ap1s2',
  'ap1s3',
  'ap2a1',
  'ap2a2',
  'ap2b1',
  'ap2m1',
  'ap2s1',
  'ap3b1',
  'ap3b2',
  'ap3m1',
  'ap3m2',
  'ap3s1',
  'ap3s2',
  'ap4b1',
  'ap4e1',
  'ap4m1',
  'ap4s1',
  'ap5b1',
  'ap5m1',
  'ap5s1',
  'ap5z1',
  'apaf1',
  'apba1',
  'apba2',
  'apba3',
  'apbb1',
  'apbb2',
  'apbb3',
  'apc',
  'apcdd1',
  'apcdd1l',
  'apeh',
  'apex1',
  'apex2',
  'aph1a',
  'aph1b',
  'api5',
  'apln',
  'aplnr',
  'aplp1',
  'aplp2',
  'apmap',
  'apoa1',
  'apoa4',
  'apoa5',
  'apobec2',
  'apoe',
  'apold1',
  'apom',
  'apoo',
  'apool',
  'app',
  'appbp2',
  'appl1',
  'appl2',
  'aprt',
  'aptx',
  'aqp1',
  'aqp10',
  'aqp11',
  'aqp2',
  'aqp3',
  'aqp4',
  'aqp5',
  'aqp6',
  'aqp8',
  'aqp9',
  'aqr',
  'ar',
  'araf',
  'arap1',
  'arap3',
  'arc',
  'arcn1',
  'arel1',
  'arf1',
  'arf3',
  'arf4',
  'arf5',
  'arf6',
  'arfgap1',
  'arfgap2',
  'arfgef1',
  'arfgef2',
  'arfgef3',
  'arfip1',
  'arfip2',
  'arfrp1',
  'arg1',
  'arg2',
  'arglu1',
  'arhgap1',
  'arhgap10',
  'arhgap11b',
  'arhgap12',
  'arhgap15',
  'arhgap17',
  'arhgap18',
  'arhgap19',
  'arhgap20',
  'arhgap22',
  'arhgap23',
  'arhgap24',
  'arhgap25',
  'arhgap26',
  'arhgap27',
  'arhgap28',
  'arhgap29',
  'arhgap30',
  'arhgap31',
  'arhgap32',
  'arhgap33',
  'arhgap35',
  'arhgap36',
  'arhgap39',
  'arhgap4',
  'arhgap40',
  'arhgap44',
  'arhgap6',
  'arhgap9',
  'arhgdia',
  'arhgdib',
  'arhgdig',
  'arhgef1',
  'arhgef10l',
  'arhgef11',
  'arhgef12',
  'arhgef15',
  'arhgef16',
  'arhgef17',
  'arhgef18',
  'arhgef19',
  'arhgef2',
  'arhgef25',
  'arhgef26',
  'arhgef3',
  'arhgef33',
  'arhgef37',
  'arhgef39',
  'arhgef40',
  'arhgef6',
  'arhgef7',
  'arhgef9',
  'arid1a',
  'arid1b',
  'arid2',
  'arid3a',
  'arid3b',
  'arid3c',
  'arid4a',
  'arid4b',
  'arid5a',
  'arid5b',
  'arih1',
  'arih2',
  'arl1',
  'arl10',
  'arl11',
  'arl13b',
  'arl14ep',
  'arl14epl',
  'arl15',
  'arl16',
  'arl2',
  'arl2bp',
  'arl3',
  'arl4a',
  'arl4c',
  'arl5a',
  'arl5b',
  'arl6',
  'arl6ip1',
  'arl6ip4',
  'arl6ip5',
  'arl6ip6',
  'arl8a',
  'arl8b',
  'armc1',
  'armc12',
  'armc2',
  'armc5',
  'armc6',
  'armc7',
  'armc8',
  'armc9',
  'armcx1',
  'armcx2',
  'armcx3',
  'armcx6',
  'armh1',
  'armh3',
  'arnt',
  'arnt2',
  'arntl',
  'arntl2',
  'arpc1a',
  'arpc1b',
  'arpc2',
  'arpc3',
  'arpc4',
  'arpc5',
  'arpc5l',
  'arpin',
  'arpp19',
  'arpp21',
  'arr3',
  'arrb1',
  'arrb2',
  'arrdc1',
  'arrdc2',
  'arrdc3',
  'arrdc4',
  'arsa',
  'arsb',
  'arsg',
  'arsi',
  'arsj',
  'arsk',
  'art1',
  'art5',
  'artn',
  'arv1',
  'arvcf',
  'arx',
  'asah2b',
  'asap1',
  'asap2',
  'asap3',
  'asb1',
  'asb10',
  'asb11',
  'asb12',
  'asb13',
  'asb14',
  'asb15',
  'asb16',
  'asb18',
  'asb2',
  'asb3',
  'asb4',
  'asb5',
  'asb6',
  'asb7',
  'asb8',
  'asb9',
  'ascc1',
  'ascc2',
  'ascc3',
  'ascl1',
  'ascl2',
  'ascl3',
  'ascl4',
  'ascl5',
  'asf1a',
  'asf1b',
  'asgr1',
  'ash1l',
  'ash2l',
  'asic1',
  'asic2',
  'asic3',
  'asic4',
  'asl',
  'asns',
  'asnsd1',
  'aspa',
  'aspdh',
  'aspg',
  'asphd1',
  'asphd2',
  'aspn',
  'asprv1',
  'asrgl1',
  'ass1',
  'astn1',
  'astn2',
  'asxl1',
  'asxl2',
  'asxl3',
  'atad1',
  'atad2b',
  'atad3a',
  'atat1',
  'atcay',
  'ate1',
  'atf1',
  'atf2',
  'atf3',
  'atf4',
  'atf5',
  'atf6',
  'atf6b',
  'atf7',
  'atf7ip',
  'atg101',
  'atg12',
  'atg13',
  'atg14',
  'atg16l1',
  'atg16l2',
  'atg2a',
  'atg2b',
  'atg3',
  'atg4a',
  'atg4b',
  'atg4c',
  'atg4d',
  'atg5',
  'atg7',
  'atg9a',
  'atg9b',
  'atic',
  'atl1',
  'atl2',
  'atl3',
  'atm',
  'atn1',
  'atoh1',
  'atoh7',
  'atox1',
  'atp10b',
  'atp11b',
  'atp11c',
  'atp12a',
  'atp13a1',
  'atp13a2',
  'atp13a3',
  'atp13a4',
  'atp13a5',
  'atp1a1',
  'atp1a2',
  'atp1a3',
  'atp1a4',
  'atp1b1',
  'atp1b2',
  'atp1b4',
  'atp23',
  'atp2a1',
  'atp2a2',
  'atp2a3',
  'atp2b1',
  'atp2b2',
  'atp2b3',
  'atp2b4',
  'atp2c1',
  'atp4a',
  'atp4b',
  'atp5f1a',
  'atp5f1b',
  'atp5f1c',
  'atp5f1d',
  'atp5f1e',
  'atp5mc1',
  'atp5mc2',
  'atp5mc3',
  'atp5me',
  'atp5mg',
  'atp5mgl',
  'atp5mk',
  'atp5pb',
  'atp5pf',
  'atp5po',
  'atp6ap1',
  'atp6ap2',
  'atp6v0a1',
  'atp6v0a2',
  'atp6v0a4',
  'atp6v0b',
  'atp6v0c',
  'atp6v0d1',
  'atp6v0d2',
  'atp6v0e1',
  'atp6v0e2',
  'atp6v1a',
  'atp6v1b1',
  'atp6v1b2',
  'atp6v1c1',
  'atp6v1c2',
  'atp6v1d',
  'atp6v1e1',
  'atp6v1e2',
  'atp6v1f',
  'atp6v1fnb',
  'atp6v1g1',
  'atp6v1g2',
  'atp6v1h',
  'atp7a',
  'atp7b',
  'atp8a2',
  'atp8b1',
  'atp8b2',
  'atp8b4',
  'atp9a',
  'atpaf1',
  'atpaf2',
  'atr',
  'atraid',
  'atrip',
  'atrn',
  'atrnl1',
  'atrx',
  'atxn10',
  'atxn1l',
  'atxn2',
  'atxn2l',
  'atxn3',
  'atxn7',
  'atxn7l1',
  'atxn7l2',
  'atxn7l3',
  'atxn7l3b',
  'auh',
  'aup1',
  'aurka',
  'aurkaip1',
  'aurkb',
  'aurkc',
  'auts2',
  'avil',
  'avl9',
  'avp',
  'avpi1',
  'avpr1a',
  'avpr1b',
  'avpr2',
  'awat1',
  'awat2',
  'axin1',
  'axin2',
  'axl',
  'azi2',
  'azin1',
  'azin2',
  'b3galt1',
  'b3galt2',
  'b3galt4',
  'b3galt9',
  'b3gat1',
  'b3gat2',
  'b3gat3',
  'b3glct',
  'b3gnt4',
  'b3gnt6',
  'b3gnt7',
  'b3gnt8',
  'b3gnt9',
  'b4galnt1',
  'b4galnt3',
  'b4galnt4',
  'b4galt1',
  'b4galt2',
  'b4galt3',
  'b4galt4',
  'b4galt5',
  'b4galt6',
  'b4galt7',
  'b4gat1',
  'b9d1',
  'b9d2',
  'baalc',
  'babam1',
  'babam2',
  'bace1',
  'bace2',
  'bach1',
  'bach2',
  'bad',
  'bag1',
  'bag2',
  'bag3',
  'bag4',
  'bag6',
  'bahcc1',
  'bahd1',
  'baiap2',
  'baiap2l2',
  'baiap3',
  'bak1',
  'bambi',
  'banf1',
  'banf2',
  'banp',
  'bap1',
  'barhl1',
  'barhl2',
  'barx1',
  'barx2',
  'batf',
  'batf3',
  'bax',
  'baz1a',
  'baz1b',
  'baz2a',
  'baz2b',
  'bbc3',
  'bbln',
  'bbox1',
  'bbs1',
  'bbs2',
  'bbs5',
  'bbx',
  'bcam',
  'bcan',
  'bcap31',
  'bcar1',
  'bcar3',
  'bcas2',
  'bcas3',
  'bcat1',
  'bcat2',
  'bccip',
  'bcdin3d',
  'bckdha',
  'bckdhb',
  'bckdk',
  'bcl10',
  'bcl11a',
  'bcl11b',
  'bcl2',
  'bcl2l1',
  'bcl2l11',
  'bcl2l12',
  'bcl2l2-pabpn1',
  'bcl2l2',
  'bcl3',
  'bcl6',
  'bcl6b',
  'bcl7a',
  'bcl7b',
  'bcl7c',
  'bcl9',
  'bcl9l',
  'bclaf1',
  'bco2',
  'bcor',
  'bcorl1',
  'bcr',
  'bcs1l',
  'bdh1',
  'bdh2',
  'bdkrb1',
  'bdkrb2',
  'bdnf',
  'bean1',
  'becn1',
  'begain',
  'bend4',
  'bend5',
  'bend6',
  'bend7',
  'best1',
  'best2',
  'best4',
  'bet1l',
  'bex1',
  'bex2',
  'bex3',
  'bex4',
  'bex5',
  'bfar',
  'bfsp2',
  'bglap',
  'bgn',
  'bhlha9',
  'bhlhb9',
  'bhlhe22',
  'bhlhe40',
  'bhlhe41',
  'bhmt',
  'bhmt2',
  'bicc1',
  'bicd1',
  'bicd2',
  'bicdl1',
  'bicdl2',
  'bicra',
  'bicral',
  'bin1',
  'bin2',
  'bin3',
  'birc2',
  'birc3',
  'birc5',
  'birc6',
  'bivm',
  'blcap',
  'blk',
  'blmh',
  'blnk',
  'bloc1s1',
  'bloc1s2',
  'bloc1s3',
  'bloc1s5',
  'bloc1s6',
  'blvra',
  'blvrb',
  'blzf1',
  'bmerb1',
  'bmf',
  'bmi1',
  'bmp1',
  'bmp10',
  'bmp2',
  'bmp4',
  'bmp5',
  'bmp6',
  'bmp7',
  'bmp8a',
  'bmp8b',
  'bmper',
  'bmpr1a',
  'bmpr1b',
  'bmpr2',
  'bms1',
  'bmt2',
  'bmx',
  'bnc1',
  'bnc2',
  'bnip1',
  'bnip2',
  'bnip3',
  'bnip3l',
  'bnipl',
  'boc',
  'bod1',
  'bod1l2',
  'bok',
  'bola1',
  'bola2',
  'bola2b',
  'bola3',
  'boll',
  'bora',
  'borcs5',
  'borcs6',
  'borcs8',
  'bphl',
  'bpifb4',
  'bpifb6',
  'bpnt1',
  'bpnt2',
  'bptf',
  'braf',
  'brap',
  'brat1',
  'brcc3',
  'brd1',
  'brd2',
  'brd3',
  'brd3os',
  'brd4',
  'brd7',
  'brd8',
  'brd9',
  'brf1',
  'brf2',
  'bri3',
  'bri3bp',
  'bricd5',
  'brinp1',
  'brinp2',
  'brinp3',
  'brix1',
  'brk1',
  'brms1',
  'brms1l',
  'brox',
  'brpf1',
  'brpf3',
  'brs3',
  'brsk1',
  'brsk2',
  'brwd1',
  'brwd3',
  'bscl2',
  'bsdc1',
  'bsn',
  'bspry',
  'bsx',
  'btaf1',
  'btbd1',
  'btbd10',
  'btbd11',
  'btbd18',
  'btbd19',
  'btbd3',
  'btbd6',
  'btbd7',
  'btbd8',
  'btbd9',
  'btf3',
  'btf3l4',
  'btg1',
  'btg2',
  'btg3',
  'btg4',
  'btk',
  'btrc',
  'bub1',
  'bub1b',
  'bub3',
  'bud31',
  'bves',
  'bysl',
  'bzw1',
  'bzw2',
  'c10orf105',
  'c10orf143',
  'c10orf53',
  'c10orf71',
  'c11orf1',
  'c11orf42',
  'c11orf53',
  'c11orf54',
  'c11orf58',
  'c11orf68',
  'c11orf87',
  'c11orf94',
  'c11orf97',
  'c11orf98',
  'c12orf29',
  'c12orf4',
  'c12orf57',
  'c12orf73',
  'c12orf76',
  'c13orf42',
  'c14orf119',
  'c14orf132',
  'c14orf28',
  'c14orf93',
  'c15orf39',
  'c15orf40',
  'c15orf61',
  'c15orf62',
  'c16orf72',
  'c16orf74',
  'c16orf87',
  'c16orf89',
  'c17orf100',
  'c17orf107',
  'c17orf113',
  'c17orf49',
  'c17orf50',
  'c17orf75',
  'c17orf98',
  'c18orf21',
  'c18orf25',
  'c19orf12',
  'c19orf25',
  'c19orf47',
  'c19orf53',
  'c19orf67',
  'c19orf73',
  'c19orf81',
  'c19orf84',
  'c1d',
  'c1galt1',
  'c1galt1c1',
  'c1galt1c1l',
  'c1orf109',
  'c1orf115',
  'c1orf116',
  'c1orf122',
  'c1orf146',
  'c1orf198',
  'c1orf21',
  'c1orf210',
  'c1orf216',
  'c1orf226',
  'c1orf232',
  'c1orf43',
  'c1orf50',
  'c1orf52',
  'c1orf56',
  'c1orf74',
  'c1orf94',
  'c1qa',
  'c1qb',
  'c1qbp',
  'c1qc',
  'c1ql1',
  'c1ql2',
  'c1ql3',
  'c1ql4',
  'c1qtnf2',
  'c1qtnf3',
  'c1qtnf5',
  'c1qtnf7',
  'c1qtnf8',
  'c1r',
  'c1rl',
  'c2',
  'c20orf202',
  'c20orf204',
  'c20orf27',
  'c20orf85',
  'c21orf140',
  'c21orf62',
  'c21orf91',
  'c22orf23',
  'c22orf31',
  'c22orf39',
  'c2cd2l',
  'c2cd3',
  'c2cd5',
  'c2orf42',
  'c2orf49',
  'c2orf68',
  'c2orf69',
  'c2orf76',
  'c3orf18',
  'c3orf38',
  'c3orf62',
  'c3orf80',
  'c3orf84',
  'c4orf33',
  'c4orf48',
  'c4orf54',
  'c5ar1',
  'c5ar2',
  'c5orf24',
  'c5orf49',
  'c5orf63',
  'c6orf132',
  'c6orf136',
  'c6orf163',
  'c6orf47',
  'c6orf62',
  'c6orf89',
  'c7orf25',
  'c8g',
  'c8orf34',
  'c8orf76',
  'c8orf82',
  'c8orf88',
  'c9orf24',
  'c9orf64',
  'c9orf72',
  'c9orf78',
  'c9orf85',
  'ca10',
  'ca11',
  'ca12',
  'ca13',
  'ca14',
  'ca2',
  'ca3',
  'ca5b',
  'ca7',
  'ca8',
  'ca9',
  'caap1',
  'cab39',
  'cab39l',
  'cabin1',
  'cabp1',
  'cabp2',
  'cabp5',
  'cabp7',
  'cacfd1',
  'cachd1',
  'cacna1a',
  'cacna1b',
  'cacna1c',
  'cacna1d',
  'cacna1e',
  'cacna1f',
  'cacna1g',
  'cacna1h',
  'cacna1i',
  'cacna1s',
  'cacna2d1',
  'cacna2d2',
  'cacna2d3',
  'cacna2d4',
  'cacnb1',
  'cacnb2',
  'cacnb3',
  'cacnb4',
  'cacng1',
  'cacng2',
  'cacng3',
  'cacng4',
  'cacng5',
  'cacng6',
  'cacng7',
  'cactin',
  'cacul1',
  'cacybp',
  'cad',
  'cadm1',
  'cadm2',
  'cadm3',
  'cadm4',
  'cadps',
  'cadps2',
  'calb1',
  'calb2',
  'calca',
  'calcb',
  'calcoco1',
  'calcrl',
  'calhm1',
  'calhm2',
  'calhm3',
  'calhm5',
  'calm1',
  'calm2',
  'calm3',
  'calml3',
  'calml4',
  'caln1',
  'calr',
  'calu',
  'caly',
  'camk1',
  'camk1d',
  'camk1g',
  'camk2a',
  'camk2b',
  'camk2d',
  'camk2g',
  'camk2n1',
  'camk2n2',
  'camk4',
  'camkk1',
  'camkk2',
  'camkmt',
  'camkv',
  'camlg',
  'camsap2',
  'camsap3',
  'camta1',
  'camta2',
  'cand1',
  'cand2',
  'cant1',
  'canx',
  'cap1',
  'cap2',
  'capg',
  'capn1',
  'capn10',
  'capn12',
  'capn15',
  'capn2',
  'capn3',
  'capn5',
  'capn6',
  'capn7',
  'capn9',
  'capns1',
  'capns2',
  'caprin1',
  'caprin2',
  'caps',
  'capsl',
  'capza1',
  'capza2',
  'capza3',
  'capzb',
  'card10',
  'card11',
  'card14',
  'card19',
  'card9',
  'carf',
  'carhsp1',
  'carm1',
  'carmil2',
  'carmil3',
  'carnmt1',
  'carns1',
  'cartpt',
  'casc3',
  'casd1',
  'cask',
  'caskin1',
  'caskin2',
  'casp2',
  'casp3',
  'casq1',
  'casq2',
  'casr',
  'castor1',
  'castor2',
  'casz1',
  'catip',
  'cav1',
  'cav2',
  'cav3',
  'cavin1',
  'cavin3',
  'cavin4',
  'cbarp',
  'cbfa2t2',
  'cbfa2t3',
  'cbfb',
  'cbl',
  'cblb',
  'cblc',
  'cblif',
  'cbll1',
  'cbln1',
  'cbln2',
  'cbln3',
  'cbln4',
  'cbr1',
  'cbr3',
  'cbr4',
  'cbs',
  'cbx1',
  'cbx2',
  'cbx3',
  'cbx4',
  'cbx5',
  'cbx6',
  'cbx7',
  'cbx8',
  'cby1',
  'cc2d1a',
  'cc2d1b',
  'cc2d2a',
  'ccar1',
  'ccar2',
  'ccbe1',
  'ccdc102a',
  'ccdc103',
  'ccdc105',
  'ccdc106',
  'ccdc112',
  'ccdc115',
  'ccdc117',
  'ccdc12',
  'ccdc120',
  'ccdc124',
  'ccdc126',
  'ccdc13',
  'ccdc134',
  'ccdc136',
  'ccdc142',
  'ccdc149',
  'ccdc153',
  'ccdc158',
  'ccdc167',
  'ccdc17',
  'ccdc170',
  'ccdc171',
  'ccdc177',
  'ccdc18',
  'ccdc182',
  'ccdc184',
  'ccdc186',
  'ccdc188',
  'ccdc22',
  'ccdc24',
  'ccdc28a',
  'ccdc28b',
  'ccdc3',
  'ccdc32',
  'ccdc33',
  'ccdc43',
  'ccdc47',
  'ccdc51',
  'ccdc6',
  'ccdc61',
  'ccdc65',
  'ccdc69',
  'ccdc70',
  'ccdc71',
  'ccdc71l',
  'ccdc80',
  'ccdc85a',
  'ccdc85b',
  'ccdc85c',
  'ccdc87',
  'ccdc88a',
  'ccdc88b',
  'ccdc88c',
  'ccdc89',
  'ccdc9',
  'ccdc90b',
  'ccdc91',
  'ccdc92',
  'ccdc92b',
  'ccdc93',
  'ccdc97',
  'ccer2',
  'cchcr1',
  'ccin',
  'cck',
  'cckar',
  'cckbr',
  'ccl19',
  'ccl2',
  'ccl21',
  'ccl3',
  'ccl3l3',
  'ccl4',
  'ccl5',
  'ccm2',
  'ccm2l',
  'ccn1',
  'ccn2',
  'ccn3',
  'ccn4',
  'ccn5',
  'ccn6',
  'ccna2',
  'ccnb1',
  'ccnb1ip1',
  'ccnb2',
  'ccnc',
  'ccnd1',
  'ccnd2',
  'ccnd3',
  'ccndbp1',
  'ccne2',
  'ccnf',
  'ccng1',
  'ccng2',
  'ccnh',
  'ccni',
  'ccnj',
  'ccnjl',
  'ccnl1',
  'ccnl2',
  'ccno',
  'ccnq',
  'ccnt1',
  'ccnt2',
  'ccny',
  'ccnyl1',
  'ccp110',
  'ccr1',
  'ccr10',
  'ccr2',
  'ccr3',
  'ccr4',
  'ccr5',
  'ccr7',
  'ccr8',
  'ccr9',
  'ccs',
  'ccser1',
  'ccser2',
  'cct2',
  'cct3',
  'cct4',
  'cct5',
  'cct6a',
  'cct6b',
  'cct7',
  'cct8',
  'cd101',
  'cd14',
  'cd151',
  'cd160',
  'cd163',
  'cd164l2',
  'cd19',
  'cd24',
  'cd248',
  'cd27',
  'cd276',
  'cd2ap',
  'cd2bp2',
  'cd302',
  'cd37',
  'cd40lg',
  'cd53',
  'cd6',
  'cd63',
  'cd68',
  'cd74',
  'cd81',
  'cd82',
  'cd8b',
  'cd8b2',
  'cd9',
  'cd93',
  'cda',
  'cdadc1',
  'cdan1',
  'cdc14a',
  'cdc14b',
  'cdc20',
  'cdc23',
  'cdc25a',
  'cdc25b',
  'cdc26',
  'cdc27',
  'cdc34',
  'cdc37',
  'cdc37l1',
  'cdc40',
  'cdc42',
  'cdc42bpa',
  'cdc42bpb',
  'cdc42bpg',
  'cdc42ep2',
  'cdc42ep3',
  'cdc42ep4',
  'cdc42ep5',
  'cdc42se1',
  'cdc45',
  'cdc5l',
  'cdc6',
  'cdc7',
  'cdc73',
  'cdca3',
  'cdca4',
  'cdca5',
  'cdca7',
  'cdca7l',
  'cdca8',
  'cdcp1',
  'cdcp2',
  'cdh1',
  'cdh10',
  'cdh11',
  'cdh12',
  'cdh13',
  'cdh15',
  'cdh16',
  'cdh18',
  'cdh2',
  'cdh20',
  'cdh22',
  'cdh23',
  'cdh24',
  'cdh3',
  'cdh4',
  'cdh5',
  'cdh6',
  'cdh7',
  'cdh8',
  'cdh9',
  'cdhr1',
  'cdhr2',
  'cdhr3',
  'cdhr4',
  'cdin1',
  'cdip1',
  'cdipt',
  'cdk1',
  'cdk10',
  'cdk11a',
  'cdk11b',
  'cdk12',
  'cdk13',
  'cdk14',
  'cdk15',
  'cdk16',
  'cdk17',
  'cdk18',
  'cdk19',
  'cdk2',
  'cdk20',
  'cdk2ap1',
  'cdk2ap2',
  'cdk3',
  'cdk4',
  'cdk5',
  'cdk5r1',
  'cdk5r2',
  'cdk5rap1',
  'cdk5rap3',
  'cdk6',
  'cdk7',
  'cdk8',
  'cdk9',
  'cdkal1',
  'cdkl1',
  'cdkl2',
  'cdkl4',
  'cdkl5',
  'cdkn1a',
  'cdkn1b',
  'cdkn2aip',
  'cdkn2aipnl',
  'cdkn2c',
  'cdkn2d',
  'cdkn3',
  'cdnf',
  'cdo1',
  'cdpf1',
  'cdr2',
  'cdr2l',
  'cds1',
  'cds2',
  'cdv3',
  'cdx1',
  'cdx2',
  'cdyl',
  'cdyl2',
  'ceacam16',
  'ceacam5',
  'ceacam6',
  'ceacam8',
  'cebpa',
  'cebpe',
  'cebpg',
  'cebpz',
  'cecr2',
  'cela1',
  'cela2a',
  'cela2b',
  'cela3a',
  'cela3b',
  'celf1',
  'celf2',
  'celf3',
  'celf4',
  'celf5',
  'celf6',
  'celsr1',
  'celsr2',
  'celsr3',
  'cemip',
  'cemip2',
  'cenatac',
  'cend1',
  'cenpb',
  'cenpl',
  'cenpm',
  'cenpp',
  'cenpw',
  'cep112',
  'cep120',
  'cep128',
  'cep135',
  'cep170',
  'cep170b',
  'cep19',
  'cep250',
  'cep290',
  'cep350',
  'cep41',
  'cep57',
  'cep68',
  'cep70',
  'cep76',
  'cep78',
  'cep85',
  'cep85l',
  'cep97',
  'cept1',
  'cer1',
  'cercam',
  'cerkl',
  'cers1',
  'cers2',
  'cers5',
  'cers6',
  'cert1',
  'cetn1',
  'cetn2',
  'cetn3',
  'cfap126',
  'cfap141',
  'cfap157',
  'cfap20',
  'cfap210',
  'cfap276',
  'cfap298',
  'cfap300',
  'cfap36',
  'cfap418',
  'cfap45',
  'cfap52',
  'cfap53',
  'cfap57',
  'cfap65',
  'cfap70',
  'cfap73',
  'cfap77',
  'cfap91',
  'cfap97d1',
  'cfap99',
  'cfb',
  'cfd',
  'cfdp1',
  'cfl1',
  'cfl2',
  'cfp',
  'cftr',
  'cgn',
  'cgnl1',
  'cgrrf1',
  'ch25h',
  'chac1',
  'chac2',
  'chadl',
  'champ1',
  'chat',
  'chchd1',
  'chchd10',
  'chchd2',
  'chchd3',
  'chchd4',
  'chchd5',
  'chd1',
  'chd1l',
  'chd2',
  'chd3',
  'chd4',
  'chd5',
  'chd6',
  'chd7',
  'chd8',
  'chd9',
  'chdh',
  'chek2',
  'cherp',
  'chga',
  'chi3l1',
  'chi3l2',
  'chic1',
  'chic2',
  'chid1',
  'chit1',
  'chka',
  'chkb',
  'chl1',
  'chmp1a',
  'chmp1b',
  'chmp2a',
  'chmp2b',
  'chmp3',
  'chmp4a',
  'chmp4b',
  'chmp4c',
  'chmp5',
  'chmp6',
  'chmp7',
  'chn1',
  'chn2',
  'chodl',
  'chordc1',
  'chp1',
  'chp2',
  'chpf',
  'chpf2',
  'chpt1',
  'chrac1',
  'chrd',
  'chrdl1',
  'chrdl2',
  'chrfam7a',
  'chrm1',
  'chrm2',
  'chrm3',
  'chrm4',
  'chrm5',
  'chrna1',
  'chrna10',
  'chrna2',
  'chrna3',
  'chrna4',
  'chrna5',
  'chrna7',
  'chrna9',
  'chrnb1',
  'chrnb2',
  'chrnb4',
  'chrnd',
  'chrne',
  'chrng',
  'chst1',
  'chst10',
  'chst11',
  'chst12',
  'chst14',
  'chst15',
  'chst2',
  'chst3',
  'chst4',
  'chst5',
  'chst6',
  'chst7',
  'chst8',
  'chsy1',
  'chsy3',
  'chtf8',
  'chtop',
  'chuk',
  'churc1',
  'ciao1',
  'ciao2a',
  'ciao2b',
  'ciapin1',
  'ciart',
  'cib1',
  'cib2',
  'cib3',
  'cib4',
  'cibar1',
  'cic',
  'cidea',
  'cideb',
  'cidec',
  'ciita',
  'cilk1',
  'cilp',
  'cilp2',
  'cip2a',
  'cipc',
  'cirop',
  'cisd1',
  'cisd2',
  'cish',
  'cit',
  'cited1',
  'cited2',
  'cited4',
  'ckap4',
  'ckap5',
  'ckb',
  'cklf',
  'ckm',
  'ckmt1a',
  'ckmt1b',
  'ckmt2',
  'cks1b',
  'cks2',
  'clasp1',
  'clasp2',
  'clasrp',
  'clca1',
  'clcf1',
  'clcn1',
  'clcn2',
  'clcn3',
  'clcn4',
  'clcn5',
  'clcn6',
  'clcn7',
  'clcnka',
  'clcnkb',
  'cldn1',
  'cldn10',
  'cldn11',
  'cldn12',
  'cldn14',
  'cldn15',
  'cldn16',
  'cldn17',
  'cldn18',
  'cldn19',
  'cldn2',
  'cldn20',
  'cldn22',
  'cldn23',
  'cldn24',
  'cldn3',
  'cldn4',
  'cldn5',
  'cldn6',
  'cldn7',
  'cldn8',
  'cldn9',
  'cldnd1',
  'cldnd2',
  'clec11a',
  'clec16a',
  'clec19a',
  'clec3a',
  'clec3b',
  'clic1',
  'clic3',
  'clic4',
  'clic5',
  'clint1',
  'clip1',
  'clip2',
  'clip3',
  'clip4',
  'clk1',
  'clk2',
  'clk3',
  'clk4',
  'clmp',
  'cln3',
  'cln5',
  'cln6',
  'cln8',
  'clns1a',
  'clock',
  'clp1',
  'clpb',
  'clpp',
  'clps',
  'clptm1',
  'clpx',
  'clrn1',
  'clrn2',
  'clstn1',
  'clstn2',
  'clstn3',
  'clta',
  'cltb',
  'cltc',
  'cltcl1',
  'cluh',
  'clvs1',
  'clvs2',
  'clxn',
  'clybl',
  'cmas',
  'cmbl',
  'cmc1',
  'cmc4',
  'cmip',
  'cmklr1',
  'cmklr2',
  'cmpk1',
  'cmpk2',
  'cmtm3',
  'cmtm4',
  'cmtm7',
  'cmtm8',
  'cmtr1',
  'cmtr2',
  'cnbp',
  'cndp2',
  'cnep1r1',
  'cnfn',
  'cnga2',
  'cnga3',
  'cnga4',
  'cnih1',
  'cnih2',
  'cnih3',
  'cnih4',
  'cnksr1',
  'cnksr2',
  'cnksr3',
  'cnmd',
  'cnn1',
  'cnn2',
  'cnn3',
  'cnnm1',
  'cnnm2',
  'cnnm3',
  'cnnm4',
  'cnot1',
  'cnot10',
  'cnot11',
  'cnot2',
  'cnot3',
  'cnot4',
  'cnot6',
  'cnot6l',
  'cnot7',
  'cnot8',
  'cnot9',
  'cnp',
  'cnppd1',
  'cnpy2',
  'cnpy3',
  'cnpy4',
  'cnr1',
  'cnr2',
  'cnrip1',
  'cntd1',
  'cntf',
  'cntfr',
  'cntln',
  'cntn1',
  'cntn2',
  'cntn3',
  'cntn4',
  'cntn5',
  'cntn6',
  'cntnap1',
  'cntnap2',
  'cntnap3',
  'cntnap3b',
  'cntnap4',
  'cntnap5',
  'cntrl',
  'cntrob',
  'coa3',
  'coa4',
  'coa7',
  'coasy',
  'coch',
  'cog1',
  'cog2',
  'cog3',
  'cog4',
  'cog5',
  'cog6',
  'cog7',
  'cog8',
  'col10a1',
  'col11a1',
  'col12a1',
  'col13a1',
  'col14a1',
  'col15a1',
  'col16a1',
  'col1a1',
  'col1a2',
  'col21a1',
  'col22a1',
  'col24a1',
  'col25a1',
  'col26a1',
  'col27a1',
  'col28a1',
  'col2a1',
  'col3a1',
  'col4a1',
  'col4a3',
  'col4a4',
  'col4a5',
  'col5a1',
  'col5a2',
  'col5a3',
  'col6a2',
  'col6a3',
  'col8a1',
  'col8a2',
  'col9a1',
  'col9a2',
  'colca2',
  'colec10',
  'colec11',
  'colec12',
  'colgalt1',
  'colgalt2',
  'colq',
  'commd1',
  'commd10',
  'commd2',
  'commd3',
  'commd4',
  'commd5',
  'commd6',
  'commd7',
  'commd9',
  'comp',
  'comt',
  'comtd1',
  'cop1',
  'copa',
  'copb1',
  'copb2',
  'cope',
  'copg1',
  'copg2',
  'coprs',
  'cops2',
  'cops3',
  'cops4',
  'cops5',
  'cops6',
  'cops7a',
  'cops7b',
  'cops8',
  'copz1',
  'copz2',
  'coq10a',
  'coq10b',
  'coq4',
  'coq5',
  'coq6',
  'coq7',
  'coq8a',
  'coq8b',
  'coq9',
  'coro1a',
  'coro1b',
  'coro1c',
  'coro2a',
  'coro2b',
  'coro6',
  'coro7',
  'cort',
  'cotl1',
  'cox10',
  'cox11',
  'cox14',
  'cox15',
  'cox17',
  'cox18',
  'cox4i1',
  'cox4i2',
  'cox5a',
  'cox5b',
  'cox6a1',
  'cox6a2',
  'cox6b1',
  'cox6b2',
  'cox6c',
  'cox7a2',
  'cox7a2l',
  'cox7b',
  'cox7b2',
  'cox8a',
  'cp',
  'cpa1',
  'cpa2',
  'cpa3',
  'cpa4',
  'cpa5',
  'cpa6',
  'cpb1',
  'cpd',
  'cpeb1',
  'cpeb2',
  'cpeb3',
  'cpeb4',
  'cped1',
  'cplane2',
  'cplx1',
  'cplx2',
  'cplx3',
  'cplx4',
  'cpm',
  'cpn1',
  'cpn2',
  'cpne1',
  'cpne2',
  'cpne3',
  'cpne4',
  'cpne5',
  'cpne6',
  'cpne7',
  'cpne8',
  'cpne9',
  'cpox',
  'cpped1',
  'cpq',
  'cps1',
  'cpsf2',
  'cpsf3',
  'cpsf4',
  'cpsf6',
  'cpsf7',
  'cpt1a',
  'cpt1b',
  'cpt1c',
  'cpt2',
  'cptp',
  'cpxm1',
  'cpxm2',
  'cpz',
  'cr1',
  'crabp1',
  'crabp2',
  'cracr2a',
  'cracr2b',
  'cradd',
  'cramp1',
  'crat',
  'crb2',
  'crbn',
  'crcp',
  'crct1',
  'creb1',
  'creb3l1',
  'creb3l2',
  'creb3l4',
  'creb5',
  'crebbp',
  'crebl2',
  'crebrf',
  'crebzf',
  'creg1',
  'creg2',
  'creld1',
  'crem',
  'crh',
  'crhbp',
  'crhr1',
  'crhr2',
  'crim1',
  'crip1',
  'crip2',
  'crip3',
  'cript',
  'crispld1',
  'crk',
  'crkl',
  'crlf1',
  'crlf3',
  'crls1',
  'crmp1',
  'crnkl1',
  'crocc',
  'crot',
  'crtac1',
  'crtap',
  'crtc1',
  'crtc2',
  'crtc3',
  'crx',
  'cry1',
  'cry2',
  'cryaa',
  'cryab',
  'cryba1',
  'cryba2',
  'cryba4',
  'crybb1',
  'crybb2',
  'crybb3',
  'crybg1',
  'cryga',
  'crygb',
  'crygc',
  'crygd',
  'crygs',
  'crym',
  'cryz',
  'cryzl1',
  'cs',
  'csad',
  'csdc2',
  'csde1',
  'cse1l',
  'csf1',
  'csf1r',
  'csf3',
  'csf3r',
  'csgalnact1',
  'csgalnact2',
  'csk',
  'cskmt',
  'csmd2',
  'csmd3',
  'csnk1a1',
  'csnk1a1l',
  'csnk1d',
  'csnk1e',
  'csnk1g1',
  'csnk1g2',
  'csnk1g3',
  'csnk2a1',
  'csnk2a2',
  'csnk2b',
  'cspg4',
  'cspg5',
  'cspp1',
  'csrnp1',
  'csrnp2',
  'csrnp3',
  'csrp1',
  'csrp2',
  'csrp3',
  'cstf1',
  'cstf2',
  'cstf2t',
  'cstf3',
  'cstpp1',
  'ctbp1',
  'ctbp2',
  'ctc1',
  'ctcf',
  'ctdnep1',
  'ctdsp1',
  'ctdsp2',
  'ctdspl',
  'ctdspl2',
  'ctf1',
  'cth',
  'cthrc1',
  'ctif',
  'ctla4',
  'ctnna1',
  'ctnna2',
  'ctnna3',
  'ctnnal1',
  'ctnnb1',
  'ctnnbip1',
  'ctnnbl1',
  'ctnnd1',
  'ctnnd2',
  'ctns',
  'ctps1',
  'ctps2',
  'ctr9',
  'ctrb1',
  'ctrb2',
  'ctrl',
  'ctsa',
  'ctsb',
  'ctsc',
  'ctsd',
  'ctse',
  'ctsf',
  'ctsk',
  'ctsl',
  'ctss',
  'ctsv',
  'ctsz',
  'cttn',
  'cttnbp2',
  'cttnbp2nl',
  'ctu1',
  'ctxn1',
  'ctxn2',
  'ctxn3',
  'ctxnd1',
  'ctxnd2',
  'cuedc1',
  'cuedc2',
  'cul1',
  'cul2',
  'cul3',
  'cul4a',
  'cul4b',
  'cul5',
  'cul7',
  'cul9',
  'cuta',
  'cutc',
  'cux1',
  'cux2',
  'cwc15',
  'cwc22',
  'cwc25',
  'cwc27',
  'cwf19l1',
  'cxadr',
  'cxcl12',
  'cxcl14',
  'cxcl17',
  'cxcr1',
  'cxcr3',
  'cxcr4',
  'cxcr5',
  'cxorf38',
  'cxxc1',
  'cxxc5',
  'cyb561',
  'cyb561a3',
  'cyb561d1',
  'cyb561d2',
  'cyb5a',
  'cyb5d1',
  'cyb5r1',
  'cyb5r2',
  'cyb5r3',
  'cyb5rl',
  'cyba',
  'cybb',
  'cybc1',
  'cybrd1',
  'cyc1',
  'cycs',
  'cyfip1',
  'cyfip2',
  'cygb',
  'cyld',
  'cyp11a1',
  'cyp11b1',
  'cyp11b2',
  'cyp19a1',
  'cyp1a1',
  'cyp1a2',
  'cyp1b1',
  'cyp20a1',
  'cyp24a1',
  'cyp26a1',
  'cyp26b1',
  'cyp26c1',
  'cyp27a1',
  'cyp27b1',
  'cyp2a13',
  'cyp2a6',
  'cyp2a7',
  'cyp2b6',
  'cyp2c18',
  'cyp2c19',
  'cyp2c8',
  'cyp2c9',
  'cyp2d6',
  'cyp2e1',
  'cyp2j2',
  'cyp2r1',
  'cyp2s1',
  'cyp2u1',
  'cyp2w1',
  'cyp3a4',
  'cyp3a43',
  'cyp3a5',
  'cyp3a7',
  'cyp46a1',
  'cyp4f11',
  'cyp4f12',
  'cyp4f2',
  'cyp4f22',
  'cyp4f3',
  'cyp4f8',
  'cyp4v2',
  'cyp51a1',
  'cyria',
  'cyrib',
  'cysltr1',
  'cysrt1',
  'cystm1',
  'cyth1',
  'cyth2',
  'cyth3',
  'cyth4',
  'cytip',
  'cyyr1',
  'czib',
  'd2hgdh',
  'daam1',
  'daam2',
  'dab1',
  'dab2',
  'dab2ip',
  'dach1',
  'dact1',
  'dad1',
  'dag1',
  'dagla',
  'dalrd3',
  'dao',
  'dap',
  'dap3',
  'dapk1',
  'dapk2',
  'dapk3',
  'dapl1',
  'dapp1',
  'dars1',
  'dars2',
  'daw1',
  'daxx',
  'dazap1',
  'dazap2',
  'dazl',
  'dbh',
  'dbi',
  'dbn1',
  'dbndd1',
  'dbndd2',
  'dbnl',
  'dbp',
  'dbr1',
  'dbt',
  'dbx1',
  'dbx2',
  'dcaf1',
  'dcaf10',
  'dcaf11',
  'dcaf12',
  'dcaf12l1',
  'dcaf13',
  'dcaf15',
  'dcaf17',
  'dcaf4',
  'dcaf5',
  'dcaf6',
  'dcaf7',
  'dcaf8',
  'dcaf8l1',
  'dcakd',
  'dcbld2',
  'dcc',
  'dcdc2b',
  'dchs1',
  'dck',
  'dclk1',
  'dclk2',
  'dclre1b',
  'dcn',
  'dcp1a',
  'dcp2',
  'dcps',
  'dcst1',
  'dcst2',
  'dcstamp',
  'dct',
  'dctn1',
  'dctn2',
  'dctn3',
  'dctn4',
  'dctn5',
  'dctpp1',
  'dcun1d1',
  'dcun1d3',
  'dcun1d4',
  'dcun1d5',
  'dcx',
  'dcxr',
  'dda1',
  'ddah1',
  'ddah2',
  'ddb1',
  'ddb2',
  'ddc',
  'ddhd1',
  'ddhd2',
  'ddi2',
  'ddit3',
  'ddit4',
  'ddit4l',
  'ddn',
  'ddo',
  'ddost',
  'ddr1',
  'ddr2',
  'ddrgk1',
  'ddt',
  'ddx1',
  'ddx10',
  'ddx11',
  'ddx17',
  'ddx18',
  'ddx19a',
  'ddx19b',
  'ddx20',
  'ddx21',
  'ddx23',
  'ddx24',
  'ddx25',
  'ddx27',
  'ddx39a',
  'ddx39b',
  'ddx3x',
  'ddx4',
  'ddx41',
  'ddx42',
  'ddx46',
  'ddx47',
  'ddx49',
  'ddx5',
  'ddx50',
  'ddx52',
  'ddx54',
  'ddx55',
  'ddx56',
  'ddx59',
  'ddx6',
  'deaf1',
  'decr2',
  'dedd',
  'dedd2',
  'def6',
  'def8',
  'defb124',
  'degs1',
  'degs2',
  'dek',
  'dele1',
  'dennd10',
  'dennd11',
  'dennd1a',
  'dennd2a',
  'dennd2b',
  'dennd2c',
  'dennd2d',
  'dennd4a',
  'dennd4b',
  'dennd4c',
  'dennd5a',
  'dennd5b',
  'dennd6a',
  'dennd6b',
  'denr',
  'depdc1',
  'depdc1b',
  'depdc5',
  'depp1',
  'deptor',
  'dera',
  'derl1',
  'derl2',
  'derl3',
  'derpc',
  'des',
  'desi1',
  'desi2',
  'det1',
  'dexi',
  'dffa',
  'dgat1',
  'dgat2',
  'dgcr2',
  'dgcr6',
  'dgcr6l',
  'dgcr8',
  'dgka',
  'dgkb',
  'dgkd',
  'dgke',
  'dgkg',
  'dgkh',
  'dgki',
  'dgkz',
  'dguok',
  'dhcr24',
  'dhcr7',
  'dhdds',
  'dhdh',
  'dhfr',
  'dhh',
  'dhodh',
  'dhps',
  'dhrs1',
  'dhrs11',
  'dhrs13',
  'dhrs3',
  'dhrs4',
  'dhrs4l2',
  'dhrs7b',
  'dhrs7c',
  'dhrs9',
  'dhtkd1',
  'dhx15',
  'dhx16',
  'dhx29',
  'dhx30',
  'dhx32',
  'dhx33',
  'dhx34',
  'dhx35',
  'dhx36',
  'dhx37',
  'dhx38',
  'dhx40',
  'dhx57',
  'dhx58',
  'dhx8',
  'dhx9',
  'diablo',
  'diaph2',
  'diaph3',
  'dicer1',
  'dimt1',
  'dio1',
  'dio2',
  'dio3',
  'dip2a',
  'dip2b',
  'dip2c',
  'dipk1a',
  'dipk1b',
  'dipk1c',
  'dipk2a',
  'dipk2b',
  'diras1',
  'diras2',
  'dis3',
  'dis3l',
  'dis3l2',
  'disp2',
  'disp3',
  'dixdc1',
  'dkc1',
  'dkk1',
  'dkk2',
  'dkk3',
  'dkkl1',
  'dlat',
  'dlc1',
  'dld',
  'dlec1',
  'dleu7',
  'dlg1',
  'dlg2',
  'dlg3',
  'dlg4',
  'dlg5',
  'dlgap1',
  'dlgap2',
  'dlgap3',
  'dlgap4',
  'dlk1',
  'dlk2',
  'dll1',
  'dll3',
  'dll4',
  'dlst',
  'dlx1',
  'dlx2',
  'dlx3',
  'dlx4',
  'dlx5',
  'dlx6',
  'dmac2l',
  'dmap1',
  'dmbx1',
  'dmc1',
  'dmd',
  'dmgdh',
  'dmpk',
  'dmrt1',
  'dmrt2',
  'dmrt3',
  'dmrta1',
  'dmrta2',
  'dmrtc1',
  'dmrtc1b',
  'dmrtc2',
  'dmtf1',
  'dmtn',
  'dmwd',
  'dmxl1',
  'dmxl2',
  'dna2',
  'dnaaf10',
  'dnaaf4',
  'dnaaf9',
  'dnah1',
  'dnah11',
  'dnah12',
  'dnah17',
  'dnah2',
  'dnah3',
  'dnah5',
  'dnah6',
  'dnah7',
  'dnah8',
  'dnah9',
  'dnai1',
  'dnai2',
  'dnaja1',
  'dnaja2',
  'dnaja3',
  'dnaja4',
  'dnajb1',
  'dnajb11',
  'dnajb12',
  'dnajb13',
  'dnajb14',
  'dnajb2',
  'dnajb4',
  'dnajb5',
  'dnajb8',
  'dnajb9',
  'dnajc10',
  'dnajc11',
  'dnajc13',
  'dnajc14',
  'dnajc16',
  'dnajc17',
  'dnajc18',
  'dnajc19',
  'dnajc2',
  'dnajc21',
  'dnajc22',
  'dnajc25',
  'dnajc27',
  'dnajc28',
  'dnajc3',
  'dnajc5',
  'dnajc5b',
  'dnajc6',
  'dnajc7',
  'dnajc8',
  'dnajc9',
  'dnal1',
  'dnal4',
  'dnali1',
  'dnase1l2',
  'dnase1l3',
  'dnase2b',
  'dnd1',
  'dner',
  'dnhd1',
  'dnm1',
  'dnm1l',
  'dnm2',
  'dnm3',
  'dnmt1',
  'dnmt3a',
  'dnmt3b',
  'dnpep',
  'dnph1',
  'dntt',
  'dnttip1',
  'doc2a',
  'doc2b',
  'dock1',
  'dock10',
  'dock11',
  'dock2',
  'dock3',
  'dock4',
  'dock5',
  'dock6',
  'dock7',
  'dock8',
  'dock9',
  'dohh',
  'dok1',
  'dok2',
  'dok3',
  'dok4',
  'dok5',
  'dok6',
  'dok7',
  'dolk',
  'dolpp1',
  'donson',
  'dop1a',
  'dop1b',
  'dot1l',
  'dpagt1',
  'dpcd',
  'dpep1',
  'dpf1',
  'dpf2',
  'dpf3',
  'dph1',
  'dph2',
  'dph3',
  'dph5',
  'dph6',
  'dpm1',
  'dpm2',
  'dpm3',
  'dpp10',
  'dpp3',
  'dpp4',
  'dpp7',
  'dpp8',
  'dpp9',
  'dpt',
  'dpy19l3',
  'dpy19l4',
  'dpy30',
  'dpyd',
  'dpys',
  'dpysl2',
  'dpysl3',
  'dpysl4',
  'dpysl5',
  'dqx1',
  'dr1',
  'dram1',
  'dram2',
  'drap1',
  'draxin',
  'drc3',
  'drc7',
  'drd1',
  'drd2',
  'drd3',
  'drg1',
  'drg2',
  'drgx',
  'drosha',
  'drp2',
  'dscam',
  'dscaml1',
  'dscc1',
  'dse',
  'dsel',
  'dsp',
  'dstn',
  'dstyk',
  'dtd1',
  'dtd2',
  'dtl',
  'dtna',
  'dtnb',
  'dtnbp1',
  'dtwd1',
  'dtwd2',
  'dtx1',
  'dtx2',
  'dtx3',
  'dtx4',
  'duox1',
  'duox2',
  'duoxa1',
  'duoxa2',
  'dus1l',
  'dus2',
  'dus3l',
  'dus4l',
  'dusp1',
  'dusp10',
  'dusp14',
  'dusp15',
  'dusp16',
  'dusp18',
  'dusp19',
  'dusp2',
  'dusp21',
  'dusp22',
  'dusp23',
  'dusp26',
  'dusp28',
  'dusp29',
  'dusp3',
  'dusp4',
  'dusp5',
  'dusp6',
  'dusp7',
  'dusp8',
  'dusp9',
  'dvl1',
  'dvl2',
  'dvl3',
  'dxo',
  'dym',
  'dync1h1',
  'dync1i1',
  'dync1i2',
  'dync1li1',
  'dync1li2',
  'dync2h1',
  'dync2i2',
  'dync2li1',
  'dynll1',
  'dynll2',
  'dynlrb1',
  'dynlt1',
  'dynlt2',
  'dynlt3',
  'dynlt4',
  'dyrk1a',
  'dyrk1b',
  'dyrk2',
  'dyrk3',
  'dysf',
  'dzip3',
  'e2f1',
  'e2f2',
  'e2f3',
  'e2f4',
  'e2f5',
  'e2f6',
  'e2f7',
  'e2f8',
  'e4f1',
  'eaf2',
  'eapp',
  'ears2',
  'ebag9',
  'ebf1',
  'ebf2',
  'ebf3',
  'ebf4',
  'ebi3',
  'ebna1bp2',
  'ebp',
  'ebpl',
  'ecd',
  'ece1',
  'ece2',
  'ecel1',
  'ech1',
  'echdc1',
  'echdc2',
  'echdc3',
  'eci1',
  'ecm1',
  'ecpas',
  'ecrg4',
  'ecsit',
  'ect2',
  'eda',
  'edar',
  'edc3',
  'edem1',
  'edem2',
  'edem3',
  'edf1',
  'edil3',
  'edn2',
  'ednra',
  'ednrb',
  'edrf1',
  'eea1',
  'eed',
  'eef1a1',
  'eef1a2',
  'eef1akmt2',
  'eef1akmt3',
  'eef1akmt4',
  'eef1b2',
  'eef1d',
  'eef1e1',
  'eef1g',
  'eef2',
  'eef2k',
  'eepd1',
  'efcab14',
  'efcab7',
  'efemp1',
  'efemp2',
  'efhc1',
  'efhd1',
  'efhd2',
  'efl1',
  'efna1',
  'efna2',
  'efna3',
  'efna4',
  'efna5',
  'efnb1',
  'efnb2',
  'efnb3',
  'efr3a',
  'efr3b',
  'efs',
  'eftud2',
  'egfl7',
  'egfl8',
  'egflam',
  'egfr',
  'egln1',
  'egln2',
  'egln3',
  'egr1',
  'egr2',
  'egr3',
  'egr4',
  'ehbp1',
  'ehd1',
  'ehd2',
  'ehd3',
  'ehd4',
  'ehf',
  'ehhadh',
  'ehmt1',
  'ehmt2',
  'ei24',
  'eid2',
  'eif1',
  'eif1ad',
  'eif1ax',
  'eif1b',
  'eif2a',
  'eif2ak3',
  'eif2ak4',
  'eif2b1',
  'eif2b2',
  'eif2b3',
  'eif2b4',
  'eif2b5',
  'eif2d',
  'eif2s1',
  'eif2s2',
  'eif2s3',
  'eif3a',
  'eif3b',
  'eif3c',
  'eif3cl',
  'eif3d',
  'eif3e',
  'eif3f',
  'eif3g',
  'eif3h',
  'eif3i',
  'eif3j',
  'eif3k',
  'eif3l',
  'eif3m',
  'eif4a1',
  'eif4a2',
  'eif4a3',
  'eif4b',
  'eif4e',
  'eif4e2',
  'eif4ebp1',
  'eif4ebp2',
  'eif4ebp3',
  'eif4enif1',
  'eif4g1',
  'eif4g2',
  'eif4g3',
  'eif4h',
  'eif5',
  'eif5a',
  'eif5a2',
  'eif5al1',
  'eif5b',
  'eif6',
  'eipr1',
  'elac1',
  'elac2',
  'elapor1',
  'elapor2',
  'elavl1',
  'elavl2',
  'elavl3',
  'elavl4',
  'elf1',
  'elf2',
  'elf3',
  'elf4',
  'elfn2',
  'elk1',
  'elk4',
  'ell',
  'ell2',
  'ell3',
  'elmo1',
  'elmo2',
  'elmo3',
  'elmod1',
  'elmod2',
  'elmod3',
  'eloa',
  'elob',
  'eloc',
  'elof1',
  'elovl1',
  'elovl2',
  'elovl4',
  'elovl5',
  'elovl6',
  'elovl7',
  'elp1',
  'elp2',
  'elp3',
  'elp4',
  'elp5',
  'elp6',
  'emc1',
  'emc10',
  'emc2',
  'emc3',
  'emc4',
  'emc6',
  'emc7',
  'emc8',
  'emc9',
  'eme1',
  'emg1',
  'emid1',
  'emilin1',
  'emilin3',
  'eml1',
  'eml2',
  'eml3',
  'eml4',
  'eml5',
  'eml6',
  'emp2',
  'emp3',
  'emsy',
  'emx1',
  'emx2',
  'en1',
  'en2',
  'enah',
  'enc1',
  'endog',
  'endou',
  'eng',
  'enho',
  'enkd1',
  'enkur',
  'eno1',
  'eno2',
  'eno3',
  'eno4',
  'enoph1',
  'enox1',
  'enox2',
  'enpp2',
  'enpp4',
  'enpp6',
  'enpp7',
  'ensa',
  'entpd1',
  'entpd2',
  'entpd3',
  'entpd4',
  'entpd5',
  'entpd6',
  'entpd7',
  'entpd8',
  'eny2',
  'eola1',
  'eola2',
  'eomes',
  'ep300',
  'epas1',
  'epb41',
  'epb41l1',
  'epb41l2',
  'epb41l3',
  'epb41l4a',
  'epb41l4b',
  'epb42',
  'epc1',
  'epc2',
  'epdr1',
  'epg5',
  'epha1',
  'epha10',
  'epha2',
  'epha3',
  'epha4',
  'epha5',
  'epha6',
  'epha7',
  'epha8',
  'ephb1',
  'ephb2',
  'ephb3',
  'ephb4',
  'ephb6',
  'ephx1',
  'ephx3',
  'ephx4',
  'epm2a',
  'epm2aip1',
  'epn1',
  'epn2',
  'epn3',
  'epo',
  'epor',
  'eprs1',
  'eps15',
  'eps15l1',
  'eps8',
  'eps8l1',
  'eps8l2',
  'eps8l3',
  'epx',
  'epyc',
  'eral1',
  'eras',
  'erbb2',
  'erbb3',
  'erbb4',
  'erbin',
  'erc1',
  'erc2',
  'ercc1',
  'ercc2',
  'ercc3',
  'ercc4',
  'ercc8',
  'erf',
  'erfl',
  'erg',
  'erg28',
  'ergic1',
  'ergic2',
  'ergic3',
  'erh',
  'eri3',
  'erich4',
  'erlec1',
  'erlin1',
  'erlin2',
  'ermp1',
  'ern1',
  'ern2',
  'ero1a',
  'ero1b',
  'erp29',
  'erp44',
  'esco1',
  'esd',
  'esm1',
  'espl1',
  'espn',
  'espnl',
  'esr1',
  'esr2',
  'esrp1',
  'esrp2',
  'esrra',
  'esrrb',
  'esrrg',
  'ess2',
  'esyt1',
  'esyt2',
  'esyt3',
  'etf1',
  'etfa',
  'etfb',
  'etfbkmt',
  'etfrf1',
  'ethe1',
  'etnk1',
  'etnk2',
  'ets1',
  'ets2',
  'etv1',
  'etv2',
  'etv3',
  'etv3l',
  'etv4',
  'etv5',
  'etv6',
  'eva1a',
  'eva1b',
  'eva1c',
  'evi2a',
  'evi5',
  'evi5l',
  'evl',
  'evpl',
  'evpll',
  'evx1',
  'evx2',
  'ewsr1',
  'exd2',
  'exoc1',
  'exoc1l',
  'exoc2',
  'exoc3',
  'exoc3l1',
  'exoc3l2',
  'exoc3l4',
  'exoc4',
  'exoc5',
  'exoc6',
  'exoc6b',
  'exoc7',
  'exoc8',
  'exog',
  'exosc1',
  'exosc10',
  'exosc2',
  'exosc3',
  'exosc4',
  'exosc5',
  'exosc6',
  'exosc7',
  'exosc8',
  'exosc9',
  'ext1',
  'ext2',
  'extl1',
  'extl3',
  'eya1',
  'eya2',
  'eya3',
  'eya4',
  'ezh1',
  'ezh2',
  'ezr',
  'f12',
  'f13a1',
  'f2',
  'f2rl1',
  'f8a1',
  'f8a2',
  'f8a3',
  'f9',
  'fa2h',
  'faah',
  'faap100',
  'faap24',
  'fabp1',
  'fabp3',
  'fabp4',
  'fabp5',
  'fabp7',
  'fads1',
  'fads2',
  'fads3',
  'faf1',
  'faf2',
  'fahd1',
  'fahd2a',
  'fahd2b',
  'faim',
  'faim2',
  'fam102a',
  'fam102b',
  'fam104a',
  'fam107a',
  'fam110a',
  'fam110d',
  'fam114a1',
  'fam114a2',
  'fam117a',
  'fam117b',
  'fam118b',
  'fam120a',
  'fam120c',
  'fam124a',
  'fam126a',
  'fam126b',
  'fam131a',
  'fam131b',
  'fam131c',
  'fam133a',
  'fam133b',
  'fam135a',
  'fam13b',
  'fam13c',
  'fam149b1',
  'fam151a',
  'fam151b',
  'fam161b',
  'fam163a',
  'fam163b',
  'fam166b',
  'fam167a',
  'fam167b',
  'fam168a',
  'fam168b',
  'fam169a',
  'fam171a1',
  'fam171a2',
  'fam171b',
  'fam172a',
  'fam177a1',
  'fam180a',
  'fam180b',
  'fam183a',
  'fam184a',
  'fam184b',
  'fam185a',
  'fam187a',
  'fam189b',
  'fam193b',
  'fam199x',
  'fam200a',
  'fam200b',
  'fam20a',
  'fam20b',
  'fam20c',
  'fam210a',
  'fam210b',
  'fam214b',
  'fam219a',
  'fam219b',
  'fam221a',
  'fam222a',
  'fam222b',
  'fam229a',
  'fam229b',
  'fam234b',
  'fam237b',
  'fam240a',
  'fam240b',
  'fam241b',
  'fam32a',
  'fam3a',
  'fam3c',
  'fam3d',
  'fam43a',
  'fam43b',
  'fam50a',
  'fam50b',
  'fam53b',
  'fam53c',
  'fam72a',
  'fam72b',
  'fam72c',
  'fam72d',
  'fam76a',
  'fam76b',
  'fam78a',
  'fam78b',
  'fam81a',
  'fam83a',
  'fam83c',
  'fam83d',
  'fam83e',
  'fam83f',
  'fam83g',
  'fam83h',
  'fam89a',
  'fam89b',
  'fam8a1',
  'fam91a1',
  'fam98a',
  'fam98b',
  'fam98c',
  'fancd2os',
  'fancf',
  'fancg',
  'fanci',
  'fancl',
  'fank1',
  'fap',
  'far1',
  'far2',
  'farp1',
  'fars2',
  'farsa',
  'farsb',
  'faslg',
  'fasn',
  'fastk',
  'fastkd5',
  'fat1',
  'fat2',
  'fat3',
  'fat4',
  'fau',
  'faxc',
  'faxdc2',
  'fbh1',
  'fbl',
  'fblim1',
  'fbln1',
  'fbln2',
  'fbln5',
  'fbln7',
  'fbn1',
  'fbn2',
  'fbn3',
  'fbp1',
  'fbp2',
  'fbrs',
  'fbxl12',
  'fbxl15',
  'fbxl16',
  'fbxl17',
  'fbxl18',
  'fbxl19',
  'fbxl2',
  'fbxl20',
  'fbxl22',
  'fbxl3',
  'fbxl4',
  'fbxl5',
  'fbxl6',
  'fbxl7',
  'fbxl8',
  'fbxo10',
  'fbxo11',
  'fbxo17',
  'fbxo2',
  'fbxo21',
  'fbxo22',
  'fbxo24',
  'fbxo25',
  'fbxo28',
  'fbxo30',
  'fbxo31',
  'fbxo32',
  'fbxo33',
  'fbxo36',
  'fbxo38',
  'fbxo39',
  'fbxo4',
  'fbxo40',
  'fbxo41',
  'fbxo42',
  'fbxo44',
  'fbxo45',
  'fbxo46',
  'fbxo47',
  'fbxo6',
  'fbxo7',
  'fbxo8',
  'fbxo9',
  'fbxw11',
  'fbxw2',
  'fbxw5',
  'fbxw7',
  'fbxw8',
  'fbxw9',
  'fcer1g',
  'fcf1',
  'fcgbp',
  'fcgr2b',
  'fcho1',
  'fcho2',
  'fchsd1',
  'fchsd2',
  'fcrla',
  'fcrlb',
  'fcsk',
  'fdft1',
  'fdps',
  'fdx2',
  'fdxacb1',
  'fdxr',
  'fech',
  'fem1c',
  'fen1',
  'fer',
  'fer1l5',
  'fer1l6',
  'ferd3l',
  'fermt2',
  'fermt3',
  'fes',
  'fev',
  'fez1',
  'fez2',
  'fezf1',
  'fezf2',
  'ffar1',
  'ffar2',
  'ffar3',
  'ffar4',
  'fgd1',
  'fgd2',
  'fgd3',
  'fgd4',
  'fgf1',
  'fgf10',
  'fgf11',
  'fgf12',
  'fgf13',
  'fgf14',
  'fgf16',
  'fgf17',
  'fgf18',
  'fgf19',
  'fgf2',
  'fgf20',
  'fgf21',
  'fgf22',
  'fgf23',
  'fgf4',
  'fgf5',
  'fgf6',
  'fgf7',
  'fgf8',
  'fgf9',
  'fgfbp3',
  'fgfr1',
  'fgfr1op2',
  'fgfr2',
  'fgfr3',
  'fgfr4',
  'fgfrl1',
  'fggy',
  'fgl1',
  'fgl2',
  'fgr',
  'fh',
  'fhip1a',
  'fhip1b',
  'fhip2a',
  'fhip2b',
  'fhit',
  'fhl1',
  'fhl2',
  'fhl3',
  'fhod1',
  'fhod3',
  'fibcd1',
  'fibin',
  'fibp',
  'ficd',
  'fig4',
  'figla',
  'fign',
  'fignl2',
  'filip1',
  'fip1l1',
  'fis1',
  'fitm1',
  'fitm2',
  'fiz1',
  'fkbp10',
  'fkbp11',
  'fkbp14',
  'fkbp15',
  'fkbp1a',
  'fkbp1b',
  'fkbp2',
  'fkbp3',
  'fkbp4',
  'fkbp5',
  'fkbp7',
  'fkbp8',
  'fkbp9',
  'fkbpl',
  'fkrp',
  'fktn',
  'flad1',
  'flcn',
  'fli1',
  'flii',
  'flna',
  'flnb',
  'flnc',
  'flot1',
  'flot2',
  'flrt1',
  'flrt2',
  'flrt3',
  'flt1',
  'flt3',
  'flt3lg',
  'flt4',
  'flvcr1',
  'flvcr2',
  'fmc1',
  'fmn1',
  'fmnl1',
  'fmnl2',
  'fmnl3',
  'fmo1',
  'fmo2',
  'fmo3',
  'fmo5',
  'fmod',
  'fmr1',
  'fn1',
  'fn3k',
  'fn3krp',
  'fnbp1',
  'fnbp1l',
  'fnbp4',
  'fndc11',
  'fndc3a',
  'fndc3b',
  'fndc4',
  'fndc5',
  'fndc7',
  'fndc8',
  'fndc9',
  'fnip1',
  'fnta',
  'fntb',
  'focad',
  'folh1',
  'folh1b',
  'folr1',
  'folr2',
  'folr3',
  'fos',
  'fosb',
  'fosl1',
  'fosl2',
  'foxa1',
  'foxa2',
  'foxa3',
  'foxb1',
  'foxb2',
  'foxc2',
  'foxe1',
  'foxe3',
  'foxf1',
  'foxf2',
  'foxg1',
  'foxi1',
  'foxi2',
  'foxj1',
  'foxj2',
  'foxj3',
  'foxk2',
  'foxl1',
  'foxm1',
  'foxn1',
  'foxn2',
  'foxn3',
  'foxn4',
  'foxo1',
  'foxo3',
  'foxo3b',
  'foxo4',
  'foxo6',
  'foxp1',
  'foxp2',
  'foxp3',
  'foxp4',
  'foxq1',
  'foxr1',
  'foxred1',
  'foxred2',
  'foxs1',
  'fpgs',
  'fpgt',
  'fpr1',
  'fpr2',
  'fpr3',
  'frem1',
  'frem2',
  'frg1',
  'frmd3',
  'frmd4a',
  'frmd5',
  'frmd6',
  'frmd7',
  'frmd8',
  'frmpd1',
  'frmpd2',
  'frmpd3',
  'frmpd4',
  'frrs1',
  'frrs1l',
  'frs2',
  'frs3',
  'fry',
  'fryl',
  'frzb',
  'fsbp',
  'fscn1',
  'fscn2',
  'fscn3',
  'fsd1',
  'fsd1l',
  'fsd2',
  'fshb',
  'fshr',
  'fst',
  'fstl1',
  'fstl3',
  'fstl5',
  'ftcd',
  'fth1',
  'fthl17',
  'ftl',
  'ftmt',
  'fto',
  'ftsj1',
  'ftsj3',
  'fubp1',
  'fubp3',
  'fuca1',
  'fuca2',
  'fundc1',
  'fundc2',
  'furin',
  'fus',
  'fut1',
  'fut11',
  'fut2',
  'fut7',
  'fut8',
  'fut9',
  'fuz',
  'fxr1',
  'fxr2',
  'fxyd6',
  'fxyd7',
  'fyb1',
  'fyco1',
  'fyn',
  'fyttd1',
  'fzd1',
  'fzd10',
  'fzd2',
  'fzd3',
  'fzd4',
  'fzd5',
  'fzd6',
  'fzd7',
  'fzd9',
  'fzr1',
  'g0s2',
  'g2e3',
  'g3bp1',
  'g3bp2',
  'g6pc1',
  'g6pc2',
  'g6pc3',
  'g6pd',
  'gaa',
  'gab1',
  'gab2',
  'gab3',
  'gabarap',
  'gabarapl1',
  'gabarapl2',
  'gabbr1',
  'gabbr2',
  'gabpa',
  'gabpb1',
  'gabpb2',
  'gabra1',
  'gabra2',
  'gabra3',
  'gabra4',
  'gabra5',
  'gabra6',
  'gabrb1',
  'gabrb2',
  'gabrb3',
  'gabrd',
  'gabre',
  'gabrg1',
  'gabrg2',
  'gabrg3',
  'gabrp',
  'gabrq',
  'gabrr1',
  'gabrr2',
  'gad1',
  'gad2',
  'gadd45a',
  'gadd45b',
  'gadd45g',
  'gadd45gip1',
  'gadl1',
  'gal3st1',
  'gal3st3',
  'gal3st4',
  'galc',
  'gale',
  'galk1',
  'galk2',
  'galm',
  'galnt1',
  'galnt10',
  'galnt11',
  'galnt12',
  'galnt13',
  'galnt14',
  'galnt16',
  'galnt17',
  'galnt18',
  'galnt2',
  'galnt3',
  'galnt4',
  'galnt5',
  'galnt6',
  'galnt7',
  'galnt9',
  'galntl6',
  'galr2',
  'galr3',
  'galt',
  'gamt',
  'gan',
  'ganab',
  'ganc',
  'gap43',
  'gapdh',
  'gapvd1',
  'garem1',
  'garem2',
  'garin1a',
  'garin1b',
  'garnl3',
  'garre1',
  'gars1',
  'gart',
  'gas1',
  'gas2',
  'gas2l1',
  'gas2l2',
  'gas7',
  'gas8',
  'gast',
  'gata1',
  'gata2',
  'gata3',
  'gata4',
  'gata5',
  'gata6',
  'gatad1',
  'gatad2a',
  'gatad2b',
  'gatb',
  'gatc',
  'gatd1',
  'gatm',
  'gba',
  'gba2',
  'gbe1',
  'gbf1',
  'gbgt1',
  'gbp3',
  'gbx1',
  'gbx2',
  'gcat',
  'gcc1',
  'gcdh',
  'gcg',
  'gcgr',
  'gch1',
  'gchfr',
  'gck',
  'gclc',
  'gclm',
  'gcn1',
  'gcnt1',
  'gcnt2',
  'gcnt3',
  'gcnt4',
  'gcsh',
  'gda',
  'gdap1',
  'gdap1l1',
  'gdap2',
  'gde1',
  'gdf1',
  'gdf10',
  'gdf11',
  'gdf2',
  'gdf3',
  'gdf5',
  'gdf6',
  'gdf7',
  'gdi1',
  'gdi2',
  'gdnf',
  'gdpd1',
  'gdpd2',
  'gdpd3',
  'gdpd5',
  'gdpgp1',
  'gem',
  'gemin2',
  'gemin4',
  'gemin5',
  'gemin6',
  'gemin7',
  'gemin8',
  'get1',
  'get3',
  'get4',
  'gfap',
  'gfer',
  'gfi1',
  'gfi1b',
  'gfm1',
  'gfm2',
  'gfod1',
  'gfod2',
  'gfpt1',
  'gfpt2',
  'gfra1',
  'gfra2',
  'gfra3',
  'gfra4',
  'gfus',
  'gga1',
  'gga2',
  'gga3',
  'ggact',
  'ggct',
  'ggcx',
  'ggn',
  'ggnbp2',
  'ggps1',
  'ggt1',
  'ggt5',
  'ggt6',
  'ggt7',
  'ggtlc1',
  'ggtlc2',
  'ggtlc3',
  'ghdc',
  'ghitm',
  'ghr',
  'ghrh',
  'ghrhr',
  'ghrl',
  'ghsr',
  'gid4',
  'gid8',
  'gigyf1',
  'gigyf2',
  'gin1',
  'gins1',
  'gins2',
  'gins3',
  'gip',
  'gipc1',
  'gipc3',
  'gipr',
  'git1',
  'git2',
  'gja4',
  'gja5',
  'gja8',
  'gjb1',
  'gjb2',
  'gjb3',
  'gjb4',
  'gjb5',
  'gjb6',
  'gjc1',
  'gjc2',
  'gjc3',
  'gjd2',
  'gjd3',
  'gk',
  'gk5',
  'gkap1',
  'gkn2',
  'gla',
  'glb1l',
  'glb1l2',
  'glcci1',
  'glce',
  'gldc',
  'gldn',
  'gle1',
  'glg1',
  'gli1',
  'gli2',
  'gli3',
  'glipr2',
  'glis1',
  'glis2',
  'glis3',
  'glmn',
  'glmp',
  'glo1',
  'glod4',
  'glod5',
  'glp1r',
  'glp2r',
  'glra1',
  'glra2',
  'glra3',
  'glrb',
  'glrx3',
  'glrx5',
  'gls',
  'gls2',
  'glt8d1',
  'glt8d2',
  'gltp',
  'glud1',
  'glul',
  'glyatl3',
  'glyctk',
  'glyr1',
  'gmcl1',
  'gmds',
  'gmeb1',
  'gmeb2',
  'gmfb',
  'gmfg',
  'gmip',
  'gmnc',
  'gmppa',
  'gmppb',
  'gmpr',
  'gmpr2',
  'gmps',
  'gna11',
  'gna12',
  'gna13',
  'gna14',
  'gna15',
  'gnai1',
  'gnai2',
  'gnai3',
  'gnal',
  'gnao1',
  'gnaq',
  'gnas',
  'gnat1',
  'gnat2',
  'gnat3',
  'gnaz',
  'gnb1',
  'gnb1l',
  'gnb2',
  'gnb3',
  'gnb4',
  'gnb5',
  'gne',
  'gng10',
  'gng12',
  'gng13',
  'gng2',
  'gng3',
  'gng4',
  'gng5',
  'gng5p2',
  'gng7',
  'gng8',
  'gngt1',
  'gngt2',
  'gnl1',
  'gnl2',
  'gnl3l',
  'gnmt',
  'gnpat',
  'gnpda1',
  'gnpda2',
  'gnpnat1',
  'gnptab',
  'gnrhr',
  'gns',
  'golga1',
  'golga3',
  'golga4',
  'golga5',
  'golga7',
  'golga7b',
  'golgb1',
  'golm1',
  'golm2',
  'golph3',
  'golph3l',
  'golt1a',
  'golt1b',
  'gon4l',
  'gon7',
  'gopc',
  'gorasp1',
  'gorasp2',
  'gosr1',
  'gosr2',
  'got1',
  'got2',
  'gp1bb',
  'gp2',
  'gp5',
  'gpaa1',
  'gpalpp1',
  'gpam',
  'gpank1',
  'gpat2',
  'gpat3',
  'gpat4',
  'gpatch11',
  'gpatch2',
  'gpatch2l',
  'gpatch3',
  'gpatch8',
  'gpbar1',
  'gpbp1',
  'gpbp1l1',
  'gpc1',
  'gpc2',
  'gpc3',
  'gpc4',
  'gpc5',
  'gpc6',
  'gpcpd1',
  'gpd1',
  'gpd1l',
  'gpd2',
  'gper1',
  'gpha2',
  'gphb5',
  'gphn',
  'gpi',
  'gpkow',
  'gpm6a',
  'gpm6b',
  'gpn1',
  'gpn2',
  'gpn3',
  'gpr101',
  'gpr107',
  'gpr108',
  'gpr119',
  'gpr12',
  'gpr137',
  'gpr137b',
  'gpr137c',
  'gpr139',
  'gpr141',
  'gpr143',
  'gpr146',
  'gpr149',
  'gpr150',
  'gpr151',
  'gpr153',
  'gpr155',
  'gpr156',
  'gpr157',
  'gpr158',
  'gpr161',
  'gpr162',
  'gpr17',
  'gpr173',
  'gpr174',
  'gpr176',
  'gpr18',
  'gpr180',
  'gpr182',
  'gpr183',
  'gpr19',
  'gpr20',
  'gpr21',
  'gpr22',
  'gpr26',
  'gpr27',
  'gpr3',
  'gpr33',
  'gpr34',
  'gpr35',
  'gpr37',
  'gpr37l1',
  'gpr39',
  'gpr4',
  'gpr42',
  'gpr52',
  'gpr55',
  'gpr6',
  'gpr61',
  'gpr62',
  'gpr63',
  'gpr68',
  'gpr75',
  'gpr82',
  'gpr83',
  'gpr84',
  'gpr85',
  'gpr87',
  'gpr88',
  'gpr89a',
  'gpr89b',
  'gprasp2',
  'gprc5a',
  'gprc5b',
  'gprc5c',
  'gprc5d',
  'gprc6a',
  'gps1',
  'gps2',
  'gpsm1',
  'gpsm2',
  'gpsm3',
  'gpt',
  'gpt2',
  'gpx1',
  'gpx2',
  'gpx3',
  'gpx4',
  'gpx5',
  'gpx6',
  'gpx7',
  'gpx8',
  'gramd1a',
  'gramd1b',
  'gramd1c',
  'gramd2b',
  'gramd4',
  'grap',
  'grap2',
  'grapl',
  'grb10',
  'grb14',
  'grb2',
  'grb7',
  'greb1',
  'greb1l',
  'grem1',
  'grem2',
  'grhl1',
  'grhl2',
  'grhl3',
  'grhpr',
  'gria1',
  'gria2',
  'gria3',
  'gria4',
  'grid1',
  'grid2',
  'grid2ip',
  'grifin',
  'grik1',
  'grik2',
  'grik3',
  'grik4',
  'grik5',
  'grin1',
  'grin2a',
  'grin2b',
  'grin2c',
  'grin2d',
  'grin3a',
  'grina',
  'grip1',
  'gripap1',
  'grk2',
  'grk3',
  'grk5',
  'grk6',
  'grk7',
  'grm1',
  'grm2',
  'grm3',
  'grm4',
  'grm5',
  'grm6',
  'grm7',
  'grm8',
  'grn',
  'grpel1',
  'grpel2',
  'grpr',
  'grsf1',
  'grwd1',
  'grxcr2',
  'gsc',
  'gsdma',
  'gsdme',
  'gse1',
  'gsg1l',
  'gsg1l2',
  'gsk3a',
  'gsk3b',
  'gskip',
  'gsn',
  'gspt1',
  'gspt2',
  'gss',
  'gsta1',
  'gsta2',
  'gsta3',
  'gsta4',
  'gsta5',
  'gstk1',
  'gstm1',
  'gstm3',
  'gstm4',
  'gsto2',
  'gstp1',
  'gstz1',
  'gsx1',
  'gsx2',
  'gtdc1',
  'gtf2a1',
  'gtf2a2',
  'gtf2b',
  'gtf2e1',
  'gtf2e2',
  'gtf2f1',
  'gtf2f2',
  'gtf2h1',
  'gtf2h2',
  'gtf2h2c',
  'gtf2h4',
  'gtf2h5',
  'gtf2i',
  'gtf2ird1',
  'gtf2ird2',
  'gtf2ird2b',
  'gtf3a',
  'gtf3c1',
  'gtf3c2',
  'gtf3c3',
  'gtf3c4',
  'gtf3c5',
  'gtpbp1',
  'gtpbp10',
  'gtpbp2',
  'gtpbp3',
  'gtpbp4',
  'gtsf1',
  'guca1a',
  'guca1anb',
  'guca1b',
  'guca1c',
  'guca2a',
  'gucd1',
  'gucy1a1',
  'gucy1a2',
  'gucy2d',
  'gucy2f',
  'guf1',
  'gulp1',
  'gvqw3',
  'gxylt1',
  'gxylt2',
  'gyg1',
  'gys1',
  'gys2',
  'gzf1',
  'h1-0',
  'h1-2',
  'h1-3',
  'h1-4',
  'h1-5',
  'h2ac1',
  'h2ac11',
  'h2ac12',
  'h2ac13',
  'h2ac14',
  'h2ac15',
  'h2ac16',
  'h2ac17',
  'h2ac18',
  'h2ac19',
  'h2ac20',
  'h2ac21',
  'h2ac4',
  'h2ac6',
  'h2ac7',
  'h2ac8',
  'h2aj',
  'h2aw',
  'h2ax',
  'h2az1',
  'h2az2',
  'h2bc1',
  'h2bc10',
  'h2bc11',
  'h2bc12',
  'h2bc12l',
  'h2bc13',
  'h2bc14',
  'h2bc15',
  'h2bc17',
  'h2bc18',
  'h2bc21',
  'h2bc3',
  'h2bc4',
  'h2bc5',
  'h2bc6',
  'h2bc7',
  'h2bc8',
  'h2bc9',
  'h2bk1',
  'h2bu1',
  'h3-3a',
  'h3-3b',
  'h3-4',
  'h3-5',
  'h3-7',
  'h3c1',
  'h3c10',
  'h3c11',
  'h3c12',
  'h3c13',
  'h3c14',
  'h3c15',
  'h3c2',
  'h3c3',
  'h3c4',
  'h3c6',
  'h3c7',
  'h3c8',
  'h3y1',
  'h3y2',
  'h4-16',
  'h4c1',
  'h4c11',
  'h4c12',
  'h4c13',
  'h4c14',
  'h4c15',
  'h4c2',
  'h4c3',
  'h4c4',
  'h4c5',
  'h4c6',
  'h4c7',
  'h4c8',
  'h4c9',
  'h6pd',
  'haao',
  'habp4',
  'hacd1',
  'hacd2',
  'hacd3',
  'hacd4',
  'hace1',
  'hadh',
  'hadha',
  'hadhb',
  'hagh',
  'hal',
  'hand1',
  'hand2',
  'hao1',
  'hapln1',
  'hapln2',
  'hapln3',
  'hapln4',
  'harbi1',
  'hars1',
  'hars2',
  'has1',
  'has2',
  'has3',
  'hat1',
  'haus2',
  'haus3',
  'haus4',
  'haus5',
  'hax1',
  'hba1',
  'hba2',
  'hbb',
  'hbd',
  'hbe1',
  'hbegf',
  'hbg1',
  'hbg2',
  'hbp1',
  'hbq1',
  'hbs1l',
  'hbz',
  'hcar1',
  'hcar2',
  'hcar3',
  'hccs',
  'hcfc1',
  'hcfc1r1',
  'hcfc2',
  'hck',
  'hcn1',
  'hcn3',
  'hcn4',
  'hcrt',
  'hcrtr1',
  'hcrtr2',
  'hdac1',
  'hdac10',
  'hdac11',
  'hdac2',
  'hdac3',
  'hdac4',
  'hdac5',
  'hdac6',
  'hdac7',
  'hdac8',
  'hdac9',
  'hdc',
  'hddc2',
  'hddc3',
  'hdgf',
  'hdgfl2',
  'hdgfl3',
  'hdhd2',
  'hdhd3',
  'hdhd5',
  'hdlbp',
  'hdx',
  'heatr3',
  'heatr5a',
  'heatr5b',
  'heatr6',
  'hebp1',
  'hebp2',
  'hectd1',
  'hectd2',
  'hectd3',
  'hectd4',
  'hecw1',
  'hecw2',
  'hells',
  'helt',
  'helz',
  'hemk1',
  'hepacam',
  'hepacam2',
  'heph',
  'hephl1',
  'herc1',
  'herc2',
  'herc3',
  'herc4',
  'herpud1',
  'hes1',
  'hes2',
  'hes5',
  'hes6',
  'hes7',
  'hesx1',
  'hexa',
  'hexim1',
  'hexim2',
  'hey1',
  'hey2',
  'heyl',
  'hgd',
  'hgf',
  'hgfac',
  'hgh1',
  'hgs',
  'hhat',
  'hhatl',
  'hhex',
  'hhip',
  'hhipl1',
  'hibadh',
  'hic1',
  'hic2',
  'hid1',
  'hif1a',
  'hif1an',
  'hif3a',
  'higd1a',
  'higd1b',
  'higd2a',
  'hikeshi',
  'hinfp',
  'hint1',
  'hint2',
  'hip1',
  'hip1r',
  'hipk1',
  'hipk2',
  'hipk3',
  'hipk4',
  'hira',
  'hivep2',
  'hivep3',
  'hjv',
  'hk1',
  'hk2',
  'hk3',
  'hkdc1',
  'hla-a',
  'hla-b',
  'hla-c',
  'hla-dma',
  'hla-dmb',
  'hla-doa',
  'hla-dob',
  'hla-dpa1',
  'hla-dpb1',
  'hla-dqa1',
  'hla-dqa2',
  'hla-dqb1',
  'hla-dqb2',
  'hla-dra',
  'hla-drb1',
  'hla-drb5',
  'hla-e',
  'hla-f',
  'hla-g',
  'hlf',
  'hltf',
  'hlx',
  'hm13',
  'hmbox1',
  'hmbs',
  'hmcn1',
  'hmcn2',
  'hmg20a',
  'hmg20b',
  'hmga1',
  'hmga2',
  'hmgb1',
  'hmgb2',
  'hmgb3',
  'hmgcl',
  'hmgcll1',
  'hmgcr',
  'hmgcs1',
  'hmgcs2',
  'hmgn3',
  'hmgn4',
  'hmgxb3',
  'hmgxb4',
  'hmox1',
  'hmox2',
  'hmx1',
  'hmx2',
  'hmx3',
  'hnf1a',
  'hnf1b',
  'hnf4a',
  'hnf4g',
  'hnmt',
  'hnrnpa0',
  'hnrnpa1',
  'hnrnpa1l2',
  'hnrnpa1l3',
  'hnrnpa2b1',
  'hnrnpa3',
  'hnrnpab',
  'hnrnpc',
  'hnrnpcl1',
  'hnrnpcl2',
  'hnrnpcl3',
  'hnrnpcl4',
  'hnrnpd',
  'hnrnpdl',
  'hnrnpf',
  'hnrnph1',
  'hnrnph2',
  'hnrnph3',
  'hnrnpk',
  'hnrnpl',
  'hnrnpll',
  'hnrnpr',
  'hnrnpu',
  'hnrnpul1',
  'hnrnpul2',
  'hoga1',
  'homer1',
  'homer2',
  'homer3',
  'homez',
  'hook1',
  'hook2',
  'hook3',
  'hoxa1',
  'hoxa10',
  'hoxa11',
  'hoxa13',
  'hoxa2',
  'hoxa3',
  'hoxa4',
  'hoxa5',
  'hoxa6',
  'hoxa7',
  'hoxa9',
  'hoxb1',
  'hoxb13',
  'hoxb2',
  'hoxb3',
  'hoxb4',
  'hoxb5',
  'hoxb6',
  'hoxb7',
  'hoxb8',
  'hoxb9',
  'hoxc10',
  'hoxc11',
  'hoxc12',
  'hoxc13',
  'hoxc4',
  'hoxc5',
  'hoxc6',
  'hoxc8',
  'hoxc9',
  'hoxd1',
  'hoxd10',
  'hoxd11',
  'hoxd12',
  'hoxd13',
  'hoxd3',
  'hoxd4',
  'hoxd8',
  'hoxd9',
  'hp',
  'hp1bp3',
  'hpca',
  'hpcal1',
  'hpcal4',
  'hpd',
  'hpdl',
  'hpf1',
  'hpgd',
  'hpn',
  'hprt1',
  'hps1',
  'hps3',
  'hps5',
  'hps6',
  'hpse2',
  'hpx',
  'hr',
  'hras',
  'hrh1',
  'hrh2',
  'hrh3',
  'hrk',
  'hrob',
  'hs1bp3',
  'hs2st1',
  'hs3st1',
  'hs3st2',
  'hs3st3a1',
  'hs3st3b1',
  'hs3st4',
  'hs3st5',
  'hs3st6',
  'hs6st1',
  'hs6st2',
  'hs6st3',
  'hsbp1',
  'hscb',
  'hsd11b1',
  'hsd11b1l',
  'hsd11b2',
  'hsd17b10',
  'hsd17b14',
  'hsd17b4',
  'hsd17b6',
  'hsd17b8',
  'hsd3b7',
  'hsdl1',
  'hsdl2',
  'hsf1',
  'hsf2',
  'hsf2bp',
  'hsf4',
  'hsf5',
  'hsp90aa1',
  'hsp90ab1',
  'hsp90b1',
  'hspa12a',
  'hspa12b',
  'hspa13',
  'hspa14',
  'hspa1a',
  'hspa1b',
  'hspa1l',
  'hspa2',
  'hspa4',
  'hspa4l',
  'hspa5',
  'hspa6',
  'hspa8',
  'hspa9',
  'hspb1',
  'hspb2',
  'hspb6',
  'hspb7',
  'hspb8',
  'hspbap1',
  'hspbp1',
  'hspd1',
  'hspe1',
  'hspg2',
  'hsph1',
  'htatip2',
  'htr1a',
  'htr1b',
  'htr1d',
  'htr1f',
  'htr2a',
  'htr2b',
  'htr2c',
  'htr3a',
  'htr3c',
  'htr3e',
  'htr4',
  'htr5a',
  'htr6',
  'htr7',
  'htra1',
  'htra2',
  'htra3',
  'htt',
  'hunk',
  'hus1',
  'huwe1',
  'hvcn1',
  'hyal1',
  'hyal2',
  'hyal3',
  'hyal4',
  'hyi',
  'hykk',
  'hyou1',
  'hypk',
  'iah1',
  'iars1',
  'iars2',
  'iba57',
  'ibtk',
  'ica1',
  'ica1l',
  'icam5',
  'ice2',
  'icmt',
  'id1',
  'id2',
  'id3',
  'id4',
  'ide',
  'idh1',
  'idh2',
  'idh3a',
  'idh3b',
  'idh3g',
  'ids',
  'idua',
  'ier2',
  'ier3',
  'ier3ip1',
  'ier5l',
  'iffo1',
  'iffo2',
  'ifi35',
  'ifit1',
  'ifit5',
  'ifitm10',
  'ifitm3',
  'ifitm5',
  'ifnl1',
  'ifnl2',
  'ifnl3',
  'ifrd1',
  'ifrd2',
  'ift122',
  'ift172',
  'ift20',
  'ift22',
  'ift27',
  'ift46',
  'ift52',
  'ift57',
  'ift80',
  'ift81',
  'igbp1',
  'igbp1p2',
  'igdcc3',
  'igdcc4',
  'igf1',
  'igf1r',
  'igf2',
  'igf2bp1',
  'igf2bp2',
  'igf2bp3',
  'igfals',
  'igfbp2',
  'igfbp3',
  'igfbp4',
  'igfbp5',
  'igfbp6',
  'igfbp7',
  'ighmbp2',
  'igip',
  'igll1',
  'igll5',
  'iglon5',
  'igsf1',
  'igsf11',
  'igsf21',
  'igsf22',
  'igsf3',
  'igsf8',
  'igsf9',
  'igsf9b',
  'ihh',
  'ik',
  'ikbip',
  'ikbkb',
  'ikbke',
  'ikbkg',
  'ikzf1',
  'ikzf2',
  'ikzf3',
  'ikzf4',
  'ikzf5',
  'il10',
  'il10ra',
  'il10rb',
  'il11',
  'il11ra',
  'il12b',
  'il13ra1',
  'il17b',
  'il17d',
  'il17rc',
  'il17rd',
  'il1f10',
  'il1rap',
  'il1rapl1',
  'il1rapl2',
  'il20',
  'il20rb',
  'il21r',
  'il22',
  'il22ra1',
  'il23a',
  'il25',
  'il27',
  'il2rg',
  'il36rn',
  'il4i1',
  'il6r',
  'il6st',
  'ildr1',
  'ildr2',
  'ilf2',
  'ilf3',
  'ilk',
  'ilkap',
  'ilrun',
  'ilvbl',
  'immp1l',
  'immp2l',
  'immt',
  'imp3',
  'imp4',
  'impa1',
  'impa2',
  'impact',
  'impdh1',
  'impdh2',
  'ina',
  'inava',
  'ing1',
  'ing3',
  'ing4',
  'ing5',
  'inha',
  'inhba',
  'inhbb',
  'inhbc',
  'inhbe',
  'inip',
  'inka1',
  'ino80',
  'ino80b',
  'ino80c',
  'ino80d',
  'ino80e',
  'inpp1',
  'inpp4a',
  'inpp4b',
  'inpp5a',
  'inpp5b',
  'inpp5d',
  'inpp5f',
  'inpp5j',
  'inppl1',
  'ins',
  'insc',
  'insig2',
  'insm1',
  'insm2',
  'insr',
  'insrr',
  'insyn1',
  'insyn2a',
  'insyn2b',
  'ints1',
  'ints10',
  'ints11',
  'ints12',
  'ints13',
  'ints14',
  'ints15',
  'ints2',
  'ints3',
  'ints4',
  'ints5',
  'ints6',
  'ints6l',
  'ints7',
  'ints8',
  'ints9',
  'intu',
  'invs',
  'ip6k1',
  'ip6k2',
  'ip6k3',
  'ipmk',
  'ipo11',
  'ipo13',
  'ipo5',
  'ipo7',
  'ipo8',
  'ipo9',
  'ipp',
  'ippk',
  'iqank1',
  'iqcb1',
  'iqcf3',
  'iqcf5',
  'iqcj',
  'iqgap1',
  'iqgap2',
  'iqgap3',
  'iqsec1',
  'iqsec2',
  'iqsec3',
  'irag1',
  'irak1',
  'irak1bp1',
  'irak2',
  'irak4',
  'ireb2',
  'irf1',
  'irf2',
  'irf2bp1',
  'irf2bp2',
  'irf3',
  'irf4',
  'irf6',
  'irf8',
  'irf9',
  'irgc',
  'irgq',
  'irs1',
  'irs4',
  'irx1',
  'irx2',
  'irx3',
  'irx4',
  'irx5',
  'irx6',
  'isca1',
  'isca2',
  'iscu',
  'isg15',
  'isg20',
  'isg20l2',
  'isl1',
  'isl2',
  'islr',
  'islr2',
  'ism1',
  'isoc1',
  'isoc2',
  'ist1',
  'isx',
  'isyna1',
  'itch',
  'itfg1',
  'itfg2',
  'itga1',
  'itga10',
  'itga11',
  'itga2',
  'itga2b',
  'itga3',
  'itga5',
  'itga6',
  'itga7',
  'itga8',
  'itga9',
  'itgad',
  'itgav',
  'itgb1',
  'itgb1bp1',
  'itgb1bp2',
  'itgb2',
  'itgb3',
  'itgb4',
  'itgb5',
  'itgb6',
  'itgb7',
  'itgb8',
  'itgbl1',
  'itih1',
  'itih3',
  'itih5',
  'itk',
  'itm2b',
  'itm2c',
  'itpa',
  'itpk1',
  'itpka',
  'itpkb',
  'itpkc',
  'itpr1',
  'itpr2',
  'itpr3',
  'itprid2',
  'itprip',
  'itpripl1',
  'itpripl2',
  'itsn1',
  'itsn2',
  'ivd',
  'ivns1abp',
  'iws1',
  'iyd',
  'izumo1r',
  'jade1',
  'jade2',
  'jade3',
  'jag1',
  'jag2',
  'jak1',
  'jak2',
  'jak3',
  'jakmip1',
  'jakmip2',
  'jam2',
  'jam3',
  'jarid2',
  'jazf1',
  'jdp2',
  'jkamp',
  'jmjd1c',
  'jmjd4',
  'jmjd6',
  'jmjd7',
  'jmy',
  'josd1',
  'josd2',
  'jph1',
  'jph2',
  'jph3',
  'jph4',
  'jpt1',
  'jpt2',
  'jrkl',
  'jtb',
  'jun',
  'junb',
  'jund',
  'jup',
  'kalrn',
  'kank1',
  'kank2',
  'kank3',
  'kansl1',
  'kansl1l',
  'kansl2',
  'kansl3',
  'kantr',
  'kars1',
  'kash5',
  'kat14',
  'kat2a',
  'kat2b',
  'kat5',
  'kat6a',
  'kat6b',
  'kat7',
  'kat8',
  'katna1',
  'katnal1',
  'katnal2',
  'katnb1',
  'katnbl1',
  'katnip',
  'kazald1',
  'kazn',
  'kbtbd11',
  'kbtbd13',
  'kbtbd2',
  'kbtbd3',
  'kbtbd4',
  'kbtbd6',
  'kbtbd7',
  'kbtbd8',
  'kcmf1',
  'kcna1',
  'kcna10',
  'kcna2',
  'kcna3',
  'kcna4',
  'kcna5',
  'kcna6',
  'kcna7',
  'kcnab1',
  'kcnab2',
  'kcnab3',
  'kcnb2',
  'kcnc1',
  'kcnc2',
  'kcnc3',
  'kcnc4',
  'kcnd1',
  'kcnd2',
  'kcnd3',
  'kcne1',
  'kcne2',
  'kcne3',
  'kcne4',
  'kcne5',
  'kcnf1',
  'kcng1',
  'kcng2',
  'kcng3',
  'kcng4',
  'kcnh1',
  'kcnh2',
  'kcnh3',
  'kcnh4',
  'kcnh5',
  'kcnh6',
  'kcnh7',
  'kcnh8',
  'kcnip1',
  'kcnip2',
  'kcnip3',
  'kcnip4',
  'kcnj10',
  'kcnj11',
  'kcnj12',
  'kcnj13',
  'kcnj14',
  'kcnj15',
  'kcnj18',
  'kcnj2',
  'kcnj3',
  'kcnj4',
  'kcnj5',
  'kcnj6',
  'kcnj8',
  'kcnj9',
  'kcnk1',
  'kcnk10',
  'kcnk12',
  'kcnk13',
  'kcnk15',
  'kcnk16',
  'kcnk17',
  'kcnk2',
  'kcnk3',
  'kcnk4',
  'kcnk5',
  'kcnk6',
  'kcnk7',
  'kcnma1',
  'kcnmb1',
  'kcnmb2',
  'kcnmb4',
  'kcnn1',
  'kcnn2',
  'kcnn3',
  'kcnn4',
  'kcnq1',
  'kcnq2',
  'kcnq3',
  'kcnq4',
  'kcnq5',
  'kcns1',
  'kcns2',
  'kcnt1',
  'kcnt2',
  'kcnv1',
  'kcnv2',
  'kcp',
  'kctd1',
  'kctd10',
  'kctd11',
  'kctd12',
  'kctd13',
  'kctd15',
  'kctd16',
  'kctd17',
  'kctd19',
  'kctd2',
  'kctd20',
  'kctd21',
  'kctd3',
  'kctd4',
  'kctd5',
  'kctd6',
  'kctd7',
  'kctd8',
  'kctd9',
  'kdelr1',
  'kdelr2',
  'kdelr3',
  'kdf1',
  'kdm1a',
  'kdm1b',
  'kdm2a',
  'kdm2b',
  'kdm3a',
  'kdm3b',
  'kdm4a',
  'kdm4b',
  'kdm4c',
  'kdm5a',
  'kdm5b',
  'kdm5c',
  'kdm6a',
  'kdm6b',
  'kdm7a',
  'kdm8',
  'kdr',
  'kdsr',
  'keap1',
  'kera',
  'khdc4',
  'khdrbs1',
  'khdrbs2',
  'khdrbs3',
  'khk',
  'khnyn',
  'khsrp',
  'kiaa0100',
  'kiaa0232',
  'kiaa0319l',
  'kiaa0513',
  'kiaa0895l',
  'kiaa0930',
  'kiaa1109',
  'kiaa1143',
  'kiaa1191',
  'kiaa1755',
  'kiaa1958',
  'kiaa2013',
  'kiaa2026',
  'kidins220',
  'kif11',
  'kif12',
  'kif13a',
  'kif15',
  'kif16b',
  'kif17',
  'kif18b',
  'kif19',
  'kif1a',
  'kif1b',
  'kif1c',
  'kif20a',
  'kif21a',
  'kif21b',
  'kif22',
  'kif23',
  'kif24',
  'kif26a',
  'kif26b',
  'kif27',
  'kif2a',
  'kif2b',
  'kif2c',
  'kif3a',
  'kif3b',
  'kif3c',
  'kif4a',
  'kif5a',
  'kif5b',
  'kif5c',
  'kif6',
  'kif7',
  'kif9',
  'kifap3',
  'kifbp',
  'kifc1',
  'kifc2',
  'kifc3',
  'kirrel1',
  'kirrel2',
  'kirrel3',
  'kiss1r',
  'kit',
  'kitlg',
  'kl',
  'klb',
  'klc1',
  'klc2',
  'klc3',
  'klc4',
  'klf1',
  'klf10',
  'klf12',
  'klf13',
  'klf16',
  'klf3',
  'klf4',
  'klf5',
  'klf6',
  'klf7',
  'klf9',
  'klhdc1',
  'klhdc10',
  'klhdc2',
  'klhdc3',
  'klhdc7a',
  'klhdc8a',
  'klhdc8b',
  'klhdc9',
  'klhl1',
  'klhl10',
  'klhl11',
  'klhl12',
  'klhl13',
  'klhl14',
  'klhl15',
  'klhl17',
  'klhl18',
  'klhl20',
  'klhl21',
  'klhl22',
  'klhl23',
  'klhl24',
  'klhl25',
  'klhl26',
  'klhl28',
  'klhl29',
  'klhl3',
  'klhl30',
  'klhl31',
  'klhl32',
  'klhl33',
  'klhl34',
  'klhl35',
  'klhl36',
  'klhl4',
  'klhl40',
  'klhl41',
  'klhl42',
  'klhl5',
  'klhl6',
  'klhl7',
  'klhl8',
  'klhl9',
  'klk10',
  'klk11',
  'klk12',
  'klk13',
  'klk14',
  'klk15',
  'klk4',
  'klk6',
  'klk7',
  'klk8',
  'klk9',
  'klln',
  'kmt2a',
  'kmt2b',
  'kmt2c',
  'kmt2d',
  'kmt2e',
  'kmt5a',
  'kmt5b',
  'kmt5c',
  'kncn',
  'kpna1',
  'kpna2',
  'kpna3',
  'kpna4',
  'kpna5',
  'kpna6',
  'kpnb1',
  'kptn',
  'kras',
  'krcc1',
  'kremen2',
  'krit1',
  'krr1',
  'krt1',
  'krt12',
  'krt14',
  'krt15',
  'krt16',
  'krt17',
  'krt18',
  'krt19',
  'krt2',
  'krt222',
  'krt23',
  'krt24',
  'krt25',
  'krt26',
  'krt27',
  'krt28',
  'krt31',
  'krt32',
  'krt33a',
  'krt33b',
  'krt34',
  'krt35',
  'krt36',
  'krt37',
  'krt38',
  'krt39',
  'krt4',
  'krt40',
  'krt5',
  'krt6a',
  'krt6b',
  'krt6c',
  'krt7',
  'krt71',
  'krt72',
  'krt73',
  'krt74',
  'krt75',
  'krt76',
  'krt78',
  'krt79',
  'krt8',
  'krt80',
  'krt81',
  'krt82',
  'krt83',
  'krt84',
  'krt85',
  'krt86',
  'krtap11-1',
  'krtap13-1',
  'krtap13-2',
  'krtap13-4',
  'krtap16-1',
  'krtap19-2',
  'krtap19-4',
  'krtap19-5',
  'krtap19-6',
  'krtap19-7',
  'krtap19-8',
  'krtap21-1',
  'krtap3-1',
  'krtap7-1',
  'krtap8-1',
  'krtcap2',
  'krtcap3',
  'ksr1',
  'ksr2',
  'kti12',
  'ktn1',
  'kxd1',
  'ky',
  'kyat1',
  'l1cam',
  'l2hgdh',
  'l3hypdh',
  'l3mbtl1',
  'l3mbtl2',
  'l3mbtl3',
  'l3mbtl4',
  'lacc1',
  'lactb',
  'lactb2',
  'lactbl1',
  'lag3',
  'lama2',
  'lama3',
  'lama4',
  'lama5',
  'lamb1',
  'lamb2',
  'lamb3',
  'lamc1',
  'lamc2',
  'lamc3',
  'lamp5',
  'lamtor1',
  'lamtor2',
  'lamtor3',
  'lamtor4',
  'lamtor5',
  'lancl1',
  'lancl2',
  'lancl3',
  'laptm4a',
  'laptm4b',
  'laptm5',
  'large1',
  'large2',
  'larp1',
  'larp1b',
  'larp4',
  'larp7',
  'lars1',
  'lars2',
  'las1l',
  'lasp1',
  'lat',
  'lats1',
  'lats2',
  'layn',
  'lbh',
  'lbx1',
  'lbx2',
  'lcat',
  'lce1a',
  'lce1c',
  'lce2a',
  'lce2b',
  'lce2c',
  'lce2d',
  'lce3a',
  'lce3b',
  'lce3c',
  'lce3e',
  'lce4a',
  'lce5a',
  'lck',
  'lclat1',
  'lcmt1',
  'lcmt2',
  'lcp1',
  'lcp2',
  'lct',
  'lctl',
  'ldah',
  'ldb1',
  'ldb2',
  'ldb3',
  'ldha',
  'ldhal6a',
  'ldhal6b',
  'ldhb',
  'ldhc',
  'ldhd',
  'ldlr',
  'ldlrad1',
  'ldlrad2',
  'ldlrad3',
  'ldlrad4',
  'ldlrap1',
  'ldoc1',
  'lef1',
  'lefty1',
  'lefty2',
  'lemd2',
  'lemd3',
  'lenep',
  'leng1',
  'leng8',
  'leo1',
  'lep',
  'leprot',
  'letmd1',
  'lfng',
  'lgals1',
  'lgals12',
  'lgals3',
  'lgals3bp',
  'lgals4',
  'lgals7',
  'lgals7b',
  'lgalsl',
  'lgi1',
  'lgi2',
  'lgi3',
  'lgi4',
  'lgmn',
  'lgr4',
  'lgr5',
  'lgr6',
  'lhcgr',
  'lhfpl1',
  'lhfpl2',
  'lhfpl3',
  'lhfpl4',
  'lhfpl5',
  'lhfpl6',
  'lhpp',
  'lhx1',
  'lhx2',
  'lhx3',
  'lhx4',
  'lhx5',
  'lhx6',
  'lhx8',
  'lhx9',
  'lias',
  'lif',
  'lig1',
  'lig3',
  'lig4',
  'lilra1',
  'lilra2',
  'lilra4',
  'lilra5',
  'lilrb2',
  'lilrb3',
  'lilrb5',
  'lim2',
  'lima1',
  'limd1',
  'limd2',
  'limk1',
  'limk2',
  'lims1',
  'lims2',
  'lims3',
  'lims4',
  'lin28a',
  'lin28b',
  'lin37',
  'lin52',
  'lin54',
  'lin7a',
  'lin7b',
  'lin7c',
  'lin9',
  'lingo1',
  'lingo2',
  'lingo3',
  'lingo4',
  'lipc',
  'lipe',
  'lipg',
  'lipk',
  'lipm',
  'lipt1',
  'lipt2',
  'litaf',
  'lix1',
  'lix1l',
  'lkaaear1',
  'llgl1',
  'llgl2',
  'llph',
  'lman1',
  'lman2',
  'lman2l',
  'lmbr1',
  'lmbr1l',
  'lmbrd1',
  'lmbrd2',
  'lmcd1',
  'lmf2',
  'lmln',
  'lmna',
  'lmnb1',
  'lmnb2',
  'lmo1',
  'lmo2',
  'lmo3',
  'lmo4',
  'lmod1',
  'lmod2',
  'lmtk3',
  'lmx1a',
  'lmx1b',
  'lnp1',
  'lnpep',
  'lnpk',
  'lnx1',
  'lnx2',
  'loc100421372',
  'loc100505841',
  'loc102723451',
  'loc102723475',
  'loc102724159',
  'loc102724428',
  'loc102724560',
  'loc102724594',
  'loc102724652',
  'loc105372440',
  'loc112268293',
  'loc112694756',
  'loc344065',
  'loc389895',
  'loc728392',
  'loc728743',
  'loc730098',
  'lonp1',
  'lonp2',
  'lonrf1',
  'lonrf3',
  'lox',
  'loxhd1',
  'loxl1',
  'loxl2',
  'loxl3',
  'loxl4',
  'lpar1',
  'lpar3',
  'lpar6',
  'lpcat2',
  'lpcat3',
  'lpcat4',
  'lpgat1',
  'lpin1',
  'lpin2',
  'lpin3',
  'lpl',
  'lpo',
  'lpp',
  'lpxn',
  'lratd1',
  'lratd2',
  'lrba',
  'lrch1',
  'lrch2',
  'lrch3',
  'lrch4',
  'lrfn1',
  'lrfn2',
  'lrfn3',
  'lrfn4',
  'lrfn5',
  'lrig2',
  'lrig3',
  'lrit1',
  'lrmda',
  'lrp1',
  'lrp10',
  'lrp11',
  'lrp12',
  'lrp1b',
  'lrp2bp',
  'lrp3',
  'lrp4',
  'lrp5',
  'lrp6',
  'lrp8',
  'lrpap1',
  'lrr1',
  'lrrc1',
  'lrrc10',
  'lrrc10b',
  'lrrc14',
  'lrrc14b',
  'lrrc15',
  'lrrc17',
  'lrrc18',
  'lrrc2',
  'lrrc20',
  'lrrc23',
  'lrrc24',
  'lrrc28',
  'lrrc30',
  'lrrc32',
  'lrrc36',
  'lrrc38',
  'lrrc39',
  'lrrc3b',
  'lrrc3c',
  'lrrc4',
  'lrrc40',
  'lrrc41',
  'lrrc42',
  'lrrc45',
  'lrrc46',
  'lrrc47',
  'lrrc49',
  'lrrc4b',
  'lrrc4c',
  'lrrc51',
  'lrrc52',
  'lrrc55',
  'lrrc56',
  'lrrc57',
  'lrrc58',
  'lrrc59',
  'lrrc61',
  'lrrc7',
  'lrrc71',
  'lrrc73',
  'lrrc75a',
  'lrrc75b',
  'lrrc8a',
  'lrrc8b',
  'lrrc8c',
  'lrrc8d',
  'lrrc8e',
  'lrrc9',
  'lrrfip1',
  'lrrfip2',
  'lrrk1',
  'lrrk2',
  'lrrn1',
  'lrrn2',
  'lrrn3',
  'lrrn4',
  'lrrn4cl',
  'lrrtm1',
  'lrrtm2',
  'lrrtm3',
  'lrrtm4',
  'lrsam1',
  'lrtm1',
  'lrtm2',
  'lrwd1',
  'lsamp',
  'lsm1',
  'lsm10',
  'lsm11',
  'lsm12',
  'lsm14a',
  'lsm14b',
  'lsm2',
  'lsm3',
  'lsm4',
  'lsm5',
  'lsm6',
  'lsm7',
  'lsm8',
  'lsmem1',
  'lsmem2',
  'lsr',
  'lss',
  'lta',
  'ltb',
  'ltb4r',
  'ltb4r2',
  'ltbp1',
  'ltbp2',
  'ltbp3',
  'ltbp4',
  'ltc4s',
  'ltf',
  'ltk',
  'ltn1',
  'ltv1',
  'luc7l',
  'luc7l2',
  'luc7l3',
  'lurap1',
  'lurap1l',
  'luzp1',
  'luzp2',
  'lxn',
  'ly6e',
  'ly6g5b',
  'ly6g5c',
  'ly6g6c',
  'ly6g6f',
  'ly6h',
  'ly75',
  'lyl1',
  'lyn',
  'lynx1',
  'lypd1',
  'lypd4',
  'lypd5',
  'lypd6',
  'lypd6b',
  'lypla1',
  'lypla2',
  'lyplal1',
  'lyrm1',
  'lyrm4',
  'lyrm9',
  'lysmd1',
  'lysmd2',
  'lysmd3',
  'lyzl1',
  'lyzl2',
  'lyzl6',
  'lzic',
  'lztr1',
  'lzts1',
  'lzts2',
  'lzts3',
  'm1ap',
  'm6pr',
  'mab21l1',
  'mab21l2',
  'mab21l3',
  'mab21l4',
  'macir',
  'maco1',
  'macrod1',
  'macroh2a1',
  'macroh2a2',
  'mad1l1',
  'mad2l1',
  'mad2l1bp',
  'mad2l2',
  'madd',
  'maea',
  'mael',
  'maf1',
  'mafa',
  'mafb',
  'maff',
  'mafg',
  'mafk',
  'mag',
  'mageb1',
  'mageb10',
  'mageb17',
  'mageb18',
  'mageb2',
  'mageb5',
  'maged1',
  'maged2',
  'magef1',
  'magi1',
  'magi2',
  'magi3',
  'magix',
  'magoh',
  'magohb',
  'magt1',
  'maip1',
  'mak',
  'mak16',
  'mal',
  'mal2',
  'malt1',
  'mamdc2',
  'maml1',
  'maml3',
  'mamld1',
  'mamstr',
  'man1a1',
  'man1a2',
  'man1c1',
  'man2a2',
  'man2b2',
  'man2c1',
  'manbal',
  'manea',
  'maneal',
  'manf',
  'maob',
  'map1a',
  'map1b',
  'map1lc3a',
  'map1lc3b',
  'map1lc3b2',
  'map1lc3c',
  'map1s',
  'map2',
  'map2k1',
  'map2k2',
  'map2k3',
  'map2k4',
  'map2k5',
  'map2k6',
  'map2k7',
  'map3k1',
  'map3k10',
  'map3k11',
  'map3k12',
  'map3k13',
  'map3k14',
  'map3k15',
  'map3k2',
  'map3k20',
  'map3k3',
  'map3k4',
  'map3k5',
  'map3k6',
  'map3k7',
  'map3k7cl',
  'map3k8',
  'map3k9',
  'map4k1',
  'map4k2',
  'map4k3',
  'map4k4',
  'map4k5',
  'map6d1',
  'map7',
  'map7d1',
  'mapk1',
  'mapk10',
  'mapk11',
  'mapk12',
  'mapk13',
  'mapk14',
  'mapk1ip1l',
  'mapk3',
  'mapk4',
  'mapk6',
  'mapk7',
  'mapk8',
  'mapk8ip1',
  'mapk8ip2',
  'mapk8ip3',
  'mapk9',
  'mapkap1',
  'mapkapk2',
  'mapkapk3',
  'mapkapk5',
  'mapkbp1',
  'mapre1',
  'mapre2',
  'mapre3',
  'marchf1',
  'marchf11',
  'marchf2',
  'marchf3',
  'marchf4',
  'marchf5',
  'marchf6',
  'marchf7',
  'marchf8',
  'marchf9',
  'marcksl1',
  'marf1',
  'mark1',
  'mark2',
  'mark3',
  'mark4',
  'mars1',
  'mars2',
  'marveld1',
  'marveld2',
  'marveld3',
  'masp1',
  'masp2',
  'mast1',
  'mast2',
  'mast3',
  'mast4',
  'mat1a',
  'mat2a',
  'mat2b',
  'matk',
  'matn1',
  'matn2',
  'matn3',
  'matn4',
  'matr3',
  'mau2',
  'max',
  'maz',
  'mb',
  'mb21d2',
  'mbd1',
  'mbd2',
  'mbd3',
  'mbd5',
  'mbd6',
  'mbip',
  'mblac2',
  'mbnl1',
  'mbnl2',
  'mbnl3',
  'mboat1',
  'mboat2',
  'mboat7',
  'mbtd1',
  'mbtps1',
  'mbtps2',
  'mc1r',
  'mc2r',
  'mc3r',
  'mc4r',
  'mc5r',
  'mcam',
  'mcat',
  'mcc',
  'mccc1',
  'mccc2',
  'mcf2',
  'mcfd2',
  'mchr1',
  'mchr2',
  'mcidas',
  'mcl1',
  'mcm2',
  'mcm3',
  'mcm3ap',
  'mcm4',
  'mcm5',
  'mcm6',
  'mcm7',
  'mcm8',
  'mcmbp',
  'mcmdc2',
  'mcoln1',
  'mcoln2',
  'mcoln3',
  'mcrip1',
  'mcrip2',
  'mcrs1',
  'mctp1',
  'mctp2',
  'mcts1',
  'mcts2',
  'mcu',
  'mdfi',
  'mdfic',
  'mdfic2',
  'mdga1',
  'mdga2',
  'mdh1',
  'mdh2',
  'mdk',
  'mdm2',
  'mdm4',
  'mdn1',
  'mdp1',
  'me1',
  'me2',
  'me3',
  'mea1',
  'meaf6',
  'meak7',
  'mecom',
  'mecp2',
  'mecr',
  'med1',
  'med10',
  'med11',
  'med12',
  'med12l',
  'med13',
  'med13l',
  'med14',
  'med15',
  'med16',
  'med17',
  'med18',
  'med19',
  'med20',
  'med21',
  'med22',
  'med23',
  'med24',
  'med25',
  'med26',
  'med27',
  'med28',
  'med29',
  'med30',
  'med31',
  'med4',
  'med6',
  'med7',
  'med8',
  'med9',
  'medag',
  'mef2a',
  'mef2b',
  'mef2c',
  'mef2d',
  'megf10',
  'megf11',
  'megf6',
  'megf8',
  'megf9',
  'mei1',
  'meig1',
  'meioc',
  'meis1',
  'meis2',
  'meis3',
  'melk',
  'meltf',
  'memo1',
  'men1',
  'meox1',
  'meox2',
  'mepce',
  'mertk',
  'mesd',
  'mest',
  'met',
  'metap1',
  'metap1d',
  'metap2',
  'metrn',
  'metrnl',
  'mettl1',
  'mettl13',
  'mettl14',
  'mettl17',
  'mettl21c',
  'mettl22',
  'mettl23',
  'mettl24',
  'mettl25',
  'mettl25b',
  'mettl26',
  'mettl2a',
  'mettl2b',
  'mettl3',
  'mettl4',
  'mettl5',
  'mettl7a',
  'mettl7b',
  'mettl9',
  'mex3a',
  'mex3b',
  'mex3c',
  'mfap1',
  'mfap2',
  'mfap3',
  'mfap3l',
  'mfap4',
  'mff',
  'mfhas1',
  'mfn1',
  'mfn2',
  'mfng',
  'mfrp',
  'mfsd1',
  'mfsd10',
  'mfsd11',
  'mfsd12',
  'mfsd13a',
  'mfsd14a',
  'mfsd14b',
  'mfsd2a',
  'mfsd2b',
  'mfsd3',
  'mfsd4a',
  'mfsd4b',
  'mfsd5',
  'mfsd6',
  'mfsd6l',
  'mfsd9',
  'mga',
  'mgam',
  'mgat1',
  'mgat2',
  'mgat3',
  'mgat4a',
  'mgat4b',
  'mgat4c',
  'mgat5',
  'mgat5b',
  'mgll',
  'mgme1',
  'mgrn1',
  'mgst2',
  'mgst3',
  'mia',
  'mib1',
  'mib2',
  'mical1',
  'mical2',
  'mical3',
  'micall1',
  'micos10',
  'micos13',
  'micu1',
  'micu3',
  'mid1',
  'mid1ip1',
  'mid2',
  'mideas',
  'midn',
  'mief1',
  'mief2',
  'mien1',
  'mier1',
  'mier2',
  'mier3',
  'mif',
  'mif4gd',
  'miga1',
  'miga2',
  'mindy1',
  'mindy2',
  'mindy3',
  'mink1',
  'minpp1',
  'mios',
  'miox',
  'mip',
  'mis12',
  'mis18a',
  'misp3',
  'mitf',
  'mix23',
  'mkks',
  'mkln1',
  'mknk1',
  'mknk2',
  'mkrn1',
  'mkrn2',
  'mks1',
  'mkx',
  'mlec',
  'mlf2',
  'mlh1',
  'mllt1',
  'mllt10',
  'mllt11',
  'mllt3',
  'mllt6',
  'mln',
  'mlnr',
  'mlst8',
  'mlx',
  'mlxip',
  'mlxipl',
  'mlycd',
  'mmaa',
  'mmachc',
  'mmadhc',
  'mmd',
  'mmd2',
  'mme',
  'mmel1',
  'mmgt1',
  'mmp1',
  'mmp10',
  'mmp11',
  'mmp13',
  'mmp14',
  'mmp15',
  'mmp16',
  'mmp17',
  'mmp19',
  'mmp2',
  'mmp20',
  'mmp23b',
  'mmp25',
  'mmp28',
  'mmp3',
  'mmp9',
  'mmrn2',
  'mms19',
  'mms22l',
  'mmut',
  'mn1',
  'mnat1',
  'mnd1',
  'mns1',
  'mnt',
  'mob1a',
  'mob1b',
  'mob2',
  'mob3a',
  'mob3b',
  'mob3c',
  'mob4',
  'mocs1',
  'mocs2',
  'mocs3',
  'mog',
  'mogat2',
  'mogs',
  'mon1a',
  'mon1b',
  'mon2',
  'morc2',
  'morc4',
  'morf4l1',
  'morf4l2',
  'morn2',
  'morn3',
  'morn4',
  'morn5',
  'mos',
  'mosmo',
  'mospd1',
  'mospd2',
  'mospd3',
  'mov10',
  'moxd1',
  'mpc1',
  'mpc2',
  'mpdu1',
  'mpdz',
  'mpeg1',
  'mphosph6',
  'mpi',
  'mpig6b',
  'mpl',
  'mplkip',
  'mpnd',
  'mpo',
  'mpp1',
  'mpp2',
  'mpp3',
  'mpp4',
  'mpp7',
  'mpped1',
  'mpped2',
  'mprip',
  'mpst',
  'mpv17',
  'mpv17l',
  'mpv17l2',
  'mpz',
  'mpzl1',
  'mpzl2',
  'mpzl3',
  'mrap2',
  'mras',
  'mrc2',
  'mre11',
  'mreg',
  'mrfap1',
  'mrfap1l1',
  'mrgbp',
  'mrgpre',
  'mrgprf',
  'mrgprx1',
  'mrgprx2',
  'mrgprx3',
  'mrgprx4',
  'mri1',
  'mrm1',
  'mrm2',
  'mrm3',
  'mro',
  'mroh2b',
  'mroh6',
  'mroh7',
  'mrpl10',
  'mrpl11',
  'mrpl12',
  'mrpl13',
  'mrpl14',
  'mrpl15',
  'mrpl16',
  'mrpl17',
  'mrpl18',
  'mrpl19',
  'mrpl2',
  'mrpl20',
  'mrpl22',
  'mrpl24',
  'mrpl27',
  'mrpl28',
  'mrpl30',
  'mrpl32',
  'mrpl34',
  'mrpl35',
  'mrpl37',
  'mrpl38',
  'mrpl39',
  'mrpl4',
  'mrpl41',
  'mrpl42',
  'mrpl43',
  'mrpl44',
  'mrpl46',
  'mrpl48',
  'mrpl49',
  'mrpl51',
  'mrpl52',
  'mrpl53',
  'mrpl55',
  'mrpl57',
  'mrpl58',
  'mrpl9',
  'mrps12',
  'mrps14',
  'mrps16',
  'mrps17',
  'mrps18a',
  'mrps21',
  'mrps24',
  'mrps26',
  'mrps28',
  'mrps30',
  'mrps33',
  'mrps34',
  'mrps36',
  'mrps6',
  'mrps7',
  'mrrf',
  'mrtfb',
  'mrto4',
  'msantd1',
  'msantd2',
  'msantd3',
  'msantd4',
  'msc',
  'msgn1',
  'msh2',
  'msh4',
  'msh5',
  'msh6',
  'msi1',
  'msi2',
  'msl1',
  'msl2',
  'msl3',
  'msmp',
  'msn',
  'msra',
  'msrb1',
  'msrb3',
  'mss51',
  'mst1',
  'mst1r',
  'mstn',
  'msto1',
  'msx1',
  'msx2',
  'mt1a',
  'mt1b',
  'mt1f',
  'mt1g',
  'mt1h',
  'mt1m',
  'mt1x',
  'mt2a',
  'mt3',
  'mt4',
  'mta1',
  'mta2',
  'mta3',
  'mtap',
  'mtarc1',
  'mtarc2',
  'mtch1',
  'mtch2',
  'mtcl1',
  'mtcp1',
  'mtdh',
  'mterf3',
  'mtf1',
  'mtf2',
  'mtfmt',
  'mtfp1',
  'mtfr1',
  'mtfr1l',
  'mtg1',
  'mthfd1',
  'mthfd1l',
  'mthfd2',
  'mthfd2l',
  'mthfr',
  'mthfs',
  'mthfsd',
  'mtif2',
  'mtln',
  'mtm1',
  'mtmr1',
  'mtmr11',
  'mtmr12',
  'mtmr14',
  'mtmr2',
  'mtmr3',
  'mtmr4',
  'mtmr6',
  'mtmr7',
  'mtmr9',
  'mtnr1a',
  'mtnr1b',
  'mto1',
  'mtor',
  'mtpn',
  'mtr',
  'mtrex',
  'mtrf1',
  'mtrf1l',
  'mtss1',
  'mtss2',
  'mttp',
  'mturn',
  'mtx1',
  'mtx2',
  'mtx3',
  'mul1',
  'mus81',
  'musk',
  'mustn1',
  'mutyh',
  'mvb12a',
  'mvb12b',
  'mvd',
  'mvk',
  'mvp',
  'mxd1',
  'mxd3',
  'mxd4',
  'mxi1',
  'mxra8',
  'myadm',
  'myadml2',
  'myb',
  'mybbp1a',
  'mybl1',
  'mybl2',
  'mybpc1',
  'mybpc2',
  'mybpc3',
  'mybph',
  'mybphl',
  'myc',
  'mycbp',
  'mycbp2',
  'mycl',
  'mycn',
  'myct1',
  'myd88',
  'mydgf',
  'myef2',
  'myf5',
  'myf6',
  'myg1',
  'myh1',
  'myh10',
  'myh11',
  'myh13',
  'myh14',
  'myh2',
  'myh3',
  'myh4',
  'myh6',
  'myh7',
  'myh7b',
  'myh8',
  'myh9',
  'myl1',
  'myl10',
  'myl12a',
  'myl12b',
  'myl2',
  'myl3',
  'myl4',
  'myl6',
  'myl6b',
  'myl7',
  'myl9',
  'mylip',
  'mylk',
  'mylk2',
  'mylk4',
  'mylpf',
  'mymk',
  'mymx',
  'mynn',
  'myo10',
  'myo15a',
  'myo18a',
  'myo19',
  'myo1a',
  'myo1b',
  'myo1c',
  'myo1d',
  'myo1e',
  'myo1f',
  'myo1g',
  'myo1h',
  'myo3b',
  'myo5a',
  'myo5b',
  'myo5c',
  'myo6',
  'myo7a',
  'myo7b',
  'myo9a',
  'myo9b',
  'myoc',
  'myocd',
  'myod1',
  'myof',
  'myog',
  'myom1',
  'myom3',
  'myorg',
  'myot',
  'myoz1',
  'myoz2',
  'myoz3',
  'mypn',
  'mypop',
  'myrf',
  'myrip',
  'mysm1',
  'myt1',
  'myt1l',
  'myzap',
  'mzb1',
  'mzf1',
  'mzt1',
  'mzt2a',
  'mzt2b',
  'n4bp1',
  'n4bp2l1',
  'n4bp3',
  'n6amt1',
  'naa10',
  'naa15',
  'naa16',
  'naa20',
  'naa25',
  'naa30',
  'naa35',
  'naa38',
  'naa40',
  'naa50',
  'naa60',
  'naa80',
  'naalad2',
  'naaladl1',
  'naaladl2',
  'nab1',
  'nab2',
  'nabp1',
  'nabp2',
  'naca2',
  'nacc1',
  'nacc2',
  'nadk',
  'nadk2',
  'nadsyn1',
  'nae1',
  'naga',
  'nagk',
  'naglu',
  'nagpa',
  'nags',
  'naif1',
  'nalcn',
  'nalf1',
  'nalf2',
  'nampt',
  'nanos1',
  'nanos2',
  'nanp',
  'nans',
  'nap1l1',
  'nap1l2',
  'nap1l3',
  'napa',
  'napb',
  'napepld',
  'napg',
  'naprt',
  'napsa',
  'nars1',
  'nars2',
  'nasp',
  'nat1',
  'nat10',
  'nat14',
  'nat2',
  'nat8l',
  'nat9',
  'natd1',
  'nav1',
  'nav2',
  'nav3',
  'naxd',
  'naxe',
  'nbas',
  'nbea',
  'nbeal1',
  'nbeal2',
  'nbl1',
  'nbr1',
  'ncald',
  'ncam1',
  'ncam2',
  'ncapd2',
  'ncaph',
  'ncaph2',
  'ncbp1',
  'ncbp2',
  'ncbp2as2',
  'ncbp3',
  'nccrp1',
  'ncdn',
  'nceh1',
  'ncf1',
  'ncf2',
  'ncf4',
  'nck1',
  'nck2',
  'nckap1',
  'nckap1l',
  'nckap5',
  'nckap5l',
  'nckipsd',
  'ncl',
  'ncln',
  'ncmap',
  'ncoa1',
  'ncoa2',
  'ncoa3',
  'ncoa4',
  'ncoa5',
  'ncoa6',
  'ncoa7',
  'ncor1',
  'ncor2',
  'ncs1',
  'ncstn',
  'ndc1',
  'ndc80',
  'nde1',
  'ndel1',
  'ndfip1',
  'ndfip2',
  'ndn',
  'ndnf',
  'ndor1',
  'ndp',
  'ndrg1',
  'ndrg2',
  'ndrg3',
  'ndrg4',
  'ndst1',
  'ndst2',
  'ndst3',
  'ndst4',
  'ndufa2',
  'ndufa3',
  'ndufa4',
  'ndufa4l2',
  'ndufa5',
  'ndufa6',
  'ndufa7',
  'ndufa8',
  'ndufab1',
  'ndufaf2',
  'ndufaf3',
  'ndufaf6',
  'ndufaf7',
  'ndufaf8',
  'ndufb11',
  'ndufb3',
  'ndufb4',
  'ndufb7',
  'ndufb8',
  'ndufb9',
  'ndufs1',
  'ndufs2',
  'ndufs3',
  'ndufs4',
  'ndufs5',
  'ndufs7',
  'ndufs8',
  'ndufv1',
  'ndufv2',
  'neb',
  'necab1',
  'necab2',
  'necab3',
  'necap1',
  'necap2',
  'nectin1',
  'nectin2',
  'nectin3',
  'nectin4',
  'nedd1',
  'nedd4',
  'nedd4l',
  'nedd8',
  'nedd9',
  'nefl',
  'negr1',
  'neil1',
  'nek10',
  'nek2',
  'nek6',
  'nek7',
  'nek8',
  'nek9',
  'nelfa',
  'nelfb',
  'nelfcd',
  'nelfe',
  'nell1',
  'nell2',
  'nemf',
  'nemp1',
  'nenf',
  'neo1',
  'neto1',
  'neto2',
  'neu1',
  'neu3',
  'neu4',
  'neurl1',
  'neurl1b',
  'neurl2',
  'neurl3',
  'neurl4',
  'neurod1',
  'neurod2',
  'neurod4',
  'neurod6',
  'neurog1',
  'neurog3',
  'nexmif',
  'nexn',
  'nf1',
  'nf2',
  'nfasc',
  'nfat5',
  'nfatc1',
  'nfatc2',
  'nfatc2ip',
  'nfatc3',
  'nfatc4',
  'nfe2',
  'nfe2l1',
  'nfe2l2',
  'nfia',
  'nfib',
  'nfic',
  'nfilz',
  'nfix',
  'nfkb2',
  'nfkbia',
  'nfkbib',
  'nfkbid',
  'nfkbie',
  'nfkbil1',
  'nfkbiz',
  'nfrkb',
  'nfs1',
  'nfu1',
  'nfx1',
  'nfxl1',
  'nfya',
  'nfyb',
  'nfyc',
  'ngb',
  'ngdn',
  'ngf',
  'ngfr',
  'ngly1',
  'ngrn',
  'nhej1',
  'nhlh1',
  'nhlh2',
  'nhlrc1',
  'nhlrc2',
  'nhlrc3',
  'nhp2',
  'nhs',
  'nhsl1',
  'nhsl2',
  'niban2',
  'nicn1',
  'nid1',
  'nid2',
  'nif3l1',
  'nim1k',
  'nin',
  'ninj1',
  'ninj2',
  'nip7',
  'nipa1',
  'nipa2',
  'nipal2',
  'nipal3',
  'nipal4',
  'nipbl',
  'nipsnap1',
  'nipsnap2',
  'nipsnap3a',
  'nipsnap3b',
  'nisch',
  'nit1',
  'nit2',
  'nkain1',
  'nkain2',
  'nkain3',
  'nkap',
  'nkapd1',
  'nkapl',
  'nkd1',
  'nkg7',
  'nkiras1',
  'nkiras2',
  'nkpd1',
  'nktr',
  'nkx1-1',
  'nkx2-1',
  'nkx2-2',
  'nkx2-3',
  'nkx2-4',
  'nkx2-5',
  'nkx2-8',
  'nkx3-2',
  'nkx6-1',
  'nkx6-2',
  'nkx6-3',
  'nle1',
  'nlgn1',
  'nlgn2',
  'nlgn3',
  'nlgn4x',
  'nlk',
  'nln',
  'nlrp3',
  'nlrx1',
  'nmb',
  'nmbr',
  'nmd3',
  'nme1',
  'nme2',
  'nme3',
  'nme5',
  'nme6',
  'nme7',
  'nmnat1',
  'nmnat2',
  'nmnat3',
  'nmral1',
  'nmrk2',
  'nmt1',
  'nmt2',
  'nmur1',
  'nmur2',
  'nnat',
  'nnt',
  'noa1',
  'nob1',
  'noc3l',
  'noc4l',
  'nod1',
  'nod2',
  'nodal',
  'nog',
  'nol10',
  'nol12',
  'nol3',
  'nol4',
  'nol4l',
  'nol6',
  'nolc1',
  'nomo1',
  'nomo2',
  'nomo3',
  'nono',
  'nop10',
  'nop16',
  'nop2',
  'nop53',
  'nop56',
  'nop58',
  'nop9',
  'nopchap1',
  'nos1',
  'nos1ap',
  'nos2',
  'nos3',
  'nosip',
  'notch1',
  'notch2',
  'notch2nla',
  'notch2nlb',
  'notch2nlc',
  'notch2nlr',
  'notch3',
  'notch4',
  'notum',
  'nova1',
  'nova2',
  'nox1',
  'nox3',
  'nox4',
  'npas1',
  'npas2',
  'npas3',
  'npas4',
  'npbwr1',
  'npbwr2',
  'npc1',
  'npc1l1',
  'npc2',
  'npdc1',
  'npepl1',
  'npepps',
  'npffr1',
  'nphp3',
  'nphp4',
  'nphs1',
  'nphs2',
  'npl',
  'nploc4',
  'npm1',
  'npm3',
  'nppa',
  'nppc',
  'npr1',
  'npr2',
  'npr3',
  'nprl2',
  'nprl3',
  'nptn',
  'nptx1',
  'nptx2',
  'npy',
  'npy4r',
  'npy4r2',
  'nqo1',
  'nr0b1',
  'nr0b2',
  'nr1d1',
  'nr1d2',
  'nr1h2',
  'nr1h3',
  'nr1h4',
  'nr1i2',
  'nr1i3',
  'nr2c1',
  'nr2c2',
  'nr2c2ap',
  'nr2e1',
  'nr2e3',
  'nr2f1',
  'nr2f2',
  'nr2f6',
  'nr3c1',
  'nr3c2',
  'nr4a1',
  'nr4a2',
  'nr4a3',
  'nr5a1',
  'nr5a2',
  'nr6a1',
  'nrap',
  'nrarp',
  'nras',
  'nrbf2',
  'nrbp1',
  'nrbp2',
  'nrcam',
  'nrdc',
  'nrep',
  'nrf1',
  'nrg1',
  'nrg2',
  'nrg3',
  'nrgn',
  'nrip1',
  'nrip2',
  'nrip3',
  'nrl',
  'nrm',
  'nrn1',
  'nrn1l',
  'nrp1',
  'nrp2',
  'nrros',
  'nrsn1',
  'nrsn2',
  'nrtn',
  'nrxn1',
  'nrxn2',
  'nrxn3',
  'nsa2',
  'nsd1',
  'nsd2',
  'nsd3',
  'nsdhl',
  'nsf',
  'nsfl1c',
  'nsg1',
  'nsg2',
  'nsl1',
  'nsmaf',
  'nsmce1',
  'nsmce2',
  'nsmce4a',
  'nsmf',
  'nsun3',
  'nsun4',
  'nsun5',
  'nsun6',
  'nt5c',
  'nt5c1a',
  'nt5c2',
  'nt5c3b',
  'nt5dc1',
  'nt5dc2',
  'nt5dc3',
  'nt5e',
  'nt5m',
  'ntan1',
  'ntaq1',
  'ntf3',
  'ntf4',
  'nthl1',
  'ntm',
  'ntmt1',
  'ntmt2',
  'ntn1',
  'ntn3',
  'ntn5',
  'ntng1',
  'ntng2',
  'ntpcr',
  'ntrk1',
  'ntrk2',
  'ntrk3',
  'ntsr1',
  'nuak1',
  'nuak2',
  'nub1',
  'nubp1',
  'nubp2',
  'nubpl',
  'nucb1',
  'nucb2',
  'nucks1',
  'nudc',
  'nudcd1',
  'nudcd2',
  'nudcd3',
  'nudt1',
  'nudt10',
  'nudt11',
  'nudt12',
  'nudt13',
  'nudt14',
  'nudt15',
  'nudt16',
  'nudt16l1',
  'nudt17',
  'nudt18',
  'nudt2',
  'nudt21',
  'nudt22',
  'nudt3',
  'nudt4',
  'nudt4b',
  'nudt6',
  'nudt8',
  'nudt9',
  'nuf2',
  'nuggc',
  'numa1',
  'numb',
  'numbl',
  'nup107',
  'nup133',
  'nup153',
  'nup155',
  'nup160',
  'nup188',
  'nup205',
  'nup210',
  'nup210l',
  'nup214',
  'nup35',
  'nup37',
  'nup42',
  'nup43',
  'nup50',
  'nup54',
  'nup58',
  'nup85',
  'nup88',
  'nup93',
  'nup98',
  'nupr1',
  'nus1',
  'nutf2',
  'nwd1',
  'nxf1',
  'nxn',
  'nxnl1',
  'nxnl2',
  'nxpe2',
  'nxpe3',
  'nxpe4',
  'nxph1',
  'nxph2',
  'nxph3',
  'nxph4',
  'nxt1',
  'nxt2',
  'nyap1',
  'nyap2',
  'nynrin',
  'nyx',
  'oaf',
  'oard1',
  'oat',
  'oaz2',
  'oaz3',
  'obi1',
  'obscn',
  'obsl1',
  'ociad1',
  'ocln',
  'ocm',
  'ocm2',
  'ocrl',
  'odad3',
  'odc1',
  'odf1',
  'odf2',
  'odf3',
  'odf3b',
  'odf3l1',
  'odf3l2',
  'odr4',
  'oga',
  'ogdh',
  'ogdhl',
  'ogfod1',
  'ogfod2',
  'ogfod3',
  'ogfrl1',
  'ogg1',
  'ogn',
  'ogt',
  'oit3',
  'ola1',
  'olfm1',
  'olfm2',
  'olfm3',
  'olfml1',
  'olfml2a',
  'olfml2b',
  'olfml3',
  'olig1',
  'olig2',
  'olig3',
  'omg',
  'omp',
  'onecut1',
  'onecut2',
  'opa1',
  'opa3',
  'opcml',
  'ophn1',
  'oplah',
  'opn1lw',
  'opn1mw',
  'opn1mw2',
  'opn1mw3',
  'opn1sw',
  'opn3',
  'opn4',
  'opn5',
  'oprd1',
  'oprk1',
  'oprl1',
  'oprm1',
  'optn',
  'or10a2',
  'or10a3',
  'or10a4',
  'or10a5',
  'or10a7',
  'or10g2',
  'or10g3',
  'or10g4',
  'or10g7',
  'or10h2',
  'or10j1',
  'or10j5',
  'or10k1',
  'or10k2',
  'or10q1',
  'or10r2',
  'or10t2',
  'or10w1',
  'or10z1',
  'or13c2',
  'or13c3',
  'or13c4',
  'or13c5',
  'or13c8',
  'or13c9',
  'or13d1',
  'or13f1',
  'or13j1',
  'or1d2',
  'or1e1',
  'or1e2',
  'or1f1',
  'or1g1',
  'or1i1',
  'or1j1',
  'or1j2',
  'or1j4',
  'or1l8',
  'or1m1',
  'or1n1',
  'or1s1',
  'or1s2',
  'or2a1',
  'or2a12',
  'or2a14',
  'or2a2',
  'or2a25',
  'or2a4',
  'or2a42',
  'or2a5',
  'or2a7',
  'or2ap1',
  'or2at4',
  'or2b11',
  'or2b3',
  'or2b6',
  'or2c1',
  'or2c3',
  'or2d2',
  'or2d3',
  'or2h1',
  'or2h2',
  'or2k2',
  'or2l13',
  'or2m4',
  'or2s2',
  'or2t1',
  'or2t10',
  'or2t11',
  'or2t2',
  'or2t27',
  'or2t29',
  'or2t35',
  'or2t4',
  'or2t5',
  'or2t6',
  'or2t8',
  'or4a47',
  'or4b1',
  'or4c12',
  'or4c13',
  'or4c15',
  'or4c3',
  'or4c46',
  'or4c6',
  'or4d1',
  'or4d10',
  'or4d11',
  'or4d2',
  'or4d5',
  'or4d6',
  'or4d9',
  'or4e1',
  'or4e2',
  'or4f15',
  'or4f16',
  'or4f17',
  'or4f21',
  'or4f29',
  'or4f3',
  'or4f5',
  'or4f6',
  'or4k1',
  'or4k13',
  'or4k14',
  'or4k15',
  'or4k17',
  'or4k2',
  'or4k5',
  'or4l1',
  'or4n2',
  'or4q3',
  'or4s1',
  'or4s2',
  'or4x1',
  'or4x2',
  'or51a2',
  'or51a4',
  'or51a7',
  'or51b4',
  'or51b5',
  'or51b6',
  'or51d1',
  'or51e1',
  'or51e2',
  'or51f1',
  'or51f2',
  'or51g1',
  'or51g2',
  'or51i2',
  'or51l1',
  'or51t1',
  'or52b2',
  'or52d1',
  'or52e2',
  'or52e4',
  'or52e5',
  'or52e6',
  'or52e8',
  'or52h1',
  'or52k2',
  'or56a1',
  'or56a3',
  'or56a4',
  'or56a5',
  'or56b4',
  'or5a1',
  'or5a2',
  'or5an1',
  'or5ap2',
  'or5ar1',
  'or5b12',
  'or5b17',
  'or5b2',
  'or5b21',
  'or5b3',
  'or5bs1p',
  'or5d14',
  'or5d16',
  'or5d18',
  'or5k1',
  'or5l1',
  'or5l2',
  'or6a2',
  'or6b1',
  'or6c1',
  'or6c2',
  'or6c3',
  'or6c4',
  'or6c6',
  'or6c65',
  'or6c68',
  'or6c70',
  'or6c74',
  'or6c75',
  'or6c76',
  'or6j1',
  'or6k2',
  'or6k3',
  'or6k6',
  'or6n1',
  'or6n2',
  'or6p1',
  'or6q1',
  'or6v1',
  'or6y1',
  'or7a10',
  'or7a17',
  'or7a5',
  'or7c1',
  'or7c2',
  'or7d2',
  'or7d4',
  'or7e24',
  'or7g1',
  'or7g2',
  'or7g3',
  'or8a1',
  'or8b12',
  'or8b2',
  'or8b3',
  'or8b4',
  'or8b8',
  'or8d1',
  'or8d2',
  'or8d4',
  'or8g1',
  'or8g5',
  'or8u1',
  'or8u3',
  'or9a2',
  'or9a4',
  'or9g4',
  'or9i1',
  'or9k2',
  'or9q1',
  'or9q2',
  'orai1',
  'orai2',
  'orai3',
  'orc2',
  'orc3',
  'orc4',
  'orc5',
  'orc6',
  'ormdl1',
  'ormdl2',
  'ormdl3',
  'os9',
  'osbp',
  'osbp2',
  'osbpl10',
  'osbpl11',
  'osbpl1a',
  'osbpl3',
  'osbpl5',
  'osbpl6',
  'osbpl7',
  'osbpl8',
  'osbpl9',
  'oscar',
  'oscp1',
  'oser1',
  'osgep',
  'osgepl1',
  'osgin1',
  'osgin2',
  'osr1',
  'osr2',
  'ost4',
  'ostc',
  'ostf1',
  'ostm1',
  'ostn',
  'otc',
  'otoa',
  'otof',
  'otog',
  'otogl',
  'otop2',
  'otop3',
  'otor',
  'otp',
  'otub1',
  'otub2',
  'otud1',
  'otud3',
  'otud4',
  'otud5',
  'otud6b',
  'otud7a',
  'otud7b',
  'otulin',
  'otulinl',
  'otx1',
  'otx2',
  'ovca2',
  'ovol1',
  'ovol2',
  'ovol3',
  'oxct1',
  'oxgr1',
  'oxnad1',
  'oxr1',
  'oxsm',
  'oxsr1',
  'oxt',
  'oxtr',
  'p2rx1',
  'p2rx2',
  'p2rx3',
  'p2rx4',
  'p2rx6',
  'p2rx7',
  'p2ry1',
  'p2ry10',
  'p2ry12',
  'p2ry13',
  'p2ry14',
  'p2ry2',
  'p2ry4',
  'p2ry6',
  'p3h1',
  'p3h2',
  'p3h3',
  'p3h4',
  'p3r3urf',
  'p4ha1',
  'p4ha2',
  'p4ha3',
  'p4hb',
  'p4htm',
  'pa2g4',
  'paaf1',
  'pabir1',
  'pabir2',
  'pabpc1',
  'pabpc1l',
  'pabpc1l2a',
  'pabpc1l2b',
  'pabpc3',
  'pabpc4',
  'pabpc4l',
  'pabpc5',
  'pabpn1',
  'pacc1',
  'pacrg',
  'pacs1',
  'pacs2',
  'pacsin1',
  'pacsin2',
  'pacsin3',
  'padi1',
  'padi2',
  'padi3',
  'padi4',
  'paf1',
  'pafah1b1',
  'pafah1b2',
  'pafah1b3',
  'pafah2',
  'pag1',
  'pagr1',
  'pah',
  'paics',
  'paip1',
  'paip2',
  'paip2b',
  'pak1',
  'pak2',
  'pak3',
  'pak4',
  'pak5',
  'pak6',
  'pald1',
  'palm',
  'palm2akap2',
  'pals1',
  'pals2',
  'pam',
  'pam16',
  'pamr1',
  'pan2',
  'pan3',
  'pank1',
  'pank2',
  'pank3',
  'pank4',
  'panx1',
  'panx2',
  'panx3',
  'paox',
  'papln',
  'papola',
  'papolb',
  'papolg',
  'pappa',
  'pappa2',
  'papss1',
  'papss2',
  'paqr4',
  'paqr5',
  'paqr6',
  'paqr7',
  'paqr8',
  'paqr9',
  'pard3',
  'pard3b',
  'pard6a',
  'pard6b',
  'parg',
  'park7',
  'parl',
  'parn',
  'parp1',
  'parp10',
  'parp11',
  'parp12',
  'parp16',
  'parp2',
  'parp3',
  'parp6',
  'parp8',
  'pars2',
  'parva',
  'parvb',
  'patl1',
  'patz1',
  'pawr',
  'pax1',
  'pax2',
  'pax3',
  'pax4',
  'pax5',
  'pax6',
  'pax7',
  'pax8',
  'pax9',
  'paxbp1',
  'paxx',
  'pbk',
  'pbld',
  'pbrm1',
  'pbx1',
  'pbx2',
  'pbx3',
  'pbxip1',
  'pc',
  'pcbd1',
  'pcbd2',
  'pcbp2',
  'pcbp3',
  'pcbp4',
  'pcca',
  'pccb',
  'pcdh1',
  'pcdh10',
  'pcdh11x',
  'pcdh12',
  'pcdh15',
  'pcdh17',
  'pcdh18',
  'pcdh19',
  'pcdh20',
  'pcdh7',
  'pcdh9',
  'pcdhac1',
  'pcdhac2',
  'pcdhb1',
  'pcdhga10',
  'pcdhga11',
  'pcdhga12',
  'pcdhga3',
  'pcdhga4',
  'pcdhga5',
  'pcdhga6',
  'pcdhga7',
  'pcdhga8',
  'pcdhga9',
  'pcdhgb1',
  'pcdhgb2',
  'pcdhgb3',
  'pcdhgb4',
  'pcdhgb5',
  'pcdhgb6',
  'pcdhgb7',
  'pcdhgc3',
  'pcdhgc4',
  'pcdhgc5',
  'pced1a',
  'pcf11',
  'pcgf1',
  'pcgf2',
  'pcgf3',
  'pcgf5',
  'pcgf6',
  'pcif1',
  'pck1',
  'pck2',
  'pclaf',
  'pclo',
  'pcm1',
  'pcmt1',
  'pcmtd1',
  'pcmtd2',
  'pcna',
  'pcnp',
  'pcnx1',
  'pcnx2',
  'pcnx3',
  'pcnx4',
  'pcolce',
  'pcolce2',
  'pcp2',
  'pcp4',
  'pcp4l1',
  'pcsk1',
  'pcsk1n',
  'pcsk2',
  'pcsk5',
  'pcsk7',
  'pcsk9',
  'pcyox1',
  'pcyox1l',
  'pcyt1a',
  'pcyt1b',
  'pcyt2',
  'pdap1',
  'pdc',
  'pdcd10',
  'pdcd11',
  'pdcd2',
  'pdcd2l',
  'pdcd4',
  'pdcd5',
  'pdcd6',
  'pdcd6ip',
  'pdcd7',
  'pdcl',
  'pdcl2',
  'pdcl3',
  'pde11a',
  'pde1a',
  'pde1b',
  'pde1c',
  'pde2a',
  'pde3a',
  'pde3b',
  'pde4a',
  'pde4b',
  'pde4c',
  'pde4d',
  'pde4dip',
  'pde5a',
  'pde6a',
  'pde6b',
  'pde6c',
  'pde6d',
  'pde6g',
  'pde6h',
  'pde7a',
  'pde7b',
  'pde8a',
  'pde8b',
  'pdf',
  'pdgfa',
  'pdgfb',
  'pdgfd',
  'pdgfra',
  'pdgfrb',
  'pdgfrl',
  'pdha1',
  'pdha2',
  'pdhb',
  'pdia2',
  'pdia3',
  'pdia4',
  'pdia5',
  'pdia6',
  'pdik1l',
  'pdk1',
  'pdk2',
  'pdk3',
  'pdk4',
  'pdlim1',
  'pdlim2',
  'pdlim3',
  'pdlim4',
  'pdlim5',
  'pdlim7',
  'pdp1',
  'pdp2',
  'pdpk1',
  'pdpr',
  'pdrg1',
  'pds5a',
  'pds5b',
  'pdss1',
  'pdss2',
  'pdx1',
  'pdxdc1',
  'pdxk',
  'pdxp',
  'pdzd11',
  'pdzd3',
  'pdzd4',
  'pdzd7',
  'pdzd8',
  'pdzk1',
  'pdzk1ip1',
  'pdzrn3',
  'pdzrn4',
  'pea15',
  'peak1',
  'pear1',
  'pebp1',
  'peds1',
  'pef1',
  'peli1',
  'peli2',
  'peli3',
  'pelo',
  'pelp1',
  'pemt',
  'penk',
  'pepd',
  'per1',
  'per2',
  'percc1',
  'perp',
  'pes1',
  'pet100',
  'pet117',
  'pex1',
  'pex10',
  'pex11a',
  'pex11b',
  'pex11g',
  'pex12',
  'pex13',
  'pex14',
  'pex16',
  'pex19',
  'pex2',
  'pex26',
  'pex3',
  'pex5',
  'pex5l',
  'pex6',
  'pex7',
  'pfas',
  'pfdn2',
  'pfdn4',
  'pfdn5',
  'pfdn6',
  'pfkfb1',
  'pfkfb2',
  'pfkfb3',
  'pfkfb4',
  'pfkl',
  'pfkm',
  'pfn1',
  'pfn2',
  'pfn3',
  'pfn4',
  'pga3',
  'pga4',
  'pga5',
  'pgam1',
  'pgam2',
  'pgam4',
  'pgam5',
  'pgap1',
  'pgap2',
  'pgap3',
  'pgap4',
  'pgap6',
  'pgbd5',
  'pgd',
  'pgf',
  'pgghg',
  'pggt1b',
  'pgk1',
  'pgk2',
  'pgls',
  'pglyrp1',
  'pglyrp3',
  'pglyrp4',
  'pgm1',
  'pgm2',
  'pgm2l1',
  'pgm5',
  'pgp',
  'pgpep1',
  'pgr',
  'pgrmc1',
  'pgrmc2',
  'pgs1',
  'phactr1',
  'phactr2',
  'phactr3',
  'phaf1',
  'phax',
  'phb1',
  'phb2',
  'phc1',
  'phc2',
  'phc3',
  'pheta2',
  'phex',
  'phf1',
  'phf10',
  'phf12',
  'phf13',
  'phf14',
  'phf19',
  'phf2',
  'phf20',
  'phf20l1',
  'phf21a',
  'phf23',
  'phf24',
  'phf5a',
  'phf6',
  'phf7',
  'phf8',
  'phgdh',
  'phip',
  'phka1',
  'phka2',
  'phkb',
  'phkg1',
  'phkg2',
  'phlda1',
  'phlda3',
  'phldb1',
  'phldb2',
  'phldb3',
  'phlpp1',
  'phlpp2',
  'phospho1',
  'phospho2',
  'phox2a',
  'phox2b',
  'phpt1',
  'phtf1',
  'phtf2',
  'phyhd1',
  'phyhip',
  'phyhipl',
  'phykpl',
  'pi15',
  'pi16',
  'pi4k2a',
  'pi4k2b',
  'pi4ka',
  'pi4kb',
  'pianp',
  'pias1',
  'pias2',
  'pias3',
  'pias4',
  'pibf1',
  'picalm',
  'pick1',
  'pid1',
  'pidd1',
  'pierce1',
  'pierce2',
  'piezo2',
  'pif1',
  'piga',
  'pigb',
  'pigc',
  'pigf',
  'pigg',
  'pigh',
  'pigk',
  'pigl',
  'pigm',
  'pign',
  'pigo',
  'pigp',
  'pigq',
  'pigs',
  'pigt',
  'pigu',
  'pigv',
  'pigw',
  'pigx',
  'pigy',
  'pigz',
  'pih1d1',
  'pih1d2',
  'pik3ap1',
  'pik3c2a',
  'pik3c2b',
  'pik3c3',
  'pik3ca',
  'pik3cb',
  'pik3cd',
  'pik3cg',
  'pik3ip1',
  'pik3r1',
  'pik3r2',
  'pik3r3',
  'pik3r4',
  'pik3r5',
  'pik3r6',
  'pikfyve',
  'pim1',
  'pim2',
  'pim3',
  'pimreg',
  'pin1',
  'pin4',
  'pink1',
  'pip4k2a',
  'pip4k2b',
  'pip4k2c',
  'pip4p1',
  'pip4p2',
  'pip5k1a',
  'pip5k1b',
  'pip5k1c',
  'pip5kl1',
  'pipox',
  'pir',
  'pirt',
  'pisd',
  'pithd1',
  'pitpna',
  'pitpnb',
  'pitpnc1',
  'pitpnm1',
  'pitpnm2',
  'pitpnm3',
  'pitx1',
  'pitx2',
  'pitx3',
  'piwil2',
  'pja1',
  'pja2',
  'pjvk',
  'pkd1',
  'pkd2l1',
  'pkd2l2',
  'pkdcc',
  'pkhd1',
  'pkhd1l1',
  'pkia',
  'pkig',
  'pklr',
  'pkm',
  'pkmyt1',
  'pkn1',
  'pkn2',
  'pkn3',
  'pknox1',
  'pknox2',
  'pkp1',
  'pkp2',
  'pkp3',
  'pkp4',
  'pla2g12b',
  'pla2g15',
  'pla2g2e',
  'pla2g2f',
  'pla2g3',
  'pla2g4a',
  'pla2g4b',
  'pla2g4d',
  'pla2g4e',
  'pla2g4f',
  'pla2g6',
  'pla2r1',
  'plaa',
  'plaat1',
  'plaat3',
  'plac8',
  'plac9',
  'plag1',
  'plagl1',
  'plagl2',
  'plau',
  'plbd1',
  'plbd2',
  'plcb1',
  'plcb2',
  'plcb3',
  'plcb4',
  'plcd1',
  'plcd3',
  'plcd4',
  'plce1',
  'plcg1',
  'plcg2',
  'plch1',
  'plch2',
  'plcl1',
  'plcl2',
  'plcxd2',
  'plcxd3',
  'pld1',
  'pld2',
  'pld3',
  'pld5',
  'pld6',
  'plec',
  'plek',
  'plek2',
  'plekha1',
  'plekha2',
  'plekha3',
  'plekha4',
  'plekha5',
  'plekha6',
  'plekha7',
  'plekha8',
  'plekhb1',
  'plekhb2',
  'plekhd1',
  'plekhf2',
  'plekhg1',
  'plekhg3',
  'plekhg4',
  'plekhg5',
  'plekhg6',
  'plekhh1',
  'plekhh2',
  'plekhh3',
  'plekhj1',
  'plekhm1',
  'plekhm2',
  'plekhm3',
  'plekho1',
  'plekho2',
  'plgrkt',
  'plin1',
  'plin2',
  'plin3',
  'plk1',
  'plk2',
  'plk3',
  'pllp',
  'pln',
  'plod1',
  'plod2',
  'plod3',
  'plp1',
  'plp2',
  'plpbp',
  'plpp1',
  'plpp2',
  'plpp3',
  'plpp4',
  'plpp6',
  'plpp7',
  'plppr1',
  'plppr2',
  'plppr3',
  'plppr4',
  'plppr5',
  'plrg1',
  'pls1',
  'pls3',
  'plscr3',
  'plscr4',
  'plscr5',
  'pltp',
  'plxdc1',
  'plxdc2',
  'plxna1',
  'plxna2',
  'plxna4',
  'plxnb1',
  'plxnb2',
  'plxnb3',
  'plxnc1',
  'plxnd1',
  'pm20d2',
  'pmch',
  'pmel',
  'pmepa1',
  'pmf1',
  'pml',
  'pmm1',
  'pmm2',
  'pmp22',
  'pmpca',
  'pmpcb',
  'pmvk',
  'pnisr',
  'pnkd',
  'pnkp',
  'pnlip',
  'pnliprp1',
  'pnma1',
  'pnma2',
  'pnma5',
  'pnmt',
  'pnn',
  'pno1',
  'pnoc',
  'pnp',
  'pnpla2',
  'pnpla4',
  'pnpla6',
  'pnpla8',
  'pnpo',
  'pnpt1',
  'pnrc1',
  'pnrc2',
  'poc1a',
  'poc1b',
  'poc5',
  'podn',
  'podnl1',
  'pof1b',
  'pofut1',
  'pogk',
  'poglut1',
  'poglut2',
  'poglut3',
  'pogz',
  'pola1',
  'pola2',
  'pold1',
  'pold2',
  'pold3',
  'pold4',
  'poldip2',
  'poldip3',
  'pole',
  'pole2',
  'pole3',
  'pole4',
  'polg',
  'polh',
  'poli',
  'poll',
  'polm',
  'polr1a',
  'polr1b',
  'polr1c',
  'polr1d',
  'polr1e',
  'polr1h',
  'polr2a',
  'polr2b',
  'polr2c',
  'polr2d',
  'polr2e',
  'polr2f',
  'polr2g',
  'polr2h',
  'polr2i',
  'polr2j',
  'polr2j2',
  'polr2j3',
  'polr2k',
  'polr2l',
  'polr2m',
  'polr3a',
  'polr3b',
  'polr3c',
  'polr3d',
  'polr3e',
  'polr3f',
  'polr3gl',
  'polr3h',
  'polr3k',
  'pomc',
  'pomgnt1',
  'pomgnt2',
  'pomp',
  'pomt1',
  'pomt2',
  'pon1',
  'pon2',
  'pop1',
  'pop5',
  'pop7',
  'popdc2',
  'popdc3',
  'por',
  'porcn',
  'postn',
  'pot1',
  'pou1f1',
  'pou2af1',
  'pou2f1',
  'pou2f2',
  'pou2f3',
  'pou3f1',
  'pou3f2',
  'pou3f4',
  'pou4f2',
  'pou4f3',
  'pou5f1',
  'pou5f1b',
  'pou6f1',
  'pou6f2',
  'ppa1',
  'ppara',
  'ppard',
  'pparg',
  'ppargc1a',
  'ppargc1b',
  'ppat',
  'ppcdc',
  'ppcs',
  'ppdpf',
  'ppfia1',
  'ppfia2',
  'ppfia3',
  'ppfia4',
  'ppfibp1',
  'ppfibp2',
  'pphln1',
  'ppia',
  'ppial4a',
  'ppial4c',
  'ppial4d',
  'ppial4e',
  'ppial4f',
  'ppial4g',
  'ppial4h',
  'ppib',
  'ppic',
  'ppid',
  'ppie',
  'ppif',
  'ppig',
  'ppih',
  'ppil1',
  'ppil2',
  'ppil3',
  'ppil4',
  'ppip5k1',
  'ppip5k2',
  'ppl',
  'ppm1a',
  'ppm1b',
  'ppm1d',
  'ppm1e',
  'ppm1f',
  'ppm1g',
  'ppm1h',
  'ppm1j',
  'ppm1k',
  'ppm1l',
  'ppm1m',
  'ppm1n',
  'ppme1',
  'ppox',
  'ppp1ca',
  'ppp1cb',
  'ppp1cc',
  'ppp1r10',
  'ppp1r11',
  'ppp1r12a',
  'ppp1r12b',
  'ppp1r13b',
  'ppp1r13l',
  'ppp1r14a',
  'ppp1r14b',
  'ppp1r14c',
  'ppp1r14d',
  'ppp1r16a',
  'ppp1r16b',
  'ppp1r17',
  'ppp1r18',
  'ppp1r1a',
  'ppp1r1b',
  'ppp1r1c',
  'ppp1r21',
  'ppp1r27',
  'ppp1r32',
  'ppp1r35',
  'ppp1r37',
  'ppp1r3c',
  'ppp1r3d',
  'ppp1r3e',
  'ppp1r3f',
  'ppp1r3g',
  'ppp1r42',
  'ppp1r7',
  'ppp1r8',
  'ppp1r9b',
  'ppp2ca',
  'ppp2cb',
  'ppp2r1a',
  'ppp2r1b',
  'ppp2r2a',
  'ppp2r2b',
  'ppp2r2c',
  'ppp2r2d',
  'ppp2r3a',
  'ppp2r3c',
  'ppp2r5a',
  'ppp2r5b',
  'ppp2r5c',
  'ppp2r5d',
  'ppp2r5e',
  'ppp3ca',
  'ppp3cb',
  'ppp3cc',
  'ppp3r1',
  'ppp3r2',
  'ppp4c',
  'ppp4r1',
  'ppp4r2',
  'ppp4r3a',
  'ppp4r3b',
  'ppp4r4',
  'ppp5c',
  'ppp6c',
  'ppp6r1',
  'ppp6r2',
  'ppp6r3',
  'pprc1',
  'ppt1',
  'ppt2',
  'pptc7',
  'ppwd1',
  'ppy',
  'pqbp1',
  'pradc1',
  'praf2',
  'prag1',
  'prc1',
  'prcc',
  'prcd',
  'prcp',
  'prdm1',
  'prdm10',
  'prdm11',
  'prdm13',
  'prdm14',
  'prdm15',
  'prdm16',
  'prdm2',
  'prdm4',
  'prdm5',
  'prdm6',
  'prdm9',
  'prdx1',
  'prdx2',
  'prdx3',
  'prdx4',
  'prdx6',
  'preb',
  'prelid1',
  'prelid2',
  'prelid3a',
  'prelid3b',
  'prelp',
  'prep',
  'prepl',
  'prex1',
  'prex2',
  'prf1',
  'prickle1',
  'prickle2',
  'prickle3',
  'prickle4',
  'prim1',
  'prkaa1',
  'prkaa2',
  'prkab1',
  'prkab2',
  'prkaca',
  'prkacb',
  'prkag1',
  'prkag2',
  'prkag3',
  'prkar1a',
  'prkar1b',
  'prkar2a',
  'prkar2b',
  'prkca',
  'prkcb',
  'prkcd',
  'prkce',
  'prkcg',
  'prkch',
  'prkci',
  'prkcq',
  'prkcsh',
  'prkcz',
  'prkd1',
  'prkd2',
  'prkd3',
  'prkg1',
  'prkg2',
  'prkn',
  'prkra',
  'prkrip1',
  'prlh',
  'prlhr',
  'prmt1',
  'prmt2',
  'prmt3',
  'prmt5',
  'prmt6',
  'prmt7',
  'prmt8',
  'prmt9',
  'prnp',
  'proc',
  'procr',
  'prodh',
  'prodh2',
  'prok1',
  'prok2',
  'prokr1',
  'prokr2',
  'prom2',
  'prox1',
  'prpf18',
  'prpf19',
  'prpf3',
  'prpf31',
  'prpf38a',
  'prpf38b',
  'prpf39',
  'prpf4',
  'prpf40a',
  'prpf40b',
  'prpf4b',
  'prpf6',
  'prpf8',
  'prph',
  'prph2',
  'prps1',
  'prps1l1',
  'prps2',
  'prpsap1',
  'prpsap2',
  'prr11',
  'prr12',
  'prr13',
  'prr14',
  'prr15',
  'prr15l',
  'prr16',
  'prr19',
  'prr3',
  'prr35',
  'prr5',
  'prr5l',
  'prr7',
  'prr9',
  'prrc1',
  'prrc2a',
  'prrc2b',
  'prrc2c',
  'prrg1',
  'prrg2',
  'prrg3',
  'prrt1',
  'prrt2',
  'prrt3',
  'prrt4',
  'prrx1',
  'prrx2',
  'prss1',
  'prss16',
  'prss2',
  'prss22',
  'prss23',
  'prss27',
  'prss3',
  'prss36',
  'prss37',
  'prss53',
  'prss56',
  'prss57',
  'prss8',
  'prtfdc1',
  'prtg',
  'prune1',
  'prxl2c',
  'psap',
  'psat1',
  'psd',
  'psd2',
  'psd3',
  'psen1',
  'psen2',
  'psenen',
  'psip1',
  'pskh1',
  'pskh2',
  'psma1',
  'psma2',
  'psma3',
  'psma4',
  'psma5',
  'psma6',
  'psma7',
  'psma8',
  'psmb1',
  'psmb10',
  'psmb11',
  'psmb2',
  'psmb3',
  'psmb4',
  'psmb5',
  'psmb6',
  'psmb7',
  'psmb8',
  'psmb9',
  'psmc1',
  'psmc2',
  'psmc3',
  'psmc3ip',
  'psmc4',
  'psmc5',
  'psmc6',
  'psmd1',
  'psmd10',
  'psmd11',
  'psmd12',
  'psmd13',
  'psmd14',
  'psmd2',
  'psmd3',
  'psmd4',
  'psmd5',
  'psmd6',
  'psmd7',
  'psmd8',
  'psmd9',
  'psme1',
  'psme2',
  'psme3',
  'psme3ip1',
  'psme4',
  'psmf1',
  'psmg1',
  'psmg2',
  'psmg3',
  'psmg4',
  'psors1c2',
  'pspc1',
  'psph',
  'psrc1',
  'pstk',
  'pstpip1',
  'pstpip2',
  'ptafr',
  'ptar1',
  'ptbp1',
  'ptbp2',
  'ptbp3',
  'ptch1',
  'ptch2',
  'ptchd1',
  'ptchd4',
  'ptdss1',
  'ptdss2',
  'pten',
  'pter',
  'ptf1a',
  'ptgdr',
  'ptgdr2',
  'ptger1',
  'ptger2',
  'ptger3',
  'ptger4',
  'ptges',
  'ptges2',
  'ptges3',
  'ptges3l',
  'ptgfr',
  'ptgfrn',
  'ptgir',
  'ptgis',
  'ptgr1',
  'ptgr2',
  'ptgs1',
  'ptgs2',
  'pth1r',
  'pth2',
  'pth2r',
  'pthlh',
  'ptk2',
  'ptk2b',
  'ptk7',
  'ptma',
  'ptms',
  'ptn',
  'ptov1',
  'ptp4a1',
  'ptp4a2',
  'ptp4a3',
  'ptpa',
  'ptpdc1',
  'ptpmt1',
  'ptpn1',
  'ptpn11',
  'ptpn12',
  'ptpn13',
  'ptpn14',
  'ptpn2',
  'ptpn21',
  'ptpn23',
  'ptpn3',
  'ptpn4',
  'ptpn5',
  'ptpn6',
  'ptpn7',
  'ptpn9',
  'ptpra',
  'ptprb',
  'ptprcap',
  'ptprd',
  'ptpre',
  'ptprf',
  'ptprg',
  'ptprk',
  'ptprm',
  'ptprn',
  'ptpro',
  'ptprq',
  'ptprr',
  'ptprs',
  'ptprt',
  'ptpru',
  'ptprz1',
  'ptrh1',
  'ptrh2',
  'ptrhd1',
  'pttg1',
  'pttg2',
  'ptx3',
  'puf60',
  'pum1',
  'pum2',
  'pum3',
  'pura',
  'purb',
  'purg',
  'pus10',
  'pus3',
  'pus7',
  'pvalb',
  'pwp1',
  'pwp2',
  'pwwp2a',
  'pwwp2b',
  'pxdc1',
  'pxdn',
  'pxk',
  'pxmp4',
  'pxn',
  'pxylp1',
  'pycard',
  'pycr1',
  'pycr2',
  'pydc1',
  'pygb',
  'pygl',
  'pygm',
  'pygo1',
  'pygo2',
  'pym1',
  'pyroxd1',
  'pyroxd2',
  'pyurf',
  'pyy',
  'qars1',
  'qdpr',
  'qki',
  'qpct',
  'qpctl',
  'qprt',
  'qrich1',
  'qrsl1',
  'qsox1',
  'qtrt1',
  'qtrt2',
  'r3hcc1',
  'r3hdm1',
  'r3hdm2',
  'r3hdm4',
  'rab10',
  'rab11a',
  'rab11b',
  'rab11fip2',
  'rab11fip4',
  'rab11fip5',
  'rab13',
  'rab14',
  'rab15',
  'rab17',
  'rab18',
  'rab19',
  'rab1a',
  'rab1b',
  'rab20',
  'rab21',
  'rab22a',
  'rab23',
  'rab24',
  'rab25',
  'rab26',
  'rab27a',
  'rab27b',
  'rab28',
  'rab29',
  'rab2a',
  'rab2b',
  'rab30',
  'rab31',
  'rab32',
  'rab33a',
  'rab33b',
  'rab34',
  'rab35',
  'rab36',
  'rab37',
  'rab38',
  'rab39a',
  'rab39b',
  'rab3a',
  'rab3b',
  'rab3c',
  'rab3d',
  'rab3gap1',
  'rab3gap2',
  'rab3il1',
  'rab3ip',
  'rab40b',
  'rab40c',
  'rab4a',
  'rab4b',
  'rab5a',
  'rab5b',
  'rab5c',
  'rab5if',
  'rab6a',
  'rab6b',
  'rab7a',
  'rab7b',
  'rab8a',
  'rab8b',
  'rab9a',
  'rab9b',
  'rabac1',
  'rabep1',
  'rabep2',
  'rabepk',
  'rabgap1',
  'rabgap1l',
  'rabgef1',
  'rabggta',
  'rabggtb',
  'rabif',
  'rabl2a',
  'rabl2b',
  'rabl3',
  'rac1',
  'rac2',
  'rac3',
  'racgap1',
  'rack1',
  'rad1',
  'rad17',
  'rad21',
  'rad23a',
  'rad23b',
  'rad50',
  'rad51',
  'rad51b',
  'rad51c',
  'rad51d',
  'rad54b',
  'rad54l',
  'rad54l2',
  'rad9a',
  'radil',
  'rae1',
  'raf1',
  'rag2',
  'rai1',
  'rai14',
  'rai2',
  'rala',
  'ralb',
  'ralbp1',
  'ralgapa1',
  'ralgapa2',
  'ralgapb',
  'ralgds',
  'ralgps1',
  'ralgps2',
  'raly',
  'ralyl',
  'ramac',
  'ramacl',
  'ran',
  'ranbp1',
  'ranbp10',
  'ranbp17',
  'ranbp3',
  'ranbp6',
  'rangap1',
  'rangrf',
  'rap1a',
  'rap1b',
  'rap1gap',
  'rap1gap2',
  'rap1gds1',
  'rap2a',
  'rap2b',
  'rap2c',
  'rapgef1',
  'rapgef2',
  'rapgef3',
  'rapgef4',
  'rapgef5',
  'rapgef6',
  'rapgefl1',
  'raph1',
  'rapsn',
  'rara',
  'rarb',
  'rarg',
  'rarres2',
  'rars1',
  'rars2',
  'rasa1',
  'rasa2',
  'rasa4',
  'rasa4b',
  'rasal1',
  'rasal2',
  'rasal3',
  'rasd1',
  'rasd2',
  'rasef',
  'rasgef1a',
  'rasgef1b',
  'rasgrf1',
  'rasgrf2',
  'rasgrp1',
  'rasgrp2',
  'rasgrp3',
  'rasgrp4',
  'rasip1',
  'rasl10a',
  'rasl10b',
  'rasl11a',
  'rasl11b',
  'rasl12',
  'rassf1',
  'rassf10',
  'rassf2',
  'rassf4',
  'rassf5',
  'rassf7',
  'rassf8',
  'rassf9',
  'raver1',
  'rax',
  'rax2',
  'rb1',
  'rb1cc1',
  'rbak',
  'rbbp4',
  'rbbp5',
  'rbbp6',
  'rbbp7',
  'rbbp8',
  'rbbp9',
  'rbck1',
  'rbfox1',
  'rbfox2',
  'rbfox3',
  'rbis',
  'rbks',
  'rbl1',
  'rbl2',
  'rbm10',
  'rbm12b',
  'rbm14',
  'rbm15',
  'rbm17',
  'rbm18',
  'rbm19',
  'rbm20',
  'rbm22',
  'rbm23',
  'rbm24',
  'rbm25',
  'rbm26',
  'rbm27',
  'rbm28',
  'rbm3',
  'rbm33',
  'rbm38',
  'rbm39',
  'rbm4',
  'rbm41',
  'rbm42',
  'rbm45',
  'rbm46',
  'rbm47',
  'rbm48',
  'rbm4b',
  'rbm5',
  'rbm6',
  'rbm7',
  'rbm8a',
  'rbms1',
  'rbms2',
  'rbms3',
  'rbmx',
  'rbmx2',
  'rbp1',
  'rbp2',
  'rbp3',
  'rbp4',
  'rbp5',
  'rbp7',
  'rbpj',
  'rbpjl',
  'rbpms',
  'rbpms2',
  'rbx1',
  'rc3h1',
  'rc3h2',
  'rcan1',
  'rcan2',
  'rcan3',
  'rcbtb1',
  'rcbtb2',
  'rcc1',
  'rcc1l',
  'rcc2',
  'rccd1',
  'rce1',
  'rchy1',
  'rcl1',
  'rcn1',
  'rcn2',
  'rcn3',
  'rcor1',
  'rcor2',
  'rcor3',
  'rcvrn',
  'rd3',
  'rdh10',
  'rdh11',
  'rdh12',
  'rdh13',
  'rdh14',
  'rdh16',
  'rdh5',
  'rdh8',
  'rdx',
  'reck',
  'recql',
  'recql5',
  'reeld1',
  'reep1',
  'reep2',
  'reep3',
  'reep4',
  'reep5',
  'rela',
  'relb',
  'relch',
  'rell1',
  'rell2',
  'reln',
  'relt',
  'rem1',
  'rem2',
  'renbp',
  'rep15',
  'repin1',
  'reps1',
  'reps2',
  'rer1',
  'rere',
  'rerg',
  'ret',
  'retreg1',
  'retreg2',
  'retreg3',
  'retsat',
  'rev1',
  'rev3l',
  'rexo1',
  'rexo2',
  'rfc1',
  'rfc2',
  'rfc3',
  'rfc4',
  'rfc5',
  'rffl',
  'rfk',
  'rflna',
  'rflnb',
  'rft1',
  'rftn2',
  'rfx1',
  'rfx2',
  'rfx3',
  'rfx4',
  'rfx5',
  'rfx6',
  'rfx7',
  'rfxank',
  'rfxap',
  'rgcc',
  'rgl1',
  'rgl2',
  'rgl3',
  'rgma',
  'rgmb',
  'rgn',
  'rgp1',
  'rgpd3',
  'rgpd8',
  'rgr',
  'rgs1',
  'rgs10',
  'rgs12',
  'rgs13',
  'rgs14',
  'rgs16',
  'rgs17',
  'rgs18',
  'rgs19',
  'rgs2',
  'rgs21',
  'rgs3',
  'rgs4',
  'rgs5',
  'rgs6',
  'rgs7',
  'rgs7bp',
  'rgs8',
  'rgs9',
  'rgs9bp',
  'rhbdd1',
  'rhbdd2',
  'rhbdd3',
  'rhbdf1',
  'rhbdf2',
  'rhbdl1',
  'rhbdl2',
  'rhbdl3',
  'rhbg',
  'rhcg',
  'rheb',
  'rhebl1',
  'rho',
  'rhoa',
  'rhob',
  'rhobtb1',
  'rhobtb2',
  'rhobtb3',
  'rhoc',
  'rhod',
  'rhof',
  'rhog',
  'rhoh',
  'rhoj',
  'rhoq',
  'rhot1',
  'rhot2',
  'rhou',
  'rhov',
  'rhpn1',
  'rhpn2',
  'ribc2',
  'ric1',
  'ric3',
  'ric8a',
  'ric8b',
  'rictor',
  'rida',
  'riiad1',
  'rilpl1',
  'rilpl2',
  'rimbp3',
  'rimbp3b',
  'rimbp3c',
  'rimkla',
  'rimklb',
  'rimoc1',
  'rims1',
  'rims2',
  'rims3',
  'rims4',
  'rin1',
  'rin2',
  'rin3',
  'ring1',
  'rinl',
  'rint1',
  'riok1',
  'riok2',
  'riok3',
  'riox1',
  'riox2',
  'ripk2',
  'ripk4',
  'ripor1',
  'ripor2',
  'ripor3',
  'ripply1',
  'rit1',
  'rit2',
  'rita1',
  'rlbp1',
  'rlf',
  'rlim',
  'rln3',
  'rmc1',
  'rmdn1',
  'rmdn3',
  'rmi1',
  'rmi2',
  'rmnd5a',
  'rmnd5b',
  'rnase1',
  'rnase4',
  'rnase7',
  'rnase8',
  'rnaseh2a',
  'rnaseh2b',
  'rnaseh2c',
  'rnasek',
  'rnd1',
  'rnd2',
  'rnd3',
  'rnf10',
  'rnf103',
  'rnf11',
  'rnf111',
  'rnf112',
  'rnf113a',
  'rnf113b',
  'rnf114',
  'rnf115',
  'rnf121',
  'rnf122',
  'rnf123',
  'rnf125',
  'rnf126',
  'rnf128',
  'rnf13',
  'rnf130',
  'rnf133',
  'rnf138',
  'rnf14',
  'rnf141',
  'rnf144a',
  'rnf144b',
  'rnf145',
  'rnf146',
  'rnf148',
  'rnf149',
  'rnf150',
  'rnf152',
  'rnf157',
  'rnf165',
  'rnf166',
  'rnf167',
  'rnf169',
  'rnf170',
  'rnf175',
  'rnf180',
  'rnf181',
  'rnf182',
  'rnf183',
  'rnf185',
  'rnf186',
  'rnf19a',
  'rnf19b',
  'rnf2',
  'rnf20',
  'rnf207',
  'rnf208',
  'rnf214',
  'rnf215',
  'rnf216',
  'rnf217',
  'rnf220',
  'rnf222',
  'rnf223',
  'rnf224',
  'rnf227',
  'rnf24',
  'rnf25',
  'rnf26',
  'rnf31',
  'rnf34',
  'rnf38',
  'rnf39',
  'rnf4',
  'rnf40',
  'rnf41',
  'rnf43',
  'rnf44',
  'rnf5',
  'rnf7',
  'rnft1',
  'rnft2',
  'rngtt',
  'rnh1',
  'rnls',
  'rnmt',
  'rnpc3',
  'rnpep',
  'rnpepl1',
  'rnps1',
  'ro60',
  'robo1',
  'robo2',
  'robo3',
  'robo4',
  'rock1',
  'rock2',
  'rogdi',
  'rom1',
  'romo1',
  'ropn1',
  'ropn1b',
  'ror1',
  'ror2',
  'rora',
  'rorb',
  'rorc',
  'ros1',
  'rp2',
  'rp9',
  'rpa2',
  'rpa3',
  'rpap1',
  'rpap3',
  'rpe',
  'rpe65',
  'rpel1',
  'rpf1',
  'rpf2',
  'rpgrip1',
  'rpgrip1l',
  'rph3a',
  'rph3al',
  'rpia',
  'rpl10',
  'rpl10a',
  'rpl10l',
  'rpl11',
  'rpl12',
  'rpl13',
  'rpl13a',
  'rpl14',
  'rpl15',
  'rpl17',
  'rpl18',
  'rpl18a',
  'rpl19',
  'rpl21',
  'rpl22',
  'rpl22l1',
  'rpl23',
  'rpl23a',
  'rpl24',
  'rpl26',
  'rpl26l1',
  'rpl27',
  'rpl27a',
  'rpl28',
  'rpl29',
  'rpl3',
  'rpl30',
  'rpl31',
  'rpl32',
  'rpl34',
  'rpl35',
  'rpl35a',
  'rpl36',
  'rpl36a',
  'rpl36al',
  'rpl37',
  'rpl37a',
  'rpl38',
  'rpl39',
  'rpl39l',
  'rpl3l',
  'rpl4',
  'rpl41',
  'rpl5',
  'rpl6',
  'rpl7',
  'rpl7a',
  'rpl7l1',
  'rpl8',
  'rpl9',
  'rplp0',
  'rplp1',
  'rpn1',
  'rpn2',
  'rpp14',
  'rpp21',
  'rpp25',
  'rpp25l',
  'rpp30',
  'rpp40',
  'rprd1a',
  'rprd1b',
  'rprd2',
  'rprm',
  'rps10',
  'rps11',
  'rps12',
  'rps13',
  'rps14',
  'rps15',
  'rps15a',
  'rps16',
  'rps17',
  'rps18',
  'rps19',
  'rps19bp1',
  'rps2',
  'rps20',
  'rps21',
  'rps23',
  'rps24',
  'rps25',
  'rps26',
  'rps27',
  'rps27a',
  'rps27l',
  'rps28',
  'rps29',
  'rps3',
  'rps3a',
  'rps4x',
  'rps5',
  'rps6',
  'rps6ka1',
  'rps6ka2',
  'rps6ka3',
  'rps6ka4',
  'rps6ka5',
  'rps6ka6',
  'rps6kb1',
  'rps6kb2',
  'rps6kc1',
  'rps6kl1',
  'rps7',
  'rps8',
  'rps9',
  'rpsa',
  'rpsap58',
  'rptor',
  'rpusd1',
  'rpusd2',
  'rpusd3',
  'rrad',
  'rraga',
  'rragb',
  'rragc',
  'rragd',
  'rras',
  'rras2',
  'rreb1',
  'rrm1',
  'rrm2',
  'rrm2b',
  'rrn3',
  'rrp12',
  'rrp7a',
  'rrp8',
  'rrp9',
  'rrs1',
  'rs1',
  'rsad1',
  'rsbn1',
  'rsbn1l',
  'rsc1a1',
  'rsf1',
  'rskr',
  'rsl24d1',
  'rsph9',
  'rspo1',
  'rspo2',
  'rspo3',
  'rspo4',
  'rspry1',
  'rsrc1',
  'rsrc2',
  'rsu1',
  'rtbdn',
  'rtca',
  'rtcb',
  'rtf1',
  'rtf2',
  'rtkn',
  'rtkn2',
  'rtl1',
  'rtl5',
  'rtl6',
  'rtl8a',
  'rtl8b',
  'rtl8c',
  'rtn1',
  'rtn2',
  'rtn4',
  'rtn4ip1',
  'rtn4r',
  'rtn4rl1',
  'rtn4rl2',
  'rtp1',
  'rtp2',
  'rtraf',
  'rttn',
  'rubcn',
  'rufy1',
  'rufy2',
  'rufy3',
  'rundc1',
  'rundc3a',
  'rundc3b',
  'runx1',
  'runx1t1',
  'runx2',
  'runx3',
  'rusc1',
  'rusc2',
  'rusf1',
  'ruvbl2',
  'rwdd1',
  'rwdd2b',
  'rwdd3',
  'rwdd4',
  'rxfp2',
  'rxfp3',
  'rxfp4',
  'rxra',
  'rxrb',
  'rxrg',
  'rxylt1',
  'rybp',
  'ryk',
  'ryr1',
  'ryr2',
  'ryr3',
  's100a1',
  's100a10',
  's100a11',
  's100a13',
  's100a14',
  's100a16',
  's100a2',
  's100a3',
  's100a4',
  's100a5',
  's100a6',
  's100g',
  's1pr1',
  's1pr2',
  's1pr3',
  's1pr4',
  's1pr5',
  'saa1',
  'saa2',
  'saal1',
  'sac3d1',
  'sacm1l',
  'sacs',
  'sae1',
  'safb',
  'safb2',
  'sag',
  'sall1',
  'sall2',
  'sall3',
  'samd10',
  'samd11',
  'samd13',
  'samd14',
  'samd3',
  'samd4a',
  'samd4b',
  'samd5',
  'samd8',
  'samd9l',
  'samm50',
  'samsn1',
  'sanbr',
  'sap130',
  'sap18',
  'sap30',
  'sap30bp',
  'sap30l',
  'sapcd1',
  'sapcd2',
  'sar1a',
  'sar1b',
  'sardh',
  'sarm1',
  'sarnp',
  'sars1',
  'sars2',
  'sart1',
  'sart3',
  'sash1',
  'sash3',
  'sass6',
  'sat1',
  'sat2',
  'satb1',
  'satb2',
  'sav1',
  'saxo1',
  'saxo2',
  'saysd1',
  'sbds',
  'sbf1',
  'sbf2',
  'sbk1',
  'sbk2',
  'sbk3',
  'sbno1',
  'sbno2',
  'sbspon',
  'scaf4',
  'scaf8',
  'scai',
  'scamp1',
  'scamp2',
  'scamp3',
  'scamp4',
  'scamp5',
  'scand1',
  'scap',
  'scaper',
  'scara3',
  'scara5',
  'scarb1',
  'scarb2',
  'scarf1',
  'scarf2',
  'sccpdh',
  'scd',
  'scd5',
  'scfd1',
  'scg2',
  'scg3',
  'scg5',
  'schip1',
  'scin',
  'scly',
  'scmh1',
  'scml4',
  'scn10a',
  'scn1a',
  'scn1b',
  'scn2a',
  'scn2b',
  'scn3a',
  'scn3b',
  'scn4a',
  'scn4b',
  'scn5a',
  'scn8a',
  'scn9a',
  'scnm1',
  'scnn1a',
  'scnn1b',
  'scnn1g',
  'sco1',
  'sco2',
  'scoc',
  'scp2',
  'scp2d1',
  'scpep1',
  'scrib',
  'scrn1',
  'scrn2',
  'scrn3',
  'scrt1',
  'scrt2',
  'sctr',
  'scube1',
  'scube2',
  'scube3',
  'scx',
  'scyl1',
  'scyl2',
  'scyl3',
  'sdad1',
  'sdc1',
  'sdc2',
  'sdc4',
  'sdcbp',
  'sdcbp2',
  'sdf2',
  'sdf2l1',
  'sdf4',
  'sdha',
  'sdhaf1',
  'sdhaf2',
  'sdhaf3',
  'sdhb',
  'sdhc',
  'sdhd',
  'sdk1',
  'sdk2',
  'sdr39u1',
  'sdr42e2',
  'sdr9c7',
  'sds',
  'sdsl',
  'sebox',
  'sec11a',
  'sec11c',
  'sec13',
  'sec14l1',
  'sec14l2',
  'sec14l3',
  'sec14l4',
  'sec14l5',
  'sec22a',
  'sec22b',
  'sec22c',
  'sec23a',
  'sec23b',
  'sec23ip',
  'sec24a',
  'sec24c',
  'sec24d',
  'sec31a',
  'sec31b',
  'sec61a1',
  'sec61a2',
  'sec61b',
  'sec61g',
  'sec62',
  'sec63',
  'secisbp2l',
  'seh1l',
  'sel1l',
  'sel1l3',
  'selenbp1',
  'selenof',
  'selenoh',
  'selenoi',
  'selenok',
  'selenom',
  'selenoo',
  'selenop',
  'selenos',
  'selenot',
  'sem1',
  'sema3a',
  'sema3b',
  'sema3c',
  'sema3d',
  'sema3e',
  'sema3f',
  'sema3g',
  'sema4a',
  'sema4b',
  'sema4c',
  'sema4d',
  'sema4f',
  'sema4g',
  'sema5a',
  'sema5b',
  'sema6a',
  'sema6b',
  'sema6c',
  'sema6d',
  'sema7a',
  'senp1',
  'senp2',
  'senp3',
  'senp5',
  'senp6',
  'senp8',
  'sephs1',
  'sephs2',
  'sepsecs',
  'septin1',
  'septin10',
  'septin11',
  'septin12',
  'septin2',
  'septin3',
  'septin5',
  'septin6',
  'septin7',
  'septin8',
  'septin9',
  'serac1',
  'serbp1',
  'serf1a',
  'serf2',
  'sergef',
  'serinc1',
  'serinc2',
  'serinc3',
  'serinc4',
  'serinc5',
  'serp1',
  'serp2',
  'serpina1',
  'serpina11',
  'serpina6',
  'serpina9',
  'serpinb1',
  'serpinb2',
  'serpinb5',
  'serpinb8',
  'serpinb9',
  'serpinc1',
  'serpind1',
  'serpine1',
  'serpine2',
  'serpine3',
  'serpinf1',
  'serpinf2',
  'serpinh1',
  'serpini1',
  'sertad2',
  'sertad3',
  'sertad4',
  'sertm1',
  'sertm2',
  'sesn1',
  'sesn3',
  'sestd1',
  'setbp1',
  'setd1a',
  'setd1b',
  'setd2',
  'setd3',
  'setd5',
  'setd6',
  'setd7',
  'setd9',
  'setdb1',
  'setmar',
  'setsip',
  'sez6',
  'sez6l',
  'sez6l2',
  'sf1',
  'sf3a1',
  'sf3a3',
  'sf3b1',
  'sf3b2',
  'sf3b3',
  'sf3b4',
  'sf3b5',
  'sf3b6',
  'sfmbt1',
  'sfmbt2',
  'sfn',
  'sfpq',
  'sfr1',
  'sfrp1',
  'sfrp2',
  'sfrp4',
  'sfrp5',
  'sfswap',
  'sftpa1',
  'sftpa2',
  'sftpb',
  'sftpc',
  'sftpd',
  'sfxn1',
  'sfxn2',
  'sfxn3',
  'sfxn5',
  'sgca',
  'sgcb',
  'sgcd',
  'sgce',
  'sgcz',
  'sgf29',
  'sgip1',
  'sgk1',
  'sgk2',
  'sgk3',
  'sgms1',
  'sgpl1',
  'sgpp1',
  'sgpp2',
  'sgsh',
  'sgsm1',
  'sgsm2',
  'sgsm3',
  'sgta',
  'sgtb',
  'sh2b1',
  'sh2b2',
  'sh2b3',
  'sh2d1a',
  'sh2d2a',
  'sh2d3c',
  'sh2d4b',
  'sh2d5',
  'sh3bgrl',
  'sh3bgrl2',
  'sh3bgrl3',
  'sh3bp1',
  'sh3bp2',
  'sh3bp5',
  'sh3bp5l',
  'sh3d19',
  'sh3gl1',
  'sh3gl2',
  'sh3gl3',
  'sh3glb1',
  'sh3glb2',
  'sh3kbp1',
  'sh3pxd2a',
  'sh3pxd2b',
  'sh3rf2',
  'sh3rf3',
  'sh3tc1',
  'sh3tc2',
  'shank1',
  'shank2',
  'shank3',
  'sharpin',
  'shb',
  'shbg',
  'shc1',
  'shc3',
  'shc4',
  'shcbp1',
  'shcbp1l',
  'shd',
  'she',
  'shf',
  'shfl',
  'shh',
  'shisa2',
  'shisa3',
  'shisa4',
  'shisa5',
  'shisa6',
  'shisa7',
  'shisa8',
  'shisa9',
  'shisal1',
  'shisal2b',
  'shkbp1',
  'shld3',
  'shmt1',
  'shmt2',
  'shoc2',
  'shox2',
  'shpk',
  'shprh',
  'shq1',
  'shroom1',
  'shroom4',
  'shtn1',
  'siae',
  'siah1',
  'siah2',
  'siah3',
  'sidt1',
  'sidt2',
  'sigirr',
  'siglec1',
  'siglec14',
  'siglec15',
  'sigmar1',
  'sik1',
  'sik2',
  'sik3',
  'sike1',
  'sil1',
  'sim1',
  'sim2',
  'simc1',
  'sin3a',
  'sin3b',
  'sinhcaf',
  'sipa1',
  'sipa1l1',
  'sipa1l2',
  'sipa1l3',
  'sirpa',
  'sirpb1',
  'sirt1',
  'sirt2',
  'sirt4',
  'sirt5',
  'sirt6',
  'sirt7',
  'sit1',
  'siva1',
  'six1',
  'six2',
  'six3',
  'six4',
  'six5',
  'six6',
  'ska2',
  'skap1',
  'skap2',
  'ski',
  'skida1',
  'skil',
  'skiv2l',
  'skor1',
  'skp1',
  'skp2',
  'sla2',
  'slain1',
  'slain2',
  'slamf8',
  'slamf9',
  'slbp',
  'slc10a1',
  'slc10a3',
  'slc10a7',
  'slc11a1',
  'slc11a2',
  'slc12a1',
  'slc12a2',
  'slc12a3',
  'slc12a4',
  'slc12a5',
  'slc12a6',
  'slc12a7',
  'slc12a8',
  'slc12a9',
  'slc13a2',
  'slc13a3',
  'slc13a4',
  'slc13a5',
  'slc14a1',
  'slc14a2',
  'slc15a2',
  'slc15a3',
  'slc16a1',
  'slc16a10',
  'slc16a11',
  'slc16a12',
  'slc16a13',
  'slc16a2',
  'slc16a3',
  'slc16a4',
  'slc16a6',
  'slc16a8',
  'slc16a9',
  'slc17a5',
  'slc17a6',
  'slc17a7',
  'slc17a8',
  'slc18a1',
  'slc18a2',
  'slc18a3',
  'slc18b1',
  'slc19a2',
  'slc19a3',
  'slc1a1',
  'slc1a2',
  'slc1a3',
  'slc1a4',
  'slc1a5',
  'slc1a6',
  'slc1a7',
  'slc20a1',
  'slc20a2',
  'slc22a1',
  'slc22a12',
  'slc22a13',
  'slc22a15',
  'slc22a17',
  'slc22a18',
  'slc22a2',
  'slc22a23',
  'slc22a3',
  'slc22a4',
  'slc22a5',
  'slc22a6',
  'slc22a7',
  'slc22a8',
  'slc23a1',
  'slc23a2',
  'slc23a3',
  'slc24a2',
  'slc24a3',
  'slc24a4',
  'slc24a5',
  'slc25a1',
  'slc25a10',
  'slc25a11',
  'slc25a12',
  'slc25a13',
  'slc25a14',
  'slc25a15',
  'slc25a16',
  'slc25a17',
  'slc25a18',
  'slc25a19',
  'slc25a2',
  'slc25a20',
  'slc25a21',
  'slc25a22',
  'slc25a23',
  'slc25a24',
  'slc25a25',
  'slc25a26',
  'slc25a27',
  'slc25a28',
  'slc25a29',
  'slc25a3',
  'slc25a30',
  'slc25a31',
  'slc25a32',
  'slc25a33',
  'slc25a34',
  'slc25a35',
  'slc25a36',
  'slc25a37',
  'slc25a38',
  'slc25a39',
  'slc25a4',
  'slc25a40',
  'slc25a42',
  'slc25a43',
  'slc25a44',
  'slc25a45',
  'slc25a46',
  'slc25a47',
  'slc25a48',
  'slc25a5',
  'slc25a51',
  'slc25a52',
  'slc25a53',
  'slc26a1',
  'slc26a11',
  'slc26a2',
  'slc26a4',
  'slc26a5',
  'slc26a6',
  'slc26a7',
  'slc26a9',
  'slc27a1',
  'slc27a2',
  'slc27a3',
  'slc27a4',
  'slc27a5',
  'slc28a1',
  'slc29a1',
  'slc29a2',
  'slc29a3',
  'slc29a4',
  'slc2a1',
  'slc2a10',
  'slc2a12',
  'slc2a13',
  'slc2a14',
  'slc2a2',
  'slc2a3',
  'slc2a4',
  'slc2a5',
  'slc2a6',
  'slc2a7',
  'slc2a8',
  'slc30a1',
  'slc30a10',
  'slc30a2',
  'slc30a3',
  'slc30a4',
  'slc30a5',
  'slc30a6',
  'slc30a7',
  'slc30a8',
  'slc30a9',
  'slc31a2',
  'slc32a1',
  'slc33a1',
  'slc34a1',
  'slc34a3',
  'slc35a1',
  'slc35a2',
  'slc35a3',
  'slc35a4',
  'slc35a5',
  'slc35b1',
  'slc35b2',
  'slc35b3',
  'slc35b4',
  'slc35c1',
  'slc35c2',
  'slc35d1',
  'slc35d3',
  'slc35e1',
  'slc35e4',
  'slc35f1',
  'slc35f2',
  'slc35f3',
  'slc35f4',
  'slc35f5',
  'slc35f6',
  'slc35g1',
  'slc35g2',
  'slc35g4',
  'slc36a1',
  'slc36a2',
  'slc36a3',
  'slc36a4',
  'slc37a2',
  'slc37a3',
  'slc37a4',
  'slc38a1',
  'slc38a2',
  'slc38a3',
  'slc38a4',
  'slc38a5',
  'slc38a6',
  'slc38a7',
  'slc38a8',
  'slc38a9',
  'slc39a1',
  'slc39a10',
  'slc39a11',
  'slc39a13',
  'slc39a14',
  'slc39a2',
  'slc39a3',
  'slc39a4',
  'slc39a5',
  'slc39a6',
  'slc39a7',
  'slc39a8',
  'slc39a9',
  'slc3a1',
  'slc3a2',
  'slc40a1',
  'slc41a1',
  'slc41a2',
  'slc43a1',
  'slc43a2',
  'slc43a3',
  'slc44a1',
  'slc44a2',
  'slc44a3',
  'slc44a4',
  'slc45a1',
  'slc45a2',
  'slc45a3',
  'slc45a4',
  'slc46a1',
  'slc46a2',
  'slc48a1',
  'slc49a4',
  'slc4a1',
  'slc4a10',
  'slc4a11',
  'slc4a2',
  'slc4a3',
  'slc4a4',
  'slc4a5',
  'slc4a7',
  'slc4a8',
  'slc4a9',
  'slc50a1',
  'slc51a',
  'slc52a1',
  'slc52a2',
  'slc52a3',
  'slc5a1',
  'slc5a10',
  'slc5a11',
  'slc5a12',
  'slc5a2',
  'slc5a3',
  'slc5a5',
  'slc5a6',
  'slc5a7',
  'slc5a8',
  'slc5a9',
  'slc66a1',
  'slc66a2',
  'slc66a3',
  'slc6a1',
  'slc6a11',
  'slc6a12',
  'slc6a13',
  'slc6a14',
  'slc6a15',
  'slc6a17',
  'slc6a19',
  'slc6a2',
  'slc6a20',
  'slc6a3',
  'slc6a4',
  'slc6a5',
  'slc6a6',
  'slc6a7',
  'slc6a8',
  'slc6a9',
  'slc7a1',
  'slc7a10',
  'slc7a11',
  'slc7a14',
  'slc7a2',
  'slc7a3',
  'slc7a4',
  'slc7a5',
  'slc7a6',
  'slc7a6os',
  'slc7a7',
  'slc7a8',
  'slc8a1',
  'slc8a2',
  'slc8a3',
  'slc8b1',
  'slc9a1',
  'slc9a2',
  'slc9a3',
  'slc9a3r1',
  'slc9a3r2',
  'slc9a5',
  'slc9a6',
  'slc9a7',
  'slc9a8',
  'slc9a9',
  'slco1c1',
  'slco2a1',
  'slco2b1',
  'slco3a1',
  'slco4a1',
  'slco5a1',
  'slf2',
  'slfnl1',
  'slit1',
  'slit2',
  'slit3',
  'slitrk1',
  'slitrk2',
  'slitrk3',
  'slitrk4',
  'slitrk5',
  'slitrk6',
  'slmap',
  'sltm',
  'slu7',
  'slurp2',
  'slx1a',
  'slx1b',
  'slx4ip',
  'smad1',
  'smad2',
  'smad3',
  'smad4',
  'smad5',
  'smad6',
  'smad7',
  'smad9',
  'smagp',
  'smap1',
  'smap2',
  'smarca1',
  'smarca2',
  'smarca4',
  'smarca5',
  'smarcad1',
  'smarcb1',
  'smarcc1',
  'smarcc2',
  'smarcd1',
  'smarcd2',
  'smarcd3',
  'smarce1',
  'smc1a',
  'smc1b',
  'smc2',
  'smc3',
  'smc4',
  'smc5',
  'smc6',
  'smchd1',
  'smco1',
  'smco3',
  'smco4',
  'smcr8',
  'smdt1',
  'smg1',
  'smg5',
  'smg6',
  'smg7',
  'smg8',
  'smg9',
  'smim10',
  'smim10l1',
  'smim10l2a',
  'smim10l2b',
  'smim12',
  'smim13',
  'smim14',
  'smim15',
  'smim17',
  'smim18',
  'smim19',
  'smim20',
  'smim29',
  'smim3',
  'smim32',
  'smim35',
  'smim36',
  'smim4',
  'smim40',
  'smim41',
  'smim43',
  'smim7',
  'smim8',
  'smkr1',
  'smn1',
  'smn2',
  'smndc1',
  'smo',
  'smoc1',
  'smoc2',
  'smox',
  'smpd1',
  'smpd2',
  'smpd3',
  'smpd4',
  'smpx',
  'sms',
  'smtn',
  'smtnl2',
  'smu1',
  'smug1',
  'smurf1',
  'smurf2',
  'smyd1',
  'smyd2',
  'smyd3',
  'smyd5',
  'snai1',
  'snai2',
  'snai3',
  'snap23',
  'snap25',
  'snap29',
  'snap91',
  'snapc2',
  'snapc3',
  'snapin',
  'snca',
  'sncaip',
  'sncb',
  'snd1',
  'sned1',
  'snf8',
  'snip1',
  'snn',
  'snorc',
  'snph',
  'snrk',
  'snrnp200',
  'snrnp25',
  'snrnp27',
  'snrnp35',
  'snrnp40',
  'snrnp70',
  'snrpa',
  'snrpa1',
  'snrpb',
  'snrpb2',
  'snrpc',
  'snrpd1',
  'snrpd2',
  'snrpd3',
  'snrpe',
  'snrpf',
  'snrpg',
  'snrpn',
  'snta1',
  'sntb1',
  'sntb2',
  'sntg1',
  'snu13',
  'snupn',
  'snurf',
  'snw1',
  'snx1',
  'snx10',
  'snx11',
  'snx12',
  'snx13',
  'snx14',
  'snx15',
  'snx16',
  'snx17',
  'snx18',
  'snx19',
  'snx2',
  'snx20',
  'snx21',
  'snx22',
  'snx24',
  'snx25',
  'snx27',
  'snx29',
  'snx3',
  'snx30',
  'snx31',
  'snx32',
  'snx33',
  'snx4',
  'snx5',
  'snx6',
  'snx7',
  'snx8',
  'snx9',
  'soat1',
  'soat2',
  'sobp',
  'socs1',
  'socs2',
  'socs3',
  'socs4',
  'socs5',
  'socs6',
  'socs7',
  'sod2',
  'sod3',
  'soga1',
  'soga3',
  'son',
  'sorbs1',
  'sorbs2',
  'sorbs3',
  'sorcs1',
  'sorcs2',
  'sorcs3',
  'sord',
  'sorl1',
  'sort1',
  'sos1',
  'sos2',
  'sost',
  'sostdc1',
  'sowahd',
  'sox1',
  'sox10',
  'sox11',
  'sox12',
  'sox13',
  'sox14',
  'sox15',
  'sox17',
  'sox18',
  'sox2',
  'sox21',
  'sox3',
  'sox30',
  'sox5',
  'sox6',
  'sox7',
  'sox8',
  'sox9',
  'sp1',
  'sp2',
  'sp3',
  'sp4',
  'sp5',
  'sp6',
  'sp7',
  'sp8',
  'sp9',
  'spaar',
  'spaca3',
  'spaca5',
  'spaca5b',
  'spaca6',
  'spaca9',
  'spag4',
  'spag6',
  'spag7',
  'spag9',
  'sparc',
  'spart',
  'spast',
  'spata16',
  'spata2',
  'spata20',
  'spata24',
  'spata25',
  'spata2l',
  'spata46',
  'spata5',
  'spata5l1',
  'spata6',
  'spats2',
  'spats2l',
  'spc25',
  'spcs1',
  'spcs2',
  'spcs3',
  'spdef',
  'spdl1',
  'spdya',
  'specc1',
  'specc1l',
  'spef1',
  'speg',
  'spegnb',
  'spem2',
  'spen',
  'spg11',
  'spg21',
  'sphk1',
  'sphk2',
  'spi1',
  'spib',
  'spic',
  'spin1',
  'spin2a',
  'spin2b',
  'spin3',
  'spin4',
  'spindoc',
  'spint1',
  'spint2',
  'spire1',
  'spire2',
  'spns1',
  'spns2',
  'spns3',
  'spock1',
  'spock2',
  'spon1',
  'spon2',
  'spop',
  'spopl',
  'spout1',
  'sppl2a',
  'sppl2b',
  'sppl2c',
  'sppl3',
  'spr',
  'spred1',
  'spred2',
  'spred3',
  'spring1',
  'sprn',
  'sprr1a',
  'sprr1b',
  'spry2',
  'spry4',
  'spryd3',
  'spryd4',
  'spryd7',
  'spsb1',
  'spsb2',
  'spsb3',
  'spsb4',
  'spta1',
  'sptan1',
  'sptb',
  'sptbn1',
  'sptbn2',
  'sptbn4',
  'sptbn5',
  'sptlc1',
  'sptlc2',
  'sptlc3',
  'sptssa',
  'sptssb',
  'spty2d1os',
  'sqle',
  'sqor',
  'sqstm1',
  'sra1',
  'srbd1',
  'src',
  'srcap',
  'srcin1',
  'srd5a2',
  'srd5a3',
  'srebf1',
  'srebf2',
  'srek1',
  'srek1ip1',
  'srf',
  'srgap1',
  'srgap2',
  'srgap2b',
  'srgap2c',
  'srgap3',
  'sri',
  'srl',
  'srm',
  'srp19',
  'srp54',
  'srp68',
  'srp72',
  'srp9',
  'srpk1',
  'srpk2',
  'srpk3',
  'srpra',
  'srprb',
  'srpx',
  'srpx2',
  'srr',
  'srrm1',
  'srrm2',
  'srrm3',
  'srrm4',
  'srrt',
  'srsf1',
  'srsf10',
  'srsf11',
  'srsf12',
  'srsf2',
  'srsf3',
  'srsf4',
  'srsf5',
  'srsf6',
  'srsf7',
  'srsf9',
  'srxn1',
  'ss18',
  'ss18l1',
  'ss18l2',
  'ssb',
  'ssbp1',
  'ssbp2',
  'ssbp3',
  'ssbp4',
  'ssc4d',
  'ssh1',
  'ssh2',
  'ssh3',
  'ssna1',
  'sspn',
  'ssr1',
  'ssr2',
  'ssr3',
  'ssr4',
  'ssrp1',
  'sst',
  'sstr1',
  'sstr2',
  'sstr3',
  'sstr4',
  'sstr5',
  'ssu72',
  'ssx2ip',
  'st13',
  'st14',
  'st18',
  'st3gal1',
  'st3gal2',
  'st3gal3',
  'st3gal4',
  'st3gal5',
  'st3gal6',
  'st6gal1',
  'st6gal2',
  'st6galnac2',
  'st6galnac3',
  'st6galnac4',
  'st6galnac5',
  'st6galnac6',
  'st7',
  'st7l',
  'st8sia1',
  'st8sia2',
  'st8sia3',
  'st8sia4',
  'st8sia5',
  'st8sia6',
  'stab2',
  'stac',
  'stac2',
  'stac3',
  'stag1',
  'stag2',
  'stag3',
  'stam',
  'stam2',
  'stambp',
  'stambpl1',
  'star',
  'stard10',
  'stard13',
  'stard3',
  'stard3nl',
  'stard4',
  'stard5',
  'stard7',
  'stard8',
  'stat1',
  'stat2',
  'stat3',
  'stat4',
  'stat5a',
  'stat5b',
  'stat6',
  'stau1',
  'stau2',
  'stc1',
  'stc2',
  'steap1',
  'steap2',
  'steap3',
  'steap4',
  'steep1',
  'stil',
  'stim1',
  'stim2',
  'stimate',
  'stip1',
  'stk10',
  'stk11',
  'stk11ip',
  'stk16',
  'stk17a',
  'stk17b',
  'stk19',
  'stk24',
  'stk25',
  'stk26',
  'stk3',
  'stk32a',
  'stk32b',
  'stk35',
  'stk36',
  'stk38',
  'stk38l',
  'stk39',
  'stk4',
  'stk40',
  'stmn1',
  'stmn2',
  'stmn3',
  'stmn4',
  'stmp1',
  'stom',
  'stoml1',
  'stoml2',
  'ston1',
  'ston2',
  'stox2',
  'stra6',
  'strada',
  'stradb',
  'strap',
  'strbp',
  'strc',
  'strip1',
  'strip2',
  'strit1',
  'strn',
  'strn3',
  'strn4',
  'stt3a',
  'stt3b',
  'stub1',
  'stum',
  'stx10',
  'stx11',
  'stx12',
  'stx16',
  'stx17',
  'stx18',
  'stx19',
  'stx1a',
  'stx1b',
  'stx3',
  'stx4',
  'stx5',
  'stx6',
  'stx7',
  'stx8',
  'stxbp1',
  'stxbp2',
  'stxbp3',
  'stxbp5',
  'stxbp5l',
  'stxbp6',
  'styk1',
  'styx',
  'styxl2',
  'sub1',
  'sucla2',
  'suclg1',
  'suclg2',
  'suco',
  'suds3',
  'sufu',
  'sugp1',
  'sugp2',
  'sugt1',
  'sulf1',
  'sulf2',
  'sult1a1',
  'sult1a2',
  'sult1a3',
  'sult1a4',
  'sult1c2',
  'sult4a1',
  'sumf1',
  'sumf2',
  'sumo1',
  'sumo2',
  'sumo4',
  'sun2',
  'sun5',
  'suox',
  'supt16h',
  'supt20h',
  'supt3h',
  'supt4h1',
  'supt5h',
  'supt6h',
  'supt7l',
  'supv3l1',
  'surf4',
  'surf6',
  'susd1',
  'susd2',
  'susd4',
  'susd5',
  'susd6',
  'suv39h1',
  'suv39h2',
  'suz12',
  'sv2a',
  'sv2b',
  'sv2c',
  'svbp',
  'svep1',
  'svop',
  'svopl',
  'swap70',
  'swi5',
  'swsap1',
  'syap1',
  'sybu',
  'syce3',
  'syde1',
  'syde2',
  'syf2',
  'syk',
  'sympk',
  'syn1',
  'syn2',
  'syn3',
  'syncrip',
  'syndig1',
  'syndig1l',
  'syne1',
  'syne3',
  'syngap1',
  'syngr1',
  'syngr2',
  'syngr3',
  'syngr4',
  'synj1',
  'synj2',
  'synj2bp',
  'synpo',
  'synpo2l',
  'synpr',
  'synrg',
  'syp',
  'sypl1',
  'sypl2',
  'sys1',
  'syt1',
  'syt10',
  'syt11',
  'syt12',
  'syt13',
  'syt14',
  'syt15',
  'syt15b',
  'syt16',
  'syt17',
  'syt2',
  'syt3',
  'syt4',
  'syt5',
  'syt6',
  'syt7',
  'syt9',
  'sytl1',
  'sytl4',
  'sytl5',
  'syvn1',
  'szrd1',
  'szt2',
  'taar6',
  'taar8',
  'taar9',
  'tab1',
  'tab3',
  'tac1',
  'taco1',
  'tacr1',
  'tacr2',
  'tacstd2',
  'tada1',
  'tada2a',
  'tada2b',
  'tada3',
  'taf1',
  'taf10',
  'taf11',
  'taf12',
  'taf13',
  'taf1c',
  'taf2',
  'taf4b',
  'taf5',
  'taf5l',
  'taf6',
  'taf6l',
  'taf7',
  'taf8',
  'taf9',
  'taf9b',
  'tafa1',
  'tafa2',
  'tafa3',
  'tafa4',
  'tafa5',
  'tafazzin',
  'tagln',
  'tagln2',
  'tagln3',
  'tal1',
  'tal2',
  'taldo1',
  'tamalin',
  'tamm41',
  'tanc1',
  'tanc2',
  'tango2',
  'tango6',
  'tank',
  'taok1',
  'taok2',
  'taok3',
  'tap1',
  'tap2',
  'tapbp',
  'tapbpl',
  'tapt1',
  'tarbp2',
  'tardbp',
  'tars1',
  'tars2',
  'tars3',
  'tas1r1',
  'tas1r2',
  'tas1r3',
  'tas2r39',
  'tas2r40',
  'tas2r41',
  'tasl',
  'tasor',
  'tasp1',
  'tat',
  'tatdn1',
  'tatdn2',
  'tatdn3',
  'tax1bp1',
  'tax1bp3',
  'tbc1d1',
  'tbc1d10a',
  'tbc1d10b',
  'tbc1d10c',
  'tbc1d12',
  'tbc1d13',
  'tbc1d14',
  'tbc1d15',
  'tbc1d16',
  'tbc1d17',
  'tbc1d19',
  'tbc1d2',
  'tbc1d20',
  'tbc1d21',
  'tbc1d22a',
  'tbc1d22b',
  'tbc1d23',
  'tbc1d24',
  'tbc1d25',
  'tbc1d2b',
  'tbc1d30',
  'tbc1d31',
  'tbc1d4',
  'tbc1d5',
  'tbc1d7',
  'tbc1d8',
  'tbc1d8b',
  'tbc1d9',
  'tbc1d9b',
  'tbca',
  'tbcb',
  'tbcc',
  'tbccd1',
  'tbcel',
  'tbck',
  'tbk1',
  'tbkbp1',
  'tbl1x',
  'tbl1xr1',
  'tbl2',
  'tbl3',
  'tbpl1',
  'tbr1',
  'tbrg1',
  'tbrg4',
  'tbx1',
  'tbx10',
  'tbx15',
  'tbx18',
  'tbx19',
  'tbx2',
  'tbx20',
  'tbx21',
  'tbx3',
  'tbx4',
  'tbx5',
  'tbx6',
  'tbxas1',
  'tbxt',
  'tc2n',
  'tcaf1',
  'tcaf2',
  'tcap',
  'tcea1',
  'tcea2',
  'tcea3',
  'tceal1',
  'tceal2',
  'tceal4',
  'tceal5',
  'tceal7',
  'tceal8',
  'tceanc',
  'tceanc2',
  'tcerg1',
  'tcf12',
  'tcf15',
  'tcf19',
  'tcf20',
  'tcf21',
  'tcf23',
  'tcf3',
  'tcf4',
  'tcf7',
  'tcf7l1',
  'tcf7l2',
  'tchp',
  'tcim',
  'tcirg1',
  'tcp1',
  'tcp11',
  'tcp11l2',
  'tcp11x1',
  'tcp11x2',
  'tcta',
  'tcte1',
  'tctn1',
  'tdg',
  'tdp1',
  'tdrd3',
  'tdrd7',
  'tdrkh',
  'tead1',
  'tead2',
  'tead3',
  'tead4',
  'tecpr1',
  'tecpr2',
  'tecr',
  'tecta',
  'tectb',
  'tedc2',
  'teddm1',
  'tef',
  'tefm',
  'tek',
  'tekt1',
  'tekt2',
  'tekt3',
  'tekt4',
  'tekt5',
  'ten1',
  'tenm1',
  'tenm2',
  'tenm3',
  'tenm4',
  'tent2',
  'tent4a',
  'tent4b',
  'tent5a',
  'tent5b',
  'tent5c',
  'tepp',
  'tepsin',
  'terb2',
  'terf2',
  'terf2ip',
  'tes',
  'tesc',
  'tesk1',
  'tesk2',
  'tet3',
  'tex10',
  'tex2',
  'tex261',
  'tex264',
  'tex30',
  'tex38',
  'tex45',
  'tex52',
  'tfap2a',
  'tfap2b',
  'tfap2c',
  'tfap2d',
  'tfap2e',
  'tfap4',
  'tfb1m',
  'tfcp2',
  'tfcp2l1',
  'tfdp1',
  'tfdp2',
  'tfe3',
  'tfeb',
  'tfec',
  'tfg',
  'tfip11',
  'tfpt',
  'tfr2',
  'tg',
  'tgds',
  'tgfa',
  'tgfb1',
  'tgfb1i1',
  'tgfb2',
  'tgfb3',
  'tgfbi',
  'tgfbr1',
  'tgfbr2',
  'tgfbr3',
  'tgfbrap1',
  'tgif1',
  'tgif2',
  'tgm1',
  'tgm2',
  'tgm5',
  'tgm6',
  'tgm7',
  'th',
  'thada',
  'thap1',
  'thap11',
  'thap12',
  'thap2',
  'thap3',
  'thap4',
  'thap6',
  'thap7',
  'thbd',
  'thbs1',
  'thbs2',
  'thbs3',
  'thbs4',
  'them6',
  'themis',
  'thg1l',
  'thnsl1',
  'thnsl2',
  'thoc1',
  'thoc2',
  'thoc3',
  'thoc5',
  'thoc6',
  'thoc7',
  'thop1',
  'thpo',
  'thra',
  'thrap3',
  'thrb',
  'thrsp',
  'thsd1',
  'thsd4',
  'thsd7a',
  'thsd7b',
  'thsd8',
  'thtpa',
  'thumpd1',
  'thumpd2',
  'thumpd3',
  'thy1',
  'thyn1',
  'tia1',
  'tial1',
  'tiam1',
  'tiam2',
  'tie1',
  'tifab',
  'tigd2',
  'tigd3',
  'tigd4',
  'tigd5',
  'tigd7',
  'timeless',
  'timm10',
  'timm10b',
  'timm13',
  'timm17a',
  'timm17b',
  'timm22',
  'timm23',
  'timm23b',
  'timm29',
  'timm44',
  'timm50',
  'timm8a',
  'timm8b',
  'timm9',
  'timp1',
  'timp2',
  'timp3',
  'timp4',
  'tinag',
  'tinagl1',
  'tincr',
  'tinf2',
  'tiparp',
  'tiprl',
  'tirap',
  'tjap1',
  'tjp1',
  'tjp2',
  'tjp3',
  'tk1',
  'tk2',
  'tkfc',
  'tkt',
  'tktl1',
  'tlcd1',
  'tlcd3a',
  'tlcd3b',
  'tlcd5',
  'tldc2',
  'tle1',
  'tle2',
  'tle3',
  'tle4',
  'tle5',
  'tlk1',
  'tlk2',
  'tll2',
  'tln1',
  'tln2',
  'tlr7',
  'tlr9',
  'tlx1',
  'tlx2',
  'tlx3',
  'tm2d1',
  'tm2d2',
  'tm4sf1',
  'tm4sf18',
  'tm4sf19',
  'tm4sf4',
  'tm4sf5',
  'tm6sf1',
  'tm6sf2',
  'tm7sf2',
  'tm7sf3',
  'tm9sf1',
  'tm9sf2',
  'tm9sf3',
  'tm9sf4',
  'tma7',
  'tmbim1',
  'tmbim6',
  'tmc1',
  'tmc4',
  'tmc6',
  'tmc7',
  'tmc8',
  'tmcc2',
  'tmco1',
  'tmco4',
  'tmco6',
  'tmed1',
  'tmed10',
  'tmed2',
  'tmed3',
  'tmed4',
  'tmed5',
  'tmed6',
  'tmed7',
  'tmed8',
  'tmed9',
  'tmeff1',
  'tmeff2',
  'tmem100',
  'tmem101',
  'tmem102',
  'tmem104',
  'tmem106a',
  'tmem106b',
  'tmem106c',
  'tmem107',
  'tmem108',
  'tmem109',
  'tmem11',
  'tmem114',
  'tmem115',
  'tmem117',
  'tmem119',
  'tmem120a',
  'tmem120b',
  'tmem121',
  'tmem125',
  'tmem127',
  'tmem128',
  'tmem129',
  'tmem130',
  'tmem131',
  'tmem131l',
  'tmem132a',
  'tmem132b',
  'tmem132c',
  'tmem132d',
  'tmem132e',
  'tmem134',
  'tmem135',
  'tmem138',
  'tmem140',
  'tmem141',
  'tmem143',
  'tmem145',
  'tmem147',
  'tmem14a',
  'tmem14c',
  'tmem150a',
  'tmem150b',
  'tmem150c',
  'tmem151a',
  'tmem151b',
  'tmem158',
  'tmem160',
  'tmem161a',
  'tmem161b',
  'tmem163',
  'tmem164',
  'tmem165',
  'tmem167a',
  'tmem167b',
  'tmem168',
  'tmem169',
  'tmem17',
  'tmem170a',
  'tmem170b',
  'tmem174',
  'tmem177',
  'tmem178a',
  'tmem178b',
  'tmem179',
  'tmem179b',
  'tmem182',
  'tmem183a',
  'tmem184a',
  'tmem184b',
  'tmem184c',
  'tmem185a',
  'tmem185b',
  'tmem19',
  'tmem196',
  'tmem198',
  'tmem199',
  'tmem200a',
  'tmem200b',
  'tmem200c',
  'tmem201',
  'tmem203',
  'tmem204',
  'tmem205',
  'tmem208',
  'tmem209',
  'tmem213',
  'tmem214',
  'tmem215',
  'tmem216',
  'tmem219',
  'tmem222',
  'tmem223',
  'tmem229b',
  'tmem230',
  'tmem231',
  'tmem233',
  'tmem234',
  'tmem237',
  'tmem239',
  'tmem240',
  'tmem242',
  'tmem243',
  'tmem245',
  'tmem248',
  'tmem249',
  'tmem25',
  'tmem250',
  'tmem251',
  'tmem253',
  'tmem254',
  'tmem255a',
  'tmem256',
  'tmem258',
  'tmem259',
  'tmem260',
  'tmem262',
  'tmem263',
  'tmem265',
  'tmem266',
  'tmem267',
  'tmem268',
  'tmem271',
  'tmem275',
  'tmem30a',
  'tmem30b',
  'tmem33',
  'tmem35a',
  'tmem35b',
  'tmem37',
  'tmem38a',
  'tmem38b',
  'tmem39a',
  'tmem39b',
  'tmem41a',
  'tmem41b',
  'tmem42',
  'tmem44',
  'tmem45a',
  'tmem47',
  'tmem50a',
  'tmem50b',
  'tmem51',
  'tmem53',
  'tmem54',
  'tmem59',
  'tmem59l',
  'tmem60',
  'tmem62',
  'tmem63a',
  'tmem63b',
  'tmem63c',
  'tmem64',
  'tmem65',
  'tmem67',
  'tmem68',
  'tmem72',
  'tmem74',
  'tmem74b',
  'tmem79',
  'tmem82',
  'tmem86a',
  'tmem86b',
  'tmem87a',
  'tmem88',
  'tmem89',
  'tmem8b',
  'tmem9',
  'tmem91',
  'tmem94',
  'tmem95',
  'tmem97',
  'tmem98',
  'tmem9b',
  'tmf1',
  'tmie',
  'tmod1',
  'tmod2',
  'tmod3',
  'tmod4',
  'tmpo',
  'tmppe',
  'tmprss3',
  'tmprss5',
  'tmprss6',
  'tmprss7',
  'tmprss9',
  'tmsb10',
  'tmsb15a',
  'tmsb15b',
  'tmsb4x',
  'tmtc1',
  'tmtc2',
  'tmtc3',
  'tmtc4',
  'tmub1',
  'tmub2',
  'tmx1',
  'tmx2',
  'tnc',
  'tnf',
  'tnfaip1',
  'tnfaip2',
  'tnfaip3',
  'tnfaip6',
  'tnfaip8',
  'tnfaip8l1',
  'tnfaip8l2',
  'tnfaip8l3',
  'tnfrsf11a',
  'tnfrsf11b',
  'tnfrsf12a',
  'tnfrsf13c',
  'tnfrsf21',
  'tnfrsf25',
  'tnfsf11',
  'tnfsf12',
  'tnfsf13',
  'tnfsf14',
  'tnfsf15',
  'tnfsf8',
  'tnik',
  'tnip1',
  'tnip2',
  'tnk1',
  'tnk2',
  'tnks',
  'tnks1bp1',
  'tnks2',
  'tnmd',
  'tnn',
  'tnnc1',
  'tnnc2',
  'tnni1',
  'tnni2',
  'tnni3',
  'tnni3k',
  'tnnt1',
  'tnnt2',
  'tnp1',
  'tnpo1',
  'tnpo2',
  'tnpo3',
  'tnr',
  'tnrc18',
  'tnrc6a',
  'tnrc6b',
  'tnrc6c',
  'tns1',
  'tns2',
  'tns3',
  'tns4',
  'tnxb',
  'tob1',
  'tob2',
  'toe1',
  'togaram1',
  'tollip',
  'tom1',
  'tom1l2',
  'tomm20',
  'tomm20l',
  'tomm22',
  'tomm34',
  'tomm40',
  'tomm40l',
  'tomm5',
  'tomm6',
  'tomm7',
  'tomm70',
  'tomt',
  'tonsl',
  'top1',
  'top2a',
  'top2b',
  'top3a',
  'top3b',
  'topbp1',
  'tor1a',
  'tor1b',
  'tor2a',
  'tor3a',
  'tor4a',
  'tox',
  'tox2',
  'tox3',
  'tox4',
  'tp53',
  'tp53bp1',
  'tp53bp2',
  'tp53i11',
  'tp53i13',
  'tp53i3',
  'tp53inp1',
  'tp53inp2',
  'tp53rk',
  'tp63',
  'tp73',
  'tpbgl',
  'tpcn1',
  'tpd52',
  'tpd52l1',
  'tpd52l2',
  'tpgs1',
  'tpgs2',
  'tph1',
  'tph2',
  'tpi1',
  'tpk1',
  'tpm1',
  'tpm2',
  'tpm3',
  'tpm4',
  'tpp1',
  'tpp2',
  'tppp',
  'tppp2',
  'tppp3',
  'tpr',
  'tpra1',
  'tprg1',
  'tprg1l',
  'tprkb',
  'tpsab1',
  'tpsb2',
  'tpsd1',
  'tpst2',
  'tpt1',
  'tpx2',
  'tra2a',
  'tra2b',
  'trabd',
  'trabd2b',
  'tradd',
  'traf1',
  'traf2',
  'traf3',
  'traf3ip2',
  'traf3ip3',
  'traf4',
  'traf6',
  'traf7',
  'trafd1',
  'traip',
  'trak1',
  'trak2',
  'tram1',
  'tram2',
  'trank1',
  'trap1',
  'trappc1',
  'trappc10',
  'trappc11',
  'trappc13',
  'trappc14',
  'trappc2',
  'trappc2b',
  'trappc2l',
  'trappc3',
  'trappc3l',
  'trappc4',
  'trappc5',
  'trappc6a',
  'trappc6b',
  'trappc8',
  'trappc9',
  'treh',
  'trem2',
  'treml1',
  'trerf1',
  'trex1',
  'trex2',
  'trhde',
  'trhr',
  'triap1',
  'trib1',
  'trib2',
  'trib3',
  'tril',
  'trim11',
  'trim14',
  'trim16',
  'trim2',
  'trim21',
  'trim23',
  'trim24',
  'trim27',
  'trim28',
  'trim29',
  'trim3',
  'trim32',
  'trim33',
  'trim34',
  'trim35',
  'trim36',
  'trim37',
  'trim39',
  'trim41',
  'trim42',
  'trim45',
  'trim46',
  'trim47',
  'trim50',
  'trim54',
  'trim55',
  'trim56',
  'trim6',
  'trim62',
  'trim63',
  'trim65',
  'trim66',
  'trim67',
  'trim69',
  'trim7',
  'trim71',
  'trim72',
  'trim73',
  'trim74',
  'trim8',
  'trim9',
  'trio',
  'trip10',
  'trip12',
  'trip13',
  'trip4',
  'trip6',
  'triqk',
  'trir',
  'trit1',
  'trmo',
  'trmt1',
  'trmt10a',
  'trmt10b',
  'trmt11',
  'trmt112',
  'trmt13',
  'trmt1l',
  'trmt2a',
  'trmt5',
  'trmt6',
  'trmt61a',
  'trmu',
  'trnau1ap',
  'trnp1',
  'trnt1',
  'trpc1',
  'trpc3',
  'trpc4',
  'trpc4ap',
  'trpc5',
  'trpc6',
  'trpc7',
  'trpm2',
  'trpm3',
  'trpm4',
  'trpm5',
  'trpm7',
  'trpm8',
  'trps1',
  'trpt1',
  'trpv1',
  'trpv2',
  'trpv3',
  'trpv4',
  'trpv5',
  'trpv6',
  'trrap',
  'trub1',
  'trub2',
  'tsacc',
  'tsc1',
  'tsc2',
  'tsc22d2',
  'tsc22d3',
  'tsc22d4',
  'tsen15',
  'tsen34',
  'tsen54',
  'tsfm',
  'tsg101',
  'tsga10',
  'tshb',
  'tshr',
  'tshz1',
  'tshz2',
  'tshz3',
  'tsks',
  'tsku',
  'tsn',
  'tsnax',
  'tsnaxip1',
  'tspan1',
  'tspan11',
  'tspan12',
  'tspan13',
  'tspan14',
  'tspan15',
  'tspan17',
  'tspan18',
  'tspan2',
  'tspan3',
  'tspan31',
  'tspan33',
  'tspan4',
  'tspan5',
  'tspan6',
  'tspan7',
  'tspan9',
  'tspear',
  'tspo',
  'tspo2',
  'tspoap1',
  'tspyl5',
  'tsr1',
  'tsr2',
  'tsr3',
  'tssc4',
  'tssk1b',
  'tssk2',
  'tssk3',
  'tssk4',
  'tssk6',
  'tst',
  'tstd1',
  'tstd2',
  'ttbk1',
  'ttbk2',
  'ttc1',
  'ttc13',
  'ttc14',
  'ttc17',
  'ttc19',
  'ttc21a',
  'ttc22',
  'ttc26',
  'ttc27',
  'ttc28',
  'ttc31',
  'ttc32',
  'ttc33',
  'ttc34',
  'ttc36',
  'ttc37',
  'ttc38',
  'ttc39a',
  'ttc39b',
  'ttc39c',
  'ttc4',
  'ttc5',
  'ttc7a',
  'ttc7b',
  'ttc8',
  'ttc9',
  'ttc9b',
  'ttc9c',
  'tti1',
  'ttl',
  'ttll1',
  'ttll11',
  'ttll12',
  'ttll13',
  'ttll4',
  'ttll5',
  'ttll7',
  'ttll9',
  'ttpa',
  'ttpal',
  'ttr',
  'ttyh1',
  'ttyh2',
  'ttyh3',
  'tub',
  'tuba1a',
  'tuba1b',
  'tuba1c',
  'tuba3c',
  'tuba3d',
  'tuba3e',
  'tuba4a',
  'tuba4b',
  'tuba8',
  'tubal3',
  'tubb',
  'tubb1',
  'tubb2a',
  'tubb2b',
  'tubb3',
  'tubb4a',
  'tubb4b',
  'tubb6',
  'tubb8',
  'tubb8b',
  'tubd1',
  'tube1',
  'tubg1',
  'tubg2',
  'tubgcp2',
  'tubgcp3',
  'tubgcp4',
  'tubgcp5',
  'tufm',
  'tuft1',
  'tulp1',
  'tulp4',
  'tusc1',
  'tusc2',
  'tusc3',
  'tut1',
  'tut4',
  'tvp23a',
  'tvp23b',
  'tvp23c',
  'twf1',
  'twf2',
  'twist1',
  'twist2',
  'twnk',
  'twsg1',
  'txlna',
  'txlng',
  'txn',
  'txn2',
  'txndc11',
  'txndc12',
  'txndc17',
  'txndc5',
  'txndc9',
  'txnip',
  'txnl1',
  'txnl4a',
  'txnl4b',
  'txnrd1',
  'tyk2',
  'tymp',
  'tyms',
  'tyr',
  'tyro3',
  'tyrp1',
  'tysnd1',
  'tyw1b',
  'tyw5',
  'u2af1',
  'u2af2',
  'u2surp',
  'uap1',
  'uap1l1',
  'uba1',
  'uba2',
  'uba3',
  'uba5',
  'uba52',
  'uba6',
  'uba7',
  'ubac1',
  'ubac2',
  'ubald1',
  'ubald2',
  'ubap1',
  'ubap1l',
  'ubap2',
  'ubap2l',
  'ubash3b',
  'ube2a',
  'ube2b',
  'ube2c',
  'ube2d1',
  'ube2d2',
  'ube2d3',
  'ube2e1',
  'ube2e2',
  'ube2e3',
  'ube2g1',
  'ube2g2',
  'ube2h',
  'ube2i',
  'ube2j1',
  'ube2j2',
  'ube2k',
  'ube2l3',
  'ube2l5',
  'ube2l6',
  'ube2m',
  'ube2n',
  'ube2o',
  'ube2q1',
  'ube2q2',
  'ube2ql1',
  'ube2r2',
  'ube2s',
  'ube2v1',
  'ube2v2',
  'ube2w',
  'ube2z',
  'ube3a',
  'ube3b',
  'ube3c',
  'ube4a',
  'ube4b',
  'ubfd1',
  'ubiad1',
  'ubl3',
  'ubl4a',
  'ubl4b',
  'ubl5',
  'ubl7',
  'ublcp1',
  'ubn1',
  'ubn2',
  'ubox5',
  'ubp1',
  'ubqln1',
  'ubqln2',
  'ubqln3',
  'ubqln4',
  'ubr1',
  'ubr2',
  'ubr3',
  'ubr4',
  'ubr5',
  'ubr7',
  'ubtd1',
  'ubtd2',
  'ubtf',
  'ubxn1',
  'ubxn10',
  'ubxn2b',
  'ubxn4',
  'ubxn6',
  'ubxn7',
  'uchl1',
  'uchl3',
  'uchl5',
  'uck1',
  'uck2',
  'uckl1',
  'ucn',
  'ucp1',
  'ucp2',
  'ucp3',
  'uevld',
  'ufc1',
  'ufd1',
  'ufl1',
  'ufm1',
  'ufsp1',
  'ufsp2',
  'ugcg',
  'ugdh',
  'uggt1',
  'ugp2',
  'ugt1a1',
  'ugt1a10',
  'ugt1a3',
  'ugt1a4',
  'ugt1a5',
  'ugt1a6',
  'ugt1a7',
  'ugt1a8',
  'ugt1a9',
  'ugt2a1',
  'ugt2a2',
  'ugt2b10',
  'ugt2b11',
  'ugt2b15',
  'ugt2b17',
  'ugt2b28',
  'ugt2b4',
  'ugt2b7',
  'ugt8',
  'uhmk1',
  'uhrf1',
  'uhrf1bp1',
  'uhrf1bp1l',
  'uhrf2',
  'uimc1',
  'ulk1',
  'ulk2',
  'ulk3',
  'ulk4',
  'umod',
  'umps',
  'unc119',
  'unc119b',
  'unc13a',
  'unc13c',
  'unc13d',
  'unc45a',
  'unc45b',
  'unc50',
  'unc5a',
  'unc5b',
  'unc5c',
  'unc5cl',
  'unc5d',
  'unc79',
  'unc80',
  'unc93b1',
  'ung',
  'unk',
  'upb1',
  'upf1',
  'upf2',
  'upf3b',
  'upk1a',
  'upk1b',
  'upk2',
  'upk3a',
  'upk3b',
  'uprt',
  'uqcc1',
  'uqcc2',
  'uqcr10',
  'uqcr11',
  'uqcrb',
  'uqcrc1',
  'uqcrc2',
  'uqcrh',
  'uqcrhl',
  'uqcrq',
  'urad',
  'urb1',
  'urm1',
  'uroc1',
  'urod',
  'uros',
  'usb1',
  'use1',
  'usf1',
  'usf2',
  'ush1c',
  'ush1g',
  'ush2a',
  'ushbp1',
  'uso1',
  'usp1',
  'usp10',
  'usp11',
  'usp12',
  'usp13',
  'usp14',
  'usp15',
  'usp16',
  'usp19',
  'usp2',
  'usp20',
  'usp21',
  'usp22',
  'usp24',
  'usp25',
  'usp27x',
  'usp28',
  'usp3',
  'usp30',
  'usp31',
  'usp32',
  'usp33',
  'usp34',
  'usp35',
  'usp37',
  'usp38',
  'usp39',
  'usp4',
  'usp43',
  'usp46',
  'usp47',
  'usp48',
  'usp49',
  'usp5',
  'usp54',
  'usp7',
  'usp8',
  'usp9x',
  'ust',
  'utp11',
  'utp14a',
  'utp15',
  'utp18',
  'utp20',
  'utp23',
  'utp25',
  'utp3',
  'utp4',
  'utrn',
  'uts2r',
  'uvrag',
  'uxs1',
  'uxt',
  'vac14',
  'vamp1',
  'vamp2',
  'vamp3',
  'vamp4',
  'vamp5',
  'vamp8',
  'vangl1',
  'vangl2',
  'vapa',
  'vapb',
  'vars1',
  'vars2',
  'vash1',
  'vash2',
  'vasn',
  'vasp',
  'vat1',
  'vat1l',
  'vav1',
  'vav2',
  'vav3',
  'vax1',
  'vax2',
  'vbp1',
  'vcam1',
  'vcl',
  'vcp',
  'vcpip1',
  'vcpkmt',
  'vdac1',
  'vdac2',
  'vdac3',
  'vdr',
  'vegfa',
  'vegfb',
  'vegfc',
  'vegfd',
  'veph1',
  'vezf1',
  'vezt',
  'vgf',
  'vgll2',
  'vgll3',
  'vgll4',
  'vhl',
  'vil1',
  'vill',
  'vim',
  'vipas39',
  'vipr1',
  'virma',
  'vit',
  'vkorc1',
  'vkorc1l1',
  'vldlr',
  'vma21',
  'vmac',
  'vmo1',
  'vmp1',
  'vnn1',
  'vopp1',
  'vpreb1',
  'vpreb3',
  'vps11',
  'vps13a',
  'vps13b',
  'vps13c',
  'vps13d',
  'vps16',
  'vps18',
  'vps25',
  'vps26a',
  'vps26b',
  'vps26c',
  'vps28',
  'vps29',
  'vps33a',
  'vps33b',
  'vps35',
  'vps35l',
  'vps36',
  'vps37a',
  'vps37b',
  'vps37c',
  'vps37d',
  'vps39',
  'vps41',
  'vps45',
  'vps4a',
  'vps4b',
  'vps50',
  'vps51',
  'vps52',
  'vps53',
  'vps54',
  'vps72',
  'vps8',
  'vrk1',
  'vrtn',
  'vsig10',
  'vsig10l',
  'vsig2',
  'vsig8',
  'vsir',
  'vsnl1',
  'vstm2a',
  'vstm2b',
  'vstm2l',
  'vstm5',
  'vsx1',
  'vsx2',
  'vta1',
  'vtcn1',
  'vti1a',
  'vti1b',
  'vwa2',
  'vwa5b1',
  'vwa5b2',
  'vwa7',
  'vwa8',
  'vwc2',
  'vwc2l',
  'vwce',
  'vwf',
  'wac',
  'wapl',
  'wars1',
  'wars2',
  'was',
  'wasf1',
  'wasf2',
  'wasf3',
  'washc1',
  'washc2a',
  'washc2c',
  'washc3',
  'washc4',
  'washc5',
  'wasl',
  'wbp1',
  'wbp11',
  'wbp1l',
  'wbp2',
  'wbp4',
  'wdfy1',
  'wdfy2',
  'wdfy3',
  'wdfy4',
  'wdhd1',
  'wdpcp',
  'wdr1',
  'wdr11',
  'wdr12',
  'wdr13',
  'wdr17',
  'wdr18',
  'wdr19',
  'wdr20',
  'wdr24',
  'wdr25',
  'wdr3',
  'wdr31',
  'wdr33',
  'wdr35',
  'wdr36',
  'wdr37',
  'wdr38',
  'wdr41',
  'wdr43',
  'wdr44',
  'wdr45',
  'wdr45b',
  'wdr46',
  'wdr47',
  'wdr48',
  'wdr5',
  'wdr53',
  'wdr54',
  'wdr55',
  'wdr59',
  'wdr5b',
  'wdr6',
  'wdr61',
  'wdr62',
  'wdr7',
  'wdr70',
  'wdr72',
  'wdr73',
  'wdr74',
  'wdr75',
  'wdr77',
  'wdr81',
  'wdr82',
  'wdr83',
  'wdr83os',
  'wdr86',
  'wdr89',
  'wdr91',
  'wdsub1',
  'wdtc1',
  'wee1',
  'wfdc1',
  'wfdc5',
  'wfikkn1',
  'wfikkn2',
  'wfs1',
  'whrn',
  'wif1',
  'wipf1',
  'wipf2',
  'wipi1',
  'wipi2',
  'wiz',
  'wls',
  'wnk1',
  'wnk2',
  'wnk3',
  'wnk4',
  'wnt1',
  'wnt10a',
  'wnt10b',
  'wnt11',
  'wnt16',
  'wnt2',
  'wnt2b',
  'wnt3',
  'wnt3a',
  'wnt4',
  'wnt5a',
  'wnt5b',
  'wnt6',
  'wnt7a',
  'wnt7b',
  'wnt8a',
  'wnt8b',
  'wnt9a',
  'wnt9b',
  'wrap53',
  'wrap73',
  'wrnip1',
  'wsb1',
  'wsb2',
  'wscd1',
  'wscd2',
  'wt1',
  'wtap',
  'wwc1',
  'wwc2',
  'wwox',
  'wwp1',
  'wwp2',
  'wwtr1',
  'xab2',
  'xbp1',
  'xcr1',
  'xdh',
  'xiap',
  'xirp1',
  'xk',
  'xkr4',
  'xkr6',
  'xkr7',
  'xkr8',
  'xkrx',
  'xpa',
  'xpnpep1',
  'xpnpep2',
  'xpnpep3',
  'xpo1',
  'xpo4',
  'xpo5',
  'xpo6',
  'xpo7',
  'xpot',
  'xpr1',
  'xrcc1',
  'xrcc6',
  'xrn1',
  'xrn2',
  'xrra1',
  'xxylt1',
  'xylb',
  'xylt1',
  'xylt2',
  'yae1',
  'yaf2',
  'yap1',
  'yars1',
  'yars2',
  'ybx1',
  'ybx2',
  'ybx3',
  'ydjc',
  'yeats2',
  'yeats4',
  'yes1',
  'yif1a',
  'yif1b',
  'yipf1',
  'yipf2',
  'yipf3',
  'yipf4',
  'yipf5',
  'yipf6',
  'yju2',
  'yju2b',
  'ykt6',
  'ylpm1',
  'yme1l1',
  'yod1',
  'ypel1',
  'ypel2',
  'ypel3',
  'ypel4',
  'ypel5',
  'yrdc',
  'ythdc1',
  'ythdc2',
  'ythdf1',
  'ythdf2',
  'ythdf3',
  'ywhab',
  'ywhae',
  'ywhag',
  'ywhah',
  'ywhaq',
  'ywhaz',
  'yy1',
  'zadh2',
  'zap70',
  'zbed2',
  'zbed6',
  'zbed8',
  'zbtb10',
  'zbtb11',
  'zbtb16',
  'zbtb17',
  'zbtb18',
  'zbtb2',
  'zbtb20',
  'zbtb21',
  'zbtb22',
  'zbtb24',
  'zbtb25',
  'zbtb26',
  'zbtb3',
  'zbtb32',
  'zbtb33',
  'zbtb34',
  'zbtb37',
  'zbtb38',
  'zbtb39',
  'zbtb4',
  'zbtb40',
  'zbtb41',
  'zbtb42',
  'zbtb43',
  'zbtb44',
  'zbtb45',
  'zbtb46',
  'zbtb47',
  'zbtb48',
  'zbtb49',
  'zbtb5',
  'zbtb6',
  'zbtb7a',
  'zbtb7b',
  'zbtb7c',
  'zbtb8b',
  'zbtb9',
  'zc2hc1a',
  'zc2hc1b',
  'zc3h11a',
  'zc3h12a',
  'zc3h12b',
  'zc3h12c',
  'zc3h12d',
  'zc3h13',
  'zc3h14',
  'zc3h15',
  'zc3h18',
  'zc3h3',
  'zc3h4',
  'zc3h6',
  'zc3h7a',
  'zc3h7b',
  'zc3h8',
  'zc3hav1l',
  'zc3hc1',
  'zc4h2',
  'zcchc10',
  'zcchc12',
  'zcchc14',
  'zcchc17',
  'zcchc18',
  'zcchc2',
  'zcchc24',
  'zcchc4',
  'zcchc8',
  'zcchc9',
  'zcrb1',
  'zdhhc1',
  'zdhhc12',
  'zdhhc13',
  'zdhhc14',
  'zdhhc15',
  'zdhhc16',
  'zdhhc17',
  'zdhhc19',
  'zdhhc2',
  'zdhhc20',
  'zdhhc21',
  'zdhhc22',
  'zdhhc23',
  'zdhhc24',
  'zdhhc3',
  'zdhhc5',
  'zdhhc6',
  'zdhhc7',
  'zdhhc8',
  'zdhhc9',
  'zeb1',
  'zeb2',
  'zer1',
  'zfand1',
  'zfand2b',
  'zfand3',
  'zfand5',
  'zfand6',
  'zfat',
  'zfc3h1',
  'zfhx2',
  'zfhx3',
  'zfhx4',
  'zfp14',
  'zfp2',
  'zfp3',
  'zfp30',
  'zfp36',
  'zfp36l1',
  'zfp64',
  'zfp69',
  'zfp82',
  'zfp90',
  'zfp91',
  'zfpl1',
  'zfpm2',
  'zfr',
  'zfta',
  'zfx',
  'zfyve1',
  'zfyve19',
  'zfyve26',
  'zfyve27',
  'zfyve28',
  'zfyve9',
  'zgpat',
  'zhx3',
  'zic1',
  'zic2',
  'zic3',
  'zic4',
  'zic5',
  'zkscan1',
  'zkscan2',
  'zkscan5',
  'zkscan8',
  'zmat2',
  'zmat3',
  'zmat4',
  'zmat5',
  'zmiz1',
  'zmiz2',
  'zmpste24',
  'zmym2',
  'zmym3',
  'zmym4',
  'zmym5',
  'zmym6',
  'zmynd10',
  'zmynd11',
  'zmynd12',
  'zmynd15',
  'zmynd19',
  'zmynd8',
  'znf106',
  'znf12',
  'znf131',
  'znf133',
  'znf135',
  'znf136',
  'znf14',
  'znf140',
  'znf142',
  'znf143',
  'znf148',
  'znf154',
  'znf155',
  'znf174',
  'znf175',
  'znf18',
  'znf180',
  'znf182',
  'znf184',
  'znf189',
  'znf20',
  'znf202',
  'znf205',
  'znf207',
  'znf212',
  'znf219',
  'znf221',
  'znf224',
  'znf225',
  'znf236',
  'znf24',
  'znf25',
  'znf250',
  'znf26',
  'znf260',
  'znf263',
  'znf276',
  'znf277',
  'znf280c',
  'znf280d',
  'znf282',
  'znf283',
  'znf286a',
  'znf287',
  'znf292',
  'znf296',
  'znf3',
  'znf317',
  'znf318',
  'znf319',
  'znf330',
  'znf335',
  'znf34',
  'znf341',
  'znf343',
  'znf345',
  'znf346',
  'znf35',
  'znf358',
  'znf362',
  'znf365',
  'znf366',
  'znf367',
  'znf382',
  'znf383',
  'znf384',
  'znf385a',
  'znf385b',
  'znf385c',
  'znf385d',
  'znf395',
  'znf397',
  'znf398',
  'znf408',
  'znf41',
  'znf410',
  'znf414',
  'znf420',
  'znf423',
  'znf428',
  'znf433',
  'znf436',
  'znf44',
  'znf440',
  'znf442',
  'znf444',
  'znf446',
  'znf449',
  'znf45',
  'znf451',
  'znf454',
  'znf460',
  'znf461',
  'znf462',
  'znf467',
  'znf471',
  'znf491',
  'znf496',
  'znf500',
  'znf501',
  'znf502',
  'znf503',
  'znf507',
  'znf512',
  'znf512b',
  'znf513',
  'znf516',
  'znf521',
  'znf524',
  'znf526',
  'znf527',
  'znf532',
  'znf536',
  'znf540',
  'znf541',
  'znf550',
  'znf555',
  'znf564',
  'znf565',
  'znf566',
  'znf567',
  'znf568',
  'znf569',
  'znf570',
  'znf571',
  'znf572',
  'znf573',
  'znf574',
  'znf575',
  'znf576',
  'znf579',
  'znf580',
  'znf581',
  'znf582',
  'znf583',
  'znf586',
  'znf592',
  'znf593',
  'znf598',
  'znf599',
  'znf605',
  'znf606',
  'znf609',
  'znf613',
  'znf615',
  'znf618',
  'znf621',
  'znf622',
  'znf627',
  'znf641',
  'znf644',
  'znf646',
  'znf652',
  'znf653',
  'znf654',
  'znf655',
  'znf660',
  'znf664',
  'znf668',
  'znf687',
  'znf689',
  'znf69',
  'znf691',
  'znf692',
  'znf70',
  'znf703',
  'znf704',
  'znf706',
  'znf709',
  'znf71',
  'znf710',
  'znf711',
  'znf740',
  'znf746',
  'znf747',
  'znf75a',
  'znf75d',
  'znf76',
  'znf764',
  'znf768',
  'znf771',
  'znf773',
  'znf774',
  'znf777',
  'znf783',
  'znf784',
  'znf787',
  'znf79',
  'znf790',
  'znf791',
  'znf80',
  'znf800',
  'znf81',
  'znf821',
  'znf823',
  'znf827',
  'znf829',
  'znf830',
  'znf84',
  'znf844',
  'znf862',
  'znf875',
  'znf878',
  'znf879',
  'znf883',
  'znfx1',
  'znhit1',
  'znhit2',
  'znhit3',
  'znrd2',
  'znrf1',
  'znrf2',
  'zpld1',
  'zpr1',
  'zranb1',
  'zranb2',
  'zranb3',
  'zscan10',
  'zscan2',
  'zscan20',
  'zscan21',
  'zscan23',
  'zscan25',
  'zscan29',
  'zscan9',
  'zswim1',
  'zswim3',
  'zswim4',
  'zswim5',
  'zswim6',
  'zswim7',
  'zswim8',
  'zswim9',
  'zup1',
  'zw10',
  'zwilch',
  'zyg11a',
  'zyg11b',
  'zyx',
  'zzef1',
  'zzz3'
];

export default GoodGenes;
