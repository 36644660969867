import React from 'react';

function ShowDiffsIcon(props: any) {
  const isDiffsIcon = props.isDiffsIcon !== undefined ? props.isDiffsIcon : false;
  const isActive = props.isActive !== undefined ? props.isActive : true;


  return (
    <div    style={{  padding: 0,
                      //border: isActive ? 'solid 4px #72D1FD' : 'solid 4px #EFEFEF',
                      height: 48,
                      borderRadius: 10
              }}
            className="divButtonHover"
    >
      
      <svg id="fullActive" height="48" width="48" style={{display: !isDiffsIcon && isActive ? 'block' : 'none'}}>
        <rect x={1} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={33} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />

        <rect x={1} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={33} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />

        <rect x={1} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={33} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
      </svg>

      <svg id="fullInactive" height="48" width="48" style={{display: !isDiffsIcon && !isActive ? 'block' : 'none'}}>
        <rect x={1} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={17} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#434343" />
        <rect x={33} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />

        <rect x={1} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={17} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={33} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#434343" />

        <rect x={1} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={17} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#434343" />
        <rect x={33} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
      </svg>


      <svg id="dotsActive" height="48" width="48" style={{display: isDiffsIcon && isActive ? 'block' : 'none'}}>
        <rect x={1} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={33} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />

        <rect x={4} y={20} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={33} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />

        <rect x={4} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={20} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={36} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#F2BF3D" />
      </svg>

      <svg id="dotsInactive" height="48" width="48" style={{display: isDiffsIcon && !isActive ? 'block' : 'none'}}>
        <rect x={1} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={17} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#434343" />
        <rect x={33} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />

        <rect x={4} y={20} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={17} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={33} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#434343" />

        <rect x={4} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#B7B7B7" />
        <rect x={20} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#434343" />
        <rect x={36} y={36} width={7} height={7} rx={15} stroke="black" strokeWidth={1} fill="#B7B7B7" />
      </svg>
    </div>
  )
}

export default ShowDiffsIcon;
