import React from "react";
import { FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import LogoTransparent from '../../images/LogoTransparent.png';
import SmallLogo from '../../images/SmallLogo.svg';
import GeneSearch from "./GeneSearch";
import FlipCard from "../../components/FlipCard";
import gene_profile_draft1 from '../../images/gene_profile_draft1.svg';
import variantFilter_icon from '../../images/variantFilter_icon.svg';
import compgen_icon from '../../images/compgen_icon.svg';
import functional_icon from '../../images/functional_icon.svg';
import LabScientists from '../../images/LabScientists.jpg';
import DNASearch from '../../images/DNASearch.svg';
import DoctorDNAHologram from '../../images/DoctorDNAHologram.png';
import LicenseAgreementModal from "../../components/LicenseAgreementModal";

export default function Home() {

  const [flip1Open, setflip1Open] = React.useState<boolean>(false);
  const [flip2Open, setflip2Open] = React.useState<boolean>(false);
  const [flip3Open, setflip3Open] = React.useState<boolean>(false);

  const [viewLicenseAgreement, setViewLicenseAgreement] = React.useState<boolean>(false);

  const cxmColorsBlue = "#03537A";
  const cxmColorsBrown = "#945232";
  const cxmColorsSeparator = "#DDD";

  React.useEffect(() => {document.body.style.overflow = viewLicenseAgreement ? "hidden" : "unset"},[viewLicenseAgreement]);

  const CodeXomeAndRegisterMark = (props: any) => {
    const fontSize = props.fontSize ? props.fontSize : 14;

    return (
      <span>
        <span style={{fontSize: fontSize}}>CodeXome</span><span style={{fontSize: Math.floor(fontSize * 0.85), marginLeft: 2}}>&reg;</span>
      </span>
    )
  }

  const ExploreDatabaseButton = (props: any) => {
    return (
      <div style={{position: 'relative', display: 'block', width: 282, marginLeft: 'auto', marginRight: 'auto', marginBottom: 50, marginTop: 50}}>
        <a href="#ExploreGenes" style={{    color: 'white', 
                                            textDecorationLine: 'none', 
                                            marginTop: 35,
                                            //marginLeft: -141,
                                            backgroundColor: props.bgColor ? props.bgColor : cxmColorsBlue, 
                                            borderRadius: 10, 
                                            border: 'solid 1px #EFEFEF', 
                                            padding: 15,
                                            fontSize: 16,
                                            position: 'absolute',
                                            width: 'fit-content'}}>Explore the <CodeXomeAndRegisterMark fontSize={16} /> Database</a>
      </div>
    )
  }

  return (
    // <div style={{width: '100%', height: '100%', backgroundColor: 'whitesmoke', padding: 0, margin: 0, filter:`${viewTerms === false ? 'blur(0)' : 'blur(8px)'}`}}>
    <div style={{width: '100%', height: '100%', backgroundColor: 'whitesmoke', padding: 0, margin: 0, overflow: `${viewLicenseAgreement === false ? '' : 'hidden'}` }}>
      <table cellPadding={0} cellSpacing={0} style={{width: '100%', height: '100%', padding: 0, margin: 0, borderCollapse: 'collapse'}}>
        <tbody>
          <tr>
            <td style={{        border: 'solid 0px red', 
                                backgroundColor: '#769EAC',
                                
                                }}>
              <div style={{     width: 1100, 
                                height: 450,
                                marginLeft: 'auto', 
                                marginRight: 'auto', 
                                background: `url(${DoctorDNAHologram})`, 
                                backgroundSize: 'cover', 
                                backgroundPositionX: 60,
                                backgroundPositionY: 10,
                                backgroundRepeat: 'no-repeat'
                          }}>

                <div style={{width: '100%', margin: 0, height: 70}}>

                  <div style={{width: '100%', height: 100}}>
                    <div style={{float: 'left', padding: 15, borderRadius: 5, border: 'solid 0px green', backgroundColor: "#BBB", marginTop: 10, marginLeft: 10}}>
                      <img  src={LogoTransparent} 
                            alt="Logo"
                            width='284px'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com";
                            }} 
                      />
                    </div>
                    <div style={{float: 'right', border: 'solid 0px green', marginTop: 9, marginRight: 10}}>
                      <div style={{   
                                      backgroundColor: cxmColorsBlue, 
                                      fontFamily: 'Arial', 
                                      fontSize: 12,
                                      float: 'right', 
                                      width: 'fit-content', 
                                      //border: 'solid 1px #03537A', 
                                      border: 'solid 1px white',
                                      borderRadius: 10, 
                                      paddingTop: 17, 
                                      paddingBottom: 17,
                                      paddingLeft: 70,
                                      paddingRight: 70,
                                      marginTop: 17,
                                      color: 'white',
                                      cursor: 'pointer'
                                      }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com/contact-us/";
                            }}
                      >
                        CONTACT
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: 0, paddingTop: 0}}>
                  <div style={{width: 990, marginLeft: 10, marginTop: 0,  padding: 0, paddingTop: 0, paddingBottom: 0}}>
                    <p className="headerWithShadow">
                      Every gene has a story to tell.<br/>
                      <CodeXomeAndRegisterMark fontSize={32} /> helps you tell it faster.
                    </p>
                    <p className="mediumParagraph">
                      <CodeXomeAndRegisterMark fontSize={17} /> empowers researchers to examine the 
                      context<br /> of DNA variation
                      across 80 million years of evolution<br />
                      <span style={{fontSize: 20}}>in less than 8 seconds.</span>
                    </p>

                    <div style={{marginTop: 0}}>
                      <ExploreDatabaseButton />
                    </div>
                  </div>
                </div>
              </div>
            

            </td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsBlue}}>
              <div style={{backgroundColor: cxmColorsBlue, width: 1100, height: 550, marginLeft: 'auto', marginRight: 'auto', paddingTop: 20, paddingBottom: 20}}>

                <div style={{width: '100%', height: 'fit-content', marginTop: 25, padding: 0, marginLeft: 90}}>
                  <p className="headerWithShadow" style={{color: 'white', textAlign: 'left'}}>Answers to Disease Can't Wait</p>
                  <p style={{borderBottom: 'solid 4px white', width: 100}}></p>

                  <p className="subHeader">But it takes time to...</p>
                </div>

                <div style={{width: 1006, fontSize: 18, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: 25, padding: 0, border: 'solid 0px red'}}>
                  <div style={{position: 'relative', height: 270}}>
                    <div  className="divFlipHoverRightCorner" 
                          style={{  display: flip1Open ? 'none' : 'block', 
                                    position: 'absolute', 
                                    borderRadius: 10, 
                                    width: 250,
                                    height: 225,  
                                    padding: 20, 
                                    left: 42,
                                    top: 0,
                                    backgroundColor: '#EEE', 
                                    border: 'solid 2px #999',
                                    cursor: 'pointer'
                                  }}
                          onClick={() => {
                            setflip1Open(true);
                          }}
                      >
                      <p>
                        Search for ALL<br/>Available Genetic Data
                      </p>
                      <img src={DNASearch} width={75} alt="Search Genetic Data" />
                      <p style={{fontSize: 14}}>
                        What can you do with 80 million years of genetic data at your fingertips?
                      </p>
                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: 254, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderRight: `solid 1px ${cxmColorsBlue}`,
                                    borderLeft: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomLeftRadius: 10,
                                    height: 35, width: 35,
                                  }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M1.2,3 L1.2,27 A6.5,6.5 0 0,0 7,33.7  L32,33.7 Z" stroke="#DDD" strokeWidth={2} fill="#DDD" />
                        </svg>
                      </div>
                    </div>
                    <div  className="divFlipHoverLeftCorner" 
                          style={{  display: flip1Open ? 'block' : 'none', 
                                    position: 'absolute', 
                                    borderRadius: 10, 
                                    width: 250,
                                    height: 225,  
                                    padding: 20, 
                                    paddingTop: 40,
                                    left: 42,
                                    top: 0,
                                    backgroundColor: '#DDD', 
                                    border: 'solid 2px #999', 
                                    textAlign: 'left', 
                                    cursor: 'pointer'}}
                          onClick={() => {
                            setflip1Open(false);
                          }}
                      >
                      <p style={{fontSize: 14, marginTop: 10}}>
                        <CodeXomeAndRegisterMark fontSize={14} /> accelerates your research by:
                      </p>
                      <ul style={{fontSize: 14}}>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Identifying and comparing fast or slow evolving genes</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Distinguishing conserved and variable domains for diagnostic testing</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Creating species-specific markers for conservation biology</li>
                      </ul>

                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: -2, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderLeft: `solid 1px ${cxmColorsBlue}`,
                                    borderRight: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomRightRadius: 10,
                                    height: 35, width: 35,
                                  }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M33.7,3 L33.7,27 A6.8,6.8 0 0,1 27,33.7 L2,33.7 Z" stroke="#EEE" strokeWidth={2} fill="#EEE" />
                        </svg>
                      </div>
                    </div>

                    <div  className="divFlipHoverRightCorner" 
                          style={{  display: flip2Open ? 'none' : 'block', 
                                    position: 'absolute', 
                                    borderRadius: 10, 
                                    width: 250,
                                    height: 225,  
                                    padding: 20, 
                                    left: 377,
                                    top: 0,
                                    backgroundColor: '#EEE', 
                                    border: 'solid 2px #999',
                                    cursor: 'pointer'
                                  }}
                          onClick={() => {
                            setflip2Open(true);
                          }}
                      >
                      <p>
                        Filter Millions<br/>of Variant Sites
                      </p>
                      <img src={variantFilter_icon} style={{visibility: 'hidden'}} width={75} alt="Filter Variants" />
                        <img style={{position: 'absolute', top: 95, left: 107.5}} width={75} src={variantFilter_icon} alt="Variant Filtering" />
                        <img style={{position: 'absolute', top: 95, left: 108}} width={75} src={variantFilter_icon} alt="Variant Filtering" />
                        <img style={{position: 'absolute', top: 95, left: 108.5}} width={75} src={variantFilter_icon} alt="Variant Filtering" />

                      <p style={{fontSize: 14, marginTop: 30}}>
                        Context is everything.
                      </p>

                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: 254, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderRight: `solid 1px ${cxmColorsBlue}`,
                                    borderLeft: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomLeftRadius: 10,
                                    cursor: 'pointer',
                                    height: 35, width: 35,
                                  }}
                            onClick={() => {
                              setflip2Open(true);
                            }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M1.2,3 L1.2,27 A6.5,6.5 0 0,0 7,33.7  L32,33.7 Z" stroke="#DDD" strokeWidth={2} fill="#DDD" />
                        </svg>
                      </div>
                    </div>
                    <div  className="divFlipHoverLeftCorner" 
                          style={{  display: flip2Open ? 'block' : 'none', 
                                    position: 'absolute', 
                                    width: 250,
                                    height: 225,  
                                    padding: 20, 
                                    paddingTop: 40,
                                    left: 377,
                                    top: 0,
                                    backgroundColor: '#DDD', 
                                    border: 'solid 2px #999', 
                                    borderRadius: 10,
                                    textAlign: 'left', 
                                    cursor: 'pointer'}}
                          onClick={() => {
                            setflip2Open(false);
                          }}
                      >
                      <p style={{fontSize: 14, marginTop: 10}}>
                        <CodeXomeAndRegisterMark fontSize={14} /> accelerates your research by:
                      </p>
                      <ul style={{fontSize: 14}}>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Revealing the functional impact of each variant by rapidly classifying and filtering your data</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Creating lists of variants linked solely with your population</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Visualizing these variants in their evolutionary context</li>
                      </ul>
                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: -2, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderLeft: `solid 1px ${cxmColorsBlue}`,
                                    borderRight: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomRightRadius: 10,
                                    height: 35, width: 35,
                                  }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M33.7,3 L33.7,27 A6.8,6.8 0 0,1 27,33.7 L2,33.7 Z" stroke="#EEE" strokeWidth={2} fill="#EEE" />
                        </svg>
                      </div>
                    </div>

                    <div  className="divFlipHoverRightCorner" 
                          style={{  display: flip3Open ? 'none' : 'block', 
                                    position: 'absolute', 
                                    borderRadius: 10, 
                                    width: 250,
                                    height: 225, 
                                    left: 712,
                                    top: 0,
                                    padding: 20, 
                                    backgroundColor: '#EEE', 
                                    border: 'solid 2px #999',
                                    cursor: 'pointer'
                                  }}
                          onClick={() => {
                            setflip3Open(true);
                          }}
                      >
                      <p>
                        Analyze Data and Determine<br/>its Functionality
                      </p>
                      <img src={functional_icon} width={91} alt="Search Genetic Data" />
                      <p style={{fontSize: 14}}>
                        Capture the natural, functional limits of each gene.
                      </p>

                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: 254, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderRight: `solid 1px ${cxmColorsBlue}`,
                                    borderLeft: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomLeftRadius: 10,
                                    height: 35, width: 35,
                                  }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M1.2,3 L1.2,27 A6.5,6.5 0 0,0 7,33.7  L32,33.7 Z" stroke="#DDD" strokeWidth={2} fill="#DDD" />
                        </svg>
                      </div>
                    </div>
                    <div  className="divFlipHoverLeftCorner" 
                          style={{  display: flip3Open ? 'block' : 'none', 
                                    position: 'absolute', 
                                    width: 250,  
                                    height: 225,  
                                    padding: 20, 
                                    left: 712,
                                    top: 0,
                                    paddingTop: 40,
                                    backgroundColor: '#DDD', 
                                    border: 'solid 2px #999', 
                                    borderRadius: 10,
                                    textAlign: 'left', 
                                    cursor: 'pointer'}}
                          onClick={() => {
                            setflip3Open(false);
                          }}
                      >
                      <p style={{fontSize: 14, marginTop: 10}}>
                        <CodeXomeAndRegisterMark fontSize={14} /> accelerates your research by:
                      </p>
                      <ul style={{fontSize: 14, marginTop: -2}}>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Precisely defining functional motifs for protein engineering</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Combining variant information with structural and functional elements</li>
                        <li style={{marginLeft: -15, marginBottom: 10}}>Assessing the impact of a given mutation on a patient cohort<br/><br/><br/><br/></li>
                      </ul>

                      <div style={{ position: 'absolute', padding: 0,
                                    top: -2, left: -2, 
                                    backgroundColor: cxmColorsBlue, 
                                    borderTop: `solid 1px ${cxmColorsBlue}`, borderLeft: `solid 1px ${cxmColorsBlue}`,
                                    borderRight: 'solid 2px #999', borderBottom: 'solid 2px #999',
                                    borderBottomRightRadius: 10,
                                    cursor: 'pointer',
                                    height: 35, width: 35,
                                  }}
                            onClick={() => {
                              setflip3Open(false);
                            }}
                        >
                        <svg height={50} width={50} style={{position: 'absolute', top: 0, left: 0, display: 'block'}}>
                          <path d="M33.7,3 L33.7,27 A6.8,6.8 0 0,1 27,33.7 L2,33.7 Z" stroke="#EEE" strokeWidth={2} fill="#EEE" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{width: '100%', height: 'fit-content', marginTop: 40, padding: 0, marginLeft: 90}}>
                  <p className="subHeader">
                    That's why we did it for you, saving your research and
                    development team time and money.
                  </p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: 'white'}}>
              <div style={{backgroundColor: 'white', width: 1100, height: 460, marginLeft: 'auto', marginRight: 'auto', paddingTop: 20, paddingBottom: 40}}>

                <div style={{width: '100%', height: 'fit-content', marginTop: 25, padding: 0}}>
                  <p className="headerWithShadow" style={{textAlign: 'left', marginLeft: 90, color: '#333', textShadow: '2px 2px 2px #CCC'}}>Accelerate Your Research with <CodeXomeAndRegisterMark fontSize={32} /></p>
                </div>
                <p style={{borderBottom: 'solid 4px #333', width: 100, marginLeft: 90, marginTop: -10, marginBottom: 30}}></p>

                <div style={{position: 'relative', height: 300, paddingLeft: 90, paddingRight: 90}}>
                  <div style={{float: 'left', width: 420}}>
                    <p className="mediumParagraph" style={{color: '#333'}}>
                      <CodeXomeAndRegisterMark fontSize={17} /> helps researchers quickly and precisely cut through dense, noisy data using revolutionary digital analytical software.
                    </p>
                    <p className="mediumParagraph" style={{color: '#333'}}>
                      You'll overcome the challenges associated with identifying the genetic changes underlying a particular trait or disease. 
                    </p>
                    <p className="mediumParagraph" style={{color: '#333'}}>
                      Our comprehensive data analysis platform includes much more than just variant analysis. See for yourself!
                    </p>
                  </div>
                  <div style={{float: 'right'}}>
                    <img style={{width: 400}} src={LabScientists} alt="Lab Scientists" />
                  </div>
                </div>
                <div style={{marginTop: -90}}>
                  <ExploreDatabaseButton />
                </div>
              </div>

            </td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsBlue}}>
              <div style={{backgroundColor: cxmColorsBlue, width: 1100, marginLeft: 'auto', marginRight: 'auto', paddingTop: 20, paddingBottom: 20}}>

                <div style={{width: '100%', height: 'fit-content', marginTop: 25, padding: 0}}>
                  <p className="headerWithShadow" style={{color: 'white', textAlign: 'left', marginLeft: 50}}>Exploring the <CodeXomeAndRegisterMark fontSize={32} /> Database is Fast and Easy</p>
                  <p style={{borderBottom: 'solid 4px white', marginLeft: 50, width: 100, marginBottom: 30}}></p>
                  <p className="subHeader" style={{marginLeft: 50, textAlign: 'left', width: 'fit-content'}}>
                      Quickly uncover insights with the fastest, most accurate 
                      comparative gene data set assembled, anywhere.
                  </p>
                </div>
                <div style={{width: 1000, fontSize: 18, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: 25}}>
                  <div style={{ display: 'inline-block', width: 276,  padding: 20, marginRight: 20, backgroundColor: 'white', borderRadius: 10, border: 'solid 2px #999'}}>
                    <svg height="50" width="50" viewBox="0 0 100 100" style={{marginTop: -30}}>
                      <circle cx={50} cy={50} r="50" fill={cxmColorsBrown} />
                      <text x={35} y={65} fontSize={50} fill='white'>1</text>
                    </svg>
                    <p className="cardHeader">
                      Input a Gene
                    </p>
                    <p style={{borderBottom: 'solid 1px #999', marginLeft: 'auto', marginRight: 'auto', width: 180}}></p>
                    <p className="cardSubHeader">
                    Select your favorite gene, or choose from one of the suggested names next to the search bar.<br/><br/>
                    With just one click you will generate a preview of a multiple sequence alignment for your gene of interest.
                    </p>
                  </div>
                  <div style={{ display: 'inline-block', width: 276,  padding: 20, marginRight: 20, backgroundColor: 'white', borderRadius: 10, border: 'solid 2px #999'}}>
                    <svg height="50" width="50" viewBox="0 0 100 100" style={{marginTop: -30}}>
                      <circle cx={50} cy={50} r="50" fill={cxmColorsBrown} />
                      <text x={35} y={65} fontSize={50} fill='white'>2</text>
                    </svg>
                    <p className="cardHeader">
                      Preview Curated Data Sets
                    </p>
                    <p style={{borderBottom: 'solid 1px #999', marginLeft: 'auto', marginRight: 'auto', width: 180}}></p>
                    <p className="cardSubHeader">
                    Instantly access the 80 million year evolutionary history of your<br/>selected gene. <br/><br/>
                    With CodeXome, you get the most comprehensive collection of gene variation among mankind's closest relatives.
                    </p>
                  </div>
                  <div style={{ display: 'inline-block', width: 276,  padding: 20, backgroundColor: 'white', borderRadius: 10, border: 'solid 2px #999'}}>
                    <svg height="50" width="50" viewBox="0 0 100 100" style={{marginTop: -30}}>
                      <circle cx={50} cy={50} r="50" fill={cxmColorsBrown} />
                      <text x={35} y={65} fontSize={50} fill='white'>3</text>
                    </svg>
                    <p className="cardHeader">
                      Use Multiple Views
                    </p>
                    <p style={{borderBottom: 'solid 1px #999', marginLeft: 'auto', marginRight: 'auto', width: 180}}></p>
                    <p className="cardSubHeader">
                    Explore even more: compare DNA<br/>and Amino Acid alignments, identify differences between humans 
                    and non-human primates, and investigate how<br/>the evolutionary rate of this gene<br/>compares to the 13,650 additional<br/>genes available in CodeXome.
                    </p>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <div style={{width: '100%', height: 'fit-content', marginTop: -15, backgroundColor: cxmColorsBlue, padding: 0, paddingTop: 1}}>
                  <GeneSearch setViewLicenseAgreement={setViewLicenseAgreement} />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: "white"}}>
              <div>
                <div style={{width: 1310, marginLeft: 'auto', marginRight: 'auto', marginTop: 0, backgroundColor: "white", padding: 0, paddingTop: 15, paddingBottom: 30}}>
                  <p className="headerWithShadow" style={{marginBottom: 0, textAlign: 'left', marginLeft: -20, color: '#333', textShadow: '2px 2px 2px #CCC'}}><CodeXomeAndRegisterMark fontSize={32} /> Features</p>
                  <p style={{borderBottom: 'solid 4px #333', marginLeft: -20, width: 100}}></p>
                  <p className="subHeader" style={{width: 1300, marginLeft: -20, color: '#333'}}>
                    <CodeXomeAndRegisterMark fontSize={20} /> provides remarkable clarity in understanding human variations by including 55 genera of mankind's primate relatives.

                  </p>
                </div>                

                <div style={{ backgroundColor: 'white', marginBottom: 20, padding: 0, paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 30, fontSize: 16, width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: -30, borderRadius: 10}}>
                  <div style={{width: 1310, height: 280, marginLeft: 'auto', marginRight: 'auto'}}>
                    <table style={{textAlign: 'left'}}>
                      <tbody>
                        <tr>
                          <td>
                            <div style={{marginTop: 10}}>
                              <FlipCard front={
                                          <div style={{marginTop: 0}}>
                                            <div style={{backgroundColor: cxmColorsBrown, color: 'white', width: 250, padding: 10, marginLeft: 'auto', marginRight: 'auto',
                                                        borderRadius: 10, marginTop: -45}}>
                                              <b>Choose a Gene Profile</b>
                                            </div>
                                            <br/>
                                            <img height={90} src={gene_profile_draft1} alt="Gene Profiles" />
                                          </div>
                                        } 
                                        back={
                                          <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content'}}>
                                            Select a gene profile from 80 million<br/>years of evolutionary data
                                          </div>
                                        } />
                            </div>
                          </td>
                          <td>
                            <div style={{marginTop: 10}}>
                              <FlipCard front={
                                          <div style={{marginTop: 0}}>
                                            <div style={{backgroundColor: cxmColorsBrown, color: 'white', width: 250, padding: 10, marginLeft: 'auto', marginRight: 'auto',
                                                        borderRadius: 10, marginTop: -45}}>
                                              <b>Filter Through Variants</b>
                                            </div>

                                            <div style={{ position: 'relative'}}>
                                              <img style={{position: 'absolute', top: 15, left: 107.5}} height={90} src={variantFilter_icon} alt="Variant Filtering" />
                                              <img style={{position: 'absolute', top: 15, left: 108}} height={90} src={variantFilter_icon} alt="Variant Filtering" />
                                              <img style={{position: 'absolute', top: 15, left: 108.5}} height={90} src={variantFilter_icon} alt="Variant Filtering" />
                                            </div>
                                          </div>
                                        } 
                                        back={
                                          <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content'}}>
                                            Rapidly filter the millions of<br/>variant sites in your data
                                          </div>
                                        } />
                            </div>
                          </td>
                          <td>
                            <div style={{marginTop: 10}}>
                              <FlipCard front={
                                          <div style={{marginTop: 0}}>
                                            <div style={{backgroundColor: cxmColorsBrown, color: 'white', width: 250, padding: 10, marginLeft: 'auto', marginRight: 'auto',
                                                        borderRadius: 10, marginTop: -45}}>
                                              <b>Compare the Genes</b>
                                            </div>
                                            <br/>
                                            <img height={90} src={compgen_icon} alt="Comparative Genomics" />
                                          </div>
                                        } 
                                        back={
                                          <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content'}}>
                                            Leverage genetic data across the entire exome
                                          </div>
                                        } />
                            </div>
                          </td>
                          <td>
                            <div style={{marginTop: 10}}>
                              <FlipCard front={
                                          <div style={{marginTop: 0}}>
                                            <div style={{backgroundColor: cxmColorsBrown, color: 'white', width: 250, padding: 10, marginLeft: 'auto', marginRight: 'auto',
                                                        borderRadius: 10, marginTop: -45}}>
                                              <b>Analyze the Function</b>
                                            </div>
                                            <br/>
                                            <img height={90} src={functional_icon} alt="Functional Analysis" />
                                          </div>
                                        } back={
                                          <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content'}}>
                                            Capture the natural, functional limits of each gene
                                          </div>
                                        } />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{marginTop: -200}}>
                      <ExploreDatabaseButton />
                    </div>
                  </div>
                </div>

              </div>
            </td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsBlue}}>
              <div style={{backgroundColor: cxmColorsBlue, width: 1100, height: 505, marginLeft: 'auto', marginRight: 'auto', paddingTop: 20, paddingBottom: 20}}>

                <div style={{width: '100%', height: 'fit-content', marginTop: 25, marginBottom: 50, padding: 0, marginLeft: 90}}>
                  <p className="headerWithShadow" style={{color: 'white'}}><CodeXomeAndRegisterMark fontSize={32} /> redefines "normal".</p>
                  <p style={{borderBottom: 'solid 4px white', width: 100, marginBottom: 30}}></p>
                  <p className="subHeader">Informed by nature. <CodeXomeAndRegisterMark fontSize={20} /> revolutionizes genome research.</p>
                </div>

                <div style={{width: 1006, fontSize: 18, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: 25}}>

                  <div style={{ display: 'inline-block', position: 'relative', borderRadius: 10, width: 250,  padding: 20, marginRight: 20, backgroundColor: '#EEE', border: 'solid 2px #999'}}>
                    <p className="cardHeader">
                      Compare ALL the Data
                    </p>
                    <p className="cardSubHeader">
                      The <CodeXomeAndRegisterMark fontSize={14} /> proprietary database contains nearly 14,000 genes that are
                      effectively complete, providing researchers with the most accurate comparative dataset anywhere.
                    </p>
                  </div>

                  <div style={{ display: 'inline-block', position: 'relative',  width: 250, borderRadius: 10,  padding: 20, marginRight: 20, backgroundColor: '#EEE', border: 'solid 2px #999'}}>
                    <p className="cardHeader">
                      Save Months (or Years) of<br/>Compilation
                    </p>
                    <p className="cardSubHeader" style={{marginTop: 30}}>
                    <CodeXomeAndRegisterMark fontSize={14} /> puts every gene at your fingertips in seconds &ndash; clean, accurate, and in context.
                    </p>

                  </div>
                  
                  <div style={{ display: 'inline-block', position: 'relative', width: 250, borderRadius: 10,  padding: 20, backgroundColor: '#EEE', border: 'solid 2px #999'}}>
                    <p className="cardHeader">
                      Quickly See True Variants
                    </p>
                    <p className="cardSubHeader">
                      Robust search functions and groundbreaking datasets give researchers complete control over molecular
                      and bioinformatic processing in every sample.
                    </p>

                  </div>

                </div>
                <div style={{marginTop: -60}}>
                  <ExploreDatabaseButton bgColor={cxmColorsBrown} />
                </div>
              </div>
            </td>
          </tr>
          
          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsSeparator}}>&nbsp;</td>
          </tr>

          <tr>
            <td style={{backgroundColor: cxmColorsBrown, color: 'white', height: 400, verticalAlign: 'middle', textAlign: 'center'}}>
              <div style={{ width: 800, marginLeft: 'auto', marginRight: 'auto'}}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{width: 375, fontSize: 14, textAlign: 'left', lineHeight: 1.5}}>
                        <img src={SmallLogo} alt="Small Logo" width="125px" /><br/><br/>
                        <span style={{fontSize: 12, fontStyle: "italic"}}>A Woman-Owned Small Business</span><br/><br/>

                        In early 2023, Cornerstone Genomics will launch <CodeXomeAndRegisterMark fontSize={14} />, the most 
                        accurate comparative gene dataset assembled anywhere.<br/><br/>

                        Our tools dramatically accelerate wildlife and human biology research, because 
                        finding answers to disease can't wait.
                      </td>
                      <td style={{width: 50}}>
                      </td>
                      <td style={{width: 375, verticalAlign: 'top', textAlign: 'left'}}>
                        Site Links<br/><br/>
                        <hr />
                        <br/>
                        <ul className="footerLinks">
                            <li style={{marginBottom: 10, paddingLeft: 0}}>
                              <a href="https://codexome.com">
                              <CodeXomeAndRegisterMark fontSize={16} /> ANALYTICAL SOFTWARE
                              </a>
                            </li>
                            <li style={{marginBottom: 10, paddingLeft: 0}}>
                              <a href="https://cornerstonegenomics.com/news/">
                                NEWS
                              </a>
                            </li>
                            <li style={{marginBottom: 10, paddingLeft: 0}}>
                              <a href="https://cornerstonegenomics.com/team/">
                                TEAM
                              </a>
                            </li>
                            <li>
                              <a href="https://cornerstonegenomics.com/contact-us/">
                                CONTACT
                              </a>
                            </li>
                          </ul>
                          <br/><br/>
                          <div style={{backgroundColor: 'white', display: 'inline-block'}}>
                            <FaTwitterSquare size={40} color={cxmColorsBlue} style={{padding: 0, margin: -8, cursor: 'pointer'}}
                                onClick={() => {
                                  window.location.href = "https://twitter.com/csgenomics";
                                }}
                             />
                          </div>
                          <div style={{backgroundColor: 'white', display: 'inline-block', marginLeft: 20}}>
                            <FaLinkedin size={40} color={cxmColorsBlue} style={{padding: 0, margin: -8, cursor: 'pointer'}}
                                onClick={() => {
                                  window.location.href = "https://www.linkedin.com/company/cornerstone-genomics-llc/";
                                }}
                             />
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{backgroundColor: cxmColorsBlue, color: 'white', height: 100, verticalAlign: 'middle', textAlign: 'center'}}>
            Copyright © 2022 - All Rights Reserved
            </td>
          </tr>
        </tbody>
        <LicenseAgreementModal setClose={setViewLicenseAgreement} show={viewLicenseAgreement}/>
      </table>
    </div>
  )
}
