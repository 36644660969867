import React from "react";
import './flipCardStyles.css';

function FlipCard(props: any) {
  const front = props.front ? props.front : "FLIP ME!";
  const back = props.back ? props.back : "I Have Been Flipped!";

  //const cxmColorsBlue = "#03537A";
  //const cxmColorsBrown = "#945232";
  //const cxmColorsHandBlue = "#01B8D0";

  return (
    <div className='flipCardDiv' >
      <div className='closed' style={{display: 'block', backgroundColor: '#DDD'}} >
        <div style={{position: 'absolute', textAlign: 'center', width: '100%', top: '25%', marginTop: -55, marginLeft: -10}}>
          {front}
        </div>
        <div style={{position: 'absolute', width: 280, marginLeft: 5, marginTop: 145}}>
          {back}
        </div>
      </div>

      <div style={{height: 100, marginTop: 30}}>
        
      </div>
    </div>
  )
}

export default FlipCard;
