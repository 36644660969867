import React from 'react';

function MsaIcon(props: any) {
  return (
    <div    style={{  padding: 0,
                      border: 'solid 1px #EFEFEF',
                      height: 20,
                      width: 20,
                      display: 'block'
    }}>
      
      <svg id="fullActive" height="20" width="20" viewBox="0 0 50 50" >
        <rect x={1} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={33} y={1} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />

        <rect x={1} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={33} y={17} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />

        <rect x={1} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
        <rect x={17} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#02547A" />
        <rect x={33} y={33} width={13} height={13} rx={3} stroke="black" strokeWidth={1} fill="#F2BF3D" />
      </svg>
    </div>
  )
}

export default MsaIcon;
