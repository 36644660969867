import React from 'react';
import './App.css';
import { DefinedRoutes } from './definedroutes';

function App() {
  return (
    <div style={{width: '100%', height: '100%', padding: 0, margin: 0}}>
      <DefinedRoutes />
    </div>
  );
}

export default App;
