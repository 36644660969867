import React from 'react';
import { Home } from '../screens/home';
import blueLogo from '../images/blueLogo.svg';
import Hero from '../images/Hero.png';
import { handleRegistrationApiCall } from '../utils/DataCall';
import { FaAsterisk } from 'react-icons/fa';

const cxmColorsBlue = "#03537A";

function checkRequiredFields(emailRef: any, agreeRef: any) {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const email: string = emailRef.current ? emailRef.current.value : '';
  let result: boolean = expression.test(email);

  if(result) {
    const agreeIsChecked = agreeRef.current ? agreeRef.current.checked : false;
    result = agreeIsChecked;
  }

  return result;
}

export default function PrivateRoute({children}: any) {

  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  
  return (
    isAuthenticated ? <Home /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />
  );
}

const LoginBox = (props: any) => {
  const [loginError, setLoginError] = React.useState<string>("");

  const firstRef = React.useRef<HTMLInputElement>(null);
  const lastRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const orgRef = React.useRef<HTMLInputElement>(null);
  const agreeRef = React.useRef<HTMLInputElement>(null);

  const [requirementsMet, setRequirementsMet] = React.useState<boolean>(false);

  return (
    <div style={{ padding: 10, color: 'white', border: 'solid 0px red', fontSize: 18, width: 610}}>
      <hr style={{width: 300, marginBottom: 34}} />
      <b>Yes! I am interested seeing first-hand how <CodeXomeAndRegisterMark fontSize={18} /> can help accelerate my research.</b>
      <div>
        <table border={0} cellSpacing={0} cellPadding={5} style={{marginTop: 20, fontSize: 14, border: 'solid 0px blue'}}>
          <tbody>
            <tr>
              <td style={{width: 25}}></td>
              <td style={{width: 100}}>First Name</td>
              <td style={{width: 180}}>
                <input ref={firstRef} type="text" style={{width: 180, fontSize: 16, padding: 5}} />
              </td>
              <td style={{width: 150, textAlign: 'right', paddingRight: 10}}>Last Name</td>
              <td style={{width: 180}}>
                <input ref={lastRef} type="text" style={{width: 180, fontSize: 16, padding: 5}} />
              </td>
            </tr>
            <tr>
              <td><FaAsterisk color="red" /></td>
              <td>E-mail</td>
              <td colSpan={3}>
                <input ref={emailRef} type="text" style={{width: 475, fontSize: 16, padding: 5}}
                  onKeyUp={() => {
                    const reqResult = checkRequiredFields(emailRef, agreeRef);

                    if(reqResult) {
                      // Valid email
                      if(!requirementsMet) {
                        setRequirementsMet(true);
                      }
                    }
                    else {
                      // Invalid email
                      if(requirementsMet) {
                        setRequirementsMet(false);
                      }
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Organization</td>
              <td colSpan={3}>
                <input ref={orgRef} type="text" style={{width: 475, fontSize: 16, padding: 5}} />
              </td>
            </tr>
            <tr>
            <td></td>
              <td></td>
              <td colSpan={4}>
                <div style={{position: 'relative', height: 30}}>
                  <div style={{position: 'absolute', top: 0, left: 215}}>
                    <FaAsterisk color="red" style={{marginRight: 10}} />
                    <i>Subscribe and continue<br />
                    <div style={{width: 23, display: 'inline-block'}}> </div>(you can unsubscribe at any time)</i>
                  </div>
                  <div style={{position: 'absolute', top: 5, left: 457}}>
                    <input ref={agreeRef} type="checkbox" defaultChecked={true} style={{marginLeft: 10, height: 20, width: 20}}
                        onChange={() => {
                          const reqResult = checkRequiredFields(emailRef, agreeRef);

                          if(reqResult) {
                            // Valid email
                            if(!requirementsMet) {
                              setRequirementsMet(true);
                            }
                          }
                          else {
                            // Invalid email
                            if(requirementsMet) {
                              setRequirementsMet(false);
                            }
                          }
                        }} 
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={1}>      
                <div  style={{  //position: 'absolute', 
                                //left: -215, 
                                //marginLeft: -20, 
                                marginTop: 10, 
                                cursor: requirementsMet ? 'pointer' : 'default', 
                                color: requirementsMet ? 'white' : '#ABABAB', 
                                width: 155, 
                                textAlign: 'center',
                                backgroundColor: '#945232', // requirementsMet ? '#945232' : '#ABABAB', 
                                borderRadius: 10, 
                                display: 'block',
                                border: requirementsMet ? 'solid 3px #EFEFEF' : 'solid 3px #ABABAB', 
                                padding: 15}}
                  onClick={() => {
                    if(requirementsMet) {
                      const reqResult = checkRequiredFields(emailRef, agreeRef);

                      if(!reqResult) {
                        setLoginError("Email is required - please provide a valid email to view our preview site. Please ensure checkbox is checked before submitting.");
                      }
                      else {
                        const email: string = emailRef.current ? emailRef.current.value : '';
                        const org: string = orgRef.current ? orgRef.current.value : '';
                        const firstName: string = firstRef.current ? firstRef.current.value : '';
                        const lastName: string = lastRef.current ? lastRef.current.value : '';
                        handleRegistrationApiCall(email, firstName, lastName, "", org, "").then((res: any) => {
                          if(res.success !== true) {
                            console.log(res);
                          }
                        });

                        props.setIsAuthenticated(true);
                      }
                    }
                  }}
                        >
                  Show me the <CodeXomeAndRegisterMark fontSize={14} /> Preview
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{display: loginError === "" ? 'none' : 'block', color: 'red', padding: 10, fontSize: 20, marginTop: 20, backgroundColor: 'white'}}>
        {loginError}
      </div>
    </div>
  );
}

const LoginPage = (props: any) => {
  return (
    <div style={{width: '100%', height: '100%', backgroundColor: "white", padding: 0, margin: 0}}>
      <table cellPadding={0} cellSpacing={0} style={{width: '100%', height: '100%', padding: 0, margin: 0, borderCollapse: 'collapse'}}>
        <tbody>
          <tr>
            <td style={{        border: 'solid 0px red', 
                                backgroundColor: "white",
                                
                                }}>
              <div style={{     width: 1100, 
                                height: 900,
                                marginLeft: 'auto', 
                                marginRight: 'auto', 
                                
                                backgroundSize: 'cover', 
                                backgroundPositionX: 60,
                                backgroundPositionY: 10,
                                backgroundRepeat: 'no-repeat'
                          }}>

                <div style={{width: '100%', margin: 0, height: 70}}>

                  <div style={{width: '100%', height: 100}}>
                    <div style={{float: 'left', padding: 15, borderRadius: 5, border: 'solid 0px green',  marginTop: 10, marginLeft: 10}}>
                      <img  src={blueLogo} 
                            alt="Logo"
                            width='150px'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com";
                            }} 
                      />
                    </div>
                    <div style={{float: 'right', border: 'solid 0px green', marginTop: 9, marginRight: 10}}>
                      <div style={{   
                                      backgroundColor: "#2C22EB", 
                                      fontFamily: 'Arial', 
                                      fontSize: 12,
                                      float: 'right', 
                                      width: 'fit-content', 
                                      //border: 'solid 1px #03537A', 
                                      border: 'solid 1px white',
                                      borderRadius: 10, 
                                      paddingTop: 17, 
                                      paddingBottom: 17,
                                      paddingLeft: 70,
                                      paddingRight: 70,
                                      marginTop: 17,
                                      color: 'white',
                                      cursor: 'pointer'
                                      }}
                            onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com/contact-us/";
                            }}
                      >
                        CONTACT
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: 0, marginTop: 10, padding: 40, paddingBottom: 0, border: "solid 0px red"}}>
                  <p className="headerWithShadow" style={{marginTop: 0}}>
                      Welcome to the <CodeXomeAndRegisterMark fontSize={52} /> Preview page! 
                    </p>
                    <p className="mediumParagraph">
                      In just a few days, you can view a snapshot of the evolutionary history of thousands of 
                      genes. We hope you will back soon, and in the meantime, visit our website 
                      at <u><span style={{cursor: "pointer"}}  onClick={() => {
                              window.location.href= "https://cornerstonegenomics.com/contact-us/";
                            }}>https://www.cornerstonegenomics.com</span></u>
                    </p>
                </div>
                <div style={{ margin: 0, marginTop: 0, height: 620, paddingTop: 0, 
                  background: `url(${Hero})`,  backgroundSize: "cover",
                  backgroundColor: "#F7F9FB"
                  }}>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const CodeXomeAndRegisterMark = (props: any) => {
  const fontSize = props.fontSize ? props.fontSize : 14;

  return (
    <span>
      <span style={{fontSize: fontSize}}>CodeXome</span><span style={{fontSize: Math.floor(fontSize * 0.85), marginLeft: 2}}>&reg;</span>
    </span>
  )
}


