import React from "react";
import GeneKeys from '../../utils/GeneKeys';
import GeneViewShellAA from '../../images/GeneViewShell_AA.png';
import GeneViewShellNT from '../../images/GeneViewShell_NT.png';
import { handleApiCall } from "../../utils/DataCall";
import { BsSearch } from "react-icons/bs";
import ShowDiffsIcon from "../../components/ShowDiffsIcon";
import MsaIcon from "../../components/MsaIcon";
import GraphIcon from "../../components/GraphIcon";
import NoGeneSample from '../../images/samples/NoGeneSample.png';
import SummaryHistogram from "../../components/SummaryHistogram";
import GoodGenes from "../../utils/GoodGenes";
import RulerCoords from "../../utils/RulerCoords";
import TreeFamilies from "../../components/TreeFamilies";
import { LicenseAgreementModalProps } from "../../components/LicenseAgreementModal";

//@ts-ignore
import * as d3 from "d3";

export default function GeneSearch({setViewLicenseAgreement}: LicenseAgreementModalProps) {
  // Options
  const [isNT, setisNT] = React.useState<boolean>(true);
  const [showRef, setShowRef] = React.useState<boolean>(false);

  // Dictionary
  const allGenes = React.useMemo(() => (GeneKeys.map(x => x.geneDisplay)), []);

  // Gene Setting
  const [currImage, setCurrImage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [activeGene, setActiveGene] = React.useState<string>('VEGFA');  
  const [interestRegistered, setInterestRegistered] = React.useState<boolean>(false);
  const searchBoxRef = React.useRef<HTMLInputElement>(null);

  // Ruler
  const [geneTickMarks, setGeneTickMarks] = React.useState<any>();

  // Typeahead
  const [choices, setChoices] = React.useState<any>([]);
  const [activeChoice, setActiveChoice] = React.useState<number>(-1);

  // Tutorial
  const [tutorialStep, setTutorialStep] = React.useState<number>(-1);

  // Histogram
  const [viewChart, setViewChart] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<number>(0);

  React.useEffect(() => {
    // GET GENE
    if(activeGene && activeGene !== '') {
      setIsLoading(true);
      setCurrImage('');
      handleApiCall(activeGene.toLowerCase(), isNT, showRef, false).then((result: any) => {
        if(result.success && result.data) {
          const base64 = result.data;
          if(base64.data === undefined || base64.data === null || base64.data.toString().trim() === '') {
            // BAD
            setCurrImage('');
          }
          else {
            setCurrImage(base64.data.toString().trim());
          }
        }
        else {
          setCurrImage('');
        }

        setIsLoading(false);
      })
    }

    // GET RULER
    const rulerDict = RulerCoords.find((x: any) => { return x.key === activeGene.toLowerCase()});
    if(rulerDict) {
      const startPos = Number(rulerDict.aaLeft);
      const ticks = Array<number>();

      if(startPos && startPos > 0) {
        const endPos = startPos + 25;

        for(var i = startPos; i < endPos; i++) {
          ticks.push(i);
        }

        setGeneTickMarks(ticks);
      }
      else {
        setGeneTickMarks([]);
      }
    }
    else {
      setGeneTickMarks([]);
    }

    // Load Histogram
    window.setTimeout(() => {
      setActiveTab(1);
    }, 100);
  }, [activeGene, isNT, showRef]);

  function setSelectedTerm(term: string) {
    searchBoxRef.current!.value = term;
  }

  function performSearch() {
    setInterestRegistered(false);
    setActiveGene(searchBoxRef.current!.value);
  }

  function determineTypeAhead(chars: string) {
    if(chars === "") {
      setChoices([]);
    }
    else {
      const filteredChoices1 = allGenes.filter(x => {return x.toLowerCase().startsWith(chars.toLowerCase())});
      const filteredChoices2 = filteredChoices1 && filteredChoices1.length > 0 ? filteredChoices1.filter(x => {return GoodGenes.includes(x.toLowerCase())}) : null;
      const filteredChoices = filteredChoices2 && filteredChoices2.length > 0 ? filteredChoices2.filter(x => {return x.toLowerCase().startsWith(chars.toLowerCase())}) : null;
      
      if(filteredChoices && filteredChoices.length > 0 )
      {
        setChoices(filteredChoices.sort());
      }
      else{
        setChoices([]);
      }
    }
  }

  function notifyInterestOfGene(gene: string) {
    // Log interest
    handleApiCall(gene, false, false, true).then((result: any) => {
      // Screen feedback regardless of success
      setInterestRegistered(true);

      if(result.success !== true) {
        console.log(result);
      }
    });
  }

  const getBinsForData = (dataItems: Array<any>, binRange: number, fieldFunction: any) => {
    const sortedItems = dataItems.sort((source: any, target: any) => { 
      const sourceVal = fieldFunction(source);
      const targetVal = fieldFunction(target);
      return sourceVal < targetVal ? -1 : sourceVal > targetVal ? 1 : 0;
    });

    const minValue = (Math.floor(fieldFunction(sortedItems[0]) * 100) / 100).toFixed(2);
    const maxValue = (Math.ceil(fieldFunction(sortedItems[sortedItems.length - 1]) * 100) / 100).toFixed(2);

    // TODO:  the number of decimal places should be dynamic, passed in, etc., based on the binRange provided
    const binScale = d3.range(minValue, maxValue, binRange);

    return binScale;
  }

  return (
    <div style={{width: '100%', padding: 0, position: 'relative'}}>
      <div style={{position: 'absolute', top: -500}}>
        <a id="ExploreGenes" style={{visibility: 'hidden'}} href="https://cornerstonegenomics.com/codexome">......</a>
      </div>
      <div style={{width: '100%', minHeight: 810, padding: 0, paddingTop: 0, borderRadius: 10}}>

        <table style={{ backgroundColor: 'white', 
                    marginLeft: 'auto', borderRadius: 10, marginRight: 'auto', 
                    width: 1350, minWidth: 1350, maxWidth: 1350, 
                    border: 'solid 0px red',
                    marginTop: 25
                  }} className="geneSearchTable" cellSpacing={0} cellPadding={0}>
          <tbody>
            <tr>

              <td><svg style={{width: 25, height: 1}}></svg></td>

              <td style={{position: 'relative', verticalAlign: 'top', minWidth: 1150,  border: 'solid 0px blue'}}>
                <div>
                  <div style={{display: 'flex'}}>
                    <p style={{marginLeft: 'auto', marginRight: 'auto'}}>By searching for a gene, you have accepted our <a href="#ExploreGenes" onClick={()=>{setViewLicenseAgreement(true)}}>License Agreement</a></p>
                  </div>
                  <div style={{position: 'relative', display: 'inline-block', verticalAlign: 'top', marginTop: 20}}>
                    <BsSearch   size={30}
                                style={{  position: 'absolute', 
                                          top: 7, 
                                          left: 5,
                                          cursor: 'pointer'
                                      }}
                                onClick={() => {
                                  // Search on whatever is in the box
                                  performSearch();
                                  setChoices([]);
                                }}
                    />
                    <input
                        className='form-control'
                        type='text'
                        ref={searchBoxRef}
                        style={{    height: '70%', 
                                    minHeight: 40,
                                    backgroundColor: "#EFEFEF",
                                    borderColor: "black",
                                    fontSize: 16,
                                    minWidth: 350,
                                    marginTop: 0,
                                    paddingLeft: 40,
                                    border: 'solid 3px #CDCDCD'
                              }}
                        //placeholder={ showFullButton ? `${translation.geneNameValidation} (e.g., VEGFA)` : "Gene Profile Search" }
                        placeholder={ `Type in a Gene (e.g., VEGFA)` }
                        onKeyDown={e =>{
                            if (e.key === 'Enter'){
                              // First see if they were scrolling typeahead
                              if(activeChoice !== -1) {
                                setSelectedTerm(choices[activeChoice]);
                                setActiveGene(choices[activeChoice]);
                                setChoices([]);
                              }
                              else {
                                // Search on whatever is in the box
                                performSearch();
                                setChoices([]);
                              }
                            }
                            else if (e.key === 'ArrowDown'){
                              setActiveChoice(activeChoice > (choices.length - 2) ? (choices.length - 1) : activeChoice + 1);
                              const container = document.getElementById('typeAheadDivContainer');
                              container && (container.scrollTop = activeChoice * 22);
                            }
                            else if (e.key === 'ArrowUp'){
                              setActiveChoice(activeChoice < 1 ? 0 : activeChoice - 1);
                              const container = document.getElementById('typeAheadDivContainer');
                              container && (container.scrollTop = (activeChoice - 1) * 22);                    
                            }
                            else {
                              setActiveChoice(-1);
                            }
                          }
                        }
                        onKeyUp={e => {
                            if(e.key !== 'Enter') {
                              determineTypeAhead(searchBoxRef.current!.value);
                            }
                            if(e.key === 'Escape') {
                              setChoices([]);
                            }
                          }
                        }
                        onBlur={e => {
                          window.setTimeout(() => {
                            setChoices([]);
                          }, 200);
                        }}
                    />
                    <div style={{position: 'absolute', left: 372, top: 0}}>
                      <svg height={400} width={900}>
                        <circle cx={23} cy={23} r={20} fill="white" stroke="#3B7A09" strokeWidth={3} />
                        <text x={15} y={34} fontSize={30} fill="#3B7A09">?</text>
                        <text x={50} y={18} fontSize={12} fill="#777">Don't have a favorite gene but still want to explore?</text>
                        <text x={50} y={37} fontSize={12} fill="#777">Try searching for a gene associated with the rare disease phenylketonuria (PAH), cancer (TP53), infertility (TUBB8), or covid pathogenicity gene (FURIN).</text>
                      </svg>
                    </div>
                    <div className='typeAheadDivContainerWrapper' style={{ zIndex: 5000, marginTop: 5, marginLeft: 0, display: choices.length > 0 ? 'block' : 'none'}}>
                      <div id='typeAheadDivContainer' className='typeAheadDivContainer'>
                          {
                              choices.map((choice:string, idx: number) => {
                                return (
                                  <div
                                    className={idx === activeChoice ? 'typeAheadDiv active' : 'typeAheadDiv'}
                                    key={`div_ta_${choice}`}
                                    id={`div_ta_${idx}`}
                                    //@ts-ignore
                                    choice={choice}
                                    onClick={() => {
                                      setSelectedTerm(choice);
                                      setActiveGene(choice);
                                      setChoices([]);
                                    }}
                                  >{choice}</div>
                                );
                              })
                          }
                      </div>
                    </div>
                  </div>
                  <div style={{marginLeft: 'auto', marginRight: 'auto', width: 1200, marginTop: 20, borderBottom: 'solid 2px #777'}}></div>
                </div>
              </td>
              <td><svg style={{width: 25, height: 1}}></svg></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div style={{position: 'relative'}}>
                  <div style={{marginLeft: 'auto', marginRight: 'auto', width: 790, display: 'block', verticalAlign: 'top', marginTop: 20, marginBottom: 10}}>
                    <div style={{   marginLeft: 'auto', 
                                    marginRight: 'auto', 
                                    marginTop: -42, 
                                    marginBottom: 20, 
                                    width: 'fit-content',
                                    backgroundColor: "#945232",
                                    color: 'white',
                                    padding: 10,
                                    border: 'solid 2px white',
                                    borderRadius: 10,
                                    display: activeGene && activeGene !== '' ? 'block' : 'none'
                                }}>
                      Viewing Gene: {activeGene && activeGene.toUpperCase()}
                    </div>
                    <div style={{display: "grid"}}>
                      <p>Preview our curated data sets by clicking on either of these options once you have entered a gene name above</p>
                    </div>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', width: 465}}>
                      <div style={{   display: 'inline-block', position: 'relative', 
                                      height: 20, width: 200, 
                                      border: 'solid 3px #ABABAB', 
                                      borderRadius: 5, 
                                      marginTop: 4, 
                                      padding: 5, 
                                      backgroundColor: viewChart ? '#EFEFEF' : '#03537A', 
                                      color: viewChart ? '#999999' : '#EFEFEF', 
                                      cursor: 'pointer'}}
                            onClick={() => {
                              setViewChart(false);
                            }}
                            className="divButtonHover"
                            >
                        <div style={{position: 'absolute'}}><MsaIcon /></div>
                        <div style={{position: 'absolute', left: 40, top: 9, fontSize: 12}}>Multiple Sequence Alignment</div>
                      </div>

                      <div style={{   display: 'inline-block', position: 'relative', 
                                      height: 20, width: 200, 
                                      border: 'solid 3px #ABABAB', 
                                      borderRadius: 5, 
                                      marginTop: 0, 
                                      padding: 5, 
                                      marginLeft: 30,
                                      backgroundColor: viewChart ? '#03537A' : '#EFEFEF',  
                                      color: viewChart ? '#EFEFEF' : '#999999', 
                                      cursor: 'pointer'}}
                            onClick={() => {
                              setViewChart(true);
                            }}
                            className="divButtonHover"
                            >
                        <div style={{position: 'absolute'}}><GraphIcon /></div>
                        <div style={{position: 'absolute', left: 40, top: 9, fontSize: 12}}>Evolutionary Rates</div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style={{verticalAlign: 'top', position: 'relative'}}>
                <div style={{display: viewChart ? 'none' : 'block', minHeight: 675}}>
                  <div style={{     position: 'absolute', 
                                    color: 'white', 
                                    marginLeft: 0, 
                                    left: 335, 
                                    top: 20, 
                                    width: 600, 
                                    zIndex: 1000, 
                                    padding: 20, 
                                    paddingBottom: 30, 
                                    borderRadius: 10, 
                                    backgroundColor: '#02547ADD', 
                                    display: activeGene === '' ? (tutorialStep > 0  ? 'none' : 'none') : 'none'
                              }}>
                    <h3 style={{color: '#F0BD7F'}}>Getting Started</h3>
                    <p>
                      To get started, enter a valid HGNC gene name in the text box above and select the name from the dropdown menu. Once a 
                      gene is entered, explore the multiple sequence alignment for the gene in either nucleotide or amino acid format; you 
                      can review the differences in the alignment relative to the human reference by selecting too: just use the 
                      icons in the panel at right to toggle between these options. 
                    </p>
                    <p>
                      You can also select an entirely different visualization by 
                      selecting the Evolutionary Rates icon. This interactive figure provides a comparative context for how many total 
                      changes the selected gene has acquired compared to the thousands of other genes in our proprietary database. Did you 
                      select a fast evolving gene? A highly constrained gene? CodeXome gives you the context to provide clarity and confidence in 
                      your analysis. 
                    </p>
                    <p>
                      Be sure to sign up below to stay in touch for more news about upcoming product developments and release dates.
                    </p>

                    <div style={{display: 'none'}}>
                      <h3 style={{color: '#72D1FD'}}>Show me an example</h3>
                        Explore a short use-case scenario for using CodeXome&reg; step-by-step; let us walk you through an example of using the product
                        to resolve a specific data analysis problem.<br/><br/>
                        Please <div style={{  display: 'inline-block', 
                                            borderBottom: 'solid 1px white',
                                            cursor: 'pointer'
                                        }}
                                  onClick={() => {
                                    setTutorialStep(1);
                                  }}
                              >
                        <b>click here to start the tutorial</b>
                      </div>.
                    </div>
                  </div>

                  <div style={{position: 'absolute', top: 20, left: 0, display: activeGene === '' ? 'block' : 'none'}}>
                      <div style={{ position: 'absolute', zIndex: 10, height: 690, width: 1320, top: -20, backgroundColor: '#FFFFFFBB'}}></div>
                      <img src={NoGeneSample} alt="Sample Gene" style={{ position: 'absolute', top: -20, left: -1 }}/>
                  </div>

                  <div style={{ width: 'fit-content', 
                                display: activeGene === '' ? 'none' : 'block', 
                                paddingRight: 5, 
                                paddingLeft: 10, 
                                position: 'relative', 
                                border: isLoading || currImage === '' ? 'none' : 'solid 1px #03537A', 
                                backgroundColor: 'white'}}>
                    <div style={{width: isNT ? 1195 : 590, overflow: 'hidden'}}>
                      <svg height="20" width={isNT ? 1195 : 590} style={{border: 'solid 0px red', marginLeft: 84, display: isLoading ? 'none' : currImage === '' ? 'none' : 'block'}}>
                        {
                          geneTickMarks && geneTickMarks.map((tick: number, tickIdx: number) => {
                            const startX = 231;
                            const textX = (tickIdx * (isNT ? 36 : 12)) + startX;

                            const num = isNT ? (tick * 3) - 2 : (tickIdx % 2) === 0 ? tick : '';

                            return (
                              <text key={`rulerTick_${tickIdx}`} textAnchor="middle" x={textX} y={20} fontSize={9} >{num}</text>
                            )
                          })
                        }
                      </svg>
                    </div>
                    <div>
                      <img src={isNT ? GeneViewShellNT : GeneViewShellAA} alt="Explore Gene" style={{display: isLoading ? 'none' : currImage === '' ? 'none' : 'block', marginLeft: 84}} />
                      <div style={{position: 'absolute', width: 915, height: 665, border: 'solid 0px red', top: 20, left: 230}}>
                        <div style={{position: 'absolute', top: -20, left:-223, display: isLoading ? 'none' : currImage === '' ? 'none' : 'block'}}>
                          <TreeFamilies />
                        </div>
                        <img src={`data:image/png;base64,${currImage}`} alt="Gene MSA"  style={{ display: currImage === '' ? 'none' : 'block', height: 747, position: 'absolute', top: -32, left: isNT ? 52 : 63}} />
                        <div style={{marginLeft: 0, width: 'fit-content', padding: 20, borderRadius: 10, backgroundColor: '#72D1FD88', display: activeGene !== '' && !isLoading && currImage === '' ? 'block' : 'none'}}>
                          <h3 style={{color: 'firebrick'}}>Whoops!</h3>
                          Sorry, we currently do not have that Gene in our database. Please check to confirm this is a valid gene identifier.<br/><br/>

                          <h3 style={{color: '#3B7A09'}}>Gene is correct?</h3>
                          Please <div style={{  display: 'inline-block', 
                                                borderBottom: 'solid 1px blue',
                                                cursor: 'pointer'
                                            }}
                                      onClick={() => {
                                        notifyInterestOfGene(activeGene);
                                      }}
                                  >
                            <b>click here to let us know</b>
                          </div> that you are interested in this gene!
                          <div style={{display: interestRegistered ? 'block' : 'none', backgroundColor: '#DEDEDE', textAlign: 'center', marginTop: 20, marginLeft: 0, border: 'solid 1px #333', borderRadius: 10, padding: 20}}>
                            Thank you for letting us know you are interested in this gene!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div style={{     position: 'absolute',
                                    left: isNT ? 1065 : 645,
                                    top: 35,
                                    backgroundColor: '#EFEFEF',
                                    padding: 20,
                                    border: 'solid 1px black',
                                    borderRadius: 10,
                                    width: 200,
                                    height: 610,
                                    display: activeGene === '' ? 'none' : (isLoading ? 'none' : (currImage === '' ? 'none' : 'block'))
                                  }}
                  >
                    <div style={{   border: 'solid 1px black',
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: 12,
                                    textAlign: 'center'
                    }}>
                      Sequence Type
                      <div>
                        <div style={{   display: 'inline-block', 
                                        backgroundColor: isNT ? '#02547A' : '#B7B7B7', 
                                        color: isNT ? 'white' : '#DEDEDE', 
                                        padding: 10, 
                                        borderRadius: 10, 
                                        cursor: 'pointer', 
                                        marginRight: 30,
                                        marginTop: 10,
                                        width: 'fit-content',
                                        fontWeight: isNT ? 'bold' : 'normal'
                                      }}
                              onClick={() => { setisNT(true); }}
                              className="divButtonHover"
                        >
                          NT
                        </div>
                        <div style={{   display: 'inline-block', 
                                        backgroundColor: !isNT ? '#02547A' : '#B7B7B7', 
                                        color: !isNT ? 'white' : '#DEDEDE', 
                                        padding: 10, 
                                        borderRadius: 10, 
                                        cursor: 'pointer', 
                                        marginRight: 5,
                                        marginTop: 10,
                                        width: 'fit-content',
                                        fontWeight: isNT ? 'normal' : 'bold',
                                        }}
                              onClick={() => { setisNT(false); }}
                              className="divButtonHover"
                        >
                          AA 
                        </div>
                      </div>
                    </div>
                    <div style={{   border: 'solid 1px black',
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: 12,
                                    textAlign: 'left',
                                    marginTop: 10,
                                    backgroundColor: 'white'
                    }}>
                      Navigate between nucleotides and amino acids to track how each site or residue, anchored 
                      to human coordinates, changes naturally.

                    </div>

                    <div style={{   border: 'solid 1px black',
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    marginTop: 25
                    }}>
                      Anchor to Human
                      <div>
                        <div style={{   display: 'inline-block', 
                                        cursor: 'pointer', 
                                        marginRight: 20,
                                        marginTop: 10,
                                        width: 'fit-content'
                                      }}
                              onClick={() => { setShowRef(false); }}>
                          <ShowDiffsIcon isDiffsIcon={false} isActive={!showRef} />
                        </div>
                        <div style={{   display: 'inline-block', 
                                        cursor: 'pointer', 
                                        marginRight: 5,
                                        marginTop: 10,
                                        width: 'fit-content'
                                        }}
                              onClick={() => { setShowRef(true); }}>
                          <ShowDiffsIcon isDiffsIcon={true} isActive={showRef} /> 
                        </div>
                      </div>
                    </div>
                    <div style={{   border: 'solid 1px black',
                                    padding: 10,
                                    borderRadius: 5,
                                    fontSize: 12,
                                    textAlign: 'left',
                                    marginTop: 10,
                                    backgroundColor: 'white'
                    }}>
                      Toggle preference of sequence data to be visualized. Either show all values, or just highlight 
                      differences relative to a human reference - dots represent the same value as human.
                    </div>   
                  </div>
                </div>
                
                <div style={{display: viewChart ? 'block' : 'none', padding: 20}}>
                  <div style={{width: 750, display: 'inline-block'}}>
                    <div style={{padding: 20, border: 'solid 1px #e4e8cc', width: '100%', height: 415, fontSize: 10}}>
                      <div style={{height: '100%', display: activeTab === 1 ? 'flex' : 'none'}}>
                        <div style={{display: 'block', position: 'relative', width: '100%', height: '100%'}} >
                          <SummaryHistogram color="#068CB1" 
                                            divID="graphDivOne" 
                                            binFunction={(dataItems: any) => { return getBinsForData(dataItems, 0.05, (d: any) => { return d.brLenTotal; }); }} 
                                            fieldFunction={(d: any) => { return d.brLenTotal; }}
                                            xAxisLabelLine1="Number of"
                                            xAxisLabelLine2="Genes"
                                            yAxisLabelLine1="Total Branch Length of Gene Tree"
                                            yAxisLabelLine2="(Substitutions per site)"
                                            geneName={currImage !== '' ? activeGene : ''}
                          />
                        </div>
                        <div style={{position: 'absolute', borderTop: 'solid 1px #ABABAB', paddingTop: 10, fontSize: 14, display: 'block', top: 390, left: 80, width: 675}}>
                          Individual point and label shown for the selected gene. Point value depicts the branch length of a maximum-likelihood model of the gene tree. Histogram binned in intervals of 0.05 substitutions per site, showing the frequency of genes in CodeXome within that interval.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{width: 300, height: 415, fontSize: 12, position: 'absolute', top: 20, left: 820, padding: 20, paddingTop: 10, backgroundColor: '#EFEFEF', border: 'solid 1px #333', borderRadius: 10}}>
                    <b style={{fontSize: 14}}>See how genes compare to each other</b>
                    <p>
                      CodeXome&reg; adds another dimension to your data analysis by introducing statistics and comparisons across genes.
                    </p>
                    <p>
                      This graph is an example of how certain genes change (mutate) more or less frequently over their evolution, and how those rates compare with other genes' mutation rates.
                    </p>
                    <p>
                      If you have a valid gene selected in the above text box, the bar that the gene falls in to will be highlighted.
                    </p>
                    <p>
                      Click on any one of the graph's lines (bins) to see a sample of genes in that range.
                    </p>
                  </div>
                </div>
              </td>
              <td><svg style={{width: 25, height: 1}}></svg></td>
            </tr>
            <tr>
              <td><svg style={{width: 1, height: 20}}></svg></td>
              <td></td>
            </tr>            
          </tbody>
        </table>
        <table style={{ 
                    marginLeft: 'auto', borderRadius: 10, marginRight: 'auto', 
                    width: 1350, minWidth: 1350, maxWidth: 1350, 
                    border: 'solid 0px red',
                    marginTop: 25
                  }} className="geneSearchTable" cellSpacing={0} cellPadding={0}>
          <tbody>
            <tr>
              <td><svg style={{width: 1, height: 20}}></svg></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
