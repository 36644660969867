// The idea is that this will come from a database
const SummaryGraphData = [
  { geneTranscript: "NM_006667.5", parsimonySitesP: "0.148717948717949", constantSitesP: "0.747008547008547", distinctSitesP: "0.242735042735043", brLenTotal: "0.5296" },
  { geneTranscript: "NM_001099272.2", parsimonySitesP: "0.150326797385621", constantSitesP: "0.757625272331155", distinctSitesP: "0.196623093681917", brLenTotal: "0.4663" },
  { geneTranscript: "NM_003098.3", parsimonySitesP: "0.207920792079208", constantSitesP: "0.673267326732673", distinctSitesP: "0.269306930693069", brLenTotal: "0.8358" },
  { geneTranscript: "NM_001184749.3", parsimonySitesP: "0.134607726005575", constantSitesP: "0.768618080446037", distinctSitesP: "0.178813221823975", brLenTotal: "0.4314" },
  { geneTranscript: "NM_024706.5", parsimonySitesP: "0.221863220247711", constantSitesP: "0.675282714054927", distinctSitesP: "0.277329025309639", brLenTotal: "1.0461" },
  { geneTranscript: "NM_001202438.2", parsimonySitesP: "0.174744211093161", constantSitesP: "0.727786752827141", distinctSitesP: "0.245826602046311", brLenTotal: "0.6231" },
  { geneTranscript: "NM_133173.3", parsimonySitesP: "0.154320987654321", constantSitesP: "0.718792866941015", distinctSitesP: "0.235253772290809", brLenTotal: "0.4876" },
  { geneTranscript: "NM_014795.4", parsimonySitesP: "0.0785282811641955", constantSitesP: "0.85227896760022", distinctSitesP: "0.109280615046678", brLenTotal: "0.2744" },
  { geneTranscript: "NM_001851.6", parsimonySitesP: "0.193630112196887", constantSitesP: "0.683315237061165", distinctSitesP: "0.291349981903728", brLenTotal: "0.7004" },
  { geneTranscript: "NM_031900.4", parsimonySitesP: "0.238651102464332", constantSitesP: "0.634241245136187", distinctSitesP: "0.351491569390402", brLenTotal: "0.8565" },
  { geneTranscript: "NM_005614.4", parsimonySitesP: "0.0923913043478261", constantSitesP: "0.807971014492754", distinctSitesP: "0.190217391304348", brLenTotal: "0.3294" },
  { geneTranscript: "NM_017871.6", parsimonySitesP: "0.18", constantSitesP: "0.734444444444444", distinctSitesP: "0.255", brLenTotal: "1.0824" },
  { geneTranscript: "NM_001276309.3", parsimonySitesP: "0.278846153846154", constantSitesP: "0.543269230769231", distinctSitesP: "0.467948717948718", brLenTotal: "1.4762" },
  { geneTranscript: "NM_012156.2", parsimonySitesP: "0.137722285281877", constantSitesP: "0.776012107453651", distinctSitesP: "0.174423004161937", brLenTotal: "0.5427" },
  { geneTranscript: "NM_001320371.4", parsimonySitesP: "0.400386847195358", constantSitesP: "0.42682140554481", distinctSitesP: "0.540941328175371", brLenTotal: "1.6818" },
  { geneTranscript: "NM_006179.5", parsimonySitesP: "0.273015873015873", constantSitesP: "0.501587301587302", distinctSitesP: "0.40952380952381", brLenTotal: "1.3219" },
  { geneTranscript: "NM_001110556.2", parsimonySitesP: "0.240901649666289", constantSitesP: "0.664148092179826", distinctSitesP: "0.245183226293918", brLenTotal: "1.1762" },
  { geneTranscript: "NM_001039888.4", parsimonySitesP: "0.168682795698925", constantSitesP: "0.733198924731183", distinctSitesP: "0.229838709677419", brLenTotal: "0.5850" },
  { geneTranscript: "NM_004974.4", parsimonySitesP: "0.0868403473613894", constantSitesP: "0.826987307949232", distinctSitesP: "0.136940547762191", brLenTotal: "0.3139" },
  { geneTranscript: "NM_006989.6", parsimonySitesP: "0.227895392278954", constantSitesP: "0.654628476546285", distinctSitesP: "0.297218762972188", brLenTotal: "0.9742" },
  { geneTranscript: "NM_016061.3", parsimonySitesP: "0.0826446280991736", constantSitesP: "0.743801652892562", distinctSitesP: "0.21763085399449", brLenTotal: "0.4463" },
  { geneTranscript: "NM_198552.3", parsimonySitesP: "0.23731884057971", constantSitesP: "0.603260869565217", distinctSitesP: "0.423913043478261", brLenTotal: "0.9472" },
  { geneTranscript: "NM_001584.3", parsimonySitesP: "0.0714285714285714", constantSitesP: "0.82312925170068", distinctSitesP: "0.133786848072562", brLenTotal: "0.3620" },
  { geneTranscript: "NM_178543.5", parsimonySitesP: "0.303493449781659", constantSitesP: "0.558224163027656", distinctSitesP: "0.402474526928675", brLenTotal: "1.4812" },
  { geneTranscript: "NM_153705.5", parsimonySitesP: "0.225509533201841", constantSitesP: "0.635765943458251", distinctSitesP: "0.328731097961867", brLenTotal: "0.8274" },
  { geneTranscript: "NM_030914.4", parsimonySitesP: "0.135313531353135", constantSitesP: "0.749174917491749", distinctSitesP: "0.244224422442244", brLenTotal: "0.5046" },
  { geneTranscript: "NM_001354435.2", parsimonySitesP: "0.303588027514408", constantSitesP: "0.523517382413088", distinctSitesP: "0.411786577430749", brLenTotal: "1.2304" },
  { geneTranscript: "NM_001271560.3", parsimonySitesP: "0.261294261294261", constantSitesP: "0.573870573870574", distinctSitesP: "0.36996336996337", brLenTotal: "0.8607" },
  { geneTranscript: "NM_004223.5", parsimonySitesP: "0.272331154684096", constantSitesP: "0.623093681917211", distinctSitesP: "0.381263616557734", brLenTotal: "0.9996" },
  { geneTranscript: "NM_004821.3", parsimonySitesP: "0.203100775193798", constantSitesP: "0.668217054263566", distinctSitesP: "0.30077519379845", brLenTotal: "0.7619" },
  { geneTranscript: "NM_001077621.2", parsimonySitesP: "0.119521912350598", constantSitesP: "0.782204515272244", distinctSitesP: "0.199203187250996", brLenTotal: "0.5382" },
  { geneTranscript: "NM_012110.4", parsimonySitesP: "0.0949494949494949", constantSitesP: "0.802020202020202", distinctSitesP: "0.167676767676768", brLenTotal: "0.5957" },
  { geneTranscript: "NM_203402.3", parsimonySitesP: "0.156392694063927", constantSitesP: "0.746575342465753", distinctSitesP: "0.220319634703196", brLenTotal: "0.6838" },
  { geneTranscript: "NM_001190479.3", parsimonySitesP: "0.205263157894737", constantSitesP: "0.667543859649123", distinctSitesP: "0.294736842105263", brLenTotal: "0.8162" },
  { geneTranscript: "NM_144683.4", parsimonySitesP: "0.23342175066313", constantSitesP: "0.638373121131742", distinctSitesP: "0.327144120247569", brLenTotal: "0.7771" },
  { geneTranscript: "NM_003361.4", parsimonySitesP: "0.311458333333333", constantSitesP: "0.551041666666667", distinctSitesP: "0.396875", brLenTotal: "1.1698" },
  { geneTranscript: "NM_001163315.3", parsimonySitesP: "0.122206371849738", constantSitesP: "0.796956728483119", distinctSitesP: "0.184022824536377", brLenTotal: "0.4064" },
  { geneTranscript: "NM_001013.4", parsimonySitesP: "0.230240549828179", constantSitesP: "0.59106529209622", distinctSitesP: "0.381443298969072", brLenTotal: "0.9726" },
  { geneTranscript: "NM_006348.5", parsimonySitesP: "0.167269802975472", constantSitesP: "0.706071572175312", distinctSitesP: "0.253317249698432", brLenTotal: "0.5254" },
  { geneTranscript: "NM_001256141.2", parsimonySitesP: "0.0746934225195095", constantSitesP: "0.819397993311037", distinctSitesP: "0.138238573021182", brLenTotal: "0.2449" },
  { geneTranscript: "NM_022367.4", parsimonySitesP: "0.231712658782304", constantSitesP: "0.62724485326325", distinctSitesP: "0.300919842312746", brLenTotal: "0.7712" },
  { geneTranscript: "NM_001039500.3", parsimonySitesP: "0.287517146776406", constantSitesP: "0.56241426611797", distinctSitesP: "0.38161865569273", brLenTotal: "1.1660" },
  { geneTranscript: "NM_004866.6", parsimonySitesP: "0.0927021696252465", constantSitesP: "0.832347140039448", distinctSitesP: "0.167652859960552", brLenTotal: "0.3070" },
  { geneTranscript: "NM_001144952.2", parsimonySitesP: "0.189073050951504", constantSitesP: "0.707489257213014", distinctSitesP: "0.218999386126458", brLenTotal: "0.9240" },
  { geneTranscript: "NM_001323289.2", parsimonySitesP: "0.140972222222222", constantSitesP: "0.783680555555556", distinctSitesP: "0.178125", brLenTotal: "0.4524" },
  { geneTranscript: "NM_017946.4", parsimonySitesP: "0.127962085308057", constantSitesP: "0.736176935229068", distinctSitesP: "0.229067930489731", brLenTotal: "0.4302" },
  { geneTranscript: "NM_001017919.2", parsimonySitesP: "0.354609929078014", constantSitesP: "0.480496453900709", distinctSitesP: "0.465425531914894", brLenTotal: "1.2471" },
  { geneTranscript: "NM_003511.3", parsimonySitesP: "0.282051282051282", constantSitesP: "0.525641025641026", distinctSitesP: "0.376923076923077", brLenTotal: "2.1767" },
  { geneTranscript: "NM_005235.3", parsimonySitesP: "0.147808358817533", constantSitesP: "0.757135575942915", distinctSitesP: "0.18756371049949", brLenTotal: "0.5318" },
  { geneTranscript: "NM_001009184.2", parsimonySitesP: "0.181491464510332", constantSitesP: "0.716082659478886", distinctSitesP: "0.259658580413297", brLenTotal: "0.7990" },
  { geneTranscript: "NM_173176.3", parsimonySitesP: "0.174430128840436", constantSitesP: "0.730426164519326", distinctSitesP: "0.222001982160555", brLenTotal: "0.7093" },
  { geneTranscript: "NM_003001.5", parsimonySitesP: "0.232741617357002", constantSitesP: "0.607495069033531", distinctSitesP: "0.382642998027613", brLenTotal: "0.8499" },
  { geneTranscript: "NM_181783.4", parsimonySitesP: "0.154631655725748", constantSitesP: "0.7472647702407", distinctSitesP: "0.210065645514223", brLenTotal: "0.4503" },
  { geneTranscript: "NM_016448.4", parsimonySitesP: "0.14337899543379", constantSitesP: "0.744748858447489", distinctSitesP: "0.202283105022831", brLenTotal: "0.4884" },
  { geneTranscript: "NM_013346.4", parsimonySitesP: "0.10082304526749", constantSitesP: "0.835390946502058", distinctSitesP: "0.164609053497942", brLenTotal: "0.2687" },
  { geneTranscript: "NM_001932.6", parsimonySitesP: "0.177207977207977", constantSitesP: "0.67977207977208", distinctSitesP: "0.255270655270655", brLenTotal: "0.7349" },
  { geneTranscript: "NM_001008409.5", parsimonySitesP: "0.18830675778284", constantSitesP: "0.669703872437358", distinctSitesP: "0.324981017463933", brLenTotal: "0.7776" },
  { geneTranscript: "NM_144626.3", parsimonySitesP: "0.26179604261796", constantSitesP: "0.621004566210046", distinctSitesP: "0.353120243531202", brLenTotal: "1.2182" },
  { geneTranscript: "NM_014000.3", parsimonySitesP: "0.109053497942387", constantSitesP: "0.811875367430923", distinctSitesP: "0.149617871840094", brLenTotal: "0.3827" },
  { geneTranscript: "NM_213607.3", parsimonySitesP: "0.301652892561983", constantSitesP: "0.549586776859504", distinctSitesP: "0.450413223140496", brLenTotal: "1.0109" },
  { geneTranscript: "NM_198853.3", parsimonySitesP: "0.22", constantSitesP: "0.617333333333333", distinctSitesP: "0.36", brLenTotal: "0.9137" },
  { geneTranscript: "NM_022156.5", parsimonySitesP: "0.221282593375617", constantSitesP: "0.65045806906272", distinctSitesP: "0.307258632840028", brLenTotal: "1.0092" },
  { geneTranscript: "NM_152326.4", parsimonySitesP: "0.271293375394322", constantSitesP: "0.602523659305994", distinctSitesP: "0.352260778128286", brLenTotal: "1.3139" },
  { geneTranscript: "NM_014434.4", parsimonySitesP: "0.294807370184255", constantSitesP: "0.560580681183696", distinctSitesP: "0.401451702959241", brLenTotal: "1.3622" },
  { geneTranscript: "NM_016529.6", parsimonySitesP: "0.178731762065095", constantSitesP: "0.741582491582492", distinctSitesP: "0.246632996632997", brLenTotal: "0.6557" },
  { geneTranscript: "NM_005253.4", parsimonySitesP: "0.133946830265849", constantSitesP: "0.763803680981595", distinctSitesP: "0.203476482617587", brLenTotal: "0.7707" },
  { geneTranscript: "NM_178422.6", parsimonySitesP: "0.241811175337187", constantSitesP: "0.59344894026975", distinctSitesP: "0.31888246628131", brLenTotal: "1.1031" },
  { geneTranscript: "NM_001018057.2", parsimonySitesP: "0.226666666666667", constantSitesP: "0.626666666666667", distinctSitesP: "0.341904761904762", brLenTotal: "0.9275" },
  { geneTranscript: "NM_001144.6", parsimonySitesP: "0.182996371176776", constantSitesP: "0.727319854847071", distinctSitesP: "0.245723172628305", brLenTotal: "0.6749" },
  { geneTranscript: "NM_000981.4", parsimonySitesP: "0.17687074829932", constantSitesP: "0.595238095238095", distinctSitesP: "0.384353741496599", brLenTotal: "0.7831" },
  { geneTranscript: "NM_014737.3", parsimonySitesP: "0.158486707566462", constantSitesP: "0.742331288343558", distinctSitesP: "0.248466257668712", brLenTotal: "0.6994" },
  { geneTranscript: "NM_032866.5", parsimonySitesP: "0.261392729134665", constantSitesP: "0.589349718381976", distinctSitesP: "0.370711725550435", brLenTotal: "0.9593" },
  { geneTranscript: "NM_001378974.1", parsimonySitesP: "0.115452930728242", constantSitesP: "0.778567199526347", distinctSitesP: "0.178211959739491", brLenTotal: "0.4669" },
  { geneTranscript: "NM_001206847.2", parsimonySitesP: "0.182456140350877", constantSitesP: "0.684210526315789", distinctSitesP: "0.291228070175439", brLenTotal: "0.5810" },
  { geneTranscript: "NM_001303007.2", parsimonySitesP: "0.168421052631579", constantSitesP: "0.715789473684211", distinctSitesP: "0.24327485380117", brLenTotal: "0.5671" },
  { geneTranscript: "NM_001042600.3", parsimonySitesP: "0.18838814453918", constantSitesP: "0.713357693869265", distinctSitesP: "0.254161591555014", brLenTotal: "0.6899" },
  { geneTranscript: "NM_002094.4", parsimonySitesP: "0.175300889586604", constantSitesP: "0.721611721611722", distinctSitesP: "0.261119832548404", brLenTotal: "0.7947" },
  { geneTranscript: "NM_004230.4", parsimonySitesP: "0.258734655335222", constantSitesP: "0.647780925401322", distinctSitesP: "0.313503305004721", brLenTotal: "1.2843" },
  { geneTranscript: "NM_001308210.2", parsimonySitesP: "0.217889198390591", constantSitesP: "0.691736304549675", distinctSitesP: "0.26679046734757", brLenTotal: "1.0806" },
  { geneTranscript: "NM_198539.4", parsimonySitesP: "0.428571428571429", constantSitesP: "0.473084886128364", distinctSitesP: "0.466873706004141", brLenTotal: "1.5276" },
  { geneTranscript: "NM_024072.4", parsimonySitesP: "0.260688611426409", constantSitesP: "0.583049564888384", distinctSitesP: "0.350359440030269", brLenTotal: "1.1487" },
  { geneTranscript: "NM_031989.5", parsimonySitesP: "0.0791896869244936", constantSitesP: "0.785451197053407", distinctSitesP: "0.184162062615101", brLenTotal: "0.3928" },
  { geneTranscript: "NM_001174072.3", parsimonySitesP: "0.235719450469993", constantSitesP: "0.667389732465654", distinctSitesP: "0.325379609544469", brLenTotal: "0.7922" },
  { geneTranscript: "NM_001034845.3", parsimonySitesP: "0.203549639489739", constantSitesP: "0.70216306156406", distinctSitesP: "0.27287853577371", brLenTotal: "0.7784" },
  { geneTranscript: "NM_033258.2", parsimonySitesP: "0.176190476190476", constantSitesP: "0.738095238095238", distinctSitesP: "0.266666666666667", brLenTotal: "0.5523" },
  { geneTranscript: "NM_001134366.2", parsimonySitesP: "0.182905982905983", constantSitesP: "0.728205128205128", distinctSitesP: "0.235327635327635", brLenTotal: "0.6103" },
  { geneTranscript: "NM_017549.5", parsimonySitesP: "0.255952380952381", constantSitesP: "0.616071428571429", distinctSitesP: "0.367559523809524", brLenTotal: "0.9043" },
  { geneTranscript: "NM_006687.4", parsimonySitesP: "0.31264367816092", constantSitesP: "0.539463601532567", distinctSitesP: "0.396934865900383", brLenTotal: "1.2447" },
  { geneTranscript: "NM_145040.3", parsimonySitesP: "0.234993614303959", constantSitesP: "0.59514687100894", distinctSitesP: "0.343550446998723", brLenTotal: "0.8876" },
  { geneTranscript: "NM_006626.6", parsimonySitesP: "0.137578616352201", constantSitesP: "0.737421383647799", distinctSitesP: "0.213050314465409", brLenTotal: "0.4211" },
  { geneTranscript: "NM_139209.3", parsimonySitesP: "0.21880650994575", constantSitesP: "0.650391802290536", distinctSitesP: "0.284508740204943", brLenTotal: "0.8048" },
  { geneTranscript: "NM_080747.3", parsimonySitesP: "0.268754076973255", constantSitesP: "0.591650358773646", distinctSitesP: "0.364644487932159", brLenTotal: "1.4734" },
  { geneTranscript: "NM_000503.6", parsimonySitesP: "0.13231981981982", constantSitesP: "0.782094594594595", distinctSitesP: "0.190315315315315", brLenTotal: "0.4737" },
  { geneTranscript: "NM_002556.3", parsimonySitesP: "0.135894258570838", constantSitesP: "0.74514663362247", distinctSitesP: "0.197439074762495", brLenTotal: "0.5108" },
  { geneTranscript: "NM_033448.3", parsimonySitesP: "0.247928616953474", constantSitesP: "0.609305289993627", distinctSitesP: "0.353728489483748", brLenTotal: "1.5196" },
  { geneTranscript: "NM_001348.3", parsimonySitesP: "0.224669603524229", constantSitesP: "0.665932452276065", distinctSitesP: "0.307635829662261", brLenTotal: "1.1420" },
  { geneTranscript: "NM_018146.4", parsimonySitesP: "0.326190476190476", constantSitesP: "0.511904761904762", distinctSitesP: "0.446031746031746", brLenTotal: "1.1598" },
  { geneTranscript: "NM_022733.3", parsimonySitesP: "0.128982128982129", constantSitesP: "0.773115773115773", distinctSitesP: "0.197358197358197", brLenTotal: "0.4122" },
  { geneTranscript: "NM_000316.3", parsimonySitesP: "0.171444631815627", constantSitesP: "0.712759977515458", distinctSitesP: "0.222596964586847", brLenTotal: "0.6646" },
  { geneTranscript: "NM_001001824.2", parsimonySitesP: "0.490010515247108", constantSitesP: "0.34279705573081", distinctSitesP: "0.650893796004206", brLenTotal: "3.0279" },
  { geneTranscript: "NM_001276277.3", parsimonySitesP: "0.136497720568517", constantSitesP: "0.768838830785733", distinctSitesP: "0.183159023866988", brLenTotal: "0.4501" },
  { geneTranscript: "NM_017873.4", parsimonySitesP: "0.213776722090261", constantSitesP: "0.661916072842439", distinctSitesP: "0.281076801266825", brLenTotal: "1.0303" },
  { geneTranscript: "NM_016301.4", parsimonySitesP: "0.146713615023474", constantSitesP: "0.753521126760563", distinctSitesP: "0.22887323943662", brLenTotal: "0.5048" },
  { geneTranscript: "NM_006500.3", parsimonySitesP: "0.237874097007224", constantSitesP: "0.624355005159959", distinctSitesP: "0.315789473684211", brLenTotal: "0.8768" },
  { geneTranscript: "NM_176882.2", parsimonySitesP: "0.35500515995872", constantSitesP: "0.474716202270382", distinctSitesP: "0.508771929824561", brLenTotal: "1.3434" },
  { geneTranscript: "NM_000090.4", parsimonySitesP: "0.189631650750341", constantSitesP: "0.708503865393361", distinctSitesP: "0.247157798999545", brLenTotal: "0.6709" },
  { geneTranscript: "NM_015972.4", parsimonySitesP: "0.208020050125313", constantSitesP: "0.50125313283208", distinctSitesP: "0.441102756892231", brLenTotal: "0.9098" },
  { geneTranscript: "NM_032353.4", parsimonySitesP: "0.113636363636364", constantSitesP: "0.831439393939394", distinctSitesP: "0.176136363636364", brLenTotal: "0.3080" },
  { geneTranscript: "NM_152349.3", parsimonySitesP: "0.135593220338983", constantSitesP: "0.768361581920904", distinctSitesP: "0.238418079096045", brLenTotal: "0.4485" },
  { geneTranscript: "NM_001177316.2", parsimonySitesP: "0.360601001669449", constantSitesP: "0.509738452977184", distinctSitesP: "0.457985531441291", brLenTotal: "1.5433" },
  { geneTranscript: "NM_007224.4", parsimonySitesP: "0.230519480519481", constantSitesP: "0.636363636363636", distinctSitesP: "0.325757575757576", brLenTotal: "0.8977" },
  { geneTranscript: "NM_016269.5", parsimonySitesP: "0.191311612364244", constantSitesP: "0.746867167919799", distinctSitesP: "0.23141186299081", brLenTotal: "0.6521" },
  { geneTranscript: "NM_002106.4", parsimonySitesP: "0.122395833333333", constantSitesP: "0.817708333333333", distinctSitesP: "0.184895833333333", brLenTotal: "0.3634" },
  { geneTranscript: "NM_004283.4", parsimonySitesP: "0.255707762557078", constantSitesP: "0.666666666666667", distinctSitesP: "0.336377473363775", brLenTotal: "1.1912" },
  { geneTranscript: "NM_005599.3", parsimonySitesP: "0.133333333333333", constantSitesP: "0.777777777777778", distinctSitesP: "0.224691358024691", brLenTotal: "0.4602" },
  { geneTranscript: "NM_032259.4", parsimonySitesP: "0.212658227848101", constantSitesP: "0.70379746835443", distinctSitesP: "0.240506329113924", brLenTotal: "0.9797" },
  { geneTranscript: "NM_152222.2", parsimonySitesP: "0.26046511627907", constantSitesP: "0.58062015503876", distinctSitesP: "0.375193798449612", brLenTotal: "1.0698" },
  { geneTranscript: "NM_001271875.2", parsimonySitesP: "0.208117443868739", constantSitesP: "0.656303972366149", distinctSitesP: "0.329015544041451", brLenTotal: "0.8971" },
  { geneTranscript: "NM_001111125.3", parsimonySitesP: "0.117831541218638", constantSitesP: "0.787186379928315", distinctSitesP: "0.156362007168459", brLenTotal: "0.4265" },
  { geneTranscript: "NM_006983.2", parsimonySitesP: "0.24017094017094", constantSitesP: "0.599145299145299", distinctSitesP: "0.341025641025641", brLenTotal: "1.1454" },
  { geneTranscript: "NM_005450.6", parsimonySitesP: "0.0704022988505747", constantSitesP: "0.857758620689655", distinctSitesP: "0.136494252873563", brLenTotal: "0.2687" },
  { geneTranscript: "NM_003139.4", parsimonySitesP: "0.172413793103448", constantSitesP: "0.714733542319749", distinctSitesP: "0.221525600835946", brLenTotal: "0.6646" },
  { geneTranscript: "NM_001378100.1", parsimonySitesP: "0.197167755991285", constantSitesP: "0.66557734204793", distinctSitesP: "0.313725490196078", brLenTotal: "0.8401" },
  { geneTranscript: "NM_004006.3", parsimonySitesP: "0.134961555857078", constantSitesP: "0.754319312528268", distinctSitesP: "0.161284486657621", brLenTotal: "0.4249" },
  { geneTranscript: "NM_001113561.2", parsimonySitesP: "0.158220720720721", constantSitesP: "0.710585585585586", distinctSitesP: "0.260698198198198", brLenTotal: "0.5022" },
  { geneTranscript: "NM_024594.4", parsimonySitesP: "0.259459459459459", constantSitesP: "0.571171171171171", distinctSitesP: "0.38018018018018", brLenTotal: "1.5653" },
  { geneTranscript: "NM_032361.4", parsimonySitesP: "0.165242165242165", constantSitesP: "0.702754036087369", distinctSitesP: "0.263057929724596", brLenTotal: "0.6372" },
  { geneTranscript: "NM_001005404.4", parsimonySitesP: "0.100840336134454", constantSitesP: "0.829131652661064", distinctSitesP: "0.168067226890756", brLenTotal: "0.3018" },
  { geneTranscript: "NM_000278.5", parsimonySitesP: "0.0710659898477157", constantSitesP: "0.862944162436548", distinctSitesP: "0.119289340101523", brLenTotal: "0.3252" },
  { geneTranscript: "NM_025250.3", parsimonySitesP: "0.188655194391332", constantSitesP: "0.701720841300191", distinctSitesP: "0.253664754620778", brLenTotal: "0.9237" },
  { geneTranscript: "NM_015321.3", parsimonySitesP: "0.198212407991588", constantSitesP: "0.682439537329127", distinctSitesP: "0.272870662460568", brLenTotal: "0.9923" },
  { geneTranscript: "NM_003618.4", parsimonySitesP: "0.121923937360179", constantSitesP: "0.800521998508576", distinctSitesP: "0.175615212527964", brLenTotal: "0.4103" },
  { geneTranscript: "NM_019612.4", parsimonySitesP: "0.266378689704824", constantSitesP: "0.606191504679626", distinctSitesP: "0.354931605471562", brLenTotal: "1.0921" },
  { geneTranscript: "NM_004612.4", parsimonySitesP: "0.153744201457919", constantSitesP: "0.725646123260437", distinctSitesP: "0.231278992710404", brLenTotal: "0.5680" },
  { geneTranscript: "NM_000742.4", parsimonySitesP: "0.353497164461248", constantSitesP: "0.516068052930057", distinctSitesP: "0.457466918714556", brLenTotal: "2.0233" },
  { geneTranscript: "NM_005378.6", parsimonySitesP: "0.238505747126437", constantSitesP: "0.610632183908046", distinctSitesP: "0.368534482758621", brLenTotal: "0.9276" },
  { geneTranscript: "NM_033267.5", parsimonySitesP: "0.225053078556263", constantSitesP: "0.662420382165605", distinctSitesP: "0.302193913658882", brLenTotal: "1.2326" },
  { geneTranscript: "NM_015704.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.799603174603175", distinctSitesP: "0.202380952380952", brLenTotal: "0.3223" },
  { geneTranscript: "NM_001355042.2", parsimonySitesP: "0.348765432098765", constantSitesP: "0.438271604938272", distinctSitesP: "0.567901234567901", brLenTotal: "1.2409" },
  { geneTranscript: "NM_001287135.2", parsimonySitesP: "0.085998578535892", constantSitesP: "0.818052594171997", distinctSitesP: "0.14498933901919", brLenTotal: "0.3388" },
  { geneTranscript: "NM_016143.5", parsimonySitesP: "0.13963963963964", constantSitesP: "0.737837837837838", distinctSitesP: "0.224324324324324", brLenTotal: "0.4846" },
  { geneTranscript: "NM_032145.5", parsimonySitesP: "0.16331096196868", constantSitesP: "0.720357941834452", distinctSitesP: "0.219686800894855", brLenTotal: "0.4962" },
  { geneTranscript: "NM_175733.4", parsimonySitesP: "0.10183299389002", constantSitesP: "0.807196198234895", distinctSitesP: "0.158859470468432", brLenTotal: "0.3804" },
  { geneTranscript: "NM_015721.3", parsimonySitesP: "0.291430371770636", constantSitesP: "0.572463768115942", distinctSitesP: "0.356017643352237", brLenTotal: "1.0195" },
  { geneTranscript: "NM_001369521.2", parsimonySitesP: "0.131830601092896", constantSitesP: "0.76775956284153", distinctSitesP: "0.173497267759563", brLenTotal: "0.4285" },
  { geneTranscript: "NM_024954.5", parsimonySitesP: "0.146842878120411", constantSitesP: "0.762114537444934", distinctSitesP: "0.215859030837004", brLenTotal: "0.7024" },
  { geneTranscript: "NM_001099678.2", parsimonySitesP: "0.171608265947889", constantSitesP: "0.708894878706199", distinctSitesP: "0.265947888589398", brLenTotal: "0.5298" },
  { geneTranscript: "NM_018058.7", parsimonySitesP: "0.174987392839133", constantSitesP: "0.715078164397378", distinctSitesP: "0.249621785173979", brLenTotal: "0.9080" },
  { geneTranscript: "NM_005234.4", parsimonySitesP: "0.23019801980198", constantSitesP: "0.655115511551155", distinctSitesP: "0.293729372937294", brLenTotal: "1.0886" },
  { geneTranscript: "NM_002597.5", parsimonySitesP: "0.193766937669377", constantSitesP: "0.668021680216802", distinctSitesP: "0.31029810298103", brLenTotal: "0.6513" },
  { geneTranscript: "NM_005955.3", parsimonySitesP: "0.139442231075697", constantSitesP: "0.748561310314298", distinctSitesP: "0.209384683488269", brLenTotal: "0.4549" },
  { geneTranscript: "NM_130439.3", parsimonySitesP: "0.0983050847457627", constantSitesP: "0.820338983050847", distinctSitesP: "0.176271186440678", brLenTotal: "0.2847" },
  { geneTranscript: "NM_015085.5", parsimonySitesP: "0.175342465753425", constantSitesP: "0.719634703196347", distinctSitesP: "0.253424657534247", brLenTotal: "0.6982" },
  { geneTranscript: "NM_030971.6", parsimonySitesP: "0.174454828660436", constantSitesP: "0.724818276220145", distinctSitesP: "0.272066458982347", brLenTotal: "0.6762" },
  { geneTranscript: "NM_173165.3", parsimonySitesP: "0.145116279069767", constantSitesP: "0.744806201550388", distinctSitesP: "0.194108527131783", brLenTotal: "0.4378" },
  { geneTranscript: "NM_001143981.2", parsimonySitesP: "0.126637554585153", constantSitesP: "0.7882096069869", distinctSitesP: "0.189956331877729", brLenTotal: "0.3881" },
  { geneTranscript: "NM_021960.5", parsimonySitesP: "0.184761904761905", constantSitesP: "0.64952380952381", distinctSitesP: "0.298095238095238", brLenTotal: "0.7241" },
  { geneTranscript: "NM_001003665.4", parsimonySitesP: "0.144208037825059", constantSitesP: "0.773049645390071", distinctSitesP: "0.250591016548463", brLenTotal: "0.5458" },
  { geneTranscript: "NM_018138.5", parsimonySitesP: "0.189108318372232", constantSitesP: "0.671454219030521", distinctSitesP: "0.289048473967684", brLenTotal: "0.6187" },
  { geneTranscript: "NM_001170738.2", parsimonySitesP: "0.279187817258883", constantSitesP: "0.580936266215454", distinctSitesP: "0.37366046249295", brLenTotal: "1.2687" },
  { geneTranscript: "NM_012208.4", parsimonySitesP: "0.243741765480896", constantSitesP: "0.60935441370224", distinctSitesP: "0.332674571805007", brLenTotal: "0.8900" },
  { geneTranscript: "NM_005071.3", parsimonySitesP: "0.236997635933806", constantSitesP: "0.684988179669031", distinctSitesP: "0.279550827423168", brLenTotal: "1.1203" },
  { geneTranscript: "NM_002747.4", parsimonySitesP: "0.227143668370244", constantSitesP: "0.646223736513345", distinctSitesP: "0.292447473026689", brLenTotal: "1.1962" },
  { geneTranscript: "NM_018154.3", parsimonySitesP: "0.1996699669967", constantSitesP: "0.674917491749175", distinctSitesP: "0.305280528052805", brLenTotal: "0.7444" },
  { geneTranscript: "NM_000688.6", parsimonySitesP: "0.169791666666667", constantSitesP: "0.715625", distinctSitesP: "0.229166666666667", brLenTotal: "0.6234" },
  { geneTranscript: "NM_006834.5", parsimonySitesP: "0.219259259259259", constantSitesP: "0.665185185185185", distinctSitesP: "0.325925925925926", brLenTotal: "0.6711" },
  { geneTranscript: "NM_001100910.2", parsimonySitesP: "0.138702460850112", constantSitesP: "0.74496644295302", distinctSitesP: "0.248322147651007", brLenTotal: "0.4237" },
  { geneTranscript: "NM_018926.3", parsimonySitesP: "0.41326164874552", constantSitesP: "0.44910394265233", distinctSitesP: "0.532974910394265", brLenTotal: "2.8186" },
  { geneTranscript: "NM_012215.5", parsimonySitesP: "0.12117903930131", constantSitesP: "0.779112081513828", distinctSitesP: "0.178675400291121", brLenTotal: "0.4114" },
  { geneTranscript: "NM_178175.4", parsimonySitesP: "0.13030303030303", constantSitesP: "0.759090909090909", distinctSitesP: "0.218181818181818", brLenTotal: "0.4319" },
  { geneTranscript: "NM_033386.4", parsimonySitesP: "0.273850907686365", constantSitesP: "0.560834298957126", distinctSitesP: "0.367709540363075", brLenTotal: "1.1202" },
  { geneTranscript: "NM_017703.3", parsimonySitesP: "0.211656441717791", constantSitesP: "0.673824130879346", distinctSitesP: "0.275051124744376", brLenTotal: "0.8371" },
  { geneTranscript: "NM_152678.3", parsimonySitesP: "0.141995614035088", constantSitesP: "0.766995614035088", distinctSitesP: "0.219298245614035", brLenTotal: "0.4090" },
  { geneTranscript: "NM_012199.5", parsimonySitesP: "0.0941267989109296", constantSitesP: "0.837028393621159", distinctSitesP: "0.12290937378452", brLenTotal: "0.2901" },
  { geneTranscript: "NM_001127178.3", parsimonySitesP: "0.257714479484571", constantSitesP: "0.607663614784673", distinctSitesP: "0.371990505256019", brLenTotal: "0.9624" },
  { geneTranscript: "NM_020704.3", parsimonySitesP: "0.144284572342126", constantSitesP: "0.735411670663469", distinctSitesP: "0.194244604316547", brLenTotal: "0.4979" },
  { geneTranscript: "NM_014346.5", parsimonySitesP: "0.246292714377821", constantSitesP: "0.653771760154739", distinctSitesP: "0.335267569310123", brLenTotal: "1.0544" },
  { geneTranscript: "NM_024112.4", parsimonySitesP: "0.152610441767068", constantSitesP: "0.714859437751004", distinctSitesP: "0.289156626506024", brLenTotal: "0.5443" },
  { geneTranscript: "NM_032998.3", parsimonySitesP: "0.10377358490566", constantSitesP: "0.714884696016771", distinctSitesP: "0.191823899371069", brLenTotal: "0.4923" },
  { geneTranscript: "NM_001009993.4", parsimonySitesP: "0.131623931623932", constantSitesP: "0.781196581196581", distinctSitesP: "0.181196581196581", brLenTotal: "0.6489" },
  { geneTranscript: "NM_004818.3", parsimonySitesP: "0.128455284552846", constantSitesP: "0.759349593495935", distinctSitesP: "0.184959349593496", brLenTotal: "0.4295" },
  { geneTranscript: "NM_080732.4", parsimonySitesP: "0.166257166257166", constantSitesP: "0.680589680589681", distinctSitesP: "0.265356265356265", brLenTotal: "0.6956" },
  { geneTranscript: "NM_025207.5", parsimonySitesP: "0.257240204429302", constantSitesP: "0.612720045428734", distinctSitesP: "0.323111868256672", brLenTotal: "0.8890" },
  { geneTranscript: "NM_000709.4", parsimonySitesP: "0.204494382022472", constantSitesP: "0.695131086142322", distinctSitesP: "0.273408239700375", brLenTotal: "0.8570" },
  { geneTranscript: "NM_031935.3", parsimonySitesP: "0.220408163265306", constantSitesP: "0.63768115942029", distinctSitesP: "0.264359656906241", brLenTotal: "0.7874" },
  { geneTranscript: "NM_012419.5", parsimonySitesP: "0.13015873015873", constantSitesP: "0.787301587301587", distinctSitesP: "0.201587301587302", brLenTotal: "0.4343" },
  { geneTranscript: "NM_133459.4", parsimonySitesP: "0.192939244663383", constantSitesP: "0.675697865353038", distinctSitesP: "0.314449917898194", brLenTotal: "0.7969" },
  { geneTranscript: "NM_001080439.3", parsimonySitesP: "0.184004474272931", constantSitesP: "0.655480984340045", distinctSitesP: "0.291946308724832", brLenTotal: "0.6232" },
  { geneTranscript: "NM_001005163.2", parsimonySitesP: "0.510482180293501", constantSitesP: "0.30293501048218", distinctSitesP: "0.657232704402516", brLenTotal: "1.8689" },
  { geneTranscript: "NM_002601.4", parsimonySitesP: "0.0911111111111111", constantSitesP: "0.842222222222222", distinctSitesP: "0.153333333333333", brLenTotal: "0.2192" },
  { geneTranscript: "NM_032342.3", parsimonySitesP: "0.157154673283706", constantSitesP: "0.751033912324235", distinctSitesP: "0.211745244003309", brLenTotal: "0.6501" },
  { geneTranscript: "NM_005978.4", parsimonySitesP: "0.248299319727891", constantSitesP: "0.625850340136054", distinctSitesP: "0.391156462585034", brLenTotal: "0.8534" },
  { geneTranscript: "NM_002264.4", parsimonySitesP: "0.0954151177199504", constantSitesP: "0.815985130111524", distinctSitesP: "0.143122676579926", brLenTotal: "0.3200" },
  { geneTranscript: "NM_001393500.2", parsimonySitesP: "0.222222222222222", constantSitesP: "0.642118863049096", distinctSitesP: "0.315245478036176", brLenTotal: "0.7721" },
  { geneTranscript: "NM_001129729.3", parsimonySitesP: "0.275958578225581", constantSitesP: "0.55247691015953", distinctSitesP: "0.314581584102995", brLenTotal: "0.9219" },
  { geneTranscript: "NM_003846.3", parsimonySitesP: "0.193050193050193", constantSitesP: "0.628056628056628", distinctSitesP: "0.325611325611326", brLenTotal: "0.6747" },
  { geneTranscript: "NM_006622.4", parsimonySitesP: "0.144525547445255", constantSitesP: "0.759610705596107", distinctSitesP: "0.193187347931873", brLenTotal: "0.4503" },
  { geneTranscript: "NM_173517.6", parsimonySitesP: "0.15719696969697", constantSitesP: "0.712121212121212", distinctSitesP: "0.28030303030303", brLenTotal: "0.5668" },
  { geneTranscript: "NM_003376.6", parsimonySitesP: "0.192405063291139", constantSitesP: "0.691139240506329", distinctSitesP: "0.264978902953586", brLenTotal: "0.5957" },
  { geneTranscript: "NM_012234.6", parsimonySitesP: "0.128440366972477", constantSitesP: "0.813455657492355", distinctSitesP: "0.175840978593272", brLenTotal: "0.4169" },
  { geneTranscript: "NM_018638.5", parsimonySitesP: "0.101928374655647", constantSitesP: "0.775022956841139", distinctSitesP: "0.178145087235996", brLenTotal: "0.3649" },
  { geneTranscript: "NM_000815.5", parsimonySitesP: "0.214601769911504", constantSitesP: "0.699115044247788", distinctSitesP: "0.276548672566372", brLenTotal: "1.2967" },
  { geneTranscript: "NM_005937.4", parsimonySitesP: "0.149435803598658", constantSitesP: "0.756328148825862", distinctSitesP: "0.184812442817932", brLenTotal: "0.5716" },
  { geneTranscript: "NM_001243177.4", parsimonySitesP: "0.258373205741627", constantSitesP: "0.633971291866029", distinctSitesP: "0.319776714513557", brLenTotal: "1.1466" },
  { geneTranscript: "NM_181644.5", parsimonySitesP: "0.176394293125811", constantSitesP: "0.715304798962387", distinctSitesP: "0.246433203631647", brLenTotal: "0.7273" },
  { geneTranscript: "NM_001101391.3", parsimonySitesP: "0.389076576576577", constantSitesP: "0.530968468468468", distinctSitesP: "0.275337837837838", brLenTotal: "1.3370" },
  { geneTranscript: "NM_000223.4", parsimonySitesP: "0.246963562753036", constantSitesP: "0.62280701754386", distinctSitesP: "0.336707152496626", brLenTotal: "1.2442" },
  { geneTranscript: "NM_004291.4", parsimonySitesP: "0.175287356321839", constantSitesP: "0.71264367816092", distinctSitesP: "0.275862068965517", brLenTotal: "0.5706" },
  { geneTranscript: "NM_178012.5", parsimonySitesP: "0.239700374531835", constantSitesP: "0.627715355805243", distinctSitesP: "0.330337078651685", brLenTotal: "2.3185" },
  { geneTranscript: "NM_021224.6", parsimonySitesP: "0.148709763234903", constantSitesP: "0.765230114392126", distinctSitesP: "0.160947060388401", brLenTotal: "0.5411" },
  { geneTranscript: "NM_001361665.2", parsimonySitesP: "0.124731182795699", constantSitesP: "0.76989247311828", distinctSitesP: "0.208602150537634", brLenTotal: "0.3480" },
  { geneTranscript: "NM_003904.5", parsimonySitesP: "0.192447349310094", constantSitesP: "0.668845315904139", distinctSitesP: "0.285403050108932", brLenTotal: "0.6455" },
  { geneTranscript: "NM_058229.4", parsimonySitesP: "0.133333333333333", constantSitesP: "0.756807511737089", distinctSitesP: "0.191549295774648", brLenTotal: "0.5066" },
  { geneTranscript: "NM_001005181.2", parsimonySitesP: "0.533960292580982", constantSitesP: "0.298850574712644", distinctSitesP: "0.693834900731452", brLenTotal: "3.0795" },
  { geneTranscript: "NM_004285.4", parsimonySitesP: "0.252844500632111", constantSitesP: "0.611462284028656", distinctSitesP: "0.306363253265908", brLenTotal: "1.1557" },
  { geneTranscript: "NM_032366.5", parsimonySitesP: "0.243464052287582", constantSitesP: "0.638888888888889", distinctSitesP: "0.366013071895425", brLenTotal: "0.8916" },
  { geneTranscript: "NM_001731.3", parsimonySitesP: "0.192982456140351", constantSitesP: "0.582846003898635", distinctSitesP: "0.364522417153996", brLenTotal: "0.6980" },
  { geneTranscript: "NM_001204375.2", parsimonySitesP: "0.188539741219963", constantSitesP: "0.70486752926679", distinctSitesP: "0.269870609981516", brLenTotal: "0.6367" },
  { geneTranscript: "NM_015073.3", parsimonySitesP: "0.21654501216545", constantSitesP: "0.668351113606588", distinctSitesP: "0.260527793374509", brLenTotal: "0.9964" },
  { geneTranscript: "NM_001080515.3", parsimonySitesP: "0.218875502008032", constantSitesP: "0.664658634538153", distinctSitesP: "0.313253012048193", brLenTotal: "1.0108" },
  { geneTranscript: "NM_001353694.2", parsimonySitesP: "0.155667295202179", constantSitesP: "0.752147496333543", distinctSitesP: "0.191703331238215", brLenTotal: "0.6149" },
  { geneTranscript: "NM_002765.5", parsimonySitesP: "0.19916142557652", constantSitesP: "0.682389937106918", distinctSitesP: "0.291404612159329", brLenTotal: "0.9484" },
  { geneTranscript: "NM_002465.4", parsimonySitesP: "0.189012240250498", constantSitesP: "0.710503842869342", distinctSitesP: "0.27355536578423", brLenTotal: "0.7233" },
  { geneTranscript: "NM_003211.6", parsimonySitesP: "0.17479674796748", constantSitesP: "0.678861788617886", distinctSitesP: "0.339837398373984", brLenTotal: "0.6847" },
  { geneTranscript: "NM_014041.5", parsimonySitesP: "0.11437908496732", constantSitesP: "0.777777777777778", distinctSitesP: "0.215686274509804", brLenTotal: "0.3569" },
  { geneTranscript: "NM_197956.4", parsimonySitesP: "0.170234454638124", constantSitesP: "0.73190621814475", distinctSitesP: "0.236493374108053", brLenTotal: "0.7897" },
  { geneTranscript: "NM_001303512.2", parsimonySitesP: "0.214193548387097", constantSitesP: "0.621935483870968", distinctSitesP: "0.281720430107527", brLenTotal: "0.9178" },
  { geneTranscript: "NM_002063.4", parsimonySitesP: "0.151917404129794", constantSitesP: "0.778023598820059", distinctSitesP: "0.199115044247788", brLenTotal: "0.5561" },
  { geneTranscript: "NM_003393.4", parsimonySitesP: "0.158594491927825", constantSitesP: "0.733143399810066", distinctSitesP: "0.226020892687559", brLenTotal: "0.5649" },
  { geneTranscript: "NM_006870.4", parsimonySitesP: "0.22020202020202", constantSitesP: "0.604040404040404", distinctSitesP: "0.343434343434343", brLenTotal: "0.6529" },
  { geneTranscript: "NM_001740.5", parsimonySitesP: "0.138991389913899", constantSitesP: "0.779827798277983", distinctSitesP: "0.225092250922509", brLenTotal: "0.5396" },
  { geneTranscript: "NM_001145451.5", parsimonySitesP: "0.139846743295019", constantSitesP: "0.755172413793103", distinctSitesP: "0.210344827586207", brLenTotal: "0.4774" },
  { geneTranscript: "NM_004114.5", parsimonySitesP: "0.119727891156463", constantSitesP: "0.80952380952381", distinctSitesP: "0.182312925170068", brLenTotal: "0.4201" },
  { geneTranscript: "NM_006176.3", parsimonySitesP: "0.11965811965812", constantSitesP: "0.820512820512821", distinctSitesP: "0.205128205128205", brLenTotal: "0.4168" },
  { geneTranscript: "NM_001013663.2", parsimonySitesP: "0.25", constantSitesP: "0.60952380952381", distinctSitesP: "0.383333333333333", brLenTotal: "0.7755" },
  { geneTranscript: "NM_001004748.1", parsimonySitesP: "0.478168264110756", constantSitesP: "0.340788072417465", distinctSitesP: "0.645367412140575", brLenTotal: "2.5901" },
  { geneTranscript: "NM_004535.3", parsimonySitesP: "0.210526315789474", constantSitesP: "0.672613737734166", distinctSitesP: "0.281891168599465", brLenTotal: "1.1815" },
  { geneTranscript: "NM_006517.5", parsimonySitesP: "0.165120593692022", constantSitesP: "0.750773036487322", distinctSitesP: "0.194805194805195", brLenTotal: "0.5188" },
  { geneTranscript: "NM_002764.4", parsimonySitesP: "0.170859538784067", constantSitesP: "0.689727463312369", distinctSitesP: "0.277777777777778", brLenTotal: "0.7133" },
  { geneTranscript: "NM_001136218.2", parsimonySitesP: "0.227931488801054", constantSitesP: "0.66798418972332", distinctSitesP: "0.345191040843215", brLenTotal: "1.0023" },
  { geneTranscript: "NM_000061.3", parsimonySitesP: "0.126454223571067", constantSitesP: "0.783004552352049", distinctSitesP: "0.174001011633789", brLenTotal: "0.3780" },
  { geneTranscript: "NM_016298.4", parsimonySitesP: "0.251998119417019", constantSitesP: "0.627644569816643", distinctSitesP: "0.323930418429713", brLenTotal: "0.9006" },
  { geneTranscript: "NM_015934.5", parsimonySitesP: "0.138626339004411", constantSitesP: "0.75992438563327", distinctSitesP: "0.228103339634531", brLenTotal: "0.4713" },
  { geneTranscript: "NM_024297.3", parsimonySitesP: "0.136476426799007", constantSitesP: "0.764267990074442", distinctSitesP: "0.206782464846981", brLenTotal: "0.4284" },
  { geneTranscript: "NM_007350.3", parsimonySitesP: "0.220282626766417", constantSitesP: "0.626766417290108", distinctSitesP: "0.372402327514547", brLenTotal: "1.0904" },
  { geneTranscript: "NM_002709.3", parsimonySitesP: "0.140672782874618", constantSitesP: "0.765545361875637", distinctSitesP: "0.205912334352701", brLenTotal: "0.4943" },
  { geneTranscript: "NM_004295.4", parsimonySitesP: "0.149645390070922", constantSitesP: "0.758156028368794", distinctSitesP: "0.194326241134752", brLenTotal: "0.4996" },
  { geneTranscript: "NM_152219.4", parsimonySitesP: "0.22562358276644", constantSitesP: "0.600907029478458", distinctSitesP: "0.375283446712018", brLenTotal: "0.9352" },
  { geneTranscript: "NM_000190.4", parsimonySitesP: "0.197599261311173", constantSitesP: "0.686057248384118", distinctSitesP: "0.285318559556787", brLenTotal: "0.5773" },
  { geneTranscript: "NM_002954.6", parsimonySitesP: "0.141025641025641", constantSitesP: "0.722222222222222", distinctSitesP: "0.288461538461538", brLenTotal: "0.5482" },
  { geneTranscript: "NM_052858.6", parsimonySitesP: "0.359102244389027", constantSitesP: "0.485453034081463", distinctSitesP: "0.521197007481297", brLenTotal: "1.3660" },
  { geneTranscript: "NM_033551.3", parsimonySitesP: "0.17183698296837", constantSitesP: "0.69647201946472", distinctSitesP: "0.225973236009732", brLenTotal: "0.5888" },
  { geneTranscript: "NM_005693.4", parsimonySitesP: "0.192393736017897", constantSitesP: "0.659209545115585", distinctSitesP: "0.307979120059657", brLenTotal: "0.6921" },
  { geneTranscript: "NM_013302.5", parsimonySitesP: "0.210114942528736", constantSitesP: "0.707126436781609", distinctSitesP: "0.270344827586207", brLenTotal: "0.8783" },
  { geneTranscript: "NM_001364669.2", parsimonySitesP: "0.25089605734767", constantSitesP: "0.611111111111111", distinctSitesP: "0.39247311827957", brLenTotal: "0.9694" },
  { geneTranscript: "NM_006899.5", parsimonySitesP: "0.162770562770563", constantSitesP: "0.741991341991342", distinctSitesP: "0.234632034632035", brLenTotal: "0.5389" },
  { geneTranscript: "NM_021728.4", parsimonySitesP: "0.0976430976430976", constantSitesP: "0.808080808080808", distinctSitesP: "0.169472502805836", brLenTotal: "0.3702" },
  { geneTranscript: "NM_138272.3", parsimonySitesP: "0.30567081604426", constantSitesP: "0.511756569847856", distinctSitesP: "0.438450899031812", brLenTotal: "0.9601" },
  { geneTranscript: "NM_006953.4", parsimonySitesP: "0.268292682926829", constantSitesP: "0.564459930313589", distinctSitesP: "0.407665505226481", brLenTotal: "1.1080" },
  { geneTranscript: "NM_006567.5", parsimonySitesP: "0.237989652623799", constantSitesP: "0.626755358462676", distinctSitesP: "0.348115299334812", brLenTotal: "0.8797" },
  { geneTranscript: "NM_005343.4", parsimonySitesP: "0.239858906525573", constantSitesP: "0.696649029982363", distinctSitesP: "0.291005291005291", brLenTotal: "1.1347" },
  { geneTranscript: "NM_002565.4", parsimonySitesP: "0.294063926940639", constantSitesP: "0.469406392694064", distinctSitesP: "0.410958904109589", brLenTotal: "1.3505" },
  { geneTranscript: "NM_002198.3", parsimonySitesP: "0.204102564102564", constantSitesP: "0.677948717948718", distinctSitesP: "0.303589743589744", brLenTotal: "0.7142" },
  { geneTranscript: "NM_005028.5", parsimonySitesP: "0.155172413793103", constantSitesP: "0.75944170771757", distinctSitesP: "0.230706075533662", brLenTotal: "0.6986" },
  { geneTranscript: "NM_002855.5", parsimonySitesP: "0.154094132817537", constantSitesP: "0.756931012250161", distinctSitesP: "0.212121212121212", brLenTotal: "0.6617" },
  { geneTranscript: "NM_018921.3", parsimonySitesP: "0.417381974248927", constantSitesP: "0.427753934191702", distinctSitesP: "0.516094420600858", brLenTotal: "3.7568" },
  { geneTranscript: "NM_205850.3", parsimonySitesP: "0.172", constantSitesP: "0.697333333333333", distinctSitesP: "0.271333333333333", brLenTotal: "0.5513" },
  { geneTranscript: "NM_002479.6", parsimonySitesP: "0.178571428571429", constantSitesP: "0.735119047619048", distinctSitesP: "0.257440476190476", brLenTotal: "0.7426" },
  { geneTranscript: "NM_001393530.1", parsimonySitesP: "0.226620768789443", constantSitesP: "0.6592082616179", distinctSitesP: "0.29833620195066", brLenTotal: "0.9193" },
  { geneTranscript: "NM_032854.4", parsimonySitesP: "0.146186440677966", constantSitesP: "0.74364406779661", distinctSitesP: "0.212570621468927", brLenTotal: "0.5346" },
  { geneTranscript: "NM_001032291.3", parsimonySitesP: "0.251251251251251", constantSitesP: "0.544544544544545", distinctSitesP: "0.415415415415415", brLenTotal: "0.9274" },
  { geneTranscript: "NM_003425.4", parsimonySitesP: "0.342130987292278", constantSitesP: "0.510263929618768", distinctSitesP: "0.408602150537634", brLenTotal: "1.3808" },
  { geneTranscript: "NM_001349999.2", parsimonySitesP: "0.0805369127516779", constantSitesP: "0.862788963460104", distinctSitesP: "0.128262490678598", brLenTotal: "0.2448" },
  { geneTranscript: "NM_206962.4", parsimonySitesP: "0.220939183987683", constantSitesP: "0.682063125481139", distinctSitesP: "0.310238645111624", brLenTotal: "1.0949" },
  { geneTranscript: "NM_001042545.2", parsimonySitesP: "0.200171269535431", constantSitesP: "0.686790837079854", distinctSitesP: "0.246842217940484", brLenTotal: "0.8089" },
  { geneTranscript: "NM_000400.4", parsimonySitesP: "0.179824561403509", constantSitesP: "0.727631578947368", distinctSitesP: "0.220175438596491", brLenTotal: "0.7962" },
  { geneTranscript: "NM_001130698.2", parsimonySitesP: "0.154180238870793", constantSitesP: "0.73651827723489", distinctSitesP: "0.196525515743757", brLenTotal: "0.6164" },
  { geneTranscript: "NM_012265.3", parsimonySitesP: "0.320379965457686", constantSitesP: "0.544041450777202", distinctSitesP: "0.423143350604491", brLenTotal: "1.2192" },
  { geneTranscript: "NM_001610.4", parsimonySitesP: "0.182033096926714", constantSitesP: "0.710007880220646", distinctSitesP: "0.252167060677699", brLenTotal: "0.7033" },
  { geneTranscript: "NM_032936.4", parsimonySitesP: "0.0726817042606516", constantSitesP: "0.87218045112782", distinctSitesP: "0.137844611528822", brLenTotal: "0.1813" },
  { geneTranscript: "NM_015530.5", parsimonySitesP: "0.15929203539823", constantSitesP: "0.724188790560472", distinctSitesP: "0.241150442477876", brLenTotal: "0.5398" },
  { geneTranscript: "NM_005708.5", parsimonySitesP: "0.162762762762763", constantSitesP: "0.746546546546547", distinctSitesP: "0.223423423423423", brLenTotal: "0.6258" },
  { geneTranscript: "NM_001145191.2", parsimonySitesP: "0.266869609335363", constantSitesP: "0.582952815829528", distinctSitesP: "0.390157280568239", brLenTotal: "1.2746" },
  { geneTranscript: "NM_001128126.3", parsimonySitesP: "0.127314814814815", constantSitesP: "0.747685185185185", distinctSitesP: "0.259259259259259", brLenTotal: "0.4178" },
  { geneTranscript: "NM_153232.4", parsimonySitesP: "0.310734463276836", constantSitesP: "0.507062146892655", distinctSitesP: "0.48728813559322", brLenTotal: "1.2766" },
  { geneTranscript: "NM_015893.1", parsimonySitesP: "0.402298850574713", constantSitesP: "0.375478927203065", distinctSitesP: "0.651340996168582", brLenTotal: "1.5564" },
  { geneTranscript: "NM_032271.3", parsimonySitesP: "0.177114427860697", constantSitesP: "0.743283582089552", distinctSitesP: "0.238805970149254", brLenTotal: "0.9227" },
  { geneTranscript: "NM_000921.5", parsimonySitesP: "0.199532573765703", constantSitesP: "0.671633070406077", distinctSitesP: "0.287759275489337", brLenTotal: "0.7199" },
  { geneTranscript: "NM_032357.4", parsimonySitesP: "0.312962962962963", constantSitesP: "0.47037037037037", distinctSitesP: "0.503703703703704", brLenTotal: "1.1962" },
  { geneTranscript: "NM_001515.4", parsimonySitesP: "0.129957805907173", constantSitesP: "0.771308016877637", distinctSitesP: "0.235443037974684", brLenTotal: "0.4034" },
  { geneTranscript: "NM_080722.4", parsimonySitesP: "0.24475333878441", constantSitesP: "0.616516762060507", distinctSitesP: "0.320523303352412", brLenTotal: "1.0374" },
  { geneTranscript: "NM_032777.10", parsimonySitesP: "0.232187344294968", constantSitesP: "0.634778276033881", distinctSitesP: "0.289486796213254", brLenTotal: "0.9571" },
  { geneTranscript: "NM_001122764.3", parsimonySitesP: "0.201956673654787", constantSitesP: "0.640810621942697", distinctSitesP: "0.292103424178896", brLenTotal: "0.6922" },
  { geneTranscript: "NM_000554.6", parsimonySitesP: "0.204013377926421", constantSitesP: "0.712374581939799", distinctSitesP: "0.276477146042363", brLenTotal: "0.9346" },
  { geneTranscript: "NM_001136108.3", parsimonySitesP: "0.288636363636364", constantSitesP: "0.553030303030303", distinctSitesP: "0.421212121212121", brLenTotal: "1.0215" },
  { geneTranscript: "NM_181877.4", parsimonySitesP: "0.270358306188925", constantSitesP: "0.561346362649294", distinctSitesP: "0.343105320304017", brLenTotal: "1.1539" },
  { geneTranscript: "NM_033512.3", parsimonySitesP: "0.277378097521982", constantSitesP: "0.565947242206235", distinctSitesP: "0.376498800959233", brLenTotal: "0.9281" },
  { geneTranscript: "NM_003728.4", parsimonySitesP: "0.212674543501611", constantSitesP: "0.681346222699606", distinctSitesP: "0.264232008592911", brLenTotal: "0.9777" },
  { geneTranscript: "NM_020159.5", parsimonySitesP: "0.12735542560104", constantSitesP: "0.785575048732943", distinctSitesP: "0.185834957764782", brLenTotal: "0.4305" },
  { geneTranscript: "NM_007254.4", parsimonySitesP: "0.277671145233525", constantSitesP: "0.570057581573896", distinctSitesP: "0.403710812539987", brLenTotal: "1.2122" },
  { geneTranscript: "NM_032642.3", parsimonySitesP: "0.226555246053853", constantSitesP: "0.65552460538533", distinctSitesP: "0.295264623955432", brLenTotal: "1.0011" },
  { geneTranscript: "NM_000903.3", parsimonySitesP: "0.199513381995134", constantSitesP: "0.671532846715328", distinctSitesP: "0.299270072992701", brLenTotal: "0.6908" },
  { geneTranscript: "NM_001124767.2", parsimonySitesP: "0.180952380952381", constantSitesP: "0.728571428571429", distinctSitesP: "0.276190476190476", brLenTotal: "0.4337" },
  { geneTranscript: "NM_018164.3", parsimonySitesP: "0.107648725212465", constantSitesP: "0.804060434372049", distinctSitesP: "0.162417374881964", brLenTotal: "0.3640" },
  { geneTranscript: "NM_001302109.2", parsimonySitesP: "0.278088144009932", constantSitesP: "0.553072625698324", distinctSitesP: "0.412787088764742", brLenTotal: "1.0447" },
  { geneTranscript: "NM_017714.3", parsimonySitesP: "0.0976190476190476", constantSitesP: "0.81031746031746", distinctSitesP: "0.176984126984127", brLenTotal: "0.3551" },
  { geneTranscript: "NM_003137.5", parsimonySitesP: "0.129262086513995", constantSitesP: "0.730788804071247", distinctSitesP: "0.208651399491094", brLenTotal: "0.4560" },
  { geneTranscript: "NM_207111.4", parsimonySitesP: "0.181292885518238", constantSitesP: "0.697724810400867", distinctSitesP: "0.235825207656194", brLenTotal: "0.6010" },
  { geneTranscript: "NM_016370.4", parsimonySitesP: "0.0912106135986733", constantSitesP: "0.827529021558872", distinctSitesP: "0.162520729684909", brLenTotal: "0.2471" },
  { geneTranscript: "NM_138701.4", parsimonySitesP: "0.115456238361266", constantSitesP: "0.76536312849162", distinctSitesP: "0.204841713221601", brLenTotal: "0.3581" },
  { geneTranscript: "NM_005308.3", parsimonySitesP: "0.167231638418079", constantSitesP: "0.724858757062147", distinctSitesP: "0.261581920903955", brLenTotal: "0.7568" },
  { geneTranscript: "NM_004272.5", parsimonySitesP: "0.0969868173258004", constantSitesP: "0.817325800376648", distinctSitesP: "0.167608286252354", brLenTotal: "0.3256" },
  { geneTranscript: "NM_001379029.1", parsimonySitesP: "0.106837606837607", constantSitesP: "0.806089743589744", distinctSitesP: "0.178418803418803", brLenTotal: "0.3465" },
  { geneTranscript: "NM_005269.3", parsimonySitesP: "0.213682941531043", constantSitesP: "0.638637733574442", distinctSitesP: "0.246835443037975", brLenTotal: "0.7186" },
  { geneTranscript: "NM_021074.5", parsimonySitesP: "0.140562248995984", constantSitesP: "0.720214190093708", distinctSitesP: "0.269076305220884", brLenTotal: "0.4603" },
  { geneTranscript: "NM_033259.3", parsimonySitesP: "0.0548523206751055", constantSitesP: "0.919831223628692", distinctSitesP: "0.0970464135021097", brLenTotal: "0.1425" },
  { geneTranscript: "NM_006020.3", parsimonySitesP: "0.215938303341902", constantSitesP: "0.629820051413882", distinctSitesP: "0.347043701799486", brLenTotal: "0.7403" },
  { geneTranscript: "NM_001706.5", parsimonySitesP: "0.161000944287063", constantSitesP: "0.742681775259679", distinctSitesP: "0.210103871576959", brLenTotal: "0.6160" },
  { geneTranscript: "NM_022104.4", parsimonySitesP: "0.136363636363636", constantSitesP: "0.779356060606061", distinctSitesP: "0.184659090909091", brLenTotal: "0.6010" },
  { geneTranscript: "NM_017704.3", parsimonySitesP: "0.221757322175732", constantSitesP: "0.601115760111576", distinctSitesP: "0.359832635983264", brLenTotal: "0.7760" },
  { geneTranscript: "NM_001348107.3", parsimonySitesP: "0.284444444444444", constantSitesP: "0.564444444444444", distinctSitesP: "0.444444444444444", brLenTotal: "1.0155" },
  { geneTranscript: "NM_007080.3", parsimonySitesP: "0.125", constantSitesP: "0.683333333333333", distinctSitesP: "0.329166666666667", brLenTotal: "0.4675" },
  { geneTranscript: "NM_003542.4", parsimonySitesP: "0.330097087378641", constantSitesP: "0.634304207119741", distinctSitesP: "0.372168284789644", brLenTotal: "3.3484" },
  { geneTranscript: "NM_001005494.2", parsimonySitesP: "0.420711974110032", constantSitesP: "0.44336569579288", distinctSitesP: "0.495145631067961", brLenTotal: "2.0806" },
  { geneTranscript: "NM_015540.4", parsimonySitesP: "0.264895908111989", constantSitesP: "0.570471404642259", distinctSitesP: "0.325915290739411", brLenTotal: "0.8967" },
  { geneTranscript: "NM_003664.5", parsimonySitesP: "0.141377209018891", constantSitesP: "0.730652041438147", distinctSitesP: "0.236745886654479", brLenTotal: "0.4935" },
  { geneTranscript: "NM_001045476.3", parsimonySitesP: "0.337579617834395", constantSitesP: "0.485138004246285", distinctSitesP: "0.524416135881104", brLenTotal: "1.3819" },
  { geneTranscript: "NM_178865.5", parsimonySitesP: "0.241758241758242", constantSitesP: "0.642490842490842", distinctSitesP: "0.347252747252747", brLenTotal: "1.0807" },
  { geneTranscript: "NM_006861.7", parsimonySitesP: "0.111111111111111", constantSitesP: "0.824212271973466", distinctSitesP: "0.177446102819237", brLenTotal: "0.4808" },
  { geneTranscript: "NM_001190274.2", parsimonySitesP: "0.0607695073714491", constantSitesP: "0.874865156418554", distinctSitesP: "0.0981661272923409", brLenTotal: "0.2032" },
  { geneTranscript: "NM_001195528.2", parsimonySitesP: "0.234729493891798", constantSitesP: "0.632635253054101", distinctSitesP: "0.329842931937173", brLenTotal: "0.8273" },
  { geneTranscript: "NM_173527.3", parsimonySitesP: "0.201960784313725", constantSitesP: "0.673529411764706", distinctSitesP: "0.287254901960784", brLenTotal: "0.7158" },
  { geneTranscript: "NM_173728.4", parsimonySitesP: "0.235830360681728", constantSitesP: "0.628616726119699", distinctSitesP: "0.3115338882283", brLenTotal: "0.8902" },
  { geneTranscript: "NM_021030.3", parsimonySitesP: "0.519210799584631", constantSitesP: "0.341121495327103", distinctSitesP: "0.631879543094496", brLenTotal: "3.2851" },
  { geneTranscript: "NM_000722.4", parsimonySitesP: "0.130766880537733", constantSitesP: "0.789795294836541", distinctSitesP: "0.195233730522456", brLenTotal: "0.4391" },
  { geneTranscript: "NM_005065.6", parsimonySitesP: "0.148194794290512", constantSitesP: "0.739294710327456", distinctSitesP: "0.219563392107473", brLenTotal: "0.5473" },
  { geneTranscript: "NM_022480.4", parsimonySitesP: "0.298811544991511", constantSitesP: "0.617996604414261", distinctSitesP: "0.301075268817204", brLenTotal: "1.7456" },
  { geneTranscript: "NM_019102.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.822222222222222", distinctSitesP: "0.149382716049383", brLenTotal: "0.3064" },
  { geneTranscript: "NM_080596.3", parsimonySitesP: "0.3203125", constantSitesP: "0.6171875", distinctSitesP: "0.377604166666667", brLenTotal: "1.8292" },
  { geneTranscript: "NM_054111.5", parsimonySitesP: "0.296747967479675", constantSitesP: "0.550406504065041", distinctSitesP: "0.405691056910569", brLenTotal: "1.2850" },
  { geneTranscript: "NM_002115.3", parsimonySitesP: "0.264716504153124", constantSitesP: "0.615023474178404", distinctSitesP: "0.320693391115926", brLenTotal: "1.1392" },
  { geneTranscript: "NM_001667.4", parsimonySitesP: "0.161231884057971", constantSitesP: "0.722826086956522", distinctSitesP: "0.248188405797101", brLenTotal: "0.5581" },
  { geneTranscript: "NM_005740.3", parsimonySitesP: "0.120634920634921", constantSitesP: "0.825396825396825", distinctSitesP: "0.193650793650794", brLenTotal: "0.3422" },
  { geneTranscript: "NM_006941.4", parsimonySitesP: "0.146638054363376", constantSitesP: "0.767525035765379", distinctSitesP: "0.198855507868383", brLenTotal: "0.6611" },
  { geneTranscript: "NM_001127266.2", parsimonySitesP: "0.268876611418048", constantSitesP: "0.601289134438306", distinctSitesP: "0.357274401473297", brLenTotal: "1.2144" },
  { geneTranscript: "NM_001042610.3", parsimonySitesP: "0.263713080168776", constantSitesP: "0.582278481012658", distinctSitesP: "0.417721518987342", brLenTotal: "1.1061" },
  { geneTranscript: "NM_003012.5", parsimonySitesP: "0.265392781316348", constantSitesP: "0.634819532908705", distinctSitesP: "0.356687898089172", brLenTotal: "1.0106" },
  { geneTranscript: "NM_001969.5", parsimonySitesP: "0.105181747873163", constantSitesP: "0.793503480278422", distinctSitesP: "0.185614849187935", brLenTotal: "0.4139" },
  { geneTranscript: "NM_001010847.2", parsimonySitesP: "0.179138321995465", constantSitesP: "0.700680272108844", distinctSitesP: "0.289115646258503", brLenTotal: "0.6887" },
  { geneTranscript: "NM_000982.4", parsimonySitesP: "0.1125", constantSitesP: "0.739583333333333", distinctSitesP: "0.235416666666667", brLenTotal: "0.4360" },
  { geneTranscript: "NM_002868.4", parsimonySitesP: "0.162790697674419", constantSitesP: "0.762790697674419", distinctSitesP: "0.204651162790698", brLenTotal: "0.4363" },
  { geneTranscript: "NM_014389.3", parsimonySitesP: "0.218879056047198", constantSitesP: "0.647197640117994", distinctSitesP: "0.284365781710914", brLenTotal: "0.8123" },
  { geneTranscript: "NM_001058.4", parsimonySitesP: "0.1998361998362", constantSitesP: "0.686322686322686", distinctSitesP: "0.25962325962326", brLenTotal: "0.7095" },
  { geneTranscript: "NM_033549.5", parsimonySitesP: "0.192592592592593", constantSitesP: "0.689417989417989", distinctSitesP: "0.251851851851852", brLenTotal: "0.8227" },
  { geneTranscript: "NM_014855.3", parsimonySitesP: "0.294506402313094", constantSitesP: "0.545642296571665", distinctSitesP: "0.40850888062784", brLenTotal: "1.4656" },
  { geneTranscript: "NM_001012977.3", parsimonySitesP: "0.126666666666667", constantSitesP: "0.738333333333333", distinctSitesP: "0.188333333333333", brLenTotal: "0.5787" },
  { geneTranscript: "NM_001114632.2", parsimonySitesP: "0.229957805907173", constantSitesP: "0.630801687763713", distinctSitesP: "0.35126582278481", brLenTotal: "0.8730" },
  { geneTranscript: "NM_001370095.3", parsimonySitesP: "0.286295005807201", constantSitesP: "0.533681765389082", distinctSitesP: "0.357142857142857", brLenTotal: "1.5003" },
  { geneTranscript: "NM_005780.3", parsimonySitesP: "0.185", constantSitesP: "0.72", distinctSitesP: "0.258333333333333", brLenTotal: "0.7366" },
  { geneTranscript: "NM_033343.4", parsimonySitesP: "0.135042735042735", constantSitesP: "0.783760683760684", distinctSitesP: "0.193162393162393", brLenTotal: "0.4807" },
  { geneTranscript: "NM_001300790.2", parsimonySitesP: "0.414141414141414", constantSitesP: "0.368686868686869", distinctSitesP: "0.621212121212121", brLenTotal: "2.6520" },
  { geneTranscript: "NM_174902.4", parsimonySitesP: "0.171014492753623", constantSitesP: "0.682125603864734", distinctSitesP: "0.257971014492754", brLenTotal: "1.0617" },
  { geneTranscript: "NM_001145648.3", parsimonySitesP: "0.212145319543888", constantSitesP: "0.713338636966322", distinctSitesP: "0.260143198090692", brLenTotal: "0.9432" },
  { geneTranscript: "NM_002080.4", parsimonySitesP: "0.227131782945736", constantSitesP: "0.606201550387597", distinctSitesP: "0.355813953488372", brLenTotal: "0.8661" },
  { geneTranscript: "NM_003755.5", parsimonySitesP: "0.18125", constantSitesP: "0.745833333333333", distinctSitesP: "0.253125", brLenTotal: "0.8575" },
  { geneTranscript: "NM_018018.5", parsimonySitesP: "0.17794028031688", constantSitesP: "0.705057891529555", distinctSitesP: "0.263254113345521", brLenTotal: "0.5868" },
  { geneTranscript: "NM_002351.5", parsimonySitesP: "0.122395833333333", constantSitesP: "0.7890625", distinctSitesP: "0.239583333333333", brLenTotal: "0.4549" },
  { geneTranscript: "NM_013231.6", parsimonySitesP: "0.177272727272727", constantSitesP: "0.725252525252525", distinctSitesP: "0.233838383838384", brLenTotal: "0.7568" },
  { geneTranscript: "NM_002647.4", parsimonySitesP: "0.138669673055242", constantSitesP: "0.782036828260053", distinctSitesP: "0.197670048853814", brLenTotal: "0.4605" },
  { geneTranscript: "NM_004970.3", parsimonySitesP: "0.318457300275482", constantSitesP: "0.536088154269972", distinctSitesP: "0.405509641873278", brLenTotal: "1.4346" },
  { geneTranscript: "NM_013338.5", parsimonySitesP: "0.152263374485597", constantSitesP: "0.737654320987654", distinctSitesP: "0.258230452674897", brLenTotal: "0.5293" },
  { geneTranscript: "NM_001378743.1", parsimonySitesP: "0.146792189679219", constantSitesP: "0.75418410041841", distinctSitesP: "0.211297071129707", brLenTotal: "0.5419" },
  { geneTranscript: "NM_018686.6", parsimonySitesP: "0.102918586789555", constantSitesP: "0.820276497695853", distinctSitesP: "0.14900153609831", brLenTotal: "0.3251" },
  { geneTranscript: "NM_152342.4", parsimonySitesP: "0.200922266139657", constantSitesP: "0.698945981554677", distinctSitesP: "0.268774703557312", brLenTotal: "0.9475" },
  { geneTranscript: "NM_003882.4", parsimonySitesP: "0.255222524977293", constantSitesP: "0.607629427792916", distinctSitesP: "0.343324250681199", brLenTotal: "1.1445" },
  { geneTranscript: "NM_182692.3", parsimonySitesP: "0.132570338578922", constantSitesP: "0.765855984740105", distinctSitesP: "0.198855507868383", brLenTotal: "0.5195" },
  { geneTranscript: "NM_014598.4", parsimonySitesP: "0.119896640826873", constantSitesP: "0.770542635658915", distinctSitesP: "0.212919896640827", brLenTotal: "0.4238" },
  { geneTranscript: "NM_022727.6", parsimonySitesP: "0.2768", constantSitesP: "0.597866666666667", distinctSitesP: "0.373866666666667", brLenTotal: "1.1567" },
  { geneTranscript: "NM_002088.5", parsimonySitesP: "0.127551020408163", constantSitesP: "0.783333333333333", distinctSitesP: "0.179931972789116", brLenTotal: "0.6480" },
  { geneTranscript: "NM_001105574.2", parsimonySitesP: "0.182072829131653", constantSitesP: "0.717086834733894", distinctSitesP: "0.254901960784314", brLenTotal: "0.7074" },
  { geneTranscript: "NM_004440.4", parsimonySitesP: "0.126920507682031", constantSitesP: "0.780227120908484", distinctSitesP: "0.171008684034736", brLenTotal: "0.4563" },
  { geneTranscript: "NM_173157.3", parsimonySitesP: "0.183946488294314", constantSitesP: "0.707915273132664", distinctSitesP: "0.240245261984392", brLenTotal: "0.8754" },
  { geneTranscript: "NM_002708.4", parsimonySitesP: "0.17979797979798", constantSitesP: "0.714141414141414", distinctSitesP: "0.255555555555556", brLenTotal: "0.7310" },
  { geneTranscript: "NM_002532.6", parsimonySitesP: "0.192532613585245", constantSitesP: "0.686909581646424", distinctSitesP: "0.270355375618534", brLenTotal: "0.6004" },
  { geneTranscript: "NM_001385994.1", parsimonySitesP: "0.11632870864461", constantSitesP: "0.785129847029527", distinctSitesP: "0.181430096051227", brLenTotal: "0.3267" },
  { geneTranscript: "NM_001015055.2", parsimonySitesP: "0.165778567199526", constantSitesP: "0.687981053878034", distinctSitesP: "0.230905861456483", brLenTotal: "0.5527" },
  { geneTranscript: "NM_003514.2", parsimonySitesP: "0.312820512820513", constantSitesP: "0.61025641025641", distinctSitesP: "0.382051282051282", brLenTotal: "2.2884" },
  { geneTranscript: "NM_031946.7", parsimonySitesP: "0.149652396633736", constantSitesP: "0.747530186608123", distinctSitesP: "0.213318697402122", brLenTotal: "0.6588" },
  { geneTranscript: "NM_001363541.2", parsimonySitesP: "0.204796163069544", constantSitesP: "0.673860911270983", distinctSitesP: "0.260431654676259", brLenTotal: "0.8665" },
  { geneTranscript: "NM_005722.4", parsimonySitesP: "0.11844331641286", constantSitesP: "0.736886632825719", distinctSitesP: "0.203891708967851", brLenTotal: "0.4147" },
  { geneTranscript: "NM_031925.3", parsimonySitesP: "0.177842565597668", constantSitesP: "0.729834791059281", distinctSitesP: "0.240038872691934", brLenTotal: "0.6898" },
  { geneTranscript: "NM_080749.4", parsimonySitesP: "0.231578947368421", constantSitesP: "0.637426900584795", distinctSitesP: "0.321637426900585", brLenTotal: "0.7966" },
  { geneTranscript: "NM_178862.3", parsimonySitesP: "0.0984665052461663", constantSitesP: "0.823244552058111", distinctSitesP: "0.145682001614205", brLenTotal: "0.2874" },
  { geneTranscript: "NM_001100588.3", parsimonySitesP: "0.101875174923034", constantSitesP: "0.808004478029667", distinctSitesP: "0.134340890008396", brLenTotal: "0.3039" },
  { geneTranscript: "NM_001348543.2", parsimonySitesP: "0.121756487025948", constantSitesP: "0.792415169660679", distinctSitesP: "0.197604790419162", brLenTotal: "0.4933" },
  { geneTranscript: "NM_033200.3", parsimonySitesP: "0.278642149929279", constantSitesP: "0.580858085808581", distinctSitesP: "0.366808109382367", brLenTotal: "1.2916" },
  { geneTranscript: "NM_001130915.2", parsimonySitesP: "0.285140562248996", constantSitesP: "0.513253012048193", distinctSitesP: "0.45863453815261", brLenTotal: "1.0179" },
  { geneTranscript: "NM_001083116.3", parsimonySitesP: "0.333933933933934", constantSitesP: "0.508708708708709", distinctSitesP: "0.428828828828829", brLenTotal: "1.4796" },
  { geneTranscript: "NM_001078171.2", parsimonySitesP: "0.235988200589971", constantSitesP: "0.51622418879056", distinctSitesP: "0.436578171091445", brLenTotal: "1.1521" },
  { geneTranscript: "NM_032594.4", parsimonySitesP: "0.255005889281508", constantSitesP: "0.578916372202591", distinctSitesP: "0.340400471142521", brLenTotal: "0.8946" },
  { geneTranscript: "NM_012263.5", parsimonySitesP: "0.18360914105595", constantSitesP: "0.718676122931442", distinctSitesP: "0.263199369582348", brLenTotal: "0.8988" },
  { geneTranscript: "NM_006346.4", parsimonySitesP: "0.162043152796125", constantSitesP: "0.701012769704976", distinctSitesP: "0.276970497578159", brLenTotal: "0.5543" },
  { geneTranscript: "NM_006917.5", parsimonySitesP: "0.161267098632109", constantSitesP: "0.730741540676746", distinctSitesP: "0.232541396688265", brLenTotal: "0.6999" },
  { geneTranscript: "NM_017582.7", parsimonySitesP: "0.0774091627172196", constantSitesP: "0.871248025276461", distinctSitesP: "0.113744075829384", brLenTotal: "0.2360" },
  { geneTranscript: "NM_001137560.2", parsimonySitesP: "0.141931684334511", constantSitesP: "0.760306242638398", distinctSitesP: "0.21319199057715", brLenTotal: "0.6256" },
  { geneTranscript: "NM_020378.4", parsimonySitesP: "0.216828478964401", constantSitesP: "0.660194174757282", distinctSitesP: "0.302588996763754", brLenTotal: "1.0874" },
  { geneTranscript: "NM_004093.4", parsimonySitesP: "0.0880880880880881", constantSitesP: "0.824824824824825", distinctSitesP: "0.161161161161161", brLenTotal: "0.3424" },
  { geneTranscript: "NM_020753.5", parsimonySitesP: "0.240987243483084", constantSitesP: "0.644758735440932", distinctSitesP: "0.277870216306156", brLenTotal: "0.9983" },
  { geneTranscript: "NM_020351.4", parsimonySitesP: "0.167114695340502", constantSitesP: "0.724014336917563", distinctSitesP: "0.228494623655914", brLenTotal: "0.6622" },
  { geneTranscript: "NM_012479.4", parsimonySitesP: "0.161943319838057", constantSitesP: "0.709851551956815", distinctSitesP: "0.230769230769231", brLenTotal: "0.8694" },
  { geneTranscript: "NM_001304274.2", parsimonySitesP: "0.122489959839357", constantSitesP: "0.70281124497992", distinctSitesP: "0.279116465863454", brLenTotal: "0.5025" },
  { geneTranscript: "NM_006005.3", parsimonySitesP: "0.219101123595506", constantSitesP: "0.626217228464419", distinctSitesP: "0.30187265917603", brLenTotal: "1.2844" },
  { geneTranscript: "NM_006302.3", parsimonySitesP: "0.216646754281163", constantSitesP: "0.637196336121067", distinctSitesP: "0.268418956591", brLenTotal: "0.7302" },
  { geneTranscript: "NM_000990.5", parsimonySitesP: "0.22972972972973", constantSitesP: "0.648648648648649", distinctSitesP: "0.36036036036036", brLenTotal: "0.7415" },
  { geneTranscript: "NM_001029896.2", parsimonySitesP: "0.111111111111111", constantSitesP: "0.798148148148148", distinctSitesP: "0.171296296296296", brLenTotal: "0.3699" },
  { geneTranscript: "NM_052813.5", parsimonySitesP: "0.242537313432836", constantSitesP: "0.623756218905473", distinctSitesP: "0.336442786069652", brLenTotal: "1.1580" },
  { geneTranscript: "NM_022054.4", parsimonySitesP: "0.379901960784314", constantSitesP: "0.51062091503268", distinctSitesP: "0.378267973856209", brLenTotal: "1.4824" },
  { geneTranscript: "NM_007107.5", parsimonySitesP: "0.136936936936937", constantSitesP: "0.686486486486486", distinctSitesP: "0.288288288288288", brLenTotal: "0.5484" },
  { geneTranscript: "NM_004983.3", parsimonySitesP: "0.169635284139101", constantSitesP: "0.751484308736217", distinctSitesP: "0.195928753180662", brLenTotal: "0.5726" },
  { geneTranscript: "NM_002221.4", parsimonySitesP: "0.303735024665257", constantSitesP: "0.519732205778717", distinctSitesP: "0.399577167019027", brLenTotal: "1.1907" },
  { geneTranscript: "NM_033419.5", parsimonySitesP: "0.167708333333333", constantSitesP: "0.711458333333333", distinctSitesP: "0.245833333333333", brLenTotal: "0.6667" },
  { geneTranscript: "NM_006303.4", parsimonySitesP: "0.2", constantSitesP: "0.653125", distinctSitesP: "0.30625", brLenTotal: "0.7652" },
  { geneTranscript: "NM_014287.4", parsimonySitesP: "0.196126568466994", constantSitesP: "0.695308237861429", distinctSitesP: "0.249318057828696", brLenTotal: "0.8055" },
  { geneTranscript: "NM_005658.5", parsimonySitesP: "0.221955128205128", constantSitesP: "0.651442307692308", distinctSitesP: "0.322115384615385", brLenTotal: "0.8615" },
  { geneTranscript: "NM_001634.6", parsimonySitesP: "0.0668662674650699", constantSitesP: "0.802395209580838", distinctSitesP: "0.169660678642715", brLenTotal: "0.2715" },
  { geneTranscript: "NM_181672.3", parsimonySitesP: "0.0978330146590185", constantSitesP: "0.836201402166985", distinctSitesP: "0.124920331421287", brLenTotal: "0.2923" },
  { geneTranscript: "NM_001297599.2", parsimonySitesP: "0.118787878787879", constantSitesP: "0.780606060606061", distinctSitesP: "0.186060606060606", brLenTotal: "0.4008" },
  { geneTranscript: "NM_001294.4", parsimonySitesP: "0.16243148978575", constantSitesP: "0.744394618834081", distinctSitesP: "0.216741405082212", brLenTotal: "0.7589" },
  { geneTranscript: "NM_020180.4", parsimonySitesP: "0.117283950617284", constantSitesP: "0.817558299039781", distinctSitesP: "0.151577503429355", brLenTotal: "0.5653" },
  { geneTranscript: "NM_002560.3", parsimonySitesP: "0.195876288659794", constantSitesP: "0.689862542955326", distinctSitesP: "0.302405498281787", brLenTotal: "0.8271" },
  { geneTranscript: "NM_002154.4", parsimonySitesP: "0.181349206349206", constantSitesP: "0.716666666666667", distinctSitesP: "0.238095238095238", brLenTotal: "0.6373" },
  { geneTranscript: "NM_000932.5", parsimonySitesP: "0.207455429497569", constantSitesP: "0.696110210696921", distinctSitesP: "0.240680713128039", brLenTotal: "0.9184" },
  { geneTranscript: "NM_024513.4", parsimonySitesP: "0.269057284618854", constantSitesP: "0.562922868741543", distinctSitesP: "0.32724402345512", brLenTotal: "1.0290" },
  { geneTranscript: "NM_030808.5", parsimonySitesP: "0.109178743961353", constantSitesP: "0.806763285024155", distinctSitesP: "0.171014492753623", brLenTotal: "0.3353" },
  { geneTranscript: "NM_001143942.2", parsimonySitesP: "0.11864406779661", constantSitesP: "0.803672316384181", distinctSitesP: "0.172316384180791", brLenTotal: "0.5172" },
  { geneTranscript: "NM_001005484.2", parsimonySitesP: "0.542944785276074", constantSitesP: "0.293456032719836", distinctSitesP: "0.701431492842536", brLenTotal: "3.2115" },
  { geneTranscript: "NM_016188.5", parsimonySitesP: "0.136150234741784", constantSitesP: "0.794992175273865", distinctSitesP: "0.176838810641628", brLenTotal: "0.6333" },
  { geneTranscript: "NM_001122955.4", parsimonySitesP: "0.208513708513709", constantSitesP: "0.645743145743146", distinctSitesP: "0.316738816738817", brLenTotal: "0.7517" },
  { geneTranscript: "NM_015021.3", parsimonySitesP: "0.179214102093279", constantSitesP: "0.684294283266006", distinctSitesP: "0.215448647325254", brLenTotal: "0.5805" },
  { geneTranscript: "NM_000764.3", parsimonySitesP: "0.276653171390013", constantSitesP: "0.547908232118758", distinctSitesP: "0.404183535762483", brLenTotal: "1.1686" },
  { geneTranscript: "NM_018222.5", parsimonySitesP: "0.130824372759857", constantSitesP: "0.795698924731183", distinctSitesP: "0.189964157706093", brLenTotal: "0.4419" },
  { geneTranscript: "NM_001130448.3", parsimonySitesP: "0.220952380952381", constantSitesP: "0.596190476190476", distinctSitesP: "0.365714285714286", brLenTotal: "0.8478" },
  { geneTranscript: "NM_001253772.2", parsimonySitesP: "0.183006535947712", constantSitesP: "0.711764705882353", distinctSitesP: "0.247712418300654", brLenTotal: "0.7634" },
  { geneTranscript: "NM_002452.4", parsimonySitesP: "0.273504273504274", constantSitesP: "0.604700854700855", distinctSitesP: "0.395299145299145", brLenTotal: "1.1399" },
  { geneTranscript: "NM_004703.6", parsimonySitesP: "0.117942768754834", constantSitesP: "0.775715390564578", distinctSitesP: "0.173240525908739", brLenTotal: "0.3990" },
  { geneTranscript: "NM_002547.3", parsimonySitesP: "0.147547797173732", constantSitesP: "0.755610972568579", distinctSitesP: "0.216541978387365", brLenTotal: "0.4646" },
  { geneTranscript: "NM_145261.4", parsimonySitesP: "0.0977011494252874", constantSitesP: "0.813218390804598", distinctSitesP: "0.206896551724138", brLenTotal: "0.2886" },
  { geneTranscript: "NM_133180.3", parsimonySitesP: "0.254034117104657", constantSitesP: "0.58736745043799", distinctSitesP: "0.343937298294145", brLenTotal: "1.0503" },
  { geneTranscript: "NM_033121.2", parsimonySitesP: "0.163276836158192", constantSitesP: "0.727683615819209", distinctSitesP: "0.221468926553672", brLenTotal: "0.5890" },
  { geneTranscript: "NM_171982.5", parsimonySitesP: "0.233265720081136", constantSitesP: "0.672075726842461", distinctSitesP: "0.285327924273158", brLenTotal: "0.9248" },
  { geneTranscript: "NM_015323.5", parsimonySitesP: "0.156171284634761", constantSitesP: "0.719143576826196", distinctSitesP: "0.240554156171285", brLenTotal: "0.5172" },
  { geneTranscript: "NM_004907.3", parsimonySitesP: "0.304932735426009", constantSitesP: "0.514200298953662", distinctSitesP: "0.445440956651719", brLenTotal: "1.0845" },
  { geneTranscript: "NM_012216.4", parsimonySitesP: "0.0884353741496599", constantSitesP: "0.833106575963719", distinctSitesP: "0.128344671201814", brLenTotal: "0.2740" },
  { geneTranscript: "NM_005176.7", parsimonySitesP: "0.269503546099291", constantSitesP: "0.557919621749409", distinctSitesP: "0.470449172576832", brLenTotal: "1.3078" },
  { geneTranscript: "NM_015388.4", parsimonySitesP: "0.136190476190476", constantSitesP: "0.759047619047619", distinctSitesP: "0.216190476190476", brLenTotal: "0.4691" },
  { geneTranscript: "NM_020178.5", parsimonySitesP: "0.0863821138211382", constantSitesP: "0.833333333333333", distinctSitesP: "0.155487804878049", brLenTotal: "0.3116" },
  { geneTranscript: "NM_021913.5", parsimonySitesP: "0.220357941834452", constantSitesP: "0.657345264727815", distinctSitesP: "0.279642058165548", brLenTotal: "0.8097" },
  { geneTranscript: "NM_152732.5", parsimonySitesP: "0.199275362318841", constantSitesP: "0.677536231884058", distinctSitesP: "0.294685990338164", brLenTotal: "0.6896" },
  { geneTranscript: "NM_052875.5", parsimonySitesP: "0.131944444444444", constantSitesP: "0.783730158730159", distinctSitesP: "0.199404761904762", brLenTotal: "0.5040" },
  { geneTranscript: "NM_001159944.3", parsimonySitesP: "0.163146997929607", constantSitesP: "0.749482401656315", distinctSitesP: "0.197929606625259", brLenTotal: "0.7233" },
  { geneTranscript: "NM_001371727.1", parsimonySitesP: "0.135416666666667", constantSitesP: "0.785807291666667", distinctSitesP: "0.184244791666667", brLenTotal: "0.4740" },
  { geneTranscript: "NM_001164760.2", parsimonySitesP: "0.223972003499563", constantSitesP: "0.688538932633421", distinctSitesP: "0.323709536307962", brLenTotal: "1.2215" },
  { geneTranscript: "NM_004571.5", parsimonySitesP: "0.197247706422018", constantSitesP: "0.735474006116208", distinctSitesP: "0.258409785932722", brLenTotal: "0.8499" },
  { geneTranscript: "NM_001039112.2", parsimonySitesP: "0.212708669897684", constantSitesP: "0.658768623227428", distinctSitesP: "0.284688565787112", brLenTotal: "0.9022" },
  { geneTranscript: "NM_004854.5", parsimonySitesP: "0.180711610486891", constantSitesP: "0.702247191011236", distinctSitesP: "0.282771535580524", brLenTotal: "0.7247" },
  { geneTranscript: "NM_005338.7", parsimonySitesP: "0.192864030858245", constantSitesP: "0.687238829958213", distinctSitesP: "0.267116682738669", brLenTotal: "0.7399" },
  { geneTranscript: "NM_033051.4", parsimonySitesP: "0.240701754385965", constantSitesP: "0.612631578947368", distinctSitesP: "0.342456140350877", brLenTotal: "0.8285" },
  { geneTranscript: "NM_152835.5", parsimonySitesP: "0.0811339198435973", constantSitesP: "0.857282502443793", distinctSitesP: "0.119257086999022", brLenTotal: "0.2056" },
  { geneTranscript: "NM_001838.4", parsimonySitesP: "0.193121693121693", constantSitesP: "0.686067019400353", distinctSitesP: "0.268077601410935", brLenTotal: "0.8294" },
  { geneTranscript: "NM_017481.4", parsimonySitesP: "0.29618320610687", constantSitesP: "0.510432569974555", distinctSitesP: "0.416284987277354", brLenTotal: "1.0291" },
  { geneTranscript: "NM_182620.4", parsimonySitesP: "0.190082644628099", constantSitesP: "0.641873278236915", distinctSitesP: "0.385674931129477", brLenTotal: "0.6206" },
  { geneTranscript: "NM_018920.4", parsimonySitesP: "0.415593705293276", constantSitesP: "0.466022889842632", distinctSitesP: "0.489628040057225", brLenTotal: "2.9747" },
  { geneTranscript: "NM_015562.2", parsimonySitesP: "0.124744376278119", constantSitesP: "0.802999318336742", distinctSitesP: "0.175869120654397", brLenTotal: "0.3619" },
  { geneTranscript: "NM_020884.7", parsimonySitesP: "0.210200927357032", constantSitesP: "0.694315644856603", distinctSitesP: "0.242486690709256", brLenTotal: "1.0833" },
  { geneTranscript: "NM_014252.4", parsimonySitesP: "0.243632336655592", constantSitesP: "0.640088593576966", distinctSitesP: "0.332225913621262", brLenTotal: "1.1050" },
  { geneTranscript: "NM_002298.5", parsimonySitesP: "0.187134502923977", constantSitesP: "0.718234981392876", distinctSitesP: "0.249867091972355", brLenTotal: "0.7350" },
  { geneTranscript: "NM_021729.6", parsimonySitesP: "0.148777895855473", constantSitesP: "0.749557208643287", distinctSitesP: "0.182430038965639", brLenTotal: "0.4909" },
  { geneTranscript: "NM_005288.4", parsimonySitesP: "0.169660678642715", constantSitesP: "0.744510978043912", distinctSitesP: "0.230538922155689", brLenTotal: "0.6181" },
  { geneTranscript: "NM_006439.5", parsimonySitesP: "0.11142061281337", constantSitesP: "0.755803156917363", distinctSitesP: "0.171773444753946", brLenTotal: "0.5935" },
  { geneTranscript: "NM_175907.6", parsimonySitesP: "0.193633952254642", constantSitesP: "0.67816091954023", distinctSitesP: "0.286472148541114", brLenTotal: "0.7367" },
  { geneTranscript: "NM_181784.3", parsimonySitesP: "0.11244019138756", constantSitesP: "0.812599681020734", distinctSitesP: "0.169856459330144", brLenTotal: "0.4003" },
  { geneTranscript: "NM_001106.4", parsimonySitesP: "0.137369791666667", constantSitesP: "0.779947916666667", distinctSitesP: "0.1875", brLenTotal: "0.5077" },
  { geneTranscript: "NM_015051.3", parsimonySitesP: "0.0993431855500821", constantSitesP: "0.818555008210181", distinctSitesP: "0.168308702791461", brLenTotal: "0.3236" },
  { geneTranscript: "NM_004366.6", parsimonySitesP: "0.176688938381589", constantSitesP: "0.715664439495174", distinctSitesP: "0.222345953971789", brLenTotal: "0.7320" },
  { geneTranscript: "NM_001261833.2", parsimonySitesP: "0.194656488549618", constantSitesP: "0.591603053435115", distinctSitesP: "0.25", brLenTotal: "1.0084" },
  { geneTranscript: "NM_000345.4", parsimonySitesP: "0.176190476190476", constantSitesP: "0.733333333333333", distinctSitesP: "0.283333333333333", brLenTotal: "0.5900" },
  { geneTranscript: "NM_000852.4", parsimonySitesP: "0.23968253968254", constantSitesP: "0.625396825396825", distinctSitesP: "0.363492063492063", brLenTotal: "1.0229" },
  { geneTranscript: "NM_007104.5", parsimonySitesP: "0.187403993855607", constantSitesP: "0.646697388632873", distinctSitesP: "0.339477726574501", brLenTotal: "0.7713" },
  { geneTranscript: "NM_153033.5", parsimonySitesP: "0.146482122260669", constantSitesP: "0.735870818915802", distinctSitesP: "0.251441753171857", brLenTotal: "0.5750" },
  { geneTranscript: "NM_152420.3", parsimonySitesP: "0.0806845965770171", constantSitesP: "0.850855745721271", distinctSitesP: "0.13039934800326", brLenTotal: "0.2408" },
  { geneTranscript: "NM_006145.3", parsimonySitesP: "0.2", constantSitesP: "0.602941176470588", distinctSitesP: "0.33921568627451", brLenTotal: "0.8077" },
  { geneTranscript: "NM_005652.5", parsimonySitesP: "0.175276752767528", constantSitesP: "0.712177121771218", distinctSitesP: "0.261377613776138", brLenTotal: "0.5438" },
  { geneTranscript: "NM_003966.3", parsimonySitesP: "0.215704531346989", constantSitesP: "0.691806331471136", distinctSitesP: "0.269087523277467", brLenTotal: "1.0289" },
  { geneTranscript: "NM_006848.3", parsimonySitesP: "0.158415841584158", constantSitesP: "0.6996699669967", distinctSitesP: "0.285478547854785", brLenTotal: "0.5905" },
  { geneTranscript: "NM_012247.5", parsimonySitesP: "0.188775510204082", constantSitesP: "0.646258503401361", distinctSitesP: "0.318877551020408", brLenTotal: "0.8937" },
  { geneTranscript: "NM_000507.4", parsimonySitesP: "0.235700197238659", constantSitesP: "0.617357001972387", distinctSitesP: "0.366863905325444", brLenTotal: "1.0314" },
  { geneTranscript: "NM_001532.3", parsimonySitesP: "0.190058479532164", constantSitesP: "0.677631578947368", distinctSitesP: "0.281432748538012", brLenTotal: "0.7984" },
  { geneTranscript: "NM_001394319.2", parsimonySitesP: "0.27172195892575", constantSitesP: "0.58609794628752", distinctSitesP: "0.402053712480253", brLenTotal: "1.0587" },
  { geneTranscript: "NM_147161.4", parsimonySitesP: "0.204826038159371", constantSitesP: "0.653198653198653", distinctSitesP: "0.286195286195286", brLenTotal: "0.8996" },
  { geneTranscript: "NM_023932.4", parsimonySitesP: "0.186248912097476", constantSitesP: "0.678851174934726", distinctSitesP: "0.270670147954743", brLenTotal: "0.6749" },
  { geneTranscript: "NM_005340.7", parsimonySitesP: "0.142857142857143", constantSitesP: "0.727513227513228", distinctSitesP: "0.28042328042328", brLenTotal: "0.4976" },
  { geneTranscript: "NM_004793.4", parsimonySitesP: "0.232881473757386", constantSitesP: "0.655543969412583", distinctSitesP: "0.29857490441432", brLenTotal: "1.1204" },
  { geneTranscript: "NM_000158.4", parsimonySitesP: "0.196106362773029", constantSitesP: "0.688509021842355", distinctSitesP: "0.286324786324786", brLenTotal: "0.6993" },
  { geneTranscript: "NM_018125.4", parsimonySitesP: "0.188428459734167", constantSitesP: "0.712275215011728", distinctSitesP: "0.235600729736774", brLenTotal: "0.8845" },
  { geneTranscript: "NM_017570.5", parsimonySitesP: "0.24223602484472", constantSitesP: "0.652432712215321", distinctSitesP: "0.279503105590062", brLenTotal: "1.0396" },
  { geneTranscript: "NM_015665.6", parsimonySitesP: "0.186813186813187", constantSitesP: "0.687423687423687", distinctSitesP: "0.261904761904762", brLenTotal: "0.6094" },
  { geneTranscript: "NM_001114134.2", parsimonySitesP: "0.28557645923782", constantSitesP: "0.549445248432224", distinctSitesP: "0.430294259527255", brLenTotal: "1.0508" },
  { geneTranscript: "NM_014805.4", parsimonySitesP: "0.242723778143877", constantSitesP: "0.634816035145524", distinctSitesP: "0.275123558484349", brLenTotal: "0.7748" },
  { geneTranscript: "NM_024533.5", parsimonySitesP: "0.394971613949716", constantSitesP: "0.480940794809408", distinctSitesP: "0.499594484995945", brLenTotal: "1.9549" },
  { geneTranscript: "NM_139321.3", parsimonySitesP: "0.14788896664334", constantSitesP: "0.751807790996035", distinctSitesP: "0.195008164217401", brLenTotal: "0.4882" },
  { geneTranscript: "NM_006704.5", parsimonySitesP: "0.146146146146146", constantSitesP: "0.710710710710711", distinctSitesP: "0.284284284284284", brLenTotal: "0.4943" },
  { geneTranscript: "NM_152892.3", parsimonySitesP: "0.256053580628542", constantSitesP: "0.610510046367852", distinctSitesP: "0.349819680577022", brLenTotal: "1.1652" },
  { geneTranscript: "NM_014877.4", parsimonySitesP: "0.118434603501545", constantSitesP: "0.774802608994164", distinctSitesP: "0.169756265018881", brLenTotal: "0.4215" },
  { geneTranscript: "NM_001122636.2", parsimonySitesP: "0.229408512990603", constantSitesP: "0.65616362631288", distinctSitesP: "0.290768380320619", brLenTotal: "1.2198" },
  { geneTranscript: "NM_001143883.4", parsimonySitesP: "0.264227642276423", constantSitesP: "0.436991869918699", distinctSitesP: "0.502032520325203", brLenTotal: "1.5564" },
  { geneTranscript: "NM_014357.5", parsimonySitesP: "0.412121212121212", constantSitesP: "0.360606060606061", distinctSitesP: "0.706060606060606", brLenTotal: "3.3465" },
  { geneTranscript: "NM_144586.7", parsimonySitesP: "0.151300236406619", constantSitesP: "0.730496453900709", distinctSitesP: "0.260047281323877", brLenTotal: "0.4976" },
  { geneTranscript: "NM_021956.5", parsimonySitesP: "0.130690161527166", constantSitesP: "0.795521292217327", distinctSitesP: "0.169970631424376", brLenTotal: "0.4838" },
  { geneTranscript: "NM_006658.5", parsimonySitesP: "0.180645161290323", constantSitesP: "0.636559139784946", distinctSitesP: "0.333333333333333", brLenTotal: "0.6851" },
  { geneTranscript: "NM_032536.4", parsimonySitesP: "0.174213836477987", constantSitesP: "0.733333333333333", distinctSitesP: "0.237106918238994", brLenTotal: "0.7519" },
  { geneTranscript: "NM_006837.3", parsimonySitesP: "0.100798403193613", constantSitesP: "0.812375249500998", distinctSitesP: "0.162674650698603", brLenTotal: "0.3317" },
  { geneTranscript: "NM_001199383.2", parsimonySitesP: "0.122171945701357", constantSitesP: "0.744595274007039", distinctSitesP: "0.193061840120664", brLenTotal: "0.4547" },
  { geneTranscript: "NM_021968.4", parsimonySitesP: "0.29126213592233", constantSitesP: "0.682847896440129", distinctSitesP: "0.330097087378641", brLenTotal: "2.7096" },
  { geneTranscript: "NM_024069.4", parsimonySitesP: "0.229166666666667", constantSitesP: "0.668560606060606", distinctSitesP: "0.335227272727273", brLenTotal: "1.0486" },
  { geneTranscript: "NM_017412.4", parsimonySitesP: "0.211211211211211", constantSitesP: "0.683183183183183", distinctSitesP: "0.219219219219219", brLenTotal: "0.7691" },
  { geneTranscript: "NM_016065.4", parsimonySitesP: "0.24330900243309", constantSitesP: "0.562043795620438", distinctSitesP: "0.476885644768856", brLenTotal: "0.9040" },
  { geneTranscript: "NM_001366057.1", parsimonySitesP: "0.151406343506882", constantSitesP: "0.68821065230401", distinctSitesP: "0.249551166965889", brLenTotal: "0.5807" },
  { geneTranscript: "NM_014752.3", parsimonySitesP: "0.175516224188791", constantSitesP: "0.678466076696165", distinctSitesP: "0.299410029498525", brLenTotal: "0.6767" },
  { geneTranscript: "NM_021199.4", parsimonySitesP: "0.214074074074074", constantSitesP: "0.65037037037037", distinctSitesP: "0.322962962962963", brLenTotal: "0.7814" },
  { geneTranscript: "NM_138409.4", parsimonySitesP: "0.224390243902439", constantSitesP: "0.622764227642276", distinctSitesP: "0.354471544715447", brLenTotal: "0.7492" },
  { geneTranscript: "NM_053284.3", parsimonySitesP: "0.395377128953771", constantSitesP: "0.498175182481752", distinctSitesP: "0.362530413625304", brLenTotal: "2.8416" },
  { geneTranscript: "NM_004746.4", parsimonySitesP: "0.140907540088707", constantSitesP: "0.792903445922893", distinctSitesP: "0.173660866598431", brLenTotal: "0.5048" },
  { geneTranscript: "NM_024316.3", parsimonySitesP: "0.314393939393939", constantSitesP: "0.516414141414141", distinctSitesP: "0.462121212121212", brLenTotal: "1.0863" },
  { geneTranscript: "NM_023943.4", parsimonySitesP: "0.288115942028986", constantSitesP: "0.537971014492754", distinctSitesP: "0.377971014492754", brLenTotal: "1.0700" },
  { geneTranscript: "NM_005819.6", parsimonySitesP: "0.138562091503268", constantSitesP: "0.777777777777778", distinctSitesP: "0.213071895424837", brLenTotal: "0.5262" },
  { geneTranscript: "NM_013320.3", parsimonySitesP: "0.126683501683502", constantSitesP: "0.776515151515152", distinctSitesP: "0.200757575757576", brLenTotal: "0.4018" },
  { geneTranscript: "NM_014839.5", parsimonySitesP: "0.185081585081585", constantSitesP: "0.732400932400932", distinctSitesP: "0.238694638694639", brLenTotal: "0.7380" },
  { geneTranscript: "NM_000868.4", parsimonySitesP: "0.184861717612809", constantSitesP: "0.724163027656477", distinctSitesP: "0.246724890829694", brLenTotal: "0.6127" },
  { geneTranscript: "NM_016369.4", parsimonySitesP: "0.201787994891443", constantSitesP: "0.665389527458493", distinctSitesP: "0.298850574712644", brLenTotal: "0.7500" },
  { geneTranscript: "NM_001629.4", parsimonySitesP: "0.213250517598344", constantSitesP: "0.703933747412008", distinctSitesP: "0.281573498964803", brLenTotal: "0.7459" },
  { geneTranscript: "NM_021614.4", parsimonySitesP: "0.102823430257059", constantSitesP: "0.8322798145807", distinctSitesP: "0.160977665402444", brLenTotal: "0.3516" },
  { geneTranscript: "NM_021928.4", parsimonySitesP: "0.107407407407407", constantSitesP: "0.807407407407407", distinctSitesP: "0.183333333333333", brLenTotal: "0.3240" },
  { geneTranscript: "NM_012240.3", parsimonySitesP: "0.264331210191083", constantSitesP: "0.630573248407643", distinctSitesP: "0.33651804670913", brLenTotal: "0.9371" },
  { geneTranscript: "NM_004085.4", parsimonySitesP: "0.161512027491409", constantSitesP: "0.625429553264605", distinctSitesP: "0.353951890034364", brLenTotal: "0.6870" },
  { geneTranscript: "NM_032111.4", parsimonySitesP: "0.197701149425287", constantSitesP: "0.682758620689655", distinctSitesP: "0.331034482758621", brLenTotal: "0.6825" },
  { geneTranscript: "NM_133465.4", parsimonySitesP: "0.126163873370577", constantSitesP: "0.783985102420857", distinctSitesP: "0.171787709497207", brLenTotal: "0.4524" },
  { geneTranscript: "NM_001142503.3", parsimonySitesP: "0.269265639165911", constantSitesP: "0.56935630099728", distinctSitesP: "0.349652462979752", brLenTotal: "1.0026" },
  { geneTranscript: "NM_015584.5", parsimonySitesP: "0.157608695652174", constantSitesP: "0.749094202898551", distinctSitesP: "0.22463768115942", brLenTotal: "0.5132" },
  { geneTranscript: "NM_182476.3", parsimonySitesP: "0.196581196581197", constantSitesP: "0.664529914529915", distinctSitesP: "0.295584045584046", brLenTotal: "0.7299" },
  { geneTranscript: "NM_002161.6", parsimonySitesP: "0.199947173798204", constantSitesP: "0.653195985208663", distinctSitesP: "0.308769149498151", brLenTotal: "0.7172" },
  { geneTranscript: "NM_001346793.2", parsimonySitesP: "0.226226226226226", constantSitesP: "0.637637637637638", distinctSitesP: "0.325325325325325", brLenTotal: "0.9273" },
  { geneTranscript: "NM_001966.4", parsimonySitesP: "0.245735361917935", constantSitesP: "0.585523282618718", distinctSitesP: "0.365606270170586", brLenTotal: "0.9340" },
  { geneTranscript: "NM_007108.4", parsimonySitesP: "0.18361581920904", constantSitesP: "0.627118644067797", distinctSitesP: "0.347457627118644", brLenTotal: "0.8087" },
  { geneTranscript: "NM_001311175.2", parsimonySitesP: "0.160130718954248", constantSitesP: "0.69281045751634", distinctSitesP: "0.25", brLenTotal: "0.6798" },
  { geneTranscript: "NM_018378.3", parsimonySitesP: "0.322638146167558", constantSitesP: "0.502673796791444", distinctSitesP: "0.432263814616756", brLenTotal: "1.1743" },
  { geneTranscript: "NM_001481.3", parsimonySitesP: "0.235006973500697", constantSitesP: "0.633891213389121", distinctSitesP: "0.322873082287308", brLenTotal: "0.9837" },
  { geneTranscript: "NM_003380.5", parsimonySitesP: "0.158798283261803", constantSitesP: "0.689556509298999", distinctSitesP: "0.263233190271817", brLenTotal: "0.6431" },
  { geneTranscript: "NM_000307.5", parsimonySitesP: "0.18005540166205", constantSitesP: "0.678670360110803", distinctSitesP: "0.244690674053555", brLenTotal: "0.6480" },
  { geneTranscript: "NM_003771.5", parsimonySitesP: "0.295503211991435", constantSitesP: "0.54389721627409", distinctSitesP: "0.429693076374019", brLenTotal: "1.4784" },
  { geneTranscript: "NM_004207.4", parsimonySitesP: "0.324014336917563", constantSitesP: "0.567741935483871", distinctSitesP: "0.402867383512545", brLenTotal: "1.6852" },
  { geneTranscript: "NM_001388308.1", parsimonySitesP: "0.244751664106503", constantSitesP: "0.583717357910906", distinctSitesP: "0.352278545826933", brLenTotal: "0.9350" },
  { geneTranscript: "NM_016373.4", parsimonySitesP: "0.223027375201288", constantSitesP: "0.680354267310789", distinctSitesP: "0.304347826086957", brLenTotal: "0.9188" },
  { geneTranscript: "NM_030979.3", parsimonySitesP: "0.451135763338616", constantSitesP: "0.308505018489171", distinctSitesP: "0.63391442155309", brLenTotal: "2.0944" },
  { geneTranscript: "NM_173348.2", parsimonySitesP: "0.245704467353952", constantSitesP: "0.589347079037801", distinctSitesP: "0.422680412371134", brLenTotal: "0.7433" },
  { geneTranscript: "NM_003388.5", parsimonySitesP: "0.188655194391332", constantSitesP: "0.692479286169535", distinctSitesP: "0.258126195028681", brLenTotal: "0.8940" },
  { geneTranscript: "NM_001048174.2", parsimonySitesP: "0.257837492002559", constantSitesP: "0.566858605246321", distinctSitesP: "0.387076135636596", brLenTotal: "0.9462" },
  { geneTranscript: "NM_001321324.2", parsimonySitesP: "0.148222000664673", constantSitesP: "0.722166832834829", distinctSitesP: "0.186773014290462", brLenTotal: "0.5908" },
  { geneTranscript: "NM_057168.2", parsimonySitesP: "0.168949771689498", constantSitesP: "0.696803652968037", distinctSitesP: "0.264840182648402", brLenTotal: "0.6177" },
  { geneTranscript: "NM_001020.6", parsimonySitesP: "0.175799086757991", constantSitesP: "0.678082191780822", distinctSitesP: "0.324200913242009", brLenTotal: "0.6706" },
  { geneTranscript: "NM_138392.4", parsimonySitesP: "0.187647336162188", constantSitesP: "0.703441772748703", distinctSitesP: "0.247053276756247", brLenTotal: "0.8504" },
  { geneTranscript: "NM_001080517.3", parsimonySitesP: "0.133610725843736", constantSitesP: "0.773000462320851", distinctSitesP: "0.158576051779935", brLenTotal: "0.3988" },
  { geneTranscript: "NM_002447.4", parsimonySitesP: "0.280714285714286", constantSitesP: "0.556666666666667", distinctSitesP: "0.340714285714286", brLenTotal: "1.0121" },
  { geneTranscript: "NM_002292.4", parsimonySitesP: "0.209121245828699", constantSitesP: "0.64275120504264", distinctSitesP: "0.233778272154245", brLenTotal: "0.7401" },
  { geneTranscript: "NM_017827.4", parsimonySitesP: "0.250965250965251", constantSitesP: "0.627413127413127", distinctSitesP: "0.342985842985843", brLenTotal: "0.9654" },
  { geneTranscript: "NM_003752.5", parsimonySitesP: "0.156991602774735", constantSitesP: "0.719240598758671", distinctSitesP: "0.228185469149325", brLenTotal: "0.6420" },
  { geneTranscript: "NM_014035.4", parsimonySitesP: "0.102564102564103", constantSitesP: "0.806706114398422", distinctSitesP: "0.207100591715976", brLenTotal: "0.3359" },
  { geneTranscript: "NM_003108.4", parsimonySitesP: "0.125472411186697", constantSitesP: "0.793650793650794", distinctSitesP: "0.195767195767196", brLenTotal: "0.4280" },
  { geneTranscript: "NM_006979.3", parsimonySitesP: "0.205401563610519", constantSitesP: "0.665245202558635", distinctSitesP: "0.291400142146411", brLenTotal: "0.6214" },
  { geneTranscript: "NM_001162499.2", parsimonySitesP: "0.213592233009709", constantSitesP: "0.640776699029126", distinctSitesP: "0.254584681769148", brLenTotal: "1.0087" },
  { geneTranscript: "NM_004354.3", parsimonySitesP: "0.150193798449612", constantSitesP: "0.711240310077519", distinctSitesP: "0.241279069767442", brLenTotal: "0.5300" },
  { geneTranscript: "NM_001205.3", parsimonySitesP: "0.169590643274854", constantSitesP: "0.716374269005848", distinctSitesP: "0.279239766081871", brLenTotal: "0.6194" },
  { geneTranscript: "NM_001165963.4", parsimonySitesP: "0.231126596980256", constantSitesP: "0.672805707648913", distinctSitesP: "0.272108843537415", brLenTotal: "1.4788" },
  { geneTranscript: "NM_006034.5", parsimonySitesP: "0.17636684303351", constantSitesP: "0.738977072310406", distinctSitesP: "0.261022927689594", brLenTotal: "0.8333" },
  { geneTranscript: "NM_001145113.3", parsimonySitesP: "0.266015200868621", constantSitesP: "0.606948968512486", distinctSitesP: "0.346362649294245", brLenTotal: "1.4163" },
  { geneTranscript: "NM_014901.5", parsimonySitesP: "0.199074074074074", constantSitesP: "0.677469135802469", distinctSitesP: "0.29320987654321", brLenTotal: "0.9096" },
  { geneTranscript: "NM_014240.3", parsimonySitesP: "0.250986193293886", constantSitesP: "0.566074950690335", distinctSitesP: "0.359960552268245", brLenTotal: "0.9776" },
  { geneTranscript: "NM_152914.3", parsimonySitesP: "0.147492625368732", constantSitesP: "0.731563421828909", distinctSitesP: "0.312684365781711", brLenTotal: "0.6099" },
  { geneTranscript: "NM_006660.5", parsimonySitesP: "0.123222748815166", constantSitesP: "0.754081095313323", distinctSitesP: "0.204318062137967", brLenTotal: "0.4358" },
  { geneTranscript: "NM_003533.3", parsimonySitesP: "0.299019607843137", constantSitesP: "0.656862745098039", distinctSitesP: "0.352941176470588", brLenTotal: "2.8542" },
  { geneTranscript: "NM_006576.4", parsimonySitesP: "0.220594220594221", constantSitesP: "0.636548636548637", distinctSitesP: "0.279202279202279", brLenTotal: "0.7623" },
  { geneTranscript: "NM_018660.3", parsimonySitesP: "0.268356075373619", constantSitesP: "0.619233268356075", distinctSitesP: "0.342430149447693", brLenTotal: "1.2395" },
  { geneTranscript: "NM_022064.5", parsimonySitesP: "0.181126331811263", constantSitesP: "0.699391171993912", distinctSitesP: "0.21689497716895", brLenTotal: "0.6925" },
  { geneTranscript: "NM_152898.2", parsimonySitesP: "0.253012048192771", constantSitesP: "0.558232931726908", distinctSitesP: "0.411646586345382", brLenTotal: "0.9201" },
  { geneTranscript: "NM_004041.5", parsimonySitesP: "0.141945773524721", constantSitesP: "0.767942583732057", distinctSitesP: "0.211323763955343", brLenTotal: "0.5698" },
  { geneTranscript: "NM_033547.4", parsimonySitesP: "0.14607130494981", constantSitesP: "0.749740394600208", distinctSitesP: "0.200761509172724", brLenTotal: "0.4666" },
  { geneTranscript: "NM_172347.3", parsimonySitesP: "0.308927424534361", constantSitesP: "0.520231213872832", distinctSitesP: "0.429672447013487", brLenTotal: "1.5755" },
  { geneTranscript: "NM_001136044.2", parsimonySitesP: "0.154471544715447", constantSitesP: "0.715447154471545", distinctSitesP: "0.237127371273713", brLenTotal: "0.5451" },
  { geneTranscript: "NM_001126049.2", parsimonySitesP: "0.597378277153558", constantSitesP: "0.183520599250936", distinctSitesP: "0.777153558052434", brLenTotal: "2.5787" },
  { geneTranscript: "NM_144593.3", parsimonySitesP: "0.136612021857923", constantSitesP: "0.75591985428051", distinctSitesP: "0.233151183970856", brLenTotal: "0.4271" },
  { geneTranscript: "NM_000595.4", parsimonySitesP: "0.282926829268293", constantSitesP: "0.564227642276423", distinctSitesP: "0.373983739837398", brLenTotal: "1.0010" },
  { geneTranscript: "NM_181708.3", parsimonySitesP: "0.221461187214612", constantSitesP: "0.616438356164384", distinctSitesP: "0.355022831050228", brLenTotal: "0.6746" },
  { geneTranscript: "NM_017623.5", parsimonySitesP: "0.23998114097124", constantSitesP: "0.62989156058463", distinctSitesP: "0.342291371994342", brLenTotal: "1.0382" },
  { geneTranscript: "NM_001388272.1", parsimonySitesP: "0.199384141647421", constantSitesP: "0.638183217859892", distinctSitesP: "0.316397228637413", brLenTotal: "0.9491" },
  { geneTranscript: "NM_004625.4", parsimonySitesP: "0.196752626552053", constantSitesP: "0.746895893027698", distinctSitesP: "0.219675262655205", brLenTotal: "0.7478" },
  { geneTranscript: "NM_004415.4", parsimonySitesP: "0.165215372111924", constantSitesP: "0.732497387669801", distinctSitesP: "0.185417392313944", brLenTotal: "0.6397" },
  { geneTranscript: "NM_001357.5", parsimonySitesP: "0.164041994750656", constantSitesP: "0.730183727034121", distinctSitesP: "0.223622047244094", brLenTotal: "0.5422" },
  { geneTranscript: "NM_013390.3", parsimonySitesP: "0.185104844540853", constantSitesP: "0.698722583755122", distinctSitesP: "0.245360327789829", brLenTotal: "0.6742" },
  { geneTranscript: "NM_001394154.1", parsimonySitesP: "0.269983874683253", constantSitesP: "0.617369269753513", distinctSitesP: "0.331260078322967", brLenTotal: "1.1466" },
  { geneTranscript: "NM_017711.4", parsimonySitesP: "0.189239332096475", constantSitesP: "0.683982683982684", distinctSitesP: "0.27891156462585", brLenTotal: "0.5991" },
  { geneTranscript: "NM_001080478.3", parsimonySitesP: "0.347600518806744", constantSitesP: "0.488326848249027", distinctSitesP: "0.465629053177691", brLenTotal: "1.5921" },
  { geneTranscript: "NM_138572.3", parsimonySitesP: "0.145161290322581", constantSitesP: "0.762365591397849", distinctSitesP: "0.22258064516129", brLenTotal: "0.5443" },
  { geneTranscript: "NM_005869.4", parsimonySitesP: "0.142655367231638", constantSitesP: "0.74364406779661", distinctSitesP: "0.241525423728814", brLenTotal: "0.4615" },
  { geneTranscript: "NM_001568.3", parsimonySitesP: "0.148314606741573", constantSitesP: "0.719101123595506", distinctSitesP: "0.250187265917603", brLenTotal: "0.5217" },
  { geneTranscript: "NM_030759.5", parsimonySitesP: "0.261324041811847", constantSitesP: "0.508710801393728", distinctSitesP: "0.451800232288037", brLenTotal: "0.9866" },
  { geneTranscript: "NM_006504.6", parsimonySitesP: "0.197142857142857", constantSitesP: "0.70952380952381", distinctSitesP: "0.277142857142857", brLenTotal: "0.9030" },
  { geneTranscript: "NM_153690.5", parsimonySitesP: "0.189913317572892", constantSitesP: "0.697399527186761", distinctSitesP: "0.274231678486998", brLenTotal: "0.6777" },
  { geneTranscript: "NM_001306215.2", parsimonySitesP: "0.138143694110392", constantSitesP: "0.769349367869257", distinctSitesP: "0.177304964539007", brLenTotal: "0.4827" },
  { geneTranscript: "NM_001018116.2", parsimonySitesP: "0.208791208791209", constantSitesP: "0.645604395604396", distinctSitesP: "0.354395604395604", brLenTotal: "0.7463" },
  { geneTranscript: "NM_014464.4", parsimonySitesP: "0.19047619047619", constantSitesP: "0.67156862745098", distinctSitesP: "0.32563025210084", brLenTotal: "0.6357" },
  { geneTranscript: "NM_000572.3", parsimonySitesP: "0.235955056179775", constantSitesP: "0.627340823970037", distinctSitesP: "0.374531835205993", brLenTotal: "0.7753" },
  { geneTranscript: "NM_006868.4", parsimonySitesP: "0.194871794871795", constantSitesP: "0.743589743589744", distinctSitesP: "0.25982905982906", brLenTotal: "0.5571" },
  { geneTranscript: "NM_001080457.2", parsimonySitesP: "0.143525011687705", constantSitesP: "0.78354371201496", distinctSitesP: "0.190743338008415", brLenTotal: "0.6761" },
  { geneTranscript: "NM_001004713.2", parsimonySitesP: "0.581220657276995", constantSitesP: "0.23849765258216", distinctSitesP: "0.770892018779343", brLenTotal: "3.5332" },
  { geneTranscript: "NM_000635.4", parsimonySitesP: "0.233748271092669", constantSitesP: "0.666205624711849", distinctSitesP: "0.296449976947902", brLenTotal: "1.1228" },
  { geneTranscript: "NM_021210.5", parsimonySitesP: "0.119540229885057", constantSitesP: "0.783908045977011", distinctSitesP: "0.195402298850575", brLenTotal: "0.3921" },
  { geneTranscript: "NM_014360.4", parsimonySitesP: "0.301255230125523", constantSitesP: "0.534170153417015", distinctSitesP: "0.421199442119944", brLenTotal: "1.1996" },
  { geneTranscript: "NM_001012994.2", parsimonySitesP: "0.161708619374523", constantSitesP: "0.759725400457666", distinctSitesP: "0.228070175438596", brLenTotal: "0.5560" },
  { geneTranscript: "NM_014350.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.681818181818182", distinctSitesP: "0.262626262626263", brLenTotal: "0.8175" },
  { geneTranscript: "NM_001112724.2", parsimonySitesP: "0.189393939393939", constantSitesP: "0.696127946127946", distinctSitesP: "0.303030303030303", brLenTotal: "0.6701" },
  { geneTranscript: "NM_152345.5", parsimonySitesP: "0.149627263045793", constantSitesP: "0.751863684771033", distinctSitesP: "0.198083067092652", brLenTotal: "0.6046" },
  { geneTranscript: "NM_152430.4", parsimonySitesP: "0.306079664570231", constantSitesP: "0.505241090146751", distinctSitesP: "0.348008385744235", brLenTotal: "1.4038" },
  { geneTranscript: "NM_001376113.1", parsimonySitesP: "0.200557880055788", constantSitesP: "0.663598326359833", distinctSitesP: "0.255230125523013", brLenTotal: "0.7813" },
  { geneTranscript: "NM_015512.5", parsimonySitesP: "0.216334505666276", constantSitesP: "0.65158264947245", distinctSitesP: "0.226338413442751", brLenTotal: "0.8353" },
  { geneTranscript: "NM_004579.5", parsimonySitesP: "0.164634146341463", constantSitesP: "0.729268292682927", distinctSitesP: "0.232926829268293", brLenTotal: "0.7046" },
  { geneTranscript: "NM_014444.5", parsimonySitesP: "0.13963963963964", constantSitesP: "0.773273273273273", distinctSitesP: "0.192692692692693", brLenTotal: "0.4702" },
  { geneTranscript: "NM_001080419.3", parsimonySitesP: "0.1440329218107", constantSitesP: "0.777777777777778", distinctSitesP: "0.177366255144033", brLenTotal: "0.5827" },
  { geneTranscript: "NM_002028.4", parsimonySitesP: "0.170099160945843", constantSitesP: "0.742181540808543", distinctSitesP: "0.219679633867277", brLenTotal: "0.6834" },
  { geneTranscript: "NM_003980.6", parsimonySitesP: "0.214063195371607", constantSitesP: "0.639519359145527", distinctSitesP: "0.356475300400534", brLenTotal: "0.8052" },
  { geneTranscript: "NM_001320179.2", parsimonySitesP: "0.256653992395437", constantSitesP: "0.590621039290241", distinctSitesP: "0.406210392902408", brLenTotal: "0.9930" },
  { geneTranscript: "NM_005687.5", parsimonySitesP: "0.161856253537068", constantSitesP: "0.715336728919072", distinctSitesP: "0.255234861346916", brLenTotal: "0.6159" },
  { geneTranscript: "NM_021938.4", parsimonySitesP: "0.139518900343643", constantSitesP: "0.769759450171821", distinctSitesP: "0.193814432989691", brLenTotal: "0.6104" },
  { geneTranscript: "NM_001270508.2", parsimonySitesP: "0.209282700421941", constantSitesP: "0.670886075949367", distinctSitesP: "0.265400843881857", brLenTotal: "0.7602" },
  { geneTranscript: "NM_152792.4", parsimonySitesP: "0.227799227799228", constantSitesP: "0.634491634491634", distinctSitesP: "0.333333333333333", brLenTotal: "0.9885" },
  { geneTranscript: "NM_001830.4", parsimonySitesP: "0.151754385964912", constantSitesP: "0.743421052631579", distinctSitesP: "0.204385964912281", brLenTotal: "0.7573" },
  { geneTranscript: "NM_001116.4", parsimonySitesP: "0.194382852919438", constantSitesP: "0.703867947770387", distinctSitesP: "0.236265089923627", brLenTotal: "0.7999" },
  { geneTranscript: "NM_018298.11", parsimonySitesP: "0.16998191681736", constantSitesP: "0.692585895117541", distinctSitesP: "0.25919228450874", brLenTotal: "0.6059" },
  { geneTranscript: "NM_004826.4", parsimonySitesP: "0.191397849462366", constantSitesP: "0.697634408602151", distinctSitesP: "0.253763440860215", brLenTotal: "0.8016" },
  { geneTranscript: "NM_006246.5", parsimonySitesP: "0.0599571734475375", constantSitesP: "0.8807994289793", distinctSitesP: "0.106352605281941", brLenTotal: "0.1954" },
  { geneTranscript: "NM_001387437.1", parsimonySitesP: "0.233529028049576", constantSitesP: "0.631441617742988", distinctSitesP: "0.369210697977821", brLenTotal: "1.0132" },
  { geneTranscript: "NM_030806.4", parsimonySitesP: "0.0578512396694215", constantSitesP: "0.87603305785124", distinctSitesP: "0.126721763085399", brLenTotal: "0.1806" },
  { geneTranscript: "NM_001080416.4", parsimonySitesP: "0.155585106382979", constantSitesP: "0.737145390070922", distinctSitesP: "0.227836879432624", brLenTotal: "0.4562" },
  { geneTranscript: "NM_014239.4", parsimonySitesP: "0.171889838556505", constantSitesP: "0.695156695156695", distinctSitesP: "0.260208926875594", brLenTotal: "0.6037" },
  { geneTranscript: "NM_031443.4", parsimonySitesP: "0.138888888888889", constantSitesP: "0.780780780780781", distinctSitesP: "0.203453453453453", brLenTotal: "0.7223" },
  { geneTranscript: "NM_007056.3", parsimonySitesP: "0.163699307616222", constantSitesP: "0.755192878338279", distinctSitesP: "0.224035608308605", brLenTotal: "0.6521" },
  { geneTranscript: "NM_001005468.2", parsimonySitesP: "0.517571884984026", constantSitesP: "0.35356762513312", distinctSitesP: "0.634717784877529", brLenTotal: "3.2780" },
  { geneTranscript: "NM_014397.6", parsimonySitesP: "0.171458998935037", constantSitesP: "0.74653887113951", distinctSitesP: "0.232161874334398", brLenTotal: "0.8907" },
  { geneTranscript: "NM_001256114.2", parsimonySitesP: "0.120423892100193", constantSitesP: "0.783236994219653", distinctSitesP: "0.184007707129094", brLenTotal: "0.4134" },
  { geneTranscript: "NM_144574.4", parsimonySitesP: "0.0913884007029877", constantSitesP: "0.806678383128295", distinctSitesP: "0.152314001171646", brLenTotal: "0.3963" },
  { geneTranscript: "NM_001364857.2", parsimonySitesP: "0.130178759200841", constantSitesP: "0.786540483701367", distinctSitesP: "0.162565720294427", brLenTotal: "0.5773" },
  { geneTranscript: "NM_000394.4", parsimonySitesP: "0.190751445086705", constantSitesP: "0.737957610789981", distinctSitesP: "0.248554913294798", brLenTotal: "0.9047" },
  { geneTranscript: "NM_016588.3", parsimonySitesP: "0.0657276995305164", constantSitesP: "0.870892018779343", distinctSitesP: "0.131455399061033", brLenTotal: "0.2150" },
  { geneTranscript: "NM_003280.3", parsimonySitesP: "0.192546583850932", constantSitesP: "0.726708074534161", distinctSitesP: "0.240165631469979", brLenTotal: "0.8121" },
  { geneTranscript: "NM_014173.4", parsimonySitesP: "0.220871327254306", constantSitesP: "0.663627152988855", distinctSitesP: "0.311043566362715", brLenTotal: "1.0455" },
  { geneTranscript: "NM_033222.5", parsimonySitesP: "0.0987421383647799", constantSitesP: "0.783647798742138", distinctSitesP: "0.179245283018868", brLenTotal: "0.3456" },
  { geneTranscript: "NM_000972.3", parsimonySitesP: "0.278195488721805", constantSitesP: "0.493734335839599", distinctSitesP: "0.468671679197995", brLenTotal: "1.4078" },
  { geneTranscript: "NM_031966.4", parsimonySitesP: "0.181678214010778", constantSitesP: "0.642802155504234", distinctSitesP: "0.311008468052348", brLenTotal: "0.6624" },
  { geneTranscript: "NM_020660.3", parsimonySitesP: "0.128764278296989", constantSitesP: "0.785046728971963", distinctSitesP: "0.191069574247144", brLenTotal: "0.4013" },
  { geneTranscript: "NM_001130028.2", parsimonySitesP: "0.12108843537415", constantSitesP: "0.765986394557823", distinctSitesP: "0.180952380952381", brLenTotal: "0.4736" },
  { geneTranscript: "NM_002480.3", parsimonySitesP: "0.108414239482201", constantSitesP: "0.798381877022654", distinctSitesP: "0.161488673139159", brLenTotal: "0.3520" },
  { geneTranscript: "NM_004035.7", parsimonySitesP: "0.17020202020202", constantSitesP: "0.712626262626263", distinctSitesP: "0.227777777777778", brLenTotal: "0.6208" },
  { geneTranscript: "NM_003450.3", parsimonySitesP: "0.191646191646192", constantSitesP: "0.661752661752662", distinctSitesP: "0.274365274365274", brLenTotal: "0.6942" },
  { geneTranscript: "NM_004475.3", parsimonySitesP: "0.142523364485981", constantSitesP: "0.783489096573209", distinctSitesP: "0.183021806853583", brLenTotal: "0.6185" },
  { geneTranscript: "NM_001323368.2", parsimonySitesP: "0.179254783484391", constantSitesP: "0.682779456193353", distinctSitesP: "0.27693856998993", brLenTotal: "0.6089" },
  { geneTranscript: "NM_014292.5", parsimonySitesP: "0.218446601941748", constantSitesP: "0.679611650485437", distinctSitesP: "0.298543689320388", brLenTotal: "0.8689" },
  { geneTranscript: "NM_021058.4", parsimonySitesP: "0.198412698412698", constantSitesP: "0.682539682539683", distinctSitesP: "0.322751322751323", brLenTotal: "1.4694" },
  { geneTranscript: "NM_001025598.2", parsimonySitesP: "0.248864668483197", constantSitesP: "0.587344838026037", distinctSitesP: "0.339993944898577", brLenTotal: "0.8517" },
  { geneTranscript: "NM_145255.4", parsimonySitesP: "0.275862068965517", constantSitesP: "0.567049808429119", distinctSitesP: "0.407407407407407", brLenTotal: "0.9358" },
  { geneTranscript: "NM_001366145.2", parsimonySitesP: "0.139422144657747", constantSitesP: "0.769245685476052", distinctSitesP: "0.176459181694784", brLenTotal: "0.5245" },
  { geneTranscript: "NM_183377.2", parsimonySitesP: "0.0994671403197158", constantSitesP: "0.830076968620485", distinctSitesP: "0.175843694493783", brLenTotal: "0.3346" },
  { geneTranscript: "NM_014936.5", parsimonySitesP: "0.184694628403238", constantSitesP: "0.683590875643856", distinctSitesP: "0.298749080206034", brLenTotal: "0.6339" },
  { geneTranscript: "NM_001256.6", parsimonySitesP: "0.0983009708737864", constantSitesP: "0.766181229773463", distinctSitesP: "0.16504854368932", brLenTotal: "0.3753" },
  { geneTranscript: "NM_014814.3", parsimonySitesP: "0.158526135389889", constantSitesP: "0.736932305055698", distinctSitesP: "0.234790059982862", brLenTotal: "0.5452" },
  { geneTranscript: "NM_032538.3", parsimonySitesP: "0.20035326772647", constantSitesP: "0.681554377996467", distinctSitesP: "0.244007065354529", brLenTotal: "0.9284" },
  { geneTranscript: "NM_018036.7", parsimonySitesP: "0.16008982996471", constantSitesP: "0.72409367982034", distinctSitesP: "0.216714789862047", brLenTotal: "0.5848" },
  { geneTranscript: "NM_178348.2", parsimonySitesP: "0.412121212121212", constantSitesP: "0.360606060606061", distinctSitesP: "0.703030303030303", brLenTotal: "3.1473" },
  { geneTranscript: "NM_001008219.3", parsimonySitesP: "0.233529028049576", constantSitesP: "0.63600782778865", distinctSitesP: "0.360730593607306", brLenTotal: "1.0187" },
  { geneTranscript: "NM_014655.4", parsimonySitesP: "0.152866242038217", constantSitesP: "0.743099787685775", distinctSitesP: "0.221868365180467", brLenTotal: "0.5700" },
  { geneTranscript: "NM_001142568.3", parsimonySitesP: "0.146652497343252", constantSitesP: "0.741409847679773", distinctSitesP: "0.213248317392844", brLenTotal: "0.4718" },
  { geneTranscript: "NM_006981.4", parsimonySitesP: "0.159211927582535", constantSitesP: "0.751331203407881", distinctSitesP: "0.21618743343983", brLenTotal: "0.5518" },
  { geneTranscript: "NM_001134438.2", parsimonySitesP: "0.186219739292365", constantSitesP: "0.680766161213089", distinctSitesP: "0.256717212024475", brLenTotal: "0.6440" },
  { geneTranscript: "NM_003746.3", parsimonySitesP: "0.220973782771536", constantSitesP: "0.722846441947566", distinctSitesP: "0.280898876404494", brLenTotal: "0.5984" },
  { geneTranscript: "NM_016275.5", parsimonySitesP: "0.0888888888888889", constantSitesP: "0.736752136752137", distinctSitesP: "0.203418803418803", brLenTotal: "0.3984" },
  { geneTranscript: "NM_003545.4", parsimonySitesP: "0.323624595469256", constantSitesP: "0.650485436893204", distinctSitesP: "0.362459546925566", brLenTotal: "3.6119" },
  { geneTranscript: "NM_182569.4", parsimonySitesP: "0.113588110403397", constantSitesP: "0.794055201698514", distinctSitesP: "0.193205944798301", brLenTotal: "0.3249" },
  { geneTranscript: "NM_016210.5", parsimonySitesP: "0.203703703703704", constantSitesP: "0.662551440329218", distinctSitesP: "0.325102880658436", brLenTotal: "0.6986" },
  { geneTranscript: "NM_032410.4", parsimonySitesP: "0.137418755803157", constantSitesP: "0.783658310120706", distinctSitesP: "0.205199628597957", brLenTotal: "0.4523" },
  { geneTranscript: "NM_001883.5", parsimonySitesP: "0.191403081914031", constantSitesP: "0.691808596918086", distinctSitesP: "0.281427412814274", brLenTotal: "0.6924" },
  { geneTranscript: "NM_001007248.3", parsimonySitesP: "0.381519274376417", constantSitesP: "0.39172335600907", distinctSitesP: "0.534013605442177", brLenTotal: "1.6485" },
  { geneTranscript: "NM_173511.4", parsimonySitesP: "0.139784946236559", constantSitesP: "0.748726655348048", distinctSitesP: "0.232597623089983", brLenTotal: "0.5020" },
  { geneTranscript: "NM_031478.6", parsimonySitesP: "0.145985401459854", constantSitesP: "0.774939172749392", distinctSitesP: "0.209245742092457", brLenTotal: "0.7306" },
  { geneTranscript: "NM_021240.4", parsimonySitesP: "0.203389830508475", constantSitesP: "0.656779661016949", distinctSitesP: "0.320621468926554", brLenTotal: "0.8159" },
  { geneTranscript: "NM_005498.5", parsimonySitesP: "0.203309692671395", constantSitesP: "0.70133963750985", distinctSitesP: "0.285263987391647", brLenTotal: "1.0393" },
  { geneTranscript: "NM_001358689.2", parsimonySitesP: "0.34984984984985", constantSitesP: "0.498498498498498", distinctSitesP: "0.475975975975976", brLenTotal: "2.4162" },
  { geneTranscript: "NM_000020.3", parsimonySitesP: "0.21272365805169", constantSitesP: "0.650099403578529", distinctSitesP: "0.27766732935719", brLenTotal: "0.8362" },
  { geneTranscript: "NM_001134382.3", parsimonySitesP: "0.192100538599641", constantSitesP: "0.695691202872531", distinctSitesP: "0.252842609216038", brLenTotal: "1.0640" },
  { geneTranscript: "NM_006545.5", parsimonySitesP: "0.142105263157895", constantSitesP: "0.763157894736842", distinctSitesP: "0.192105263157895", brLenTotal: "0.4894" },
  { geneTranscript: "NM_000976.4", parsimonySitesP: "0.157575757575758", constantSitesP: "0.676767676767677", distinctSitesP: "0.313131313131313", brLenTotal: "0.6512" },
  { geneTranscript: "NM_078471.4", parsimonySitesP: "0.16179811749432", constantSitesP: "0.733690360272639", distinctSitesP: "0.189224277831873", brLenTotal: "0.6411" },
  { geneTranscript: "NM_019113.4", parsimonySitesP: "0.314194577352472", constantSitesP: "0.547049441786284", distinctSitesP: "0.427432216905901", brLenTotal: "1.2393" },
  { geneTranscript: "NM_001042462.2", parsimonySitesP: "0.193262411347518", constantSitesP: "0.73758865248227", distinctSitesP: "0.25177304964539", brLenTotal: "0.7792" },
  { geneTranscript: "NM_004959.5", parsimonySitesP: "0.151843817787419", constantSitesP: "0.760665220535069", distinctSitesP: "0.199566160520607", brLenTotal: "0.6382" },
  { geneTranscript: "NM_000032.5", parsimonySitesP: "0.20442930153322", constantSitesP: "0.655309483248154", distinctSitesP: "0.279954571266326", brLenTotal: "0.6580" },
  { geneTranscript: "NM_001022.4", parsimonySitesP: "0.135632183908046", constantSitesP: "0.719540229885057", distinctSitesP: "0.31264367816092", brLenTotal: "0.5498" },
  { geneTranscript: "NM_000233.4", parsimonySitesP: "0.19790176442537", constantSitesP: "0.66762041010968", distinctSitesP: "0.305197901764425", brLenTotal: "0.6306" },
  { geneTranscript: "NM_030962.4", parsimonySitesP: "0.142599603389219", constantSitesP: "0.760050477735713", distinctSitesP: "0.172345411934379", brLenTotal: "0.4483" },
  { geneTranscript: "NM_173598.6", parsimonySitesP: "0.151228070175439", constantSitesP: "0.757894736842105", distinctSitesP: "0.19719298245614", brLenTotal: "0.6932" },
  { geneTranscript: "NM_001375484.1", parsimonySitesP: "0.123900879296563", constantSitesP: "0.772182254196643", distinctSitesP: "0.196642685851319", brLenTotal: "0.3760" },
  { geneTranscript: "NM_030777.4", parsimonySitesP: "0.277880468268638", constantSitesP: "0.570548367221195", distinctSitesP: "0.396179913739988", brLenTotal: "1.2242" },
  { geneTranscript: "NM_006585.4", parsimonySitesP: "0.123479318734793", constantSitesP: "0.781630170316302", distinctSitesP: "0.194038929440389", brLenTotal: "0.4138" },
  { geneTranscript: "NM_139126.4", parsimonySitesP: "0.13550135501355", constantSitesP: "0.752032520325203", distinctSitesP: "0.218834688346883", brLenTotal: "0.4382" },
  { geneTranscript: "NM_178006.4", parsimonySitesP: "0.221922731356694", constantSitesP: "0.656483977238694", distinctSitesP: "0.270440251572327", brLenTotal: "0.9222" },
  { geneTranscript: "NM_005208.5", parsimonySitesP: "0.105426356589147", constantSitesP: "0.781395348837209", distinctSitesP: "0.193798449612403", brLenTotal: "0.3791" },
  { geneTranscript: "NM_015981.4", parsimonySitesP: "0.119291070211316", constantSitesP: "0.812542603953647", distinctSitesP: "0.161554192229039", brLenTotal: "0.5566" },
  { geneTranscript: "NM_014781.5", parsimonySitesP: "0.151819322459222", constantSitesP: "0.728983688833124", distinctSitesP: "0.193433709744877", brLenTotal: "0.5045" },
  { geneTranscript: "NM_013272.4", parsimonySitesP: "0.128638497652582", constantSitesP: "0.799530516431925", distinctSitesP: "0.168544600938967", brLenTotal: "0.4605" },
  { geneTranscript: "NM_007370.7", parsimonySitesP: "0.157843137254902", constantSitesP: "0.736274509803922", distinctSitesP: "0.268627450980392", brLenTotal: "0.5602" },
  { geneTranscript: "NM_031954.5", parsimonySitesP: "0.171458998935037", constantSitesP: "0.722044728434505", distinctSitesP: "0.237486687965921", brLenTotal: "1.0533" },
  { geneTranscript: "NM_001122964.3", parsimonySitesP: "0.121319199057715", constantSitesP: "0.792697290930506", distinctSitesP: "0.160188457008245", brLenTotal: "0.4316" },
  { geneTranscript: "NM_000041.4", parsimonySitesP: "0.273396424815983", constantSitesP: "0.580441640378549", distinctSitesP: "0.385909568874869", brLenTotal: "1.0082" },
  { geneTranscript: "NM_004319.3", parsimonySitesP: "0.151468315301391", constantSitesP: "0.73827923750644", distinctSitesP: "0.204791344667697", brLenTotal: "0.6847" },
  { geneTranscript: "NM_001167740.2", parsimonySitesP: "0.183021806853583", constantSitesP: "0.707165109034268", distinctSitesP: "0.270249221183801", brLenTotal: "0.6831" },
  { geneTranscript: "NM_001136050.3", parsimonySitesP: "0.233226837060703", constantSitesP: "0.648562300319489", distinctSitesP: "0.340788072417465", brLenTotal: "0.8927" },
  { geneTranscript: "NM_001286176.2", parsimonySitesP: "0.111639708214399", constantSitesP: "0.80558198541072", distinctSitesP: "0.155407548366635", brLenTotal: "0.3770" },
  { geneTranscript: "NM_015976.5", parsimonySitesP: "0.191426459719143", constantSitesP: "0.647450110864745", distinctSitesP: "0.317073170731707", brLenTotal: "0.7390" },
  { geneTranscript: "NM_013240.6", parsimonySitesP: "0.21183800623053", constantSitesP: "0.609034267912773", distinctSitesP: "0.389408099688474", brLenTotal: "0.8053" },
  { geneTranscript: "NM_001408.3", parsimonySitesP: "0.239936138670316", constantSitesP: "0.650587296156916", distinctSitesP: "0.274603717641692", brLenTotal: "1.0286" },
  { geneTranscript: "NM_012387.3", parsimonySitesP: "0.30920060331825", constantSitesP: "0.550025138260432", distinctSitesP: "0.440925087983912", brLenTotal: "1.3970" },
  { geneTranscript: "NM_182556.4", parsimonySitesP: "0.262731481481481", constantSitesP: "0.585648148148148", distinctSitesP: "0.381944444444444", brLenTotal: "1.1014" },
  { geneTranscript: "NM_018061.4", parsimonySitesP: "0.14957264957265", constantSitesP: "0.736874236874237", distinctSitesP: "0.208791208791209", brLenTotal: "0.4883" },
  { geneTranscript: "NM_001165967.2", parsimonySitesP: "0.133333333333333", constantSitesP: "0.750724637681159", distinctSitesP: "0.221739130434783", brLenTotal: "0.4600" },
  { geneTranscript: "NM_022736.4", parsimonySitesP: "0.184229390681004", constantSitesP: "0.702508960573477", distinctSitesP: "0.285304659498208", brLenTotal: "0.6358" },
  { geneTranscript: "NM_021623.2", parsimonySitesP: "0.20156862745098", constantSitesP: "0.695686274509804", distinctSitesP: "0.28156862745098", brLenTotal: "0.7466" },
  { geneTranscript: "NM_145888.3", parsimonySitesP: "0.27536231884058", constantSitesP: "0.560386473429952", distinctSitesP: "0.380434782608696", brLenTotal: "1.2166" },
  { geneTranscript: "NM_005866.4", parsimonySitesP: "0.158445440956652", constantSitesP: "0.732436472346786", distinctSitesP: "0.23168908819133", brLenTotal: "0.5036" },
  { geneTranscript: "NM_152787.5", parsimonySitesP: "0.0908239700374532", constantSitesP: "0.803838951310861", distinctSitesP: "0.151685393258427", brLenTotal: "0.3593" },
  { geneTranscript: "NM_017853.3", parsimonySitesP: "0.12751677852349", constantSitesP: "0.771812080536913", distinctSitesP: "0.21923937360179", brLenTotal: "0.3602" },
  { geneTranscript: "NM_001277115.2", parsimonySitesP: "0.217153823442575", constantSitesP: "0.632860938883968", distinctSitesP: "0.293770298198996", brLenTotal: "0.7945" },
  { geneTranscript: "NM_001042681.2", parsimonySitesP: "0.177309493401447", constantSitesP: "0.732865048957003", distinctSitesP: "0.235632183908046", brLenTotal: "0.7891" },
  { geneTranscript: "NM_145015.5", parsimonySitesP: "0.198250728862974", constantSitesP: "0.648202137998056", distinctSitesP: "0.299319727891156", brLenTotal: "1.2194" },
  { geneTranscript: "NM_001317782.2", parsimonySitesP: "0.202334630350195", constantSitesP: "0.643320363164721", distinctSitesP: "0.328145265888457", brLenTotal: "0.8009" },
  { geneTranscript: "NM_130830.5", parsimonySitesP: "0.237521514629948", constantSitesP: "0.625358577165806", distinctSitesP: "0.313253012048193", brLenTotal: "1.0844" },
  { geneTranscript: "NM_001085437.3", parsimonySitesP: "0.335570469798658", constantSitesP: "0.504101416853095", distinctSitesP: "0.488441461595824", brLenTotal: "1.5617" },
  { geneTranscript: "NM_138639.2", parsimonySitesP: "0.242666666666667", constantSitesP: "0.617333333333333", distinctSitesP: "0.369333333333333", brLenTotal: "0.8803" },
  { geneTranscript: "NM_001001331.4", parsimonySitesP: "0.157951193349423", constantSitesP: "0.764279967819791", distinctSitesP: "0.190935907750067", brLenTotal: "0.7087" },
  { geneTranscript: "NM_198321.4", parsimonySitesP: "0.188501934770591", constantSitesP: "0.721945826423438", distinctSitesP: "0.244886677722499", brLenTotal: "0.9358" },
  { geneTranscript: "NM_005406.3", parsimonySitesP: "0.117676021664205", constantSitesP: "0.785327424913836", distinctSitesP: "0.167897587395372", brLenTotal: "0.4174" },
  { geneTranscript: "NM_152405.5", parsimonySitesP: "0.201417004048583", constantSitesP: "0.652496626180837", distinctSitesP: "0.283738191632928", brLenTotal: "0.6845" },
  { geneTranscript: "NM_018217.3", parsimonySitesP: "0.15282583621684", constantSitesP: "0.736447520184544", distinctSitesP: "0.223760092272203", brLenTotal: "0.5978" },
  { geneTranscript: "NM_030877.5", parsimonySitesP: "0.149200710479574", constantSitesP: "0.750740082889284", distinctSitesP: "0.21255180580225", brLenTotal: "0.5409" },
  { geneTranscript: "NM_173161.3", parsimonySitesP: "0.24780701754386", constantSitesP: "0.585526315789474", distinctSitesP: "0.399122807017544", brLenTotal: "0.9011" },
  { geneTranscript: "NM_001080477.4", parsimonySitesP: "0.229097196492528", constantSitesP: "0.679263924910461", distinctSitesP: "0.245029023094973", brLenTotal: "1.0356" },
  { geneTranscript: "NM_001167676.2", parsimonySitesP: "0.228346456692913", constantSitesP: "0.564304461942257", distinctSitesP: "0.443569553805774", brLenTotal: "0.8264" },
  { geneTranscript: "NM_014271.4", parsimonySitesP: "0.101053639846743", constantSitesP: "0.818965517241379", distinctSitesP: "0.14367816091954", brLenTotal: "0.3122" },
  { geneTranscript: "NM_004140.4", parsimonySitesP: "0.227443609022556", constantSitesP: "0.656328320802005", distinctSitesP: "0.264724310776942", brLenTotal: "0.9865" },
  { geneTranscript: "NM_001005214.4", parsimonySitesP: "0.261980830670927", constantSitesP: "0.601703940362087", distinctSitesP: "0.363152289669862", brLenTotal: "0.9910" },
  { geneTranscript: "NM_017636.4", parsimonySitesP: "0.28116419549698", constantSitesP: "0.572487644151565", distinctSitesP: "0.367655134541461", brLenTotal: "1.1689" },
  { geneTranscript: "NM_022551.3", parsimonySitesP: "0.129385964912281", constantSitesP: "0.739035087719298", distinctSitesP: "0.258771929824561", brLenTotal: "0.4516" },
  { geneTranscript: "NM_001370259.2", parsimonySitesP: "0.177595628415301", constantSitesP: "0.719125683060109", distinctSitesP: "0.234426229508197", brLenTotal: "0.7473" },
  { geneTranscript: "NM_020470.3", parsimonySitesP: "0.150170648464164", constantSitesP: "0.726962457337884", distinctSitesP: "0.236632536973834", brLenTotal: "0.5514" },
  { geneTranscript: "NM_152753.4", parsimonySitesP: "0.162470627727425", constantSitesP: "0.746559248069822", distinctSitesP: "0.177240684793555", brLenTotal: "0.5222" },
  { geneTranscript: "NM_016538.3", parsimonySitesP: "0.21", constantSitesP: "0.666666666666667", distinctSitesP: "0.273333333333333", brLenTotal: "0.8286" },
  { geneTranscript: "NM_001384290.1", parsimonySitesP: "0.375739644970414", constantSitesP: "0.41025641025641", distinctSitesP: "0.566074950690335", brLenTotal: "2.5190" },
  { geneTranscript: "NM_000184.3", parsimonySitesP: "0.312925170068027", constantSitesP: "0.571428571428571", distinctSitesP: "0.421768707482993", brLenTotal: "1.2145" },
  { geneTranscript: "NM_015094.3", parsimonySitesP: "0.220054200542005", constantSitesP: "0.64769647696477", distinctSitesP: "0.294308943089431", brLenTotal: "1.1108" },
  { geneTranscript: "NM_175856.5", parsimonySitesP: "0.195767195767196", constantSitesP: "0.625094482237339", distinctSitesP: "0.26681783824641", brLenTotal: "0.8201" },
  { geneTranscript: "NM_006482.3", parsimonySitesP: "0.166389351081531", constantSitesP: "0.660011092623405", distinctSitesP: "0.220188574597892", brLenTotal: "1.6903" },
  { geneTranscript: "NM_001367549.1", parsimonySitesP: "0.147823779193206", constantSitesP: "0.749469214437367", distinctSitesP: "0.203556263269639", brLenTotal: "0.5009" },
  { geneTranscript: "NM_175061.4", parsimonySitesP: "0.0946502057613169", constantSitesP: "0.828532235939643", distinctSitesP: "0.16323731138546", brLenTotal: "0.3468" },
  { geneTranscript: "NM_021129.4", parsimonySitesP: "0.147635524798155", constantSitesP: "0.728950403690888", distinctSitesP: "0.256055363321799", brLenTotal: "0.5012" },
  { geneTranscript: "NM_030940.4", parsimonySitesP: "0.157622739018088", constantSitesP: "0.684754521963824", distinctSitesP: "0.320413436692506", brLenTotal: "0.5262" },
  { geneTranscript: "NM_145719.3", parsimonySitesP: "0.24203821656051", constantSitesP: "0.613588110403397", distinctSitesP: "0.316348195329087", brLenTotal: "0.8933" },
  { geneTranscript: "NM_001143842.2", parsimonySitesP: "0.185333333333333", constantSitesP: "0.696", distinctSitesP: "0.296", brLenTotal: "0.5970" },
  { geneTranscript: "NM_003909.5", parsimonySitesP: "0.152700186219739", constantSitesP: "0.750465549348231", distinctSitesP: "0.245189323401614", brLenTotal: "0.4925" },
  { geneTranscript: "NM_001013838.3", parsimonySitesP: "0.227177700348432", constantSitesP: "0.629268292682927", distinctSitesP: "0.317770034843206", brLenTotal: "0.8719" },
  { geneTranscript: "NM_138383.3", parsimonySitesP: "0.169120928157073", constantSitesP: "0.741186970102633", distinctSitesP: "0.21731369924141", brLenTotal: "0.8218" },
  { geneTranscript: "NM_005924.5", parsimonySitesP: "0.165570175438596", constantSitesP: "0.756578947368421", distinctSitesP: "0.235745614035088", brLenTotal: "0.4963" },
  { geneTranscript: "NM_001265577.2", parsimonySitesP: "0.285602503912363", constantSitesP: "0.545774647887324", distinctSitesP: "0.393192488262911", brLenTotal: "1.1327" },
  { geneTranscript: "NM_024815.4", parsimonySitesP: "0.253869969040248", constantSitesP: "0.596491228070175", distinctSitesP: "0.357069143446852", brLenTotal: "0.9283" },
  { geneTranscript: "NM_001379451.1", parsimonySitesP: "0.172922502334267", constantSitesP: "0.711858076563959", distinctSitesP: "0.196451914098973", brLenTotal: "0.6634" },
  { geneTranscript: "NM_178498.4", parsimonySitesP: "0.213592233009709", constantSitesP: "0.647788565264293", distinctSitesP: "0.330636461704423", brLenTotal: "0.7616" },
  { geneTranscript: "NM_194281.4", parsimonySitesP: "0.189236111111111", constantSitesP: "0.654513888888889", distinctSitesP: "0.319444444444444", brLenTotal: "0.7304" },
  { geneTranscript: "NM_173509.3", parsimonySitesP: "0.227544910179641", constantSitesP: "0.608782435129741", distinctSitesP: "0.365269461077844", brLenTotal: "0.8540" },
  { geneTranscript: "NM_014874.4", parsimonySitesP: "0.160281814178776", constantSitesP: "0.745486569793043", distinctSitesP: "0.211360634081902", brLenTotal: "0.6520" },
  { geneTranscript: "NM_002468.5", parsimonySitesP: "0.217342342342342", constantSitesP: "0.64527027027027", distinctSitesP: "0.30518018018018", brLenTotal: "0.7704" },
  { geneTranscript: "NM_012081.6", parsimonySitesP: "0.159895833333333", constantSitesP: "0.689583333333333", distinctSitesP: "0.278645833333333", brLenTotal: "0.5843" },
  { geneTranscript: "NM_003510.3", parsimonySitesP: "0.3", constantSitesP: "0.615384615384615", distinctSitesP: "0.376923076923077", brLenTotal: "2.4219" },
  { geneTranscript: "NM_016004.5", parsimonySitesP: "0.186117467581998", constantSitesP: "0.678871090770404", distinctSitesP: "0.27536231884058", brLenTotal: "0.5720" },
  { geneTranscript: "NM_018509.4", parsimonySitesP: "0.135722041259501", constantSitesP: "0.741585233441911", distinctSitesP: "0.226927252985885", brLenTotal: "0.4908" },
  { geneTranscript: "NM_003252.4", parsimonySitesP: "0.0648888888888889", constantSitesP: "0.886222222222222", distinctSitesP: "0.109333333333333", brLenTotal: "0.2016" },
  { geneTranscript: "NM_003625.5", parsimonySitesP: "0.149827631927871", constantSitesP: "0.760010607265977", distinctSitesP: "0.195173693980377", brLenTotal: "0.4946" },
  { geneTranscript: "NM_002918.5", parsimonySitesP: "0.193054136874362", constantSitesP: "0.699012597889002", distinctSitesP: "0.241743275451141", brLenTotal: "0.9271" },
  { geneTranscript: "NM_004115.4", parsimonySitesP: "0.109311740890688", constantSitesP: "0.807017543859649", distinctSitesP: "0.176788124156545", brLenTotal: "0.3252" },
  { geneTranscript: "NM_003171.5", parsimonySitesP: "0.207379134860051", constantSitesP: "0.671331636980492", distinctSitesP: "0.287107718405428", brLenTotal: "0.6615" },
  { geneTranscript: "NM_001199267.2", parsimonySitesP: "0.166307471264368", constantSitesP: "0.73742816091954", distinctSitesP: "0.21551724137931", brLenTotal: "0.8333" },
  { geneTranscript: "NM_001042413.2", parsimonySitesP: "0.211827956989247", constantSitesP: "0.670250896057348", distinctSitesP: "0.293548387096774", brLenTotal: "0.7664" },
  { geneTranscript: "NM_020897.3", parsimonySitesP: "0.189491817398794", constantSitesP: "0.68733850129199", distinctSitesP: "0.253229974160207", brLenTotal: "0.7716" },
  { geneTranscript: "NM_016564.4", parsimonySitesP: "0.357941834451902", constantSitesP: "0.434004474272931", distinctSitesP: "0.568232662192394", brLenTotal: "1.5428" },
  { geneTranscript: "NM_001371389.2", parsimonySitesP: "0.164190476190476", constantSitesP: "0.749333333333333", distinctSitesP: "0.205714285714286", brLenTotal: "0.7092" },
  { geneTranscript: "NM_006079.5", parsimonySitesP: "0.162962962962963", constantSitesP: "0.634567901234568", distinctSitesP: "0.304938271604938", brLenTotal: "0.8162" },
  { geneTranscript: "NM_022143.5", parsimonySitesP: "0.125574272588055", constantSitesP: "0.727922409392547", distinctSitesP: "0.178152118427769", brLenTotal: "0.5496" },
  { geneTranscript: "NM_002616.3", parsimonySitesP: "0.209043927648579", constantSitesP: "0.669767441860465", distinctSitesP: "0.256330749354005", brLenTotal: "0.7812" },
  { geneTranscript: "NM_000712.4", parsimonySitesP: "0.20045045045045", constantSitesP: "0.663288288288288", distinctSitesP: "0.320945945945946", brLenTotal: "0.6954" },
  { geneTranscript: "NM_003088.4", parsimonySitesP: "0.161595672751859", constantSitesP: "0.733603786342123", distinctSitesP: "0.231237322515213", brLenTotal: "0.7807" },
  { geneTranscript: "NM_016406.4", parsimonySitesP: "0.131736526946108", constantSitesP: "0.754491017964072", distinctSitesP: "0.241516966067864", brLenTotal: "0.4757" },
  { geneTranscript: "NM_001004309.3", parsimonySitesP: "0.231193926846101", constantSitesP: "0.592132505175983", distinctSitesP: "0.371980676328502", brLenTotal: "0.8260" },
  { geneTranscript: "NM_003116.3", parsimonySitesP: "0.206712433257056", constantSitesP: "0.627765064836003", distinctSitesP: "0.315026697177727", brLenTotal: "0.6527" },
  { geneTranscript: "NM_003536.3", parsimonySitesP: "0.321078431372549", constantSitesP: "0.561274509803922", distinctSitesP: "0.401960784313725", brLenTotal: "2.3556" },
  { geneTranscript: "NM_000141.5", parsimonySitesP: "0.149411287048315", constantSitesP: "0.78603329273244", distinctSitesP: "0.185140073081608", brLenTotal: "0.5657" },
  { geneTranscript: "NM_001330063.2", parsimonySitesP: "0.20302252637582", constantSitesP: "0.689478186484175", distinctSitesP: "0.254633589962931", brLenTotal: "0.7625" },
  { geneTranscript: "NM_004428.3", parsimonySitesP: "0.160975609756098", constantSitesP: "0.705691056910569", distinctSitesP: "0.260162601626016", brLenTotal: "0.5488" },
  { geneTranscript: "NM_006938.4", parsimonySitesP: "0.11484593837535", constantSitesP: "0.722689075630252", distinctSitesP: "0.282913165266106", brLenTotal: "0.4273" },
  { geneTranscript: "NM_005447.4", parsimonySitesP: "0.242145593869732", constantSitesP: "0.596934865900383", distinctSitesP: "0.360919540229885", brLenTotal: "0.8387" },
  { geneTranscript: "NM_078626.3", parsimonySitesP: "0.0992063492063492", constantSitesP: "0.833333333333333", distinctSitesP: "0.150793650793651", brLenTotal: "0.2826" },
  { geneTranscript: "NM_000883.4", parsimonySitesP: "0.169170840289371", constantSitesP: "0.730662214802449", distinctSitesP: "0.242626599888703", brLenTotal: "0.7989" },
  { geneTranscript: "NM_017541.4", parsimonySitesP: "0.155430711610487", constantSitesP: "0.750936329588015", distinctSitesP: "0.250936329588015", brLenTotal: "0.5409" },
  { geneTranscript: "NM_014624.4", parsimonySitesP: "0.174074074074074", constantSitesP: "0.711111111111111", distinctSitesP: "0.248148148148148", brLenTotal: "0.4925" },
  { geneTranscript: "NM_000122.2", parsimonySitesP: "0.163256606990622", constantSitesP: "0.731884057971014", distinctSitesP: "0.224211423699915", brLenTotal: "0.6387" },
  { geneTranscript: "NM_001007559.3", parsimonySitesP: "0.0805422647527911", constantSitesP: "0.845295055821372", distinctSitesP: "0.140350877192982", brLenTotal: "0.2814" },
  { geneTranscript: "NM_001037132.4", parsimonySitesP: "0.15439672801636", constantSitesP: "0.744376278118609", distinctSitesP: "0.201687116564417", brLenTotal: "0.5510" },
  { geneTranscript: "NM_000540.3", parsimonySitesP: "0.19280137620749", constantSitesP: "0.715694058488818", distinctSitesP: "0.213245997088792", brLenTotal: "0.8707" },
  { geneTranscript: "NM_013248.3", parsimonySitesP: "0.252380952380952", constantSitesP: "0.569047619047619", distinctSitesP: "0.364285714285714", brLenTotal: "0.9347" },
  { geneTranscript: "NM_174916.3", parsimonySitesP: "0.134553077949304", constantSitesP: "0.761959214789403", distinctSitesP: "0.180102915951973", brLenTotal: "0.4322" },
  { geneTranscript: "NM_003616.3", parsimonySitesP: "0.117719950433705", constantSitesP: "0.775712515489467", distinctSitesP: "0.232961586121437", brLenTotal: "0.4070" },
  { geneTranscript: "NM_020163.3", parsimonySitesP: "0.28772378516624", constantSitesP: "0.562233589087809", distinctSitesP: "0.349104859335038", brLenTotal: "1.1843" },
  { geneTranscript: "NM_198148.3", parsimonySitesP: "0.229717813051146", constantSitesP: "0.637125220458554", distinctSitesP: "0.33289241622575", brLenTotal: "0.9249" },
  { geneTranscript: "NM_000993.5", parsimonySitesP: "0.149333333333333", constantSitesP: "0.674666666666667", distinctSitesP: "0.32", brLenTotal: "0.5575" },
  { geneTranscript: "NM_002809.4", parsimonySitesP: "0.155430711610487", constantSitesP: "0.743445692883895", distinctSitesP: "0.210362047440699", brLenTotal: "0.5376" },
  { geneTranscript: "NM_005380.8", parsimonySitesP: "0.167587476979742", constantSitesP: "0.747697974217311", distinctSitesP: "0.259668508287293", brLenTotal: "0.6368" },
  { geneTranscript: "NM_006263.4", parsimonySitesP: "0.129852744310576", constantSitesP: "0.756358768406961", distinctSitesP: "0.21954484605087", brLenTotal: "0.4313" },
  { geneTranscript: "NM_001330422.2", parsimonySitesP: "0.142935079105292", constantSitesP: "0.731042007637752", distinctSitesP: "0.212220403709765", brLenTotal: "0.5336" },
  { geneTranscript: "NM_003331.5", parsimonySitesP: "0.283628194327436", constantSitesP: "0.580454928390901", distinctSitesP: "0.356922212861556", brLenTotal: "1.1457" },
  { geneTranscript: "NM_001287737.2", parsimonySitesP: "0.504566210045662", constantSitesP: "0.214611872146119", distinctSitesP: "0.773972602739726", brLenTotal: "2.5810" },
  { geneTranscript: "NM_005663.5", parsimonySitesP: "0.203282828282828", constantSitesP: "0.722222222222222", distinctSitesP: "0.268939393939394", brLenTotal: "0.9676" },
  { geneTranscript: "NM_032490.5", parsimonySitesP: "0.303333333333333", constantSitesP: "0.486666666666667", distinctSitesP: "0.536666666666667", brLenTotal: "0.9366" },
  { geneTranscript: "NM_006116.3", parsimonySitesP: "0.181216931216931", constantSitesP: "0.73015873015873", distinctSitesP: "0.236772486772487", brLenTotal: "0.8697" },
  { geneTranscript: "NM_001085458.2", parsimonySitesP: "0.115702479338843", constantSitesP: "0.786501377410468", distinctSitesP: "0.161157024793388", brLenTotal: "0.3874" },
  { geneTranscript: "NM_001126108.2", parsimonySitesP: "0.204701273261508", constantSitesP: "0.694743715311786", distinctSitesP: "0.256611165523996", brLenTotal: "0.9184" },
  { geneTranscript: "NM_001367949.2", parsimonySitesP: "0.192053461175274", constantSitesP: "0.690201205781942", distinctSitesP: "0.199753032614222", brLenTotal: "0.7275" },
  { geneTranscript: "NM_012182.3", parsimonySitesP: "0.124102564102564", constantSitesP: "0.787692307692308", distinctSitesP: "0.190769230769231", brLenTotal: "0.4667" },
  { geneTranscript: "NM_001101648.2", parsimonySitesP: "0.261761761761762", constantSitesP: "0.592342342342342", distinctSitesP: "0.331331331331331", brLenTotal: "1.1123" },
  { geneTranscript: "NM_007000.4", parsimonySitesP: "0.219638242894057", constantSitesP: "0.664082687338501", distinctSitesP: "0.334625322997416", brLenTotal: "0.9637" },
  { geneTranscript: "NM_144773.4", parsimonySitesP: "0.232638888888889", constantSitesP: "0.640625", distinctSitesP: "0.318576388888889", brLenTotal: "1.0725" },
  { geneTranscript: "NM_004583.4", parsimonySitesP: "0.115740740740741", constantSitesP: "0.739197530864198", distinctSitesP: "0.237654320987654", brLenTotal: "0.5222" },
  { geneTranscript: "NM_032312.4", parsimonySitesP: "0.0765027322404372", constantSitesP: "0.836065573770492", distinctSitesP: "0.15983606557377", brLenTotal: "0.2765" },
  { geneTranscript: "NM_173576.3", parsimonySitesP: "0.175189393939394", constantSitesP: "0.727272727272727", distinctSitesP: "0.256628787878788", brLenTotal: "0.6145" },
  { geneTranscript: "NM_001301073.2", parsimonySitesP: "0.114155251141553", constantSitesP: "0.777397260273973", distinctSitesP: "0.186073059360731", brLenTotal: "0.4368" },
  { geneTranscript: "NM_012205.3", parsimonySitesP: "0.231934731934732", constantSitesP: "0.618881118881119", distinctSitesP: "0.351981351981352", brLenTotal: "0.8537" },
  { geneTranscript: "NM_139137.4", parsimonySitesP: "0.233019853709509", constantSitesP: "0.682863113897597", distinctSitesP: "0.215256008359457", brLenTotal: "0.7162" },
  { geneTranscript: "NM_001195553.2", parsimonySitesP: "0.0992714025500911", constantSitesP: "0.806921675774135", distinctSitesP: "0.147540983606557", brLenTotal: "0.3394" },
  { geneTranscript: "NM_001051.5", parsimonySitesP: "0.230462519936204", constantSitesP: "0.662679425837321", distinctSitesP: "0.307814992025518", brLenTotal: "1.1789" },
  { geneTranscript: "NM_001004706.1", parsimonySitesP: "0.465166130760986", constantSitesP: "0.342979635584137", distinctSitesP: "0.612004287245445", brLenTotal: "3.1505" },
  { geneTranscript: "NM_001142462.3", parsimonySitesP: "0.108974358974359", constantSitesP: "0.823717948717949", distinctSitesP: "0.164529914529915", brLenTotal: "0.3479" },
  { geneTranscript: "NM_014501.3", parsimonySitesP: "0.204204204204204", constantSitesP: "0.659159159159159", distinctSitesP: "0.343843843843844", brLenTotal: "0.9902" },
  { geneTranscript: "NM_004492.3", parsimonySitesP: "0.0703363914373089", constantSitesP: "0.801223241590214", distinctSitesP: "0.217125382262997", brLenTotal: "0.2961" },
  { geneTranscript: "NM_001195755.2", parsimonySitesP: "0.242843951985226", constantSitesP: "0.61218836565097", distinctSitesP: "0.376731301939058", brLenTotal: "0.8001" },
  { geneTranscript: "NM_018420.3", parsimonySitesP: "0.15478366849482", constantSitesP: "0.739792809262645", distinctSitesP: "0.238878732480195", brLenTotal: "0.6279" },
  { geneTranscript: "NM_017545.3", parsimonySitesP: "0.205405405405405", constantSitesP: "0.671171171171171", distinctSitesP: "0.306306306306306", brLenTotal: "0.7996" },
  { geneTranscript: "NM_152339.4", parsimonySitesP: "0.256289308176101", constantSitesP: "0.626572327044025", distinctSitesP: "0.322327044025157", brLenTotal: "1.1846" },
  { geneTranscript: "NM_024535.5", parsimonySitesP: "0.277117117117117", constantSitesP: "0.58990990990991", distinctSitesP: "0.346666666666667", brLenTotal: "1.1673" },
  { geneTranscript: "NM_001278563.3", parsimonySitesP: "0.224489795918367", constantSitesP: "0.647014361300076", distinctSitesP: "0.338624338624339", brLenTotal: "1.0237" },
  { geneTranscript: "NM_001167856.3", parsimonySitesP: "0.136396267049533", constantSitesP: "0.768126346015793", distinctSitesP: "0.176357980378081", brLenTotal: "0.4634" },
  { geneTranscript: "NM_001005182.2", parsimonySitesP: "0.509615384615385", constantSitesP: "0.368589743589744", distinctSitesP: "0.617521367521368", brLenTotal: "2.7646" },
  { geneTranscript: "NM_000631.5", parsimonySitesP: "0.221238938053097", constantSitesP: "0.666666666666667", distinctSitesP: "0.319567354965585", brLenTotal: "1.0500" },
  { geneTranscript: "NM_001123225.3", parsimonySitesP: "0.21969696969697", constantSitesP: "0.681818181818182", distinctSitesP: "0.318181818181818", brLenTotal: "0.7218" },
  { geneTranscript: "NM_130807.3", parsimonySitesP: "0.235023041474654", constantSitesP: "0.640552995391705", distinctSitesP: "0.316436251920123", brLenTotal: "1.4024" },
  { geneTranscript: "NM_003555.1", parsimonySitesP: "0.678381256656017", constantSitesP: "0.233226837060703", distinctSitesP: "0.77209797657082", brLenTotal: "5.9027" },
  { geneTranscript: "NM_024041.4", parsimonySitesP: "0.195652173913043", constantSitesP: "0.652173913043478", distinctSitesP: "0.320289855072464", brLenTotal: "0.6552" },
  { geneTranscript: "NM_006387.6", parsimonySitesP: "0.189592430858806", constantSitesP: "0.718704512372635", distinctSitesP: "0.243813682678311", brLenTotal: "1.0152" },
  { geneTranscript: "NM_020131.5", parsimonySitesP: "0.164725457570715", constantSitesP: "0.731558513588464", distinctSitesP: "0.217970049916805", brLenTotal: "0.6151" },
  { geneTranscript: "NM_145870.3", parsimonySitesP: "0.223765432098765", constantSitesP: "0.612654320987654", distinctSitesP: "0.381172839506173", brLenTotal: "0.8127" },
  { geneTranscript: "NM_001101372.3", parsimonySitesP: "0.143849206349206", constantSitesP: "0.76984126984127", distinctSitesP: "0.220238095238095", brLenTotal: "0.6515" },
  { geneTranscript: "NM_013444.4", parsimonySitesP: "0.12767094017094", constantSitesP: "0.78258547008547", distinctSitesP: "0.172008547008547", brLenTotal: "0.3951" },
  { geneTranscript: "NM_018971.3", parsimonySitesP: "0.345777777777778", constantSitesP: "0.564444444444444", distinctSitesP: "0.287111111111111", brLenTotal: "1.1451" },
  { geneTranscript: "NM_004643.4", parsimonySitesP: "0.093681917211329", constantSitesP: "0.765795206971678", distinctSitesP: "0.224400871459695", brLenTotal: "0.3934" },
  { geneTranscript: "NM_022351.5", parsimonySitesP: "0.121557454890788", constantSitesP: "0.794871794871795", distinctSitesP: "0.206077872744539", brLenTotal: "0.3789" },
  { geneTranscript: "NM_001324116.5", parsimonySitesP: "0.169220945083014", constantSitesP: "0.706257982120051", distinctSitesP: "0.244572158365262", brLenTotal: "0.5590" },
  { geneTranscript: "NM_002738.7", parsimonySitesP: "0.145616641901932", constantSitesP: "0.771173848439822", distinctSitesP: "0.195641406636949", brLenTotal: "0.5748" },
  { geneTranscript: "NM_005104.4", parsimonySitesP: "0.176029962546816", constantSitesP: "0.688722430295464", distinctSitesP: "0.24427798585102", brLenTotal: "0.6235" },
  { geneTranscript: "NM_004804.3", parsimonySitesP: "0.158308751229105", constantSitesP: "0.731563421828909", distinctSitesP: "0.231071779744346", brLenTotal: "0.5294" },
  { geneTranscript: "NM_000352.6", parsimonySitesP: "0.173308032890576", constantSitesP: "0.734556188066624", distinctSitesP: "0.207463630613536", brLenTotal: "0.7100" },
  { geneTranscript: "NM_001369450.1", parsimonySitesP: "0.244155844155844", constantSitesP: "0.605194805194805", distinctSitesP: "0.36017316017316", brLenTotal: "0.8173" },
  { geneTranscript: "NM_001130182.2", parsimonySitesP: "0.17968094038623", constantSitesP: "0.685978169605374", distinctSitesP: "0.273719563392107", brLenTotal: "0.7362" },
  { geneTranscript: "NM_001005277.1", parsimonySitesP: "0.56517094017094", constantSitesP: "0.305555555555556", distinctSitesP: "0.681623931623932", brLenTotal: "3.3366" },
  { geneTranscript: "NM_000294.3", parsimonySitesP: "0.136288998357964", constantSitesP: "0.754515599343186", distinctSitesP: "0.215106732348112", brLenTotal: "0.4833" },
  { geneTranscript: "NM_001144887.2", parsimonySitesP: "0.229706390328152", constantSitesP: "0.640759930915371", distinctSitesP: "0.336787564766839", brLenTotal: "0.7348" },
  { geneTranscript: "NM_006164.5", parsimonySitesP: "0.166942148760331", constantSitesP: "0.69366391184573", distinctSitesP: "0.260606060606061", brLenTotal: "0.5373" },
  { geneTranscript: "NM_005148.4", parsimonySitesP: "0.136111111111111", constantSitesP: "0.763888888888889", distinctSitesP: "0.219444444444444", brLenTotal: "0.4908" },
  { geneTranscript: "NM_206827.2", parsimonySitesP: "0.21900826446281", constantSitesP: "0.648760330578512", distinctSitesP: "0.323691460055096", brLenTotal: "0.8335" },
  { geneTranscript: "NM_003356.4", parsimonySitesP: "0.243589743589744", constantSitesP: "0.635683760683761", distinctSitesP: "0.341880341880342", brLenTotal: "0.9995" },
  { geneTranscript: "NM_031407.7", parsimonySitesP: "0.122847126962353", constantSitesP: "0.799725651577503", distinctSitesP: "0.125666819082457", brLenTotal: "0.3628" },
  { geneTranscript: "NM_000612.6", parsimonySitesP: "0.190740740740741", constantSitesP: "0.712962962962963", distinctSitesP: "0.294444444444444", brLenTotal: "0.6244" },
  { geneTranscript: "NM_152284.4", parsimonySitesP: "0.214592274678112", constantSitesP: "0.669527896995708", distinctSitesP: "0.336194563662375", brLenTotal: "0.7068" },
  { geneTranscript: "NM_182528.4", parsimonySitesP: "0.21602787456446", constantSitesP: "0.666666666666667", distinctSitesP: "0.284552845528455", brLenTotal: "0.8825" },
  { geneTranscript: "NM_001077350.3", parsimonySitesP: "0.182190978324546", constantSitesP: "0.718219097832455", distinctSitesP: "0.244874048037493", brLenTotal: "1.0000" },
  { geneTranscript: "NM_182757.4", parsimonySitesP: "0.171617161716172", constantSitesP: "0.696369636963696", distinctSitesP: "0.271727172717272", brLenTotal: "0.7256" },
  { geneTranscript: "NM_020713.3", parsimonySitesP: "0.256726457399103", constantSitesP: "0.612855007473842", distinctSitesP: "0.333333333333333", brLenTotal: "1.1903" },
  { geneTranscript: "NM_005313.5", parsimonySitesP: "0.153135313531353", constantSitesP: "0.728712871287129", distinctSitesP: "0.246204620462046", brLenTotal: "0.4867" },
  { geneTranscript: "NM_001383.6", parsimonySitesP: "0.235159817351598", constantSitesP: "0.638508371385084", distinctSitesP: "0.324200913242009", brLenTotal: "0.8116" },
  { geneTranscript: "NM_052916.3", parsimonySitesP: "0.17427589592538", constantSitesP: "0.715758468335788", distinctSitesP: "0.253804614629357", brLenTotal: "0.6088" },
  { geneTranscript: "NM_014963.3", parsimonySitesP: "0.27086383601757", constantSitesP: "0.618594436310395", distinctSitesP: "0.337481698389458", brLenTotal: "1.3445" },
  { geneTranscript: "NM_001111077.2", parsimonySitesP: "0.197383390216155", constantSitesP: "0.664391353811149", distinctSitesP: "0.271899886234357", brLenTotal: "1.0081" },
  { geneTranscript: "NM_201630.2", parsimonySitesP: "0.200561009817672", constantSitesP: "0.689574567554932", distinctSitesP: "0.252921926133707", brLenTotal: "0.8064" },
  { geneTranscript: "NM_017814.3", parsimonySitesP: "0.265135699373695", constantSitesP: "0.599164926931106", distinctSitesP: "0.363256784968685", brLenTotal: "1.1016" },
  { geneTranscript: "NM_004261.5", parsimonySitesP: "0.204040404040404", constantSitesP: "0.660606060606061", distinctSitesP: "0.365656565656566", brLenTotal: "0.7289" },
  { geneTranscript: "NM_024420.3", parsimonySitesP: "0.186470850022252", constantSitesP: "0.720071206052514", distinctSitesP: "0.272363150867824", brLenTotal: "0.6359" },
  { geneTranscript: "NM_001495.5", parsimonySitesP: "0.146551724137931", constantSitesP: "0.755747126436782", distinctSitesP: "0.22485632183908", brLenTotal: "0.6549" },
  { geneTranscript: "NM_001007231.3", parsimonySitesP: "0.209494324045408", constantSitesP: "0.654798761609907", distinctSitesP: "0.298761609907121", brLenTotal: "0.7523" },
  { geneTranscript: "NM_014974.3", parsimonySitesP: "0.228149100257069", constantSitesP: "0.698157669237361", distinctSitesP: "0.287489288774636", brLenTotal: "1.1399" },
  { geneTranscript: "NM_006285.3", parsimonySitesP: "0.152289669861555", constantSitesP: "0.727369542066028", distinctSitesP: "0.223109691160809", brLenTotal: "0.5997" },
  { geneTranscript: "NM_001959.4", parsimonySitesP: "0.173333333333333", constantSitesP: "0.659259259259259", distinctSitesP: "0.300740740740741", brLenTotal: "0.6623" },
  { geneTranscript: "NM_001099436.4", parsimonySitesP: "0.20409604519774", constantSitesP: "0.670197740112994", distinctSitesP: "0.281779661016949", brLenTotal: "0.7898" },
  { geneTranscript: "NM_001416.4", parsimonySitesP: "0.179802955665025", constantSitesP: "0.688834154351396", distinctSitesP: "0.283251231527094", brLenTotal: "0.6974" },
  { geneTranscript: "NM_000484.4", parsimonySitesP: "0.153679653679654", constantSitesP: "0.76969696969697", distinctSitesP: "0.201731601731602", brLenTotal: "0.6102" },
  { geneTranscript: "NM_012304.5", parsimonySitesP: "0.112016293279022", constantSitesP: "0.809232858112695", distinctSitesP: "0.167006109979633", brLenTotal: "0.3703" },
  { geneTranscript: "NM_000807.4", parsimonySitesP: "0.206208425720621", constantSitesP: "0.716186252771619", distinctSitesP: "0.271988174427199", brLenTotal: "0.9075" },
  { geneTranscript: "NM_022832.4", parsimonySitesP: "0.201275045537341", constantSitesP: "0.713114754098361", distinctSitesP: "0.246812386156648", brLenTotal: "1.0391" },
  { geneTranscript: "NM_005716.4", parsimonySitesP: "0.233233233233233", constantSitesP: "0.673673673673674", distinctSitesP: "0.293293293293293", brLenTotal: "1.0869" },
  { geneTranscript: "NM_006769.4", parsimonySitesP: "0.0626262626262626", constantSitesP: "0.868686868686869", distinctSitesP: "0.135353535353535", brLenTotal: "0.2322" },
  { geneTranscript: "NM_001195518.2", parsimonySitesP: "0.13515406162465", constantSitesP: "0.755602240896359", distinctSitesP: "0.225490196078431", brLenTotal: "0.4478" },
  { geneTranscript: "NM_212482.4", parsimonySitesP: "0.180863948324586", constantSitesP: "0.726012649710672", distinctSitesP: "0.219082223119365", brLenTotal: "0.6742" },
  { geneTranscript: "NM_032214.4", parsimonySitesP: "0.247765006385696", constantSitesP: "0.581098339719029", distinctSitesP: "0.40485312899106", brLenTotal: "0.9282" },
  { geneTranscript: "NM_018270.6", parsimonySitesP: "0.155228758169935", constantSitesP: "0.740196078431373", distinctSitesP: "0.261437908496732", brLenTotal: "0.6305" },
  { geneTranscript: "NM_024027.5", parsimonySitesP: "0.211562115621156", constantSitesP: "0.702337023370234", distinctSitesP: "0.292742927429274", brLenTotal: "0.9393" },
  { geneTranscript: "NM_212559.3", parsimonySitesP: "0.131403118040089", constantSitesP: "0.741648106904232", distinctSitesP: "0.210838901262064", brLenTotal: "0.4281" },
  { geneTranscript: "NM_001017980.4", parsimonySitesP: "0.171617161716172", constantSitesP: "0.712871287128713", distinctSitesP: "0.29042904290429", brLenTotal: "0.5357" },
  { geneTranscript: "NM_015456.5", parsimonySitesP: "0.219214437367304", constantSitesP: "0.684713375796178", distinctSitesP: "0.290339702760085", brLenTotal: "0.9982" },
  { geneTranscript: "NM_001242617.2", parsimonySitesP: "0.199530516431925", constantSitesP: "0.629107981220657", distinctSitesP: "0.363849765258216", brLenTotal: "0.6844" },
  { geneTranscript: "NM_001031684.3", parsimonySitesP: "0.0910364145658263", constantSitesP: "0.7703081232493", distinctSitesP: "0.196078431372549", brLenTotal: "0.3710" },
  { geneTranscript: "NM_001130040.2", parsimonySitesP: "0.149543378995434", constantSitesP: "0.755707762557078", distinctSitesP: "0.218036529680365", brLenTotal: "0.5138" },
  { geneTranscript: "NM_018012.4", parsimonySitesP: "0.249367488931056", constantSitesP: "0.615275142314991", distinctSitesP: "0.301707779886148", brLenTotal: "1.0893" },
  { geneTranscript: "NM_004270.5", parsimonySitesP: "0.128755364806867", constantSitesP: "0.705293276108727", distinctSitesP: "0.237482117310443", brLenTotal: "0.4666" },
  { geneTranscript: "NM_012392.4", parsimonySitesP: "0.185446009389671", constantSitesP: "0.674882629107981", distinctSitesP: "0.313380281690141", brLenTotal: "0.7096" },
  { geneTranscript: "NM_025152.3", parsimonySitesP: "0.191222570532915", constantSitesP: "0.681295715778474", distinctSitesP: "0.315569487983281", brLenTotal: "0.6275" },
  { geneTranscript: "NM_006224.4", parsimonySitesP: "0.164197530864198", constantSitesP: "0.776543209876543", distinctSitesP: "0.225925925925926", brLenTotal: "0.5057" },
  { geneTranscript: "NM_016226.5", parsimonySitesP: "0.0970695970695971", constantSitesP: "0.802197802197802", distinctSitesP: "0.205128205128205", brLenTotal: "0.3285" },
  { geneTranscript: "NM_003581.5", parsimonySitesP: "0.159649122807018", constantSitesP: "0.669298245614035", distinctSitesP: "0.235964912280702", brLenTotal: "1.1833" },
  { geneTranscript: "NM_018096.5", parsimonySitesP: "0.164948453608247", constantSitesP: "0.712714776632302", distinctSitesP: "0.245360824742268", brLenTotal: "0.6218" },
  { geneTranscript: "NM_004664.4", parsimonySitesP: "0.124463519313305", constantSitesP: "0.802575107296137", distinctSitesP: "0.184549356223176", brLenTotal: "0.4041" },
  { geneTranscript: "NM_001135789.5", parsimonySitesP: "0.268292682926829", constantSitesP: "0.434959349593496", distinctSitesP: "0.502032520325203", brLenTotal: "1.6493" },
  { geneTranscript: "NM_001318503.2", parsimonySitesP: "0.221354166666667", constantSitesP: "0.646701388888889", distinctSitesP: "0.298611111111111", brLenTotal: "0.8736" },
  { geneTranscript: "NM_014615.5", parsimonySitesP: "0.223500410846343", constantSitesP: "0.652150095864147", distinctSitesP: "0.3021090112298", brLenTotal: "1.1466" },
  { geneTranscript: "NM_001018.5", parsimonySitesP: "0.213793103448276", constantSitesP: "0.581609195402299", distinctSitesP: "0.383908045977011", brLenTotal: "1.0967" },
  { geneTranscript: "NM_020846.2", parsimonySitesP: "0.164658634538153", constantSitesP: "0.683878370625359", distinctSitesP: "0.258749282845668", brLenTotal: "0.6648" },
  { geneTranscript: "NM_001286045.2", parsimonySitesP: "0.164794007490637", constantSitesP: "0.67665418227216", distinctSitesP: "0.298377028714107", brLenTotal: "0.5738" },
  { geneTranscript: "NM_033637.4", parsimonySitesP: "0.120110192837466", constantSitesP: "0.775757575757576", distinctSitesP: "0.175206611570248", brLenTotal: "0.4558" },
  { geneTranscript: "NM_000782.5", parsimonySitesP: "0.260700389105058", constantSitesP: "0.606355382619974", distinctSitesP: "0.390402075226978", brLenTotal: "0.9744" },
  { geneTranscript: "NM_017592.4", parsimonySitesP: "0.19", constantSitesP: "0.72", distinctSitesP: "0.276666666666667", brLenTotal: "0.6684" },
  { geneTranscript: "NM_015690.5", parsimonySitesP: "0.228390367553866", constantSitesP: "0.611153358681876", distinctSitesP: "0.293789607097592", brLenTotal: "0.7493" },
  { geneTranscript: "NM_001184970.3", parsimonySitesP: "0.198902606310014", constantSitesP: "0.703017832647462", distinctSitesP: "0.264060356652949", brLenTotal: "1.0291" },
  { geneTranscript: "NM_002741.5", parsimonySitesP: "0.208775654635527", constantSitesP: "0.681882519462137", distinctSitesP: "0.260438782731776", brLenTotal: "1.0298" },
  { geneTranscript: "NM_006471.4", parsimonySitesP: "0.194931773879142", constantSitesP: "0.612085769980507", distinctSitesP: "0.374269005847953", brLenTotal: "1.0023" },
  { geneTranscript: "NM_001387552.1", parsimonySitesP: "0.205443940375891", constantSitesP: "0.696910779866062", distinctSitesP: "0.237416288615252", brLenTotal: "0.8273" },
  { geneTranscript: "NM_005444.3", parsimonySitesP: "0.0981047937569677", constantSitesP: "0.819397993311037", distinctSitesP: "0.166109253065775", brLenTotal: "0.3220" },
  { geneTranscript: "NM_198173.3", parsimonySitesP: "0.19047619047619", constantSitesP: "0.700996677740864", distinctSitesP: "0.260797342192691", brLenTotal: "0.8771" },
  { geneTranscript: "NM_173514.4", parsimonySitesP: "0.139631610219846", constantSitesP: "0.755199049316696", distinctSitesP: "0.240047534165181", brLenTotal: "0.4501" },
  { geneTranscript: "NM_018368.4", parsimonySitesP: "0.143827160493827", constantSitesP: "0.760493827160494", distinctSitesP: "0.22962962962963", brLenTotal: "0.4596" },
  { geneTranscript: "NM_025241.3", parsimonySitesP: "0.269085411942555", constantSitesP: "0.603174603174603", distinctSitesP: "0.383219954648526", brLenTotal: "1.1194" },
  { geneTranscript: "NM_173825.5", parsimonySitesP: "0.117231638418079", constantSitesP: "0.785310734463277", distinctSitesP: "0.206214689265537", brLenTotal: "0.3469" },
  { geneTranscript: "NM_000660.7", parsimonySitesP: "0.18974358974359", constantSitesP: "0.716239316239316", distinctSitesP: "0.235042735042735", brLenTotal: "0.6328" },
  { geneTranscript: "NM_024828.4", parsimonySitesP: "0.162511542012927", constantSitesP: "0.701754385964912", distinctSitesP: "0.27331486611265", brLenTotal: "0.5849" },
  { geneTranscript: "NM_001105192.3", parsimonySitesP: "0.146510619852622", constantSitesP: "0.776766363242306", distinctSitesP: "0.189423493714781", brLenTotal: "0.7699" },
  { geneTranscript: "NM_001304944.2", parsimonySitesP: "0.23069403714565", constantSitesP: "0.58455522971652", distinctSitesP: "0.35386119257087", brLenTotal: "0.7668" },
  { geneTranscript: "NM_080872.4", parsimonySitesP: "0.163343826512767", constantSitesP: "0.743966421825813", distinctSitesP: "0.223854494578524", brLenTotal: "0.7020" },
  { geneTranscript: "NM_000217.3", parsimonySitesP: "0.276094276094276", constantSitesP: "0.641750841750842", distinctSitesP: "0.323905723905724", brLenTotal: "1.3146" },
  { geneTranscript: "NM_001040709.2", parsimonySitesP: "0.14828431372549", constantSitesP: "0.745098039215686", distinctSitesP: "0.25", brLenTotal: "0.5633" },
  { geneTranscript: "NM_005001.5", parsimonySitesP: "0.247787610619469", constantSitesP: "0.595870206489676", distinctSitesP: "0.377581120943953", brLenTotal: "1.0963" },
  { geneTranscript: "NM_174913.3", parsimonySitesP: "0.237421383647799", constantSitesP: "0.593815513626834", distinctSitesP: "0.35062893081761", brLenTotal: "0.7705" },
  { geneTranscript: "NM_007279.3", parsimonySitesP: "0.127017543859649", constantSitesP: "0.809122807017544", distinctSitesP: "0.16140350877193", brLenTotal: "0.5837" },
  { geneTranscript: "NM_003808.4", parsimonySitesP: "0.209333333333333", constantSitesP: "0.676", distinctSitesP: "0.282666666666667", brLenTotal: "0.6106" },
  { geneTranscript: "NM_024544.3", parsimonySitesP: "0.1875", constantSitesP: "0.720643939393939", distinctSitesP: "0.247159090909091", brLenTotal: "0.6419" },
  { geneTranscript: "NM_020841.5", parsimonySitesP: "0.120734908136483", constantSitesP: "0.78440194975628", distinctSitesP: "0.172853393325834", brLenTotal: "0.3623" },
  { geneTranscript: "NM_004926.4", parsimonySitesP: "0.103550295857988", constantSitesP: "0.821499013806706", distinctSitesP: "0.151873767258383", brLenTotal: "0.3706" },
  { geneTranscript: "NM_000710.4", parsimonySitesP: "0.316336166194523", constantSitesP: "0.52219074598678", distinctSitesP: "0.440982058545798", brLenTotal: "1.3021" },
  { geneTranscript: "NM_002661.5", parsimonySitesP: "0.197101449275362", constantSitesP: "0.700395256916996", distinctSitesP: "0.265349143610013", brLenTotal: "0.8161" },
  { geneTranscript: "NM_016277.5", parsimonySitesP: "0.109704641350211", constantSitesP: "0.79887482419128", distinctSitesP: "0.194092827004219", brLenTotal: "0.3452" },
  { geneTranscript: "NM_001376558.2", parsimonySitesP: "0.083088954056696", constantSitesP: "0.840664711632454", distinctSitesP: "0.134897360703812", brLenTotal: "0.2724" },
  { geneTranscript: "NM_014762.4", parsimonySitesP: "0.164082687338501", constantSitesP: "0.737080103359173", distinctSitesP: "0.229328165374677", brLenTotal: "0.7433" },
  { geneTranscript: "NM_001153484.2", parsimonySitesP: "0.105691056910569", constantSitesP: "0.804878048780488", distinctSitesP: "0.227642276422764", brLenTotal: "0.3004" },
  { geneTranscript: "NM_001893.6", parsimonySitesP: "0.183132530120482", constantSitesP: "0.722088353413655", distinctSitesP: "0.236947791164659", brLenTotal: "0.8517" },
  { geneTranscript: "NM_080607.3", parsimonySitesP: "0.125816993464052", constantSitesP: "0.759803921568627", distinctSitesP: "0.225490196078431", brLenTotal: "0.5993" },
  { geneTranscript: "NM_001143780.3", parsimonySitesP: "0.220055710306407", constantSitesP: "0.633240482822656", distinctSitesP: "0.326833797585887", brLenTotal: "0.9545" },
  { geneTranscript: "NM_003179.3", parsimonySitesP: "0.136315228966986", constantSitesP: "0.791267305644302", distinctSitesP: "0.192758253461129", brLenTotal: "0.4956" },
  { geneTranscript: "NM_006343.3", parsimonySitesP: "0.237570904237571", constantSitesP: "0.615281948615282", distinctSitesP: "0.34668001334668", brLenTotal: "0.9206" },
  { geneTranscript: "NM_134269.3", parsimonySitesP: "0.238979963570128", constantSitesP: "0.609471766848816", distinctSitesP: "0.322040072859745", brLenTotal: "0.9124" },
  { geneTranscript: "NM_006806.5", parsimonySitesP: "0.132275132275132", constantSitesP: "0.718253968253968", distinctSitesP: "0.259259259259259", brLenTotal: "0.4443" },
  { geneTranscript: "NM_001539.4", parsimonySitesP: "0.109991603694374", constantSitesP: "0.720403022670025", distinctSitesP: "0.228379513014274", brLenTotal: "0.4779" },
  { geneTranscript: "NM_001349884.2", parsimonySitesP: "0.160401002506266", constantSitesP: "0.740601503759398", distinctSitesP: "0.228070175438596", brLenTotal: "0.5783" },
  { geneTranscript: "NM_001009984.3", parsimonySitesP: "0.157462475219485", constantSitesP: "0.711129991503823", distinctSitesP: "0.227414330218069", brLenTotal: "0.5185" },
  { geneTranscript: "NM_153371.4", parsimonySitesP: "0.201932367149758", constantSitesP: "0.676328502415459", distinctSitesP: "0.295652173913043", brLenTotal: "0.7715" },
  { geneTranscript: "NM_001134398.2", parsimonySitesP: "0.207289293849658", constantSitesP: "0.708048595292331", distinctSitesP: "0.265375854214123", brLenTotal: "0.8949" },
  { geneTranscript: "NM_001080521.3", parsimonySitesP: "0.22879684418146", constantSitesP: "0.6232741617357", distinctSitesP: "0.309664694280079", brLenTotal: "0.8335" },
  { geneTranscript: "NM_005303.3", parsimonySitesP: "0.305555555555556", constantSitesP: "0.561111111111111", distinctSitesP: "0.424444444444444", brLenTotal: "1.2675" },
  { geneTranscript: "NM_181809.4", parsimonySitesP: "0.227197346600332", constantSitesP: "0.602819237147595", distinctSitesP: "0.3681592039801", brLenTotal: "1.0620" },
  { geneTranscript: "NM_006244.4", parsimonySitesP: "0.138832997987928", constantSitesP: "0.785378940308518", distinctSitesP: "0.183769282360832", brLenTotal: "0.4652" },
  { geneTranscript: "NM_004124.3", parsimonySitesP: "0.0892018779342723", constantSitesP: "0.830985915492958", distinctSitesP: "0.171361502347418", brLenTotal: "0.2867" },
  { geneTranscript: "NM_058169.6", parsimonySitesP: "0.166666666666667", constantSitesP: "0.744897959183673", distinctSitesP: "0.236394557823129", brLenTotal: "0.6240" },
  { geneTranscript: "NM_001142395.2", parsimonySitesP: "0.129969418960245", constantSitesP: "0.775229357798165", distinctSitesP: "0.212538226299694", brLenTotal: "0.3241" },
  { geneTranscript: "NM_006369.5", parsimonySitesP: "0.116174055829228", constantSitesP: "0.798029556650246", distinctSitesP: "0.15311986863711", brLenTotal: "0.3475" },
  { geneTranscript: "NM_031477.5", parsimonySitesP: "0.0785562632696391", constantSitesP: "0.857749469214437", distinctSitesP: "0.146496815286624", brLenTotal: "0.1949" },
  { geneTranscript: "NM_002168.4", parsimonySitesP: "0.179941002949853", constantSitesP: "0.738200589970501", distinctSitesP: "0.250737463126844", brLenTotal: "0.7305" },
  { geneTranscript: "NM_153221.2", parsimonySitesP: "0.28517877739331", constantSitesP: "0.545271049596309", distinctSitesP: "0.335928489042676", brLenTotal: "1.4324" },
  { geneTranscript: "NM_001105206.3", parsimonySitesP: "0.203693545437923", constantSitesP: "0.669226549643445", distinctSitesP: "0.275187420003657", brLenTotal: "0.7255" },
  { geneTranscript: "NM_014924.5", parsimonySitesP: "0.173441734417344", constantSitesP: "0.717479674796748", distinctSitesP: "0.254742547425474", brLenTotal: "0.6259" },
  { geneTranscript: "NM_001178020.3", parsimonySitesP: "0.261261261261261", constantSitesP: "0.588159588159588", distinctSitesP: "0.384813384813385", brLenTotal: "1.1595" },
  { geneTranscript: "NM_021158.5", parsimonySitesP: "0.310055865921788", constantSitesP: "0.506517690875233", distinctSitesP: "0.437616387337058", brLenTotal: "1.3344" },
  { geneTranscript: "NM_016437.3", parsimonySitesP: "0.16260162601626", constantSitesP: "0.720620842572062", distinctSitesP: "0.246119733924612", brLenTotal: "0.6155" },
  { geneTranscript: "NM_001114123.3", parsimonySitesP: "0.26791277258567", constantSitesP: "0.571651090342679", distinctSitesP: "0.373052959501558", brLenTotal: "1.0308" },
  { geneTranscript: "NM_018404.3", parsimonySitesP: "0.196850393700787", constantSitesP: "0.6832895888014", distinctSitesP: "0.282589676290464", brLenTotal: "0.6264" },
  { geneTranscript: "NM_004914.5", parsimonySitesP: "0.237203495630462", constantSitesP: "0.65043695380774", distinctSitesP: "0.368289637952559", brLenTotal: "0.9891" },
  { geneTranscript: "NM_000487.6", parsimonySitesP: "0.259986902423052", constantSitesP: "0.603798297314997", distinctSitesP: "0.349705304518664", brLenTotal: "1.0085" },
  { geneTranscript: "NM_001034954.3", parsimonySitesP: "0.165376676986584", constantSitesP: "0.71155830753354", distinctSitesP: "0.230134158926729", brLenTotal: "0.6343" },
  { geneTranscript: "NM_003861.3", parsimonySitesP: "0.154635527246992", constantSitesP: "0.740268931351734", distinctSitesP: "0.197452229299363", brLenTotal: "0.5418" },
  { geneTranscript: "NM_000827.4", parsimonySitesP: "0.150478292862399", constantSitesP: "0.729212656364974", distinctSitesP: "0.190213392200147", brLenTotal: "0.6460" },
  { geneTranscript: "NM_006327.4", parsimonySitesP: "0.130781499202552", constantSitesP: "0.709728867623604", distinctSitesP: "0.274322169059011", brLenTotal: "0.5522" },
  { geneTranscript: "NM_031937.3", parsimonySitesP: "0.191601049868766", constantSitesP: "0.692913385826772", distinctSitesP: "0.254593175853018", brLenTotal: "0.7798" },
  { geneTranscript: "NM_144594.3", parsimonySitesP: "0.155688622754491", constantSitesP: "0.732534930139721", distinctSitesP: "0.311377245508982", brLenTotal: "0.4380" },
  { geneTranscript: "NM_017742.6", parsimonySitesP: "0.16044142614601", constantSitesP: "0.715619694397284", distinctSitesP: "0.258347481607244", brLenTotal: "0.5596" },
  { geneTranscript: "NM_001554.5", parsimonySitesP: "0.173228346456693", constantSitesP: "0.723534558180227", distinctSitesP: "0.236220472440945", brLenTotal: "0.5790" },
  { geneTranscript: "NM_006612.6", parsimonySitesP: "0.200967059534603", constantSitesP: "0.675430643699003", distinctSitesP: "0.255666364460562", brLenTotal: "0.7786" },
  { geneTranscript: "NM_181333.4", parsimonySitesP: "0.217353951890034", constantSitesP: "0.673539518900344", distinctSitesP: "0.316151202749141", brLenTotal: "1.0951" },
  { geneTranscript: "NM_032632.5", parsimonySitesP: "0.0662192393736018", constantSitesP: "0.838926174496644", distinctSitesP: "0.132885906040268", brLenTotal: "0.2714" },
  { geneTranscript: "NM_001171613.2", parsimonySitesP: "0.139498432601881", constantSitesP: "0.75914315569488", distinctSitesP: "0.22466039707419", brLenTotal: "0.4752" },
  { geneTranscript: "NM_015335.5", parsimonySitesP: "0.106636500754148", constantSitesP: "0.811915535444947", distinctSitesP: "0.130015082956259", brLenTotal: "0.3549" },
  { geneTranscript: "NM_006253.5", parsimonySitesP: "0.14320987654321", constantSitesP: "0.775308641975309", distinctSitesP: "0.22962962962963", brLenTotal: "0.5138" },
  { geneTranscript: "NM_002158.4", parsimonySitesP: "0.131477184841454", constantSitesP: "0.765661252900232", distinctSitesP: "0.215777262180974", brLenTotal: "0.4025" },
  { geneTranscript: "NM_001973.4", parsimonySitesP: "0.191028615622583", constantSitesP: "0.655065738592421", distinctSitesP: "0.290796597061098", brLenTotal: "0.6681" },
  { geneTranscript: "NM_004990.4", parsimonySitesP: "0.19037037037037", constantSitesP: "0.682592592592593", distinctSitesP: "0.246296296296296", brLenTotal: "0.6521" },
  { geneTranscript: "NM_001029871.4", parsimonySitesP: "0.235042735042735", constantSitesP: "0.605413105413105", distinctSitesP: "0.371794871794872", brLenTotal: "0.9119" },
  { geneTranscript: "NM_020872.3", parsimonySitesP: "0.176070038910506", constantSitesP: "0.708171206225681", distinctSitesP: "0.259403372243839", brLenTotal: "0.6521" },
  { geneTranscript: "NM_000678.4", parsimonySitesP: "0.214452214452214", constantSitesP: "0.643356643356643", distinctSitesP: "0.296037296037296", brLenTotal: "0.8290" },
  { geneTranscript: "NM_178314.5", parsimonySitesP: "0.164598842018197", constantSitesP: "0.739454094292804", distinctSitesP: "0.222497932175352", brLenTotal: "0.6182" },
  { geneTranscript: "NM_001348716.2", parsimonySitesP: "0.157638466220329", constantSitesP: "0.735037532968148", distinctSitesP: "0.196388719821465", brLenTotal: "0.5898" },
  { geneTranscript: "NM_004056.6", parsimonySitesP: "0.137931034482759", constantSitesP: "0.771264367816092", distinctSitesP: "0.232183908045977", brLenTotal: "0.5404" },
  { geneTranscript: "NM_139320.2", parsimonySitesP: "0.189320388349515", constantSitesP: "0.690129449838188", distinctSitesP: "0.283980582524272", brLenTotal: "1.0901" },
  { geneTranscript: "NM_001102420.3", parsimonySitesP: "0.101721439749609", constantSitesP: "0.834115805946792", distinctSitesP: "0.151799687010955", brLenTotal: "0.2520" },
  { geneTranscript: "NM_007007.3", parsimonySitesP: "0.0895341802782819", constantSitesP: "0.844525105868119", distinctSitesP: "0.137931034482759", brLenTotal: "0.2857" },
  { geneTranscript: "NM_003093.3", parsimonySitesP: "0.121593291404612", constantSitesP: "0.649895178197065", distinctSitesP: "0.354297693920335", brLenTotal: "0.6063" },
  { geneTranscript: "NM_005859.5", parsimonySitesP: "0.0455486542443064", constantSitesP: "0.905797101449275", distinctSitesP: "0.082815734989648", brLenTotal: "0.1885" },
  { geneTranscript: "NM_001134225.2", parsimonySitesP: "0.139917695473251", constantSitesP: "0.777434842249657", distinctSitesP: "0.183813443072702", brLenTotal: "0.5637" },
  { geneTranscript: "NM_014706.4", parsimonySitesP: "0.213568708895812", constantSitesP: "0.66632052613361", distinctSitesP: "0.290411907234337", brLenTotal: "0.8069" },
  { geneTranscript: "NM_000898.5", parsimonySitesP: "0.207051282051282", constantSitesP: "0.676923076923077", distinctSitesP: "0.291025641025641", brLenTotal: "0.7277" },
  { geneTranscript: "NM_017772.4", parsimonySitesP: "0.126732673267327", constantSitesP: "0.794059405940594", distinctSitesP: "0.176897689768977", brLenTotal: "0.4077" },
  { geneTranscript: "NM_004135.4", parsimonySitesP: "0.205258693808312", constantSitesP: "0.694656488549618", distinctSitesP: "0.284139100932994", brLenTotal: "0.8237" },
  { geneTranscript: "NM_001134375.2", parsimonySitesP: "0.117493472584856", constantSitesP: "0.746736292428198", distinctSitesP: "0.190600522193211", brLenTotal: "0.4194" },
  { geneTranscript: "NM_080723.5", parsimonySitesP: "0.21025641025641", constantSitesP: "0.663247863247863", distinctSitesP: "0.292307692307692", brLenTotal: "1.0015" },
  { geneTranscript: "NM_001177676.2", parsimonySitesP: "0.198173515981735", constantSitesP: "0.622831050228311", distinctSitesP: "0.303196347031963", brLenTotal: "0.9590" },
  { geneTranscript: "NM_006019.4", parsimonySitesP: "0.257028112449799", constantSitesP: "0.613253012048193", distinctSitesP: "0.342570281124498", brLenTotal: "1.2596" },
  { geneTranscript: "NM_199320.4", parsimonySitesP: "0.156373713380839", constantSitesP: "0.697545526524149", distinctSitesP: "0.203087885985748", brLenTotal: "0.7874" },
  { geneTranscript: "NM_003462.5", parsimonySitesP: "0.200258397932817", constantSitesP: "0.718346253229974", distinctSitesP: "0.258397932816537", brLenTotal: "0.6606" },
  { geneTranscript: "NM_001039213.4", parsimonySitesP: "0.230588235294118", constantSitesP: "0.669019607843137", distinctSitesP: "0.286274509803922", brLenTotal: "0.9948" },
  { geneTranscript: "NM_024927.5", parsimonySitesP: "0.189995796553174", constantSitesP: "0.686002522068096", distinctSitesP: "0.251786464901219", brLenTotal: "0.7813" },
  { geneTranscript: "NM_033124.5", parsimonySitesP: "0.207300275482094", constantSitesP: "0.639118457300275", distinctSitesP: "0.294765840220386", brLenTotal: "0.6302" },
  { geneTranscript: "NM_000488.4", parsimonySitesP: "0.192528735632184", constantSitesP: "0.658764367816092", distinctSitesP: "0.280890804597701", brLenTotal: "0.7017" },
  { geneTranscript: "NM_198320.5", parsimonySitesP: "0.209932279909707", constantSitesP: "0.619262603461249", distinctSitesP: "0.357411587659895", brLenTotal: "0.8273" },
  { geneTranscript: "NM_001287385.2", parsimonySitesP: "0.138702460850112", constantSitesP: "0.738255033557047", distinctSitesP: "0.252796420581655", brLenTotal: "0.4270" },
  { geneTranscript: "NM_018916.4", parsimonySitesP: "0.414520743919886", constantSitesP: "0.450643776824034", distinctSitesP: "0.496065808297568", brLenTotal: "3.5251" },
  { geneTranscript: "NM_001839.5", parsimonySitesP: "0.144883485309017", constantSitesP: "0.709219858156028", distinctSitesP: "0.257345491388045", brLenTotal: "0.5948" },
  { geneTranscript: "NM_015089.4", parsimonySitesP: "0.21414382201033", constantSitesP: "0.653423387630777", distinctSitesP: "0.228976294530526", brLenTotal: "0.8488" },
  { geneTranscript: "NM_006841.6", parsimonySitesP: "0.208333333333333", constantSitesP: "0.68452380952381", distinctSitesP: "0.261243386243386", brLenTotal: "0.8308" },
  { geneTranscript: "NM_001355243.2", parsimonySitesP: "0.249158249158249", constantSitesP: "0.538720538720539", distinctSitesP: "0.417508417508418", brLenTotal: "0.9622" },
  { geneTranscript: "NM_139355.3", parsimonySitesP: "0.230111768573307", constantSitesP: "0.652859960552268", distinctSitesP: "0.307692307692308", brLenTotal: "1.1296" },
  { geneTranscript: "NM_001190737.2", parsimonySitesP: "0.0937921727395412", constantSitesP: "0.809716599190283", distinctSitesP: "0.120782726045884", brLenTotal: "0.3524" },
  { geneTranscript: "NM_001130528.3", parsimonySitesP: "0.126167045167802", constantSitesP: "0.767347968710573", distinctSitesP: "0.165278829169821", brLenTotal: "0.4250" },
  { geneTranscript: "NM_020447.5", parsimonySitesP: "0.237373737373737", constantSitesP: "0.612794612794613", distinctSitesP: "0.368686868686869", brLenTotal: "0.8744" },
  { geneTranscript: "NM_031431.4", parsimonySitesP: "0.144927536231884", constantSitesP: "0.750402576489533", distinctSitesP: "0.219404186795491", brLenTotal: "0.4912" },
  { geneTranscript: "NM_004594.3", parsimonySitesP: "0.154017857142857", constantSitesP: "0.754092261904762", distinctSitesP: "0.191964285714286", brLenTotal: "0.5232" },
  { geneTranscript: "NM_018043.7", parsimonySitesP: "0.195402298850575", constantSitesP: "0.701825557809331", distinctSitesP: "0.27315753887762", brLenTotal: "0.9304" },
  { geneTranscript: "NM_080752.4", parsimonySitesP: "0.224137931034483", constantSitesP: "0.613505747126437", distinctSitesP: "0.318007662835249", brLenTotal: "0.7817" },
  { geneTranscript: "NM_001350770.2", parsimonySitesP: "0.244663382594417", constantSitesP: "0.548440065681445", distinctSitesP: "0.430213464696223", brLenTotal: "0.9508" },
  { geneTranscript: "NM_004047.5", parsimonySitesP: "0.120325203252033", constantSitesP: "0.791869918699187", distinctSitesP: "0.198373983739837", brLenTotal: "0.4368" },
  { geneTranscript: "NM_003738.5", parsimonySitesP: "0.210030479357163", constantSitesP: "0.643668606262122", distinctSitesP: "0.266555832640621", brLenTotal: "0.7733" },
  { geneTranscript: "NM_016006.6", parsimonySitesP: "0.157593123209169", constantSitesP: "0.713467048710602", distinctSitesP: "0.249283667621777", brLenTotal: "0.5599" },
  { geneTranscript: "NM_001017923.2", parsimonySitesP: "0.104301075268817", constantSitesP: "0.804301075268817", distinctSitesP: "0.165591397849462", brLenTotal: "0.3044" },
  { geneTranscript: "NM_001256441.2", parsimonySitesP: "0.185281385281385", constantSitesP: "0.693506493506494", distinctSitesP: "0.266666666666667", brLenTotal: "0.7550" },
  { geneTranscript: "NM_005072.5", parsimonySitesP: "0.215975422427035", constantSitesP: "0.688479262672811", distinctSitesP: "0.255606758832565", brLenTotal: "0.9480" },
  { geneTranscript: "NM_001096.3", parsimonySitesP: "0.151680290644868", constantSitesP: "0.759006963366636", distinctSitesP: "0.187102633969119", brLenTotal: "0.6198" },
  { geneTranscript: "NM_198505.4", parsimonySitesP: "0.24384236453202", constantSitesP: "0.594690749863164", distinctSitesP: "0.354132457580733", brLenTotal: "0.8623" },
  { geneTranscript: "NM_178311.3", parsimonySitesP: "0.285925925925926", constantSitesP: "0.592592592592593", distinctSitesP: "0.385185185185185", brLenTotal: "1.4083" },
  { geneTranscript: "NM_020410.3", parsimonySitesP: "0.196290143964563", constantSitesP: "0.686323366555925", distinctSitesP: "0.247231450719823", brLenTotal: "0.8848" },
  { geneTranscript: "NM_004878.5", parsimonySitesP: "0.232456140350877", constantSitesP: "0.625", distinctSitesP: "0.37280701754386", brLenTotal: "0.9664" },
  { geneTranscript: "NM_006923.4", parsimonySitesP: "0.183254344391785", constantSitesP: "0.695102685624013", distinctSitesP: "0.28436018957346", brLenTotal: "0.6007" },
  { geneTranscript: "NM_001382323.2", parsimonySitesP: "0.0988700564971751", constantSitesP: "0.778954802259887", distinctSitesP: "0.153248587570621", brLenTotal: "0.5526" },
  { geneTranscript: "NM_005861.4", parsimonySitesP: "0.183718371837184", constantSitesP: "0.722772277227723", distinctSitesP: "0.255225522552255", brLenTotal: "0.7299" },
  { geneTranscript: "NM_006087.4", parsimonySitesP: "0.247747747747748", constantSitesP: "0.671171171171171", distinctSitesP: "0.316066066066066", brLenTotal: "1.9787" },
  { geneTranscript: "NM_018471.3", parsimonySitesP: "0.117370892018779", constantSitesP: "0.755868544600939", distinctSitesP: "0.214397496087637", brLenTotal: "0.4388" },
  { geneTranscript: "NM_022552.5", parsimonySitesP: "0.127923976608187", constantSitesP: "0.786549707602339", distinctSitesP: "0.169590643274854", brLenTotal: "0.4645" },
  { geneTranscript: "NM_014938.6", parsimonySitesP: "0.229597388465724", constantSitesP: "0.651432716721074", distinctSitesP: "0.283641639463185", brLenTotal: "0.9220" },
  { geneTranscript: "NM_139166.5", parsimonySitesP: "0.257217847769029", constantSitesP: "0.57917760279965", distinctSitesP: "0.363079615048119", brLenTotal: "1.0090" },
  { geneTranscript: "NM_012261.4", parsimonySitesP: "0.170238095238095", constantSitesP: "0.694047619047619", distinctSitesP: "0.285714285714286", brLenTotal: "0.6254" },
  { geneTranscript: "NM_000166.6", parsimonySitesP: "0.141342756183746", constantSitesP: "0.779740871613663", distinctSitesP: "0.206124852767962", brLenTotal: "0.4905" },
  { geneTranscript: "NM_021947.3", parsimonySitesP: "0.186274509803922", constantSitesP: "0.688235294117647", distinctSitesP: "0.299019607843137", brLenTotal: "0.5798" },
  { geneTranscript: "NM_033068.3", parsimonySitesP: "0.268388106416275", constantSitesP: "0.588419405320814", distinctSitesP: "0.346635367762128", brLenTotal: "1.2021" },
  { geneTranscript: "NM_138995.5", parsimonySitesP: "0.169525229927914", constantSitesP: "0.696992294307731", distinctSitesP: "0.251553566989809", brLenTotal: "0.6063" },
  { geneTranscript: "NM_000386.4", parsimonySitesP: "0.13992673992674", constantSitesP: "0.755311355311355", distinctSitesP: "0.208791208791209", brLenTotal: "0.4587" },
  { geneTranscript: "NM_203411.2", parsimonySitesP: "0.207547169811321", constantSitesP: "0.675052410901468", distinctSitesP: "0.29769392033543", brLenTotal: "0.7368" },
  { geneTranscript: "NM_138962.4", parsimonySitesP: "0.0609756097560976", constantSitesP: "0.905487804878049", distinctSitesP: "0.106707317073171", brLenTotal: "0.2201" },
  { geneTranscript: "NM_203301.4", parsimonySitesP: "0.136936936936937", constantSitesP: "0.759159159159159", distinctSitesP: "0.201801801801802", brLenTotal: "0.4050" },
  { geneTranscript: "NM_005215.4", parsimonySitesP: "0.152960147431467", constantSitesP: "0.745219995392767", distinctSitesP: "0.194194885970974", brLenTotal: "0.5654" },
  { geneTranscript: "NM_001077498.3", parsimonySitesP: "0.0812574139976275", constantSitesP: "0.802491103202847", distinctSitesP: "0.147686832740214", brLenTotal: "0.3291" },
  { geneTranscript: "NM_018198.4", parsimonySitesP: "0.151460942158617", constantSitesP: "0.773404889683959", distinctSitesP: "0.20989862850328", brLenTotal: "0.5342" },
  { geneTranscript: "NM_181711.4", parsimonySitesP: "0.221097046413502", constantSitesP: "0.660759493670886", distinctSitesP: "0.321518987341772", brLenTotal: "0.8872" },
  { geneTranscript: "NM_001142730.3", parsimonySitesP: "0.114450867052023", constantSitesP: "0.799229287090559", distinctSitesP: "0.16223506743738", brLenTotal: "0.4398" },
  { geneTranscript: "NM_017799.4", parsimonySitesP: "0.17963224893918", constantSitesP: "0.69024045261669", distinctSitesP: "0.288543140028289", brLenTotal: "0.6540" },
  { geneTranscript: "NM_007186.6", parsimonySitesP: "0.243652743652744", constantSitesP: "0.58968058968059", distinctSitesP: "0.265629265629266", brLenTotal: "0.8308" },
  { geneTranscript: "NM_014168.4", parsimonySitesP: "0.135566188197767", constantSitesP: "0.740031897926635", distinctSitesP: "0.274322169059011", brLenTotal: "0.4419" },
  { geneTranscript: "NM_021994.3", parsimonySitesP: "0.145185185185185", constantSitesP: "0.704444444444444", distinctSitesP: "0.284444444444444", brLenTotal: "0.5141" },
  { geneTranscript: "NM_005655.4", parsimonySitesP: "0.177083333333333", constantSitesP: "0.699305555555556", distinctSitesP: "0.25", brLenTotal: "0.5857" },
  { geneTranscript: "NM_001999.4", parsimonySitesP: "0.188415750915751", constantSitesP: "0.713255494505495", distinctSitesP: "0.211996336996337", brLenTotal: "0.7420" },
  { geneTranscript: "NM_001353921.2", parsimonySitesP: "0.140854047163799", constantSitesP: "0.80050987890376", distinctSitesP: "0.184193753983429", brLenTotal: "0.5006" },
  { geneTranscript: "NM_015395.3", parsimonySitesP: "0.243490701001431", constantSitesP: "0.64549356223176", distinctSitesP: "0.286123032904149", brLenTotal: "1.2176" },
  { geneTranscript: "NM_001142749.3", parsimonySitesP: "0.137674117265954", constantSitesP: "0.745383867832847", distinctSitesP: "0.208292840945902", brLenTotal: "0.4892" },
  { geneTranscript: "NM_021033.7", parsimonySitesP: "0.145719489981785", constantSitesP: "0.704918032786885", distinctSitesP: "0.282331511839709", brLenTotal: "0.6742" },
  { geneTranscript: "NM_014026.6", parsimonySitesP: "0.271018793273986", constantSitesP: "0.578635014836795", distinctSitesP: "0.394658753709199", brLenTotal: "1.1874" },
  { geneTranscript: "NM_003905.4", parsimonySitesP: "0.116104868913858", constantSitesP: "0.790886392009988", distinctSitesP: "0.188514357053683", brLenTotal: "0.3565" },
  { geneTranscript: "NM_001128.6", parsimonySitesP: "0.0758313057583131", constantSitesP: "0.846309813463098", distinctSitesP: "0.128548256285483", brLenTotal: "0.2505" },
  { geneTranscript: "NM_014051.4", parsimonySitesP: "0.154882154882155", constantSitesP: "0.730639730639731", distinctSitesP: "0.292929292929293", brLenTotal: "0.4719" },
  { geneTranscript: "NM_001242835.2", parsimonySitesP: "0.127840909090909", constantSitesP: "0.783143939393939", distinctSitesP: "0.212121212121212", brLenTotal: "0.6923" },
  { geneTranscript: "NM_144964.4", parsimonySitesP: "0.20042194092827", constantSitesP: "0.663502109704641", distinctSitesP: "0.318565400843882", brLenTotal: "0.6335" },
  { geneTranscript: "NM_014149.4", parsimonySitesP: "0.176260597947345", constantSitesP: "0.710397144132084", distinctSitesP: "0.225792057117358", brLenTotal: "0.7275" },
  { geneTranscript: "NM_006873.4", parsimonySitesP: "0.234013605442177", constantSitesP: "0.60453514739229", distinctSitesP: "0.307936507936508", brLenTotal: "0.7673" },
  { geneTranscript: "NM_020457.3", parsimonySitesP: "0.162420382165605", constantSitesP: "0.705944798301486", distinctSitesP: "0.281316348195329", brLenTotal: "0.8516" },
  { geneTranscript: "NM_000444.6", parsimonySitesP: "0.123275478415665", constantSitesP: "0.778371161548732", distinctSitesP: "0.179350244770806", brLenTotal: "0.3911" },
  { geneTranscript: "NM_024779.5", parsimonySitesP: "0.117181314330958", constantSitesP: "0.761678543151227", distinctSitesP: "0.192399049881235", brLenTotal: "0.4275" },
  { geneTranscript: "NM_015215.4", parsimonySitesP: "0.147638971906754", constantSitesP: "0.771667662881052", distinctSitesP: "0.180912532376968", brLenTotal: "0.6174" },
  { geneTranscript: "NM_198560.3", parsimonySitesP: "0.179487179487179", constantSitesP: "0.703103913630229", distinctSitesP: "0.186234817813765", brLenTotal: "0.5583" },
  { geneTranscript: "NM_019045.5", parsimonySitesP: "0.137276378240234", constantSitesP: "0.75684556407448", distinctSitesP: "0.202263599853961", brLenTotal: "0.4334" },
  { geneTranscript: "NM_019558.4", parsimonySitesP: "0.113793103448276", constantSitesP: "0.8", distinctSitesP: "0.236781609195402", brLenTotal: "0.3371" },
  { geneTranscript: "NM_000387.6", parsimonySitesP: "0.170542635658915", constantSitesP: "0.710963455149502", distinctSitesP: "0.255813953488372", brLenTotal: "0.5325" },
  { geneTranscript: "NM_006815.4", parsimonySitesP: "0.119402985074627", constantSitesP: "0.794361525704809", distinctSitesP: "0.17910447761194", brLenTotal: "0.4176" },
  { geneTranscript: "NM_018998.4", parsimonySitesP: "0.241460541813899", constantSitesP: "0.633097762073027", distinctSitesP: "0.319199057714959", brLenTotal: "1.3177" },
  { geneTranscript: "NM_000211.5", parsimonySitesP: "0.284351972258344", constantSitesP: "0.574772431729519", distinctSitesP: "0.400953619419159", brLenTotal: "1.4605" },
  { geneTranscript: "NM_212556.4", parsimonySitesP: "0.270386266094421", constantSitesP: "0.57725321888412", distinctSitesP: "0.402002861230329", brLenTotal: "1.0141" },
  { geneTranscript: "NM_002862.4", parsimonySitesP: "0.2143139580862", constantSitesP: "0.669434559114274", distinctSitesP: "0.282720442862792", brLenTotal: "1.2118" },
  { geneTranscript: "NM_015577.3", parsimonySitesP: "0.181292517006803", constantSitesP: "0.698639455782313", distinctSitesP: "0.233333333333333", brLenTotal: "0.6058" },
  { geneTranscript: "NM_001166293.2", parsimonySitesP: "0.154180238870793", constantSitesP: "0.736156351791531", distinctSitesP: "0.223669923995657", brLenTotal: "0.5257" },
  { geneTranscript: "NM_000271.5", parsimonySitesP: "0.213354199269692", constantSitesP: "0.662493479394888", distinctSitesP: "0.318466353677621", brLenTotal: "0.8575" },
  { geneTranscript: "NM_014089.4", parsimonySitesP: "0.117974401780746", constantSitesP: "0.766833611574847", distinctSitesP: "0.185308848080134", brLenTotal: "0.4317" },
  { geneTranscript: "NM_005197.4", parsimonySitesP: "0.128205128205128", constantSitesP: "0.715099715099715", distinctSitesP: "0.211538461538462", brLenTotal: "0.7245" },
  { geneTranscript: "NM_001789.3", parsimonySitesP: "0.19147582697201", constantSitesP: "0.684478371501272", distinctSitesP: "0.309796437659033", brLenTotal: "0.6020" },
  { geneTranscript: "NM_012062.5", parsimonySitesP: "0.108242753623188", constantSitesP: "0.816123188405797", distinctSitesP: "0.165307971014493", brLenTotal: "0.3300" },
  { geneTranscript: "NM_182647.4", parsimonySitesP: "0.201801801801802", constantSitesP: "0.668468468468468", distinctSitesP: "0.274774774774775", brLenTotal: "1.2989" },
  { geneTranscript: "NM_020987.5", parsimonySitesP: "0.138450993831391", constantSitesP: "0.763688980275683", distinctSitesP: "0.151245145076536", brLenTotal: "0.4954" },
  { geneTranscript: "NM_003613.4", parsimonySitesP: "0.233108108108108", constantSitesP: "0.639358108108108", distinctSitesP: "0.292792792792793", brLenTotal: "0.8256" },
  { geneTranscript: "NM_032320.7", parsimonySitesP: "0.157894736842105", constantSitesP: "0.713684210526316", distinctSitesP: "0.235087719298246", brLenTotal: "0.4907" },
  { geneTranscript: "NM_030652.4", parsimonySitesP: "0.241183162684869", constantSitesP: "0.597269624573379", distinctSitesP: "0.362912400455063", brLenTotal: "0.8307" },
  { geneTranscript: "NM_018691.4", parsimonySitesP: "0.196699669966997", constantSitesP: "0.615841584158416", distinctSitesP: "0.358415841584158", brLenTotal: "0.7269" },
  { geneTranscript: "NM_001146156.2", parsimonySitesP: "0.0746031746031746", constantSitesP: "0.855555555555556", distinctSitesP: "0.127777777777778", brLenTotal: "0.2293" },
  { geneTranscript: "NM_004881.5", parsimonySitesP: "0.215863453815261", constantSitesP: "0.646586345381526", distinctSitesP: "0.320281124497992", brLenTotal: "0.8112" },
  { geneTranscript: "NM_003711.4", parsimonySitesP: "0.115023474178404", constantSitesP: "0.796948356807512", distinctSitesP: "0.204225352112676", brLenTotal: "0.3792" },
  { geneTranscript: "NM_153460.4", parsimonySitesP: "0.295833333333333", constantSitesP: "0.536111111111111", distinctSitesP: "0.426388888888889", brLenTotal: "1.0680" },
  { geneTranscript: "NM_012157.5", parsimonySitesP: "0.123719464144996", constantSitesP: "0.787234042553191", distinctSitesP: "0.185185185185185", brLenTotal: "0.4698" },
  { geneTranscript: "NM_015193.5", parsimonySitesP: "0.182659932659933", constantSitesP: "0.712962962962963", distinctSitesP: "0.25", brLenTotal: "0.9829" },
  { geneTranscript: "NM_005331.5", parsimonySitesP: "0.359154929577465", constantSitesP: "0.476525821596244", distinctSitesP: "0.490610328638498", brLenTotal: "1.3174" },
  { geneTranscript: "NM_005563.4", parsimonySitesP: "0.192393736017897", constantSitesP: "0.70917225950783", distinctSitesP: "0.29082774049217", brLenTotal: "0.8797" },
  { geneTranscript: "NM_002968.3", parsimonySitesP: "0.168177240684794", constantSitesP: "0.677995971802618", distinctSitesP: "0.210725075528701", brLenTotal: "0.8116" },
  { geneTranscript: "NM_006529.4", parsimonySitesP: "0.171695402298851", constantSitesP: "0.732758620689655", distinctSitesP: "0.23132183908046", brLenTotal: "0.6306" },
  { geneTranscript: "NM_016206.4", parsimonySitesP: "0.160531697341513", constantSitesP: "0.730061349693252", distinctSitesP: "0.265848670756646", brLenTotal: "0.5102" },
  { geneTranscript: "NM_023039.5", parsimonySitesP: "0.119275825346113", constantSitesP: "0.796592119275825", distinctSitesP: "0.198083067092652", brLenTotal: "0.3641" },
  { geneTranscript: "NM_002184.4", parsimonySitesP: "0.185911401597676", constantSitesP: "0.685911401597676", distinctSitesP: "0.284313725490196", brLenTotal: "0.6144" },
  { geneTranscript: "NM_207371.4", parsimonySitesP: "0.113069016152717", constantSitesP: "0.785242290748899", distinctSitesP: "0.197136563876652", brLenTotal: "0.4041" },
  { geneTranscript: "NM_014370.4", parsimonySitesP: "0.227513227513228", constantSitesP: "0.674309229864785", distinctSitesP: "0.296884185773075", brLenTotal: "1.0034" },
  { geneTranscript: "NM_001201552.2", parsimonySitesP: "0.122168284789644", constantSitesP: "0.779935275080906", distinctSitesP: "0.1957928802589", brLenTotal: "0.4099" },
  { geneTranscript: "NM_016818.3", parsimonySitesP: "0.217717717717718", constantSitesP: "0.701701701701702", distinctSitesP: "0.270770770770771", brLenTotal: "1.1439" },
  { geneTranscript: "NM_005433.4", parsimonySitesP: "0.162676488643339", constantSitesP: "0.724370779619398", distinctSitesP: "0.233885819521179", brLenTotal: "0.6256" },
  { geneTranscript: "NM_015463.3", parsimonySitesP: "0.136178861788618", constantSitesP: "0.786585365853659", distinctSitesP: "0.213414634146341", brLenTotal: "0.4101" },
  { geneTranscript: "NM_018717.5", parsimonySitesP: "0.180433509080258", constantSitesP: "0.693321616871705", distinctSitesP: "0.255418863503222", brLenTotal: "0.7841" },
  { geneTranscript: "NM_001258217.2", parsimonySitesP: "0.160975609756098", constantSitesP: "0.679674796747967", distinctSitesP: "0.291056910569106", brLenTotal: "0.5384" },
  { geneTranscript: "NM_004265.4", parsimonySitesP: "0.207957957957958", constantSitesP: "0.680930930930931", distinctSitesP: "0.274774774774775", brLenTotal: "0.8499" },
  { geneTranscript: "NM_003110.6", parsimonySitesP: "0.151169113648722", constantSitesP: "0.742794997281131", distinctSitesP: "0.2131593257205", brLenTotal: "0.6261" },
  { geneTranscript: "NM_014007.4", parsimonySitesP: "0.142755174875089", constantSitesP: "0.785867237687366", distinctSitesP: "0.18344039971449", brLenTotal: "0.5570" },
  { geneTranscript: "NM_002474.3", parsimonySitesP: "0.196923597025017", constantSitesP: "0.7131507775524", distinctSitesP: "0.227011494252874", brLenTotal: "0.9204" },
  { geneTranscript: "NM_000213.5", parsimonySitesP: "0.212221002561288", constantSitesP: "0.677094767654592", distinctSitesP: "0.251372118551043", brLenTotal: "0.9426" },
  { geneTranscript: "NM_001350134.2", parsimonySitesP: "0.142139479905437", constantSitesP: "0.732860520094563", distinctSitesP: "0.212765957446809", brLenTotal: "0.4443" },
  { geneTranscript: "NM_006168.3", parsimonySitesP: "0.220708446866485", constantSitesP: "0.67574931880109", distinctSitesP: "0.291553133514986", brLenTotal: "0.8898" },
  { geneTranscript: "NM_007236.5", parsimonySitesP: "0.112820512820513", constantSitesP: "0.781196581196581", distinctSitesP: "0.18974358974359", brLenTotal: "0.3686" },
  { geneTranscript: "NM_032970.4", parsimonySitesP: "0.193619361936194", constantSitesP: "0.665566556655666", distinctSitesP: "0.294829482948295", brLenTotal: "0.6686" },
  { geneTranscript: "NM_024663.4", parsimonySitesP: "0.191204588910134", constantSitesP: "0.706819630337795", distinctSitesP: "0.251752708731676", brLenTotal: "1.0465" },
  { geneTranscript: "NM_001953.5", parsimonySitesP: "0.283540802213001", constantSitesP: "0.576071922544952", distinctSitesP: "0.37551867219917", brLenTotal: "1.0366" },
  { geneTranscript: "NM_032508.4", parsimonySitesP: "0.15047619047619", constantSitesP: "0.760952380952381", distinctSitesP: "0.21047619047619", brLenTotal: "0.5924" },
  { geneTranscript: "NM_001007540.4", parsimonySitesP: "0.311336717428088", constantSitesP: "0.491962774957699", distinctSitesP: "0.444585448392555", brLenTotal: "1.0652" },
  { geneTranscript: "NM_001975.3", parsimonySitesP: "0.146697388632873", constantSitesP: "0.748847926267281", distinctSitesP: "0.196620583717358", brLenTotal: "0.5812" },
  { geneTranscript: "NM_004078.3", parsimonySitesP: "0.195164075993092", constantSitesP: "0.7426597582038", distinctSitesP: "0.24006908462867", brLenTotal: "0.7887" },
  { geneTranscript: "NM_001220.5", parsimonySitesP: "0.141641641641642", constantSitesP: "0.771771771771772", distinctSitesP: "0.183683683683684", brLenTotal: "0.7410" },
  { geneTranscript: "NM_144699.4", parsimonySitesP: "0.311305474570781", constantSitesP: "0.566893424036281", distinctSitesP: "0.378036929057337", brLenTotal: "1.5934" },
  { geneTranscript: "NM_153350.4", parsimonySitesP: "0.171189979123173", constantSitesP: "0.745302713987474", distinctSitesP: "0.220598469032707", brLenTotal: "0.7881" },
  { geneTranscript: "NM_014450.3", parsimonySitesP: "0.295918367346939", constantSitesP: "0.540816326530612", distinctSitesP: "0.44047619047619", brLenTotal: "0.9389" },
  { geneTranscript: "NM_003586.3", parsimonySitesP: "0.185", constantSitesP: "0.719166666666667", distinctSitesP: "0.259166666666667", brLenTotal: "0.8254" },
  { geneTranscript: "NM_005864.4", parsimonySitesP: "0.262032085561497", constantSitesP: "0.578728461081402", distinctSitesP: "0.369578134284017", brLenTotal: "0.9867" },
  { geneTranscript: "NM_005271.5", parsimonySitesP: "0.195340501792115", constantSitesP: "0.610513739545998", distinctSitesP: "0.348267622461171", brLenTotal: "0.8306" },
  { geneTranscript: "NM_152744.4", parsimonySitesP: "0.221720138575087", constantSitesP: "0.672239795149872", distinctSitesP: "0.278053923783702", brLenTotal: "1.0599" },
  { geneTranscript: "NM_018263.6", parsimonySitesP: "0.177932636469222", constantSitesP: "0.670383275261324", distinctSitesP: "0.250406504065041", brLenTotal: "0.6083" },
  { geneTranscript: "NM_002961.3", parsimonySitesP: "0.231023102310231", constantSitesP: "0.646864686468647", distinctSitesP: "0.356435643564356", brLenTotal: "0.7709" },
  { geneTranscript: "NM_015987.5", parsimonySitesP: "0.236331569664903", constantSitesP: "0.585537918871252", distinctSitesP: "0.3668430335097", brLenTotal: "0.9266" },
  { geneTranscript: "NM_001384253.1", parsimonySitesP: "0.115460656385923", constantSitesP: "0.803084223013049", distinctSitesP: "0.159746935547647", brLenTotal: "0.3583" },
  { geneTranscript: "NM_001005471.2", parsimonySitesP: "0.492424242424242", constantSitesP: "0.346320346320346", distinctSitesP: "0.637445887445887", brLenTotal: "3.2317" },
  { geneTranscript: "NM_002572.4", parsimonySitesP: "0.112081513828239", constantSitesP: "0.797671033478894", distinctSitesP: "0.181950509461426", brLenTotal: "0.4005" },
  { geneTranscript: "NM_001145347.2", parsimonySitesP: "0.223529411764706", constantSitesP: "0.627450980392157", distinctSitesP: "0.349019607843137", brLenTotal: "0.7412" },
  { geneTranscript: "NM_207312.3", parsimonySitesP: "0.316296296296296", constantSitesP: "0.531851851851852", distinctSitesP: "0.44", brLenTotal: "2.4572" },
  { geneTranscript: "NM_000263.4", parsimonySitesP: "0.263346792283535", constantSitesP: "0.575145805293854", distinctSitesP: "0.377299237326155", brLenTotal: "1.1370" },
  { geneTranscript: "NM_006310.4", parsimonySitesP: "0.0779833151976786", constantSitesP: "0.846572361262242", distinctSitesP: "0.121508886470802", brLenTotal: "0.2431" },
  { geneTranscript: "NM_001014286.3", parsimonySitesP: "0.115960633290543", constantSitesP: "0.794608472400513", distinctSitesP: "0.176294394522893", brLenTotal: "0.3663" },
  { geneTranscript: "NM_012269.3", parsimonySitesP: "0.229383229383229", constantSitesP: "0.609147609147609", distinctSitesP: "0.362439362439362", brLenTotal: "0.8288" },
  { geneTranscript: "NM_001037329.4", parsimonySitesP: "0.217971014492754", constantSitesP: "0.649275362318841", distinctSitesP: "0.300289855072464", brLenTotal: "0.7998" },
  { geneTranscript: "NM_000693.4", parsimonySitesP: "0.212890625", constantSitesP: "0.6796875", distinctSitesP: "0.266276041666667", brLenTotal: "0.9483" },
  { geneTranscript: "NM_003470.3", parsimonySitesP: "0.126134301270417", constantSitesP: "0.79643073200242", distinctSitesP: "0.172413793103448", brLenTotal: "0.4401" },
  { geneTranscript: "NM_001950.4", parsimonySitesP: "0.171912832929782", constantSitesP: "0.720742534301856", distinctSitesP: "0.263115415657789", brLenTotal: "0.5612" },
  { geneTranscript: "NM_001080472.4", parsimonySitesP: "0.212468193384224", constantSitesP: "0.673027989821883", distinctSitesP: "0.301526717557252", brLenTotal: "0.7667" },
  { geneTranscript: "NM_012113.3", parsimonySitesP: "0.193867457962413", constantSitesP: "0.638971315529179", distinctSitesP: "0.333333333333333", brLenTotal: "0.7406" },
  { geneTranscript: "NM_000831.4", parsimonySitesP: "0.147624229234675", constantSitesP: "0.757344940152339", distinctSitesP: "0.193688792165397", brLenTotal: "0.7376" },
  { geneTranscript: "NM_002537.3", parsimonySitesP: "0.103125", constantSitesP: "0.8015625", distinctSitesP: "0.1921875", brLenTotal: "0.4031" },
  { geneTranscript: "NM_001291815.2", parsimonySitesP: "0.296383802585811", constantSitesP: "0.547745619216381", distinctSitesP: "0.31955109273479", brLenTotal: "1.3685" },
  { geneTranscript: "NM_152421.4", parsimonySitesP: "0.224284609435422", constantSitesP: "0.645784996133024", distinctSitesP: "0.31399845320959", brLenTotal: "1.1827" },
  { geneTranscript: "NM_018696.3", parsimonySitesP: "0.160697887970615", constantSitesP: "0.722681359044995", distinctSitesP: "0.238751147842057", brLenTotal: "0.5744" },
  { geneTranscript: "NM_016580.4", parsimonySitesP: "0.248029279279279", constantSitesP: "0.599943693693694", distinctSitesP: "0.289695945945946", brLenTotal: "0.9577" },
  { geneTranscript: "NM_178500.4", parsimonySitesP: "0.188514357053683", constantSitesP: "0.717852684144819", distinctSitesP: "0.263420724094881", brLenTotal: "0.7017" },
  { geneTranscript: "NM_024818.6", parsimonySitesP: "0.138613861386139", constantSitesP: "0.741749174917492", distinctSitesP: "0.240924092409241", brLenTotal: "0.4720" },
  { geneTranscript: "NM_001005238.2", parsimonySitesP: "0.404458598726115", constantSitesP: "0.393842887473461", distinctSitesP: "0.538216560509554", brLenTotal: "1.9167" },
  { geneTranscript: "NM_020361.5", parsimonySitesP: "0.177726926010679", constantSitesP: "0.708619374523265", distinctSitesP: "0.27536231884058", brLenTotal: "0.6029" },
  { geneTranscript: "NM_020448.5", parsimonySitesP: "0.183908045977011", constantSitesP: "0.720032840722496", distinctSitesP: "0.261083743842365", brLenTotal: "0.7035" },
  { geneTranscript: "NM_152293.3", parsimonySitesP: "0.185714285714286", constantSitesP: "0.711904761904762", distinctSitesP: "0.226190476190476", brLenTotal: "0.9287" },
  { geneTranscript: "NM_016433.4", parsimonySitesP: "0.151515151515152", constantSitesP: "0.736842105263158", distinctSitesP: "0.244019138755981", brLenTotal: "0.7219" },
  { geneTranscript: "NM_017841.4", parsimonySitesP: "0.172690763052209", constantSitesP: "0.692771084337349", distinctSitesP: "0.309236947791165", brLenTotal: "0.6007" },
  { geneTranscript: "NM_003952.3", parsimonySitesP: "0.190179806362379", constantSitesP: "0.693637621023513", distinctSitesP: "0.267634854771784", brLenTotal: "0.7392" },
  { geneTranscript: "NM_003862.3", parsimonySitesP: "0.101449275362319", constantSitesP: "0.821256038647343", distinctSitesP: "0.170692431561997", brLenTotal: "0.3623" },
  { geneTranscript: "NM_022131.3", parsimonySitesP: "0.177661431064572", constantSitesP: "0.717277486910995", distinctSitesP: "0.225828970331588", brLenTotal: "0.7796" },
  { geneTranscript: "NM_000284.4", parsimonySitesP: "0.18034188034188", constantSitesP: "0.692307692307692", distinctSitesP: "0.279487179487179", brLenTotal: "0.6864" },
  { geneTranscript: "NM_001082.5", parsimonySitesP: "0.371794871794872", constantSitesP: "0.433974358974359", distinctSitesP: "0.514102564102564", brLenTotal: "2.5936" },
  { geneTranscript: "NM_032797.6", parsimonySitesP: "0.216264521894549", constantSitesP: "0.659517426273458", distinctSitesP: "0.322609472743521", brLenTotal: "0.8730" },
  { geneTranscript: "NM_145805.3", parsimonySitesP: "0.157845868152275", constantSitesP: "0.759517177344475", distinctSitesP: "0.225626740947075", brLenTotal: "0.6107" },
  { geneTranscript: "NM_016573.4", parsimonySitesP: "0.238831615120275", constantSitesP: "0.605498281786942", distinctSitesP: "0.35979381443299", brLenTotal: "1.0092" },
  { geneTranscript: "NM_021146.4", parsimonySitesP: "0.180154142581888", constantSitesP: "0.708092485549133", distinctSitesP: "0.24373795761079", brLenTotal: "0.6619" },
  { geneTranscript: "NM_000618.5", parsimonySitesP: "0.156862745098039", constantSitesP: "0.773420479302832", distinctSitesP: "0.215686274509804", brLenTotal: "0.5553" },
  { geneTranscript: "NM_003227.4", parsimonySitesP: "0.246774864752393", constantSitesP: "0.628381190178943", distinctSitesP: "0.313358302122347", brLenTotal: "0.9228" },
  { geneTranscript: "NM_001760.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.732876712328767", distinctSitesP: "0.234018264840183", brLenTotal: "0.5431" },
  { geneTranscript: "NM_177559.3", parsimonySitesP: "0.0861040068201194", constantSitesP: "0.832054560954817", distinctSitesP: "0.137254901960784", brLenTotal: "0.2760" },
  { geneTranscript: "NM_007213.3", parsimonySitesP: "0.185393258426966", constantSitesP: "0.685393258426966", distinctSitesP: "0.282771535580524", brLenTotal: "0.6552" },
  { geneTranscript: "NM_002831.6", parsimonySitesP: "0.174789915966387", constantSitesP: "0.725490196078431", distinctSitesP: "0.233053221288515", brLenTotal: "0.7305" },
  { geneTranscript: "NM_001080379.2", parsimonySitesP: "0.133592736705577", constantSitesP: "0.767833981841764", distinctSitesP: "0.214007782101167", brLenTotal: "0.4685" },
  { geneTranscript: "NM_006375.4", parsimonySitesP: "0.120481927710843", constantSitesP: "0.795180722891566", distinctSitesP: "0.173264486517499", brLenTotal: "0.3866" },
  { geneTranscript: "NM_001308120.2", parsimonySitesP: "0.18556119571348", constantSitesP: "0.656702387666855", distinctSitesP: "0.263207369806355", brLenTotal: "0.5947" },
  { geneTranscript: "NM_001722.3", parsimonySitesP: "0.1892797319933", constantSitesP: "0.692629815745394", distinctSitesP: "0.277219430485762", brLenTotal: "0.7507" },
  { geneTranscript: "NM_006858.4", parsimonySitesP: "0.2143906020558", constantSitesP: "0.693098384728341", distinctSitesP: "0.284875183553598", brLenTotal: "0.8343" },
  { geneTranscript: "NM_138576.4", parsimonySitesP: "0.107755406413125", constantSitesP: "0.738627889634601", distinctSitesP: "0.156226696495153", brLenTotal: "0.7235" },
  { geneTranscript: "NM_001868.4", parsimonySitesP: "0.26491646778043", constantSitesP: "0.58472553699284", distinctSitesP: "0.39538583929992", brLenTotal: "1.1449" },
  { geneTranscript: "NM_003190.5", parsimonySitesP: "0.270089285714286", constantSitesP: "0.569196428571429", distinctSitesP: "0.372767857142857", brLenTotal: "0.9946" },
  { geneTranscript: "NM_001098834.3", parsimonySitesP: "0.171717171717172", constantSitesP: "0.737373737373737", distinctSitesP: "0.248852157943067", brLenTotal: "0.5600" },
  { geneTranscript: "NM_139246.5", parsimonySitesP: "0.199612403100775", constantSitesP: "0.650516795865633", distinctSitesP: "0.343023255813953", brLenTotal: "0.6897" },
  { geneTranscript: "NM_001077418.3", parsimonySitesP: "0.238396624472574", constantSitesP: "0.590717299578059", distinctSitesP: "0.426160337552743", brLenTotal: "0.9412" },
  { geneTranscript: "NM_001001956.1", parsimonySitesP: "0.492662473794549", constantSitesP: "0.40461215932914", distinctSitesP: "0.59748427672956", brLenTotal: "4.4034" },
  { geneTranscript: "NM_000093.5", parsimonySitesP: "0.200943054044251", constantSitesP: "0.713456655785274", distinctSitesP: "0.272760246644904", brLenTotal: "1.0598" },
  { geneTranscript: "NM_022831.4", parsimonySitesP: "0.127450980392157", constantSitesP: "0.750544662309368", distinctSitesP: "0.249455337690632", brLenTotal: "0.4065" },
  { geneTranscript: "NM_013354.7", parsimonySitesP: "0.174269005847953", constantSitesP: "0.687719298245614", distinctSitesP: "0.276023391812865", brLenTotal: "0.6130" },
  { geneTranscript: "NM_001393907.1", parsimonySitesP: "0.124168514412417", constantSitesP: "0.779009608277901", distinctSitesP: "0.186252771618625", brLenTotal: "0.4575" },
  { geneTranscript: "NM_198880.3", parsimonySitesP: "0.102233676975945", constantSitesP: "0.820876288659794", distinctSitesP: "0.139175257731959", brLenTotal: "0.3373" },
  { geneTranscript: "NM_198055.2", parsimonySitesP: "0.279745685740236", constantSitesP: "0.563578564940963", distinctSitesP: "0.376930063578565", brLenTotal: "1.0316" },
  { geneTranscript: "NM_022475.3", parsimonySitesP: "0.129047619047619", constantSitesP: "0.764761904761905", distinctSitesP: "0.19", brLenTotal: "0.4912" },
  { geneTranscript: "NM_006083.4", parsimonySitesP: "0.128067025733094", constantSitesP: "0.77737881508079", distinctSitesP: "0.19868342309994", brLenTotal: "0.3933" },
  { geneTranscript: "NM_001320925.4", parsimonySitesP: "0.146666666666667", constantSitesP: "0.752", distinctSitesP: "0.232", brLenTotal: "0.4428" },
  { geneTranscript: "NM_005982.4", parsimonySitesP: "0.132629107981221", constantSitesP: "0.773474178403756", distinctSitesP: "0.190140845070423", brLenTotal: "0.5736" },
  { geneTranscript: "NM_018440.4", parsimonySitesP: "0.279320987654321", constantSitesP: "0.592592592592593", distinctSitesP: "0.405092592592593", brLenTotal: "1.1063" },
  { geneTranscript: "NM_173354.5", parsimonySitesP: "0.308216262239251", constantSitesP: "0.559812686249468", distinctSitesP: "0.420178799489144", brLenTotal: "1.3818" },
  { geneTranscript: "NM_144719.4", parsimonySitesP: "0.292773892773893", constantSitesP: "0.547319347319347", distinctSitesP: "0.397202797202797", brLenTotal: "1.0471" },
  { geneTranscript: "NM_002585.4", parsimonySitesP: "0.113953488372093", constantSitesP: "0.845736434108527", distinctSitesP: "0.142635658914729", brLenTotal: "0.6174" },
  { geneTranscript: "NM_001370150.2", parsimonySitesP: "0.289896128423041", constantSitesP: "0.509915014164306", distinctSitesP: "0.429650613786591", brLenTotal: "1.0609" },
  { geneTranscript: "NM_019082.4", parsimonySitesP: "0.200487507617307", constantSitesP: "0.677635588056063", distinctSitesP: "0.287629494210847", brLenTotal: "0.7166" },
  { geneTranscript: "NM_001359084.1", parsimonySitesP: "0.254166666666667", constantSitesP: "0.566666666666667", distinctSitesP: "0.4875", brLenTotal: "1.1093" },
  { geneTranscript: "NM_001330723.2", parsimonySitesP: "0.112138016019717", constantSitesP: "0.823783117683303", distinctSitesP: "0.155884165126309", brLenTotal: "0.3025" },
  { geneTranscript: "NM_015401.5", parsimonySitesP: "0.210225361587622", constantSitesP: "0.667003027245207", distinctSitesP: "0.249915909855365", brLenTotal: "0.7569" },
  { geneTranscript: "NM_016283.5", parsimonySitesP: "0.193798449612403", constantSitesP: "0.645348837209302", distinctSitesP: "0.352713178294574", brLenTotal: "0.6617" },
  { geneTranscript: "NM_001318192.2", parsimonySitesP: "0.169590643274854", constantSitesP: "0.70441254651781", distinctSitesP: "0.242424242424242", brLenTotal: "0.6415" },
  { geneTranscript: "NM_017446.4", parsimonySitesP: "0.196252465483235", constantSitesP: "0.67258382642998", distinctSitesP: "0.322485207100592", brLenTotal: "0.7077" },
  { geneTranscript: "NM_007285.7", parsimonySitesP: "0.0683760683760684", constantSitesP: "0.851851851851852", distinctSitesP: "0.153846153846154", brLenTotal: "0.2375" },
  { geneTranscript: "NM_006913.4", parsimonySitesP: "0.107407407407407", constantSitesP: "0.761111111111111", distinctSitesP: "0.233333333333333", brLenTotal: "0.4047" },
  { geneTranscript: "NM_004426.3", parsimonySitesP: "0.165338645418327", constantSitesP: "0.72875166002656", distinctSitesP: "0.213811420982736", brLenTotal: "0.5399" },
  { geneTranscript: "NM_015670.6", parsimonySitesP: "0.113821138211382", constantSitesP: "0.788037166085947", distinctSitesP: "0.165505226480836", brLenTotal: "0.3608" },
  { geneTranscript: "NM_003732.3", parsimonySitesP: "0.19", constantSitesP: "0.643333333333333", distinctSitesP: "0.366666666666667", brLenTotal: "0.6570" },
  { geneTranscript: "NM_001164664.2", parsimonySitesP: "0.265599186681916", constantSitesP: "0.591307662981319", distinctSitesP: "0.356970390138518", brLenTotal: "1.0691" },
  { geneTranscript: "NM_148919.4", parsimonySitesP: "0.241545893719807", constantSitesP: "0.635265700483092", distinctSitesP: "0.367149758454106", brLenTotal: "0.9051" },
  { geneTranscript: "NM_002217.4", parsimonySitesP: "0.236329588014981", constantSitesP: "0.606741573033708", distinctSitesP: "0.329213483146067", brLenTotal: "0.9717" },
  { geneTranscript: "NM_024096.2", parsimonySitesP: "0.309803921568627", constantSitesP: "0.509803921568627", distinctSitesP: "0.486274509803922", brLenTotal: "1.0746" },
  { geneTranscript: "NM_173614.4", parsimonySitesP: "0.196399345335516", constantSitesP: "0.689307146753955", distinctSitesP: "0.253682487725041", brLenTotal: "0.8140" },
  { geneTranscript: "NM_001797.4", parsimonySitesP: "0.151591289782245", constantSitesP: "0.775544388609715", distinctSitesP: "0.190117252931323", brLenTotal: "0.6113" },
  { geneTranscript: "NM_001261430.2", parsimonySitesP: "0.164529914529915", constantSitesP: "0.730769230769231", distinctSitesP: "0.271367521367521", brLenTotal: "0.4590" },
  { geneTranscript: "NM_018718.3", parsimonySitesP: "0.18230563002681", constantSitesP: "0.696157283288651", distinctSitesP: "0.271671134941912", brLenTotal: "0.6489" },
  { geneTranscript: "NM_001030006.2", parsimonySitesP: "0.167542937259026", constantSitesP: "0.705923589204346", distinctSitesP: "0.236593059936909", brLenTotal: "0.6668" },
  { geneTranscript: "NM_018463.4", parsimonySitesP: "0.217747949291573", constantSitesP: "0.651006711409396", distinctSitesP: "0.315436241610738", brLenTotal: "0.8678" },
  { geneTranscript: "NM_006620.4", parsimonySitesP: "0.165692007797271", constantSitesP: "0.705165692007797", distinctSitesP: "0.267056530214425", brLenTotal: "0.5975" },
  { geneTranscript: "NM_001355204.2", parsimonySitesP: "0.380952380952381", constantSitesP: "0.333333333333333", distinctSitesP: "0.666666666666667", brLenTotal: "1.7184" },
  { geneTranscript: "NM_004465.2", parsimonySitesP: "0.0993589743589744", constantSitesP: "0.801282051282051", distinctSitesP: "0.192307692307692", brLenTotal: "0.3399" },
  { geneTranscript: "NM_016734.3", parsimonySitesP: "0.116794543904518", constantSitesP: "0.774083546462063", distinctSitesP: "0.190963341858483", brLenTotal: "0.4805" },
  { geneTranscript: "NM_000687.4", parsimonySitesP: "0.220679012345679", constantSitesP: "0.606481481481481", distinctSitesP: "0.33641975308642", brLenTotal: "1.0641" },
  { geneTranscript: "NM_145230.4", parsimonySitesP: "0.119341563786008", constantSitesP: "0.802469135802469", distinctSitesP: "0.201646090534979", brLenTotal: "0.5060" },
  { geneTranscript: "NM_144609.3", parsimonySitesP: "0.191964285714286", constantSitesP: "0.645833333333333", distinctSitesP: "0.34077380952381", brLenTotal: "0.7144" },
  { geneTranscript: "NM_032813.5", parsimonySitesP: "0.191228070175439", constantSitesP: "0.691666666666667", distinctSitesP: "0.289473684210526", brLenTotal: "0.7867" },
  { geneTranscript: "NM_006012.4", parsimonySitesP: "0.222623345367028", constantSitesP: "0.655836341756919", distinctSitesP: "0.339350180505415", brLenTotal: "0.9678" },
  { geneTranscript: "NM_021018.3", parsimonySitesP: "0.321078431372549", constantSitesP: "0.661764705882353", distinctSitesP: "0.343137254901961", brLenTotal: "2.3173" },
  { geneTranscript: "NM_001193329.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.614977614977615", distinctSitesP: "0.327635327635328", brLenTotal: "0.7604" },
  { geneTranscript: "NM_000821.7", parsimonySitesP: "0.184256816182938", constantSitesP: "0.688214599824099", distinctSitesP: "0.245382585751979", brLenTotal: "0.6033" },
  { geneTranscript: "NM_001365693.1", parsimonySitesP: "0.255599951567986", constantSitesP: "0.603341808935707", distinctSitesP: "0.273277636517738", brLenTotal: "1.0762" },
  { geneTranscript: "NM_153271.2", parsimonySitesP: "0.16260162601626", constantSitesP: "0.731707317073171", distinctSitesP: "0.21602787456446", brLenTotal: "0.6854" },
  { geneTranscript: "NM_005804.4", parsimonySitesP: "0.225604996096799", constantSitesP: "0.68696330991413", distinctSitesP: "0.287275565964091", brLenTotal: "1.1468" },
  { geneTranscript: "NM_015342.4", parsimonySitesP: "0.132610939112487", constantSitesP: "0.757997936016512", distinctSitesP: "0.210526315789474", brLenTotal: "0.4298" },
  { geneTranscript: "NM_020150.5", parsimonySitesP: "0.12962962962963", constantSitesP: "0.784511784511785", distinctSitesP: "0.217171717171717", brLenTotal: "0.4245" },
  { geneTranscript: "NM_006852.6", parsimonySitesP: "0.0915555555555556", constantSitesP: "0.808", distinctSitesP: "0.139555555555556", brLenTotal: "0.3829" },
  { geneTranscript: "NM_000845.3", parsimonySitesP: "0.123715124816446", constantSitesP: "0.794052863436123", distinctSitesP: "0.174008810572687", brLenTotal: "0.4370" },
  { geneTranscript: "NM_006767.4", parsimonySitesP: "0.185714285714286", constantSitesP: "0.73015873015873", distinctSitesP: "0.229365079365079", brLenTotal: "0.9258" },
  { geneTranscript: "NM_176812.5", parsimonySitesP: "0.147321428571429", constantSitesP: "0.770833333333333", distinctSitesP: "0.223214285714286", brLenTotal: "0.5941" },
  { geneTranscript: "NM_016098.4", parsimonySitesP: "0.195718654434251", constantSitesP: "0.740061162079511", distinctSitesP: "0.287461773700306", brLenTotal: "0.7077" },
  { geneTranscript: "NM_020195.3", parsimonySitesP: "0.219567690557452", constantSitesP: "0.59613196814562", distinctSitesP: "0.34584755403868", brLenTotal: "0.7770" },
  { geneTranscript: "NM_198965.2", parsimonySitesP: "0.171374764595104", constantSitesP: "0.696798493408663", distinctSitesP: "0.271186440677966", brLenTotal: "0.5697" },
  { geneTranscript: "NM_015235.3", parsimonySitesP: "0.222402597402597", constantSitesP: "0.62987012987013", distinctSitesP: "0.311147186147186", brLenTotal: "0.8210" },
  { geneTranscript: "NM_017901.6", parsimonySitesP: "0.175653594771242", constantSitesP: "0.718954248366013", distinctSitesP: "0.244281045751634", brLenTotal: "0.7830" },
  { geneTranscript: "NM_003546.3", parsimonySitesP: "0.3042071197411", constantSitesP: "0.656957928802589", distinctSitesP: "0.355987055016181", brLenTotal: "1.8792" },
  { geneTranscript: "NM_018461.5", parsimonySitesP: "0.275202354672553", constantSitesP: "0.642384105960265", distinctSitesP: "0.333333333333333", brLenTotal: "1.4444" },
  { geneTranscript: "NM_173510.4", parsimonySitesP: "0.172043010752688", constantSitesP: "0.683393070489845", distinctSitesP: "0.305854241338112", brLenTotal: "0.5257" },
  { geneTranscript: "NM_174976.2", parsimonySitesP: "0.138149556400507", constantSitesP: "0.762991128010139", distinctSitesP: "0.219264892268695", brLenTotal: "0.5155" },
  { geneTranscript: "NM_001377303.1", parsimonySitesP: "0.218484145398299", constantSitesP: "0.639597834493426", distinctSitesP: "0.310518174787316", brLenTotal: "0.7879" },
  { geneTranscript: "NM_015426.5", parsimonySitesP: "0.178542178542179", constantSitesP: "0.696150696150696", distinctSitesP: "0.256347256347256", brLenTotal: "0.7200" },
  { geneTranscript: "NM_001386096.1", parsimonySitesP: "0.478494623655914", constantSitesP: "0.358064516129032", distinctSitesP: "0.644086021505376", brLenTotal: "3.5455" },
  { geneTranscript: "NM_016436.5", parsimonySitesP: "0.153162055335968", constantSitesP: "0.718050065876153", distinctSitesP: "0.211462450592885", brLenTotal: "0.5149" },
  { geneTranscript: "NM_001031827.3", parsimonySitesP: "0.162790697674419", constantSitesP: "0.635658914728682", distinctSitesP: "0.356589147286822", brLenTotal: "0.5524" },
  { geneTranscript: "NM_001040424.3", parsimonySitesP: "0.218229623137599", constantSitesP: "0.691498685363716", distinctSitesP: "0.283377154542799", brLenTotal: "1.0046" },
  { geneTranscript: "NM_018457.4", parsimonySitesP: "0.286036036036036", constantSitesP: "0.509009009009009", distinctSitesP: "0.513513513513514", brLenTotal: "1.1236" },
  { geneTranscript: "NM_001002909.4", parsimonySitesP: "0.173102529960053", constantSitesP: "0.675321793164669", distinctSitesP: "0.213271193963604", brLenTotal: "0.6006" },
  { geneTranscript: "NM_033310.3", parsimonySitesP: "0.18490245971162", constantSitesP: "0.699745547073791", distinctSitesP: "0.248515691263783", brLenTotal: "0.6725" },
  { geneTranscript: "NM_002004.4", parsimonySitesP: "0.214001591089897", constantSitesP: "0.652346857597454", distinctSitesP: "0.330151153540175", brLenTotal: "0.8097" },
  { geneTranscript: "NM_003475.4", parsimonySitesP: "0.327077747989276", constantSitesP: "0.506702412868633", distinctSitesP: "0.462019660411081", brLenTotal: "1.4257" },
  { geneTranscript: "NM_015529.4", parsimonySitesP: "0.183251767264818", constantSitesP: "0.666666666666667", distinctSitesP: "0.297444263186514", brLenTotal: "0.7075" },
  { geneTranscript: "NM_003374.3", parsimonySitesP: "0.187279151943463", constantSitesP: "0.667844522968198", distinctSitesP: "0.288574793875147", brLenTotal: "0.8365" },
  { geneTranscript: "NM_020063.2", parsimonySitesP: "0.16365202411714", constantSitesP: "0.725236864771748", distinctSitesP: "0.252368647717485", brLenTotal: "0.6004" },
  { geneTranscript: "NM_178554.6", parsimonySitesP: "0.195158850226929", constantSitesP: "0.690872415532022", distinctSitesP: "0.266263237518911", brLenTotal: "0.7835" },
  { geneTranscript: "NM_012064.4", parsimonySitesP: "0.168567807351077", constantSitesP: "0.695817490494297", distinctSitesP: "0.250950570342205", brLenTotal: "0.6525" },
  { geneTranscript: "NM_006465.4", parsimonySitesP: "0.241071428571429", constantSitesP: "0.591666666666667", distinctSitesP: "0.360119047619048", brLenTotal: "1.0724" },
  { geneTranscript: "NM_153343.4", parsimonySitesP: "0.258333333333333", constantSitesP: "0.618939393939394", distinctSitesP: "0.375757575757576", brLenTotal: "1.1644" },
  { geneTranscript: "NM_033087.4", parsimonySitesP: "0.21875", constantSitesP: "0.651442307692308", distinctSitesP: "0.300480769230769", brLenTotal: "0.7620" },
  { geneTranscript: "NM_178429.5", parsimonySitesP: "0.43030303030303", constantSitesP: "0.321212121212121", distinctSitesP: "0.724242424242424", brLenTotal: "3.6759" },
  { geneTranscript: "NM_001288.6", parsimonySitesP: "0.157676348547718", constantSitesP: "0.684647302904564", distinctSitesP: "0.271092669432918", brLenTotal: "0.6004" },
  { geneTranscript: "NM_001693.4", parsimonySitesP: "0.174820613176778", constantSitesP: "0.730593607305936", distinctSitesP: "0.238095238095238", brLenTotal: "0.7686" },
  { geneTranscript: "NM_005892.4", parsimonySitesP: "0.204848484848485", constantSitesP: "0.678484848484848", distinctSitesP: "0.256666666666667", brLenTotal: "0.9214" },
  { geneTranscript: "NM_198488.5", parsimonySitesP: "0.270568278201866", constantSitesP: "0.590613514277636", distinctSitesP: "0.32767882386203", brLenTotal: "1.2401" },
  { geneTranscript: "NM_001455.4", parsimonySitesP: "0.149083704804359", constantSitesP: "0.760277365032194", distinctSitesP: "0.189202575532442", brLenTotal: "0.5848" },
  { geneTranscript: "NM_013255.5", parsimonySitesP: "0.0975056689342404", constantSitesP: "0.819047619047619", distinctSitesP: "0.143764172335601", brLenTotal: "0.3390" },
  { geneTranscript: "NM_001540.5", parsimonySitesP: "0.24390243902439", constantSitesP: "0.543089430894309", distinctSitesP: "0.456910569105691", brLenTotal: "1.0199" },
  { geneTranscript: "NM_019020.4", parsimonySitesP: "0.236853541938288", constantSitesP: "0.633202955236854", distinctSitesP: "0.310734463276836", brLenTotal: "1.1953" },
  { geneTranscript: "NM_003709.4", parsimonySitesP: "0.115894039735099", constantSitesP: "0.727373068432671", distinctSitesP: "0.189845474613687", brLenTotal: "0.8208" },
  { geneTranscript: "NM_001251845.2", parsimonySitesP: "0.0878520386717108", constantSitesP: "0.837326607818411", distinctSitesP: "0.129466162253047", brLenTotal: "0.2654" },
  { geneTranscript: "NM_080622.4", parsimonySitesP: "0.338308457711443", constantSitesP: "0.497512437810945", distinctSitesP: "0.442786069651741", brLenTotal: "1.5620" },
  { geneTranscript: "NM_152415.3", parsimonySitesP: "0.154492023509656", constantSitesP: "0.732997481108312", distinctSitesP: "0.269521410579345", brLenTotal: "0.4853" },
  { geneTranscript: "NM_003986.3", parsimonySitesP: "0.20671834625323", constantSitesP: "0.671834625322997", distinctSitesP: "0.311800172265289", brLenTotal: "0.7231" },
  { geneTranscript: "NM_130468.4", parsimonySitesP: "0.179078014184397", constantSitesP: "0.689716312056738", distinctSitesP: "0.252659574468085", brLenTotal: "0.6109" },
  { geneTranscript: "NM_002733.5", parsimonySitesP: "0.129909365558912", constantSitesP: "0.750251762336354", distinctSitesP: "0.229607250755287", brLenTotal: "0.4443" },
  { geneTranscript: "NM_003805.5", parsimonySitesP: "0.249581239530988", constantSitesP: "0.614740368509213", distinctSitesP: "0.363484087102178", brLenTotal: "1.0450" },
  { geneTranscript: "NM_001080471.3", parsimonySitesP: "0.22854387656702", constantSitesP: "0.620379299260688", distinctSitesP: "0.298296367727419", brLenTotal: "0.8587" },
  { geneTranscript: "NM_182523.2", parsimonySitesP: "0.163522012578616", constantSitesP: "0.672955974842767", distinctSitesP: "0.345911949685535", brLenTotal: "0.6104" },
  { geneTranscript: "NM_001288718.2", parsimonySitesP: "0.157430730478589", constantSitesP: "0.727539882451721", distinctSitesP: "0.230478589420655", brLenTotal: "0.7272" },
  { geneTranscript: "NM_005630.3", parsimonySitesP: "0.225505443234837", constantSitesP: "0.65526179367548", distinctSitesP: "0.294453084499741", brLenTotal: "0.8641" },
  { geneTranscript: "NM_016057.3", parsimonySitesP: "0.112994350282486", constantSitesP: "0.804143126177024", distinctSitesP: "0.195856873822976", brLenTotal: "0.3266" },
  { geneTranscript: "NM_003478.6", parsimonySitesP: "0.085042735042735", constantSitesP: "0.826495726495726", distinctSitesP: "0.126068376068376", brLenTotal: "0.3065" },
  { geneTranscript: "NM_004286.5", parsimonySitesP: "0.138515196811161", constantSitesP: "0.781265570503239", distinctSitesP: "0.183856502242152", brLenTotal: "0.5107" },
  { geneTranscript: "NM_003761.5", parsimonySitesP: "0.18", constantSitesP: "0.68", distinctSitesP: "0.326666666666667", brLenTotal: "0.5914" },
  { geneTranscript: "NM_153367.4", parsimonySitesP: "0.125864453665284", constantSitesP: "0.773167358229599", distinctSitesP: "0.20746887966805", brLenTotal: "0.4979" },
  { geneTranscript: "NM_024636.4", parsimonySitesP: "0.213507625272331", constantSitesP: "0.641975308641975", distinctSitesP: "0.32316630355846", brLenTotal: "0.7176" },
  { geneTranscript: "NM_005523.6", parsimonySitesP: "0.0862619808306709", constantSitesP: "0.850905218317359", distinctSitesP: "0.150159744408946", brLenTotal: "0.2744" },
  { geneTranscript: "NM_001163788.4", parsimonySitesP: "0.0896946564885496", constantSitesP: "0.801526717557252", distinctSitesP: "0.156488549618321", brLenTotal: "0.3243" },
  { geneTranscript: "NM_001377295.2", parsimonySitesP: "0.135593220338983", constantSitesP: "0.761770244821092", distinctSitesP: "0.2015065913371", brLenTotal: "0.4892" },
  { geneTranscript: "NM_001080446.3", parsimonySitesP: "0.299319727891156", constantSitesP: "0.503401360544218", distinctSitesP: "0.5", brLenTotal: "1.2629" },
  { geneTranscript: "NM_001135705.3", parsimonySitesP: "0.246994535519126", constantSitesP: "0.59344262295082", distinctSitesP: "0.358469945355191", brLenTotal: "0.8351" },
  { geneTranscript: "NM_017495.6", parsimonySitesP: "0.191073919107392", constantSitesP: "0.656903765690377", distinctSitesP: "0.338912133891213", brLenTotal: "1.0240" },
  { geneTranscript: "NM_024652.6", parsimonySitesP: "0.213895781637717", constantSitesP: "0.674441687344913", distinctSitesP: "0.255748552522746", brLenTotal: "0.9518" },
  { geneTranscript: "NM_032758.4", parsimonySitesP: "0.148484848484848", constantSitesP: "0.733333333333333", distinctSitesP: "0.275757575757576", brLenTotal: "0.5758" },
  { geneTranscript: "NM_001447.3", parsimonySitesP: "0.233846861347436", constantSitesP: "0.617383306507243", distinctSitesP: "0.238675557599448", brLenTotal: "0.8735" },
  { geneTranscript: "NM_005188.4", parsimonySitesP: "0.149374540103017", constantSitesP: "0.737674760853569", distinctSitesP: "0.213760117733628", brLenTotal: "0.5300" },
  { geneTranscript: "NM_021128.5", parsimonySitesP: "0.194029850746269", constantSitesP: "0.711442786069652", distinctSitesP: "0.298507462686567", brLenTotal: "0.7507" },
  { geneTranscript: "NM_181621.4", parsimonySitesP: "0.466666666666667", constantSitesP: "0.255238095238095", distinctSitesP: "0.780952380952381", brLenTotal: "2.5702" },
  { geneTranscript: "NM_003458.4", parsimonySitesP: "0.215316692137884", constantSitesP: "0.63355408388521", distinctSitesP: "0.220241127525896", brLenTotal: "0.8256" },
  { geneTranscript: "NM_003520.4", parsimonySitesP: "0.182539682539683", constantSitesP: "0.703703703703704", distinctSitesP: "0.298941798941799", brLenTotal: "1.8141" },
  { geneTranscript: "NM_020754.4", parsimonySitesP: "0.233379501385042", constantSitesP: "0.597183748845799", distinctSitesP: "0.311634349030471", brLenTotal: "0.8446" },
  { geneTranscript: "NM_001077195.2", parsimonySitesP: "0.125531914893617", constantSitesP: "0.771631205673759", distinctSitesP: "0.178723404255319", brLenTotal: "0.4829" },
  { geneTranscript: "NM_018059.5", parsimonySitesP: "0.285581395348837", constantSitesP: "0.586046511627907", distinctSitesP: "0.36", brLenTotal: "1.3454" },
  { geneTranscript: "NM_002655.3", parsimonySitesP: "0.0953333333333333", constantSitesP: "0.849333333333333", distinctSitesP: "0.126", brLenTotal: "0.2991" },
  { geneTranscript: "NM_031442.4", parsimonySitesP: "0.0939226519337017", constantSitesP: "0.78268876611418", distinctSitesP: "0.204419889502762", brLenTotal: "0.3176" },
  { geneTranscript: "NM_001396013.1", parsimonySitesP: "0.3125", constantSitesP: "0.307291666666667", distinctSitesP: "0.619791666666667", brLenTotal: "2.1100" },
  { geneTranscript: "NM_212550.5", parsimonySitesP: "0.249174917491749", constantSitesP: "0.622112211221122", distinctSitesP: "0.367986798679868", brLenTotal: "0.9173" },
  { geneTranscript: "NM_004146.6", parsimonySitesP: "0.299270072992701", constantSitesP: "0.564476885644769", distinctSitesP: "0.457420924574209", brLenTotal: "1.1916" },
  { geneTranscript: "NM_001164094.2", parsimonySitesP: "0.141818181818182", constantSitesP: "0.763636363636364", distinctSitesP: "0.214545454545455", brLenTotal: "0.5150" },
  { geneTranscript: "NM_004872.5", parsimonySitesP: "0.152734778121775", constantSitesP: "0.742002063983488", distinctSitesP: "0.252837977296182", brLenTotal: "0.4481" },
  { geneTranscript: "NM_015492.5", parsimonySitesP: "0.3546641197071", constantSitesP: "0.440942375039796", distinctSitesP: "0.465775230818211", brLenTotal: "1.6616" },
  { geneTranscript: "NM_016103.4", parsimonySitesP: "0.148148148148148", constantSitesP: "0.760942760942761", distinctSitesP: "0.232323232323232", brLenTotal: "0.4873" },
  { geneTranscript: "NM_031232.4", parsimonySitesP: "0.266835016835017", constantSitesP: "0.574915824915825", distinctSitesP: "0.416666666666667", brLenTotal: "1.2084" },
  { geneTranscript: "NM_153000.5", parsimonySitesP: "0.212062256809339", constantSitesP: "0.664721141374838", distinctSitesP: "0.302853437094682", brLenTotal: "1.0033" },
  { geneTranscript: "NM_020989.4", parsimonySitesP: "0.256704980842912", constantSitesP: "0.568965517241379", distinctSitesP: "0.413793103448276", brLenTotal: "1.2985" },
  { geneTranscript: "NM_001385648.2", parsimonySitesP: "0.280436607892527", constantSitesP: "0.588581024349286", distinctSitesP: "0.356842989084803", brLenTotal: "1.1322" },
  { geneTranscript: "NM_001320510.2", parsimonySitesP: "0.238921001926782", constantSitesP: "0.601156069364162", distinctSitesP: "0.400770712909441", brLenTotal: "0.8797" },
  { geneTranscript: "NM_018843.4", parsimonySitesP: "0.175542406311637", constantSitesP: "0.694280078895464", distinctSitesP: "0.282051282051282", brLenTotal: "0.5176" },
  { geneTranscript: "NM_032582.4", parsimonySitesP: "0.130922693266833", constantSitesP: "0.75498753117207", distinctSitesP: "0.174355777223608", brLenTotal: "0.4339" },
  { geneTranscript: "NM_145720.4", parsimonySitesP: "0.169921875", constantSitesP: "0.690104166666667", distinctSitesP: "0.277994791666667", brLenTotal: "0.6248" },
  { geneTranscript: "NM_001286077.2", parsimonySitesP: "0.17283950617284", constantSitesP: "0.720538720538721", distinctSitesP: "0.245791245791246", brLenTotal: "0.5160" },
  { geneTranscript: "NM_012393.3", parsimonySitesP: "0.233931240657698", constantSitesP: "0.642750373692078", distinctSitesP: "0.269556552067763", brLenTotal: "0.9215" },
  { geneTranscript: "NM_152756.5", parsimonySitesP: "0.112607338017174", constantSitesP: "0.80815768930523", distinctSitesP: "0.146174863387978", brLenTotal: "0.3478" },
  { geneTranscript: "NM_001098200.2", parsimonySitesP: "0.221550855991944", constantSitesP: "0.671701913393756", distinctSitesP: "0.30110775427996", brLenTotal: "0.7561" },
  { geneTranscript: "NM_005996.4", parsimonySitesP: "0.153526970954357", constantSitesP: "0.746887966804979", distinctSitesP: "0.213001383125864", brLenTotal: "0.6485" },
  { geneTranscript: "NM_001136528.2", parsimonySitesP: "0.198992443324937", constantSitesP: "0.691855583543241", distinctSitesP: "0.304785894206549", brLenTotal: "0.8766" },
  { geneTranscript: "NM_033337.3", parsimonySitesP: "0.205298013245033", constantSitesP: "0.629139072847682", distinctSitesP: "0.315673289183223", brLenTotal: "0.9607" },
  { geneTranscript: "NM_001304331.2", parsimonySitesP: "0.166804750069042", constantSitesP: "0.730185031759183", distinctSitesP: "0.212096106048053", brLenTotal: "0.7399" },
  { geneTranscript: "NM_000025.3", parsimonySitesP: "0.405228758169935", constantSitesP: "0.464052287581699", distinctSitesP: "0.443627450980392", brLenTotal: "1.5445" },
  { geneTranscript: "NM_004929.4", parsimonySitesP: "0.112388250319285", constantSitesP: "0.804597701149425", distinctSitesP: "0.187739463601533", brLenTotal: "0.3426" },
  { geneTranscript: "NM_001271870.5", parsimonySitesP: "0.122004357298475", constantSitesP: "0.769063180827887", distinctSitesP: "0.187363834422658", brLenTotal: "0.4654" },
  { geneTranscript: "NM_003936.5", parsimonySitesP: "0.122615803814714", constantSitesP: "0.773841961852861", distinctSitesP: "0.212534059945504", brLenTotal: "0.4932" },
  { geneTranscript: "NM_173478.3", parsimonySitesP: "0.206060606060606", constantSitesP: "0.657575757575758", distinctSitesP: "0.298989898989899", brLenTotal: "0.6296" },
  { geneTranscript: "NM_001687.5", parsimonySitesP: "0.30952380952381", constantSitesP: "0.571428571428571", distinctSitesP: "0.416666666666667", brLenTotal: "1.3642" },
  { geneTranscript: "NM_057161.4", parsimonySitesP: "0.117801047120419", constantSitesP: "0.801919720767888", distinctSitesP: "0.164921465968586", brLenTotal: "0.3673" },
  { geneTranscript: "NM_015846.4", parsimonySitesP: "0.186776859504132", constantSitesP: "0.690358126721763", distinctSitesP: "0.257300275482094", brLenTotal: "0.6381" },
  { geneTranscript: "NM_006809.5", parsimonySitesP: "0.223300970873786", constantSitesP: "0.652642934196332", distinctSitesP: "0.309600862998921", brLenTotal: "0.7003" },
  { geneTranscript: "NM_016076.5", parsimonySitesP: "0.132302405498282", constantSitesP: "0.776632302405498", distinctSitesP: "0.235395189003436", brLenTotal: "0.4949" },
  { geneTranscript: "NM_006366.3", parsimonySitesP: "0.187980433263452", constantSitesP: "0.698812019566737", distinctSitesP: "0.285814116002795", brLenTotal: "0.6993" },
  { geneTranscript: "NM_021971.4", parsimonySitesP: "0.140740740740741", constantSitesP: "0.77037037037037", distinctSitesP: "0.19537037037037", brLenTotal: "0.4908" },
  { geneTranscript: "NM_153810.5", parsimonySitesP: "0.0975609756097561", constantSitesP: "0.832881662149955", distinctSitesP: "0.160794941282746", brLenTotal: "0.2900" },
  { geneTranscript: "NM_000704.3", parsimonySitesP: "0.186151368760064", constantSitesP: "0.726570048309179", distinctSitesP: "0.229307568438003", brLenTotal: "0.9409" },
  { geneTranscript: "NM_001242818.2", parsimonySitesP: "0.218773096821877", constantSitesP: "0.665927568366593", distinctSitesP: "0.293422025129342", brLenTotal: "1.1550" },
  { geneTranscript: "NM_152485.4", parsimonySitesP: "0.244114002478315", constantSitesP: "0.579925650557621", distinctSitesP: "0.349442379182156", brLenTotal: "0.7695" },
  { geneTranscript: "NM_016315.4", parsimonySitesP: "0.105263157894737", constantSitesP: "0.802631578947368", distinctSitesP: "0.18859649122807", brLenTotal: "0.3565" },
  { geneTranscript: "NM_198719.2", parsimonySitesP: "0.22991452991453", constantSitesP: "0.606837606837607", distinctSitesP: "0.341025641025641", brLenTotal: "0.8467" },
  { geneTranscript: "NM_000756.4", parsimonySitesP: "0.229591836734694", constantSitesP: "0.608843537414966", distinctSitesP: "0.375850340136054", brLenTotal: "0.8152" },
  { geneTranscript: "NM_007259.5", parsimonySitesP: "0.116374269005848", constantSitesP: "0.77719298245614", distinctSitesP: "0.184210526315789", brLenTotal: "0.4052" },
  { geneTranscript: "NM_006230.4", parsimonySitesP: "0.200426439232409", constantSitesP: "0.685856432125089", distinctSitesP: "0.297796730632552", brLenTotal: "0.7553" },
  { geneTranscript: "NM_198896.2", parsimonySitesP: "0.0865384615384615", constantSitesP: "0.846153846153846", distinctSitesP: "0.145833333333333", brLenTotal: "0.2292" },
  { geneTranscript: "NM_006544.4", parsimonySitesP: "0.104048964218456", constantSitesP: "0.803201506591337", distinctSitesP: "0.164312617702448", brLenTotal: "0.3221" },
  { geneTranscript: "NM_001161417.2", parsimonySitesP: "0.215339233038348", constantSitesP: "0.668633235004916", distinctSitesP: "0.294001966568338", brLenTotal: "1.0466" },
  { geneTranscript: "NM_001195571.2", parsimonySitesP: "0.341954022988506", constantSitesP: "0.514367816091954", distinctSitesP: "0.471264367816092", brLenTotal: "1.0158" },
  { geneTranscript: "NM_017813.5", parsimonySitesP: "0.167130919220056", constantSitesP: "0.72794800371402", distinctSitesP: "0.256267409470752", brLenTotal: "0.5288" },
  { geneTranscript: "NM_001351578.2", parsimonySitesP: "0.138523391812865", constantSitesP: "0.752558479532164", distinctSitesP: "0.192251461988304", brLenTotal: "0.5257" },
  { geneTranscript: "NM_019851.3", parsimonySitesP: "0.151658767772512", constantSitesP: "0.734597156398104", distinctSitesP: "0.248025276461295", brLenTotal: "0.5027" },
  { geneTranscript: "NM_023083.4", parsimonySitesP: "0.27827380952381", constantSitesP: "0.580357142857143", distinctSitesP: "0.359623015873016", brLenTotal: "1.2358" },
  { geneTranscript: "NM_001008392.2", parsimonySitesP: "0.120772946859903", constantSitesP: "0.800724637681159", distinctSitesP: "0.185990338164251", brLenTotal: "0.3999" },
  { geneTranscript: "NM_001366418.1", parsimonySitesP: "0.147316821465428", constantSitesP: "0.753611971104231", distinctSitesP: "0.184726522187822", brLenTotal: "0.4876" },
  { geneTranscript: "NM_138289.4", parsimonySitesP: "0.438829787234043", constantSitesP: "0.408687943262411", distinctSitesP: "0.540780141843972", brLenTotal: "1.9373" },
  { geneTranscript: "NM_005711.5", parsimonySitesP: "0.1625", constantSitesP: "0.734027777777778", distinctSitesP: "0.2375", brLenTotal: "0.6416" },
  { geneTranscript: "NM_001663.4", parsimonySitesP: "0.0704761904761905", constantSitesP: "0.739047619047619", distinctSitesP: "0.121904761904762", brLenTotal: "0.7563" },
  { geneTranscript: "NM_000036.3", parsimonySitesP: "0.178937974118697", constantSitesP: "0.695671575189647", distinctSitesP: "0.256581883087907", brLenTotal: "0.6214" },
  { geneTranscript: "NM_001272005.2", parsimonySitesP: "0.26643598615917", constantSitesP: "0.591695501730104", distinctSitesP: "0.369665513264129", brLenTotal: "1.0605" },
  { geneTranscript: "NM_015160.3", parsimonySitesP: "0.246349206349206", constantSitesP: "0.643809523809524", distinctSitesP: "0.330793650793651", brLenTotal: "1.1841" },
  { geneTranscript: "NM_001378213.1", parsimonySitesP: "0.160329108294419", constantSitesP: "0.733600177896375", distinctSitesP: "0.189237269290638", brLenTotal: "0.6680" },
  { geneTranscript: "NM_018221.5", parsimonySitesP: "0.134259259259259", constantSitesP: "0.783950617283951", distinctSitesP: "0.200617283950617", brLenTotal: "0.4580" },
  { geneTranscript: "NM_001014840.2", parsimonySitesP: "0.238361266294227", constantSitesP: "0.631284916201117", distinctSitesP: "0.335195530726257", brLenTotal: "0.7037" },
  { geneTranscript: "NM_145049.5", parsimonySitesP: "0.0964360587002096", constantSitesP: "0.829140461215933", distinctSitesP: "0.153039832285115", brLenTotal: "0.3087" },
  { geneTranscript: "NM_015028.4", parsimonySitesP: "0.167892156862745", constantSitesP: "0.760294117647059", distinctSitesP: "0.189705882352941", brLenTotal: "0.6046" },
  { geneTranscript: "NM_002884.4", parsimonySitesP: "0.114130434782609", constantSitesP: "0.777173913043478", distinctSitesP: "0.193840579710145", brLenTotal: "0.3719" },
  { geneTranscript: "NM_021020.5", parsimonySitesP: "0.206935123042506", constantSitesP: "0.650447427293065", distinctSitesP: "0.286353467561521", brLenTotal: "0.9752" },
  { geneTranscript: "NM_001303441.2", parsimonySitesP: "0.272727272727273", constantSitesP: "0.525641025641026", distinctSitesP: "0.407925407925408", brLenTotal: "1.0955" },
  { geneTranscript: "NM_001182.5", parsimonySitesP: "0.204081632653061", constantSitesP: "0.664192949907236", distinctSitesP: "0.338899196042053", brLenTotal: "0.7953" },
  { geneTranscript: "NM_001332.4", parsimonySitesP: "0.119183673469388", constantSitesP: "0.810340136054422", distinctSitesP: "0.164897959183673", brLenTotal: "0.4968" },
  { geneTranscript: "NM_138368.5", parsimonySitesP: "0.304479878511769", constantSitesP: "0.522019741837509", distinctSitesP: "0.356492027334852", brLenTotal: "1.1971" },
  { geneTranscript: "NM_020799.4", parsimonySitesP: "0.17737003058104", constantSitesP: "0.712538226299694", distinctSitesP: "0.255351681957187", brLenTotal: "0.6709" },
  { geneTranscript: "NM_001079900.2", parsimonySitesP: "0.238993710691824", constantSitesP: "0.637316561844864", distinctSitesP: "0.364779874213836", brLenTotal: "0.7229" },
  { geneTranscript: "NM_198439.3", parsimonySitesP: "0.162854030501089", constantSitesP: "0.725490196078431", distinctSitesP: "0.235294117647059", brLenTotal: "0.5325" },
  { geneTranscript: "NM_014603.3", parsimonySitesP: "0.175627240143369", constantSitesP: "0.710394265232975", distinctSitesP: "0.234408602150538", brLenTotal: "0.6577" },
  { geneTranscript: "NM_001289971.2", parsimonySitesP: "0.21996386630533", constantSitesP: "0.599819331526649", distinctSitesP: "0.342818428184282", brLenTotal: "0.9641" },
  { geneTranscript: "NM_173353.4", parsimonySitesP: "0.159863945578231", constantSitesP: "0.742176870748299", distinctSitesP: "0.236054421768707", brLenTotal: "0.5837" },
  { geneTranscript: "NM_003214.4", parsimonySitesP: "0.144827586206897", constantSitesP: "0.76551724137931", distinctSitesP: "0.196934865900383", brLenTotal: "0.5983" },
  { geneTranscript: "NM_006044.4", parsimonySitesP: "0.223868312757202", constantSitesP: "0.626063100137174", distinctSitesP: "0.298491083676269", brLenTotal: "0.7686" },
  { geneTranscript: "NM_004431.5", parsimonySitesP: "0.255122950819672", constantSitesP: "0.654713114754098", distinctSitesP: "0.281420765027322", brLenTotal: "1.1189" },
  { geneTranscript: "NM_022361.5", parsimonySitesP: "0.132875143184422", constantSitesP: "0.77205040091638", distinctSitesP: "0.207331042382589", brLenTotal: "0.4242" },
  { geneTranscript: "NM_001400.5", parsimonySitesP: "0.216404886561955", constantSitesP: "0.68760907504363", distinctSitesP: "0.264397905759162", brLenTotal: "0.8996" },
  { geneTranscript: "NM_005095.3", parsimonySitesP: "0.130921619293712", constantSitesP: "0.781438415159345", distinctSitesP: "0.169465977605512", brLenTotal: "0.4391" },
  { geneTranscript: "NM_001379110.1", parsimonySitesP: "0.12125675012273", constantSitesP: "0.786941580756014", distinctSitesP: "0.181639666175749", brLenTotal: "0.4156" },
  { geneTranscript: "NM_000022.4", parsimonySitesP: "0.237832874196511", constantSitesP: "0.614325068870523", distinctSitesP: "0.365472910927456", brLenTotal: "1.0510" },
  { geneTranscript: "NM_002648.4", parsimonySitesP: "0.143769968051118", constantSitesP: "0.757188498402556", distinctSitesP: "0.215122470713525", brLenTotal: "0.5389" },
  { geneTranscript: "NM_012232.6", parsimonySitesP: "0.163247863247863", constantSitesP: "0.747863247863248", distinctSitesP: "0.221367521367521", brLenTotal: "0.5217" },
  { geneTranscript: "NM_005190.4", parsimonySitesP: "0.0624263839811543", constantSitesP: "0.890459363957597", distinctSitesP: "0.10718492343934", brLenTotal: "0.1816" },
  { geneTranscript: "NM_002075.4", parsimonySitesP: "0.162745098039216", constantSitesP: "0.746078431372549", distinctSitesP: "0.217647058823529", brLenTotal: "0.6030" },
  { geneTranscript: "NM_015696.5", parsimonySitesP: "0.171122994652406", constantSitesP: "0.71301247771836", distinctSitesP: "0.28698752228164", brLenTotal: "0.6096" },
  { geneTranscript: "NM_006732.3", parsimonySitesP: "0.107495069033531", constantSitesP: "0.815581854043393", distinctSitesP: "0.149901380670611", brLenTotal: "0.3793" },
  { geneTranscript: "NM_005267.5", parsimonySitesP: "0.280215550423403", constantSitesP: "0.569668976135489", distinctSitesP: "0.38337182448037", brLenTotal: "1.2094" },
  { geneTranscript: "NM_002134.4", parsimonySitesP: "0.220464135021097", constantSitesP: "0.636075949367089", distinctSitesP: "0.362869198312236", brLenTotal: "0.8089" },
  { geneTranscript: "NM_022459.5", parsimonySitesP: "0.108601216333623", constantSitesP: "0.785983203011874", distinctSitesP: "0.167101071532001", brLenTotal: "0.4315" },
  { geneTranscript: "NM_020655.4", parsimonySitesP: "0.236631016042781", constantSitesP: "0.633244206773619", distinctSitesP: "0.28921568627451", brLenTotal: "1.0696" },
  { geneTranscript: "NM_000431.4", parsimonySitesP: "0.25", constantSitesP: "0.61026936026936", distinctSitesP: "0.345959595959596", brLenTotal: "1.0172" },
  { geneTranscript: "NM_005912.3", parsimonySitesP: "0.160642570281124", constantSitesP: "0.743975903614458", distinctSitesP: "0.219879518072289", brLenTotal: "0.5538" },
  { geneTranscript: "NM_025251.3", parsimonySitesP: "0.210951526032316", constantSitesP: "0.683123877917415", distinctSitesP: "0.247157390783962", brLenTotal: "1.0211" },
  { geneTranscript: "NM_001004471.2", parsimonySitesP: "0.484848484848485", constantSitesP: "0.327063740856844", distinctSitesP: "0.656217345872518", brLenTotal: "2.5618" },
  { geneTranscript: "NM_019888.3", parsimonySitesP: "0.231166150670795", constantSitesP: "0.657378740970072", distinctSitesP: "0.30546955624355", brLenTotal: "0.8342" },
  { geneTranscript: "NM_152901.4", parsimonySitesP: "0.464419475655431", constantSitesP: "0.329588014981273", distinctSitesP: "0.689138576779026", brLenTotal: "2.0030" },
  { geneTranscript: "NM_014994.3", parsimonySitesP: "0.210875331564987", constantSitesP: "0.642351900972591", distinctSitesP: "0.294871794871795", brLenTotal: "0.7494" },
  { geneTranscript: "NM_001205252.2", parsimonySitesP: "0.360107095046854", constantSitesP: "0.493975903614458", distinctSitesP: "0.504685408299866", brLenTotal: "1.8844" },
  { geneTranscript: "NM_000038.6", parsimonySitesP: "0.144917340837144", constantSitesP: "0.72775237425255", distinctSitesP: "0.168952983937156", brLenTotal: "0.5330" },
  { geneTranscript: "NM_178557.4", parsimonySitesP: "0.15121412803532", constantSitesP: "0.760485651214128", distinctSitesP: "0.253863134657837", brLenTotal: "0.6848" },
  { geneTranscript: "NM_005973.5", parsimonySitesP: "0.130346232179226", constantSitesP: "0.763747454175153", distinctSitesP: "0.196877121520706", brLenTotal: "0.4392" },
  { geneTranscript: "NM_018110.5", parsimonySitesP: "0.140081799591002", constantSitesP: "0.760736196319018", distinctSitesP: "0.215746421267894", brLenTotal: "0.6052" },
  { geneTranscript: "NM_052874.5", parsimonySitesP: "0.125", constantSitesP: "0.818287037037037", distinctSitesP: "0.162037037037037", brLenTotal: "0.4322" },
  { geneTranscript: "NM_199054.3", parsimonySitesP: "0.209318996415771", constantSitesP: "0.69247311827957", distinctSitesP: "0.283870967741935", brLenTotal: "1.1671" },
  { geneTranscript: "NM_001346249.2", parsimonySitesP: "0.113296616837136", constantSitesP: "0.789011277209546", distinctSitesP: "0.142932074482035", brLenTotal: "0.3602" },
  { geneTranscript: "NM_000406.3", parsimonySitesP: "0.242886178861789", constantSitesP: "0.622967479674797", distinctSitesP: "0.33130081300813", brLenTotal: "0.8057" },
  { geneTranscript: "NM_001156.5", parsimonySitesP: "0.157367668097282", constantSitesP: "0.655221745350501", distinctSitesP: "0.309728183118741", brLenTotal: "0.6135" },
  { geneTranscript: "NM_002866.5", parsimonySitesP: "0.231818181818182", constantSitesP: "0.696969696969697", distinctSitesP: "0.284848484848485", brLenTotal: "1.0492" },
  { geneTranscript: "NM_019004.2", parsimonySitesP: "0.109886746250383", constantSitesP: "0.79400061218243", distinctSitesP: "0.156718702173248", brLenTotal: "0.3732" },
  { geneTranscript: "NM_153631.3", parsimonySitesP: "0.17757712565839", constantSitesP: "0.697516930022573", distinctSitesP: "0.26561324303988", brLenTotal: "0.6869" },
  { geneTranscript: "NM_001370464.1", parsimonySitesP: "0.614906832298137", constantSitesP: "0.218426501035197", distinctSitesP: "0.785714285714286", brLenTotal: "3.7478" },
  { geneTranscript: "NM_001010855.4", parsimonySitesP: "0.265694076038904", constantSitesP: "0.584880636604775", distinctSitesP: "0.370468611847922", brLenTotal: "1.0708" },
  { geneTranscript: "NM_001135054.2", parsimonySitesP: "0.279674796747967", constantSitesP: "0.551219512195122", distinctSitesP: "0.419512195121951", brLenTotal: "1.0705" },
  { geneTranscript: "NM_001366481.3", parsimonySitesP: "0.22483660130719", constantSitesP: "0.549019607843137", distinctSitesP: "0.394771241830065", brLenTotal: "1.0386" },
  { geneTranscript: "NM_003299.3", parsimonySitesP: "0.133250311332503", constantSitesP: "0.766708177667082", distinctSitesP: "0.207139892071399", brLenTotal: "0.4944" },
  { geneTranscript: "NM_138425.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.732804232804233", distinctSitesP: "0.267195767195767", brLenTotal: "0.7441" },
  { geneTranscript: "NM_001395002.1", parsimonySitesP: "0.120183044315992", constantSitesP: "0.799373795761079", distinctSitesP: "0.145953757225434", brLenTotal: "0.4248" },
  { geneTranscript: "NM_004451.5", parsimonySitesP: "0.159968479117415", constantSitesP: "0.727344365642238", distinctSitesP: "0.2301024428684", brLenTotal: "0.6636" },
  { geneTranscript: "NM_004423.4", parsimonySitesP: "0.134078212290503", constantSitesP: "0.773743016759777", distinctSitesP: "0.184823091247672", brLenTotal: "0.5601" },
  { geneTranscript: "NM_017448.5", parsimonySitesP: "0.255020080321285", constantSitesP: "0.616465863453815", distinctSitesP: "0.356425702811245", brLenTotal: "1.1635" },
  { geneTranscript: "NM_024574.4", parsimonySitesP: "0.227699530516432", constantSitesP: "0.671361502347418", distinctSitesP: "0.266431924882629", brLenTotal: "0.7602" },
  { geneTranscript: "NM_012278.4", parsimonySitesP: "0.0970220941402498", constantSitesP: "0.792507204610951", distinctSitesP: "0.185398655139289", brLenTotal: "0.3151" },
  { geneTranscript: "NM_145011.4", parsimonySitesP: "0.427631578947368", constantSitesP: "0.440058479532164", distinctSitesP: "0.551900584795322", brLenTotal: "2.2682" },
  { geneTranscript: "NM_001113482.2", parsimonySitesP: "0.150984682713348", constantSitesP: "0.731582786287381", distinctSitesP: "0.238512035010941", brLenTotal: "0.5746" },
  { geneTranscript: "NM_033412.4", parsimonySitesP: "0.336700336700337", constantSitesP: "0.420875420875421", distinctSitesP: "0.537598204264871", brLenTotal: "1.3500" },
  { geneTranscript: "NM_033182.7", parsimonySitesP: "0.169934640522876", constantSitesP: "0.716339869281046", distinctSitesP: "0.252287581699346", brLenTotal: "0.8121" },
  { geneTranscript: "NM_002802.3", parsimonySitesP: "0.149242424242424", constantSitesP: "0.690909090909091", distinctSitesP: "0.275757575757576", brLenTotal: "0.6147" },
  { geneTranscript: "NM_015063.3", parsimonySitesP: "0.155627940644227", constantSitesP: "0.764024610930148", distinctSitesP: "0.186753528773073", brLenTotal: "0.6129" },
  { geneTranscript: "NM_022048.5", parsimonySitesP: "0.102685624012638", constantSitesP: "0.805687203791469", distinctSitesP: "0.173775671406003", brLenTotal: "0.3274" },
  { geneTranscript: "NM_019009.4", parsimonySitesP: "0.22992700729927", constantSitesP: "0.683698296836983", distinctSitesP: "0.300486618004866", brLenTotal: "1.2915" },
  { geneTranscript: "NM_014251.3", parsimonySitesP: "0.153086419753086", constantSitesP: "0.729382716049383", distinctSitesP: "0.231604938271605", brLenTotal: "0.6016" },
  { geneTranscript: "NM_000178.4", parsimonySitesP: "0.172995780590717", constantSitesP: "0.70393811533052", distinctSitesP: "0.243319268635724", brLenTotal: "0.5681" },
  { geneTranscript: "NM_014412.3", parsimonySitesP: "0.175438596491228", constantSitesP: "0.662280701754386", distinctSitesP: "0.339181286549708", brLenTotal: "0.6243" },
  { geneTranscript: "NM_004935.4", parsimonySitesP: "0.15296803652968", constantSitesP: "0.78310502283105", distinctSitesP: "0.212328767123288", brLenTotal: "0.5317" },
  { geneTranscript: "NM_172230.3", parsimonySitesP: "0.158833063209076", constantSitesP: "0.725553754727175", distinctSitesP: "0.217720151269584", brLenTotal: "0.6211" },
  { geneTranscript: "NM_002959.7", parsimonySitesP: "0.154031287605295", constantSitesP: "0.746490172482952", distinctSitesP: "0.209787404733253", brLenTotal: "0.5179" },
  { geneTranscript: "NM_019849.3", parsimonySitesP: "0.206500956022945", constantSitesP: "0.695984703632887", distinctSitesP: "0.282982791586998", brLenTotal: "0.8520" },
  { geneTranscript: "NM_001144382.2", parsimonySitesP: "0.262644188110027", constantSitesP: "0.63117420881396", distinctSitesP: "0.204081632653061", brLenTotal: "1.0704" },
  { geneTranscript: "NM_001388303.1", parsimonySitesP: "0.150973291081938", constantSitesP: "0.764523917307982", distinctSitesP: "0.157461898294854", brLenTotal: "0.6291" },
  { geneTranscript: "NM_001256404.2", parsimonySitesP: "0.193965517241379", constantSitesP: "0.660560344827586", distinctSitesP: "0.316810344827586", brLenTotal: "0.6435" },
  { geneTranscript: "NM_001029859.3", parsimonySitesP: "0.137179487179487", constantSitesP: "0.780769230769231", distinctSitesP: "0.2", brLenTotal: "0.5669" },
  { geneTranscript: "NM_003875.3", parsimonySitesP: "0.116402116402116", constantSitesP: "0.769119769119769", distinctSitesP: "0.185185185185185", brLenTotal: "0.4101" },
  { geneTranscript: "NM_001316943.2", parsimonySitesP: "0.173913043478261", constantSitesP: "0.694616977225673", distinctSitesP: "0.25776397515528", brLenTotal: "0.5911" },
  { geneTranscript: "NM_001741.3", parsimonySitesP: "0.321513002364066", constantSitesP: "0.515366430260047", distinctSitesP: "0.505910165484634", brLenTotal: "1.0919" },
  { geneTranscript: "NM_005186.4", parsimonySitesP: "0.19234360410831", constantSitesP: "0.721755368814192", distinctSitesP: "0.250700280112045", brLenTotal: "0.8203" },
  { geneTranscript: "NM_020127.3", parsimonySitesP: "0.21025641025641", constantSitesP: "0.635897435897436", distinctSitesP: "0.317948717948718", brLenTotal: "0.7520" },
  { geneTranscript: "NM_031263.4", parsimonySitesP: "0.0883620689655172", constantSitesP: "0.805316091954023", distinctSitesP: "0.188218390804598", brLenTotal: "0.3355" },
  { geneTranscript: "NM_014828.4", parsimonySitesP: "0.14975845410628", constantSitesP: "0.736983360171766", distinctSitesP: "0.22061191626409", brLenTotal: "0.5431" },
  { geneTranscript: "NM_001271749.2", parsimonySitesP: "0.365974282888229", constantSitesP: "0.472799208704253", distinctSitesP: "0.481701285855589", brLenTotal: "1.7177" },
  { geneTranscript: "NM_015542.4", parsimonySitesP: "0.12185534591195", constantSitesP: "0.790356394129979", distinctSitesP: "0.164308176100629", brLenTotal: "0.4270" },
  { geneTranscript: "NM_014675.5", parsimonySitesP: "0.249710791604693", constantSitesP: "0.572467360766815", distinctSitesP: "0.314658734093538", brLenTotal: "1.1279" },
  { geneTranscript: "NM_145037.4", parsimonySitesP: "0.175909361955874", constantSitesP: "0.666666666666667", distinctSitesP: "0.290995825879547", brLenTotal: "0.6337" },
  { geneTranscript: "NM_178581.3", parsimonySitesP: "0.132237871674491", constantSitesP: "0.788732394366197", distinctSitesP: "0.195618153364632", brLenTotal: "0.4437" },
  { geneTranscript: "NM_178450.5", parsimonySitesP: "0.0895915678524374", constantSitesP: "0.83399209486166", distinctSitesP: "0.150197628458498", brLenTotal: "0.3140" },
  { geneTranscript: "NM_016441.3", parsimonySitesP: "0.153474903474903", constantSitesP: "0.755148005148005", distinctSitesP: "0.192728442728443", brLenTotal: "0.4869" },
  { geneTranscript: "NM_018917.4", parsimonySitesP: "0.402286902286902", constantSitesP: "0.477477477477477", distinctSitesP: "0.489258489258489", brLenTotal: "2.6447" },
  { geneTranscript: "NM_030667.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.726699561403509", distinctSitesP: "0.21984649122807", brLenTotal: "0.6289" },
  { geneTranscript: "NM_017966.5", parsimonySitesP: "0.284507042253521", constantSitesP: "0.540845070422535", distinctSitesP: "0.402816901408451", brLenTotal: "1.2699" },
  { geneTranscript: "NM_001008395.4", parsimonySitesP: "0.131313131313131", constantSitesP: "0.771043771043771", distinctSitesP: "0.232323232323232", brLenTotal: "0.5625" },
  { geneTranscript: "NM_020805.3", parsimonySitesP: "0.116772823779193", constantSitesP: "0.822186836518047", distinctSitesP: "0.16348195329087", brLenTotal: "0.3658" },
  { geneTranscript: "NM_004368.4", parsimonySitesP: "0.312837108953614", constantSitesP: "0.485436893203883", distinctSitesP: "0.473570658036677", brLenTotal: "1.6734" },
  { geneTranscript: "NM_015374.3", parsimonySitesP: "0.223152022315202", constantSitesP: "0.643421664342166", distinctSitesP: "0.311018131101813", brLenTotal: "1.0232" },
  { geneTranscript: "NM_001678.5", parsimonySitesP: "0.126436781609195", constantSitesP: "0.798850574712644", distinctSitesP: "0.190804597701149", brLenTotal: "0.4312" },
  { geneTranscript: "NM_002205.5", parsimonySitesP: "0.185891325071497", constantSitesP: "0.682872577057515", distinctSitesP: "0.23006037496028", brLenTotal: "0.6237" },
  { geneTranscript: "NM_000543.5", parsimonySitesP: "0.253037506603275", constantSitesP: "0.628631801373481", distinctSitesP: "0.338615953512942", brLenTotal: "0.9439" },
  { geneTranscript: "NM_003995.4", parsimonySitesP: "0.10792741165234", constantSitesP: "0.806749442852595", distinctSitesP: "0.140401146131805", brLenTotal: "0.3491" },
  { geneTranscript: "NM_020959.3", parsimonySitesP: "0.230790043290043", constantSitesP: "0.65232683982684", distinctSitesP: "0.28030303030303", brLenTotal: "1.0898" },
  { geneTranscript: "NM_032311.5", parsimonySitesP: "0.187648456057007", constantSitesP: "0.702296120348377", distinctSitesP: "0.2541567695962", brLenTotal: "0.5887" },
  { geneTranscript: "NM_005684.5", parsimonySitesP: "0.120036934441367", constantSitesP: "0.787626962142198", distinctSitesP: "0.181902123730379", brLenTotal: "0.3820" },
  { geneTranscript: "NM_001004726.1", parsimonySitesP: "0.689617486338798", constantSitesP: "0.212021857923497", distinctSitesP: "0.797814207650273", brLenTotal: "4.5673" },
  { geneTranscript: "NM_012425.4", parsimonySitesP: "0.181708784596871", constantSitesP: "0.753309265944645", distinctSitesP: "0.250300842358604", brLenTotal: "0.6367" },
  { geneTranscript: "NM_004405.4", parsimonySitesP: "0.169715447154472", constantSitesP: "0.739837398373984", distinctSitesP: "0.244918699186992", brLenTotal: "0.5638" },
  { geneTranscript: "NM_005122.5", parsimonySitesP: "0.240421455938697", constantSitesP: "0.594827586206897", distinctSitesP: "0.346743295019157", brLenTotal: "0.8052" },
  { geneTranscript: "NM_015087.5", parsimonySitesP: "0.193193193193193", constantSitesP: "0.674674674674675", distinctSitesP: "0.275275275275275", brLenTotal: "0.6976" },
  { geneTranscript: "NM_005332.3", parsimonySitesP: "0.213615023474178", constantSitesP: "0.612676056338028", distinctSitesP: "0.363849765258216", brLenTotal: "0.9332" },
  { geneTranscript: "NM_001717.4", parsimonySitesP: "0.206237424547284", constantSitesP: "0.647551978537894", distinctSitesP: "0.276324614352783", brLenTotal: "0.6902" },
  { geneTranscript: "NM_014971.2", parsimonySitesP: "0.154630762953896", constantSitesP: "0.765809873521012", distinctSitesP: "0.203998368013056", brLenTotal: "0.6358" },
  { geneTranscript: "NM_024040.3", parsimonySitesP: "0.144018583042973", constantSitesP: "0.717770034843206", distinctSitesP: "0.249709639953542", brLenTotal: "0.6055" },
  { geneTranscript: "NM_001143936.2", parsimonySitesP: "0.165605095541401", constantSitesP: "0.645435244161359", distinctSitesP: "0.290870488322718", brLenTotal: "0.6178" },
  { geneTranscript: "NM_152731.3", parsimonySitesP: "0.150537634408602", constantSitesP: "0.716845878136201", distinctSitesP: "0.268817204301075", brLenTotal: "0.5185" },
  { geneTranscript: "NM_005171.5", parsimonySitesP: "0.163591635916359", constantSitesP: "0.683886838868389", distinctSitesP: "0.290282902829028", brLenTotal: "0.6140" },
  { geneTranscript: "NM_022101.4", parsimonySitesP: "0.163663663663664", constantSitesP: "0.719219219219219", distinctSitesP: "0.271771771771772", brLenTotal: "0.6077" },
  { geneTranscript: "NM_016201.4", parsimonySitesP: "0.233333333333333", constantSitesP: "0.618376068376068", distinctSitesP: "0.297008547008547", brLenTotal: "0.8953" },
  { geneTranscript: "NM_018993.4", parsimonySitesP: "0.187709497206704", constantSitesP: "0.694227188081937", distinctSitesP: "0.252886405959032", brLenTotal: "0.6580" },
  { geneTranscript: "NM_020804.5", parsimonySitesP: "0.156906906906907", constantSitesP: "0.758258258258258", distinctSitesP: "0.211711711711712", brLenTotal: "0.6139" },
  { geneTranscript: "NM_006645.3", parsimonySitesP: "0.143184421534937", constantSitesP: "0.764032073310424", distinctSitesP: "0.219931271477663", brLenTotal: "0.4758" },
  { geneTranscript: "NM_018116.4", parsimonySitesP: "0.252631578947368", constantSitesP: "0.581286549707602", distinctSitesP: "0.395321637426901", brLenTotal: "0.9060" },
  { geneTranscript: "NM_014285.7", parsimonySitesP: "0.169510807736064", constantSitesP: "0.729237770193402", distinctSitesP: "0.25028441410694", brLenTotal: "0.6038" },
  { geneTranscript: "NM_001042646.3", parsimonySitesP: "0.181532004197272", constantSitesP: "0.720531654424624", distinctSitesP: "0.229450856942987", brLenTotal: "0.7596" },
  { geneTranscript: "NM_024646.3", parsimonySitesP: "0.109318996415771", constantSitesP: "0.812275985663082", distinctSitesP: "0.148745519713262", brLenTotal: "0.3298" },
  { geneTranscript: "NM_001082967.3", parsimonySitesP: "0.136363636363636", constantSitesP: "0.79040404040404", distinctSitesP: "0.227272727272727", brLenTotal: "0.6023" },
  { geneTranscript: "NM_013340.4", parsimonySitesP: "0.222493887530562", constantSitesP: "0.606764466177669", distinctSitesP: "0.318663406682967", brLenTotal: "0.7362" },
  { geneTranscript: "NM_012152.3", parsimonySitesP: "0.192634560906516", constantSitesP: "0.704438149197356", distinctSitesP: "0.298394711992446", brLenTotal: "0.7119" },
  { geneTranscript: "NM_001378785.1", parsimonySitesP: "0.140350877192982", constantSitesP: "0.739612188365651", distinctSitesP: "0.253000923361034", brLenTotal: "0.4450" },
  { geneTranscript: "NM_000633.3", parsimonySitesP: "0.131101813110181", constantSitesP: "0.774058577405858", distinctSitesP: "0.227336122733612", brLenTotal: "0.4590" },
  { geneTranscript: "NM_022918.4", parsimonySitesP: "0.13391557496361", constantSitesP: "0.759097525473071", distinctSitesP: "0.23216885007278", brLenTotal: "0.3984" },
  { geneTranscript: "NM_181332.3", parsimonySitesP: "0.32312091503268", constantSitesP: "0.581290849673203", distinctSitesP: "0.370506535947712", brLenTotal: "1.7254" },
  { geneTranscript: "NM_018325.5", parsimonySitesP: "0.125433125433125", constantSitesP: "0.792099792099792", distinctSitesP: "0.207900207900208", brLenTotal: "0.4039" },
  { geneTranscript: "NM_001101662.2", parsimonySitesP: "0.132638285548798", constantSitesP: "0.761366927309586", distinctSitesP: "0.193165363452071", brLenTotal: "0.4331" },
  { geneTranscript: "NM_181303.2", parsimonySitesP: "0.104952830188679", constantSitesP: "0.84001572327044", distinctSitesP: "0.122641509433962", brLenTotal: "0.2971" },
  { geneTranscript: "NM_012443.4", parsimonySitesP: "0.166994106090373", constantSitesP: "0.731499672560576", distinctSitesP: "0.222658808120498", brLenTotal: "0.4921" },
  { geneTranscript: "NM_144686.4", parsimonySitesP: "0.298394711992446", constantSitesP: "0.518885741265345", distinctSitesP: "0.422096317280453", brLenTotal: "1.0866" },
  { geneTranscript: "NM_144658.4", parsimonySitesP: "0.123492522913652", constantSitesP: "0.779707348448304", distinctSitesP: "0.164495899662325", brLenTotal: "0.4059" },
  { geneTranscript: "NM_181553.4", parsimonySitesP: "0.186813186813187", constantSitesP: "0.695970695970696", distinctSitesP: "0.318681318681319", brLenTotal: "0.8243" },
  { geneTranscript: "NM_002449.5", parsimonySitesP: "0.212234706616729", constantSitesP: "0.670411985018727", distinctSitesP: "0.314606741573034", brLenTotal: "0.9233" },
  { geneTranscript: "NM_003022.3", parsimonySitesP: "0.134502923976608", constantSitesP: "0.748538011695906", distinctSitesP: "0.269005847953216", brLenTotal: "0.4291" },
  { geneTranscript: "NM_032785.4", parsimonySitesP: "0.171636845593108", constantSitesP: "0.731610337972167", distinctSitesP: "0.230616302186879", brLenTotal: "0.5374" },
  { geneTranscript: "NM_002478.5", parsimonySitesP: "0.196875", constantSitesP: "0.658333333333333", distinctSitesP: "0.29375", brLenTotal: "0.7618" },
  { geneTranscript: "NM_000208.4", parsimonySitesP: "0.222383019778099", constantSitesP: "0.668837433671008", distinctSitesP: "0.258321273516643", brLenTotal: "0.9845" },
  { geneTranscript: "NM_198993.5", parsimonySitesP: "0.184103811841038", constantSitesP: "0.699107866991079", distinctSitesP: "0.271695052716951", brLenTotal: "0.6958" },
  { geneTranscript: "NM_001282717.2", parsimonySitesP: "0.227569331158238", constantSitesP: "0.634040239260468", distinctSitesP: "0.312941816204459", brLenTotal: "0.7812" },
  { geneTranscript: "NM_006364.4", parsimonySitesP: "0.144226579520697", constantSitesP: "0.749455337690632", distinctSitesP: "0.201742919389978", brLenTotal: "0.5047" },
  { geneTranscript: "NM_001070.5", parsimonySitesP: "0.141906873614191", constantSitesP: "0.753141167775314", distinctSitesP: "0.205469327420547", brLenTotal: "0.5500" },
  { geneTranscript: "NM_002598.4", parsimonySitesP: "0.261627906976744", constantSitesP: "0.525193798449612", distinctSitesP: "0.426356589147287", brLenTotal: "1.0046" },
  { geneTranscript: "NM_030640.3", parsimonySitesP: "0.1734335839599", constantSitesP: "0.724812030075188", distinctSitesP: "0.220551378446115", brLenTotal: "0.6821" },
  { geneTranscript: "NM_012421.4", parsimonySitesP: "0.129049111807732", constantSitesP: "0.755485893416928", distinctSitesP: "0.150470219435737", brLenTotal: "0.4317" },
  { geneTranscript: "NM_013337.4", parsimonySitesP: "0.219931271477663", constantSitesP: "0.65807560137457", distinctSitesP: "0.328178694158076", brLenTotal: "0.7323" },
  { geneTranscript: "NM_005466.4", parsimonySitesP: "0.131436314363144", constantSitesP: "0.764227642276423", distinctSitesP: "0.224932249322493", brLenTotal: "0.4680" },
  { geneTranscript: "NM_004849.4", parsimonySitesP: "0.0896969696969697", constantSitesP: "0.83030303030303", distinctSitesP: "0.158787878787879", brLenTotal: "0.3097" },
  { geneTranscript: "NM_001304833.2", parsimonySitesP: "0.280952380952381", constantSitesP: "0.597142857142857", distinctSitesP: "0.352380952380952", brLenTotal: "1.1062" },
  { geneTranscript: "NM_001395548.1", parsimonySitesP: "0.244338498212157", constantSitesP: "0.621771950735002", distinctSitesP: "0.33611442193087", brLenTotal: "0.8709" },
  { geneTranscript: "NM_001015877.2", parsimonySitesP: "0.0721461187214612", constantSitesP: "0.873059360730594", distinctSitesP: "0.114155251141553", brLenTotal: "0.2069" },
  { geneTranscript: "NM_032182.4", parsimonySitesP: "0.151807228915663", constantSitesP: "0.749397590361446", distinctSitesP: "0.220080321285141", brLenTotal: "0.5272" },
  { geneTranscript: "NM_001004312.2", parsimonySitesP: "0.299259259259259", constantSitesP: "0.555555555555556", distinctSitesP: "0.432592592592593", brLenTotal: "1.7658" },
  { geneTranscript: "NM_001385503.1", parsimonySitesP: "0.148006379585327", constantSitesP: "0.726634768740032", distinctSitesP: "0.209888357256778", brLenTotal: "0.4863" },
  { geneTranscript: "NM_014424.5", parsimonySitesP: "0.209803921568627", constantSitesP: "0.717647058823529", distinctSitesP: "0.296078431372549", brLenTotal: "0.9587" },
  { geneTranscript: "NM_004925.5", parsimonySitesP: "0.188356164383562", constantSitesP: "0.731735159817352", distinctSitesP: "0.247716894977169", brLenTotal: "0.6671" },
  { geneTranscript: "NM_016041.5", parsimonySitesP: "0.132496513249651", constantSitesP: "0.781032078103208", distinctSitesP: "0.188284518828452", brLenTotal: "0.4121" },
  { geneTranscript: "NM_014160.5", parsimonySitesP: "0.226762820512821", constantSitesP: "0.634615384615385", distinctSitesP: "0.342147435897436", brLenTotal: "0.8630" },
  { geneTranscript: "NM_002231.4", parsimonySitesP: "0.274656679151061", constantSitesP: "0.595505617977528", distinctSitesP: "0.380774032459426", brLenTotal: "1.1688" },
  { geneTranscript: "NM_024669.3", parsimonySitesP: "0.217698154180239", constantSitesP: "0.636264929424539", distinctSitesP: "0.327904451682953", brLenTotal: "0.7816" },
  { geneTranscript: "NM_144599.5", parsimonySitesP: "0.163120567375887", constantSitesP: "0.768996960486322", distinctSitesP: "0.24113475177305", brLenTotal: "0.8053" },
  { geneTranscript: "NM_016485.5", parsimonySitesP: "0.161780673181325", constantSitesP: "0.742671009771987", distinctSitesP: "0.250814332247557", brLenTotal: "0.5228" },
  { geneTranscript: "NM_001394410.1", parsimonySitesP: "0.120634920634921", constantSitesP: "0.807936507936508", distinctSitesP: "0.192063492063492", brLenTotal: "0.4248" },
  { geneTranscript: "NM_020765.3", parsimonySitesP: "0.145218342015564", constantSitesP: "0.758762621390443", distinctSitesP: "0.148048105987523", brLenTotal: "0.5391" },
  { geneTranscript: "NM_180982.3", parsimonySitesP: "0.23224043715847", constantSitesP: "0.601092896174863", distinctSitesP: "0.407103825136612", brLenTotal: "0.6904" },
  { geneTranscript: "NM_024571.4", parsimonySitesP: "0.16260162601626", constantSitesP: "0.680216802168022", distinctSitesP: "0.2710027100271", brLenTotal: "0.6869" },
  { geneTranscript: "NM_203414.3", parsimonySitesP: "0.266666666666667", constantSitesP: "0.538888888888889", distinctSitesP: "0.405555555555556", brLenTotal: "0.9551" },
  { geneTranscript: "NM_014601.4", parsimonySitesP: "0.298956414978514", constantSitesP: "0.604051565377532", distinctSitesP: "0.335788827501535", brLenTotal: "1.5434" },
  { geneTranscript: "NM_001008220.2", parsimonySitesP: "0.174129353233831", constantSitesP: "0.761194029850746", distinctSitesP: "0.243781094527363", brLenTotal: "0.8804" },
  { geneTranscript: "NM_001321439.2", parsimonySitesP: "0.263713080168776", constantSitesP: "0.612869198312236", distinctSitesP: "0.371308016877637", brLenTotal: "1.1335" },
  { geneTranscript: "NM_005806.4", parsimonySitesP: "0.183694530443756", constantSitesP: "0.724458204334365", distinctSitesP: "0.265221878224974", brLenTotal: "0.8374" },
  { geneTranscript: "NM_012233.3", parsimonySitesP: "0.143391097519538", constantSitesP: "0.746856948691811", distinctSitesP: "0.200135915732246", brLenTotal: "0.4522" },
  { geneTranscript: "NM_005094.4", parsimonySitesP: "0.224468636599274", constantSitesP: "0.621047174701918", distinctSitesP: "0.290305857957491", brLenTotal: "0.9815" },
  { geneTranscript: "NM_005309.3", parsimonySitesP: "0.270833333333333", constantSitesP: "0.606182795698925", distinctSitesP: "0.331989247311828", brLenTotal: "1.2872" },
  { geneTranscript: "NM_014320.3", parsimonySitesP: "0.226016260162602", constantSitesP: "0.639024390243902", distinctSitesP: "0.357723577235772", brLenTotal: "0.6606" },
  { geneTranscript: "NM_004436.4", parsimonySitesP: "0.121212121212121", constantSitesP: "0.691460055096419", distinctSitesP: "0.297520661157025", brLenTotal: "0.5148" },
  { geneTranscript: "NM_181723.3", parsimonySitesP: "0.158490566037736", constantSitesP: "0.732704402515723", distinctSitesP: "0.264779874213836", brLenTotal: "0.5340" },
  { geneTranscript: "NM_014153.4", parsimonySitesP: "0.132166151733608", constantSitesP: "0.774802608994164", distinctSitesP: "0.193614830072091", brLenTotal: "0.4541" },
  { geneTranscript: "NM_014735.5", parsimonySitesP: "0.194005670311867", constantSitesP: "0.667881733495342", distinctSitesP: "0.270149858242203", brLenTotal: "0.6467" },
  { geneTranscript: "NM_024686.6", parsimonySitesP: "0.163096580232995", constantSitesP: "0.73130402104472", distinctSitesP: "0.234122510334461", brLenTotal: "0.5274" },
  { geneTranscript: "NM_001694.4", parsimonySitesP: "0.189247311827957", constantSitesP: "0.701075268817204", distinctSitesP: "0.281720430107527", brLenTotal: "0.7775" },
  { geneTranscript: "NM_003525.3", parsimonySitesP: "0.19047619047619", constantSitesP: "0.685185185185185", distinctSitesP: "0.306878306878307", brLenTotal: "1.3193" },
  { geneTranscript: "NM_001971.6", parsimonySitesP: "0.24031007751938", constantSitesP: "0.616279069767442", distinctSitesP: "0.363049095607235", brLenTotal: "0.7940" },
  { geneTranscript: "NM_153045.4", parsimonySitesP: "0.208576998050682", constantSitesP: "0.651072124756335", distinctSitesP: "0.313840155945419", brLenTotal: "0.8472" },
  { geneTranscript: "NM_001139456.2", parsimonySitesP: "0.235772357723577", constantSitesP: "0.645663956639566", distinctSitesP: "0.350948509485095", brLenTotal: "0.9713" },
  { geneTranscript: "NM_001025091.2", parsimonySitesP: "0.193293885601578", constantSitesP: "0.700591715976331", distinctSitesP: "0.266666666666667", brLenTotal: "0.7347" },
  { geneTranscript: "NM_001376937.1", parsimonySitesP: "0.294117647058824", constantSitesP: "0.656862745098039", distinctSitesP: "0.340686274509804", brLenTotal: "2.1684" },
  { geneTranscript: "NM_007231.5", parsimonySitesP: "0.159916926272066", constantSitesP: "0.729491173416407", distinctSitesP: "0.2466251298027", brLenTotal: "0.5215" },
  { geneTranscript: "NM_004707.4", parsimonySitesP: "0.216666666666667", constantSitesP: "0.676190476190476", distinctSitesP: "0.347619047619048", brLenTotal: "0.8341" },
  { geneTranscript: "NM_001199799.2", parsimonySitesP: "0.242368742368742", constantSitesP: "0.6001221001221", distinctSitesP: "0.334554334554335", brLenTotal: "0.8832" },
  { geneTranscript: "NM_024527.5", parsimonySitesP: "0.223993925588459", constantSitesP: "0.66211085801063", distinctSitesP: "0.292331055429005", brLenTotal: "1.0112" },
  { geneTranscript: "NM_033054.3", parsimonySitesP: "0.213490504256713", constantSitesP: "0.668631303208906", distinctSitesP: "0.262933857236411", brLenTotal: "0.9446" },
  { geneTranscript: "NM_004956.5", parsimonySitesP: "0.0908455625436758", constantSitesP: "0.839972047519217", distinctSitesP: "0.146051712089448", brLenTotal: "0.3046" },
  { geneTranscript: "NM_001083601.3", parsimonySitesP: "0.134986225895317", constantSitesP: "0.767217630853994", distinctSitesP: "0.21900826446281", brLenTotal: "0.5100" },
  { geneTranscript: "NM_025069.3", parsimonySitesP: "0.151977401129944", constantSitesP: "0.750847457627119", distinctSitesP: "0.224293785310734", brLenTotal: "0.6453" },
  { geneTranscript: "NM_173834.4", parsimonySitesP: "0.172316384180791", constantSitesP: "0.716101694915254", distinctSitesP: "0.259887005649718", brLenTotal: "0.5711" },
  { geneTranscript: "NM_145253.3", parsimonySitesP: "0.141242937853107", constantSitesP: "0.753295668549906", distinctSitesP: "0.24105461393597", brLenTotal: "0.6630" },
  { geneTranscript: "NM_012334.3", parsimonySitesP: "0.184159378036929", constantSitesP: "0.719468739876903", distinctSitesP: "0.227243278263686", brLenTotal: "0.7688" },
  { geneTranscript: "NM_003102.4", parsimonySitesP: "0.288888888888889", constantSitesP: "0.540277777777778", distinctSitesP: "0.45", brLenTotal: "1.0901" },
  { geneTranscript: "NM_006473.4", parsimonySitesP: "0.210075026795284", constantSitesP: "0.673633440514469", distinctSitesP: "0.265809217577706", brLenTotal: "0.7552" },
  { geneTranscript: "NM_014112.5", parsimonySitesP: "0.117465224111283", constantSitesP: "0.782586295723854", distinctSitesP: "0.152498712004122", brLenTotal: "0.4316" },
  { geneTranscript: "NM_015630.4", parsimonySitesP: "0.0652622883106154", constantSitesP: "0.861214374225527", distinctSitesP: "0.115654688145394", brLenTotal: "0.2278" },
  { geneTranscript: "NM_004164.3", parsimonySitesP: "0.238805970149254", constantSitesP: "0.646766169154229", distinctSitesP: "0.390547263681592", brLenTotal: "0.7989" },
  { geneTranscript: "NM_000082.4", parsimonySitesP: "0.13047138047138", constantSitesP: "0.747474747474747", distinctSitesP: "0.245791245791246", brLenTotal: "0.4235" },
  { geneTranscript: "NM_005850.5", parsimonySitesP: "0.17374213836478", constantSitesP: "0.627358490566038", distinctSitesP: "0.315251572327044", brLenTotal: "0.6945" },
  { geneTranscript: "NM_001172.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.689265536723164", distinctSitesP: "0.25894538606403", brLenTotal: "0.5661" },
  { geneTranscript: "NM_006494.4", parsimonySitesP: "0.171532846715328", constantSitesP: "0.750608272506083", distinctSitesP: "0.228102189781022", brLenTotal: "0.8161" },
  { geneTranscript: "NM_021137.5", parsimonySitesP: "0.206751054852321", constantSitesP: "0.706751054852321", distinctSitesP: "0.25210970464135", brLenTotal: "0.8032" },
  { geneTranscript: "NM_014384.3", parsimonySitesP: "0.216064257028112", constantSitesP: "0.669076305220884", distinctSitesP: "0.313253012048193", brLenTotal: "0.7776" },
  { geneTranscript: "NM_003655.3", parsimonySitesP: "0.203571428571429", constantSitesP: "0.69702380952381", distinctSitesP: "0.275595238095238", brLenTotal: "0.8600" },
  { geneTranscript: "NM_001329630.2", parsimonySitesP: "0.171518489378442", constantSitesP: "0.705218987673748", distinctSitesP: "0.21767637031209", brLenTotal: "0.7360" },
  { geneTranscript: "NM_006151.3", parsimonySitesP: "0.267790262172285", constantSitesP: "0.550093632958801", distinctSitesP: "0.393258426966292", brLenTotal: "1.1860" },
  { geneTranscript: "NM_000258.3", parsimonySitesP: "0.196581196581197", constantSitesP: "0.663247863247863", distinctSitesP: "0.31965811965812", brLenTotal: "0.9632" },
  { geneTranscript: "NM_004565.3", parsimonySitesP: "0.163572060123784", constantSitesP: "0.732979664014147", distinctSitesP: "0.247568523430592", brLenTotal: "0.7441" },
  { geneTranscript: "NM_021163.4", parsimonySitesP: "0.273576097105509", constantSitesP: "0.612978524743231", distinctSitesP: "0.338935574229692", brLenTotal: "0.9801" },
  { geneTranscript: "NM_001101362.3", parsimonySitesP: "0.2627365356623", constantSitesP: "0.584425036390102", distinctSitesP: "0.332605531295488", brLenTotal: "1.1312" },
  { geneTranscript: "NM_133639.4", parsimonySitesP: "0.132768361581921", constantSitesP: "0.757062146892655", distinctSitesP: "0.224576271186441", brLenTotal: "0.4843" },
  { geneTranscript: "NM_001252507.3", parsimonySitesP: "0.200803212851406", constantSitesP: "0.694779116465863", distinctSitesP: "0.329317269076305", brLenTotal: "0.8787" },
  { geneTranscript: "NM_020830.5", parsimonySitesP: "0.164227642276423", constantSitesP: "0.744715447154472", distinctSitesP: "0.235772357723577", brLenTotal: "0.6330" },
  { geneTranscript: "NM_001875.5", parsimonySitesP: "0.164", constantSitesP: "0.730666666666667", distinctSitesP: "0.226888888888889", brLenTotal: "0.5766" },
  { geneTranscript: "NM_002829.4", parsimonySitesP: "0.157356699525374", constantSitesP: "0.752464403066813", distinctSitesP: "0.233296823658269", brLenTotal: "0.5502" },
  { geneTranscript: "NM_153374.3", parsimonySitesP: "0.167441860465116", constantSitesP: "0.671317829457364", distinctSitesP: "0.330232558139535", brLenTotal: "0.5568" },
  { geneTranscript: "NM_005334.3", parsimonySitesP: "0.204914004914005", constantSitesP: "0.706470106470106", distinctSitesP: "0.224897624897625", brLenTotal: "0.8659" },
  { geneTranscript: "NM_001001912.3", parsimonySitesP: "0.463258785942492", constantSitesP: "0.345047923322684", distinctSitesP: "0.613418530351438", brLenTotal: "2.5656" },
  { geneTranscript: "NM_177964.5", parsimonySitesP: "0.288244766505636", constantSitesP: "0.552334943639291", distinctSitesP: "0.444444444444444", brLenTotal: "1.0602" },
  { geneTranscript: "NM_177550.5", parsimonySitesP: "0.222417840375587", constantSitesP: "0.636737089201878", distinctSitesP: "0.326291079812207", brLenTotal: "0.8635" },
  { geneTranscript: "NM_145185.4", parsimonySitesP: "0.145584725536993", constantSitesP: "0.780429594272076", distinctSitesP: "0.195704057279236", brLenTotal: "0.7370" },
  { geneTranscript: "NM_001270764.2", parsimonySitesP: "0.200831847890671", constantSitesP: "0.685680332739156", distinctSitesP: "0.26797385620915", brLenTotal: "0.7558" },
  { geneTranscript: "NM_001365068.1", parsimonySitesP: "0.153348269853124", constantSitesP: "0.750809061488673", distinctSitesP: "0.199402539208364", brLenTotal: "0.6238" },
  { geneTranscript: "NM_003981.4", parsimonySitesP: "0.231720430107527", constantSitesP: "0.625268817204301", distinctSitesP: "0.329032258064516", brLenTotal: "0.7803" },
  { geneTranscript: "NM_004582.4", parsimonySitesP: "0.14098690835851", constantSitesP: "0.75730110775428", distinctSitesP: "0.236656596173212", brLenTotal: "0.5071" },
  { geneTranscript: "NM_054012.4", parsimonySitesP: "0.18042071197411", constantSitesP: "0.673139158576052", distinctSitesP: "0.301779935275081", brLenTotal: "0.8654" },
  { geneTranscript: "NM_001322917.1", parsimonySitesP: "0.3086038124678", constantSitesP: "0.515713549716641", distinctSitesP: "0.3827923750644", brLenTotal: "1.2310" },
  { geneTranscript: "NM_016401.4", parsimonySitesP: "0.0913705583756345", constantSitesP: "0.810490693739425", distinctSitesP: "0.182741116751269", brLenTotal: "0.3225" },
  { geneTranscript: "NM_004267.5", parsimonySitesP: "0.190566037735849", constantSitesP: "0.682389937106918", distinctSitesP: "0.272955974842767", brLenTotal: "0.7563" },
  { geneTranscript: "NM_001005518.1", parsimonySitesP: "0.552350427350427", constantSitesP: "0.348290598290598", distinctSitesP: "0.663461538461538", brLenTotal: "3.6455" },
  { geneTranscript: "NM_032293.5", parsimonySitesP: "0.14642974662718", constantSitesP: "0.741362290227048", distinctSitesP: "0.197104310628496", brLenTotal: "0.5533" },
  { geneTranscript: "NM_001105203.2", parsimonySitesP: "0.228011825572801", constantSitesP: "0.631929046563193", distinctSitesP: "0.312269031781227", brLenTotal: "0.7837" },
  { geneTranscript: "NM_012476.3", parsimonySitesP: "0.206896551724138", constantSitesP: "0.642528735632184", distinctSitesP: "0.336781609195402", brLenTotal: "0.8324" },
  { geneTranscript: "NM_018259.6", parsimonySitesP: "0.127081507449606", constantSitesP: "0.773590417762197", distinctSitesP: "0.176161262050833", brLenTotal: "0.4571" },
  { geneTranscript: "NM_001388459.1", parsimonySitesP: "0.160757831551304", constantSitesP: "0.728006002626149", distinctSitesP: "0.201650722190959", brLenTotal: "0.5697" },
  { geneTranscript: "NM_023002.3", parsimonySitesP: "0.261194029850746", constantSitesP: "0.632669983416252", distinctSitesP: "0.323383084577114", brLenTotal: "1.0498" },
  { geneTranscript: "NM_017877.4", parsimonySitesP: "0.191374663072776", constantSitesP: "0.689128481581312", distinctSitesP: "0.272237196765499", brLenTotal: "0.8973" },
  { geneTranscript: "NM_023110.3", parsimonySitesP: "0.178021086780211", constantSitesP: "0.752230332522303", distinctSitesP: "0.207218167072182", brLenTotal: "0.7817" },
  { geneTranscript: "NM_000203.5", parsimonySitesP: "0.309851965288412", constantSitesP: "0.544155181214906", distinctSitesP: "0.42930066360388", brLenTotal: "1.4186" },
  { geneTranscript: "NM_015544.3", parsimonySitesP: "0.150442477876106", constantSitesP: "0.762536873156342", distinctSitesP: "0.231563421828909", brLenTotal: "0.5291" },
  { geneTranscript: "NM_001084.5", parsimonySitesP: "0.214543812104788", constantSitesP: "0.654471544715447", distinctSitesP: "0.299006323396567", brLenTotal: "0.8780" },
  { geneTranscript: "NM_019091.4", parsimonySitesP: "0.132222222222222", constantSitesP: "0.748888888888889", distinctSitesP: "0.224444444444444", brLenTotal: "0.4122" },
  { geneTranscript: "NM_013348.4", parsimonySitesP: "0.176605504587156", constantSitesP: "0.664373088685015", distinctSitesP: "0.259174311926606", brLenTotal: "0.8483" },
  { geneTranscript: "NM_001981.3", parsimonySitesP: "0.149925595238095", constantSitesP: "0.738095238095238", distinctSitesP: "0.206845238095238", brLenTotal: "0.4428" },
  { geneTranscript: "NM_001933.5", parsimonySitesP: "0.154525386313466", constantSitesP: "0.724797645327447", distinctSitesP: "0.245768947755703", brLenTotal: "0.5386" },
  { geneTranscript: "NM_000024.6", parsimonySitesP: "0.213075060532688", constantSitesP: "0.594834543987086", distinctSitesP: "0.324455205811138", brLenTotal: "0.8672" },
  { geneTranscript: "NM_003273.6", parsimonySitesP: "0.216108452950558", constantSitesP: "0.643540669856459", distinctSitesP: "0.315789473684211", brLenTotal: "0.8088" },
  { geneTranscript: "NM_000460.4", parsimonySitesP: "0.288007554296506", constantSitesP: "0.478753541076487", distinctSitesP: "0.468366383380548", brLenTotal: "0.9636" },
  { geneTranscript: "NM_003678.5", parsimonySitesP: "0.178623718887262", constantSitesP: "0.69643728648121", distinctSitesP: "0.248413860419717", brLenTotal: "0.6768" },
  { geneTranscript: "NM_006341.4", parsimonySitesP: "0.14218009478673", constantSitesP: "0.761453396524487", distinctSitesP: "0.257503949447077", brLenTotal: "0.5996" },
  { geneTranscript: "NM_007247.6", parsimonySitesP: "0.161846778285134", constantSitesP: "0.712836123795028", distinctSitesP: "0.225266362252664", brLenTotal: "0.5602" },
  { geneTranscript: "NM_020134.4", parsimonySitesP: "0.145981087470449", constantSitesP: "0.764775413711584", distinctSitesP: "0.202718676122931", brLenTotal: "0.7025" },
  { geneTranscript: "NM_000342.4", parsimonySitesP: "0.272228320526894", constantSitesP: "0.604463959019393", distinctSitesP: "0.342480790340285", brLenTotal: "1.0708" },
  { geneTranscript: "NM_024095.5", parsimonySitesP: "0.134259259259259", constantSitesP: "0.767361111111111", distinctSitesP: "0.197916666666667", brLenTotal: "0.4406" },
  { geneTranscript: "NM_032836.3", parsimonySitesP: "0.234543010752688", constantSitesP: "0.660618279569892", distinctSitesP: "0.315860215053763", brLenTotal: "1.0268" },
  { geneTranscript: "NM_001381865.2", parsimonySitesP: "0.18368962787015", constantSitesP: "0.657165479018211", distinctSitesP: "0.26603325415677", brLenTotal: "0.7272" },
  { geneTranscript: "NM_001012754.4", parsimonySitesP: "0.195965417867435", constantSitesP: "0.646493756003842", distinctSitesP: "0.345821325648415", brLenTotal: "0.7419" },
  { geneTranscript: "NM_030774.4", parsimonySitesP: "0.217708333333333", constantSitesP: "0.667708333333333", distinctSitesP: "0.297916666666667", brLenTotal: "0.7113" },
  { geneTranscript: "NM_024644.5", parsimonySitesP: "0.238689547581903", constantSitesP: "0.606344253770151", distinctSitesP: "0.343733749349974", brLenTotal: "0.8744" },
  { geneTranscript: "NM_006910.5", parsimonySitesP: "0.136346726190476", constantSitesP: "0.765066964285714", distinctSitesP: "0.166852678571429", brLenTotal: "0.4424" },
  { geneTranscript: "NM_004134.7", parsimonySitesP: "0.147766323024055", constantSitesP: "0.74079528718704", distinctSitesP: "0.238095238095238", brLenTotal: "0.5175" },
  { geneTranscript: "NM_001174147.2", parsimonySitesP: "0.0961857379767828", constantSitesP: "0.822553897180763", distinctSitesP: "0.154228855721393", brLenTotal: "0.4654" },
  { geneTranscript: "NM_033280.4", parsimonySitesP: "0.133680555555556", constantSitesP: "0.765625", distinctSitesP: "0.217013888888889", brLenTotal: "0.4788" },
  { geneTranscript: "NM_001258.4", parsimonySitesP: "0.217486338797814", constantSitesP: "0.665573770491803", distinctSitesP: "0.300546448087432", brLenTotal: "0.8605" },
  { geneTranscript: "NM_001001852.4", parsimonySitesP: "0.210633946830266", constantSitesP: "0.649284253578732", distinctSitesP: "0.28118609406953", brLenTotal: "0.9453" },
  { geneTranscript: "NM_006857.3", parsimonySitesP: "0.126881720430108", constantSitesP: "0.759139784946237", distinctSitesP: "0.243010752688172", brLenTotal: "0.4194" },
  { geneTranscript: "NM_003765.3", parsimonySitesP: "0.220883534136546", constantSitesP: "0.646586345381526", distinctSitesP: "0.329317269076305", brLenTotal: "0.8212" },
  { geneTranscript: "NM_002491.3", parsimonySitesP: "0.289115646258503", constantSitesP: "0.435374149659864", distinctSitesP: "0.58843537414966", brLenTotal: "1.4103" },
  { geneTranscript: "NM_178014.4", parsimonySitesP: "0.243993993993994", constantSitesP: "0.635885885885886", distinctSitesP: "0.331831831831832", brLenTotal: "1.7195" },
  { geneTranscript: "NM_207418.3", parsimonySitesP: "0.138702460850112", constantSitesP: "0.740492170022371", distinctSitesP: "0.250559284116331", brLenTotal: "0.4250" },
  { geneTranscript: "NM_022451.11", parsimonySitesP: "0.157083333333333", constantSitesP: "0.719166666666667", distinctSitesP: "0.249166666666667", brLenTotal: "0.5271" },
  { geneTranscript: "NM_004958.4", parsimonySitesP: "0.134170262848176", constantSitesP: "0.787367595135347", distinctSitesP: "0.143716490126847", brLenTotal: "0.4680" },
  { geneTranscript: "NM_002055.5", parsimonySitesP: "0.190586419753086", constantSitesP: "0.698302469135802", distinctSitesP: "0.255401234567901", brLenTotal: "0.7618" },
  { geneTranscript: "NM_001031689.3", parsimonySitesP: "0.133752620545073", constantSitesP: "0.767295597484277", distinctSitesP: "0.19958071278826", brLenTotal: "0.3889" },
  { geneTranscript: "NM_133450.4", parsimonySitesP: "0.238313008130081", constantSitesP: "0.639227642276423", distinctSitesP: "0.32520325203252", brLenTotal: "1.0473" },
  { geneTranscript: "NM_022776.5", parsimonySitesP: "0.136546184738956", constantSitesP: "0.744310575635877", distinctSitesP: "0.207050423917894", brLenTotal: "0.4636" },
  { geneTranscript: "NM_018668.5", parsimonySitesP: "0.145326850351162", constantSitesP: "0.747703943814155", distinctSitesP: "0.20259319286872", brLenTotal: "0.5161" },
  { geneTranscript: "NM_001004752.2", parsimonySitesP: "0.461859979101358", constantSitesP: "0.351097178683386", distinctSitesP: "0.629049111807732", brLenTotal: "1.8195" },
  { geneTranscript: "NM_003567.4", parsimonySitesP: "0.233939393939394", constantSitesP: "0.627070707070707", distinctSitesP: "0.305050505050505", brLenTotal: "0.8766" },
  { geneTranscript: "NM_003352.8", parsimonySitesP: "0.0396039603960396", constantSitesP: "0.887788778877888", distinctSitesP: "0.132013201320132", brLenTotal: "0.1427" },
  { geneTranscript: "NM_030816.5", parsimonySitesP: "0.0942698706099815", constantSitesP: "0.786814540973506", distinctSitesP: "0.163894023413432", brLenTotal: "0.3666" },
  { geneTranscript: "NM_022344.4", parsimonySitesP: "0.148989898989899", constantSitesP: "0.730639730639731", distinctSitesP: "0.241582491582492", brLenTotal: "0.4731" },
  { geneTranscript: "NM_018091.6", parsimonySitesP: "0.163315051797684", constantSitesP: "0.759293113954906", distinctSitesP: "0.239488117001828", brLenTotal: "0.6077" },
  { geneTranscript: "NM_152372.4", parsimonySitesP: "0.236836000927859", constantSitesP: "0.62839248434238", distinctSitesP: "0.303177916956623", brLenTotal: "1.0137" },
  { geneTranscript: "NM_001386298.1", parsimonySitesP: "0.196530260892597", constantSitesP: "0.689974837769832", distinctSitesP: "0.21056813667064", brLenTotal: "0.8153" },
  { geneTranscript: "NM_003531.3", parsimonySitesP: "0.348039215686275", constantSitesP: "0.620098039215686", distinctSitesP: "0.389705882352941", brLenTotal: "2.2386" },
  { geneTranscript: "NM_016297.4", parsimonySitesP: "0.222442244224422", constantSitesP: "0.642244224422442", distinctSitesP: "0.330693069306931", brLenTotal: "0.7535" },
  { geneTranscript: "NM_004308.5", parsimonySitesP: "0.160971905846621", constantSitesP: "0.722095671981777", distinctSitesP: "0.236142748671222", brLenTotal: "0.5961" },
  { geneTranscript: "NM_032485.6", parsimonySitesP: "0.165873015873016", constantSitesP: "0.716666666666667", distinctSitesP: "0.243650793650794", brLenTotal: "0.5490" },
  { geneTranscript: "NM_015213.4", parsimonySitesP: "0.137788137788138", constantSitesP: "0.763532763532764", distinctSitesP: "0.170940170940171", brLenTotal: "0.4540" },
  { geneTranscript: "NM_003867.4", parsimonySitesP: "0.146604938271605", constantSitesP: "0.763888888888889", distinctSitesP: "0.220679012345679", brLenTotal: "0.5292" },
  { geneTranscript: "NM_001018072.2", parsimonySitesP: "0.119263285024155", constantSitesP: "0.806461352657005", distinctSitesP: "0.159118357487923", brLenTotal: "0.4253" },
  { geneTranscript: "NM_001330640.2", parsimonySitesP: "0.162921348314607", constantSitesP: "0.706843718079673", distinctSitesP: "0.218760640108955", brLenTotal: "0.5329" },
  { geneTranscript: "NM_002931.4", parsimonySitesP: "0.12807881773399", constantSitesP: "0.780788177339901", distinctSitesP: "0.186371100164204", brLenTotal: "0.4873" },
  { geneTranscript: "NM_001371194.2", parsimonySitesP: "0.273395204949729", constantSitesP: "0.592420726991493", distinctSitesP: "0.365429234338747", brLenTotal: "1.2908" },
  { geneTranscript: "NM_001170820.4", parsimonySitesP: "0.236842105263158", constantSitesP: "0.621345029239766", distinctSitesP: "0.362573099415205", brLenTotal: "1.5003" },
  { geneTranscript: "NM_001541.4", parsimonySitesP: "0.15018315018315", constantSitesP: "0.741758241758242", distinctSitesP: "0.247252747252747", brLenTotal: "0.5493" },
  { geneTranscript: "NM_020248.3", parsimonySitesP: "0.127572016460905", constantSitesP: "0.773662551440329", distinctSitesP: "0.238683127572016", brLenTotal: "0.4694" },
  { geneTranscript: "NM_005879.3", parsimonySitesP: "0.194740582800284", constantSitesP: "0.641791044776119", distinctSitesP: "0.312011371712864", brLenTotal: "0.6668" },
  { geneTranscript: "NM_001385.3", parsimonySitesP: "0.205523442517662", constantSitesP: "0.674373795761079", distinctSitesP: "0.298651252408478", brLenTotal: "0.7669" },
  { geneTranscript: "NM_014771.4", parsimonySitesP: "0.19014319014319", constantSitesP: "0.70995670995671", distinctSitesP: "0.218448218448218", brLenTotal: "0.6427" },
  { geneTranscript: "NM_015373.4", parsimonySitesP: "0.182539682539683", constantSitesP: "0.685185185185185", distinctSitesP: "0.30952380952381", brLenTotal: "0.5874" },
  { geneTranscript: "NM_014298.6", parsimonySitesP: "0.252525252525253", constantSitesP: "0.609427609427609", distinctSitesP: "0.36026936026936", brLenTotal: "1.3924" },
  { geneTranscript: "NM_003920.5", parsimonySitesP: "0.238134657836645", constantSitesP: "0.6280353200883", distinctSitesP: "0.319260485651214", brLenTotal: "0.7617" },
  { geneTranscript: "NM_017887.3", parsimonySitesP: "0.1625", constantSitesP: "0.739583333333333", distinctSitesP: "0.272916666666667", brLenTotal: "0.4813" },
  { geneTranscript: "NM_003036.4", parsimonySitesP: "0.174450549450549", constantSitesP: "0.728021978021978", distinctSitesP: "0.223901098901099", brLenTotal: "0.8353" },
  { geneTranscript: "NM_172238.4", parsimonySitesP: "0.0700589970501475", constantSitesP: "0.857669616519174", distinctSitesP: "0.129793510324484", brLenTotal: "0.2516" },
  { geneTranscript: "NM_002350.4", parsimonySitesP: "0.177083333333333", constantSitesP: "0.719401041666667", distinctSitesP: "0.252604166666667", brLenTotal: "0.7859" },
  { geneTranscript: "NM_173468.4", parsimonySitesP: "0.182098765432099", constantSitesP: "0.740740740740741", distinctSitesP: "0.237654320987654", brLenTotal: "0.6475" },
  { geneTranscript: "NM_024712.5", parsimonySitesP: "0.221759259259259", constantSitesP: "0.67037037037037", distinctSitesP: "0.277777777777778", brLenTotal: "0.8701" },
  { geneTranscript: "NM_002155.5", parsimonySitesP: "0.389320891653707", constantSitesP: "0.475375842405391", distinctSitesP: "0.450492483151892", brLenTotal: "1.4055" },
  { geneTranscript: "NM_002128.7", parsimonySitesP: "0.161240310077519", constantSitesP: "0.663565891472868", distinctSitesP: "0.325581395348837", brLenTotal: "0.8182" },
  { geneTranscript: "NM_199227.3", parsimonySitesP: "0.124378109452736", constantSitesP: "0.770149253731343", distinctSitesP: "0.209950248756219", brLenTotal: "0.4000" },
  { geneTranscript: "NM_152624.6", parsimonySitesP: "0.129365079365079", constantSitesP: "0.78015873015873", distinctSitesP: "0.2", brLenTotal: "0.4461" },
  { geneTranscript: "NM_173485.6", parsimonySitesP: "0.213088330109607", constantSitesP: "0.686653771760155", distinctSitesP: "0.268214055448098", brLenTotal: "0.8571" },
  { geneTranscript: "NM_022049.3", parsimonySitesP: "0.126736111111111", constantSitesP: "0.755208333333333", distinctSitesP: "0.214409722222222", brLenTotal: "0.6102" },
  { geneTranscript: "NM_002841.4", parsimonySitesP: "0.160553633217993", constantSitesP: "0.73679354094579", distinctSitesP: "0.216378316032295", brLenTotal: "0.6281" },
  { geneTranscript: "NM_032233.3", parsimonySitesP: "0.171717171717172", constantSitesP: "0.721099887766554", distinctSitesP: "0.24354657687991", brLenTotal: "0.6160" },
  { geneTranscript: "NM_001330585.2", parsimonySitesP: "0.242566510172144", constantSitesP: "0.607981220657277", distinctSitesP: "0.327073552425665", brLenTotal: "0.8691" },
  { geneTranscript: "NM_178468.6", parsimonySitesP: "0.287371709058456", constantSitesP: "0.544399821508255", distinctSitesP: "0.381972333779563", brLenTotal: "0.9692" },
  { geneTranscript: "NM_005125.2", parsimonySitesP: "0.233576642335766", constantSitesP: "0.638686131386861", distinctSitesP: "0.334549878345499", brLenTotal: "0.8068" },
  { geneTranscript: "NM_001684.5", parsimonySitesP: "0.238174273858921", constantSitesP: "0.651728907330567", distinctSitesP: "0.299585062240664", brLenTotal: "1.0007" },
  { geneTranscript: "NM_004494.3", parsimonySitesP: "0.145833333333333", constantSitesP: "0.775", distinctSitesP: "0.223611111111111", brLenTotal: "0.5496" },
  { geneTranscript: "NM_138349.4", parsimonySitesP: "0.319762510602205", constantSitesP: "0.502120441051739", distinctSitesP: "0.463104325699746", brLenTotal: "1.0793" },
  { geneTranscript: "NM_032289.4", parsimonySitesP: "0.230004323389537", constantSitesP: "0.64591439688716", distinctSitesP: "0.298313878080415", brLenTotal: "0.8792" },
  { geneTranscript: "NM_001256545.2", parsimonySitesP: "0.195321637426901", constantSitesP: "0.699415204678363", distinctSitesP: "0.271637426900585", brLenTotal: "0.7861" },
  { geneTranscript: "NM_005482.3", parsimonySitesP: "0.146835443037975", constantSitesP: "0.725738396624473", distinctSitesP: "0.237974683544304", brLenTotal: "0.5151" },
  { geneTranscript: "NM_152663.5", parsimonySitesP: "0.120068610634648", constantSitesP: "0.796455117209834", distinctSitesP: "0.184105202973128", brLenTotal: "0.3874" },
  { geneTranscript: "NM_003383.5", parsimonySitesP: "0.156930126002291", constantSitesP: "0.751050019091256", distinctSitesP: "0.207331042382589", brLenTotal: "0.5748" },
  { geneTranscript: "NM_152307.3", parsimonySitesP: "0.231833910034602", constantSitesP: "0.68396770472895", distinctSitesP: "0.291810841983852", brLenTotal: "1.1894" },
  { geneTranscript: "NM_001199172.2", parsimonySitesP: "0.182659932659933", constantSitesP: "0.713383838383838", distinctSitesP: "0.23989898989899", brLenTotal: "0.8803" },
  { geneTranscript: "NM_000377.3", parsimonySitesP: "0.185922974767596", constantSitesP: "0.689907038512616", distinctSitesP: "0.266268260292165", brLenTotal: "0.7168" },
  { geneTranscript: "NM_002860.4", parsimonySitesP: "0.160587002096436", constantSitesP: "0.732914046121593", distinctSitesP: "0.205450733752621", brLenTotal: "0.6094" },
  { geneTranscript: "NM_004786.3", parsimonySitesP: "0.104959630911188", constantSitesP: "0.815455594002307", distinctSitesP: "0.171856978085352", brLenTotal: "0.3331" },
  { geneTranscript: "NM_005565.5", parsimonySitesP: "0.212007504690432", constantSitesP: "0.6729205753596", distinctSitesP: "0.33083176985616", brLenTotal: "0.8187" },
  { geneTranscript: "NM_005115.5", parsimonySitesP: "0.224710712952594", constantSitesP: "0.643523702874207", distinctSitesP: "0.288913773796193", brLenTotal: "0.9664" },
  { geneTranscript: "NM_203451.3", parsimonySitesP: "0.168224299065421", constantSitesP: "0.728971962616822", distinctSitesP: "0.2398753894081", brLenTotal: "0.6845" },
  { geneTranscript: "NM_001029865.4", parsimonySitesP: "0.209912536443149", constantSitesP: "0.663751214771623", distinctSitesP: "0.332361516034985", brLenTotal: "0.8331" },
  { geneTranscript: "NM_001136483.3", parsimonySitesP: "0.193089430894309", constantSitesP: "0.66869918699187", distinctSitesP: "0.310975609756098", brLenTotal: "0.6513" },
  { geneTranscript: "NM_001145668.2", parsimonySitesP: "0.148148148148148", constantSitesP: "0.753086419753086", distinctSitesP: "0.259259259259259", brLenTotal: "0.5000" },
  { geneTranscript: "NM_005276.4", parsimonySitesP: "0.163323782234957", constantSitesP: "0.715377268385864", distinctSitesP: "0.242597898758357", brLenTotal: "0.5751" },
  { geneTranscript: "NM_199129.4", parsimonySitesP: "0.155555555555556", constantSitesP: "0.71358024691358", distinctSitesP: "0.254320987654321", brLenTotal: "0.6457" },
  { geneTranscript: "NM_001037281.2", parsimonySitesP: "0.190338164251208", constantSitesP: "0.693719806763285", distinctSitesP: "0.268599033816425", brLenTotal: "0.6104" },
  { geneTranscript: "NM_001315532.2", parsimonySitesP: "0.218181818181818", constantSitesP: "0.709090909090909", distinctSitesP: "0.290909090909091", brLenTotal: "0.8287" },
  { geneTranscript: "NM_197941.4", parsimonySitesP: "0.0877350044762757", constantSitesP: "0.843927185914652", distinctSitesP: "0.12354521038496", brLenTotal: "0.2818" },
  { geneTranscript: "NM_015982.4", parsimonySitesP: "0.195970695970696", constantSitesP: "0.663003663003663", distinctSitesP: "0.298534798534799", brLenTotal: "0.6835" },
  { geneTranscript: "NM_153326.3", parsimonySitesP: "0.181538461538462", constantSitesP: "0.675897435897436", distinctSitesP: "0.276923076923077", brLenTotal: "0.6175" },
  { geneTranscript: "NM_001318525.2", parsimonySitesP: "0.225419664268585", constantSitesP: "0.683453237410072", distinctSitesP: "0.335731414868106", brLenTotal: "0.9862" },
  { geneTranscript: "NM_001007.5", parsimonySitesP: "0.197718631178707", constantSitesP: "0.624841571609632", distinctSitesP: "0.342205323193916", brLenTotal: "0.8501" },
  { geneTranscript: "NM_052854.4", parsimonySitesP: "0.163776493256262", constantSitesP: "0.73538856775851", distinctSitesP: "0.224149004495825", brLenTotal: "0.7709" },
  { geneTranscript: "NM_012176.3", parsimonySitesP: "0.176571920757967", constantSitesP: "0.689061154177433", distinctSitesP: "0.291989664082687", brLenTotal: "0.5851" },
  { geneTranscript: "NM_014028.4", parsimonySitesP: "0.189620758483034", constantSitesP: "0.658682634730539", distinctSitesP: "0.327345309381238", brLenTotal: "0.6498" },
  { geneTranscript: "NM_005639.3", parsimonySitesP: "0.170616113744076", constantSitesP: "0.76303317535545", distinctSitesP: "0.213270142180095", brLenTotal: "0.5876" },
  { geneTranscript: "NM_016079.4", parsimonySitesP: "0.156156156156156", constantSitesP: "0.6996996996997", distinctSitesP: "0.294294294294294", brLenTotal: "0.6326" },
  { geneTranscript: "NM_001164310.3", parsimonySitesP: "0.303030303030303", constantSitesP: "0.453333333333333", distinctSitesP: "0.530909090909091", brLenTotal: "1.1243" },
  { geneTranscript: "NM_001130929.2", parsimonySitesP: "0.106382978723404", constantSitesP: "0.75177304964539", distinctSitesP: "0.297872340425532", brLenTotal: "0.3508" },
  { geneTranscript: "NM_006832.3", parsimonySitesP: "0.13578431372549", constantSitesP: "0.767647058823529", distinctSitesP: "0.19656862745098", brLenTotal: "0.4551" },
  { geneTranscript: "NM_001042690.2", parsimonySitesP: "0.172661870503597", constantSitesP: "0.729016786570743", distinctSitesP: "0.245803357314149", brLenTotal: "0.6461" },
  { geneTranscript: "NM_003931.3", parsimonySitesP: "0.0906380441264162", constantSitesP: "0.809779367918903", distinctSitesP: "0.150864639236732", brLenTotal: "0.3538" },
  { geneTranscript: "NM_002874.5", parsimonySitesP: "0.142624286878566", constantSitesP: "0.709861450692747", distinctSitesP: "0.263243683781581", brLenTotal: "0.5589" },
  { geneTranscript: "NM_138450.6", parsimonySitesP: "0.341836734693878", constantSitesP: "0.520408163265306", distinctSitesP: "0.481292517006803", brLenTotal: "1.3152" },
  { geneTranscript: "NM_017617.5", parsimonySitesP: "0.260013046314416", constantSitesP: "0.62570123939987", distinctSitesP: "0.28897586431833", brLenTotal: "1.4687" },
  { geneTranscript: "NM_005026.5", parsimonySitesP: "0.177522349936143", constantSitesP: "0.732439335887612", distinctSitesP: "0.219029374201788", brLenTotal: "0.8131" },
  { geneTranscript: "NM_016170.5", parsimonySitesP: "0.187793427230047", constantSitesP: "0.708920187793427", distinctSitesP: "0.275821596244131", brLenTotal: "0.6362" },
  { geneTranscript: "NM_007232.3", parsimonySitesP: "0.189513108614232", constantSitesP: "0.707116104868914", distinctSitesP: "0.274906367041199", brLenTotal: "1.1332" },
  { geneTranscript: "NM_005199.5", parsimonySitesP: "0.252095422308188", constantSitesP: "0.626047711154094", distinctSitesP: "0.315280464216634", brLenTotal: "1.1395" },
  { geneTranscript: "NM_013234.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.743119266055046", distinctSitesP: "0.243119266055046", brLenTotal: "0.5965" },
  { geneTranscript: "NM_080677.3", parsimonySitesP: "0.0374531835205993", constantSitesP: "0.891385767790262", distinctSitesP: "0.104868913857678", brLenTotal: "0.1789" },
  { geneTranscript: "NM_007255.3", parsimonySitesP: "0.200815494393476", constantSitesP: "0.689092762487258", distinctSitesP: "0.282364933741081", brLenTotal: "0.8180" },
  { geneTranscript: "NM_001143919.3", parsimonySitesP: "0.307765151515152", constantSitesP: "0.548295454545455", distinctSitesP: "0.429924242424242", brLenTotal: "1.3544" },
  { geneTranscript: "NM_001024613.4", parsimonySitesP: "0.152280701754386", constantSitesP: "0.728421052631579", distinctSitesP: "0.229473684210526", brLenTotal: "0.5011" },
  { geneTranscript: "NM_000151.4", parsimonySitesP: "0.226890756302521", constantSitesP: "0.672268907563025", distinctSitesP: "0.303454715219421", brLenTotal: "0.7635" },
  { geneTranscript: "NM_001392013.1", parsimonySitesP: "0.19946984758118", constantSitesP: "0.644797879390325", distinctSitesP: "0.288270377733598", brLenTotal: "0.8632" },
  { geneTranscript: "NM_001193489.2", parsimonySitesP: "0.160762942779292", constantSitesP: "0.728125946109597", distinctSitesP: "0.213442325158946", brLenTotal: "0.6068" },
  { geneTranscript: "NM_003884.5", parsimonySitesP: "0.132211538461538", constantSitesP: "0.731169871794872", distinctSitesP: "0.224759615384615", brLenTotal: "0.5857" },
  { geneTranscript: "NM_016407.5", parsimonySitesP: "0.183006535947712", constantSitesP: "0.699346405228758", distinctSitesP: "0.29520697167756", brLenTotal: "0.7555" },
  { geneTranscript: "NM_001033046.4", parsimonySitesP: "0.242424242424242", constantSitesP: "0.618538324420677", distinctSitesP: "0.345811051693405", brLenTotal: "1.0136" },
  { geneTranscript: "NM_024771.4", parsimonySitesP: "0.143459915611814", constantSitesP: "0.759493670886076", distinctSitesP: "0.205344585091421", brLenTotal: "0.5573" },
  { geneTranscript: "NM_021818.4", parsimonySitesP: "0.135770234986945", constantSitesP: "0.736292428198433", distinctSitesP: "0.241949521322889", brLenTotal: "0.4420" },
  { geneTranscript: "NM_016107.5", parsimonySitesP: "0.0900062073246431", constantSitesP: "0.84016139044072", distinctSitesP: "0.132836747361887", brLenTotal: "0.2945" },
  { geneTranscript: "NM_002227.4", parsimonySitesP: "0.187752744078567", constantSitesP: "0.732235701906412", distinctSitesP: "0.236279607163489", brLenTotal: "0.7053" },
  { geneTranscript: "NM_002883.4", parsimonySitesP: "0.214082907438955", constantSitesP: "0.662691652470187", distinctSitesP: "0.306076093128904", brLenTotal: "0.9729" },
  { geneTranscript: "NM_001962.3", parsimonySitesP: "0.0657894736842105", constantSitesP: "0.861111111111111", distinctSitesP: "0.12719298245614", brLenTotal: "0.2470" },
  { geneTranscript: "NM_016333.4", parsimonySitesP: "0.219718992248062", constantSitesP: "0.655038759689922", distinctSitesP: "0.280038759689922", brLenTotal: "0.8134" },
  { geneTranscript: "NM_152280.5", parsimonySitesP: "0.126836813611756", constantSitesP: "0.796597061098221", distinctSitesP: "0.174787316318639", brLenTotal: "0.4302" },
  { geneTranscript: "NM_152581.4", parsimonySitesP: "0.136422136422136", constantSitesP: "0.748391248391248", distinctSitesP: "0.232947232947233", brLenTotal: "0.4477" },
  { geneTranscript: "NM_016302.4", parsimonySitesP: "0.120663650075415", constantSitesP: "0.802413273001508", distinctSitesP: "0.171191553544495", brLenTotal: "0.3845" },
  { geneTranscript: "NM_001369919.2", parsimonySitesP: "0.244565217391304", constantSitesP: "0.624094202898551", distinctSitesP: "0.348731884057971", brLenTotal: "0.8172" },
  { geneTranscript: "NM_017632.4", parsimonySitesP: "0.16551724137931", constantSitesP: "0.649425287356322", distinctSitesP: "0.266091954022989", brLenTotal: "0.6093" },
  { geneTranscript: "NM_004197.2", parsimonySitesP: "0.211286089238845", constantSitesP: "0.628608923884514", distinctSitesP: "0.329396325459318", brLenTotal: "0.8142" },
  { geneTranscript: "NM_145313.4", parsimonySitesP: "0.175329175329175", constantSitesP: "0.722799722799723", distinctSitesP: "0.237699237699238", brLenTotal: "0.6792" },
  { geneTranscript: "NM_173495.3", parsimonySitesP: "0.0765765765765766", constantSitesP: "0.846471471471471", distinctSitesP: "0.118993993993994", brLenTotal: "0.2556" },
  { geneTranscript: "NM_006606.3", parsimonySitesP: "0.188172043010753", constantSitesP: "0.672043010752688", distinctSitesP: "0.327956989247312", brLenTotal: "0.6534" },
  { geneTranscript: "NM_138447.3", parsimonySitesP: "0.188666666666667", constantSitesP: "0.697333333333333", distinctSitesP: "0.264", brLenTotal: "0.7296" },
  { geneTranscript: "NM_014661.4", parsimonySitesP: "0.208530805687204", constantSitesP: "0.643759873617694", distinctSitesP: "0.29304897314376", brLenTotal: "0.8874" },
  { geneTranscript: "NM_001013437.2", parsimonySitesP: "0.157561361836896", constantSitesP: "0.733174980205859", distinctSitesP: "0.247030878859857", brLenTotal: "0.4699" },
  { geneTranscript: "NM_002853.4", parsimonySitesP: "0.209219858156028", constantSitesP: "0.622931442080378", distinctSitesP: "0.32387706855792", brLenTotal: "0.7204" },
  { geneTranscript: "NM_005374.5", parsimonySitesP: "0.156400966183575", constantSitesP: "0.720410628019324", distinctSitesP: "0.227053140096618", brLenTotal: "0.7232" },
  { geneTranscript: "NM_017744.5", parsimonySitesP: "0.183768115942029", constantSitesP: "0.650434782608696", distinctSitesP: "0.277101449275362", brLenTotal: "0.6388" },
  { geneTranscript: "NM_000714.6", parsimonySitesP: "0.276134122287968", constantSitesP: "0.595660749506903", distinctSitesP: "0.400394477317554", brLenTotal: "1.1411" },
  { geneTranscript: "NM_001042625.2", parsimonySitesP: "0.189102564102564", constantSitesP: "0.72275641025641", distinctSitesP: "0.282051282051282", brLenTotal: "0.6983" },
  { geneTranscript: "NM_018413.6", parsimonySitesP: "0.119318181818182", constantSitesP: "0.789772727272727", distinctSitesP: "0.183712121212121", brLenTotal: "0.4429" },
  { geneTranscript: "NM_000325.6", parsimonySitesP: "0.153292181069959", constantSitesP: "0.724279835390947", distinctSitesP: "0.213991769547325", brLenTotal: "0.6118" },
  { geneTranscript: "NM_178493.6", parsimonySitesP: "0.209005376344086", constantSitesP: "0.686827956989247", distinctSitesP: "0.264784946236559", brLenTotal: "0.8930" },
  { geneTranscript: "NM_006775.3", parsimonySitesP: "0.0733137829912023", constantSitesP: "0.816226783968719", distinctSitesP: "0.165200391006843", brLenTotal: "0.2627" },
  { geneTranscript: "NM_001371340.3", parsimonySitesP: "0.16750418760469", constantSitesP: "0.748743718592965", distinctSitesP: "0.246231155778894", brLenTotal: "0.7057" },
  { geneTranscript: "NM_004460.5", parsimonySitesP: "0.119298245614035", constantSitesP: "0.799561403508772", distinctSitesP: "0.193421052631579", brLenTotal: "0.3988" },
  { geneTranscript: "NM_002315.3", parsimonySitesP: "0.123931623931624", constantSitesP: "0.786324786324786", distinctSitesP: "0.19017094017094", brLenTotal: "0.5136" },
  { geneTranscript: "NM_001318170.2", parsimonySitesP: "0.175347222222222", constantSitesP: "0.734953703703704", distinctSitesP: "0.279513888888889", brLenTotal: "0.7400" },
  { geneTranscript: "NM_025246.3", parsimonySitesP: "0.133495145631068", constantSitesP: "0.748381877022654", distinctSitesP: "0.211165048543689", brLenTotal: "0.4199" },
  { geneTranscript: "NM_005726.6", parsimonySitesP: "0.202051282051282", constantSitesP: "0.620512820512821", distinctSitesP: "0.335384615384615", brLenTotal: "0.7713" },
  { geneTranscript: "NM_025222.4", parsimonySitesP: "0.10223642172524", constantSitesP: "0.805111821086262", distinctSitesP: "0.183173588924388", brLenTotal: "0.3641" },
  { geneTranscript: "NM_014908.4", parsimonySitesP: "0.191449814126394", constantSitesP: "0.685873605947955", distinctSitesP: "0.250309789343247", brLenTotal: "0.6519" },
  { geneTranscript: "NM_001042.3", parsimonySitesP: "0.180091683038638", constantSitesP: "0.721021611001965", distinctSitesP: "0.224623444662737", brLenTotal: "0.6436" },
  { geneTranscript: "NM_021081.6", parsimonySitesP: "0.228395061728395", constantSitesP: "0.598765432098765", distinctSitesP: "0.404320987654321", brLenTotal: "0.7196" },
  { geneTranscript: "NM_032752.3", parsimonySitesP: "0.274843838727995", constantSitesP: "0.564452015900057", distinctSitesP: "0.39579784213515", brLenTotal: "1.1907" },
  { geneTranscript: "NM_032110.3", parsimonySitesP: "0.202337023370234", constantSitesP: "0.69249692496925", distinctSitesP: "0.277367773677737", brLenTotal: "0.8072" },
  { geneTranscript: "NM_005881.4", parsimonySitesP: "0.190938511326861", constantSitesP: "0.712783171521036", distinctSitesP: "0.250809061488673", brLenTotal: "0.7474" },
  { geneTranscript: "NM_001003681.3", parsimonySitesP: "0.155296727676095", constantSitesP: "0.744869661674986", distinctSitesP: "0.221297836938436", brLenTotal: "0.4953" },
  { geneTranscript: "NM_003637.5", parsimonySitesP: "0.197943444730077", constantSitesP: "0.651813767495001", distinctSitesP: "0.257355041416738", brLenTotal: "0.6773" },
  { geneTranscript: "NM_001256470.2", parsimonySitesP: "0.166406656266251", constantSitesP: "0.727249089963599", distinctSitesP: "0.240509620384815", brLenTotal: "0.6192" },
  { geneTranscript: "NM_024619.4", parsimonySitesP: "0.331175836030205", constantSitesP: "0.55663430420712", distinctSitesP: "0.44336569579288", brLenTotal: "1.5765" },
  { geneTranscript: "NM_001142930.2", parsimonySitesP: "0.115139949109415", constantSitesP: "0.779262086513995", distinctSitesP: "0.196564885496183", brLenTotal: "0.4122" },
  { geneTranscript: "NM_001098.3", parsimonySitesP: "0.194444444444444", constantSitesP: "0.697435897435897", distinctSitesP: "0.255982905982906", brLenTotal: "0.7888" },
  { geneTranscript: "NM_007170.3", parsimonySitesP: "0.175715119673088", constantSitesP: "0.69118505545826", distinctSitesP: "0.27086981903094", brLenTotal: "0.5681" },
  { geneTranscript: "NM_001039753.4", parsimonySitesP: "0.158495062989445", constantSitesP: "0.749744637385087", distinctSitesP: "0.192713653387811", brLenTotal: "0.6377" },
  { geneTranscript: "NM_001352171.3", parsimonySitesP: "0.155322862129145", constantSitesP: "0.735311227457824", distinctSitesP: "0.234438627108784", brLenTotal: "0.5356" },
  { geneTranscript: "NM_030952.3", parsimonySitesP: "0.25796178343949", constantSitesP: "0.619957537154989", distinctSitesP: "0.319002123142251", brLenTotal: "1.0094" },
  { geneTranscript: "NM_001369869.1", parsimonySitesP: "0.338383838383838", constantSitesP: "0.51010101010101", distinctSitesP: "0.515151515151515", brLenTotal: "1.7717" },
  { geneTranscript: "NM_001748.5", parsimonySitesP: "0.207619047619048", constantSitesP: "0.695238095238095", distinctSitesP: "0.286666666666667", brLenTotal: "0.8130" },
  { geneTranscript: "NM_206894.4", parsimonySitesP: "0.503668763102725", constantSitesP: "0.34748427672956", distinctSitesP: "0.633647798742138", brLenTotal: "2.6694" },
  { geneTranscript: "NM_001282144.2", parsimonySitesP: "0.243760683760684", constantSitesP: "0.620512820512821", distinctSitesP: "0.298461538461538", brLenTotal: "0.9652" },
  { geneTranscript: "NM_000558.5", parsimonySitesP: "0.295774647887324", constantSitesP: "0.584507042253521", distinctSitesP: "0.413145539906103", brLenTotal: "1.3776" },
  { geneTranscript: "NM_001098672.2", parsimonySitesP: "0.207937877480587", constantSitesP: "0.662064998561979", distinctSitesP: "0.291918320391142", brLenTotal: "0.7674" },
  { geneTranscript: "NM_000478.6", parsimonySitesP: "0.220101781170483", constantSitesP: "0.683206106870229", distinctSitesP: "0.286259541984733", brLenTotal: "0.9799" },
  { geneTranscript: "NM_003676.4", parsimonySitesP: "0.210526315789474", constantSitesP: "0.639834881320949", distinctSitesP: "0.315789473684211", brLenTotal: "0.8663" },
  { geneTranscript: "NM_006339.3", parsimonySitesP: "0.184016824395373", constantSitesP: "0.720294426919033", distinctSitesP: "0.273396424815983", brLenTotal: "0.7373" },
  { geneTranscript: "NM_001013627.3", parsimonySitesP: "0.20108843537415", constantSitesP: "0.650340136054422", distinctSitesP: "0.258503401360544", brLenTotal: "0.6489" },
  { geneTranscript: "NM_022075.5", parsimonySitesP: "0.158771929824561", constantSitesP: "0.741228070175439", distinctSitesP: "0.212280701754386", brLenTotal: "0.5354" },
  { geneTranscript: "NM_144976.4", parsimonySitesP: "0.369499698613623", constantSitesP: "0.365280289330922", distinctSitesP: "0.491862567811935", brLenTotal: "2.2713" },
  { geneTranscript: "NM_014332.3", parsimonySitesP: "0.151515151515152", constantSitesP: "0.742424242424242", distinctSitesP: "0.265151515151515", brLenTotal: "0.4475" },
  { geneTranscript: "NM_002145.4", parsimonySitesP: "0.228464419475655", constantSitesP: "0.613295880149813", distinctSitesP: "0.357677902621723", brLenTotal: "0.8597" },
  { geneTranscript: "NM_001613.4", parsimonySitesP: "0.162687886825818", constantSitesP: "0.765694076038904", distinctSitesP: "0.219274977895668", brLenTotal: "0.6996" },
  { geneTranscript: "NM_005926.3", parsimonySitesP: "0.122247532270311", constantSitesP: "0.774487471526196", distinctSitesP: "0.191343963553531", brLenTotal: "0.4025" },
  { geneTranscript: "NM_001080836.3", parsimonySitesP: "0.140151515151515", constantSitesP: "0.712121212121212", distinctSitesP: "0.310606060606061", brLenTotal: "0.5495" },
  { geneTranscript: "NM_001377376.1", parsimonySitesP: "0.271332694151486", constantSitesP: "0.539469479066795", distinctSitesP: "0.383828699264941", brLenTotal: "1.1121" },
  { geneTranscript: "NM_030665.4", parsimonySitesP: "0.238020286813571", constantSitesP: "0.642882126617698", distinctSitesP: "0.259706190975866", brLenTotal: "1.0597" },
  { geneTranscript: "NM_020663.5", parsimonySitesP: "0.163551401869159", constantSitesP: "0.744548286604361", distinctSitesP: "0.261682242990654", brLenTotal: "0.6084" },
  { geneTranscript: "NM_021982.3", parsimonySitesP: "0.183897529734675", constantSitesP: "0.677340652637999", distinctSitesP: "0.259835315645014", brLenTotal: "0.5929" },
  { geneTranscript: "NM_002822.5", parsimonySitesP: "0.107619047619048", constantSitesP: "0.814285714285714", distinctSitesP: "0.16952380952381", brLenTotal: "0.3359" },
  { geneTranscript: "NM_001007524.2", parsimonySitesP: "0.215633423180593", constantSitesP: "0.562443845462713", distinctSitesP: "0.378256963162624", brLenTotal: "1.1861" },
  { geneTranscript: "NM_001659.3", parsimonySitesP: "0.132596685082873", constantSitesP: "0.786372007366483", distinctSitesP: "0.202578268876611", brLenTotal: "0.5292" },
  { geneTranscript: "NM_002706.6", parsimonySitesP: "0.128044537230341", constantSitesP: "0.768963117606124", distinctSitesP: "0.167014613778706", brLenTotal: "0.3875" },
  { geneTranscript: "NM_001278639.2", parsimonySitesP: "0.197841726618705", constantSitesP: "0.664268585131894", distinctSitesP: "0.314148681055156", brLenTotal: "0.9491" },
  { geneTranscript: "NM_020244.3", parsimonySitesP: "0.169950738916256", constantSitesP: "0.711822660098522", distinctSitesP: "0.278325123152709", brLenTotal: "0.5000" },
  { geneTranscript: "NM_017988.6", parsimonySitesP: "0.154287764621457", constantSitesP: "0.722640832436311", distinctSitesP: "0.22891998564765", brLenTotal: "0.5231" },
  { geneTranscript: "NM_006097.5", parsimonySitesP: "0.218992248062016", constantSitesP: "0.656976744186047", distinctSitesP: "0.325581395348837", brLenTotal: "0.9781" },
  { geneTranscript: "NM_001136018.4", parsimonySitesP: "0.246153846153846", constantSitesP: "0.613919413919414", distinctSitesP: "0.371428571428571", brLenTotal: "1.0011" },
  { geneTranscript: "NM_001167.4", parsimonySitesP: "0.134808853118712", constantSitesP: "0.707578806170355", distinctSitesP: "0.240778001341382", brLenTotal: "0.4893" },
  { geneTranscript: "NM_014947.5", parsimonySitesP: "0.101286173633441", constantSitesP: "0.810825294748124", distinctSitesP: "0.151661307609861", brLenTotal: "0.3444" },
  { geneTranscript: "NM_138281.3", parsimonySitesP: "0.252777777777778", constantSitesP: "0.555555555555556", distinctSitesP: "0.386111111111111", brLenTotal: "0.9249" },
  { geneTranscript: "NM_003860.4", parsimonySitesP: "0.149812734082397", constantSitesP: "0.711610486891386", distinctSitesP: "0.280898876404494", brLenTotal: "0.5081" },
  { geneTranscript: "NM_004595.5", parsimonySitesP: "0.177595628415301", constantSitesP: "0.675774134790528", distinctSitesP: "0.295081967213115", brLenTotal: "0.6848" },
  { geneTranscript: "NM_001105577.2", parsimonySitesP: "0.279383429672447", constantSitesP: "0.491329479768786", distinctSitesP: "0.48747591522158", brLenTotal: "1.0589" },
  { geneTranscript: "NM_023004.6", parsimonySitesP: "0.267089499647639", constantSitesP: "0.59830866807611", distinctSitesP: "0.343904157857646", brLenTotal: "1.3578" },
  { geneTranscript: "NM_001346252.4", parsimonySitesP: "0.150424348844015", constantSitesP: "0.737196371085748", distinctSitesP: "0.222709979514194", brLenTotal: "0.5551" },
  { geneTranscript: "NM_015107.3", parsimonySitesP: "0.138671875", constantSitesP: "0.759765625", distinctSitesP: "0.184244791666667", brLenTotal: "0.4720" },
  { geneTranscript: "NM_000169.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.652680652680653", distinctSitesP: "0.312354312354312", brLenTotal: "0.6748" },
  { geneTranscript: "NM_001321635.2", parsimonySitesP: "0.174934725848564", constantSitesP: "0.690165361183638", distinctSitesP: "0.31331592689295", brLenTotal: "0.5954" },
  { geneTranscript: "NM_006252.4", parsimonySitesP: "0.234299516908213", constantSitesP: "0.692632850241546", distinctSitesP: "0.225845410628019", brLenTotal: "0.7646" },
  { geneTranscript: "NM_001037.5", parsimonySitesP: "0.154434250764526", constantSitesP: "0.779816513761468", distinctSitesP: "0.218654434250765", brLenTotal: "0.6683" },
  { geneTranscript: "NM_004359.2", parsimonySitesP: "0.206214689265537", constantSitesP: "0.704802259887006", distinctSitesP: "0.290960451977401", brLenTotal: "1.1367" },
  { geneTranscript: "NM_014516.4", parsimonySitesP: "0.146525011066844", constantSitesP: "0.762726870296591", distinctSitesP: "0.207613988490483", brLenTotal: "0.6611" },
  { geneTranscript: "NM_005827.4", parsimonySitesP: "0.13871635610766", constantSitesP: "0.755693581780538", distinctSitesP: "0.217391304347826", brLenTotal: "0.4640" },
  { geneTranscript: "NM_004537.7", parsimonySitesP: "0.0861040068201194", constantSitesP: "0.791133844842285", distinctSitesP: "0.196078431372549", brLenTotal: "0.3218" },
  { geneTranscript: "NM_004247.4", parsimonySitesP: "0.136831275720165", constantSitesP: "0.77880658436214", distinctSitesP: "0.17798353909465", brLenTotal: "0.5417" },
  { geneTranscript: "NM_015407.5", parsimonySitesP: "0.243542435424354", constantSitesP: "0.590405904059041", distinctSitesP: "0.377613776137761", brLenTotal: "0.9625" },
  { geneTranscript: "NM_014235.5", parsimonySitesP: "0.18895966029724", constantSitesP: "0.679405520169851", distinctSitesP: "0.301486199575372", brLenTotal: "0.6445" },
  { geneTranscript: "NM_022763.4", parsimonySitesP: "0.190199335548173", constantSitesP: "0.704318936877076", distinctSitesP: "0.266888150609081", brLenTotal: "0.7511" },
  { geneTranscript: "NM_002792.4", parsimonySitesP: "0.154569892473118", constantSitesP: "0.731182795698925", distinctSitesP: "0.258064516129032", brLenTotal: "0.7575" },
  { geneTranscript: "NM_004331.3", parsimonySitesP: "0.164383561643836", constantSitesP: "0.724505327245053", distinctSitesP: "0.266362252663623", brLenTotal: "0.6162" },
  { geneTranscript: "NM_014003.4", parsimonySitesP: "0.161912523770714", constantSitesP: "0.759576202118989", distinctSitesP: "0.191795707688128", brLenTotal: "0.6928" },
  { geneTranscript: "NM_024758.5", parsimonySitesP: "0.239583333333333", constantSitesP: "0.626893939393939", distinctSitesP: "0.356060606060606", brLenTotal: "0.9122" },
  { geneTranscript: "NM_194434.3", parsimonySitesP: "0.105756358768407", constantSitesP: "0.808567603748327", distinctSitesP: "0.180722891566265", brLenTotal: "0.3475" },
  { geneTranscript: "NM_017784.5", parsimonySitesP: "0.178446771378709", constantSitesP: "0.725130890052356", distinctSitesP: "0.238219895287958", brLenTotal: "0.7298" },
  { geneTranscript: "NM_139319.3", parsimonySitesP: "0.179400113186191", constantSitesP: "0.715336728919072", distinctSitesP: "0.250707413695529", brLenTotal: "0.6227" },
  { geneTranscript: "NM_016339.6", parsimonySitesP: "0.14551863041289", constantSitesP: "0.748237663645519", distinctSitesP: "0.213997985901309", brLenTotal: "0.5394" },
  { geneTranscript: "NM_001743.6", parsimonySitesP: "0.170022371364653", constantSitesP: "0.776286353467562", distinctSitesP: "0.225950782997763", brLenTotal: "0.6728" },
  { geneTranscript: "NM_018972.4", parsimonySitesP: "0.125698324022346", constantSitesP: "0.790502793296089", distinctSitesP: "0.18342644320298", brLenTotal: "0.4080" },
  { geneTranscript: "NM_001205293.3", parsimonySitesP: "0.163712350482778", constantSitesP: "0.749387519815535", distinctSitesP: "0.194552529182879", brLenTotal: "0.6303" },
  { geneTranscript: "NM_001907.3", parsimonySitesP: "0.300505050505051", constantSitesP: "0.439393939393939", distinctSitesP: "0.564393939393939", brLenTotal: "1.1963" },
  { geneTranscript: "NM_012255.5", parsimonySitesP: "0.121754385964912", constantSitesP: "0.781052631578947", distinctSitesP: "0.174385964912281", brLenTotal: "0.4314" },
  { geneTranscript: "NM_001384272.1", parsimonySitesP: "0.186186186186186", constantSitesP: "0.704204204204204", distinctSitesP: "0.253003003003003", brLenTotal: "0.6804" },
  { geneTranscript: "NM_006827.6", parsimonySitesP: "0.175038051750381", constantSitesP: "0.687975646879756", distinctSitesP: "0.275494672754947", brLenTotal: "0.5685" },
  { geneTranscript: "NM_022454.4", parsimonySitesP: "0.235909822866345", constantSitesP: "0.612721417069243", distinctSitesP: "0.356682769726248", brLenTotal: "0.9491" },
  { geneTranscript: "NM_024586.6", parsimonySitesP: "0.124094202898551", constantSitesP: "0.771739130434783", distinctSitesP: "0.20018115942029", brLenTotal: "0.4116" },
  { geneTranscript: "NM_020777.3", parsimonySitesP: "0.238136324417601", constantSitesP: "0.61518550474547", distinctSitesP: "0.33879781420765", brLenTotal: "1.0700" },
  { geneTranscript: "NM_033274.5", parsimonySitesP: "0.197893972403776", constantSitesP: "0.67683369644154", distinctSitesP: "0.281045751633987", brLenTotal: "0.7736" },
  { geneTranscript: "NM_020949.3", parsimonySitesP: "0.188067444876783", constantSitesP: "0.718547341115435", distinctSitesP: "0.229139645482058", brLenTotal: "0.8121" },
  { geneTranscript: "NM_005847.5", parsimonySitesP: "0.212931995540691", constantSitesP: "0.679487179487179", distinctSitesP: "0.282608695652174", brLenTotal: "0.8768" },
  { geneTranscript: "NM_004982.4", parsimonySitesP: "0.148584905660377", constantSitesP: "0.770440251572327", distinctSitesP: "0.202044025157233", brLenTotal: "0.5011" },
  { geneTranscript: "NM_002511.4", parsimonySitesP: "0.255555555555556", constantSitesP: "0.572649572649573", distinctSitesP: "0.383760683760684", brLenTotal: "0.9303" },
  { geneTranscript: "NM_005985.4", parsimonySitesP: "0.224747474747475", constantSitesP: "0.640151515151515", distinctSitesP: "0.316919191919192", brLenTotal: "1.0139" },
  { geneTranscript: "NM_004924.6", parsimonySitesP: "0.179656055616539", constantSitesP: "0.736187339919502", distinctSitesP: "0.221002561287962", brLenTotal: "1.0406" },
  { geneTranscript: "NM_001145365.3", parsimonySitesP: "0.107810781078108", constantSitesP: "0.792079207920792", distinctSitesP: "0.161166116611661", brLenTotal: "0.3935" },
  { geneTranscript: "NM_004073.4", parsimonySitesP: "0.169762641898865", constantSitesP: "0.696078431372549", distinctSitesP: "0.231682146542828", brLenTotal: "0.6248" },
  { geneTranscript: "NM_052970.5", parsimonySitesP: "0.190962099125364", constantSitesP: "0.700680272108844", distinctSitesP: "0.243926141885326", brLenTotal: "0.8021" },
  { geneTranscript: "NM_153487.4", parsimonySitesP: "0.144851657940663", constantSitesP: "0.754624781849913", distinctSitesP: "0.194066317626527", brLenTotal: "0.5820" },
  { geneTranscript: "NM_032330.3", parsimonySitesP: "0.223118279569892", constantSitesP: "0.65994623655914", distinctSitesP: "0.30241935483871", brLenTotal: "0.6509" },
  { geneTranscript: "NM_145254.3", parsimonySitesP: "0.136574074074074", constantSitesP: "0.747685185185185", distinctSitesP: "0.259259259259259", brLenTotal: "0.5031" },
  { geneTranscript: "NM_001356.5", parsimonySitesP: "0.147532729103726", constantSitesP: "0.737160120845921", distinctSitesP: "0.231117824773414", brLenTotal: "0.6233" },
  { geneTranscript: "NM_014303.4", parsimonySitesP: "0.224489795918367", constantSitesP: "0.613945578231293", distinctSitesP: "0.341269841269841", brLenTotal: "0.9717" },
  { geneTranscript: "NM_012288.4", parsimonySitesP: "0.125225225225225", constantSitesP: "0.775675675675676", distinctSitesP: "0.187387387387387", brLenTotal: "0.4125" },
  { geneTranscript: "NM_003620.4", parsimonySitesP: "0.162534435261708", constantSitesP: "0.720110192837466", distinctSitesP: "0.234159779614325", brLenTotal: "0.4832" },
  { geneTranscript: "NM_000796.6", parsimonySitesP: "0.214166666666667", constantSitesP: "0.66", distinctSitesP: "0.3175", brLenTotal: "0.7535" },
  { geneTranscript: "NM_001194958.2", parsimonySitesP: "0.214780600461894", constantSitesP: "0.674364896073903", distinctSitesP: "0.288683602771363", brLenTotal: "1.0954" },
  { geneTranscript: "NM_001136107.2", parsimonySitesP: "0.190812720848057", constantSitesP: "0.65017667844523", distinctSitesP: "0.306242638398115", brLenTotal: "0.7615" },
  { geneTranscript: "NM_014306.5", parsimonySitesP: "0.138613861386139", constantSitesP: "0.73993399339934", distinctSitesP: "0.23036303630363", brLenTotal: "0.5294" },
  { geneTranscript: "NM_018476.4", parsimonySitesP: "0.258666666666667", constantSitesP: "0.56", distinctSitesP: "0.445333333333333", brLenTotal: "1.1069" },
  { geneTranscript: "NM_001348323.3", parsimonySitesP: "0.0924044508950169", constantSitesP: "0.833736494113853", distinctSitesP: "0.119174326721497", brLenTotal: "0.2985" },
  { geneTranscript: "NM_001001976.3", parsimonySitesP: "0.184684684684685", constantSitesP: "0.696911196911197", distinctSitesP: "0.287644787644788", brLenTotal: "0.6242" },
  { geneTranscript: "NM_001071.4", parsimonySitesP: "0.181043663471778", constantSitesP: "0.668796592119276", distinctSitesP: "0.299254526091587", brLenTotal: "0.6397" },
  { geneTranscript: "NM_024326.4", parsimonySitesP: "0.207777777777778", constantSitesP: "0.637777777777778", distinctSitesP: "0.27", brLenTotal: "0.7308" },
  { geneTranscript: "NM_001394336.1", parsimonySitesP: "0.171544715447154", constantSitesP: "0.723577235772358", distinctSitesP: "0.249593495934959", brLenTotal: "0.6879" },
  { geneTranscript: "NM_001364677.1", parsimonySitesP: "0.188712522045855", constantSitesP: "0.661375661375661", distinctSitesP: "0.324514991181658", brLenTotal: "0.7183" },
  { geneTranscript: "NM_001271592.2", parsimonySitesP: "0.235294117647059", constantSitesP: "0.583333333333333", distinctSitesP: "0.392156862745098", brLenTotal: "0.9042" },
  { geneTranscript: "NM_001042492.3", parsimonySitesP: "0.112128683808853", constantSitesP: "0.796054948925678", distinctSitesP: "0.132323588117882", brLenTotal: "0.3646" },
  { geneTranscript: "NM_001102371.2", parsimonySitesP: "0.261208576998051", constantSitesP: "0.597953216374269", distinctSitesP: "0.362573099415205", brLenTotal: "1.0213" },
  { geneTranscript: "NM_002641.4", parsimonySitesP: "0.170798898071625", constantSitesP: "0.699035812672176", distinctSitesP: "0.262396694214876", brLenTotal: "0.5713" },
  { geneTranscript: "NM_001394376.1", parsimonySitesP: "0.103098290598291", constantSitesP: "0.826388888888889", distinctSitesP: "0.153846153846154", brLenTotal: "0.4307" },
  { geneTranscript: "NM_080385.5", parsimonySitesP: "0.240825688073394", constantSitesP: "0.587155963302752", distinctSitesP: "0.367737003058104", brLenTotal: "0.9309" },
  { geneTranscript: "NM_003092.5", parsimonySitesP: "0.168888888888889", constantSitesP: "0.677037037037037", distinctSitesP: "0.300740740740741", brLenTotal: "0.6281" },
  { geneTranscript: "NM_004960.4", parsimonySitesP: "0.156527249683143", constantSitesP: "0.729404309252218", distinctSitesP: "0.279467680608365", brLenTotal: "0.6472" },
  { geneTranscript: "NM_002192.4", parsimonySitesP: "0.123630672926448", constantSitesP: "0.793427230046948", distinctSitesP: "0.176838810641628", brLenTotal: "0.4216" },
  { geneTranscript: "NM_032439.4", parsimonySitesP: "0.0833333333333333", constantSitesP: "0.843085106382979", distinctSitesP: "0.144503546099291", brLenTotal: "0.2425" },
  { geneTranscript: "NM_022152.6", parsimonySitesP: "0.184351554126474", constantSitesP: "0.734190782422294", distinctSitesP: "0.280814576634512", brLenTotal: "0.7547" },
  { geneTranscript: "NM_014608.6", parsimonySitesP: "0.197126895450918", constantSitesP: "0.717744080872572", distinctSitesP: "0.237031125299282", brLenTotal: "1.0223" },
  { geneTranscript: "NM_024608.4", parsimonySitesP: "0.305982905982906", constantSitesP: "0.507692307692308", distinctSitesP: "0.442735042735043", brLenTotal: "1.2308" },
  { geneTranscript: "NM_007184.4", parsimonySitesP: "0.209663120567376", constantSitesP: "0.678856382978723", distinctSitesP: "0.263297872340426", brLenTotal: "0.9150" },
  { geneTranscript: "NM_006541.5", parsimonySitesP: "0.18407960199005", constantSitesP: "0.702487562189055", distinctSitesP: "0.295522388059701", brLenTotal: "0.6090" },
  { geneTranscript: "NM_017629.4", parsimonySitesP: "0.116531165311653", constantSitesP: "0.780487804878049", distinctSitesP: "0.168408826945412", brLenTotal: "0.4374" },
  { geneTranscript: "NM_006113.5", parsimonySitesP: "0.135379771743408", constantSitesP: "0.765053128689492", distinctSitesP: "0.221566312475403", brLenTotal: "0.4768" },
  { geneTranscript: "NM_015564.3", parsimonySitesP: "0.0510335917312661", constantSitesP: "0.890826873385013", distinctSitesP: "0.0930232558139535", brLenTotal: "0.1610" },
  { geneTranscript: "NM_001190228.2", parsimonySitesP: "0.386752136752137", constantSitesP: "0.397435897435897", distinctSitesP: "0.587606837606838", brLenTotal: "2.0375" },
  { geneTranscript: "NM_001128922.2", parsimonySitesP: "0.270896273917422", constantSitesP: "0.573514602215509", distinctSitesP: "0.321752265861027", brLenTotal: "1.0306" },
  { geneTranscript: "NM_001394014.1", parsimonySitesP: "0.124857468643101", constantSitesP: "0.785442797415431", distinctSitesP: "0.152603572786013", brLenTotal: "0.4005" },
  { geneTranscript: "NM_002410.5", parsimonySitesP: "0.12280701754386", constantSitesP: "0.785874943769681", distinctSitesP: "0.170490328385065", brLenTotal: "0.4523" },
  { geneTranscript: "NM_015476.4", parsimonySitesP: "0.184444444444444", constantSitesP: "0.68", distinctSitesP: "0.324444444444444", brLenTotal: "0.5979" },
  { geneTranscript: "NM_001384648.1", parsimonySitesP: "0.16595865193996", constantSitesP: "0.740583404134806", distinctSitesP: "0.192013593882753", brLenTotal: "0.7194" },
  { geneTranscript: "NM_020673.3", parsimonySitesP: "0.111683848797251", constantSitesP: "0.798969072164948", distinctSitesP: "0.176975945017182", brLenTotal: "0.3915" },
  { geneTranscript: "NM_020441.3", parsimonySitesP: "0.229038854805726", constantSitesP: "0.635310156782549", distinctSitesP: "0.30879345603272", brLenTotal: "1.1112" },
  { geneTranscript: "NM_016525.5", parsimonySitesP: "0.117529880478088", constantSitesP: "0.745019920318725", distinctSitesP: "0.227091633466135", brLenTotal: "0.4150" },
  { geneTranscript: "NM_001282300.2", parsimonySitesP: "0.294871794871795", constantSitesP: "0.499013806706114", distinctSitesP: "0.460552268244576", brLenTotal: "1.0489" },
  { geneTranscript: "NM_000370.3", parsimonySitesP: "0.21462829736211", constantSitesP: "0.628297362110312", distinctSitesP: "0.360911270983213", brLenTotal: "0.7533" },
  { geneTranscript: "NM_182581.4", parsimonySitesP: "0.334610472541507", constantSitesP: "0.467432950191571", distinctSitesP: "0.494252873563218", brLenTotal: "1.1484" },
  { geneTranscript: "NM_002273.4", parsimonySitesP: "0.318840579710145", constantSitesP: "0.465148378191856", distinctSitesP: "0.488612836438923", brLenTotal: "1.7589" },
  { geneTranscript: "NM_170783.4", parsimonySitesP: "0.232804232804233", constantSitesP: "0.592592592592593", distinctSitesP: "0.365079365079365", brLenTotal: "0.8415" },
  { geneTranscript: "NM_013280.5", parsimonySitesP: "0.184470820969337", constantSitesP: "0.665182987141444", distinctSitesP: "0.231454005934718", brLenTotal: "1.3941" },
  { geneTranscript: "NM_001037333.3", parsimonySitesP: "0.154828411811652", constantSitesP: "0.764831072093642", distinctSitesP: "0.189412077680234", brLenTotal: "0.6972" },
  { geneTranscript: "NM_003274.5", parsimonySitesP: "0.206513105639396", constantSitesP: "0.681758009001853", distinctSitesP: "0.293354514164681", brLenTotal: "0.8668" },
  { geneTranscript: "NM_175736.5", parsimonySitesP: "0.153846153846154", constantSitesP: "0.746835443037975", distinctSitesP: "0.190522557611165", brLenTotal: "0.5596" },
  { geneTranscript: "NM_020064.4", parsimonySitesP: "0.116207951070336", constantSitesP: "0.787971457696228", distinctSitesP: "0.191641182466871", brLenTotal: "0.4378" },
  { geneTranscript: "NM_001385305.1", parsimonySitesP: "0.135910224438903", constantSitesP: "0.762261014131338", distinctSitesP: "0.193266832917706", brLenTotal: "0.4573" },
  { geneTranscript: "NM_130837.3", parsimonySitesP: "0.141543513957307", constantSitesP: "0.764860426929392", distinctSitesP: "0.202955665024631", brLenTotal: "0.4566" },
  { geneTranscript: "NM_024628.6", parsimonySitesP: "0.236227824463119", constantSitesP: "0.61624649859944", distinctSitesP: "0.34500466853408", brLenTotal: "0.8623" },
  { geneTranscript: "NM_015680.6", parsimonySitesP: "0.210569105691057", constantSitesP: "0.632520325203252", distinctSitesP: "0.298373983739837", brLenTotal: "0.7862" },
  { geneTranscript: "NM_000599.4", parsimonySitesP: "0.133578431372549", constantSitesP: "0.769607843137255", distinctSitesP: "0.212009803921569", brLenTotal: "0.5286" },
  { geneTranscript: "NM_001658.4", parsimonySitesP: "0.173112338858195", constantSitesP: "0.694290976058932", distinctSitesP: "0.285451197053407", brLenTotal: "0.9349" },
  { geneTranscript: "NM_152511.5", parsimonySitesP: "0.287234042553191", constantSitesP: "0.597517730496454", distinctSitesP: "0.368794326241135", brLenTotal: "1.2786" },
  { geneTranscript: "NM_014305.4", parsimonySitesP: "0.113333333333333", constantSitesP: "0.780952380952381", distinctSitesP: "0.22", brLenTotal: "0.4416" },
  { geneTranscript: "NM_003917.5", parsimonySitesP: "0.211889596602972", constantSitesP: "0.627176220806794", distinctSitesP: "0.300636942675159", brLenTotal: "0.6835" },
  { geneTranscript: "NM_006195.6", parsimonySitesP: "0.097542242703533", constantSitesP: "0.852534562211982", distinctSitesP: "0.118279569892473", brLenTotal: "0.2937" },
  { geneTranscript: "NM_001363818.2", parsimonySitesP: "0.118589743589744", constantSitesP: "0.778846153846154", distinctSitesP: "0.189102564102564", brLenTotal: "0.3707" },
  { geneTranscript: "NM_015959.4", parsimonySitesP: "0.18018018018018", constantSitesP: "0.711711711711712", distinctSitesP: "0.269144144144144", brLenTotal: "0.5427" },
  { geneTranscript: "NM_014571.4", parsimonySitesP: "0.234756097560976", constantSitesP: "0.591463414634146", distinctSitesP: "0.359756097560976", brLenTotal: "0.9311" },
  { geneTranscript: "NM_003038.5", parsimonySitesP: "0.222431077694236", constantSitesP: "0.68671679197995", distinctSitesP: "0.280075187969925", brLenTotal: "0.7814" },
  { geneTranscript: "NM_001145344.1", parsimonySitesP: "0.332535885167464", constantSitesP: "0.573365231259968", distinctSitesP: "0.388357256778309", brLenTotal: "1.1632" },
  { geneTranscript: "NM_001252102.2", parsimonySitesP: "0.187307455329636", constantSitesP: "0.721298007804477", distinctSitesP: "0.213801601971657", brLenTotal: "0.8254" },
  { geneTranscript: "NM_001282116.2", parsimonySitesP: "0.0716510903426791", constantSitesP: "0.85981308411215", distinctSitesP: "0.109924343569203", brLenTotal: "0.2586" },
  { geneTranscript: "NM_005034.4", parsimonySitesP: "0.0977011494252874", constantSitesP: "0.793103448275862", distinctSitesP: "0.270114942528736", brLenTotal: "0.3652" },
  { geneTranscript: "NM_005805.6", parsimonySitesP: "0.0559139784946237", constantSitesP: "0.86989247311828", distinctSitesP: "0.12258064516129", brLenTotal: "0.1923" },
  { geneTranscript: "NM_024948.4", parsimonySitesP: "0.101123595505618", constantSitesP: "0.820973782771536", distinctSitesP: "0.159550561797753", brLenTotal: "0.3231" },
  { geneTranscript: "NM_001762.4", parsimonySitesP: "0.207156308851224", constantSitesP: "0.637790332705587", distinctSitesP: "0.325172630257376", brLenTotal: "0.8429" },
  { geneTranscript: "NM_002040.4", parsimonySitesP: "0.138766519823789", constantSitesP: "0.73201174743025", distinctSitesP: "0.243759177679883", brLenTotal: "0.4979" },
  { geneTranscript: "NM_002957.6", parsimonySitesP: "0.1998556998557", constantSitesP: "0.706349206349206", distinctSitesP: "0.267676767676768", brLenTotal: "1.2432" },
  { geneTranscript: "NM_014245.5", parsimonySitesP: "0.135693215339233", constantSitesP: "0.63716814159292", distinctSitesP: "0.339233038348083", brLenTotal: "0.6366" },
  { geneTranscript: "NM_016243.3", parsimonySitesP: "0.167213114754098", constantSitesP: "0.724590163934426", distinctSitesP: "0.237158469945355", brLenTotal: "0.5283" },
  { geneTranscript: "NM_032380.5", parsimonySitesP: "0.18528027385537", constantSitesP: "0.69191270860077", distinctSitesP: "0.281129653401797", brLenTotal: "0.6026" },
  { geneTranscript: "NM_001377.3", parsimonySitesP: "0.166937543533782", constantSitesP: "0.709465211670923", distinctSitesP: "0.212057890256172", brLenTotal: "0.5665" },
  { geneTranscript: "NM_058170.4", parsimonySitesP: "0.155749636098981", constantSitesP: "0.72707423580786", distinctSitesP: "0.222707423580786", brLenTotal: "0.5861" },
  { geneTranscript: "NM_001384.5", parsimonySitesP: "0.232447171097478", constantSitesP: "0.603271983640082", distinctSitesP: "0.349011588275392", brLenTotal: "0.7583" },
  { geneTranscript: "NM_018129.4", parsimonySitesP: "0.186462324393359", constantSitesP: "0.679438058748404", distinctSitesP: "0.296296296296296", brLenTotal: "0.6278" },
  { geneTranscript: "NM_173560.4", parsimonySitesP: "0.189295977011494", constantSitesP: "0.689295977011494", distinctSitesP: "0.283045977011494", brLenTotal: "0.6818" },
  { geneTranscript: "NM_004310.5", parsimonySitesP: "0.197207678883072", constantSitesP: "0.731239092495637", distinctSitesP: "0.270506108202443", brLenTotal: "0.9862" },
  { geneTranscript: "NM_006907.4", parsimonySitesP: "0.274817136886102", constantSitesP: "0.64367816091954", distinctSitesP: "0.345872518286311", brLenTotal: "1.2199" },
  { geneTranscript: "NM_203434.3", parsimonySitesP: "0.126237623762376", constantSitesP: "0.641914191419142", distinctSitesP: "0.355610561056106", brLenTotal: "0.6719" },
  { geneTranscript: "NM_014214.3", parsimonySitesP: "0.221064814814815", constantSitesP: "0.686342592592593", distinctSitesP: "0.305555555555556", brLenTotal: "0.7979" },
  { geneTranscript: "NM_000251.3", parsimonySitesP: "0.136688079942898", constantSitesP: "0.74768022840828", distinctSitesP: "0.200214132762313", brLenTotal: "0.4471" },
  { geneTranscript: "NM_032646.6", parsimonySitesP: "0.245942571785268", constantSitesP: "0.607990012484395", distinctSitesP: "0.3458177278402", brLenTotal: "1.0887" },
  { geneTranscript: "NM_177477.4", parsimonySitesP: "0.32183908045977", constantSitesP: "0.563218390804598", distinctSitesP: "0.459770114942529", brLenTotal: "1.3054" },
  { geneTranscript: "NM_173569.4", parsimonySitesP: "0.130660727542687", constantSitesP: "0.767384310814155", distinctSitesP: "0.175204157386785", brLenTotal: "0.3948" },
  { geneTranscript: "NM_001938.3", parsimonySitesP: "0.106060606060606", constantSitesP: "0.768939393939394", distinctSitesP: "0.208333333333333", brLenTotal: "0.3754" },
  { geneTranscript: "NM_024546.4", parsimonySitesP: "0.17722681359045", constantSitesP: "0.671258034894399", distinctSitesP: "0.265381083562902", brLenTotal: "0.5667" },
  { geneTranscript: "NM_018271.5", parsimonySitesP: "0.245179063360882", constantSitesP: "0.595730027548209", distinctSitesP: "0.348484848484848", brLenTotal: "1.0449" },
  { geneTranscript: "NM_024315.4", parsimonySitesP: "0.135593220338983", constantSitesP: "0.76271186440678", distinctSitesP: "0.242937853107345", brLenTotal: "0.3896" },
  { geneTranscript: "NM_004469.5", parsimonySitesP: "0.210922787193974", constantSitesP: "0.63653483992467", distinctSitesP: "0.312617702448211", brLenTotal: "0.6760" },
  { geneTranscript: "NM_000075.4", parsimonySitesP: "0.14961496149615", constantSitesP: "0.735973597359736", distinctSitesP: "0.242024202420242", brLenTotal: "0.4936" },
  { geneTranscript: "NM_016044.3", parsimonySitesP: "0.210191082802548", constantSitesP: "0.619957537154989", distinctSitesP: "0.342887473460722", brLenTotal: "0.8543" },
  { geneTranscript: "NM_000369.5", parsimonySitesP: "0.221640488656195", constantSitesP: "0.618237347294939", distinctSitesP: "0.319808027923211", brLenTotal: "0.8041" },
  { geneTranscript: "NM_001135553.4", parsimonySitesP: "0.174757281553398", constantSitesP: "0.739482200647249", distinctSitesP: "0.233009708737864", brLenTotal: "0.7188" },
  { geneTranscript: "NM_001350994.2", parsimonySitesP: "0.16504854368932", constantSitesP: "0.760517799352751", distinctSitesP: "0.245954692556634", brLenTotal: "0.6470" },
  { geneTranscript: "NM_004514.4", parsimonySitesP: "0.257575757575758", constantSitesP: "0.56969696969697", distinctSitesP: "0.377777777777778", brLenTotal: "1.3337" },
  { geneTranscript: "NM_152556.3", parsimonySitesP: "0.102057613168724", constantSitesP: "0.807407407407407", distinctSitesP: "0.162962962962963", brLenTotal: "0.3106" },
  { geneTranscript: "NM_002752.5", parsimonySitesP: "0.169811320754717", constantSitesP: "0.713050314465409", distinctSitesP: "0.224056603773585", brLenTotal: "0.8370" },
  { geneTranscript: "NM_002448.3", parsimonySitesP: "0.195819581958196", constantSitesP: "0.666666666666667", distinctSitesP: "0.298129812981298", brLenTotal: "0.8603" },
  { geneTranscript: "NM_005584.5", parsimonySitesP: "0.142061281337047", constantSitesP: "0.728876508820799", distinctSitesP: "0.171773444753946", brLenTotal: "0.5948" },
  { geneTranscript: "NM_018845.4", parsimonySitesP: "0.194570135746606", constantSitesP: "0.678733031674208", distinctSitesP: "0.273001508295626", brLenTotal: "0.5445" },
  { geneTranscript: "NM_032409.3", parsimonySitesP: "0.261617900172117", constantSitesP: "0.578313253012048", distinctSitesP: "0.363166953528399", brLenTotal: "0.9804" },
  { geneTranscript: "NM_004531.5", parsimonySitesP: "0.225177304964539", constantSitesP: "0.611702127659574", distinctSitesP: "0.358156028368794", brLenTotal: "0.7991" },
  { geneTranscript: "NM_002364.5", parsimonySitesP: "0.557993730407524", constantSitesP: "0.272727272727273", distinctSitesP: "0.729362591431557", brLenTotal: "3.1777" },
  { geneTranscript: "NM_032875.3", parsimonySitesP: "0.0802752293577982", constantSitesP: "0.847094801223242", distinctSitesP: "0.149082568807339", brLenTotal: "0.2592" },
  { geneTranscript: "NM_003099.5", parsimonySitesP: "0.142401021711367", constantSitesP: "0.755427841634738", distinctSitesP: "0.213282247765006", brLenTotal: "0.5055" },
  { geneTranscript: "NM_004269.4", parsimonySitesP: "0.14898177920686", constantSitesP: "0.766345123258307", distinctSitesP: "0.217577706323687", brLenTotal: "0.5074" },
  { geneTranscript: "NM_138615.3", parsimonySitesP: "0.153266331658291", constantSitesP: "0.759073143495254", distinctSitesP: "0.180904522613065", brLenTotal: "0.6761" },
  { geneTranscript: "NM_001716.5", parsimonySitesP: "0.227598566308244", constantSitesP: "0.656810035842294", distinctSitesP: "0.323476702508961", brLenTotal: "0.9742" },
  { geneTranscript: "NM_001330078.2", parsimonySitesP: "0.11170095111701", constantSitesP: "0.81375801813758", distinctSitesP: "0.138907321389073", brLenTotal: "0.3977" },
  { geneTranscript: "NM_001824.5", parsimonySitesP: "0.191601049868766", constantSitesP: "0.73578302712161", distinctSitesP: "0.243219597550306", brLenTotal: "0.8842" },
  { geneTranscript: "NM_001048.4", parsimonySitesP: "0.158045977011494", constantSitesP: "0.767241379310345", distinctSitesP: "0.229885057471264", brLenTotal: "0.5765" },
  { geneTranscript: "NM_145259.3", parsimonySitesP: "0.169709263015551", constantSitesP: "0.739688979039892", distinctSitesP: "0.228532792427316", brLenTotal: "0.5251" },
  { geneTranscript: "NM_014948.4", parsimonySitesP: "0.234750462107209", constantSitesP: "0.62784966112138", distinctSitesP: "0.303758471965496", brLenTotal: "0.7985" },
  { geneTranscript: "NM_014460.4", parsimonySitesP: "0.18082788671024", constantSitesP: "0.736383442265795", distinctSitesP: "0.259259259259259", brLenTotal: "0.6272" },
  { geneTranscript: "NM_006656.6", parsimonySitesP: "0.289949385394071", constantSitesP: "0.543745480838756", distinctSitesP: "0.399855386840202", brLenTotal: "1.0227" },
  { geneTranscript: "NM_001032999.3", parsimonySitesP: "0.172549019607843", constantSitesP: "0.728851540616246", distinctSitesP: "0.235854341736695", brLenTotal: "0.6939" },
  { geneTranscript: "NM_004973.4", parsimonySitesP: "0.12011771000535", constantSitesP: "0.793739967897271", distinctSitesP: "0.159176029962547", brLenTotal: "0.5333" },
  { geneTranscript: "NM_014244.5", parsimonySitesP: "0.235067437379576", constantSitesP: "0.647398843930636", distinctSitesP: "0.290118359482521", brLenTotal: "1.0809" },
  { geneTranscript: "NM_001253875.2", parsimonySitesP: "0.141176470588235", constantSitesP: "0.76", distinctSitesP: "0.231372549019608", brLenTotal: "0.6217" },
  { geneTranscript: "NM_015960.3", parsimonySitesP: "0.15018315018315", constantSitesP: "0.725274725274725", distinctSitesP: "0.261294261294261", brLenTotal: "0.4829" },
  { geneTranscript: "NM_017934.7", parsimonySitesP: "0.13673805601318", constantSitesP: "0.769723595094271", distinctSitesP: "0.158887058392824", brLenTotal: "0.4600" },
  { geneTranscript: "NM_033212.4", parsimonySitesP: "0.189090909090909", constantSitesP: "0.706666666666667", distinctSitesP: "0.27030303030303", brLenTotal: "0.8482" },
  { geneTranscript: "NM_001170880.2", parsimonySitesP: "0.138888888888889", constantSitesP: "0.776094276094276", distinctSitesP: "0.18013468013468", brLenTotal: "0.5385" },
  { geneTranscript: "NM_000359.3", parsimonySitesP: "0.211750305997552", constantSitesP: "0.678498572011424", distinctSitesP: "0.270501835985312", brLenTotal: "0.8164" },
  { geneTranscript: "NM_001841.3", parsimonySitesP: "0.271296296296296", constantSitesP: "0.571296296296296", distinctSitesP: "0.402777777777778", brLenTotal: "0.9643" },
  { geneTranscript: "NM_005182.3", parsimonySitesP: "0.156565656565657", constantSitesP: "0.736111111111111", distinctSitesP: "0.243686868686869", brLenTotal: "0.5930" },
  { geneTranscript: "NM_004187.5", parsimonySitesP: "0.13482905982906", constantSitesP: "0.759401709401709", distinctSitesP: "0.158119658119658", brLenTotal: "0.4858" },
  { geneTranscript: "NM_033480.3", parsimonySitesP: "0.122044241037376", constantSitesP: "0.755911517925248", distinctSitesP: "0.204424103737605", brLenTotal: "0.4203" },
  { geneTranscript: "NM_002242.4", parsimonySitesP: "0.112037037037037", constantSitesP: "0.787962962962963", distinctSitesP: "0.178703703703704", brLenTotal: "0.3583" },
  { geneTranscript: "NM_022121.5", parsimonySitesP: "0.219343696027634", constantSitesP: "0.6286701208981", distinctSitesP: "0.343696027633851", brLenTotal: "0.8726" },
  { geneTranscript: "NM_001112704.2", parsimonySitesP: "0.155688622754491", constantSitesP: "0.745508982035928", distinctSitesP: "0.291417165668663", brLenTotal: "0.6315" },
  { geneTranscript: "NM_001349232.2", parsimonySitesP: "0.146989094357515", constantSitesP: "0.737316263632053", distinctSitesP: "0.211474632527264", brLenTotal: "0.5150" },
  { geneTranscript: "NM_020390.6", parsimonySitesP: "0.152505446623094", constantSitesP: "0.784313725490196", distinctSitesP: "0.217864923747277", brLenTotal: "0.4981" },
  { geneTranscript: "NM_021978.4", parsimonySitesP: "0.297855750487329", constantSitesP: "0.584405458089669", distinctSitesP: "0.381676413255361", brLenTotal: "1.3223" },
  { geneTranscript: "NM_018143.3", parsimonySitesP: "0.131826741996234", constantSitesP: "0.75376647834275", distinctSitesP: "0.187853107344633", brLenTotal: "0.4951" },
  { geneTranscript: "NM_001135032.2", parsimonySitesP: "0.230263157894737", constantSitesP: "0.616228070175439", distinctSitesP: "0.366228070175439", brLenTotal: "0.8634" },
  { geneTranscript: "NM_002035.4", parsimonySitesP: "0.157630522088353", constantSitesP: "0.759036144578313", distinctSitesP: "0.237951807228916", brLenTotal: "0.5097" },
  { geneTranscript: "NM_017803.5", parsimonySitesP: "0.183908045977011", constantSitesP: "0.680189317106153", distinctSitesP: "0.286004056795132", brLenTotal: "0.6518" },
  { geneTranscript: "NM_015297.3", parsimonySitesP: "0.194166666666667", constantSitesP: "0.6875", distinctSitesP: "0.2675", brLenTotal: "0.6565" },
  { geneTranscript: "NM_001098484.3", parsimonySitesP: "0.180105035526722", constantSitesP: "0.729070126660488", distinctSitesP: "0.236329935125116", brLenTotal: "0.7106" },
  { geneTranscript: "NM_001394966.1", parsimonySitesP: "0.161136023916293", constantSitesP: "0.698953662182362", distinctSitesP: "0.264275037369208", brLenTotal: "0.6077" },
  { geneTranscript: "NM_001382548.1", parsimonySitesP: "0.120478325859492", constantSitesP: "0.802391629297459", distinctSitesP: "0.178774289985052", brLenTotal: "0.6112" },
  { geneTranscript: "NM_001135575.2", parsimonySitesP: "0.172161172161172", constantSitesP: "0.706959706959707", distinctSitesP: "0.285714285714286", brLenTotal: "0.5347" },
  { geneTranscript: "NM_005911.6", parsimonySitesP: "0.0886075949367089", constantSitesP: "0.841350210970464", distinctSitesP: "0.142616033755274", brLenTotal: "0.2912" },
  { geneTranscript: "NM_002435.3", parsimonySitesP: "0.20488573680063", constantSitesP: "0.649330181245075", distinctSitesP: "0.281323877068558", brLenTotal: "0.6789" },
  { geneTranscript: "NM_058163.3", parsimonySitesP: "0.254799301919721", constantSitesP: "0.596858638743455", distinctSitesP: "0.394415357766143", brLenTotal: "0.7890" },
  { geneTranscript: "NM_001395253.1", parsimonySitesP: "0.256", constantSitesP: "0.581333333333333", distinctSitesP: "0.376", brLenTotal: "1.1551" },
  { geneTranscript: "NM_139159.5", parsimonySitesP: "0.217862481315396", constantSitesP: "0.692077727952167", distinctSitesP: "0.272421524663677", brLenTotal: "1.0331" },
  { geneTranscript: "NM_000986.4", parsimonySitesP: "0.148619957537155", constantSitesP: "0.717622080679406", distinctSitesP: "0.292993630573248", brLenTotal: "0.5146" },
  { geneTranscript: "NM_032558.3", parsimonySitesP: "0.16600790513834", constantSitesP: "0.706851119894598", distinctSitesP: "0.262187088274045", brLenTotal: "0.6101" },
  { geneTranscript: "NM_005381.3", parsimonySitesP: "0.17887323943662", constantSitesP: "0.667605633802817", distinctSitesP: "0.315023474178404", brLenTotal: "1.0368" },
  { geneTranscript: "NM_003427.5", parsimonySitesP: "0.167836257309942", constantSitesP: "0.74093567251462", distinctSitesP: "0.223976608187135", brLenTotal: "0.5729" },
  { geneTranscript: "NM_001161498.2", parsimonySitesP: "0.154150197628458", constantSitesP: "0.742424242424242", distinctSitesP: "0.218050065876153", brLenTotal: "0.6368" },
  { geneTranscript: "NM_001024736.2", parsimonySitesP: "0.222222222222222", constantSitesP: "0.606741573033708", distinctSitesP: "0.31398252184769", brLenTotal: "1.2096" },
  { geneTranscript: "NM_007221.4", parsimonySitesP: "0.278048780487805", constantSitesP: "0.505691056910569", distinctSitesP: "0.450406504065041", brLenTotal: "1.1115" },
  { geneTranscript: "NM_002804.5", parsimonySitesP: "0.154897494305239", constantSitesP: "0.75626423690205", distinctSitesP: "0.207289293849658", brLenTotal: "0.5996" },
  { geneTranscript: "NM_004517.4", parsimonySitesP: "0.115044247787611", constantSitesP: "0.801622418879056", distinctSitesP: "0.16740412979351", brLenTotal: "0.3570" },
  { geneTranscript: "NM_032564.5", parsimonySitesP: "0.188144329896907", constantSitesP: "0.707044673539519", distinctSitesP: "0.245704467353952", brLenTotal: "0.6645" },
  { geneTranscript: "NM_015404.4", parsimonySitesP: "0.211319367879456", constantSitesP: "0.651966188901139", distinctSitesP: "0.274898934215362", brLenTotal: "0.9401" },
  { geneTranscript: "NM_001351288.2", parsimonySitesP: "0.147140864714086", constantSitesP: "0.747559274755927", distinctSitesP: "0.229428172942817", brLenTotal: "0.4658" },
  { geneTranscript: "NM_001033564.3", parsimonySitesP: "0.154166666666667", constantSitesP: "0.616666666666667", distinctSitesP: "0.391666666666667", brLenTotal: "0.6559" },
  { geneTranscript: "NM_006363.6", parsimonySitesP: "0.169491525423729", constantSitesP: "0.720990873533246", distinctSitesP: "0.232942199043894", brLenTotal: "0.6289" },
  { geneTranscript: "NM_004557.4", parsimonySitesP: "0.250624063904144", constantSitesP: "0.588450657347312", distinctSitesP: "0.298219337660176", brLenTotal: "0.9070" },
  { geneTranscript: "NM_018197.3", parsimonySitesP: "0.220753793441018", constantSitesP: "0.659813999021047", distinctSitesP: "0.298091042584435", brLenTotal: "1.0966" },
  { geneTranscript: "NM_005618.4", parsimonySitesP: "0.227293683725219", constantSitesP: "0.668971876440756", distinctSitesP: "0.296449976947902", brLenTotal: "1.0450" },
  { geneTranscript: "NM_001008274.4", parsimonySitesP: "0.23130677847659", constantSitesP: "0.658979734451433", distinctSitesP: "0.290006988120196", brLenTotal: "0.9536" },
  { geneTranscript: "NM_001536.6", parsimonySitesP: "0.185085354896676", constantSitesP: "0.708894878706199", distinctSitesP: "0.265049415992812", brLenTotal: "0.9643" },
  { geneTranscript: "NM_001039697.2", parsimonySitesP: "0.210056772100568", constantSitesP: "0.630170316301703", distinctSitesP: "0.347120843471208", brLenTotal: "0.7579" },
  { geneTranscript: "NM_006279.5", parsimonySitesP: "0.124444444444444", constantSitesP: "0.783111111111111", distinctSitesP: "0.186666666666667", brLenTotal: "0.4839" },
  { geneTranscript: "NM_001258282.3", parsimonySitesP: "0.14026402640264", constantSitesP: "0.766776677667767", distinctSitesP: "0.185368536853685", brLenTotal: "0.4466" },
  { geneTranscript: "NM_080911.3", parsimonySitesP: "0.24174653887114", constantSitesP: "0.561235356762513", distinctSitesP: "0.384451544195953", brLenTotal: "1.0424" },
  { geneTranscript: "NM_015963.6", parsimonySitesP: "0.222414789139226", constantSitesP: "0.659734257654535", distinctSitesP: "0.303292894280763", brLenTotal: "1.0605" },
  { geneTranscript: "NM_003342.5", parsimonySitesP: "0.0627450980392157", constantSitesP: "0.864705882352941", distinctSitesP: "0.143137254901961", brLenTotal: "0.1985" },
  { geneTranscript: "NM_004097.3", parsimonySitesP: "0.0977011494252874", constantSitesP: "0.789655172413793", distinctSitesP: "0.177011494252874", brLenTotal: "0.3895" },
  { geneTranscript: "NM_006686.4", parsimonySitesP: "0.297991967871486", constantSitesP: "0.529317269076305", distinctSitesP: "0.407228915662651", brLenTotal: "1.3853" },
  { geneTranscript: "NM_001134363.3", parsimonySitesP: "0.225210540613964", constantSitesP: "0.623471882640587", distinctSitesP: "0.306438467807661", brLenTotal: "0.9203" },
  { geneTranscript: "NM_170699.3", parsimonySitesP: "0.270707070707071", constantSitesP: "0.581818181818182", distinctSitesP: "0.378787878787879", brLenTotal: "1.1183" },
  { geneTranscript: "NM_007289.4", parsimonySitesP: "0.155555555555556", constantSitesP: "0.728888888888889", distinctSitesP: "0.271555555555556", brLenTotal: "0.6021" },
  { geneTranscript: "NM_005178.5", parsimonySitesP: "0.215124816446402", constantSitesP: "0.662261380323054", distinctSitesP: "0.325256975036711", brLenTotal: "0.8060" },
  { geneTranscript: "NM_175057.4", parsimonySitesP: "0.476053639846743", constantSitesP: "0.32088122605364", distinctSitesP: "0.661877394636015", brLenTotal: "2.3093" },
  { geneTranscript: "NM_004468.5", parsimonySitesP: "0.148809523809524", constantSitesP: "0.775", distinctSitesP: "0.198809523809524", brLenTotal: "0.5227" },
  { geneTranscript: "NM_012474.5", parsimonySitesP: "0.159642401021711", constantSitesP: "0.735632183908046", distinctSitesP: "0.243933588761175", brLenTotal: "0.6261" },
  { geneTranscript: "NM_173596.3", parsimonySitesP: "0.235802469135802", constantSitesP: "0.608641975308642", distinctSitesP: "0.303703703703704", brLenTotal: "0.8004" },
  { geneTranscript: "NM_001003891.3", parsimonySitesP: "0.202622673434856", constantSitesP: "0.656514382402707", distinctSitesP: "0.295685279187817", brLenTotal: "0.8847" },
  { geneTranscript: "NM_145173.4", parsimonySitesP: "0.141414141414141", constantSitesP: "0.728956228956229", distinctSitesP: "0.205387205387205", brLenTotal: "0.8929" },
  { geneTranscript: "NM_001163321.4", parsimonySitesP: "0.191091954022989", constantSitesP: "0.67816091954023", distinctSitesP: "0.246647509578544", brLenTotal: "0.7133" },
  { geneTranscript: "NM_001320643.3", parsimonySitesP: "0.308216262239251", constantSitesP: "0.559386973180077", distinctSitesP: "0.420604512558536", brLenTotal: "1.3826" },
  { geneTranscript: "NM_014071.5", parsimonySitesP: "0.131846825012118", constantSitesP: "0.753271934076587", distinctSitesP: "0.181612538374535", brLenTotal: "0.4826" },
  { geneTranscript: "NM_014850.4", parsimonySitesP: "0.192599332726721", constantSitesP: "0.734607218683652", distinctSitesP: "0.219290263876251", brLenTotal: "0.8090" },
  { geneTranscript: "NM_024701.4", parsimonySitesP: "0.205035971223022", constantSitesP: "0.712230215827338", distinctSitesP: "0.286570743405276", brLenTotal: "1.1408" },
  { geneTranscript: "NM_021193.4", parsimonySitesP: "0.216049382716049", constantSitesP: "0.660493827160494", distinctSitesP: "0.307407407407407", brLenTotal: "0.6957" },
  { geneTranscript: "NM_002084.5", parsimonySitesP: "0.278761061946903", constantSitesP: "0.58259587020649", distinctSitesP: "0.402654867256637", brLenTotal: "1.3326" },
  { geneTranscript: "NM_020771.4", parsimonySitesP: "0.102310231023102", constantSitesP: "0.808580858085809", distinctSitesP: "0.149248258159149", brLenTotal: "0.3357" },
  { geneTranscript: "NM_025188.4", parsimonySitesP: "0.22816091954023", constantSitesP: "0.592528735632184", distinctSitesP: "0.333908045977011", brLenTotal: "0.9005" },
  { geneTranscript: "NM_001014447.3", parsimonySitesP: "0.241308793456033", constantSitesP: "0.624744376278119", distinctSitesP: "0.333844580777096", brLenTotal: "1.2015" },
  { geneTranscript: "NM_021165.4", parsimonySitesP: "0.177522349936143", constantSitesP: "0.719455087271179", distinctSitesP: "0.236696466581524", brLenTotal: "0.6496" },
  { geneTranscript: "NM_007260.3", parsimonySitesP: "0.10966810966811", constantSitesP: "0.808080808080808", distinctSitesP: "0.183261183261183", brLenTotal: "0.3964" },
  { geneTranscript: "NM_001375670.1", parsimonySitesP: "0.0867158671586716", constantSitesP: "0.838253382533825", distinctSitesP: "0.133456334563346", brLenTotal: "0.3047" },
  { geneTranscript: "NM_003072.5", parsimonySitesP: "0.179720704310868", constantSitesP: "0.735883424408015", distinctSitesP: "0.213721918639951", brLenTotal: "1.0677" },
  { geneTranscript: "NM_001008949.3", parsimonySitesP: "0.223423423423423", constantSitesP: "0.616816816816817", distinctSitesP: "0.311711711711712", brLenTotal: "0.7733" },
  { geneTranscript: "NM_016238.3", parsimonySitesP: "0.0991150442477876", constantSitesP: "0.821828908554572", distinctSitesP: "0.146902654867257", brLenTotal: "0.3071" },
  { geneTranscript: "NM_198580.3", parsimonySitesP: "0.262641898864809", constantSitesP: "0.607843137254902", distinctSitesP: "0.306501547987616", brLenTotal: "1.1440" },
  { geneTranscript: "NM_001165.5", parsimonySitesP: "0.310154525386313", constantSitesP: "0.559602649006623", distinctSitesP: "0.411147902869757", brLenTotal: "1.3934" },
  { geneTranscript: "NM_002799.4", parsimonySitesP: "0.138387484957882", constantSitesP: "0.74488567990373", distinctSitesP: "0.22864019253911", brLenTotal: "0.4936" },
  { geneTranscript: "NM_003902.5", parsimonySitesP: "0.103002070393375", constantSitesP: "0.812629399585921", distinctSitesP: "0.160455486542443", brLenTotal: "0.3421" },
  { geneTranscript: "NM_001257180.2", parsimonySitesP: "0.276073619631902", constantSitesP: "0.645705521472393", distinctSitesP: "0.348670756646217", brLenTotal: "1.5486" },
  { geneTranscript: "NM_004100.5", parsimonySitesP: "0.104329681794471", constantSitesP: "0.823161189358372", distinctSitesP: "0.157016171100678", brLenTotal: "0.3486" },
  { geneTranscript: "NM_002415.2", parsimonySitesP: "0.197101449275362", constantSitesP: "0.666666666666667", distinctSitesP: "0.344927536231884", brLenTotal: "0.6641" },
  { geneTranscript: "NM_001039372.4", parsimonySitesP: "0.199134199134199", constantSitesP: "0.655122655122655", distinctSitesP: "0.315295815295815", brLenTotal: "0.7138" },
  { geneTranscript: "NM_001386125.1", parsimonySitesP: "0.329449112978525", constantSitesP: "0.517609710550887", distinctSitesP: "0.363323996265173", brLenTotal: "1.8548" },
  { geneTranscript: "NM_002721.5", parsimonySitesP: "0.0885245901639344", constantSitesP: "0.841530054644809", distinctSitesP: "0.149726775956284", brLenTotal: "0.2725" },
  { geneTranscript: "NM_005752.6", parsimonySitesP: "0.236886632825719", constantSitesP: "0.632825719120135", distinctSitesP: "0.348561759729272", brLenTotal: "0.8438" },
  { geneTranscript: "NM_000302.4", parsimonySitesP: "0.18707015130674", constantSitesP: "0.698303530490601", distinctSitesP: "0.24484181568088", brLenTotal: "0.8438" },
  { geneTranscript: "NM_001001343.4", parsimonySitesP: "0.261904761904762", constantSitesP: "0.613095238095238", distinctSitesP: "0.383928571428571", brLenTotal: "0.8973" },
  { geneTranscript: "NM_017533.2", parsimonySitesP: "0.21179302045728", constantSitesP: "0.676293622141998", distinctSitesP: "0.2647412755716", brLenTotal: "1.3762" },
  { geneTranscript: "NM_182752.4", parsimonySitesP: "0.1875", constantSitesP: "0.700980392156863", distinctSitesP: "0.281862745098039", brLenTotal: "0.7878" },
  { geneTranscript: "NM_005070.4", parsimonySitesP: "0.186147186147186", constantSitesP: "0.706168831168831", distinctSitesP: "0.224025974025974", brLenTotal: "0.8873" },
  { geneTranscript: "NM_004686.5", parsimonySitesP: "0.184343434343434", constantSitesP: "0.717676767676768", distinctSitesP: "0.261111111111111", brLenTotal: "0.6790" },
  { geneTranscript: "NM_002635.4", parsimonySitesP: "0.160664819944598", constantSitesP: "0.66943674976916", distinctSitesP: "0.291782086795937", brLenTotal: "0.6212" },
  { geneTranscript: "NM_017682.3", parsimonySitesP: "0.278323510150622", constantSitesP: "0.611656843483955", distinctSitesP: "0.349705304518664", brLenTotal: "1.3703" },
  { geneTranscript: "NM_001166347.2", parsimonySitesP: "0.27997799779978", constantSitesP: "0.56985698569857", distinctSitesP: "0.399339933993399", brLenTotal: "1.3270" },
  { geneTranscript: "NM_001184739.2", parsimonySitesP: "0.219921104536489", constantSitesP: "0.654832347140039", distinctSitesP: "0.329388560157791", brLenTotal: "0.6225" },
  { geneTranscript: "NM_001162501.2", parsimonySitesP: "0.0705582833242408", constantSitesP: "0.868521549372613", distinctSitesP: "0.102382251318422", brLenTotal: "0.2334" },
  { geneTranscript: "NM_002123.5", parsimonySitesP: "0.41507024265645", constantSitesP: "0.371647509578544", distinctSitesP: "0.61941251596424", brLenTotal: "2.7651" },
  { geneTranscript: "NM_006324.3", parsimonySitesP: "0.169453734671126", constantSitesP: "0.701226309921962", distinctSitesP: "0.272017837235229", brLenTotal: "0.5492" },
  { geneTranscript: "NM_001375524.1", parsimonySitesP: "0.16851708408641", constantSitesP: "0.761941647301833", distinctSitesP: "0.177898270074877", brLenTotal: "0.7092" },
  { geneTranscript: "NM_001105573.2", parsimonySitesP: "0.107226107226107", constantSitesP: "0.787878787878788", distinctSitesP: "0.22027972027972", brLenTotal: "0.4208" },
  { geneTranscript: "NM_001361.5", parsimonySitesP: "0.235443037974684", constantSitesP: "0.628691983122363", distinctSitesP: "0.364556962025316", brLenTotal: "0.9185" },
  { geneTranscript: "NM_005837.3", parsimonySitesP: "0.214285714285714", constantSitesP: "0.654761904761905", distinctSitesP: "0.338095238095238", brLenTotal: "0.8225" },
  { geneTranscript: "NM_016617.4", parsimonySitesP: "0.0784313725490196", constantSitesP: "0.83921568627451", distinctSitesP: "0.188235294117647", brLenTotal: "0.2141" },
  { geneTranscript: "NM_173542.4", parsimonySitesP: "0.263723825693265", constantSitesP: "0.602150537634409", distinctSitesP: "0.375212224108659", brLenTotal: "1.1135" },
  { geneTranscript: "NM_201631.4", parsimonySitesP: "0.253703703703704", constantSitesP: "0.609259259259259", distinctSitesP: "0.364814814814815", brLenTotal: "0.9547" },
  { geneTranscript: "NM_021823.5", parsimonySitesP: "0.200980392156863", constantSitesP: "0.632352941176471", distinctSitesP: "0.336601307189542", brLenTotal: "0.9635" },
  { geneTranscript: "NM_017413.5", parsimonySitesP: "0.16017316017316", constantSitesP: "0.705627705627706", distinctSitesP: "0.294372294372294", brLenTotal: "0.5529" },
  { geneTranscript: "NM_199285.3", parsimonySitesP: "0.350187265917603", constantSitesP: "0.467228464419476", distinctSitesP: "0.460674157303371", brLenTotal: "1.1113" },
  { geneTranscript: "NM_004113.6", parsimonySitesP: "0.160220994475138", constantSitesP: "0.758747697974217", distinctSitesP: "0.230202578268877", brLenTotal: "0.5861" },
  { geneTranscript: "NM_001031722.4", parsimonySitesP: "0.181744091279544", constantSitesP: "0.661776691116544", distinctSitesP: "0.289323553382233", brLenTotal: "0.5916" },
  { geneTranscript: "NM_002401.5", parsimonySitesP: "0.142705005324814", constantSitesP: "0.734291799787007", distinctSitesP: "0.190095846645367", brLenTotal: "0.5409" },
  { geneTranscript: "NM_004939.3", parsimonySitesP: "0.117567567567568", constantSitesP: "0.794594594594595", distinctSitesP: "0.191891891891892", brLenTotal: "0.4030" },
  { geneTranscript: "NM_182645.3", parsimonySitesP: "0.167192429022082", constantSitesP: "0.697160883280757", distinctSitesP: "0.279705573080967", brLenTotal: "0.6789" },
  { geneTranscript: "NM_001378452.1", parsimonySitesP: "0.197002658931593", constantSitesP: "0.732656514382403", distinctSitesP: "0.210901619531061", brLenTotal: "0.7516" },
  { geneTranscript: "NM_033282.4", parsimonySitesP: "0.237796373779637", constantSitesP: "0.617852161785216", distinctSitesP: "0.329149232914923", brLenTotal: "1.0191" },
  { geneTranscript: "NM_175859.3", parsimonySitesP: "0.188850967007964", constantSitesP: "0.693970420932878", distinctSitesP: "0.292946530147895", brLenTotal: "0.6700" },
  { geneTranscript: "NM_018928.3", parsimonySitesP: "0.120824449182658", constantSitesP: "0.782515991471215", distinctSitesP: "0.154939587775409", brLenTotal: "0.4320" },
  { geneTranscript: "NM_018252.3", parsimonySitesP: "0.138095238095238", constantSitesP: "0.759047619047619", distinctSitesP: "0.214285714285714", brLenTotal: "0.4787" },
  { geneTranscript: "NM_013328.4", parsimonySitesP: "0.217708333333333", constantSitesP: "0.665625", distinctSitesP: "0.3125", brLenTotal: "0.9027" },
  { geneTranscript: "NM_000965.5", parsimonySitesP: "0.102678571428571", constantSitesP: "0.804315476190476", distinctSitesP: "0.15922619047619", brLenTotal: "0.4174" },
  { geneTranscript: "NM_014175.4", parsimonySitesP: "0.189189189189189", constantSitesP: "0.648648648648649", distinctSitesP: "0.332207207207207", brLenTotal: "0.7444" },
  { geneTranscript: "NM_001005270.4", parsimonySitesP: "0.620280474649407", constantSitesP: "0.274002157497303", distinctSitesP: "0.723840345199569", brLenTotal: "4.8638" },
  { geneTranscript: "NM_024063.3", parsimonySitesP: "0.258964143426295", constantSitesP: "0.582115980522355", distinctSitesP: "0.376272687029659", brLenTotal: "0.8591" },
  { geneTranscript: "NM_018364.5", parsimonySitesP: "0.0980881130507066", constantSitesP: "0.804655029093932", distinctSitesP: "0.154613466334165", brLenTotal: "0.3207" },
  { geneTranscript: "NM_000389.5", parsimonySitesP: "0.264227642276423", constantSitesP: "0.603658536585366", distinctSitesP: "0.40650406504065", brLenTotal: "1.1369" },
  { geneTranscript: "NM_005133.3", parsimonySitesP: "0.168186423505572", constantSitesP: "0.734549138804458", distinctSitesP: "0.233029381965552", brLenTotal: "0.5824" },
  { geneTranscript: "NM_001382779.1", parsimonySitesP: "0.126112759643917", constantSitesP: "0.783382789317507", distinctSitesP: "0.173095944609298", brLenTotal: "0.5040" },
  { geneTranscript: "NM_025126.4", parsimonySitesP: "0.165770609318996", constantSitesP: "0.706093189964158", distinctSitesP: "0.257168458781362", brLenTotal: "0.7381" },
  { geneTranscript: "NM_173475.4", parsimonySitesP: "0.131578947368421", constantSitesP: "0.705043859649123", distinctSitesP: "0.25", brLenTotal: "0.5522" },
  { geneTranscript: "NM_012111.3", parsimonySitesP: "0.138067061143984", constantSitesP: "0.760355029585799", distinctSitesP: "0.22189349112426", brLenTotal: "0.4619" },
  { geneTranscript: "NM_001322255.2", parsimonySitesP: "0.287634408602151", constantSitesP: "0.567204301075269", distinctSitesP: "0.419354838709677", brLenTotal: "0.9318" },
  { geneTranscript: "NM_000228.3", parsimonySitesP: "0.267633674630262", constantSitesP: "0.575654152445961", distinctSitesP: "0.340728100113766", brLenTotal: "1.0765" },
  { geneTranscript: "NM_001371445.1", parsimonySitesP: "0.0564102564102564", constantSitesP: "0.866666666666667", distinctSitesP: "0.131623931623932", brLenTotal: "0.1981" },
  { geneTranscript: "NM_001168468.2", parsimonySitesP: "0.273927392739274", constantSitesP: "0.55973597359736", distinctSitesP: "0.382178217821782", brLenTotal: "1.0053" },
  { geneTranscript: "NM_144963.4", parsimonySitesP: "0.149562450278441", constantSitesP: "0.73508353221957", distinctSitesP: "0.230708035003978", brLenTotal: "0.5170" },
  { geneTranscript: "NM_013436.5", parsimonySitesP: "0.0963356973995272", constantSitesP: "0.83806146572104", distinctSitesP: "0.141843971631206", brLenTotal: "0.2962" },
  { geneTranscript: "NM_015527.4", parsimonySitesP: "0.20049504950495", constantSitesP: "0.669141914191419", distinctSitesP: "0.274752475247525", brLenTotal: "0.7035" },
  { geneTranscript: "NM_001136561.3", parsimonySitesP: "0.581342434584755", constantSitesP: "0.167235494880546", distinctSitesP: "0.829351535836177", brLenTotal: "2.6225" },
  { geneTranscript: "NM_002353.3", parsimonySitesP: "0.229102167182663", constantSitesP: "0.628482972136223", distinctSitesP: "0.335397316821465", brLenTotal: "0.7814" },
  { geneTranscript: "NM_018201.5", parsimonySitesP: "0.153333333333333", constantSitesP: "0.754166666666667", distinctSitesP: "0.2125", brLenTotal: "0.5462" },
  { geneTranscript: "NM_024045.2", parsimonySitesP: "0.123473541383989", constantSitesP: "0.684305744007237", distinctSitesP: "0.243781094527363", brLenTotal: "0.5413" },
  { geneTranscript: "NM_182894.3", parsimonySitesP: "0.154201292705448", constantSitesP: "0.700831024930748", distinctSitesP: "0.239150507848569", brLenTotal: "0.6755" },
  { geneTranscript: "NM_005683.4", parsimonySitesP: "0.270637408568443", constantSitesP: "0.577847439916405", distinctSitesP: "0.401253918495298", brLenTotal: "1.0213" },
  { geneTranscript: "NM_001319944.2", parsimonySitesP: "0.193166885676741", constantSitesP: "0.674989049496277", distinctSitesP: "0.254927726675427", brLenTotal: "0.6062" },
  { geneTranscript: "NM_138373.5", parsimonySitesP: "0.259834368530021", constantSitesP: "0.616977225672878", distinctSitesP: "0.339544513457557", brLenTotal: "1.2801" },
  { geneTranscript: "NM_053056.3", parsimonySitesP: "0.171751412429379", constantSitesP: "0.728813559322034", distinctSitesP: "0.238418079096045", brLenTotal: "0.7524" },
  { geneTranscript: "NM_006396.3", parsimonySitesP: "0.1892797319933", constantSitesP: "0.67001675041876", distinctSitesP: "0.316582914572864", brLenTotal: "0.6728" },
  { geneTranscript: "NM_003578.4", parsimonySitesP: "0.219029374201788", constantSitesP: "0.636653895274585", distinctSitesP: "0.34418901660281", brLenTotal: "0.8509" },
  { geneTranscript: "NM_004782.4", parsimonySitesP: "0.245478036175711", constantSitesP: "0.591731266149871", distinctSitesP: "0.383720930232558", brLenTotal: "0.8567" },
  { geneTranscript: "NM_144689.5", parsimonySitesP: "0.325096899224806", constantSitesP: "0.464631782945736", distinctSitesP: "0.476259689922481", brLenTotal: "1.7021" },
  { geneTranscript: "NM_005222.4", parsimonySitesP: "0.0853242320819113", constantSitesP: "0.828213879408419", distinctSitesP: "0.164960182025028", brLenTotal: "0.4226" },
  { geneTranscript: "NM_018214.5", parsimonySitesP: "0.145674300254453", constantSitesP: "0.738549618320611", distinctSitesP: "0.200381679389313", brLenTotal: "0.4986" },
  { geneTranscript: "NM_001290117.2", parsimonySitesP: "0.141843971631206", constantSitesP: "0.74645390070922", distinctSitesP: "0.26063829787234", brLenTotal: "0.5509" },
  { geneTranscript: "NM_003467.3", parsimonySitesP: "0.158143939393939", constantSitesP: "0.733901515151515", distinctSitesP: "0.226325757575758", brLenTotal: "0.5502" },
  { geneTranscript: "NM_016836.4", parsimonySitesP: "0.133825944170772", constantSitesP: "0.801313628899836", distinctSitesP: "0.180623973727422", brLenTotal: "0.4706" },
  { geneTranscript: "NM_015472.6", parsimonySitesP: "0.144166666666667", constantSitesP: "0.756666666666667", distinctSitesP: "0.211666666666667", brLenTotal: "0.5029" },
  { geneTranscript: "NM_018337.4", parsimonySitesP: "0.249745158002039", constantSitesP: "0.608562691131498", distinctSitesP: "0.359836901121305", brLenTotal: "1.6313" },
  { geneTranscript: "NM_006354.5", parsimonySitesP: "0.154320987654321", constantSitesP: "0.756172839506173", distinctSitesP: "0.209876543209877", brLenTotal: "0.6545" },
  { geneTranscript: "NM_007358.4", parsimonySitesP: "0.0494659921304103", constantSitesP: "0.872962338392355", distinctSitesP: "0.101180438448567", brLenTotal: "0.1898" },
  { geneTranscript: "NM_003407.5", parsimonySitesP: "0.26278118609407", constantSitesP: "0.615541922290389", distinctSitesP: "0.355828220858896", brLenTotal: "0.9333" },
  { geneTranscript: "NM_021957.4", parsimonySitesP: "0.171171171171171", constantSitesP: "0.725936462778568", distinctSitesP: "0.248458985301091", brLenTotal: "0.6256" },
  { geneTranscript: "NM_017420.5", parsimonySitesP: "0.131455399061033", constantSitesP: "0.726845924029023", distinctSitesP: "0.212974818608621", brLenTotal: "0.4373" },
  { geneTranscript: "NM_016060.3", parsimonySitesP: "0.0916030534351145", constantSitesP: "0.811704834605598", distinctSitesP: "0.203562340966921", brLenTotal: "0.3206" },
  { geneTranscript: "NM_001079520.2", parsimonySitesP: "0.274509803921569", constantSitesP: "0.582811848143513", distinctSitesP: "0.358781810596579", brLenTotal: "0.9278" },
  { geneTranscript: "NM_001093771.3", parsimonySitesP: "0.197226502311248", constantSitesP: "0.672316384180791", distinctSitesP: "0.305598356445814", brLenTotal: "0.7190" },
  { geneTranscript: "NM_183373.4", parsimonySitesP: "0.161616161616162", constantSitesP: "0.715728715728716", distinctSitesP: "0.295815295815296", brLenTotal: "0.5922" },
  { geneTranscript: "NM_016358.3", parsimonySitesP: "0.273603082851638", constantSitesP: "0.572896596017983", distinctSitesP: "0.385356454720617", brLenTotal: "1.4866" },
  { geneTranscript: "NM_004456.5", parsimonySitesP: "0.112738570794496", constantSitesP: "0.799822458943631", distinctSitesP: "0.153573013759432", brLenTotal: "0.4002" },
  { geneTranscript: "NM_015638.3", parsimonySitesP: "0.143036386449184", constantSitesP: "0.756168966959431", distinctSitesP: "0.191133416980343", brLenTotal: "0.4650" },
  { geneTranscript: "NM_024494.3", parsimonySitesP: "0.142369991474851", constantSitesP: "0.762148337595908", distinctSitesP: "0.219948849104859", brLenTotal: "0.4584" },
  { geneTranscript: "NM_001080453.3", parsimonySitesP: "0.241552511415525", constantSitesP: "0.661491628614916", distinctSitesP: "0.271232876712329", brLenTotal: "1.1720" },
  { geneTranscript: "NM_152740.4", parsimonySitesP: "0.146825396825397", constantSitesP: "0.756944444444444", distinctSitesP: "0.232142857142857", brLenTotal: "0.4377" },
  { geneTranscript: "NM_182488.4", parsimonySitesP: "0.152252252252252", constantSitesP: "0.728828828828829", distinctSitesP: "0.220720720720721", brLenTotal: "0.6093" },
  { geneTranscript: "NM_001012426.2", parsimonySitesP: "0.151470588235294", constantSitesP: "0.766666666666667", distinctSitesP: "0.201960784313725", brLenTotal: "0.6832" },
  { geneTranscript: "NM_001164261.2", parsimonySitesP: "0.278455284552846", constantSitesP: "0.441056910569106", distinctSitesP: "0.504065040650407", brLenTotal: "1.6603" },
  { geneTranscript: "NM_000455.5", parsimonySitesP: "0.209391839876828", constantSitesP: "0.697459584295612", distinctSitesP: "0.274056966897614", brLenTotal: "1.1350" },
  { geneTranscript: "NM_003055.3", parsimonySitesP: "0.209899749373434", constantSitesP: "0.679197994987469", distinctSitesP: "0.268796992481203", brLenTotal: "0.8108" },
  { geneTranscript: "NM_001704.3", parsimonySitesP: "0.111476127901883", constantSitesP: "0.815812527376259", distinctSitesP: "0.139728427507665", brLenTotal: "0.4164" },
  { geneTranscript: "NM_001145313.3", parsimonySitesP: "0.114465408805031", constantSitesP: "0.784905660377358", distinctSitesP: "0.19874213836478", brLenTotal: "0.3795" },
  { geneTranscript: "NM_016204.4", parsimonySitesP: "0.27972027972028", constantSitesP: "0.568764568764569", distinctSitesP: "0.405594405594406", brLenTotal: "1.1783" },
  { geneTranscript: "NM_001321103.2", parsimonySitesP: "0.141911570029124", constantSitesP: "0.762245168122849", distinctSitesP: "0.183214191157003", brLenTotal: "0.5430" },
  { geneTranscript: "NM_033334.4", parsimonySitesP: "0.116666666666667", constantSitesP: "0.809027777777778", distinctSitesP: "0.170833333333333", brLenTotal: "0.3418" },
  { geneTranscript: "NM_080669.6", parsimonySitesP: "0.217138707334786", constantSitesP: "0.659404502541757", distinctSitesP: "0.302106027596224", brLenTotal: "0.8636" },
  { geneTranscript: "NM_005632.3", parsimonySitesP: "0.280233271945979", constantSitesP: "0.603130755064457", distinctSitesP: "0.337630448127686", brLenTotal: "1.5999" },
  { geneTranscript: "NM_015995.4", parsimonySitesP: "0.193287037037037", constantSitesP: "0.642361111111111", distinctSitesP: "0.342592592592593", brLenTotal: "0.9714" },
  { geneTranscript: "NM_001039111.3", parsimonySitesP: "0.179339477726575", constantSitesP: "0.74347158218126", distinctSitesP: "0.22273425499232", brLenTotal: "0.7282" },
  { geneTranscript: "NM_014618.3", parsimonySitesP: "0.10994305738064", constantSitesP: "0.814717477003942", distinctSitesP: "0.149364870784056", brLenTotal: "0.4166" },
  { geneTranscript: "NM_004489.5", parsimonySitesP: "0.153924566768603", constantSitesP: "0.754332313965341", distinctSitesP: "0.229357798165138", brLenTotal: "0.5072" },
  { geneTranscript: "NM_024603.4", parsimonySitesP: "0.0768012668250198", constantSitesP: "0.851148060174188", distinctSitesP: "0.129057798891528", brLenTotal: "0.2666" },
  { geneTranscript: "NM_001016.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.704545454545455", distinctSitesP: "0.282828282828283", brLenTotal: "0.5510" },
  { geneTranscript: "NM_053004.3", parsimonySitesP: "0.308868501529052", constantSitesP: "0.562691131498471", distinctSitesP: "0.389398572884811", brLenTotal: "1.4620" },
  { geneTranscript: "NM_001207067.2", parsimonySitesP: "0.096260938743039", constantSitesP: "0.780429594272076", distinctSitesP: "0.186157517899761", brLenTotal: "0.4159" },
  { geneTranscript: "NM_016284.5", parsimonySitesP: "0.09834455667789", constantSitesP: "0.825056116722783", distinctSitesP: "0.115319865319865", brLenTotal: "0.3122" },
  { geneTranscript: "NM_004111.6", parsimonySitesP: "0.150877192982456", constantSitesP: "0.726315789473684", distinctSitesP: "0.233333333333333", brLenTotal: "0.6037" },
  { geneTranscript: "NM_001286811.2", parsimonySitesP: "0.198879551820728", constantSitesP: "0.607843137254902", distinctSitesP: "0.371148459383754", brLenTotal: "0.6825" },
  { geneTranscript: "NM_001381.5", parsimonySitesP: "0.234234234234234", constantSitesP: "0.611919611919612", distinctSitesP: "0.322938322938323", brLenTotal: "0.8133" },
  { geneTranscript: "NM_018161.5", parsimonySitesP: "0.233238904627007", constantSitesP: "0.645892351274788", distinctSitesP: "0.321529745042493", brLenTotal: "1.1419" },
  { geneTranscript: "NM_024516.4", parsimonySitesP: "0.23753280839895", constantSitesP: "0.602362204724409", distinctSitesP: "0.360892388451444", brLenTotal: "0.8287" },
  { geneTranscript: "NM_022171.3", parsimonySitesP: "0.190938511326861", constantSitesP: "0.669902912621359", distinctSitesP: "0.300970873786408", brLenTotal: "0.5444" },
  { geneTranscript: "NM_020862.2", parsimonySitesP: "0.20925205361003", constantSitesP: "0.692174664937311", distinctSitesP: "0.261565067012538", brLenTotal: "0.8777" },
  { geneTranscript: "NM_002409.5", parsimonySitesP: "0.183864915572233", constantSitesP: "0.719824890556598", distinctSitesP: "0.25328330206379", brLenTotal: "0.7446" },
  { geneTranscript: "NM_000639.3", parsimonySitesP: "0.232502965599051", constantSitesP: "0.609727164887307", distinctSitesP: "0.354685646500593", brLenTotal: "1.0502" },
  { geneTranscript: "NM_001020658.2", parsimonySitesP: "0.0791245791245791", constantSitesP: "0.837542087542088", distinctSitesP: "0.113636363636364", brLenTotal: "0.3624" },
  { geneTranscript: "NM_052943.4", parsimonySitesP: "0.288627450980392", constantSitesP: "0.545882352941176", distinctSitesP: "0.387450980392157", brLenTotal: "1.2946" },
  { geneTranscript: "NM_021809.7", parsimonySitesP: "0.150492264416315", constantSitesP: "0.682137834036568", distinctSitesP: "0.246132208157525", brLenTotal: "0.5831" },
  { geneTranscript: "NM_016340.6", parsimonySitesP: "0.198625858838226", constantSitesP: "0.677909639808453", distinctSitesP: "0.25754736622944", brLenTotal: "0.6746" },
  { geneTranscript: "NM_198722.3", parsimonySitesP: "0.304232804232804", constantSitesP: "0.525132275132275", distinctSitesP: "0.404100529100529", brLenTotal: "1.1503" },
  { geneTranscript: "NM_004503.4", parsimonySitesP: "0.0482269503546099", constantSitesP: "0.912056737588652", distinctSitesP: "0.0893617021276596", brLenTotal: "0.1436" },
  { geneTranscript: "NM_000033.4", parsimonySitesP: "0.227293064876957", constantSitesP: "0.659507829977629", distinctSitesP: "0.305592841163311", brLenTotal: "0.9247" },
  { geneTranscript: "NM_001301059.2", parsimonySitesP: "0.228444444444444", constantSitesP: "0.642666666666667", distinctSitesP: "0.348444444444444", brLenTotal: "0.8951" },
  { geneTranscript: "NM_018085.5", parsimonySitesP: "0.123278898495037", constantSitesP: "0.785782901056676", distinctSitesP: "0.161383285302594", brLenTotal: "0.4089" },
  { geneTranscript: "NM_003118.4", parsimonySitesP: "0.144114411441144", constantSitesP: "0.771177117711771", distinctSitesP: "0.221122112211221", brLenTotal: "0.5433" },
  { geneTranscript: "NM_001134337.3", parsimonySitesP: "0.0923423423423423", constantSitesP: "0.842342342342342", distinctSitesP: "0.171171171171171", brLenTotal: "0.2629" },
  { geneTranscript: "NM_052951.3", parsimonySitesP: "0.15096251266464", constantSitesP: "0.747720364741641", distinctSitesP: "0.22289766970618", brLenTotal: "0.4765" },
  { geneTranscript: "NM_145232.4", parsimonySitesP: "0.236590038314176", constantSitesP: "0.564176245210728", distinctSitesP: "0.409003831417625", brLenTotal: "1.1827" },
  { geneTranscript: "NM_032268.5", parsimonySitesP: "0.0895741556534508", constantSitesP: "0.822320117474302", distinctSitesP: "0.168869309838473", brLenTotal: "0.3343" },
  { geneTranscript: "NM_020122.5", parsimonySitesP: "0.0612423447069116", constantSitesP: "0.795275590551181", distinctSitesP: "0.153105861767279", brLenTotal: "0.3143" },
  { geneTranscript: "NM_001243332.2", parsimonySitesP: "0.173708920187793", constantSitesP: "0.731310942578548", distinctSitesP: "0.214879017695919", brLenTotal: "0.6539" },
  { geneTranscript: "NM_002202.3", parsimonySitesP: "0.0926456542502388", constantSitesP: "0.8414517669532", distinctSitesP: "0.143266475644699", brLenTotal: "0.3181" },
  { geneTranscript: "NM_001125.4", parsimonySitesP: "0.247432306255836", constantSitesP: "0.620915032679739", distinctSitesP: "0.338935574229692", brLenTotal: "0.9144" },
  { geneTranscript: "NM_001363644.2", parsimonySitesP: "0.0817610062893082", constantSitesP: "0.838050314465409", distinctSitesP: "0.14937106918239", brLenTotal: "0.2999" },
  { geneTranscript: "NM_006188.4", parsimonySitesP: "0.134556574923547", constantSitesP: "0.801223241590214", distinctSitesP: "0.232415902140673", brLenTotal: "0.4455" },
  { geneTranscript: "NM_024870.4", parsimonySitesP: "0.159817351598174", constantSitesP: "0.738480697384807", distinctSitesP: "0.214404317144043", brLenTotal: "0.5782" },
  { geneTranscript: "NM_006150.5", parsimonySitesP: "0.258536585365854", constantSitesP: "0.584823848238482", distinctSitesP: "0.364769647696477", brLenTotal: "1.0777" },
  { geneTranscript: "NM_024896.3", parsimonySitesP: "0.170722713864307", constantSitesP: "0.6976401179941", distinctSitesP: "0.257374631268437", brLenTotal: "0.5668" },
  { geneTranscript: "NM_177925.5", parsimonySitesP: "0.235142118863049", constantSitesP: "0.645994832041344", distinctSitesP: "0.403100775193798", brLenTotal: "1.0757" },
  { geneTranscript: "NM_005947.3", parsimonySitesP: "0.245901639344262", constantSitesP: "0.601092896174863", distinctSitesP: "0.442622950819672", brLenTotal: "1.8532" },
  { geneTranscript: "NM_032309.4", parsimonySitesP: "0.224242424242424", constantSitesP: "0.645454545454545", distinctSitesP: "0.375757575757576", brLenTotal: "0.8643" },
  { geneTranscript: "NM_005739.4", parsimonySitesP: "0.147218736930155", constantSitesP: "0.746967795901297", distinctSitesP: "0.213718109577583", brLenTotal: "0.4726" },
  { geneTranscript: "NM_001142784.3", parsimonySitesP: "0.207740916271722", constantSitesP: "0.63349131121643", distinctSitesP: "0.30173775671406", brLenTotal: "0.7187" },
  { geneTranscript: "NM_015135.3", parsimonySitesP: "0.159045725646123", constantSitesP: "0.73078197481776", distinctSitesP: "0.202617627567926", brLenTotal: "0.5262" },
  { geneTranscript: "NM_002739.5", parsimonySitesP: "0.131994261119082", constantSitesP: "0.78000956480153", distinctSitesP: "0.18077474892396", brLenTotal: "0.5443" },
  { geneTranscript: "NM_016316.4", parsimonySitesP: "0.167332800426326", constantSitesP: "0.700239808153477", distinctSitesP: "0.261124433786304", brLenTotal: "0.5416" },
  { geneTranscript: "NM_001267776.2", parsimonySitesP: "0.113636363636364", constantSitesP: "0.767676767676768", distinctSitesP: "0.222222222222222", brLenTotal: "0.3425" },
  { geneTranscript: "NM_005347.5", parsimonySitesP: "0.164118246687054", constantSitesP: "0.696738022426096", distinctSitesP: "0.245158002038736", brLenTotal: "0.5675" },
  { geneTranscript: "NM_014653.4", parsimonySitesP: "0.188200589970501", constantSitesP: "0.670206489675516", distinctSitesP: "0.256047197640118", brLenTotal: "0.8520" },
  { geneTranscript: "NM_015059.3", parsimonySitesP: "0.167060057697351", constantSitesP: "0.755573039601364", distinctSitesP: "0.190138998164175", brLenTotal: "0.7459" },
  { geneTranscript: "NM_001367943.1", parsimonySitesP: "0.0974690360796984", constantSitesP: "0.855142703284868", distinctSitesP: "0.128163704900377", brLenTotal: "0.3058" },
  { geneTranscript: "NM_004845.5", parsimonySitesP: "0.142728093947606", constantSitesP: "0.764227642276423", distinctSitesP: "0.200542005420054", brLenTotal: "0.4922" },
  { geneTranscript: "NM_000803.5", parsimonySitesP: "0.311111111111111", constantSitesP: "0.552941176470588", distinctSitesP: "0.433986928104575", brLenTotal: "1.2410" },
  { geneTranscript: "NM_015975.5", parsimonySitesP: "0.167330677290837", constantSitesP: "0.717131474103586", distinctSitesP: "0.262948207171315", brLenTotal: "0.6043" },
  { geneTranscript: "NM_032038.3", parsimonySitesP: "0.196338383838384", constantSitesP: "0.704545454545455", distinctSitesP: "0.250631313131313", brLenTotal: "0.8454" },
  { geneTranscript: "NM_152233.4", parsimonySitesP: "0.0985221674876847", constantSitesP: "0.830049261083744", distinctSitesP: "0.160919540229885", brLenTotal: "0.3265" },
  { geneTranscript: "NM_007180.3", parsimonySitesP: "0.259005145797599", constantSitesP: "0.577472841623785", distinctSitesP: "0.384791309319611", brLenTotal: "1.0267" },
  { geneTranscript: "NM_178832.4", parsimonySitesP: "0.132420091324201", constantSitesP: "0.757990867579909", distinctSitesP: "0.232876712328767", brLenTotal: "0.5950" },
  { geneTranscript: "NM_015049.3", parsimonySitesP: "0.183807439824945", constantSitesP: "0.683078045222465", distinctSitesP: "0.242523705324581", brLenTotal: "0.5851" },
  { geneTranscript: "NM_003910.4", parsimonySitesP: "0.138888888888889", constantSitesP: "0.780092592592593", distinctSitesP: "0.229166666666667", brLenTotal: "0.4085" },
  { geneTranscript: "NM_003286.4", parsimonySitesP: "0.115468409586057", constantSitesP: "0.802178649237473", distinctSitesP: "0.167755991285403", brLenTotal: "0.3405" },
  { geneTranscript: "NM_198503.5", parsimonySitesP: "0.130983847283407", constantSitesP: "0.792657856093979", distinctSitesP: "0.174449339207048", brLenTotal: "0.4249" },
  { geneTranscript: "NM_012200.4", parsimonySitesP: "0.153233830845771", constantSitesP: "0.733333333333333", distinctSitesP: "0.23681592039801", brLenTotal: "0.4964" },
  { geneTranscript: "NM_022716.4", parsimonySitesP: "0.104761904761905", constantSitesP: "0.802721088435374", distinctSitesP: "0.178231292517007", brLenTotal: "0.4093" },
  { geneTranscript: "NM_018684.4", parsimonySitesP: "0.148809523809524", constantSitesP: "0.760416666666667", distinctSitesP: "0.223214285714286", brLenTotal: "0.4343" },
  { geneTranscript: "NM_025010.5", parsimonySitesP: "0.137049941927991", constantSitesP: "0.75725900116144", distinctSitesP: "0.188734030197445", brLenTotal: "0.6049" },
  { geneTranscript: "NM_017619.4", parsimonySitesP: "0.1785944551902", constantSitesP: "0.682785299806576", distinctSitesP: "0.312056737588652", brLenTotal: "0.6533" },
  { geneTranscript: "NM_001301009.2", parsimonySitesP: "0.191666666666667", constantSitesP: "0.726388888888889", distinctSitesP: "0.283333333333333", brLenTotal: "0.6919" },
  { geneTranscript: "NM_032140.3", parsimonySitesP: "0.289980732177264", constantSitesP: "0.55009633911368", distinctSitesP: "0.421965317919075", brLenTotal: "1.0175" },
  { geneTranscript: "NM_001378418.1", parsimonySitesP: "0.162925170068027", constantSitesP: "0.700510204081633", distinctSitesP: "0.203741496598639", brLenTotal: "0.5943" },
  { geneTranscript: "NM_000748.3", parsimonySitesP: "0.178618857901726", constantSitesP: "0.712483399734396", distinctSitesP: "0.249667994687915", brLenTotal: "0.7794" },
  { geneTranscript: "NM_032918.3", parsimonySitesP: "0.152428810720268", constantSitesP: "0.752093802345059", distinctSitesP: "0.241206030150754", brLenTotal: "0.5118" },
  { geneTranscript: "NM_001100423.2", parsimonySitesP: "0.16547192353644", constantSitesP: "0.716845878136201", distinctSitesP: "0.241338112305854", brLenTotal: "0.6792" },
  { geneTranscript: "NM_002816.5", parsimonySitesP: "0.139619883040936", constantSitesP: "0.75219298245614", distinctSitesP: "0.214181286549708", brLenTotal: "0.4776" },
  { geneTranscript: "NM_018369.3", parsimonySitesP: "0.163831127914304", constantSitesP: "0.703213610586011", distinctSitesP: "0.24007561436673", brLenTotal: "0.5770" },
  { geneTranscript: "NM_001903.5", parsimonySitesP: "0.160044150110375", constantSitesP: "0.718175128771155", distinctSitesP: "0.222958057395143", brLenTotal: "0.6755" },
  { geneTranscript: "NM_018723.4", parsimonySitesP: "0.116708648194794", constantSitesP: "0.826196473551637", distinctSitesP: "0.162048698572628", brLenTotal: "0.4324" },
  { geneTranscript: "NM_001024808.3", parsimonySitesP: "0.171428571428571", constantSitesP: "0.73015873015873", distinctSitesP: "0.250793650793651", brLenTotal: "0.7461" },
  { geneTranscript: "NM_021066.3", parsimonySitesP: "0.28125", constantSitesP: "0.638020833333333", distinctSitesP: "0.346354166666667", brLenTotal: "2.2536" },
  { geneTranscript: "NM_001010908.2", parsimonySitesP: "0.18562091503268", constantSitesP: "0.716339869281046", distinctSitesP: "0.209150326797386", brLenTotal: "0.6483" },
  { geneTranscript: "NM_012415.3", parsimonySitesP: "0.179487179487179", constantSitesP: "0.668864468864469", distinctSitesP: "0.294871794871795", brLenTotal: "0.5977" },
  { geneTranscript: "NM_152902.5", parsimonySitesP: "0.136029411764706", constantSitesP: "0.761029411764706", distinctSitesP: "0.215686274509804", brLenTotal: "0.4527" },
  { geneTranscript: "NM_025230.5", parsimonySitesP: "0.13980463980464", constantSitesP: "0.764346764346764", distinctSitesP: "0.198412698412698", brLenTotal: "0.4454" },
  { geneTranscript: "NM_002814.4", parsimonySitesP: "0.147492625368732", constantSitesP: "0.682890855457227", distinctSitesP: "0.287610619469027", brLenTotal: "0.5257" },
  { geneTranscript: "NM_005446.5", parsimonySitesP: "0.26984126984127", constantSitesP: "0.554799697656841", distinctSitesP: "0.404383975812547", brLenTotal: "1.0729" },
  { geneTranscript: "NM_015448.3", parsimonySitesP: "0.195402298850575", constantSitesP: "0.638752052545156", distinctSitesP: "0.311986863711002", brLenTotal: "0.7361" },
  { geneTranscript: "NM_133510.4", parsimonySitesP: "0.165714285714286", constantSitesP: "0.691428571428571", distinctSitesP: "0.291428571428571", brLenTotal: "0.5453" },
  { geneTranscript: "NM_032023.4", parsimonySitesP: "0.22533748701973", constantSitesP: "0.659397715472482", distinctSitesP: "0.329179646936656", brLenTotal: "0.9469" },
  { geneTranscript: "NM_001347969.2", parsimonySitesP: "0.158113011923276", constantSitesP: "0.744945567651633", distinctSitesP: "0.208398133748056", brLenTotal: "0.5323" },
  { geneTranscript: "NM_017822.4", parsimonySitesP: "0.128048780487805", constantSitesP: "0.768292682926829", distinctSitesP: "0.199186991869919", brLenTotal: "0.3901" },
  { geneTranscript: "NM_033400.3", parsimonySitesP: "0.198807672369103", constantSitesP: "0.655002592016589", distinctSitesP: "0.214230171073095", brLenTotal: "0.7032" },
  { geneTranscript: "NM_021218.3", parsimonySitesP: "0.147435897435897", constantSitesP: "0.762820512820513", distinctSitesP: "0.253205128205128", brLenTotal: "0.4249" },
  { geneTranscript: "NM_015072.5", parsimonySitesP: "0.182669789227166", constantSitesP: "0.68279989591465", distinctSitesP: "0.261774655217278", brLenTotal: "0.5563" },
  { geneTranscript: "NM_177990.4", parsimonySitesP: "0.188224385720909", constantSitesP: "0.710709318497914", distinctSitesP: "0.252202132591562", brLenTotal: "0.7882" },
  { geneTranscript: "NM_006191.3", parsimonySitesP: "0.0913705583756345", constantSitesP: "0.812182741116751", distinctSitesP: "0.153976311336717", brLenTotal: "0.3117" },
  { geneTranscript: "NM_032478.4", parsimonySitesP: "0.252631578947368", constantSitesP: "0.594736842105263", distinctSitesP: "0.37719298245614", brLenTotal: "0.9542" },
  { geneTranscript: "NM_006912.6", parsimonySitesP: "0.136986301369863", constantSitesP: "0.721461187214612", distinctSitesP: "0.245053272450533", brLenTotal: "0.5307" },
  { geneTranscript: "NM_005236.3", parsimonySitesP: "0.209970887918486", constantSitesP: "0.665574963609898", distinctSitesP: "0.29330422125182", brLenTotal: "0.7403" },
  { geneTranscript: "NM_017576.4", parsimonySitesP: "0.196526290744706", constantSitesP: "0.638591482274566", distinctSitesP: "0.308351177730193", brLenTotal: "0.6163" },
  { geneTranscript: "NM_001159773.2", parsimonySitesP: "0.252701579384871", constantSitesP: "0.620947630922693", distinctSitesP: "0.322527015793849", brLenTotal: "1.1695" },
  { geneTranscript: "NM_000422.3", parsimonySitesP: "0.244598765432099", constantSitesP: "0.611111111111111", distinctSitesP: "0.344135802469136", brLenTotal: "1.2923" },
  { geneTranscript: "NM_001388419.1", parsimonySitesP: "0.138423755302523", constantSitesP: "0.772158964054476", distinctSitesP: "0.151931234650592", brLenTotal: "0.5054" },
  { geneTranscript: "NM_022124.6", parsimonySitesP: "0.197276883323395", constantSitesP: "0.687437885112304", distinctSitesP: "0.214867819518982", brLenTotal: "0.8698" },
  { geneTranscript: "NM_015285.3", parsimonySitesP: "0.160178970917226", constantSitesP: "0.742281879194631", distinctSitesP: "0.192170022371365", brLenTotal: "0.6162" },
  { geneTranscript: "NM_022894.4", parsimonySitesP: "0.121376811594203", constantSitesP: "0.796648550724638", distinctSitesP: "0.185235507246377", brLenTotal: "0.3846" },
  { geneTranscript: "NM_002832.4", parsimonySitesP: "0.223148148148148", constantSitesP: "0.681481481481481", distinctSitesP: "0.283333333333333", brLenTotal: "0.8166" },
  { geneTranscript: "NM_001135254.2", parsimonySitesP: "0.17953795379538", constantSitesP: "0.741914191419142", distinctSitesP: "0.235643564356436", brLenTotal: "0.9604" },
  { geneTranscript: "NM_001024674.3", parsimonySitesP: "0.0952380952380952", constantSitesP: "0.830357142857143", distinctSitesP: "0.166666666666667", brLenTotal: "0.3167" },
  { geneTranscript: "NM_005274.3", parsimonySitesP: "0.200980392156863", constantSitesP: "0.642156862745098", distinctSitesP: "0.352941176470588", brLenTotal: "0.6535" },
  { geneTranscript: "NM_152519.4", parsimonySitesP: "0.168524147247552", constantSitesP: "0.659912191827085", distinctSitesP: "0.306653157716988", brLenTotal: "0.5730" },
  { geneTranscript: "NM_198268.3", parsimonySitesP: "0.115426997245179", constantSitesP: "0.791735537190083", distinctSitesP: "0.159228650137741", brLenTotal: "0.3784" },
  { geneTranscript: "NM_018416.3", parsimonySitesP: "0.17479674796748", constantSitesP: "0.696283391405343", distinctSitesP: "0.254355400696864", brLenTotal: "0.6686" },
  { geneTranscript: "NM_080836.4", parsimonySitesP: "0.183520599250936", constantSitesP: "0.66167290886392", distinctSitesP: "0.267166042446941", brLenTotal: "0.6681" },
  { geneTranscript: "NM_000843.4", parsimonySitesP: "0.239832763207906", constantSitesP: "0.624097301406309", distinctSitesP: "0.320410490307868", brLenTotal: "1.2280" },
  { geneTranscript: "NM_198046.3", parsimonySitesP: "0.143236074270557", constantSitesP: "0.739168877099912", distinctSitesP: "0.214854111405836", brLenTotal: "0.5033" },
  { geneTranscript: "NM_145899.3", parsimonySitesP: "0.149532710280374", constantSitesP: "0.700934579439252", distinctSitesP: "0.277258566978193", brLenTotal: "0.6672" },
  { geneTranscript: "NM_207517.3", parsimonySitesP: "0.249753597476838", constantSitesP: "0.613640843682239", distinctSitesP: "0.339049871870688", brLenTotal: "0.9641" },
  { geneTranscript: "NM_002338.5", parsimonySitesP: "0.0887573964497041", constantSitesP: "0.829388560157791", distinctSitesP: "0.154832347140039", brLenTotal: "0.3322" },
  { geneTranscript: "NM_198935.3", parsimonySitesP: "0.205387205387205", constantSitesP: "0.713804713804714", distinctSitesP: "0.287878787878788", brLenTotal: "1.1210" },
  { geneTranscript: "NM_003521.3", parsimonySitesP: "0.293650793650794", constantSitesP: "0.648148148148148", distinctSitesP: "0.35978835978836", brLenTotal: "2.1952" },
  { geneTranscript: "NM_020312.4", parsimonySitesP: "0.212788259958071", constantSitesP: "0.669811320754717", distinctSitesP: "0.309224318658281", brLenTotal: "0.7930" },
  { geneTranscript: "NM_001136534.3", parsimonySitesP: "0.128440366972477", constantSitesP: "0.749235474006116", distinctSitesP: "0.244648318042813", brLenTotal: "0.4073" },
  { geneTranscript: "NM_000476.3", parsimonySitesP: "0.190721649484536", constantSitesP: "0.711340206185567", distinctSitesP: "0.288659793814433", brLenTotal: "0.8193" },
  { geneTranscript: "NM_020202.5", parsimonySitesP: "0.183574879227053", constantSitesP: "0.689613526570048", distinctSitesP: "0.304347826086957", brLenTotal: "0.6299" },
  { geneTranscript: "NM_017706.5", parsimonySitesP: "0.249782419495213", constantSitesP: "0.612706701479547", distinctSitesP: "0.34638816362054", brLenTotal: "0.7745" },
  { geneTranscript: "NM_001200.4", parsimonySitesP: "0.13973063973064", constantSitesP: "0.745791245791246", distinctSitesP: "0.213804713804714", brLenTotal: "0.5098" },
  { geneTranscript: "NM_003367.4", parsimonySitesP: "0.132947976878613", constantSitesP: "0.804431599229287", distinctSitesP: "0.192678227360308", brLenTotal: "0.4200" },
  { geneTranscript: "NM_019080.3", parsimonySitesP: "0.21031746031746", constantSitesP: "0.651785714285714", distinctSitesP: "0.31547619047619", brLenTotal: "0.7012" },
  { geneTranscript: "NM_024523.6", parsimonySitesP: "0.206881720430108", constantSitesP: "0.672688172043011", distinctSitesP: "0.247741935483871", brLenTotal: "0.6724" },
  { geneTranscript: "NM_001135673.4", parsimonySitesP: "0.101772441395083", constantSitesP: "0.80674671240709", distinctSitesP: "0.165809033733562", brLenTotal: "0.3479" },
  { geneTranscript: "NM_024835.5", parsimonySitesP: "0.101386896221903", constantSitesP: "0.815399330463893", distinctSitesP: "0.147297943567671", brLenTotal: "0.3245" },
  { geneTranscript: "NM_021227.4", parsimonySitesP: "0.140939597315436", constantSitesP: "0.682326621923937", distinctSitesP: "0.279642058165548", brLenTotal: "0.6623" },
  { geneTranscript: "NM_138348.6", parsimonySitesP: "0.180871212121212", constantSitesP: "0.700757575757576", distinctSitesP: "0.302083333333333", brLenTotal: "0.6189" },
  { geneTranscript: "NM_015113.4", parsimonySitesP: "0.194641449960599", constantSitesP: "0.693684566024992", distinctSitesP: "0.225712034222673", brLenTotal: "0.6641" },
  { geneTranscript: "NM_020725.2", parsimonySitesP: "0.0894308943089431", constantSitesP: "0.83662408052652", distinctSitesP: "0.137437088656601", brLenTotal: "0.3112" },
  { geneTranscript: "NM_020868.6", parsimonySitesP: "0.14070351758794", constantSitesP: "0.766331658291457", distinctSitesP: "0.222780569514238", brLenTotal: "0.4772" },
  { geneTranscript: "NM_032301.3", parsimonySitesP: "0.288937409024745", constantSitesP: "0.585152838427948", distinctSitesP: "0.355167394468705", brLenTotal: "0.9654" },
  { geneTranscript: "NM_003587.5", parsimonySitesP: "0.163944924751841", constantSitesP: "0.730707652897855", distinctSitesP: "0.199167467178995", brLenTotal: "0.6095" },
  { geneTranscript: "NM_001278.5", parsimonySitesP: "0.12751677852349", constantSitesP: "0.750335570469799", distinctSitesP: "0.18076062639821", brLenTotal: "0.4048" },
  { geneTranscript: "NM_013319.3", parsimonySitesP: "0.176528599605523", constantSitesP: "0.720907297830375", distinctSitesP: "0.247534516765286", brLenTotal: "0.5910" },
  { geneTranscript: "NM_019592.7", parsimonySitesP: "0.157948717948718", constantSitesP: "0.749059829059829", distinctSitesP: "0.194188034188034", brLenTotal: "0.4971" },
  { geneTranscript: "NM_017740.3", parsimonySitesP: "0.216450216450216", constantSitesP: "0.668831168831169", distinctSitesP: "0.285714285714286", brLenTotal: "1.0818" },
  { geneTranscript: "NM_053278.3", parsimonySitesP: "0.525341130604288", constantSitesP: "0.299220272904483", distinctSitesP: "0.680311890838207", brLenTotal: "2.8578" },
  { geneTranscript: "NM_006668.2", parsimonySitesP: "0.148666666666667", constantSitesP: "0.766666666666667", distinctSitesP: "0.196666666666667", brLenTotal: "0.6477" },
  { geneTranscript: "NM_203371.2", parsimonySitesP: "0.165876777251185", constantSitesP: "0.740916271721959", distinctSitesP: "0.243285939968404", brLenTotal: "0.5485" },
  { geneTranscript: "NM_144775.3", parsimonySitesP: "0.210956954820349", constantSitesP: "0.665243685521167", distinctSitesP: "0.264318747776592", brLenTotal: "0.6895" },
  { geneTranscript: "NM_001297588.2", parsimonySitesP: "0.450567260940032", constantSitesP: "0.366828741220962", distinctSitesP: "0.606158833063209", brLenTotal: "2.1439" },
  { geneTranscript: "NM_002276.5", parsimonySitesP: "0.333333333333333", constantSitesP: "0.511666666666667", distinctSitesP: "0.4625", brLenTotal: "1.7346" },
  { geneTranscript: "NM_015595.4", parsimonySitesP: "0.218905472636816", constantSitesP: "0.652506697282817", distinctSitesP: "0.29697665518561", brLenTotal: "0.7465" },
  { geneTranscript: "NM_001276501.2", parsimonySitesP: "0.19375", constantSitesP: "0.664583333333333", distinctSitesP: "0.310416666666667", brLenTotal: "0.6289" },
  { geneTranscript: "NM_001004739.1", parsimonySitesP: "0.631296891747053", constantSitesP: "0.255091103965702", distinctSitesP: "0.768488745980707", brLenTotal: "5.6491" },
  { geneTranscript: "NM_001010888.4", parsimonySitesP: "0.128787878787879", constantSitesP: "0.757177033492823", distinctSitesP: "0.168261562998405", brLenTotal: "0.4171" },
  { geneTranscript: "NM_001498.4", parsimonySitesP: "0.156462585034014", constantSitesP: "0.740973312401884", distinctSitesP: "0.221350078492936", brLenTotal: "0.5284" },
  { geneTranscript: "NM_001161748.2", parsimonySitesP: "0.171483622350674", constantSitesP: "0.747591522157996", distinctSitesP: "0.25626204238921", brLenTotal: "0.5870" },
  { geneTranscript: "NM_013443.5", parsimonySitesP: "0.183183183183183", constantSitesP: "0.706706706706707", distinctSitesP: "0.259259259259259", brLenTotal: "0.6621" },
  { geneTranscript: "NM_012465.4", parsimonySitesP: "0.210180623973727", constantSitesP: "0.661412151067323", distinctSitesP: "0.281773399014778", brLenTotal: "0.9428" },
  { geneTranscript: "NM_001002295.2", parsimonySitesP: "0.177177177177177", constantSitesP: "0.711711711711712", distinctSitesP: "0.238738738738739", brLenTotal: "0.7404" },
  { geneTranscript: "NM_005061.3", parsimonySitesP: "0.206388206388206", constantSitesP: "0.66011466011466", distinctSitesP: "0.287469287469287", brLenTotal: "0.9812" },
  { geneTranscript: "NM_003359.4", parsimonySitesP: "0.128879892037787", constantSitesP: "0.758434547908232", distinctSitesP: "0.205128205128205", brLenTotal: "0.4979" },
  { geneTranscript: "NM_001560.3", parsimonySitesP: "0.19672131147541", constantSitesP: "0.665886026541764", distinctSitesP: "0.343481654957065", brLenTotal: "0.6641" },
  { geneTranscript: "NM_016040.5", parsimonySitesP: "0.136826783114993", constantSitesP: "0.758369723435226", distinctSitesP: "0.222707423580786", brLenTotal: "0.4300" },
  { geneTranscript: "NM_177533.5", parsimonySitesP: "0.285285285285285", constantSitesP: "0.566066066066066", distinctSitesP: "0.412912912912913", brLenTotal: "1.3816" },
  { geneTranscript: "NM_002107.7", parsimonySitesP: "0.110294117647059", constantSitesP: "0.693627450980392", distinctSitesP: "0.269607843137255", brLenTotal: "0.4573" },
  { geneTranscript: "NM_001030059.3", parsimonySitesP: "0.113161131611316", constantSitesP: "0.816728167281673", distinctSitesP: "0.17220172201722", brLenTotal: "0.3532" },
  { geneTranscript: "NM_015484.5", parsimonySitesP: "0.159122085048011", constantSitesP: "0.710562414266118", distinctSitesP: "0.253772290809328", brLenTotal: "0.5701" },
  { geneTranscript: "NM_025261.3", parsimonySitesP: "0.226666666666667", constantSitesP: "0.621333333333333", distinctSitesP: "0.336", brLenTotal: "0.7829" },
  { geneTranscript: "NM_012309.5", parsimonySitesP: "0.189291508923743", constantSitesP: "0.721110510185686", distinctSitesP: "0.212727600504777", brLenTotal: "0.8682" },
  { geneTranscript: "NM_181537.4", parsimonySitesP: "0.282498184458969", constantSitesP: "0.59041394335512", distinctSitesP: "0.376180101670298", brLenTotal: "1.1987" },
  { geneTranscript: "NM_022065.5", parsimonySitesP: "0.22205154463219", constantSitesP: "0.624338624338624", distinctSitesP: "0.318655060590544", brLenTotal: "0.7448" },
  { geneTranscript: "NM_005529.7", parsimonySitesP: "0.242237910878312", constantSitesP: "0.636681090108555", distinctSitesP: "0.25324527442496", brLenTotal: "1.1584" },
  { geneTranscript: "NM_001283041.3", parsimonySitesP: "0.131555555555556", constantSitesP: "0.775111111111111", distinctSitesP: "0.189333333333333", brLenTotal: "0.4311" },
  { geneTranscript: "NM_001104.4", parsimonySitesP: "0.203477617462079", constantSitesP: "0.679245283018868", distinctSitesP: "0.260081391046985", brLenTotal: "1.0267" },
  { geneTranscript: "NM_007262.5", parsimonySitesP: "0.199294532627866", constantSitesP: "0.652557319223986", distinctSitesP: "0.350970017636684", brLenTotal: "0.8676" },
  { geneTranscript: "NM_002103.5", parsimonySitesP: "0.169154228855721", constantSitesP: "0.747625508819539", distinctSitesP: "0.212121212121212", brLenTotal: "0.6784" },
  { geneTranscript: "NM_019101.3", parsimonySitesP: "0.203900709219858", constantSitesP: "0.627659574468085", distinctSitesP: "0.359929078014184", brLenTotal: "0.6105" },
  { geneTranscript: "NM_001105539.3", parsimonySitesP: "0.110218140068886", constantSitesP: "0.786835055491772", distinctSitesP: "0.17451205510907", brLenTotal: "0.3360" },
  { geneTranscript: "NM_144653.5", parsimonySitesP: "0.212379329926178", constantSitesP: "0.68824531516184", distinctSitesP: "0.269733106189665", brLenTotal: "1.2316" },
  { geneTranscript: "NM_001291291.2", parsimonySitesP: "0.290715372907154", constantSitesP: "0.523592085235921", distinctSitesP: "0.540334855403349", brLenTotal: "1.1112" },
  { geneTranscript: "NM_012328.3", parsimonySitesP: "0.143497757847534", constantSitesP: "0.736920777279522", distinctSitesP: "0.26457399103139", brLenTotal: "0.4455" },
  { geneTranscript: "NM_002086.5", parsimonySitesP: "0.098310291858679", constantSitesP: "0.811059907834101", distinctSitesP: "0.170506912442396", brLenTotal: "0.4049" },
  { geneTranscript: "NM_138378.3", parsimonySitesP: "0.25", constantSitesP: "0.579594017094017", distinctSitesP: "0.338141025641026", brLenTotal: "0.8043" },
  { geneTranscript: "NM_001392073.1", parsimonySitesP: "0.158344003414426", constantSitesP: "0.728553137003841", distinctSitesP: "0.219376867264191", brLenTotal: "0.6465" },
  { geneTranscript: "NM_032870.4", parsimonySitesP: "0.0869565217391304", constantSitesP: "0.844306418219462", distinctSitesP: "0.126708074534161", brLenTotal: "0.2596" },
  { geneTranscript: "NM_181535.3", parsimonySitesP: "0.258620689655172", constantSitesP: "0.589798850574713", distinctSitesP: "0.385775862068966", brLenTotal: "1.1950" },
  { geneTranscript: "NM_004163.4", parsimonySitesP: "0.14525993883792", constantSitesP: "0.746177370030581", distinctSitesP: "0.250764525993884", brLenTotal: "0.4845" },
  { geneTranscript: "NM_015596.3", parsimonySitesP: "0.282791817087846", constantSitesP: "0.577617328519856", distinctSitesP: "0.407942238267148", brLenTotal: "0.9644" },
  { geneTranscript: "NM_005373.3", parsimonySitesP: "0.241994750656168", constantSitesP: "0.602099737532808", distinctSitesP: "0.3249343832021", brLenTotal: "0.7623" },
  { geneTranscript: "NM_004177.5", parsimonySitesP: "0.134948096885813", constantSitesP: "0.769319492502884", distinctSitesP: "0.221453287197232", brLenTotal: "0.4960" },
  { geneTranscript: "NM_198584.3", parsimonySitesP: "0.211195928753181", constantSitesP: "0.66793893129771", distinctSitesP: "0.32824427480916", brLenTotal: "0.7350" },
  { geneTranscript: "NM_001300942.2", parsimonySitesP: "0.0900024931438544", constantSitesP: "0.838444278234854", distinctSitesP: "0.12341062079282", brLenTotal: "0.2724" },
  { geneTranscript: "NM_012377.1", parsimonySitesP: "0.617554858934169", constantSitesP: "0.22884012539185", distinctSitesP: "0.766980146290491", brLenTotal: "4.2363" },
  { geneTranscript: "NM_022658.4", parsimonySitesP: "0.0440771349862259", constantSitesP: "0.902203856749311", distinctSitesP: "0.09366391184573", brLenTotal: "0.1573" },
  { geneTranscript: "NM_001270471.2", parsimonySitesP: "0.13973063973064", constantSitesP: "0.725589225589226", distinctSitesP: "0.239057239057239", brLenTotal: "0.5316" },
  { geneTranscript: "NM_001201338.2", parsimonySitesP: "0.187931661214104", constantSitesP: "0.704107597237368", distinctSitesP: "0.272991639403853", brLenTotal: "0.7019" },
  { geneTranscript: "NM_052844.4", parsimonySitesP: "0.304726368159204", constantSitesP: "0.560323383084577", distinctSitesP: "0.413557213930348", brLenTotal: "1.3505" },
  { geneTranscript: "NM_012316.5", parsimonySitesP: "0.118781094527363", constantSitesP: "0.763681592039801", distinctSitesP: "0.175373134328358", brLenTotal: "0.4752" },
  { geneTranscript: "NM_001044.5", parsimonySitesP: "0.219354838709677", constantSitesP: "0.668279569892473", distinctSitesP: "0.291935483870968", brLenTotal: "1.2991" },
  { geneTranscript: "NM_004809.5", parsimonySitesP: "0.215242881072027", constantSitesP: "0.634840871021776", distinctSitesP: "0.320770519262982", brLenTotal: "0.9263" },
  { geneTranscript: "NM_007055.4", parsimonySitesP: "0.164268585131894", constantSitesP: "0.747961630695444", distinctSitesP: "0.197362110311751", brLenTotal: "0.6679" },
  { geneTranscript: "NM_133443.4", parsimonySitesP: "0.221797323135755", constantSitesP: "0.687699171446781", distinctSitesP: "0.29955385595921", brLenTotal: "0.9205" },
  { geneTranscript: "NM_001267571.2", parsimonySitesP: "0.252873563218391", constantSitesP: "0.60632183908046", distinctSitesP: "0.338362068965517", brLenTotal: "0.9384" },
  { geneTranscript: "NM_002181.4", parsimonySitesP: "0.175993511759935", constantSitesP: "0.710462287104623", distinctSitesP: "0.248175182481752", brLenTotal: "0.8152" },
  { geneTranscript: "NM_016208.4", parsimonySitesP: "0.167420814479638", constantSitesP: "0.7526395173454", distinctSitesP: "0.262443438914027", brLenTotal: "0.9551" },
  { geneTranscript: "NM_152407.4", parsimonySitesP: "0.245925925925926", constantSitesP: "0.582222222222222", distinctSitesP: "0.380740740740741", brLenTotal: "0.9681" },
  { geneTranscript: "NM_015043.4", parsimonySitesP: "0.237902135712355", constantSitesP: "0.618815896188159", distinctSitesP: "0.286563936198973", brLenTotal: "1.0451" },
  { geneTranscript: "NM_004739.4", parsimonySitesP: "0.164670658682635", constantSitesP: "0.752994011976048", distinctSitesP: "0.20309381237525", brLenTotal: "0.5831" },
  { geneTranscript: "NM_017805.3", parsimonySitesP: "0.205607476635514", constantSitesP: "0.692973347178955", distinctSitesP: "0.248875043267567", brLenTotal: "0.8075" },
  { geneTranscript: "NM_133478.3", parsimonySitesP: "0.182872435325602", constantSitesP: "0.711567053226286", distinctSitesP: "0.23847754980672", brLenTotal: "0.6888" },
  { geneTranscript: "NM_001880.4", parsimonySitesP: "0.0785478547854785", constantSitesP: "0.841584158415842", distinctSitesP: "0.133333333333333", brLenTotal: "0.2734" },
  { geneTranscript: "NM_018286.3", parsimonySitesP: "0.233830845771144", constantSitesP: "0.634328358208955", distinctSitesP: "0.373134328358209", brLenTotal: "0.8412" },
  { geneTranscript: "NM_020752.3", parsimonySitesP: "0.205486968449931", constantSitesP: "0.654869684499314", distinctSitesP: "0.296570644718793", brLenTotal: "0.7057" },
  { geneTranscript: "NM_024893.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.655038759689922", distinctSitesP: "0.308785529715762", brLenTotal: "0.9809" },
  { geneTranscript: "NM_006235.3", parsimonySitesP: "0.212239583333333", constantSitesP: "0.696614583333333", distinctSitesP: "0.296875", brLenTotal: "0.8711" },
  { geneTranscript: "NM_001290403.2", parsimonySitesP: "0.171198388721047", constantSitesP: "0.716012084592145", distinctSitesP: "0.262839879154079", brLenTotal: "0.6669" },
  { geneTranscript: "NM_003595.5", parsimonySitesP: "0.216622458001768", constantSitesP: "0.580017683465959", distinctSitesP: "0.328028293545535", brLenTotal: "1.2269" },
  { geneTranscript: "NM_007269.4", parsimonySitesP: "0.119932432432432", constantSitesP: "0.787725225225225", distinctSitesP: "0.198198198198198", brLenTotal: "0.3983" },
  { geneTranscript: "NM_001140.5", parsimonySitesP: "0.316717019133938", constantSitesP: "0.471802618328298", distinctSitesP: "0.47583081570997", brLenTotal: "1.4897" },
  { geneTranscript: "NM_004615.4", parsimonySitesP: "0.0950468540829987", constantSitesP: "0.834002677376171", distinctSitesP: "0.153949129852744", brLenTotal: "0.3250" },
  { geneTranscript: "NM_003368.5", parsimonySitesP: "0.140552016985138", constantSitesP: "0.71932059447983", distinctSitesP: "0.239490445859873", brLenTotal: "0.4892" },
  { geneTranscript: "NM_016053.4", parsimonySitesP: "0.0996563573883162", constantSitesP: "0.742268041237113", distinctSitesP: "0.231958762886598", brLenTotal: "0.4118" },
  { geneTranscript: "NM_004040.4", parsimonySitesP: "0.137755102040816", constantSitesP: "0.772108843537415", distinctSitesP: "0.17687074829932", brLenTotal: "0.4005" },
  { geneTranscript: "NM_032876.6", parsimonySitesP: "0.162329615861214", constantSitesP: "0.712515489467162", distinctSitesP: "0.234820322180917", brLenTotal: "0.5364" },
  { geneTranscript: "NM_022730.4", parsimonySitesP: "0.10479797979798", constantSitesP: "0.816919191919192", distinctSitesP: "0.171717171717172", brLenTotal: "0.3092" },
  { geneTranscript: "NM_022833.4", parsimonySitesP: "0.210902591599643", constantSitesP: "0.67426273458445", distinctSitesP: "0.270330652368186", brLenTotal: "1.0524" },
  { geneTranscript: "NM_000869.6", parsimonySitesP: "0.248953974895397", constantSitesP: "0.622733612273361", distinctSitesP: "0.374476987447699", brLenTotal: "1.0023" },
  { geneTranscript: "NM_198478.4", parsimonySitesP: "0.286458333333333", constantSitesP: "0.588942307692308", distinctSitesP: "0.375", brLenTotal: "1.0405" },
  { geneTranscript: "NM_001323532.2", parsimonySitesP: "0.080952380952381", constantSitesP: "0.831216931216931", distinctSitesP: "0.133862433862434", brLenTotal: "0.3167" },
  { geneTranscript: "NM_001348768.2", parsimonySitesP: "0.169423240033927", constantSitesP: "0.712680237489398", distinctSitesP: "0.225614927905004", brLenTotal: "0.7594" },
  { geneTranscript: "NM_001365480.1", parsimonySitesP: "0.114733654017459", constantSitesP: "0.794584001425263", distinctSitesP: "0.16657758774274", brLenTotal: "0.3385" },
  { geneTranscript: "NM_033159.4", parsimonySitesP: "0.268965517241379", constantSitesP: "0.577011494252874", distinctSitesP: "0.364750957854406", brLenTotal: "0.9235" },
  { geneTranscript: "NM_001042506.2", parsimonySitesP: "0.126666666666667", constantSitesP: "0.738333333333333", distinctSitesP: "0.188333333333333", brLenTotal: "0.5787" },
  { geneTranscript: "NM_001126340.3", parsimonySitesP: "0.233595800524934", constantSitesP: "0.644356955380577", distinctSitesP: "0.324146981627297", brLenTotal: "1.2388" },
  { geneTranscript: "NM_001039693.3", parsimonySitesP: "0.133333333333333", constantSitesP: "0.742857142857143", distinctSitesP: "0.237037037037037", brLenTotal: "0.4680" },
  { geneTranscript: "NM_001786.5", parsimonySitesP: "0.105499438832772", constantSitesP: "0.742985409652076", distinctSitesP: "0.231200897867565", brLenTotal: "0.4177" },
  { geneTranscript: "NM_001354009.3", parsimonySitesP: "0.275862068965517", constantSitesP: "0.601330913490623", distinctSitesP: "0.38173018753781", brLenTotal: "1.3375" },
  { geneTranscript: "NM_004091.4", parsimonySitesP: "0.235697940503432", constantSitesP: "0.606407322654462", distinctSitesP: "0.33257055682685", brLenTotal: "0.9498" },
  { geneTranscript: "NM_024901.5", parsimonySitesP: "0.191082802547771", constantSitesP: "0.692852087756546", distinctSitesP: "0.267515923566879", brLenTotal: "0.6482" },
  { geneTranscript: "NM_001365575.2", parsimonySitesP: "0.319555555555556", constantSitesP: "0.496444444444444", distinctSitesP: "0.408888888888889", brLenTotal: "1.0826" },
  { geneTranscript: "NM_020156.5", parsimonySitesP: "0.169880624426079", constantSitesP: "0.66574839302112", distinctSitesP: "0.329660238751148", brLenTotal: "0.6346" },
  { geneTranscript: "NM_032526.3", parsimonySitesP: "0.168478260869565", constantSitesP: "0.719202898550725", distinctSitesP: "0.246376811594203", brLenTotal: "0.7247" },
  { geneTranscript: "NM_003459.5", parsimonySitesP: "0.184707903780069", constantSitesP: "0.713917525773196", distinctSitesP: "0.246563573883162", brLenTotal: "0.7264" },
  { geneTranscript: "NM_001018069.2", parsimonySitesP: "0.150127226463104", constantSitesP: "0.657336726039016", distinctSitesP: "0.280746395250212", brLenTotal: "0.6366" },
  { geneTranscript: "NM_001162435.3", parsimonySitesP: "0.248299319727891", constantSitesP: "0.578231292517007", distinctSitesP: "0.380952380952381", brLenTotal: "0.9824" },
  { geneTranscript: "NM_001127211.3", parsimonySitesP: "0.133122028526149", constantSitesP: "0.754886423666138", distinctSitesP: "0.218700475435816", brLenTotal: "0.4326" },
  { geneTranscript: "NM_020360.4", parsimonySitesP: "0.171751412429379", constantSitesP: "0.703954802259887", distinctSitesP: "0.257627118644068", brLenTotal: "0.6381" },
  { geneTranscript: "NM_005524.4", parsimonySitesP: "0.110714285714286", constantSitesP: "0.820238095238095", distinctSitesP: "0.175", brLenTotal: "0.3548" },
  { geneTranscript: "NM_080667.7", parsimonySitesP: "0.166666666666667", constantSitesP: "0.676413255360624", distinctSitesP: "0.299220272904483", brLenTotal: "0.5070" },
  { geneTranscript: "NM_207118.3", parsimonySitesP: "0.122065727699531", constantSitesP: "0.741784037558685", distinctSitesP: "0.262910798122066", brLenTotal: "0.4252" },
  { geneTranscript: "NM_014584.3", parsimonySitesP: "0.133903133903134", constantSitesP: "0.772079772079772", distinctSitesP: "0.22008547008547", brLenTotal: "0.4260" },
  { geneTranscript: "NM_005359.6", parsimonySitesP: "0.0960144927536232", constantSitesP: "0.830314009661836", distinctSitesP: "0.139492753623188", brLenTotal: "0.3309" },
  { geneTranscript: "NM_031461.6", parsimonySitesP: "0.184", constantSitesP: "0.701333333333333", distinctSitesP: "0.294", brLenTotal: "0.6031" },
  { geneTranscript: "NM_014159.7", parsimonySitesP: "0.16315652626105", constantSitesP: "0.696307852314093", distinctSitesP: "0.188377535101404", brLenTotal: "0.5316" },
  { geneTranscript: "NM_080632.3", parsimonySitesP: "0.121463077984817", constantSitesP: "0.728088336783989", distinctSitesP: "0.24223602484472", brLenTotal: "0.4646" },
  { geneTranscript: "NM_198525.3", parsimonySitesP: "0.243236535120377", constantSitesP: "0.611317944899479", distinctSitesP: "0.312236286919831", brLenTotal: "0.9355" },
  { geneTranscript: "NM_003888.4", parsimonySitesP: "0.135778635778636", constantSitesP: "0.763835263835264", distinctSitesP: "0.202059202059202", brLenTotal: "0.5072" },
  { geneTranscript: "NM_012120.3", parsimonySitesP: "0.171100678142932", constantSitesP: "0.693792383933229", distinctSitesP: "0.288471570161711", brLenTotal: "0.5754" },
  { geneTranscript: "NM_002444.3", parsimonySitesP: "0.150779896013865", constantSitesP: "0.763720392836511", distinctSitesP: "0.194685153090699", brLenTotal: "0.4976" },
  { geneTranscript: "NM_153213.5", parsimonySitesP: "0.211138819617623", constantSitesP: "0.662510390689942", distinctSitesP: "0.28595178719867", brLenTotal: "0.8078" },
  { geneTranscript: "NM_003060.4", parsimonySitesP: "0.213046080191502", constantSitesP: "0.660682226211849", distinctSitesP: "0.323758228605625", brLenTotal: "0.8997" },
  { geneTranscript: "NM_058241.3", parsimonySitesP: "0.104109589041096", constantSitesP: "0.807305936073059", distinctSitesP: "0.151141552511416", brLenTotal: "0.3266" },
  { geneTranscript: "NM_002617.4", parsimonySitesP: "0.311860940695297", constantSitesP: "0.532719836400818", distinctSitesP: "0.458077709611452", brLenTotal: "1.5066" },
  { geneTranscript: "NM_006043.2", parsimonySitesP: "0.230699364214351", constantSitesP: "0.661217075386013", distinctSitesP: "0.29700272479564", brLenTotal: "0.8881" },
  { geneTranscript: "NM_018431.5", parsimonySitesP: "0.143790849673203", constantSitesP: "0.766884531590414", distinctSitesP: "0.202614379084967", brLenTotal: "0.5260" },
  { geneTranscript: "NM_015354.3", parsimonySitesP: "0.172288927005908", constantSitesP: "0.720792834000381", distinctSitesP: "0.204878978463884", brLenTotal: "0.5729" },
  { geneTranscript: "NM_001001683.4", parsimonySitesP: "0.162393162393162", constantSitesP: "0.715099715099715", distinctSitesP: "0.282051282051282", brLenTotal: "0.5081" },
  { geneTranscript: "NM_001278919.2", parsimonySitesP: "0.249478079331942", constantSitesP: "0.60821155184412", distinctSitesP: "0.338552540013918", brLenTotal: "1.2209" },
  { geneTranscript: "NM_031898.3", parsimonySitesP: "0.282993197278912", constantSitesP: "0.56734693877551", distinctSitesP: "0.397959183673469", brLenTotal: "0.9770" },
  { geneTranscript: "NM_006258.4", parsimonySitesP: "0.108843537414966", constantSitesP: "0.824586977648202", distinctSitesP: "0.150145772594752", brLenTotal: "0.3384" },
  { geneTranscript: "NM_001167600.3", parsimonySitesP: "0.365013774104683", constantSitesP: "0.492424242424242", distinctSitesP: "0.482093663911846", brLenTotal: "2.0342" },
  { geneTranscript: "NM_001118.5", parsimonySitesP: "0.183760683760684", constantSitesP: "0.695868945868946", distinctSitesP: "0.277065527065527", brLenTotal: "0.7476" },
  { geneTranscript: "NM_032175.4", parsimonySitesP: "0.165379665379665", constantSitesP: "0.734234234234234", distinctSitesP: "0.249034749034749", brLenTotal: "0.5128" },
  { geneTranscript: "NM_000191.3", parsimonySitesP: "0.252307692307692", constantSitesP: "0.612307692307692", distinctSitesP: "0.355897435897436", brLenTotal: "0.8915" },
  { geneTranscript: "NM_002096.3", parsimonySitesP: "0.217279174725983", constantSitesP: "0.692456479690522", distinctSitesP: "0.302385557704707", brLenTotal: "1.1891" },
  { geneTranscript: "NM_024664.4", parsimonySitesP: "0.197213290460879", constantSitesP: "0.685959271168274", distinctSitesP: "0.295819935691318", brLenTotal: "0.6052" },
  { geneTranscript: "NM_080704.4", parsimonySitesP: "0.259038537941994", constantSitesP: "0.597934048470401", distinctSitesP: "0.363528009535161", brLenTotal: "1.1123" },
  { geneTranscript: "NM_016308.3", parsimonySitesP: "0.165204678362573", constantSitesP: "0.711988304093567", distinctSitesP: "0.289473684210526", brLenTotal: "0.4931" },
  { geneTranscript: "NM_175747.2", parsimonySitesP: "0.144607843137255", constantSitesP: "0.763480392156863", distinctSitesP: "0.213235294117647", brLenTotal: "0.5282" },
  { geneTranscript: "NM_145807.4", parsimonySitesP: "0.263803680981595", constantSitesP: "0.590320381731425", distinctSitesP: "0.355146557600545", brLenTotal: "1.0692" },
  { geneTranscript: "NM_005871.4", parsimonySitesP: "0.107843137254902", constantSitesP: "0.788515406162465", distinctSitesP: "0.186274509803922", brLenTotal: "0.3195" },
  { geneTranscript: "NM_001127496.3", parsimonySitesP: "0.158305462653289", constantSitesP: "0.727982162764771", distinctSitesP: "0.251950947603122", brLenTotal: "0.8236" },
  { geneTranscript: "NM_182925.5", parsimonySitesP: "0.24700415749572", constantSitesP: "0.624602592320861", distinctSitesP: "0.310344827586207", brLenTotal: "1.0693" },
  { geneTranscript: "NM_016390.4", parsimonySitesP: "0.228723404255319", constantSitesP: "0.656914893617021", distinctSitesP: "0.320921985815603", brLenTotal: "0.8863" },
  { geneTranscript: "NM_006038.4", parsimonySitesP: "0.220512820512821", constantSitesP: "0.646153846153846", distinctSitesP: "0.284615384615385", brLenTotal: "0.9308" },
  { geneTranscript: "NM_001024678.4", parsimonySitesP: "0.294346978557505", constantSitesP: "0.554256010396361", distinctSitesP: "0.387264457439896", brLenTotal: "1.2461" },
  { geneTranscript: "NM_001387220.1", parsimonySitesP: "0.0652724968314322", constantSitesP: "0.875158428390368", distinctSitesP: "0.107731305449937", brLenTotal: "0.2098" },
  { geneTranscript: "NM_003934.2", parsimonySitesP: "0.153263403263403", constantSitesP: "0.747086247086247", distinctSitesP: "0.228438228438228", brLenTotal: "0.6194" },
  { geneTranscript: "NM_133496.5", parsimonySitesP: "0.120567375886525", constantSitesP: "0.763297872340426", distinctSitesP: "0.212765957446809", brLenTotal: "0.4560" },
  { geneTranscript: "NM_001370959.1", parsimonySitesP: "0.110648148148148", constantSitesP: "0.793981481481481", distinctSitesP: "0.180555555555556", brLenTotal: "0.4346" },
  { geneTranscript: "NM_020800.3", parsimonySitesP: "0.138567138567139", constantSitesP: "0.750750750750751", distinctSitesP: "0.215787215787216", brLenTotal: "0.4513" },
  { geneTranscript: "NM_181606.3", parsimonySitesP: "0.237547892720307", constantSitesP: "0.651340996168582", distinctSitesP: "0.344827586206897", brLenTotal: "0.6328" },
  { geneTranscript: "NM_030630.3", parsimonySitesP: "0.159475465313029", constantSitesP: "0.746615905245347", distinctSitesP: "0.212774957698816", brLenTotal: "0.7783" },
  { geneTranscript: "NM_001393892.1", parsimonySitesP: "0.15929203539823", constantSitesP: "0.748525073746313", distinctSitesP: "0.210914454277286", brLenTotal: "0.7456" },
  { geneTranscript: "NM_001308154.2", parsimonySitesP: "0.132075471698113", constantSitesP: "0.738993710691824", distinctSitesP: "0.221698113207547", brLenTotal: "0.6292" },
  { geneTranscript: "NM_198992.4", parsimonySitesP: "0.169534735500319", constantSitesP: "0.680050987890376", distinctSitesP: "0.251115360101976", brLenTotal: "0.7353" },
  { geneTranscript: "NM_004183.4", parsimonySitesP: "0.271225071225071", constantSitesP: "0.602849002849003", distinctSitesP: "0.348717948717949", brLenTotal: "0.9958" },
  { geneTranscript: "NM_006802.4", parsimonySitesP: "0.131736526946108", constantSitesP: "0.761144377910845", distinctSitesP: "0.198935462408516", brLenTotal: "0.4589" },
  { geneTranscript: "NM_002573.4", parsimonySitesP: "0.168831168831169", constantSitesP: "0.711399711399711", distinctSitesP: "0.264069264069264", brLenTotal: "0.5298" },
  { geneTranscript: "NM_005521.4", parsimonySitesP: "0.205050505050505", constantSitesP: "0.692929292929293", distinctSitesP: "0.276767676767677", brLenTotal: "0.9098" },
  { geneTranscript: "NM_001010875.4", parsimonySitesP: "0.175257731958763", constantSitesP: "0.707903780068729", distinctSitesP: "0.278350515463918", brLenTotal: "0.7135" },
  { geneTranscript: "NM_032825.5", parsimonySitesP: "0.340606060606061", constantSitesP: "0.493333333333333", distinctSitesP: "0.434545454545455", brLenTotal: "1.2410" },
  { geneTranscript: "NM_173860.3", parsimonySitesP: "0.126477541371158", constantSitesP: "0.778959810874704", distinctSitesP: "0.215130023640662", brLenTotal: "0.4799" },
  { geneTranscript: "NM_024869.3", parsimonySitesP: "0.259532595325953", constantSitesP: "0.600246002460025", distinctSitesP: "0.364083640836408", brLenTotal: "0.8516" },
  { geneTranscript: "NM_002029.4", parsimonySitesP: "0.533333333333333", constantSitesP: "0.281904761904762", distinctSitesP: "0.719047619047619", brLenTotal: "2.6299" },
  { geneTranscript: "NM_020981.4", parsimonySitesP: "0.150306748466258", constantSitesP: "0.769938650306748", distinctSitesP: "0.206543967280164", brLenTotal: "0.4742" },
  { geneTranscript: "NM_001015882.3", parsimonySitesP: "0.171296296296296", constantSitesP: "0.712962962962963", distinctSitesP: "0.265740740740741", brLenTotal: "0.5286" },
  { geneTranscript: "NM_000264.5", parsimonySitesP: "0.162404975812025", constantSitesP: "0.742225293711126", distinctSitesP: "0.208246947707901", brLenTotal: "0.7120" },
  { geneTranscript: "NM_130386.3", parsimonySitesP: "0.172955974842767", constantSitesP: "0.703504043126685", distinctSitesP: "0.247978436657682", brLenTotal: "0.6759" },
  { geneTranscript: "NM_004116.5", parsimonySitesP: "0.0709876543209877", constantSitesP: "0.885802469135802", distinctSitesP: "0.132716049382716", brLenTotal: "0.2276" },
  { geneTranscript: "NM_001080432.3", parsimonySitesP: "0.188118811881188", constantSitesP: "0.704290429042904", distinctSitesP: "0.250825082508251", brLenTotal: "0.6956" },
  { geneTranscript: "NM_001163.4", parsimonySitesP: "0.20310633213859", constantSitesP: "0.71963361210673", distinctSitesP: "0.247710075667065", brLenTotal: "0.7649" },
  { geneTranscript: "NM_001136157.2", parsimonySitesP: "0.151354534746761", constantSitesP: "0.761484098939929", distinctSitesP: "0.219670200235571", brLenTotal: "0.5158" },
  { geneTranscript: "NM_014342.4", parsimonySitesP: "0.125412541254125", constantSitesP: "0.795379537953795", distinctSitesP: "0.227722772277228", brLenTotal: "0.3794" },
  { geneTranscript: "NM_031296.3", parsimonySitesP: "0.155749636098981", constantSitesP: "0.743813682678311", distinctSitesP: "0.21688500727802", brLenTotal: "0.5972" },
  { geneTranscript: "NM_001039591.3", parsimonySitesP: "0.121508744453145", constantSitesP: "0.789480553380318", distinctSitesP: "0.149177760375881", brLenTotal: "0.4526" },
  { geneTranscript: "NM_001083613.2", parsimonySitesP: "0.209722222222222", constantSitesP: "0.651388888888889", distinctSitesP: "0.322222222222222", brLenTotal: "0.6782" },
  { geneTranscript: "NM_005738.5", parsimonySitesP: "0.148333333333333", constantSitesP: "0.665", distinctSitesP: "0.316666666666667", brLenTotal: "0.5902" },
  { geneTranscript: "NM_001035254.3", parsimonySitesP: "0.170138888888889", constantSitesP: "0.735243055555556", distinctSitesP: "0.228298611111111", brLenTotal: "0.8094" },
  { geneTranscript: "NM_000481.4", parsimonySitesP: "0.23904052936311", constantSitesP: "0.626964433416046", distinctSitesP: "0.334160463192721", brLenTotal: "0.8068" },
  { geneTranscript: "NM_014043.4", parsimonySitesP: "0.120500782472613", constantSitesP: "0.774647887323944", distinctSitesP: "0.208137715179969", brLenTotal: "0.4689" },
  { geneTranscript: "NM_033380.3", parsimonySitesP: "0.14508180563769", constantSitesP: "0.746895328208161", distinctSitesP: "0.187265917602996", brLenTotal: "0.4484" },
  { geneTranscript: "NM_007327.4", parsimonySitesP: "0.132906894100924", constantSitesP: "0.798507462686567", distinctSitesP: "0.169509594882729", brLenTotal: "0.5903" },
  { geneTranscript: "NM_023940.3", parsimonySitesP: "0.208333333333333", constantSitesP: "0.649193548387097", distinctSitesP: "0.32258064516129", brLenTotal: "0.9060" },
  { geneTranscript: "NM_001077198.3", parsimonySitesP: "0.134286849423917", constantSitesP: "0.774731823599523", distinctSitesP: "0.171632896305125", brLenTotal: "0.4656" },
  { geneTranscript: "NM_006336.4", parsimonySitesP: "0.151436031331593", constantSitesP: "0.77154046997389", distinctSitesP: "0.181897302001741", brLenTotal: "0.5992" },
  { geneTranscript: "NM_006644.4", parsimonySitesP: "0.151515151515152", constantSitesP: "0.71950271950272", distinctSitesP: "0.21950271950272", brLenTotal: "0.5531" },
  { geneTranscript: "NM_004076.5", parsimonySitesP: "0.232227488151659", constantSitesP: "0.657187993680885", distinctSitesP: "0.330173775671406", brLenTotal: "0.8175" },
  { geneTranscript: "NM_001377142.1", parsimonySitesP: "0.124378109452736", constantSitesP: "0.789939192924268", distinctSitesP: "0.183250414593698", brLenTotal: "0.4625" },
  { geneTranscript: "NM_018027.5", parsimonySitesP: "0.153994225216554", constantSitesP: "0.756496631376323", distinctSitesP: "0.201796599294193", brLenTotal: "0.6873" },
  { geneTranscript: "NM_015662.3", parsimonySitesP: "0.180293501048218", constantSitesP: "0.676577091671431", distinctSitesP: "0.229655040975796", brLenTotal: "0.6522" },
  { geneTranscript: "NM_001304.5", parsimonySitesP: "0.158937198067633", constantSitesP: "0.738647342995169", distinctSitesP: "0.201932367149758", brLenTotal: "0.4788" },
  { geneTranscript: "NM_001010854.2", parsimonySitesP: "0.141162514827995", constantSitesP: "0.73942269671807", distinctSitesP: "0.207987346777382", brLenTotal: "0.6764" },
  { geneTranscript: "NM_182898.4", parsimonySitesP: "0.122047244094488", constantSitesP: "0.794619422572178", distinctSitesP: "0.18241469816273", brLenTotal: "0.4637" },
  { geneTranscript: "NM_001005519.2", parsimonySitesP: "0.511752136752137", constantSitesP: "0.35042735042735", distinctSitesP: "0.633547008547009", brLenTotal: "3.0835" },
  { geneTranscript: "NM_173824.4", parsimonySitesP: "0.214792299898683", constantSitesP: "0.641337386018237", distinctSitesP: "0.368794326241135", brLenTotal: "0.7053" },
  { geneTranscript: "NM_001001563.5", parsimonySitesP: "0.195467422096317", constantSitesP: "0.694050991501416", distinctSitesP: "0.275731822474032", brLenTotal: "0.7236" },
  { geneTranscript: "NM_020442.6", parsimonySitesP: "0.249921605518971", constantSitesP: "0.600501724678583", distinctSitesP: "0.324553151458137", brLenTotal: "0.9449" },
  { geneTranscript: "NM_002715.4", parsimonySitesP: "0.132686084142395", constantSitesP: "0.747572815533981", distinctSitesP: "0.227615965480043", brLenTotal: "0.5123" },
  { geneTranscript: "NM_005286.4", parsimonySitesP: "0.418418418418418", constantSitesP: "0.477477477477477", distinctSitesP: "0.469469469469469", brLenTotal: "1.7268" },
  { geneTranscript: "NM_001376.5", parsimonySitesP: "0.177715597646721", constantSitesP: "0.748457454441096", distinctSitesP: "0.186540393169752", brLenTotal: "0.7946" },
  { geneTranscript: "NM_005990.4", parsimonySitesP: "0.212809917355372", constantSitesP: "0.673553719008264", distinctSitesP: "0.289600550964187", brLenTotal: "0.8102" },
  { geneTranscript: "NM_153614.4", parsimonySitesP: "0.207805907172996", constantSitesP: "0.672995780590717", distinctSitesP: "0.305907172995781", brLenTotal: "0.7053" },
  { geneTranscript: "NM_014742.4", parsimonySitesP: "0.135514018691589", constantSitesP: "0.77466251298027", distinctSitesP: "0.186915887850467", brLenTotal: "0.4473" },
  { geneTranscript: "NM_024881.5", parsimonySitesP: "0.224390243902439", constantSitesP: "0.669918699186992", distinctSitesP: "0.320325203252033", brLenTotal: "0.9767" },
  { geneTranscript: "NM_205859.2", parsimonySitesP: "0.339662447257384", constantSitesP: "0.457805907172996", distinctSitesP: "0.479957805907173", brLenTotal: "1.3268" },
  { geneTranscript: "NM_003876.3", parsimonySitesP: "0.154513888888889", constantSitesP: "0.786458333333333", distinctSitesP: "0.213541666666667", brLenTotal: "0.6502" },
  { geneTranscript: "NM_022717.4", parsimonySitesP: "0.191056910569106", constantSitesP: "0.681571815718157", distinctSitesP: "0.279132791327913", brLenTotal: "0.8907" },
  { geneTranscript: "NM_173555.4", parsimonySitesP: "0.303297997644287", constantSitesP: "0.55359246171967", distinctSitesP: "0.378680800942285", brLenTotal: "1.1245" },
  { geneTranscript: "NM_032730.5", parsimonySitesP: "0.196969696969697", constantSitesP: "0.667508417508418", distinctSitesP: "0.313973063973064", brLenTotal: "0.7102" },
  { geneTranscript: "NM_032138.7", parsimonySitesP: "0.220272904483431", constantSitesP: "0.660331384015595", distinctSitesP: "0.290448343079922", brLenTotal: "0.9051" },
  { geneTranscript: "NM_033198.4", parsimonySitesP: "0.192192192192192", constantSitesP: "0.681681681681682", distinctSitesP: "0.26006006006006", brLenTotal: "0.6294" },
  { geneTranscript: "NM_001290258.2", parsimonySitesP: "0.197845804988662", constantSitesP: "0.658730158730159", distinctSitesP: "0.302721088435374", brLenTotal: "0.6864" },
  { geneTranscript: "NM_018045.8", parsimonySitesP: "0.17906976744186", constantSitesP: "0.687596899224806", distinctSitesP: "0.272868217054264", brLenTotal: "0.6561" },
  { geneTranscript: "NM_080617.6", parsimonySitesP: "0.145936981757877", constantSitesP: "0.724709784411277", distinctSitesP: "0.243781094527363", brLenTotal: "0.5682" },
  { geneTranscript: "NM_001010926.4", parsimonySitesP: "0.178714859437751", constantSitesP: "0.684738955823293", distinctSitesP: "0.335341365461847", brLenTotal: "0.7019" },
  { geneTranscript: "NM_004860.4", parsimonySitesP: "0.131748390292224", constantSitesP: "0.789004457652303", distinctSitesP: "0.163447251114413", brLenTotal: "0.4065" },
  { geneTranscript: "NM_015439.3", parsimonySitesP: "0.128623188405797", constantSitesP: "0.759057971014493", distinctSitesP: "0.242753623188406", brLenTotal: "0.4252" },
  { geneTranscript: "NM_006092.4", parsimonySitesP: "0.257082896117524", constantSitesP: "0.604407135362015", distinctSitesP: "0.332284015389997", brLenTotal: "1.0112" },
  { geneTranscript: "NM_001369203.1", parsimonySitesP: "0.244725738396624", constantSitesP: "0.552742616033755", distinctSitesP: "0.438818565400844", brLenTotal: "0.8481" },
  { geneTranscript: "NM_001237.5", parsimonySitesP: "0.16358024691358", constantSitesP: "0.693672839506173", distinctSitesP: "0.283950617283951", brLenTotal: "0.5267" },
  { geneTranscript: "NM_017899.4", parsimonySitesP: "0.163551401869159", constantSitesP: "0.753894080996885", distinctSitesP: "0.235202492211838", brLenTotal: "0.6372" },
  { geneTranscript: "NM_014169.5", parsimonySitesP: "0.160660660660661", constantSitesP: "0.737237237237237", distinctSitesP: "0.234234234234234", brLenTotal: "0.5131" },
  { geneTranscript: "NM_013366.4", parsimonySitesP: "0.199918896999189", constantSitesP: "0.689375506893755", distinctSitesP: "0.246553122465531", brLenTotal: "0.9610" },
  { geneTranscript: "NM_172193.3", parsimonySitesP: "0.120689655172414", constantSitesP: "0.766830870279146", distinctSitesP: "0.206896551724138", brLenTotal: "0.3822" },
  { geneTranscript: "NM_001145474.4", parsimonySitesP: "0.275080906148867", constantSitesP: "0.52588996763754", distinctSitesP: "0.5", brLenTotal: "0.9742" },
  { geneTranscript: "NM_031208.4", parsimonySitesP: "0.242835595776772", constantSitesP: "0.570135746606335", distinctSitesP: "0.352941176470588", brLenTotal: "0.8447" },
  { geneTranscript: "NM_001039348.3", parsimonySitesP: "0.155510480054091", constantSitesP: "0.750507099391481", distinctSitesP: "0.211629479377958", brLenTotal: "0.5331" },
  { geneTranscript: "NM_002952.4", parsimonySitesP: "0.244596131968146", constantSitesP: "0.588168373151308", distinctSitesP: "0.40386803185438", brLenTotal: "1.2014" },
  { geneTranscript: "NM_006060.6", parsimonySitesP: "0.14193962748876", constantSitesP: "0.759152215799615", distinctSitesP: "0.204238921001927", brLenTotal: "0.5525" },
  { geneTranscript: "NM_017818.4", parsimonySitesP: "0.231884057971014", constantSitesP: "0.654347826086957", distinctSitesP: "0.336231884057971", brLenTotal: "1.1663" },
  { geneTranscript: "NM_018257.3", parsimonySitesP: "0.181902123730379", constantSitesP: "0.708217913204063", distinctSitesP: "0.256694367497692", brLenTotal: "0.6520" },
  { geneTranscript: "NM_006488.3", parsimonySitesP: "0.206935123042506", constantSitesP: "0.672259507829978", distinctSitesP: "0.297539149888143", brLenTotal: "0.8536" },
  { geneTranscript: "NM_001039614.3", parsimonySitesP: "0.188850967007964", constantSitesP: "0.676905574516496", distinctSitesP: "0.292377701934016", brLenTotal: "0.8610" },
  { geneTranscript: "NM_031432.5", parsimonySitesP: "0.217809867629362", constantSitesP: "0.660649819494585", distinctSitesP: "0.306859205776173", brLenTotal: "0.8833" },
  { geneTranscript: "NM_201628.3", parsimonySitesP: "0.150537634408602", constantSitesP: "0.737204301075269", distinctSitesP: "0.211182795698925", brLenTotal: "0.6994" },
  { geneTranscript: "NM_004733.4", parsimonySitesP: "0.166970248937462", constantSitesP: "0.726168791742562", distinctSitesP: "0.218579234972678", brLenTotal: "0.4885" },
  { geneTranscript: "NM_001144995.2", parsimonySitesP: "0.159904534606205", constantSitesP: "0.639618138424821", distinctSitesP: "0.273667462211615", brLenTotal: "0.7942" },
  { geneTranscript: "NM_022362.5", parsimonySitesP: "0.182847896440129", constantSitesP: "0.667313915857605", distinctSitesP: "0.257281553398058", brLenTotal: "0.6174" },
  { geneTranscript: "NM_001504.2", parsimonySitesP: "0.242753623188406", constantSitesP: "0.658514492753623", distinctSitesP: "0.308876811594203", brLenTotal: "0.8896" },
  { geneTranscript: "NM_198963.3", parsimonySitesP: "0.168590668590669", constantSitesP: "0.702982202982203", distinctSitesP: "0.224146224146224", brLenTotal: "0.5849" },
  { geneTranscript: "NM_052897.4", parsimonySitesP: "0.106015287470921", constantSitesP: "0.78996344300432", distinctSitesP: "0.15785975407112", brLenTotal: "0.3695" },
  { geneTranscript: "NM_012213.3", parsimonySitesP: "0.260311020960108", constantSitesP: "0.630155510480054", distinctSitesP: "0.373225152129817", brLenTotal: "1.0716" },
  { geneTranscript: "NM_171999.4", parsimonySitesP: "0.288205128205128", constantSitesP: "0.567692307692308", distinctSitesP: "0.37974358974359", brLenTotal: "1.6356" },
  { geneTranscript: "NM_000308.4", parsimonySitesP: "0.196527777777778", constantSitesP: "0.683333333333333", distinctSitesP: "0.281944444444444", brLenTotal: "0.6811" },
  { geneTranscript: "NM_002940.3", parsimonySitesP: "0.115748469671675", constantSitesP: "0.798553144129104", distinctSitesP: "0.187534780189204", brLenTotal: "0.3818" },
  { geneTranscript: "NM_015275.3", parsimonySitesP: "0.104290991759022", constantSitesP: "0.807047456663825", distinctSitesP: "0.150326797385621", brLenTotal: "0.3402" },
  { geneTranscript: "NM_002233.4", parsimonySitesP: "0.180704441041348", constantSitesP: "0.737621235324145", distinctSitesP: "0.227156712608474", brLenTotal: "0.7815" },
  { geneTranscript: "NM_001012968.3", parsimonySitesP: "0.168674698795181", constantSitesP: "0.728246318607764", distinctSitesP: "0.231593038821954", brLenTotal: "0.5582" },
  { geneTranscript: "NM_001142.2", parsimonySitesP: "0.130890052356021", constantSitesP: "0.75043630017452", distinctSitesP: "0.232111692844677", brLenTotal: "0.4715" },
  { geneTranscript: "NM_002268.5", parsimonySitesP: "0.0940499040307102", constantSitesP: "0.822776711452335", distinctSitesP: "0.147792706333973", brLenTotal: "0.2824" },
  { geneTranscript: "NM_020374.4", parsimonySitesP: "0.14975845410628", constantSitesP: "0.732487922705314", distinctSitesP: "0.228260869565217", brLenTotal: "0.5281" },
  { geneTranscript: "NM_020768.4", parsimonySitesP: "0.129283489096573", constantSitesP: "0.780373831775701", distinctSitesP: "0.197040498442368", brLenTotal: "0.4459" },
  { geneTranscript: "NM_001199417.2", parsimonySitesP: "0.188016990833892", constantSitesP: "0.687010954616588", distinctSitesP: "0.245919964229823", brLenTotal: "0.8042" },
  { geneTranscript: "NM_014390.4", parsimonySitesP: "0.132600732600733", constantSitesP: "0.789377289377289", distinctSitesP: "0.165201465201465", brLenTotal: "0.4818" },
  { geneTranscript: "NM_001160167.2", parsimonySitesP: "0.188405797101449", constantSitesP: "0.638586956521739", distinctSitesP: "0.305253623188406", brLenTotal: "0.8739" },
  { geneTranscript: "NM_005787.6", parsimonySitesP: "0.169710806697108", constantSitesP: "0.707762557077626", distinctSitesP: "0.246575342465753", brLenTotal: "0.6555" },
  { geneTranscript: "NM_198276.3", parsimonySitesP: "0.208754208754209", constantSitesP: "0.676767676767677", distinctSitesP: "0.303030303030303", brLenTotal: "0.6199" },
  { geneTranscript: "NM_030796.5", parsimonySitesP: "0.242248062015504", constantSitesP: "0.653100775193798", distinctSitesP: "0.375968992248062", brLenTotal: "0.9729" },
  { geneTranscript: "NM_006384.4", parsimonySitesP: "0.174520069808028", constantSitesP: "0.727748691099476", distinctSitesP: "0.256544502617801", brLenTotal: "0.7028" },
  { geneTranscript: "NM_002428.4", parsimonySitesP: "0.210264075734928", constantSitesP: "0.684603886397608", distinctSitesP: "0.269058295964126", brLenTotal: "0.9283" },
  { geneTranscript: "NM_001010898.4", parsimonySitesP: "0.142595139844108", constantSitesP: "0.763869784502522", distinctSitesP: "0.191655204034846", brLenTotal: "0.6539" },
  { geneTranscript: "NM_001271186.2", parsimonySitesP: "0.0564663023679417", constantSitesP: "0.910746812386157", distinctSitesP: "0.0892531876138434", brLenTotal: "0.1444" },
  { geneTranscript: "NM_002046.7", parsimonySitesP: "0.225870646766169", constantSitesP: "0.58407960199005", distinctSitesP: "0.382089552238806", brLenTotal: "1.1001" },
  { geneTranscript: "NM_187841.3", parsimonySitesP: "0.173184357541899", constantSitesP: "0.706703910614525", distinctSitesP: "0.274674115456238", brLenTotal: "0.7467" },
  { geneTranscript: "NM_020338.4", parsimonySitesP: "0.118400499843799", constantSitesP: "0.818181818181818", distinctSitesP: "0.150265542018119", brLenTotal: "0.5785" },
  { geneTranscript: "NM_001127198.5", parsimonySitesP: "0.299378881987578", constantSitesP: "0.542443064182195", distinctSitesP: "0.401656314699793", brLenTotal: "1.3045" },
  { geneTranscript: "NM_000441.2", parsimonySitesP: "0.186324786324786", constantSitesP: "0.708974358974359", distinctSitesP: "0.28034188034188", brLenTotal: "0.6572" },
  { geneTranscript: "NM_001389.5", parsimonySitesP: "0.149768058316766", constantSitesP: "0.767395626242545", distinctSitesP: "0.17428760768721", brLenTotal: "0.6149" },
  { geneTranscript: "NM_138463.4", parsimonySitesP: "0.249662618083671", constantSitesP: "0.635627530364372", distinctSitesP: "0.350877192982456", brLenTotal: "0.7668" },
  { geneTranscript: "NM_001375765.1", parsimonySitesP: "0.187117552334944", constantSitesP: "0.714975845410628", distinctSitesP: "0.264090177133655", brLenTotal: "0.8814" },
  { geneTranscript: "NM_003870.4", parsimonySitesP: "0.188493260913297", constantSitesP: "0.716556024944679", distinctSitesP: "0.215449607724804", brLenTotal: "0.6062" },
  { geneTranscript: "NM_206922.3", parsimonySitesP: "0.181372549019608", constantSitesP: "0.709150326797386", distinctSitesP: "0.281045751633987", brLenTotal: "0.5565" },
  { geneTranscript: "NM_001032.5", parsimonySitesP: "0.220238095238095", constantSitesP: "0.577380952380952", distinctSitesP: "0.446428571428571", brLenTotal: "0.9233" },
  { geneTranscript: "NM_001258400.2", parsimonySitesP: "0.263922518159806", constantSitesP: "0.591606133979015", distinctSitesP: "0.338983050847458", brLenTotal: "1.0914" },
  { geneTranscript: "NM_012268.4", parsimonySitesP: "0.17687074829932", constantSitesP: "0.741496598639456", distinctSitesP: "0.224489795918367", brLenTotal: "0.6908" },
  { geneTranscript: "NM_152308.3", parsimonySitesP: "0.328798185941043", constantSitesP: "0.464852607709751", distinctSitesP: "0.512471655328798", brLenTotal: "1.3526" },
  { geneTranscript: "NM_001276252.2", parsimonySitesP: "0.14081733136386", constantSitesP: "0.756277695716396", distinctSitesP: "0.185622845888725", brLenTotal: "0.5852" },
  { geneTranscript: "NM_199511.3", parsimonySitesP: "0.227719298245614", constantSitesP: "0.61719298245614", distinctSitesP: "0.325964912280702", brLenTotal: "0.8311" },
  { geneTranscript: "NM_001384528.1", parsimonySitesP: "0.229758149316509", constantSitesP: "0.657728706624606", distinctSitesP: "0.280231335436383", brLenTotal: "1.0561" },
  { geneTranscript: "NM_001004483.1", parsimonySitesP: "0.48125", constantSitesP: "0.34375", distinctSitesP: "0.648958333333333", brLenTotal: "2.0275" },
  { geneTranscript: "NM_032323.3", parsimonySitesP: "0.263959390862944", constantSitesP: "0.6082910321489", distinctSitesP: "0.33756345177665", brLenTotal: "0.9058" },
  { geneTranscript: "NM_005456.4", parsimonySitesP: "0.161275199249883", constantSitesP: "0.732770745428973", distinctSitesP: "0.23300515705579", brLenTotal: "0.6804" },
  { geneTranscript: "NM_182503.3", parsimonySitesP: "0.19022687609075", constantSitesP: "0.664921465968586", distinctSitesP: "0.31413612565445", brLenTotal: "0.8014" },
  { geneTranscript: "NM_001004711.2", parsimonySitesP: "0.4447983014862", constantSitesP: "0.361995753715499", distinctSitesP: "0.589171974522293", brLenTotal: "3.4227" },
  { geneTranscript: "NM_012262.4", parsimonySitesP: "0.0955056179775281", constantSitesP: "0.821161048689139", distinctSitesP: "0.162921348314607", brLenTotal: "0.2929" },
  { geneTranscript: "NM_020125.3", parsimonySitesP: "0.297076023391813", constantSitesP: "0.538011695906433", distinctSitesP: "0.44093567251462", brLenTotal: "1.0827" },
  { geneTranscript: "NM_001193511.2", parsimonySitesP: "0.134155455904335", constantSitesP: "0.777653213751868", distinctSitesP: "0.170403587443946", brLenTotal: "0.4223" },
  { geneTranscript: "NM_004996.4", parsimonySitesP: "0.257348138471587", constantSitesP: "0.608099281515349", distinctSitesP: "0.332680165469192", brLenTotal: "1.1614" },
  { geneTranscript: "NM_001130823.3", parsimonySitesP: "0.210375816993464", constantSitesP: "0.678921568627451", distinctSitesP: "0.260416666666667", brLenTotal: "1.0002" },
  { geneTranscript: "NM_002893.4", parsimonySitesP: "0.0933333333333333", constantSitesP: "0.816470588235294", distinctSitesP: "0.148235294117647", brLenTotal: "0.3367" },
  { geneTranscript: "NM_022893.4", parsimonySitesP: "0.0315369261477046", constantSitesP: "0.942914171656687", distinctSitesP: "0.0530938123752495", brLenTotal: "0.0983" },
  { geneTranscript: "NM_001323329.2", parsimonySitesP: "0.118657298985168", constantSitesP: "0.811865729898517", distinctSitesP: "0.163934426229508", brLenTotal: "0.4297" },
  { geneTranscript: "NM_032869.4", parsimonySitesP: "0.161806746712407", constantSitesP: "0.71412235563179", distinctSitesP: "0.234419668381933", brLenTotal: "0.5118" },
  { geneTranscript: "NM_012399.5", parsimonySitesP: "0.0996309963099631", constantSitesP: "0.831488314883149", distinctSitesP: "0.169741697416974", brLenTotal: "0.3119" },
  { geneTranscript: "NM_000246.4", parsimonySitesP: "0.327728613569322", constantSitesP: "0.506784660766962", distinctSitesP: "0.42330383480826", brLenTotal: "1.3707" },
  { geneTranscript: "NM_001304748.2", parsimonySitesP: "0.204427083333333", constantSitesP: "0.638020833333333", distinctSitesP: "0.338541666666667", brLenTotal: "0.8589" },
  { geneTranscript: "NM_053041.3", parsimonySitesP: "0.143333333333333", constantSitesP: "0.725", distinctSitesP: "0.288333333333333", brLenTotal: "0.5474" },
  { geneTranscript: "NM_005642.3", parsimonySitesP: "0.166189111747851", constantSitesP: "0.669531996179561", distinctSitesP: "0.3018147086915", brLenTotal: "0.6308" },
  { geneTranscript: "NM_145657.3", parsimonySitesP: "0.181818181818182", constantSitesP: "0.695707070707071", distinctSitesP: "0.282828282828283", brLenTotal: "0.7122" },
  { geneTranscript: "NM_015329.4", parsimonySitesP: "0.147362697117999", constantSitesP: "0.774877650897227", distinctSitesP: "0.194671016856987", brLenTotal: "0.7385" },
  { geneTranscript: "NM_170692.4", parsimonySitesP: "0.149479166666667", constantSitesP: "0.75", distinctSitesP: "0.18046875", brLenTotal: "0.6605" },
  { geneTranscript: "NM_031412.4", parsimonySitesP: "0.105413105413105", constantSitesP: "0.831908831908832", distinctSitesP: "0.173789173789174", brLenTotal: "0.3127" },
  { geneTranscript: "NM_006638.4", parsimonySitesP: "0.235996326905418", constantSitesP: "0.629935720844812", distinctSitesP: "0.341597796143251", brLenTotal: "0.7632" },
  { geneTranscript: "NM_198536.3", parsimonySitesP: "0.220458553791887", constantSitesP: "0.6331569664903", distinctSitesP: "0.328042328042328", brLenTotal: "0.8137" },
  { geneTranscript: "NM_030907.4", parsimonySitesP: "0.232558139534884", constantSitesP: "0.630490956072351", distinctSitesP: "0.343669250645995", brLenTotal: "0.8711" },
  { geneTranscript: "NM_002858.4", parsimonySitesP: "0.129489124936773", constantSitesP: "0.770864946889226", distinctSitesP: "0.204350025290845", brLenTotal: "0.4030" },
  { geneTranscript: "NM_013321.4", parsimonySitesP: "0.221505376344086", constantSitesP: "0.621505376344086", distinctSitesP: "0.341935483870968", brLenTotal: "0.9128" },
  { geneTranscript: "NM_078629.4", parsimonySitesP: "0.144593730006398", constantSitesP: "0.72680742162508", distinctSitesP: "0.229686500319898", brLenTotal: "0.5480" },
  { geneTranscript: "NM_022899.5", parsimonySitesP: "0.135683760683761", constantSitesP: "0.778311965811966", distinctSitesP: "0.194978632478632", brLenTotal: "0.5098" },
  { geneTranscript: "NM_006717.3", parsimonySitesP: "0.120865139949109", constantSitesP: "0.763358778625954", distinctSitesP: "0.170483460559796", brLenTotal: "0.4431" },
  { geneTranscript: "NM_138982.4", parsimonySitesP: "0.0991379310344828", constantSitesP: "0.834770114942529", distinctSitesP: "0.149425287356322", brLenTotal: "0.4077" },
  { geneTranscript: "NM_000219.6", parsimonySitesP: "0.333333333333333", constantSitesP: "0.516795865633075", distinctSitesP: "0.501291989664083", brLenTotal: "1.7668" },
  { geneTranscript: "NM_021223.3", parsimonySitesP: "0.16", constantSitesP: "0.718095238095238", distinctSitesP: "0.260952380952381", brLenTotal: "0.6860" },
  { geneTranscript: "NM_001111067.4", parsimonySitesP: "0.134250163719712", constantSitesP: "0.793713163064833", distinctSitesP: "0.182056319580878", brLenTotal: "0.4486" },
  { geneTranscript: "NM_001395413.1", parsimonySitesP: "0.224027572624323", constantSitesP: "0.682914820285574", distinctSitesP: "0.289020187099951", brLenTotal: "1.0067" },
  { geneTranscript: "NM_018195.4", parsimonySitesP: "0.156996587030717", constantSitesP: "0.696245733788396", distinctSitesP: "0.273037542662116", brLenTotal: "0.4990" },
  { geneTranscript: "NM_020645.3", parsimonySitesP: "0.13969571230982", constantSitesP: "0.739972337482711", distinctSitesP: "0.262793914246196", brLenTotal: "0.4324" },
  { geneTranscript: "NM_001922.5", parsimonySitesP: "0.242132305716121", constantSitesP: "0.605651894669236", distinctSitesP: "0.357739242132306", brLenTotal: "0.9029" },
  { geneTranscript: "NM_001003.3", parsimonySitesP: "0.137426900584795", constantSitesP: "0.692982456140351", distinctSitesP: "0.298245614035088", brLenTotal: "0.6047" },
  { geneTranscript: "NM_000524.4", parsimonySitesP: "0.209320695102686", constantSitesP: "0.67693522906793", distinctSitesP: "0.285150078988942", brLenTotal: "0.7180" },
  { geneTranscript: "NM_021637.3", parsimonySitesP: "0.119760479041916", constantSitesP: "0.788423153692615", distinctSitesP: "0.207584830339321", brLenTotal: "0.3830" },
  { geneTranscript: "NM_001136049.3", parsimonySitesP: "0.183235867446394", constantSitesP: "0.699317738791423", distinctSitesP: "0.274366471734893", brLenTotal: "0.5722" },
  { geneTranscript: "NM_015132.5", parsimonySitesP: "0.0978753047718565", constantSitesP: "0.83002438174852", distinctSitesP: "0.13758272378962", brLenTotal: "0.2941" },
  { geneTranscript: "NM_032581.4", parsimonySitesP: "0.108125399872041", constantSitesP: "0.785668586052463", distinctSitesP: "0.182341650671785", brLenTotal: "0.3616" },
  { geneTranscript: "NM_018994.3", parsimonySitesP: "0.136215713621571", constantSitesP: "0.761041376104138", distinctSitesP: "0.192933519293352", brLenTotal: "0.4522" },
  { geneTranscript: "NM_016338.5", parsimonySitesP: "0.118290598290598", constantSitesP: "0.797606837606838", distinctSitesP: "0.174700854700855", brLenTotal: "0.3883" },
  { geneTranscript: "NM_006477.5", parsimonySitesP: "0.170771756978654", constantSitesP: "0.702791461412151", distinctSitesP: "0.243021346469622", brLenTotal: "0.6560" },
  { geneTranscript: "NM_152727.6", parsimonySitesP: "0.187347931873479", constantSitesP: "0.70316301703163", distinctSitesP: "0.261557177615572", brLenTotal: "0.7493" },
  { geneTranscript: "NM_023034.2", parsimonySitesP: "0.151472976107632", constantSitesP: "0.725121781489214", distinctSitesP: "0.210392020412897", brLenTotal: "0.5878" },
  { geneTranscript: "NM_004228.7", parsimonySitesP: "0.150375939849624", constantSitesP: "0.770258980785297", distinctSitesP: "0.201336675020886", brLenTotal: "0.6603" },
  { geneTranscript: "NM_018688.6", parsimonySitesP: "0.177865612648221", constantSitesP: "0.723320158102767", distinctSitesP: "0.27536231884058", brLenTotal: "0.8094" },
  { geneTranscript: "NM_004255.4", parsimonySitesP: "0.137777777777778", constantSitesP: "0.668888888888889", distinctSitesP: "0.288888888888889", brLenTotal: "0.5328" },
  { geneTranscript: "NM_004730.4", parsimonySitesP: "0.07627765064836", constantSitesP: "0.812356979405034", distinctSitesP: "0.166285278413425", brLenTotal: "0.2911" },
  { geneTranscript: "NM_013353.3", parsimonySitesP: "0.154589371980676", constantSitesP: "0.752657004830918", distinctSitesP: "0.222222222222222", brLenTotal: "0.5037" },
  { geneTranscript: "NM_025073.3", parsimonySitesP: "0.115942028985507", constantSitesP: "0.782608695652174", distinctSitesP: "0.190016103059581", brLenTotal: "0.3756" },
  { geneTranscript: "NM_001005327.3", parsimonySitesP: "0.483597883597884", constantSitesP: "0.372486772486772", distinctSitesP: "0.62010582010582", brLenTotal: "2.2106" },
  { geneTranscript: "NM_022461.5", parsimonySitesP: "0.157312925170068", constantSitesP: "0.699829931972789", distinctSitesP: "0.261904761904762", brLenTotal: "0.5156" },
  { geneTranscript: "NM_001267547.3", parsimonySitesP: "0.185737976782753", constantSitesP: "0.72636815920398", distinctSitesP: "0.262023217247098", brLenTotal: "0.9837" },
  { geneTranscript: "NM_015057.5", parsimonySitesP: "0.118070400456035", constantSitesP: "0.792503919053727", distinctSitesP: "0.124554652985606", brLenTotal: "0.4036" },
  { geneTranscript: "NM_003800.5", parsimonySitesP: "0.115577889447236", constantSitesP: "0.793969849246231", distinctSitesP: "0.173087660524846", brLenTotal: "0.3538" },
  { geneTranscript: "NM_000424.4", parsimonySitesP: "0.269491525423729", constantSitesP: "0.60225988700565", distinctSitesP: "0.379661016949153", brLenTotal: "1.4250" },
  { geneTranscript: "NM_016018.5", parsimonySitesP: "0.113077679449361", constantSitesP: "0.788593903638151", distinctSitesP: "0.183218616846935", brLenTotal: "0.3882" },
  { geneTranscript: "NM_000890.5", parsimonySitesP: "0.262529832935561", constantSitesP: "0.60381861575179", distinctSitesP: "0.346857597454256", brLenTotal: "1.2073" },
  { geneTranscript: "NM_021640.4", parsimonySitesP: "0.238475177304965", constantSitesP: "0.601063829787234", distinctSitesP: "0.363475177304965", brLenTotal: "0.8796" },
  { geneTranscript: "NM_019854.5", parsimonySitesP: "0.109983079526227", constantSitesP: "0.813874788494078", distinctSitesP: "0.175972927241963", brLenTotal: "0.5081" },
  { geneTranscript: "NM_025147.5", parsimonySitesP: "0.197478991596639", constantSitesP: "0.637254901960784", distinctSitesP: "0.350140056022409", brLenTotal: "0.7050" },
  { geneTranscript: "NM_138801.3", parsimonySitesP: "0.213450292397661", constantSitesP: "0.599415204678363", distinctSitesP: "0.367446393762183", brLenTotal: "0.8669" },
  { geneTranscript: "NM_013312.3", parsimonySitesP: "0.23643949930459", constantSitesP: "0.625405656003709", distinctSitesP: "0.312007417709782", brLenTotal: "0.8658" },
  { geneTranscript: "NM_182915.3", parsimonySitesP: "0.224230254350736", constantSitesP: "0.65194109772423", distinctSitesP: "0.313922356091031", brLenTotal: "1.0436" },
  { geneTranscript: "NM_020832.3", parsimonySitesP: "0.243600107787658", constantSitesP: "0.6238210724872", distinctSitesP: "0.279708973322555", brLenTotal: "0.8742" },
  { geneTranscript: "NM_006709.5", parsimonySitesP: "0.174931129476584", constantSitesP: "0.734435261707989", distinctSitesP: "0.215151515151515", brLenTotal: "0.7879" },
  { geneTranscript: "NM_002660.3", parsimonySitesP: "0.143041569842499", constantSitesP: "0.767105602891815", distinctSitesP: "0.168086754453912", brLenTotal: "0.5580" },
  { geneTranscript: "NM_005562.3", parsimonySitesP: "0.243084660519698", constantSitesP: "0.617770326906957", distinctSitesP: "0.333892148644873", brLenTotal: "0.8988" },
  { geneTranscript: "NM_001277062.2", parsimonySitesP: "0.126002290950745", constantSitesP: "0.715922107674685", distinctSitesP: "0.239404352806415", brLenTotal: "0.4854" },
  { geneTranscript: "NM_001005192.2", parsimonySitesP: "0.577706323687031", constantSitesP: "0.27438370846731", distinctSitesP: "0.721329046087889", brLenTotal: "4.2166" },
  { geneTranscript: "NM_183065.4", parsimonySitesP: "0.192857142857143", constantSitesP: "0.688095238095238", distinctSitesP: "0.304761904761905", brLenTotal: "0.5669" },
  { geneTranscript: "NM_002228.4", parsimonySitesP: "0.103726082578046", constantSitesP: "0.800604229607251", distinctSitesP: "0.169184290030211", brLenTotal: "0.3406" },
  { geneTranscript: "NM_152478.3", parsimonySitesP: "0.258933801991798", constantSitesP: "0.579964850615114", distinctSitesP: "0.368482718219098", brLenTotal: "1.1358" },
  { geneTranscript: "NM_001386889.1", parsimonySitesP: "0.212806026365348", constantSitesP: "0.687382297551789", distinctSitesP: "0.297551789077213", brLenTotal: "0.6694" },
  { geneTranscript: "NM_014515.7", parsimonySitesP: "0.0524691358024691", constantSitesP: "0.9", distinctSitesP: "0.0895061728395062", brLenTotal: "0.1564" },
  { geneTranscript: "NM_198514.4", parsimonySitesP: "0.194674012855831", constantSitesP: "0.682736455463728", distinctSitesP: "0.307621671258035", brLenTotal: "0.6567" },
  { geneTranscript: "NM_020382.7", parsimonySitesP: "0.168560606060606", constantSitesP: "0.664772727272727", distinctSitesP: "0.267992424242424", brLenTotal: "0.7075" },
  { geneTranscript: "NM_017825.3", parsimonySitesP: "0.194674012855831", constantSitesP: "0.68595041322314", distinctSitesP: "0.284664830119376", brLenTotal: "0.7285" },
  { geneTranscript: "NM_001946.4", parsimonySitesP: "0.121609798775153", constantSitesP: "0.771653543307087", distinctSitesP: "0.188976377952756", brLenTotal: "0.4558" },
  { geneTranscript: "NM_001395167.1", parsimonySitesP: "0.140642303433001", constantSitesP: "0.77796234772979", distinctSitesP: "0.185492801771872", brLenTotal: "0.5523" },
  { geneTranscript: "NM_004797.4", parsimonySitesP: "0.259562841530055", constantSitesP: "0.627049180327869", distinctSitesP: "0.37431693989071", brLenTotal: "0.8528" },
  { geneTranscript: "NM_001308147.2", parsimonySitesP: "0.282745419742959", constantSitesP: "0.5701394585726", distinctSitesP: "0.356302980585179", brLenTotal: "1.1740" },
  { geneTranscript: "NM_203349.4", parsimonySitesP: "0.196825396825397", constantSitesP: "0.669312169312169", distinctSitesP: "0.295238095238095", brLenTotal: "0.6594" },
  { geneTranscript: "NM_016291.4", parsimonySitesP: "0.149452269170579", constantSitesP: "0.763693270735524", distinctSitesP: "0.208137715179969", brLenTotal: "0.5130" },
  { geneTranscript: "NM_018375.5", parsimonySitesP: "0.168295331161781", constantSitesP: "0.718783930510315", distinctSitesP: "0.243213897937025", brLenTotal: "0.6224" },
  { geneTranscript: "NM_177963.4", parsimonySitesP: "0.185273159144893", constantSitesP: "0.705463182897862", distinctSitesP: "0.268408551068884", brLenTotal: "0.7862" },
  { geneTranscript: "NM_006943.4", parsimonySitesP: "0.136507936507937", constantSitesP: "0.803174603174603", distinctSitesP: "0.19047619047619", brLenTotal: "0.4353" },
  { geneTranscript: "NM_001130173.2", parsimonySitesP: "0.131844064826982", constantSitesP: "0.742882172579939", distinctSitesP: "0.19886114761279", brLenTotal: "0.4598" },
  { geneTranscript: "NM_014692.2", parsimonySitesP: "0.230842911877395", constantSitesP: "0.658524904214559", distinctSitesP: "0.315613026819923", brLenTotal: "0.9986" },
  { geneTranscript: "NM_139239.3", parsimonySitesP: "0.208732694355698", constantSitesP: "0.673056443024494", distinctSitesP: "0.303514376996805", brLenTotal: "0.8519" },
  { geneTranscript: "NM_007274.4", parsimonySitesP: "0.203603603603604", constantSitesP: "0.675675675675676", distinctSitesP: "0.293693693693694", brLenTotal: "0.9923" },
  { geneTranscript: "NM_013293.5", parsimonySitesP: "0.120567375886525", constantSitesP: "0.73758865248227", distinctSitesP: "0.235224586288416", brLenTotal: "0.4992" },
  { geneTranscript: "NM_020834.3", parsimonySitesP: "0.203636363636364", constantSitesP: "0.666060606060606", distinctSitesP: "0.276969696969697", brLenTotal: "0.6036" },
  { geneTranscript: "NM_003505.2", parsimonySitesP: "0.198351365275631", constantSitesP: "0.64554353426069", distinctSitesP: "0.278722308088614", brLenTotal: "0.8362" },
  { geneTranscript: "NM_032360.4", parsimonySitesP: "0.215130023640662", constantSitesP: "0.66903073286052", distinctSitesP: "0.312056737588652", brLenTotal: "0.7001" },
  { geneTranscript: "NM_001769.4", parsimonySitesP: "0.219298245614035", constantSitesP: "0.704678362573099", distinctSitesP: "0.301169590643275", brLenTotal: "0.9275" },
  { geneTranscript: "NM_002844.4", parsimonySitesP: "0.148611111111111", constantSitesP: "0.763657407407407", distinctSitesP: "0.184490740740741", brLenTotal: "0.5396" },
  { geneTranscript: "NM_000533.5", parsimonySitesP: "0.0565583634175692", constantSitesP: "0.885679903730445", distinctSitesP: "0.0998796630565584", brLenTotal: "0.1579" },
  { geneTranscript: "NM_001378189.1", parsimonySitesP: "0.210666666666667", constantSitesP: "0.663466666666667", distinctSitesP: "0.293333333333333", brLenTotal: "0.7296" },
  { geneTranscript: "NM_020693.4", parsimonySitesP: "0.136223412891703", constantSitesP: "0.776424744276668", distinctSitesP: "0.166747848676733", brLenTotal: "0.6299" },
  { geneTranscript: "NM_001372052.1", parsimonySitesP: "0.121760096443641", constantSitesP: "0.758288125376733", distinctSitesP: "0.200120554550934", brLenTotal: "0.4015" },
  { geneTranscript: "NM_018405.4", parsimonySitesP: "0.253623188405797", constantSitesP: "0.545289855072464", distinctSitesP: "0.40036231884058", brLenTotal: "0.9493" },
  { geneTranscript: "NM_003439.4", parsimonySitesP: "0.178211959739491", constantSitesP: "0.716400236826525", distinctSitesP: "0.2599171107164", brLenTotal: "0.6193" },
  { geneTranscript: "NM_001386814.1", parsimonySitesP: "0.166391184573003", constantSitesP: "0.722865013774105", distinctSitesP: "0.229752066115702", brLenTotal: "0.7158" },
  { geneTranscript: "NM_001097622.2", parsimonySitesP: "0.134556574923547", constantSitesP: "0.798165137614679", distinctSitesP: "0.235474006116208", brLenTotal: "0.4488" },
  { geneTranscript: "NM_000193.4", parsimonySitesP: "0.193362193362193", constantSitesP: "0.697691197691198", distinctSitesP: "0.29004329004329", brLenTotal: "0.7838" },
  { geneTranscript: "NM_022765.4", parsimonySitesP: "0.23961262105592", constantSitesP: "0.609497032177445", distinctSitesP: "0.329896907216495", brLenTotal: "0.8919" },
  { geneTranscript: "NM_152232.6", parsimonySitesP: "0.335717123559793", constantSitesP: "0.524831148192292", distinctSitesP: "0.440603893524037", brLenTotal: "1.4242" },
  { geneTranscript: "NM_001199753.2", parsimonySitesP: "0.220838522208385", constantSitesP: "0.640514736405147", distinctSitesP: "0.299294312992943", brLenTotal: "0.8631" },
  { geneTranscript: "NM_005514.8", parsimonySitesP: "0.347145488029466", constantSitesP: "0.413443830570902", distinctSitesP: "0.569060773480663", brLenTotal: "2.6351" },
  { geneTranscript: "NM_174938.6", parsimonySitesP: "0.125628140703518", constantSitesP: "0.760469011725293", distinctSitesP: "0.197096594081519", brLenTotal: "0.4485" },
  { geneTranscript: "NM_001004318.3", parsimonySitesP: "0.257990867579909", constantSitesP: "0.611111111111111", distinctSitesP: "0.353120243531202", brLenTotal: "1.0866" },
  { geneTranscript: "NM_002700.3", parsimonySitesP: "0.231755424063116", constantSitesP: "0.692307692307692", distinctSitesP: "0.233727810650888", brLenTotal: "0.8625" },
  { geneTranscript: "NM_020357.3", parsimonySitesP: "0.0880149812734082", constantSitesP: "0.734082397003745", distinctSitesP: "0.256554307116105", brLenTotal: "0.3825" },
  { geneTranscript: "NM_003170.5", parsimonySitesP: "0.132097334878331", constantSitesP: "0.786597141753573", distinctSitesP: "0.152568559289301", brLenTotal: "0.4493" },
  { geneTranscript: "NM_014062.3", parsimonySitesP: "0.235436893203883", constantSitesP: "0.625404530744337", distinctSitesP: "0.352750809061489", brLenTotal: "0.9330" },
  { geneTranscript: "NM_004638.4", parsimonySitesP: "0.195951166743934", constantSitesP: "0.673157162726008", distinctSitesP: "0.248338742080049", brLenTotal: "0.6833" },
  { geneTranscript: "NM_013437.5", parsimonySitesP: "0.13814512999612", constantSitesP: "0.746216530849825", distinctSitesP: "0.207605743112146", brLenTotal: "0.4832" },
  { geneTranscript: "NM_001198800.3", parsimonySitesP: "0.183006535947712", constantSitesP: "0.713352007469655", distinctSitesP: "0.257703081232493", brLenTotal: "0.5688" },
  { geneTranscript: "NM_012203.2", parsimonySitesP: "0.211382113821138", constantSitesP: "0.663617886178862", distinctSitesP: "0.323170731707317", brLenTotal: "0.8279" },
  { geneTranscript: "NM_014643.4", parsimonySitesP: "0.280022929206076", constantSitesP: "0.574949842361708", distinctSitesP: "0.372599598738894", brLenTotal: "1.3094" },
  { geneTranscript: "NM_021964.3", parsimonySitesP: "0.0713685978169605", constantSitesP: "0.86272040302267", distinctSitesP: "0.107052896725441", brLenTotal: "0.2401" },
  { geneTranscript: "NM_198559.2", parsimonySitesP: "0.311800172265289", constantSitesP: "0.522825150732127", distinctSitesP: "0.464254952627046", brLenTotal: "1.1005" },
  { geneTranscript: "NM_001369769.2", parsimonySitesP: "0.262536873156342", constantSitesP: "0.603034134007585", distinctSitesP: "0.324062368310156", brLenTotal: "1.0652" },
  { geneTranscript: "NM_002507.4", parsimonySitesP: "0.178766588602654", constantSitesP: "0.727556596409055", distinctSitesP: "0.246682279469165", brLenTotal: "0.8336" },
  { geneTranscript: "NM_002083.4", parsimonySitesP: "0.149122807017544", constantSitesP: "0.643859649122807", distinctSitesP: "0.271929824561404", brLenTotal: "0.6875" },
  { geneTranscript: "NM_016038.4", parsimonySitesP: "0.156", constantSitesP: "0.729333333333333", distinctSitesP: "0.284", brLenTotal: "0.5084" },
  { geneTranscript: "NM_007030.3", parsimonySitesP: "0.235920852359209", constantSitesP: "0.604261796042618", distinctSitesP: "0.354642313546423", brLenTotal: "1.5756" },
  { geneTranscript: "NM_016162.4", parsimonySitesP: "0.0779569892473118", constantSitesP: "0.853494623655914", distinctSitesP: "0.133064516129032", brLenTotal: "0.2301" },
  { geneTranscript: "NM_018013.4", parsimonySitesP: "0.119893088965254", constantSitesP: "0.755250095456281", distinctSitesP: "0.206567392134402", brLenTotal: "0.4816" },
  { geneTranscript: "NM_007242.7", parsimonySitesP: "0.153096729297147", constantSitesP: "0.750869867780097", distinctSitesP: "0.225469728601253", brLenTotal: "0.6652" },
  { geneTranscript: "NM_015915.5", parsimonySitesP: "0.116487455197133", constantSitesP: "0.801075268817204", distinctSitesP: "0.166666666666667", brLenTotal: "0.3376" },
  { geneTranscript: "NM_031484.4", parsimonySitesP: "0.163776493256262", constantSitesP: "0.682080924855491", distinctSitesP: "0.312138728323699", brLenTotal: "0.6481" },
  { geneTranscript: "NM_000719.7", parsimonySitesP: "0.18288119738073", constantSitesP: "0.722793888369192", distinctSitesP: "0.218584346741503", brLenTotal: "0.8966" },
  { geneTranscript: "NM_001007527.2", parsimonySitesP: "0.114148681055156", constantSitesP: "0.790407673860911", distinctSitesP: "0.170263788968825", brLenTotal: "0.3672" },
  { geneTranscript: "NM_153207.5", parsimonySitesP: "0.0775347912524851", constantSitesP: "0.835652750165673", distinctSitesP: "0.157720344599072", brLenTotal: "0.2927" },
  { geneTranscript: "NM_052937.4", parsimonySitesP: "0.141923436041083", constantSitesP: "0.736694677871148", distinctSitesP: "0.222222222222222", brLenTotal: "0.5478" },
  { geneTranscript: "NM_002112.4", parsimonySitesP: "0.222054380664653", constantSitesP: "0.667170191339376", distinctSitesP: "0.283484390735146", brLenTotal: "0.7987" },
  { geneTranscript: "NM_017449.5", parsimonySitesP: "0.158553076402975", constantSitesP: "0.733603786342123", distinctSitesP: "0.205544286680189", brLenTotal: "0.9732" },
  { geneTranscript: "NM_032960.4", parsimonySitesP: "0.148333333333333", constantSitesP: "0.776666666666667", distinctSitesP: "0.246666666666667", brLenTotal: "0.5358" },
  { geneTranscript: "NM_001008938.4", parsimonySitesP: "0.130249343832021", constantSitesP: "0.766404199475066", distinctSitesP: "0.155183727034121", brLenTotal: "0.4235" },
  { geneTranscript: "NM_005721.5", parsimonySitesP: "0.108452950558214", constantSitesP: "0.769537480063796", distinctSitesP: "0.180223285486443", brLenTotal: "0.4162" },
  { geneTranscript: "NM_031485.4", parsimonySitesP: "0.222720478325859", constantSitesP: "0.667414050822123", distinctSitesP: "0.29745889387145", brLenTotal: "0.9756" },
  { geneTranscript: "NM_182765.6", parsimonySitesP: "0.113831615120275", constantSitesP: "0.779209621993127", distinctSitesP: "0.190292096219931", brLenTotal: "0.3598" },
  { geneTranscript: "NM_003756.3", parsimonySitesP: "0.154356060606061", constantSitesP: "0.705492424242424", distinctSitesP: "0.261363636363636", brLenTotal: "0.6100" },
  { geneTranscript: "NM_198219.3", parsimonySitesP: "0.229390681003584", constantSitesP: "0.654719235364397", distinctSitesP: "0.314217443249701", brLenTotal: "0.8493" },
  { geneTranscript: "NM_001105564.2", parsimonySitesP: "0.266743207041715", constantSitesP: "0.559127439724455", distinctSitesP: "0.371603520857252", brLenTotal: "0.9654" },
  { geneTranscript: "NM_001281775.3", parsimonySitesP: "0.16612641815235", constantSitesP: "0.738519719070773", distinctSitesP: "0.225823878984333", brLenTotal: "0.6984" },
  { geneTranscript: "NM_001369863.1", parsimonySitesP: "0.171912832929782", constantSitesP: "0.718321226795803", distinctSitesP: "0.278450363196126", brLenTotal: "0.6149" },
  { geneTranscript: "NM_022900.5", parsimonySitesP: "0.108322877457131", constantSitesP: "0.785027185278126", distinctSitesP: "0.17189460476788", brLenTotal: "0.3726" },
  { geneTranscript: "NM_000374.5", parsimonySitesP: "0.166212534059946", constantSitesP: "0.721162579473206", distinctSitesP: "0.242506811989101", brLenTotal: "0.5229" },
  { geneTranscript: "NM_144607.6", parsimonySitesP: "0.184210526315789", constantSitesP: "0.68859649122807", distinctSitesP: "0.288011695906433", brLenTotal: "0.5462" },
  { geneTranscript: "NM_002206.3", parsimonySitesP: "0.202286719437115", constantSitesP: "0.676927587217825", distinctSitesP: "0.244209909117561", brLenTotal: "0.7646" },
  { geneTranscript: "NM_001258374.3", parsimonySitesP: "0.182051282051282", constantSitesP: "0.721978021978022", distinctSitesP: "0.252747252747253", brLenTotal: "0.8410" },
  { geneTranscript: "NM_015169.4", parsimonySitesP: "0.270319634703196", constantSitesP: "0.491324200913242", distinctSitesP: "0.427397260273973", brLenTotal: "1.0684" },
  { geneTranscript: "NM_001317056.2", parsimonySitesP: "0.246031746031746", constantSitesP: "0.598124098124098", distinctSitesP: "0.316017316017316", brLenTotal: "0.9247" },
  { geneTranscript: "NM_198147.3", parsimonySitesP: "0.267094017094017", constantSitesP: "0.598290598290598", distinctSitesP: "0.35042735042735", brLenTotal: "0.9052" },
  { geneTranscript: "NM_015952.4", parsimonySitesP: "0.128943758573388", constantSitesP: "0.714677640603567", distinctSitesP: "0.267489711934156", brLenTotal: "0.4905" },
  { geneTranscript: "NM_032168.3", parsimonySitesP: "0.170682730923695", constantSitesP: "0.704819277108434", distinctSitesP: "0.282329317269076", brLenTotal: "0.5935" },
  { geneTranscript: "NM_020883.2", parsimonySitesP: "0.153305203938115", constantSitesP: "0.70492264416315", distinctSitesP: "0.218284106891702", brLenTotal: "0.5595" },
  { geneTranscript: "NM_020247.5", parsimonySitesP: "0.249356002060793", constantSitesP: "0.650695517774343", distinctSitesP: "0.337454920144256", brLenTotal: "1.0823" },
  { geneTranscript: "NM_005602.6", parsimonySitesP: "0.186795491143317", constantSitesP: "0.740740740740741", distinctSitesP: "0.267310789049919", brLenTotal: "0.8041" },
  { geneTranscript: "NM_000250.2", parsimonySitesP: "0.277852348993289", constantSitesP: "0.572706935123043", distinctSitesP: "0.379418344519016", brLenTotal: "1.2982" },
  { geneTranscript: "NM_003718.5", parsimonySitesP: "0.109567901234568", constantSitesP: "0.797178130511464", distinctSitesP: "0.144179894179894", brLenTotal: "0.3410" },
  { geneTranscript: "NM_001164446.3", parsimonySitesP: "0.348484848484848", constantSitesP: "0.435465768799102", distinctSitesP: "0.521604938271605", brLenTotal: "1.3919" },
  { geneTranscript: "NM_001099780.2", parsimonySitesP: "0.24", constantSitesP: "0.607777777777778", distinctSitesP: "0.341111111111111", brLenTotal: "1.0016" },
  { geneTranscript: "NM_145306.3", parsimonySitesP: "0.159779614325069", constantSitesP: "0.702479338842975", distinctSitesP: "0.283746556473829", brLenTotal: "0.7949" },
  { geneTranscript: "NM_001005289.5", parsimonySitesP: "0.421443736730361", constantSitesP: "0.407643312101911", distinctSitesP: "0.587048832271762", brLenTotal: "1.8647" },
  { geneTranscript: "NM_003831.5", parsimonySitesP: "0.113680154142582", constantSitesP: "0.784200385356455", distinctSitesP: "0.182402055234425", brLenTotal: "0.4094" },
  { geneTranscript: "NM_012321.5", parsimonySitesP: "0.175059952038369", constantSitesP: "0.741007194244604", distinctSitesP: "0.268585131894484", brLenTotal: "0.8456" },
  { geneTranscript: "NM_001378328.1", parsimonySitesP: "0.347466048360384", constantSitesP: "0.521695925803246", distinctSitesP: "0.408413381914541", brLenTotal: "1.8984" },
  { geneTranscript: "NM_003168.3", parsimonySitesP: "0.113960113960114", constantSitesP: "0.789173789173789", distinctSitesP: "0.210826210826211", brLenTotal: "0.3996" },
  { geneTranscript: "NM_174934.4", parsimonySitesP: "0.214912280701754", constantSitesP: "0.64327485380117", distinctSitesP: "0.353801169590643", brLenTotal: "0.8186" },
  { geneTranscript: "NM_001172173.2", parsimonySitesP: "0.154985754985755", constantSitesP: "0.736182336182336", distinctSitesP: "0.223361823361823", brLenTotal: "0.6087" },
  { geneTranscript: "NM_001806.4", parsimonySitesP: "0.208888888888889", constantSitesP: "0.702222222222222", distinctSitesP: "0.295555555555556", brLenTotal: "0.7204" },
  { geneTranscript: "NM_005967.4", parsimonySitesP: "0.127619047619048", constantSitesP: "0.779047619047619", distinctSitesP: "0.186666666666667", brLenTotal: "0.4475" },
  { geneTranscript: "NM_001135155.3", parsimonySitesP: "0.0981912144702842", constantSitesP: "0.837209302325581", distinctSitesP: "0.14900947459087", brLenTotal: "0.3592" },
  { geneTranscript: "NM_178565.5", parsimonySitesP: "0.131687242798354", constantSitesP: "0.769547325102881", distinctSitesP: "0.212620027434842", brLenTotal: "0.4467" },
  { geneTranscript: "NM_052841.4", parsimonySitesP: "0.139303482587065", constantSitesP: "0.748756218905473", distinctSitesP: "0.225124378109453", brLenTotal: "0.5978" },
  { geneTranscript: "NM_001004354.3", parsimonySitesP: "0.125730994152047", constantSitesP: "0.766081871345029", distinctSitesP: "0.204678362573099", brLenTotal: "0.4289" },
  { geneTranscript: "NM_001004487.1", parsimonySitesP: "0.414529914529915", constantSitesP: "0.395299145299145", distinctSitesP: "0.52991452991453", brLenTotal: "1.8334" },
  { geneTranscript: "NM_004214.5", parsimonySitesP: "0.156862745098039", constantSitesP: "0.749766573295985", distinctSitesP: "0.211951447245565", brLenTotal: "0.5224" },
  { geneTranscript: "NM_002344.6", parsimonySitesP: "0.263117283950617", constantSitesP: "0.552083333333333", distinctSitesP: "0.371527777777778", brLenTotal: "1.0081" },
  { geneTranscript: "NM_000529.2", parsimonySitesP: "0.254769921436588", constantSitesP: "0.616161616161616", distinctSitesP: "0.372615039281706", brLenTotal: "0.9655" },
  { geneTranscript: "NM_013372.7", parsimonySitesP: "0.132246376811594", constantSitesP: "0.778985507246377", distinctSitesP: "0.21195652173913", brLenTotal: "0.5437" },
  { geneTranscript: "NM_013232.4", parsimonySitesP: "0.193717277486911", constantSitesP: "0.720767888307155", distinctSitesP: "0.317626527050611", brLenTotal: "0.7558" },
  { geneTranscript: "NM_001010883.3", parsimonySitesP: "0.15", constantSitesP: "0.747222222222222", distinctSitesP: "0.243518518518519", brLenTotal: "0.4957" },
  { geneTranscript: "NM_002588.4", parsimonySitesP: "0.183797287651677", constantSitesP: "0.69129193433262", distinctSitesP: "0.224482512491078", brLenTotal: "0.6384" },
  { geneTranscript: "NM_001144889.2", parsimonySitesP: "0.248087431693989", constantSitesP: "0.579781420765027", distinctSitesP: "0.362295081967213", brLenTotal: "0.8828" },
  { geneTranscript: "NM_003850.3", parsimonySitesP: "0.173506119510439", constantSitesP: "0.672426205903528", distinctSitesP: "0.292296616270698", brLenTotal: "0.6578" },
  { geneTranscript: "NM_001001973.3", parsimonySitesP: "0.225950782997763", constantSitesP: "0.61744966442953", distinctSitesP: "0.368008948545861", brLenTotal: "0.8635" },
  { geneTranscript: "NM_019100.5", parsimonySitesP: "0.141327623126338", constantSitesP: "0.760885082084226", distinctSitesP: "0.198429693076374", brLenTotal: "0.6198" },
  { geneTranscript: "NM_003632.3", parsimonySitesP: "0.177263969171484", constantSitesP: "0.736994219653179", distinctSitesP: "0.204961464354528", brLenTotal: "0.6651" },
  { geneTranscript: "NM_012420.3", parsimonySitesP: "0.195020746887967", constantSitesP: "0.646611341632089", distinctSitesP: "0.278008298755187", brLenTotal: "0.7017" },
  { geneTranscript: "NM_033272.4", parsimonySitesP: "0.173076923076923", constantSitesP: "0.71711259754738", distinctSitesP: "0.218784838350056", brLenTotal: "0.6005" },
  { geneTranscript: "NM_001085049.3", parsimonySitesP: "0.123397435897436", constantSitesP: "0.786858974358974", distinctSitesP: "0.197115384615385", brLenTotal: "0.5728" },
  { geneTranscript: "NM_000723.5", parsimonySitesP: "0.127090301003344", constantSitesP: "0.778149386845039", distinctSitesP: "0.177814938684504", brLenTotal: "0.4795" },
  { geneTranscript: "NM_001144967.3", parsimonySitesP: "0.15008547008547", constantSitesP: "0.772307692307692", distinctSitesP: "0.208547008547009", brLenTotal: "0.6413" },
  { geneTranscript: "NM_001171907.3", parsimonySitesP: "0.29535864978903", constantSitesP: "0.4957805907173", distinctSitesP: "0.50210970464135", brLenTotal: "1.1002" },
  { geneTranscript: "NM_002012.4", parsimonySitesP: "0.235827664399093", constantSitesP: "0.585034013605442", distinctSitesP: "0.408163265306122", brLenTotal: "0.8911" },
  { geneTranscript: "NM_003523.3", parsimonySitesP: "0.203703703703704", constantSitesP: "0.69047619047619", distinctSitesP: "0.306878306878307", brLenTotal: "1.6763" },
  { geneTranscript: "NM_001164478.2", parsimonySitesP: "0.214492753623188", constantSitesP: "0.576811594202899", distinctSitesP: "0.426086956521739", brLenTotal: "0.6981" },
  { geneTranscript: "NM_020780.2", parsimonySitesP: "0.224616858237548", constantSitesP: "0.654693486590038", distinctSitesP: "0.289750957854406", brLenTotal: "1.0083" },
  { geneTranscript: "NM_002871.5", parsimonySitesP: "0.151761517615176", constantSitesP: "0.747967479674797", distinctSitesP: "0.249322493224932", brLenTotal: "0.6026" },
  { geneTranscript: "NM_001032221.6", parsimonySitesP: "0.154882154882155", constantSitesP: "0.761503928170595", distinctSitesP: "0.201459034792368", brLenTotal: "0.6670" },
  { geneTranscript: "NM_004943.2", parsimonySitesP: "0.202274975272008", constantSitesP: "0.677546983184965", distinctSitesP: "0.262116716122651", brLenTotal: "1.1123" },
  { geneTranscript: "NM_000289.6", parsimonySitesP: "0.168376068376068", constantSitesP: "0.742735042735043", distinctSitesP: "0.226495726495726", brLenTotal: "0.6449" },
  { geneTranscript: "NM_004972.4", parsimonySitesP: "0.126619552414605", constantSitesP: "0.779740871613663", distinctSitesP: "0.163427561837456", brLenTotal: "0.3675" },
  { geneTranscript: "NM_001033044.4", parsimonySitesP: "0.179624664879357", constantSitesP: "0.615728328865058", distinctSitesP: "0.322609472743521", brLenTotal: "0.7990" },
  { geneTranscript: "NM_006409.4", parsimonySitesP: "0.167567567567568", constantSitesP: "0.641441441441441", distinctSitesP: "0.333333333333333", brLenTotal: "0.8115" },
  { geneTranscript: "NM_001134707.2", parsimonySitesP: "0.273420479302832", constantSitesP: "0.589687726942629", distinctSitesP: "0.363471314451707", brLenTotal: "1.3430" },
  { geneTranscript: "NM_018593.5", parsimonySitesP: "0.218122977346278", constantSitesP: "0.636893203883495", distinctSitesP: "0.289967637540453", brLenTotal: "0.7744" },
  { geneTranscript: "NM_000163.5", parsimonySitesP: "0.209508881922675", constantSitesP: "0.6478578892372", distinctSitesP: "0.300940438871473", brLenTotal: "0.6595" },
  { geneTranscript: "NM_013374.6", parsimonySitesP: "0.124807987711214", constantSitesP: "0.76689708141321", distinctSitesP: "0.188556067588326", brLenTotal: "0.4264" },
  { geneTranscript: "NM_004819.3", parsimonySitesP: "0.181580324437467", constantSitesP: "0.731030873888017", distinctSitesP: "0.21847200418629", brLenTotal: "0.7737" },
  { geneTranscript: "NM_015317.5", parsimonySitesP: "0.0742481203007519", constantSitesP: "0.856203007518797", distinctSitesP: "0.107142857142857", brLenTotal: "0.2406" },
  { geneTranscript: "NM_033630.3", parsimonySitesP: "0.363128491620112", constantSitesP: "0.443202979515829", distinctSitesP: "0.538175046554935", brLenTotal: "1.4443" },
  { geneTranscript: "NM_018439.4", parsimonySitesP: "0.11875", constantSitesP: "0.761458333333333", distinctSitesP: "0.235416666666667", brLenTotal: "0.4243" },
  { geneTranscript: "NM_001164839.2", parsimonySitesP: "0.184557438794727", constantSitesP: "0.657250470809793", distinctSitesP: "0.31638418079096", brLenTotal: "0.5902" },
  { geneTranscript: "NM_003321.5", parsimonySitesP: "0.224908424908425", constantSitesP: "0.655677655677656", distinctSitesP: "0.295970695970696", brLenTotal: "0.7872" },
  { geneTranscript: "NM_022100.3", parsimonySitesP: "0.244791666666667", constantSitesP: "0.604166666666667", distinctSitesP: "0.388020833333333", brLenTotal: "0.8902" },
  { geneTranscript: "NM_014504.3", parsimonySitesP: "0.147318397827563", constantSitesP: "0.730482009504413", distinctSitesP: "0.230821452817379", brLenTotal: "0.5454" },
  { geneTranscript: "NM_006699.5", parsimonySitesP: "0.138325533021321", constantSitesP: "0.753510140405616", distinctSitesP: "0.195527821112845", brLenTotal: "0.4732" },
  { geneTranscript: "NM_003412.4", parsimonySitesP: "0.117822520507084", constantSitesP: "0.817300521998509", distinctSitesP: "0.16331096196868", brLenTotal: "0.4047" },
  { geneTranscript: "NM_005354.6", parsimonySitesP: "0.393852065321806", constantSitesP: "0.456292026897214", distinctSitesP: "0.51969260326609", brLenTotal: "1.9768" },
  { geneTranscript: "NM_015050.3", parsimonySitesP: "0.149700598802395", constantSitesP: "0.748902195608782", distinctSitesP: "0.202794411177645", brLenTotal: "0.4908" },
  { geneTranscript: "NM_001184742.2", parsimonySitesP: "0.111111111111111", constantSitesP: "0.789186507936508", distinctSitesP: "0.16468253968254", brLenTotal: "0.3327" },
  { geneTranscript: "NM_178502.4", parsimonySitesP: "0.10566762728146", constantSitesP: "0.823246878001921", distinctSitesP: "0.155619596541787", brLenTotal: "0.3581" },
  { geneTranscript: "NM_000176.3", parsimonySitesP: "0.176319176319176", constantSitesP: "0.708708708708709", distinctSitesP: "0.237237237237237", brLenTotal: "0.5657" },
  { geneTranscript: "NM_005510.4", parsimonySitesP: "0.195286195286195", constantSitesP: "0.663299663299663", distinctSitesP: "0.292929292929293", brLenTotal: "0.6833" },
  { geneTranscript: "NM_194248.3", parsimonySitesP: "0.182774161241863", constantSitesP: "0.71206810215323", distinctSitesP: "0.217826740110165", brLenTotal: "0.8190" },
  { geneTranscript: "NM_020939.2", parsimonySitesP: "0.154019111860596", constantSitesP: "0.764474423833614", distinctSitesP: "0.209106239460371", brLenTotal: "0.7937" },
  { geneTranscript: "NM_004069.6", parsimonySitesP: "0.133802816901408", constantSitesP: "0.784037558685446", distinctSitesP: "0.232394366197183", brLenTotal: "0.5935" },
  { geneTranscript: "NM_005263.5", parsimonySitesP: "0.258293838862559", constantSitesP: "0.627962085308057", distinctSitesP: "0.315955766192733", brLenTotal: "0.9916" },
  { geneTranscript: "NM_019625.4", parsimonySitesP: "0.183637946040035", constantSitesP: "0.724978241949521", distinctSitesP: "0.233681462140992", brLenTotal: "0.8842" },
  { geneTranscript: "NM_006977.5", parsimonySitesP: "0.171647509578544", constantSitesP: "0.675095785440613", distinctSitesP: "0.247509578544061", brLenTotal: "0.5223" },
  { geneTranscript: "NM_001001664.3", parsimonySitesP: "0.0935374149659864", constantSitesP: "0.840986394557823", distinctSitesP: "0.141156462585034", brLenTotal: "0.2603" },
  { geneTranscript: "NM_001083909.3", parsimonySitesP: "0.289880952380952", constantSitesP: "0.576190476190476", distinctSitesP: "0.394047619047619", brLenTotal: "1.4536" },
  { geneTranscript: "NM_005401.5", parsimonySitesP: "0.191519236169615", constantSitesP: "0.711317045773659", distinctSitesP: "0.23869699522606", brLenTotal: "0.7806" },
  { geneTranscript: "NM_001278556.2", parsimonySitesP: "0.110486891385768", constantSitesP: "0.760299625468165", distinctSitesP: "0.265917602996255", brLenTotal: "0.4030" },
  { geneTranscript: "NM_031286.4", parsimonySitesP: "0.103942652329749", constantSitesP: "0.799283154121864", distinctSitesP: "0.211469534050179", brLenTotal: "0.4309" },
  { geneTranscript: "NM_003722.5", parsimonySitesP: "0.136764705882353", constantSitesP: "0.781372549019608", distinctSitesP: "0.188235294117647", brLenTotal: "0.5019" },
  { geneTranscript: "NM_020340.5", parsimonySitesP: "0.197825754095851", constantSitesP: "0.706017455213597", distinctSitesP: "0.229367631296892", brLenTotal: "0.8000" },
  { geneTranscript: "NM_001030005.3", parsimonySitesP: "0.124472573839662", constantSitesP: "0.765822784810127", distinctSitesP: "0.20253164556962", brLenTotal: "0.4852" },
  { geneTranscript: "NM_002779.5", parsimonySitesP: "0.164388020833333", constantSitesP: "0.684244791666667", distinctSitesP: "0.202799479166667", brLenTotal: "0.7813" },
  { geneTranscript: "NM_012117.3", parsimonySitesP: "0.106457242582897", constantSitesP: "0.827225130890052", distinctSitesP: "0.171029668411867", brLenTotal: "0.3058" },
  { geneTranscript: "NM_016399.3", parsimonySitesP: "0.162280701754386", constantSitesP: "0.719298245614035", distinctSitesP: "0.276315789473684", brLenTotal: "0.4336" },
  { geneTranscript: "NM_032780.4", parsimonySitesP: "0.179417122040073", constantSitesP: "0.690346083788707", distinctSitesP: "0.270491803278689", brLenTotal: "0.6387" },
  { geneTranscript: "NM_001923.5", parsimonySitesP: "0.132748538011696", constantSitesP: "0.782748538011696", distinctSitesP: "0.171052631578947", brLenTotal: "0.4969" },
  { geneTranscript: "NM_001355283.3", parsimonySitesP: "0.447457627118644", constantSitesP: "0.222598870056497", distinctSitesP: "0.701694915254237", brLenTotal: "2.2639" },
  { geneTranscript: "NM_002032.3", parsimonySitesP: "0.191256830601093", constantSitesP: "0.663023679417122", distinctSitesP: "0.326047358834244", brLenTotal: "0.8083" },
  { geneTranscript: "NM_017453.4", parsimonySitesP: "0.127094165222415", constantSitesP: "0.74581166955517", distinctSitesP: "0.186019641825534", brLenTotal: "0.5322" },
  { geneTranscript: "NM_014613.3", parsimonySitesP: "0.119850187265918", constantSitesP: "0.794007490636704", distinctSitesP: "0.190262172284644", brLenTotal: "0.3882" },
  { geneTranscript: "NM_032412.4", parsimonySitesP: "0.123711340206186", constantSitesP: "0.731958762886598", distinctSitesP: "0.254295532646048", brLenTotal: "0.4370" },
  { geneTranscript: "NM_001257359.2", parsimonySitesP: "0.18705035971223", constantSitesP: "0.710631494804157", distinctSitesP: "0.255795363709033", brLenTotal: "0.7782" },
  { geneTranscript: "NM_153182.4", parsimonySitesP: "0.23010752688172", constantSitesP: "0.607885304659498", distinctSitesP: "0.356272401433692", brLenTotal: "0.8542" },
  { geneTranscript: "NM_001006634.3", parsimonySitesP: "0.185017026106697", constantSitesP: "0.712447975785093", distinctSitesP: "0.266742338251986", brLenTotal: "0.7183" },
  { geneTranscript: "NM_001199563.2", parsimonySitesP: "0.157407407407407", constantSitesP: "0.712962962962963", distinctSitesP: "0.275925925925926", brLenTotal: "0.5824" },
  { geneTranscript: "NM_001823.5", parsimonySitesP: "0.232720909886264", constantSitesP: "0.671041119860017", distinctSitesP: "0.287839020122485", brLenTotal: "0.9445" },
  { geneTranscript: "NM_001270975.2", parsimonySitesP: "0.15391621129326", constantSitesP: "0.755009107468124", distinctSitesP: "0.239526411657559", brLenTotal: "0.5030" },
  { geneTranscript: "NM_017831.4", parsimonySitesP: "0.201149425287356", constantSitesP: "0.66235632183908", distinctSitesP: "0.320402298850575", brLenTotal: "0.6677" },
  { geneTranscript: "NM_018482.4", parsimonySitesP: "0.130498966637142", constantSitesP: "0.784174785946265", distinctSitesP: "0.175376439326838", brLenTotal: "0.4814" },
  { geneTranscript: "NM_003659.4", parsimonySitesP: "0.108915906788247", constantSitesP: "0.802431610942249", distinctSitesP: "0.174265450861196", brLenTotal: "0.3460" },
  { geneTranscript: "NM_000744.7", parsimonySitesP: "0.373205741626794", constantSitesP: "0.468367889420521", distinctSitesP: "0.508240297713982", brLenTotal: "2.2836" },
  { geneTranscript: "NM_001282659.2", parsimonySitesP: "0.103813038130381", constantSitesP: "0.816974169741697", distinctSitesP: "0.137761377613776", brLenTotal: "0.3203" },
  { geneTranscript: "NM_003112.5", parsimonySitesP: "0.0799319727891156", constantSitesP: "0.853741496598639", distinctSitesP: "0.121173469387755", brLenTotal: "0.2537" },
  { geneTranscript: "NM_003271.5", parsimonySitesP: "0.224089635854342", constantSitesP: "0.675070028011204", distinctSitesP: "0.291316526610644", brLenTotal: "1.1804" },
  { geneTranscript: "NM_015015.3", parsimonySitesP: "0.243004866180049", constantSitesP: "0.651155717761557", distinctSitesP: "0.322384428223844", brLenTotal: "1.3832" },
  { geneTranscript: "NM_020137.5", parsimonySitesP: "0.151010701545779", constantSitesP: "0.734046769718589", distinctSitesP: "0.212841854934602", brLenTotal: "0.5141" },
  { geneTranscript: "NM_173829.4", parsimonySitesP: "0.16989247311828", constantSitesP: "0.67741935483871", distinctSitesP: "0.337634408602151", brLenTotal: "0.6222" },
  { geneTranscript: "NM_018194.6", parsimonySitesP: "0.237998647734956", constantSitesP: "0.630831643002028", distinctSitesP: "0.354969574036511", brLenTotal: "0.9437" },
  { geneTranscript: "NM_006335.3", parsimonySitesP: "0.130604288499025", constantSitesP: "0.746588693957115", distinctSitesP: "0.251461988304094", brLenTotal: "0.4801" },
  { geneTranscript: "NM_001350451.2", parsimonySitesP: "0.115134633240483", constantSitesP: "0.837511606313835", distinctSitesP: "0.166202414113278", brLenTotal: "0.5205" },
  { geneTranscript: "NM_001290321.3", parsimonySitesP: "0.166119860017498", constantSitesP: "0.711942257217848", distinctSitesP: "0.192366579177603", brLenTotal: "0.5594" },
  { geneTranscript: "NM_014640.5", parsimonySitesP: "0.229635807617459", constantSitesP: "0.604948568251321", distinctSitesP: "0.322212955240478", brLenTotal: "0.7708" },
  { geneTranscript: "NM_001077525.3", parsimonySitesP: "0.154358974358974", constantSitesP: "0.736410256410256", distinctSitesP: "0.222564102564103", brLenTotal: "0.5192" },
  { geneTranscript: "NM_020399.4", parsimonySitesP: "0.151515151515152", constantSitesP: "0.746031746031746", distinctSitesP: "0.224386724386724", brLenTotal: "0.4829" },
  { geneTranscript: "NM_015379.5", parsimonySitesP: "0.189333333333333", constantSitesP: "0.714666666666667", distinctSitesP: "0.274666666666667", brLenTotal: "0.6121" },
  { geneTranscript: "NM_013276.4", parsimonySitesP: "0.269874476987448", constantSitesP: "0.582984658298466", distinctSitesP: "0.377266387726639", brLenTotal: "1.0804" },
  { geneTranscript: "NM_001399.5", parsimonySitesP: "0.142369991474851", constantSitesP: "0.749360613810742", distinctSitesP: "0.229326513213981", brLenTotal: "0.4644" },
  { geneTranscript: "NM_182616.4", parsimonySitesP: "0.312684365781711", constantSitesP: "0.553097345132743", distinctSitesP: "0.435103244837758", brLenTotal: "1.1708" },
  { geneTranscript: "NM_006390.4", parsimonySitesP: "0.145933783349405", constantSitesP: "0.767277402764384", distinctSitesP: "0.214079074252652", brLenTotal: "0.4653" },
  { geneTranscript: "NM_182548.4", parsimonySitesP: "0.140030441400304", constantSitesP: "0.777777777777778", distinctSitesP: "0.211567732115677", brLenTotal: "0.5549" },
  { geneTranscript: "NM_003540.4", parsimonySitesP: "0.307443365695793", constantSitesP: "0.666666666666667", distinctSitesP: "0.343042071197411", brLenTotal: "2.8588" },
  { geneTranscript: "NM_004884.4", parsimonySitesP: "0.232596232596233", constantSitesP: "0.632678132678133", distinctSitesP: "0.330876330876331", brLenTotal: "1.1477" },
  { geneTranscript: "NM_001080437.3", parsimonySitesP: "0.265628686010852", constantSitesP: "0.604387827317764", distinctSitesP: "0.348667138476056", brLenTotal: "1.3059" },
  { geneTranscript: "NM_018914.3", parsimonySitesP: "0.432085561497326", constantSitesP: "0.461675579322638", distinctSitesP: "0.510516934046346", brLenTotal: "3.5727" },
  { geneTranscript: "NM_152493.3", parsimonySitesP: "0.116666666666667", constantSitesP: "0.806349206349206", distinctSitesP: "0.175396825396825", brLenTotal: "0.4151" },
  { geneTranscript: "NM_006362.5", parsimonySitesP: "0.183629509962305", constantSitesP: "0.704361873990307", distinctSitesP: "0.24824986537426", brLenTotal: "0.6835" },
  { geneTranscript: "NM_004609.4", parsimonySitesP: "0.195979899497487", constantSitesP: "0.601340033500838", distinctSitesP: "0.314907872696817", brLenTotal: "0.9977" },
  { geneTranscript: "NM_201439.2", parsimonySitesP: "0.171662125340599", constantSitesP: "0.69936421435059", distinctSitesP: "0.277929155313351", brLenTotal: "0.5206" },
  { geneTranscript: "NM_006260.5", parsimonySitesP: "0.124338624338624", constantSitesP: "0.782407407407407", distinctSitesP: "0.19510582010582", brLenTotal: "0.4273" },
  { geneTranscript: "NM_001278716.2", parsimonySitesP: "0.140633387010199", constantSitesP: "0.731615673644659", distinctSitesP: "0.219538378958669", brLenTotal: "0.4679" },
  { geneTranscript: "NM_004418.4", parsimonySitesP: "0.266454352441614", constantSitesP: "0.578556263269639", distinctSitesP: "0.392781316348195", brLenTotal: "1.0974" },
  { geneTranscript: "NM_022073.4", parsimonySitesP: "0.115760111576011", constantSitesP: "0.790794979079498", distinctSitesP: "0.199442119944212", brLenTotal: "0.3848" },
  { geneTranscript: "NM_016464.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.713991769547325", distinctSitesP: "0.269547325102881", brLenTotal: "0.5048" },
  { geneTranscript: "NM_000433.4", parsimonySitesP: "0.224334600760456", constantSitesP: "0.635614702154626", distinctSitesP: "0.356147021546261", brLenTotal: "0.8149" },
  { geneTranscript: "NM_022169.5", parsimonySitesP: "0.134674922600619", constantSitesP: "0.765737874097007", distinctSitesP: "0.184210526315789", brLenTotal: "0.5116" },
  { geneTranscript: "NM_001005211.2", parsimonySitesP: "0.393842887473461", constantSitesP: "0.360934182590234", distinctSitesP: "0.582802547770701", brLenTotal: "1.8196" },
  { geneTranscript: "NM_000053.4", parsimonySitesP: "0.253242320819113", constantSitesP: "0.600910125142207", distinctSitesP: "0.335153583617747", brLenTotal: "0.9690" },
  { geneTranscript: "NM_001126128.2", parsimonySitesP: "0.204134366925065", constantSitesP: "0.677002583979328", distinctSitesP: "0.325581395348837", brLenTotal: "0.6375" },
  { geneTranscript: "NM_014234.5", parsimonySitesP: "0.196679438058748", constantSitesP: "0.683269476372925", distinctSitesP: "0.30779054916986", brLenTotal: "0.5964" },
  { geneTranscript: "NM_003680.4", parsimonySitesP: "0.169191919191919", constantSitesP: "0.720959595959596", distinctSitesP: "0.232954545454545", brLenTotal: "0.5642" },
  { geneTranscript: "NM_003945.4", parsimonySitesP: "0.1440329218107", constantSitesP: "0.707818930041152", distinctSitesP: "0.304526748971193", brLenTotal: "0.5542" },
  { geneTranscript: "NM_006088.6", parsimonySitesP: "0.30187265917603", constantSitesP: "0.585767790262172", distinctSitesP: "0.383520599250936", brLenTotal: "2.2171" },
  { geneTranscript: "NM_005452.6", parsimonySitesP: "0.233333333333333", constantSitesP: "0.618579234972678", distinctSitesP: "0.321857923497268", brLenTotal: "0.7753" },
  { geneTranscript: "NM_001144061.2", parsimonySitesP: "0.100734522560336", constantSitesP: "0.812521860790486", distinctSitesP: "0.137110877929346", brLenTotal: "0.3120" },
  { geneTranscript: "NM_198887.3", parsimonySitesP: "0.167543859649123", constantSitesP: "0.699122807017544", distinctSitesP: "0.264912280701754", brLenTotal: "0.5362" },
  { geneTranscript: "NM_002576.5", parsimonySitesP: "0.105810397553517", constantSitesP: "0.81039755351682", distinctSitesP: "0.149847094801223", brLenTotal: "0.3635" },
  { geneTranscript: "NM_001461.4", parsimonySitesP: "0.224515322076298", constantSitesP: "0.634146341463415", distinctSitesP: "0.357723577235772", brLenTotal: "0.7942" },
  { geneTranscript: "NM_016322.4", parsimonySitesP: "0.0604651162790698", constantSitesP: "0.871317829457364", distinctSitesP: "0.130232558139535", brLenTotal: "0.2473" },
  { geneTranscript: "NM_199191.3", parsimonySitesP: "0.108790252393386", constantSitesP: "0.798955613577023", distinctSitesP: "0.179286335944299", brLenTotal: "0.3746" },
  { geneTranscript: "NM_005918.4", parsimonySitesP: "0.216962524654832", constantSitesP: "0.633136094674556", distinctSitesP: "0.356015779092702", brLenTotal: "0.9436" },
  { geneTranscript: "NM_015423.3", parsimonySitesP: "0.120819848975189", constantSitesP: "0.774541531823085", distinctSitesP: "0.217907227615965", brLenTotal: "0.4032" },
  { geneTranscript: "NM_015268.4", parsimonySitesP: "0.117848120077277", constantSitesP: "0.782137018873532", distinctSitesP: "0.156041016495765", brLenTotal: "0.4296" },
  { geneTranscript: "NM_001005196.1", parsimonySitesP: "0.427184466019417", constantSitesP: "0.312837108953614", distinctSitesP: "0.609492988133765", brLenTotal: "1.9351" },
  { geneTranscript: "NM_001033723.3", parsimonySitesP: "0.138349514563107", constantSitesP: "0.756472491909385", distinctSitesP: "0.22411003236246", brLenTotal: "0.5157" },
  { geneTranscript: "NM_005319.4", parsimonySitesP: "0.419405320813772", constantSitesP: "0.497652582159624", distinctSitesP: "0.492957746478873", brLenTotal: "2.3667" },
  { geneTranscript: "NM_006312.6", parsimonySitesP: "0.227658446035534", constantSitesP: "0.652214266772739", distinctSitesP: "0.289180588703262", brLenTotal: "1.1944" },
  { geneTranscript: "NM_030795.4", parsimonySitesP: "0.158950617283951", constantSitesP: "0.74537037037037", distinctSitesP: "0.229938271604938", brLenTotal: "0.6460" },
  { geneTranscript: "NM_004712.5", parsimonySitesP: "0.221364221364221", constantSitesP: "0.682968682968683", distinctSitesP: "0.287001287001287", brLenTotal: "1.1208" },
  { geneTranscript: "NM_001173524.2", parsimonySitesP: "0.139405204460967", constantSitesP: "0.728004956629492", distinctSitesP: "0.228624535315985", brLenTotal: "0.5251" },
  { geneTranscript: "NM_001197104.2", parsimonySitesP: "0.121685129237999", constantSitesP: "0.776518966096005", distinctSitesP: "0.132930513595166", brLenTotal: "0.4102" },
  { geneTranscript: "NM_000312.4", parsimonySitesP: "0.281272595806218", constantSitesP: "0.588575560375994", distinctSitesP: "0.376717281272596", brLenTotal: "1.1179" },
  { geneTranscript: "NM_003881.4", parsimonySitesP: "0.357333333333333", constantSitesP: "0.484", distinctSitesP: "0.469333333333333", brLenTotal: "1.3475" },
  { geneTranscript: "NM_022911.3", parsimonySitesP: "0.273166447079491", constantSitesP: "0.602986385595081", distinctSitesP: "0.347826086956522", brLenTotal: "0.9889" },
  { geneTranscript: "NM_000912.5", parsimonySitesP: "0.225438596491228", constantSitesP: "0.681578947368421", distinctSitesP: "0.300877192982456", brLenTotal: "0.8611" },
  { geneTranscript: "NM_138481.2", parsimonySitesP: "0.273840769903762", constantSitesP: "0.549868766404199", distinctSitesP: "0.352143482064742", brLenTotal: "1.0224" },
  { geneTranscript: "NM_015054.2", parsimonySitesP: "0.188752276867031", constantSitesP: "0.682149362477231", distinctSitesP: "0.27299635701275", brLenTotal: "0.6020" },
  { geneTranscript: "NM_006112.4", parsimonySitesP: "0.152823920265781", constantSitesP: "0.709856035437431", distinctSitesP: "0.270210409745293", brLenTotal: "0.6478" },
  { geneTranscript: "NM_001034.4", parsimonySitesP: "0.200514138817481", constantSitesP: "0.672664952870608", distinctSitesP: "0.323907455012853", brLenTotal: "0.7725" },
  { geneTranscript: "NM_024102.4", parsimonySitesP: "0.194931773879142", constantSitesP: "0.671539961013645", distinctSitesP: "0.303118908382066", brLenTotal: "0.6464" },
  { geneTranscript: "NM_022076.4", parsimonySitesP: "0.440350877192982", constantSitesP: "0.464912280701754", distinctSitesP: "0.556140350877193", brLenTotal: "1.8941" },
  { geneTranscript: "NM_014606.3", parsimonySitesP: "0.164761904761905", constantSitesP: "0.747936507936508", distinctSitesP: "0.21047619047619", brLenTotal: "0.5790" },
  { geneTranscript: "NM_153812.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.723333333333333", distinctSitesP: "0.243333333333333", brLenTotal: "0.6907" },
  { geneTranscript: "NM_003654.6", parsimonySitesP: "0.152473641524736", constantSitesP: "0.75669099756691", distinctSitesP: "0.205190592051906", brLenTotal: "0.6268" },
  { geneTranscript: "NM_016520.3", parsimonySitesP: "0.15916955017301", constantSitesP: "0.734717416378316", distinctSitesP: "0.242214532871972", brLenTotal: "0.5541" },
  { geneTranscript: "NM_003052.5", parsimonySitesP: "0.271257172665623", constantSitesP: "0.613458528951487", distinctSitesP: "0.342201356285863", brLenTotal: "1.1749" },
  { geneTranscript: "NM_053023.5", parsimonySitesP: "0.123976608187135", constantSitesP: "0.787134502923977", distinctSitesP: "0.185964912280702", brLenTotal: "0.3735" },
  { geneTranscript: "NM_004838.4", parsimonySitesP: "0.209602954755309", constantSitesP: "0.688827331486611", distinctSitesP: "0.300092336103416", brLenTotal: "1.0997" },
  { geneTranscript: "NM_000829.4", parsimonySitesP: "0.139320029563932", constantSitesP: "0.775683665927568", distinctSitesP: "0.185513673318551", brLenTotal: "0.5794" },
  { geneTranscript: "NM_001394298.1", parsimonySitesP: "0.238993710691824", constantSitesP: "0.637316561844864", distinctSitesP: "0.364779874213836", brLenTotal: "0.7229" },
  { geneTranscript: "NM_017746.4", parsimonySitesP: "0.130247578040904", constantSitesP: "0.777538571941155", distinctSitesP: "0.185504126300682", brLenTotal: "0.3951" },
  { geneTranscript: "NM_001378457.1", parsimonySitesP: "0.15402223675605", constantSitesP: "0.736320034881186", distinctSitesP: "0.174187922389361", brLenTotal: "0.5750" },
  { geneTranscript: "NM_013267.4", parsimonySitesP: "0.156146179401993", constantSitesP: "0.740863787375415", distinctSitesP: "0.217607973421927", brLenTotal: "0.4934" },
  { geneTranscript: "NM_004112.4", parsimonySitesP: "0.108148148148148", constantSitesP: "0.783703703703704", distinctSitesP: "0.197037037037037", brLenTotal: "0.4010" },
  { geneTranscript: "NM_020402.4", parsimonySitesP: "0.213333333333333", constantSitesP: "0.662962962962963", distinctSitesP: "0.302962962962963", brLenTotal: "0.7955" },
  { geneTranscript: "NM_001288655.2", parsimonySitesP: "0.210678210678211", constantSitesP: "0.652236652236652", distinctSitesP: "0.316017316017316", brLenTotal: "0.9329" },
  { geneTranscript: "NM_000751.3", parsimonySitesP: "0.209542230818827", constantSitesP: "0.671179883945841", distinctSitesP: "0.289490651192779", brLenTotal: "0.9037" },
  { geneTranscript: "NM_138638.5", parsimonySitesP: "0.0281124497991968", constantSitesP: "0.921686746987952", distinctSitesP: "0.0823293172690763", brLenTotal: "0.1048" },
  { geneTranscript: "NM_002645.4", parsimonySitesP: "0.14650059311981", constantSitesP: "0.73566627125346", distinctSitesP: "0.194938710952946", brLenTotal: "0.4449" },
  { geneTranscript: "NM_019062.2", parsimonySitesP: "0.367107195301028", constantSitesP: "0.434654919236417", distinctSitesP: "0.544787077826725", brLenTotal: "1.4762" },
  { geneTranscript: "NM_006129.5", parsimonySitesP: "0.188979039891819", constantSitesP: "0.704868154158215", distinctSitesP: "0.236984448951995", brLenTotal: "0.9223" },
  { geneTranscript: "NM_020873.7", parsimonySitesP: "0.146648044692737", constantSitesP: "0.696461824953445", distinctSitesP: "0.208100558659218", brLenTotal: "0.6913" },
  { geneTranscript: "NM_153365.3", parsimonySitesP: "0.146972369194591", constantSitesP: "0.744855967078189", distinctSitesP: "0.238095238095238", brLenTotal: "0.4754" },
  { geneTranscript: "NM_007124.3", parsimonySitesP: "0.198368773667346", constantSitesP: "0.675987959996116", distinctSitesP: "0.259539761141858", brLenTotal: "0.7208" },
  { geneTranscript: "NM_001099695.2", parsimonySitesP: "0.208867521367521", constantSitesP: "0.671474358974359", distinctSitesP: "0.271901709401709", brLenTotal: "0.8258" },
  { geneTranscript: "NM_002746.3", parsimonySitesP: "0.143359718557608", constantSitesP: "0.766051011433597", distinctSitesP: "0.227792436235708", brLenTotal: "0.5242" },
  { geneTranscript: "NM_030648.4", parsimonySitesP: "0.151183970856102", constantSitesP: "0.757741347905282", distinctSitesP: "0.204918032786885", brLenTotal: "0.5723" },
  { geneTranscript: "NM_015032.4", parsimonySitesP: "0.114029025570145", constantSitesP: "0.795669200645013", distinctSitesP: "0.144667127390002", brLenTotal: "0.3952" },
  { geneTranscript: "NM_015246.4", parsimonySitesP: "0.216435185185185", constantSitesP: "0.673611111111111", distinctSitesP: "0.30150462962963", brLenTotal: "1.0886" },
  { geneTranscript: "NM_016429.4", parsimonySitesP: "0.13968253968254", constantSitesP: "0.736507936507937", distinctSitesP: "0.258730158730159", brLenTotal: "0.5377" },
  { geneTranscript: "NM_003614.2", parsimonySitesP: "0.347826086956522", constantSitesP: "0.547101449275362", distinctSitesP: "0.378623188405797", brLenTotal: "1.2896" },
  { geneTranscript: "NM_005424.5", parsimonySitesP: "0.205916813122437", constantSitesP: "0.675746924428822", distinctSitesP: "0.241066198008202", brLenTotal: "0.8011" },
  { geneTranscript: "NM_174895.3", parsimonySitesP: "0.254901960784314", constantSitesP: "0.583333333333333", distinctSitesP: "0.404411764705882", brLenTotal: "1.2353" },
  { geneTranscript: "NM_005472.5", parsimonySitesP: "0.242718446601942", constantSitesP: "0.653721682847896", distinctSitesP: "0.352750809061489", brLenTotal: "0.6771" },
  { geneTranscript: "NM_016023.5", parsimonySitesP: "0.171786120591581", constantSitesP: "0.706484641638225", distinctSitesP: "0.301478953356086", brLenTotal: "0.5253" },
  { geneTranscript: "NM_004522.3", parsimonySitesP: "0.13166144200627", constantSitesP: "0.785092302333682", distinctSitesP: "0.171368861024033", brLenTotal: "0.4827" },
  { geneTranscript: "NM_014634.4", parsimonySitesP: "0.292217327459618", constantSitesP: "0.562408223201175", distinctSitesP: "0.423641703377386", brLenTotal: "1.3065" },
  { geneTranscript: "NM_001436.4", parsimonySitesP: "0.173416407061267", constantSitesP: "0.723779854620976", distinctSitesP: "0.261682242990654", brLenTotal: "0.8427" },
  { geneTranscript: "NM_014368.5", parsimonySitesP: "0.103741496598639", constantSitesP: "0.817176870748299", distinctSitesP: "0.157312925170068", brLenTotal: "0.3501" },
  { geneTranscript: "NM_001004127.3", parsimonySitesP: "0.196476964769648", constantSitesP: "0.663279132791328", distinctSitesP: "0.284552845528455", brLenTotal: "0.6931" },
  { geneTranscript: "NM_001160329.2", parsimonySitesP: "0.155367231638418", constantSitesP: "0.757062146892655", distinctSitesP: "0.211299435028249", brLenTotal: "0.6539" },
  { geneTranscript: "NM_007031.2", parsimonySitesP: "0.216566866267465", constantSitesP: "0.658682634730539", distinctSitesP: "0.344311377245509", brLenTotal: "0.7590" },
  { geneTranscript: "NM_001079807.4", parsimonySitesP: "0.317010309278351", constantSitesP: "0.541237113402062", distinctSitesP: "0.428694158075601", brLenTotal: "1.4719" },
  { geneTranscript: "NM_006875.4", parsimonySitesP: "0.158628081457663", constantSitesP: "0.728831725616292", distinctSitesP: "0.231511254019293", brLenTotal: "0.4895" },
  { geneTranscript: "NM_017670.3", parsimonySitesP: "0.127921279212792", constantSitesP: "0.773677736777368", distinctSitesP: "0.19680196801968", brLenTotal: "0.5263" },
  { geneTranscript: "NM_014621.3", parsimonySitesP: "0.173856209150327", constantSitesP: "0.722875816993464", distinctSitesP: "0.257516339869281", brLenTotal: "0.6276" },
  { geneTranscript: "NM_012396.5", parsimonySitesP: "0.125984251968504", constantSitesP: "0.774278215223097", distinctSitesP: "0.230971128608924", brLenTotal: "0.4549" },
  { geneTranscript: "NM_001144758.3", parsimonySitesP: "0.158557250060518", constantSitesP: "0.717743887678528", distinctSitesP: "0.192447349310094", brLenTotal: "0.6104" },
  { geneTranscript: "NM_001394901.1", parsimonySitesP: "0.16750418760469", constantSitesP: "0.748743718592965", distinctSitesP: "0.246231155778894", brLenTotal: "0.7057" },
  { geneTranscript: "NM_001004708.1", parsimonySitesP: "0.466029723991507", constantSitesP: "0.378980891719745", distinctSitesP: "0.605095541401274", brLenTotal: "1.9189" },
  { geneTranscript: "NM_001001964.2", parsimonySitesP: "0.579113924050633", constantSitesP: "0.246835443037975", distinctSitesP: "0.759493670886076", brLenTotal: "3.4048" },
  { geneTranscript: "NM_033179.2", parsimonySitesP: "0.566666666666667", constantSitesP: "0.309677419354839", distinctSitesP: "0.683870967741935", brLenTotal: "3.1854" },
  { geneTranscript: "NM_001002248.3", parsimonySitesP: "0.170634920634921", constantSitesP: "0.702380952380952", distinctSitesP: "0.28968253968254", brLenTotal: "0.7855" },
  { geneTranscript: "NM_001004454.2", parsimonySitesP: "0.580366774541532", constantSitesP: "0.271844660194175", distinctSitesP: "0.722761596548004", brLenTotal: "2.7966" },
  { geneTranscript: "NM_206918.3", parsimonySitesP: "0.219814241486068", constantSitesP: "0.670794633642931", distinctSitesP: "0.293085655314757", brLenTotal: "1.1856" },
  { geneTranscript: "NM_004711.5", parsimonySitesP: "0.155937052932761", constantSitesP: "0.753934191702432", distinctSitesP: "0.227467811158798", brLenTotal: "0.6577" },
  { geneTranscript: "NM_001204.7", parsimonySitesP: "0.111432241490045", constantSitesP: "0.78965960179833", distinctSitesP: "0.149967886962107", brLenTotal: "0.3310" },
  { geneTranscript: "NM_005114.4", parsimonySitesP: "0.206297502714441", constantSitesP: "0.655808903365907", distinctSitesP: "0.286644951140065", brLenTotal: "0.7880" },
  { geneTranscript: "NM_001197293.3", parsimonySitesP: "0.146233382570162", constantSitesP: "0.752831117676022", distinctSitesP: "0.207779419005416", brLenTotal: "0.7124" },
  { geneTranscript: "NM_004950.5", parsimonySitesP: "0.20703933747412", constantSitesP: "0.653209109730849", distinctSitesP: "0.306418219461698", brLenTotal: "0.7031" },
  { geneTranscript: "NM_001142699.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.803760683760684", distinctSitesP: "0.163418803418803", brLenTotal: "0.3646" },
  { geneTranscript: "NM_001145127.2", parsimonySitesP: "0.20265780730897", constantSitesP: "0.637873754152824", distinctSitesP: "0.325581395348837", brLenTotal: "0.8138" },
  { geneTranscript: "NM_006399.5", parsimonySitesP: "0.162666666666667", constantSitesP: "0.749333333333333", distinctSitesP: "0.237333333333333", brLenTotal: "0.7760" },
  { geneTranscript: "NM_017633.3", parsimonySitesP: "0.138763197586727", constantSitesP: "0.744343891402715", distinctSitesP: "0.201357466063348", brLenTotal: "0.5899" },
  { geneTranscript: "NM_005268.4", parsimonySitesP: "0.349206349206349", constantSitesP: "0.492063492063492", distinctSitesP: "0.459096459096459", brLenTotal: "1.5868" },
  { geneTranscript: "NM_032569.4", parsimonySitesP: "0.103074141048825", constantSitesP: "0.833634719710669", distinctSitesP: "0.148282097649186", brLenTotal: "0.3842" },
  { geneTranscript: "NM_199355.4", parsimonySitesP: "0.218946218946219", constantSitesP: "0.66038766038766", distinctSitesP: "0.289380289380289", brLenTotal: "0.8385" },
  { geneTranscript: "NM_079834.4", parsimonySitesP: "0.292576419213974", constantSitesP: "0.631732168850073", distinctSitesP: "0.369723435225619", brLenTotal: "1.5466" },
  { geneTranscript: "NM_005901.6", parsimonySitesP: "0.101356174161313", constantSitesP: "0.78372591006424", distinctSitesP: "0.154889364739472", brLenTotal: "0.4747" },
  { geneTranscript: "NM_005322.3", parsimonySitesP: "0.334808259587021", constantSitesP: "0.551622418879056", distinctSitesP: "0.452802359882006", brLenTotal: "1.7610" },
  { geneTranscript: "NM_014592.4", parsimonySitesP: "0.117283950617284", constantSitesP: "0.811728395061728", distinctSitesP: "0.189814814814815", brLenTotal: "0.4743" },
  { geneTranscript: "NM_013382.7", parsimonySitesP: "0.186666666666667", constantSitesP: "0.698222222222222", distinctSitesP: "0.251111111111111", brLenTotal: "0.6666" },
  { geneTranscript: "NM_005610.3", parsimonySitesP: "0.135686274509804", constantSitesP: "0.72", distinctSitesP: "0.253333333333333", brLenTotal: "0.5655" },
  { geneTranscript: "NM_006309.4", parsimonySitesP: "0.140545538603791", constantSitesP: "0.715210355987055", distinctSitesP: "0.250115580212668", brLenTotal: "0.4952" },
  { geneTranscript: "NM_001025096.2", parsimonySitesP: "0.190666666666667", constantSitesP: "0.678666666666667", distinctSitesP: "0.3", brLenTotal: "0.6658" },
  { geneTranscript: "NM_022915.5", parsimonySitesP: "0.254016064257028", constantSitesP: "0.60140562248996", distinctSitesP: "0.3785140562249", brLenTotal: "0.8252" },
  { geneTranscript: "NM_020414.4", parsimonySitesP: "0.246022506790842", constantSitesP: "0.590997283663174", distinctSitesP: "0.331005044625534", brLenTotal: "0.8865" },
  { geneTranscript: "NM_005735.4", parsimonySitesP: "0.168439716312057", constantSitesP: "0.750886524822695", distinctSitesP: "0.221631205673759", brLenTotal: "0.7938" },
  { geneTranscript: "NM_020726.5", parsimonySitesP: "0.15625", constantSitesP: "0.715909090909091", distinctSitesP: "0.232481060606061", brLenTotal: "0.5787" },
  { geneTranscript: "NM_005628.3", parsimonySitesP: "0.282809611829945", constantSitesP: "0.574245224892175", distinctSitesP: "0.396796056685151", brLenTotal: "1.2126" },
  { geneTranscript: "NM_002813.7", parsimonySitesP: "0.275037369207773", constantSitesP: "0.573991031390135", distinctSitesP: "0.417040358744395", brLenTotal: "0.9396" },
  { geneTranscript: "NM_001007237.3", parsimonySitesP: "0.191233947515355", constantSitesP: "0.697096594081519", distinctSitesP: "0.234226689000558", brLenTotal: "0.8692" },
  { geneTranscript: "NM_033515.3", parsimonySitesP: "0.193564605329311", constantSitesP: "0.664655605832076", distinctSitesP: "0.313725490196078", brLenTotal: "0.6834" },
  { geneTranscript: "NM_031844.3", parsimonySitesP: "0.138181818181818", constantSitesP: "0.785454545454545", distinctSitesP: "0.192727272727273", brLenTotal: "0.4508" },
  { geneTranscript: "NM_001128918.3", parsimonySitesP: "0.134130146082337", constantSitesP: "0.761398849048251", distinctSitesP: "0.204072598494909", brLenTotal: "0.5141" },
  { geneTranscript: "NM_025128.5", parsimonySitesP: "0.252873563218391", constantSitesP: "0.574107683000605", distinctSitesP: "0.370235934664247", brLenTotal: "0.9454" },
  { geneTranscript: "NM_004380.3", parsimonySitesP: "0.161206661206661", constantSitesP: "0.739284739284739", distinctSitesP: "0.2001092001092", brLenTotal: "0.6880" },
  { geneTranscript: "NM_014223.5", parsimonySitesP: "0.0955223880597015", constantSitesP: "0.787064676616915", distinctSitesP: "0.186069651741294", brLenTotal: "0.3844" },
  { geneTranscript: "NM_198081.5", parsimonySitesP: "0.293075684380032", constantSitesP: "0.571658615136876", distinctSitesP: "0.403381642512077", brLenTotal: "1.1443" },
  { geneTranscript: "NM_138423.4", parsimonySitesP: "0.147553516819572", constantSitesP: "0.72782874617737", distinctSitesP: "0.25611620795107", brLenTotal: "0.5003" },
  { geneTranscript: "NM_152295.5", parsimonySitesP: "0.173351775011526", constantSitesP: "0.708160442600277", distinctSitesP: "0.260949746426925", brLenTotal: "0.6045" },
  { geneTranscript: "NM_212481.3", parsimonySitesP: "0.274971941638608", constantSitesP: "0.577441077441077", distinctSitesP: "0.365319865319865", brLenTotal: "1.1150" },
  { geneTranscript: "NM_006292.4", parsimonySitesP: "0.107692307692308", constantSitesP: "0.808547008547009", distinctSitesP: "0.179487179487179", brLenTotal: "0.3166" },
  { geneTranscript: "NM_004725.4", parsimonySitesP: "0.133130081300813", constantSitesP: "0.736788617886179", distinctSitesP: "0.23780487804878", brLenTotal: "0.4842" },
  { geneTranscript: "NM_000143.4", parsimonySitesP: "0.2", constantSitesP: "0.631372549019608", distinctSitesP: "0.318954248366013", brLenTotal: "0.7860" },
  { geneTranscript: "NM_152356.4", parsimonySitesP: "0.535469107551487", constantSitesP: "0.331045003813883", distinctSitesP: "0.659801678108314", brLenTotal: "3.4269" },
  { geneTranscript: "NM_003712.4", parsimonySitesP: "0.260416666666667", constantSitesP: "0.578703703703704", distinctSitesP: "0.384259259259259", brLenTotal: "1.1653" },
  { geneTranscript: "NM_173854.6", parsimonySitesP: "0.116309291747888", constantSitesP: "0.797920727745289", distinctSitesP: "0.167641325536062", brLenTotal: "0.4247" },
  { geneTranscript: "NM_015161.3", parsimonySitesP: "0.146141215106732", constantSitesP: "0.719211822660099", distinctSitesP: "0.274220032840722", brLenTotal: "0.4994" },
  { geneTranscript: "NM_001395430.1", parsimonySitesP: "0.18208516886931", constantSitesP: "0.701419481155164", distinctSitesP: "0.241801272638277", brLenTotal: "0.7247" },
  { geneTranscript: "NM_031892.3", parsimonySitesP: "0.125814536340852", constantSitesP: "0.765413533834586", distinctSitesP: "0.19749373433584", brLenTotal: "0.5015" },
  { geneTranscript: "NM_001256455.2", parsimonySitesP: "0.183673469387755", constantSitesP: "0.711193568336425", distinctSitesP: "0.242424242424242", brLenTotal: "0.7317" },
  { geneTranscript: "NM_001029884.3", parsimonySitesP: "0.280385078219013", constantSitesP: "0.580986762936221", distinctSitesP: "0.357400722021661", brLenTotal: "1.1467" },
  { geneTranscript: "NM_205853.4", parsimonySitesP: "0.178861788617886", constantSitesP: "0.703252032520325", distinctSitesP: "0.345528455284553", brLenTotal: "0.7266" },
  { geneTranscript: "NM_020861.3", parsimonySitesP: "0.157587548638132", constantSitesP: "0.75875486381323", distinctSitesP: "0.199740596627756", brLenTotal: "0.7309" },
  { geneTranscript: "NM_003503.4", parsimonySitesP: "0.168408826945412", constantSitesP: "0.685249709639954", distinctSitesP: "0.259581881533101", brLenTotal: "0.5680" },
  { geneTranscript: "NM_005633.4", parsimonySitesP: "0.101775443860965", constantSitesP: "0.801700425106277", distinctSitesP: "0.138784696174044", brLenTotal: "0.3621" },
  { geneTranscript: "NM_153816.6", parsimonySitesP: "0.126849894291755", constantSitesP: "0.78400281888654", distinctSitesP: "0.18569415081043", brLenTotal: "0.3855" },
  { geneTranscript: "NM_001100915.3", parsimonySitesP: "0.222822174226062", constantSitesP: "0.643988480921526", distinctSitesP: "0.277897768178546", brLenTotal: "0.7034" },
  { geneTranscript: "NM_004313.4", parsimonySitesP: "0.136919315403423", constantSitesP: "0.770171149144254", distinctSitesP: "0.198859005704971", brLenTotal: "0.4814" },
  { geneTranscript: "NM_005055.5", parsimonySitesP: "0.139967637540453", constantSitesP: "0.756472491909385", distinctSitesP: "0.204692556634304", brLenTotal: "0.6706" },
  { geneTranscript: "NM_015044.4", parsimonySitesP: "0.183251767264818", constantSitesP: "0.687873844480696", distinctSitesP: "0.270799347471452", brLenTotal: "0.6685" },
  { geneTranscript: "NM_013292.5", parsimonySitesP: "0.165680473372781", constantSitesP: "0.759368836291913", distinctSitesP: "0.230769230769231", brLenTotal: "0.6044" },
  { geneTranscript: "NM_001370299.1", parsimonySitesP: "0.174968071519796", constantSitesP: "0.643039591315453", distinctSitesP: "0.289910600255428", brLenTotal: "0.7011" },
  { geneTranscript: "NM_172219.3", parsimonySitesP: "0.292483660130719", constantSitesP: "0.53921568627451", distinctSitesP: "0.431372549019608", brLenTotal: "1.1808" },
  { geneTranscript: "NM_001235.5", parsimonySitesP: "0.207336523125997", constantSitesP: "0.698564593301435", distinctSitesP: "0.264752791068581", brLenTotal: "0.8788" },
  { geneTranscript: "NM_001008223.2", parsimonySitesP: "0.249299719887955", constantSitesP: "0.659663865546218", distinctSitesP: "0.299719887955182", brLenTotal: "1.0251" },
  { geneTranscript: "NM_016306.6", parsimonySitesP: "0.135940409683426", constantSitesP: "0.754189944134078", distinctSitesP: "0.210428305400372", brLenTotal: "0.4423" },
  { geneTranscript: "NM_153706.4", parsimonySitesP: "0.169453734671126", constantSitesP: "0.675585284280936", distinctSitesP: "0.27536231884058", brLenTotal: "0.5695" },
  { geneTranscript: "NM_001003795.3", parsimonySitesP: "0.268001404987706", constantSitesP: "0.617492096944152", distinctSitesP: "0.34703196347032", brLenTotal: "1.1158" },
  { geneTranscript: "NM_014633.5", parsimonySitesP: "0.13356067064507", constantSitesP: "0.776072747939756", distinctSitesP: "0.183290707587383", brLenTotal: "0.4520" },
  { geneTranscript: "NM_016265.4", parsimonySitesP: "0.221425155428025", constantSitesP: "0.445241511238642", distinctSitesP: "0.343854615016738", brLenTotal: "1.2336" },
  { geneTranscript: "NM_001394232.1", parsimonySitesP: "0.217391304347826", constantSitesP: "0.58695652173913", distinctSitesP: "0.427536231884058", brLenTotal: "0.8832" },
  { geneTranscript: "NM_213606.4", parsimonySitesP: "0.218346253229974", constantSitesP: "0.649870801033592", distinctSitesP: "0.304263565891473", brLenTotal: "0.7531" },
  { geneTranscript: "NM_003792.4", parsimonySitesP: "0.171171171171171", constantSitesP: "0.720720720720721", distinctSitesP: "0.29954954954955", brLenTotal: "0.6671" },
  { geneTranscript: "NM_001387222.1", parsimonySitesP: "0.558922558922559", constantSitesP: "0.202020202020202", distinctSitesP: "0.831649831649832", brLenTotal: "3.8891" },
  { geneTranscript: "NM_178160.3", parsimonySitesP: "0.273428232502966", constantSitesP: "0.590747330960854", distinctSitesP: "0.372479240806643", brLenTotal: "1.0084" },
  { geneTranscript: "NM_016256.4", parsimonySitesP: "0.279611650485437", constantSitesP: "0.575404530744337", distinctSitesP: "0.392880258899676", brLenTotal: "1.1580" },
  { geneTranscript: "NM_001098797.2", parsimonySitesP: "0.162714097496706", constantSitesP: "0.738471673254282", distinctSitesP: "0.221343873517787", brLenTotal: "0.7888" },
  { geneTranscript: "NM_020309.4", parsimonySitesP: "0.156547619047619", constantSitesP: "0.775595238095238", distinctSitesP: "0.198214285714286", brLenTotal: "0.5004" },
  { geneTranscript: "NM_004169.5", parsimonySitesP: "0.209109730848861", constantSitesP: "0.640441683919945", distinctSitesP: "0.318150448585231", brLenTotal: "0.8345" },
  { geneTranscript: "NM_052989.3", parsimonySitesP: "0.193123824872415", constantSitesP: "0.694063926940639", distinctSitesP: "0.265914585012087", brLenTotal: "0.6925" },
  { geneTranscript: "NM_080546.5", parsimonySitesP: "0.102993404363267", constantSitesP: "0.823947234906139", distinctSitesP: "0.147640791476408", brLenTotal: "0.3374" },
  { geneTranscript: "NM_000819.5", parsimonySitesP: "0.194059405940594", constantSitesP: "0.675577557755776", distinctSitesP: "0.261716171617162", brLenTotal: "0.6092" },
  { geneTranscript: "NM_017588.3", parsimonySitesP: "0.18063872255489", constantSitesP: "0.740518962075848", distinctSitesP: "0.283433133732535", brLenTotal: "0.9962" },
  { geneTranscript: "NM_014719.3", parsimonySitesP: "0.272167933405718", constantSitesP: "0.600072385088672", distinctSitesP: "0.307998552298227", brLenTotal: "1.0890" },
  { geneTranscript: "NM_014764.4", parsimonySitesP: "0.125", constantSitesP: "0.753968253968254", distinctSitesP: "0.226190476190476", brLenTotal: "0.4198" },
  { geneTranscript: "NM_020640.4", parsimonySitesP: "0.0759330759330759", constantSitesP: "0.804375804375804", distinctSitesP: "0.186615186615187", brLenTotal: "0.3619" },
  { geneTranscript: "NM_012301.4", parsimonySitesP: "0.12233676975945", constantSitesP: "0.791523482245132", distinctSitesP: "0.192668957617411", brLenTotal: "0.4388" },
  { geneTranscript: "NM_017964.5", parsimonySitesP: "0.123644251626898", constantSitesP: "0.770788141720897", distinctSitesP: "0.205350686912509", brLenTotal: "0.4132" },
  { geneTranscript: "NM_032349.4", parsimonySitesP: "0.143759873617694", constantSitesP: "0.77567140600316", distinctSitesP: "0.216429699842022", brLenTotal: "0.5238" },
  { geneTranscript: "NM_017747.3", parsimonySitesP: "0.117886178861789", constantSitesP: "0.787044322056124", distinctSitesP: "0.142276422764228", brLenTotal: "0.3659" },
  { geneTranscript: "NM_022494.3", parsimonySitesP: "0.138821630347054", constantSitesP: "0.712671509281679", distinctSitesP: "0.255851493139629", brLenTotal: "0.4841" },
  { geneTranscript: "NM_001011551.3", parsimonySitesP: "0.168763102725367", constantSitesP: "0.60482180293501", distinctSitesP: "0.258909853249476", brLenTotal: "0.8040" },
  { geneTranscript: "NM_004227.4", parsimonySitesP: "0.178780284043442", constantSitesP: "0.748538011695906", distinctSitesP: "0.242272347535505", brLenTotal: "0.7747" },
  { geneTranscript: "NM_032385.5", parsimonySitesP: "0.22022022022022", constantSitesP: "0.620620620620621", distinctSitesP: "0.344344344344344", brLenTotal: "0.8331" },
  { geneTranscript: "NM_001757.4", parsimonySitesP: "0.293622141997593", constantSitesP: "0.531889290012034", distinctSitesP: "0.425992779783394", brLenTotal: "1.3717" },
  { geneTranscript: "NM_001271977.2", parsimonySitesP: "0.200312989045383", constantSitesP: "0.710485133020344", distinctSitesP: "0.268388106416275", brLenTotal: "1.0360" },
  { geneTranscript: "NM_006015.6", parsimonySitesP: "0.0974471188913202", constantSitesP: "0.825674690007294", distinctSitesP: "0.122684172137126", brLenTotal: "0.3283" },
  { geneTranscript: "NM_002241.5", parsimonySitesP: "0.135444151275286", constantSitesP: "0.767810026385224", distinctSitesP: "0.201407211961302", brLenTotal: "0.5180" },
  { geneTranscript: "NM_178815.5", parsimonySitesP: "0.130353817504655", constantSitesP: "0.787709497206704", distinctSitesP: "0.212290502793296", brLenTotal: "0.4467" },
  { geneTranscript: "NM_004530.6", parsimonySitesP: "0.181313131313131", constantSitesP: "0.727777777777778", distinctSitesP: "0.235858585858586", brLenTotal: "0.7319" },
  { geneTranscript: "NM_001017928.4", parsimonySitesP: "0.0833333333333333", constantSitesP: "0.784722222222222", distinctSitesP: "0.203703703703704", brLenTotal: "0.3386" },
  { geneTranscript: "NM_173550.4", parsimonySitesP: "0.145550527903469", constantSitesP: "0.715183509301156", distinctSitesP: "0.219959778783308", brLenTotal: "0.4976" },
  { geneTranscript: "NM_030651.4", parsimonySitesP: "0.147058823529412", constantSitesP: "0.733115468409586", distinctSitesP: "0.259259259259259", brLenTotal: "0.6486" },
  { geneTranscript: "NM_017926.4", parsimonySitesP: "0.156984785615491", constantSitesP: "0.726832641770401", distinctSitesP: "0.239280774550484", brLenTotal: "0.4918" },
  { geneTranscript: "NM_000170.3", parsimonySitesP: "0.188888888888889", constantSitesP: "0.694771241830065", distinctSitesP: "0.269607843137255", brLenTotal: "0.7171" },
  { geneTranscript: "NM_152468.5", parsimonySitesP: "0.282828282828283", constantSitesP: "0.562901744719927", distinctSitesP: "0.40633608815427", brLenTotal: "1.0724" },
  { geneTranscript: "NM_052998.4", parsimonySitesP: "0.193478260869565", constantSitesP: "0.62536231884058", distinctSitesP: "0.302898550724638", brLenTotal: "0.8113" },
  { geneTranscript: "NM_032204.5", parsimonySitesP: "0.194627917217085", constantSitesP: "0.681638044914135", distinctSitesP: "0.276089828269485", brLenTotal: "0.7896" },
  { geneTranscript: "NM_002791.3", parsimonySitesP: "0.0894308943089431", constantSitesP: "0.75609756097561", distinctSitesP: "0.220867208672087", brLenTotal: "0.3534" },
  { geneTranscript: "NM_024626.4", parsimonySitesP: "0.184397163120567", constantSitesP: "0.670212765957447", distinctSitesP: "0.314420803782506", brLenTotal: "0.6213" },
  { geneTranscript: "NM_012154.5", parsimonySitesP: "0.159487776484284", constantSitesP: "0.759022118742724", distinctSitesP: "0.203337213814513", brLenTotal: "0.9207" },
  { geneTranscript: "NM_022436.3", parsimonySitesP: "0.267793138760881", constantSitesP: "0.594982078853047", distinctSitesP: "0.391705069124424", brLenTotal: "0.9262" },
  { geneTranscript: "NM_006247.4", parsimonySitesP: "0.140948563794255", constantSitesP: "0.769539078156313", distinctSitesP: "0.219104876419506", brLenTotal: "0.6023" },
  { geneTranscript: "NM_005963.4", parsimonySitesP: "0.213855939487708", constantSitesP: "0.695891352931064", distinctSitesP: "0.256145779611484", brLenTotal: "1.5469" },
  { geneTranscript: "NM_139125.4", parsimonySitesP: "0.199175824175824", constantSitesP: "0.696886446886447", distinctSitesP: "0.272435897435897", brLenTotal: "0.7966" },
  { geneTranscript: "NM_001316979.2", parsimonySitesP: "0.297455968688845", constantSitesP: "0.578604044357469", distinctSitesP: "0.355512067840835", brLenTotal: "1.4060" },
  { geneTranscript: "NM_080822.3", parsimonySitesP: "0.303964757709251", constantSitesP: "0.531571218795888", distinctSitesP: "0.439060205580029", brLenTotal: "1.0702" },
  { geneTranscript: "NM_003883.4", parsimonySitesP: "0.112928348909657", constantSitesP: "0.806853582554517", distinctSitesP: "0.165109034267913", brLenTotal: "0.3745" },
  { geneTranscript: "NM_003631.5", parsimonySitesP: "0.163251366120219", constantSitesP: "0.694330601092896", distinctSitesP: "0.242144808743169", brLenTotal: "0.5634" },
  { geneTranscript: "NM_054027.6", parsimonySitesP: "0.15650406504065", constantSitesP: "0.78929539295393", distinctSitesP: "0.206639566395664", brLenTotal: "0.7099" },
  { geneTranscript: "NM_001330145.2", parsimonySitesP: "0.0714285714285714", constantSitesP: "0.856547619047619", distinctSitesP: "0.126190476190476", brLenTotal: "0.2152" },
  { geneTranscript: "NM_022748.12", parsimonySitesP: "0.247289504036909", constantSitesP: "0.623298731257209", distinctSitesP: "0.315570934256055", brLenTotal: "1.1761" },
  { geneTranscript: "NM_018184.3", parsimonySitesP: "0.0806451612903226", constantSitesP: "0.835125448028674", distinctSitesP: "0.170250896057348", brLenTotal: "0.2658" },
  { geneTranscript: "NM_001282857.2", parsimonySitesP: "0.142857142857143", constantSitesP: "0.747540338449429", distinctSitesP: "0.212908303817395", brLenTotal: "0.4678" },
  { geneTranscript: "NM_019108.4", parsimonySitesP: "0.148076923076923", constantSitesP: "0.757692307692308", distinctSitesP: "0.206410256410256", brLenTotal: "0.4978" },
  { geneTranscript: "NM_001031.5", parsimonySitesP: "0.236714975845411", constantSitesP: "0.642512077294686", distinctSitesP: "0.357487922705314", brLenTotal: "0.7660" },
  { geneTranscript: "NM_016329.4", parsimonySitesP: "0.138953040800616", constantSitesP: "0.742879137798306", distinctSitesP: "0.203618167821401", brLenTotal: "0.5059" },
  { geneTranscript: "NM_001384910.1", parsimonySitesP: "0.174129353233831", constantSitesP: "0.734660033167496", distinctSitesP: "0.271973466003317", brLenTotal: "0.8018" },
  { geneTranscript: "NM_012104.6", parsimonySitesP: "0.111111111111111", constantSitesP: "0.800399201596806", distinctSitesP: "0.169660678642715", brLenTotal: "0.3975" },
  { geneTranscript: "NM_014547.5", parsimonySitesP: "0.171401515151515", constantSitesP: "0.712121212121212", distinctSitesP: "0.269886363636364", brLenTotal: "0.5690" },
  { geneTranscript: "NM_020672.3", parsimonySitesP: "0.185897435897436", constantSitesP: "0.695512820512821", distinctSitesP: "0.317307692307692", brLenTotal: "0.6304" },
  { geneTranscript: "NM_000154.2", parsimonySitesP: "0.23469387755102", constantSitesP: "0.660714285714286", distinctSitesP: "0.318877551020408", brLenTotal: "0.9301" },
  { geneTranscript: "NM_001040100.2", parsimonySitesP: "0.201754385964912", constantSitesP: "0.701754385964912", distinctSitesP: "0.285087719298246", brLenTotal: "0.5908" },
  { geneTranscript: "NM_178310.4", parsimonySitesP: "0.404109589041096", constantSitesP: "0.461187214611872", distinctSitesP: "0.512557077625571", brLenTotal: "1.8344" },
  { geneTranscript: "NM_004673.4", parsimonySitesP: "0.169721656483367", constantSitesP: "0.730482009504413", distinctSitesP: "0.249830278343517", brLenTotal: "0.5410" },
  { geneTranscript: "NM_002486.5", parsimonySitesP: "0.1042194092827", constantSitesP: "0.824050632911392", distinctSitesP: "0.141772151898734", brLenTotal: "0.3151" },
  { geneTranscript: "NM_004627.6", parsimonySitesP: "0.157088122605364", constantSitesP: "0.722222222222222", distinctSitesP: "0.296934865900383", brLenTotal: "0.6484" },
  { geneTranscript: "NM_001284230.2", parsimonySitesP: "0.156099033816425", constantSitesP: "0.708635265700483", distinctSitesP: "0.213768115942029", brLenTotal: "0.7595" },
  { geneTranscript: "NM_022455.5", parsimonySitesP: "0.173219584569733", constantSitesP: "0.689540059347181", distinctSitesP: "0.207467853610287", brLenTotal: "0.5922" },
  { geneTranscript: "NM_031300.4", parsimonySitesP: "0.27831715210356", constantSitesP: "0.55663430420712", distinctSitesP: "0.40453074433657", brLenTotal: "1.1490" },
  { geneTranscript: "NM_000789.4", parsimonySitesP: "0.254977029096478", constantSitesP: "0.603624298111281", distinctSitesP: "0.324910668708525", brLenTotal: "1.0871" },
  { geneTranscript: "NM_001379150.1", parsimonySitesP: "0.248938428874735", constantSitesP: "0.598195329087049", distinctSitesP: "0.312101910828025", brLenTotal: "0.7702" },
  { geneTranscript: "NM_138621.5", parsimonySitesP: "0.143097643097643", constantSitesP: "0.759259259259259", distinctSitesP: "0.225589225589226", brLenTotal: "0.4251" },
  { geneTranscript: "NM_001080392.2", parsimonySitesP: "0.149450549450549", constantSitesP: "0.755311355311355", distinctSitesP: "0.251282051282051", brLenTotal: "0.5490" },
  { geneTranscript: "NM_001348255.2", parsimonySitesP: "0.277777777777778", constantSitesP: "0.555555555555556", distinctSitesP: "0.482905982905983", brLenTotal: "1.3202" },
  { geneTranscript: "NM_153603.4", parsimonySitesP: "0.197402597402597", constantSitesP: "0.691341991341991", distinctSitesP: "0.267099567099567", brLenTotal: "0.7661" },
  { geneTranscript: "NM_178026.3", parsimonySitesP: "0.153071500503525", constantSitesP: "0.744712990936556", distinctSitesP: "0.204934541792548", brLenTotal: "0.6664" },
  { geneTranscript: "NM_138927.4", parsimonySitesP: "0.151552624347348", constantSitesP: "0.741000274800769", distinctSitesP: "0.227122835943941", brLenTotal: "0.5351" },
  { geneTranscript: "NM_001448.3", parsimonySitesP: "0.229616306954436", constantSitesP: "0.669664268585132", distinctSitesP: "0.26378896882494", brLenTotal: "0.7038" },
  { geneTranscript: "NM_001378074.1", parsimonySitesP: "0.255306427503737", constantSitesP: "0.614648729446936", distinctSitesP: "0.356352765321375", brLenTotal: "1.0243" },
  { geneTranscript: "NM_001303052.2", parsimonySitesP: "0.199550309162451", constantSitesP: "0.721472737492974", distinctSitesP: "0.264193367060146", brLenTotal: "1.1004" },
  { geneTranscript: "NM_006108.4", parsimonySitesP: "0.14787277984304", constantSitesP: "0.765386204047914", distinctSitesP: "0.198265179677819", brLenTotal: "0.5061" },
  { geneTranscript: "NM_001931.5", parsimonySitesP: "0.178773827923751", constantSitesP: "0.669242658423493", distinctSitesP: "0.269963936115404", brLenTotal: "0.6091" },
  { geneTranscript: "NM_032947.5", parsimonySitesP: "0.205555555555556", constantSitesP: "0.688888888888889", distinctSitesP: "0.333333333333333", brLenTotal: "0.6723" },
  { geneTranscript: "NM_018975.4", parsimonySitesP: "0.192982456140351", constantSitesP: "0.666666666666667", distinctSitesP: "0.294903926482874", brLenTotal: "0.6498" },
  { geneTranscript: "NM_080874.4", parsimonySitesP: "0.214792299898683", constantSitesP: "0.688956433637285", distinctSitesP: "0.28774062816616", brLenTotal: "0.7360" },
  { geneTranscript: "NM_000168.6", parsimonySitesP: "0.218565400843882", constantSitesP: "0.643459915611814", distinctSitesP: "0.262025316455696", brLenTotal: "0.9170" },
  { geneTranscript: "NM_003312.6", parsimonySitesP: "0.206509539842873", constantSitesP: "0.673400673400673", distinctSitesP: "0.292929292929293", brLenTotal: "0.7489" },
  { geneTranscript: "NM_005161.6", parsimonySitesP: "0.167543859649123", constantSitesP: "0.728070175438596", distinctSitesP: "0.237719298245614", brLenTotal: "0.7211" },
  { geneTranscript: "NM_021204.5", parsimonySitesP: "0.333333333333333", constantSitesP: "0.471264367816092", distinctSitesP: "0.492975734355045", brLenTotal: "1.4902" },
  { geneTranscript: "NM_016113.5", parsimonySitesP: "0.253490401396161", constantSitesP: "0.599912739965096", distinctSitesP: "0.354712041884817", brLenTotal: "0.9958" },
  { geneTranscript: "NM_175852.4", parsimonySitesP: "0.186813186813187", constantSitesP: "0.684981684981685", distinctSitesP: "0.278998778998779", brLenTotal: "0.6697" },
  { geneTranscript: "NM_001283.5", parsimonySitesP: "0.130801687763713", constantSitesP: "0.780590717299578", distinctSitesP: "0.210970464135021", brLenTotal: "0.5539" },
  { geneTranscript: "NM_002653.5", parsimonySitesP: "0.184713375796178", constantSitesP: "0.686836518046709", distinctSitesP: "0.256900212314225", brLenTotal: "0.7015" },
  { geneTranscript: "NM_178516.4", parsimonySitesP: "0.285969615728329", constantSitesP: "0.567470956210903", distinctSitesP: "0.349419124218052", brLenTotal: "1.0065" },
  { geneTranscript: "NM_022843.4", parsimonySitesP: "0.19453207150368", constantSitesP: "0.673676831405538", distinctSitesP: "0.260778128286015", brLenTotal: "0.6628" },
  { geneTranscript: "NM_005648.4", parsimonySitesP: "0.157738095238095", constantSitesP: "0.699404761904762", distinctSitesP: "0.294642857142857", brLenTotal: "0.5308" },
  { geneTranscript: "NM_005033.3", parsimonySitesP: "0.118451025056948", constantSitesP: "0.75626423690205", distinctSitesP: "0.239179954441913", brLenTotal: "0.4109" },
  { geneTranscript: "NM_015695.3", parsimonySitesP: "0.145504840940526", constantSitesP: "0.739972337482711", distinctSitesP: "0.180082987551867", brLenTotal: "0.5011" },
  { geneTranscript: "NM_002275.4", parsimonySitesP: "0.259502923976608", constantSitesP: "0.589912280701754", distinctSitesP: "0.404239766081871", brLenTotal: "1.2953" },
  { geneTranscript: "NM_134323.2", parsimonySitesP: "0.13752276867031", constantSitesP: "0.768670309653916", distinctSitesP: "0.193989071038251", brLenTotal: "0.4731" },
  { geneTranscript: "NM_001199198.3", parsimonySitesP: "0.123032904148784", constantSitesP: "0.772055317119695", distinctSitesP: "0.195517405817835", brLenTotal: "0.3892" },
  { geneTranscript: "NM_004287.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.677672955974843", distinctSitesP: "0.284591194968553", brLenTotal: "0.6253" },
  { geneTranscript: "NM_020387.4", parsimonySitesP: "0.167449139280125", constantSitesP: "0.672926447574335", distinctSitesP: "0.283255086071987", brLenTotal: "0.7046" },
  { geneTranscript: "NM_002293.4", parsimonySitesP: "0.208825357364823", constantSitesP: "0.682825771700849", distinctSitesP: "0.254816656308266", brLenTotal: "0.7485" },
  { geneTranscript: "NM_005285.5", parsimonySitesP: "0.228658536585366", constantSitesP: "0.648373983739837", distinctSitesP: "0.334349593495935", brLenTotal: "0.9175" },
  { geneTranscript: "NM_080656.3", parsimonySitesP: "0.137931034482759", constantSitesP: "0.669540229885057", distinctSitesP: "0.313218390804598", brLenTotal: "0.5293" },
  { geneTranscript: "NM_006018.3", parsimonySitesP: "0.279931093884582", constantSitesP: "0.530577088716624", distinctSitesP: "0.424633936261843", brLenTotal: "1.1958" },
  { geneTranscript: "NM_001370592.1", parsimonySitesP: "0.142642642642643", constantSitesP: "0.762762762762763", distinctSitesP: "0.211711711711712", brLenTotal: "0.4946" },
  { geneTranscript: "NM_001127.4", parsimonySitesP: "0.191078328064629", constantSitesP: "0.691956445381103", distinctSitesP: "0.246926589392343", brLenTotal: "0.8620" },
  { geneTranscript: "NM_005873.3", parsimonySitesP: "0.238095238095238", constantSitesP: "0.642089093701997", distinctSitesP: "0.342549923195084", brLenTotal: "1.1281" },
  { geneTranscript: "NM_001142459.2", parsimonySitesP: "0.236973590292648", constantSitesP: "0.618129907209136", distinctSitesP: "0.322626695217702", brLenTotal: "0.9722" },
  { geneTranscript: "NM_006357.4", parsimonySitesP: "0.162640901771337", constantSitesP: "0.676328502415459", distinctSitesP: "0.2914653784219", brLenTotal: "0.6235" },
  { geneTranscript: "NM_080670.4", parsimonySitesP: "0.211934156378601", constantSitesP: "0.643004115226337", distinctSitesP: "0.300411522633745", brLenTotal: "0.8018" },
  { geneTranscript: "NM_175065.3", parsimonySitesP: "0.294871794871795", constantSitesP: "0.648717948717949", distinctSitesP: "0.346153846153846", brLenTotal: "1.6949" },
  { geneTranscript: "NM_001958.5", parsimonySitesP: "0.222462203023758", constantSitesP: "0.716342692584593", distinctSitesP: "0.254859611231102", brLenTotal: "1.3702" },
  { geneTranscript: "NM_031266.3", parsimonySitesP: "0.155622489959839", constantSitesP: "0.684738955823293", distinctSitesP: "0.255020080321285", brLenTotal: "0.7172" },
  { geneTranscript: "NM_032826.5", parsimonySitesP: "0.156092648539778", constantSitesP: "0.743202416918429", distinctSitesP: "0.252769385699899", brLenTotal: "0.6008" },
  { geneTranscript: "NM_138440.3", parsimonySitesP: "0.303615651312531", constantSitesP: "0.557701832590391", distinctSitesP: "0.353145121347202", brLenTotal: "1.4273" },
  { geneTranscript: "NM_006353.3", parsimonySitesP: "0.248148148148148", constantSitesP: "0.592592592592593", distinctSitesP: "0.381481481481481", brLenTotal: "0.8002" },
  { geneTranscript: "NM_015496.5", parsimonySitesP: "0.105592347314202", constantSitesP: "0.810706401766004", distinctSitesP: "0.131346578366446", brLenTotal: "0.3305" },
  { geneTranscript: "NM_006843.3", parsimonySitesP: "0.251016260162602", constantSitesP: "0.614837398373984", distinctSitesP: "0.350609756097561", brLenTotal: "1.1743" },
  { geneTranscript: "NM_016337.3", parsimonySitesP: "0.131578947368421", constantSitesP: "0.757575757575758", distinctSitesP: "0.236842105263158", brLenTotal: "0.5454" },
  { geneTranscript: "NM_014233.4", parsimonySitesP: "0.128708551483421", constantSitesP: "0.803228621291449", distinctSitesP: "0.162303664921466", brLenTotal: "0.5241" },
  { geneTranscript: "NM_002569.4", parsimonySitesP: "0.186817800167926", constantSitesP: "0.710747271200672", distinctSitesP: "0.237615449202351", brLenTotal: "0.7944" },
  { geneTranscript: "NM_080832.3", parsimonySitesP: "0.141361256544503", constantSitesP: "0.755671902268761", distinctSitesP: "0.193717277486911", brLenTotal: "0.4157" },
  { geneTranscript: "NM_016233.2", parsimonySitesP: "0.235943775100402", constantSitesP: "0.643072289156627", distinctSitesP: "0.32429718875502", brLenTotal: "1.1730" },
  { geneTranscript: "NM_001354768.3", parsimonySitesP: "0.178621659634318", constantSitesP: "0.654008438818565", distinctSitesP: "0.305203938115331", brLenTotal: "0.6978" },
  { geneTranscript: "NM_001128633.2", parsimonySitesP: "0.387838112670327", constantSitesP: "0.392719137685581", distinctSitesP: "0.512914378686191", brLenTotal: "1.4354" },
  { geneTranscript: "NM_001444.3", parsimonySitesP: "0.175308641975309", constantSitesP: "0.676543209876543", distinctSitesP: "0.333333333333333", brLenTotal: "0.6214" },
  { geneTranscript: "NM_003558.4", parsimonySitesP: "0.202469135802469", constantSitesP: "0.696296296296296", distinctSitesP: "0.278395061728395", brLenTotal: "0.8281" },
  { geneTranscript: "NM_001042750.2", parsimonySitesP: "0.0914826498422713", constantSitesP: "0.838065194532072", distinctSitesP: "0.1198738170347", brLenTotal: "0.3408" },
  { geneTranscript: "NM_203406.2", parsimonySitesP: "0.138590203106332", constantSitesP: "0.77299880525687", distinctSitesP: "0.210274790919952", brLenTotal: "0.4935" },
  { geneTranscript: "NM_024410.4", parsimonySitesP: "0.186666666666667", constantSitesP: "0.716", distinctSitesP: "0.285333333333333", brLenTotal: "0.9029" },
  { geneTranscript: "NM_005370.5", parsimonySitesP: "0.13365539452496", constantSitesP: "0.785829307568438", distinctSitesP: "0.21256038647343", brLenTotal: "0.5526" },
  { geneTranscript: "NM_032803.6", parsimonySitesP: "0.20032310177706", constantSitesP: "0.665051157781368", distinctSitesP: "0.308023694130318", brLenTotal: "0.6656" },
  { geneTranscript: "NM_004706.4", parsimonySitesP: "0.193713450292398", constantSitesP: "0.682383040935673", distinctSitesP: "0.258406432748538", brLenTotal: "0.8307" },
  { geneTranscript: "NM_138610.3", parsimonySitesP: "0.138888888888889", constantSitesP: "0.770609318996416", distinctSitesP: "0.205197132616487", brLenTotal: "0.6747" },
  { geneTranscript: "NM_002861.5", parsimonySitesP: "0.222793487574979", constantSitesP: "0.674378748928877", distinctSitesP: "0.329048843187661", brLenTotal: "1.0577" },
  { geneTranscript: "NM_001391956.1", parsimonySitesP: "0.192003167062549", constantSitesP: "0.664291369754553", distinctSitesP: "0.22901821060966", brLenTotal: "0.5627" },
  { geneTranscript: "NM_001378600.1", parsimonySitesP: "0.3760092272203", constantSitesP: "0.420991926182238", distinctSitesP: "0.550173010380623", brLenTotal: "1.3420" },
  { geneTranscript: "NM_152296.5", parsimonySitesP: "0.197104310628496", constantSitesP: "0.736097400460678", distinctSitesP: "0.226061204343534", brLenTotal: "1.1534" },
  { geneTranscript: "NM_005909.5", parsimonySitesP: "0.193544030253917", constantSitesP: "0.676391139924365", distinctSitesP: "0.224473257698541", brLenTotal: "0.7770" },
  { geneTranscript: "NM_001318100.2", parsimonySitesP: "0.18435475834579", constantSitesP: "0.668161434977578", distinctSitesP: "0.263577478824116", brLenTotal: "0.8467" },
  { geneTranscript: "NM_002143.3", parsimonySitesP: "0.160621761658031", constantSitesP: "0.744386873920553", distinctSitesP: "0.226252158894646", brLenTotal: "0.5540" },
  { geneTranscript: "NM_006734.4", parsimonySitesP: "0.190651403652221", constantSitesP: "0.692150449713818", distinctSitesP: "0.209048787135459", brLenTotal: "0.7086" },
  { geneTranscript: "NM_153712.5", parsimonySitesP: "0.157382847038019", constantSitesP: "0.760389036251105", distinctSitesP: "0.229000884173298", brLenTotal: "0.5095" },
  { geneTranscript: "NM_024320.4", parsimonySitesP: "0.281553398058252", constantSitesP: "0.621359223300971", distinctSitesP: "0.420711974110032", brLenTotal: "0.9624" },
  { geneTranscript: "NM_004691.5", parsimonySitesP: "0.124406457739791", constantSitesP: "0.793922127255461", distinctSitesP: "0.176638176638177", brLenTotal: "0.4678" },
  { geneTranscript: "NM_014263.4", parsimonySitesP: "0.138268156424581", constantSitesP: "0.750465549348231", distinctSitesP: "0.20903165735568", brLenTotal: "0.4570" },
  { geneTranscript: "NM_002223.4", parsimonySitesP: "0.155127730470196", constantSitesP: "0.753424657534247", distinctSitesP: "0.182278168579538", brLenTotal: "0.6405" },
  { geneTranscript: "NM_153270.3", parsimonySitesP: "0.288819875776398", constantSitesP: "0.579710144927536", distinctSitesP: "0.333333333333333", brLenTotal: "1.1391" },
  { geneTranscript: "NM_002900.3", parsimonySitesP: "0.255012028869286", constantSitesP: "0.596364608393478", distinctSitesP: "0.294573643410853", brLenTotal: "1.1804" },
  { geneTranscript: "NM_001039029.3", parsimonySitesP: "0.202702702702703", constantSitesP: "0.681081081081081", distinctSitesP: "0.272972972972973", brLenTotal: "0.8118" },
  { geneTranscript: "NM_005877.6", parsimonySitesP: "0.16015132408575", constantSitesP: "0.759142496847415", distinctSitesP: "0.208911307271963", brLenTotal: "0.5955" },
  { geneTranscript: "NM_001103.4", parsimonySitesP: "0.177852348993289", constantSitesP: "0.73489932885906", distinctSitesP: "0.22110365398956", brLenTotal: "0.8716" },
  { geneTranscript: "NM_001005240.3", parsimonySitesP: "0.569398907103825", constantSitesP: "0.302732240437158", distinctSitesP: "0.693989071038251", brLenTotal: "3.3615" },
  { geneTranscript: "NM_001291415.2", parsimonySitesP: "0.0738701537049782", constantSitesP: "0.822895159440239", distinctSitesP: "0.10782289515944", brLenTotal: "0.3100" },
  { geneTranscript: "NM_019114.5", parsimonySitesP: "0.136296296296296", constantSitesP: "0.754074074074074", distinctSitesP: "0.225925925925926", brLenTotal: "0.5200" },
  { geneTranscript: "NM_006386.5", parsimonySitesP: "0.103337905807042", constantSitesP: "0.79652491998171", distinctSitesP: "0.171010516689529", brLenTotal: "0.3859" },
  { geneTranscript: "NM_001145161.3", parsimonySitesP: "0.0848861283643892", constantSitesP: "0.857142857142857", distinctSitesP: "0.144927536231884", brLenTotal: "0.2763" },
  { geneTranscript: "NM_058219.3", parsimonySitesP: "0.268382352941176", constantSitesP: "0.621323529411765", distinctSitesP: "0.363970588235294", brLenTotal: "1.0718" },
  { geneTranscript: "NM_032832.6", parsimonySitesP: "0.252", constantSitesP: "0.585333333333333", distinctSitesP: "0.422", brLenTotal: "0.9985" },
  { geneTranscript: "NM_005169.4", parsimonySitesP: "0.172535211267606", constantSitesP: "0.726525821596244", distinctSitesP: "0.254694835680751", brLenTotal: "0.7741" },
  { geneTranscript: "NM_001362985.3", parsimonySitesP: "0.262359275575135", constantSitesP: "0.60009789525208", distinctSitesP: "0.323054331864905", brLenTotal: "1.0943" },
  { geneTranscript: "NM_024509.2", parsimonySitesP: "0.191613588110403", constantSitesP: "0.670912951167728", distinctSitesP: "0.247876857749469", brLenTotal: "1.3445" },
  { geneTranscript: "NM_025078.5", parsimonySitesP: "0.238622386223862", constantSitesP: "0.661746617466175", distinctSitesP: "0.317343173431734", brLenTotal: "1.2018" },
  { geneTranscript: "NM_014187.4", parsimonySitesP: "0.134874759152216", constantSitesP: "0.763005780346821", distinctSitesP: "0.208092485549133", brLenTotal: "0.4379" },
  { geneTranscript: "NM_080876.4", parsimonySitesP: "0.138248847926267", constantSitesP: "0.725038402457757", distinctSitesP: "0.288786482334869", brLenTotal: "0.4701" },
  { geneTranscript: "NM_003153.5", parsimonySitesP: "0.194018103109012", constantSitesP: "0.679260133805588", distinctSitesP: "0.246753246753247", brLenTotal: "0.6072" },
  { geneTranscript: "NM_014652.4", parsimonySitesP: "0.109034267912773", constantSitesP: "0.811353409484251", distinctSitesP: "0.141571478020076", brLenTotal: "0.3495" },
  { geneTranscript: "NM_004964.3", parsimonySitesP: "0.134163208852006", constantSitesP: "0.704011065006916", distinctSitesP: "0.253803596127248", brLenTotal: "0.6418" },
  { geneTranscript: "NM_015559.3", parsimonySitesP: "0.151002506265664", constantSitesP: "0.735797827903091", distinctSitesP: "0.184210526315789", brLenTotal: "0.6278" },
  { geneTranscript: "NM_001009552.2", parsimonySitesP: "0.208198489751888", constantSitesP: "0.710895361380798", distinctSitesP: "0.259978425026969", brLenTotal: "0.7721" },
  { geneTranscript: "NM_001242532.5", parsimonySitesP: "0.149220489977728", constantSitesP: "0.765404602821084", distinctSitesP: "0.219005196733482", brLenTotal: "0.5012" },
  { geneTranscript: "NM_017679.5", parsimonySitesP: "0.110259218692954", constantSitesP: "0.79043446513326", distinctSitesP: "0.164293537787514", brLenTotal: "0.4104" },
  { geneTranscript: "NM_001389320.1", parsimonySitesP: "0.33125", constantSitesP: "0.35625", distinctSitesP: "0.602083333333333", brLenTotal: "1.7152" },
  { geneTranscript: "NM_005275.5", parsimonySitesP: "0.13948380010983", constantSitesP: "0.764415156507414", distinctSitesP: "0.176825919824272", brLenTotal: "0.4996" },
  { geneTranscript: "NM_020437.5", parsimonySitesP: "0.200542005420054", constantSitesP: "0.686540198735321", distinctSitesP: "0.282746160794941", brLenTotal: "0.8333" },
  { geneTranscript: "NM_153266.4", parsimonySitesP: "0.183048433048433", constantSitesP: "0.690883190883191", distinctSitesP: "0.240740740740741", brLenTotal: "0.9301" },
  { geneTranscript: "NM_017957.3", parsimonySitesP: "0.229957805907173", constantSitesP: "0.604430379746835", distinctSitesP: "0.35126582278481", brLenTotal: "0.9486" },
  { geneTranscript: "NM_016605.3", parsimonySitesP: "0.0901360544217687", constantSitesP: "0.82312925170068", distinctSitesP: "0.150510204081633", brLenTotal: "0.3307" },
  { geneTranscript: "NM_033055.3", parsimonySitesP: "0.129931972789116", constantSitesP: "0.758503401360544", distinctSitesP: "0.195918367346939", brLenTotal: "0.6088" },
  { geneTranscript: "NM_003182.3", parsimonySitesP: "0.0878552971576227", constantSitesP: "0.801033591731266", distinctSitesP: "0.227390180878553", brLenTotal: "0.3612" },
  { geneTranscript: "NM_014921.5", parsimonySitesP: "0.167007034263671", constantSitesP: "0.752666212843204", distinctSitesP: "0.190832766054005", brLenTotal: "0.8209" },
  { geneTranscript: "NM_001105247.2", parsimonySitesP: "0.24777183600713", constantSitesP: "0.638859180035651", distinctSitesP: "0.308021390374332", brLenTotal: "0.9880" },
  { geneTranscript: "NM_003070.5", parsimonySitesP: "0.167505241090147", constantSitesP: "0.754507337526205", distinctSitesP: "0.211740041928721", brLenTotal: "0.6537" },
  { geneTranscript: "NM_133455.4", parsimonySitesP: "0.219714070729872", constantSitesP: "0.656884875846501", distinctSitesP: "0.335590669676448", brLenTotal: "0.8403" },
  { geneTranscript: "NM_019855.5", parsimonySitesP: "0.173410404624277", constantSitesP: "0.720616570327553", distinctSitesP: "0.271676300578035", brLenTotal: "0.7387" },
  { geneTranscript: "NM_007005.6", parsimonySitesP: "0.145752479517033", constantSitesP: "0.786114704614058", distinctSitesP: "0.19404915912031", brLenTotal: "1.0310" },
  { geneTranscript: "NM_016577.4", parsimonySitesP: "0.118589743589744", constantSitesP: "0.801282051282051", distinctSitesP: "0.190705128205128", brLenTotal: "0.7477" },
  { geneTranscript: "NM_001025.5", parsimonySitesP: "0.160839160839161", constantSitesP: "0.638694638694639", distinctSitesP: "0.337995337995338", brLenTotal: "0.7891" },
  { geneTranscript: "NM_001001557.4", parsimonySitesP: "0.183150183150183", constantSitesP: "0.685714285714286", distinctSitesP: "0.271062271062271", brLenTotal: "0.6404" },
  { geneTranscript: "NM_153361.4", parsimonySitesP: "0.203363914373089", constantSitesP: "0.67737003058104", distinctSitesP: "0.275993883792049", brLenTotal: "0.7252" },
  { geneTranscript: "NM_003289.4", parsimonySitesP: "0.144366197183099", constantSitesP: "0.772300469483568", distinctSitesP: "0.205399061032864", brLenTotal: "0.5123" },
  { geneTranscript: "NM_207361.6", parsimonySitesP: "0.24129588724098", constantSitesP: "0.625223519511939", distinctSitesP: "0.300725781003471", brLenTotal: "0.8854" },
  { geneTranscript: "NM_001330195.2", parsimonySitesP: "0.146827922766815", constantSitesP: "0.752174835561214", distinctSitesP: "0.185656694249947", brLenTotal: "0.6121" },
  { geneTranscript: "NM_197964.5", parsimonySitesP: "0.179941002949853", constantSitesP: "0.693215339233038", distinctSitesP: "0.303834808259587", brLenTotal: "0.5252" },
  { geneTranscript: "NM_001134647.2", parsimonySitesP: "0.234643734643735", constantSitesP: "0.65028665028665", distinctSitesP: "0.309172809172809", brLenTotal: "1.0890" },
  { geneTranscript: "NM_014845.6", parsimonySitesP: "0.156560088202867", constantSitesP: "0.741639103270856", distinctSitesP: "0.224917309812569", brLenTotal: "0.5412" },
  { geneTranscript: "NM_001099294.2", parsimonySitesP: "0.190954773869347", constantSitesP: "0.67001675041876", distinctSitesP: "0.33500837520938", brLenTotal: "0.9229" },
  { geneTranscript: "NM_005853.6", parsimonySitesP: "0.184265010351967", constantSitesP: "0.692201518288475", distinctSitesP: "0.294685990338164", brLenTotal: "0.8710" },
  { geneTranscript: "NM_138444.4", parsimonySitesP: "0.158974358974359", constantSitesP: "0.715897435897436", distinctSitesP: "0.228717948717949", brLenTotal: "0.6264" },
  { geneTranscript: "NM_002305.4", parsimonySitesP: "0.195061728395062", constantSitesP: "0.666666666666667", distinctSitesP: "0.333333333333333", brLenTotal: "0.7313" },
  { geneTranscript: "NM_138459.5", parsimonySitesP: "0.154721274175199", constantSitesP: "0.62457337883959", distinctSitesP: "0.29806598407281", brLenTotal: "0.6237" },
  { geneTranscript: "NM_021959.3", parsimonySitesP: "0.119047619047619", constantSitesP: "0.764550264550265", distinctSitesP: "0.246031746031746", brLenTotal: "0.4306" },
  { geneTranscript: "NM_025000.4", parsimonySitesP: "0.135897435897436", constantSitesP: "0.738461538461538", distinctSitesP: "0.228846153846154", brLenTotal: "0.4309" },
  { geneTranscript: "NM_005245.4", parsimonySitesP: "0.285672769543737", constantSitesP: "0.586820691659401", distinctSitesP: "0.351278698052892", brLenTotal: "1.2332" },
  { geneTranscript: "NM_173076.3", parsimonySitesP: "0.189723827874117", constantSitesP: "0.683879254977521", distinctSitesP: "0.262684649967887", brLenTotal: "0.6756" },
  { geneTranscript: "NM_001258429.2", parsimonySitesP: "0.187739463601533", constantSitesP: "0.701149425287356", distinctSitesP: "0.289272030651341", brLenTotal: "0.7364" },
  { geneTranscript: "NM_001025200.4", parsimonySitesP: "0.278833967046895", constantSitesP: "0.550063371356147", distinctSitesP: "0.446134347275032", brLenTotal: "1.6398" },
  { geneTranscript: "NM_004434.3", parsimonySitesP: "0.170961145194274", constantSitesP: "0.735787321063395", distinctSitesP: "0.244171779141104", brLenTotal: "0.7977" },
  { geneTranscript: "NM_152447.5", parsimonySitesP: "0.145108947612425", constantSitesP: "0.765414928140936", distinctSitesP: "0.194251274918869", brLenTotal: "0.4448" },
  { geneTranscript: "NM_032609.3", parsimonySitesP: "0.274853801169591", constantSitesP: "0.582846003898635", distinctSitesP: "0.421052631578947", brLenTotal: "0.8982" },
  { geneTranscript: "NM_139057.4", parsimonySitesP: "0.201522070015221", constantSitesP: "0.689193302891933", distinctSitesP: "0.27427701674277", brLenTotal: "0.8912" },
  { geneTranscript: "NM_001385028.1", parsimonySitesP: "0.190350877192982", constantSitesP: "0.683625730994152", distinctSitesP: "0.23859649122807", brLenTotal: "0.9137" },
  { geneTranscript: "NM_144617.3", parsimonySitesP: "0.197916666666667", constantSitesP: "0.685416666666667", distinctSitesP: "0.302083333333333", brLenTotal: "0.7796" },
  { geneTranscript: "NM_181836.6", parsimonySitesP: "0.125", constantSitesP: "0.763392857142857", distinctSitesP: "0.21875", brLenTotal: "0.4488" },
  { geneTranscript: "NM_022453.3", parsimonySitesP: "0.195352214960058", constantSitesP: "0.64560639070443", distinctSitesP: "0.291938997821351", brLenTotal: "0.6669" },
  { geneTranscript: "NM_001916.5", parsimonySitesP: "0.224615384615385", constantSitesP: "0.642051282051282", distinctSitesP: "0.32", brLenTotal: "0.7604" },
  { geneTranscript: "NM_003541.3", parsimonySitesP: "0.29126213592233", constantSitesP: "0.682847896440129", distinctSitesP: "0.330097087378641", brLenTotal: "2.7096" },
  { geneTranscript: "NM_013375.4", parsimonySitesP: "0.28921568627451", constantSitesP: "0.544117647058824", distinctSitesP: "0.427696078431373", brLenTotal: "1.0857" },
  { geneTranscript: "NM_152516.4", parsimonySitesP: "0.194736842105263", constantSitesP: "0.656140350877193", distinctSitesP: "0.336842105263158", brLenTotal: "0.6807" },
  { geneTranscript: "NM_001301043.2", parsimonySitesP: "0.0997876857749469", constantSitesP: "0.826610049539986", distinctSitesP: "0.167728237791932", brLenTotal: "0.3760" },
  { geneTranscript: "NM_001365631.1", parsimonySitesP: "0.112439132359451", constantSitesP: "0.787516600265604", distinctSitesP: "0.150509074811864", brLenTotal: "0.3863" },
  { geneTranscript: "NM_002611.5", parsimonySitesP: "0.137592137592138", constantSitesP: "0.79033579033579", distinctSitesP: "0.186732186732187", brLenTotal: "0.6262" },
  { geneTranscript: "NM_032839.3", parsimonySitesP: "0.145048814504881", constantSitesP: "0.763598326359833", distinctSitesP: "0.211994421199442", brLenTotal: "0.4475" },
  { geneTranscript: "NM_001430.5", parsimonySitesP: "0.185823754789272", constantSitesP: "0.690804597701149", distinctSitesP: "0.266283524904215", brLenTotal: "0.7678" },
  { geneTranscript: "NM_152862.3", parsimonySitesP: "0.0811111111111111", constantSitesP: "0.828888888888889", distinctSitesP: "0.166666666666667", brLenTotal: "0.3104" },
  { geneTranscript: "NM_020905.4", parsimonySitesP: "0.216269841269841", constantSitesP: "0.636904761904762", distinctSitesP: "0.322420634920635", brLenTotal: "0.7282" },
  { geneTranscript: "NM_181534.4", parsimonySitesP: "0.248148148148148", constantSitesP: "0.641481481481481", distinctSitesP: "0.339259259259259", brLenTotal: "1.2211" },
  { geneTranscript: "NM_021008.4", parsimonySitesP: "0.221238938053097", constantSitesP: "0.691445427728614", distinctSitesP: "0.317994100294985", brLenTotal: "1.0544" },
  { geneTranscript: "NM_173473.4", parsimonySitesP: "0.124242424242424", constantSitesP: "0.73030303030303", distinctSitesP: "0.248484848484848", brLenTotal: "0.4777" },
  { geneTranscript: "NM_001417.7", parsimonySitesP: "0.157119476268412", constantSitesP: "0.651936715766503", distinctSitesP: "0.285870158210584", brLenTotal: "0.6941" },
  { geneTranscript: "NM_138453.4", parsimonySitesP: "0.127753303964758", constantSitesP: "0.759177679882526", distinctSitesP: "0.20704845814978", brLenTotal: "0.4960" },
  { geneTranscript: "NM_005749.4", parsimonySitesP: "0.0753623188405797", constantSitesP: "0.761352657004831", distinctSitesP: "0.167149758454106", brLenTotal: "0.5225" },
  { geneTranscript: "NM_002701.6", parsimonySitesP: "0.268518518518519", constantSitesP: "0.47962962962963", distinctSitesP: "0.442592592592593", brLenTotal: "1.2108" },
  { geneTranscript: "NM_023015.5", parsimonySitesP: "0.136276391554702", constantSitesP: "0.785668586052463", distinctSitesP: "0.163787587971849", brLenTotal: "0.4487" },
  { geneTranscript: "NM_001079537.2", parsimonySitesP: "0.0864978902953586", constantSitesP: "0.784810126582278", distinctSitesP: "0.183544303797468", brLenTotal: "0.3448" },
  { geneTranscript: "NM_134261.3", parsimonySitesP: "0.0898661567877629", constantSitesP: "0.840662842574888", distinctSitesP: "0.140854047163799", brLenTotal: "0.3606" },
  { geneTranscript: "NM_005045.4", parsimonySitesP: "0.154238921001927", constantSitesP: "0.752697495183044", distinctSitesP: "0.165606936416185", brLenTotal: "0.5633" },
  { geneTranscript: "NM_018728.4", parsimonySitesP: "0.207998469192499", constantSitesP: "0.677382319173364", distinctSitesP: "0.282051282051282", brLenTotal: "0.7612" },
  { geneTranscript: "NM_012322.3", parsimonySitesP: "0.10989010989011", constantSitesP: "0.80952380952381", distinctSitesP: "0.245421245421245", brLenTotal: "0.3174" },
  { geneTranscript: "NM_002167.5", parsimonySitesP: "0.215686274509804", constantSitesP: "0.680672268907563", distinctSitesP: "0.316526610644258", brLenTotal: "0.7221" },
  { geneTranscript: "NM_022055.2", parsimonySitesP: "0.0937984496124031", constantSitesP: "0.694573643410853", distinctSitesP: "0.162015503875969", brLenTotal: "0.6704" },
  { geneTranscript: "NM_015137.6", parsimonySitesP: "0.139261063743402", constantSitesP: "0.769386926512383", distinctSitesP: "0.203004466098254", brLenTotal: "0.4464" },
  { geneTranscript: "NM_019116.3", parsimonySitesP: "0.133764832793959", constantSitesP: "0.724919093851133", distinctSitesP: "0.239482200647249", brLenTotal: "0.5338" },
  { geneTranscript: "NM_001014809.3", parsimonySitesP: "0.214771622934888", constantSitesP: "0.689990281827017", distinctSitesP: "0.273566569484937", brLenTotal: "1.0049" },
  { geneTranscript: "NM_181885.3", parsimonySitesP: "0.239750445632799", constantSitesP: "0.587344028520499", distinctSitesP: "0.354723707664884", brLenTotal: "0.9489" },
  { geneTranscript: "NM_006653.5", parsimonySitesP: "0.178184281842818", constantSitesP: "0.68360433604336", distinctSitesP: "0.256775067750678", brLenTotal: "0.7289" },
  { geneTranscript: "NM_006451.5", parsimonySitesP: "0.102992345163535", constantSitesP: "0.791231732776618", distinctSitesP: "0.187195546276966", brLenTotal: "0.3466" },
  { geneTranscript: "NM_201566.3", parsimonySitesP: "0.219874804381847", constantSitesP: "0.634585289514867", distinctSitesP: "0.310641627543036", brLenTotal: "0.7340" },
  { geneTranscript: "NM_016631.4", parsimonySitesP: "0.113049800072701", constantSitesP: "0.801163213376954", distinctSitesP: "0.165394402035623", brLenTotal: "0.3596" },
  { geneTranscript: "NM_001451.3", parsimonySitesP: "0.154793315743184", constantSitesP: "0.703605980650836", distinctSitesP: "0.236587510993843", brLenTotal: "0.6796" },
  { geneTranscript: "NM_001348946.2", parsimonySitesP: "0.240625", constantSitesP: "0.629947916666667", distinctSitesP: "0.31875", brLenTotal: "0.9912" },
  { geneTranscript: "NM_001188.4", parsimonySitesP: "0.227488151658768", constantSitesP: "0.649289099526066", distinctSitesP: "0.327014218009479", brLenTotal: "0.8838" },
  { geneTranscript: "NM_001351.4", parsimonySitesP: "0.178531073446328", constantSitesP: "0.671186440677966", distinctSitesP: "0.331073446327684", brLenTotal: "0.6176" },
  { geneTranscript: "NM_006914.4", parsimonySitesP: "0.0944081336238199", constantSitesP: "0.847494553376906", distinctSitesP: "0.142338416848221", brLenTotal: "0.3180" },
  { geneTranscript: "NM_001344.4", parsimonySitesP: "0.0973451327433628", constantSitesP: "0.746312684365782", distinctSitesP: "0.24188790560472", brLenTotal: "0.4052" },
  { geneTranscript: "NM_004095.4", parsimonySitesP: "0.209039548022599", constantSitesP: "0.65819209039548", distinctSitesP: "0.34180790960452", brLenTotal: "0.7512" },
  { geneTranscript: "NM_052925.4", parsimonySitesP: "0.1925", constantSitesP: "0.700416666666667", distinctSitesP: "0.24375", brLenTotal: "0.8424" },
  { geneTranscript: "NM_145295.4", parsimonySitesP: "0.445408532176428", constantSitesP: "0.397686189443239", distinctSitesP: "0.582791033984093", brLenTotal: "2.3780" },
  { geneTranscript: "NM_020717.5", parsimonySitesP: "0.228175932127707", constantSitesP: "0.575574905112748", distinctSitesP: "0.290913150256754", brLenTotal: "0.8421" },
  { geneTranscript: "NM_001394031.1", parsimonySitesP: "0.107407407407407", constantSitesP: "0.797979797979798", distinctSitesP: "0.156565656565657", brLenTotal: "0.3572" },
  { geneTranscript: "NM_001360452.2", parsimonySitesP: "0.1071953010279", constantSitesP: "0.832599118942731", distinctSitesP: "0.18208516886931", brLenTotal: "0.3560" },
  { geneTranscript: "NM_001458.5", parsimonySitesP: "0.194006116207951", constantSitesP: "0.706911314984709", distinctSitesP: "0.209785932721713", brLenTotal: "0.9372" },
  { geneTranscript: "NM_015026.3", parsimonySitesP: "0.148126577363619", constantSitesP: "0.763346922927587", distinctSitesP: "0.183653659483595", brLenTotal: "0.4753" },
  { geneTranscript: "NM_016039.3", parsimonySitesP: "0.12431693989071", constantSitesP: "0.748633879781421", distinctSitesP: "0.237704918032787", brLenTotal: "0.4083" },
  { geneTranscript: "NM_002039.4", parsimonySitesP: "0.167627281460134", constantSitesP: "0.727665706051873", distinctSitesP: "0.226224783861671", brLenTotal: "0.5868" },
  { geneTranscript: "NM_130443.4", parsimonySitesP: "0.216191768430574", constantSitesP: "0.663500678426052", distinctSitesP: "0.283582089552239", brLenTotal: "0.8623" },
  { geneTranscript: "NM_001394674.1", parsimonySitesP: "0.113665389527458", constantSitesP: "0.818646232439336", distinctSitesP: "0.169859514687101", brLenTotal: "0.3496" },
  { geneTranscript: "NM_012423.4", parsimonySitesP: "0.187192118226601", constantSitesP: "0.697865353037767", distinctSitesP: "0.303776683087028", brLenTotal: "0.7421" },
  { geneTranscript: "NM_001330988.2", parsimonySitesP: "0.188349514563107", constantSitesP: "0.711326860841424", distinctSitesP: "0.242071197411003", brLenTotal: "0.7711" },
  { geneTranscript: "NM_001127392.3", parsimonySitesP: "0.186794092093831", constantSitesP: "0.69794381697075", distinctSitesP: "0.239791485664639", brLenTotal: "0.7493" },
  { geneTranscript: "NM_001394073.1", parsimonySitesP: "0.183462532299742", constantSitesP: "0.695090439276486", distinctSitesP: "0.243927648578811", brLenTotal: "0.6001" },
  { geneTranscript: "NM_000944.5", parsimonySitesP: "0.154190658989123", constantSitesP: "0.788227767114523", distinctSitesP: "0.206653870761356", brLenTotal: "0.5320" },
  { geneTranscript: "NM_003297.4", parsimonySitesP: "0.123272526257601", constantSitesP: "0.737976782752902", distinctSitesP: "0.216694306246545", brLenTotal: "0.4772" },
  { geneTranscript: "NM_052876.4", parsimonySitesP: "0.257432005060089", constantSitesP: "0.654648956356736", distinctSitesP: "0.298545224541429", brLenTotal: "1.2444" },
  { geneTranscript: "NM_203463.3", parsimonySitesP: "0.152777777777778", constantSitesP: "0.744791666666667", distinctSitesP: "0.230902777777778", brLenTotal: "0.5292" },
  { geneTranscript: "NM_173508.4", parsimonySitesP: "0.13469387755102", constantSitesP: "0.774149659863946", distinctSitesP: "0.201360544217687", brLenTotal: "0.5482" },
  { geneTranscript: "NM_001206927.2", parsimonySitesP: "0.184547836555485", constantSitesP: "0.707386162453084", distinctSitesP: "0.235252460873876", brLenTotal: "0.6656" },
  { geneTranscript: "NM_004640.7", parsimonySitesP: "0.132398753894081", constantSitesP: "0.752336448598131", distinctSitesP: "0.218068535825545", brLenTotal: "0.5335" },
  { geneTranscript: "NM_000799.4", parsimonySitesP: "0.295336787564767", constantSitesP: "0.559585492227979", distinctSitesP: "0.411053540587219", brLenTotal: "0.9386" },
  { geneTranscript: "NM_003509.3", parsimonySitesP: "0.246153846153846", constantSitesP: "0.661538461538462", distinctSitesP: "0.328205128205128", brLenTotal: "1.9903" },
  { geneTranscript: "NM_014372.5", parsimonySitesP: "0.0670995670995671", constantSitesP: "0.835497835497835", distinctSitesP: "0.162337662337662", brLenTotal: "0.2573" },
  { geneTranscript: "NM_001395426.1", parsimonySitesP: "0.261608623548922", constantSitesP: "0.54367053620785", distinctSitesP: "0.369402985074627", brLenTotal: "0.9876" },
  { geneTranscript: "NM_014962.4", parsimonySitesP: "0.176245210727969", constantSitesP: "0.720306513409962", distinctSitesP: "0.190932311621967", brLenTotal: "0.6604" },
  { geneTranscript: "NM_003307.4", parsimonySitesP: "0.261698824573076", constantSitesP: "0.602572632512752", distinctSitesP: "0.345974717232202", brLenTotal: "1.2216" },
  { geneTranscript: "NM_022481.6", parsimonySitesP: "0.201208981001727", constantSitesP: "0.660621761658031", distinctSitesP: "0.244386873920553", brLenTotal: "0.7092" },
  { geneTranscript: "NM_001385089.1", parsimonySitesP: "0.308278867102397", constantSitesP: "0.562091503267974", distinctSitesP: "0.398148148148148", brLenTotal: "1.2199" },
  { geneTranscript: "NM_006306.4", parsimonySitesP: "0.123276561232766", constantSitesP: "0.792376317923763", distinctSitesP: "0.137334414706677", brLenTotal: "0.3534" },
  { geneTranscript: "NM_130385.4", parsimonySitesP: "0.208333333333333", constantSitesP: "0.638523391812865", distinctSitesP: "0.294590643274854", brLenTotal: "0.7629" },
  { geneTranscript: "NM_001017995.3", parsimonySitesP: "0.234540797658251", constantSitesP: "0.633735821441639", distinctSitesP: "0.302231979509696", brLenTotal: "0.9525" },
  { geneTranscript: "NM_021111.3", parsimonySitesP: "0.1520768966701", constantSitesP: "0.751458976999657", distinctSitesP: "0.203913491246138", brLenTotal: "0.4861" },
  { geneTranscript: "NM_001394167.1", parsimonySitesP: "0.204726826273788", constantSitesP: "0.669736034376918", distinctSitesP: "0.280233271945979", brLenTotal: "0.8185" },
  { geneTranscript: "NM_021059.3", parsimonySitesP: "0.284313725490196", constantSitesP: "0.696078431372549", distinctSitesP: "0.303921568627451", brLenTotal: "2.1418" },
  { geneTranscript: "NM_001098785.2", parsimonySitesP: "0.201058201058201", constantSitesP: "0.707231040564374", distinctSitesP: "0.287477954144621", brLenTotal: "0.6773" },
  { geneTranscript: "NM_012193.4", parsimonySitesP: "0.121663563004345", constantSitesP: "0.792675356921167", distinctSitesP: "0.168218497827436", brLenTotal: "0.3984" },
  { geneTranscript: "NM_007122.5", parsimonySitesP: "0.108602150537634", constantSitesP: "0.818279569892473", distinctSitesP: "0.167741935483871", brLenTotal: "0.3725" },
  { geneTranscript: "NM_006742.3", parsimonySitesP: "0.163522012578616", constantSitesP: "0.726415094339623", distinctSitesP: "0.226415094339623", brLenTotal: "0.6374" },
  { geneTranscript: "NM_025194.3", parsimonySitesP: "0.26207906295754", constantSitesP: "0.547584187408492", distinctSitesP: "0.367984382625671", brLenTotal: "0.9688" },
  { geneTranscript: "NM_001040179.2", parsimonySitesP: "0.217647058823529", constantSitesP: "0.647058823529412", distinctSitesP: "0.326470588235294", brLenTotal: "0.7088" },
  { geneTranscript: "NM_001098497.3", parsimonySitesP: "0.198536139066789", constantSitesP: "0.669411405916438", distinctSitesP: "0.261055199756023", brLenTotal: "0.8958" },
  { geneTranscript: "NM_004596.5", parsimonySitesP: "0.167848699763593", constantSitesP: "0.716312056737589", distinctSitesP: "0.268321513002364", brLenTotal: "0.7252" },
  { geneTranscript: "NM_006922.4", parsimonySitesP: "0.239333333333333", constantSitesP: "0.663666666666667", distinctSitesP: "0.2725", brLenTotal: "1.6653" },
  { geneTranscript: "NM_001616.5", parsimonySitesP: "0.0844704353476283", constantSitesP: "0.840155945419103", distinctSitesP: "0.130604288499025", brLenTotal: "0.2821" },
  { geneTranscript: "NM_001297563.2", parsimonySitesP: "0.245963912630579", constantSitesP: "0.568850902184236", distinctSitesP: "0.415954415954416", brLenTotal: "0.8700" },
  { geneTranscript: "NM_001098507.2", parsimonySitesP: "0.100539811066127", constantSitesP: "0.778677462887989", distinctSitesP: "0.179487179487179", brLenTotal: "0.3892" },
  { geneTranscript: "NM_001136262.2", parsimonySitesP: "0.199312714776632", constantSitesP: "0.65979381443299", distinctSitesP: "0.298969072164948", brLenTotal: "0.6914" },
  { geneTranscript: "NM_016037.4", parsimonySitesP: "0.155467720685112", constantSitesP: "0.716732542819499", distinctSitesP: "0.258234519104084", brLenTotal: "0.4890" },
  { geneTranscript: "NM_001242898.2", parsimonySitesP: "0.256517205422315", constantSitesP: "0.610358011817866", distinctSitesP: "0.367744177963156", brLenTotal: "1.0925" },
  { geneTranscript: "NM_020368.3", parsimonySitesP: "0.237299930410578", constantSitesP: "0.57759220598469", distinctSitesP: "0.383437717466945", brLenTotal: "0.8038" },
  { geneTranscript: "NM_022144.3", parsimonySitesP: "0.101997896950578", constantSitesP: "0.794952681388013", distinctSitesP: "0.176656151419558", brLenTotal: "0.3437" },
  { geneTranscript: "NM_019842.4", parsimonySitesP: "0.121602288984263", constantSitesP: "0.78898426323319", distinctSitesP: "0.170243204577969", brLenTotal: "0.4150" },
  { geneTranscript: "NM_145725.3", parsimonySitesP: "0.157276995305164", constantSitesP: "0.755868544600939", distinctSitesP: "0.227699530516432", brLenTotal: "0.7008" },
  { geneTranscript: "NM_012188.5", parsimonySitesP: "0.281305114638448", constantSitesP: "0.574955908289242", distinctSitesP: "0.383597883597884", brLenTotal: "1.1406" },
  { geneTranscript: "NM_000906.4", parsimonySitesP: "0.201382343700911", constantSitesP: "0.668237511781338", distinctSitesP: "0.24693685202639", brLenTotal: "0.7773" },
  { geneTranscript: "NM_001286451.2", parsimonySitesP: "0.17877094972067", constantSitesP: "0.649906890130354", distinctSitesP: "0.31098696461825", brLenTotal: "0.6770" },
  { geneTranscript: "NM_012323.4", parsimonySitesP: "0.201219512195122", constantSitesP: "0.693089430894309", distinctSitesP: "0.327235772357724", brLenTotal: "0.7714" },
  { geneTranscript: "NM_004710.7", parsimonySitesP: "0.232142857142857", constantSitesP: "0.636904761904762", distinctSitesP: "0.333333333333333", brLenTotal: "1.0961" },
  { geneTranscript: "NM_005706.4", parsimonySitesP: "0.407294832826748", constantSitesP: "0.406281661600811", distinctSitesP: "0.556231003039514", brLenTotal: "1.7131" },
  { geneTranscript: "NM_000138.5", parsimonySitesP: "0.160571229536747", constantSitesP: "0.754208754208754", distinctSitesP: "0.181585974689423", brLenTotal: "0.5930" },
  { geneTranscript: "NM_199000.3", parsimonySitesP: "0.138418079096045", constantSitesP: "0.78954802259887", distinctSitesP: "0.204802259887006", brLenTotal: "0.4118" },
  { geneTranscript: "NM_030901.2", parsimonySitesP: "0.55663430420712", constantSitesP: "0.272923408845739", distinctSitesP: "0.701186623516721", brLenTotal: "3.9067" },
  { geneTranscript: "NM_006406.2", parsimonySitesP: "0.161131611316113", constantSitesP: "0.734317343173432", distinctSitesP: "0.285362853628536", brLenTotal: "0.5399" },
  { geneTranscript: "NM_006816.3", parsimonySitesP: "0.180711610486891", constantSitesP: "0.716292134831461", distinctSitesP: "0.25", brLenTotal: "0.7765" },
  { geneTranscript: "NM_015713.5", parsimonySitesP: "0.138651471984805", constantSitesP: "0.779677113010446", distinctSitesP: "0.190883190883191", brLenTotal: "0.4252" },
  { geneTranscript: "NM_212552.3", parsimonySitesP: "0.21183800623053", constantSitesP: "0.644859813084112", distinctSitesP: "0.370716510903427", brLenTotal: "0.7058" },
  { geneTranscript: "NM_001512.4", parsimonySitesP: "0.157657657657658", constantSitesP: "0.75975975975976", distinctSitesP: "0.247747747747748", brLenTotal: "0.5394" },
  { geneTranscript: "NM_033266.4", parsimonySitesP: "0.265658747300216", constantSitesP: "0.590712742980562", distinctSitesP: "0.359611231101512", brLenTotal: "1.0441" },
  { geneTranscript: "NM_021004.4", parsimonySitesP: "0.326139088729017", constantSitesP: "0.532374100719424", distinctSitesP: "0.452038369304556", brLenTotal: "1.3529" },
  { geneTranscript: "NM_015510.5", parsimonySitesP: "0.248205128205128", constantSitesP: "0.6", distinctSitesP: "0.361025641025641", brLenTotal: "0.8950" },
  { geneTranscript: "NM_013391.3", parsimonySitesP: "0.196304849884527", constantSitesP: "0.680523479599692", distinctSitesP: "0.279445727482679", brLenTotal: "0.7200" },
  { geneTranscript: "NM_175607.3", parsimonySitesP: "0.182586094866797", constantSitesP: "0.710526315789474", distinctSitesP: "0.229694606887589", brLenTotal: "0.7498" },
  { geneTranscript: "NM_001947.4", parsimonySitesP: "0.153540175019889", constantSitesP: "0.651551312649165", distinctSitesP: "0.256961018297534", brLenTotal: "0.8666" },
  { geneTranscript: "NM_001130963.2", parsimonySitesP: "0.174924924924925", constantSitesP: "0.688438438438438", distinctSitesP: "0.295045045045045", brLenTotal: "0.5552" },
  { geneTranscript: "NM_139177.4", parsimonySitesP: "0.213930348258706", constantSitesP: "0.683582089552239", distinctSitesP: "0.28955223880597", brLenTotal: "0.9870" },
  { geneTranscript: "NM_001177701.3", parsimonySitesP: "0.234767025089606", constantSitesP: "0.637992831541219", distinctSitesP: "0.360215053763441", brLenTotal: "0.8444" },
  { geneTranscript: "NM_005469.4", parsimonySitesP: "0.229885057471264", constantSitesP: "0.635318704284222", distinctSitesP: "0.32183908045977", brLenTotal: "0.8242" },
  { geneTranscript: "NM_182517.3", parsimonySitesP: "0.247787610619469", constantSitesP: "0.554572271386431", distinctSitesP: "0.436578171091445", brLenTotal: "0.8895" },
  { geneTranscript: "NM_138331.2", parsimonySitesP: "0.443722943722944", constantSitesP: "0.354978354978355", distinctSitesP: "0.686147186147186", brLenTotal: "2.0094" },
  { geneTranscript: "NM_138983.3", parsimonySitesP: "0.173431734317343", constantSitesP: "0.674046740467405", distinctSitesP: "0.313653136531365", brLenTotal: "0.8261" },
  { geneTranscript: "NM_001035006.5", parsimonySitesP: "0.134057971014493", constantSitesP: "0.766304347826087", distinctSitesP: "0.240942028985507", brLenTotal: "0.4655" },
  { geneTranscript: "NM_003955.5", parsimonySitesP: "0.13037037037037", constantSitesP: "0.768888888888889", distinctSitesP: "0.217777777777778", brLenTotal: "0.5650" },
  { geneTranscript: "NM_138761.4", parsimonySitesP: "0.152777777777778", constantSitesP: "0.765625", distinctSitesP: "0.223958333333333", brLenTotal: "0.5755" },
  { geneTranscript: "NM_003887.3", parsimonySitesP: "0.180914512922465", constantSitesP: "0.714049039098741", distinctSitesP: "0.24155069582505", brLenTotal: "0.7487" },
  { geneTranscript: "NM_152721.6", parsimonySitesP: "0.181268882175227", constantSitesP: "0.749244712990937", distinctSitesP: "0.231621349446123", brLenTotal: "0.6829" },
  { geneTranscript: "NM_024697.3", parsimonySitesP: "0.110548523206751", constantSitesP: "0.760337552742616", distinctSitesP: "0.194092827004219", brLenTotal: "0.4711" },
  { geneTranscript: "NM_006184.6", parsimonySitesP: "0.209689081706435", constantSitesP: "0.684743311641359", distinctSitesP: "0.310195227765727", brLenTotal: "0.8689" },
  { geneTranscript: "NM_182539.4", parsimonySitesP: "0.260811709913506", constantSitesP: "0.59148369926813", distinctSitesP: "0.391217564870259", brLenTotal: "1.0392" },
  { geneTranscript: "NM_001104544.3", parsimonySitesP: "0.123076923076923", constantSitesP: "0.8", distinctSitesP: "0.182564102564103", brLenTotal: "0.3735" },
  { geneTranscript: "NM_000346.4", parsimonySitesP: "0.161755075311067", constantSitesP: "0.696791093647675", distinctSitesP: "0.239030779305828", brLenTotal: "0.7889" },
  { geneTranscript: "NM_001389617.1", parsimonySitesP: "0.178835489833641", constantSitesP: "0.726586568083795", distinctSitesP: "0.200092421441774", brLenTotal: "0.6302" },
  { geneTranscript: "NM_001625.4", parsimonySitesP: "0.186889818688982", constantSitesP: "0.668061366806137", distinctSitesP: "0.292887029288703", brLenTotal: "0.7236" },
  { geneTranscript: "NM_006682.3", parsimonySitesP: "0.231586940015186", constantSitesP: "0.613515565679575", distinctSitesP: "0.331814730447988", brLenTotal: "0.9224" },
  { geneTranscript: "NM_025079.3", parsimonySitesP: "0.278241513633834", constantSitesP: "0.591541457985531", distinctSitesP: "0.351697273233166", brLenTotal: "1.1918" },
  { geneTranscript: "NM_006496.4", parsimonySitesP: "0.145009416195857", constantSitesP: "0.749529190207156", distinctSitesP: "0.211864406779661", brLenTotal: "0.6247" },
  { geneTranscript: "NM_017556.4", parsimonySitesP: "0.280607685433423", constantSitesP: "0.562109025915996", distinctSitesP: "0.431635388739946", brLenTotal: "1.2591" },
  { geneTranscript: "NM_024847.4", parsimonySitesP: "0.200092208390964", constantSitesP: "0.684186260949746", distinctSitesP: "0.29921622867681", brLenTotal: "0.7444" },
  { geneTranscript: "NM_030811.4", parsimonySitesP: "0.307317073170732", constantSitesP: "0.52520325203252", distinctSitesP: "0.455284552845528", brLenTotal: "1.0160" },
  { geneTranscript: "NM_171998.4", parsimonySitesP: "0.0782472613458529", constantSitesP: "0.821596244131455", distinctSitesP: "0.151799687010955", brLenTotal: "0.3058" },
  { geneTranscript: "NM_139284.3", parsimonySitesP: "0.23153320918684", constantSitesP: "0.63935443823712", distinctSitesP: "0.320918684047176", brLenTotal: "1.0214" },
  { geneTranscript: "NM_014331.4", parsimonySitesP: "0.141716566866267", constantSitesP: "0.761144377910845", distinctSitesP: "0.229540918163673", brLenTotal: "0.5442" },
  { geneTranscript: "NM_016618.3", parsimonySitesP: "0.392535392535393", constantSitesP: "0.451737451737452", distinctSitesP: "0.485199485199485", brLenTotal: "1.1630" },
  { geneTranscript: "NM_015387.5", parsimonySitesP: "0.0666666666666667", constantSitesP: "0.85037037037037", distinctSitesP: "0.145185185185185", brLenTotal: "0.2414" },
  { geneTranscript: "NM_002881.3", parsimonySitesP: "0.148867313915858", constantSitesP: "0.733009708737864", distinctSitesP: "0.231391585760518", brLenTotal: "0.7687" },
  { geneTranscript: "NM_198504.4", parsimonySitesP: "0.172413793103448", constantSitesP: "0.710875331564987", distinctSitesP: "0.260831122900088", brLenTotal: "0.6380" },
  { geneTranscript: "NM_001143830.3", parsimonySitesP: "0.117145899893504", constantSitesP: "0.779552715654952", distinctSitesP: "0.198083067092652", brLenTotal: "0.3692" },
  { geneTranscript: "NM_016609.7", parsimonySitesP: "0.136819862651875", constantSitesP: "0.770206022187005", distinctSitesP: "0.185419968304279", brLenTotal: "0.5147" },
  { geneTranscript: "NM_018348.6", parsimonySitesP: "0.196536796536797", constantSitesP: "0.655411255411255", distinctSitesP: "0.274891774891775", brLenTotal: "0.6329" },
  { geneTranscript: "NM_001128203.2", parsimonySitesP: "0.269547325102881", constantSitesP: "0.62962962962963", distinctSitesP: "0.378600823045267", brLenTotal: "1.1985" },
  { geneTranscript: "NM_021079.5", parsimonySitesP: "0.144489247311828", constantSitesP: "0.721102150537634", distinctSitesP: "0.225806451612903", brLenTotal: "0.5396" },
  { geneTranscript: "NM_032932.6", parsimonySitesP: "0.178963893249608", constantSitesP: "0.717948717948718", distinctSitesP: "0.250654107796965", brLenTotal: "0.8338" },
  { geneTranscript: "NM_005316.4", parsimonySitesP: "0.108880778588808", constantSitesP: "0.790754257907543", distinctSitesP: "0.182481751824818", brLenTotal: "0.3772" },
  { geneTranscript: "NM_152737.4", parsimonySitesP: "0.157894736842105", constantSitesP: "0.735492577597841", distinctSitesP: "0.225371120107962", brLenTotal: "0.7143" },
  { geneTranscript: "NM_005180.9", parsimonySitesP: "0.0674846625766871", constantSitesP: "0.857873210633947", distinctSitesP: "0.1359918200409", brLenTotal: "0.2143" },
  { geneTranscript: "NM_006233.5", parsimonySitesP: "0.189333333333333", constantSitesP: "0.757333333333333", distinctSitesP: "0.24", brLenTotal: "0.5942" },
  { geneTranscript: "NM_022070.5", parsimonySitesP: "0.220152413209145", constantSitesP: "0.611910810047982", distinctSitesP: "0.329664126446514", brLenTotal: "0.7808" },
  { geneTranscript: "NM_024615.4", parsimonySitesP: "0.126073380171741", constantSitesP: "0.788836846213895", distinctSitesP: "0.184231069476971", brLenTotal: "0.4154" },
  { geneTranscript: "NM_015701.5", parsimonySitesP: "0.106280193236715", constantSitesP: "0.806763285024155", distinctSitesP: "0.173222912353347", brLenTotal: "0.3508" },
  { geneTranscript: "NM_033452.3", parsimonySitesP: "0.231452455590387", constantSitesP: "0.652037617554859", distinctSitesP: "0.297805642633229", brLenTotal: "0.9431" },
  { geneTranscript: "NM_012250.6", parsimonySitesP: "0.0964052287581699", constantSitesP: "0.777777777777778", distinctSitesP: "0.197712418300654", brLenTotal: "0.3742" },
  { geneTranscript: "NM_030624.3", parsimonySitesP: "0.121412803532009", constantSitesP: "0.805187637969095", distinctSitesP: "0.153973509933775", brLenTotal: "0.3951" },
  { geneTranscript: "NM_199180.4", parsimonySitesP: "0.273069679849341", constantSitesP: "0.541902071563089", distinctSitesP: "0.385122410546139", brLenTotal: "1.0307" },
  { geneTranscript: "NM_006906.2", parsimonySitesP: "0.158702064896755", constantSitesP: "0.726843657817109", distinctSitesP: "0.221828908554572", brLenTotal: "0.6051" },
  { geneTranscript: "NM_145199.3", parsimonySitesP: "0.194816800714924", constantSitesP: "0.662198391420912", distinctSitesP: "0.306523681858803", brLenTotal: "0.6225" },
  { geneTranscript: "NM_006929.5", parsimonySitesP: "0.196361690743713", constantSitesP: "0.682182985553772", distinctSitesP: "0.245050829320492", brLenTotal: "0.7472" },
  { geneTranscript: "NM_001302757.2", parsimonySitesP: "0.235087719298246", constantSitesP: "0.640350877192982", distinctSitesP: "0.363157894736842", brLenTotal: "1.0137" },
  { geneTranscript: "NM_001171109.2", parsimonySitesP: "0.278225806451613", constantSitesP: "0.575268817204301", distinctSitesP: "0.388440860215054", brLenTotal: "0.9382" },
  { geneTranscript: "NM_022158.4", parsimonySitesP: "0.290183387270766", constantSitesP: "0.571736785329018", distinctSitesP: "0.386192017259978", brLenTotal: "1.2704" },
  { geneTranscript: "NM_203453.5", parsimonySitesP: "0.19774011299435", constantSitesP: "0.649717514124294", distinctSitesP: "0.305084745762712", brLenTotal: "0.6930" },
  { geneTranscript: "NM_015848.4", parsimonySitesP: "0.365726227795193", constantSitesP: "0.446708463949843", distinctSitesP: "0.552246603970742", brLenTotal: "2.0408" },
  { geneTranscript: "NM_031455.4", parsimonySitesP: "0.171604938271605", constantSitesP: "0.696296296296296", distinctSitesP: "0.304938271604938", brLenTotal: "0.5987" },
  { geneTranscript: "NM_002632.6", parsimonySitesP: "0.176470588235294", constantSitesP: "0.696078431372549", distinctSitesP: "0.292156862745098", brLenTotal: "0.7104" },
  { geneTranscript: "NM_005226.4", parsimonySitesP: "0.249559082892416", constantSitesP: "0.611111111111111", distinctSitesP: "0.346560846560847", brLenTotal: "1.0343" },
  { geneTranscript: "NM_015464.3", parsimonySitesP: "0.127831715210356", constantSitesP: "0.755663430420712", distinctSitesP: "0.228155339805825", brLenTotal: "0.4161" },
  { geneTranscript: "NM_021202.3", parsimonySitesP: "0.192424242424242", constantSitesP: "0.660606060606061", distinctSitesP: "0.339393939393939", brLenTotal: "0.7217" },
  { geneTranscript: "NM_017694.4", parsimonySitesP: "0.198482932996207", constantSitesP: "0.676780446691951", distinctSitesP: "0.277707543194269", brLenTotal: "0.7097" },
  { geneTranscript: "NM_000557.5", parsimonySitesP: "0.143047238855622", constantSitesP: "0.745176314038589", distinctSitesP: "0.21290751829674", brLenTotal: "0.5067" },
  { geneTranscript: "NM_002358.4", parsimonySitesP: "0.15609756097561", constantSitesP: "0.68780487804878", distinctSitesP: "0.268292682926829", brLenTotal: "0.5635" },
  { geneTranscript: "NM_006099.3", parsimonySitesP: "0.152866242038217", constantSitesP: "0.752653927813163", distinctSitesP: "0.201698513800425", brLenTotal: "0.5422" },
  { geneTranscript: "NM_001363941.2", parsimonySitesP: "0.0782565626547796", constantSitesP: "0.840515106488361", distinctSitesP: "0.120851906884596", brLenTotal: "0.2833" },
  { geneTranscript: "NM_001372.4", parsimonySitesP: "0.256501709020657", constantSitesP: "0.602318323673651", distinctSitesP: "0.298707088720464", brLenTotal: "0.9697" },
  { geneTranscript: "NM_001013641.3", parsimonySitesP: "0.256559766763848", constantSitesP: "0.602526724975705", distinctSitesP: "0.380952380952381", brLenTotal: "1.1720" },
  { geneTranscript: "NM_004859.4", parsimonySitesP: "0.120597014925373", constantSitesP: "0.788059701492537", distinctSitesP: "0.14865671641791", brLenTotal: "0.4568" },
  { geneTranscript: "NM_005653.5", parsimonySitesP: "0.126162018592297", constantSitesP: "0.791500664010624", distinctSitesP: "0.191899070385126", brLenTotal: "0.4288" },
  { geneTranscript: "NM_005522.5", parsimonySitesP: "0.166169154228856", constantSitesP: "0.721393034825871", distinctSitesP: "0.250746268656716", brLenTotal: "0.5205" },
  { geneTranscript: "NM_030768.3", parsimonySitesP: "0.131802721088435", constantSitesP: "0.747448979591837", distinctSitesP: "0.218537414965986", brLenTotal: "0.5356" },
  { geneTranscript: "NM_001365304.2", parsimonySitesP: "0.225419664268585", constantSitesP: "0.62589928057554", distinctSitesP: "0.354916067146283", brLenTotal: "0.7188" },
  { geneTranscript: "NM_145315.5", parsimonySitesP: "0.144144144144144", constantSitesP: "0.72972972972973", distinctSitesP: "0.232848232848233", brLenTotal: "0.5369" },
  { geneTranscript: "NM_001093.4", parsimonySitesP: "0.243965283428261", constantSitesP: "0.649308380797396", distinctSitesP: "0.275562788174668", brLenTotal: "1.0853" },
  { geneTranscript: "NM_005645.4", parsimonySitesP: "0.115591397849462", constantSitesP: "0.744623655913978", distinctSitesP: "0.263440860215054", brLenTotal: "0.4385" },
  { geneTranscript: "NM_001396959.1", parsimonySitesP: "0.232963549920761", constantSitesP: "0.637612255678817", distinctSitesP: "0.323560486001057", brLenTotal: "0.9405" },
  { geneTranscript: "NM_014580.5", parsimonySitesP: "0.236198462613557", constantSitesP: "0.635220125786164", distinctSitesP: "0.313067784765898", brLenTotal: "0.9895" },
  { geneTranscript: "NM_001330071.2", parsimonySitesP: "0.131531531531532", constantSitesP: "0.784684684684685", distinctSitesP: "0.183783783783784", brLenTotal: "0.6001" },
  { geneTranscript: "NM_002027.3", parsimonySitesP: "0.175021987686895", constantSitesP: "0.686015831134565", distinctSitesP: "0.314863676341249", brLenTotal: "0.5965" },
  { geneTranscript: "NM_194296.2", parsimonySitesP: "0.159349593495935", constantSitesP: "0.728455284552846", distinctSitesP: "0.247154471544715", brLenTotal: "0.4660" },
  { geneTranscript: "NM_001083965.2", parsimonySitesP: "0.190730837789661", constantSitesP: "0.659536541889483", distinctSitesP: "0.300059417706477", brLenTotal: "0.6597" },
  { geneTranscript: "NM_002070.4", parsimonySitesP: "0.169953051643192", constantSitesP: "0.725821596244131", distinctSitesP: "0.228169014084507", brLenTotal: "0.7127" },
  { geneTranscript: "NM_001366446.1", parsimonySitesP: "0.14557564224548", constantSitesP: "0.739613066920393", distinctSitesP: "0.200126863304789", brLenTotal: "0.4960" },
  { geneTranscript: "NM_025163.4", parsimonySitesP: "0.302820955670697", constantSitesP: "0.540011514104778", distinctSitesP: "0.40587219343696", brLenTotal: "1.1349" },
  { geneTranscript: "NM_139167.4", parsimonySitesP: "0.13034188034188", constantSitesP: "0.74465811965812", distinctSitesP: "0.204059829059829", brLenTotal: "0.5138" },
  { geneTranscript: "NM_002911.4", parsimonySitesP: "0.179785330948122", constantSitesP: "0.756410256410256", distinctSitesP: "0.211687537268933", brLenTotal: "0.9277" },
  { geneTranscript: "NM_005697.5", parsimonySitesP: "0.19047619047619", constantSitesP: "0.707193515704154", distinctSitesP: "0.271529888551165", brLenTotal: "0.6902" },
  { geneTranscript: "NM_006725.5", parsimonySitesP: "0.329840319361277", constantSitesP: "0.510978043912176", distinctSitesP: "0.463073852295409", brLenTotal: "1.2271" },
  { geneTranscript: "NM_006514.4", parsimonySitesP: "0.281356509884117", constantSitesP: "0.590149965916837", distinctSitesP: "0.35906612133606", brLenTotal: "1.5073" },
  { geneTranscript: "NM_001618.4", parsimonySitesP: "0.215976331360947", constantSitesP: "0.657133464825773", distinctSitesP: "0.292570677186062", brLenTotal: "0.8276" },
  { geneTranscript: "NM_032808.7", parsimonySitesP: "0.159677419354839", constantSitesP: "0.717204301075269", distinctSitesP: "0.2", brLenTotal: "0.6949" },
  { geneTranscript: "NM_001324.3", parsimonySitesP: "0.146171693735499", constantSitesP: "0.767208043310131", distinctSitesP: "0.219644238205723", brLenTotal: "0.6233" },
  { geneTranscript: "NM_001350197.2", parsimonySitesP: "0.131961259079903", constantSitesP: "0.764326069410815", distinctSitesP: "0.189265536723164", brLenTotal: "0.4518" },
  { geneTranscript: "NM_172037.5", parsimonySitesP: "0.108504398826979", constantSitesP: "0.805474095796676", distinctSitesP: "0.173998044965787", brLenTotal: "0.3510" },
  { geneTranscript: "NM_018225.3", parsimonySitesP: "0.124756335282651", constantSitesP: "0.773229369720598", distinctSitesP: "0.191682910981157", brLenTotal: "0.3752" },
  { geneTranscript: "NM_031468.4", parsimonySitesP: "0.164750957854406", constantSitesP: "0.744572158365262", distinctSitesP: "0.233716475095785", brLenTotal: "0.6522" },
  { geneTranscript: "NM_001031702.4", parsimonySitesP: "0.198088618592528", constantSitesP: "0.682305241818708", distinctSitesP: "0.254561251086012", brLenTotal: "0.8023" },
  { geneTranscript: "NM_001080475.3", parsimonySitesP: "0.179150240911082", constantSitesP: "0.683749452474814", distinctSitesP: "0.249671484888305", brLenTotal: "0.6638" },
  { geneTranscript: "NM_001114753.3", parsimonySitesP: "0.28774062816616", constantSitesP: "0.565856129685917", distinctSitesP: "0.400202634245187", brLenTotal: "1.0773" },
  { geneTranscript: "NM_006565.4", parsimonySitesP: "0.0994956441999083", constantSitesP: "0.836313617606602", distinctSitesP: "0.130215497478221", brLenTotal: "0.3416" },
  { geneTranscript: "NM_020126.5", parsimonySitesP: "0.23598369011213", constantSitesP: "0.64322120285423", distinctSitesP: "0.299184505606524", brLenTotal: "0.9053" },
  { geneTranscript: "NM_005172.2", parsimonySitesP: "0.290960451977401", constantSitesP: "0.578154425612053", distinctSitesP: "0.379472693032015", brLenTotal: "0.9746" },
  { geneTranscript: "NM_006367.4", parsimonySitesP: "0.147368421052632", constantSitesP: "0.703157894736842", distinctSitesP: "0.259649122807018", brLenTotal: "0.5613" },
  { geneTranscript: "NM_018711.5", parsimonySitesP: "0.153284671532847", constantSitesP: "0.765206812652068", distinctSitesP: "0.201338199513382", brLenTotal: "0.6485" },
  { geneTranscript: "NM_003610.4", parsimonySitesP: "0.157608695652174", constantSitesP: "0.748188405797101", distinctSitesP: "0.240942028985507", brLenTotal: "0.7105" },
  { geneTranscript: "NM_001386095.1", parsimonySitesP: "0.539784946236559", constantSitesP: "0.317204301075269", distinctSitesP: "0.681720430107527", brLenTotal: "4.1783" },
  { geneTranscript: "NM_001007561.3", parsimonySitesP: "0.324237560192616", constantSitesP: "0.52434456928839", distinctSitesP: "0.411449973247726", brLenTotal: "1.2696" },
  { geneTranscript: "NM_144563.3", parsimonySitesP: "0.165058949624866", constantSitesP: "0.683815648445874", distinctSitesP: "0.273311897106109", brLenTotal: "0.7675" },
  { geneTranscript: "NM_005986.3", parsimonySitesP: "0.35464620630861", constantSitesP: "0.555839727195226", distinctSitesP: "0.416027280477408", brLenTotal: "2.4171" },
  { geneTranscript: "NM_005098.4", parsimonySitesP: "0.181229773462783", constantSitesP: "0.676375404530744", distinctSitesP: "0.296116504854369", brLenTotal: "0.6742" },
  { geneTranscript: "NM_017758.4", parsimonySitesP: "0.130287648054146", constantSitesP: "0.777495769881557", distinctSitesP: "0.191201353637902", brLenTotal: "0.4876" },
  { geneTranscript: "NM_178011.5", parsimonySitesP: "0.0929432013769363", constantSitesP: "0.837636259323006", distinctSitesP: "0.133677567412507", brLenTotal: "0.3022" },
  { geneTranscript: "NM_003518.4", parsimonySitesP: "0.240740740740741", constantSitesP: "0.587301587301587", distinctSitesP: "0.383597883597884", brLenTotal: "1.6852" },
  { geneTranscript: "NM_001365324.3", parsimonySitesP: "0.11781206171108", constantSitesP: "0.784011220196353", distinctSitesP: "0.203833567087424", brLenTotal: "0.4100" },
  { geneTranscript: "NM_002391.6", parsimonySitesP: "0.172494172494172", constantSitesP: "0.615384615384615", distinctSitesP: "0.372960372960373", brLenTotal: "0.6784" },
  { geneTranscript: "NM_001004704.2", parsimonySitesP: "0.638619201725998", constantSitesP: "0.239482200647249", distinctSitesP: "0.751887810140237", brLenTotal: "5.6673" },
  { geneTranscript: "NM_005897.3", parsimonySitesP: "0.123287671232877", constantSitesP: "0.768264840182648", distinctSitesP: "0.195776255707763", brLenTotal: "0.3985" },
  { geneTranscript: "NM_003278.3", parsimonySitesP: "0.214521452145215", constantSitesP: "0.668316831683168", distinctSitesP: "0.305280528052805", brLenTotal: "0.7428" },
  { geneTranscript: "NM_018448.5", parsimonySitesP: "0.116531165311653", constantSitesP: "0.772086720867209", distinctSitesP: "0.154471544715447", brLenTotal: "0.4469" },
  { geneTranscript: "NM_013318.4", parsimonySitesP: "0.214595483774488", constantSitesP: "0.660983998803649", distinctSitesP: "0.239718857484672", brLenTotal: "0.9323" },
  { geneTranscript: "NM_052898.2", parsimonySitesP: "0.176410256410256", constantSitesP: "0.70974358974359", distinctSitesP: "0.272820512820513", brLenTotal: "0.8072" },
  { geneTranscript: "NM_181349.3", parsimonySitesP: "0.153214774281806", constantSitesP: "0.7765617875057", distinctSitesP: "0.202918376652987", brLenTotal: "0.6401" },
  { geneTranscript: "NM_002406.4", parsimonySitesP: "0.227715355805243", constantSitesP: "0.674906367041199", distinctSitesP: "0.292883895131086", brLenTotal: "1.0232" },
  { geneTranscript: "NM_017907.3", parsimonySitesP: "0.115942028985507", constantSitesP: "0.824016563146998", distinctSitesP: "0.169772256728778", brLenTotal: "0.3992" },
  { geneTranscript: "NM_003642.4", parsimonySitesP: "0.081145584725537", constantSitesP: "0.844073190135243", distinctSitesP: "0.140015910898966", brLenTotal: "0.2423" },
  { geneTranscript: "NM_138326.3", parsimonySitesP: "0.1875", constantSitesP: "0.709325396825397", distinctSitesP: "0.27281746031746", brLenTotal: "0.7179" },
  { geneTranscript: "NM_031463.5", parsimonySitesP: "0.227272727272727", constantSitesP: "0.656565656565657", distinctSitesP: "0.329292929292929", brLenTotal: "0.9176" },
  { geneTranscript: "NM_018486.3", parsimonySitesP: "0.111405835543767", constantSitesP: "0.801945181255526", distinctSitesP: "0.177718832891247", brLenTotal: "0.3557" },
  { geneTranscript: "NM_015004.4", parsimonySitesP: "0.166093928980527", constantSitesP: "0.713631156930126", distinctSitesP: "0.262313860252005", brLenTotal: "0.6595" },
  { geneTranscript: "NM_001164.5", parsimonySitesP: "0.147887323943662", constantSitesP: "0.753990610328638", distinctSitesP: "0.205633802816901", brLenTotal: "0.5518" },
  { geneTranscript: "NM_001011655.3", parsimonySitesP: "0.244548286604361", constantSitesP: "0.598130841121495", distinctSitesP: "0.372274143302181", brLenTotal: "0.9655" },
  { geneTranscript: "NM_173500.4", parsimonySitesP: "0.167738478027867", constantSitesP: "0.695337620578778", distinctSitesP: "0.229367631296892", brLenTotal: "0.5163" },
  { geneTranscript: "NM_018052.5", parsimonySitesP: "0.176470588235294", constantSitesP: "0.72463768115942", distinctSitesP: "0.245098039215686", brLenTotal: "0.8001" },
  { geneTranscript: "NM_001372163.1", parsimonySitesP: "0.181818181818182", constantSitesP: "0.705627705627706", distinctSitesP: "0.271284271284271", brLenTotal: "0.6899" },
  { geneTranscript: "NM_016557.4", parsimonySitesP: "0.220952380952381", constantSitesP: "0.632380952380952", distinctSitesP: "0.344761904761905", brLenTotal: "0.7052" },
  { geneTranscript: "NM_005654.6", parsimonySitesP: "0.0378250591016548", constantSitesP: "0.867612293144208", distinctSitesP: "0.103230890464933", brLenTotal: "0.2286" },
  { geneTranscript: "NM_014668.4", parsimonySitesP: "0.227296049256029", constantSitesP: "0.650761074055071", distinctSitesP: "0.297417479049085", brLenTotal: "1.0251" },
  { geneTranscript: "NM_021948.5", parsimonySitesP: "0.222832052689352", constantSitesP: "0.641785583607757", distinctSitesP: "0.298207098426637", brLenTotal: "0.7951" },
  { geneTranscript: "NM_058192.3", parsimonySitesP: "0.27991452991453", constantSitesP: "0.579059829059829", distinctSitesP: "0.413461538461538", brLenTotal: "1.4070" },
  { geneTranscript: "NM_000792.7", parsimonySitesP: "0.231593038821954", constantSitesP: "0.627844712182062", distinctSitesP: "0.321285140562249", brLenTotal: "0.7574" },
  { geneTranscript: "NM_024057.4", parsimonySitesP: "0.157464212678937", constantSitesP: "0.739263803680982", distinctSitesP: "0.247443762781186", brLenTotal: "0.5175" },
  { geneTranscript: "NM_004606.5", parsimonySitesP: "0.120840007118704", constantSitesP: "0.777540487631251", distinctSitesP: "0.153052144509699", brLenTotal: "0.3979" },
  { geneTranscript: "NM_017423.3", parsimonySitesP: "0.168442415017757", constantSitesP: "0.73871131405378", distinctSitesP: "0.199898528665652", brLenTotal: "0.5437" },
  { geneTranscript: "NM_001371986.1", parsimonySitesP: "0.152527075812274", constantSitesP: "0.756016847172082", distinctSitesP: "0.167769755314882", brLenTotal: "0.5654" },
  { geneTranscript: "NM_182810.3", parsimonySitesP: "0.241215574548908", constantSitesP: "0.58974358974359", distinctSitesP: "0.389363722697056", brLenTotal: "0.8295" },
  { geneTranscript: "NM_181776.3", parsimonySitesP: "0.28088336783989", constantSitesP: "0.565907522429262", distinctSitesP: "0.407867494824017", brLenTotal: "1.2564" },
  { geneTranscript: "NM_020412.5", parsimonySitesP: "0.139028475711893", constantSitesP: "0.721943048576214", distinctSitesP: "0.231155778894472", brLenTotal: "0.5031" },
  { geneTranscript: "NM_153356.3", parsimonySitesP: "0.214285714285714", constantSitesP: "0.672619047619048", distinctSitesP: "0.307539682539683", brLenTotal: "0.7912" },
  { geneTranscript: "NM_020962.3", parsimonySitesP: "0.247466666666667", constantSitesP: "0.596533333333333", distinctSitesP: "0.342933333333333", brLenTotal: "0.9841" },
  { geneTranscript: "NM_001440.4", parsimonySitesP: "0.191512513601741", constantSitesP: "0.725426187885383", distinctSitesP: "0.229960101559666", brLenTotal: "0.8239" },
  { geneTranscript: "NM_172070.4", parsimonySitesP: "0.115112994350282", constantSitesP: "0.799435028248588", distinctSitesP: "0.145833333333333", brLenTotal: "0.3595" },
  { geneTranscript: "NM_020632.3", parsimonySitesP: "0.23452380952381", constantSitesP: "0.667063492063492", distinctSitesP: "0.304761904761905", brLenTotal: "1.0065" },
  { geneTranscript: "NM_005685.4", parsimonySitesP: "0.190324858757062", constantSitesP: "0.692090395480226", distinctSitesP: "0.258827683615819", brLenTotal: "0.8076" },
  { geneTranscript: "NM_017621.4", parsimonySitesP: "0.275938189845475", constantSitesP: "0.592715231788079", distinctSitesP: "0.370860927152318", brLenTotal: "1.1687" },
  { geneTranscript: "NM_002245.4", parsimonySitesP: "0.154761904761905", constantSitesP: "0.744047619047619", distinctSitesP: "0.242063492063492", brLenTotal: "0.6300" },
  { geneTranscript: "NM_005159.5", parsimonySitesP: "0.184792219274978", constantSitesP: "0.735632183908046", distinctSitesP: "0.238726790450928", brLenTotal: "0.9763" },
  { geneTranscript: "NM_021944.4", parsimonySitesP: "0.174721189591078", constantSitesP: "0.703841387856258", distinctSitesP: "0.239157372986369", brLenTotal: "0.5706" },
  { geneTranscript: "NM_173828.5", parsimonySitesP: "0.194719471947195", constantSitesP: "0.675467546754675", distinctSitesP: "0.288228822882288", brLenTotal: "0.6139" },
  { geneTranscript: "NM_001394796.1", parsimonySitesP: "0.126769626769627", constantSitesP: "0.767696267696268", distinctSitesP: "0.193050193050193", brLenTotal: "0.4940" },
  { geneTranscript: "NM_016025.5", parsimonySitesP: "0.0849056603773585", constantSitesP: "0.843815513626834", distinctSitesP: "0.132075471698113", brLenTotal: "0.2775" },
  { geneTranscript: "NM_025216.3", parsimonySitesP: "0.155875299760192", constantSitesP: "0.756994404476419", distinctSitesP: "0.20863309352518", brLenTotal: "0.6504" },
  { geneTranscript: "NM_002240.5", parsimonySitesP: "0.191489361702128", constantSitesP: "0.73364854215918", distinctSitesP: "0.226162332545311", brLenTotal: "0.7461" },
  { geneTranscript: "NM_001366028.2", parsimonySitesP: "0.16473063973064", constantSitesP: "0.71506734006734", distinctSitesP: "0.224915824915825", brLenTotal: "0.5514" },
  { geneTranscript: "NM_001707.4", parsimonySitesP: "0.155115511551155", constantSitesP: "0.734323432343234", distinctSitesP: "0.240924092409241", brLenTotal: "0.5088" },
  { geneTranscript: "NM_014231.5", parsimonySitesP: "0.192090395480226", constantSitesP: "0.720338983050847", distinctSitesP: "0.245762711864407", brLenTotal: "0.5611" },
  { geneTranscript: "NM_004704.5", parsimonySitesP: "0.236491228070175", constantSitesP: "0.656140350877193", distinctSitesP: "0.306666666666667", brLenTotal: "0.9157" },
  { geneTranscript: "NM_000310.4", parsimonySitesP: "0.208061002178649", constantSitesP: "0.664488017429194", distinctSitesP: "0.321350762527233", brLenTotal: "0.6726" },
  { geneTranscript: "NM_015675.4", parsimonySitesP: "0.185416666666667", constantSitesP: "0.70625", distinctSitesP: "0.272916666666667", brLenTotal: "0.7897" },
  { geneTranscript: "NM_001256447.2", parsimonySitesP: "0.219512195121951", constantSitesP: "0.642276422764228", distinctSitesP: "0.333333333333333", brLenTotal: "0.7106" },
  { geneTranscript: "NM_001071775.3", parsimonySitesP: "0.170731707317073", constantSitesP: "0.752032520325203", distinctSitesP: "0.276422764227642", brLenTotal: "0.5250" },
  { geneTranscript: "NM_005011.5", parsimonySitesP: "0.115970841616965", constantSitesP: "0.797216699801193", distinctSitesP: "0.170311464546057", brLenTotal: "0.4622" },
  { geneTranscript: "NM_022781.5", parsimonySitesP: "0.0757281553398058", constantSitesP: "0.849838187702265", distinctSitesP: "0.127508090614887", brLenTotal: "0.2409" },
  { geneTranscript: "NM_001031628.2", parsimonySitesP: "0.168384879725086", constantSitesP: "0.70446735395189", distinctSitesP: "0.316151202749141", brLenTotal: "0.5461" },
  { geneTranscript: "NM_013326.5", parsimonySitesP: "0.168949771689498", constantSitesP: "0.737696600710299", distinctSitesP: "0.235920852359209", brLenTotal: "0.7498" },
  { geneTranscript: "NM_020435.4", parsimonySitesP: "0.26879271070615", constantSitesP: "0.563401670463174", distinctSitesP: "0.396355353075171", brLenTotal: "1.3496" },
  { geneTranscript: "NM_005762.3", parsimonySitesP: "0.185229540918164", constantSitesP: "0.711377245508982", distinctSitesP: "0.249900199600798", brLenTotal: "0.6950" },
  { geneTranscript: "NM_003338.5", parsimonySitesP: "0.108843537414966", constantSitesP: "0.841269841269841", distinctSitesP: "0.165532879818594", brLenTotal: "0.2681" },
  { geneTranscript: "NM_000952.5", parsimonySitesP: "0.246588693957115", constantSitesP: "0.637426900584795", distinctSitesP: "0.325536062378168", brLenTotal: "0.9690" },
  { geneTranscript: "NM_001272004.3", parsimonySitesP: "0.1230012300123", constantSitesP: "0.776547765477655", distinctSitesP: "0.173021730217302", brLenTotal: "0.4006" },
  { geneTranscript: "NM_080748.3", parsimonySitesP: "0.0928270042194093", constantSitesP: "0.814345991561181", distinctSitesP: "0.189873417721519", brLenTotal: "0.3183" },
  { geneTranscript: "NM_001365892.2", parsimonySitesP: "0.35240464344942", constantSitesP: "0.446102819237148", distinctSitesP: "0.550580431177446", brLenTotal: "1.3218" },
  { geneTranscript: "NM_032415.7", parsimonySitesP: "0.194974003466205", constantSitesP: "0.711149624494512", distinctSitesP: "0.241767764298094", brLenTotal: "0.8482" },
  { geneTranscript: "NM_006213.5", parsimonySitesP: "0.211024978466839", constantSitesP: "0.691645133505599", distinctSitesP: "0.289405684754522", brLenTotal: "0.9268" },
  { geneTranscript: "NM_144631.6", parsimonySitesP: "0.104128157732594", constantSitesP: "0.741836105976587", distinctSitesP: "0.156500308071473", brLenTotal: "0.5178" },
  { geneTranscript: "NM_003255.5", parsimonySitesP: "0.133333333333333", constantSitesP: "0.768181818181818", distinctSitesP: "0.225757575757576", brLenTotal: "0.5980" },
  { geneTranscript: "NM_024007.5", parsimonySitesP: "0.0891144952058658", constantSitesP: "0.865200225606317", distinctSitesP: "0.125211505922166", brLenTotal: "0.3444" },
  { geneTranscript: "NM_004454.3", parsimonySitesP: "0.115686274509804", constantSitesP: "0.749019607843137", distinctSitesP: "0.199346405228758", brLenTotal: "0.4746" },
  { geneTranscript: "NM_001290060.2", parsimonySitesP: "0.242545616377392", constantSitesP: "0.623943035157988", distinctSitesP: "0.287494437027147", brLenTotal: "0.9155" },
  { geneTranscript: "NM_004004.6", parsimonySitesP: "0.221238938053097", constantSitesP: "0.647492625368732", distinctSitesP: "0.305309734513274", brLenTotal: "1.2642" },
  { geneTranscript: "NM_018924.5", parsimonySitesP: "0.517043415859347", constantSitesP: "0.315751704341586", distinctSitesP: "0.683530678148547", brLenTotal: "3.3678" },
  { geneTranscript: "NM_005284.5", parsimonySitesP: "0.194290976058932", constantSitesP: "0.707182320441989", distinctSitesP: "0.262430939226519", brLenTotal: "0.7421" },
  { geneTranscript: "NM_015122.3", parsimonySitesP: "0.246344206974128", constantSitesP: "0.640044994375703", distinctSitesP: "0.33708286464192", brLenTotal: "1.1591" },
  { geneTranscript: "NM_175054.2", parsimonySitesP: "0.297734627831715", constantSitesP: "0.647249190938511", distinctSitesP: "0.375404530744337", brLenTotal: "2.6241" },
  { geneTranscript: "NM_024104.4", parsimonySitesP: "0.142222222222222", constantSitesP: "0.742222222222222", distinctSitesP: "0.284444444444444", brLenTotal: "0.5270" },
  { geneTranscript: "NM_022762.5", parsimonySitesP: "0.18490245971162", constantSitesP: "0.696352841391009", distinctSitesP: "0.257845631891433", brLenTotal: "0.7004" },
  { geneTranscript: "NM_001080500.4", parsimonySitesP: "0.0645645645645646", constantSitesP: "0.866366366366366", distinctSitesP: "0.126126126126126", brLenTotal: "0.2142" },
  { geneTranscript: "NM_024632.6", parsimonySitesP: "0.0965391621129326", constantSitesP: "0.828779599271403", distinctSitesP: "0.169398907103825", brLenTotal: "0.3406" },
  { geneTranscript: "NM_000381.4", parsimonySitesP: "0.111944027986007", constantSitesP: "0.819090454772614", distinctSitesP: "0.151424287856072", brLenTotal: "0.3782" },
  { geneTranscript: "NM_181608.2", parsimonySitesP: "0.532051282051282", constantSitesP: "0.314102564102564", distinctSitesP: "0.717948717948718", brLenTotal: "2.0063" },
  { geneTranscript: "NM_022659.4", parsimonySitesP: "0.180289855072464", constantSitesP: "0.743768115942029", distinctSitesP: "0.219710144927536", brLenTotal: "0.7999" },
  { geneTranscript: "NM_015570.4", parsimonySitesP: "0.126025946518401", constantSitesP: "0.773365104580355", distinctSitesP: "0.171299973523961", brLenTotal: "0.4926" },
  { geneTranscript: "NM_001005499.1", parsimonySitesP: "0.594017094017094", constantSitesP: "0.302350427350427", distinctSitesP: "0.708333333333333", brLenTotal: "4.1973" },
  { geneTranscript: "NM_152374.2", parsimonySitesP: "0.212518195050946", constantSitesP: "0.636098981077147", distinctSitesP: "0.347889374090247", brLenTotal: "0.7864" },
  { geneTranscript: "NM_153333.3", parsimonySitesP: "0.162393162393162", constantSitesP: "0.603988603988604", distinctSitesP: "0.447293447293447", brLenTotal: "0.6437" },
  { geneTranscript: "NM_006004.4", parsimonySitesP: "0.168498168498168", constantSitesP: "0.666666666666667", distinctSitesP: "0.366300366300366", brLenTotal: "0.6066" },
  { geneTranscript: "NM_000443.4", parsimonySitesP: "0.222569715923899", constantSitesP: "0.650508209538702", distinctSitesP: "0.302580140734949", brLenTotal: "0.9158" },
  { geneTranscript: "NM_201589.4", parsimonySitesP: "0.166194523135033", constantSitesP: "0.636449480642115", distinctSitesP: "0.30028328611898", brLenTotal: "0.9296" },
  { geneTranscript: "NM_002005.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.683292781832928", distinctSitesP: "0.278588807785888", brLenTotal: "0.8994" },
  { geneTranscript: "NM_001040429.3", parsimonySitesP: "0.143226919758412", constantSitesP: "0.771642220304861", distinctSitesP: "0.176876617773943", brLenTotal: "0.5054" },
  { geneTranscript: "NM_052848.3", parsimonySitesP: "0.26530612244898", constantSitesP: "0.619047619047619", distinctSitesP: "0.356656948493683", brLenTotal: "1.0316" },
  { geneTranscript: "NM_015378.4", parsimonySitesP: "0.169629292008508", constantSitesP: "0.715360072926162", distinctSitesP: "0.18482224247949", brLenTotal: "0.5939" },
  { geneTranscript: "NM_002767.4", parsimonySitesP: "0.168021680216802", constantSitesP: "0.742547425474255", distinctSitesP: "0.232158988256549", brLenTotal: "0.6837" },
  { geneTranscript: "NM_001195076.2", parsimonySitesP: "0.281144781144781", constantSitesP: "0.545454545454545", distinctSitesP: "0.420875420875421", brLenTotal: "0.9796" },
  { geneTranscript: "NM_004941.3", parsimonySitesP: "0.157650273224044", constantSitesP: "0.72896174863388", distinctSitesP: "0.199726775956284", brLenTotal: "0.5674" },
  { geneTranscript: "NM_080627.4", parsimonySitesP: "0.220951234196267", constantSitesP: "0.663255067228577", distinctSitesP: "0.273931366646598", brLenTotal: "0.9516" },
  { geneTranscript: "NM_024333.3", parsimonySitesP: "0.180779569892473", constantSitesP: "0.723790322580645", distinctSitesP: "0.245295698924731", brLenTotal: "0.8430" },
  { geneTranscript: "NM_018034.4", parsimonySitesP: "0.13914373088685", constantSitesP: "0.771151885830785", distinctSitesP: "0.211009174311927", brLenTotal: "0.4838" },
  { geneTranscript: "NM_130769.4", parsimonySitesP: "0.214470284237726", constantSitesP: "0.65374677002584", distinctSitesP: "0.328165374677003", brLenTotal: "0.6816" },
  { geneTranscript: "NM_006185.4", parsimonySitesP: "0.228684003152088", constantSitesP: "0.621118991331757", distinctSitesP: "0.241292356185973", brLenTotal: "0.7902" },
  { geneTranscript: "NM_001537.4", parsimonySitesP: "0.206140350877193", constantSitesP: "0.644736842105263", distinctSitesP: "0.359649122807018", brLenTotal: "0.6499" },
  { geneTranscript: "NM_016237.5", parsimonySitesP: "0.142163355408389", constantSitesP: "0.781015452538631", distinctSitesP: "0.188520971302428", brLenTotal: "0.4608" },
  { geneTranscript: "NM_025219.3", parsimonySitesP: "0.161616161616162", constantSitesP: "0.767676767676768", distinctSitesP: "0.223905723905724", brLenTotal: "0.7655" },
  { geneTranscript: "NM_006109.5", parsimonySitesP: "0.118785975928833", constantSitesP: "0.788069073783359", distinctSitesP: "0.179487179487179", brLenTotal: "0.3884" },
  { geneTranscript: "NM_152288.3", parsimonySitesP: "0.19774011299435", constantSitesP: "0.664406779661017", distinctSitesP: "0.298305084745763", brLenTotal: "0.7576" },
  { geneTranscript: "NM_018247.4", parsimonySitesP: "0.156048014773777", constantSitesP: "0.754385964912281", distinctSitesP: "0.225300092336103", brLenTotal: "0.4621" },
  { geneTranscript: "NM_006586.5", parsimonySitesP: "0.164268585131894", constantSitesP: "0.702637889688249", distinctSitesP: "0.286570743405276", brLenTotal: "0.6345" },
  { geneTranscript: "NM_130811.4", parsimonySitesP: "0.0631067961165049", constantSitesP: "0.880258899676375", distinctSitesP: "0.119741100323625", brLenTotal: "0.2397" },
  { geneTranscript: "NM_015629.4", parsimonySitesP: "0.169672678690715", constantSitesP: "0.745490981963928", distinctSitesP: "0.224448897795591", brLenTotal: "0.8871" },
  { geneTranscript: "NM_152635.3", parsimonySitesP: "0.195107033639144", constantSitesP: "0.688073394495413", distinctSitesP: "0.269113149847095", brLenTotal: "0.7398" },
  { geneTranscript: "NM_000290.4", parsimonySitesP: "0.181818181818182", constantSitesP: "0.708827404479578", distinctSitesP: "0.256916996047431", brLenTotal: "0.8761" },
  { geneTranscript: "NM_020807.3", parsimonySitesP: "0.180412371134021", constantSitesP: "0.737113402061856", distinctSitesP: "0.219931271477663", brLenTotal: "0.9104" },
  { geneTranscript: "NM_080386.4", parsimonySitesP: "0.291851851851852", constantSitesP: "0.554814814814815", distinctSitesP: "0.419259259259259", brLenTotal: "2.3918" },
  { geneTranscript: "NM_000502.6", parsimonySitesP: "0.261538461538462", constantSitesP: "0.578088578088578", distinctSitesP: "0.365967365967366", brLenTotal: "1.1248" },
  { geneTranscript: "NM_022483.5", parsimonySitesP: "0.172093023255814", constantSitesP: "0.703875968992248", distinctSitesP: "0.258914728682171", brLenTotal: "0.6093" },
  { geneTranscript: "NM_001367801.1", parsimonySitesP: "0.212310437109723", constantSitesP: "0.648528099910794", distinctSitesP: "0.320249776984835", brLenTotal: "0.6558" },
  { geneTranscript: "NM_001202429.2", parsimonySitesP: "0.214698162729659", constantSitesP: "0.667716535433071", distinctSitesP: "0.270341207349081", brLenTotal: "0.9551" },
  { geneTranscript: "NM_014940.4", parsimonySitesP: "0.216331505179768", constantSitesP: "0.663010359536868", distinctSitesP: "0.30164533820841", brLenTotal: "0.8938" },
  { geneTranscript: "NM_001201427.2", parsimonySitesP: "0.158551810237203", constantSitesP: "0.726591760299625", distinctSitesP: "0.214419475655431", brLenTotal: "0.6800" },
  { geneTranscript: "NM_020197.3", parsimonySitesP: "0.13779830638953", constantSitesP: "0.730561970746728", distinctSitesP: "0.235565819861432", brLenTotal: "0.5269" },
  { geneTranscript: "NM_152470.3", parsimonySitesP: "0.0809248554913295", constantSitesP: "0.842003853564547", distinctSitesP: "0.133911368015414", brLenTotal: "0.3089" },
  { geneTranscript: "NM_007364.4", parsimonySitesP: "0.247311827956989", constantSitesP: "0.640552995391705", distinctSitesP: "0.370199692780338", brLenTotal: "1.0156" },
  { geneTranscript: "NM_138414.3", parsimonySitesP: "0.174061433447099", constantSitesP: "0.703071672354949", distinctSitesP: "0.295790671217292", brLenTotal: "0.8009" },
  { geneTranscript: "NM_015459.5", parsimonySitesP: "0.14849044978435", constantSitesP: "0.731977818853974", distinctSitesP: "0.231053604436229", brLenTotal: "0.5105" },
  { geneTranscript: "NM_001382637.1", parsimonySitesP: "0.176848874598071", constantSitesP: "0.697391925687746", distinctSitesP: "0.288317256162915", brLenTotal: "0.8998" },
  { geneTranscript: "NM_207336.3", parsimonySitesP: "0.24593837535014", constantSitesP: "0.5703081232493", distinctSitesP: "0.366946778711485", brLenTotal: "0.9220" },
  { geneTranscript: "NM_005246.4", parsimonySitesP: "0.141930251419303", constantSitesP: "0.75750202757502", distinctSitesP: "0.21492295214923", brLenTotal: "0.4593" },
  { geneTranscript: "NM_014865.4", parsimonySitesP: "0.217701641684511", constantSitesP: "0.618605757792053", distinctSitesP: "0.291934332619557", brLenTotal: "0.7620" },
  { geneTranscript: "NM_080927.4", parsimonySitesP: "0.188387096774194", constantSitesP: "0.683870967741935", distinctSitesP: "0.276559139784946", brLenTotal: "0.6363" },
  { geneTranscript: "NM_001025356.3", parsimonySitesP: "0.165567765567766", constantSitesP: "0.724542124542125", distinctSitesP: "0.248717948717949", brLenTotal: "0.5752" },
  { geneTranscript: "NM_138771.4", parsimonySitesP: "0.116666666666667", constantSitesP: "0.80952380952381", distinctSitesP: "0.185714285714286", brLenTotal: "0.3238" },
  { geneTranscript: "NM_152390.3", parsimonySitesP: "0.108866442199776", constantSitesP: "0.822671156004489", distinctSitesP: "0.180695847362514", brLenTotal: "0.3451" },
  { geneTranscript: "NM_203487.3", parsimonySitesP: "0.101589867960119", constantSitesP: "0.701158717326866", distinctSitesP: "0.142818647264888", brLenTotal: "0.8494" },
  { geneTranscript: "NM_005371.6", parsimonySitesP: "0.170289855072464", constantSitesP: "0.683574879227053", distinctSitesP: "0.271739130434783", brLenTotal: "0.5935" },
  { geneTranscript: "NM_001278669.2", parsimonySitesP: "0.32661717921527", constantSitesP: "0.55638034641216", distinctSitesP: "0.408624955814776", brLenTotal: "1.7132" },
  { geneTranscript: "NM_001005273.3", parsimonySitesP: "0.1365", constantSitesP: "0.766833333333333", distinctSitesP: "0.162333333333333", brLenTotal: "0.4897" },
  { geneTranscript: "NM_000162.5", parsimonySitesP: "0.156272401433692", constantSitesP: "0.757706093189964", distinctSitesP: "0.207885304659498", brLenTotal: "0.7215" },
  { geneTranscript: "NM_001005167.2", parsimonySitesP: "0.559105431309904", constantSitesP: "0.300319488817891", distinctSitesP: "0.685835995740149", brLenTotal: "3.6336" },
  { geneTranscript: "NM_145117.5", parsimonySitesP: "0.160148209139564", constantSitesP: "0.731988472622478", distinctSitesP: "0.192671881432688", brLenTotal: "0.6618" },
  { geneTranscript: "NM_012124.3", parsimonySitesP: "0.17570281124498", constantSitesP: "0.705823293172691", distinctSitesP: "0.300200803212851", brLenTotal: "0.6287" },
  { geneTranscript: "NM_033387.4", parsimonySitesP: "0.190812720848057", constantSitesP: "0.651354534746761", distinctSitesP: "0.279151943462898", brLenTotal: "1.0881" },
  { geneTranscript: "NM_005934.4", parsimonySitesP: "0.160405485986881", constantSitesP: "0.732259988073942", distinctSitesP: "0.233154442456768", brLenTotal: "0.8820" },
  { geneTranscript: "NM_014878.5", parsimonySitesP: "0.203703703703704", constantSitesP: "0.625", distinctSitesP: "0.347736625514403", brLenTotal: "0.7794" },
  { geneTranscript: "NM_001366737.1", parsimonySitesP: "0.188373804267844", constantSitesP: "0.669610007358352", distinctSitesP: "0.293598233995585", brLenTotal: "0.6633" },
  { geneTranscript: "NM_020689.4", parsimonySitesP: "0.175465838509317", constantSitesP: "0.734989648033126", distinctSitesP: "0.237060041407867", brLenTotal: "0.7247" },
  { geneTranscript: "NM_001135036.2", parsimonySitesP: "0.174055829228243", constantSitesP: "0.681444991789819", distinctSitesP: "0.32183908045977", brLenTotal: "0.5732" },
  { geneTranscript: "NM_006497.4", parsimonySitesP: "0.184873949579832", constantSitesP: "0.736227824463119", distinctSitesP: "0.221288515406162", brLenTotal: "0.6550" },
  { geneTranscript: "NM_001080430.4", parsimonySitesP: "0.165509259259259", constantSitesP: "0.730324074074074", distinctSitesP: "0.237847222222222", brLenTotal: "0.6246" },
  { geneTranscript: "NM_175575.6", parsimonySitesP: "0.217592592592593", constantSitesP: "0.672453703703704", distinctSitesP: "0.278356481481481", brLenTotal: "1.0545" },
  { geneTranscript: "NM_012141.3", parsimonySitesP: "0.138669673055242", constantSitesP: "0.76249530251785", distinctSitesP: "0.190905674558437", brLenTotal: "0.5000" },
  { geneTranscript: "NM_001376000.2", parsimonySitesP: "0.129957805907173", constantSitesP: "0.770464135021097", distinctSitesP: "0.236286919831224", brLenTotal: "0.4080" },
  { geneTranscript: "NM_020708.5", parsimonySitesP: "0.136200716845878", constantSitesP: "0.760752688172043", distinctSitesP: "0.181899641577061", brLenTotal: "0.5490" },
  { geneTranscript: "NM_139315.3", parsimonySitesP: "0.178237321516494", constantSitesP: "0.732151649433776", distinctSitesP: "0.228458887247661", brLenTotal: "0.8175" },
  { geneTranscript: "NM_001253.4", parsimonySitesP: "0.123441396508728", constantSitesP: "0.78428927680798", distinctSitesP: "0.177472984206151", brLenTotal: "0.3944" },
  { geneTranscript: "NM_207308.2", parsimonySitesP: "0.217161016949153", constantSitesP: "0.626235875706215", distinctSitesP: "0.284604519774011", brLenTotal: "0.6626" },
  { geneTranscript: "NM_006519.4", parsimonySitesP: "0.179941002949853", constantSitesP: "0.660766961651917", distinctSitesP: "0.339233038348083", brLenTotal: "0.9011" },
  { geneTranscript: "NM_178161.3", parsimonySitesP: "0.205284552845528", constantSitesP: "0.601626016260163", distinctSitesP: "0.361788617886179", brLenTotal: "0.8641" },
  { geneTranscript: "NM_002226.5", parsimonySitesP: "0.239095315024233", constantSitesP: "0.664512654819602", distinctSitesP: "0.290791599353796", brLenTotal: "1.2534" },
  { geneTranscript: "NM_019058.4", parsimonySitesP: "0.172413793103448", constantSitesP: "0.705459770114943", distinctSitesP: "0.260057471264368", brLenTotal: "0.5232" },
  { geneTranscript: "NM_001385012.1", parsimonySitesP: "0.12931131333558", constantSitesP: "0.778451859341647", distinctSitesP: "0.151780698797888", brLenTotal: "0.4592" },
  { geneTranscript: "NM_152594.3", parsimonySitesP: "0.0938438438438438", constantSitesP: "0.785285285285285", distinctSitesP: "0.180930930930931", brLenTotal: "0.3373" },
  { geneTranscript: "NM_002267.4", parsimonySitesP: "0.0940499040307102", constantSitesP: "0.838131797824696", distinctSitesP: "0.157389635316699", brLenTotal: "0.2884" },
  { geneTranscript: "NM_005399.5", parsimonySitesP: "0.102941176470588", constantSitesP: "0.814950980392157", distinctSitesP: "0.183823529411765", brLenTotal: "0.3359" },
  { geneTranscript: "NM_001001919.1", parsimonySitesP: "0.552410901467505", constantSitesP: "0.274633123689727", distinctSitesP: "0.724318658280922", brLenTotal: "4.4845" },
  { geneTranscript: "NM_014392.5", parsimonySitesP: "0.165765765765766", constantSitesP: "0.726126126126126", distinctSitesP: "0.259459459459459", brLenTotal: "0.7173" },
  { geneTranscript: "NM_003690.5", parsimonySitesP: "0.0915867944621938", constantSitesP: "0.821086261980831", distinctSitesP: "0.161874334398296", brLenTotal: "0.2852" },
  { geneTranscript: "NM_001323311.2", parsimonySitesP: "0.109510086455331", constantSitesP: "0.782901056676273", distinctSitesP: "0.168107588856868", brLenTotal: "0.4754" },
  { geneTranscript: "NM_022170.2", parsimonySitesP: "0.135752688172043", constantSitesP: "0.780913978494624", distinctSitesP: "0.217741935483871", brLenTotal: "0.4524" },
  { geneTranscript: "NM_001394583.1", parsimonySitesP: "0.186422413793103", constantSitesP: "0.692887931034483", distinctSitesP: "0.283045977011494", brLenTotal: "0.8421" },
  { geneTranscript: "NM_014663.3", parsimonySitesP: "0.155701754385965", constantSitesP: "0.730576441102757", distinctSitesP: "0.206453634085213", brLenTotal: "0.6058" },
  { geneTranscript: "NM_014819.5", parsimonySitesP: "0.18879472693032", constantSitesP: "0.652542372881356", distinctSitesP: "0.313559322033898", brLenTotal: "0.7068" },
  { geneTranscript: "NM_001384657.1", parsimonySitesP: "0.24293310943185", constantSitesP: "0.589700531766023", distinctSitesP: "0.336971732437727", brLenTotal: "0.8388" },
  { geneTranscript: "NM_016466.6", parsimonySitesP: "0.242258652094718", constantSitesP: "0.633879781420765", distinctSitesP: "0.336976320582878", brLenTotal: "0.9085" },
  { geneTranscript: "NM_005669.5", parsimonySitesP: "0.142857142857143", constantSitesP: "0.760141093474427", distinctSitesP: "0.225749559082892", brLenTotal: "0.4989" },
  { geneTranscript: "NM_019897.2", parsimonySitesP: "0.444096133751306", constantSitesP: "0.355276907001045", distinctSitesP: "0.602925809822362", brLenTotal: "3.4214" },
  { geneTranscript: "NM_004957.6", parsimonySitesP: "0.241340147643384", constantSitesP: "0.613855763770585", distinctSitesP: "0.323679727427598", brLenTotal: "0.9743" },
  { geneTranscript: "NM_153633.3", parsimonySitesP: "0.0353535353535354", constantSitesP: "0.925505050505051", distinctSitesP: "0.0795454545454545", brLenTotal: "0.0987" },
  { geneTranscript: "NM_007256.5", parsimonySitesP: "0.291020216267043", constantSitesP: "0.563234602726845", distinctSitesP: "0.445698166431594", brLenTotal: "1.0399" },
  { geneTranscript: "NM_153612.4", parsimonySitesP: "0.134874759152216", constantSitesP: "0.763969171483622", distinctSitesP: "0.186897880539499", brLenTotal: "0.5249" },
  { geneTranscript: "NM_001348484.3", parsimonySitesP: "0.106824310309065", constantSitesP: "0.792574154739681", distinctSitesP: "0.152872847956855", brLenTotal: "0.3666" },
  { geneTranscript: "NM_007197.4", parsimonySitesP: "0.184165232358003", constantSitesP: "0.700516351118761", distinctSitesP: "0.248422260470453", brLenTotal: "0.6939" },
  { geneTranscript: "NM_001350175.2", parsimonySitesP: "0.151635720601238", constantSitesP: "0.733863837312113", distinctSitesP: "0.209549071618037", brLenTotal: "0.4930" },
  { geneTranscript: "NM_021727.5", parsimonySitesP: "0.206741573033708", constantSitesP: "0.677153558052434", distinctSitesP: "0.277902621722846", brLenTotal: "0.8301" },
  { geneTranscript: "NM_002541.4", parsimonySitesP: "0.171717171717172", constantSitesP: "0.73900293255132", distinctSitesP: "0.203649397197784", brLenTotal: "0.7744" },
  { geneTranscript: "NM_139286.4", parsimonySitesP: "0.172549019607843", constantSitesP: "0.690196078431373", distinctSitesP: "0.298039215686275", brLenTotal: "0.5350" },
  { geneTranscript: "NM_000661.5", parsimonySitesP: "0.142361111111111", constantSitesP: "0.703125", distinctSitesP: "0.28125", brLenTotal: "0.5646" },
  { geneTranscript: "NM_001005237.1", parsimonySitesP: "0.500519210799585", constantSitesP: "0.339563862928349", distinctSitesP: "0.651090342679128", brLenTotal: "2.2637" },
  { geneTranscript: "NM_000140.5", parsimonySitesP: "0.213553979511426", constantSitesP: "0.650118203309693", distinctSitesP: "0.334909377462569", brLenTotal: "0.8393" },
  { geneTranscript: "NM_002496.4", parsimonySitesP: "0.226984126984127", constantSitesP: "0.673015873015873", distinctSitesP: "0.323809523809524", brLenTotal: "0.9808" },
  { geneTranscript: "NM_001174100.2", parsimonySitesP: "0.138130686517783", constantSitesP: "0.791563275434243", distinctSitesP: "0.187758478081059", brLenTotal: "0.5648" },
  { geneTranscript: "NM_000291.4", parsimonySitesP: "0.15427657873701", constantSitesP: "0.677058353317346", distinctSitesP: "0.279776179056755", brLenTotal: "0.6054" },
  { geneTranscript: "NM_004646.4", parsimonySitesP: "0.282299221058286", constantSitesP: "0.543916196615633", distinctSitesP: "0.397797475154445", brLenTotal: "1.1360" },
  { geneTranscript: "NM_001291867.2", parsimonySitesP: "0.1847365233192", constantSitesP: "0.689884918231375", distinctSitesP: "0.212396527357157", brLenTotal: "0.6014" },
  { geneTranscript: "NM_021243.3", parsimonySitesP: "0.17283950617284", constantSitesP: "0.732510288065844", distinctSitesP: "0.275720164609053", brLenTotal: "0.5593" },
  { geneTranscript: "NM_004162.5", parsimonySitesP: "0.165891472868217", constantSitesP: "0.714728682170543", distinctSitesP: "0.252713178294574", brLenTotal: "0.5999" },
  { geneTranscript: "NM_000811.3", parsimonySitesP: "0.203090507726269", constantSitesP: "0.667402501839588", distinctSitesP: "0.316409124356144", brLenTotal: "0.7966" },
  { geneTranscript: "NM_001005522.2", parsimonySitesP: "0.61965811965812", constantSitesP: "0.238247863247863", distinctSitesP: "0.770299145299145", brLenTotal: "5.1684" },
  { geneTranscript: "NM_030915.4", parsimonySitesP: "0.177777777777778", constantSitesP: "0.726984126984127", distinctSitesP: "0.266666666666667", brLenTotal: "0.6940" },
  { geneTranscript: "NM_005261.4", parsimonySitesP: "0.15990990990991", constantSitesP: "0.720720720720721", distinctSitesP: "0.247747747747748", brLenTotal: "0.6770" },
  { geneTranscript: "NM_001009880.2", parsimonySitesP: "0.174917491749175", constantSitesP: "0.737623762376238", distinctSitesP: "0.245874587458746", brLenTotal: "0.8085" },
  { geneTranscript: "NM_003528.3", parsimonySitesP: "0.201058201058201", constantSitesP: "0.67989417989418", distinctSitesP: "0.312169312169312", brLenTotal: "1.4141" },
  { geneTranscript: "NM_001387011.1", parsimonySitesP: "0.128916281458654", constantSitesP: "0.74191063174114", distinctSitesP: "0.187211093990755", brLenTotal: "0.4857" },
  { geneTranscript: "NM_000199.5", parsimonySitesP: "0.225099601593625", constantSitesP: "0.630146082337317", distinctSitesP: "0.316069057104914", brLenTotal: "1.1518" },
  { geneTranscript: "NM_001005280.1", parsimonySitesP: "0.468354430379747", constantSitesP: "0.408227848101266", distinctSitesP: "0.529535864978903", brLenTotal: "1.6952" },
  { geneTranscript: "NM_032012.4", parsimonySitesP: "0.166856276071293", constantSitesP: "0.722032612817596", distinctSitesP: "0.256351915054987", brLenTotal: "0.6319" },
  { geneTranscript: "NM_002714.4", parsimonySitesP: "0.171631205673759", constantSitesP: "0.729432624113475", distinctSitesP: "0.228368794326241", brLenTotal: "0.6036" },
  { geneTranscript: "NM_004062.4", parsimonySitesP: "0.306795335745879", constantSitesP: "0.533172496984318", distinctSitesP: "0.421793325291516", brLenTotal: "1.0930" },
  { geneTranscript: "NM_175053.4", parsimonySitesP: "0.315689981096408", constantSitesP: "0.530560806553245", distinctSitesP: "0.444864524259609", brLenTotal: "1.7229" },
  { geneTranscript: "NM_005917.4", parsimonySitesP: "0.13872255489022", constantSitesP: "0.712574850299401", distinctSitesP: "0.258483033932136", brLenTotal: "0.5336" },
  { geneTranscript: "NM_001028.3", parsimonySitesP: "0.152", constantSitesP: "0.64", distinctSitesP: "0.330666666666667", brLenTotal: "0.6691" },
  { geneTranscript: "NM_001278116.2", parsimonySitesP: "0.216653407584195", constantSitesP: "0.675152479448422", distinctSitesP: "0.248740387165208", brLenTotal: "0.8678" },
  { geneTranscript: "NM_004176.5", parsimonySitesP: "0.258355129322871", constantSitesP: "0.602441150828248", distinctSitesP: "0.300203429235687", brLenTotal: "1.0220" },
  { geneTranscript: "NM_014463.3", parsimonySitesP: "0.238562091503268", constantSitesP: "0.519607843137255", distinctSitesP: "0.513071895424837", brLenTotal: "0.9958" },
  { geneTranscript: "NM_005938.4", parsimonySitesP: "0.207920792079208", constantSitesP: "0.688448844884488", distinctSitesP: "0.266006600660066", brLenTotal: "0.6689" },
  { geneTranscript: "NM_000594.4", parsimonySitesP: "0.256080114449213", constantSitesP: "0.600858369098712", distinctSitesP: "0.36480686695279", brLenTotal: "0.9890" },
  { geneTranscript: "NM_018060.4", parsimonySitesP: "0.208168642951252", constantSitesP: "0.656785243741765", distinctSitesP: "0.286890645586298", brLenTotal: "0.6987" },
  { geneTranscript: "NM_001031725.6", parsimonySitesP: "0.227248249865374", constantSitesP: "0.624663435648896", distinctSitesP: "0.353257942918686", brLenTotal: "0.8142" },
  { geneTranscript: "NM_020314.7", parsimonySitesP: "0.166839736933195", constantSitesP: "0.746278989269643", distinctSitesP: "0.224299065420561", brLenTotal: "0.6603" },
  { geneTranscript: "NM_004890.3", parsimonySitesP: "0.130690161527166", constantSitesP: "0.79295154185022", distinctSitesP: "0.187958883994126", brLenTotal: "0.4097" },
  { geneTranscript: "NM_001010862.3", parsimonySitesP: "0.352713178294574", constantSitesP: "0.51421188630491", distinctSitesP: "0.421188630490956", brLenTotal: "1.0874" },
  { geneTranscript: "NM_001146108.2", parsimonySitesP: "0.252279635258359", constantSitesP: "0.581560283687943", distinctSitesP: "0.443768996960486", brLenTotal: "1.0148" },
  { geneTranscript: "NM_006295.3", parsimonySitesP: "0.209915611814346", constantSitesP: "0.679588607594937", distinctSitesP: "0.251582278481013", brLenTotal: "0.8239" },
  { geneTranscript: "NM_016146.6", parsimonySitesP: "0.144596651445967", constantSitesP: "0.741248097412481", distinctSitesP: "0.231354642313546", brLenTotal: "0.4551" },
  { geneTranscript: "NM_001372062.1", parsimonySitesP: "0.145522388059701", constantSitesP: "0.761194029850746", distinctSitesP: "0.215796019900498", brLenTotal: "0.5009" },
  { geneTranscript: "NM_020431.4", parsimonySitesP: "0.231182795698925", constantSitesP: "0.665839536807279", distinctSitesP: "0.301075268817204", brLenTotal: "0.9577" },
  { geneTranscript: "NM_002506.3", parsimonySitesP: "0.233748271092669", constantSitesP: "0.670816044260028", distinctSitesP: "0.311203319502075", brLenTotal: "1.0219" },
  { geneTranscript: "NM_021974.5", parsimonySitesP: "0.154855643044619", constantSitesP: "0.771653543307087", distinctSitesP: "0.225721784776903", brLenTotal: "0.6534" },
  { geneTranscript: "NM_001199196.2", parsimonySitesP: "0.340652029274784", constantSitesP: "0.527611443779108", distinctSitesP: "0.429141716566866", brLenTotal: "1.4516" },
  { geneTranscript: "NM_022968.2", parsimonySitesP: "0.118279569892473", constantSitesP: "0.811827956989247", distinctSitesP: "0.252688172043011", brLenTotal: "0.3367" },
  { geneTranscript: "NM_001141980.3", parsimonySitesP: "0.179396391839487", constantSitesP: "0.683190018546619", distinctSitesP: "0.254088686562131", brLenTotal: "0.5731" },
  { geneTranscript: "NM_030758.4", parsimonySitesP: "0.23216885007278", constantSitesP: "0.65429403202329", distinctSitesP: "0.297307132459971", brLenTotal: "0.9290" },
  { geneTranscript: "NM_138689.3", parsimonySitesP: "0.17687074829932", constantSitesP: "0.609977324263039", distinctSitesP: "0.401360544217687", brLenTotal: "0.9135" },
  { geneTranscript: "NM_006297.3", parsimonySitesP: "0.238546603475513", constantSitesP: "0.609794628751975", distinctSitesP: "0.32596103212217", brLenTotal: "0.9150" },
  { geneTranscript: "NM_001303037.2", parsimonySitesP: "0.121604938271605", constantSitesP: "0.796296296296296", distinctSitesP: "0.185802469135802", brLenTotal: "0.3708" },
  { geneTranscript: "NM_018340.3", parsimonySitesP: "0.287685774946921", constantSitesP: "0.59447983014862", distinctSitesP: "0.395966029723992", brLenTotal: "1.1842" },
  { geneTranscript: "NM_001256789.3", parsimonySitesP: "0.1804001356392", constantSitesP: "0.708375720583249", distinctSitesP: "0.210918955578162", brLenTotal: "0.6749" },
  { geneTranscript: "NM_001367916.1", parsimonySitesP: "0.124378109452736", constantSitesP: "0.797014925373134", distinctSitesP: "0.195024875621891", brLenTotal: "0.3575" },
  { geneTranscript: "NM_000727.4", parsimonySitesP: "0.193693693693694", constantSitesP: "0.689189189189189", distinctSitesP: "0.283783783783784", brLenTotal: "0.7795" },
  { geneTranscript: "NM_001989.5", parsimonySitesP: "0.160524160524161", constantSitesP: "0.705978705978706", distinctSitesP: "0.253890253890254", brLenTotal: "0.6513" },
  { geneTranscript: "NM_003591.4", parsimonySitesP: "0.118568232662192", constantSitesP: "0.768232662192394", distinctSitesP: "0.195973154362416", brLenTotal: "0.4661" },
  { geneTranscript: "NM_001079514.3", parsimonySitesP: "0.200176366843034", constantSitesP: "0.660787771898883", distinctSitesP: "0.268665490887713", brLenTotal: "0.7112" },
  { geneTranscript: "NM_001378090.1", parsimonySitesP: "0.255197132616487", constantSitesP: "0.529749103942652", distinctSitesP: "0.432974910394265", brLenTotal: "1.1979" },
  { geneTranscript: "NM_005151.4", parsimonySitesP: "0.123481781376518", constantSitesP: "0.776653171390013", distinctSitesP: "0.193657219973009", brLenTotal: "0.4231" },
  { geneTranscript: "NM_014452.5", parsimonySitesP: "0.168447837150127", constantSitesP: "0.704325699745547", distinctSitesP: "0.238676844783715", brLenTotal: "0.6212" },
  { geneTranscript: "NM_080683.3", parsimonySitesP: "0.181220657276995", constantSitesP: "0.670690811535882", distinctSitesP: "0.259959758551308", brLenTotal: "0.6216" },
  { geneTranscript: "NM_012235.4", parsimonySitesP: "0.204847537138389", constantSitesP: "0.679437060203284", distinctSitesP: "0.247849882720876", brLenTotal: "0.8568" },
  { geneTranscript: "NM_001048225.4", parsimonySitesP: "0.173913043478261", constantSitesP: "0.701863354037267", distinctSitesP: "0.281573498964803", brLenTotal: "0.5715" },
  { geneTranscript: "NM_006610.4", parsimonySitesP: "0.265792031098154", constantSitesP: "0.582118561710398", distinctSitesP: "0.376579203109815", brLenTotal: "1.0303" },
  { geneTranscript: "NM_015442.3", parsimonySitesP: "0.116487455197133", constantSitesP: "0.780017921146953", distinctSitesP: "0.172939068100358", brLenTotal: "0.4179" },
  { geneTranscript: "NM_032848.3", parsimonySitesP: "0.371747211895911", constantSitesP: "0.413878562577447", distinctSitesP: "0.536555142503098", brLenTotal: "1.3548" },
  { geneTranscript: "NM_004496.5", parsimonySitesP: "0.168079096045198", constantSitesP: "0.730932203389831", distinctSitesP: "0.240112994350282", brLenTotal: "0.6793" },
  { geneTranscript: "NM_006940.6", parsimonySitesP: "0.110965487112276", constantSitesP: "0.831367409349061", distinctSitesP: "0.138488422892093", brLenTotal: "0.4136" },
  { geneTranscript: "NM_030649.3", parsimonySitesP: "0.234612310151878", constantSitesP: "0.660271782573941", distinctSitesP: "0.297761790567546", brLenTotal: "1.1963" },
  { geneTranscript: "NM_014585.6", parsimonySitesP: "0.168709865732633", constantSitesP: "0.712200817279626", distinctSitesP: "0.262113251605371", brLenTotal: "0.5649" },
  { geneTranscript: "NM_014614.3", parsimonySitesP: "0.12353047567372", constantSitesP: "0.789473684210526", distinctSitesP: "0.158799059504431", brLenTotal: "0.3815" },
  { geneTranscript: "NM_021962.5", parsimonySitesP: "0.16569654637175", constantSitesP: "0.755141637563058", distinctSitesP: "0.205665502522313", brLenTotal: "0.6920" },
  { geneTranscript: "NM_001176.4", parsimonySitesP: "0.282962962962963", constantSitesP: "0.582222222222222", distinctSitesP: "0.426666666666667", brLenTotal: "1.3423" },
  { geneTranscript: "NM_032109.3", parsimonySitesP: "0.0984615384615385", constantSitesP: "0.814358974358974", distinctSitesP: "0.17025641025641", brLenTotal: "0.3823" },
  { geneTranscript: "NM_002558.4", parsimonySitesP: "0.224728487886383", constantSitesP: "0.673350041771094", distinctSitesP: "0.306599832915622", brLenTotal: "0.7974" },
  { geneTranscript: "NM_002495.4", parsimonySitesP: "0.177142857142857", constantSitesP: "0.685714285714286", distinctSitesP: "0.316190476190476", brLenTotal: "0.5873" },
  { geneTranscript: "NM_001290223.2", parsimonySitesP: "0.183633792859668", constantSitesP: "0.73683280311064", distinctSitesP: "0.232237539766702", brLenTotal: "0.7704" },
  { geneTranscript: "NM_199229.3", parsimonySitesP: "0.100877192982456", constantSitesP: "0.792397660818713", distinctSitesP: "0.182748538011696", brLenTotal: "0.3592" },
  { geneTranscript: "NM_024946.4", parsimonySitesP: "0.148293963254593", constantSitesP: "0.711286089238845", distinctSitesP: "0.272965879265092", brLenTotal: "0.5799" },
  { geneTranscript: "NM_152592.6", parsimonySitesP: "0.28957264957265", constantSitesP: "0.528547008547009", distinctSitesP: "0.39965811965812", brLenTotal: "1.2822" },
  { geneTranscript: "NM_001352702.2", parsimonySitesP: "0.122262773722628", constantSitesP: "0.791362530413625", distinctSitesP: "0.171532846715328", brLenTotal: "0.4388" },
  { geneTranscript: "NM_022464.5", parsimonySitesP: "0.225596529284165", constantSitesP: "0.640636297903109", distinctSitesP: "0.32176428054953", brLenTotal: "0.8641" },
  { geneTranscript: "NM_001365103.2", parsimonySitesP: "0.193032015065913", constantSitesP: "0.684557438794727", distinctSitesP: "0.286252354048964", brLenTotal: "0.9495" },
  { geneTranscript: "NM_004805.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.727699530516432", distinctSitesP: "0.262910798122066", brLenTotal: "0.6929" },
  { geneTranscript: "NM_145868.2", parsimonySitesP: "0.190759075907591", constantSitesP: "0.683828382838284", distinctSitesP: "0.27986798679868", brLenTotal: "0.8733" },
  { geneTranscript: "NM_020637.2", parsimonySitesP: "0.286274509803922", constantSitesP: "0.552941176470588", distinctSitesP: "0.427450980392157", brLenTotal: "1.2919" },
  { geneTranscript: "NM_025181.5", parsimonySitesP: "0.120458891013384", constantSitesP: "0.775015933715743", distinctSitesP: "0.226896112173359", brLenTotal: "0.4036" },
  { geneTranscript: "NM_015457.3", parsimonySitesP: "0.122144522144522", constantSitesP: "0.790675990675991", distinctSitesP: "0.168298368298368", brLenTotal: "0.3891" },
  { geneTranscript: "NM_001040440.3", parsimonySitesP: "0.162570888468809", constantSitesP: "0.691241335853812", distinctSitesP: "0.289224952741021", brLenTotal: "0.5109" },
  { geneTranscript: "NM_032663.5", parsimonySitesP: "0.191489361702128", constantSitesP: "0.691811734364926", distinctSitesP: "0.274016763378466", brLenTotal: "0.6580" },
  { geneTranscript: "NM_020999.4", parsimonySitesP: "0.320872274143302", constantSitesP: "0.531152647975078", distinctSitesP: "0.453271028037383", brLenTotal: "1.1497" },
  { geneTranscript: "NM_006442.4", parsimonySitesP: "0.180487804878049", constantSitesP: "0.738211382113821", distinctSitesP: "0.240650406504065", brLenTotal: "0.6674" },
  { geneTranscript: "NM_024740.2", parsimonySitesP: "0.147788565264293", constantSitesP: "0.737324703344121", distinctSitesP: "0.199568500539374", brLenTotal: "0.4733" },
  { geneTranscript: "NM_001256378.2", parsimonySitesP: "0.146354166666667", constantSitesP: "0.751041666666667", distinctSitesP: "0.202083333333333", brLenTotal: "0.4940" },
  { geneTranscript: "NM_033126.3", parsimonySitesP: "0.38008658008658", constantSitesP: "0.445887445887446", distinctSitesP: "0.485714285714286", brLenTotal: "1.4064" },
  { geneTranscript: "NM_003180.3", parsimonySitesP: "0.197754749568221", constantSitesP: "0.659758203799655", distinctSitesP: "0.278929188255613", brLenTotal: "0.7287" },
  { geneTranscript: "NM_001013699.3", parsimonySitesP: "0.520987654320988", constantSitesP: "0.254320987654321", distinctSitesP: "0.750617283950617", brLenTotal: "2.5754" },
  { geneTranscript: "NM_024598.4", parsimonySitesP: "0.212578616352201", constantSitesP: "0.630188679245283", distinctSitesP: "0.342138364779874", brLenTotal: "0.8395" },
  { geneTranscript: "NM_015997.4", parsimonySitesP: "0.192982456140351", constantSitesP: "0.664561403508772", distinctSitesP: "0.275087719298246", brLenTotal: "0.6737" },
  { geneTranscript: "NM_178425.4", parsimonySitesP: "0.109136264421578", constantSitesP: "0.805425631431244", distinctSitesP: "0.153726223885251", brLenTotal: "0.3744" },
  { geneTranscript: "NM_024036.5", parsimonySitesP: "0.179002624671916", constantSitesP: "0.722309711286089", distinctSitesP: "0.22992125984252", brLenTotal: "0.9507" },
  { geneTranscript: "NM_012276.5", parsimonySitesP: "0.455577822311289", constantSitesP: "0.319305277221109", distinctSitesP: "0.636606546426186", brLenTotal: "2.7122" },
  { geneTranscript: "NM_133264.5", parsimonySitesP: "0.135606060606061", constantSitesP: "0.747727272727273", distinctSitesP: "0.226515151515152", brLenTotal: "0.4952" },
  { geneTranscript: "NM_001098402.2", parsimonySitesP: "0.185115697310819", constantSitesP: "0.695747342088806", distinctSitesP: "0.230456535334584", brLenTotal: "0.7330" },
  { geneTranscript: "NM_013387.4", parsimonySitesP: "0.248677248677249", constantSitesP: "0.619047619047619", distinctSitesP: "0.380952380952381", brLenTotal: "0.8618" },
  { geneTranscript: "NM_001101.5", parsimonySitesP: "0.235555555555556", constantSitesP: "0.608", distinctSitesP: "0.347555555555556", brLenTotal: "1.4390" },
  { geneTranscript: "NM_001172560.3", parsimonySitesP: "0.327838827838828", constantSitesP: "0.506410256410256", distinctSitesP: "0.447802197802198", brLenTotal: "1.7253" },
  { geneTranscript: "NM_032177.4", parsimonySitesP: "0.235194585448393", constantSitesP: "0.594754653130288", distinctSitesP: "0.378172588832487", brLenTotal: "0.8003" },
  { geneTranscript: "NM_001017363.4", parsimonySitesP: "0.251618122977346", constantSitesP: "0.597087378640777", distinctSitesP: "0.365695792880259", brLenTotal: "0.9656" },
  { geneTranscript: "NM_173628.4", parsimonySitesP: "0.250784401613626", constantSitesP: "0.637158225011206", distinctSitesP: "0.301957268788286", brLenTotal: "1.1708" },
  { geneTranscript: "NM_001005388.3", parsimonySitesP: "0.147311827956989", constantSitesP: "0.770161290322581", distinctSitesP: "0.181989247311828", brLenTotal: "0.6702" },
  { geneTranscript: "NM_032514.4", parsimonySitesP: "0.09366391184573", constantSitesP: "0.848484848484848", distinctSitesP: "0.148760330578512", brLenTotal: "0.2733" },
  { geneTranscript: "NM_012414.4", parsimonySitesP: "0.170614979660206", constantSitesP: "0.712371380713089", distinctSitesP: "0.228523570232113", brLenTotal: "0.5608" },
  { geneTranscript: "NM_020245.5", parsimonySitesP: "0.18751350183625", constantSitesP: "0.706632101965867", distinctSitesP: "0.212788939295744", brLenTotal: "0.8235" },
  { geneTranscript: "NM_014743.3", parsimonySitesP: "0.135961768219833", constantSitesP: "0.730227001194743", distinctSitesP: "0.198805256869773", brLenTotal: "0.4975" },
  { geneTranscript: "NM_017686.4", parsimonySitesP: "0.152246814218645", constantSitesP: "0.757209926224011", distinctSitesP: "0.229376257545272", brLenTotal: "0.5002" },
  { geneTranscript: "NM_020791.4", parsimonySitesP: "0.102231102231102", constantSitesP: "0.815184815184815", distinctSitesP: "0.139194139194139", brLenTotal: "0.2997" },
  { geneTranscript: "NM_001042371.3", parsimonySitesP: "0.191069574247144", constantSitesP: "0.693665628245067", distinctSitesP: "0.275181723779855", brLenTotal: "0.6620" },
  { geneTranscript: "NM_001088.3", parsimonySitesP: "0.286634460547504", constantSitesP: "0.573268921095008", distinctSitesP: "0.402576489533011", brLenTotal: "1.2243" },
  { geneTranscript: "NM_001098816.3", parsimonySitesP: "0.194534729745997", constantSitesP: "0.705549536535452", distinctSitesP: "0.21066570362345", brLenTotal: "0.8052" },
  { geneTranscript: "NM_001382241.1", parsimonySitesP: "0.159048680787811", constantSitesP: "0.768115942028986", distinctSitesP: "0.180230397621702", brLenTotal: "0.7586" },
  { geneTranscript: "NM_023948.5", parsimonySitesP: "0.175886524822695", constantSitesP: "0.675177304964539", distinctSitesP: "0.268085106382979", brLenTotal: "0.6176" },
  { geneTranscript: "NM_032408.4", parsimonySitesP: "0.151719487525287", constantSitesP: "0.748258035513599", distinctSitesP: "0.187682625309058", brLenTotal: "0.4701" },
  { geneTranscript: "NM_002846.4", parsimonySitesP: "0.209397344228805", constantSitesP: "0.649302008852571", distinctSitesP: "0.287708546135512", brLenTotal: "0.7965" },
  { geneTranscript: "NM_001282957.2", parsimonySitesP: "0.23943661971831", constantSitesP: "0.627934272300469", distinctSitesP: "0.355633802816901", brLenTotal: "0.9579" },
  { geneTranscript: "NM_001330564.2", parsimonySitesP: "0.172957858997404", constantSitesP: "0.706211304174156", distinctSitesP: "0.229678450169762", brLenTotal: "0.5812" },
  { geneTranscript: "NM_004490.3", parsimonySitesP: "0.143827160493827", constantSitesP: "0.747530864197531", distinctSitesP: "0.224074074074074", brLenTotal: "0.4556" },
  { geneTranscript: "NM_005923.4", parsimonySitesP: "0.156720038816109", constantSitesP: "0.746482290150412", distinctSitesP: "0.206453178068899", brLenTotal: "0.5573" },
  { geneTranscript: "NM_003221.4", parsimonySitesP: "0.0942028985507246", constantSitesP: "0.816666666666667", distinctSitesP: "0.152898550724638", brLenTotal: "0.3657" },
  { geneTranscript: "NM_013335.4", parsimonySitesP: "0.173809523809524", constantSitesP: "0.734126984126984", distinctSitesP: "0.225396825396825", brLenTotal: "0.6706" },
  { geneTranscript: "NM_019104.3", parsimonySitesP: "0.199186991869919", constantSitesP: "0.703252032520325", distinctSitesP: "0.303523035230352", brLenTotal: "0.6944" },
  { geneTranscript: "NM_001924.4", parsimonySitesP: "0.133333333333333", constantSitesP: "0.785858585858586", distinctSitesP: "0.222222222222222", brLenTotal: "0.4507" },
  { geneTranscript: "NM_005634.3", parsimonySitesP: "0.185351270553064", constantSitesP: "0.678624813153961", distinctSitesP: "0.286248131539611", brLenTotal: "0.7736" },
  { geneTranscript: "NM_001031711.3", parsimonySitesP: "0.147126436781609", constantSitesP: "0.780459770114943", distinctSitesP: "0.209195402298851", brLenTotal: "0.6384" },
  { geneTranscript: "NM_017880.3", parsimonySitesP: "0.134146341463415", constantSitesP: "0.744483159117305", distinctSitesP: "0.199767711962834", brLenTotal: "0.5425" },
  { geneTranscript: "NM_000194.3", parsimonySitesP: "0.102446483180428", constantSitesP: "0.793577981651376", distinctSitesP: "0.195718654434251", brLenTotal: "0.3157" },
  { geneTranscript: "NM_148956.4", parsimonySitesP: "0.266809728183119", constantSitesP: "0.595135908440629", distinctSitesP: "0.378397711015737", brLenTotal: "0.9968" },
  { geneTranscript: "NM_014470.4", parsimonySitesP: "0.152298850574713", constantSitesP: "0.748563218390805", distinctSitesP: "0.237068965517241", brLenTotal: "0.5248" },
  { geneTranscript: "NM_016627.5", parsimonySitesP: "0.225925925925926", constantSitesP: "0.543518518518519", distinctSitesP: "0.421296296296296", brLenTotal: "0.9314" },
  { geneTranscript: "NM_203462.3", parsimonySitesP: "0.312335958005249", constantSitesP: "0.451443569553806", distinctSitesP: "0.509186351706037", brLenTotal: "1.6078" },
  { geneTranscript: "NM_012181.5", parsimonySitesP: "0.217110573042776", constantSitesP: "0.677966101694915", distinctSitesP: "0.282485875706215", brLenTotal: "1.1161" },
  { geneTranscript: "NM_032539.5", parsimonySitesP: "0.155029585798817", constantSitesP: "0.731755424063116", distinctSitesP: "0.199605522682446", brLenTotal: "0.5045" },
  { geneTranscript: "NM_001005164.2", parsimonySitesP: "0.547692307692308", constantSitesP: "0.313846153846154", distinctSitesP: "0.678974358974359", brLenTotal: "4.0159" },
  { geneTranscript: "NM_024541.3", parsimonySitesP: "0.11611030478955", constantSitesP: "0.806966618287373", distinctSitesP: "0.160619254958878", brLenTotal: "0.3361" },
  { geneTranscript: "NM_006739.4", parsimonySitesP: "0.17574931880109", constantSitesP: "0.716167120799273", distinctSitesP: "0.234786557674841", brLenTotal: "0.7637" },
  { geneTranscript: "NM_015178.3", parsimonySitesP: "0.137551581843191", constantSitesP: "0.749197615772581", distinctSitesP: "0.188904172397983", brLenTotal: "0.6992" },
  { geneTranscript: "NM_004373.4", parsimonySitesP: "0.311926605504587", constantSitesP: "0.501529051987768", distinctSitesP: "0.486238532110092", brLenTotal: "1.1331" },
  { geneTranscript: "NM_001692.4", parsimonySitesP: "0.165042235217674", constantSitesP: "0.735542560103964", distinctSitesP: "0.228070175438596", brLenTotal: "0.6581" },
  { geneTranscript: "NM_001004431.3", parsimonySitesP: "0.316184351554126", constantSitesP: "0.526259378349411", distinctSitesP: "0.480171489817792", brLenTotal: "1.5245" },
  { geneTranscript: "NM_001300.6", parsimonySitesP: "0.156654888103651", constantSitesP: "0.738515901060071", distinctSitesP: "0.212014134275618", brLenTotal: "0.8345" },
  { geneTranscript: "NM_004118.4", parsimonySitesP: "0.253535353535354", constantSitesP: "0.576767676767677", distinctSitesP: "0.356565656565657", brLenTotal: "0.9973" },
  { geneTranscript: "NM_172250.3", parsimonySitesP: "0.178628389154705", constantSitesP: "0.681818181818182", distinctSitesP: "0.268740031897927", brLenTotal: "0.6076" },
  { geneTranscript: "NM_006532.4", parsimonySitesP: "0.280193236714976", constantSitesP: "0.588835212023618", distinctSitesP: "0.371443907675792", brLenTotal: "1.3148" },
  { geneTranscript: "NM_001372105.2", parsimonySitesP: "0.279411764705882", constantSitesP: "0.67156862745098", distinctSitesP: "0.323529411764706", brLenTotal: "2.9941" },
  { geneTranscript: "NM_018990.4", parsimonySitesP: "0.17280701754386", constantSitesP: "0.726315789473684", distinctSitesP: "0.243859649122807", brLenTotal: "0.6475" },
  { geneTranscript: "NM_033506.3", parsimonySitesP: "0.201149425287356", constantSitesP: "0.664367816091954", distinctSitesP: "0.266091954022989", brLenTotal: "0.6807" },
  { geneTranscript: "NM_001000.4", parsimonySitesP: "0.202614379084967", constantSitesP: "0.594771241830065", distinctSitesP: "0.411764705882353", brLenTotal: "0.8108" },
  { geneTranscript: "NM_001012978.3", parsimonySitesP: "0.345345345345345", constantSitesP: "0.492492492492492", distinctSitesP: "0.486486486486486", brLenTotal: "1.0864" },
  { geneTranscript: "NM_018026.4", parsimonySitesP: "0.17410868812738", constantSitesP: "0.72724125995154", distinctSitesP: "0.228798892350294", brLenTotal: "0.6543" },
  { geneTranscript: "NM_001011.4", parsimonySitesP: "0.15979381443299", constantSitesP: "0.675257731958763", distinctSitesP: "0.309278350515464", brLenTotal: "0.7132" },
  { geneTranscript: "NM_005427.4", parsimonySitesP: "0.213836477987421", constantSitesP: "0.670859538784067", distinctSitesP: "0.291928721174004", brLenTotal: "1.0849" },
  { geneTranscript: "NM_017626.7", parsimonySitesP: "0.162666666666667", constantSitesP: "0.711111111111111", distinctSitesP: "0.239111111111111", brLenTotal: "0.7074" },
  { geneTranscript: "NM_024939.3", parsimonySitesP: "0.184565318456532", constantSitesP: "0.686192468619247", distinctSitesP: "0.253370525337053", brLenTotal: "0.6565" },
  { geneTranscript: "NM_033114.4", parsimonySitesP: "0.102918586789555", constantSitesP: "0.772657450076805", distinctSitesP: "0.216589861751152", brLenTotal: "0.3498" },
  { geneTranscript: "NM_003173.4", parsimonySitesP: "0.120550161812298", constantSitesP: "0.792880258899676", distinctSitesP: "0.16504854368932", brLenTotal: "0.4127" },
  { geneTranscript: "NM_022166.4", parsimonySitesP: "0.202641640597845", constantSitesP: "0.673270768161279", distinctSitesP: "0.303441084462982", brLenTotal: "1.0685" },
  { geneTranscript: "NM_001369369.1", parsimonySitesP: "0.227880658436214", constantSitesP: "0.640946502057613", distinctSitesP: "0.295267489711934", brLenTotal: "0.9539" },
  { geneTranscript: "NM_001012643.4", parsimonySitesP: "0.211361737677527", constantSitesP: "0.64327485380117", distinctSitesP: "0.33249791144528", brLenTotal: "0.8573" },
  { geneTranscript: "NM_002520.7", parsimonySitesP: "0.0975056689342404", constantSitesP: "0.801587301587302", distinctSitesP: "0.213151927437642", brLenTotal: "0.3591" },
  { geneTranscript: "NM_000925.4", parsimonySitesP: "0.138347260909935", constantSitesP: "0.763231197771588", distinctSitesP: "0.226555246053853", brLenTotal: "0.4967" },
  { geneTranscript: "NM_001008537.3", parsimonySitesP: "0.184036939313984", constantSitesP: "0.684476693051891", distinctSitesP: "0.213940193491645", brLenTotal: "0.5408" },
  { geneTranscript: "NM_024508.5", parsimonySitesP: "0.305810397553517", constantSitesP: "0.480122324159021", distinctSitesP: "0.466360856269113", brLenTotal: "1.0874" },
  { geneTranscript: "NM_003469.5", parsimonySitesP: "0.248514316585629", constantSitesP: "0.595894111291194", distinctSitesP: "0.33387358184765", brLenTotal: "0.7977" },
  { geneTranscript: "NM_004701.4", parsimonySitesP: "0.198492462311558", constantSitesP: "0.637353433835846", distinctSitesP: "0.319095477386935", brLenTotal: "0.7882" },
  { geneTranscript: "NM_014709.4", parsimonySitesP: "0.098702763677383", constantSitesP: "0.822617033276932", distinctSitesP: "0.120041361158112", brLenTotal: "0.3148" },
  { geneTranscript: "NM_001244950.2", parsimonySitesP: "0.17059748427673", constantSitesP: "0.738207547169811", distinctSitesP: "0.22562893081761", brLenTotal: "0.7456" },
  { geneTranscript: "NM_012109.3", parsimonySitesP: "0.244639376218324", constantSitesP: "0.592592592592593", distinctSitesP: "0.378167641325536", brLenTotal: "0.9803" },
  { geneTranscript: "NM_173525.3", parsimonySitesP: "0.244244244244244", constantSitesP: "0.621621621621622", distinctSitesP: "0.333333333333333", brLenTotal: "0.6651" },
  { geneTranscript: "NM_001795.5", parsimonySitesP: "0.256377551020408", constantSitesP: "0.613520408163265", distinctSitesP: "0.350340136054422", brLenTotal: "1.0082" },
  { geneTranscript: "NM_003984.4", parsimonySitesP: "0.310247747747748", constantSitesP: "0.560247747747748", distinctSitesP: "0.418918918918919", brLenTotal: "1.2528" },
  { geneTranscript: "NM_014730.4", parsimonySitesP: "0.13013698630137", constantSitesP: "0.773972602739726", distinctSitesP: "0.202054794520548", brLenTotal: "0.4271" },
  { geneTranscript: "NM_005076.5", parsimonySitesP: "0.184615384615385", constantSitesP: "0.713461538461538", distinctSitesP: "0.247115384615385", brLenTotal: "0.7714" },
  { geneTranscript: "NM_003932.5", parsimonySitesP: "0.137308039747064", constantSitesP: "0.681120144534779", distinctSitesP: "0.310749774164408", brLenTotal: "0.5589" },
  { geneTranscript: "NM_002688.6", parsimonySitesP: "0.173441734417344", constantSitesP: "0.754290876242096", distinctSitesP: "0.23486901535682", brLenTotal: "0.9855" },
  { geneTranscript: "NM_001964.3", parsimonySitesP: "0.171884591774095", constantSitesP: "0.718232044198895", distinctSitesP: "0.240638428483732", brLenTotal: "0.6656" },
  { geneTranscript: "NM_001039469.3", parsimonySitesP: "0.0981387478849408", constantSitesP: "0.821489001692047", distinctSitesP: "0.139593908629442", brLenTotal: "0.3597" },
  { geneTranscript: "NM_006006.6", parsimonySitesP: "0.139673105497771", constantSitesP: "0.778603268945022", distinctSitesP: "0.186230807330362", brLenTotal: "0.6304" },
  { geneTranscript: "NM_012306.4", parsimonySitesP: "0.184599156118143", constantSitesP: "0.715189873417722", distinctSitesP: "0.282700421940928", brLenTotal: "0.6047" },
  { geneTranscript: "NM_003675.4", parsimonySitesP: "0.148148148148148", constantSitesP: "0.757309941520468", distinctSitesP: "0.245614035087719", brLenTotal: "0.5217" },
  { geneTranscript: "NM_001371272.1", parsimonySitesP: "0.24496475327291", constantSitesP: "0.582829808660624", distinctSitesP: "0.326283987915408", brLenTotal: "0.9698" },
  { geneTranscript: "NM_025136.4", parsimonySitesP: "0.292364990689013", constantSitesP: "0.58659217877095", distinctSitesP: "0.411545623836127", brLenTotal: "1.1894" },
  { geneTranscript: "NM_003258.5", parsimonySitesP: "0.230769230769231", constantSitesP: "0.668091168091168", distinctSitesP: "0.317663817663818", brLenTotal: "0.8867" },
  { geneTranscript: "NM_002024.6", parsimonySitesP: "0.080168776371308", constantSitesP: "0.842299578059072", distinctSitesP: "0.144514767932489", brLenTotal: "0.2453" },
  { geneTranscript: "NM_001079675.5", parsimonySitesP: "0.141184573002755", constantSitesP: "0.71969696969697", distinctSitesP: "0.233471074380165", brLenTotal: "0.5895" },
  { geneTranscript: "NM_152522.7", parsimonySitesP: "0.278761061946903", constantSitesP: "0.553097345132743", distinctSitesP: "0.430678466076696", brLenTotal: "0.9664" },
  { geneTranscript: "NM_173547.4", parsimonySitesP: "0.319148936170213", constantSitesP: "0.493230174081238", distinctSitesP: "0.435847840103159", brLenTotal: "1.2942" },
  { geneTranscript: "NM_003660.4", parsimonySitesP: "0.151032942490229", constantSitesP: "0.752652149637074", distinctSitesP: "0.19179229480737", brLenTotal: "0.6152" },
  { geneTranscript: "NM_002818.3", parsimonySitesP: "0.135285913528591", constantSitesP: "0.726638772663877", distinctSitesP: "0.239888423988842", brLenTotal: "0.4480" },
  { geneTranscript: "NM_021930.6", parsimonySitesP: "0.163720538720539", constantSitesP: "0.711279461279461", distinctSitesP: "0.263888888888889", brLenTotal: "0.5575" },
  { geneTranscript: "NM_000362.5", parsimonySitesP: "0.109004739336493", constantSitesP: "0.799368088467615", distinctSitesP: "0.187993680884676", brLenTotal: "0.3575" },
  { geneTranscript: "NM_005262.3", parsimonySitesP: "0.247154471544715", constantSitesP: "0.626016260162602", distinctSitesP: "0.367479674796748", brLenTotal: "0.8859" },
  { geneTranscript: "NM_003921.5", parsimonySitesP: "0.157367668097282", constantSitesP: "0.729613733905579", distinctSitesP: "0.253218884120172", brLenTotal: "0.4982" },
  { geneTranscript: "NM_001346231.2", parsimonySitesP: "0.121984649122807", constantSitesP: "0.776864035087719", distinctSitesP: "0.178179824561404", brLenTotal: "0.4080" },
  { geneTranscript: "NM_006947.4", parsimonySitesP: "0.14903129657228", constantSitesP: "0.720814704421262", distinctSitesP: "0.231495280675609", brLenTotal: "0.5634" },
  { geneTranscript: "NM_022752.6", parsimonySitesP: "0.204613095238095", constantSitesP: "0.672247023809524", distinctSitesP: "0.2421875", brLenTotal: "0.8035" },
  { geneTranscript: "NM_001100876.2", parsimonySitesP: "0.209621993127148", constantSitesP: "0.63573883161512", distinctSitesP: "0.343642611683849", brLenTotal: "0.8571" },
  { geneTranscript: "NM_016341.4", parsimonySitesP: "0.203880683463655", constantSitesP: "0.664639443961772", distinctSitesP: "0.28077034462786", brLenTotal: "0.7499" },
  { geneTranscript: "NM_020699.4", parsimonySitesP: "0.106801573917931", constantSitesP: "0.815626756604834", distinctSitesP: "0.152332771219786", brLenTotal: "0.3211" },
  { geneTranscript: "NM_001195248.2", parsimonySitesP: "0.200779727095517", constantSitesP: "0.649122807017544", distinctSitesP: "0.300194931773879", brLenTotal: "0.6359" },
  { geneTranscript: "NM_013246.3", parsimonySitesP: "0.137777777777778", constantSitesP: "0.754074074074074", distinctSitesP: "0.211851851851852", brLenTotal: "0.4658" },
  { geneTranscript: "NM_148174.4", parsimonySitesP: "0.0989583333333333", constantSitesP: "0.794642857142857", distinctSitesP: "0.181547619047619", brLenTotal: "0.3092" },
  { geneTranscript: "NM_007363.5", parsimonySitesP: "0.145789101203114", constantSitesP: "0.663835810332626", distinctSitesP: "0.280962491153574", brLenTotal: "0.6301" },
  { geneTranscript: "NM_003403.5", parsimonySitesP: "0.0909822866344605", constantSitesP: "0.751207729468599", distinctSitesP: "0.239935587761675", brLenTotal: "0.3978" },
  { geneTranscript: "NM_002875.5", parsimonySitesP: "0.109144542772861", constantSitesP: "0.779744346116028", distinctSitesP: "0.196656833824975", brLenTotal: "0.3841" },
  { geneTranscript: "NM_014371.4", parsimonySitesP: "0.176986584107327", constantSitesP: "0.711042311661507", distinctSitesP: "0.255417956656347", brLenTotal: "0.6937" },
  { geneTranscript: "NM_014017.4", parsimonySitesP: "0.12", constantSitesP: "0.792", distinctSitesP: "0.2", brLenTotal: "0.3935" },
  { geneTranscript: "NM_025268.4", parsimonySitesP: "0.153605015673981", constantSitesP: "0.756530825496343", distinctSitesP: "0.215256008359457", brLenTotal: "0.5807" },
  { geneTranscript: "NM_001308313.2", parsimonySitesP: "0.342767295597484", constantSitesP: "0.518867924528302", distinctSitesP: "0.474842767295597", brLenTotal: "1.1485" },
  { geneTranscript: "NM_004462.5", parsimonySitesP: "0.213429256594724", constantSitesP: "0.657873701039169", distinctSitesP: "0.311750599520384", brLenTotal: "0.7897" },
  { geneTranscript: "NM_021003.5", parsimonySitesP: "0.209424083769634", constantSitesP: "0.719895287958115", distinctSitesP: "0.226003490401396", brLenTotal: "0.5802" },
  { geneTranscript: "NM_022097.4", parsimonySitesP: "0.217687074829932", constantSitesP: "0.647959183673469", distinctSitesP: "0.357142857142857", brLenTotal: "0.8419" },
  { geneTranscript: "NM_198545.4", parsimonySitesP: "0.238777459407832", constantSitesP: "0.560649474689589", distinctSitesP: "0.377268385864374", brLenTotal: "1.0535" },
  { geneTranscript: "NM_018836.4", parsimonySitesP: "0.238442822384428", constantSitesP: "0.636658556366586", distinctSitesP: "0.321978913219789", brLenTotal: "0.9993" },
  { geneTranscript: "NM_003537.4", parsimonySitesP: "0.343137254901961", constantSitesP: "0.617647058823529", distinctSitesP: "0.387254901960784", brLenTotal: "2.7666" },
  { geneTranscript: "NM_012329.3", parsimonySitesP: "0.102240896358543", constantSitesP: "0.836134453781513", distinctSitesP: "0.15546218487395", brLenTotal: "0.3075" },
  { geneTranscript: "NM_145288.3", parsimonySitesP: "0.276491228070175", constantSitesP: "0.564912280701754", distinctSitesP: "0.374035087719298", brLenTotal: "1.1599" },
  { geneTranscript: "NM_019053.6", parsimonySitesP: "0.115671641791045", constantSitesP: "0.76575456053068", distinctSitesP: "0.19485903814262", brLenTotal: "0.4018" },
  { geneTranscript: "NM_001037954.4", parsimonySitesP: "0.142508540751586", constantSitesP: "0.738408979990239", distinctSitesP: "0.219619326500732", brLenTotal: "0.4729" },
  { geneTranscript: "NM_001080792.4", parsimonySitesP: "0.205405405405405", constantSitesP: "0.659459459459459", distinctSitesP: "0.327927927927928", brLenTotal: "0.6413" },
  { geneTranscript: "NM_018639.5", parsimonySitesP: "0.12953795379538", constantSitesP: "0.758250825082508", distinctSitesP: "0.215346534653465", brLenTotal: "0.4883" },
  { geneTranscript: "NM_018285.4", parsimonySitesP: "0.226449275362319", constantSitesP: "0.574275362318841", distinctSitesP: "0.403985507246377", brLenTotal: "0.7888" },
  { geneTranscript: "NM_078483.4", parsimonySitesP: "0.263305322128852", constantSitesP: "0.619047619047619", distinctSitesP: "0.337535014005602", brLenTotal: "1.0818" },
  { geneTranscript: "NM_001163735.2", parsimonySitesP: "0.248797250859107", constantSitesP: "0.601030927835052", distinctSitesP: "0.333333333333333", brLenTotal: "0.8683" },
  { geneTranscript: "NM_032377.4", parsimonySitesP: "0.136546184738956", constantSitesP: "0.815261044176707", distinctSitesP: "0.196787148594378", brLenTotal: "0.6031" },
  { geneTranscript: "NM_021963.4", parsimonySitesP: "0.195652173913043", constantSitesP: "0.639855072463768", distinctSitesP: "0.309420289855072", brLenTotal: "0.7119" },
  { geneTranscript: "NM_001278431.2", parsimonySitesP: "0.193415637860082", constantSitesP: "0.716049382716049", distinctSitesP: "0.272976680384088", brLenTotal: "0.7281" },
  { geneTranscript: "NM_000350.3", parsimonySitesP: "0.209561519284353", constantSitesP: "0.658161020677519", distinctSitesP: "0.285965684117906", brLenTotal: "0.8661" },
  { geneTranscript: "NM_015144.3", parsimonySitesP: "0.222529158993247", constantSitesP: "0.657458563535912", distinctSitesP: "0.313996316758748", brLenTotal: "0.9877" },
  { geneTranscript: "NM_001352888.2", parsimonySitesP: "0.146892655367232", constantSitesP: "0.751412429378531", distinctSitesP: "0.271186440677966", brLenTotal: "0.6016" },
  { geneTranscript: "NM_145266.6", parsimonySitesP: "0.133757961783439", constantSitesP: "0.723991507430998", distinctSitesP: "0.263269639065817", brLenTotal: "0.5036" },
  { geneTranscript: "NM_001387340.1", parsimonySitesP: "0.0842293906810036", constantSitesP: "0.832437275985663", distinctSitesP: "0.14336917562724", brLenTotal: "0.3188" },
  { geneTranscript: "NM_024165.3", parsimonySitesP: "0.119341563786008", constantSitesP: "0.794238683127572", distinctSitesP: "0.168136390358613", brLenTotal: "0.3727" },
  { geneTranscript: "NM_001355256.2", parsimonySitesP: "0.25", constantSitesP: "0.603174603174603", distinctSitesP: "0.424603174603175", brLenTotal: "1.3529" },
  { geneTranscript: "NM_014454.3", parsimonySitesP: "0.118572292800968", constantSitesP: "0.76950998185118", distinctSitesP: "0.188747731397459", brLenTotal: "0.4024" },
  { geneTranscript: "NM_001008218.2", parsimonySitesP: "0.233529028049576", constantSitesP: "0.63600782778865", distinctSitesP: "0.360730593607306", brLenTotal: "1.0187" },
  { geneTranscript: "NM_153330.6", parsimonySitesP: "0.399425287356322", constantSitesP: "0.441091954022989", distinctSitesP: "0.533045977011494", brLenTotal: "1.8299" },
  { geneTranscript: "NM_000983.4", parsimonySitesP: "0.145833333333333", constantSitesP: "0.716145833333333", distinctSitesP: "0.276041666666667", brLenTotal: "0.5486" },
  { geneTranscript: "NM_000961.4", parsimonySitesP: "0.250666666666667", constantSitesP: "0.617333333333333", distinctSitesP: "0.374", brLenTotal: "1.0645" },
  { geneTranscript: "NM_014712.3", parsimonySitesP: "0.209138840070299", constantSitesP: "0.695957820738137", distinctSitesP: "0.247607889084163", brLenTotal: "0.8500" },
  { geneTranscript: "NM_006735.4", parsimonySitesP: "0.0824468085106383", constantSitesP: "0.835992907801418", distinctSitesP: "0.152482269503546", brLenTotal: "0.2769" },
  { geneTranscript: "NM_021973.3", parsimonySitesP: "0.119815668202765", constantSitesP: "0.783410138248848", distinctSitesP: "0.196620583717358", brLenTotal: "0.4970" },
  { geneTranscript: "NM_032217.5", parsimonySitesP: "0.102958125240108", constantSitesP: "0.808938404405174", distinctSitesP: "0.122294788065053", brLenTotal: "0.3516" },
  { geneTranscript: "NM_001005339.2", parsimonySitesP: "0.160220994475138", constantSitesP: "0.758747697974217", distinctSitesP: "0.232044198895028", brLenTotal: "0.5564" },
  { geneTranscript: "NM_152346.3", parsimonySitesP: "0.200937316930287", constantSitesP: "0.707674282366725", distinctSitesP: "0.25717633274751", brLenTotal: "0.8897" },
  { geneTranscript: "NM_021116.4", parsimonySitesP: "0.168602919273161", constantSitesP: "0.735180220434912", distinctSitesP: "0.216860291927316", brLenTotal: "0.7659" },
  { geneTranscript: "NM_001665.4", parsimonySitesP: "0.155322862129145", constantSitesP: "0.727748691099476", distinctSitesP: "0.263525305410122", brLenTotal: "0.5813" },
  { geneTranscript: "NM_022787.4", parsimonySitesP: "0.241338112305854", constantSitesP: "0.590203106332139", distinctSitesP: "0.432497013142174", brLenTotal: "0.9334" },
  { geneTranscript: "NM_020814.3", parsimonySitesP: "0.160975609756098", constantSitesP: "0.730081300813008", distinctSitesP: "0.248780487804878", brLenTotal: "0.6315" },
  { geneTranscript: "NM_014579.4", parsimonySitesP: "0.284789644012945", constantSitesP: "0.517799352750809", distinctSitesP: "0.448759439050701", brLenTotal: "1.0381" },
  { geneTranscript: "NM_002591.4", parsimonySitesP: "0.256162915326902", constantSitesP: "0.643086816720257", distinctSitesP: "0.330117899249732", brLenTotal: "1.0811" },
  { geneTranscript: "NM_001080501.3", parsimonySitesP: "0.341584158415842", constantSitesP: "0.503300330033003", distinctSitesP: "0.475247524752475", brLenTotal: "1.0967" },
  { geneTranscript: "NM_033256.3", parsimonySitesP: "0.206349206349206", constantSitesP: "0.653061224489796", distinctSitesP: "0.365079365079365", brLenTotal: "0.8502" },
  { geneTranscript: "NM_017817.3", parsimonySitesP: "0.246438746438746", constantSitesP: "0.61965811965812", distinctSitesP: "0.383190883190883", brLenTotal: "1.1236" },
  { geneTranscript: "NM_032305.3", parsimonySitesP: "0.17737003058104", constantSitesP: "0.715596330275229", distinctSitesP: "0.276758409785933", brLenTotal: "0.5365" },
  { geneTranscript: "NM_014698.3", parsimonySitesP: "0.230896323833127", constantSitesP: "0.660057827344073", distinctSitesP: "0.312267657992565", brLenTotal: "0.9041" },
  { geneTranscript: "NM_000795.4", parsimonySitesP: "0.120391271632807", constantSitesP: "0.784800601956358", distinctSitesP: "0.18359668924003", brLenTotal: "0.5193" },
  { geneTranscript: "NM_052988.5", parsimonySitesP: "0.200925925925926", constantSitesP: "0.7", distinctSitesP: "0.286111111111111", brLenTotal: "1.1090" },
  { geneTranscript: "NM_001369496.1", parsimonySitesP: "0.220478325859492", constantSitesP: "0.661434977578475", distinctSitesP: "0.292974588938714", brLenTotal: "0.9618" },
  { geneTranscript: "NM_175883.4", parsimonySitesP: "0.487179487179487", constantSitesP: "0.353632478632479", distinctSitesP: "0.620726495726496", brLenTotal: "3.0862" },
  { geneTranscript: "NM_180989.6", parsimonySitesP: "0.153030303030303", constantSitesP: "0.709848484848485", distinctSitesP: "0.256818181818182", brLenTotal: "0.5255" },
  { geneTranscript: "NM_019024.3", parsimonySitesP: "0.153227104458394", constantSitesP: "0.746982134234669", distinctSitesP: "0.179784323193304", brLenTotal: "0.5644" },
  { geneTranscript: "NM_001114633.2", parsimonySitesP: "0.285531370038412", constantSitesP: "0.570209133589415", distinctSitesP: "0.37430644472898", brLenTotal: "1.0606" },
  { geneTranscript: "NM_006262.4", parsimonySitesP: "0.179432624113475", constantSitesP: "0.719858156028369", distinctSitesP: "0.25531914893617", brLenTotal: "0.7208" },
  { geneTranscript: "NM_006662.3", parsimonySitesP: "0.17750257997936", constantSitesP: "0.699587203302374", distinctSitesP: "0.203199174406605", brLenTotal: "0.5970" },
  { geneTranscript: "NM_001023.4", parsimonySitesP: "0.218487394957983", constantSitesP: "0.563025210084034", distinctSitesP: "0.42296918767507", brLenTotal: "0.9516" },
  { geneTranscript: "NM_015360.5", parsimonySitesP: "0.114843250159949", constantSitesP: "0.799424184261036", distinctSitesP: "0.169865642994242", brLenTotal: "0.3809" },
  { geneTranscript: "NM_021073.4", parsimonySitesP: "0.145374449339207", constantSitesP: "0.776798825256975", distinctSitesP: "0.207782672540382", brLenTotal: "0.4550" },
  { geneTranscript: "NM_000182.5", parsimonySitesP: "0.190039318479685", constantSitesP: "0.667103538663172", distinctSitesP: "0.282219309742246", brLenTotal: "0.7133" },
  { geneTranscript: "NM_030934.5", parsimonySitesP: "0.138699408822192", constantSitesP: "0.722601182355616", distinctSitesP: "0.256480218281037", brLenTotal: "0.4887" },
  { geneTranscript: "NM_003489.4", parsimonySitesP: "0.191709844559585", constantSitesP: "0.674726540011514", distinctSitesP: "0.242084052964882", brLenTotal: "0.6206" },
  { geneTranscript: "NM_000321.3", parsimonySitesP: "0.122485632183908", constantSitesP: "0.772988505747126", distinctSitesP: "0.213002873563218", brLenTotal: "0.4062" },
  { geneTranscript: "NM_001395159.1", parsimonySitesP: "0.148627016377293", constantSitesP: "0.761851988671346", distinctSitesP: "0.184336904322128", brLenTotal: "0.5917" },
  { geneTranscript: "NM_024775.10", parsimonySitesP: "0.209580838323353", constantSitesP: "0.668662674650699", distinctSitesP: "0.287425149700599", brLenTotal: "0.7018" },
  { geneTranscript: "NM_015932.6", parsimonySitesP: "0.146572104018913", constantSitesP: "0.716312056737589", distinctSitesP: "0.271867612293144", brLenTotal: "0.4953" },
  { geneTranscript: "NM_007278.2", parsimonySitesP: "0.0769230769230769", constantSitesP: "0.857549857549858", distinctSitesP: "0.150997150997151", brLenTotal: "0.2171" },
  { geneTranscript: "NM_001323572.2", parsimonySitesP: "0.192061890346451", constantSitesP: "0.652203161789438", distinctSitesP: "0.315842583249243", brLenTotal: "0.6067" },
  { geneTranscript: "NM_005546.4", parsimonySitesP: "0.195698924731183", constantSitesP: "0.708064516129032", distinctSitesP: "0.268817204301075", brLenTotal: "0.7340" },
  { geneTranscript: "NM_022114.4", parsimonySitesP: "0.238244514106583", constantSitesP: "0.658045977011494", distinctSitesP: "0.283437826541275", brLenTotal: "1.0886" },
  { geneTranscript: "NM_000689.5", parsimonySitesP: "0.220891550232868", constantSitesP: "0.667332002661344", distinctSitesP: "0.323353293413174", brLenTotal: "0.9262" },
  { geneTranscript: "NM_032999.4", parsimonySitesP: "0.157314629258517", constantSitesP: "0.757181028724115", distinctSitesP: "0.219438877755511", brLenTotal: "0.6146" },
  { geneTranscript: "NM_006420.3", parsimonySitesP: "0.141736694677871", constantSitesP: "0.756676003734827", distinctSitesP: "0.174976657329599", brLenTotal: "0.5251" },
  { geneTranscript: "NM_001394565.1", parsimonySitesP: "0.161585365853659", constantSitesP: "0.708333333333333", distinctSitesP: "0.294715447154472", brLenTotal: "0.5332" },
  { geneTranscript: "NM_020343.4", parsimonySitesP: "0.185086314290799", constantSitesP: "0.703327994305036", distinctSitesP: "0.243993593166044", brLenTotal: "0.7124" },
  { geneTranscript: "NM_001364614.2", parsimonySitesP: "0.163828061638281", constantSitesP: "0.738037307380373", distinctSitesP: "0.225060827250608", brLenTotal: "0.6651" },
  { geneTranscript: "NM_001127228.2", parsimonySitesP: "0.126126126126126", constantSitesP: "0.708108108108108", distinctSitesP: "0.284684684684685", brLenTotal: "0.4813" },
  { geneTranscript: "NM_001258280.2", parsimonySitesP: "0.273062730627306", constantSitesP: "0.548585485854859", distinctSitesP: "0.3690036900369", brLenTotal: "1.0412" },
  { geneTranscript: "NM_000694.4", parsimonySitesP: "0.241452991452991", constantSitesP: "0.608974358974359", distinctSitesP: "0.349002849002849", brLenTotal: "1.2848" },
  { geneTranscript: "NM_133169.6", parsimonySitesP: "0.29404309252218", constantSitesP: "0.522179974651458", distinctSitesP: "0.437262357414449", brLenTotal: "1.1044" },
  { geneTranscript: "NM_001375808.2", parsimonySitesP: "0.196428571428571", constantSitesP: "0.688244047619048", distinctSitesP: "0.261904761904762", brLenTotal: "0.8189" },
  { geneTranscript: "NM_001312653.2", parsimonySitesP: "0.203703703703704", constantSitesP: "0.687830687830688", distinctSitesP: "0.304232804232804", brLenTotal: "1.3836" },
  { geneTranscript: "NM_001378969.1", parsimonySitesP: "0.117557251908397", constantSitesP: "0.812722646310433", distinctSitesP: "0.151145038167939", brLenTotal: "0.4535" },
  { geneTranscript: "NM_005913.3", parsimonySitesP: "0.4", constantSitesP: "0.475897435897436", distinctSitesP: "0.507692307692308", brLenTotal: "1.6588" },
  { geneTranscript: "NM_001348233.2", parsimonySitesP: "0.314121037463977", constantSitesP: "0.370797310278578", distinctSitesP: "0.587896253602305", brLenTotal: "1.5519" },
  { geneTranscript: "NM_178819.4", parsimonySitesP: "0.189327485380117", constantSitesP: "0.696637426900585", distinctSitesP: "0.295321637426901", brLenTotal: "0.7779" },
  { geneTranscript: "NM_007175.8", parsimonySitesP: "0.182890855457227", constantSitesP: "0.737463126843658", distinctSitesP: "0.231071779744346", brLenTotal: "0.7057" },
  { geneTranscript: "NM_003743.5", parsimonySitesP: "0.113115891741846", constantSitesP: "0.791117279666898", distinctSitesP: "0.145500809622947", brLenTotal: "0.3541" },
  { geneTranscript: "NM_001183.6", parsimonySitesP: "0.214893617021277", constantSitesP: "0.636879432624113", distinctSitesP: "0.319148936170213", brLenTotal: "0.8270" },
  { geneTranscript: "NM_001365809.2", parsimonySitesP: "0.0796890184645287", constantSitesP: "0.853741496598639", distinctSitesP: "0.120505344995141", brLenTotal: "0.3278" },
  { geneTranscript: "NM_002193.4", parsimonySitesP: "0.136773136773137", constantSitesP: "0.751842751842752", distinctSitesP: "0.212940212940213", brLenTotal: "0.5869" },
  { geneTranscript: "NM_005440.5", parsimonySitesP: "0.110132158590308", constantSitesP: "0.797356828193833", distinctSitesP: "0.195301027900147", brLenTotal: "0.3533" },
  { geneTranscript: "NM_006251.6", parsimonySitesP: "0.165175909361956", constantSitesP: "0.754323196183661", distinctSitesP: "0.216457960644007", brLenTotal: "0.6550" },
  { geneTranscript: "NM_016252.4", parsimonySitesP: "0.12840573742365", constantSitesP: "0.775993411570929", distinctSitesP: "0.133072541349255", brLenTotal: "0.4314" },
  { geneTranscript: "NM_001037637.2", parsimonySitesP: "0.0776699029126214", constantSitesP: "0.771844660194175", distinctSitesP: "0.223300970873786", brLenTotal: "0.3424" },
  { geneTranscript: "NM_013309.6", parsimonySitesP: "0.125874125874126", constantSitesP: "0.756798756798757", distinctSitesP: "0.198135198135198", brLenTotal: "0.3866" },
  { geneTranscript: "NM_030798.5", parsimonySitesP: "0.238505747126437", constantSitesP: "0.635057471264368", distinctSitesP: "0.344827586206897", brLenTotal: "0.9326" },
  { geneTranscript: "NM_005301.5", parsimonySitesP: "0.370010787486516", constantSitesP: "0.488673139158576", distinctSitesP: "0.504854368932039", brLenTotal: "1.7390" },
  { geneTranscript: "NM_005052.3", parsimonySitesP: "0.173611111111111", constantSitesP: "0.746527777777778", distinctSitesP: "0.262152777777778", brLenTotal: "0.9327" },
  { geneTranscript: "NM_000800.5", parsimonySitesP: "0.176344086021505", constantSitesP: "0.724731182795699", distinctSitesP: "0.258064516129032", brLenTotal: "0.5920" },
  { geneTranscript: "NM_001364564.1", parsimonySitesP: "0.173134328358209", constantSitesP: "0.697512437810945", distinctSitesP: "0.225538971807629", brLenTotal: "0.5708" },
  { geneTranscript: "NM_181891.3", parsimonySitesP: "0.0793650793650794", constantSitesP: "0.841269841269841", distinctSitesP: "0.151927437641723", brLenTotal: "0.2453" },
  { geneTranscript: "NM_015416.5", parsimonySitesP: "0.208333333333333", constantSitesP: "0.650925925925926", distinctSitesP: "0.285185185185185", brLenTotal: "0.5820" },
  { geneTranscript: "NM_004753.7", parsimonySitesP: "0.132450331125828", constantSitesP: "0.783664459161148", distinctSitesP: "0.195364238410596", brLenTotal: "0.5717" },
  { geneTranscript: "NM_001530.4", parsimonySitesP: "0.121468926553672", constantSitesP: "0.775221953188055", distinctSitesP: "0.175141242937853", brLenTotal: "0.4184" },
  { geneTranscript: "NM_006822.3", parsimonySitesP: "0.292565947242206", constantSitesP: "0.588729016786571", distinctSitesP: "0.400479616306954", brLenTotal: "1.5094" },
  { geneTranscript: "NM_021038.5", parsimonySitesP: "0.0619546247818499", constantSitesP: "0.87521815008726", distinctSitesP: "0.102094240837696", brLenTotal: "0.2026" },
  { geneTranscript: "NM_002047.4", parsimonySitesP: "0.166441136671177", constantSitesP: "0.71808750563825", distinctSitesP: "0.252593594948128", brLenTotal: "0.5808" },
  { geneTranscript: "NM_003282.4", parsimonySitesP: "0.203296703296703", constantSitesP: "0.716117216117216", distinctSitesP: "0.3003663003663", brLenTotal: "1.3908" },
  { geneTranscript: "NM_006618.5", parsimonySitesP: "0.139248704663212", constantSitesP: "0.751943005181347", distinctSitesP: "0.173359240069085", brLenTotal: "0.4805" },
  { geneTranscript: "NM_001005197.2", parsimonySitesP: "0.549893842887473", constantSitesP: "0.294055201698514", distinctSitesP: "0.683651804670913", brLenTotal: "3.8298" },
  { geneTranscript: "NM_001005361.3", parsimonySitesP: "0.201915708812261", constantSitesP: "0.711877394636015", distinctSitesP: "0.243295019157088", brLenTotal: "0.9468" },
  { geneTranscript: "NM_000157.4", parsimonySitesP: "0.218283582089552", constantSitesP: "0.609452736318408", distinctSitesP: "0.331467661691542", brLenTotal: "0.8260" },
  { geneTranscript: "NM_000514.4", parsimonySitesP: "0.129541864139021", constantSitesP: "0.767772511848341", distinctSitesP: "0.210110584518167", brLenTotal: "0.4124" },
  { geneTranscript: "NM_000614.4", parsimonySitesP: "0.225", constantSitesP: "0.596666666666667", distinctSitesP: "0.38", brLenTotal: "0.7811" },
  { geneTranscript: "NM_001397448.1", parsimonySitesP: "0.0789473684210526", constantSitesP: "0.807017543859649", distinctSitesP: "0.18859649122807", brLenTotal: "0.2817" },
  { geneTranscript: "NM_000939.4", parsimonySitesP: "0.279650436953808", constantSitesP: "0.556803995006242", distinctSitesP: "0.428214731585518", brLenTotal: "1.0424" },
  { geneTranscript: "NM_001099737.3", parsimonySitesP: "0.204232804232804", constantSitesP: "0.675132275132275", distinctSitesP: "0.293121693121693", brLenTotal: "0.7083" },
  { geneTranscript: "NM_001142966.3", parsimonySitesP: "0.155832899982666", constantSitesP: "0.734616051308719", distinctSitesP: "0.18842087016814", brLenTotal: "0.5430" },
  { geneTranscript: "NM_005518.4", parsimonySitesP: "0.272309711286089", constantSitesP: "0.603674540682415", distinctSitesP: "0.398950131233596", brLenTotal: "1.0103" },
  { geneTranscript: "NM_021005.4", parsimonySitesP: "0.0644122383252818", constantSitesP: "0.877616747181965", distinctSitesP: "0.106280193236715", brLenTotal: "0.2268" },
  { geneTranscript: "NM_001191061.2", parsimonySitesP: "0.198142414860681", constantSitesP: "0.703818369453044", distinctSitesP: "0.280701754385965", brLenTotal: "1.0858" },
  { geneTranscript: "NM_001330691.3", parsimonySitesP: "0.174468085106383", constantSitesP: "0.708274231678487", distinctSitesP: "0.265248226950355", brLenTotal: "0.5710" },
  { geneTranscript: "NM_001366285.2", parsimonySitesP: "0.231651376146789", constantSitesP: "0.617737003058104", distinctSitesP: "0.35474006116208", brLenTotal: "1.1297" },
  { geneTranscript: "NM_002975.3", parsimonySitesP: "0.236326109391125", constantSitesP: "0.638802889576883", distinctSitesP: "0.352941176470588", brLenTotal: "0.8473" },
  { geneTranscript: "NM_004419.4", parsimonySitesP: "0.168402777777778", constantSitesP: "0.689236111111111", distinctSitesP: "0.274305555555556", brLenTotal: "0.6146" },
  { geneTranscript: "NM_007225.4", parsimonySitesP: "0.138888888888889", constantSitesP: "0.75", distinctSitesP: "0.220899470899471", brLenTotal: "0.5093" },
  { geneTranscript: "NM_001270639.2", parsimonySitesP: "0.173758865248227", constantSitesP: "0.728723404255319", distinctSitesP: "0.26063829787234", brLenTotal: "0.8664" },
  { geneTranscript: "NM_003236.4", parsimonySitesP: "0.16875", constantSitesP: "0.733333333333333", distinctSitesP: "0.277083333333333", brLenTotal: "0.6094" },
  { geneTranscript: "NM_005282.3", parsimonySitesP: "0.176795580110497", constantSitesP: "0.667587476979742", distinctSitesP: "0.246777163904236", brLenTotal: "0.8741" },
  { geneTranscript: "NM_018219.3", parsimonySitesP: "0.341970946211229", constantSitesP: "0.466038476639183", distinctSitesP: "0.471142520612485", brLenTotal: "1.0766" },
  { geneTranscript: "NM_001114118.3", parsimonySitesP: "0.159139784946237", constantSitesP: "0.722043010752688", distinctSitesP: "0.247311827956989", brLenTotal: "0.5623" },
  { geneTranscript: "NM_001370687.1", parsimonySitesP: "0.314777998674619", constantSitesP: "0.507620941020543", distinctSitesP: "0.408217362491716", brLenTotal: "1.1180" },
  { geneTranscript: "NM_001017368.2", parsimonySitesP: "0.170798898071625", constantSitesP: "0.709825528007346", distinctSitesP: "0.242424242424242", brLenTotal: "0.5463" },
  { geneTranscript: "NM_032562.5", parsimonySitesP: "0.188034188034188", constantSitesP: "0.690598290598291", distinctSitesP: "0.292307692307692", brLenTotal: "0.5898" },
  { geneTranscript: "NM_001323032.3", parsimonySitesP: "0.197657393850659", constantSitesP: "0.690580771107857", distinctSitesP: "0.28648121034651", brLenTotal: "0.9177" },
  { geneTranscript: "NM_001353425.2", parsimonySitesP: "0.384879725085911", constantSitesP: "0.402061855670103", distinctSitesP: "0.609965635738832", brLenTotal: "1.4874" },
  { geneTranscript: "NM_000840.3", parsimonySitesP: "0.15092908608267", constantSitesP: "0.722791050436102", distinctSitesP: "0.201744406522564", brLenTotal: "0.6236" },
  { geneTranscript: "NM_020440.4", parsimonySitesP: "0.208191126279863", constantSitesP: "0.661736822146378", distinctSitesP: "0.270003792188093", brLenTotal: "0.8032" },
  { geneTranscript: "NM_001034077.4", parsimonySitesP: "0.27831715210356", constantSitesP: "0.679611650485437", distinctSitesP: "0.333333333333333", brLenTotal: "1.7229" },
  { geneTranscript: "NM_004994.3", parsimonySitesP: "0.273455917020273", constantSitesP: "0.586515794436587", distinctSitesP: "0.375766148043376", brLenTotal: "0.9522" },
  { geneTranscript: "NM_005264.8", parsimonySitesP: "0.1584229390681", constantSitesP: "0.756272401433692", distinctSitesP: "0.22078853046595", brLenTotal: "0.5330" },
  { geneTranscript: "NM_014858.4", parsimonySitesP: "0.166901739539257", constantSitesP: "0.724024447578749", distinctSitesP: "0.23836389280677", brLenTotal: "0.7705" },
  { geneTranscript: "NM_001377534.1", parsimonySitesP: "0.422322775263952", constantSitesP: "0.390648567119155", distinctSitesP: "0.570135746606335", brLenTotal: "1.7511" },
  { geneTranscript: "NM_001031677.4", parsimonySitesP: "0.134646962233169", constantSitesP: "0.789819376026273", distinctSitesP: "0.205254515599343", brLenTotal: "0.4071" },
  { geneTranscript: "NM_025045.6", parsimonySitesP: "0.209829867674858", constantSitesP: "0.659735349716446", distinctSitesP: "0.340264650283554", brLenTotal: "0.9073" },
  { geneTranscript: "NM_012326.4", parsimonySitesP: "0.0925266903914591", constantSitesP: "0.832740213523132", distinctSitesP: "0.136417556346382", brLenTotal: "0.3244" },
  { geneTranscript: "NM_152365.3", parsimonySitesP: "0.1892797319933", constantSitesP: "0.676716917922948", distinctSitesP: "0.265494137353434", brLenTotal: "0.7533" },
  { geneTranscript: "NM_001010897.3", parsimonySitesP: "0.0974358974358974", constantSitesP: "0.846153846153846", distinctSitesP: "0.184615384615385", brLenTotal: "0.2768" },
  { geneTranscript: "NM_032153.6", parsimonySitesP: "0.149700598802395", constantSitesP: "0.739520958083832", distinctSitesP: "0.226546906187625", brLenTotal: "0.4907" },
  { geneTranscript: "NM_032493.4", parsimonySitesP: "0.171000788022065", constantSitesP: "0.757289204097715", distinctSitesP: "0.225374310480693", brLenTotal: "0.9481" },
  { geneTranscript: "NM_031469.4", parsimonySitesP: "0.130841121495327", constantSitesP: "0.775700934579439", distinctSitesP: "0.2398753894081", brLenTotal: "0.3808" },
  { geneTranscript: "NM_001366661.1", parsimonySitesP: "0.188427299703264", constantSitesP: "0.717606330365974", distinctSitesP: "0.227250247279921", brLenTotal: "0.9136" },
  { geneTranscript: "NM_001101338.1", parsimonySitesP: "0.507475813544415", constantSitesP: "0.383465259454705", distinctSitesP: "0.622691292875989", brLenTotal: "2.4224" },
  { geneTranscript: "NM_021627.3", parsimonySitesP: "0.115449915110357", constantSitesP: "0.76287492925863", distinctSitesP: "0.203169213355971", brLenTotal: "0.3899" },
  { geneTranscript: "NM_139278.4", parsimonySitesP: "0.172141119221411", constantSitesP: "0.726277372262774", distinctSitesP: "0.228710462287105", brLenTotal: "0.7940" },
  { geneTranscript: "NM_153477.3", parsimonySitesP: "0.126232741617357", constantSitesP: "0.745562130177515", distinctSitesP: "0.236686390532544", brLenTotal: "0.3894" },
  { geneTranscript: "NM_173352.4", parsimonySitesP: "0.334615384615385", constantSitesP: "0.509615384615385", distinctSitesP: "0.470512820512821", brLenTotal: "1.4646" },
  { geneTranscript: "NM_018243.4", parsimonySitesP: "0.22999222999223", constantSitesP: "0.700854700854701", distinctSitesP: "0.275058275058275", brLenTotal: "1.1751" },
  { geneTranscript: "NM_014289.4", parsimonySitesP: "0.128965158606344", constantSitesP: "0.771190847633905", distinctSitesP: "0.174726989079563", brLenTotal: "0.3893" },
  { geneTranscript: "NM_006265.3", parsimonySitesP: "0.148441627047015", constantSitesP: "0.75013206550449", distinctSitesP: "0.20919175911252", brLenTotal: "0.5337" },
  { geneTranscript: "NM_001348800.3", parsimonySitesP: "0.100314889788574", constantSitesP: "0.83625730994152", distinctSitesP: "0.142600089968511", brLenTotal: "0.3859" },
  { geneTranscript: "NM_001145065.2", parsimonySitesP: "0.196666666666667", constantSitesP: "0.655555555555556", distinctSitesP: "0.295185185185185", brLenTotal: "0.6875" },
  { geneTranscript: "NM_018403.7", parsimonySitesP: "0.183276059564719", constantSitesP: "0.675830469644903", distinctSitesP: "0.266895761741123", brLenTotal: "0.6590" },
  { geneTranscript: "NM_001135655.2", parsimonySitesP: "0.211180124223602", constantSitesP: "0.625258799171843", distinctSitesP: "0.331262939958592", brLenTotal: "0.9147" },
  { geneTranscript: "NM_007249.5", parsimonySitesP: "0.0870646766169154", constantSitesP: "0.845771144278607", distinctSitesP: "0.151741293532338", brLenTotal: "0.2854" },
  { geneTranscript: "NM_018898.5", parsimonySitesP: "0.235721703011423", constantSitesP: "0.574247144340602", distinctSitesP: "0.335410176531672", brLenTotal: "0.7987" },
  { geneTranscript: "NM_003491.4", parsimonySitesP: "0.173049645390071", constantSitesP: "0.675177304964539", distinctSitesP: "0.28936170212766", brLenTotal: "0.7274" },
  { geneTranscript: "NM_005899.5", parsimonySitesP: "0.191166321601104", constantSitesP: "0.674948240165631", distinctSitesP: "0.266045548654244", brLenTotal: "0.6023" },
  { geneTranscript: "NM_001206.4", parsimonySitesP: "0.0573770491803279", constantSitesP: "0.878415300546448", distinctSitesP: "0.12431693989071", brLenTotal: "0.2258" },
  { geneTranscript: "NM_001030.6", parsimonySitesP: "0.162698412698413", constantSitesP: "0.746031746031746", distinctSitesP: "0.281746031746032", brLenTotal: "0.5564" },
  { geneTranscript: "NM_001005221.2", parsimonySitesP: "0.56517094017094", constantSitesP: "0.305555555555556", distinctSitesP: "0.681623931623932", brLenTotal: "3.3366" },
  { geneTranscript: "NM_052840.5", parsimonySitesP: "0.120582120582121", constantSitesP: "0.763686763686764", distinctSitesP: "0.18988218988219", brLenTotal: "0.5296" },
  { geneTranscript: "NM_182527.3", parsimonySitesP: "0.125581395348837", constantSitesP: "0.806201550387597", distinctSitesP: "0.186046511627907", brLenTotal: "0.4414" },
  { geneTranscript: "NM_001004456.2", parsimonySitesP: "0.605963791267306", constantSitesP: "0.271565495207668", distinctSitesP: "0.714589989350373", brLenTotal: "3.6031" },
  { geneTranscript: "NM_005356.5", parsimonySitesP: "0.215455140798952", constantSitesP: "0.688277668631303", distinctSitesP: "0.258677144728225", brLenTotal: "0.7164" },
  { geneTranscript: "NM_002081.3", parsimonySitesP: "0.235961768219833", constantSitesP: "0.635005973715651", distinctSitesP: "0.313620071684588", brLenTotal: "1.2522" },
  { geneTranscript: "NM_153240.5", parsimonySitesP: "0.165413533834586", constantSitesP: "0.709774436090226", distinctSitesP: "0.244611528822055", brLenTotal: "0.5961" },
  { geneTranscript: "NM_001004339.3", parsimonySitesP: "0.228370663153272", constantSitesP: "0.587176108915239", distinctSitesP: "0.343873517786561", brLenTotal: "0.8908" },
  { geneTranscript: "NM_032496.4", parsimonySitesP: "0.227998176014592", constantSitesP: "0.619699042407661", distinctSitesP: "0.316461468308254", brLenTotal: "0.8149" },
  { geneTranscript: "NM_014216.6", parsimonySitesP: "0.181159420289855", constantSitesP: "0.69645732689211", distinctSitesP: "0.27536231884058", brLenTotal: "1.1706" },
  { geneTranscript: "NM_000459.5", parsimonySitesP: "0.178825622775801", constantSitesP: "0.711150652431791", distinctSitesP: "0.215895610913405", brLenTotal: "0.5841" },
  { geneTranscript: "NM_003768.5", parsimonySitesP: "0.0897435897435897", constantSitesP: "0.823076923076923", distinctSitesP: "0.166666666666667", brLenTotal: "0.2723" },
  { geneTranscript: "NM_003024.3", parsimonySitesP: "0.142359093550261", constantSitesP: "0.767576990122022", distinctSitesP: "0.178965717606043", brLenTotal: "0.5298" },
  { geneTranscript: "NM_000603.5", parsimonySitesP: "0.201995012468828", constantSitesP: "0.696314768633971", distinctSitesP: "0.240232751454697", brLenTotal: "0.9060" },
  { geneTranscript: "NM_001970.5", parsimonySitesP: "0.0692640692640693", constantSitesP: "0.87012987012987", distinctSitesP: "0.123376623376623", brLenTotal: "0.1971" },
  { geneTranscript: "NM_005339.5", parsimonySitesP: "0.0433333333333333", constantSitesP: "0.91", distinctSitesP: "0.09", brLenTotal: "0.1823" },
  { geneTranscript: "NM_001024401.3", parsimonySitesP: "0.145440251572327", constantSitesP: "0.74685534591195", distinctSitesP: "0.229559748427673", brLenTotal: "0.7251" },
  { geneTranscript: "NM_001386863.1", parsimonySitesP: "0.147310989867498", constantSitesP: "0.731618602234347", distinctSitesP: "0.2094050402702", brLenTotal: "0.5005" },
  { geneTranscript: "NM_001005467.2", parsimonySitesP: "0.513312034078807", constantSitesP: "0.358892438764643", distinctSitesP: "0.630457933972311", brLenTotal: "3.2463" },
  { geneTranscript: "NM_178539.5", parsimonySitesP: "0.111959287531807", constantSitesP: "0.806615776081425", distinctSitesP: "0.203562340966921", brLenTotal: "0.4332" },
  { geneTranscript: "NM_004789.4", parsimonySitesP: "0.12807881773399", constantSitesP: "0.797208538587849", distinctSitesP: "0.174055829228243", brLenTotal: "0.5898" },
  { geneTranscript: "NM_030935.5", parsimonySitesP: "0.245569620253165", constantSitesP: "0.610126582278481", distinctSitesP: "0.346835443037975", brLenTotal: "1.0411" },
  { geneTranscript: "NM_145886.4", parsimonySitesP: "0.291208791208791", constantSitesP: "0.563736263736264", distinctSitesP: "0.381684981684982", brLenTotal: "1.2533" },
  { geneTranscript: "NM_002118.5", parsimonySitesP: "0.26615969581749", constantSitesP: "0.577946768060837", distinctSitesP: "0.415716096324461", brLenTotal: "1.0033" },
  { geneTranscript: "NM_001277075.3", parsimonySitesP: "0.304110946012878", constantSitesP: "0.515106488360575", distinctSitesP: "0.400445765230312", brLenTotal: "1.2529" },
  { geneTranscript: "NM_001861.6", parsimonySitesP: "0.32741617357002", constantSitesP: "0.548323471400394", distinctSitesP: "0.455621301775148", brLenTotal: "1.4173" },
  { geneTranscript: "NM_014499.4", parsimonySitesP: "0.198623402163225", constantSitesP: "0.673549655850541", distinctSitesP: "0.305801376597837", brLenTotal: "0.6432" },
  { geneTranscript: "NM_001042697.2", parsimonySitesP: "0.202380952380952", constantSitesP: "0.669047619047619", distinctSitesP: "0.319047619047619", brLenTotal: "0.6053" },
  { geneTranscript: "NM_001271893.4", parsimonySitesP: "0.0625", constantSitesP: "0.885416666666667", distinctSitesP: "0.11875", brLenTotal: "0.1944" },
  { geneTranscript: "NM_000785.4", parsimonySitesP: "0.246719160104987", constantSitesP: "0.618110236220472", distinctSitesP: "0.324146981627297", brLenTotal: "0.8357" },
  { geneTranscript: "NM_016131.5", parsimonySitesP: "0.05", constantSitesP: "0.895", distinctSitesP: "0.0966666666666667", brLenTotal: "0.1487" },
  { geneTranscript: "NM_001382567.1", parsimonySitesP: "0.116852886405959", constantSitesP: "0.794692737430168", distinctSitesP: "0.155959031657356", brLenTotal: "0.3706" },
  { geneTranscript: "NM_018381.4", parsimonySitesP: "0.161512027491409", constantSitesP: "0.77205040091638", distinctSitesP: "0.221076746849943", brLenTotal: "0.7743" },
  { geneTranscript: "NM_001281766.3", parsimonySitesP: "0.22998687664042", constantSitesP: "0.684711286089239", distinctSitesP: "0.258202099737533", brLenTotal: "0.8578" },
  { geneTranscript: "NM_004433.5", parsimonySitesP: "0.226415094339623", constantSitesP: "0.649595687331536", distinctSitesP: "0.30188679245283", brLenTotal: "0.8830" },
  { geneTranscript: "NM_006266.4", parsimonySitesP: "0.25346462436178", constantSitesP: "0.61998541210795", distinctSitesP: "0.337345003646973", brLenTotal: "1.3738" },
  { geneTranscript: "NM_001006681.2", parsimonySitesP: "0.377260981912145", constantSitesP: "0.515503875968992", distinctSitesP: "0.435400516795866", brLenTotal: "1.1397" },
  { geneTranscript: "NM_003341.5", parsimonySitesP: "0.146804835924007", constantSitesP: "0.725388601036269", distinctSitesP: "0.250431778929188", brLenTotal: "0.6585" },
  { geneTranscript: "NM_005398.7", parsimonySitesP: "0.19348054679285", constantSitesP: "0.702418506834911", distinctSitesP: "0.262881177707676", brLenTotal: "0.6399" },
  { geneTranscript: "NM_003015.3", parsimonySitesP: "0.160883280757098", constantSitesP: "0.709779179810726", distinctSitesP: "0.272344900105152", brLenTotal: "0.7601" },
  { geneTranscript: "NM_023112.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.700854700854701", distinctSitesP: "0.264957264957265", brLenTotal: "0.6471" },
  { geneTranscript: "NM_000175.5", parsimonySitesP: "0.197729988052569", constantSitesP: "0.700716845878136", distinctSitesP: "0.270609318996416", brLenTotal: "0.7137" },
  { geneTranscript: "NM_021019.5", parsimonySitesP: "0.152317880794702", constantSitesP: "0.785871964679912", distinctSitesP: "0.209713024282561", brLenTotal: "0.6230" },
  { geneTranscript: "NM_001242318.3", parsimonySitesP: "0.117565698478562", constantSitesP: "0.802904564315353", distinctSitesP: "0.165283540802213", brLenTotal: "0.3499" },
  { geneTranscript: "NM_001371533.1", parsimonySitesP: "0.12463768115942", constantSitesP: "0.787826086956522", distinctSitesP: "0.166376811594203", brLenTotal: "0.3652" },
  { geneTranscript: "NM_014443.3", parsimonySitesP: "0.203703703703704", constantSitesP: "0.657407407407407", distinctSitesP: "0.322222222222222", brLenTotal: "0.9047" },
  { geneTranscript: "NM_000728.4", parsimonySitesP: "0.267716535433071", constantSitesP: "0.574803149606299", distinctSitesP: "0.435695538057743", brLenTotal: "0.9179" },
  { geneTranscript: "NM_021062.3", parsimonySitesP: "0.246031746031746", constantSitesP: "0.656084656084656", distinctSitesP: "0.338624338624339", brLenTotal: "1.8858" },
  { geneTranscript: "NM_001289080.2", parsimonySitesP: "0.187094682230869", constantSitesP: "0.673800259403372", distinctSitesP: "0.281452658884565", brLenTotal: "0.6663" },
  { geneTranscript: "NM_015920.4", parsimonySitesP: "0.123015873015873", constantSitesP: "0.662698412698413", distinctSitesP: "0.325396825396825", brLenTotal: "0.6064" },
  { geneTranscript: "NM_001142569.3", parsimonySitesP: "0.258938869665513", constantSitesP: "0.603806228373702", distinctSitesP: "0.348327566320646", brLenTotal: "0.9730" },
  { geneTranscript: "NM_001161403.3", parsimonySitesP: "0.168132942326491", constantSitesP: "0.708699902248289", distinctSitesP: "0.250244379276637", brLenTotal: "0.8985" },
  { geneTranscript: "NM_004279.3", parsimonySitesP: "0.16428084526244", constantSitesP: "0.721881390593047", distinctSitesP: "0.268575323790048", brLenTotal: "0.5253" },
  { geneTranscript: "NM_152494.4", parsimonySitesP: "0.252596789423985", constantSitesP: "0.602927289896128", distinctSitesP: "0.356468366383381", brLenTotal: "0.8925" },
  { geneTranscript: "NM_015355.4", parsimonySitesP: "0.0681100586377988", constantSitesP: "0.856562922868742", distinctSitesP: "0.132160577356788", brLenTotal: "0.2255" },
  { geneTranscript: "NM_002794.5", parsimonySitesP: "0.131011608623549", constantSitesP: "0.751243781094527", distinctSitesP: "0.240464344941957", brLenTotal: "0.4526" },
  { geneTranscript: "NM_022465.4", parsimonySitesP: "0.105413105413105", constantSitesP: "0.821652421652422", distinctSitesP: "0.147008547008547", brLenTotal: "0.3497" },
  { geneTranscript: "NM_006826.4", parsimonySitesP: "0.122448979591837", constantSitesP: "0.700680272108844", distinctSitesP: "0.272108843537415", brLenTotal: "0.5336" },
  { geneTranscript: "NM_002806.5", parsimonySitesP: "0.102827763496144", constantSitesP: "0.796915167095116", distinctSitesP: "0.19280205655527", brLenTotal: "0.3413" },
  { geneTranscript: "NM_001167670.3", parsimonySitesP: "0.276315789473684", constantSitesP: "0.570175438596491", distinctSitesP: "0.401315789473684", brLenTotal: "1.1069" },
  { geneTranscript: "NM_005900.3", parsimonySitesP: "0.1584229390681", constantSitesP: "0.756989247311828", distinctSitesP: "0.217204301075269", brLenTotal: "0.5864" },
  { geneTranscript: "NM_001048249.4", parsimonySitesP: "0.220720720720721", constantSitesP: "0.527027027027027", distinctSitesP: "0.490990990990991", brLenTotal: "0.8494" },
  { geneTranscript: "NM_004618.5", parsimonySitesP: "0.235764235764236", constantSitesP: "0.643689643689644", distinctSitesP: "0.294039294039294", brLenTotal: "0.8509" },
  { geneTranscript: "NM_015909.4", parsimonySitesP: "0.217489104456629", constantSitesP: "0.644172641642064", distinctSitesP: "0.287220582032898", brLenTotal: "0.7899" },
  { geneTranscript: "NM_014786.4", parsimonySitesP: "0.230893520762643", constantSitesP: "0.630473420584909", distinctSitesP: "0.253191145580869", brLenTotal: "0.8636" },
  { geneTranscript: "NM_003529.3", parsimonySitesP: "0.316176470588235", constantSitesP: "0.656862745098039", distinctSitesP: "0.352941176470588", brLenTotal: "2.1209" },
  { geneTranscript: "NM_152925.3", parsimonySitesP: "0.204841713221601", constantSitesP: "0.658597144630664", distinctSitesP: "0.304158907510863", brLenTotal: "0.7063" },
  { geneTranscript: "NM_001123396.4", parsimonySitesP: "0.377777777777778", constantSitesP: "0.471296296296296", distinctSitesP: "0.505555555555556", brLenTotal: "2.0002" },
  { geneTranscript: "NM_024409.4", parsimonySitesP: "0.216931216931217", constantSitesP: "0.669312169312169", distinctSitesP: "0.325396825396825", brLenTotal: "0.5568" },
  { geneTranscript: "NM_014365.3", parsimonySitesP: "0.163265306122449", constantSitesP: "0.73469387755102", distinctSitesP: "0.256802721088435", brLenTotal: "0.6509" },
  { geneTranscript: "NM_001024675.2", parsimonySitesP: "0.337414965986395", constantSitesP: "0.462585034013605", distinctSitesP: "0.476190476190476", brLenTotal: "1.3971" },
  { geneTranscript: "NM_021927.3", parsimonySitesP: "0.176880916791231", constantSitesP: "0.698555057299452", distinctSitesP: "0.296462381664175", brLenTotal: "0.6026" },
  { geneTranscript: "NM_003908.5", parsimonySitesP: "0.139139139139139", constantSitesP: "0.658658658658659", distinctSitesP: "0.3003003003003", brLenTotal: "0.6028" },
  { geneTranscript: "NM_020533.3", parsimonySitesP: "0.223563218390805", constantSitesP: "0.667816091954023", distinctSitesP: "0.310344827586207", brLenTotal: "1.1884" },
  { geneTranscript: "NM_173546.3", parsimonySitesP: "0.170433145009416", constantSitesP: "0.74105461393597", distinctSitesP: "0.22316384180791", brLenTotal: "0.5466" },
  { geneTranscript: "NM_020697.4", parsimonySitesP: "0.142557651991614", constantSitesP: "0.77078965758211", distinctSitesP: "0.198462613556953", brLenTotal: "0.5138" },
  { geneTranscript: "NM_152913.3", parsimonySitesP: "0.249014972419228", constantSitesP: "0.624901497241923", distinctSitesP: "0.342789598108747", brLenTotal: "0.9460" },
  { geneTranscript: "NM_014249.4", parsimonySitesP: "0.230081300813008", constantSitesP: "0.630081300813008", distinctSitesP: "0.327642276422764", brLenTotal: "1.1315" },
  { geneTranscript: "NM_004770.3", parsimonySitesP: "0.228320526893524", constantSitesP: "0.67178924259056", distinctSitesP: "0.259787778997439", brLenTotal: "1.0172" },
  { geneTranscript: "NM_002815.4", parsimonySitesP: "0.0805687203791469", constantSitesP: "0.832543443917852", distinctSitesP: "0.138230647709321", brLenTotal: "0.2642" },
  { geneTranscript: "NM_173515.4", parsimonySitesP: "0.197597597597598", constantSitesP: "0.697897897897898", distinctSitesP: "0.29009009009009", brLenTotal: "0.8593" },
  { geneTranscript: "NM_052936.5", parsimonySitesP: "0.128140703517588", constantSitesP: "0.777219430485762", distinctSitesP: "0.187604690117253", brLenTotal: "0.3745" },
  { geneTranscript: "NM_172225.2", parsimonySitesP: "0.163572060123784", constantSitesP: "0.729442970822281", distinctSitesP: "0.232537577365164", brLenTotal: "0.7267" },
  { geneTranscript: "NM_000864.5", parsimonySitesP: "0.231653404067197", constantSitesP: "0.584438549955791", distinctSitesP: "0.335985853227233", brLenTotal: "1.2457" },
  { geneTranscript: "NM_000281.4", parsimonySitesP: "0.150641025641026", constantSitesP: "0.791666666666667", distinctSitesP: "0.221153846153846", brLenTotal: "0.4609" },
  { geneTranscript: "NM_004136.4", parsimonySitesP: "0.119072343371409", constantSitesP: "0.769470404984424", distinctSitesP: "0.174800969193493", brLenTotal: "0.4017" },
  { geneTranscript: "NM_014409.4", parsimonySitesP: "0.153367289190719", constantSitesP: "0.669496321448783", distinctSitesP: "0.227504244482173", brLenTotal: "0.6714" },
  { geneTranscript: "NM_001002034.3", parsimonySitesP: "0.283140283140283", constantSitesP: "0.504504504504505", distinctSitesP: "0.442728442728443", brLenTotal: "1.0612" },
  { geneTranscript: "NM_207163.3", parsimonySitesP: "0.226081657525899", constantSitesP: "0.644728823887873", distinctSitesP: "0.350396099939062", brLenTotal: "1.0024" },
  { geneTranscript: "NM_016403.4", parsimonySitesP: "0.135371179039301", constantSitesP: "0.692867540029112", distinctSitesP: "0.244541484716157", brLenTotal: "0.5733" },
  { geneTranscript: "NM_006779.4", parsimonySitesP: "0.220634920634921", constantSitesP: "0.653968253968254", distinctSitesP: "0.306349206349206", brLenTotal: "1.0326" },
  { geneTranscript: "NM_020646.3", parsimonySitesP: "0.281767955801105", constantSitesP: "0.552486187845304", distinctSitesP: "0.425414364640884", brLenTotal: "0.9608" },
  { geneTranscript: "NM_001195243.2", parsimonySitesP: "0.266666666666667", constantSitesP: "0.569230769230769", distinctSitesP: "0.492307692307692", brLenTotal: "1.2073" },
  { geneTranscript: "NM_175887.3", parsimonySitesP: "0.276485788113695", constantSitesP: "0.537467700258398", distinctSitesP: "0.457364341085271", brLenTotal: "1.0661" },
  { geneTranscript: "NM_024600.6", parsimonySitesP: "0.255162241887906", constantSitesP: "0.616519174041298", distinctSitesP: "0.34070796460177", brLenTotal: "1.4437" },
  { geneTranscript: "NM_013352.4", parsimonySitesP: "0.183368128044537", constantSitesP: "0.674669450243563", distinctSitesP: "0.251913709116214", brLenTotal: "0.6817" },
  { geneTranscript: "NM_003031.4", parsimonySitesP: "0.134751773049645", constantSitesP: "0.732860520094563", distinctSitesP: "0.213947990543735", brLenTotal: "0.5508" },
  { geneTranscript: "NM_020928.2", parsimonySitesP: "0.121810699588477", constantSitesP: "0.785733882030178", distinctSitesP: "0.194513031550069", brLenTotal: "0.5117" },
  { geneTranscript: "NM_001343.4", parsimonySitesP: "0.169264069264069", constantSitesP: "0.717748917748918", distinctSitesP: "0.254112554112554", brLenTotal: "0.5531" },
  { geneTranscript: "NM_004497.3", parsimonySitesP: "0.208571428571429", constantSitesP: "0.678095238095238", distinctSitesP: "0.29047619047619", brLenTotal: "0.8466" },
  { geneTranscript: "NM_001321571.2", parsimonySitesP: "0.135709818636648", constantSitesP: "0.796747967479675", distinctSitesP: "0.181988742964353", brLenTotal: "0.4252" },
  { geneTranscript: "NM_006271.2", parsimonySitesP: "0.131205673758865", constantSitesP: "0.730496453900709", distinctSitesP: "0.283687943262411", brLenTotal: "0.5227" },
  { geneTranscript: "NM_030954.4", parsimonySitesP: "0.148578811369509", constantSitesP: "0.748062015503876", distinctSitesP: "0.254521963824289", brLenTotal: "0.4833" },
  { geneTranscript: "NM_001002255.2", parsimonySitesP: "0.368421052631579", constantSitesP: "0.364912280701754", distinctSitesP: "0.63859649122807", brLenTotal: "1.6815" },
  { geneTranscript: "NM_001012750.3", parsimonySitesP: "0.103950103950104", constantSitesP: "0.808038808038808", distinctSitesP: "0.159390159390159", brLenTotal: "0.3780" },
  { geneTranscript: "NM_002625.4", parsimonySitesP: "0.169143665958953", constantSitesP: "0.737438075017693", distinctSitesP: "0.241330502476999", brLenTotal: "0.5148" },
  { geneTranscript: "NM_002567.4", parsimonySitesP: "0.215686274509804", constantSitesP: "0.632798573975045", distinctSitesP: "0.342245989304813", brLenTotal: "0.9330" },
  { geneTranscript: "NM_145054.5", parsimonySitesP: "0.212365591397849", constantSitesP: "0.663978494623656", distinctSitesP: "0.308602150537634", brLenTotal: "0.8330" },
  { geneTranscript: "NM_016479.6", parsimonySitesP: "0.268055555555556", constantSitesP: "0.565277777777778", distinctSitesP: "0.405555555555556", brLenTotal: "1.1411" },
  { geneTranscript: "NM_004630.4", parsimonySitesP: "0.118414188836724", constantSitesP: "0.752738654147105", distinctSitesP: "0.19509650495566", brLenTotal: "0.5113" },
  { geneTranscript: "NM_198570.5", parsimonySitesP: "0.163076923076923", constantSitesP: "0.725128205128205", distinctSitesP: "0.245128205128205", brLenTotal: "0.7065" },
  { geneTranscript: "NM_001031710.3", parsimonySitesP: "0.104664391353811", constantSitesP: "0.808304891922639", distinctSitesP: "0.156427758816837", brLenTotal: "0.3577" },
  { geneTranscript: "NM_033362.4", parsimonySitesP: "0.229468599033816", constantSitesP: "0.644927536231884", distinctSitesP: "0.364734299516908", brLenTotal: "0.9670" },
  { geneTranscript: "NM_001145252.3", parsimonySitesP: "0.319829424307036", constantSitesP: "0.53589196872779", distinctSitesP: "0.427149964463397", brLenTotal: "1.1886" },
  { geneTranscript: "NM_003801.4", parsimonySitesP: "0.234567901234568", constantSitesP: "0.632313472893183", distinctSitesP: "0.30703166935051", brLenTotal: "0.9336" },
  { geneTranscript: "NM_001164161.2", parsimonySitesP: "0.132493318060328", constantSitesP: "0.775486827033219", distinctSitesP: "0.187476135929744", brLenTotal: "0.4760" },
  { geneTranscript: "NM_133642.5", parsimonySitesP: "0.180776014109347", constantSitesP: "0.734126984126984", distinctSitesP: "0.217813051146384", brLenTotal: "0.7644" },
  { geneTranscript: "NM_080431.5", parsimonySitesP: "0.395225464190981", constantSitesP: "0.47656940760389", distinctSitesP: "0.493368700265252", brLenTotal: "2.0281" },
  { geneTranscript: "NM_017658.5", parsimonySitesP: "0.118505545826036", constantSitesP: "0.798015178050204", distinctSitesP: "0.170461179217747", brLenTotal: "0.3688" },
  { geneTranscript: "NM_001386795.1", parsimonySitesP: "0.14978354978355", constantSitesP: "0.77012987012987", distinctSitesP: "0.204329004329004", brLenTotal: "0.5131" },
  { geneTranscript: "NM_018170.5", parsimonySitesP: "0.120726495726496", constantSitesP: "0.804487179487179", distinctSitesP: "0.178418803418803", brLenTotal: "0.3469" },
  { geneTranscript: "NM_016457.5", parsimonySitesP: "0.186788154897494", constantSitesP: "0.707289293849658", distinctSitesP: "0.23955960516325", brLenTotal: "0.8094" },
  { geneTranscript: "NM_004302.5", parsimonySitesP: "0.13993399339934", constantSitesP: "0.781518151815182", distinctSitesP: "0.18019801980198", brLenTotal: "0.5415" },
  { geneTranscript: "NM_020871.4", parsimonySitesP: "0.126797385620915", constantSitesP: "0.772113289760349", distinctSitesP: "0.208278867102397", brLenTotal: "0.3724" },
  { geneTranscript: "NM_000399.5", parsimonySitesP: "0.149859943977591", constantSitesP: "0.76890756302521", distinctSitesP: "0.205882352941176", brLenTotal: "0.5214" },
  { geneTranscript: "NM_001441.3", parsimonySitesP: "0.233160621761658", constantSitesP: "0.630972941853771", distinctSitesP: "0.308578008059873", brLenTotal: "0.8671" },
  { geneTranscript: "NM_021633.4", parsimonySitesP: "0.160798122065728", constantSitesP: "0.714201877934272", distinctSitesP: "0.231807511737089", brLenTotal: "0.5823" },
  { geneTranscript: "NM_001039182.4", parsimonySitesP: "0.162790697674419", constantSitesP: "0.635658914728682", distinctSitesP: "0.356589147286822", brLenTotal: "0.5524" },
  { geneTranscript: "NM_015391.4", parsimonySitesP: "0.103603603603604", constantSitesP: "0.662162162162162", distinctSitesP: "0.310810810810811", brLenTotal: "0.6410" },
  { geneTranscript: "NM_001013839.4", parsimonySitesP: "0.16812865497076", constantSitesP: "0.733918128654971", distinctSitesP: "0.228070175438596", brLenTotal: "0.6232" },
  { geneTranscript: "NM_001394530.1", parsimonySitesP: "0.224043715846995", constantSitesP: "0.612021857923497", distinctSitesP: "0.398907103825137", brLenTotal: "0.6311" },
  { geneTranscript: "NM_174905.4", parsimonySitesP: "0.305635148042025", constantSitesP: "0.520534861509074", distinctSitesP: "0.44603629417383", brLenTotal: "1.0512" },
  { geneTranscript: "NM_181351.5", parsimonySitesP: "0.146853146853147", constantSitesP: "0.775058275058275", distinctSitesP: "0.18997668997669", brLenTotal: "0.6152" },
  { geneTranscript: "NM_000967.4", parsimonySitesP: "0.233250620347395", constantSitesP: "0.607940446650124", distinctSitesP: "0.355665839536807", brLenTotal: "1.2301" },
  { geneTranscript: "NM_001496.4", parsimonySitesP: "0.265", constantSitesP: "0.564166666666667", distinctSitesP: "0.386666666666667", brLenTotal: "0.9117" },
  { geneTranscript: "NM_012305.4", parsimonySitesP: "0.231806886758963", constantSitesP: "0.681931132410366", distinctSitesP: "0.29215477458289", brLenTotal: "1.3831" },
  { geneTranscript: "NM_000026.4", parsimonySitesP: "0.173553719008264", constantSitesP: "0.715564738292011", distinctSitesP: "0.254820936639118", brLenTotal: "0.5887" },
  { geneTranscript: "NM_007121.7", parsimonySitesP: "0.234782608695652", constantSitesP: "0.669565217391304", distinctSitesP: "0.301449275362319", brLenTotal: "1.0388" },
  { geneTranscript: "NM_001384332.1", parsimonySitesP: "0.121693121693122", constantSitesP: "0.804232804232804", distinctSitesP: "0.216931216931217", brLenTotal: "0.5174" },
  { geneTranscript: "NM_138333.5", parsimonySitesP: "0.0894308943089431", constantSitesP: "0.836236933797909", distinctSitesP: "0.145180023228804", brLenTotal: "0.2732" },
  { geneTranscript: "NM_015207.2", parsimonySitesP: "0.161641541038526", constantSitesP: "0.71608040201005", distinctSitesP: "0.272194304857621", brLenTotal: "0.5296" },
  { geneTranscript: "NM_000697.3", parsimonySitesP: "0.232780291603821", constantSitesP: "0.603318250377074", distinctSitesP: "0.312719959778783", brLenTotal: "0.8697" },
  { geneTranscript: "NM_013260.8", parsimonySitesP: "0.133116883116883", constantSitesP: "0.772727272727273", distinctSitesP: "0.205627705627706", brLenTotal: "0.4815" },
  { geneTranscript: "NM_182931.3", parsimonySitesP: "0.112307140294223", constantSitesP: "0.795479009687836", distinctSitesP: "0.145317545748116", brLenTotal: "0.3642" },
  { geneTranscript: "NM_022072.5", parsimonySitesP: "0.234313725490196", constantSitesP: "0.590196078431373", distinctSitesP: "0.384313725490196", brLenTotal: "0.8179" },
  { geneTranscript: "NM_001358661.2", parsimonySitesP: "0.232592592592593", constantSitesP: "0.604938271604938", distinctSitesP: "0.310617283950617", brLenTotal: "0.9575" },
  { geneTranscript: "NM_153442.4", parsimonySitesP: "0.157270029673591", constantSitesP: "0.732937685459941", distinctSitesP: "0.228486646884273", brLenTotal: "0.7282" },
  { geneTranscript: "NM_018358.3", parsimonySitesP: "0.172543488481429", constantSitesP: "0.724024447578749", distinctSitesP: "0.230841560883874", brLenTotal: "0.6358" },
  { geneTranscript: "NM_019034.3", parsimonySitesP: "0.202211690363349", constantSitesP: "0.682464454976303", distinctSitesP: "0.30173775671406", brLenTotal: "0.7892" },
  { geneTranscript: "NM_016261.4", parsimonySitesP: "0.194996320824135", constantSitesP: "0.646799116997792", distinctSitesP: "0.311994113318617", brLenTotal: "0.6722" },
  { geneTranscript: "NM_031229.4", parsimonySitesP: "0.173202614379085", constantSitesP: "0.718954248366013", distinctSitesP: "0.23921568627451", brLenTotal: "0.7326" },
  { geneTranscript: "NM_080388.3", parsimonySitesP: "0.171521035598706", constantSitesP: "0.73462783171521", distinctSitesP: "0.262135922330097", brLenTotal: "0.6214" },
  { geneTranscript: "NM_030577.3", parsimonySitesP: "0.302250803858521", constantSitesP: "0.542336548767417", distinctSitesP: "0.423365487674169", brLenTotal: "1.0071" },
  { geneTranscript: "NM_000051.4", parsimonySitesP: "0.183027923211169", constantSitesP: "0.681609947643979", distinctSitesP: "0.260034904013962", brLenTotal: "0.6023" },
  { geneTranscript: "NM_006715.4", parsimonySitesP: "0.235897435897436", constantSitesP: "0.628846153846154", distinctSitesP: "0.278525641025641", brLenTotal: "0.8904" },
  { geneTranscript: "NM_001395749.1", parsimonySitesP: "0.470164609053498", constantSitesP: "0.371399176954733", distinctSitesP: "0.544238683127572", brLenTotal: "1.7414" },
  { geneTranscript: "NM_015203.5", parsimonySitesP: "0.130276066621036", constantSitesP: "0.766141911932466", distinctSitesP: "0.163586584531143", brLenTotal: "0.4176" },
  { geneTranscript: "NM_017807.4", parsimonySitesP: "0.1681592039801", constantSitesP: "0.728358208955224", distinctSitesP: "0.241791044776119", brLenTotal: "0.5220" },
  { geneTranscript: "NM_001249.5", parsimonySitesP: "0.165109034267913", constantSitesP: "0.698598130841121", distinctSitesP: "0.253115264797508", brLenTotal: "0.5420" },
  { geneTranscript: "NM_002072.5", parsimonySitesP: "0.119777158774373", constantSitesP: "0.812441968430826", distinctSitesP: "0.168987929433612", brLenTotal: "0.4328" },
  { geneTranscript: "NM_004382.5", parsimonySitesP: "0.147791164658635", constantSitesP: "0.734136546184739", distinctSitesP: "0.236144578313253", brLenTotal: "0.6371" },
  { geneTranscript: "NM_003562.5", parsimonySitesP: "0.156050955414013", constantSitesP: "0.756900212314225", distinctSitesP: "0.217622080679406", brLenTotal: "0.5284" },
  { geneTranscript: "NM_004877.4", parsimonySitesP: "0.150234741784038", constantSitesP: "0.784037558685446", distinctSitesP: "0.21830985915493", brLenTotal: "0.6366" },
  { geneTranscript: "NM_033655.5", parsimonySitesP: "0.293478260869565", constantSitesP: "0.539596273291925", distinctSitesP: "0.393892339544513", brLenTotal: "1.3452" },
  { geneTranscript: "NM_145236.3", parsimonySitesP: "0.252701579384871", constantSitesP: "0.645054031587697", distinctSitesP: "0.333333333333333", brLenTotal: "1.1275" },
  { geneTranscript: "NM_001127898.4", parsimonySitesP: "0.153186274509804", constantSitesP: "0.749183006535948", distinctSitesP: "0.178921568627451", brLenTotal: "0.4995" },
  { geneTranscript: "NM_018082.6", parsimonySitesP: "0.119152691968226", constantSitesP: "0.794939688143572", distinctSitesP: "0.163871726978523", brLenTotal: "0.4118" },
  { geneTranscript: "NM_001164211.2", parsimonySitesP: "0.155963302752294", constantSitesP: "0.714722586282219", distinctSitesP: "0.267365661861075", brLenTotal: "0.5866" },
  { geneTranscript: "NM_002807.4", parsimonySitesP: "0.10912906610703", constantSitesP: "0.809723679608255", distinctSitesP: "0.151801329136062", brLenTotal: "0.3432" },
  { geneTranscript: "NM_152550.4", parsimonySitesP: "0.248742569730224", constantSitesP: "0.601280292638317", distinctSitesP: "0.326474622770919", brLenTotal: "0.9512" },
  { geneTranscript: "NM_012315.2", parsimonySitesP: "0.236", constantSitesP: "0.628", distinctSitesP: "0.342666666666667", brLenTotal: "1.0173" },
  { geneTranscript: "NM_020459.1", parsimonySitesP: "0.138211382113821", constantSitesP: "0.769647696476965", distinctSitesP: "0.230352303523035", brLenTotal: "0.4557" },
  { geneTranscript: "NM_002178.3", parsimonySitesP: "0.281944444444444", constantSitesP: "0.581944444444444", distinctSitesP: "0.388888888888889", brLenTotal: "0.8875" },
  { geneTranscript: "NM_001388498.1", parsimonySitesP: "0.534833869239014", constantSitesP: "0.385852090032154", distinctSitesP: "0.618435155412647", brLenTotal: "3.0628" },
  { geneTranscript: "NM_006243.4", parsimonySitesP: "0.0843621399176955", constantSitesP: "0.842935528120713", distinctSitesP: "0.146776406035665", brLenTotal: "0.2415" },
  { geneTranscript: "NM_018674.6", parsimonySitesP: "0.147186147186147", constantSitesP: "0.758194186765615", distinctSitesP: "0.195423623995053", brLenTotal: "0.5344" },
  { geneTranscript: "NM_002210.5", parsimonySitesP: "0.157442748091603", constantSitesP: "0.743320610687023", distinctSitesP: "0.224872773536896", brLenTotal: "0.5204" },
  { geneTranscript: "NM_001363118.2", parsimonySitesP: "0.319850187265918", constantSitesP: "0.522097378277154", distinctSitesP: "0.417228464419476", brLenTotal: "1.4994" },
  { geneTranscript: "NM_004050.5", parsimonySitesP: "0.0915371329879102", constantSitesP: "0.853195164075993", distinctSitesP: "0.139896373056995", brLenTotal: "0.3022" },
  { geneTranscript: "NM_198447.2", parsimonySitesP: "0.26010101010101", constantSitesP: "0.590909090909091", distinctSitesP: "0.431818181818182", brLenTotal: "0.8798" },
  { geneTranscript: "NM_001162371.3", parsimonySitesP: "0.277310924369748", constantSitesP: "0.515406162464986", distinctSitesP: "0.492997198879552", brLenTotal: "1.1247" },
  { geneTranscript: "NM_022149.5", parsimonySitesP: "0.369163952225841", constantSitesP: "0.363735070575461", distinctSitesP: "0.577633007600434", brLenTotal: "1.5485" },
  { geneTranscript: "NM_032488.4", parsimonySitesP: "0.163690476190476", constantSitesP: "0.761904761904762", distinctSitesP: "0.24702380952381", brLenTotal: "0.4840" },
  { geneTranscript: "NM_001005466.2", parsimonySitesP: "0.39247311827957", constantSitesP: "0.397849462365591", distinctSitesP: "0.570967741935484", brLenTotal: "2.5608" },
  { geneTranscript: "NM_001366207.1", parsimonySitesP: "0.11123553564763", constantSitesP: "0.792086599477417", distinctSitesP: "0.166106756252333", brLenTotal: "0.3679" },
  { geneTranscript: "NM_018090.5", parsimonySitesP: "0.168567807351077", constantSitesP: "0.67680608365019", distinctSitesP: "0.315589353612167", brLenTotal: "0.7057" },
  { geneTranscript: "NM_007286.6", parsimonySitesP: "0.244739756367663", constantSitesP: "0.609449981543005", distinctSitesP: "0.311184939091916", brLenTotal: "1.0977" },
  { geneTranscript: "NM_019594.4", parsimonySitesP: "0.164197530864198", constantSitesP: "0.687242798353909", distinctSitesP: "0.203703703703704", brLenTotal: "1.1537" },
  { geneTranscript: "NM_018008.4", parsimonySitesP: "0.148148148148148", constantSitesP: "0.757443718228032", distinctSitesP: "0.221496005809731", brLenTotal: "0.5735" },
  { geneTranscript: "NM_012088.3", parsimonySitesP: "0.254521963824289", constantSitesP: "0.621447028423773", distinctSitesP: "0.335917312661499", brLenTotal: "0.9663" },
  { geneTranscript: "NM_174921.3", parsimonySitesP: "0.148148148148148", constantSitesP: "0.734006734006734", distinctSitesP: "0.279461279461279", brLenTotal: "0.4472" },
  { geneTranscript: "NM_018947.6", parsimonySitesP: "0.171428571428571", constantSitesP: "0.60952380952381", distinctSitesP: "0.358730158730159", brLenTotal: "0.8201" },
  { geneTranscript: "NM_002516.4", parsimonySitesP: "0.113143631436314", constantSitesP: "0.772357723577236", distinctSitesP: "0.177506775067751", brLenTotal: "1.0113" },
  { geneTranscript: "NM_016192.4", parsimonySitesP: "0.0980392156862745", constantSitesP: "0.810160427807487", distinctSitesP: "0.174688057040998", brLenTotal: "0.3678" },
  { geneTranscript: "NM_005391.5", parsimonySitesP: "0.13136288998358", constantSitesP: "0.761904761904762", distinctSitesP: "0.206075533661741", brLenTotal: "0.4501" },
  { geneTranscript: "NM_001135196.2", parsimonySitesP: "0.353542392566783", constantSitesP: "0.453193960511034", distinctSitesP: "0.50801393728223", brLenTotal: "1.4926" },
  { geneTranscript: "NM_032373.5", parsimonySitesP: "0.0859375", constantSitesP: "0.822916666666667", distinctSitesP: "0.165364583333333", brLenTotal: "0.2893" },
  { geneTranscript: "NM_024852.4", parsimonySitesP: "0.107751937984496", constantSitesP: "0.812015503875969", distinctSitesP: "0.138372093023256", brLenTotal: "0.3663" },
  { geneTranscript: "NM_001754.5", parsimonySitesP: "0.109027777777778", constantSitesP: "0.826388888888889", distinctSitesP: "0.16875", brLenTotal: "0.4291" },
  { geneTranscript: "NM_004756.5", parsimonySitesP: "0.197591680350301", constantSitesP: "0.710454296661193", distinctSitesP: "0.26984126984127", brLenTotal: "0.8620" },
  { geneTranscript: "NM_032801.5", parsimonySitesP: "0.2", constantSitesP: "0.689247311827957", distinctSitesP: "0.29247311827957", brLenTotal: "0.8467" },
  { geneTranscript: "NM_022749.7", parsimonySitesP: "0.260655002243158", constantSitesP: "0.598474652310453", distinctSitesP: "0.349932705248991", brLenTotal: "1.1072" },
  { geneTranscript: "NM_018373.3", parsimonySitesP: "0.193103448275862", constantSitesP: "0.579310344827586", distinctSitesP: "0.36551724137931", brLenTotal: "0.7000" },
  { geneTranscript: "NM_178570.3", parsimonySitesP: "0.202380952380952", constantSitesP: "0.71984126984127", distinctSitesP: "0.196825396825397", brLenTotal: "0.5924" },
  { geneTranscript: "NM_012428.4", parsimonySitesP: "0.0971524288107203", constantSitesP: "0.78391959798995", distinctSitesP: "0.175041876046901", brLenTotal: "0.4184" },
  { geneTranscript: "NM_001004460.2", parsimonySitesP: "0.475247524752475", constantSitesP: "0.358635863586359", distinctSitesP: "0.584158415841584", brLenTotal: "3.2437" },
  { geneTranscript: "NM_002578.5", parsimonySitesP: "0.112745098039216", constantSitesP: "0.827205882352941", distinctSitesP: "0.153186274509804", brLenTotal: "0.3378" },
  { geneTranscript: "NM_014402.5", parsimonySitesP: "0.349593495934959", constantSitesP: "0.536585365853659", distinctSitesP: "0.479674796747967", brLenTotal: "1.0228" },
  { geneTranscript: "NM_014906.5", parsimonySitesP: "0.155408388520971", constantSitesP: "0.712582781456954", distinctSitesP: "0.254304635761589", brLenTotal: "0.5381" },
  { geneTranscript: "NM_005047.4", parsimonySitesP: "0.158068783068783", constantSitesP: "0.715608465608466", distinctSitesP: "0.228174603174603", brLenTotal: "0.5145" },
  { geneTranscript: "NM_000230.3", parsimonySitesP: "0.327345309381238", constantSitesP: "0.528942115768463", distinctSitesP: "0.512974051896208", brLenTotal: "1.1057" },
  { geneTranscript: "NM_016046.5", parsimonySitesP: "0.153846153846154", constantSitesP: "0.726495726495726", distinctSitesP: "0.256410256410256", brLenTotal: "0.5265" },
  { geneTranscript: "NM_013975.4", parsimonySitesP: "0.18401057152296", constantSitesP: "0.695407994714239", distinctSitesP: "0.269573835480674", brLenTotal: "0.6149" },
  { geneTranscript: "NM_005477.3", parsimonySitesP: "0.213078415073428", constantSitesP: "0.671931282903851", distinctSitesP: "0.274591299528955", brLenTotal: "1.0597" },
  { geneTranscript: "NM_016289.4", parsimonySitesP: "0.104594330400782", constantSitesP: "0.826979472140762", distinctSitesP: "0.164222873900293", brLenTotal: "0.3708" },
  { geneTranscript: "NM_002472.3", parsimonySitesP: "0.229392531405954", constantSitesP: "0.668215453450353", distinctSitesP: "0.275856134916538", brLenTotal: "1.3841" },
  { geneTranscript: "NM_020710.3", parsimonySitesP: "0.258433390508862", constantSitesP: "0.636363636363636", distinctSitesP: "0.331046312178388", brLenTotal: "1.1592" },
  { geneTranscript: "NM_001080458.2", parsimonySitesP: "0.198879551820728", constantSitesP: "0.707983193277311", distinctSitesP: "0.273809523809524", brLenTotal: "0.8640" },
  { geneTranscript: "NM_016584.3", parsimonySitesP: "0.204585537918871", constantSitesP: "0.641975308641975", distinctSitesP: "0.317460317460317", brLenTotal: "0.6361" },
  { geneTranscript: "NM_203306.3", parsimonySitesP: "0.235042735042735", constantSitesP: "0.581196581196581", distinctSitesP: "0.44017094017094", brLenTotal: "1.3273" },
  { geneTranscript: "NM_000617.3", parsimonySitesP: "0.168746286393345", constantSitesP: "0.702317290552585", distinctSitesP: "0.262626262626263", brLenTotal: "0.5708" },
  { geneTranscript: "NM_014586.2", parsimonySitesP: "0.188608776844071", constantSitesP: "0.697012138188609", distinctSitesP: "0.274976657329599", brLenTotal: "0.8094" },
  { geneTranscript: "NM_018941.4", parsimonySitesP: "0.305361305361305", constantSitesP: "0.590909090909091", distinctSitesP: "0.403263403263403", brLenTotal: "1.3070" },
  { geneTranscript: "NM_020940.4", parsimonySitesP: "0.16078431372549", constantSitesP: "0.752069716775599", distinctSitesP: "0.227450980392157", brLenTotal: "0.5239" },
  { geneTranscript: "NM_014856.3", parsimonySitesP: "0.171791443850267", constantSitesP: "0.712789661319073", distinctSitesP: "0.212566844919786", brLenTotal: "0.6408" },
  { geneTranscript: "NM_001727.2", parsimonySitesP: "0.167919799498747", constantSitesP: "0.698412698412698", distinctSitesP: "0.268170426065163", brLenTotal: "0.5375" },
  { geneTranscript: "NM_000234.3", parsimonySitesP: "0.242655059847661", constantSitesP: "0.614435981138919", distinctSitesP: "0.358360536815379", brLenTotal: "1.1138" },
  { geneTranscript: "NM_024294.4", parsimonySitesP: "0.0648769574944072", constantSitesP: "0.857941834451902", distinctSitesP: "0.135346756152125", brLenTotal: "0.2102" },
  { geneTranscript: "NM_198565.3", parsimonySitesP: "0.267341040462428", constantSitesP: "0.581888246628131", distinctSitesP: "0.335741811175337", brLenTotal: "1.0460" },
  { geneTranscript: "NM_001003796.2", parsimonySitesP: "0.192708333333333", constantSitesP: "0.59375", distinctSitesP: "0.40625", brLenTotal: "0.8067" },
  { geneTranscript: "NM_001364140.2", parsimonySitesP: "0.111842105263158", constantSitesP: "0.81140350877193", distinctSitesP: "0.160087719298246", brLenTotal: "0.3596" },
  { geneTranscript: "NM_001004451.1", parsimonySitesP: "0.563146997929607", constantSitesP: "0.298136645962733", distinctSitesP: "0.703933747412008", brLenTotal: "3.9323" },
  { geneTranscript: "NM_025236.4", parsimonySitesP: "0.253787878787879", constantSitesP: "0.612689393939394", distinctSitesP: "0.359848484848485", brLenTotal: "0.9553" },
  { geneTranscript: "NM_080744.2", parsimonySitesP: "0.255072463768116", constantSitesP: "0.590724637681159", distinctSitesP: "0.364057971014493", brLenTotal: "1.0560" },
  { geneTranscript: "NM_144664.5", parsimonySitesP: "0.103244837758112", constantSitesP: "0.819075712881023", distinctSitesP: "0.174041297935103", brLenTotal: "0.3267" },
  { geneTranscript: "NM_024336.3", parsimonySitesP: "0.221556886227545", constantSitesP: "0.63606121091151", distinctSitesP: "0.327345309381238", brLenTotal: "0.9254" },
  { geneTranscript: "NM_012367.1", parsimonySitesP: "0.542066027689031", constantSitesP: "0.314164004259851", distinctSitesP: "0.670926517571885", brLenTotal: "2.5889" },
  { geneTranscript: "NM_032846.4", parsimonySitesP: "0.0895061728395062", constantSitesP: "0.817901234567901", distinctSitesP: "0.17283950617284", brLenTotal: "0.2870" },
  { geneTranscript: "NM_012430.5", parsimonySitesP: "0.0890336590662324", constantSitesP: "0.820846905537459", distinctSitesP: "0.162866449511401", brLenTotal: "0.2994" },
  { geneTranscript: "NM_016563.4", parsimonySitesP: "0.180451127819549", constantSitesP: "0.682957393483709", distinctSitesP: "0.280701754385965", brLenTotal: "0.7658" },
  { geneTranscript: "NM_001082968.2", parsimonySitesP: "0.169625246548323", constantSitesP: "0.726495726495726", distinctSitesP: "0.238001314924392", brLenTotal: "0.6606" },
  { geneTranscript: "NM_018464.5", parsimonySitesP: "0.16358024691358", constantSitesP: "0.660493827160494", distinctSitesP: "0.330246913580247", brLenTotal: "0.5458" },
  { geneTranscript: "NM_032160.3", parsimonySitesP: "0.158140814081408", constantSitesP: "0.727172717271727", distinctSitesP: "0.185368536853685", brLenTotal: "0.5010" },
  { geneTranscript: "NM_014909.5", parsimonySitesP: "0.133333333333333", constantSitesP: "0.764383561643836", distinctSitesP: "0.212785388127854", brLenTotal: "0.5675" },
  { geneTranscript: "NM_173660.5", parsimonySitesP: "0.271164021164021", constantSitesP: "0.606481481481481", distinctSitesP: "0.363756613756614", brLenTotal: "1.4231" },
  { geneTranscript: "NM_198925.4", parsimonySitesP: "0.257666268418957", constantSitesP: "0.595380326563122", distinctSitesP: "0.327359617682198", brLenTotal: "1.1100" },
  { geneTranscript: "NM_001719.3", parsimonySitesP: "0.153905645784996", constantSitesP: "0.769528228924981", distinctSitesP: "0.202629543696829", brLenTotal: "0.6726" },
  { geneTranscript: "NM_002951.5", parsimonySitesP: "0.159006867406233", constantSitesP: "0.717379820390914", distinctSitesP: "0.229265715795034", brLenTotal: "0.6186" },
  { geneTranscript: "NM_001276318.2", parsimonySitesP: "0.180406212664277", constantSitesP: "0.703703703703704", distinctSitesP: "0.254480286738351", brLenTotal: "0.7634" },
  { geneTranscript: "NM_024602.6", parsimonySitesP: "0.16298877274487", constantSitesP: "0.737514518002323", distinctSitesP: "0.202477739063105", brLenTotal: "0.5989" },
  { geneTranscript: "NM_016612.4", parsimonySitesP: "0.179487179487179", constantSitesP: "0.692307692307692", distinctSitesP: "0.255424063116371", brLenTotal: "0.7843" },
  { geneTranscript: "NM_002618.4", parsimonySitesP: "0.162944582299421", constantSitesP: "0.686517783291977", distinctSitesP: "0.277088502894955", brLenTotal: "0.5664" },
  { geneTranscript: "NM_000531.6", parsimonySitesP: "0.135593220338983", constantSitesP: "0.742937853107345", distinctSitesP: "0.249529190207156", brLenTotal: "0.4968" },
  { geneTranscript: "NM_006445.4", parsimonySitesP: "0.167023554603854", constantSitesP: "0.744325481798715", distinctSitesP: "0.184725196288365", brLenTotal: "0.5854" },
  { geneTranscript: "NM_024944.3", parsimonySitesP: "0.147741147741148", constantSitesP: "0.713064713064713", distinctSitesP: "0.264957264957265", brLenTotal: "0.5510" },
  { geneTranscript: "NM_001199161.2", parsimonySitesP: "0.172206165703276", constantSitesP: "0.697495183044316", distinctSitesP: "0.21868978805395", brLenTotal: "0.6978" },
  { geneTranscript: "NM_005694.2", parsimonySitesP: "0.216931216931217", constantSitesP: "0.671957671957672", distinctSitesP: "0.333333333333333", brLenTotal: "0.5396" },
  { geneTranscript: "NM_176096.3", parsimonySitesP: "0.200922266139657", constantSitesP: "0.672595520421607", distinctSitesP: "0.278656126482213", brLenTotal: "0.6785" },
  { geneTranscript: "NM_000428.3", parsimonySitesP: "0.230459454512173", constantSitesP: "0.607541643785466", distinctSitesP: "0.312648727805235", brLenTotal: "1.0563" },
  { geneTranscript: "NM_001008708.4", parsimonySitesP: "0.126811594202899", constantSitesP: "0.759057971014493", distinctSitesP: "0.242753623188406", brLenTotal: "0.4044" },
  { geneTranscript: "NM_031483.7", parsimonySitesP: "0.125676720804331", constantSitesP: "0.785769528228925", distinctSitesP: "0.172080433101315", brLenTotal: "0.3998" },
  { geneTranscript: "NM_001369667.1", parsimonySitesP: "0.290682414698163", constantSitesP: "0.545275590551181", distinctSitesP: "0.388451443569554", brLenTotal: "1.1215" },
  { geneTranscript: "NM_054016.4", parsimonySitesP: "0.122137404580153", constantSitesP: "0.709923664122137", distinctSitesP: "0.265903307888041", brLenTotal: "0.4919" },
  { geneTranscript: "NM_001031709.3", parsimonySitesP: "0.195906432748538", constantSitesP: "0.644249512670565", distinctSitesP: "0.351851851851852", brLenTotal: "0.6448" },
  { geneTranscript: "NM_020806.5", parsimonySitesP: "0.0767230169050715", constantSitesP: "0.853489380147378", distinctSitesP: "0.12136974425661", brLenTotal: "0.2391" },
  { geneTranscript: "NM_007355.4", parsimonySitesP: "0.221454880294659", constantSitesP: "0.610957642725599", distinctSitesP: "0.310773480662983", brLenTotal: "0.9361" },
  { geneTranscript: "NM_020820.4", parsimonySitesP: "0.192284508740205", constantSitesP: "0.697006228651798", distinctSitesP: "0.247940526421539", brLenTotal: "0.8391" },
  { geneTranscript: "NM_001161352.2", parsimonySitesP: "0.0960086299892125", constantSitesP: "0.848435814455232", distinctSitesP: "0.12891046386192", brLenTotal: "0.3706" },
  { geneTranscript: "NM_024761.5", parsimonySitesP: "0.186728395061728", constantSitesP: "0.762345679012346", distinctSitesP: "0.203703703703704", brLenTotal: "0.6558" },
  { geneTranscript: "NM_001799.4", parsimonySitesP: "0.115606936416185", constantSitesP: "0.783236994219653", distinctSitesP: "0.202312138728324", brLenTotal: "0.3843" },
  { geneTranscript: "NM_003553.3", parsimonySitesP: "0.605095541401274", constantSitesP: "0.251592356687898", distinctSitesP: "0.752653927813163", brLenTotal: "4.4624" },
  { geneTranscript: "NM_022356.4", parsimonySitesP: "0.221014492753623", constantSitesP: "0.656702898550725", distinctSitesP: "0.291213768115942", brLenTotal: "0.8306" },
  { geneTranscript: "NM_001012393.5", parsimonySitesP: "0.153846153846154", constantSitesP: "0.757396449704142", distinctSitesP: "0.227810650887574", brLenTotal: "0.6787" },
  { geneTranscript: "NM_016011.5", parsimonySitesP: "0.22609472743521", constantSitesP: "0.613941018766756", distinctSitesP: "0.342269883824844", brLenTotal: "0.8906" },
  { geneTranscript: "NM_052859.4", parsimonySitesP: "0.239063462723352", constantSitesP: "0.614910659272951", distinctSitesP: "0.335181762168823", brLenTotal: "0.8220" },
  { geneTranscript: "NM_001161625.2", parsimonySitesP: "0.273504273504274", constantSitesP: "0.52991452991453", distinctSitesP: "0.44017094017094", brLenTotal: "1.1383" },
  { geneTranscript: "NM_014351.4", parsimonySitesP: "0.132629107981221", constantSitesP: "0.803990610328638", distinctSitesP: "0.186619718309859", brLenTotal: "0.6121" },
  { geneTranscript: "NM_144695.4", parsimonySitesP: "0.151662611516626", constantSitesP: "0.715328467153285", distinctSitesP: "0.27007299270073", brLenTotal: "0.4943" },
  { geneTranscript: "NM_152484.3", parsimonySitesP: "0.247327502429543", constantSitesP: "0.594266277939747", distinctSitesP: "0.349368318756074", brLenTotal: "0.9318" },
  { geneTranscript: "NM_000447.3", parsimonySitesP: "0.183035714285714", constantSitesP: "0.730654761904762", distinctSitesP: "0.257440476190476", brLenTotal: "0.8486" },
  { geneTranscript: "NM_005451.5", parsimonySitesP: "0.191101385849745", constantSitesP: "0.703865791393144", distinctSitesP: "0.267687819110139", brLenTotal: "0.9014" },
  { geneTranscript: "NM_006982.3", parsimonySitesP: "0.128834355828221", constantSitesP: "0.773006134969325", distinctSitesP: "0.193251533742331", brLenTotal: "0.4212" },
  { geneTranscript: "NM_021785.6", parsimonySitesP: "0.174842767295597", constantSitesP: "0.706289308176101", distinctSitesP: "0.246540880503145", brLenTotal: "0.6805" },
  { geneTranscript: "NM_006819.3", parsimonySitesP: "0.166359729895641", constantSitesP: "0.688766114180479", distinctSitesP: "0.262737875997545", brLenTotal: "0.7196" },
  { geneTranscript: "NM_002336.3", parsimonySitesP: "0.117999586691465", constantSitesP: "0.795205620996074", distinctSitesP: "0.143418061582972", brLenTotal: "0.3712" },
  { geneTranscript: "NM_004519.4", parsimonySitesP: "0.172782874617737", constantSitesP: "0.737767584097859", distinctSitesP: "0.227064220183486", brLenTotal: "0.6632" },
  { geneTranscript: "NM_001378477.3", parsimonySitesP: "0.26890756302521", constantSitesP: "0.59733893557423", distinctSitesP: "0.34453781512605", brLenTotal: "1.1058" },
  { geneTranscript: "NM_001164508.2", parsimonySitesP: "0.218924731182796", constantSitesP: "0.677536656891496", distinctSitesP: "0.199452590420332", brLenTotal: "1.0221" },
  { geneTranscript: "NM_003392.7", parsimonySitesP: "0.135964912280702", constantSitesP: "0.764035087719298", distinctSitesP: "0.215789473684211", brLenTotal: "0.6311" },
  { geneTranscript: "NM_002903.3", parsimonySitesP: "0.188333333333333", constantSitesP: "0.718333333333333", distinctSitesP: "0.278333333333333", brLenTotal: "0.7768" },
  { geneTranscript: "NM_006946.4", parsimonySitesP: "0.219386331938633", constantSitesP: "0.670153417015342", distinctSitesP: "0.234449093444909", brLenTotal: "0.9851" },
  { geneTranscript: "NM_004776.4", parsimonySitesP: "0.154639175257732", constantSitesP: "0.72680412371134", distinctSitesP: "0.231958762886598", brLenTotal: "0.5737" },
  { geneTranscript: "NM_001297436.2", parsimonySitesP: "0.180820335066436", constantSitesP: "0.721548238012709", distinctSitesP: "0.239168110918544", brLenTotal: "0.7235" },
  { geneTranscript: "NM_030922.7", parsimonySitesP: "0.200925925925926", constantSitesP: "0.680555555555556", distinctSitesP: "0.292592592592593", brLenTotal: "0.7857" },
  { geneTranscript: "NM_000287.4", parsimonySitesP: "0.215646258503401", constantSitesP: "0.635034013605442", distinctSitesP: "0.282993197278912", brLenTotal: "0.7573" },
  { geneTranscript: "NM_024111.6", parsimonySitesP: "0.238738738738739", constantSitesP: "0.551051051051051", distinctSitesP: "0.39039039039039", brLenTotal: "1.0489" },
  { geneTranscript: "NM_001001965.1", parsimonySitesP: "0.552410901467505", constantSitesP: "0.329140461215933", distinctSitesP: "0.660377358490566", brLenTotal: "2.4742" },
  { geneTranscript: "NM_000969.5", parsimonySitesP: "0.130190796857464", constantSitesP: "0.704826038159371", distinctSitesP: "0.262626262626263", brLenTotal: "0.5583" },
  { geneTranscript: "NM_006801.3", parsimonySitesP: "0.136792452830189", constantSitesP: "0.79559748427673", distinctSitesP: "0.191823899371069", brLenTotal: "0.5119" },
  { geneTranscript: "NM_002867.4", parsimonySitesP: "0.167427701674277", constantSitesP: "0.722983257229833", distinctSitesP: "0.252663622526636", brLenTotal: "0.6650" },
  { geneTranscript: "NM_018320.5", parsimonySitesP: "0.119266055045872", constantSitesP: "0.809378185524975", distinctSitesP: "0.186544342507645", brLenTotal: "0.3978" },
  { geneTranscript: "NM_001656.4", parsimonySitesP: "0.12137049941928", constantSitesP: "0.778745644599303", distinctSitesP: "0.180023228803717", brLenTotal: "0.3842" },
  { geneTranscript: "NM_033133.5", parsimonySitesP: "0.193982581155978", constantSitesP: "0.694378463974663", distinctSitesP: "0.257323832145685", brLenTotal: "0.7292" },
  { geneTranscript: "NM_021969.3", parsimonySitesP: "0.250324254215305", constantSitesP: "0.579766536964981", distinctSitesP: "0.373540856031128", brLenTotal: "0.9190" },
  { geneTranscript: "NM_144949.3", parsimonySitesP: "0.154228855721393", constantSitesP: "0.603855721393035", distinctSitesP: "0.318407960199005", brLenTotal: "0.7580" },
  { geneTranscript: "NM_006059.4", parsimonySitesP: "0.311534391534392", constantSitesP: "0.53989417989418", distinctSitesP: "0.397460317460317", brLenTotal: "1.4340" },
  { geneTranscript: "NM_152328.5", parsimonySitesP: "0.195477753464624", constantSitesP: "0.711159737417943", distinctSitesP: "0.280087527352298", brLenTotal: "1.0227" },
  { geneTranscript: "NM_012151.4", parsimonySitesP: "0.215633423180593", constantSitesP: "0.562443845462713", distinctSitesP: "0.378256963162624", brLenTotal: "1.1861" },
  { geneTranscript: "NM_175884.6", parsimonySitesP: "0.215602836879433", constantSitesP: "0.669503546099291", distinctSitesP: "0.336170212765957", brLenTotal: "0.7765" },
  { geneTranscript: "NM_000578.4", parsimonySitesP: "0.214545454545455", constantSitesP: "0.657575757575758", distinctSitesP: "0.316969696969697", brLenTotal: "0.8206" },
  { geneTranscript: "NM_001146028.2", parsimonySitesP: "0.186836518046709", constantSitesP: "0.718683651804671", distinctSitesP: "0.204883227176221", brLenTotal: "0.5906" },
  { geneTranscript: "NM_033416.3", parsimonySitesP: "0.22794959908362", constantSitesP: "0.632302405498282", distinctSitesP: "0.332187857961054", brLenTotal: "0.9644" },
  { geneTranscript: "NM_000188.3", parsimonySitesP: "0.204289349327517", constantSitesP: "0.69429298436932", distinctSitesP: "0.261359505634315", brLenTotal: "0.8871" },
  { geneTranscript: "NM_001040456.3", parsimonySitesP: "0.226190476190476", constantSitesP: "0.639194139194139", distinctSitesP: "0.328754578754579", brLenTotal: "0.9365" },
  { geneTranscript: "NM_014181.3", parsimonySitesP: "0.122093023255814", constantSitesP: "0.790697674418605", distinctSitesP: "0.195736434108527", brLenTotal: "0.3937" },
  { geneTranscript: "NM_001673.5", parsimonySitesP: "0.199643493761141", constantSitesP: "0.622697563874034", distinctSitesP: "0.30600118835413", brLenTotal: "0.7622" },
  { geneTranscript: "NM_007137.5", parsimonySitesP: "0.233484619263742", constantSitesP: "0.530005042864347", distinctSitesP: "0.349974785678265", brLenTotal: "1.1296" },
  { geneTranscript: "NM_004798.4", parsimonySitesP: "0.138331102186524", constantSitesP: "0.756804997768853", distinctSitesP: "0.199910754127622", brLenTotal: "0.4965" },
  { geneTranscript: "NM_024876.4", parsimonySitesP: "0.233455882352941", constantSitesP: "0.646446078431373", distinctSitesP: "0.317401960784314", brLenTotal: "0.8847" },
  { geneTranscript: "NM_001135219.2", parsimonySitesP: "0.201353637901861", constantSitesP: "0.653130287648054", distinctSitesP: "0.285109983079526", brLenTotal: "0.7967" },
  { geneTranscript: "NM_001004738.2", parsimonySitesP: "0.637727759914255", constantSitesP: "0.279742765273312", distinctSitesP: "0.743837084673098", brLenTotal: "5.7622" },
  { geneTranscript: "NM_006268.5", parsimonySitesP: "0.14151747655584", constantSitesP: "0.757885763000853", distinctSitesP: "0.20460358056266", brLenTotal: "0.5406" },
  { geneTranscript: "NM_016203.4", parsimonySitesP: "0.157000585823081", constantSitesP: "0.753954305799649", distinctSitesP: "0.23784417106034", brLenTotal: "0.6344" },
  { geneTranscript: "NM_001353812.2", parsimonySitesP: "0.109241216415707", constantSitesP: "0.782403306761146", distinctSitesP: "0.160909359315028", brLenTotal: "0.3512" },
  { geneTranscript: "NM_025077.4", parsimonySitesP: "0.202614379084967", constantSitesP: "0.63921568627451", distinctSitesP: "0.28562091503268", brLenTotal: "0.6417" },
  { geneTranscript: "NM_001297671.3", parsimonySitesP: "0.14453125", constantSitesP: "0.762152777777778", distinctSitesP: "0.205295138888889", brLenTotal: "0.5471" },
  { geneTranscript: "NM_005876.5", parsimonySitesP: "0.199469441893684", constantSitesP: "0.666666666666667", distinctSitesP: "0.212733394551576", brLenTotal: "0.8345" },
  { geneTranscript: "NM_173216.2", parsimonySitesP: "0.236453201970443", constantSitesP: "0.614121510673235", distinctSitesP: "0.334154351395731", brLenTotal: "0.7822" },
  { geneTranscript: "NM_001378107.1", parsimonySitesP: "0.106407995296884", constantSitesP: "0.783656672545561", distinctSitesP: "0.161375661375661", brLenTotal: "0.3837" },
  { geneTranscript: "NM_001394206.1", parsimonySitesP: "0.228650137741047", constantSitesP: "0.608815426997245", distinctSitesP: "0.391184573002755", brLenTotal: "1.0689" },
  { geneTranscript: "NM_001080514.3", parsimonySitesP: "0.275290215588723", constantSitesP: "0.587064676616915", distinctSitesP: "0.333333333333333", brLenTotal: "1.4254" },
  { geneTranscript: "NM_032192.4", parsimonySitesP: "0.191176470588235", constantSitesP: "0.69281045751634", distinctSitesP: "0.313725490196078", brLenTotal: "0.6200" },
  { geneTranscript: "NM_001355436.2", parsimonySitesP: "0.221076746849943", constantSitesP: "0.653636884306987", distinctSitesP: "0.241838487972509", brLenTotal: "0.9399" },
  { geneTranscript: "NM_015945.12", parsimonySitesP: "0.122374429223744", constantSitesP: "0.772602739726027", distinctSitesP: "0.193607305936073", brLenTotal: "0.4516" },
  { geneTranscript: "NM_022042.4", parsimonySitesP: "0.31621493105088", constantSitesP: "0.54303376129339", distinctSitesP: "0.427960057061341", brLenTotal: "1.6960" },
  { geneTranscript: "NM_003719.5", parsimonySitesP: "0.141619585687382", constantSitesP: "0.754425612052731", distinctSitesP: "0.2", brLenTotal: "0.5630" },
  { geneTranscript: "NM_003544.3", parsimonySitesP: "0.310679611650485", constantSitesP: "0.669902912621359", distinctSitesP: "0.343042071197411", brLenTotal: "2.7480" },
  { geneTranscript: "NM_014699.4", parsimonySitesP: "0.29075691411936", constantSitesP: "0.537663755458515", distinctSitesP: "0.336608442503639", brLenTotal: "0.9767" },
  { geneTranscript: "NM_181336.4", parsimonySitesP: "0.179589131875414", constantSitesP: "0.705765407554672", distinctSitesP: "0.26441351888668", brLenTotal: "0.6215" },
  { geneTranscript: "NM_173039.3", parsimonySitesP: "0.201722017220172", constantSitesP: "0.649446494464945", distinctSitesP: "0.306273062730627", brLenTotal: "0.6100" },
  { geneTranscript: "NM_004161.5", parsimonySitesP: "0.0650406504065041", constantSitesP: "0.879674796747967", distinctSitesP: "0.11219512195122", brLenTotal: "0.2234" },
  { geneTranscript: "NM_004769.4", parsimonySitesP: "0.266792215944758", constantSitesP: "0.591964846202134", distinctSitesP: "0.350282485875706", brLenTotal: "1.1942" },
  { geneTranscript: "NM_138418.4", parsimonySitesP: "0.18125", constantSitesP: "0.664583333333333", distinctSitesP: "0.31875", brLenTotal: "0.7102" },
  { geneTranscript: "NM_001370640.3", parsimonySitesP: "0.584886128364389", constantSitesP: "0.290890269151139", distinctSitesP: "0.702898550724638", brLenTotal: "4.2425" },
  { geneTranscript: "NM_003982.4", parsimonySitesP: "0.185909980430528", constantSitesP: "0.695368558382257", distinctSitesP: "0.262883235485975", brLenTotal: "0.6027" },
  { geneTranscript: "NM_032790.3", parsimonySitesP: "0.273730684326711", constantSitesP: "0.620309050772627", distinctSitesP: "0.353200883002208", brLenTotal: "1.3422" },
  { geneTranscript: "NM_001378.3", parsimonySitesP: "0.11128526645768", constantSitesP: "0.804597701149425", distinctSitesP: "0.170323928944619", brLenTotal: "0.3702" },
  { geneTranscript: "NM_206538.4", parsimonySitesP: "0.227735368956743", constantSitesP: "0.659033078880407", distinctSitesP: "0.324427480916031", brLenTotal: "1.1050" },
  { geneTranscript: "NM_020975.6", parsimonySitesP: "0.232495511669659", constantSitesP: "0.637342908438061", distinctSitesP: "0.298922800718133", brLenTotal: "0.9753" },
  { geneTranscript: "NM_139178.4", parsimonySitesP: "0.212121212121212", constantSitesP: "0.655011655011655", distinctSitesP: "0.344988344988345", brLenTotal: "0.8646" },
  { geneTranscript: "NM_001040436.3", parsimonySitesP: "0.230607966457023", constantSitesP: "0.624039133473096", distinctSitesP: "0.360587002096436", brLenTotal: "0.8144" },
  { geneTranscript: "NM_001005189.2", parsimonySitesP: "0.353846153846154", constantSitesP: "0.446153846153846", distinctSitesP: "0.475897435897436", brLenTotal: "1.5046" },
  { geneTranscript: "NM_006052.2", parsimonySitesP: "0.19304152637486", constantSitesP: "0.705948372615039", distinctSitesP: "0.290684624017957", brLenTotal: "0.7028" },
  { geneTranscript: "NM_138782.3", parsimonySitesP: "0.11522633744856", constantSitesP: "0.783539094650206", distinctSitesP: "0.187654320987654", brLenTotal: "0.3700" },
  { geneTranscript: "NM_005617.4", parsimonySitesP: "0.17439293598234", constantSitesP: "0.63355408388521", distinctSitesP: "0.34878587196468", brLenTotal: "0.7447" },
  { geneTranscript: "NM_002125.4", parsimonySitesP: "0.427318295739348", constantSitesP: "0.335839598997494", distinctSitesP: "0.631578947368421", brLenTotal: "3.2561" },
  { geneTranscript: "NM_001391963.1", parsimonySitesP: "0.160997732426304", constantSitesP: "0.709750566893424", distinctSitesP: "0.270975056689342", brLenTotal: "0.5247" },
  { geneTranscript: "NM_001364929.1", parsimonySitesP: "0.132862062715244", constantSitesP: "0.77542142468733", distinctSitesP: "0.164038426681167", brLenTotal: "0.4391" },
  { geneTranscript: "NM_014576.4", parsimonySitesP: "0.175767918088737", constantSitesP: "0.728100113765643", distinctSitesP: "0.234357224118316", brLenTotal: "0.5999" },
  { geneTranscript: "NM_001251974.2", parsimonySitesP: "0.149519890260631", constantSitesP: "0.744855967078189", distinctSitesP: "0.245541838134431", brLenTotal: "0.5298" },
  { geneTranscript: "NM_004443.4", parsimonySitesP: "0.193052772211089", constantSitesP: "0.683366733466934", distinctSitesP: "0.252171008684035", brLenTotal: "0.9556" },
  { geneTranscript: "NM_005481.3", parsimonySitesP: "0.216267578867351", constantSitesP: "0.677309007981756", distinctSitesP: "0.283542379323451", brLenTotal: "1.2002" },
  { geneTranscript: "NM_005513.3", parsimonySitesP: "0.148063781321185", constantSitesP: "0.733485193621868", distinctSitesP: "0.23538344722855", brLenTotal: "0.4940" },
  { geneTranscript: "NM_052867.4", parsimonySitesP: "0.156693517453011", constantSitesP: "0.770617568085923", distinctSitesP: "0.204065976217875", brLenTotal: "0.6116" },
  { geneTranscript: "NM_001696.4", parsimonySitesP: "0.153392330383481", constantSitesP: "0.705014749262537", distinctSitesP: "0.303834808259587", brLenTotal: "0.5838" },
  { geneTranscript: "NM_025248.3", parsimonySitesP: "0.164271625810087", constantSitesP: "0.739081431389124", distinctSitesP: "0.207945900253593", brLenTotal: "0.7133" },
  { geneTranscript: "NM_006221.4", parsimonySitesP: "0.157464212678937", constantSitesP: "0.703476482617587", distinctSitesP: "0.286298568507157", brLenTotal: "0.7806" },
  { geneTranscript: "NM_020383.4", parsimonySitesP: "0.150650650650651", constantSitesP: "0.743243243243243", distinctSitesP: "0.228228228228228", brLenTotal: "0.5547" },
  { geneTranscript: "NM_001377299.1", parsimonySitesP: "0.182145428365731", constantSitesP: "0.697624190064795", distinctSitesP: "0.275017998560115", brLenTotal: "0.6048" },
  { geneTranscript: "NM_153276.3", parsimonySitesP: "0.198787878787879", constantSitesP: "0.660606060606061", distinctSitesP: "0.291515151515152", brLenTotal: "0.7365" },
  { geneTranscript: "NM_175068.3", parsimonySitesP: "0.26358024691358", constantSitesP: "0.576543209876543", distinctSitesP: "0.384567901234568", brLenTotal: "1.4736" },
  { geneTranscript: "NM_033401.5", parsimonySitesP: "0.26401630988787", constantSitesP: "0.596585117227319", distinctSitesP: "0.333588175331295", brLenTotal: "1.0686" },
  { geneTranscript: "NM_138461.4", parsimonySitesP: "0.287081339712919", constantSitesP: "0.547049441786284", distinctSitesP: "0.424242424242424", brLenTotal: "0.9362" },
  { geneTranscript: "NM_207348.3", parsimonySitesP: "0.223684210526316", constantSitesP: "0.660087719298246", distinctSitesP: "0.31469298245614", brLenTotal: "0.9095" },
  { geneTranscript: "NM_006584.4", parsimonySitesP: "0.245911949685535", constantSitesP: "0.567924528301887", distinctSitesP: "0.394339622641509", brLenTotal: "0.8907" },
  { geneTranscript: "NM_001282693.2", parsimonySitesP: "0.228696741854637", constantSitesP: "0.615914786967419", distinctSitesP: "0.342105263157895", brLenTotal: "0.7954" },
  { geneTranscript: "NM_001256720.2", parsimonySitesP: "0.225806451612903", constantSitesP: "0.637992831541219", distinctSitesP: "0.333333333333333", brLenTotal: "0.8286" },
  { geneTranscript: "NM_001007025.2", parsimonySitesP: "0.137096774193548", constantSitesP: "0.745967741935484", distinctSitesP: "0.254032258064516", brLenTotal: "0.4622" },
  { geneTranscript: "NM_000021.4", parsimonySitesP: "0.163454675231977", constantSitesP: "0.725910064239829", distinctSitesP: "0.236973590292648", brLenTotal: "0.5828" },
  { geneTranscript: "NM_021065.3", parsimonySitesP: "0.258974358974359", constantSitesP: "0.638461538461538", distinctSitesP: "0.356410256410256", brLenTotal: "1.9740" },
  { geneTranscript: "NM_032830.3", parsimonySitesP: "0.182701652089407", constantSitesP: "0.698736637512148", distinctSitesP: "0.249757045675413", brLenTotal: "0.6301" },
  { geneTranscript: "NM_001114734.2", parsimonySitesP: "0.259459459459459", constantSitesP: "0.561261261261261", distinctSitesP: "0.315315315315315", brLenTotal: "1.0977" },
  { geneTranscript: "NM_014321.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.607142857142857", distinctSitesP: "0.37962962962963", brLenTotal: "0.7556" },
  { geneTranscript: "NM_001402.6", parsimonySitesP: "0.151515151515152", constantSitesP: "0.681096681096681", distinctSitesP: "0.254689754689755", brLenTotal: "0.6548" },
  { geneTranscript: "NM_003079.5", parsimonySitesP: "0.130575831305758", constantSitesP: "0.737226277372263", distinctSitesP: "0.227088402270884", brLenTotal: "0.5402" },
  { geneTranscript: "NM_001143909.1", parsimonySitesP: "0.527777777777778", constantSitesP: "0.266081871345029", distinctSitesP: "0.767543859649123", brLenTotal: "2.0270" },
  { geneTranscript: "NM_024641.4", parsimonySitesP: "0.16955266955267", constantSitesP: "0.689033189033189", distinctSitesP: "0.274891774891775", brLenTotal: "0.6071" },
  { geneTranscript: "NM_002834.5", parsimonySitesP: "0.152894884766723", constantSitesP: "0.697020798201237", distinctSitesP: "0.259134345137718", brLenTotal: "0.6285" },
  { geneTranscript: "NM_002766.3", parsimonySitesP: "0.191341991341991", constantSitesP: "0.717748917748918", distinctSitesP: "0.27012987012987", brLenTotal: "0.8092" },
  { geneTranscript: "NM_001004452.1", parsimonySitesP: "0.624068157614483", constantSitesP: "0.269435569755059", distinctSitesP: "0.722044728434505", brLenTotal: "4.1596" },
  { geneTranscript: "NM_012365.2", parsimonySitesP: "0.473740621650589", constantSitesP: "0.360128617363344", distinctSitesP: "0.629153269024652", brLenTotal: "3.0044" },
  { geneTranscript: "NM_001145073.3", parsimonySitesP: "0.10882800608828", constantSitesP: "0.784627092846271", distinctSitesP: "0.176560121765601", brLenTotal: "0.4334" },
  { geneTranscript: "NM_182985.5", parsimonySitesP: "0.248", constantSitesP: "0.596", distinctSitesP: "0.387333333333333", brLenTotal: "0.7312" },
  { geneTranscript: "NM_005497.4", parsimonySitesP: "0.122053872053872", constantSitesP: "0.749158249158249", distinctSitesP: "0.179292929292929", brLenTotal: "0.5417" },
  { geneTranscript: "NM_152616.5", parsimonySitesP: "0.245274319963117", constantSitesP: "0.61134163208852", distinctSitesP: "0.331489165514062", brLenTotal: "0.8513" },
  { geneTranscript: "NM_000859.3", parsimonySitesP: "0.143393393393393", constantSitesP: "0.759009009009009", distinctSitesP: "0.209459459459459", brLenTotal: "0.4815" },
  { geneTranscript: "NM_016264.4", parsimonySitesP: "0.467750677506775", constantSitesP: "0.360433604336043", distinctSitesP: "0.61029810298103", brLenTotal: "2.2598" },
  { geneTranscript: "NM_001272046.2", parsimonySitesP: "0.283443708609272", constantSitesP: "0.559381898454746", distinctSitesP: "0.390286975717439", brLenTotal: "1.2169" },
  { geneTranscript: "NM_001414.4", parsimonySitesP: "0.154098360655738", constantSitesP: "0.744262295081967", distinctSitesP: "0.243715846994536", brLenTotal: "0.5837" },
  { geneTranscript: "NM_014953.5", parsimonySitesP: "0.16562282533055", constantSitesP: "0.730340988169798", distinctSitesP: "0.242171189979123", brLenTotal: "0.5496" },
  { geneTranscript: "NM_001256964.2", parsimonySitesP: "0.231954582319546", constantSitesP: "0.587996755879968", distinctSitesP: "0.326845093268451", brLenTotal: "0.8051" },
  { geneTranscript: "NM_001110219.3", parsimonySitesP: "0.215836526181354", constantSitesP: "0.586206896551724", distinctSitesP: "0.32183908045977", brLenTotal: "2.2609" },
  { geneTranscript: "NM_001101330.3", parsimonySitesP: "0.353439153439153", constantSitesP: "0.496296296296296", distinctSitesP: "0.438095238095238", brLenTotal: "1.0542" },
  { geneTranscript: "NM_002229.3", parsimonySitesP: "0.14985590778098", constantSitesP: "0.777137367915466", distinctSitesP: "0.198847262247839", brLenTotal: "0.4931" },
  { geneTranscript: "NM_005631.5", parsimonySitesP: "0.160525201185938", constantSitesP: "0.737399407030919", distinctSitesP: "0.235493434985176", brLenTotal: "0.6747" },
  { geneTranscript: "NM_017708.4", parsimonySitesP: "0.317991631799163", constantSitesP: "0.53905160390516", distinctSitesP: "0.442119944211994", brLenTotal: "1.4981" },
  { geneTranscript: "NM_001080505.3", parsimonySitesP: "0.256302521008403", constantSitesP: "0.633053221288515", distinctSitesP: "0.343137254901961", brLenTotal: "0.9829" },
  { geneTranscript: "NM_001394783.1", parsimonySitesP: "0.391098484848485", constantSitesP: "0.449810606060606", distinctSitesP: "0.526515151515152", brLenTotal: "1.9334" },
  { geneTranscript: "NM_152414.5", parsimonySitesP: "0.180227471566054", constantSitesP: "0.707786526684164", distinctSitesP: "0.298337707786527", brLenTotal: "0.8616" },
  { geneTranscript: "NM_000966.6", parsimonySitesP: "0.150513950073421", constantSitesP: "0.767254038179148", distinctSitesP: "0.204845814977974", brLenTotal: "0.6149" },
  { geneTranscript: "NM_054021.2", parsimonySitesP: "0.25262467191601", constantSitesP: "0.604330708661417", distinctSitesP: "0.385170603674541", brLenTotal: "0.8814" },
  { geneTranscript: "NM_001135863.2", parsimonySitesP: "0.225190839694656", constantSitesP: "0.577608142493639", distinctSitesP: "0.421119592875318", brLenTotal: "0.8538" },
  { geneTranscript: "NM_198834.3", parsimonySitesP: "0.135123793537558", constantSitesP: "0.775073436844314", distinctSitesP: "0.156665267869632", brLenTotal: "0.4519" },
  { geneTranscript: "NM_178009.5", parsimonySitesP: "0.175409836065574", constantSitesP: "0.716666666666667", distinctSitesP: "0.244262295081967", brLenTotal: "0.6435" },
  { geneTranscript: "NM_001101669.3", parsimonySitesP: "0.174963924963925", constantSitesP: "0.718614718614719", distinctSitesP: "0.246392496392496", brLenTotal: "0.5837" },
  { geneTranscript: "NM_032634.4", parsimonySitesP: "0.206305479032752", constantSitesP: "0.655341291704928", distinctSitesP: "0.247933884297521", brLenTotal: "0.6717" },
  { geneTranscript: "NM_001123068.3", parsimonySitesP: "0.272357723577236", constantSitesP: "0.441056910569106", distinctSitesP: "0.50609756097561", brLenTotal: "1.6362" },
  { geneTranscript: "NM_020653.4", parsimonySitesP: "0.176084099868594", constantSitesP: "0.636005256241787", distinctSitesP: "0.284275076653526", brLenTotal: "0.8912" },
  { geneTranscript: "NM_052839.4", parsimonySitesP: "0.177744953225012", constantSitesP: "0.710487444608567", distinctSitesP: "0.243722304283604", brLenTotal: "0.7775" },
  { geneTranscript: "NM_001083961.2", parsimonySitesP: "0.265484788794047", constantSitesP: "0.580214488947253", distinctSitesP: "0.358940687240096", brLenTotal: "1.0695" },
  { geneTranscript: "NM_001300860.2", parsimonySitesP: "0.131459948320413", constantSitesP: "0.786175710594315", distinctSitesP: "0.172157622739018", brLenTotal: "0.4138" },
  { geneTranscript: "NM_014553.3", parsimonySitesP: "0.167710508002784", constantSitesP: "0.741823242867084", distinctSitesP: "0.221990257480863", brLenTotal: "0.6838" },
  { geneTranscript: "NM_001404.5", parsimonySitesP: "0.155606407322654", constantSitesP: "0.679633867276888", distinctSitesP: "0.259344012204424", brLenTotal: "0.6031" },
  { geneTranscript: "NM_001800.4", parsimonySitesP: "0.218875502008032", constantSitesP: "0.642570281124498", distinctSitesP: "0.327309236947791", brLenTotal: "0.7942" },
  { geneTranscript: "NM_020315.5", parsimonySitesP: "0.220720720720721", constantSitesP: "0.653153153153153", distinctSitesP: "0.343468468468468", brLenTotal: "0.8383" },
  { geneTranscript: "NM_030581.4", parsimonySitesP: "0.172142368240931", constantSitesP: "0.744010951403149", distinctSitesP: "0.219028062970568", brLenTotal: "0.7429" },
  { geneTranscript: "NM_003248.6", parsimonySitesP: "0.2088102670829", constantSitesP: "0.674991328477281", distinctSitesP: "0.288935137010059", brLenTotal: "0.8326" },
  { geneTranscript: "NM_006403.4", parsimonySitesP: "0.237809752198241", constantSitesP: "0.633093525179856", distinctSitesP: "0.319744204636291", brLenTotal: "0.9330" },
  { geneTranscript: "NM_001135048.2", parsimonySitesP: "0.134969325153374", constantSitesP: "0.783231083844581", distinctSitesP: "0.212678936605317", brLenTotal: "0.7332" },
  { geneTranscript: "NM_018710.3", parsimonySitesP: "0.120622568093385", constantSitesP: "0.80674448767834", distinctSitesP: "0.190661478599222", brLenTotal: "0.3509" },
  { geneTranscript: "NM_144605.5", parsimonySitesP: "0.259776536312849", constantSitesP: "0.60707635009311", distinctSitesP: "0.373370577281192", brLenTotal: "0.9880" },
  { geneTranscript: "NM_015698.6", parsimonySitesP: "0.257703081232493", constantSitesP: "0.572128851540616", distinctSitesP: "0.357843137254902", brLenTotal: "0.8508" },
  { geneTranscript: "NM_001185106.1", parsimonySitesP: "0.195628415300546", constantSitesP: "0.645901639344262", distinctSitesP: "0.306010928961749", brLenTotal: "0.7847" },
  { geneTranscript: "NM_015177.2", parsimonySitesP: "0.202477113624125", constantSitesP: "0.67635971997846", distinctSitesP: "0.274098007539041", brLenTotal: "0.8634" },
  { geneTranscript: "NM_001004304.4", parsimonySitesP: "0.077720207253886", constantSitesP: "0.861830742659758", distinctSitesP: "0.132987910189983", brLenTotal: "0.2181" },
  { geneTranscript: "NM_198407.2", parsimonySitesP: "0.209471766848816", constantSitesP: "0.656648451730419", distinctSitesP: "0.309653916211293", brLenTotal: "0.7291" },
  { geneTranscript: "NM_019013.3", parsimonySitesP: "0.365546218487395", constantSitesP: "0.396358543417367", distinctSitesP: "0.561624649859944", brLenTotal: "1.5521" },
  { geneTranscript: "NM_005586.4", parsimonySitesP: "0.181571815718157", constantSitesP: "0.677506775067751", distinctSitesP: "0.279132791327913", brLenTotal: "0.6988" },
  { geneTranscript: "NM_001190.4", parsimonySitesP: "0.256802721088435", constantSitesP: "0.624149659863946", distinctSitesP: "0.345238095238095", brLenTotal: "1.0184" },
  { geneTranscript: "NM_001382294.1", parsimonySitesP: "0.234234234234234", constantSitesP: "0.576576576576577", distinctSitesP: "0.40990990990991", brLenTotal: "0.8604" },
  { geneTranscript: "NM_032307.5", parsimonySitesP: "0.208211143695015", constantSitesP: "0.624633431085044", distinctSitesP: "0.325513196480938", brLenTotal: "0.7017" },
  { geneTranscript: "NM_015289.5", parsimonySitesP: "0.126857142857143", constantSitesP: "0.772190476190476", distinctSitesP: "0.184", brLenTotal: "0.4386" },
  { geneTranscript: "NM_004927.4", parsimonySitesP: "0.263052208835341", constantSitesP: "0.586345381526104", distinctSitesP: "0.405622489959839", brLenTotal: "0.9276" },
  { geneTranscript: "NM_015348.2", parsimonySitesP: "0.154717649141441", constantSitesP: "0.722959815896619", distinctSitesP: "0.219507877500443", brLenTotal: "0.5293" },
  { geneTranscript: "NM_030981.3", parsimonySitesP: "0.160862354892206", constantSitesP: "0.754560530679934", distinctSitesP: "0.222222222222222", brLenTotal: "0.6611" },
  { geneTranscript: "NM_001198679.3", parsimonySitesP: "0.103569632981398", constantSitesP: "0.81447963800905", distinctSitesP: "0.164404223227753", brLenTotal: "0.4078" },
  { geneTranscript: "NM_173502.5", parsimonySitesP: "0.242105263157895", constantSitesP: "0.589083820662768", distinctSitesP: "0.32943469785575", brLenTotal: "0.9100" },
  { geneTranscript: "NM_203374.2", parsimonySitesP: "0.237358101135191", constantSitesP: "0.638802889576883", distinctSitesP: "0.35500515995872", brLenTotal: "1.0280" },
  { geneTranscript: "NM_020546.3", parsimonySitesP: "0.186373357775741", constantSitesP: "0.728689275893676", distinctSitesP: "0.252062328139322", brLenTotal: "0.8397" },
  { geneTranscript: "NM_007371.4", parsimonySitesP: "0.21763085399449", constantSitesP: "0.654729109274564", distinctSitesP: "0.279614325068871", brLenTotal: "1.0609" },
  { geneTranscript: "NM_133640.5", parsimonySitesP: "0.201666666666667", constantSitesP: "0.681666666666667", distinctSitesP: "0.311666666666667", brLenTotal: "0.9444" },
  { geneTranscript: "NM_014211.3", parsimonySitesP: "0.165151515151515", constantSitesP: "0.720454545454545", distinctSitesP: "0.265151515151515", brLenTotal: "0.5622" },
  { geneTranscript: "NM_017945.5", parsimonySitesP: "0.167452830188679", constantSitesP: "0.677672955974843", distinctSitesP: "0.300314465408805", brLenTotal: "0.5726" },
  { geneTranscript: "NM_032505.3", parsimonySitesP: "0.126455906821963", constantSitesP: "0.762063227953411", distinctSitesP: "0.184137548530227", brLenTotal: "0.4435" },
  { geneTranscript: "NM_001136032.3", parsimonySitesP: "0.241333333333333", constantSitesP: "0.656", distinctSitesP: "0.324", brLenTotal: "0.9620" },
  { geneTranscript: "NM_002839.4", parsimonySitesP: "0.13336820083682", constantSitesP: "0.776847977684798", distinctSitesP: "0.156555090655509", brLenTotal: "0.5039" },
  { geneTranscript: "NM_024325.6", parsimonySitesP: "0.567612687813022", constantSitesP: "0.284362826933779", distinctSitesP: "0.709515859766277", brLenTotal: "2.5770" },
  { geneTranscript: "NM_001134745.3", parsimonySitesP: "0.11864406779661", constantSitesP: "0.804519774011299", distinctSitesP: "0.172881355932203", brLenTotal: "0.3908" },
  { geneTranscript: "NM_033064.5", parsimonySitesP: "0.217430368373765", constantSitesP: "0.690925426774483", distinctSitesP: "0.316262353998203", brLenTotal: "1.1038" },
  { geneTranscript: "NM_004846.4", parsimonySitesP: "0.133333333333333", constantSitesP: "0.717006802721088", distinctSitesP: "0.253061224489796", brLenTotal: "0.5098" },
  { geneTranscript: "NM_002300.8", parsimonySitesP: "0.141716566866267", constantSitesP: "0.703592814371257", distinctSitesP: "0.254491017964072", brLenTotal: "0.6165" },
  { geneTranscript: "NM_032889.5", parsimonySitesP: "0.165925925925926", constantSitesP: "0.737777777777778", distinctSitesP: "0.215555555555556", brLenTotal: "0.4926" },
  { geneTranscript: "NM_016505.4", parsimonySitesP: "0.135546334716459", constantSitesP: "0.742738589211618", distinctSitesP: "0.243430152143845", brLenTotal: "0.4180" },
  { geneTranscript: "NM_015873.4", parsimonySitesP: "0.303738317757009", constantSitesP: "0.508177570093458", distinctSitesP: "0.451323987538941", brLenTotal: "1.1887" },
  { geneTranscript: "NM_001024455.4", parsimonySitesP: "0.253075571177504", constantSitesP: "0.588752196836555", distinctSitesP: "0.372583479789104", brLenTotal: "0.8585" },
  { geneTranscript: "NM_005972.6", parsimonySitesP: "0.256", constantSitesP: "0.580444444444444", distinctSitesP: "0.376888888888889", brLenTotal: "1.1533" },
  { geneTranscript: "NM_020695.4", parsimonySitesP: "0.314223314223314", constantSitesP: "0.518973518973519", distinctSitesP: "0.392301392301392", brLenTotal: "1.6990" },
  { geneTranscript: "NM_012207.3", parsimonySitesP: "0.081888246628131", constantSitesP: "0.854527938342967", distinctSitesP: "0.131984585741811", brLenTotal: "0.2660" },
  { geneTranscript: "NM_014284.3", parsimonySitesP: "0.118427069044353", constantSitesP: "0.80612711476909", distinctSitesP: "0.15363511659808", brLenTotal: "0.5435" },
  { geneTranscript: "NM_017838.4", parsimonySitesP: "0.217864923747277", constantSitesP: "0.636165577342048", distinctSitesP: "0.352941176470588", brLenTotal: "0.8347" },
  { geneTranscript: "NM_001142928.2", parsimonySitesP: "0.265122265122265", constantSitesP: "0.558558558558559", distinctSitesP: "0.398970398970399", brLenTotal: "1.1605" },
  { geneTranscript: "NM_153366.4", parsimonySitesP: "0.216185942313078", constantSitesP: "0.645757490898908", distinctSitesP: "0.277793335200224", brLenTotal: "0.7744" },
  { geneTranscript: "NM_022479.3", parsimonySitesP: "0.155518394648829", constantSitesP: "0.748049052396878", distinctSitesP: "0.217948717948718", brLenTotal: "0.7110" },
  { geneTranscript: "NM_000052.7", parsimonySitesP: "0.176666666666667", constantSitesP: "0.699333333333333", distinctSitesP: "0.220666666666667", brLenTotal: "0.5197" },
  { geneTranscript: "NM_020902.2", parsimonySitesP: "0.235388310648519", constantSitesP: "0.644782492660795", distinctSitesP: "0.298638911128903", brLenTotal: "1.1695" },
  { geneTranscript: "NM_014694.4", parsimonySitesP: "0.231335436382755", constantSitesP: "0.676480897301087", distinctSitesP: "0.290571328426218", brLenTotal: "1.2237" },
  { geneTranscript: "NM_001382309.1", parsimonySitesP: "0.0835734870317003", constantSitesP: "0.836695485110471", distinctSitesP: "0.139289145052834", brLenTotal: "0.3460" },
  { geneTranscript: "NM_003651.5", parsimonySitesP: "0.149641577060932", constantSitesP: "0.675627240143369", distinctSitesP: "0.28405017921147", brLenTotal: "0.5800" },
  { geneTranscript: "NM_033026.6", parsimonySitesP: "0.200959419162453", constantSitesP: "0.659017243614677", distinctSitesP: "0.240503046804097", brLenTotal: "0.7113" },
  { geneTranscript: "NM_004064.5", parsimonySitesP: "0.185185185185185", constantSitesP: "0.712121212121212", distinctSitesP: "0.260942760942761", brLenTotal: "0.4845" },
  { geneTranscript: "NM_006347.4", parsimonySitesP: "0.128060263653484", constantSitesP: "0.785310734463277", distinctSitesP: "0.227871939736347", brLenTotal: "0.4293" },
  { geneTranscript: "NM_005500.3", parsimonySitesP: "0.179190751445087", constantSitesP: "0.700385356454721", distinctSitesP: "0.254335260115607", brLenTotal: "0.5999" },
  { geneTranscript: "NM_015001.3", parsimonySitesP: "0.225982532751092", constantSitesP: "0.655749636098981", distinctSitesP: "0.253366084425036", brLenTotal: "0.8564" },
  { geneTranscript: "NM_001018055.3", parsimonySitesP: "0.134020618556701", constantSitesP: "0.764032073310424", distinctSitesP: "0.22680412371134", brLenTotal: "0.4583" },
  { geneTranscript: "NM_198252.3", parsimonySitesP: "0.179206566347469", constantSitesP: "0.738258093935249", distinctSitesP: "0.233470132238942", brLenTotal: "0.8322" },
  { geneTranscript: "NM_133467.3", parsimonySitesP: "0.311594202898551", constantSitesP: "0.521739130434783", distinctSitesP: "0.494565217391304", brLenTotal: "1.2766" },
  { geneTranscript: "NM_006227.4", parsimonySitesP: "0.219066937119675", constantSitesP: "0.661257606490872", distinctSitesP: "0.294117647058824", brLenTotal: "0.8041" },
  { geneTranscript: "NM_017759.5", parsimonySitesP: "0.0957481337228173", constantSitesP: "0.764686790003246", distinctSitesP: "0.138915936384291", brLenTotal: "0.5445" },
  { geneTranscript: "NM_001004052.1", parsimonySitesP: "0.280701754385965", constantSitesP: "0.518059855521156", distinctSitesP: "0.423116615067079", brLenTotal: "0.9114" },
  { geneTranscript: "NM_021177.5", parsimonySitesP: "0.126315789473684", constantSitesP: "0.807017543859649", distinctSitesP: "0.214035087719298", brLenTotal: "0.4219" },
  { geneTranscript: "NM_024638.4", parsimonySitesP: "0.183935742971888", constantSitesP: "0.681927710843373", distinctSitesP: "0.299598393574297", brLenTotal: "0.6649" },
  { geneTranscript: "NM_014595.3", parsimonySitesP: "0.213930348258706", constantSitesP: "0.625207296849088", distinctSitesP: "0.341625207296849", brLenTotal: "0.7680" },
  { geneTranscript: "NM_033309.3", parsimonySitesP: "0.310945273631841", constantSitesP: "0.548092868988391", distinctSitesP: "0.412935323383085", brLenTotal: "1.2622" },
  { geneTranscript: "NM_002455.5", parsimonySitesP: "0.277539341917024", constantSitesP: "0.55793991416309", distinctSitesP: "0.399856938483548", brLenTotal: "0.9358" },
  { geneTranscript: "NM_020208.4", parsimonySitesP: "0.221283783783784", constantSitesP: "0.649774774774775", distinctSitesP: "0.301238738738739", brLenTotal: "0.9116" },
  { geneTranscript: "NM_001396017.1", parsimonySitesP: "0.212605415002219", constantSitesP: "0.626719928983577", distinctSitesP: "0.33155792276964", brLenTotal: "0.7520" },
  { geneTranscript: "NM_004887.5", parsimonySitesP: "0.131313131313131", constantSitesP: "0.777777777777778", distinctSitesP: "0.245791245791246", brLenTotal: "0.3894" },
  { geneTranscript: "NM_001037806.4", parsimonySitesP: "0.226886556721639", constantSitesP: "0.626186906546727", distinctSitesP: "0.268115942028986", brLenTotal: "0.8747" },
  { geneTranscript: "NM_001004684.1", parsimonySitesP: "0.426262626262626", constantSitesP: "0.42020202020202", distinctSitesP: "0.592929292929293", brLenTotal: "1.7159" },
  { geneTranscript: "NM_001626.6", parsimonySitesP: "0.153153153153153", constantSitesP: "0.748440748440748", distinctSitesP: "0.218295218295218", brLenTotal: "0.7417" },
  { geneTranscript: "NM_001011718.2", parsimonySitesP: "0.175590097869891", constantSitesP: "0.712723085780081", distinctSitesP: "0.261945883707542", brLenTotal: "0.7264" },
  { geneTranscript: "NM_001146213.3", parsimonySitesP: "0.143916913946588", constantSitesP: "0.748763600395648", distinctSitesP: "0.212660731948566", brLenTotal: "0.4191" },
  { geneTranscript: "NM_016272.4", parsimonySitesP: "0.159883720930233", constantSitesP: "0.705426356589147", distinctSitesP: "0.250968992248062", brLenTotal: "0.6590" },
  { geneTranscript: "NM_020336.4", parsimonySitesP: "0.0912539045069166", constantSitesP: "0.832440874609549", distinctSitesP: "0.122043730477465", brLenTotal: "0.2814" },
  { geneTranscript: "NM_032656.4", parsimonySitesP: "0.271391529818496", constantSitesP: "0.59723422644771", distinctSitesP: "0.372515125324114", brLenTotal: "1.2685" },
  { geneTranscript: "NM_004343.4", parsimonySitesP: "0.195843325339728", constantSitesP: "0.709032773780975", distinctSitesP: "0.253397282174261", brLenTotal: "0.8187" },
  { geneTranscript: "NM_030926.6", parsimonySitesP: "0.18601747815231", constantSitesP: "0.706616729088639", distinctSitesP: "0.284644194756554", brLenTotal: "0.8607" },
  { geneTranscript: "NM_014697.3", parsimonySitesP: "0.160079051383399", constantSitesP: "0.729249011857708", distinctSitesP: "0.227931488801054", brLenTotal: "0.6554" },
  { geneTranscript: "NM_003599.4", parsimonySitesP: "0.147213459516299", constantSitesP: "0.759200841219769", distinctSitesP: "0.227129337539432", brLenTotal: "0.4336" },
  { geneTranscript: "NM_002857.4", parsimonySitesP: "0.149386845039019", constantSitesP: "0.754738015607581", distinctSitesP: "0.221850613154961", brLenTotal: "0.5067" },
  { geneTranscript: "NM_001144774.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.821493624772313", distinctSitesP: "0.163023679417122", brLenTotal: "0.4489" },
  { geneTranscript: "NM_002693.3", parsimonySitesP: "0.185902609631423", constantSitesP: "0.696798493408663", distinctSitesP: "0.233252623083132", brLenTotal: "0.6687" },
  { geneTranscript: "NM_032345.3", parsimonySitesP: "0.194444444444444", constantSitesP: "0.661764705882353", distinctSitesP: "0.313725490196078", brLenTotal: "0.6225" },
  { geneTranscript: "NM_000419.5", parsimonySitesP: "0.262431825473211", constantSitesP: "0.587423804940648", distinctSitesP: "0.350016041065127", brLenTotal: "1.0215" },
  { geneTranscript: "NM_001130053.5", parsimonySitesP: "0.293147861926842", constantSitesP: "0.542503863987635", distinctSitesP: "0.387944358578053", brLenTotal: "1.2491" },
  { geneTranscript: "NM_001145108.2", parsimonySitesP: "0.126225490196078", constantSitesP: "0.769607843137255", distinctSitesP: "0.193218954248366", brLenTotal: "0.4355" },
  { geneTranscript: "NM_152443.3", parsimonySitesP: "0.248945147679325", constantSitesP: "0.640295358649789", distinctSitesP: "0.335443037974684", brLenTotal: "1.0121" },
  { geneTranscript: "NM_138285.5", parsimonySitesP: "0.130879345603272", constantSitesP: "0.7280163599182", distinctSitesP: "0.249488752556237", brLenTotal: "0.5172" },
  { geneTranscript: "NM_000156.6", parsimonySitesP: "0.23728813559322", constantSitesP: "0.632768361581921", distinctSitesP: "0.340395480225989", brLenTotal: "1.0205" },
  { geneTranscript: "NM_022110.4", parsimonySitesP: "0.216809933142311", constantSitesP: "0.59025787965616", distinctSitesP: "0.370582617000955", brLenTotal: "0.6852" },
  { geneTranscript: "NM_001387430.1", parsimonySitesP: "0.158730158730159", constantSitesP: "0.749559082892416", distinctSitesP: "0.210758377425044", brLenTotal: "0.5326" },
  { geneTranscript: "NM_006212.2", parsimonySitesP: "0.2", constantSitesP: "0.715511551155116", distinctSitesP: "0.24950495049505", brLenTotal: "0.7173" },
  { geneTranscript: "NM_148178.3", parsimonySitesP: "0.188139059304703", constantSitesP: "0.658486707566462", distinctSitesP: "0.333333333333333", brLenTotal: "0.6576" },
  { geneTranscript: "NM_024640.4", parsimonySitesP: "0.224611708482676", constantSitesP: "0.598566308243728", distinctSitesP: "0.357228195937873", brLenTotal: "0.8536" },
  { geneTranscript: "NM_014991.6", parsimonySitesP: "0.18084704102855", constantSitesP: "0.738419360937795", distinctSitesP: "0.204575534127434", brLenTotal: "0.6477" },
  { geneTranscript: "NM_002904.6", parsimonySitesP: "0.157894736842105", constantSitesP: "0.716666666666667", distinctSitesP: "0.255263157894737", brLenTotal: "0.8028" },
  { geneTranscript: "NM_014038.3", parsimonySitesP: "0.116149562450278", constantSitesP: "0.806682577565632", distinctSitesP: "0.186157517899761", brLenTotal: "0.4590" },
  { geneTranscript: "NM_001270941.2", parsimonySitesP: "0.117479674796748", constantSitesP: "0.798780487804878", distinctSitesP: "0.15650406504065", brLenTotal: "0.4681" },
  { geneTranscript: "NM_006513.4", parsimonySitesP: "0.155642023346304", constantSitesP: "0.74124513618677", distinctSitesP: "0.232166018158236", brLenTotal: "0.5619" },
  { geneTranscript: "NM_012095.6", parsimonySitesP: "0.17304625199362", constantSitesP: "0.732057416267943", distinctSitesP: "0.198564593301435", brLenTotal: "0.5249" },
  { geneTranscript: "NM_000761.5", parsimonySitesP: "0.391472868217054", constantSitesP: "0.402454780361757", distinctSitesP: "0.589147286821705", brLenTotal: "2.1231" },
  { geneTranscript: "NM_000434.4", parsimonySitesP: "0.248995983935743", constantSitesP: "0.617670682730924", distinctSitesP: "0.344578313253012", brLenTotal: "0.8875" },
  { geneTranscript: "NM_006764.5", parsimonySitesP: "0.218702865761689", constantSitesP: "0.632730015082956", distinctSitesP: "0.290346907993967", brLenTotal: "0.8005" },
  { geneTranscript: "NM_205768.3", parsimonySitesP: "0.0602636534839925", constantSitesP: "0.883239171374765", distinctSitesP: "0.104833647206529", brLenTotal: "0.2239" },
  { geneTranscript: "NM_177983.3", parsimonySitesP: "0.113553113553114", constantSitesP: "0.772283272283272", distinctSitesP: "0.174603174603175", brLenTotal: "0.3575" },
  { geneTranscript: "NM_020421.4", parsimonySitesP: "0.188655194391332", constantSitesP: "0.704270235818993", distinctSitesP: "0.255576800509879", brLenTotal: "0.7817" },
  { geneTranscript: "NM_024657.5", parsimonySitesP: "0.15652792600498", constantSitesP: "0.726787620064034", distinctSitesP: "0.24119530416222", brLenTotal: "0.4492" },
  { geneTranscript: "NM_006029.5", parsimonySitesP: "0.19452313503305", constantSitesP: "0.622285174693107", distinctSitesP: "0.26628895184136", brLenTotal: "0.7811" },
  { geneTranscript: "NM_018117.12", parsimonySitesP: "0.17619825708061", constantSitesP: "0.709694989106754", distinctSitesP: "0.237472766884532", brLenTotal: "0.6154" },
  { geneTranscript: "NM_031917.3", parsimonySitesP: "0.269503546099291", constantSitesP: "0.587234042553191", distinctSitesP: "0.407801418439716", brLenTotal: "0.9835" },
  { geneTranscript: "NM_022080.3", parsimonySitesP: "0.110738255033557", constantSitesP: "0.791946308724832", distinctSitesP: "0.189038031319911", brLenTotal: "0.4663" },
  { geneTranscript: "NM_006182.4", parsimonySitesP: "0.148538011695906", constantSitesP: "0.750877192982456", distinctSitesP: "0.193372319688109", brLenTotal: "0.4995" },
  { geneTranscript: "NM_002742.3", parsimonySitesP: "0.161549707602339", constantSitesP: "0.734649122807018", distinctSitesP: "0.233187134502924", brLenTotal: "0.5713" },
  { geneTranscript: "NM_006181.3", parsimonySitesP: "0.25", constantSitesP: "0.620114942528736", distinctSitesP: "0.331034482758621", brLenTotal: "1.0531" },
  { geneTranscript: "NM_007076.3", parsimonySitesP: "0.197962154294032", constantSitesP: "0.687045123726346", distinctSitesP: "0.271470160116448", brLenTotal: "0.8860" },
  { geneTranscript: "NM_001029998.6", parsimonySitesP: "0.13921568627451", constantSitesP: "0.76078431372549", distinctSitesP: "0.229411764705882", brLenTotal: "0.5156" },
  { geneTranscript: "NM_000755.5", parsimonySitesP: "0.205537806176784", constantSitesP: "0.69542066027689", distinctSitesP: "0.252928647497338", brLenTotal: "0.8534" },
  { geneTranscript: "NM_031449.4", parsimonySitesP: "0.182608695652174", constantSitesP: "0.705072463768116", distinctSitesP: "0.232608695652174", brLenTotal: "0.8796" },
  { geneTranscript: "NM_032316.3", parsimonySitesP: "0.21283255086072", constantSitesP: "0.685446009389671", distinctSitesP: "0.275430359937402", brLenTotal: "0.6615" },
  { geneTranscript: "NM_001379286.1", parsimonySitesP: "0.127450980392157", constantSitesP: "0.79437564499484", distinctSitesP: "0.156604747162023", brLenTotal: "0.6537" },
  { geneTranscript: "NM_021100.5", parsimonySitesP: "0.157549234135667", constantSitesP: "0.721371261852662", distinctSitesP: "0.242159008023341", brLenTotal: "0.5333" },
  { geneTranscript: "NM_053064.5", parsimonySitesP: "0.140845070422535", constantSitesP: "0.746478873239437", distinctSitesP: "0.244131455399061", brLenTotal: "0.7076" },
  { geneTranscript: "NM_052855.4", parsimonySitesP: "0.144021739130435", constantSitesP: "0.740942028985507", distinctSitesP: "0.230072463768116", brLenTotal: "0.4834" },
  { geneTranscript: "NM_005983.4", parsimonySitesP: "0.17688679245283", constantSitesP: "0.679245283018868", distinctSitesP: "0.290094339622642", brLenTotal: "0.6080" },
  { geneTranscript: "NM_002470.4", parsimonySitesP: "0.225945017182131", constantSitesP: "0.676116838487973", distinctSitesP: "0.270790378006873", brLenTotal: "1.2300" },
  { geneTranscript: "NM_001492.6", parsimonySitesP: "0.348566308243728", constantSitesP: "0.483870967741935", distinctSitesP: "0.475806451612903", brLenTotal: "1.3765" },
  { geneTranscript: "NM_001204077.2", parsimonySitesP: "0.125703564727955", constantSitesP: "0.774546591619762", distinctSitesP: "0.156347717323327", brLenTotal: "0.3921" },
  { geneTranscript: "NM_020307.4", parsimonySitesP: "0.107731305449937", constantSitesP: "0.82256020278834", distinctSitesP: "0.162864385297845", brLenTotal: "0.3398" },
  { geneTranscript: "NM_006469.5", parsimonySitesP: "0.109553478712357", constantSitesP: "0.805815160955348", distinctSitesP: "0.152647975077882", brLenTotal: "0.3310" },
  { geneTranscript: "NM_012295.4", parsimonySitesP: "0.217117117117117", constantSitesP: "0.650900900900901", distinctSitesP: "0.259159159159159", brLenTotal: "0.8680" },
  { geneTranscript: "NM_001002800.3", parsimonySitesP: "0.142339544513458", constantSitesP: "0.729554865424431", distinctSitesP: "0.221273291925466", brLenTotal: "0.4821" },
  { geneTranscript: "NM_006430.4", parsimonySitesP: "0.137291280148423", constantSitesP: "0.760049474335189", distinctSitesP: "0.20160791589363", brLenTotal: "0.4298" },
  { geneTranscript: "NM_002564.4", parsimonySitesP: "0.240495137046861", constantSitesP: "0.62157382847038", distinctSitesP: "0.332449160035367", brLenTotal: "0.9952" },
  { geneTranscript: "NM_178433.1", parsimonySitesP: "0.470175438596491", constantSitesP: "0.27719298245614", distinctSitesP: "0.750877192982456", brLenTotal: "2.4355" },
  { geneTranscript: "NM_014978.3", parsimonySitesP: "0.169394435351882", constantSitesP: "0.716857610474632", distinctSitesP: "0.221222040370977", brLenTotal: "0.6333" },
  { geneTranscript: "NM_001086521.2", parsimonySitesP: "0.243243243243243", constantSitesP: "0.567567567567568", distinctSitesP: "0.432432432432432", brLenTotal: "1.0839" },
  { geneTranscript: "NM_015245.3", parsimonySitesP: "0.17283950617284", constantSitesP: "0.711052322163433", distinctSitesP: "0.224279835390947", brLenTotal: "0.7522" },
  { geneTranscript: "NM_002509.4", parsimonySitesP: "0.113553113553114", constantSitesP: "0.774114774114774", distinctSitesP: "0.197802197802198", brLenTotal: "0.4864" },
  { geneTranscript: "NM_006397.3", parsimonySitesP: "0.254180602006689", constantSitesP: "0.612040133779264", distinctSitesP: "0.358974358974359", brLenTotal: "0.9777" },
  { geneTranscript: "NM_002737.3", parsimonySitesP: "0.16765873015873", constantSitesP: "0.755952380952381", distinctSitesP: "0.219246031746032", brLenTotal: "0.7297" },
  { geneTranscript: "NM_001390846.1", parsimonySitesP: "0.2361782071927", constantSitesP: "0.616478797638218", distinctSitesP: "0.29898013955985", brLenTotal: "0.8850" },
  { geneTranscript: "NM_005698.4", parsimonySitesP: "0.179634966378482", constantSitesP: "0.704130643611912", distinctSitesP: "0.261287223823247", brLenTotal: "0.5787" },
  { geneTranscript: "NM_182760.4", parsimonySitesP: "0.251336898395722", constantSitesP: "0.640819964349376", distinctSitesP: "0.337789661319073", brLenTotal: "0.8981" },
  { geneTranscript: "NM_021979.4", parsimonySitesP: "0.234741784037559", constantSitesP: "0.664580073030777", distinctSitesP: "0.236828377673448", brLenTotal: "0.8932" },
  { geneTranscript: "NM_001042424.3", parsimonySitesP: "0.177533577533578", constantSitesP: "0.727472527472527", distinctSitesP: "0.233943833943834", brLenTotal: "0.6306" },
  { geneTranscript: "NM_001233.5", parsimonySitesP: "0.17283950617284", constantSitesP: "0.67283950617284", distinctSitesP: "0.308641975308642", brLenTotal: "0.6032" },
  { geneTranscript: "NM_001004751.3", parsimonySitesP: "0.398148148148148", constantSitesP: "0.40843621399177", distinctSitesP: "0.524691358024691", brLenTotal: "1.5403" },
  { geneTranscript: "NM_006649.4", parsimonySitesP: "0.249027237354086", constantSitesP: "0.565499351491569", distinctSitesP: "0.367920449632512", brLenTotal: "0.9604" },
  { geneTranscript: "NM_003021.4", parsimonySitesP: "0.237486687965921", constantSitesP: "0.674121405750799", distinctSitesP: "0.331203407880724", brLenTotal: "1.2509" },
  { geneTranscript: "NM_002969.6", parsimonySitesP: "0.217983651226158", constantSitesP: "0.67574931880109", distinctSitesP: "0.295186194368756", brLenTotal: "1.0419" },
  { geneTranscript: "NM_013989.5", parsimonySitesP: "0.164835164835165", constantSitesP: "0.735042735042735", distinctSitesP: "0.253968253968254", brLenTotal: "0.5983" },
  { geneTranscript: "NM_003969.4", parsimonySitesP: "0.0837887067395264", constantSitesP: "0.85792349726776", distinctSitesP: "0.132969034608379", brLenTotal: "0.2415" },
  { geneTranscript: "NM_001260.3", parsimonySitesP: "0.100574712643678", constantSitesP: "0.814655172413793", distinctSitesP: "0.156609195402299", brLenTotal: "0.3501" },
  { geneTranscript: "NM_018622.7", parsimonySitesP: "0.218997361477573", constantSitesP: "0.624450307827617", distinctSitesP: "0.351802990325418", brLenTotal: "0.7571" },
  { geneTranscript: "NM_014467.3", parsimonySitesP: "0.147670250896057", constantSitesP: "0.759139784946237", distinctSitesP: "0.210035842293907", brLenTotal: "0.4438" },
  { geneTranscript: "NM_001377540.1", parsimonySitesP: "0.0954077593032462", constantSitesP: "0.821456848772763", distinctSitesP: "0.146080760095012", brLenTotal: "0.2841" },
  { geneTranscript: "NM_024812.3", parsimonySitesP: "0.211494252873563", constantSitesP: "0.639080459770115", distinctSitesP: "0.358620689655172", brLenTotal: "0.7153" },
  { geneTranscript: "NM_001366006.2", parsimonySitesP: "0.142727479010665", constantSitesP: "0.776038121170865", distinctSitesP: "0.169956886771046", brLenTotal: "0.5211" },
  { geneTranscript: "NM_014612.5", parsimonySitesP: "0.126416219439475", constantSitesP: "0.757006559332141", distinctSitesP: "0.152057245080501", brLenTotal: "0.5159" },
  { geneTranscript: "NM_016615.5", parsimonySitesP: "0.241417497231451", constantSitesP: "0.642857142857143", distinctSitesP: "0.320044296788483", brLenTotal: "0.9817" },
  { geneTranscript: "NM_022488.5", parsimonySitesP: "0.10828025477707", constantSitesP: "0.768577494692144", distinctSitesP: "0.231422505307856", brLenTotal: "0.4290" },
  { geneTranscript: "NM_001387751.1", parsimonySitesP: "0.149794238683128", constantSitesP: "0.765432098765432", distinctSitesP: "0.208230452674897", brLenTotal: "0.6438" },
  { geneTranscript: "NM_152793.3", parsimonySitesP: "0.0966921119592875", constantSitesP: "0.824427480916031", distinctSitesP: "0.190839694656489", brLenTotal: "0.3177" },
  { geneTranscript: "NM_173474.4", parsimonySitesP: "0.162365591397849", constantSitesP: "0.704301075268817", distinctSitesP: "0.304301075268817", brLenTotal: "0.5900" },
  { geneTranscript: "NM_001004702.2", parsimonySitesP: "0.601545253863135", constantSitesP: "0.266004415011038", distinctSitesP: "0.721854304635762", brLenTotal: "3.8364" },
  { geneTranscript: "NM_080820.6", parsimonySitesP: "0.181818181818182", constantSitesP: "0.712918660287081", distinctSitesP: "0.283891547049442", brLenTotal: "0.6069" },
  { geneTranscript: "NM_016542.4", parsimonySitesP: "0.116987179487179", constantSitesP: "0.774839743589744", distinctSitesP: "0.201923076923077", brLenTotal: "0.4114" },
  { geneTranscript: "NM_001024807.3", parsimonySitesP: "0.19815668202765", constantSitesP: "0.673323092677931", distinctSitesP: "0.272913466461854", brLenTotal: "0.7655" },
  { geneTranscript: "NM_003262.4", parsimonySitesP: "0.154553049289891", constantSitesP: "0.750208855472013", distinctSitesP: "0.221386800334169", brLenTotal: "0.5430" },
  { geneTranscript: "NM_005086.5", parsimonySitesP: "0.190672153635117", constantSitesP: "0.680384087791495", distinctSitesP: "0.316872427983539", brLenTotal: "0.5807" },
  { geneTranscript: "NM_003590.5", parsimonySitesP: "0.0828993055555556", constantSitesP: "0.837673611111111", distinctSitesP: "0.131076388888889", brLenTotal: "0.2809" },
  { geneTranscript: "NM_058238.3", parsimonySitesP: "0.236867239732569", constantSitesP: "0.701050620821394", distinctSitesP: "0.276026743075454", brLenTotal: "1.0218" },
  { geneTranscript: "NM_005143.5", parsimonySitesP: "0.293924466338259", constantSitesP: "0.566502463054187", distinctSitesP: "0.382594417077176", brLenTotal: "1.2598" },
  { geneTranscript: "NM_014383.3", parsimonySitesP: "0.295003422313484", constantSitesP: "0.528405201916496", distinctSitesP: "0.422313483915127", brLenTotal: "1.0576" },
  { geneTranscript: "NM_001098671.2", parsimonySitesP: "0.161466885604817", constantSitesP: "0.733990147783251", distinctSitesP: "0.207991242474001", brLenTotal: "0.6388" },
  { geneTranscript: "NM_003913.5", parsimonySitesP: "0.135716650115856", constantSitesP: "0.765309500165508", distinctSitesP: "0.190334326381993", brLenTotal: "0.4249" },
  { geneTranscript: "NM_018256.4", parsimonySitesP: "0.148148148148148", constantSitesP: "0.727344365642238", distinctSitesP: "0.249014972419228", brLenTotal: "0.5135" },
  { geneTranscript: "NM_003095.5", parsimonySitesP: "0.12015503875969", constantSitesP: "0.75968992248062", distinctSitesP: "0.248062015503876", brLenTotal: "0.3826" },
  { geneTranscript: "NM_000269.3", parsimonySitesP: "0.199561403508772", constantSitesP: "0.640350877192982", distinctSitesP: "0.324561403508772", brLenTotal: "0.8074" },
  { geneTranscript: "NM_003149.3", parsimonySitesP: "0.149253731343284", constantSitesP: "0.720563847429519", distinctSitesP: "0.246268656716418", brLenTotal: "0.5263" },
  { geneTranscript: "NM_022903.4", parsimonySitesP: "0.266238401142041", constantSitesP: "0.573162027123483", distinctSitesP: "0.382583868665239", brLenTotal: "1.0990" },
  { geneTranscript: "NM_152869.4", parsimonySitesP: "0.205128205128205", constantSitesP: "0.668896321070234", distinctSitesP: "0.319955406911929", brLenTotal: "0.7079" },
  { geneTranscript: "NM_003535.3", parsimonySitesP: "0.311274509803922", constantSitesP: "0.666666666666667", distinctSitesP: "0.338235294117647", brLenTotal: "2.1883" },
  { geneTranscript: "NM_005244.5", parsimonySitesP: "0.184634448574969", constantSitesP: "0.70631970260223", distinctSitesP: "0.268897149938042", brLenTotal: "0.7929" },
  { geneTranscript: "NM_001005185.2", parsimonySitesP: "0.415598290598291", constantSitesP: "0.436965811965812", distinctSitesP: "0.492521367521368", brLenTotal: "1.5457" },
  { geneTranscript: "NM_005119.4", parsimonySitesP: "0.143106457242583", constantSitesP: "0.679232111692845", distinctSitesP: "0.228970331588133", brLenTotal: "0.5743" },
  { geneTranscript: "NM_031427.4", parsimonySitesP: "0.119298245614035", constantSitesP: "0.807017543859649", distinctSitesP: "0.2", brLenTotal: "0.3312" },
  { geneTranscript: "NM_018919.3", parsimonySitesP: "0.404864091559371", constantSitesP: "0.46173104434907", distinctSitesP: "0.495708154506438", brLenTotal: "3.5762" },
  { geneTranscript: "NM_004098.4", parsimonySitesP: "0.0939153439153439", constantSitesP: "0.802910052910053", distinctSitesP: "0.164021164021164", brLenTotal: "0.3198" },
  { geneTranscript: "NM_207334.3", parsimonySitesP: "0.123606889564336", constantSitesP: "0.735562310030395", distinctSitesP: "0.253292806484296", brLenTotal: "0.5633" },
  { geneTranscript: "NM_001015878.2", parsimonySitesP: "0.305285868392665", constantSitesP: "0.528586839266451", distinctSitesP: "0.458468176914779", brLenTotal: "1.3698" },
  { geneTranscript: "NM_002593.4", parsimonySitesP: "0.204157386785449", constantSitesP: "0.658500371195249", distinctSitesP: "0.291759465478842", brLenTotal: "0.8077" },
  { geneTranscript: "NM_001384125.1", parsimonySitesP: "0.121146671902611", constantSitesP: "0.781988350022907", distinctSitesP: "0.12455003599712", brLenTotal: "0.4016" },
  { geneTranscript: "NM_153703.5", parsimonySitesP: "0.181076672104405", constantSitesP: "0.698205546492659", distinctSitesP: "0.250135943447526", brLenTotal: "0.8728" },
  { geneTranscript: "NM_005884.5", parsimonySitesP: "0.199097574732092", constantSitesP: "0.653694303440496", distinctSitesP: "0.28087986463621", brLenTotal: "1.0300" },
  { geneTranscript: "NM_138783.4", parsimonySitesP: "0.212466124661247", constantSitesP: "0.680216802168022", distinctSitesP: "0.278048780487805", brLenTotal: "1.0663" },
  { geneTranscript: "NM_001145784.2", parsimonySitesP: "0.179271708683473", constantSitesP: "0.736694677871148", distinctSitesP: "0.274509803921569", brLenTotal: "0.8696" },
  { geneTranscript: "NM_144635.5", parsimonySitesP: "0.148451730418944", constantSitesP: "0.765027322404372", distinctSitesP: "0.223132969034608", brLenTotal: "0.5540" },
  { geneTranscript: "NM_019028.3", parsimonySitesP: "0.137191854233655", constantSitesP: "0.737406216505895", distinctSitesP: "0.227224008574491", brLenTotal: "0.4737" },
  { geneTranscript: "NM_004409.5", parsimonySitesP: "0.208267090620032", constantSitesP: "0.670376258611553", distinctSitesP: "0.273979862215156", brLenTotal: "0.7935" },
  { geneTranscript: "NM_002518.4", parsimonySitesP: "0.176779935275081", constantSitesP: "0.702265372168285", distinctSitesP: "0.258090614886731", brLenTotal: "0.7250" },
  { geneTranscript: "NM_020456.4", parsimonySitesP: "0.115646258503401", constantSitesP: "0.802721088435374", distinctSitesP: "0.197278911564626", brLenTotal: "0.3485" },
  { geneTranscript: "NM_003085.5", parsimonySitesP: "0.159203980099502", constantSitesP: "0.756218905472637", distinctSitesP: "0.23134328358209", brLenTotal: "0.6044" },
  { geneTranscript: "NM_001080533.3", parsimonySitesP: "0.167330677290837", constantSitesP: "0.729083665338645", distinctSitesP: "0.247011952191235", brLenTotal: "0.4991" },
  { geneTranscript: "NM_052960.3", parsimonySitesP: "0.211442786069652", constantSitesP: "0.649253731343284", distinctSitesP: "0.370646766169154", brLenTotal: "0.6902" },
  { geneTranscript: "NM_172373.4", parsimonySitesP: "0.180936995153473", constantSitesP: "0.689283791060851", distinctSitesP: "0.25686591276252", brLenTotal: "0.6179" },
  { geneTranscript: "NM_017504.2", parsimonySitesP: "0.57556270096463", constantSitesP: "0.310825294748124", distinctSitesP: "0.690246516613076", brLenTotal: "3.0829" },
  { geneTranscript: "NM_015726.4", parsimonySitesP: "0.12786152987158", constantSitesP: "0.756560580681184", distinctSitesP: "0.1892797319933", brLenTotal: "0.4452" },
  { geneTranscript: "NM_002610.5", parsimonySitesP: "0.164373088685015", constantSitesP: "0.733180428134557", distinctSitesP: "0.252293577981651", brLenTotal: "0.5573" },
  { geneTranscript: "NM_001366683.2", parsimonySitesP: "0.162364563416189", constantSitesP: "0.746175908221797", distinctSitesP: "0.197737412364563", brLenTotal: "0.6498" },
  { geneTranscript: "NM_006063.3", parsimonySitesP: "0.119361936193619", constantSitesP: "0.778327832783278", distinctSitesP: "0.178217821782178", brLenTotal: "0.4039" },
  { geneTranscript: "NM_004317.4", parsimonySitesP: "0.14367816091954", constantSitesP: "0.777777777777778", distinctSitesP: "0.202107279693487", brLenTotal: "0.6290" },
  { geneTranscript: "NM_182916.3", parsimonySitesP: "0.155145929339478", constantSitesP: "0.704301075268817", distinctSitesP: "0.271121351766513", brLenTotal: "0.5289" },
  { geneTranscript: "NM_018649.3", parsimonySitesP: "0.128136200716846", constantSitesP: "0.807347670250896", distinctSitesP: "0.17831541218638", brLenTotal: "0.6533" },
  { geneTranscript: "NM_006228.5", parsimonySitesP: "0.261363636363636", constantSitesP: "0.604166666666667", distinctSitesP: "0.371212121212121", brLenTotal: "0.9796" },
  { geneTranscript: "NM_007065.4", parsimonySitesP: "0.164902998236332", constantSitesP: "0.737213403880071", distinctSitesP: "0.231040564373898", brLenTotal: "0.7416" },
  { geneTranscript: "NM_005768.6", parsimonySitesP: "0.176591375770021", constantSitesP: "0.704996577686516", distinctSitesP: "0.248459958932238", brLenTotal: "0.5798" },
  { geneTranscript: "NM_207318.4", parsimonySitesP: "0.095360824742268", constantSitesP: "0.829896907216495", distinctSitesP: "0.146907216494845", brLenTotal: "0.2993" },
  { geneTranscript: "NM_003339.3", parsimonySitesP: "0.0702947845804989", constantSitesP: "0.877551020408163", distinctSitesP: "0.122448979591837", brLenTotal: "0.2025" },
  { geneTranscript: "NM_024006.6", parsimonySitesP: "0.239263803680982", constantSitesP: "0.619631901840491", distinctSitesP: "0.366053169734151", brLenTotal: "0.8985" },
  { geneTranscript: "NM_174911.5", parsimonySitesP: "0.193548387096774", constantSitesP: "0.681720430107527", distinctSitesP: "0.280645161290323", brLenTotal: "0.7544" },
  { geneTranscript: "NM_017777.4", parsimonySitesP: "0.214669051878354", constantSitesP: "0.644603458556947", distinctSitesP: "0.307692307692308", brLenTotal: "0.7176" },
  { geneTranscript: "NM_153607.3", parsimonySitesP: "0.114241001564945", constantSitesP: "0.802816901408451", distinctSitesP: "0.159102764736568", brLenTotal: "0.3482" },
  { geneTranscript: "NM_013237.4", parsimonySitesP: "0.153729071537291", constantSitesP: "0.637747336377473", distinctSitesP: "0.327245053272451", brLenTotal: "0.6117" },
  { geneTranscript: "NM_015469.3", parsimonySitesP: "0.217273954116059", constantSitesP: "0.64642375168691", distinctSitesP: "0.346828609986505", brLenTotal: "0.7744" },
  { geneTranscript: "NM_014673.5", parsimonySitesP: "0.0830527497194164", constantSitesP: "0.823793490460157", distinctSitesP: "0.168350168350168", brLenTotal: "0.2964" },
  { geneTranscript: "NM_018047.3", parsimonySitesP: "0.153174603174603", constantSitesP: "0.691269841269841", distinctSitesP: "0.256349206349206", brLenTotal: "0.6260" },
  { geneTranscript: "NM_001551.3", parsimonySitesP: "0.295968534906588", constantSitesP: "0.489675516224189", distinctSitesP: "0.469026548672566", brLenTotal: "1.2695" },
  { geneTranscript: "NM_016048.2", parsimonySitesP: "0.143176733780761", constantSitesP: "0.720357941834452", distinctSitesP: "0.272930648769575", brLenTotal: "0.5488" },
  { geneTranscript: "NM_080873.3", parsimonySitesP: "0.187822497420021", constantSitesP: "0.676986584107327", distinctSitesP: "0.272445820433437", brLenTotal: "0.6971" },
  { geneTranscript: "NM_001690.4", parsimonySitesP: "0.129119394921664", constantSitesP: "0.779578606158833", distinctSitesP: "0.187466234467855", brLenTotal: "0.4260" },
  { geneTranscript: "NM_145008.3", parsimonySitesP: "0.099737532808399", constantSitesP: "0.816272965879265", distinctSitesP: "0.178477690288714", brLenTotal: "0.3616" },
  { geneTranscript: "NM_018318.5", parsimonySitesP: "0.154950869236584", constantSitesP: "0.702191987906274", distinctSitesP: "0.300831443688587", brLenTotal: "0.5270" },
  { geneTranscript: "NM_000440.3", parsimonySitesP: "0.191085271317829", constantSitesP: "0.703488372093023", distinctSitesP: "0.258527131782946", brLenTotal: "0.7635" },
  { geneTranscript: "NM_006257.5", parsimonySitesP: "0.223796033994334", constantSitesP: "0.677998111425873", distinctSitesP: "0.321529745042493", brLenTotal: "1.0446" },
  { geneTranscript: "NM_016361.5", parsimonySitesP: "0.256230529595016", constantSitesP: "0.592679127725857", distinctSitesP: "0.359034267912773", brLenTotal: "0.8771" },
  { geneTranscript: "NM_001131035.2", parsimonySitesP: "0.231242312423124", constantSitesP: "0.587945879458795", distinctSitesP: "0.391143911439114", brLenTotal: "0.9132" },
  { geneTranscript: "NM_014920.5", parsimonySitesP: "0.191983122362869", constantSitesP: "0.688291139240506", distinctSitesP: "0.270569620253165", brLenTotal: "0.6455" },
  { geneTranscript: "NM_022066.4", parsimonySitesP: "0.171826625386997", constantSitesP: "0.73968008255934", distinctSitesP: "0.197110423116615", brLenTotal: "0.7607" },
  { geneTranscript: "NM_003633.4", parsimonySitesP: "0.159026598754952", constantSitesP: "0.678551216751556", distinctSitesP: "0.201471420486701", brLenTotal: "1.4982" },
  { geneTranscript: "NM_005140.3", parsimonySitesP: "0.217369477911647", constantSitesP: "0.650602409638554", distinctSitesP: "0.299196787148594", brLenTotal: "0.7109" },
  { geneTranscript: "NM_144632.5", parsimonySitesP: "0.190684133915575", constantSitesP: "0.666666666666667", distinctSitesP: "0.291120815138282", brLenTotal: "0.7627" },
  { geneTranscript: "NM_145865.3", parsimonySitesP: "0.299760191846523", constantSitesP: "0.531574740207834", distinctSitesP: "0.452438049560352", brLenTotal: "1.2012" },
  { geneTranscript: "NM_181611.3", parsimonySitesP: "0.458333333333333", constantSitesP: "0.347222222222222", distinctSitesP: "0.648148148148148", brLenTotal: "2.3312" },
  { geneTranscript: "NM_030883.5", parsimonySitesP: "0.457805907172996", constantSitesP: "0.318565400843882", distinctSitesP: "0.642405063291139", brLenTotal: "2.2261" },
  { geneTranscript: "NM_001367498.1", parsimonySitesP: "0.196123437898495", constantSitesP: "0.687069625095639", distinctSitesP: "0.259117572047947", brLenTotal: "0.7591" },
  { geneTranscript: "NM_007209.4", parsimonySitesP: "0.195121951219512", constantSitesP: "0.650406504065041", distinctSitesP: "0.346883468834688", brLenTotal: "0.7151" },
  { geneTranscript: "NM_018460.4", parsimonySitesP: "0.117894736842105", constantSitesP: "0.764210526315789", distinctSitesP: "0.209824561403509", brLenTotal: "0.4001" },
  { geneTranscript: "NM_001689.5", parsimonySitesP: "0.110328638497653", constantSitesP: "0.737089201877934", distinctSitesP: "0.251173708920188", brLenTotal: "0.4204" },
  { geneTranscript: "NM_053051.5", parsimonySitesP: "0.239571797711333", constantSitesP: "0.602436323366556", distinctSitesP: "0.298265042451089", brLenTotal: "0.7770" },
  { geneTranscript: "NM_001080534.3", parsimonySitesP: "0.197831978319783", constantSitesP: "0.676452875639868", distinctSitesP: "0.258657031014755", brLenTotal: "0.6923" },
  { geneTranscript: "NM_018362.4", parsimonySitesP: "0.128595600676819", constantSitesP: "0.769881556683587", distinctSitesP: "0.2165820642978", brLenTotal: "0.5395" },
  { geneTranscript: "NM_001299.6", parsimonySitesP: "0.169472502805836", constantSitesP: "0.732884399551066", distinctSitesP: "0.239057239057239", brLenTotal: "0.7985" },
  { geneTranscript: "NM_016938.5", parsimonySitesP: "0.159518434913469", constantSitesP: "0.753197893152746", distinctSitesP: "0.212189616252822", brLenTotal: "0.7075" },
  { geneTranscript: "NM_016453.4", parsimonySitesP: "0.207294552169898", constantSitesP: "0.660203139427516", distinctSitesP: "0.264542936288089", brLenTotal: "0.8190" },
  { geneTranscript: "NM_001033566.3", parsimonySitesP: "0.0895295902883156", constantSitesP: "0.805766312594841", distinctSitesP: "0.160849772382398", brLenTotal: "0.3352" },
  { geneTranscript: "NM_002469.3", parsimonySitesP: "0.143250688705234", constantSitesP: "0.754820936639118", distinctSitesP: "0.228650137741047", brLenTotal: "0.4847" },
  { geneTranscript: "NM_001759.4", parsimonySitesP: "0.147635524798155", constantSitesP: "0.753171856978085", distinctSitesP: "0.221453287197232", brLenTotal: "0.6484" },
  { geneTranscript: "NM_020120.4", parsimonySitesP: "0.173633440514469", constantSitesP: "0.708681672025723", distinctSitesP: "0.236227224008574", brLenTotal: "0.6051" },
  { geneTranscript: "NM_001134663.2", parsimonySitesP: "0.0947712418300654", constantSitesP: "0.826797385620915", distinctSitesP: "0.179738562091503", brLenTotal: "0.3263" },
  { geneTranscript: "NM_024308.4", parsimonySitesP: "0.171794871794872", constantSitesP: "0.716666666666667", distinctSitesP: "0.26025641025641", brLenTotal: "0.6100" },
  { geneTranscript: "NM_002928.4", parsimonySitesP: "0.242574257425743", constantSitesP: "0.610561056105611", distinctSitesP: "0.367986798679868", brLenTotal: "0.9895" },
  { geneTranscript: "NM_001278720.2", parsimonySitesP: "0.146559428060769", constantSitesP: "0.772117962466488", distinctSitesP: "0.195710455764075", brLenTotal: "0.7753" },
  { geneTranscript: "NM_007026.4", parsimonySitesP: "0.116161616161616", constantSitesP: "0.81986531986532", distinctSitesP: "0.175084175084175", brLenTotal: "0.3971" },
  { geneTranscript: "NM_001040874.1", parsimonySitesP: "0.251282051282051", constantSitesP: "0.697435897435897", distinctSitesP: "0.305128205128205", brLenTotal: "2.1468" },
  { geneTranscript: "NM_175834.3", parsimonySitesP: "0.314641744548287", constantSitesP: "0.517757009345794", distinctSitesP: "0.438629283489097", brLenTotal: "1.6221" },
  { geneTranscript: "NM_031435.4", parsimonySitesP: "0.121345029239766", constantSitesP: "0.761695906432749", distinctSitesP: "0.260233918128655", brLenTotal: "0.3849" },
  { geneTranscript: "NM_018477.3", parsimonySitesP: "0.150279776179057", constantSitesP: "0.755395683453237", distinctSitesP: "0.239008792965627", brLenTotal: "0.4687" },
  { geneTranscript: "NM_000249.4", parsimonySitesP: "0.186507936507937", constantSitesP: "0.665343915343915", distinctSitesP: "0.286596119929453", brLenTotal: "0.6663" },
  { geneTranscript: "NM_006768.5", parsimonySitesP: "0.143018018018018", constantSitesP: "0.773648648648649", distinctSitesP: "0.186936936936937", brLenTotal: "0.4751" },
  { geneTranscript: "NM_032523.4", parsimonySitesP: "0.135617416131335", constantSitesP: "0.784796573875803", distinctSitesP: "0.175231977159172", brLenTotal: "0.4355" },
  { geneTranscript: "NM_001134673.4", parsimonySitesP: "0.0759659462999345", constantSitesP: "0.870988867059594", distinctSitesP: "0.112639161755075", brLenTotal: "0.2761" },
  { geneTranscript: "NM_016475.5", parsimonySitesP: "0.140407288317256", constantSitesP: "0.743837084673098", distinctSitesP: "0.22615219721329", brLenTotal: "0.5306" },
  { geneTranscript: "NM_005476.7", parsimonySitesP: "0.116805170821791", constantSitesP: "0.803785780240074", distinctSitesP: "0.153739612188366", brLenTotal: "0.3959" },
  { geneTranscript: "NM_021931.4", parsimonySitesP: "0.152678994784258", constantSitesP: "0.747273589378853", distinctSitesP: "0.234234234234234", brLenTotal: "0.5895" },
  { geneTranscript: "NM_024613.4", parsimonySitesP: "0.103078982597055", constantSitesP: "0.823293172690763", distinctSitesP: "0.167336010709505", brLenTotal: "0.2954" },
  { geneTranscript: "NM_001321926.2", parsimonySitesP: "0.123900879296563", constantSitesP: "0.772981614708233", distinctSitesP: "0.195843325339728", brLenTotal: "0.3746" },
  { geneTranscript: "NM_178148.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.699074074074074", distinctSitesP: "0.263117283950617", brLenTotal: "0.6178" },
  { geneTranscript: "NM_001185072.3", parsimonySitesP: "0.136612021857923", constantSitesP: "0.769125683060109", distinctSitesP: "0.224043715846995", brLenTotal: "0.4705" },
  { geneTranscript: "NM_001164262.3", parsimonySitesP: "0.284552845528455", constantSitesP: "0.420731707317073", distinctSitesP: "0.520325203252033", brLenTotal: "1.7330" },
  { geneTranscript: "NM_005772.5", parsimonySitesP: "0.151921358355675", constantSitesP: "0.74798927613941", distinctSitesP: "0.246648793565684", brLenTotal: "0.5110" },
  { geneTranscript: "NM_015124.5", parsimonySitesP: "0.193771626297578", constantSitesP: "0.716262975778547", distinctSitesP: "0.262975778546713", brLenTotal: "1.0399" },
  { geneTranscript: "NM_001374675.1", parsimonySitesP: "0.165989159891599", constantSitesP: "0.712737127371274", distinctSitesP: "0.242547425474255", brLenTotal: "0.5536" },
  { geneTranscript: "NM_001193646.2", parsimonySitesP: "0.23758865248227", constantSitesP: "0.606382978723404", distinctSitesP: "0.390070921985816", brLenTotal: "1.0596" },
  { geneTranscript: "NM_181844.4", parsimonySitesP: "0.193458594293667", constantSitesP: "0.693110647181628", distinctSitesP: "0.27696590118302", brLenTotal: "0.6751" },
  { geneTranscript: "NM_001142327.2", parsimonySitesP: "0.0942982456140351", constantSitesP: "0.819298245614035", distinctSitesP: "0.153508771929825", brLenTotal: "0.3054" },
  { geneTranscript: "NM_001349336.2", parsimonySitesP: "0.230439442658092", constantSitesP: "0.653804930332262", distinctSitesP: "0.331189710610932", brLenTotal: "0.8928" },
  { geneTranscript: "NM_016151.4", parsimonySitesP: "0.184615384615385", constantSitesP: "0.705802968960864", distinctSitesP: "0.20944669365722", brLenTotal: "0.6923" },
  { geneTranscript: "NM_014982.3", parsimonySitesP: "0.162750961127723", constantSitesP: "0.720632208457924", distinctSitesP: "0.207461198917841", brLenTotal: "0.6250" },
  { geneTranscript: "NM_003045.5", parsimonySitesP: "0.235294117647059", constantSitesP: "0.682034976152623", distinctSitesP: "0.294647588765236", brLenTotal: "1.0528" },
  { geneTranscript: "NM_001011658.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.652380952380952", distinctSitesP: "0.34047619047619", brLenTotal: "0.6667" },
  { geneTranscript: "NM_012067.3", parsimonySitesP: "0.30110775427996", constantSitesP: "0.525679758308157", distinctSitesP: "0.465256797583082", brLenTotal: "1.7008" },
  { geneTranscript: "NM_015326.5", parsimonySitesP: "0.116402116402116", constantSitesP: "0.791472144413321", distinctSitesP: "0.156551509492686", brLenTotal: "0.4128" },
  { geneTranscript: "NM_005120.3", parsimonySitesP: "0.114224467922217", constantSitesP: "0.801408666360435", distinctSitesP: "0.150512938294289", brLenTotal: "0.4025" },
  { geneTranscript: "NM_178564.4", parsimonySitesP: "0.204923486360612", constantSitesP: "0.678642714570858", distinctSitesP: "0.275449101796407", brLenTotal: "0.8863" },
  { geneTranscript: "NM_018011.4", parsimonySitesP: "0.0683760683760684", constantSitesP: "0.864468864468864", distinctSitesP: "0.133089133089133", brLenTotal: "0.1985" },
  { geneTranscript: "NM_177404.3", parsimonySitesP: "0.56388088376561", constantSitesP: "0.263208453410183", distinctSitesP: "0.720461095100865", brLenTotal: "3.1913" },
  { geneTranscript: "NM_002141.5", parsimonySitesP: "0.184375", constantSitesP: "0.660416666666667", distinctSitesP: "0.33125", brLenTotal: "0.7180" },
  { geneTranscript: "NM_001039479.2", parsimonySitesP: "0.123531794248684", constantSitesP: "0.778857837181045", distinctSitesP: "0.170919400567031", brLenTotal: "0.4244" },
  { geneTranscript: "NM_001628.4", parsimonySitesP: "0.267932489451477", constantSitesP: "0.588607594936709", distinctSitesP: "0.407172995780591", brLenTotal: "1.5059" },
  { geneTranscript: "NM_006032.4", parsimonySitesP: "0.168162776780371", constantSitesP: "0.747456612806703", distinctSitesP: "0.211250748055057", brLenTotal: "0.7049" },
  { geneTranscript: "NM_173505.4", parsimonySitesP: "0.162790697674419", constantSitesP: "0.744186046511628", distinctSitesP: "0.252491694352159", brLenTotal: "0.6164" },
  { geneTranscript: "NM_024680.4", parsimonySitesP: "0.201460976547482", constantSitesP: "0.661668589004229", distinctSitesP: "0.272202998846597", brLenTotal: "0.6691" },
  { geneTranscript: "NM_001002296.2", parsimonySitesP: "0.126520681265207", constantSitesP: "0.744525547445255", distinctSitesP: "0.250608272506083", brLenTotal: "0.4585" },
  { geneTranscript: "NM_004761.5", parsimonySitesP: "0.178893178893179", constantSitesP: "0.713427713427713", distinctSitesP: "0.222222222222222", brLenTotal: "0.5609" },
  { geneTranscript: "NM_006064.5", parsimonySitesP: "0.104046242774566", constantSitesP: "0.824662813102119", distinctSitesP: "0.158959537572254", brLenTotal: "0.3064" },
  { geneTranscript: "NM_032620.4", parsimonySitesP: "0.300135501355014", constantSitesP: "0.553523035230352", distinctSitesP: "0.415311653116531", brLenTotal: "1.2402" },
  { geneTranscript: "NM_001114394.3", parsimonySitesP: "0.0971074380165289", constantSitesP: "0.818870523415978", distinctSitesP: "0.161157024793388", brLenTotal: "0.3020" },
  { geneTranscript: "NM_014911.5", parsimonySitesP: "0.125910509885536", constantSitesP: "0.775927852930975", distinctSitesP: "0.183489420742282", brLenTotal: "0.4498" },
  { geneTranscript: "NM_001113207.2", parsimonySitesP: "0.252173913043478", constantSitesP: "0.602898550724638", distinctSitesP: "0.443478260869565", brLenTotal: "0.7523" },
  { geneTranscript: "NM_005795.6", parsimonySitesP: "0.167028199566161", constantSitesP: "0.732465654374548", distinctSitesP: "0.250903832248735", brLenTotal: "0.5819" },
  { geneTranscript: "NM_005281.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.638383838383838", distinctSitesP: "0.291919191919192", brLenTotal: "0.8584" },
  { geneTranscript: "NM_001418.4", parsimonySitesP: "0.0599044468945241", constantSitesP: "0.879823594266814", distinctSitesP: "0.100698272693863", brLenTotal: "0.1874" },
  { geneTranscript: "NM_003028.3", parsimonySitesP: "0.168958742632613", constantSitesP: "0.733464309102816", distinctSitesP: "0.225933202357564", brLenTotal: "0.6424" },
  { geneTranscript: "NM_017854.2", parsimonySitesP: "0.205673758865248", constantSitesP: "0.666666666666667", distinctSitesP: "0.310283687943262", brLenTotal: "0.9168" },
  { geneTranscript: "NM_153212.3", parsimonySitesP: "0.276942355889724", constantSitesP: "0.590225563909774", distinctSitesP: "0.379699248120301", brLenTotal: "1.4490" },
  { geneTranscript: "NM_003802.3", parsimonySitesP: "0.232198142414861", constantSitesP: "0.65359477124183", distinctSitesP: "0.285173718610251", brLenTotal: "1.4172" },
  { geneTranscript: "NM_033211.4", parsimonySitesP: "0.184466019417476", constantSitesP: "0.728155339805825", distinctSitesP: "0.237864077669903", brLenTotal: "0.5920" },
  { geneTranscript: "NM_001968.5", parsimonySitesP: "0.0798771121351767", constantSitesP: "0.823348694316436", distinctSitesP: "0.165898617511521", brLenTotal: "0.2769" },
  { geneTranscript: "NM_032223.4", parsimonySitesP: "0.202228777450016", constantSitesP: "0.701081612586037", distinctSitesP: "0.222877745001639", brLenTotal: "0.8879" },
  { geneTranscript: "NM_004168.4", parsimonySitesP: "0.241465863453815", constantSitesP: "0.626506024096386", distinctSitesP: "0.356425702811245", brLenTotal: "1.0669" },
  { geneTranscript: "NM_004840.3", parsimonySitesP: "0.152491408934708", constantSitesP: "0.753436426116838", distinctSitesP: "0.217783505154639", brLenTotal: "0.4446" },
  { geneTranscript: "NM_002010.3", parsimonySitesP: "0.136217948717949", constantSitesP: "0.782051282051282", distinctSitesP: "0.182692307692308", brLenTotal: "0.5211" },
  { geneTranscript: "NM_021814.5", parsimonySitesP: "0.17056856187291", constantSitesP: "0.70345596432553", distinctSitesP: "0.270903010033445", brLenTotal: "0.5988" },
  { geneTranscript: "NM_001080510.5", parsimonySitesP: "0.173684210526316", constantSitesP: "0.7", distinctSitesP: "0.291228070175439", brLenTotal: "0.5695" },
  { geneTranscript: "NM_001143978.3", parsimonySitesP: "0.303556658395368", constantSitesP: "0.476426799007444", distinctSitesP: "0.465674110835401", brLenTotal: "1.1859" },
  { geneTranscript: "NM_005465.7", parsimonySitesP: "0.0918580375782881", constantSitesP: "0.832289491997216", distinctSitesP: "0.148921363952679", brLenTotal: "0.3447" },
  { geneTranscript: "NM_005792.2", parsimonySitesP: "0.183333333333333", constantSitesP: "0.685416666666667", distinctSitesP: "0.347916666666667", brLenTotal: "0.5307" },
  { geneTranscript: "NM_003601.4", parsimonySitesP: "0.129277566539924", constantSitesP: "0.769961977186312", distinctSitesP: "0.170785804816223", brLenTotal: "0.4312" },
  { geneTranscript: "NM_001105571.3", parsimonySitesP: "0.209003215434084", constantSitesP: "0.643086816720257", distinctSitesP: "0.312968917470525", brLenTotal: "0.8356" },
  { geneTranscript: "NM_005239.6", parsimonySitesP: "0.198294243070362", constantSitesP: "0.686567164179104", distinctSitesP: "0.30135039090263", brLenTotal: "0.9833" },
  { geneTranscript: "NM_001004712.2", parsimonySitesP: "0.505376344086022", constantSitesP: "0.336559139784946", distinctSitesP: "0.652688172043011", brLenTotal: "2.5190" },
  { geneTranscript: "NM_014445.4", parsimonySitesP: "0.0808080808080808", constantSitesP: "0.838383838383838", distinctSitesP: "0.171717171717172", brLenTotal: "0.1934" },
  { geneTranscript: "NM_001098272.3", parsimonySitesP: "0.150641025641026", constantSitesP: "0.746153846153846", distinctSitesP: "0.209615384615385", brLenTotal: "0.4886" },
  { geneTranscript: "NM_022728.4", parsimonySitesP: "0.0791295746785361", constantSitesP: "0.864490603363007", distinctSitesP: "0.124629080118694", brLenTotal: "0.2569" },
  { geneTranscript: "NM_133452.3", parsimonySitesP: "0.209291835814163", constantSitesP: "0.67298150654037", distinctSitesP: "0.266576454668471", brLenTotal: "0.9886" },
  { geneTranscript: "NM_015047.3", parsimonySitesP: "0.171198388721047", constantSitesP: "0.725746894931185", distinctSitesP: "0.227257468949312", brLenTotal: "0.6173" },
  { geneTranscript: "NM_001695.5", parsimonySitesP: "0.12216404886562", constantSitesP: "0.782722513089005", distinctSitesP: "0.18935427574171", brLenTotal: "0.4026" },
  { geneTranscript: "NM_014431.3", parsimonySitesP: "0.263629283489097", constantSitesP: "0.586448598130841", distinctSitesP: "0.338395638629283", brLenTotal: "1.1020" },
  { geneTranscript: "NM_138794.5", parsimonySitesP: "0.20393811533052", constantSitesP: "0.628691983122363", distinctSitesP: "0.361462728551336", brLenTotal: "0.7388" },
  { geneTranscript: "NM_007050.6", parsimonySitesP: "0.163312514457553", constantSitesP: "0.753411982419616", distinctSitesP: "0.191071015498496", brLenTotal: "0.7280" },
  { geneTranscript: "NM_001145306.2", parsimonySitesP: "0.110429447852761", constantSitesP: "0.770961145194274", distinctSitesP: "0.185071574642127", brLenTotal: "0.4449" },
  { geneTranscript: "NM_139012.3", parsimonySitesP: "0.087037037037037", constantSitesP: "0.843518518518519", distinctSitesP: "0.147222222222222", brLenTotal: "0.2919" },
  { geneTranscript: "NM_012252.4", parsimonySitesP: "0.170989433237272", constantSitesP: "0.708933717579251", distinctSitesP: "0.273775216138329", brLenTotal: "0.5308" },
  { geneTranscript: "NM_004888.4", parsimonySitesP: "0.169491525423729", constantSitesP: "0.666666666666667", distinctSitesP: "0.344632768361582", brLenTotal: "0.6302" },
  { geneTranscript: "NM_001271803.2", parsimonySitesP: "0.175853018372703", constantSitesP: "0.741469816272966", distinctSitesP: "0.242782152230971", brLenTotal: "0.5684" },
  { geneTranscript: "NM_002235.5", parsimonySitesP: "0.293005671077505", constantSitesP: "0.615626969124134", distinctSitesP: "0.352236925015753", brLenTotal: "2.1600" },
  { geneTranscript: "NM_001368120.1", parsimonySitesP: "0.28030303030303", constantSitesP: "0.567234848484848", distinctSitesP: "0.410984848484848", brLenTotal: "1.2967" },
  { geneTranscript: "NM_014042.3", parsimonySitesP: "0.110192837465565", constantSitesP: "0.765840220385675", distinctSitesP: "0.242424242424242", brLenTotal: "0.4904" },
  { geneTranscript: "NM_001367484.1", parsimonySitesP: "0.268763102725367", constantSitesP: "0.561844863731656", distinctSitesP: "0.369392033542977", brLenTotal: "1.1612" },
  { geneTranscript: "NM_003682.4", parsimonySitesP: "0.143695608176482", constantSitesP: "0.746002833434527", distinctSitesP: "0.172434729811779", brLenTotal: "0.5179" },
  { geneTranscript: "NM_213647.3", parsimonySitesP: "0.252285951787199", constantSitesP: "0.607231920199501", distinctSitesP: "0.337905236907731", brLenTotal: "1.2094" },
  { geneTranscript: "NM_023018.5", parsimonySitesP: "0.234678624813154", constantSitesP: "0.654708520179372", distinctSitesP: "0.334080717488789", brLenTotal: "1.1313" },
  { geneTranscript: "NM_006486.3", parsimonySitesP: "0.20199146514936", constantSitesP: "0.695590327169275", distinctSitesP: "0.287339971550498", brLenTotal: "0.9113" },
  { geneTranscript: "NM_001034172.4", parsimonySitesP: "0.325476992143659", constantSitesP: "0.408529741863075", distinctSitesP: "0.561167227833895", brLenTotal: "1.3891" },
  { geneTranscript: "NM_001134231.2", parsimonySitesP: "0.19509275882705", constantSitesP: "0.678635547576302", distinctSitesP: "0.288450029922202", brLenTotal: "0.8442" },
  { geneTranscript: "NM_005556.4", parsimonySitesP: "0.309168443496802", constantSitesP: "0.512437810945274", distinctSitesP: "0.466950959488273", brLenTotal: "1.8419" },
  { geneTranscript: "NM_001217.5", parsimonySitesP: "0.129065040650407", constantSitesP: "0.776422764227642", distinctSitesP: "0.204268292682927", brLenTotal: "0.4435" },
  { geneTranscript: "NM_001281445.2", parsimonySitesP: "0.18859649122807", constantSitesP: "0.692982456140351", distinctSitesP: "0.315789473684211", brLenTotal: "0.8016" },
  { geneTranscript: "NM_001005278.2", parsimonySitesP: "0.409043112513144", constantSitesP: "0.442691903259727", distinctSitesP: "0.535226077812829", brLenTotal: "1.3928" },
  { geneTranscript: "NM_031911.5", parsimonySitesP: "0.168396770472895", constantSitesP: "0.722029988465975", distinctSitesP: "0.253748558246828", brLenTotal: "0.6210" },
  { geneTranscript: "NM_001190850.2", parsimonySitesP: "0.0579710144927536", constantSitesP: "0.881252921926134", distinctSitesP: "0.100514258999532", brLenTotal: "0.2043" },
  { geneTranscript: "NM_001286086.2", parsimonySitesP: "0.197831978319783", constantSitesP: "0.628726287262873", distinctSitesP: "0.349593495934959", brLenTotal: "0.6773" },
  { geneTranscript: "NM_015610.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.715859030837004", distinctSitesP: "0.278267254038179", brLenTotal: "0.7298" },
  { geneTranscript: "NM_005548.3", parsimonySitesP: "0.201005025125628", constantSitesP: "0.660524846454495", distinctSitesP: "0.299832495812395", brLenTotal: "0.7190" },
  { geneTranscript: "NM_006472.6", parsimonySitesP: "0.184995737425405", constantSitesP: "0.70076726342711", distinctSitesP: "0.26768968456948", brLenTotal: "0.6387" },
  { geneTranscript: "NM_001005193.2", parsimonySitesP: "0.584362139917695", constantSitesP: "0.270576131687243", distinctSitesP: "0.713991769547325", brLenTotal: "4.3188" },
  { geneTranscript: "NM_002602.4", parsimonySitesP: "0.134099616858238", constantSitesP: "0.75095785440613", distinctSitesP: "0.272030651340996", brLenTotal: "0.5297" },
  { geneTranscript: "NM_018989.2", parsimonySitesP: "0.118238993710692", constantSitesP: "0.793081761006289", distinctSitesP: "0.166352201257862", brLenTotal: "0.3898" },
  { geneTranscript: "NM_004117.4", parsimonySitesP: "0.186725018234865", constantSitesP: "0.711889132020423", distinctSitesP: "0.25382932166302", brLenTotal: "0.5760" },
  { geneTranscript: "NM_153273.4", parsimonySitesP: "0.17838246409675", constantSitesP: "0.720332577475435", distinctSitesP: "0.226001511715797", brLenTotal: "0.5865" },
  { geneTranscript: "NM_004780.3", parsimonySitesP: "0.180293501048218", constantSitesP: "0.687631027253669", distinctSitesP: "0.310272536687631", brLenTotal: "0.5593" },
  { geneTranscript: "NM_022568.4", parsimonySitesP: "0.223134839151266", constantSitesP: "0.649555099247091", distinctSitesP: "0.326488706365503", brLenTotal: "0.9902" },
  { geneTranscript: "NM_013265.4", parsimonySitesP: "0.196504688832055", constantSitesP: "0.702898550724638", distinctSitesP: "0.234015345268542", brLenTotal: "0.8313" },
  { geneTranscript: "NM_001330360.2", parsimonySitesP: "0.162352406902816", constantSitesP: "0.723887375113533", distinctSitesP: "0.219800181653043", brLenTotal: "0.4914" },
  { geneTranscript: "NM_018571.6", parsimonySitesP: "0.135566188197767", constantSitesP: "0.721690590111643", distinctSitesP: "0.251993620414673", brLenTotal: "0.5103" },
  { geneTranscript: "NM_014055.4", parsimonySitesP: "0.148915187376726", constantSitesP: "0.736686390532544", distinctSitesP: "0.226331360946746", brLenTotal: "0.4023" },
  { geneTranscript: "NM_031904.5", parsimonySitesP: "0.237068965517241", constantSitesP: "0.620689655172414", distinctSitesP: "0.341235632183908", brLenTotal: "1.0592" },
  { geneTranscript: "NM_005131.3", parsimonySitesP: "0.106544901065449", constantSitesP: "0.80517503805175", distinctSitesP: "0.159817351598174", brLenTotal: "0.3553" },
  { geneTranscript: "NM_001039762.3", parsimonySitesP: "0.27348643006263", constantSitesP: "0.597773138482951", distinctSitesP: "0.406402226861517", brLenTotal: "1.0865" },
  { geneTranscript: "NM_144598.5", parsimonySitesP: "0.115349682107175", constantSitesP: "0.748410535876476", distinctSitesP: "0.210717529518619", brLenTotal: "0.5490" },
  { geneTranscript: "NM_001365790.2", parsimonySitesP: "0.252613969050606", constantSitesP: "0.586365537432037", distinctSitesP: "0.345462149728147", brLenTotal: "0.8797" },
  { geneTranscript: "NM_021064.5", parsimonySitesP: "0.248717948717949", constantSitesP: "0.664102564102564", distinctSitesP: "0.335897435897436", brLenTotal: "1.8073" },
  { geneTranscript: "NM_006939.4", parsimonySitesP: "0.129379379379379", constantSitesP: "0.775275275275275", distinctSitesP: "0.155405405405405", brLenTotal: "0.3930" },
  { geneTranscript: "NM_004463.3", parsimonySitesP: "0.147415886229622", constantSitesP: "0.742976066597294", distinctSitesP: "0.189386056191467", brLenTotal: "0.4787" },
  { geneTranscript: "NM_183235.3", parsimonySitesP: "0.167420814479638", constantSitesP: "0.7526395173454", distinctSitesP: "0.254901960784314", brLenTotal: "0.5603" },
  { geneTranscript: "NM_030760.5", parsimonySitesP: "0.301507537688442", constantSitesP: "0.562814070351759", distinctSitesP: "0.394472361809045", brLenTotal: "1.2491" },
  { geneTranscript: "NM_017516.3", parsimonySitesP: "0.135176651305684", constantSitesP: "0.674347158218126", distinctSitesP: "0.231950844854071", brLenTotal: "0.7056" },
  { geneTranscript: "NM_194454.3", parsimonySitesP: "0.118659420289855", constantSitesP: "0.788949275362319", distinctSitesP: "0.171195652173913", brLenTotal: "0.3652" },
  { geneTranscript: "NM_020532.5", parsimonySitesP: "0.207774049217002", constantSitesP: "0.642337807606264", distinctSitesP: "0.29334451901566", brLenTotal: "0.6459" },
  { geneTranscript: "NM_020779.4", parsimonySitesP: "0.187179487179487", constantSitesP: "0.66980056980057", distinctSitesP: "0.264102564102564", brLenTotal: "0.6340" },
  { geneTranscript: "NM_006082.3", parsimonySitesP: "0.291943828529194", constantSitesP: "0.568366592756837", distinctSitesP: "0.403547671840355", brLenTotal: "2.1562" },
  { geneTranscript: "NM_006270.5", parsimonySitesP: "0.175840978593272", constantSitesP: "0.729357798165138", distinctSitesP: "0.250764525993884", brLenTotal: "0.7646" },
  { geneTranscript: "NM_001190946.3", parsimonySitesP: "0.203568532035685", constantSitesP: "0.619626926196269", distinctSitesP: "0.279805352798054", brLenTotal: "0.7706" },
  { geneTranscript: "NM_030645.3", parsimonySitesP: "0.239185750636132", constantSitesP: "0.67175572519084", distinctSitesP: "0.287531806615776", brLenTotal: "0.9516" },
  { geneTranscript: "NM_012394.4", parsimonySitesP: "0.136363636363636", constantSitesP: "0.781385281385281", distinctSitesP: "0.220779220779221", brLenTotal: "0.3930" },
  { geneTranscript: "NM_002380.5", parsimonySitesP: "0.194211994421199", constantSitesP: "0.676429567642957", distinctSitesP: "0.267782426778243", brLenTotal: "0.7618" },
  { geneTranscript: "NM_015651.3", parsimonySitesP: "0.123563218390805", constantSitesP: "0.783908045977011", distinctSitesP: "0.171264367816092", brLenTotal: "0.4735" },
  { geneTranscript: "NM_020680.4", parsimonySitesP: "0.202145214521452", constantSitesP: "0.676980198019802", distinctSitesP: "0.265676567656766", brLenTotal: "0.8297" },
  { geneTranscript: "NM_017442.4", parsimonySitesP: "0.289405684754522", constantSitesP: "0.566537467700258", distinctSitesP: "0.322674418604651", brLenTotal: "1.1851" },
  { geneTranscript: "NM_015714.4", parsimonySitesP: "0.297734627831715", constantSitesP: "0.524271844660194", distinctSitesP: "0.482200647249191", brLenTotal: "1.1005" },
  { geneTranscript: "NM_012337.3", parsimonySitesP: "0.223230490018149", constantSitesP: "0.625529340592861", distinctSitesP: "0.317604355716878", brLenTotal: "0.7345" },
  { geneTranscript: "NM_001001344.3", parsimonySitesP: "0.237431693989071", constantSitesP: "0.669398907103825", distinctSitesP: "0.276775956284153", brLenTotal: "1.3274" },
  { geneTranscript: "NM_001099270.4", parsimonySitesP: "0.126322751322751", constantSitesP: "0.774470899470899", distinctSitesP: "0.180555555555556", brLenTotal: "0.5353" },
  { geneTranscript: "NM_002250.3", parsimonySitesP: "0.192037470725995", constantSitesP: "0.693989071038251", distinctSitesP: "0.269320843091335", brLenTotal: "0.6980" },
  { geneTranscript: "NM_198391.3", parsimonySitesP: "0.115048793014895", constantSitesP: "0.781201848998459", distinctSitesP: "0.175141242937853", brLenTotal: "0.3643" },
  { geneTranscript: "NM_016507.4", parsimonySitesP: "0.143176733780761", constantSitesP: "0.738255033557047", distinctSitesP: "0.184563758389262", brLenTotal: "0.5163" },
  { geneTranscript: "NM_001001827.2", parsimonySitesP: "0.52218782249742", constantSitesP: "0.336429308565531", distinctSitesP: "0.647058823529412", brLenTotal: "3.1709" },
  { geneTranscript: "NM_001376376.1", parsimonySitesP: "0.0765239948119326", constantSitesP: "0.85538261997406", distinctSitesP: "0.127107652399481", brLenTotal: "0.2407" },
  { geneTranscript: "NM_001168368.2", parsimonySitesP: "0.149940262843489", constantSitesP: "0.743130227001195", distinctSitesP: "0.204301075268817", brLenTotal: "0.6481" },
  { geneTranscript: "NM_001077639.2", parsimonySitesP: "0.33578431372549", constantSitesP: "0.474877450980392", distinctSitesP: "0.488357843137255", brLenTotal: "1.7800" },
  { geneTranscript: "NM_001145358.2", parsimonySitesP: "0.148730034040325", constantSitesP: "0.73108143493061", distinctSitesP: "0.184341450641529", brLenTotal: "0.5262" },
  { geneTranscript: "NM_004466.6", parsimonySitesP: "0.226689976689977", constantSitesP: "0.604312354312354", distinctSitesP: "0.352564102564103", brLenTotal: "0.8198" },
  { geneTranscript: "NM_025106.4", parsimonySitesP: "0.239316239316239", constantSitesP: "0.675213675213675", distinctSitesP: "0.283272283272283", brLenTotal: "1.2518" },
  { geneTranscript: "NM_001080464.3", parsimonySitesP: "0.345549738219895", constantSitesP: "0.460732984293194", distinctSitesP: "0.531122745782432", brLenTotal: "1.6065" },
  { geneTranscript: "NM_002612.4", parsimonySitesP: "0.160583941605839", constantSitesP: "0.712084347120843", distinctSitesP: "0.265206812652068", brLenTotal: "0.5534" },
  { geneTranscript: "NM_005068.3", parsimonySitesP: "0.155352480417755", constantSitesP: "0.733246301131419", distinctSitesP: "0.215404699738903", brLenTotal: "0.5600" },
  { geneTranscript: "NM_005314.3", parsimonySitesP: "0.154513888888889", constantSitesP: "0.741319444444444", distinctSitesP: "0.222222222222222", brLenTotal: "0.5068" },
  { geneTranscript: "NM_001080825.2", parsimonySitesP: "0.178957718780728", constantSitesP: "0.716814159292035", distinctSitesP: "0.268436578171091", brLenTotal: "0.6548" },
  { geneTranscript: "NM_001244705.2", parsimonySitesP: "0.194050033806626", constantSitesP: "0.686950642325896", distinctSitesP: "0.289384719405003", brLenTotal: "0.7331" },
  { geneTranscript: "NM_144576.4", parsimonySitesP: "0.175438596491228", constantSitesP: "0.684210526315789", distinctSitesP: "0.310391363022942", brLenTotal: "0.5848" },
  { geneTranscript: "NM_001166222.2", parsimonySitesP: "0.233684210526316", constantSitesP: "0.641052631578947", distinctSitesP: "0.286666666666667", brLenTotal: "0.9094" },
  { geneTranscript: "NM_004045.4", parsimonySitesP: "0.235294117647059", constantSitesP: "0.632352941176471", distinctSitesP: "0.387254901960784", brLenTotal: "0.7247" },
  { geneTranscript: "NM_170665.4", parsimonySitesP: "0.142034548944338", constantSitesP: "0.758797184900832", distinctSitesP: "0.186500319897633", brLenTotal: "0.6065" },
  { geneTranscript: "NM_004304.5", parsimonySitesP: "0.216049382716049", constantSitesP: "0.6559670781893", distinctSitesP: "0.282921810699588", brLenTotal: "0.9677" },
  { geneTranscript: "NM_018925.3", parsimonySitesP: "0.42795232936078", constantSitesP: "0.42578548212351", distinctSitesP: "0.566630552546046", brLenTotal: "3.8607" },
  { geneTranscript: "NM_002270.4", parsimonySitesP: "0.112843355605048", constantSitesP: "0.78619153674833", distinctSitesP: "0.170007423904974", brLenTotal: "0.4157" },
  { geneTranscript: "NM_001004697.2", parsimonySitesP: "0.594708994708995", constantSitesP: "0.28994708994709", distinctSitesP: "0.721693121693122", brLenTotal: "3.7533" },
  { geneTranscript: "NM_001160325.2", parsimonySitesP: "0.439537329127234", constantSitesP: "0.407991587802313", distinctSitesP: "0.562565720294427", brLenTotal: "1.6273" },
  { geneTranscript: "NM_031475.3", parsimonySitesP: "0.215456674473068", constantSitesP: "0.642857142857143", distinctSitesP: "0.278688524590164", brLenTotal: "0.8606" },
  { geneTranscript: "NM_001102450.3", parsimonySitesP: "0.122222222222222", constantSitesP: "0.8", distinctSitesP: "0.212962962962963", brLenTotal: "0.3955" },
  { geneTranscript: "NM_005012.4", parsimonySitesP: "0.184631803628602", constantSitesP: "0.724297403059409", distinctSitesP: "0.222340803984347", brLenTotal: "0.6706" },
  { geneTranscript: "NM_004726.3", parsimonySitesP: "0.165656565656566", constantSitesP: "0.713131313131313", distinctSitesP: "0.265151515151515", brLenTotal: "0.5949" },
  { geneTranscript: "NM_001013657.3", parsimonySitesP: "0.288311688311688", constantSitesP: "0.563636363636364", distinctSitesP: "0.395670995670996", brLenTotal: "1.0389" },
  { geneTranscript: "NM_013247.5", parsimonySitesP: "0.205967976710335", constantSitesP: "0.65429403202329", distinctSitesP: "0.265647743813683", brLenTotal: "0.6593" },
  { geneTranscript: "NM_004148.4", parsimonySitesP: "0.291666666666667", constantSitesP: "0.62280701754386", distinctSitesP: "0.383771929824561", brLenTotal: "1.0295" },
  { geneTranscript: "NM_002774.4", parsimonySitesP: "0.237704918032787", constantSitesP: "0.60655737704918", distinctSitesP: "0.360655737704918", brLenTotal: "1.1135" },
  { geneTranscript: "NM_024956.4", parsimonySitesP: "0.196993260756869", constantSitesP: "0.674442716433385", distinctSitesP: "0.287195438050804", brLenTotal: "0.6025" },
  { geneTranscript: "NM_177417.3", parsimonySitesP: "0.246031746031746", constantSitesP: "0.62037037037037", distinctSitesP: "0.351190476190476", brLenTotal: "1.2816" },
  { geneTranscript: "NM_003498.6", parsimonySitesP: "0.143939393939394", constantSitesP: "0.787878787878788", distinctSitesP: "0.208333333333333", brLenTotal: "0.5692" },
  { geneTranscript: "NM_004778.3", parsimonySitesP: "0.263291139240506", constantSitesP: "0.60253164556962", distinctSitesP: "0.363713080168776", brLenTotal: "1.0400" },
  { geneTranscript: "NM_032961.3", parsimonySitesP: "0.127884615384615", constantSitesP: "0.765705128205128", distinctSitesP: "0.161538461538462", brLenTotal: "0.4958" },
  { geneTranscript: "NM_025092.5", parsimonySitesP: "0.312075983717775", constantSitesP: "0.530076888285844", distinctSitesP: "0.436001809136137", brLenTotal: "1.4731" },
  { geneTranscript: "NM_001255.3", parsimonySitesP: "0.132264529058116", constantSitesP: "0.721442885771543", distinctSitesP: "0.214428857715431", brLenTotal: "0.4716" },
  { geneTranscript: "NM_019016.3", parsimonySitesP: "0.325714285714286", constantSitesP: "0.480634920634921", distinctSitesP: "0.499047619047619", brLenTotal: "1.3031" },
  { geneTranscript: "NM_001457.4", parsimonySitesP: "0.200614911606457", constantSitesP: "0.709198052779913", distinctSitesP: "0.222136817832437", brLenTotal: "0.9785" },
  { geneTranscript: "NM_018847.4", parsimonySitesP: "0.117233927606699", constantSitesP: "0.781739600216099", distinctSitesP: "0.17882225823879", brLenTotal: "0.4090" },
  { geneTranscript: "NM_018297.4", parsimonySitesP: "0.17533129459735", constantSitesP: "0.667176350662589", distinctSitesP: "0.275739041794088", brLenTotal: "0.6106" },
  { geneTranscript: "NM_001039574.3", parsimonySitesP: "0.198615548455804", constantSitesP: "0.668264110756124", distinctSitesP: "0.276357827476038", brLenTotal: "1.0833" },
  { geneTranscript: "NM_001363538.2", parsimonySitesP: "0.373957199854915", constantSitesP: "0.464272760246645", distinctSitesP: "0.443598113891911", brLenTotal: "1.5885" },
  { geneTranscript: "NM_015980.5", parsimonySitesP: "0.124756335282651", constantSitesP: "0.816764132553606", distinctSitesP: "0.181286549707602", brLenTotal: "0.4296" },
  { geneTranscript: "NM_001130445.3", parsimonySitesP: "0.208101667990469", constantSitesP: "0.638866825522902", distinctSitesP: "0.303680169446651", brLenTotal: "0.7333" },
  { geneTranscript: "NM_001331036.3", parsimonySitesP: "0.137155705452501", constantSitesP: "0.725688589094997", distinctSitesP: "0.197301854974705", brLenTotal: "0.5282" },
  { geneTranscript: "NM_014502.5", parsimonySitesP: "0.140873015873016", constantSitesP: "0.777777777777778", distinctSitesP: "0.19510582010582", brLenTotal: "0.5648" },
  { geneTranscript: "NM_001100399.2", parsimonySitesP: "0.131388681126901", constantSitesP: "0.75268012964348", distinctSitesP: "0.176265270506108", brLenTotal: "0.5235" },
  { geneTranscript: "NM_207309.3", parsimonySitesP: "0.262984878369494", constantSitesP: "0.585141354372124", distinctSitesP: "0.3767258382643", brLenTotal: "1.0814" },
  { geneTranscript: "NM_001006605.5", parsimonySitesP: "0.108255451713396", constantSitesP: "0.764797507788162", distinctSitesP: "0.175233644859813", brLenTotal: "0.3989" },
  { geneTranscript: "NM_001330067.2", parsimonySitesP: "0.208791208791209", constantSitesP: "0.702380952380952", distinctSitesP: "0.266483516483516", brLenTotal: "0.9148" },
  { geneTranscript: "NM_016281.4", parsimonySitesP: "0.0872308834446919", constantSitesP: "0.818856718634001", distinctSitesP: "0.138827023014105", brLenTotal: "0.3108" },
  { geneTranscript: "NM_000934.4", parsimonySitesP: "0.304141208418194", constantSitesP: "0.533604887983707", distinctSitesP: "0.430414120841819", brLenTotal: "1.1901" },
  { geneTranscript: "NM_001035267.2", parsimonySitesP: "0.0666666666666667", constantSitesP: "0.746666666666667", distinctSitesP: "0.346666666666667", brLenTotal: "0.3023" },
  { geneTranscript: "NM_001080418.3", parsimonySitesP: "0.12087163772557", constantSitesP: "0.800476676881171", distinctSitesP: "0.156962887299966", brLenTotal: "0.4692" },
  { geneTranscript: "NM_004771.4", parsimonySitesP: "0.20151828847481", constantSitesP: "0.684610075914424", distinctSitesP: "0.301587301587302", brLenTotal: "0.7621" },
  { geneTranscript: "NM_033453.4", parsimonySitesP: "0.233676975945017", constantSitesP: "0.680412371134021", distinctSitesP: "0.335051546391753", brLenTotal: "0.8457" },
  { geneTranscript: "NM_001320973.2", parsimonySitesP: "0.190833333333333", constantSitesP: "0.633333333333333", distinctSitesP: "0.319166666666667", brLenTotal: "0.6693" },
  { geneTranscript: "NM_014426.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.693894389438944", distinctSitesP: "0.296204620462046", brLenTotal: "0.6121" },
  { geneTranscript: "NM_015399.4", parsimonySitesP: "0.17479674796748", constantSitesP: "0.730352303523035", distinctSitesP: "0.266937669376694", brLenTotal: "0.5993" },
  { geneTranscript: "NM_001122772.3", parsimonySitesP: "0.165268456375839", constantSitesP: "0.738814317673378", distinctSitesP: "0.20413870246085", brLenTotal: "0.5894" },
  { geneTranscript: "NM_001004750.1", parsimonySitesP: "0.441239316239316", constantSitesP: "0.389957264957265", distinctSitesP: "0.615384615384615", brLenTotal: "2.0764" },
  { geneTranscript: "NM_020851.3", parsimonySitesP: "0.25413870246085", constantSitesP: "0.612080536912752", distinctSitesP: "0.314988814317673", brLenTotal: "0.9762" },
  { geneTranscript: "NM_005851.5", parsimonySitesP: "0.132275132275132", constantSitesP: "0.761904761904762", distinctSitesP: "0.224867724867725", brLenTotal: "0.5250" },
  { geneTranscript: "NM_001127258.3", parsimonySitesP: "0.296248934356351", constantSitesP: "0.562659846547315", distinctSitesP: "0.388320545609548", brLenTotal: "1.1141" },
  { geneTranscript: "NM_024015.5", parsimonySitesP: "0.114209827357238", constantSitesP: "0.788844621513944", distinctSitesP: "0.197875166002656", brLenTotal: "0.3888" },
  { geneTranscript: "NM_001145115.3", parsimonySitesP: "0.337057728119181", constantSitesP: "0.458100558659218", distinctSitesP: "0.535381750465549", brLenTotal: "1.3899" },
  { geneTranscript: "NM_018092.5", parsimonySitesP: "0.166349206349206", constantSitesP: "0.725714285714286", distinctSitesP: "0.246349206349206", brLenTotal: "0.5582" },
  { geneTranscript: "NM_001849.4", parsimonySitesP: "0.235525024533857", constantSitesP: "0.664376840039254", distinctSitesP: "0.301929996728819", brLenTotal: "1.2177" },
  { geneTranscript: "NM_021192.3", parsimonySitesP: "0.176528599605523", constantSitesP: "0.707100591715976", distinctSitesP: "0.292899408284024", brLenTotal: "0.7297" },
  { geneTranscript: "NM_001040283.3", parsimonySitesP: "0.280653950953678", constantSitesP: "0.554041780199818", distinctSitesP: "0.37511353315168", brLenTotal: "0.9670" },
  { geneTranscript: "NM_004593.3", parsimonySitesP: "0.145833333333333", constantSitesP: "0.77662037037037", distinctSitesP: "0.21412037037037", brLenTotal: "0.5055" },
  { geneTranscript: "NM_001388022.1", parsimonySitesP: "0.234913793103448", constantSitesP: "0.59698275862069", distinctSitesP: "0.343869731800766", brLenTotal: "0.7893" },
  { geneTranscript: "NM_006581.4", parsimonySitesP: "0.114206128133705", constantSitesP: "0.792943361188487", distinctSitesP: "0.184772516248839", brLenTotal: "0.3764" },
  { geneTranscript: "NM_002197.3", parsimonySitesP: "0.186726659167604", constantSitesP: "0.704911886014248", distinctSitesP: "0.255718035245594", brLenTotal: "0.6514" },
  { geneTranscript: "NM_016100.5", parsimonySitesP: "0.153558052434457", constantSitesP: "0.722846441947566", distinctSitesP: "0.277153558052434", brLenTotal: "0.6591" },
  { geneTranscript: "NM_004252.5", parsimonySitesP: "0.238361266294227", constantSitesP: "0.577281191806331", distinctSitesP: "0.383612662942272", brLenTotal: "0.9708" },
  { geneTranscript: "NM_001005326.2", parsimonySitesP: "0.525641025641026", constantSitesP: "0.293803418803419", distinctSitesP: "0.663461538461538", brLenTotal: "2.7712" },
  { geneTranscript: "NM_003753.4", parsimonySitesP: "0.151459854014599", constantSitesP: "0.740875912408759", distinctSitesP: "0.223844282238443", brLenTotal: "0.6417" },
  { geneTranscript: "NM_001162383.2", parsimonySitesP: "0.179513184584178", constantSitesP: "0.713657876943881", distinctSitesP: "0.216024340770791", brLenTotal: "0.6414" },
  { geneTranscript: "NM_173602.3", parsimonySitesP: "0.124788494077834", constantSitesP: "0.78087986463621", distinctSitesP: "0.157994923857868", brLenTotal: "0.4415" },
  { geneTranscript: "NM_145811.3", parsimonySitesP: "0.193939393939394", constantSitesP: "0.696969696969697", distinctSitesP: "0.259393939393939", brLenTotal: "0.9716" },
  { geneTranscript: "NM_015578.4", parsimonySitesP: "0.169186465082793", constantSitesP: "0.633549316054716", distinctSitesP: "0.324694024478042", brLenTotal: "0.6972" },
  { geneTranscript: "NM_003347.4", parsimonySitesP: "0.101731601731602", constantSitesP: "0.718614718614719", distinctSitesP: "0.266233766233766", brLenTotal: "0.4989" },
  { geneTranscript: "NM_182553.3", parsimonySitesP: "0.0479166666666667", constantSitesP: "0.897916666666667", distinctSitesP: "0.0958333333333333", brLenTotal: "0.1569" },
  { geneTranscript: "NM_001037442.4", parsimonySitesP: "0.158602150537634", constantSitesP: "0.732258064516129", distinctSitesP: "0.236559139784946", brLenTotal: "0.5718" },
  { geneTranscript: "NM_000314.8", parsimonySitesP: "0.0479735318444996", constantSitesP: "0.909015715467328", distinctSitesP: "0.086848635235732", brLenTotal: "0.1259" },
  { geneTranscript: "NM_014975.3", parsimonySitesP: "0.212951167728238", constantSitesP: "0.687048832271762", distinctSitesP: "0.236942675159236", brLenTotal: "0.8610" },
  { geneTranscript: "NM_018128.5", parsimonySitesP: "0.204394693200663", constantSitesP: "0.649253731343284", distinctSitesP: "0.282338308457711", brLenTotal: "0.6524" },
  { geneTranscript: "NM_001144074.3", parsimonySitesP: "0.142424242424242", constantSitesP: "0.757575757575758", distinctSitesP: "0.187272727272727", brLenTotal: "0.4891" },
  { geneTranscript: "NM_001052.4", parsimonySitesP: "0.259450171821306", constantSitesP: "0.600515463917526", distinctSitesP: "0.36426116838488", brLenTotal: "1.1330" },
  { geneTranscript: "NM_032531.4", parsimonySitesP: "0.165809768637532", constantSitesP: "0.746358183376178", distinctSitesP: "0.221936589545844", brLenTotal: "0.8291" },
  { geneTranscript: "NM_001017405.3", parsimonySitesP: "0.164983164983165", constantSitesP: "0.740740740740741", distinctSitesP: "0.218855218855219", brLenTotal: "0.9051" },
  { geneTranscript: "NM_002157.3", parsimonySitesP: "0.101307189542484", constantSitesP: "0.73202614379085", distinctSitesP: "0.258169934640523", brLenTotal: "0.4019" },
  { geneTranscript: "NM_020344.4", parsimonySitesP: "0.167927382753404", constantSitesP: "0.718103883005547", distinctSitesP: "0.238527483610691", brLenTotal: "0.5857" },
  { geneTranscript: "NM_005077.5", parsimonySitesP: "0.159307359307359", constantSitesP: "0.754112554112554", distinctSitesP: "0.21991341991342", brLenTotal: "1.0968" },
  { geneTranscript: "NM_001005329.2", parsimonySitesP: "0.499467518636848", constantSitesP: "0.342917997870075", distinctSitesP: "0.652822151224707", brLenTotal: "2.6384" },
  { geneTranscript: "NM_178812.4", parsimonySitesP: "0.116265750286369", constantSitesP: "0.781786941580756", distinctSitesP: "0.192439862542955", brLenTotal: "0.3607" },
  { geneTranscript: "NM_006476.5", parsimonySitesP: "0.161812297734628", constantSitesP: "0.618122977346278", distinctSitesP: "0.385113268608414", brLenTotal: "0.6357" },
  { geneTranscript: "NM_024635.4", parsimonySitesP: "0.088735632183908", constantSitesP: "0.846436781609195", distinctSitesP: "0.139770114942529", brLenTotal: "0.2972" },
  { geneTranscript: "NM_002061.4", parsimonySitesP: "0.127737226277372", constantSitesP: "0.77007299270073", distinctSitesP: "0.223844282238443", brLenTotal: "0.4146" },
  { geneTranscript: "NM_021215.4", parsimonySitesP: "0.118609406952965", constantSitesP: "0.805725971370143", distinctSitesP: "0.165644171779141", brLenTotal: "0.3443" },
  { geneTranscript: "NM_001397496.1", parsimonySitesP: "0.167587476979742", constantSitesP: "0.701657458563536", distinctSitesP: "0.263351749539595", brLenTotal: "0.5888" },
  { geneTranscript: "NM_032368.5", parsimonySitesP: "0.0859649122807018", constantSitesP: "0.836842105263158", distinctSitesP: "0.164912280701754", brLenTotal: "0.2408" },
  { geneTranscript: "NM_181861.2", parsimonySitesP: "0.173878205128205", constantSitesP: "0.706997863247863", distinctSitesP: "0.239850427350427", brLenTotal: "0.5434" },
  { geneTranscript: "NM_002419.4", parsimonySitesP: "0.19323101141283", constantSitesP: "0.694214876033058", distinctSitesP: "0.256198347107438", brLenTotal: "0.8440" },
  { geneTranscript: "NM_032376.4", parsimonySitesP: "0.143968871595331", constantSitesP: "0.754863813229572", distinctSitesP: "0.206225680933852", brLenTotal: "0.5058" },
  { geneTranscript: "NM_000482.4", parsimonySitesP: "0.313131313131313", constantSitesP: "0.514309764309764", distinctSitesP: "0.427609427609428", brLenTotal: "1.2768" },
  { geneTranscript: "NM_207115.2", parsimonySitesP: "0.122093023255814", constantSitesP: "0.790697674418605", distinctSitesP: "0.201550387596899", brLenTotal: "0.4050" },
  { geneTranscript: "NM_003513.3", parsimonySitesP: "0.325641025641026", constantSitesP: "0.625641025641026", distinctSitesP: "0.387179487179487", brLenTotal: "2.2072" },
  { geneTranscript: "NM_005566.4", parsimonySitesP: "0.203815261044177", constantSitesP: "0.642570281124498", distinctSitesP: "0.310240963855422", brLenTotal: "0.8629" },
  { geneTranscript: "NM_007212.4", parsimonySitesP: "0.157738095238095", constantSitesP: "0.679563492063492", distinctSitesP: "0.261904761904762", brLenTotal: "0.6070" },
  { geneTranscript: "NM_001010.3", parsimonySitesP: "0.175368139223561", constantSitesP: "0.617135207496653", distinctSitesP: "0.330655957161981", brLenTotal: "0.7383" },
  { geneTranscript: "NM_003813.4", parsimonySitesP: "0.433518005540166", constantSitesP: "0.391505078485688", distinctSitesP: "0.569252077562327", brLenTotal: "2.3789" },
  { geneTranscript: "NM_006189.1", parsimonySitesP: "0.243353783231084", constantSitesP: "0.658486707566462", distinctSitesP: "0.335378323108384", brLenTotal: "0.9536" },
  { geneTranscript: "NM_004467.4", parsimonySitesP: "0.229700854700855", constantSitesP: "0.629273504273504", distinctSitesP: "0.384615384615385", brLenTotal: "0.8557" },
  { geneTranscript: "NM_001317774.2", parsimonySitesP: "0.344339622641509", constantSitesP: "0.515723270440252", distinctSitesP: "0.473270440251572", brLenTotal: "1.1461" },
  { geneTranscript: "NM_001313998.2", parsimonySitesP: "0.105925925925926", constantSitesP: "0.806666666666667", distinctSitesP: "0.164444444444444", brLenTotal: "0.3429" },
  { geneTranscript: "NM_000530.8", parsimonySitesP: "0.129032258064516", constantSitesP: "0.787634408602151", distinctSitesP: "0.206989247311828", brLenTotal: "0.3843" },
  { geneTranscript: "NM_002676.3", parsimonySitesP: "0.178117048346056", constantSitesP: "0.746819338422392", distinctSitesP: "0.227735368956743", brLenTotal: "0.6477" },
  { geneTranscript: "NM_007192.4", parsimonySitesP: "0.120980579433301", constantSitesP: "0.80834129258198", distinctSitesP: "0.161095192613817", brLenTotal: "0.3565" },
  { geneTranscript: "NM_019589.3", parsimonySitesP: "0.115719167443305", constantSitesP: "0.776328052190121", distinctSitesP: "0.152687169928549", brLenTotal: "0.3882" },
  { geneTranscript: "NM_000037.4", parsimonySitesP: "0.228723404255319", constantSitesP: "0.674468085106383", distinctSitesP: "0.275531914893617", brLenTotal: "1.0314" },
  { geneTranscript: "NM_001945.3", parsimonySitesP: "0.174679487179487", constantSitesP: "0.695512820512821", distinctSitesP: "0.280448717948718", brLenTotal: "0.5888" },
  { geneTranscript: "NM_025137.4", parsimonySitesP: "0.226633920043662", constantSitesP: "0.622185837085551", distinctSitesP: "0.305498703779506", brLenTotal: "0.7127" },
  { geneTranscript: "NM_006562.5", parsimonySitesP: "0.129300118623962", constantSitesP: "0.766310794780546", distinctSitesP: "0.208778173190985", brLenTotal: "0.4428" },
  { geneTranscript: "NM_012481.5", parsimonySitesP: "0.165029469548134", constantSitesP: "0.718402095612312", distinctSitesP: "0.244924688932547", brLenTotal: "0.5674" },
  { geneTranscript: "NM_001029861.3", parsimonySitesP: "0.297101449275362", constantSitesP: "0.567632850241546", distinctSitesP: "0.420289855072464", brLenTotal: "1.1290" },
  { geneTranscript: "NM_024551.3", parsimonySitesP: "0.18307426597582", constantSitesP: "0.700345423143351", distinctSitesP: "0.267702936096718", brLenTotal: "0.6758" },
  { geneTranscript: "NM_006383.4", parsimonySitesP: "0.169340463458111", constantSitesP: "0.716577540106952", distinctSitesP: "0.28698752228164", brLenTotal: "0.7822" },
  { geneTranscript: "NM_002544.5", parsimonySitesP: "0.102272727272727", constantSitesP: "0.788636363636364", distinctSitesP: "0.173484848484848", brLenTotal: "0.2963" },
  { geneTranscript: "NM_173798.4", parsimonySitesP: "0.286069651741294", constantSitesP: "0.533167495854063", distinctSitesP: "0.396351575456053", brLenTotal: "1.0057" },
  { geneTranscript: "NM_198471.3", parsimonySitesP: "0.323995127892814", constantSitesP: "0.485180673974827", distinctSitesP: "0.453105968331303", brLenTotal: "1.3714" },
  { geneTranscript: "NM_001005464.3", parsimonySitesP: "0.284313725490196", constantSitesP: "0.696078431372549", distinctSitesP: "0.303921568627451", brLenTotal: "2.1418" },
  { geneTranscript: "NM_000048.4", parsimonySitesP: "0.238505747126437", constantSitesP: "0.611350574712644", distinctSitesP: "0.341954022988506", brLenTotal: "1.0177" },
  { geneTranscript: "NM_001122681.2", parsimonySitesP: "0.272133095662507", constantSitesP: "0.606654783125371", distinctSitesP: "0.351158645276292", brLenTotal: "1.0863" },
  { geneTranscript: "NM_001282933.2", parsimonySitesP: "0.185402029664325", constantSitesP: "0.684621389539422", distinctSitesP: "0.241217798594848", brLenTotal: "0.8214" },
  { geneTranscript: "NM_001146262.4", parsimonySitesP: "0.108594657375145", constantSitesP: "0.806039488966318", distinctSitesP: "0.160278745644599", brLenTotal: "0.3888" },
  { geneTranscript: "NM_001029891.3", parsimonySitesP: "0.467191601049869", constantSitesP: "0.26509186351706", distinctSitesP: "0.700787401574803", brLenTotal: "2.4224" },
  { geneTranscript: "NM_002166.5", parsimonySitesP: "0.104477611940299", constantSitesP: "0.805970149253731", distinctSitesP: "0.208955223880597", brLenTotal: "0.3405" },
  { geneTranscript: "NM_198994.3", parsimonySitesP: "0.259206798866856", constantSitesP: "0.612842304060434", distinctSitesP: "0.341359773371105", brLenTotal: "0.9067" },
  { geneTranscript: "NM_022772.4", parsimonySitesP: "0.271328671328671", constantSitesP: "0.612587412587413", distinctSitesP: "0.37016317016317", brLenTotal: "1.1109" },
  { geneTranscript: "NM_002966.3", parsimonySitesP: "0.116838487972509", constantSitesP: "0.690721649484536", distinctSitesP: "0.298969072164948", brLenTotal: "0.5594" },
  { geneTranscript: "NM_001904.4", parsimonySitesP: "0.124199743918054", constantSitesP: "0.802390098164746", distinctSitesP: "0.160478019632949", brLenTotal: "0.4035" },
  { geneTranscript: "NM_006559.3", parsimonySitesP: "0.132430398796087", constantSitesP: "0.710308502633559", distinctSitesP: "0.228743416102333", brLenTotal: "0.5009" },
  { geneTranscript: "NM_001114133.3", parsimonySitesP: "0.240191061071307", constantSitesP: "0.623336745138178", distinctSitesP: "0.301944728761515", brLenTotal: "0.7641" },
  { geneTranscript: "NM_006056.5", parsimonySitesP: "0.321596244131455", constantSitesP: "0.510954616588419", distinctSitesP: "0.442879499217527", brLenTotal: "1.3525" },
  { geneTranscript: "NM_001306087.2", parsimonySitesP: "0.18213058419244", constantSitesP: "0.734020618556701", distinctSitesP: "0.235051546391753", brLenTotal: "0.6297" },
  { geneTranscript: "NM_021083.4", parsimonySitesP: "0.217717717717718", constantSitesP: "0.679429429429429", distinctSitesP: "0.306306306306306", brLenTotal: "0.6832" },
  { geneTranscript: "NM_001130158.3", parsimonySitesP: "0.131455399061033", constantSitesP: "0.790199530516432", distinctSitesP: "0.186619718309859", brLenTotal: "0.5205" },
  { geneTranscript: "NM_024731.4", parsimonySitesP: "0.166125541125541", constantSitesP: "0.70995670995671", distinctSitesP: "0.235930735930736", brLenTotal: "0.7609" },
  { geneTranscript: "NM_001113226.3", parsimonySitesP: "0.0964749536178108", constantSitesP: "0.825602968460111", distinctSitesP: "0.156462585034014", brLenTotal: "0.3538" },
  { geneTranscript: "NM_003565.4", parsimonySitesP: "0.230793650793651", constantSitesP: "0.646666666666667", distinctSitesP: "0.30031746031746", brLenTotal: "1.1265" },
  { geneTranscript: "NM_002019.4", parsimonySitesP: "0.184105630293971", constantSitesP: "0.693323368211261", distinctSitesP: "0.272546088689586", brLenTotal: "0.6820" },
  { geneTranscript: "NM_001330301.2", parsimonySitesP: "0.169031851151056", constantSitesP: "0.731630400504573", distinctSitesP: "0.225796278776411", brLenTotal: "0.6267" },
  { geneTranscript: "NM_031958.2", parsimonySitesP: "0.363945578231293", constantSitesP: "0.45578231292517", distinctSitesP: "0.513605442176871", brLenTotal: "1.4177" },
  { geneTranscript: "NM_001886.3", parsimonySitesP: "0.195578231292517", constantSitesP: "0.726190476190476", distinctSitesP: "0.275510204081633", brLenTotal: "0.7565" },
  { geneTranscript: "NM_001005373.4", parsimonySitesP: "0.196864914707238", constantSitesP: "0.66804979253112", distinctSitesP: "0.285846011987091", brLenTotal: "0.7988" },
  { geneTranscript: "NM_006896.4", parsimonySitesP: "0.176811594202899", constantSitesP: "0.728985507246377", distinctSitesP: "0.27536231884058", brLenTotal: "1.0527" },
  { geneTranscript: "NM_001272071.2", parsimonySitesP: "0.154166666666667", constantSitesP: "0.75", distinctSitesP: "0.264583333333333", brLenTotal: "0.6659" },
  { geneTranscript: "NM_001271.4", parsimonySitesP: "0.116156090444931", constantSitesP: "0.794128373450036", distinctSitesP: "0.146243617797228", brLenTotal: "0.3744" },
  { geneTranscript: "NM_013323.3", parsimonySitesP: "0.176543209876543", constantSitesP: "0.687654320987654", distinctSitesP: "0.276543209876543", brLenTotal: "0.5590" },
  { geneTranscript: "NM_002803.4", parsimonySitesP: "0.114703618167821", constantSitesP: "0.75211701308699", distinctSitesP: "0.201693610469592", brLenTotal: "0.4395" },
  { geneTranscript: "NM_001128214.2", parsimonySitesP: "0.09985935302391", constantSitesP: "0.80450070323488", distinctSitesP: "0.175808720112518", brLenTotal: "0.3461" },
  { geneTranscript: "NM_053053.4", parsimonySitesP: "0.167164179104478", constantSitesP: "0.741293532338308", distinctSitesP: "0.241791044776119", brLenTotal: "0.6589" },
  { geneTranscript: "NM_021628.3", parsimonySitesP: "0.207219878105954", constantSitesP: "0.649789029535865", distinctSitesP: "0.285513361462729", brLenTotal: "0.7755" },
  { geneTranscript: "NM_002236.5", parsimonySitesP: "0.151821862348178", constantSitesP: "0.772604588394062", distinctSitesP: "0.195681511470985", brLenTotal: "0.6153" },
  { geneTranscript: "NM_003355.3", parsimonySitesP: "0.168284789644013", constantSitesP: "0.6957928802589", distinctSitesP: "0.268608414239482", brLenTotal: "0.6630" },
  { geneTranscript: "NM_003486.7", parsimonySitesP: "0.182117028270874", constantSitesP: "0.715318869165023", distinctSitesP: "0.276791584483892", brLenTotal: "0.8151" },
  { geneTranscript: "NM_004561.4", parsimonySitesP: "0.15980024968789", constantSitesP: "0.745318352059925", distinctSitesP: "0.237203495630462", brLenTotal: "0.6663" },
  { geneTranscript: "NM_002633.3", parsimonySitesP: "0.170818505338078", constantSitesP: "0.70699881376038", distinctSitesP: "0.247330960854093", brLenTotal: "0.6768" },
  { geneTranscript: "NM_032319.3", parsimonySitesP: "0.113475177304965", constantSitesP: "0.785460992907801", distinctSitesP: "0.198581560283688", brLenTotal: "0.4284" },
  { geneTranscript: "NM_006621.7", parsimonySitesP: "0.0924528301886792", constantSitesP: "0.851572327044025", distinctSitesP: "0.130817610062893", brLenTotal: "0.2829" },
  { geneTranscript: "NM_019109.5", parsimonySitesP: "0.295977011494253", constantSitesP: "0.558189655172414", distinctSitesP: "0.451867816091954", brLenTotal: "1.2524" },
  { geneTranscript: "NM_203281.3", parsimonySitesP: "0.159506172839506", constantSitesP: "0.733333333333333", distinctSitesP: "0.244938271604938", brLenTotal: "0.5104" },
  { geneTranscript: "NM_001100.4", parsimonySitesP: "0.168877099911583", constantSitesP: "0.755083996463307", distinctSitesP: "0.216622458001768", brLenTotal: "0.7709" },
  { geneTranscript: "NM_032951.3", parsimonySitesP: "0.238654147104851", constantSitesP: "0.621674491392801", distinctSitesP: "0.320813771517997", brLenTotal: "0.8989" },
  { geneTranscript: "NM_005202.4", parsimonySitesP: "0.176386913229018", constantSitesP: "0.702228544333807", distinctSitesP: "0.255097202465624", brLenTotal: "0.8223" },
  { geneTranscript: "NM_024025.3", parsimonySitesP: "0.172195892575039", constantSitesP: "0.720379146919431", distinctSitesP: "0.252764612954186", brLenTotal: "0.8509" },
  { geneTranscript: "NM_032364.6", parsimonySitesP: "0.200379867046534", constantSitesP: "0.586894586894587", distinctSitesP: "0.292972459639126", brLenTotal: "0.7337" },
  { geneTranscript: "NM_004226.4", parsimonySitesP: "0.147849462365591", constantSitesP: "0.728494623655914", distinctSitesP: "0.228494623655914", brLenTotal: "0.5867" },
  { geneTranscript: "NM_001355407.2", parsimonySitesP: "0.274401473296501", constantSitesP: "0.576427255985267", distinctSitesP: "0.412523020257827", brLenTotal: "1.2652" },
  { geneTranscript: "NM_001265.6", parsimonySitesP: "0.185303514376997", constantSitesP: "0.718849840255591", distinctSitesP: "0.272630457933972", brLenTotal: "0.8090" },
  { geneTranscript: "NM_003872.3", parsimonySitesP: "0.177825773938085", constantSitesP: "0.692224622030238", distinctSitesP: "0.242980561555076", brLenTotal: "0.8668" },
  { geneTranscript: "NM_001351601.3", parsimonySitesP: "0.113956941351151", constantSitesP: "0.775055679287305", distinctSitesP: "0.201187824795843", brLenTotal: "0.3755" },
  { geneTranscript: "NM_012245.3", parsimonySitesP: "0.121268656716418", constantSitesP: "0.716417910447761", distinctSitesP: "0.240049751243781", brLenTotal: "0.4840" },
  { geneTranscript: "NM_002937.5", parsimonySitesP: "0.258503401360544", constantSitesP: "0.578231292517007", distinctSitesP: "0.401360544217687", brLenTotal: "0.9035" },
  { geneTranscript: "NM_016026.4", parsimonySitesP: "0.216981132075472", constantSitesP: "0.647798742138365", distinctSitesP: "0.324947589098532", brLenTotal: "0.7195" },
  { geneTranscript: "NM_001098522.2", parsimonySitesP: "0.230027548209366", constantSitesP: "0.619834710743802", distinctSitesP: "0.402203856749311", brLenTotal: "0.8354" },
  { geneTranscript: "NM_001353214.3", parsimonySitesP: "0.144567219152855", constantSitesP: "0.740331491712707", distinctSitesP: "0.219613259668508", brLenTotal: "0.5536" },
  { geneTranscript: "NM_001369741.1", parsimonySitesP: "0.264855687606112", constantSitesP: "0.620826259196378", distinctSitesP: "0.341822297679683", brLenTotal: "1.4475" },
  { geneTranscript: "NM_006877.4", parsimonySitesP: "0.190338164251208", constantSitesP: "0.67536231884058", distinctSitesP: "0.285024154589372", brLenTotal: "0.8956" },
  { geneTranscript: "NM_019005.4", parsimonySitesP: "0.100952380952381", constantSitesP: "0.809904761904762", distinctSitesP: "0.147428571428571", brLenTotal: "0.3752" },
  { geneTranscript: "NM_003762.5", parsimonySitesP: "0.0732860520094563", constantSitesP: "0.846335697399527", distinctSitesP: "0.177304964539007", brLenTotal: "0.2375" },
  { geneTranscript: "NM_012123.4", parsimonySitesP: "0.209537572254335", constantSitesP: "0.63728323699422", distinctSitesP: "0.332369942196532", brLenTotal: "0.7846" },
  { geneTranscript: "NM_001105520.2", parsimonySitesP: "0.553672316384181", constantSitesP: "0.189265536723164", distinctSitesP: "0.850282485875706", brLenTotal: "2.3994" },
  { geneTranscript: "NM_080662.4", parsimonySitesP: "0.289073305670816", constantSitesP: "0.575380359612725", distinctSitesP: "0.428769017980636", brLenTotal: "1.2690" },
  { geneTranscript: "NM_001300862.2", parsimonySitesP: "0.250831669993347", constantSitesP: "0.620093147039255", distinctSitesP: "0.375249500998004", brLenTotal: "1.2193" },
  { geneTranscript: "NM_016279.4", parsimonySitesP: "0.23574144486692", constantSitesP: "0.661174482467258", distinctSitesP: "0.289818335445712", brLenTotal: "0.8827" },
  { geneTranscript: "NM_030780.5", parsimonySitesP: "0.165079365079365", constantSitesP: "0.729100529100529", distinctSitesP: "0.245502645502646", brLenTotal: "0.5377" },
  { geneTranscript: "NM_018425.4", parsimonySitesP: "0.144050104384134", constantSitesP: "0.747390396659708", distinctSitesP: "0.208072372999304", brLenTotal: "0.5016" },
  { geneTranscript: "NM_002805.6", parsimonySitesP: "0.158456486042693", constantSitesP: "0.747947454844007", distinctSitesP: "0.225779967159278", brLenTotal: "0.6596" },
  { geneTranscript: "NM_001136023.3", parsimonySitesP: "0.193029490616622", constantSitesP: "0.661304736371761", distinctSitesP: "0.280607685433423", brLenTotal: "0.6553" },
  { geneTranscript: "NM_003806.4", parsimonySitesP: "0.0915750915750916", constantSitesP: "0.714285714285714", distinctSitesP: "0.274725274725275", brLenTotal: "0.5622" },
  { geneTranscript: "NM_138501.6", parsimonySitesP: "0.151515151515152", constantSitesP: "0.730519480519481", distinctSitesP: "0.244588744588745", brLenTotal: "0.6267" },
  { geneTranscript: "NM_005763.4", parsimonySitesP: "0.198344132469402", constantSitesP: "0.680705543556515", distinctSitesP: "0.304175665946724", brLenTotal: "0.6805" },
  { geneTranscript: "NM_005507.3", parsimonySitesP: "0.214859437751004", constantSitesP: "0.606425702811245", distinctSitesP: "0.377510040160643", brLenTotal: "0.9583" },
  { geneTranscript: "NM_176818.3", parsimonySitesP: "0.272058823529412", constantSitesP: "0.541666666666667", distinctSitesP: "0.475490196078431", brLenTotal: "1.0056" },
  { geneTranscript: "NM_013296.5", parsimonySitesP: "0.1364522417154", constantSitesP: "0.727095516569201", distinctSitesP: "0.240740740740741", brLenTotal: "0.5189" },
  { geneTranscript: "NM_000070.3", parsimonySitesP: "0.163621599675193", constantSitesP: "0.738530247665449", distinctSitesP: "0.226958993097848", brLenTotal: "0.5889" },
  { geneTranscript: "NM_018237.4", parsimonySitesP: "0.128985507246377", constantSitesP: "0.77768115942029", distinctSitesP: "0.17768115942029", brLenTotal: "0.4183" },
  { geneTranscript: "NM_022788.5", parsimonySitesP: "0.210526315789474", constantSitesP: "0.660818713450292", distinctSitesP: "0.314814814814815", brLenTotal: "0.7703" },
  { geneTranscript: "NM_001281453.2", parsimonySitesP: "0.179839633447881", constantSitesP: "0.733104238258877", distinctSitesP: "0.235967926689576", brLenTotal: "1.0303" },
  { geneTranscript: "NM_000914.5", parsimonySitesP: "0.191666666666667", constantSitesP: "0.719166666666667", distinctSitesP: "0.265", brLenTotal: "0.6873" },
  { geneTranscript: "NM_000262.3", parsimonySitesP: "0.246553122465531", constantSitesP: "0.647201946472019", distinctSitesP: "0.32441200324412", brLenTotal: "0.9140" },
  { geneTranscript: "NM_002971.6", parsimonySitesP: "0.117518567059851", constantSitesP: "0.785058977719528", distinctSitesP: "0.169069462647444", brLenTotal: "0.5061" },
  { geneTranscript: "NM_017775.4", parsimonySitesP: "0.197368421052632", constantSitesP: "0.664912280701754", distinctSitesP: "0.321052631578947", brLenTotal: "0.6591" },
  { geneTranscript: "NM_001242481.2", parsimonySitesP: "0.181818181818182", constantSitesP: "0.715151515151515", distinctSitesP: "0.296969696969697", brLenTotal: "0.5592" },
  { geneTranscript: "NM_012319.4", parsimonySitesP: "0.16953642384106", constantSitesP: "0.70242825607064", distinctSitesP: "0.265342163355408", brLenTotal: "0.6442" },
  { geneTranscript: "NM_022746.4", parsimonySitesP: "0.298714144411474", constantSitesP: "0.5351137487636", distinctSitesP: "0.452027695351137", brLenTotal: "1.2234" },
  { geneTranscript: "NM_001024593.2", parsimonySitesP: "0.215217391304348", constantSitesP: "0.623188405797101", distinctSitesP: "0.305072463768116", brLenTotal: "0.6547" },
  { geneTranscript: "NM_002851.3", parsimonySitesP: "0.190784737221022", constantSitesP: "0.661915046796256", distinctSitesP: "0.272138228941685", brLenTotal: "0.6916" },
  { geneTranscript: "NM_017954.11", parsimonySitesP: "0.151234567901235", constantSitesP: "0.757201646090535", distinctSitesP: "0.194444444444444", brLenTotal: "0.6049" },
  { geneTranscript: "NM_004852.3", parsimonySitesP: "0.12037037037037", constantSitesP: "0.761904761904762", distinctSitesP: "0.220899470899471", brLenTotal: "0.5698" },
  { geneTranscript: "NM_001105569.3", parsimonySitesP: "0.208981001727116", constantSitesP: "0.614853195164076", distinctSitesP: "0.343696027633851", brLenTotal: "0.8165" },
  { geneTranscript: "NM_001034173.4", parsimonySitesP: "0.162874684001445", constantSitesP: "0.704947634525099", distinctSitesP: "0.241242325749368", brLenTotal: "0.6327" },
  { geneTranscript: "NM_018208.4", parsimonySitesP: "0.155440414507772", constantSitesP: "0.728842832469775", distinctSitesP: "0.236614853195164", brLenTotal: "0.5344" },
  { geneTranscript: "NM_020311.3", parsimonySitesP: "0.177716390423573", constantSitesP: "0.719152854511971", distinctSitesP: "0.24585635359116", brLenTotal: "0.9027" },
  { geneTranscript: "NM_002405.4", parsimonySitesP: "0.252336448598131", constantSitesP: "0.609553478712357", distinctSitesP: "0.371754932502596", brLenTotal: "1.0532" },
  { geneTranscript: "NM_006030.4", parsimonySitesP: "0.141732283464567", constantSitesP: "0.762029746281715", distinctSitesP: "0.200641586468358", brLenTotal: "0.5412" },
  { geneTranscript: "NM_020158.4", parsimonySitesP: "0.239716312056738", constantSitesP: "0.646808510638298", distinctSitesP: "0.339007092198582", brLenTotal: "1.0021" },
  { geneTranscript: "NM_001129891.3", parsimonySitesP: "0.229906542056075", constantSitesP: "0.610591900311526", distinctSitesP: "0.321495327102804", brLenTotal: "0.7731" },
  { geneTranscript: "NM_005949.4", parsimonySitesP: "0.306010928961749", constantSitesP: "0.540983606557377", distinctSitesP: "0.491803278688525", brLenTotal: "1.9149" },
  { geneTranscript: "NM_001002862.3", parsimonySitesP: "0.204255319148936", constantSitesP: "0.678014184397163", distinctSitesP: "0.300709219858156", brLenTotal: "0.7758" },
  { geneTranscript: "NM_000306.4", parsimonySitesP: "0.166093928980527", constantSitesP: "0.7262313860252", distinctSitesP: "0.248568155784651", brLenTotal: "0.5549" },
  { geneTranscript: "NM_003543.4", parsimonySitesP: "0.320388349514563", constantSitesP: "0.656957928802589", distinctSitesP: "0.355987055016181", brLenTotal: "3.2003" },
  { geneTranscript: "NM_001300759.2", parsimonySitesP: "0.17877094972067", constantSitesP: "0.727653631284916", distinctSitesP: "0.243016759776536", brLenTotal: "0.6096" },
  { geneTranscript: "NM_006791.4", parsimonySitesP: "0.144478844169247", constantSitesP: "0.723426212590299", distinctSitesP: "0.260061919504644", brLenTotal: "0.6060" },
  { geneTranscript: "NM_002207.3", parsimonySitesP: "0.193236714975845", constantSitesP: "0.693075684380032", distinctSitesP: "0.253140096618357", brLenTotal: "0.7502" },
  { geneTranscript: "NM_018115.4", parsimonySitesP: "0.169820475497331", constantSitesP: "0.6836487142164", distinctSitesP: "0.301310043668122", brLenTotal: "0.6540" },
  { geneTranscript: "NM_020761.3", parsimonySitesP: "0.197253433208489", constantSitesP: "0.736079900124844", distinctSitesP: "0.24294631710362", brLenTotal: "1.0165" },
  { geneTranscript: "NM_005604.4", parsimonySitesP: "0.0782543265613243", constantSitesP: "0.860045146726862", distinctSitesP: "0.135440180586907", brLenTotal: "0.3048" },
  { geneTranscript: "NM_001005337.3", parsimonySitesP: "0.188246097337006", constantSitesP: "0.715794306703398", distinctSitesP: "0.244260789715335", brLenTotal: "0.8026" },
  { geneTranscript: "NM_001384237.2", parsimonySitesP: "0.235011990407674", constantSitesP: "0.575539568345324", distinctSitesP: "0.45083932853717", brLenTotal: "0.7420" },
  { geneTranscript: "NM_001325.3", parsimonySitesP: "0.129404968226459", constantSitesP: "0.758521086077412", distinctSitesP: "0.198729058347776", brLenTotal: "0.4570" },
  { geneTranscript: "NM_004461.3", parsimonySitesP: "0.21259842519685", constantSitesP: "0.681102362204724", distinctSitesP: "0.267060367454068", brLenTotal: "0.8985" },
  { geneTranscript: "NM_001326411.2", parsimonySitesP: "0.209453952730236", constantSitesP: "0.678076609616952", distinctSitesP: "0.291768541157294", brLenTotal: "0.7593" },
  { geneTranscript: "NM_178525.5", parsimonySitesP: "0.338942307692308", constantSitesP: "0.512019230769231", distinctSitesP: "0.452724358974359", brLenTotal: "1.5154" },
  { geneTranscript: "NM_020911.2", parsimonySitesP: "0.180218233016543", constantSitesP: "0.717177050334389", distinctSitesP: "0.20960929250264", brLenTotal: "0.9025" },
  { geneTranscript: "NM_001713.3", parsimonySitesP: "0.210180623973727", constantSitesP: "0.65927750410509", distinctSitesP: "0.298029556650246", brLenTotal: "0.9263" },
  { geneTranscript: "NM_001164579.2", parsimonySitesP: "0.0518962075848303", constantSitesP: "0.86626746506986", distinctSitesP: "0.12375249500998", brLenTotal: "0.1829" },
  { geneTranscript: "NM_003564.3", parsimonySitesP: "0.14572864321608", constantSitesP: "0.725293132328308", distinctSitesP: "0.261306532663317", brLenTotal: "0.6168" },
  { geneTranscript: "NM_001080397.3", parsimonySitesP: "0.238413547237077", constantSitesP: "0.659090909090909", distinctSitesP: "0.296791443850267", brLenTotal: "1.1397" },
  { geneTranscript: "NM_003345.5", parsimonySitesP: "0.160337552742616", constantSitesP: "0.763713080168776", distinctSitesP: "0.238396624472574", brLenTotal: "0.7127" },
  { geneTranscript: "NM_005337.5", parsimonySitesP: "0.166518781425614", constantSitesP: "0.719018041999408", distinctSitesP: "0.217687074829932", brLenTotal: "0.5508" },
  { geneTranscript: "NM_001360016.2", parsimonySitesP: "0.196763754045307", constantSitesP: "0.709385113268608", distinctSitesP: "0.249190938511327", brLenTotal: "0.8254" },
  { geneTranscript: "NM_006845.4", parsimonySitesP: "0.192183908045977", constantSitesP: "0.664827586206897", distinctSitesP: "0.258390804597701", brLenTotal: "0.6444" },
  { geneTranscript: "NM_022061.4", parsimonySitesP: "0.23047619047619", constantSitesP: "0.607619047619048", distinctSitesP: "0.401904761904762", brLenTotal: "0.7255" },
  { geneTranscript: "NM_002215.4", parsimonySitesP: "0.242956458104647", constantSitesP: "0.614709110867179", distinctSitesP: "0.320892791803879", brLenTotal: "0.9328" },
  { geneTranscript: "NM_004741.5", parsimonySitesP: "0.27658559847401", constantSitesP: "0.533619456366237", distinctSitesP: "0.436814496900334", brLenTotal: "1.0626" },
  { geneTranscript: "NM_015966.3", parsimonySitesP: "0.120104438642298", constantSitesP: "0.796344647519582", distinctSitesP: "0.184508268059182", brLenTotal: "0.4797" },
  { geneTranscript: "NM_002214.3", parsimonySitesP: "0.194191590810577", constantSitesP: "0.664932813177287", distinctSitesP: "0.271348071087993", brLenTotal: "0.6372" },
  { geneTranscript: "NM_000889.3", parsimonySitesP: "0.223057644110276", constantSitesP: "0.642857142857143", distinctSitesP: "0.280701754385965", brLenTotal: "0.8333" },
  { geneTranscript: "NM_139072.4", parsimonySitesP: "0.234283129805518", constantSitesP: "0.644957033016735", distinctSitesP: "0.323383084577114", brLenTotal: "0.9560" },
  { geneTranscript: "NM_198123.2", parsimonySitesP: "0.128046039025268", constantSitesP: "0.775110151964751", distinctSitesP: "0.147828432694902", brLenTotal: "0.4374" },
  { geneTranscript: "NM_194312.4", parsimonySitesP: "0.314759535655058", constantSitesP: "0.537313432835821", distinctSitesP: "0.405638474295191", brLenTotal: "1.7009" },
  { geneTranscript: "NM_001329686.2", parsimonySitesP: "0.12280701754386", constantSitesP: "0.754385964912281", distinctSitesP: "0.24780701754386", brLenTotal: "0.4369" },
  { geneTranscript: "NM_004061.5", parsimonySitesP: "0.248950461796809", constantSitesP: "0.666666666666667", distinctSitesP: "0.307304785894207", brLenTotal: "1.3594" },
  { geneTranscript: "NM_181575.5", parsimonySitesP: "0.161788617886179", constantSitesP: "0.708943089430894", distinctSitesP: "0.23739837398374", brLenTotal: "0.5226" },
  { geneTranscript: "NM_001785.3", parsimonySitesP: "0.28310502283105", constantSitesP: "0.552511415525114", distinctSitesP: "0.449771689497717", brLenTotal: "1.1668" },
  { geneTranscript: "NM_184085.2", parsimonySitesP: "0.190389294403893", constantSitesP: "0.670316301703163", distinctSitesP: "0.321776155717762", brLenTotal: "0.6966" },
  { geneTranscript: "NM_014405.4", parsimonySitesP: "0.139653414882773", constantSitesP: "0.791029561671764", distinctSitesP: "0.17737003058104", brLenTotal: "0.6728" },
  { geneTranscript: "NM_015271.5", parsimonySitesP: "0.154345006485084", constantSitesP: "0.769995676610463", distinctSitesP: "0.198875918720277", brLenTotal: "0.6372" },
  { geneTranscript: "NM_001366306.2", parsimonySitesP: "0.124922696351268", constantSitesP: "0.78726035868893", distinctSitesP: "0.191094619666048", brLenTotal: "0.4488" },
  { geneTranscript: "NM_080651.4", parsimonySitesP: "0.147940074906367", constantSitesP: "0.720973782771536", distinctSitesP: "0.260299625468165", brLenTotal: "0.5892" },
  { geneTranscript: "NM_016602.3", parsimonySitesP: "0.213627992633517", constantSitesP: "0.652854511970534", distinctSitesP: "0.301104972375691", brLenTotal: "0.8051" },
  { geneTranscript: "NM_001385001.1", parsimonySitesP: "0.19665907365224", constantSitesP: "0.678815489749431", distinctSitesP: "0.300683371298405", brLenTotal: "0.7417" },
  { geneTranscript: "NM_001198961.2", parsimonySitesP: "0.228310502283105", constantSitesP: "0.63013698630137", distinctSitesP: "0.360730593607306", brLenTotal: "0.9531" },
  { geneTranscript: "NM_022089.4", parsimonySitesP: "0.221751412429379", constantSitesP: "0.658474576271186", distinctSitesP: "0.267796610169492", brLenTotal: "0.9431" },
  { geneTranscript: "NM_000189.5", parsimonySitesP: "0.19629225736096", constantSitesP: "0.697564521992003", distinctSitesP: "0.243547800799709", brLenTotal: "0.8235" },
  { geneTranscript: "NM_152531.5", parsimonySitesP: "0.196776929601357", constantSitesP: "0.689567430025445", distinctSitesP: "0.273112807463953", brLenTotal: "0.8655" },
  { geneTranscript: "NM_001039651.2", parsimonySitesP: "0.265917602996255", constantSitesP: "0.554307116104869", distinctSitesP: "0.443820224719101", brLenTotal: "0.8291" },
  { geneTranscript: "NM_175055.3", parsimonySitesP: "0.198412698412698", constantSitesP: "0.698412698412698", distinctSitesP: "0.293650793650794", brLenTotal: "1.9813" },
  { geneTranscript: "NM_021187.4", parsimonySitesP: "0.391221374045802", constantSitesP: "0.435750636132316", distinctSitesP: "0.527989821882952", brLenTotal: "2.6669" },
  { geneTranscript: "NM_015346.4", parsimonySitesP: "0.23198109491926", constantSitesP: "0.60968885387948", distinctSitesP: "0.254168307732703", brLenTotal: "0.8072" },
  { geneTranscript: "NM_203447.4", parsimonySitesP: "0.185008734317929", constantSitesP: "0.70255677306654", distinctSitesP: "0.225504208353184", brLenTotal: "0.6603" },
  { geneTranscript: "NM_004542.4", parsimonySitesP: "0.285714285714286", constantSitesP: "0.587301587301587", distinctSitesP: "0.452380952380952", brLenTotal: "1.2088" },
  { geneTranscript: "NM_001130082.3", parsimonySitesP: "0.210304449648712", constantSitesP: "0.642779078844653", distinctSitesP: "0.245589383294301", brLenTotal: "0.9070" },
  { geneTranscript: "NM_004569.5", parsimonySitesP: "0.186170212765957", constantSitesP: "0.640070921985816", distinctSitesP: "0.328014184397163", brLenTotal: "0.5948" },
  { geneTranscript: "NM_053013.4", parsimonySitesP: "0.221966205837174", constantSitesP: "0.685099846390169", distinctSitesP: "0.282642089093702", brLenTotal: "0.9440" },
  { geneTranscript: "NM_032575.3", parsimonySitesP: "0.197837150127226", constantSitesP: "0.705470737913486", distinctSitesP: "0.248091603053435", brLenTotal: "0.9641" },
  { geneTranscript: "NM_020346.3", parsimonySitesP: "0.126575028636884", constantSitesP: "0.792668957617411", distinctSitesP: "0.177548682703322", brLenTotal: "0.4235" },
  { geneTranscript: "NM_012096.3", parsimonySitesP: "0.100141043723554", constantSitesP: "0.809590973201693", distinctSitesP: "0.181006111894687", brLenTotal: "0.3443" },
  { geneTranscript: "NM_001127217.3", parsimonySitesP: "0.239114917915774", constantSitesP: "0.668807994289793", distinctSitesP: "0.295503211991435", brLenTotal: "1.0221" },
  { geneTranscript: "NM_005826.5", parsimonySitesP: "0.15376513954713", constantSitesP: "0.69826224328594", distinctSitesP: "0.24855186940495", brLenTotal: "0.6617" },
  { geneTranscript: "NM_000293.3", parsimonySitesP: "0.1741384568466", constantSitesP: "0.723086306800854", distinctSitesP: "0.241537053979872", brLenTotal: "0.5937" },
  { geneTranscript: "NM_173470.3", parsimonySitesP: "0.16030534351145", constantSitesP: "0.735368956743003", distinctSitesP: "0.246819338422392", brLenTotal: "0.4955" },
  { geneTranscript: "NM_005184.4", parsimonySitesP: "0.154362416107383", constantSitesP: "0.758389261744966", distinctSitesP: "0.228187919463087", brLenTotal: "0.5809" },
  { geneTranscript: "NM_001130012.3", parsimonySitesP: "0.231454005934718", constantSitesP: "0.663699307616222", distinctSitesP: "0.304648862512364", brLenTotal: "1.0478" },
  { geneTranscript: "NM_001298.3", parsimonySitesP: "0.296829971181556", constantSitesP: "0.543227665706052", distinctSitesP: "0.412584053794428", brLenTotal: "1.2845" },
  { geneTranscript: "NM_024816.3", parsimonySitesP: "0.236086701816052", constantSitesP: "0.625659050966608", distinctSitesP: "0.319273579379028", brLenTotal: "0.9074" },
  { geneTranscript: "NM_014117.3", parsimonySitesP: "0.0593939393939394", constantSitesP: "0.863030303030303", distinctSitesP: "0.127272727272727", brLenTotal: "0.2078" },
  { geneTranscript: "NM_005099.6", parsimonySitesP: "0.187972919155715", constantSitesP: "0.684587813620072", distinctSitesP: "0.248108323377141", brLenTotal: "0.7439" },
  { geneTranscript: "NM_001382683.1", parsimonySitesP: "0.0861040068201194", constantSitesP: "0.867007672634271", distinctSitesP: "0.119352088661552", brLenTotal: "0.3084" },
  { geneTranscript: "NM_152868.3", parsimonySitesP: "0.137827715355805", constantSitesP: "0.791011235955056", distinctSitesP: "0.188014981273408", brLenTotal: "0.8327" },
  { geneTranscript: "NM_017882.3", parsimonySitesP: "0.188638799571275", constantSitesP: "0.67524115755627", distinctSitesP: "0.304394426580922", brLenTotal: "0.9567" },
  { geneTranscript: "NM_001141969.2", parsimonySitesP: "0.233333333333333", constantSitesP: "0.631081081081081", distinctSitesP: "0.319369369369369", brLenTotal: "0.7408" },
  { geneTranscript: "NM_001012755.5", parsimonySitesP: "0.185667752442997", constantSitesP: "0.685124864277959", distinctSitesP: "0.285559174809989", brLenTotal: "0.6047" },
  { geneTranscript: "NM_015533.4", parsimonySitesP: "0.213913043478261", constantSitesP: "0.653333333333333", distinctSitesP: "0.290434782608696", brLenTotal: "1.0185" },
  { geneTranscript: "NM_007182.5", parsimonySitesP: "0.192156862745098", constantSitesP: "0.677450980392157", distinctSitesP: "0.276470588235294", brLenTotal: "0.7036" },
  { geneTranscript: "NM_001010846.3", parsimonySitesP: "0.204713804713805", constantSitesP: "0.667340067340067", distinctSitesP: "0.293602693602694", brLenTotal: "0.7109" },
  { geneTranscript: "NM_022051.3", parsimonySitesP: "0.151799687010955", constantSitesP: "0.690140845070423", distinctSitesP: "0.315336463223787", brLenTotal: "0.6190" },
  { geneTranscript: "NM_007352.4", parsimonySitesP: "0.334567901234568", constantSitesP: "0.51358024691358", distinctSitesP: "0.477777777777778", brLenTotal: "1.6558" },
  { geneTranscript: "NM_001258406.2", parsimonySitesP: "0.274428274428274", constantSitesP: "0.550935550935551", distinctSitesP: "0.422037422037422", brLenTotal: "0.9902" },
  { geneTranscript: "NM_199044.4", parsimonySitesP: "0.213541666666667", constantSitesP: "0.640625", distinctSitesP: "0.311631944444444", brLenTotal: "0.6367" },
  { geneTranscript: "NM_014793.5", parsimonySitesP: "0.270165208940719", constantSitesP: "0.560738581146744", distinctSitesP: "0.36491739552964", brLenTotal: "0.9551" },
  { geneTranscript: "NM_022740.5", parsimonySitesP: "0.132721202003339", constantSitesP: "0.789092932665554", distinctSitesP: "0.167501391207568", brLenTotal: "0.5487" },
  { geneTranscript: "NM_018981.4", parsimonySitesP: "0.129886506935687", constantSitesP: "0.75031525851198", distinctSitesP: "0.229928541403951", brLenTotal: "0.4274" },
  { geneTranscript: "NM_017634.4", parsimonySitesP: "0.146529562982005", constantSitesP: "0.75235646958012", distinctSitesP: "0.230505569837189", brLenTotal: "0.4693" },
  { geneTranscript: "NM_001270485.2", parsimonySitesP: "0.193310657596372", constantSitesP: "0.688775510204082", distinctSitesP: "0.268707482993197", brLenTotal: "0.8748" },
  { geneTranscript: "NM_014302.4", parsimonySitesP: "0.0784313725490196", constantSitesP: "0.843137254901961", distinctSitesP: "0.181372549019608", brLenTotal: "0.2486" },
  { geneTranscript: "NM_018228.3", parsimonySitesP: "0.299620132953466", constantSitesP: "0.537986704653371", distinctSitesP: "0.38034188034188", brLenTotal: "1.1432" },
  { geneTranscript: "NM_000365.6", parsimonySitesP: "0.179384203480589", constantSitesP: "0.698795180722892", distinctSitesP: "0.27710843373494", brLenTotal: "0.8216" },
  { geneTranscript: "NM_004281.4", parsimonySitesP: "0.206376811594203", constantSitesP: "0.623768115942029", distinctSitesP: "0.314782608695652", brLenTotal: "0.8189" },
  { geneTranscript: "NM_005027.4", parsimonySitesP: "0.239468864468864", constantSitesP: "0.643772893772894", distinctSitesP: "0.302197802197802", brLenTotal: "1.1543" },
  { geneTranscript: "NM_012264.5", parsimonySitesP: "0.161343161343161", constantSitesP: "0.761670761670762", distinctSitesP: "0.21048321048321", brLenTotal: "0.7962" },
  { geneTranscript: "NM_001010942.3", parsimonySitesP: "0.135869565217391", constantSitesP: "0.746376811594203", distinctSitesP: "0.239130434782609", brLenTotal: "0.4889" },
  { geneTranscript: "NM_031896.5", parsimonySitesP: "0.138181818181818", constantSitesP: "0.787878787878788", distinctSitesP: "0.186666666666667", brLenTotal: "0.4998" },
  { geneTranscript: "NM_198285.3", parsimonySitesP: "0.213652482269504", constantSitesP: "0.664007092198582", distinctSitesP: "0.304964539007092", brLenTotal: "1.0385" },
  { geneTranscript: "NM_001306135.2", parsimonySitesP: "0.277526395173454", constantSitesP: "0.526395173453997", distinctSitesP: "0.423831070889894", brLenTotal: "1.0129" },
  { geneTranscript: "NM_018988.4", parsimonySitesP: "0.109401709401709", constantSitesP: "0.793162393162393", distinctSitesP: "0.179487179487179", brLenTotal: "0.4354" },
  { geneTranscript: "NM_001258277.2", parsimonySitesP: "0.194161575016972", constantSitesP: "0.678207739307536", distinctSitesP: "0.273591310251188", brLenTotal: "0.7537" },
  { geneTranscript: "NM_014815.4", parsimonySitesP: "0.173238961914392", constantSitesP: "0.72531176272329", distinctSitesP: "0.23154701718908", brLenTotal: "0.7308" },
  { geneTranscript: "NM_144702.3", parsimonySitesP: "0.280858676207513", constantSitesP: "0.592725104353011", distinctSitesP: "0.378652355396541", brLenTotal: "0.9552" },
  { geneTranscript: "NM_025150.5", parsimonySitesP: "0.225626740947075", constantSitesP: "0.627205199628598", distinctSitesP: "0.306406685236769", brLenTotal: "0.7380" },
  { geneTranscript: "NM_203422.4", parsimonySitesP: "0.34453781512605", constantSitesP: "0.485994397759104", distinctSitesP: "0.473389355742297", brLenTotal: "1.3028" },
  { geneTranscript: "NM_032737.4", parsimonySitesP: "0.218279569892473", constantSitesP: "0.677956989247312", distinctSitesP: "0.283333333333333", brLenTotal: "1.0633" },
  { geneTranscript: "NM_006948.5", parsimonySitesP: "0.176220806794055", constantSitesP: "0.692144373673036", distinctSitesP: "0.26963906581741", brLenTotal: "0.6189" },
  { geneTranscript: "NM_031891.4", parsimonySitesP: "0.182688306283812", constantSitesP: "0.705784436121515", distinctSitesP: "0.237619642114024", brLenTotal: "0.7105" },
  { geneTranscript: "NM_001048201.3", parsimonySitesP: "0.273644388398487", constantSitesP: "0.630096679277007", distinctSitesP: "0.353509878100042", brLenTotal: "1.2990" },
  { geneTranscript: "NM_018053.4", parsimonySitesP: "0.260759493670886", constantSitesP: "0.589029535864979", distinctSitesP: "0.372151898734177", brLenTotal: "1.0580" },
  { geneTranscript: "NM_177939.3", parsimonySitesP: "0.185258964143426", constantSitesP: "0.689907038512616", distinctSitesP: "0.249003984063745", brLenTotal: "0.6700" },
  { geneTranscript: "NM_004933.3", parsimonySitesP: "0.313267813267813", constantSitesP: "0.546273546273546", distinctSitesP: "0.415233415233415", brLenTotal: "1.5226" },
  { geneTranscript: "NM_016353.5", parsimonySitesP: "0.137148047229791", constantSitesP: "0.756584922797457", distinctSitesP: "0.221616712079927", brLenTotal: "0.4712" },
  { geneTranscript: "NM_030930.4", parsimonySitesP: "0.204355108877722", constantSitesP: "0.675600223338917", distinctSitesP: "0.278615298715801", brLenTotal: "0.8273" },
  { geneTranscript: "NM_178496.4", parsimonySitesP: "0.132382892057026", constantSitesP: "0.790902919212492", distinctSitesP: "0.177868295994569", brLenTotal: "0.4961" },
  { geneTranscript: "NM_021943.3", parsimonySitesP: "0.0778267254038179", constantSitesP: "0.857562408223201", distinctSitesP: "0.140969162995595", brLenTotal: "0.2850" },
  { geneTranscript: "NM_203412.2", parsimonySitesP: "0.386973180076628", constantSitesP: "0.415708812260536", distinctSitesP: "0.574712643678161", brLenTotal: "1.5805" },
  { geneTranscript: "NM_032840.3", parsimonySitesP: "0.128205128205128", constantSitesP: "0.779034690799397", distinctSitesP: "0.184766214177979", brLenTotal: "0.4884" },
  { geneTranscript: "NM_015267.4", parsimonySitesP: "0.213324360699865", constantSitesP: "0.675190668461193", distinctSitesP: "0.259982054733064", brLenTotal: "1.0132" },
  { geneTranscript: "NM_005999.3", parsimonySitesP: "0.140229885057471", constantSitesP: "0.754022988505747", distinctSitesP: "0.227586206896552", brLenTotal: "0.4725" },
  { geneTranscript: "NM_003859.3", parsimonySitesP: "0.157692307692308", constantSitesP: "0.724358974358974", distinctSitesP: "0.279487179487179", brLenTotal: "0.5704" },
  { geneTranscript: "NM_003700.1", parsimonySitesP: "0.451298701298701", constantSitesP: "0.398268398268398", distinctSitesP: "0.558441558441558", brLenTotal: "1.4510" },
  { geneTranscript: "NM_001387025.1", parsimonySitesP: "0.140250855188141", constantSitesP: "0.764728240212847", distinctSitesP: "0.1923223109084", brLenTotal: "0.5976" },
  { geneTranscript: "NM_025146.4", parsimonySitesP: "0.0848126232741617", constantSitesP: "0.773175542406312", distinctSitesP: "0.214990138067061", brLenTotal: "0.3481" },
  { geneTranscript: "NM_080552.3", parsimonySitesP: "0.133333333333333", constantSitesP: "0.780952380952381", distinctSitesP: "0.181587301587302", brLenTotal: "0.4816" },
  { geneTranscript: "NM_018174.6", parsimonySitesP: "0.354737173434057", constantSitesP: "0.474661630468996", distinctSitesP: "0.467107333962858", brLenTotal: "1.7843" },
  { geneTranscript: "NM_212502.3", parsimonySitesP: "0.167369901547117", constantSitesP: "0.706047819971871", distinctSitesP: "0.254571026722925", brLenTotal: "0.6678" },
  { geneTranscript: "NM_006580.4", parsimonySitesP: "0.235460992907801", constantSitesP: "0.665248226950355", distinctSitesP: "0.34468085106383", brLenTotal: "0.8147" },
  { geneTranscript: "NM_012363.1", parsimonySitesP: "0.566988210075027", constantSitesP: "0.292604501607717", distinctSitesP: "0.682743837084673", brLenTotal: "3.0168" },
  { geneTranscript: "NM_175871.4", parsimonySitesP: "0.336", constantSitesP: "0.464", distinctSitesP: "0.489333333333333", brLenTotal: "1.1421" },
  { geneTranscript: "NM_139276.3", parsimonySitesP: "0.134199134199134", constantSitesP: "0.79004329004329", distinctSitesP: "0.176190476190476", brLenTotal: "0.5015" },
  { geneTranscript: "NM_020647.4", parsimonySitesP: "0.167423096318709", constantSitesP: "0.709531013615734", distinctSitesP: "0.207261724659607", brLenTotal: "0.7034" },
  { geneTranscript: "NM_001173467.3", parsimonySitesP: "0.187161639597834", constantSitesP: "0.709203402938902", distinctSitesP: "0.232791956689869", brLenTotal: "0.6068" },
  { geneTranscript: "NM_001382347.1", parsimonySitesP: "0.140425531914894", constantSitesP: "0.759929078014184", distinctSitesP: "0.177836879432624", brLenTotal: "0.4852" },
  { geneTranscript: "NM_001256829.2", parsimonySitesP: "0.175925925925926", constantSitesP: "0.712962962962963", distinctSitesP: "0.287037037037037", brLenTotal: "0.4776" },
  { geneTranscript: "NM_019070.5", parsimonySitesP: "0.239475500345066", constantSitesP: "0.654244306418219", distinctSitesP: "0.330572808833678", brLenTotal: "1.0727" },
  { geneTranscript: "NM_002149.4", parsimonySitesP: "0.162348877374784", constantSitesP: "0.761658031088083", distinctSitesP: "0.214162348877375", brLenTotal: "0.8197" },
  { geneTranscript: "NM_001164440.2", parsimonySitesP: "0.290823211875843", constantSitesP: "0.550607287449393", distinctSitesP: "0.40080971659919", brLenTotal: "1.2298" },
  { geneTranscript: "NM_005502.4", parsimonySitesP: "0.171163202122954", constantSitesP: "0.715612560813799", distinctSitesP: "0.196962995724606", brLenTotal: "0.6248" },
  { geneTranscript: "NM_016069.11", parsimonySitesP: "0.186666666666667", constantSitesP: "0.722666666666667", distinctSitesP: "0.274666666666667", brLenTotal: "0.6638" },
  { geneTranscript: "NM_001135653.2", parsimonySitesP: "0.132323232323232", constantSitesP: "0.765151515151515", distinctSitesP: "0.198484848484848", brLenTotal: "0.5098" },
  { geneTranscript: "NM_000997.5", parsimonySitesP: "0.175257731958763", constantSitesP: "0.673539518900344", distinctSitesP: "0.333333333333333", brLenTotal: "0.6928" },
  { geneTranscript: "NM_014053.4", parsimonySitesP: "0.20960960960961", constantSitesP: "0.613213213213213", distinctSitesP: "0.358558558558559", brLenTotal: "0.7868" },
  { geneTranscript: "NM_002373.6", parsimonySitesP: "0.238316089903675", constantSitesP: "0.604947080508978", distinctSitesP: "0.279700321084552", brLenTotal: "0.7967" },
  { geneTranscript: "NM_198098.4", parsimonySitesP: "0.19454770755886", constantSitesP: "0.697645600991326", distinctSitesP: "0.286245353159851", brLenTotal: "0.9502" },
  { geneTranscript: "NM_014050.4", parsimonySitesP: "0.204225352112676", constantSitesP: "0.63849765258216", distinctSitesP: "0.375586854460094", brLenTotal: "0.6928" },
  { geneTranscript: "NM_001098201.3", parsimonySitesP: "0.258666666666667", constantSitesP: "0.589333333333333", distinctSitesP: "0.381333333333333", brLenTotal: "1.5145" },
  { geneTranscript: "NM_019030.4", parsimonySitesP: "0.144874604334064", constantSitesP: "0.73654735816898", distinctSitesP: "0.21572924275627", brLenTotal: "0.4497" },
  { geneTranscript: "NM_006415.4", parsimonySitesP: "0.16138125440451", constantSitesP: "0.708245243128964", distinctSitesP: "0.266384778012685", brLenTotal: "0.6088" },
  { geneTranscript: "NM_152383.5", parsimonySitesP: "0.210169491525424", constantSitesP: "0.651600753295669", distinctSitesP: "0.310357815442561", brLenTotal: "0.7626" },
  { geneTranscript: "NM_014754.3", parsimonySitesP: "0.128259337561663", constantSitesP: "0.786469344608879", distinctSitesP: "0.183932346723044", brLenTotal: "0.4197" },
  { geneTranscript: "NM_020935.3", parsimonySitesP: "0.145726932243786", constantSitesP: "0.748382703438883", distinctSitesP: "0.201225740551583", brLenTotal: "0.4361" },
  { geneTranscript: "NM_032859.3", parsimonySitesP: "0.10484668644906", constantSitesP: "0.735905044510386", distinctSitesP: "0.201780415430267", brLenTotal: "0.4714" },
  { geneTranscript: "NM_001109689.4", parsimonySitesP: "0.357957957957958", constantSitesP: "0.431231231231231", distinctSitesP: "0.486486486486486", brLenTotal: "2.0408" },
  { geneTranscript: "NM_000536.4", parsimonySitesP: "0.183428209993675", constantSitesP: "0.668564199873498", distinctSitesP: "0.25932953826692", brLenTotal: "0.6188" },
  { geneTranscript: "NM_001135050.2", parsimonySitesP: "0.208651399491094", constantSitesP: "0.636415040995194", distinctSitesP: "0.258693808312129", brLenTotal: "0.8052" },
  { geneTranscript: "NM_014729.3", parsimonySitesP: "0.172370088719899", constantSitesP: "0.741444866920152", distinctSitesP: "0.228136882129278", brLenTotal: "0.6914" },
  { geneTranscript: "NM_030937.6", parsimonySitesP: "0.257051282051282", constantSitesP: "0.614102564102564", distinctSitesP: "0.367948717948718", brLenTotal: "0.9987" },
  { geneTranscript: "NM_017585.4", parsimonySitesP: "0.241288625904011", constantSitesP: "0.648915187376726", distinctSitesP: "0.301775147928994", brLenTotal: "1.0934" },
  { geneTranscript: "NM_001005165.2", parsimonySitesP: "0.52457264957265", constantSitesP: "0.278846153846154", distinctSitesP: "0.668803418803419", brLenTotal: "2.7977" },
  { geneTranscript: "NM_198273.2", parsimonySitesP: "0.175381263616558", constantSitesP: "0.68082788671024", distinctSitesP: "0.297385620915033", brLenTotal: "0.6042" },
  { geneTranscript: "NM_007265.3", parsimonySitesP: "0.203933747412008", constantSitesP: "0.641821946169772", distinctSitesP: "0.34472049689441", brLenTotal: "0.6721" },
  { geneTranscript: "NM_145068.4", parsimonySitesP: "0.232911392405063", constantSitesP: "0.652320675105485", distinctSitesP: "0.2957805907173", brLenTotal: "0.8720" },
  { geneTranscript: "NM_001089591.2", parsimonySitesP: "0.684981684981685", constantSitesP: "0.102564102564103", distinctSitesP: "0.915750915750916", brLenTotal: "3.6740" },
  { geneTranscript: "NM_003512.4", parsimonySitesP: "0.287179487179487", constantSitesP: "0.653846153846154", distinctSitesP: "0.341025641025641", brLenTotal: "1.7672" },
  { geneTranscript: "NM_152335.3", parsimonySitesP: "0.212806026365348", constantSitesP: "0.637790332705587", distinctSitesP: "0.310106716886378", brLenTotal: "0.8549" },
  { geneTranscript: "NM_152305.3", parsimonySitesP: "0.143707482993197", constantSitesP: "0.747448979591837", distinctSitesP: "0.250850340136054", brLenTotal: "0.4573" },
  { geneTranscript: "NM_006225.4", parsimonySitesP: "0.229717813051146", constantSitesP: "0.660493827160494", distinctSitesP: "0.2663139329806", brLenTotal: "0.9119" },
  { geneTranscript: "NM_144638.3", parsimonySitesP: "0.205450733752621", constantSitesP: "0.658280922431866", distinctSitesP: "0.312368972746331", brLenTotal: "0.7582" },
  { geneTranscript: "NM_003890.2", parsimonySitesP: "0.326140226069085", constantSitesP: "0.506521223618686", distinctSitesP: "0.383606039048297", brLenTotal: "1.4090" },
  { geneTranscript: "NM_020389.3", parsimonySitesP: "0.155065738592421", constantSitesP: "0.75599381283836", distinctSitesP: "0.206496519721578", brLenTotal: "0.6653" },
  { geneTranscript: "NM_001172501.3", parsimonySitesP: "0.178282009724473", constantSitesP: "0.719070772555375", distinctSitesP: "0.240410588870881", brLenTotal: "0.7274" },
  { geneTranscript: "NM_001003694.2", parsimonySitesP: "0.113114754098361", constantSitesP: "0.799180327868852", distinctSitesP: "0.147267759562842", brLenTotal: "0.4032" },
  { geneTranscript: "NM_003465.3", parsimonySitesP: "0.297567954220315", constantSitesP: "0.541487839771102", distinctSitesP: "0.434191702432046", brLenTotal: "1.1659" },
  { geneTranscript: "NM_001136103.3", parsimonySitesP: "0.388387484957882", constantSitesP: "0.462394705174489", distinctSitesP: "0.46179302045728", brLenTotal: "2.2293" },
  { geneTranscript: "NM_032648.3", parsimonySitesP: "0.243353783231084", constantSitesP: "0.61758691206544", distinctSitesP: "0.370143149284254", brLenTotal: "0.8869" },
  { geneTranscript: "NM_032442.3", parsimonySitesP: "0.162825437473325", constantSitesP: "0.732394366197183", distinctSitesP: "0.187366623986342", brLenTotal: "0.6061" },
  { geneTranscript: "NM_138340.5", parsimonySitesP: "0.158924205378973", constantSitesP: "0.724531377343113", distinctSitesP: "0.235533822330888", brLenTotal: "0.5175" },
  { geneTranscript: "NM_012283.2", parsimonySitesP: "0.366952789699571", constantSitesP: "0.540057224606581", distinctSitesP: "0.411301859799714", brLenTotal: "1.6127" },
  { geneTranscript: "NM_012433.4", parsimonySitesP: "0.107106339468303", constantSitesP: "0.811605316973415", distinctSitesP: "0.142126789366053", brLenTotal: "0.3601" },
  { geneTranscript: "NM_004438.5", parsimonySitesP: "0.152805949966193", constantSitesP: "0.737998647734956", distinctSitesP: "0.205882352941176", brLenTotal: "0.6382" },
  { geneTranscript: "NM_001042454.3", parsimonySitesP: "0.176428054953001", constantSitesP: "0.724511930585683", distinctSitesP: "0.237888647866956", brLenTotal: "0.6404" },
  { geneTranscript: "NM_014313.4", parsimonySitesP: "0.0976645435244161", constantSitesP: "0.794055201698514", distinctSitesP: "0.210191082802548", brLenTotal: "0.3839" },
  { geneTranscript: "NM_018640.5", parsimonySitesP: "0.0482758620689655", constantSitesP: "0.880459770114943", distinctSitesP: "0.119540229885057", brLenTotal: "0.1956" },
  { geneTranscript: "NM_199342.4", parsimonySitesP: "0.146464646464646", constantSitesP: "0.752525252525253", distinctSitesP: "0.262626262626263", brLenTotal: "0.3598" },
  { geneTranscript: "NM_014184.4", parsimonySitesP: "0.13189448441247", constantSitesP: "0.786570743405276", distinctSitesP: "0.213429256594724", brLenTotal: "0.3607" },
  { geneTranscript: "NM_001242957.3", parsimonySitesP: "0.22119341563786", constantSitesP: "0.608539094650206", distinctSitesP: "0.396090534979424", brLenTotal: "0.8296" },
  { geneTranscript: "NM_000195.5", parsimonySitesP: "0.241428571428571", constantSitesP: "0.612857142857143", distinctSitesP: "0.331904761904762", brLenTotal: "1.0369" },
  { geneTranscript: "NM_003559.5", parsimonySitesP: "0.127403846153846", constantSitesP: "0.792467948717949", distinctSitesP: "0.178685897435897", brLenTotal: "0.4467" },
  { geneTranscript: "NM_019121.2", parsimonySitesP: "0.190545103714424", constantSitesP: "0.708634828750603", distinctSitesP: "0.256150506512301", brLenTotal: "0.8121" },
  { geneTranscript: "NM_003144.5", parsimonySitesP: "0.122377622377622", constantSitesP: "0.772727272727273", distinctSitesP: "0.215617715617716", brLenTotal: "0.4285" },
  { geneTranscript: "NM_002865.3", parsimonySitesP: "0.0849056603773585", constantSitesP: "0.85062893081761", distinctSitesP: "0.141509433962264", brLenTotal: "0.2471" },
  { geneTranscript: "NM_052935.5", parsimonySitesP: "0.197777777777778", constantSitesP: "0.682222222222222", distinctSitesP: "0.302222222222222", brLenTotal: "0.6406" },
  { geneTranscript: "NM_001312673.2", parsimonySitesP: "0.192552225249773", constantSitesP: "0.71480472297911", distinctSitesP: "0.269754768392371", brLenTotal: "0.6722" },
  { geneTranscript: "NM_033467.4", parsimonySitesP: "0.306375695335901", constantSitesP: "0.556268720581943", distinctSitesP: "0.416345742404792", brLenTotal: "1.2753" },
  { geneTranscript: "NM_144697.4", parsimonySitesP: "0.236363636363636", constantSitesP: "0.580952380952381", distinctSitesP: "0.370562770562771", brLenTotal: "0.7204" },
  { geneTranscript: "NM_006563.5", parsimonySitesP: "0.353591160220994", constantSitesP: "0.499079189686924", distinctSitesP: "0.441988950276243", brLenTotal: "1.2095" },
  { geneTranscript: "NM_018322.3", parsimonySitesP: "0.31511839708561", constantSitesP: "0.471766848816029", distinctSitesP: "0.520947176684882", brLenTotal: "1.1104" },
  { geneTranscript: "NM_002148.4", parsimonySitesP: "0.042156862745098", constantSitesP: "0.918627450980392", distinctSitesP: "0.0784313725490196", brLenTotal: "0.1208" },
  { geneTranscript: "NM_001017961.5", parsimonySitesP: "0.106002554278416", constantSitesP: "0.787994891443167", distinctSitesP: "0.159642401021711", brLenTotal: "0.4292" },
  { geneTranscript: "NM_033296.3", parsimonySitesP: "0.322834645669291", constantSitesP: "0.498687664041995", distinctSitesP: "0.47244094488189", brLenTotal: "1.8093" },
  { geneTranscript: "NM_001004317.4", parsimonySitesP: "0.126666666666667", constantSitesP: "0.769333333333333", distinctSitesP: "0.208", brLenTotal: "0.4330" },
  { geneTranscript: "NM_022343.4", parsimonySitesP: "0.147186147186147", constantSitesP: "0.766233766233766", distinctSitesP: "0.238095238095238", brLenTotal: "0.5204" },
  { geneTranscript: "NM_014888.3", parsimonySitesP: "0.117474302496329", constantSitesP: "0.779735682819383", distinctSitesP: "0.227606461086637", brLenTotal: "0.3966" },
  { geneTranscript: "NM_001388185.1", parsimonySitesP: "0.21462829736211", constantSitesP: "0.673061550759392", distinctSitesP: "0.264988009592326", brLenTotal: "0.8707" },
  { geneTranscript: "NM_017848.6", parsimonySitesP: "0.158759124087591", constantSitesP: "0.679440389294404", distinctSitesP: "0.245742092457421", brLenTotal: "0.5736" },
  { geneTranscript: "NM_001351411.2", parsimonySitesP: "0.148351648351648", constantSitesP: "0.768315018315018", distinctSitesP: "0.212454212454212", brLenTotal: "0.4867" },
  { geneTranscript: "NM_002408.4", parsimonySitesP: "0.165548098434004", constantSitesP: "0.699478001491424", distinctSitesP: "0.24683072334079", brLenTotal: "0.5281" },
  { geneTranscript: "NM_152450.3", parsimonySitesP: "0.163949275362319", constantSitesP: "0.73731884057971", distinctSitesP: "0.248188405797101", brLenTotal: "0.5828" },
  { geneTranscript: "NM_019063.5", parsimonySitesP: "0.135915732246007", constantSitesP: "0.760788311247027", distinctSitesP: "0.207271491675161", brLenTotal: "0.4494" },
  { geneTranscript: "NM_005105.5", parsimonySitesP: "0.145593869731801", constantSitesP: "0.775862068965517", distinctSitesP: "0.218390804597701", brLenTotal: "0.4201" },
  { geneTranscript: "NM_005057.4", parsimonySitesP: "0.0947955390334572", constantSitesP: "0.843246592317224", distinctSitesP: "0.144361833952912", brLenTotal: "0.3071" },
  { geneTranscript: "NM_018163.3", parsimonySitesP: "0.214912280701754", constantSitesP: "0.641447368421053", distinctSitesP: "0.321271929824561", brLenTotal: "0.7624" },
  { geneTranscript: "NM_152795.4", parsimonySitesP: "0.233682112605879", constantSitesP: "0.611858495266567", distinctSitesP: "0.351270553064275", brLenTotal: "0.9223" },
  { geneTranscript: "NM_030799.9", parsimonySitesP: "0.146562905317769", constantSitesP: "0.75745784695201", distinctSitesP: "0.230869001297017", brLenTotal: "0.4652" },
  { geneTranscript: "NM_020196.3", parsimonySitesP: "0.177387914230019", constantSitesP: "0.725536062378168", distinctSitesP: "0.215204678362573", brLenTotal: "0.9634" },
  { geneTranscript: "NM_001278473.3", parsimonySitesP: "0.18958818958819", constantSitesP: "0.665889665889666", distinctSitesP: "0.287490287490287", brLenTotal: "0.8222" },
  { geneTranscript: "NM_006380.5", parsimonySitesP: "0.0877492877492877", constantSitesP: "0.835897435897436", distinctSitesP: "0.146438746438746", brLenTotal: "0.2857" },
  { geneTranscript: "NM_018502.5", parsimonySitesP: "0.208248816768087", constantSitesP: "0.627450980392157", distinctSitesP: "0.314401622718053", brLenTotal: "0.6841" },
  { geneTranscript: "NM_001040181.2", parsimonySitesP: "0.148880105401845", constantSitesP: "0.740447957839262", distinctSitesP: "0.235836627140975", brLenTotal: "0.5550" },
  { geneTranscript: "NM_033449.3", parsimonySitesP: "0.185507246376812", constantSitesP: "0.690338164251208", distinctSitesP: "0.257487922705314", brLenTotal: "0.6738" },
  { geneTranscript: "NM_003647.3", parsimonySitesP: "0.141093474426808", constantSitesP: "0.744855967078189", distinctSitesP: "0.207524985302763", brLenTotal: "0.4365" },
  { geneTranscript: "NM_001127173.3", parsimonySitesP: "0.150753768844221", constantSitesP: "0.765494137353434", distinctSitesP: "0.209380234505863", brLenTotal: "0.5398" },
  { geneTranscript: "NM_002220.3", parsimonySitesP: "0.184381778741866", constantSitesP: "0.716558206796819", distinctSitesP: "0.250180766449747", brLenTotal: "0.8197" },
  { geneTranscript: "NM_033208.4", parsimonySitesP: "0.201578627808136", constantSitesP: "0.625986642380085", distinctSitesP: "0.305403764420158", brLenTotal: "0.6286" },
  { geneTranscript: "NM_006080.3", parsimonySitesP: "0.165585819282317", constantSitesP: "0.745784695201038", distinctSitesP: "0.228707306528318", brLenTotal: "0.5854" },
  { geneTranscript: "NM_080608.4", parsimonySitesP: "0.274596182085169", constantSitesP: "0.547723935389134", distinctSitesP: "0.437591776798825", brLenTotal: "0.9083" },
  { geneTranscript: "NM_001406.4", parsimonySitesP: "0.12156862745098", constantSitesP: "0.772549019607843", distinctSitesP: "0.207843137254902", brLenTotal: "0.4584" },
  { geneTranscript: "NM_138458.4", parsimonySitesP: "0.145658263305322", constantSitesP: "0.766573295985061", distinctSitesP: "0.200746965452848", brLenTotal: "0.4937" },
  { geneTranscript: "NM_001002030.2", parsimonySitesP: "0.20265780730897", constantSitesP: "0.635658914728682", distinctSitesP: "0.356589147286822", brLenTotal: "0.6729" },
  { geneTranscript: "NM_014589.3", parsimonySitesP: "0.248826291079812", constantSitesP: "0.626760563380282", distinctSitesP: "0.389671361502347", brLenTotal: "1.0515" },
  { geneTranscript: "NM_138473.3", parsimonySitesP: "0.110828025477707", constantSitesP: "0.803821656050955", distinctSitesP: "0.15244161358811", brLenTotal: "0.3347" },
  { geneTranscript: "NM_213600.4", parsimonySitesP: "0.303886925795053", constantSitesP: "0.531998429524931", distinctSitesP: "0.418138987043581", brLenTotal: "1.1446" },
  { geneTranscript: "NM_152424.4", parsimonySitesP: "0.256093979441997", constantSitesP: "0.573568281938326", distinctSitesP: "0.330102790014684", brLenTotal: "0.8578" },
  { geneTranscript: "NM_080655.3", parsimonySitesP: "0.11030303030303", constantSitesP: "0.76969696969697", distinctSitesP: "0.216969696969697", brLenTotal: "0.4151" },
  { geneTranscript: "NM_000095.3", parsimonySitesP: "0.251871422280934", constantSitesP: "0.65962131219727", distinctSitesP: "0.305592250110084", brLenTotal: "0.9877" },
  { geneTranscript: "NM_002481.4", parsimonySitesP: "0.190767141887305", constantSitesP: "0.688051595383571", distinctSitesP: "0.263747454175153", brLenTotal: "0.5820" },
  { geneTranscript: "NM_080594.4", parsimonySitesP: "0.160655737704918", constantSitesP: "0.680874316939891", distinctSitesP: "0.288524590163934", brLenTotal: "0.6873" },
  { geneTranscript: "NM_001143676.3", parsimonySitesP: "0.14765525982256", constantSitesP: "0.747782002534854", distinctSitesP: "0.230038022813688", brLenTotal: "0.5620" },
  { geneTranscript: "NM_001366299.1", parsimonySitesP: "0.159303882195448", constantSitesP: "0.733154841588577", distinctSitesP: "0.251227130745203", brLenTotal: "0.8260" },
  { geneTranscript: "NM_153219.4", parsimonySitesP: "0.205808080808081", constantSitesP: "0.647727272727273", distinctSitesP: "0.301767676767677", brLenTotal: "0.7572" },
  { geneTranscript: "NM_001426.4", parsimonySitesP: "0.139455782312925", constantSitesP: "0.760204081632653", distinctSitesP: "0.227040816326531", brLenTotal: "0.6318" },
  { geneTranscript: "NM_017719.5", parsimonySitesP: "0.143355119825708", constantSitesP: "0.739869281045752", distinctSitesP: "0.18562091503268", brLenTotal: "0.7575" },
  { geneTranscript: "NM_001286069.2", parsimonySitesP: "0.167195767195767", constantSitesP: "0.682539682539683", distinctSitesP: "0.300529100529101", brLenTotal: "0.5662" },
  { geneTranscript: "NM_001126121.2", parsimonySitesP: "0.275", constantSitesP: "0.563541666666667", distinctSitesP: "0.396875", brLenTotal: "1.0487" },
  { geneTranscript: "NM_001195442.2", parsimonySitesP: "0.29718875502008", constantSitesP: "0.562248995983936", distinctSitesP: "0.441767068273092", brLenTotal: "1.0729" },
  { geneTranscript: "NM_173561.3", parsimonySitesP: "0.238095238095238", constantSitesP: "0.631274131274131", distinctSitesP: "0.305662805662806", brLenTotal: "0.8980" },
  { geneTranscript: "NM_144589.4", parsimonySitesP: "0.2264631043257", constantSitesP: "0.620865139949109", distinctSitesP: "0.33587786259542", brLenTotal: "0.8279" },
  { geneTranscript: "NM_006160.4", parsimonySitesP: "0.109947643979058", constantSitesP: "0.819371727748691", distinctSitesP: "0.165794066317627", brLenTotal: "0.3339" },
  { geneTranscript: "NM_001284254.2", parsimonySitesP: "0.156206415620642", constantSitesP: "0.715481171548117", distinctSitesP: "0.255230125523013", brLenTotal: "0.6888" },
  { geneTranscript: "NM_207346.3", parsimonySitesP: "0.261089987325729", constantSitesP: "0.579847908745247", distinctSitesP: "0.368187579214195", brLenTotal: "0.9197" },
  { geneTranscript: "NM_005436.5", parsimonySitesP: "0.109001406469761", constantSitesP: "0.80168776371308", distinctSitesP: "0.169479606188467", brLenTotal: "0.3593" },
  { geneTranscript: "NM_001394998.1", parsimonySitesP: "0.129861780777885", constantSitesP: "0.756669881067181", distinctSitesP: "0.147540983606557", brLenTotal: "0.5424" },
  { geneTranscript: "NM_001145638.3", parsimonySitesP: "0.228641975308642", constantSitesP: "0.661234567901235", distinctSitesP: "0.298765432098765", brLenTotal: "1.1660" },
  { geneTranscript: "NM_001281.3", parsimonySitesP: "0.180327868852459", constantSitesP: "0.711748633879781", distinctSitesP: "0.26775956284153", brLenTotal: "0.7862" },
  { geneTranscript: "NM_006575.6", parsimonySitesP: "0.117809298660362", constantSitesP: "0.789204097714736", distinctSitesP: "0.177304964539007", brLenTotal: "0.3891" },
  { geneTranscript: "NM_016129.3", parsimonySitesP: "0.123152709359606", constantSitesP: "0.77175697865353", distinctSitesP: "0.206896551724138", brLenTotal: "0.4345" },
  { geneTranscript: "NM_001032396.4", parsimonySitesP: "0.241496598639456", constantSitesP: "0.590136054421769", distinctSitesP: "0.319727891156463", brLenTotal: "0.9210" },
  { geneTranscript: "NM_031294.4", parsimonySitesP: "0.282345442957298", constantSitesP: "0.572339069471001", distinctSitesP: "0.381771829190567", brLenTotal: "1.0017" },
  { geneTranscript: "NM_025153.3", parsimonySitesP: "0.262605521332421", constantSitesP: "0.591832078485056", distinctSitesP: "0.364590463153091", brLenTotal: "0.9975" },
  { geneTranscript: "NM_000958.3", parsimonySitesP: "0.172131147540984", constantSitesP: "0.710382513661202", distinctSitesP: "0.25", brLenTotal: "0.6444" },
  { geneTranscript: "NM_144710.5", parsimonySitesP: "0.254038179148311", constantSitesP: "0.577826725403818", distinctSitesP: "0.399412628487518", brLenTotal: "1.2463" },
  { geneTranscript: "NM_024503.5", parsimonySitesP: "0.206428373510668", constantSitesP: "0.6460238293156", distinctSitesP: "0.236630645608202", brLenTotal: "0.8910" },
  { geneTranscript: "NM_001193424.2", parsimonySitesP: "0.146341463414634", constantSitesP: "0.701626016260163", distinctSitesP: "0.246341463414634", brLenTotal: "0.5342" },
  { geneTranscript: "NM_144772.3", parsimonySitesP: "0.244212962962963", constantSitesP: "0.625", distinctSitesP: "0.336805555555556", brLenTotal: "0.8240" },
  { geneTranscript: "NM_007021.4", parsimonySitesP: "0.382075471698113", constantSitesP: "0.378930817610063", distinctSitesP: "0.616352201257862", brLenTotal: "1.5376" },
  { geneTranscript: "NM_004290.5", parsimonySitesP: "0.139943741209564", constantSitesP: "0.727144866385373", distinctSitesP: "0.234880450070323", brLenTotal: "0.4696" },
  { geneTranscript: "NM_181523.3", parsimonySitesP: "0.157918968692449", constantSitesP: "0.729281767955801", distinctSitesP: "0.220534069981584", brLenTotal: "0.5825" },
  { geneTranscript: "NM_001282430.2", parsimonySitesP: "0.361952861952862", constantSitesP: "0.476430976430976", distinctSitesP: "0.498316498316498", brLenTotal: "1.2789" },
  { geneTranscript: "NM_018113.4", parsimonySitesP: "0.153374233128834", constantSitesP: "0.734832992501704", distinctSitesP: "0.227675528289025", brLenTotal: "0.4929" },
  { geneTranscript: "NM_004558.5", parsimonySitesP: "0.22165820642978", constantSitesP: "0.649746192893401", distinctSitesP: "0.326565143824027", brLenTotal: "0.7480" },
  { geneTranscript: "NM_006648.4", parsimonySitesP: "0.253646068260412", constantSitesP: "0.603067207938656", distinctSitesP: "0.326868140129304", brLenTotal: "1.3301" },
  { geneTranscript: "NM_198204.2", parsimonySitesP: "0.144808743169399", constantSitesP: "0.762295081967213", distinctSitesP: "0.206284153005464", brLenTotal: "0.4889" },
  { geneTranscript: "NM_016173.5", parsimonySitesP: "0.258382642998028", constantSitesP: "0.582840236686391", distinctSitesP: "0.3767258382643", brLenTotal: "0.8654" },
  { geneTranscript: "NM_015159.3", parsimonySitesP: "0.100709219858156", constantSitesP: "0.824113475177305", distinctSitesP: "0.165957446808511", brLenTotal: "0.3787" },
  { geneTranscript: "NM_000641.4", parsimonySitesP: "0.231155778894472", constantSitesP: "0.653266331658291", distinctSitesP: "0.30820770519263", brLenTotal: "1.0566" },
  { geneTranscript: "NM_001134693.2", parsimonySitesP: "0.0900900900900901", constantSitesP: "0.783783783783784", distinctSitesP: "0.225225225225225", brLenTotal: "0.2896" },
  { geneTranscript: "NM_001004714.2", parsimonySitesP: "0.587719298245614", constantSitesP: "0.309210526315789", distinctSitesP: "0.682017543859649", brLenTotal: "3.3462" },
  { geneTranscript: "NM_001796.5", parsimonySitesP: "0.121401752190238", constantSitesP: "0.800166875260743", distinctSitesP: "0.163954943679599", brLenTotal: "0.4238" },
  { geneTranscript: "NM_145715.3", parsimonySitesP: "0.36952380952381", constantSitesP: "0.56", distinctSitesP: "0.393015873015873", brLenTotal: "1.7330" },
  { geneTranscript: "NM_001360236.2", parsimonySitesP: "0.108910891089109", constantSitesP: "0.772277227722772", distinctSitesP: "0.207920792079208", brLenTotal: "0.3988" },
  { geneTranscript: "NM_032806.6", parsimonySitesP: "0.196551724137931", constantSitesP: "0.671264367816092", distinctSitesP: "0.260344827586207", brLenTotal: "0.9269" },
  { geneTranscript: "NM_000675.6", parsimonySitesP: "0.203883495145631", constantSitesP: "0.68042071197411", distinctSitesP: "0.27588996763754", brLenTotal: "0.9350" },
  { geneTranscript: "NM_001064.4", parsimonySitesP: "0.191546281433922", constantSitesP: "0.699304440877475", distinctSitesP: "0.256286784376672", brLenTotal: "0.9270" },
  { geneTranscript: "NM_174936.4", parsimonySitesP: "0.281791907514451", constantSitesP: "0.554913294797688", distinctSitesP: "0.393063583815029", brLenTotal: "1.1515" },
  { geneTranscript: "NM_004599.4", parsimonySitesP: "0.1954425942156", constantSitesP: "0.705229330996202", distinctSitesP: "0.234881682734443", brLenTotal: "0.7517" },
  { geneTranscript: "NM_001177.6", parsimonySitesP: "0.121546961325967", constantSitesP: "0.780847145488029", distinctSitesP: "0.224677716390424", brLenTotal: "0.3811" },
  { geneTranscript: "NM_025263.4", parsimonySitesP: "0.161347517730496", constantSitesP: "0.675531914893617", distinctSitesP: "0.320921985815603", brLenTotal: "0.5550" },
  { geneTranscript: "NM_002970.4", parsimonySitesP: "0.120857699805068", constantSitesP: "0.814814814814815", distinctSitesP: "0.187134502923977", brLenTotal: "0.3100" },
  { geneTranscript: "NM_024915.4", parsimonySitesP: "0.171733333333333", constantSitesP: "0.7408", distinctSitesP: "0.221866666666667", brLenTotal: "0.6284" },
  { geneTranscript: "NM_004479.4", parsimonySitesP: "0.310916179337232", constantSitesP: "0.529239766081871", distinctSitesP: "0.443469785575049", brLenTotal: "1.6594" },
  { geneTranscript: "NM_022463.5", parsimonySitesP: "0.144827586206897", constantSitesP: "0.760919540229885", distinctSitesP: "0.21992337164751", brLenTotal: "0.5655" },
  { geneTranscript: "NM_002870.5", parsimonySitesP: "0.167487684729064", constantSitesP: "0.704433497536946", distinctSitesP: "0.274220032840722", brLenTotal: "0.6018" },
  { geneTranscript: "NM_005146.5", parsimonySitesP: "0.197083333333333", constantSitesP: "0.705416666666667", distinctSitesP: "0.251666666666667", brLenTotal: "0.8635" },
  { geneTranscript: "NM_001005738.2", parsimonySitesP: "0.42355175688509", constantSitesP: "0.381766381766382", distinctSitesP: "0.570750237416904", brLenTotal: "1.9220" },
  { geneTranscript: "NM_014230.4", parsimonySitesP: "0.17384370015949", constantSitesP: "0.721956406166932", distinctSitesP: "0.25518341307815", brLenTotal: "0.6594" },
  { geneTranscript: "NM_014636.3", parsimonySitesP: "0.107121484141233", constantSitesP: "0.820466786355476", distinctSitesP: "0.153201675643327", brLenTotal: "0.3650" },
  { geneTranscript: "NM_015365.3", parsimonySitesP: "0.0520520520520521", constantSitesP: "0.8998998998999", distinctSitesP: "0.1001001001001", brLenTotal: "0.1613" },
  { geneTranscript: "NM_000285.4", parsimonySitesP: "0.227856659905341", constantSitesP: "0.649087221095335", distinctSitesP: "0.344827586206897", brLenTotal: "1.0931" },
  { geneTranscript: "NM_001113407.3", parsimonySitesP: "0.08110300081103", constantSitesP: "0.825628548256285", distinctSitesP: "0.124898621248986", brLenTotal: "0.3262" },
  { geneTranscript: "NM_018645.6", parsimonySitesP: "0.242559523809524", constantSitesP: "0.608630952380952", distinctSitesP: "0.366071428571429", brLenTotal: "0.9425" },
  { geneTranscript: "NM_001255978.2", parsimonySitesP: "0.250632911392405", constantSitesP: "0.561181434599156", distinctSitesP: "0.367932489451477", brLenTotal: "0.7612" },
  { geneTranscript: "NM_033131.4", parsimonySitesP: "0.261363636363636", constantSitesP: "0.653409090909091", distinctSitesP: "0.285037878787879", brLenTotal: "1.1736" },
  { geneTranscript: "NM_002473.6", parsimonySitesP: "0.209183673469388", constantSitesP: "0.681632653061224", distinctSitesP: "0.245068027210884", brLenTotal: "1.0579" },
  { geneTranscript: "NM_001102654.2", parsimonySitesP: "0.146913580246914", constantSitesP: "0.77283950617284", distinctSitesP: "0.218518518518519", brLenTotal: "0.5268" },
  { geneTranscript: "NM_030784.4", parsimonySitesP: "0.177406523468576", constantSitesP: "0.700875099443119", distinctSitesP: "0.26332537788385", brLenTotal: "0.5810" },
  { geneTranscript: "NM_001296.5", parsimonySitesP: "0.301215277777778", constantSitesP: "0.542534722222222", distinctSitesP: "0.444444444444444", brLenTotal: "1.0384" },
  { geneTranscript: "NM_013363.4", parsimonySitesP: "0.158232931726908", constantSitesP: "0.718072289156627", distinctSitesP: "0.280321285140562", brLenTotal: "0.6242" },
  { geneTranscript: "NM_006772.3", parsimonySitesP: "0.101762223876893", constantSitesP: "0.833457433606354", distinctSitesP: "0.120625465376024", brLenTotal: "0.3648" },
  { geneTranscript: "NM_015946.5", parsimonySitesP: "0.136796536796537", constantSitesP: "0.744588744588745", distinctSitesP: "0.211255411255411", brLenTotal: "0.4630" },
  { geneTranscript: "NM_012333.5", parsimonySitesP: "0.0809061488673139", constantSitesP: "0.822006472491909", distinctSitesP: "0.181229773462783", brLenTotal: "0.2799" },
  { geneTranscript: "NM_012401.4", parsimonySitesP: "0.259702575262967", constantSitesP: "0.629669931084512", distinctSitesP: "0.311570547696772", brLenTotal: "1.3418" },
  { geneTranscript: "NM_032430.2", parsimonySitesP: "0.145672664952871", constantSitesP: "0.765638389031705", distinctSitesP: "0.196658097686375", brLenTotal: "0.5837" },
  { geneTranscript: "NM_004396.5", parsimonySitesP: "0.125950054288817", constantSitesP: "0.777958740499457", distinctSitesP: "0.179695982627579", brLenTotal: "0.4626" },
  { geneTranscript: "NM_078480.3", parsimonySitesP: "0.158020274299344", constantSitesP: "0.773404889683959", distinctSitesP: "0.206320810971974", brLenTotal: "0.6885" },
  { geneTranscript: "NM_001040716.2", parsimonySitesP: "0.170911148839842", constantSitesP: "0.737973967176005", distinctSitesP: "0.205149971703452", brLenTotal: "0.8133" },
  { geneTranscript: "NM_001163278.2", parsimonySitesP: "0.156661786237189", constantSitesP: "0.729502196193265", distinctSitesP: "0.176915568570034", brLenTotal: "0.6491" },
  { geneTranscript: "NM_001352005.2", parsimonySitesP: "0.132394366197183", constantSitesP: "0.780281690140845", distinctSitesP: "0.215023474178404", brLenTotal: "0.5520" },
  { geneTranscript: "NM_003974.4", parsimonySitesP: "0.310679611650485", constantSitesP: "0.531553398058252", distinctSitesP: "0.428802588996764", brLenTotal: "1.2814" },
  { geneTranscript: "NM_001134479.2", parsimonySitesP: "0.125097125097125", constantSitesP: "0.786713286713287", distinctSitesP: "0.167832167832168", brLenTotal: "0.4107" },
  { geneTranscript: "NM_198060.4", parsimonySitesP: "0.187090558766859", constantSitesP: "0.68150289017341", distinctSitesP: "0.249518304431599", brLenTotal: "0.7434" },
  { geneTranscript: "NM_000322.5", parsimonySitesP: "0.185934489402697", constantSitesP: "0.707129094412331", distinctSitesP: "0.272639691714836", brLenTotal: "0.9102" },
  { geneTranscript: "NM_001111020.3", parsimonySitesP: "0.152407237043852", constantSitesP: "0.770622508432996", distinctSitesP: "0.190125728304201", brLenTotal: "0.6339" },
  { geneTranscript: "NM_005896.4", parsimonySitesP: "0.191626409017713", constantSitesP: "0.646537842190016", distinctSitesP: "0.313204508856683", brLenTotal: "0.8807" },
  { geneTranscript: "NM_023933.3", parsimonySitesP: "0.340425531914894", constantSitesP: "0.517730496453901", distinctSitesP: "0.463829787234043", brLenTotal: "1.5324" },
  { geneTranscript: "NM_018238.4", parsimonySitesP: "0.185624012638231", constantSitesP: "0.694312796208531", distinctSitesP: "0.311216429699842", brLenTotal: "0.6574" },
  { geneTranscript: "NM_018245.3", parsimonySitesP: "0.194059405940594", constantSitesP: "0.68019801980198", distinctSitesP: "0.257755775577558", brLenTotal: "0.9271" },
  { geneTranscript: "NM_015633.3", parsimonySitesP: "0.111989459815547", constantSitesP: "0.779973649538867", distinctSitesP: "0.22266139657444", brLenTotal: "0.4058" },
  { geneTranscript: "NM_198066.4", parsimonySitesP: "0.114130434782609", constantSitesP: "0.76268115942029", distinctSitesP: "0.233695652173913", brLenTotal: "0.4025" },
  { geneTranscript: "NM_001367233.3", parsimonySitesP: "0.202360391479562", constantSitesP: "0.671560161197467", distinctSitesP: "0.271445020149683", brLenTotal: "0.6576" },
  { geneTranscript: "NM_018712.4", parsimonySitesP: "0.149700598802395", constantSitesP: "0.755489021956088", distinctSitesP: "0.25249500998004", brLenTotal: "0.5217" },
  { geneTranscript: "NM_014275.5", parsimonySitesP: "0.168491484184915", constantSitesP: "0.718978102189781", distinctSitesP: "0.237834549878345", brLenTotal: "0.8148" },
  { geneTranscript: "NM_018946.4", parsimonySitesP: "0.170844939647168", constantSitesP: "0.724233983286908", distinctSitesP: "0.236768802228412", brLenTotal: "0.5935" },
  { geneTranscript: "NM_004533.4", parsimonySitesP: "0.233420975752264", constantSitesP: "0.662284545720129", distinctSitesP: "0.306456324861233", brLenTotal: "1.0493" },
  { geneTranscript: "NM_000511.6", parsimonySitesP: "0.433430515063168", constantSitesP: "0.452866861030126", distinctSitesP: "0.540330417881438", brLenTotal: "2.9416" },
  { geneTranscript: "NM_006086.4", parsimonySitesP: "0.268888888888889", constantSitesP: "0.602222222222222", distinctSitesP: "0.367407407407407", brLenTotal: "2.0923" },
  { geneTranscript: "NM_017897.3", parsimonySitesP: "0.179375453885258", constantSitesP: "0.677559912854031", distinctSitesP: "0.286855482933914", brLenTotal: "0.5848" },
  { geneTranscript: "NM_017534.6", parsimonySitesP: "0.211918255194917", constantSitesP: "0.701356688991929", distinctSitesP: "0.246093079168813", brLenTotal: "1.2859" },
  { geneTranscript: "NM_001003693.3", parsimonySitesP: "0.285072951739618", constantSitesP: "0.534231200897868", distinctSitesP: "0.410774410774411", brLenTotal: "0.9652" },
  { geneTranscript: "NM_014030.4", parsimonySitesP: "0.148050810337276", constantSitesP: "0.77748576434516", distinctSitesP: "0.188786684187473", brLenTotal: "0.5942" },
  { geneTranscript: "NM_001318895.3", parsimonySitesP: "0.169653524492234", constantSitesP: "0.734767025089606", distinctSitesP: "0.223416965352449", brLenTotal: "0.7886" },
  { geneTranscript: "NM_006148.4", parsimonySitesP: "0.146871008939974", constantSitesP: "0.752234993614304", distinctSitesP: "0.246487867177522", brLenTotal: "0.6891" },
  { geneTranscript: "NM_182507.3", parsimonySitesP: "0.230825958702065", constantSitesP: "0.62905604719764", distinctSitesP: "0.321533923303835", brLenTotal: "0.9463" },
  { geneTranscript: "NM_003626.5", parsimonySitesP: "0.199389905712701", constantSitesP: "0.705768164170826", distinctSitesP: "0.262063227953411", brLenTotal: "0.8771" },
  { geneTranscript: "NM_001304762.2", parsimonySitesP: "0.16969696969697", constantSitesP: "0.701010101010101", distinctSitesP: "0.301010101010101", brLenTotal: "0.6881" },
  { geneTranscript: "NM_017828.5", parsimonySitesP: "0.249581239530988", constantSitesP: "0.591289782244556", distinctSitesP: "0.403685092127303", brLenTotal: "1.0585" },
  { geneTranscript: "NM_178232.4", parsimonySitesP: "0.259259259259259", constantSitesP: "0.588888888888889", distinctSitesP: "0.380555555555556", brLenTotal: "1.1693" },
  { geneTranscript: "NM_021135.6", parsimonySitesP: "0.246475670759436", constantSitesP: "0.693951796271032", distinctSitesP: "0.304683947248749", brLenTotal: "1.3002" },
  { geneTranscript: "NM_080725.3", parsimonySitesP: "0.21654501216545", constantSitesP: "0.608272506082725", distinctSitesP: "0.37712895377129", brLenTotal: "0.9626" },
  { geneTranscript: "NM_014868.5", parsimonySitesP: "0.14262227702425", constantSitesP: "0.729551993423757", distinctSitesP: "0.207562679819153", brLenTotal: "0.4670" },
  { geneTranscript: "NM_152600.3", parsimonySitesP: "0.231316725978648", constantSitesP: "0.63938315539739", distinctSitesP: "0.320877817319098", brLenTotal: "1.1027" },
  { geneTranscript: "NM_000397.4", parsimonySitesP: "0.182456140350877", constantSitesP: "0.734502923976608", distinctSitesP: "0.242105263157895", brLenTotal: "0.5735" },
  { geneTranscript: "NM_001745.4", parsimonySitesP: "0.190315315315315", constantSitesP: "0.648648648648649", distinctSitesP: "0.307432432432432", brLenTotal: "0.6712" },
  { geneTranscript: "NM_003187.5", parsimonySitesP: "0.262626262626263", constantSitesP: "0.388888888888889", distinctSitesP: "0.575757575757576", brLenTotal: "1.3556" },
  { geneTranscript: "NM_001084392.3", parsimonySitesP: "0.324858757062147", constantSitesP: "0.429378531073446", distinctSitesP: "0.567796610169492", brLenTotal: "1.3474" },
  { geneTranscript: "NM_002718.5", parsimonySitesP: "0.179130434782609", constantSitesP: "0.688695652173913", distinctSitesP: "0.263188405797101", brLenTotal: "0.5639" },
  { geneTranscript: "NM_000031.6", parsimonySitesP: "0.203030303030303", constantSitesP: "0.701010101010101", distinctSitesP: "0.264646464646465", brLenTotal: "0.7564" },
  { geneTranscript: "NM_001527.4", parsimonySitesP: "0.101775956284153", constantSitesP: "0.799180327868852", distinctSitesP: "0.174180327868852", brLenTotal: "0.4080" },
  { geneTranscript: "NM_018295.5", parsimonySitesP: "0.354954954954955", constantSitesP: "0.455855855855856", distinctSitesP: "0.54954954954955", brLenTotal: "1.3567" },
  { geneTranscript: "NM_145799.4", parsimonySitesP: "0.188134270101483", constantSitesP: "0.742388758782201", distinctSitesP: "0.202185792349727", brLenTotal: "0.5661" },
  { geneTranscript: "NM_194291.3", parsimonySitesP: "0.119444444444444", constantSitesP: "0.740277777777778", distinctSitesP: "0.259722222222222", brLenTotal: "0.4541" },
  { geneTranscript: "NM_023003.5", parsimonySitesP: "0.14954954954955", constantSitesP: "0.744144144144144", distinctSitesP: "0.233333333333333", brLenTotal: "0.4740" },
  { geneTranscript: "NM_004930.5", parsimonySitesP: "0.106617647058824", constantSitesP: "0.814950980392157", distinctSitesP: "0.169117647058824", brLenTotal: "0.4023" },
  { geneTranscript: "NM_153354.5", parsimonySitesP: "0.115674195756331", constantSitesP: "0.772758384668036", distinctSitesP: "0.203285420944559", brLenTotal: "0.3649" },
  { geneTranscript: "NM_014683.4", parsimonySitesP: "0.170527670527671", constantSitesP: "0.723616473616474", distinctSitesP: "0.235521235521236", brLenTotal: "0.5780" },
  { geneTranscript: "NM_001198671.2", parsimonySitesP: "0.148299319727891", constantSitesP: "0.733333333333333", distinctSitesP: "0.225850340136054", brLenTotal: "0.5297" },
  { geneTranscript: "NM_016178.2", parsimonySitesP: "0.230671736375158", constantSitesP: "0.593155893536122", distinctSitesP: "0.396704689480355", brLenTotal: "0.7912" },
  { geneTranscript: "NM_000318.3", parsimonySitesP: "0.20983606557377", constantSitesP: "0.654644808743169", distinctSitesP: "0.297267759562842", brLenTotal: "0.6412" },
  { geneTranscript: "NM_002850.4", parsimonySitesP: "0.197125256673511", constantSitesP: "0.706023271731691", distinctSitesP: "0.22741273100616", brLenTotal: "1.0252" },
  { geneTranscript: "NM_021126.8", parsimonySitesP: "0.279705573080967", constantSitesP: "0.598317560462671", distinctSitesP: "0.359621451104101", brLenTotal: "1.0843" },
  { geneTranscript: "NM_024014.4", parsimonySitesP: "0.133047210300429", constantSitesP: "0.804005722460658", distinctSitesP: "0.200286123032904", brLenTotal: "0.6734" },
  { geneTranscript: "NM_001079827.2", parsimonySitesP: "0.219827586206897", constantSitesP: "0.612068965517241", distinctSitesP: "0.327586206896552", brLenTotal: "1.0604" },
  { geneTranscript: "NM_002133.3", parsimonySitesP: "0.247685185185185", constantSitesP: "0.606481481481481", distinctSitesP: "0.378472222222222", brLenTotal: "1.0455" },
  { geneTranscript: "NM_000738.3", parsimonySitesP: "0.134057971014493", constantSitesP: "0.791304347826087", distinctSitesP: "0.177536231884058", brLenTotal: "0.5465" },
  { geneTranscript: "NM_001384369.1", parsimonySitesP: "0.201017811704835", constantSitesP: "0.66412213740458", distinctSitesP: "0.290076335877863", brLenTotal: "0.7149" },
  { geneTranscript: "NM_003394.4", parsimonySitesP: "0.154241645244216", constantSitesP: "0.737789203084833", distinctSitesP: "0.223650385604113", brLenTotal: "0.4806" },
  { geneTranscript: "NM_015187.5", parsimonySitesP: "0.206713780918728", constantSitesP: "0.669022379269729", distinctSitesP: "0.284157832744405", brLenTotal: "0.8046" },
  { geneTranscript: "NM_021176.3", parsimonySitesP: "0.235680751173709", constantSitesP: "0.553051643192488", distinctSitesP: "0.436619718309859", brLenTotal: "0.8558" },
  { geneTranscript: "NM_020474.4", parsimonySitesP: "0.141323792486583", constantSitesP: "0.698270721526535", distinctSitesP: "0.240906380441264", brLenTotal: "0.5785" },
  { geneTranscript: "NM_020216.4", parsimonySitesP: "0.219487179487179", constantSitesP: "0.65025641025641", distinctSitesP: "0.305641025641026", brLenTotal: "0.8391" },
  { geneTranscript: "NM_001666.5", parsimonySitesP: "0.261451726568006", constantSitesP: "0.611346018322763", distinctSitesP: "0.343199436222692", brLenTotal: "1.1227" },
  { geneTranscript: "NM_001040151.2", parsimonySitesP: "0.137984496124031", constantSitesP: "0.772093023255814", distinctSitesP: "0.231007751937984", brLenTotal: "0.6122" },
  { geneTranscript: "NM_004979.6", parsimonySitesP: "0.175167439464194", constantSitesP: "0.70994332818135", distinctSitesP: "0.218444100978877", brLenTotal: "0.7629" },
  { geneTranscript: "NM_207343.4", parsimonySitesP: "0.136557610241821", constantSitesP: "0.738738738738739", distinctSitesP: "0.20199146514936", brLenTotal: "0.4393" },
  { geneTranscript: "NM_006010.6", parsimonySitesP: "0.141025641025641", constantSitesP: "0.732600732600733", distinctSitesP: "0.247252747252747", brLenTotal: "0.5683" },
  { geneTranscript: "NM_013242.3", parsimonySitesP: "0.14853195164076", constantSitesP: "0.758203799654577", distinctSitesP: "0.229706390328152", brLenTotal: "0.5008" },
  { geneTranscript: "NM_004863.4", parsimonySitesP: "0.15717674970344", constantSitesP: "0.720640569395018", distinctSitesP: "0.235468564650059", brLenTotal: "0.5872" },
  { geneTranscript: "NM_013261.5", parsimonySitesP: "0.105680868838764", constantSitesP: "0.80952380952381", distinctSitesP: "0.149958228905597", brLenTotal: "0.3271" },
  { geneTranscript: "NM_001242809.2", parsimonySitesP: "0.202659330582302", constantSitesP: "0.663915635029803", distinctSitesP: "0.291609353507565", brLenTotal: "0.7346" },
  { geneTranscript: "NM_001105079.3", parsimonySitesP: "0.102380952380952", constantSitesP: "0.82891156462585", distinctSitesP: "0.153401360544218", brLenTotal: "0.4370" },
  { geneTranscript: "NM_025015.3", parsimonySitesP: "0.161975308641975", constantSitesP: "0.757037037037037", distinctSitesP: "0.202962962962963", brLenTotal: "0.6475" },
  { geneTranscript: "NM_001004063.3", parsimonySitesP: "0.52518756698821", constantSitesP: "0.381564844587353", distinctSitesP: "0.604501607717042", brLenTotal: "2.4156" },
  { geneTranscript: "NM_001008269.3", parsimonySitesP: "0.341719077568134", constantSitesP: "0.442348008385744", distinctSitesP: "0.551362683438155", brLenTotal: "1.3308" },
  { geneTranscript: "NM_004225.3", parsimonySitesP: "0.188846641318124", constantSitesP: "0.674588086185044", distinctSitesP: "0.253485424588086", brLenTotal: "0.7529" },
  { geneTranscript: "NM_001297776.2", parsimonySitesP: "0.169257340241796", constantSitesP: "0.670120898100173", distinctSitesP: "0.283246977547496", brLenTotal: "0.6493" },
  { geneTranscript: "NM_001650.7", parsimonySitesP: "0.171310629514964", constantSitesP: "0.745098039215686", distinctSitesP: "0.238390092879257", brLenTotal: "0.6465" },
  { geneTranscript: "NM_014325.4", parsimonySitesP: "0.158227848101266", constantSitesP: "0.757383966244726", distinctSitesP: "0.210970464135021", brLenTotal: "0.6179" },
  { geneTranscript: "NM_001039771.3", parsimonySitesP: "0.195121951219512", constantSitesP: "0.717073170731707", distinctSitesP: "0.28130081300813", brLenTotal: "0.8225" },
  { geneTranscript: "NM_032683.3", parsimonySitesP: "0.241100323624595", constantSitesP: "0.629449838187702", distinctSitesP: "0.352750809061489", brLenTotal: "0.9067" },
  { geneTranscript: "NM_001123385.2", parsimonySitesP: "0.170560303893637", constantSitesP: "0.726495726495726", distinctSitesP: "0.197530864197531", brLenTotal: "0.6390" },
  { geneTranscript: "NM_198274.4", parsimonySitesP: "0.168707482993197", constantSitesP: "0.727891156462585", distinctSitesP: "0.22108843537415", brLenTotal: "0.6578" },
  { geneTranscript: "NM_030797.4", parsimonySitesP: "0.144478844169247", constantSitesP: "0.796697626418989", distinctSitesP: "0.192982456140351", brLenTotal: "0.5620" },
  { geneTranscript: "NM_182506.3", parsimonySitesP: "0.560038424591739", constantSitesP: "0.276657060518732", distinctSitesP: "0.692603266090298", brLenTotal: "2.6389" },
  { geneTranscript: "NM_006812.4", parsimonySitesP: "0.188905547226387", constantSitesP: "0.695652173913043", distinctSitesP: "0.270864567716142", brLenTotal: "0.6651" },
  { geneTranscript: "NM_144985.4", parsimonySitesP: "0.187366623986342", constantSitesP: "0.69910371318822", distinctSitesP: "0.236022193768673", brLenTotal: "0.7745" },
  { geneTranscript: "NM_030978.3", parsimonySitesP: "0.14161220043573", constantSitesP: "0.705882352941176", distinctSitesP: "0.287581699346405", brLenTotal: "0.5920" },
  { geneTranscript: "NM_139323.4", parsimonySitesP: "0.184281842818428", constantSitesP: "0.695121951219512", distinctSitesP: "0.276422764227642", brLenTotal: "0.6710" },
  { geneTranscript: "NM_022828.5", parsimonySitesP: "0.118414918414918", constantSitesP: "0.77972027972028", distinctSitesP: "0.171561771561772", brLenTotal: "0.3904" },
  { geneTranscript: "NM_017872.5", parsimonySitesP: "0.184563758389262", constantSitesP: "0.693512304250559", distinctSitesP: "0.277404921700224", brLenTotal: "0.5977" },
  { geneTranscript: "NM_002559.5", parsimonySitesP: "0.172124265323258", constantSitesP: "0.709487825356843", distinctSitesP: "0.262804366078925", brLenTotal: "0.6932" },
  { geneTranscript: "NM_016544.3", parsimonySitesP: "0.113553113553114", constantSitesP: "0.798534798534799", distinctSitesP: "0.184371184371184", brLenTotal: "0.3826" },
  { geneTranscript: "NM_001271641.2", parsimonySitesP: "0.148243359040274", constantSitesP: "0.763496143958869", distinctSitesP: "0.20222793487575", brLenTotal: "0.5095" },
  { geneTranscript: "NM_145798.3", parsimonySitesP: "0.181314330958036", constantSitesP: "0.716547901821061", distinctSitesP: "0.228820269200317", brLenTotal: "0.6959" },
  { geneTranscript: "NM_178477.5", parsimonySitesP: "0.233333333333333", constantSitesP: "0.625925925925926", distinctSitesP: "0.385185185185185", brLenTotal: "0.8000" },
  { geneTranscript: "NM_138417.3", parsimonySitesP: "0.322975517890772", constantSitesP: "0.519774011299435", distinctSitesP: "0.474576271186441", brLenTotal: "1.1354" },
  { geneTranscript: "NM_199334.5", parsimonySitesP: "0.0666666666666667", constantSitesP: "0.867479674796748", distinctSitesP: "0.10650406504065", brLenTotal: "0.2517" },
  { geneTranscript: "NM_006466.4", parsimonySitesP: "0.132911392405063", constantSitesP: "0.762658227848101", distinctSitesP: "0.206751054852321", brLenTotal: "0.4320" },
  { geneTranscript: "NM_018133.4", parsimonySitesP: "0.0716348931253611", constantSitesP: "0.836510687463894", distinctSitesP: "0.11265164644714", brLenTotal: "0.2952" },
  { geneTranscript: "NM_004357.5", parsimonySitesP: "0.226613965744401", constantSitesP: "0.658761528326746", distinctSitesP: "0.318840579710145", brLenTotal: "0.9785" },
  { geneTranscript: "NM_001001974.4", parsimonySitesP: "0.123762376237624", constantSitesP: "0.761551155115512", distinctSitesP: "0.204620462046205", brLenTotal: "0.4444" },
  { geneTranscript: "NM_016262.5", parsimonySitesP: "0.162105263157895", constantSitesP: "0.707368421052632", distinctSitesP: "0.265263157894737", brLenTotal: "0.5617" },
  { geneTranscript: "NM_001033561.2", parsimonySitesP: "0.0816733067729084", constantSitesP: "0.847941567065073", distinctSitesP: "0.116533864541833", brLenTotal: "0.2621" },
  { geneTranscript: "NM_020369.3", parsimonySitesP: "0.254350736278447", constantSitesP: "0.599062918340027", distinctSitesP: "0.379518072289157", brLenTotal: "0.8636" },
  { geneTranscript: "NM_001372078.1", parsimonySitesP: "0.157401490947817", constantSitesP: "0.70468583599574", distinctSitesP: "0.182108626198083", brLenTotal: "0.5076" },
  { geneTranscript: "NM_016049.4", parsimonySitesP: "0.173076923076923", constantSitesP: "0.692307692307692", distinctSitesP: "0.27724358974359", brLenTotal: "0.5149" },
  { geneTranscript: "NM_014408.5", parsimonySitesP: "0.0981481481481481", constantSitesP: "0.811111111111111", distinctSitesP: "0.177777777777778", brLenTotal: "0.3492" },
  { geneTranscript: "NM_023938.6", parsimonySitesP: "0.346089850249584", constantSitesP: "0.453133666112035", distinctSitesP: "0.492512479201331", brLenTotal: "1.3057" },
  { geneTranscript: "NM_001004473.2", parsimonySitesP: "0.403620873269436", constantSitesP: "0.416400425985091", distinctSitesP: "0.603833865814696", brLenTotal: "1.8873" },
  { geneTranscript: "NM_001040446.3", parsimonySitesP: "0.159303882195448", constantSitesP: "0.729138777331548", distinctSitesP: "0.224899598393574", brLenTotal: "0.5395" },
  { geneTranscript: "NM_006833.5", parsimonySitesP: "0.135575942915392", constantSitesP: "0.781855249745158", distinctSitesP: "0.208970438328236", brLenTotal: "0.4785" },
  { geneTranscript: "NM_003617.4", parsimonySitesP: "0.143646408839779", constantSitesP: "0.742173112338858", distinctSitesP: "0.246777163904236", brLenTotal: "0.4652" },
  { geneTranscript: "NM_078470.6", parsimonySitesP: "0.179674796747967", constantSitesP: "0.659349593495935", distinctSitesP: "0.304878048780488", brLenTotal: "0.7339" },
  { geneTranscript: "NM_015488.5", parsimonySitesP: "0.151515151515152", constantSitesP: "0.756709956709957", distinctSitesP: "0.218181818181818", brLenTotal: "0.5170" },
  { geneTranscript: "NM_203505.3", parsimonySitesP: "0.108575380359613", constantSitesP: "0.772475795297372", distinctSitesP: "0.177040110650069", brLenTotal: "0.3736" },
  { geneTranscript: "NM_001282544.2", parsimonySitesP: "0.302832244008715", constantSitesP: "0.514161220043573", distinctSitesP: "0.468409586056645", brLenTotal: "1.1288" },
  { geneTranscript: "NM_030819.4", parsimonySitesP: "0.214718614718615", constantSitesP: "0.63030303030303", distinctSitesP: "0.283982683982684", brLenTotal: "1.2645" },
  { geneTranscript: "NM_198085.2", parsimonySitesP: "0.224043715846995", constantSitesP: "0.582513661202186", distinctSitesP: "0.385792349726776", brLenTotal: "0.8092" },
  { geneTranscript: "NM_020826.3", parsimonySitesP: "0.200312989045383", constantSitesP: "0.693270735524257", distinctSitesP: "0.259780907668232", brLenTotal: "0.8938" },
  { geneTranscript: "NM_001050.3", parsimonySitesP: "0.168925022583559", constantSitesP: "0.633242999096658", distinctSitesP: "0.257452574525745", brLenTotal: "1.0661" },
  { geneTranscript: "NM_080626.6", parsimonySitesP: "0.224435590969456", constantSitesP: "0.610889774236388", distinctSitesP: "0.366533864541833", brLenTotal: "0.9037" },
  { geneTranscript: "NM_005189.3", parsimonySitesP: "0.283208020050125", constantSitesP: "0.583959899749373", distinctSitesP: "0.37406015037594", brLenTotal: "1.3414" },
  { geneTranscript: "NM_178123.5", parsimonySitesP: "0.113505747126437", constantSitesP: "0.80316091954023", distinctSitesP: "0.171455938697318", brLenTotal: "0.3803" },
  { geneTranscript: "NM_030949.3", parsimonySitesP: "0.16969696969697", constantSitesP: "0.707070707070707", distinctSitesP: "0.315151515151515", brLenTotal: "0.5687" },
  { geneTranscript: "NM_022363.3", parsimonySitesP: "0.233001658374793", constantSitesP: "0.701492537313433", distinctSitesP: "0.247097844112769", brLenTotal: "0.8669" },
  { geneTranscript: "NM_145290.4", parsimonySitesP: "0.202876608629826", constantSitesP: "0.677769366641433", distinctSitesP: "0.284632853898562", brLenTotal: "0.8651" },
  { geneTranscript: "NM_030939.5", parsimonySitesP: "0.0436681222707424", constantSitesP: "0.87627365356623", distinctSitesP: "0.098981077147016", brLenTotal: "0.1768" },
  { geneTranscript: "NM_004515.4", parsimonySitesP: "0.124786324786325", constantSitesP: "0.742735042735043", distinctSitesP: "0.223076923076923", brLenTotal: "0.4400" },
  { geneTranscript: "NM_001080442.3", parsimonySitesP: "0.326436781609195", constantSitesP: "0.537164750957854", distinctSitesP: "0.477394636015326", brLenTotal: "1.3984" },
  { geneTranscript: "NM_020783.4", parsimonySitesP: "0.145098039215686", constantSitesP: "0.745882352941176", distinctSitesP: "0.212549019607843", brLenTotal: "0.4496" },
  { geneTranscript: "NM_018899.6", parsimonySitesP: "0.15127441244621", constantSitesP: "0.750413770274743", distinctSitesP: "0.195630585898709", brLenTotal: "0.4860" },
  { geneTranscript: "NM_017916.3", parsimonySitesP: "0.24367816091954", constantSitesP: "0.637931034482759", distinctSitesP: "0.358620689655172", brLenTotal: "0.7774" },
  { geneTranscript: "NM_178431.1", parsimonySitesP: "0.50187265917603", constantSitesP: "0.254681647940075", distinctSitesP: "0.797752808988764", brLenTotal: "2.5461" },
  { geneTranscript: "NM_000884.3", parsimonySitesP: "0.140726329442283", constantSitesP: "0.766536964980545", distinctSitesP: "0.188067444876783", brLenTotal: "0.4867" },
  { geneTranscript: "NM_003629.4", parsimonySitesP: "0.112075198843095", constantSitesP: "0.793203181489516", distinctSitesP: "0.16413593637021", brLenTotal: "0.3435" },
  { geneTranscript: "NM_020189.6", parsimonySitesP: "0.0198019801980198", constantSitesP: "0.95049504950495", distinctSitesP: "0.066006600660066", brLenTotal: "0.0531" },
  { geneTranscript: "NM_001378778.1", parsimonySitesP: "0.187600644122383", constantSitesP: "0.670209339774557", distinctSitesP: "0.274074074074074", brLenTotal: "0.6822" },
  { geneTranscript: "NM_001025780.3", parsimonySitesP: "0.0925925925925926", constantSitesP: "0.789351851851852", distinctSitesP: "0.164351851851852", brLenTotal: "0.3642" },
  { geneTranscript: "NM_013341.5", parsimonySitesP: "0.0925925925925926", constantSitesP: "0.771043771043771", distinctSitesP: "0.2003367003367", brLenTotal: "0.3558" },
  { geneTranscript: "NM_001195129.2", parsimonySitesP: "0.24212271973466", constantSitesP: "0.600331674958541", distinctSitesP: "0.346600331674959", brLenTotal: "1.0104" },
  { geneTranscript: "NM_002798.3", parsimonySitesP: "0.179916317991632", constantSitesP: "0.695955369595537", distinctSitesP: "0.295676429567643", brLenTotal: "0.6269" },
  { geneTranscript: "NM_001242850.2", parsimonySitesP: "0.129990714948932", constantSitesP: "0.744661095636026", distinctSitesP: "0.244196843082637", brLenTotal: "0.4814" },
  { geneTranscript: "NM_014583.4", parsimonySitesP: "0.239269406392694", constantSitesP: "0.647488584474886", distinctSitesP: "0.298630136986301", brLenTotal: "0.9771" },
  { geneTranscript: "NM_001004439.2", parsimonySitesP: "0.2168911335578", constantSitesP: "0.656565656565657", distinctSitesP: "0.280022446689113", brLenTotal: "0.9351" },
  { geneTranscript: "NM_025099.6", parsimonySitesP: "0.293344289235826", constantSitesP: "0.534648041632429", distinctSitesP: "0.392769104354971", brLenTotal: "0.9798" },
  { geneTranscript: "NM_001099666.2", parsimonySitesP: "0.139303482587065", constantSitesP: "0.747927031509121", distinctSitesP: "0.220563847429519", brLenTotal: "0.4498" },
  { geneTranscript: "NM_006949.4", parsimonySitesP: "0.218100056211355", constantSitesP: "0.683530073074761", distinctSitesP: "0.281618887015177", brLenTotal: "1.0585" },
  { geneTranscript: "NM_133329.6", parsimonySitesP: "0.105504587155963", constantSitesP: "0.814984709480122", distinctSitesP: "0.159785932721713", brLenTotal: "0.3644" },
  { geneTranscript: "NM_001017.3", parsimonySitesP: "0.158940397350993", constantSitesP: "0.730684326710817", distinctSitesP: "0.278145695364238", brLenTotal: "0.5559" },
  { geneTranscript: "NM_001024383.2", parsimonySitesP: "0.173724668064291", constantSitesP: "0.721733053808526", distinctSitesP: "0.213836477987421", brLenTotal: "0.6408" },
  { geneTranscript: "NM_006898.5", parsimonySitesP: "0.135030864197531", constantSitesP: "0.733024691358025", distinctSitesP: "0.22608024691358", brLenTotal: "0.4872" },
  { geneTranscript: "NM_001307.6", parsimonySitesP: "0.183254344391785", constantSitesP: "0.671406003159558", distinctSitesP: "0.306477093206951", brLenTotal: "0.6193" },
  { geneTranscript: "NM_001896.4", parsimonySitesP: "0.0685714285714286", constantSitesP: "0.84952380952381", distinctSitesP: "0.12", brLenTotal: "0.2508" },
  { geneTranscript: "NM_003384.3", parsimonySitesP: "0.143097643097643", constantSitesP: "0.732323232323232", distinctSitesP: "0.246632996632997", brLenTotal: "0.5528" },
  { geneTranscript: "NM_001002925.1", parsimonySitesP: "0.594936708860759", constantSitesP: "0.274261603375527", distinctSitesP: "0.734177215189873", brLenTotal: "4.4240" },
  { geneTranscript: "NM_001015880.2", parsimonySitesP: "0.204092622509424", constantSitesP: "0.67635971997846", distinctSitesP: "0.296715131933226", brLenTotal: "0.7375" },
  { geneTranscript: "NM_001184880.2", parsimonySitesP: "0.159117305458769", constantSitesP: "0.767421602787456", distinctSitesP: "0.186120789779326", brLenTotal: "0.5290" },
  { geneTranscript: "NM_003740.4", parsimonySitesP: "0.213092852371409", constantSitesP: "0.675350701402806", distinctSitesP: "0.272545090180361", brLenTotal: "0.9978" },
  { geneTranscript: "NM_004205.5", parsimonySitesP: "0.17465564738292", constantSitesP: "0.703030303030303", distinctSitesP: "0.248484848484848", brLenTotal: "0.5709" },
  { geneTranscript: "NM_018559.5", parsimonySitesP: "0.182352941176471", constantSitesP: "0.659803921568627", distinctSitesP: "0.312745098039216", brLenTotal: "0.6445" },
  { geneTranscript: "NM_003344.4", parsimonySitesP: "0.0837887067395264", constantSitesP: "0.839708561020036", distinctSitesP: "0.14207650273224", brLenTotal: "0.2901" },
  { geneTranscript: "NM_014276.4", parsimonySitesP: "0.223081882656351", constantSitesP: "0.6428110896196", distinctSitesP: "0.308833010960671", brLenTotal: "0.8890" },
  { geneTranscript: "NM_001354909.2", parsimonySitesP: "0.141491395793499", constantSitesP: "0.752071383046526", distinctSitesP: "0.219247928616953", brLenTotal: "0.4951" },
  { geneTranscript: "NM_002953.4", parsimonySitesP: "0.155555555555556", constantSitesP: "0.750566893424036", distinctSitesP: "0.208163265306122", brLenTotal: "0.6822" },
  { geneTranscript: "NM_001300783.2", parsimonySitesP: "0.202850877192982", constantSitesP: "0.663377192982456", distinctSitesP: "0.299342105263158", brLenTotal: "0.7261" },
  { geneTranscript: "NM_001123376.3", parsimonySitesP: "0.255757575757576", constantSitesP: "0.574545454545455", distinctSitesP: "0.392727272727273", brLenTotal: "1.0338" },
  { geneTranscript: "NM_174975.5", parsimonySitesP: "0.188333333333333", constantSitesP: "0.653333333333333", distinctSitesP: "0.299166666666667", brLenTotal: "0.8059" },
  { geneTranscript: "NM_014278.4", parsimonySitesP: "0.128724672228844", constantSitesP: "0.757250695272149", distinctSitesP: "0.212554628526023", brLenTotal: "0.4352" },
  { geneTranscript: "NM_001318810.2", parsimonySitesP: "0.184919822586148", constantSitesP: "0.713749573524394", distinctSitesP: "0.234732173319686", brLenTotal: "0.6608" },
  { geneTranscript: "NM_001077415.3", parsimonySitesP: "0.199206349206349", constantSitesP: "0.684126984126984", distinctSitesP: "0.28015873015873", brLenTotal: "0.6868" },
  { geneTranscript: "NM_001330260.2", parsimonySitesP: "0.12979797979798", constantSitesP: "0.790740740740741", distinctSitesP: "0.146801346801347", brLenTotal: "0.4427" },
  { geneTranscript: "NM_152366.5", parsimonySitesP: "0.239732569245463", constantSitesP: "0.619866284622732", distinctSitesP: "0.320916905444126", brLenTotal: "0.7621" },
  { geneTranscript: "NM_006675.5", parsimonySitesP: "0.163179916317992", constantSitesP: "0.765690376569038", distinctSitesP: "0.237099023709902", brLenTotal: "0.6452" },
  { geneTranscript: "NM_172166.4", parsimonySitesP: "0.184652278177458", constantSitesP: "0.680655475619504", distinctSitesP: "0.252997601918465", brLenTotal: "0.6293" },
  { geneTranscript: "NM_000746.6", parsimonySitesP: "0.177954847277556", constantSitesP: "0.709827357237716", distinctSitesP: "0.258964143426295", brLenTotal: "0.9471" },
  { geneTranscript: "NM_024345.5", parsimonySitesP: "0.15742397137746", constantSitesP: "0.728085867620751", distinctSitesP: "0.227787716159809", brLenTotal: "0.4620" },
  { geneTranscript: "NM_052953.4", parsimonySitesP: "0.0913770913770914", constantSitesP: "0.833976833976834", distinctSitesP: "0.162162162162162", brLenTotal: "0.3447" },
  { geneTranscript: "NM_023009.7", parsimonySitesP: "0.283760683760684", constantSitesP: "0.338461538461538", distinctSitesP: "0.582905982905983", brLenTotal: "1.5155" },
  { geneTranscript: "NM_002817.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.754432624113475", distinctSitesP: "0.252659574468085", brLenTotal: "0.7011" },
  { geneTranscript: "NM_033161.4", parsimonySitesP: "0.16728624535316", constantSitesP: "0.747211895910781", distinctSitesP: "0.250309789343247", brLenTotal: "0.6069" },
  { geneTranscript: "NM_001284285.2", parsimonySitesP: "0.245526838966203", constantSitesP: "0.602717031146455", distinctSitesP: "0.308151093439364", brLenTotal: "0.8901" },
  { geneTranscript: "NM_001352129.2", parsimonySitesP: "0.238095238095238", constantSitesP: "0.638095238095238", distinctSitesP: "0.428571428571429", brLenTotal: "0.7129" },
  { geneTranscript: "NM_014484.5", parsimonySitesP: "0.264492753623188", constantSitesP: "0.600724637681159", distinctSitesP: "0.348550724637681", brLenTotal: "0.8633" },
  { geneTranscript: "NM_002430.3", parsimonySitesP: "0.189646464646465", constantSitesP: "0.688636363636364", distinctSitesP: "0.248989898989899", brLenTotal: "0.8253" },
  { geneTranscript: "NM_001320126.2", parsimonySitesP: "0.176063303659743", constantSitesP: "0.727992087042532", distinctSitesP: "0.25024727992087", brLenTotal: "0.7119" },
  { geneTranscript: "NM_020186.3", parsimonySitesP: "0.248", constantSitesP: "0.581333333333333", distinctSitesP: "0.389333333333333", brLenTotal: "0.8806" },
  { geneTranscript: "NM_198475.3", parsimonySitesP: "0.169491525423729", constantSitesP: "0.706214689265537", distinctSitesP: "0.247376916868442", brLenTotal: "0.7001" },
  { geneTranscript: "NM_033542.4", parsimonySitesP: "0.0961538461538462", constantSitesP: "0.822649572649573", distinctSitesP: "0.173076923076923", brLenTotal: "0.2833" },
  { geneTranscript: "NM_001002901.4", parsimonySitesP: "0.25508607198748", constantSitesP: "0.570422535211268", distinctSitesP: "0.381064162754304", brLenTotal: "0.9108" },
  { geneTranscript: "NM_018229.4", parsimonySitesP: "0.195918367346939", constantSitesP: "0.668027210884354", distinctSitesP: "0.275510204081633", brLenTotal: "0.6300" },
  { geneTranscript: "NM_001366245.2", parsimonySitesP: "0.0990159901599016", constantSitesP: "0.80319803198032", distinctSitesP: "0.15990159901599", brLenTotal: "0.3503" },
  { geneTranscript: "NM_001135608.3", parsimonySitesP: "0.134387351778656", constantSitesP: "0.762406675450154", distinctSitesP: "0.18620992534036", brLenTotal: "0.4859" },
  { geneTranscript: "NM_020379.4", parsimonySitesP: "0.175661375661376", constantSitesP: "0.710582010582011", distinctSitesP: "0.258730158730159", brLenTotal: "0.7093" },
  { geneTranscript: "NM_025083.5", parsimonySitesP: "0.162073490813648", constantSitesP: "0.718503937007874", distinctSitesP: "0.208005249343832", brLenTotal: "0.5866" },
  { geneTranscript: "NM_005733.3", parsimonySitesP: "0.175280898876404", constantSitesP: "0.693632958801498", distinctSitesP: "0.251310861423221", brLenTotal: "0.5705" },
  { geneTranscript: "NM_001321092.3", parsimonySitesP: "0.191649555099247", constantSitesP: "0.691307323750856", distinctSitesP: "0.277891854893908", brLenTotal: "0.9993" },
  { geneTranscript: "NM_015672.2", parsimonySitesP: "0.387227984543421", constantSitesP: "0.392515761643278", distinctSitesP: "0.51189749847468", brLenTotal: "1.4333" },
  { geneTranscript: "NM_032174.6", parsimonySitesP: "0.111471861471861", constantSitesP: "0.804112554112554", distinctSitesP: "0.167748917748918", brLenTotal: "0.3701" },
  { geneTranscript: "NM_016304.3", parsimonySitesP: "0.165644171779141", constantSitesP: "0.711656441717791", distinctSitesP: "0.321063394683027", brLenTotal: "0.5258" },
  { geneTranscript: "NM_012406.4", parsimonySitesP: "0.128589263420724", constantSitesP: "0.753225135247607", distinctSitesP: "0.1993341656263", brLenTotal: "0.4152" },
  { geneTranscript: "NM_014718.4", parsimonySitesP: "0.170850767085077", constantSitesP: "0.723152022315202", distinctSitesP: "0.217573221757322", brLenTotal: "0.7808" },
  { geneTranscript: "NM_005006.7", parsimonySitesP: "0.138927097661623", constantSitesP: "0.761118752865658", distinctSitesP: "0.198074277854195", brLenTotal: "0.4344" },
  { geneTranscript: "NM_005981.5", parsimonySitesP: "0.103174603174603", constantSitesP: "0.766666666666667", distinctSitesP: "0.2", brLenTotal: "0.3475" },
  { geneTranscript: "NM_198291.3", parsimonySitesP: "0.188432835820896", constantSitesP: "0.712686567164179", distinctSitesP: "0.246268656716418", brLenTotal: "0.9386" },
  { geneTranscript: "NM_004371.4", parsimonySitesP: "0.132080610021786", constantSitesP: "0.770152505446623", distinctSitesP: "0.168028322440087", brLenTotal: "0.4438" },
  { geneTranscript: "NM_033201.3", parsimonySitesP: "0.127450980392157", constantSitesP: "0.777777777777778", distinctSitesP: "0.207516339869281", brLenTotal: "0.4567" },
  { geneTranscript: "NM_001001958.1", parsimonySitesP: "0.560897435897436", constantSitesP: "0.294871794871795", distinctSitesP: "0.695512820512821", brLenTotal: "4.1308" },
  { geneTranscript: "NM_001651.4", parsimonySitesP: "0.2", constantSitesP: "0.70440251572327", distinctSitesP: "0.269182389937107", brLenTotal: "0.7444" },
  { geneTranscript: "NM_001135812.2", parsimonySitesP: "0.135746606334842", constantSitesP: "0.663650075414781", distinctSitesP: "0.297134238310709", brLenTotal: "0.5817" },
  { geneTranscript: "NM_001394334.1", parsimonySitesP: "0.183098591549296", constantSitesP: "0.60093896713615", distinctSitesP: "0.413145539906103", brLenTotal: "0.7552" },
  { geneTranscript: "NM_001375405.1", parsimonySitesP: "0.16632860040568", constantSitesP: "0.708586883029074", distinctSitesP: "0.252197430696416", brLenTotal: "0.5741" },
  { geneTranscript: "NM_013305.6", parsimonySitesP: "0.138297872340426", constantSitesP: "0.735815602836879", distinctSitesP: "0.207446808510638", brLenTotal: "0.6382" },
  { geneTranscript: "NM_001184940.2", parsimonySitesP: "0.0918918918918919", constantSitesP: "0.836036036036036", distinctSitesP: "0.163963963963964", brLenTotal: "0.2698" },
  { geneTranscript: "NM_182828.4", parsimonySitesP: "0.282962962962963", constantSitesP: "0.565185185185185", distinctSitesP: "0.399259259259259", brLenTotal: "1.0838" },
  { geneTranscript: "NM_014751.6", parsimonySitesP: "0.139072847682119", constantSitesP: "0.74878587196468", distinctSitesP: "0.19205298013245", brLenTotal: "0.6521" },
  { geneTranscript: "NM_018646.6", parsimonySitesP: "0.231808278867102", constantSitesP: "0.638779956427015", distinctSitesP: "0.32723311546841", brLenTotal: "0.9868" },
  { geneTranscript: "NM_001368128.3", parsimonySitesP: "0.300813008130081", constantSitesP: "0.416666666666667", distinctSitesP: "0.532520325203252", brLenTotal: "1.7399" },
  { geneTranscript: "NM_001323682.2", parsimonySitesP: "0.290849673202614", constantSitesP: "0.565359477124183", distinctSitesP: "0.415032679738562", brLenTotal: "1.0875" },
  { geneTranscript: "NM_001077619.2", parsimonySitesP: "0.175226586102719", constantSitesP: "0.70392749244713", distinctSitesP: "0.27693856998993", brLenTotal: "0.5613" },
  { geneTranscript: "NM_022725.4", parsimonySitesP: "0.367201426024955", constantSitesP: "0.441176470588235", distinctSitesP: "0.50891265597148", brLenTotal: "1.3191" },
  { geneTranscript: "NM_016050.5", parsimonySitesP: "0.246527777777778", constantSitesP: "0.647569444444444", distinctSitesP: "0.338541666666667", brLenTotal: "0.7400" },
  { geneTranscript: "NM_015295.3", parsimonySitesP: "0.143308395677473", constantSitesP: "0.744139650872818", distinctSitesP: "0.20831255195345", brLenTotal: "0.4740" },
  { geneTranscript: "NM_001358699.2", parsimonySitesP: "0.3", constantSitesP: "0.533333333333333", distinctSitesP: "0.466666666666667", brLenTotal: "1.1116" },
  { geneTranscript: "NM_025149.6", parsimonySitesP: "0.245528455284553", constantSitesP: "0.613008130081301", distinctSitesP: "0.344715447154472", brLenTotal: "0.9345" },
  { geneTranscript: "NM_001111319.3", parsimonySitesP: "0.277272727272727", constantSitesP: "0.559090909090909", distinctSitesP: "0.440909090909091", brLenTotal: "1.0471" },
  { geneTranscript: "NM_013355.5", parsimonySitesP: "0.247094113235846", constantSitesP: "0.622422197225347", distinctSitesP: "0.313085864266967", brLenTotal: "1.0262" },
  { geneTranscript: "NM_001379659.1", parsimonySitesP: "0.215686274509804", constantSitesP: "0.63275039745628", distinctSitesP: "0.2471294824236", brLenTotal: "0.7372" },
  { geneTranscript: "NM_016320.5", parsimonySitesP: "0.157592592592593", constantSitesP: "0.723148148148148", distinctSitesP: "0.202962962962963", brLenTotal: "0.4982" },
  { geneTranscript: "NM_022735.4", parsimonySitesP: "0.162247474747475", constantSitesP: "0.732954545454545", distinctSitesP: "0.224747474747475", brLenTotal: "0.4743" },
  { geneTranscript: "NM_001001669.3", parsimonySitesP: "0.264197530864198", constantSitesP: "0.558024691358025", distinctSitesP: "0.392098765432099", brLenTotal: "1.0652" },
  { geneTranscript: "NM_000245.4", parsimonySitesP: "0.188729016786571", constantSitesP: "0.700719424460432", distinctSitesP: "0.243405275779376", brLenTotal: "0.6664" },
  { geneTranscript: "NM_001366521.1", parsimonySitesP: "0.0844262295081967", constantSitesP: "0.834153005464481", distinctSitesP: "0.116939890710383", brLenTotal: "0.2940" },
  { geneTranscript: "NM_004232.4", parsimonySitesP: "0.261682242990654", constantSitesP: "0.566355140186916", distinctSitesP: "0.357009345794393", brLenTotal: "1.0752" },
  { geneTranscript: "NM_024684.4", parsimonySitesP: "0.147540983606557", constantSitesP: "0.726775956284153", distinctSitesP: "0.262295081967213", brLenTotal: "0.4988" },
  { geneTranscript: "NM_004320.6", parsimonySitesP: "0.192488262910798", constantSitesP: "0.719986586183769", distinctSitesP: "0.229040912139504", brLenTotal: "0.9649" },
  { geneTranscript: "NM_003094.4", parsimonySitesP: "0.0942028985507246", constantSitesP: "0.771739130434783", distinctSitesP: "0.297101449275362", brLenTotal: "0.3283" },
  { geneTranscript: "NM_080597.4", parsimonySitesP: "0.171228070175439", constantSitesP: "0.709122807017544", distinctSitesP: "0.255438596491228", brLenTotal: "0.5916" },
  { geneTranscript: "NM_003919.3", parsimonySitesP: "0.11441647597254", constantSitesP: "0.787948131197559", distinctSitesP: "0.175438596491228", brLenTotal: "0.4483" },
  { geneTranscript: "NM_001013642.3", parsimonySitesP: "0.237885462555066", constantSitesP: "0.641703377386197", distinctSitesP: "0.405286343612335", brLenTotal: "0.9152" },
  { geneTranscript: "NM_022731.5", parsimonySitesP: "0.116598079561043", constantSitesP: "0.794238683127572", distinctSitesP: "0.198902606310014", brLenTotal: "0.3937" },
  { geneTranscript: "NM_001168302.2", parsimonySitesP: "0.0965049556598852", constantSitesP: "0.834115805946792", distinctSitesP: "0.142931664058425", brLenTotal: "0.3172" },
  { geneTranscript: "NM_015601.4", parsimonySitesP: "0.103908484270734", constantSitesP: "0.822052748649507", distinctSitesP: "0.136638068001271", brLenTotal: "0.2997" },
  { geneTranscript: "NM_001004333.5", parsimonySitesP: "0.102040816326531", constantSitesP: "0.76530612244898", distinctSitesP: "0.244897959183673", brLenTotal: "0.3656" },
  { geneTranscript: "NM_025257.3", parsimonySitesP: "0.215962441314554", constantSitesP: "0.633333333333333", distinctSitesP: "0.31830985915493", brLenTotal: "0.8433" },
  { geneTranscript: "NM_001134407.3", parsimonySitesP: "0.186703096539162", constantSitesP: "0.727686703096539", distinctSitesP: "0.216530054644809", brLenTotal: "0.7748" },
  { geneTranscript: "NM_178861.5", parsimonySitesP: "0.368530020703934", constantSitesP: "0.457556935817805", distinctSitesP: "0.518633540372671", brLenTotal: "1.3260" },
  { geneTranscript: "NM_006795.4", parsimonySitesP: "0.194756554307116", constantSitesP: "0.714107365792759", distinctSitesP: "0.252808988764045", brLenTotal: "0.9394" },
  { geneTranscript: "NM_025228.4", parsimonySitesP: "0.210526315789474", constantSitesP: "0.595281306715064", distinctSitesP: "0.376285541439806", brLenTotal: "0.7779" },
  { geneTranscript: "NM_001145101.3", parsimonySitesP: "0.281835205992509", constantSitesP: "0.508426966292135", distinctSitesP: "0.394662921348315", brLenTotal: "0.9077" },
  { geneTranscript: "NM_014575.4", parsimonySitesP: "0.148528405201916", constantSitesP: "0.711156741957563", distinctSitesP: "0.23750855578371", brLenTotal: "0.5083" },
  { geneTranscript: "NM_001079808.6", parsimonySitesP: "0.316151202749141", constantSitesP: "0.541237113402062", distinctSitesP: "0.429553264604811", brLenTotal: "1.4709" },
  { geneTranscript: "NM_024612.5", parsimonySitesP: "0.102695763799743", constantSitesP: "0.804450149764656", distinctSitesP: "0.151048352588789", brLenTotal: "0.3310" },
  { geneTranscript: "NM_006521.6", parsimonySitesP: "0.157101449275362", constantSitesP: "0.762898550724638", distinctSitesP: "0.190144927536232", brLenTotal: "0.5603" },
  { geneTranscript: "NM_001099857.5", parsimonySitesP: "0.217183770883055", constantSitesP: "0.66030230708035", distinctSitesP: "0.287191726332538", brLenTotal: "0.8526" },
  { geneTranscript: "NM_002037.5", parsimonySitesP: "0.119801365611421", constantSitesP: "0.799503414028554", distinctSitesP: "0.163873370577281", brLenTotal: "0.4926" },
  { geneTranscript: "NM_001031716.5", parsimonySitesP: "0.133986928104575", constantSitesP: "0.727124183006536", distinctSitesP: "0.269607843137255", brLenTotal: "0.4703" },
  { geneTranscript: "NM_004323.6", parsimonySitesP: "0.279227053140097", constantSitesP: "0.541062801932367", distinctSitesP: "0.470531400966184", brLenTotal: "1.0862" },
  { geneTranscript: "NM_003076.5", parsimonySitesP: "0.0932038834951456", constantSitesP: "0.820711974110032", distinctSitesP: "0.142394822006472", brLenTotal: "0.3128" },
  { geneTranscript: "NM_001134405.2", parsimonySitesP: "0.10672514619883", constantSitesP: "0.789473684210526", distinctSitesP: "0.189327485380117", brLenTotal: "0.3209" },
  { geneTranscript: "NM_003748.4", parsimonySitesP: "0.217288336293665", constantSitesP: "0.670219064535228", distinctSitesP: "0.307282415630551", brLenTotal: "1.0842" },
  { geneTranscript: "NM_004718.4", parsimonySitesP: "0.190058479532164", constantSitesP: "0.652046783625731", distinctSitesP: "0.353801169590643", brLenTotal: "0.7351" },
  { geneTranscript: "NM_003575.4", parsimonySitesP: "0.206159960258321", constantSitesP: "0.669150521609538", distinctSitesP: "0.303030303030303", brLenTotal: "1.0275" },
  { geneTranscript: "NM_001144955.2", parsimonySitesP: "0.161184210526316", constantSitesP: "0.691885964912281", distinctSitesP: "0.302631578947368", brLenTotal: "0.5096" },
  { geneTranscript: "NM_005013.4", parsimonySitesP: "0.144444444444444", constantSitesP: "0.746825396825397", distinctSitesP: "0.232539682539683", brLenTotal: "0.4356" },
  { geneTranscript: "NM_001115016.3", parsimonySitesP: "0.14540622627183", constantSitesP: "0.74791192103265", distinctSitesP: "0.21336370539104", brLenTotal: "0.5154" },
  { geneTranscript: "NM_003026.5", parsimonySitesP: "0.151515151515152", constantSitesP: "0.761363636363636", distinctSitesP: "0.213068181818182", brLenTotal: "0.5584" },
  { geneTranscript: "NM_015884.4", parsimonySitesP: "0.119460500963391", constantSitesP: "0.779062299293513", distinctSitesP: "0.202312138728324", brLenTotal: "0.4946" },
  { geneTranscript: "NM_007362.5", parsimonySitesP: "0.126068376068376", constantSitesP: "0.739316239316239", distinctSitesP: "0.247863247863248", brLenTotal: "0.4808" },
  { geneTranscript: "NM_139199.2", parsimonySitesP: "0.163562753036437", constantSitesP: "0.729014844804318", distinctSitesP: "0.238866396761134", brLenTotal: "0.5103" },
  { geneTranscript: "NM_006098.5", parsimonySitesP: "0.17875920084122", constantSitesP: "0.726603575184017", distinctSitesP: "0.246056782334385", brLenTotal: "0.7361" },
  { geneTranscript: "NM_001348022.3", parsimonySitesP: "0.212121212121212", constantSitesP: "0.617845117845118", distinctSitesP: "0.309343434343434", brLenTotal: "0.8050" },
  { geneTranscript: "NM_001995.5", parsimonySitesP: "0.218720152817574", constantSitesP: "0.680515759312321", distinctSitesP: "0.317574021012416", brLenTotal: "0.8142" },
  { geneTranscript: "NM_031287.3", parsimonySitesP: "0.12015503875969", constantSitesP: "0.77906976744186", distinctSitesP: "0.224806201550388", brLenTotal: "0.3627" },
  { geneTranscript: "NM_018332.5", parsimonySitesP: "0.155509065550907", constantSitesP: "0.746164574616457", distinctSitesP: "0.239191073919107", brLenTotal: "0.6463" },
  { geneTranscript: "NM_001004753.2", parsimonySitesP: "0.533333333333333", constantSitesP: "0.303030303030303", distinctSitesP: "0.68989898989899", brLenTotal: "2.7127" },
  { geneTranscript: "NM_017431.4", parsimonySitesP: "0.242672119972733", constantSitesP: "0.611451942740286", distinctSitesP: "0.351738241308793", brLenTotal: "0.9032" },
  { geneTranscript: "NM_015104.3", parsimonySitesP: "0.231682146542828", constantSitesP: "0.648262813897489", distinctSitesP: "0.25421396628827", brLenTotal: "0.9403" },
  { geneTranscript: "NM_000179.3", parsimonySitesP: "0.174509803921569", constantSitesP: "0.708823529411765", distinctSitesP: "0.218382352941176", brLenTotal: "0.5396" },
  { geneTranscript: "NM_022163.4", parsimonySitesP: "0.299880525686977", constantSitesP: "0.502986857825568", distinctSitesP: "0.46594982078853", brLenTotal: "1.0256" },
  { geneTranscript: "NM_207037.2", parsimonySitesP: "0.0915958451369216", constantSitesP: "0.821529745042493", distinctSitesP: "0.147308781869688", brLenTotal: "0.2904" },
  { geneTranscript: "NM_005295.3", parsimonySitesP: "0.0715935334872979", constantSitesP: "0.851424172440339", distinctSitesP: "0.130100076982294", brLenTotal: "0.2230" },
  { geneTranscript: "NM_170746.4", parsimonySitesP: "0.240437158469945", constantSitesP: "0.565573770491803", distinctSitesP: "0.407103825136612", brLenTotal: "0.7736" },
  { geneTranscript: "NM_014797.3", parsimonySitesP: "0.202295552367288", constantSitesP: "0.660449545671927", distinctSitesP: "0.269249163079866", brLenTotal: "0.6589" },
  { geneTranscript: "NM_021198.3", parsimonySitesP: "0.151979565772669", constantSitesP: "0.762452107279693", distinctSitesP: "0.218390804597701", brLenTotal: "0.6924" },
  { geneTranscript: "NM_004484.4", parsimonySitesP: "0.127586206896552", constantSitesP: "0.783908045977011", distinctSitesP: "0.195977011494253", brLenTotal: "0.4212" },
  { geneTranscript: "NM_012238.5", parsimonySitesP: "0.15975011155734", constantSitesP: "0.722445336903168", distinctSitesP: "0.256581883087907", brLenTotal: "0.4912" },
  { geneTranscript: "NM_001320329.2", parsimonySitesP: "0.15633423180593", constantSitesP: "0.761904761904762", distinctSitesP: "0.21832884097035", brLenTotal: "0.5311" },
  { geneTranscript: "NM_030961.3", parsimonySitesP: "0.2719646799117", constantSitesP: "0.59205298013245", distinctSitesP: "0.339514348785872", brLenTotal: "1.2871" },
  { geneTranscript: "NM_020988.3", parsimonySitesP: "0.0885122410546139", constantSitesP: "0.84180790960452", distinctSitesP: "0.13653483992467", brLenTotal: "0.2994" },
  { geneTranscript: "NM_015902.6", parsimonySitesP: "0.102655710372752", constantSitesP: "0.819935691318328", distinctSitesP: "0.117899249732047", brLenTotal: "0.3446" },
  { geneTranscript: "NM_016094.4", parsimonySitesP: "0.175879396984925", constantSitesP: "0.701842546063652", distinctSitesP: "0.268006700167504", brLenTotal: "0.5598" },
  { geneTranscript: "NM_001394037.1", parsimonySitesP: "0.154371584699454", constantSitesP: "0.736338797814208", distinctSitesP: "0.246584699453552", brLenTotal: "0.5589" },
  { geneTranscript: "NM_014517.5", parsimonySitesP: "0.119135802469136", constantSitesP: "0.798765432098765", distinctSitesP: "0.171604938271605", brLenTotal: "0.3819" },
  { geneTranscript: "NM_020724.2", parsimonySitesP: "0.124048706240487", constantSitesP: "0.796042617960426", distinctSitesP: "0.177321156773212", brLenTotal: "0.4252" },
  { geneTranscript: "NM_004218.4", parsimonySitesP: "0.171253822629969", constantSitesP: "0.718654434250765", distinctSitesP: "0.244648318042813", brLenTotal: "1.0507" },
  { geneTranscript: "NM_006522.4", parsimonySitesP: "0.216438356164384", constantSitesP: "0.693150684931507", distinctSitesP: "0.281278538812785", brLenTotal: "0.8712" },
  { geneTranscript: "NM_001034852.3", parsimonySitesP: "0.162452107279693", constantSitesP: "0.734099616858238", distinctSitesP: "0.233716475095785", brLenTotal: "0.6186" },
  { geneTranscript: "NM_033177.4", parsimonySitesP: "0.286516853932584", constantSitesP: "0.539325842696629", distinctSitesP: "0.393258426966292", brLenTotal: "0.9123" },
  { geneTranscript: "NM_016132.5", parsimonySitesP: "0.0805555555555556", constantSitesP: "0.845555555555556", distinctSitesP: "0.131111111111111", brLenTotal: "0.2371" },
  { geneTranscript: "NM_178125.3", parsimonySitesP: "0.233401779603012", constantSitesP: "0.635181382614648", distinctSitesP: "0.335386721423682", brLenTotal: "1.0243" },
  { geneTranscript: "NM_012139.4", parsimonySitesP: "0.238719068413392", constantSitesP: "0.575691411935953", distinctSitesP: "0.374818049490539", brLenTotal: "0.8709" },
  { geneTranscript: "NM_001042536.3", parsimonySitesP: "0.169799498746867", constantSitesP: "0.699874686716792", distinctSitesP: "0.255012531328321", brLenTotal: "0.6769" },
  { geneTranscript: "NM_021639.5", parsimonySitesP: "0.152601969057665", constantSitesP: "0.69620253164557", distinctSitesP: "0.244022503516174", brLenTotal: "0.4887" },
  { geneTranscript: "NM_001039960.3", parsimonySitesP: "0.159804818542238", constantSitesP: "0.741384568465996", distinctSitesP: "0.205245501677341", brLenTotal: "0.5104" },
  { geneTranscript: "NM_032227.4", parsimonySitesP: "0.0527497194163861", constantSitesP: "0.905723905723906", distinctSitesP: "0.0965207631874299", brLenTotal: "0.1471" },
  { geneTranscript: "NM_004086.3", parsimonySitesP: "0.156363636363636", constantSitesP: "0.746666666666667", distinctSitesP: "0.229090909090909", brLenTotal: "0.4976" },
  { geneTranscript: "NM_006321.4", parsimonySitesP: "0.128465179175118", constantSitesP: "0.746450304259635", distinctSitesP: "0.204868154158215", brLenTotal: "0.5031" },
  { geneTranscript: "NM_015626.10", parsimonySitesP: "0.107680126682502", constantSitesP: "0.806809184481394", distinctSitesP: "0.169437846397466", brLenTotal: "0.3384" },
  { geneTranscript: "NM_012143.4", parsimonySitesP: "0.167662285941856", constantSitesP: "0.720828355236957", distinctSitesP: "0.235364396654719", brLenTotal: "0.7437" },
  { geneTranscript: "NM_145171.4", parsimonySitesP: "0.207692307692308", constantSitesP: "0.656410256410256", distinctSitesP: "0.330769230769231", brLenTotal: "0.9160" },
  { geneTranscript: "NM_012225.4", parsimonySitesP: "0.275522755227552", constantSitesP: "0.592865928659287", distinctSitesP: "0.388683886838868", brLenTotal: "1.4979" },
  { geneTranscript: "NM_001031623.3", parsimonySitesP: "0.170279610430412", constantSitesP: "0.703738611372919", distinctSitesP: "0.232170907948476", brLenTotal: "0.5411" },
  { geneTranscript: "NM_001135726.3", parsimonySitesP: "0.0984126984126984", constantSitesP: "0.831746031746032", distinctSitesP: "0.150793650793651", brLenTotal: "0.3110" },
  { geneTranscript: "NM_003370.4", parsimonySitesP: "0.198245614035088", constantSitesP: "0.695614035087719", distinctSitesP: "0.3", brLenTotal: "0.8409" },
  { geneTranscript: "NM_020458.4", parsimonySitesP: "0.194250194250194", constantSitesP: "0.688422688422688", distinctSitesP: "0.243589743589744", brLenTotal: "0.7907" },
  { geneTranscript: "NM_000113.3", parsimonySitesP: "0.174698795180723", constantSitesP: "0.713855421686747", distinctSitesP: "0.240963855421687", brLenTotal: "0.6201" },
  { geneTranscript: "NM_007038.5", parsimonySitesP: "0.208243727598566", constantSitesP: "0.680286738351254", distinctSitesP: "0.265591397849462", brLenTotal: "0.7843" },
  { geneTranscript: "NM_015194.3", parsimonySitesP: "0.154738237243207", constantSitesP: "0.748508946322068", distinctSitesP: "0.211729622266402", brLenTotal: "0.5216" },
  { geneTranscript: "NM_006337.5", parsimonySitesP: "0.124819624819625", constantSitesP: "0.787157287157287", distinctSitesP: "0.171717171717172", brLenTotal: "0.4254" },
  { geneTranscript: "NM_032525.3", parsimonySitesP: "0.272795216741405", constantSitesP: "0.615844544095665", distinctSitesP: "0.346038863976084", brLenTotal: "2.1983" },
  { geneTranscript: "NM_173633.3", parsimonySitesP: "0.14604462474645", constantSitesP: "0.759296822177147", distinctSitesP: "0.203515889114266", brLenTotal: "0.5355" },
  { geneTranscript: "NM_001098616.3", parsimonySitesP: "0.122529644268775", constantSitesP: "0.761528326745718", distinctSitesP: "0.213438735177866", brLenTotal: "0.3900" },
  { geneTranscript: "NM_012310.5", parsimonySitesP: "0.179383116883117", constantSitesP: "0.673701298701299", distinctSitesP: "0.259199134199134", brLenTotal: "0.6708" },
  { geneTranscript: "NM_194251.3", parsimonySitesP: "0.237867939538584", constantSitesP: "0.569610182975338", distinctSitesP: "0.351630867143994", brLenTotal: "0.8835" },
  { geneTranscript: "NM_002254.8", parsimonySitesP: "0.147120638923918", constantSitesP: "0.754939050021017", distinctSitesP: "0.19630096679277", brLenTotal: "0.5928" },
  { geneTranscript: "NM_031886.3", parsimonySitesP: "0.219298245614035", constantSitesP: "0.641812865497076", distinctSitesP: "0.271198830409357", brLenTotal: "1.0096" },
  { geneTranscript: "NM_181532.3", parsimonySitesP: "0.333333333333333", constantSitesP: "0.520743919885551", distinctSitesP: "0.487839771101574", brLenTotal: "1.1779" },
  { geneTranscript: "NM_001199261.3", parsimonySitesP: "0.0975609756097561", constantSitesP: "0.816056910569106", distinctSitesP: "0.166666666666667", brLenTotal: "0.2989" },
  { geneTranscript: "NM_000549.5", parsimonySitesP: "0.239130434782609", constantSitesP: "0.589371980676329", distinctSitesP: "0.41304347826087", brLenTotal: "0.7229" },
  { geneTranscript: "NM_001330213.2", parsimonySitesP: "0.146448087431694", constantSitesP: "0.713661202185792", distinctSitesP: "0.246994535519126", brLenTotal: "0.5101" },
  { geneTranscript: "NM_003275.4", parsimonySitesP: "0.134633240482823", constantSitesP: "0.782729805013928", distinctSitesP: "0.195914577530176", brLenTotal: "0.6124" },
  { geneTranscript: "NM_004314.3", parsimonySitesP: "0.281345565749235", constantSitesP: "0.551478083588175", distinctSitesP: "0.411824668705403", brLenTotal: "1.0819" },
  { geneTranscript: "NM_006407.4", parsimonySitesP: "0.212765957446809", constantSitesP: "0.696808510638298", distinctSitesP: "0.276595744680851", brLenTotal: "0.7638" },
  { geneTranscript: "NM_000116.5", parsimonySitesP: "0.168949771689498", constantSitesP: "0.727168949771689", distinctSitesP: "0.262557077625571", brLenTotal: "0.6674" },
  { geneTranscript: "NM_002613.5", parsimonySitesP: "0.179856115107914", constantSitesP: "0.738009592326139", distinctSitesP: "0.245203836930456", brLenTotal: "0.7421" },
  { geneTranscript: "NM_198904.4", parsimonySitesP: "0.169824561403509", constantSitesP: "0.751578947368421", distinctSitesP: "0.229473684210526", brLenTotal: "0.5869" },
  { geneTranscript: "NM_001079866.2", parsimonySitesP: "0.154335719968178", constantSitesP: "0.739061256961018", distinctSitesP: "0.224343675417661", brLenTotal: "0.5088" },
  { geneTranscript: "NM_006823.4", parsimonySitesP: "0.0789473684210526", constantSitesP: "0.820175438596491", distinctSitesP: "0.18859649122807", brLenTotal: "0.3351" },
  { geneTranscript: "NM_001336.4", parsimonySitesP: "0.26952695269527", constantSitesP: "0.580858085808581", distinctSitesP: "0.435643564356436", brLenTotal: "1.1103" },
  { geneTranscript: "NM_002126.5", parsimonySitesP: "0.0915254237288136", constantSitesP: "0.816949152542373", distinctSitesP: "0.159322033898305", brLenTotal: "0.3904" },
  { geneTranscript: "NM_003106.4", parsimonySitesP: "0.196635120925342", constantSitesP: "0.638275499474238", distinctSitesP: "0.294426919032597", brLenTotal: "0.8480" },
  { geneTranscript: "NM_032272.5", parsimonySitesP: "0.196614583333333", constantSitesP: "0.697916666666667", distinctSitesP: "0.298177083333333", brLenTotal: "0.6968" },
  { geneTranscript: "NM_012398.3", parsimonySitesP: "0.218063872255489", constantSitesP: "0.692115768463074", distinctSitesP: "0.291916167664671", brLenTotal: "1.2675" },
  { geneTranscript: "NM_004995.4", parsimonySitesP: "0.174684994272623", constantSitesP: "0.734249713631157", distinctSitesP: "0.219358533791523", brLenTotal: "0.6178" },
  { geneTranscript: "NM_020198.3", parsimonySitesP: "0.126293995859213", constantSitesP: "0.783298826777088", distinctSitesP: "0.186335403726708", brLenTotal: "0.3577" },
  { geneTranscript: "NM_014229.3", parsimonySitesP: "0.168776371308017", constantSitesP: "0.729430379746835", distinctSitesP: "0.244198312236287", brLenTotal: "0.6483" },
  { geneTranscript: "NM_003506.4", parsimonySitesP: "0.152502360717658", constantSitesP: "0.692634560906516", distinctSitesP: "0.226628895184136", brLenTotal: "0.5565" },
  { geneTranscript: "NM_005613.6", parsimonySitesP: "0.157723577235772", constantSitesP: "0.738211382113821", distinctSitesP: "0.273170731707317", brLenTotal: "0.5101" },
  { geneTranscript: "NM_203499.3", parsimonySitesP: "0.138237384506041", constantSitesP: "0.76226012793177", distinctSitesP: "0.183013503909026", brLenTotal: "0.4536" },
  { geneTranscript: "NM_024100.4", parsimonySitesP: "0.227623456790123", constantSitesP: "0.653549382716049", distinctSitesP: "0.323302469135802", brLenTotal: "1.1239" },
  { geneTranscript: "NM_001619.5", parsimonySitesP: "0.143202709240445", constantSitesP: "0.777455249153362", distinctSitesP: "0.188679245283019", brLenTotal: "0.7076" },
  { geneTranscript: "NM_001100121.2", parsimonySitesP: "0.181263616557734", constantSitesP: "0.691938997821351", distinctSitesP: "0.244880174291939", brLenTotal: "0.6844" },
  { geneTranscript: "NM_003251.4", parsimonySitesP: "0.296803652968037", constantSitesP: "0.531963470319635", distinctSitesP: "0.440639269406393", brLenTotal: "1.1044" },
  { geneTranscript: "NM_001365951.3", parsimonySitesP: "0.139500734214391", constantSitesP: "0.772026431718062", distinctSitesP: "0.163913362701909", brLenTotal: "0.4566" },
  { geneTranscript: "NM_012279.4", parsimonySitesP: "0.187074829931973", constantSitesP: "0.6859410430839", distinctSitesP: "0.299319727891156", brLenTotal: "0.6539" },
  { geneTranscript: "NM_001242908.2", parsimonySitesP: "0.183776932826362", constantSitesP: "0.680608365019011", distinctSitesP: "0.296577946768061", brLenTotal: "0.7455" },
  { geneTranscript: "NM_182649.2", parsimonySitesP: "0.148148148148148", constantSitesP: "0.730523627075351", distinctSitesP: "0.268199233716475", brLenTotal: "0.5035" },
  { geneTranscript: "NM_004563.4", parsimonySitesP: "0.174479166666667", constantSitesP: "0.716666666666667", distinctSitesP: "0.225520833333333", brLenTotal: "0.6262" },
  { geneTranscript: "NM_032901.4", parsimonySitesP: "0.263157894736842", constantSitesP: "0.538011695906433", distinctSitesP: "0.461988304093567", brLenTotal: "0.9042" },
  { geneTranscript: "NM_006574.4", parsimonySitesP: "0.197278911564626", constantSitesP: "0.637600494743352", distinctSitesP: "0.312925170068027", brLenTotal: "0.8045" },
  { geneTranscript: "NM_005870.5", parsimonySitesP: "0.170542635658915", constantSitesP: "0.653100775193798", distinctSitesP: "0.300387596899225", brLenTotal: "0.7483" },
  { geneTranscript: "NM_001284401.2", parsimonySitesP: "0.194856577645895", constantSitesP: "0.675568743818002", distinctSitesP: "0.34322453016815", brLenTotal: "0.6876" },
  { geneTranscript: "NM_004457.5", parsimonySitesP: "0.15787037037037", constantSitesP: "0.724074074074074", distinctSitesP: "0.237037037037037", brLenTotal: "0.5325" },
  { geneTranscript: "NM_002023.5", parsimonySitesP: "0.167553191489362", constantSitesP: "0.735815602836879", distinctSitesP: "0.232269503546099", brLenTotal: "0.6706" },
  { geneTranscript: "NM_000330.4", parsimonySitesP: "0.174107142857143", constantSitesP: "0.744047619047619", distinctSitesP: "0.248511904761905", brLenTotal: "0.5827" },
  { geneTranscript: "NM_012286.3", parsimonySitesP: "0.475694444444444", constantSitesP: "0.3125", distinctSitesP: "0.659722222222222", brLenTotal: "1.9764" },
  { geneTranscript: "NM_194298.3", parsimonySitesP: "0.180091683038638", constantSitesP: "0.686313032089064", distinctSitesP: "0.256712508185986", brLenTotal: "0.6280" },
  { geneTranscript: "NM_014141.6", parsimonySitesP: "0.206110693713999", constantSitesP: "0.698221888304533", distinctSitesP: "0.273729025795141", brLenTotal: "0.8561" },
  { geneTranscript: "NM_001017964.2", parsimonySitesP: "0.244582043343653", constantSitesP: "0.604747162022704", distinctSitesP: "0.352941176470588", brLenTotal: "0.9502" },
  { geneTranscript: "NM_003818.4", parsimonySitesP: "0.147565543071161", constantSitesP: "0.759550561797753", distinctSitesP: "0.209737827715356", brLenTotal: "0.5368" },
  { geneTranscript: "NM_006804.4", parsimonySitesP: "0.20749063670412", constantSitesP: "0.684644194756554", distinctSitesP: "0.295880149812734", brLenTotal: "0.7722" },
  { geneTranscript: "NM_005946.3", parsimonySitesP: "0.284153005464481", constantSitesP: "0.573770491803279", distinctSitesP: "0.469945355191257", brLenTotal: "1.4934" },
  { geneTranscript: "NM_020702.5", parsimonySitesP: "0.245564892623716", constantSitesP: "0.661531279178338", distinctSitesP: "0.287114845938375", brLenTotal: "0.9384" },
  { geneTranscript: "NM_145272.4", parsimonySitesP: "0.35632183908046", constantSitesP: "0.413793103448276", distinctSitesP: "0.603448275862069", brLenTotal: "1.3450" },
  { geneTranscript: "NM_006503.4", parsimonySitesP: "0.155502392344498", constantSitesP: "0.767942583732057", distinctSitesP: "0.203349282296651", brLenTotal: "0.6458" },
  { geneTranscript: "NM_003395.4", parsimonySitesP: "0.215525114155251", constantSitesP: "0.620091324200913", distinctSitesP: "0.285844748858447", brLenTotal: "1.6192" },
  { geneTranscript: "NM_015397.4", parsimonySitesP: "0.135393671817513", constantSitesP: "0.782192788815305", distinctSitesP: "0.192788815305372", brLenTotal: "0.3782" },
  { geneTranscript: "NM_012405.4", parsimonySitesP: "0.14906103286385", constantSitesP: "0.742957746478873", distinctSitesP: "0.240610328638498", brLenTotal: "0.4998" },
  { geneTranscript: "NM_024930.3", parsimonySitesP: "0.168446026097272", constantSitesP: "0.722419928825623", distinctSitesP: "0.269276393831554", brLenTotal: "0.5559" },
  { geneTranscript: "NM_006893.3", parsimonySitesP: "0.167808219178082", constantSitesP: "0.707762557077626", distinctSitesP: "0.254566210045662", brLenTotal: "0.5403" },
  { geneTranscript: "NM_024052.5", parsimonySitesP: "0.172222222222222", constantSitesP: "0.724444444444444", distinctSitesP: "0.237777777777778", brLenTotal: "0.5203" },
  { geneTranscript: "NM_014891.7", parsimonySitesP: "0.147329650092081", constantSitesP: "0.732965009208103", distinctSitesP: "0.243093922651934", brLenTotal: "0.5846" },
  { geneTranscript: "NM_018453.4", parsimonySitesP: "0.167251461988304", constantSitesP: "0.663157894736842", distinctSitesP: "0.311111111111111", brLenTotal: "0.6325" },
  { geneTranscript: "NM_001918.5", parsimonySitesP: "0.18118948824343", constantSitesP: "0.670124481327801", distinctSitesP: "0.30359612724758", brLenTotal: "0.6345" },
  { geneTranscript: "NM_198443.2", parsimonySitesP: "0.321212121212121", constantSitesP: "0.503030303030303", distinctSitesP: "0.486868686868687", brLenTotal: "1.2234" },
  { geneTranscript: "NM_002657.3", parsimonySitesP: "0.130376344086022", constantSitesP: "0.75", distinctSitesP: "0.198924731182796", brLenTotal: "0.4901" },
  { geneTranscript: "NM_001099645.2", parsimonySitesP: "0.226775956284153", constantSitesP: "0.598360655737705", distinctSitesP: "0.377049180327869", brLenTotal: "0.7942" },
  { geneTranscript: "NM_001355479.1", parsimonySitesP: "0.284444444444444", constantSitesP: "0.585185185185185", distinctSitesP: "0.404444444444444", brLenTotal: "1.5378" },
  { geneTranscript: "NM_001305.5", parsimonySitesP: "0.349282296650718", constantSitesP: "0.601275917065391", distinctSitesP: "0.38755980861244", brLenTotal: "1.7536" },
  { geneTranscript: "NM_001353258.2", parsimonySitesP: "0.0936213991769547", constantSitesP: "0.809670781893004", distinctSitesP: "0.156378600823045", brLenTotal: "0.3572" },
  { geneTranscript: "NM_001097620.2", parsimonySitesP: "0.303470540758676", constantSitesP: "0.561743341404358", distinctSitesP: "0.412429378531073", brLenTotal: "1.5858" },
  { geneTranscript: "NM_030912.3", parsimonySitesP: "0.109497882637629", constantSitesP: "0.794313369630974", distinctSitesP: "0.168784029038113", brLenTotal: "0.4426" },
  { geneTranscript: "NM_019074.4", parsimonySitesP: "0.176155717761557", constantSitesP: "0.70705596107056", distinctSitesP: "0.227250608272506", brLenTotal: "0.6549" },
  { geneTranscript: "NM_012382.3", parsimonySitesP: "0.173027989821883", constantSitesP: "0.701017811704835", distinctSitesP: "0.276081424936387", brLenTotal: "0.5991" },
  { geneTranscript: "NM_025205.5", parsimonySitesP: "0.217228464419476", constantSitesP: "0.597378277153558", distinctSitesP: "0.400749063670412", brLenTotal: "1.0904" },
  { geneTranscript: "NM_015077.4", parsimonySitesP: "0.180018416206262", constantSitesP: "0.70902394106814", distinctSitesP: "0.224677716390424", brLenTotal: "0.6647" },
  { geneTranscript: "NM_001002002.3", parsimonySitesP: "0.160919540229885", constantSitesP: "0.731800766283525", distinctSitesP: "0.220306513409962", brLenTotal: "0.4555" },
  { geneTranscript: "NM_007131.5", parsimonySitesP: "0.329411764705882", constantSitesP: "0.471895424836601", distinctSitesP: "0.466013071895425", brLenTotal: "1.3668" },
  { geneTranscript: "NM_005593.3", parsimonySitesP: "0.189542483660131", constantSitesP: "0.688888888888889", distinctSitesP: "0.284967320261438", brLenTotal: "0.6271" },
  { geneTranscript: "NM_015024.5", parsimonySitesP: "0.116528672186446", constantSitesP: "0.793928242870285", distinctSitesP: "0.14688745783502", brLenTotal: "0.4512" },
  { geneTranscript: "NM_001329984.2", parsimonySitesP: "0.120551924473493", constantSitesP: "0.754538852578068", distinctSitesP: "0.194625998547567", brLenTotal: "0.4821" },
  { geneTranscript: "NM_003142.5", parsimonySitesP: "0.138071895424837", constantSitesP: "0.72140522875817", distinctSitesP: "0.250816993464052", brLenTotal: "0.4856" },
  { geneTranscript: "NM_015687.5", parsimonySitesP: "0.179994503984611", constantSitesP: "0.658697444352844", distinctSitesP: "0.223962627095356", brLenTotal: "0.7993" },
  { geneTranscript: "NM_002488.5", parsimonySitesP: "0.212121212121212", constantSitesP: "0.646464646464646", distinctSitesP: "0.36026936026936", brLenTotal: "0.6748" },
  { geneTranscript: "NM_012164.4", parsimonySitesP: "0.091776798825257", constantSitesP: "0.792217327459618", distinctSitesP: "0.176945668135095", brLenTotal: "0.3256" },
  { geneTranscript: "NM_021147.5", parsimonySitesP: "0.244761904761905", constantSitesP: "0.596190476190476", distinctSitesP: "0.353333333333333", brLenTotal: "0.8420" },
  { geneTranscript: "NM_003089.6", parsimonySitesP: "0.163996948893974", constantSitesP: "0.749046529366895", distinctSitesP: "0.217391304347826", brLenTotal: "0.6994" },
  { geneTranscript: "NM_019088.4", parsimonySitesP: "0.156308851224105", constantSitesP: "0.75894538606403", distinctSitesP: "0.204017576898933", brLenTotal: "0.5708" },
  { geneTranscript: "NM_021958.4", parsimonySitesP: "0.275273224043716", constantSitesP: "0.586748633879781", distinctSitesP: "0.39275956284153", brLenTotal: "1.0915" },
  { geneTranscript: "NM_022095.4", parsimonySitesP: "0.214356681569796", constantSitesP: "0.659960258320914", distinctSitesP: "0.254595131644312", brLenTotal: "0.8362" },
  { geneTranscript: "NM_002872.5", parsimonySitesP: "0.144097222222222", constantSitesP: "0.744791666666667", distinctSitesP: "0.243055555555556", brLenTotal: "0.7384" },
  { geneTranscript: "NM_006766.5", parsimonySitesP: "0.189121756487026", constantSitesP: "0.701763140385895", distinctSitesP: "0.248502994011976", brLenTotal: "0.7303" },
  { geneTranscript: "NM_002786.4", parsimonySitesP: "0.114068441064639", constantSitesP: "0.756653992395437", distinctSitesP: "0.219264892268695", brLenTotal: "0.4286" },
  { geneTranscript: "NM_004365.4", parsimonySitesP: "0.0818363273453094", constantSitesP: "0.842315369261477", distinctSitesP: "0.159680638722555", brLenTotal: "0.2748" },
  { geneTranscript: "NM_001113490.2", parsimonySitesP: "0.170971709717097", constantSitesP: "0.716482164821648", distinctSitesP: "0.232779827798278", brLenTotal: "0.5589" },
  { geneTranscript: "NM_016183.4", parsimonySitesP: "0.191073919107392", constantSitesP: "0.658298465829847", distinctSitesP: "0.343096234309623", brLenTotal: "0.7976" },
  { geneTranscript: "NM_000550.3", parsimonySitesP: "0.210428305400372", constantSitesP: "0.671011793916822", distinctSitesP: "0.309124767225326", brLenTotal: "0.7529" },
  { geneTranscript: "NM_001105581.3", parsimonySitesP: "0.248062015503876", constantSitesP: "0.626799557032115", distinctSitesP: "0.331118493909192", brLenTotal: "1.0028" },
  { geneTranscript: "NM_133625.6", parsimonySitesP: "0.194730813287514", constantSitesP: "0.683848797250859", distinctSitesP: "0.280641466208477", brLenTotal: "0.9282" },
  { geneTranscript: "NM_001525.3", parsimonySitesP: "0.172549019607843", constantSitesP: "0.68", distinctSitesP: "0.284705882352941", brLenTotal: "0.7921" },
  { geneTranscript: "NM_170707.4", parsimonySitesP: "0.161144578313253", constantSitesP: "0.758032128514056", distinctSitesP: "0.196787148594378", brLenTotal: "0.6358" },
  { geneTranscript: "NM_024721.5", parsimonySitesP: "0.168325958702065", constantSitesP: "0.744745575221239", distinctSitesP: "0.192662241887906", brLenTotal: "0.6946" },
  { geneTranscript: "NM_006154.4", parsimonySitesP: "0.138888888888889", constantSitesP: "0.751851851851852", distinctSitesP: "0.224444444444444", brLenTotal: "0.4602" },
  { geneTranscript: "NM_001170629.2", parsimonySitesP: "0.127857419604804", constantSitesP: "0.786775151749968", distinctSitesP: "0.133927418313315", brLenTotal: "0.3951" },
  { geneTranscript: "NM_004985.5", parsimonySitesP: "0.113475177304965", constantSitesP: "0.817375886524823", distinctSitesP: "0.168439716312057", brLenTotal: "0.3417" },
  { geneTranscript: "NM_003714.3", parsimonySitesP: "0.217439293598234", constantSitesP: "0.661147902869757", distinctSitesP: "0.305739514348786", brLenTotal: "0.7683" },
  { geneTranscript: "NM_005598.4", parsimonySitesP: "0.177944862155388", constantSitesP: "0.68922305764411", distinctSitesP: "0.31328320802005", brLenTotal: "0.7436" },
  { geneTranscript: "NM_001005204.1", parsimonySitesP: "0.581445523193096", constantSitesP: "0.306364617044229", distinctSitesP: "0.690399137001079", brLenTotal: "3.7785" },
  { geneTranscript: "NM_022490.4", parsimonySitesP: "0.215592680986476", constantSitesP: "0.628480509148767", distinctSitesP: "0.348448687350835", brLenTotal: "0.6949" },
  { geneTranscript: "NM_006836.2", parsimonySitesP: "0.175215275177836", constantSitesP: "0.711468863097467", distinctSitesP: "0.189442156495694", brLenTotal: "0.6926" },
  { geneTranscript: "NM_012294.5", parsimonySitesP: "0.146847867119668", constantSitesP: "0.753491883729709", distinctSitesP: "0.218573046432616", brLenTotal: "0.5106" },
  { geneTranscript: "NM_016653.3", parsimonySitesP: "0.154166666666667", constantSitesP: "0.735833333333333", distinctSitesP: "0.22875", brLenTotal: "0.5477" },
  { geneTranscript: "NM_205548.3", parsimonySitesP: "0.193236714975845", constantSitesP: "0.630434782608696", distinctSitesP: "0.330917874396135", brLenTotal: "0.6567" },
  { geneTranscript: "NM_014562.4", parsimonySitesP: "0.177966101694915", constantSitesP: "0.731638418079096", distinctSitesP: "0.235404896421846", brLenTotal: "0.6152" },
  { geneTranscript: "NM_001142305.2", parsimonySitesP: "0.182965299684543", constantSitesP: "0.726603575184017", distinctSitesP: "0.248159831756046", brLenTotal: "0.6819" },
  { geneTranscript: "NM_000602.5", parsimonySitesP: "0.28441127694859", constantSitesP: "0.57379767827529", distinctSitesP: "0.393034825870647", brLenTotal: "1.0929" },
  { geneTranscript: "NM_013442.3", parsimonySitesP: "0.152621722846442", constantSitesP: "0.716292134831461", distinctSitesP: "0.23876404494382", brLenTotal: "0.5298" },
  { geneTranscript: "NM_017793.3", parsimonySitesP: "0.182579564489112", constantSitesP: "0.701842546063652", distinctSitesP: "0.283082077051926", brLenTotal: "0.6895" },
  { geneTranscript: "NM_052868.6", parsimonySitesP: "0.216965742251223", constantSitesP: "0.682979880369766", distinctSitesP: "0.265905383360522", brLenTotal: "0.8862" },
  { geneTranscript: "NM_176870.3", parsimonySitesP: "0.218579234972678", constantSitesP: "0.60655737704918", distinctSitesP: "0.431693989071038", brLenTotal: "1.4278" },
  { geneTranscript: "NM_016075.4", parsimonySitesP: "0.119170984455959", constantSitesP: "0.768566493955095", distinctSitesP: "0.214162348877375", brLenTotal: "0.3754" },
  { geneTranscript: "NM_018667.4", parsimonySitesP: "0.213740458015267", constantSitesP: "0.679898218829517", distinctSitesP: "0.262595419847328", brLenTotal: "0.9607" },
  { geneTranscript: "NM_000238.4", parsimonySitesP: "0.193557664653437", constantSitesP: "0.716709807305148", distinctSitesP: "0.230658613747483", brLenTotal: "0.8852" },
  { geneTranscript: "NM_012121.5", parsimonySitesP: "0.285580524344569", constantSitesP: "0.602996254681648", distinctSitesP: "0.375468164794007", brLenTotal: "1.3322" },
  { geneTranscript: "NM_002691.4", parsimonySitesP: "0.218307738632942", constantSitesP: "0.687443541102078", distinctSitesP: "0.266485998193315", brLenTotal: "1.0540" },
  { geneTranscript: "NM_080701.4", parsimonySitesP: "0.305084745762712", constantSitesP: "0.562146892655367", distinctSitesP: "0.395480225988701", brLenTotal: "1.4371" },
  { geneTranscript: "NM_022090.5", parsimonySitesP: "0.144219977553311", constantSitesP: "0.721661054994388", distinctSitesP: "0.222222222222222", brLenTotal: "0.4845" },
  { geneTranscript: "NM_024067.4", parsimonySitesP: "0.193021529324425", constantSitesP: "0.723088344469191", distinctSitesP: "0.248700816629547", brLenTotal: "0.6782" },
  { geneTranscript: "NM_001005183.1", parsimonySitesP: "0.555555555555556", constantSitesP: "0.379273504273504", distinctSitesP: "0.628205128205128", brLenTotal: "4.4066" },
  { geneTranscript: "NM_178439.5", parsimonySitesP: "0.155339805825243", constantSitesP: "0.733333333333333", distinctSitesP: "0.249190938511327", brLenTotal: "0.4998" },
  { geneTranscript: "NM_006530.4", parsimonySitesP: "0.116005873715125", constantSitesP: "0.81057268722467", distinctSitesP: "0.190895741556535", brLenTotal: "0.3526" },
  { geneTranscript: "NM_032673.3", parsimonySitesP: "0.105534105534106", constantSitesP: "0.835263835263835", distinctSitesP: "0.149292149292149", brLenTotal: "0.2959" },
  { geneTranscript: "NM_001146314.2", parsimonySitesP: "0.219047619047619", constantSitesP: "0.625396825396825", distinctSitesP: "0.350793650793651", brLenTotal: "0.7631" },
  { geneTranscript: "NM_206833.4", parsimonySitesP: "0.109756097560976", constantSitesP: "0.829268292682927", distinctSitesP: "0.166666666666667", brLenTotal: "0.3561" },
  { geneTranscript: "NM_182904.5", parsimonySitesP: "0.175245098039216", constantSitesP: "0.684436274509804", distinctSitesP: "0.26531862745098", brLenTotal: "0.6153" },
  { geneTranscript: "NM_004521.3", parsimonySitesP: "0.115610938040845", constantSitesP: "0.804430598823122", distinctSitesP: "0.162339910003461", brLenTotal: "0.3626" },
  { geneTranscript: "NM_016570.3", parsimonySitesP: "0.099027409372237", constantSitesP: "0.81078691423519", distinctSitesP: "0.174182139699381", brLenTotal: "0.3585" },
  { geneTranscript: "NM_001232.4", parsimonySitesP: "0.203007518796992", constantSitesP: "0.683375104427736", distinctSitesP: "0.305764411027569", brLenTotal: "0.9301" },
  { geneTranscript: "NM_015164.4", parsimonySitesP: "0.178606476938175", constantSitesP: "0.703631010794897", distinctSitesP: "0.236506378802748", brLenTotal: "0.6986" },
  { geneTranscript: "NM_006143.3", parsimonySitesP: "0.198393574297189", constantSitesP: "0.669879518072289", distinctSitesP: "0.274698795180723", brLenTotal: "0.7064" },
  { geneTranscript: "NM_001391906.1", parsimonySitesP: "0.14503351614869", constantSitesP: "0.736339630306724", distinctSitesP: "0.182002843794434", brLenTotal: "0.4894" },
  { geneTranscript: "NM_001004736.4", parsimonySitesP: "0.494588744588745", constantSitesP: "0.311688311688312", distinctSitesP: "0.69047619047619", brLenTotal: "3.7279" },
  { geneTranscript: "NM_001005483.1", parsimonySitesP: "0.515995872033024", constantSitesP: "0.324045407636739", distinctSitesP: "0.657378740970072", brLenTotal: "3.4736" },
  { geneTranscript: "NM_016400.4", parsimonySitesP: "0.148760330578512", constantSitesP: "0.746556473829201", distinctSitesP: "0.261707988980716", brLenTotal: "0.5282" },
  { geneTranscript: "NM_012079.6", parsimonySitesP: "0.230191256830601", constantSitesP: "0.645491803278689", distinctSitesP: "0.318989071038251", brLenTotal: "0.9195" },
  { geneTranscript: "NM_198581.3", parsimonySitesP: "0.159517802074572", constantSitesP: "0.716848892626857", distinctSitesP: "0.214185590131763", brLenTotal: "0.5069" },
  { geneTranscript: "NM_003348.4", parsimonySitesP: "0.131578947368421", constantSitesP: "0.721491228070175", distinctSitesP: "0.254385964912281", brLenTotal: "0.4423" },
  { geneTranscript: "NM_001351169.2", parsimonySitesP: "0.115270350564468", constantSitesP: "0.779560308972074", distinctSitesP: "0.169934640522876", brLenTotal: "0.4896" },
  { geneTranscript: "NM_024648.3", parsimonySitesP: "0.268547544409613", constantSitesP: "0.584117032392894", distinctSitesP: "0.415882967607106", brLenTotal: "1.5227" },
  { geneTranscript: "NM_001112.4", parsimonySitesP: "0.225392296718973", constantSitesP: "0.693295292439372", distinctSitesP: "0.275320970042796", brLenTotal: "0.9558" },
  { geneTranscript: "NM_017832.4", parsimonySitesP: "0.151012891344383", constantSitesP: "0.725598526703499", distinctSitesP: "0.274401473296501", brLenTotal: "0.5443" },
  { geneTranscript: "NM_002371.4", parsimonySitesP: "0.237472766884532", constantSitesP: "0.647058823529412", distinctSitesP: "0.359477124183007", brLenTotal: "0.9260" },
  { geneTranscript: "NM_015969.3", parsimonySitesP: "0.271794871794872", constantSitesP: "0.533333333333333", distinctSitesP: "0.461538461538462", brLenTotal: "1.1639" },
  { geneTranscript: "NM_005958.4", parsimonySitesP: "0.34", constantSitesP: "0.521904761904762", distinctSitesP: "0.44952380952381", brLenTotal: "1.8259" },
  { geneTranscript: "NM_138619.4", parsimonySitesP: "0.230520977408944", constantSitesP: "0.630244352236053", distinctSitesP: "0.346242508068234", brLenTotal: "0.9187" },
  { geneTranscript: "NM_138395.4", parsimonySitesP: "0.219224283305228", constantSitesP: "0.644182124789207", distinctSitesP: "0.295671725688589", brLenTotal: "0.7023" },
  { geneTranscript: "NM_001174164.2", parsimonySitesP: "0.261772339636633", constantSitesP: "0.558027437893956", distinctSitesP: "0.364849833147942", brLenTotal: "1.0410" },
  { geneTranscript: "NM_181600.3", parsimonySitesP: "0.497916666666667", constantSitesP: "0.208333333333333", distinctSitesP: "0.8125", brLenTotal: "2.4021" },
  { geneTranscript: "NM_006623.4", parsimonySitesP: "0.223889931207004", constantSitesP: "0.621638524077548", distinctSitesP: "0.3270794246404", brLenTotal: "0.9486" },
  { geneTranscript: "NM_001142343.2", parsimonySitesP: "0.288650580875782", constantSitesP: "0.571045576407507", distinctSitesP: "0.413762287756926", brLenTotal: "1.1320" },
  { geneTranscript: "NM_002890.3", parsimonySitesP: "0.126074498567335", constantSitesP: "0.78191658707418", distinctSitesP: "0.1849729385546", brLenTotal: "0.3977" },
  { geneTranscript: "NM_003061.3", parsimonySitesP: "0.183833116036506", constantSitesP: "0.694046066927423", distinctSitesP: "0.230769230769231", brLenTotal: "0.8112" },
  { geneTranscript: "NM_001371072.1", parsimonySitesP: "0.223913043478261", constantSitesP: "0.653985507246377", distinctSitesP: "0.302898550724638", brLenTotal: "0.8523" },
  { geneTranscript: "NM_001128205.2", parsimonySitesP: "0.177573670110984", constantSitesP: "0.720627631075392", distinctSitesP: "0.262916188289323", brLenTotal: "0.7010" },
  { geneTranscript: "NM_001395010.1", parsimonySitesP: "0.155592935239697", constantSitesP: "0.755536865713485", distinctSitesP: "0.184749088870199", brLenTotal: "0.7347" },
  { geneTranscript: "NM_016366.3", parsimonySitesP: "0.18030303030303", constantSitesP: "0.701515151515152", distinctSitesP: "0.272727272727273", brLenTotal: "0.8341" },
  { geneTranscript: "NM_000303.3", parsimonySitesP: "0.204607046070461", constantSitesP: "0.693766937669377", distinctSitesP: "0.302168021680217", brLenTotal: "0.7254" },
  { geneTranscript: "NM_001354712.2", parsimonySitesP: "0.130874909616775", constantSitesP: "0.806941431670282", distinctSitesP: "0.159797541576283", brLenTotal: "0.5067" },
  { geneTranscript: "NM_178566.6", parsimonySitesP: "0.0729559748427673", constantSitesP: "0.849056603773585", distinctSitesP: "0.143396226415094", brLenTotal: "0.2457" },
  { geneTranscript: "NM_198582.4", parsimonySitesP: "0.231833910034602", constantSitesP: "0.630334486735871", distinctSitesP: "0.339677047289504", brLenTotal: "1.2608" },
  { geneTranscript: "NM_032603.5", parsimonySitesP: "0.158477202301903", constantSitesP: "0.729083665338645", distinctSitesP: "0.208056662239929", brLenTotal: "0.5815" },
  { geneTranscript: "NM_001674.4", parsimonySitesP: "0.12707182320442", constantSitesP: "0.766114180478821", distinctSitesP: "0.224677716390424", brLenTotal: "0.5442" },
  { geneTranscript: "NM_025155.3", parsimonySitesP: "0.139455782312925", constantSitesP: "0.750850340136054", distinctSitesP: "0.222789115646259", brLenTotal: "0.4418" },
  { geneTranscript: "NM_178860.5", parsimonySitesP: "0.183769282360832", constantSitesP: "0.689470154258887", distinctSitesP: "0.230717639168343", brLenTotal: "0.6481" },
  { geneTranscript: "NM_003466.4", parsimonySitesP: "0.118518518518519", constantSitesP: "0.800740740740741", distinctSitesP: "0.172592592592593", brLenTotal: "0.6348" },
  { geneTranscript: "NM_145059.3", parsimonySitesP: "0.233394833948339", constantSitesP: "0.629151291512915", distinctSitesP: "0.296432964329643", brLenTotal: "0.9525" },
  { geneTranscript: "NM_001382289.1", parsimonySitesP: "0.294573643410853", constantSitesP: "0.542635658914729", distinctSitesP: "0.449612403100775", brLenTotal: "1.0427" },
  { geneTranscript: "NM_005087.4", parsimonySitesP: "0.100912506709608", constantSitesP: "0.792270531400966", distinctSitesP: "0.185721953837896", brLenTotal: "0.3843" },
  { geneTranscript: "NM_022819.4", parsimonySitesP: "0.33175355450237", constantSitesP: "0.499210110584518", distinctSitesP: "0.510268562401264", brLenTotal: "1.3564" },
  { geneTranscript: "NM_014872.3", parsimonySitesP: "0.143771541112752", constantSitesP: "0.75726243229936", distinctSitesP: "0.191038897095027", brLenTotal: "0.4692" },
  { geneTranscript: "NM_001378026.1", parsimonySitesP: "0.158648549394051", constantSitesP: "0.718080548414739", distinctSitesP: "0.201738278859101", brLenTotal: "0.5154" },
  { geneTranscript: "NM_001318734.2", parsimonySitesP: "0.216505894962487", constantSitesP: "0.684351554126474", distinctSitesP: "0.265273311897106", brLenTotal: "0.8938" },
  { geneTranscript: "NM_001301833.4", parsimonySitesP: "0.0437710437710438", constantSitesP: "0.855218855218855", distinctSitesP: "0.121212121212121", brLenTotal: "0.2038" },
  { geneTranscript: "NM_002880.4", parsimonySitesP: "0.151234567901235", constantSitesP: "0.726851851851852", distinctSitesP: "0.211934156378601", brLenTotal: "0.6183" },
  { geneTranscript: "NM_080611.5", parsimonySitesP: "0.180141843971631", constantSitesP: "0.721985815602837", distinctSitesP: "0.270921985815603", brLenTotal: "0.5573" },
  { geneTranscript: "NM_153267.5", parsimonySitesP: "0.199708454810496", constantSitesP: "0.672497570456754", distinctSitesP: "0.288143828960155", brLenTotal: "0.6718" },
  { geneTranscript: "NM_001382358.1", parsimonySitesP: "0.581342434584755", constantSitesP: "0.167235494880546", distinctSitesP: "0.825938566552901", brLenTotal: "2.5733" },
  { geneTranscript: "NM_001013703.4", parsimonySitesP: "0.180109157064888", constantSitesP: "0.706488781079442", distinctSitesP: "0.228219122700627", brLenTotal: "0.6304" },
  { geneTranscript: "NM_002615.7", parsimonySitesP: "0.257575757575758", constantSitesP: "0.600478468899522", distinctSitesP: "0.372408293460925", brLenTotal: "0.9968" },
  { geneTranscript: "NM_001130144.3", parsimonySitesP: "0.177027372729598", constantSitesP: "0.730110002558199", distinctSitesP: "0.212586339217191", brLenTotal: "0.7345" },
  { geneTranscript: "NM_018949.3", parsimonySitesP: "0.34790059982862", constantSitesP: "0.499571550985433", distinctSitesP: "0.461868037703513", brLenTotal: "1.7492" },
  { geneTranscript: "NM_001308142.2", parsimonySitesP: "0.218986957840461", constantSitesP: "0.658780709736124", distinctSitesP: "0.285714285714286", brLenTotal: "0.7756" },
  { geneTranscript: "NM_018145.3", parsimonySitesP: "0.214184397163121", constantSitesP: "0.64113475177305", distinctSitesP: "0.304964539007092", brLenTotal: "0.7183" },
  { geneTranscript: "NM_002895.5", parsimonySitesP: "0.162297128589263", constantSitesP: "0.705992509363296", distinctSitesP: "0.23689138576779", brLenTotal: "0.5381" },
  { geneTranscript: "NM_016019.5", parsimonySitesP: "0.0901360544217687", constantSitesP: "0.819727891156463", distinctSitesP: "0.151360544217687", brLenTotal: "0.3103" },
  { geneTranscript: "NM_002015.4", parsimonySitesP: "0.188295165394402", constantSitesP: "0.629516539440204", distinctSitesP: "0.286513994910941", brLenTotal: "0.8649" },
  { geneTranscript: "NM_014665.4", parsimonySitesP: "0.237998647734956", constantSitesP: "0.661257606490872", distinctSitesP: "0.285327924273158", brLenTotal: "0.9534" },
  { geneTranscript: "NM_133372.3", parsimonySitesP: "0.17095483133219", constantSitesP: "0.724985706117782", distinctSitesP: "0.222984562607204", brLenTotal: "0.5262" },
  { geneTranscript: "NM_182565.4", parsimonySitesP: "0.152439024390244", constantSitesP: "0.707317073170732", distinctSitesP: "0.280487804878049", brLenTotal: "0.6843" },
  { geneTranscript: "NM_153635.3", parsimonySitesP: "0.121760096443641", constantSitesP: "0.78119349005425", distinctSitesP: "0.194695599758891", brLenTotal: "0.4348" },
  { geneTranscript: "NM_174983.5", parsimonySitesP: "0.254166666666667", constantSitesP: "0.618055555555556", distinctSitesP: "0.359027777777778", brLenTotal: "1.2801" },
  { geneTranscript: "NM_152667.3", parsimonySitesP: "0.229838709677419", constantSitesP: "0.646505376344086", distinctSitesP: "0.350806451612903", brLenTotal: "0.8598" },
  { geneTranscript: "NM_005732.4", parsimonySitesP: "0.149644308943089", constantSitesP: "0.742123983739837", distinctSitesP: "0.214176829268293", brLenTotal: "0.4783" },
  { geneTranscript: "NM_152437.3", parsimonySitesP: "0.219667943805875", constantSitesP: "0.573435504469987", distinctSitesP: "0.280970625798212", brLenTotal: "1.5581" },
  { geneTranscript: "NM_024080.5", parsimonySitesP: "0.1868961352657", constantSitesP: "0.705314009661836", distinctSitesP: "0.258454106280193", brLenTotal: "0.7596" },
  { geneTranscript: "NM_000621.5", parsimonySitesP: "0.166312809624912", constantSitesP: "0.734607218683652", distinctSitesP: "0.259731068648266", brLenTotal: "0.5947" },
  { geneTranscript: "NM_022036.4", parsimonySitesP: "0.206349206349206", constantSitesP: "0.678004535147392", distinctSitesP: "0.287981859410431", brLenTotal: "1.0148" },
  { geneTranscript: "NM_201596.3", parsimonySitesP: "0.159090909090909", constantSitesP: "0.747474747474747", distinctSitesP: "0.214141414141414", brLenTotal: "0.5364" },
  { geneTranscript: "NM_032302.4", parsimonySitesP: "0.226775956284153", constantSitesP: "0.672131147540984", distinctSitesP: "0.311475409836066", brLenTotal: "0.7263" },
  { geneTranscript: "NM_001912.5", parsimonySitesP: "0.295295295295295", constantSitesP: "0.548548548548549", distinctSitesP: "0.443443443443443", brLenTotal: "1.2246" },
  { geneTranscript: "NM_001320714.2", parsimonySitesP: "0.226138671308384", constantSitesP: "0.650130548302872", distinctSitesP: "0.288511749347258", brLenTotal: "0.9430" },
  { geneTranscript: "NM_016568.3", parsimonySitesP: "0.27363184079602", constantSitesP: "0.550106609808102", distinctSitesP: "0.410092395167022", brLenTotal: "1.0065" },
  { geneTranscript: "NM_015897.4", parsimonySitesP: "0.213725490196078", constantSitesP: "0.701307189542484", distinctSitesP: "0.274509803921569", brLenTotal: "1.1800" },
  { geneTranscript: "NM_173567.5", parsimonySitesP: "0.154696132596685", constantSitesP: "0.736648250460405", distinctSitesP: "0.241252302025783", brLenTotal: "0.5151" },
  { geneTranscript: "NM_004046.6", parsimonySitesP: "0.154912597950573", constantSitesP: "0.702833031946956", distinctSitesP: "0.235081374321881", brLenTotal: "0.6061" },
  { geneTranscript: "NM_001930.4", parsimonySitesP: "0.210478771454381", constantSitesP: "0.688346883468835", distinctSitesP: "0.282746160794941", brLenTotal: "0.8122" },
  { geneTranscript: "NM_001040431.3", parsimonySitesP: "0.289308176100629", constantSitesP: "0.553459119496855", distinctSitesP: "0.449685534591195", brLenTotal: "0.8639" },
  { geneTranscript: "NM_001720.5", parsimonySitesP: "0.227197346600332", constantSitesP: "0.601990049751244", distinctSitesP: "0.3681592039801", brLenTotal: "1.0921" },
  { geneTranscript: "NM_002335.4", parsimonySitesP: "0.208462332301342", constantSitesP: "0.67141382868937", distinctSitesP: "0.24953560371517", brLenTotal: "1.1601" },
  { geneTranscript: "NM_017999.5", parsimonySitesP: "0.19092039800995", constantSitesP: "0.685323383084577", distinctSitesP: "0.226990049751244", brLenTotal: "0.6164" },
  { geneTranscript: "NM_030817.3", parsimonySitesP: "0.244623655913978", constantSitesP: "0.606182795698925", distinctSitesP: "0.380376344086022", brLenTotal: "0.8961" },
  { geneTranscript: "NM_144579.3", parsimonySitesP: "0.129411764705882", constantSitesP: "0.781372549019608", distinctSitesP: "0.216666666666667", brLenTotal: "0.4547" },
  { geneTranscript: "NM_018641.5", parsimonySitesP: "0.254428341384863", constantSitesP: "0.623993558776167", distinctSitesP: "0.321256038647343", brLenTotal: "1.1734" },
  { geneTranscript: "NM_000435.3", parsimonySitesP: "0.249174206520178", constantSitesP: "0.635358322562114", distinctSitesP: "0.263679448513572", brLenTotal: "1.0777" },
  { geneTranscript: "NM_032420.5", parsimonySitesP: "0.149016437617893", constantSitesP: "0.763675559148477", distinctSitesP: "0.177849636216653", brLenTotal: "0.5758" },
  { geneTranscript: "NM_002830.4", parsimonySitesP: "0.111231101511879", constantSitesP: "0.791936645068395", distinctSitesP: "0.160187185025198", brLenTotal: "0.3427" },
  { geneTranscript: "NM_201542.5", parsimonySitesP: "0.128109452736318", constantSitesP: "0.784825870646766", distinctSitesP: "0.205223880597015", brLenTotal: "0.3963" },
  { geneTranscript: "NM_002124.4", parsimonySitesP: "0.428571428571429", constantSitesP: "0.37218045112782", distinctSitesP: "0.62280701754386", brLenTotal: "3.0367" },
  { geneTranscript: "NM_001615.4", parsimonySitesP: "0.158687943262411", constantSitesP: "0.75177304964539", distinctSitesP: "0.224290780141844", brLenTotal: "0.8984" },
  { geneTranscript: "NM_032511.4", parsimonySitesP: "0.0961695191524042", constantSitesP: "0.838630806845966", distinctSitesP: "0.144254278728606", brLenTotal: "0.3369" },
  { geneTranscript: "NM_032314.4", parsimonySitesP: "0.233435270132518", constantSitesP: "0.593272171253823", distinctSitesP: "0.380224260958206", brLenTotal: "0.7589" },
  { geneTranscript: "NM_001375584.1", parsimonySitesP: "0.085650098286998", constantSitesP: "0.822802583543948", distinctSitesP: "0.12636899747262", brLenTotal: "0.2889" },
  { geneTranscript: "NM_079420.3", parsimonySitesP: "0.151202749140893", constantSitesP: "0.752577319587629", distinctSitesP: "0.262886597938144", brLenTotal: "0.6542" },
  { geneTranscript: "NM_133267.3", parsimonySitesP: "0.211622807017544", constantSitesP: "0.62609649122807", distinctSitesP: "0.336622807017544", brLenTotal: "1.0835" },
  { geneTranscript: "NM_001080441.4", parsimonySitesP: "0.206349206349206", constantSitesP: "0.619047619047619", distinctSitesP: "0.335097001763668", brLenTotal: "0.6337" },
  { geneTranscript: "NM_012320.4", parsimonySitesP: "0.216019417475728", constantSitesP: "0.672330097087379", distinctSitesP: "0.303398058252427", brLenTotal: "0.7639" },
  { geneTranscript: "NM_152924.5", parsimonySitesP: "0.144313725490196", constantSitesP: "0.766274509803922", distinctSitesP: "0.218823529411765", brLenTotal: "0.5513" },
  { geneTranscript: "NM_001358530.2", parsimonySitesP: "0.247379454926625", constantSitesP: "0.594863731656184", distinctSitesP: "0.342767295597484", brLenTotal: "0.9177" },
  { geneTranscript: "NM_001257.5", parsimonySitesP: "0.183263207106124", constantSitesP: "0.709677419354839", distinctSitesP: "0.248246844319776", brLenTotal: "0.8113" },
  { geneTranscript: "NM_001366122.1", parsimonySitesP: "0.284807534807535", constantSitesP: "0.542792792792793", distinctSitesP: "0.362817362817363", brLenTotal: "1.2292" },
  { geneTranscript: "NM_014212.4", parsimonySitesP: "0.120614035087719", constantSitesP: "0.792763157894737", distinctSitesP: "0.174342105263158", brLenTotal: "0.3707" },
  { geneTranscript: "NM_001267052.2", parsimonySitesP: "0.174739863652673", constantSitesP: "0.669537136706136", distinctSitesP: "0.24578399712953", brLenTotal: "0.7570" },
  { geneTranscript: "NM_001142565.3", parsimonySitesP: "0.0735930735930736", constantSitesP: "0.854978354978355", distinctSitesP: "0.127705627705628", brLenTotal: "0.2457" },
  { geneTranscript: "NM_000863.3", parsimonySitesP: "0.211965811965812", constantSitesP: "0.665811965811966", distinctSitesP: "0.294871794871795", brLenTotal: "0.7483" },
  { geneTranscript: "NM_001005195.1", parsimonySitesP: "0.496774193548387", constantSitesP: "0.343010752688172", distinctSitesP: "0.641935483870968", brLenTotal: "2.8853" },
  { geneTranscript: "NM_003769.3", parsimonySitesP: "0.128205128205128", constantSitesP: "0.770739064856712", distinctSitesP: "0.197586726998492", brLenTotal: "0.3655" },
  { geneTranscript: "NM_002402.4", parsimonySitesP: "0.1681592039801", constantSitesP: "0.702487562189055", distinctSitesP: "0.266666666666667", brLenTotal: "0.6363" },
  { geneTranscript: "NM_006245.4", parsimonySitesP: "0.129568106312292", constantSitesP: "0.782392026578073", distinctSitesP: "0.181063122923588", brLenTotal: "0.4248" },
  { geneTranscript: "NM_016565.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.601532567049808", distinctSitesP: "0.413793103448276", brLenTotal: "0.8160" },
  { geneTranscript: "NM_033413.4", parsimonySitesP: "0.272066458982347", constantSitesP: "0.530633437175493", distinctSitesP: "0.448598130841121", brLenTotal: "0.9690" },
  { geneTranscript: "NM_001005566.3", parsimonySitesP: "0.454153182308522", constantSitesP: "0.390507011866235", distinctSitesP: "0.592233009708738", brLenTotal: "1.8529" },
  { geneTranscript: "NM_001409.4", parsimonySitesP: "0.315812243132165", constantSitesP: "0.525849015790612", distinctSitesP: "0.403417694138006", brLenTotal: "1.5724" },
  { geneTranscript: "NM_001370597.1", parsimonySitesP: "0.159103641456583", constantSitesP: "0.749579831932773", distinctSitesP: "0.19327731092437", brLenTotal: "0.6888" },
  { geneTranscript: "NM_152766.5", parsimonySitesP: "0.215339233038348", constantSitesP: "0.687315634218289", distinctSitesP: "0.312684365781711", brLenTotal: "0.6779" },
  { geneTranscript: "NM_054107.1", parsimonySitesP: "0.612353567625133", constantSitesP: "0.268370607028754", distinctSitesP: "0.728434504792332", brLenTotal: "4.0797" },
  { geneTranscript: "NM_025247.6", parsimonySitesP: "0.256846081208687", constantSitesP: "0.587661315706641", distinctSitesP: "0.369845766446333", brLenTotal: "0.9731" },
  { geneTranscript: "NM_001866.3", parsimonySitesP: "0.25", constantSitesP: "0.5", distinctSitesP: "0.483333333333333", brLenTotal: "1.0468" },
  { geneTranscript: "NM_001365597.4", parsimonySitesP: "0.104595336076818", constantSitesP: "0.816529492455418", distinctSitesP: "0.155006858710562", brLenTotal: "0.3305" },
  { geneTranscript: "NM_006636.4", parsimonySitesP: "0.215238095238095", constantSitesP: "0.601904761904762", distinctSitesP: "0.347619047619048", brLenTotal: "0.8598" },
  { geneTranscript: "NM_001482.3", parsimonySitesP: "0.126083530338849", constantSitesP: "0.77698975571316", distinctSitesP: "0.193065405831363", brLenTotal: "0.3863" },
  { geneTranscript: "NM_002500.5", parsimonySitesP: "0.135767790262172", constantSitesP: "0.778089887640449", distinctSitesP: "0.190074906367041", brLenTotal: "0.4606" },
  { geneTranscript: "NM_001039165.4", parsimonySitesP: "0.330128205128205", constantSitesP: "0.485042735042735", distinctSitesP: "0.477564102564103", brLenTotal: "1.7013" },
  { geneTranscript: "NM_005643.4", parsimonySitesP: "0.186413902053712", constantSitesP: "0.63349131121643", distinctSitesP: "0.350710900473934", brLenTotal: "0.6964" },
  { geneTranscript: "NM_025234.3", parsimonySitesP: "0.133333333333333", constantSitesP: "0.763934426229508", distinctSitesP: "0.218579234972678", brLenTotal: "0.4757" },
  { geneTranscript: "NM_001395252.1", parsimonySitesP: "0.510683760683761", constantSitesP: "0.34508547008547", distinctSitesP: "0.633547008547009", brLenTotal: "2.1418" },
  { geneTranscript: "NM_001145250.2", parsimonySitesP: "0.259641873278237", constantSitesP: "0.676997245179063", distinctSitesP: "0.309228650137741", brLenTotal: "1.5400" },
  { geneTranscript: "NM_001005465.2", parsimonySitesP: "0.363152289669862", constantSitesP: "0.454739084132055", distinctSitesP: "0.503727369542066", brLenTotal: "1.3275" },
  { geneTranscript: "NM_001001656.3", parsimonySitesP: "0.370488322717622", constantSitesP: "0.393842887473461", distinctSitesP: "0.574309978768577", brLenTotal: "1.5444" },
  { geneTranscript: "NM_022780.4", parsimonySitesP: "0.116794543904518", constantSitesP: "0.791133844842285", distinctSitesP: "0.170502983802217", brLenTotal: "0.4256" },
  { geneTranscript: "NM_024980.5", parsimonySitesP: "0.258706467661692", constantSitesP: "0.596019900497512", distinctSitesP: "0.372139303482587", brLenTotal: "1.0897" },
  { geneTranscript: "NM_001372066.1", parsimonySitesP: "0.0736522399392559", constantSitesP: "0.857251328777525", distinctSitesP: "0.123006833712984", brLenTotal: "0.2469" },
  { geneTranscript: "NM_053280.5", parsimonySitesP: "0.232283464566929", constantSitesP: "0.650918635170604", distinctSitesP: "0.31758530183727", brLenTotal: "0.9694" },
  { geneTranscript: "NM_001018100.5", parsimonySitesP: "0.220314735336195", constantSitesP: "0.665951359084406", distinctSitesP: "0.312589413447783", brLenTotal: "0.7733" },
  { geneTranscript: "NM_001927.4", parsimonySitesP: "0.160992907801418", constantSitesP: "0.736879432624113", distinctSitesP: "0.219858156028369", brLenTotal: "0.7121" },
  { geneTranscript: "NM_012244.4", parsimonySitesP: "0.173831775700935", constantSitesP: "0.722741433021807", distinctSitesP: "0.226791277258567", brLenTotal: "0.6085" },
  { geneTranscript: "NM_001005184.2", parsimonySitesP: "0.374085684430512", constantSitesP: "0.463949843260188", distinctSitesP: "0.526645768025078", brLenTotal: "1.5420" },
  { geneTranscript: "NM_004344.3", parsimonySitesP: "0.184108527131783", constantSitesP: "0.664728682170543", distinctSitesP: "0.323643410852713", brLenTotal: "0.7342" },
  { geneTranscript: "NM_001372043.1", parsimonySitesP: "0.22858152269917", constantSitesP: "0.636459989401166", distinctSitesP: "0.333863275039746", brLenTotal: "0.9221" },
  { geneTranscript: "NM_015702.3", parsimonySitesP: "0.175675675675676", constantSitesP: "0.627252252252252", distinctSitesP: "0.355855855855856", brLenTotal: "0.7056" },
  { geneTranscript: "NM_138355.4", parsimonySitesP: "0.234509803921569", constantSitesP: "0.615686274509804", distinctSitesP: "0.31843137254902", brLenTotal: "0.9306" },
  { geneTranscript: "NM_001033.5", parsimonySitesP: "0.131734006734007", constantSitesP: "0.783249158249158", distinctSitesP: "0.17003367003367", brLenTotal: "0.4017" },
  { geneTranscript: "NM_001009996.3", parsimonySitesP: "0.281767955801105", constantSitesP: "0.538367096378146", distinctSitesP: "0.384898710865562", brLenTotal: "0.9401" },
  { geneTranscript: "NM_018202.6", parsimonySitesP: "0.0963855421686747", constantSitesP: "0.830321285140562", distinctSitesP: "0.136044176706827", brLenTotal: "0.3520" },
  { geneTranscript: "NM_015528.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.718095238095238", distinctSitesP: "0.272380952380952", brLenTotal: "0.5600" },
  { geneTranscript: "NM_001097579.2", parsimonySitesP: "0.176727909011374", constantSitesP: "0.720909886264217", distinctSitesP: "0.256342957130359", brLenTotal: "0.5229" },
  { geneTranscript: "NM_173191.3", parsimonySitesP: "0.11358024691358", constantSitesP: "0.802469135802469", distinctSitesP: "0.160493827160494", brLenTotal: "0.4230" },
  { geneTranscript: "NM_001282597.3", parsimonySitesP: "0.138859741168241", constantSitesP: "0.782091640433718", distinctSitesP: "0.181532004197272", brLenTotal: "0.4896" },
  { geneTranscript: "NM_001008749.3", parsimonySitesP: "0.219662058371736", constantSitesP: "0.640552995391705", distinctSitesP: "0.328725038402458", brLenTotal: "0.9857" },
  { geneTranscript: "NM_033315.4", parsimonySitesP: "0.090311986863711", constantSitesP: "0.842364532019704", distinctSitesP: "0.155993431855501", brLenTotal: "0.3068" },
  { geneTranscript: "NM_002828.4", parsimonySitesP: "0.152610441767068", constantSitesP: "0.718072289156627", distinctSitesP: "0.263453815261044", brLenTotal: "0.5334" },
  { geneTranscript: "NM_005342.4", parsimonySitesP: "0.178333333333333", constantSitesP: "0.586666666666667", distinctSitesP: "0.376666666666667", brLenTotal: "0.9527" },
  { geneTranscript: "NM_002710.4", parsimonySitesP: "0.126934984520124", constantSitesP: "0.771929824561404", distinctSitesP: "0.201238390092879", brLenTotal: "0.6552" },
  { geneTranscript: "NM_019006.4", parsimonySitesP: "0.149038461538462", constantSitesP: "0.615384615384615", distinctSitesP: "0.331730769230769", brLenTotal: "0.6506" },
  { geneTranscript: "NM_001039672.3", parsimonySitesP: "0.192144373673036", constantSitesP: "0.701698513800425", distinctSitesP: "0.280254777070064", brLenTotal: "0.8455" },
  { geneTranscript: "NM_005251.3", parsimonySitesP: "0.204258150365935", constantSitesP: "0.681969394544245", distinctSitesP: "0.268795741849634", brLenTotal: "0.7632" },
  { geneTranscript: "NM_006426.3", parsimonySitesP: "0.284965034965035", constantSitesP: "0.632284382284382", distinctSitesP: "0.354312354312354", brLenTotal: "1.5141" },
  { geneTranscript: "NM_004911.5", parsimonySitesP: "0.216020671834625", constantSitesP: "0.655297157622739", distinctSitesP: "0.297674418604651", brLenTotal: "0.8843" },
  { geneTranscript: "NM_004663.5", parsimonySitesP: "0.217592592592593", constantSitesP: "0.725308641975309", distinctSitesP: "0.259259259259259", brLenTotal: "0.8015" },
  { geneTranscript: "NM_001363642.1", parsimonySitesP: "0.215252152521525", constantSitesP: "0.683886838868389", distinctSitesP: "0.290282902829028", brLenTotal: "0.7311" },
  { geneTranscript: "NM_139281.3", parsimonySitesP: "0.154934823091248", constantSitesP: "0.715456238361266", distinctSitesP: "0.235754189944134", brLenTotal: "0.5439" },
  { geneTranscript: "NM_001135106.2", parsimonySitesP: "0.218820861678005", constantSitesP: "0.604308390022676", distinctSitesP: "0.340136054421769", brLenTotal: "0.8656" },
  { geneTranscript: "NM_001365977.2", parsimonySitesP: "0.191756272401434", constantSitesP: "0.709677419354839", distinctSitesP: "0.254480286738351", brLenTotal: "0.6661" },
  { geneTranscript: "NM_006598.3", parsimonySitesP: "0.301939058171745", constantSitesP: "0.604185903354878", distinctSitesP: "0.375807940904894", brLenTotal: "1.8451" },
  { geneTranscript: "NM_001370298.3", parsimonySitesP: "0.204134366925065", constantSitesP: "0.66703580657069", distinctSitesP: "0.285714285714286", brLenTotal: "0.7188" },
  { geneTranscript: "NM_001135638.2", parsimonySitesP: "0.218861209964413", constantSitesP: "0.663107947805457", distinctSitesP: "0.306642941874259", brLenTotal: "0.8634" },
  { geneTranscript: "NM_024053.5", parsimonySitesP: "0.233333333333333", constantSitesP: "0.583333333333333", distinctSitesP: "0.396296296296296", brLenTotal: "0.8223" },
  { geneTranscript: "NM_006876.3", parsimonySitesP: "0.146987951807229", constantSitesP: "0.738955823293173", distinctSitesP: "0.21285140562249", brLenTotal: "0.5011" },
  { geneTranscript: "NM_022760.6", parsimonySitesP: "0.193098384728341", constantSitesP: "0.693098384728341", distinctSitesP: "0.258443465491924", brLenTotal: "0.6160" },
  { geneTranscript: "NM_018992.4", parsimonySitesP: "0.173789173789174", constantSitesP: "0.729344729344729", distinctSitesP: "0.256410256410256", brLenTotal: "0.8440" },
  { geneTranscript: "NM_145262.4", parsimonySitesP: "0.244741873804971", constantSitesP: "0.603569152326322", distinctSitesP: "0.322498406628426", brLenTotal: "0.8256" },
  { geneTranscript: "NM_001128215.1", parsimonySitesP: "0.203309692671395", constantSitesP: "0.660362490149724", distinctSitesP: "0.317572892040977", brLenTotal: "0.7375" },
  { geneTranscript: "NM_030916.3", parsimonySitesP: "0.184313725490196", constantSitesP: "0.711111111111111", distinctSitesP: "0.239869281045752", brLenTotal: "0.6501" },
  { geneTranscript: "NM_001114.5", parsimonySitesP: "0.259876543209877", constantSitesP: "0.62283950617284", distinctSitesP: "0.341358024691358", brLenTotal: "1.2657" },
  { geneTranscript: "NM_015311.3", parsimonySitesP: "0.225738396624473", constantSitesP: "0.637482419127989", distinctSitesP: "0.254571026722925", brLenTotal: "0.9197" },
  { geneTranscript: "NM_198057.3", parsimonySitesP: "0.193333333333333", constantSitesP: "0.705", distinctSitesP: "0.266666666666667", brLenTotal: "0.6232" },
  { geneTranscript: "NM_017567.6", parsimonySitesP: "0.171511627906977", constantSitesP: "0.708333333333333", distinctSitesP: "0.26453488372093", brLenTotal: "0.5625" },
  { geneTranscript: "NM_023915.4", parsimonySitesP: "0.209497206703911", constantSitesP: "0.676908752327747", distinctSitesP: "0.304469273743017", brLenTotal: "0.7633" },
  { geneTranscript: "NM_004774.4", parsimonySitesP: "0.109424414927261", constantSitesP: "0.786422095720008", distinctSitesP: "0.169723803499895", brLenTotal: "0.3605" },
  { geneTranscript: "NM_020642.4", parsimonySitesP: "0.287301587301587", constantSitesP: "0.512698412698413", distinctSitesP: "0.46984126984127", brLenTotal: "0.9629" },
  { geneTranscript: "NM_020177.3", parsimonySitesP: "0.0988654781199352", constantSitesP: "0.82441923284711", distinctSitesP: "0.146407347379795", brLenTotal: "0.3239" },
  { geneTranscript: "NM_001425.3", parsimonySitesP: "0.161554192229039", constantSitesP: "0.719836400817996", distinctSitesP: "0.263803680981595", brLenTotal: "0.5419" },
  { geneTranscript: "NM_021052.4", parsimonySitesP: "0.320512820512821", constantSitesP: "0.623076923076923", distinctSitesP: "0.374358974358974", brLenTotal: "2.4440" },
  { geneTranscript: "NM_032355.4", parsimonySitesP: "0.169369369369369", constantSitesP: "0.715915915915916", distinctSitesP: "0.23003003003003", brLenTotal: "0.6501" },
  { geneTranscript: "NM_182746.3", parsimonySitesP: "0.208960988798764", constantSitesP: "0.689841637697953", distinctSitesP: "0.280803398995751", brLenTotal: "0.8165" },
  { geneTranscript: "NM_003256.4", parsimonySitesP: "0.180059523809524", constantSitesP: "0.665178571428571", distinctSitesP: "0.324404761904762", brLenTotal: "0.6462" },
  { geneTranscript: "NM_005902.4", parsimonySitesP: "0.143529411764706", constantSitesP: "0.768627450980392", distinctSitesP: "0.189019607843137", brLenTotal: "0.8236" },
  { geneTranscript: "NM_020700.2", parsimonySitesP: "0.167315175097276", constantSitesP: "0.760700389105058", distinctSitesP: "0.240596627756161", brLenTotal: "0.6306" },
  { geneTranscript: "NM_007078.3", parsimonySitesP: "0.21595598349381", constantSitesP: "0.654287024300779", distinctSitesP: "0.318661164603393", brLenTotal: "0.9483" },
  { geneTranscript: "NM_024103.3", parsimonySitesP: "0.193019943019943", constantSitesP: "0.692307692307692", distinctSitesP: "0.268518518518519", brLenTotal: "0.9033" },
  { geneTranscript: "NM_001372327.1", parsimonySitesP: "0.202485380116959", constantSitesP: "0.684210526315789", distinctSitesP: "0.273391812865497", brLenTotal: "0.6852" },
  { geneTranscript: "NM_001079670.3", parsimonySitesP: "0.19188921859545", constantSitesP: "0.693372898120673", distinctSitesP: "0.279920870425321", brLenTotal: "0.7240" },
  { geneTranscript: "NM_001363871.4", parsimonySitesP: "0.158790170132325", constantSitesP: "0.738500315059861", distinctSitesP: "0.243226212980466", brLenTotal: "0.5515" },
  { geneTranscript: "NM_001205019.2", parsimonySitesP: "0.125819916517591", constantSitesP: "0.782945736434109", distinctSitesP: "0.199761478831246", brLenTotal: "0.4304" },
  { geneTranscript: "NM_001244926.2", parsimonySitesP: "0.136276391554702", constantSitesP: "0.768394113883557", distinctSitesP: "0.211132437619962", brLenTotal: "0.4447" },
  { geneTranscript: "NM_001098479.2", parsimonySitesP: "0.388386123680241", constantSitesP: "0.39894419306184", distinctSitesP: "0.555052790346908", brLenTotal: "2.5230" },
  { geneTranscript: "NM_003155.3", parsimonySitesP: "0.143049932523617", constantSitesP: "0.759784075573549", distinctSitesP: "0.225371120107962", brLenTotal: "0.5138" },
  { geneTranscript: "NM_001164277.2", parsimonySitesP: "0.168609168609169", constantSitesP: "0.709401709401709", distinctSitesP: "0.238539238539239", brLenTotal: "0.5858" },
  { geneTranscript: "NM_002393.5", parsimonySitesP: "0.160544217687075", constantSitesP: "0.694557823129252", distinctSitesP: "0.262585034013605", brLenTotal: "0.5245" },
  { geneTranscript: "NM_003630.3", parsimonySitesP: "0.109025915996425", constantSitesP: "0.795352993744415", distinctSitesP: "0.181411974977659", brLenTotal: "0.3696" },
  { geneTranscript: "NM_005775.5", parsimonySitesP: "0.271733730750124", constantSitesP: "0.57973174366617", distinctSitesP: "0.392945851962245", brLenTotal: "0.9886" },
  { geneTranscript: "NM_016299.4", parsimonySitesP: "0.16633922724296", constantSitesP: "0.717092337917485", distinctSitesP: "0.244269810085134", brLenTotal: "0.5389" },
  { geneTranscript: "NM_058216.3", parsimonySitesP: "0.179078014184397", constantSitesP: "0.675531914893617", distinctSitesP: "0.318262411347518", brLenTotal: "0.6093" },
  { geneTranscript: "NM_006096.4", parsimonySitesP: "0.16497461928934", constantSitesP: "0.738578680203046", distinctSitesP: "0.258037225042301", brLenTotal: "0.7850" },
  { geneTranscript: "NM_004350.3", parsimonySitesP: "0.177510040160643", constantSitesP: "0.681124497991968", distinctSitesP: "0.248995983935743", brLenTotal: "1.1348" },
  { geneTranscript: "NM_015130.3", parsimonySitesP: "0.167719852553976", constantSitesP: "0.719852553975777", distinctSitesP: "0.212480252764613", brLenTotal: "0.6849" },
  { geneTranscript: "NM_003238.6", parsimonySitesP: "0.138486312399356", constantSitesP: "0.755233494363929", distinctSitesP: "0.22061191626409", brLenTotal: "0.5051" },
  { geneTranscript: "NM_001552.3", parsimonySitesP: "0.170542635658915", constantSitesP: "0.731266149870801", distinctSitesP: "0.236434108527132", brLenTotal: "0.5353" },
  { geneTranscript: "NM_001199107.2", parsimonySitesP: "0.220035778175313", constantSitesP: "0.67262969588551", distinctSitesP: "0.284436493738819", brLenTotal: "1.0696" },
  { geneTranscript: "NM_000101.4", parsimonySitesP: "0.247863247863248", constantSitesP: "0.654700854700855", distinctSitesP: "0.369230769230769", brLenTotal: "1.2875" },
  { geneTranscript: "NM_000801.5", parsimonySitesP: "0.175925925925926", constantSitesP: "0.645061728395062", distinctSitesP: "0.354938271604938", brLenTotal: "0.6437" },
  { geneTranscript: "NM_138364.4", parsimonySitesP: "0.182248520710059", constantSitesP: "0.675739644970414", distinctSitesP: "0.252859960552268", brLenTotal: "0.5945" },
  { geneTranscript: "NM_003924.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.717622080679406", distinctSitesP: "0.294055201698514", brLenTotal: "0.7773" },
  { geneTranscript: "NM_014494.4", parsimonySitesP: "0.0615018688413184", constantSitesP: "0.876316683656133", distinctSitesP: "0.0924226979272851", brLenTotal: "0.2225" },
  { geneTranscript: "NM_001030060.3", parsimonySitesP: "0.15606936416185", constantSitesP: "0.703275529865125", distinctSitesP: "0.292870905587669", brLenTotal: "0.5864" },
  { geneTranscript: "NM_001174156.2", parsimonySitesP: "0.0931726907630522", constantSitesP: "0.823293172690763", distinctSitesP: "0.150200803212851", brLenTotal: "0.3023" },
  { geneTranscript: "NM_001100164.2", parsimonySitesP: "0.174677002583979", constantSitesP: "0.693023255813953", distinctSitesP: "0.291989664082687", brLenTotal: "0.6188" },
  { geneTranscript: "NM_006814.5", parsimonySitesP: "0.182041820418204", constantSitesP: "0.672816728167282", distinctSitesP: "0.292742927429274", brLenTotal: "0.5940" },
  { geneTranscript: "NM_032881.3", parsimonySitesP: "0.224932249322493", constantSitesP: "0.615176151761518", distinctSitesP: "0.344173441734417", brLenTotal: "0.9607" },
  { geneTranscript: "NM_001198.4", parsimonySitesP: "0.195151515151515", constantSitesP: "0.698181818181818", distinctSitesP: "0.251313131313131", brLenTotal: "0.7303" },
  { geneTranscript: "NM_004567.4", parsimonySitesP: "0.171286425017768", constantSitesP: "0.727078891257996", distinctSitesP: "0.240227434257285", brLenTotal: "0.6765" },
  { geneTranscript: "NM_021245.4", parsimonySitesP: "0.17056856187291", constantSitesP: "0.690078037904125", distinctSitesP: "0.279821627647715", brLenTotal: "0.5919" },
  { geneTranscript: "NM_001617.4", parsimonySitesP: "0.148760330578512", constantSitesP: "0.755280073461892", distinctSitesP: "0.210743801652893", brLenTotal: "0.7083" },
  { geneTranscript: "NM_001080779.2", parsimonySitesP: "0.204766384446535", constantSitesP: "0.701160238319222", distinctSitesP: "0.2505487613672", brLenTotal: "0.8848" },
  { geneTranscript: "NM_144994.8", parsimonySitesP: "0.244808743169399", constantSitesP: "0.59672131147541", distinctSitesP: "0.370491803278689", brLenTotal: "0.8632" },
  { geneTranscript: "NM_015253.2", parsimonySitesP: "0.242898550724638", constantSitesP: "0.622608695652174", distinctSitesP: "0.330434782608696", brLenTotal: "1.0440" },
  { geneTranscript: "NM_001278182.2", parsimonySitesP: "0.13806146572104", constantSitesP: "0.752245862884161", distinctSitesP: "0.204728132387707", brLenTotal: "0.4975" },
  { geneTranscript: "NM_001345.5", parsimonySitesP: "0.165986394557823", constantSitesP: "0.726984126984127", distinctSitesP: "0.214965986394558", brLenTotal: "0.5058" },
  { geneTranscript: "NM_001375380.1", parsimonySitesP: "0.0858505564387917", constantSitesP: "0.85691573926868", distinctSitesP: "0.122946475887652", brLenTotal: "0.3156" },
  { geneTranscript: "NM_003074.4", parsimonySitesP: "0.155656108597285", constantSitesP: "0.726696832579186", distinctSitesP: "0.234087481146305", brLenTotal: "0.5533" },
  { geneTranscript: "NM_001555.5", parsimonySitesP: "0.224301397205589", constantSitesP: "0.641217564870259", distinctSitesP: "0.274700598802395", brLenTotal: "0.6673" },
  { geneTranscript: "NM_012339.5", parsimonySitesP: "0.199546485260771", constantSitesP: "0.700680272108844", distinctSitesP: "0.286848072562358", brLenTotal: "0.8006" },
  { geneTranscript: "NM_153695.4", parsimonySitesP: "0.17047619047619", constantSitesP: "0.708571428571429", distinctSitesP: "0.280952380952381", brLenTotal: "0.7177" },
  { geneTranscript: "NM_003797.5", parsimonySitesP: "0.0710506424792139", constantSitesP: "0.860922146636432", distinctSitesP: "0.120181405895692", brLenTotal: "0.2253" },
  { geneTranscript: "NM_001308330.2", parsimonySitesP: "0.125932300631096", constantSitesP: "0.776821572002295", distinctSitesP: "0.168387837062536", brLenTotal: "0.4057" },
  { geneTranscript: "NM_014066.4", parsimonySitesP: "0.321428571428571", constantSitesP: "0.516369047619048", distinctSitesP: "0.44047619047619", brLenTotal: "1.2628" },
  { geneTranscript: "NM_173852.4", parsimonySitesP: "0.137254901960784", constantSitesP: "0.78921568627451", distinctSitesP: "0.213235294117647", brLenTotal: "0.3985" },
  { geneTranscript: "NM_152758.6", parsimonySitesP: "0.101994301994302", constantSitesP: "0.736752136752137", distinctSitesP: "0.174358974358974", brLenTotal: "0.4195" },
  { geneTranscript: "NM_001524.1", parsimonySitesP: "0.300254452926209", constantSitesP: "0.567430025445293", distinctSitesP: "0.432569974554707", brLenTotal: "1.0179" },
  { geneTranscript: "NM_003809.3", parsimonySitesP: "0.180722891566265", constantSitesP: "0.72289156626506", distinctSitesP: "0.236947791164659", brLenTotal: "0.6446" },
  { geneTranscript: "NM_001010887.3", parsimonySitesP: "0.144242424242424", constantSitesP: "0.774545454545455", distinctSitesP: "0.196363636363636", brLenTotal: "0.5458" },
  { geneTranscript: "NM_001195278.2", parsimonySitesP: "0.0839002267573696", constantSitesP: "0.841269841269841", distinctSitesP: "0.136054421768707", brLenTotal: "0.3214" },
  { geneTranscript: "NM_007062.3", parsimonySitesP: "0.156353958749168", constantSitesP: "0.726546906187625", distinctSitesP: "0.249500998003992", brLenTotal: "0.5734" },
  { geneTranscript: "NM_033215.5", parsimonySitesP: "0.182728410513141", constantSitesP: "0.687526074259491", distinctSitesP: "0.243637880684189", brLenTotal: "0.6455" },
  { geneTranscript: "NM_017771.5", parsimonySitesP: "0.13437139561707", constantSitesP: "0.756055363321799", distinctSitesP: "0.217416378316032", brLenTotal: "0.4759" },
  { geneTranscript: "NM_183374.3", parsimonySitesP: "0.23690932311622", constantSitesP: "0.606641123882503", distinctSitesP: "0.314176245210728", brLenTotal: "0.9412" },
  { geneTranscript: "NM_001150.3", parsimonySitesP: "0.306790761806274", constantSitesP: "0.573940020682523", distinctSitesP: "0.402964495001724", brLenTotal: "1.4161" },
  { geneTranscript: "NM_030761.5", parsimonySitesP: "0.230769230769231", constantSitesP: "0.705603038936372", distinctSitesP: "0.218423551756885", brLenTotal: "1.4415" },
  { geneTranscript: "NM_003336.4", parsimonySitesP: "0.107456140350877", constantSitesP: "0.81359649122807", distinctSitesP: "0.219298245614035", brLenTotal: "0.3504" },
  { geneTranscript: "NM_001419.3", parsimonySitesP: "0.187116564417178", constantSitesP: "0.740286298568507", distinctSitesP: "0.2280163599182", brLenTotal: "0.7901" },
  { geneTranscript: "NM_001037666.3", parsimonySitesP: "0.178318135764944", constantSitesP: "0.698074974670719", distinctSitesP: "0.277608915906788", brLenTotal: "0.7392" },
  { geneTranscript: "NM_017646.6", parsimonySitesP: "0.199857244825125", constantSitesP: "0.660242683797288", distinctSitesP: "0.291934332619557", brLenTotal: "0.6404" },
  { geneTranscript: "NM_145071.4", parsimonySitesP: "0.233850129198966", constantSitesP: "0.666666666666667", distinctSitesP: "0.315245478036176", brLenTotal: "0.7354" },
  { geneTranscript: "NM_198563.5", parsimonySitesP: "0.138321995464853", constantSitesP: "0.748299319727891", distinctSitesP: "0.222222222222222", brLenTotal: "0.5008" },
  { geneTranscript: "NM_016594.3", parsimonySitesP: "0.159203980099502", constantSitesP: "0.728026533996683", distinctSitesP: "0.248756218905473", brLenTotal: "0.4693" },
  { geneTranscript: "NM_001372574.1", parsimonySitesP: "0.113997113997114", constantSitesP: "0.786435786435786", distinctSitesP: "0.187878787878788", brLenTotal: "0.4125" },
  { geneTranscript: "NM_032028.4", parsimonySitesP: "0.371480472297911", constantSitesP: "0.471389645776567", distinctSitesP: "0.510445049954587", brLenTotal: "2.0887" },
  { geneTranscript: "NM_205846.3", parsimonySitesP: "0.173258003766478", constantSitesP: "0.704331450094162", distinctSitesP: "0.297551789077213", brLenTotal: "0.5282" },
  { geneTranscript: "NM_001478.5", parsimonySitesP: "0.222639149468418", constantSitesP: "0.652908067542214", distinctSitesP: "0.288930581613508", brLenTotal: "0.7843" },
  { geneTranscript: "NM_005388.5", parsimonySitesP: "0.167220376522702", constantSitesP: "0.714285714285714", distinctSitesP: "0.239202657807309", brLenTotal: "0.5609" },
  { geneTranscript: "NM_004263.5", parsimonySitesP: "0.206493506493506", constantSitesP: "0.668398268398268", distinctSitesP: "0.265367965367965", brLenTotal: "0.6716" },
  { geneTranscript: "NM_001172439.2", parsimonySitesP: "0.261788617886179", constantSitesP: "0.584552845528455", distinctSitesP: "0.403252032520325", brLenTotal: "1.0110" },
  { geneTranscript: "NM_006371.5", parsimonySitesP: "0.248545303408146", constantSitesP: "0.625103906899418", distinctSitesP: "0.321695760598504", brLenTotal: "0.9098" },
  { geneTranscript: "NM_003539.4", parsimonySitesP: "0.300970873786408", constantSitesP: "0.679611650485437", distinctSitesP: "0.333333333333333", brLenTotal: "2.2172" },
  { geneTranscript: "NM_001005504.1", parsimonySitesP: "0.563034188034188", constantSitesP: "0.314102564102564", distinctSitesP: "0.673076923076923", brLenTotal: "3.3921" },
  { geneTranscript: "NM_024827.4", parsimonySitesP: "0.183477425552354", constantSitesP: "0.704130643611912", distinctSitesP: "0.263208453410183", brLenTotal: "0.7901" },
  { geneTranscript: "NM_138428.6", parsimonySitesP: "0.148550724637681", constantSitesP: "0.753623188405797", distinctSitesP: "0.242753623188406", brLenTotal: "0.4379" },
  { geneTranscript: "NM_017622.3", parsimonySitesP: "0.274509803921569", constantSitesP: "0.551820728291317", distinctSitesP: "0.389355742296919", brLenTotal: "0.9403" },
  { geneTranscript: "NM_001319217.2", parsimonySitesP: "0.342447916666667", constantSitesP: "0.464192708333333", distinctSitesP: "0.486328125", brLenTotal: "1.6411" },
  { geneTranscript: "NM_018953.4", parsimonySitesP: "0.0405405405405405", constantSitesP: "0.896396396396396", distinctSitesP: "0.0975975975975976", brLenTotal: "0.1287" },
  { geneTranscript: "NM_000496.3", parsimonySitesP: "0.175609756097561", constantSitesP: "0.721951219512195", distinctSitesP: "0.289430894308943", brLenTotal: "0.9045" },
  { geneTranscript: "NM_019852.5", parsimonySitesP: "0.124137931034483", constantSitesP: "0.779310344827586", distinctSitesP: "0.175862068965517", brLenTotal: "0.3627" },
  { geneTranscript: "NM_032199.3", parsimonySitesP: "0.158249158249158", constantSitesP: "0.73625140291807", distinctSitesP: "0.212121212121212", brLenTotal: "0.5853" },
  { geneTranscript: "NM_006289.4", parsimonySitesP: "0.162140889413617", constantSitesP: "0.752328479601207", distinctSitesP: "0.165682802046438", brLenTotal: "0.5692" },
  { geneTranscript: "NM_001369817.2", parsimonySitesP: "0.188459941884599", constantSitesP: "0.61519302615193", distinctSitesP: "0.243254462432545", brLenTotal: "1.0881" },
  { geneTranscript: "NM_006761.5", parsimonySitesP: "0.133333333333333", constantSitesP: "0.691503267973856", distinctSitesP: "0.271895424836601", brLenTotal: "0.6303" },
  { geneTranscript: "NM_022126.4", parsimonySitesP: "0.254320987654321", constantSitesP: "0.612345679012346", distinctSitesP: "0.388888888888889", brLenTotal: "1.0233" },
  { geneTranscript: "NM_001112741.2", parsimonySitesP: "0.114529914529915", constantSitesP: "0.764672364672365", distinctSitesP: "0.180626780626781", brLenTotal: "0.5176" },
  { geneTranscript: "NM_024595.3", parsimonySitesP: "0.151041666666667", constantSitesP: "0.666666666666667", distinctSitesP: "0.317708333333333", brLenTotal: "0.5168" },
  { geneTranscript: "NM_001031732.4", parsimonySitesP: "0.116460339293902", constantSitesP: "0.815222375057313", distinctSitesP: "0.161393856029344", brLenTotal: "0.4062" },
  { geneTranscript: "NM_001033578.3", parsimonySitesP: "0.11491935483871", constantSitesP: "0.772177419354839", distinctSitesP: "0.193548387096774", brLenTotal: "0.3864" },
  { geneTranscript: "NM_017951.5", parsimonySitesP: "0.271261588069327", constantSitesP: "0.553405884723902", distinctSitesP: "0.378476420798065", brLenTotal: "1.2034" },
  { geneTranscript: "NM_001354761.2", parsimonySitesP: "0.163537755527743", constantSitesP: "0.750521485189821", distinctSitesP: "0.213600333750521", brLenTotal: "0.5484" },
  { geneTranscript: "NM_000210.4", parsimonySitesP: "0.167753960857409", constantSitesP: "0.731904318111215", distinctSitesP: "0.220254737496117", brLenTotal: "0.6006" },
  { geneTranscript: "NM_005915.6", parsimonySitesP: "0.145757206658546", constantSitesP: "0.737718229801056", distinctSitesP: "0.207064555420219", brLenTotal: "0.5130" },
  { geneTranscript: "NM_006744.4", parsimonySitesP: "0.172470978441128", constantSitesP: "0.73134328358209", distinctSitesP: "0.252072968490879", brLenTotal: "0.6661" },
  { geneTranscript: "NM_001193315.2", parsimonySitesP: "0.148073022312373", constantSitesP: "0.744421906693712", distinctSitesP: "0.230561189993239", brLenTotal: "0.4644" },
  { geneTranscript: "NM_012477.4", parsimonySitesP: "0.209417596034696", constantSitesP: "0.604708798017348", distinctSitesP: "0.38909541511772", brLenTotal: "0.7534" },
  { geneTranscript: "NM_022496.5", parsimonySitesP: "0.106060606060606", constantSitesP: "0.803030303030303", distinctSitesP: "0.183501683501684", brLenTotal: "0.3713" },
  { geneTranscript: "NM_001427.4", parsimonySitesP: "0.193193193193193", constantSitesP: "0.67967967967968", distinctSitesP: "0.299299299299299", brLenTotal: "0.8681" },
  { geneTranscript: "NM_018347.3", parsimonySitesP: "0.27", constantSitesP: "0.593333333333333", distinctSitesP: "0.376666666666667", brLenTotal: "0.9876" },
  { geneTranscript: "NM_004951.5", parsimonySitesP: "0.193905817174515", constantSitesP: "0.692520775623269", distinctSitesP: "0.295475530932595", brLenTotal: "0.7171" },
  { geneTranscript: "NM_001270875.3", parsimonySitesP: "0.115183246073298", constantSitesP: "0.734729493891798", distinctSitesP: "0.228621291448517", brLenTotal: "0.4529" },
  { geneTranscript: "NM_003795.6", parsimonySitesP: "0.10082304526749", constantSitesP: "0.802469135802469", distinctSitesP: "0.191358024691358", brLenTotal: "0.3443" },
  { geneTranscript: "NM_004750.5", parsimonySitesP: "0.17693522906793", constantSitesP: "0.733017377567141", distinctSitesP: "0.259083728278041", brLenTotal: "0.7354" },
  { geneTranscript: "NM_001303264.2", parsimonySitesP: "0.182098765432099", constantSitesP: "0.69047619047619", distinctSitesP: "0.242063492063492", brLenTotal: "0.5611" },
  { geneTranscript: "NM_212551.5", parsimonySitesP: "0.215859030837004", constantSitesP: "0.629955947136564", distinctSitesP: "0.345080763582966", brLenTotal: "0.6953" },
  { geneTranscript: "NM_001017930.2", parsimonySitesP: "0.473888888888889", constantSitesP: "0.333333333333333", distinctSitesP: "0.656111111111111", brLenTotal: "2.3278" },
  { geneTranscript: "NM_198467.3", parsimonySitesP: "0.164302600472813", constantSitesP: "0.719858156028369", distinctSitesP: "0.251379038613081", brLenTotal: "0.4915" },
  { geneTranscript: "NM_006986.4", parsimonySitesP: "0.154670094258783", constantSitesP: "0.706083976006855", distinctSitesP: "0.24764353041988", brLenTotal: "0.5599" },
  { geneTranscript: "NM_015475.5", parsimonySitesP: "0.109395109395109", constantSitesP: "0.761904761904762", distinctSitesP: "0.197554697554698", brLenTotal: "0.4620" },
  { geneTranscript: "NM_021160.3", parsimonySitesP: "0.146356033452808", constantSitesP: "0.747311827956989", distinctSitesP: "0.219832735961768", brLenTotal: "0.5022" },
  { geneTranscript: "NM_002017.5", parsimonySitesP: "0.157079646017699", constantSitesP: "0.710914454277286", distinctSitesP: "0.240412979351032", brLenTotal: "0.8098" },
  { geneTranscript: "NM_145109.3", parsimonySitesP: "0.166186359269933", constantSitesP: "0.738712776176753", distinctSitesP: "0.253602305475504", brLenTotal: "0.8226" },
  { geneTranscript: "NM_001003722.2", parsimonySitesP: "0.209169054441261", constantSitesP: "0.639923591212989", distinctSitesP: "0.297039159503343", brLenTotal: "0.7364" },
  { geneTranscript: "NM_014806.5", parsimonySitesP: "0.195030782761653", constantSitesP: "0.664248021108179", distinctSitesP: "0.233069481090589", brLenTotal: "0.6694" },
  { geneTranscript: "NM_004656.4", parsimonySitesP: "0.165980795610425", constantSitesP: "0.681755829903978", distinctSitesP: "0.256058527663466", brLenTotal: "0.6823" },
  { geneTranscript: "NM_144990.4", parsimonySitesP: "0.2997542997543", constantSitesP: "0.524160524160524", distinctSitesP: "0.466011466011466", brLenTotal: "1.2689" },
  { geneTranscript: "NM_021831.6", parsimonySitesP: "0.175696012039127", constantSitesP: "0.685477802859293", distinctSitesP: "0.240406320541761", brLenTotal: "0.5768" },
  { geneTranscript: "NM_003628.6", parsimonySitesP: "0.121644295302013", constantSitesP: "0.770134228187919", distinctSitesP: "0.17589485458613", brLenTotal: "0.4794" },
  { geneTranscript: "NM_013388.6", parsimonySitesP: "0.179056754596323", constantSitesP: "0.675459632294165", distinctSitesP: "0.258992805755396", brLenTotal: "0.6292" },
  { geneTranscript: "NM_018066.4", parsimonySitesP: "0.189247311827957", constantSitesP: "0.7", distinctSitesP: "0.263440860215054", brLenTotal: "0.6685" },
  { geneTranscript: "NM_001326.3", parsimonySitesP: "0.0841469084146908", constantSitesP: "0.831706183170618", distinctSitesP: "0.139934913993491", brLenTotal: "0.3215" },
  { geneTranscript: "NM_016524.4", parsimonySitesP: "0.168776371308017", constantSitesP: "0.748241912798875", distinctSitesP: "0.234177215189873", brLenTotal: "0.6313" },
  { geneTranscript: "NM_012214.3", parsimonySitesP: "0.142056074766355", constantSitesP: "0.75202492211838", distinctSitesP: "0.235514018691589", brLenTotal: "0.5168" },
  { geneTranscript: "NM_012378.2", parsimonySitesP: "0.401929260450161", constantSitesP: "0.412647374062165", distinctSitesP: "0.518756698821008", brLenTotal: "2.0108" },
  { geneTranscript: "NM_005573.4", parsimonySitesP: "0.131968145620023", constantSitesP: "0.781001137656428", distinctSitesP: "0.190557451649602", brLenTotal: "0.4481" },
  { geneTranscript: "NM_182918.4", parsimonySitesP: "0.167710508002784", constantSitesP: "0.744606819763396", distinctSitesP: "0.216423103688239", brLenTotal: "0.7980" },
  { geneTranscript: "NM_001395490.1", parsimonySitesP: "0.150990099009901", constantSitesP: "0.733223322332233", distinctSitesP: "0.202695269526953", brLenTotal: "0.5642" },
  { geneTranscript: "NM_004330.4", parsimonySitesP: "0.150743099787686", constantSitesP: "0.752653927813163", distinctSitesP: "0.234607218683652", brLenTotal: "0.4880" },
  { geneTranscript: "NM_000937.5", parsimonySitesP: "0.166835871404399", constantSitesP: "0.746023688663283", distinctSitesP: "0.199153976311337", brLenTotal: "0.8019" },
  { geneTranscript: "NM_000349.3", parsimonySitesP: "0.231578947368421", constantSitesP: "0.632748538011696", distinctSitesP: "0.333333333333333", brLenTotal: "1.0419" },
  { geneTranscript: "NM_002098.6", parsimonySitesP: "0.2", constantSitesP: "0.723333333333333", distinctSitesP: "0.241666666666667", brLenTotal: "0.7580" },
  { geneTranscript: "NM_005091.3", parsimonySitesP: "0.403061224489796", constantSitesP: "0.452380952380952", distinctSitesP: "0.583333333333333", brLenTotal: "1.7494" },
  { geneTranscript: "NM_024656.4", parsimonySitesP: "0.247588424437299", constantSitesP: "0.615219721329046", distinctSitesP: "0.329581993569132", brLenTotal: "1.1862" },
  { geneTranscript: "NM_007103.4", parsimonySitesP: "0.1875", constantSitesP: "0.726293103448276", distinctSitesP: "0.235632183908046", brLenTotal: "0.7342" },
  { geneTranscript: "NM_001001961.3", parsimonySitesP: "0.538380651945321", constantSitesP: "0.325972660357518", distinctSitesP: "0.664563617245005", brLenTotal: "3.9588" },
  { geneTranscript: "NM_001384189.2", parsimonySitesP: "0.218181818181818", constantSitesP: "0.612121212121212", distinctSitesP: "0.406060606060606", brLenTotal: "0.7417" },
  { geneTranscript: "NM_003183.6", parsimonySitesP: "0.147653721682848", constantSitesP: "0.742313915857605", distinctSitesP: "0.226941747572816", brLenTotal: "0.5433" },
  { geneTranscript: "NM_004172.5", parsimonySitesP: "0.156826568265683", constantSitesP: "0.748462484624846", distinctSitesP: "0.220787207872079", brLenTotal: "0.6622" },
  { geneTranscript: "NM_002787.5", parsimonySitesP: "0.111111111111111", constantSitesP: "0.777777777777778", distinctSitesP: "0.213675213675214", brLenTotal: "0.3987" },
  { geneTranscript: "NM_000760.4", parsimonySitesP: "0.32695374800638", constantSitesP: "0.496012759170654", distinctSitesP: "0.41786283891547", brLenTotal: "1.1786" },
  { geneTranscript: "NM_017917.4", parsimonySitesP: "0.107431935246505", constantSitesP: "0.787343635025754", distinctSitesP: "0.186902133922001", brLenTotal: "0.3610" },
  { geneTranscript: "NM_000730.3", parsimonySitesP: "0.208722741433022", constantSitesP: "0.634735202492212", distinctSitesP: "0.338785046728972", brLenTotal: "0.8974" },
  { geneTranscript: "NM_207645.4", parsimonySitesP: "0.203045685279188", constantSitesP: "0.654822335025381", distinctSitesP: "0.328257191201354", brLenTotal: "0.7028" },
  { geneTranscript: "NM_005789.4", parsimonySitesP: "0.0669291338582677", constantSitesP: "0.868766404199475", distinctSitesP: "0.123359580052493", brLenTotal: "0.2044" },
  { geneTranscript: "NM_001749.4", parsimonySitesP: "0.180348258706468", constantSitesP: "0.730099502487562", distinctSitesP: "0.284825870646766", brLenTotal: "1.0877" },
  { geneTranscript: "NM_138499.4", parsimonySitesP: "0.350847457627119", constantSitesP: "0.527683615819209", distinctSitesP: "0.449717514124294", brLenTotal: "1.6632" },
  { geneTranscript: "NM_198924.4", parsimonySitesP: "0.22", constantSitesP: "0.618666666666667", distinctSitesP: "0.36", brLenTotal: "0.9198" },
  { geneTranscript: "NM_001136265.2", parsimonySitesP: "0.124435847840103", constantSitesP: "0.725338491295938", distinctSitesP: "0.217279174725983", brLenTotal: "0.7303" },
  { geneTranscript: "NM_006058.5", parsimonySitesP: "0.20230607966457", constantSitesP: "0.65041928721174", distinctSitesP: "0.289832285115304", brLenTotal: "0.8432" },
  { geneTranscript: "NM_145649.5", parsimonySitesP: "0.330016583747927", constantSitesP: "0.518242122719735", distinctSitesP: "0.466832504145937", brLenTotal: "1.3797" },
  { geneTranscript: "NM_207197.3", parsimonySitesP: "0.253379683275396", constantSitesP: "0.592893008883739", distinctSitesP: "0.326380842023947", brLenTotal: "0.8698" },
  { geneTranscript: "NM_003017.5", parsimonySitesP: "0.115853658536585", constantSitesP: "0.693089430894309", distinctSitesP: "0.282520325203252", brLenTotal: "0.5664" },
  { geneTranscript: "NM_004174.4", parsimonySitesP: "0.28017585931255", constantSitesP: "0.632294164668265", distinctSitesP: "0.349720223820943", brLenTotal: "1.2951" },
  { geneTranscript: "NM_002224.4", parsimonySitesP: "0.165605890428054", constantSitesP: "0.733807562710595", distinctSitesP: "0.181080743791339", brLenTotal: "0.7446" },
  { geneTranscript: "NM_015938.5", parsimonySitesP: "0.161696487740225", constantSitesP: "0.710404241219351", distinctSitesP: "0.260437375745527", brLenTotal: "0.5207" },
  { geneTranscript: "NM_003838.5", parsimonySitesP: "0.227272727272727", constantSitesP: "0.598204264870932", distinctSitesP: "0.343434343434343", brLenTotal: "0.7629" },
  { geneTranscript: "NM_001243925.2", parsimonySitesP: "0.168411867364747", constantSitesP: "0.715532286212914", distinctSitesP: "0.24956369982548", brLenTotal: "0.5877" },
  { geneTranscript: "NM_032288.7", parsimonySitesP: "0.176100628930818", constantSitesP: "0.674004192872117", distinctSitesP: "0.315513626834382", brLenTotal: "0.5781" },
  { geneTranscript: "NM_138413.4", parsimonySitesP: "0.194699286442406", constantSitesP: "0.666666666666667", distinctSitesP: "0.305810397553517", brLenTotal: "0.8678" },
  { geneTranscript: "NM_000376.3", parsimonySitesP: "0.198282591725215", constantSitesP: "0.700234192037471", distinctSitesP: "0.278688524590164", brLenTotal: "0.7712" },
  { geneTranscript: "NM_006701.5", parsimonySitesP: "0.147887323943662", constantSitesP: "0.76056338028169", distinctSitesP: "0.241784037558685", brLenTotal: "0.6651" },
  { geneTranscript: "NM_002668.3", parsimonySitesP: "0.206140350877193", constantSitesP: "0.660087719298246", distinctSitesP: "0.317982456140351", brLenTotal: "0.6448" },
  { geneTranscript: "NM_012285.3", parsimonySitesP: "0.1976401179941", constantSitesP: "0.656833824975418", distinctSitesP: "0.246804326450344", brLenTotal: "0.7825" },
  { geneTranscript: "NM_182981.3", parsimonySitesP: "0.273235499650594", constantSitesP: "0.590496156533892", distinctSitesP: "0.365478686233403", brLenTotal: "1.2489" },
  { geneTranscript: "NM_021200.3", parsimonySitesP: "0.179698216735254", constantSitesP: "0.718792866941015", distinctSitesP: "0.268861454046639", brLenTotal: "0.6928" },
  { geneTranscript: "NM_201624.3", parsimonySitesP: "0.130259787778997", constantSitesP: "0.772045371386754", distinctSitesP: "0.193560190267106", brLenTotal: "0.4578" },
  { geneTranscript: "NM_005379.4", parsimonySitesP: "0.228187919463087", constantSitesP: "0.64046021093001", distinctSitesP: "0.30520933205497", brLenTotal: "0.7716" },
  { geneTranscript: "NM_007160.4", parsimonySitesP: "0.44017094017094", constantSitesP: "0.335470085470085", distinctSitesP: "0.620726495726496", brLenTotal: "1.9337" },
  { geneTranscript: "NM_007044.4", parsimonySitesP: "0.140529531568228", constantSitesP: "0.755600814663951", distinctSitesP: "0.198234894772573", brLenTotal: "0.5005" },
  { geneTranscript: "NM_001384474.1", parsimonySitesP: "0.201055873295205", constantSitesP: "0.6820648188884", distinctSitesP: "0.231265581463558", brLenTotal: "0.8858" },
  { geneTranscript: "NM_172000.4", parsimonySitesP: "0.371184371184371", constantSitesP: "0.411477411477411", distinctSitesP: "0.595848595848596", brLenTotal: "1.6747" },
  { geneTranscript: "NM_001352913.2", parsimonySitesP: "0.142199194012666", constantSitesP: "0.751295336787565", distinctSitesP: "0.225100748416811", brLenTotal: "0.5232" },
  { geneTranscript: "NM_021239.3", parsimonySitesP: "0.109134045077106", constantSitesP: "0.815737445630684", distinctSitesP: "0.164491894029261", brLenTotal: "0.3334" },
  { geneTranscript: "NM_025182.4", parsimonySitesP: "0.118339529120198", constantSitesP: "0.762081784386617", distinctSitesP: "0.180297397769517", brLenTotal: "0.3644" },
  { geneTranscript: "NM_001145971.2", parsimonySitesP: "0.262839879154079", constantSitesP: "0.611278952668681", distinctSitesP: "0.367573011077543", brLenTotal: "1.1915" },
  { geneTranscript: "NM_014166.4", parsimonySitesP: "0.180246913580247", constantSitesP: "0.732098765432099", distinctSitesP: "0.259259259259259", brLenTotal: "0.5866" },
  { geneTranscript: "NM_145260.3", parsimonySitesP: "0.159147869674185", constantSitesP: "0.745614035087719", distinctSitesP: "0.221804511278195", brLenTotal: "0.6032" },
  { geneTranscript: "NM_001076674.3", parsimonySitesP: "0.181723779854621", constantSitesP: "0.679127725856698", distinctSitesP: "0.274143302180685", brLenTotal: "0.5886" },
  { geneTranscript: "NM_178841.4", parsimonySitesP: "0.146272855133615", constantSitesP: "0.745428973277075", distinctSitesP: "0.222222222222222", brLenTotal: "0.7117" },
  { geneTranscript: "NM_015331.3", parsimonySitesP: "0.19181946403385", constantSitesP: "0.676069581570287", distinctSitesP: "0.249647390691114", brLenTotal: "0.6988" },
  { geneTranscript: "NM_144567.5", parsimonySitesP: "0.127450980392157", constantSitesP: "0.772671568627451", distinctSitesP: "0.201593137254902", brLenTotal: "0.3745" },
  { geneTranscript: "NM_173540.3", parsimonySitesP: "0.242547425474255", constantSitesP: "0.613143631436314", distinctSitesP: "0.329268292682927", brLenTotal: "0.8098" },
  { geneTranscript: "NM_014045.5", parsimonySitesP: "0.220196353436185", constantSitesP: "0.651238896680692", distinctSitesP: "0.280972417017298", brLenTotal: "0.7805" },
  { geneTranscript: "NM_021996.6", parsimonySitesP: "0.304514889529299", constantSitesP: "0.525456292026897", distinctSitesP: "0.45821325648415", brLenTotal: "1.2048" },
  { geneTranscript: "NM_002586.5", parsimonySitesP: "0.128682170542636", constantSitesP: "0.786046511627907", distinctSitesP: "0.194573643410853", brLenTotal: "0.4756" },
  { geneTranscript: "NM_001168214.2", parsimonySitesP: "0.182186234817814", constantSitesP: "0.672064777327935", distinctSitesP: "0.310391363022942", brLenTotal: "0.7036" },
  { geneTranscript: "NM_016463.9", parsimonySitesP: "0.169772256728778", constantSitesP: "0.752587991718427", distinctSitesP: "0.238095238095238", brLenTotal: "0.8015" },
  { geneTranscript: "NM_016355.4", parsimonySitesP: "0.167032967032967", constantSitesP: "0.714285714285714", distinctSitesP: "0.263003663003663", brLenTotal: "0.5681" },
  { geneTranscript: "NM_016194.4", parsimonySitesP: "0.154430379746835", constantSitesP: "0.777215189873418", distinctSitesP: "0.223628691983122", brLenTotal: "0.5902" },
  { geneTranscript: "NM_002150.3", parsimonySitesP: "0.215436810856658", constantSitesP: "0.657336726039016", distinctSitesP: "0.340118744698897", brLenTotal: "0.8560" },
  { geneTranscript: "NM_001395333.1", parsimonySitesP: "0.272335488148516", constantSitesP: "0.583291894579811", distinctSitesP: "0.357699320404442", brLenTotal: "1.1122" },
  { geneTranscript: "NM_020843.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.70952380952381", distinctSitesP: "0.229047619047619", brLenTotal: "0.5538" },
  { geneTranscript: "NM_002905.5", parsimonySitesP: "0.264150943396226", constantSitesP: "0.60167714884696", distinctSitesP: "0.336477987421384", brLenTotal: "0.7999" },
  { geneTranscript: "NM_020798.4", parsimonySitesP: "0.235756385068762", constantSitesP: "0.64800261951539", distinctSitesP: "0.294368041912246", brLenTotal: "0.9652" },
  { geneTranscript: "NM_175854.8", parsimonySitesP: "0.0980834272829763", constantSitesP: "0.820368282600526", distinctSitesP: "0.153701615933859", brLenTotal: "0.3351" },
  { geneTranscript: "NM_001305581.2", parsimonySitesP: "0.169616519174041", constantSitesP: "0.71976401179941", distinctSitesP: "0.277286135693215", brLenTotal: "0.5663" },
  { geneTranscript: "NM_001098204.2", parsimonySitesP: "0.416064257028112", constantSitesP: "0.405622489959839", distinctSitesP: "0.506024096385542", brLenTotal: "2.0815" },
  { geneTranscript: "NM_016391.8", parsimonySitesP: "0.191011235955056", constantSitesP: "0.629213483146067", distinctSitesP: "0.359550561797753", brLenTotal: "0.7627" },
  { geneTranscript: "NM_003019.5", parsimonySitesP: "0.296888888888889", constantSitesP: "0.512888888888889", distinctSitesP: "0.464", brLenTotal: "1.1533" },
  { geneTranscript: "NM_175039.4", parsimonySitesP: "0.211920529801325", constantSitesP: "0.681015452538631", distinctSitesP: "0.282560706401766", brLenTotal: "0.9396" },
  { geneTranscript: "NM_006693.4", parsimonySitesP: "0.125154894671623", constantSitesP: "0.811648079306072", distinctSitesP: "0.174721189591078", brLenTotal: "0.4813" },
  { geneTranscript: "NM_002355.4", parsimonySitesP: "0.168471720818291", constantSitesP: "0.708784596871239", distinctSitesP: "0.25150421179302", brLenTotal: "0.5394" },
  { geneTranscript: "NM_001330707.2", parsimonySitesP: "0.118780096308186", constantSitesP: "0.784911717495987", distinctSitesP: "0.180310326377742", brLenTotal: "0.3753" },
  { geneTranscript: "NM_001421.4", parsimonySitesP: "0.207139265962795", constantSitesP: "0.662141779788839", distinctSitesP: "0.275012569130216", brLenTotal: "0.7061" },
  { geneTranscript: "NM_001308426.2", parsimonySitesP: "0.163082437275986", constantSitesP: "0.67741935483871", distinctSitesP: "0.277777777777778", brLenTotal: "0.6084" },
  { geneTranscript: "NM_177551.4", parsimonySitesP: "0.280073461891644", constantSitesP: "0.539026629935721", distinctSitesP: "0.414141414141414", brLenTotal: "1.2029" },
  { geneTranscript: "NM_014949.4", parsimonySitesP: "0.0955483170466884", constantSitesP: "0.823561346362649", distinctSitesP: "0.157437567861021", brLenTotal: "0.3137" },
  { geneTranscript: "NM_004504.5", parsimonySitesP: "0.0889679715302491", constantSitesP: "0.83214709371293", distinctSitesP: "0.144721233689205", brLenTotal: "0.2794" },
  { geneTranscript: "NM_003775.4", parsimonySitesP: "0.280381944444444", constantSitesP: "0.561631944444444", distinctSitesP: "0.362847222222222", brLenTotal: "1.2813" },
  { geneTranscript: "NM_002471.4", parsimonySitesP: "0.233625580195977", constantSitesP: "0.66099363933299", distinctSitesP: "0.281760357572632", brLenTotal: "1.5714" },
  { geneTranscript: "NM_012082.4", parsimonySitesP: "0.152620909354185", constantSitesP: "0.741673906747756", distinctSitesP: "0.202143064002317", brLenTotal: "0.5277" },
  { geneTranscript: "NM_004631.5", parsimonySitesP: "0.151609553478712", constantSitesP: "0.734164070612669", distinctSitesP: "0.217722395292489", brLenTotal: "0.5412" },
  { geneTranscript: "NM_002129.4", parsimonySitesP: "0.210526315789474", constantSitesP: "0.641148325358852", distinctSitesP: "0.309409888357257", brLenTotal: "1.0571" },
  { geneTranscript: "NM_001146267.2", parsimonySitesP: "0.0585585585585586", constantSitesP: "0.885585585585586", distinctSitesP: "0.103603603603604", brLenTotal: "0.1655" },
  { geneTranscript: "NM_016155.7", parsimonySitesP: "0.295743504698729", constantSitesP: "0.587617468214483", distinctSitesP: "0.409618573797678", brLenTotal: "1.5273" },
  { geneTranscript: "NM_001128833.2", parsimonySitesP: "0.212240868706811", constantSitesP: "0.675222112537019", distinctSitesP: "0.259295820993748", brLenTotal: "0.8354" },
  { geneTranscript: "NM_006118.4", parsimonySitesP: "0.200716845878136", constantSitesP: "0.624850657108722", distinctSitesP: "0.375149342891278", brLenTotal: "0.7140" },
  { geneTranscript: "NM_004529.4", parsimonySitesP: "0.0892018779342723", constantSitesP: "0.8268779342723", distinctSitesP: "0.181338028169014", brLenTotal: "0.4803" },
  { geneTranscript: "NM_014044.7", parsimonySitesP: "0.166023166023166", constantSitesP: "0.736164736164736", distinctSitesP: "0.27027027027027", brLenTotal: "0.5777" },
  { geneTranscript: "NM_004203.5", parsimonySitesP: "0.246492985971944", constantSitesP: "0.623246492985972", distinctSitesP: "0.315965263861055", brLenTotal: "1.0347" },
  { geneTranscript: "NM_145307.4", parsimonySitesP: "0.182266009852217", constantSitesP: "0.647509578544061", distinctSitesP: "0.340448823207444", brLenTotal: "0.5991" },
  { geneTranscript: "NM_006142.5", parsimonySitesP: "0.139784946236559", constantSitesP: "0.725806451612903", distinctSitesP: "0.237903225806452", brLenTotal: "0.6443" },
  { geneTranscript: "NM_015238.3", parsimonySitesP: "0.180892482779275", constantSitesP: "0.697813716681641", distinctSitesP: "0.233003893381252", brLenTotal: "0.7271" },
  { geneTranscript: "NM_001031672.4", parsimonySitesP: "0.232804232804233", constantSitesP: "0.610582010582011", distinctSitesP: "0.35026455026455", brLenTotal: "0.8220" },
  { geneTranscript: "NM_005348.4", parsimonySitesP: "0.296448087431694", constantSitesP: "0.528233151183971", distinctSitesP: "0.40528233151184", brLenTotal: "1.7515" },
  { geneTranscript: "NM_021260.4", parsimonySitesP: "0.162162162162162", constantSitesP: "0.726726726726727", distinctSitesP: "0.214071214071214", brLenTotal: "0.6168" },
  { geneTranscript: "NM_014864.4", parsimonySitesP: "0.136104319478403", constantSitesP: "0.735126324368378", distinctSitesP: "0.212713936430318", brLenTotal: "0.5151" },
  { geneTranscript: "NM_001670.3", parsimonySitesP: "0.237352737352737", constantSitesP: "0.647609147609148", distinctSitesP: "0.294871794871795", brLenTotal: "1.1295" },
  { geneTranscript: "NM_024769.5", parsimonySitesP: "0.193923145665773", constantSitesP: "0.680965147453083", distinctSitesP: "0.271671134941912", brLenTotal: "0.6689" },
  { geneTranscript: "NM_001079668.3", parsimonySitesP: "0.108063175394846", constantSitesP: "0.799667497921862", distinctSitesP: "0.185369908561929", brLenTotal: "0.4341" },
  { geneTranscript: "NM_003956.4", parsimonySitesP: "0.279411764705882", constantSitesP: "0.574754901960784", distinctSitesP: "0.403186274509804", brLenTotal: "1.0090" },
  { geneTranscript: "NM_019026.6", parsimonySitesP: "0.120567375886525", constantSitesP: "0.789007092198582", distinctSitesP: "0.200354609929078", brLenTotal: "0.4390" },
  { geneTranscript: "NM_002110.5", parsimonySitesP: "0.198986058301648", constantSitesP: "0.678707224334601", distinctSitesP: "0.285171102661597", brLenTotal: "0.9314" },
  { geneTranscript: "NM_032554.4", parsimonySitesP: "0.282273603082852", constantSitesP: "0.582851637764933", distinctSitesP: "0.385356454720617", brLenTotal: "1.0399" },
  { geneTranscript: "NM_025124.4", parsimonySitesP: "0.147008547008547", constantSitesP: "0.733333333333333", distinctSitesP: "0.232478632478632", brLenTotal: "0.5271" },
  { geneTranscript: "NM_017859.4", parsimonySitesP: "0.20316301703163", constantSitesP: "0.715328467153285", distinctSitesP: "0.262165450121655", brLenTotal: "1.0430" },
  { geneTranscript: "NM_015092.5", parsimonySitesP: "0.128289174178276", constantSitesP: "0.785304561595193", distinctSitesP: "0.145224437767459", brLenTotal: "0.4258" },
  { geneTranscript: "NM_133491.5", parsimonySitesP: "0.241176470588235", constantSitesP: "0.582352941176471", distinctSitesP: "0.392156862745098", brLenTotal: "0.7895" },
  { geneTranscript: "NM_016106.4", parsimonySitesP: "0.106438213914849", constantSitesP: "0.807892004153686", distinctSitesP: "0.179127725856698", brLenTotal: "0.3689" },
  { geneTranscript: "NM_005782.4", parsimonySitesP: "0.20959595959596", constantSitesP: "0.623737373737374", distinctSitesP: "0.392676767676768", brLenTotal: "0.9241" },
  { geneTranscript: "NM_032865.6", parsimonySitesP: "0.258275058275058", constantSitesP: "0.560839160839161", distinctSitesP: "0.373892773892774", brLenTotal: "1.0253" },
  { geneTranscript: "NM_001146175.2", parsimonySitesP: "0.254700854700855", constantSitesP: "0.583760683760684", distinctSitesP: "0.382051282051282", brLenTotal: "0.9416" },
  { geneTranscript: "NM_144633.3", parsimonySitesP: "0.159590484793737", constantSitesP: "0.716651610960554", distinctSitesP: "0.218608852755194", brLenTotal: "0.5915" },
  { geneTranscript: "NM_001660.4", parsimonySitesP: "0.1", constantSitesP: "0.796296296296296", distinctSitesP: "0.17962962962963", brLenTotal: "0.2978" },
  { geneTranscript: "NM_152261.4", parsimonySitesP: "0.0890804597701149", constantSitesP: "0.807471264367816", distinctSitesP: "0.209770114942529", brLenTotal: "0.2766" },
  { geneTranscript: "NM_001389625.1", parsimonySitesP: "0.277777777777778", constantSitesP: "0.592592592592593", distinctSitesP: "0.407407407407407", brLenTotal: "0.8040" },
  { geneTranscript: "NM_133171.5", parsimonySitesP: "0.118055555555556", constantSitesP: "0.797222222222222", distinctSitesP: "0.158333333333333", brLenTotal: "0.4242" },
  { geneTranscript: "NM_182700.6", parsimonySitesP: "0.16010498687664", constantSitesP: "0.743438320209974", distinctSitesP: "0.244750656167979", brLenTotal: "0.6826" },
  { geneTranscript: "NM_000915.4", parsimonySitesP: "0.210666666666667", constantSitesP: "0.629333333333333", distinctSitesP: "0.370666666666667", brLenTotal: "1.0273" },
  { geneTranscript: "NM_139265.4", parsimonySitesP: "0.234134319162046", constantSitesP: "0.67097966728281", distinctSitesP: "0.276648182378312", brLenTotal: "1.1299" },
  { geneTranscript: "NM_001146069.2", parsimonySitesP: "0.27985347985348", constantSitesP: "0.594139194139194", distinctSitesP: "0.373626373626374", brLenTotal: "1.2290" },
  { geneTranscript: "NM_001130438.3", parsimonySitesP: "0.16027452563585", constantSitesP: "0.756156641098103", distinctSitesP: "0.168348809043197", brLenTotal: "0.5946" },
  { geneTranscript: "NM_006937.4", parsimonySitesP: "0.108771929824561", constantSitesP: "0.684210526315789", distinctSitesP: "0.305263157894737", brLenTotal: "0.4409" },
  { geneTranscript: "NM_001079.4", parsimonySitesP: "0.189553042541734", constantSitesP: "0.670436187399031", distinctSitesP: "0.275175013462574", brLenTotal: "0.9492" },
  { geneTranscript: "NM_023947.4", parsimonySitesP: "0.229007633587786", constantSitesP: "0.66412213740458", distinctSitesP: "0.319762510602205", brLenTotal: "0.9982" },
  { geneTranscript: "NM_001130969.3", parsimonySitesP: "0.169182389937107", constantSitesP: "0.742138364779874", distinctSitesP: "0.216352201257862", brLenTotal: "0.6772" },
  { geneTranscript: "NM_014206.4", parsimonySitesP: "0.164556962025316", constantSitesP: "0.729957805907173", distinctSitesP: "0.278481012658228", brLenTotal: "0.5864" },
  { geneTranscript: "NM_173552.5", parsimonySitesP: "0.0891472868217054", constantSitesP: "0.837984496124031", distinctSitesP: "0.142635658914729", brLenTotal: "0.2755" },
  { geneTranscript: "NM_001018005.2", parsimonySitesP: "0.107981220657277", constantSitesP: "0.80868544600939", distinctSitesP: "0.165492957746479", brLenTotal: "0.4273" },
  { geneTranscript: "NM_007024.5", parsimonySitesP: "0.190883190883191", constantSitesP: "0.701804368471035", distinctSitesP: "0.252611585944919", brLenTotal: "0.6377" },
  { geneTranscript: "NM_007006.3", parsimonySitesP: "0.0969162995594714", constantSitesP: "0.772393538913363", distinctSitesP: "0.2143906020558", brLenTotal: "0.3754" },
  { geneTranscript: "NM_001122848.3", parsimonySitesP: "0.256243213897937", constantSitesP: "0.61943539630836", distinctSitesP: "0.365906623235613", brLenTotal: "1.2438" },
  { geneTranscript: "NM_003742.4", parsimonySitesP: "0.217764319959627", constantSitesP: "0.652788291698208", distinctSitesP: "0.294726217511986", brLenTotal: "0.7399" },
  { geneTranscript: "NM_001009955.4", parsimonySitesP: "0.0720221606648199", constantSitesP: "0.869806094182825", distinctSitesP: "0.133887349953832", brLenTotal: "0.3204" },
  { geneTranscript: "NM_015461.3", parsimonySitesP: "0.128909229595728", constantSitesP: "0.796084413933384", distinctSitesP: "0.156623442664633", brLenTotal: "0.4841" },
  { geneTranscript: "NM_012412.5", parsimonySitesP: "0.104166666666667", constantSitesP: "0.809895833333333", distinctSitesP: "0.200520833333333", brLenTotal: "0.3220" },
  { geneTranscript: "NM_024296.5", parsimonySitesP: "0.178333333333333", constantSitesP: "0.676666666666667", distinctSitesP: "0.306666666666667", brLenTotal: "0.9039" },
  { geneTranscript: "NM_001080438.1", parsimonySitesP: "0.262745098039216", constantSitesP: "0.563725490196078", distinctSitesP: "0.419607843137255", brLenTotal: "1.0054" },
  { geneTranscript: "NM_004175.5", parsimonySitesP: "0.142857142857143", constantSitesP: "0.764550264550265", distinctSitesP: "0.222222222222222", brLenTotal: "0.5443" },
  { geneTranscript: "NM_032621.4", parsimonySitesP: "0.263020833333333", constantSitesP: "0.5625", distinctSitesP: "0.46875", brLenTotal: "1.0001" },
  { geneTranscript: "NM_015904.4", parsimonySitesP: "0.138251366120219", constantSitesP: "0.74016393442623", distinctSitesP: "0.215846994535519", brLenTotal: "0.4614" },
  { geneTranscript: "NM_001276266.2", parsimonySitesP: "0.261418853255588", constantSitesP: "0.583090379008746", distinctSitesP: "0.37220602526725", brLenTotal: "0.9311" },
  { geneTranscript: "NM_001163678.2", parsimonySitesP: "0.126436781609195", constantSitesP: "0.796238244514107", distinctSitesP: "0.198537095088819", brLenTotal: "0.4177" },
  { geneTranscript: "NM_001164811.2", parsimonySitesP: "0.209876543209877", constantSitesP: "0.703703703703704", distinctSitesP: "0.308641975308642", brLenTotal: "0.4870" },
  { geneTranscript: "NM_000552.5", parsimonySitesP: "0.276691551131651", constantSitesP: "0.576253110558123", distinctSitesP: "0.357743808508117", brLenTotal: "1.1459" },
  { geneTranscript: "NM_001384574.2", parsimonySitesP: "0.124879923150817", constantSitesP: "0.769932756964457", distinctSitesP: "0.177713736791547", brLenTotal: "0.4703" },
  { geneTranscript: "NM_000674.3", parsimonySitesP: "0.169734151329243", constantSitesP: "0.753578732106339", distinctSitesP: "0.2280163599182", brLenTotal: "0.7078" },
  { geneTranscript: "NM_001004469.1", parsimonySitesP: "0.483279395900755", constantSitesP: "0.308522114347357", distinctSitesP: "0.678532901833873", brLenTotal: "2.2291" },
  { geneTranscript: "NM_014824.3", parsimonySitesP: "0.11036036036036", constantSitesP: "0.801351351351351", distinctSitesP: "0.158558558558559", brLenTotal: "0.3550" },
  { geneTranscript: "NM_033117.4", parsimonySitesP: "0.108771929824561", constantSitesP: "0.815789473684211", distinctSitesP: "0.180701754385965", brLenTotal: "0.3799" },
  { geneTranscript: "NM_001374504.1", parsimonySitesP: "0.228179551122195", constantSitesP: "0.651704073150457", distinctSitesP: "0.288445552784705", brLenTotal: "1.0236" },
  { geneTranscript: "NM_003622.4", parsimonySitesP: "0.183416252072968", constantSitesP: "0.690878938640133", distinctSitesP: "0.273300165837479", brLenTotal: "0.6677" },
  { geneTranscript: "NM_024562.2", parsimonySitesP: "0.244058500914077", constantSitesP: "0.590188909201706", distinctSitesP: "0.317489335770871", brLenTotal: "0.8303" },
  { geneTranscript: "NM_001166269.2", parsimonySitesP: "0.205693296602388", constantSitesP: "0.617079889807163", distinctSitesP: "0.32415059687787", brLenTotal: "0.6375" },
  { geneTranscript: "NM_017875.4", parsimonySitesP: "0.206140350877193", constantSitesP: "0.676535087719298", distinctSitesP: "0.283991228070175", brLenTotal: "0.7262" },
  { geneTranscript: "NM_003495.3", parsimonySitesP: "0.3042071197411", constantSitesP: "0.614886731391586", distinctSitesP: "0.381877022653722", brLenTotal: "2.5472" },
  { geneTranscript: "NM_004865.4", parsimonySitesP: "0.0698924731182796", constantSitesP: "0.844086021505376", distinctSitesP: "0.14336917562724", brLenTotal: "0.2451" },
  { geneTranscript: "NM_001001412.4", parsimonySitesP: "0.224470134874759", constantSitesP: "0.64354527938343", distinctSitesP: "0.303468208092486", brLenTotal: "1.3880" },
  { geneTranscript: "NM_025238.4", parsimonySitesP: "0.156293222683264", constantSitesP: "0.747579529737206", distinctSitesP: "0.237206085753804", brLenTotal: "0.5302" },
  { geneTranscript: "NM_004556.3", parsimonySitesP: "0.211449676823638", constantSitesP: "0.646352723915051", distinctSitesP: "0.295475530932595", brLenTotal: "0.7153" },
  { geneTranscript: "NM_025191.4", parsimonySitesP: "0.117310443490701", constantSitesP: "0.772174535050072", distinctSitesP: "0.181688125894134", brLenTotal: "0.4047" },
  { geneTranscript: "NM_001004705.2", parsimonySitesP: "0.465166130760986", constantSitesP: "0.37406216505895", distinctSitesP: "0.598070739549839", brLenTotal: "3.1291" },
  { geneTranscript: "NM_005488.3", parsimonySitesP: "0.205284552845528", constantSitesP: "0.682926829268293", distinctSitesP: "0.287940379403794", brLenTotal: "0.8571" },
  { geneTranscript: "NM_004575.3", parsimonySitesP: "0.143439282803586", constantSitesP: "0.766911165444173", distinctSitesP: "0.209453952730236", brLenTotal: "0.5531" },
  { geneTranscript: "NM_006712.5", parsimonySitesP: "0.18093503339405", constantSitesP: "0.655130540376442", distinctSitesP: "0.255009107468124", brLenTotal: "0.6190" },
  { geneTranscript: "NM_004370.6", parsimonySitesP: "0.187071498530852", constantSitesP: "0.702035041897921", distinctSitesP: "0.244096201980629", brLenTotal: "0.7145" },
  { geneTranscript: "NM_001385875.1", parsimonySitesP: "0.180048661800487", constantSitesP: "0.678021086780211", distinctSitesP: "0.283860502838605", brLenTotal: "0.8203" },
  { geneTranscript: "NM_003977.4", parsimonySitesP: "0.185858585858586", constantSitesP: "0.713131313131313", distinctSitesP: "0.260606060606061", brLenTotal: "0.8338" },
  { geneTranscript: "NM_001103161.2", parsimonySitesP: "0.22301024428684", constantSitesP: "0.637509850275808", distinctSitesP: "0.307328605200946", brLenTotal: "0.9169" },
  { geneTranscript: "NM_001367534.1", parsimonySitesP: "0.0873015873015873", constantSitesP: "0.853174603174603", distinctSitesP: "0.122448979591837", brLenTotal: "0.2804" },
  { geneTranscript: "NM_006277.3", parsimonySitesP: "0.148693773325476", constantSitesP: "0.735808289137694", distinctSitesP: "0.201139265370261", brLenTotal: "0.5077" },
  { geneTranscript: "NM_016263.4", parsimonySitesP: "0.175118323191346", constantSitesP: "0.749154834347532", distinctSitesP: "0.22920892494929", brLenTotal: "1.1917" },
  { geneTranscript: "NM_000110.4", parsimonySitesP: "0.163577235772358", constantSitesP: "0.715772357723577", distinctSitesP: "0.24", brLenTotal: "0.5988" },
  { geneTranscript: "NM_001037131.3", parsimonySitesP: "0.187475690392843", constantSitesP: "0.713730066122131", distinctSitesP: "0.248930377285103", brLenTotal: "0.9053" },
  { geneTranscript: "NM_014935.5", parsimonySitesP: "0.18352417302799", constantSitesP: "0.701972010178117", distinctSitesP: "0.248409669211196", brLenTotal: "0.7664" },
  { geneTranscript: "NM_001113498.3", parsimonySitesP: "0.12", constantSitesP: "0.782439024390244", distinctSitesP: "0.171382113821138", brLenTotal: "0.4302" },
  { geneTranscript: "NM_005885.4", parsimonySitesP: "0.115750915750916", constantSitesP: "0.815384615384615", distinctSitesP: "0.157875457875458", brLenTotal: "0.4081" },
  { geneTranscript: "NM_001271874.2", parsimonySitesP: "0.1953125", constantSitesP: "0.697916666666667", distinctSitesP: "0.250868055555556", brLenTotal: "0.6415" },
  { geneTranscript: "NM_030571.4", parsimonySitesP: "0.120663650075415", constantSitesP: "0.79788838612368", distinctSitesP: "0.188536953242836", brLenTotal: "0.3747" },
  { geneTranscript: "NM_001003801.2", parsimonySitesP: "0.0986887508626639", constantSitesP: "0.820565907522429", distinctSitesP: "0.142857142857143", brLenTotal: "0.4284" },
  { geneTranscript: "NM_022476.4", parsimonySitesP: "0.126712328767123", constantSitesP: "0.76027397260274", distinctSitesP: "0.219178082191781", brLenTotal: "0.4052" },
  { geneTranscript: "NM_000962.4", parsimonySitesP: "0.228714524207012", constantSitesP: "0.65386755703951", distinctSitesP: "0.298831385642738", brLenTotal: "1.0418" },
  { geneTranscript: "NM_005893.3", parsimonySitesP: "0.188208616780045", constantSitesP: "0.694444444444444", distinctSitesP: "0.242630385487528", brLenTotal: "0.6548" },
  { geneTranscript: "NM_020370.3", parsimonySitesP: "0.234848484848485", constantSitesP: "0.603535353535354", distinctSitesP: "0.326599326599327", brLenTotal: "0.8145" },
  { geneTranscript: "NM_006842.3", parsimonySitesP: "0.163500931098696", constantSitesP: "0.739292364990689", distinctSitesP: "0.211173184357542", brLenTotal: "0.5925" },
  { geneTranscript: "NM_012186.3", parsimonySitesP: "0.25914315569488", constantSitesP: "0.57889237199582", distinctSitesP: "0.434691745036573", brLenTotal: "1.0733" },
  { geneTranscript: "NM_001005470.1", parsimonySitesP: "0.610571736785329", constantSitesP: "0.281553398058252", distinctSitesP: "0.727076591154261", brLenTotal: "4.0637" },
  { geneTranscript: "NM_001605.3", parsimonySitesP: "0.192493112947658", constantSitesP: "0.693870523415978", distinctSitesP: "0.257231404958678", brLenTotal: "0.7461" },
  { geneTranscript: "NM_030622.8", parsimonySitesP: "0.299603174603175", constantSitesP: "0.578042328042328", distinctSitesP: "0.376984126984127", brLenTotal: "1.1127" },
  { geneTranscript: "NM_000260.4", parsimonySitesP: "0.165989465763732", constantSitesP: "0.736192626034612", distinctSitesP: "0.187509405568096", brLenTotal: "0.7635" },
  { geneTranscript: "NM_002695.5", parsimonySitesP: "0.171428571428571", constantSitesP: "0.755555555555556", distinctSitesP: "0.26984126984127", brLenTotal: "0.9102" },
  { geneTranscript: "NM_031372.4", parsimonySitesP: "0.149206349206349", constantSitesP: "0.692857142857143", distinctSitesP: "0.261111111111111", brLenTotal: "0.5640" },
  { geneTranscript: "NM_005605.5", parsimonySitesP: "0.173177083333333", constantSitesP: "0.697265625", distinctSitesP: "0.259114583333333", brLenTotal: "0.6752" },
  { geneTranscript: "NM_001001479.4", parsimonySitesP: "0.225714285714286", constantSitesP: "0.625714285714286", distinctSitesP: "0.320952380952381", brLenTotal: "0.9849" },
  { geneTranscript: "NM_170604.3", parsimonySitesP: "0.230807330361565", constantSitesP: "0.660227835562159", distinctSitesP: "0.301634472511144", brLenTotal: "0.8299" },
  { geneTranscript: "NM_014832.5", parsimonySitesP: "0.193631227529533", constantSitesP: "0.696199280945044", distinctSitesP: "0.252953261427838", brLenTotal: "0.7069" },
  { geneTranscript: "NM_001316349.2", parsimonySitesP: "0.219178082191781", constantSitesP: "0.632835201328352", distinctSitesP: "0.278746367787464", brLenTotal: "0.7659" },
  { geneTranscript: "NM_145065.3", parsimonySitesP: "0.160625444207534", constantSitesP: "0.768301350390903", distinctSitesP: "0.209665955934613", brLenTotal: "0.6821" },
  { geneTranscript: "NM_001365999.1", parsimonySitesP: "0.188811188811189", constantSitesP: "0.689102564102564", distinctSitesP: "0.192501942501943", brLenTotal: "0.6659" },
  { geneTranscript: "NM_006750.4", parsimonySitesP: "0.141358024691358", constantSitesP: "0.741358024691358", distinctSitesP: "0.216666666666667", brLenTotal: "0.4943" },
  { geneTranscript: "NM_001166114.2", parsimonySitesP: "0.203907203907204", constantSitesP: "0.701343101343101", distinctSitesP: "0.244200244200244", brLenTotal: "1.0146" },
  { geneTranscript: "NM_001024599.5", parsimonySitesP: "0.161375661375661", constantSitesP: "0.703703703703704", distinctSitesP: "0.277777777777778", brLenTotal: "1.5731" },
  { geneTranscript: "NM_020755.4", parsimonySitesP: "0.123620309050773", constantSitesP: "0.783664459161148", distinctSitesP: "0.171449595290655", brLenTotal: "0.4373" },
  { geneTranscript: "NM_003508.3", parsimonySitesP: "0.166384658770446", constantSitesP: "0.660462492949803", distinctSitesP: "0.22673434856176", brLenTotal: "0.8970" },
  { geneTranscript: "NM_017409.4", parsimonySitesP: "0.0779727095516569", constantSitesP: "0.839181286549708", distinctSitesP: "0.152046783625731", brLenTotal: "0.2735" },
  { geneTranscript: "NM_007365.3", parsimonySitesP: "0.186466165413534", constantSitesP: "0.697744360902256", distinctSitesP: "0.244611528822055", brLenTotal: "0.8718" },
  { geneTranscript: "NM_005997.3", parsimonySitesP: "0.1996336996337", constantSitesP: "0.708791208791209", distinctSitesP: "0.271062271062271", brLenTotal: "0.6334" },
  { geneTranscript: "NM_001012339.3", parsimonySitesP: "0.186440677966102", constantSitesP: "0.687382297551789", distinctSitesP: "0.310106716886378", brLenTotal: "0.6172" },
  { geneTranscript: "NM_017895.8", parsimonySitesP: "0.167320261437908", constantSitesP: "0.708061002178649", distinctSitesP: "0.242701525054466", brLenTotal: "0.6801" },
  { geneTranscript: "NM_012168.6", parsimonySitesP: "0.19481981981982", constantSitesP: "0.67454954954955", distinctSitesP: "0.309684684684685", brLenTotal: "0.8090" },
  { geneTranscript: "NM_020117.11", parsimonySitesP: "0.153061224489796", constantSitesP: "0.737528344671202", distinctSitesP: "0.223356009070295", brLenTotal: "0.5095" },
  { geneTranscript: "NM_000229.2", parsimonySitesP: "0.209848484848485", constantSitesP: "0.670454545454545", distinctSitesP: "0.284848484848485", brLenTotal: "0.8362" },
  { geneTranscript: "NM_005023.4", parsimonySitesP: "0.105216622458002", constantSitesP: "0.7789566755084", distinctSitesP: "0.186560565870911", brLenTotal: "0.3668" },
  { geneTranscript: "NM_001326342.2", parsimonySitesP: "0.0946896992962252", constantSitesP: "0.840690978886756", distinctSitesP: "0.137555982085733", brLenTotal: "0.3662" },
  { geneTranscript: "NM_017415.3", parsimonySitesP: "0.164679159568427", constantSitesP: "0.760931289040318", distinctSitesP: "0.211243611584327", brLenTotal: "0.6255" },
  { geneTranscript: "NM_001029885.2", parsimonySitesP: "0.294392523364486", constantSitesP: "0.551401869158879", distinctSitesP: "0.431464174454829", brLenTotal: "1.2297" },
  { geneTranscript: "NM_213720.3", parsimonySitesP: "0.227699530516432", constantSitesP: "0.553990610328638", distinctSitesP: "0.448356807511737", brLenTotal: "1.0536" },
  { geneTranscript: "NM_001005287.2", parsimonySitesP: "0.501075268817204", constantSitesP: "0.343010752688172", distinctSitesP: "0.644086021505376", brLenTotal: "3.7444" },
  { geneTranscript: "NM_002475.5", parsimonySitesP: "0.213141025641026", constantSitesP: "0.657051282051282", distinctSitesP: "0.315705128205128", brLenTotal: "0.8557" },
  { geneTranscript: "NM_001352964.2", parsimonySitesP: "0.195638629283489", constantSitesP: "0.686915887850467", distinctSitesP: "0.253582554517134", brLenTotal: "0.8475" },
  { geneTranscript: "NM_006828.4", parsimonySitesP: "0.143657281259461", constantSitesP: "0.750075688767787", distinctSitesP: "0.180896155010596", brLenTotal: "0.4809" },
  { geneTranscript: "NM_007277.5", parsimonySitesP: "0.222818791946309", constantSitesP: "0.682326621923937", distinctSitesP: "0.27427293064877", brLenTotal: "1.1211" },
  { geneTranscript: "NM_001017970.3", parsimonySitesP: "0.176638176638177", constantSitesP: "0.660018993352327", distinctSitesP: "0.284900284900285", brLenTotal: "0.6957" },
  { geneTranscript: "NM_005998.5", parsimonySitesP: "0.141896024464832", constantSitesP: "0.736391437308869", distinctSitesP: "0.224464831804281", brLenTotal: "0.5078" },
  { geneTranscript: "NM_022913.4", parsimonySitesP: "0.103594080338266", constantSitesP: "0.766032417195208", distinctSitesP: "0.188865398167724", brLenTotal: "0.3545" },
  { geneTranscript: "NM_138494.3", parsimonySitesP: "0.210454854039375", constantSitesP: "0.598099117447386", distinctSitesP: "0.315682281059063", brLenTotal: "0.6786" },
  { geneTranscript: "NM_007059.4", parsimonySitesP: "0.225535168195719", constantSitesP: "0.644495412844037", distinctSitesP: "0.333333333333333", brLenTotal: "0.9279" },
  { geneTranscript: "NM_012098.3", parsimonySitesP: "0.160919540229885", constantSitesP: "0.758620689655172", distinctSitesP: "0.212981744421907", brLenTotal: "0.7271" },
  { geneTranscript: "NM_006759.4", parsimonySitesP: "0.0918635170603675", constantSitesP: "0.822834645669291", distinctSitesP: "0.162073490813648", brLenTotal: "0.2994" },
  { geneTranscript: "NM_005797.4", parsimonySitesP: "0.175193798449612", constantSitesP: "0.68062015503876", distinctSitesP: "0.310077519379845", brLenTotal: "0.6475" },
  { geneTranscript: "NM_006803.4", parsimonySitesP: "0.194577352472089", constantSitesP: "0.694577352472089", distinctSitesP: "0.260765550239234", brLenTotal: "0.8102" },
  { geneTranscript: "NM_001282129.2", parsimonySitesP: "0.189885057471264", constantSitesP: "0.668735632183908", distinctSitesP: "0.254022988505747", brLenTotal: "0.6053" },
  { geneTranscript: "NM_005688.4", parsimonySitesP: "0.155184411969381", constantSitesP: "0.749710044073301", distinctSitesP: "0.184643934122013", brLenTotal: "0.5832" },
  { geneTranscript: "NM_006663.4", parsimonySitesP: "0.239935587761675", constantSitesP: "0.632447665056361", distinctSitesP: "0.338566827697262", brLenTotal: "1.0964" },
  { geneTranscript: "NM_006721.4", parsimonySitesP: "0.148250460405157", constantSitesP: "0.744014732965009", distinctSitesP: "0.224677716390424", brLenTotal: "0.4800" },
  { geneTranscript: "NM_001001995.3", parsimonySitesP: "0.117886178861789", constantSitesP: "0.835365853658537", distinctSitesP: "0.146341463414634", brLenTotal: "0.3566" },
  { geneTranscript: "NM_015002.3", parsimonySitesP: "0.143853998926463", constantSitesP: "0.761674718196457", distinctSitesP: "0.20504562533548", brLenTotal: "0.5265" },
  { geneTranscript: "NM_001019.5", parsimonySitesP: "0.128205128205128", constantSitesP: "0.646153846153846", distinctSitesP: "0.312820512820513", brLenTotal: "0.6333" },
  { geneTranscript: "NM_021916.4", parsimonySitesP: "0.308669656203288", constantSitesP: "0.45440956651719", distinctSitesP: "0.426008968609865", brLenTotal: "1.8942" },
  { geneTranscript: "NM_004446.3", parsimonySitesP: "0.17173721340388", constantSitesP: "0.702821869488536", distinctSitesP: "0.250440917107584", brLenTotal: "0.5994" },
  { geneTranscript: "NM_207386.4", parsimonySitesP: "0.128856624319419", constantSitesP: "0.785843920145191", distinctSitesP: "0.206896551724138", brLenTotal: "0.5339" },
  { geneTranscript: "NM_176894.3", parsimonySitesP: "0.26271186440678", constantSitesP: "0.589453860640301", distinctSitesP: "0.405838041431262", brLenTotal: "1.0124" },
  { geneTranscript: "NM_001005283.3", parsimonySitesP: "0.495753715498938", constantSitesP: "0.360934182590234", distinctSitesP: "0.628450106157113", brLenTotal: "2.3474" },
  { geneTranscript: "NM_017789.5", parsimonySitesP: "0.204081632653061", constantSitesP: "0.687875150060024", distinctSitesP: "0.255702280912365", brLenTotal: "0.8288" },
  { geneTranscript: "NM_004067.4", parsimonySitesP: "0.141025641025641", constantSitesP: "0.774216524216524", distinctSitesP: "0.207977207977208", brLenTotal: "0.5285" },
  { geneTranscript: "NM_001287.6", parsimonySitesP: "0.206211180124224", constantSitesP: "0.707246376811594", distinctSitesP: "0.296066252587992", brLenTotal: "1.0783" },
  { geneTranscript: "NM_001655.5", parsimonySitesP: "0.113502935420744", constantSitesP: "0.793215916503588", distinctSitesP: "0.16764514024788", brLenTotal: "0.3695" },
  { geneTranscript: "NM_173537.5", parsimonySitesP: "0.267298911134528", constantSitesP: "0.616438356164384", distinctSitesP: "0.347734457323498", brLenTotal: "1.1214" },
  { geneTranscript: "NM_014945.5", parsimonySitesP: "0.151781356759395", constantSitesP: "0.759882869692533", distinctSitesP: "0.206930209858468", brLenTotal: "0.5988" },
  { geneTranscript: "NM_001005469.2", parsimonySitesP: "0.430997876857749", constantSitesP: "0.373673036093418", distinctSitesP: "0.588110403397028", brLenTotal: "2.2630" },
  { geneTranscript: "NM_005527.4", parsimonySitesP: "0.348933957358294", constantSitesP: "0.563182527301092", distinctSitesP: "0.399895995839834", brLenTotal: "1.8793" },
  { geneTranscript: "NM_005662.7", parsimonySitesP: "0.177856301531213", constantSitesP: "0.729093050647821", distinctSitesP: "0.260306242638398", brLenTotal: "0.6423" },
  { geneTranscript: "NM_020444.5", parsimonySitesP: "0.216393442622951", constantSitesP: "0.619672131147541", distinctSitesP: "0.334426229508197", brLenTotal: "0.7713" },
  { geneTranscript: "NM_001025595.3", parsimonySitesP: "0.127792672028597", constantSitesP: "0.748882931188561", distinctSitesP: "0.213583556747096", brLenTotal: "0.4963" },
  { geneTranscript: "NM_001321623.1", parsimonySitesP: "0.0887372013651877", constantSitesP: "0.819112627986348", distinctSitesP: "0.145620022753129", brLenTotal: "0.2774" },
  { geneTranscript: "NM_015906.4", parsimonySitesP: "0.0943507837917776", constantSitesP: "0.826974267968057", distinctSitesP: "0.136054421768707", brLenTotal: "0.3104" },
  { geneTranscript: "NM_017506.2", parsimonySitesP: "0.587251828631139", constantSitesP: "0.248693834900731", distinctSitesP: "0.731452455590387", brLenTotal: "4.5005" },
  { geneTranscript: "NM_173491.4", parsimonySitesP: "0.169444444444444", constantSitesP: "0.718518518518519", distinctSitesP: "0.280555555555556", brLenTotal: "0.5915" },
  { geneTranscript: "NM_003097.6", parsimonySitesP: "0.161111111111111", constantSitesP: "0.75", distinctSitesP: "0.240277777777778", brLenTotal: "0.7349" },
  { geneTranscript: "NM_005667.4", parsimonySitesP: "0.11338199513382", constantSitesP: "0.779075425790754", distinctSitesP: "0.174209245742092", brLenTotal: "0.3833" },
  { geneTranscript: "NM_001040142.2", parsimonySitesP: "0.234081463009144", constantSitesP: "0.677306733167082", distinctSitesP: "0.259185369908562", brLenTotal: "1.3499" },
  { geneTranscript: "NM_001005501.2", parsimonySitesP: "0.5276008492569", constantSitesP: "0.320594479830149", distinctSitesP: "0.64755838641189", brLenTotal: "3.1303" },
  { geneTranscript: "NM_032167.5", parsimonySitesP: "0.19680196801968", constantSitesP: "0.685526855268553", distinctSitesP: "0.281262812628126", brLenTotal: "0.7983" },
  { geneTranscript: "NM_016654.5", parsimonySitesP: "0.107919930374238", constantSitesP: "0.819843342036554", distinctSitesP: "0.161009573542211", brLenTotal: "0.4143" },
  { geneTranscript: "NM_183419.4", parsimonySitesP: "0.0990453460620525", constantSitesP: "0.80151153540175", distinctSitesP: "0.15035799522673", brLenTotal: "0.3247" },
  { geneTranscript: "NM_032246.6", parsimonySitesP: "0.175746924428822", constantSitesP: "0.745166959578207", distinctSitesP: "0.226127709431752", brLenTotal: "0.6416" },
  { geneTranscript: "NM_006856.3", parsimonySitesP: "0.12008281573499", constantSitesP: "0.799171842650104", distinctSitesP: "0.175293305728088", brLenTotal: "0.3644" },
  { geneTranscript: "NM_001029.5", parsimonySitesP: "0.202898550724638", constantSitesP: "0.643478260869565", distinctSitesP: "0.347826086956522", brLenTotal: "0.8735" },
  { geneTranscript: "NM_005744.5", parsimonySitesP: "0.0730101735487732", constantSitesP: "0.86654697785757", distinctSitesP: "0.107121484141233", brLenTotal: "0.2493" },
  { geneTranscript: "NM_001350709.2", parsimonySitesP: "0.11706102117061", constantSitesP: "0.780821917808219", distinctSitesP: "0.175176421751764", brLenTotal: "0.4009" },
  { geneTranscript: "NM_000685.5", parsimonySitesP: "0.181058495821727", constantSitesP: "0.685236768802228", distinctSitesP: "0.265552460538533", brLenTotal: "0.7099" },
  { geneTranscript: "NM_001142351.2", parsimonySitesP: "0.263390044108381", constantSitesP: "0.592312539382483", distinctSitesP: "0.352236925015753", brLenTotal: "0.9943" },
  { geneTranscript: "NM_005067.7", parsimonySitesP: "0.119341563786008", constantSitesP: "0.787037037037037", distinctSitesP: "0.171810699588477", brLenTotal: "0.4846" },
  { geneTranscript: "NM_198236.3", parsimonySitesP: "0.206145966709347", constantSitesP: "0.654289372599232", distinctSitesP: "0.267819035424669", brLenTotal: "0.7879" },
  { geneTranscript: "NM_053025.4", parsimonySitesP: "0.219261581330547", constantSitesP: "0.658307210031348", distinctSitesP: "0.300069662138628", brLenTotal: "0.8886" },
  { geneTranscript: "NM_001145080.3", parsimonySitesP: "0.162464985994398", constantSitesP: "0.677521008403361", distinctSitesP: "0.240196078431373", brLenTotal: "0.4943" },
  { geneTranscript: "NM_005007.4", parsimonySitesP: "0.169728783902012", constantSitesP: "0.688538932633421", distinctSitesP: "0.244969378827647", brLenTotal: "0.5851" },
  { geneTranscript: "NM_001112706.3", parsimonySitesP: "0.202331002331002", constantSitesP: "0.664335664335664", distinctSitesP: "0.288111888111888", brLenTotal: "0.7114" },
  { geneTranscript: "NM_005210.4", parsimonySitesP: "0.340952380952381", constantSitesP: "0.544761904761905", distinctSitesP: "0.47047619047619", brLenTotal: "1.5017" },
  { geneTranscript: "NM_001372044.2", parsimonySitesP: "0.165458263617773", constantSitesP: "0.742517196504927", distinctSitesP: "0.2069157836029", brLenTotal: "0.7581" },
  { geneTranscript: "NM_014077.4", parsimonySitesP: "0.1875", constantSitesP: "0.675595238095238", distinctSitesP: "0.318452380952381", brLenTotal: "0.7160" },
  { geneTranscript: "NM_005519.2", parsimonySitesP: "0.178266178266178", constantSitesP: "0.669108669108669", distinctSitesP: "0.283272283272283", brLenTotal: "0.6908" },
  { geneTranscript: "NM_080861.4", parsimonySitesP: "0.254460093896714", constantSitesP: "0.64037558685446", distinctSitesP: "0.345539906103286", brLenTotal: "1.3641" },
  { geneTranscript: "NM_194272.3", parsimonySitesP: "0.180223285486443", constantSitesP: "0.69377990430622", distinctSitesP: "0.311004784688995", brLenTotal: "0.7851" },
  { geneTranscript: "NM_004068.4", parsimonySitesP: "0.124137931034483", constantSitesP: "0.757854406130268", distinctSitesP: "0.197701149425287", brLenTotal: "0.4568" },
  { geneTranscript: "NM_017849.4", parsimonySitesP: "0.159663865546218", constantSitesP: "0.743697478991597", distinctSitesP: "0.2296918767507", brLenTotal: "0.5756" },
  { geneTranscript: "NM_015000.4", parsimonySitesP: "0.0919540229885057", constantSitesP: "0.818247126436782", distinctSitesP: "0.149425287356322", brLenTotal: "0.3041" },
  { geneTranscript: "NM_001009921.3", parsimonySitesP: "0.157796451914099", constantSitesP: "0.73155929038282", distinctSitesP: "0.236461251167134", brLenTotal: "0.5405" },
  { geneTranscript: "NM_207363.3", parsimonySitesP: "0.28583900820674", constantSitesP: "0.538851056399511", distinctSitesP: "0.396368081019731", brLenTotal: "0.9945" },
  { geneTranscript: "NM_198576.4", parsimonySitesP: "0.314425427872861", constantSitesP: "0.554360228198859", distinctSitesP: "0.388264058679707", brLenTotal: "1.4648" },
  { geneTranscript: "NM_001290043.2", parsimonySitesP: "0.29980563654033", constantSitesP: "0.535471331389699", distinctSitesP: "0.412536443148688", brLenTotal: "1.1576" },
  { geneTranscript: "NM_001363540.2", parsimonySitesP: "0.135864978902954", constantSitesP: "0.781772151898734", distinctSitesP: "0.170464135021097", brLenTotal: "0.4983" },
  { geneTranscript: "NM_007126.5", parsimonySitesP: "0.115798180314309", constantSitesP: "0.812655086848635", distinctSitesP: "0.153846153846154", brLenTotal: "0.3546" },
  { geneTranscript: "NM_006540.4", parsimonySitesP: "0.130009107468124", constantSitesP: "0.788023679417122", distinctSitesP: "0.163479052823315", brLenTotal: "0.4450" },
  { geneTranscript: "NM_033445.3", parsimonySitesP: "0.258974358974359", constantSitesP: "0.687179487179487", distinctSitesP: "0.317948717948718", brLenTotal: "1.8567" },
  { geneTranscript: "NM_032892.5", parsimonySitesP: "0.0953216374269006", constantSitesP: "0.828654970760234", distinctSitesP: "0.14327485380117", brLenTotal: "0.3218" },
  { geneTranscript: "NM_031488.5", parsimonySitesP: "0.173522458628842", constantSitesP: "0.712529550827423", distinctSitesP: "0.246808510638298", brLenTotal: "0.6775" },
  { geneTranscript: "NM_012451.4", parsimonySitesP: "0.306267806267806", constantSitesP: "0.541310541310541", distinctSitesP: "0.423076923076923", brLenTotal: "1.1564" },
  { geneTranscript: "NM_012155.4", parsimonySitesP: "0.165896250642013", constantSitesP: "0.731895223420647", distinctSitesP: "0.228043143297381", brLenTotal: "0.7347" },
  { geneTranscript: "NM_001098814.2", parsimonySitesP: "0.170542635658915", constantSitesP: "0.739957716701903", distinctSitesP: "0.245947850599013", brLenTotal: "0.6038" },
  { geneTranscript: "NM_207299.2", parsimonySitesP: "0.100512820512821", constantSitesP: "0.820512820512821", distinctSitesP: "0.167179487179487", brLenTotal: "0.3682" },
  { geneTranscript: "NM_001007157.2", parsimonySitesP: "0.109001406469761", constantSitesP: "0.80028129395218", distinctSitesP: "0.161040787623066", brLenTotal: "0.3734" },
  { geneTranscript: "NM_001286574.2", parsimonySitesP: "0.232352941176471", constantSitesP: "0.637254901960784", distinctSitesP: "0.32156862745098", brLenTotal: "0.7969" },
  { geneTranscript: "NM_001139442.2", parsimonySitesP: "0.238028169014085", constantSitesP: "0.637558685446009", distinctSitesP: "0.336619718309859", brLenTotal: "0.8855" },
  { geneTranscript: "NM_001042683.3", parsimonySitesP: "0.178055060408002", constantSitesP: "0.71558724499901", distinctSitesP: "0.221033868092692", brLenTotal: "0.5468" },
  { geneTranscript: "NM_014951.3", parsimonySitesP: "0.200655200655201", constantSitesP: "0.681408681408681", distinctSitesP: "0.276822276822277", brLenTotal: "0.6666" },
  { geneTranscript: "NM_002626.6", parsimonySitesP: "0.214529914529915", constantSitesP: "0.689316239316239", distinctSitesP: "0.279059829059829", brLenTotal: "1.2097" },
  { geneTranscript: "NM_199328.3", parsimonySitesP: "0.253333333333333", constantSitesP: "0.613333333333333", distinctSitesP: "0.361481481481481", brLenTotal: "0.7826" },
  { geneTranscript: "NM_003301.7", parsimonySitesP: "0.17001675041876", constantSitesP: "0.724455611390285", distinctSitesP: "0.237018425460637", brLenTotal: "0.5090" },
  { geneTranscript: "NM_001005497.2", parsimonySitesP: "0.523504273504274", constantSitesP: "0.370726495726496", distinctSitesP: "0.620726495726496", brLenTotal: "2.9939" },
  { geneTranscript: "NM_012325.3", parsimonySitesP: "0.152985074626866", constantSitesP: "0.659203980099502", distinctSitesP: "0.286069651741294", brLenTotal: "0.6677" },
  { geneTranscript: "NM_005611.4", parsimonySitesP: "0.157448053848405", constantSitesP: "0.718759145449224", distinctSitesP: "0.208955223880597", brLenTotal: "0.4965" },
  { geneTranscript: "NM_024923.4", parsimonySitesP: "0.264087617028793", constantSitesP: "0.554672319378202", distinctSitesP: "0.353471118177001", brLenTotal: "1.2499" },
  { geneTranscript: "NM_004278.4", parsimonySitesP: "0.267195767195767", constantSitesP: "0.597883597883598", distinctSitesP: "0.412698412698413", brLenTotal: "0.8704" },
  { geneTranscript: "NM_007037.6", parsimonySitesP: "0.218597675290589", constantSitesP: "0.644169478815148", distinctSitesP: "0.282714660667417", brLenTotal: "0.9607" },
  { geneTranscript: "NM_017994.5", parsimonySitesP: "0.159235668789809", constantSitesP: "0.763269639065817", distinctSitesP: "0.220806794055202", brLenTotal: "0.5645" },
  { geneTranscript: "NM_001420.4", parsimonySitesP: "0.129881925522252", constantSitesP: "0.799273387829246", distinctSitesP: "0.184377838328792", brLenTotal: "0.6558" },
  { geneTranscript: "NM_001338.5", parsimonySitesP: "0.187214611872146", constantSitesP: "0.673059360730594", distinctSitesP: "0.302283105022831", brLenTotal: "0.6438" },
  { geneTranscript: "NM_018834.6", parsimonySitesP: "0.0794962613144431", constantSitesP: "0.80952380952381", distinctSitesP: "0.144824872097599", brLenTotal: "0.3293" },
  { geneTranscript: "NM_022341.2", parsimonySitesP: "0.368998628257888", constantSitesP: "0.462277091906722", distinctSitesP: "0.528120713305898", brLenTotal: "1.4403" },
  { geneTranscript: "NM_003111.5", parsimonySitesP: "0.0926163038839095", constantSitesP: "0.800256081946223", distinctSitesP: "0.163038839095177", brLenTotal: "0.3118" },
  { geneTranscript: "NM_199141.2", parsimonySitesP: "0.133771929824561", constantSitesP: "0.790570175438596", distinctSitesP: "0.18859649122807", brLenTotal: "0.7186" },
  { geneTranscript: "NM_000270.4", parsimonySitesP: "0.274509803921569", constantSitesP: "0.575547866205306", distinctSitesP: "0.418685121107266", brLenTotal: "0.9026" },
  { geneTranscript: "NM_001281956.2", parsimonySitesP: "0.170201046543652", constantSitesP: "0.72734783806114", distinctSitesP: "0.181951712108694", brLenTotal: "0.7085" },
  { geneTranscript: "NM_005581.5", parsimonySitesP: "0.300955414012739", constantSitesP: "0.573248407643312", distinctSitesP: "0.402335456475584", brLenTotal: "1.2413" },
  { geneTranscript: "NM_001267723.2", parsimonySitesP: "0.231770833333333", constantSitesP: "0.634765625", distinctSitesP: "0.326171875", brLenTotal: "0.8673" },
  { geneTranscript: "NM_003941.4", parsimonySitesP: "0.130693069306931", constantSitesP: "0.780858085808581", distinctSitesP: "0.192739273927393", brLenTotal: "0.4222" },
  { geneTranscript: "NM_018291.5", parsimonySitesP: "0.184513006654567", constantSitesP: "0.710223835450696", distinctSitesP: "0.295825771324864", brLenTotal: "0.6738" },
  { geneTranscript: "NM_001396408.1", parsimonySitesP: "0.134099616858238", constantSitesP: "0.754789272030651", distinctSitesP: "0.28735632183908", brLenTotal: "0.5128" },
  { geneTranscript: "NM_058164.4", parsimonySitesP: "0.219530102790015", constantSitesP: "0.6784140969163", distinctSitesP: "0.275330396475771", brLenTotal: "1.0955" },
  { geneTranscript: "NM_001161376.2", parsimonySitesP: "0.32997311827957", constantSitesP: "0.494623655913978", distinctSitesP: "0.445564516129032", brLenTotal: "1.3147" },
  { geneTranscript: "NM_001367561.1", parsimonySitesP: "0.143146417445483", constantSitesP: "0.758411214953271", distinctSitesP: "0.169781931464174", brLenTotal: "0.5147" },
  { geneTranscript: "NM_181612.3", parsimonySitesP: "0.488505747126437", constantSitesP: "0.367816091954023", distinctSitesP: "0.632183908045977", brLenTotal: "2.7992" },
  { geneTranscript: "NM_002309.5", parsimonySitesP: "0.196369636963696", constantSitesP: "0.706270627062706", distinctSitesP: "0.259075907590759", brLenTotal: "0.7847" },
  { geneTranscript: "NM_173666.4", parsimonySitesP: "0.228187919463087", constantSitesP: "0.625279642058166", distinctSitesP: "0.365771812080537", brLenTotal: "0.7472" },
  { geneTranscript: "NM_004189.4", parsimonySitesP: "0.0819444444444444", constantSitesP: "0.861111111111111", distinctSitesP: "0.133333333333333", brLenTotal: "0.2744" },
  { geneTranscript: "NM_000125.4", parsimonySitesP: "0.166386554621849", constantSitesP: "0.72436974789916", distinctSitesP: "0.231932773109244", brLenTotal: "0.5959" },
  { geneTranscript: "NM_000896.3", parsimonySitesP: "0.345512820512821", constantSitesP: "0.473717948717949", distinctSitesP: "0.496794871794872", brLenTotal: "2.3179" },
  { geneTranscript: "NM_007375.4", parsimonySitesP: "0.156199677938808", constantSitesP: "0.70048309178744", distinctSitesP: "0.27536231884058", brLenTotal: "0.6394" },
  { geneTranscript: "NM_005216.5", parsimonySitesP: "0.189825360668185", constantSitesP: "0.713743356112377", distinctSitesP: "0.255884586180714", brLenTotal: "0.7197" },
  { geneTranscript: "NM_033486.3", parsimonySitesP: "0.18542199488491", constantSitesP: "0.720375106564365", distinctSitesP: "0.273231031543052", brLenTotal: "1.0278" },
  { geneTranscript: "NM_001003443.3", parsimonySitesP: "0.410582010582011", constantSitesP: "0.442328042328042", distinctSitesP: "0.565079365079365", brLenTotal: "2.6507" },
  { geneTranscript: "NM_080391.4", parsimonySitesP: "0.0858283433133733", constantSitesP: "0.818363273453094", distinctSitesP: "0.181636726546906", brLenTotal: "0.2824" },
  { geneTranscript: "NM_003202.5", parsimonySitesP: "0.180555555555556", constantSitesP: "0.690104166666667", distinctSitesP: "0.293402777777778", brLenTotal: "0.6607" },
  { geneTranscript: "NM_006009.4", parsimonySitesP: "0.373983739837398", constantSitesP: "0.46119733924612", distinctSitesP: "0.49519586104952", brLenTotal: "2.5096" },
  { geneTranscript: "NM_004128.3", parsimonySitesP: "0.153949129852744", constantSitesP: "0.734939759036145", distinctSitesP: "0.240963855421687", brLenTotal: "0.5778" },
  { geneTranscript: "NM_001349.4", parsimonySitesP: "0.110445775116434", constantSitesP: "0.789753825681969", distinctSitesP: "0.181636726546906", brLenTotal: "0.3884" },
  { geneTranscript: "NM_002789.6", parsimonySitesP: "0.14176245210728", constantSitesP: "0.75095785440613", distinctSitesP: "0.220945083014049", brLenTotal: "0.5171" },
  { geneTranscript: "NM_030770.4", parsimonySitesP: "0.304157549234136", constantSitesP: "0.525164113785558", distinctSitesP: "0.439095550692925", brLenTotal: "1.1764" },
  { geneTranscript: "NM_006546.4", parsimonySitesP: "0.150202195262854", constantSitesP: "0.780473714615829", distinctSitesP: "0.187752744078567", brLenTotal: "0.5453" },
  { geneTranscript: "NM_014476.6", parsimonySitesP: "0.194139194139194", constantSitesP: "0.720695970695971", distinctSitesP: "0.282051282051282", brLenTotal: "0.8089" },
  { geneTranscript: "NM_178518.3", parsimonySitesP: "0.280839895013123", constantSitesP: "0.568897637795276", distinctSitesP: "0.375984251968504", brLenTotal: "0.9573" },
  { geneTranscript: "NM_001008703.4", parsimonySitesP: "0.156862745098039", constantSitesP: "0.748366013071895", distinctSitesP: "0.264705882352941", brLenTotal: "0.4835" },
  { geneTranscript: "NM_198573.3", parsimonySitesP: "0.118421052631579", constantSitesP: "0.767543859649123", distinctSitesP: "0.236842105263158", brLenTotal: "0.3949" },
  { geneTranscript: "NM_014738.6", parsimonySitesP: "0.172074729596853", constantSitesP: "0.716568338249754", distinctSitesP: "0.202310717797443", brLenTotal: "0.7270" },
  { geneTranscript: "NM_014236.4", parsimonySitesP: "0.199019607843137", constantSitesP: "0.632843137254902", distinctSitesP: "0.306862745098039", brLenTotal: "0.7275" },
  { geneTranscript: "NM_018287.7", parsimonySitesP: "0.153270291568164", constantSitesP: "0.739952718676123", distinctSitesP: "0.235224586288416", brLenTotal: "0.5013" },
  { geneTranscript: "NM_016032.4", parsimonySitesP: "0.114468864468864", constantSitesP: "0.798534798534799", distinctSitesP: "0.177655677655678", brLenTotal: "0.3687" },
  { geneTranscript: "NM_002212.4", parsimonySitesP: "0.156462585034014", constantSitesP: "0.738775510204082", distinctSitesP: "0.248979591836735", brLenTotal: "0.5154" },
  { geneTranscript: "NM_012101.4", parsimonySitesP: "0.175736961451247", constantSitesP: "0.698979591836735", distinctSitesP: "0.246598639455782", brLenTotal: "0.8148" },
  { geneTranscript: "NM_001354604.2", parsimonySitesP: "0.129911280101394", constantSitesP: "0.76489226869455", distinctSitesP: "0.189480354879594", brLenTotal: "0.5102" },
  { geneTranscript: "NM_000475.5", parsimonySitesP: "0.314893617021277", constantSitesP: "0.543971631205674", distinctSitesP: "0.378723404255319", brLenTotal: "0.9616" },
  { geneTranscript: "NM_003246.4", parsimonySitesP: "0.149002849002849", constantSitesP: "0.765811965811966", distinctSitesP: "0.190598290598291", brLenTotal: "0.6325" },
  { geneTranscript: "NM_145914.3", parsimonySitesP: "0.251585623678647", constantSitesP: "0.593375616631431", distinctSitesP: "0.374207188160677", brLenTotal: "0.9455" },
  { geneTranscript: "NM_032112.3", parsimonySitesP: "0.285115303983229", constantSitesP: "0.528301886792453", distinctSitesP: "0.431865828092243", brLenTotal: "0.9919" },
  { geneTranscript: "NM_003437.5", parsimonySitesP: "0.408024691358025", constantSitesP: "0.426543209876543", distinctSitesP: "0.546296296296296", brLenTotal: "2.0983" },
  { geneTranscript: "NM_001320485.2", parsimonySitesP: "0.213652482269504", constantSitesP: "0.664007092198582", distinctSitesP: "0.320035460992908", brLenTotal: "1.1167" },
  { geneTranscript: "NM_012291.5", parsimonySitesP: "0.257389937106918", constantSitesP: "0.573899371069182", distinctSitesP: "0.296069182389937", brLenTotal: "0.8255" },
  { geneTranscript: "NM_003734.4", parsimonySitesP: "0.416775884665793", constantSitesP: "0.45128877238969", distinctSitesP: "0.507645259938838", brLenTotal: "2.3857" },
  { geneTranscript: "NM_016077.5", parsimonySitesP: "0.221601489757914", constantSitesP: "0.592178770949721", distinctSitesP: "0.374301675977654", brLenTotal: "0.7478" },
  { geneTranscript: "NM_000155.4", parsimonySitesP: "0.160070360598065", constantSitesP: "0.700087950747581", distinctSitesP: "0.254177660510114", brLenTotal: "0.5567" },
  { geneTranscript: "NM_003192.3", parsimonySitesP: "0.279383429672447", constantSitesP: "0.515414258188825", distinctSitesP: "0.436416184971098", brLenTotal: "0.9547" },
  { geneTranscript: "NM_020786.4", parsimonySitesP: "0.218651543793321", constantSitesP: "0.649023314429742", distinctSitesP: "0.306868304977946", brLenTotal: "0.7495" },
  { geneTranscript: "NM_144721.6", parsimonySitesP: "0.168168168168168", constantSitesP: "0.716216216216216", distinctSitesP: "0.27027027027027", brLenTotal: "0.5165" },
  { geneTranscript: "NM_001286620.2", parsimonySitesP: "0.169014084507042", constantSitesP: "0.725352112676056", distinctSitesP: "0.26056338028169", brLenTotal: "0.5302" },
  { geneTranscript: "NM_004993.6", parsimonySitesP: "0.168975069252078", constantSitesP: "0.656509695290859", distinctSitesP: "0.318559556786704", brLenTotal: "0.7745" },
  { geneTranscript: "NM_001370087.1", parsimonySitesP: "0.309090909090909", constantSitesP: "0.567676767676768", distinctSitesP: "0.43030303030303", brLenTotal: "1.2604" },
  { geneTranscript: "NM_000145.4", parsimonySitesP: "0.187529976019185", constantSitesP: "0.658513189448441", distinctSitesP: "0.290167865707434", brLenTotal: "0.6336" },
  { geneTranscript: "NM_016321.3", parsimonySitesP: "0.265831593597773", constantSitesP: "0.625608907446068", distinctSitesP: "0.356297842727905", brLenTotal: "1.1955" },
  { geneTranscript: "NM_001035235.4", parsimonySitesP: "0.21577380952381", constantSitesP: "0.59375", distinctSitesP: "0.361607142857143", brLenTotal: "0.7526" },
  { geneTranscript: "NM_133466.4", parsimonySitesP: "0.37531328320802", constantSitesP: "0.419172932330827", distinctSitesP: "0.492481203007519", brLenTotal: "1.9778" },
  { geneTranscript: "NM_138328.3", parsimonySitesP: "0.161716171617162", constantSitesP: "0.748349834983498", distinctSitesP: "0.231023102310231", brLenTotal: "0.6396" },
  { geneTranscript: "NM_015551.2", parsimonySitesP: "0.3232644409115", constantSitesP: "0.502914679385268", distinctSitesP: "0.422363540010599", brLenTotal: "1.1881" },
  { geneTranscript: "NM_005328.3", parsimonySitesP: "0.126207729468599", constantSitesP: "0.767512077294686", distinctSitesP: "0.172705314009662", brLenTotal: "0.5430" },
  { geneTranscript: "NM_024794.3", parsimonySitesP: "0.257407407407407", constantSitesP: "0.605555555555556", distinctSitesP: "0.364814814814815", brLenTotal: "0.9778" },
  { geneTranscript: "NM_001202.6", parsimonySitesP: "0.108660130718954", constantSitesP: "0.808823529411765", distinctSitesP: "0.165849673202614", brLenTotal: "0.3519" },
  { geneTranscript: "NM_004455.3", parsimonySitesP: "0.266765285996055", constantSitesP: "0.571005917159763", distinctSitesP: "0.350098619329389", brLenTotal: "0.9996" },
  { geneTranscript: "NM_015058.2", parsimonySitesP: "0.189851268591426", constantSitesP: "0.680839895013123", distinctSitesP: "0.261242344706912", brLenTotal: "0.6658" },
  { geneTranscript: "NM_003532.3", parsimonySitesP: "0.323529411764706", constantSitesP: "0.647058823529412", distinctSitesP: "0.360294117647059", brLenTotal: "2.1089" },
  { geneTranscript: "NM_173601.2", parsimonySitesP: "0.161363636363636", constantSitesP: "0.709090909090909", distinctSitesP: "0.271212121212121", brLenTotal: "0.5370" },
  { geneTranscript: "NM_003483.6", parsimonySitesP: "0.091743119266055", constantSitesP: "0.758409785932722", distinctSitesP: "0.232415902140673", brLenTotal: "0.3803" },
  { geneTranscript: "NM_007194.4", parsimonySitesP: "0.175567833026397", constantSitesP: "0.718232044198895", distinctSitesP: "0.264579496623696", brLenTotal: "0.5813" },
  { geneTranscript: "NM_003592.3", parsimonySitesP: "0.141323024054983", constantSitesP: "0.77319587628866", distinctSitesP: "0.207903780068729", brLenTotal: "0.5773" },
  { geneTranscript: "NM_024514.5", parsimonySitesP: "0.165003326679973", constantSitesP: "0.686626746506986", distinctSitesP: "0.263473053892216", brLenTotal: "0.5321" },
  { geneTranscript: "NM_030752.3", parsimonySitesP: "0.137290167865707", constantSitesP: "0.712230215827338", distinctSitesP: "0.241007194244604", brLenTotal: "0.5221" },
  { geneTranscript: "NM_021168.5", parsimonySitesP: "0.201660735468565", constantSitesP: "0.712930011862396", distinctSitesP: "0.260972716488731", brLenTotal: "1.0305" },
  { geneTranscript: "NM_005862.3", parsimonySitesP: "0.0871754107048225", constantSitesP: "0.839427662957075", distinctSitesP: "0.117647058823529", brLenTotal: "0.2888" },
  { geneTranscript: "NM_177987.3", parsimonySitesP: "0.352102102102102", constantSitesP: "0.496246246246246", distinctSitesP: "0.472972972972973", brLenTotal: "2.5069" },
  { geneTranscript: "NM_004096.5", parsimonySitesP: "0.122222222222222", constantSitesP: "0.75", distinctSitesP: "0.255555555555556", brLenTotal: "0.3882" },
  { geneTranscript: "NM_002213.5", parsimonySitesP: "0.206925323320818", constantSitesP: "0.685022945348352", distinctSitesP: "0.282436378806842", brLenTotal: "0.7968" },
  { geneTranscript: "NM_014033.4", parsimonySitesP: "0.315573770491803", constantSitesP: "0.524590163934426", distinctSitesP: "0.457650273224044", brLenTotal: "1.4194" },
  { geneTranscript: "NM_001550.4", parsimonySitesP: "0.141167775314117", constantSitesP: "0.736141906873614", distinctSitesP: "0.22690317812269", brLenTotal: "0.4889" },
  { geneTranscript: "NM_001008783.3", parsimonySitesP: "0.169070512820513", constantSitesP: "0.715544871794872", distinctSitesP: "0.266826923076923", brLenTotal: "0.6775" },
  { geneTranscript: "NM_015954.4", parsimonySitesP: "0.167714884696017", constantSitesP: "0.721174004192872", distinctSitesP: "0.277777777777778", brLenTotal: "0.6363" },
  { geneTranscript: "NM_145808.4", parsimonySitesP: "0.0903954802259887", constantSitesP: "0.850282485875706", distinctSitesP: "0.155367231638418", brLenTotal: "0.2436" },
  { geneTranscript: "NM_002334.4", parsimonySitesP: "0.158180227471566", constantSitesP: "0.73840769903762", distinctSitesP: "0.186176727909011", brLenTotal: "0.5991" },
  { geneTranscript: "NM_001085347.3", parsimonySitesP: "0.228452751817238", constantSitesP: "0.626168224299065", distinctSitesP: "0.313603322949117", brLenTotal: "0.9178" },
  { geneTranscript: "NM_199243.3", parsimonySitesP: "0.321812596006144", constantSitesP: "0.497695852534562", distinctSitesP: "0.436251920122888", brLenTotal: "1.2298" },
  { geneTranscript: "NM_005413.4", parsimonySitesP: "0.0592369477911647", constantSitesP: "0.831325301204819", distinctSitesP: "0.146586345381526", brLenTotal: "0.2575" },
  { geneTranscript: "NM_001300816.3", parsimonySitesP: "0.196180555555556", constantSitesP: "0.640625", distinctSitesP: "0.364583333333333", brLenTotal: "0.6746" },
  { geneTranscript: "NM_001304808.3", parsimonySitesP: "0.180543874404261", constantSitesP: "0.742921222315671", distinctSitesP: "0.214465937762826", brLenTotal: "0.8715" },
  { geneTranscript: "NM_002595.5", parsimonySitesP: "0.112173358827279", constantSitesP: "0.801147227533461", distinctSitesP: "0.186743148502231", brLenTotal: "0.3847" },
  { geneTranscript: "NM_003735.3", parsimonySitesP: "0.413805436337625", constantSitesP: "0.48032904148784", distinctSitesP: "0.488197424892704", brLenTotal: "3.3312" },
  { geneTranscript: "NM_001198784.2", parsimonySitesP: "0.190082644628099", constantSitesP: "0.647382920110193", distinctSitesP: "0.37465564738292", brLenTotal: "0.6459" },
  { geneTranscript: "NM_007010.5", parsimonySitesP: "0.168057874234836", constantSitesP: "0.686143572621035", distinctSitesP: "0.293266555370061", brLenTotal: "0.5614" },
  { geneTranscript: "NM_032484.5", parsimonySitesP: "0.313836477987421", constantSitesP: "0.519496855345912", distinctSitesP: "0.410062893081761", brLenTotal: "1.1263" },
  { geneTranscript: "NM_016480.5", parsimonySitesP: "0.0603674540682415", constantSitesP: "0.847769028871391", distinctSitesP: "0.146981627296588", brLenTotal: "0.1976" },
  { geneTranscript: "NM_207354.3", parsimonySitesP: "0.196694214876033", constantSitesP: "0.705785123966942", distinctSitesP: "0.253443526170799", brLenTotal: "0.7959" },
  { geneTranscript: "NM_001077628.3", parsimonySitesP: "0.070440251572327", constantSitesP: "0.850314465408805", distinctSitesP: "0.138364779874214", brLenTotal: "0.2350" },
  { geneTranscript: "NM_052965.4", parsimonySitesP: "0.206627680311891", constantSitesP: "0.641325536062378", distinctSitesP: "0.354775828460039", brLenTotal: "0.7003" },
  { geneTranscript: "NM_001388.5", parsimonySitesP: "0.149267399267399", constantSitesP: "0.755494505494505", distinctSitesP: "0.21978021978022", brLenTotal: "0.6451" },
  { geneTranscript: "NM_138773.4", parsimonySitesP: "0.125996810207337", constantSitesP: "0.767942583732057", distinctSitesP: "0.218500797448166", brLenTotal: "0.4359" },
  { geneTranscript: "NM_018922.3", parsimonySitesP: "0.474649406688242", constantSitesP: "0.385472851492269", distinctSitesP: "0.593311758360302", brLenTotal: "4.2873" },
  { geneTranscript: "NM_015440.5", parsimonySitesP: "0.216768916155419", constantSitesP: "0.654737559645535", distinctSitesP: "0.32788002726653", brLenTotal: "0.9051" },
  { geneTranscript: "NM_022153.2", parsimonySitesP: "0.216505894962487", constantSitesP: "0.645230439442658", distinctSitesP: "0.334405144694534", brLenTotal: "0.8723" },
  { geneTranscript: "NM_003798.4", parsimonySitesP: "0.153042688465032", constantSitesP: "0.718437783832879", distinctSitesP: "0.244323342415985", brLenTotal: "0.5147" },
  { geneTranscript: "NM_012404.3", parsimonySitesP: "0.312977099236641", constantSitesP: "0.374045801526718", distinctSitesP: "0.615776081424936", brLenTotal: "1.4761" },
  { geneTranscript: "NM_001905.4", parsimonySitesP: "0.166384658770446", constantSitesP: "0.736040609137056", distinctSitesP: "0.217710095882685", brLenTotal: "0.6141" },
  { geneTranscript: "NM_213618.2", parsimonySitesP: "0.150688947522721", constantSitesP: "0.732629727352682", distinctSitesP: "0.193784813837584", brLenTotal: "0.4948" },
  { geneTranscript: "NM_022821.4", parsimonySitesP: "0.152927120669056", constantSitesP: "0.767025089605735", distinctSitesP: "0.217443249701314", brLenTotal: "0.4491" },
  { geneTranscript: "NM_145239.3", parsimonySitesP: "0.248039215686275", constantSitesP: "0.590196078431373", distinctSitesP: "0.36078431372549", brLenTotal: "0.8321" },
  { geneTranscript: "NM_201433.2", parsimonySitesP: "0.131652661064426", constantSitesP: "0.792016806722689", distinctSitesP: "0.189075630252101", brLenTotal: "0.5504" },
  { geneTranscript: "NM_002073.4", parsimonySitesP: "0.155868544600939", constantSitesP: "0.776525821596244", distinctSitesP: "0.204694835680751", brLenTotal: "0.7056" },
  { geneTranscript: "NM_002234.4", parsimonySitesP: "0.32952691680261", constantSitesP: "0.548123980424144", distinctSitesP: "0.419249592169657", brLenTotal: "2.1737" },
  { geneTranscript: "NM_003527.4", parsimonySitesP: "0.169312169312169", constantSitesP: "0.743386243386243", distinctSitesP: "0.261904761904762", brLenTotal: "1.4070" },
  { geneTranscript: "NM_003522.4", parsimonySitesP: "0.17989417989418", constantSitesP: "0.642857142857143", distinctSitesP: "0.328042328042328", brLenTotal: "1.3685" },
  { geneTranscript: "NM_001031738.3", parsimonySitesP: "0.164821648216482", constantSitesP: "0.746617466174662", distinctSitesP: "0.217712177121771", brLenTotal: "0.5534" },
  { geneTranscript: "NM_152743.4", parsimonySitesP: "0.353633779943159", constantSitesP: "0.501015022330491", distinctSitesP: "0.455948030856679", brLenTotal: "1.5907" },
  { geneTranscript: "NM_005776.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.796296296296296", distinctSitesP: "0.208333333333333", brLenTotal: "0.3302" },
  { geneTranscript: "NM_015060.3", parsimonySitesP: "0.160493827160494", constantSitesP: "0.715020576131687", distinctSitesP: "0.251028806584362", brLenTotal: "0.5503" },
  { geneTranscript: "NM_173794.4", parsimonySitesP: "0.139784946236559", constantSitesP: "0.718279569892473", distinctSitesP: "0.273118279569892", brLenTotal: "0.4624" },
  { geneTranscript: "NM_001122770.3", parsimonySitesP: "0.100728959575878", constantSitesP: "0.788601722995361", distinctSitesP: "0.163021868787276", brLenTotal: "0.3440" },
  { geneTranscript: "NM_007241.4", parsimonySitesP: "0.127906976744186", constantSitesP: "0.760981912144703", distinctSitesP: "0.233850129198966", brLenTotal: "0.4700" },
  { geneTranscript: "NM_002060.3", parsimonySitesP: "0.202202202202202", constantSitesP: "0.592592592592593", distinctSitesP: "0.309309309309309", brLenTotal: "1.0469" },
  { geneTranscript: "NM_021248.3", parsimonySitesP: "0.175523349436393", constantSitesP: "0.717391304347826", distinctSitesP: "0.249597423510467", brLenTotal: "0.8320" },
  { geneTranscript: "NM_015896.4", parsimonySitesP: "0.206818181818182", constantSitesP: "0.648484848484848", distinctSitesP: "0.281060606060606", brLenTotal: "0.7349" },
  { geneTranscript: "NM_002246.3", parsimonySitesP: "0.148054145516074", constantSitesP: "0.781725888324873", distinctSitesP: "0.202199661590525", brLenTotal: "0.5117" },
  { geneTranscript: "NM_001144888.2", parsimonySitesP: "0.200998751560549", constantSitesP: "0.707240948813983", distinctSitesP: "0.260299625468165", brLenTotal: "1.0471" },
  { geneTranscript: "NM_000691.5", parsimonySitesP: "0.231788079470199", constantSitesP: "0.637969094922737", distinctSitesP: "0.326710816777042", brLenTotal: "1.0072" },
  { geneTranscript: "NM_021806.4", parsimonySitesP: "0.182608695652174", constantSitesP: "0.721739130434783", distinctSitesP: "0.271014492753623", brLenTotal: "0.6456" },
  { geneTranscript: "NM_001012301.4", parsimonySitesP: "0.187463386057411", constantSitesP: "0.701816051552431", distinctSitesP: "0.239601640304628", brLenTotal: "0.9189" },
  { geneTranscript: "NM_001078173.2", parsimonySitesP: "0.327433628318584", constantSitesP: "0.474926253687316", distinctSitesP: "0.507374631268437", brLenTotal: "1.8593" },
  { geneTranscript: "NM_001005512.2", parsimonySitesP: "0.606256742179072", constantSitesP: "0.302049622437972", distinctSitesP: "0.701186623516721", brLenTotal: "5.6153" },
  { geneTranscript: "NM_001385562.1", parsimonySitesP: "0.132231404958678", constantSitesP: "0.759543486816214", distinctSitesP: "0.196772924045651", brLenTotal: "0.4952" },
  { geneTranscript: "NM_203350.3", parsimonySitesP: "0.0656565656565657", constantSitesP: "0.824242424242424", distinctSitesP: "0.147474747474747", brLenTotal: "0.2505" },
  { geneTranscript: "NM_001384609.1", parsimonySitesP: "0.142310368823939", constantSitesP: "0.764787752261656", distinctSitesP: "0.185107863604732", brLenTotal: "0.4775" },
  { geneTranscript: "NM_170745.3", parsimonySitesP: "0.402035623409669", constantSitesP: "0.486005089058524", distinctSitesP: "0.488549618320611", brLenTotal: "3.5035" },
  { geneTranscript: "NM_020764.4", parsimonySitesP: "0.242720708129513", constantSitesP: "0.64593524341952", distinctSitesP: "0.293733985557885", brLenTotal: "1.1275" },
  { geneTranscript: "NM_001256627.2", parsimonySitesP: "0.188405797101449", constantSitesP: "0.745923913043478", distinctSitesP: "0.225996376811594", brLenTotal: "1.0860" },
  { geneTranscript: "NM_001142651.3", parsimonySitesP: "0.249249249249249", constantSitesP: "0.648048048048048", distinctSitesP: "0.301501501501502", brLenTotal: "1.0186" },
  { geneTranscript: "NM_003105.6", parsimonySitesP: "0.185636856368564", constantSitesP: "0.706112616681722", distinctSitesP: "0.227943390545017", brLenTotal: "0.7388" },
  { geneTranscript: "NM_018376.4", parsimonySitesP: "0.278002699055331", constantSitesP: "0.545209176788124", distinctSitesP: "0.445344129554656", brLenTotal: "1.1662" },
  { geneTranscript: "NM_031439.4", parsimonySitesP: "0.221649484536082", constantSitesP: "0.615120274914089", distinctSitesP: "0.326460481099656", brLenTotal: "1.0563" },
  { geneTranscript: "NM_006351.4", parsimonySitesP: "0.22787610619469", constantSitesP: "0.660766961651917", distinctSitesP: "0.31858407079646", brLenTotal: "0.8813" },
  { geneTranscript: "NM_173582.6", parsimonySitesP: "0.12593783494105", constantSitesP: "0.741693461950697", distinctSitesP: "0.211682743837085", brLenTotal: "0.4477" },
  { geneTranscript: "NM_133433.4", parsimonySitesP: "0.0840466000951022", constantSitesP: "0.845934379457917", distinctSitesP: "0.101640513552068", brLenTotal: "0.2652" },
  { geneTranscript: "NM_003242.6", parsimonySitesP: "0.174603174603175", constantSitesP: "0.727807172251617", distinctSitesP: "0.234567901234568", brLenTotal: "0.6686" },
  { geneTranscript: "NM_020190.5", parsimonySitesP: "0.160919540229885", constantSitesP: "0.725779967159278", distinctSitesP: "0.248768472906404", brLenTotal: "0.6866" },
  { geneTranscript: "NM_001025603.2", parsimonySitesP: "0.227813852813853", constantSitesP: "0.609848484848485", distinctSitesP: "0.32521645021645", brLenTotal: "0.7572" },
  { geneTranscript: "NM_001395891.1", parsimonySitesP: "0.130425486422921", constantSitesP: "0.769937994440881", distinctSitesP: "0.177036561898653", brLenTotal: "0.5353" },
  { geneTranscript: "NM_138711.6", parsimonySitesP: "0.145263157894737", constantSitesP: "0.752982456140351", distinctSitesP: "0.213333333333333", brLenTotal: "0.6392" },
  { geneTranscript: "NM_001080426.3", parsimonySitesP: "0.301669545192861", constantSitesP: "0.52734599884859", distinctSitesP: "0.402993667242372", brLenTotal: "1.2179" },
  { geneTranscript: "NM_005951.2", parsimonySitesP: "0.256830601092896", constantSitesP: "0.562841530054645", distinctSitesP: "0.469945355191257", brLenTotal: "2.1377" },
  { geneTranscript: "NM_014999.4", parsimonySitesP: "0.0992592592592593", constantSitesP: "0.820740740740741", distinctSitesP: "0.205925925925926", brLenTotal: "0.3232" },
  { geneTranscript: "NM_001164273.2", parsimonySitesP: "0.163132137030995", constantSitesP: "0.709298531810767", distinctSitesP: "0.204567699836868", brLenTotal: "0.5033" },
  { geneTranscript: "NM_001294338.2", parsimonySitesP: "0.128256513026052", constantSitesP: "0.801603206412826", distinctSitesP: "0.160320641282565", brLenTotal: "0.3630" },
  { geneTranscript: "NM_024838.5", parsimonySitesP: "0.210856886496187", constantSitesP: "0.651413189771198", distinctSitesP: "0.293853746074473", brLenTotal: "0.7000" },
  { geneTranscript: "NM_012204.4", parsimonySitesP: "0.175993511759935", constantSitesP: "0.713706407137064", distinctSitesP: "0.233171127331711", brLenTotal: "0.6335" },
  { geneTranscript: "NM_003440.4", parsimonySitesP: "0.228300510576222", constantSitesP: "0.587162654996353", distinctSitesP: "0.353756382202772", brLenTotal: "0.8001" },
  { geneTranscript: "NM_001668.4", parsimonySitesP: "0.134347275031686", constantSitesP: "0.755386565272497", distinctSitesP: "0.202365863962822", brLenTotal: "0.4514" },
  { geneTranscript: "NM_004897.5", parsimonySitesP: "0.219712525667351", constantSitesP: "0.629705681040383", distinctSitesP: "0.334017796030116", brLenTotal: "0.7147" },
  { geneTranscript: "NM_001371279.1", parsimonySitesP: "0.11150234741784", constantSitesP: "0.78169014084507", distinctSitesP: "0.183098591549296", brLenTotal: "0.4990" },
  { geneTranscript: "NM_001001795.2", parsimonySitesP: "0.268518518518519", constantSitesP: "0.580246913580247", distinctSitesP: "0.401234567901235", brLenTotal: "0.9935" },
  { geneTranscript: "NM_001286060.2", parsimonySitesP: "0.0623973727422003", constantSitesP: "0.847290640394089", distinctSitesP: "0.139573070607553", brLenTotal: "0.2301" },
  { geneTranscript: "NM_001364841.2", parsimonySitesP: "0.20125786163522", constantSitesP: "0.666666666666667", distinctSitesP: "0.316981132075472", brLenTotal: "1.0011" },
  { geneTranscript: "NM_001388359.1", parsimonySitesP: "0.21573398215734", constantSitesP: "0.668288726682887", distinctSitesP: "0.320356853203569", brLenTotal: "1.1447" },
  { geneTranscript: "NM_003366.4", parsimonySitesP: "0.211920529801325", constantSitesP: "0.632818248712288", distinctSitesP: "0.355408388520971", brLenTotal: "0.7734" },
  { geneTranscript: "NM_001349798.2", parsimonySitesP: "0.0905233380480905", constantSitesP: "0.819424799622819", distinctSitesP: "0.141914191419142", brLenTotal: "0.3124" },
  { geneTranscript: "NM_152246.3", parsimonySitesP: "0.214162348877375", constantSitesP: "0.681778929188256", distinctSitesP: "0.258203799654577", brLenTotal: "0.7963" },
  { geneTranscript: "NM_001101387.2", parsimonySitesP: "0.257907542579075", constantSitesP: "0.600973236009732", distinctSitesP: "0.384428223844282", brLenTotal: "0.9715" },
  { geneTranscript: "NM_015375.3", parsimonySitesP: "0.174022246142806", constantSitesP: "0.72048797990671", distinctSitesP: "0.217796914244708", brLenTotal: "0.5746" },
  { geneTranscript: "NM_021961.6", parsimonySitesP: "0.07981220657277", constantSitesP: "0.833333333333333", distinctSitesP: "0.136150234741784", brLenTotal: "0.3479" },
  { geneTranscript: "NM_001049.3", parsimonySitesP: "0.130434782608696", constantSitesP: "0.791986359761296", distinctSitesP: "0.188405797101449", brLenTotal: "0.4515" },
  { geneTranscript: "NM_005147.6", parsimonySitesP: "0.213194444444444", constantSitesP: "0.675694444444444", distinctSitesP: "0.293055555555556", brLenTotal: "0.7981" },
  { geneTranscript: "NM_000699.4", parsimonySitesP: "0.230919765166341", constantSitesP: "0.647097195042401", distinctSitesP: "0.354207436399217", brLenTotal: "1.0039" },
  { geneTranscript: "NM_021173.5", parsimonySitesP: "0.18380062305296", constantSitesP: "0.67601246105919", distinctSitesP: "0.32398753894081", brLenTotal: "0.5924" },
  { geneTranscript: "NM_001382422.1", parsimonySitesP: "0.22236076475478", constantSitesP: "0.654197838736492", distinctSitesP: "0.319201995012469", brLenTotal: "0.9117" },
  { geneTranscript: "NM_024295.6", parsimonySitesP: "0.136786188579017", constantSitesP: "0.766268260292165", distinctSitesP: "0.207171314741036", brLenTotal: "0.4386" },
  { geneTranscript: "NM_021188.3", parsimonySitesP: "0.112273361227336", constantSitesP: "0.783124128312413", distinctSitesP: "0.183403068340307", brLenTotal: "0.3379" },
  { geneTranscript: "NM_001543.5", parsimonySitesP: "0.196900982615268", constantSitesP: "0.718442932728647", distinctSitesP: "0.208238851095994", brLenTotal: "0.9928" },
  { geneTranscript: "NM_001379692.1", parsimonySitesP: "0.281329923273657", constantSitesP: "0.57459505541347", distinctSitesP: "0.393009377664109", brLenTotal: "1.2530" },
  { geneTranscript: "NM_144691.4", parsimonySitesP: "0.254056560037089", constantSitesP: "0.626332869726472", distinctSitesP: "0.350023180343069", brLenTotal: "1.0871" },
  { geneTranscript: "NM_006431.3", parsimonySitesP: "0.133956386292835", constantSitesP: "0.743302180685358", distinctSitesP: "0.21619937694704", brLenTotal: "0.4856" },
  { geneTranscript: "NM_001095.4", parsimonySitesP: "0.101010101010101", constantSitesP: "0.820075757575758", distinctSitesP: "0.151515151515152", brLenTotal: "0.3764" },
  { geneTranscript: "NM_153360.3", parsimonySitesP: "0.365269461077844", constantSitesP: "0.501663339986693", distinctSitesP: "0.457085828343313", brLenTotal: "1.6550" },
  { geneTranscript: "NM_005097.4", parsimonySitesP: "0.107121484141233", constantSitesP: "0.810891681627768", distinctSitesP: "0.158587672052663", brLenTotal: "0.4004" },
  { geneTranscript: "NM_018677.4", parsimonySitesP: "0.146457441749881", constantSitesP: "0.736091298145506", distinctSitesP: "0.208749405611032", brLenTotal: "0.4812" },
  { geneTranscript: "NM_005984.5", parsimonySitesP: "0.189710610932476", constantSitesP: "0.696677384780279", distinctSitesP: "0.267952840300107", brLenTotal: "0.7470" },
  { geneTranscript: "NM_001304509.2", parsimonySitesP: "0.308100929614874", constantSitesP: "0.556440903054449", distinctSitesP: "0.403718459495352", brLenTotal: "1.0126" },
  { geneTranscript: "NM_005736.4", parsimonySitesP: "0.151595744680851", constantSitesP: "0.756205673758865", distinctSitesP: "0.211879432624113", brLenTotal: "0.6668" },
  { geneTranscript: "NM_014691.3", parsimonySitesP: "0.141638608305275", constantSitesP: "0.76341189674523", distinctSitesP: "0.188776655443322", brLenTotal: "0.4780" },
  { geneTranscript: "NM_002388.6", parsimonySitesP: "0.167904290429043", constantSitesP: "0.708745874587459", distinctSitesP: "0.23473597359736", brLenTotal: "0.6004" },
  { geneTranscript: "NM_014849.5", parsimonySitesP: "0.150044923629829", constantSitesP: "0.765498652291105", distinctSitesP: "0.196316262353998", brLenTotal: "0.5642" },
  { geneTranscript: "NM_001510.4", parsimonySitesP: "0.135054617676266", constantSitesP: "0.78417742469381", distinctSitesP: "0.182720953326713", brLenTotal: "0.4773" },
  { geneTranscript: "NM_152723.3", parsimonySitesP: "0.295900178253119", constantSitesP: "0.524064171122995", distinctSitesP: "0.422459893048128", brLenTotal: "0.8965" },
  { geneTranscript: "NM_014755.3", parsimonySitesP: "0.167728237791932", constantSitesP: "0.747346072186837", distinctSitesP: "0.236730360934183", brLenTotal: "0.6249" },
  { geneTranscript: "NM_001135998.3", parsimonySitesP: "0.344226579520697", constantSitesP: "0.416122004357298", distinctSitesP: "0.518518518518519", brLenTotal: "1.3874" },
  { geneTranscript: "NM_018207.3", parsimonySitesP: "0.132631578947368", constantSitesP: "0.785964912280702", distinctSitesP: "0.2", brLenTotal: "0.6662" },
  { geneTranscript: "NM_016929.5", parsimonySitesP: "0.164674634794157", constantSitesP: "0.707835325365206", distinctSitesP: "0.264276228419655", brLenTotal: "0.7116" },
  { geneTranscript: "NM_012448.4", parsimonySitesP: "0.168149089368911", constantSitesP: "0.746293943244388", distinctSitesP: "0.208386277001271", brLenTotal: "0.7563" },
  { geneTranscript: "NM_001004478.2", parsimonySitesP: "0.460063897763578", constantSitesP: "0.368477103301384", distinctSitesP: "0.579339723109691", brLenTotal: "2.1982" },
  { geneTranscript: "NM_006411.4", parsimonySitesP: "0.100117785630153", constantSitesP: "0.817432273262662", distinctSitesP: "0.16489988221437", brLenTotal: "0.3711" },
  { geneTranscript: "NM_001128159.3", parsimonySitesP: "0.162259615384615", constantSitesP: "0.743189102564103", distinctSitesP: "0.231169871794872", brLenTotal: "0.6398" },
  { geneTranscript: "NM_017594.5", parsimonySitesP: "0.199329983249581", constantSitesP: "0.74036850921273", distinctSitesP: "0.23785594639866", brLenTotal: "0.7083" },
  { geneTranscript: "NM_013313.5", parsimonySitesP: "0.168067226890756", constantSitesP: "0.722689075630252", distinctSitesP: "0.282913165266106", brLenTotal: "0.8326" },
  { geneTranscript: "NM_003042.4", parsimonySitesP: "0.141346688925988", constantSitesP: "0.787423483583751", distinctSitesP: "0.196994991652755", brLenTotal: "0.6956" },
  { geneTranscript: "NM_148897.3", parsimonySitesP: "0.292864749733759", constantSitesP: "0.605963791267306", distinctSitesP: "0.37912673056443", brLenTotal: "1.5000" },
  { geneTranscript: "NM_004934.5", parsimonySitesP: "0.181012658227848", constantSitesP: "0.712658227848101", distinctSitesP: "0.243037974683544", brLenTotal: "0.8465" },
  { geneTranscript: "NM_012335.4", parsimonySitesP: "0.206132361870067", constantSitesP: "0.682756527018822", distinctSitesP: "0.254098360655738", brLenTotal: "0.8896" },
  { geneTranscript: "NM_001906.6", parsimonySitesP: "0.280101394169835", constantSitesP: "0.5297845373891", distinctSitesP: "0.460076045627376", brLenTotal: "1.5560" },
  { geneTranscript: "NM_005508.5", parsimonySitesP: "0.226851851851852", constantSitesP: "0.611111111111111", distinctSitesP: "0.331481481481481", brLenTotal: "1.1421" },
  { geneTranscript: "NM_198971.3", parsimonySitesP: "0.177304964539007", constantSitesP: "0.700838168923275", distinctSitesP: "0.256608639587363", brLenTotal: "0.5922" },
  { geneTranscript: "NM_001371910.2", parsimonySitesP: "0.128702207862143", constantSitesP: "0.756596661281637", distinctSitesP: "0.200861604738826", brLenTotal: "0.4381" },
  { geneTranscript: "NM_017864.4", parsimonySitesP: "0.124623115577889", constantSitesP: "0.784254606365159", distinctSitesP: "0.187939698492462", brLenTotal: "0.3881" },
  { geneTranscript: "NM_001199867.2", parsimonySitesP: "0.13741134751773", constantSitesP: "0.780585106382979", distinctSitesP: "0.185283687943262", brLenTotal: "0.6271" },
  { geneTranscript: "NM_001371904.1", parsimonySitesP: "0.302367941712204", constantSitesP: "0.539162112932605", distinctSitesP: "0.429872495446266", brLenTotal: "1.0598" },
  { geneTranscript: "NM_054030.4", parsimonySitesP: "0.536363636363636", constantSitesP: "0.271717171717172", distinctSitesP: "0.711111111111111", brLenTotal: "2.8607" },
  { geneTranscript: "NM_001005743.2", parsimonySitesP: "0.135688684075781", constantSitesP: "0.732206861239119", distinctSitesP: "0.206861239119304", brLenTotal: "0.4815" },
  { geneTranscript: "NM_002318.3", parsimonySitesP: "0.20198105081826", constantSitesP: "0.69207579672696", distinctSitesP: "0.266580534022394", brLenTotal: "0.8802" },
  { geneTranscript: "NM_004869.4", parsimonySitesP: "0.15990990990991", constantSitesP: "0.727477477477477", distinctSitesP: "0.245495495495495", brLenTotal: "0.6073" },
  { geneTranscript: "NM_002915.4", parsimonySitesP: "0.141385767790262", constantSitesP: "0.74438202247191", distinctSitesP: "0.228464419475655", brLenTotal: "0.4832" },
  { geneTranscript: "NM_138477.4", parsimonySitesP: "0.205378973105134", constantSitesP: "0.653626731866341", distinctSitesP: "0.255908720456398", brLenTotal: "0.7123" },
  { geneTranscript: "NM_001371919.1", parsimonySitesP: "0.551020408163265", constantSitesP: "0.276643990929705", distinctSitesP: "0.705215419501134", brLenTotal: "3.2218" },
  { geneTranscript: "NM_022770.4", parsimonySitesP: "0.233024691358025", constantSitesP: "0.614197530864198", distinctSitesP: "0.390432098765432", brLenTotal: "0.8874" },
  { geneTranscript: "NM_020707.4", parsimonySitesP: "0.218915343915344", constantSitesP: "0.667328042328042", distinctSitesP: "0.296957671957672", brLenTotal: "0.8486" },
  { geneTranscript: "NM_001040118.3", parsimonySitesP: "0.213563218390805", constantSitesP: "0.673793103448276", distinctSitesP: "0.248965517241379", brLenTotal: "0.8275" },
  { geneTranscript: "NM_133373.5", parsimonySitesP: "0.209547950992818", constantSitesP: "0.670468948035488", distinctSitesP: "0.270384452893959", brLenTotal: "0.9175" },
  { geneTranscript: "NM_001004059.3", parsimonySitesP: "0.496248660235798", constantSitesP: "0.363344051446945", distinctSitesP: "0.627009646302251", brLenTotal: "2.6582" },
  { geneTranscript: "NM_001099433.2", parsimonySitesP: "0.170878459687124", constantSitesP: "0.717208182912154", distinctSitesP: "0.231046931407942", brLenTotal: "0.7757" },
  { geneTranscript: "NM_001201380.3", parsimonySitesP: "0.293478260869565", constantSitesP: "0.537784679089027", distinctSitesP: "0.394668737060041", brLenTotal: "1.3451" },
  { geneTranscript: "NM_016091.4", parsimonySitesP: "0.179669030732861", constantSitesP: "0.670212765957447", distinctSitesP: "0.277777777777778", brLenTotal: "0.7535" },
  { geneTranscript: "NM_032593.3", parsimonySitesP: "0.177914110429448", constantSitesP: "0.683026584867076", distinctSitesP: "0.304703476482618", brLenTotal: "0.6265" },
  { geneTranscript: "NM_004823.3", parsimonySitesP: "0.281150159744409", constantSitesP: "0.563365282215122", distinctSitesP: "0.382321618743344", brLenTotal: "1.3618" },
  { geneTranscript: "NM_020782.2", parsimonySitesP: "0.15049504950495", constantSitesP: "0.746534653465347", distinctSitesP: "0.221122112211221", brLenTotal: "0.5296" },
  { geneTranscript: "NM_003517.3", parsimonySitesP: "0.27906976744186", constantSitesP: "0.658914728682171", distinctSitesP: "0.343669250645995", brLenTotal: "2.1226" },
  { geneTranscript: "NM_001997.5", parsimonySitesP: "0.19047619047619", constantSitesP: "0.676691729323308", distinctSitesP: "0.308270676691729", brLenTotal: "0.6704" },
  { geneTranscript: "NM_000486.6", parsimonySitesP: "0.212792127921279", constantSitesP: "0.646986469864699", distinctSitesP: "0.300123001230012", brLenTotal: "0.9129" },
  { geneTranscript: "NM_001143998.2", parsimonySitesP: "0.175757575757576", constantSitesP: "0.731002331002331", distinctSitesP: "0.25034965034965", brLenTotal: "0.7719" },
  { geneTranscript: "NM_173683.4", parsimonySitesP: "0.0904836193447738", constantSitesP: "0.840353614144566", distinctSitesP: "0.154966198647946", brLenTotal: "0.3179" },
  { geneTranscript: "NM_000311.5", parsimonySitesP: "0.225296442687747", constantSitesP: "0.69433465085639", distinctSitesP: "0.325428194993412", brLenTotal: "0.9850" },
  { geneTranscript: "NM_032852.4", parsimonySitesP: "0.132459970887918", constantSitesP: "0.748180494905386", distinctSitesP: "0.203056768558952", brLenTotal: "0.4305" },
  { geneTranscript: "NM_005813.6", parsimonySitesP: "0.128838951310861", constantSitesP: "0.747191011235955", distinctSitesP: "0.190262172284644", brLenTotal: "0.4755" },
  { geneTranscript: "NM_004121.5", parsimonySitesP: "0.337883959044369", constantSitesP: "0.506825938566553", distinctSitesP: "0.456769055745165", brLenTotal: "1.5333" },
  { geneTranscript: "NM_001018108.4", parsimonySitesP: "0.0677966101694915", constantSitesP: "0.796610169491525", distinctSitesP: "0.23728813559322", brLenTotal: "0.2768" },
  { geneTranscript: "NM_024665.7", parsimonySitesP: "0.140077821011673", constantSitesP: "0.74254215304799", distinctSitesP: "0.206874189364462", brLenTotal: "0.6505" },
  { geneTranscript: "NM_014232.3", parsimonySitesP: "0.103448275862069", constantSitesP: "0.810344827586207", distinctSitesP: "0.192528735632184", brLenTotal: "0.3835" },
  { geneTranscript: "NM_024522.3", parsimonySitesP: "0.0917874396135266", constantSitesP: "0.826086956521739", distinctSitesP: "0.170692431561997", brLenTotal: "0.3480" },
  { geneTranscript: "NM_012418.4", parsimonySitesP: "0.224932249322493", constantSitesP: "0.595528455284553", distinctSitesP: "0.300813008130081", brLenTotal: "1.3050" },
  { geneTranscript: "NM_024038.4", parsimonySitesP: "0.121212121212121", constantSitesP: "0.804924242424242", distinctSitesP: "0.196969696969697", brLenTotal: "0.4829" },
  { geneTranscript: "NM_000539.3", parsimonySitesP: "0.168582375478927", constantSitesP: "0.722222222222222", distinctSitesP: "0.226053639846743", brLenTotal: "0.8189" },
  { geneTranscript: "NM_017525.3", parsimonySitesP: "0.248656780571674", constantSitesP: "0.614442295293359", distinctSitesP: "0.289490651192779", brLenTotal: "1.0653" },
  { geneTranscript: "NM_015509.4", parsimonySitesP: "0.138181818181818", constantSitesP: "0.75030303030303", distinctSitesP: "0.225454545454545", brLenTotal: "0.4672" },
  { geneTranscript: "NM_001056.4", parsimonySitesP: "0.199324324324324", constantSitesP: "0.64527027027027", distinctSitesP: "0.345720720720721", brLenTotal: "0.7118" },
  { geneTranscript: "NM_000023.4", parsimonySitesP: "0.248062015503876", constantSitesP: "0.616709732988803", distinctSitesP: "0.362618432385874", brLenTotal: "0.9485" },
  { geneTranscript: "NM_004297.4", parsimonySitesP: "0.192488262910798", constantSitesP: "0.71830985915493", distinctSitesP: "0.257276995305164", brLenTotal: "0.6942" },
  { geneTranscript: "NM_206917.3", parsimonySitesP: "0.102310231023102", constantSitesP: "0.706270627062706", distinctSitesP: "0.280528052805281", brLenTotal: "0.4485" },
  { geneTranscript: "NM_006869.4", parsimonySitesP: "0.204099821746881", constantSitesP: "0.706773618538324", distinctSitesP: "0.280748663101604", brLenTotal: "0.9468" },
  { geneTranscript: "NM_133261.3", parsimonySitesP: "0.254273504273504", constantSitesP: "0.626068376068376", distinctSitesP: "0.337606837606838", brLenTotal: "1.0386" },
  { geneTranscript: "NM_152316.3", parsimonySitesP: "0.203846153846154", constantSitesP: "0.592307692307692", distinctSitesP: "0.351282051282051", brLenTotal: "0.7836" },
  { geneTranscript: "NM_031157.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.753584229390681", distinctSitesP: "0.224014336917563", brLenTotal: "0.4782" },
  { geneTranscript: "NM_016145.4", parsimonySitesP: "0.154088050314465", constantSitesP: "0.789308176100629", distinctSitesP: "0.210691823899371", brLenTotal: "0.5125" },
  { geneTranscript: "NM_001304533.3", parsimonySitesP: "0.131498470948012", constantSitesP: "0.785932721712538", distinctSitesP: "0.215596330275229", brLenTotal: "0.4767" },
  { geneTranscript: "NM_001244710.2", parsimonySitesP: "0.119217930376729", constantSitesP: "0.780639008106819", distinctSitesP: "0.183595612780162", brLenTotal: "0.4417" },
  { geneTranscript: "NM_005201.4", parsimonySitesP: "0.298591549295775", constantSitesP: "0.537089201877934", distinctSitesP: "0.443192488262911", brLenTotal: "0.9770" },
  { geneTranscript: "NM_001081550.2", parsimonySitesP: "0.0926972169910023", constantSitesP: "0.830717723373091", distinctSitesP: "0.120736555764804", brLenTotal: "0.2930" },
  { geneTranscript: "NM_145305.3", parsimonySitesP: "0.179863147605083", constantSitesP: "0.680351906158358", distinctSitesP: "0.268817204301075", brLenTotal: "0.5370" },
  { geneTranscript: "NM_006996.3", parsimonySitesP: "0.195841716968478", constantSitesP: "0.674044265593561", distinctSitesP: "0.285714285714286", brLenTotal: "0.6188" },
  { geneTranscript: "NM_002726.5", parsimonySitesP: "0.175586854460094", constantSitesP: "0.711267605633803", distinctSitesP: "0.241784037558685", brLenTotal: "0.6612" },
  { geneTranscript: "NM_001702.3", parsimonySitesP: "0.196969696969697", constantSitesP: "0.713594276094276", distinctSitesP: "0.228324915824916", brLenTotal: "0.9508" },
  { geneTranscript: "NM_002049.4", parsimonySitesP: "0.188861985472155", constantSitesP: "0.687651331719128", distinctSitesP: "0.258272800645682", brLenTotal: "0.6739" },
  { geneTranscript: "NM_012453.4", parsimonySitesP: "0.221476510067114", constantSitesP: "0.658463832960477", distinctSitesP: "0.315436241610738", brLenTotal: "0.7446" },
  { geneTranscript: "NM_020659.4", parsimonySitesP: "0.16962962962963", constantSitesP: "0.702222222222222", distinctSitesP: "0.246666666666667", brLenTotal: "0.7223" },
  { geneTranscript: "NM_198077.4", parsimonySitesP: "0.168498168498168", constantSitesP: "0.67032967032967", distinctSitesP: "0.304029304029304", brLenTotal: "0.7094" },
  { geneTranscript: "NM_032188.3", parsimonySitesP: "0.171033478893741", constantSitesP: "0.745269286754003", distinctSitesP: "0.235807860262009", brLenTotal: "0.6184" },
  { geneTranscript: "NM_012202.5", parsimonySitesP: "0.142222222222222", constantSitesP: "0.777777777777778", distinctSitesP: "0.222222222222222", brLenTotal: "0.3808" },
  { geneTranscript: "NM_001024630.4", parsimonySitesP: "0.0671785028790787", constantSitesP: "0.864363403710813", distinctSitesP: "0.142674344209853", brLenTotal: "0.3140" },
  { geneTranscript: "NM_001164379.3", parsimonySitesP: "0.253187613843352", constantSitesP: "0.597449908925319", distinctSitesP: "0.397085610200364", brLenTotal: "0.7920" },
  { geneTranscript: "NM_001300721.2", parsimonySitesP: "0.267683772538141", constantSitesP: "0.590383726306056", distinctSitesP: "0.346740638002774", brLenTotal: "1.0692" },
  { geneTranscript: "NM_014555.4", parsimonySitesP: "0.275250357653791", constantSitesP: "0.587124463519313", distinctSitesP: "0.354220314735336", brLenTotal: "1.2096" },
  { geneTranscript: "NM_006509.4", parsimonySitesP: "0.218192285549799", constantSitesP: "0.667242371905584", distinctSitesP: "0.291882556131261", brLenTotal: "0.8766" },
  { geneTranscript: "NM_080817.5", parsimonySitesP: "0.215277777777778", constantSitesP: "0.663690476190476", distinctSitesP: "0.323412698412698", brLenTotal: "0.6294" },
  { geneTranscript: "NM_001155.5", parsimonySitesP: "0.173353145121347", constantSitesP: "0.728578504210005", distinctSitesP: "0.236750866765726", brLenTotal: "0.6506" },
  { geneTranscript: "NM_001517.5", parsimonySitesP: "0.133477633477633", constantSitesP: "0.766955266955267", distinctSitesP: "0.182539682539683", brLenTotal: "0.4467" },
  { geneTranscript: "NM_001329788.2", parsimonySitesP: "0.173493975903614", constantSitesP: "0.712449799196787", distinctSitesP: "0.261044176706827", brLenTotal: "0.5718" },
  { geneTranscript: "NM_002396.5", parsimonySitesP: "0.182648401826484", constantSitesP: "0.703767123287671", distinctSitesP: "0.257990867579909", brLenTotal: "0.5672" },
  { geneTranscript: "NM_138401.4", parsimonySitesP: "0.263736263736264", constantSitesP: "0.627594627594628", distinctSitesP: "0.361416361416361", brLenTotal: "0.9582" },
  { geneTranscript: "NM_172071.4", parsimonySitesP: "0.123859958811415", constantSitesP: "0.753456899087967", distinctSitesP: "0.179758752574287", brLenTotal: "0.4352" },
  { geneTranscript: "NM_006045.3", parsimonySitesP: "0.169691181152499", constantSitesP: "0.752308182107609", distinctSitesP: "0.221267112384591", brLenTotal: "0.7988" },
  { geneTranscript: "NM_024122.5", parsimonySitesP: "0.198653198653199", constantSitesP: "0.632996632996633", distinctSitesP: "0.348484848484848", brLenTotal: "0.6880" },
  { geneTranscript: "NM_001004432.4", parsimonySitesP: "0.225407532321529", constantSitesP: "0.647554806070826", distinctSitesP: "0.278246205733558", brLenTotal: "0.7789" },
  { geneTranscript: "NM_130783.5", parsimonySitesP: "0.173387096774194", constantSitesP: "0.708333333333333", distinctSitesP: "0.278225806451613", brLenTotal: "0.8729" },
  { geneTranscript: "NM_003162.4", parsimonySitesP: "0.114957264957265", constantSitesP: "0.787179487179487", distinctSitesP: "0.175641025641026", brLenTotal: "0.3940" },
  { geneTranscript: "NM_001004019.2", parsimonySitesP: "0.275656647711887", constantSitesP: "0.587598158678581", distinctSitesP: "0.354725155699973", brLenTotal: "1.3828" },
  { geneTranscript: "NM_194249.3", parsimonySitesP: "0.218130311614731", constantSitesP: "0.640226628895184", distinctSitesP: "0.328611898016997", brLenTotal: "0.7290" },
  { geneTranscript: "NM_006510.5", parsimonySitesP: "0.138401559454191", constantSitesP: "0.759584145549058", distinctSitesP: "0.193632228719948", brLenTotal: "0.5337" },
  { geneTranscript: "NM_001242739.2", parsimonySitesP: "0.196825396825397", constantSitesP: "0.671957671957672", distinctSitesP: "0.302645502645503", brLenTotal: "0.6810" },
  { geneTranscript: "NM_007226.3", parsimonySitesP: "0.142676767676768", constantSitesP: "0.660353535353535", distinctSitesP: "0.243686868686869", brLenTotal: "0.9264" },
  { geneTranscript: "NM_001003940.2", parsimonySitesP: "0.164855072463768", constantSitesP: "0.721014492753623", distinctSitesP: "0.255434782608696", brLenTotal: "0.5489" },
  { geneTranscript: "NM_003687.4", parsimonySitesP: "0.224242424242424", constantSitesP: "0.633333333333333", distinctSitesP: "0.326262626262626", brLenTotal: "0.8608" },
  { geneTranscript: "NM_013939.2", parsimonySitesP: "0.526984126984127", constantSitesP: "0.257142857142857", distinctSitesP: "0.743915343915344", brLenTotal: "4.6431" },
  { geneTranscript: "NM_014015.4", parsimonySitesP: "0.150877192982456", constantSitesP: "0.736842105263158", distinctSitesP: "0.252631578947368", brLenTotal: "0.4898" },
  { geneTranscript: "NM_014012.6", parsimonySitesP: "0.242729306487696", constantSitesP: "0.635346756152125", distinctSitesP: "0.340044742729306", brLenTotal: "0.8864" },
  { geneTranscript: "NM_003196.3", parsimonySitesP: "0.171455938697318", constantSitesP: "0.674329501915709", distinctSitesP: "0.282567049808429", brLenTotal: "0.6665" },
  { geneTranscript: "NM_002319.5", parsimonySitesP: "0.226451927769644", constantSitesP: "0.63152757442655", distinctSitesP: "0.325524646168863", brLenTotal: "0.9216" },
  { geneTranscript: "NM_001984.2", parsimonySitesP: "0.163120567375887", constantSitesP: "0.715130023640662", distinctSitesP: "0.283687943262411", brLenTotal: "0.5361" },
  { geneTranscript: "NM_002528.7", parsimonySitesP: "0.291666666666667", constantSitesP: "0.582236842105263", distinctSitesP: "0.400219298245614", brLenTotal: "1.2525" },
  { geneTranscript: "NM_198334.3", parsimonySitesP: "0.177612994350282", constantSitesP: "0.704802259887006", distinctSitesP: "0.23975988700565", brLenTotal: "0.5975" },
  { geneTranscript: "NM_004824.4", parsimonySitesP: "0.17953431372549", constantSitesP: "0.711397058823529", distinctSitesP: "0.243259803921569", brLenTotal: "0.7677" },
  { geneTranscript: "NM_001161586.3", parsimonySitesP: "0.152317880794702", constantSitesP: "0.747240618101545", distinctSitesP: "0.216335540838852", brLenTotal: "0.5769" },
  { geneTranscript: "NM_014215.3", parsimonySitesP: "0.207144692881007", constantSitesP: "0.683885890516577", distinctSitesP: "0.258802364430738", brLenTotal: "0.7784" },
  { geneTranscript: "NM_001367886.1", parsimonySitesP: "0.143853998926463", constantSitesP: "0.749865807836822", distinctSitesP: "0.202361782071927", brLenTotal: "0.5374" },
  { geneTranscript: "NM_006002.5", parsimonySitesP: "0.117391304347826", constantSitesP: "0.794202898550725", distinctSitesP: "0.214492753623188", brLenTotal: "0.3883" },
  { geneTranscript: "NM_198390.3", parsimonySitesP: "0.110823630875377", constantSitesP: "0.828374299266925", distinctSitesP: "0.156964208710651", brLenTotal: "0.4432" },
  { geneTranscript: "NM_024009.3", parsimonySitesP: "0.285185185185185", constantSitesP: "0.564197530864198", distinctSitesP: "0.377777777777778", brLenTotal: "1.1035" },
  { geneTranscript: "NM_015589.6", parsimonySitesP: "0.150882079851439", constantSitesP: "0.751160631383473", distinctSitesP: "0.188022284122563", brLenTotal: "0.6322" },
  { geneTranscript: "NM_016310.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.737654320987654", distinctSitesP: "0.25", brLenTotal: "0.6044" },
  { geneTranscript: "NM_003068.5", parsimonySitesP: "0.161691542288557", constantSitesP: "0.735074626865672", distinctSitesP: "0.223880597014925", brLenTotal: "0.6827" },
  { geneTranscript: "NM_000671.4", parsimonySitesP: "0.278966131907308", constantSitesP: "0.565953654188948", distinctSitesP: "0.416221033868093", brLenTotal: "1.2379" },
  { geneTranscript: "NM_002165.4", parsimonySitesP: "0.212903225806452", constantSitesP: "0.664516129032258", distinctSitesP: "0.344086021505376", brLenTotal: "0.7774" },
  { geneTranscript: "NM_001001524.3", parsimonySitesP: "0.273209549071618", constantSitesP: "0.570291777188329", distinctSitesP: "0.401414677276746", brLenTotal: "1.1079" },
  { geneTranscript: "NM_001004729.2", parsimonySitesP: "0.473740621650589", constantSitesP: "0.381564844587353", distinctSitesP: "0.631296891747053", brLenTotal: "2.1423" },
  { geneTranscript: "NM_199001.5", parsimonySitesP: "0.342592592592593", constantSitesP: "0.476851851851852", distinctSitesP: "0.539351851851852", brLenTotal: "1.6911" },
  { geneTranscript: "NM_006888.6", parsimonySitesP: "0.136465324384787", constantSitesP: "0.782997762863535", distinctSitesP: "0.210290827740492", brLenTotal: "0.7740" },
  { geneTranscript: "NM_000805.5", parsimonySitesP: "0.33993399339934", constantSitesP: "0.531353135313531", distinctSitesP: "0.504950495049505", brLenTotal: "1.1326" },
  { geneTranscript: "NM_182715.4", parsimonySitesP: "0.226832641770401", constantSitesP: "0.629322268326418", distinctSitesP: "0.383125864453665", brLenTotal: "0.8835" },
  { geneTranscript: "NM_001005198.2", parsimonySitesP: "0.523043944265809", constantSitesP: "0.362272240085745", distinctSitesP: "0.616291532690247", brLenTotal: "4.4208" },
  { geneTranscript: "NM_000945.4", parsimonySitesP: "0.129411764705882", constantSitesP: "0.801960784313725", distinctSitesP: "0.162745098039216", brLenTotal: "0.3235" },
  { geneTranscript: "NM_016467.5", parsimonySitesP: "0.122004357298475", constantSitesP: "0.775599128540305", distinctSitesP: "0.191721132897603", brLenTotal: "0.3791" },
  { geneTranscript: "NM_032025.5", parsimonySitesP: "0.143589743589744", constantSitesP: "0.723076923076923", distinctSitesP: "0.231339031339031", brLenTotal: "0.5098" },
  { geneTranscript: "NM_001139.3", parsimonySitesP: "0.237280076081788", constantSitesP: "0.64717070851165", distinctSitesP: "0.29624346172135", brLenTotal: "0.8409" },
  { geneTranscript: "NM_001982.4", parsimonySitesP: "0.156731246895181", constantSitesP: "0.732985593641331", distinctSitesP: "0.193740685543964", brLenTotal: "0.5077" },
  { geneTranscript: "NM_001372123.1", parsimonySitesP: "0.096260938743039", constantSitesP: "0.800318217979316", distinctSitesP: "0.167859984089101", brLenTotal: "0.3252" },
  { geneTranscript: "NM_012308.3", parsimonySitesP: "0.080895008605852", constantSitesP: "0.849397590361446", distinctSitesP: "0.114170969592656", brLenTotal: "0.2707" },
  { geneTranscript: "NM_003222.4", parsimonySitesP: "0.203703703703704", constantSitesP: "0.625925925925926", distinctSitesP: "0.306666666666667", brLenTotal: "0.9263" },
  { geneTranscript: "NM_001320.7", parsimonySitesP: "0.10077519379845", constantSitesP: "0.817054263565891", distinctSitesP: "0.172093023255814", brLenTotal: "0.3302" },
  { geneTranscript: "NM_054025.3", parsimonySitesP: "0.155688622754491", constantSitesP: "0.755489021956088", distinctSitesP: "0.234530938123752", brLenTotal: "0.7122" },
  { geneTranscript: "NM_003217.3", parsimonySitesP: "0.149085794655415", constantSitesP: "0.718706047819972", distinctSitesP: "0.277074542897328", brLenTotal: "0.4995" },
  { geneTranscript: "NM_005717.4", parsimonySitesP: "0.134657836644592", constantSitesP: "0.717439293598234", distinctSitesP: "0.262693156732892", brLenTotal: "0.5400" },
  { geneTranscript: "NM_177433.3", parsimonySitesP: "0.20957095709571", constantSitesP: "0.646314631463146", distinctSitesP: "0.296479647964796", brLenTotal: "0.7251" },
  { geneTranscript: "NM_001080425.4", parsimonySitesP: "0.416666666666667", constantSitesP: "0.391666666666667", distinctSitesP: "0.586111111111111", brLenTotal: "1.2692" },
  { geneTranscript: "NM_022484.6", parsimonySitesP: "0.132950741272119", constantSitesP: "0.77714012434242", distinctSitesP: "0.179818268770923", brLenTotal: "0.3875" },
  { geneTranscript: "NM_014291.4", parsimonySitesP: "0.233890214797136", constantSitesP: "0.634049323786794", distinctSitesP: "0.322195704057279", brLenTotal: "0.8810" },
  { geneTranscript: "NM_001793.6", parsimonySitesP: "0.28226779252111", constantSitesP: "0.587856855649377", distinctSitesP: "0.359871330920788", brLenTotal: "1.0961" },
  { geneTranscript: "NM_024066.3", parsimonySitesP: "0.0731948565776459", constantSitesP: "0.864490603363007", distinctSitesP: "0.128585558852621", brLenTotal: "0.2391" },
  { geneTranscript: "NM_017982.4", parsimonySitesP: "0.244897959183673", constantSitesP: "0.633333333333333", distinctSitesP: "0.361224489795918", brLenTotal: "0.9554" },
  { geneTranscript: "NM_030573.3", parsimonySitesP: "0.199568500539374", constantSitesP: "0.687162891046386", distinctSitesP: "0.284789644012945", brLenTotal: "0.7083" },
  { geneTranscript: "NM_000142.5", parsimonySitesP: "0.252688172043011", constantSitesP: "0.645161290322581", distinctSitesP: "0.3287841191067", brLenTotal: "1.2261" },
  { geneTranscript: "NM_001301056.2", parsimonySitesP: "0.125821596244131", constantSitesP: "0.806572769953052", distinctSitesP: "0.176525821596244", brLenTotal: "0.4276" },
  { geneTranscript: "NM_001278309.2", parsimonySitesP: "0.321219226260258", constantSitesP: "0.466197733489644", distinctSitesP: "0.522078937084799", brLenTotal: "1.2170" },
  { geneTranscript: "NM_002863.5", parsimonySitesP: "0.16922471467926", constantSitesP: "0.723337268791814", distinctSitesP: "0.243604879968516", brLenTotal: "0.6687" },
  { geneTranscript: "NM_000361.3", parsimonySitesP: "0.388405797101449", constantSitesP: "0.445217391304348", distinctSitesP: "0.475942028985507", brLenTotal: "1.5379" },
  { geneTranscript: "NM_005346.6", parsimonySitesP: "0.31201248049922", constantSitesP: "0.608424336973479", distinctSitesP: "0.354654186167447", brLenTotal: "1.7050" },
  { geneTranscript: "NM_020225.3", parsimonySitesP: "0.185385169186465", constantSitesP: "0.730741540676746", distinctSitesP: "0.223902087832973", brLenTotal: "0.7170" },
  { geneTranscript: "NM_021254.4", parsimonySitesP: "0.197701149425287", constantSitesP: "0.693103448275862", distinctSitesP: "0.291954022988506", brLenTotal: "0.7132" },
  { geneTranscript: "NM_173562.5", parsimonySitesP: "0.182179793158313", constantSitesP: "0.704057279236277", distinctSitesP: "0.249801113762928", brLenTotal: "0.6453" },
  { geneTranscript: "NM_004621.6", parsimonySitesP: "0.191908342284282", constantSitesP: "0.693161475116362", distinctSitesP: "0.254206945936269", brLenTotal: "0.8027" },
  { geneTranscript: "NM_002013.4", parsimonySitesP: "0.0982142857142857", constantSitesP: "0.738095238095238", distinctSitesP: "0.233630952380952", brLenTotal: "0.4184" },
  { geneTranscript: "NM_004099.6", parsimonySitesP: "0.167824074074074", constantSitesP: "0.697916666666667", distinctSitesP: "0.309027777777778", brLenTotal: "0.6601" },
  { geneTranscript: "NM_001135690.3", parsimonySitesP: "0.253433208489388", constantSitesP: "0.607990012484395", distinctSitesP: "0.350811485642946", brLenTotal: "0.8873" },
  { geneTranscript: "NM_031299.7", parsimonySitesP: "0.247512437810945", constantSitesP: "0.543532338308458", distinctSitesP: "0.374378109452736", brLenTotal: "0.7801" },
  { geneTranscript: "NM_145052.4", parsimonySitesP: "0.189859762675297", constantSitesP: "0.67529665587918", distinctSitesP: "0.298813376483279", brLenTotal: "0.6173" },
  { geneTranscript: "NM_024321.5", parsimonySitesP: "0.154166666666667", constantSitesP: "0.726388888888889", distinctSitesP: "0.236805555555556", brLenTotal: "0.6449" },
  { geneTranscript: "NM_001261413.2", parsimonySitesP: "0.162094763092269", constantSitesP: "0.730673316708229", distinctSitesP: "0.214463840399002", brLenTotal: "0.4859" },
  { geneTranscript: "NM_002237.4", parsimonySitesP: "0.224171539961014", constantSitesP: "0.602988953866147", distinctSitesP: "0.314489928525016", brLenTotal: "0.9756" },
  { geneTranscript: "NM_000320.3", parsimonySitesP: "0.202185792349727", constantSitesP: "0.666666666666667", distinctSitesP: "0.308743169398907", brLenTotal: "0.8925" },
  { geneTranscript: "NM_015266.3", parsimonySitesP: "0.1285140562249", constantSitesP: "0.789443488238669", distinctSitesP: "0.180722891566265", brLenTotal: "0.4845" },
  { geneTranscript: "NM_003787.5", parsimonySitesP: "0.11128526645768", constantSitesP: "0.80773249738767", distinctSitesP: "0.156739811912226", brLenTotal: "0.3456" },
  { geneTranscript: "NM_001258419.2", parsimonySitesP: "0.1109375", constantSitesP: "0.796875", distinctSitesP: "0.15", brLenTotal: "0.4515" },
  { geneTranscript: "NM_004608.4", parsimonySitesP: "0.228593272171254", constantSitesP: "0.650611620795107", distinctSitesP: "0.32262996941896", brLenTotal: "0.8168" },
  { geneTranscript: "NM_000680.4", parsimonySitesP: "0.188841201716738", constantSitesP: "0.698140200286123", distinctSitesP: "0.254649499284692", brLenTotal: "0.6776" },
  { geneTranscript: "NM_007210.4", parsimonySitesP: "0.189710610932476", constantSitesP: "0.694533762057878", distinctSitesP: "0.237406216505895", brLenTotal: "0.6442" },
  { geneTranscript: "NM_017913.4", parsimonySitesP: "0.0949554896142433", constantSitesP: "0.813056379821958", distinctSitesP: "0.168150346191889", brLenTotal: "0.2642" },
  { geneTranscript: "NM_014315.3", parsimonySitesP: "0.0960591133004926", constantSitesP: "0.811986863711002", distinctSitesP: "0.16256157635468", brLenTotal: "0.3139" },
  { geneTranscript: "NM_001352452.2", parsimonySitesP: "0.365953109072375", constantSitesP: "0.419979612640163", distinctSitesP: "0.499490316004077", brLenTotal: "1.7421" },
  { geneTranscript: "NM_001004703.1", parsimonySitesP: "0.598705501618123", constantSitesP: "0.224379719525351", distinctSitesP: "0.750809061488673", brLenTotal: "4.5989" },
  { geneTranscript: "NM_018584.6", parsimonySitesP: "0.0427350427350427", constantSitesP: "0.897435897435897", distinctSitesP: "0.115384615384615", brLenTotal: "0.1267" },
  { geneTranscript: "NM_001288833.2", parsimonySitesP: "0.289982425307557", constantSitesP: "0.58465143526655", distinctSitesP: "0.369654364381957", brLenTotal: "1.4438" },
  { geneTranscript: "NM_006110.3", parsimonySitesP: "0.232649071358749", constantSitesP: "0.66177908113392", distinctSitesP: "0.309872922776149", brLenTotal: "0.7836" },
  { geneTranscript: "NM_014964.5", parsimonySitesP: "0.204888195527821", constantSitesP: "0.661986479459178", distinctSitesP: "0.280291211648466", brLenTotal: "0.8728" },
  { geneTranscript: "NM_021025.4", parsimonySitesP: "0.115693012600229", constantSitesP: "0.793814432989691", distinctSitesP: "0.18098510882016", brLenTotal: "0.3950" },
  { geneTranscript: "NM_001967.4", parsimonySitesP: "0.113022113022113", constantSitesP: "0.786240786240786", distinctSitesP: "0.188370188370188", brLenTotal: "0.4368" },
  { geneTranscript: "NM_006427.4", parsimonySitesP: "0.28", constantSitesP: "0.561904761904762", distinctSitesP: "0.426666666666667", brLenTotal: "1.1946" },
  { geneTranscript: "NM_001282195.2", parsimonySitesP: "0.148717948717949", constantSitesP: "0.773333333333333", distinctSitesP: "0.209230769230769", brLenTotal: "0.4215" },
  { geneTranscript: "NM_018927.4", parsimonySitesP: "0.428776462145676", constantSitesP: "0.423753139576606", distinctSitesP: "0.55005382131324", brLenTotal: "2.8506" },
  { geneTranscript: "NM_000083.3", parsimonySitesP: "0.209514170040486", constantSitesP: "0.67004048582996", distinctSitesP: "0.286774628879892", brLenTotal: "0.7911" },
  { geneTranscript: "NM_213596.3", parsimonySitesP: "0.261766602192134", constantSitesP: "0.594455190199871", distinctSitesP: "0.345583494519665", brLenTotal: "1.0910" },
  { geneTranscript: "NM_006831.3", parsimonySitesP: "0.139607843137255", constantSitesP: "0.731764705882353", distinctSitesP: "0.217254901960784", brLenTotal: "0.4829" },
  { geneTranscript: "NM_014859.6", parsimonySitesP: "0.159331703341483", constantSitesP: "0.751426242868786", distinctSitesP: "0.227791361043195", brLenTotal: "0.6265" },
  { geneTranscript: "NM_152274.5", parsimonySitesP: "0.252688172043011", constantSitesP: "0.583333333333333", distinctSitesP: "0.422043010752688", brLenTotal: "0.9804" },
  { geneTranscript: "NM_001304438.2", parsimonySitesP: "0.183116076970826", constantSitesP: "0.721911855990068", distinctSitesP: "0.234947237740534", brLenTotal: "0.8054" },
  { geneTranscript: "NM_000593.6", parsimonySitesP: "0.303475935828877", constantSitesP: "0.522281639928699", distinctSitesP: "0.389928698752228", brLenTotal: "1.1116" },
  { geneTranscript: "NM_014473.4", parsimonySitesP: "0.148029818956337", constantSitesP: "0.736954206602769", distinctSitesP: "0.272630457933972", brLenTotal: "0.5088" },
  { geneTranscript: "NM_001469.5", parsimonySitesP: "0.234263820470717", constantSitesP: "0.569786535303777", distinctSitesP: "0.375478927203065", brLenTotal: "0.9123" },
  { geneTranscript: "NM_032477.3", parsimonySitesP: "0.330900243309002", constantSitesP: "0.535279805352798", distinctSitesP: "0.430656934306569", brLenTotal: "1.2835" },
  { geneTranscript: "NM_004952.5", parsimonySitesP: "0.170868347338936", constantSitesP: "0.754901960784314", distinctSitesP: "0.205882352941176", brLenTotal: "0.4724" },
  { geneTranscript: "NM_005779.3", parsimonySitesP: "0.160818713450292", constantSitesP: "0.725146198830409", distinctSitesP: "0.247076023391813", brLenTotal: "0.6756" },
  { geneTranscript: "NM_002835.4", parsimonySitesP: "0.134188034188034", constantSitesP: "0.747863247863248", distinctSitesP: "0.217521367521368", brLenTotal: "0.4492" },
  { geneTranscript: "NM_001198934.2", parsimonySitesP: "0.225647899910634", constantSitesP: "0.620643431635389", distinctSitesP: "0.2609472743521", brLenTotal: "0.8458" },
  { geneTranscript: "NM_017610.8", parsimonySitesP: "0.128127112914131", constantSitesP: "0.766734279918864", distinctSitesP: "0.181541582150101", brLenTotal: "0.4102" },
  { geneTranscript: "NM_178839.5", parsimonySitesP: "0.0996168582375479", constantSitesP: "0.837164750957854", distinctSitesP: "0.14367816091954", brLenTotal: "0.3137" },
  { geneTranscript: "NM_001388488.1", parsimonySitesP: "0.43312101910828", constantSitesP: "0.41932059447983", distinctSitesP: "0.552016985138004", brLenTotal: "2.6507" },
  { geneTranscript: "NM_021815.5", parsimonySitesP: "0.2", constantSitesP: "0.703448275862069", distinctSitesP: "0.264942528735632", brLenTotal: "0.7629" },
  { geneTranscript: "NM_001256007.3", parsimonySitesP: "0.168371696504689", constantSitesP: "0.710571184995737", distinctSitesP: "0.254049445865303", brLenTotal: "0.5216" },
  { geneTranscript: "NM_005505.5", parsimonySitesP: "0.258677144728225", constantSitesP: "0.628028814669286", distinctSitesP: "0.370006548788474", brLenTotal: "0.9892" },
  { geneTranscript: "NM_001144877.3", parsimonySitesP: "0.0940594059405941", constantSitesP: "0.822882288228823", distinctSitesP: "0.145764576457646", brLenTotal: "0.3104" },
  { geneTranscript: "NM_007189.3", parsimonySitesP: "0.14071696094168", constantSitesP: "0.70733012306046", distinctSitesP: "0.237560192616372", brLenTotal: "0.6043" },
  { geneTranscript: "NM_001037232.4", parsimonySitesP: "0.295524691358025", constantSitesP: "0.554012345679012", distinctSitesP: "0.417438271604938", brLenTotal: "1.1757" },
  { geneTranscript: "NM_001042406.2", parsimonySitesP: "0.164705882352941", constantSitesP: "0.730392156862745", distinctSitesP: "0.259803921568627", brLenTotal: "0.5615" },
  { geneTranscript: "NM_152362.3", parsimonySitesP: "0.338709677419355", constantSitesP: "0.448028673835125", distinctSitesP: "0.444444444444444", brLenTotal: "1.8513" },
  { geneTranscript: "NM_012144.4", parsimonySitesP: "0.182641869337148", constantSitesP: "0.691940867906533", distinctSitesP: "0.270863137815928", brLenTotal: "0.6285" },
  { geneTranscript: "NM_181619.2", parsimonySitesP: "0.459915611814346", constantSitesP: "0.257383966244726", distinctSitesP: "0.835443037974684", brLenTotal: "4.3355" },
  { geneTranscript: "NM_182580.3", parsimonySitesP: "0.16448326055313", constantSitesP: "0.719068413391557", distinctSitesP: "0.2372634643377", brLenTotal: "0.4903" },
  { geneTranscript: "NM_001040260.4", parsimonySitesP: "0.163185378590078", constantSitesP: "0.691470844212359", distinctSitesP: "0.243690165361184", brLenTotal: "0.6646" },
  { geneTranscript: "NM_015891.3", parsimonySitesP: "0.112262521588946", constantSitesP: "0.80195739781232", distinctSitesP: "0.16580310880829", brLenTotal: "0.3583" },
  { geneTranscript: "NM_014228.5", parsimonySitesP: "0.144654088050314", constantSitesP: "0.754716981132075", distinctSitesP: "0.201781970649895", brLenTotal: "0.5899" },
  { geneTranscript: "NM_178138.6", parsimonySitesP: "0.207388748950462", constantSitesP: "0.65071368597817", distinctSitesP: "0.317380352644836", brLenTotal: "1.1274" },
  { geneTranscript: "NM_001282874.2", parsimonySitesP: "0.11214953271028", constantSitesP: "0.795015576323988", distinctSitesP: "0.153271028037383", brLenTotal: "0.3360" },
  { geneTranscript: "NM_005173.4", parsimonySitesP: "0.215215215215215", constantSitesP: "0.672339005672339", distinctSitesP: "0.268601935268602", brLenTotal: "1.1681" },
  { geneTranscript: "NM_001355247.2", parsimonySitesP: "0.4004329004329", constantSitesP: "0.324675324675325", distinctSitesP: "0.677489177489177", brLenTotal: "1.5890" },
  { geneTranscript: "NM_001245002.2", parsimonySitesP: "0.173884514435696", constantSitesP: "0.741469816272966", distinctSitesP: "0.231627296587927", brLenTotal: "0.8723" },
  { geneTranscript: "NM_021255.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.673015873015873", distinctSitesP: "0.292857142857143", brLenTotal: "1.0014" },
  { geneTranscript: "NM_003452.4", parsimonySitesP: "0.108626198083067", constantSitesP: "0.798189563365282", distinctSitesP: "0.164536741214058", brLenTotal: "0.3288" },
  { geneTranscript: "NM_002313.7", parsimonySitesP: "0.167523564695801", constantSitesP: "0.74293059125964", distinctSitesP: "0.249357326478149", brLenTotal: "0.6119" },
  { geneTranscript: "NM_005252.4", parsimonySitesP: "0.174561403508772", constantSitesP: "0.728070175438596", distinctSitesP: "0.234210526315789", brLenTotal: "0.6519" },
  { geneTranscript: "NM_016424.5", parsimonySitesP: "0.107253086419753", constantSitesP: "0.8125", distinctSitesP: "0.172067901234568", brLenTotal: "0.3106" },
  { geneTranscript: "NM_015686.3", parsimonySitesP: "0.147598870056497", constantSitesP: "0.730225988700565", distinctSitesP: "0.227401129943503", brLenTotal: "0.4854" },
  { geneTranscript: "NM_152833.3", parsimonySitesP: "0.182254196642686", constantSitesP: "0.693045563549161", distinctSitesP: "0.280575539568345", brLenTotal: "0.7676" },
  { geneTranscript: "NM_006516.4", parsimonySitesP: "0.159214092140921", constantSitesP: "0.754065040650407", distinctSitesP: "0.217479674796748", brLenTotal: "0.6861" },
  { geneTranscript: "NM_004379.5", parsimonySitesP: "0.0744138634046891", constantSitesP: "0.856269113149847", distinctSitesP: "0.127420998980632", brLenTotal: "0.2487" },
  { geneTranscript: "NM_015272.5", parsimonySitesP: "0.191381495564005", constantSitesP: "0.674271229404309", distinctSitesP: "0.289987325728771", brLenTotal: "0.7031" },
  { geneTranscript: "NM_133369.3", parsimonySitesP: "0.167854315122724", constantSitesP: "0.730403800475059", distinctSitesP: "0.218527315914489", brLenTotal: "0.8822" },
  { geneTranscript: "NM_030628.2", parsimonySitesP: "0.173372587504089", constantSitesP: "0.720968269545306", distinctSitesP: "0.205430160287864", brLenTotal: "0.5591" },
  { geneTranscript: "NM_001113491.2", parsimonySitesP: "0.217861205915813", constantSitesP: "0.682025028441411", distinctSitesP: "0.282707622298066", brLenTotal: "1.0755" },
  { geneTranscript: "NM_181719.7", parsimonySitesP: "0.266035751840168", constantSitesP: "0.585173501577287", distinctSitesP: "0.379074658254469", brLenTotal: "1.0616" },
  { geneTranscript: "NM_004655.4", parsimonySitesP: "0.192566231712139", constantSitesP: "0.621194147884539", distinctSitesP: "0.270462633451957", brLenTotal: "0.8400" },
  { geneTranscript: "NM_024065.5", parsimonySitesP: "0.260808926080893", constantSitesP: "0.562064156206416", distinctSitesP: "0.428172942817294", brLenTotal: "0.9970" },
  { geneTranscript: "NM_001005328.2", parsimonySitesP: "0.466666666666667", constantSitesP: "0.337634408602151", distinctSitesP: "0.641935483870968", brLenTotal: "3.6417" },
  { geneTranscript: "NM_002907.4", parsimonySitesP: "0.185413456599897", constantSitesP: "0.673343605546995", distinctSitesP: "0.331278890600924", brLenTotal: "0.6647" },
  { geneTranscript: "NM_018394.4", parsimonySitesP: "0.241830065359477", constantSitesP: "0.598039215686275", distinctSitesP: "0.381263616557734", brLenTotal: "0.8515" },
  { geneTranscript: "NM_207344.4", parsimonySitesP: "0.151368760064412", constantSitesP: "0.718196457326892", distinctSitesP: "0.262479871175523", brLenTotal: "0.4874" },
  { geneTranscript: "NM_002624.4", parsimonySitesP: "0.119047619047619", constantSitesP: "0.768398268398268", distinctSitesP: "0.216450216450216", brLenTotal: "0.4058" },
  { geneTranscript: "NM_001039803.3", parsimonySitesP: "0.228323699421965", constantSitesP: "0.613680154142582", distinctSitesP: "0.336223506743738", brLenTotal: "0.9013" },
  { geneTranscript: "NM_014734.4", parsimonySitesP: "0.182618261826183", constantSitesP: "0.668866886688669", distinctSitesP: "0.265126512651265", brLenTotal: "0.7736" },
  { geneTranscript: "NM_007023.4", parsimonySitesP: "0.107814045499505", constantSitesP: "0.804154302670623", distinctSitesP: "0.169139465875371", brLenTotal: "0.4034" },
  { geneTranscript: "NM_005321.3", parsimonySitesP: "0.23896499238965", constantSitesP: "0.649923896499239", distinctSitesP: "0.319634703196347", brLenTotal: "1.2082" },
  { geneTranscript: "NM_001388490.1", parsimonySitesP: "0.196825396825397", constantSitesP: "0.667460317460317", distinctSitesP: "0.270634920634921", brLenTotal: "0.7263" },
  { geneTranscript: "NM_021620.4", parsimonySitesP: "0.245638849599246", constantSitesP: "0.611975483262612", distinctSitesP: "0.326732673267327", brLenTotal: "0.9503" },
  { geneTranscript: "NM_032339.5", parsimonySitesP: "0.194202898550725", constantSitesP: "0.684057971014493", distinctSitesP: "0.330434782608696", brLenTotal: "0.6232" },
  { geneTranscript: "NM_020866.3", parsimonySitesP: "0.169786096256684", constantSitesP: "0.732620320855615", distinctSitesP: "0.233511586452763", brLenTotal: "0.5965" },
  { geneTranscript: "NM_001136203.2", parsimonySitesP: "0.209267563527653", constantSitesP: "0.704035874439462", distinctSitesP: "0.257100149476831", brLenTotal: "0.6981" },
  { geneTranscript: "NM_182948.4", parsimonySitesP: "0.107202680067002", constantSitesP: "0.815745393634841", distinctSitesP: "0.17001675041876", brLenTotal: "0.3236" },
  { geneTranscript: "NM_006736.6", parsimonySitesP: "0.191358024691358", constantSitesP: "0.67798353909465", distinctSitesP: "0.283950617283951", brLenTotal: "0.6887" },
  { geneTranscript: "NM_003385.5", parsimonySitesP: "0.169284467713787", constantSitesP: "0.762652705061082", distinctSitesP: "0.230366492146597", brLenTotal: "0.7842" },
  { geneTranscript: "NM_177398.4", parsimonySitesP: "0.160558464223386", constantSitesP: "0.741710296684119", distinctSitesP: "0.233856893542757", brLenTotal: "0.5749" },
  { geneTranscript: "NM_005754.3", parsimonySitesP: "0.169527896995708", constantSitesP: "0.676680972818312", distinctSitesP: "0.271101573676681", brLenTotal: "0.5842" },
  { geneTranscript: "NM_005783.4", parsimonySitesP: "0.144542772861357", constantSitesP: "0.706489675516224", distinctSitesP: "0.253687315634218", brLenTotal: "0.5214" },
  { geneTranscript: "NM_139343.3", parsimonySitesP: "0.161888701517707", constantSitesP: "0.729623383923553", distinctSitesP: "0.234401349072513", brLenTotal: "0.8110" },
  { geneTranscript: "NM_001258031.2", parsimonySitesP: "0.203989703989704", constantSitesP: "0.637065637065637", distinctSitesP: "0.316602316602317", brLenTotal: "0.6173" },
  { geneTranscript: "NM_001384156.1", parsimonySitesP: "0.123090745732255", constantSitesP: "0.790655884995508", distinctSitesP: "0.204851752021563", brLenTotal: "0.7191" },
  { geneTranscript: "NM_001162529.3", parsimonySitesP: "0.139493949394939", constantSitesP: "0.72035203520352", distinctSitesP: "0.205500550055006", brLenTotal: "0.4866" },
  { geneTranscript: "NM_017821.5", parsimonySitesP: "0.202420242024202", constantSitesP: "0.665566556655666", distinctSitesP: "0.315731573157316", brLenTotal: "0.6836" },
  { geneTranscript: "NM_003213.4", parsimonySitesP: "0.206605222734255", constantSitesP: "0.693548387096774", distinctSitesP: "0.261904761904762", brLenTotal: "1.0520" },
  { geneTranscript: "NM_173551.5", parsimonySitesP: "0.212782242632989", constantSitesP: "0.656333716035209", distinctSitesP: "0.314198239571374", brLenTotal: "0.9395" },
  { geneTranscript: "NM_022173.4", parsimonySitesP: "0.0811744386873921", constantSitesP: "0.857512953367876", distinctSitesP: "0.130397236614853", brLenTotal: "0.2852" },
  { geneTranscript: "NM_152467.5", parsimonySitesP: "0.144736842105263", constantSitesP: "0.75", distinctSitesP: "0.194627192982456", brLenTotal: "0.5571" },
  { geneTranscript: "NM_001288732.2", parsimonySitesP: "0.167406092871931", constantSitesP: "0.698018337769891", distinctSitesP: "0.225672877846791", brLenTotal: "0.7133" },
  { geneTranscript: "NM_173814.6", parsimonySitesP: "0.178260869565217", constantSitesP: "0.698260869565217", distinctSitesP: "0.244057971014493", brLenTotal: "0.6354" },
  { geneTranscript: "NM_000718.4", parsimonySitesP: "0.219467008693174", constantSitesP: "0.665099045176001", distinctSitesP: "0.269773407439077", brLenTotal: "1.0270" },
  { geneTranscript: "NM_001278212.2", parsimonySitesP: "0.181159420289855", constantSitesP: "0.697463768115942", distinctSitesP: "0.280797101449275", brLenTotal: "0.7596" },
  { geneTranscript: "NM_000741.5", parsimonySitesP: "0.171189979123173", constantSitesP: "0.755045233124565", distinctSitesP: "0.219902574808629", brLenTotal: "0.8276" },
  { geneTranscript: "NM_182511.4", parsimonySitesP: "0.123511904761905", constantSitesP: "0.772321428571429", distinctSitesP: "0.251488095238095", brLenTotal: "0.4440" },
  { geneTranscript: "NM_004264.5", parsimonySitesP: "0.12037037037037", constantSitesP: "0.763888888888889", distinctSitesP: "0.247685185185185", brLenTotal: "0.4442" },
  { geneTranscript: "NM_020795.4", parsimonySitesP: "0.143712574850299", constantSitesP: "0.788822355289421", distinctSitesP: "0.180439121756487", brLenTotal: "0.6683" },
  { geneTranscript: "NM_133367.5", parsimonySitesP: "0.19774011299435", constantSitesP: "0.710922787193974", distinctSitesP: "0.252354048964218", brLenTotal: "0.7469" },
  { geneTranscript: "NM_001368397.1", parsimonySitesP: "0.152478244419221", constantSitesP: "0.745175936435868", distinctSitesP: "0.177449867574726", brLenTotal: "0.5516" },
  { geneTranscript: "NM_005941.5", parsimonySitesP: "0.0779791323448655", constantSitesP: "0.85063152114223", distinctSitesP: "0.124107633168589", brLenTotal: "0.2571" },
  { geneTranscript: "NM_001640.4", parsimonySitesP: "0.171675774134791", constantSitesP: "0.694444444444444", distinctSitesP: "0.215391621129326", brLenTotal: "0.5910" },
  { geneTranscript: "NM_005957.5", parsimonySitesP: "0.204268292682927", constantSitesP: "0.673272357723577", distinctSitesP: "0.274390243902439", brLenTotal: "0.8348" },
  { geneTranscript: "NM_012384.5", parsimonySitesP: "0.214465408805031", constantSitesP: "0.672955974842767", distinctSitesP: "0.276100628930818", brLenTotal: "1.2757" },
  { geneTranscript: "NM_015874.6", parsimonySitesP: "0.116358658453114", constantSitesP: "0.786447638603696", distinctSitesP: "0.182751540041068", brLenTotal: "0.4251" },
  { geneTranscript: "NM_001079673.2", parsimonySitesP: "0.147468002225932", constantSitesP: "0.739009460211464", distinctSitesP: "0.217028380634391", brLenTotal: "0.4568" },
  { geneTranscript: "NM_015481.3", parsimonySitesP: "0.119307832422587", constantSitesP: "0.769581056466302", distinctSitesP: "0.192167577413479", brLenTotal: "0.4315" },
  { geneTranscript: "NM_001008216.2", parsimonySitesP: "0.174329501915709", constantSitesP: "0.708812260536398", distinctSitesP: "0.246168582375479", brLenTotal: "0.6679" },
  { geneTranscript: "NM_080862.3", parsimonySitesP: "0.227106227106227", constantSitesP: "0.673992673992674", distinctSitesP: "0.266178266178266", brLenTotal: "1.1291" },
  { geneTranscript: "NM_001164380.2", parsimonySitesP: "0.103508771929825", constantSitesP: "0.809941520467836", distinctSitesP: "0.15906432748538", brLenTotal: "0.3397" },
  { geneTranscript: "NM_001293.3", parsimonySitesP: "0.140646976090014", constantSitesP: "0.729957805907173", distinctSitesP: "0.248945147679325", brLenTotal: "0.5212" },
  { geneTranscript: "NM_004896.5", parsimonySitesP: "0.0835881753312946", constantSitesP: "0.81855249745158", distinctSitesP: "0.151885830784913", brLenTotal: "0.2663" },
  { geneTranscript: "NM_153713.3", parsimonySitesP: "0.134520276953511", constantSitesP: "0.763600395647873", distinctSitesP: "0.212660731948566", brLenTotal: "0.4357" },
  { geneTranscript: "NM_001079559.3", parsimonySitesP: "0.127621597501116", constantSitesP: "0.780901383311022", distinctSitesP: "0.184738955823293", brLenTotal: "0.3885" },
  { geneTranscript: "NM_001145312.3", parsimonySitesP: "0.205078125", constantSitesP: "0.681640625", distinctSitesP: "0.272786458333333", brLenTotal: "0.9234" },
  { geneTranscript: "NM_020133.3", parsimonySitesP: "0.216049382716049", constantSitesP: "0.709876543209877", distinctSitesP: "0.27336860670194", brLenTotal: "0.9177" },
  { geneTranscript: "NM_006372.5", parsimonySitesP: "0.126805778491172", constantSitesP: "0.80577849117175", distinctSitesP: "0.157303370786517", brLenTotal: "0.4138" },
  { geneTranscript: "NM_005372.1", parsimonySitesP: "0.320809248554913", constantSitesP: "0.535645472061657", distinctSitesP: "0.491329479768786", brLenTotal: "1.3119" },
  { geneTranscript: "NM_017584.6", parsimonySitesP: "0.229239766081871", constantSitesP: "0.639766081871345", distinctSitesP: "0.357894736842105", brLenTotal: "1.0592" },
  { geneTranscript: "NM_017416.2", parsimonySitesP: "0.137998056365403", constantSitesP: "0.75801749271137", distinctSitesP: "0.207968901846453", brLenTotal: "0.4114" },
  { geneTranscript: "NM_031297.7", parsimonySitesP: "0.189016602809706", constantSitesP: "0.694763729246488", distinctSitesP: "0.264367816091954", brLenTotal: "0.9402" },
  { geneTranscript: "NM_024717.7", parsimonySitesP: "0.171838505171839", constantSitesP: "0.722722722722723", distinctSitesP: "0.242242242242242", brLenTotal: "0.5658" },
  { geneTranscript: "NM_001878.4", parsimonySitesP: "0.159420289855072", constantSitesP: "0.748792270531401", distinctSitesP: "0.260869565217391", brLenTotal: "0.6013" },
  { geneTranscript: "NM_003131.4", parsimonySitesP: "0.094488188976378", constantSitesP: "0.825459317585302", distinctSitesP: "0.145669291338583", brLenTotal: "0.3193" },
  { geneTranscript: "NM_014740.4", parsimonySitesP: "0.180859691808597", constantSitesP: "0.727493917274939", distinctSitesP: "0.283860502838605", brLenTotal: "0.8690" },
  { geneTranscript: "NM_016271.5", parsimonySitesP: "0.104761904761905", constantSitesP: "0.810884353741497", distinctSitesP: "0.201360544217687", brLenTotal: "0.3216" },
  { geneTranscript: "NM_025235.4", parsimonySitesP: "0.125500285877644", constantSitesP: "0.77472841623785", distinctSitesP: "0.168096054888508", brLenTotal: "0.4296" },
  { geneTranscript: "NM_005392.4", parsimonySitesP: "0.181265206812652", constantSitesP: "0.702858880778589", distinctSitesP: "0.242700729927007", brLenTotal: "1.0388" },
  { geneTranscript: "NM_001079524.2", parsimonySitesP: "0.174901960784314", constantSitesP: "0.631372549019608", distinctSitesP: "0.32156862745098", brLenTotal: "0.6740" },
  { geneTranscript: "NM_015935.5", parsimonySitesP: "0.193609918931807", constantSitesP: "0.684310920362423", distinctSitesP: "0.258941344778255", brLenTotal: "0.6744" },
  { geneTranscript: "NM_006166.4", parsimonySitesP: "0.0933977455716586", constantSitesP: "0.809983896940419", distinctSitesP: "0.194847020933977", brLenTotal: "0.3292" },
  { geneTranscript: "NM_001466.4", parsimonySitesP: "0.260766961651917", constantSitesP: "0.630088495575221", distinctSitesP: "0.326253687315634", brLenTotal: "1.1950" },
  { geneTranscript: "NM_001940.4", parsimonySitesP: "0.181792717086835", constantSitesP: "0.684873949579832", distinctSitesP: "0.240056022408964", brLenTotal: "0.7290" },
  { geneTranscript: "NM_178430.4", parsimonySitesP: "0.406060606060606", constantSitesP: "0.318181818181818", distinctSitesP: "0.742424242424242", brLenTotal: "3.7301" },
  { geneTranscript: "NM_000107.3", parsimonySitesP: "0.160811865729899", constantSitesP: "0.704918032786885", distinctSitesP: "0.260733801717408", brLenTotal: "0.5398" },
  { geneTranscript: "NM_152376.5", parsimonySitesP: "0.31547619047619", constantSitesP: "0.511904761904762", distinctSitesP: "0.479761904761905", brLenTotal: "1.1040" },
  { geneTranscript: "NM_001080433.2", parsimonySitesP: "0.149487643158529", constantSitesP: "0.756479807112719", distinctSitesP: "0.221217600964436", brLenTotal: "0.6260" },
  { geneTranscript: "NM_024536.6", parsimonySitesP: "0.193118279569892", constantSitesP: "0.679569892473118", distinctSitesP: "0.235698924731183", brLenTotal: "0.9955" },
  { geneTranscript: "NM_033102.3", parsimonySitesP: "0.221217600964436", constantSitesP: "0.654008438818565", distinctSitesP: "0.289933694996986", brLenTotal: "0.9326" },
  { geneTranscript: "NM_182623.3", parsimonySitesP: "0.279761904761905", constantSitesP: "0.53452380952381", distinctSitesP: "0.46547619047619", brLenTotal: "1.2436" },
  { geneTranscript: "NM_001282788.3", parsimonySitesP: "0.251461988304094", constantSitesP: "0.579922027290448", distinctSitesP: "0.381091617933723", brLenTotal: "0.8093" },
  { geneTranscript: "NM_006122.4", parsimonySitesP: "0.213623188405797", constantSitesP: "0.681449275362319", distinctSitesP: "0.25304347826087", brLenTotal: "0.9256" },
  { geneTranscript: "NM_014548.4", parsimonySitesP: "0.135802469135802", constantSitesP: "0.763532763532764", distinctSitesP: "0.205128205128205", brLenTotal: "0.4621" },
  { geneTranscript: "NM_000187.4", parsimonySitesP: "0.172284644194757", constantSitesP: "0.719850187265918", distinctSitesP: "0.274906367041199", brLenTotal: "0.5685" },
  { geneTranscript: "NM_001396061.1", parsimonySitesP: "0.383708467309753", constantSitesP: "0.435155412647374", distinctSitesP: "0.555198285101822", brLenTotal: "1.3390" },
  { geneTranscript: "NM_003073.5", parsimonySitesP: "0.131601731601732", constantSitesP: "0.808658008658009", distinctSitesP: "0.183549783549784", brLenTotal: "0.8290" },
  { geneTranscript: "NM_014587.5", parsimonySitesP: "0.280269058295964", constantSitesP: "0.589686098654709", distinctSitesP: "0.366218236173393", brLenTotal: "1.5225" },
  { geneTranscript: "NM_033414.3", parsimonySitesP: "0.20335429769392", constantSitesP: "0.637316561844864", distinctSitesP: "0.328441649196366", brLenTotal: "0.7476" },
  { geneTranscript: "NM_024674.6", parsimonySitesP: "0.154704944178628", constantSitesP: "0.631578947368421", distinctSitesP: "0.333333333333333", brLenTotal: "0.7649" },
  { geneTranscript: "NM_001195626.3", parsimonySitesP: "0.108926342072409", constantSitesP: "0.795255930087391", distinctSitesP: "0.176029962546816", brLenTotal: "0.3645" },
  { geneTranscript: "NM_001363711.2", parsimonySitesP: "0.237510766580534", constantSitesP: "0.62984496124031", distinctSitesP: "0.314168819982773", brLenTotal: "0.9705" },
  { geneTranscript: "NM_014800.11", parsimonySitesP: "0.136176066024759", constantSitesP: "0.781751490142137", distinctSitesP: "0.185694635488308", brLenTotal: "0.5922" },
  { geneTranscript: "NM_000360.4", parsimonySitesP: "0.263581488933602", constantSitesP: "0.641180415828303", distinctSitesP: "0.340040241448692", brLenTotal: "1.2730" },
  { geneTranscript: "NM_003442.6", parsimonySitesP: "0.115987460815047", constantSitesP: "0.802507836990596", distinctSitesP: "0.16405433646813", brLenTotal: "0.4119" },
  { geneTranscript: "NM_007136.4", parsimonySitesP: "0.505494505494505", constantSitesP: "0.334554334554335", distinctSitesP: "0.631257631257631", brLenTotal: "1.9060" },
  { geneTranscript: "NM_145690.3", parsimonySitesP: "0.172789115646259", constantSitesP: "0.644897959183673", distinctSitesP: "0.315646258503401", brLenTotal: "0.7189" },
  { geneTranscript: "NM_031464.5", parsimonySitesP: "0.27565270188221", constantSitesP: "0.556769884638737", distinctSitesP: "0.372799028536733", brLenTotal: "1.0666" },
  { geneTranscript: "NM_001048181.3", parsimonySitesP: "0.208791208791209", constantSitesP: "0.702380952380952", distinctSitesP: "0.266483516483516", brLenTotal: "0.9148" },
  { geneTranscript: "NM_178435.4", parsimonySitesP: "0.434782608695652", constantSitesP: "0.289855072463768", distinctSitesP: "0.72463768115942", brLenTotal: "2.9084" },
  { geneTranscript: "NM_021097.5", parsimonySitesP: "0.134977732100034", constantSitesP: "0.745460774237753", distinctSitesP: "0.187392942788626", brLenTotal: "0.5782" },
  { geneTranscript: "NM_001291999.2", parsimonySitesP: "0.131741821396994", constantSitesP: "0.73474801061008", distinctSitesP: "0.225464190981432", brLenTotal: "0.4336" },
  { geneTranscript: "NM_198850.4", parsimonySitesP: "0.269270833333333", constantSitesP: "0.593229166666667", distinctSitesP: "0.370833333333333", brLenTotal: "1.0468" },
  { geneTranscript: "NM_033082.4", parsimonySitesP: "0.103174603174603", constantSitesP: "0.822222222222222", distinctSitesP: "0.2", brLenTotal: "0.3506" },
  { geneTranscript: "NM_005258.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.726190476190476", distinctSitesP: "0.305555555555556", brLenTotal: "0.4622" },
  { geneTranscript: "NM_014489.4", parsimonySitesP: "0.136507936507937", constantSitesP: "0.784126984126984", distinctSitesP: "0.202116402116402", brLenTotal: "0.4558" },
  { geneTranscript: "NM_005467.4", parsimonySitesP: "0.220720720720721", constantSitesP: "0.63963963963964", distinctSitesP: "0.33018018018018", brLenTotal: "0.8960" },
  { geneTranscript: "NM_018913.3", parsimonySitesP: "0.445512820512821", constantSitesP: "0.450854700854701", distinctSitesP: "0.523860398860399", brLenTotal: "3.9325" },
  { geneTranscript: "NM_032641.4", parsimonySitesP: "0.238276299112801", constantSitesP: "0.487959442332066", distinctSitesP: "0.465145754119138", brLenTotal: "1.0718" },
  { geneTranscript: "NM_001146079.2", parsimonySitesP: "0.237099023709902", constantSitesP: "0.659693165969317", distinctSitesP: "0.316596931659693", brLenTotal: "0.9814" },
  { geneTranscript: "NM_021103.4", parsimonySitesP: "0.0833333333333333", constantSitesP: "0.765151515151515", distinctSitesP: "0.272727272727273", brLenTotal: "0.3078" },
  { geneTranscript: "NM_145214.3", parsimonySitesP: "0.31980056980057", constantSitesP: "0.563390313390313", distinctSitesP: "0.366096866096866", brLenTotal: "1.4348" },
  { geneTranscript: "NM_002958.4", parsimonySitesP: "0.122460186710599", constantSitesP: "0.766062602965404", distinctSitesP: "0.231191652937946", brLenTotal: "0.4347" },
  { geneTranscript: "NM_001167608.3", parsimonySitesP: "0.243386243386243", constantSitesP: "0.638095238095238", distinctSitesP: "0.374603174603175", brLenTotal: "0.8413" },
  { geneTranscript: "NM_003524.3", parsimonySitesP: "0.23015873015873", constantSitesP: "0.661375661375661", distinctSitesP: "0.343915343915344", brLenTotal: "1.6502" },
  { geneTranscript: "NM_175839.3", parsimonySitesP: "0.154354354354354", constantSitesP: "0.753153153153153", distinctSitesP: "0.207207207207207", brLenTotal: "0.6541" },
  { geneTranscript: "NM_014680.5", parsimonySitesP: "0.155853840417599", constantSitesP: "0.735719612229679", distinctSitesP: "0.173750932140194", brLenTotal: "0.4843" },
  { geneTranscript: "NM_012142.5", parsimonySitesP: "0.210185185185185", constantSitesP: "0.618518518518519", distinctSitesP: "0.356481481481481", brLenTotal: "0.7157" },
  { geneTranscript: "NM_198402.5", parsimonySitesP: "0.15748031496063", constantSitesP: "0.758530183727034", distinctSitesP: "0.23753280839895", brLenTotal: "0.5029" },
  { geneTranscript: "NM_021232.2", parsimonySitesP: "0.284782608695652", constantSitesP: "0.568840579710145", distinctSitesP: "0.401449275362319", brLenTotal: "1.1505" },
  { geneTranscript: "NM_198849.3", parsimonySitesP: "0.225526641883519", constantSitesP: "0.669144981412639", distinctSitesP: "0.330855018587361", brLenTotal: "0.8955" },
  { geneTranscript: "NM_001042472.3", parsimonySitesP: "0.178391959798995", constantSitesP: "0.705192629815745", distinctSitesP: "0.299832495812395", brLenTotal: "0.6655" },
  { geneTranscript: "NM_021733.2", parsimonySitesP: "0.241554054054054", constantSitesP: "0.608108108108108", distinctSitesP: "0.365990990990991", brLenTotal: "0.8852" },
  { geneTranscript: "NM_001001954.2", parsimonySitesP: "0.396090534979424", constantSitesP: "0.429012345679012", distinctSitesP: "0.545267489711934", brLenTotal: "1.6047" },
  { geneTranscript: "NM_006579.3", parsimonySitesP: "0.250724637681159", constantSitesP: "0.572463768115942", distinctSitesP: "0.395652173913043", brLenTotal: "0.8977" },
  { geneTranscript: "NM_004219.4", parsimonySitesP: "0.242574257425743", constantSitesP: "0.544554455445545", distinctSitesP: "0.455445544554455", brLenTotal: "0.9483" },
  { geneTranscript: "NM_000823.4", parsimonySitesP: "0.243498817966903", constantSitesP: "0.605200945626478", distinctSitesP: "0.375098502758077", brLenTotal: "0.9799" },
  { geneTranscript: "NM_014759.5", parsimonySitesP: "0.12020202020202", constantSitesP: "0.776767676767677", distinctSitesP: "0.184848484848485", brLenTotal: "0.6496" },
  { geneTranscript: "NM_153006.3", parsimonySitesP: "0.224094881398252", constantSitesP: "0.621722846441948", distinctSitesP: "0.322097378277154", brLenTotal: "0.8006" },
  { geneTranscript: "NM_001316.4", parsimonySitesP: "0.112598695502918", constantSitesP: "0.81531067627875", distinctSitesP: "0.148987298317885", brLenTotal: "0.3573" },
  { geneTranscript: "NM_015710.5", parsimonySitesP: "0.315202231520223", constantSitesP: "0.51255230125523", distinctSitesP: "0.424686192468619", brLenTotal: "1.3921" },
  { geneTranscript: "NM_001002.4", parsimonySitesP: "0.16403785488959", constantSitesP: "0.661409043112513", distinctSitesP: "0.28391167192429", brLenTotal: "0.6684" },
  { geneTranscript: "NM_207368.5", parsimonySitesP: "0.199312714776632", constantSitesP: "0.731958762886598", distinctSitesP: "0.292096219931271", brLenTotal: "0.7605" },
  { geneTranscript: "NM_019601.4", parsimonySitesP: "0.309407948094079", constantSitesP: "0.533657745336577", distinctSitesP: "0.420924574209246", brLenTotal: "1.5233" },
  { geneTranscript: "NM_001367767.2", parsimonySitesP: "0.227895392278954", constantSitesP: "0.653383146533831", distinctSitesP: "0.297633872976339", brLenTotal: "0.9762" },
  { geneTranscript: "NM_012282.4", parsimonySitesP: "0.251173708920188", constantSitesP: "0.607981220657277", distinctSitesP: "0.363849765258216", brLenTotal: "0.8562" },
  { geneTranscript: "NM_207303.4", parsimonySitesP: "0.113608895334784", constantSitesP: "0.797679477882524", distinctSitesP: "0.153251148175006", brLenTotal: "0.3593" },
  { geneTranscript: "NM_020904.3", parsimonySitesP: "0.247325631151048", constantSitesP: "0.585793752674369", distinctSitesP: "0.353016688061617", brLenTotal: "0.9737" },
  { geneTranscript: "NM_003868.3", parsimonySitesP: "0.138486312399356", constantSitesP: "0.732689210950081", distinctSitesP: "0.231884057971014", brLenTotal: "0.4831" },
  { geneTranscript: "NM_031885.5", parsimonySitesP: "0.195561719833564", constantSitesP: "0.682847896440129", distinctSitesP: "0.279704114655571", brLenTotal: "0.6218" },
  { geneTranscript: "NM_004830.4", parsimonySitesP: "0.135964912280702", constantSitesP: "0.783625730994152", distinctSitesP: "0.169103313840156", brLenTotal: "0.4599" },
  { geneTranscript: "NM_020751.3", parsimonySitesP: "0.177067478437341", constantSitesP: "0.710806697108067", distinctSitesP: "0.258751902587519", brLenTotal: "0.6129" },
  { geneTranscript: "NM_019593.5", parsimonySitesP: "0.147321428571429", constantSitesP: "0.744543650793651", distinctSitesP: "0.229662698412698", brLenTotal: "0.5090" },
  { geneTranscript: "NM_001024924.2", parsimonySitesP: "0.14951528709918", constantSitesP: "0.75316927665921", distinctSitesP: "0.211409395973154", brLenTotal: "0.4924" },
  { geneTranscript: "NM_032236.8", parsimonySitesP: "0.12914653784219", constantSitesP: "0.789371980676329", distinctSitesP: "0.172946859903382", brLenTotal: "0.4396" },
  { geneTranscript: "NM_014183.4", parsimonySitesP: "0.128472222222222", constantSitesP: "0.743055555555556", distinctSitesP: "0.260416666666667", brLenTotal: "0.4620" },
  { geneTranscript: "NM_006293.4", parsimonySitesP: "0.1812734082397", constantSitesP: "0.714232209737828", distinctSitesP: "0.234831460674157", brLenTotal: "0.6418" },
  { geneTranscript: "NM_000984.6", parsimonySitesP: "0.136752136752137", constantSitesP: "0.745726495726496", distinctSitesP: "0.288461538461538", brLenTotal: "0.5032" },
  { geneTranscript: "NM_001004329.3", parsimonySitesP: "0.246804326450344", constantSitesP: "0.573254670599803", distinctSitesP: "0.392330383480826", brLenTotal: "0.8318" },
  { geneTranscript: "NM_012248.4", parsimonySitesP: "0.255952380952381", constantSitesP: "0.506696428571429", distinctSitesP: "0.436755952380952", brLenTotal: "1.1172" },
  { geneTranscript: "NM_001098536.2", parsimonySitesP: "0.143745143745144", constantSitesP: "0.770784770784771", distinctSitesP: "0.174825174825175", brLenTotal: "0.5346" },
  { geneTranscript: "NM_001244949.2", parsimonySitesP: "0.177938808373591", constantSitesP: "0.7085346215781", distinctSitesP: "0.255233494363929", brLenTotal: "0.6077" },
  { geneTranscript: "NM_018119.4", parsimonySitesP: "0.183145009416196", constantSitesP: "0.703389830508475", distinctSitesP: "0.251883239171375", brLenTotal: "0.7648" },
  { geneTranscript: "NM_015836.4", parsimonySitesP: "0.258333333333333", constantSitesP: "0.609259259259259", distinctSitesP: "0.356481481481481", brLenTotal: "0.9458" },
  { geneTranscript: "NM_004333.6", parsimonySitesP: "0.087467362924282", constantSitesP: "0.831592689295039", distinctSitesP: "0.148389904264578", brLenTotal: "0.2824" },
  { geneTranscript: "NM_024012.4", parsimonySitesP: "0.282913165266106", constantSitesP: "0.589169000933707", distinctSitesP: "0.378151260504202", brLenTotal: "1.1589" },
  { geneTranscript: "NM_001017962.3", parsimonySitesP: "0.107365792759051", constantSitesP: "0.792759051186017", distinctSitesP: "0.162297128589263", brLenTotal: "0.3218" },
  { geneTranscript: "NM_206809.4", parsimonySitesP: "0.214574898785425", constantSitesP: "0.638326585695007", distinctSitesP: "0.368421052631579", brLenTotal: "0.7495" },
  { geneTranscript: "NM_001485.4", parsimonySitesP: "0.115900383141762", constantSitesP: "0.801724137931034", distinctSitesP: "0.1772030651341", brLenTotal: "0.4334" },
  { geneTranscript: "NM_002819.5", parsimonySitesP: "0.197486535008977", constantSitesP: "0.70197486535009", distinctSitesP: "0.271693596648713", brLenTotal: "1.1014" },
  { geneTranscript: "NM_001029881.3", parsimonySitesP: "0.190990990990991", constantSitesP: "0.700900900900901", distinctSitesP: "0.300900900900901", brLenTotal: "0.7296" },
  { geneTranscript: "NM_001286615.2", parsimonySitesP: "0.104363001745201", constantSitesP: "0.816055846422339", distinctSitesP: "0.147294938917976", brLenTotal: "0.3670" },
  { geneTranscript: "NM_006763.3", parsimonySitesP: "0.183544303797468", constantSitesP: "0.651898734177215", distinctSitesP: "0.284810126582278", brLenTotal: "0.7010" },
  { geneTranscript: "NM_000104.4", parsimonySitesP: "0.29036218538981", constantSitesP: "0.583793738489871", distinctSitesP: "0.352977286678944", brLenTotal: "0.9117" },
  { geneTranscript: "NM_005051.3", parsimonySitesP: "0.2", constantSitesP: "0.661935483870968", distinctSitesP: "0.277849462365591", brLenTotal: "0.6537" },
  { geneTranscript: "NM_015879.3", parsimonySitesP: "0.0649122807017544", constantSitesP: "0.856140350877193", distinctSitesP: "0.121052631578947", brLenTotal: "0.2231" },
  { geneTranscript: "NM_002906.4", parsimonySitesP: "0.176672384219554", constantSitesP: "0.711835334476844", distinctSitesP: "0.244139508290452", brLenTotal: "0.6081" },
  { geneTranscript: "NM_017693.4", parsimonySitesP: "0.150430748840292", constantSitesP: "0.742876076872101", distinctSitesP: "0.234592445328032", brLenTotal: "0.4889" },
  { geneTranscript: "NM_001137675.4", parsimonySitesP: "0.131107885824867", constantSitesP: "0.751330430575714", distinctSitesP: "0.206579583938075", brLenTotal: "0.4798" },
  { geneTranscript: "NM_054032.3", parsimonySitesP: "0.623188405797101", constantSitesP: "0.212215320910973", distinctSitesP: "0.793995859213251", brLenTotal: "3.8195" },
  { geneTranscript: "NM_005453.5", parsimonySitesP: "0.213985278654048", constantSitesP: "0.656677181913775", distinctSitesP: "0.277602523659306", brLenTotal: "0.7405" },
  { geneTranscript: "NM_000388.4", parsimonySitesP: "0.1713048855906", constantSitesP: "0.727891156462585", distinctSitesP: "0.22665429808287", brLenTotal: "0.6482" },
  { geneTranscript: "NM_006024.7", parsimonySitesP: "0.16687790452049", constantSitesP: "0.705111956062526", distinctSitesP: "0.252640473172793", brLenTotal: "0.5302" },
  { geneTranscript: "NM_016494.4", parsimonySitesP: "0.200435729847495", constantSitesP: "0.638344226579521", distinctSitesP: "0.335511982570806", brLenTotal: "0.6905" },
  { geneTranscript: "NM_001270520.2", parsimonySitesP: "0.159488139825218", constantSitesP: "0.750936329588015", distinctSitesP: "0.205680399500624", brLenTotal: "0.5469" },
  { geneTranscript: "NM_018079.5", parsimonySitesP: "0.187269681742044", constantSitesP: "0.680402010050251", distinctSitesP: "0.279731993299832", brLenTotal: "0.6146" },
  { geneTranscript: "NM_001078172.2", parsimonySitesP: "0.268436578171091", constantSitesP: "0.489675516224189", distinctSitesP: "0.469026548672566", brLenTotal: "1.2079" },
  { geneTranscript: "NM_213594.3", parsimonySitesP: "0.117006802721088", constantSitesP: "0.793650793650794", distinctSitesP: "0.166439909297052", brLenTotal: "0.4189" },
  { geneTranscript: "NM_198241.3", parsimonySitesP: "0.177194079633104", constantSitesP: "0.701688555347092", distinctSitesP: "0.227433812799666", brLenTotal: "0.6716" },
  { geneTranscript: "NM_133328.4", parsimonySitesP: "0.138036809815951", constantSitesP: "0.750511247443763", distinctSitesP: "0.208588957055215", brLenTotal: "0.5360" },
  { geneTranscript: "NM_019015.3", parsimonySitesP: "0.240500863557858", constantSitesP: "0.664075993091537", distinctSitesP: "0.268566493955095", brLenTotal: "0.9264" },
  { geneTranscript: "NM_198446.3", parsimonySitesP: "0.121212121212121", constantSitesP: "0.787878787878788", distinctSitesP: "0.221212121212121", brLenTotal: "0.3709" },
  { geneTranscript: "NM_001079935.2", parsimonySitesP: "0.584070796460177", constantSitesP: "0.236971484759095", distinctSitesP: "0.757128810226155", brLenTotal: "3.8752" },
  { geneTranscript: "NM_022829.6", parsimonySitesP: "0.212070874861573", constantSitesP: "0.668327796234773", distinctSitesP: "0.293466223698782", brLenTotal: "0.7689" },
  { geneTranscript: "NM_022802.3", parsimonySitesP: "0.252453468697124", constantSitesP: "0.595939086294416", distinctSitesP: "0.331641285956007", brLenTotal: "1.1261" },
  { geneTranscript: "NM_001015.5", parsimonySitesP: "0.19831223628692", constantSitesP: "0.666666666666667", distinctSitesP: "0.30379746835443", brLenTotal: "0.7624" },
  { geneTranscript: "NM_001025389.2", parsimonySitesP: "0.166014776184268", constantSitesP: "0.740547588005215", distinctSitesP: "0.211647109952195", brLenTotal: "0.6869" },
  { geneTranscript: "NM_001003692.2", parsimonySitesP: "0.180392156862745", constantSitesP: "0.709803921568627", distinctSitesP: "0.270588235294118", brLenTotal: "0.6125" },
  { geneTranscript: "NM_019111.5", parsimonySitesP: "0.303149606299213", constantSitesP: "0.536745406824147", distinctSitesP: "0.454068241469816", brLenTotal: "1.3716" },
  { geneTranscript: "NM_002505.5", parsimonySitesP: "0.114313160422671", constantSitesP: "0.804995196926033", distinctSitesP: "0.182516810758886", brLenTotal: "0.3785" },
  { geneTranscript: "NM_020774.4", parsimonySitesP: "0.113982770046388", constantSitesP: "0.809145129224652", distinctSitesP: "0.154075546719682", brLenTotal: "0.3890" },
  { geneTranscript: "NM_000490.5", parsimonySitesP: "0.26219512195122", constantSitesP: "0.599593495934959", distinctSitesP: "0.396341463414634", brLenTotal: "1.0086" },
  { geneTranscript: "NM_006796.3", parsimonySitesP: "0.186114596403179", constantSitesP: "0.710581346716855", distinctSitesP: "0.265161020493517", brLenTotal: "0.6922" },
  { geneTranscript: "NM_001319206.4", parsimonySitesP: "0.149174917491749", constantSitesP: "0.735973597359736", distinctSitesP: "0.217821782178218", brLenTotal: "0.6041" },
  { geneTranscript: "NM_020354.5", parsimonySitesP: "0.155629139072848", constantSitesP: "0.716335540838852", distinctSitesP: "0.217991169977925", brLenTotal: "0.5884" },
  { geneTranscript: "NM_080621.5", parsimonySitesP: "0.165016501650165", constantSitesP: "0.698019801980198", distinctSitesP: "0.283828382838284", brLenTotal: "0.7122" },
  { geneTranscript: "NM_004997.3", parsimonySitesP: "0.264849755415793", constantSitesP: "0.602375960866527", distinctSitesP: "0.364779874213836", brLenTotal: "1.2015" },
  { geneTranscript: "NM_144676.4", parsimonySitesP: "0.218055555555556", constantSitesP: "0.616666666666667", distinctSitesP: "0.355555555555556", brLenTotal: "0.7160" },
  { geneTranscript: "NM_016072.5", parsimonySitesP: "0.0772946859903382", constantSitesP: "0.867149758454106", distinctSitesP: "0.135265700483092", brLenTotal: "0.2577" },
  { geneTranscript: "NM_022469.4", parsimonySitesP: "0.146825396825397", constantSitesP: "0.682539682539683", distinctSitesP: "0.244047619047619", brLenTotal: "0.5367" },
  { geneTranscript: "NM_001320870.2", parsimonySitesP: "0.176666666666667", constantSitesP: "0.715555555555556", distinctSitesP: "0.256666666666667", brLenTotal: "0.5790" },
  { geneTranscript: "NM_001014.5", parsimonySitesP: "0.149494949494949", constantSitesP: "0.678787878787879", distinctSitesP: "0.307070707070707", brLenTotal: "0.6913" },
  { geneTranscript: "NM_001384900.1", parsimonySitesP: "0.16988416988417", constantSitesP: "0.721149721149721", distinctSitesP: "0.238524238524239", brLenTotal: "0.5364" },
  { geneTranscript: "NM_017707.4", parsimonySitesP: "0.215946843853821", constantSitesP: "0.645994832041344", distinctSitesP: "0.284237726098191", brLenTotal: "0.7817" },
  { geneTranscript: "NM_007163.4", parsimonySitesP: "0.265579710144928", constantSitesP: "0.585507246376812", distinctSitesP: "0.383695652173913", brLenTotal: "1.1676" },
  { geneTranscript: "NM_003782.4", parsimonySitesP: "0.276014109347443", constantSitesP: "0.59700176366843", distinctSitesP: "0.340388007054674", brLenTotal: "0.9604" },
  { geneTranscript: "NM_001320848.2", parsimonySitesP: "0.208067940552017", constantSitesP: "0.626326963906582", distinctSitesP: "0.333333333333333", brLenTotal: "0.8457" },
  { geneTranscript: "NM_001736.4", parsimonySitesP: "0.398095238095238", constantSitesP: "0.486666666666667", distinctSitesP: "0.518095238095238", brLenTotal: "1.9851" },
  { geneTranscript: "NM_015516.4", parsimonySitesP: "0.27289896128423", constantSitesP: "0.582625118035883", distinctSitesP: "0.354107648725212", brLenTotal: "1.1527" },
  { geneTranscript: "NM_032341.5", parsimonySitesP: "0.12280701754386", constantSitesP: "0.783625730994152", distinctSitesP: "0.174603174603175", brLenTotal: "0.4040" },
  { geneTranscript: "NM_001270616.2", parsimonySitesP: "0.10085933966531", constantSitesP: "0.843962008141113", distinctSitesP: "0.126639529624604", brLenTotal: "0.3527" },
  { geneTranscript: "NM_014748.4", parsimonySitesP: "0.129078014184397", constantSitesP: "0.760283687943262", distinctSitesP: "0.200709219858156", brLenTotal: "0.4646" },
  { geneTranscript: "NM_002515.3", parsimonySitesP: "0.0276134122287968", constantSitesP: "0.943458251150559", distinctSitesP: "0.05456936226167", brLenTotal: "0.0740" },
  { geneTranscript: "NM_020996.3", parsimonySitesP: "0.200320512820513", constantSitesP: "0.673076923076923", distinctSitesP: "0.301282051282051", brLenTotal: "0.7021" },
  { geneTranscript: "NM_001375505.1", parsimonySitesP: "0.198503922641854", constantSitesP: "0.661740558292282", distinctSitesP: "0.275862068965517", brLenTotal: "0.6925" },
  { geneTranscript: "NM_001394531.1", parsimonySitesP: "0.261934673366834", constantSitesP: "0.581448911222781", distinctSitesP: "0.336892797319933", brLenTotal: "1.0577" },
  { geneTranscript: "NM_015937.6", parsimonySitesP: "0.182814302191465", constantSitesP: "0.704728950403691", distinctSitesP: "0.25836216839677", brLenTotal: "0.7507" },
  { geneTranscript: "NM_001286769.2", parsimonySitesP: "0.244279529993816", constantSitesP: "0.63821892393321", distinctSitesP: "0.330241187384045", brLenTotal: "0.8042" },
  { geneTranscript: "NM_025201.5", parsimonySitesP: "0.261904761904762", constantSitesP: "0.561904761904762", distinctSitesP: "0.353061224489796", brLenTotal: "1.0172" },
  { geneTranscript: "NM_001985.3", parsimonySitesP: "0.196078431372549", constantSitesP: "0.694117647058824", distinctSitesP: "0.288888888888889", brLenTotal: "0.8497" },
  { geneTranscript: "NM_133471.4", parsimonySitesP: "0.253398586188146", constantSitesP: "0.55247417074497", distinctSitesP: "0.364328439369222", brLenTotal: "0.8708" },
  { geneTranscript: "NM_000871.3", parsimonySitesP: "0.203030303030303", constantSitesP: "0.665909090909091", distinctSitesP: "0.302272727272727", brLenTotal: "0.9434" },
  { geneTranscript: "NM_006790.3", parsimonySitesP: "0.147925033467202", constantSitesP: "0.736947791164659", distinctSitesP: "0.238286479250335", brLenTotal: "0.4766" },
  { geneTranscript: "NM_001040272.6", parsimonySitesP: "0.174233825198638", constantSitesP: "0.706583427922815", distinctSitesP: "0.213961407491487", brLenTotal: "0.6799" },
  { geneTranscript: "NM_001753.5", parsimonySitesP: "0.136704119850187", constantSitesP: "0.767790262172285", distinctSitesP: "0.224719101123596", brLenTotal: "0.5410" },
  { geneTranscript: "NM_024034.6", parsimonySitesP: "0.11716621253406", constantSitesP: "0.797456857402361", distinctSitesP: "0.181653042688465", brLenTotal: "0.5093" },
  { geneTranscript: "NM_001882.4", parsimonySitesP: "0.194616977225673", constantSitesP: "0.664596273291925", distinctSitesP: "0.299171842650104", brLenTotal: "0.6208" },
  { geneTranscript: "NM_006242.4", parsimonySitesP: "0.289855072463768", constantSitesP: "0.569676700111483", distinctSitesP: "0.405797101449275", brLenTotal: "1.1863" },
  { geneTranscript: "NM_003907.3", parsimonySitesP: "0.218677762367083", constantSitesP: "0.647711511789182", distinctSitesP: "0.283402681460934", brLenTotal: "0.6660" },
  { geneTranscript: "NM_001387048.1", parsimonySitesP: "0.253639846743295", constantSitesP: "0.65095785440613", distinctSitesP: "0.308045977011494", brLenTotal: "1.1504" },
  { geneTranscript: "NM_181675.4", parsimonySitesP: "0.0993227990970655", constantSitesP: "0.81640331075997", distinctSitesP: "0.158013544018059", brLenTotal: "0.3625" },
  { geneTranscript: "NM_015557.3", parsimonySitesP: "0.178096212896622", constantSitesP: "0.719890822244968", distinctSitesP: "0.220743773456158", brLenTotal: "0.8202" },
  { geneTranscript: "NM_201280.3", parsimonySitesP: "0.204991087344029", constantSitesP: "0.655971479500891", distinctSitesP: "0.320855614973262", brLenTotal: "0.6593" },
  { geneTranscript: "NM_000701.8", parsimonySitesP: "0.177256435320951", constantSitesP: "0.743890518084066", distinctSitesP: "0.22059302704464", brLenTotal: "0.8261" },
  { geneTranscript: "NM_004937.3", parsimonySitesP: "0.231607629427793", constantSitesP: "0.662125340599455", distinctSitesP: "0.333333333333333", brLenTotal: "0.8728" },
  { geneTranscript: "NM_004700.4", parsimonySitesP: "0.14484412470024", constantSitesP: "0.747721822541966", distinctSitesP: "0.228776978417266", brLenTotal: "0.5761" },
  { geneTranscript: "NM_001363066.2", parsimonySitesP: "0.212538226299694", constantSitesP: "0.695718654434251", distinctSitesP: "0.27217125382263", brLenTotal: "0.7195" },
  { geneTranscript: "NM_003942.3", parsimonySitesP: "0.165371329879102", constantSitesP: "0.746545768566494", distinctSitesP: "0.227115716753022", brLenTotal: "0.6751" },
  { geneTranscript: "NM_205836.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.808080808080808", distinctSitesP: "0.148148148148148", brLenTotal: "0.3527" },
  { geneTranscript: "NM_198597.3", parsimonySitesP: "0.139549055453991", constantSitesP: "0.741925655088361", distinctSitesP: "0.184948202315661", brLenTotal: "0.4492" },
  { geneTranscript: "NM_015111.2", parsimonySitesP: "0.229166666666667", constantSitesP: "0.630514705882353", distinctSitesP: "0.315563725490196", brLenTotal: "0.9655" },
  { geneTranscript: "NM_133263.4", parsimonySitesP: "0.250244379276637", constantSitesP: "0.586836102965135", distinctSitesP: "0.328445747800587", brLenTotal: "1.0555" },
  { geneTranscript: "NM_007325.5", parsimonySitesP: "0.108128262490679", constantSitesP: "0.808351976137211", distinctSitesP: "0.151006711409396", brLenTotal: "0.4626" },
  { geneTranscript: "NM_006506.5", parsimonySitesP: "0.109148017275226", constantSitesP: "0.800549666274048", distinctSitesP: "0.161758932076953", brLenTotal: "0.3452" },
  { geneTranscript: "NM_001130842.2", parsimonySitesP: "0.17530390275112", constantSitesP: "0.572616762635956", distinctSitesP: "0.316058861164427", brLenTotal: "0.8153" },
  { geneTranscript: "NM_001091.4", parsimonySitesP: "0.289835774522858", constantSitesP: "0.552152685308478", distinctSitesP: "0.384820239680426", brLenTotal: "1.2233" },
  { geneTranscript: "NM_004274.5", parsimonySitesP: "0.191461836998706", constantSitesP: "0.675434813856547", distinctSitesP: "0.238177375305448", brLenTotal: "0.6037" },
  { geneTranscript: "NM_020445.6", parsimonySitesP: "0.155502392344498", constantSitesP: "0.777511961722488", distinctSitesP: "0.203349282296651", brLenTotal: "0.6065" },
  { geneTranscript: "NM_018250.4", parsimonySitesP: "0.17629179331307", constantSitesP: "0.739108409321175", distinctSitesP: "0.232522796352584", brLenTotal: "0.7130" },
  { geneTranscript: "NM_001017915.3", parsimonySitesP: "0.217269414073451", constantSitesP: "0.676478833753855", distinctSitesP: "0.28174936921783", brLenTotal: "0.9281" },
  { geneTranscript: "NM_033388.2", parsimonySitesP: "0.255250403877221", constantSitesP: "0.599892299407647", distinctSitesP: "0.350565428109855", brLenTotal: "0.8973" },
  { geneTranscript: "NM_005400.3", parsimonySitesP: "0.138851198552691", constantSitesP: "0.775667118950701", distinctSitesP: "0.186793306196291", brLenTotal: "0.5368" },
  { geneTranscript: "NM_001791.4", parsimonySitesP: "0.12739965095986", constantSitesP: "0.684118673647469", distinctSitesP: "0.286212914485166", brLenTotal: "0.5963" },
  { geneTranscript: "NM_018086.4", parsimonySitesP: "0.095740008783487", constantSitesP: "0.829600351339482", distinctSitesP: "0.140096618357488", brLenTotal: "0.3508" },
  { geneTranscript: "NM_020184.4", parsimonySitesP: "0.207741935483871", constantSitesP: "0.686451612903226", distinctSitesP: "0.260645161290323", brLenTotal: "0.6894" },
  { geneTranscript: "NM_025081.3", parsimonySitesP: "0.248155953635406", constantSitesP: "0.619599578503688", distinctSitesP: "0.281875658587987", brLenTotal: "0.8757" },
  { geneTranscript: "NM_004483.5", parsimonySitesP: "0.28131021194605", constantSitesP: "0.520231213872832", distinctSitesP: "0.529865125240848", brLenTotal: "1.1119" },
  { geneTranscript: "NM_145176.3", parsimonySitesP: "0.24689357104268", constantSitesP: "0.620745542949757", distinctSitesP: "0.329551593733117", brLenTotal: "0.8771" },
  { geneTranscript: "NM_015523.4", parsimonySitesP: "0.0843881856540084", constantSitesP: "0.839662447257384", distinctSitesP: "0.153305203938115", brLenTotal: "0.2534" },
  { geneTranscript: "NM_153827.5", parsimonySitesP: "0.153403403403403", constantSitesP: "0.747997997997998", distinctSitesP: "0.181181181181181", brLenTotal: "0.6141" },
  { geneTranscript: "NM_021828.5", parsimonySitesP: "0.104166666666667", constantSitesP: "0.794481981981982", distinctSitesP: "0.162725225225225", brLenTotal: "0.3327" },
  { geneTranscript: "NM_001032386.2", parsimonySitesP: "0.200611620795107", constantSitesP: "0.656269113149847", distinctSitesP: "0.275229357798165", brLenTotal: "0.6434" },
  { geneTranscript: "NM_004352.4", parsimonySitesP: "0.126079447322971", constantSitesP: "0.803108808290155", distinctSitesP: "0.191709844559585", brLenTotal: "0.3118" },
  { geneTranscript: "NM_002577.4", parsimonySitesP: "0.162849872773537", constantSitesP: "0.724554707379135", distinctSitesP: "0.263358778625954", brLenTotal: "0.6640" },
  { geneTranscript: "NM_178562.5", parsimonySitesP: "0.138987043580683", constantSitesP: "0.767962308598351", distinctSitesP: "0.215547703180212", brLenTotal: "0.4596" },
  { geneTranscript: "NM_139162.4", parsimonySitesP: "0.281204111600587", constantSitesP: "0.567547723935389", distinctSitesP: "0.359765051395007", brLenTotal: "1.2008" },
  { geneTranscript: "NM_023079.5", parsimonySitesP: "0.096045197740113", constantSitesP: "0.838983050847458", distinctSitesP: "0.148775894538606", brLenTotal: "0.2948" },
  { geneTranscript: "NM_001545.3", parsimonySitesP: "0.252427184466019", constantSitesP: "0.592233009708738", distinctSitesP: "0.411003236245955", brLenTotal: "0.8847" },
  { geneTranscript: "NM_016176.6", parsimonySitesP: "0.260093896713615", constantSitesP: "0.64131455399061", distinctSitesP: "0.363380281690141", brLenTotal: "1.3641" },
  { geneTranscript: "NM_006013.5", parsimonySitesP: "0.222741433021807", constantSitesP: "0.577881619937695", distinctSitesP: "0.384735202492212", brLenTotal: "0.9802" },
  { geneTranscript: "NM_139245.4", parsimonySitesP: "0.137037037037037", constantSitesP: "0.792592592592593", distinctSitesP: "0.193518518518519", brLenTotal: "0.4071" },
  { geneTranscript: "NM_139164.3", parsimonySitesP: "0.172357723577236", constantSitesP: "0.699186991869919", distinctSitesP: "0.278048780487805", brLenTotal: "0.5143" },
  { geneTranscript: "NM_006186.4", parsimonySitesP: "0.0819397993311037", constantSitesP: "0.857859531772575", distinctSitesP: "0.12263099219621", brLenTotal: "0.2882" },
  { geneTranscript: "NM_177478.2", parsimonySitesP: "0.363636363636364", constantSitesP: "0.420110192837466", distinctSitesP: "0.555096418732782", brLenTotal: "1.6620" },
  { geneTranscript: "NM_022372.6", parsimonySitesP: "0.173824130879346", constantSitesP: "0.751533742331288", distinctSitesP: "0.222903885480573", brLenTotal: "0.7667" },
  { geneTranscript: "NM_001286646.2", parsimonySitesP: "0.251650165016502", constantSitesP: "0.641501650165017", distinctSitesP: "0.326732673267327", brLenTotal: "1.1741" },
  { geneTranscript: "NM_001385109.1", parsimonySitesP: "0.153279006596818", constantSitesP: "0.722157547535894", distinctSitesP: "0.222739619712844", brLenTotal: "0.6832" },
  { geneTranscript: "NM_002623.4", parsimonySitesP: "0.0995024875621891", constantSitesP: "0.778606965174129", distinctSitesP: "0.203980099502488", brLenTotal: "0.3699" },
  { geneTranscript: "NM_005030.6", parsimonySitesP: "0.17191818684356", constantSitesP: "0.70702045328911", distinctSitesP: "0.232172470978441", brLenTotal: "0.5968" },
  { geneTranscript: "NM_001901.4", parsimonySitesP: "0.169054441260745", constantSitesP: "0.720152817574021", distinctSitesP: "0.247373447946514", brLenTotal: "0.6190" },
  { geneTranscript: "NM_001330288.2", parsimonySitesP: "0.110575635876841", constantSitesP: "0.806425702811245", distinctSitesP: "0.139491298527443", brLenTotal: "0.4032" },
  { geneTranscript: "NM_178329.3", parsimonySitesP: "0.471361502347418", constantSitesP: "0.386854460093897", distinctSitesP: "0.584976525821596", brLenTotal: "2.3617" },
  { geneTranscript: "NM_001368894.2", parsimonySitesP: "0.0703363914373089", constantSitesP: "0.850152905198777", distinctSitesP: "0.117737003058104", brLenTotal: "0.3040" },
  { geneTranscript: "NM_032348.4", parsimonySitesP: "0.285067873303167", constantSitesP: "0.567119155354449", distinctSitesP: "0.41025641025641", brLenTotal: "1.1348" },
  { geneTranscript: "NM_001083893.2", parsimonySitesP: "0.0836470096194061", constantSitesP: "0.842325386867419", distinctSitesP: "0.131744040150565", brLenTotal: "0.2576" },
  { geneTranscript: "NM_007018.6", parsimonySitesP: "0.201863799283154", constantSitesP: "0.649462365591398", distinctSitesP: "0.28573476702509", brLenTotal: "0.6202" },
  { geneTranscript: "NM_013399.3", parsimonySitesP: "0.171474358974359", constantSitesP: "0.709935897435897", distinctSitesP: "0.264423076923077", brLenTotal: "0.6591" },
  { geneTranscript: "NM_001029875.3", parsimonySitesP: "0.0920881971465629", constantSitesP: "0.821011673151751", distinctSitesP: "0.173800259403372", brLenTotal: "0.3062" },
  { geneTranscript: "NM_012471.3", parsimonySitesP: "0.150393970537855", constantSitesP: "0.771154504967455", distinctSitesP: "0.193902021240151", brLenTotal: "0.6294" },
  { geneTranscript: "NM_057749.3", parsimonySitesP: "0.118811881188119", constantSitesP: "0.778877887788779", distinctSitesP: "0.197194719471947", brLenTotal: "0.3794" },
  { geneTranscript: "NM_003548.2", parsimonySitesP: "0.27831715210356", constantSitesP: "0.679611650485437", distinctSitesP: "0.333333333333333", brLenTotal: "1.7229" },
  { geneTranscript: "NM_001136201.2", parsimonySitesP: "0.261788617886179", constantSitesP: "0.604878048780488", distinctSitesP: "0.373983739837398", brLenTotal: "1.0709" },
  { geneTranscript: "NM_000559.3", parsimonySitesP: "0.312925170068027", constantSitesP: "0.566893424036281", distinctSitesP: "0.426303854875283", brLenTotal: "1.1559" },
  { geneTranscript: "NM_006091.5", parsimonySitesP: "0.150694444444444", constantSitesP: "0.75", distinctSitesP: "0.209027777777778", brLenTotal: "0.6928" },
  { geneTranscript: "NM_001395462.1", parsimonySitesP: "0.22366790582404", constantSitesP: "0.626084262701363", distinctSitesP: "0.27633209417596", brLenTotal: "0.7126" },
  { geneTranscript: "NM_005903.7", parsimonySitesP: "0.226523297491039", constantSitesP: "0.696057347670251", distinctSitesP: "0.268100358422939", brLenTotal: "1.0755" },
  { geneTranscript: "NM_001139488.2", parsimonySitesP: "0.141545893719807", constantSitesP: "0.768115942028986", distinctSitesP: "0.201449275362319", brLenTotal: "0.4881" },
  { geneTranscript: "NM_001031723.4", parsimonySitesP: "0.143359718557608", constantSitesP: "0.724714160070361", distinctSitesP: "0.25065963060686", brLenTotal: "0.5030" },
  { geneTranscript: "NM_203290.4", parsimonySitesP: "0.184007707129094", constantSitesP: "0.70616570327553", distinctSitesP: "0.271676300578035", brLenTotal: "0.6197" },
  { geneTranscript: "NM_001253852.3", parsimonySitesP: "0.169598556608029", constantSitesP: "0.698691926026161", distinctSitesP: "0.257104194857916", brLenTotal: "0.5770" },
  { geneTranscript: "NM_145057.4", parsimonySitesP: "0.279279279279279", constantSitesP: "0.560810810810811", distinctSitesP: "0.405405405405405", brLenTotal: "1.1556" },
  { geneTranscript: "NM_001711.6", parsimonySitesP: "0.211050724637681", constantSitesP: "0.70018115942029", distinctSitesP: "0.261775362318841", brLenTotal: "0.8042" },
  { geneTranscript: "NM_001038603.3", parsimonySitesP: "0.213859020310633", constantSitesP: "0.639187574671446", distinctSitesP: "0.32078853046595", brLenTotal: "0.8369" },
  { geneTranscript: "NM_001278512.2", parsimonySitesP: "0.174386920980926", constantSitesP: "0.722373599757796", distinctSitesP: "0.22252497729337", brLenTotal: "0.6750" },
  { geneTranscript: "NM_004208.4", parsimonySitesP: "0.125611745513866", constantSitesP: "0.745513866231648", distinctSitesP: "0.202827623708537", brLenTotal: "0.4828" },
  { geneTranscript: "NM_181718.4", parsimonySitesP: "0.245299145299145", constantSitesP: "0.615384615384615", distinctSitesP: "0.335897435897436", brLenTotal: "0.8980" },
  { geneTranscript: "NM_181503.3", parsimonySitesP: "0.115942028985507", constantSitesP: "0.760869565217391", distinctSitesP: "0.22463768115942", brLenTotal: "0.3988" },
  { geneTranscript: "NM_017769.5", parsimonySitesP: "0.151085930122757", constantSitesP: "0.731350330500472", distinctSitesP: "0.249763928234183", brLenTotal: "0.4894" },
  { geneTranscript: "NM_003243.5", parsimonySitesP: "0.210732471602037", constantSitesP: "0.641989815902859", distinctSitesP: "0.296513905209557", brLenTotal: "0.8197" },
  { geneTranscript: "NM_005723.4", parsimonySitesP: "0.104477611940299", constantSitesP: "0.84452736318408", distinctSitesP: "0.157960199004975", brLenTotal: "0.3439" },
  { geneTranscript: "NM_001037171.2", parsimonySitesP: "0.19047619047619", constantSitesP: "0.68452380952381", distinctSitesP: "0.311755952380952", brLenTotal: "0.6298" },
  { geneTranscript: "NM_173699.4", parsimonySitesP: "0.563654033041788", constantSitesP: "0.275024295432459", distinctSitesP: "0.696793002915452", brLenTotal: "3.0918" },
  { geneTranscript: "NM_000224.3", parsimonySitesP: "0.264341085271318", constantSitesP: "0.509302325581395", distinctSitesP: "0.416279069767442", brLenTotal: "1.3289" },
  { geneTranscript: "NM_001493.3", parsimonySitesP: "0.148396718866518", constantSitesP: "0.770320656226696", distinctSitesP: "0.199850857568978", brLenTotal: "0.5321" },
  { geneTranscript: "NM_015681.6", parsimonySitesP: "0.212418300653595", constantSitesP: "0.684640522875817", distinctSitesP: "0.312091503267974", brLenTotal: "0.7601" },
  { geneTranscript: "NM_001470.4", parsimonySitesP: "0.135622615331252", constantSitesP: "0.785293097467915", distinctSitesP: "0.171349288935137", brLenTotal: "0.4892" },
  { geneTranscript: "NM_024068.4", parsimonySitesP: "0.161137440758294", constantSitesP: "0.682464454976303", distinctSitesP: "0.278041074249605", brLenTotal: "0.6096" },
  { geneTranscript: "NM_005499.3", parsimonySitesP: "0.140625", constantSitesP: "0.74375", distinctSitesP: "0.213020833333333", brLenTotal: "0.4701" },
  { geneTranscript: "NM_022553.6", parsimonySitesP: "0.161825726141079", constantSitesP: "0.747810050714615", distinctSitesP: "0.19870908252651", brLenTotal: "0.4865" },
  { geneTranscript: "NM_007029.4", parsimonySitesP: "0.0782122905027933", constantSitesP: "0.759776536312849", distinctSitesP: "0.184357541899441", brLenTotal: "0.4323" },
  { geneTranscript: "NM_004130.4", parsimonySitesP: "0.157142857142857", constantSitesP: "0.695238095238095", distinctSitesP: "0.271428571428571", brLenTotal: "0.5881" },
  { geneTranscript: "NM_020971.3", parsimonySitesP: "0.18733749349974", constantSitesP: "0.71151846073843", distinctSitesP: "0.204758190327613", brLenTotal: "0.7648" },
  { geneTranscript: "NM_019007.4", parsimonySitesP: "0.225555555555556", constantSitesP: "0.578888888888889", distinctSitesP: "0.368888888888889", brLenTotal: "0.7696" },
  { geneTranscript: "NM_025065.7", parsimonySitesP: "0.163323782234957", constantSitesP: "0.715377268385864", distinctSitesP: "0.268385864374403", brLenTotal: "0.5219" },
  { geneTranscript: "NM_018398.3", parsimonySitesP: "0.136571952337305", constantSitesP: "0.780934922089826", distinctSitesP: "0.198594561564314", brLenTotal: "0.5253" },
  { geneTranscript: "NM_018120.6", parsimonySitesP: "0.147754137115839", constantSitesP: "0.74468085106383", distinctSitesP: "0.236406619385343", brLenTotal: "0.4863" },
  { geneTranscript: "NM_001077351.2", parsimonySitesP: "0.181473044798785", constantSitesP: "0.667425968109339", distinctSitesP: "0.314350797266515", brLenTotal: "0.6525" },
  { geneTranscript: "NM_020856.4", parsimonySitesP: "0.215541165587419", constantSitesP: "0.670983657107616", distinctSitesP: "0.251927227875424", brLenTotal: "1.0001" },
  { geneTranscript: "NM_004559.5", parsimonySitesP: "0.141975308641975", constantSitesP: "0.661522633744856", distinctSitesP: "0.326131687242798", brLenTotal: "0.6751" },
  { geneTranscript: "NM_005302.5", parsimonySitesP: "0.269711799891245", constantSitesP: "0.600326264274062", distinctSitesP: "0.351821642196846", brLenTotal: "1.0081" },
  { geneTranscript: "NM_001126.5", parsimonySitesP: "0.135964912280702", constantSitesP: "0.73172514619883", distinctSitesP: "0.248538011695906", brLenTotal: "0.5076" },
  { geneTranscript: "NM_021220.4", parsimonySitesP: "0.210909090909091", constantSitesP: "0.631515151515152", distinctSitesP: "0.323636363636364", brLenTotal: "0.8664" },
  { geneTranscript: "NM_005233.6", parsimonySitesP: "0.177348253645303", constantSitesP: "0.704984740590031", distinctSitesP: "0.238046795523906", brLenTotal: "0.7542" },
  { geneTranscript: "NM_003025.4", parsimonySitesP: "0.214673913043478", constantSitesP: "0.692934782608696", distinctSitesP: "0.28804347826087", brLenTotal: "1.1751" },
  { geneTranscript: "NM_206937.2", parsimonySitesP: "0.203073545554336", constantSitesP: "0.655323819978046", distinctSitesP: "0.276984998170509", brLenTotal: "0.7049" },
  { geneTranscript: "NM_000581.4", parsimonySitesP: "0.275862068965517", constantSitesP: "0.471264367816092", distinctSitesP: "0.522167487684729", brLenTotal: "1.2560" },
  { geneTranscript: "NM_015526.3", parsimonySitesP: "0.149299207800122", constantSitesP: "0.772090188909202", distinctSitesP: "0.207800121876904", brLenTotal: "0.6644" },
  { geneTranscript: "NM_014377.3", parsimonySitesP: "0.113258185721954", constantSitesP: "0.800322061191626", distinctSitesP: "0.168545356951154", brLenTotal: "0.3472" },
  { geneTranscript: "NM_013446.4", parsimonySitesP: "0.148686030428769", constantSitesP: "0.68118948824343", distinctSitesP: "0.269017980636238", brLenTotal: "0.6047" },
  { geneTranscript: "NM_001352186.2", parsimonySitesP: "0.143968871595331", constantSitesP: "0.757198443579767", distinctSitesP: "0.200778210116732", brLenTotal: "0.5413" },
  { geneTranscript: "NM_004698.4", parsimonySitesP: "0.105417276720351", constantSitesP: "0.793557833089312", distinctSitesP: "0.167886774036115", brLenTotal: "0.3601" },
  { geneTranscript: "NM_024814.4", parsimonySitesP: "0.076714188730482", constantSitesP: "0.781398506449423", distinctSitesP: "0.1642905634759", brLenTotal: "0.3366" },
  { geneTranscript: "NM_001288565.2", parsimonySitesP: "0.17304189435337", constantSitesP: "0.723132969034608", distinctSitesP: "0.258652094717668", brLenTotal: "0.8005" },
  { geneTranscript: "NM_022044.3", parsimonySitesP: "0.233785822021116", constantSitesP: "0.65158371040724", distinctSitesP: "0.334841628959276", brLenTotal: "0.9062" },
  { geneTranscript: "NM_024046.5", parsimonySitesP: "0.131736526946108", constantSitesP: "0.768463073852295", distinctSitesP: "0.184298070525615", brLenTotal: "0.4503" },
  { geneTranscript: "NM_001129993.3", parsimonySitesP: "0.122098421541318", constantSitesP: "0.758124419684308", distinctSitesP: "0.233983286908078", brLenTotal: "0.4308" },
  { geneTranscript: "NM_032387.5", parsimonySitesP: "0.233038348082596", constantSitesP: "0.615178331992491", distinctSitesP: "0.300080450522928", brLenTotal: "0.8384" },
  { geneTranscript: "NM_000989.4", parsimonySitesP: "0.115942028985507", constantSitesP: "0.72463768115942", distinctSitesP: "0.289855072463768", brLenTotal: "0.5490" },
  { geneTranscript: "NM_004493.3", parsimonySitesP: "0.186462324393359", constantSitesP: "0.69220945083014", distinctSitesP: "0.291187739463602", brLenTotal: "0.5697" },
  { geneTranscript: "NM_015101.4", parsimonySitesP: "0.188498402555911", constantSitesP: "0.701277955271565", distinctSitesP: "0.255058572949947", brLenTotal: "0.6653" },
  { geneTranscript: "NM_005164.4", parsimonySitesP: "0.140540540540541", constantSitesP: "0.746846846846847", distinctSitesP: "0.200900900900901", brLenTotal: "0.4550" },
  { geneTranscript: "NM_020686.6", parsimonySitesP: "0.204", constantSitesP: "0.700666666666667", distinctSitesP: "0.285333333333333", brLenTotal: "0.7996" },
  { geneTranscript: "NM_015292.3", parsimonySitesP: "0.193236714975845", constantSitesP: "0.668478260869565", distinctSitesP: "0.259359903381643", brLenTotal: "0.6529" },
  { geneTranscript: "NM_000786.4", parsimonySitesP: "0.165684348395547", constantSitesP: "0.685003274394237", distinctSitesP: "0.275049115913556", brLenTotal: "0.5604" },
  { geneTranscript: "NM_017553.3", parsimonySitesP: "0.125964010282776", constantSitesP: "0.785561268209083", distinctSitesP: "0.157883461868038", brLenTotal: "0.3943" },
  { geneTranscript: "NM_001129994.2", parsimonySitesP: "0.163722025912839", constantSitesP: "0.74793875147232", distinctSitesP: "0.235571260306243", brLenTotal: "0.8355" },
  { geneTranscript: "NM_033088.4", parsimonySitesP: "0.156113102349661", constantSitesP: "0.739147749900438", distinctSitesP: "0.201911589008363", brLenTotal: "0.5736" },
  { geneTranscript: "NM_001370302.1", parsimonySitesP: "0.216073781291173", constantSitesP: "0.657444005270092", distinctSitesP: "0.328063241106719", brLenTotal: "0.9344" },
  { geneTranscript: "NM_018444.4", parsimonySitesP: "0.126629422718808", constantSitesP: "0.78895096213532", distinctSitesP: "0.176288019863439", brLenTotal: "0.4125" },
  { geneTranscript: "NM_001252078.2", parsimonySitesP: "0.0975195378865104", constantSitesP: "0.828406388039416", distinctSitesP: "0.136595310907238", brLenTotal: "0.3179" },
  { geneTranscript: "NM_004502.4", parsimonySitesP: "0.124423963133641", constantSitesP: "0.758832565284178", distinctSitesP: "0.225806451612903", brLenTotal: "0.4676" },
  { geneTranscript: "NM_032294.3", parsimonySitesP: "0.223762376237624", constantSitesP: "0.672607260726073", distinctSitesP: "0.297689768976898", brLenTotal: "0.9351" },
  { geneTranscript: "NM_002522.4", parsimonySitesP: "0.13966049382716", constantSitesP: "0.779320987654321", distinctSitesP: "0.204475308641975", brLenTotal: "0.6030" },
  { geneTranscript: "NM_015460.4", parsimonySitesP: "0.225067908420644", constantSitesP: "0.625921614280171", distinctSitesP: "0.321303841676368", brLenTotal: "0.8915" },
  { geneTranscript: "NM_001957.4", parsimonySitesP: "0.150663544106167", constantSitesP: "0.746291959406714", distinctSitesP: "0.212334113973458", brLenTotal: "0.4779" },
  { geneTranscript: "NM_016096.5", parsimonySitesP: "0.0526315789473684", constantSitesP: "0.868421052631579", distinctSitesP: "0.140350877192982", brLenTotal: "0.1672" },
  { geneTranscript: "NM_198182.3", parsimonySitesP: "0.169363538295577", constantSitesP: "0.73462783171521", distinctSitesP: "0.23462783171521", brLenTotal: "0.7139" },
  { geneTranscript: "NM_003313.4", parsimonySitesP: "0.202492211838006", constantSitesP: "0.692627206645898", distinctSitesP: "0.286604361370717", brLenTotal: "0.7899" },
  { geneTranscript: "NM_021830.5", parsimonySitesP: "0.253411306042885", constantSitesP: "0.59551656920078", distinctSitesP: "0.321637426900585", brLenTotal: "0.8393" },
  { geneTranscript: "NM_001407.3", parsimonySitesP: "0.204911433172303", constantSitesP: "0.650161030595813", distinctSitesP: "0.204408212560386", brLenTotal: "0.7866" },
  { geneTranscript: "NM_018294.6", parsimonySitesP: "0.174101610904585", constantSitesP: "0.690830235439901", distinctSitesP: "0.267657992565056", brLenTotal: "0.5519" },
  { geneTranscript: "NM_021132.4", parsimonySitesP: "0.0801526717557252", constantSitesP: "0.857506361323155", distinctSitesP: "0.132315521628499", brLenTotal: "0.2717" },
  { geneTranscript: "NM_001386393.1", parsimonySitesP: "0.134057971014493", constantSitesP: "0.77463768115942", distinctSitesP: "0.197101449275362", brLenTotal: "0.3944" },
  { geneTranscript: "NM_023944.4", parsimonySitesP: "0.401399491094148", constantSitesP: "0.428117048346056", distinctSitesP: "0.547073791348601", brLenTotal: "2.6286" },
  { geneTranscript: "NM_016068.3", parsimonySitesP: "0.151315789473684", constantSitesP: "0.725877192982456", distinctSitesP: "0.258771929824561", brLenTotal: "0.5935" },
  { geneTranscript: "NM_001145667.2", parsimonySitesP: "0.151258128357365", constantSitesP: "0.752897936104043", distinctSitesP: "0.200169635284139", brLenTotal: "0.5217" },
  { geneTranscript: "NM_001370470.1", parsimonySitesP: "0.226190476190476", constantSitesP: "0.652380952380952", distinctSitesP: "0.326190476190476", brLenTotal: "0.8680" },
  { geneTranscript: "NM_016120.4", parsimonySitesP: "0.167200854700855", constantSitesP: "0.706196581196581", distinctSitesP: "0.262820512820513", brLenTotal: "0.5616" },
  { geneTranscript: "NM_001144869.3", parsimonySitesP: "0.301587301587302", constantSitesP: "0.542568542568543", distinctSitesP: "0.422799422799423", brLenTotal: "1.0805" },
  { geneTranscript: "NM_001166037.2", parsimonySitesP: "0.433656957928803", constantSitesP: "0.406957928802589", distinctSitesP: "0.563106796116505", brLenTotal: "1.5366" },
  { geneTranscript: "NM_012388.4", parsimonySitesP: "0.145348837209302", constantSitesP: "0.697674418604651", distinctSitesP: "0.27906976744186", brLenTotal: "0.5430" },
  { geneTranscript: "NM_001300791.2", parsimonySitesP: "0.120752984389348", constantSitesP: "0.7910927456382", distinctSitesP: "0.162993572084481", brLenTotal: "0.4093" },
  { geneTranscript: "NM_001033113.2", parsimonySitesP: "0.356902356902357", constantSitesP: "0.495622895622896", distinctSitesP: "0.463299663299663", brLenTotal: "1.4882" },
  { geneTranscript: "NM_153756.3", parsimonySitesP: "0.127358490566038", constantSitesP: "0.762578616352201", distinctSitesP: "0.221698113207547", brLenTotal: "0.5132" },
  { geneTranscript: "NM_015641.4", parsimonySitesP: "0.176563737133808", constantSitesP: "0.701504354711006", distinctSitesP: "0.270783847980998", brLenTotal: "0.6080" },
  { geneTranscript: "NM_000286.3", parsimonySitesP: "0.162488393686165", constantSitesP: "0.708449396471681", distinctSitesP: "0.259052924791086", brLenTotal: "0.4815" },
  { geneTranscript: "NM_006349.3", parsimonySitesP: "0.162337662337662", constantSitesP: "0.757575757575758", distinctSitesP: "0.248917748917749", brLenTotal: "0.7548" },
  { geneTranscript: "NM_016628.5", parsimonySitesP: "0.0839773312725399", constantSitesP: "0.844925296239052", distinctSitesP: "0.128799587841319", brLenTotal: "0.2874" },
  { geneTranscript: "NM_014455.4", parsimonySitesP: "0.125", constantSitesP: "0.754385964912281", distinctSitesP: "0.219298245614035", brLenTotal: "0.4124" },
  { geneTranscript: "NM_004588.5", parsimonySitesP: "0.148837209302326", constantSitesP: "0.761240310077519", distinctSitesP: "0.241860465116279", brLenTotal: "0.6248" },
  { geneTranscript: "NM_001015072.4", parsimonySitesP: "0.312206572769953", constantSitesP: "0.518779342723005", distinctSitesP: "0.429577464788732", brLenTotal: "1.0806" },
  { geneTranscript: "NM_001103146.3", parsimonySitesP: "0.140107775211701", constantSitesP: "0.759558634847318", distinctSitesP: "0.192968950474724", brLenTotal: "0.4849" },
  { geneTranscript: "NM_020877.5", parsimonySitesP: "0.198403734658535", constantSitesP: "0.685339959340411", distinctSitesP: "0.211128680069272", brLenTotal: "0.7254" },
  { geneTranscript: "NM_002497.4", parsimonySitesP: "0.1812734082397", constantSitesP: "0.69063670411985", distinctSitesP: "0.268164794007491", brLenTotal: "0.5629" },
  { geneTranscript: "NM_016454.4", parsimonySitesP: "0.123861566484517", constantSitesP: "0.786885245901639", distinctSitesP: "0.204007285974499", brLenTotal: "0.3411" },
  { geneTranscript: "NM_002788.4", parsimonySitesP: "0.0967320261437908", constantSitesP: "0.809150326797386", distinctSitesP: "0.193464052287582", brLenTotal: "0.3321" },
  { geneTranscript: "NM_032228.6", parsimonySitesP: "0.106148867313916", constantSitesP: "0.746278317152104", distinctSitesP: "0.218770226537217", brLenTotal: "0.4067" },
  { geneTranscript: "NM_000429.3", parsimonySitesP: "0.2", constantSitesP: "0.683544303797468", distinctSitesP: "0.286075949367089", brLenTotal: "1.1687" },
  { geneTranscript: "NM_001085365.2", parsimonySitesP: "0.348101265822785", constantSitesP: "0.462025316455696", distinctSitesP: "0.540084388185654", brLenTotal: "1.3634" },
  { geneTranscript: "NM_080652.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.675505050505051", distinctSitesP: "0.306818181818182", brLenTotal: "0.6674" },
  { geneTranscript: "NM_001013635.4", parsimonySitesP: "0.178694158075601", constantSitesP: "0.685567010309278", distinctSitesP: "0.309278350515464", brLenTotal: "0.5570" },
  { geneTranscript: "NM_000106.6", parsimonySitesP: "0.333333333333333", constantSitesP: "0.482226693494299", distinctSitesP: "0.513749161636486", brLenTotal: "1.8518" },
  { geneTranscript: "NM_005600.3", parsimonySitesP: "0.164118246687054", constantSitesP: "0.705402650356779", distinctSitesP: "0.269113149847095", brLenTotal: "0.5276" },
  { geneTranscript: "NM_001098821.2", parsimonySitesP: "0.246124031007752", constantSitesP: "0.637596899224806", distinctSitesP: "0.354651162790698", brLenTotal: "0.7988" },
  { geneTranscript: "NM_001376049.1", parsimonySitesP: "0.175621890547264", constantSitesP: "0.688557213930348", distinctSitesP: "0.266666666666667", brLenTotal: "0.5503" },
  { geneTranscript: "NM_006039.5", parsimonySitesP: "0.189091728645481", constantSitesP: "0.694838855082263", distinctSitesP: "0.220419202163624", brLenTotal: "0.8198" },
  { geneTranscript: "NM_000159.4", parsimonySitesP: "0.226788432267884", constantSitesP: "0.640791476407915", distinctSitesP: "0.319634703196347", brLenTotal: "0.9177" },
  { geneTranscript: "NM_014337.4", parsimonySitesP: "0.201282051282051", constantSitesP: "0.68974358974359", distinctSitesP: "0.301923076923077", brLenTotal: "0.8546" },
  { geneTranscript: "NM_001497.4", parsimonySitesP: "0.214405360134003", constantSitesP: "0.663316582914573", distinctSitesP: "0.291457286432161", brLenTotal: "0.6985" },
  { geneTranscript: "NM_001001436.4", parsimonySitesP: "0.0822510822510823", constantSitesP: "0.844155844155844", distinctSitesP: "0.158008658008658", brLenTotal: "0.2265" },
  { geneTranscript: "NM_032125.3", parsimonySitesP: "0.184294871794872", constantSitesP: "0.697115384615385", distinctSitesP: "0.269230769230769", brLenTotal: "0.7138" },
  { geneTranscript: "NM_020801.4", parsimonySitesP: "0.0652173913043478", constantSitesP: "0.867954911433172", distinctSitesP: "0.114331723027375", brLenTotal: "0.2088" },
  { geneTranscript: "NM_001004744.1", parsimonySitesP: "0.509259259259259", constantSitesP: "0.316872427983539", distinctSitesP: "0.685185185185185", brLenTotal: "2.8009" },
  { geneTranscript: "NM_152729.3", parsimonySitesP: "0.19047619047619", constantSitesP: "0.638827838827839", distinctSitesP: "0.317216117216117", brLenTotal: "0.7892" },
  { geneTranscript: "NM_022491.3", parsimonySitesP: "0.129065040650407", constantSitesP: "0.764227642276423", distinctSitesP: "0.217479674796748", brLenTotal: "0.4749" },
  { geneTranscript: "NM_016470.8", parsimonySitesP: "0.132420091324201", constantSitesP: "0.720319634703196", distinctSitesP: "0.240867579908676", brLenTotal: "0.4702" },
  { geneTranscript: "NM_199127.3", parsimonySitesP: "0.299694189602446", constantSitesP: "0.562691131498471", distinctSitesP: "0.434250764525994", brLenTotal: "1.5540" },
  { geneTranscript: "NM_003400.4", parsimonySitesP: "0.103952692187986", constantSitesP: "0.830999066293184", distinctSitesP: "0.136009959539371", brLenTotal: "0.3370" },
  { geneTranscript: "NM_018683.4", parsimonySitesP: "0.140350877192982", constantSitesP: "0.783625730994152", distinctSitesP: "0.204678362573099", brLenTotal: "0.5091" },
  { geneTranscript: "NM_024709.5", parsimonySitesP: "0.326291079812207", constantSitesP: "0.511737089201878", distinctSitesP: "0.502347417840376", brLenTotal: "1.1751" },
  { geneTranscript: "NM_006492.3", parsimonySitesP: "0.185617103984451", constantSitesP: "0.671525753158406", distinctSitesP: "0.291545189504373", brLenTotal: "0.7065" },
  { geneTranscript: "NM_001172679.2", parsimonySitesP: "0.339066339066339", constantSitesP: "0.523341523341523", distinctSitesP: "0.44963144963145", brLenTotal: "1.5846" },
  { geneTranscript: "NM_003372.7", parsimonySitesP: "0.148900169204738", constantSitesP: "0.708967851099831", distinctSitesP: "0.28595600676819", brLenTotal: "0.5307" },
  { geneTranscript: "NM_005872.3", parsimonySitesP: "0.145185185185185", constantSitesP: "0.73037037037037", distinctSitesP: "0.257777777777778", brLenTotal: "0.5625" },
  { geneTranscript: "NM_005415.5", parsimonySitesP: "0.185567010309278", constantSitesP: "0.707903780068729", distinctSitesP: "0.247913598429062", brLenTotal: "0.6147" },
  { geneTranscript: "NM_001367834.3", parsimonySitesP: "0.221217600964436", constantSitesP: "0.66787221217601", distinctSitesP: "0.258589511754069", brLenTotal: "0.9573" },
  { geneTranscript: "NM_003239.5", parsimonySitesP: "0.144822006472492", constantSitesP: "0.756472491909385", distinctSitesP: "0.194174757281553", brLenTotal: "0.5268" },
  { geneTranscript: "NM_006425.5", parsimonySitesP: "0.141069397042093", constantSitesP: "0.745733788395904", distinctSitesP: "0.228100113765643", brLenTotal: "0.4783" },
  { geneTranscript: "NM_018401.3", parsimonySitesP: "0.239130434782609", constantSitesP: "0.615942028985507", distinctSitesP: "0.368760064412238", brLenTotal: "1.0133" },
  { geneTranscript: "NM_001130072.2", parsimonySitesP: "0.220486111111111", constantSitesP: "0.678819444444444", distinctSitesP: "0.27662037037037", brLenTotal: "1.1624" },
  { geneTranscript: "NM_030911.4", parsimonySitesP: "0.119974059662776", constantSitesP: "0.761997405966278", distinctSitesP: "0.212710765239948", brLenTotal: "0.4096" },
  { geneTranscript: "NM_032501.4", parsimonySitesP: "0.257861635220126", constantSitesP: "0.623125302370585", distinctSitesP: "0.376874697629415", brLenTotal: "1.1343" },
  { geneTranscript: "NM_001042603.3", parsimonySitesP: "0.164299802761341", constantSitesP: "0.738461538461538", distinctSitesP: "0.196844181459566", brLenTotal: "0.5784" },
  { geneTranscript: "NM_000911.4", parsimonySitesP: "0.165770609318996", constantSitesP: "0.681899641577061", distinctSitesP: "0.262544802867384", brLenTotal: "0.8504" },
  { geneTranscript: "NM_003656.5", parsimonySitesP: "0.163963963963964", constantSitesP: "0.734234234234234", distinctSitesP: "0.241441441441441", brLenTotal: "0.5809" },
  { geneTranscript: "NM_000268.4", parsimonySitesP: "0.136134453781513", constantSitesP: "0.784313725490196", distinctSitesP: "0.177591036414566", brLenTotal: "0.4986" },
  { geneTranscript: "NM_001320436.2", parsimonySitesP: "0.272727272727273", constantSitesP: "0.537878787878788", distinctSitesP: "0.431818181818182", brLenTotal: "0.9631" },
  { geneTranscript: "NM_001349338.3", parsimonySitesP: "0.102904972919744", constantSitesP: "0.818808468734613", distinctSitesP: "0.144756277695716", brLenTotal: "0.4030" },
  { geneTranscript: "NM_006282.5", parsimonySitesP: "0.117043121149897", constantSitesP: "0.797399041752225", distinctSitesP: "0.169062286105407", brLenTotal: "0.3936" },
  { geneTranscript: "NM_005003.3", parsimonySitesP: "0.264957264957265", constantSitesP: "0.525641025641026", distinctSitesP: "0.482905982905983", brLenTotal: "1.0545" },
  { geneTranscript: "NM_017563.5", parsimonySitesP: "0.223274695534506", constantSitesP: "0.644113667117727", distinctSitesP: "0.290031574199369", brLenTotal: "0.8466" },
  { geneTranscript: "NM_194279.4", parsimonySitesP: "0.203463203463203", constantSitesP: "0.632034632034632", distinctSitesP: "0.357142857142857", brLenTotal: "0.7025" },
  { geneTranscript: "NM_001161.5", parsimonySitesP: "0.188208616780045", constantSitesP: "0.705215419501134", distinctSitesP: "0.290249433106576", brLenTotal: "0.6360" },
  { geneTranscript: "NM_020162.4", parsimonySitesP: "0.171617161716172", constantSitesP: "0.704856199905705", distinctSitesP: "0.242809995285243", brLenTotal: "0.6107" },
  { geneTranscript: "NM_032315.3", parsimonySitesP: "0.133956386292835", constantSitesP: "0.771547248182762", distinctSitesP: "0.204569055036345", brLenTotal: "0.4644" },
  { geneTranscript: "NM_001368135.1", parsimonySitesP: "0.273563218390805", constantSitesP: "0.564367816091954", distinctSitesP: "0.410344827586207", brLenTotal: "0.9086" },
  { geneTranscript: "NM_173558.4", parsimonySitesP: "0.243256997455471", constantSitesP: "0.61882951653944", distinctSitesP: "0.353689567430025", brLenTotal: "1.0347" },
  { geneTranscript: "NM_152739.4", parsimonySitesP: "0.125", constantSitesP: "0.783088235294118", distinctSitesP: "0.21078431372549", brLenTotal: "0.4912" },
  { geneTranscript: "NM_018433.6", parsimonySitesP: "0.161998485995458", constantSitesP: "0.713853141559425", distinctSitesP: "0.230633358566742", brLenTotal: "0.5390" },
  { geneTranscript: "NM_177965.4", parsimonySitesP: "0.22061191626409", constantSitesP: "0.621578099838969", distinctSitesP: "0.35426731078905", brLenTotal: "0.7297" },
  { geneTranscript: "NM_004292.3", parsimonySitesP: "0.289910600255428", constantSitesP: "0.562792677735206", distinctSitesP: "0.363133248190719", brLenTotal: "1.1699" },
  { geneTranscript: "NM_194317.5", parsimonySitesP: "0.153996101364522", constantSitesP: "0.748538011695906", distinctSitesP: "0.247563352826511", brLenTotal: "0.4795" },
  { geneTranscript: "NM_003848.4", parsimonySitesP: "0.183641975308642", constantSitesP: "0.682098765432099", distinctSitesP: "0.285493827160494", brLenTotal: "0.6989" },
  { geneTranscript: "NM_024645.3", parsimonySitesP: "0.211062590975255", constantSitesP: "0.704512372634643", distinctSitesP: "0.317321688500728", brLenTotal: "0.8442" },
  { geneTranscript: "NM_000676.4", parsimonySitesP: "0.229919678714859", constantSitesP: "0.635542168674699", distinctSitesP: "0.327309236947791", brLenTotal: "0.8599" },
  { geneTranscript: "NM_001370100.5", parsimonySitesP: "0.15171650055371", constantSitesP: "0.781838316722038", distinctSitesP: "0.226467331118494", brLenTotal: "0.5747" },
  { geneTranscript: "NM_001146154.2", parsimonySitesP: "0.156695156695157", constantSitesP: "0.726495726495726", distinctSitesP: "0.246913580246914", brLenTotal: "0.5227" },
  { geneTranscript: "NM_152267.4", parsimonySitesP: "0.135416666666667", constantSitesP: "0.802083333333333", distinctSitesP: "0.199652777777778", brLenTotal: "0.4793" },
  { geneTranscript: "NM_022827.4", parsimonySitesP: "0.22734829592685", constantSitesP: "0.659185369908562", distinctSitesP: "0.292186201163757", brLenTotal: "0.9323" },
  { geneTranscript: "NM_001042479.2", parsimonySitesP: "0.370523415977961", constantSitesP: "0.409090909090909", distinctSitesP: "0.560606060606061", brLenTotal: "1.5888" },
  { geneTranscript: "NM_014851.4", parsimonySitesP: "0.174204355108878", constantSitesP: "0.733109994416527", distinctSitesP: "0.235064209938582", brLenTotal: "0.7768" },
  { geneTranscript: "NM_024803.3", parsimonySitesP: "0.372944693572496", constantSitesP: "0.501494768310912", distinctSitesP: "0.473841554559043", brLenTotal: "1.7625" },
  { geneTranscript: "NM_012429.5", parsimonySitesP: "0.181141439205955", constantSitesP: "0.704714640198511", distinctSitesP: "0.257237386269644", brLenTotal: "0.6321" },
  { geneTranscript: "NM_006765.4", parsimonySitesP: "0.117816091954023", constantSitesP: "0.78735632183908", distinctSitesP: "0.200191570881226", brLenTotal: "0.4121" },
  { geneTranscript: "NM_005259.3", parsimonySitesP: "0.107555555555556", constantSitesP: "0.816888888888889", distinctSitesP: "0.162666666666667", brLenTotal: "0.2887" },
  { geneTranscript: "NM_032793.5", parsimonySitesP: "0.19937106918239", constantSitesP: "0.694339622641509", distinctSitesP: "0.266037735849057", brLenTotal: "0.7208" },
  { geneTranscript: "NM_006421.5", parsimonySitesP: "0.10077519379845", constantSitesP: "0.799891833423472", distinctSitesP: "0.140977104741302", brLenTotal: "0.3491" },
  { geneTranscript: "NM_014343.3", parsimonySitesP: "0.242690058479532", constantSitesP: "0.64327485380117", distinctSitesP: "0.353801169590643", brLenTotal: "0.8236" },
  { geneTranscript: "NM_006441.4", parsimonySitesP: "0.233169129720854", constantSitesP: "0.64696223316913", distinctSitesP: "0.35632183908046", brLenTotal: "0.8201" },
  { geneTranscript: "NM_014456.5", parsimonySitesP: "0.108742004264392", constantSitesP: "0.791044776119403", distinctSitesP: "0.169154228855721", brLenTotal: "0.3480" },
  { geneTranscript: "NM_001318841.2", parsimonySitesP: "0.163704900376952", constantSitesP: "0.681744749596123", distinctSitesP: "0.214324178782983", brLenTotal: "1.7987" },
  { geneTranscript: "NM_016423.3", parsimonySitesP: "0.210526315789474", constantSitesP: "0.669898430286242", distinctSitesP: "0.268698060941828", brLenTotal: "0.7748" },
  { geneTranscript: "NM_014379.4", parsimonySitesP: "0.164666666666667", constantSitesP: "0.729333333333333", distinctSitesP: "0.214666666666667", brLenTotal: "0.5340" },
  { geneTranscript: "NM_000215.4", parsimonySitesP: "0.266014234875445", constantSitesP: "0.607651245551601", distinctSitesP: "0.325622775800712", brLenTotal: "1.1057" },
  { geneTranscript: "NM_006067.5", parsimonySitesP: "0.147619047619048", constantSitesP: "0.749206349206349", distinctSitesP: "0.236507936507937", brLenTotal: "0.5614" },
  { geneTranscript: "NM_007127.3", parsimonySitesP: "0.250302297460701", constantSitesP: "0.629987908101572", distinctSitesP: "0.312777106005643", brLenTotal: "0.9036" },
  { geneTranscript: "NM_053024.4", parsimonySitesP: "0.0428571428571429", constantSitesP: "0.878571428571429", distinctSitesP: "0.121428571428571", brLenTotal: "0.1651" },
  { geneTranscript: "NM_203486.3", parsimonySitesP: "0.277115275411698", constantSitesP: "0.566155593412834", distinctSitesP: "0.406587166382737", brLenTotal: "1.1016" },
  { geneTranscript: "NM_001085375.2", parsimonySitesP: "0.275735294117647", constantSitesP: "0.531862745098039", distinctSitesP: "0.417892156862745", brLenTotal: "1.0897" },
  { geneTranscript: "NM_006789.4", parsimonySitesP: "0.188988095238095", constantSitesP: "0.714285714285714", distinctSitesP: "0.273809523809524", brLenTotal: "0.6619" },
  { geneTranscript: "NM_014681.6", parsimonySitesP: "0.269757946923301", constantSitesP: "0.599008457276174", distinctSitesP: "0.340915718868475", brLenTotal: "1.2051" },
  { geneTranscript: "NM_012456.3", parsimonySitesP: "0.151851851851852", constantSitesP: "0.692592592592593", distinctSitesP: "0.307407407407407", brLenTotal: "0.6000" },
  { geneTranscript: "NM_001162495.3", parsimonySitesP: "0.321156773211568", constantSitesP: "0.520547945205479", distinctSitesP: "0.462709284627093", brLenTotal: "1.2742" },
  { geneTranscript: "NM_004714.3", parsimonySitesP: "0.129835718071012", constantSitesP: "0.79438261791203", distinctSitesP: "0.16852146263911", brLenTotal: "0.5354" },
  { geneTranscript: "NM_004498.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.802867383512545", distinctSitesP: "0.154838709677419", brLenTotal: "0.3344" },
  { geneTranscript: "NM_172160.3", parsimonySitesP: "0.155926809864757", constantSitesP: "0.746221161495625", distinctSitesP: "0.233094669848846", brLenTotal: "0.5903" },
  { geneTranscript: "NM_130388.4", parsimonySitesP: "0.209643605870021", constantSitesP: "0.658280922431866", distinctSitesP: "0.315513626834382", brLenTotal: "0.6859" },
  { geneTranscript: "NM_032898.5", parsimonySitesP: "0.16359918200409", constantSitesP: "0.707566462167689", distinctSitesP: "0.276073619631902", brLenTotal: "0.5049" },
  { geneTranscript: "NM_002280.6", parsimonySitesP: "0.265201465201465", constantSitesP: "0.556043956043956", distinctSitesP: "0.393406593406593", brLenTotal: "1.4016" },
  { geneTranscript: "NM_001286.5", parsimonySitesP: "0.184119677790564", constantSitesP: "0.731875719217491", distinctSitesP: "0.229382431914077", brLenTotal: "0.7051" },
  { geneTranscript: "NM_016504.3", parsimonySitesP: "0.238738738738739", constantSitesP: "0.540540540540541", distinctSitesP: "0.466216216216216", brLenTotal: "0.9180" },
  { geneTranscript: "NM_001396241.1", parsimonySitesP: "0.357291666666667", constantSitesP: "0.307291666666667", distinctSitesP: "0.644791666666667", brLenTotal: "1.9082" },
  { geneTranscript: "NM_005294.3", parsimonySitesP: "0.128939828080229", constantSitesP: "0.762177650429799", distinctSitesP: "0.204393505253104", brLenTotal: "0.3805" },
  { geneTranscript: "NM_001005498.4", parsimonySitesP: "0.226118500604595", constantSitesP: "0.667472793228537", distinctSitesP: "0.275292220878678", brLenTotal: "1.0764" },
  { geneTranscript: "NM_001130021.3", parsimonySitesP: "0.129430505774592", constantSitesP: "0.774591796097172", distinctSitesP: "0.169255276782159", brLenTotal: "0.4637" },
  { geneTranscript: "NM_001367721.1", parsimonySitesP: "0.0745140388768898", constantSitesP: "0.871130309575234", distinctSitesP: "0.105831533477322", brLenTotal: "0.2300" },
  { geneTranscript: "NM_024605.4", parsimonySitesP: "0.172603901611535", constantSitesP: "0.711195928753181", distinctSitesP: "0.266751484308736", brLenTotal: "0.6340" },
  { geneTranscript: "NM_016001.3", parsimonySitesP: "0.193645083932854", constantSitesP: "0.632494004796163", distinctSitesP: "0.32673860911271", brLenTotal: "0.7108" },
  { geneTranscript: "NM_001142633.3", parsimonySitesP: "0.210984848484848", constantSitesP: "0.666666666666667", distinctSitesP: "0.271212121212121", brLenTotal: "0.8433" },
  { geneTranscript: "NM_003151.4", parsimonySitesP: "0.139928698752228", constantSitesP: "0.761140819964349", distinctSitesP: "0.219251336898396", brLenTotal: "0.4730" },
  { geneTranscript: "NM_033033.4", parsimonySitesP: "0.312540610786225", constantSitesP: "0.545159194282001", distinctSitesP: "0.423651721897336", brLenTotal: "1.6420" },
  { geneTranscript: "NM_001219.5", parsimonySitesP: "0.105820105820106", constantSitesP: "0.828571428571429", distinctSitesP: "0.149206349206349", brLenTotal: "0.2687" },
  { geneTranscript: "NM_017902.3", parsimonySitesP: "0.141356255969436", constantSitesP: "0.764087870105062", distinctSitesP: "0.194842406876791", brLenTotal: "0.4614" },
  { geneTranscript: "NM_152230.5", parsimonySitesP: "0.161858974358974", constantSitesP: "0.667467948717949", distinctSitesP: "0.274038461538462", brLenTotal: "0.5620" },
  { geneTranscript: "NM_000358.3", parsimonySitesP: "0.219619326500732", constantSitesP: "0.684236212786725", distinctSitesP: "0.298194241093216", brLenTotal: "0.8644" },
  { geneTranscript: "NM_130434.5", parsimonySitesP: "0.119047619047619", constantSitesP: "0.785336356764928", distinctSitesP: "0.167044595616024", brLenTotal: "0.3902" },
  { geneTranscript: "NM_145291.4", parsimonySitesP: "0.295860566448802", constantSitesP: "0.561220043572985", distinctSitesP: "0.398692810457516", brLenTotal: "1.0838" },
  { geneTranscript: "NM_001370.2", parsimonySitesP: "0.187349687349687", constantSitesP: "0.688632355299022", distinctSitesP: "0.237534070867404", brLenTotal: "0.6556" },
  { geneTranscript: "NM_004626.3", parsimonySitesP: "0.132768361581921", constantSitesP: "0.793785310734463", distinctSitesP: "0.185499058380414", brLenTotal: "0.5752" },
  { geneTranscript: "NM_003128.3", parsimonySitesP: "0.162718556119571", constantSitesP: "0.751692047377327", distinctSitesP: "0.169627749576988", brLenTotal: "0.6701" },
  { geneTranscript: "NM_001324418.2", parsimonySitesP: "0.119986168741355", constantSitesP: "0.795297372060858", distinctSitesP: "0.178769017980636", brLenTotal: "0.4018" },
  { geneTranscript: "NM_001005415.2", parsimonySitesP: "0.258807588075881", constantSitesP: "0.658536585365854", distinctSitesP: "0.321138211382114", brLenTotal: "1.1579" },
  { geneTranscript: "NM_032231.7", parsimonySitesP: "0.135416666666667", constantSitesP: "0.725", distinctSitesP: "0.289583333333333", brLenTotal: "0.4736" },
  { geneTranscript: "NM_000196.4", parsimonySitesP: "0.235390946502058", constantSitesP: "0.647736625514403", distinctSitesP: "0.298765432098765", brLenTotal: "0.8982" },
  { geneTranscript: "NM_020927.3", parsimonySitesP: "0.155131264916468", constantSitesP: "0.749403341288783", distinctSitesP: "0.231503579952267", brLenTotal: "0.5866" },
  { geneTranscript: "NM_005994.4", parsimonySitesP: "0.186329588014981", constantSitesP: "0.715355805243446", distinctSitesP: "0.244850187265918", brLenTotal: "0.6823" },
  { geneTranscript: "NM_014267.6", parsimonySitesP: "0.0983606557377049", constantSitesP: "0.777777777777778", distinctSitesP: "0.216757741347905", brLenTotal: "0.3888" },
  { geneTranscript: "NM_018929.3", parsimonySitesP: "0.169138418079096", constantSitesP: "0.709745762711864", distinctSitesP: "0.210805084745763", brLenTotal: "0.5737" },
  { geneTranscript: "NM_006468.8", parsimonySitesP: "0.173533083645443", constantSitesP: "0.722222222222222", distinctSitesP: "0.229712858926342", brLenTotal: "0.5152" },
  { geneTranscript: "NM_003259.4", parsimonySitesP: "0.274170274170274", constantSitesP: "0.595238095238095", distinctSitesP: "0.323593073593074", brLenTotal: "1.0480" },
  { geneTranscript: "NM_201521.3", parsimonySitesP: "0.18147549811524", constantSitesP: "0.697899838449111", distinctSitesP: "0.224555735056543", brLenTotal: "0.6031" },
  { geneTranscript: "NM_001002254.1", parsimonySitesP: "0.285285285285285", constantSitesP: "0.583583583583584", distinctSitesP: "0.395395395395395", brLenTotal: "0.8901" },
  { geneTranscript: "NM_213653.4", parsimonySitesP: "0.21283255086072", constantSitesP: "0.648669796557121", distinctSitesP: "0.296557120500782", brLenTotal: "0.7864" },
  { geneTranscript: "NM_004251.5", parsimonySitesP: "0.225538971807629", constantSitesP: "0.595356550580431", distinctSitesP: "0.328358208955224", brLenTotal: "0.7212" },
  { geneTranscript: "NM_002404.3", parsimonySitesP: "0.184313725490196", constantSitesP: "0.724183006535948", distinctSitesP: "0.264052287581699", brLenTotal: "0.6525" },
  { geneTranscript: "NM_000080.4", parsimonySitesP: "0.2684246112238", constantSitesP: "0.601081812035159", distinctSitesP: "0.362407031778229", brLenTotal: "0.9773" },
  { geneTranscript: "NM_016093.4", parsimonySitesP: "0.236781609195402", constantSitesP: "0.639080459770115", distinctSitesP: "0.342528735632184", brLenTotal: "0.9689" },
  { geneTranscript: "NM_001146684.3", parsimonySitesP: "0.236363636363636", constantSitesP: "0.65", distinctSitesP: "0.328787878787879", brLenTotal: "1.1910" },
  { geneTranscript: "NM_138300.4", parsimonySitesP: "0.152709359605911", constantSitesP: "0.75944170771757", distinctSitesP: "0.21264367816092", brLenTotal: "0.4541" },
  { geneTranscript: "NM_052918.5", parsimonySitesP: "0.17722602739726", constantSitesP: "0.709474885844749", distinctSitesP: "0.237157534246575", brLenTotal: "0.6547" },
  { geneTranscript: "NM_015905.3", parsimonySitesP: "0.121904761904762", constantSitesP: "0.741587301587302", distinctSitesP: "0.18984126984127", brLenTotal: "0.4428" },
  { geneTranscript: "NM_012257.4", parsimonySitesP: "0.0888456549935149", constantSitesP: "0.830090791180285", distinctSitesP: "0.14591439688716", brLenTotal: "0.2850" },
  { geneTranscript: "NM_015859.4", parsimonySitesP: "0.102836879432624", constantSitesP: "0.814716312056738", distinctSitesP: "0.173758865248227", brLenTotal: "0.3641" },
  { geneTranscript: "NM_021965.4", parsimonySitesP: "0.154027042915932", constantSitesP: "0.725455614344503", distinctSitesP: "0.247501469723692", brLenTotal: "0.5635" },
  { geneTranscript: "NM_002386.4", parsimonySitesP: "0.347003154574132", constantSitesP: "0.488958990536278", distinctSitesP: "0.475289169295478", brLenTotal: "1.4158" },
  { geneTranscript: "NM_003463.5", parsimonySitesP: "0.113680154142582", constantSitesP: "0.778420038535645", distinctSitesP: "0.215799614643545", brLenTotal: "0.3975" },
  { geneTranscript: "NM_020526.5", parsimonySitesP: "0.183416252072968", constantSitesP: "0.689883913764511", distinctSitesP: "0.245771144278607", brLenTotal: "1.0487" },
  { geneTranscript: "NM_001377334.1", parsimonySitesP: "0.179110567115463", constantSitesP: "0.714810281517748", distinctSitesP: "0.212158302733578", brLenTotal: "0.6665" },
  { geneTranscript: "NM_007034.5", parsimonySitesP: "0.100890207715134", constantSitesP: "0.757665677546983", distinctSitesP: "0.183976261127596", brLenTotal: "0.4115" },
  { geneTranscript: "NM_001370809.1", parsimonySitesP: "0.188931297709924", constantSitesP: "0.681297709923664", distinctSitesP: "0.270992366412214", brLenTotal: "0.5820" },
  { geneTranscript: "NM_016080.4", parsimonySitesP: "0.194630872483221", constantSitesP: "0.6834451901566", distinctSitesP: "0.305369127516779", brLenTotal: "0.6543" },
  { geneTranscript: "NM_015871.5", parsimonySitesP: "0.246268656716418", constantSitesP: "0.597014925373134", distinctSitesP: "0.390547263681592", brLenTotal: "0.7604" },
  { geneTranscript: "NM_004444.5", parsimonySitesP: "0.219858156028369", constantSitesP: "0.681188787571766", distinctSitesP: "0.26241134751773", brLenTotal: "1.4052" },
  { geneTranscript: "NM_001130083.2", parsimonySitesP: "0.207751937984496", constantSitesP: "0.702842377260982", distinctSitesP: "0.273385012919897", brLenTotal: "0.9523" },
  { geneTranscript: "NM_000391.4", parsimonySitesP: "0.202486678507993", constantSitesP: "0.667258732978094", distinctSitesP: "0.263469508584962", brLenTotal: "0.6329" },
  { geneTranscript: "NM_017812.4", parsimonySitesP: "0.176211453744493", constantSitesP: "0.681350954478708", distinctSitesP: "0.325991189427313", brLenTotal: "0.7127" },
  { geneTranscript: "NM_006157.5", parsimonySitesP: "0.151028806584362", constantSitesP: "0.730452674897119", distinctSitesP: "0.21522633744856", brLenTotal: "0.5591" },
  { geneTranscript: "NM_003365.3", parsimonySitesP: "0.229861111111111", constantSitesP: "0.644444444444444", distinctSitesP: "0.315277777777778", brLenTotal: "0.8407" },
  { geneTranscript: "NM_175067.1", parsimonySitesP: "0.459903381642512", constantSitesP: "0.326570048309179", distinctSitesP: "0.63768115942029", brLenTotal: "2.4722" },
  { geneTranscript: "NM_001195087.2", parsimonySitesP: "0.38562091503268", constantSitesP: "0.453159041394336", distinctSitesP: "0.522875816993464", brLenTotal: "1.9629" },
  { geneTranscript: "NM_170662.5", parsimonySitesP: "0.136795655125594", constantSitesP: "0.769178547182621", distinctSitesP: "0.191446028513238", brLenTotal: "0.4693" },
  { geneTranscript: "NM_001080528.3", parsimonySitesP: "0.225490196078431", constantSitesP: "0.560457516339869", distinctSitesP: "0.380718954248366", brLenTotal: "0.7383" },
  { geneTranscript: "NM_014840.3", parsimonySitesP: "0.182551689359556", constantSitesP: "0.714573877962683", distinctSitesP: "0.24357034795764", brLenTotal: "0.6958" },
  { geneTranscript: "NM_144499.3", parsimonySitesP: "0.142857142857143", constantSitesP: "0.752380952380952", distinctSitesP: "0.208571428571429", brLenTotal: "0.4966" },
  { geneTranscript: "NM_014014.5", parsimonySitesP: "0.159332084893883", constantSitesP: "0.754993757802747", distinctSitesP: "0.177434456928839", brLenTotal: "0.6132" },
  { geneTranscript: "NM_006449.5", parsimonySitesP: "0.246719160104987", constantSitesP: "0.564304461942257", distinctSitesP: "0.375328083989501", brLenTotal: "1.0855" },
  { geneTranscript: "NM_000069.3", parsimonySitesP: "0.230823990033814", constantSitesP: "0.664531055347927", distinctSitesP: "0.278163374265884", brLenTotal: "0.9767" },
  { geneTranscript: "NM_001039618.4", parsimonySitesP: "0.180790960451977", constantSitesP: "0.705273069679849", distinctSitesP: "0.270244821092279", brLenTotal: "0.5831" },
  { geneTranscript: "NM_004346.4", parsimonySitesP: "0.220216606498195", constantSitesP: "0.607701564380265", distinctSitesP: "0.38748495788207", brLenTotal: "0.9290" },
  { geneTranscript: "NM_001365242.1", parsimonySitesP: "0.124716553287982", constantSitesP: "0.770975056689342", distinctSitesP: "0.189909297052154", brLenTotal: "0.4347" },
  { geneTranscript: "NM_182970.4", parsimonySitesP: "0.118959107806691", constantSitesP: "0.799256505576208", distinctSitesP: "0.179677819083024", brLenTotal: "0.4894" },
  { geneTranscript: "NM_016229.5", parsimonySitesP: "0.222222222222222", constantSitesP: "0.63768115942029", distinctSitesP: "0.359903381642512", brLenTotal: "0.7632" },
  { geneTranscript: "NM_030938.5", parsimonySitesP: "0.113300492610837", constantSitesP: "0.769293924466338", distinctSitesP: "0.197044334975369", brLenTotal: "0.3615" },
  { geneTranscript: "NM_007317.3", parsimonySitesP: "0.221052631578947", constantSitesP: "0.647117794486216", distinctSitesP: "0.314786967418546", brLenTotal: "0.7185" },
  { geneTranscript: "NM_018064.4", parsimonySitesP: "0.137931034482759", constantSitesP: "0.699507389162562", distinctSitesP: "0.270935960591133", brLenTotal: "0.5221" },
  { geneTranscript: "NM_016478.5", parsimonySitesP: "0.219123505976096", constantSitesP: "0.618193891102258", distinctSitesP: "0.322045152722444", brLenTotal: "0.7427" },
  { geneTranscript: "NM_004417.4", parsimonySitesP: "0.165304268846503", constantSitesP: "0.698455949137148", distinctSitesP: "0.243415077202543", brLenTotal: "0.6793" },
  { geneTranscript: "NM_181697.3", parsimonySitesP: "0.128978224455611", constantSitesP: "0.706867671691792", distinctSitesP: "0.268006700167504", brLenTotal: "0.4936" },
  { geneTranscript: "NM_001113567.3", parsimonySitesP: "0.169573643410853", constantSitesP: "0.714147286821705", distinctSitesP: "0.252906976744186", brLenTotal: "0.7675" },
  { geneTranscript: "NM_153320.2", parsimonySitesP: "0.244525547445255", constantSitesP: "0.626520681265207", distinctSitesP: "0.298053527980535", brLenTotal: "0.8702" },
  { geneTranscript: "NM_000841.4", parsimonySitesP: "0.20577485380117", constantSitesP: "0.699926900584795", distinctSitesP: "0.235745614035088", brLenTotal: "0.9273" },
  { geneTranscript: "NM_001136239.4", parsimonySitesP: "0.150140056022409", constantSitesP: "0.750700280112045", distinctSitesP: "0.226890756302521", brLenTotal: "0.5750" },
  { geneTranscript: "NM_152513.4", parsimonySitesP: "0.244897959183673", constantSitesP: "0.588697017268446", distinctSitesP: "0.336996336996337", brLenTotal: "0.8089" },
  { geneTranscript: "NM_001365925.2", parsimonySitesP: "0.162514827995255", constantSitesP: "0.721233689205219", distinctSitesP: "0.204824041122974", brLenTotal: "0.6080" },
  { geneTranscript: "NM_005647.4", parsimonySitesP: "0.228769497400347", constantSitesP: "0.694974003466205", distinctSitesP: "0.299826689774697", brLenTotal: "1.0897" },
  { geneTranscript: "NM_030957.4", parsimonySitesP: "0.178301601692354", constantSitesP: "0.728014505893019", distinctSitesP: "0.215170746449078", brLenTotal: "0.8419" },
  { geneTranscript: "NM_013385.5", parsimonySitesP: "0.199661590524535", constantSitesP: "0.687817258883249", distinctSitesP: "0.28087986463621", brLenTotal: "0.9495" },
  { geneTranscript: "NM_001198568.2", parsimonySitesP: "0.19900959455277", constantSitesP: "0.67966573816156", distinctSitesP: "0.242339832869081", brLenTotal: "0.7103" },
  { geneTranscript: "NM_033176.2", parsimonySitesP: "0.192090395480226", constantSitesP: "0.620527306967985", distinctSitesP: "0.354990583804143", brLenTotal: "1.1868" },
  { geneTranscript: "NM_001370125.1", parsimonySitesP: "0.303763440860215", constantSitesP: "0.56989247311828", distinctSitesP: "0.423387096774194", brLenTotal: "1.2351" },
  { geneTranscript: "NM_153613.3", parsimonySitesP: "0.156488549618321", constantSitesP: "0.720101781170483", distinctSitesP: "0.248091603053435", brLenTotal: "0.5579" },
  { geneTranscript: "NM_003378.4", parsimonySitesP: "0.186991869918699", constantSitesP: "0.655826558265583", distinctSitesP: "0.27479674796748", brLenTotal: "0.7366" },
  { geneTranscript: "NM_018654.2", parsimonySitesP: "0.233816425120773", constantSitesP: "0.617391304347826", distinctSitesP: "0.36231884057971", brLenTotal: "0.8942" },
  { geneTranscript: "NM_030908.2", parsimonySitesP: "0.461290322580645", constantSitesP: "0.349462365591398", distinctSitesP: "0.627956989247312", brLenTotal: "3.4530" },
  { geneTranscript: "NM_002663.5", parsimonySitesP: "0.19935691318328", constantSitesP: "0.685602000714541", distinctSitesP: "0.253662022150768", brLenTotal: "0.6756" },
  { geneTranscript: "NM_033036.3", parsimonySitesP: "0.196442382057231", constantSitesP: "0.689095127610209", distinctSitesP: "0.250580046403712", brLenTotal: "0.7168" },
  { geneTranscript: "NM_015151.4", parsimonySitesP: "0.204540632293656", constantSitesP: "0.691491618926374", distinctSitesP: "0.265648207086781", brLenTotal: "0.9132" },
  { geneTranscript: "NM_152695.6", parsimonySitesP: "0.151866151866152", constantSitesP: "0.70978120978121", distinctSitesP: "0.234234234234234", brLenTotal: "0.5076" },
  { geneTranscript: "NM_003145.4", parsimonySitesP: "0.178506375227687", constantSitesP: "0.699453551912568", distinctSitesP: "0.282331511839709", brLenTotal: "0.5753" },
  { geneTranscript: "NM_001280542.3", parsimonySitesP: "0.135802469135802", constantSitesP: "0.775132275132275", distinctSitesP: "0.194003527336861", brLenTotal: "0.6027" },
  { geneTranscript: "NM_030631.4", parsimonySitesP: "0.130434782608696", constantSitesP: "0.746934225195095", distinctSitesP: "0.245261984392419", brLenTotal: "0.4335" },
  { geneTranscript: "NM_013241.3", parsimonySitesP: "0.223367697594502", constantSitesP: "0.63659793814433", distinctSitesP: "0.288659793814433", brLenTotal: "0.7467" },
  { geneTranscript: "NM_004147.4", parsimonySitesP: "0.131698455949137", constantSitesP: "0.728428701180745", distinctSitesP: "0.240690281562216", brLenTotal: "0.4748" },
  { geneTranscript: "NM_014290.3", parsimonySitesP: "0.176381299332119", constantSitesP: "0.687006678809957", distinctSitesP: "0.248937462052216", brLenTotal: "0.5824" },
  { geneTranscript: "NM_001290474.2", parsimonySitesP: "0.160056657223796", constantSitesP: "0.730406043437205", distinctSitesP: "0.220018885741265", brLenTotal: "0.5652" },
  { geneTranscript: "NM_001367711.1", parsimonySitesP: "0.273301737756714", constantSitesP: "0.576619273301738", distinctSitesP: "0.371248025276461", brLenTotal: "1.1226" },
  { geneTranscript: "NM_014550.4", parsimonySitesP: "0.20671834625323", constantSitesP: "0.668604651162791", distinctSitesP: "0.27390180878553", brLenTotal: "0.9086" },
  { geneTranscript: "NM_003472.4", parsimonySitesP: "0.148444444444444", constantSitesP: "0.703111111111111", distinctSitesP: "0.266666666666667", brLenTotal: "0.6031" },
  { geneTranscript: "NM_005569.4", parsimonySitesP: "0.162486938349007", constantSitesP: "0.719958202716823", distinctSitesP: "0.222048066875653", brLenTotal: "0.5662" },
  { geneTranscript: "NM_004059.5", parsimonySitesP: "0.248025276461295", constantSitesP: "0.609794628751975", distinctSitesP: "0.359399684044234", brLenTotal: "1.0234" },
  { geneTranscript: "NM_024017.5", parsimonySitesP: "0.048", constantSitesP: "0.889333333333333", distinctSitesP: "0.104", brLenTotal: "0.1870" },
  { geneTranscript: "NM_024302.5", parsimonySitesP: "0.230128205128205", constantSitesP: "0.627564102564103", distinctSitesP: "0.326282051282051", brLenTotal: "0.9506" },
  { geneTranscript: "NM_001093725.2", parsimonySitesP: "0.125641025641026", constantSitesP: "0.762820512820513", distinctSitesP: "0.226923076923077", brLenTotal: "0.5794" },
  { geneTranscript: "NM_014847.4", parsimonySitesP: "0.0947562097516099", constantSitesP: "0.80558111008893", distinctSitesP: "0.134621281815394", brLenTotal: "0.3120" },
  { geneTranscript: "NM_000071.3", parsimonySitesP: "0.275862068965517", constantSitesP: "0.601330913490623", distinctSitesP: "0.38173018753781", brLenTotal: "1.3363" },
  { geneTranscript: "NM_174907.4", parsimonySitesP: "0.155075939248601", constantSitesP: "0.673061550759392", distinctSitesP: "0.290967226219025", brLenTotal: "0.5746" },
  { geneTranscript: "NM_001080421.3", parsimonySitesP: "0.196711685261304", constantSitesP: "0.718340183989039", distinctSitesP: "0.232922293990996", brLenTotal: "0.9264" },
  { geneTranscript: "NM_153018.3", parsimonySitesP: "0.164674634794157", constantSitesP: "0.719123505976096", distinctSitesP: "0.254316069057105", brLenTotal: "0.4910" },
  { geneTranscript: "NM_018462.5", parsimonySitesP: "0.142222222222222", constantSitesP: "0.786666666666667", distinctSitesP: "0.248888888888889", brLenTotal: "0.4246" },
  { geneTranscript: "NM_031418.4", parsimonySitesP: "0.150866462793068", constantSitesP: "0.759768943255182", distinctSitesP: "0.216785592932382", brLenTotal: "0.5844" },
  { geneTranscript: "NM_053045.2", parsimonySitesP: "0.188725490196078", constantSitesP: "0.561274509803922", distinctSitesP: "0.377450980392157", brLenTotal: "0.8931" },
  { geneTranscript: "NM_032843.5", parsimonySitesP: "0.224150397686189", constantSitesP: "0.666666666666667", distinctSitesP: "0.300072306579899", brLenTotal: "1.1648" },
  { geneTranscript: "NM_030674.4", parsimonySitesP: "0.145790554414784", constantSitesP: "0.767967145790554", distinctSitesP: "0.218343600273785", brLenTotal: "0.4683" },
  { geneTranscript: "NM_003721.4", parsimonySitesP: "0.226923076923077", constantSitesP: "0.655128205128205", distinctSitesP: "0.319230769230769", brLenTotal: "0.9827" },
  { geneTranscript: "NM_007183.4", parsimonySitesP: "0.23797574236721", constantSitesP: "0.647009619406106", distinctSitesP: "0.299874529485571", brLenTotal: "1.1578" },
  { geneTranscript: "NM_012073.5", parsimonySitesP: "0.191004313000616", constantSitesP: "0.675908810844116", distinctSitesP: "0.281577325939618", brLenTotal: "0.8234" },
  { geneTranscript: "NM_006710.5", parsimonySitesP: "0.133971291866029", constantSitesP: "0.757575757575758", distinctSitesP: "0.240829346092504", brLenTotal: "0.4924" },
  { geneTranscript: "NM_001098794.2", parsimonySitesP: "0.141632373113855", constantSitesP: "0.755486968449931", distinctSitesP: "0.177297668038409", brLenTotal: "0.4450" },
  { geneTranscript: "NM_014458.4", parsimonySitesP: "0.113847837985769", constantSitesP: "0.813355227148331", distinctSitesP: "0.160372194854953", brLenTotal: "0.3593" },
  { geneTranscript: "NM_194247.4", parsimonySitesP: "0.0873015873015873", constantSitesP: "0.740740740740741", distinctSitesP: "0.220458553791887", brLenTotal: "0.4177" },
  { geneTranscript: "NM_030913.6", parsimonySitesP: "0.235125448028674", constantSitesP: "0.625089605734767", distinctSitesP: "0.311827956989247", brLenTotal: "0.8298" },
  { geneTranscript: "NM_144650.3", parsimonySitesP: "0.188436830835118", constantSitesP: "0.693076374018558", distinctSitesP: "0.291934332619557", brLenTotal: "0.6764" },
  { geneTranscript: "NM_001122.4", parsimonySitesP: "0.278413424866514", constantSitesP: "0.538520213577422", distinctSitesP: "0.437070938215103", brLenTotal: "1.0930" },
  { geneTranscript: "NM_000636.4", parsimonySitesP: "0.217717717717718", constantSitesP: "0.662162162162162", distinctSitesP: "0.33033033033033", brLenTotal: "0.7699" },
  { geneTranscript: "NM_138691.3", parsimonySitesP: "0.156140350877193", constantSitesP: "0.737280701754386", distinctSitesP: "0.225438596491228", brLenTotal: "0.5598" },
  { geneTranscript: "NM_001347684.2", parsimonySitesP: "0.297101449275362", constantSitesP: "0.532608695652174", distinctSitesP: "0.422705314009662", brLenTotal: "1.4236" },
  { geneTranscript: "NM_199051.3", parsimonySitesP: "0.14621409921671", constantSitesP: "0.786771105308964", distinctSitesP: "0.18798955613577", brLenTotal: "0.5488" },
  { geneTranscript: "NM_182710.3", parsimonySitesP: "0.122100122100122", constantSitesP: "0.803418803418803", distinctSitesP: "0.162393162393162", brLenTotal: "0.4416" },
  { geneTranscript: "NM_004758.4", parsimonySitesP: "0.239633817985999", constantSitesP: "0.59899479447137", distinctSitesP: "0.309459702028361", brLenTotal: "0.9146" },
  { geneTranscript: "NM_030627.4", parsimonySitesP: "0.0791037951531779", constantSitesP: "0.850937357110197", distinctSitesP: "0.122542295381802", brLenTotal: "0.2857" },
  { geneTranscript: "NM_024119.3", parsimonySitesP: "0.298918387413963", constantSitesP: "0.549164208456244", distinctSitesP: "0.383480825958702", brLenTotal: "1.1554" },
  { geneTranscript: "NM_014713.5", parsimonySitesP: "0.130185979971388", constantSitesP: "0.782546494992847", distinctSitesP: "0.204577968526466", brLenTotal: "0.4096" },
  { geneTranscript: "NM_203314.3", parsimonySitesP: "0.24198250728863", constantSitesP: "0.6044703595724", distinctSitesP: "0.375121477162293", brLenTotal: "1.0007" },
  { geneTranscript: "NM_173079.5", parsimonySitesP: "0.200652528548124", constantSitesP: "0.653072321914084", distinctSitesP: "0.296356715606308", brLenTotal: "0.7305" },
  { geneTranscript: "NM_015352.2", parsimonySitesP: "0.188144329896907", constantSitesP: "0.686426116838488", distinctSitesP: "0.269759450171821", brLenTotal: "0.6450" },
  { geneTranscript: "NM_213622.4", parsimonySitesP: "0.147798742138365", constantSitesP: "0.709905660377358", distinctSitesP: "0.238993710691824", brLenTotal: "0.4807" },
  { geneTranscript: "NM_020428.4", parsimonySitesP: "0.198772426817753", constantSitesP: "0.705382436260623", distinctSitesP: "0.25590179414542", brLenTotal: "0.7953" },
  { geneTranscript: "NM_016200.5", parsimonySitesP: "0.142361111111111", constantSitesP: "0.784722222222222", distinctSitesP: "0.236111111111111", brLenTotal: "0.4752" },
  { geneTranscript: "NM_004853.3", parsimonySitesP: "0.128531073446328", constantSitesP: "0.772598870056497", distinctSitesP: "0.214689265536723", brLenTotal: "0.4432" },
  { geneTranscript: "NM_017542.5", parsimonySitesP: "0.219485495347564", constantSitesP: "0.648604269293924", distinctSitesP: "0.305966064586754", brLenTotal: "0.9391" },
  { geneTranscript: "NM_024121.3", parsimonySitesP: "0.192380952380952", constantSitesP: "0.648571428571429", distinctSitesP: "0.295238095238095", brLenTotal: "0.7620" },
  { geneTranscript: "NM_004699.4", parsimonySitesP: "0.16125860373648", constantSitesP: "0.764011799410029", distinctSitesP: "0.227138643067847", brLenTotal: "0.6326" },
  { geneTranscript: "NM_031918.4", parsimonySitesP: "0.277777777777778", constantSitesP: "0.574074074074074", distinctSitesP: "0.376984126984127", brLenTotal: "1.5041" },
  { geneTranscript: "NM_152268.4", parsimonySitesP: "0.242105263157895", constantSitesP: "0.612631578947368", distinctSitesP: "0.326315789473684", brLenTotal: "1.0145" },
  { geneTranscript: "NM_001080496.3", parsimonySitesP: "0.12617220801364", constantSitesP: "0.759590792838875", distinctSitesP: "0.202898550724638", brLenTotal: "0.4389" },
  { geneTranscript: "NM_000707.5", parsimonySitesP: "0.289308176100629", constantSitesP: "0.530660377358491", distinctSitesP: "0.407232704402516", brLenTotal: "1.1829" },
  { geneTranscript: "NM_001099733.2", parsimonySitesP: "0.210227272727273", constantSitesP: "0.621212121212121", distinctSitesP: "0.363636363636364", brLenTotal: "0.7763" },
  { geneTranscript: "NM_002712.3", parsimonySitesP: "0.181481481481481", constantSitesP: "0.703703703703704", distinctSitesP: "0.29537037037037", brLenTotal: "0.6758" },
  { geneTranscript: "NM_021191.3", parsimonySitesP: "0.193353474320242", constantSitesP: "0.677744209466264", distinctSitesP: "0.305135951661631", brLenTotal: "0.6093" },
  { geneTranscript: "NM_013401.4", parsimonySitesP: "0.218150087260035", constantSitesP: "0.650959860383944", distinctSitesP: "0.329842931937173", brLenTotal: "0.9429" },
  { geneTranscript: "NM_016334.5", parsimonySitesP: "0.136996336996337", constantSitesP: "0.766300366300366", distinctSitesP: "0.199267399267399", brLenTotal: "0.4506" },
  { geneTranscript: "NM_170693.3", parsimonySitesP: "0.188010899182561", constantSitesP: "0.695731153496821", distinctSitesP: "0.283378746594005", brLenTotal: "0.6929" },
  { geneTranscript: "NM_031419.4", parsimonySitesP: "0.236304549675023", constantSitesP: "0.624883936861653", distinctSitesP: "0.331940575673166", brLenTotal: "0.8166" },
  { geneTranscript: "NM_016073.4", parsimonySitesP: "0.0607553366174056", constantSitesP: "0.86863711001642", distinctSitesP: "0.12807881773399", brLenTotal: "0.1857" },
  { geneTranscript: "NM_000783.4", parsimonySitesP: "0.152246814218645", constantSitesP: "0.729040912139504", distinctSitesP: "0.213950368879946", brLenTotal: "0.4893" },
  { geneTranscript: "NM_001330700.2", parsimonySitesP: "0.1230012300123", constantSitesP: "0.784952849528495", distinctSitesP: "0.15149651496515", brLenTotal: "0.4451" },
  { geneTranscript: "NM_001002836.4", parsimonySitesP: "0.193717277486911", constantSitesP: "0.697207678883072", distinctSitesP: "0.292321116928447", brLenTotal: "1.0291" },
  { geneTranscript: "NM_001317857.2", parsimonySitesP: "0.156819839533187", constantSitesP: "0.719183078045222", distinctSitesP: "0.229029905178702", brLenTotal: "0.6407" },
  { geneTranscript: "NM_001164688.2", parsimonySitesP: "0.222222222222222", constantSitesP: "0.617094017094017", distinctSitesP: "0.381196581196581", brLenTotal: "0.9241" },
  { geneTranscript: "NM_031309.6", parsimonySitesP: "0.205938697318008", constantSitesP: "0.683908045977011", distinctSitesP: "0.290229885057471", brLenTotal: "0.7411" },
  { geneTranscript: "NM_030647.2", parsimonySitesP: "0.12150194828197", constantSitesP: "0.769040028338647", distinctSitesP: "0.191994332270634", brLenTotal: "0.3936" },
  { geneTranscript: "NM_001128225.3", parsimonySitesP: "0.198562443845463", constantSitesP: "0.672955974842767", distinctSitesP: "0.274932614555256", brLenTotal: "0.8836" },
  { geneTranscript: "NM_014979.4", parsimonySitesP: "0.154516276937185", constantSitesP: "0.749656121045392", distinctSitesP: "0.218248509857863", brLenTotal: "0.6576" },
  { geneTranscript: "NM_006651.4", parsimonySitesP: "0.248756218905473", constantSitesP: "0.686567164179104", distinctSitesP: "0.298507462686567", brLenTotal: "1.0894" },
  { geneTranscript: "NM_006197.4", parsimonySitesP: "0.167325428194993", constantSitesP: "0.704380764163373", distinctSitesP: "0.2383069828722", brLenTotal: "0.5402" },
  { geneTranscript: "NM_020362.5", parsimonySitesP: "0.110584518167457", constantSitesP: "0.799368088467615", distinctSitesP: "0.187993680884676", brLenTotal: "0.3246" },
  { geneTranscript: "NM_006897.3", parsimonySitesP: "0.0576923076923077", constantSitesP: "0.884615384615385", distinctSitesP: "0.106410256410256", brLenTotal: "0.1824" },
  { geneTranscript: "NM_001145809.2", parsimonySitesP: "0.223641126391618", constantSitesP: "0.66633922724296", distinctSitesP: "0.253438113948919", brLenTotal: "1.0244" },
  { geneTranscript: "NM_000968.4", parsimonySitesP: "0.19672131147541", constantSitesP: "0.625292740046838", distinctSitesP: "0.366120218579235", brLenTotal: "0.8341" },
  { geneTranscript: "NM_006454.3", parsimonySitesP: "0.161084529505582", constantSitesP: "0.752791068580542", distinctSitesP: "0.239234449760766", brLenTotal: "0.6631" },
  { geneTranscript: "NM_002808.5", parsimonySitesP: "0.149045521292217", constantSitesP: "0.747430249632893", distinctSitesP: "0.189794419970631", brLenTotal: "0.5058" },
  { geneTranscript: "NM_018969.6", parsimonySitesP: "0.117962466487936", constantSitesP: "0.789991063449508", distinctSitesP: "0.164432529043789", brLenTotal: "0.4962" },
  { geneTranscript: "NM_006548.6", parsimonySitesP: "0.130217028380634", constantSitesP: "0.766833611574847", distinctSitesP: "0.194212576516416", brLenTotal: "0.4543" },
  { geneTranscript: "NM_002885.4", parsimonySitesP: "0.136249371543489", constantSitesP: "0.785822021116139", distinctSitesP: "0.185017596782303", brLenTotal: "0.6236" },
  { geneTranscript: "NM_003186.5", parsimonySitesP: "0.150912106135987", constantSitesP: "0.75787728026534", distinctSitesP: "0.228855721393035", brLenTotal: "0.6216" },
  { geneTranscript: "NM_020865.3", parsimonySitesP: "0.159722222222222", constantSitesP: "0.732142857142857", distinctSitesP: "0.25462962962963", brLenTotal: "0.5260" },
  { geneTranscript: "NM_153689.6", parsimonySitesP: "0.166233766233766", constantSitesP: "0.686580086580087", distinctSitesP: "0.252813852813853", brLenTotal: "0.5413" },
  { geneTranscript: "NM_002361.4", parsimonySitesP: "0.175186368477103", constantSitesP: "0.733226837060703", distinctSitesP: "0.230564430244941", brLenTotal: "0.8904" },
  { geneTranscript: "NM_003635.4", parsimonySitesP: "0.132880332200831", constantSitesP: "0.765194412986032", distinctSitesP: "0.170630426576066", brLenTotal: "0.4505" },
  { geneTranscript: "NM_001854.4", parsimonySitesP: "0.154854189737911", constantSitesP: "0.747139165743817", distinctSitesP: "0.2046880767811", brLenTotal: "0.5272" },
  { geneTranscript: "NM_025244.4", parsimonySitesP: "0.142788920725883", constantSitesP: "0.726361031518625", distinctSitesP: "0.221585482330468", brLenTotal: "0.4424" },
  { geneTranscript: "NM_138455.4", parsimonySitesP: "0.155006858710562", constantSitesP: "0.743484224965706", distinctSitesP: "0.241426611796982", brLenTotal: "0.5192" },
  { geneTranscript: "NM_001014443.3", parsimonySitesP: "0.125073746312684", constantSitesP: "0.772861356932153", distinctSitesP: "0.175221238938053", brLenTotal: "0.3909" },
  { geneTranscript: "NM_001012338.3", parsimonySitesP: "0.140246324990068", constantSitesP: "0.7632101708383", distinctSitesP: "0.197457290425109", brLenTotal: "0.5848" },
  { geneTranscript: "NM_145858.3", parsimonySitesP: "0.145176695319962", constantSitesP: "0.740210124164279", distinctSitesP: "0.249283667621777", brLenTotal: "0.4760" },
  { geneTranscript: "NM_015482.2", parsimonySitesP: "0.162779397473275", constantSitesP: "0.73712342079689", distinctSitesP: "0.244897959183673", brLenTotal: "0.6862" },
  { geneTranscript: "NM_007067.5", parsimonySitesP: "0.110747408619749", constantSitesP: "0.805237315875614", distinctSitesP: "0.169667212220404", brLenTotal: "0.3895" },
  { geneTranscript: "NM_004066.3", parsimonySitesP: "0.25968992248062", constantSitesP: "0.591085271317829", distinctSitesP: "0.37015503875969", brLenTotal: "1.0217" },
  { geneTranscript: "NM_001146686.3", parsimonySitesP: "0.208582834331337", constantSitesP: "0.635728542914172", distinctSitesP: "0.335329341317365", brLenTotal: "0.6804" },
  { geneTranscript: "NM_031291.4", parsimonySitesP: "0.166137566137566", constantSitesP: "0.706878306878307", distinctSitesP: "0.262433862433862", brLenTotal: "0.5664" },
  { geneTranscript: "NM_014326.5", parsimonySitesP: "0.144144144144144", constantSitesP: "0.753153153153153", distinctSitesP: "0.220720720720721", brLenTotal: "0.5505" },
  { geneTranscript: "NM_001369598.1", parsimonySitesP: "0.109185441941075", constantSitesP: "0.818601964182553", distinctSitesP: "0.155979202772964", brLenTotal: "0.3437" },
  { geneTranscript: "NM_015441.3", parsimonySitesP: "0.269777777777778", constantSitesP: "0.584", distinctSitesP: "0.356444444444444", brLenTotal: "1.1265" },
  { geneTranscript: "NM_004839.4", parsimonySitesP: "0.199222546161322", constantSitesP: "0.682215743440233", distinctSitesP: "0.289601554907677", brLenTotal: "0.8982" },
  { geneTranscript: "NM_032229.3", parsimonySitesP: "0.210463733650416", constantSitesP: "0.669044787950852", distinctSitesP: "0.276654776060246", brLenTotal: "0.6599" },
  { geneTranscript: "NM_001446.5", parsimonySitesP: "0.123737373737374", constantSitesP: "0.732323232323232", distinctSitesP: "0.255050505050505", brLenTotal: "0.5354" },
  { geneTranscript: "NM_001130924.3", parsimonySitesP: "0.242742742742743", constantSitesP: "0.632632632632633", distinctSitesP: "0.32032032032032", brLenTotal: "1.0685" },
  { geneTranscript: "NM_002291.3", parsimonySitesP: "0.177678238148563", constantSitesP: "0.712392683837253", distinctSitesP: "0.216312056737589", brLenTotal: "0.6507" },
  { geneTranscript: "NM_015162.5", parsimonySitesP: "0.227900552486188", constantSitesP: "0.649171270718232", distinctSitesP: "0.323204419889503", brLenTotal: "1.0012" },
  { geneTranscript: "NM_000266.4", parsimonySitesP: "0.112781954887218", constantSitesP: "0.771929824561404", distinctSitesP: "0.220551378446115", brLenTotal: "0.4350" },
  { geneTranscript: "NM_019843.4", parsimonySitesP: "0.164805414551607", constantSitesP: "0.671404399323181", distinctSitesP: "0.249746192893401", brLenTotal: "0.6177" },
  { geneTranscript: "NM_018095.6", parsimonySitesP: "0.116729088639201", constantSitesP: "0.788389513108614", distinctSitesP: "0.16916354556804", brLenTotal: "0.3727" },
  { geneTranscript: "NM_013386.5", parsimonySitesP: "0.160726764500349", constantSitesP: "0.693920335429769", distinctSitesP: "0.264849755415793", brLenTotal: "0.6214" },
  { geneTranscript: "NM_178000.3", parsimonySitesP: "0.134158926728586", constantSitesP: "0.758513931888545", distinctSitesP: "0.212590299277606", brLenTotal: "0.5203" },
  { geneTranscript: "NM_001164749.2", parsimonySitesP: "0.11718470882458", constantSitesP: "0.794212218649518", distinctSitesP: "0.179707038227939", brLenTotal: "0.4810" },
  { geneTranscript: "NM_015990.5", parsimonySitesP: "0.155618241654913", constantSitesP: "0.721203573107663", distinctSitesP: "0.209214856605548", brLenTotal: "0.8165" },
  { geneTranscript: "NM_021190.4", parsimonySitesP: "0.0740740740740741", constantSitesP: "0.848713119899561", distinctSitesP: "0.12241054613936", brLenTotal: "0.2733" },
  { geneTranscript: "NM_003358.3", parsimonySitesP: "0.11336717428088", constantSitesP: "0.809644670050761", distinctSitesP: "0.16497461928934", brLenTotal: "0.3756" },
  { geneTranscript: "NM_017697.4", parsimonySitesP: "0.111600587371512", constantSitesP: "0.787567302985805", distinctSitesP: "0.17131669114048", brLenTotal: "0.3829" },
  { geneTranscript: "NM_207122.2", parsimonySitesP: "0.162952646239554", constantSitesP: "0.730733519034355", distinctSitesP: "0.224233983286908", brLenTotal: "0.7453" },
  { geneTranscript: "NM_172362.3", parsimonySitesP: "0.145601617795753", constantSitesP: "0.767441860465116", distinctSitesP: "0.184698348500169", brLenTotal: "0.6002" },
  { geneTranscript: "NM_005891.3", parsimonySitesP: "0.213266162888329", constantSitesP: "0.63392107472712", distinctSitesP: "0.370277078085642", brLenTotal: "0.8044" },
  { geneTranscript: "NM_024328.6", parsimonySitesP: "0.246376811594203", constantSitesP: "0.555072463768116", distinctSitesP: "0.407246376811594", brLenTotal: "0.7813" },
  { geneTranscript: "NM_032804.6", parsimonySitesP: "0.175308641975309", constantSitesP: "0.675308641975309", distinctSitesP: "0.274074074074074", brLenTotal: "0.7408" },
  { geneTranscript: "NM_019556.3", parsimonySitesP: "0.0954616588419405", constantSitesP: "0.758998435054773", distinctSitesP: "0.197183098591549", brLenTotal: "0.3412" },
  { geneTranscript: "NM_001013698.2", parsimonySitesP: "0.195555555555556", constantSitesP: "0.672592592592593", distinctSitesP: "0.32", brLenTotal: "0.6578" },
  { geneTranscript: "NM_002379.3", parsimonySitesP: "0.202956989247312", constantSitesP: "0.684811827956989", distinctSitesP: "0.281586021505376", brLenTotal: "0.8795" },
  { geneTranscript: "NM_018387.5", parsimonySitesP: "0.0833333333333333", constantSitesP: "0.837301587301587", distinctSitesP: "0.130456349206349", brLenTotal: "0.2665" },
  { geneTranscript: "NM_001128212.3", parsimonySitesP: "0.199579831932773", constantSitesP: "0.665266106442577", distinctSitesP: "0.327731092436975", brLenTotal: "0.6388" },
  { geneTranscript: "NM_017615.3", parsimonySitesP: "0.187878787878788", constantSitesP: "0.705627705627706", distinctSitesP: "0.306493506493506", brLenTotal: "0.5843" },
  { geneTranscript: "NM_006255.5", parsimonySitesP: "0.172767203513909", constantSitesP: "0.738408979990239", distinctSitesP: "0.222059541239629", brLenTotal: "0.7055" },
  { geneTranscript: "NM_014222.3", parsimonySitesP: "0.191860465116279", constantSitesP: "0.674418604651163", distinctSitesP: "0.339147286821705", brLenTotal: "0.6658" },
  { geneTranscript: "NM_004424.5", parsimonySitesP: "0.235544217687075", constantSitesP: "0.644982993197279", distinctSitesP: "0.289540816326531", brLenTotal: "1.0721" },
  { geneTranscript: "NM_000725.4", parsimonySitesP: "0.12396694214876", constantSitesP: "0.787190082644628", distinctSitesP: "0.178374655647383", brLenTotal: "0.4213" },
  { geneTranscript: "NM_005458.8", parsimonySitesP: "0.128586609989373", constantSitesP: "0.806234502302515", distinctSitesP: "0.16259298618491", brLenTotal: "0.4866" },
  { geneTranscript: "NM_015167.3", parsimonySitesP: "0.161290322580645", constantSitesP: "0.757650951199338", distinctSitesP: "0.216708023159636", brLenTotal: "0.5449" },
  { geneTranscript: "NM_173642.4", parsimonySitesP: "0.152600170502984", constantSitesP: "0.750213128729753", distinctSitesP: "0.206308610400682", brLenTotal: "0.5186" },
  { geneTranscript: "NM_000368.5", parsimonySitesP: "0.178980526918671", constantSitesP: "0.703035509736541", distinctSitesP: "0.22709049255441", brLenTotal: "0.5994" },
  { geneTranscript: "NM_001794.5", parsimonySitesP: "0.231804949053857", constantSitesP: "0.673944687045124", distinctSitesP: "0.291484716157205", brLenTotal: "1.2275" },
  { geneTranscript: "NM_025187.5", parsimonySitesP: "0.105845181674566", constantSitesP: "0.813586097946288", distinctSitesP: "0.172985781990521", brLenTotal: "0.3304" },
  { geneTranscript: "NM_001324242.2", parsimonySitesP: "0.123569794050343", constantSitesP: "0.775743707093822", distinctSitesP: "0.216628527841342", brLenTotal: "0.3583" },
  { geneTranscript: "NM_001366282.2", parsimonySitesP: "0.211294934640523", constantSitesP: "0.613255718954248", distinctSitesP: "0.280330882352941", brLenTotal: "0.7010" },
  { geneTranscript: "NM_014746.6", parsimonySitesP: "0.171232876712329", constantSitesP: "0.75", distinctSitesP: "0.240867579908676", brLenTotal: "0.7349" },
  { geneTranscript: "NM_013943.3", parsimonySitesP: "0.106719367588933", constantSitesP: "0.768115942028986", distinctSitesP: "0.208168642951252", brLenTotal: "0.4117" },
  { geneTranscript: "NM_145729.3", parsimonySitesP: "0.225308641975309", constantSitesP: "0.62037037037037", distinctSitesP: "0.367283950617284", brLenTotal: "0.7921" },
  { geneTranscript: "NM_031894.3", parsimonySitesP: "0.482695810564663", constantSitesP: "0.316939890710383", distinctSitesP: "0.657559198542805", brLenTotal: "2.0527" },
  { geneTranscript: "NM_001031712.3", parsimonySitesP: "0.154067674586033", constantSitesP: "0.732901367890569", distinctSitesP: "0.251259899208063", brLenTotal: "0.5141" },
  { geneTranscript: "NM_001300939.2", parsimonySitesP: "0.255645889792231", constantSitesP: "0.613369467028004", distinctSitesP: "0.365853658536585", brLenTotal: "0.9710" },
  { geneTranscript: "NM_002204.4", parsimonySitesP: "0.195686647637171", constantSitesP: "0.685379004123057", distinctSitesP: "0.249920710434507", brLenTotal: "0.7333" },
  { geneTranscript: "NM_001004482.1", parsimonySitesP: "0.509433962264151", constantSitesP: "0.378406708595388", distinctSitesP: "0.607966457023061", brLenTotal: "4.4944" },
  { geneTranscript: "NM_020825.4", parsimonySitesP: "0.254005778828474", constantSitesP: "0.60782768584187", distinctSitesP: "0.322301024428684", brLenTotal: "1.1275" },
  { geneTranscript: "NM_001282431.2", parsimonySitesP: "0.0845771144278607", constantSitesP: "0.839137645107794", distinctSitesP: "0.159203980099502", brLenTotal: "0.2700" },
  { geneTranscript: "NM_004998.4", parsimonySitesP: "0.167569193742479", constantSitesP: "0.729542719614922", distinctSitesP: "0.214801444043321", brLenTotal: "0.6320" },
  { geneTranscript: "NM_001032283.3", parsimonySitesP: "0.116740088105727", constantSitesP: "0.759911894273128", distinctSitesP: "0.206314243759178", brLenTotal: "0.4137" },
  { geneTranscript: "NM_016930.4", parsimonySitesP: "0.155223880597015", constantSitesP: "0.732338308457711", distinctSitesP: "0.246766169154229", brLenTotal: "0.5494" },
  { geneTranscript: "NM_000489.6", parsimonySitesP: "0.13175494917068", constantSitesP: "0.776618512573569", distinctSitesP: "0.174157303370787", brLenTotal: "0.3907" },
  { geneTranscript: "NM_031934.6", parsimonySitesP: "0.144144144144144", constantSitesP: "0.758043758043758", distinctSitesP: "0.225225225225225", brLenTotal: "0.4636" },
  { geneTranscript: "NM_031209.3", parsimonySitesP: "0.229942100909843", constantSitesP: "0.668320926385443", distinctSitesP: "0.283705541770058", brLenTotal: "0.9474" },
  { geneTranscript: "NM_005929.6", parsimonySitesP: "0.232610659439928", constantSitesP: "0.627822944896116", distinctSitesP: "0.331978319783198", brLenTotal: "1.0112" },
  { geneTranscript: "NM_006705.4", parsimonySitesP: "0.230607966457023", constantSitesP: "0.647798742138365", distinctSitesP: "0.333333333333333", brLenTotal: "1.1942" },
  { geneTranscript: "NM_057093.2", parsimonySitesP: "0.208121827411168", constantSitesP: "0.666666666666667", distinctSitesP: "0.326565143824027", brLenTotal: "0.6504" },
  { geneTranscript: "NM_007271.4", parsimonySitesP: "0.0853046594982079", constantSitesP: "0.822939068100358", distinctSitesP: "0.144802867383513", brLenTotal: "0.2974" },
  { geneTranscript: "NM_178171.5", parsimonySitesP: "0.217977528089888", constantSitesP: "0.645692883895131", distinctSitesP: "0.340823970037453", brLenTotal: "0.7712" },
  { geneTranscript: "NM_020737.3", parsimonySitesP: "0.182931981411069", constantSitesP: "0.699197296155471", distinctSitesP: "0.252640473172793", brLenTotal: "0.8160" },
  { geneTranscript: "NM_004905.3", parsimonySitesP: "0.21875", constantSitesP: "0.642857142857143", distinctSitesP: "0.330357142857143", brLenTotal: "0.6768" },
  { geneTranscript: "NM_001136537.3", parsimonySitesP: "0.230240549828179", constantSitesP: "0.630011454753723", distinctSitesP: "0.339060710194731", brLenTotal: "0.8112" },
  { geneTranscript: "NM_001320214.2", parsimonySitesP: "0.0919117647058824", constantSitesP: "0.848039215686275", distinctSitesP: "0.142156862745098", brLenTotal: "0.2798" },
  { geneTranscript: "NM_032436.4", parsimonySitesP: "0.192528735632184", constantSitesP: "0.664614121510673", distinctSitesP: "0.261494252873563", brLenTotal: "0.6524" },
  { geneTranscript: "NM_174858.3", parsimonySitesP: "0.182680901542112", constantSitesP: "0.70344009489917", distinctSitesP: "0.265124555160142", brLenTotal: "0.6849" },
  { geneTranscript: "NM_002196.3", parsimonySitesP: "0.19281045751634", constantSitesP: "0.672549019607843", distinctSitesP: "0.34640522875817", brLenTotal: "0.8002" },
  { geneTranscript: "NM_001033677.2", parsimonySitesP: "0.145045045045045", constantSitesP: "0.746846846846847", distinctSitesP: "0.256756756756757", brLenTotal: "0.4885" },
  { geneTranscript: "NM_016824.5", parsimonySitesP: "0.158640226628895", constantSitesP: "0.746458923512748", distinctSitesP: "0.212464589235127", brLenTotal: "0.5130" },
  { geneTranscript: "NM_138809.4", parsimonySitesP: "0.247619047619048", constantSitesP: "0.620408163265306", distinctSitesP: "0.374149659863946", brLenTotal: "1.0127" },
  { geneTranscript: "NM_003783.3", parsimonySitesP: "0.141390205371248", constantSitesP: "0.756714060031596", distinctSitesP: "0.210110584518167", brLenTotal: "0.4487" },
  { geneTranscript: "NM_018179.5", parsimonySitesP: "0.204199475065617", constantSitesP: "0.668766404199475", distinctSitesP: "0.293438320209974", brLenTotal: "0.7079" },
  { geneTranscript: "NM_007223.3", parsimonySitesP: "0.176699029126214", constantSitesP: "0.690614886731392", distinctSitesP: "0.266666666666667", brLenTotal: "0.6387" },
  { geneTranscript: "NM_021813.4", parsimonySitesP: "0.187078874355925", constantSitesP: "0.707491082045184", distinctSitesP: "0.24534284581847", brLenTotal: "0.7407" },
  { geneTranscript: "NM_015294.6", parsimonySitesP: "0.144536652835408", constantSitesP: "0.741701244813278", distinctSitesP: "0.208852005532503", brLenTotal: "0.4478" },
  { geneTranscript: "NM_182977.3", parsimonySitesP: "0.157458563535912", constantSitesP: "0.752302025782689", distinctSitesP: "0.206261510128913", brLenTotal: "0.5321" },
  { geneTranscript: "NM_021195.5", parsimonySitesP: "0.401515151515152", constantSitesP: "0.443939393939394", distinctSitesP: "0.513636363636364", brLenTotal: "2.4258" },
  { geneTranscript: "NM_019886.4", parsimonySitesP: "0.239368998628258", constantSitesP: "0.643347050754458", distinctSitesP: "0.292866941015089", brLenTotal: "0.9197" },
  { geneTranscript: "NM_030578.4", parsimonySitesP: "0.207619047619048", constantSitesP: "0.681904761904762", distinctSitesP: "0.297142857142857", brLenTotal: "0.7708" },
  { geneTranscript: "NM_019609.5", parsimonySitesP: "0.227974568574024", constantSitesP: "0.64850136239782", distinctSitesP: "0.323342415985468", brLenTotal: "0.8564" },
  { geneTranscript: "NM_013362.4", parsimonySitesP: "0.464117091595845", constantSitesP: "0.347969782813975", distinctSitesP: "0.639282341831917", brLenTotal: "2.5489" },
  { geneTranscript: "NM_207009.4", parsimonySitesP: "0.156862745098039", constantSitesP: "0.725490196078431", distinctSitesP: "0.242763772175537", brLenTotal: "0.5355" },
  { geneTranscript: "NM_021149.5", parsimonySitesP: "0.173708920187793", constantSitesP: "0.720657276995305", distinctSitesP: "0.267605633802817", brLenTotal: "0.7145" },
  { geneTranscript: "NM_000666.3", parsimonySitesP: "0.228758169934641", constantSitesP: "0.636437908496732", distinctSitesP: "0.333333333333333", brLenTotal: "0.8092" },
  { geneTranscript: "NM_003741.4", parsimonySitesP: "0.217102966841187", constantSitesP: "0.63804537521815", distinctSitesP: "0.28760907504363", brLenTotal: "0.8046" },
  { geneTranscript: "NM_004383.3", parsimonySitesP: "0.172592592592593", constantSitesP: "0.753333333333333", distinctSitesP: "0.212592592592593", brLenTotal: "0.7249" },
  { geneTranscript: "NM_007176.4", parsimonySitesP: "0.126190476190476", constantSitesP: "0.788095238095238", distinctSitesP: "0.214285714285714", brLenTotal: "0.4127" },
  { geneTranscript: "NM_014188.3", parsimonySitesP: "0.192439862542955", constantSitesP: "0.640893470790378", distinctSitesP: "0.304123711340206", brLenTotal: "0.9283" },
  { geneTranscript: "NM_005629.4", parsimonySitesP: "0.156955380577428", constantSitesP: "0.708661417322835", distinctSitesP: "0.230446194225722", brLenTotal: "0.6799" },
  { geneTranscript: "NM_203370.2", parsimonySitesP: "0.252631578947368", constantSitesP: "0.589473684210526", distinctSitesP: "0.354385964912281", brLenTotal: "0.8715" },
  { geneTranscript: "NM_001323627.2", parsimonySitesP: "0.256569847856155", constantSitesP: "0.598893499308437", distinctSitesP: "0.353388658367911", brLenTotal: "0.9641" },
  { geneTranscript: "NM_003034.4", parsimonySitesP: "0.155430711610487", constantSitesP: "0.741573033707865", distinctSitesP: "0.219101123595506", brLenTotal: "0.4999" },
  { geneTranscript: "NM_006634.3", parsimonySitesP: "0.275862068965517", constantSitesP: "0.563218390804598", distinctSitesP: "0.442528735632184", brLenTotal: "0.7886" },
  { geneTranscript: "NM_000682.7", parsimonySitesP: "0.237037037037037", constantSitesP: "0.637037037037037", distinctSitesP: "0.326666666666667", brLenTotal: "0.8375" },
  { geneTranscript: "NM_002271.6", parsimonySitesP: "0.137040413248253", constantSitesP: "0.769978729869341", distinctSitesP: "0.178365238529322", brLenTotal: "0.4519" },
  { geneTranscript: "NM_001303256.3", parsimonySitesP: "0.173772609819121", constantSitesP: "0.718669250645995", distinctSitesP: "0.222868217054264", brLenTotal: "0.5920" },
  { geneTranscript: "NM_194293.4", parsimonySitesP: "0.323566648580213", constantSitesP: "0.477482365708085", distinctSitesP: "0.381624163501537", brLenTotal: "1.2319" },
  { geneTranscript: "NM_053017.5", parsimonySitesP: "0.242840778923253", constantSitesP: "0.59106529209622", distinctSitesP: "0.38602520045819", brLenTotal: "0.7841" },
  { geneTranscript: "NM_004901.5", parsimonySitesP: "0.167748917748918", constantSitesP: "0.726190476190476", distinctSitesP: "0.239177489177489", brLenTotal: "0.6457" },
  { geneTranscript: "NM_004540.5", parsimonySitesP: "0.15810434090004", constantSitesP: "0.738351254480287", distinctSitesP: "0.222620469932298", brLenTotal: "0.5538" },
  { geneTranscript: "NM_001304963.2", parsimonySitesP: "0.16869918699187", constantSitesP: "0.644308943089431", distinctSitesP: "0.286585365853659", brLenTotal: "0.8801" },
  { geneTranscript: "NM_006713.4", parsimonySitesP: "0.10498687664042", constantSitesP: "0.779527559055118", distinctSitesP: "0.225721784776903", brLenTotal: "0.3280" },
  { geneTranscript: "NM_000924.4", parsimonySitesP: "0.148009950248756", constantSitesP: "0.759328358208955", distinctSitesP: "0.201492537313433", brLenTotal: "0.5339" },
  { geneTranscript: "NM_002524.5", parsimonySitesP: "0.128747795414462", constantSitesP: "0.767195767195767", distinctSitesP: "0.204585537918871", brLenTotal: "0.4128" },
  { geneTranscript: "NM_030773.4", parsimonySitesP: "0.28159645232816", constantSitesP: "0.624538063562454", distinctSitesP: "0.324464153732446", brLenTotal: "1.2672" },
  { geneTranscript: "NM_020529.3", parsimonySitesP: "0.153522607781283", constantSitesP: "0.738170347003155", distinctSitesP: "0.246056782334385", brLenTotal: "0.5112" },
  { geneTranscript: "NM_001351574.3", parsimonySitesP: "0.11046511627907", constantSitesP: "0.798449612403101", distinctSitesP: "0.186046511627907", brLenTotal: "0.4034" },
  { geneTranscript: "NM_006070.6", parsimonySitesP: "0.139166666666667", constantSitesP: "0.743333333333333", distinctSitesP: "0.223333333333333", brLenTotal: "0.4767" },
  { geneTranscript: "NM_173793.5", parsimonySitesP: "0.203174603174603", constantSitesP: "0.615873015873016", distinctSitesP: "0.387301587301587", brLenTotal: "0.9280" },
  { geneTranscript: "NM_014593.4", parsimonySitesP: "0.146341463414634", constantSitesP: "0.765243902439024", distinctSitesP: "0.196646341463415", brLenTotal: "0.5859" },
  { geneTranscript: "NM_007036.5", parsimonySitesP: "0.268115942028986", constantSitesP: "0.632246376811594", distinctSitesP: "0.344202898550725", brLenTotal: "0.8815" },
  { geneTranscript: "NM_001365276.2", parsimonySitesP: "0.3229657555765", constantSitesP: "0.50722588752749", distinctSitesP: "0.378966383914546", brLenTotal: "1.9001" },
  { geneTranscript: "NM_017590.6", parsimonySitesP: "0.155919481405664", constantSitesP: "0.75639713408393", distinctSitesP: "0.193108154213579", brLenTotal: "0.6684" },
  { geneTranscript: "NM_004946.3", parsimonySitesP: "0.165209471766849", constantSitesP: "0.739344262295082", distinctSitesP: "0.201275045537341", brLenTotal: "0.6318" },
  { geneTranscript: "NM_032728.4", parsimonySitesP: "0.154981549815498", constantSitesP: "0.730627306273063", distinctSitesP: "0.230012300123001", brLenTotal: "0.8032" },
  { geneTranscript: "NM_007129.5", parsimonySitesP: "0.177944862155388", constantSitesP: "0.74812030075188", distinctSitesP: "0.229949874686717", brLenTotal: "0.7197" },
  { geneTranscript: "NM_014399.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.727124183006536", distinctSitesP: "0.259803921568627", brLenTotal: "0.6885" },
  { geneTranscript: "NM_016141.4", parsimonySitesP: "0.159337157425112", constantSitesP: "0.745697896749522", distinctSitesP: "0.216061185468451", brLenTotal: "0.5615" },
  { geneTranscript: "NM_001348195.2", parsimonySitesP: "0.353564547206166", constantSitesP: "0.469171483622351", distinctSitesP: "0.555876685934489", brLenTotal: "1.7945" },
  { geneTranscript: "NM_001112726.3", parsimonySitesP: "0.321106821106821", constantSitesP: "0.537752037752038", distinctSitesP: "0.385456885456885", brLenTotal: "1.5204" },
  { geneTranscript: "NM_001144825.2", parsimonySitesP: "0.125560538116592", constantSitesP: "0.775784753363229", distinctSitesP: "0.191330343796712", brLenTotal: "0.4598" },
  { geneTranscript: "NM_152429.5", parsimonySitesP: "0.370801033591731", constantSitesP: "0.373385012919897", distinctSitesP: "0.626614987080103", brLenTotal: "1.4255" },
  { geneTranscript: "NM_030762.3", parsimonySitesP: "0.118948824343015", constantSitesP: "0.771092669432918", distinctSitesP: "0.231673582295989", brLenTotal: "0.4488" },
  { geneTranscript: "NM_001452.2", parsimonySitesP: "0.147147147147147", constantSitesP: "0.727477477477477", distinctSitesP: "0.253753753753754", brLenTotal: "0.6172" },
  { geneTranscript: "NM_203403.2", parsimonySitesP: "0.213450292397661", constantSitesP: "0.633040935672515", distinctSitesP: "0.35672514619883", brLenTotal: "0.8198" },
  { geneTranscript: "NM_173811.4", parsimonySitesP: "0.152817574021012", constantSitesP: "0.729703915950334", distinctSitesP: "0.226361031518625", brLenTotal: "0.5233" },
  { geneTranscript: "NM_015138.5", parsimonySitesP: "0.111737089201878", constantSitesP: "0.815492957746479", distinctSitesP: "0.154929577464789", brLenTotal: "0.3713" },
  { geneTranscript: "NM_002403.4", parsimonySitesP: "0.191256830601093", constantSitesP: "0.703096539162113", distinctSitesP: "0.322404371584699", brLenTotal: "0.7351" },
  { geneTranscript: "NM_000282.4", parsimonySitesP: "0.158882783882784", constantSitesP: "0.732600732600733", distinctSitesP: "0.262362637362637", brLenTotal: "0.5329" },
  { geneTranscript: "NM_001004476.2", parsimonySitesP: "0.347222222222222", constantSitesP: "0.446581196581197", distinctSitesP: "0.526709401709402", brLenTotal: "1.8153" },
  { geneTranscript: "NM_000298.6", parsimonySitesP: "0.215447154471545", constantSitesP: "0.668408826945412", distinctSitesP: "0.274680603948897", brLenTotal: "0.7726" },
  { geneTranscript: "NM_001243750.2", parsimonySitesP: "0.343220338983051", constantSitesP: "0.518361581920904", distinctSitesP: "0.470338983050847", brLenTotal: "1.2605" },
  { geneTranscript: "NM_006156.3", parsimonySitesP: "0.135802469135802", constantSitesP: "0.761316872427984", distinctSitesP: "0.246913580246914", brLenTotal: "0.3543" },
  { geneTranscript: "NM_138795.4", parsimonySitesP: "0.0949820788530466", constantSitesP: "0.851254480286738", distinctSitesP: "0.14336917562724", brLenTotal: "0.2687" },
  { geneTranscript: "NM_001282684.2", parsimonySitesP: "0.133757961783439", constantSitesP: "0.75796178343949", distinctSitesP: "0.240976645435244", brLenTotal: "0.5632" },
  { geneTranscript: "NM_001135586.1", parsimonySitesP: "0.111702127659574", constantSitesP: "0.789007092198582", distinctSitesP: "0.193262411347518", brLenTotal: "0.3455" },
  { geneTranscript: "NM_033022.4", parsimonySitesP: "0.115384615384615", constantSitesP: "0.741025641025641", distinctSitesP: "0.271794871794872", brLenTotal: "0.4683" },
  { geneTranscript: "NM_001037317.2", parsimonySitesP: "0.116303219106957", constantSitesP: "0.794392523364486", distinctSitesP: "0.190031152647975", brLenTotal: "0.4662" },
  { geneTranscript: "NM_004604.5", parsimonySitesP: "0.167227833894501", constantSitesP: "0.740740740740741", distinctSitesP: "0.239057239057239", brLenTotal: "0.6721" },
  { geneTranscript: "NM_013266.4", parsimonySitesP: "0.15903165735568", constantSitesP: "0.732216014897579", distinctSitesP: "0.226443202979516", brLenTotal: "0.5180" },
  { geneTranscript: "NM_001282680.3", parsimonySitesP: "0.125114155251142", constantSitesP: "0.791324200913242", distinctSitesP: "0.151598173515982", brLenTotal: "0.4069" },
  { geneTranscript: "NM_003504.5", parsimonySitesP: "0.197290930506478", constantSitesP: "0.690812720848057", distinctSitesP: "0.295053003533569", brLenTotal: "0.7653" },
  { geneTranscript: "NM_001099287.2", parsimonySitesP: "0.238448844884488", constantSitesP: "0.641089108910891", distinctSitesP: "0.33993399339934", brLenTotal: "0.8838" },
  { geneTranscript: "NM_005168.5", parsimonySitesP: "0.105191256830601", constantSitesP: "0.829234972677596", distinctSitesP: "0.165300546448087", brLenTotal: "0.3811" },
  { geneTranscript: "NM_006136.3", parsimonySitesP: "0.108391608391608", constantSitesP: "0.792540792540793", distinctSitesP: "0.180652680652681", brLenTotal: "0.3729" },
  { geneTranscript: "NM_001160305.4", parsimonySitesP: "0.260747004933051", constantSitesP: "0.580690627202255", distinctSitesP: "0.372093023255814", brLenTotal: "0.8770" },
  { geneTranscript: "NM_001256798.2", parsimonySitesP: "0.126960784313725", constantSitesP: "0.797549019607843", distinctSitesP: "0.167647058823529", brLenTotal: "0.6060" },
  { geneTranscript: "NM_002490.6", parsimonySitesP: "0.21875", constantSitesP: "0.611979166666667", distinctSitesP: "0.3984375", brLenTotal: "0.8535" },
  { geneTranscript: "NM_001305203.2", parsimonySitesP: "0.162997903563941", constantSitesP: "0.718029350104822", distinctSitesP: "0.253668763102725", brLenTotal: "0.5072" },
  { geneTranscript: "NM_012338.4", parsimonySitesP: "0.13551912568306", constantSitesP: "0.785792349726776", distinctSitesP: "0.191256830601093", brLenTotal: "0.3662" },
  { geneTranscript: "NM_002397.5", parsimonySitesP: "0.0690627202255109", constantSitesP: "0.886539816772375", distinctSitesP: "0.103594080338266", brLenTotal: "0.2383" },
  { geneTranscript: "NM_003204.3", parsimonySitesP: "0.122193436960276", constantSitesP: "0.801813471502591", distinctSitesP: "0.158894645941278", brLenTotal: "0.4342" },
  { geneTranscript: "NM_001256012.3", parsimonySitesP: "0.183026075402757", constantSitesP: "0.721807008802524", distinctSitesP: "0.202291978076731", brLenTotal: "0.7783" },
  { geneTranscript: "NM_003014.4", parsimonySitesP: "0.173410404624277", constantSitesP: "0.722543352601156", distinctSitesP: "0.249518304431599", brLenTotal: "0.6723" },
  { geneTranscript: "NM_014453.4", parsimonySitesP: "0.162162162162162", constantSitesP: "0.738738738738739", distinctSitesP: "0.24024024024024", brLenTotal: "0.5988" },
  { geneTranscript: "NM_001295.3", parsimonySitesP: "0.346478873239437", constantSitesP: "0.432863849765258", distinctSitesP: "0.469483568075117", brLenTotal: "1.8036" },
  { geneTranscript: "NM_001822.7", parsimonySitesP: "0.114742193173566", constantSitesP: "0.82643427741467", distinctSitesP: "0.164851125635439", brLenTotal: "0.4223" },
  { geneTranscript: "NM_006597.6", parsimonySitesP: "0.284829721362229", constantSitesP: "0.552115583075335", distinctSitesP: "0.375644994840041", brLenTotal: "1.3506" },
  { geneTranscript: "NM_001005194.2", parsimonySitesP: "0.447680690399137", constantSitesP: "0.395900755124056", distinctSitesP: "0.571736785329018", brLenTotal: "2.1888" },
  { geneTranscript: "NM_001146.5", parsimonySitesP: "0.139223560910308", constantSitesP: "0.78045515394913", distinctSitesP: "0.187416331994645", brLenTotal: "0.3983" },
  { geneTranscript: "NM_001393499.1", parsimonySitesP: "0.134692616620327", constantSitesP: "0.747605807846772", distinctSitesP: "0.184430027803522", brLenTotal: "0.4814" },
  { geneTranscript: "NM_001134232.2", parsimonySitesP: "0.125304136253041", constantSitesP: "0.759124087591241", distinctSitesP: "0.211678832116788", brLenTotal: "0.4480" },
  { geneTranscript: "NM_015140.4", parsimonySitesP: "0.231884057971014", constantSitesP: "0.633540372670807", distinctSitesP: "0.342650103519669", brLenTotal: "1.1460" },
  { geneTranscript: "NM_019848.5", parsimonySitesP: "0.232704402515723", constantSitesP: "0.614255765199161", distinctSitesP: "0.318658280922432", brLenTotal: "0.8684" },
  { geneTranscript: "NM_005640.3", parsimonySitesP: "0.220804331013148", constantSitesP: "0.594740912606342", distinctSitesP: "0.336040216550657", brLenTotal: "0.7364" },
  { geneTranscript: "NM_001276.4", parsimonySitesP: "0.252393385552654", constantSitesP: "0.609225413402959", distinctSitesP: "0.347258485639687", brLenTotal: "0.9773" },
  { geneTranscript: "NM_201591.3", parsimonySitesP: "0.128297362110312", constantSitesP: "0.806954436450839", distinctSitesP: "0.1810551558753", brLenTotal: "0.4396" },
  { geneTranscript: "NM_022167.4", parsimonySitesP: "0.173795761078998", constantSitesP: "0.714836223506744", distinctSitesP: "0.230057803468208", brLenTotal: "0.8138" },
  { geneTranscript: "NM_005029.4", parsimonySitesP: "0.194260485651214", constantSitesP: "0.641280353200883", distinctSitesP: "0.2560706401766", brLenTotal: "1.1167" },
  { geneTranscript: "NM_004036.5", parsimonySitesP: "0.192307692307692", constantSitesP: "0.720862470862471", distinctSitesP: "0.23018648018648", brLenTotal: "0.7985" },
  { geneTranscript: "NM_176782.3", parsimonySitesP: "0.328774928774929", constantSitesP: "0.466096866096866", distinctSitesP: "0.466096866096866", brLenTotal: "1.4326" },
  { geneTranscript: "NM_000274.4", parsimonySitesP: "0.204252088078967", constantSitesP: "0.665907365223994", distinctSitesP: "0.30751708428246", brLenTotal: "0.8463" },
  { geneTranscript: "NM_003502.4", parsimonySitesP: "0.28538283062645", constantSitesP: "0.596287703016241", distinctSitesP: "0.351121423047177", brLenTotal: "1.3206" },
  { geneTranscript: "NM_001043229.2", parsimonySitesP: "0.293055555555556", constantSitesP: "0.543055555555556", distinctSitesP: "0.405555555555556", brLenTotal: "0.9798" },
  { geneTranscript: "NM_006444.3", parsimonySitesP: "0.155388471177945", constantSitesP: "0.73322194374826", distinctSitesP: "0.218880534670008", brLenTotal: "0.4839" },
  { geneTranscript: "NM_022450.5", parsimonySitesP: "0.202729044834308", constantSitesP: "0.695126705653021", distinctSitesP: "0.253801169590643", brLenTotal: "0.9836" },
  { geneTranscript: "NM_003027.5", parsimonySitesP: "0.152737752161383", constantSitesP: "0.723342939481268", distinctSitesP: "0.272814601344861", brLenTotal: "0.5973" },
  { geneTranscript: "NM_000437.4", parsimonySitesP: "0.193877551020408", constantSitesP: "0.663265306122449", distinctSitesP: "0.282312925170068", brLenTotal: "0.6804" },
  { geneTranscript: "NM_001308027.2", parsimonySitesP: "0.0900417412045319", constantSitesP: "0.776386404293381", distinctSitesP: "0.143112701252236", brLenTotal: "0.4092" },
  { geneTranscript: "NM_003803.4", parsimonySitesP: "0.198021760633037", constantSitesP: "0.680909990108803", distinctSitesP: "0.264292779426311", brLenTotal: "0.7961" },
  { geneTranscript: "NM_020439.3", parsimonySitesP: "0.186974789915966", constantSitesP: "0.719187675070028", distinctSitesP: "0.253501400560224", brLenTotal: "0.6532" },
  { geneTranscript: "NM_001136.5", parsimonySitesP: "0.245049504950495", constantSitesP: "0.573432343234323", distinctSitesP: "0.348184818481848", brLenTotal: "0.9107" },
  { geneTranscript: "NM_032270.5", parsimonySitesP: "0.159402241594022", constantSitesP: "0.755500207555002", distinctSitesP: "0.190120381901204", brLenTotal: "0.5319" },
  { geneTranscript: "NM_001346311.2", parsimonySitesP: "0.13030303030303", constantSitesP: "0.771515151515152", distinctSitesP: "0.193939393939394", brLenTotal: "0.4454" },
  { geneTranscript: "NM_003247.5", parsimonySitesP: "0.291524459613197", constantSitesP: "0.623720136518771", distinctSitesP: "0.342434584755404", brLenTotal: "1.5866" },
  { geneTranscript: "NM_017521.3", parsimonySitesP: "0.222689075630252", constantSitesP: "0.677871148459384", distinctSitesP: "0.302521008403361", brLenTotal: "1.1706" },
  { geneTranscript: "NM_006885.4", parsimonySitesP: "0.142857142857143", constantSitesP: "0.770006301197227", distinctSitesP: "0.160140426681069", brLenTotal: "0.5650" },
  { geneTranscript: "NM_015634.4", parsimonySitesP: "0.169082125603865", constantSitesP: "0.668276972624799", distinctSitesP: "0.254965110037574", brLenTotal: "0.5756" },
  { geneTranscript: "NM_181654.4", parsimonySitesP: "0.177083333333333", constantSitesP: "0.727083333333333", distinctSitesP: "0.277083333333333", brLenTotal: "0.5931" },
  { geneTranscript: "NM_014034.3", parsimonySitesP: "0.0751633986928105", constantSitesP: "0.774509803921569", distinctSitesP: "0.179738562091503", brLenTotal: "0.3261" },
  { geneTranscript: "NM_194436.3", parsimonySitesP: "0.260330578512397", constantSitesP: "0.608815426997245", distinctSitesP: "0.369146005509642", brLenTotal: "1.1681" },
  { geneTranscript: "NM_006035.4", parsimonySitesP: "0.190337034872394", constantSitesP: "0.713422949542178", distinctSitesP: "0.234950321449445", brLenTotal: "0.8114" },
  { geneTranscript: "NM_001003760.5", parsimonySitesP: "0.15720294426919", constantSitesP: "0.737644584647739", distinctSitesP: "0.220294426919033", brLenTotal: "0.5300" },
  { geneTranscript: "NM_138288.4", parsimonySitesP: "0.136150234741784", constantSitesP: "0.76056338028169", distinctSitesP: "0.253521126760563", brLenTotal: "0.4282" },
  { geneTranscript: "NM_033119.5", parsimonySitesP: "0.177304964539007", constantSitesP: "0.695035460992908", distinctSitesP: "0.266666666666667", brLenTotal: "0.7878" },
  { geneTranscript: "NM_181458.4", parsimonySitesP: "0.152203856749311", constantSitesP: "0.777548209366391", distinctSitesP: "0.199035812672176", brLenTotal: "0.5502" },
  { geneTranscript: "NM_003013.3", parsimonySitesP: "0.135593220338983", constantSitesP: "0.763841807909605", distinctSitesP: "0.203389830508475", brLenTotal: "0.4855" },
  { geneTranscript: "NM_015722.4", parsimonySitesP: "0.28110599078341", constantSitesP: "0.559139784946237", distinctSitesP: "0.450076804915515", brLenTotal: "1.1033" },
  { geneTranscript: "NM_004910.3", parsimonySitesP: "0.233386923901393", constantSitesP: "0.669614147909968", distinctSitesP: "0.272775991425509", brLenTotal: "1.1532" },
  { geneTranscript: "NM_024313.3", parsimonySitesP: "0.247261345852895", constantSitesP: "0.571205007824726", distinctSitesP: "0.403755868544601", brLenTotal: "0.9815" },
  { geneTranscript: "NM_019555.3", parsimonySitesP: "0.14702154626109", constantSitesP: "0.745880861850444", distinctSitesP: "0.210392902408112", brLenTotal: "0.5782" },
  { geneTranscript: "NM_001330348.2", parsimonySitesP: "0.195959595959596", constantSitesP: "0.677056277056277", distinctSitesP: "0.259451659451659", brLenTotal: "0.9065" },
  { geneTranscript: "NM_014069.3", parsimonySitesP: "0.303921568627451", constantSitesP: "0.5", distinctSitesP: "0.487745098039216", brLenTotal: "1.1160" },
  { geneTranscript: "NM_001024644.2", parsimonySitesP: "0.328328328328328", constantSitesP: "0.530530530530531", distinctSitesP: "0.447447447447447", brLenTotal: "1.2617" },
  { geneTranscript: "NM_015099.4", parsimonySitesP: "0.159456461453134", constantSitesP: "0.73516361619523", distinctSitesP: "0.199667221297837", brLenTotal: "0.5194" },
  { geneTranscript: "NM_001352027.3", parsimonySitesP: "0.0621634850709741", constantSitesP: "0.867351933431229", distinctSitesP: "0.105237395986295", brLenTotal: "0.2230" },
  { geneTranscript: "NM_001244008.2", parsimonySitesP: "0.181276754141076", constantSitesP: "0.714870649544016", distinctSitesP: "0.230783547366462", brLenTotal: "0.9938" },
  { geneTranscript: "NM_145047.5", parsimonySitesP: "0.20844327176781", constantSitesP: "0.669305189094107", distinctSitesP: "0.308707124010554", brLenTotal: "0.7165" },
  { geneTranscript: "NM_001170687.4", parsimonySitesP: "0.22303664921466", constantSitesP: "0.665968586387435", distinctSitesP: "0.269109947643979", brLenTotal: "1.0998" },
  { geneTranscript: "NM_184234.3", parsimonySitesP: "0.115723270440252", constantSitesP: "0.80377358490566", distinctSitesP: "0.186163522012579", brLenTotal: "0.3781" },
  { geneTranscript: "NM_004784.3", parsimonySitesP: "0.251622756777396", constantSitesP: "0.65864833906071", distinctSitesP: "0.284841542573501", brLenTotal: "0.9095" },
  { geneTranscript: "NM_002062.5", parsimonySitesP: "0.209503239740821", constantSitesP: "0.682505399568035", distinctSitesP: "0.264938804895608", brLenTotal: "0.7866" },
  { geneTranscript: "NM_005922.4", parsimonySitesP: "0.180762852404643", constantSitesP: "0.715381426202322", distinctSitesP: "0.242951907131012", brLenTotal: "0.6708" },
  { geneTranscript: "NM_025029.5", parsimonySitesP: "0.339662447257384", constantSitesP: "0.457805907172996", distinctSitesP: "0.535864978902954", brLenTotal: "1.3742" },
  { geneTranscript: "NM_017672.6", parsimonySitesP: "0.11277926720286", constantSitesP: "0.793565683646113", distinctSitesP: "0.150312779267203", brLenTotal: "0.3617" },
  { geneTranscript: "NM_014892.5", parsimonySitesP: "0.131917125622869", constantSitesP: "0.769997377393129", distinctSitesP: "0.173354314188303", brLenTotal: "0.4414" },
  { geneTranscript: "NM_001286554.2", parsimonySitesP: "0.150678294573643", constantSitesP: "0.755813953488372", distinctSitesP: "0.200096899224806", brLenTotal: "0.4984" },
  { geneTranscript: "NM_052820.4", parsimonySitesP: "0.218412698412698", constantSitesP: "0.668571428571429", distinctSitesP: "0.304761904761905", brLenTotal: "0.7581" },
  { geneTranscript: "NM_006443.3", parsimonySitesP: "0.318007662835249", constantSitesP: "0.488505747126437", distinctSitesP: "0.484674329501916", brLenTotal: "1.2628" },
  { geneTranscript: "NM_001256409.2", parsimonySitesP: "0.180685358255452", constantSitesP: "0.697040498442368", distinctSitesP: "0.2601246105919", brLenTotal: "0.6017" },
  { geneTranscript: "NM_018244.5", parsimonySitesP: "0.17056856187291", constantSitesP: "0.701226309921962", distinctSitesP: "0.297658862876254", brLenTotal: "0.5440" },
  { geneTranscript: "NM_006805.4", parsimonySitesP: "0.13551912568306", constantSitesP: "0.804371584699454", distinctSitesP: "0.19016393442623", brLenTotal: "0.4410" },
  { geneTranscript: "NM_015894.4", parsimonySitesP: "0.151851851851852", constantSitesP: "0.738888888888889", distinctSitesP: "0.238888888888889", brLenTotal: "0.5765" },
  { geneTranscript: "NM_006040.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.671052631578947", distinctSitesP: "0.260233918128655", brLenTotal: "0.7919" },
  { geneTranscript: "NM_006011.4", parsimonySitesP: "0.183111111111111", constantSitesP: "0.737777777777778", distinctSitesP: "0.241777777777778", brLenTotal: "0.7975" },
  { geneTranscript: "NM_001353788.2", parsimonySitesP: "0.250111259457054", constantSitesP: "0.607476635514019", distinctSitesP: "0.337783711615487", brLenTotal: "1.5155" },
  { geneTranscript: "NM_005421.3", parsimonySitesP: "0.200617283950617", constantSitesP: "0.626543209876543", distinctSitesP: "0.37962962962963", brLenTotal: "0.7110" },
  { geneTranscript: "NM_001614.5", parsimonySitesP: "0.262222222222222", constantSitesP: "0.595555555555556", distinctSitesP: "0.367111111111111", brLenTotal: "1.8358" },
  { geneTranscript: "NM_018557.3", parsimonySitesP: "0.155758498224252", constantSitesP: "0.728926578241647", distinctSitesP: "0.176270203667464", brLenTotal: "0.5337" },
  { geneTranscript: "NM_207581.4", parsimonySitesP: "0.2875", constantSitesP: "0.54375", distinctSitesP: "0.444791666666667", brLenTotal: "1.1651" },
  { geneTranscript: "NM_015306.3", parsimonySitesP: "0.146819338422392", constantSitesP: "0.7735368956743", distinctSitesP: "0.174427480916031", brLenTotal: "0.5069" },
  { geneTranscript: "NM_014867.3", parsimonySitesP: "0.26645264847512", constantSitesP: "0.590690208667737", distinctSitesP: "0.370786516853933", brLenTotal: "1.2034" },
  { geneTranscript: "NM_001365919.1", parsimonySitesP: "0.0504885993485342", constantSitesP: "0.881107491856678", distinctSitesP: "0.0950054288816504", brLenTotal: "0.1743" },
  { geneTranscript: "NM_134268.5", parsimonySitesP: "0.121052631578947", constantSitesP: "0.782456140350877", distinctSitesP: "0.217543859649123", brLenTotal: "0.4929" },
  { geneTranscript: "NM_017761.4", parsimonySitesP: "0.143884892086331", constantSitesP: "0.606714628297362", distinctSitesP: "0.342925659472422", brLenTotal: "0.6273" },
  { geneTranscript: "NM_001083962.2", parsimonySitesP: "0.0983606557377049", constantSitesP: "0.828117237953304", distinctSitesP: "0.141082960755092", brLenTotal: "0.4357" },
  { geneTranscript: "NM_015420.7", parsimonySitesP: "0.155056179775281", constantSitesP: "0.674906367041199", distinctSitesP: "0.281647940074906", brLenTotal: "0.5536" },
  { geneTranscript: "NM_015012.4", parsimonySitesP: "0.13745704467354", constantSitesP: "0.741122565864834", distinctSitesP: "0.255441008018328", brLenTotal: "0.4282" },
  { geneTranscript: "NM_001001674.2", parsimonySitesP: "0.527777777777778", constantSitesP: "0.335470085470085", distinctSitesP: "0.635683760683761", brLenTotal: "2.7443" },
  { geneTranscript: "NM_001097612.2", parsimonySitesP: "0.136996336996337", constantSitesP: "0.766300366300366", distinctSitesP: "0.199267399267399", brLenTotal: "0.4512" },
  { geneTranscript: "NM_138706.5", parsimonySitesP: "0.321180555555556", constantSitesP: "0.506076388888889", distinctSitesP: "0.467013888888889", brLenTotal: "1.3441" },
  { geneTranscript: "NM_019035.5", parsimonySitesP: "0.166226138032305", constantSitesP: "0.723348017621145", distinctSitesP: "0.216740088105727", brLenTotal: "0.6171" },
  { geneTranscript: "NM_001080513.4", parsimonySitesP: "0.304587155963303", constantSitesP: "0.514984709480122", distinctSitesP: "0.400611620795107", brLenTotal: "1.3716" },
  { geneTranscript: "NM_001370165.1", parsimonySitesP: "0.140586189766518", constantSitesP: "0.751614505712866", distinctSitesP: "0.208643815201192", brLenTotal: "0.4195" },
  { geneTranscript: "NM_001321527.2", parsimonySitesP: "0.257178526841448", constantSitesP: "0.565959217644611", distinctSitesP: "0.354140657511444", brLenTotal: "0.9167" },
  { geneTranscript: "NM_003668.4", parsimonySitesP: "0.113234253361642", constantSitesP: "0.820240622788393", distinctSitesP: "0.16135881104034", brLenTotal: "0.3457" },
  { geneTranscript: "NM_152479.6", parsimonySitesP: "0.181311018131102", constantSitesP: "0.707112970711297", distinctSitesP: "0.271966527196653", brLenTotal: "0.6821" },
  { geneTranscript: "NM_001042432.2", parsimonySitesP: "0.248097412480974", constantSitesP: "0.628614916286149", distinctSitesP: "0.359208523592085", brLenTotal: "0.9064" },
  { geneTranscript: "NM_016608.2", parsimonySitesP: "0.18027961736571", constantSitesP: "0.649006622516556", distinctSitesP: "0.258278145695364", brLenTotal: "0.6521" },
  { geneTranscript: "NM_006223.4", parsimonySitesP: "0.132315521628499", constantSitesP: "0.620865139949109", distinctSitesP: "0.379134860050891", brLenTotal: "0.6631" },
  { geneTranscript: "NM_006755.2", parsimonySitesP: "0.205736894164194", constantSitesP: "0.690405539070227", distinctSitesP: "0.289812067260138", brLenTotal: "1.0094" },
  { geneTranscript: "NM_004716.4", parsimonySitesP: "0.204670912951168", constantSitesP: "0.656900212314225", distinctSitesP: "0.276857749469214", brLenTotal: "0.7921" },
  { geneTranscript: "NM_138422.4", parsimonySitesP: "0.348773841961853", constantSitesP: "0.517711171662125", distinctSitesP: "0.445049954586739", brLenTotal: "1.8977" },
  { geneTranscript: "NM_014064.4", parsimonySitesP: "0.177877428998505", constantSitesP: "0.721973094170404", distinctSitesP: "0.242152466367713", brLenTotal: "0.7749" },
  { geneTranscript: "NM_001363562.2", parsimonySitesP: "0.11731843575419", constantSitesP: "0.815642458100559", distinctSitesP: "0.189944134078212", brLenTotal: "0.3587" },
  { geneTranscript: "NM_198083.4", parsimonySitesP: "0.333333333333333", constantSitesP: "0.48132183908046", distinctSitesP: "0.507183908045977", brLenTotal: "1.4890" },
  { geneTranscript: "NM_014316.4", parsimonySitesP: "0.215419501133787", constantSitesP: "0.634920634920635", distinctSitesP: "0.344671201814059", brLenTotal: "1.2378" },
  { geneTranscript: "NM_001317078.4", parsimonySitesP: "0.170765027322404", constantSitesP: "0.704918032786885", distinctSitesP: "0.260928961748634", brLenTotal: "0.5836" },
  { geneTranscript: "NM_001278356.2", parsimonySitesP: "0.0977690288713911", constantSitesP: "0.791994750656168", distinctSitesP: "0.167322834645669", brLenTotal: "0.3319" },
  { geneTranscript: "NM_001258315.2", parsimonySitesP: "0.143326039387309", constantSitesP: "0.758570386579139", distinctSitesP: "0.217359591539023", brLenTotal: "0.4723" },
  { geneTranscript: "NM_021101.5", parsimonySitesP: "0.175355450236967", constantSitesP: "0.706161137440758", distinctSitesP: "0.248025276461295", brLenTotal: "0.6262" },
  { geneTranscript: "NM_000525.4", parsimonySitesP: "0.238461538461538", constantSitesP: "0.671794871794872", distinctSitesP: "0.258974358974359", brLenTotal: "1.0271" },
  { geneTranscript: "NM_006331.8", parsimonySitesP: "0.202185792349727", constantSitesP: "0.629781420765027", distinctSitesP: "0.322404371584699", brLenTotal: "0.7360" },
  { geneTranscript: "NM_005257.6", parsimonySitesP: "0.217927170868347", constantSitesP: "0.668347338935574", distinctSitesP: "0.30140056022409", brLenTotal: "0.7968" },
  { geneTranscript: "NM_001308173.3", parsimonySitesP: "0.211024978466839", constantSitesP: "0.655469422911283", distinctSitesP: "0.291128337639966", brLenTotal: "0.8853" },
  { geneTranscript: "NM_001005191.3", parsimonySitesP: "0.53525641025641", constantSitesP: "0.322649572649573", distinctSitesP: "0.66025641025641", brLenTotal: "3.5454" },
  { geneTranscript: "NM_001105244.2", parsimonySitesP: "0.177474402730375", constantSitesP: "0.727189988623436", distinctSitesP: "0.218430034129693", brLenTotal: "0.7925" },
  { geneTranscript: "NM_001177479.2", parsimonySitesP: "0.147342995169082", constantSitesP: "0.723671497584541", distinctSitesP: "0.251690821256039", brLenTotal: "0.4667" },
  { geneTranscript: "NM_018713.3", parsimonySitesP: "0.249484536082474", constantSitesP: "0.583505154639175", distinctSitesP: "0.4", brLenTotal: "0.8367" },
  { geneTranscript: "NM_017445.3", parsimonySitesP: "0.195767195767196", constantSitesP: "0.653439153439153", distinctSitesP: "0.343915343915344", brLenTotal: "1.4292" },
  { geneTranscript: "NM_032982.4", parsimonySitesP: "0.220501474926254", constantSitesP: "0.66740412979351", distinctSitesP: "0.299410029498525", brLenTotal: "0.7546" },
  { geneTranscript: "NM_001083913.2", parsimonySitesP: "0.169880624426079", constantSitesP: "0.60514233241506", distinctSitesP: "0.31404958677686", brLenTotal: "0.9686" },
  { geneTranscript: "NM_021035.3", parsimonySitesP: "0.208898157803267", constantSitesP: "0.669447340980188", distinctSitesP: "0.220368439346542", brLenTotal: "0.6673" },
  { geneTranscript: "NM_018389.5", parsimonySitesP: "0.204212454212454", constantSitesP: "0.679487179487179", distinctSitesP: "0.282967032967033", brLenTotal: "0.8151" },
  { geneTranscript: "NM_001270531.2", parsimonySitesP: "0.0824915824915825", constantSitesP: "0.787037037037037", distinctSitesP: "0.212962962962963", brLenTotal: "0.3332" },
  { geneTranscript: "NM_007188.5", parsimonySitesP: "0.240018570102136", constantSitesP: "0.624883936861653", distinctSitesP: "0.326369545032498", brLenTotal: "1.0023" },
  { geneTranscript: "NM_015999.6", parsimonySitesP: "0.141333333333333", constantSitesP: "0.669333333333333", distinctSitesP: "0.258666666666667", brLenTotal: "0.6123" },
  { geneTranscript: "NM_198526.4", parsimonySitesP: "0.199799196787149", constantSitesP: "0.703815261044177", distinctSitesP: "0.234939759036145", brLenTotal: "0.9424" },
  { geneTranscript: "NM_001318042.2", parsimonySitesP: "0.143955276030748", constantSitesP: "0.774982529699511", distinctSitesP: "0.184136967155835", brLenTotal: "0.7882" },
  { geneTranscript: "NM_012138.4", parsimonySitesP: "0.22797619047619", constantSitesP: "0.595238095238095", distinctSitesP: "0.3375", brLenTotal: "0.7859" },
  { geneTranscript: "NM_000766.5", parsimonySitesP: "0.275303643724696", constantSitesP: "0.535087719298246", distinctSitesP: "0.397435897435897", brLenTotal: "1.1796" },
  { geneTranscript: "NM_001130987.2", parsimonySitesP: "0.181532169262231", constantSitesP: "0.705836086204184", distinctSitesP: "0.215982381626553", brLenTotal: "0.7039" },
  { geneTranscript: "NM_133637.3", parsimonySitesP: "0.218503021850302", constantSitesP: "0.622501162250116", distinctSitesP: "0.274755927475593", brLenTotal: "0.6947" },
  { geneTranscript: "NM_013351.2", parsimonySitesP: "0.178816199376947", constantSitesP: "0.69595015576324", distinctSitesP: "0.258566978193146", brLenTotal: "0.6936" },
  { geneTranscript: "NM_001159699.2", parsimonySitesP: "0.126126126126126", constantSitesP: "0.826576576576577", distinctSitesP: "0.172297297297297", brLenTotal: "0.3129" },
  { geneTranscript: "NM_006275.6", parsimonySitesP: "0.150193798449612", constantSitesP: "0.747093023255814", distinctSitesP: "0.218023255813953", brLenTotal: "0.5067" },
  { geneTranscript: "NM_000794.5", parsimonySitesP: "0.188340807174888", constantSitesP: "0.699551569506726", distinctSitesP: "0.250373692077728", brLenTotal: "0.8300" },
  { geneTranscript: "NM_006076.5", parsimonySitesP: "0.221067221067221", constantSitesP: "0.637560637560638", distinctSitesP: "0.316701316701317", brLenTotal: "0.7475" },
  { geneTranscript: "NM_002137.4", parsimonySitesP: "0.130987292277615", constantSitesP: "0.781036168132942", distinctSitesP: "0.188660801564027", brLenTotal: "0.4215" },
  { geneTranscript: "NM_006358.4", parsimonySitesP: "0.144408251900109", constantSitesP: "0.733984799131379", distinctSitesP: "0.251900108577633", brLenTotal: "0.4569" },
  { geneTranscript: "NM_199421.2", parsimonySitesP: "0.181818181818182", constantSitesP: "0.646969696969697", distinctSitesP: "0.268939393939394", brLenTotal: "0.6902" },
  { geneTranscript: "NM_017443.5", parsimonySitesP: "0.154195011337868", constantSitesP: "0.750566893424036", distinctSitesP: "0.247165532879819", brLenTotal: "0.5770" },
  { geneTranscript: "NM_001394867.1", parsimonySitesP: "0.10755871324255", constantSitesP: "0.810144069469114", distinctSitesP: "0.136767317939609", brLenTotal: "0.3418" },
  { geneTranscript: "NM_002795.4", parsimonySitesP: "0.12520325203252", constantSitesP: "0.75609756097561", distinctSitesP: "0.222764227642276", brLenTotal: "0.5333" },
  { geneTranscript: "NM_032590.5", parsimonySitesP: "0.160678642714571", constantSitesP: "0.752245508982036", distinctSitesP: "0.198852295409182", brLenTotal: "0.8211" },
  { geneTranscript: "NM_021817.3", parsimonySitesP: "0.185294117647059", constantSitesP: "0.695098039215686", distinctSitesP: "0.265686274509804", brLenTotal: "0.6394" },
  { geneTranscript: "NM_006493.4", parsimonySitesP: "0.229050279329609", constantSitesP: "0.631284916201117", distinctSitesP: "0.36219739292365", brLenTotal: "0.7452" },
  { geneTranscript: "NM_001004693.2", parsimonySitesP: "0.533119658119658", constantSitesP: "0.34508547008547", distinctSitesP: "0.657051282051282", brLenTotal: "2.9319" },
  { geneTranscript: "NM_001190880.3", parsimonySitesP: "0.219013237063779", constantSitesP: "0.635379061371841", distinctSitesP: "0.335740072202166", brLenTotal: "0.7973" },
  { geneTranscript: "NM_173810.4", parsimonySitesP: "0.138401559454191", constantSitesP: "0.730994152046784", distinctSitesP: "0.239766081871345", brLenTotal: "0.4499" },
  { geneTranscript: "NM_018697.4", parsimonySitesP: "0.252592592592593", constantSitesP: "0.638518518518519", distinctSitesP: "0.362962962962963", brLenTotal: "0.9770" },
  { geneTranscript: "NM_017643.3", parsimonySitesP: "0.0796178343949045", constantSitesP: "0.849787685774947", distinctSitesP: "0.121549893842887", brLenTotal: "0.2684" },
  { geneTranscript: "NM_022164.3", parsimonySitesP: "0.201998572448251", constantSitesP: "0.673090649536046", distinctSitesP: "0.279086366880799", brLenTotal: "0.7956" },
  { geneTranscript: "NM_001146070.2", parsimonySitesP: "0.156362007168459", constantSitesP: "0.693996415770609", distinctSitesP: "0.240591397849462", brLenTotal: "0.5343" },
  { geneTranscript: "NM_181703.4", parsimonySitesP: "0.2756052141527", constantSitesP: "0.621042830540037", distinctSitesP: "0.336126629422719", brLenTotal: "0.9996" },
  { geneTranscript: "NM_000517.6", parsimonySitesP: "0.295774647887324", constantSitesP: "0.584507042253521", distinctSitesP: "0.413145539906103", brLenTotal: "1.3785" },
  { geneTranscript: "NM_000237.3", parsimonySitesP: "0.225964912280702", constantSitesP: "0.665263157894737", distinctSitesP: "0.331929824561404", brLenTotal: "0.8293" },
  { geneTranscript: "NM_006810.4", parsimonySitesP: "0.161207450224791", constantSitesP: "0.737957610789981", distinctSitesP: "0.243416827231856", brLenTotal: "0.5813" },
  { geneTranscript: "NM_015705.6", parsimonySitesP: "0.193591455273698", constantSitesP: "0.718291054739653", distinctSitesP: "0.245215843346684", brLenTotal: "0.8840" },
  { geneTranscript: "NM_001170331.2", parsimonySitesP: "0.169047619047619", constantSitesP: "0.71984126984127", distinctSitesP: "0.236507936507937", brLenTotal: "0.5533" },
  { geneTranscript: "NM_017842.3", parsimonySitesP: "0.162100456621005", constantSitesP: "0.755707762557078", distinctSitesP: "0.237442922374429", brLenTotal: "0.5928" },
  { geneTranscript: "NM_001366686.3", parsimonySitesP: "0.14536157779401", constantSitesP: "0.750669588507426", distinctSitesP: "0.196737277818359", brLenTotal: "0.5232" },
  { geneTranscript: "NM_020664.4", parsimonySitesP: "0.280821917808219", constantSitesP: "0.58675799086758", distinctSitesP: "0.41324200913242", brLenTotal: "1.3424" },
  { geneTranscript: "NM_012351.3", parsimonySitesP: "0.507011866235167", constantSitesP: "0.303128371089536", distinctSitesP: "0.687162891046386", brLenTotal: "2.7211" },
  { geneTranscript: "NM_031490.5", parsimonySitesP: "0.174100156494523", constantSitesP: "0.732394366197183", distinctSitesP: "0.223787167449139", brLenTotal: "0.5847" },
  { geneTranscript: "NM_032880.5", parsimonySitesP: "0.182012847965739", constantSitesP: "0.712348322626695", distinctSitesP: "0.264810849393291", brLenTotal: "0.9035" },
  { geneTranscript: "NM_025232.4", parsimonySitesP: "0.221789883268482", constantSitesP: "0.64461738002594", distinctSitesP: "0.326848249027237", brLenTotal: "0.9260" },
  { geneTranscript: "NM_001350605.2", parsimonySitesP: "0.12465564738292", constantSitesP: "0.786501377410468", distinctSitesP: "0.194903581267218", brLenTotal: "0.4192" },
  { geneTranscript: "NM_021214.2", parsimonySitesP: "0.224924012158055", constantSitesP: "0.651469098277609", distinctSitesP: "0.353596757852077", brLenTotal: "1.1148" },
  { geneTranscript: "NM_152564.5", parsimonySitesP: "0.173630222667", constantSitesP: "0.700775581686265", distinctSitesP: "0.204319906596614", brLenTotal: "0.5644" },
  { geneTranscript: "NM_005242.6", parsimonySitesP: "0.235936188077246", constantSitesP: "0.612930310663308", distinctSitesP: "0.355163727959698", brLenTotal: "0.8398" },
  { geneTranscript: "NM_001366432.2", parsimonySitesP: "0.111644657863145", constantSitesP: "0.805922368947579", distinctSitesP: "0.13765506202481", brLenTotal: "0.3779" },
  { geneTranscript: "NM_002283.4", parsimonySitesP: "0.213675213675214", constantSitesP: "0.664694280078895", distinctSitesP: "0.30637738330046", brLenTotal: "1.2673" },
  { geneTranscript: "NM_004850.5", parsimonySitesP: "0.11359269932757", constantSitesP: "0.790105667627281", distinctSitesP: "0.167627281460134", brLenTotal: "0.3762" },
  { geneTranscript: "NM_014997.4", parsimonySitesP: "0.120663650075415", constantSitesP: "0.795625942684766", distinctSitesP: "0.168929110105581", brLenTotal: "0.5036" },
  { geneTranscript: "NM_003091.4", parsimonySitesP: "0.220779220779221", constantSitesP: "0.685425685425685", distinctSitesP: "0.295815295815296", brLenTotal: "0.9690" },
  { geneTranscript: "NM_144711.6", parsimonySitesP: "0.106929510155317", constantSitesP: "0.773596176821983", distinctSitesP: "0.18936678614098", brLenTotal: "0.3734" },
  { geneTranscript: "NM_000505.4", parsimonySitesP: "0.314905149051491", constantSitesP: "0.523035230352304", distinctSitesP: "0.449864498644986", brLenTotal: "1.2250" },
  { geneTranscript: "NM_020796.5", parsimonySitesP: "0.161812297734628", constantSitesP: "0.73915857605178", distinctSitesP: "0.231391585760518", brLenTotal: "0.6527" },
  { geneTranscript: "NM_177947.3", parsimonySitesP: "0.132805628847845", constantSitesP: "0.687774846086192", distinctSitesP: "0.259454705364996", brLenTotal: "0.5351" },
  { geneTranscript: "NM_001242896.3", parsimonySitesP: "0.151174880432522", constantSitesP: "0.753794967768767", distinctSitesP: "0.192139737991266", brLenTotal: "0.5706" },
  { geneTranscript: "NM_001001952.1", parsimonySitesP: "0.596379126730564", constantSitesP: "0.284345047923323", distinctSitesP: "0.707135250266241", brLenTotal: "3.8539" },
  { geneTranscript: "NM_004209.6", parsimonySitesP: "0.176128093158661", constantSitesP: "0.727802037845706", distinctSitesP: "0.251819505094614", brLenTotal: "0.6421" },
  { geneTranscript: "NM_033105.6", parsimonySitesP: "0.217755443886097", constantSitesP: "0.604690117252931", distinctSitesP: "0.368509212730318", brLenTotal: "0.9806" },
  { geneTranscript: "NM_001099439.2", parsimonySitesP: "0.214285714285714", constantSitesP: "0.655092592592593", distinctSitesP: "0.271494708994709", brLenTotal: "0.9806" },
  { geneTranscript: "NM_001114974.2", parsimonySitesP: "0.268980477223427", constantSitesP: "0.552422270426609", distinctSitesP: "0.404193781634129", brLenTotal: "1.3899" },
  { geneTranscript: "NM_001031692.3", parsimonySitesP: "0.153439153439153", constantSitesP: "0.718065003779289", distinctSitesP: "0.232048374905518", brLenTotal: "0.4968" },
  { geneTranscript: "NM_005660.3", parsimonySitesP: "0.159090909090909", constantSitesP: "0.760942760942761", distinctSitesP: "0.212121212121212", brLenTotal: "0.6011" },
  { geneTranscript: "NM_001236.4", parsimonySitesP: "0.322503008423586", constantSitesP: "0.525872442839952", distinctSitesP: "0.447653429602888", brLenTotal: "1.3320" },
  { geneTranscript: "NM_001004492.2", parsimonySitesP: "0.538380651945321", constantSitesP: "0.28286014721346", distinctSitesP: "0.652996845425868", brLenTotal: "2.7437" },
  { geneTranscript: "NM_001310.4", parsimonySitesP: "0.105555555555556", constantSitesP: "0.783333333333333", distinctSitesP: "0.213888888888889", brLenTotal: "0.3626" },
  { geneTranscript: "NM_015189.3", parsimonySitesP: "0.106041923551171", constantSitesP: "0.804356761200164", distinctSitesP: "0.157007809288944", brLenTotal: "0.3365" },
  { geneTranscript: "NM_000964.4", parsimonySitesP: "0.155844155844156", constantSitesP: "0.764790764790765", distinctSitesP: "0.189033189033189", brLenTotal: "0.6248" },
  { geneTranscript: "NM_014482.3", parsimonySitesP: "0.223270440251572", constantSitesP: "0.658018867924528", distinctSitesP: "0.308176100628931", brLenTotal: "0.7650" },
  { geneTranscript: "NM_000601.6", parsimonySitesP: "0.152472527472527", constantSitesP: "0.759615384615385", distinctSitesP: "0.206501831501832", brLenTotal: "0.4413" },
  { geneTranscript: "NM_005585.5", parsimonySitesP: "0.131048387096774", constantSitesP: "0.723790322580645", distinctSitesP: "0.227150537634409", brLenTotal: "0.5842" },
  { geneTranscript: "NM_001320038.2", parsimonySitesP: "0.114942528735632", constantSitesP: "0.793103448275862", distinctSitesP: "0.252873563218391", brLenTotal: "0.3584" },
  { geneTranscript: "NM_183357.3", parsimonySitesP: "0.169177901136664", constantSitesP: "0.739095955590801", distinctSitesP: "0.223632038065028", brLenTotal: "0.7037" },
  { geneTranscript: "NM_024293.6", parsimonySitesP: "0.170042971147944", constantSitesP: "0.682627378759975", distinctSitesP: "0.262737875997545", brLenTotal: "0.5763" },
  { geneTranscript: "NM_003062.4", parsimonySitesP: "0.179032611074633", constantSitesP: "0.696870212300285", distinctSitesP: "0.225869993434012", brLenTotal: "0.7924" },
  { geneTranscript: "NM_001282866.2", parsimonySitesP: "0.238510762070971", constantSitesP: "0.621291448516579", distinctSitesP: "0.319953461314718", brLenTotal: "0.8413" },
  { geneTranscript: "NM_004082.5", parsimonySitesP: "0.15414710485133", constantSitesP: "0.741784037558685", distinctSitesP: "0.177882107459572", brLenTotal: "0.5369" },
  { geneTranscript: "NM_000252.3", parsimonySitesP: "0.145936981757877", constantSitesP: "0.766169154228856", distinctSitesP: "0.201216141514649", brLenTotal: "0.4766" },
  { geneTranscript: "NM_001242672.3", parsimonySitesP: "0.354031510658017", constantSitesP: "0.467408093914118", distinctSitesP: "0.447018844609206", brLenTotal: "1.6698" },
  { geneTranscript: "NM_012258.4", parsimonySitesP: "0.179824561403509", constantSitesP: "0.719298245614035", distinctSitesP: "0.24780701754386", brLenTotal: "0.5959" },
  { geneTranscript: "NM_021259.3", parsimonySitesP: "0.340683095546909", constantSitesP: "0.517942066580199", distinctSitesP: "0.454820579334198", brLenTotal: "1.5016" },
  { geneTranscript: "NM_000334.4", parsimonySitesP: "0.204974582425563", constantSitesP: "0.674473493100944", distinctSitesP: "0.249092229484386", brLenTotal: "1.1418" },
  { geneTranscript: "NM_014002.4", parsimonySitesP: "0.21927374301676", constantSitesP: "0.637337057728119", distinctSitesP: "0.328212290502793", brLenTotal: "0.9328" },
  { geneTranscript: "NM_006537.4", parsimonySitesP: "0.124358974358974", constantSitesP: "0.791025641025641", distinctSitesP: "0.185897435897436", brLenTotal: "0.4059" },
  { geneTranscript: "NM_153711.5", parsimonySitesP: "0.252427184466019", constantSitesP: "0.601941747572816", distinctSitesP: "0.352750809061489", brLenTotal: "0.8669" },
  { geneTranscript: "NM_005796.3", parsimonySitesP: "0.078740157480315", constantSitesP: "0.84251968503937", distinctSitesP: "0.162729658792651", brLenTotal: "0.3305" },
  { geneTranscript: "NM_003279.3", parsimonySitesP: "0.195833333333333", constantSitesP: "0.69375", distinctSitesP: "0.270833333333333", brLenTotal: "0.7527" },
  { geneTranscript: "NM_153329.4", parsimonySitesP: "0.266832917705736", constantSitesP: "0.573150457190357", distinctSitesP: "0.34621778886118", brLenTotal: "1.1478" },
  { geneTranscript: "NM_000992.3", parsimonySitesP: "0.253668763102725", constantSitesP: "0.547169811320755", distinctSitesP: "0.438155136268344", brLenTotal: "1.1548" },
  { geneTranscript: "NM_005828.5", parsimonySitesP: "0.0974658869395712", constantSitesP: "0.797270955165692", distinctSitesP: "0.164717348927875", brLenTotal: "0.4976" },
  { geneTranscript: "NM_182836.3", parsimonySitesP: "0.184597295708407", constantSitesP: "0.691358024691358", distinctSitesP: "0.242798353909465", brLenTotal: "0.6144" },
  { geneTranscript: "NM_015270.5", parsimonySitesP: "0.173801369863014", constantSitesP: "0.728025114155251", distinctSitesP: "0.207477168949772", brLenTotal: "0.7309" },
  { geneTranscript: "NM_001276451.2", parsimonySitesP: "0.17617237008872", constantSitesP: "0.711026615969582", distinctSitesP: "0.259822560202788", brLenTotal: "0.7926" },
  { geneTranscript: "NM_001011547.3", parsimonySitesP: "0.232501223690651", constantSitesP: "0.634850709740578", distinctSitesP: "0.341164953499755", brLenTotal: "0.9447" },
  { geneTranscript: "NM_007185.7", parsimonySitesP: "0.118279569892473", constantSitesP: "0.806451612903226", distinctSitesP: "0.172759856630824", brLenTotal: "0.5675" },
  { geneTranscript: "NM_001145204.3", parsimonySitesP: "0.139937106918239", constantSitesP: "0.783018867924528", distinctSitesP: "0.202044025157233", brLenTotal: "0.5312" },
  { geneTranscript: "NM_002536.4", parsimonySitesP: "0.206395348837209", constantSitesP: "0.640503875968992", distinctSitesP: "0.289728682170543", brLenTotal: "0.8190" },
  { geneTranscript: "NM_003000.3", parsimonySitesP: "0.180952380952381", constantSitesP: "0.70952380952381", distinctSitesP: "0.278571428571429", brLenTotal: "0.6489" },
  { geneTranscript: "NM_183240.3", parsimonySitesP: "0.27719298245614", constantSitesP: "0.545614035087719", distinctSitesP: "0.429824561403509", brLenTotal: "1.0908" },
  { geneTranscript: "NM_018704.3", parsimonySitesP: "0.206572769953052", constantSitesP: "0.644757433489828", distinctSitesP: "0.282211789254043", brLenTotal: "0.7698" },
  { geneTranscript: "NM_001319152.2", parsimonySitesP: "0.307127882599581", constantSitesP: "0.524109014675052", distinctSitesP: "0.447589098532495", brLenTotal: "1.4625" },
  { geneTranscript: "NM_004473.4", parsimonySitesP: "0.247542448614835", constantSitesP: "0.647006255585344", distinctSitesP: "0.328865058087578", brLenTotal: "0.9415" },
  { geneTranscript: "NM_020182.5", parsimonySitesP: "0.180023228803717", constantSitesP: "0.708478513356562", distinctSitesP: "0.261324041811847", brLenTotal: "0.8490" },
  { geneTranscript: "NM_025237.3", parsimonySitesP: "0.169014084507042", constantSitesP: "0.687010954616588", distinctSitesP: "0.297339593114241", brLenTotal: "0.7698" },
  { geneTranscript: "NM_007115.4", parsimonySitesP: "0.18531889290012", constantSitesP: "0.716004813477738", distinctSitesP: "0.276774969915764", brLenTotal: "0.6056" },
  { geneTranscript: "NM_018943.3", parsimonySitesP: "0.21826280623608", constantSitesP: "0.67706013363029", distinctSitesP: "0.279881217520416", brLenTotal: "1.2434" },
  { geneTranscript: "NM_004765.4", parsimonySitesP: "0.119815668202765", constantSitesP: "0.77726574500768", distinctSitesP: "0.202764976958525", brLenTotal: "0.4116" },
  { geneTranscript: "NM_001004484.2", parsimonySitesP: "0.590233545647558", constantSitesP: "0.288747346072187", distinctSitesP: "0.698513800424628", brLenTotal: "3.8787" },
  { geneTranscript: "NM_001012614.2", parsimonySitesP: "0.208236208236208", constantSitesP: "0.696969696969697", distinctSitesP: "0.277389277389277", brLenTotal: "1.3124" },
  { geneTranscript: "NM_003164.5", parsimonySitesP: "0.12112676056338", constantSitesP: "0.773708920187793", distinctSitesP: "0.186854460093897", brLenTotal: "0.3793" },
  { geneTranscript: "NM_004482.4", parsimonySitesP: "0.163243812532912", constantSitesP: "0.738809899947341", distinctSitesP: "0.221169036334913", brLenTotal: "0.5262" },
  { geneTranscript: "NM_032256.3", parsimonySitesP: "0.143968871595331", constantSitesP: "0.778210116731518", distinctSitesP: "0.182230869001297", brLenTotal: "0.4532" },
  { geneTranscript: "NM_030803.7", parsimonySitesP: "0.159802306425041", constantSitesP: "0.738605161998902", distinctSitesP: "0.223503569467326", brLenTotal: "0.5404" },
  { geneTranscript: "NM_001355258.2", parsimonySitesP: "0.517156862745098", constantSitesP: "0.299019607843137", distinctSitesP: "0.681372549019608", brLenTotal: "3.1717" },
  { geneTranscript: "NM_001169.3", parsimonySitesP: "0.286079182630907", constantSitesP: "0.573435504469987", distinctSitesP: "0.432950191570881", brLenTotal: "1.2969" },
  { geneTranscript: "NM_001329990.2", parsimonySitesP: "0.211916807195053", constantSitesP: "0.670039347948286", distinctSitesP: "0.292861157953907", brLenTotal: "0.9798" },
  { geneTranscript: "NM_001463.4", parsimonySitesP: "0.155897435897436", constantSitesP: "0.689230769230769", distinctSitesP: "0.273846153846154", brLenTotal: "0.6210" },
  { geneTranscript: "NM_001195150.3", parsimonySitesP: "0.28219696969697", constantSitesP: "0.575757575757576", distinctSitesP: "0.397727272727273", brLenTotal: "0.9036" },
  { geneTranscript: "NM_001009999.3", parsimonySitesP: "0.139649923896499", constantSitesP: "0.769025875190259", distinctSitesP: "0.198630136986301", brLenTotal: "0.4632" },
  { geneTranscript: "NM_001009894.3", parsimonySitesP: "0.175384615384615", constantSitesP: "0.691282051282051", distinctSitesP: "0.297435897435897", brLenTotal: "0.5560" },
  { geneTranscript: "NM_016498.5", parsimonySitesP: "0.188755020080321", constantSitesP: "0.662650602409639", distinctSitesP: "0.313253012048193", brLenTotal: "0.7726" },
  { geneTranscript: "NM_000726.5", parsimonySitesP: "0.102564102564103", constantSitesP: "0.821153846153846", distinctSitesP: "0.161538461538462", brLenTotal: "0.3800" },
  { geneTranscript: "NM_002068.4", parsimonySitesP: "0.254901960784314", constantSitesP: "0.639928698752228", distinctSitesP: "0.331550802139037", brLenTotal: "1.1982" },
  { geneTranscript: "NM_018176.4", parsimonySitesP: "0.165749235474006", constantSitesP: "0.745565749235474", distinctSitesP: "0.235474006116208", brLenTotal: "0.7404" },
  { geneTranscript: "NM_152265.5", parsimonySitesP: "0.111814345991561", constantSitesP: "0.706751054852321", distinctSitesP: "0.278481012658228", brLenTotal: "0.4457" },
  { geneTranscript: "NM_152416.4", parsimonySitesP: "0.212212212212212", constantSitesP: "0.654654654654655", distinctSitesP: "0.335335335335335", brLenTotal: "0.6533" },
  { geneTranscript: "NM_130782.3", parsimonySitesP: "0.231205673758865", constantSitesP: "0.628368794326241", distinctSitesP: "0.370212765957447", brLenTotal: "0.7253" },
  { geneTranscript: "NM_001077446.4", parsimonySitesP: "0.22258064516129", constantSitesP: "0.645161290322581", distinctSitesP: "0.324731182795699", brLenTotal: "0.8573" },
  { geneTranscript: "NM_175876.5", parsimonySitesP: "0.194022988505747", constantSitesP: "0.68183908045977", distinctSitesP: "0.237241379310345", brLenTotal: "0.7107" },
  { geneTranscript: "NM_178126.4", parsimonySitesP: "0.158082975679542", constantSitesP: "0.721030042918455", distinctSitesP: "0.233905579399142", brLenTotal: "0.4944" },
  { geneTranscript: "NM_022060.3", parsimonySitesP: "0.171539961013645", constantSitesP: "0.736842105263158", distinctSitesP: "0.230019493177388", brLenTotal: "0.6467" },
  { geneTranscript: "NM_007085.5", parsimonySitesP: "0.136363636363636", constantSitesP: "0.755411255411255", distinctSitesP: "0.222943722943723", brLenTotal: "0.4941" },
  { geneTranscript: "NM_005592.4", parsimonySitesP: "0.181051016494054", constantSitesP: "0.709627924817798", distinctSitesP: "0.246260069044879", brLenTotal: "0.6414" },
  { geneTranscript: "NM_183352.3", parsimonySitesP: "0.186335403726708", constantSitesP: "0.632505175983437", distinctSitesP: "0.331262939958592", brLenTotal: "0.8917" },
  { geneTranscript: "NM_000304.4", parsimonySitesP: "0.170833333333333", constantSitesP: "0.71875", distinctSitesP: "0.28125", brLenTotal: "0.7085" },
  { geneTranscript: "NM_003490.4", parsimonySitesP: "0.210919540229885", constantSitesP: "0.678735632183908", distinctSitesP: "0.285632183908046", brLenTotal: "0.8658" },
  { geneTranscript: "NM_017824.5", parsimonySitesP: "0.0827338129496403", constantSitesP: "0.839328537170264", distinctSitesP: "0.145083932853717", brLenTotal: "0.2616" },
  { geneTranscript: "NM_006315.7", parsimonySitesP: "0.169421487603306", constantSitesP: "0.758953168044077", distinctSitesP: "0.247933884297521", brLenTotal: "0.6772" },
  { geneTranscript: "NM_002160.4", parsimonySitesP: "0.237922156595487", constantSitesP: "0.624110252915342", distinctSitesP: "0.284567620778434", brLenTotal: "1.0219" },
  { geneTranscript: "NM_002398.3", parsimonySitesP: "0.041025641025641", constantSitesP: "0.917094017094017", distinctSitesP: "0.0863247863247863", brLenTotal: "0.1540" },
  { geneTranscript: "NM_001257964.2", parsimonySitesP: "0.437984496124031", constantSitesP: "0.27906976744186", distinctSitesP: "0.734496124031008", brLenTotal: "2.0510" },
  { geneTranscript: "NM_020639.3", parsimonySitesP: "0.228741496598639", constantSitesP: "0.642857142857143", distinctSitesP: "0.286139455782313", brLenTotal: "1.3138" },
  { geneTranscript: "NM_001031703.3", parsimonySitesP: "0.243107769423559", constantSitesP: "0.600250626566416", distinctSitesP: "0.37218045112782", brLenTotal: "0.8050" },
  { geneTranscript: "NM_001080506.3", parsimonySitesP: "0.174029451137885", constantSitesP: "0.725568942436412", distinctSitesP: "0.278447121820616", brLenTotal: "0.8082" },
  { geneTranscript: "NM_007068.4", parsimonySitesP: "0.115686274509804", constantSitesP: "0.808823529411765", distinctSitesP: "0.191176470588235", brLenTotal: "0.3519" },
  { geneTranscript: "NM_021570.4", parsimonySitesP: "0.206036745406824", constantSitesP: "0.704724409448819", distinctSitesP: "0.28740157480315", brLenTotal: "0.7898" },
  { geneTranscript: "NM_001099735.2", parsimonySitesP: "0.194112967382657", constantSitesP: "0.708830548926014", distinctSitesP: "0.251392203659507", brLenTotal: "0.8238" },
  { geneTranscript: "NM_000256.3", parsimonySitesP: "0.227106227106227", constantSitesP: "0.65541601255887", distinctSitesP: "0.280481423338566", brLenTotal: "0.9674" },
  { geneTranscript: "NM_001365088.1", parsimonySitesP: "0.137101449275362", constantSitesP: "0.773913043478261", distinctSitesP: "0.168695652173913", brLenTotal: "0.4612" },
  { geneTranscript: "NM_001127644.2", parsimonySitesP: "0.181286549707602", constantSitesP: "0.740497076023392", distinctSitesP: "0.222953216374269", brLenTotal: "0.6133" },
  { geneTranscript: "NM_017986.4", parsimonySitesP: "0.319940476190476", constantSitesP: "0.503720238095238", distinctSitesP: "0.439732142857143", brLenTotal: "1.3450" },
  { geneTranscript: "NM_006055.3", parsimonySitesP: "0.172932330827068", constantSitesP: "0.700918964076859", distinctSitesP: "0.268170426065163", brLenTotal: "0.6618" },
  { geneTranscript: "NM_177402.5", parsimonySitesP: "0.175019888623707", constantSitesP: "0.758949880668258", distinctSitesP: "0.222752585521082", brLenTotal: "0.7718" },
  { geneTranscript: "NM_024735.5", parsimonySitesP: "0.23747680890538", constantSitesP: "0.648113790970934", distinctSitesP: "0.319109461966605", brLenTotal: "1.2808" },
  { geneTranscript: "NM_177972.3", parsimonySitesP: "0.147562582345191", constantSitesP: "0.759552042160738", distinctSitesP: "0.200922266139657", brLenTotal: "0.5116" },
  { geneTranscript: "NM_001115.3", parsimonySitesP: "0.149480415667466", constantSitesP: "0.762057021049827", distinctSitesP: "0.190514255262457", brLenTotal: "0.5885" },
  { geneTranscript: "NM_018268.4", parsimonySitesP: "0.158315177923021", constantSitesP: "0.707334785766158", distinctSitesP: "0.268700072621641", brLenTotal: "0.5188" },
  { geneTranscript: "NM_194282.4", parsimonySitesP: "0.164218958611482", constantSitesP: "0.72630173564753", distinctSitesP: "0.22919448153093", brLenTotal: "0.6592" },
  { geneTranscript: "NM_003571.4", parsimonySitesP: "0.194377510040161", constantSitesP: "0.688353413654618", distinctSitesP: "0.290763052208835", brLenTotal: "0.7942" },
  { geneTranscript: "NM_017688.3", parsimonySitesP: "0.218076285240464", constantSitesP: "0.638474295190713", distinctSitesP: "0.31592039800995", brLenTotal: "0.8126" },
  { geneTranscript: "NM_001013661.1", parsimonySitesP: "0.22061191626409", constantSitesP: "0.658615136876006", distinctSitesP: "0.309983896940419", brLenTotal: "0.7626" },
  { geneTranscript: "NM_001008489.4", parsimonySitesP: "0.16597510373444", constantSitesP: "0.676348547717842", distinctSitesP: "0.29045643153527", brLenTotal: "0.5124" },
  { geneTranscript: "NM_013365.5", parsimonySitesP: "0.176317162232655", constantSitesP: "0.687010954616588", distinctSitesP: "0.25717266562337", brLenTotal: "0.7370" },
  { geneTranscript: "NM_004280.5", parsimonySitesP: "0.18007662835249", constantSitesP: "0.708812260536398", distinctSitesP: "0.304597701149425", brLenTotal: "0.6762" },
  { geneTranscript: "NM_000439.5", parsimonySitesP: "0.197432492253209", constantSitesP: "0.693227091633466", distinctSitesP: "0.277999114652501", brLenTotal: "0.7112" },
  { geneTranscript: "NM_001257096.2", parsimonySitesP: "0.191101385849745", constantSitesP: "0.685630926331145", distinctSitesP: "0.293946024799416", brLenTotal: "0.7757" },
  { geneTranscript: "NM_001961.4", parsimonySitesP: "0.198912198912199", constantSitesP: "0.71017871017871", distinctSitesP: "0.254856254856255", brLenTotal: "1.0207" },
  { geneTranscript: "NM_181599.3", parsimonySitesP: "0.523255813953488", constantSitesP: "0.22093023255814", distinctSitesP: "0.825581395348837", brLenTotal: "2.5635" },
  { geneTranscript: "NM_001567.4", parsimonySitesP: "0.170906200317965", constantSitesP: "0.729994700582936", distinctSitesP: "0.208532061473238", brLenTotal: "0.6520" },
  { geneTranscript: "NM_001320669.3", parsimonySitesP: "0.330122029543995", constantSitesP: "0.533718689788054", distinctSitesP: "0.421965317919075", brLenTotal: "1.4400" },
  { geneTranscript: "NM_000956.4", parsimonySitesP: "0.227188081936685", constantSitesP: "0.629422718808194", distinctSitesP: "0.326815642458101", brLenTotal: "0.7851" },
  { geneTranscript: "NM_006389.5", parsimonySitesP: "0.193526860193527", constantSitesP: "0.670670670670671", distinctSitesP: "0.257924591257925", brLenTotal: "0.7222" },
  { geneTranscript: "NM_025159.3", parsimonySitesP: "0.204872646733112", constantSitesP: "0.666666666666667", distinctSitesP: "0.305647840531561", brLenTotal: "0.5765" },
  { geneTranscript: "NM_013450.4", parsimonySitesP: "0.109624846248462", constantSitesP: "0.80119926199262", distinctSitesP: "0.138376383763838", brLenTotal: "0.3324" },
  { geneTranscript: "NM_001010852.4", parsimonySitesP: "0.137614678899083", constantSitesP: "0.756371049949032", distinctSitesP: "0.208970438328236", brLenTotal: "0.5489" },
  { geneTranscript: "NM_021078.3", parsimonySitesP: "0.142970927917164", constantSitesP: "0.77299880525687", distinctSitesP: "0.174432497013142", brLenTotal: "0.5866" },
  { geneTranscript: "NM_004573.3", parsimonySitesP: "0.219127988748242", constantSitesP: "0.649507735583685", distinctSitesP: "0.281012658227848", brLenTotal: "0.7847" },
  { geneTranscript: "NM_032924.5", parsimonySitesP: "0.175635276532138", constantSitesP: "0.643497757847534", distinctSitesP: "0.285500747384155", brLenTotal: "0.8270" },
  { geneTranscript: "NM_004811.3", parsimonySitesP: "0.208117443868739", constantSitesP: "0.638169257340242", distinctSitesP: "0.328151986183074", brLenTotal: "0.7445" },
  { geneTranscript: "NM_001017998.4", parsimonySitesP: "0.21078431372549", constantSitesP: "0.632352941176471", distinctSitesP: "0.352941176470588", brLenTotal: "0.6224" },
  { geneTranscript: "NM_001114748.2", parsimonySitesP: "0.0982658959537572", constantSitesP: "0.84393063583815", distinctSitesP: "0.15028901734104", brLenTotal: "0.4226" },
  { geneTranscript: "NM_004094.5", parsimonySitesP: "0.107936507936508", constantSitesP: "0.776719576719577", distinctSitesP: "0.183068783068783", brLenTotal: "0.3762" },
  { geneTranscript: "NM_001370062.2", parsimonySitesP: "0.23741435805779", constantSitesP: "0.601429848078642", distinctSitesP: "0.358057789693178", brLenTotal: "0.8100" },
  { geneTranscript: "NM_002686.4", parsimonySitesP: "0.252955082742317", constantSitesP: "0.621749408983452", distinctSitesP: "0.367612293144208", brLenTotal: "0.9254" },
  { geneTranscript: "NM_017726.8", parsimonySitesP: "0.220689655172414", constantSitesP: "0.613793103448276", distinctSitesP: "0.409195402298851", brLenTotal: "0.7907" },
  { geneTranscript: "NM_001171.6", parsimonySitesP: "0.287646928365491", constantSitesP: "0.526058993124861", distinctSitesP: "0.419605233976491", brLenTotal: "1.3274" },
  { geneTranscript: "NM_001352.5", parsimonySitesP: "0.205128205128205", constantSitesP: "0.705641025641026", distinctSitesP: "0.275897435897436", brLenTotal: "0.8038" },
  { geneTranscript: "NM_002745.5", parsimonySitesP: "0.131481481481481", constantSitesP: "0.778703703703704", distinctSitesP: "0.194444444444444", brLenTotal: "0.4942" },
  { geneTranscript: "NM_182498.4", parsimonySitesP: "0.154255319148936", constantSitesP: "0.74468085106383", distinctSitesP: "0.25177304964539", brLenTotal: "0.5634" },
  { geneTranscript: "NM_001143854.2", parsimonySitesP: "0.205571565802113", constantSitesP: "0.668107588856868", distinctSitesP: "0.29731027857829", brLenTotal: "0.8736" },
  { geneTranscript: "NM_024630.3", parsimonySitesP: "0.148907103825137", constantSitesP: "0.788251366120219", distinctSitesP: "0.193989071038251", brLenTotal: "0.6126" },
  { geneTranscript: "NM_001382430.1", parsimonySitesP: "0.182638888888889", constantSitesP: "0.740972222222222", distinctSitesP: "0.233333333333333", brLenTotal: "1.0773" },
  { geneTranscript: "NM_001843.4", parsimonySitesP: "0.165684348395547", constantSitesP: "0.750163719711853", distinctSitesP: "0.219711853307138", brLenTotal: "0.5745" },
  { geneTranscript: "NM_004408.4", parsimonySitesP: "0.140432098765432", constantSitesP: "0.76658950617284", distinctSitesP: "0.181327160493827", brLenTotal: "0.6599" },
  { geneTranscript: "NM_005198.5", parsimonySitesP: "0.182278481012658", constantSitesP: "0.70210970464135", distinctSitesP: "0.265822784810127", brLenTotal: "0.6042" },
  { geneTranscript: "NM_019060.3", parsimonySitesP: "0.36026936026936", constantSitesP: "0.441077441077441", distinctSitesP: "0.609427609427609", brLenTotal: "1.7958" },
  { geneTranscript: "NM_016546.4", parsimonySitesP: "0.314168377823409", constantSitesP: "0.471594798083504", distinctSitesP: "0.468856947296372", brLenTotal: "1.4174" },
  { geneTranscript: "NM_014213.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.672348484848485", distinctSitesP: "0.310606060606061", brLenTotal: "0.6898" },
  { geneTranscript: "NM_017620.3", parsimonySitesP: "0.161469933184855", constantSitesP: "0.753526354862658", distinctSitesP: "0.198960653303638", brLenTotal: "0.7853" },
  { geneTranscript: "NM_152653.4", parsimonySitesP: "0.0895522388059701", constantSitesP: "0.81592039800995", distinctSitesP: "0.172470978441128", brLenTotal: "0.3396" },
  { geneTranscript: "NM_032344.4", parsimonySitesP: "0.268426842684268", constantSitesP: "0.565456545654565", distinctSitesP: "0.382838283828383", brLenTotal: "1.0460" },
  { geneTranscript: "NM_001164431.2", parsimonySitesP: "0.265084075173096", constantSitesP: "0.557368941641939", distinctSitesP: "0.415430267062315", brLenTotal: "1.1117" },
  { geneTranscript: "NM_001004754.3", parsimonySitesP: "0.387820512820513", constantSitesP: "0.398504273504274", distinctSitesP: "0.523504273504274", brLenTotal: "1.9700" },
  { geneTranscript: "NM_001012974.4", parsimonySitesP: "0.126582278481013", constantSitesP: "0.79535864978903", distinctSitesP: "0.187763713080169", brLenTotal: "0.3949" },
  { geneTranscript: "NM_145115.3", parsimonySitesP: "0.231004901960784", constantSitesP: "0.636029411764706", distinctSitesP: "0.300857843137255", brLenTotal: "0.9240" },
  { geneTranscript: "NM_031288.4", parsimonySitesP: "0.199438202247191", constantSitesP: "0.680711610486891", distinctSitesP: "0.281835205992509", brLenTotal: "0.7221" },
  { geneTranscript: "NM_005438.5", parsimonySitesP: "0.232472324723247", constantSitesP: "0.602706027060271", distinctSitesP: "0.354243542435424", brLenTotal: "0.8442" },
  { geneTranscript: "NM_153252.5", parsimonySitesP: "0.12079171291158", constantSitesP: "0.783388827229005", distinctSitesP: "0.154827968923418", brLenTotal: "0.3666" },
  { geneTranscript: "NM_016174.5", parsimonySitesP: "0.213445378151261", constantSitesP: "0.654341736694678", distinctSitesP: "0.28515406162465", brLenTotal: "0.8911" },
  { geneTranscript: "NM_207340.3", parsimonySitesP: "0.204225352112676", constantSitesP: "0.669014084507042", distinctSitesP: "0.299295774647887", brLenTotal: "0.8354" },
  { geneTranscript: "NM_001004125.3", parsimonySitesP: "0.312599681020734", constantSitesP: "0.511961722488038", distinctSitesP: "0.499202551834131", brLenTotal: "1.1641" },
  { geneTranscript: "NM_005829.5", parsimonySitesP: "0.120898100172712", constantSitesP: "0.768566493955095", distinctSitesP: "0.226252158894646", brLenTotal: "0.4322" },
  { geneTranscript: "NM_020853.2", parsimonySitesP: "0.228831725616292", constantSitesP: "0.637191854233655", distinctSitesP: "0.312433011789925", brLenTotal: "0.8498" },
  { geneTranscript: "NM_005670.4", parsimonySitesP: "0.191339375629406", constantSitesP: "0.707955689828802", distinctSitesP: "0.275931520644512", brLenTotal: "0.6557" },
  { geneTranscript: "NM_001032296.4", parsimonySitesP: "0.17246713070379", constantSitesP: "0.703789636504254", distinctSitesP: "0.25754060324826", brLenTotal: "0.7943" },
  { geneTranscript: "NM_018316.3", parsimonySitesP: "0.226016260162602", constantSitesP: "0.669918699186992", distinctSitesP: "0.273712737127371", brLenTotal: "1.1875" },
  { geneTranscript: "NM_001270377.2", parsimonySitesP: "0.138888888888889", constantSitesP: "0.732456140350877", distinctSitesP: "0.254385964912281", brLenTotal: "0.5173" },
  { geneTranscript: "NM_080658.2", parsimonySitesP: "0.331243469174504", constantSitesP: "0.512016718913271", distinctSitesP: "0.496342737722048", brLenTotal: "1.4221" },
  { geneTranscript: "NM_015080.4", parsimonySitesP: "0.131619937694704", constantSitesP: "0.763823987538941", distinctSitesP: "0.164914330218069", brLenTotal: "0.6062" },
  { geneTranscript: "NM_003612.5", parsimonySitesP: "0.206706706706707", constantSitesP: "0.679179179179179", distinctSitesP: "0.278278278278278", brLenTotal: "0.8727" },
  { geneTranscript: "NM_020890.3", parsimonySitesP: "0.187845303867403", constantSitesP: "0.671454880294659", distinctSitesP: "0.293186003683241", brLenTotal: "0.5944" },
  { geneTranscript: "NM_145160.3", parsimonySitesP: "0.0989583333333333", constantSitesP: "0.81547619047619", distinctSitesP: "0.181547619047619", brLenTotal: "0.3619" },
  { geneTranscript: "NM_019008.6", parsimonySitesP: "0.151187904967603", constantSitesP: "0.751619870410367", distinctSitesP: "0.216702663786897", brLenTotal: "0.5430" },
  { geneTranscript: "NM_001395498.1", parsimonySitesP: "0.160852713178295", constantSitesP: "0.734496124031008", distinctSitesP: "0.253875968992248", brLenTotal: "0.6116" },
  { geneTranscript: "NM_001135993.2", parsimonySitesP: "0.12292738707833", constantSitesP: "0.778730703259005", distinctSitesP: "0.200686106346484", brLenTotal: "0.4325" },
  { geneTranscript: "NM_000329.3", parsimonySitesP: "0.178236397748593", constantSitesP: "0.727329580988118", distinctSitesP: "0.265165728580363", brLenTotal: "0.6234" },
  { geneTranscript: "NM_052904.4", parsimonySitesP: "0.16505376344086", constantSitesP: "0.763440860215054", distinctSitesP: "0.212365591397849", brLenTotal: "0.5873" },
  { geneTranscript: "NM_015112.3", parsimonySitesP: "0.21598071931776", constantSitesP: "0.649981460882462", distinctSitesP: "0.276603633667037", brLenTotal: "0.7516" },
  { geneTranscript: "NM_024339.5", parsimonySitesP: "0.190615835777126", constantSitesP: "0.703812316715543", distinctSitesP: "0.273704789833822", brLenTotal: "0.7505" },
  { geneTranscript: "NM_015316.3", parsimonySitesP: "0.213455657492355", constantSitesP: "0.669724770642202", distinctSitesP: "0.276452599388379", brLenTotal: "0.8401" },
  { geneTranscript: "NM_000875.5", parsimonySitesP: "0.191660570592538", constantSitesP: "0.72201901975128", distinctSitesP: "0.209948792977323", brLenTotal: "0.7437" },
  { geneTranscript: "NM_001004459.2", parsimonySitesP: "0.522435897435897", constantSitesP: "0.31517094017094", distinctSitesP: "0.648504273504274", brLenTotal: "2.7372" },
  { geneTranscript: "NM_198403.4", parsimonySitesP: "0.149051490514905", constantSitesP: "0.775067750677507", distinctSitesP: "0.222222222222222", brLenTotal: "0.5917" },
  { geneTranscript: "NM_002972.4", parsimonySitesP: "0.211128719845043", constantSitesP: "0.693960204261314", distinctSitesP: "0.23842225743969", brLenTotal: "0.9507" },
  { geneTranscript: "NM_001393769.1", parsimonySitesP: "0.154587155963303", constantSitesP: "0.742660550458716", distinctSitesP: "0.183792048929664", brLenTotal: "0.5845" },
  { geneTranscript: "NM_015208.5", parsimonySitesP: "0.167313288069835", constantSitesP: "0.697219527966376", distinctSitesP: "0.242159715486583", brLenTotal: "0.5756" },
  { geneTranscript: "NM_173653.4", parsimonySitesP: "0.180878552971576", constantSitesP: "0.703359173126615", distinctSitesP: "0.266666666666667", brLenTotal: "0.6075" },
  { geneTranscript: "NM_012166.3", parsimonySitesP: "0.185843793584379", constantSitesP: "0.709205020920502", distinctSitesP: "0.21792189679219", brLenTotal: "0.6304" },
  { geneTranscript: "NM_153676.4", parsimonySitesP: "0.182424916573971", constantSitesP: "0.701520207638116", distinctSitesP: "0.275862068965517", brLenTotal: "0.7310" },
  { geneTranscript: "NM_153026.3", parsimonySitesP: "0.167268351383875", constantSitesP: "0.731247492980345", distinctSitesP: "0.213397513036502", brLenTotal: "0.7215" },
  { geneTranscript: "NM_001271838.2", parsimonySitesP: "0.158682634730539", constantSitesP: "0.712574850299401", distinctSitesP: "0.274451097804391", brLenTotal: "0.5513" },
  { geneTranscript: "NM_001379313.1", parsimonySitesP: "0.190651906519065", constantSitesP: "0.658056580565806", distinctSitesP: "0.311193111931119", brLenTotal: "0.7167" },
  { geneTranscript: "NM_203475.3", parsimonySitesP: "0.11352133044107", constantSitesP: "0.817787418655098", distinctSitesP: "0.162689804772234", brLenTotal: "0.4235" },
  { geneTranscript: "NM_001384359.1", parsimonySitesP: "0.320547945205479", constantSitesP: "0.515068493150685", distinctSitesP: "0.435616438356164", brLenTotal: "1.4797" },
  { geneTranscript: "NM_021012.5", parsimonySitesP: "0.213240954580446", constantSitesP: "0.677444187836798", distinctSitesP: "0.286374133949192", brLenTotal: "1.0899" },
  { geneTranscript: "NM_005060.4", parsimonySitesP: "0.216859716859717", constantSitesP: "0.66023166023166", distinctSitesP: "0.292149292149292", brLenTotal: "0.7231" },
  { geneTranscript: "NM_001814.6", parsimonySitesP: "0.237580993520518", constantSitesP: "0.598272138228942", distinctSitesP: "0.357811375089993", brLenTotal: "0.8772" },
  { geneTranscript: "NM_153373.4", parsimonySitesP: "0.188148148148148", constantSitesP: "0.686666666666667", distinctSitesP: "0.279259259259259", brLenTotal: "0.7412" },
  { geneTranscript: "NM_019606.6", parsimonySitesP: "0.192065795839381", constantSitesP: "0.661828737300435", distinctSitesP: "0.275278180938558", brLenTotal: "0.6967" },
  { geneTranscript: "NM_005137.3", parsimonySitesP: "0.204848484848485", constantSitesP: "0.695151515151515", distinctSitesP: "0.264848484848485", brLenTotal: "0.9460" },
  { geneTranscript: "NM_004766.3", parsimonySitesP: "0.108167770419426", constantSitesP: "0.800588668138337", distinctSitesP: "0.15673289183223", brLenTotal: "0.3614" },
  { geneTranscript: "NM_006201.5", parsimonySitesP: "0.120967741935484", constantSitesP: "0.786962365591398", distinctSitesP: "0.182123655913978", brLenTotal: "0.4179" },
  { geneTranscript: "NM_001004485.1", parsimonySitesP: "0.45141065830721", constantSitesP: "0.35423197492163", distinctSitesP: "0.658307210031348", brLenTotal: "2.3307" },
  { geneTranscript: "NM_005686.3", parsimonySitesP: "0.172025723472669", constantSitesP: "0.695605573419078", distinctSitesP: "0.245980707395498", brLenTotal: "0.6961" },
  { geneTranscript: "NM_017515.5", parsimonySitesP: "0.24777183600713", constantSitesP: "0.622994652406417", distinctSitesP: "0.376114081996435", brLenTotal: "0.8600" },
  { geneTranscript: "NM_032208.3", parsimonySitesP: "0.146572104018913", constantSitesP: "0.763002364066194", distinctSitesP: "0.231678486997636", brLenTotal: "0.5333" },
  { geneTranscript: "NM_138370.3", parsimonySitesP: "0.16632860040568", constantSitesP: "0.723461798512508", distinctSitesP: "0.245436105476673", brLenTotal: "0.6408" },
  { geneTranscript: "NM_024899.4", parsimonySitesP: "0.121396054628225", constantSitesP: "0.786545270612038", distinctSitesP: "0.179059180576631", brLenTotal: "0.4280" },
  { geneTranscript: "NM_173050.5", parsimonySitesP: "0.213900134952767", constantSitesP: "0.656545209176788", distinctSitesP: "0.285425101214575", brLenTotal: "0.9629" },
  { geneTranscript: "NM_001833.4", parsimonySitesP: "0.123853211009174", constantSitesP: "0.770642201834862", distinctSitesP: "0.215596330275229", brLenTotal: "0.4264" },
  { geneTranscript: "NM_020297.4", parsimonySitesP: "0.16010329244674", constantSitesP: "0.747579083279535", distinctSitesP: "0.201205078545298", brLenTotal: "0.5941" },
  { geneTranscript: "NM_005746.3", parsimonySitesP: "0.118805159538357", constantSitesP: "0.790224032586558", distinctSitesP: "0.194161575016972", brLenTotal: "0.3616" },
  { geneTranscript: "NM_002359.4", parsimonySitesP: "0.298353909465021", constantSitesP: "0.631687242798354", distinctSitesP: "0.343621399176955", brLenTotal: "1.3338" },
  { geneTranscript: "NM_015305.4", parsimonySitesP: "0.191044776119403", constantSitesP: "0.658706467661692", distinctSitesP: "0.257213930348259", brLenTotal: "0.6872" },
  { geneTranscript: "NM_021184.4", parsimonySitesP: "0.293650793650794", constantSitesP: "0.526077097505669", distinctSitesP: "0.403628117913832", brLenTotal: "0.8930" },
  { geneTranscript: "NM_024560.4", parsimonySitesP: "0.206997084548105", constantSitesP: "0.689990281827017", distinctSitesP: "0.279883381924198", brLenTotal: "0.7110" },
  { geneTranscript: "NM_018266.3", parsimonySitesP: "0.105874316939891", constantSitesP: "0.806693989071038", distinctSitesP: "0.163251366120219", brLenTotal: "0.3237" },
  { geneTranscript: "NM_005220.3", parsimonySitesP: "0.117305458768873", constantSitesP: "0.801393728222997", distinctSitesP: "0.174216027874564", brLenTotal: "0.4675" },
  { geneTranscript: "NM_005539.5", parsimonySitesP: "0.186893203883495", constantSitesP: "0.730582524271845", distinctSitesP: "0.287216828478964", brLenTotal: "0.7752" },
  { geneTranscript: "NM_001098811.2", parsimonySitesP: "0.160110420979986", constantSitesP: "0.761214630779848", distinctSitesP: "0.219461697722567", brLenTotal: "0.7348" },
  { geneTranscript: "NM_000690.4", parsimonySitesP: "0.210831721470019", constantSitesP: "0.655061250805932", distinctSitesP: "0.306898774983881", brLenTotal: "0.8942" },
  { geneTranscript: "NM_024417.5", parsimonySitesP: "0.23489477257298", constantSitesP: "0.604209097080788", distinctSitesP: "0.318397827562797", brLenTotal: "0.9405" },
  { geneTranscript: "NM_000474.4", parsimonySitesP: "0.12046204620462", constantSitesP: "0.767326732673267", distinctSitesP: "0.259075907590759", brLenTotal: "0.4762" },
  { geneTranscript: "NM_003413.4", parsimonySitesP: "0.216987865810136", constantSitesP: "0.72876516773733", distinctSitesP: "0.228408279800143", brLenTotal: "0.6977" },
  { geneTranscript: "NM_019071.3", parsimonySitesP: "0.116427432216906", constantSitesP: "0.809409888357257", distinctSitesP: "0.175438596491228", brLenTotal: "0.3761" },
  { geneTranscript: "NM_002105.3", parsimonySitesP: "0.174825174825175", constantSitesP: "0.724941724941725", distinctSitesP: "0.256410256410256", brLenTotal: "0.6896" },
  { geneTranscript: "NM_015942.5", parsimonySitesP: "0.206235011990408", constantSitesP: "0.62669864108713", distinctSitesP: "0.378896882494005", brLenTotal: "0.6644" },
  { geneTranscript: "NM_021130.5", parsimonySitesP: "0.131313131313131", constantSitesP: "0.672727272727273", distinctSitesP: "0.315151515151515", brLenTotal: "0.6004" },
  { geneTranscript: "NM_017659.4", parsimonySitesP: "0.226876090750436", constantSitesP: "0.636998254799302", distinctSitesP: "0.31326352530541", brLenTotal: "0.9209" },
  { geneTranscript: "NM_181342.3", parsimonySitesP: "0.196696696696697", constantSitesP: "0.677177177177177", distinctSitesP: "0.315315315315315", brLenTotal: "0.5906" },
  { geneTranscript: "NM_020845.3", parsimonySitesP: "0.218680504077094", constantSitesP: "0.679762787249815", distinctSitesP: "0.261428218433407", brLenTotal: "1.0813" },
  { geneTranscript: "NM_025211.4", parsimonySitesP: "0.102914389799636", constantSitesP: "0.799635701275046", distinctSitesP: "0.186703096539162", brLenTotal: "0.3483" },
  { geneTranscript: "NM_012342.3", parsimonySitesP: "0.176923076923077", constantSitesP: "0.711538461538462", distinctSitesP: "0.264102564102564", brLenTotal: "0.7024" },
  { geneTranscript: "NM_022165.3", parsimonySitesP: "0.164251207729469", constantSitesP: "0.743961352657005", distinctSitesP: "0.254428341384863", brLenTotal: "0.6285" },
  { geneTranscript: "NM_138967.4", parsimonySitesP: "0.124822695035461", constantSitesP: "0.824113475177305", distinctSitesP: "0.174468085106383", brLenTotal: "0.5073" },
  { geneTranscript: "NM_016154.5", parsimonySitesP: "0.131455399061033", constantSitesP: "0.782472613458529", distinctSitesP: "0.200312989045383", brLenTotal: "0.5554" },
  { geneTranscript: "NM_004430.3", parsimonySitesP: "0.0801033591731266", constantSitesP: "0.861326442721792", distinctSitesP: "0.111972437553833", brLenTotal: "0.2642" },
  { geneTranscript: "NM_001382226.1", parsimonySitesP: "0.162634408602151", constantSitesP: "0.727150537634409", distinctSitesP: "0.251344086021505", brLenTotal: "0.6378" },
  { geneTranscript: "NM_033195.3", parsimonySitesP: "0.503062117235346", constantSitesP: "0.356955380577428", distinctSitesP: "0.636920384951881", brLenTotal: "2.1506" },
  { geneTranscript: "NM_198074.6", parsimonySitesP: "0.552083333333333", constantSitesP: "0.266666666666667", distinctSitesP: "0.7375", brLenTotal: "2.9072" },
  { geneTranscript: "NM_001372076.1", parsimonySitesP: "0.134897360703812", constantSitesP: "0.748778103616813", distinctSitesP: "0.208211143695015", brLenTotal: "0.5632" },
  { geneTranscript: "NM_006659.4", parsimonySitesP: "0.242054693274205", constantSitesP: "0.652254249815225", distinctSitesP: "0.301182557280118", brLenTotal: "1.0453" },
  { geneTranscript: "NM_015009.3", parsimonySitesP: "0.242651657285804", constantSitesP: "0.647279549718574", distinctSitesP: "0.266416510318949", brLenTotal: "0.9365" },
  { geneTranscript: "NM_004488.2", parsimonySitesP: "0.326190476190476", constantSitesP: "0.474404761904762", distinctSitesP: "0.477380952380952", brLenTotal: "1.1496" },
  { geneTranscript: "NM_015550.4", parsimonySitesP: "0.168733558812477", constantSitesP: "0.726042841037204", distinctSitesP: "0.23750469748215", brLenTotal: "0.6278" },
  { geneTranscript: "NM_138394.4", parsimonySitesP: "0.0885608856088561", constantSitesP: "0.822878228782288", distinctSitesP: "0.14760147601476", brLenTotal: "0.3157" },
  { geneTranscript: "NM_014361.4", parsimonySitesP: "0.171818181818182", constantSitesP: "0.708484848484848", distinctSitesP: "0.257272727272727", brLenTotal: "0.6262" },
  { geneTranscript: "NM_001013615.3", parsimonySitesP: "0.216178521617852", constantSitesP: "0.626220362622036", distinctSitesP: "0.330543933054393", brLenTotal: "0.7456" },
  { geneTranscript: "NM_138797.4", parsimonySitesP: "0.197777777777778", constantSitesP: "0.688888888888889", distinctSitesP: "0.296666666666667", brLenTotal: "0.7399" },
  { geneTranscript: "NM_020651.4", parsimonySitesP: "0.144338118022329", constantSitesP: "0.759968102073365", distinctSitesP: "0.185007974481659", brLenTotal: "0.5648" },
  { geneTranscript: "NM_001001955.2", parsimonySitesP: "0.606256742179072", constantSitesP: "0.259978425026969", distinctSitesP: "0.73462783171521", brLenTotal: "4.9812" },
  { geneTranscript: "NM_006854.4", parsimonySitesP: "0.172955974842767", constantSitesP: "0.756289308176101", distinctSitesP: "0.243710691823899", brLenTotal: "0.6789" },
  { geneTranscript: "NM_012470.4", parsimonySitesP: "0.107981220657277", constantSitesP: "0.813289996388588", distinctSitesP: "0.137233658360419", brLenTotal: "0.3407" },
  { geneTranscript: "NM_021724.5", parsimonySitesP: "0.157437567861021", constantSitesP: "0.747014115092291", distinctSitesP: "0.218241042345277", brLenTotal: "0.5991" },
  { geneTranscript: "NM_000946.3", parsimonySitesP: "0.171428571428571", constantSitesP: "0.724603174603175", distinctSitesP: "0.262698412698413", brLenTotal: "0.5123" },
  { geneTranscript: "NM_000089.4", parsimonySitesP: "0.167398731088336", constantSitesP: "0.717667154709614", distinctSitesP: "0.230356271351879", brLenTotal: "0.6363" },
  { geneTranscript: "NM_032775.4", parsimonySitesP: "0.176656151419558", constantSitesP: "0.728180862250263", distinctSitesP: "0.217665615141956", brLenTotal: "0.9227" },
  { geneTranscript: "NM_015485.5", parsimonySitesP: "0.202247191011236", constantSitesP: "0.641697877652934", distinctSitesP: "0.320848938826467", brLenTotal: "0.6952" },
  { geneTranscript: "NM_014747.3", parsimonySitesP: "0.143939393939394", constantSitesP: "0.761904761904762", distinctSitesP: "0.217532467532468", brLenTotal: "0.5963" },
  { geneTranscript: "NM_197968.4", parsimonySitesP: "0.10481723553619", constantSitesP: "0.783345436940208", distinctSitesP: "0.147421931735657", brLenTotal: "0.3904" },
  { geneTranscript: "NM_020822.3", parsimonySitesP: "0.212145748987854", constantSitesP: "0.677732793522267", distinctSitesP: "0.282051282051282", brLenTotal: "1.1450" },
  { geneTranscript: "NM_003791.4", parsimonySitesP: "0.167300380228137", constantSitesP: "0.742712294043093", distinctSitesP: "0.220532319391635", brLenTotal: "0.6641" },
  { geneTranscript: "NM_019087.3", parsimonySitesP: "0.125816993464052", constantSitesP: "0.802287581699346", distinctSitesP: "0.189542483660131", brLenTotal: "0.3378" },
  { geneTranscript: "NM_015020.3", parsimonySitesP: "0.198034769463341", constantSitesP: "0.678256487780297", distinctSitesP: "0.257999496094734", brLenTotal: "0.6924" },
  { geneTranscript: "NM_007043.7", parsimonySitesP: "0.167979002624672", constantSitesP: "0.719160104986877", distinctSitesP: "0.26159230096238", brLenTotal: "0.5300" },
  { geneTranscript: "NM_003873.7", parsimonySitesP: "0.195377392560491", constantSitesP: "0.71397616468039", distinctSitesP: "0.263994221740701", brLenTotal: "0.7186" },
  { geneTranscript: "NM_003627.6", parsimonySitesP: "0.239713774597496", constantSitesP: "0.643410852713178", distinctSitesP: "0.316040548598688", brLenTotal: "0.9180" },
  { geneTranscript: "NM_000684.3", parsimonySitesP: "0.240391334730957", constantSitesP: "0.598183088749126", distinctSitesP: "0.347309573724668", brLenTotal: "1.1362" },
  { geneTranscript: "NM_138697.4", parsimonySitesP: "0.311930241775664", constantSitesP: "0.510107015457788", distinctSitesP: "0.429647245342846", brLenTotal: "1.1419" },
  { geneTranscript: "NM_170744.5", parsimonySitesP: "0.223985890652557", constantSitesP: "0.668783068783069", distinctSitesP: "0.268430335097002", brLenTotal: "1.1073" },
  { geneTranscript: "NM_001039690.5", parsimonySitesP: "0.104683195592287", constantSitesP: "0.809917355371901", distinctSitesP: "0.201101928374656", brLenTotal: "0.2754" },
  { geneTranscript: "NM_006455.3", parsimonySitesP: "0.232646834477498", constantSitesP: "0.652173913043478", distinctSitesP: "0.292906178489703", brLenTotal: "0.8330" },
  { geneTranscript: "NM_016078.6", parsimonySitesP: "0.177235772357724", constantSitesP: "0.689430894308943", distinctSitesP: "0.313821138211382", brLenTotal: "0.5754" },
  { geneTranscript: "NM_020318.3", parsimonySitesP: "0.296482412060302", constantSitesP: "0.549972082635399", distinctSitesP: "0.384515168434766", brLenTotal: "1.1243" },
  { geneTranscript: "NM_015382.4", parsimonySitesP: "0.0978288633461047", constantSitesP: "0.827713920817369", distinctSitesP: "0.11455938697318", brLenTotal: "0.3114" },
  { geneTranscript: "NM_018383.5", parsimonySitesP: "0.119760479041916", constantSitesP: "0.794660678642715", distinctSitesP: "0.155189620758483", brLenTotal: "0.4007" },
  { geneTranscript: "NM_001025253.3", parsimonySitesP: "0.13365539452496", constantSitesP: "0.743961352657005", distinctSitesP: "0.235104669887279", brLenTotal: "0.5386" },
  { geneTranscript: "NM_004236.4", parsimonySitesP: "0.0632054176072235", constantSitesP: "0.877351392024078", distinctSitesP: "0.114371708051166", brLenTotal: "0.1788" },
  { geneTranscript: "NM_006908.5", parsimonySitesP: "0.145833333333333", constantSitesP: "0.765625", distinctSitesP: "0.239583333333333", brLenTotal: "0.5429" },
  { geneTranscript: "NM_032434.4", parsimonySitesP: "0.105232216343327", constantSitesP: "0.744268077601411", distinctSitesP: "0.199294532627866", brLenTotal: "0.4092" },
  { geneTranscript: "NM_175744.5", parsimonySitesP: "0.157167530224525", constantSitesP: "0.72020725388601", distinctSitesP: "0.250431778929188", brLenTotal: "0.6083" },
  { geneTranscript: "NM_033629.6", parsimonySitesP: "0.29723991507431", constantSitesP: "0.526539278131635", distinctSitesP: "0.402335456475584", brLenTotal: "1.1262" },
  { geneTranscript: "NM_020151.4", parsimonySitesP: "0.169369369369369", constantSitesP: "0.721621621621622", distinctSitesP: "0.254954954954955", brLenTotal: "0.4969" },
  { geneTranscript: "NM_004071.4", parsimonySitesP: "0.168732782369146", constantSitesP: "0.739669421487603", distinctSitesP: "0.227272727272727", brLenTotal: "0.5264" },
  { geneTranscript: "NM_016396.3", parsimonySitesP: "0.0708154506437768", constantSitesP: "0.863376251788269", distinctSitesP: "0.116595135908441", brLenTotal: "0.2150" },
  { geneTranscript: "NM_205855.4", parsimonySitesP: "0.260115606936416", constantSitesP: "0.58766859344894", distinctSitesP: "0.414258188824663", brLenTotal: "1.2104" },
  { geneTranscript: "NM_058004.4", parsimonySitesP: "0.169362511893435", constantSitesP: "0.735014272121789", distinctSitesP: "0.200761179828735", brLenTotal: "0.7307" },
  { geneTranscript: "NM_001362796.2", parsimonySitesP: "0.177489177489177", constantSitesP: "0.716883116883117", distinctSitesP: "0.321212121212121", brLenTotal: "0.6289" },
  { geneTranscript: "NM_016231.5", parsimonySitesP: "0.0436432637571157", constantSitesP: "0.885515496521189", distinctSitesP: "0.0936116382036686", brLenTotal: "0.1708" },
  { geneTranscript: "NM_001002918.1", parsimonySitesP: "0.540192926045016", constantSitesP: "0.320471596998928", distinctSitesP: "0.663451232583065", brLenTotal: "4.0603" },
  { geneTranscript: "NM_001370549.1", parsimonySitesP: "0.271439224459359", constantSitesP: "0.586129753914989", distinctSitesP: "0.365398956002983", brLenTotal: "0.9659" },
  { geneTranscript: "NM_002382.5", parsimonySitesP: "0.05", constantSitesP: "0.85625", distinctSitesP: "0.133333333333333", brLenTotal: "0.2160" },
  { geneTranscript: "NM_002265.6", parsimonySitesP: "0.0993150684931507", constantSitesP: "0.81544901065449", distinctSitesP: "0.14117199391172", brLenTotal: "0.3246" },
  { geneTranscript: "NM_015568.4", parsimonySitesP: "0.145208700764256", constantSitesP: "0.783656672545561", distinctSitesP: "0.189300411522634", brLenTotal: "0.5626" },
  { geneTranscript: "NM_001005281.3", parsimonySitesP: "0.231511254019293", constantSitesP: "0.608788853161844", distinctSitesP: "0.352625937834941", brLenTotal: "0.8285" },
  { geneTranscript: "NM_005765.3", parsimonySitesP: "0.151428571428571", constantSitesP: "0.712380952380952", distinctSitesP: "0.242857142857143", brLenTotal: "0.5138" },
  { geneTranscript: "NM_004257.6", parsimonySitesP: "0.201937984496124", constantSitesP: "0.681782945736434", distinctSitesP: "0.258527131782946", brLenTotal: "1.0444" },
  { geneTranscript: "NM_001098621.4", parsimonySitesP: "0.130879345603272", constantSitesP: "0.7280163599182", distinctSitesP: "0.259713701431493", brLenTotal: "0.4085" },
  { geneTranscript: "NM_001988.4", parsimonySitesP: "0.250860796851943", constantSitesP: "0.58780127889818", distinctSitesP: "0.284144941793737", brLenTotal: "1.0532" },
  { geneTranscript: "NM_002069.6", parsimonySitesP: "0.126177024482109", constantSitesP: "0.785310734463277", distinctSitesP: "0.178907721280603", brLenTotal: "0.4346" },
  { geneTranscript: "NM_020246.4", parsimonySitesP: "0.230853391684902", constantSitesP: "0.665937272064187", distinctSitesP: "0.271334792122538", brLenTotal: "1.0596" },
  { geneTranscript: "NM_021999.5", parsimonySitesP: "0.1265664160401", constantSitesP: "0.753132832080201", distinctSitesP: "0.214285714285714", brLenTotal: "0.4427" },
  { geneTranscript: "NM_001243787.2", parsimonySitesP: "0.232098765432099", constantSitesP: "0.565432098765432", distinctSitesP: "0.380246913580247", brLenTotal: "0.8344" },
  { geneTranscript: "NM_003418.5", parsimonySitesP: "0.207156308851224", constantSitesP: "0.670433145009416", distinctSitesP: "0.314500941619586", brLenTotal: "0.9127" },
  { geneTranscript: "NM_001322209.2", parsimonySitesP: "0.203096539162113", constantSitesP: "0.675774134790528", distinctSitesP: "0.301457194899818", brLenTotal: "0.6971" },
  { geneTranscript: "NM_006307.5", parsimonySitesP: "0.165948275862069", constantSitesP: "0.693965517241379", distinctSitesP: "0.272988505747126", brLenTotal: "0.5782" },
  { geneTranscript: "NM_004075.5", parsimonySitesP: "0.114903299203641", constantSitesP: "0.77929465301479", distinctSitesP: "0.179180887372014", brLenTotal: "0.3957" },
  { geneTranscript: "NM_001231.5", parsimonySitesP: "0.177609427609428", constantSitesP: "0.722222222222222", distinctSitesP: "0.251683501683502", brLenTotal: "0.6603" },
  { geneTranscript: "NM_153260.3", parsimonySitesP: "0.161785216178522", constantSitesP: "0.711297071129707", distinctSitesP: "0.266387726638773", brLenTotal: "0.4666" },
  { geneTranscript: "NM_001105579.2", parsimonySitesP: "0.208683473389356", constantSitesP: "0.65266106442577", distinctSitesP: "0.303921568627451", brLenTotal: "0.8929" },
  { geneTranscript: "NM_006590.4", parsimonySitesP: "0.16047197640118", constantSitesP: "0.71740412979351", distinctSitesP: "0.223598820058997", brLenTotal: "0.5802" },
  { geneTranscript: "NM_005560.6", parsimonySitesP: "0.31041948579161", constantSitesP: "0.539648173207037", distinctSitesP: "0.385385656292287", brLenTotal: "1.5967" },
  { geneTranscript: "NM_022157.4", parsimonySitesP: "0.151211361737678", constantSitesP: "0.755221386800334", distinctSitesP: "0.197994987468672", brLenTotal: "0.4983" },
  { geneTranscript: "NM_007074.4", parsimonySitesP: "0.201012292118583", constantSitesP: "0.715835140997831", distinctSitesP: "0.245842371655821", brLenTotal: "0.8390" },
  { geneTranscript: "NM_000146.4", parsimonySitesP: "0.238095238095238", constantSitesP: "0.544761904761905", distinctSitesP: "0.419047619047619", brLenTotal: "1.0178" },
  { geneTranscript: "NM_024821.5", parsimonySitesP: "0.200873362445415", constantSitesP: "0.665211062590975", distinctSitesP: "0.285298398835517", brLenTotal: "0.6663" },
  { geneTranscript: "NM_032842.4", parsimonySitesP: "0.161616161616162", constantSitesP: "0.717765894236482", distinctSitesP: "0.258467023172906", brLenTotal: "0.6409" },
  { geneTranscript: "NM_006628.6", parsimonySitesP: "0.12797619047619", constantSitesP: "0.720238095238095", distinctSitesP: "0.264880952380952", brLenTotal: "0.5557" },
  { geneTranscript: "NM_000620.5", parsimonySitesP: "0.205253370525337", constantSitesP: "0.694560669456067", distinctSitesP: "0.246397024639702", brLenTotal: "0.9237" },
  { geneTranscript: "NM_006429.4", parsimonySitesP: "0.194597912829957", constantSitesP: "0.638428483732351", distinctSitesP: "0.30939226519337", brLenTotal: "0.7609" },
  { geneTranscript: "NM_014604.4", parsimonySitesP: "0.112903225806452", constantSitesP: "0.798387096774194", distinctSitesP: "0.204301075268817", brLenTotal: "0.4973" },
  { geneTranscript: "NM_014666.4", parsimonySitesP: "0.0949333333333333", constantSitesP: "0.804266666666667", distinctSitesP: "0.1568", brLenTotal: "0.3300" },
  { geneTranscript: "NM_004090.4", parsimonySitesP: "0.165765765765766", constantSitesP: "0.742342342342342", distinctSitesP: "0.243243243243243", brLenTotal: "0.5511" },
  { geneTranscript: "NM_003582.4", parsimonySitesP: "0.213718820861678", constantSitesP: "0.658730158730159", distinctSitesP: "0.292517006802721", brLenTotal: "0.6515" },
  { geneTranscript: "NM_001375978.1", parsimonySitesP: "0.190395480225989", constantSitesP: "0.693785310734463", distinctSitesP: "0.259887005649718", brLenTotal: "0.8664" },
  { geneTranscript: "NM_175866.5", parsimonySitesP: "0.096260938743039", constantSitesP: "0.821797931583134", distinctSitesP: "0.155131264916468", brLenTotal: "0.3085" },
  { geneTranscript: "NM_000905.4", parsimonySitesP: "0.161512027491409", constantSitesP: "0.707903780068729", distinctSitesP: "0.309278350515464", brLenTotal: "0.4937" },
  { geneTranscript: "NM_022902.5", parsimonySitesP: "0.115468409586057", constantSitesP: "0.778213507625272", distinctSitesP: "0.178649237472767", brLenTotal: "0.3785" },
  { geneTranscript: "NM_058187.5", parsimonySitesP: "0.18442932728647", constantSitesP: "0.684807256235828", distinctSitesP: "0.270597127739985", brLenTotal: "0.6436" },
  { geneTranscript: "NM_020335.3", parsimonySitesP: "0.218809980806142", constantSitesP: "0.694177863083813", distinctSitesP: "0.26423544465771", brLenTotal: "1.0528" },
  { geneTranscript: "NM_006256.4", parsimonySitesP: "0.106368563685637", constantSitesP: "0.78760162601626", distinctSitesP: "0.177845528455285", brLenTotal: "0.3543" },
  { geneTranscript: "NM_001319236.2", parsimonySitesP: "0.162393162393162", constantSitesP: "0.695156695156695", distinctSitesP: "0.293447293447293", brLenTotal: "0.6138" },
  { geneTranscript: "NM_001289162.2", parsimonySitesP: "0.270785659801678", constantSitesP: "0.59115179252479", distinctSitesP: "0.379862700228833", brLenTotal: "1.1320" },
  { geneTranscript: "NM_000212.3", parsimonySitesP: "0.174703891708968", constantSitesP: "0.688663282571912", distinctSitesP: "0.230118443316413", brLenTotal: "0.6778" },
  { geneTranscript: "NM_015950.5", parsimonySitesP: "0.239344262295082", constantSitesP: "0.627322404371585", distinctSitesP: "0.347540983606557", brLenTotal: "0.7838" },
  { geneTranscript: "NM_002249.6", parsimonySitesP: "0.13406292749658", constantSitesP: "0.798449612403101", distinctSitesP: "0.175558595531236", brLenTotal: "0.4633" },
  { geneTranscript: "NM_012458.4", parsimonySitesP: "0.168421052631579", constantSitesP: "0.726315789473684", distinctSitesP: "0.273684210526316", brLenTotal: "0.5406" },
  { geneTranscript: "NM_005842.4", parsimonySitesP: "0.13015873015873", constantSitesP: "0.767195767195767", distinctSitesP: "0.206349206349206", brLenTotal: "0.4461" },
  { geneTranscript: "NM_018339.6", parsimonySitesP: "0.189247311827957", constantSitesP: "0.653763440860215", distinctSitesP: "0.339784946236559", brLenTotal: "0.6682" },
  { geneTranscript: "NM_024637.5", parsimonySitesP: "0.250342935528121", constantSitesP: "0.580246913580247", distinctSitesP: "0.340192043895748", brLenTotal: "0.8710" },
  { geneTranscript: "NM_001330574.2", parsimonySitesP: "0.203634861627427", constantSitesP: "0.728624535315985", distinctSitesP: "0.204460966542751", brLenTotal: "0.5591" },
  { geneTranscript: "NM_000747.3", parsimonySitesP: "0.206919494344644", constantSitesP: "0.664005322687957", distinctSitesP: "0.286094477711244", brLenTotal: "0.6565" },
  { geneTranscript: "NM_001949.5", parsimonySitesP: "0.0838709677419355", constantSitesP: "0.815770609318996", distinctSitesP: "0.162007168458781", brLenTotal: "0.2945" },
  { geneTranscript: "NM_001010980.5", parsimonySitesP: "0.241830065359477", constantSitesP: "0.640522875816993", distinctSitesP: "0.369281045751634", brLenTotal: "0.8427" },
  { geneTranscript: "NM_001297654.2", parsimonySitesP: "0.154435925520263", constantSitesP: "0.735304855786783", distinctSitesP: "0.195691858342461", brLenTotal: "0.6717" },
  { geneTranscript: "NM_080743.5", parsimonySitesP: "0.109833971902937", constantSitesP: "0.777777777777778", distinctSitesP: "0.189016602809706", brLenTotal: "0.3172" },
  { geneTranscript: "NM_013448.3", parsimonySitesP: "0.143101970865467", constantSitesP: "0.74057412167952", distinctSitesP: "0.203941730934019", brLenTotal: "0.4546" },
  { geneTranscript: "NM_004422.3", parsimonySitesP: "0.178442028985507", constantSitesP: "0.718297101449275", distinctSitesP: "0.238677536231884", brLenTotal: "0.7207" },
  { geneTranscript: "NM_001676.7", parsimonySitesP: "0.279756175810074", constantSitesP: "0.601539942252166", distinctSitesP: "0.368623676612127", brLenTotal: "1.3005" },
  { geneTranscript: "NM_001034996.3", parsimonySitesP: "0.226356589147287", constantSitesP: "0.536434108527132", distinctSitesP: "0.441860465116279", brLenTotal: "1.0036" },
  { geneTranscript: "NM_000393.5", parsimonySitesP: "0.144540804981099", constantSitesP: "0.757393818100956", distinctSitesP: "0.211919057149211", brLenTotal: "0.5005" },
  { geneTranscript: "NM_001173464.2", parsimonySitesP: "0.145758661887694", constantSitesP: "0.752688172043011", distinctSitesP: "0.189565909996018", brLenTotal: "0.4593" },
  { geneTranscript: "NM_001039199.3", parsimonySitesP: "0.176413255360624", constantSitesP: "0.725146198830409", distinctSitesP: "0.237816764132554", brLenTotal: "0.6350" },
  { geneTranscript: "NM_001321142.2", parsimonySitesP: "0.285714285714286", constantSitesP: "0.547619047619048", distinctSitesP: "0.432773109243697", brLenTotal: "1.0518" },
  { geneTranscript: "NM_000918.4", parsimonySitesP: "0.227690288713911", constantSitesP: "0.678477690288714", distinctSitesP: "0.288057742782152", brLenTotal: "0.9890" },
  { geneTranscript: "NM_001507.1", parsimonySitesP: "0.294498381877023", constantSitesP: "0.550161812297735", distinctSitesP: "0.415857605177994", brLenTotal: "1.0597" },
  { geneTranscript: "NM_005718.5", parsimonySitesP: "0.109126984126984", constantSitesP: "0.785714285714286", distinctSitesP: "0.208333333333333", brLenTotal: "0.3952" },
  { geneTranscript: "NM_001005218.3", parsimonySitesP: "0.368932038834951", constantSitesP: "0.359223300970874", distinctSitesP: "0.560949298813376", brLenTotal: "1.8581" },
  { geneTranscript: "NM_002504.6", parsimonySitesP: "0.166071428571429", constantSitesP: "0.705654761904762", distinctSitesP: "0.217559523809524", brLenTotal: "0.5251" },
  { geneTranscript: "NM_080671.4", parsimonySitesP: "0.192156862745098", constantSitesP: "0.690196078431373", distinctSitesP: "0.296078431372549", brLenTotal: "0.7679" },
  { geneTranscript: "NM_020839.4", parsimonySitesP: "0.110782865583456", constantSitesP: "0.810438207779419", distinctSitesP: "0.168882323978336", brLenTotal: "0.3945" },
  { geneTranscript: "NM_000372.5", parsimonySitesP: "0.23755513547574", constantSitesP: "0.626969124133585", distinctSitesP: "0.330812854442344", brLenTotal: "0.7951" },
  { geneTranscript: "NM_001375567.1", parsimonySitesP: "0.192485656116972", constantSitesP: "0.674069961132704", distinctSitesP: "0.287988154728854", brLenTotal: "0.6366" },
  { geneTranscript: "NM_001393586.1", parsimonySitesP: "0.29333955804544", constantSitesP: "0.552754435107376", distinctSitesP: "0.359165888577653", brLenTotal: "1.2755" },
  { geneTranscript: "NM_003825.4", parsimonySitesP: "0.169036334913112", constantSitesP: "0.707740916271722", distinctSitesP: "0.295418641390205", brLenTotal: "0.5060" },
  { geneTranscript: "NM_016578.4", parsimonySitesP: "0.163775156141568", constantSitesP: "0.715475364330326", distinctSitesP: "0.22970159611381", brLenTotal: "0.5217" },
  { geneTranscript: "NM_004204.5", parsimonySitesP: "0.286861732644865", constantSitesP: "0.580608146873207", distinctSitesP: "0.384968445209409", brLenTotal: "1.2842" },
  { geneTranscript: "NM_052906.5", parsimonySitesP: "0.190650406504065", constantSitesP: "0.703658536585366", distinctSitesP: "0.238617886178862", brLenTotal: "0.9307" },
  { geneTranscript: "NM_001321759.2", parsimonySitesP: "0.105575326215896", constantSitesP: "0.810201660735469", distinctSitesP: "0.181494661921708", brLenTotal: "0.3380" },
  { geneTranscript: "NM_000996.4", parsimonySitesP: "0.151515151515152", constantSitesP: "0.7", distinctSitesP: "0.333333333333333", brLenTotal: "0.5943" },
  { geneTranscript: "NM_002036.4", parsimonySitesP: "0.357142857142857", constantSitesP: "0.436507936507937", distinctSitesP: "0.537698412698413", brLenTotal: "1.3630" },
  { geneTranscript: "NM_016240.3", parsimonySitesP: "0.217271727172717", constantSitesP: "0.652915291529153", distinctSitesP: "0.29042904290429", brLenTotal: "0.9544" },
  { geneTranscript: "NM_018062.4", parsimonySitesP: "0.16", constantSitesP: "0.707555555555556", distinctSitesP: "0.290666666666667", brLenTotal: "0.5178" },
  { geneTranscript: "NM_006405.7", parsimonySitesP: "0.102860286028603", constantSitesP: "0.797579757975798", distinctSitesP: "0.162816281628163", brLenTotal: "0.3531" },
  { geneTranscript: "NM_001365915.1", parsimonySitesP: "0.204145077720207", constantSitesP: "0.673229706390328", distinctSitesP: "0.275647668393782", brLenTotal: "0.8090" },
  { geneTranscript: "NM_145175.4", parsimonySitesP: "0.11986301369863", constantSitesP: "0.789954337899543", distinctSitesP: "0.192922374429224", brLenTotal: "0.4048" },
  { geneTranscript: "NM_006226.4", parsimonySitesP: "0.175038051750381", constantSitesP: "0.648401826484018", distinctSitesP: "0.217047184170472", brLenTotal: "0.9082" },
  { geneTranscript: "NM_002360.4", parsimonySitesP: "0.235042735042735", constantSitesP: "0.666666666666667", distinctSitesP: "0.329059829059829", brLenTotal: "1.6242" },
  { geneTranscript: "NM_032042.6", parsimonySitesP: "0.12099358974359", constantSitesP: "0.798878205128205", distinctSitesP: "0.193910256410256", brLenTotal: "0.3668" },
  { geneTranscript: "NM_003391.3", parsimonySitesP: "0.147222222222222", constantSitesP: "0.783333333333333", distinctSitesP: "0.193518518518519", brLenTotal: "0.4798" },
  { geneTranscript: "NM_015168.2", parsimonySitesP: "0.20465592223075", constantSitesP: "0.704272192376567", distinctSitesP: "0.241493988232284", brLenTotal: "0.9707" },
  { geneTranscript: "NM_020310.3", parsimonySitesP: "0.167239404352806", constantSitesP: "0.721076746849943", distinctSitesP: "0.239977090492554", brLenTotal: "0.6827" },
  { geneTranscript: "NM_139279.6", parsimonySitesP: "0.203196347031963", constantSitesP: "0.671232876712329", distinctSitesP: "0.317351598173516", brLenTotal: "0.6524" },
  { geneTranscript: "NM_004445.6", parsimonySitesP: "0.203196347031963", constantSitesP: "0.675146771037182", distinctSitesP: "0.250489236790607", brLenTotal: "0.8861" },
  { geneTranscript: "NM_033546.4", parsimonySitesP: "0.213178294573643", constantSitesP: "0.641472868217054", distinctSitesP: "0.335271317829457", brLenTotal: "1.1674" },
  { geneTranscript: "NM_023037.3", parsimonySitesP: "0.152339860604049", constantSitesP: "0.762031198141387", distinctSitesP: "0.175351255669875", brLenTotal: "0.6053" },
  { geneTranscript: "NM_183387.3", parsimonySitesP: "0.133198448828191", constantSitesP: "0.752992749957849", distinctSitesP: "0.166919575113809", brLenTotal: "0.4876" },
  { geneTranscript: "NM_005843.6", parsimonySitesP: "0.147301587301587", constantSitesP: "0.732063492063492", distinctSitesP: "0.231746031746032", brLenTotal: "0.4733" },
  { geneTranscript: "NM_001319.7", parsimonySitesP: "0.177510040160643", constantSitesP: "0.708433734939759", distinctSitesP: "0.261044176706827", brLenTotal: "0.9563" },
  { geneTranscript: "NM_000870.7", parsimonySitesP: "0.123711340206186", constantSitesP: "0.784364261168385", distinctSitesP: "0.191580756013746", brLenTotal: "0.4178" },
  { geneTranscript: "NM_001254757.2", parsimonySitesP: "0.188188188188188", constantSitesP: "0.697697697697698", distinctSitesP: "0.266266266266266", brLenTotal: "0.8194" },
  { geneTranscript: "NM_032027.3", parsimonySitesP: "0.219001610305958", constantSitesP: "0.652173913043478", distinctSitesP: "0.365539452495974", brLenTotal: "0.7852" },
  { geneTranscript: "NM_006204.4", parsimonySitesP: "0.179098679098679", constantSitesP: "0.721833721833722", distinctSitesP: "0.257964257964258", brLenTotal: "0.6930" },
  { geneTranscript: "NM_021090.4", parsimonySitesP: "0.182526432943795", constantSitesP: "0.700333889816361", distinctSitesP: "0.240122426265999", brLenTotal: "0.6030" },
  { geneTranscript: "NM_018055.5", parsimonySitesP: "0.295869356388088", constantSitesP: "0.560999039385207", distinctSitesP: "0.375600384245917", brLenTotal: "1.0462" },
  { geneTranscript: "NM_012478.4", parsimonySitesP: "0.160919540229885", constantSitesP: "0.772669220945083", distinctSitesP: "0.209450830140485", brLenTotal: "0.5658" },
  { geneTranscript: "NM_194071.4", parsimonySitesP: "0.198717948717949", constantSitesP: "0.683974358974359", distinctSitesP: "0.269230769230769", brLenTotal: "0.7821" },
  { geneTranscript: "NM_000987.5", parsimonySitesP: "0.15632183908046", constantSitesP: "0.708045977011494", distinctSitesP: "0.273563218390805", brLenTotal: "0.5785" },
  { geneTranscript: "NM_002466.4", parsimonySitesP: "0.226666666666667", constantSitesP: "0.632380952380952", distinctSitesP: "0.33047619047619", brLenTotal: "0.9621" },
  { geneTranscript: "NM_004448.4", parsimonySitesP: "0.186188579017264", constantSitesP: "0.696945551128818", distinctSitesP: "0.22124833997344", brLenTotal: "0.7225" },
  { geneTranscript: "NM_013271.5", parsimonySitesP: "0.279487179487179", constantSitesP: "0.567948717948718", distinctSitesP: "0.382051282051282", brLenTotal: "1.0576" },
  { geneTranscript: "NM_205860.3", parsimonySitesP: "0.140480591497227", constantSitesP: "0.713493530499076", distinctSitesP: "0.224892174984596", brLenTotal: "0.5821" },
  { geneTranscript: "NM_001007228.2", parsimonySitesP: "0.10873440285205", constantSitesP: "0.79144385026738", distinctSitesP: "0.175579322638146", brLenTotal: "0.3662" },
  { geneTranscript: "NM_001300781.2", parsimonySitesP: "0.221763085399449", constantSitesP: "0.634986225895317", distinctSitesP: "0.325068870523416", brLenTotal: "0.7728" },
  { geneTranscript: "NM_001321.3", parsimonySitesP: "0.207253886010363", constantSitesP: "0.677029360967185", distinctSitesP: "0.303972366148532", brLenTotal: "0.8971" },
  { geneTranscript: "NM_005402.4", parsimonySitesP: "0.124595469255663", constantSitesP: "0.788025889967638", distinctSitesP: "0.192556634304207", brLenTotal: "0.4424" },
  { geneTranscript: "NM_015351.2", parsimonySitesP: "0.18018018018018", constantSitesP: "0.689189189189189", distinctSitesP: "0.310810810810811", brLenTotal: "0.6821" },
  { geneTranscript: "NM_016531.6", parsimonySitesP: "0.175845410628019", constantSitesP: "0.723671497584541", distinctSitesP: "0.278260869565217", brLenTotal: "0.8216" },
  { geneTranscript: "NM_001346413.3", parsimonySitesP: "0.106563859232898", constantSitesP: "0.80347963621985", distinctSitesP: "0.141162514827995", brLenTotal: "0.3380" },
  { geneTranscript: "NM_007257.6", parsimonySitesP: "0.42032967032967", constantSitesP: "0.471611721611722", distinctSitesP: "0.442307692307692", brLenTotal: "1.5587" },
  { geneTranscript: "NM_005243.4", parsimonySitesP: "0.131605691056911", constantSitesP: "0.678353658536585", distinctSitesP: "0.245426829268293", brLenTotal: "0.6244" },
  { geneTranscript: "NM_001145536.2", parsimonySitesP: "0.356140350877193", constantSitesP: "0.426315789473684", distinctSitesP: "0.568421052631579", brLenTotal: "1.2012" },
  { geneTranscript: "NM_032818.3", parsimonySitesP: "0.218905472636816", constantSitesP: "0.653731343283582", distinctSitesP: "0.304477611940299", brLenTotal: "0.6429" },
  { geneTranscript: "NM_001395507.1", parsimonySitesP: "0.21787267694741", constantSitesP: "0.63582443653618", distinctSitesP: "0.323052589956505", brLenTotal: "0.7881" },
  { geneTranscript: "NM_015274.3", parsimonySitesP: "0.297654443343244", constantSitesP: "0.559299636603898", distinctSitesP: "0.403369672943508", brLenTotal: "1.4024" },
  { geneTranscript: "NM_015336.4", parsimonySitesP: "0.0922995780590717", constantSitesP: "0.84704641350211", distinctSitesP: "0.132911392405063", brLenTotal: "0.2937" },
  { geneTranscript: "NM_012290.5", parsimonySitesP: "0.0992167101827676", constantSitesP: "0.806788511749347", distinctSitesP: "0.157093124456049", brLenTotal: "0.3451" },
  { geneTranscript: "NM_001143831.3", parsimonySitesP: "0.177667766776678", constantSitesP: "0.721672167216722", distinctSitesP: "0.235148514851485", brLenTotal: "0.6724" },
  { geneTranscript: "NM_023934.4", parsimonySitesP: "0.245149911816578", constantSitesP: "0.559082892416226", distinctSitesP: "0.412698412698413", brLenTotal: "0.9834" },
  { geneTranscript: "NM_147190.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.693877551020408", distinctSitesP: "0.260204081632653", brLenTotal: "0.5173" },
  { geneTranscript: "NM_032486.4", parsimonySitesP: "0.102564102564103", constantSitesP: "0.782051282051282", distinctSitesP: "0.201465201465201", brLenTotal: "0.3789" },
  { geneTranscript: "NM_004361.5", parsimonySitesP: "0.191507430997877", constantSitesP: "0.704033970276008", distinctSitesP: "0.250106157112527", brLenTotal: "0.8025" },
  { geneTranscript: "NM_002930.4", parsimonySitesP: "0.135176651305684", constantSitesP: "0.746543778801843", distinctSitesP: "0.251920122887865", brLenTotal: "0.4590" },
  { geneTranscript: "NM_012267.5", parsimonySitesP: "0.184772516248839", constantSitesP: "0.715877437325905", distinctSitesP: "0.254410399257196", brLenTotal: "0.8404" },
  { geneTranscript: "NM_053279.3", parsimonySitesP: "0.269470404984424", constantSitesP: "0.58411214953271", distinctSitesP: "0.43613707165109", brLenTotal: "1.2238" },
  { geneTranscript: "NM_003929.3", parsimonySitesP: "0.137931034482759", constantSitesP: "0.77175697865353", distinctSitesP: "0.216748768472906", brLenTotal: "0.4060" },
  { geneTranscript: "NM_012281.3", parsimonySitesP: "0.196825396825397", constantSitesP: "0.714814814814815", distinctSitesP: "0.241269841269841", brLenTotal: "1.0169" },
  { geneTranscript: "NM_016630.7", parsimonySitesP: "0.13961038961039", constantSitesP: "0.772727272727273", distinctSitesP: "0.20995670995671", brLenTotal: "0.4905" },
  { geneTranscript: "NM_001308319.2", parsimonySitesP: "0.139224485099528", constantSitesP: "0.762397882867334", distinctSitesP: "0.154297549188816", brLenTotal: "0.4410" },
  { geneTranscript: "NM_003405.4", parsimonySitesP: "0.241192411924119", constantSitesP: "0.685636856368564", distinctSitesP: "0.295392953929539", brLenTotal: "1.0239" },
  { geneTranscript: "NM_020381.4", parsimonySitesP: "0.138680033416876", constantSitesP: "0.729323308270677", distinctSitesP: "0.24812030075188", brLenTotal: "0.4964" },
  { geneTranscript: "NM_003010.4", parsimonySitesP: "0.0618212197159566", constantSitesP: "0.889724310776942", distinctSitesP: "0.110275689223058", brLenTotal: "0.1822" },
  { geneTranscript: "NM_020154.3", parsimonySitesP: "0.139118457300275", constantSitesP: "0.756198347107438", distinctSitesP: "0.232782369146006", brLenTotal: "0.4729" },
  { geneTranscript: "NM_001003745.2", parsimonySitesP: "0.43312101910828", constantSitesP: "0.400212314225053", distinctSitesP: "0.587048832271762", brLenTotal: "2.3267" },
  { geneTranscript: "NM_013322.3", parsimonySitesP: "0.12106135986733", constantSitesP: "0.787728026533997", distinctSitesP: "0.2106135986733", brLenTotal: "0.4705" },
  { geneTranscript: "NM_006254.4", parsimonySitesP: "0.194773175542406", constantSitesP: "0.72189349112426", distinctSitesP: "0.244575936883629", brLenTotal: "0.7657" },
  { geneTranscript: "NM_006101.3", parsimonySitesP: "0.159397715472482", constantSitesP: "0.743509865005192", distinctSitesP: "0.232606438213915", brLenTotal: "0.5236" },
  { geneTranscript: "NM_001320835.1", parsimonySitesP: "0.147177066946338", constantSitesP: "0.722426149274602", distinctSitesP: "0.18930256948086", brLenTotal: "0.5315" },
  { geneTranscript: "NM_001005473.3", parsimonySitesP: "0.117341640706127", constantSitesP: "0.805815160955348", distinctSitesP: "0.18276220145379", brLenTotal: "0.3708" },
  { geneTranscript: "NM_017990.5", parsimonySitesP: "0.172165339400834", constantSitesP: "0.726583238528631", distinctSitesP: "0.224118316268487", brLenTotal: "0.6692" },
  { geneTranscript: "NM_001046.3", parsimonySitesP: "0.172717271727173", constantSitesP: "0.731848184818482", distinctSitesP: "0.233773377337734", brLenTotal: "0.5664" },
  { geneTranscript: "NM_001394098.1", parsimonySitesP: "0.143993635640414", constantSitesP: "0.728719172633254", distinctSitesP: "0.220365950676213", brLenTotal: "0.5519" },
  { geneTranscript: "NM_001352248.3", parsimonySitesP: "0.218765432098765", constantSitesP: "0.649876543209877", distinctSitesP: "0.328888888888889", brLenTotal: "0.8341" },
  { geneTranscript: "NM_001163809.2", parsimonySitesP: "0.262579426412502", constantSitesP: "0.620127082260003", distinctSitesP: "0.282500429331959", brLenTotal: "1.0567" },
  { geneTranscript: "NM_012287.6", parsimonySitesP: "0.105826906598115", constantSitesP: "0.808483290488432", distinctSitesP: "0.157669237360754", brLenTotal: "0.3436" },
  { geneTranscript: "NM_145111.4", parsimonySitesP: "0.287376381617219", constantSitesP: "0.56020942408377", distinctSitesP: "0.39848749272833", brLenTotal: "1.1520" },
  { geneTranscript: "NM_015055.4", parsimonySitesP: "0.173789173789174", constantSitesP: "0.711111111111111", distinctSitesP: "0.238176638176638", brLenTotal: "0.5720" },
  { geneTranscript: "NM_080476.5", parsimonySitesP: "0.124904214559387", constantSitesP: "0.786973180076628", distinctSitesP: "0.196934865900383", brLenTotal: "0.3960" },
  { geneTranscript: "NM_014394.3", parsimonySitesP: "0.183574879227053", constantSitesP: "0.663768115942029", distinctSitesP: "0.294685990338164", brLenTotal: "0.6602" },
  { geneTranscript: "NM_001281740.3", parsimonySitesP: "0.199547883271681", constantSitesP: "0.677558569667078", distinctSitesP: "0.260378133990958", brLenTotal: "0.8332" },
  { geneTranscript: "NM_005341.4", parsimonySitesP: "0.188953488372093", constantSitesP: "0.698158914728682", distinctSitesP: "0.240794573643411", brLenTotal: "0.6688" },
  { geneTranscript: "NM_016169.4", parsimonySitesP: "0.140495867768595", constantSitesP: "0.753443526170799", distinctSitesP: "0.195592286501377", brLenTotal: "0.6438" },
  { geneTranscript: "NM_001253697.2", parsimonySitesP: "0.149197355996223", constantSitesP: "0.73961284230406", distinctSitesP: "0.21671388101983", brLenTotal: "0.4669" },
  { geneTranscript: "NM_147180.4", parsimonySitesP: "0.298039215686275", constantSitesP: "0.552941176470588", distinctSitesP: "0.415686274509804", brLenTotal: "1.2396" },
  { geneTranscript: "NM_030927.4", parsimonySitesP: "0.148148148148148", constantSitesP: "0.776543209876543", distinctSitesP: "0.207407407407407", brLenTotal: "0.6462" },
  { geneTranscript: "NM_016147.3", parsimonySitesP: "0.106217616580311", constantSitesP: "0.826424870466321", distinctSitesP: "0.159758203799655", brLenTotal: "0.3005" },
  { geneTranscript: "NM_153015.3", parsimonySitesP: "0.263387978142077", constantSitesP: "0.553005464480874", distinctSitesP: "0.401092896174863", brLenTotal: "0.9831" },
  { geneTranscript: "NM_015576.3", parsimonySitesP: "0.135144548937652", constantSitesP: "0.776036224312086", distinctSitesP: "0.18216649251132", brLenTotal: "0.5312" },
  { geneTranscript: "NM_020239.4", parsimonySitesP: "0.126582278481013", constantSitesP: "0.784810126582278", distinctSitesP: "0.232067510548523", brLenTotal: "0.3182" },
  { geneTranscript: "NM_000183.3", parsimonySitesP: "0.174402250351617", constantSitesP: "0.693389592123769", distinctSitesP: "0.277777777777778", brLenTotal: "0.6539" },
  { geneTranscript: "NM_001193483.3", parsimonySitesP: "0.154302670623145", constantSitesP: "0.770524233432245", distinctSitesP: "0.219584569732938", brLenTotal: "0.5736" },
  { geneTranscript: "NM_001104631.2", parsimonySitesP: "0.143798928718583", constantSitesP: "0.76967449526164", distinctSitesP: "0.19283065512979", brLenTotal: "0.5011" },
  { geneTranscript: "NM_033505.4", parsimonySitesP: "0.130142737195634", constantSitesP: "0.760705289672544", distinctSitesP: "0.237615449202351", brLenTotal: "0.4038" },
  { geneTranscript: "NM_006600.4", parsimonySitesP: "0.16515609264854", constantSitesP: "0.711983887210473", distinctSitesP: "0.244712990936556", brLenTotal: "0.5770" },
  { geneTranscript: "NM_153485.3", parsimonySitesP: "0.140905823148814", constantSitesP: "0.76659477594057", distinctSitesP: "0.188832973879703", brLenTotal: "0.4416" },
  { geneTranscript: "NM_016139.4", parsimonySitesP: "0.264900662251656", constantSitesP: "0.481236203090508", distinctSitesP: "0.543046357615894", brLenTotal: "1.2197" },
  { geneTranscript: "NM_201403.3", parsimonySitesP: "0.209876543209877", constantSitesP: "0.662037037037037", distinctSitesP: "0.313271604938272", brLenTotal: "1.0352" },
  { geneTranscript: "NM_032291.4", parsimonySitesP: "0.169082125603865", constantSitesP: "0.74194847020934", distinctSitesP: "0.226247987117552", brLenTotal: "0.5820" },
  { geneTranscript: "NM_020661.4", parsimonySitesP: "0.203703703703704", constantSitesP: "0.705387205387205", distinctSitesP: "0.30976430976431", brLenTotal: "0.7674" },
  { geneTranscript: "NM_144972.5", parsimonySitesP: "0.308232931726908", constantSitesP: "0.518072289156627", distinctSitesP: "0.450803212851406", brLenTotal: "1.3652" },
  { geneTranscript: "NM_006593.4", parsimonySitesP: "0.0703812316715543", constantSitesP: "0.873411534701857", distinctSitesP: "0.105571847507331", brLenTotal: "0.2563" },
  { geneTranscript: "NM_012437.6", parsimonySitesP: "0.176470588235294", constantSitesP: "0.708333333333333", distinctSitesP: "0.284313725490196", brLenTotal: "0.5896" },
  { geneTranscript: "NM_015110.4", parsimonySitesP: "0.145927944293067", constantSitesP: "0.74084165909779", distinctSitesP: "0.201937632455344", brLenTotal: "0.4610" },
  { geneTranscript: "NM_032014.3", parsimonySitesP: "0.263473053892216", constantSitesP: "0.57684630738523", distinctSitesP: "0.417165668662675", brLenTotal: "0.9470" },
  { geneTranscript: "NM_015473.4", parsimonySitesP: "0.167970022808732", constantSitesP: "0.684587813620072", distinctSitesP: "0.220918866080156", brLenTotal: "0.5564" },
  { geneTranscript: "NM_001709.5", parsimonySitesP: "0.116059379217274", constantSitesP: "0.798920377867746", distinctSitesP: "0.182186234817814", brLenTotal: "0.4383" },
  { geneTranscript: "NM_001318852.2", parsimonySitesP: "0.207429568686113", constantSitesP: "0.709299426576913", distinctSitesP: "0.239591124407878", brLenTotal: "1.0210" },
  { geneTranscript: "NM_032389.6", parsimonySitesP: "0.161868202175304", constantSitesP: "0.72040946896993", distinctSitesP: "0.242482405630198", brLenTotal: "0.5837" },
  { geneTranscript: "NM_052950.4", parsimonySitesP: "0.1275", constantSitesP: "0.78", distinctSitesP: "0.215833333333333", brLenTotal: "0.4402" },
  { geneTranscript: "NM_020144.5", parsimonySitesP: "0.2145473574045", constantSitesP: "0.64887493458922", distinctSitesP: "0.306122448979592", brLenTotal: "0.7744" },
  { geneTranscript: "NM_014780.5", parsimonySitesP: "0.253239104829211", constantSitesP: "0.612485276796231", distinctSitesP: "0.283078131134668", brLenTotal: "0.9631" },
  { geneTranscript: "NM_001302551.2", parsimonySitesP: "0.582480091012514", constantSitesP: "0.167235494880546", distinctSitesP: "0.825938566552901", brLenTotal: "2.5751" },
  { geneTranscript: "NM_018048.5", parsimonySitesP: "0.171171171171171", constantSitesP: "0.68018018018018", distinctSitesP: "0.315315315315315", brLenTotal: "0.5998" },
  { geneTranscript: "NM_020145.4", parsimonySitesP: "0.155274261603376", constantSitesP: "0.744303797468354", distinctSitesP: "0.226160337552743", brLenTotal: "0.6323" },
  { geneTranscript: "NM_013245.3", parsimonySitesP: "0.168573607932876", constantSitesP: "0.750572082379863", distinctSitesP: "0.22044241037376", brLenTotal: "0.6888" },
  { geneTranscript: "NM_006639.4", parsimonySitesP: "0.188921859545005", constantSitesP: "0.685459940652819", distinctSitesP: "0.300692383778437", brLenTotal: "0.5642" },
  { geneTranscript: "NM_003476.5", parsimonySitesP: "0.166666666666667", constantSitesP: "0.756013745704467", distinctSitesP: "0.25085910652921", brLenTotal: "0.7285" },
  { geneTranscript: "NM_001991.5", parsimonySitesP: "0.114680946006247", constantSitesP: "0.78937974118697", distinctSitesP: "0.157965194109772", brLenTotal: "0.3715" },
  { geneTranscript: "NM_002734.5", parsimonySitesP: "0.141732283464567", constantSitesP: "0.745406824146982", distinctSitesP: "0.231846019247594", brLenTotal: "0.4765" },
  { geneTranscript: "NM_001007189.2", parsimonySitesP: "0.050314465408805", constantSitesP: "0.930817610062893", distinctSitesP: "0.0943396226415094", brLenTotal: "0.0918" },
  { geneTranscript: "NM_000326.5", parsimonySitesP: "0.205047318611987", constantSitesP: "0.695057833859096", distinctSitesP: "0.290220820189274", brLenTotal: "0.7844" },
  { geneTranscript: "NM_007214.5", parsimonySitesP: "0.117543859649123", constantSitesP: "0.793421052631579", distinctSitesP: "0.171929824561404", brLenTotal: "0.3709" },
  { geneTranscript: "NM_019003.5", parsimonySitesP: "0.378552971576227", constantSitesP: "0.515503875968992", distinctSitesP: "0.437984496124031", brLenTotal: "1.1423" },
  { geneTranscript: "NM_000743.5", parsimonySitesP: "0.22970297029703", constantSitesP: "0.613861386138614", distinctSitesP: "0.342574257425743", brLenTotal: "1.1838" },
  { geneTranscript: "NM_003677.5", parsimonySitesP: "0.116161616161616", constantSitesP: "0.745791245791246", distinctSitesP: "0.235690235690236", brLenTotal: "0.4684" },
  { geneTranscript: "NM_018137.3", parsimonySitesP: "0.229333333333333", constantSitesP: "0.566222222222222", distinctSitesP: "0.351111111111111", brLenTotal: "0.8929" },
  { geneTranscript: "NM_007217.4", parsimonySitesP: "0.0927672955974843", constantSitesP: "0.773584905660377", distinctSitesP: "0.218553459119497", brLenTotal: "0.4290" },
  { geneTranscript: "NM_001130111.2", parsimonySitesP: "0.213978494623656", constantSitesP: "0.675268817204301", distinctSitesP: "0.291397849462366", brLenTotal: "1.0583" },
  { geneTranscript: "NM_001036.6", parsimonySitesP: "0.17501711156742", constantSitesP: "0.715263518138261", distinctSitesP: "0.190896646132786", brLenTotal: "0.6840" },
  { geneTranscript: "NM_178438.5", parsimonySitesP: "0.426553672316384", constantSitesP: "0.313559322033898", distinctSitesP: "0.72316384180791", brLenTotal: "2.9236" },
  { geneTranscript: "NM_012330.4", parsimonySitesP: "0.112236694002251", constantSitesP: "0.800450233156456", distinctSitesP: "0.153722463418556", brLenTotal: "0.5200" },
  { geneTranscript: "NM_025058.5", parsimonySitesP: "0.146684233640755", constantSitesP: "0.770311813790075", distinctSitesP: "0.183574879227053", brLenTotal: "0.6016" },
  { geneTranscript: "NM_001500.4", parsimonySitesP: "0.155017921146953", constantSitesP: "0.748207885304659", distinctSitesP: "0.25", brLenTotal: "0.5470" },
  { geneTranscript: "NM_001261434.2", parsimonySitesP: "0.182038834951456", constantSitesP: "0.677993527508091", distinctSitesP: "0.292071197411003", brLenTotal: "0.6580" },
  { geneTranscript: "NM_005386.4", parsimonySitesP: "0.0987654320987654", constantSitesP: "0.847736625514403", distinctSitesP: "0.160493827160494", brLenTotal: "0.2583" },
  { geneTranscript: "NM_020211.3", parsimonySitesP: "0.173333333333333", constantSitesP: "0.72962962962963", distinctSitesP: "0.246666666666667", brLenTotal: "0.9554" },
  { geneTranscript: "NM_001080404.3", parsimonySitesP: "0.483992467043315", constantSitesP: "0.378531073446328", distinctSitesP: "0.602636534839925", brLenTotal: "2.3718" },
  { geneTranscript: "NM_001013845.2", parsimonySitesP: "0.29746835443038", constantSitesP: "0.4957805907173", distinctSitesP: "0.5042194092827", brLenTotal: "1.1028" },
  { geneTranscript: "NM_144585.4", parsimonySitesP: "0.328511151295961", constantSitesP: "0.494876431585292", distinctSitesP: "0.458107293550332", brLenTotal: "1.5429" },
  { geneTranscript: "NM_014448.4", parsimonySitesP: "0.287729196050776", constantSitesP: "0.593794076163611", distinctSitesP: "0.372825575928538", brLenTotal: "1.2904" },
  { geneTranscript: "NM_000426.4", parsimonySitesP: "0.201259876147769", constantSitesP: "0.664104206705104", distinctSitesP: "0.258915225282938", brLenTotal: "0.7376" },
  { geneTranscript: "NM_015534.6", parsimonySitesP: "0.150978220745663", constantSitesP: "0.718346253229974", distinctSitesP: "0.201550387596899", brLenTotal: "0.5100" },
  { geneTranscript: "NM_001195545.2", parsimonySitesP: "0.250909090909091", constantSitesP: "0.568484848484848", distinctSitesP: "0.393939393939394", brLenTotal: "1.0285" },
  { geneTranscript: "NM_022457.7", parsimonySitesP: "0.116735066119471", constantSitesP: "0.790697674418605", distinctSitesP: "0.180574555403557", brLenTotal: "0.3756" },
  { geneTranscript: "NM_032118.4", parsimonySitesP: "0.216566866267465", constantSitesP: "0.63373253493014", distinctSitesP: "0.300399201596806", brLenTotal: "0.7165" },
  { geneTranscript: "NM_002030.5", parsimonySitesP: "0.500472143531634", constantSitesP: "0.342776203966006", distinctSitesP: "0.643059490084986", brLenTotal: "2.3406" },
  { geneTranscript: "NM_006688.5", parsimonySitesP: "0.186046511627907", constantSitesP: "0.713178294573643", distinctSitesP: "0.218346253229974", brLenTotal: "0.7855" },
  { geneTranscript: "NM_002503.5", parsimonySitesP: "0.250936329588015", constantSitesP: "0.602996254681648", distinctSitesP: "0.370786516853933", brLenTotal: "1.0558" },
  { geneTranscript: "NM_005609.4", parsimonySitesP: "0.188440221694378", constantSitesP: "0.730403800475059", distinctSitesP: "0.214568487727633", brLenTotal: "0.8073" },
  { geneTranscript: "NM_015302.2", parsimonySitesP: "0.293312269615587", constantSitesP: "0.535018430753028", distinctSitesP: "0.395471300684571", brLenTotal: "1.0400" },
  { geneTranscript: "NM_153611.6", parsimonySitesP: "0.242424242424242", constantSitesP: "0.608815426997245", distinctSitesP: "0.377410468319559", brLenTotal: "0.9122" },
  { geneTranscript: "NM_001174096.2", parsimonySitesP: "0.171555555555556", constantSitesP: "0.727111111111111", distinctSitesP: "0.230518518518519", brLenTotal: "0.5870" },
  { geneTranscript: "NM_002087.4", parsimonySitesP: "0.283305227655987", constantSitesP: "0.55593029792018", distinctSitesP: "0.36424957841484", brLenTotal: "1.0692" },
  { geneTranscript: "NM_020367.6", parsimonySitesP: "0.105522682445759", constantSitesP: "0.78698224852071", distinctSitesP: "0.183431952662722", brLenTotal: "0.4103" },
  { geneTranscript: "NM_014983.3", parsimonySitesP: "0.176986584107327", constantSitesP: "0.682146542827657", distinctSitesP: "0.233488132094943", brLenTotal: "0.6050" },
  { geneTranscript: "NM_133444.3", parsimonySitesP: "0.228855721393035", constantSitesP: "0.639800995024876", distinctSitesP: "0.303980099502488", brLenTotal: "0.8925" },
  { geneTranscript: "NM_001164375.3", parsimonySitesP: "0.315789473684211", constantSitesP: "0.491228070175439", distinctSitesP: "0.511278195488722", brLenTotal: "1.5090" },
  { geneTranscript: "NM_002699.4", parsimonySitesP: "0.158906134515891", constantSitesP: "0.777531411677753", distinctSitesP: "0.227642276422764", brLenTotal: "0.6549" },
  { geneTranscript: "NM_005155.7", parsimonySitesP: "0.139072847682119", constantSitesP: "0.746136865342163", distinctSitesP: "0.21523178807947", brLenTotal: "0.5053" },
  { geneTranscript: "NM_007118.4", parsimonySitesP: "0.167689161554192", constantSitesP: "0.744806802281778", distinctSitesP: "0.195888494241739", brLenTotal: "0.7202" },
  { geneTranscript: "NM_018203.3", parsimonySitesP: "0.173333333333333", constantSitesP: "0.72952380952381", distinctSitesP: "0.239047619047619", brLenTotal: "0.6652" },
  { geneTranscript: "NM_001017979.3", parsimonySitesP: "0.131221719457014", constantSitesP: "0.730015082956259", distinctSitesP: "0.232277526395173", brLenTotal: "0.4408" },
  { geneTranscript: "NM_017837.4", parsimonySitesP: "0.217038539553753", constantSitesP: "0.619337390128465", distinctSitesP: "0.310344827586207", brLenTotal: "0.7283" },
  { geneTranscript: "NM_001005284.2", parsimonySitesP: "0.398504273504274", constantSitesP: "0.454059829059829", distinctSitesP: "0.503205128205128", brLenTotal: "1.6538" },
  { geneTranscript: "NM_001386991.1", parsimonySitesP: "0.181132075471698", constantSitesP: "0.737735849056604", distinctSitesP: "0.257232704402516", brLenTotal: "1.0053" },
  { geneTranscript: "NM_004690.4", parsimonySitesP: "0.123893805309735", constantSitesP: "0.771091445427729", distinctSitesP: "0.166961651917404", brLenTotal: "0.4033" },
  { geneTranscript: "NM_001195427.2", parsimonySitesP: "0.184012066365008", constantSitesP: "0.701357466063348", distinctSitesP: "0.277526395173454", brLenTotal: "0.7128" },
  { geneTranscript: "NM_001548.5", parsimonySitesP: "0.49163179916318", constantSitesP: "0.369595536959554", distinctSitesP: "0.596931659693166", brLenTotal: "2.2277" },
  { geneTranscript: "NM_002263.4", parsimonySitesP: "0.266468548786528", constantSitesP: "0.58048538880634", distinctSitesP: "0.369489846458643", brLenTotal: "0.8565" },
  { geneTranscript: "NM_006855.4", parsimonySitesP: "0.21183800623053", constantSitesP: "0.713395638629283", distinctSitesP: "0.269470404984424", brLenTotal: "0.6761" },
  { geneTranscript: "NM_019048.4", parsimonySitesP: "0.244686365992742", constantSitesP: "0.557283566614826", distinctSitesP: "0.4116122343183", brLenTotal: "0.8827" },
  { geneTranscript: "NM_015241.3", parsimonySitesP: "0.238594738594739", constantSitesP: "0.63969363969364", distinctSitesP: "0.291208791208791", brLenTotal: "1.0134" },
  { geneTranscript: "NM_020982.4", parsimonySitesP: "0.316436251920123", constantSitesP: "0.572964669738863", distinctSitesP: "0.391705069124424", brLenTotal: "1.6425" },
  { geneTranscript: "NM_003764.4", parsimonySitesP: "0.18815331010453", constantSitesP: "0.70383275261324", distinctSitesP: "0.267131242740999", brLenTotal: "0.5599" },
  { geneTranscript: "NM_033116.6", parsimonySitesP: "0.12836227442969", constantSitesP: "0.772216547497446", distinctSitesP: "0.175008512087164", brLenTotal: "0.4297" },
  { geneTranscript: "NM_006379.5", parsimonySitesP: "0.169995561473591", constantSitesP: "0.731469152241456", distinctSitesP: "0.225033288948069", brLenTotal: "0.5433" },
  { geneTranscript: "NM_020980.5", parsimonySitesP: "0.271186440677966", constantSitesP: "0.613559322033898", distinctSitesP: "0.386440677966102", brLenTotal: "0.9431" },
  { geneTranscript: "NM_004309.6", parsimonySitesP: "0.197712418300654", constantSitesP: "0.679738562091503", distinctSitesP: "0.303921568627451", brLenTotal: "0.8901" },
  { geneTranscript: "NM_002790.4", parsimonySitesP: "0.0926694329183956", constantSitesP: "0.838174273858921", distinctSitesP: "0.152143845089903", brLenTotal: "0.2662" },
  { geneTranscript: "NM_001003897.2", parsimonySitesP: "0.192156862745098", constantSitesP: "0.670588235294118", distinctSitesP: "0.329411764705882", brLenTotal: "0.6536" },
  { geneTranscript: "NM_001005489.2", parsimonySitesP: "0.508492569002123", constantSitesP: "0.349256900212314", distinctSitesP: "0.633757961783439", brLenTotal: "2.5977" },
  { geneTranscript: "NM_001267594.2", parsimonySitesP: "0.138198757763975", constantSitesP: "0.74120082815735", distinctSitesP: "0.226708074534161", brLenTotal: "0.4430" },
  { geneTranscript: "NM_017575.5", parsimonySitesP: "0.175005872680291", constantSitesP: "0.702842377260982", distinctSitesP: "0.213765562602772", brLenTotal: "0.5664" },
  { geneTranscript: "NM_022734.3", parsimonySitesP: "0.256578947368421", constantSitesP: "0.58625730994152", distinctSitesP: "0.402777777777778", brLenTotal: "0.8622" },
  { geneTranscript: "NM_000044.6", parsimonySitesP: "0.20036231884058", constantSitesP: "0.665579710144928", distinctSitesP: "0.292028985507246", brLenTotal: "0.6761" },
  { geneTranscript: "NM_001303457.2", parsimonySitesP: "0.212733394551576", constantSitesP: "0.63116008570554", distinctSitesP: "0.284664830119376", brLenTotal: "0.7569" },
  { geneTranscript: "NM_024028.4", parsimonySitesP: "0.192982456140351", constantSitesP: "0.688259109311741", distinctSitesP: "0.263832658569501", brLenTotal: "0.8556" },
  { geneTranscript: "NM_032352.4", parsimonySitesP: "0.0887512899896801", constantSitesP: "0.834881320949432", distinctSitesP: "0.158926728586171", brLenTotal: "0.2702" },
  { geneTranscript: "NM_002886.4", parsimonySitesP: "0.12568306010929", constantSitesP: "0.717668488160291", distinctSitesP: "0.255009107468124", brLenTotal: "0.5297" },
  { geneTranscript: "NM_002562.6", parsimonySitesP: "0.228571428571429", constantSitesP: "0.643137254901961", distinctSitesP: "0.322689075630252", brLenTotal: "0.8532" },
  { geneTranscript: "NM_152945.4", parsimonySitesP: "0.120956399437412", constantSitesP: "0.758087201125176", distinctSitesP: "0.191983122362869", brLenTotal: "0.3904" },
  { geneTranscript: "NM_005748.6", parsimonySitesP: "0.0722222222222222", constantSitesP: "0.866666666666667", distinctSitesP: "0.135185185185185", brLenTotal: "0.2149" },
  { geneTranscript: "NM_001378120.1", parsimonySitesP: "0.0604130476741942", constantSitesP: "0.859100559737502", distinctSitesP: "0.0957344142057518", brLenTotal: "0.2111" },
  { geneTranscript: "NM_012469.4", parsimonySitesP: "0.188452001416932", constantSitesP: "0.728303223521077", distinctSitesP: "0.228480340063762", brLenTotal: "0.8915" },
  { geneTranscript: "NM_001928.4", parsimonySitesP: "0.30171277997365", constantSitesP: "0.519104084321476", distinctSitesP: "0.455862977602108", brLenTotal: "1.3250" },
  { geneTranscript: "NM_015886.5", parsimonySitesP: "0.15374677002584", constantSitesP: "0.735142118863049", distinctSitesP: "0.250645994832041", brLenTotal: "0.5055" },
  { geneTranscript: "NM_020896.4", parsimonySitesP: "0.262798634812287", constantSitesP: "0.584755403868032", distinctSitesP: "0.354948805460751", brLenTotal: "1.2992" },
  { geneTranscript: "NM_003516.3", parsimonySitesP: "0.251282051282051", constantSitesP: "0.697435897435897", distinctSitesP: "0.305128205128205", brLenTotal: "2.1532" },
  { geneTranscript: "NM_001029886.3", parsimonySitesP: "0.265206812652068", constantSitesP: "0.600973236009732", distinctSitesP: "0.367396593673966", brLenTotal: "0.9602" },
  { geneTranscript: "NM_005144.5", parsimonySitesP: "0.276422764227642", constantSitesP: "0.545556490047659", distinctSitesP: "0.351275581721334", brLenTotal: "1.0828" },
  { geneTranscript: "NM_178428.4", parsimonySitesP: "0.424528301886792", constantSitesP: "0.276729559748428", distinctSitesP: "0.748427672955975", brLenTotal: "2.8742" },
  { geneTranscript: "NM_020365.5", parsimonySitesP: "0.164454277286136", constantSitesP: "0.682153392330383", distinctSitesP: "0.280973451327434", brLenTotal: "0.5747" },
  { geneTranscript: "NM_016587.4", parsimonySitesP: "0.132969034608379", constantSitesP: "0.70856102003643", distinctSitesP: "0.264116575591985", brLenTotal: "0.5493" },
  { geneTranscript: "NM_021117.5", parsimonySitesP: "0.15177065767285", constantSitesP: "0.74761101742552", distinctSitesP: "0.205733558178752", brLenTotal: "0.6000" },
  { geneTranscript: "NM_152263.4", parsimonySitesP: "0.102923976608187", constantSitesP: "0.791812865497076", distinctSitesP: "0.19766081871345", brLenTotal: "0.3492" },
  { geneTranscript: "NM_001098426.2", parsimonySitesP: "0.148775894538606", constantSitesP: "0.712492153170119", distinctSitesP: "0.215317011927181", brLenTotal: "0.6394" },
  { geneTranscript: "NM_016199.3", parsimonySitesP: "0.177993527508091", constantSitesP: "0.757281553398058", distinctSitesP: "0.252427184466019", brLenTotal: "0.6566" },
  { geneTranscript: "NM_007166.4", parsimonySitesP: "0.070040899795501", constantSitesP: "0.864519427402863", distinctSitesP: "0.122699386503067", brLenTotal: "0.2182" },
  { geneTranscript: "NM_001364791.2", parsimonySitesP: "0.216766867067468", constantSitesP: "0.65998663994656", distinctSitesP: "0.316633266533066", brLenTotal: "0.9361" },
  { geneTranscript: "NM_024528.4", parsimonySitesP: "0.181526104417671", constantSitesP: "0.686746987951807", distinctSitesP: "0.293172690763052", brLenTotal: "0.5643" },
  { geneTranscript: "NM_004554.5", parsimonySitesP: "0.151515151515152", constantSitesP: "0.711751662971175", distinctSitesP: "0.214708056171471", brLenTotal: "0.6214" },
  { geneTranscript: "NM_001318234.2", parsimonySitesP: "0.187732342007435", constantSitesP: "0.711276332094176", distinctSitesP: "0.248451053283767", brLenTotal: "0.9038" },
  { geneTranscript: "NM_020738.4", parsimonySitesP: "0.196687370600414", constantSitesP: "0.710521362695276", distinctSitesP: "0.244682853378506", brLenTotal: "0.8057" },
  { geneTranscript: "NM_138357.3", parsimonySitesP: "0.118708452041785", constantSitesP: "0.798670465337132", distinctSitesP: "0.192782526115859", brLenTotal: "0.3667" },
  { geneTranscript: "NM_002833.4", parsimonySitesP: "0.137717818999438", constantSitesP: "0.744238336143901", distinctSitesP: "0.208544125913435", brLenTotal: "0.4643" },
  { geneTranscript: "NM_000980.4", parsimonySitesP: "0.229166666666667", constantSitesP: "0.623106060606061", distinctSitesP: "0.365530303030303", brLenTotal: "1.0691" },
  { geneTranscript: "NM_004977.3", parsimonySitesP: "0.169969176574196", constantSitesP: "0.731395860854249", distinctSitesP: "0.262439453985029", brLenTotal: "0.8993" },
  { geneTranscript: "NM_004892.6", parsimonySitesP: "0.114728682170543", constantSitesP: "0.781395348837209", distinctSitesP: "0.203100775193798", brLenTotal: "0.3632" },
  { geneTranscript: "NM_001321120.2", parsimonySitesP: "0.178876678876679", constantSitesP: "0.725885225885226", distinctSitesP: "0.247252747252747", brLenTotal: "0.8026" },
  { geneTranscript: "NM_001798.5", parsimonySitesP: "0.124161073825503", constantSitesP: "0.788590604026846", distinctSitesP: "0.175615212527964", brLenTotal: "0.4235" },
  { geneTranscript: "NM_001384711.1", parsimonySitesP: "0.163323782234957", constantSitesP: "0.748806112702961", distinctSitesP: "0.240687679083095", brLenTotal: "0.6518" },
  { geneTranscript: "NM_018206.6", parsimonySitesP: "0.135678391959799", constantSitesP: "0.724036850921273", distinctSitesP: "0.213986599664992", brLenTotal: "0.4875" },
  { geneTranscript: "NM_033504.4", parsimonySitesP: "0.24024024024024", constantSitesP: "0.575075075075075", distinctSitesP: "0.415915915915916", brLenTotal: "0.9908" },
  { geneTranscript: "NM_001098531.4", parsimonySitesP: "0.196460816179126", constantSitesP: "0.671722643553629", distinctSitesP: "0.266522210184182", brLenTotal: "0.7775" },
  { geneTranscript: "NM_153274.3", parsimonySitesP: "0.22692036645525", constantSitesP: "0.651162790697674", distinctSitesP: "0.303030303030303", brLenTotal: "0.8426" },
  { geneTranscript: "NM_005921.2", parsimonySitesP: "0.149029982363316", constantSitesP: "0.749118165784832", distinctSitesP: "0.19973544973545", brLenTotal: "0.4874" },
  { geneTranscript: "NM_133497.4", parsimonySitesP: "0.242201834862385", constantSitesP: "0.636697247706422", distinctSitesP: "0.332110091743119", brLenTotal: "1.0005" },
  { geneTranscript: "NM_003337.4", parsimonySitesP: "0.0745614035087719", constantSitesP: "0.861842105263158", distinctSitesP: "0.138157894736842", brLenTotal: "0.2204" },
  { geneTranscript: "NM_002314.4", parsimonySitesP: "0.183925811437403", constantSitesP: "0.722823286965482", distinctSitesP: "0.248325605358063", brLenTotal: "0.8752" },
  { geneTranscript: "NM_020348.3", parsimonySitesP: "0.202593760953382", constantSitesP: "0.673676831405538", distinctSitesP: "0.264984227129338", brLenTotal: "0.7668" },
  { geneTranscript: "NM_001892.6", parsimonySitesP: "0.107814045499505", constantSitesP: "0.786350148367953", distinctSitesP: "0.18595450049456", brLenTotal: "0.4038" },
  { geneTranscript: "NM_019094.6", parsimonySitesP: "0.15", constantSitesP: "0.727777777777778", distinctSitesP: "0.25", brLenTotal: "0.7742" },
  { geneTranscript: "NM_001031685.3", parsimonySitesP: "0.222810111699001", constantSitesP: "0.655202821869489", distinctSitesP: "0.288359788359788", brLenTotal: "0.8004" },
  { geneTranscript: "NM_001001794.4", parsimonySitesP: "0.219373219373219", constantSitesP: "0.661538461538462", distinctSitesP: "0.32022792022792", brLenTotal: "0.9749" },
  { geneTranscript: "NM_001321209.2", parsimonySitesP: "0.0740740740740741", constantSitesP: "0.835016835016835", distinctSitesP: "0.185185185185185", brLenTotal: "0.2559" },
  { geneTranscript: "NM_013434.5", parsimonySitesP: "0.18359375", constantSitesP: "0.729166666666667", distinctSitesP: "0.272135416666667", brLenTotal: "0.6965" },
  { geneTranscript: "NM_005875.3", parsimonySitesP: "0.0383480825958702", constantSitesP: "0.890855457227139", distinctSitesP: "0.115044247787611", brLenTotal: "0.1596" },
  { geneTranscript: "NM_005766.4", parsimonySitesP: "0.187240829346093", constantSitesP: "0.703030303030303", distinctSitesP: "0.268580542264753", brLenTotal: "0.7603" },
  { geneTranscript: "NM_001384647.1", parsimonySitesP: "0.175141242937853", constantSitesP: "0.669491525423729", distinctSitesP: "0.338983050847458", brLenTotal: "0.7556" },
  { geneTranscript: "NM_004586.3", parsimonySitesP: "0.0644144144144144", constantSitesP: "0.850900900900901", distinctSitesP: "0.109009009009009", brLenTotal: "0.2545" },
  { geneTranscript: "NM_080660.4", parsimonySitesP: "0.203333333333333", constantSitesP: "0.651111111111111", distinctSitesP: "0.332222222222222", brLenTotal: "0.7349" },
  { geneTranscript: "NM_032242.4", parsimonySitesP: "0.295886075949367", constantSitesP: "0.607594936708861", distinctSitesP: "0.32331223628692", brLenTotal: "1.6068" },
  { geneTranscript: "NM_004584.3", parsimonySitesP: "0.234441602728048", constantSitesP: "0.61381074168798", distinctSitesP: "0.352941176470588", brLenTotal: "0.9144" },
  { geneTranscript: "NM_020750.3", parsimonySitesP: "0.15531561461794", constantSitesP: "0.749169435215947", distinctSitesP: "0.196013289036545", brLenTotal: "0.4667" },
  { geneTranscript: "NM_014462.3", parsimonySitesP: "0.132832080200501", constantSitesP: "0.701754385964912", distinctSitesP: "0.268170426065163", brLenTotal: "0.5149" },
  { geneTranscript: "NM_032299.4", parsimonySitesP: "0.0618846694796062", constantSitesP: "0.877637130801688", distinctSitesP: "0.115330520393812", brLenTotal: "0.2021" },
  { geneTranscript: "NM_212558.3", parsimonySitesP: "0.188652482269504", constantSitesP: "0.71063829787234", distinctSitesP: "0.26241134751773", brLenTotal: "0.5267" },
  { geneTranscript: "NM_001364886.1", parsimonySitesP: "0.12996632996633", constantSitesP: "0.802693602693603", distinctSitesP: "0.206060606060606", brLenTotal: "0.5000" },
  { geneTranscript: "NM_032116.5", parsimonySitesP: "0.141496598639456", constantSitesP: "0.719727891156463", distinctSitesP: "0.208163265306122", brLenTotal: "0.5720" },
  { geneTranscript: "NM_205767.3", parsimonySitesP: "0.228813559322034", constantSitesP: "0.632768361581921", distinctSitesP: "0.378531073446328", brLenTotal: "0.8356" },
  { geneTranscript: "NM_152745.3", parsimonySitesP: "0.0885608856088561", constantSitesP: "0.830258302583026", distinctSitesP: "0.151291512915129", brLenTotal: "0.2662" },
  { geneTranscript: "NM_018397.5", parsimonySitesP: "0.259259259259259", constantSitesP: "0.635802469135802", distinctSitesP: "0.326599326599327", brLenTotal: "1.1716" },
  { geneTranscript: "NM_018240.7", parsimonySitesP: "0.16776750330251", constantSitesP: "0.750330250990753", distinctSitesP: "0.21180096873624", brLenTotal: "0.6912" },
  { geneTranscript: "NM_032557.6", parsimonySitesP: "0.142354446577095", constantSitesP: "0.735124760076775", distinctSitesP: "0.206973768394114", brLenTotal: "0.4670" },
  { geneTranscript: "NM_005625.4", parsimonySitesP: "0.136465324384787", constantSitesP: "0.69910514541387", distinctSitesP: "0.293064876957494", brLenTotal: "0.5121" },
  { geneTranscript: "NM_173658.4", parsimonySitesP: "0.249748237663646", constantSitesP: "0.507552870090634", distinctSitesP: "0.342396777442095", brLenTotal: "1.2935" },
  { geneTranscript: "NM_001087.5", parsimonySitesP: "0.147465437788018", constantSitesP: "0.7642089093702", distinctSitesP: "0.201996927803379", brLenTotal: "0.5101" },
  { geneTranscript: "NM_023077.3", parsimonySitesP: "0.25974025974026", constantSitesP: "0.574314574314574", distinctSitesP: "0.365079365079365", brLenTotal: "0.7853" },
  { geneTranscript: "NM_005549.2", parsimonySitesP: "0.302674494455316", constantSitesP: "0.572733202870189", distinctSitesP: "0.340508806262231", brLenTotal: "1.1491" },
  { geneTranscript: "NM_007275.3", parsimonySitesP: "0.160606060606061", constantSitesP: "0.724242424242424", distinctSitesP: "0.272727272727273", brLenTotal: "0.4325" },
  { geneTranscript: "NM_005121.3", parsimonySitesP: "0.101349279362159", constantSitesP: "0.812174179699479", distinctSitesP: "0.126801594602883", brLenTotal: "0.3113" },
  { geneTranscript: "NM_030662.4", parsimonySitesP: "0.218333333333333", constantSitesP: "0.700833333333333", distinctSitesP: "0.2975", brLenTotal: "1.1553" },
  { geneTranscript: "NM_018056.4", parsimonySitesP: "0.132791327913279", constantSitesP: "0.764227642276423", distinctSitesP: "0.203929539295393", brLenTotal: "0.6019" },
  { geneTranscript: "NM_016223.5", parsimonySitesP: "0.205974842767296", constantSitesP: "0.669811320754717", distinctSitesP: "0.283018867924528", brLenTotal: "0.9645" },
  { geneTranscript: "NM_003972.3", parsimonySitesP: "0.115918514512349", constantSitesP: "0.790877952046151", distinctSitesP: "0.146926266450334", brLenTotal: "0.3803" },
  { geneTranscript: "NM_003779.4", parsimonySitesP: "0.132315521628499", constantSitesP: "0.765055131467345", distinctSitesP: "0.170483460559796", brLenTotal: "0.4249" },
  { geneTranscript: "NM_006161.3", parsimonySitesP: "0.241912798874824", constantSitesP: "0.609001406469761", distinctSitesP: "0.360056258790436", brLenTotal: "0.8714" },
  { geneTranscript: "NM_007146.3", parsimonySitesP: "0.0831733845169546", constantSitesP: "0.791426743442099", distinctSitesP: "0.187460012795905", brLenTotal: "0.3924" },
  { geneTranscript: "NM_001355221.1", parsimonySitesP: "0.325034578146611", constantSitesP: "0.49792531120332", distinctSitesP: "0.496542185338866", brLenTotal: "1.8256" },
  { geneTranscript: "NM_006319.5", parsimonySitesP: "0.179968701095462", constantSitesP: "0.697965571205008", distinctSitesP: "0.267605633802817", brLenTotal: "0.5981" },
  { geneTranscript: "NM_032704.5", parsimonySitesP: "0.277654046028211", constantSitesP: "0.532293986636971", distinctSitesP: "0.416481069042316", brLenTotal: "2.0724" },
  { geneTranscript: "NM_012424.6", parsimonySitesP: "0.151969981238274", constantSitesP: "0.720762976860538", distinctSitesP: "0.228580362726704", brLenTotal: "0.4937" },
  { geneTranscript: "NM_080746.3", parsimonySitesP: "0.264797507788162", constantSitesP: "0.496884735202492", distinctSitesP: "0.403426791277259", brLenTotal: "1.5294" },
  { geneTranscript: "NM_138376.3", parsimonySitesP: "0.184090909090909", constantSitesP: "0.698484848484848", distinctSitesP: "0.264393939393939", brLenTotal: "0.5503" },
  { geneTranscript: "NM_022093.2", parsimonySitesP: "0.300487554529125", constantSitesP: "0.540928919681807", distinctSitesP: "0.373107518604054", brLenTotal: "1.5613" },
  { geneTranscript: "NM_001127715.4", parsimonySitesP: "0.126846220677672", constantSitesP: "0.78945844193455", distinctSitesP: "0.167390674775557", brLenTotal: "0.4032" },
  { geneTranscript: "NM_053035.3", parsimonySitesP: "0.235849056603774", constantSitesP: "0.566037735849057", distinctSitesP: "0.468553459119497", brLenTotal: "0.8811" },
  { geneTranscript: "NM_001018025.4", parsimonySitesP: "0.0467289719626168", constantSitesP: "0.881619937694704", distinctSitesP: "0.121495327102804", brLenTotal: "0.1744" },
  { geneTranscript: "NM_001031727.4", parsimonySitesP: "0.283649503161698", constantSitesP: "0.538392050587173", distinctSitesP: "0.398373983739837", brLenTotal: "1.3529" },
  { geneTranscript: "NM_022845.3", parsimonySitesP: "0.0980392156862745", constantSitesP: "0.827094474153298", distinctSitesP: "0.165775401069519", brLenTotal: "0.2555" },
  { geneTranscript: "NM_001376256.1", parsimonySitesP: "0.179405520169851", constantSitesP: "0.71656050955414", distinctSitesP: "0.250530785562633", brLenTotal: "0.5665" },
  { geneTranscript: "NM_001395976.1", parsimonySitesP: "0.160919540229885", constantSitesP: "0.737274220032841", distinctSitesP: "0.244663382594417", brLenTotal: "0.6426" },
  { geneTranscript: "NM_018346.3", parsimonySitesP: "0.269230769230769", constantSitesP: "0.571644042232278", distinctSitesP: "0.380844645550528", brLenTotal: "0.9799" },
  { geneTranscript: "NM_001185181.3", parsimonySitesP: "0.13953488372093", constantSitesP: "0.718346253229974", distinctSitesP: "0.242894056847545", brLenTotal: "0.4352" },
  { geneTranscript: "NM_153646.4", parsimonySitesP: "0.213290460878885", constantSitesP: "0.673097534833869", distinctSitesP: "0.278670953912111", brLenTotal: "1.0339" },
  { geneTranscript: "NM_001146182.2", parsimonySitesP: "0.33397807865893", constantSitesP: "0.508059316569955", distinctSitesP: "0.471953578336557", brLenTotal: "1.3382" },
  { geneTranscript: "NM_001172509.2", parsimonySitesP: "0.0932241928149159", constantSitesP: "0.849931787175989", distinctSitesP: "0.125966348340155", brLenTotal: "0.3396" },
  { geneTranscript: "NM_001288705.3", parsimonySitesP: "0.278463648834019", constantSitesP: "0.56721536351166", distinctSitesP: "0.37517146776406", brLenTotal: "1.1177" },
  { geneTranscript: "NM_001006.5", parsimonySitesP: "0.14520202020202", constantSitesP: "0.683080808080808", distinctSitesP: "0.285353535353535", brLenTotal: "0.5557" },
  { geneTranscript: "NM_003285.3", parsimonySitesP: "0.190967108492882", constantSitesP: "0.697839960726559", distinctSitesP: "0.229749631811487", brLenTotal: "0.7667" },
  { geneTranscript: "NM_020631.6", parsimonySitesP: "0.243870112657389", constantSitesP: "0.628893306825712", distinctSitesP: "0.309476474486415", brLenTotal: "0.9611" },
  { geneTranscript: "NM_001174089.2", parsimonySitesP: "0.229333333333333", constantSitesP: "0.651047619047619", distinctSitesP: "0.303238095238095", brLenTotal: "1.0528" },
  { geneTranscript: "NM_002568.4", parsimonySitesP: "0.127882599580713", constantSitesP: "0.740566037735849", distinctSitesP: "0.210691823899371", brLenTotal: "0.5251" },
  { geneTranscript: "NM_020644.3", parsimonySitesP: "0.12962962962963", constantSitesP: "0.771043771043771", distinctSitesP: "0.215488215488215", brLenTotal: "0.4097" },
  { geneTranscript: "NM_002272.4", parsimonySitesP: "0.258333333333333", constantSitesP: "0.597435897435897", distinctSitesP: "0.372435897435897", brLenTotal: "1.2177" },
  { geneTranscript: "NM_002599.5", parsimonySitesP: "0.16400991852639", constantSitesP: "0.739284449167552", distinctSitesP: "0.206517888770811", brLenTotal: "0.5694" },
  { geneTranscript: "NM_138435.4", parsimonySitesP: "0.258666666666667", constantSitesP: "0.578666666666667", distinctSitesP: "0.392", brLenTotal: "0.9795" },
  { geneTranscript: "NM_015916.5", parsimonySitesP: "0.203302373581011", constantSitesP: "0.666666666666667", distinctSitesP: "0.263157894736842", brLenTotal: "1.3129" },
  { geneTranscript: "NM_001203.3", parsimonySitesP: "0.206507304116866", constantSitesP: "0.696547144754316", distinctSitesP: "0.289508632138114", brLenTotal: "0.8368" },
  { geneTranscript: "NM_001375462.1", parsimonySitesP: "0.217320261437908", constantSitesP: "0.664488017429194", distinctSitesP: "0.293028322440087", brLenTotal: "0.7517" },
  { geneTranscript: "NM_016516.3", parsimonySitesP: "0.0890481064483112", constantSitesP: "0.835551006482429", distinctSitesP: "0.138178096212897", brLenTotal: "0.2681" },
  { geneTranscript: "NM_144564.5", parsimonySitesP: "0.260084925690021", constantSitesP: "0.628450106157113", distinctSitesP: "0.328025477707006", brLenTotal: "1.0703" },
  { geneTranscript: "NM_014310.4", parsimonySitesP: "0.233082706766917", constantSitesP: "0.700501253132832", distinctSitesP: "0.266917293233083", brLenTotal: "0.7514" },
  { geneTranscript: "NM_080603.5", parsimonySitesP: "0.239175257731959", constantSitesP: "0.599312714776632", distinctSitesP: "0.332646048109966", brLenTotal: "0.8280" },
  { geneTranscript: "NM_002563.5", parsimonySitesP: "0.160857908847185", constantSitesP: "0.744414655942806", distinctSitesP: "0.221626452189455", brLenTotal: "0.5317" },
  { geneTranscript: "NM_017888.3", parsimonySitesP: "0.277489925158319", constantSitesP: "0.583189407023604", distinctSitesP: "0.401842256764537", brLenTotal: "1.0185" },
  { geneTranscript: "NM_014674.3", parsimonySitesP: "0.182141045154744", constantSitesP: "0.702181633688483", distinctSitesP: "0.284119736174531", brLenTotal: "0.6530" },
  { geneTranscript: "NM_144580.3", parsimonySitesP: "0.263546798029557", constantSitesP: "0.5623973727422", distinctSitesP: "0.376847290640394", brLenTotal: "0.9860" },
  { geneTranscript: "NM_000214.3", parsimonySitesP: "0.146141215106732", constantSitesP: "0.765736179529283", distinctSitesP: "0.180623973727422", brLenTotal: "0.5808" },
  { geneTranscript: "NM_001105562.3", parsimonySitesP: "0.14900153609831", constantSitesP: "0.763952892985151", distinctSitesP: "0.187403993855607", brLenTotal: "0.5327" },
  { geneTranscript: "NM_021222.3", parsimonySitesP: "0.203090507726269", constantSitesP: "0.640912435614422", distinctSitesP: "0.281089036055923", brLenTotal: "0.6666" },
  { geneTranscript: "NM_001385026.1", parsimonySitesP: "0.218785796105384", constantSitesP: "0.638793432607866", distinctSitesP: "0.26536846124475", brLenTotal: "0.7583" },
  { geneTranscript: "NM_012368.3", parsimonySitesP: "0.634615384615385", constantSitesP: "0.245726495726496", distinctSitesP: "0.763888888888889", brLenTotal: "3.5114" },
  { geneTranscript: "NM_019597.5", parsimonySitesP: "0.22271714922049", constantSitesP: "0.568671121009651", distinctSitesP: "0.357089829250186", brLenTotal: "1.1214" },
  { geneTranscript: "NM_002523.3", parsimonySitesP: "0.22969837587007", constantSitesP: "0.646558391337974", distinctSitesP: "0.306264501160093", brLenTotal: "0.9402" },
  { geneTranscript: "NM_001321739.2", parsimonySitesP: "0.245911949685535", constantSitesP: "0.589937106918239", distinctSitesP: "0.346540880503145", brLenTotal: "0.8138" },
  { geneTranscript: "NM_139280.4", parsimonySitesP: "0.200435729847495", constantSitesP: "0.736383442265795", distinctSitesP: "0.26797385620915", brLenTotal: "0.7165" },
  { geneTranscript: "NM_004817.4", parsimonySitesP: "0.205602240896359", constantSitesP: "0.668347338935574", distinctSitesP: "0.293557422969188", brLenTotal: "0.7939" },
  { geneTranscript: "NM_001369216.1", parsimonySitesP: "0.301075268817204", constantSitesP: "0.46594982078853", distinctSitesP: "0.566308243727599", brLenTotal: "1.5790" },
  { geneTranscript: "NM_006041.3", parsimonySitesP: "0.252991452991453", constantSitesP: "0.578632478632479", distinctSitesP: "0.34017094017094", brLenTotal: "1.1019" },
  { geneTranscript: "NM_003794.4", parsimonySitesP: "0.159259259259259", constantSitesP: "0.724444444444444", distinctSitesP: "0.255555555555556", brLenTotal: "0.5112" },
  { geneTranscript: "NM_009590.4", parsimonySitesP: "0.330246913580247", constantSitesP: "0.489858906525573", distinctSitesP: "0.371693121693122", brLenTotal: "1.2332" },
  { geneTranscript: "NM_012284.3", parsimonySitesP: "0.169898430286242", constantSitesP: "0.726069559864574", distinctSitesP: "0.199138196368113", brLenTotal: "0.7119" },
  { geneTranscript: "NM_016121.5", parsimonySitesP: "0.161963190184049", constantSitesP: "0.735378323108384", distinctSitesP: "0.232310838445808", brLenTotal: "0.5697" },
  { geneTranscript: "NM_016074.5", parsimonySitesP: "0.328467153284672", constantSitesP: "0.542579075425791", distinctSitesP: "0.423357664233577", brLenTotal: "1.0423" },
  { geneTranscript: "NM_016484.5", parsimonySitesP: "0.107142857142857", constantSitesP: "0.776190476190476", distinctSitesP: "0.221428571428571", brLenTotal: "0.3058" },
  { geneTranscript: "NM_019069.4", parsimonySitesP: "0.174747474747475", constantSitesP: "0.687878787878788", distinctSitesP: "0.27979797979798", brLenTotal: "0.5651" },
  { geneTranscript: "NM_000762.6", parsimonySitesP: "0.282726045883941", constantSitesP: "0.533738191632928", distinctSitesP: "0.401484480431849", brLenTotal: "1.2053" },
  { geneTranscript: "NM_030958.3", parsimonySitesP: "0.21187106918239", constantSitesP: "0.686713836477987", distinctSitesP: "0.287342767295597", brLenTotal: "0.7889" },
  { geneTranscript: "NM_003363.4", parsimonySitesP: "0.193492557978539", constantSitesP: "0.675320179993077", distinctSitesP: "0.257874697127034", brLenTotal: "0.6615" },
  { geneTranscript: "NM_019059.5", parsimonySitesP: "0.145454545454545", constantSitesP: "0.721212121212121", distinctSitesP: "0.315151515151515", brLenTotal: "0.5116" },
  { geneTranscript: "NM_002390.6", parsimonySitesP: "0.163849154746424", constantSitesP: "0.731686172518422", distinctSitesP: "0.208929345470308", brLenTotal: "0.6274" },
  { geneTranscript: "NM_024941.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.803357314148681", distinctSitesP: "0.173461231015188", brLenTotal: "0.3817" },
  { geneTranscript: "NM_001142447.3", parsimonySitesP: "0.15219421101774", constantSitesP: "0.742296918767507", distinctSitesP: "0.236227824463119", brLenTotal: "0.4749" },
  { geneTranscript: "NM_001556.3", parsimonySitesP: "0.183862433862434", constantSitesP: "0.722222222222222", distinctSitesP: "0.257495590828924", brLenTotal: "0.8108" },
  { geneTranscript: "NM_000955.3", parsimonySitesP: "0.26865671641791", constantSitesP: "0.611111111111111", distinctSitesP: "0.340796019900498", brLenTotal: "0.9512" },
  { geneTranscript: "NM_012473.4", parsimonySitesP: "0.22289156626506", constantSitesP: "0.596385542168675", distinctSitesP: "0.36144578313253", brLenTotal: "0.8305" },
  { geneTranscript: "NM_005445.4", parsimonySitesP: "0.101068200493016", constantSitesP: "0.814845247877294", distinctSitesP: "0.139687756778965", brLenTotal: "0.3288" },
  { geneTranscript: "NM_004538.6", parsimonySitesP: "0.304347826086957", constantSitesP: "0.479578392621871", distinctSitesP: "0.496706192358366", brLenTotal: "1.2075" },
  { geneTranscript: "NM_004231.4", parsimonySitesP: "0.156862745098039", constantSitesP: "0.722689075630252", distinctSitesP: "0.266106442577031", brLenTotal: "0.7230" },
  { geneTranscript: "NM_144979.5", parsimonySitesP: "0.126328955597248", constantSitesP: "0.719824890556598", distinctSitesP: "0.218886804252658", brLenTotal: "0.4804" },
  { geneTranscript: "NM_004898.4", parsimonySitesP: "0.149724192277384", constantSitesP: "0.752167060677699", distinctSitesP: "0.227738376674547", brLenTotal: "0.5927" },
  { geneTranscript: "NM_001135556.2", parsimonySitesP: "0.123673036093418", constantSitesP: "0.784501061571125", distinctSitesP: "0.176751592356688", brLenTotal: "0.6021" },
  { geneTranscript: "NM_012426.5", parsimonySitesP: "0.146535195836757", constantSitesP: "0.774856203779786", distinctSitesP: "0.179950698438784", brLenTotal: "0.5107" },
  { geneTranscript: "NM_020416.4", parsimonySitesP: "0.170768083519761", constantSitesP: "0.747949291573453", distinctSitesP: "0.223713646532438", brLenTotal: "0.9145" },
  { geneTranscript: "NM_018031.6", parsimonySitesP: "0.210526315789474", constantSitesP: "0.647933392804044", distinctSitesP: "0.249776984834969", brLenTotal: "0.7565" },
  { geneTranscript: "NM_022098.4", parsimonySitesP: "0.189349112426036", constantSitesP: "0.680473372781065", distinctSitesP: "0.280078895463511", brLenTotal: "0.6134" },
  { geneTranscript: "NM_001145319.2", parsimonySitesP: "0.126656067832538", constantSitesP: "0.76682564917859", distinctSitesP: "0.180710121886592", brLenTotal: "0.4102" },
  { geneTranscript: "NM_014352.4", parsimonySitesP: "0.157492354740061", constantSitesP: "0.724770642201835", distinctSitesP: "0.247706422018349", brLenTotal: "0.6184" },
  { geneTranscript: "NM_021029.6", parsimonySitesP: "0.141509433962264", constantSitesP: "0.685534591194969", distinctSitesP: "0.345911949685535", brLenTotal: "0.5111" },
  { geneTranscript: "NM_020919.4", parsimonySitesP: "0.16193924763629", constantSitesP: "0.72118286059143", distinctSitesP: "0.215650774492054", brLenTotal: "0.5869" },
  { geneTranscript: "NM_001366386.2", parsimonySitesP: "0.163984674329502", constantSitesP: "0.696551724137931", distinctSitesP: "0.267432950191571", brLenTotal: "0.6000" },
  { geneTranscript: "NM_015204.3", parsimonySitesP: "0.189096761215047", constantSitesP: "0.685777509555421", distinctSitesP: "0.251458459062563", brLenTotal: "0.7369" },
  { geneTranscript: "NM_002736.3", parsimonySitesP: "0.117224880382775", constantSitesP: "0.790271132376396", distinctSitesP: "0.189792663476874", brLenTotal: "0.3617" },
  { geneTranscript: "NM_152699.5", parsimonySitesP: "0.179690949227373", constantSitesP: "0.685651214128035", distinctSitesP: "0.262251655629139", brLenTotal: "0.5327" },
  { geneTranscript: "NM_153214.3", parsimonySitesP: "0.189066059225513", constantSitesP: "0.663629460895976", distinctSitesP: "0.296127562642369", brLenTotal: "0.7678" },
  { geneTranscript: "NM_001031690.3", parsimonySitesP: "0.169444444444444", constantSitesP: "0.744444444444444", distinctSitesP: "0.231481481481481", brLenTotal: "0.5809" },
  { geneTranscript: "NM_000335.5", parsimonySitesP: "0.231927212572374", constantSitesP: "0.651943755169562", distinctSitesP: "0.263854425144748", brLenTotal: "1.2278" },
  { geneTranscript: "NM_004932.4", parsimonySitesP: "0.158227848101266", constantSitesP: "0.738818565400844", distinctSitesP: "0.218565400843882", brLenTotal: "0.6321" },
  { geneTranscript: "NM_015433.3", parsimonySitesP: "0.162241887905605", constantSitesP: "0.710914454277286", distinctSitesP: "0.253687315634218", brLenTotal: "0.4842" },
  { geneTranscript: "NM_020404.3", parsimonySitesP: "0.273007485689124", constantSitesP: "0.582122413033906", distinctSitesP: "0.362835755173932", brLenTotal: "1.0192" },
  { geneTranscript: "NM_021252.5", parsimonySitesP: "0.0970873786407767", constantSitesP: "0.800970873786408", distinctSitesP: "0.182847896440129", brLenTotal: "0.3556" },
  { geneTranscript: "NM_030920.5", parsimonySitesP: "0.138059701492537", constantSitesP: "0.743781094527363", distinctSitesP: "0.278606965174129", brLenTotal: "0.4988" },
  { geneTranscript: "NM_001277285.4", parsimonySitesP: "0.146369751797727", constantSitesP: "0.772674553467873", distinctSitesP: "0.17907678032939", brLenTotal: "0.6649" },
  { geneTranscript: "NM_145331.3", parsimonySitesP: "0.0973597359735974", constantSitesP: "0.825632563256326", distinctSitesP: "0.143564356435644", brLenTotal: "0.3068" },
  { geneTranscript: "NM_004808.3", parsimonySitesP: "0.230923694779116", constantSitesP: "0.660642570281124", distinctSitesP: "0.296519410977242", brLenTotal: "1.0530" },
  { geneTranscript: "NM_030753.5", parsimonySitesP: "0.148356807511737", constantSitesP: "0.753051643192488", distinctSitesP: "0.216901408450704", brLenTotal: "0.7745" },
  { geneTranscript: "NM_000336.3", parsimonySitesP: "0.230208333333333", constantSitesP: "0.638541666666667", distinctSitesP: "0.340104166666667", brLenTotal: "1.0221" },
  { geneTranscript: "NM_001393986.1", parsimonySitesP: "0.204695382227396", constantSitesP: "0.670741171905316", distinctSitesP: "0.265618936748157", brLenTotal: "0.7578" },
  { geneTranscript: "NM_006480.5", parsimonySitesP: "0.229093050647821", constantSitesP: "0.638987043580683", distinctSitesP: "0.303886925795053", brLenTotal: "0.7986" },
  { geneTranscript: "NM_001395966.1", parsimonySitesP: "0.426470588235294", constantSitesP: "0.326470588235294", distinctSitesP: "0.673529411764706", brLenTotal: "1.7080" },
  { geneTranscript: "NM_004211.5", parsimonySitesP: "0.154328732747804", constantSitesP: "0.74362191551652", distinctSitesP: "0.216227519866165", brLenTotal: "0.5924" },
  { geneTranscript: "NM_020648.6", parsimonySitesP: "0.155455904334828", constantSitesP: "0.742899850523169", distinctSitesP: "0.243647234678625", brLenTotal: "0.5718" },
  { geneTranscript: "NM_016221.4", parsimonySitesP: "0.133333333333333", constantSitesP: "0.764492753623188", distinctSitesP: "0.192753623188406", brLenTotal: "0.4753" },
  { geneTranscript: "NM_000430.4", parsimonySitesP: "0.0943089430894309", constantSitesP: "0.82520325203252", distinctSitesP: "0.157723577235772", brLenTotal: "0.3003" },
  { geneTranscript: "NM_001240.4", parsimonySitesP: "0.154269972451791", constantSitesP: "0.742883379247016", distinctSitesP: "0.208448117539027", brLenTotal: "0.4539" },
  { geneTranscript: "NM_001128431.4", parsimonySitesP: "0.214769647696477", constantSitesP: "0.665311653116531", distinctSitesP: "0.314363143631436", brLenTotal: "0.9923" },
  { geneTranscript: "NM_001172681.2", parsimonySitesP: "0.148584905660377", constantSitesP: "0.731132075471698", distinctSitesP: "0.211477987421384", brLenTotal: "0.4759" },
  { geneTranscript: "NM_024713.3", parsimonySitesP: "0.143640350877193", constantSitesP: "0.6875", distinctSitesP: "0.285087719298246", brLenTotal: "0.5278" },
  { geneTranscript: "NM_022337.3", parsimonySitesP: "0.153238546603476", constantSitesP: "0.748815165876777", distinctSitesP: "0.23696682464455", brLenTotal: "0.5411" },
  { geneTranscript: "NM_002609.4", parsimonySitesP: "0.207956600361664", constantSitesP: "0.665762507534659", distinctSitesP: "0.281796262808921", brLenTotal: "0.9078" },
  { geneTranscript: "NM_005801.4", parsimonySitesP: "0.129793510324484", constantSitesP: "0.781710914454277", distinctSitesP: "0.215339233038348", brLenTotal: "0.5033" },
  { geneTranscript: "NM_001288702.2", parsimonySitesP: "0.372745490981964", constantSitesP: "0.430861723446894", distinctSitesP: "0.53373413493654", brLenTotal: "1.5565" },
  { geneTranscript: "NM_001005486.2", parsimonySitesP: "0.432098765432099", constantSitesP: "0.407407407407407", distinctSitesP: "0.540123456790123", brLenTotal: "2.0719" },
  { geneTranscript: "NM_001330449.2", parsimonySitesP: "0.212713936430318", constantSitesP: "0.661776691116544", distinctSitesP: "0.287693561532192", brLenTotal: "0.9928" },
  { geneTranscript: "NM_000045.4", parsimonySitesP: "0.20703933747412", constantSitesP: "0.667701863354037", distinctSitesP: "0.319875776397516", brLenTotal: "0.6962" },
  { geneTranscript: "NM_002531.3", parsimonySitesP: "0.245614035087719", constantSitesP: "0.625996810207337", distinctSitesP: "0.358054226475279", brLenTotal: "1.1932" },
  { geneTranscript: "NM_015939.5", parsimonySitesP: "0.185781354795439", constantSitesP: "0.692823608316566", distinctSitesP: "0.28169014084507", brLenTotal: "0.6011" },
  { geneTranscript: "NM_006703.4", parsimonySitesP: "0.122093023255814", constantSitesP: "0.786821705426357", distinctSitesP: "0.209302325581395", brLenTotal: "0.4028" },
  { geneTranscript: "NM_001256706.2", parsimonySitesP: "0.106306306306306", constantSitesP: "0.783783783783784", distinctSitesP: "0.203603603603604", brLenTotal: "0.3522" },
  { geneTranscript: "NM_001002847.4", parsimonySitesP: "0.270356234096692", constantSitesP: "0.526717557251908", distinctSitesP: "0.428753180661578", brLenTotal: "0.9491" },
  { geneTranscript: "NM_001199140.2", parsimonySitesP: "0.0752688172043011", constantSitesP: "0.870967741935484", distinctSitesP: "0.117204301075269", brLenTotal: "0.2215" },
  { geneTranscript: "NM_003040.4", parsimonySitesP: "0.203062046736503", constantSitesP: "0.690840719849584", distinctSitesP: "0.244157937147462", brLenTotal: "0.9193" },
  { geneTranscript: "NM_004341.5", parsimonySitesP: "0.188614232209738", constantSitesP: "0.705168539325843", distinctSitesP: "0.195505617977528", brLenTotal: "0.7382" },
  { geneTranscript: "NM_005430.4", parsimonySitesP: "0.145045045045045", constantSitesP: "0.767567567567568", distinctSitesP: "0.197297297297297", brLenTotal: "0.5129" },
  { geneTranscript: "NM_001387287.1", parsimonySitesP: "0.170542635658915", constantSitesP: "0.703488372093023", distinctSitesP: "0.271317829457364", brLenTotal: "0.4884" },
  { geneTranscript: "NM_002442.4", parsimonySitesP: "0.0930018416206262", constantSitesP: "0.86279926335175", distinctSitesP: "0.136279926335175", brLenTotal: "0.4921" },
  { geneTranscript: "NM_006520.3", parsimonySitesP: "0.146551724137931", constantSitesP: "0.758620689655172", distinctSitesP: "0.270114942528736", brLenTotal: "0.5355" },
  { geneTranscript: "NM_002357.4", parsimonySitesP: "0.116138763197587", constantSitesP: "0.812971342383107", distinctSitesP: "0.170437405731523", brLenTotal: "0.5506" },
  { geneTranscript: "NM_001303.4", parsimonySitesP: "0.249811888638074", constantSitesP: "0.580135440180587", distinctSitesP: "0.36719337848006", brLenTotal: "0.9300" },
  { geneTranscript: "NM_015994.4", parsimonySitesP: "0.105263157894737", constantSitesP: "0.804318488529015", distinctSitesP: "0.190283400809717", brLenTotal: "0.3311" },
  { geneTranscript: "NM_024963.6", parsimonySitesP: "0.212163416898793", constantSitesP: "0.667130919220056", distinctSitesP: "0.270659238625812", brLenTotal: "1.0647" },
  { geneTranscript: "NM_006134.7", parsimonySitesP: "0.105485232067511", constantSitesP: "0.79535864978903", distinctSitesP: "0.213080168776371", brLenTotal: "0.3853" },
  { geneTranscript: "NM_021977.4", parsimonySitesP: "0.185851318944844", constantSitesP: "0.695443645083933", distinctSitesP: "0.284772182254197", brLenTotal: "0.6698" },
  { geneTranscript: "NM_014491.4", parsimonySitesP: "0.0703962703962704", constantSitesP: "0.851748251748252", distinctSitesP: "0.144522144522145", brLenTotal: "0.3182" },
  { geneTranscript: "NM_017552.4", parsimonySitesP: "0.107224508459076", constantSitesP: "0.802926383173297", distinctSitesP: "0.134887974394147", brLenTotal: "0.3358" },
  { geneTranscript: "NM_001128302.3", parsimonySitesP: "0.163934426229508", constantSitesP: "0.73224043715847", distinctSitesP: "0.275956284153005", brLenTotal: "0.4920" },
  { geneTranscript: "NM_006978.3", parsimonySitesP: "0.266277939747328", constantSitesP: "0.541302235179786", distinctSitesP: "0.365403304178814", brLenTotal: "0.9325" },
  { geneTranscript: "NM_000035.4", parsimonySitesP: "0.23992673992674", constantSitesP: "0.65018315018315", distinctSitesP: "0.316849816849817", brLenTotal: "0.8510" },
  { geneTranscript: "NM_152529.7", parsimonySitesP: "0.159770114942529", constantSitesP: "0.721072796934866", distinctSitesP: "0.227969348659004", brLenTotal: "0.5213" },
  { geneTranscript: "NM_001017969.3", parsimonySitesP: "0.188777936281503", constantSitesP: "0.641781581867174", distinctSitesP: "0.27119987319702", brLenTotal: "0.6436" },
  { geneTranscript: "NM_033643.3", parsimonySitesP: "0.253968253968254", constantSitesP: "0.580952380952381", distinctSitesP: "0.40952380952381", brLenTotal: "1.0148" },
  { geneTranscript: "NM_020141.4", parsimonySitesP: "0.0720720720720721", constantSitesP: "0.86036036036036", distinctSitesP: "0.166666666666667", brLenTotal: "0.2199" },
  { geneTranscript: "NM_019014.6", parsimonySitesP: "0.172393538913363", constantSitesP: "0.703964757709251", distinctSitesP: "0.254919236417034", brLenTotal: "0.6261" },
  { geneTranscript: "NM_001130003.2", parsimonySitesP: "0.162573099415205", constantSitesP: "0.748538011695906", distinctSitesP: "0.273684210526316", brLenTotal: "0.5795" },
  { geneTranscript: "NM_016492.5", parsimonySitesP: "0.184587813620072", constantSitesP: "0.655913978494624", distinctSitesP: "0.315412186379928", brLenTotal: "0.6426" },
  { geneTranscript: "NM_018590.5", parsimonySitesP: "0.129766297662977", constantSitesP: "0.754612546125461", distinctSitesP: "0.194956949569496", brLenTotal: "0.4587" },
  { geneTranscript: "NM_005187.6", parsimonySitesP: "0.298111281265952", constantSitesP: "0.551812149055641", distinctSitesP: "0.428279734558448", brLenTotal: "1.6639" },
  { geneTranscript: "NM_005724.6", parsimonySitesP: "0.137022397891963", constantSitesP: "0.764163372859025", distinctSitesP: "0.216073781291173", brLenTotal: "0.6069" },
  { geneTranscript: "NM_012243.3", parsimonySitesP: "0.136410256410256", constantSitesP: "0.774358974358974", distinctSitesP: "0.213333333333333", brLenTotal: "0.4124" },
  { geneTranscript: "NM_020803.5", parsimonySitesP: "0.175806451612903", constantSitesP: "0.729569892473118", distinctSitesP: "0.241935483870968", brLenTotal: "0.6152" },
  { geneTranscript: "NM_012134.3", parsimonySitesP: "0.233888888888889", constantSitesP: "0.635", distinctSitesP: "0.318888888888889", brLenTotal: "0.9339" },
  { geneTranscript: "NM_001394165.1", parsimonySitesP: "0.203921568627451", constantSitesP: "0.654901960784314", distinctSitesP: "0.388235294117647", brLenTotal: "0.8224" },
  { geneTranscript: "NM_002144.4", parsimonySitesP: "0.2281284606866", constantSitesP: "0.623477297895903", distinctSitesP: "0.3421926910299", brLenTotal: "0.7831" },
  { geneTranscript: "NM_152896.3", parsimonySitesP: "0.129260182876143", constantSitesP: "0.770157938487116", distinctSitesP: "0.194929343308396", brLenTotal: "0.4032" },
  { geneTranscript: "NM_014686.5", parsimonySitesP: "0.18816199376947", constantSitesP: "0.702492211838006", distinctSitesP: "0.228660436137072", brLenTotal: "0.6658" },
  { geneTranscript: "NM_178568.4", parsimonySitesP: "0.208616780045351", constantSitesP: "0.686318972033258", distinctSitesP: "0.26681783824641", brLenTotal: "0.8555" },
  { geneTranscript: "NM_003244.4", parsimonySitesP: "0.183823529411765", constantSitesP: "0.659313725490196", distinctSitesP: "0.299019607843137", brLenTotal: "0.7259" },
  { geneTranscript: "NM_032549.4", parsimonySitesP: "0.163809523809524", constantSitesP: "0.740952380952381", distinctSitesP: "0.262857142857143", brLenTotal: "0.5046" },
  { geneTranscript: "NM_005157.6", parsimonySitesP: "0.214749262536873", constantSitesP: "0.660766961651917", distinctSitesP: "0.261946902654867", brLenTotal: "1.0197" },
  { geneTranscript: "NM_001145862.2", parsimonySitesP: "0.205923836389281", constantSitesP: "0.628114715561824", distinctSitesP: "0.306535025858016", brLenTotal: "0.6466" },
  { geneTranscript: "NM_003284.4", parsimonySitesP: "0.212121212121212", constantSitesP: "0.587878787878788", distinctSitesP: "0.424242424242424", brLenTotal: "0.6681" },
  { geneTranscript: "NM_178471.3", parsimonySitesP: "0.237810945273632", constantSitesP: "0.609950248756219", distinctSitesP: "0.321393034825871", brLenTotal: "0.7226" },
  { geneTranscript: "NM_014689.3", parsimonySitesP: "0.1741384568466", constantSitesP: "0.723391277828606", distinctSitesP: "0.232540408661177", brLenTotal: "0.6681" },
  { geneTranscript: "NM_153257.5", parsimonySitesP: "0.387211367673179", constantSitesP: "0.411486086441681", distinctSitesP: "0.545293072824156", brLenTotal: "2.1259" },
  { geneTranscript: "NM_013438.5", parsimonySitesP: "0.153933220147142", constantSitesP: "0.736842105263158", distinctSitesP: "0.210526315789474", brLenTotal: "0.5005" },
  { geneTranscript: "NM_003857.4", parsimonySitesP: "0.265288544358312", constantSitesP: "0.564168819982773", distinctSitesP: "0.397071490094746", brLenTotal: "1.1178" },
  { geneTranscript: "NM_004791.3", parsimonySitesP: "0.184885290148448", constantSitesP: "0.699055330634278", distinctSitesP: "0.257759784075574", brLenTotal: "0.6484" },
  { geneTranscript: "NM_022896.3", parsimonySitesP: "0.285154719937329", constantSitesP: "0.54876615746181", distinctSitesP: "0.388954171562867", brLenTotal: "1.1076" },
  { geneTranscript: "NM_001277204.2", parsimonySitesP: "0.235772357723577", constantSitesP: "0.626016260162602", distinctSitesP: "0.339430894308943", brLenTotal: "0.9463" },
  { geneTranscript: "NM_001370485.4", parsimonySitesP: "0.520833333333333", constantSitesP: "0.354166666666667", distinctSitesP: "0.63125", brLenTotal: "3.7007" },
  { geneTranscript: "NM_001353345.2", parsimonySitesP: "0.229060698541879", constantSitesP: "0.657341471685317", distinctSitesP: "0.288741946422516", brLenTotal: "1.0951" },
  { geneTranscript: "NM_001350562.2", parsimonySitesP: "0.202872531418312", constantSitesP: "0.670257330939557", distinctSitesP: "0.271095152603232", brLenTotal: "0.7190" },
  { geneTranscript: "NM_016357.5", parsimonySitesP: "0.226174791392183", constantSitesP: "0.614404918752745", distinctSitesP: "0.328941589811155", brLenTotal: "0.7391" },
  { geneTranscript: "NM_001042702.5", parsimonySitesP: "0.114583333333333", constantSitesP: "0.785984848484848", distinctSitesP: "0.18655303030303", brLenTotal: "0.3796" },
  { geneTranscript: "NM_001747.4", parsimonySitesP: "0.175287356321839", constantSitesP: "0.681034482758621", distinctSitesP: "0.278735632183908", brLenTotal: "0.7783" },
  { geneTranscript: "NM_001346810.2", parsimonySitesP: "0.249605055292259", constantSitesP: "0.630647709320695", distinctSitesP: "0.316903633491311", brLenTotal: "1.1896" },
  { geneTranscript: "NM_005109.3", parsimonySitesP: "0.115749525616698", constantSitesP: "0.771030993042378", distinctSitesP: "0.189753320683112", brLenTotal: "0.4192" },
  { geneTranscript: "NM_004642.4", parsimonySitesP: "0.179710144927536", constantSitesP: "0.663768115942029", distinctSitesP: "0.339130434782609", brLenTotal: "0.8225" },
  { geneTranscript: "NM_004870.4", parsimonySitesP: "0.190283400809717", constantSitesP: "0.684210526315789", distinctSitesP: "0.280701754385965", brLenTotal: "0.6184" },
  { geneTranscript: "NM_032382.5", parsimonySitesP: "0.207516339869281", constantSitesP: "0.657407407407407", distinctSitesP: "0.299019607843137", brLenTotal: "0.7698" },
  { geneTranscript: "NM_003288.4", parsimonySitesP: "0.197411003236246", constantSitesP: "0.674757281553398", distinctSitesP: "0.305825242718447", brLenTotal: "0.7700" },
  { geneTranscript: "NM_032849.4", parsimonySitesP: "0.183718371837184", constantSitesP: "0.675467546754675", distinctSitesP: "0.317931793179318", brLenTotal: "0.6445" },
  { geneTranscript: "NM_007359.5", parsimonySitesP: "0.171171171171171", constantSitesP: "0.715030820293978", distinctSitesP: "0.239449976292082", brLenTotal: "0.5101" },
  { geneTranscript: "NM_032306.4", parsimonySitesP: "0.277526395173454", constantSitesP: "0.553544494720965", distinctSitesP: "0.384615384615385", brLenTotal: "1.2285" },
  { geneTranscript: "NM_000180.4", parsimonySitesP: "0.248111211846479", constantSitesP: "0.620731338773043", distinctSitesP: "0.308250226654578", brLenTotal: "0.9794" },
  { geneTranscript: "NM_001040214.3", parsimonySitesP: "0.0496794871794872", constantSitesP: "0.884615384615385", distinctSitesP: "0.116987179487179", brLenTotal: "0.1824" },
  { geneTranscript: "NM_012407.4", parsimonySitesP: "0.16144578313253", constantSitesP: "0.746184738955823", distinctSitesP: "0.232931726907631", brLenTotal: "0.7366" },
  { geneTranscript: "NM_001201325.2", parsimonySitesP: "0.280025690430315", constantSitesP: "0.545279383429672", distinctSitesP: "0.458574181117534", brLenTotal: "1.0648" },
  { geneTranscript: "NM_001384140.1", parsimonySitesP: "0.189080459770115", constantSitesP: "0.687931034482759", distinctSitesP: "0.246360153256705", brLenTotal: "0.6808" },
  { geneTranscript: "NM_000713.3", parsimonySitesP: "0.211974110032362", constantSitesP: "0.676375404530744", distinctSitesP: "0.3042071197411", brLenTotal: "0.9011" },
  { geneTranscript: "NM_018566.4", parsimonySitesP: "0.150383141762452", constantSitesP: "0.727969348659004", distinctSitesP: "0.25", brLenTotal: "0.5152" },
  { geneTranscript: "NM_001004481.1", parsimonySitesP: "0.518867924528302", constantSitesP: "0.378406708595388", distinctSitesP: "0.615303983228512", brLenTotal: "4.2958" },
  { geneTranscript: "NM_024561.5", parsimonySitesP: "0.157407407407407", constantSitesP: "0.72337962962963", distinctSitesP: "0.238811728395062", brLenTotal: "0.5548" },
  { geneTranscript: "NM_001282426.2", parsimonySitesP: "0.173321234119782", constantSitesP: "0.734422262552934", distinctSitesP: "0.208711433756806", brLenTotal: "0.6176" },
  { geneTranscript: "NM_001284.4", parsimonySitesP: "0.075993091537133", constantSitesP: "0.815198618307427", distinctSitesP: "0.184801381692573", brLenTotal: "0.2801" },
  { geneTranscript: "NM_003750.4", parsimonySitesP: "0.153642064640617", constantSitesP: "0.753738543174144", distinctSitesP: "0.20935841775205", brLenTotal: "0.5131" },
  { geneTranscript: "NM_012161.4", parsimonySitesP: "0.133622768933912", constantSitesP: "0.76748673420164", distinctSitesP: "0.17607332368548", brLenTotal: "0.4137" },
  { geneTranscript: "NM_001013649.4", parsimonySitesP: "0.180722891566265", constantSitesP: "0.694779116465863", distinctSitesP: "0.269076305220884", brLenTotal: "0.5643" },
  { geneTranscript: "NM_014830.3", parsimonySitesP: "0.180243445692884", constantSitesP: "0.684456928838951", distinctSitesP: "0.249531835205993", brLenTotal: "0.6569" },
  { geneTranscript: "NM_020205.4", parsimonySitesP: "0.152629497825227", constantSitesP: "0.73230525899565", distinctSitesP: "0.201265322261764", brLenTotal: "0.4932" },
  { geneTranscript: "NM_004055.5", parsimonySitesP: "0.205729166666667", constantSitesP: "0.677083333333333", distinctSitesP: "0.263541666666667", brLenTotal: "0.8639" },
  { geneTranscript: "NM_032015.5", parsimonySitesP: "0.209391839876828", constantSitesP: "0.652040030792918", distinctSitesP: "0.296381832178599", brLenTotal: "0.7196" },
  { geneTranscript: "NM_000613.3", parsimonySitesP: "0.24963924963925", constantSitesP: "0.62049062049062", distinctSitesP: "0.350649350649351", brLenTotal: "0.8122" },
  { geneTranscript: "NM_001004733.3", parsimonySitesP: "0.475583864118896", constantSitesP: "0.333333333333333", distinctSitesP: "0.628450106157113", brLenTotal: "2.3107" },
  { geneTranscript: "NM_003223.3", parsimonySitesP: "0.152859960552268", constantSitesP: "0.77120315581854", distinctSitesP: "0.20414201183432", brLenTotal: "0.6770" },
  { geneTranscript: "NM_000750.5", parsimonySitesP: "0.308567603748327", constantSitesP: "0.517402945113788", distinctSitesP: "0.42904953145917", brLenTotal: "1.3964" },
  { geneTranscript: "NM_003826.3", parsimonySitesP: "0.165598290598291", constantSitesP: "0.761752136752137", distinctSitesP: "0.248931623931624", brLenTotal: "0.5964" },
  { geneTranscript: "NM_018918.3", parsimonySitesP: "0.397064088793412", constantSitesP: "0.468313641245972", distinctSitesP: "0.49266022198353", brLenTotal: "2.8068" },
  { geneTranscript: "NM_017661.4", parsimonySitesP: "0.176370446033367", constantSitesP: "0.672795369424583", distinctSitesP: "0.282260810350698", brLenTotal: "0.6074" },
  { geneTranscript: "NM_014487.6", parsimonySitesP: "0.132291666666667", constantSitesP: "0.76875", distinctSitesP: "0.220833333333333", brLenTotal: "0.4495" },
  { geneTranscript: "NM_001454.4", parsimonySitesP: "0.17735550277118", constantSitesP: "0.707046714172605", distinctSitesP: "0.253365003958828", brLenTotal: "0.8340" },
  { geneTranscript: "NM_006666.3", parsimonySitesP: "0.160547156227502", constantSitesP: "0.753059755219582", distinctSitesP: "0.226061915046796", brLenTotal: "0.8350" },
  { geneTranscript: "NM_000126.4", parsimonySitesP: "0.166166166166166", constantSitesP: "0.72972972972973", distinctSitesP: "0.261261261261261", brLenTotal: "0.5218" },
  { geneTranscript: "NM_005474.5", parsimonySitesP: "0.131313131313131", constantSitesP: "0.772133095662507", distinctSitesP: "0.174688057040998", brLenTotal: "0.5246" },
  { geneTranscript: "NM_004083.6", parsimonySitesP: "0.195266272189349", constantSitesP: "0.641025641025641", distinctSitesP: "0.339250493096647", brLenTotal: "0.6722" },
  { geneTranscript: "NM_001004696.2", parsimonySitesP: "0.565625", constantSitesP: "0.26875", distinctSitesP: "0.692708333333333", brLenTotal: "3.9424" },
  { geneTranscript: "NM_004775.5", parsimonySitesP: "0.134380453752182", constantSitesP: "0.766143106457243", distinctSitesP: "0.204188481675393", brLenTotal: "0.4354" },
  { geneTranscript: "NM_018150.4", parsimonySitesP: "0.115429917550059", constantSitesP: "0.821554770318021", distinctSitesP: "0.159599528857479", brLenTotal: "0.3918" },
  { geneTranscript: "NM_021784.5", parsimonySitesP: "0.142548596112311", constantSitesP: "0.732901367890569", distinctSitesP: "0.222462203023758", brLenTotal: "0.6111" },
  { geneTranscript: "NM_012446.5", parsimonySitesP: "0.0517082179132041", constantSitesP: "0.878116343490305", distinctSitesP: "0.105263157894737", brLenTotal: "0.1937" },
  { geneTranscript: "NM_004215.5", parsimonySitesP: "0.120500782472613", constantSitesP: "0.763693270735524", distinctSitesP: "0.214397496087637", brLenTotal: "0.4043" },
  { geneTranscript: "NM_001394311.1", parsimonySitesP: "0.142786069651741", constantSitesP: "0.746268656716418", distinctSitesP: "0.211940298507463", brLenTotal: "0.4780" },
  { geneTranscript: "NM_033027.4", parsimonySitesP: "0.275042444821732", constantSitesP: "0.550650820599887", distinctSitesP: "0.375212224108659", brLenTotal: "1.0014" },
  { geneTranscript: "NM_016059.5", parsimonySitesP: "0.150602409638554", constantSitesP: "0.710843373493976", distinctSitesP: "0.285140562248996", brLenTotal: "0.6160" },
  { geneTranscript: "NM_002717.4", parsimonySitesP: "0.0917225950782998", constantSitesP: "0.799403430275913", distinctSitesP: "0.146159582401193", brLenTotal: "0.4116" },
  { geneTranscript: "NM_017922.4", parsimonySitesP: "0.0976581963129048", constantSitesP: "0.818634778276034", distinctSitesP: "0.150971599402093", brLenTotal: "0.3047" },
  { geneTranscript: "NM_014506.3", parsimonySitesP: "0.236111111111111", constantSitesP: "0.599206349206349", distinctSitesP: "0.353174603174603", brLenTotal: "1.0027" },
  { geneTranscript: "NM_001039503.3", parsimonySitesP: "0.255575647980711", constantSitesP: "0.590717299578059", distinctSitesP: "0.33212778782399", brLenTotal: "0.9980" },
  { geneTranscript: "NM_005227.3", parsimonySitesP: "0.189054726368159", constantSitesP: "0.683250414593698", distinctSitesP: "0.283582089552239", brLenTotal: "0.7021" },
  { geneTranscript: "NM_015565.3", parsimonySitesP: "0.175537938844847", constantSitesP: "0.707436768591921", distinctSitesP: "0.243676859192148", brLenTotal: "0.5483" },
  { geneTranscript: "NM_004500.4", parsimonySitesP: "0.119453924914676", constantSitesP: "0.66325369738339", distinctSitesP: "0.303754266211604", brLenTotal: "0.5512" },
  { geneTranscript: "NM_138493.3", parsimonySitesP: "0.216494845360825", constantSitesP: "0.597938144329897", distinctSitesP: "0.381443298969072", brLenTotal: "0.7788" },
  { geneTranscript: "NM_138792.4", parsimonySitesP: "0.136136136136136", constantSitesP: "0.761761761761762", distinctSitesP: "0.208208208208208", brLenTotal: "0.5097" },
  { geneTranscript: "NM_000079.4", parsimonySitesP: "0.162654996353027", constantSitesP: "0.728665207877462", distinctSitesP: "0.235594456601021", brLenTotal: "0.6864" },
  { geneTranscript: "NM_152707.4", parsimonySitesP: "0.140562248995984", constantSitesP: "0.757028112449799", distinctSitesP: "0.23995983935743", brLenTotal: "0.4566" },
  { geneTranscript: "NM_017727.5", parsimonySitesP: "0.228834059022738", constantSitesP: "0.590711175616836", distinctSitesP: "0.328011611030479", brLenTotal: "0.8716" },
  { geneTranscript: "NM_016533.6", parsimonySitesP: "0.382629107981221", constantSitesP: "0.429577464788732", distinctSitesP: "0.553990610328638", brLenTotal: "1.5391" },
  { geneTranscript: "NM_001005186.2", parsimonySitesP: "0.261829652996845", constantSitesP: "0.574132492113565", distinctSitesP: "0.38801261829653", brLenTotal: "0.9505" },
  { geneTranscript: "NM_002239.4", parsimonySitesP: "0.0818363273453094", constantSitesP: "0.827012641383899", distinctSitesP: "0.129075182967399", brLenTotal: "0.3195" },
  { geneTranscript: "NM_006589.3", parsimonySitesP: "0.165668662674651", constantSitesP: "0.731536926147705", distinctSitesP: "0.226546906187625", brLenTotal: "0.5763" },
  { geneTranscript: "NM_024738.4", parsimonySitesP: "0.151219512195122", constantSitesP: "0.71869918699187", distinctSitesP: "0.252032520325203", brLenTotal: "0.6262" },
  { geneTranscript: "NM_007178.4", parsimonySitesP: "0.127619047619048", constantSitesP: "0.737142857142857", distinctSitesP: "0.255238095238095", brLenTotal: "0.4704" },
  { geneTranscript: "NM_178863.5", parsimonySitesP: "0.161094224924012", constantSitesP: "0.749746707193516", distinctSitesP: "0.227963525835866", brLenTotal: "0.5492" },
  { geneTranscript: "NM_001965.4", parsimonySitesP: "0.215363511659808", constantSitesP: "0.66323731138546", distinctSitesP: "0.272976680384088", brLenTotal: "0.7073" },
  { geneTranscript: "NM_013342.4", parsimonySitesP: "0.209486166007905", constantSitesP: "0.686429512516469", distinctSitesP: "0.299077733860343", brLenTotal: "0.7808" },
  { geneTranscript: "NM_002600.4", parsimonySitesP: "0.207880434782609", constantSitesP: "0.702898550724638", distinctSitesP: "0.250905797101449", brLenTotal: "0.8157" },
  { geneTranscript: "NM_006404.5", parsimonySitesP: "0.284313725490196", constantSitesP: "0.572829131652661", distinctSitesP: "0.399159663865546", brLenTotal: "0.9724" },
  { geneTranscript: "NM_001194986.2", parsimonySitesP: "0.197292069632495", constantSitesP: "0.68214055448098", distinctSitesP: "0.26434558349452", brLenTotal: "0.9669" },
  { geneTranscript: "NM_016932.5", parsimonySitesP: "0.166093928980527", constantSitesP: "0.769759450171821", distinctSitesP: "0.222222222222222", brLenTotal: "0.5883" },
  { geneTranscript: "NM_006416.5", parsimonySitesP: "0.134520276953511", constantSitesP: "0.763600395647873", distinctSitesP: "0.200791295746785", brLenTotal: "0.4075" },
  { geneTranscript: "NM_000283.4", parsimonySitesP: "0.21623731459797", constantSitesP: "0.660421545667447", distinctSitesP: "0.300546448087432", brLenTotal: "0.9718" },
  { geneTranscript: "NM_003507.2", parsimonySitesP: "0.246225319396051", constantSitesP: "0.659117305458769", distinctSitesP: "0.284552845528455", brLenTotal: "0.9523" },
  { geneTranscript: "NM_001010924.2", parsimonySitesP: "0.237078651685393", constantSitesP: "0.659925093632959", distinctSitesP: "0.288014981273408", brLenTotal: "1.0688" },
  { geneTranscript: "NM_002637.4", parsimonySitesP: "0.144180975742709", constantSitesP: "0.735622785500136", distinctSitesP: "0.198964295448351", brLenTotal: "0.4540" },
  { geneTranscript: "NM_178864.4", parsimonySitesP: "0.136325852036575", constantSitesP: "0.77265170407315", distinctSitesP: "0.182460515378221", brLenTotal: "0.4399" },
  { geneTranscript: "NM_001372080.1", parsimonySitesP: "0.19131455399061", constantSitesP: "0.658841940532081", distinctSitesP: "0.269953051643192", brLenTotal: "0.5857" },
  { geneTranscript: "NM_001371928.1", parsimonySitesP: "0.164899147431899", constantSitesP: "0.711582449573716", distinctSitesP: "0.195050946142649", brLenTotal: "0.9562" },
  { geneTranscript: "NM_024926.4", parsimonySitesP: "0.127557160048135", constantSitesP: "0.765944645006017", distinctSitesP: "0.200361010830325", brLenTotal: "0.4015" },
  { geneTranscript: "NM_000458.4", parsimonySitesP: "0.108318372232196", constantSitesP: "0.792938360263315", distinctSitesP: "0.172950329144225", brLenTotal: "0.4276" },
  { geneTranscript: "NM_002643.4", parsimonySitesP: "0.170471841704718", constantSitesP: "0.700152207001522", distinctSitesP: "0.290715372907154", brLenTotal: "0.5184" },
  { geneTranscript: "NM_001347721.2", parsimonySitesP: "0.0835543766578249", constantSitesP: "0.839080459770115", distinctSitesP: "0.12157382847038", brLenTotal: "0.3108" },
  { geneTranscript: "NM_031471.6", parsimonySitesP: "0.198089492207139", constantSitesP: "0.701357466063348", distinctSitesP: "0.241830065359477", brLenTotal: "0.9707" },
  { geneTranscript: "NM_001257293.2", parsimonySitesP: "0.135115070527097", constantSitesP: "0.746844840386043", distinctSitesP: "0.225686711210097", brLenTotal: "0.6540" },
  { geneTranscript: "NM_018292.5", parsimonySitesP: "0.230429292929293", constantSitesP: "0.597222222222222", distinctSitesP: "0.370580808080808", brLenTotal: "0.8962" },
  { geneTranscript: "NM_004378.3", parsimonySitesP: "0.104622871046229", constantSitesP: "0.824817518248175", distinctSitesP: "0.172749391727494", brLenTotal: "0.4201" },
  { geneTranscript: "NM_001005210.4", parsimonySitesP: "0.200223713646532", constantSitesP: "0.674496644295302", distinctSitesP: "0.285234899328859", brLenTotal: "0.8891" },
  { geneTranscript: "NM_182577.3", parsimonySitesP: "0.351787773933103", constantSitesP: "0.498269896193772", distinctSitesP: "0.492502883506344", brLenTotal: "1.8944" },
  { geneTranscript: "NM_014617.4", parsimonySitesP: "0.323754789272031", constantSitesP: "0.53639846743295", distinctSitesP: "0.442528735632184", brLenTotal: "1.5449" },
  { geneTranscript: "NM_020135.3", parsimonySitesP: "0.170927318295739", constantSitesP: "0.717794486215539", distinctSitesP: "0.23609022556391", brLenTotal: "0.6225" },
  { geneTranscript: "NM_198976.4", parsimonySitesP: "0.162937464142283", constantSitesP: "0.759609868043603", distinctSitesP: "0.227194492254733", brLenTotal: "0.7013" },
  { geneTranscript: "NM_001369623.2", parsimonySitesP: "0.136846405228758", constantSitesP: "0.77859477124183", distinctSitesP: "0.17687908496732", brLenTotal: "0.4429" },
  { geneTranscript: "NM_030904.2", parsimonySitesP: "0.459119496855346", constantSitesP: "0.40251572327044", distinctSitesP: "0.589098532494759", brLenTotal: "2.5681" },
  { geneTranscript: "NM_024419.5", parsimonySitesP: "0.191247002398082", constantSitesP: "0.709832134292566", distinctSitesP: "0.260191846522782", brLenTotal: "0.7666" },
  { geneTranscript: "NM_006391.3", parsimonySitesP: "0.107578676942839", constantSitesP: "0.792228644829801", distinctSitesP: "0.158959537572254", brLenTotal: "0.3679" },
  { geneTranscript: "NM_018359.5", parsimonySitesP: "0.199715707178394", constantSitesP: "0.64818763326226", distinctSitesP: "0.324804548685146", brLenTotal: "0.7595" },
  { geneTranscript: "NM_181715.3", parsimonySitesP: "0.173160173160173", constantSitesP: "0.705146705146705", distinctSitesP: "0.221260221260221", brLenTotal: "0.5455" },
  { geneTranscript: "NM_001387777.1", parsimonySitesP: "0.207721587819467", constantSitesP: "0.679354721769077", distinctSitesP: "0.244698205546493", brLenTotal: "0.8567" },
  { geneTranscript: "NM_181435.6", parsimonySitesP: "0.13166144200627", constantSitesP: "0.776384535005225", distinctSitesP: "0.203761755485893", brLenTotal: "0.5068" },
  { geneTranscript: "NM_005954.4", parsimonySitesP: "0.205882352941176", constantSitesP: "0.720588235294118", distinctSitesP: "0.308823529411765", brLenTotal: "0.6778" },
  { geneTranscript: "NM_031942.5", parsimonySitesP: "0.179259259259259", constantSitesP: "0.664444444444444", distinctSitesP: "0.321481481481481", brLenTotal: "0.6907" },
  { geneTranscript: "NM_001369.3", parsimonySitesP: "0.217776816608997", constantSitesP: "0.650086505190311", distinctSitesP: "0.279051326412918", brLenTotal: "0.8134" },
  { geneTranscript: "NM_015027.4", parsimonySitesP: "0.203045685279188", constantSitesP: "0.666243654822335", distinctSitesP: "0.328680203045685", brLenTotal: "0.8081" },
  { geneTranscript: "NM_018671.5", parsimonySitesP: "0.191737288135593", constantSitesP: "0.677612994350282", distinctSitesP: "0.255296610169492", brLenTotal: "0.7607" },
  { geneTranscript: "NM_018159.4", parsimonySitesP: "0.16869918699187", constantSitesP: "0.644308943089431", distinctSitesP: "0.286585365853659", brLenTotal: "0.8879" },
  { geneTranscript: "NM_002370.4", parsimonySitesP: "0.136986301369863", constantSitesP: "0.778538812785388", distinctSitesP: "0.226027397260274", brLenTotal: "0.4629" },
  { geneTranscript: "NM_001330994.2", parsimonySitesP: "0.173867228661749", constantSitesP: "0.752019669827889", distinctSitesP: "0.216016859852476", brLenTotal: "0.6501" },
  { geneTranscript: "NM_002634.4", parsimonySitesP: "0.193627450980392", constantSitesP: "0.618872549019608", distinctSitesP: "0.345588235294118", brLenTotal: "1.0716" },
  { geneTranscript: "NM_173618.3", parsimonySitesP: "0.144808743169399", constantSitesP: "0.769125683060109", distinctSitesP: "0.211748633879781", brLenTotal: "0.4463" },
  { geneTranscript: "NM_181787.3", parsimonySitesP: "0.140617796219456", constantSitesP: "0.74458275703089", distinctSitesP: "0.229137851544491", brLenTotal: "0.4251" },
  { geneTranscript: "NM_001365792.1", parsimonySitesP: "0.0882882882882883", constantSitesP: "0.844444444444444", distinctSitesP: "0.140540540540541", brLenTotal: "0.3112" },
  { geneTranscript: "NM_016445.3", parsimonySitesP: "0.187913125590179", constantSitesP: "0.696883852691218", distinctSitesP: "0.286118980169972", brLenTotal: "0.6922" },
  { geneTranscript: "NM_022456.5", parsimonySitesP: "0.144202898550725", constantSitesP: "0.753623188405797", distinctSitesP: "0.223913043478261", brLenTotal: "0.4700" },
  { geneTranscript: "NM_002730.4", parsimonySitesP: "0.135802469135802", constantSitesP: "0.778727445394112", distinctSitesP: "0.203228869895537", brLenTotal: "0.5452" },
  { geneTranscript: "NM_001162498.3", parsimonySitesP: "0.158914728682171", constantSitesP: "0.742248062015504", distinctSitesP: "0.248062015503876", brLenTotal: "0.4672" },
  { geneTranscript: "NM_001368809.2", parsimonySitesP: "0.142222222222222", constantSitesP: "0.767676767676768", distinctSitesP: "0.187474747474747", brLenTotal: "0.6109" },
  { geneTranscript: "NM_025130.4", parsimonySitesP: "0.237368229734642", constantSitesP: "0.646310432569975", distinctSitesP: "0.307161032351872", brLenTotal: "1.0364" },
  { geneTranscript: "NM_001320466.2", parsimonySitesP: "0.179310344827586", constantSitesP: "0.691187739463602", distinctSitesP: "0.251340996168582", brLenTotal: "0.6080" },
  { geneTranscript: "NM_006965.4", parsimonySitesP: "0.125", constantSitesP: "0.75", distinctSitesP: "0.217391304347826", brLenTotal: "0.4346" },
  { geneTranscript: "NM_001013836.2", parsimonySitesP: "0.253017641597029", constantSitesP: "0.603992571959146", distinctSitesP: "0.354688950789229", brLenTotal: "1.1057" },
  { geneTranscript: "NM_024940.8", parsimonySitesP: "0.197504456327986", constantSitesP: "0.711408199643494", distinctSitesP: "0.255258467023173", brLenTotal: "0.7510" },
  { geneTranscript: "NM_024907.7", parsimonySitesP: "0.213429256594724", constantSitesP: "0.65947242206235", distinctSitesP: "0.292565947242206", brLenTotal: "0.7641" },
  { geneTranscript: "NM_000074.3", parsimonySitesP: "0.159642401021711", constantSitesP: "0.721583652618135", distinctSitesP: "0.24904214559387", brLenTotal: "0.5518" },
  { geneTranscript: "NM_001365536.1", parsimonySitesP: "0.267102615694165", constantSitesP: "0.635311871227364", distinctSitesP: "0.302816901408451", brLenTotal: "1.6096" },
  { geneTranscript: "NM_001039770.3", parsimonySitesP: "0.222958057395143", constantSitesP: "0.635761589403974", distinctSitesP: "0.307579102281089", brLenTotal: "0.9872" },
  { geneTranscript: "NM_030632.3", parsimonySitesP: "0.201067615658363", constantSitesP: "0.668297746144721", distinctSitesP: "0.249258600237248", brLenTotal: "0.6968" },
  { geneTranscript: "NM_018231.3", parsimonySitesP: "0.159451659451659", constantSitesP: "0.744588744588745", distinctSitesP: "0.216450216450216", brLenTotal: "0.6824" },
  { geneTranscript: "NM_001166105.3", parsimonySitesP: "0.112114371708051", constantSitesP: "0.801354401805869", distinctSitesP: "0.18058690744921", brLenTotal: "0.3821" },
  { geneTranscript: "NM_007374.3", parsimonySitesP: "0.146341463414634", constantSitesP: "0.754742547425474", distinctSitesP: "0.226287262872629", brLenTotal: "0.5086" },
  { geneTranscript: "NM_152393.4", parsimonySitesP: "0.203972088030059", constantSitesP: "0.678475577026302", distinctSitesP: "0.268921095008052", brLenTotal: "0.8137" },
  { geneTranscript: "NM_014669.5", parsimonySitesP: "0.153846153846154", constantSitesP: "0.745217745217745", distinctSitesP: "0.212047212047212", brLenTotal: "0.5534" },
  { geneTranscript: "NM_001145077.2", parsimonySitesP: "0.166666666666667", constantSitesP: "0.703196347031963", distinctSitesP: "0.254566210045662", brLenTotal: "0.6247" },
  { geneTranscript: "NM_015106.4", parsimonySitesP: "0.136787093842309", constantSitesP: "0.773006134969325", distinctSitesP: "0.169734151329243", brLenTotal: "0.4293" },
  { geneTranscript: "NM_001377448.1", parsimonySitesP: "0.299974437627812", constantSitesP: "0.557259713701431", distinctSitesP: "0.355061349693252", brLenTotal: "1.5234" },
  { geneTranscript: "NM_001166395.2", parsimonySitesP: "0.315198618307427", constantSitesP: "0.541450777202073", distinctSitesP: "0.427461139896373", brLenTotal: "1.1339" },
  { geneTranscript: "NM_001135178.3", parsimonySitesP: "0.230961298377029", constantSitesP: "0.560549313358302", distinctSitesP: "0.401997503121099", brLenTotal: "0.9652" },
  { geneTranscript: "NM_004381.5", parsimonySitesP: "0.201517306780465", constantSitesP: "0.643907064959697", distinctSitesP: "0.294452347083926", brLenTotal: "0.7420" },
  { geneTranscript: "NM_024092.3", parsimonySitesP: "0.244170096021948", constantSitesP: "0.593964334705075", distinctSitesP: "0.38957475994513", brLenTotal: "0.8628" },
  { geneTranscript: "NM_003216.4", parsimonySitesP: "0.135313531353135", constantSitesP: "0.772277227722772", distinctSitesP: "0.209020902090209", brLenTotal: "0.5600" },
  { geneTranscript: "NM_001001802.3", parsimonySitesP: "0.501075268817204", constantSitesP: "0.343010752688172", distinctSitesP: "0.644086021505376", brLenTotal: "3.7459" },
  { geneTranscript: "NM_001080448.3", parsimonySitesP: "0.174926253687316", constantSitesP: "0.70117994100295", distinctSitesP: "0.245722713864307", brLenTotal: "0.6827" },
  { geneTranscript: "NM_001004475.1", parsimonySitesP: "0.299363057324841", constantSitesP: "0.461783439490446", distinctSitesP: "0.48619957537155", brLenTotal: "1.0398" },
  { geneTranscript: "NM_003897.4", parsimonySitesP: "0.277777777777778", constantSitesP: "0.566239316239316", distinctSitesP: "0.393162393162393", brLenTotal: "0.9101" },
  { geneTranscript: "NM_006808.3", parsimonySitesP: "0.138888888888889", constantSitesP: "0.736111111111111", distinctSitesP: "0.263888888888889", brLenTotal: "0.5535" },
  { geneTranscript: "NM_004622.3", parsimonySitesP: "0.112573099415205", constantSitesP: "0.766081871345029", distinctSitesP: "0.211988304093567", brLenTotal: "0.4430" },
  { geneTranscript: "NM_183381.3", parsimonySitesP: "0.122484689413823", constantSitesP: "0.794400699912511", distinctSitesP: "0.187226596675416", brLenTotal: "0.3932" },
  { geneTranscript: "NM_001388306.1", parsimonySitesP: "0.233529028049576", constantSitesP: "0.648401826484018", distinctSitesP: "0.328114807566862", brLenTotal: "1.2260" },
  { geneTranscript: "NM_015299.3", parsimonySitesP: "0.282694198623402", constantSitesP: "0.58062930186824", distinctSitesP: "0.326450344149459", brLenTotal: "0.9098" },
  { geneTranscript: "NM_001321708.2", parsimonySitesP: "0.120782087669505", constantSitesP: "0.784610532954904", distinctSitesP: "0.191737622201198", brLenTotal: "0.4288" },
  { geneTranscript: "NM_032438.4", parsimonySitesP: "0.144017094017094", constantSitesP: "0.766239316239316", distinctSitesP: "0.193589743589744", brLenTotal: "0.5087" },
  { geneTranscript: "NM_002811.5", parsimonySitesP: "0.148148148148148", constantSitesP: "0.746913580246914", distinctSitesP: "0.239711934156379", brLenTotal: "0.5114" },
  { geneTranscript: "NM_017763.6", parsimonySitesP: "0.223073648361005", constantSitesP: "0.618986802894849", distinctSitesP: "0.291187739463602", brLenTotal: "0.7714" },
  { geneTranscript: "NM_004598.4", parsimonySitesP: "0.176157934700076", constantSitesP: "0.694001518602885", distinctSitesP: "0.264236902050114", brLenTotal: "0.6530" },
  { geneTranscript: "NM_012100.4", parsimonySitesP: "0.202061855670103", constantSitesP: "0.685910652920962", distinctSitesP: "0.289347079037801", brLenTotal: "0.7019" },
  { geneTranscript: "NM_003334.4", parsimonySitesP: "0.179269061121613", constantSitesP: "0.72117202268431", distinctSitesP: "0.211405166981727", brLenTotal: "0.5856" },
  { geneTranscript: "NM_172069.4", parsimonySitesP: "0.154945300290243", constantSitesP: "0.716454565751284", distinctSitesP: "0.203840142889038", brLenTotal: "0.5228" },
  { geneTranscript: "NM_016133.4", parsimonySitesP: "0.111111111111111", constantSitesP: "0.805925925925926", distinctSitesP: "0.188148148148148", brLenTotal: "0.3442" },
  { geneTranscript: "NM_005730.4", parsimonySitesP: "0.143911439114391", constantSitesP: "0.782287822878229", distinctSitesP: "0.210332103321033", brLenTotal: "0.5083" },
  { geneTranscript: "NM_017748.5", parsimonySitesP: "0.190588235294118", constantSitesP: "0.668235294117647", distinctSitesP: "0.313725490196078", brLenTotal: "0.7202" },
  { geneTranscript: "NM_001035521.3", parsimonySitesP: "0.148188803512623", constantSitesP: "0.719356019026711", distinctSitesP: "0.196853274789608", brLenTotal: "0.4894" },
  { geneTranscript: "NM_002436.4", parsimonySitesP: "0.178826895565093", constantSitesP: "0.734620886981402", distinctSitesP: "0.231759656652361", brLenTotal: "0.5940" },
  { geneTranscript: "NM_023068.4", parsimonySitesP: "0.320850399843963", constantSitesP: "0.511410181392627", distinctSitesP: "0.378388921396528", brLenTotal: "1.3181" },
  { geneTranscript: "NM_020863.4", parsimonySitesP: "0.204344328238134", constantSitesP: "0.675516224188791", distinctSitesP: "0.272995441137034", brLenTotal: "0.8683" },
  { geneTranscript: "NM_002044.4", parsimonySitesP: "0.192139737991266", constantSitesP: "0.684133915574964", distinctSitesP: "0.286754002911208", brLenTotal: "0.5921" },
  { geneTranscript: "NM_012460.4", parsimonySitesP: "0.0823970037453184", constantSitesP: "0.764044943820225", distinctSitesP: "0.247191011235955", brLenTotal: "0.3388" },
  { geneTranscript: "NM_198404.3", parsimonySitesP: "0.123552123552124", constantSitesP: "0.774774774774775", distinctSitesP: "0.193050193050193", brLenTotal: "0.3794" },
  { geneTranscript: "NM_020123.4", parsimonySitesP: "0.087719298245614", constantSitesP: "0.835880022637238", distinctSitesP: "0.150537634408602", brLenTotal: "0.3135" },
  { geneTranscript: "NM_198149.3", parsimonySitesP: "0.174280879864636", constantSitesP: "0.703891708967851", distinctSitesP: "0.291032148900169", brLenTotal: "0.6130" },
  { geneTranscript: "NM_153265.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.724702380952381", distinctSitesP: "0.213169642857143", brLenTotal: "0.5965" },
  { geneTranscript: "NM_016823.4", parsimonySitesP: "0.0745614035087719", constantSitesP: "0.883771929824561", distinctSitesP: "0.111842105263158", brLenTotal: "0.1860" },
  { geneTranscript: "NM_016185.4", parsimonySitesP: "0.183982683982684", constantSitesP: "0.681818181818182", distinctSitesP: "0.311688311688312", brLenTotal: "0.6640" },
  { geneTranscript: "NM_005225.3", parsimonySitesP: "0.205949656750572", constantSitesP: "0.691838291380625", distinctSitesP: "0.29443173150267", brLenTotal: "0.7203" },
  { geneTranscript: "NM_001384133.1", parsimonySitesP: "0.1982414068745", constantSitesP: "0.707434052757794", distinctSitesP: "0.270983213429257", brLenTotal: "0.8521" },
  { geneTranscript: "NM_019072.3", parsimonySitesP: "0.104166666666667", constantSitesP: "0.804824561403509", distinctSitesP: "0.172149122807018", brLenTotal: "0.3593" },
  { geneTranscript: "NM_016729.3", parsimonySitesP: "0.363164721141375", constantSitesP: "0.457846952010376", distinctSitesP: "0.50064850843061", brLenTotal: "1.7789" },
  { geneTranscript: "NM_014466.3", parsimonySitesP: "0.229457364341085", constantSitesP: "0.623255813953488", distinctSitesP: "0.322480620155039", brLenTotal: "0.7930" },
  { geneTranscript: "NM_001257291.2", parsimonySitesP: "0.153810835629017", constantSitesP: "0.745638200183655", distinctSitesP: "0.221763085399449", brLenTotal: "0.6066" },
  { geneTranscript: "NM_001114598.2", parsimonySitesP: "0.239104829210836", constantSitesP: "0.607773851590106", distinctSitesP: "0.335689045936396", brLenTotal: "0.9159" },
  { geneTranscript: "NM_153741.2", parsimonySitesP: "0.199275362318841", constantSitesP: "0.652173913043478", distinctSitesP: "0.326086956521739", brLenTotal: "0.6957" },
  { geneTranscript: "NM_014182.5", parsimonySitesP: "0.220043572984749", constantSitesP: "0.61437908496732", distinctSitesP: "0.342047930283224", brLenTotal: "0.8255" },
  { geneTranscript: "NM_001098832.2", parsimonySitesP: "0.201288244766506", constantSitesP: "0.642512077294686", distinctSitesP: "0.357487922705314", brLenTotal: "0.7658" },
  { geneTranscript: "NM_005803.4", parsimonySitesP: "0.146760343481655", constantSitesP: "0.75175644028103", distinctSitesP: "0.197501951600312", brLenTotal: "0.5593" },
  { geneTranscript: "NM_001100427.2", parsimonySitesP: "0.126853377265239", constantSitesP: "0.758923668314113", distinctSitesP: "0.204832509610104", brLenTotal: "0.4523" },
  { geneTranscript: "NM_021178.5", parsimonySitesP: "0.187725631768953", constantSitesP: "0.667870036101083", distinctSitesP: "0.314079422382671", brLenTotal: "0.6577" },
  { geneTranscript: "NM_153358.3", parsimonySitesP: "0.502314814814815", constantSitesP: "0.354166666666667", distinctSitesP: "0.60474537037037", brLenTotal: "2.7010" },
  { geneTranscript: "NM_003786.4", parsimonySitesP: "0.260423488321327", constantSitesP: "0.582842174197773", distinctSitesP: "0.359310194280725", brLenTotal: "1.0764" },
  { geneTranscript: "NM_001001.5", parsimonySitesP: "0.289308176100629", constantSitesP: "0.481132075471698", distinctSitesP: "0.531446540880503", brLenTotal: "1.0031" },
  { geneTranscript: "NM_013451.4", parsimonySitesP: "0.191007601487951", constantSitesP: "0.703703703703704", distinctSitesP: "0.235807860262009", brLenTotal: "0.7244" },
  { geneTranscript: "NM_014225.6", parsimonySitesP: "0.151669496321449", constantSitesP: "0.757781550650821", distinctSitesP: "0.202037351443124", brLenTotal: "0.5846" },
  { geneTranscript: "NM_178470.5", parsimonySitesP: "0.329013678905688", constantSitesP: "0.497480201583873", distinctSitesP: "0.450683945284377", brLenTotal: "1.6433" },
  { geneTranscript: "NM_006231.4", parsimonySitesP: "0.228492271799358", constantSitesP: "0.647564887722368", distinctSitesP: "0.285214348206474", brLenTotal: "1.0023" },
  { geneTranscript: "NM_001142640.2", parsimonySitesP: "0.141184573002755", constantSitesP: "0.747073002754821", distinctSitesP: "0.167183195592287", brLenTotal: "0.5377" },
  { geneTranscript: "NM_020132.5", parsimonySitesP: "0.193262411347518", constantSitesP: "0.711879432624113", distinctSitesP: "0.287234042553191", brLenTotal: "0.8660" },
  { geneTranscript: "NM_003390.4", parsimonySitesP: "0.148606811145511", constantSitesP: "0.72858617131063", distinctSitesP: "0.225490196078431", brLenTotal: "0.4996" },
  { geneTranscript: "NM_001920.5", parsimonySitesP: "0.202414113277623", constantSitesP: "0.681522748375116", distinctSitesP: "0.282265552460539", brLenTotal: "0.7017" },
  { geneTranscript: "NM_033129.4", parsimonySitesP: "0.196525515743757", constantSitesP: "0.719869706840391", distinctSitesP: "0.274701411509229", brLenTotal: "0.9355" },
  { geneTranscript: "NM_001330311.2", parsimonySitesP: "0.240767386091127", constantSitesP: "0.658513189448441", distinctSitesP: "0.309352517985612", brLenTotal: "1.3331" },
  { geneTranscript: "NM_015327.3", parsimonySitesP: "0.160761154855643", constantSitesP: "0.727362204724409", distinctSitesP: "0.191272965879265", brLenTotal: "0.5498" },
  { geneTranscript: "NM_000994.4", parsimonySitesP: "0.185185185185185", constantSitesP: "0.664197530864198", distinctSitesP: "0.320987654320988", brLenTotal: "0.7016" },
  { geneTranscript: "NM_002800.5", parsimonySitesP: "0.19634703196347", constantSitesP: "0.683409436834094", distinctSitesP: "0.310502283105023", brLenTotal: "0.7985" },
  { geneTranscript: "NM_032907.5", parsimonySitesP: "0.152631578947368", constantSitesP: "0.72280701754386", distinctSitesP: "0.225438596491228", brLenTotal: "0.6751" },
  { geneTranscript: "NM_024682.3", parsimonySitesP: "0.202674897119342", constantSitesP: "0.676440329218107", distinctSitesP: "0.270576131687243", brLenTotal: "0.9448" },
  { geneTranscript: "NM_002009.4", parsimonySitesP: "0.0824742268041237", constantSitesP: "0.84192439862543", distinctSitesP: "0.149484536082474", brLenTotal: "0.2555" },
  { geneTranscript: "NM_000379.4", parsimonySitesP: "0.209302325581395", constantSitesP: "0.663165791447862", distinctSitesP: "0.288822205551388", brLenTotal: "0.8389" },
  { geneTranscript: "NM_014425.5", parsimonySitesP: "0.221596244131455", constantSitesP: "0.641627543035994", distinctSitesP: "0.304851330203443", brLenTotal: "0.6889" },
  { geneTranscript: "NM_004000.3", parsimonySitesP: "0.232478632478632", constantSitesP: "0.586324786324786", distinctSitesP: "0.395726495726496", brLenTotal: "0.9135" },
  { geneTranscript: "NM_152278.5", parsimonySitesP: "0.146666666666667", constantSitesP: "0.636666666666667", distinctSitesP: "0.313333333333333", brLenTotal: "0.5397" },
  { geneTranscript: "NM_003047.5", parsimonySitesP: "0.178323108384458", constantSitesP: "0.720245398773006", distinctSitesP: "0.210224948875256", brLenTotal: "0.8061" },
  { geneTranscript: "NM_006158.5", parsimonySitesP: "0.157151626764886", constantSitesP: "0.595457335788828", distinctSitesP: "0.262124002455494", brLenTotal: "0.9513" },
  { geneTranscript: "NM_013409.3", parsimonySitesP: "0.131782945736434", constantSitesP: "0.788759689922481", distinctSitesP: "0.194767441860465", brLenTotal: "0.4583" },
  { geneTranscript: "NM_024587.4", parsimonySitesP: "0.204572803850782", constantSitesP: "0.670276774969916", distinctSitesP: "0.275571600481348", brLenTotal: "0.7253" },
  { geneTranscript: "NM_022569.3", parsimonySitesP: "0.184633027522936", constantSitesP: "0.673165137614679", distinctSitesP: "0.254587155963303", brLenTotal: "0.7025" },
  { geneTranscript: "NM_001792.5", parsimonySitesP: "0.146063281824871", constantSitesP: "0.775570272259014", distinctSitesP: "0.194628403237675", brLenTotal: "0.5054" },
  { geneTranscript: "NM_031220.4", parsimonySitesP: "0.189596167008898", constantSitesP: "0.694045174537988", distinctSitesP: "0.25564681724846", brLenTotal: "0.8237" },
  { geneTranscript: "NM_005707.2", parsimonySitesP: "0.238487972508591", constantSitesP: "0.545017182130584", distinctSitesP: "0.404810996563574", brLenTotal: "1.1004" },
  { geneTranscript: "NM_173557.3", parsimonySitesP: "0.157635467980296", constantSitesP: "0.755336617405583", distinctSitesP: "0.234811165845649", brLenTotal: "0.7703" },
  { geneTranscript: "NM_178031.3", parsimonySitesP: "0.23525578364288", constantSitesP: "0.623004235907462", distinctSitesP: "0.295536005213425", brLenTotal: "1.0207" },
  { geneTranscript: "NM_004689.4", parsimonySitesP: "0.158041958041958", constantSitesP: "0.754778554778555", distinctSitesP: "0.21958041958042", brLenTotal: "0.8553" },
  { geneTranscript: "NM_004999.4", parsimonySitesP: "0.150713359273671", constantSitesP: "0.746562905317769", distinctSitesP: "0.216342412451362", brLenTotal: "0.5377" },
  { geneTranscript: "NM_001080512.3", parsimonySitesP: "0.121492128678987", constantSitesP: "0.781656399726215", distinctSitesP: "0.178302532511978", brLenTotal: "0.4489" },
  { geneTranscript: "NM_138733.5", parsimonySitesP: "0.287769784172662", constantSitesP: "0.576338928856914", distinctSitesP: "0.363709032773781", brLenTotal: "1.0291" },
  { geneTranscript: "NM_001205254.2", parsimonySitesP: "0.203703703703704", constantSitesP: "0.650063856960409", distinctSitesP: "0.323754789272031", brLenTotal: "0.7570" },
  { geneTranscript: "NM_015308.5", parsimonySitesP: "0.162569649295313", constantSitesP: "0.726319239593576", distinctSitesP: "0.236643723369387", brLenTotal: "0.5349" },
  { geneTranscript: "NM_005557.4", parsimonySitesP: "0.281888653981677", constantSitesP: "0.568710359408034", distinctSitesP: "0.444679351656096", brLenTotal: "1.9833" },
  { geneTranscript: "NM_004217.4", parsimonySitesP: "0.236434108527132", constantSitesP: "0.652131782945736", distinctSitesP: "0.317829457364341", brLenTotal: "0.9187" },
  { geneTranscript: "NM_001004106.3", parsimonySitesP: "0.183449074074074", constantSitesP: "0.733796296296296", distinctSitesP: "0.226851851851852", brLenTotal: "0.8135" },
  { geneTranscript: "NM_024113.5", parsimonySitesP: "0.177240684793555", constantSitesP: "0.70896273917422", distinctSitesP: "0.258811681772407", brLenTotal: "0.6491" },
  { geneTranscript: "NM_007051.3", parsimonySitesP: "0.0876923076923077", constantSitesP: "0.812307692307692", distinctSitesP: "0.146153846153846", brLenTotal: "0.3099" },
  { geneTranscript: "NM_144997.7", parsimonySitesP: "0.223373632700058", constantSitesP: "0.683937823834197", distinctSitesP: "0.284974093264249", brLenTotal: "1.0588" },
  { geneTranscript: "NM_173809.5", parsimonySitesP: "0.133802816901408", constantSitesP: "0.741784037558685", distinctSitesP: "0.26056338028169", brLenTotal: "0.5683" },
  { geneTranscript: "NM_012125.4", parsimonySitesP: "0.196741854636591", constantSitesP: "0.662280701754386", distinctSitesP: "0.283208020050125", brLenTotal: "0.6808" },
  { geneTranscript: "NM_015649.3", parsimonySitesP: "0.186643835616438", constantSitesP: "0.723173515981735", distinctSitesP: "0.236872146118721", brLenTotal: "0.7626" },
  { geneTranscript: "NM_013283.5", parsimonySitesP: "0.140718562874251", constantSitesP: "0.734530938123752", distinctSitesP: "0.25249500998004", brLenTotal: "0.4711" },
  { geneTranscript: "NM_001395847.1", parsimonySitesP: "0.193843193843194", constantSitesP: "0.666666666666667", distinctSitesP: "0.2996632996633", brLenTotal: "0.6628" },
  { geneTranscript: "NM_004947.5", parsimonySitesP: "0.122660098522167", constantSitesP: "0.789819376026273", distinctSitesP: "0.135796387520525", brLenTotal: "0.3777" },
  { geneTranscript: "NM_024089.3", parsimonySitesP: "0.185922974767596", constantSitesP: "0.691235059760956", distinctSitesP: "0.269588313413015", brLenTotal: "0.6544" },
  { geneTranscript: "NM_002696.3", parsimonySitesP: "0.112403100775194", constantSitesP: "0.777131782945736", distinctSitesP: "0.255813953488372", brLenTotal: "0.4310" },
  { geneTranscript: "NM_000337.6", parsimonySitesP: "0.128735632183908", constantSitesP: "0.771264367816092", distinctSitesP: "0.226436781609195", brLenTotal: "0.4542" },
  { geneTranscript: "NM_004969.4", parsimonySitesP: "0.112201504743212", constantSitesP: "0.793915603532875", distinctSitesP: "0.148184494602552", brLenTotal: "0.3866" },
  { geneTranscript: "NM_001099754.2", parsimonySitesP: "0.233785822021116", constantSitesP: "0.631473102061337", distinctSitesP: "0.316239316239316", brLenTotal: "0.8339" },
  { geneTranscript: "NM_012133.6", parsimonySitesP: "0.130884041331803", constantSitesP: "0.775353999234596", distinctSitesP: "0.171450440107157", brLenTotal: "0.3932" },
  { geneTranscript: "NM_001039569.2", parsimonySitesP: "0.181818181818182", constantSitesP: "0.729437229437229", distinctSitesP: "0.298701298701299", brLenTotal: "0.5450" },
  { geneTranscript: "NM_004268.5", parsimonySitesP: "0.150537634408602", constantSitesP: "0.757296466973886", distinctSitesP: "0.211981566820276", brLenTotal: "0.5049" },
  { geneTranscript: "NM_001006630.2", parsimonySitesP: "0.15307582260372", constantSitesP: "0.7310443490701", distinctSitesP: "0.21101573676681", brLenTotal: "0.5195" },
  { geneTranscript: "NM_152403.4", parsimonySitesP: "0.215064420218038", constantSitesP: "0.647175421209118", distinctSitesP: "0.302609844730757", brLenTotal: "0.8576" },
  { geneTranscript: "NM_024501.3", parsimonySitesP: "0.269308943089431", constantSitesP: "0.542682926829268", distinctSitesP: "0.447154471544715", brLenTotal: "1.0650" },
  { geneTranscript: "NM_001396022.1", parsimonySitesP: "0.372549019607843", constantSitesP: "0.348039215686275", distinctSitesP: "0.632352941176471", brLenTotal: "1.6176" },
  { geneTranscript: "NM_012180.3", parsimonySitesP: "0.136886102403344", constantSitesP: "0.776384535005225", distinctSitesP: "0.170323928944619", brLenTotal: "0.3914" },
  { geneTranscript: "NM_001145118.2", parsimonySitesP: "0.21607486925406", constantSitesP: "0.681255161023947", distinctSitesP: "0.258739333883843", brLenTotal: "0.9789" },
  { geneTranscript: "NM_002512.4", parsimonySitesP: "0.175438596491228", constantSitesP: "0.644736842105263", distinctSitesP: "0.31359649122807", brLenTotal: "0.7370" },
  { geneTranscript: "NM_002427.4", parsimonySitesP: "0.211606510969568", constantSitesP: "0.67445152158528", distinctSitesP: "0.300070771408351", brLenTotal: "0.8297" },
  { geneTranscript: "NM_005324.5", parsimonySitesP: "0.176470588235294", constantSitesP: "0.691176470588235", distinctSitesP: "0.286764705882353", brLenTotal: "0.8223" },
  { geneTranscript: "NM_178526.5", parsimonySitesP: "0.205450733752621", constantSitesP: "0.708595387840671", distinctSitesP: "0.252620545073375", brLenTotal: "0.7279" },
  { geneTranscript: "NM_004077.3", parsimonySitesP: "0.143776824034335", constantSitesP: "0.718168812589413", distinctSitesP: "0.229613733905579", brLenTotal: "0.4852" },
  { geneTranscript: "NM_147156.4", parsimonySitesP: "0.110573042776433", constantSitesP: "0.824051654560129", distinctSitesP: "0.163034705407587", brLenTotal: "0.3351" },
  { geneTranscript: "NM_005576.4", parsimonySitesP: "0.183507549361208", constantSitesP: "0.700348432055749", distinctSitesP: "0.261324041811847", brLenTotal: "0.7584" },
  { geneTranscript: "NM_017595.6", parsimonySitesP: "0.176265270506108", constantSitesP: "0.753926701570681", distinctSitesP: "0.233856893542757", brLenTotal: "0.5895" },
  { geneTranscript: "NM_001282547.2", parsimonySitesP: "0.154022988505747", constantSitesP: "0.771647509578544", distinctSitesP: "0.210727969348659", brLenTotal: "0.7538" },
  { geneTranscript: "NM_138959.3", parsimonySitesP: "0.21882951653944", constantSitesP: "0.677480916030534", distinctSitesP: "0.288804071246819", brLenTotal: "0.9928" },
  { geneTranscript: "NM_016258.3", parsimonySitesP: "0.1335636154289", constantSitesP: "0.709844559585492", distinctSitesP: "0.18077144502015", brLenTotal: "0.5525" },
  { geneTranscript: "NM_001291284.2", parsimonySitesP: "0.162162162162162", constantSitesP: "0.714714714714715", distinctSitesP: "0.265765765765766", brLenTotal: "0.5391" },
  { geneTranscript: "NM_032340.4", parsimonySitesP: "0.185185185185185", constantSitesP: "0.708994708994709", distinctSitesP: "0.298941798941799", brLenTotal: "0.5483" },
  { geneTranscript: "NM_021643.4", parsimonySitesP: "0.162293488824101", constantSitesP: "0.774538386783285", distinctSitesP: "0.208940719144801", brLenTotal: "0.6063" },
  { geneTranscript: "NM_000338.3", parsimonySitesP: "0.165605095541401", constantSitesP: "0.724901425538368", distinctSitesP: "0.227782832878374", brLenTotal: "0.5910" },
  { geneTranscript: "NM_018191.4", parsimonySitesP: "0.157564344005022", constantSitesP: "0.751412429378531", distinctSitesP: "0.228499686126805", brLenTotal: "0.6097" },
  { geneTranscript: "NM_004813.4", parsimonySitesP: "0.179563492063492", constantSitesP: "0.686507936507937", distinctSitesP: "0.267857142857143", brLenTotal: "0.7196" },
  { geneTranscript: "NM_001318063.2", parsimonySitesP: "0.376106194690265", constantSitesP: "0.488200589970501", distinctSitesP: "0.470501474926254", brLenTotal: "1.1947" },
  { geneTranscript: "NM_002887.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.685858585858586", distinctSitesP: "0.280808080808081", brLenTotal: "0.6287" },
  { geneTranscript: "NM_002199.4", parsimonySitesP: "0.206303724928367", constantSitesP: "0.715377268385864", distinctSitesP: "0.275071633237822", brLenTotal: "0.7539" },
  { geneTranscript: "NM_000901.5", parsimonySitesP: "0.15650406504065", constantSitesP: "0.729674796747967", distinctSitesP: "0.215785907859079", brLenTotal: "0.6035" },
  { geneTranscript: "NM_007245.4", parsimonySitesP: "0.141705426356589", constantSitesP: "0.772713178294574", distinctSitesP: "0.191627906976744", brLenTotal: "0.4986" },
  { geneTranscript: "NM_001282663.2", parsimonySitesP: "0.200771055753262", constantSitesP: "0.665183867141163", distinctSitesP: "0.270166073546856", brLenTotal: "0.8035" },
  { geneTranscript: "NM_006539.4", parsimonySitesP: "0.0825396825396825", constantSitesP: "0.86984126984127", distinctSitesP: "0.126984126984127", brLenTotal: "0.2899" },
  { geneTranscript: "NM_001397.3", parsimonySitesP: "0.184415584415584", constantSitesP: "0.719480519480519", distinctSitesP: "0.243722943722944", brLenTotal: "0.8535" },
  { geneTranscript: "NM_001067.4", parsimonySitesP: "0.173089483997387", constantSitesP: "0.727411278031788", distinctSitesP: "0.238188547790115", brLenTotal: "0.7039" },
  { geneTranscript: "NM_006835.3", parsimonySitesP: "0.109637488947834", constantSitesP: "0.746242263483643", distinctSitesP: "0.216622458001768", brLenTotal: "0.4010" },
  { geneTranscript: "NM_000975.5", parsimonySitesP: "0.131086142322097", constantSitesP: "0.754681647940075", distinctSitesP: "0.239700374531835", brLenTotal: "0.4747" },
  { geneTranscript: "NM_002751.7", parsimonySitesP: "0.155677655677656", constantSitesP: "0.728937728937729", distinctSitesP: "0.242673992673993", brLenTotal: "0.7168" },
  { geneTranscript: "NM_001017403.2", parsimonySitesP: "0.211995863495346", constantSitesP: "0.665287831782144", distinctSitesP: "0.285418821096174", brLenTotal: "0.7967" },
  { geneTranscript: "NM_019112.4", parsimonySitesP: "0.321683752718235", constantSitesP: "0.53215284249767", distinctSitesP: "0.405871388630009", brLenTotal: "1.4814" },
  { geneTranscript: "NM_002923.4", parsimonySitesP: "0.17693522906793", constantSitesP: "0.69826224328594", distinctSitesP: "0.274881516587678", brLenTotal: "0.6078" },
  { geneTranscript: "NM_024855.4", parsimonySitesP: "0.196595277320154", constantSitesP: "0.693025809994509", distinctSitesP: "0.271279516749039", brLenTotal: "0.7138" },
  { geneTranscript: "NM_001136223.3", parsimonySitesP: "0.0711271850512357", constantSitesP: "0.851717902350814", distinctSitesP: "0.132610006027728", brLenTotal: "0.2341" },
  { geneTranscript: "NM_020706.2", parsimonySitesP: "0.138041267073525", constantSitesP: "0.773031095611741", distinctSitesP: "0.191514094739901", brLenTotal: "0.5053" },
  { geneTranscript: "NM_016417.3", parsimonySitesP: "0.220806794055202", constantSitesP: "0.554140127388535", distinctSitesP: "0.43312101910828", brLenTotal: "0.9788" },
  { geneTranscript: "NM_001080415.2", parsimonySitesP: "0.0897311305474571", constantSitesP: "0.835762876579203", distinctSitesP: "0.13022351797862", brLenTotal: "0.2686" },
  { geneTranscript: "NM_000054.7", parsimonySitesP: "0.268643306379155", constantSitesP: "0.601976639712489", distinctSitesP: "0.371069182389937", brLenTotal: "1.1820" },
  { geneTranscript: "NM_080650.4", parsimonySitesP: "0.143570536828964", constantSitesP: "0.737827715355805", distinctSitesP: "0.250936329588015", brLenTotal: "0.4676" },
  { geneTranscript: "NM_152406.4", parsimonySitesP: "0.203125", constantSitesP: "0.644965277777778", distinctSitesP: "0.276475694444444", brLenTotal: "0.8563" },
  { geneTranscript: "NM_016561.3", parsimonySitesP: "0.194814814814815", constantSitesP: "0.667407407407407", distinctSitesP: "0.26962962962963", brLenTotal: "0.6604" },
  { geneTranscript: "NM_004133.5", parsimonySitesP: "0.202197802197802", constantSitesP: "0.709157509157509", distinctSitesP: "0.271794871794872", brLenTotal: "0.6317" },
  { geneTranscript: "NM_001001667.1", parsimonySitesP: "0.293929712460064", constantSitesP: "0.522896698615548", distinctSitesP: "0.443024494142705", brLenTotal: "1.0623" },
  { geneTranscript: "NM_000255.4", parsimonySitesP: "0.152444444444444", constantSitesP: "0.743555555555556", distinctSitesP: "0.22", brLenTotal: "0.5008" },
  { geneTranscript: "NM_001134888.3", parsimonySitesP: "0.266813942071674", constantSitesP: "0.610211094747177", distinctSitesP: "0.309278350515464", brLenTotal: "1.0206" },
  { geneTranscript: "NM_033426.3", parsimonySitesP: "0.19047619047619", constantSitesP: "0.672514619883041", distinctSitesP: "0.276524644945698", brLenTotal: "0.8070" },
  { geneTranscript: "NM_001012.2", parsimonySitesP: "0.176282051282051", constantSitesP: "0.66025641025641", distinctSitesP: "0.314102564102564", brLenTotal: "0.7395" },
  { geneTranscript: "NM_007098.4", parsimonySitesP: "0.360772357723577", constantSitesP: "0.463414634146341", distinctSitesP: "0.480081300813008", brLenTotal: "1.7334" },
  { geneTranscript: "NM_014224.5", parsimonySitesP: "0.313573883161512", constantSitesP: "0.559278350515464", distinctSitesP: "0.421821305841924", brLenTotal: "1.4958" },
  { geneTranscript: "NM_015654.5", parsimonySitesP: "0.214170692431562", constantSitesP: "0.648953301127214", distinctSitesP: "0.338164251207729", brLenTotal: "0.7797" },
  { geneTranscript: "NM_017924.4", parsimonySitesP: "0.235714285714286", constantSitesP: "0.497619047619048", distinctSitesP: "0.45952380952381", brLenTotal: "0.9148" },
  { geneTranscript: "NM_001384598.1", parsimonySitesP: "0.279746835443038", constantSitesP: "0.549789029535865", distinctSitesP: "0.371308016877637", brLenTotal: "1.1659" },
  { geneTranscript: "NM_006135.3", parsimonySitesP: "0.0944055944055944", constantSitesP: "0.797202797202797", distinctSitesP: "0.187645687645688", brLenTotal: "0.3265" },
  { geneTranscript: "NM_000814.6", parsimonySitesP: "0.157152924594785", constantSitesP: "0.758985200845666", distinctSitesP: "0.205778717406624", brLenTotal: "0.8208" },
  { geneTranscript: "NM_001206999.2", parsimonySitesP: "0.136458836797164", constantSitesP: "0.787981311422587", distinctSitesP: "0.161269534396649", brLenTotal: "0.5187" },
  { geneTranscript: "NM_144723.3", parsimonySitesP: "0.112227805695142", constantSitesP: "0.804020100502513", distinctSitesP: "0.184254606365159", brLenTotal: "0.3207" },
  { geneTranscript: "NM_001354473.2", parsimonySitesP: "0.148148148148148", constantSitesP: "0.762962962962963", distinctSitesP: "0.248148148148148", brLenTotal: "0.3875" },
  { geneTranscript: "NM_005538.4", parsimonySitesP: "0.253787878787879", constantSitesP: "0.569128787878788", distinctSitesP: "0.347537878787879", brLenTotal: "0.8092" },
  { geneTranscript: "NM_024837.4", parsimonySitesP: "0.184004474272931", constantSitesP: "0.675615212527964", distinctSitesP: "0.262304250559284", brLenTotal: "0.6237" },
  { geneTranscript: "NM_014918.5", parsimonySitesP: "0.189526184538653", constantSitesP: "0.664588528678304", distinctSitesP: "0.248129675810474", brLenTotal: "0.9575" },
  { geneTranscript: "NM_001273.5", parsimonySitesP: "0.133542538354254", constantSitesP: "0.770397489539749", distinctSitesP: "0.147838214783821", brLenTotal: "0.4700" },
  { geneTranscript: "NM_002825.7", parsimonySitesP: "0.156746031746032", constantSitesP: "0.720238095238095", distinctSitesP: "0.251984126984127", brLenTotal: "0.4948" },
  { geneTranscript: "NM_006475.3", parsimonySitesP: "0.16866028708134", constantSitesP: "0.73444976076555", distinctSitesP: "0.248405103668262", brLenTotal: "0.5776" },
  { geneTranscript: "NM_001305018.2", parsimonySitesP: "0.442094662638469", constantSitesP: "0.369587109768379", distinctSitesP: "0.624370594159114", brLenTotal: "2.3049" },
  { geneTranscript: "NM_001004727.1", parsimonySitesP: "0.588558855885589", constantSitesP: "0.283828382838284", distinctSitesP: "0.697469746974697", brLenTotal: "3.8311" },
  { geneTranscript: "NM_001330442.2", parsimonySitesP: "0.113916947250281", constantSitesP: "0.787878787878788", distinctSitesP: "0.179012345679012", brLenTotal: "0.3993" },
  { geneTranscript: "NM_001318510.2", parsimonySitesP: "0.13681592039801", constantSitesP: "0.751741293532338", distinctSitesP: "0.202985074626866", brLenTotal: "0.4461" },
  { geneTranscript: "NM_001286389.2", parsimonySitesP: "0.175965665236052", constantSitesP: "0.702432045779685", distinctSitesP: "0.278969957081545", brLenTotal: "0.6642" },
  { geneTranscript: "NM_000327.4", parsimonySitesP: "0.212725546058879", constantSitesP: "0.623931623931624", distinctSitesP: "0.300094966761633", brLenTotal: "0.6785" },
  { geneTranscript: "NM_024864.5", parsimonySitesP: "0.304060434372049", constantSitesP: "0.526912181303116", distinctSitesP: "0.4438149197356", brLenTotal: "1.0786" },
  { geneTranscript: "NM_001671.5", parsimonySitesP: "0.235967926689576", constantSitesP: "0.624284077892325", distinctSitesP: "0.335624284077892", brLenTotal: "0.9716" },
  { geneTranscript: "NM_003959.3", parsimonySitesP: "0.227840199750312", constantSitesP: "0.65792759051186", distinctSitesP: "0.291198501872659", brLenTotal: "1.0541" },
  { geneTranscript: "NM_001135179.2", parsimonySitesP: "0.0925306577480491", constantSitesP: "0.807134894091416", distinctSitesP: "0.147157190635452", brLenTotal: "0.3903" },
  { geneTranscript: "NM_022841.7", parsimonySitesP: "0.121232876712329", constantSitesP: "0.77351598173516", distinctSitesP: "0.159817351598174", brLenTotal: "0.3984" },
  { geneTranscript: "NM_182902.4", parsimonySitesP: "0.19957805907173", constantSitesP: "0.653586497890295", distinctSitesP: "0.289873417721519", brLenTotal: "0.7062" },
  { geneTranscript: "NM_145203.6", parsimonySitesP: "0.272007912957468", constantSitesP: "0.573689416419387", distinctSitesP: "0.392680514342235", brLenTotal: "1.1300" },
  { geneTranscript: "NM_002467.6", parsimonySitesP: "0.18575624082232", constantSitesP: "0.681350954478708", distinctSitesP: "0.262114537444934", brLenTotal: "0.7012" },
  { geneTranscript: "NM_003745.2", parsimonySitesP: "0.161137440758294", constantSitesP: "0.737756714060032", distinctSitesP: "0.249605055292259", brLenTotal: "0.6141" },
  { geneTranscript: "NM_019023.5", parsimonySitesP: "0.244219653179191", constantSitesP: "0.623795761078998", distinctSitesP: "0.335260115606936", brLenTotal: "0.9953" },
  { geneTranscript: "NM_022130.4", parsimonySitesP: "0.114093959731544", constantSitesP: "0.802013422818792", distinctSitesP: "0.186800894854586", brLenTotal: "0.4088" },
  { geneTranscript: "NM_001347217.2", parsimonySitesP: "0.138831615120275", constantSitesP: "0.795189003436426", distinctSitesP: "0.168384879725086", brLenTotal: "0.5005" },
  { geneTranscript: "NM_000398.7", parsimonySitesP: "0.187153931339978", constantSitesP: "0.697674418604651", distinctSitesP: "0.283499446290144", brLenTotal: "0.9406" },
  { geneTranscript: "NM_014388.7", parsimonySitesP: "0.208553791887125", constantSitesP: "0.641975308641975", distinctSitesP: "0.311728395061728", brLenTotal: "0.7224" },
  { geneTranscript: "NM_153747.2", parsimonySitesP: "0.198653198653199", constantSitesP: "0.679012345679012", distinctSitesP: "0.271604938271605", brLenTotal: "0.6400" },
  { geneTranscript: "NM_003983.6", parsimonySitesP: "0.181229773462783", constantSitesP: "0.703559870550162", distinctSitesP: "0.25631067961165", brLenTotal: "0.6304" },
  { geneTranscript: "NM_007109.3", parsimonySitesP: "0.228985507246377", constantSitesP: "0.600966183574879", distinctSitesP: "0.351690821256039", brLenTotal: "0.8540" },
  { geneTranscript: "NM_145804.3", parsimonySitesP: "0.173008130081301", constantSitesP: "0.677073170731707", distinctSitesP: "0.215934959349593", brLenTotal: "1.1516" },
  { geneTranscript: "NM_022575.4", parsimonySitesP: "0.158522050059595", constantSitesP: "0.745729042510926", distinctSitesP: "0.214541120381406", brLenTotal: "0.6140" },
  { geneTranscript: "NM_001382508.1", parsimonySitesP: "0.140223192624939", constantSitesP: "0.774623968947113", distinctSitesP: "0.179767103347889", brLenTotal: "0.5155" },
  { geneTranscript: "NM_003519.4", parsimonySitesP: "0.187830687830688", constantSitesP: "0.73015873015873", distinctSitesP: "0.283068783068783", brLenTotal: "1.2188" },
  { geneTranscript: "NM_003538.4", parsimonySitesP: "0.249190938511327", constantSitesP: "0.686084142394822", distinctSitesP: "0.317152103559871", brLenTotal: "1.1475" },
  { geneTranscript: "NM_001040402.3", parsimonySitesP: "0.0947488584474886", constantSitesP: "0.819634703196347", distinctSitesP: "0.180365296803653", brLenTotal: "0.3653" },
  { geneTranscript: "NM_203436.3", parsimonySitesP: "0.312015503875969", constantSitesP: "0.472868217054264", distinctSitesP: "0.527131782945736", brLenTotal: "1.1537" },
  { geneTranscript: "NM_022146.5", parsimonySitesP: "0.22093023255814", constantSitesP: "0.624806201550388", distinctSitesP: "0.332558139534884", brLenTotal: "0.8905" },
  { geneTranscript: "NM_080491.3", parsimonySitesP: "0.178500986193294", constantSitesP: "0.702169625246548", distinctSitesP: "0.249506903353057", brLenTotal: "0.6923" },
  { geneTranscript: "NM_001161476.3", parsimonySitesP: "0.29656862745098", constantSitesP: "0.560049019607843", distinctSitesP: "0.414828431372549", brLenTotal: "1.1484" },
  { geneTranscript: "NM_005817.5", parsimonySitesP: "0.324116743471582", constantSitesP: "0.516129032258065", distinctSitesP: "0.496927803379416", brLenTotal: "1.4023" },
  { geneTranscript: "NM_004837.4", parsimonySitesP: "0.144444444444444", constantSitesP: "0.742222222222222", distinctSitesP: "0.231111111111111", brLenTotal: "0.5114" },
  { geneTranscript: "NM_001193451.2", parsimonySitesP: "0.187830687830688", constantSitesP: "0.667422524565382", distinctSitesP: "0.277777777777778", brLenTotal: "0.7012" },
  { geneTranscript: "NM_001085447.2", parsimonySitesP: "0.198671497584541", constantSitesP: "0.631642512077295", distinctSitesP: "0.333937198067633", brLenTotal: "0.6437" },
  { geneTranscript: "NM_024589.3", parsimonySitesP: "0.211382113821138", constantSitesP: "0.70267131242741", distinctSitesP: "0.288037166085947", brLenTotal: "0.9369" },
  { geneTranscript: "NM_001005190.2", parsimonySitesP: "0.52319309600863", constantSitesP: "0.294498381877023", distinctSitesP: "0.666666666666667", brLenTotal: "3.8589" },
  { geneTranscript: "NM_002898.4", parsimonySitesP: "0.146601146601147", constantSitesP: "0.723996723996724", distinctSitesP: "0.257985257985258", brLenTotal: "0.5038" },
  { geneTranscript: "NM_018321.4", parsimonySitesP: "0.186024551463645", constantSitesP: "0.677998111425873", distinctSitesP: "0.316336166194523", brLenTotal: "0.6560" },
  { geneTranscript: "NM_014687.4", parsimonySitesP: "0.227023319615912", constantSitesP: "0.642318244170096", distinctSitesP: "0.29320987654321", brLenTotal: "0.7702" },
  { geneTranscript: "NM_020384.4", parsimonySitesP: "0.185507246376812", constantSitesP: "0.717391304347826", distinctSitesP: "0.266666666666667", brLenTotal: "0.5656" },
  { geneTranscript: "NM_133368.3", parsimonySitesP: "0.109375", constantSitesP: "0.798032407407407", distinctSitesP: "0.172453703703704", brLenTotal: "0.3588" },
  { geneTranscript: "NM_001370096.2", parsimonySitesP: "0.353448275862069", constantSitesP: "0.529693486590038", distinctSitesP: "0.440613026819923", brLenTotal: "1.5645" },
  { geneTranscript: "NM_016541.3", parsimonySitesP: "0.208955223880597", constantSitesP: "0.706467661691542", distinctSitesP: "0.313432835820896", brLenTotal: "0.8296" },
  { geneTranscript: "NM_020745.4", parsimonySitesP: "0.251099830795262", constantSitesP: "0.6165820642978", distinctSitesP: "0.306598984771574", brLenTotal: "0.8834" },
  { geneTranscript: "NM_006370.3", parsimonySitesP: "0.193965517241379", constantSitesP: "0.640804597701149", distinctSitesP: "0.333333333333333", brLenTotal: "0.6491" },
  { geneTranscript: "NM_019605.5", parsimonySitesP: "0.164794007490637", constantSitesP: "0.713483146067416", distinctSitesP: "0.26685393258427", brLenTotal: "0.5662" },
  { geneTranscript: "NM_144571.3", parsimonySitesP: "0.0342342342342342", constantSitesP: "0.918318318318318", distinctSitesP: "0.0720720720720721", brLenTotal: "0.1257" },
  { geneTranscript: "NM_021975.4", parsimonySitesP: "0.187537810042347", constantSitesP: "0.696914700544465", distinctSitesP: "0.257108287961283", brLenTotal: "0.6352" },
  { geneTranscript: "NM_001114108.2", parsimonySitesP: "0.199179847685999", constantSitesP: "0.650263620386643", distinctSitesP: "0.293497363796134", brLenTotal: "0.7942" },
  { geneTranscript: "NM_001939.3", parsimonySitesP: "0.144548937652386", constantSitesP: "0.757924068268896", distinctSitesP: "0.176245210727969", brLenTotal: "0.4465" },
  { geneTranscript: "NM_005689.4", parsimonySitesP: "0.219319081551861", constantSitesP: "0.649643705463183", distinctSitesP: "0.288598574821853", brLenTotal: "0.7404" },
  { geneTranscript: "NM_001099274.3", parsimonySitesP: "0.275683665927568", constantSitesP: "0.53880266075388", distinctSitesP: "0.413155949741316", brLenTotal: "0.8305" },
  { geneTranscript: "NM_016582.3", parsimonySitesP: "0.244979919678715", constantSitesP: "0.605278255880666", distinctSitesP: "0.345381526104418", brLenTotal: "1.0307" },
  { geneTranscript: "NM_001287491.2", parsimonySitesP: "0.183286908077994", constantSitesP: "0.700464252553389", distinctSitesP: "0.208356545961003", brLenTotal: "0.7076" },
  { geneTranscript: "NM_000276.4", parsimonySitesP: "0.132075471698113", constantSitesP: "0.776174620791713", distinctSitesP: "0.172401035886053", brLenTotal: "0.3842" },
  { geneTranscript: "NM_014481.4", parsimonySitesP: "0.286357786357786", constantSitesP: "0.508365508365508", distinctSitesP: "0.431788931788932", brLenTotal: "1.0313" },
  { geneTranscript: "NM_052917.4", parsimonySitesP: "0.108513189448441", constantSitesP: "0.810551558752998", distinctSitesP: "0.172062350119904", brLenTotal: "0.3405" },
  { geneTranscript: "NM_144982.5", parsimonySitesP: "0.119322942852355", constantSitesP: "0.766381766381766", distinctSitesP: "0.158035863918217", brLenTotal: "0.4146" },
  { geneTranscript: "NM_022768.5", parsimonySitesP: "0.136472193790515", constantSitesP: "0.761514841351075", distinctSitesP: "0.190037529853292", brLenTotal: "0.4118" },
  { geneTranscript: "NM_001270454.2", parsimonySitesP: "0.161685823754789", constantSitesP: "0.74176245210728", distinctSitesP: "0.215708812260536", brLenTotal: "0.6411" },
  { geneTranscript: "NM_007019.4", parsimonySitesP: "0.160148975791434", constantSitesP: "0.659217877094972", distinctSitesP: "0.346368715083799", brLenTotal: "0.6742" },
  { geneTranscript: "NM_172341.4", parsimonySitesP: "0.115511551155116", constantSitesP: "0.745874587458746", distinctSitesP: "0.247524752475248", brLenTotal: "0.3803" },
  { geneTranscript: "NM_012249.4", parsimonySitesP: "0.0536585365853659", constantSitesP: "0.866666666666667", distinctSitesP: "0.130081300813008", brLenTotal: "0.2018" },
  { geneTranscript: "NM_012072.4", parsimonySitesP: "0.369631901840491", constantSitesP: "0.461656441717791", distinctSitesP: "0.49079754601227", brLenTotal: "1.6151" },
  { geneTranscript: "NM_001005212.4", parsimonySitesP: "0.509677419354839", constantSitesP: "0.301075268817204", distinctSitesP: "0.658064516129032", brLenTotal: "2.7062" },
  { geneTranscript: "NM_022767.4", parsimonySitesP: "0.281025641025641", constantSitesP: "0.546666666666667", distinctSitesP: "0.402051282051282", brLenTotal: "1.1164" },
  { geneTranscript: "NM_001045.6", parsimonySitesP: "0.177777777777778", constantSitesP: "0.716402116402116", distinctSitesP: "0.280952380952381", brLenTotal: "0.7691" },
  { geneTranscript: "NM_003705.5", parsimonySitesP: "0.133235004916421", constantSitesP: "0.768436578171091", distinctSitesP: "0.185349065880039", brLenTotal: "0.4563" },
  { geneTranscript: "NM_001109763.2", parsimonySitesP: "0.117824773413897", constantSitesP: "0.80563947633434", distinctSitesP: "0.199395770392749", brLenTotal: "0.4245" },
  { geneTranscript: "NM_001042388.3", parsimonySitesP: "0.171929824561404", constantSitesP: "0.70280701754386", distinctSitesP: "0.252631578947368", brLenTotal: "0.6214" },
  { geneTranscript: "NM_030805.4", parsimonySitesP: "0.148467432950192", constantSitesP: "0.745210727969349", distinctSitesP: "0.217432950191571", brLenTotal: "0.4582" },
  { geneTranscript: "NM_004179.3", parsimonySitesP: "0.163663663663664", constantSitesP: "0.71996996996997", distinctSitesP: "0.238738738738739", brLenTotal: "0.5277" },
  { geneTranscript: "NM_182612.4", parsimonySitesP: "0.237878787878788", constantSitesP: "0.645454545454545", distinctSitesP: "0.357575757575758", brLenTotal: "1.1926" },
  { geneTranscript: "NM_005232.5", parsimonySitesP: "0.235655737704918", constantSitesP: "0.638319672131148", distinctSitesP: "0.296789617486339", brLenTotal: "0.9586" },
  { geneTranscript: "NM_001374385.1", parsimonySitesP: "0.211830535571543", constantSitesP: "0.675459632294165", distinctSitesP: "0.30402344790834", brLenTotal: "0.8203" },
  { geneTranscript: "NM_178040.4", parsimonySitesP: "0.191457586618877", constantSitesP: "0.686977299880526", distinctSitesP: "0.226702508960573", brLenTotal: "0.6920" },
  { geneTranscript: "NM_022662.4", parsimonySitesP: "0.150720164609053", constantSitesP: "0.746742112482853", distinctSitesP: "0.190500685871056", brLenTotal: "0.5054" },
  { geneTranscript: "NM_002074.5", parsimonySitesP: "0.230392156862745", constantSitesP: "0.713725490196078", distinctSitesP: "0.291176470588235", brLenTotal: "1.2463" },
  { geneTranscript: "NM_138387.4", parsimonySitesP: "0.205202312138728", constantSitesP: "0.673410404624277", distinctSitesP: "0.280346820809249", brLenTotal: "0.7142" },
  { geneTranscript: "NM_001039841.3", parsimonySitesP: "0.215980024968789", constantSitesP: "0.636704119850187", distinctSitesP: "0.368289637952559", brLenTotal: "0.6766" },
  { geneTranscript: "NM_182947.4", parsimonySitesP: "0.177011494252874", constantSitesP: "0.681034482758621", distinctSitesP: "0.259195402298851", brLenTotal: "0.5878" },
  { geneTranscript: "NM_018303.6", parsimonySitesP: "0.189393939393939", constantSitesP: "0.696608946608947", distinctSitesP: "0.277056277056277", brLenTotal: "0.7136" },
  { geneTranscript: "NM_005468.3", parsimonySitesP: "0.263513513513514", constantSitesP: "0.607657657657658", distinctSitesP: "0.335135135135135", brLenTotal: "1.0125" },
  { geneTranscript: "NM_014300.4", parsimonySitesP: "0.119180633147114", constantSitesP: "0.778398510242086", distinctSitesP: "0.212290502793296", brLenTotal: "0.4641" },
  { geneTranscript: "NM_014619.5", parsimonySitesP: "0.111924686192469", constantSitesP: "0.792189679218968", distinctSitesP: "0.171199442119944", brLenTotal: "0.4493" },
  { geneTranscript: "NM_006747.4", parsimonySitesP: "0.228406909788868", constantSitesP: "0.622520793346129", distinctSitesP: "0.27831094049904", brLenTotal: "0.9502" },
  { geneTranscript: "NM_019096.5", parsimonySitesP: "0.122923588039867", constantSitesP: "0.795681063122924", distinctSitesP: "0.174972314507198", brLenTotal: "0.4000" },
  { geneTranscript: "NM_002821.5", parsimonySitesP: "0.184735202492212", constantSitesP: "0.694392523364486", distinctSitesP: "0.224922118380062", brLenTotal: "0.7237" },
  { geneTranscript: "NM_002156.5", parsimonySitesP: "0.161140197789412", constantSitesP: "0.617801047120419", distinctSitesP: "0.311227457824316", brLenTotal: "0.7604" },
  { geneTranscript: "NM_015901.6", parsimonySitesP: "0.204545454545455", constantSitesP: "0.636363636363636", distinctSitesP: "0.325757575757576", brLenTotal: "0.6395" },
  { geneTranscript: "NM_005207.4", parsimonySitesP: "0.133113311331133", constantSitesP: "0.771177117711771", distinctSitesP: "0.215621562156216", brLenTotal: "0.4469" },
  { geneTranscript: "NM_145012.6", parsimonySitesP: "0.112414467253177", constantSitesP: "0.820136852394917", distinctSitesP: "0.170087976539589", brLenTotal: "0.4125" },
  { geneTranscript: "NM_004883.3", parsimonySitesP: "0.125098039215686", constantSitesP: "0.774509803921569", distinctSitesP: "0.212549019607843", brLenTotal: "0.4827" },
  { geneTranscript: "NM_017491.5", parsimonySitesP: "0.198019801980198", constantSitesP: "0.674367436743674", distinctSitesP: "0.273927392739274", brLenTotal: "0.8942" },
  { geneTranscript: "NM_001010917.3", parsimonySitesP: "0.139720558882236", constantSitesP: "0.7624750499002", distinctSitesP: "0.239520958083832", brLenTotal: "0.5862" },
  { geneTranscript: "NM_021098.3", parsimonySitesP: "0.329366765830854", constantSitesP: "0.540444822212778", distinctSitesP: "0.423431080889644", brLenTotal: "1.7963" },
  { geneTranscript: "NM_001006638.3", parsimonySitesP: "0.188340807174888", constantSitesP: "0.690582959641256", distinctSitesP: "0.316890881913303", brLenTotal: "0.6855" },
  { geneTranscript: "NM_024757.5", parsimonySitesP: "0.231638418079096", constantSitesP: "0.668464304057524", distinctSitesP: "0.282999486389317", brLenTotal: "1.0758" },
  { geneTranscript: "NM_198239.2", parsimonySitesP: "0.234463276836158", constantSitesP: "0.609227871939736", distinctSitesP: "0.360640301318267", brLenTotal: "0.8027" },
  { geneTranscript: "NM_001174103.2", parsimonySitesP: "0.211382113821138", constantSitesP: "0.639837398373984", distinctSitesP: "0.334959349593496", brLenTotal: "0.6334" },
  { geneTranscript: "NM_003302.3", parsimonySitesP: "0.23109243697479", constantSitesP: "0.609243697478992", distinctSitesP: "0.353641456582633", brLenTotal: "0.9820" },
  { geneTranscript: "NM_052969.3", parsimonySitesP: "0.287581699346405", constantSitesP: "0.372549019607843", distinctSitesP: "0.581699346405229", brLenTotal: "1.3669" },
  { geneTranscript: "NM_015908.6", parsimonySitesP: "0.177321156773212", constantSitesP: "0.740487062404871", distinctSitesP: "0.213850837138508", brLenTotal: "0.7380" },
  { geneTranscript: "NM_003673.4", parsimonySitesP: "0.199600798403194", constantSitesP: "0.672654690618762", distinctSitesP: "0.301397205588822", brLenTotal: "0.7517" },
  { geneTranscript: "NM_003350.3", parsimonySitesP: "0.177011494252874", constantSitesP: "0.657471264367816", distinctSitesP: "0.326436781609195", brLenTotal: "0.8672" },
  { geneTranscript: "NM_014505.6", parsimonySitesP: "0.0984126984126984", constantSitesP: "0.83015873015873", distinctSitesP: "0.157142857142857", brLenTotal: "0.2975" },
  { geneTranscript: "NM_000017.4", parsimonySitesP: "0.210355987055016", constantSitesP: "0.677184466019417", distinctSitesP: "0.310679611650485", brLenTotal: "0.9351" },
  { geneTranscript: "NM_004693.3", parsimonySitesP: "0.305505142165759", constantSitesP: "0.53599516031458", distinctSitesP: "0.4162129461585", brLenTotal: "1.4170" },
  { geneTranscript: "NM_015458.4", parsimonySitesP: "0.201578627808136", constantSitesP: "0.695810564663024", distinctSitesP: "0.290831815421979", brLenTotal: "0.7135" },
  { geneTranscript: "NM_016084.5", parsimonySitesP: "0.139976275207592", constantSitesP: "0.743772241992883", distinctSitesP: "0.232502965599051", brLenTotal: "0.4950" },
  { geneTranscript: "NM_175709.5", parsimonySitesP: "0.110225763612218", constantSitesP: "0.820717131474104", distinctSitesP: "0.166002656042497", brLenTotal: "0.3872" },
  { geneTranscript: "NM_001801.3", parsimonySitesP: "0.196666666666667", constantSitesP: "0.685", distinctSitesP: "0.31", brLenTotal: "0.6570" },
  { geneTranscript: "NM_015497.5", parsimonySitesP: "0.127927927927928", constantSitesP: "0.787387387387387", distinctSitesP: "0.207807807807808", brLenTotal: "0.3832" },
  { geneTranscript: "NM_201599.3", parsimonySitesP: "0.114355231143552", constantSitesP: "0.803649635036496", distinctSitesP: "0.139659367396594", brLenTotal: "0.3573" },
  { geneTranscript: "NM_005345.6", parsimonySitesP: "0.323452938117525", constantSitesP: "0.596463858554342", distinctSitesP: "0.358294331773271", brLenTotal: "1.9764" },
  { geneTranscript: "NM_021807.4", parsimonySitesP: "0.17419575633128", constantSitesP: "0.724845995893224", distinctSitesP: "0.225530458590007", brLenTotal: "0.6278" },
  { geneTranscript: "NM_003758.4", parsimonySitesP: "0.116279069767442", constantSitesP: "0.764857881136951", distinctSitesP: "0.22609819121447", brLenTotal: "0.3829" },
  { geneTranscript: "NM_001014440.4", parsimonySitesP: "0.308300395256917", constantSitesP: "0.561264822134387", distinctSitesP: "0.404479578392622", brLenTotal: "1.0632" },
  { geneTranscript: "NM_005920.4", parsimonySitesP: "0.133717210492642", constantSitesP: "0.777991042866283", distinctSitesP: "0.202815099168266", brLenTotal: "0.5071" },
  { geneTranscript: "NM_020917.3", parsimonySitesP: "0.303314571607255", constantSitesP: "0.542839274546592", distinctSitesP: "0.381488430268918", brLenTotal: "1.1697" },
  { geneTranscript: "NM_001124.3", parsimonySitesP: "0.255855855855856", constantSitesP: "0.572972972972973", distinctSitesP: "0.41981981981982", brLenTotal: "0.8596" },
  { geneTranscript: "NM_001006617.3", parsimonySitesP: "0.0862068965517241", constantSitesP: "0.841634738186462", distinctSitesP: "0.136653895274585", brLenTotal: "0.3167" },
  { geneTranscript: "NM_001193360.2", parsimonySitesP: "0.193236714975845", constantSitesP: "0.666129898013956", distinctSitesP: "0.271068169618894", brLenTotal: "0.6973" },
  { geneTranscript: "NM_014813.3", parsimonySitesP: "0.202503912363067", constantSitesP: "0.671048513302034", distinctSitesP: "0.279499217527387", brLenTotal: "0.7539" },
  { geneTranscript: "NM_152285.4", parsimonySitesP: "0.24788298691301", constantSitesP: "0.622016936104696", distinctSitesP: "0.336412625096228", brLenTotal: "1.1177" },
  { geneTranscript: "NM_001386823.1", parsimonySitesP: "0.18970189701897", constantSitesP: "0.618789521228546", distinctSitesP: "0.34327009936766", brLenTotal: "0.6574" },
  { geneTranscript: "NM_001297719.2", parsimonySitesP: "0.10010649627263", constantSitesP: "0.81629392971246", distinctSitesP: "0.151757188498403", brLenTotal: "0.3455" },
  { geneTranscript: "NM_004397.6", parsimonySitesP: "0.0710835058661146", constantSitesP: "0.820565907522429", distinctSitesP: "0.153209109730849", brLenTotal: "0.2577" },
  { geneTranscript: "NM_174893.4", parsimonySitesP: "0.0968992248062016", constantSitesP: "0.810077519379845", distinctSitesP: "0.182170542635659", brLenTotal: "0.3107" },
  { geneTranscript: "NM_014323.3", parsimonySitesP: "0.118874332848132", constantSitesP: "0.812712275594372", distinctSitesP: "0.154779233381853", brLenTotal: "0.3915" },
  { geneTranscript: "NM_001394198.1", parsimonySitesP: "0.232828282828283", constantSitesP: "0.646969696969697", distinctSitesP: "0.310606060606061", brLenTotal: "1.3748" },
  { geneTranscript: "NM_002852.4", parsimonySitesP: "0.255468066491689", constantSitesP: "0.572178477690289", distinctSitesP: "0.363079615048119", brLenTotal: "0.8428" },
  { geneTranscript: "NM_018279.4", parsimonySitesP: "0.173611111111111", constantSitesP: "0.721230158730159", distinctSitesP: "0.259920634920635", brLenTotal: "0.5392" },
  { geneTranscript: "NM_153347.3", parsimonySitesP: "0.172222222222222", constantSitesP: "0.723611111111111", distinctSitesP: "0.256944444444444", brLenTotal: "0.6200" },
  { geneTranscript: "NM_003281.4", parsimonySitesP: "0.18716577540107", constantSitesP: "0.736185383244207", distinctSitesP: "0.263814616755793", brLenTotal: "0.8897" },
  { geneTranscript: "NM_014060.3", parsimonySitesP: "0.112338858195212", constantSitesP: "0.801104972375691", distinctSitesP: "0.202578268876611", brLenTotal: "0.4064" },
  { geneTranscript: "NM_001098212.2", parsimonySitesP: "0.242299794661191", constantSitesP: "0.593429158110883", distinctSitesP: "0.371663244353183", brLenTotal: "0.9019" },
  { geneTranscript: "NM_032037.4", parsimonySitesP: "0.179487179487179", constantSitesP: "0.721611721611722", distinctSitesP: "0.251526251526252", brLenTotal: "0.6334" },
  { geneTranscript: "NM_022468.5", parsimonySitesP: "0.287069988137604", constantSitesP: "0.582443653618031", distinctSitesP: "0.379596678529063", brLenTotal: "1.2535" },
  { geneTranscript: "NM_001123168.3", parsimonySitesP: "0.138702460850112", constantSitesP: "0.747203579418345", distinctSitesP: "0.246085011185682", brLenTotal: "0.4187" },
  { geneTranscript: "NM_006141.3", parsimonySitesP: "0.12059620596206", constantSitesP: "0.774390243902439", distinctSitesP: "0.190379403794038", brLenTotal: "0.4356" },
  { geneTranscript: "NM_003692.5", parsimonySitesP: "0.104385964912281", constantSitesP: "0.819298245614035", distinctSitesP: "0.184210526315789", brLenTotal: "0.3036" },
  { geneTranscript: "NM_001129778.3", parsimonySitesP: "0.177966101694915", constantSitesP: "0.686440677966102", distinctSitesP: "0.322033898305085", brLenTotal: "0.7369" },
  { geneTranscript: "NM_138358.4", parsimonySitesP: "0.307692307692308", constantSitesP: "0.502564102564103", distinctSitesP: "0.456410256410256", brLenTotal: "1.1131" },
  { geneTranscript: "NM_170686.3", parsimonySitesP: "0.207165109034268", constantSitesP: "0.666147455867082", distinctSitesP: "0.266355140186916", brLenTotal: "0.8956" },
  { geneTranscript: "NM_198999.3", parsimonySitesP: "0.140681003584229", constantSitesP: "0.756720430107527", distinctSitesP: "0.214605734767025", brLenTotal: "0.5116" },
  { geneTranscript: "NM_013262.4", parsimonySitesP: "0.158801498127341", constantSitesP: "0.738576779026217", distinctSitesP: "0.230711610486891", brLenTotal: "0.6406" },
  { geneTranscript: "NM_133178.4", parsimonySitesP: "0.184076137418756", constantSitesP: "0.70636025998143", distinctSitesP: "0.217966573816156", brLenTotal: "0.9653" },
  { geneTranscript: "NM_022742.5", parsimonySitesP: "0.260254188330445", constantSitesP: "0.579145002888504", distinctSitesP: "0.37579433853264", brLenTotal: "0.9161" },
  { geneTranscript: "NM_002889.4", parsimonySitesP: "0.347648261758691", constantSitesP: "0.49079754601227", distinctSitesP: "0.478527607361963", brLenTotal: "1.1673" },
  { geneTranscript: "NM_003968.4", parsimonySitesP: "0.120230381569474", constantSitesP: "0.777537796976242", distinctSitesP: "0.231821454283657", brLenTotal: "0.4009" },
  { geneTranscript: "NM_006078.5", parsimonySitesP: "0.0784313725490196", constantSitesP: "0.771929824561404", distinctSitesP: "0.144478844169247", brLenTotal: "0.4008" },
  { geneTranscript: "NM_005297.4", parsimonySitesP: "0.176581680830973", constantSitesP: "0.734655335221907", distinctSitesP: "0.244570349386213", brLenTotal: "0.8785" },
  { geneTranscript: "NM_006924.5", parsimonySitesP: "0.0577956989247312", constantSitesP: "0.844086021505376", distinctSitesP: "0.137096774193548", brLenTotal: "0.2394" },
  { geneTranscript: "NM_004060.4", parsimonySitesP: "0.140112994350282", constantSitesP: "0.690395480225989", distinctSitesP: "0.271186440677966", brLenTotal: "0.5129" },
  { geneTranscript: "NM_007348.4", parsimonySitesP: "0.174626865671642", constantSitesP: "0.669154228855721", distinctSitesP: "0.298009950248756", brLenTotal: "0.6297" },
  { geneTranscript: "NM_018960.6", parsimonySitesP: "0.186440677966102", constantSitesP: "0.703954802259887", distinctSitesP: "0.270056497175141", brLenTotal: "0.6429" },
  { geneTranscript: "NM_004736.4", parsimonySitesP: "0.109195402298851", constantSitesP: "0.817049808429119", distinctSitesP: "0.153735632183908", brLenTotal: "0.3217" },
  { geneTranscript: "NM_017510.6", parsimonySitesP: "0.219858156028369", constantSitesP: "0.666666666666667", distinctSitesP: "0.320567375886525", brLenTotal: "0.7842" },
  { geneTranscript: "NM_003335.3", parsimonySitesP: "0.272727272727273", constantSitesP: "0.55368906455863", distinctSitesP: "0.362977602108037", brLenTotal: "0.9422" },
  { geneTranscript: "NM_023012.6", parsimonySitesP: "0.124423963133641", constantSitesP: "0.774193548387097", distinctSitesP: "0.204301075268817", brLenTotal: "0.3903" },
  { geneTranscript: "NM_001142311.2", parsimonySitesP: "0.22334455667789", constantSitesP: "0.682379349046016", distinctSitesP: "0.297418630751964", brLenTotal: "0.7439" },
  { geneTranscript: "NM_007203.5", parsimonySitesP: "0.221517074644908", constantSitesP: "0.650045330915684", distinctSitesP: "0.298277425203989", brLenTotal: "0.8549" },
  { geneTranscript: "NM_020703.4", parsimonySitesP: "0.139283299526707", constantSitesP: "0.751859364435429", distinctSitesP: "0.188640973630832", brLenTotal: "0.5004" },
  { geneTranscript: "NM_001001330.3", parsimonySitesP: "0.150326797385621", constantSitesP: "0.743790849673203", distinctSitesP: "0.235294117647059", brLenTotal: "0.4781" },
  { geneTranscript: "NM_005545.4", parsimonySitesP: "0.276479750778816", constantSitesP: "0.557632398753894", distinctSitesP: "0.363707165109034", brLenTotal: "1.4348" },
  { geneTranscript: "NM_001130.6", parsimonySitesP: "0.181049069373942", constantSitesP: "0.754653130287648", distinctSitesP: "0.260575296108291", brLenTotal: "0.8310" },
  { geneTranscript: "NM_006784.3", parsimonySitesP: "0.165782962177448", constantSitesP: "0.719688936019795", distinctSitesP: "0.249911629551078", brLenTotal: "0.5817" },
  { geneTranscript: "NM_014886.6", parsimonySitesP: "0.138461538461538", constantSitesP: "0.701282051282051", distinctSitesP: "0.273076923076923", brLenTotal: "0.5860" },
  { geneTranscript: "NM_020407.5", parsimonySitesP: "0.256186317321689", constantSitesP: "0.579330422125182", distinctSitesP: "0.380640465793304", brLenTotal: "0.9259" },
  { geneTranscript: "NM_006950.3", parsimonySitesP: "0.138534278959811", constantSitesP: "0.770212765957447", distinctSitesP: "0.185342789598109", brLenTotal: "0.5415" },
  { geneTranscript: "NM_000784.4", parsimonySitesP: "0.283113622096673", constantSitesP: "0.55743879472693", distinctSitesP: "0.386064030131827", brLenTotal: "1.0234" },
  { geneTranscript: "NM_080826.2", parsimonySitesP: "0.204741379310345", constantSitesP: "0.672413793103448", distinctSitesP: "0.280172413793103", brLenTotal: "0.8204" },
  { geneTranscript: "NM_002295.6", parsimonySitesP: "0.161581920903955", constantSitesP: "0.628248587570621", distinctSitesP: "0.331073446327684", brLenTotal: "0.7522" },
  { geneTranscript: "NM_005544.3", parsimonySitesP: "0.209339774557166", constantSitesP: "0.664519592055824", distinctSitesP: "0.256843800322061", brLenTotal: "0.7565" },
  { geneTranscript: "NM_014905.5", parsimonySitesP: "0.118086696562033", constantSitesP: "0.788739412057798", distinctSitesP: "0.192326856003986", brLenTotal: "0.3758" },
  { geneTranscript: "NM_005165.3", parsimonySitesP: "0.156593406593407", constantSitesP: "0.718864468864469", distinctSitesP: "0.228021978021978", brLenTotal: "0.5493" },
  { geneTranscript: "NM_145017.3", parsimonySitesP: "0.332549019607843", constantSitesP: "0.494117647058824", distinctSitesP: "0.517647058823529", brLenTotal: "1.2640" },
  { geneTranscript: "NM_001098787.2", parsimonySitesP: "0.234234234234234", constantSitesP: "0.630630630630631", distinctSitesP: "0.396396396396396", brLenTotal: "0.8785" },
  { geneTranscript: "NM_001143775.2", parsimonySitesP: "0.0860655737704918", constantSitesP: "0.806010928961749", distinctSitesP: "0.174863387978142", brLenTotal: "0.3422" },
  { geneTranscript: "NM_003885.3", parsimonySitesP: "0.110749185667752", constantSitesP: "0.771986970684039", distinctSitesP: "0.174809989142237", brLenTotal: "0.4387" },
  { geneTranscript: "NM_182519.3", parsimonySitesP: "0.270358306188925", constantSitesP: "0.580890336590662", distinctSitesP: "0.398479913137894", brLenTotal: "1.1323" },
  { geneTranscript: "NM_022087.4", parsimonySitesP: "0.227521929824561", constantSitesP: "0.668859649122807", distinctSitesP: "0.304276315789474", brLenTotal: "0.8794" },
  { geneTranscript: "NM_015607.4", parsimonySitesP: "0.104838709677419", constantSitesP: "0.75", distinctSitesP: "0.216397849462366", brLenTotal: "0.4220" },
  { geneTranscript: "NM_003240.5", parsimonySitesP: "0.307832422586521", constantSitesP: "0.502732240437158", distinctSitesP: "0.445355191256831", brLenTotal: "1.4118" },
  { geneTranscript: "NM_020405.5", parsimonySitesP: "0.224", constantSitesP: "0.634666666666667", distinctSitesP: "0.320666666666667", brLenTotal: "0.8812" },
  { geneTranscript: "NM_033318.5", parsimonySitesP: "0.264797507788162", constantSitesP: "0.573208722741433", distinctSitesP: "0.426791277258567", brLenTotal: "0.8829" },
  { geneTranscript: "NM_181774.4", parsimonySitesP: "0.264539007092199", constantSitesP: "0.588652482269504", distinctSitesP: "0.382269503546099", brLenTotal: "1.0525" },
  { geneTranscript: "NM_199069.2", parsimonySitesP: "0.278985507246377", constantSitesP: "0.552536231884058", distinctSitesP: "0.445652173913043", brLenTotal: "0.9103" },
  { geneTranscript: "NM_022739.4", parsimonySitesP: "0.14126559714795", constantSitesP: "0.737967914438503", distinctSitesP: "0.201871657754011", brLenTotal: "0.5613" },
  { geneTranscript: "NM_014853.3", parsimonySitesP: "0.200761179828735", constantSitesP: "0.684744687599112", distinctSitesP: "0.248969235648589", brLenTotal: "0.7662" },
  { geneTranscript: "NM_018100.4", parsimonySitesP: "0.204166666666667", constantSitesP: "0.653125", distinctSitesP: "0.315625", brLenTotal: "0.6854" },
  { geneTranscript: "NM_005622.4", parsimonySitesP: "0.215017064846416", constantSitesP: "0.64391353811149", distinctSitesP: "0.339021615472127", brLenTotal: "0.6988" },
  { geneTranscript: "NM_001012361.4", parsimonySitesP: "0.1952770208901", constantSitesP: "0.676657584014532", distinctSitesP: "0.287920072661217", brLenTotal: "0.6348" },
  { geneTranscript: "NM_024953.4", parsimonySitesP: "0.0997942386831276", constantSitesP: "0.797325102880658", distinctSitesP: "0.148148148148148", brLenTotal: "0.3300" },
  { geneTranscript: "NM_000696.4", parsimonySitesP: "0.231016731016731", constantSitesP: "0.633204633204633", distinctSitesP: "0.330759330759331", brLenTotal: "0.9152" },
  { geneTranscript: "NM_001002917.2", parsimonySitesP: "0.567099567099567", constantSitesP: "0.323593073593074", distinctSitesP: "0.675324675324675", brLenTotal: "5.0339" },
  { geneTranscript: "NM_014391.3", parsimonySitesP: "0.172413793103448", constantSitesP: "0.700104493207941", distinctSitesP: "0.283176593521421", brLenTotal: "0.6380" },
  { geneTranscript: "NM_032885.6", parsimonySitesP: "0.208157524613221", constantSitesP: "0.674402250351617", distinctSitesP: "0.283403656821378", brLenTotal: "0.7938" },
  { geneTranscript: "NM_001128219.3", parsimonySitesP: "0.184684684684685", constantSitesP: "0.712837837837838", distinctSitesP: "0.27027027027027", brLenTotal: "1.1243" },
  { geneTranscript: "NM_175921.6", parsimonySitesP: "0.155328798185941", constantSitesP: "0.726757369614512", distinctSitesP: "0.251700680272109", brLenTotal: "0.4974" },
  { geneTranscript: "NM_003954.5", parsimonySitesP: "0.229848644843365", constantSitesP: "0.636043646603309", distinctSitesP: "0.301654347060894", brLenTotal: "0.9420" },
  { geneTranscript: "NM_005927.5", parsimonySitesP: "0.161141804788214", constantSitesP: "0.705340699815838", distinctSitesP: "0.269797421731123", brLenTotal: "0.5730" },
  { geneTranscript: "NM_172229.3", parsimonySitesP: "0.244588744588745", constantSitesP: "0.611832611832612", distinctSitesP: "0.347041847041847", brLenTotal: "0.8998" },
  { geneTranscript: "NM_003292.3", parsimonySitesP: "0.157426999576809", constantSitesP: "0.739455494427987", distinctSitesP: "0.187473550571308", brLenTotal: "0.5116" },
  { geneTranscript: "NM_152495.2", parsimonySitesP: "0.15625", constantSitesP: "0.739583333333333", distinctSitesP: "0.270833333333333", brLenTotal: "0.6059" },
  { geneTranscript: "NM_003161.4", parsimonySitesP: "0.0736507936507937", constantSitesP: "0.829206349206349", distinctSitesP: "0.14031746031746", brLenTotal: "0.2566" },
  { geneTranscript: "NM_000408.5", parsimonySitesP: "0.157267308574049", constantSitesP: "0.751948647409445", distinctSitesP: "0.199908298945438", brLenTotal: "0.5379" },
  { geneTranscript: "NM_001004694.3", parsimonySitesP: "0.595767195767196", constantSitesP: "0.288888888888889", distinctSitesP: "0.71957671957672", brLenTotal: "3.7946" },
  { geneTranscript: "NM_015425.6", parsimonySitesP: "0.218798449612403", constantSitesP: "0.648449612403101", distinctSitesP: "0.286434108527132", brLenTotal: "0.8673" },
  { geneTranscript: "NM_001128591.2", parsimonySitesP: "0.168021680216802", constantSitesP: "0.742547425474255", distinctSitesP: "0.268292682926829", brLenTotal: "0.5755" },
  { geneTranscript: "NM_017931.4", parsimonySitesP: "0.252309879175551", constantSitesP: "0.605543710021322", distinctSitesP: "0.381663113006397", brLenTotal: "1.0726" },
  { geneTranscript: "NM_031479.5", parsimonySitesP: "0.25047619047619", constantSitesP: "0.574285714285714", distinctSitesP: "0.363809523809524", brLenTotal: "0.8379" },
  { geneTranscript: "NM_000634.3", parsimonySitesP: "0.407619047619048", constantSitesP: "0.311428571428571", distinctSitesP: "0.68952380952381", brLenTotal: "2.5229" },
  { geneTranscript: "NM_001271944.2", parsimonySitesP: "0.161064425770308", constantSitesP: "0.754901960784314", distinctSitesP: "0.216386554621849", brLenTotal: "0.7070" },
  { geneTranscript: "NM_001007538.2", parsimonySitesP: "0.179661016949153", constantSitesP: "0.702824858757062", distinctSitesP: "0.274576271186441", brLenTotal: "0.8121" },
  { geneTranscript: "NM_032578.4", parsimonySitesP: "0.179040404040404", constantSitesP: "0.688888888888889", distinctSitesP: "0.24040404040404", brLenTotal: "0.6463" },
  { geneTranscript: "NM_001002294.3", parsimonySitesP: "0.288847117794486", constantSitesP: "0.557017543859649", distinctSitesP: "0.416666666666667", brLenTotal: "1.1746" },
  { geneTranscript: "NM_001080452.2", parsimonySitesP: "0.241866175567833", constantSitesP: "0.639042357274401", distinctSitesP: "0.343155310006139", brLenTotal: "0.9724" },
  { geneTranscript: "NM_020143.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.611111111111111", distinctSitesP: "0.369047619047619", brLenTotal: "0.7378" },
  { geneTranscript: "NM_032701.4", parsimonySitesP: "0.256854256854257", constantSitesP: "0.630591630591631", distinctSitesP: "0.328282828282828", brLenTotal: "1.0748" },
  { geneTranscript: "NM_000978.4", parsimonySitesP: "0.138095238095238", constantSitesP: "0.733333333333333", distinctSitesP: "0.273809523809524", brLenTotal: "0.5592" },
  { geneTranscript: "NM_014766.5", parsimonySitesP: "0.201288244766506", constantSitesP: "0.687600644122383", distinctSitesP: "0.289049919484702", brLenTotal: "0.7512" },
  { geneTranscript: "NM_005857.5", parsimonySitesP: "0.124210526315789", constantSitesP: "0.776842105263158", distinctSitesP: "0.183859649122807", brLenTotal: "0.3790" },
  { geneTranscript: "NM_000844.4", parsimonySitesP: "0.268123861566485", constantSitesP: "0.654280510018215", distinctSitesP: "0.271766848816029", brLenTotal: "1.1895" },
  { geneTranscript: "NM_006547.3", parsimonySitesP: "0.123200921128382", constantSitesP: "0.759355210132412", distinctSitesP: "0.193436960276339", brLenTotal: "0.4341" },
  { geneTranscript: "NM_021072.4", parsimonySitesP: "0.147565543071161", constantSitesP: "0.759925093632959", distinctSitesP: "0.206367041198502", brLenTotal: "0.5293" },
  { geneTranscript: "NM_005742.4", parsimonySitesP: "0.190151515151515", constantSitesP: "0.696212121212121", distinctSitesP: "0.285606060606061", brLenTotal: "0.8076" },
  { geneTranscript: "NM_014578.4", parsimonySitesP: "0.226984126984127", constantSitesP: "0.657142857142857", distinctSitesP: "0.341269841269841", brLenTotal: "0.8624" },
  { geneTranscript: "NM_004661.4", parsimonySitesP: "0.135120044667783", constantSitesP: "0.777219430485762", distinctSitesP: "0.193746510329425", brLenTotal: "0.4459" },
  { geneTranscript: "NM_000942.5", parsimonySitesP: "0.220679012345679", constantSitesP: "0.695987654320988", distinctSitesP: "0.279320987654321", brLenTotal: "0.7982" },
  { geneTranscript: "NM_002697.4", parsimonySitesP: "0.128372497824195", constantSitesP: "0.766753698868581", distinctSitesP: "0.170147954743255", brLenTotal: "0.4846" },
  { geneTranscript: "NM_004447.6", parsimonySitesP: "0.179643146796431", constantSitesP: "0.708029197080292", distinctSitesP: "0.253041362530414", brLenTotal: "0.6246" },
  { geneTranscript: "NM_004137.4", parsimonySitesP: "0.223385689354276", constantSitesP: "0.649214659685864", distinctSitesP: "0.321116928446771", brLenTotal: "0.8331" },
  { geneTranscript: "NM_006613.4", parsimonySitesP: "0.204301075268817", constantSitesP: "0.685099846390169", distinctSitesP: "0.293394777265745", brLenTotal: "0.7922" },
  { geneTranscript: "NM_001040455.2", parsimonySitesP: "0.157051282051282", constantSitesP: "0.753205128205128", distinctSitesP: "0.216346153846154", brLenTotal: "0.7040" },
  { geneTranscript: "NM_003269.5", parsimonySitesP: "0.0961038961038961", constantSitesP: "0.819047619047619", distinctSitesP: "0.176623376623377", brLenTotal: "0.4212" },
  { geneTranscript: "NM_001379180.1", parsimonySitesP: "0.218061674008811", constantSitesP: "0.654185022026432", distinctSitesP: "0.262848751835536", brLenTotal: "1.0221" },
  { geneTranscript: "NM_130466.4", parsimonySitesP: "0.173845193508115", constantSitesP: "0.72940074906367", distinctSitesP: "0.235955056179775", brLenTotal: "0.7046" },
  { geneTranscript: "NM_001802.2", parsimonySitesP: "0.195301027900147", constantSitesP: "0.680616740088106", distinctSitesP: "0.254772393538913", brLenTotal: "0.6737" },
  { geneTranscript: "NM_021138.4", parsimonySitesP: "0.214238190286094", constantSitesP: "0.655355954757152", distinctSitesP: "0.290751829673985", brLenTotal: "1.1682" },
  { geneTranscript: "NM_014685.4", parsimonySitesP: "0.18925831202046", constantSitesP: "0.670929241261722", distinctSitesP: "0.303495311167945", brLenTotal: "0.6479" },
  { geneTranscript: "NM_002232.5", parsimonySitesP: "0.183768115942029", constantSitesP: "0.667246376811594", distinctSitesP: "0.245217391304348", brLenTotal: "0.8238" },
  { geneTranscript: "NM_001004136.2", parsimonySitesP: "0.508230452674897", constantSitesP: "0.353909465020576", distinctSitesP: "0.633744855967078", brLenTotal: "3.0384" },
  { geneTranscript: "NM_145296.2", parsimonySitesP: "0.150343642611684", constantSitesP: "0.774054982817869", distinctSitesP: "0.196735395189003", brLenTotal: "0.5286" },
  { geneTranscript: "NM_001438.4", parsimonySitesP: "0.101164483260553", constantSitesP: "0.848617176128093", distinctSitesP: "0.141193595342067", brLenTotal: "0.3417" },
  { geneTranscript: "NM_001079802.2", parsimonySitesP: "0.179320318148952", constantSitesP: "0.695589298626175", distinctSitesP: "0.272595806218366", brLenTotal: "0.5601" },
  { geneTranscript: "NM_005916.5", parsimonySitesP: "0.206305053314789", constantSitesP: "0.698191933240612", distinctSitesP: "0.270746407046824", brLenTotal: "0.7800" },
  { geneTranscript: "NM_004794.3", parsimonySitesP: "0.118143459915612", constantSitesP: "0.791842475386779", distinctSitesP: "0.19549929676512", brLenTotal: "0.4051" },
  { geneTranscript: "NM_181744.4", parsimonySitesP: "0.139359698681733", constantSitesP: "0.75894538606403", distinctSitesP: "0.214689265536723", brLenTotal: "0.4567" },
  { geneTranscript: "NM_133445.3", parsimonySitesP: "0.170104633781764", constantSitesP: "0.711808669656203", distinctSitesP: "0.228101644245142", brLenTotal: "0.5663" },
  { geneTranscript: "NM_033023.5", parsimonySitesP: "0.163265306122449", constantSitesP: "0.732993197278912", distinctSitesP: "0.275510204081633", brLenTotal: "0.7313" },
  { geneTranscript: "NM_018216.4", parsimonySitesP: "0.215178956446744", constantSitesP: "0.695127210004312", distinctSitesP: "0.285467874083657", brLenTotal: "1.1580" },
  { geneTranscript: "NM_002720.3", parsimonySitesP: "0.130293159609121", constantSitesP: "0.789359391965255", distinctSitesP: "0.193268186753529", brLenTotal: "0.4979" },
  { geneTranscript: "NM_007015.3", parsimonySitesP: "0.178642714570858", constantSitesP: "0.689620758483034", distinctSitesP: "0.291417165668663", brLenTotal: "0.6165" },
  { geneTranscript: "NM_020649.3", parsimonySitesP: "0.181662382176521", constantSitesP: "0.705227077977721", distinctSitesP: "0.264781491002571", brLenTotal: "0.7718" },
  { geneTranscript: "NM_005608.3", parsimonySitesP: "0.36084142394822", constantSitesP: "0.485436893203883", distinctSitesP: "0.475728155339806", brLenTotal: "1.5299" },
  { geneTranscript: "NM_004762.6", parsimonySitesP: "0.161641541038526", constantSitesP: "0.750418760469012", distinctSitesP: "0.220268006700168", brLenTotal: "0.7156" },
  { geneTranscript: "NM_001009185.3", parsimonySitesP: "0.219298245614035", constantSitesP: "0.688827331486611", distinctSitesP: "0.287165281625115", brLenTotal: "0.7760" },
  { geneTranscript: "NM_001607.4", parsimonySitesP: "0.195754716981132", constantSitesP: "0.694182389937107", distinctSitesP: "0.264150943396226", brLenTotal: "0.6886" },
  { geneTranscript: "NM_178229.5", parsimonySitesP: "0.198242387083589", constantSitesP: "0.655630492540364", distinctSitesP: "0.246065808297568", brLenTotal: "0.7522" },
  { geneTranscript: "NM_015913.4", parsimonySitesP: "0.145348837209302", constantSitesP: "0.763565891472868", distinctSitesP: "0.271317829457364", brLenTotal: "0.4046" },
  { geneTranscript: "NM_001100818.2", parsimonySitesP: "0.142857142857143", constantSitesP: "0.781874039938556", distinctSitesP: "0.218125960061444", brLenTotal: "0.7161" },
  { geneTranscript: "NM_000677.4", parsimonySitesP: "0.257861635220126", constantSitesP: "0.59643605870021", distinctSitesP: "0.385744234800839", brLenTotal: "0.8483" },
  { geneTranscript: "NM_006000.3", parsimonySitesP: "0.28422619047619", constantSitesP: "0.575892857142857", distinctSitesP: "0.389136904761905", brLenTotal: "1.6945" },
  { geneTranscript: "NM_000265.7", parsimonySitesP: "0.252991452991453", constantSitesP: "0.635042735042735", distinctSitesP: "0.362393162393162", brLenTotal: "1.1038" },
  { geneTranscript: "NM_013263.5", parsimonySitesP: "0.197644649257552", constantSitesP: "0.61699948796723", distinctSitesP: "0.325140809011777", brLenTotal: "0.8296" },
  { geneTranscript: "NM_001382.4", parsimonySitesP: "0.121732026143791", constantSitesP: "0.781045751633987", distinctSitesP: "0.191993464052288", brLenTotal: "0.3652" },
  { geneTranscript: "NM_003757.4", parsimonySitesP: "0.142564102564103", constantSitesP: "0.751794871794872", distinctSitesP: "0.220512820512821", brLenTotal: "0.5338" },
  { geneTranscript: "NM_199456.3", parsimonySitesP: "0.274691358024691", constantSitesP: "0.555555555555556", distinctSitesP: "0.445987654320988", brLenTotal: "1.0523" },
  { geneTranscript: "NM_001031803.2", parsimonySitesP: "0.230392156862745", constantSitesP: "0.638888888888889", distinctSitesP: "0.304575163398693", brLenTotal: "1.0351" },
  { geneTranscript: "NM_001033930.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.692708333333333", distinctSitesP: "0.291666666666667", brLenTotal: "0.5842" },
  { geneTranscript: "NM_005273.4", parsimonySitesP: "0.157843137254902", constantSitesP: "0.737254901960784", distinctSitesP: "0.235294117647059", brLenTotal: "0.8081" },
  { geneTranscript: "NM_001256660.2", parsimonySitesP: "0.195121951219512", constantSitesP: "0.696230598669623", distinctSitesP: "0.252771618625277", brLenTotal: "0.9482" },
  { geneTranscript: "NM_021109.4", parsimonySitesP: "0.128787878787879", constantSitesP: "0.704545454545455", distinctSitesP: "0.310606060606061", brLenTotal: "0.4298" },
  { geneTranscript: "NM_001289808.2", parsimonySitesP: "0.121904761904762", constantSitesP: "0.788571428571429", distinctSitesP: "0.2", brLenTotal: "0.4348" },
  { geneTranscript: "NM_001040715.2", parsimonySitesP: "0.221514508138712", constantSitesP: "0.643312101910828", distinctSitesP: "0.295116772823779", brLenTotal: "0.7486" },
  { geneTranscript: "NM_002429.6", parsimonySitesP: "0.24737532808399", constantSitesP: "0.582677165354331", distinctSitesP: "0.373359580052493", brLenTotal: "0.8603" },
  { geneTranscript: "NM_001159542.3", parsimonySitesP: "0.62116991643454", constantSitesP: "0.10677808727948", distinctSitesP: "0.882079851439183", brLenTotal: "3.7340" },
  { geneTranscript: "NM_000729.6", parsimonySitesP: "0.234782608695652", constantSitesP: "0.585507246376812", distinctSitesP: "0.411594202898551", brLenTotal: "0.8453" },
  { geneTranscript: "NM_014171.6", parsimonySitesP: "0.102310231023102", constantSitesP: "0.768976897689769", distinctSitesP: "0.254125412541254", brLenTotal: "0.3654" },
  { geneTranscript: "NM_198317.3", parsimonySitesP: "0.21183800623053", constantSitesP: "0.705088265835929", distinctSitesP: "0.27466251298027", brLenTotal: "1.0782" },
  { geneTranscript: "NM_012097.4", parsimonySitesP: "0.115456238361266", constantSitesP: "0.748603351955307", distinctSitesP: "0.232774674115456", brLenTotal: "0.4385" },
  { geneTranscript: "NM_001098169.2", parsimonySitesP: "0.167381974248927", constantSitesP: "0.699570815450644", distinctSitesP: "0.274678111587983", brLenTotal: "0.6781" },
  { geneTranscript: "NM_001164595.2", parsimonySitesP: "0.287001287001287", constantSitesP: "0.551801801801802", distinctSitesP: "0.354247104247104", brLenTotal: "1.1628" },
  { geneTranscript: "NM_002529.4", parsimonySitesP: "0.200167504187605", constantSitesP: "0.670854271356784", distinctSitesP: "0.252093802345059", brLenTotal: "0.8260" },
  { geneTranscript: "NM_014774.3", parsimonySitesP: "0.190572390572391", constantSitesP: "0.643097643097643", distinctSitesP: "0.312457912457912", brLenTotal: "0.6407" },
  { geneTranscript: "NM_032211.7", parsimonySitesP: "0.214726631393298", constantSitesP: "0.649911816578483", distinctSitesP: "0.279982363315697", brLenTotal: "0.9115" },
  { geneTranscript: "NM_013327.5", parsimonySitesP: "0.191391941391941", constantSitesP: "0.687728937728938", distinctSitesP: "0.315018315018315", brLenTotal: "0.8631" },
  { geneTranscript: "NM_007264.4", parsimonySitesP: "0.287953795379538", constantSitesP: "0.562706270627063", distinctSitesP: "0.435643564356436", brLenTotal: "1.1464" },
  { geneTranscript: "NM_005659.7", parsimonySitesP: "0.161780673181325", constantSitesP: "0.751357220412595", distinctSitesP: "0.258414766558089", brLenTotal: "0.6336" },
  { geneTranscript: "NM_138353.4", parsimonySitesP: "0.209444444444444", constantSitesP: "0.693888888888889", distinctSitesP: "0.270555555555556", brLenTotal: "0.9734" },
  { geneTranscript: "NM_002664.3", parsimonySitesP: "0.168571428571429", constantSitesP: "0.726666666666667", distinctSitesP: "0.25047619047619", brLenTotal: "0.5615" },
  { geneTranscript: "NM_032772.6", parsimonySitesP: "0.156862745098039", constantSitesP: "0.763157894736842", distinctSitesP: "0.206914344685243", brLenTotal: "0.6404" },
  { geneTranscript: "NM_002018.4", parsimonySitesP: "0.193328079852903", constantSitesP: "0.707906488048332", distinctSitesP: "0.218544785920672", brLenTotal: "0.8190" },
  { geneTranscript: "NM_016940.3", parsimonySitesP: "0.205851619644723", constantSitesP: "0.635318704284222", distinctSitesP: "0.329153605015674", brLenTotal: "0.7085" },
  { geneTranscript: "NM_153234.5", parsimonySitesP: "0.204491725768322", constantSitesP: "0.700945626477541", distinctSitesP: "0.269503546099291", brLenTotal: "0.6510" },
  { geneTranscript: "NM_139283.2", parsimonySitesP: "0.112938596491228", constantSitesP: "0.81030701754386", distinctSitesP: "0.190789473684211", brLenTotal: "0.3916" },
  { geneTranscript: "NM_033328.3", parsimonySitesP: "0.206243032329989", constantSitesP: "0.682274247491639", distinctSitesP: "0.289855072463768", brLenTotal: "0.6384" },
  { geneTranscript: "NM_021615.5", parsimonySitesP: "0.341772151898734", constantSitesP: "0.539240506329114", distinctSitesP: "0.454852320675105", brLenTotal: "1.8232" },
  { geneTranscript: "NM_002878.4", parsimonySitesP: "0.23780487804878", constantSitesP: "0.602642276422764", distinctSitesP: "0.338414634146341", brLenTotal: "0.8253" },
  { geneTranscript: "NM_017721.5", parsimonySitesP: "0.245005257623554", constantSitesP: "0.618647038205398", distinctSitesP: "0.327024185068349", brLenTotal: "1.0688" },
  { geneTranscript: "NM_001387360.1", parsimonySitesP: "0.138784067085954", constantSitesP: "0.784486373165618", distinctSitesP: "0.176519916142558", brLenTotal: "0.4822" },
  { geneTranscript: "NM_004450.3", parsimonySitesP: "0.137820512820513", constantSitesP: "0.71474358974359", distinctSitesP: "0.269230769230769", brLenTotal: "0.5365" },
  { geneTranscript: "NM_004170.6", parsimonySitesP: "0.181297709923664", constantSitesP: "0.709923664122137", distinctSitesP: "0.276717557251908", brLenTotal: "0.6861" },
  { geneTranscript: "NM_001142601.2", parsimonySitesP: "0.283854166666667", constantSitesP: "0.573784722222222", distinctSitesP: "0.372395833333333", brLenTotal: "1.0711" },
  { geneTranscript: "NM_001377304.1", parsimonySitesP: "0.254545454545455", constantSitesP: "0.635353535353535", distinctSitesP: "0.365656565656566", brLenTotal: "1.2470" },
  { geneTranscript: "NM_020438.5", parsimonySitesP: "0.123249299719888", constantSitesP: "0.777310924369748", distinctSitesP: "0.200280112044818", brLenTotal: "0.4300" },
  { geneTranscript: "NM_024671.4", parsimonySitesP: "0.172222222222222", constantSitesP: "0.716049382716049", distinctSitesP: "0.229012345679012", brLenTotal: "0.6116" },
  { geneTranscript: "NM_001010985.3", parsimonySitesP: "0.268361581920904", constantSitesP: "0.581920903954802", distinctSitesP: "0.349340866290019", brLenTotal: "1.1548" },
  { geneTranscript: "NM_004441.5", parsimonySitesP: "0.153455284552846", constantSitesP: "0.747628726287263", distinctSitesP: "0.200542005420054", brLenTotal: "0.6924" },
  { geneTranscript: "NM_003634.4", parsimonySitesP: "0.164319248826291", constantSitesP: "0.748826291079812", distinctSitesP: "0.233568075117371", brLenTotal: "0.5667" },
  { geneTranscript: "NM_001164522.3", parsimonySitesP: "0.224455611390285", constantSitesP: "0.678391959798995", distinctSitesP: "0.341708542713568", brLenTotal: "0.8691" },
  { geneTranscript: "NM_001372179.1", parsimonySitesP: "0.251480883144857", constantSitesP: "0.635971997845988", distinctSitesP: "0.335487345180398", brLenTotal: "1.0431" },
  { geneTranscript: "NM_002163.4", parsimonySitesP: "0.221439749608764", constantSitesP: "0.657276995305164", distinctSitesP: "0.320813771517997", brLenTotal: "1.0769" },
  { geneTranscript: "NM_003780.5", parsimonySitesP: "0.172043010752688", constantSitesP: "0.734767025089606", distinctSitesP: "0.238351254480287", brLenTotal: "0.6265" },
  { geneTranscript: "NM_033278.4", parsimonySitesP: "0.149193548387097", constantSitesP: "0.770161290322581", distinctSitesP: "0.185483870967742", brLenTotal: "0.6257" },
  { geneTranscript: "NM_144973.4", parsimonySitesP: "0.167189952904239", constantSitesP: "0.737833594976452", distinctSitesP: "0.191261119832548", brLenTotal: "0.5641" },
  { geneTranscript: "NM_130446.4", parsimonySitesP: "0.18142780461621", constantSitesP: "0.721417069243156", distinctSitesP: "0.230810520665593", brLenTotal: "0.7922" },
  { geneTranscript: "NM_006558.3", parsimonySitesP: "0.141618497109827", constantSitesP: "0.734104046242775", distinctSitesP: "0.244701348747592", brLenTotal: "0.5427" },
  { geneTranscript: "NM_020812.4", parsimonySitesP: "0.217228464419476", constantSitesP: "0.673343103729034", distinctSitesP: "0.25663572708028", brLenTotal: "1.0654" },
  { geneTranscript: "NM_181581.3", parsimonySitesP: "0.129337539432177", constantSitesP: "0.752891692954784", distinctSitesP: "0.227129337539432", brLenTotal: "0.4326" },
  { geneTranscript: "NM_001386135.1", parsimonySitesP: "0.194942903752039", constantSitesP: "0.693855356171833", distinctSitesP: "0.258020663404024", brLenTotal: "0.7570" },
  { geneTranscript: "NM_001365902.3", parsimonySitesP: "0.0909694555112882", constantSitesP: "0.860557768924303", distinctSitesP: "0.124169986719788", brLenTotal: "0.2849" },
  { geneTranscript: "NM_000098.3", parsimonySitesP: "0.221884498480243", constantSitesP: "0.639311043566363", distinctSitesP: "0.298885511651469", brLenTotal: "0.7517" },
  { geneTranscript: "NM_017846.5", parsimonySitesP: "0.125435540069686", constantSitesP: "0.744483159117305", distinctSitesP: "0.246225319396051", brLenTotal: "0.5329" },
  { geneTranscript: "NM_014927.5", parsimonySitesP: "0.0831721470019342", constantSitesP: "0.842037395228885", distinctSitesP: "0.120567375886525", brLenTotal: "0.2614" },
  { geneTranscript: "NM_145055.5", parsimonySitesP: "0.110561056105611", constantSitesP: "0.79950495049505", distinctSitesP: "0.182343234323432", brLenTotal: "0.3725" },
  { geneTranscript: "NM_001144757.3", parsimonySitesP: "0.19811320754717", constantSitesP: "0.70440251572327", distinctSitesP: "0.29874213836478", brLenTotal: "0.6631" },
  { geneTranscript: "NM_003827.4", parsimonySitesP: "0.151412429378531", constantSitesP: "0.770621468926554", distinctSitesP: "0.210169491525424", brLenTotal: "0.8295" },
  { geneTranscript: "NM_001394010.1", parsimonySitesP: "0.212339743589744", constantSitesP: "0.689102564102564", distinctSitesP: "0.291666666666667", brLenTotal: "1.1201" },
  { geneTranscript: "NM_018446.4", parsimonySitesP: "0.161725067385445", constantSitesP: "0.705300988319856", distinctSitesP: "0.234501347708895", brLenTotal: "0.5182" },
  { geneTranscript: "NM_018896.5", parsimonySitesP: "0.171224232225494", constantSitesP: "0.724021876314682", distinctSitesP: "0.194222409199271", brLenTotal: "0.7670" },
  { geneTranscript: "NM_013392.4", parsimonySitesP: "0.104672897196262", constantSitesP: "0.814953271028037", distinctSitesP: "0.154517133956386", brLenTotal: "0.3298" },
  { geneTranscript: "NM_012324.6", parsimonySitesP: "0.219255663430421", constantSitesP: "0.673948220064725", distinctSitesP: "0.278721682847896", brLenTotal: "1.0000" },
  { geneTranscript: "NM_003260.5", parsimonySitesP: "0.246747420367878", constantSitesP: "0.652759084791386", distinctSitesP: "0.332436069986541", brLenTotal: "1.3208" },
  { geneTranscript: "NM_175858.3", parsimonySitesP: "0.267893660531697", constantSitesP: "0.580777096114519", distinctSitesP: "0.437627811860941", brLenTotal: "0.9020" },
  { geneTranscript: "NM_017754.4", parsimonySitesP: "0.200925925925926", constantSitesP: "0.655787037037037", distinctSitesP: "0.242824074074074", brLenTotal: "0.6436" },
  { geneTranscript: "NM_024079.5", parsimonySitesP: "0.162230671736375", constantSitesP: "0.723067173637516", distinctSitesP: "0.250950570342205", brLenTotal: "0.5273" },
  { geneTranscript: "NM_014009.4", parsimonySitesP: "0.206496519721578", constantSitesP: "0.669760247486466", distinctSitesP: "0.260634184068059", brLenTotal: "0.7669" },
  { geneTranscript: "NM_012340.5", parsimonySitesP: "0.208469055374593", constantSitesP: "0.694173000361925", distinctSitesP: "0.257690915671372", brLenTotal: "0.8395" },
  { geneTranscript: "NM_001100878.2", parsimonySitesP: "0.329624478442281", constantSitesP: "0.511358368103848", distinctSitesP: "0.42512749188688", brLenTotal: "1.4744" },
  { geneTranscript: "NM_001395895.1", parsimonySitesP: "0.16615594143684", constantSitesP: "0.704119850187266", distinctSitesP: "0.224378617637045", brLenTotal: "0.5356" },
  { geneTranscript: "NM_003773.5", parsimonySitesP: "0.224806201550388", constantSitesP: "0.661733615221987", distinctSitesP: "0.269203664552502", brLenTotal: "0.7873" },
  { geneTranscript: "NM_054105.2", parsimonySitesP: "0.528846153846154", constantSitesP: "0.39957264957265", distinctSitesP: "0.594017094017094", brLenTotal: "2.5561" },
  { geneTranscript: "NM_002768.5", parsimonySitesP: "0.180272108843537", constantSitesP: "0.715986394557823", distinctSitesP: "0.273809523809524", brLenTotal: "0.9786" },
  { geneTranscript: "NM_001003892.3", parsimonySitesP: "0.245454545454545", constantSitesP: "0.631818181818182", distinctSitesP: "0.363636363636364", brLenTotal: "0.9907" },
  { geneTranscript: "NM_018144.4", parsimonySitesP: "0.159663865546218", constantSitesP: "0.758403361344538", distinctSitesP: "0.2296918767507", brLenTotal: "0.6877" },
  { geneTranscript: "NM_178170.3", parsimonySitesP: "0.198940269749518", constantSitesP: "0.695568400770713", distinctSitesP: "0.254335260115607", brLenTotal: "0.7739" },
  { geneTranscript: "NM_001007553.3", parsimonySitesP: "0.093984962406015", constantSitesP: "0.812447786131997", distinctSitesP: "0.147451963241437", brLenTotal: "0.3060" },
  { geneTranscript: "NM_012158.4", parsimonySitesP: "0.129283489096573", constantSitesP: "0.782710280373832", distinctSitesP: "0.189252336448598", brLenTotal: "0.3873" },
  { geneTranscript: "NM_000532.5", parsimonySitesP: "0.170068027210884", constantSitesP: "0.711193568336425", distinctSitesP: "0.249226963512678", brLenTotal: "0.6478" },
  { geneTranscript: "NM_005725.6", parsimonySitesP: "0.14630467571644", constantSitesP: "0.751131221719457", distinctSitesP: "0.244343891402715", brLenTotal: "0.5292" },
  { geneTranscript: "NM_145064.3", parsimonySitesP: "0.147435897435897", constantSitesP: "0.75", distinctSitesP: "0.231684981684982", brLenTotal: "0.4560" },
  { geneTranscript: "NM_001110303.4", parsimonySitesP: "0.211889132020423", constantSitesP: "0.676148796498906", distinctSitesP: "0.282275711159737", brLenTotal: "1.0047" },
  { geneTranscript: "NM_147164.3", parsimonySitesP: "0.139784946236559", constantSitesP: "0.751792114695341", distinctSitesP: "0.21505376344086", brLenTotal: "0.5901" },
  { geneTranscript: "NM_016257.4", parsimonySitesP: "0.207678883071553", constantSitesP: "0.668411867364747", distinctSitesP: "0.275741710296684", brLenTotal: "0.7506" },
  { geneTranscript: "NM_006570.5", parsimonySitesP: "0.226837060702875", constantSitesP: "0.619808306709265", distinctSitesP: "0.288604898828541", brLenTotal: "0.7377" },
  { geneTranscript: "NM_001368882.1", parsimonySitesP: "0.169413919413919", constantSitesP: "0.709249084249084", distinctSitesP: "0.281135531135531", brLenTotal: "0.6718" },
  { geneTranscript: "NM_014600.3", parsimonySitesP: "0.193769470404984", constantSitesP: "0.686604361370717", distinctSitesP: "0.263551401869159", brLenTotal: "0.9671" },
  { geneTranscript: "NM_021934.5", parsimonySitesP: "0.148318042813456", constantSitesP: "0.762996941896024", distinctSitesP: "0.2217125382263", brLenTotal: "0.7338" },
  { geneTranscript: "NM_022047.4", parsimonySitesP: "0.150554675118859", constantSitesP: "0.727945060750132", distinctSitesP: "0.218172213417855", brLenTotal: "0.5462" },
  { geneTranscript: "NM_001110792.2", parsimonySitesP: "0.110441767068273", constantSitesP: "0.798527443105756", distinctSitesP: "0.170013386880857", brLenTotal: "0.4672" },
  { geneTranscript: "NM_058222.3", parsimonySitesP: "0.170212765957447", constantSitesP: "0.746707193515704", distinctSitesP: "0.246200607902736", brLenTotal: "0.6310" },
  { geneTranscript: "NM_024575.5", parsimonySitesP: "0.244565217391304", constantSitesP: "0.675724637681159", distinctSitesP: "0.315217391304348", brLenTotal: "1.0395" },
  { geneTranscript: "NM_020850.3", parsimonySitesP: "0.133333333333333", constantSitesP: "0.782795698924731", distinctSitesP: "0.185483870967742", brLenTotal: "0.4643" },
  { geneTranscript: "NM_194277.3", parsimonySitesP: "0.187208216619981", constantSitesP: "0.657796451914099", distinctSitesP: "0.275443510737628", brLenTotal: "0.5724" },
  { geneTranscript: "NM_173519.3", parsimonySitesP: "0.146892655367232", constantSitesP: "0.753295668549906", distinctSitesP: "0.207156308851224", brLenTotal: "0.4791" },
  { geneTranscript: "NM_024031.5", parsimonySitesP: "0.228490028490028", constantSitesP: "0.614245014245014", distinctSitesP: "0.326495726495726", brLenTotal: "0.7622" },
  { geneTranscript: "NM_001005166.5", parsimonySitesP: "0.531090723751274", constantSitesP: "0.337410805300714", distinctSitesP: "0.654434250764526", brLenTotal: "2.7387" },
  { geneTranscript: "NM_152309.3", parsimonySitesP: "0.224016563146998", constantSitesP: "0.654244306418219", distinctSitesP: "0.296066252587992", brLenTotal: "0.9243" },
  { geneTranscript: "NM_022830.3", parsimonySitesP: "0.236079328756674", constantSitesP: "0.612509534706331", distinctSitesP: "0.300152555301297", brLenTotal: "0.7861" },
  { geneTranscript: "NM_002225.5", parsimonySitesP: "0.210401891252955", constantSitesP: "0.680851063829787", distinctSitesP: "0.291568163908589", brLenTotal: "0.7184" },
  { geneTranscript: "NM_001004067.4", parsimonySitesP: "0.196399345335516", constantSitesP: "0.689307146753955", distinctSitesP: "0.253955264593562", brLenTotal: "0.8155" },
  { geneTranscript: "NM_001178015.2", parsimonySitesP: "0.123732856290996", constantSitesP: "0.794871794871795", distinctSitesP: "0.16159809183065", brLenTotal: "0.3793" },
  { geneTranscript: "NM_001321075.3", parsimonySitesP: "0.0879373848987109", constantSitesP: "0.849447513812155", distinctSitesP: "0.117863720073665", brLenTotal: "0.3234" },
  { geneTranscript: "NM_173469.4", parsimonySitesP: "0.129777777777778", constantSitesP: "0.712888888888889", distinctSitesP: "0.268444444444444", brLenTotal: "0.5581" },
  { geneTranscript: "NM_057175.5", parsimonySitesP: "0.102386451116243", constantSitesP: "0.81139337952271", distinctSitesP: "0.137413394919169", brLenTotal: "0.3392" },
  { geneTranscript: "NM_000665.5", parsimonySitesP: "0.190553745928339", constantSitesP: "0.690010857763301", distinctSitesP: "0.242671009771987", brLenTotal: "0.7717" },
  { geneTranscript: "NM_005530.3", parsimonySitesP: "0.152094717668488", constantSitesP: "0.750455373406193", distinctSitesP: "0.238615664845173", brLenTotal: "0.6282" },
  { geneTranscript: "NM_004822.3", parsimonySitesP: "0.133002207505519", constantSitesP: "0.788631346578366", distinctSitesP: "0.183774834437086", brLenTotal: "0.5318" },
  { geneTranscript: "NM_001714.4", parsimonySitesP: "0.153846153846154", constantSitesP: "0.75965811965812", distinctSitesP: "0.189059829059829", brLenTotal: "0.5799" },
  { geneTranscript: "NM_033550.4", parsimonySitesP: "0.239789196310935", constantSitesP: "0.61133069828722", distinctSitesP: "0.370223978919631", brLenTotal: "0.8145" },
  { geneTranscript: "NM_000943.5", parsimonySitesP: "0.216981132075472", constantSitesP: "0.644654088050314", distinctSitesP: "0.333333333333333", brLenTotal: "0.9622" },
  { geneTranscript: "NM_006304.2", parsimonySitesP: "0.123809523809524", constantSitesP: "0.804761904761905", distinctSitesP: "0.238095238095238", brLenTotal: "0.3230" },
  { geneTranscript: "NM_033025.6", parsimonySitesP: "0.259410430839002", constantSitesP: "0.609977324263039", distinctSitesP: "0.312471655328798", brLenTotal: "1.0697" },
  { geneTranscript: "NM_017546.5", parsimonySitesP: "0.164052287581699", constantSitesP: "0.747058823529412", distinctSitesP: "0.232679738562092", brLenTotal: "0.5560" },
  { geneTranscript: "NM_018951.4", parsimonySitesP: "0.172357723577236", constantSitesP: "0.71219512195122", distinctSitesP: "0.272357723577236", brLenTotal: "0.6239" },
  { geneTranscript: "NM_005785.4", parsimonySitesP: "0.109358569926393", constantSitesP: "0.80651945320715", distinctSitesP: "0.167192429022082", brLenTotal: "0.3900" },
  { geneTranscript: "NM_152641.4", parsimonySitesP: "0.098455949137148", constantSitesP: "0.816348773841962", distinctSitesP: "0.129155313351499", brLenTotal: "0.2989" },
  { geneTranscript: "NM_021244.5", parsimonySitesP: "0.164166666666667", constantSitesP: "0.748333333333333", distinctSitesP: "0.2225", brLenTotal: "0.5925" },
  { geneTranscript: "NM_018952.5", parsimonySitesP: "0.138392857142857", constantSitesP: "0.772321428571429", distinctSitesP: "0.212797619047619", brLenTotal: "0.4511" },
  { geneTranscript: "NM_021939.4", parsimonySitesP: "0.200458190148912", constantSitesP: "0.675830469644903", distinctSitesP: "0.265177548682703", brLenTotal: "0.7868" },
  { geneTranscript: "NM_002054.5", parsimonySitesP: "0.157407407407407", constantSitesP: "0.755555555555556", distinctSitesP: "0.240740740740741", brLenTotal: "0.5212" },
  { geneTranscript: "NM_138578.3", parsimonySitesP: "0.103004291845494", constantSitesP: "0.821173104434907", distinctSitesP: "0.161659513590844", brLenTotal: "0.3371" },
  { geneTranscript: "NM_002754.5", parsimonySitesP: "0.185388127853881", constantSitesP: "0.70958904109589", distinctSitesP: "0.269406392694064", brLenTotal: "0.7485" },
  { geneTranscript: "NM_018019.3", parsimonySitesP: "0.19634703196347", constantSitesP: "0.668949771689498", distinctSitesP: "0.324200913242009", brLenTotal: "0.7334" },
  { geneTranscript: "NM_000999.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.780952380952381", distinctSitesP: "0.29047619047619", brLenTotal: "0.6178" },
  { geneTranscript: "NM_018149.7", parsimonySitesP: "0.145980491086445", constantSitesP: "0.754793138244198", distinctSitesP: "0.191052808610831", brLenTotal: "0.4409" },
  { geneTranscript: "NM_001004356.3", parsimonySitesP: "0.257936507936508", constantSitesP: "0.630291005291005", distinctSitesP: "0.351190476190476", brLenTotal: "1.2990" },
  { geneTranscript: "NM_015231.3", parsimonySitesP: "0.165715644317641", constantSitesP: "0.717784117926771", distinctSitesP: "0.216357584403233", brLenTotal: "0.5277" },
  { geneTranscript: "NM_033396.3", parsimonySitesP: "0.318681318681319", constantSitesP: "0.498939656834394", distinctSitesP: "0.401195295932138", brLenTotal: "1.2227" },
  { geneTranscript: "NM_181845.2", parsimonySitesP: "0.442317133038783", constantSitesP: "0.417771232204222", distinctSitesP: "0.566519391261659", brLenTotal: "2.6388" },
  { geneTranscript: "NM_019044.5", parsimonySitesP: "0.154252509244585", constantSitesP: "0.728473322768093", distinctSitesP: "0.254094030639197", brLenTotal: "0.5309" },
  { geneTranscript: "NM_006232.5", parsimonySitesP: "0.128888888888889", constantSitesP: "0.793333333333333", distinctSitesP: "0.213333333333333", brLenTotal: "0.3766" },
  { geneTranscript: "NM_052905.4", parsimonySitesP: "0.144688644688645", constantSitesP: "0.754273504273504", distinctSitesP: "0.205738705738706", brLenTotal: "0.5314" },
  { geneTranscript: "NM_001271696.3", parsimonySitesP: "0.138741134751773", constantSitesP: "0.742021276595745", distinctSitesP: "0.212322695035461", brLenTotal: "0.4531" },
  { geneTranscript: "NM_174878.3", parsimonySitesP: "0.21551724137931", constantSitesP: "0.65948275862069", distinctSitesP: "0.330459770114943", brLenTotal: "0.7251" },
  { geneTranscript: "NM_005249.5", parsimonySitesP: "0.0647580095432856", constantSitesP: "0.859577368779823", distinctSitesP: "0.158827539195637", brLenTotal: "0.2360" },
  { geneTranscript: "NM_003124.5", parsimonySitesP: "0.284802043422733", constantSitesP: "0.535121328224777", distinctSitesP: "0.416347381864623", brLenTotal: "0.9818" },
  { geneTranscript: "NM_002729.5", parsimonySitesP: "0.145679012345679", constantSitesP: "0.7", distinctSitesP: "0.260493827160494", brLenTotal: "0.5483" },
  { geneTranscript: "NM_148960.3", parsimonySitesP: "0.165178571428571", constantSitesP: "0.761904761904762", distinctSitesP: "0.229166666666667", brLenTotal: "0.7621" },
  { geneTranscript: "NM_001349723.3", parsimonySitesP: "0.106349206349206", constantSitesP: "0.823015873015873", distinctSitesP: "0.156349206349206", brLenTotal: "0.3559" },
  { geneTranscript: "NM_181724.3", parsimonySitesP: "0.353356890459364", constantSitesP: "0.48527679623086", distinctSitesP: "0.540636042402827", brLenTotal: "1.5380" },
  { geneTranscript: "NM_001110.4", parsimonySitesP: "0.10873440285205", constantSitesP: "0.81729055258467", distinctSitesP: "0.151960784313725", brLenTotal: "0.3258" },
  { geneTranscript: "NM_005032.7", parsimonySitesP: "0.137566137566138", constantSitesP: "0.764021164021164", distinctSitesP: "0.187830687830688", brLenTotal: "0.4983" },
  { geneTranscript: "NM_001261.4", parsimonySitesP: "0.155017921146953", constantSitesP: "0.699820788530466", distinctSitesP: "0.254480286738351", brLenTotal: "0.7117" },
  { geneTranscript: "NM_006727.5", parsimonySitesP: "0.218697123519459", constantSitesP: "0.667935702199662", distinctSitesP: "0.274111675126904", brLenTotal: "0.9867" },
  { geneTranscript: "NM_001018024.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.642156862745098", distinctSitesP: "0.372549019607843", brLenTotal: "0.5888" },
  { geneTranscript: "NM_001376924.1", parsimonySitesP: "0.237199582027168", constantSitesP: "0.634273772204807", distinctSitesP: "0.368861024033438", brLenTotal: "0.7531" },
  { geneTranscript: "NM_015914.7", parsimonySitesP: "0.208420320111343", constantSitesP: "0.652400835073069", distinctSitesP: "0.30758524704245", brLenTotal: "0.7852" },
  { geneTranscript: "NM_003353.4", parsimonySitesP: "0.384408602150538", constantSitesP: "0.456989247311828", distinctSitesP: "0.540322580645161", brLenTotal: "1.4851" },
  { geneTranscript: "NM_020179.3", parsimonySitesP: "0.112994350282486", constantSitesP: "0.774011299435028", distinctSitesP: "0.231638418079096", brLenTotal: "0.5125" },
  { geneTranscript: "NM_181872.6", parsimonySitesP: "0.200831847890671", constantSitesP: "0.661319073083779", distinctSitesP: "0.295900178253119", brLenTotal: "0.7125" },
  { geneTranscript: "NM_003041.4", parsimonySitesP: "0.253968253968254", constantSitesP: "0.64484126984127", distinctSitesP: "0.313492063492063", brLenTotal: "1.0955" },
  { geneTranscript: "NM_144641.4", parsimonySitesP: "0.195352214960058", constantSitesP: "0.662309368191721", distinctSitesP: "0.28322440087146", brLenTotal: "0.7176" },
  { geneTranscript: "NM_001297595.2", parsimonySitesP: "0.194690265486726", constantSitesP: "0.699410029498525", distinctSitesP: "0.242182890855457", brLenTotal: "1.0702" },
  { geneTranscript: "NM_004053.4", parsimonySitesP: "0.202135774218154", constantSitesP: "0.659801678108314", distinctSitesP: "0.277650648360031", brLenTotal: "0.7156" },
  { geneTranscript: "NM_181789.4", parsimonySitesP: "0.224440411373261", constantSitesP: "0.639443436176649", distinctSitesP: "0.350877192982456", brLenTotal: "0.7921" },
  { geneTranscript: "NM_178834.5", parsimonySitesP: "0.241532976827094", constantSitesP: "0.612299465240642", distinctSitesP: "0.380570409982175", brLenTotal: "0.8407" },
  { geneTranscript: "NM_205861.3", parsimonySitesP: "0.141141141141141", constantSitesP: "0.754754754754755", distinctSitesP: "0.211211211211211", brLenTotal: "0.4656" },
  { geneTranscript: "NM_152221.3", parsimonySitesP: "0.145833333333333", constantSitesP: "0.767628205128205", distinctSitesP: "0.210737179487179", brLenTotal: "0.6748" },
  { geneTranscript: "NM_014862.4", parsimonySitesP: "0.195258019525802", constantSitesP: "0.721524872152487", distinctSitesP: "0.269177126917713", brLenTotal: "0.9946" },
  { geneTranscript: "NM_013306.5", parsimonySitesP: "0.235867446393762", constantSitesP: "0.621832358674464", distinctSitesP: "0.330409356725146", brLenTotal: "0.9921" },
  { geneTranscript: "NM_001358351.3", parsimonySitesP: "0.149425287356322", constantSitesP: "0.758931345138242", distinctSitesP: "0.195402298850575", brLenTotal: "0.5330" },
  { geneTranscript: "NM_001099652.2", parsimonySitesP: "0.140637140637141", constantSitesP: "0.755244755244755", distinctSitesP: "0.231546231546232", brLenTotal: "0.4366" },
  { geneTranscript: "NM_006238.5", parsimonySitesP: "0.17384731670446", constantSitesP: "0.718820861678005", distinctSitesP: "0.233560090702948", brLenTotal: "0.6383" },
  { geneTranscript: "NM_015926.6", parsimonySitesP: "0.209797657082002", constantSitesP: "0.608093716719915", distinctSitesP: "0.351437699680511", brLenTotal: "0.8166" },
  { geneTranscript: "NM_006698.4", parsimonySitesP: "0.0804597701149425", constantSitesP: "0.873563218390805", distinctSitesP: "0.134099616858238", brLenTotal: "0.2309" },
  { geneTranscript: "NM_005471.5", parsimonySitesP: "0.163783160322953", constantSitesP: "0.693194925028835", distinctSitesP: "0.25836216839677", brLenTotal: "0.7438" },
  { geneTranscript: "NM_182759.3", parsimonySitesP: "0.210526315789474", constantSitesP: "0.649122807017544", distinctSitesP: "0.363408521303258", brLenTotal: "0.9364" },
  { geneTranscript: "NM_001029858.4", parsimonySitesP: "0.142156862745098", constantSitesP: "0.763071895424837", distinctSitesP: "0.207516339869281", brLenTotal: "0.5409" },
  { geneTranscript: "NM_000380.4", parsimonySitesP: "0.196581196581197", constantSitesP: "0.678876678876679", distinctSitesP: "0.302808302808303", brLenTotal: "0.6080" },
  { geneTranscript: "NM_001042450.4", parsimonySitesP: "0.248322147651007", constantSitesP: "0.621364653243848", distinctSitesP: "0.354026845637584", brLenTotal: "1.1855" },
  { geneTranscript: "NM_001317162.2", parsimonySitesP: "0.307415406767459", constantSitesP: "0.598272138228942", distinctSitesP: "0.365010799136069", brLenTotal: "0.9002" },
  { geneTranscript: "NM_001005493.2", parsimonySitesP: "0.583864118895966", constantSitesP: "0.312101910828025", distinctSitesP: "0.686836518046709", brLenTotal: "3.4593" },
  { geneTranscript: "NM_006572.6", parsimonySitesP: "0.112290008841733", constantSitesP: "0.7763041556145", distinctSitesP: "0.174182139699381", brLenTotal: "0.4067" },
  { geneTranscript: "NM_001048241.3", parsimonySitesP: "0.127853881278539", constantSitesP: "0.821917808219178", distinctSitesP: "0.210045662100457", brLenTotal: "0.3235" },
  { geneTranscript: "NM_175940.3", parsimonySitesP: "0.216634429400387", constantSitesP: "0.653986675263271", distinctSitesP: "0.263271007951859", brLenTotal: "0.8945" },
  { geneTranscript: "NM_005729.4", parsimonySitesP: "0.22061191626409", constantSitesP: "0.658615136876006", distinctSitesP: "0.315619967793881", brLenTotal: "0.8481" },
  { geneTranscript: "NM_194286.4", parsimonySitesP: "0.189852700490998", constantSitesP: "0.673758865248227", distinctSitesP: "0.29405346426623", brLenTotal: "0.6958" },
  { geneTranscript: "NM_002639.5", parsimonySitesP: "0.200888888888889", constantSitesP: "0.688", distinctSitesP: "0.278222222222222", brLenTotal: "0.6872" },
  { geneTranscript: "NM_001370300.1", parsimonySitesP: "0.173160173160173", constantSitesP: "0.722943722943723", distinctSitesP: "0.281385281385281", brLenTotal: "0.4816" },
  { geneTranscript: "NM_006284.4", parsimonySitesP: "0.142201834862385", constantSitesP: "0.749235474006116", distinctSitesP: "0.243119266055046", brLenTotal: "0.4727" },
  { geneTranscript: "NM_016647.3", parsimonySitesP: "0.256410256410256", constantSitesP: "0.584935897435897", distinctSitesP: "0.373397435897436", brLenTotal: "0.9155" },
  { geneTranscript: "NM_006090.5", parsimonySitesP: "0.116185897435897", constantSitesP: "0.818910256410256", distinctSitesP: "0.157852564102564", brLenTotal: "0.3731" },
  { geneTranscript: "NM_030948.6", parsimonySitesP: "0.141379310344828", constantSitesP: "0.788505747126437", distinctSitesP: "0.190804597701149", brLenTotal: "0.4861" },
  { geneTranscript: "NM_003847.3", parsimonySitesP: "0.199730094466937", constantSitesP: "0.587044534412955", distinctSitesP: "0.350877192982456", brLenTotal: "0.7648" },
  { geneTranscript: "NM_007263.4", parsimonySitesP: "0.217532467532468", constantSitesP: "0.688311688311688", distinctSitesP: "0.306277056277056", brLenTotal: "1.1126" },
  { geneTranscript: "NM_001039840.4", parsimonySitesP: "0.114583333333333", constantSitesP: "0.813988095238095", distinctSitesP: "0.208333333333333", brLenTotal: "0.3973" },
  { geneTranscript: "NM_130463.4", parsimonySitesP: "0.0903954802259887", constantSitesP: "0.788135593220339", distinctSitesP: "0.194915254237288", brLenTotal: "0.3637" },
  { geneTranscript: "NM_007173.6", parsimonySitesP: "0.206266318537859", constantSitesP: "0.694516971279373", distinctSitesP: "0.275892080069626", brLenTotal: "0.7399" },
  { geneTranscript: "NM_012197.4", parsimonySitesP: "0.112566261303399", constantSitesP: "0.80355472404116", distinctSitesP: "0.155597131275335", brLenTotal: "0.3522" },
  { geneTranscript: "NM_001301202.2", parsimonySitesP: "0.234782608695652", constantSitesP: "0.631055900621118", distinctSitesP: "0.314699792960663", brLenTotal: "0.9870" },
  { geneTranscript: "NM_005318.4", parsimonySitesP: "0.146048109965636", constantSitesP: "0.730240549828179", distinctSitesP: "0.247422680412371", brLenTotal: "0.4693" },
  { geneTranscript: "NM_001032288.3", parsimonySitesP: "0.0634920634920635", constantSitesP: "0.750566893424036", distinctSitesP: "0.224489795918367", brLenTotal: "0.4084" },
  { geneTranscript: "NM_001829.4", parsimonySitesP: "0.207823960880196", constantSitesP: "0.668296658516707", distinctSitesP: "0.28158109209454", brLenTotal: "1.0878" },
  { geneTranscript: "NM_001363705.2", parsimonySitesP: "0.143589743589744", constantSitesP: "0.756695156695157", distinctSitesP: "0.190693257359924", brLenTotal: "0.4607" },
  { geneTranscript: "NM_173483.4", parsimonySitesP: "0.292529817953547", constantSitesP: "0.608286252354049", distinctSitesP: "0.377903327055869", brLenTotal: "1.1598" },
  { geneTranscript: "NM_003493.3", parsimonySitesP: "0.357843137254902", constantSitesP: "0.517156862745098", distinctSitesP: "0.475490196078431", brLenTotal: "2.0364" },
  { geneTranscript: "NM_016422.4", parsimonySitesP: "0.121739130434783", constantSitesP: "0.795652173913043", distinctSitesP: "0.204347826086957", brLenTotal: "0.4002" },
  { geneTranscript: "NM_004566.4", parsimonySitesP: "0.201282051282051", constantSitesP: "0.7", distinctSitesP: "0.284615384615385", brLenTotal: "1.1041" },
  { geneTranscript: "NM_006756.4", parsimonySitesP: "0.109634551495017", constantSitesP: "0.777408637873754", distinctSitesP: "0.223698781838317", brLenTotal: "0.4109" },
  { geneTranscript: "NM_001272013.2", parsimonySitesP: "0.24375380865326", constantSitesP: "0.622181596587447", distinctSitesP: "0.314442413162706", brLenTotal: "0.9998" },
  { geneTranscript: "NM_006001.3", parsimonySitesP: "0.317037037037037", constantSitesP: "0.544444444444444", distinctSitesP: "0.440740740740741", brLenTotal: "2.3615" },
  { geneTranscript: "NM_001042635.2", parsimonySitesP: "0.176719576719577", constantSitesP: "0.684656084656085", distinctSitesP: "0.322751322751323", brLenTotal: "0.5952" },
  { geneTranscript: "NM_001083614.2", parsimonySitesP: "0.263862332695985", constantSitesP: "0.592734225621415", distinctSitesP: "0.37157425111536", brLenTotal: "0.9458" },
  { geneTranscript: "NM_005050.4", parsimonySitesP: "0.183718371837184", constantSitesP: "0.676017601760176", distinctSitesP: "0.287678767876788", brLenTotal: "0.6730" },
  { geneTranscript: "NM_014912.5", parsimonySitesP: "0.134670487106017", constantSitesP: "0.786055396370583", distinctSitesP: "0.178605539637058", brLenTotal: "0.4506" },
  { geneTranscript: "NM_001098722.2", parsimonySitesP: "0.168888888888889", constantSitesP: "0.693333333333333", distinctSitesP: "0.275555555555556", brLenTotal: "1.1341" },
  { geneTranscript: "NM_022460.4", parsimonySitesP: "0.26530612244898", constantSitesP: "0.545918367346939", distinctSitesP: "0.41921768707483", brLenTotal: "1.0701" },
  { geneTranscript: "NM_001393997.1", parsimonySitesP: "0.197544239797761", constantSitesP: "0.686529433008306", distinctSitesP: "0.260743950884796", brLenTotal: "0.8294" },
  { geneTranscript: "NM_181486.4", parsimonySitesP: "0.167310167310167", constantSitesP: "0.727155727155727", distinctSitesP: "0.24002574002574", brLenTotal: "0.7932" },
  { geneTranscript: "NM_001301267.2", parsimonySitesP: "0.220430107526882", constantSitesP: "0.639784946236559", distinctSitesP: "0.408602150537634", brLenTotal: "1.4114" },
  { geneTranscript: "NM_006311.4", parsimonySitesP: "0.15", constantSitesP: "0.741530054644809", distinctSitesP: "0.184972677595628", brLenTotal: "0.5065" },
  { geneTranscript: "NM_005142.3", parsimonySitesP: "0.279776179056755", constantSitesP: "0.520383693045564", distinctSitesP: "0.456434852118305", brLenTotal: "1.1074" },
  { geneTranscript: "NM_001242409.2", parsimonySitesP: "0.167047184170472", constantSitesP: "0.730974124809741", distinctSitesP: "0.210426179604262", brLenTotal: "0.6221" },
  { geneTranscript: "NM_001199824.2", parsimonySitesP: "0.250696378830084", constantSitesP: "0.605385329619313", distinctSitesP: "0.365831012070566", brLenTotal: "1.1493" },
  { geneTranscript: "NM_016931.5", parsimonySitesP: "0.164359861591696", constantSitesP: "0.722606689734717", distinctSitesP: "0.27277970011534", brLenTotal: "0.5342" },
  { geneTranscript: "NM_080430.4", parsimonySitesP: "0.23448275862069", constantSitesP: "0.627586206896552", distinctSitesP: "0.358620689655172", brLenTotal: "0.7858" },
  { geneTranscript: "NM_001362.3", parsimonySitesP: "0.180921052631579", constantSitesP: "0.666666666666667", distinctSitesP: "0.25219298245614", brLenTotal: "0.8771" },
  { geneTranscript: "NM_015042.2", parsimonySitesP: "0.145287030474841", constantSitesP: "0.752421450507914", distinctSitesP: "0.176706827309237", brLenTotal: "0.4981" },
  { geneTranscript: "NM_001080483.3", parsimonySitesP: "0.22473604826546", constantSitesP: "0.662141779788839", distinctSitesP: "0.331825037707391", brLenTotal: "1.1426" },
  { geneTranscript: "NM_014638.4", parsimonySitesP: "0.316148775894539", constantSitesP: "0.5484934086629", distinctSitesP: "0.398775894538606", brLenTotal: "1.4807" },
  { geneTranscript: "NM_004282.4", parsimonySitesP: "0.126382306477093", constantSitesP: "0.766192733017378", distinctSitesP: "0.203791469194313", brLenTotal: "0.4149" },
  { geneTranscript: "NM_001388485.1", parsimonySitesP: "0.152054794520548", constantSitesP: "0.752283105022831", distinctSitesP: "0.192694063926941", brLenTotal: "0.5993" },
  { geneTranscript: "NM_130787.3", parsimonySitesP: "0.18848167539267", constantSitesP: "0.734031413612565", distinctSitesP: "0.225479930191972", brLenTotal: "0.9537" },
  { geneTranscript: "NM_001100913.3", parsimonySitesP: "0.220501474926254", constantSitesP: "0.68141592920354", distinctSitesP: "0.27617994100295", brLenTotal: "1.1174" },
  { geneTranscript: "NM_001011713.3", parsimonySitesP: "0.135359116022099", constantSitesP: "0.698895027624309", distinctSitesP: "0.258747697974217", brLenTotal: "0.4816" },
  { geneTranscript: "NM_017709.4", parsimonySitesP: "0.213981244671782", constantSitesP: "0.648763853367434", distinctSitesP: "0.257459505541347", brLenTotal: "0.9565" },
  { geneTranscript: "NM_001191057.4", parsimonySitesP: "0.196520921485661", constantSitesP: "0.701927597555242", distinctSitesP: "0.27550540667607", brLenTotal: "0.6987" },
  { geneTranscript: "NM_001377935.1", parsimonySitesP: "0.171285299551333", constantSitesP: "0.734758511480602", distinctSitesP: "0.216415940881499", brLenTotal: "0.7356" },
  { geneTranscript: "NM_145814.2", parsimonySitesP: "0.226923076923077", constantSitesP: "0.621794871794872", distinctSitesP: "0.351282051282051", brLenTotal: "0.9380" },
  { geneTranscript: "NM_145691.4", parsimonySitesP: "0.220299884659746", constantSitesP: "0.650519031141869", distinctSitesP: "0.355247981545559", brLenTotal: "0.7761" },
  { geneTranscript: "NM_016047.4", parsimonySitesP: "0.0933333333333333", constantSitesP: "0.813333333333333", distinctSitesP: "0.186666666666667", brLenTotal: "0.3229" },
  { geneTranscript: "NM_000888.5", parsimonySitesP: "0.176395939086294", constantSitesP: "0.703468697123519", distinctSitesP: "0.241116751269036", brLenTotal: "0.6549" },
  { geneTranscript: "NM_032013.4", parsimonySitesP: "0.162666666666667", constantSitesP: "0.698666666666667", distinctSitesP: "0.293333333333333", brLenTotal: "0.5461" },
  { geneTranscript: "NM_001271507.2", parsimonySitesP: "0.225836869401226", constantSitesP: "0.64026402640264", distinctSitesP: "0.297972654408298", brLenTotal: "1.0454" },
  { geneTranscript: "NM_005778.4", parsimonySitesP: "0.114110429447853", constantSitesP: "0.8", distinctSitesP: "0.159918200408998", brLenTotal: "0.3968" },
  { geneTranscript: "NM_001393578.1", parsimonySitesP: "0.589026915113872", constantSitesP: "0.225672877846791", distinctSitesP: "0.779503105590062", brLenTotal: "3.8218" },
  { geneTranscript: "NM_014297.5", parsimonySitesP: "0.217847769028871", constantSitesP: "0.641732283464567", distinctSitesP: "0.334645669291339", brLenTotal: "0.7466" },
  { geneTranscript: "NM_176814.5", parsimonySitesP: "0.0692771084337349", constantSitesP: "0.865963855421687", distinctSitesP: "0.111947791164659", brLenTotal: "0.2244" },
  { geneTranscript: "NM_001128840.3", parsimonySitesP: "0.13250038562394", constantSitesP: "0.786055838346445", distinctSitesP: "0.159339811815518", brLenTotal: "0.5626" },
  { geneTranscript: "NM_003944.4", parsimonySitesP: "0.21680790960452", constantSitesP: "0.665960451977401", distinctSitesP: "0.302966101694915", brLenTotal: "0.8553" },
  { geneTranscript: "NM_016410.6", parsimonySitesP: "0.105022831050228", constantSitesP: "0.792998477929985", distinctSitesP: "0.210045662100457", brLenTotal: "0.3623" },
  { geneTranscript: "NM_178449.4", parsimonySitesP: "0.28", constantSitesP: "0.55", distinctSitesP: "0.463333333333333", brLenTotal: "0.9235" },
  { geneTranscript: "NM_001415.4", parsimonySitesP: "0.140536723163842", constantSitesP: "0.712570621468927", distinctSitesP: "0.267655367231638", brLenTotal: "0.6076" },
  { geneTranscript: "NM_002085.5", parsimonySitesP: "0.230118443316413", constantSitesP: "0.676818950930626", distinctSitesP: "0.336717428087986", brLenTotal: "0.8726" },
  { geneTranscript: "NM_005956.4", parsimonySitesP: "0.220677361853832", constantSitesP: "0.654901960784314", distinctSitesP: "0.298395721925134", brLenTotal: "0.8807" },
  { geneTranscript: "NM_004526.4", parsimonySitesP: "0.271386430678466", constantSitesP: "0.634587020648968", distinctSitesP: "0.335914454277286", brLenTotal: "1.2160" },
  { geneTranscript: "NM_178120.5", parsimonySitesP: "0.0771241830065359", constantSitesP: "0.849673202614379", distinctSitesP: "0.141176470588235", brLenTotal: "0.2535" },
  { geneTranscript: "NM_176875.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.656972408650261", distinctSitesP: "0.304996271439224", brLenTotal: "0.8370" },
  { geneTranscript: "NM_138360.4", parsimonySitesP: "0.165208940719145", constantSitesP: "0.718415937803693", distinctSitesP: "0.227648202137998", brLenTotal: "0.6255" },
  { geneTranscript: "NM_052934.4", parsimonySitesP: "0.184576485461441", constantSitesP: "0.703329119258323", distinctSitesP: "0.259165613147914", brLenTotal: "0.7398" },
  { geneTranscript: "NM_007190.4", parsimonySitesP: "0.193333333333333", constantSitesP: "0.684", distinctSitesP: "0.263666666666667", brLenTotal: "0.6128" },
  { geneTranscript: "NM_001172225.3", parsimonySitesP: "0.479292929292929", constantSitesP: "0.370707070707071", distinctSitesP: "0.606060606060606", brLenTotal: "2.3626" },
  { geneTranscript: "NM_024649.5", parsimonySitesP: "0.226531759415402", constantSitesP: "0.651489600899382", distinctSitesP: "0.299044406970208", brLenTotal: "0.8103" },
  { geneTranscript: "NM_175875.5", parsimonySitesP: "0.22192151556157", constantSitesP: "0.660351826792963", distinctSitesP: "0.271538114569238", brLenTotal: "0.8872" },
  { geneTranscript: "NM_004491.5", parsimonySitesP: "0.139871025127863", constantSitesP: "0.766066266399822", distinctSitesP: "0.161440960640427", brLenTotal: "0.5045" },
  { geneTranscript: "NM_004432.5", parsimonySitesP: "0.0817084493964717", constantSitesP: "0.831940575673166", distinctSitesP: "0.129990714948932", brLenTotal: "0.2753" },
  { geneTranscript: "NM_000834.5", parsimonySitesP: "0.1455525606469", constantSitesP: "0.776055705300988", distinctSitesP: "0.178346810422282", brLenTotal: "0.5834" },
  { geneTranscript: "NM_020719.3", parsimonySitesP: "0.204485920104781", constantSitesP: "0.702521283562541", distinctSitesP: "0.233955468238376", brLenTotal: "0.9772" },
  { geneTranscript: "NM_004248.3", parsimonySitesP: "0.181081081081081", constantSitesP: "0.663963963963964", distinctSitesP: "0.298198198198198", brLenTotal: "0.7857" },
  { geneTranscript: "NM_016368.5", parsimonySitesP: "0.244922341696535", constantSitesP: "0.603345280764636", distinctSitesP: "0.347670250896057", brLenTotal: "1.1213" },
  { geneTranscript: "NM_001377405.1", parsimonySitesP: "0.182361733931241", constantSitesP: "0.701793721973094", distinctSitesP: "0.244768310911809", brLenTotal: "0.6534" },
  { geneTranscript: "NM_001662.4", parsimonySitesP: "0.103703703703704", constantSitesP: "0.812962962962963", distinctSitesP: "0.177777777777778", brLenTotal: "0.3814" },
  { geneTranscript: "NM_173595.4", parsimonySitesP: "0.0938661710037175", constantSitesP: "0.837670384138786", distinctSitesP: "0.12453531598513", brLenTotal: "0.3145" },
  { geneTranscript: "NM_152601.4", parsimonySitesP: "0.430577223088924", constantSitesP: "0.41185647425897", distinctSitesP: "0.557982319292772", brLenTotal: "2.7056" },
  { geneTranscript: "NM_178434.3", parsimonySitesP: "0.514184397163121", constantSitesP: "0.230496453900709", distinctSitesP: "0.797872340425532", brLenTotal: "2.7377" },
  { geneTranscript: "NM_020997.4", parsimonySitesP: "0.306921675774135", constantSitesP: "0.494535519125683", distinctSitesP: "0.477231329690346", brLenTotal: "1.4245" },
  { geneTranscript: "NM_152272.5", parsimonySitesP: "0.128771155261221", constantSitesP: "0.783664459161148", distinctSitesP: "0.187637969094923", brLenTotal: "0.4311" },
  { geneTranscript: "NM_182663.4", parsimonySitesP: "0.212121212121212", constantSitesP: "0.66347687400319", distinctSitesP: "0.294258373205742", brLenTotal: "0.7740" },
  { geneTranscript: "NM_002332.3", parsimonySitesP: "0.162558685446009", constantSitesP: "0.756455399061033", distinctSitesP: "0.159477699530516", brLenTotal: "0.7403" },
  { geneTranscript: "NM_173848.7", parsimonySitesP: "0.117983963344788", constantSitesP: "0.812142038946163", distinctSitesP: "0.201603665521191", brLenTotal: "0.3775" },
  { geneTranscript: "NM_015039.4", parsimonySitesP: "0.136807817589577", constantSitesP: "0.787187839305103", distinctSitesP: "0.205211726384365", brLenTotal: "0.5095" },
  { geneTranscript: "NM_175886.3", parsimonySitesP: "0.229559748427673", constantSitesP: "0.605870020964361", distinctSitesP: "0.351153039832285", brLenTotal: "0.8787" },
  { geneTranscript: "NM_001365341.2", parsimonySitesP: "0.217333333333333", constantSitesP: "0.621333333333333", distinctSitesP: "0.352", brLenTotal: "0.6664" },
  { geneTranscript: "NM_014475.4", parsimonySitesP: "0.277445109780439", constantSitesP: "0.552894211576846", distinctSitesP: "0.403193612774451", brLenTotal: "1.0669" },
  { geneTranscript: "NM_057169.5", parsimonySitesP: "0.126482213438735", constantSitesP: "0.776460254721124", distinctSitesP: "0.172595520421607", brLenTotal: "0.4985" },
  { geneTranscript: "NM_001042590.4", parsimonySitesP: "0.161255411255411", constantSitesP: "0.719336219336219", distinctSitesP: "0.217171717171717", brLenTotal: "0.5756" },
  { geneTranscript: "NM_020650.3", parsimonySitesP: "0.185975609756098", constantSitesP: "0.708333333333333", distinctSitesP: "0.277439024390244", brLenTotal: "0.8215" },
  { geneTranscript: "NM_001428.5", parsimonySitesP: "0.263440860215054", constantSitesP: "0.625192012288786", distinctSitesP: "0.35099846390169", brLenTotal: "1.8096" },
  { geneTranscript: "NM_004795.4", parsimonySitesP: "0.250329380764163", constantSitesP: "0.632740447957839", distinctSitesP: "0.346179183135705", brLenTotal: "0.9743" },
  { geneTranscript: "NM_003295.4", parsimonySitesP: "0.0910852713178295", constantSitesP: "0.746124031007752", distinctSitesP: "0.238372093023256", brLenTotal: "0.4076" },
  { geneTranscript: "NM_031947.4", parsimonySitesP: "0.261351052048726", constantSitesP: "0.586932447397564", distinctSitesP: "0.36766334440753", brLenTotal: "0.9727" },
  { geneTranscript: "NM_004814.3", parsimonySitesP: "0.142857142857143", constantSitesP: "0.719887955182073", distinctSitesP: "0.246498599439776", brLenTotal: "0.5484" },
  { geneTranscript: "NM_016235.3", parsimonySitesP: "0.165425971877585", constantSitesP: "0.742762613730356", distinctSitesP: "0.23490488006617", brLenTotal: "0.7793" },
  { geneTranscript: "NM_022078.3", parsimonySitesP: "0.25968253968254", constantSitesP: "0.587301587301587", distinctSitesP: "0.343492063492063", brLenTotal: "0.9070" },
  { geneTranscript: "NM_033510.3", parsimonySitesP: "0.243397573162027", constantSitesP: "0.620985010706638", distinctSitesP: "0.256483464192244", brLenTotal: "0.7915" },
  { geneTranscript: "NM_015192.4", parsimonySitesP: "0.145010964912281", constantSitesP: "0.770010964912281", distinctSitesP: "0.195449561403509", brLenTotal: "0.5266" },
  { geneTranscript: "NM_203500.2", parsimonySitesP: "0.198183760683761", constantSitesP: "0.697115384615385", distinctSitesP: "0.24732905982906", brLenTotal: "0.9465" },
  { geneTranscript: "NM_025179.4", parsimonySitesP: "0.180042238648363", constantSitesP: "0.734424498416051", distinctSitesP: "0.198345652939106", brLenTotal: "0.7747" },
  { geneTranscript: "NM_001827.3", parsimonySitesP: "0.10126582278481", constantSitesP: "0.679324894514768", distinctSitesP: "0.278481012658228", brLenTotal: "0.4890" },
  { geneTranscript: "NM_001358.3", parsimonySitesP: "0.0964360587002096", constantSitesP: "0.810901467505241", distinctSitesP: "0.147169811320755", brLenTotal: "0.3501" },
  { geneTranscript: "NM_021942.6", parsimonySitesP: "0.173286260664901", constantSitesP: "0.724919093851133", distinctSitesP: "0.24771991762283", brLenTotal: "0.6325" },
  { geneTranscript: "NM_000867.5", parsimonySitesP: "0.178794178794179", constantSitesP: "0.688842688842689", distinctSitesP: "0.275814275814276", brLenTotal: "0.5421" },
  { geneTranscript: "NM_001370472.1", parsimonySitesP: "0.149012567324955", constantSitesP: "0.73967684021544", distinctSitesP: "0.187312986235787", brLenTotal: "0.6480" },
  { geneTranscript: "NM_015353.3", parsimonySitesP: "0.173637515842839", constantSitesP: "0.73510773130545", distinctSitesP: "0.282636248415716", brLenTotal: "0.6367" },
  { geneTranscript: "NM_052885.4", parsimonySitesP: "0.126543209876543", constantSitesP: "0.78343621399177", distinctSitesP: "0.191358024691358", brLenTotal: "0.3880" },
  { geneTranscript: "NM_001003845.3", parsimonySitesP: "0.215242881072027", constantSitesP: "0.686767169179229", distinctSitesP: "0.278056951423786", brLenTotal: "1.0409" },
  { geneTranscript: "NM_178507.4", parsimonySitesP: "0.206349206349206", constantSitesP: "0.63003663003663", distinctSitesP: "0.356532356532357", brLenTotal: "1.0624" },
  { geneTranscript: "NM_033132.5", parsimonySitesP: "0.151799687010955", constantSitesP: "0.729786124152321", distinctSitesP: "0.267083985393845", brLenTotal: "0.6265" },
  { geneTranscript: "NM_001379291.1", parsimonySitesP: "0.175232501223691", constantSitesP: "0.726872246696035", distinctSitesP: "0.223445912873226", brLenTotal: "0.7364" },
  { geneTranscript: "NM_007040.6", parsimonySitesP: "0.128894080996885", constantSitesP: "0.760903426791277", distinctSitesP: "0.181853582554517", brLenTotal: "0.4620" },
  { geneTranscript: "NM_000991.5", parsimonySitesP: "0.16301703163017", constantSitesP: "0.654501216545012", distinctSitesP: "0.326034063260341", brLenTotal: "0.7741" },
  { geneTranscript: "NM_007112.5", parsimonySitesP: "0.134937238493724", constantSitesP: "0.76255230125523", distinctSitesP: "0.172245467224547", brLenTotal: "0.4906" },
  { geneTranscript: "NM_001123375.3", parsimonySitesP: "0.281862745098039", constantSitesP: "0.681372549019608", distinctSitesP: "0.316176470588235", brLenTotal: "2.9216" },
  { geneTranscript: "NM_198516.3", parsimonySitesP: "0.14168039538715", constantSitesP: "0.763866007688083", distinctSitesP: "0.198242723778144", brLenTotal: "0.6518" },
  { geneTranscript: "NM_007253.4", parsimonySitesP: "0.397435897435897", constantSitesP: "0.445512820512821", distinctSitesP: "0.526923076923077", brLenTotal: "2.5452" },
  { geneTranscript: "NM_001001660.3", parsimonySitesP: "0.144444444444444", constantSitesP: "0.781481481481481", distinctSitesP: "0.262962962962963", brLenTotal: "0.3508" },
  { geneTranscript: "NM_014937.4", parsimonySitesP: "0.169611307420495", constantSitesP: "0.698468786808009", distinctSitesP: "0.25736160188457", brLenTotal: "0.6065" },
  { geneTranscript: "NM_144666.3", parsimonySitesP: "0.297145662388667", constantSitesP: "0.528368048250228", distinctSitesP: "0.350164808191318", brLenTotal: "0.9856" },
  { geneTranscript: "NM_021237.5", parsimonySitesP: "0.102836879432624", constantSitesP: "0.75531914893617", distinctSitesP: "0.290780141843972", brLenTotal: "0.3872" },
  { geneTranscript: "NM_032611.3", parsimonySitesP: "0.194605009633911", constantSitesP: "0.699421965317919", distinctSitesP: "0.273603082851638", brLenTotal: "1.1459" },
  { geneTranscript: "NM_031298.4", parsimonySitesP: "0.124242424242424", constantSitesP: "0.809090909090909", distinctSitesP: "0.206060606060606", brLenTotal: "0.3829" },
  { geneTranscript: "NM_001129742.2", parsimonySitesP: "0.265503875968992", constantSitesP: "0.584302325581395", distinctSitesP: "0.366279069767442", brLenTotal: "1.2790" },
  { geneTranscript: "NM_138396.6", parsimonySitesP: "0.131984585741811", constantSitesP: "0.765895953757225", distinctSitesP: "0.202312138728324", brLenTotal: "0.5463" },
  { geneTranscript: "NM_024577.4", parsimonySitesP: "0.261645962732919", constantSitesP: "0.589285714285714", distinctSitesP: "0.331262939958592", brLenTotal: "0.9346" },
  { geneTranscript: "NM_006180.6", parsimonySitesP: "0.139220365950676", constantSitesP: "0.768894192521877", distinctSitesP: "0.199284009546539", brLenTotal: "0.5403" },
  { geneTranscript: "NM_016581.5", parsimonySitesP: "0.332559938128384", constantSitesP: "0.525135344160866", distinctSitesP: "0.453982985305491", brLenTotal: "1.2357" },
  { geneTranscript: "NM_001151.4", parsimonySitesP: "0.223713646532438", constantSitesP: "0.694630872483221", distinctSitesP: "0.282997762863535", brLenTotal: "0.8785" },
  { geneTranscript: "NM_001268.4", parsimonySitesP: "0.180883242589232", constantSitesP: "0.73200241984271", distinctSitesP: "0.250453720508167", brLenTotal: "0.6825" },
  { geneTranscript: "NM_001153.5", parsimonySitesP: "0.179646936656282", constantSitesP: "0.716510903426791", distinctSitesP: "0.284527518172378", brLenTotal: "0.7024" },
  { geneTranscript: "NM_001044370.2", parsimonySitesP: "0.183026584867076", constantSitesP: "0.757668711656442", distinctSitesP: "0.21881390593047", brLenTotal: "0.8665" },
  { geneTranscript: "NM_001102575.2", parsimonySitesP: "0.159188034188034", constantSitesP: "0.682692307692308", distinctSitesP: "0.228632478632479", brLenTotal: "0.6679" },
  { geneTranscript: "NM_006062.3", parsimonySitesP: "0.155502392344498", constantSitesP: "0.756778309409888", distinctSitesP: "0.202551834130781", brLenTotal: "0.4642" },
  { geneTranscript: "NM_033030.6", parsimonySitesP: "0.114252061248528", constantSitesP: "0.76678445229682", distinctSitesP: "0.228504122497055", brLenTotal: "0.3745" },
  { geneTranscript: "NM_006794.4", parsimonySitesP: "0.177777777777778", constantSitesP: "0.695061728395062", distinctSitesP: "0.262345679012346", brLenTotal: "0.7018" },
  { geneTranscript: "NM_001297663.2", parsimonySitesP: "0.166377816291161", constantSitesP: "0.736568457538995", distinctSitesP: "0.238012709416522", brLenTotal: "0.5811" },
  { geneTranscript: "NM_001130004.2", parsimonySitesP: "0.148796498905908", constantSitesP: "0.75528811086798", distinctSitesP: "0.192560175054705", brLenTotal: "0.7869" },
  { geneTranscript: "NM_001007533.4", parsimonySitesP: "0.24025974025974", constantSitesP: "0.632034632034632", distinctSitesP: "0.33982683982684", brLenTotal: "0.8781" },
  { geneTranscript: "NM_003713.5", parsimonySitesP: "0.144694533762058", constantSitesP: "0.766345123258307", distinctSitesP: "0.203644158628081", brLenTotal: "0.5398" },
  { geneTranscript: "NM_014939.5", parsimonySitesP: "0.124506387921022", constantSitesP: "0.771428571428571", distinctSitesP: "0.17212543554007", brLenTotal: "0.3999" },
  { geneTranscript: "NM_032862.5", parsimonySitesP: "0.249221183800623", constantSitesP: "0.613707165109034", distinctSitesP: "0.341121495327103", brLenTotal: "1.0787" },
  { geneTranscript: "NM_003560.4", parsimonySitesP: "0.222497932175352", constantSitesP: "0.659636062861869", distinctSitesP: "0.2808105872622", brLenTotal: "0.9137" },
  { geneTranscript: "NM_031448.6", parsimonySitesP: "0.335697399527187", constantSitesP: "0.515366430260047", distinctSitesP: "0.505910165484634", brLenTotal: "1.7092" },
  { geneTranscript: "NM_012391.3", parsimonySitesP: "0.204975124378109", constantSitesP: "0.699502487562189", distinctSitesP: "0.272636815920398", brLenTotal: "0.8583" },
  { geneTranscript: "NM_000545.8", parsimonySitesP: "0.186476492340201", constantSitesP: "0.693079767564712", distinctSitesP: "0.246170100369783", brLenTotal: "0.8642" },
  { geneTranscript: "NM_032635.4", parsimonySitesP: "0.136904761904762", constantSitesP: "0.788690476190476", distinctSitesP: "0.202380952380952", brLenTotal: "0.4581" },
  { geneTranscript: "NM_172107.4", parsimonySitesP: "0.193042813455657", constantSitesP: "0.715596330275229", distinctSitesP: "0.238914373088685", brLenTotal: "1.1497" },
  { geneTranscript: "NM_030973.4", parsimonySitesP: "0.190986166889781", constantSitesP: "0.721106648817492", distinctSitesP: "0.233377956269523", brLenTotal: "0.9117" },
  { geneTranscript: "NM_170736.3", parsimonySitesP: "0.243555555555556", constantSitesP: "0.573333333333333", distinctSitesP: "0.328", brLenTotal: "1.8631" },
  { geneTranscript: "NM_000277.3", parsimonySitesP: "0.193215339233038", constantSitesP: "0.693952802359882", distinctSitesP: "0.278023598820059", brLenTotal: "0.7136" },
  { geneTranscript: "NM_015179.4", parsimonySitesP: "0.220508866615266", constantSitesP: "0.632742225648933", distinctSitesP: "0.295553842199949", brLenTotal: "0.8800" },
  { geneTranscript: "NM_002840.5", parsimonySitesP: "0.216920118860339", constantSitesP: "0.699353259919594", distinctSitesP: "0.222863135815417", brLenTotal: "1.0156" },
  { geneTranscript: "NM_002629.4", parsimonySitesP: "0.219160104986877", constantSitesP: "0.590551181102362", distinctSitesP: "0.36745406824147", brLenTotal: "0.9013" },
  { geneTranscript: "NM_139055.4", parsimonySitesP: "0.19719298245614", constantSitesP: "0.673333333333333", distinctSitesP: "0.254385964912281", brLenTotal: "0.8656" },
  { geneTranscript: "NM_001142551.2", parsimonySitesP: "0.11461733768589", constantSitesP: "0.800145085237577", distinctSitesP: "0.172288719622778", brLenTotal: "0.3899" },
  { geneTranscript: "NM_003526.3", parsimonySitesP: "0.222222222222222", constantSitesP: "0.62962962962963", distinctSitesP: "0.325396825396825", brLenTotal: "1.4936" },
  { geneTranscript: "NM_031908.6", parsimonySitesP: "0.185964912280702", constantSitesP: "0.699415204678363", distinctSitesP: "0.276023391812865", brLenTotal: "0.8223" },
  { geneTranscript: "NM_001300905.2", parsimonySitesP: "0.18111753371869", constantSitesP: "0.684708355228586", distinctSitesP: "0.216500262743037", brLenTotal: "0.5859" },
  { geneTranscript: "NM_003736.4", parsimonySitesP: "0.428674611773203", constantSitesP: "0.439508847959552", distinctSitesP: "0.546767786204406", brLenTotal: "3.7495" },
  { geneTranscript: "NM_001366735.2", parsimonySitesP: "0.0969877523998676", constantSitesP: "0.833167825223436", distinctSitesP: "0.12975835815955", brLenTotal: "0.2972" },
  { geneTranscript: "NM_002758.4", parsimonySitesP: "0.13872255489022", constantSitesP: "0.774451097804391", distinctSitesP: "0.216566866267465", brLenTotal: "0.5646" },
  { geneTranscript: "NM_030769.3", parsimonySitesP: "0.169791666666667", constantSitesP: "0.698958333333333", distinctSitesP: "0.272916666666667", brLenTotal: "0.5737" },
  { geneTranscript: "NM_002998.4", parsimonySitesP: "0.14759535655058", constantSitesP: "0.718076285240464", distinctSitesP: "0.280265339966833", brLenTotal: "0.5780" },
  { geneTranscript: "NM_031948.5", parsimonySitesP: "0.295402298850575", constantSitesP: "0.559770114942529", distinctSitesP: "0.426436781609195", brLenTotal: "1.3522" },
  { geneTranscript: "NM_015226.3", parsimonySitesP: "0.182336182336182", constantSitesP: "0.708768597657487", distinctSitesP: "0.257993035770814", brLenTotal: "0.7471" },
  { geneTranscript: "NM_001004755.2", parsimonySitesP: "0.549206349206349", constantSitesP: "0.334391534391534", distinctSitesP: "0.671957671957672", brLenTotal: "2.3221" },
  { geneTranscript: "NM_003120.3", parsimonySitesP: "0.2", constantSitesP: "0.725925925925926", distinctSitesP: "0.254320987654321", brLenTotal: "0.7819" },
  { geneTranscript: "NM_001317778.2", parsimonySitesP: "0.261780104712042", constantSitesP: "0.582897033158813", distinctSitesP: "0.406631762652705", brLenTotal: "1.0750" },
  { geneTranscript: "NM_001306.4", parsimonySitesP: "0.346969696969697", constantSitesP: "0.525757575757576", distinctSitesP: "0.463636363636364", brLenTotal: "1.7618" },
  { geneTranscript: "NM_004038.4", parsimonySitesP: "0.233529028049576", constantSitesP: "0.635355512067841", distinctSitesP: "0.361382909328115", brLenTotal: "1.0185" },
  { geneTranscript: "NM_001387994.1", parsimonySitesP: "0.153471026965003", constantSitesP: "0.746987951807229", distinctSitesP: "0.193918531267929", brLenTotal: "0.5310" },
  { geneTranscript: "NM_004277.5", parsimonySitesP: "0.118679050567595", constantSitesP: "0.775025799793602", distinctSitesP: "0.209494324045408", brLenTotal: "0.4435" },
  { geneTranscript: "NM_032829.3", parsimonySitesP: "0.222713864306785", constantSitesP: "0.655604719764012", distinctSitesP: "0.304572271386431", brLenTotal: "1.3095" },
  { geneTranscript: "NM_001884.4", parsimonySitesP: "0.177024482109228", constantSitesP: "0.718455743879473", distinctSitesP: "0.273069679849341", brLenTotal: "0.7996" },
  { geneTranscript: "NM_002025.4", parsimonySitesP: "0.183574879227053", constantSitesP: "0.698449021103483", distinctSitesP: "0.235952199338927", brLenTotal: "0.6073" },
  { geneTranscript: "NM_006671.6", parsimonySitesP: "0.195238095238095", constantSitesP: "0.707142857142857", distinctSitesP: "0.257738095238095", brLenTotal: "1.0129" },
  { geneTranscript: "NM_019896.4", parsimonySitesP: "0.253561253561254", constantSitesP: "0.666666666666667", distinctSitesP: "0.37037037037037", brLenTotal: "0.8266" },
  { geneTranscript: "NM_178868.5", parsimonySitesP: "0.157996146435453", constantSitesP: "0.763005780346821", distinctSitesP: "0.248554913294798", brLenTotal: "0.7261" },
  { geneTranscript: "NM_018178.6", parsimonySitesP: "0.171929824561404", constantSitesP: "0.725146198830409", distinctSitesP: "0.261988304093567", brLenTotal: "0.5547" },
  { geneTranscript: "NM_002513.3", parsimonySitesP: "0.2465483234714", constantSitesP: "0.633136094674556", distinctSitesP: "0.358974358974359", brLenTotal: "0.9127" },
  { geneTranscript: "NM_145204.4", parsimonySitesP: "0.20125786163522", constantSitesP: "0.650943396226415", distinctSitesP: "0.29559748427673", brLenTotal: "0.6331" },
  { geneTranscript: "NM_005022.4", parsimonySitesP: "0.130952380952381", constantSitesP: "0.735714285714286", distinctSitesP: "0.245238095238095", brLenTotal: "0.4393" },
  { geneTranscript: "NM_152906.7", parsimonySitesP: "0.254830917874396", constantSitesP: "0.635265700483092", distinctSitesP: "0.355072463768116", brLenTotal: "1.1554" },
  { geneTranscript: "NM_001013631.3", parsimonySitesP: "0.587030716723549", constantSitesP: "0.176336746302617", distinctSitesP: "0.825938566552901", brLenTotal: "2.6472" },
  { geneTranscript: "NM_000523.4", parsimonySitesP: "0.185617103984451", constantSitesP: "0.724003887269193", distinctSitesP: "0.252672497570457", brLenTotal: "0.7705" },
  { geneTranscript: "NM_001145064.3", parsimonySitesP: "0.144883485309017", constantSitesP: "0.780141843971631", distinctSitesP: "0.206686930091185", brLenTotal: "0.6681" },
  { geneTranscript: "NM_005952.4", parsimonySitesP: "0.256830601092896", constantSitesP: "0.557377049180328", distinctSitesP: "0.46448087431694", brLenTotal: "1.5940" },
  { geneTranscript: "NM_000257.4", parsimonySitesP: "0.233074935400517", constantSitesP: "0.662876830318691", distinctSitesP: "0.273557278208441", brLenTotal: "1.4994" },
  { geneTranscript: "NM_005368.3", parsimonySitesP: "0.218614718614719", constantSitesP: "0.694805194805195", distinctSitesP: "0.324675324675325", brLenTotal: "1.0967" },
  { geneTranscript: "NM_000108.5", parsimonySitesP: "0.107400130975769", constantSitesP: "0.783235101506221", distinctSitesP: "0.185985592665357", brLenTotal: "0.3936" },
  { geneTranscript: "NM_015725.4", parsimonySitesP: "0.267952840300107", constantSitesP: "0.584137191854234", distinctSitesP: "0.369774919614148", brLenTotal: "1.0829" },
  { geneTranscript: "NM_003249.5", parsimonySitesP: "0.225931301403", constantSitesP: "0.668118045476536", distinctSitesP: "0.297048863086599", brLenTotal: "1.1804" },
  { geneTranscript: "NM_001080467.3", parsimonySitesP: "0.193362193362193", constantSitesP: "0.690656565656566", distinctSitesP: "0.246212121212121", brLenTotal: "0.7462" },
  { geneTranscript: "NM_003534.3", parsimonySitesP: "0.294117647058824", constantSitesP: "0.649509803921569", distinctSitesP: "0.355392156862745", brLenTotal: "2.3760" },
  { geneTranscript: "NM_022719.3", parsimonySitesP: "0.222689075630252", constantSitesP: "0.638655462184874", distinctSitesP: "0.317226890756303", brLenTotal: "0.9185" },
  { geneTranscript: "NM_003132.3", parsimonySitesP: "0.206401766004415", constantSitesP: "0.644591611479029", distinctSitesP: "0.316777041942605", brLenTotal: "0.8351" },
  { geneTranscript: "NM_019845.3", parsimonySitesP: "0.137614678899083", constantSitesP: "0.773700305810398", distinctSitesP: "0.232415902140673", brLenTotal: "0.4159" },
  { geneTranscript: "NM_182854.4", parsimonySitesP: "0.319620253164557", constantSitesP: "0.503164556962025", distinctSitesP: "0.449367088607595", brLenTotal: "1.3881" },
  { geneTranscript: "NM_194284.3", parsimonySitesP: "0.299086757990868", constantSitesP: "0.544520547945205", distinctSitesP: "0.444063926940639", brLenTotal: "1.1153" },
  { geneTranscript: "NM_153230.3", parsimonySitesP: "0.238310708898944", constantSitesP: "0.635746606334842", distinctSitesP: "0.315233785822021", brLenTotal: "0.7798" },
  { geneTranscript: "NM_005811.5", parsimonySitesP: "0.0925470925470925", constantSitesP: "0.827190827190827", distinctSitesP: "0.16953316953317", brLenTotal: "0.2992" },
  { geneTranscript: "NM_014820.5", parsimonySitesP: "0.126644736842105", constantSitesP: "0.775767543859649", distinctSitesP: "0.18969298245614", brLenTotal: "0.3994" },
  { geneTranscript: "NM_001017425.3", parsimonySitesP: "0.128325508607199", constantSitesP: "0.804381846635368", distinctSitesP: "0.172143974960876", brLenTotal: "0.4497" },
  { geneTranscript: "NM_144568.4", parsimonySitesP: "0.11913357400722", constantSitesP: "0.8014440433213", distinctSitesP: "0.179302045728039", brLenTotal: "0.3653" },
  { geneTranscript: "NM_024074.4", parsimonySitesP: "0.206243032329989", constantSitesP: "0.684503901895206", distinctSitesP: "0.290969899665552", brLenTotal: "0.9344" },
  { geneTranscript: "NM_004470.4", parsimonySitesP: "0.152582159624413", constantSitesP: "0.678403755868545", distinctSitesP: "0.2981220657277", brLenTotal: "0.6516" },
  { geneTranscript: "NM_021907.5", parsimonySitesP: "0.110047846889952", constantSitesP: "0.805422647527911", distinctSitesP: "0.160552897395003", brLenTotal: "0.3934" },
  { geneTranscript: "NM_006844.5", parsimonySitesP: "0.266877637130802", constantSitesP: "0.608122362869198", distinctSitesP: "0.333333333333333", brLenTotal: "1.0955" },
  { geneTranscript: "NM_001378183.1", parsimonySitesP: "0.181500872600349", constantSitesP: "0.716114019778941", distinctSitesP: "0.234322280395579", brLenTotal: "0.7163" },
  { geneTranscript: "NM_020770.3", parsimonySitesP: "0.223330562482682", constantSitesP: "0.626489332224993", distinctSitesP: "0.270989193682461", brLenTotal: "0.7407" },
  { geneTranscript: "NM_001429.4", parsimonySitesP: "0.159210162938415", constantSitesP: "0.71886219276443", distinctSitesP: "0.200911350455675", brLenTotal: "0.5759" },
  { geneTranscript: "NM_004767.5", parsimonySitesP: "0.223146223146223", constantSitesP: "0.659043659043659", distinctSitesP: "0.295911295911296", brLenTotal: "0.8834" },
  { geneTranscript: "NM_020898.3", parsimonySitesP: "0.165943077665219", constantSitesP: "0.701398938736131", distinctSitesP: "0.231548480463097", brLenTotal: "0.5707" },
  { geneTranscript: "NM_004807.3", parsimonySitesP: "0.155717761557178", constantSitesP: "0.688564476885645", distinctSitesP: "0.209245742092457", brLenTotal: "0.8733" },
  { geneTranscript: "NM_001330588.2", parsimonySitesP: "0.132857897517169", constantSitesP: "0.772583201267829", distinctSitesP: "0.176175382989963", brLenTotal: "0.4565" },
  { geneTranscript: "NM_006042.3", parsimonySitesP: "0.244663382594417", constantSitesP: "0.591133004926108", distinctSitesP: "0.342364532019704", brLenTotal: "1.0814" },
  { geneTranscript: "NM_018694.4", parsimonySitesP: "0.244725738396624", constantSitesP: "0.60056258790436", distinctSitesP: "0.419127988748242", brLenTotal: "0.9826" },
  { geneTranscript: "NM_001089.3", parsimonySitesP: "0.237284820031299", constantSitesP: "0.646322378716745", distinctSitesP: "0.28971048513302", brLenTotal: "1.0949" },
  { geneTranscript: "NM_001533.3", parsimonySitesP: "0.11431805319751", constantSitesP: "0.792303338992643", distinctSitesP: "0.173174872665535", brLenTotal: "0.4213" },
  { geneTranscript: "NM_144628.4", parsimonySitesP: "0.156327543424318", constantSitesP: "0.729528535980149", distinctSitesP: "0.226633581472291", brLenTotal: "0.5776" },
  { geneTranscript: "NM_017839.5", parsimonySitesP: "0.181985294117647", constantSitesP: "0.693627450980392", distinctSitesP: "0.28125", brLenTotal: "0.5978" },
  { geneTranscript: "NM_005880.4", parsimonySitesP: "0.149676375404531", constantSitesP: "0.748381877022654", distinctSitesP: "0.209546925566343", brLenTotal: "0.4758" },
  { geneTranscript: "NM_003136.4", parsimonySitesP: "0.085978835978836", constantSitesP: "0.84457671957672", distinctSitesP: "0.141534391534392", brLenTotal: "0.2709" },
  { geneTranscript: "NM_014623.4", parsimonySitesP: "0.158558558558559", constantSitesP: "0.700900900900901", distinctSitesP: "0.302702702702703", brLenTotal: "0.5789" },
  { geneTranscript: "NM_018715.4", parsimonySitesP: "0.156449553001277", constantSitesP: "0.749680715197957", distinctSitesP: "0.229885057471264", brLenTotal: "0.6622" },
  { geneTranscript: "NM_003146.3", parsimonySitesP: "0.146215326751293", constantSitesP: "0.77103902209685", distinctSitesP: "0.188998589562764", brLenTotal: "0.5188" },
  { geneTranscript: "NM_001355573.2", parsimonySitesP: "0.189542483660131", constantSitesP: "0.657516339869281", distinctSitesP: "0.36078431372549", brLenTotal: "0.7214" },
  { geneTranscript: "NM_033331.4", parsimonySitesP: "0.152610441767068", constantSitesP: "0.735609103078983", distinctSitesP: "0.251004016064257", brLenTotal: "0.4889" },
  { geneTranscript: "NM_001008495.4", parsimonySitesP: "0.149122807017544", constantSitesP: "0.709649122807018", distinctSitesP: "0.294736842105263", brLenTotal: "0.5623" },
  { geneTranscript: "NM_000373.4", parsimonySitesP: "0.211111111111111", constantSitesP: "0.609722222222222", distinctSitesP: "0.332638888888889", brLenTotal: "0.7593" },
  { geneTranscript: "NM_004275.5", parsimonySitesP: "0.136792452830189", constantSitesP: "0.778301886792453", distinctSitesP: "0.213836477987421", brLenTotal: "0.4678" },
  { geneTranscript: "NM_001321967.2", parsimonySitesP: "0.141274238227147", constantSitesP: "0.734995383194829", distinctSitesP: "0.221606648199446", brLenTotal: "0.4500" },
  { geneTranscript: "NM_006601.7", parsimonySitesP: "0.0604166666666667", constantSitesP: "0.86875", distinctSitesP: "0.129166666666667", brLenTotal: "0.1961" },
  { geneTranscript: "NM_000548.5", parsimonySitesP: "0.240730492529054", constantSitesP: "0.654122855561704", distinctSitesP: "0.305294226157536", brLenTotal: "1.0912" },
  { geneTranscript: "NM_017896.3", parsimonySitesP: "0.16812865497076", constantSitesP: "0.748538011695906", distinctSitesP: "0.223684210526316", brLenTotal: "0.7428" },
  { geneTranscript: "NM_002191.4", parsimonySitesP: "0.265938069216758", constantSitesP: "0.557377049180328", distinctSitesP: "0.373406193078324", brLenTotal: "0.9502" },
  { geneTranscript: "NM_000598.5", parsimonySitesP: "0.256586483390607", constantSitesP: "0.571592210767468", distinctSitesP: "0.373424971363116", brLenTotal: "1.1147" },
  { geneTranscript: "NM_002451.4", parsimonySitesP: "0.175500588928151", constantSitesP: "0.702002355712603", distinctSitesP: "0.289752650176678", brLenTotal: "0.6154" },
  { geneTranscript: "NM_003985.6", parsimonySitesP: "0.255168935955623", constantSitesP: "0.59606656580938", distinctSitesP: "0.339384770549672", brLenTotal: "0.9178" },
  { geneTranscript: "NM_001001658.1", parsimonySitesP: "0.38494623655914", constantSitesP: "0.398924731182796", distinctSitesP: "0.563440860215054", brLenTotal: "1.5776" },
  { geneTranscript: "NM_016213.5", parsimonySitesP: "0.151462994836489", constantSitesP: "0.728628800917958", distinctSitesP: "0.230636833046472", brLenTotal: "0.4791" },
  { geneTranscript: "NM_000679.4", parsimonySitesP: "0.137820512820513", constantSitesP: "0.746153846153846", distinctSitesP: "0.208333333333333", brLenTotal: "0.5306" },
  { geneTranscript: "NM_001258285.2", parsimonySitesP: "0.416396979503776", constantSitesP: "0.435814455231931", distinctSitesP: "0.492988133764833", brLenTotal: "1.5042" },
  { geneTranscript: "NM_002810.4", parsimonySitesP: "0.15473032714412", constantSitesP: "0.727674624226348", distinctSitesP: "0.24314765694076", brLenTotal: "0.6292" },
  { geneTranscript: "NM_002542.6", parsimonySitesP: "0.240579710144928", constantSitesP: "0.608695652173913", distinctSitesP: "0.361352657004831", brLenTotal: "0.8584" },
  { geneTranscript: "NM_152716.3", parsimonySitesP: "0.121645021645022", constantSitesP: "0.76017316017316", distinctSitesP: "0.191341991341991", brLenTotal: "0.3969" },
  { geneTranscript: "NM_153498.4", parsimonySitesP: "0.164502164502165", constantSitesP: "0.751515151515152", distinctSitesP: "0.234632034632035", brLenTotal: "0.7059" },
  { geneTranscript: "NM_000292.3", parsimonySitesP: "0.187314439946019", constantSitesP: "0.71497975708502", distinctSitesP: "0.259919028340081", brLenTotal: "0.6761" },
  { geneTranscript: "NM_201550.4", parsimonySitesP: "0.25511432009627", constantSitesP: "0.610108303249097", distinctSitesP: "0.340553549939832", brLenTotal: "0.9041" },
  { geneTranscript: "NM_173477.5", parsimonySitesP: "0.15762834417932", constantSitesP: "0.723065798987708", distinctSitesP: "0.235719450469993", brLenTotal: "0.5436" },
  { geneTranscript: "NM_000625.4", parsimonySitesP: "0.246892165365713", constantSitesP: "0.606533680254409", distinctSitesP: "0.342873662908355", brLenTotal: "1.1126" },
  { geneTranscript: "NM_152772.3", parsimonySitesP: "0.156711624919717", constantSitesP: "0.702633269107258", distinctSitesP: "0.251766217084136", brLenTotal: "0.5137" },
  { geneTranscript: "NM_001134658.3", parsimonySitesP: "0.273059360730594", constantSitesP: "0.578995433789954", distinctSitesP: "0.436529680365297", brLenTotal: "0.9586" },
  { geneTranscript: "NM_001394390.1", parsimonySitesP: "0.205235602094241", constantSitesP: "0.642233856893543", distinctSitesP: "0.280977312390925", brLenTotal: "0.7931" },
  { geneTranscript: "NM_031492.4", parsimonySitesP: "0.248839368616527", constantSitesP: "0.614670380687094", distinctSitesP: "0.375116063138347", brLenTotal: "1.1497" },
  { geneTranscript: "NM_020170.4", parsimonySitesP: "0.191237418590882", constantSitesP: "0.714031971580817", distinctSitesP: "0.261693309650681", brLenTotal: "0.9680" },
  { geneTranscript: "NM_005053.4", parsimonySitesP: "0.166207529843893", constantSitesP: "0.742883379247016", distinctSitesP: "0.228650137741047", brLenTotal: "0.5953" },
  { geneTranscript: "NM_015506.3", parsimonySitesP: "0.250591016548463", constantSitesP: "0.606382978723404", distinctSitesP: "0.340425531914894", brLenTotal: "0.8651" },
  { geneTranscript: "NM_199351.3", parsimonySitesP: "0.174230568596766", constantSitesP: "0.697965571205008", distinctSitesP: "0.262389149713093", brLenTotal: "0.6707" },
  { geneTranscript: "NM_016535.4", parsimonySitesP: "0.241962774957699", constantSitesP: "0.641285956006768", distinctSitesP: "0.346869712351946", brLenTotal: "1.0013" },
  { geneTranscript: "NM_138964.4", parsimonySitesP: "0.257845631891433", constantSitesP: "0.633587786259542", distinctSitesP: "0.351145038167939", brLenTotal: "1.1262" },
  { geneTranscript: "NM_001330103.2", parsimonySitesP: "0.118261826182618", constantSitesP: "0.784928492849285", distinctSitesP: "0.187568756875688", brLenTotal: "0.3721" },
  { geneTranscript: "NM_002797.5", parsimonySitesP: "0.211660329531052", constantSitesP: "0.600760456273764", distinctSitesP: "0.346007604562738", brLenTotal: "0.7656" },
  { geneTranscript: "NM_139015.5", parsimonySitesP: "0.105902777777778", constantSitesP: "0.8125", distinctSitesP: "0.159722222222222", brLenTotal: "0.4115" },
  { geneTranscript: "NM_003396.3", parsimonySitesP: "0.199813258636788", constantSitesP: "0.676937441643324", distinctSitesP: "0.289449112978525", brLenTotal: "0.9235" },
  { geneTranscript: "NM_007013.4", parsimonySitesP: "0.147866955892986", constantSitesP: "0.724511930585683", distinctSitesP: "0.209327548806941", brLenTotal: "0.5310" },
  { geneTranscript: "NM_016101.5", parsimonySitesP: "0.175925925925926", constantSitesP: "0.716666666666667", distinctSitesP: "0.281481481481481", brLenTotal: "0.5669" },
  { geneTranscript: "NM_006762.3", parsimonySitesP: "0.241730279898219", constantSitesP: "0.618320610687023", distinctSitesP: "0.388040712468193", brLenTotal: "1.0805" },
  { geneTranscript: "NM_001002905.2", parsimonySitesP: "0.532690246516613", constantSitesP: "0.335476956055734", distinctSitesP: "0.658092175777063", brLenTotal: "3.8912" },
  { geneTranscript: "NM_007204.5", parsimonySitesP: "0.204692556634304", constantSitesP: "0.638754045307443", distinctSitesP: "0.307443365695793", brLenTotal: "0.6877" },
  { geneTranscript: "NM_015922.3", parsimonySitesP: "0.223413762287757", constantSitesP: "0.655049151027703", distinctSitesP: "0.313672922252011", brLenTotal: "0.8359" },
  { geneTranscript: "NM_018111.3", parsimonySitesP: "0.547803617571059", constantSitesP: "0.180878552971576", distinctSitesP: "0.808785529715762", brLenTotal: "3.3915" },
  { geneTranscript: "NM_001289974.2", parsimonySitesP: "0.241220495106505", constantSitesP: "0.624640184225676", distinctSitesP: "0.331030512377663", brLenTotal: "1.0005" },
  { geneTranscript: "NM_001487.4", parsimonySitesP: "0.18082788671024", constantSitesP: "0.673202614379085", distinctSitesP: "0.30718954248366", brLenTotal: "0.6566" },
  { geneTranscript: "NM_080916.3", parsimonySitesP: "0.208182912154031", constantSitesP: "0.647412755716005", distinctSitesP: "0.34777376654633", brLenTotal: "0.6407" },
  { geneTranscript: "NM_015328.4", parsimonySitesP: "0.108019639934534", constantSitesP: "0.787234042553191", distinctSitesP: "0.166393889798145", brLenTotal: "0.3591" },
  { geneTranscript: "NM_001004728.2", parsimonySitesP: "0.391534391534392", constantSitesP: "0.386243386243386", distinctSitesP: "0.520634920634921", brLenTotal: "1.7489" },
  { geneTranscript: "NM_182627.3", parsimonySitesP: "0.219739292364991", constantSitesP: "0.628491620111732", distinctSitesP: "0.31098696461825", brLenTotal: "0.6407" },
  { geneTranscript: "NM_004620.4", parsimonySitesP: "0.197956577266922", constantSitesP: "0.633461047254151", distinctSitesP: "0.278416347381865", brLenTotal: "0.8020" },
  { geneTranscript: "NM_003325.4", parsimonySitesP: "0.13241560144215", constantSitesP: "0.769255981645362", distinctSitesP: "0.169452638479187", brLenTotal: "0.5451" },
  { geneTranscript: "NM_198586.3", parsimonySitesP: "0.232067510548523", constantSitesP: "0.630379746835443", distinctSitesP: "0.340084388185654", brLenTotal: "0.8050" },
  { geneTranscript: "NM_006818.4", parsimonySitesP: "0.155555555555556", constantSitesP: "0.692592592592593", distinctSitesP: "0.296296296296296", brLenTotal: "0.4812" },
  { geneTranscript: "NM_004481.5", parsimonySitesP: "0.166958552247519", constantSitesP: "0.741973146526562", distinctSitesP: "0.226503210741389", brLenTotal: "0.6565" },
  { geneTranscript: "NM_006178.4", parsimonySitesP: "0.132616487455197", constantSitesP: "0.756272401433692", distinctSitesP: "0.189516129032258", brLenTotal: "0.5459" },
  { geneTranscript: "NM_000891.3", parsimonySitesP: "0.1935987509758", constantSitesP: "0.737704918032787", distinctSitesP: "0.227946916471507", brLenTotal: "0.7976" },
  { geneTranscript: "NM_004984.4", parsimonySitesP: "0.150193798449612", constantSitesP: "0.773578811369509", distinctSitesP: "0.182170542635659", brLenTotal: "0.6141" },
  { geneTranscript: "NM_182895.5", parsimonySitesP: "0.247113163972286", constantSitesP: "0.620092378752887", distinctSitesP: "0.340261739799846", brLenTotal: "0.9699" },
  { geneTranscript: "NM_001004707.4", parsimonySitesP: "0.512486427795874", constantSitesP: "0.352877307274701", distinctSitesP: "0.625407166123779", brLenTotal: "3.2431" },
  { geneTranscript: "NM_001987.5", parsimonySitesP: "0.103982300884956", constantSitesP: "0.829646017699115", distinctSitesP: "0.153392330383481", brLenTotal: "0.3730" },
  { geneTranscript: "NM_001282112.2", parsimonySitesP: "0.182134570765661", constantSitesP: "0.702242846094354", distinctSitesP: "0.24168600154679", brLenTotal: "0.9412" },
  { geneTranscript: "NM_006298.4", parsimonySitesP: "0.15282583621684", constantSitesP: "0.696078431372549", distinctSitesP: "0.249711649365629", brLenTotal: "0.5845" },
  { geneTranscript: "NM_004184.4", parsimonySitesP: "0.197452229299363", constantSitesP: "0.663128096249115", distinctSitesP: "0.283085633404105", brLenTotal: "0.8464" },
  { geneTranscript: "NM_145206.4", parsimonySitesP: "0.0998463901689708", constantSitesP: "0.80184331797235", distinctSitesP: "0.185867895545315", brLenTotal: "0.3589" },
  { geneTranscript: "NM_001004759.3", parsimonySitesP: "0.324159021406728", constantSitesP: "0.493374108053007", distinctSitesP: "0.460754332313965", brLenTotal: "1.1932" },
  { geneTranscript: "NM_006891.4", parsimonySitesP: "0.260536398467433", constantSitesP: "0.591954022988506", distinctSitesP: "0.385057471264368", brLenTotal: "1.2337" },
  { geneTranscript: "NM_002079.3", parsimonySitesP: "0.215496368038741", constantSitesP: "0.677966101694915", distinctSitesP: "0.284907183212268", brLenTotal: "0.7547" },
  { geneTranscript: "NM_032878.5", parsimonySitesP: "0.242296918767507", constantSitesP: "0.65406162464986", distinctSitesP: "0.327731092436975", brLenTotal: "0.9566" },
  { geneTranscript: "NM_020944.3", parsimonySitesP: "0.188781014023732", constantSitesP: "0.690758719884933", distinctSitesP: "0.243797195253506", brLenTotal: "0.5742" },
  { geneTranscript: "NM_001018050.4", parsimonySitesP: "0.183006535947712", constantSitesP: "0.722222222222222", distinctSitesP: "0.277777777777778", brLenTotal: "0.8126" },
  { geneTranscript: "NM_014607.4", parsimonySitesP: "0.148950131233596", constantSitesP: "0.730314960629921", distinctSitesP: "0.236876640419948", brLenTotal: "0.4891" },
  { geneTranscript: "NM_019858.2", parsimonySitesP: "0.187074829931973", constantSitesP: "0.68140589569161", distinctSitesP: "0.246598639455782", brLenTotal: "0.7574" },
  { geneTranscript: "NM_005428.4", parsimonySitesP: "0.205522682445759", constantSitesP: "0.702958579881657", distinctSitesP: "0.255226824457594", brLenTotal: "0.9025" },
  { geneTranscript: "NM_030636.3", parsimonySitesP: "0.210310486233158", constantSitesP: "0.667838312829525", distinctSitesP: "0.290568248388987", brLenTotal: "0.9277" },
  { geneTranscript: "NM_006147.4", parsimonySitesP: "0.132048536759458", constantSitesP: "0.751605995717345", distinctSitesP: "0.203426124197002", brLenTotal: "0.4960" },
  { geneTranscript: "NM_001104647.3", parsimonySitesP: "0.118971061093248", constantSitesP: "0.770632368703108", distinctSitesP: "0.211146838156484", brLenTotal: "0.4315" },
  { geneTranscript: "NM_138317.3", parsimonySitesP: "0.147943523634131", constantSitesP: "0.768569674647023", distinctSitesP: "0.203192142418662", brLenTotal: "0.6455" },
  { geneTranscript: "NM_005214.5", parsimonySitesP: "0.240657698056801", constantSitesP: "0.600896860986547", distinctSitesP: "0.36322869955157", brLenTotal: "0.8821" },
  { geneTranscript: "NM_031438.4", parsimonySitesP: "0.172438672438672", constantSitesP: "0.702020202020202", distinctSitesP: "0.282828282828283", brLenTotal: "0.5772" },
  { geneTranscript: "NM_001282611.2", parsimonySitesP: "0.141580756013746", constantSitesP: "0.791752577319588", distinctSitesP: "0.19106529209622", brLenTotal: "0.5806" },
  { geneTranscript: "NM_001004434.3", parsimonySitesP: "0.244623655913978", constantSitesP: "0.618279569892473", distinctSitesP: "0.359318996415771", brLenTotal: "0.9739" },
  { geneTranscript: "NM_152464.3", parsimonySitesP: "0.224358974358974", constantSitesP: "0.621794871794872", distinctSitesP: "0.368589743589744", brLenTotal: "0.7465" },
  { geneTranscript: "NM_033446.3", parsimonySitesP: "0.128526645768025", constantSitesP: "0.789968652037618", distinctSitesP: "0.198537095088819", brLenTotal: "0.4666" },
  { geneTranscript: "NM_018094.5", parsimonySitesP: "0.14755838641189", constantSitesP: "0.73036093418259", distinctSitesP: "0.221868365180467", brLenTotal: "0.5220" },
  { geneTranscript: "NM_003315.4", parsimonySitesP: "0.124156545209177", constantSitesP: "0.765856950067476", distinctSitesP: "0.190283400809717", brLenTotal: "0.4142" },
  { geneTranscript: "NM_018071.5", parsimonySitesP: "0.206495501426377", constantSitesP: "0.656572306341892", distinctSitesP: "0.252578450735133", brLenTotal: "0.7152" },
  { geneTranscript: "NM_001002911.4", parsimonySitesP: "0.1671388101983", constantSitesP: "0.738432483474976", distinctSitesP: "0.229461756373938", brLenTotal: "0.5628" },
  { geneTranscript: "NM_021095.4", parsimonySitesP: "0.26246719160105", constantSitesP: "0.597900262467192", distinctSitesP: "0.367979002624672", brLenTotal: "1.0386" },
  { geneTranscript: "NM_001178139.2", parsimonySitesP: "0.132286995515695", constantSitesP: "0.73168908819133", distinctSitesP: "0.210014947683109", brLenTotal: "0.5418" },
  { geneTranscript: "NM_002116.8", parsimonySitesP: "0.330593607305936", constantSitesP: "0.436529680365297", distinctSitesP: "0.530593607305936", brLenTotal: "2.5692" },
  { geneTranscript: "NM_001017992.4", parsimonySitesP: "0.256205673758865", constantSitesP: "0.624113475177305", distinctSitesP: "0.325354609929078", brLenTotal: "1.1923" },
  { geneTranscript: "NM_007373.4", parsimonySitesP: "0.0807560137457045", constantSitesP: "0.836197021764032", distinctSitesP: "0.131729667812142", brLenTotal: "0.2547" },
  { geneTranscript: "NM_002109.6", parsimonySitesP: "0.170923379174853", constantSitesP: "0.713817943680419", distinctSitesP: "0.238375900458415", brLenTotal: "0.5594" },
  { geneTranscript: "NM_199242.3", parsimonySitesP: "0.238532110091743", constantSitesP: "0.634250764525994", distinctSitesP: "0.283180428134557", brLenTotal: "0.9684" },
  { geneTranscript: "NM_004039.3", parsimonySitesP: "0.160275319567355", constantSitesP: "0.725663716814159", distinctSitesP: "0.274336283185841", brLenTotal: "0.6828" },
  { geneTranscript: "NM_001004715.5", parsimonySitesP: "0.563034188034188", constantSitesP: "0.299145299145299", distinctSitesP: "0.701923076923077", brLenTotal: "4.4244" },
  { geneTranscript: "NM_015434.4", parsimonySitesP: "0.14968814968815", constantSitesP: "0.751559251559252", distinctSitesP: "0.194733194733195", brLenTotal: "0.4696" },
  { geneTranscript: "NM_003443.3", parsimonySitesP: "0.17725197177252", constantSitesP: "0.733499377334994", distinctSitesP: "0.222083852220839", brLenTotal: "0.7935" },
  { geneTranscript: "NM_001031698.3", parsimonySitesP: "0.131165919282511", constantSitesP: "0.776905829596413", distinctSitesP: "0.183482810164425", brLenTotal: "0.4454" },
  { geneTranscript: "NM_001005168.3", parsimonySitesP: "0.56975505857295", constantSitesP: "0.292864749733759", distinctSitesP: "0.677316293929712", brLenTotal: "3.8374" },
  { geneTranscript: "NM_001286635.2", parsimonySitesP: "0.245917387127762", constantSitesP: "0.636887608069164", distinctSitesP: "0.337175792507205", brLenTotal: "0.7827" },
  { geneTranscript: "NM_080865.4", parsimonySitesP: "0.251811594202899", constantSitesP: "0.589673913043478", distinctSitesP: "0.361413043478261", brLenTotal: "0.9826" },
  { geneTranscript: "NM_017638.3", parsimonySitesP: "0.149038461538462", constantSitesP: "0.717948717948718", distinctSitesP: "0.232371794871795", brLenTotal: "0.6059" },
  { geneTranscript: "NM_001080497.3", parsimonySitesP: "0.201550387596899", constantSitesP: "0.633997785160576", distinctSitesP: "0.317275747508306", brLenTotal: "0.6639" },
  { geneTranscript: "NM_006782.4", parsimonySitesP: "0.196774193548387", constantSitesP: "0.667741935483871", distinctSitesP: "0.288172043010753", brLenTotal: "0.7309" },
  { geneTranscript: "NM_001039355.3", parsimonySitesP: "0.236523652365237", constantSitesP: "0.666666666666667", distinctSitesP: "0.308030803080308", brLenTotal: "0.9430" },
  { geneTranscript: "NM_001130100.2", parsimonySitesP: "0.182405165456013", constantSitesP: "0.707021791767554", distinctSitesP: "0.23728813559322", brLenTotal: "0.9848" },
  { geneTranscript: "NM_017840.4", parsimonySitesP: "0.219123505976096", constantSitesP: "0.657370517928287", distinctSitesP: "0.329349269588313", brLenTotal: "0.7692" },
  { geneTranscript: "NM_021063.4", parsimonySitesP: "0.216931216931217", constantSitesP: "0.658730158730159", distinctSitesP: "0.322751322751323", brLenTotal: "1.3474" },
  { geneTranscript: "NM_019037.3", parsimonySitesP: "0.2", constantSitesP: "0.672108843537415", distinctSitesP: "0.293877551020408", brLenTotal: "0.8475" },
  { geneTranscript: "NM_001003793.3", parsimonySitesP: "0.0785659801678108", constantSitesP: "0.852784134248665", distinctSitesP: "0.138825324180015", brLenTotal: "0.2665" },
  { geneTranscript: "NM_012317.4", parsimonySitesP: "0.17351598173516", constantSitesP: "0.69634703196347", distinctSitesP: "0.287671232876712", brLenTotal: "0.5621" },
  { geneTranscript: "NM_002796.3", parsimonySitesP: "0.172979797979798", constantSitesP: "0.70959595959596", distinctSitesP: "0.286616161616162", brLenTotal: "0.5676" },
  { geneTranscript: "NM_001100420.2", parsimonySitesP: "0.169472502805836", constantSitesP: "0.67003367003367", distinctSitesP: "0.271604938271605", brLenTotal: "0.5263" },
  { geneTranscript: "NM_001371273.1", parsimonySitesP: "0.196569309225777", constantSitesP: "0.709318497913769", distinctSitesP: "0.257301808066759", brLenTotal: "0.8246" },
  { geneTranscript: "NM_033285.4", parsimonySitesP: "0.125", constantSitesP: "0.755555555555556", distinctSitesP: "0.215277777777778", brLenTotal: "0.4143" },
  { geneTranscript: "NM_032905.5", parsimonySitesP: "0.166251039068994", constantSitesP: "0.716541978387365", distinctSitesP: "0.266001662510391", brLenTotal: "0.6978" },
  { geneTranscript: "NM_017410.3", parsimonySitesP: "0.134343434343434", constantSitesP: "0.753535353535354", distinctSitesP: "0.211111111111111", brLenTotal: "0.5770" },
  { geneTranscript: "NM_017893.4", parsimonySitesP: "0.201660735468565", constantSitesP: "0.644128113879004", distinctSitesP: "0.263345195729537", brLenTotal: "0.8163" },
  { geneTranscript: "NM_021120.4", parsimonySitesP: "0.124031007751938", constantSitesP: "0.800081599347205", distinctSitesP: "0.157078743370053", brLenTotal: "0.3821" },
  { geneTranscript: "NM_001001850.3", parsimonySitesP: "0.204081632653061", constantSitesP: "0.629251700680272", distinctSitesP: "0.344671201814059", brLenTotal: "0.6376" },
  { geneTranscript: "NM_000127.3", parsimonySitesP: "0.0616621983914209", constantSitesP: "0.850312779267203", distinctSitesP: "0.103217158176944", brLenTotal: "0.3020" },
  { geneTranscript: "NM_153708.3", parsimonySitesP: "0.20532319391635", constantSitesP: "0.628643852978454", distinctSitesP: "0.321926489226869", brLenTotal: "0.8844" },
  { geneTranscript: "NM_002487.3", parsimonySitesP: "0.219106957424714", constantSitesP: "0.63343717549325", distinctSitesP: "0.311526479750779", brLenTotal: "0.9568" },
  { geneTranscript: "NM_002801.4", parsimonySitesP: "0.26984126984127", constantSitesP: "0.58974358974359", distinctSitesP: "0.37973137973138", brLenTotal: "0.9173" },
  { geneTranscript: "NM_004165.3", parsimonySitesP: "0.235930735930736", constantSitesP: "0.638528138528139", distinctSitesP: "0.32034632034632", brLenTotal: "1.0216" },
  { geneTranscript: "NM_013381.3", parsimonySitesP: "0.149672591206735", constantSitesP: "0.74555659494855", distinctSitesP: "0.211100717181166", brLenTotal: "0.4913" },
  { geneTranscript: "NM_001021.6", parsimonySitesP: "0.177777777777778", constantSitesP: "0.651851851851852", distinctSitesP: "0.348148148148148", brLenTotal: "0.7239" },
  { geneTranscript: "NM_053006.5", parsimonySitesP: "0.20391061452514", constantSitesP: "0.610800744878957", distinctSitesP: "0.322160148975791", brLenTotal: "0.9715" },
  { geneTranscript: "NM_024301.5", parsimonySitesP: "0.24983164983165", constantSitesP: "0.628956228956229", distinctSitesP: "0.312457912457912", brLenTotal: "1.0483" },
  { geneTranscript: "NM_000516.7", parsimonySitesP: "0.133671742808799", constantSitesP: "0.797800338409475", distinctSitesP: "0.187817258883249", brLenTotal: "0.5045" },
  { geneTranscript: "NM_001010853.3", parsimonySitesP: "0.212538226299694", constantSitesP: "0.623853211009174", distinctSitesP: "0.334862385321101", brLenTotal: "0.7160" },
  { geneTranscript: "NM_004326.4", parsimonySitesP: "0.123655913978495", constantSitesP: "0.768349696119682", distinctSitesP: "0.166666666666667", brLenTotal: "0.4358" },
  { geneTranscript: "NM_016108.4", parsimonySitesP: "0.141456582633053", constantSitesP: "0.774509803921569", distinctSitesP: "0.217086834733894", brLenTotal: "0.4448" },
  { geneTranscript: "NM_207315.4", parsimonySitesP: "0.32293986636971", constantSitesP: "0.51818856718634", distinctSitesP: "0.44988864142539", brLenTotal: "1.1769" },
  { geneTranscript: "NM_001077199.3", parsimonySitesP: "0.104700854700855", constantSitesP: "0.816773504273504", distinctSitesP: "0.162393162393162", brLenTotal: "0.3162" },
  { geneTranscript: "NM_130902.3", parsimonySitesP: "0.432098765432099", constantSitesP: "0.362139917695473", distinctSitesP: "0.641975308641975", brLenTotal: "1.4309" },
  { geneTranscript: "NM_182547.4", parsimonySitesP: "0.205580029368576", constantSitesP: "0.679882525697504", distinctSitesP: "0.28928046989721", brLenTotal: "0.7111" },
  { geneTranscript: "NM_003206.4", parsimonySitesP: "0.134078212290503", constantSitesP: "0.748603351955307", distinctSitesP: "0.217877094972067", brLenTotal: "0.4663" },
  { geneTranscript: "NM_198243.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.792452830188679", distinctSitesP: "0.158280922431866", brLenTotal: "0.6690" },
  { geneTranscript: "NM_020895.5", parsimonySitesP: "0.189226519337017", constantSitesP: "0.711786372007366", distinctSitesP: "0.246777163904236", brLenTotal: "0.9175" },
  { geneTranscript: "NM_021625.5", parsimonySitesP: "0.193647148871029", constantSitesP: "0.686184462303865", distinctSitesP: "0.248373517030233", brLenTotal: "0.9468" },
  { geneTranscript: "NM_002716.5", parsimonySitesP: "0.168607875762618", constantSitesP: "0.701053799223516", distinctSitesP: "0.257903494176373", brLenTotal: "0.6014" },
  { geneTranscript: "NM_033347.2", parsimonySitesP: "0.339847991313789", constantSitesP: "0.441910966340934", distinctSitesP: "0.505971769815418", brLenTotal: "1.2101" },
  { geneTranscript: "NM_015171.4", parsimonySitesP: "0.128296296296296", constantSitesP: "0.783407407407407", distinctSitesP: "0.164444444444444", brLenTotal: "0.4290" },
  { geneTranscript: "NM_153634.3", parsimonySitesP: "0.121749408983452", constantSitesP: "0.797281323877069", distinctSitesP: "0.210401891252955", brLenTotal: "0.4706" },
  { geneTranscript: "NM_001033081.3", parsimonySitesP: "0.196886446886447", constantSitesP: "0.588827838827839", distinctSitesP: "0.357142857142857", brLenTotal: "0.7861" },
  { geneTranscript: "NM_006423.3", parsimonySitesP: "0.176576576576577", constantSitesP: "0.724324324324324", distinctSitesP: "0.288288288288288", brLenTotal: "0.6184" },
  { geneTranscript: "NM_017780.4", parsimonySitesP: "0.144477811144478", constantSitesP: "0.772661550439328", distinctSitesP: "0.161828495161828", brLenTotal: "0.5365" },
  { geneTranscript: "NM_181552.4", parsimonySitesP: "0.173643410852713", constantSitesP: "0.733111849390919", distinctSitesP: "0.230121816168328", brLenTotal: "0.7530" },
  { geneTranscript: "NM_152477.5", parsimonySitesP: "0.450233800935204", constantSitesP: "0.42685370741483", distinctSitesP: "0.527722110888444", brLenTotal: "2.1050" },
  { geneTranscript: "NM_000407.5", parsimonySitesP: "0.3042071197411", constantSitesP: "0.514563106796117", distinctSitesP: "0.449838187702265", brLenTotal: "1.2007" },
  { geneTranscript: "NM_013403.3", parsimonySitesP: "0.177069499778663", constantSitesP: "0.728640991589199", distinctSitesP: "0.242585214696768", brLenTotal: "0.8131" },
  { geneTranscript: "NM_001353655.3", parsimonySitesP: "0.198148148148148", constantSitesP: "0.661728395061728", distinctSitesP: "0.297530864197531", brLenTotal: "0.8455" },
  { geneTranscript: "NM_203446.3", parsimonySitesP: "0.130180523773201", constantSitesP: "0.775743707093822", distinctSitesP: "0.174167302313755", brLenTotal: "0.4362" },
  { geneTranscript: "NM_001316897.2", parsimonySitesP: "0.274774774774775", constantSitesP: "0.599099099099099", distinctSitesP: "0.427927927927928", brLenTotal: "1.0053" },
  { geneTranscript: "NM_032776.3", parsimonySitesP: "0.140288713910761", constantSitesP: "0.741601049868766", distinctSitesP: "0.169553805774278", brLenTotal: "0.4356" },
  { geneTranscript: "NM_017857.4", parsimonySitesP: "0.262013151239251", constantSitesP: "0.583206879109762", distinctSitesP: "0.355589276681841", brLenTotal: "1.0516" },
  { geneTranscript: "NM_019073.4", parsimonySitesP: "0.167349726775956", constantSitesP: "0.705601092896175", distinctSitesP: "0.275273224043716", brLenTotal: "0.5229" },
  { geneTranscript: "NM_033224.5", parsimonySitesP: "0.146367521367521", constantSitesP: "0.721153846153846", distinctSitesP: "0.226495726495726", brLenTotal: "0.6319" },
  { geneTranscript: "NM_172231.4", parsimonySitesP: "0.20671834625323", constantSitesP: "0.676485788113695", distinctSitesP: "0.28062015503876", brLenTotal: "0.9184" },
  { geneTranscript: "NM_024558.3", parsimonySitesP: "0.15429403202329", constantSitesP: "0.7117903930131", distinctSitesP: "0.289665211062591", brLenTotal: "0.5633" },
  { geneTranscript: "NM_022720.7", parsimonySitesP: "0.138852953859422", constantSitesP: "0.759379042690815", distinctSitesP: "0.192324277705908", brLenTotal: "0.5936" },
  { geneTranscript: "NM_020924.4", parsimonySitesP: "0.0438397581254724", constantSitesP: "0.910808767951625", distinctSitesP: "0.0854119425547997", brLenTotal: "0.1237" },
  { geneTranscript: "NM_144705.4", parsimonySitesP: "0.363984674329502", constantSitesP: "0.438314176245211", distinctSitesP: "0.51264367816092", brLenTotal: "1.5623" },
  { geneTranscript: "NM_021096.4", parsimonySitesP: "0.221922327185485", constantSitesP: "0.658419553156395", distinctSitesP: "0.265707002549108", brLenTotal: "1.0738" },
  { geneTranscript: "NM_001005490.2", parsimonySitesP: "0.52457264957265", constantSitesP: "0.337606837606838", distinctSitesP: "0.636752136752137", brLenTotal: "3.1480" },
  { geneTranscript: "NM_004387.4", parsimonySitesP: "0.202674897119342", constantSitesP: "0.685185185185185", distinctSitesP: "0.29320987654321", brLenTotal: "0.7449" },
  { geneTranscript: "NM_015176.4", parsimonySitesP: "0.128623188405797", constantSitesP: "0.768115942028986", distinctSitesP: "0.21195652173913", brLenTotal: "0.3951" },
  { geneTranscript: "NM_032515.5", parsimonySitesP: "0.223270440251572", constantSitesP: "0.663522012578616", distinctSitesP: "0.308176100628931", brLenTotal: "1.1755" },
  { geneTranscript: "NM_177400.3", parsimonySitesP: "0.174488567990373", constantSitesP: "0.702767749699158", distinctSitesP: "0.258724428399519", brLenTotal: "0.6343" },
  { geneTranscript: "NM_018273.4", parsimonySitesP: "0.22076978939724", constantSitesP: "0.649237472766885", distinctSitesP: "0.314451706608569", brLenTotal: "0.8224" },
  { geneTranscript: "NM_002341.2", parsimonySitesP: "0.241803278688525", constantSitesP: "0.584699453551913", distinctSitesP: "0.37431693989071", brLenTotal: "0.8658" },
  { geneTranscript: "NM_018923.3", parsimonySitesP: "0.472610096670247", constantSitesP: "0.392409595417114", distinctSitesP: "0.593626924453992", brLenTotal: "4.2001" },
  { geneTranscript: "NM_170679.3", parsimonySitesP: "0.0715746421267894", constantSitesP: "0.775051124744376", distinctSitesP: "0.204498977505112", brLenTotal: "0.3072" },
  { geneTranscript: "NM_004229.4", parsimonySitesP: "0.11118752865658", constantSitesP: "0.789316827143512", distinctSitesP: "0.146950939935809", brLenTotal: "0.3804" },
  { geneTranscript: "NM_005953.5", parsimonySitesP: "0.191256830601093", constantSitesP: "0.704918032786885", distinctSitesP: "0.33879781420765", brLenTotal: "0.7855" },
  { geneTranscript: "NM_000513.2", parsimonySitesP: "0.208791208791209", constantSitesP: "0.702380952380952", distinctSitesP: "0.266483516483516", brLenTotal: "0.9150" },
  { geneTranscript: "NM_004831.5", parsimonySitesP: "0.208888888888889", constantSitesP: "0.677222222222222", distinctSitesP: "0.261111111111111", brLenTotal: "1.0048" },
  { geneTranscript: "NM_001258248.2", parsimonySitesP: "0.156914893617021", constantSitesP: "0.723404255319149", distinctSitesP: "0.226063829787234", brLenTotal: "0.5799" },
  { geneTranscript: "NM_003922.4", parsimonySitesP: "0.120139888911747", constantSitesP: "0.786326544606734", distinctSitesP: "0.12281423575396", brLenTotal: "0.4279" },
  { geneTranscript: "NM_031467.3", parsimonySitesP: "0.224191866527633", constantSitesP: "0.614529023288147", distinctSitesP: "0.306916927354884", brLenTotal: "0.7487" },
  { geneTranscript: "NM_001367977.2", parsimonySitesP: "0.182555123216602", constantSitesP: "0.688391699092088", distinctSitesP: "0.245460440985733", brLenTotal: "0.7443" },
  { geneTranscript: "NM_016282.4", parsimonySitesP: "0.202643171806167", constantSitesP: "0.606461086637298", distinctSitesP: "0.36417033773862", brLenTotal: "0.7420" },
  { geneTranscript: "NM_001009.4", parsimonySitesP: "0.19281045751634", constantSitesP: "0.709150326797386", distinctSitesP: "0.287581699346405", brLenTotal: "0.7062" },
  { geneTranscript: "NM_002460.4", parsimonySitesP: "0.182557280118256", constantSitesP: "0.726533628972653", distinctSitesP: "0.254988913525499", brLenTotal: "0.8994" },
  { geneTranscript: "NM_002631.4", parsimonySitesP: "0.1959972394755", constantSitesP: "0.668737060041408", distinctSitesP: "0.307108350586611", brLenTotal: "0.8584" },
  { geneTranscript: "NM_023935.3", parsimonySitesP: "0.214437367303609", constantSitesP: "0.646496815286624", distinctSitesP: "0.315286624203822", brLenTotal: "0.7858" },
  { geneTranscript: "NM_019884.3", parsimonySitesP: "0.133885438233264", constantSitesP: "0.791580400276052", distinctSitesP: "0.173222912353347", brLenTotal: "0.4141" },
  { geneTranscript: "NM_005293.3", parsimonySitesP: "0.262569832402235", constantSitesP: "0.602420856610801", distinctSitesP: "0.373370577281192", brLenTotal: "1.4229" },
  { geneTranscript: "NM_017739.4", parsimonySitesP: "0.146464646464646", constantSitesP: "0.753535353535354", distinctSitesP: "0.196969696969697", brLenTotal: "0.5192" },
  { geneTranscript: "NM_004603.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.751157407407407", distinctSitesP: "0.230324074074074", brLenTotal: "0.6600" },
  { geneTranscript: "NM_024707.3", parsimonySitesP: "0.244274809160305", constantSitesP: "0.582697201017812", distinctSitesP: "0.404580152671756", brLenTotal: "0.9554" },
  { geneTranscript: "NM_015076.5", parsimonySitesP: "0.130810092961487", constantSitesP: "0.796812749003984", distinctSitesP: "0.171314741035857", brLenTotal: "0.4911" },
  { geneTranscript: "NM_000171.4", parsimonySitesP: "0.138084632516704", constantSitesP: "0.766146993318486", distinctSitesP: "0.203414996288048", brLenTotal: "0.5019" },
  { geneTranscript: "NM_005221.6", parsimonySitesP: "0.108419838523645", constantSitesP: "0.786620530565167", distinctSitesP: "0.167243367935409", brLenTotal: "0.3732" },
  { geneTranscript: "NM_025114.4", parsimonySitesP: "0.162700013446282", constantSitesP: "0.705929810407422", distinctSitesP: "0.245529111200753", brLenTotal: "0.5323" },
  { geneTranscript: "NM_001024845.3", parsimonySitesP: "0.146392838335966", constantSitesP: "0.750394944707741", distinctSitesP: "0.197998946814113", brLenTotal: "0.6644" },
  { geneTranscript: "NM_001114600.3", parsimonySitesP: "0.0745614035087719", constantSitesP: "0.835526315789474", distinctSitesP: "0.166666666666667", brLenTotal: "0.4209" },
  { geneTranscript: "NM_017699.3", parsimonySitesP: "0.180975413139863", constantSitesP: "0.691656590084643", distinctSitesP: "0.271664651350262", brLenTotal: "0.7197" },
  { geneTranscript: "NM_001377960.1", parsimonySitesP: "0.197469197469197", constantSitesP: "0.65001665001665", distinctSitesP: "0.273060273060273", brLenTotal: "0.6921" },
  { geneTranscript: "NM_005886.3", parsimonySitesP: "0.181679389312977", constantSitesP: "0.714503816793893", distinctSitesP: "0.249363867684478", brLenTotal: "0.8579" },
  { geneTranscript: "NM_000971.4", parsimonySitesP: "0.151881720430108", constantSitesP: "0.689516129032258", distinctSitesP: "0.276881720430108", brLenTotal: "0.6420" },
  { geneTranscript: "NM_014110.5", parsimonySitesP: "0.11965811965812", constantSitesP: "0.720797720797721", distinctSitesP: "0.200379867046534", brLenTotal: "0.5918" },
  { geneTranscript: "NM_001085411.3", parsimonySitesP: "0.137254901960784", constantSitesP: "0.771493212669683", distinctSitesP: "0.223227752639517", brLenTotal: "0.4120" },
  { geneTranscript: "NM_018840.5", parsimonySitesP: "0.103359173126615", constantSitesP: "0.775193798449612", distinctSitesP: "0.227390180878553", brLenTotal: "0.3667" },
  { geneTranscript: "NM_002546.4", parsimonySitesP: "0.203657522859518", constantSitesP: "0.643391521197007", distinctSitesP: "0.298420615128845", brLenTotal: "0.8843" },
  { geneTranscript: "NM_001367873.1", parsimonySitesP: "0.0764895330112721", constantSitesP: "0.859500805152979", distinctSitesP: "0.118357487922705", brLenTotal: "0.2904" },
  { geneTranscript: "NM_002043.5", parsimonySitesP: "0.212903225806452", constantSitesP: "0.67168458781362", distinctSitesP: "0.302508960573477", brLenTotal: "0.8501" },
  { geneTranscript: "NM_138391.6", parsimonySitesP: "0.165780141843972", constantSitesP: "0.648936170212766", distinctSitesP: "0.312943262411348", brLenTotal: "0.6399" },
  { geneTranscript: "NM_020992.4", parsimonySitesP: "0.19047619047619", constantSitesP: "0.652482269503546", distinctSitesP: "0.32016210739615", brLenTotal: "0.7944" },
  { geneTranscript: "NM_005412.6", parsimonySitesP: "0.173280423280423", constantSitesP: "0.712301587301587", distinctSitesP: "0.241402116402116", brLenTotal: "0.5904" },
  { geneTranscript: "NM_001388492.1", parsimonySitesP: "0.20337364735837", constantSitesP: "0.68894546997666", distinctSitesP: "0.246339910884787", brLenTotal: "0.7668" },
  { geneTranscript: "NM_020860.4", parsimonySitesP: "0.173369079535299", constantSitesP: "0.714030384271671", distinctSitesP: "0.237265415549598", brLenTotal: "0.7019" },
  { geneTranscript: "NM_016010.3", parsimonySitesP: "0.156923076923077", constantSitesP: "0.750769230769231", distinctSitesP: "0.245128205128205", brLenTotal: "0.4556" },
  { geneTranscript: "NM_207117.4", parsimonySitesP: "0.268398268398268", constantSitesP: "0.613636363636364", distinctSitesP: "0.38961038961039", brLenTotal: "1.2944" },
  { geneTranscript: "NM_016021.3", parsimonySitesP: "0.143605870020964", constantSitesP: "0.728511530398323", distinctSitesP: "0.225366876310273", brLenTotal: "0.5019" },
  { geneTranscript: "NM_002594.5", parsimonySitesP: "0.153082549634274", constantSitesP: "0.755485893416928", distinctSitesP: "0.214211076280042", brLenTotal: "0.6918" },
  { geneTranscript: "NM_015483.3", parsimonySitesP: "0.102728731942215", constantSitesP: "0.811663991439272", distinctSitesP: "0.155163188871054", brLenTotal: "0.3676" },
  { geneTranscript: "NM_201269.3", parsimonySitesP: "0.132378799296659", constantSitesP: "0.758352172820899", distinctSitesP: "0.175081637779452", brLenTotal: "0.4179" },
  { geneTranscript: "NM_006556.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.640625", distinctSitesP: "0.317708333333333", brLenTotal: "1.0033" },
  { geneTranscript: "NM_004464.4", parsimonySitesP: "0.256218905472637", constantSitesP: "0.623134328358209", distinctSitesP: "0.361940298507463", brLenTotal: "0.9509" },
  { geneTranscript: "NM_001278064.2", parsimonySitesP: "0.159966499162479", constantSitesP: "0.749581239530988", distinctSitesP: "0.199050809603573", brLenTotal: "0.5651" },
  { geneTranscript: "NM_000979.4", parsimonySitesP: "0.189716312056738", constantSitesP: "0.675531914893617", distinctSitesP: "0.315602836879433", brLenTotal: "0.7629" },
  { geneTranscript: "NM_182487.4", parsimonySitesP: "0.25920245398773", constantSitesP: "0.621676891615542", distinctSitesP: "0.328732106339468", brLenTotal: "1.1675" },
  { geneTranscript: "NM_031426.4", parsimonySitesP: "0.117777777777778", constantSitesP: "0.795555555555556", distinctSitesP: "0.206666666666667", brLenTotal: "0.4070" },
  { geneTranscript: "NM_004476.3", parsimonySitesP: "0.206222222222222", constantSitesP: "0.649777777777778", distinctSitesP: "0.319555555555556", brLenTotal: "0.7354" },
  { geneTranscript: "NM_001039152.3", parsimonySitesP: "0.190789473684211", constantSitesP: "0.673245614035088", distinctSitesP: "0.328947368421053", brLenTotal: "0.5882" },
  { geneTranscript: "NM_001079872.2", parsimonySitesP: "0.0949720670391061", constantSitesP: "0.820856610800745", distinctSitesP: "0.13854748603352", brLenTotal: "0.2928" },
  { geneTranscript: "NM_014647.4", parsimonySitesP: "0.167814772292384", constantSitesP: "0.719479525449675", distinctSitesP: "0.212973593570608", brLenTotal: "0.5781" },
  { geneTranscript: "NM_024580.6", parsimonySitesP: "0.192261904761905", constantSitesP: "0.675", distinctSitesP: "0.277380952380952", brLenTotal: "0.7198" },
  { geneTranscript: "NM_025208.5", parsimonySitesP: "0.204504504504505", constantSitesP: "0.678378378378378", distinctSitesP: "0.291891891891892", brLenTotal: "0.6716" },
  { geneTranscript: "NM_022917.5", parsimonySitesP: "0.235311227457824", constantSitesP: "0.615474112856312", distinctSitesP: "0.283595113438045", brLenTotal: "0.8072" },
  { geneTranscript: "NM_001286611.2", parsimonySitesP: "0.0925460636515913", constantSitesP: "0.814489112227806", distinctSitesP: "0.148241206030151", brLenTotal: "0.3112" },
  { geneTranscript: "NM_006361.6", parsimonySitesP: "0.183098591549296", constantSitesP: "0.71830985915493", distinctSitesP: "0.26056338028169", brLenTotal: "0.6305" },
  { geneTranscript: "NM_001102386.3", parsimonySitesP: "0.174199623352166", constantSitesP: "0.724105461393597", distinctSitesP: "0.253295668549906", brLenTotal: "0.6457" },
  { geneTranscript: "NM_144613.5", parsimonySitesP: "0.303030303030303", constantSitesP: "0.549242424242424", distinctSitesP: "0.465909090909091", brLenTotal: "0.9545" },
  { geneTranscript: "NM_006927.4", parsimonySitesP: "0.162857142857143", constantSitesP: "0.73047619047619", distinctSitesP: "0.235238095238095", brLenTotal: "0.6463" },
  { geneTranscript: "NM_000698.5", parsimonySitesP: "0.185459940652819", constantSitesP: "0.716617210682493", distinctSitesP: "0.242334322453017", brLenTotal: "0.7202" },
  { geneTranscript: "NM_002139.4", parsimonySitesP: "0.132992327365729", constantSitesP: "0.631713554987212", distinctSitesP: "0.293265132139812", brLenTotal: "0.6967" },
  { geneTranscript: "NM_001315494.2", parsimonySitesP: "0.242063492063492", constantSitesP: "0.53968253968254", distinctSitesP: "0.46031746031746", brLenTotal: "0.9529" },
  { geneTranscript: "NM_004527.4", parsimonySitesP: "0.198162729658793", constantSitesP: "0.664041994750656", distinctSitesP: "0.31496062992126", brLenTotal: "0.8861" },
  { geneTranscript: "NM_170610.3", parsimonySitesP: "0.398950131233596", constantSitesP: "0.467191601049869", distinctSitesP: "0.548556430446194", brLenTotal: "2.7627" },
  { geneTranscript: "NM_001242868.2", parsimonySitesP: "0.153107344632768", constantSitesP: "0.732768361581921", distinctSitesP: "0.258757062146893", brLenTotal: "0.5772" },
  { geneTranscript: "NM_001184.4", parsimonySitesP: "0.147251638930913", constantSitesP: "0.735249621785174", distinctSitesP: "0.188350983358548", brLenTotal: "0.4705" },
  { geneTranscript: "NM_178150.3", parsimonySitesP: "0.251837647810802", constantSitesP: "0.631511665068712", distinctSitesP: "0.347075743048897", brLenTotal: "1.1059" },
  { geneTranscript: "NM_002581.5", parsimonySitesP: "0.18459332104077", constantSitesP: "0.713788158164311", distinctSitesP: "0.23540258143823", brLenTotal: "0.7083" },
  { geneTranscript: "NM_001281503.2", parsimonySitesP: "0.100574712643678", constantSitesP: "0.811302681992337", distinctSitesP: "0.147988505747126", brLenTotal: "0.3091" },
  { geneTranscript: "NM_014817.4", parsimonySitesP: "0.232223592272914", constantSitesP: "0.60583641594739", distinctSitesP: "0.308672420879573", brLenTotal: "0.9038" },
  { geneTranscript: "NM_001384749.1", parsimonySitesP: "0.243619489559165", constantSitesP: "0.606341840680588", distinctSitesP: "0.306264501160093", brLenTotal: "1.2073" },
  { geneTranscript: "NM_001168241.2", parsimonySitesP: "0.202898550724638", constantSitesP: "0.675057208237986", distinctSitesP: "0.28604118993135", brLenTotal: "0.7659" },
  { geneTranscript: "NM_024529.5", parsimonySitesP: "0.124921531701193", constantSitesP: "0.785938480853735", distinctSitesP: "0.193973634651601", brLenTotal: "0.4675" },
  { geneTranscript: "NM_003290.3", parsimonySitesP: "0.201612903225806", constantSitesP: "0.706989247311828", distinctSitesP: "0.306451612903226", brLenTotal: "0.9521" },
  { geneTranscript: "NM_024585.4", parsimonySitesP: "0.259259259259259", constantSitesP: "0.634680134680135", distinctSitesP: "0.353535353535354", brLenTotal: "0.9905" },
  { geneTranscript: "NM_152367.3", parsimonySitesP: "0.271639042357274", constantSitesP: "0.576427255985267", distinctSitesP: "0.406077348066298", brLenTotal: "1.1196" },
  { geneTranscript: "NM_012210.4", parsimonySitesP: "0.154670750382848", constantSitesP: "0.698315467075038", distinctSitesP: "0.204185809086269", brLenTotal: "0.5866" },
  { geneTranscript: "NM_014423.4", parsimonySitesP: "0.146460303811981", constantSitesP: "0.746059042705646", distinctSitesP: "0.194325021496131", brLenTotal: "0.4705" },
  { geneTranscript: "NM_017550.3", parsimonySitesP: "0.271559633027523", constantSitesP: "0.592048929663609", distinctSitesP: "0.381039755351682", brLenTotal: "1.2094" },
  { geneTranscript: "NM_000702.4", parsimonySitesP: "0.177777777777778", constantSitesP: "0.735947712418301", distinctSitesP: "0.219281045751634", brLenTotal: "1.0057" },
  { geneTranscript: "NM_017551.3", parsimonySitesP: "0.144697720515362", constantSitesP: "0.77205153617443", distinctSitesP: "0.181367690782953", brLenTotal: "0.7323" },
  { geneTranscript: "NM_138431.3", parsimonySitesP: "0.336569579288026", constantSitesP: "0.511326860841424", distinctSitesP: "0.460355987055016", brLenTotal: "1.3957" },
  { geneTranscript: "NM_032016.4", parsimonySitesP: "0.12962962962963", constantSitesP: "0.767806267806268", distinctSitesP: "0.245014245014245", brLenTotal: "0.5062" },
  { geneTranscript: "NM_001258392.3", parsimonySitesP: "0.189069423929099", constantSitesP: "0.675036927621861", distinctSitesP: "0.248645987198424", brLenTotal: "0.6782" },
  { geneTranscript: "NM_001008272.2", parsimonySitesP: "0.130653266331658", constantSitesP: "0.8107202680067", distinctSitesP: "0.1892797319933", brLenTotal: "0.3886" },
  { geneTranscript: "NM_015569.5", parsimonySitesP: "0.159134801081499", constantSitesP: "0.757049053688683", distinctSitesP: "0.21591348010815", brLenTotal: "0.6552" },
  { geneTranscript: "NM_022474.4", parsimonySitesP: "0.11358024691358", constantSitesP: "0.792592592592593", distinctSitesP: "0.173827160493827", brLenTotal: "0.3877" },
  { geneTranscript: "NM_001664.4", parsimonySitesP: "0.170984455958549", constantSitesP: "0.670120898100173", distinctSitesP: "0.303972366148532", brLenTotal: "0.6937" },
  { geneTranscript: "NM_003716.4", parsimonySitesP: "0.14609509731461", constantSitesP: "0.763242177876324", distinctSitesP: "0.191180093619118", brLenTotal: "0.5723" },
  { geneTranscript: "NM_004747.4", parsimonySitesP: "0.187597707139135", constantSitesP: "0.703317700191072", distinctSitesP: "0.214000347403161", brLenTotal: "0.7781" },
  { geneTranscript: "NM_001810.6", parsimonySitesP: "0.181969949916528", constantSitesP: "0.722871452420701", distinctSitesP: "0.233722871452421", brLenTotal: "0.8242" },
  { geneTranscript: "NM_001367799.1", parsimonySitesP: "0.134133042529989", constantSitesP: "0.770810614322065", distinctSitesP: "0.152490003635042", brLenTotal: "0.4580" },
  { geneTranscript: "NM_152401.3", parsimonySitesP: "0.134163208852006", constantSitesP: "0.730290456431535", distinctSitesP: "0.255878284923928", brLenTotal: "0.4110" },
  { geneTranscript: "NM_001166.5", parsimonySitesP: "0.195253505933118", constantSitesP: "0.647249190938511", distinctSitesP: "0.310140237324703", brLenTotal: "0.7617" },
  { geneTranscript: "NM_001005209.3", parsimonySitesP: "0.132407407407407", constantSitesP: "0.764814814814815", distinctSitesP: "0.212037037037037", brLenTotal: "0.5363" },
  { geneTranscript: "NM_001286134.2", parsimonySitesP: "0.25549278091651", constantSitesP: "0.610797237915882", distinctSitesP: "0.35969868173258", brLenTotal: "1.0453" },
  { geneTranscript: "NM_001346194.2", parsimonySitesP: "0.25024727992087", constantSitesP: "0.624134520276954", distinctSitesP: "0.374876360039565", brLenTotal: "0.8895" },
  { geneTranscript: "NM_015466.4", parsimonySitesP: "0.215770171149144", constantSitesP: "0.655052974735126", distinctSitesP: "0.277098614506927", brLenTotal: "0.8882" },
  { geneTranscript: "NM_012192.4", parsimonySitesP: "0.155339805825243", constantSitesP: "0.702265372168285", distinctSitesP: "0.330097087378641", brLenTotal: "0.4981" },
  { geneTranscript: "NM_145080.4", parsimonySitesP: "0.238095238095238", constantSitesP: "0.657894736842105", distinctSitesP: "0.337092731829574", brLenTotal: "0.9279" },
  { geneTranscript: "NM_015627.3", parsimonySitesP: "0.204545454545455", constantSitesP: "0.674242424242424", distinctSitesP: "0.310606060606061", brLenTotal: "0.7664" },
  { geneTranscript: "NM_032287.3", parsimonySitesP: "0.217573221757322", constantSitesP: "0.677824267782427", distinctSitesP: "0.316596931659693", brLenTotal: "1.0020" },
  { geneTranscript: "NM_020933.5", parsimonySitesP: "0.237535014005602", constantSitesP: "0.630252100840336", distinctSitesP: "0.335574229691877", brLenTotal: "1.0167" },
  { geneTranscript: "NM_001167675.2", parsimonySitesP: "0.127887788778878", constantSitesP: "0.785478547854785", distinctSitesP: "0.197194719471947", brLenTotal: "0.5005" },
  { geneTranscript: "NM_033421.4", parsimonySitesP: "0.198391420911528", constantSitesP: "0.663092046470063", distinctSitesP: "0.292225201072386", brLenTotal: "0.7806" },
  { geneTranscript: "NM_033118.4", parsimonySitesP: "0.206935123042506", constantSitesP: "0.649328859060403", distinctSitesP: "0.298098434004474", brLenTotal: "0.8391" },
  { geneTranscript: "NM_014952.5", parsimonySitesP: "0.18974358974359", constantSitesP: "0.691880341880342", distinctSitesP: "0.247008547008547", brLenTotal: "0.6707" },
  { geneTranscript: "NM_001165877.1", parsimonySitesP: "0.35", constantSitesP: "0.366666666666667", distinctSitesP: "0.673333333333333", brLenTotal: "1.8473" },
  { geneTranscript: "NM_001173523.2", parsimonySitesP: "0.131474103585657", constantSitesP: "0.784329349269588", distinctSitesP: "0.181938911022576", brLenTotal: "0.4952" },
  { geneTranscript: "NM_001079843.3", parsimonySitesP: "0.186090581769945", constantSitesP: "0.715747583854463", distinctSitesP: "0.231191965131704", brLenTotal: "0.8681" },
  { geneTranscript: "NM_025054.5", parsimonySitesP: "0.115111838516094", constantSitesP: "0.800872885979269", distinctSitesP: "0.147299509001637", brLenTotal: "0.3253" },
  { geneTranscript: "NM_152760.3", parsimonySitesP: "0.173697270471464", constantSitesP: "0.717948717948718", distinctSitesP: "0.251447477253929", brLenTotal: "0.6720" },
  { geneTranscript: "NM_145201.6", parsimonySitesP: "0.267657992565056", constantSitesP: "0.583023543990087", distinctSitesP: "0.365551425030979", brLenTotal: "1.2162" },
  { geneTranscript: "NM_001322799.2", parsimonySitesP: "0.195183776932826", constantSitesP: "0.689480354879594", distinctSitesP: "0.276299112801014", brLenTotal: "0.7469" },
  { geneTranscript: "NM_001004730.1", parsimonySitesP: "0.52258064516129", constantSitesP: "0.350537634408602", distinctSitesP: "0.646236559139785", brLenTotal: "2.5035" },
  { geneTranscript: "NM_007148.5", parsimonySitesP: "0.227680929741152", constantSitesP: "0.671949286846276", distinctSitesP: "0.297411516111992", brLenTotal: "0.9384" },
  { geneTranscript: "NM_006198.3", parsimonySitesP: "0.129032258064516", constantSitesP: "0.78494623655914", distinctSitesP: "0.252688172043011", brLenTotal: "0.4857" },
  { geneTranscript: "NM_001329443.2", parsimonySitesP: "0.30239898989899", constantSitesP: "0.550505050505051", distinctSitesP: "0.381944444444444", brLenTotal: "1.4276" },
  { geneTranscript: "NM_020925.4", parsimonySitesP: "0.172161172161172", constantSitesP: "0.755886970172684", distinctSitesP: "0.1996336996337", brLenTotal: "0.6418" },
  { geneTranscript: "NM_024306.5", parsimonySitesP: "0.244623655913978", constantSitesP: "0.646057347670251", distinctSitesP: "0.343189964157706", brLenTotal: "0.8879" },
  { geneTranscript: "NM_001144032.3", parsimonySitesP: "0.284552845528455", constantSitesP: "0.420731707317073", distinctSitesP: "0.520325203252033", brLenTotal: "1.7330" },
  { geneTranscript: "NM_001351695.2", parsimonySitesP: "0.123188405797101", constantSitesP: "0.793199554069119", distinctSitesP: "0.155239687848384", brLenTotal: "0.3709" },
  { geneTranscript: "NM_000839.5", parsimonySitesP: "0.253058103975535", constantSitesP: "0.640290519877676", distinctSitesP: "0.265672782874618", brLenTotal: "1.1079" },
  { geneTranscript: "NM_001271938.2", parsimonySitesP: "0.206561218512009", constantSitesP: "0.693497363796134", distinctSitesP: "0.222964264792033", brLenTotal: "0.9464" },
  { geneTranscript: "NM_001288962.2", parsimonySitesP: "0.215751525235718", constantSitesP: "0.675540765391015", distinctSitesP: "0.268996117581808", brLenTotal: "0.8633" },
  { geneTranscript: "NM_001329998.2", parsimonySitesP: "0.241832266756484", constantSitesP: "0.621870438980577", distinctSitesP: "0.266868754911867", brLenTotal: "0.8299" },
  { geneTranscript: "NM_001371589.1", parsimonySitesP: "0.225163225692606", constantSitesP: "0.64425622022234", distinctSitesP: "0.259925886712546", brLenTotal: "1.0036" },
  { geneTranscript: "NM_001384317.1", parsimonySitesP: "0.198744769874477", constantSitesP: "0.648535564853556", distinctSitesP: "0.25278940027894", brLenTotal: "0.7343" },
  { geneTranscript: "NM_002230.4", parsimonySitesP: "0.178076062639821", constantSitesP: "0.715883668903803", distinctSitesP: "0.231319910514541", brLenTotal: "1.0124" },
  { geneTranscript: "NM_018180.3", parsimonySitesP: "0.179004037685061", constantSitesP: "0.711081202332885", distinctSitesP: "0.255271422162405", brLenTotal: "0.5575" },
  { geneTranscript: "NM_003554.2", parsimonySitesP: "0.515995872033024", constantSitesP: "0.285861713106295", distinctSitesP: "0.69969040247678", brLenTotal: "3.3290" },
  { geneTranscript: "NM_199337.3", parsimonySitesP: "0.210045662100457", constantSitesP: "0.65144596651446", distinctSitesP: "0.30441400304414", brLenTotal: "0.7209" },
  { geneTranscript: "NM_006281.4", parsimonySitesP: "0.154786150712831", constantSitesP: "0.756958587915818", distinctSitesP: "0.207739307535642", brLenTotal: "0.5740" },
  { geneTranscript: "NM_032989.3", parsimonySitesP: "0.251984126984127", constantSitesP: "0.591269841269841", distinctSitesP: "0.388888888888889", brLenTotal: "0.8574" },
  { geneTranscript: "NM_003547.3", parsimonySitesP: "0.374149659863946", constantSitesP: "0.551020408163265", distinctSitesP: "0.438775510204082", brLenTotal: "3.2196" },
  { geneTranscript: "NM_005310.5", parsimonySitesP: "0.206140350877193", constantSitesP: "0.680451127819549", distinctSitesP: "0.272556390977444", brLenTotal: "0.8605" },
  { geneTranscript: "NM_172369.5", parsimonySitesP: "0.291156462585034", constantSitesP: "0.568707482993197", distinctSitesP: "0.414965986394558", brLenTotal: "1.2767" },
  { geneTranscript: "NM_025193.4", parsimonySitesP: "0.224028906955736", constantSitesP: "0.655826558265583", distinctSitesP: "0.305329719963866", brLenTotal: "0.8903" },
  { geneTranscript: "NM_144582.3", parsimonySitesP: "0.0935374149659864", constantSitesP: "0.831632653061224", distinctSitesP: "0.153061224489796", brLenTotal: "0.3370" },
  { geneTranscript: "NM_025061.6", parsimonySitesP: "0.242462311557789", constantSitesP: "0.661641541038526", distinctSitesP: "0.284338358458961", brLenTotal: "1.1205" },
  { geneTranscript: "NM_016062.4", parsimonySitesP: "0.175869120654397", constantSitesP: "0.740286298568507", distinctSitesP: "0.265848670756646", brLenTotal: "0.5814" },
  { geneTranscript: "NM_145244.4", parsimonySitesP: "0.22279792746114", constantSitesP: "0.621761658031088", distinctSitesP: "0.404145077720207", brLenTotal: "0.8222" },
  { geneTranscript: "NM_016009.5", parsimonySitesP: "0.117808219178082", constantSitesP: "0.789954337899543", distinctSitesP: "0.180821917808219", brLenTotal: "0.3508" },
  { geneTranscript: "NM_001364910.1", parsimonySitesP: "0.166051660516605", constantSitesP: "0.698646986469865", distinctSitesP: "0.298892988929889", brLenTotal: "0.4977" },
  { geneTranscript: "NM_001826.3", parsimonySitesP: "0.105485232067511", constantSitesP: "0.708860759493671", distinctSitesP: "0.282700421940928", brLenTotal: "0.4068" },
  { geneTranscript: "NM_014008.5", parsimonySitesP: "0.201488569909623", constantSitesP: "0.669324827219564", distinctSitesP: "0.257309941520468", brLenTotal: "0.6824" },
  { geneTranscript: "NM_000808.4", parsimonySitesP: "0.149728997289973", constantSitesP: "0.733062330623306", distinctSitesP: "0.231029810298103", brLenTotal: "0.5034" },
  { geneTranscript: "NM_019839.5", parsimonySitesP: "0.219739292364991", constantSitesP: "0.64804469273743", distinctSitesP: "0.32122905027933", brLenTotal: "0.8562" },
  { geneTranscript: "NM_005116.6", parsimonySitesP: "0.183076923076923", constantSitesP: "0.737948717948718", distinctSitesP: "0.223076923076923", brLenTotal: "0.9068" },
  { geneTranscript: "NM_052920.2", parsimonySitesP: "0.125333333333333", constantSitesP: "0.803047619047619", distinctSitesP: "0.163047619047619", brLenTotal: "0.6547" },
  { geneTranscript: "NM_001035223.4", parsimonySitesP: "0.285446009389671", constantSitesP: "0.543192488262911", distinctSitesP: "0.423943661971831", brLenTotal: "1.1657" },
  { geneTranscript: "NM_001395421.1", parsimonySitesP: "0.275985663082437", constantSitesP: "0.609318996415771", distinctSitesP: "0.408602150537634", brLenTotal: "0.9819" },
  { geneTranscript: "NM_012137.4", parsimonySitesP: "0.148538011695906", constantSitesP: "0.743859649122807", distinctSitesP: "0.24093567251462", brLenTotal: "0.4922" },
  { geneTranscript: "NM_014628.3", parsimonySitesP: "0.223844282238443", constantSitesP: "0.627737226277372", distinctSitesP: "0.345498783454988", brLenTotal: "0.7058" },
  { geneTranscript: "NM_001184819.2", parsimonySitesP: "0.217640320733104", constantSitesP: "0.615120274914089", distinctSitesP: "0.332760595647194", brLenTotal: "0.7677" },
  { geneTranscript: "NM_198215.4", parsimonySitesP: "0.191452991452991", constantSitesP: "0.672934472934473", distinctSitesP: "0.315669515669516", brLenTotal: "0.6361" },
  { geneTranscript: "NM_000988.5", parsimonySitesP: "0.159313725490196", constantSitesP: "0.669117647058824", distinctSitesP: "0.325980392156863", brLenTotal: "0.6897" },
  { geneTranscript: "NM_030792.8", parsimonySitesP: "0.173002754820937", constantSitesP: "0.702479338842975", distinctSitesP: "0.240771349862259", brLenTotal: "0.7954" },
  { geneTranscript: "NM_001139444.3", parsimonySitesP: "0.162062615101289", constantSitesP: "0.720073664825046", distinctSitesP: "0.267034990791897", brLenTotal: "0.5816" },
  { geneTranscript: "NM_001005279.3", parsimonySitesP: "0.455761316872428", constantSitesP: "0.393004115226337", distinctSitesP: "0.587448559670782", brLenTotal: "1.8688" },
  { geneTranscript: "NM_000164.4", parsimonySitesP: "0.246065808297568", constantSitesP: "0.59585121602289", distinctSitesP: "0.339055793991416", brLenTotal: "0.8492" },
  { geneTranscript: "NM_018426.3", parsimonySitesP: "0.111778846153846", constantSitesP: "0.804487179487179", distinctSitesP: "0.148637820512821", brLenTotal: "0.5106" },
  { geneTranscript: "NM_024755.4", parsimonySitesP: "0.115731785944552", constantSitesP: "0.792714377820761", distinctSitesP: "0.170857511283043", brLenTotal: "0.3793" },
  { geneTranscript: "NM_015480.3", parsimonySitesP: "0.106253794778385", constantSitesP: "0.805707346690953", distinctSitesP: "0.166363084395871", brLenTotal: "0.3405" },
  { geneTranscript: "NM_144685.5", parsimonySitesP: "0.266774891774892", constantSitesP: "0.586038961038961", distinctSitesP: "0.36038961038961", brLenTotal: "1.1370" },
  { geneTranscript: "NM_004792.3", parsimonySitesP: "0.14367816091954", constantSitesP: "0.701149425287356", distinctSitesP: "0.236074270557029", brLenTotal: "0.5044" },
  { geneTranscript: "NM_138966.5", parsimonySitesP: "0.151969981238274", constantSitesP: "0.778611632270169", distinctSitesP: "0.201375859912445", brLenTotal: "0.6167" },
  { geneTranscript: "NM_024016.4", parsimonySitesP: "0.0768175582990398", constantSitesP: "0.843621399176955", distinctSitesP: "0.142661179698217", brLenTotal: "0.2706" },
  { geneTranscript: "NM_004551.3", parsimonySitesP: "0.243686868686869", constantSitesP: "0.609848484848485", distinctSitesP: "0.372474747474747", brLenTotal: "0.7816" },
  { geneTranscript: "NM_203459.4", parsimonySitesP: "0.148173207036536", constantSitesP: "0.741993685160126", distinctSitesP: "0.187640956247181", brLenTotal: "0.5122" },
  { geneTranscript: "NM_005005.3", parsimonySitesP: "0.284916201117318", constantSitesP: "0.527001862197393", distinctSitesP: "0.443202979515829", brLenTotal: "1.0595" },
  { geneTranscript: "NM_001351132.2", parsimonySitesP: "0.149713093375065", constantSitesP: "0.720396452790819", distinctSitesP: "0.223265519040167", brLenTotal: "0.5726" },
  { geneTranscript: "NM_001099658.2", parsimonySitesP: "0.177024482109228", constantSitesP: "0.604048964218456", distinctSitesP: "0.274952919020716", brLenTotal: "0.7776" },
  { geneTranscript: "NM_001197184.3", parsimonySitesP: "0.279279279279279", constantSitesP: "0.566566566566567", distinctSitesP: "0.414414414414414", brLenTotal: "0.8046" },
  { geneTranscript: "NM_007046.4", parsimonySitesP: "0.231955380577428", constantSitesP: "0.601706036745407", distinctSitesP: "0.29002624671916", brLenTotal: "1.0164" },
  { geneTranscript: "NM_001040445.3", parsimonySitesP: "0.193034825870647", constantSitesP: "0.714427860696517", distinctSitesP: "0.27363184079602", brLenTotal: "0.8430" },
  { geneTranscript: "NM_024911.7", parsimonySitesP: "0.168823166974738", constantSitesP: "0.759704251386322", distinctSitesP: "0.213801601971657", brLenTotal: "0.6513" },
  { geneTranscript: "NM_021044.4", parsimonySitesP: "0.181818181818182", constantSitesP: "0.713804713804714", distinctSitesP: "0.25", brLenTotal: "0.6959" },
  { geneTranscript: "NM_002147.4", parsimonySitesP: "0.0892193308550186", constantSitesP: "0.838909541511772", distinctSitesP: "0.154894671623296", brLenTotal: "0.3261" },
  { geneTranscript: "NM_015986.4", parsimonySitesP: "0.150829562594268", constantSitesP: "0.711915535444947", distinctSitesP: "0.243589743589744", brLenTotal: "0.5312" },
  { geneTranscript: "NM_003410.4", parsimonySitesP: "0.170186335403727", constantSitesP: "0.711801242236025", distinctSitesP: "0.227743271221532", brLenTotal: "0.8247" },
  { geneTranscript: "NM_153348.3", parsimonySitesP: "0.261426978818283", constantSitesP: "0.608695652173913", distinctSitesP: "0.375696767001115", brLenTotal: "0.9008" },
  { geneTranscript: "NM_001378414.1", parsimonySitesP: "0.232935414753597", constantSitesP: "0.681052953780227", distinctSitesP: "0.271808999081726", brLenTotal: "1.2446" },
  { geneTranscript: "NM_006288.5", parsimonySitesP: "0.194616977225673", constantSitesP: "0.668737060041408", distinctSitesP: "0.335403726708075", brLenTotal: "0.7991" },
  { geneTranscript: "NM_152879.3", parsimonySitesP: "0.188358045030203", constantSitesP: "0.72103239978034", distinctSitesP: "0.241076331685887", brLenTotal: "0.8384" },
  { geneTranscript: "NM_000916.4", parsimonySitesP: "0.226221079691517", constantSitesP: "0.626392459297344", distinctSitesP: "0.336760925449871", brLenTotal: "1.1126" },
  { geneTranscript: "NM_001314050.5", parsimonySitesP: "0.192678227360308", constantSitesP: "0.737957610789981", distinctSitesP: "0.248554913294798", brLenTotal: "0.9064" },
  { geneTranscript: "NM_006915.3", parsimonySitesP: "0.162857142857143", constantSitesP: "0.724761904761905", distinctSitesP: "0.25047619047619", brLenTotal: "0.5028" },
  { geneTranscript: "NM_006607.3", parsimonySitesP: "0.474694589877836", constantSitesP: "0.246073298429319", distinctSitesP: "0.767888307155323", brLenTotal: "1.9861" },
  { geneTranscript: "NM_007100.4", parsimonySitesP: "0.256038647342995", constantSitesP: "0.618357487922705", distinctSitesP: "0.410628019323671", brLenTotal: "0.6630" },
  { geneTranscript: "NM_007234.5", parsimonySitesP: "0.188172043010753", constantSitesP: "0.709677419354839", distinctSitesP: "0.267025089605735", brLenTotal: "0.5525" },
  { geneTranscript: "NM_005461.5", parsimonySitesP: "0.119711042311662", constantSitesP: "0.804953560371517", distinctSitesP: "0.179566563467492", brLenTotal: "0.4402" },
  { geneTranscript: "NM_006133.3", parsimonySitesP: "0.165706973768394", constantSitesP: "0.753998720409469", distinctSitesP: "0.198656429942418", brLenTotal: "0.8503" },
  { geneTranscript: "NM_001395460.1", parsimonySitesP: "0.208603701033405", constantSitesP: "0.687214611872146", distinctSitesP: "0.227469358327325", brLenTotal: "1.0380" },
  { geneTranscript: "NM_002744.6", parsimonySitesP: "0.179054054054054", constantSitesP: "0.746058558558559", distinctSitesP: "0.25", brLenTotal: "0.8328" },
  { geneTranscript: "NM_000938.3", parsimonySitesP: "0.143100511073254", constantSitesP: "0.777115275411698", distinctSitesP: "0.195911413969336", brLenTotal: "0.5337" },
  { geneTranscript: "NM_001319999.2", parsimonySitesP: "0.15295358649789", constantSitesP: "0.725738396624473", distinctSitesP: "0.234704641350211", brLenTotal: "0.5080" },
  { geneTranscript: "NM_005715.3", parsimonySitesP: "0.118226600985222", constantSitesP: "0.785714285714286", distinctSitesP: "0.197044334975369", brLenTotal: "0.4281" },
  { geneTranscript: "NM_014255.7", parsimonySitesP: "0.108058608058608", constantSitesP: "0.793040293040293", distinctSitesP: "0.188644688644689", brLenTotal: "0.3259" },
  { geneTranscript: "NM_005422.4", parsimonySitesP: "0.175715390564578", constantSitesP: "0.731013147718484", distinctSitesP: "0.205723124516628", brLenTotal: "0.7153" },
  { geneTranscript: "NM_003579.4", parsimonySitesP: "0.162873717090585", constantSitesP: "0.715751896474788", distinctSitesP: "0.222222222222222", brLenTotal: "0.4999" },
  { geneTranscript: "NM_004425.4", parsimonySitesP: "0.266666666666667", constantSitesP: "0.575308641975309", distinctSitesP: "0.391975308641975", brLenTotal: "0.9402" },
  { geneTranscript: "NM_001185149.1", parsimonySitesP: "0.3", constantSitesP: "0.563636363636364", distinctSitesP: "0.431818181818182", brLenTotal: "1.2302" },
  { geneTranscript: "NM_001686.4", parsimonySitesP: "0.151228733459357", constantSitesP: "0.717076244486452", distinctSitesP: "0.238185255198488", brLenTotal: "0.5474" },
  { geneTranscript: "NM_032088.2", parsimonySitesP: "0.446709585121602", constantSitesP: "0.402360515021459", distinctSitesP: "0.547210300429185", brLenTotal: "4.3814" },
  { geneTranscript: "NM_001005224.2", parsimonySitesP: "0.56517094017094", constantSitesP: "0.305555555555556", distinctSitesP: "0.681623931623932", brLenTotal: "3.3366" },
  { geneTranscript: "NM_203344.3", parsimonySitesP: "0.207482993197279", constantSitesP: "0.583333333333333", distinctSitesP: "0.374149659863946", brLenTotal: "0.7937" },
  { geneTranscript: "NM_015276.2", parsimonySitesP: "0.203809523809524", constantSitesP: "0.723174603174603", distinctSitesP: "0.252063492063492", brLenTotal: "0.8931" },
  { geneTranscript: "NM_001387601.1", parsimonySitesP: "0.268070175438596", constantSitesP: "0.589473684210526", distinctSitesP: "0.31859649122807", brLenTotal: "0.9001" },
  { geneTranscript: "NM_001365618.1", parsimonySitesP: "0.153046062407132", constantSitesP: "0.759782070331847", distinctSitesP: "0.190193164933135", brLenTotal: "0.6110" },
  { geneTranscript: "NM_004356.4", parsimonySitesP: "0.18361581920904", constantSitesP: "0.731638418079096", distinctSitesP: "0.258474576271186", brLenTotal: "0.8564" },
  { geneTranscript: "NM_175914.5", parsimonySitesP: "0.168879056047198", constantSitesP: "0.728613569321534", distinctSitesP: "0.239675516224189", brLenTotal: "0.6793" },
  { geneTranscript: "NM_024335.3", parsimonySitesP: "0.298953662182362", constantSitesP: "0.506726457399103", distinctSitesP: "0.426756352765321", brLenTotal: "1.0785" },
  { geneTranscript: "NM_001128635.2", parsimonySitesP: "0.387838112670327", constantSitesP: "0.392719137685581", distinctSitesP: "0.512914378686191", brLenTotal: "1.4353" },
  { geneTranscript: "NM_006280.3", parsimonySitesP: "0.219653179190751", constantSitesP: "0.660886319845857", distinctSitesP: "0.33140655105973", brLenTotal: "0.8298" },
  { geneTranscript: "NM_001384134.1", parsimonySitesP: "0.244047619047619", constantSitesP: "0.648809523809524", distinctSitesP: "0.357142857142857", brLenTotal: "0.8787" },
  { geneTranscript: "NM_018945.4", parsimonySitesP: "0.137777777777778", constantSitesP: "0.781481481481481", distinctSitesP: "0.206666666666667", brLenTotal: "0.4464" },
  { geneTranscript: "NM_001152.5", parsimonySitesP: "0.287472035794183", constantSitesP: "0.562639821029083", distinctSitesP: "0.402684563758389", brLenTotal: "1.4263" },
  { geneTranscript: "NM_003048.6", parsimonySitesP: "0.240147783251232", constantSitesP: "0.65311986863711", distinctSitesP: "0.313628899835796", brLenTotal: "0.8893" },
  { geneTranscript: "NM_016604.4", parsimonySitesP: "0.127011167897028", constantSitesP: "0.786674238122279", distinctSitesP: "0.155971985614234", brLenTotal: "0.3778" },
  { geneTranscript: "NM_014970.4", parsimonySitesP: "0.112794612794613", constantSitesP: "0.789983164983165", distinctSitesP: "0.171296296296296", brLenTotal: "0.3577" },
  { geneTranscript: "NM_014268.4", parsimonySitesP: "0.143730886850153", constantSitesP: "0.768603465851172", distinctSitesP: "0.202854230377166", brLenTotal: "0.4741" },
  { geneTranscript: "NM_001044264.3", parsimonySitesP: "0.15347721822542", constantSitesP: "0.717026378896882", distinctSitesP: "0.26378896882494", brLenTotal: "0.4635" },
  { geneTranscript: "NM_182932.3", parsimonySitesP: "0.134998190372783", constantSitesP: "0.749547593195802", distinctSitesP: "0.177343467245747", brLenTotal: "0.5533" },
  { geneTranscript: "NM_001281789.2", parsimonySitesP: "0.0773333333333333", constantSitesP: "0.864", distinctSitesP: "0.144", brLenTotal: "0.2387" },
  { geneTranscript: "NM_004273.5", parsimonySitesP: "0.202505219206681", constantSitesP: "0.694502435629784", distinctSitesP: "0.276270006958942", brLenTotal: "0.7756" },
  { geneTranscript: "NM_000046.5", parsimonySitesP: "0.222639149468418", constantSitesP: "0.647279549718574", distinctSitesP: "0.327704815509694", brLenTotal: "0.8045" },
  { geneTranscript: "NM_001007523.2", parsimonySitesP: "0.215633423180593", constantSitesP: "0.562443845462713", distinctSitesP: "0.378256963162624", brLenTotal: "1.1861" },
  { geneTranscript: "NM_016224.5", parsimonySitesP: "0.181512605042017", constantSitesP: "0.716526610644258", distinctSitesP: "0.272268907563025", brLenTotal: "0.7118" },
  { geneTranscript: "NM_015964.4", parsimonySitesP: "0.151515151515152", constantSitesP: "0.759469696969697", distinctSitesP: "0.21780303030303", brLenTotal: "0.6794" },
  { geneTranscript: "NM_002278.3", parsimonySitesP: "0.311755952380952", constantSitesP: "0.537946428571429", distinctSitesP: "0.431547619047619", brLenTotal: "1.6167" },
  { geneTranscript: "NM_005589.4", parsimonySitesP: "0.135202492211838", constantSitesP: "0.75202492211838", distinctSitesP: "0.219314641744548", brLenTotal: "0.4408" },
  { geneTranscript: "NM_005336.6", parsimonySitesP: "0.211619348054679", constantSitesP: "0.698738170347003", distinctSitesP: "0.267350157728707", brLenTotal: "0.9863" },
  { geneTranscript: "NM_002749.4", parsimonySitesP: "0.223039215686275", constantSitesP: "0.66421568627451", distinctSitesP: "0.28390522875817", brLenTotal: "0.8339" },
  { geneTranscript: "NM_001034841.4", parsimonySitesP: "0.258566978193146", constantSitesP: "0.600623052959502", distinctSitesP: "0.333956386292835", brLenTotal: "0.9355" },
  { geneTranscript: "NM_001733.7", parsimonySitesP: "0.304018912529551", constantSitesP: "0.577304964539007", distinctSitesP: "0.365957446808511", brLenTotal: "1.1915" },
  { geneTranscript: "NM_005568.5", parsimonySitesP: "0.14696223316913", constantSitesP: "0.768472906403941", distinctSitesP: "0.196223316912972", brLenTotal: "0.4752" },
  { geneTranscript: "NM_015417.5", parsimonySitesP: "0.244350282485876", constantSitesP: "0.637005649717514", distinctSitesP: "0.34180790960452", brLenTotal: "0.7901" },
  { geneTranscript: "NM_025233.7", parsimonySitesP: "0.225768321513002", constantSitesP: "0.618203309692671", distinctSitesP: "0.306737588652482", brLenTotal: "0.7802" },
  { geneTranscript: "NM_001164405.2", parsimonySitesP: "0.375886524822695", constantSitesP: "0.421276595744681", distinctSitesP: "0.574468085106383", brLenTotal: "1.4733" },
  { geneTranscript: "NM_015386.3", parsimonySitesP: "0.163075623151669", constantSitesP: "0.729615547106041", distinctSitesP: "0.216730038022814", brLenTotal: "0.5279" },
  { geneTranscript: "NM_012280.4", parsimonySitesP: "0.221884498480243", constantSitesP: "0.606889564336373", distinctSitesP: "0.331306990881459", brLenTotal: "0.8389" },
  { geneTranscript: "NM_024949.6", parsimonySitesP: "0.210570469798658", constantSitesP: "0.669742729306488", distinctSitesP: "0.302572706935123", brLenTotal: "0.8027" },
  { geneTranscript: "NM_001199862.2", parsimonySitesP: "0.134136546184739", constantSitesP: "0.787951807228916", distinctSitesP: "0.208835341365462", brLenTotal: "0.6628" },
  { geneTranscript: "NM_001244944.2", parsimonySitesP: "0.151020408163265", constantSitesP: "0.762585034013605", distinctSitesP: "0.203401360544218", brLenTotal: "0.4495" },
  { geneTranscript: "NM_001166345.3", parsimonySitesP: "0.158536585365854", constantSitesP: "0.634146341463415", distinctSitesP: "0.333333333333333", brLenTotal: "0.7327" },
  { geneTranscript: "NM_017811.4", parsimonySitesP: "0.0714285714285714", constantSitesP: "0.848739495798319", distinctSitesP: "0.140056022408964", brLenTotal: "0.2556" },
  { geneTranscript: "NM_024090.3", parsimonySitesP: "0.129559748427673", constantSitesP: "0.735849056603774", distinctSitesP: "0.226415094339623", brLenTotal: "0.5662" },
  { geneTranscript: "NM_152341.5", parsimonySitesP: "0.188034188034188", constantSitesP: "0.736263736263736", distinctSitesP: "0.242979242979243", brLenTotal: "0.7281" },
  { geneTranscript: "NM_001641.4", parsimonySitesP: "0.177148846960168", constantSitesP: "0.636268343815514", distinctSitesP: "0.29769392033543", brLenTotal: "0.7289" },
  { geneTranscript: "NM_030802.4", parsimonySitesP: "0.175864606328182", constantSitesP: "0.700515084621045", distinctSitesP: "0.270051508462104", brLenTotal: "0.6655" },
  { geneTranscript: "NM_014716.4", parsimonySitesP: "0.177927927927928", constantSitesP: "0.709459459459459", distinctSitesP: "0.235585585585586", brLenTotal: "0.6420" },
  { geneTranscript: "NM_001099625.2", parsimonySitesP: "0.132420091324201", constantSitesP: "0.762557077625571", distinctSitesP: "0.207762557077626", brLenTotal: "0.4197" },
  { geneTranscript: "NM_000432.4", parsimonySitesP: "0.184738955823293", constantSitesP: "0.72289156626506", distinctSitesP: "0.275100401606426", brLenTotal: "0.7122" },
  { geneTranscript: "NM_021242.6", parsimonySitesP: "0.14936247723133", constantSitesP: "0.737704918032787", distinctSitesP: "0.236794171220401", brLenTotal: "0.4960" },
  { geneTranscript: "NM_001042616.3", parsimonySitesP: "0.206572769953052", constantSitesP: "0.563380281690141", distinctSitesP: "0.380281690140845", brLenTotal: "0.9051" },
  { geneTranscript: "NM_014727.3", parsimonySitesP: "0.185758133824432", constantSitesP: "0.707427869858809", distinctSitesP: "0.219643953345611", brLenTotal: "0.7145" },
  { geneTranscript: "NM_001037172.3", parsimonySitesP: "0.192361111111111", constantSitesP: "0.698611111111111", distinctSitesP: "0.263194444444444", brLenTotal: "0.7276" },
  { geneTranscript: "NM_001069.3", parsimonySitesP: "0.240449438202247", constantSitesP: "0.640449438202247", distinctSitesP: "0.325842696629213", brLenTotal: "2.1926" },
  { geneTranscript: "NM_014873.3", parsimonySitesP: "0.125225225225225", constantSitesP: "0.757657657657658", distinctSitesP: "0.211711711711712", brLenTotal: "0.3966" },
  { geneTranscript: "NM_001393339.1", parsimonySitesP: "0.199391171993912", constantSitesP: "0.622526636225266", distinctSitesP: "0.330289193302892", brLenTotal: "0.6943" },
  { geneTranscript: "NM_001079539.2", parsimonySitesP: "0.183510638297872", constantSitesP: "0.650709219858156", distinctSitesP: "0.299645390070922", brLenTotal: "0.6184" },
  { geneTranscript: "NM_020130.5", parsimonySitesP: "0.185534591194969", constantSitesP: "0.726415094339623", distinctSitesP: "0.279874213836478", brLenTotal: "0.6084" },
  { geneTranscript: "NM_021221.3", parsimonySitesP: "0.306799336650083", constantSitesP: "0.479270315091211", distinctSitesP: "0.469320066334992", brLenTotal: "0.9730" },
  { geneTranscript: "NM_000121.4", parsimonySitesP: "0.295275590551181", constantSitesP: "0.554461942257218", distinctSitesP: "0.366141732283465", brLenTotal: "0.9910" },
  { geneTranscript: "NM_000922.4", parsimonySitesP: "0.189748201438849", constantSitesP: "0.654676258992806", distinctSitesP: "0.300959232613909", brLenTotal: "0.6514" },
  { geneTranscript: "NM_004393.6", parsimonySitesP: "0.20707635009311", constantSitesP: "0.667783985102421", distinctSitesP: "0.252141527001862", brLenTotal: "0.7744" },
  { geneTranscript: "NM_003737.4", parsimonySitesP: "0.191934505761067", constantSitesP: "0.679401657570245", distinctSitesP: "0.193854861532242", brLenTotal: "0.6797" },
  { geneTranscript: "NM_004705.4", parsimonySitesP: "0.282084975908892", constantSitesP: "0.427507665352606", distinctSitesP: "0.530004380201489", brLenTotal: "1.3971" },
  { geneTranscript: "NM_000207.3", parsimonySitesP: "0.312121212121212", constantSitesP: "0.581818181818182", distinctSitesP: "0.43030303030303", brLenTotal: "1.2032" },
  { geneTranscript: "NM_001135629.3", parsimonySitesP: "0.143162393162393", constantSitesP: "0.747863247863248", distinctSitesP: "0.209401709401709", brLenTotal: "0.4780" },
  { geneTranscript: "NM_170675.5", parsimonySitesP: "0.0649895178197065", constantSitesP: "0.86512928022362", distinctSitesP: "0.120195667365479", brLenTotal: "0.2337" },
  { geneTranscript: "NM_032796.4", parsimonySitesP: "0.18655303030303", constantSitesP: "0.705492424242424", distinctSitesP: "0.299242424242424", brLenTotal: "0.6209" },
  { geneTranscript: "NM_178034.4", parsimonySitesP: "0.274246128769356", constantSitesP: "0.539527302363488", distinctSitesP: "0.387123064384678", brLenTotal: "1.1173" },
  { geneTranscript: "NM_021951.3", parsimonySitesP: "0.247542448614835", constantSitesP: "0.628239499553172", distinctSitesP: "0.344057193923146", brLenTotal: "0.9175" },
  { geneTranscript: "NM_007284.4", parsimonySitesP: "0.194842406876791", constantSitesP: "0.713467048710602", distinctSitesP: "0.25214899713467", brLenTotal: "0.8987" },
  { geneTranscript: "NM_004732.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.725247524752475", distinctSitesP: "0.252475247524752", brLenTotal: "0.5579" },
  { geneTranscript: "NM_015143.3", parsimonySitesP: "0.169257340241796", constantSitesP: "0.698618307426598", distinctSitesP: "0.287564766839378", brLenTotal: "0.6645" },
  { geneTranscript: "NM_001190787.3", parsimonySitesP: "0.282251082251082", constantSitesP: "0.538528138528139", distinctSitesP: "0.415584415584416", brLenTotal: "1.0245" },
  { geneTranscript: "NM_016222.4", parsimonySitesP: "0.181672025723473", constantSitesP: "0.733654876741693", distinctSitesP: "0.232047159699893", brLenTotal: "0.7404" },
  { geneTranscript: "NM_032756.4", parsimonySitesP: "0.334231805929919", constantSitesP: "0.519317160826595", distinctSitesP: "0.401617250673854", brLenTotal: "1.1882" },
  { geneTranscript: "NM_003369.4", parsimonySitesP: "0.161182641869337", constantSitesP: "0.714830710538865", distinctSitesP: "0.243681449690033", brLenTotal: "0.5714" },
  { geneTranscript: "NM_017791.3", parsimonySitesP: "0.20532319391635", constantSitesP: "0.680608365019011", distinctSitesP: "0.309252217997465", brLenTotal: "0.7493" },
  { geneTranscript: "NM_020775.5", parsimonySitesP: "0.185587364264561", constantSitesP: "0.687726225732149", distinctSitesP: "0.247778874629812", brLenTotal: "0.7138" },
  { geneTranscript: "NM_001718.6", parsimonySitesP: "0.202079272254711", constantSitesP: "0.673164392462638", distinctSitesP: "0.298895386614685", brLenTotal: "0.8166" },
  { geneTranscript: "NM_002705.5", parsimonySitesP: "0.257782839787396", constantSitesP: "0.593963553530752", distinctSitesP: "0.301822323462415", brLenTotal: "1.0855" },
  { geneTranscript: "NM_014567.5", parsimonySitesP: "0.236781609195402", constantSitesP: "0.61455938697318", distinctSitesP: "0.313409961685824", brLenTotal: "1.2115" },
  { geneTranscript: "NM_032864.4", parsimonySitesP: "0.153846153846154", constantSitesP: "0.689102564102564", distinctSitesP: "0.278846153846154", brLenTotal: "0.6385" },
  { geneTranscript: "NM_001098511.3", parsimonySitesP: "0.0752688172043011", constantSitesP: "0.833333333333333", distinctSitesP: "0.140232974910394", brLenTotal: "0.2559" },
  { geneTranscript: "NM_138779.5", parsimonySitesP: "0.0763582966226138", constantSitesP: "0.819383259911894", distinctSitesP: "0.171806167400881", brLenTotal: "0.2838" },
  { geneTranscript: "NM_021976.5", parsimonySitesP: "0.109443402126329", constantSitesP: "0.819262038774234", distinctSitesP: "0.17385866166354", brLenTotal: "0.3804" },
  { geneTranscript: "NM_019885.4", parsimonySitesP: "0.146484375", constantSitesP: "0.771484375", distinctSitesP: "0.194661458333333", brLenTotal: "0.7386" },
  { geneTranscript: "NM_016335.6", parsimonySitesP: "0.323888888888889", constantSitesP: "0.479444444444444", distinctSitesP: "0.488888888888889", brLenTotal: "1.4992" },
  { geneTranscript: "NM_138802.3", parsimonySitesP: "0.151750972762646", constantSitesP: "0.728923476005188", distinctSitesP: "0.247730220492866", brLenTotal: "0.4806" },
  { geneTranscript: "NM_032799.5", parsimonySitesP: "0.198501872659176", constantSitesP: "0.655430711610487", distinctSitesP: "0.297128589263421", brLenTotal: "0.7825" },
  { geneTranscript: "NM_001377137.1", parsimonySitesP: "0.154659498207885", constantSitesP: "0.727956989247312", distinctSitesP: "0.17921146953405", brLenTotal: "0.5281" },
  { geneTranscript: "NM_022897.5", parsimonySitesP: "0.135723039215686", constantSitesP: "0.738664215686275", distinctSitesP: "0.210171568627451", brLenTotal: "0.4551" },
  { geneTranscript: "NM_001164473.3", parsimonySitesP: "0.0881542699724518", constantSitesP: "0.835812672176309", distinctSitesP: "0.145454545454545", brLenTotal: "0.2893" },
  { geneTranscript: "NM_000817.3", parsimonySitesP: "0.13973063973064", constantSitesP: "0.783389450056117", distinctSitesP: "0.191358024691358", brLenTotal: "0.4606" },
  { geneTranscript: "NM_198406.3", parsimonySitesP: "0.22093023255814", constantSitesP: "0.665697674418605", distinctSitesP: "0.301356589147287", brLenTotal: "0.7488" },
  { geneTranscript: "NM_001004717.1", parsimonySitesP: "0.53525641025641", constantSitesP: "0.314102564102564", distinctSitesP: "0.674145299145299", brLenTotal: "3.8540" },
  { geneTranscript: "NM_020061.6", parsimonySitesP: "0.208791208791209", constantSitesP: "0.701465201465201", distinctSitesP: "0.267399267399267", brLenTotal: "0.9362" },
  { geneTranscript: "NM_018650.5", parsimonySitesP: "0.107337526205451", constantSitesP: "0.80125786163522", distinctSitesP: "0.167295597484277", brLenTotal: "0.3624" },
  { geneTranscript: "NM_145309.6", parsimonySitesP: "0.230902777777778", constantSitesP: "0.631944444444444", distinctSitesP: "0.357638888888889", brLenTotal: "0.7283" },
  { geneTranscript: "NM_014871.6", parsimonySitesP: "0.125208681135225", constantSitesP: "0.778519755147468", distinctSitesP: "0.156928213689482", brLenTotal: "0.3898" },
  { geneTranscript: "NM_002755.4", parsimonySitesP: "0.151823579304495", constantSitesP: "0.737065309584394", distinctSitesP: "0.245122985581001", brLenTotal: "0.6748" },
  { geneTranscript: "NM_032041.3", parsimonySitesP: "0.151986183074266", constantSitesP: "0.739205526770294", distinctSitesP: "0.208981001727116", brLenTotal: "0.9392" },
  { geneTranscript: "NM_080759.6", parsimonySitesP: "0.103871576959396", constantSitesP: "0.805004721435316", distinctSitesP: "0.202077431539188", brLenTotal: "0.4201" },
  { geneTranscript: "NM_001654.5", parsimonySitesP: "0.141914191419142", constantSitesP: "0.75962596259626", distinctSitesP: "0.198569856985699", brLenTotal: "0.5059" },
  { geneTranscript: "NM_001805.4", parsimonySitesP: "0.181494661921708", constantSitesP: "0.720047449584816", distinctSitesP: "0.257413997627521", brLenTotal: "0.6842" },
  { geneTranscript: "NM_001003682.4", parsimonySitesP: "0.198697068403909", constantSitesP: "0.648208469055375", distinctSitesP: "0.310532030401737", brLenTotal: "0.7278" },
  { geneTranscript: "NM_003100.4", parsimonySitesP: "0.0918432883750803", constantSitesP: "0.828516377649326", distinctSitesP: "0.166987797045601", brLenTotal: "0.2838" },
  { geneTranscript: "NM_004186.5", parsimonySitesP: "0.179617834394904", constantSitesP: "0.726539278131635", distinctSitesP: "0.212738853503185", brLenTotal: "0.7532" },
  { geneTranscript: "NM_004429.5", parsimonySitesP: "0.153179190751445", constantSitesP: "0.761078998073218", distinctSitesP: "0.20616570327553", brLenTotal: "0.5187" },
  { geneTranscript: "NM_015694.3", parsimonySitesP: "0.182511030886482", constantSitesP: "0.66105094263939", distinctSitesP: "0.240272763738468", brLenTotal: "0.7909" },
  { geneTranscript: "NM_001127358.2", parsimonySitesP: "0.113788487282463", constantSitesP: "0.788487282463186", distinctSitesP: "0.168674698795181", brLenTotal: "0.3594" },
  { geneTranscript: "NM_152722.5", parsimonySitesP: "0.173076923076923", constantSitesP: "0.709935897435897", distinctSitesP: "0.253205128205128", brLenTotal: "0.8368" },
  { geneTranscript: "NM_194463.2", parsimonySitesP: "0.169781931464174", constantSitesP: "0.741433021806854", distinctSitesP: "0.227414330218069", brLenTotal: "0.5013" },
  { geneTranscript: "NM_002067.5", parsimonySitesP: "0.254410399257196", constantSitesP: "0.656453110492108", distinctSitesP: "0.311049210770659", brLenTotal: "1.2273" },
  { geneTranscript: "NM_017728.4", parsimonySitesP: "0.173387096774194", constantSitesP: "0.723790322580645", distinctSitesP: "0.248655913978495", brLenTotal: "0.7823" },
  { geneTranscript: "NM_016312.3", parsimonySitesP: "0.146125845033801", constantSitesP: "0.655746229849194", distinctSitesP: "0.277171086843474", brLenTotal: "0.6238" },
  { geneTranscript: "NM_001514.6", parsimonySitesP: "0.121308016877637", constantSitesP: "0.792194092827004", distinctSitesP: "0.189873417721519", brLenTotal: "0.4040" },
  { geneTranscript: "NM_001186.4", parsimonySitesP: "0.221920289855072", constantSitesP: "0.634510869565217", distinctSitesP: "0.303442028985507", brLenTotal: "0.7518" },
  { geneTranscript: "NM_000015.3", parsimonySitesP: "0.354022988505747", constantSitesP: "0.5", distinctSitesP: "0.527586206896552", brLenTotal: "1.3351" },
  { geneTranscript: "NM_000016.6", parsimonySitesP: "0.16547901821061", constantSitesP: "0.71021377672209", distinctSitesP: "0.28424386381631", brLenTotal: "0.6154" },
  { geneTranscript: "NM_000018.4", parsimonySitesP: "0.244783715012723", constantSitesP: "0.649363867684478", distinctSitesP: "0.307379134860051", brLenTotal: "0.7637" },
  { geneTranscript: "NM_000019.4", parsimonySitesP: "0.213114754098361", constantSitesP: "0.656518345042935", distinctSitesP: "0.347384855581577", brLenTotal: "0.8273" },
  { geneTranscript: "NM_000027.4", parsimonySitesP: "0.264932562620424", constantSitesP: "0.604046242774566", distinctSitesP: "0.413294797687861", brLenTotal: "0.9295" },
  { geneTranscript: "NM_000049.4", parsimonySitesP: "0.222577209797657", constantSitesP: "0.634717784877529", distinctSitesP: "0.364217252396166", brLenTotal: "0.7324" },
  { geneTranscript: "NM_000039.3", parsimonySitesP: "0.310861423220974", constantSitesP: "0.534332084893883", distinctSitesP: "0.465667915106117", brLenTotal: "1.2021" },
  { geneTranscript: "NM_000030.3", parsimonySitesP: "0.316326530612245", constantSitesP: "0.554421768707483", distinctSitesP: "0.442176870748299", brLenTotal: "1.4613" },
  { geneTranscript: "NM_000063.6", parsimonySitesP: "0.230939716312057", constantSitesP: "0.636968085106383", distinctSitesP: "0.309840425531915", brLenTotal: "0.9079" },
  { geneTranscript: "NM_000112.4", parsimonySitesP: "0.212900315741994", constantSitesP: "0.662607126747857", distinctSitesP: "0.305818673883627", brLenTotal: "0.7221" },
  { geneTranscript: "NM_000067.3", parsimonySitesP: "0.243589743589744", constantSitesP: "0.625641025641026", distinctSitesP: "0.393589743589744", brLenTotal: "0.8465" },
  { geneTranscript: "NM_000085.5", parsimonySitesP: "0.27802037845706", constantSitesP: "0.590490053372149", distinctSitesP: "0.385735080058224", brLenTotal: "1.2928" },
  { geneTranscript: "NM_000091.5", parsimonySitesP: "0.241516966067864", constantSitesP: "0.606187624750499", distinctSitesP: "0.379840319361277", brLenTotal: "0.9233" },
  { geneTranscript: "NM_000088.4", parsimonySitesP: "0.14799635701275", constantSitesP: "0.763888888888889", distinctSitesP: "0.217896174863388", brLenTotal: "0.5713" },
  { geneTranscript: "NM_000103.4", parsimonySitesP: "0.25115970841617", constantSitesP: "0.640159045725646", distinctSitesP: "0.38104705102717", brLenTotal: "0.8817" },
  { geneTranscript: "NM_000092.5", parsimonySitesP: "0.265285996055227", constantSitesP: "0.581065088757396", distinctSitesP: "0.383826429980276", brLenTotal: "1.0225" },
  { geneTranscript: "NM_000096.4", parsimonySitesP: "0.228794992175274", constantSitesP: "0.62472613458529", distinctSitesP: "0.384350547730829", brLenTotal: "0.8439" },
  { geneTranscript: "NM_000097.7", parsimonySitesP: "0.234948604992658", constantSitesP: "0.618208516886931", distinctSitesP: "0.379588839941263", brLenTotal: "0.8334" },
  { geneTranscript: "NM_000129.4", parsimonySitesP: "0.241347905282332", constantSitesP: "0.643897996357013", distinctSitesP: "0.336976320582878", brLenTotal: "0.8833" },
  { geneTranscript: "NM_000152.5", parsimonySitesP: "0.292366946778711", constantSitesP: "0.564425770308123", distinctSitesP: "0.420168067226891", brLenTotal: "1.4395" },
  { geneTranscript: "NM_000133.4", parsimonySitesP: "0.179320318148952", constantSitesP: "0.71294287780188", distinctSitesP: "0.298626174981923", brLenTotal: "0.6011" },
  { geneTranscript: "NM_000147.5", parsimonySitesP: "0.273247496423462", constantSitesP: "0.592989985693848", distinctSitesP: "0.388412017167382", brLenTotal: "1.0542" },
  { geneTranscript: "NM_000153.4", parsimonySitesP: "0.205839416058394", constantSitesP: "0.666666666666667", distinctSitesP: "0.326520681265207", brLenTotal: "0.7311" },
  { geneTranscript: "NM_000160.5", parsimonySitesP: "0.288609364081062", constantSitesP: "0.560447239692523", distinctSitesP: "0.43046820405311", brLenTotal: "1.3942" },
  { geneTranscript: "NM_000161.3", parsimonySitesP: "0.193333333333333", constantSitesP: "0.664", distinctSitesP: "0.348", brLenTotal: "0.7311" },
  { geneTranscript: "NM_000396.4", parsimonySitesP: "0.181357649442756", constantSitesP: "0.693009118541033", distinctSitesP: "0.298885511651469", brLenTotal: "0.5790" },
  { geneTranscript: "NM_000185.4", parsimonySitesP: "0.24248496993988", constantSitesP: "0.63059452237809", distinctSitesP: "0.33934535738143", brLenTotal: "1.0026" },
  { geneTranscript: "NM_000206.3", parsimonySitesP: "0.206865401987353", constantSitesP: "0.655826558265583", distinctSitesP: "0.339656729900632", brLenTotal: "0.6854" },
  { geneTranscript: "NM_000209.4", parsimonySitesP: "0.220259128386337", constantSitesP: "0.632508833922261", distinctSitesP: "0.352179034157833", brLenTotal: "0.9442" },
  { geneTranscript: "NM_000202.8", parsimonySitesP: "0.218181818181818", constantSitesP: "0.639393939393939", distinctSitesP: "0.331515151515152", brLenTotal: "0.7661" },
  { geneTranscript: "NM_000218.3", parsimonySitesP: "0.227810650887574", constantSitesP: "0.67258382642998", distinctSitesP: "0.321499013806706", brLenTotal: "1.1266" },
  { geneTranscript: "NM_000498.3", parsimonySitesP: "0.328694499668655", constantSitesP: "0.497017892644135", distinctSitesP: "0.487740225314778", brLenTotal: "1.5493" },
  { geneTranscript: "NM_000222.3", parsimonySitesP: "0.211065573770492", constantSitesP: "0.674863387978142", distinctSitesP: "0.313866120218579", brLenTotal: "0.9681" },
  { geneTranscript: "NM_000232.5", parsimonySitesP: "0.20440251572327", constantSitesP: "0.683438155136268", distinctSitesP: "0.325995807127883", brLenTotal: "0.8892" },
  { geneTranscript: "NM_000236.3", parsimonySitesP: "0.279893119572478", constantSitesP: "0.577822311289245", distinctSitesP: "0.445557782231129", brLenTotal: "1.0857" },
  { geneTranscript: "NM_000254.3", parsimonySitesP: "0.199472990777339", constantSitesP: "0.688801054018445", distinctSitesP: "0.276152832674572", brLenTotal: "0.8478" },
  { geneTranscript: "NM_000273.3", parsimonySitesP: "0.236798679867987", constantSitesP: "0.637788778877888", distinctSitesP: "0.336633663366337", brLenTotal: "0.8672" },
  { geneTranscript: "NM_000261.2", parsimonySitesP: "0.279100529100529", constantSitesP: "0.549603174603175", distinctSitesP: "0.419312169312169", brLenTotal: "1.1584" },
  { geneTranscript: "NM_000288.4", parsimonySitesP: "0.163054695562436", constantSitesP: "0.712074303405573", distinctSitesP: "0.281733746130031", brLenTotal: "0.6036" },
  { geneTranscript: "NM_000295.5", parsimonySitesP: "0.393141945773525", constantSitesP: "0.415470494417863", distinctSitesP: "0.575757575757576", brLenTotal: "1.8068" },
  { geneTranscript: "NM_000305.3", parsimonySitesP: "0.170433145009416", constantSitesP: "0.710922787193974", distinctSitesP: "0.255178907721281", brLenTotal: "0.5835" },
  { geneTranscript: "NM_000340.2", parsimonySitesP: "0.245547073791349", constantSitesP: "0.611959287531807", distinctSitesP: "0.400127226463104", brLenTotal: "1.1127" },
  { geneTranscript: "NM_000341.4", parsimonySitesP: "0.254987834549878", constantSitesP: "0.61021897810219", distinctSitesP: "0.401946472019465", brLenTotal: "0.8975" },
  { geneTranscript: "NM_000343.4", parsimonySitesP: "0.259036144578313", constantSitesP: "0.626004016064257", distinctSitesP: "0.368975903614458", brLenTotal: "1.0349" },
  { geneTranscript: "NM_000344.4", parsimonySitesP: "0.193877551020408", constantSitesP: "0.643990929705215", distinctSitesP: "0.382086167800454", brLenTotal: "0.7668" },
  { geneTranscript: "NM_000348.4", parsimonySitesP: "0.270341207349081", constantSitesP: "0.585301837270341", distinctSitesP: "0.41994750656168", brLenTotal: "0.9953" },
  { geneTranscript: "NM_000363.5", parsimonySitesP: "0.222222222222222", constantSitesP: "0.66031746031746", distinctSitesP: "0.358730158730159", brLenTotal: "1.0423" },
  { geneTranscript: "NM_000371.4", parsimonySitesP: "0.308390022675737", constantSitesP: "0.562358276643991", distinctSitesP: "0.482993197278912", brLenTotal: "1.1353" },
  { geneTranscript: "NM_000375.3", parsimonySitesP: "0.20251572327044", constantSitesP: "0.660377358490566", distinctSitesP: "0.376100628930818", brLenTotal: "0.7626" },
  { geneTranscript: "NM_000353.3", parsimonySitesP: "0.236417033773862", constantSitesP: "0.658590308370044", distinctSitesP: "0.324522760646109", brLenTotal: "0.7040" },
  { geneTranscript: "NM_000382.3", parsimonySitesP: "0.196563573883162", constantSitesP: "0.674914089347079", distinctSitesP: "0.305154639175258", brLenTotal: "0.7364" },
  { geneTranscript: "NM_000392.5", parsimonySitesP: "0.234735706580367", constantSitesP: "0.629989212513484", distinctSitesP: "0.334412081984898", brLenTotal: "0.9053" },
  { geneTranscript: "NM_000423.3", parsimonySitesP: "0.307250912884716", constantSitesP: "0.562858633281168", distinctSitesP: "0.462180490349504", brLenTotal: "1.7330" },
  { geneTranscript: "NM_000436.4", parsimonySitesP: "0.173076923076923", constantSitesP: "0.705769230769231", distinctSitesP: "0.300641025641026", brLenTotal: "0.5950" },
  { geneTranscript: "NM_000414.4", parsimonySitesP: "0.1875", constantSitesP: "0.686594202898551", distinctSitesP: "0.316576086956522", brLenTotal: "0.6658" },
  { geneTranscript: "NM_000446.7", parsimonySitesP: "0.255399061032864", constantSitesP: "0.620657276995305", distinctSitesP: "0.392488262910798", brLenTotal: "0.9605" },
  { geneTranscript: "NM_000453.3", parsimonySitesP: "0.290824261275272", constantSitesP: "0.595127008812856", distinctSitesP: "0.400725764644894", brLenTotal: "1.3043" },
  { geneTranscript: "NM_000463.3", parsimonySitesP: "0.287054409005629", constantSitesP: "0.543464665415885", distinctSitesP: "0.435897435897436", brLenTotal: "1.1822" },
  { geneTranscript: "NM_000466.3", parsimonySitesP: "0.207066770589764", constantSitesP: "0.648480124707716", distinctSitesP: "0.315146791374383", brLenTotal: "0.7046" },
  { geneTranscript: "NM_000485.3", parsimonySitesP: "0.311111111111111", constantSitesP: "0.546296296296296", distinctSitesP: "0.451851851851852", brLenTotal: "1.2341" },
  { geneTranscript: "NM_000493.4", parsimonySitesP: "0.247058823529412", constantSitesP: "0.591176470588235", distinctSitesP: "0.355882352941176", brLenTotal: "0.9063" },
  { geneTranscript: "NM_000492.4", parsimonySitesP: "0.165540540540541", constantSitesP: "0.706081081081081", distinctSitesP: "0.259234234234234", brLenTotal: "0.5953" },
  { geneTranscript: "NM_000497.4", parsimonySitesP: "0.336646785950961", constantSitesP: "0.49039098740888", distinctSitesP: "0.504307488402916", brLenTotal: "1.6001" },
  { geneTranscript: "NM_000518.5", parsimonySitesP: "0.321995464852608", constantSitesP: "0.562358276643991", distinctSitesP: "0.444444444444444", brLenTotal: "1.4897" },
  { geneTranscript: "NM_000506.5", parsimonySitesP: "0.27384780278671", constantSitesP: "0.584137191854234", distinctSitesP: "0.385316184351554", brLenTotal: "1.1645" },
  { geneTranscript: "NM_000519.4", parsimonySitesP: "0.376417233560091", constantSitesP: "0.476190476190476", distinctSitesP: "0.532879818594104", brLenTotal: "1.6555" },
  { geneTranscript: "NM_000527.5", parsimonySitesP: "0.317054263565891", constantSitesP: "0.556589147286822", distinctSitesP: "0.427131782945736", brLenTotal: "1.4131" },
  { geneTranscript: "NM_000520.6", parsimonySitesP: "0.217391304347826", constantSitesP: "0.659105229993699", distinctSitesP: "0.303087586641462", brLenTotal: "0.8164" },
  { geneTranscript: "NM_000522.5", parsimonySitesP: "0.164089347079038", constantSitesP: "0.738831615120275", distinctSitesP: "0.319587628865979", brLenTotal: "0.7073" },
  { geneTranscript: "NM_000526.5", parsimonySitesP: "0.263418079096045", constantSitesP: "0.584039548022599", distinctSitesP: "0.423728813559322", brLenTotal: "1.8590" },
  { geneTranscript: "NM_000538.4", parsimonySitesP: "0.257352941176471", constantSitesP: "0.590686274509804", distinctSitesP: "0.444852941176471", brLenTotal: "0.9390" },
  { geneTranscript: "NM_000541.5", parsimonySitesP: "0.206584362139918", constantSitesP: "0.675720164609053", distinctSitesP: "0.331687242798354", brLenTotal: "0.9137" },
  { geneTranscript: "NM_000542.5", parsimonySitesP: "0.321084864391951", constantSitesP: "0.496937882764654", distinctSitesP: "0.499562554680665", brLenTotal: "1.2951" },
  { geneTranscript: "NM_000546.6", parsimonySitesP: "0.26039016115352", constantSitesP: "0.604749787955895", distinctSitesP: "0.408821034775233", brLenTotal: "1.0354" },
  { geneTranscript: "NM_000551.4", parsimonySitesP: "0.287949921752739", constantSitesP: "0.553990610328638", distinctSitesP: "0.456964006259781", brLenTotal: "1.3158" },
  { geneTranscript: "NM_000560.4", parsimonySitesP: "0.214611872146119", constantSitesP: "0.633181126331811", distinctSitesP: "0.350076103500761", brLenTotal: "0.8002" },
  { geneTranscript: "NM_000561.4", parsimonySitesP: "0.301223241590214", constantSitesP: "0.539755351681957", distinctSitesP: "0.481651376146789", brLenTotal: "1.6667" },
  { geneTranscript: "NM_000565.4", parsimonySitesP: "0.318376068376068", constantSitesP: "0.512108262108262", distinctSitesP: "0.482193732193732", brLenTotal: "1.2764" },
  { geneTranscript: "NM_000591.4", parsimonySitesP: "0.350222222222222", constantSitesP: "0.459555555555556", distinctSitesP: "0.500444444444444", brLenTotal: "1.2409" },
  { geneTranscript: "NM_000597.3", parsimonySitesP: "0.21025641025641", constantSitesP: "0.672820512820513", distinctSitesP: "0.314871794871795", brLenTotal: "0.8779" },
  { geneTranscript: "NM_000606.3", parsimonySitesP: "0.326732673267327", constantSitesP: "0.504950495049505", distinctSitesP: "0.483498349834983", brLenTotal: "1.3580" },
  { geneTranscript: "NM_000628.5", parsimonySitesP: "0.254358974358974", constantSitesP: "0.592820512820513", distinctSitesP: "0.442051282051282", brLenTotal: "0.9681" },
  { geneTranscript: "NM_000642.3", parsimonySitesP: "0.156005221932115", constantSitesP: "0.715404699738903", distinctSitesP: "0.227589208006963", brLenTotal: "0.5707" },
  { geneTranscript: "NM_000651.6", parsimonySitesP: "0.405651533413687", constantSitesP: "0.418374179724119", distinctSitesP: "0.369090665595286", brLenTotal: "2.4040" },
  { geneTranscript: "NM_000662.8", parsimonySitesP: "0.322988505747126", constantSitesP: "0.506896551724138", distinctSitesP: "0.527586206896552", brLenTotal: "1.2772" },
  { geneTranscript: "NM_000667.4", parsimonySitesP: "0.325333333333333", constantSitesP: "0.526222222222222", distinctSitesP: "0.476444444444444", brLenTotal: "1.8916" },
  { geneTranscript: "NM_000668.6", parsimonySitesP: "0.312", constantSitesP: "0.532444444444444", distinctSitesP: "0.469333333333333", brLenTotal: "1.9415" },
  { geneTranscript: "NM_000669.5", parsimonySitesP: "0.312", constantSitesP: "0.551111111111111", distinctSitesP: "0.459555555555556", brLenTotal: "1.8794" },
  { geneTranscript: "NM_000673.7", parsimonySitesP: "0.306595365418895", constantSitesP: "0.526737967914439", distinctSitesP: "0.451871657754011", brLenTotal: "1.4532" },
  { geneTranscript: "NM_000681.4", parsimonySitesP: "0.209318996415771", constantSitesP: "0.629390681003584", distinctSitesP: "0.337634408602151", brLenTotal: "0.9120" },
  { geneTranscript: "NM_000683.4", parsimonySitesP: "0.186147186147186", constantSitesP: "0.663059163059163", distinctSitesP: "0.334054834054834", brLenTotal: "0.8524" },
  { geneTranscript: "NM_000700.3", parsimonySitesP: "0.180154142581888", constantSitesP: "0.709055876685934", distinctSitesP: "0.303468208092486", brLenTotal: "0.6254" },
  { geneTranscript: "NM_000705.4", parsimonySitesP: "0.270332187857961", constantSitesP: "0.601374570446735", distinctSitesP: "0.407789232531501", brLenTotal: "1.4232" },
  { geneTranscript: "NM_000706.5", parsimonySitesP: "0.246411483253589", constantSitesP: "0.613237639553429", distinctSitesP: "0.363636363636364", brLenTotal: "1.0198" },
  { geneTranscript: "NM_000745.4", parsimonySitesP: "0.253561253561254", constantSitesP: "0.571937321937322", distinctSitesP: "0.384615384615385", brLenTotal: "1.3072" },
  { geneTranscript: "NM_000754.4", parsimonySitesP: "0.346863468634686", constantSitesP: "0.52029520295203", distinctSitesP: "0.482164821648216", brLenTotal: "1.6511" },
  { geneTranscript: "NM_000757.6", parsimonySitesP: "0.325511432009627", constantSitesP: "0.511432009626955", distinctSitesP: "0.474729241877256", brLenTotal: "1.0502" },
  { geneTranscript: "NM_000765.5", parsimonySitesP: "0.330682571239231", constantSitesP: "0.526176275679258", distinctSitesP: "0.487740225314778", brLenTotal: "1.8787" },
  { geneTranscript: "NM_000767.5", parsimonySitesP: "0.340801086218601", constantSitesP: "0.496266123557366", distinctSitesP: "0.505770536320434", brLenTotal: "1.4247" },
  { geneTranscript: "NM_000769.4", parsimonySitesP: "0.389795918367347", constantSitesP: "0.438095238095238", distinctSitesP: "0.562585034013605", brLenTotal: "2.6200" },
  { geneTranscript: "NM_000770.3", parsimonySitesP: "0.379591836734694", constantSitesP: "0.431972789115646", distinctSitesP: "0.561224489795918", brLenTotal: "2.3587" },
  { geneTranscript: "NM_000771.4", parsimonySitesP: "0.372108843537415", constantSitesP: "0.457823129251701", distinctSitesP: "0.539455782312925", brLenTotal: "2.4756" },
  { geneTranscript: "NM_000772.3", parsimonySitesP: "0.389795918367347", constantSitesP: "0.425850340136054", distinctSitesP: "0.568027210884354", brLenTotal: "2.2508" },
  { geneTranscript: "NM_000773.4", parsimonySitesP: "0.338742393509128", constantSitesP: "0.512508451656525", distinctSitesP: "0.525354969574037", brLenTotal: "1.5620" },
  { geneTranscript: "NM_000775.4", parsimonySitesP: "0.296812749003984", constantSitesP: "0.539176626826029", distinctSitesP: "0.461487383798141", brLenTotal: "1.2294" },
  { geneTranscript: "NM_000777.5", parsimonySitesP: "0.326029216467463", constantSitesP: "0.535192563081009", distinctSitesP: "0.48273572377158", brLenTotal: "1.9126" },
  { geneTranscript: "NM_000781.3", parsimonySitesP: "0.309021113243762", constantSitesP: "0.565579014715291", distinctSitesP: "0.4510556621881", brLenTotal: "1.1697" },
  { geneTranscript: "NM_000787.4", parsimonySitesP: "0.33063209076175", constantSitesP: "0.53160453808752", distinctSitesP: "0.489465153970827", brLenTotal: "1.4588" },
  { geneTranscript: "NM_000788.3", parsimonySitesP: "0.179487179487179", constantSitesP: "0.692307692307692", distinctSitesP: "0.302564102564103", brLenTotal: "0.5460" },
  { geneTranscript: "NM_000791.4", parsimonySitesP: "0.190730837789661", constantSitesP: "0.64349376114082", distinctSitesP: "0.385026737967914", brLenTotal: "0.7424" },
  { geneTranscript: "NM_000804.4", parsimonySitesP: "0.359183673469388", constantSitesP: "0.463945578231293", distinctSitesP: "0.559183673469388", brLenTotal: "1.7595" },
  { geneTranscript: "NM_000809.4", parsimonySitesP: "0.24488567990373", constantSitesP: "0.65884476534296", distinctSitesP: "0.308664259927798", brLenTotal: "0.9956" },
  { geneTranscript: "NM_000810.4", parsimonySitesP: "0.191197691197691", constantSitesP: "0.707070707070707", distinctSitesP: "0.286435786435786", brLenTotal: "1.0636" },
  { geneTranscript: "NM_000812.4", parsimonySitesP: "0.262306610407876", constantSitesP: "0.661744022503516", distinctSitesP: "0.311533052039381", brLenTotal: "0.9914" },
  { geneTranscript: "NM_000824.5", parsimonySitesP: "0.166331321260899", constantSitesP: "0.75251509054326", distinctSitesP: "0.23943661971831", brLenTotal: "0.7461" },
  { geneTranscript: "NM_000835.6", parsimonySitesP: "0.226007028926737", constantSitesP: "0.636928899702622", distinctSitesP: "0.304136253041363", brLenTotal: "1.0693" },
  { geneTranscript: "NM_000836.4", parsimonySitesP: "0.183882235528942", constantSitesP: "0.733782435129741", distinctSitesP: "0.248502994011976", brLenTotal: "0.7584" },
  { geneTranscript: "NM_000846.5", parsimonySitesP: "0.348348348348348", constantSitesP: "0.466966966966967", distinctSitesP: "0.557057057057057", brLenTotal: "1.8110" },
  { geneTranscript: "NM_000847.5", parsimonySitesP: "0.339339339339339", constantSitesP: "0.460960960960961", distinctSitesP: "0.554054054054054", brLenTotal: "1.7297" },
  { geneTranscript: "NM_000849.5", parsimonySitesP: "0.216296296296296", constantSitesP: "0.684444444444444", distinctSitesP: "0.33037037037037", brLenTotal: "0.7088" },
  { geneTranscript: "NM_000850.5", parsimonySitesP: "0.26605504587156", constantSitesP: "0.558103975535168", distinctSitesP: "0.45565749235474", brLenTotal: "1.5079" },
  { geneTranscript: "NM_000855.3", parsimonySitesP: "0.204462659380692", constantSitesP: "0.699908925318761", distinctSitesP: "0.271402550091075", brLenTotal: "0.7121" },
  { geneTranscript: "NM_000860.6", parsimonySitesP: "0.167919799498747", constantSitesP: "0.731829573934837", distinctSitesP: "0.271929824561404", brLenTotal: "0.6145" },
  { geneTranscript: "NM_000899.5", parsimonySitesP: "0.142857142857143", constantSitesP: "0.766788766788767", distinctSitesP: "0.247863247863248", brLenTotal: "0.4125" },
  { geneTranscript: "NM_000926.4", parsimonySitesP: "0.258306538049303", constantSitesP: "0.585923544122901", distinctSitesP: "0.369774919614148", brLenTotal: "0.8817" },
  { geneTranscript: "NM_000936.4", parsimonySitesP: "0.287455197132616", constantSitesP: "0.559139784946237", distinctSitesP: "0.446594982078853", brLenTotal: "1.2119" },
  { geneTranscript: "NM_000951.3", parsimonySitesP: "0.244224422442244", constantSitesP: "0.622112211221122", distinctSitesP: "0.400990099009901", brLenTotal: "0.8732" },
  { geneTranscript: "NM_000953.3", parsimonySitesP: "0.279480037140204", constantSitesP: "0.590529247910864", distinctSitesP: "0.40297121634169", brLenTotal: "0.8970" },
  { geneTranscript: "NM_000959.4", parsimonySitesP: "0.192200557103064", constantSitesP: "0.693593314763231", distinctSitesP: "0.291550603528319", brLenTotal: "0.6398" },
  { geneTranscript: "NM_000960.4", parsimonySitesP: "0.252158894645941", constantSitesP: "0.607081174438687", distinctSitesP: "0.373920552677029", brLenTotal: "0.8893" },
  { geneTranscript: "NM_000963.4", parsimonySitesP: "0.215783664459161", constantSitesP: "0.682119205298013", distinctSitesP: "0.330022075055188", brLenTotal: "0.7725" },
  { geneTranscript: "NM_000970.6", parsimonySitesP: "0.233796296296296", constantSitesP: "0.574074074074074", distinctSitesP: "0.396990740740741", brLenTotal: "0.9888" },
  { geneTranscript: "NM_000977.4", parsimonySitesP: "0.235387045813586", constantSitesP: "0.571879936808847", distinctSitesP: "0.390205371248025", brLenTotal: "1.1503" },
  { geneTranscript: "NM_000998.5", parsimonySitesP: "0.123188405797101", constantSitesP: "0.72463768115942", distinctSitesP: "0.307971014492754", brLenTotal: "0.5091" },
  { geneTranscript: "NM_001001346.3", parsimonySitesP: "0.240487062404871", constantSitesP: "0.605783866057839", distinctSitesP: "0.41400304414003", brLenTotal: "1.0061" },
  { geneTranscript: "NM_001001410.3", parsimonySitesP: "0.30982905982906", constantSitesP: "0.522435897435897", distinctSitesP: "0.477564102564103", brLenTotal: "1.3106" },
  { geneTranscript: "NM_001001433.3", parsimonySitesP: "0.137435897435897", constantSitesP: "0.774358974358974", distinctSitesP: "0.228717948717949", brLenTotal: "0.4635" },
  { geneTranscript: "NM_001001414.2", parsimonySitesP: "0.275151515151515", constantSitesP: "0.593939393939394", distinctSitesP: "0.425454545454545", brLenTotal: "1.1787" },
  { geneTranscript: "NM_001001437.4", parsimonySitesP: "0.376344086021505", constantSitesP: "0.462365591397849", distinctSitesP: "0.587813620071685", brLenTotal: "1.4210" },
  { geneTranscript: "NM_001001520.3", parsimonySitesP: "0.263288623944362", constantSitesP: "0.617486338797814", distinctSitesP: "0.384500745156483", brLenTotal: "1.3698" },
  { geneTranscript: "NM_001001659.3", parsimonySitesP: "0.50752688172043", constantSitesP: "0.326881720430108", distinctSitesP: "0.664516129032258", brLenTotal: "3.8792" },
  { geneTranscript: "NM_001001671.4", parsimonySitesP: "0.207666920538208", constantSitesP: "0.668697638994669", distinctSitesP: "0.324447829398324", brLenTotal: "0.7755" },
  { geneTranscript: "NM_001001790.3", parsimonySitesP: "0.176470588235294", constantSitesP: "0.666666666666667", distinctSitesP: "0.372549019607843", brLenTotal: "0.5630" },
  { geneTranscript: "NM_001001920.3", parsimonySitesP: "0.531645569620253", constantSitesP: "0.354430379746835", distinctSitesP: "0.663502109704641", brLenTotal: "4.7219" },
  { geneTranscript: "NM_001001936.3", parsimonySitesP: "0.231866340668297", constantSitesP: "0.649959250203749", distinctSitesP: "0.32559087204564", brLenTotal: "0.9692" },
  { geneTranscript: "NM_001001991.3", parsimonySitesP: "0.225925925925926", constantSitesP: "0.638888888888889", distinctSitesP: "0.315277777777778", brLenTotal: "1.1912" },
  { geneTranscript: "NM_001001998.3", parsimonySitesP: "0.220338983050847", constantSitesP: "0.670433145009416", distinctSitesP: "0.336723163841808", brLenTotal: "0.7889" },
  { geneTranscript: "NM_001002257.3", parsimonySitesP: "0.158687943262411", constantSitesP: "0.721631205673759", distinctSitesP: "0.25531914893617", brLenTotal: "0.5902" },
  { geneTranscript: "NM_001002755.4", parsimonySitesP: "0.152230971128609", constantSitesP: "0.703412073490814", distinctSitesP: "0.307086614173228", brLenTotal: "0.5160" },
  { geneTranscript: "NM_001002759.2", parsimonySitesP: "0.254421768707483", constantSitesP: "0.575510204081633", distinctSitesP: "0.431292517006803", brLenTotal: "0.8522" },
  { geneTranscript: "NM_001002860.4", parsimonySitesP: "0.132508833922261", constantSitesP: "0.722025912838634", distinctSitesP: "0.222614840989399", brLenTotal: "0.5267" },
  { geneTranscript: "NM_001002913.3", parsimonySitesP: "0.236760124610592", constantSitesP: "0.610591900311526", distinctSitesP: "0.394080996884735", brLenTotal: "0.9605" },
  { geneTranscript: "NM_001003699.4", parsimonySitesP: "0.262342135476464", constantSitesP: "0.586107921928817", distinctSitesP: "0.382319173363949", brLenTotal: "1.1753" },
  { geneTranscript: "NM_001003703.2", parsimonySitesP: "0.265432098765432", constantSitesP: "0.493827160493827", distinctSitesP: "0.558641975308642", brLenTotal: "1.1556" },
  { geneTranscript: "NM_001003787.4", parsimonySitesP: "0.17246713070379", constantSitesP: "0.722351121423047", distinctSitesP: "0.242072699149265", brLenTotal: "0.6779" },
  { geneTranscript: "NM_001003800.2", parsimonySitesP: "0.235087719298246", constantSitesP: "0.644834307992203", distinctSitesP: "0.302534113060429", brLenTotal: "1.1965" },
  { geneTranscript: "NM_001003818.3", parsimonySitesP: "0.383074935400517", constantSitesP: "0.458656330749354", distinctSitesP: "0.504521963824289", brLenTotal: "1.4776" },
  { geneTranscript: "NM_001003841.3", parsimonySitesP: "0.289695057833859", constantSitesP: "0.594637223974763", distinctSitesP: "0.396950578338591", brLenTotal: "1.2943" },
  { geneTranscript: "NM_001003931.4", parsimonySitesP: "0.290181363352095", constantSitesP: "0.542213883677298", distinctSitesP: "0.42338961851157", brLenTotal: "1.0902" },
  { geneTranscript: "NM_001004051.4", parsimonySitesP: "0.290373906125696", constantSitesP: "0.52744630071599", distinctSitesP: "0.411296738265712", brLenTotal: "1.1415" },
  { geneTranscript: "NM_001004135.2", parsimonySitesP: "0.5", constantSitesP: "0.37741935483871", distinctSitesP: "0.627956989247312", brLenTotal: "3.3755" },
  { geneTranscript: "NM_001004311.3", parsimonySitesP: "0.28310502283105", constantSitesP: "0.534246575342466", distinctSitesP: "0.523592085235921", brLenTotal: "0.9749" },
  { geneTranscript: "NM_001004320.2", parsimonySitesP: "0.210486891385768", constantSitesP: "0.654681647940075", distinctSitesP: "0.355805243445693", brLenTotal: "0.7047" },
  { geneTranscript: "NM_001004341.2", parsimonySitesP: "0.328716528162512", constantSitesP: "0.493074792243767", distinctSitesP: "0.519852262234534", brLenTotal: "1.6582" },
  { geneTranscript: "NM_001004342.5", parsimonySitesP: "0.218816517667092", constantSitesP: "0.686249467858663", distinctSitesP: "0.279693486590038", brLenTotal: "1.0096" },
  { geneTranscript: "NM_001004343.3", parsimonySitesP: "0.281179138321995", constantSitesP: "0.560090702947846", distinctSitesP: "0.458049886621315", brLenTotal: "1.0384" },
  { geneTranscript: "NM_001004441.3", parsimonySitesP: "0.22632944228275", constantSitesP: "0.590791180285344", distinctSitesP: "0.385862516212711", brLenTotal: "0.8713" },
  { geneTranscript: "NM_001004458.2", parsimonySitesP: "0.521025641025641", constantSitesP: "0.316923076923077", distinctSitesP: "0.657435897435897", brLenTotal: "2.6779" },
  { geneTranscript: "NM_001004462.2", parsimonySitesP: "0.566988210075027", constantSitesP: "0.245444801714898", distinctSitesP: "0.781350482315113", brLenTotal: "4.1425" },
  { geneTranscript: "NM_001004463.2", parsimonySitesP: "0.520900321543408", constantSitesP: "0.219721329046088", distinctSitesP: "0.830653804930332", brLenTotal: "4.0210" },
  { geneTranscript: "NM_001004470.3", parsimonySitesP: "0.214405360134003", constantSitesP: "0.648241206030151", distinctSitesP: "0.35929648241206", brLenTotal: "0.7656" },
  { geneTranscript: "NM_001004723.3", parsimonySitesP: "0.51900108577633", constantSitesP: "0.337676438653637", distinctSitesP: "0.667752442996743", brLenTotal: "4.0733" },
  { geneTranscript: "NM_001004725.1", parsimonySitesP: "0.545846817691478", constantSitesP: "0.335490830636462", distinctSitesP: "0.670981661272923", brLenTotal: "2.6558" },
  { geneTranscript: "NM_001004735.1", parsimonySitesP: "0.577494692144374", constantSitesP: "0.298301486199575", distinctSitesP: "0.701698513800425", brLenTotal: "3.4884" },
  { geneTranscript: "NM_001004749.2", parsimonySitesP: "0.397435897435897", constantSitesP: "0.368589743589744", distinctSitesP: "0.588675213675214", brLenTotal: "1.6371" },
  { geneTranscript: "NM_001005172.2", parsimonySitesP: "0.558386411889597", constantSitesP: "0.307855626326964", distinctSitesP: "0.683651804670913", brLenTotal: "2.9532" },
  { geneTranscript: "NM_001005179.4", parsimonySitesP: "0.383386581469649", constantSitesP: "0.438764643237487", distinctSitesP: "0.525026624068158", brLenTotal: "2.5295" },
  { geneTranscript: "NM_001005226.2", parsimonySitesP: "0.583599574014909", constantSitesP: "0.22790202342918", distinctSitesP: "0.774227902023429", brLenTotal: "3.1451" },
  { geneTranscript: "NM_001005242.3", parsimonySitesP: "0.233771405814417", constantSitesP: "0.622859418558343", distinctSitesP: "0.364794902429311", brLenTotal: "0.8946" },
  { geneTranscript: "NM_001005243.2", parsimonySitesP: "0.254526091586794", constantSitesP: "0.589989350372737", distinctSitesP: "0.40468583599574", brLenTotal: "0.8458" },
  { geneTranscript: "NM_001005285.2", parsimonySitesP: "0.315625", constantSitesP: "0.430208333333333", distinctSitesP: "0.583333333333333", brLenTotal: "1.6652" },
  { geneTranscript: "NM_001005480.2", parsimonySitesP: "0.530398322851153", constantSitesP: "0.29664570230608", distinctSitesP: "0.70440251572327", brLenTotal: "4.5991" },
  { geneTranscript: "NM_001005496.1", parsimonySitesP: "0.598577235772358", constantSitesP: "0.234756097560976", distinctSitesP: "0.765243902439024", brLenTotal: "4.5384" },
  { geneTranscript: "NM_001005.5", parsimonySitesP: "0.175582990397805", constantSitesP: "0.643347050754458", distinctSitesP: "0.327846364883402", brLenTotal: "0.7802" },
  { geneTranscript: "NM_001005751.3", parsimonySitesP: "0.240119313944817", constantSitesP: "0.592344021874223", distinctSitesP: "0.401441710166542", brLenTotal: "0.9144" },
  { geneTranscript: "NM_001006935.3", parsimonySitesP: "0.347286821705426", constantSitesP: "0.396899224806202", distinctSitesP: "0.582945736434109", brLenTotal: "1.9881" },
  { geneTranscript: "NM_001007088.2", parsimonySitesP: "0.214516129032258", constantSitesP: "0.569354838709677", distinctSitesP: "0.323655913978495", brLenTotal: "1.0067" },
  { geneTranscript: "NM_001007122.4", parsimonySitesP: "0.261682242990654", constantSitesP: "0.593235425011126", distinctSitesP: "0.400979083222074", brLenTotal: "0.9208" },
  { geneTranscript: "NM_001007525.5", parsimonySitesP: "0.334962756052142", constantSitesP: "0.494180633147114", distinctSitesP: "0.461359404096834", brLenTotal: "1.4848" },
  { geneTranscript: "NM_001008212.2", parsimonySitesP: "0.222992489890237", constantSitesP: "0.632004621606008", distinctSitesP: "0.400346620450607", brLenTotal: "0.8025" },
  { geneTranscript: "NM_001007531.3", parsimonySitesP: "0.364842454394693", constantSitesP: "0.402985074626866", distinctSitesP: "0.602819237147595", brLenTotal: "1.4302" },
  { geneTranscript: "NM_001008237.3", parsimonySitesP: "0.214128035320088", constantSitesP: "0.629139072847682", distinctSitesP: "0.375275938189845", brLenTotal: "0.7086" },
  { geneTranscript: "NM_001008270.3", parsimonySitesP: "0.245390070921986", constantSitesP: "0.631205673758865", distinctSitesP: "0.364539007092199", brLenTotal: "0.7772" },
  { geneTranscript: "NM_001008388.5", parsimonySitesP: "0.190123456790123", constantSitesP: "0.711111111111111", distinctSitesP: "0.306172839506173", brLenTotal: "0.5484" },
  { geneTranscript: "NM_001008397.4", parsimonySitesP: "0.125996810207337", constantSitesP: "0.768740031897927", distinctSitesP: "0.244019138755981", brLenTotal: "0.3932" },
  { geneTranscript: "NM_001008710.3", parsimonySitesP: "0.0697278911564626", constantSitesP: "0.858843537414966", distinctSitesP: "0.149659863945578", brLenTotal: "0.3208" },
  { geneTranscript: "NM_001008777.3", parsimonySitesP: "0.192477876106195", constantSitesP: "0.662241887905605", distinctSitesP: "0.334808259587021", brLenTotal: "0.6048" },
  { geneTranscript: "NM_001008895.4", parsimonySitesP: "0.234958278436539", constantSitesP: "0.669740887132191", distinctSitesP: "0.312252964426877", brLenTotal: "1.2377" },
  { geneTranscript: "NM_001009565.2", parsimonySitesP: "0.226455026455026", constantSitesP: "0.60952380952381", distinctSitesP: "0.391534391534392", brLenTotal: "0.8261" },
  { geneTranscript: "NM_001009566.3", parsimonySitesP: "0.226639483520217", constantSitesP: "0.676520557254502", distinctSitesP: "0.310567448182127", brLenTotal: "1.0722" },
  { geneTranscript: "NM_001009606.4", parsimonySitesP: "0.325536062378168", constantSitesP: "0.510721247563353", distinctSitesP: "0.483430799220273", brLenTotal: "1.7365" },
  { geneTranscript: "NM_001009608.3", parsimonySitesP: "0.244281045751634", constantSitesP: "0.600490196078431", distinctSitesP: "0.388071895424837", brLenTotal: "0.7873" },
  { geneTranscript: "NM_001009881.3", parsimonySitesP: "0.136170212765957", constantSitesP: "0.749138804457953", distinctSitesP: "0.225734549138804", brLenTotal: "0.4353" },
  { geneTranscript: "NM_001009909.4", parsimonySitesP: "0.177263969171484", constantSitesP: "0.677263969171484", distinctSitesP: "0.347784200385356", brLenTotal: "0.6632" },
  { geneTranscript: "NM_001009923.2", parsimonySitesP: "0.258652094717668", constantSitesP: "0.546448087431694", distinctSitesP: "0.457194899817851", brLenTotal: "1.3095" },
  { geneTranscript: "NM_001009944.3", parsimonySitesP: "0.325199473235727", constantSitesP: "0.534898133085444", distinctSitesP: "0.387326671314587", brLenTotal: "1.5778" },
  { geneTranscript: "NM_001010844.4", parsimonySitesP: "0.234615384615385", constantSitesP: "0.620512820512821", distinctSitesP: "0.364102564102564", brLenTotal: "0.7302" },
  { geneTranscript: "NM_001010848.4", parsimonySitesP: "0.120210727969349", constantSitesP: "0.766283524904215", distinctSitesP: "0.216954022988506", brLenTotal: "0.5379" },
  { geneTranscript: "NM_001010867.4", parsimonySitesP: "0.377340823970037", constantSitesP: "0.471910112359551", distinctSitesP: "0.502808988764045", brLenTotal: "1.7268" },
  { geneTranscript: "NM_001010868.3", parsimonySitesP: "0.252279635258359", constantSitesP: "0.570415400202634", distinctSitesP: "0.448834853090172", brLenTotal: "0.8868" },
  { geneTranscript: "NM_001010873.3", parsimonySitesP: "0.282352941176471", constantSitesP: "0.533333333333333", distinctSitesP: "0.450980392156863", brLenTotal: "0.9666" },
  { geneTranscript: "NM_001010904.2", parsimonySitesP: "0.253472222222222", constantSitesP: "0.56712962962963", distinctSitesP: "0.44212962962963", brLenTotal: "0.9336" },
  { geneTranscript: "NM_001010906.2", parsimonySitesP: "0.242881072026801", constantSitesP: "0.610134003350084", distinctSitesP: "0.385678391959799", brLenTotal: "0.9590" },
  { geneTranscript: "NM_001010915.5", parsimonySitesP: "0.149425287356322", constantSitesP: "0.754310344827586", distinctSitesP: "0.265804597701149", brLenTotal: "0.5103" },
  { geneTranscript: "NM_001010923.3", parsimonySitesP: "0.217888715548622", constantSitesP: "0.629745189807592", distinctSitesP: "0.33749349973999", brLenTotal: "0.7507" },
  { geneTranscript: "NM_001010977.3", parsimonySitesP: "0.242424242424242", constantSitesP: "0.621212121212121", distinctSitesP: "0.38510101010101", brLenTotal: "0.8775" },
  { geneTranscript: "NM_001010978.4", parsimonySitesP: "0.294308943089431", constantSitesP: "0.521951219512195", distinctSitesP: "0.499186991869919", brLenTotal: "1.3785" },
  { geneTranscript: "NM_001010979.3", parsimonySitesP: "0.217821782178218", constantSitesP: "0.627062706270627", distinctSitesP: "0.425742574257426", brLenTotal: "0.6688" },
  { geneTranscript: "NM_001011663.2", parsimonySitesP: "0.151428571428571", constantSitesP: "0.737142857142857", distinctSitesP: "0.272380952380952", brLenTotal: "0.6712" },
  { geneTranscript: "NM_001012267.3", parsimonySitesP: "0.243055555555556", constantSitesP: "0.542824074074074", distinctSitesP: "0.472222222222222", brLenTotal: "0.9591" },
  { geneTranscript: "NM_001012279.3", parsimonySitesP: "0.147835269271383", constantSitesP: "0.712073213657163", distinctSitesP: "0.215417106652587", brLenTotal: "0.7285" },
  { geneTranscript: "NM_001012418.5", parsimonySitesP: "0.220790378006873", constantSitesP: "0.646048109965636", distinctSitesP: "0.357388316151203", brLenTotal: "0.8152" },
  { geneTranscript: "NM_001012425.2", parsimonySitesP: "0.177777777777778", constantSitesP: "0.688888888888889", distinctSitesP: "0.331481481481481", brLenTotal: "0.5863" },
  { geneTranscript: "NM_001012455.2", parsimonySitesP: "0.254498714652956", constantSitesP: "0.520137103684662", distinctSitesP: "0.427592116538132", brLenTotal: "1.1967" },
  { geneTranscript: "NM_001012502.3", parsimonySitesP: "0.332692307692308", constantSitesP: "0.506410256410256", distinctSitesP: "0.492948717948718", brLenTotal: "1.2750" },
  { geneTranscript: "NM_001012507.4", parsimonySitesP: "0.231060606060606", constantSitesP: "0.598484848484848", distinctSitesP: "0.424242424242424", brLenTotal: "0.7976" },
  { geneTranscript: "NM_001012720.2", parsimonySitesP: "0.240549828178694", constantSitesP: "0.625429553264605", distinctSitesP: "0.3631156930126", brLenTotal: "1.0104" },
  { geneTranscript: "NM_001012758.3", parsimonySitesP: "0.28760162601626", constantSitesP: "0.522357723577236", distinctSitesP: "0.458333333333333", brLenTotal: "1.0399" },
  { geneTranscript: "NM_001012973.3", parsimonySitesP: "0.305841924398625", constantSitesP: "0.505154639175258", distinctSitesP: "0.532646048109966", brLenTotal: "1.1020" },
  { geneTranscript: "NM_001012979.3", parsimonySitesP: "0.519417475728155", constantSitesP: "0.257281553398058", distinctSitesP: "0.817152103559871", brLenTotal: "2.9584" },
  { geneTranscript: "NM_001012981.5", parsimonySitesP: "0.233712512926577", constantSitesP: "0.617028610823854", distinctSitesP: "0.35849706997587", brLenTotal: "0.8994" },
  { geneTranscript: "NM_001013579.3", parsimonySitesP: "0.246951219512195", constantSitesP: "0.579268292682927", distinctSitesP: "0.438008130081301", brLenTotal: "0.8645" },
  { geneTranscript: "NM_001013251.3", parsimonySitesP: "0.283553875236295", constantSitesP: "0.554505356017643", distinctSitesP: "0.420289855072464", brLenTotal: "0.9891" },
  { geneTranscript: "NM_001013619.4", parsimonySitesP: "0.239499553172475", constantSitesP: "0.57372654155496", distinctSitesP: "0.402144772117962", brLenTotal: "0.9217" },
  { geneTranscript: "NM_001013623.3", parsimonySitesP: "0.252252252252252", constantSitesP: "0.59009009009009", distinctSitesP: "0.432432432432432", brLenTotal: "0.8598" },
  { geneTranscript: "NM_001013625.4", parsimonySitesP: "0.184557438794727", constantSitesP: "0.638418079096045", distinctSitesP: "0.355932203389831", brLenTotal: "0.6588" },
  { geneTranscript: "NM_001013693.3", parsimonySitesP: "0.3125", constantSitesP: "0.485294117647059", distinctSitesP: "0.53921568627451", brLenTotal: "1.2596" },
  { geneTranscript: "NM_001013735.1", parsimonySitesP: "0.23533950617284", constantSitesP: "0.649691358024691", distinctSitesP: "0.35108024691358", brLenTotal: "1.0608" },
  { geneTranscript: "NM_001014436.3", parsimonySitesP: "0.198449612403101", constantSitesP: "0.682945736434109", distinctSitesP: "0.287596899224806", brLenTotal: "0.8676" },
  { geneTranscript: "NM_001014987.2", parsimonySitesP: "0.32761087267525", constantSitesP: "0.535050071530758", distinctSitesP: "0.530758226037196", brLenTotal: "1.4377" },
  { geneTranscript: "NM_001014999.3", parsimonySitesP: "0.301818181818182", constantSitesP: "0.517575757575758", distinctSitesP: "0.47030303030303", brLenTotal: "1.5746" },
  { geneTranscript: "NM_001015887.3", parsimonySitesP: "0.161639597834493", constantSitesP: "0.718484145398299", distinctSitesP: "0.264501160092807", brLenTotal: "0.5924" },
  { geneTranscript: "NM_001017373.4", parsimonySitesP: "0.214102564102564", constantSitesP: "0.623717948717949", distinctSitesP: "0.390384615384615", brLenTotal: "0.7643" },
  { geneTranscript: "NM_001017390.3", parsimonySitesP: "0.301694915254237", constantSitesP: "0.529943502824859", distinctSitesP: "0.484745762711864", brLenTotal: "1.5123" },
  { geneTranscript: "NM_001017921.4", parsimonySitesP: "0.398422090729783", constantSitesP: "0.3767258382643", distinctSitesP: "0.629191321499014", brLenTotal: "1.3922" },
  { geneTranscript: "NM_001017392.5", parsimonySitesP: "0.222427603203943", constantSitesP: "0.632778804682686", distinctSitesP: "0.312384473197782", brLenTotal: "0.8939" },
  { geneTranscript: "NM_001017920.3", parsimonySitesP: "0.193146417445483", constantSitesP: "0.663551401869159", distinctSitesP: "0.367601246105919", brLenTotal: "0.6573" },
  { geneTranscript: "NM_001017974.2", parsimonySitesP: "0.208255159474672", constantSitesP: "0.707942464040025", distinctSitesP: "0.287054409005629", brLenTotal: "0.7154" },
  { geneTranscript: "NM_001017981.2", parsimonySitesP: "0.200707338638373", constantSitesP: "0.677276746242263", distinctSitesP: "0.313881520778073", brLenTotal: "0.7730" },
  { geneTranscript: "NM_001018071.4", parsimonySitesP: "0.27782021899669", constantSitesP: "0.542908072319837", distinctSitesP: "0.435956200662083", brLenTotal: "1.0865" },
  { geneTranscript: "NM_001018109.3", parsimonySitesP: "0.163218390804598", constantSitesP: "0.691954022988506", distinctSitesP: "0.31264367816092", brLenTotal: "0.6880" },
  { geneTranscript: "NM_001023570.4", parsimonySitesP: "0.182274247491639", constantSitesP: "0.667224080267559", distinctSitesP: "0.313266443701226", brLenTotal: "0.6272" },
  { geneTranscript: "NM_001024211.2", parsimonySitesP: "0.23469387755102", constantSitesP: "0.656462585034014", distinctSitesP: "0.374149659863946", brLenTotal: "0.7914" },
  { geneTranscript: "NM_001024.4", parsimonySitesP: "0.132530120481928", constantSitesP: "0.759036144578313", distinctSitesP: "0.309236947791165", brLenTotal: "0.6180" },
  { geneTranscript: "NM_001025159.3", parsimonySitesP: "0.26463963963964", constantSitesP: "0.608108108108108", distinctSitesP: "0.38963963963964", brLenTotal: "1.0131" },
  { geneTranscript: "NM_001025295.3", parsimonySitesP: "0.257575757575758", constantSitesP: "0.616161616161616", distinctSitesP: "0.411616161616162", brLenTotal: "1.2055" },
  { geneTranscript: "NM_001025616.3", parsimonySitesP: "0.220142602495544", constantSitesP: "0.677361853832442", distinctSitesP: "0.300802139037433", brLenTotal: "0.7979" },
  { geneTranscript: "NM_001029864.2", parsimonySitesP: "0.327222222222222", constantSitesP: "0.491666666666667", distinctSitesP: "0.473888888888889", brLenTotal: "1.2559" },
  { geneTranscript: "NM_001029874.3", parsimonySitesP: "0.245762711864407", constantSitesP: "0.56638418079096", distinctSitesP: "0.396892655367232", brLenTotal: "0.8528" },
  { geneTranscript: "NM_001029954.3", parsimonySitesP: "0.269162210338681", constantSitesP: "0.579322638146168", distinctSitesP: "0.447415329768271", brLenTotal: "1.0015" },
  { geneTranscript: "NM_001031679.3", parsimonySitesP: "0.176576576576577", constantSitesP: "0.715315315315315", distinctSitesP: "0.293693693693694", brLenTotal: "0.7281" },
  { geneTranscript: "NM_001031701.3", parsimonySitesP: "0.177007299270073", constantSitesP: "0.718978102189781", distinctSitesP: "0.280413625304136", brLenTotal: "0.6877" },
  { geneTranscript: "NM_001031721.4", parsimonySitesP: "0.456509994597515", constantSitesP: "0.343057806591032", distinctSitesP: "0.674230145867099", brLenTotal: "2.7911" },
  { geneTranscript: "NM_001031739.3", parsimonySitesP: "0.32312925170068", constantSitesP: "0.502267573696145", distinctSitesP: "0.534013605442177", brLenTotal: "1.4242" },
  { geneTranscript: "NM_001031749.3", parsimonySitesP: "0.313413014608234", constantSitesP: "0.50730411686587", distinctSitesP: "0.50730411686587", brLenTotal: "1.2056" },
  { geneTranscript: "NM_001031855.3", parsimonySitesP: "0.220465524813351", constantSitesP: "0.646025472112429", distinctSitesP: "0.313570487483531", brLenTotal: "0.7061" },
  { geneTranscript: "NM_001032363.4", parsimonySitesP: "0.11965811965812", constantSitesP: "0.747863247863248", distinctSitesP: "0.277777777777778", brLenTotal: "0.4146" },
  { geneTranscript: "NM_001032382.2", parsimonySitesP: "0.205031446540881", constantSitesP: "0.69559748427673", distinctSitesP: "0.309433962264151", brLenTotal: "0.6568" },
  { geneTranscript: "NM_001033026.2", parsimonySitesP: "0.241397849462366", constantSitesP: "0.656451612903226", distinctSitesP: "0.356989247311828", brLenTotal: "1.0756" },
  { geneTranscript: "NM_001033031.2", parsimonySitesP: "0.145936981757877", constantSitesP: "0.759535655058043", distinctSitesP: "0.270315091210614", brLenTotal: "0.4991" },
  { geneTranscript: "NM_001033057.2", parsimonySitesP: "0.185134518923849", constantSitesP: "0.70405836753306", distinctSitesP: "0.239626082991336", brLenTotal: "0.7041" },
  { geneTranscript: "NM_001033088.3", parsimonySitesP: "0.344788087056128", constantSitesP: "0.446735395189003", distinctSitesP: "0.539518900343643", brLenTotal: "1.3192" },
  { geneTranscript: "NM_001033678.4", parsimonySitesP: "0.245059288537549", constantSitesP: "0.587615283267457", distinctSitesP: "0.384716732542819", brLenTotal: "0.8654" },
  { geneTranscript: "NM_001034116.2", parsimonySitesP: "0.176545570427024", constantSitesP: "0.663479923518164", distinctSitesP: "0.281070745697897", brLenTotal: "0.6661" },
  { geneTranscript: "NM_001034850.3", parsimonySitesP: "0.184439973172368", constantSitesP: "0.676727028839705", distinctSitesP: "0.303822937625755", brLenTotal: "0.6225" },
  { geneTranscript: "NM_001035.3", parsimonySitesP: "0.206160660358365", constantSitesP: "0.707536406952554", distinctSitesP: "0.228306825045299", brLenTotal: "1.1939" },
  { geneTranscript: "NM_001037283.2", parsimonySitesP: "0.207207207207207", constantSitesP: "0.694922194922195", distinctSitesP: "0.292383292383292", brLenTotal: "0.7541" },
  { geneTranscript: "NM_001037500.2", parsimonySitesP: "0.323943661971831", constantSitesP: "0.427230046948357", distinctSitesP: "0.60093896713615", brLenTotal: "1.1806" },
  { geneTranscript: "NM_001037582.3", parsimonySitesP: "0.252525252525253", constantSitesP: "0.629292929292929", distinctSitesP: "0.342424242424242", brLenTotal: "1.2275" },
  { geneTranscript: "NM_001037763.3", parsimonySitesP: "0.192296296296296", constantSitesP: "0.658666666666667", distinctSitesP: "0.310518518518519", brLenTotal: "0.7445" },
  { geneTranscript: "NM_001038.6", parsimonySitesP: "0.239661185849527", constantSitesP: "0.611858495266567", distinctSitesP: "0.355256601893373", brLenTotal: "1.0443" },
  { geneTranscript: "NM_001038705.3", parsimonySitesP: "0.251709986320109", constantSitesP: "0.57546739626083", distinctSitesP: "0.363885088919289", brLenTotal: "0.8541" },
  { geneTranscript: "NM_001039362.2", parsimonySitesP: "0.192818110850898", constantSitesP: "0.702576112412178", distinctSitesP: "0.320843091334895", brLenTotal: "0.7449" },
  { geneTranscript: "NM_001039396.2", parsimonySitesP: "0.335195530726257", constantSitesP: "0.476256983240223", distinctSitesP: "0.502793296089385", brLenTotal: "1.3625" },
  { geneTranscript: "NM_001039.4", parsimonySitesP: "0.223420647149461", constantSitesP: "0.667180277349769", distinctSitesP: "0.326142783769902", brLenTotal: "0.8191" },
  { geneTranscript: "NM_001039464.4", parsimonySitesP: "0.282690854119426", constantSitesP: "0.524565381708239", distinctSitesP: "0.431090954900479", brLenTotal: "1.1369" },
  { geneTranscript: "NM_001039547.3", parsimonySitesP: "0.207939508506616", constantSitesP: "0.664146187775677", distinctSitesP: "0.311909262759924", brLenTotal: "0.7466" },
  { geneTranscript: "NM_001039548.3", parsimonySitesP: "0.218410520297313", constantSitesP: "0.630074328187536", distinctSitesP: "0.334476843910806", brLenTotal: "0.9279" },
  { geneTranscript: "NM_001039613.3", parsimonySitesP: "0.205645161290323", constantSitesP: "0.653225806451613", distinctSitesP: "0.372311827956989", brLenTotal: "0.8164" },
  { geneTranscript: "NM_001039617.2", parsimonySitesP: "0.310679611650485", constantSitesP: "0.532901833872708", distinctSitesP: "0.468176914778857", brLenTotal: "1.1691" },
  { geneTranscript: "NM_001039999.3", parsimonySitesP: "0.281490481976509", constantSitesP: "0.575536654515998", distinctSitesP: "0.392061563385986", brLenTotal: "1.2041" },
  { geneTranscript: "NM_001040023.2", parsimonySitesP: "0.362433862433862", constantSitesP: "0.456349206349206", distinctSitesP: "0.51984126984127", brLenTotal: "1.8237" },
  { geneTranscript: "NM_001040025.3", parsimonySitesP: "0.248554913294798", constantSitesP: "0.59344894026975", distinctSitesP: "0.398843930635838", brLenTotal: "0.9278" },
  { geneTranscript: "NM_001040084.3", parsimonySitesP: "0.203873598369011", constantSitesP: "0.614678899082569", distinctSitesP: "0.361875637104995", brLenTotal: "0.9380" },
  { geneTranscript: "NM_001040092.3", parsimonySitesP: "0.154113557358053", constantSitesP: "0.736191579760525", distinctSitesP: "0.227500965623793", brLenTotal: "0.6179" },
  { geneTranscript: "NM_001040125.2", parsimonySitesP: "0.240549828178694", constantSitesP: "0.63573883161512", distinctSitesP: "0.379152348224513", brLenTotal: "1.0848" },
  { geneTranscript: "NM_001040167.2", parsimonySitesP: "0.237467018469657", constantSitesP: "0.631486367634125", distinctSitesP: "0.392260334212841", brLenTotal: "1.1176" },
  { geneTranscript: "NM_001040192.3", parsimonySitesP: "0.207044673539519", constantSitesP: "0.638316151202749", distinctSitesP: "0.391752577319588", brLenTotal: "0.8020" },
  { geneTranscript: "NM_001040450.3", parsimonySitesP: "0.159420289855072", constantSitesP: "0.698336017176597", distinctSitesP: "0.271604938271605", brLenTotal: "0.5804" },
  { geneTranscript: "NM_001040.5", parsimonySitesP: "0.313432835820896", constantSitesP: "0.511608623548922", distinctSitesP: "0.47346600331675", brLenTotal: "1.0817" },
  { geneTranscript: "NM_001040697.4", parsimonySitesP: "0.159943382873319", constantSitesP: "0.692144373673036", distinctSitesP: "0.298655343241331", brLenTotal: "0.5688" },
  { geneTranscript: "NM_001042353.3", parsimonySitesP: "0.23728813559322", constantSitesP: "0.563841807909605", distinctSitesP: "0.409039548022599", brLenTotal: "1.0325" },
  { geneTranscript: "NM_001042427.3", parsimonySitesP: "0.290322580645161", constantSitesP: "0.555555555555556", distinctSitesP: "0.469534050179211", brLenTotal: "1.1221" },
  { geneTranscript: "NM_001042428.2", parsimonySitesP: "0.299638989169675", constantSitesP: "0.504813477737665", distinctSitesP: "0.457882069795427", brLenTotal: "1.2924" },
  { geneTranscript: "NM_001042475.3", parsimonySitesP: "0.183850931677019", constantSitesP: "0.655900621118012", distinctSitesP: "0.284472049689441", brLenTotal: "0.6258" },
  { geneTranscript: "NM_001042493.3", parsimonySitesP: "0.171821305841924", constantSitesP: "0.652920962199313", distinctSitesP: "0.374570446735395", brLenTotal: "0.7563" },
  { geneTranscript: "NM_001042507.4", parsimonySitesP: "0.257352941176471", constantSitesP: "0.57843137254902", distinctSitesP: "0.446078431372549", brLenTotal: "1.2890" },
  { geneTranscript: "NM_001042517.2", parsimonySitesP: "0.166806370494552", constantSitesP: "0.702430846605197", distinctSitesP: "0.271584241408215", brLenTotal: "0.5747" },
  { geneTranscript: "NM_001042552.3", parsimonySitesP: "0.183698296836983", constantSitesP: "0.683698296836983", distinctSitesP: "0.316301703163017", brLenTotal: "0.5656" },
  { geneTranscript: "NM_001042618.2", parsimonySitesP: "0.173099415204678", constantSitesP: "0.682456140350877", distinctSitesP: "0.288888888888889", brLenTotal: "0.5836" },
  { geneTranscript: "NM_001042631.3", parsimonySitesP: "0.321739130434783", constantSitesP: "0.492753623188406", distinctSitesP: "0.501449275362319", brLenTotal: "1.1298" },
  { geneTranscript: "NM_001042706.3", parsimonySitesP: "0.205357142857143", constantSitesP: "0.636904761904762", distinctSitesP: "0.395833333333333", brLenTotal: "0.7531" },
  { geneTranscript: "NM_001042724.2", parsimonySitesP: "0.30731102850062", constantSitesP: "0.565055762081784", distinctSitesP: "0.413258983890954", brLenTotal: "1.1939" },
  { geneTranscript: "NM_001044305.3", parsimonySitesP: "0.139186295503212", constantSitesP: "0.755888650963597", distinctSitesP: "0.235546038543897", brLenTotal: "0.4962" },
  { geneTranscript: "NM_001044369.3", parsimonySitesP: "0.299920445505171", constantSitesP: "0.537788385043755", distinctSitesP: "0.467780429594272", brLenTotal: "1.3730" },
  { geneTranscript: "NM_001044385.3", parsimonySitesP: "0.193627450980392", constantSitesP: "0.658496732026144", distinctSitesP: "0.365196078431373", brLenTotal: "0.6178" },
  { geneTranscript: "NM_001048166.1", parsimonySitesP: "0.206004140786749", constantSitesP: "0.623964803312629", distinctSitesP: "0.378623188405797", brLenTotal: "0.7380" },
  { geneTranscript: "NM_001048252.3", parsimonySitesP: "0.246913580246914", constantSitesP: "0.666666666666667", distinctSitesP: "0.37037037037037", brLenTotal: "0.9457" },
  { geneTranscript: "NM_001048265.2", parsimonySitesP: "0.321078431372549", constantSitesP: "0.507352941176471", distinctSitesP: "0.522058823529412", brLenTotal: "1.2041" },
  { geneTranscript: "NM_001054.4", parsimonySitesP: "0.317514124293785", constantSitesP: "0.52090395480226", distinctSitesP: "0.500564971751412", brLenTotal: "1.5941" },
  { geneTranscript: "NM_001055.4", parsimonySitesP: "0.302824858757062", constantSitesP: "0.541242937853107", distinctSitesP: "0.477966101694915", brLenTotal: "1.5524" },
  { geneTranscript: "NM_001057.3", parsimonySitesP: "0.221943048576214", constantSitesP: "0.642378559463987", distinctSitesP: "0.350083752093802", brLenTotal: "0.9507" },
  { geneTranscript: "NM_001061.7", parsimonySitesP: "0.25328330206379", constantSitesP: "0.606003752345216", distinctSitesP: "0.369606003752345", brLenTotal: "0.9773" },
  { geneTranscript: "NM_001072.4", parsimonySitesP: "0.274436090225564", constantSitesP: "0.580827067669173", distinctSitesP: "0.442355889724311", brLenTotal: "1.0205" },
  { geneTranscript: "NM_001073.3", parsimonySitesP: "0.414618777567738", constantSitesP: "0.429111531190926", distinctSitesP: "0.568367989918084", brLenTotal: "2.6521" },
  { geneTranscript: "NM_001074.4", parsimonySitesP: "0.406427221172023", constantSitesP: "0.434152488972905", distinctSitesP: "0.569628229363579", brLenTotal: "2.4610" },
  { geneTranscript: "NM_001075.6", parsimonySitesP: "0.416035353535354", constantSitesP: "0.4375", distinctSitesP: "0.564393939393939", brLenTotal: "2.5998" },
  { geneTranscript: "NM_001076.4", parsimonySitesP: "0.385534591194969", constantSitesP: "0.455974842767296", distinctSitesP: "0.549685534591195", brLenTotal: "2.2844" },
  { geneTranscript: "NM_001076680.3", parsimonySitesP: "0.179487179487179", constantSitesP: "0.743589743589744", distinctSitesP: "0.299145299145299", brLenTotal: "0.5174" },
  { geneTranscript: "NM_001076778.3", parsimonySitesP: "0.199074074074074", constantSitesP: "0.703703703703704", distinctSitesP: "0.3125", brLenTotal: "0.7559" },
  { geneTranscript: "NM_001077207.4", parsimonySitesP: "0.189071038251366", constantSitesP: "0.695628415300546", distinctSitesP: "0.278688524590164", brLenTotal: "0.7373" },
  { geneTranscript: "NM_001077268.2", parsimonySitesP: "0.281670205237084", constantSitesP: "0.520877565463553", distinctSitesP: "0.453644727530078", brLenTotal: "1.1679" },
  { geneTranscript: "NM_001077365.2", parsimonySitesP: "0.253793103448276", constantSitesP: "0.640459770114943", distinctSitesP: "0.370574712643678", brLenTotal: "1.0218" },
  { geneTranscript: "NM_001077.4", parsimonySitesP: "0.377358490566038", constantSitesP: "0.455974842767296", distinctSitesP: "0.541509433962264", brLenTotal: "2.2399" },
  { geneTranscript: "NM_001077594.2", parsimonySitesP: "0.353647276084949", constantSitesP: "0.477377654662973", distinctSitesP: "0.501385041551247", brLenTotal: "1.4265" },
  { geneTranscript: "NM_001077620.3", parsimonySitesP: "0.283950617283951", constantSitesP: "0.592592592592593", distinctSitesP: "0.469135802469136", brLenTotal: "0.7313" },
  { geneTranscript: "NM_001077653.2", parsimonySitesP: "0.143176733780761", constantSitesP: "0.759880686055183", distinctSitesP: "0.216256524981357", brLenTotal: "0.6865" },
  { geneTranscript: "NM_001077657.3", parsimonySitesP: "0.272585669781931", constantSitesP: "0.538940809968847", distinctSitesP: "0.503115264797508", brLenTotal: "0.9307" },
  { geneTranscript: "NM_001077700.3", parsimonySitesP: "0.0997050147492625", constantSitesP: "0.8023598820059", distinctSitesP: "0.176991150442478", brLenTotal: "0.3962" },
  { geneTranscript: "NM_001078.4", parsimonySitesP: "0.353631032927379", constantSitesP: "0.49255751014885", distinctSitesP: "0.515561569688769", brLenTotal: "1.5617" },
  { geneTranscript: "NM_001079512.4", parsimonySitesP: "0.203442879499218", constantSitesP: "0.693270735524257", distinctSitesP: "0.317683881064163", brLenTotal: "0.6715" },
  { geneTranscript: "NM_001079521.2", parsimonySitesP: "0.132645541635962", constantSitesP: "0.747973470891673", distinctSitesP: "0.224023581429624", brLenTotal: "0.4546" },
  { geneTranscript: "NM_001079862.4", parsimonySitesP: "0.226053639846743", constantSitesP: "0.666666666666667", distinctSitesP: "0.386973180076628", brLenTotal: "0.8554" },
  { geneTranscript: "NM_001080.3", parsimonySitesP: "0.242367601246106", constantSitesP: "0.629906542056075", distinctSitesP: "0.378816199376947", brLenTotal: "1.0085" },
  { geneTranscript: "NM_001080393.2", parsimonySitesP: "0.192626034612491", constantSitesP: "0.673438675696012", distinctSitesP: "0.301730624529722", brLenTotal: "0.7916" },
  { geneTranscript: "NM_001080396.3", parsimonySitesP: "0.147016011644833", constantSitesP: "0.743085880640466", distinctSitesP: "0.245269286754003", brLenTotal: "0.5863" },
  { geneTranscript: "NM_001080395.3", parsimonySitesP: "0.351043182920912", constantSitesP: "0.486171761280932", distinctSitesP: "0.512372634643377", brLenTotal: "1.7688" },
  { geneTranscript: "NM_001080401.2", parsimonySitesP: "0.286046511627907", constantSitesP: "0.52015503875969", distinctSitesP: "0.444186046511628", brLenTotal: "1.1004" },
  { geneTranscript: "NM_001080414.4", parsimonySitesP: "0.244411571334648", constantSitesP: "0.602564102564103", distinctSitesP: "0.304076265614727", brLenTotal: "1.1371" },
  { geneTranscript: "NM_001080451.2", parsimonySitesP: "0.290679304897314", constantSitesP: "0.518167456556082", distinctSitesP: "0.462085308056872", brLenTotal: "1.1417" },
  { geneTranscript: "NM_001080449.3", parsimonySitesP: "0.178616352201258", constantSitesP: "0.690566037735849", distinctSitesP: "0.315408805031447", brLenTotal: "0.6179" },
  { geneTranscript: "NM_001080454.2", parsimonySitesP: "0.289655172413793", constantSitesP: "0.52816091954023", distinctSitesP: "0.463218390804598", brLenTotal: "1.1697" },
  { geneTranscript: "NM_001080465.3", parsimonySitesP: "0.30952380952381", constantSitesP: "0.558441558441558", distinctSitesP: "0.458874458874459", brLenTotal: "1.1048" },
  { geneTranscript: "NM_001080469.2", parsimonySitesP: "0.208955223880597", constantSitesP: "0.660033167495854", distinctSitesP: "0.302929795467109", brLenTotal: "0.9839" },
  { geneTranscript: "NM_001080480.3", parsimonySitesP: "0.208754208754209", constantSitesP: "0.661952861952862", distinctSitesP: "0.351515151515152", brLenTotal: "0.7274" },
  { geneTranscript: "NM_001080482.5", parsimonySitesP: "0.264002732240437", constantSitesP: "0.587090163934426", distinctSitesP: "0.37431693989071", brLenTotal: "1.1328" },
  { geneTranscript: "NM_001080489.3", parsimonySitesP: "0.208333333333333", constantSitesP: "0.629166666666667", distinctSitesP: "0.39375", brLenTotal: "0.7585" },
  { geneTranscript: "NM_001080493.4", parsimonySitesP: "0.446994535519126", constantSitesP: "0.359562841530055", distinctSitesP: "0.618032786885246", brLenTotal: "2.1592" },
  { geneTranscript: "NM_001080495.3", parsimonySitesP: "0.26044474393531", constantSitesP: "0.615902964959569", distinctSitesP: "0.302785265049416", brLenTotal: "1.4013" },
  { geneTranscript: "NM_001080508.3", parsimonySitesP: "0.236683141131247", constantSitesP: "0.660626029654036", distinctSitesP: "0.317408017572762", brLenTotal: "0.7687" },
  { geneTranscript: "NM_001080516.2", parsimonySitesP: "0.200268817204301", constantSitesP: "0.686827956989247", distinctSitesP: "0.336021505376344", brLenTotal: "0.7200" },
  { geneTranscript: "NM_001080518.2", parsimonySitesP: "0.243107769423559", constantSitesP: "0.598997493734336", distinctSitesP: "0.406850459482038", brLenTotal: "0.8550" },
  { geneTranscript: "NM_001080543.2", parsimonySitesP: "0.228232189973615", constantSitesP: "0.667106420404573", distinctSitesP: "0.306948109058927", brLenTotal: "1.0859" },
  { geneTranscript: "NM_001080545.3", parsimonySitesP: "0.174311926605505", constantSitesP: "0.712538226299694", distinctSitesP: "0.318042813455657", brLenTotal: "0.5586" },
  { geneTranscript: "NM_001080826.3", parsimonySitesP: "0.287339971550498", constantSitesP: "0.570412517780939", distinctSitesP: "0.395922238027501", brLenTotal: "1.2056" },
  { geneTranscript: "NM_001080837.4", parsimonySitesP: "0.415789473684211", constantSitesP: "0.359649122807018", distinctSitesP: "0.631578947368421", brLenTotal: "1.4448" },
  { geneTranscript: "NM_001080978.4", parsimonySitesP: "0.473478503629257", constantSitesP: "0.310441094360692", distinctSitesP: "0.690117252931323", brLenTotal: "3.5779" },
  { geneTranscript: "NM_001081442.3", parsimonySitesP: "0.517766497461929", constantSitesP: "0.250423011844332", distinctSitesP: "0.757473209249859", brLenTotal: "3.6642" },
  { geneTranscript: "NM_001081573.3", parsimonySitesP: "0.24190800681431", constantSitesP: "0.598523566155593", distinctSitesP: "0.378194207836457", brLenTotal: "0.7599" },
  { geneTranscript: "NM_001082538.3", parsimonySitesP: "0.263513513513514", constantSitesP: "0.573761261261261", distinctSitesP: "0.426238738738739", brLenTotal: "0.9811" },
  { geneTranscript: "NM_001082971.2", parsimonySitesP: "0.236805555555556", constantSitesP: "0.638194444444444", distinctSitesP: "0.363888888888889", brLenTotal: "1.1203" },
  { geneTranscript: "NM_001083.4", parsimonySitesP: "0.173714285714286", constantSitesP: "0.72647619047619", distinctSitesP: "0.257142857142857", brLenTotal: "0.5899" },
  { geneTranscript: "NM_001083536.2", parsimonySitesP: "0.317701149425287", constantSitesP: "0.541149425287356", distinctSitesP: "0.440919540229885", brLenTotal: "1.4209" },
  { geneTranscript: "NM_001083619.3", parsimonySitesP: "0.136277840694602", constantSitesP: "0.763306908267271", distinctSitesP: "0.178557946394866", brLenTotal: "0.8716" },
  { geneTranscript: "NM_001083926.2", parsimonySitesP: "0.28030303030303", constantSitesP: "0.607142857142857", distinctSitesP: "0.409090909090909", brLenTotal: "0.9919" },
  { geneTranscript: "NM_001085377.2", parsimonySitesP: "0.193326790971541", constantSitesP: "0.712136081125286", distinctSitesP: "0.270199542034675", brLenTotal: "0.8669" },
  { geneTranscript: "NM_001085384.3", parsimonySitesP: "0.450800915331808", constantSitesP: "0.405797101449275", distinctSitesP: "0.558352402745995", brLenTotal: "2.4173" },
  { geneTranscript: "NM_001085400.2", parsimonySitesP: "0.263222632226322", constantSitesP: "0.627306273062731", distinctSitesP: "0.397293972939729", brLenTotal: "1.0397" },
  { geneTranscript: "NM_001085420.2", parsimonySitesP: "0.200492004920049", constantSitesP: "0.639606396063961", distinctSitesP: "0.3690036900369", brLenTotal: "0.7778" },
  { geneTranscript: "NM_001085429.2", parsimonySitesP: "0.308411214953271", constantSitesP: "0.545171339563863", distinctSitesP: "0.504672897196262", brLenTotal: "1.2455" },
  { geneTranscript: "NM_001085451.2", parsimonySitesP: "0.256554307116105", constantSitesP: "0.535580524344569", distinctSitesP: "0.51685393258427", brLenTotal: "1.1032" },
  { geneTranscript: "NM_001085481.3", parsimonySitesP: "0.594666666666667", constantSitesP: "0.197333333333333", distinctSitesP: "0.84", brLenTotal: "2.9571" },
  { geneTranscript: "NM_001085487.3", parsimonySitesP: "0.178743961352657", constantSitesP: "0.694847020933977", distinctSitesP: "0.284219001610306", brLenTotal: "0.5818" },
  { geneTranscript: "NM_001089584.3", parsimonySitesP: "0.337868480725624", constantSitesP: "0.507936507936508", distinctSitesP: "0.528344671201814", brLenTotal: "1.1640" },
  { geneTranscript: "NM_001097615.3", parsimonySitesP: "0.147826086956522", constantSitesP: "0.744927536231884", distinctSitesP: "0.284057971014493", brLenTotal: "0.6711" },
  { geneTranscript: "NM_001098502.2", parsimonySitesP: "0.39906103286385", constantSitesP: "0.323943661971831", distinctSitesP: "0.685446009389671", brLenTotal: "2.5715" },
  { geneTranscript: "NM_001098514.3", parsimonySitesP: "0.332409972299169", constantSitesP: "0.51061865189289", distinctSitesP: "0.521698984302862", brLenTotal: "1.3551" },
  { geneTranscript: "NM_001098612.3", parsimonySitesP: "0.499158249158249", constantSitesP: "0.319023569023569", distinctSitesP: "0.704545454545455", brLenTotal: "3.1973" },
  { geneTranscript: "NM_001098633.4", parsimonySitesP: "0.25", constantSitesP: "0.645833333333333", distinctSitesP: "0.354166666666667", brLenTotal: "1.2142" },
  { geneTranscript: "NM_001098634.2", parsimonySitesP: "0.184935356942102", constantSitesP: "0.681843732433952", distinctSitesP: "0.315907813378302", brLenTotal: "0.9184" },
  { geneTranscript: "NM_001098638.2", parsimonySitesP: "0.209510357815443", constantSitesP: "0.63653483992467", distinctSitesP: "0.326271186440678", brLenTotal: "0.6818" },
  { geneTranscript: "NM_001098668.4", parsimonySitesP: "0.360215053763441", constantSitesP: "0.46505376344086", distinctSitesP: "0.576612903225806", brLenTotal: "1.4409" },
  { geneTranscript: "NM_001098818.4", parsimonySitesP: "0.293627450980392", constantSitesP: "0.580392156862745", distinctSitesP: "0.413235294117647", brLenTotal: "1.3736" },
  { geneTranscript: "NM_001098845.3", parsimonySitesP: "0.204892966360856", constantSitesP: "0.614678899082569", distinctSitesP: "0.363914373088685", brLenTotal: "0.9364" },
  { geneTranscript: "NM_001099219.1", parsimonySitesP: "0.465608465608466", constantSitesP: "0.291005291005291", distinctSitesP: "0.735449735449735", brLenTotal: "2.6357" },
  { geneTranscript: "NM_001099220.3", parsimonySitesP: "0.267750213857998", constantSitesP: "0.579697747362418", distinctSitesP: "0.369261477045908", brLenTotal: "1.1246" },
  { geneTranscript: "NM_001099221.2", parsimonySitesP: "0.331262939958592", constantSitesP: "0.459627329192547", distinctSitesP: "0.583850931677019", brLenTotal: "1.3754" },
  { geneTranscript: "NM_001099271.2", parsimonySitesP: "0.20695652173913", constantSitesP: "0.645797101449275", distinctSitesP: "0.361159420289855", brLenTotal: "0.7822" },
  { geneTranscript: "NM_001099289.3", parsimonySitesP: "0.240362811791383", constantSitesP: "0.626606198034769", distinctSitesP: "0.31708238851096", brLenTotal: "1.1970" },
  { geneTranscript: "NM_001099434.2", parsimonySitesP: "0.289398280802292", constantSitesP: "0.516714422158548", distinctSitesP: "0.485195797516714", brLenTotal: "1.0752" },
  { geneTranscript: "NM_001099.5", parsimonySitesP: "0.246113989637306", constantSitesP: "0.620898100172712", distinctSitesP: "0.392918825561313", brLenTotal: "0.9241" },
  { geneTranscript: "NM_001099673.3", parsimonySitesP: "0.166666666666667", constantSitesP: "0.59", distinctSitesP: "0.46", brLenTotal: "0.7438" },
  { geneTranscript: "NM_001099692.2", parsimonySitesP: "0.536796536796537", constantSitesP: "0.303030303030303", distinctSitesP: "0.746753246753247", brLenTotal: "2.0324" },
  { geneTranscript: "NM_001099783.2", parsimonySitesP: "0.179229480737018", constantSitesP: "0.628140703517588", distinctSitesP: "0.35678391959799", brLenTotal: "0.6577" },
  { geneTranscript: "NM_001099858.2", parsimonySitesP: "0.139350752177356", constantSitesP: "0.676959619952494", distinctSitesP: "0.281076801266825", brLenTotal: "0.5754" },
  { geneTranscript: "NM_001100623.2", parsimonySitesP: "0.207207207207207", constantSitesP: "0.654654654654655", distinctSitesP: "0.352852852852853", brLenTotal: "0.8437" },
  { geneTranscript: "NM_001100829.3", parsimonySitesP: "0.143939393939394", constantSitesP: "0.800505050505051", distinctSitesP: "0.217171717171717", brLenTotal: "0.4962" },
  { geneTranscript: "NM_001101376.3", parsimonySitesP: "0.303482587064677", constantSitesP: "0.480099502487562", distinctSitesP: "0.524875621890547", brLenTotal: "1.0320" },
  { geneTranscript: "NM_001101421.4", parsimonySitesP: "0.201348747591522", constantSitesP: "0.688182402055234", distinctSitesP: "0.283236994219653", brLenTotal: "0.7464" },
  { geneTranscript: "NM_001102562.3", parsimonySitesP: "0.209784411276949", constantSitesP: "0.645936981757877", distinctSitesP: "0.390547263681592", brLenTotal: "0.7676" },
  { geneTranscript: "NM_001102566.2", parsimonySitesP: "0.151960784313725", constantSitesP: "0.740196078431373", distinctSitesP: "0.318627450980392", brLenTotal: "0.5254" },
  { geneTranscript: "NM_001102594.3", parsimonySitesP: "0.254555198285102", constantSitesP: "0.628081457663451", distinctSitesP: "0.35101822079314", brLenTotal: "1.2671" },
  { geneTranscript: "NM_001105576.3", parsimonySitesP: "0.307936507936508", constantSitesP: "0.501587301587302", distinctSitesP: "0.462433862433862", brLenTotal: "1.1594" },
  { geneTranscript: "NM_001105677.2", parsimonySitesP: "0.380597014925373", constantSitesP: "0.498134328358209", distinctSitesP: "0.472636815920398", brLenTotal: "1.7298" },
  { geneTranscript: "NM_001107.5", parsimonySitesP: "0.175084175084175", constantSitesP: "0.696969696969697", distinctSitesP: "0.346801346801347", brLenTotal: "0.6178" },
  { geneTranscript: "NM_001109754.4", parsimonySitesP: "0.22979683972912", constantSitesP: "0.63431151241535", distinctSitesP: "0.293905191873589", brLenTotal: "0.8358" },
  { geneTranscript: "NM_001109977.3", parsimonySitesP: "0.212820512820513", constantSitesP: "0.624679487179487", distinctSitesP: "0.310897435897436", brLenTotal: "0.9966" },
  { geneTranscript: "NM_001110199.3", parsimonySitesP: "0.194486983154671", constantSitesP: "0.667177131189382", distinctSitesP: "0.330270546197039", brLenTotal: "0.7305" },
  { geneTranscript: "NM_001111307.2", parsimonySitesP: "0.22234762979684", constantSitesP: "0.659518434913469", distinctSitesP: "0.316027088036117", brLenTotal: "0.8997" },
  { geneTranscript: "NM_001113324.3", parsimonySitesP: "0.330623306233062", constantSitesP: "0.509485094850949", distinctSitesP: "0.53929539295393", brLenTotal: "1.2874" },
  { geneTranscript: "NM_001113378.2", parsimonySitesP: "0.19578313253012", constantSitesP: "0.668172690763052", distinctSitesP: "0.288152610441767", brLenTotal: "0.6263" },
  { geneTranscript: "NM_001113402.2", parsimonySitesP: "0.169250645994832", constantSitesP: "0.731266149870801", distinctSitesP: "0.266149870801034", brLenTotal: "0.5030" },
  { geneTranscript: "NM_001113525.2", parsimonySitesP: "0.289359391965255", constantSitesP: "0.583061889250814", distinctSitesP: "0.420738327904452", brLenTotal: "1.0983" },
  { geneTranscript: "NM_001114092.2", parsimonySitesP: "0.221564760026298", constantSitesP: "0.614727153188692", distinctSitesP: "0.385930309007232", brLenTotal: "0.7438" },
  { geneTranscript: "NM_001114106.3", parsimonySitesP: "0.218478815722307", constantSitesP: "0.619703930576825", distinctSitesP: "0.370086778968862", brLenTotal: "0.8844" },
  { geneTranscript: "NM_001114120.3", parsimonySitesP: "0.221126181668722", constantSitesP: "0.588573777229758", distinctSitesP: "0.359638306617345", brLenTotal: "0.7932" },
  { geneTranscript: "NM_001114938.3", parsimonySitesP: "0.351554126473741", constantSitesP: "0.452840300107181", distinctSitesP: "0.541264737406217", brLenTotal: "1.2726" },
  { geneTranscript: "NM_001122659.3", parsimonySitesP: "0.193815987933635", constantSitesP: "0.682503770739065", distinctSitesP: "0.295625942684766", brLenTotal: "0.6527" },
  { geneTranscript: "NM_001122752.2", parsimonySitesP: "0.170731707317073", constantSitesP: "0.699186991869919", distinctSitesP: "0.313821138211382", brLenTotal: "0.6903" },
  { geneTranscript: "NM_001122819.3", parsimonySitesP: "0.273994811932555", constantSitesP: "0.544747081712062", distinctSitesP: "0.423476005188067", brLenTotal: "1.2122" },
  { geneTranscript: "NM_001122838.3", parsimonySitesP: "0.162849872773537", constantSitesP: "0.732824427480916", distinctSitesP: "0.263782866836302", brLenTotal: "0.5528" },
  { geneTranscript: "NM_001122853.3", parsimonySitesP: "0.266932270916335", constantSitesP: "0.544488711819389", distinctSitesP: "0.464807436918991", brLenTotal: "1.0898" },
  { geneTranscript: "NM_001123364.3", parsimonySitesP: "0.221311475409836", constantSitesP: "0.624772313296903", distinctSitesP: "0.38615664845173", brLenTotal: "0.8194" },
  { geneTranscript: "NM_001124758.3", parsimonySitesP: "0.197935640558591", constantSitesP: "0.680631451123254", distinctSitesP: "0.297510625379478", brLenTotal: "0.8192" },
  { geneTranscript: "NM_001126111.3", parsimonySitesP: "0.134790528233151", constantSitesP: "0.743169398907104", distinctSitesP: "0.226472374013358", brLenTotal: "0.4659" },
  { geneTranscript: "NM_001126328.3", parsimonySitesP: "0.261904761904762", constantSitesP: "0.59478021978022", distinctSitesP: "0.412087912087912", brLenTotal: "1.4731" },
  { geneTranscript: "NM_001127222.2", parsimonySitesP: "0.175179569034318", constantSitesP: "0.729582335727587", distinctSitesP: "0.230513434424049", brLenTotal: "0.7905" },
  { geneTranscript: "NM_001127453.2", parsimonySitesP: "0.286962365591398", constantSitesP: "0.548387096774194", distinctSitesP: "0.481854838709677", brLenTotal: "1.1781" },
  { geneTranscript: "NM_001127649.3", parsimonySitesP: "0.290710382513661", constantSitesP: "0.553005464480874", distinctSitesP: "0.430601092896175", brLenTotal: "1.0684" },
  { geneTranscript: "NM_001127895.2", parsimonySitesP: "0.263364779874214", constantSitesP: "0.60377358490566", distinctSitesP: "0.410377358490566", brLenTotal: "1.1751" },
  { geneTranscript: "NM_001128174.3", parsimonySitesP: "0.194701170671596", constantSitesP: "0.711645101663586", distinctSitesP: "0.275415896487985", brLenTotal: "0.6323" },
  { geneTranscript: "NM_001128423.2", parsimonySitesP: "0.297619047619048", constantSitesP: "0.530612244897959", distinctSitesP: "0.481292517006803", brLenTotal: "1.0525" },
  { geneTranscript: "NM_001128596.3", parsimonySitesP: "0.176190476190476", constantSitesP: "0.687074829931973", distinctSitesP: "0.297959183673469", brLenTotal: "0.5736" },
  { geneTranscript: "NM_001128626.2", parsimonySitesP: "0.19620811287478", constantSitesP: "0.675925925925926", distinctSitesP: "0.320546737213404", brLenTotal: "0.7057" },
  { geneTranscript: "NM_001128917.2", parsimonySitesP: "0.18005540166205", constantSitesP: "0.709141274238227", distinctSitesP: "0.260387811634349", brLenTotal: "0.6383" },
  { geneTranscript: "NM_001128926.4", parsimonySitesP: "0.282222222222222", constantSitesP: "0.526666666666667", distinctSitesP: "0.457777777777778", brLenTotal: "1.0270" },
  { geneTranscript: "NM_001128928.2", parsimonySitesP: "0.237259816207185", constantSitesP: "0.616541353383459", distinctSitesP: "0.377610693400167", brLenTotal: "0.8686" },
  { geneTranscript: "NM_001129.5", parsimonySitesP: "0.219055843408175", constantSitesP: "0.659182498560737", distinctSitesP: "0.302533103051238", brLenTotal: "0.9979" },
  { geneTranscript: "NM_001130145.3", parsimonySitesP: "0.164021164021164", constantSitesP: "0.681216931216931", distinctSitesP: "0.276455026455026", brLenTotal: "0.6983" },
  { geneTranscript: "NM_001130514.3", parsimonySitesP: "0.330769230769231", constantSitesP: "0.435897435897436", distinctSitesP: "0.605128205128205", brLenTotal: "1.4271" },
  { geneTranscript: "NM_001130682.3", parsimonySitesP: "0.186473429951691", constantSitesP: "0.680193236714976", distinctSitesP: "0.271980676328502", brLenTotal: "1.2983" },
  { geneTranscript: "NM_001130864.2", parsimonySitesP: "0.113465783664459", constantSitesP: "0.685209713024283", distinctSitesP: "0.245474613686534", brLenTotal: "0.5069" },
  { geneTranscript: "NM_001130917.3", parsimonySitesP: "0.489993098688751", constantSitesP: "0.296756383712905", distinctSitesP: "0.685990338164251", brLenTotal: "3.6726" },
  { geneTranscript: "NM_001130919.3", parsimonySitesP: "0.222707423580786", constantSitesP: "0.606986899563319", distinctSitesP: "0.390101892285298", brLenTotal: "0.8354" },
  { geneTranscript: "NM_001131007.2", parsimonySitesP: "0.191925465838509", constantSitesP: "0.662111801242236", distinctSitesP: "0.305383022774327", brLenTotal: "0.7942" },
  { geneTranscript: "NM_001134442.3", parsimonySitesP: "0.240808823529412", constantSitesP: "0.490808823529412", distinctSitesP: "0.395833333333333", brLenTotal: "1.1905" },
  { geneTranscript: "NM_001134665.3", parsimonySitesP: "0.246804326450344", constantSitesP: "0.56243854473943", distinctSitesP: "0.467059980334317", brLenTotal: "0.9605" },
  { geneTranscript: "NM_001134734.2", parsimonySitesP: "0.318283166109253", constantSitesP: "0.503901895206243", distinctSitesP: "0.454292084726867", brLenTotal: "1.1390" },
  { geneTranscript: "NM_001135022.2", parsimonySitesP: "0.237095363079615", constantSitesP: "0.598425196850394", distinctSitesP: "0.400699912510936", brLenTotal: "0.8723" },
  { geneTranscript: "NM_001135146.2", parsimonySitesP: "0.221014492753623", constantSitesP: "0.678985507246377", distinctSitesP: "0.342753623188406", brLenTotal: "0.7798" },
  { geneTranscript: "NM_001135217.2", parsimonySitesP: "0.253644314868805", constantSitesP: "0.608357628765792", distinctSitesP: "0.389698736637512", brLenTotal: "0.8262" },
  { geneTranscript: "NM_001135570.3", parsimonySitesP: "0.230046948356808", constantSitesP: "0.652582159624413", distinctSitesP: "0.394366197183099", brLenTotal: "0.8214" },
  { geneTranscript: "NM_001135635.2", parsimonySitesP: "0.154721274175199", constantSitesP: "0.729237770193402", distinctSitesP: "0.26962457337884", brLenTotal: "0.6321" },
  { geneTranscript: "NM_001135674.2", parsimonySitesP: "0.218068535825545", constantSitesP: "0.623052959501558", distinctSitesP: "0.376947040498442", brLenTotal: "0.9020" },
  { geneTranscript: "NM_001136020.3", parsimonySitesP: "0.16632160110421", constantSitesP: "0.710144927536232", distinctSitesP: "0.285714285714286", brLenTotal: "0.6687" },
  { geneTranscript: "NM_001136035.4", parsimonySitesP: "0.287303995953465", constantSitesP: "0.575113808801214", distinctSitesP: "0.386444107233182", brLenTotal: "1.0565" },
  { geneTranscript: "NM_001136046.3", parsimonySitesP: "0.24303683737646", constantSitesP: "0.588050314465409", distinctSitesP: "0.365229110512129", brLenTotal: "0.8446" },
  { geneTranscript: "NM_001136053.4", parsimonySitesP: "0.23503127792672", constantSitesP: "0.638963360142985", distinctSitesP: "0.366398570151921", brLenTotal: "1.0872" },
  { geneTranscript: "NM_001136116.3", parsimonySitesP: "0.36234458259325", constantSitesP: "0.487270574304322", distinctSitesP: "0.502072232089994", brLenTotal: "1.7390" },
  { geneTranscript: "NM_001136152.1", parsimonySitesP: "0.317829457364341", constantSitesP: "0.544186046511628", distinctSitesP: "0.496124031007752", brLenTotal: "1.4515" },
  { geneTranscript: "NM_001136156.2", parsimonySitesP: "0.247289261979713", constantSitesP: "0.640783490731025", distinctSitesP: "0.330535152151102", brLenTotal: "0.9342" },
  { geneTranscript: "NM_001136191.3", parsimonySitesP: "0.27497062279671", constantSitesP: "0.599686643164904", distinctSitesP: "0.384253819036428", brLenTotal: "1.4265" },
  { geneTranscript: "NM_001136472.2", parsimonySitesP: "0.267080745341615", constantSitesP: "0.631469979296066", distinctSitesP: "0.380952380952381", brLenTotal: "1.1638" },
  { geneTranscript: "NM_001136501.3", parsimonySitesP: "0.511511511511512", constantSitesP: "0.332332332332332", distinctSitesP: "0.665165165165165", brLenTotal: "2.6319" },
  { geneTranscript: "NM_001137550.2", parsimonySitesP: "0.1890625", constantSitesP: "0.704166666666667", distinctSitesP: "0.2984375", brLenTotal: "0.7458" },
  { geneTranscript: "NM_001137601.3", parsimonySitesP: "0.308846761453397", constantSitesP: "0.46129541864139", distinctSitesP: "0.459715639810427", brLenTotal: "1.5282" },
  { geneTranscript: "NM_001138.2", parsimonySitesP: "0.267676767676768", constantSitesP: "0.580808080808081", distinctSitesP: "0.441919191919192", brLenTotal: "0.8745" },
  { geneTranscript: "NM_001142276.2", parsimonySitesP: "0.181091877496671", constantSitesP: "0.710164225477142", distinctSitesP: "0.261429205503773", brLenTotal: "0.7661" },
  { geneTranscript: "NM_001142286.2", parsimonySitesP: "0.15948670944088", constantSitesP: "0.748548732050107", distinctSitesP: "0.231591811793462", brLenTotal: "0.5230" },
  { geneTranscript: "NM_001142405.2", parsimonySitesP: "0.186046511627907", constantSitesP: "0.703488372093023", distinctSitesP: "0.323643410852713", brLenTotal: "0.6198" },
  { geneTranscript: "NM_001142416.2", parsimonySitesP: "0.254273504273504", constantSitesP: "0.583333333333333", distinctSitesP: "0.418803418803419", brLenTotal: "1.0169" },
  { geneTranscript: "NM_001142524.2", parsimonySitesP: "0.230956733698964", constantSitesP: "0.598415600243754", distinctSitesP: "0.369896404631322", brLenTotal: "0.7347" },
  { geneTranscript: "NM_001142616.3", parsimonySitesP: "0.118390804597701", constantSitesP: "0.75948275862069", distinctSitesP: "0.210632183908046", brLenTotal: "0.3963" },
  { geneTranscript: "NM_001142684.2", parsimonySitesP: "0.25909317389138", constantSitesP: "0.587443946188341", distinctSitesP: "0.400597907324365", brLenTotal: "1.1747" },
  { geneTranscript: "NM_001142733.3", parsimonySitesP: "0.21351504826803", constantSitesP: "0.622941510505395", distinctSitesP: "0.354344122657581", brLenTotal: "0.6967" },
  { geneTranscript: "NM_001142782.2", parsimonySitesP: "0.174656763448121", constantSitesP: "0.691874859329282", distinctSitesP: "0.252757146072474", brLenTotal: "0.6852" },
  { geneTranscript: "NM_001142807.4", parsimonySitesP: "0.281034482758621", constantSitesP: "0.5", distinctSitesP: "0.490804597701149", brLenTotal: "1.0744" },
  { geneTranscript: "NM_001143674.4", parsimonySitesP: "0.154855643044619", constantSitesP: "0.706036745406824", distinctSitesP: "0.333333333333333", brLenTotal: "0.5980" },
  { geneTranscript: "NM_001143688.3", parsimonySitesP: "0.19797596457938", constantSitesP: "0.659709044908286", distinctSitesP: "0.317836812144213", brLenTotal: "0.8488" },
  { geneTranscript: "NM_001143820.2", parsimonySitesP: "0.15807560137457", constantSitesP: "0.74914089347079", distinctSitesP: "0.230927835051546", brLenTotal: "0.6326" },
  { geneTranscript: "NM_001143835.2", parsimonySitesP: "0.188093405183474", constantSitesP: "0.695663330767257", distinctSitesP: "0.245060302797023", brLenTotal: "0.8051" },
  { geneTranscript: "NM_001143852.2", parsimonySitesP: "0.254350736278447", constantSitesP: "0.606425702811245", distinctSitesP: "0.372155287817938", brLenTotal: "0.9145" },
  { geneTranscript: "NM_001143992.2", parsimonySitesP: "0.24330900243309", constantSitesP: "0.608272506082725", distinctSitesP: "0.391727493917275", brLenTotal: "0.8319" },
  { geneTranscript: "NM_001144013.2", parsimonySitesP: "0.177284793325749", constantSitesP: "0.679560106181267", distinctSitesP: "0.281190747061054", brLenTotal: "0.6179" },
  { geneTranscript: "NM_001144060.2", parsimonySitesP: "0.278331257783313", constantSitesP: "0.561021170610212", distinctSitesP: "0.389165628891656", brLenTotal: "1.0813" },
  { geneTranscript: "NM_001144072.2", parsimonySitesP: "0.160852713178295", constantSitesP: "0.73546511627907", distinctSitesP: "0.258720930232558", brLenTotal: "0.5626" },
  { geneTranscript: "NM_001144831.2", parsimonySitesP: "0.162764771460424", constantSitesP: "0.731326644370123", distinctSitesP: "0.258639910813824", brLenTotal: "0.5838" },
  { geneTranscript: "NM_001144871.2", parsimonySitesP: "0.226666666666667", constantSitesP: "0.605", distinctSitesP: "0.375", brLenTotal: "1.0954" },
  { geneTranscript: "NM_001144872.3", parsimonySitesP: "0.340909090909091", constantSitesP: "0.463203463203463", distinctSitesP: "0.554112554112554", brLenTotal: "1.3496" },
  { geneTranscript: "NM_001144936.2", parsimonySitesP: "0.154867256637168", constantSitesP: "0.712389380530973", distinctSitesP: "0.284660766961652", brLenTotal: "0.6804" },
  { geneTranscript: "NM_001144937.3", parsimonySitesP: "0.204183719872669", constantSitesP: "0.635288767621646", distinctSitesP: "0.331969076853115", brLenTotal: "0.8706" },
  { geneTranscript: "NM_001144956.3", parsimonySitesP: "0.304347826086957", constantSitesP: "0.557971014492754", distinctSitesP: "0.456521739130435", brLenTotal: "1.0568" },
  { geneTranscript: "NM_001144958.2", parsimonySitesP: "0.265389876880985", constantSitesP: "0.581395348837209", distinctSitesP: "0.389876880984952", brLenTotal: "1.0157" },
  { geneTranscript: "NM_001144960.3", parsimonySitesP: "0.240196078431373", constantSitesP: "0.571691176470588", distinctSitesP: "0.401348039215686", brLenTotal: "0.8311" },
  { geneTranscript: "NM_001144978.3", parsimonySitesP: "0.23342939481268", constantSitesP: "0.609990393852065", distinctSitesP: "0.408261287223823", brLenTotal: "0.9893" },
  { geneTranscript: "NM_001145018.3", parsimonySitesP: "0.444444444444444", constantSitesP: "0.274603174603175", distinctSitesP: "0.728571428571429", brLenTotal: "1.7255" },
  { geneTranscript: "NM_001145026.2", parsimonySitesP: "0.174133681310715", constantSitesP: "0.68595041322314", distinctSitesP: "0.284181528200667", brLenTotal: "0.6202" },
  { geneTranscript: "NM_001145059.2", parsimonySitesP: "0.308558558558559", constantSitesP: "0.490990990990991", distinctSitesP: "0.506756756756757", brLenTotal: "1.1549" },
  { geneTranscript: "NM_001145165.2", parsimonySitesP: "0.298013245033113", constantSitesP: "0.586092715231788", distinctSitesP: "0.428256070640177", brLenTotal: "1.5461" },
  { geneTranscript: "NM_001145176.2", parsimonySitesP: "0.184634448574969", constantSitesP: "0.702602230483271", distinctSitesP: "0.285006195786865", brLenTotal: "0.8240" },
  { geneTranscript: "NM_001145263.2", parsimonySitesP: "0.296959826275787", constantSitesP: "0.506514657980456", distinctSitesP: "0.471226927252986", brLenTotal: "1.1680" },
  { geneTranscript: "NM_001145268.2", parsimonySitesP: "0.244897959183673", constantSitesP: "0.579931972789116", distinctSitesP: "0.402210884353741", brLenTotal: "0.7978" },
  { geneTranscript: "NM_001145290.2", parsimonySitesP: "0.230871590153027", constantSitesP: "0.666001330671989", distinctSitesP: "0.325349301397206", brLenTotal: "0.9636" },
  { geneTranscript: "NM_001145373.3", parsimonySitesP: "0.25017325017325", constantSitesP: "0.615384615384615", distinctSitesP: "0.413721413721414", brLenTotal: "0.9060" },
  { geneTranscript: "NM_001145374.2", parsimonySitesP: "0.272030651340996", constantSitesP: "0.587484035759898", distinctSitesP: "0.418901660280971", brLenTotal: "1.0501" },
  { geneTranscript: "NM_001145418.2", parsimonySitesP: "0.183259438398495", constantSitesP: "0.718124412199382", distinctSitesP: "0.224774956334811", brLenTotal: "0.7260" },
  { geneTranscript: "NM_001145432.3", parsimonySitesP: "0.18407960199005", constantSitesP: "0.691542288557214", distinctSitesP: "0.3681592039801", brLenTotal: "0.5310" },
  { geneTranscript: "NM_001145440.3", parsimonySitesP: "0.234530938123752", constantSitesP: "0.63872255489022", distinctSitesP: "0.348802395209581", brLenTotal: "0.8661" },
  { geneTranscript: "NM_001145450.3", parsimonySitesP: "0.181015452538631", constantSitesP: "0.713024282560706", distinctSitesP: "0.322295805739514", brLenTotal: "0.5848" },
  { geneTranscript: "NM_001145636.2", parsimonySitesP: "0.231818181818182", constantSitesP: "0.634090909090909", distinctSitesP: "0.359090909090909", brLenTotal: "0.8025" },
  { geneTranscript: "NM_001145720.2", parsimonySitesP: "0.214141414141414", constantSitesP: "0.671380471380471", distinctSitesP: "0.303703703703704", brLenTotal: "0.9117" },
  { geneTranscript: "NM_001145785.2", parsimonySitesP: "0.26268115942029", constantSitesP: "0.611413043478261", distinctSitesP: "0.393115942028986", brLenTotal: "1.2056" },
  { geneTranscript: "NM_001145860.2", parsimonySitesP: "0.222330729166667", constantSitesP: "0.627604166666667", distinctSitesP: "0.350911458333333", brLenTotal: "0.8291" },
  { geneTranscript: "NM_001146033.1", parsimonySitesP: "0.415335463258786", constantSitesP: "0.389776357827476", distinctSitesP: "0.594249201277955", brLenTotal: "2.6515" },
  { geneTranscript: "NM_001146312.3", parsimonySitesP: "0.252535496957404", constantSitesP: "0.613928329952671", distinctSitesP: "0.369506423258959", brLenTotal: "0.9387" },
  { geneTranscript: "NM_001146336.2", parsimonySitesP: "0.249626307922272", constantSitesP: "0.579970104633782", distinctSitesP: "0.415545590433483", brLenTotal: "1.0664" },
  { geneTranscript: "NM_001146339.2", parsimonySitesP: "0.237426900584795", constantSitesP: "0.588304093567251", distinctSitesP: "0.421052631578947", brLenTotal: "1.2272" },
  { geneTranscript: "NM_001148.6", parsimonySitesP: "0.233510235026535", constantSitesP: "0.648555302838851", distinctSitesP: "0.300143206132592", brLenTotal: "0.9728" },
  { geneTranscript: "NM_001154.4", parsimonySitesP: "0.159375", constantSitesP: "0.714583333333333", distinctSitesP: "0.2625", brLenTotal: "0.5435" },
  { geneTranscript: "NM_001159377.2", parsimonySitesP: "0.316797214969539", constantSitesP: "0.521322889469104", distinctSitesP: "0.472584856396867", brLenTotal: "1.5488" },
  { geneTranscript: "NM_001159.4", parsimonySitesP: "0.227204783258595", constantSitesP: "0.626806178375685", distinctSitesP: "0.34304932735426", brLenTotal: "0.9201" },
  { geneTranscript: "NM_001160148.2", parsimonySitesP: "0.185555555555556", constantSitesP: "0.697777777777778", distinctSitesP: "0.29037037037037", brLenTotal: "0.6065" },
  { geneTranscript: "NM_001160372.4", parsimonySitesP: "0.205284552845528", constantSitesP: "0.693379790940767", distinctSitesP: "0.274099883855981", brLenTotal: "0.9287" },
  { geneTranscript: "NM_001163435.3", parsimonySitesP: "0.185516983949235", constantSitesP: "0.708473310936917", distinctSitesP: "0.280701754385965", brLenTotal: "0.6363" },
  { geneTranscript: "NM_001163438.2", parsimonySitesP: "0.469879518072289", constantSitesP: "0.317269076305221", distinctSitesP: "0.718875502008032", brLenTotal: "2.3174" },
  { geneTranscript: "NM_001163692.2", parsimonySitesP: "0.309711286089239", constantSitesP: "0.52405949256343", distinctSitesP: "0.477690288713911", brLenTotal: "1.2631" },
  { geneTranscript: "NM_001163922.3", parsimonySitesP: "0.309880815071126", constantSitesP: "0.499807766243752", distinctSitesP: "0.458669742406767", brLenTotal: "1.1865" },
  { geneTranscript: "NM_001163941.2", parsimonySitesP: "0.218774860779634", constantSitesP: "0.628215327499337", distinctSitesP: "0.330151153540175", brLenTotal: "0.8682" },
  { geneTranscript: "NM_001164442.2", parsimonySitesP: "0.177083333333333", constantSitesP: "0.641666666666667", distinctSitesP: "0.358333333333333", brLenTotal: "0.5665" },
  { geneTranscript: "NM_001164463.1", parsimonySitesP: "0.178659112370161", constantSitesP: "0.671199244570349", distinctSitesP: "0.28876298394712", brLenTotal: "0.6390" },
  { geneTranscript: "NM_001165978.3", parsimonySitesP: "0.308952837729816", constantSitesP: "0.533573141486811", distinctSitesP: "0.442446043165468", brLenTotal: "1.2634" },
  { geneTranscript: "NM_001166412.2", parsimonySitesP: "0.256352765321375", constantSitesP: "0.643497757847534", distinctSitesP: "0.350523168908819", brLenTotal: "1.5371" },
  { geneTranscript: "NM_001167912.2", parsimonySitesP: "0.19047619047619", constantSitesP: "0.663465386154462", distinctSitesP: "0.27891156462585", brLenTotal: "0.6386" },
  { geneTranscript: "NM_001168243.4", parsimonySitesP: "0.245614035087719", constantSitesP: "0.617543859649123", distinctSitesP: "0.428070175438596", brLenTotal: "0.9272" },
  { geneTranscript: "NM_001168.3", parsimonySitesP: "0.147887323943662", constantSitesP: "0.746478873239437", distinctSitesP: "0.255868544600939", brLenTotal: "0.5178" },
  { geneTranscript: "NM_001168618.2", parsimonySitesP: "0.175438596491228", constantSitesP: "0.68969298245614", distinctSitesP: "0.31359649122807", brLenTotal: "0.6965" },
  { geneTranscript: "NM_001170535.3", parsimonySitesP: "0.281569965870307", constantSitesP: "0.592718998862344", distinctSitesP: "0.401592718998862", brLenTotal: "1.3146" },
  { geneTranscript: "NM_001170543.2", parsimonySitesP: "0.224913494809689", constantSitesP: "0.677047289504037", distinctSitesP: "0.335640138408304", brLenTotal: "1.0762" },
  { geneTranscript: "NM_001170795.4", parsimonySitesP: "0.2372634643377", constantSitesP: "0.580786026200873", distinctSitesP: "0.433770014556041", brLenTotal: "0.8160" },
  { geneTranscript: "NM_001171155.2", parsimonySitesP: "0.278538812785388", constantSitesP: "0.593607305936073", distinctSitesP: "0.470319634703196", brLenTotal: "0.8517" },
  { geneTranscript: "NM_001171251.3", parsimonySitesP: "0.30030959752322", constantSitesP: "0.495872033023736", distinctSitesP: "0.493292053663571", brLenTotal: "1.2235" },
  { geneTranscript: "NM_001171797.2", parsimonySitesP: "0.147286821705426", constantSitesP: "0.755813953488372", distinctSitesP: "0.290697674418605", brLenTotal: "0.4201" },
  { geneTranscript: "NM_001171876.2", parsimonySitesP: "0.156843156843157", constantSitesP: "0.722943722943723", distinctSitesP: "0.252081252081252", brLenTotal: "0.5200" },
  { geneTranscript: "NM_001172223.3", parsimonySitesP: "0.232587064676617", constantSitesP: "0.656716417910448", distinctSitesP: "0.340796019900498", brLenTotal: "1.1811" },
  { geneTranscript: "NM_001172690.2", parsimonySitesP: "0.455639097744361", constantSitesP: "0.35187969924812", distinctSitesP: "0.645614035087719", brLenTotal: "2.3522" },
  { geneTranscript: "NM_001172700.2", parsimonySitesP: "0.370892018779343", constantSitesP: "0.419014084507042", distinctSitesP: "0.537558685446009", brLenTotal: "1.3268" },
  { geneTranscript: "NM_001172774.2", parsimonySitesP: "0.179236499068901", constantSitesP: "0.715083798882682", distinctSitesP: "0.269553072625698", brLenTotal: "0.6905" },
  { geneTranscript: "NM_001173990.3", parsimonySitesP: "0.195402298850575", constantSitesP: "0.67816091954023", distinctSitesP: "0.32183908045977", brLenTotal: "0.5635" },
  { geneTranscript: "NM_001174084.2", parsimonySitesP: "0.245217391304348", constantSitesP: "0.596521739130435", distinctSitesP: "0.360579710144928", brLenTotal: "0.9165" },
  { geneTranscript: "NM_001174150.2", parsimonySitesP: "0.217289719626168", constantSitesP: "0.633177570093458", distinctSitesP: "0.366043613707165", brLenTotal: "0.7561" },
  { geneTranscript: "NM_001175.7", parsimonySitesP: "0.207296849087894", constantSitesP: "0.713101160862355", distinctSitesP: "0.293532338308458", brLenTotal: "0.8656" },
  { geneTranscript: "NM_001177306.2", parsimonySitesP: "0.177115450496745", constantSitesP: "0.700924974306269", distinctSitesP: "0.293593696471394", brLenTotal: "0.6751" },
  { geneTranscript: "NM_001177382.2", parsimonySitesP: "0.151192778852353", constantSitesP: "0.729529335912315", distinctSitesP: "0.239845261121857", brLenTotal: "0.5506" },
  { geneTranscript: "NM_001178089.3", parsimonySitesP: "0.321200510855683", constantSitesP: "0.544061302681992", distinctSitesP: "0.418901660280971", brLenTotal: "1.2028" },
  { geneTranscript: "NM_001178126.2", parsimonySitesP: "0.517133956386293", constantSitesP: "0.257009345794393", distinctSitesP: "0.775700934579439", brLenTotal: "2.9701" },
  { geneTranscript: "NM_001184785.2", parsimonySitesP: "0.184528208918453", constantSitesP: "0.726287262872629", distinctSitesP: "0.237989652623799", brLenTotal: "0.7106" },
  { geneTranscript: "NM_001184924.2", parsimonySitesP: "0.45014880952381", constantSitesP: "0.37202380952381", distinctSitesP: "0.587797619047619", brLenTotal: "2.0912" },
  { geneTranscript: "NM_001189.4", parsimonySitesP: "0.226226226226226", constantSitesP: "0.622622622622623", distinctSitesP: "0.366366366366366", brLenTotal: "0.8220" },
  { geneTranscript: "NM_001190462.2", parsimonySitesP: "0.30952380952381", constantSitesP: "0.497354497354497", distinctSitesP: "0.552910052910053", brLenTotal: "1.2915" },
  { geneTranscript: "NM_001190972.2", parsimonySitesP: "0.108262108262108", constantSitesP: "0.800569800569801", distinctSitesP: "0.222222222222222", brLenTotal: "0.2853" },
  { geneTranscript: "NM_001193282.4", parsimonySitesP: "0.320949432404541", constantSitesP: "0.519607843137255", distinctSitesP: "0.469556243550052", brLenTotal: "1.2852" },
  { geneTranscript: "NM_001193308.2", parsimonySitesP: "0.242586002372479", constantSitesP: "0.558718861209964", distinctSitesP: "0.373072360616845", brLenTotal: "1.0290" },
  { geneTranscript: "NM_001193457.2", parsimonySitesP: "0.145359019264448", constantSitesP: "0.764156450671337", distinctSitesP: "0.207822533566842", brLenTotal: "0.6946" },
  { geneTranscript: "NM_001193623.2", parsimonySitesP: "0.385304659498208", constantSitesP: "0.415770609318996", distinctSitesP: "0.627240143369176", brLenTotal: "1.5642" },
  { geneTranscript: "NM_001193628.2", parsimonySitesP: "0.257062146892655", constantSitesP: "0.570621468926554", distinctSitesP: "0.435028248587571", brLenTotal: "0.8844" },
  { geneTranscript: "NM_001195144.2", parsimonySitesP: "0.154414232964082", constantSitesP: "0.733467606579389", distinctSitesP: "0.209130580731789", brLenTotal: "0.7395" },
  { geneTranscript: "NM_001195156.2", parsimonySitesP: "0.248917748917749", constantSitesP: "0.536796536796537", distinctSitesP: "0.46969696969697", brLenTotal: "0.8833" },
  { geneTranscript: "NM_001195220.2", parsimonySitesP: "0.328449328449328", constantSitesP: "0.526862026862027", distinctSitesP: "0.453601953601954", brLenTotal: "1.5982" },
  { geneTranscript: "NM_001195263.2", parsimonySitesP: "0.259438528557599", constantSitesP: "0.571151984511133", distinctSitesP: "0.381413359148112", brLenTotal: "1.1956" },
  { geneTranscript: "NM_001195535.4", parsimonySitesP: "0.20679012345679", constantSitesP: "0.666666666666667", distinctSitesP: "0.364197530864198", brLenTotal: "0.6442" },
  { geneTranscript: "NM_001195581.2", parsimonySitesP: "0.256578947368421", constantSitesP: "0.620614035087719", distinctSitesP: "0.401315789473684", brLenTotal: "0.9595" },
  { geneTranscript: "NM_001195753.2", parsimonySitesP: "0.249651324965132", constantSitesP: "0.591352859135286", distinctSitesP: "0.442119944211994", brLenTotal: "0.9672" },
  { geneTranscript: "NM_001195833.2", parsimonySitesP: "0.339222614840989", constantSitesP: "0.479387514723204", distinctSitesP: "0.502944640753828", brLenTotal: "1.2448" },
  { geneTranscript: "NM_001197026.2", parsimonySitesP: "0.138086062941554", constantSitesP: "0.754656390494541", distinctSitesP: "0.245343609505459", brLenTotal: "0.4792" },
  { geneTranscript: "NM_001197294.2", parsimonySitesP: "0.198343079922027", constantSitesP: "0.707115009746589", distinctSitesP: "0.272904483430799", brLenTotal: "1.0447" },
  { geneTranscript: "NM_001198533.2", parsimonySitesP: "0.145475372279496", constantSitesP: "0.720885834287896", distinctSitesP: "0.25811378388698", brLenTotal: "0.5115" },
  { geneTranscript: "NM_001198754.2", parsimonySitesP: "0.231884057971014", constantSitesP: "0.632850241545894", distinctSitesP: "0.386473429951691", brLenTotal: "0.7752" },
  { geneTranscript: "NM_001198956.2", parsimonySitesP: "0.13459516298633", constantSitesP: "0.740273396424816", distinctSitesP: "0.208201892744479", brLenTotal: "0.4959" },
  { geneTranscript: "NM_001199097.2", parsimonySitesP: "0.265046296296296", constantSitesP: "0.588831018518519", distinctSitesP: "0.400462962962963", brLenTotal: "1.2804" },
  { geneTranscript: "NM_001199135.3", parsimonySitesP: "0.149803921568627", constantSitesP: "0.699607843137255", distinctSitesP: "0.272941176470588", brLenTotal: "0.5063" },
  { geneTranscript: "NM_001199165.4", parsimonySitesP: "0.159511343804538", constantSitesP: "0.71413612565445", distinctSitesP: "0.254799301919721", brLenTotal: "0.5552" },
  { geneTranscript: "NM_001199206.4", parsimonySitesP: "0.317333333333333", constantSitesP: "0.502666666666667", distinctSitesP: "0.5", brLenTotal: "1.3948" },
  { geneTranscript: "NM_001199280.2", parsimonySitesP: "0.15370705244123", constantSitesP: "0.682338758288125", distinctSitesP: "0.238095238095238", brLenTotal: "1.1434" },
  { geneTranscript: "NM_001199298.2", parsimonySitesP: "0.193324061196106", constantSitesP: "0.616133518776078", distinctSitesP: "0.355586462679648", brLenTotal: "0.7058" },
  { geneTranscript: "NM_001199324.2", parsimonySitesP: "0.333782569631626", constantSitesP: "0.460916442048518", distinctSitesP: "0.525606469002695", brLenTotal: "1.5487" },
  { geneTranscript: "NM_001200016.2", parsimonySitesP: "0.289044289044289", constantSitesP: "0.532634032634033", distinctSitesP: "0.463869463869464", brLenTotal: "1.0404" },
  { geneTranscript: "NM_001201363.2", parsimonySitesP: "0.0666666666666667", constantSitesP: "0.807692307692308", distinctSitesP: "0.225641025641026", brLenTotal: "0.2819" },
  { geneTranscript: "NM_001204424.2", parsimonySitesP: "0.138775510204082", constantSitesP: "0.773469387755102", distinctSitesP: "0.214965986394558", brLenTotal: "0.5787" },
  { geneTranscript: "NM_001206427.2", parsimonySitesP: "0.149425287356322", constantSitesP: "0.591954022988506", distinctSitesP: "0.540229885057471", brLenTotal: "0.7515" },
  { geneTranscript: "NM_001206671.4", parsimonySitesP: "0.208672086720867", constantSitesP: "0.628726287262873", distinctSitesP: "0.345076784101174", brLenTotal: "0.6923" },
  { geneTranscript: "NM_001206979.2", parsimonySitesP: "0.170168067226891", constantSitesP: "0.717787114845938", distinctSitesP: "0.278011204481793", brLenTotal: "0.6472" },
  { geneTranscript: "NM_001207020.3", parsimonySitesP: "0.322418136020151", constantSitesP: "0.525608732157851", distinctSitesP: "0.488664987405542", brLenTotal: "1.1947" },
  { geneTranscript: "NM_001211.6", parsimonySitesP: "0.186031746031746", constantSitesP: "0.683809523809524", distinctSitesP: "0.282539682539683", brLenTotal: "0.6010" },
  { geneTranscript: "NM_001212.4", parsimonySitesP: "0.24822695035461", constantSitesP: "0.574468085106383", distinctSitesP: "0.431442080378251", brLenTotal: "0.9207" },
  { geneTranscript: "NM_001218.5", parsimonySitesP: "0.273069679849341", constantSitesP: "0.592278719397363", distinctSitesP: "0.404896421845574", brLenTotal: "1.2136" },
  { geneTranscript: "NM_001216.3", parsimonySitesP: "0.294843863471314", constantSitesP: "0.554829339143065", distinctSitesP: "0.424110384894699", brLenTotal: "1.0203" },
  { geneTranscript: "NM_001242312.2", parsimonySitesP: "0.305860805860806", constantSitesP: "0.522588522588523", distinctSitesP: "0.46031746031746", brLenTotal: "1.2712" },
  { geneTranscript: "NM_001239.4", parsimonySitesP: "0.12796697626419", constantSitesP: "0.780185758513932", distinctSitesP: "0.223942208462332", brLenTotal: "0.3850" },
  { geneTranscript: "NM_001242472.2", parsimonySitesP: "0.33948087431694", constantSitesP: "0.441256830601093", distinctSitesP: "0.496584699453552", brLenTotal: "1.5242" },
  { geneTranscript: "NM_001242.5", parsimonySitesP: "0.321794871794872", constantSitesP: "0.493589743589744", distinctSitesP: "0.498717948717949", brLenTotal: "1.3196" },
  { geneTranscript: "NM_001242699.2", parsimonySitesP: "0.2368", constantSitesP: "0.610666666666667", distinctSitesP: "0.3888", brLenTotal: "0.8580" },
  { geneTranscript: "NM_001242792.2", parsimonySitesP: "0.147004777655274", constantSitesP: "0.760382212421904", distinctSitesP: "0.2146269753767", brLenTotal: "0.5551" },
  { geneTranscript: "NM_001243133.2", parsimonySitesP: "0.279497098646035", constantSitesP: "0.602836879432624", distinctSitesP: "0.366860090264346", brLenTotal: "1.2380" },
  { geneTranscript: "NM_001242882.2", parsimonySitesP: "0.265450861195542", constantSitesP: "0.60790273556231", distinctSitesP: "0.415400202634245", brLenTotal: "1.3041" },
  { geneTranscript: "NM_001243212.2", parsimonySitesP: "0.349624060150376", constantSitesP: "0.43483709273183", distinctSitesP: "0.592731829573935", brLenTotal: "1.6312" },
  { geneTranscript: "NM_001243156.2", parsimonySitesP: "0.341637010676157", constantSitesP: "0.494661921708185", distinctSitesP: "0.468960063266113", brLenTotal: "1.4486" },
  { geneTranscript: "NM_001243351.2", parsimonySitesP: "0.218970189701897", constantSitesP: "0.655826558265583", distinctSitesP: "0.338211382113821", brLenTotal: "0.8266" },
  { geneTranscript: "NM_001243439.2", parsimonySitesP: "0.271847690387016", constantSitesP: "0.585518102372035", distinctSitesP: "0.379213483146067", brLenTotal: "1.0326" },
  { geneTranscript: "NM_001244014.2", parsimonySitesP: "0.144927536231884", constantSitesP: "0.757246376811594", distinctSitesP: "0.233091787439614", brLenTotal: "0.5486" },
  { geneTranscript: "NM_001244.4", parsimonySitesP: "0.274928774928775", constantSitesP: "0.582621082621083", distinctSitesP: "0.444444444444444", brLenTotal: "0.8850" },
  { geneTranscript: "NM_001245025.3", parsimonySitesP: "0.240631163708087", constantSitesP: "0.560157790927022", distinctSitesP: "0.447731755424063", brLenTotal: "0.9597" },
  { geneTranscript: "NM_001247.5", parsimonySitesP: "0.253443526170799", constantSitesP: "0.617768595041322", distinctSitesP: "0.394628099173554", brLenTotal: "1.0482" },
  { geneTranscript: "NM_001248.4", parsimonySitesP: "0.221802142407057", constantSitesP: "0.645242596093258", distinctSitesP: "0.344045368620038", brLenTotal: "0.8390" },
  { geneTranscript: "NM_001247997.2", parsimonySitesP: "0.162262401483542", constantSitesP: "0.713259156235512", distinctSitesP: "0.242234585071859", brLenTotal: "0.6544" },
  { geneTranscript: "NM_001252275.3", parsimonySitesP: "0.320050600885515", constantSitesP: "0.531309297912713", distinctSitesP: "0.467425679949399", brLenTotal: "1.6940" },
  { geneTranscript: "NM_001251.3", parsimonySitesP: "0.281544256120527", constantSitesP: "0.55743879472693", distinctSitesP: "0.454802259887006", brLenTotal: "1.0180" },
  { geneTranscript: "NM_001253829.2", parsimonySitesP: "0.243399339933993", constantSitesP: "0.611386138613861", distinctSitesP: "0.362211221122112", brLenTotal: "0.8422" },
  { geneTranscript: "NM_001254.4", parsimonySitesP: "0.179761904761905", constantSitesP: "0.6625", distinctSitesP: "0.330357142857143", brLenTotal: "0.5851" },
  { geneTranscript: "NM_001256106.3", parsimonySitesP: "0.297747306562194", constantSitesP: "0.502448579823702", distinctSitesP: "0.460006529546197", brLenTotal: "1.2726" },
  { geneTranscript: "NM_001256317.3", parsimonySitesP: "0.264900662251656", constantSitesP: "0.632082413539367", distinctSitesP: "0.37233259749816", brLenTotal: "1.2288" },
  { geneTranscript: "NM_001256613.2", parsimonySitesP: "0.385964912280702", constantSitesP: "0.411549707602339", distinctSitesP: "0.567982456140351", brLenTotal: "1.8532" },
  { geneTranscript: "NM_001256864.2", parsimonySitesP: "0.179725085910653", constantSitesP: "0.713058419243986", distinctSitesP: "0.240893470790378", brLenTotal: "0.6777" },
  { geneTranscript: "NM_001256875.2", parsimonySitesP: "0.182142857142857", constantSitesP: "0.672619047619048", distinctSitesP: "0.322619047619048", brLenTotal: "0.7088" },
  { geneTranscript: "NM_001258308.2", parsimonySitesP: "0.248768472906404", constantSitesP: "0.577175697865353", distinctSitesP: "0.40311986863711", brLenTotal: "0.9370" },
  { geneTranscript: "NM_001258311.2", parsimonySitesP: "0.181933842239186", constantSitesP: "0.716284987277354", distinctSitesP: "0.256361323155216", brLenTotal: "0.9070" },
  { geneTranscript: "NM_001261836.2", parsimonySitesP: "0.284622731614136", constantSitesP: "0.581661891117479", distinctSitesP: "0.41738299904489", brLenTotal: "0.9831" },
  { geneTranscript: "NM_001263.4", parsimonySitesP: "0.186550976138829", constantSitesP: "0.721619667389732", distinctSitesP: "0.27765726681128", brLenTotal: "0.6659" },
  { geneTranscript: "NM_001267046.2", parsimonySitesP: "0.167202572347267", constantSitesP: "0.729367631296892", distinctSitesP: "0.232047159699893", brLenTotal: "0.6678" },
  { geneTranscript: "NM_001267560.2", parsimonySitesP: "0.307943416757345", constantSitesP: "0.560029017047515", distinctSitesP: "0.439970982952485", brLenTotal: "1.4255" },
  { geneTranscript: "NM_001267727.2", parsimonySitesP: "0.269206349206349", constantSitesP: "0.591746031746032", distinctSitesP: "0.394920634920635", brLenTotal: "1.0455" },
  { geneTranscript: "NM_001270366.2", parsimonySitesP: "0.248839368616527", constantSitesP: "0.651346332404828", distinctSitesP: "0.332869080779944", brLenTotal: "1.4960" },
  { geneTranscript: "NM_001270.4", parsimonySitesP: "0.151851851851852", constantSitesP: "0.757699805068226", distinctSitesP: "0.182651072124756", brLenTotal: "0.4990" },
  { geneTranscript: "NM_001270441.2", parsimonySitesP: "0.363901018922853", constantSitesP: "0.46433770014556", distinctSitesP: "0.576419213973799", brLenTotal: "1.2650" },
  { geneTranscript: "NM_001270601.2", parsimonySitesP: "0.239482200647249", constantSitesP: "0.577669902912621", distinctSitesP: "0.448220064724919", brLenTotal: "1.0128" },
  { geneTranscript: "NM_001270891.2", parsimonySitesP: "0.285115303983229", constantSitesP: "0.519916142557652", distinctSitesP: "0.528301886792453", brLenTotal: "1.1167" },
  { geneTranscript: "NM_001270960.2", parsimonySitesP: "0.227021040974529", constantSitesP: "0.661129568106312", distinctSitesP: "0.31672203765227", brLenTotal: "1.0041" },
  { geneTranscript: "NM_001271458.2", parsimonySitesP: "0.270916334661355", constantSitesP: "0.531208499335989", distinctSitesP: "0.48339973439575", brLenTotal: "1.1004" },
  { geneTranscript: "NM_001271752.1", parsimonySitesP: "0.598787878787879", constantSitesP: "0.207272727272727", distinctSitesP: "0.755151515151515", brLenTotal: "3.5014" },
  { geneTranscript: "NM_001271783.2", parsimonySitesP: "0.208414239482201", constantSitesP: "0.679611650485437", distinctSitesP: "0.307443365695793", brLenTotal: "0.7468" },
  { geneTranscript: "NM_001271958.2", parsimonySitesP: "0.199588477366255", constantSitesP: "0.59156378600823", distinctSitesP: "0.340534979423868", brLenTotal: "0.7714" },
  { geneTranscript: "NM_001275.4", parsimonySitesP: "0.318016046681255", constantSitesP: "0.467541940189643", distinctSitesP: "0.531728665207877", brLenTotal: "1.3953" },
  { geneTranscript: "NM_001276345.2", parsimonySitesP: "0.178970917225951", constantSitesP: "0.715883668903803", distinctSitesP: "0.306487695749441", brLenTotal: "0.7868" },
  { geneTranscript: "NM_001276713.2", parsimonySitesP: "0.275883838383838", constantSitesP: "0.498737373737374", distinctSitesP: "0.468434343434343", brLenTotal: "1.0799" },
  { geneTranscript: "NM_001277074.2", parsimonySitesP: "0.208835341365462", constantSitesP: "0.678714859437751", distinctSitesP: "0.319277108433735", brLenTotal: "0.8674" },
  { geneTranscript: "NM_001277.3", parsimonySitesP: "0.167031363967907", constantSitesP: "0.723559445660102", distinctSitesP: "0.303428154631656", brLenTotal: "0.5715" },
  { geneTranscript: "NM_001277307.2", parsimonySitesP: "0.599206349206349", constantSitesP: "0.255952380952381", distinctSitesP: "0.741071428571429", brLenTotal: "3.1320" },
  { geneTranscript: "NM_001277313.2", parsimonySitesP: "0.258632840028189", constantSitesP: "0.58491895701198", distinctSitesP: "0.391120507399577", brLenTotal: "0.9429" },
  { geneTranscript: "NM_001277378.2", parsimonySitesP: "0.315642458100559", constantSitesP: "0.532588454376164", distinctSitesP: "0.442271880819367", brLenTotal: "1.0487" },
  { geneTranscript: "NM_001277423.2", parsimonySitesP: "0.384111384111384", constantSitesP: "0.433251433251433", distinctSitesP: "0.524979524979525", brLenTotal: "1.7093" },
  { geneTranscript: "NM_001278293.3", parsimonySitesP: "0.136200716845878", constantSitesP: "0.75089605734767", distinctSitesP: "0.268817204301075", brLenTotal: "0.4313" },
  { geneTranscript: "NM_001278509.3", parsimonySitesP: "0.344862155388471", constantSitesP: "0.411528822055138", distinctSitesP: "0.529824561403509", brLenTotal: "1.5047" },
  { geneTranscript: "NM_001278624.2", parsimonySitesP: "0.188803512623491", constantSitesP: "0.675082327113063", distinctSitesP: "0.297475301866081", brLenTotal: "0.7598" },
  { geneTranscript: "NM_001278628.2", parsimonySitesP: "0.14798641436196", constantSitesP: "0.770014556040757", distinctSitesP: "0.204269771955361", brLenTotal: "0.5435" },
  { geneTranscript: "NM_001278736.2", parsimonySitesP: "0.352813852813853", constantSitesP: "0.45021645021645", distinctSitesP: "0.541125541125541", brLenTotal: "1.3129" },
  { geneTranscript: "NM_001279.4", parsimonySitesP: "0.289193302891933", constantSitesP: "0.582952815829528", distinctSitesP: "0.430745814307458", brLenTotal: "1.1379" },
  { geneTranscript: "NM_001280561.2", parsimonySitesP: "0.334751773049645", constantSitesP: "0.516312056737589", distinctSitesP: "0.483687943262411", brLenTotal: "1.2058" },
  { geneTranscript: "NM_001282011.2", parsimonySitesP: "0.324749642346209", constantSitesP: "0.510729613733906", distinctSitesP: "0.517882689556509", brLenTotal: "1.4317" },
  { geneTranscript: "NM_001282290.2", parsimonySitesP: "0.262092238470191", constantSitesP: "0.548556430446194", distinctSitesP: "0.395950506186727", brLenTotal: "1.1187" },
  { geneTranscript: "NM_001282448.2", parsimonySitesP: "0.307471264367816", constantSitesP: "0.543103448275862", distinctSitesP: "0.488505747126437", brLenTotal: "1.2016" },
  { geneTranscript: "NM_001282537.2", parsimonySitesP: "0.313413014608234", constantSitesP: "0.525896414342629", distinctSitesP: "0.468791500664011", brLenTotal: "1.0351" },
  { geneTranscript: "NM_001282805.2", parsimonySitesP: "0.135416666666667", constantSitesP: "0.726325757575758", distinctSitesP: "0.246685606060606", brLenTotal: "0.4635" },
  { geneTranscript: "NM_001282860.2", parsimonySitesP: "0.235906589320244", constantSitesP: "0.587237840249888", distinctSitesP: "0.362338241856314", brLenTotal: "0.8758" },
  { geneTranscript: "NM_001284240.2", parsimonySitesP: "0.205666985036613", constantSitesP: "0.579751671442216", distinctSitesP: "0.400191021967526", brLenTotal: "0.8217" },
  { geneTranscript: "NM_001285.4", parsimonySitesP: "0.294310722100656", constantSitesP: "0.557986870897155", distinctSitesP: "0.420131291028446", brLenTotal: "1.0786" },
  { geneTranscript: "NM_001285485.2", parsimonySitesP: "0.347328244274809", constantSitesP: "0.497455470737913", distinctSitesP: "0.498727735368957", brLenTotal: "1.4157" },
  { geneTranscript: "NM_001286234.2", parsimonySitesP: "0.320590207914152", constantSitesP: "0.527162977867203", distinctSitesP: "0.476190476190476", brLenTotal: "1.4779" },
  { geneTranscript: "NM_001286398.3", parsimonySitesP: "0.171586715867159", constantSitesP: "0.698031980319803", distinctSitesP: "0.274292742927429", brLenTotal: "0.5444" },
  { geneTranscript: "NM_001286423.2", parsimonySitesP: "0.218144750254842", constantSitesP: "0.652395514780836", distinctSitesP: "0.317533129459735", brLenTotal: "0.6665" },
  { geneTranscript: "NM_001286445.3", parsimonySitesP: "0.184017828716969", constantSitesP: "0.683540273798153", distinctSitesP: "0.266157274753263", brLenTotal: "0.8410" },
  { geneTranscript: "NM_001286496.2", parsimonySitesP: "0.243369734789392", constantSitesP: "0.58814352574103", distinctSitesP: "0.349973998959958", brLenTotal: "0.9619" },
  { geneTranscript: "NM_001286555.3", parsimonySitesP: "0.213008130081301", constantSitesP: "0.695934959349593", distinctSitesP: "0.330081300813008", brLenTotal: "0.9072" },
  { geneTranscript: "NM_001286577.2", parsimonySitesP: "0.232894177645559", constantSitesP: "0.598101714123814", distinctSitesP: "0.343108088964443", brLenTotal: "0.7807" },
  { geneTranscript: "NM_001286606.2", parsimonySitesP: "0.322472848788638", constantSitesP: "0.50125313283208", distinctSitesP: "0.48203842940685", brLenTotal: "1.1893" },
  { geneTranscript: "NM_001286657.2", parsimonySitesP: "0.147119341563786", constantSitesP: "0.751028806584362", distinctSitesP: "0.236625514403292", brLenTotal: "0.4793" },
  { geneTranscript: "NM_001288622.3", parsimonySitesP: "0.145919778699862", constantSitesP: "0.706085753803596", distinctSitesP: "0.273167358229599", brLenTotal: "0.5385" },
  { geneTranscript: "NM_001288990.3", parsimonySitesP: "0.286516853932584", constantSitesP: "0.546348314606742", distinctSitesP: "0.425093632958801", brLenTotal: "0.9526" },
  { geneTranscript: "NM_001289104.2", parsimonySitesP: "0.261059190031153", constantSitesP: "0.614953271028037", distinctSitesP: "0.368847352024922", brLenTotal: "1.2147" },
  { geneTranscript: "NM_001289401.2", parsimonySitesP: "0.350557244174265", constantSitesP: "0.417933130699088", distinctSitesP: "0.566869300911854", brLenTotal: "1.6600" },
  { geneTranscript: "NM_001290047.2", parsimonySitesP: "0.268214936247723", constantSitesP: "0.598588342440801", distinctSitesP: "0.35655737704918", brLenTotal: "1.1296" },
  { geneTranscript: "NM_001290079.1", parsimonySitesP: "0.280505952380952", constantSitesP: "0.587053571428571", distinctSitesP: "0.423363095238095", brLenTotal: "1.0779" },
  { geneTranscript: "NM_001290268.2", parsimonySitesP: "0.302105263157895", constantSitesP: "0.539298245614035", distinctSitesP: "0.428771929824561", brLenTotal: "1.3107" },
  { geneTranscript: "NM_001290.5", parsimonySitesP: "0.096514745308311", constantSitesP: "0.838248436103664", distinctSitesP: "0.152815013404826", brLenTotal: "0.4811" },
  { geneTranscript: "NM_001291281.3", parsimonySitesP: "0.155826558265583", constantSitesP: "0.739159891598916", distinctSitesP: "0.27710027100271", brLenTotal: "0.6491" },
  { geneTranscript: "NM_001291303.3", parsimonySitesP: "0.182754699310991", constantSitesP: "0.685530804736103", distinctSitesP: "0.223693892568065", brLenTotal: "0.8298" },
  { geneTranscript: "NM_001291694.2", parsimonySitesP: "0.210290827740492", constantSitesP: "0.713087248322148", distinctSitesP: "0.297539149888143", brLenTotal: "0.8291" },
  { geneTranscript: "NM_001292063.2", parsimonySitesP: "0.252774917038563", constantSitesP: "0.601098523858565", distinctSitesP: "0.307586680398215", brLenTotal: "1.0649" },
  { geneTranscript: "NM_001293083.2", parsimonySitesP: "0.25376762275158", constantSitesP: "0.599416626154594", distinctSitesP: "0.324744773942635", brLenTotal: "0.9439" },
  { geneTranscript: "NM_001293298.2", parsimonySitesP: "0.248836639725692", constantSitesP: "0.642419789370561", distinctSitesP: "0.333823169238305", brLenTotal: "1.0453" },
  { geneTranscript: "NM_001297551.2", parsimonySitesP: "0.290909090909091", constantSitesP: "0.513131313131313", distinctSitesP: "0.484848484848485", brLenTotal: "1.0718" },
  { geneTranscript: "NM_001297732.2", parsimonySitesP: "0.293413173652695", constantSitesP: "0.565868263473054", distinctSitesP: "0.441117764471058", brLenTotal: "0.9887" },
  { geneTranscript: "NM_001300826.2", parsimonySitesP: "0.134974920200638", constantSitesP: "0.763337893296854", distinctSitesP: "0.229822161422709", brLenTotal: "0.4561" },
  { geneTranscript: "NM_001300921.2", parsimonySitesP: "0.16025641025641", constantSitesP: "0.714209401709402", distinctSitesP: "0.280448717948718", brLenTotal: "0.4902" },
  { geneTranscript: "NM_001300975.2", parsimonySitesP: "0.214421252371917", constantSitesP: "0.642631246046806", distinctSitesP: "0.367488931056293", brLenTotal: "0.7207" },
  { geneTranscript: "NM_001301098.2", parsimonySitesP: "0.113450292397661", constantSitesP: "0.749707602339181", distinctSitesP: "0.195321637426901", brLenTotal: "0.4781" },
  { geneTranscript: "NM_001302.5", parsimonySitesP: "0.323809523809524", constantSitesP: "0.533333333333333", distinctSitesP: "0.507936507936508", brLenTotal: "1.2222" },
  { geneTranscript: "NM_001302769.2", parsimonySitesP: "0.20387275242047", constantSitesP: "0.653665283540802", distinctSitesP: "0.292116182572614", brLenTotal: "0.8182" },
  { geneTranscript: "NM_001303143.2", parsimonySitesP: "0.197346600331675", constantSitesP: "0.658927584300719", distinctSitesP: "0.339966832504146", brLenTotal: "0.6738" },
  { geneTranscript: "NM_001303281.2", parsimonySitesP: "0.211293260473588", constantSitesP: "0.624165148755313", distinctSitesP: "0.366120218579235", brLenTotal: "0.7822" },
  { geneTranscript: "NM_001303473.2", parsimonySitesP: "0.335335335335335", constantSitesP: "0.54954954954955", distinctSitesP: "0.45045045045045", brLenTotal: "1.7378" },
  { geneTranscript: "NM_001303508.2", parsimonySitesP: "0.359183673469388", constantSitesP: "0.442176870748299", distinctSitesP: "0.563265306122449", brLenTotal: "1.4373" },
  { geneTranscript: "NM_001304504.2", parsimonySitesP: "0.128722382324688", constantSitesP: "0.758885686839577", distinctSitesP: "0.219020172910663", brLenTotal: "0.4932" },
  { geneTranscript: "NM_001304817.2", parsimonySitesP: "0.245333333333333", constantSitesP: "0.528", distinctSitesP: "0.517333333333333", brLenTotal: "0.8843" },
  { geneTranscript: "NM_001304993.2", parsimonySitesP: "0.350557244174265", constantSitesP: "0.525835866261398", distinctSitesP: "0.502532928064843", brLenTotal: "1.7439" },
  { geneTranscript: "NM_001306089.2", parsimonySitesP: "0.21620646092763", constantSitesP: "0.686157733261144", distinctSitesP: "0.285688503880166", brLenTotal: "1.0009" },
  { geneTranscript: "NM_001306144.3", parsimonySitesP: "0.172362555720654", constantSitesP: "0.720158494304111", distinctSitesP: "0.246161466072313", brLenTotal: "0.6569" },
  { geneTranscript: "NM_001306158.2", parsimonySitesP: "0.222707423580786", constantSitesP: "0.60844250363901", distinctSitesP: "0.388646288209607", brLenTotal: "0.8428" },
  { geneTranscript: "NM_001308093.3", parsimonySitesP: "0.222723852520692", constantSitesP: "0.680210684725357", distinctSitesP: "0.310759969902182", brLenTotal: "1.1243" },
  { geneTranscript: "NM_001308195.2", parsimonySitesP: "0.273849607182941", constantSitesP: "0.560044893378227", distinctSitesP: "0.424616535727647", brLenTotal: "1.1913" },
  { geneTranscript: "NM_001308209.2", parsimonySitesP: "0.364066193853428", constantSitesP: "0.459810874704492", distinctSitesP: "0.554373522458629", brLenTotal: "1.8567" },
  { geneTranscript: "NM_001308236.3", parsimonySitesP: "0.322727272727273", constantSitesP: "0.541666666666667", distinctSitesP: "0.433333333333333", brLenTotal: "1.5081" },
  { geneTranscript: "NM_001308.3", parsimonySitesP: "0.237991266375546", constantSitesP: "0.628093158660844", distinctSitesP: "0.334061135371179", brLenTotal: "0.9400" },
  { geneTranscript: "NM_001308348.2", parsimonySitesP: "0.493532338308458", constantSitesP: "0.323880597014925", distinctSitesP: "0.656218905472637", brLenTotal: "2.4817" },
  { geneTranscript: "NM_001310219.2", parsimonySitesP: "0.320819112627986", constantSitesP: "0.494880546075085", distinctSitesP: "0.531285551763367", brLenTotal: "1.3492" },
  { geneTranscript: "NM_001311.5", parsimonySitesP: "0.173160173160173", constantSitesP: "0.761904761904762", distinctSitesP: "0.32034632034632", brLenTotal: "0.5972" },
  { geneTranscript: "NM_001312.4", parsimonySitesP: "0.201923076923077", constantSitesP: "0.719551282051282", distinctSitesP: "0.290064102564103", brLenTotal: "0.8936" },
  { geneTranscript: "NM_001316972.2", parsimonySitesP: "0.32201646090535", constantSitesP: "0.519547325102881", distinctSitesP: "0.492798353909465", brLenTotal: "1.1639" },
  { geneTranscript: "NM_001317107.2", parsimonySitesP: "0.428571428571429", constantSitesP: "0.415873015873016", distinctSitesP: "0.546031746031746", brLenTotal: "2.0195" },
  { geneTranscript: "NM_001317950.2", parsimonySitesP: "0.36738475793375", constantSitesP: "0.439888811674774", distinctSitesP: "0.536252026870512", brLenTotal: "1.4493" },
  { geneTranscript: "NM_001318089.2", parsimonySitesP: "0.235897435897436", constantSitesP: "0.571794871794872", distinctSitesP: "0.469230769230769", brLenTotal: "0.7958" },
  { geneTranscript: "NM_001318104.2", parsimonySitesP: "0.219858156028369", constantSitesP: "0.665878644602049", distinctSitesP: "0.300236406619385", brLenTotal: "0.7842" },
  { geneTranscript: "NM_001318777.2", parsimonySitesP: "0.294117647058824", constantSitesP: "0.570135746606335", distinctSitesP: "0.457013574660633", brLenTotal: "1.2933" },
  { geneTranscript: "NM_001318891.2", parsimonySitesP: "0.169107856191744", constantSitesP: "0.589436307146028", distinctSitesP: "0.27075011096316", brLenTotal: "1.4325" },
  { geneTranscript: "NM_001319074.4", parsimonySitesP: "0.278985507246377", constantSitesP: "0.577898550724638", distinctSitesP: "0.391304347826087", brLenTotal: "1.9854" },
  { geneTranscript: "NM_001319658.2", parsimonySitesP: "0.155555555555556", constantSitesP: "0.708888888888889", distinctSitesP: "0.284444444444444", brLenTotal: "0.6099" },
  { geneTranscript: "NM_001319674.2", parsimonySitesP: "0.116637063351095", constantSitesP: "0.77797513321492", distinctSitesP: "0.168146832445234", brLenTotal: "0.4853" },
  { geneTranscript: "NM_001320198.2", parsimonySitesP: "0.205075445816187", constantSitesP: "0.646776406035665", distinctSitesP: "0.367626886145405", brLenTotal: "1.3522" },
  { geneTranscript: "NM_001320646.2", parsimonySitesP: "0.151388888888889", constantSitesP: "0.797222222222222", distinctSitesP: "0.202777777777778", brLenTotal: "0.6934" },
  { geneTranscript: "NM_001320768.2", parsimonySitesP: "0.255913978494624", constantSitesP: "0.597849462365591", distinctSitesP: "0.436559139784946", brLenTotal: "0.9516" },
  { geneTranscript: "NM_001321645.3", parsimonySitesP: "0.474776049033475", constantSitesP: "0.335690711928336", distinctSitesP: "0.660537482319661", brLenTotal: "2.8068" },
  { geneTranscript: "NM_001321958.2", parsimonySitesP: "0.233333333333333", constantSitesP: "0.652083333333333", distinctSitesP: "0.370833333333333", brLenTotal: "0.8157" },
  { geneTranscript: "NM_001321971.2", parsimonySitesP: "0.317729083665339", constantSitesP: "0.487715803452855", distinctSitesP: "0.47609561752988", brLenTotal: "1.2334" },
  { geneTranscript: "NM_001322331.2", parsimonySitesP: "0.150793650793651", constantSitesP: "0.708994708994709", distinctSitesP: "0.328042328042328", brLenTotal: "0.5125" },
  { geneTranscript: "NM_001322934.2", parsimonySitesP: "0.208148148148148", constantSitesP: "0.673333333333333", distinctSitesP: "0.278888888888889", brLenTotal: "0.8644" },
  { geneTranscript: "NM_001323043.2", parsimonySitesP: "0.15748031496063", constantSitesP: "0.733158355205599", distinctSitesP: "0.247156605424322", brLenTotal: "0.5431" },
  { geneTranscript: "NM_001324144.2", parsimonySitesP: "0.361574668378263", constantSitesP: "0.446726572528883", distinctSitesP: "0.5066324347454", brLenTotal: "1.5313" },
  { geneTranscript: "NM_001324366.2", parsimonySitesP: "0.155868544600939", constantSitesP: "0.707981220657277", distinctSitesP: "0.285446009389671", brLenTotal: "0.4858" },
  { geneTranscript: "NM_001324445.2", parsimonySitesP: "0.249003984063745", constantSitesP: "0.586321381142098", distinctSitesP: "0.413678618857902", brLenTotal: "0.8977" },
  { geneTranscript: "NM_001328655.2", parsimonySitesP: "0.347368421052632", constantSitesP: "0.473684210526316", distinctSitesP: "0.578947368421053", brLenTotal: "1.1755" },
  { geneTranscript: "NM_001330059.2", parsimonySitesP: "0.138812785388128", constantSitesP: "0.757990867579909", distinctSitesP: "0.225570776255708", brLenTotal: "0.4857" },
  { geneTranscript: "NM_001330074.2", parsimonySitesP: "0.240865026099925", constantSitesP: "0.592095451155854", distinctSitesP: "0.402187422321651", brLenTotal: "0.9166" },
  { geneTranscript: "NM_001330177.2", parsimonySitesP: "0.208475540386803", constantSitesP: "0.660409556313993", distinctSitesP: "0.306882821387941", brLenTotal: "0.6909" },
  { geneTranscript: "NM_001330218.2", parsimonySitesP: "0.161559888579387", constantSitesP: "0.67966573816156", distinctSitesP: "0.286908077994429", brLenTotal: "0.6275" },
  { geneTranscript: "NM_001330230.2", parsimonySitesP: "0.305361305361305", constantSitesP: "0.536130536130536", distinctSitesP: "0.468531468531469", brLenTotal: "1.0887" },
  { geneTranscript: "NM_001330239.4", parsimonySitesP: "0.170437405731523", constantSitesP: "0.72209653092006", distinctSitesP: "0.225113122171946", brLenTotal: "0.6707" },
  { geneTranscript: "NM_001330448.1", parsimonySitesP: "0.264367816091954", constantSitesP: "0.559386973180077", distinctSitesP: "0.431034482758621", brLenTotal: "1.0342" },
  { geneTranscript: "NM_001330.5", parsimonySitesP: "0.235489220563847", constantSitesP: "0.603648424543947", distinctSitesP: "0.436152570480929", brLenTotal: "1.1455" },
  { geneTranscript: "NM_001330559.2", parsimonySitesP: "0.198154180238871", constantSitesP: "0.663952225841477", distinctSitesP: "0.319761129207383", brLenTotal: "0.8211" },
  { geneTranscript: "NM_001330677.2", parsimonySitesP: "0.136212624584718", constantSitesP: "0.759136212624585", distinctSitesP: "0.211517165005537", brLenTotal: "0.5338" },
  { geneTranscript: "NM_001330701.2", parsimonySitesP: "0.146275149537792", constantSitesP: "0.747688961392061", distinctSitesP: "0.22131593257205", brLenTotal: "0.4579" },
  { geneTranscript: "NM_001330724.2", parsimonySitesP: "0.209941520467836", constantSitesP: "0.643859649122807", distinctSitesP: "0.369005847953216", brLenTotal: "0.7506" },
  { geneTranscript: "NM_001333.4", parsimonySitesP: "0.323353293413174", constantSitesP: "0.529940119760479", distinctSitesP: "0.455089820359281", brLenTotal: "1.6548" },
  { geneTranscript: "NM_001346.3", parsimonySitesP: "0.173198482932996", constantSitesP: "0.705014749262537", distinctSitesP: "0.27307206068268", brLenTotal: "0.6563" },
  { geneTranscript: "NM_001346754.2", parsimonySitesP: "0.228835978835979", constantSitesP: "0.619708994708995", distinctSitesP: "0.35978835978836", brLenTotal: "0.7209" },
  { geneTranscript: "NM_001346880.2", parsimonySitesP: "0.261904761904762", constantSitesP: "0.609126984126984", distinctSitesP: "0.372354497354497", brLenTotal: "1.2019" },
  { geneTranscript: "NM_001347885.2", parsimonySitesP: "0.206773618538324", constantSitesP: "0.661319073083779", distinctSitesP: "0.354723707664884", brLenTotal: "0.8001" },
  { geneTranscript: "NM_001347886.2", parsimonySitesP: "0.218591318591319", constantSitesP: "0.65995085995086", distinctSitesP: "0.275675675675676", brLenTotal: "0.7888" },
  { geneTranscript: "NM_001348119.1", parsimonySitesP: "0.283096926713948", constantSitesP: "0.550827423167849", distinctSitesP: "0.41371158392435", brLenTotal: "1.0270" },
  { geneTranscript: "NM_001348699.2", parsimonySitesP: "0.277292576419214", constantSitesP: "0.534934497816594", distinctSitesP: "0.495633187772926", brLenTotal: "1.0012" },
  { geneTranscript: "NM_001349074.2", parsimonySitesP: "0.143206854345165", constantSitesP: "0.732762137902897", distinctSitesP: "0.24235006119951", brLenTotal: "0.5539" },
  { geneTranscript: "NM_001349206.2", parsimonySitesP: "0.240460763138949", constantSitesP: "0.655867530597552", distinctSitesP: "0.339812814974802", brLenTotal: "1.1272" },
  { geneTranscript: "NM_001349278.2", parsimonySitesP: "0.106494305940289", constantSitesP: "0.780240073868883", distinctSitesP: "0.152046783625731", brLenTotal: "0.6247" },
  { geneTranscript: "NM_001349727.2", parsimonySitesP: "0.358730158730159", constantSitesP: "0.452380952380952", distinctSitesP: "0.592063492063492", brLenTotal: "1.4028" },
  { geneTranscript: "NM_001350599.2", parsimonySitesP: "0.206221507106463", constantSitesP: "0.66130329847144", distinctSitesP: "0.319120407615983", brLenTotal: "0.6743" },
  { geneTranscript: "NM_001350814.2", parsimonySitesP: "0.183501683501684", constantSitesP: "0.699775533108866", distinctSitesP: "0.280022446689113", brLenTotal: "0.7934" },
  { geneTranscript: "NM_001351589.3", parsimonySitesP: "0.292307692307692", constantSitesP: "0.544615384615385", distinctSitesP: "0.471794871794872", brLenTotal: "1.2534" },
  { geneTranscript: "NM_001352754.2", parsimonySitesP: "0.232681336593317", constantSitesP: "0.63080684596577", distinctSitesP: "0.359005704971475", brLenTotal: "0.9141" },
  { geneTranscript: "NM_001352837.2", parsimonySitesP: "0.211079274116523", constantSitesP: "0.653613498885705", distinctSitesP: "0.329512893982808", brLenTotal: "0.7870" },
  { geneTranscript: "NM_001353803.2", parsimonySitesP: "0.391666666666667", constantSitesP: "0.357291666666667", distinctSitesP: "0.5890625", brLenTotal: "1.6324" },
  { geneTranscript: "NM_001354046.2", parsimonySitesP: "0.146945088940449", constantSitesP: "0.751740139211137", distinctSitesP: "0.237045630317092", brLenTotal: "0.6329" },
  { geneTranscript: "NM_001354483.2", parsimonySitesP: "0.215538847117794", constantSitesP: "0.656015037593985", distinctSitesP: "0.323308270676692", brLenTotal: "0.9407" },
  { geneTranscript: "NM_001354631.1", parsimonySitesP: "0.373257287705957", constantSitesP: "0.402408111533587", distinctSitesP: "0.594423320659062", brLenTotal: "1.5077" },
  { geneTranscript: "NM_001354640.2", parsimonySitesP: "0.252961082910321", constantSitesP: "0.547800338409475", distinctSitesP: "0.408206429780034", brLenTotal: "1.2181" },
  { geneTranscript: "NM_001355570.4", parsimonySitesP: "0.242937853107345", constantSitesP: "0.604519774011299", distinctSitesP: "0.474576271186441", brLenTotal: "0.7430" },
  { geneTranscript: "NM_001358291.2", parsimonySitesP: "0.2288", constantSitesP: "0.609066666666667", distinctSitesP: "0.3984", brLenTotal: "0.7460" },
  { geneTranscript: "NM_001358345.2", parsimonySitesP: "0.250570776255708", constantSitesP: "0.606164383561644", distinctSitesP: "0.377853881278539", brLenTotal: "1.0865" },
  { geneTranscript: "NM_001358451.3", parsimonySitesP: "0.135775862068966", constantSitesP: "0.758620689655172", distinctSitesP: "0.223419540229885", brLenTotal: "0.4401" },
  { geneTranscript: "NM_001359228.2", parsimonySitesP: "0.257383966244726", constantSitesP: "0.602848101265823", distinctSitesP: "0.383438818565401", brLenTotal: "1.2549" },
  { geneTranscript: "NM_001360.3", parsimonySitesP: "0.261754385964912", constantSitesP: "0.604210526315789", distinctSitesP: "0.383859649122807", brLenTotal: "1.2911" },
  { geneTranscript: "NM_001361041.2", parsimonySitesP: "0.220157657657658", constantSitesP: "0.633445945945946", distinctSitesP: "0.372184684684685", brLenTotal: "0.8349" },
  { geneTranscript: "NM_001363.5", parsimonySitesP: "0.147859922178988", constantSitesP: "0.734760051880674", distinctSitesP: "0.259403372243839", brLenTotal: "0.5939" },
  { geneTranscript: "NM_001363531.2", parsimonySitesP: "0.243016759776536", constantSitesP: "0.577281191806331", distinctSitesP: "0.407821229050279", brLenTotal: "0.8598" },
  { geneTranscript: "NM_001363764.2", parsimonySitesP: "0.352445193929174", constantSitesP: "0.473299606520517", distinctSitesP: "0.543001686340641", brLenTotal: "1.6767" },
  { geneTranscript: "NM_001363845.2", parsimonySitesP: "0.256619937694704", constantSitesP: "0.607476635514019", distinctSitesP: "0.370716510903427", brLenTotal: "0.8290" },
  { geneTranscript: "NM_001364008.2", parsimonySitesP: "0.20615796519411", constantSitesP: "0.677376171352075", distinctSitesP: "0.333333333333333", brLenTotal: "0.6227" },
  { geneTranscript: "NM_001364013.2", parsimonySitesP: "0.204778156996587", constantSitesP: "0.676905574516496", distinctSitesP: "0.344709897610922", brLenTotal: "0.6371" },
  { geneTranscript: "NM_001364711.1", parsimonySitesP: "0.313413014608234", constantSitesP: "0.525896414342629", distinctSitesP: "0.468791500664011", brLenTotal: "1.0351" },
  { geneTranscript: "NM_001364716.4", parsimonySitesP: "0.262268041237113", constantSitesP: "0.604536082474227", distinctSitesP: "0.320274914089347", brLenTotal: "1.0876" },
  { geneTranscript: "NM_001364730.1", parsimonySitesP: "0.49469964664311", constantSitesP: "0.366313309776207", distinctSitesP: "0.637809187279152", brLenTotal: "2.5761" },
  { geneTranscript: "NM_001364905.1", parsimonySitesP: "0.169237961664329", constantSitesP: "0.711781206171108", distinctSitesP: "0.229195885928004", brLenTotal: "0.6239" },
  { geneTranscript: "NM_001365156.1", parsimonySitesP: "0.274691358024691", constantSitesP: "0.601851851851852", distinctSitesP: "0.41358024691358", brLenTotal: "1.2533" },
  { geneTranscript: "NM_001365174.2", parsimonySitesP: "0.373770491803279", constantSitesP: "0.434972677595628", distinctSitesP: "0.558469945355191", brLenTotal: "1.5508" },
  { geneTranscript: "NM_001365225.1", parsimonySitesP: "0.175050301810865", constantSitesP: "0.691482226693494", distinctSitesP: "0.26224010731053", brLenTotal: "0.6916" },
  { geneTranscript: "NM_001365308.1", parsimonySitesP: "0.164963503649635", constantSitesP: "0.718248175182482", distinctSitesP: "0.255474452554745", brLenTotal: "0.7699" },
  { geneTranscript: "NM_001365310.2", parsimonySitesP: "0.411985018726592", constantSitesP: "0.369538077403246", distinctSitesP: "0.594257178526841", brLenTotal: "2.2826" },
  { geneTranscript: "NM_001365621.2", parsimonySitesP: "0.130712365591398", constantSitesP: "0.771841397849462", distinctSitesP: "0.189516129032258", brLenTotal: "0.6098" },
  { geneTranscript: "NM_001365635.2", parsimonySitesP: "0.149500998003992", constantSitesP: "0.718363273453094", distinctSitesP: "0.231536926147705", brLenTotal: "0.5001" },
  { geneTranscript: "NM_001365692.1", parsimonySitesP: "0.176298890834793", constantSitesP: "0.712200817279626", distinctSitesP: "0.284296555750146", brLenTotal: "0.8252" },
  { geneTranscript: "NM_001365715.1", parsimonySitesP: "0.184470184470184", constantSitesP: "0.692406692406692", distinctSitesP: "0.291720291720292", brLenTotal: "0.6322" },
  { geneTranscript: "NM_001365788.1", parsimonySitesP: "0.327790973871734", constantSitesP: "0.429136975455265", distinctSitesP: "0.517814726840855", brLenTotal: "1.4697" },
  { geneTranscript: "NM_001365906.3", parsimonySitesP: "0.306468440271257", constantSitesP: "0.5", distinctSitesP: "0.455399061032864", brLenTotal: "1.5023" },
  { geneTranscript: "NM_001366110.1", parsimonySitesP: "0.248812915479582", constantSitesP: "0.568850902184236", distinctSitesP: "0.39031339031339", brLenTotal: "0.8854" },
  { geneTranscript: "NM_001366178.1", parsimonySitesP: "0.215747215747216", constantSitesP: "0.656047656047656", distinctSitesP: "0.293188293188293", brLenTotal: "0.8870" },
  { geneTranscript: "NM_001366230.1", parsimonySitesP: "0.200274348422497", constantSitesP: "0.670324645633288", distinctSitesP: "0.34156378600823", brLenTotal: "0.7588" },
  { geneTranscript: "NM_001366293.2", parsimonySitesP: "0.261044176706827", constantSitesP: "0.646586345381526", distinctSitesP: "0.417670682730924", brLenTotal: "0.7982" },
  { geneTranscript: "NM_001366385.1", parsimonySitesP: "0.295152722443559", constantSitesP: "0.545484727755644", distinctSitesP: "0.404714475431607", brLenTotal: "1.3489" },
  { geneTranscript: "NM_001366508.1", parsimonySitesP: "0.209000762776506", constantSitesP: "0.636155606407323", distinctSitesP: "0.32418001525553", brLenTotal: "0.9000" },
  { geneTranscript: "NM_001366722.1", parsimonySitesP: "0.180851063829787", constantSitesP: "0.728723404255319", distinctSitesP: "0.232860520094563", brLenTotal: "0.8223" },
  { geneTranscript: "NM_001366845.3", parsimonySitesP: "0.199195522910108", constantSitesP: "0.661594963273872", distinctSitesP: "0.289786638684855", brLenTotal: "0.6666" },
  { geneTranscript: "NM_001366854.1", parsimonySitesP: "0.242843951985226", constantSitesP: "0.60387811634349", distinctSitesP: "0.32409972299169", brLenTotal: "1.1023" },
  { geneTranscript: "NM_001366900.1", parsimonySitesP: "0.276719979690277", constantSitesP: "0.521959888296522", distinctSitesP: "0.418126428027418", brLenTotal: "1.0395" },
  { geneTranscript: "NM_001367656.1", parsimonySitesP: "0.232041343669251", constantSitesP: "0.60671834625323", distinctSitesP: "0.367441860465116", brLenTotal: "1.0662" },
  { geneTranscript: "NM_001367710.1", parsimonySitesP: "0.200485289657264", constantSitesP: "0.669396420988778", distinctSitesP: "0.262966333030027", brLenTotal: "0.7667" },
  { geneTranscript: "NM_001367773.1", parsimonySitesP: "0.204003079291763", constantSitesP: "0.693995381062356", distinctSitesP: "0.299076212471132", brLenTotal: "0.8377" },
  { geneTranscript: "NM_001367805.3", parsimonySitesP: "0.131416837782341", constantSitesP: "0.747433264887064", distinctSitesP: "0.203285420944559", brLenTotal: "0.4940" },
  { geneTranscript: "NM_001367806.1", parsimonySitesP: "0.122513922036595", constantSitesP: "0.772474144789181", distinctSitesP: "0.203659506762132", brLenTotal: "0.4101" },
  { geneTranscript: "NM_001367807.1", parsimonySitesP: "0.197691197691198", constantSitesP: "0.588744588744589", distinctSitesP: "0.385281385281385", brLenTotal: "2.2749" },
  { geneTranscript: "NM_001367823.1", parsimonySitesP: "0.290717609600784", constantSitesP: "0.566985060004898", distinctSitesP: "0.403869703649277", brLenTotal: "1.2752" },
  { geneTranscript: "NM_001367975.1", parsimonySitesP: "0.2372634643377", constantSitesP: "0.590975254730713", distinctSitesP: "0.439592430858806", brLenTotal: "0.8022" },
  { geneTranscript: "NM_001368158.1", parsimonySitesP: "0.384930384930385", constantSitesP: "0.432432432432432", distinctSitesP: "0.533988533988534", brLenTotal: "1.7020" },
  { geneTranscript: "NM_001368238.1", parsimonySitesP: "0.235038084874864", constantSitesP: "0.650344577439246", distinctSitesP: "0.332970620239391", brLenTotal: "1.0701" },
  { geneTranscript: "NM_001369125.3", parsimonySitesP: "0.306010928961749", constantSitesP: "0.532786885245902", distinctSitesP: "0.513661202185792", brLenTotal: "1.4528" },
  { geneTranscript: "NM_001369441.2", parsimonySitesP: "0.219274977895668", constantSitesP: "0.587975243147657", distinctSitesP: "0.381962864721485", brLenTotal: "0.7984" },
  { geneTranscript: "NM_001369775.2", parsimonySitesP: "0.312084993359894", constantSitesP: "0.52058432934927", distinctSitesP: "0.48472775564409", brLenTotal: "1.2268" },
  { geneTranscript: "NM_001370184.1", parsimonySitesP: "0.262074425969913", constantSitesP: "0.564528899445764", distinctSitesP: "0.398258115597783", brLenTotal: "1.1390" },
  { geneTranscript: "NM_001370215.1", parsimonySitesP: "0.293867638129933", constantSitesP: "0.483302975106254", distinctSitesP: "0.469945355191257", brLenTotal: "1.6586" },
  { geneTranscript: "NM_001370326.1", parsimonySitesP: "0.187899941826643", constantSitesP: "0.699534613147179", distinctSitesP: "0.27021524141943", brLenTotal: "0.6364" },
  { geneTranscript: "NM_001370338.1", parsimonySitesP: "0.229483282674772", constantSitesP: "0.637284701114488", distinctSitesP: "0.367781155015198", brLenTotal: "1.0927" },
  { geneTranscript: "NM_001370461.1", parsimonySitesP: "0.230083857442348", constantSitesP: "0.650943396226415", distinctSitesP: "0.335953878406709", brLenTotal: "0.9970" },
  { geneTranscript: "NM_001370465.2", parsimonySitesP: "0.321969696969697", constantSitesP: "0.520833333333333", distinctSitesP: "0.53030303030303", brLenTotal: "1.3163" },
  { geneTranscript: "NM_001370466.1", parsimonySitesP: "0.302073050345508", constantSitesP: "0.527805199078644", distinctSitesP: "0.411977624218493", brLenTotal: "1.3082" },
  { geneTranscript: "NM_001370476.2", parsimonySitesP: "0.15544472152951", constantSitesP: "0.734829592684954", distinctSitesP: "0.27597672485453", brLenTotal: "0.5317" },
  { geneTranscript: "NM_001370785.2", parsimonySitesP: "0.109206349206349", constantSitesP: "0.820952380952381", distinctSitesP: "0.144973544973545", brLenTotal: "0.3936" },
  { geneTranscript: "NM_001371237.1", parsimonySitesP: "0.237847222222222", constantSitesP: "0.602430555555556", distinctSitesP: "0.381944444444444", brLenTotal: "1.0406" },
  { geneTranscript: "NM_001371242.2", parsimonySitesP: "0.266385108712654", constantSitesP: "0.566400750821211", distinctSitesP: "0.372595025809479", brLenTotal: "1.0077" },
  { geneTranscript: "NM_001371333.1", parsimonySitesP: "0.223152022315202", constantSitesP: "0.655509065550907", distinctSitesP: "0.373779637377964", brLenTotal: "0.7921" },
  { geneTranscript: "NM_001372060.1", parsimonySitesP: "0.318150448585231", constantSitesP: "0.469289164941339", distinctSitesP: "0.525879917184265", brLenTotal: "1.2844" },
  { geneTranscript: "NM_001372073.1", parsimonySitesP: "0.264888888888889", constantSitesP: "0.603555555555556", distinctSitesP: "0.401777777777778", brLenTotal: "1.1498" },
  { geneTranscript: "NM_001372108.2", parsimonySitesP: "0.280547409579668", constantSitesP: "0.580645161290323", distinctSitesP: "0.429130009775171", brLenTotal: "1.1615" },
  { geneTranscript: "NM_001374.3", parsimonySitesP: "0.325529542920847", constantSitesP: "0.573021181716834", distinctSitesP: "0.413600891861761", brLenTotal: "1.4942" },
  { geneTranscript: "NM_001374353.1", parsimonySitesP: "0.271722965795624", constantSitesP: "0.567452729976631", distinctSitesP: "0.364138517102188", brLenTotal: "1.3511" },
  { geneTranscript: "NM_001374828.1", parsimonySitesP: "0.128442945474986", constantSitesP: "0.777262507026419", distinctSitesP: "0.186340640809444", brLenTotal: "0.5137" },
  { geneTranscript: "NM_001375834.1", parsimonySitesP: "0.266401590457256", constantSitesP: "0.620941020543406", distinctSitesP: "0.378396288933068", brLenTotal: "1.0528" },
  { geneTranscript: "NM_001375883.1", parsimonySitesP: "0.20478890989288", constantSitesP: "0.660995589161941", distinctSitesP: "0.296786389413989", brLenTotal: "0.9102" },
  { geneTranscript: "NM_001375912.1", parsimonySitesP: "0.230591852421214", constantSitesP: "0.607993850883935", distinctSitesP: "0.341788367922111", brLenTotal: "1.0554" },
  { geneTranscript: "NM_001376013.1", parsimonySitesP: "0.14429012345679", constantSitesP: "0.738811728395062", distinctSitesP: "0.221836419753086", brLenTotal: "0.5622" },
  { geneTranscript: "NM_001376131.1", parsimonySitesP: "0.212425595238095", constantSitesP: "0.611049107142857", distinctSitesP: "0.38188244047619", brLenTotal: "0.7605" },
  { geneTranscript: "NM_001376312.2", parsimonySitesP: "0.172489082969432", constantSitesP: "0.700873362445415", distinctSitesP: "0.27802037845706", brLenTotal: "0.5333" },
  { geneTranscript: "NM_001376342.1", parsimonySitesP: "0.179835390946502", constantSitesP: "0.632921810699588", distinctSitesP: "0.313991769547325", brLenTotal: "0.6741" },
  { geneTranscript: "NM_001376665.1", parsimonySitesP: "0.252309879175551", constantSitesP: "0.595593461265103", distinctSitesP: "0.402985074626866", brLenTotal: "0.9870" },
  { geneTranscript: "NM_001376887.1", parsimonySitesP: "0.326388888888889", constantSitesP: "0.5375", distinctSitesP: "0.470833333333333", brLenTotal: "1.3345" },
  { geneTranscript: "NM_001377440.1", parsimonySitesP: "0.280499519692603", constantSitesP: "0.598463016330451", distinctSitesP: "0.433237271853987", brLenTotal: "0.9587" },
  { geneTranscript: "NM_001378024.1", parsimonySitesP: "0.200063461843567", constantSitesP: "0.667935903537998", distinctSitesP: "0.247501189909567", brLenTotal: "0.8418" },
  { geneTranscript: "NM_001378034.2", parsimonySitesP: "0.191567065073041", constantSitesP: "0.671314741035857", distinctSitesP: "0.301460823373174", brLenTotal: "0.6386" },
  { geneTranscript: "NM_001378067.1", parsimonySitesP: "0.156878963330576", constantSitesP: "0.724841466776951", distinctSitesP: "0.2191894127378", brLenTotal: "0.5148" },
  { geneTranscript: "NM_001378068.1", parsimonySitesP: "0.195722919572292", constantSitesP: "0.642026964202696", distinctSitesP: "0.346350534635053", brLenTotal: "0.6753" },
  { geneTranscript: "NM_001378102.1", parsimonySitesP: "0.292464878671775", constantSitesP: "0.57088122605364", distinctSitesP: "0.432950191570881", brLenTotal: "1.2273" },
  { geneTranscript: "NM_001378122.1", parsimonySitesP: "0.209487424387138", constantSitesP: "0.625278573702642", distinctSitesP: "0.334288443170965", brLenTotal: "0.7567" },
  { geneTranscript: "NM_001378156.1", parsimonySitesP: "0.288180610889774", constantSitesP: "0.580345285524568", distinctSitesP: "0.427622841965471", brLenTotal: "1.2903" },
  { geneTranscript: "NM_001378157.1", parsimonySitesP: "0.24625", constantSitesP: "0.588333333333333", distinctSitesP: "0.400833333333333", brLenTotal: "0.8829" },
  { geneTranscript: "NM_001378204.1", parsimonySitesP: "0.170904925544101", constantSitesP: "0.693012600229095", distinctSitesP: "0.27766323024055", brLenTotal: "0.5480" },
  { geneTranscript: "NM_001378609.3", parsimonySitesP: "0.182178778863862", constantSitesP: "0.681116305425698", distinctSitesP: "0.285026207678141", brLenTotal: "0.6664" },
  { geneTranscript: "NM_001378615.1", parsimonySitesP: "0.22119341563786", constantSitesP: "0.644444444444444", distinctSitesP: "0.337860082304527", brLenTotal: "0.7924" },
  { geneTranscript: "NM_001378687.1", parsimonySitesP: "0.114254624591948", constantSitesP: "0.790714544795067", distinctSitesP: "0.173376858904606", brLenTotal: "0.4511" },
  { geneTranscript: "NM_001378902.1", parsimonySitesP: "0.221700128150363", constantSitesP: "0.614979353552613", distinctSitesP: "0.353267834258864", brLenTotal: "0.7679" },
  { geneTranscript: "NM_001379081.2", parsimonySitesP: "0.247208199479884", constantSitesP: "0.592626587119474", distinctSitesP: "0.374024782010096", brLenTotal: "0.9093" },
  { geneTranscript: "NM_001379200.1", parsimonySitesP: "0.173941798941799", constantSitesP: "0.712962962962963", distinctSitesP: "0.316798941798942", brLenTotal: "0.8341" },
  { geneTranscript: "NM_001379210.1", parsimonySitesP: "0.183698296836983", constantSitesP: "0.671532846715328", distinctSitesP: "0.339416058394161", brLenTotal: "0.6709" },
  { geneTranscript: "NM_001381874.1", parsimonySitesP: "0.341071428571429", constantSitesP: "0.44702380952381", distinctSitesP: "0.573214285714286", brLenTotal: "1.4943" },
  { geneTranscript: "NM_001381946.1", parsimonySitesP: "0.291803278688525", constantSitesP: "0.573770491803279", distinctSitesP: "0.443715846994536", brLenTotal: "1.0640" },
  { geneTranscript: "NM_001382266.1", parsimonySitesP: "0.183183183183183", constantSitesP: "0.714714714714715", distinctSitesP: "0.262762762762763", brLenTotal: "0.8814" },
  { geneTranscript: "NM_001382273.1", parsimonySitesP: "0.203159557661927", constantSitesP: "0.682148499210111", distinctSitesP: "0.268878357030016", brLenTotal: "0.8731" },
  { geneTranscript: "NM_001382293.1", parsimonySitesP: "0.120879120879121", constantSitesP: "0.706959706959707", distinctSitesP: "0.333333333333333", brLenTotal: "0.4729" },
  { geneTranscript: "NM_001382391.1", parsimonySitesP: "0.186786296900489", constantSitesP: "0.676454594888526", distinctSitesP: "0.307232191408374", brLenTotal: "0.6001" },
  { geneTranscript: "NM_001382507.1", parsimonySitesP: "0.206666666666667", constantSitesP: "0.641666666666667", distinctSitesP: "0.4", brLenTotal: "0.7482" },
  { geneTranscript: "NM_001384361.1", parsimonySitesP: "0.226424609178013", constantSitesP: "0.59505799293999", distinctSitesP: "0.373171961674231", brLenTotal: "0.7837" },
  { geneTranscript: "NM_001384597.1", parsimonySitesP: "0.142857142857143", constantSitesP: "0.726190476190476", distinctSitesP: "0.416666666666667", brLenTotal: "0.5260" },
  { geneTranscript: "NM_001384743.1", parsimonySitesP: "0.307228915662651", constantSitesP: "0.547523427041499", distinctSitesP: "0.424364123159304", brLenTotal: "1.4179" },
  { geneTranscript: "NM_001384994.1", parsimonySitesP: "0.303571428571429", constantSitesP: "0.514880952380952", distinctSitesP: "0.514880952380952", brLenTotal: "1.0088" },
  { geneTranscript: "NM_001384995.1", parsimonySitesP: "0.259315977539561", constantSitesP: "0.59315977539561", distinctSitesP: "0.350689127105666", brLenTotal: "0.9687" },
  { geneTranscript: "NM_001385125.1", parsimonySitesP: "0.253140096618357", constantSitesP: "0.542028985507246", distinctSitesP: "0.447342995169082", brLenTotal: "1.0820" },
  { geneTranscript: "NM_001385224.1", parsimonySitesP: "0.20957095709571", constantSitesP: "0.65016501650165", distinctSitesP: "0.354785478547855", brLenTotal: "1.2081" },
  { geneTranscript: "NM_001385641.1", parsimonySitesP: "0.285150078988942", constantSitesP: "0.556477093206951", distinctSitesP: "0.409162717219589", brLenTotal: "1.1231" },
  { geneTranscript: "NM_001385745.1", parsimonySitesP: "0.109100877192982", constantSitesP: "0.813048245614035", distinctSitesP: "0.182017543859649", brLenTotal: "0.6166" },
  { geneTranscript: "NM_001385981.1", parsimonySitesP: "0.271353684859698", constantSitesP: "0.583718778908418", distinctSitesP: "0.357693493678693", brLenTotal: "1.1083" },
  { geneTranscript: "NM_001386014.1", parsimonySitesP: "0.291878172588832", constantSitesP: "0.560913705583756", distinctSitesP: "0.418781725888325", brLenTotal: "1.8278" },
  { geneTranscript: "NM_001386140.1", parsimonySitesP: "0.249067859806115", constantSitesP: "0.610365398956003", distinctSitesP: "0.401565995525727", brLenTotal: "0.9285" },
  { geneTranscript: "NM_001386375.1", parsimonySitesP: "0.264851485148515", constantSitesP: "0.581683168316832", distinctSitesP: "0.429042904290429", brLenTotal: "1.0243" },
  { geneTranscript: "NM_001386800.1", parsimonySitesP: "0.327536231884058", constantSitesP: "0.469565217391304", distinctSitesP: "0.573913043478261", brLenTotal: "1.1940" },
  { geneTranscript: "NM_001386888.1", parsimonySitesP: "0.188785727289277", constantSitesP: "0.72200983069361", distinctSitesP: "0.24340069178955", brLenTotal: "0.8360" },
  { geneTranscript: "NM_001386928.1", parsimonySitesP: "0.133928571428571", constantSitesP: "0.779761904761905", distinctSitesP: "0.267857142857143", brLenTotal: "0.4303" },
  { geneTranscript: "NM_001386936.1", parsimonySitesP: "0.206954570947841", constantSitesP: "0.694709291456347", distinctSitesP: "0.2456533931576", brLenTotal: "0.9676" },
  { geneTranscript: "NM_001386972.1", parsimonySitesP: "0.255208333333333", constantSitesP: "0.510416666666667", distinctSitesP: "0.461805555555556", brLenTotal: "1.0437" },
  { geneTranscript: "NM_001386974.1", parsimonySitesP: "0.231430325352977", constantSitesP: "0.65438919582566", distinctSitesP: "0.30877839165132", brLenTotal: "1.1788" },
  { geneTranscript: "NM_001387010.1", parsimonySitesP: "0.310405643738977", constantSitesP: "0.477954144620811", distinctSitesP: "0.518518518518519", brLenTotal: "1.4428" },
  { geneTranscript: "NM_001387468.1", parsimonySitesP: "0.162297128589263", constantSitesP: "0.704119850187266", distinctSitesP: "0.298377028714107", brLenTotal: "0.5428" },
  { geneTranscript: "NM_001387567.1", parsimonySitesP: "0.333952912019827", constantSitesP: "0.576827757125155", distinctSitesP: "0.410780669144981", brLenTotal: "1.5583" },
  { geneTranscript: "NM_001387579.1", parsimonySitesP: "0.115682371156824", constantSitesP: "0.786109267861093", distinctSitesP: "0.159920371599204", brLenTotal: "0.3813" },
  { geneTranscript: "NM_001387690.1", parsimonySitesP: "0.183395291201983", constantSitesP: "0.672862453531599", distinctSitesP: "0.308550185873606", brLenTotal: "0.8150" },
  { geneTranscript: "NM_001387844.1", parsimonySitesP: "0.184840119622728", constantSitesP: "0.687715665976536", distinctSitesP: "0.267425810904072", brLenTotal: "0.6358" },
  { geneTranscript: "NM_001387889.1", parsimonySitesP: "0.2110365398956", constantSitesP: "0.705816554809843", distinctSitesP: "0.293810589112603", brLenTotal: "0.9327" },
  { geneTranscript: "NM_001389244.1", parsimonySitesP: "0.305491105955143", constantSitesP: "0.528228924980665", distinctSitesP: "0.461716937354988", brLenTotal: "1.3559" },
  { geneTranscript: "NM_001389445.1", parsimonySitesP: "0.225352112676056", constantSitesP: "0.63943661971831", distinctSitesP: "0.331455399061033", brLenTotal: "0.7429" },
  { geneTranscript: "NM_001389466.1", parsimonySitesP: "0.322789943227899", constantSitesP: "0.551500405515004", distinctSitesP: "0.450121654501217", brLenTotal: "1.5568" },
  { geneTranscript: "NM_001389683.1", parsimonySitesP: "0.294170004450378", constantSitesP: "0.583222073876279", distinctSitesP: "0.38785046728972", brLenTotal: "1.1956" },
  { geneTranscript: "NM_001391974.1", parsimonySitesP: "0.390728476821192", constantSitesP: "0.403973509933775", distinctSitesP: "0.584988962472406", brLenTotal: "2.2395" },
  { geneTranscript: "NM_001393402.2", parsimonySitesP: "0.280519480519481", constantSitesP: "0.561038961038961", distinctSitesP: "0.437229437229437", brLenTotal: "1.6185" },
  { geneTranscript: "NM_001393504.1", parsimonySitesP: "0.252412769116555", constantSitesP: "0.629547141796585", distinctSitesP: "0.329373917347191", brLenTotal: "1.3117" },
  { geneTranscript: "NM_001393816.1", parsimonySitesP: "0.308333333333333", constantSitesP: "0.483333333333333", distinctSitesP: "0.554166666666667", brLenTotal: "1.1088" },
  { geneTranscript: "NM_001393887.1", parsimonySitesP: "0.341991341991342", constantSitesP: "0.476190476190476", distinctSitesP: "0.538961038961039", brLenTotal: "1.4710" },
  { geneTranscript: "NM_001394015.1", parsimonySitesP: "0.188584877905266", constantSitesP: "0.706384230656075", distinctSitesP: "0.235951750514857", brLenTotal: "1.0863" },
  { geneTranscript: "NM_001393918.1", parsimonySitesP: "0.281796690307329", constantSitesP: "0.59338061465721", distinctSitesP: "0.414184397163121", brLenTotal: "1.5428" },
  { geneTranscript: "NM_001394028.1", parsimonySitesP: "0.316151202749141", constantSitesP: "0.443298969072165", distinctSitesP: "0.577319587628866", brLenTotal: "1.2317" },
  { geneTranscript: "NM_001394067.2", parsimonySitesP: "0.180522088353414", constantSitesP: "0.697791164658635", distinctSitesP: "0.239759036144578", brLenTotal: "0.9867" },
  { geneTranscript: "NM_001394372.1", parsimonySitesP: "0.228632478632479", constantSitesP: "0.655769230769231", distinctSitesP: "0.311538461538462", brLenTotal: "1.2588" },
  { geneTranscript: "NM_001394395.1", parsimonySitesP: "0.157494407158837", constantSitesP: "0.732885906040268", distinctSitesP: "0.227964205816555", brLenTotal: "0.5372" },
  { geneTranscript: "NM_001394396.1", parsimonySitesP: "0.271121351766513", constantSitesP: "0.579877112135177", distinctSitesP: "0.380952380952381", brLenTotal: "1.2342" },
  { geneTranscript: "NM_001394477.1", parsimonySitesP: "0.366666666666667", constantSitesP: "0.462365591397849", distinctSitesP: "0.553763440860215", brLenTotal: "1.5707" },
  { geneTranscript: "NM_001394.7", parsimonySitesP: "0.181049069373942", constantSitesP: "0.677664974619289", distinctSitesP: "0.268189509306261", brLenTotal: "1.5862" },
  { geneTranscript: "NM_001394755.1", parsimonySitesP: "0.187533875338753", constantSitesP: "0.711111111111111", distinctSitesP: "0.276964769647696", brLenTotal: "0.7718" },
  { geneTranscript: "NM_001394787.1", parsimonySitesP: "0.321759259259259", constantSitesP: "0.516203703703704", distinctSitesP: "0.518518518518519", brLenTotal: "1.0696" },
  { geneTranscript: "NM_001394837.1", parsimonySitesP: "0.199269692227439", constantSitesP: "0.722483046426708", distinctSitesP: "0.268648930620762", brLenTotal: "0.8433" },
  { geneTranscript: "NM_001394922.1", parsimonySitesP: "0.333333333333333", constantSitesP: "0.551282051282051", distinctSitesP: "0.487179487179487", brLenTotal: "1.1841" },
  { geneTranscript: "NM_001394954.1", parsimonySitesP: "0.213667561921814", constantSitesP: "0.663682482840943", distinctSitesP: "0.324380781856162", brLenTotal: "0.7706" },
  { geneTranscript: "NM_001394955.1", parsimonySitesP: "0.20504009163803", constantSitesP: "0.627720504009164", distinctSitesP: "0.355097365406644", brLenTotal: "0.6515" },
  { geneTranscript: "NM_001394958.1", parsimonySitesP: "0.259259259259259", constantSitesP: "0.589225589225589", distinctSitesP: "0.420875420875421", brLenTotal: "0.9272" },
  { geneTranscript: "NM_001394959.1", parsimonySitesP: "0.223241590214067", constantSitesP: "0.631192660550459", distinctSitesP: "0.332721712538226", brLenTotal: "0.9951" },
  { geneTranscript: "NM_001394961.1", parsimonySitesP: "0.222222222222222", constantSitesP: "0.701149425287356", distinctSitesP: "0.333333333333333", brLenTotal: "0.6524" },
  { geneTranscript: "NM_001395207.1", parsimonySitesP: "0.248974358974359", constantSitesP: "0.646666666666667", distinctSitesP: "0.335897435897436", brLenTotal: "1.1043" },
  { geneTranscript: "NM_001395273.1", parsimonySitesP: "0.2", constantSitesP: "0.678504672897196", distinctSitesP: "0.307165109034268", brLenTotal: "0.8346" },
  { geneTranscript: "NM_001395400.1", parsimonySitesP: "0.286634460547504", constantSitesP: "0.537842190016103", distinctSitesP: "0.420826623725174", brLenTotal: "1.1269" },
  { geneTranscript: "NM_001395425.1", parsimonySitesP: "0.307535641547862", constantSitesP: "0.505770536320434", distinctSitesP: "0.457569585879158", brLenTotal: "1.3369" },
  { geneTranscript: "NM_001395467.1", parsimonySitesP: "0.235023041474654", constantSitesP: "0.608294930875576", distinctSitesP: "0.3963133640553", brLenTotal: "0.7665" },
  { geneTranscript: "NM_001395513.1", parsimonySitesP: "0.312900274473925", constantSitesP: "0.549862763037511", distinctSitesP: "0.433363830436109", brLenTotal: "1.4561" },
  { geneTranscript: "NM_001395648.1", parsimonySitesP: "0.185185185185185", constantSitesP: "0.678491744756805", distinctSitesP: "0.316599732262383", brLenTotal: "0.5806" },
  { geneTranscript: "NM_001395656.1", parsimonySitesP: "0.165648336727766", constantSitesP: "0.735234215885947", distinctSitesP: "0.223127404390134", brLenTotal: "0.7151" },
  { geneTranscript: "NM_001395936.1", parsimonySitesP: "0.572649572649573", constantSitesP: "0.275641025641026", distinctSitesP: "0.726495726495726", brLenTotal: "3.2551" },
  { geneTranscript: "NM_001395961.1", parsimonySitesP: "0.330779054916986", constantSitesP: "0.48786717752235", distinctSitesP: "0.496807151979566", brLenTotal: "1.3389" },
  { geneTranscript: "NM_001395999.1", parsimonySitesP: "0.418079096045198", constantSitesP: "0.319209039548023", distinctSitesP: "0.709039548022599", brLenTotal: "1.5743" },
  { geneTranscript: "NM_001396071.1", parsimonySitesP: "0.35969868173258", constantSitesP: "0.44256120527307", distinctSitesP: "0.627118644067797", brLenTotal: "1.4422" },
  { geneTranscript: "NM_001396072.1", parsimonySitesP: "0.20316301703163", constantSitesP: "0.677615571776156", distinctSitesP: "0.335766423357664", brLenTotal: "0.6197" },
  { geneTranscript: "NM_001397372.1", parsimonySitesP: "0.507109004739336", constantSitesP: "0.32306477093207", distinctSitesP: "0.675355450236967", brLenTotal: "2.9788" },
  { geneTranscript: "NM_001397406.1", parsimonySitesP: "0.273224043715847", constantSitesP: "0.588342440801457", distinctSitesP: "0.424408014571949", brLenTotal: "1.0800" },
  { geneTranscript: "NM_001398.3", parsimonySitesP: "0.274390243902439", constantSitesP: "0.588414634146341", distinctSitesP: "0.434959349593496", brLenTotal: "1.2315" },
  { geneTranscript: "NM_001405.4", parsimonySitesP: "0.195618153364632", constantSitesP: "0.68075117370892", distinctSitesP: "0.306729264475743", brLenTotal: "0.7882" },
  { geneTranscript: "NM_001412.4", parsimonySitesP: "0.180555555555556", constantSitesP: "0.752314814814815", distinctSitesP: "0.282407407407407", brLenTotal: "0.7257" },
  { geneTranscript: "NM_001424.6", parsimonySitesP: "0.267465069860279", constantSitesP: "0.610778443113772", distinctSitesP: "0.43313373253493", brLenTotal: "1.3344" },
  { geneTranscript: "NM_001431.4", parsimonySitesP: "0.1681592039801", constantSitesP: "0.706135986733002", distinctSitesP: "0.251741293532338", brLenTotal: "0.6461" },
  { geneTranscript: "NM_001433.5", parsimonySitesP: "0.19481405663596", constantSitesP: "0.689525759126578", distinctSitesP: "0.271238485158649", brLenTotal: "0.9545" },
  { geneTranscript: "NM_001437.3", parsimonySitesP: "0.232704402515723", constantSitesP: "0.640251572327044", distinctSitesP: "0.344654088050314", brLenTotal: "0.8855" },
  { geneTranscript: "NM_001442.3", parsimonySitesP: "0.207070707070707", constantSitesP: "0.661616161616162", distinctSitesP: "0.351010101010101", brLenTotal: "0.7463" },
  { geneTranscript: "NM_001443.3", parsimonySitesP: "0.225721784776903", constantSitesP: "0.656167979002625", distinctSitesP: "0.36745406824147", brLenTotal: "0.9734" },
  { geneTranscript: "NM_001459.4", parsimonySitesP: "0.304964539007092", constantSitesP: "0.553191489361702", distinctSitesP: "0.459574468085106", brLenTotal: "1.0783" },
  { geneTranscript: "NM_001460.5", parsimonySitesP: "0.300311526479751", constantSitesP: "0.544548286604361", distinctSitesP: "0.421183800623053", brLenTotal: "1.1924" },
  { geneTranscript: "NM_001465.6", parsimonySitesP: "0.218737434660233", constantSitesP: "0.631282669883394", distinctSitesP: "0.349819059107358", brLenTotal: "0.7857" },
  { geneTranscript: "NM_001483.3", parsimonySitesP: "0.18997668997669", constantSitesP: "0.717948717948718", distinctSitesP: "0.272727272727273", brLenTotal: "0.5708" },
  { geneTranscript: "NM_001490.5", parsimonySitesP: "0.327102803738318", constantSitesP: "0.457943925233645", distinctSitesP: "0.496105919003115", brLenTotal: "1.6054" },
  { geneTranscript: "NM_001494.4", parsimonySitesP: "0.204494382022472", constantSitesP: "0.67565543071161", distinctSitesP: "0.302621722846442", brLenTotal: "0.9137" },
  { geneTranscript: "NM_001502.4", parsimonySitesP: "0.323970037453184", constantSitesP: "0.502496878901373", distinctSitesP: "0.502496878901373", brLenTotal: "1.3645" },
  { geneTranscript: "NM_001508.3", parsimonySitesP: "0.294334069168506", constantSitesP: "0.572479764532745", distinctSitesP: "0.417954378219279", brLenTotal: "1.0902" },
  { geneTranscript: "NM_001509.3", parsimonySitesP: "0.375565610859729", constantSitesP: "0.42684766214178", distinctSitesP: "0.586726998491704", brLenTotal: "1.9130" },
  { geneTranscript: "NM_001519.4", parsimonySitesP: "0.224519940915805", constantSitesP: "0.664204825209257", distinctSitesP: "0.34219596258001", brLenTotal: "1.3454" },
  { geneTranscript: "NM_001520.4", parsimonySitesP: "0.230282914493441", constantSitesP: "0.635530267109214", distinctSitesP: "0.309309309309309", brLenTotal: "1.0135" },
  { geneTranscript: "NM_001522.3", parsimonySitesP: "0.160950661853189", constantSitesP: "0.670878459687124", distinctSitesP: "0.24849578820698", brLenTotal: "0.7229" },
  { geneTranscript: "NM_001528.4", parsimonySitesP: "0.312977099236641", constantSitesP: "0.557251908396947", distinctSitesP: "0.413740458015267", brLenTotal: "1.4739" },
  { geneTranscript: "NM_001546.4", parsimonySitesP: "0.111801242236025", constantSitesP: "0.654244306418219", distinctSitesP: "0.287784679089027", brLenTotal: "0.8104" },
  { geneTranscript: "NM_001553.3", parsimonySitesP: "0.147754137115839", constantSitesP: "0.745862884160757", distinctSitesP: "0.24822695035461", brLenTotal: "0.6051" },
  { geneTranscript: "NM_001558.4", parsimonySitesP: "0.342560553633218", constantSitesP: "0.482122260668973", distinctSitesP: "0.531718569780854", brLenTotal: "1.2461" },
  { geneTranscript: "NM_001569.4", parsimonySitesP: "0.263576779026217", constantSitesP: "0.598314606741573", distinctSitesP: "0.375", brLenTotal: "0.9681" },
  { geneTranscript: "NM_001570.4", parsimonySitesP: "0.278933333333333", constantSitesP: "0.5536", distinctSitesP: "0.434666666666667", brLenTotal: "1.1498" },
  { geneTranscript: "NM_001571.6", parsimonySitesP: "0.265417642466823", constantSitesP: "0.604215456674473", distinctSitesP: "0.398907103825137", brLenTotal: "1.0993" },
  { geneTranscript: "NM_001611.5", parsimonySitesP: "0.242051282051282", constantSitesP: "0.634871794871795", distinctSitesP: "0.342564102564103", brLenTotal: "1.0814" },
  { geneTranscript: "NM_001623.5", parsimonySitesP: "0.192743764172336", constantSitesP: "0.673469387755102", distinctSitesP: "0.349206349206349", brLenTotal: "0.6388" },
  { geneTranscript: "NM_001627.4", parsimonySitesP: "0.166952544311035", constantSitesP: "0.725557461406518", distinctSitesP: "0.253287592910234", brLenTotal: "0.5321" },
  { geneTranscript: "NM_001635.4", parsimonySitesP: "0.191846522781775", constantSitesP: "0.691127098321343", distinctSitesP: "0.309832134292566", brLenTotal: "0.8473" },
  { geneTranscript: "NM_001652.4", parsimonySitesP: "0.26241134751773", constantSitesP: "0.608747044917258", distinctSitesP: "0.373522458628842", brLenTotal: "1.1087" },
  { geneTranscript: "NM_001677.4", parsimonySitesP: "0.182618261826183", constantSitesP: "0.695269526952695", distinctSitesP: "0.288228822882288", brLenTotal: "0.6902" },
  { geneTranscript: "NM_001688.5", parsimonySitesP: "0.307291666666667", constantSitesP: "0.493489583333333", distinctSitesP: "0.522135416666667", brLenTotal: "1.3347" },
  { geneTranscript: "NM_001697.3", parsimonySitesP: "0.231611893583725", constantSitesP: "0.604068857589984", distinctSitesP: "0.402190923317684", brLenTotal: "0.8732" },
  { geneTranscript: "NM_001698.3", parsimonySitesP: "0.195673549655851", constantSitesP: "0.672566371681416", distinctSitesP: "0.340216322517207", brLenTotal: "0.7729" },
  { geneTranscript: "NM_001710.6", parsimonySitesP: "0.220767888307155", constantSitesP: "0.630890052356021", distinctSitesP: "0.306282722513089", brLenTotal: "0.8389" },
  { geneTranscript: "NM_001715.3", parsimonySitesP: "0.278547854785479", constantSitesP: "0.57953795379538", distinctSitesP: "0.410561056105611", brLenTotal: "1.3737" },
  { geneTranscript: "NM_001730.5", parsimonySitesP: "0.137855579868709", constantSitesP: "0.738147337709701", distinctSitesP: "0.257476294675419", brLenTotal: "0.4711" },
  { geneTranscript: "NM_001744.6", parsimonySitesP: "0.181818181818182", constantSitesP: "0.722339675828048", distinctSitesP: "0.27906976744186", brLenTotal: "0.6312" },
  { geneTranscript: "NM_001746.4", parsimonySitesP: "0.155405405405405", constantSitesP: "0.727477477477477", distinctSitesP: "0.226914414414414", brLenTotal: "0.5793" },
  { geneTranscript: "NM_001756.4", parsimonySitesP: "0.34238683127572", constantSitesP: "0.482304526748971", distinctSitesP: "0.529218106995885", brLenTotal: "1.3643" },
  { geneTranscript: "NM_001761.3", parsimonySitesP: "0.253604749787956", constantSitesP: "0.615351993214589", distinctSitesP: "0.354537743850721", brLenTotal: "1.0699" },
  { geneTranscript: "NM_001770.6", parsimonySitesP: "0.296163069544365", constantSitesP: "0.543764988009592", distinctSitesP: "0.447242206235012", brLenTotal: "1.0832" },
  { geneTranscript: "NM_001774.3", parsimonySitesP: "0.230130486358244", constantSitesP: "0.62514827995255", distinctSitesP: "0.368920521945433", brLenTotal: "0.8149" },
  { geneTranscript: "NM_001776.6", parsimonySitesP: "0.244444444444444", constantSitesP: "0.622875816993464", distinctSitesP: "0.377777777777778", brLenTotal: "0.8424" },
  { geneTranscript: "NM_001780.6", parsimonySitesP: "0.219887955182073", constantSitesP: "0.658263305322129", distinctSitesP: "0.331932773109244", brLenTotal: "0.7885" },
  { geneTranscript: "NM_001788.6", parsimonySitesP: "0.156369183829138", constantSitesP: "0.698703279938978", distinctSitesP: "0.283752860411899", brLenTotal: "0.6736" },
  { geneTranscript: "NM_001804.3", parsimonySitesP: "0.251572327044025", constantSitesP: "0.610062893081761", distinctSitesP: "0.372327044025157", brLenTotal: "0.9669" },
  { geneTranscript: "NM_001816.4", parsimonySitesP: "0.462273161413563", constantSitesP: "0.360076408787011", distinctSitesP: "0.655205348615091", brLenTotal: "2.5988" },
  { geneTranscript: "NM_001832.4", parsimonySitesP: "0.336309523809524", constantSitesP: "0.538690476190476", distinctSitesP: "0.491071428571429", brLenTotal: "1.5767" },
  { geneTranscript: "NM_001844.5", parsimonySitesP: "0.145258910558171", constantSitesP: "0.769782559964134", distinctSitesP: "0.221699170589554", brLenTotal: "0.5755" },
  { geneTranscript: "NM_001845.6", parsimonySitesP: "0.239664469742361", constantSitesP: "0.667066107449571", distinctSitesP: "0.315358498102656", brLenTotal: "1.0489" },
  { geneTranscript: "NM_001852.4", parsimonySitesP: "0.213836477987421", constantSitesP: "0.658442186744074", distinctSitesP: "0.315432994678278", brLenTotal: "0.8558" },
  { geneTranscript: "NM_001855.5", parsimonySitesP: "0.266330451488953", constantSitesP: "0.576368876080692", distinctSitesP: "0.409702209414025", brLenTotal: "1.0769" },
  { geneTranscript: "NM_001856.4", parsimonySitesP: "0.212801330008313", constantSitesP: "0.659808811305071", distinctSitesP: "0.296965918536991", brLenTotal: "0.8895" },
  { geneTranscript: "NM_001860.3", parsimonySitesP: "0.24009324009324", constantSitesP: "0.610722610722611", distinctSitesP: "0.41025641025641", brLenTotal: "0.7742" },
  { geneTranscript: "NM_001862.3", parsimonySitesP: "0.291989664082687", constantSitesP: "0.583979328165375", distinctSitesP: "0.503875968992248", brLenTotal: "0.9853" },
  { geneTranscript: "NM_001863.5", parsimonySitesP: "0.298449612403101", constantSitesP: "0.565891472868217", distinctSitesP: "0.515503875968992", brLenTotal: "1.0744" },
  { geneTranscript: "NM_001869.3", parsimonySitesP: "0.242641209228321", constantSitesP: "0.606205250596659", distinctSitesP: "0.378679395385839", brLenTotal: "0.8845" },
  { geneTranscript: "NM_001870.4", parsimonySitesP: "0.227018385291767", constantSitesP: "0.600319744204636", distinctSitesP: "0.411670663469225", brLenTotal: "0.8405" },
  { geneTranscript: "NM_001871.3", parsimonySitesP: "0.268585131894484", constantSitesP: "0.609912070343725", distinctSitesP: "0.433253397282174", brLenTotal: "1.0360" },
  { geneTranscript: "NM_001876.4", parsimonySitesP: "0.264769297110824", constantSitesP: "0.651573954290643", distinctSitesP: "0.364381198792583", brLenTotal: "1.2179" },
  { geneTranscript: "NM_001887.4", parsimonySitesP: "0.281746031746032", constantSitesP: "0.612433862433862", distinctSitesP: "0.399470899470899", brLenTotal: "1.0987" },
  { geneTranscript: "NM_001889.4", parsimonySitesP: "0.250253292806484", constantSitesP: "0.558257345491388", distinctSitesP: "0.437689969604863", brLenTotal: "1.0437" },
  { geneTranscript: "NM_001897.5", parsimonySitesP: "0.344243468274476", constantSitesP: "0.462388745334482", distinctSitesP: "0.453057708871662", brLenTotal: "1.6832" },
  { geneTranscript: "NM_001902.6", parsimonySitesP: "0.214814814814815", constantSitesP: "0.637037037037037", distinctSitesP: "0.376954732510288", brLenTotal: "0.8743" },
  { geneTranscript: "NM_001908.5", parsimonySitesP: "0.265486725663717", constantSitesP: "0.632251720747296", distinctSitesP: "0.375614552605703", brLenTotal: "1.1934" },
  { geneTranscript: "NM_001910.4", parsimonySitesP: "0.264309764309764", constantSitesP: "0.546296296296296", distinctSitesP: "0.412457912457912", brLenTotal: "1.3485" },
  { geneTranscript: "NM_001909.5", parsimonySitesP: "0.317152103559871", constantSitesP: "0.527508090614887", distinctSitesP: "0.450647249190939", brLenTotal: "1.7168" },
  { geneTranscript: "NM_001915.4", parsimonySitesP: "0.269588313413015", constantSitesP: "0.618857901726428", distinctSitesP: "0.377158034528552", brLenTotal: "1.1666" },
  { geneTranscript: "NM_001917.5", parsimonySitesP: "0.258405379442843", constantSitesP: "0.58597502401537", distinctSitesP: "0.401536983669549", brLenTotal: "0.9620" },
  { geneTranscript: "NM_001919.4", parsimonySitesP: "0.339955849889625", constantSitesP: "0.509933774834437", distinctSitesP: "0.503311258278146", brLenTotal: "1.4674" },
  { geneTranscript: "NM_001935.4", parsimonySitesP: "0.156222802436902", constantSitesP: "0.744560487380331", distinctSitesP: "0.27023498694517", brLenTotal: "0.5177" },
  { geneTranscript: "NM_001937.5", parsimonySitesP: "0.154228855721393", constantSitesP: "0.777777777777778", distinctSitesP: "0.230514096185738", brLenTotal: "0.5123" },
  { geneTranscript: "NM_001951.4", parsimonySitesP: "0.145472061657033", constantSitesP: "0.719653179190751", distinctSitesP: "0.301541425818882", brLenTotal: "0.5291" },
  { geneTranscript: "NM_001956.5", parsimonySitesP: "0.265917602996255", constantSitesP: "0.573033707865169", distinctSitesP: "0.455056179775281", brLenTotal: "0.9444" },
  { geneTranscript: "NM_001983.4", parsimonySitesP: "0.236812570145903", constantSitesP: "0.640852974186308", distinctSitesP: "0.359147025813692", brLenTotal: "0.8884" },
  { geneTranscript: "NM_001990.4", parsimonySitesP: "0.116928446771379", constantSitesP: "0.782431646305992", distinctSitesP: "0.180337405468296", brLenTotal: "0.4304" },
  { geneTranscript: "NM_002007.4", parsimonySitesP: "0.207119741100324", constantSitesP: "0.655339805825243", distinctSitesP: "0.333333333333333", brLenTotal: "1.1068" },
  { geneTranscript: "NM_002014.4", parsimonySitesP: "0.182280319535221", constantSitesP: "0.676107480029049", distinctSitesP: "0.294843863471314", brLenTotal: "0.6588" },
  { geneTranscript: "NM_002042.5", parsimonySitesP: "0.189979123173278", constantSitesP: "0.720250521920668", distinctSitesP: "0.278357689631176", brLenTotal: "0.7405" },
  { geneTranscript: "NM_002045.4", parsimonySitesP: "0.179271708683473", constantSitesP: "0.703081232492997", distinctSitesP: "0.333333333333333", brLenTotal: "0.6879" },
  { geneTranscript: "NM_002048.3", parsimonySitesP: "0.182608695652174", constantSitesP: "0.684057971014493", distinctSitesP: "0.303381642512077", brLenTotal: "0.7581" },
  { geneTranscript: "NM_002076.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.685386473429952", distinctSitesP: "0.309178743961353", brLenTotal: "0.7589" },
  { geneTranscript: "NM_002077.4", parsimonySitesP: "0.18948283355063", constantSitesP: "0.664928292046936", distinctSitesP: "0.318557149065624", brLenTotal: "0.7632" },
  { geneTranscript: "NM_002078.5", parsimonySitesP: "0.21270553064275", constantSitesP: "0.581614349775785", distinctSitesP: "0.370254110612855", brLenTotal: "0.8018" },
  { geneTranscript: "NM_002092.4", parsimonySitesP: "0.214583333333333", constantSitesP: "0.601388888888889", distinctSitesP: "0.389583333333333", brLenTotal: "0.8430" },
  { geneTranscript: "NM_002095.6", parsimonySitesP: "0.142038946162658", constantSitesP: "0.768613974799542", distinctSitesP: "0.245131729667812", brLenTotal: "0.5072" },
  { geneTranscript: "NM_002097.3", parsimonySitesP: "0.231050228310502", constantSitesP: "0.606392694063927", distinctSitesP: "0.423744292237443", brLenTotal: "0.9674" },
  { geneTranscript: "NM_002108.4", parsimonySitesP: "0.193810248604769", constantSitesP: "0.704211060375444", distinctSitesP: "0.288685946220193", brLenTotal: "0.8926" },
  { geneTranscript: "NM_002117.6", parsimonySitesP: "0.330601092896175", constantSitesP: "0.458105646630237", distinctSitesP: "0.516393442622951", brLenTotal: "2.5116" },
  { geneTranscript: "NM_002119.4", parsimonySitesP: "0.333333333333333", constantSitesP: "0.469333333333333", distinctSitesP: "0.508", brLenTotal: "2.1929" },
  { geneTranscript: "NM_002120.4", parsimonySitesP: "0.358974358974359", constantSitesP: "0.433455433455433", distinctSitesP: "0.556776556776557", brLenTotal: "1.6058" },
  { geneTranscript: "NM_002121.6", parsimonySitesP: "0.467700258397933", constantSitesP: "0.322997416020672", distinctSitesP: "0.670542635658915", brLenTotal: "3.0308" },
  { geneTranscript: "NM_002122.5", parsimonySitesP: "0.426143790849673", constantSitesP: "0.430065359477124", distinctSitesP: "0.597385620915033", brLenTotal: "2.6067" },
  { geneTranscript: "NM_002180.3", parsimonySitesP: "0.29607250755287", constantSitesP: "0.556562604900973", distinctSitesP: "0.422960725075529", brLenTotal: "1.4069" },
  { geneTranscript: "NM_002182.4", parsimonySitesP: "0.153216374269006", constantSitesP: "0.745614035087719", distinctSitesP: "0.250877192982456", brLenTotal: "0.4986" },
  { geneTranscript: "NM_002187.3", parsimonySitesP: "0.288617886178862", constantSitesP: "0.561991869918699", distinctSitesP: "0.442073170731707", brLenTotal: "1.0210" },
  { geneTranscript: "NM_002201.6", parsimonySitesP: "0.270718232044199", constantSitesP: "0.594843462246777", distinctSitesP: "0.414364640883978", brLenTotal: "1.2164" },
  { geneTranscript: "NM_002203.4", parsimonySitesP: "0.197854925204629", constantSitesP: "0.668360146768275", distinctSitesP: "0.30962461191081", brLenTotal: "0.7343" },
  { geneTranscript: "NM_002211.4", parsimonySitesP: "0.155388471177945", constantSitesP: "0.756474519632414", distinctSitesP: "0.231829573934837", brLenTotal: "0.6791" },
  { geneTranscript: "NM_002253.4", parsimonySitesP: "0.216568338249754", constantSitesP: "0.666912487708948", distinctSitesP: "0.322271386430678", brLenTotal: "0.9055" },
  { geneTranscript: "NM_002266.4", parsimonySitesP: "0.187775677378702", constantSitesP: "0.616887208569628", distinctSitesP: "0.340264650283554", brLenTotal: "0.8615" },
  { geneTranscript: "NM_002277.3", parsimonySitesP: "0.278044871794872", constantSitesP: "0.572916666666667", distinctSitesP: "0.40625", brLenTotal: "1.8521" },
  { geneTranscript: "NM_002279.5", parsimonySitesP: "0.291254125412541", constantSitesP: "0.52970297029703", distinctSitesP: "0.429042904290429", brLenTotal: "2.0397" },
  { geneTranscript: "NM_002281.4", parsimonySitesP: "0.222442244224422", constantSitesP: "0.593399339933993", distinctSitesP: "0.42046204620462", brLenTotal: "1.4691" },
  { geneTranscript: "NM_002282.3", parsimonySitesP: "0.229885057471264", constantSitesP: "0.597025016903313", distinctSitesP: "0.411764705882353", brLenTotal: "1.5040" },
  { geneTranscript: "NM_002286.6", parsimonySitesP: "0.283809523809524", constantSitesP: "0.512380952380952", distinctSitesP: "0.454603174603175", brLenTotal: "1.1770" },
  { geneTranscript: "NM_002299.4", parsimonySitesP: "0.28247708008995", constantSitesP: "0.579484518249438", distinctSitesP: "0.35945338176786", brLenTotal: "1.0614" },
  { geneTranscript: "NM_002306.4", parsimonySitesP: "0.296", constantSitesP: "0.544", distinctSitesP: "0.504", brLenTotal: "1.2326" },
  { geneTranscript: "NM_002307.4", parsimonySitesP: "0.257352941176471", constantSitesP: "0.57843137254902", distinctSitesP: "0.446078431372549", brLenTotal: "1.2954" },
  { geneTranscript: "NM_002317.7", parsimonySitesP: "0.230215827338129", constantSitesP: "0.631494804156675", distinctSitesP: "0.364508393285372", brLenTotal: "0.8932" },
  { geneTranscript: "NM_002333.4", parsimonySitesP: "0.233333333333333", constantSitesP: "0.667965367965368", distinctSitesP: "0.310822510822511", brLenTotal: "1.1889" },
  { geneTranscript: "NM_002337.4", parsimonySitesP: "0.295985060690943", constantSitesP: "0.563958916900093", distinctSitesP: "0.448179271708683", brLenTotal: "1.4099" },
  { geneTranscript: "NM_002340.6", parsimonySitesP: "0.262295081967213", constantSitesP: "0.626138433515483", distinctSitesP: "0.365209471766849", brLenTotal: "1.2061" },
  { geneTranscript: "NM_002343.6", parsimonySitesP: "0.358215962441315", constantSitesP: "0.506103286384977", distinctSitesP: "0.504225352112676", brLenTotal: "1.7239" },
  { geneTranscript: "NM_002346.3", parsimonySitesP: "0.346055979643766", constantSitesP: "0.544529262086514", distinctSitesP: "0.513994910941476", brLenTotal: "1.5548" },
  { geneTranscript: "NM_002349.4", parsimonySitesP: "0.223383662408053", constantSitesP: "0.627177700348432", distinctSitesP: "0.33817266744096", brLenTotal: "0.7554" },
  { geneTranscript: "NM_002381.5", parsimonySitesP: "0.251714677640604", constantSitesP: "0.603566529492455", distinctSitesP: "0.362139917695473", brLenTotal: "0.9363" },
  { geneTranscript: "NM_002383.4", parsimonySitesP: "0.113207547169811", constantSitesP: "0.76240391334731", distinctSitesP: "0.271139063591894", brLenTotal: "0.5879" },
  { geneTranscript: "NM_002392.6", parsimonySitesP: "0.186452045606975", constantSitesP: "0.584171696847753", distinctSitesP: "0.36485580147552", brLenTotal: "1.0200" },
  { geneTranscript: "NM_002395.6", parsimonySitesP: "0.202214452214452", constantSitesP: "0.678321678321678", distinctSitesP: "0.323426573426573", brLenTotal: "0.7212" },
  { geneTranscript: "NM_002413.5", parsimonySitesP: "0.222222222222222", constantSitesP: "0.655328798185941", distinctSitesP: "0.374149659863946", brLenTotal: "0.7393" },
  { geneTranscript: "NM_002418.3", parsimonySitesP: "0.28695652173913", constantSitesP: "0.542028985507246", distinctSitesP: "0.498550724637681", brLenTotal: "1.0497" },
  { geneTranscript: "NM_002421.4", parsimonySitesP: "0.258706467661692", constantSitesP: "0.607675906183369", distinctSitesP: "0.394456289978678", brLenTotal: "0.9613" },
  { geneTranscript: "NM_002422.5", parsimonySitesP: "0.308176100628931", constantSitesP: "0.549266247379455", distinctSitesP: "0.459119496855346", brLenTotal: "1.3327" },
  { geneTranscript: "NM_002425.3", parsimonySitesP: "0.327731092436975", constantSitesP: "0.507703081232493", distinctSitesP: "0.490896358543417", brLenTotal: "1.6364" },
  { geneTranscript: "NM_002431.4", parsimonySitesP: "0.137001078748652", constantSitesP: "0.746494066882416", distinctSitesP: "0.235167206040992", brLenTotal: "0.4580" },
  { geneTranscript: "NM_002437.5", parsimonySitesP: "0.170454545454545", constantSitesP: "0.702651515151515", distinctSitesP: "0.297348484848485", brLenTotal: "0.6145" },
  { geneTranscript: "NM_002440.4", parsimonySitesP: "0.165598290598291", constantSitesP: "0.701566951566952", distinctSitesP: "0.281695156695157", brLenTotal: "0.5748" },
  { geneTranscript: "NM_002446.4", parsimonySitesP: "0.211740041928721", constantSitesP: "0.670510132774284", distinctSitesP: "0.281271837875611", brLenTotal: "0.9419" },
  { geneTranscript: "NM_002453.3", parsimonySitesP: "0.202659330582302", constantSitesP: "0.657955066483265", distinctSitesP: "0.342961944062357", brLenTotal: "0.6684" },
  { geneTranscript: "NM_002461.3", parsimonySitesP: "0.298333333333333", constantSitesP: "0.583333333333333", distinctSitesP: "0.415833333333333", brLenTotal: "1.4296" },
  { geneTranscript: "NM_002476.2", parsimonySitesP: "0.231810490693739", constantSitesP: "0.661590524534687", distinctSitesP: "0.355329949238579", brLenTotal: "1.0190" },
  { geneTranscript: "NM_002482.4", parsimonySitesP: "0.191624365482234", constantSitesP: "0.648477157360406", distinctSitesP: "0.328680203045685", brLenTotal: "0.6449" },
  { geneTranscript: "NM_002483.7", parsimonySitesP: "0.467054263565891", constantSitesP: "0.34593023255814", distinctSitesP: "0.665697674418605", brLenTotal: "2.9811" },
  { geneTranscript: "NM_002484.4", parsimonySitesP: "0.198958333333333", constantSitesP: "0.696875", distinctSitesP: "0.316666666666667", brLenTotal: "0.8770" },
  { geneTranscript: "NM_002489.4", parsimonySitesP: "0.259259259259259", constantSitesP: "0.592592592592593", distinctSitesP: "0.502057613168724", brLenTotal: "0.8750" },
  { geneTranscript: "NM_002499.4", parsimonySitesP: "0.164955509924709", constantSitesP: "0.737166324435318", distinctSitesP: "0.228382386493269", brLenTotal: "0.6986" },
  { geneTranscript: "NM_002508.3", parsimonySitesP: "0.256615878107458", constantSitesP: "0.630045442395082", distinctSitesP: "0.347500668270516", brLenTotal: "1.1428" },
  { geneTranscript: "NM_002514.4", parsimonySitesP: "0.213818860877684", constantSitesP: "0.632119514472456", distinctSitesP: "0.349206349206349", brLenTotal: "0.8732" },
  { geneTranscript: "NM_002517.4", parsimonySitesP: "0.219774011299435", constantSitesP: "0.642372881355932", distinctSitesP: "0.326553672316384", brLenTotal: "0.9763" },
  { geneTranscript: "NM_002526.4", parsimonySitesP: "0.193960511033682", constantSitesP: "0.681765389082462", distinctSitesP: "0.284552845528455", brLenTotal: "0.6736" },
  { geneTranscript: "NM_002539.3", parsimonySitesP: "0.204627621113521", constantSitesP: "0.655820679681851", distinctSitesP: "0.323933477946493", brLenTotal: "0.7463" },
  { geneTranscript: "NM_002548.3", parsimonySitesP: "0.561965811965812", constantSitesP: "0.259615384615385", distinctSitesP: "0.735042735042735", brLenTotal: "4.2277" },
  { geneTranscript: "NM_002553.4", parsimonySitesP: "0.128735632183908", constantSitesP: "0.774712643678161", distinctSitesP: "0.23448275862069", brLenTotal: "0.4021" },
  { geneTranscript: "NM_002555.6", parsimonySitesP: "0.316037735849057", constantSitesP: "0.512578616352201", distinctSitesP: "0.505503144654088", brLenTotal: "1.4290" },
  { geneTranscript: "NM_002575.3", parsimonySitesP: "0.274698795180723", constantSitesP: "0.593574297188755", distinctSitesP: "0.419277108433735", brLenTotal: "1.0762" },
  { geneTranscript: "NM_002579.3", parsimonySitesP: "0.239448751076658", constantSitesP: "0.625322997416021", distinctSitesP: "0.3557278208441", brLenTotal: "1.3933" },
  { geneTranscript: "NM_002582.4", parsimonySitesP: "0.149713093375065", constantSitesP: "0.740219092331768", distinctSitesP: "0.25508607198748", brLenTotal: "0.5354" },
  { geneTranscript: "NM_002583.4", parsimonySitesP: "0.203921568627451", constantSitesP: "0.649019607843137", distinctSitesP: "0.381372549019608", brLenTotal: "0.7421" },
  { geneTranscript: "NM_002605.3", parsimonySitesP: "0.245275432247688", constantSitesP: "0.584640128669079", distinctSitesP: "0.392038600723764", brLenTotal: "0.8995" },
  { geneTranscript: "NM_002608.4", parsimonySitesP: "0.175656984785615", constantSitesP: "0.681881051175657", distinctSitesP: "0.315352697095436", brLenTotal: "0.7455" },
  { geneTranscript: "NM_002640.4", parsimonySitesP: "0.370766488413547", constantSitesP: "0.489304812834225", distinctSitesP: "0.501782531194296", brLenTotal: "1.7012" },
  { geneTranscript: "NM_002654.6", parsimonySitesP: "0.209039548022599", constantSitesP: "0.628374136848713", distinctSitesP: "0.332077840552417", brLenTotal: "0.9745" },
  { geneTranscript: "NM_002658.6", parsimonySitesP: "0.2846094354215", constantSitesP: "0.584686774941995", distinctSitesP: "0.39907192575406", brLenTotal: "0.9380" },
  { geneTranscript: "NM_002662.5", parsimonySitesP: "0.196151458721291", constantSitesP: "0.684357541899441", distinctSitesP: "0.301675977653631", brLenTotal: "0.7533" },
  { geneTranscript: "NM_002666.5", parsimonySitesP: "0.284802043422733", constantSitesP: "0.566411238825032", distinctSitesP: "0.422094508301405", brLenTotal: "1.1894" },
  { geneTranscript: "NM_002667.5", parsimonySitesP: "0.198717948717949", constantSitesP: "0.730769230769231", distinctSitesP: "0.301282051282051", brLenTotal: "0.4838" },
  { geneTranscript: "NM_002669.4", parsimonySitesP: "0.143320363164721", constantSitesP: "0.765239948119326", distinctSitesP: "0.21011673151751", brLenTotal: "0.5211" },
  { geneTranscript: "NM_002674.4", parsimonySitesP: "0.129292929292929", constantSitesP: "0.741414141414141", distinctSitesP: "0.288888888888889", brLenTotal: "0.4137" },
  { geneTranscript: "NM_002687.4", parsimonySitesP: "0.130172013017201", constantSitesP: "0.773128777312878", distinctSitesP: "0.202231520223152", brLenTotal: "0.4086" },
  { geneTranscript: "NM_002689.4", parsimonySitesP: "0.210702341137124", constantSitesP: "0.664437012263099", distinctSitesP: "0.323857302118172", brLenTotal: "0.7839" },
  { geneTranscript: "NM_002692.4", parsimonySitesP: "0.149272612270715", constantSitesP: "0.746995572422517", distinctSitesP: "0.269449715370019", brLenTotal: "0.4868" },
  { geneTranscript: "NM_002703.5", parsimonySitesP: "0.170857511283043", constantSitesP: "0.725983236621534", distinctSitesP: "0.267569310122502", brLenTotal: "0.6134" },
  { geneTranscript: "NM_002722.5", parsimonySitesP: "0.273684210526316", constantSitesP: "0.550877192982456", distinctSitesP: "0.484210526315789", brLenTotal: "1.0910" },
  { geneTranscript: "NM_002725.4", parsimonySitesP: "0.195462478184991", constantSitesP: "0.678010471204188", distinctSitesP: "0.270506108202443", brLenTotal: "0.8669" },
  { geneTranscript: "NM_002740.6", parsimonySitesP: "0.13758389261745", constantSitesP: "0.77572706935123", distinctSitesP: "0.204697986577181", brLenTotal: "0.4823" },
  { geneTranscript: "NM_002748.4", parsimonySitesP: "0.297734627831715", constantSitesP: "0.46786870087841", distinctSitesP: "0.48081368469718", brLenTotal: "1.3339" },
  { geneTranscript: "NM_002769.5", parsimonySitesP: "0.34412955465587", constantSitesP: "0.508771929824561", distinctSitesP: "0.53306342780027", brLenTotal: "1.7417" },
  { geneTranscript: "NM_002770.4", parsimonySitesP: "0.337381916329285", constantSitesP: "0.515519568151147", distinctSitesP: "0.51417004048583", brLenTotal: "1.6619" },
  { geneTranscript: "NM_002771.4", parsimonySitesP: "0.337381916329285", constantSitesP: "0.51417004048583", distinctSitesP: "0.530364372469636", brLenTotal: "1.6372" },
  { geneTranscript: "NM_002773.5", parsimonySitesP: "0.327502429543246", constantSitesP: "0.556851311953353", distinctSitesP: "0.45578231292517", brLenTotal: "1.2794" },
  { geneTranscript: "NM_002775.5", parsimonySitesP: "0.1875", constantSitesP: "0.722222222222222", distinctSitesP: "0.275", brLenTotal: "0.7788" },
  { geneTranscript: "NM_002778.4", parsimonySitesP: "0.253180661577608", constantSitesP: "0.606234096692112", distinctSitesP: "0.396310432569975", brLenTotal: "1.0315" },
  { geneTranscript: "NM_002793.4", parsimonySitesP: "0.201936376210235", constantSitesP: "0.67911479944675", distinctSitesP: "0.329183955739972", brLenTotal: "0.7774" },
  { geneTranscript: "NM_002812.5", parsimonySitesP: "0.194285714285714", constantSitesP: "0.680952380952381", distinctSitesP: "0.308571428571429", brLenTotal: "0.6812" },
  { geneTranscript: "NM_002823.5", parsimonySitesP: "0.142424242424242", constantSitesP: "0.696969696969697", distinctSitesP: "0.303030303030303", brLenTotal: "0.6611" },
  { geneTranscript: "NM_002824.6", parsimonySitesP: "0.0915032679738562", constantSitesP: "0.803921568627451", distinctSitesP: "0.238562091503268", brLenTotal: "0.5101" },
  { geneTranscript: "NM_002826.5", parsimonySitesP: "0.312806782686301", constantSitesP: "0.533244087460955", distinctSitesP: "0.437751004016064", brLenTotal: "1.2557" },
  { geneTranscript: "NM_002827.4", parsimonySitesP: "0.207662835249042", constantSitesP: "0.667432950191571", distinctSitesP: "0.314176245210728", brLenTotal: "0.8500" },
  { geneTranscript: "NM_002849.4", parsimonySitesP: "0.165905631659056", constantSitesP: "0.697108066971081", distinctSitesP: "0.274479959411466", brLenTotal: "0.6300" },
  { geneTranscript: "NM_002892.4", parsimonySitesP: "0.144789180588703", constantSitesP: "0.7175815433572", distinctSitesP: "0.239989392734023", brLenTotal: "0.5122" },
  { geneTranscript: "NM_002894.3", parsimonySitesP: "0.205499814195466", constantSitesP: "0.620587142326273", distinctSitesP: "0.37421033073207", brLenTotal: "0.7249" },
  { geneTranscript: "NM_002896.4", parsimonySitesP: "0.206959706959707", constantSitesP: "0.668498168498168", distinctSitesP: "0.311355311355311", brLenTotal: "0.9221" },
  { geneTranscript: "NM_002899.5", parsimonySitesP: "0.211505922165821", constantSitesP: "0.631133671742809", distinctSitesP: "0.377326565143824", brLenTotal: "0.8107" },
  { geneTranscript: "NM_002901.4", parsimonySitesP: "0.18429003021148", constantSitesP: "0.663645518630413", distinctSitesP: "0.314199395770393", brLenTotal: "0.7771" },
  { geneTranscript: "NM_002902.3", parsimonySitesP: "0.131440588853838", constantSitesP: "0.758149316508938", distinctSitesP: "0.240799158780231", brLenTotal: "0.4229" },
  { geneTranscript: "NM_002910.6", parsimonySitesP: "0.252927400468384", constantSitesP: "0.620608899297424", distinctSitesP: "0.375487900078064", brLenTotal: "1.0827" },
  { geneTranscript: "NM_002913.5", parsimonySitesP: "0.200232490555071", constantSitesP: "0.639349026445801", distinctSitesP: "0.345248474280732", brLenTotal: "0.7389" },
  { geneTranscript: "NM_002916.5", parsimonySitesP: "0.175390266299357", constantSitesP: "0.7089072543618", distinctSitesP: "0.307621671258035", brLenTotal: "0.5369" },
  { geneTranscript: "NM_002922.4", parsimonySitesP: "0.20414673046252", constantSitesP: "0.649122807017544", distinctSitesP: "0.366826156299841", brLenTotal: "0.7453" },
  { geneTranscript: "NM_002927.5", parsimonySitesP: "0.215932914046122", constantSitesP: "0.651991614255765", distinctSitesP: "0.39622641509434", brLenTotal: "0.6945" },
  { geneTranscript: "NM_002933.5", parsimonySitesP: "0.301282051282051", constantSitesP: "0.504273504273504", distinctSitesP: "0.495726495726496", brLenTotal: "1.1111" },
  { geneTranscript: "NM_002938.5", parsimonySitesP: "0.189473684210526", constantSitesP: "0.705263157894737", distinctSitesP: "0.324561403508772", brLenTotal: "0.6959" },
  { geneTranscript: "NM_002941.4", parsimonySitesP: "0.192004845548153", constantSitesP: "0.717343024429639", distinctSitesP: "0.249747627700384", brLenTotal: "0.9865" },
  { geneTranscript: "NM_002946.5", parsimonySitesP: "0.2", constantSitesP: "0.651851851851852", distinctSitesP: "0.32716049382716", brLenTotal: "0.7388" },
  { geneTranscript: "NM_002947.5", parsimonySitesP: "0.267217630853994", constantSitesP: "0.545454545454545", distinctSitesP: "0.506887052341598", brLenTotal: "1.0142" },
  { geneTranscript: "NM_002948.5", parsimonySitesP: "0.207516339869281", constantSitesP: "0.464052287581699", distinctSitesP: "0.452614379084967", brLenTotal: "1.1469" },
  { geneTranscript: "NM_002949.4", parsimonySitesP: "0.274410774410774", constantSitesP: "0.601010101010101", distinctSitesP: "0.412457912457912", brLenTotal: "1.0707" },
  { geneTranscript: "NM_002950.4", parsimonySitesP: "0.247666117517847", constantSitesP: "0.630971993410214", distinctSitesP: "0.375068643602416", brLenTotal: "1.0748" },
  { geneTranscript: "NM_002960.2", parsimonySitesP: "0.297029702970297", constantSitesP: "0.531353135313531", distinctSitesP: "0.501650165016502", brLenTotal: "1.0830" },
  { geneTranscript: "NM_002979.5", parsimonySitesP: "0.180377818403413", constantSitesP: "0.702010968921389", distinctSitesP: "0.29981718464351", brLenTotal: "0.6654" },
  { geneTranscript: "NM_002980.3", parsimonySitesP: "0.258333333333333", constantSitesP: "0.636363636363636", distinctSitesP: "0.36969696969697", brLenTotal: "0.9378" },
  { geneTranscript: "NM_002982.4", parsimonySitesP: "0.400673400673401", constantSitesP: "0.488215488215488", distinctSitesP: "0.565656565656566", brLenTotal: "1.3484" },
  { geneTranscript: "NM_002983.3", parsimonySitesP: "0.376811594202899", constantSitesP: "0.442028985507246", distinctSitesP: "0.61231884057971", brLenTotal: "1.5231" },
  { geneTranscript: "NM_002984.4", parsimonySitesP: "0.271739130434783", constantSitesP: "0.619565217391304", distinctSitesP: "0.420289855072464", brLenTotal: "0.9446" },
  { geneTranscript: "NM_002989.4", parsimonySitesP: "0.293532338308458", constantSitesP: "0.544776119402985", distinctSitesP: "0.447761194029851", brLenTotal: "0.9599" },
  { geneTranscript: "NM_002997.5", parsimonySitesP: "0.279569892473118", constantSitesP: "0.526881720430108", distinctSitesP: "0.472043010752688", brLenTotal: "1.1205" },
  { geneTranscript: "NM_002999.4", parsimonySitesP: "0.247474747474747", constantSitesP: "0.609427609427609", distinctSitesP: "0.400673400673401", brLenTotal: "0.9313" },
  { geneTranscript: "NM_003002.4", parsimonySitesP: "0.222222222222222", constantSitesP: "0.563941299790356", distinctSitesP: "0.457023060796646", brLenTotal: "0.8057" },
  { geneTranscript: "NM_003039.3", parsimonySitesP: "0.282767797737858", constantSitesP: "0.598802395209581", distinctSitesP: "0.413173652694611", brLenTotal: "1.2994" },
  { geneTranscript: "NM_003043.6", parsimonySitesP: "0.224193548387097", constantSitesP: "0.672043010752688", distinctSitesP: "0.309139784946237", brLenTotal: "1.0045" },
  { geneTranscript: "NM_003049.4", parsimonySitesP: "0.286532951289398", constantSitesP: "0.530085959885387", distinctSitesP: "0.471824259789876", brLenTotal: "1.1299" },
  { geneTranscript: "NM_003051.4", parsimonySitesP: "0.223333333333333", constantSitesP: "0.640666666666667", distinctSitesP: "0.337333333333333", brLenTotal: "0.8330" },
  { geneTranscript: "NM_003053.4", parsimonySitesP: "0.257142857142857", constantSitesP: "0.607619047619048", distinctSitesP: "0.391746031746032", brLenTotal: "1.0452" },
  { geneTranscript: "NM_003054.6", parsimonySitesP: "0.162127107652399", constantSitesP: "0.716601815823606", distinctSitesP: "0.27367055771725", brLenTotal: "0.6565" },
  { geneTranscript: "NM_003057.3", parsimonySitesP: "0.339350180505415", constantSitesP: "0.524067388688327", distinctSitesP: "0.483152827918171", brLenTotal: "1.5773" },
  { geneTranscript: "NM_003058.4", parsimonySitesP: "0.273273273273273", constantSitesP: "0.593993993993994", distinctSitesP: "0.395795795795796", brLenTotal: "1.2851" },
  { geneTranscript: "NM_003059.3", parsimonySitesP: "0.24258923169994", constantSitesP: "0.647912885662432", distinctSitesP: "0.353901996370236", brLenTotal: "0.9194" },
  { geneTranscript: "NM_003071.4", parsimonySitesP: "0.141724479682854", constantSitesP: "0.751569210439379", distinctSitesP: "0.232903865213082", brLenTotal: "0.4772" },
  { geneTranscript: "NM_003080.3", parsimonySitesP: "0.274231678486998", constantSitesP: "0.563435776201734", distinctSitesP: "0.403467297084318", brLenTotal: "0.9250" },
  { geneTranscript: "NM_003083.4", parsimonySitesP: "0.350299401197605", constantSitesP: "0.435129740518962", distinctSitesP: "0.559880239520958", brLenTotal: "1.5094" },
  { geneTranscript: "NM_003090.4", parsimonySitesP: "0.109803921568627", constantSitesP: "0.798692810457516", distinctSitesP: "0.2", brLenTotal: "0.4394" },
  { geneTranscript: "NM_003096.4", parsimonySitesP: "0.12280701754386", constantSitesP: "0.644736842105263", distinctSitesP: "0.394736842105263", brLenTotal: "0.6078" },
  { geneTranscript: "NM_003101.6", parsimonySitesP: "0.173939393939394", constantSitesP: "0.732727272727273", distinctSitesP: "0.265454545454545", brLenTotal: "0.5546" },
  { geneTranscript: "NM_003104.6", parsimonySitesP: "0.23249299719888", constantSitesP: "0.620915032679739", distinctSitesP: "0.380018674136321", brLenTotal: "0.8700" },
  { geneTranscript: "NM_003121.5", parsimonySitesP: "0.21882951653944", constantSitesP: "0.650127226463104", distinctSitesP: "0.343511450381679", brLenTotal: "0.8989" },
  { geneTranscript: "NM_003125.3", parsimonySitesP: "0.393258426966292", constantSitesP: "0.404494382022472", distinctSitesP: "0.685393258426966", brLenTotal: "2.0735" },
  { geneTranscript: "NM_003126.4", parsimonySitesP: "0.263607551329751", constantSitesP: "0.605897753892793", distinctSitesP: "0.355932203389831", brLenTotal: "0.8959" },
  { geneTranscript: "NM_003129.4", parsimonySitesP: "0.191637630662021", constantSitesP: "0.680603948896632", distinctSitesP: "0.289198606271777", brLenTotal: "0.6820" },
  { geneTranscript: "NM_003130.4", parsimonySitesP: "0.143097643097643", constantSitesP: "0.762626262626263", distinctSitesP: "0.265993265993266", brLenTotal: "0.4681" },
  { geneTranscript: "NM_003133.6", parsimonySitesP: "0.197674418604651", constantSitesP: "0.689922480620155", distinctSitesP: "0.344961240310078", brLenTotal: "0.5557" },
  { geneTranscript: "NM_003135.3", parsimonySitesP: "0.171296296296296", constantSitesP: "0.666666666666667", distinctSitesP: "0.331018518518519", brLenTotal: "0.5958" },
  { geneTranscript: "NM_003141.4", parsimonySitesP: "0.327719298245614", constantSitesP: "0.500350877192982", distinctSitesP: "0.494736842105263", brLenTotal: "1.2780" },
  { geneTranscript: "NM_003143.3", parsimonySitesP: "0.155405405405405", constantSitesP: "0.711711711711712", distinctSitesP: "0.317567567567568", brLenTotal: "0.6179" },
  { geneTranscript: "NM_003177.7", parsimonySitesP: "0.208923884514436", constantSitesP: "0.712335958005249", distinctSitesP: "0.278215223097113", brLenTotal: "0.9851" },
  { geneTranscript: "NM_003184.4", parsimonySitesP: "0.133166527661941", constantSitesP: "0.785932721712538", distinctSitesP: "0.182652210175146", brLenTotal: "0.4439" },
  { geneTranscript: "NM_003195.6", parsimonySitesP: "0.216276477146042", constantSitesP: "0.652173913043478", distinctSitesP: "0.31438127090301", brLenTotal: "1.1001" },
  { geneTranscript: "NM_003198.3", parsimonySitesP: "0.198618307426598", constantSitesP: "0.672711571675302", distinctSitesP: "0.281088082901554", brLenTotal: "0.8056" },
  { geneTranscript: "NM_003200.5", parsimonySitesP: "0.268603465851172", constantSitesP: "0.595820591233435", distinctSitesP: "0.3710499490316", brLenTotal: "1.5269" },
  { geneTranscript: "NM_003235.5", parsimonySitesP: "0.295038535645472", constantSitesP: "0.538776493256262", distinctSitesP: "0.402938342967245", brLenTotal: "1.2169" },
  { geneTranscript: "NM_003254.3", parsimonySitesP: "0.198067632850242", constantSitesP: "0.639291465378422", distinctSitesP: "0.326892109500805", brLenTotal: "0.6968" },
  { geneTranscript: "NM_003270.4", parsimonySitesP: "0.213605442176871", constantSitesP: "0.659863945578231", distinctSitesP: "0.349659863945578", brLenTotal: "0.6638" },
  { geneTranscript: "NM_003272.4", parsimonySitesP: "0.164578111946533", constantSitesP: "0.665831244778613", distinctSitesP: "0.311612364243943", brLenTotal: "0.8095" },
  { geneTranscript: "NM_003283.6", parsimonySitesP: "0.202637889688249", constantSitesP: "0.718225419664269", distinctSitesP: "0.302158273381295", brLenTotal: "0.9359" },
  { geneTranscript: "NM_003287.4", parsimonySitesP: "0.124183006535948", constantSitesP: "0.776143790849673", distinctSitesP: "0.241830065359477", brLenTotal: "0.4649" },
  { geneTranscript: "NM_003294.4", parsimonySitesP: "0.406060606060606", constantSitesP: "0.452121212121212", distinctSitesP: "0.557575757575758", brLenTotal: "2.1527" },
  { geneTranscript: "NM_003310.5", parsimonySitesP: "0.18260120585702", constantSitesP: "0.719207579672696", distinctSitesP: "0.266149870801034", brLenTotal: "0.9997" },
  { geneTranscript: "NM_003314.3", parsimonySitesP: "0.181506849315068", constantSitesP: "0.67351598173516", distinctSitesP: "0.324200913242009", brLenTotal: "0.6519" },
  { geneTranscript: "NM_003322.6", parsimonySitesP: "0.227552275522755", constantSitesP: "0.633456334563346", distinctSitesP: "0.359163591635916", brLenTotal: "0.9092" },
  { geneTranscript: "NM_003329.4", parsimonySitesP: "0.149206349206349", constantSitesP: "0.73015873015873", distinctSitesP: "0.317460317460317", brLenTotal: "0.6074" },
  { geneTranscript: "NM_003343.6", parsimonySitesP: "0.133333333333333", constantSitesP: "0.743434343434343", distinctSitesP: "0.252525252525253", brLenTotal: "0.7420" },
  { geneTranscript: "NM_003377.5", parsimonySitesP: "0.148148148148148", constantSitesP: "0.705314009661836", distinctSitesP: "0.276972624798712", brLenTotal: "0.6003" },
  { geneTranscript: "NM_003399.6", parsimonySitesP: "0.219090009891197", constantSitesP: "0.643422354104847", distinctSitesP: "0.334322453016815", brLenTotal: "0.8164" },
  { geneTranscript: "NM_003420.4", parsimonySitesP: "0.196078431372549", constantSitesP: "0.646426312460468", distinctSitesP: "0.321948134092347", brLenTotal: "0.7448" },
  { geneTranscript: "NM_003455.4", parsimonySitesP: "0.241255144032922", constantSitesP: "0.630144032921811", distinctSitesP: "0.349279835390947", brLenTotal: "0.8014" },
  { geneTranscript: "NM_003461.5", parsimonySitesP: "0.236596736596737", constantSitesP: "0.613053613053613", distinctSitesP: "0.346736596736597", brLenTotal: "0.9766" },
  { geneTranscript: "NM_003468.4", parsimonySitesP: "0.164672364672365", constantSitesP: "0.645584045584046", distinctSitesP: "0.245014245014245", brLenTotal: "0.9545" },
  { geneTranscript: "NM_003473.4", parsimonySitesP: "0.161111111111111", constantSitesP: "0.75", distinctSitesP: "0.250617283950617", brLenTotal: "0.5999" },
  { geneTranscript: "NM_003500.4", parsimonySitesP: "0.274106705824767", constantSitesP: "0.584434654919236", distinctSitesP: "0.400391581008321", brLenTotal: "1.0764" },
  { geneTranscript: "NM_003549.4", parsimonySitesP: "0.26378896882494", constantSitesP: "0.534772182254197", distinctSitesP: "0.374900079936051", brLenTotal: "0.8799" },
  { geneTranscript: "NM_003551.3", parsimonySitesP: "0.171383647798742", constantSitesP: "0.693396226415094", distinctSitesP: "0.311320754716981", brLenTotal: "0.5558" },
  { geneTranscript: "NM_003566.4", parsimonySitesP: "0.169619655090952", constantSitesP: "0.711315851641862", distinctSitesP: "0.281833215213796", brLenTotal: "0.6134" },
  { geneTranscript: "NM_003568.3", parsimonySitesP: "0.256038647342995", constantSitesP: "0.604830917874396", distinctSitesP: "0.410628019323671", brLenTotal: "0.9458" },
  { geneTranscript: "NM_003569.3", parsimonySitesP: "0.160919540229885", constantSitesP: "0.734355044699872", distinctSitesP: "0.28735632183908", brLenTotal: "0.5325" },
  { geneTranscript: "NM_003580.4", parsimonySitesP: "0.182479098509633", constantSitesP: "0.708106143220647", distinctSitesP: "0.294074881861141", brLenTotal: "0.6941" },
  { geneTranscript: "NM_003585.5", parsimonySitesP: "0.172330097087379", constantSitesP: "0.733818770226537", distinctSitesP: "0.256472491909385", brLenTotal: "0.7502" },
  { geneTranscript: "NM_003621.5", parsimonySitesP: "0.178843226788432", constantSitesP: "0.701293759512938", distinctSitesP: "0.269786910197869", brLenTotal: "0.6690" },
  { geneTranscript: "NM_003638.3", parsimonySitesP: "0.21574161179053", constantSitesP: "0.654123549702101", distinctSitesP: "0.338037002195045", brLenTotal: "0.7623" },
  { geneTranscript: "NM_003640.5", parsimonySitesP: "0.200950950950951", constantSitesP: "0.66016016016016", distinctSitesP: "0.313813813813814", brLenTotal: "0.6772" },
  { geneTranscript: "NM_003645.4", parsimonySitesP: "0.229569892473118", constantSitesP: "0.629569892473118", distinctSitesP: "0.346774193548387", brLenTotal: "0.7877" },
  { geneTranscript: "NM_003653.4", parsimonySitesP: "0.101654846335697", constantSitesP: "0.789598108747045", distinctSitesP: "0.186761229314421", brLenTotal: "0.4016" },
  { geneTranscript: "NM_003658.5", parsimonySitesP: "0.20310633213859", constantSitesP: "0.66547192353644", distinctSitesP: "0.318996415770609", brLenTotal: "0.8899" },
  { geneTranscript: "NM_003667.4", parsimonySitesP: "0.188533627342889", constantSitesP: "0.691289966923925", distinctSitesP: "0.277471517824329", brLenTotal: "0.6387" },
  { geneTranscript: "NM_003670.3", parsimonySitesP: "0.204692556634304", constantSitesP: "0.65453074433657", distinctSitesP: "0.299352750809061", brLenTotal: "0.8309" },
  { geneTranscript: "NM_003672.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.709315375982043", distinctSitesP: "0.259820426487093", brLenTotal: "0.6952" },
  { geneTranscript: "NM_003681.5", parsimonySitesP: "0.223290598290598", constantSitesP: "0.666666666666667", distinctSitesP: "0.353632478632479", brLenTotal: "1.0048" },
  { geneTranscript: "NM_003689.4", parsimonySitesP: "0.285979572887651", constantSitesP: "0.516248839368617", distinctSitesP: "0.469823584029712", brLenTotal: "1.5939" },
  { geneTranscript: "NM_003693.4", parsimonySitesP: "0.317670682730924", constantSitesP: "0.510843373493976", distinctSitesP: "0.447389558232932", brLenTotal: "1.3175" },
  { geneTranscript: "NM_003696.3", parsimonySitesP: "0.468909276248726", constantSitesP: "0.349643221202854", distinctSitesP: "0.632008154943935", brLenTotal: "2.5713" },
  { geneTranscript: "NM_003701.4", parsimonySitesP: "0.196635120925342", constantSitesP: "0.676130389064143", distinctSitesP: "0.331230283911672", brLenTotal: "0.6824" },
  { geneTranscript: "NM_003708.5", parsimonySitesP: "0.370136698212408", constantSitesP: "0.445846477392219", distinctSitesP: "0.548895899053628", brLenTotal: "1.8664" },
  { geneTranscript: "NM_003710.4", parsimonySitesP: "0.287199480181936", constantSitesP: "0.572449642625081", distinctSitesP: "0.415204678362573", brLenTotal: "1.0639" },
  { geneTranscript: "NM_003715.4", parsimonySitesP: "0.163548163548164", constantSitesP: "0.723492723492723", distinctSitesP: "0.260914760914761", brLenTotal: "0.5885" },
  { geneTranscript: "NM_003720.4", parsimonySitesP: "0.18287037037037", constantSitesP: "0.6875", distinctSitesP: "0.325231481481481", brLenTotal: "0.6179" },
  { geneTranscript: "NM_003725.4", parsimonySitesP: "0.332281808622503", constantSitesP: "0.501577287066246", distinctSitesP: "0.516298633017876", brLenTotal: "1.5378" },
  { geneTranscript: "NM_003726.4", parsimonySitesP: "0.162488393686165", constantSitesP: "0.708449396471681", distinctSitesP: "0.302692664809656", brLenTotal: "0.5164" },
  { geneTranscript: "NM_003729.4", parsimonySitesP: "0.134790528233151", constantSitesP: "0.741347905282332", distinctSitesP: "0.236794171220401", brLenTotal: "0.5093" },
  { geneTranscript: "NM_003731.3", parsimonySitesP: "0.179271708683473", constantSitesP: "0.736694677871148", distinctSitesP: "0.280112044817927", brLenTotal: "0.5663" },
  { geneTranscript: "NM_003747.3", parsimonySitesP: "0.176839989952273", constantSitesP: "0.708364732479277", distinctSitesP: "0.221301180607887", brLenTotal: "0.9629" },
  { geneTranscript: "NM_003754.3", parsimonySitesP: "0.260504201680672", constantSitesP: "0.572362278244631", distinctSitesP: "0.429505135387488", brLenTotal: "1.2154" },
  { geneTranscript: "NM_003770.5", parsimonySitesP: "0.314031180400891", constantSitesP: "0.534521158129176", distinctSitesP: "0.465478841870824", brLenTotal: "1.8543" },
  { geneTranscript: "NM_003774.5", parsimonySitesP: "0.177047289504037", constantSitesP: "0.689734717416378", distinctSitesP: "0.256055363321799", brLenTotal: "0.6492" },
  { geneTranscript: "NM_003778.4", parsimonySitesP: "0.206395348837209", constantSitesP: "0.650193798449612", distinctSitesP: "0.369186046511628", brLenTotal: "0.7313" },
  { geneTranscript: "NM_003789.4", parsimonySitesP: "0.287393162393162", constantSitesP: "0.560897435897436", distinctSitesP: "0.427350427350427", brLenTotal: "1.0883" },
  { geneTranscript: "NM_003790.3", parsimonySitesP: "0.295763389288569", constantSitesP: "0.539568345323741", distinctSitesP: "0.458832933653078", brLenTotal: "1.1442" },
  { geneTranscript: "NM_003793.4", parsimonySitesP: "0.256198347107438", constantSitesP: "0.590909090909091", distinctSitesP: "0.384986225895317", brLenTotal: "0.8998" },
  { geneTranscript: "NM_003799.3", parsimonySitesP: "0.197478991596639", constantSitesP: "0.669467787114846", distinctSitesP: "0.332633053221289", brLenTotal: "0.6264" },
  { geneTranscript: "NM_003812.4", parsimonySitesP: "0.133413461538462", constantSitesP: "0.764022435897436", distinctSitesP: "0.212740384615385", brLenTotal: "0.5049" },
  { geneTranscript: "NM_003816.3", parsimonySitesP: "0.146520146520147", constantSitesP: "0.734635734635735", distinctSitesP: "0.242979242979243", brLenTotal: "0.5397" },
  { geneTranscript: "NM_003821.6", parsimonySitesP: "0.184567901234568", constantSitesP: "0.705555555555556", distinctSitesP: "0.294444444444444", brLenTotal: "0.5925" },
  { geneTranscript: "NM_003835.4", parsimonySitesP: "0.193867457962413", constantSitesP: "0.682492581602374", distinctSitesP: "0.289317507418398", brLenTotal: "0.8711" },
  { geneTranscript: "NM_003836.7", parsimonySitesP: "0.28372497824195", constantSitesP: "0.595300261096606", distinctSitesP: "0.401218450826806", brLenTotal: "1.2001" },
  { geneTranscript: "NM_003837.4", parsimonySitesP: "0.224188790560472", constantSitesP: "0.5968534906588", distinctSitesP: "0.372664700098328", brLenTotal: "0.9954" },
  { geneTranscript: "NM_003839.4", parsimonySitesP: "0.275974025974026", constantSitesP: "0.554112554112554", distinctSitesP: "0.448051948051948", brLenTotal: "1.0810" },
  { geneTranscript: "NM_003849.4", parsimonySitesP: "0.195568400770713", constantSitesP: "0.710019267822736", distinctSitesP: "0.305394990366089", brLenTotal: "0.6403" },
  { geneTranscript: "NM_003851.3", parsimonySitesP: "0.272727272727273", constantSitesP: "0.574242424242424", distinctSitesP: "0.428787878787879", brLenTotal: "1.1260" },
  { geneTranscript: "NM_003863.4", parsimonySitesP: "0.194444444444444", constantSitesP: "0.682539682539683", distinctSitesP: "0.345238095238095", brLenTotal: "0.7754" },
  { geneTranscript: "NM_003864.4", parsimonySitesP: "0.148484848484848", constantSitesP: "0.75", distinctSitesP: "0.243939393939394", brLenTotal: "0.5030" },
  { geneTranscript: "NM_003865.3", parsimonySitesP: "0.227027027027027", constantSitesP: "0.587387387387387", distinctSitesP: "0.407207207207207", brLenTotal: "0.7070" },
  { geneTranscript: "NM_003889.4", parsimonySitesP: "0.248079877112135", constantSitesP: "0.615207373271889", distinctSitesP: "0.360215053763441", brLenTotal: "0.9416" },
  { geneTranscript: "NM_003896.4", parsimonySitesP: "0.236044657097289", constantSitesP: "0.637161084529506", distinctSitesP: "0.366826156299841", brLenTotal: "0.7842" },
  { geneTranscript: "NM_003898.4", parsimonySitesP: "0.247103386809269", constantSitesP: "0.632130124777184", distinctSitesP: "0.339349376114082", brLenTotal: "1.0109" },
  { geneTranscript: "NM_003900.5", parsimonySitesP: "0.254545454545455", constantSitesP: "0.60530303030303", distinctSitesP: "0.381818181818182", brLenTotal: "1.1424" },
  { geneTranscript: "NM_003901.4", parsimonySitesP: "0.203638497652582", constantSitesP: "0.67018779342723", distinctSitesP: "0.302816901408451", brLenTotal: "0.6687" },
  { geneTranscript: "NM_003906.5", parsimonySitesP: "0.267508417508418", constantSitesP: "0.583501683501684", distinctSitesP: "0.374242424242424", brLenTotal: "1.0670" },
  { geneTranscript: "NM_003927.5", parsimonySitesP: "0.0689375506893755", constantSitesP: "0.827250608272506", distinctSitesP: "0.182481751824818", brLenTotal: "0.2904" },
  { geneTranscript: "NM_003930.5", parsimonySitesP: "0.154131847725162", constantSitesP: "0.732590529247911", distinctSitesP: "0.267409470752089", brLenTotal: "0.5042" },
  { geneTranscript: "NM_003940.3", parsimonySitesP: "0.169563538045577", constantSitesP: "0.720741599073001", distinctSitesP: "0.249903437620703", brLenTotal: "0.8202" },
  { geneTranscript: "NM_003953.6", parsimonySitesP: "0.225526641883519", constantSitesP: "0.657992565055762", distinctSitesP: "0.328376703841388", brLenTotal: "0.7426" },
  { geneTranscript: "NM_003963.3", parsimonySitesP: "0.263959390862944", constantSitesP: "0.620981387478849", distinctSitesP: "0.39424703891709", brLenTotal: "0.9776" },
  { geneTranscript: "NM_003975.4", parsimonySitesP: "0.31191088260497", constantSitesP: "0.45929734361611", distinctSitesP: "0.545844044558698", brLenTotal: "1.2731" },
  { geneTranscript: "NM_003978.5", parsimonySitesP: "0.231570512820513", constantSitesP: "0.650641025641026", distinctSitesP: "0.34775641025641", brLenTotal: "1.0002" },
  { geneTranscript: "NM_003979.4", parsimonySitesP: "0.297852474323063", constantSitesP: "0.558356676003735", distinctSitesP: "0.450046685340803", brLenTotal: "1.2457" },
  { geneTranscript: "NM_004044.7", parsimonySitesP: "0.226351351351351", constantSitesP: "0.599662162162162", distinctSitesP: "0.397522522522523", brLenTotal: "0.9268" },
  { geneTranscript: "NM_004052.4", parsimonySitesP: "0.235395189003436", constantSitesP: "0.621993127147766", distinctSitesP: "0.410652920962199", brLenTotal: "1.1801" },
  { geneTranscript: "NM_004057.3", parsimonySitesP: "0.215189873417722", constantSitesP: "0.637130801687764", distinctSitesP: "0.392405063291139", brLenTotal: "0.7208" },
  { geneTranscript: "NM_004058.5", parsimonySitesP: "0.27336860670194", constantSitesP: "0.557319223985891", distinctSitesP: "0.442680776014109", brLenTotal: "1.3053" },
  { geneTranscript: "NM_004070.4", parsimonySitesP: "0.273168364871422", constantSitesP: "0.607957302280446", distinctSitesP: "0.379912663755459", brLenTotal: "1.2033" },
  { geneTranscript: "NM_004074.3", parsimonySitesP: "0.347826086956522", constantSitesP: "0.42512077294686", distinctSitesP: "0.608695652173913", brLenTotal: "1.1461" },
  { geneTranscript: "NM_004079.5", parsimonySitesP: "0.273917421953676", constantSitesP: "0.625377643504532", distinctSitesP: "0.362537764350453", brLenTotal: "0.8737" },
  { geneTranscript: "NM_004088.4", parsimonySitesP: "0.219384413883432", constantSitesP: "0.641126391617551", distinctSitesP: "0.350360183366077", brLenTotal: "0.8640" },
  { geneTranscript: "NM_004102.5", parsimonySitesP: "0.177944862155388", constantSitesP: "0.659147869674185", distinctSitesP: "0.350877192982456", brLenTotal: "0.7000" },
  { geneTranscript: "NM_004104.5", parsimonySitesP: "0.330943847072879", constantSitesP: "0.539360148679145", distinctSitesP: "0.400504447099429", brLenTotal: "1.6550" },
  { geneTranscript: "NM_004106.2", parsimonySitesP: "0.201550387596899", constantSitesP: "0.682170542635659", distinctSitesP: "0.356589147286822", brLenTotal: "0.6359" },
  { geneTranscript: "NM_004119.3", parsimonySitesP: "0.209801946962068", constantSitesP: "0.658274588788184", distinctSitesP: "0.327962403491104", brLenTotal: "0.7955" },
  { geneTranscript: "NM_004123.3", parsimonySitesP: "0.281045751633987", constantSitesP: "0.542483660130719", distinctSitesP: "0.52723311546841", brLenTotal: "0.9141" },
  { geneTranscript: "NM_004138.4", parsimonySitesP: "0.282178217821782", constantSitesP: "0.566006600660066", distinctSitesP: "0.407590759075908", brLenTotal: "2.0004" },
  { geneTranscript: "NM_004145.4", parsimonySitesP: "0.250811312007418", constantSitesP: "0.633750579508577", distinctSitesP: "0.303198887343533", brLenTotal: "1.2033" },
  { geneTranscript: "NM_004155.6", parsimonySitesP: "0.36436170212766", constantSitesP: "0.450354609929078", distinctSitesP: "0.518617021276596", brLenTotal: "1.7936" },
  { geneTranscript: "NM_004157.4", parsimonySitesP: "0.164191419141914", constantSitesP: "0.725247524752475", distinctSitesP: "0.265676567656766", brLenTotal: "0.5433" },
  { geneTranscript: "NM_004171.4", parsimonySitesP: "0.18815331010453", constantSitesP: "0.727642276422764", distinctSitesP: "0.24390243902439", brLenTotal: "1.0285" },
  { geneTranscript: "NM_004173.3", parsimonySitesP: "0.320734908136483", constantSitesP: "0.532283464566929", distinctSitesP: "0.437270341207349", brLenTotal: "1.2709" },
  { geneTranscript: "NM_004181.5", parsimonySitesP: "0.183856502242152", constantSitesP: "0.699551569506726", distinctSitesP: "0.336322869955157", brLenTotal: "0.8381" },
  { geneTranscript: "NM_004196.7", parsimonySitesP: "0.19140989729225", constantSitesP: "0.677871148459384", distinctSitesP: "0.323996265172736", brLenTotal: "0.6309" },
  { geneTranscript: "NM_004210.5", parsimonySitesP: "0.214285714285714", constantSitesP: "0.655632984901278", distinctSitesP: "0.311265969802555", brLenTotal: "0.9272" },
  { geneTranscript: "NM_004213.5", parsimonySitesP: "0.304057524396507", constantSitesP: "0.527478171545968", distinctSitesP: "0.461736004108885", brLenTotal: "1.2636" },
  { geneTranscript: "NM_004235.6", parsimonySitesP: "0.203897007654836", constantSitesP: "0.638830897703549", distinctSitesP: "0.325678496868476", brLenTotal: "0.7517" },
  { geneTranscript: "NM_004237.4", parsimonySitesP: "0.233024691358025", constantSitesP: "0.641975308641975", distinctSitesP: "0.35570987654321", brLenTotal: "1.0872" },
  { geneTranscript: "NM_004246.3", parsimonySitesP: "0.245931283905967", constantSitesP: "0.608800482218204", distinctSitesP: "0.362266425557565", brLenTotal: "0.8589" },
  { geneTranscript: "NM_004254.4", parsimonySitesP: "0.22140221402214", constantSitesP: "0.649446494464945", distinctSitesP: "0.311193111931119", brLenTotal: "0.8545" },
  { geneTranscript: "NM_004256.4", parsimonySitesP: "0.338173018753781", constantSitesP: "0.496672716273442", distinctSitesP: "0.488203266787659", brLenTotal: "1.3193" },
  { geneTranscript: "NM_004259.7", parsimonySitesP: "0.271779347460478", constantSitesP: "0.572149344096872", distinctSitesP: "0.365623948873192", brLenTotal: "0.9817" },
  { geneTranscript: "NM_004284.6", parsimonySitesP: "0.216648086213304", constantSitesP: "0.643255295429208", distinctSitesP: "0.338907469342252", brLenTotal: "0.7561" },
  { geneTranscript: "NM_004288.5", parsimonySitesP: "0.216341689879294", constantSitesP: "0.631383472609099", distinctSitesP: "0.385329619312906", brLenTotal: "0.7925" },
  { geneTranscript: "NM_004293.5", parsimonySitesP: "0.168869309838473", constantSitesP: "0.720264317180617", distinctSitesP: "0.283406754772394", brLenTotal: "0.5445" },
  { geneTranscript: "NM_004294.4", parsimonySitesP: "0.215730337078652", constantSitesP: "0.62247191011236", distinctSitesP: "0.361797752808989", brLenTotal: "0.6868" },
  { geneTranscript: "NM_004301.5", parsimonySitesP: "0.125874125874126", constantSitesP: "0.796425796425796", distinctSitesP: "0.18026418026418", brLenTotal: "0.5107" },
  { geneTranscript: "NM_004307.2", parsimonySitesP: "0.233640755379886", constantSitesP: "0.655248133509003", distinctSitesP: "0.341238471673254", brLenTotal: "1.1121" },
  { geneTranscript: "NM_004311.4", parsimonySitesP: "0.126373626373626", constantSitesP: "0.782051282051282", distinctSitesP: "0.210622710622711", brLenTotal: "0.6887" },
  { geneTranscript: "NM_004312.3", parsimonySitesP: "0.198453608247423", constantSitesP: "0.592783505154639", distinctSitesP: "0.389175257731959", brLenTotal: "0.8099" },
  { geneTranscript: "NM_004316.4", parsimonySitesP: "0.145480225988701", constantSitesP: "0.772598870056497", distinctSitesP: "0.268361581920904", brLenTotal: "0.6523" },
  { geneTranscript: "NM_004327.4", parsimonySitesP: "0.223183844741673", constantSitesP: "0.625229478101233", distinctSitesP: "0.337529504327301", brLenTotal: "1.0184" },
  { geneTranscript: "NM_004329.3", parsimonySitesP: "0.162280701754386", constantSitesP: "0.738721804511278", distinctSitesP: "0.233709273182957", brLenTotal: "0.7837" },
  { geneTranscript: "NM_004332.4", parsimonySitesP: "0.280641466208477", constantSitesP: "0.562428407789233", distinctSitesP: "0.424971363115693", brLenTotal: "1.0513" },
  { geneTranscript: "NM_004336.5", parsimonySitesP: "0.209216589861751", constantSitesP: "0.623655913978495", distinctSitesP: "0.352380952380952", brLenTotal: "0.7479" },
  { geneTranscript: "NM_004360.5", parsimonySitesP: "0.270219198790627", constantSitesP: "0.612622826908541", distinctSitesP: "0.388133030990174", brLenTotal: "1.1238" },
  { geneTranscript: "NM_004363.6", parsimonySitesP: "0.500949667616334", constantSitesP: "0.331908831908832", distinctSitesP: "0.630579297245964", brLenTotal: "3.5169" },
  { geneTranscript: "NM_004364.5", parsimonySitesP: "0.160148975791434", constantSitesP: "0.689944134078212", distinctSitesP: "0.283985102420857", brLenTotal: "0.6389" },
  { geneTranscript: "NM_004369.4", parsimonySitesP: "0.255377190221383", constantSitesP: "0.623963907250026", distinctSitesP: "0.3202182352324", brLenTotal: "1.1122" },
  { geneTranscript: "NM_004374.4", parsimonySitesP: "0.311111111111111", constantSitesP: "0.48", distinctSitesP: "0.551111111111111", brLenTotal: "1.2457" },
  { geneTranscript: "NM_004375.5", parsimonySitesP: "0.259661835748792", constantSitesP: "0.602657004830918", distinctSitesP: "0.411835748792271", brLenTotal: "0.8198" },
  { geneTranscript: "NM_004394.3", parsimonySitesP: "0.196078431372549", constantSitesP: "0.702614379084967", distinctSitesP: "0.323529411764706", brLenTotal: "0.8100" },
  { geneTranscript: "NM_004398.4", parsimonySitesP: "0.208", constantSitesP: "0.64952380952381", distinctSitesP: "0.348571428571429", brLenTotal: "0.7664" },
  { geneTranscript: "NM_004401.3", parsimonySitesP: "0.267875125881168", constantSitesP: "0.559919436052367", distinctSitesP: "0.451158106747231", brLenTotal: "0.9815" },
  { geneTranscript: "NM_004404.5", parsimonySitesP: "0.182825484764543", constantSitesP: "0.707294552169898", distinctSitesP: "0.30932594644506", brLenTotal: "0.8018" },
  { geneTranscript: "NM_004414.7", parsimonySitesP: "0.161375661375661", constantSitesP: "0.673280423280423", distinctSitesP: "0.334656084656085", brLenTotal: "0.6807" },
  { geneTranscript: "NM_004416.3", parsimonySitesP: "0.194086021505376", constantSitesP: "0.711827956989247", distinctSitesP: "0.286021505376344", brLenTotal: "0.9883" },
  { geneTranscript: "NM_004420.3", parsimonySitesP: "0.232533333333333", constantSitesP: "0.651733333333333", distinctSitesP: "0.322666666666667", brLenTotal: "1.1312" },
  { geneTranscript: "NM_004435.2", parsimonySitesP: "0.212121212121212", constantSitesP: "0.646464646464646", distinctSitesP: "0.369248035914703", brLenTotal: "0.9890" },
  { geneTranscript: "NM_004477.3", parsimonySitesP: "0.156330749354005", constantSitesP: "0.702842377260982", distinctSitesP: "0.308785529715762", brLenTotal: "0.5786" },
  { geneTranscript: "NM_004506.4", parsimonySitesP: "0.105721393034826", constantSitesP: "0.798507462686567", distinctSitesP: "0.175995024875622", brLenTotal: "0.3494" },
  { geneTranscript: "NM_004507.4", parsimonySitesP: "0.16547619047619", constantSitesP: "0.683333333333333", distinctSitesP: "0.31547619047619", brLenTotal: "0.5895" },
  { geneTranscript: "NM_004523.4", parsimonySitesP: "0.180871212121212", constantSitesP: "0.665088383838384", distinctSitesP: "0.306818181818182", brLenTotal: "0.6542" },
  { geneTranscript: "NM_004528.4", parsimonySitesP: "0.223684210526316", constantSitesP: "0.68859649122807", distinctSitesP: "0.350877192982456", brLenTotal: "0.8236" },
  { geneTranscript: "NM_004539.4", parsimonySitesP: "0.203771289537713", constantSitesP: "0.68978102189781", distinctSitesP: "0.319951338199513", brLenTotal: "0.7338" },
  { geneTranscript: "NM_004547.6", parsimonySitesP: "0.354005167958656", constantSitesP: "0.490956072351421", distinctSitesP: "0.565891472868217", brLenTotal: "1.3528" },
  { geneTranscript: "NM_004552.3", parsimonySitesP: "0.361635220125786", constantSitesP: "0.437106918238994", distinctSitesP: "0.622641509433962", brLenTotal: "1.7498" },
  { geneTranscript: "NM_004560.4", parsimonySitesP: "0.23683280311064", constantSitesP: "0.635560268646165", distinctSitesP: "0.325910215623895", brLenTotal: "1.1855" },
  { geneTranscript: "NM_004562.3", parsimonySitesP: "0.228673835125448", constantSitesP: "0.640143369175627", distinctSitesP: "0.361290322580645", brLenTotal: "0.9224" },
  { geneTranscript: "NM_004564.3", parsimonySitesP: "0.239377618192699", constantSitesP: "0.628964691801317", distinctSitesP: "0.371035308198683", brLenTotal: "0.8739" },
  { geneTranscript: "NM_004577.4", parsimonySitesP: "0.237037037037037", constantSitesP: "0.617777777777778", distinctSitesP: "0.4", brLenTotal: "0.7893" },
  { geneTranscript: "NM_004578.4", parsimonySitesP: "0.108562691131498", constantSitesP: "0.795107033639144", distinctSitesP: "0.212538226299694", brLenTotal: "0.4987" },
  { geneTranscript: "NM_004589.4", parsimonySitesP: "0.293466223698782", constantSitesP: "0.572535991140642", distinctSitesP: "0.464008859357697", brLenTotal: "0.9666" },
  { geneTranscript: "NM_004592.4", parsimonySitesP: "0.205397826848931", constantSitesP: "0.705222572730459", distinctSitesP: "0.281458114265685", brLenTotal: "0.9598" },
  { geneTranscript: "NM_004607.3", parsimonySitesP: "0.160493827160494", constantSitesP: "0.712962962962963", distinctSitesP: "0.333333333333333", brLenTotal: "0.5114" },
  { geneTranscript: "NM_004613.4", parsimonySitesP: "0.324599708879185", constantSitesP: "0.557011159631247", distinctSitesP: "0.434255215914605", brLenTotal: "1.2995" },
  { geneTranscript: "NM_004614.5", parsimonySitesP: "0.277987421383648", constantSitesP: "0.587421383647799", distinctSitesP: "0.433962264150943", brLenTotal: "1.0226" },
  { geneTranscript: "NM_004617.4", parsimonySitesP: "0.229372937293729", constantSitesP: "0.638613861386139", distinctSitesP: "0.397689768976898", brLenTotal: "0.9812" },
  { geneTranscript: "NM_004623.5", parsimonySitesP: "0.203273040482343", constantSitesP: "0.605512489233419", distinctSitesP: "0.380706287683032", brLenTotal: "0.7957" },
  { geneTranscript: "NM_004624.4", parsimonySitesP: "0.242159008023341", constantSitesP: "0.63019693654267", distinctSitesP: "0.358862144420131", brLenTotal: "0.8785" },
  { geneTranscript: "NM_004629.2", parsimonySitesP: "0.277063236870311", constantSitesP: "0.555198285101822", distinctSitesP: "0.398713826366559", brLenTotal: "0.8527" },
  { geneTranscript: "NM_004632.4", parsimonySitesP: "0.231993299832496", constantSitesP: "0.6214405360134", distinctSitesP: "0.381909547738693", brLenTotal: "0.7887" },
  { geneTranscript: "NM_004637.6", parsimonySitesP: "0.202898550724638", constantSitesP: "0.697262479871176", distinctSitesP: "0.293075684380032", brLenTotal: "0.7197" },
  { geneTranscript: "NM_004650.3", parsimonySitesP: "0.230566534914361", constantSitesP: "0.654808959156785", distinctSitesP: "0.342555994729908", brLenTotal: "0.7469" },
  { geneTranscript: "NM_004666.3", parsimonySitesP: "0.315139701104613", constantSitesP: "0.516569200779727", distinctSitesP: "0.497076023391813", brLenTotal: "1.3344" },
  { geneTranscript: "NM_004667.6", parsimonySitesP: "0.21555647496897", constantSitesP: "0.664460074472487", distinctSitesP: "0.25127568611226", brLenTotal: "0.9966" },
  { geneTranscript: "NM_004669.3", parsimonySitesP: "0.244350282485876", constantSitesP: "0.625706214689266", distinctSitesP: "0.382768361581921", brLenTotal: "1.1296" },
  { geneTranscript: "NM_004671.5", parsimonySitesP: "0.0966183574879227", constantSitesP: "0.756307031669351", distinctSitesP: "0.176596886741814", brLenTotal: "0.4154" },
  { geneTranscript: "NM_004672.5", parsimonySitesP: "0.240683229813665", constantSitesP: "0.625258799171843", distinctSitesP: "0.324792960662526", brLenTotal: "0.9280" },
  { geneTranscript: "NM_004674.5", parsimonySitesP: "0.168259023354565", constantSitesP: "0.709129511677282", distinctSitesP: "0.262738853503185", brLenTotal: "0.6776" },
  { geneTranscript: "NM_004694.5", parsimonySitesP: "0.233269598470363", constantSitesP: "0.643722115997451", distinctSitesP: "0.339069471000637", brLenTotal: "1.0515" },
  { geneTranscript: "NM_004685.5", parsimonySitesP: "0.155662909286098", constantSitesP: "0.730542136339238", distinctSitesP: "0.252818035426731", brLenTotal: "0.5125" },
  { geneTranscript: "NM_004696.3", parsimonySitesP: "0.23066392881588", constantSitesP: "0.618754277891855", distinctSitesP: "0.351813826146475", brLenTotal: "0.7795" },
  { geneTranscript: "NM_004708.4", parsimonySitesP: "0.133333333333333", constantSitesP: "0.752", distinctSitesP: "0.298666666666667", brLenTotal: "0.4956" },
  { geneTranscript: "NM_004713.6", parsimonySitesP: "0.135997521685254", constantSitesP: "0.750309789343247", distinctSitesP: "0.241325898389095", brLenTotal: "0.4748" },
  { geneTranscript: "NM_004721.5", parsimonySitesP: "0.181849551414769", constantSitesP: "0.700138026224983", distinctSitesP: "0.252933057280883", brLenTotal: "0.6885" },
  { geneTranscript: "NM_004722.4", parsimonySitesP: "0.204562178072112", constantSitesP: "0.683590875643856", distinctSitesP: "0.303899926416483", brLenTotal: "0.7589" },
  { geneTranscript: "NM_004724.4", parsimonySitesP: "0.163029525032092", constantSitesP: "0.699614890885751", distinctSitesP: "0.265297389816003", brLenTotal: "0.5649" },
  { geneTranscript: "NM_004728.4", parsimonySitesP: "0.221796509152831", constantSitesP: "0.604512558535547", distinctSitesP: "0.349084716900809", brLenTotal: "0.8544" },
  { geneTranscript: "NM_004738.5", parsimonySitesP: "0.137174211248285", constantSitesP: "0.772290809327846", distinctSitesP: "0.235939643347051", brLenTotal: "0.5357" },
  { geneTranscript: "NM_004749.4", parsimonySitesP: "0.297939778129952", constantSitesP: "0.567881669307977", distinctSitesP: "0.403063919704173", brLenTotal: "1.0763" },
  { geneTranscript: "NM_004751.3", parsimonySitesP: "0.30441400304414", constantSitesP: "0.566971080669711", distinctSitesP: "0.429984779299848", brLenTotal: "1.0618" },
  { geneTranscript: "NM_004755.4", parsimonySitesP: "0.147547797173732", constantSitesP: "0.761429758935993", distinctSitesP: "0.206566916043225", brLenTotal: "0.6465" },
  { geneTranscript: "NM_004760.3", parsimonySitesP: "0.175523349436393", constantSitesP: "0.69645732689211", distinctSitesP: "0.314814814814815", brLenTotal: "0.5933" },
  { geneTranscript: "NM_004763.5", parsimonySitesP: "0.17", constantSitesP: "0.733333333333333", distinctSitesP: "0.276666666666667", brLenTotal: "0.6394" },
  { geneTranscript: "NM_004772.4", parsimonySitesP: "0.25", constantSitesP: "0.544117647058824", distinctSitesP: "0.441176470588235", brLenTotal: "0.9270" },
  { geneTranscript: "NM_004773.4", parsimonySitesP: "0.223655913978495", constantSitesP: "0.634408602150538", distinctSitesP: "0.408602150537634", brLenTotal: "0.7583" },
  { geneTranscript: "NM_004781.4", parsimonySitesP: "0.246666666666667", constantSitesP: "0.693333333333333", distinctSitesP: "0.353333333333333", brLenTotal: "0.9630" },
  { geneTranscript: "NM_004787.4", parsimonySitesP: "0.167429692609549", constantSitesP: "0.73076084586876", distinctSitesP: "0.213429256594724", brLenTotal: "0.7215" },
  { geneTranscript: "NM_004799.4", parsimonySitesP: "0.16140350877193", constantSitesP: "0.702222222222222", distinctSitesP: "0.234619883040936", brLenTotal: "0.5186" },
  { geneTranscript: "NM_004800.3", parsimonySitesP: "0.131724484665661", constantSitesP: "0.781297134238311", distinctSitesP: "0.201608848667672", brLenTotal: "0.4368" },
  { geneTranscript: "NM_004810.4", parsimonySitesP: "0.202020202020202", constantSitesP: "0.688888888888889", distinctSitesP: "0.329292929292929", brLenTotal: "0.7831" },
  { geneTranscript: "NM_004815.4", parsimonySitesP: "0.197991012424002", constantSitesP: "0.669310071371927", distinctSitesP: "0.299497753106001", brLenTotal: "0.6457" },
  { geneTranscript: "NM_004836.7", parsimonySitesP: "0.18010752688172", constantSitesP: "0.690561529271207", distinctSitesP: "0.262544802867384", brLenTotal: "0.6175" },
  { geneTranscript: "NM_004844.5", parsimonySitesP: "0.170695970695971", constantSitesP: "0.70989010989011", distinctSitesP: "0.24981684981685", brLenTotal: "0.7828" },
  { geneTranscript: "NM_004851.3", parsimonySitesP: "0.31031746031746", constantSitesP: "0.516666666666667", distinctSitesP: "0.470634920634921", brLenTotal: "1.4169" },
  { geneTranscript: "NM_004855.5", parsimonySitesP: "0.187725631768953", constantSitesP: "0.654031287605295", distinctSitesP: "0.339350180505415", brLenTotal: "0.6788" },
  { geneTranscript: "NM_004874.4", parsimonySitesP: "0.192560175054705", constantSitesP: "0.652078774617068", distinctSitesP: "0.334792122538293", brLenTotal: "0.7206" },
  { geneTranscript: "NM_004879.5", parsimonySitesP: "0.142156862745098", constantSitesP: "0.696078431372549", distinctSitesP: "0.258823529411765", brLenTotal: "0.6144" },
  { geneTranscript: "NM_004886.4", parsimonySitesP: "0.329275362318841", constantSitesP: "0.521739130434783", distinctSitesP: "0.433623188405797", brLenTotal: "1.6345" },
  { geneTranscript: "NM_004917.5", parsimonySitesP: "0.299212598425197", constantSitesP: "0.557742782152231", distinctSitesP: "0.44488188976378", brLenTotal: "1.1620" },
  { geneTranscript: "NM_004931.5", parsimonySitesP: "0.358730158730159", constantSitesP: "0.455555555555556", distinctSitesP: "0.588888888888889", brLenTotal: "1.3597" },
  { geneTranscript: "NM_004944.4", parsimonySitesP: "0.25792349726776", constantSitesP: "0.597814207650273", distinctSitesP: "0.398907103825137", brLenTotal: "0.9497" },
  { geneTranscript: "NM_004961.4", parsimonySitesP: "0.260210803689065", constantSitesP: "0.561923583662714", distinctSitesP: "0.404479578392622", brLenTotal: "0.9930" },
  { geneTranscript: "NM_004938.4", parsimonySitesP: "0.179487179487179", constantSitesP: "0.688111888111888", distinctSitesP: "0.267365967365967", brLenTotal: "0.7689" },
  { geneTranscript: "NM_004962.5", parsimonySitesP: "0.281032078103208", constantSitesP: "0.566248256624826", distinctSitesP: "0.400278940027894", brLenTotal: "1.1080" },
  { geneTranscript: "NM_004991.4", parsimonySitesP: "0.148506860371267", constantSitesP: "0.756793112725316", distinctSitesP: "0.189669087974173", brLenTotal: "0.6265" },
  { geneTranscript: "NM_005000.5", parsimonySitesP: "0.169540229885057", constantSitesP: "0.635057471264368", distinctSitesP: "0.410919540229885", brLenTotal: "0.7727" },
  { geneTranscript: "NM_005004.4", parsimonySitesP: "0.277777777777778", constantSitesP: "0.548387096774194", distinctSitesP: "0.458781362007168", brLenTotal: "1.2149" },
  { geneTranscript: "NM_005036.6", parsimonySitesP: "0.25", constantSitesP: "0.636752136752137", distinctSitesP: "0.35042735042735", brLenTotal: "1.4816" },
  { geneTranscript: "NM_005038.3", parsimonySitesP: "0.158558558558559", constantSitesP: "0.665765765765766", distinctSitesP: "0.315315315315315", brLenTotal: "0.6142" },
  { geneTranscript: "NM_005040.4", parsimonySitesP: "0.241263440860215", constantSitesP: "0.610215053763441", distinctSitesP: "0.393817204301075", brLenTotal: "0.7847" },
  { geneTranscript: "NM_005046.4", parsimonySitesP: "0.314888010540184", constantSitesP: "0.550724637681159", distinctSitesP: "0.467720685111989", brLenTotal: "1.4031" },
  { geneTranscript: "NM_005048.4", parsimonySitesP: "0.20969696969697", constantSitesP: "0.634545454545455", distinctSitesP: "0.356969696969697", brLenTotal: "0.7864" },
  { geneTranscript: "NM_005049.3", parsimonySitesP: "0.235038084874864", constantSitesP: "0.649981864345303", distinctSitesP: "0.333333333333333", brLenTotal: "1.0708" },
  { geneTranscript: "NM_005063.5", parsimonySitesP: "0.253481894150418", constantSitesP: "0.587743732590529", distinctSitesP: "0.395543175487465", brLenTotal: "1.1384" },
  { geneTranscript: "NM_005066.3", parsimonySitesP: "0.195190947666195", constantSitesP: "0.60961810466761", distinctSitesP: "0.356907119283357", brLenTotal: "0.9207" },
  { geneTranscript: "NM_005069.6", parsimonySitesP: "0.16791604197901", constantSitesP: "0.709145427286357", distinctSitesP: "0.28735632183908", brLenTotal: "0.7471" },
  { geneTranscript: "NM_005085.4", parsimonySitesP: "0.23030303030303", constantSitesP: "0.61451355661882", distinctSitesP: "0.321850079744817", brLenTotal: "0.8667" },
  { geneTranscript: "NM_005101.4", parsimonySitesP: "0.397979797979798", constantSitesP: "0.44040404040404", distinctSitesP: "0.6", brLenTotal: "1.8801" },
  { geneTranscript: "NM_005102.3", parsimonySitesP: "0.173748819641171", constantSitesP: "0.712936732766761", distinctSitesP: "0.30028328611898", brLenTotal: "0.6648" },
  { geneTranscript: "NM_005103.5", parsimonySitesP: "0.133503401360544", constantSitesP: "0.741496598639456", distinctSitesP: "0.21343537414966", brLenTotal: "0.6138" },
  { geneTranscript: "NM_005107.4", parsimonySitesP: "0.244565217391304", constantSitesP: "0.560688405797101", distinctSitesP: "0.419384057971014", brLenTotal: "0.9474" },
  { geneTranscript: "NM_005108.4", parsimonySitesP: "0.200870646766169", constantSitesP: "0.646766169154229", distinctSitesP: "0.333333333333333", brLenTotal: "0.8388" },
  { geneTranscript: "NM_005110.4", parsimonySitesP: "0.202834799608993", constantSitesP: "0.691104594330401", distinctSitesP: "0.28494623655914", brLenTotal: "0.9084" },
  { geneTranscript: "NM_005113.4", parsimonySitesP: "0.186502507979936", constantSitesP: "0.671226630186959", distinctSitesP: "0.322845417236662", brLenTotal: "0.7022" },
  { geneTranscript: "NM_005117.3", parsimonySitesP: "0.280864197530864", constantSitesP: "0.583333333333333", distinctSitesP: "0.432098765432099", brLenTotal: "1.1999" },
  { geneTranscript: "NM_005118.4", parsimonySitesP: "0.249667994687915", constantSitesP: "0.608233731739708", distinctSitesP: "0.343957503320053", brLenTotal: "0.9060" },
  { geneTranscript: "NM_005124.4", parsimonySitesP: "0.183954802259887", constantSitesP: "0.651525423728814", distinctSitesP: "0.287457627118644", brLenTotal: "0.6830" },
  { geneTranscript: "NM_005126.5", parsimonySitesP: "0.122625215889465", constantSitesP: "0.737478411053541", distinctSitesP: "0.19804260218768", brLenTotal: "0.5716" },
  { geneTranscript: "NM_005138.3", parsimonySitesP: "0.333333333333333", constantSitesP: "0.482456140350877", distinctSitesP: "0.516290726817043", brLenTotal: "1.5031" },
  { geneTranscript: "NM_005149.3", parsimonySitesP: "0.217261904761905", constantSitesP: "0.632440476190476", distinctSitesP: "0.335565476190476", brLenTotal: "1.0032" },
  { geneTranscript: "NM_005153.3", parsimonySitesP: "0.241019214703425", constantSitesP: "0.619465329991646", distinctSitesP: "0.376775271512114", brLenTotal: "1.0149" },
  { geneTranscript: "NM_005154.5", parsimonySitesP: "0.153846153846154", constantSitesP: "0.731365533691115", distinctSitesP: "0.242993440667859", brLenTotal: "0.4905" },
  { geneTranscript: "NM_005160.4", parsimonySitesP: "0.170542635658915", constantSitesP: "0.752422480620155", distinctSitesP: "0.241763565891473", brLenTotal: "0.6492" },
  { geneTranscript: "NM_005167.7", parsimonySitesP: "0.18019801980198", constantSitesP: "0.703630363036304", distinctSitesP: "0.253465346534653", brLenTotal: "0.6032" },
  { geneTranscript: "NM_005170.3", parsimonySitesP: "0.248704663212435", constantSitesP: "0.559585492227979", distinctSitesP: "0.423143350604491", brLenTotal: "1.1120" },
  { geneTranscript: "NM_005175.3", parsimonySitesP: "0.156862745098039", constantSitesP: "0.674019607843137", distinctSitesP: "0.343137254901961", brLenTotal: "0.5210" },
  { geneTranscript: "NM_005181.4", parsimonySitesP: "0.193589743589744", constantSitesP: "0.694871794871795", distinctSitesP: "0.302564102564103", brLenTotal: "0.7534" },
  { geneTranscript: "NM_005185.4", parsimonySitesP: "0.404921700223714", constantSitesP: "0.420581655480984", distinctSitesP: "0.565995525727069", brLenTotal: "1.8579" },
  { geneTranscript: "NM_005192.4", parsimonySitesP: "0.188679245283019", constantSitesP: "0.707547169811321", distinctSitesP: "0.29559748427673", brLenTotal: "0.6136" },
  { geneTranscript: "NM_005204.4", parsimonySitesP: "0.197002141327623", constantSitesP: "0.709493219129193", distinctSitesP: "0.294789436117059", brLenTotal: "0.8252" },
  { geneTranscript: "NM_005205.4", parsimonySitesP: "0.319587628865979", constantSitesP: "0.577319587628866", distinctSitesP: "0.457044673539519", brLenTotal: "0.8461" },
  { geneTranscript: "NM_005224.3", parsimonySitesP: "0.291736930860034", constantSitesP: "0.592467678471051", distinctSitesP: "0.419898819561551", brLenTotal: "1.7248" },
  { geneTranscript: "NM_005228.5", parsimonySitesP: "0.232231404958678", constantSitesP: "0.657575757575758", distinctSitesP: "0.313223140495868", brLenTotal: "1.0804" },
  { geneTranscript: "NM_005231.4", parsimonySitesP: "0.217575757575758", constantSitesP: "0.673333333333333", distinctSitesP: "0.323636363636364", brLenTotal: "1.0992" },
  { geneTranscript: "NM_005248.3", parsimonySitesP: "0.218021424070573", constantSitesP: "0.676118462507876", distinctSitesP: "0.299936988027725", brLenTotal: "0.8274" },
  { geneTranscript: "NM_005250.3", parsimonySitesP: "0.283091787439614", constantSitesP: "0.541062801932367", distinctSitesP: "0.434782608695652", brLenTotal: "1.1019" },
  { geneTranscript: "NM_005304.5", parsimonySitesP: "0.357418111753372", constantSitesP: "0.455684007707129", distinctSitesP: "0.573217726396917", brLenTotal: "1.8354" },
  { geneTranscript: "NM_005320.3", parsimonySitesP: "0.431372549019608", constantSitesP: "0.470588235294118", distinctSitesP: "0.523378582202112", brLenTotal: "2.0740" },
  { geneTranscript: "NM_005326.6", parsimonySitesP: "0.28030303030303", constantSitesP: "0.598484848484848", distinctSitesP: "0.393939393939394", brLenTotal: "1.2179" },
  { geneTranscript: "NM_005327.7", parsimonySitesP: "0.252653927813163", constantSitesP: "0.653927813163482", distinctSitesP: "0.365180467091295", brLenTotal: "0.9349" },
  { geneTranscript: "NM_005330.4", parsimonySitesP: "0.253968253968254", constantSitesP: "0.625850340136054", distinctSitesP: "0.378684807256236", brLenTotal: "0.9299" },
  { geneTranscript: "NM_005333.5", parsimonySitesP: "0.200248756218905", constantSitesP: "0.689054726368159", distinctSitesP: "0.329601990049751", brLenTotal: "0.6818" },
  { geneTranscript: "NM_005353.3", parsimonySitesP: "0.315532586850416", constantSitesP: "0.526557565317255", distinctSitesP: "0.457651449899512", brLenTotal: "1.2923" },
  { geneTranscript: "NM_005357.4", parsimonySitesP: "0.275402726146221", constantSitesP: "0.567224287484511", distinctSitesP: "0.394052044609665", brLenTotal: "1.2773" },
  { geneTranscript: "NM_005385.4", parsimonySitesP: "0.218422252621979", constantSitesP: "0.640218878248974", distinctSitesP: "0.291153670770634", brLenTotal: "0.7355" },
  { geneTranscript: "NM_005390.5", parsimonySitesP: "0.383161512027491", constantSitesP: "0.445017182130584", distinctSitesP: "0.570446735395189", brLenTotal: "1.8642" },
  { geneTranscript: "NM_005393.3", parsimonySitesP: "0.29020429544264", constantSitesP: "0.56731272917758", distinctSitesP: "0.379256155055003", brLenTotal: "1.2439" },
  { geneTranscript: "NM_005410.4", parsimonySitesP: "0.349081364829396", constantSitesP: "0.492563429571304", distinctSitesP: "0.510061242344707", brLenTotal: "1.3210" },
  { geneTranscript: "NM_005411.5", parsimonySitesP: "0.360215053763441", constantSitesP: "0.46505376344086", distinctSitesP: "0.575268817204301", brLenTotal: "1.4339" },
  { geneTranscript: "NM_005414.5", parsimonySitesP: "0.22270955165692", constantSitesP: "0.655945419103314", distinctSitesP: "0.303118908382066", brLenTotal: "0.6923" },
  { geneTranscript: "NM_005419.4", parsimonySitesP: "0.229925577751665", constantSitesP: "0.644339992166079", distinctSitesP: "0.323540932236584", brLenTotal: "0.7972" },
  { geneTranscript: "NM_005429.5", parsimonySitesP: "0.201272871917263", constantSitesP: "0.656324582338902", distinctSitesP: "0.335719968178202", brLenTotal: "0.6765" },
  { geneTranscript: "NM_005443.5", parsimonySitesP: "0.232905982905983", constantSitesP: "0.638888888888889", distinctSitesP: "0.338141025641026", brLenTotal: "1.0883" },
  { geneTranscript: "NM_005454.3", parsimonySitesP: "0.304619225967541", constantSitesP: "0.484394506866417", distinctSitesP: "0.540574282147316", brLenTotal: "1.0941" },
  { geneTranscript: "NM_005459.4", parsimonySitesP: "0.247208931419458", constantSitesP: "0.610845295055821", distinctSitesP: "0.411483253588517", brLenTotal: "0.8533" },
  { geneTranscript: "NM_005460.4", parsimonySitesP: "0.230685527747552", constantSitesP: "0.641639463184621", distinctSitesP: "0.335872324990932", brLenTotal: "0.8551" },
  { geneTranscript: "NM_005475.3", parsimonySitesP: "0.251594202898551", constantSitesP: "0.623188405797101", distinctSitesP: "0.368695652173913", brLenTotal: "1.0432" },
  { geneTranscript: "NM_005491.5", parsimonySitesP: "0.227390180878553", constantSitesP: "0.615848406546081", distinctSitesP: "0.332472006890612", brLenTotal: "1.0019" },
  { geneTranscript: "NM_005504.7", parsimonySitesP: "0.213298791018998", constantSitesP: "0.651986183074266", distinctSitesP: "0.348013816925734", brLenTotal: "0.8130" },
  { geneTranscript: "NM_005506.4", parsimonySitesP: "0.213389121338912", constantSitesP: "0.672245467224547", distinctSitesP: "0.364714086471409", brLenTotal: "0.7862" },
  { geneTranscript: "NM_005516.6", parsimonySitesP: "0.361266294227188", constantSitesP: "0.436685288640596", distinctSitesP: "0.554934823091248", brLenTotal: "2.3470" },
  { geneTranscript: "NM_005525.4", parsimonySitesP: "0.26027397260274", constantSitesP: "0.611872146118721", distinctSitesP: "0.382420091324201", brLenTotal: "0.8815" },
  { geneTranscript: "NM_005526.4", parsimonySitesP: "0.214870825456837", constantSitesP: "0.666036546943919", distinctSitesP: "0.300567107750473", brLenTotal: "0.9201" },
  { geneTranscript: "NM_005536.4", parsimonySitesP: "0.200962695547533", constantSitesP: "0.66546329723225", distinctSitesP: "0.333333333333333", brLenTotal: "0.7590" },
  { geneTranscript: "NM_005540.3", parsimonySitesP: "0.19240598758671", constantSitesP: "0.676889375684556", distinctSitesP: "0.281854691493246", brLenTotal: "0.6452" },
  { geneTranscript: "NM_005554.4", parsimonySitesP: "0.294326241134752", constantSitesP: "0.560283687943262", distinctSitesP: "0.438534278959811", brLenTotal: "1.9603" },
  { geneTranscript: "NM_005555.4", parsimonySitesP: "0.302600472813239", constantSitesP: "0.542553191489362", distinctSitesP: "0.452718676122931", brLenTotal: "2.0681" },
  { geneTranscript: "NM_005567.4", parsimonySitesP: "0.343589743589744", constantSitesP: "0.51965811965812", distinctSitesP: "0.467236467236467", brLenTotal: "1.5272" },
  { geneTranscript: "NM_005570.4", parsimonySitesP: "0.152941176470588", constantSitesP: "0.716993464052288", distinctSitesP: "0.276470588235294", brLenTotal: "0.5666" },
  { geneTranscript: "NM_005574.4", parsimonySitesP: "0.13215859030837", constantSitesP: "0.713656387665198", distinctSitesP: "0.243759177679883", brLenTotal: "0.8477" },
  { geneTranscript: "NM_005575.3", parsimonySitesP: "0.244878048780488", constantSitesP: "0.619512195121951", distinctSitesP: "0.375609756097561", brLenTotal: "0.8012" },
  { geneTranscript: "NM_005583.5", parsimonySitesP: "0.248809523809524", constantSitesP: "0.613095238095238", distinctSitesP: "0.366666666666667", brLenTotal: "1.0087" },
  { geneTranscript: "NM_005591.4", parsimonySitesP: "0.16713747645951", constantSitesP: "0.719868173258004", distinctSitesP: "0.267419962335217", brLenTotal: "0.5806" },
  { geneTranscript: "NM_005601.4", parsimonySitesP: "0.361616161616162", constantSitesP: "0.494949494949495", distinctSitesP: "0.529292929292929", brLenTotal: "1.5001" },
  { geneTranscript: "NM_005606.7", parsimonySitesP: "0.234795996920708", constantSitesP: "0.645881447267129", distinctSitesP: "0.377983063895304", brLenTotal: "1.0865" },
  { geneTranscript: "NM_005619.5", parsimonySitesP: "0.257492354740061", constantSitesP: "0.603669724770642", distinctSitesP: "0.394495412844037", brLenTotal: "0.9969" },
  { geneTranscript: "NM_005620.2", parsimonySitesP: "0.285714285714286", constantSitesP: "0.533333333333333", distinctSitesP: "0.533333333333333", brLenTotal: "1.0115" },
  { geneTranscript: "NM_005626.5", parsimonySitesP: "0.208502024291498", constantSitesP: "0.529014844804318", distinctSitesP: "0.422402159244265", brLenTotal: "1.1415" },
  { geneTranscript: "NM_005644.4", parsimonySitesP: "0.105590062111801", constantSitesP: "0.730848861283644", distinctSitesP: "0.233954451345756", brLenTotal: "0.4550" },
  { geneTranscript: "NM_005668.6", parsimonySitesP: "0.184772516248839", constantSitesP: "0.715877437325905", distinctSitesP: "0.273909006499536", brLenTotal: "0.6044" },
  { geneTranscript: "NM_005675.6", parsimonySitesP: "0.289393939393939", constantSitesP: "0.507575757575758", distinctSitesP: "0.463636363636364", brLenTotal: "1.3904" },
  { geneTranscript: "NM_005676.5", parsimonySitesP: "0.134767025089606", constantSitesP: "0.793189964157706", distinctSitesP: "0.17921146953405", brLenTotal: "0.5213" },
  { geneTranscript: "NM_005677.4", parsimonySitesP: "0.171428571428571", constantSitesP: "0.684981684981685", distinctSitesP: "0.302564102564103", brLenTotal: "0.7981" },
  { geneTranscript: "NM_005701.4", parsimonySitesP: "0.203703703703704", constantSitesP: "0.616666666666667", distinctSitesP: "0.350925925925926", brLenTotal: "0.7657" },
  { geneTranscript: "NM_005702.4", parsimonySitesP: "0.283752860411899", constantSitesP: "0.567505720823799", distinctSitesP: "0.418764302059497", brLenTotal: "0.9117" },
  { geneTranscript: "NM_005720.4", parsimonySitesP: "0.218637992831541", constantSitesP: "0.658602150537634", distinctSitesP: "0.310931899641577", brLenTotal: "1.2230" },
  { geneTranscript: "NM_005727.4", parsimonySitesP: "0.265560165975104", constantSitesP: "0.608575380359613", distinctSitesP: "0.401106500691563", brLenTotal: "1.0877" },
  { geneTranscript: "NM_005734.5", parsimonySitesP: "0.143758573388203", constantSitesP: "0.713031550068587", distinctSitesP: "0.196707818930041", brLenTotal: "0.6520" },
  { geneTranscript: "NM_005741.5", parsimonySitesP: "0.235236700829673", constantSitesP: "0.623718887262079", distinctSitesP: "0.346022449975598", brLenTotal: "0.9824" },
  { geneTranscript: "NM_005747.5", parsimonySitesP: "0.332098765432099", constantSitesP: "0.525925925925926", distinctSitesP: "0.488888888888889", brLenTotal: "1.7282" },
  { geneTranscript: "NM_005755.3", parsimonySitesP: "0.334788937409025", constantSitesP: "0.503639010189229", distinctSitesP: "0.510917030567686", brLenTotal: "1.6333" },
  { geneTranscript: "NM_005760.3", parsimonySitesP: "0.201454775458571", constantSitesP: "0.666034155597723", distinctSitesP: "0.308349146110057", brLenTotal: "0.6863" },
  { geneTranscript: "NM_005761.3", parsimonySitesP: "0.160501700680272", constantSitesP: "0.732993197278912", distinctSitesP: "0.240008503401361", brLenTotal: "0.5930" },
  { geneTranscript: "NM_005764.4", parsimonySitesP: "0.239766081871345", constantSitesP: "0.590643274853801", distinctSitesP: "0.429824561403509", brLenTotal: "1.0916" },
  { geneTranscript: "NM_005777.3", parsimonySitesP: "0.151973879489463", constantSitesP: "0.724547343425349", distinctSitesP: "0.232116355001484", brLenTotal: "0.4989" },
  { geneTranscript: "NM_005788.4", parsimonySitesP: "0.161330822347772", constantSitesP: "0.709353421217828", distinctSitesP: "0.278719397363465", brLenTotal: "0.5501" },
  { geneTranscript: "NM_005809.6", parsimonySitesP: "0.252525252525253", constantSitesP: "0.617845117845118", distinctSitesP: "0.372053872053872", brLenTotal: "0.9784" },
  { geneTranscript: "NM_005833.4", parsimonySitesP: "0.229390681003584", constantSitesP: "0.563620071684588", distinctSitesP: "0.387992831541219", brLenTotal: "0.7849" },
  { geneTranscript: "NM_005836.3", parsimonySitesP: "0.211678832116788", constantSitesP: "0.615571776155718", distinctSitesP: "0.423357664233577", brLenTotal: "0.7529" },
  { geneTranscript: "NM_005839.4", parsimonySitesP: "0.130530973451327", constantSitesP: "0.707964601769912", distinctSitesP: "0.240044247787611", brLenTotal: "0.5518" },
  { geneTranscript: "NM_005860.3", parsimonySitesP: "0.243346007604563", constantSitesP: "0.608365019011407", distinctSitesP: "0.385297845373891", brLenTotal: "1.0202" },
  { geneTranscript: "NM_005865.4", parsimonySitesP: "0.289234760051881", constantSitesP: "0.54863813229572", distinctSitesP: "0.405966277561608", brLenTotal: "1.0487" },
  { geneTranscript: "NM_005898.5", parsimonySitesP: "0.130230371415139", constantSitesP: "0.731076633756465", distinctSitesP: "0.223319228960978", brLenTotal: "0.5379" },
  { geneTranscript: "NM_005904.4", parsimonySitesP: "0.0774647887323944", constantSitesP: "0.822378716744914", distinctSitesP: "0.150234741784038", brLenTotal: "0.5014" },
  { geneTranscript: "NM_005907.4", parsimonySitesP: "0.162838182746299", constantSitesP: "0.706482899438489", distinctSitesP: "0.255232261357836", brLenTotal: "0.6194" },
  { geneTranscript: "NM_005940.5", parsimonySitesP: "0.256830601092896", constantSitesP: "0.618169398907104", distinctSitesP: "0.362021857923497", brLenTotal: "1.0674" },
  { geneTranscript: "NM_005959.5", parsimonySitesP: "0.411602209944751", constantSitesP: "0.300184162062615", distinctSitesP: "0.714548802946593", brLenTotal: "2.0807" },
  { geneTranscript: "NM_005966.4", parsimonySitesP: "0.156741957563313", constantSitesP: "0.659822039698836", distinctSitesP: "0.316906228610541", brLenTotal: "0.7534" },
  { geneTranscript: "NM_005987.4", parsimonySitesP: "0.400749063670412", constantSitesP: "0.393258426966292", distinctSitesP: "0.670411985018727", brLenTotal: "2.0579" },
  { geneTranscript: "NM_005989.4", parsimonySitesP: "0.23721881390593", constantSitesP: "0.612474437627812", distinctSitesP: "0.392638036809816", brLenTotal: "0.9594" },
  { geneTranscript: "NM_005995.5", parsimonySitesP: "0.280519480519481", constantSitesP: "0.554112554112554", distinctSitesP: "0.434632034632035", brLenTotal: "1.0744" },
  { geneTranscript: "NM_006033.4", parsimonySitesP: "0.244666666666667", constantSitesP: "0.618", distinctSitesP: "0.358", brLenTotal: "1.0103" },
  { geneTranscript: "NM_006065.5", parsimonySitesP: "0.468174204355109", constantSitesP: "0.334170854271357", distinctSitesP: "0.675041876046901", brLenTotal: "2.5519" },
  { geneTranscript: "NM_006084.5", parsimonySitesP: "0.229855810008482", constantSitesP: "0.6225614927905", distinctSitesP: "0.365564037319763", brLenTotal: "0.8226" },
  { geneTranscript: "NM_006085.6", parsimonySitesP: "0.16991341991342", constantSitesP: "0.687229437229437", distinctSitesP: "0.292207792207792", brLenTotal: "0.5916" },
  { geneTranscript: "NM_006111.3", parsimonySitesP: "0.228379513014274", constantSitesP: "0.644836272040302", distinctSitesP: "0.348446683459278", brLenTotal: "0.7481" },
  { geneTranscript: "NM_006120.4", parsimonySitesP: "0.27330779054917", constantSitesP: "0.60536398467433", distinctSitesP: "0.41507024265645", brLenTotal: "1.0780" },
  { geneTranscript: "NM_006121.4", parsimonySitesP: "0.328674948240166", constantSitesP: "0.518633540372671", distinctSitesP: "0.481884057971014", brLenTotal: "1.5747" },
  { geneTranscript: "NM_006149.4", parsimonySitesP: "0.304437564499484", constantSitesP: "0.569659442724458", distinctSitesP: "0.447884416924665", brLenTotal: "1.1786" },
  { geneTranscript: "NM_006172.4", parsimonySitesP: "0.278145695364238", constantSitesP: "0.584988962472406", distinctSitesP: "0.434878587196468", brLenTotal: "1.1513" },
  { geneTranscript: "NM_006190.5", parsimonySitesP: "0.143269786250722", constantSitesP: "0.725592143269786", distinctSitesP: "0.268053148469093", brLenTotal: "0.4794" },
  { geneTranscript: "NM_006205.3", parsimonySitesP: "0.184738955823293", constantSitesP: "0.738955823293173", distinctSitesP: "0.309236947791165", brLenTotal: "0.7452" },
  { geneTranscript: "NM_006206.6", parsimonySitesP: "0.216712580348944", constantSitesP: "0.685644322007958", distinctSitesP: "0.314355677992042", brLenTotal: "1.0662" },
  { geneTranscript: "NM_006218.4", parsimonySitesP: "0.102372034956305", constantSitesP: "0.800561797752809", distinctSitesP: "0.149812734082397", brLenTotal: "0.4234" },
  { geneTranscript: "NM_006219.3", parsimonySitesP: "0.13613707165109", constantSitesP: "0.732710280373832", distinctSitesP: "0.199376947040498", brLenTotal: "0.5724" },
  { geneTranscript: "NM_006229.4", parsimonySitesP: "0.240542469664525", constantSitesP: "0.573162027123483", distinctSitesP: "0.426124197002141", brLenTotal: "0.9500" },
  { geneTranscript: "NM_006234.6", parsimonySitesP: "0.148148148148148", constantSitesP: "0.746438746438746", distinctSitesP: "0.279202279202279", brLenTotal: "0.6005" },
  { geneTranscript: "NM_006259.3", parsimonySitesP: "0.207786526684164", constantSitesP: "0.697725284339458", distinctSitesP: "0.310148731408574", brLenTotal: "0.8761" },
  { geneTranscript: "NM_006274.3", parsimonySitesP: "0.275510204081633", constantSitesP: "0.585034013605442", distinctSitesP: "0.438775510204082", brLenTotal: "0.8094" },
  { geneTranscript: "NM_006291.4", parsimonySitesP: "0.278797145769623", constantSitesP: "0.575942915392457", distinctSitesP: "0.412844036697248", brLenTotal: "1.0531" },
  { geneTranscript: "NM_006294.5", parsimonySitesP: "0.219219219219219", constantSitesP: "0.660660660660661", distinctSitesP: "0.378378378378378", brLenTotal: "0.7595" },
  { geneTranscript: "NM_006299.5", parsimonySitesP: "0.264805414551607", constantSitesP: "0.512690355329949", distinctSitesP: "0.452622673434856", brLenTotal: "1.0687" },
  { geneTranscript: "NM_006305.4", parsimonySitesP: "0.119143239625167", constantSitesP: "0.760374832663989", distinctSitesP: "0.227576974564926", brLenTotal: "0.5454" },
  { geneTranscript: "NM_006314.3", parsimonySitesP: "0.278634876110332", constantSitesP: "0.546517064048621", distinctSitesP: "0.417952314165498", brLenTotal: "1.0261" },
  { geneTranscript: "NM_006320.6", parsimonySitesP: "0.115097159940209", constantSitesP: "0.753363228699552", distinctSitesP: "0.224215246636771", brLenTotal: "0.5079" },
  { geneTranscript: "NM_006322.6", parsimonySitesP: "0.207276736493936", constantSitesP: "0.689819919147372", distinctSitesP: "0.297684674751929", brLenTotal: "0.9706" },
  { geneTranscript: "NM_006328.4", parsimonySitesP: "0.0817140009965122", constantSitesP: "0.823119083208769", distinctSitesP: "0.144494270054808", brLenTotal: "0.3535" },
  { geneTranscript: "NM_006329.4", parsimonySitesP: "0.155505952380952", constantSitesP: "0.75297619047619", distinctSitesP: "0.225446428571429", brLenTotal: "0.6583" },
  { geneTranscript: "NM_006325.5", parsimonySitesP: "0.228395061728395", constantSitesP: "0.618827160493827", distinctSitesP: "0.395061728395062", brLenTotal: "1.1061" },
  { geneTranscript: "NM_006330.4", parsimonySitesP: "0.210144927536232", constantSitesP: "0.655072463768116", distinctSitesP: "0.385507246376812", brLenTotal: "0.7278" },
  { geneTranscript: "NM_006345.4", parsimonySitesP: "0.170774647887324", constantSitesP: "0.727699530516432", distinctSitesP: "0.298708920187793", brLenTotal: "0.6212" },
  { geneTranscript: "NM_006360.6", parsimonySitesP: "0.114973262032086", constantSitesP: "0.788770053475936", distinctSitesP: "0.189839572192513", brLenTotal: "0.4809" },
  { geneTranscript: "NM_006373.4", parsimonySitesP: "0.202714164546226", constantSitesP: "0.678541136556404", distinctSitesP: "0.309584393553859", brLenTotal: "0.8413" },
  { geneTranscript: "NM_006392.4", parsimonySitesP: "0.204826038159371", constantSitesP: "0.651515151515152", distinctSitesP: "0.313692480359147", brLenTotal: "0.8427" },
  { geneTranscript: "NM_006408.4", parsimonySitesP: "0.188571428571429", constantSitesP: "0.697142857142857", distinctSitesP: "0.337142857142857", brLenTotal: "0.6149" },
  { geneTranscript: "NM_006412.4", parsimonySitesP: "0.302158273381295", constantSitesP: "0.569544364508393", distinctSitesP: "0.428057553956835", brLenTotal: "1.2417" },
  { geneTranscript: "NM_006413.5", parsimonySitesP: "0.167910447761194", constantSitesP: "0.733830845771144", distinctSitesP: "0.284825870646766", brLenTotal: "0.5599" },
  { geneTranscript: "NM_006428.5", parsimonySitesP: "0.303385416666667", constantSitesP: "0.567708333333333", distinctSitesP: "0.4453125", brLenTotal: "1.2966" },
  { geneTranscript: "NM_006432.5", parsimonySitesP: "0.233995584988962", constantSitesP: "0.642384105960265", distinctSitesP: "0.408388520971302", brLenTotal: "0.9359" },
  { geneTranscript: "NM_006447.3", parsimonySitesP: "0.159983799108951", constantSitesP: "0.700283515593358", distinctSitesP: "0.283515593357635", brLenTotal: "0.5673" },
  { geneTranscript: "NM_006438.5", parsimonySitesP: "0.194945848375451", constantSitesP: "0.69193742478941", distinctSitesP: "0.314079422382671", brLenTotal: "0.6461" },
  { geneTranscript: "NM_006453.3", parsimonySitesP: "0.258663366336634", constantSitesP: "0.610561056105611", distinctSitesP: "0.384075907590759", brLenTotal: "1.2158" },
  { geneTranscript: "NM_006456.3", parsimonySitesP: "0.288770053475936", constantSitesP: "0.564171122994652", distinctSitesP: "0.414438502673797", brLenTotal: "1.2205" },
  { geneTranscript: "NM_006459.4", parsimonySitesP: "0.135057471264368", constantSitesP: "0.761494252873563", distinctSitesP: "0.21455938697318", brLenTotal: "0.4769" },
  { geneTranscript: "NM_006457.5", parsimonySitesP: "0.178970917225951", constantSitesP: "0.692953020134228", distinctSitesP: "0.275167785234899", brLenTotal: "0.6709" },
  { geneTranscript: "NM_006460.3", parsimonySitesP: "0.184772516248839", constantSitesP: "0.638811513463324", distinctSitesP: "0.285979572887651", brLenTotal: "0.7267" },
  { geneTranscript: "NM_006502.3", parsimonySitesP: "0.222533894343151", constantSitesP: "0.626928471248247", distinctSitesP: "0.338943431510051", brLenTotal: "0.6998" },
  { geneTranscript: "NM_006511.3", parsimonySitesP: "0.271745002701243", constantSitesP: "0.531064289573204", distinctSitesP: "0.431118314424635", brLenTotal: "0.9041" },
  { geneTranscript: "NM_006515.4", parsimonySitesP: "0.282651072124756", constantSitesP: "0.440058479532164", distinctSitesP: "0.517056530214425", brLenTotal: "1.9916" },
  { geneTranscript: "NM_006527.4", parsimonySitesP: "0.190123456790123", constantSitesP: "0.685185185185185", distinctSitesP: "0.332098765432099", brLenTotal: "0.6612" },
  { geneTranscript: "NM_006533.4", parsimonySitesP: "0.234096692111959", constantSitesP: "0.646310432569975", distinctSitesP: "0.363867684478372", brLenTotal: "0.7264" },
  { geneTranscript: "NM_006554.5", parsimonySitesP: "0.106463878326996", constantSitesP: "0.818757921419518", distinctSitesP: "0.202788339670469", brLenTotal: "0.3165" },
  { geneTranscript: "NM_006555.4", parsimonySitesP: "0.144781144781145", constantSitesP: "0.764309764309764", distinctSitesP: "0.235690235690236", brLenTotal: "0.5546" },
  { geneTranscript: "NM_006568.3", parsimonySitesP: "0.180722891566265", constantSitesP: "0.697791164658635", distinctSitesP: "0.290160642570281", brLenTotal: "0.6434" },
  { geneTranscript: "NM_006591.3", parsimonySitesP: "0.165236051502146", constantSitesP: "0.699570815450644", distinctSitesP: "0.266809728183119", brLenTotal: "0.5462" },
  { geneTranscript: "NM_006614.4", parsimonySitesP: "0.186546840958606", constantSitesP: "0.693082788671024", distinctSitesP: "0.30718954248366", brLenTotal: "0.7057" },
  { geneTranscript: "NM_006615.3", parsimonySitesP: "0.257487922705314", constantSitesP: "0.624154589371981", distinctSitesP: "0.370531400966184", brLenTotal: "1.0034" },
  { geneTranscript: "NM_006633.5", parsimonySitesP: "0.188571428571429", constantSitesP: "0.695449735449735", distinctSitesP: "0.291851851851852", brLenTotal: "0.7402" },
  { geneTranscript: "NM_006635.4", parsimonySitesP: "0.490510083036773", constantSitesP: "0.34282325029656", distinctSitesP: "0.638196915776987", brLenTotal: "2.5838" },
  { geneTranscript: "NM_006646.6", parsimonySitesP: "0.194555112881806", constantSitesP: "0.705179282868526", distinctSitesP: "0.283532536520584", brLenTotal: "0.9809" },
  { geneTranscript: "NM_006694.4", parsimonySitesP: "0.228310502283105", constantSitesP: "0.664383561643836", distinctSitesP: "0.376712328767123", brLenTotal: "0.6694" },
  { geneTranscript: "NM_006700.3", parsimonySitesP: "0.213631156930126", constantSitesP: "0.628293241695304", distinctSitesP: "0.349369988545246", brLenTotal: "0.7162" },
  { geneTranscript: "NM_006708.3", parsimonySitesP: "0.175724637681159", constantSitesP: "0.608695652173913", distinctSitesP: "0.376811594202899", brLenTotal: "0.7899" },
  { geneTranscript: "NM_006729.5", parsimonySitesP: "0.151377535573721", constantSitesP: "0.730547986678777", distinctSitesP: "0.254011504692704", brLenTotal: "0.5234" },
  { geneTranscript: "NM_006741.4", parsimonySitesP: "0.191033138401559", constantSitesP: "0.676413255360624", distinctSitesP: "0.319688109161793", brLenTotal: "0.6426" },
  { geneTranscript: "NM_006743.5", parsimonySitesP: "0.16135881104034", constantSitesP: "0.734607218683652", distinctSitesP: "0.290870488322718", brLenTotal: "0.5306" },
  { geneTranscript: "NM_006753.6", parsimonySitesP: "0.33056325023084", constantSitesP: "0.433979686057248", distinctSitesP: "0.57617728531856", brLenTotal: "1.4586" },
  { geneTranscript: "NM_006758.3", parsimonySitesP: "0.151388888888889", constantSitesP: "0.797222222222222", distinctSitesP: "0.202777777777778", brLenTotal: "0.6934" },
  { geneTranscript: "NM_006760.4", parsimonySitesP: "0.230072463768116", constantSitesP: "0.603260869565217", distinctSitesP: "0.389492753623188", brLenTotal: "0.8795" },
  { geneTranscript: "NM_006771.4", parsimonySitesP: "0.309210526315789", constantSitesP: "0.52046783625731", distinctSitesP: "0.47733918128655", brLenTotal: "1.8389" },
  { geneTranscript: "NM_006773.4", parsimonySitesP: "0.221393034825871", constantSitesP: "0.618905472636816", distinctSitesP: "0.360696517412935", brLenTotal: "0.8998" },
  { geneTranscript: "NM_006785.4", parsimonySitesP: "0.164644012944984", constantSitesP: "0.726132686084142", distinctSitesP: "0.254449838187702", brLenTotal: "0.5983" },
  { geneTranscript: "NM_006788.4", parsimonySitesP: "0.184223918575064", constantSitesP: "0.673282442748092", distinctSitesP: "0.306361323155216", brLenTotal: "0.7125" },
  { geneTranscript: "NM_006793.5", parsimonySitesP: "0.21875", constantSitesP: "0.6328125", distinctSitesP: "0.391927083333333", brLenTotal: "0.8301" },
  { geneTranscript: "NM_006811.4", parsimonySitesP: "0.249471458773784", constantSitesP: "0.625792811839323", distinctSitesP: "0.391825229034531", brLenTotal: "0.7657" },
  { geneTranscript: "NM_006813.3", parsimonySitesP: "0.206931702344546", constantSitesP: "0.594291539245668", distinctSitesP: "0.396534148827727", brLenTotal: "0.8112" },
  { geneTranscript: "NM_006817.4", parsimonySitesP: "0.205619412515964", constantSitesP: "0.623243933588761", distinctSitesP: "0.344827586206897", brLenTotal: "0.8852" },
  { geneTranscript: "NM_006824.3", parsimonySitesP: "0.19281045751634", constantSitesP: "0.668845315904139", distinctSitesP: "0.321350762527233", brLenTotal: "0.6250" },
  { geneTranscript: "NM_006825.4", parsimonySitesP: "0.203211517165006", constantSitesP: "0.656146179401993", distinctSitesP: "0.313399778516058", brLenTotal: "0.8848" },
  { geneTranscript: "NM_006829.3", parsimonySitesP: "0.263157894736842", constantSitesP: "0.583333333333333", distinctSitesP: "0.43859649122807", brLenTotal: "0.7708" },
  { geneTranscript: "NM_006830.4", parsimonySitesP: "0.404761904761905", constantSitesP: "0.470238095238095", distinctSitesP: "0.583333333333333", brLenTotal: "1.4096" },
  { geneTranscript: "NM_006838.4", parsimonySitesP: "0.126917712691771", constantSitesP: "0.682008368200837", distinctSitesP: "0.260111576011158", brLenTotal: "0.5794" },
  { geneTranscript: "NM_006839.3", parsimonySitesP: "0.175901495162709", constantSitesP: "0.693051890941073", distinctSitesP: "0.259454705364996", brLenTotal: "0.5844" },
  { geneTranscript: "NM_006849.4", parsimonySitesP: "0.351746031746032", constantSitesP: "0.496507936507937", distinctSitesP: "0.500952380952381", brLenTotal: "1.6976" },
  { geneTranscript: "NM_006859.4", parsimonySitesP: "0.17831541218638", constantSitesP: "0.694444444444444", distinctSitesP: "0.310035842293907", brLenTotal: "0.5935" },
  { geneTranscript: "NM_006863.4", parsimonySitesP: "0.485344239945467", constantSitesP: "0.300613496932515", distinctSitesP: "0.676891615541922", brLenTotal: "3.6809" },
  { geneTranscript: "NM_006864.4", parsimonySitesP: "0.47068145800317", constantSitesP: "0.288959323824617", distinctSitesP: "0.691494981510829", brLenTotal: "3.3024" },
  { geneTranscript: "NM_006886.4", parsimonySitesP: "0.163398692810458", constantSitesP: "0.738562091503268", distinctSitesP: "0.30718954248366", brLenTotal: "0.6069" },
  { geneTranscript: "NM_006892.4", parsimonySitesP: "0.180539273153576", constantSitesP: "0.715123094958968", distinctSitesP: "0.268073466197733", brLenTotal: "0.7335" },
  { geneTranscript: "NM_006895.3", parsimonySitesP: "0.235159817351598", constantSitesP: "0.59703196347032", distinctSitesP: "0.381278538812785", brLenTotal: "0.8430" },
  { geneTranscript: "NM_006901.4", parsimonySitesP: "0.165227629513344", constantSitesP: "0.703296703296703", distinctSitesP: "0.221873364730508", brLenTotal: "0.5925" },
  { geneTranscript: "NM_006909.3", parsimonySitesP: "0.157100511991377", constantSitesP: "0.734572891403934", distinctSitesP: "0.220156292104554", brLenTotal: "0.8445" },
  { geneTranscript: "NM_006931.3", parsimonySitesP: "0.317204301075269", constantSitesP: "0.546370967741935", distinctSitesP: "0.448252688172043", brLenTotal: "1.4008" },
  { geneTranscript: "NM_006933.7", parsimonySitesP: "0.110027855153203", constantSitesP: "0.803621169916435", distinctSitesP: "0.166202414113278", brLenTotal: "0.3441" },
  { geneTranscript: "NM_006942.2", parsimonySitesP: "0.301859799713877", constantSitesP: "0.489270386266094", distinctSitesP: "0.50071530758226", brLenTotal: "1.1812" },
  { geneTranscript: "NM_006951.5", parsimonySitesP: "0.1175", constantSitesP: "0.787916666666667", distinctSitesP: "0.1675", brLenTotal: "0.4001" },
  { geneTranscript: "NM_006952.4", parsimonySitesP: "0.182051282051282", constantSitesP: "0.701282051282051", distinctSitesP: "0.293589743589744", brLenTotal: "0.6336" },
  { geneTranscript: "NM_006984.5", parsimonySitesP: "0.128654970760234", constantSitesP: "0.805555555555556", distinctSitesP: "0.20906432748538", brLenTotal: "0.3988" },
  { geneTranscript: "NM_006987.4", parsimonySitesP: "0.296296296296296", constantSitesP: "0.562962962962963", distinctSitesP: "0.445502645502646", brLenTotal: "1.2203" },
  { geneTranscript: "NM_006988.5", parsimonySitesP: "0.22440537745605", constantSitesP: "0.655291278869355", distinctSitesP: "0.29438124784557", brLenTotal: "0.8318" },
  { geneTranscript: "NM_006990.5", parsimonySitesP: "0.147255689424364", constantSitesP: "0.748995983935743", distinctSitesP: "0.228915662650602", brLenTotal: "0.6286" },
  { geneTranscript: "NM_006993.3", parsimonySitesP: "0.226591760299625", constantSitesP: "0.608614232209738", distinctSitesP: "0.400749063670412", brLenTotal: "0.7874" },
  { geneTranscript: "NM_006999.6", parsimonySitesP: "0.173400673400673", constantSitesP: "0.717171717171717", distinctSitesP: "0.296717171717172", brLenTotal: "0.7511" },
  { geneTranscript: "NM_007002.4", parsimonySitesP: "0.198198198198198", constantSitesP: "0.722358722358722", distinctSitesP: "0.290745290745291", brLenTotal: "1.0522" },
  { geneTranscript: "NM_007027.4", parsimonySitesP: "0.203022339027595", constantSitesP: "0.648707840560666", distinctSitesP: "0.31712658782304", brLenTotal: "0.7450" },
  { geneTranscript: "NM_007033.5", parsimonySitesP: "0.17687074829932", constantSitesP: "0.751700680272109", distinctSitesP: "0.261904761904762", brLenTotal: "0.8022" },
  { geneTranscript: "NM_007035.4", parsimonySitesP: "0.21780303030303", constantSitesP: "0.675189393939394", distinctSitesP: "0.348484848484848", brLenTotal: "0.6682" },
  { geneTranscript: "NM_007039.4", parsimonySitesP: "0.203861442362294", constantSitesP: "0.661555934128336", distinctSitesP: "0.284497444633731", brLenTotal: "1.0001" },
  { geneTranscript: "NM_007042.6", parsimonySitesP: "0.161290322580645", constantSitesP: "0.690860215053763", distinctSitesP: "0.311827956989247", brLenTotal: "0.6312" },
  { geneTranscript: "NM_007052.5", parsimonySitesP: "0.179669030732861", constantSitesP: "0.66193853427896", distinctSitesP: "0.276595744680851", brLenTotal: "0.6723" },
  { geneTranscript: "NM_007053.4", parsimonySitesP: "0.360957642725599", constantSitesP: "0.464088397790055", distinctSitesP: "0.580110497237569", brLenTotal: "1.1284" },
  { geneTranscript: "NM_007083.5", parsimonySitesP: "0.255274261603376", constantSitesP: "0.584388185654008", distinctSitesP: "0.439873417721519", brLenTotal: "0.9477" },
  { geneTranscript: "NM_007084.4", parsimonySitesP: "0.166666666666667", constantSitesP: "0.565217391304348", distinctSitesP: "0.31280193236715", brLenTotal: "2.5594" },
  { geneTranscript: "NM_007086.4", parsimonySitesP: "0.183643342190729", constantSitesP: "0.663123708296428", distinctSitesP: "0.324771183938589", brLenTotal: "0.6916" },
  { geneTranscript: "NM_007097.5", parsimonySitesP: "0.168850072780204", constantSitesP: "0.74235807860262", distinctSitesP: "0.263464337700146", brLenTotal: "0.6581" },
  { geneTranscript: "NM_007106.4", parsimonySitesP: "0.0997150997150997", constantSitesP: "0.800569800569801", distinctSitesP: "0.219373219373219", brLenTotal: "0.3568" },
  { geneTranscript: "NM_007111.5", parsimonySitesP: "0.210569105691057", constantSitesP: "0.650406504065041", distinctSitesP: "0.317886178861789", brLenTotal: "1.1820" },
  { geneTranscript: "NM_007114.3", parsimonySitesP: "0.177188167124123", constantSitesP: "0.683135102165294", distinctSitesP: "0.282403171698689", brLenTotal: "0.5970" },
  { geneTranscript: "NM_007120.3", parsimonySitesP: "0.331460674157303", constantSitesP: "0.49063670411985", distinctSitesP: "0.531835205992509", brLenTotal: "1.6123" },
  { geneTranscript: "NM_007128.4", parsimonySitesP: "0.482758620689655", constantSitesP: "0.308045977011494", distinctSitesP: "0.708045977011494", brLenTotal: "1.9842" },
  { geneTranscript: "NM_007135.3", parsimonySitesP: "0.311244979919679", constantSitesP: "0.479250334672021", distinctSitesP: "0.447791164658635", brLenTotal: "1.7258" },
  { geneTranscript: "NM_007144.3", parsimonySitesP: "0.150193798449612", constantSitesP: "0.75968992248062", distinctSitesP: "0.231589147286822", brLenTotal: "0.5475" },
  { geneTranscript: "NM_007147.4", parsimonySitesP: "0.433661509610877", constantSitesP: "0.344116268166901", distinctSitesP: "0.592123769338959", brLenTotal: "2.0821" },
  { geneTranscript: "NM_007167.4", parsimonySitesP: "0.213081761006289", constantSitesP: "0.65685534591195", distinctSitesP: "0.289056603773585", brLenTotal: "0.8143" },
  { geneTranscript: "NM_007187.5", parsimonySitesP: "0.179078014184397", constantSitesP: "0.683510638297872", distinctSitesP: "0.36258865248227", brLenTotal: "0.5936" },
  { geneTranscript: "NM_007172.4", parsimonySitesP: "0.283475783475783", constantSitesP: "0.530626780626781", distinctSitesP: "0.471509971509972", brLenTotal: "1.1592" },
  { geneTranscript: "NM_007191.5", parsimonySitesP: "0.19349164467898", constantSitesP: "0.688654353562005", distinctSitesP: "0.308707124010554", brLenTotal: "0.7711" },
  { geneTranscript: "NM_007195.3", parsimonySitesP: "0.233783783783784", constantSitesP: "0.572972972972973", distinctSitesP: "0.458108108108108", brLenTotal: "0.8402" },
  { geneTranscript: "NM_007196.4", parsimonySitesP: "0.297435897435897", constantSitesP: "0.578205128205128", distinctSitesP: "0.416666666666667", brLenTotal: "1.1276" },
  { geneTranscript: "NM_007198.4", parsimonySitesP: "0.237575757575758", constantSitesP: "0.64", distinctSitesP: "0.353939393939394", brLenTotal: "0.8732" },
  { geneTranscript: "NM_007202.4", parsimonySitesP: "0.143001007049345", constantSitesP: "0.746727089627392", distinctSitesP: "0.253272910372608", brLenTotal: "0.4859" },
  { geneTranscript: "NM_007207.6", parsimonySitesP: "0.16597510373444", constantSitesP: "0.721300138312586", distinctSitesP: "0.270401106500692", brLenTotal: "0.6361" },
  { geneTranscript: "NM_007220.4", parsimonySitesP: "0.201892744479495", constantSitesP: "0.596214511041009", distinctSitesP: "0.391167192429022", brLenTotal: "0.9004" },
  { geneTranscript: "NM_007235.6", parsimonySitesP: "0.159043659043659", constantSitesP: "0.729383229383229", distinctSitesP: "0.242550242550243", brLenTotal: "0.5930" },
  { geneTranscript: "NM_007238.5", parsimonySitesP: "0.25314465408805", constantSitesP: "0.639937106918239", distinctSitesP: "0.377358490566038", brLenTotal: "0.9447" },
  { geneTranscript: "NM_007266.4", parsimonySitesP: "0.163992869875223", constantSitesP: "0.700534759358289", distinctSitesP: "0.300356506238859", brLenTotal: "0.6128" },
  { geneTranscript: "NM_007270.5", parsimonySitesP: "0.181286549707602", constantSitesP: "0.712280701754386", distinctSitesP: "0.247953216374269", brLenTotal: "0.7769" },
  { geneTranscript: "NM_007283.7", parsimonySitesP: "0.276890308839191", constantSitesP: "0.613418530351438", distinctSitesP: "0.417465388711395", brLenTotal: "1.3138" },
  { geneTranscript: "NM_007314.4", parsimonySitesP: "0.170614777213762", constantSitesP: "0.697969543147208", distinctSitesP: "0.23716864072194", brLenTotal: "0.7200" },
  { geneTranscript: "NM_007315.4", parsimonySitesP: "0.158222222222222", constantSitesP: "0.748", distinctSitesP: "0.251111111111111", brLenTotal: "0.6640" },
  { geneTranscript: "NM_007322.3", parsimonySitesP: "0.208112874779541", constantSitesP: "0.680188124632569", distinctSitesP: "0.312757201646091", brLenTotal: "0.8599" },
  { geneTranscript: "NM_007335.4", parsimonySitesP: "0.294966761633428", constantSitesP: "0.530864197530864", distinctSitesP: "0.429439696106363", brLenTotal: "1.1151" },
  { geneTranscript: "NM_007342.3", parsimonySitesP: "0.235618597320725", constantSitesP: "0.58628841607565", distinctSitesP: "0.40661938534279", brLenTotal: "0.8358" },
  { geneTranscript: "NM_007347.5", parsimonySitesP: "0.167692758721782", constantSitesP: "0.710642040457344", distinctSitesP: "0.263559073585459", brLenTotal: "0.5410" },
  { geneTranscript: "NM_007353.3", parsimonySitesP: "0.226596675415573", constantSitesP: "0.644794400699913", distinctSitesP: "0.3167104111986", brLenTotal: "0.7840" },
  { geneTranscript: "NM_007357.3", parsimonySitesP: "0.221770551038844", constantSitesP: "0.655826558265583", distinctSitesP: "0.354110207768744", brLenTotal: "0.8153" },
  { geneTranscript: "NM_007361.4", parsimonySitesP: "0.240484848484848", constantSitesP: "0.617212121212121", distinctSitesP: "0.33769696969697", brLenTotal: "0.9658" },
  { geneTranscript: "NM_007366.5", parsimonySitesP: "0.233766233766234", constantSitesP: "0.602642971064024", distinctSitesP: "0.379813169286853", brLenTotal: "0.8718" },
  { geneTranscript: "NM_012071.4", parsimonySitesP: "0.121367521367521", constantSitesP: "0.762393162393162", distinctSitesP: "0.254700854700855", brLenTotal: "0.3384" },
  { geneTranscript: "NM_012086.5", parsimonySitesP: "0.13280662151994", constantSitesP: "0.744168547780286", distinctSitesP: "0.211437170805117", brLenTotal: "0.5358" },
  { geneTranscript: "NM_012087.4", parsimonySitesP: "0.226075786769428", constantSitesP: "0.649967886962107", distinctSitesP: "0.305716120745022", brLenTotal: "0.9973" },
  { geneTranscript: "NM_012105.5", parsimonySitesP: "0.228442728442728", constantSitesP: "0.664736164736165", distinctSitesP: "0.331402831402831", brLenTotal: "0.9294" },
  { geneTranscript: "NM_012106.4", parsimonySitesP: "0.184049079754601", constantSitesP: "0.658486707566462", distinctSitesP: "0.368098159509202", brLenTotal: "0.6681" },
  { geneTranscript: "NM_012116.4", parsimonySitesP: "0.322784810126582", constantSitesP: "0.530239099859353", distinctSitesP: "0.439521800281294", brLenTotal: "1.1815" },
  { geneTranscript: "NM_012112.5", parsimonySitesP: "0.202588130298974", constantSitesP: "0.632307005800982", distinctSitesP: "0.321285140562249", brLenTotal: "0.6661" },
  { geneTranscript: "NM_012131.3", parsimonySitesP: "0.345238095238095", constantSitesP: "0.523809523809524", distinctSitesP: "0.467261904761905", brLenTotal: "1.3465" },
  { geneTranscript: "NM_012135.3", parsimonySitesP: "0.272820512820513", constantSitesP: "0.576410256410256", distinctSitesP: "0.378461538461538", brLenTotal: "1.2233" },
  { geneTranscript: "NM_012140.5", parsimonySitesP: "0.239256678281069", constantSitesP: "0.679442508710801", distinctSitesP: "0.326364692218351", brLenTotal: "1.1626" },
  { geneTranscript: "NM_012153.6", parsimonySitesP: "0.17", constantSitesP: "0.706666666666667", distinctSitesP: "0.275555555555556", brLenTotal: "0.7038" },
  { geneTranscript: "NM_012162.4", parsimonySitesP: "0.285095856524428", constantSitesP: "0.536796536796537", distinctSitesP: "0.416202844774273", brLenTotal: "1.1128" },
  { geneTranscript: "NM_012179.4", parsimonySitesP: "0.235632183908046", constantSitesP: "0.622605363984674", distinctSitesP: "0.362068965517241", brLenTotal: "0.7671" },
  { geneTranscript: "NM_012190.4", parsimonySitesP: "0.276792313377679", constantSitesP: "0.619733924611973", distinctSitesP: "0.383592017738359", brLenTotal: "1.4284" },
  { geneTranscript: "NM_012217.3", parsimonySitesP: "0.424242424242424", constantSitesP: "0.447658402203857", distinctSitesP: "0.585399449035813", brLenTotal: "2.4467" },
  { geneTranscript: "NM_012237.4", parsimonySitesP: "0.233076263924593", constantSitesP: "0.657240788346187", distinctSitesP: "0.370179948586118", brLenTotal: "0.8634" },
  { geneTranscript: "NM_012241.5", parsimonySitesP: "0.240860215053763", constantSitesP: "0.6", distinctSitesP: "0.404301075268817", brLenTotal: "0.9001" },
  { geneTranscript: "NM_012242.4", parsimonySitesP: "0.204260651629073", constantSitesP: "0.670426065162907", distinctSitesP: "0.338345864661654", brLenTotal: "0.6657" },
  { geneTranscript: "NM_012253.4", parsimonySitesP: "0.32662192393736", constantSitesP: "0.503355704697987", distinctSitesP: "0.47427293064877", brLenTotal: "1.4675" },
  { geneTranscript: "NM_012254.3", parsimonySitesP: "0.318840579710145", constantSitesP: "0.519806763285024", distinctSitesP: "0.445893719806763", brLenTotal: "1.2407" },
  { geneTranscript: "NM_012256.4", parsimonySitesP: "0.242424242424242", constantSitesP: "0.614814814814815", distinctSitesP: "0.357575757575758", brLenTotal: "1.1530" },
  { geneTranscript: "NM_012259.3", parsimonySitesP: "0.164193867457962", constantSitesP: "0.737883283877349", distinctSitesP: "0.257171117705242", brLenTotal: "0.6396" },
  { geneTranscript: "NM_012275.3", parsimonySitesP: "0.251612903225806", constantSitesP: "0.634408602150538", distinctSitesP: "0.361290322580645", brLenTotal: "1.0039" },
  { geneTranscript: "NM_012293.3", parsimonySitesP: "0.24904214559387", constantSitesP: "0.627901735406806", distinctSitesP: "0.341446923597025", brLenTotal: "1.3211" },
  { geneTranscript: "NM_012307.5", parsimonySitesP: "0.203311867525299", constantSitesP: "0.684759276295615", distinctSitesP: "0.295921496473474", brLenTotal: "0.8963" },
  { geneTranscript: "NM_012331.5", parsimonySitesP: "0.234042553191489", constantSitesP: "0.618439716312057", distinctSitesP: "0.380141843971631", brLenTotal: "0.8977" },
  { geneTranscript: "NM_012341.3", parsimonySitesP: "0.235015772870662", constantSitesP: "0.647213459516299", distinctSitesP: "0.3732912723449", brLenTotal: "1.0001" },
  { geneTranscript: "NM_012381.4", parsimonySitesP: "0.198781059540553", constantSitesP: "0.660571964369433", distinctSitesP: "0.324894514767932", brLenTotal: "0.6469" },
  { geneTranscript: "NM_012383.5", parsimonySitesP: "0.118380062305296", constantSitesP: "0.802180685358255", distinctSitesP: "0.21183800623053", brLenTotal: "0.4438" },
  { geneTranscript: "NM_012385.3", parsimonySitesP: "0.24390243902439", constantSitesP: "0.565040650406504", distinctSitesP: "0.443089430894309", brLenTotal: "1.0555" },
  { geneTranscript: "NM_012413.4", parsimonySitesP: "0.242843951985226", constantSitesP: "0.626038781163435", distinctSitesP: "0.375807940904894", brLenTotal: "0.8376" },
  { geneTranscript: "NM_012416.4", parsimonySitesP: "0.111010558069382", constantSitesP: "0.70920060331825", distinctSitesP: "0.199698340874811", brLenTotal: "0.4888" },
  { geneTranscript: "NM_012417.4", parsimonySitesP: "0.127510040160643", constantSitesP: "0.776104417670683", distinctSitesP: "0.208835341365462", brLenTotal: "0.6123" },
  { geneTranscript: "NM_012431.3", parsimonySitesP: "0.196129032258065", constantSitesP: "0.688172043010753", distinctSitesP: "0.304516129032258", brLenTotal: "0.6719" },
  { geneTranscript: "NM_012434.5", parsimonySitesP: "0.206060606060606", constantSitesP: "0.684848484848485", distinctSitesP: "0.317171717171717", brLenTotal: "0.8076" },
  { geneTranscript: "NM_012445.4", parsimonySitesP: "0.267875125881168", constantSitesP: "0.624370594159114", distinctSitesP: "0.370594159113797", brLenTotal: "1.0950" },
  { geneTranscript: "NM_012449.3", parsimonySitesP: "0.224188790560472", constantSitesP: "0.633235004916421", distinctSitesP: "0.370698131760079", brLenTotal: "0.6768" },
  { geneTranscript: "NM_012454.4", parsimonySitesP: "0.243386243386243", constantSitesP: "0.629041740152851", distinctSitesP: "0.321575543797766", brLenTotal: "1.0130" },
  { geneTranscript: "NM_012459.4", parsimonySitesP: "0.176706827309237", constantSitesP: "0.698795180722892", distinctSitesP: "0.337349397590361", brLenTotal: "0.6184" },
  { geneTranscript: "NM_012463.4", parsimonySitesP: "0.218068535825545", constantSitesP: "0.680295950155763", distinctSitesP: "0.305685358255452", brLenTotal: "0.8793" },
  { geneTranscript: "NM_013233.3", parsimonySitesP: "0.1217125382263", constantSitesP: "0.78960244648318", distinctSitesP: "0.214678899082569", brLenTotal: "0.4311" },
  { geneTranscript: "NM_013236.4", parsimonySitesP: "0.227368421052632", constantSitesP: "0.650526315789474", distinctSitesP: "0.351578947368421", brLenTotal: "0.7914" },
  { geneTranscript: "NM_013243.4", parsimonySitesP: "0.178062678062678", constantSitesP: "0.708689458689459", distinctSitesP: "0.300569800569801", brLenTotal: "0.5815" },
  { geneTranscript: "NM_013254.4", parsimonySitesP: "0.128029263831733", constantSitesP: "0.763145861911294", distinctSitesP: "0.231367169638775", brLenTotal: "0.5720" },
  { geneTranscript: "NM_013258.5", parsimonySitesP: "0.341880341880342", constantSitesP: "0.523076923076923", distinctSitesP: "0.485470085470085", brLenTotal: "1.3420" },
  { geneTranscript: "NM_013264.5", parsimonySitesP: "0.182884748102139", constantSitesP: "0.676328502415459", distinctSitesP: "0.287784679089027", brLenTotal: "0.8224" },
  { geneTranscript: "NM_013284.4", parsimonySitesP: "0.295546558704453", constantSitesP: "0.541835357624831", distinctSitesP: "0.409581646423752", brLenTotal: "1.1571" },
  { geneTranscript: "NM_013285.3", parsimonySitesP: "0.188782489740082", constantSitesP: "0.658002735978112", distinctSitesP: "0.321021431828545", brLenTotal: "0.7090" },
  { geneTranscript: "NM_013299.4", parsimonySitesP: "0.311918063314711", constantSitesP: "0.544692737430168", distinctSitesP: "0.42364990689013", brLenTotal: "1.3802" },
  { geneTranscript: "NM_013314.4", parsimonySitesP: "0.190789473684211", constantSitesP: "0.676900584795322", distinctSitesP: "0.33187134502924", brLenTotal: "0.7797" },
  { geneTranscript: "NM_013325.5", parsimonySitesP: "0.217133163698049", constantSitesP: "0.689567430025445", distinctSitesP: "0.290924512298558", brLenTotal: "0.9475" },
  { geneTranscript: "NM_013330.5", parsimonySitesP: "0.164893617021277", constantSitesP: "0.718085106382979", distinctSitesP: "0.271276595744681", brLenTotal: "0.5752" },
  { geneTranscript: "NM_013336.4", parsimonySitesP: "0.254201680672269", constantSitesP: "0.693977591036415", distinctSitesP: "0.292016806722689", brLenTotal: "1.0926" },
  { geneTranscript: "NM_013339.4", parsimonySitesP: "0.145956607495069", constantSitesP: "0.719921104536489", distinctSitesP: "0.297830374753452", brLenTotal: "0.5829" },
  { geneTranscript: "NM_013349.5", parsimonySitesP: "0.236434108527132", constantSitesP: "0.596899224806202", distinctSitesP: "0.408914728682171", brLenTotal: "0.9573" },
  { geneTranscript: "NM_013356.3", parsimonySitesP: "0.262566137566138", constantSitesP: "0.572751322751323", distinctSitesP: "0.367063492063492", brLenTotal: "1.4096" },
  { geneTranscript: "NM_013358.3", parsimonySitesP: "0.315233785822021", constantSitesP: "0.553544494720965", distinctSitesP: "0.456510809451986", brLenTotal: "1.3850" },
  { geneTranscript: "NM_013367.3", parsimonySitesP: "0.132838283828383", constantSitesP: "0.775577557755776", distinctSitesP: "0.233498349834983", brLenTotal: "0.5181" },
  { geneTranscript: "NM_013373.4", parsimonySitesP: "0.227015250544662", constantSitesP: "0.644444444444444", distinctSitesP: "0.291503267973856", brLenTotal: "1.1580" },
  { geneTranscript: "NM_013378.3", parsimonySitesP: "0.268292682926829", constantSitesP: "0.563685636856369", distinctSitesP: "0.46070460704607", brLenTotal: "1.1380" },
  { geneTranscript: "NM_013379.3", parsimonySitesP: "0.317750677506775", constantSitesP: "0.546747967479675", distinctSitesP: "0.449864498644986", brLenTotal: "1.5154" },
  { geneTranscript: "NM_013393.3", parsimonySitesP: "0.268292682926829", constantSitesP: "0.558265582655827", distinctSitesP: "0.459349593495935", brLenTotal: "1.0121" },
  { geneTranscript: "NM_013397.6", parsimonySitesP: "0.297743055555556", constantSitesP: "0.499131944444444", distinctSitesP: "0.473090277777778", brLenTotal: "1.0770" },
  { geneTranscript: "NM_013402.7", parsimonySitesP: "0.225548902195609", constantSitesP: "0.648702594810379", distinctSitesP: "0.33666001330672", brLenTotal: "0.8719" },
  { geneTranscript: "NM_013410.4", parsimonySitesP: "0.198804185351271", constantSitesP: "0.645739910313901", distinctSitesP: "0.36322869955157", brLenTotal: "0.8203" },
  { geneTranscript: "NM_013427.3", parsimonySitesP: "0.221423682409309", constantSitesP: "0.669062286105407", distinctSitesP: "0.297056810403833", brLenTotal: "0.7558" },
  { geneTranscript: "NM_013432.5", parsimonySitesP: "0.312772133526851", constantSitesP: "0.532414126753749", distinctSitesP: "0.425253991291727", brLenTotal: "1.3880" },
  { geneTranscript: "NM_013435.3", parsimonySitesP: "0.207129094412331", constantSitesP: "0.682080924855491", distinctSitesP: "0.314065510597303", brLenTotal: "0.7950" },
  { geneTranscript: "NM_013441.4", parsimonySitesP: "0.250345781466113", constantSitesP: "0.648686030428769", distinctSitesP: "0.341632088520055", brLenTotal: "1.0882" },
  { geneTranscript: "NM_013964.5", parsimonySitesP: "0.1765625", constantSitesP: "0.713020833333333", distinctSitesP: "0.2515625", brLenTotal: "0.7921" },
  { geneTranscript: "NM_014016.5", parsimonySitesP: "0.120386144236229", constantSitesP: "0.780238500851789", distinctSitesP: "0.208404315729699", brLenTotal: "0.4488" },
  { geneTranscript: "NM_014018.3", parsimonySitesP: "0.285204991087344", constantSitesP: "0.563279857397504", distinctSitesP: "0.461675579322638", brLenTotal: "0.9760" },
  { geneTranscript: "NM_014023.4", parsimonySitesP: "0.230769230769231", constantSitesP: "0.699055330634278", distinctSitesP: "0.303643724696356", brLenTotal: "0.9765" },
  { geneTranscript: "NM_014047.3", parsimonySitesP: "0.326599326599327", constantSitesP: "0.481481481481481", distinctSitesP: "0.552188552188552", brLenTotal: "1.5239" },
  { geneTranscript: "NM_014049.5", parsimonySitesP: "0.250670960815888", constantSitesP: "0.616210413311863", distinctSitesP: "0.361245303274289", brLenTotal: "0.9842" },
  { geneTranscript: "NM_014056.4", parsimonySitesP: "0.240143369175627", constantSitesP: "0.555555555555556", distinctSitesP: "0.476702508960573", brLenTotal: "1.0858" },
  { geneTranscript: "NM_014057.5", parsimonySitesP: "0.130872483221477", constantSitesP: "0.749440715883669", distinctSitesP: "0.246085011185682", brLenTotal: "0.4740" },
  { geneTranscript: "NM_014059.3", parsimonySitesP: "0.160583941605839", constantSitesP: "0.744525547445255", distinctSitesP: "0.289537712895377", brLenTotal: "0.5784" },
  { geneTranscript: "NM_014067.4", parsimonySitesP: "0.243076923076923", constantSitesP: "0.628717948717949", distinctSitesP: "0.381538461538462", brLenTotal: "0.9108" },
  { geneTranscript: "NM_014078.6", parsimonySitesP: "0.181647940074906", constantSitesP: "0.719101123595506", distinctSitesP: "0.310861423220974", brLenTotal: "0.5678" },
  { geneTranscript: "NM_014161.5", parsimonySitesP: "0.231481481481481", constantSitesP: "0.594444444444444", distinctSitesP: "0.440740740740741", brLenTotal: "0.8236" },
  { geneTranscript: "NM_014172.6", parsimonySitesP: "0.232", constantSitesP: "0.656", distinctSitesP: "0.370666666666667", brLenTotal: "0.7134" },
  { geneTranscript: "NM_014174.3", parsimonySitesP: "0.219259259259259", constantSitesP: "0.644444444444444", distinctSitesP: "0.398518518518519", brLenTotal: "0.7370" },
  { geneTranscript: "NM_014180.4", parsimonySitesP: "0.202265372168285", constantSitesP: "0.650485436893204", distinctSitesP: "0.354368932038835", brLenTotal: "0.7389" },
  { geneTranscript: "NM_014186.4", parsimonySitesP: "0.198653198653199", constantSitesP: "0.654882154882155", distinctSitesP: "0.34006734006734", brLenTotal: "0.7212" },
  { geneTranscript: "NM_014205.4", parsimonySitesP: "0.354838709677419", constantSitesP: "0.466501240694789", distinctSitesP: "0.481389578163772", brLenTotal: "1.3228" },
  { geneTranscript: "NM_014209.4", parsimonySitesP: "0.348927875243665", constantSitesP: "0.461988304093567", distinctSitesP: "0.538011695906433", brLenTotal: "1.1284" },
  { geneTranscript: "NM_014210.4", parsimonySitesP: "0.216101694915254", constantSitesP: "0.590395480225989", distinctSitesP: "0.402542372881356", brLenTotal: "0.7080" },
  { geneTranscript: "NM_014220.3", parsimonySitesP: "0.227722772277228", constantSitesP: "0.63036303630363", distinctSitesP: "0.387788778877888", brLenTotal: "1.0009" },
  { geneTranscript: "NM_014241.4", parsimonySitesP: "0.175925925925926", constantSitesP: "0.69212962962963", distinctSitesP: "0.327546296296296", brLenTotal: "0.6191" },
  { geneTranscript: "NM_014243.3", parsimonySitesP: "0.249792531120332", constantSitesP: "0.636791147994467", distinctSitesP: "0.328907330567082", brLenTotal: "1.0693" },
  { geneTranscript: "NM_014248.4", parsimonySitesP: "0.0709876543209877", constantSitesP: "0.858024691358025", distinctSitesP: "0.166666666666667", brLenTotal: "0.3237" },
  { geneTranscript: "NM_014254.3", parsimonySitesP: "0.192626034612491", constantSitesP: "0.656132430398796", distinctSitesP: "0.341610233258089", brLenTotal: "0.7428" },
  { geneTranscript: "NM_014262.5", parsimonySitesP: "0.233695652173913", constantSitesP: "0.634510869565217", distinctSitesP: "0.332427536231884", brLenTotal: "0.8834" },
  { geneTranscript: "NM_014280.3", parsimonySitesP: "0.110671936758893", constantSitesP: "0.794466403162055", distinctSitesP: "0.221343873517787", brLenTotal: "0.4082" },
  { geneTranscript: "NM_014282.4", parsimonySitesP: "0.208232445520581", constantSitesP: "0.605326876513317", distinctSitesP: "0.361581920903955", brLenTotal: "0.9087" },
  { geneTranscript: "NM_014283.5", parsimonySitesP: "0.15337586390218", constantSitesP: "0.715576820839979", distinctSitesP: "0.236842105263158", brLenTotal: "0.5097" },
  { geneTranscript: "NM_014286.4", parsimonySitesP: "0.157894736842105", constantSitesP: "0.791228070175439", distinctSitesP: "0.228070175438596", brLenTotal: "0.9311" },
  { geneTranscript: "NM_014294.6", parsimonySitesP: "0.114973262032086", constantSitesP: "0.811942959001783", distinctSitesP: "0.184491978609626", brLenTotal: "0.3591" },
  { geneTranscript: "NM_014296.3", parsimonySitesP: "0.133661336613366", constantSitesP: "0.760967609676097", distinctSitesP: "0.205822058220582", brLenTotal: "0.5390" },
  { geneTranscript: "NM_014312.5", parsimonySitesP: "0.239551478083588", constantSitesP: "0.608562691131498", distinctSitesP: "0.390417940876656", brLenTotal: "0.8930" },
  { geneTranscript: "NM_014317.5", parsimonySitesP: "0.216867469879518", constantSitesP: "0.648192771084337", distinctSitesP: "0.355020080321285", brLenTotal: "0.8952" },
  { geneTranscript: "NM_014319.5", parsimonySitesP: "0.190267105744603", constantSitesP: "0.682034394438346", distinctSitesP: "0.271130625686059", brLenTotal: "0.6294" },
  { geneTranscript: "NM_014322.3", parsimonySitesP: "0.151741293532338", constantSitesP: "0.723880597014925", distinctSitesP: "0.278606965174129", brLenTotal: "0.5544" },
  { geneTranscript: "NM_014324.6", parsimonySitesP: "0.280977312390925", constantSitesP: "0.584642233856894", distinctSitesP: "0.428446771378709", brLenTotal: "1.0364" },
  { geneTranscript: "NM_014334.4", parsimonySitesP: "0.128555176336746", constantSitesP: "0.76905574516496", distinctSitesP: "0.213879408418658", brLenTotal: "0.4515" },
  { geneTranscript: "NM_014345.3", parsimonySitesP: "0.206962117887963", constantSitesP: "0.653795524352786", distinctSitesP: "0.291794646774901", brLenTotal: "0.6501" },
  { geneTranscript: "NM_014353.5", parsimonySitesP: "0.266927083333333", constantSitesP: "0.608072916666667", distinctSitesP: "0.391927083333333", brLenTotal: "1.0521" },
  { geneTranscript: "NM_014363.6", parsimonySitesP: "0.163208852005533", constantSitesP: "0.710489917740409", distinctSitesP: "0.176603334061294", brLenTotal: "0.6353" },
  { geneTranscript: "NM_014395.3", parsimonySitesP: "0.185714285714286", constantSitesP: "0.714285714285714", distinctSitesP: "0.313095238095238", brLenTotal: "0.7753" },
  { geneTranscript: "NM_014396.4", parsimonySitesP: "0.142466822794692", constantSitesP: "0.766198282591725", distinctSitesP: "0.225604996096799", brLenTotal: "0.5096" },
  { geneTranscript: "NM_014415.4", parsimonySitesP: "0.179487179487179", constantSitesP: "0.719214941437164", distinctSitesP: "0.244064577397911", brLenTotal: "0.5673" },
  { geneTranscript: "NM_014417.5", parsimonySitesP: "0.146804835924007", constantSitesP: "0.756476683937824", distinctSitesP: "0.229706390328152", brLenTotal: "0.5584" },
  { geneTranscript: "NM_014419.4", parsimonySitesP: "0.329201101928375", constantSitesP: "0.475206611570248", distinctSitesP: "0.523415977961433", brLenTotal: "1.1988" },
  { geneTranscript: "NM_014421.3", parsimonySitesP: "0.248391248391248", constantSitesP: "0.644787644787645", distinctSitesP: "0.346203346203346", brLenTotal: "0.9817" },
  { geneTranscript: "NM_014478.5", parsimonySitesP: "0.204954954954955", constantSitesP: "0.675675675675676", distinctSitesP: "0.351351351351351", brLenTotal: "0.8197" },
  { geneTranscript: "NM_014496.5", parsimonySitesP: "0.127069351230425", constantSitesP: "0.750335570469799", distinctSitesP: "0.214317673378076", brLenTotal: "0.4548" },
  { geneTranscript: "NM_014503.3", parsimonySitesP: "0.19377618192699", constantSitesP: "0.668821065230401", distinctSitesP: "0.286175942549372", brLenTotal: "0.7278" },
  { geneTranscript: "NM_014520.4", parsimonySitesP: "0.34136546184739", constantSitesP: "0.473895582329317", distinctSitesP: "0.469879518072289", brLenTotal: "1.4448" },
  { geneTranscript: "NM_014568.3", parsimonySitesP: "0.25", constantSitesP: "0.581914893617021", distinctSitesP: "0.40709219858156", brLenTotal: "0.8348" },
  { geneTranscript: "NM_014572.3", parsimonySitesP: "0.275122549019608", constantSitesP: "0.602022058823529", distinctSitesP: "0.353860294117647", brLenTotal: "1.2743" },
  { geneTranscript: "NM_014573.3", parsimonySitesP: "0.261363636363636", constantSitesP: "0.53219696969697", distinctSitesP: "0.477272727272727", brLenTotal: "0.9896" },
  { geneTranscript: "NM_014588.6", parsimonySitesP: "0.308675799086758", constantSitesP: "0.523287671232877", distinctSitesP: "0.476712328767123", brLenTotal: "1.2506" },
  { geneTranscript: "NM_014602.3", parsimonySitesP: "0.159057437407953", constantSitesP: "0.734413352970054", distinctSitesP: "0.211340206185567", brLenTotal: "0.6041" },
  { geneTranscript: "NM_014611.3", parsimonySitesP: "0.228436978794377", constantSitesP: "0.620979270907791", distinctSitesP: "0.286454610436026", brLenTotal: "0.8022" },
  { geneTranscript: "NM_014616.3", parsimonySitesP: "0.131690739167375", constantSitesP: "0.767204757858963", distinctSitesP: "0.194562446898895", brLenTotal: "0.4554" },
  { geneTranscript: "NM_014625.4", parsimonySitesP: "0.231505657093124", constantSitesP: "0.618798955613577", distinctSitesP: "0.359442993907746", brLenTotal: "0.8334" },
  { geneTranscript: "NM_014630.3", parsimonySitesP: "0.198368850302552", constantSitesP: "0.67219152854512", distinctSitesP: "0.257300710339384", brLenTotal: "0.7352" },
  { geneTranscript: "NM_014637.4", parsimonySitesP: "0.223223223223223", constantSitesP: "0.58958958958959", distinctSitesP: "0.395395395395395", brLenTotal: "0.8755" },
  { geneTranscript: "NM_014639.4", parsimonySitesP: "0.182225063938619", constantSitesP: "0.675831202046036", distinctSitesP: "0.275575447570332", brLenTotal: "0.6423" },
  { geneTranscript: "NM_014648.4", parsimonySitesP: "0.156456953642384", constantSitesP: "0.725993377483444", distinctSitesP: "0.25", brLenTotal: "0.4978" },
  { geneTranscript: "NM_014649.3", parsimonySitesP: "0.205666316894019", constantSitesP: "0.683105981112277", distinctSitesP: "0.314095837705491", brLenTotal: "0.8963" },
  { geneTranscript: "NM_014671.3", parsimonySitesP: "0.184056632810095", constantSitesP: "0.726069559864574", distinctSitesP: "0.265312403816559", brLenTotal: "0.8313" },
  { geneTranscript: "NM_014679.5", parsimonySitesP: "0.189333333333333", constantSitesP: "0.662666666666667", distinctSitesP: "0.325333333333333", brLenTotal: "0.6538" },
  { geneTranscript: "NM_014717.3", parsimonySitesP: "0.238717948717949", constantSitesP: "0.643846153846154", distinctSitesP: "0.320769230769231", brLenTotal: "1.0837" },
  { geneTranscript: "NM_014736.6", parsimonySitesP: "0.168168168168168", constantSitesP: "0.72972972972973", distinctSitesP: "0.315315315315315", brLenTotal: "0.5438" },
  { geneTranscript: "NM_014739.3", parsimonySitesP: "0.0793478260869565", constantSitesP: "0.822826086956522", distinctSitesP: "0.153985507246377", brLenTotal: "0.2736" },
  { geneTranscript: "NM_014753.4", parsimonySitesP: "0.265730629225169", constantSitesP: "0.535621424856994", distinctSitesP: "0.40665626625065", brLenTotal: "1.1035" },
  { geneTranscript: "NM_014757.5", parsimonySitesP: "0.244750656167979", constantSitesP: "0.573490813648294", distinctSitesP: "0.3500656167979", brLenTotal: "1.1018" },
  { geneTranscript: "NM_014758.3", parsimonySitesP: "0.272849462365591", constantSitesP: "0.551411290322581", distinctSitesP: "0.398521505376344", brLenTotal: "0.9769" },
  { geneTranscript: "NM_014760.4", parsimonySitesP: "0.257993867717915", constantSitesP: "0.593517301795883", distinctSitesP: "0.358300481822164", brLenTotal: "0.8606" },
  { geneTranscript: "NM_014763.4", parsimonySitesP: "0.286529680365297", constantSitesP: "0.574200913242009", distinctSitesP: "0.420091324200913", brLenTotal: "0.9731" },
  { geneTranscript: "NM_014765.3", parsimonySitesP: "0.2", constantSitesP: "0.664367816091954", distinctSitesP: "0.35632183908046", brLenTotal: "0.6732" },
  { geneTranscript: "NM_014772.3", parsimonySitesP: "0.16778149386845", constantSitesP: "0.715719063545151", distinctSitesP: "0.234671125975474", brLenTotal: "0.9193" },
  { geneTranscript: "NM_014773.5", parsimonySitesP: "0.27378640776699", constantSitesP: "0.535275080906149", distinctSitesP: "0.433656957928803", brLenTotal: "1.0042" },
  { geneTranscript: "NM_014788.4", parsimonySitesP: "0.271493212669683", constantSitesP: "0.576168929110106", distinctSitesP: "0.429110105580694", brLenTotal: "1.0110" },
  { geneTranscript: "NM_014791.4", parsimonySitesP: "0.172043010752688", constantSitesP: "0.698412698412698", distinctSitesP: "0.298003072196621", brLenTotal: "0.5389" },
  { geneTranscript: "NM_014798.3", parsimonySitesP: "0.232954545454545", constantSitesP: "0.587436868686869", distinctSitesP: "0.351957070707071", brLenTotal: "1.0202" },
  { geneTranscript: "NM_014801.4", parsimonySitesP: "0.255966307908283", constantSitesP: "0.613944782405241", distinctSitesP: "0.354858836374981", brLenTotal: "1.1211" },
  { geneTranscript: "NM_014810.5", parsimonySitesP: "0.20660891883221", constantSitesP: "0.643139771147471", distinctSitesP: "0.282964388835419", brLenTotal: "0.6553" },
  { geneTranscript: "NM_014812.3", parsimonySitesP: "0.147306397306397", constantSitesP: "0.751262626262626", distinctSitesP: "0.207281144781145", brLenTotal: "0.4497" },
  { geneTranscript: "NM_014822.4", parsimonySitesP: "0.223191214470284", constantSitesP: "0.656653746770026", distinctSitesP: "0.328811369509044", brLenTotal: "0.9035" },
  { geneTranscript: "NM_014825.3", parsimonySitesP: "0.28063995303097", constantSitesP: "0.558491119917804", distinctSitesP: "0.391310729487744", brLenTotal: "1.1444" },
  { geneTranscript: "NM_014836.5", parsimonySitesP: "0.186302681992337", constantSitesP: "0.706896551724138", distinctSitesP: "0.269157088122605", brLenTotal: "0.7236" },
  { geneTranscript: "NM_014844.5", parsimonySitesP: "0.226553271911174", constantSitesP: "0.645641389085755", distinctSitesP: "0.310181904086936", brLenTotal: "0.9632" },
  { geneTranscript: "NM_014846.4", parsimonySitesP: "0.148116192119643", constantSitesP: "0.741731377624389", distinctSitesP: "0.205637043428243", brLenTotal: "0.5993" },
  { geneTranscript: "NM_014860.3", parsimonySitesP: "0.142512077294686", constantSitesP: "0.743156199677939", distinctSitesP: "0.219001610305958", brLenTotal: "0.4800" },
  { geneTranscript: "NM_014870.4", parsimonySitesP: "0.197202044659672", constantSitesP: "0.656443368307775", distinctSitesP: "0.319343556631692", brLenTotal: "0.7884" },
  { geneTranscript: "NM_014879.4", parsimonySitesP: "0.249506903353057", constantSitesP: "0.610453648915187", distinctSitesP: "0.421104536489152", brLenTotal: "0.9566" },
  { geneTranscript: "NM_014880.5", parsimonySitesP: "0.19683908045977", constantSitesP: "0.665229885057471", distinctSitesP: "0.375", brLenTotal: "0.6370" },
  { geneTranscript: "NM_014899.4", parsimonySitesP: "0.158210583742499", constantSitesP: "0.763229678123295", distinctSitesP: "0.22367703218767", brLenTotal: "0.6277" },
  { geneTranscript: "NM_014904.3", parsimonySitesP: "0.108723958333333", constantSitesP: "0.778645833333333", distinctSitesP: "0.194010416666667", brLenTotal: "0.3692" },
  { geneTranscript: "NM_014907.3", parsimonySitesP: "0.237431347697507", constantSitesP: "0.600760456273764", distinctSitesP: "0.366075200675961", brLenTotal: "0.8563" },
  { geneTranscript: "NM_014931.4", parsimonySitesP: "0.22360953461975", constantSitesP: "0.645478622777147", distinctSitesP: "0.320469163828982", brLenTotal: "1.0292" },
  { geneTranscript: "NM_014946.4", parsimonySitesP: "0.125541125541126", constantSitesP: "0.777056277056277", distinctSitesP: "0.199675324675325", brLenTotal: "0.3949" },
  { geneTranscript: "NM_014976.2", parsimonySitesP: "0.233030464991983", constantSitesP: "0.612150365223588", distinctSitesP: "0.30304649919829", brLenTotal: "0.9575" },
  { geneTranscript: "NM_014989.7", parsimonySitesP: "0.14558707643814", constantSitesP: "0.744286840031521", distinctSitesP: "0.186564223798266", brLenTotal: "0.5579" },
  { geneTranscript: "NM_014996.4", parsimonySitesP: "0.194658753709199", constantSitesP: "0.662512363996044", distinctSitesP: "0.293570722057369", brLenTotal: "0.7524" },
  { geneTranscript: "NM_015018.4", parsimonySitesP: "0.141446923597025", constantSitesP: "0.750236646382691", distinctSitesP: "0.182285327924273", brLenTotal: "0.5416" },
  { geneTranscript: "NM_015030.2", parsimonySitesP: "0.195375594645425", constantSitesP: "0.711361876313752", distinctSitesP: "0.255780506693218", brLenTotal: "0.8853" },
  { geneTranscript: "NM_015033.3", parsimonySitesP: "0.165316045380875", constantSitesP: "0.746623446785521", distinctSitesP: "0.249054565099946", brLenTotal: "0.7223" },
  { geneTranscript: "NM_015040.4", parsimonySitesP: "0.13504925325707", constantSitesP: "0.755163647918653", distinctSitesP: "0.188751191611058", brLenTotal: "0.4763" },
  { geneTranscript: "NM_015045.5", parsimonySitesP: "0.116806722689076", constantSitesP: "0.737535014005602", distinctSitesP: "0.223809523809524", brLenTotal: "0.4586" },
  { geneTranscript: "NM_015052.5", parsimonySitesP: "0.188044831880448", constantSitesP: "0.709215442092154", distinctSitesP: "0.250311332503113", brLenTotal: "0.7366" },
  { geneTranscript: "NM_015061.6", parsimonySitesP: "0.176767676767677", constantSitesP: "0.687815656565657", distinctSitesP: "0.272411616161616", brLenTotal: "0.7447" },
  { geneTranscript: "NM_015062.5", parsimonySitesP: "0.227163461538462", constantSitesP: "0.608974358974359", distinctSitesP: "0.287259615384615", brLenTotal: "0.8800" },
  { geneTranscript: "NM_015086.2", parsimonySitesP: "0.248007501172058", constantSitesP: "0.575246132208158", distinctSitesP: "0.352555086732302", brLenTotal: "0.8333" },
  { geneTranscript: "NM_015100.4", parsimonySitesP: "0.146099290780142", constantSitesP: "0.758156028368794", distinctSitesP: "0.186524822695035", brLenTotal: "0.4686" },
  { geneTranscript: "NM_015102.5", parsimonySitesP: "0.304581580177653", constantSitesP: "0.553295932678822", distinctSitesP: "0.443899018232819", brLenTotal: "1.3723" },
  { geneTranscript: "NM_015103.3", parsimonySitesP: "0.21991341991342", constantSitesP: "0.668744588744589", distinctSitesP: "0.266493506493506", brLenTotal: "0.9330" },
  { geneTranscript: "NM_015117.3", parsimonySitesP: "0.313291139240506", constantSitesP: "0.516877637130802", distinctSitesP: "0.418776371308017", brLenTotal: "1.3230" },
  { geneTranscript: "NM_015131.3", parsimonySitesP: "0.145248645987198", constantSitesP: "0.721319547021172", distinctSitesP: "0.262432299359921", brLenTotal: "0.6296" },
  { geneTranscript: "NM_015139.3", parsimonySitesP: "0.125821596244131", constantSitesP: "0.769014084507042", distinctSitesP: "0.229107981220657", brLenTotal: "0.4233" },
  { geneTranscript: "NM_015141.4", parsimonySitesP: "0.193732193732194", constantSitesP: "0.712250712250712", distinctSitesP: "0.283000949667616", brLenTotal: "0.8269" },
  { geneTranscript: "NM_015147.3", parsimonySitesP: "0.314839277851167", constantSitesP: "0.493615147512109", distinctSitesP: "0.462351387054161", brLenTotal: "1.1594" },
  { geneTranscript: "NM_015154.3", parsimonySitesP: "0.272079772079772", constantSitesP: "0.561253561253561", distinctSitesP: "0.464387464387464", brLenTotal: "1.0325" },
  { geneTranscript: "NM_015156.4", parsimonySitesP: "0.154639175257732", constantSitesP: "0.764948453608247", distinctSitesP: "0.251546391752577", brLenTotal: "0.5306" },
  { geneTranscript: "NM_015158.5", parsimonySitesP: "0.228303747534517", constantSitesP: "0.630917159763314", distinctSitesP: "0.306952662721893", brLenTotal: "0.8584" },
  { geneTranscript: "NM_015175.3", parsimonySitesP: "0.231663035584604", constantSitesP: "0.631808278867102", distinctSitesP: "0.305979181796175", brLenTotal: "0.9020" },
  { geneTranscript: "NM_015188.2", parsimonySitesP: "0.208172043010753", constantSitesP: "0.663225806451613", distinctSitesP: "0.30494623655914", brLenTotal: "0.6020" },
  { geneTranscript: "NM_015190.5", parsimonySitesP: "0.203846153846154", constantSitesP: "0.683333333333333", distinctSitesP: "0.319230769230769", brLenTotal: "0.5728" },
  { geneTranscript: "NM_015191.3", parsimonySitesP: "0.173506119510439", constantSitesP: "0.73110151187905", distinctSitesP: "0.242980561555076", brLenTotal: "0.6343" },
  { geneTranscript: "NM_015202.5", parsimonySitesP: "0.297898640296663", constantSitesP: "0.53955500618047", distinctSitesP: "0.419653893695921", brLenTotal: "1.2083" },
  { geneTranscript: "NM_015214.3", parsimonySitesP: "0.145335208626348", constantSitesP: "0.73652133145804", distinctSitesP: "0.229723394280356", brLenTotal: "0.5858" },
  { geneTranscript: "NM_015258.2", parsimonySitesP: "0.219305441618813", constantSitesP: "0.621547716707684", distinctSitesP: "0.331145747880777", brLenTotal: "0.7736" },
  { geneTranscript: "NM_015278.5", parsimonySitesP: "0.219192729216787", constantSitesP: "0.662389735364876", distinctSitesP: "0.310612135792569", brLenTotal: "0.8850" },
  { geneTranscript: "NM_015279.2", parsimonySitesP: "0.19053876478318", constantSitesP: "0.693385895751205", distinctSitesP: "0.290845378887429", brLenTotal: "0.9687" },
  { geneTranscript: "NM_015291.4", parsimonySitesP: "0.191389599317988", constantSitesP: "0.679454390451833", distinctSitesP: "0.288576300085251", brLenTotal: "0.7271" },
  { geneTranscript: "NM_015310.4", parsimonySitesP: "0.228589621139764", constantSitesP: "0.637058261700096", distinctSitesP: "0.335243553008596", brLenTotal: "0.8259" },
  { geneTranscript: "NM_015313.3", parsimonySitesP: "0.139680483592401", constantSitesP: "0.721718480138169", distinctSitesP: "0.218696027633851", brLenTotal: "0.5279" },
  { geneTranscript: "NM_015324.4", parsimonySitesP: "0.277777777777778", constantSitesP: "0.557748538011696", distinctSitesP: "0.423976608187135", brLenTotal: "0.8732" },
  { geneTranscript: "NM_015330.6", parsimonySitesP: "0.176365264100269", constantSitesP: "0.720680393912265", distinctSitesP: "0.236048940614742", brLenTotal: "0.6211" },
  { geneTranscript: "NM_015332.4", parsimonySitesP: "0.169898430286242", constantSitesP: "0.704524469067405", distinctSitesP: "0.279778393351801", brLenTotal: "0.6516" },
  { geneTranscript: "NM_015338.6", parsimonySitesP: "0.225827384815055", constantSitesP: "0.621457927752542", distinctSitesP: "0.326411421155094", brLenTotal: "0.8208" },
  { geneTranscript: "NM_015340.4", parsimonySitesP: "0.205610926541159", constantSitesP: "0.646363971945367", distinctSitesP: "0.30749354005168", brLenTotal: "0.9039" },
  { geneTranscript: "NM_015341.5", parsimonySitesP: "0.218173639226271", constantSitesP: "0.636077372919478", distinctSitesP: "0.367071524966262", brLenTotal: "0.8424" },
  { geneTranscript: "NM_015368.4", parsimonySitesP: "0.258998435054773", constantSitesP: "0.618153364632238", distinctSitesP: "0.36697965571205", brLenTotal: "1.0704" },
  { geneTranscript: "NM_015370.2", parsimonySitesP: "0.329885057471264", constantSitesP: "0.452873563218391", distinctSitesP: "0.564367816091954", brLenTotal: "1.2752" },
  { geneTranscript: "NM_015380.5", parsimonySitesP: "0.174840085287846", constantSitesP: "0.72636815920398", distinctSitesP: "0.276474769012082", brLenTotal: "0.7726" },
  { geneTranscript: "NM_015392.4", parsimonySitesP: "0.303589743589744", constantSitesP: "0.542564102564103", distinctSitesP: "0.472820512820513", brLenTotal: "1.4931" },
  { geneTranscript: "NM_015411.4", parsimonySitesP: "0.224806201550388", constantSitesP: "0.641196013289037", distinctSitesP: "0.347729789590255", brLenTotal: "0.8579" },
  { geneTranscript: "NM_015436.4", parsimonySitesP: "0.168582375478927", constantSitesP: "0.735632183908046", distinctSitesP: "0.282247765006386", brLenTotal: "0.5987" },
  { geneTranscript: "NM_015443.4", parsimonySitesP: "0.136651583710407", constantSitesP: "0.714328808446456", distinctSitesP: "0.210859728506787", brLenTotal: "0.5320" },
  { geneTranscript: "NM_015450.3", parsimonySitesP: "0.187171398527865", constantSitesP: "0.680862250262881", distinctSitesP: "0.324395373291272", brLenTotal: "0.6548" },
  { geneTranscript: "NM_015444.3", parsimonySitesP: "0.148888888888889", constantSitesP: "0.744444444444444", distinctSitesP: "0.34", brLenTotal: "0.5997" },
  { geneTranscript: "NM_015465.5", parsimonySitesP: "0.209549071618037", constantSitesP: "0.635057471264368", distinctSitesP: "0.307913351016799", brLenTotal: "0.7608" },
  { geneTranscript: "NM_015471.4", parsimonySitesP: "0.238434163701068", constantSitesP: "0.608540925266904", distinctSitesP: "0.409252669039146", brLenTotal: "0.7867" },
  { geneTranscript: "NM_015490.4", parsimonySitesP: "0.237772123268306", constantSitesP: "0.538874752615211", distinctSitesP: "0.38676844783715", brLenTotal: "0.9744" },
  { geneTranscript: "NM_015508.5", parsimonySitesP: "0.128361237950279", constantSitesP: "0.777270421106038", distinctSitesP: "0.192795535261289", brLenTotal: "0.3821" },
  { geneTranscript: "NM_015515.5", parsimonySitesP: "0.253554502369668", constantSitesP: "0.578988941548183", distinctSitesP: "0.400473933649289", brLenTotal: "0.9623" },
  { geneTranscript: "NM_015525.4", parsimonySitesP: "0.174180832717418", constantSitesP: "0.673072185267307", distinctSitesP: "0.292682926829268", brLenTotal: "0.6056" },
  { geneTranscript: "NM_015532.5", parsimonySitesP: "0.268115942028986", constantSitesP: "0.548007246376812", distinctSitesP: "0.45018115942029", brLenTotal: "0.8941" },
  { geneTranscript: "NM_015554.3", parsimonySitesP: "0.122636412749865", constantSitesP: "0.764451647757969", distinctSitesP: "0.20097244732577", brLenTotal: "0.6256" },
  { geneTranscript: "NM_015571.4", parsimonySitesP: "0.173561151079137", constantSitesP: "0.701438848920863", distinctSitesP: "0.288669064748201", brLenTotal: "0.5612" },
  { geneTranscript: "NM_015603.3", parsimonySitesP: "0.276208411801632", constantSitesP: "0.604519774011299", distinctSitesP: "0.397991211550534", brLenTotal: "1.1399" },
  { geneTranscript: "NM_015604.4", parsimonySitesP: "0.226936026936027", constantSitesP: "0.620875420875421", distinctSitesP: "0.346127946127946", brLenTotal: "1.0151" },
  { geneTranscript: "NM_015613.3", parsimonySitesP: "0.28143392188336", constantSitesP: "0.563402889245586", distinctSitesP: "0.392188336008561", brLenTotal: "1.1248" },
  { geneTranscript: "NM_015621.3", parsimonySitesP: "0.231981981981982", constantSitesP: "0.593468468468468", distinctSitesP: "0.414414414414414", brLenTotal: "0.9529" },
  { geneTranscript: "NM_015653.5", parsimonySitesP: "0.275741710296684", constantSitesP: "0.56544502617801", distinctSitesP: "0.43630017452007", brLenTotal: "1.0355" },
  { geneTranscript: "NM_015656.2", parsimonySitesP: "0.362557562876373", constantSitesP: "0.487601842012044", distinctSitesP: "0.456075097414098", brLenTotal: "1.9655" },
  { geneTranscript: "NM_015679.3", parsimonySitesP: "0.283987915407855", constantSitesP: "0.555891238670695", distinctSitesP: "0.446122860020141", brLenTotal: "1.0490" },
  { geneTranscript: "NM_015683.2", parsimonySitesP: "0.280917280917281", constantSitesP: "0.583947583947584", distinctSitesP: "0.3996723996724", brLenTotal: "1.3577" },
  { geneTranscript: "NM_015688.2", parsimonySitesP: "0.244654088050314", constantSitesP: "0.585534591194969", distinctSitesP: "0.389308176100629", brLenTotal: "1.0625" },
  { geneTranscript: "NM_015689.5", parsimonySitesP: "0.218698381235547", constantSitesP: "0.645523620746614", distinctSitesP: "0.292368681863231", brLenTotal: "0.7762" },
  { geneTranscript: "NM_015693.4", parsimonySitesP: "0.197806086341118", constantSitesP: "0.639419674451522", distinctSitesP: "0.323071479122435", brLenTotal: "0.7234" },
  { geneTranscript: "NM_015703.5", parsimonySitesP: "0.275", constantSitesP: "0.555952380952381", distinctSitesP: "0.45", brLenTotal: "1.1169" },
  { geneTranscript: "NM_015715.5", parsimonySitesP: "0.326784544859201", constantSitesP: "0.472822527832351", distinctSitesP: "0.511460379829731", brLenTotal: "1.2082" },
  { geneTranscript: "NM_015718.3", parsimonySitesP: "0.23650234741784", constantSitesP: "0.624413145539906", distinctSitesP: "0.377934272300469", brLenTotal: "0.9241" },
  { geneTranscript: "NM_015719.4", parsimonySitesP: "0.265520534861509", constantSitesP: "0.606685768863419", distinctSitesP: "0.354918815663801", brLenTotal: "1.1066" },
  { geneTranscript: "NM_015849.3", parsimonySitesP: "0.364312267657993", constantSitesP: "0.499380421313507", distinctSitesP: "0.519206939281289", brLenTotal: "1.7182" },
  { geneTranscript: "NM_015865.7", parsimonySitesP: "0.284490145672665", constantSitesP: "0.567266495287061", distinctSitesP: "0.425878320479863", brLenTotal: "1.1602" },
  { geneTranscript: "NM_015888.6", parsimonySitesP: "0.149267399267399", constantSitesP: "0.74496336996337", distinctSitesP: "0.257326007326007", brLenTotal: "0.5080" },
  { geneTranscript: "NM_015898.4", parsimonySitesP: "0.195205479452055", constantSitesP: "0.706050228310502", distinctSitesP: "0.256849315068493", brLenTotal: "0.9025" },
  { geneTranscript: "NM_015910.7", parsimonySitesP: "0.17426273458445", constantSitesP: "0.686773905272565", distinctSitesP: "0.290437890974084", brLenTotal: "0.5451" },
  { geneTranscript: "NM_015917.3", parsimonySitesP: "0.235988200589971", constantSitesP: "0.631268436578171", distinctSitesP: "0.384955752212389", brLenTotal: "0.9349" },
  { geneTranscript: "NM_015918.4", parsimonySitesP: "0.198364008179959", constantSitesP: "0.67280163599182", distinctSitesP: "0.331288343558282", brLenTotal: "0.6190" },
  { geneTranscript: "NM_015933.6", parsimonySitesP: "0.130208333333333", constantSitesP: "0.765625", distinctSitesP: "0.270833333333333", brLenTotal: "0.4049" },
  { geneTranscript: "NM_015941.4", parsimonySitesP: "0.134575569358178", constantSitesP: "0.795031055900621", distinctSitesP: "0.196687370600414", brLenTotal: "0.4788" },
  { geneTranscript: "NM_015949.3", parsimonySitesP: "0.211009174311927", constantSitesP: "0.709480122324159", distinctSitesP: "0.296636085626911", brLenTotal: "0.9511" },
  { geneTranscript: "NM_015956.3", parsimonySitesP: "0.310825294748124", constantSitesP: "0.558413719185423", distinctSitesP: "0.439442658092176", brLenTotal: "1.4138" },
  { geneTranscript: "NM_015958.3", parsimonySitesP: "0.191812865497076", constantSitesP: "0.673684210526316", distinctSitesP: "0.311111111111111", brLenTotal: "0.7083" },
  { geneTranscript: "NM_015962.5", parsimonySitesP: "0.141414141414141", constantSitesP: "0.722222222222222", distinctSitesP: "0.304713804713805", brLenTotal: "0.5090" },
  { geneTranscript: "NM_015971.4", parsimonySitesP: "0.293388429752066", constantSitesP: "0.541322314049587", distinctSitesP: "0.462809917355372", brLenTotal: "0.9275" },
  { geneTranscript: "NM_015978.3", parsimonySitesP: "0.160479041916168", constantSitesP: "0.706986027944112", distinctSitesP: "0.261077844311377", brLenTotal: "0.6059" },
  { geneTranscript: "NM_015991.4", parsimonySitesP: "0.329251700680272", constantSitesP: "0.545578231292517", distinctSitesP: "0.450340136054422", brLenTotal: "1.5366" },
  { geneTranscript: "NM_015993.3", parsimonySitesP: "0.228937728937729", constantSitesP: "0.644688644688645", distinctSitesP: "0.347985347985348", brLenTotal: "0.9423" },
  { geneTranscript: "NM_016002.3", parsimonySitesP: "0.198912198912199", constantSitesP: "0.682206682206682", distinctSitesP: "0.327117327117327", brLenTotal: "0.7045" },
  { geneTranscript: "NM_016008.4", parsimonySitesP: "0.162393162393162", constantSitesP: "0.718898385565052", distinctSitesP: "0.299145299145299", brLenTotal: "0.5071" },
  { geneTranscript: "NM_016020.4", parsimonySitesP: "0.236994219653179", constantSitesP: "0.64354527938343", distinctSitesP: "0.380539499036609", brLenTotal: "0.9216" },
  { geneTranscript: "NM_016024.4", parsimonySitesP: "0.302277432712215", constantSitesP: "0.466873706004141", distinctSitesP: "0.548654244306418", brLenTotal: "1.3015" },
  { geneTranscript: "NM_016027.3", parsimonySitesP: "0.163194444444444", constantSitesP: "0.71875", distinctSitesP: "0.296296296296296", brLenTotal: "0.5826" },
  { geneTranscript: "NM_016033.3", parsimonySitesP: "0.193205944798301", constantSitesP: "0.64968152866242", distinctSitesP: "0.351380042462845", brLenTotal: "0.6990" },
  { geneTranscript: "NM_016035.5", parsimonySitesP: "0.305660377358491", constantSitesP: "0.533333333333333", distinctSitesP: "0.438993710691824", brLenTotal: "1.1213" },
  { geneTranscript: "NM_016042.4", parsimonySitesP: "0.180606060606061", constantSitesP: "0.688484848484848", distinctSitesP: "0.312727272727273", brLenTotal: "0.6737" },
  { geneTranscript: "NM_016045.3", parsimonySitesP: "0.15979381443299", constantSitesP: "0.692439862542955", distinctSitesP: "0.312714776632302", brLenTotal: "0.6468" },
  { geneTranscript: "NM_016055.6", parsimonySitesP: "0.194968553459119", constantSitesP: "0.680817610062893", distinctSitesP: "0.320754716981132", brLenTotal: "0.6153" },
  { geneTranscript: "NM_016058.5", parsimonySitesP: "0.161904761904762", constantSitesP: "0.681904761904762", distinctSitesP: "0.344761904761905", brLenTotal: "0.6504" },
  { geneTranscript: "NM_016063.3", parsimonySitesP: "0.214052287581699", constantSitesP: "0.660130718954248", distinctSitesP: "0.354575163398693", brLenTotal: "0.7612" },
  { geneTranscript: "NM_016083.6", parsimonySitesP: "0.182203389830508", constantSitesP: "0.708333333333333", distinctSitesP: "0.219632768361582", brLenTotal: "0.9826" },
  { geneTranscript: "NM_016095.3", parsimonySitesP: "0.227027027027027", constantSitesP: "0.646846846846847", distinctSitesP: "0.363963963963964", brLenTotal: "0.8599" },
  { geneTranscript: "NM_016097.5", parsimonySitesP: "0.138211382113821", constantSitesP: "0.752032520325203", distinctSitesP: "0.276422764227642", brLenTotal: "0.5073" },
  { geneTranscript: "NM_016112.3", parsimonySitesP: "0.226915113871636", constantSitesP: "0.617805383022774", distinctSitesP: "0.336645962732919", brLenTotal: "0.8960" },
  { geneTranscript: "NM_016115.5", parsimonySitesP: "0.198198198198198", constantSitesP: "0.678893178893179", distinctSitesP: "0.317245817245817", brLenTotal: "0.6017" },
  { geneTranscript: "NM_016116.3", parsimonySitesP: "0.18075117370892", constantSitesP: "0.708920187793427", distinctSitesP: "0.257433489827856", brLenTotal: "0.7362" },
  { geneTranscript: "NM_016123.4", parsimonySitesP: "0.197826086956522", constantSitesP: "0.68695652173913", distinctSitesP: "0.294927536231884", brLenTotal: "0.6050" },
  { geneTranscript: "NM_016125.4", parsimonySitesP: "0.177011494252874", constantSitesP: "0.684291187739464", distinctSitesP: "0.304214559386973", brLenTotal: "0.5247" },
  { geneTranscript: "NM_016128.4", parsimonySitesP: "0.24790236460717", constantSitesP: "0.657513348588863", distinctSitesP: "0.323417238749047", brLenTotal: "1.0896" },
  { geneTranscript: "NM_016134.4", parsimonySitesP: "0.251412429378531", constantSitesP: "0.605932203389831", distinctSitesP: "0.392655367231638", brLenTotal: "0.8165" },
  { geneTranscript: "NM_016138.5", parsimonySitesP: "0.230414746543779", constantSitesP: "0.640552995391705", distinctSitesP: "0.362519201228879", brLenTotal: "1.0755" },
  { geneTranscript: "NM_016144.4", parsimonySitesP: "0.163366336633663", constantSitesP: "0.678217821782178", distinctSitesP: "0.333333333333333", brLenTotal: "0.6332" },
  { geneTranscript: "NM_016148.5", parsimonySitesP: "0.177232762609903", constantSitesP: "0.732685485114916", distinctSitesP: "0.21918864723122", brLenTotal: "0.7424" },
  { geneTranscript: "NM_016156.6", parsimonySitesP: "0.141524105754277", constantSitesP: "0.736651114567133", distinctSitesP: "0.211508553654743", brLenTotal: "0.6497" },
  { geneTranscript: "NM_016166.3", parsimonySitesP: "0.106502816180236", constantSitesP: "0.776753712237583", distinctSitesP: "0.186891961085509", brLenTotal: "0.5010" },
  { geneTranscript: "NM_016172.3", parsimonySitesP: "0.225514403292181", constantSitesP: "0.653497942386831", distinctSitesP: "0.354732510288066", brLenTotal: "1.1157" },
  { geneTranscript: "NM_016179.4", parsimonySitesP: "0.22790856363016", constantSitesP: "0.682702149437052", distinctSitesP: "0.255203002388263", brLenTotal: "0.8019" },
  { geneTranscript: "NM_016180.5", parsimonySitesP: "0.252201257861635", constantSitesP: "0.622641509433962", distinctSitesP: "0.359119496855346", brLenTotal: "0.8910" },
  { geneTranscript: "NM_016196.4", parsimonySitesP: "0.264930555555556", constantSitesP: "0.583333333333333", distinctSitesP: "0.391319444444444", brLenTotal: "1.2020" },
  { geneTranscript: "NM_016207.4", parsimonySitesP: "0.124269005847953", constantSitesP: "0.791910331384016", distinctSitesP: "0.191520467836257", brLenTotal: "0.5043" },
  { geneTranscript: "NM_016215.5", parsimonySitesP: "0.296703296703297", constantSitesP: "0.549450549450549", distinctSitesP: "0.446886446886447", brLenTotal: "1.4074" },
  { geneTranscript: "NM_016216.4", parsimonySitesP: "0.178308823529412", constantSitesP: "0.677083333333333", distinctSitesP: "0.290441176470588", brLenTotal: "0.6074" },
  { geneTranscript: "NM_016239.4", parsimonySitesP: "0.256751652502361", constantSitesP: "0.581397544853636", distinctSitesP: "0.33833805476865", brLenTotal: "1.0780" },
  { geneTranscript: "NM_016246.3", parsimonySitesP: "0.307407407407407", constantSitesP: "0.569135802469136", distinctSitesP: "0.450617283950617", brLenTotal: "1.1920" },
  { geneTranscript: "NM_016255.3", parsimonySitesP: "0.257465698143664", constantSitesP: "0.598062953995157", distinctSitesP: "0.380145278450363", brLenTotal: "0.8760" },
  { geneTranscript: "NM_016274.6", parsimonySitesP: "0.181744091279544", constantSitesP: "0.690301548492258", distinctSitesP: "0.28280358598207", brLenTotal: "0.6827" },
  { geneTranscript: "NM_016292.3", parsimonySitesP: "0.248579545454545", constantSitesP: "0.613162878787879", distinctSitesP: "0.363636363636364", brLenTotal: "1.1215" },
  { geneTranscript: "NM_016286.4", parsimonySitesP: "0.263661202185792", constantSitesP: "0.58879781420765", distinctSitesP: "0.405737704918033", brLenTotal: "1.0739" },
  { geneTranscript: "NM_016293.4", parsimonySitesP: "0.232448377581121", constantSitesP: "0.583480825958702", distinctSitesP: "0.404129793510324", brLenTotal: "0.8403" },
  { geneTranscript: "NM_016307.4", parsimonySitesP: "0.158102766798419", constantSitesP: "0.725955204216074", distinctSitesP: "0.281949934123847", brLenTotal: "0.8391" },
  { geneTranscript: "NM_016309.3", parsimonySitesP: "0.211576846307385", constantSitesP: "0.642714570858283", distinctSitesP: "0.355289421157685", brLenTotal: "0.7587" },
  { geneTranscript: "NM_016327.3", parsimonySitesP: "0.243055555555556", constantSitesP: "0.638020833333333", distinctSitesP: "0.348090277777778", brLenTotal: "0.9705" },
  { geneTranscript: "NM_016332.4", parsimonySitesP: "0.227011494252874", constantSitesP: "0.637931034482759", distinctSitesP: "0.399425287356322", brLenTotal: "1.1064" },
  { geneTranscript: "NM_016352.4", parsimonySitesP: "0.2486144101346", constantSitesP: "0.604117181314331", distinctSitesP: "0.380047505938242", brLenTotal: "1.0247" },
  { geneTranscript: "NM_016354.4", parsimonySitesP: "0.319482917820868", constantSitesP: "0.536472760849492", distinctSitesP: "0.451061865189289", brLenTotal: "1.7137" },
  { geneTranscript: "NM_016360.4", parsimonySitesP: "0.28956228956229", constantSitesP: "0.537598204264871", distinctSitesP: "0.452300785634119", brLenTotal: "1.0505" },
  { geneTranscript: "NM_016362.5", parsimonySitesP: "0.262108262108262", constantSitesP: "0.558404558404558", distinctSitesP: "0.458689458689459", brLenTotal: "0.9626" },
  { geneTranscript: "NM_016374.6", parsimonySitesP: "0.12474593495935", constantSitesP: "0.764227642276423", distinctSitesP: "0.197408536585366", brLenTotal: "0.4764" },
  { geneTranscript: "NM_016395.4", parsimonySitesP: "0.167587476979742", constantSitesP: "0.733885819521179", distinctSitesP: "0.260589318600368", brLenTotal: "0.5728" },
  { geneTranscript: "NM_016404.3", parsimonySitesP: "0.234666666666667", constantSitesP: "0.496", distinctSitesP: "0.482666666666667", brLenTotal: "1.0420" },
  { geneTranscript: "NM_016408.4", parsimonySitesP: "0.19534355479841", constantSitesP: "0.67007382169222", distinctSitesP: "0.312890403180011", brLenTotal: "0.7336" },
  { geneTranscript: "NM_016428.3", parsimonySitesP: "0.234061930783242", constantSitesP: "0.622950819672131", distinctSitesP: "0.375227686703097", brLenTotal: "0.9445" },
  { geneTranscript: "NM_016438.4", parsimonySitesP: "0.276094276094276", constantSitesP: "0.585858585858586", distinctSitesP: "0.457912457912458", brLenTotal: "0.8909" },
  { geneTranscript: "NM_016458.4", parsimonySitesP: "0.297435897435897", constantSitesP: "0.52991452991453", distinctSitesP: "0.453846153846154", brLenTotal: "1.3003" },
  { geneTranscript: "NM_016459.4", parsimonySitesP: "0.294532627865961", constantSitesP: "0.557319223985891", distinctSitesP: "0.417989417989418", brLenTotal: "0.9916" },
  { geneTranscript: "NM_016462.4", parsimonySitesP: "0.279761904761905", constantSitesP: "0.589285714285714", distinctSitesP: "0.443452380952381", brLenTotal: "1.1298" },
  { geneTranscript: "NM_016472.5", parsimonySitesP: "0.160671462829736", constantSitesP: "0.712230215827338", distinctSitesP: "0.275779376498801", brLenTotal: "0.6077" },
  { geneTranscript: "NM_016481.5", parsimonySitesP: "0.303854875283447", constantSitesP: "0.510204081632653", distinctSitesP: "0.480725623582766", brLenTotal: "1.1552" },
  { geneTranscript: "NM_016483.7", parsimonySitesP: "0.1583552055993", constantSitesP: "0.665791776027997", distinctSitesP: "0.323709536307962", brLenTotal: "0.6314" },
  { geneTranscript: "NM_016491.4", parsimonySitesP: "0.30575256107171", constantSitesP: "0.550827423167849", distinctSitesP: "0.461780929866036", brLenTotal: "1.0069" },
  { geneTranscript: "NM_016495.6", parsimonySitesP: "0.18316268486917", constantSitesP: "0.704209328782708", distinctSitesP: "0.276450511945392", brLenTotal: "0.6324" },
  { geneTranscript: "NM_016497.4", parsimonySitesP: "0.255208333333333", constantSitesP: "0.611979166666667", distinctSitesP: "0.40625", brLenTotal: "0.7894" },
  { geneTranscript: "NM_016510.7", parsimonySitesP: "0.291385767790262", constantSitesP: "0.573033707865169", distinctSitesP: "0.426966292134831", brLenTotal: "1.2995" },
  { geneTranscript: "NM_016518.3", parsimonySitesP: "0.282905982905983", constantSitesP: "0.560683760683761", distinctSitesP: "0.457264957264957", brLenTotal: "1.0247" },
  { geneTranscript: "NM_016530.3", parsimonySitesP: "0.111111111111111", constantSitesP: "0.816425120772947", distinctSitesP: "0.188405797101449", brLenTotal: "0.3586" },
  { geneTranscript: "NM_016536.5", parsimonySitesP: "0.471811713191024", constantSitesP: "0.35303776683087", distinctSitesP: "0.614668856048166", brLenTotal: "2.5774" },
  { geneTranscript: "NM_016539.4", parsimonySitesP: "0.258215962441315", constantSitesP: "0.618779342723005", distinctSitesP: "0.377464788732394", brLenTotal: "1.2646" },
  { geneTranscript: "NM_016540.4", parsimonySitesP: "0.200945626477541", constantSitesP: "0.655634357762017", distinctSitesP: "0.325453112687155", brLenTotal: "0.9523" },
  { geneTranscript: "NM_016548.4", parsimonySitesP: "0.248545303408146", constantSitesP: "0.601828761429759", distinctSitesP: "0.427265170407315", brLenTotal: "0.9795" },
  { geneTranscript: "NM_016551.3", parsimonySitesP: "0.219298245614035", constantSitesP: "0.642690058479532", distinctSitesP: "0.353216374269006", brLenTotal: "0.8048" },
  { geneTranscript: "NM_016556.4", parsimonySitesP: "0.230414746543779", constantSitesP: "0.626728110599078", distinctSitesP: "0.377880184331797", brLenTotal: "0.7298" },
  { geneTranscript: "NM_016559.3", parsimonySitesP: "0.15814696485623", constantSitesP: "0.733226837060703", distinctSitesP: "0.234291799787007", brLenTotal: "0.6776" },
  { geneTranscript: "NM_016562.4", parsimonySitesP: "0.214172227518271", constantSitesP: "0.661264696536384", distinctSitesP: "0.300921512551636", brLenTotal: "0.6856" },
  { geneTranscript: "NM_016586.3", parsimonySitesP: "0.153100775193798", constantSitesP: "0.738372093023256", distinctSitesP: "0.288759689922481", brLenTotal: "0.4762" },
  { geneTranscript: "NM_016599.5", parsimonySitesP: "0.194444444444444", constantSitesP: "0.703282828282828", distinctSitesP: "0.316919191919192", brLenTotal: "0.5784" },
  { geneTranscript: "NM_016619.3", parsimonySitesP: "0.266666666666667", constantSitesP: "0.6", distinctSitesP: "0.455072463768116", brLenTotal: "1.3554" },
  { geneTranscript: "NM_016622.4", parsimonySitesP: "0.292553191489362", constantSitesP: "0.49822695035461", distinctSitesP: "0.528368794326241", brLenTotal: "1.2669" },
  { geneTranscript: "NM_016626.5", parsimonySitesP: "0.121396054628225", constantSitesP: "0.757207890743551", distinctSitesP: "0.229135053110774", brLenTotal: "0.4876" },
  { geneTranscript: "NM_016639.3", parsimonySitesP: "0.235142118863049", constantSitesP: "0.638242894056848", distinctSitesP: "0.385012919896641", brLenTotal: "0.7796" },
  { geneTranscript: "NM_016640.4", parsimonySitesP: "0.269552012148823", constantSitesP: "0.57327258921792", distinctSitesP: "0.44039483675019", brLenTotal: "0.9650" },
  { geneTranscript: "NM_016641.4", parsimonySitesP: "0.181268882175227", constantSitesP: "0.684793554884189", distinctSitesP: "0.310171198388721", brLenTotal: "0.6242" },
  { geneTranscript: "NM_016642.4", parsimonySitesP: "0.352749047359826", constantSitesP: "0.453365995282163", distinctSitesP: "0.461712937760842", brLenTotal: "1.4346" },
  { geneTranscript: "NM_016648.4", parsimonySitesP: "0.209621993127148", constantSitesP: "0.642611683848797", distinctSitesP: "0.371706758304696", brLenTotal: "0.7499" },
  { geneTranscript: "NM_016732.3", parsimonySitesP: "0.228758169934641", constantSitesP: "0.612200435729847", distinctSitesP: "0.381263616557734", brLenTotal: "1.4161" },
  { geneTranscript: "NM_016848.6", parsimonySitesP: "0.191919191919192", constantSitesP: "0.671156004489338", distinctSitesP: "0.277216610549944", brLenTotal: "0.8266" },
  { geneTranscript: "NM_016951.4", parsimonySitesP: "0.256578947368421", constantSitesP: "0.633771929824561", distinctSitesP: "0.37719298245614", brLenTotal: "0.7473" },
  { geneTranscript: "NM_016953.4", parsimonySitesP: "0.166130760986066", constantSitesP: "0.733476241514827", distinctSitesP: "0.24258663808503", brLenTotal: "0.5587" },
  { geneTranscript: "NM_016955.4", parsimonySitesP: "0.149700598802395", constantSitesP: "0.743845642049235", distinctSitesP: "0.244178310046574", brLenTotal: "0.5062" },
  { geneTranscript: "NM_017411.4", parsimonySitesP: "0.193877551020408", constantSitesP: "0.642857142857143", distinctSitesP: "0.383219954648526", brLenTotal: "0.7677" },
  { geneTranscript: "NM_017426.4", parsimonySitesP: "0.151216305062459", constantSitesP: "0.74293228139382", distinctSitesP: "0.234714003944773", brLenTotal: "0.5169" },
  { geneTranscript: "NM_017435.5", parsimonySitesP: "0.170411985018727", constantSitesP: "0.688670411985019", distinctSitesP: "0.300561797752809", brLenTotal: "0.6130" },
  { geneTranscript: "NM_017437.3", parsimonySitesP: "0.119778346121057", constantSitesP: "0.790281329923274", distinctSitesP: "0.17306052855925", brLenTotal: "0.4395" },
  { geneTranscript: "NM_017444.6", parsimonySitesP: "0.208651399491094", constantSitesP: "0.620865139949109", distinctSitesP: "0.412213740458015", brLenTotal: "0.6662" },
  { geneTranscript: "NM_017460.6", parsimonySitesP: "0.327369118621604", constantSitesP: "0.530152418820411", distinctSitesP: "0.486414844267727", brLenTotal: "1.8354" },
  { geneTranscript: "NM_017509.4", parsimonySitesP: "0.279947916666667", constantSitesP: "0.548177083333333", distinctSitesP: "0.436197916666667", brLenTotal: "1.1972" },
  { geneTranscript: "NM_017526.5", parsimonySitesP: "0.124681933842239", constantSitesP: "0.7735368956743", distinctSitesP: "0.241730279898219", brLenTotal: "0.3916" },
  { geneTranscript: "NM_017547.4", parsimonySitesP: "0.273662551440329", constantSitesP: "0.600137174211248", distinctSitesP: "0.382030178326475", brLenTotal: "1.0617" },
  { geneTranscript: "NM_017548.5", parsimonySitesP: "0.177002583979328", constantSitesP: "0.660206718346253", distinctSitesP: "0.329457364341085", brLenTotal: "0.7367" },
  { geneTranscript: "NM_017559.4", parsimonySitesP: "0.328189300411523", constantSitesP: "0.508230452674897", distinctSitesP: "0.487654320987654", brLenTotal: "1.0835" },
  { geneTranscript: "NM_017564.10", parsimonySitesP: "0.274271527505553", constantSitesP: "0.584346008101398", distinctSitesP: "0.370834966679733", brLenTotal: "1.0606" },
  { geneTranscript: "NM_017565.4", parsimonySitesP: "0.209488601355514", constantSitesP: "0.661121380160197", distinctSitesP: "0.335181762168823", brLenTotal: "0.8296" },
  { geneTranscript: "NM_017577.5", parsimonySitesP: "0.210473313192346", constantSitesP: "0.627391742195368", distinctSitesP: "0.368076535750252", brLenTotal: "0.6835" },
  { geneTranscript: "NM_017580.3", parsimonySitesP: "0.112052730696798", constantSitesP: "0.807909604519774", distinctSitesP: "0.173258003766478", brLenTotal: "0.3675" },
  { geneTranscript: "NM_017581.4", parsimonySitesP: "0.235908141962422", constantSitesP: "0.629784272790536", distinctSitesP: "0.359777313848295", brLenTotal: "1.1342" },
  { geneTranscript: "NM_017586.5", parsimonySitesP: "0.224806201550388", constantSitesP: "0.64922480620155", distinctSitesP: "0.362403100775194", brLenTotal: "0.9328" },
  { geneTranscript: "NM_017599.4", parsimonySitesP: "0.174154899443731", constantSitesP: "0.675224646983312", distinctSitesP: "0.261874197689345", brLenTotal: "0.6255" },
  { geneTranscript: "NM_017612.5", parsimonySitesP: "0.206977840641207", constantSitesP: "0.684582743988685", distinctSitesP: "0.311645450259312", brLenTotal: "0.7347" },
  { geneTranscript: "NM_017613.4", parsimonySitesP: "0.238515901060071", constantSitesP: "0.581272084805654", distinctSitesP: "0.402237926972909", brLenTotal: "0.8821" },
  { geneTranscript: "NM_017614.5", parsimonySitesP: "0.246097337006428", constantSitesP: "0.599632690541781", distinctSitesP: "0.372819100091827", brLenTotal: "1.1857" },
  { geneTranscript: "NM_017635.5", parsimonySitesP: "0.15668549905838", constantSitesP: "0.749152542372881", distinctSitesP: "0.224482109227872", brLenTotal: "0.5420" },
  { geneTranscript: "NM_017637.6", parsimonySitesP: "0.127085228996057", constantSitesP: "0.753412192902639", distinctSitesP: "0.183803457688808", brLenTotal: "0.5405" },
  { geneTranscript: "NM_017644.3", parsimonySitesP: "0.108333333333333", constantSitesP: "0.81", distinctSitesP: "0.152777777777778", brLenTotal: "0.3640" },
  { geneTranscript: "NM_017647.4", parsimonySitesP: "0.226288862652499", constantSitesP: "0.632821723730815", distinctSitesP: "0.334907516725699", brLenTotal: "0.8159" },
  { geneTranscript: "NM_017649.5", parsimonySitesP: "0.133333333333333", constantSitesP: "0.755809523809524", distinctSitesP: "0.190095238095238", brLenTotal: "0.6096" },
  { geneTranscript: "NM_017652.4", parsimonySitesP: "0.513266998341625", constantSitesP: "0.335820895522388", distinctSitesP: "0.683250414593698", brLenTotal: "2.6103" },
  { geneTranscript: "NM_017664.4", parsimonySitesP: "0.217460317460317", constantSitesP: "0.626190476190476", distinctSitesP: "0.338095238095238", brLenTotal: "0.8763" },
  { geneTranscript: "NM_017666.5", parsimonySitesP: "0.280416101311624", constantSitesP: "0.59520578923564", distinctSitesP: "0.382180009045681", brLenTotal: "1.1008" },
  { geneTranscript: "NM_017667.4", parsimonySitesP: "0.125864453665284", constantSitesP: "0.773167358229599", distinctSitesP: "0.192254495159059", brLenTotal: "0.4576" },
  { geneTranscript: "NM_017668.3", parsimonySitesP: "0.232835820895522", constantSitesP: "0.646766169154229", distinctSitesP: "0.334328358208955", brLenTotal: "1.0526" },
  { geneTranscript: "NM_017675.6", parsimonySitesP: "0.335368956743003", constantSitesP: "0.50559796437659", distinctSitesP: "0.463104325699746", brLenTotal: "1.4980" },
  { geneTranscript: "NM_017676.2", parsimonySitesP: "0.16794380587484", constantSitesP: "0.699872286079183", distinctSitesP: "0.29757343550447", brLenTotal: "0.6028" },
  { geneTranscript: "NM_017680.5", parsimonySitesP: "0.144736842105263", constantSitesP: "0.765789473684211", distinctSitesP: "0.220175438596491", brLenTotal: "0.5369" },
  { geneTranscript: "NM_017691.5", parsimonySitesP: "0.159863945578231", constantSitesP: "0.726919339164237", distinctSitesP: "0.256559766763848", brLenTotal: "0.5253" },
  { geneTranscript: "NM_017705.4", parsimonySitesP: "0.208080808080808", constantSitesP: "0.674747474747475", distinctSitesP: "0.305050505050505", brLenTotal: "0.8002" },
  { geneTranscript: "NM_017712.4", parsimonySitesP: "0.22488038277512", constantSitesP: "0.685805422647528", distinctSitesP: "0.320574162679426", brLenTotal: "1.0290" },
  { geneTranscript: "NM_017723.3", parsimonySitesP: "0.319148936170213", constantSitesP: "0.490937746256895", distinctSitesP: "0.479905437352246", brLenTotal: "1.4092" },
  { geneTranscript: "NM_017735.5", parsimonySitesP: "0.205614867536576", constantSitesP: "0.649663898774219", distinctSitesP: "0.351522340846184", brLenTotal: "0.7310" },
  { geneTranscript: "NM_017736.5", parsimonySitesP: "0.198300283286119", constantSitesP: "0.631728045325779", distinctSitesP: "0.345609065155807", brLenTotal: "0.7234" },
  { geneTranscript: "NM_017738.4", parsimonySitesP: "0.19701280227596", constantSitesP: "0.629919393077288", distinctSitesP: "0.351588430535799", brLenTotal: "0.6503" },
  { geneTranscript: "NM_017750.4", parsimonySitesP: "0.272677595628415", constantSitesP: "0.548087431693989", distinctSitesP: "0.403825136612022", brLenTotal: "1.0819" },
  { geneTranscript: "NM_017752.3", parsimonySitesP: "0.151488095238095", constantSitesP: "0.722321428571429", distinctSitesP: "0.246130952380952", brLenTotal: "0.4740" },
  { geneTranscript: "NM_017768.5", parsimonySitesP: "0.183831672203765", constantSitesP: "0.668327796234773", distinctSitesP: "0.331118493909192", brLenTotal: "0.6541" },
  { geneTranscript: "NM_017770.4", parsimonySitesP: "0.153153153153153", constantSitesP: "0.748873873873874", distinctSitesP: "0.261261261261261", brLenTotal: "0.6819" },
  { geneTranscript: "NM_017774.3", parsimonySitesP: "0.177892918825561", constantSitesP: "0.690846286701209", distinctSitesP: "0.305699481865285", brLenTotal: "0.7506" },
  { geneTranscript: "NM_017781.3", parsimonySitesP: "0.324489795918367", constantSitesP: "0.500680272108844", distinctSitesP: "0.43469387755102", brLenTotal: "1.8591" },
  { geneTranscript: "NM_017785.5", parsimonySitesP: "0.212121212121212", constantSitesP: "0.633608815426997", distinctSitesP: "0.350964187327824", brLenTotal: "0.7835" },
  { geneTranscript: "NM_017798.4", parsimonySitesP: "0.329159212880143", constantSitesP: "0.540846750149076", distinctSitesP: "0.376267143709004", brLenTotal: "1.6370" },
  { geneTranscript: "NM_017830.4", parsimonySitesP: "0.14421768707483", constantSitesP: "0.746938775510204", distinctSitesP: "0.253061224489796", brLenTotal: "0.4465" },
  { geneTranscript: "NM_017847.6", parsimonySitesP: "0.16446402349486", constantSitesP: "0.675477239353891", distinctSitesP: "0.337738619676946", brLenTotal: "0.5946" },
  { geneTranscript: "NM_017860.5", parsimonySitesP: "0.374389051808407", constantSitesP: "0.462365591397849", distinctSitesP: "0.555229716520039", brLenTotal: "1.3637" },
  { geneTranscript: "NM_017861.4", parsimonySitesP: "0.232558139534884", constantSitesP: "0.60594315245478", distinctSitesP: "0.428940568475452", brLenTotal: "0.7742" },
  { geneTranscript: "NM_017865.4", parsimonySitesP: "0.241490044958253", constantSitesP: "0.582530507385999", distinctSitesP: "0.374438021836866", brLenTotal: "0.9225" },
  { geneTranscript: "NM_017867.3", parsimonySitesP: "0.236994219653179", constantSitesP: "0.583815028901734", distinctSitesP: "0.420038535645472", brLenTotal: "0.8492" },
  { geneTranscript: "NM_017885.4", parsimonySitesP: "0.280193236714976", constantSitesP: "0.521739130434783", distinctSitesP: "0.466183574879227", brLenTotal: "0.9213" },
  { geneTranscript: "NM_017886.4", parsimonySitesP: "0.228496732026144", constantSitesP: "0.616993464052288", distinctSitesP: "0.36078431372549", brLenTotal: "0.8368" },
  { geneTranscript: "NM_017898.5", parsimonySitesP: "0.303482587064677", constantSitesP: "0.552238805970149", distinctSitesP: "0.485572139303483", brLenTotal: "1.2456" },
  { geneTranscript: "NM_017900.3", parsimonySitesP: "0.422110552763819", constantSitesP: "0.385259631490787", distinctSitesP: "0.64321608040201", brLenTotal: "1.5315" },
  { geneTranscript: "NM_017908.4", parsimonySitesP: "0.355555555555556", constantSitesP: "0.479259259259259", distinctSitesP: "0.52962962962963", brLenTotal: "1.5682" },
  { geneTranscript: "NM_017919.3", parsimonySitesP: "0.0949227373068433", constantSitesP: "0.813465783664459", distinctSitesP: "0.181015452538631", brLenTotal: "0.3080" },
  { geneTranscript: "NM_017921.4", parsimonySitesP: "0.175438596491228", constantSitesP: "0.740131578947368", distinctSitesP: "0.233004385964912", brLenTotal: "0.8204" },
  { geneTranscript: "NM_017955.4", parsimonySitesP: "0.351313969571231", constantSitesP: "0.485477178423237", distinctSitesP: "0.5283540802213", brLenTotal: "1.7641" },
  { geneTranscript: "NM_017969.3", parsimonySitesP: "0.166056166056166", constantSitesP: "0.71021571021571", distinctSitesP: "0.262515262515263", brLenTotal: "0.6329" },
  { geneTranscript: "NM_017971.4", parsimonySitesP: "0.232662192393736", constantSitesP: "0.630872483221477", distinctSitesP: "0.407158836689038", brLenTotal: "0.8689" },
  { geneTranscript: "NM_017975.5", parsimonySitesP: "0.183305132543711", constantSitesP: "0.671742808798646", distinctSitesP: "0.328257191201354", brLenTotal: "0.8050" },
  { geneTranscript: "NM_017983.7", parsimonySitesP: "0.177877428998505", constantSitesP: "0.710762331838565", distinctSitesP: "0.278026905829596", brLenTotal: "0.6875" },
  { geneTranscript: "NM_018000.3", parsimonySitesP: "0.188473520249221", constantSitesP: "0.657320872274143", distinctSitesP: "0.348909657320872", brLenTotal: "0.6822" },
  { geneTranscript: "NM_018004.3", parsimonySitesP: "0.286060606060606", constantSitesP: "0.567272727272727", distinctSitesP: "0.48", brLenTotal: "1.1128" },
  { geneTranscript: "NM_018006.5", parsimonySitesP: "0.226444972288203", constantSitesP: "0.654790182106097", distinctSitesP: "0.354711005542359", brLenTotal: "0.9459" },
  { geneTranscript: "NM_018009.5", parsimonySitesP: "0.317663817663818", constantSitesP: "0.512820512820513", distinctSitesP: "0.49002849002849", brLenTotal: "1.2358" },
  { geneTranscript: "NM_018010.4", parsimonySitesP: "0.186480186480186", constantSitesP: "0.692307692307692", distinctSitesP: "0.306138306138306", brLenTotal: "0.5373" },
  { geneTranscript: "NM_018017.4", parsimonySitesP: "0.156273199703044", constantSitesP: "0.723830734966592", distinctSitesP: "0.248700816629547", brLenTotal: "0.4940" },
  { geneTranscript: "NM_018023.5", parsimonySitesP: "0.188466947960619", constantSitesP: "0.685888420065635", distinctSitesP: "0.26418190342241", brLenTotal: "0.8008" },
  { geneTranscript: "NM_018024.3", parsimonySitesP: "0.190243902439024", constantSitesP: "0.67479674796748", distinctSitesP: "0.336585365853659", brLenTotal: "0.7684" },
  { geneTranscript: "NM_018040.5", parsimonySitesP: "0.152777777777778", constantSitesP: "0.725378787878788", distinctSitesP: "0.258207070707071", brLenTotal: "0.5479" },
  { geneTranscript: "NM_018049.3", parsimonySitesP: "0.230425055928412", constantSitesP: "0.664429530201342", distinctSitesP: "0.357941834451902", brLenTotal: "0.8109" },
  { geneTranscript: "NM_018063.5", parsimonySitesP: "0.139618138424821", constantSitesP: "0.750994431185362", distinctSitesP: "0.249801113762928", brLenTotal: "0.4767" },
  { geneTranscript: "NM_018068.5", parsimonySitesP: "0.204179513532032", constantSitesP: "0.669749914354231", distinctSitesP: "0.309352517985612", brLenTotal: "0.7415" },
  { geneTranscript: "NM_018074.6", parsimonySitesP: "0.246646026831785", constantSitesP: "0.628482972136223", distinctSitesP: "0.382868937048504", brLenTotal: "1.2564" },
  { geneTranscript: "NM_018077.3", parsimonySitesP: "0.207290294246816", constantSitesP: "0.650856389986825", distinctSitesP: "0.318401405357927", brLenTotal: "0.8343" },
  { geneTranscript: "NM_018078.4", parsimonySitesP: "0.187454412837345", constantSitesP: "0.641867250182349", distinctSitesP: "0.314004376367615", brLenTotal: "0.7155" },
  { geneTranscript: "NM_018083.5", parsimonySitesP: "0.255281690140845", constantSitesP: "0.551056338028169", distinctSitesP: "0.419014084507042", brLenTotal: "1.1875" },
  { geneTranscript: "NM_018087.5", parsimonySitesP: "0.177052423343225", constantSitesP: "0.681008902077151", distinctSitesP: "0.299703264094955", brLenTotal: "0.6266" },
  { geneTranscript: "NM_018089.3", parsimonySitesP: "0.260330578512397", constantSitesP: "0.547291092745638", distinctSitesP: "0.401744719926538", brLenTotal: "0.8653" },
  { geneTranscript: "NM_018097.3", parsimonySitesP: "0.221276595744681", constantSitesP: "0.611347517730496", distinctSitesP: "0.412765957446809", brLenTotal: "0.7221" },
  { geneTranscript: "NM_018105.3", parsimonySitesP: "0.167449139280125", constantSitesP: "0.7339593114241", distinctSitesP: "0.28169014084507", brLenTotal: "0.6620" },
  { geneTranscript: "NM_018112.3", parsimonySitesP: "0.198167239404353", constantSitesP: "0.626575028636884", distinctSitesP: "0.359679266895762", brLenTotal: "0.7639" },
  { geneTranscript: "NM_018122.5", parsimonySitesP: "0.182428940568475", constantSitesP: "0.693540051679587", distinctSitesP: "0.309043927648579", brLenTotal: "0.5991" },
  { geneTranscript: "NM_018121.4", parsimonySitesP: "0.152031827223643", constantSitesP: "0.720659278204035", distinctSitesP: "0.233873259448707", brLenTotal: "0.5403" },
  { geneTranscript: "NM_018126.3", parsimonySitesP: "0.171390013495277", constantSitesP: "0.748987854251012", distinctSitesP: "0.264507422402159", brLenTotal: "0.6268" },
  { geneTranscript: "NM_018127.7", parsimonySitesP: "0.251412429378531", constantSitesP: "0.612590799031477", distinctSitesP: "0.355932203389831", brLenTotal: "0.9420" },
  { geneTranscript: "NM_018130.3", parsimonySitesP: "0.268630849220104", constantSitesP: "0.56210283073368", distinctSitesP: "0.427498555748122", brLenTotal: "0.9831" },
  { geneTranscript: "NM_018135.4", parsimonySitesP: "0.258503401360544", constantSitesP: "0.58843537414966", distinctSitesP: "0.443877551020408", brLenTotal: "1.0229" },
  { geneTranscript: "NM_018142.4", parsimonySitesP: "0.153990610328638", constantSitesP: "0.753051643192488", distinctSitesP: "0.236150234741784", brLenTotal: "0.6510" },
  { geneTranscript: "NM_018171.5", parsimonySitesP: "0.158132530120482", constantSitesP: "0.725401606425703", distinctSitesP: "0.267570281124498", brLenTotal: "0.6515" },
  { geneTranscript: "NM_018192.4", parsimonySitesP: "0.207627118644068", constantSitesP: "0.685028248587571", distinctSitesP: "0.32015065913371", brLenTotal: "0.7135" },
  { geneTranscript: "NM_018209.4", parsimonySitesP: "0.263546798029557", constantSitesP: "0.621510673234811", distinctSitesP: "0.38423645320197", brLenTotal: "1.3317" },
  { geneTranscript: "NM_018212.6", parsimonySitesP: "0.13625730994152", constantSitesP: "0.73625730994152", distinctSitesP: "0.28187134502924", brLenTotal: "0.5302" },
  { geneTranscript: "NM_018226.6", parsimonySitesP: "0.168735632183908", constantSitesP: "0.741149425287356", distinctSitesP: "0.269425287356322", brLenTotal: "0.8912" },
  { geneTranscript: "NM_018227.6", parsimonySitesP: "0.166666666666667", constantSitesP: "0.716413181242079", distinctSitesP: "0.269645120405577", brLenTotal: "0.5638" },
  { geneTranscript: "NM_018230.3", parsimonySitesP: "0.207612456747405", constantSitesP: "0.666089965397924", distinctSitesP: "0.317474048442907", brLenTotal: "0.7516" },
  { geneTranscript: "NM_018233.4", parsimonySitesP: "0.189421894218942", constantSitesP: "0.678966789667897", distinctSitesP: "0.298892988929889", brLenTotal: "0.6582" },
  { geneTranscript: "NM_018235.3", parsimonySitesP: "0.246315789473684", constantSitesP: "0.659649122807018", distinctSitesP: "0.341754385964912", brLenTotal: "1.2030" },
  { geneTranscript: "NM_018241.3", parsimonySitesP: "0.161339421613394", constantSitesP: "0.705479452054795", distinctSitesP: "0.271689497716895", brLenTotal: "0.5504" },
  { geneTranscript: "NM_018255.4", parsimonySitesP: "0.174334140435835", constantSitesP: "0.690879741727199", distinctSitesP: "0.290153349475383", brLenTotal: "0.5939" },
  { geneTranscript: "NM_018269.4", parsimonySitesP: "0.245810055865922", constantSitesP: "0.629422718808194", distinctSitesP: "0.364990689013035", brLenTotal: "1.1584" },
  { geneTranscript: "NM_018275.5", parsimonySitesP: "0.159195402298851", constantSitesP: "0.747701149425287", distinctSitesP: "0.216091954022989", brLenTotal: "0.6508" },
  { geneTranscript: "NM_018283.4", parsimonySitesP: "0.25609756097561", constantSitesP: "0.554878048780488", distinctSitesP: "0.436991869918699", brLenTotal: "1.0634" },
  { geneTranscript: "NM_018284.3", parsimonySitesP: "0.382072829131653", constantSitesP: "0.459943977591036", distinctSitesP: "0.534453781512605", brLenTotal: "2.0205" },
  { geneTranscript: "NM_018288.4", parsimonySitesP: "0.123159303882195", constantSitesP: "0.754350736278447", distinctSitesP: "0.236278447121821", brLenTotal: "0.4996" },
  { geneTranscript: "NM_018290.4", parsimonySitesP: "0.226579520697168", constantSitesP: "0.619825708061002", distinctSitesP: "0.354030501089325", brLenTotal: "0.9172" },
  { geneTranscript: "NM_018296.6", parsimonySitesP: "0.194518125552608", constantSitesP: "0.646330680813439", distinctSitesP: "0.35499557913351", brLenTotal: "0.6162" },
  { geneTranscript: "NM_018299.6", parsimonySitesP: "0.0596026490066225", constantSitesP: "0.849889624724062", distinctSitesP: "0.152317880794702", brLenTotal: "0.2106" },
  { geneTranscript: "NM_018304.4", parsimonySitesP: "0.25462962962963", constantSitesP: "0.562962962962963", distinctSitesP: "0.47962962962963", brLenTotal: "0.9973" },
  { geneTranscript: "NM_018310.4", parsimonySitesP: "0.223548130469372", constantSitesP: "0.647573587907717", distinctSitesP: "0.328560063643596", brLenTotal: "0.8828" },
  { geneTranscript: "NM_018317.4", parsimonySitesP: "0.0950570342205323", constantSitesP: "0.803548795944233", distinctSitesP: "0.183776932826362", brLenTotal: "0.3397" },
  { geneTranscript: "NM_018319.4", parsimonySitesP: "0.208333333333333", constantSitesP: "0.633771929824561", distinctSitesP: "0.364583333333333", brLenTotal: "0.8753" },
  { geneTranscript: "NM_018323.4", parsimonySitesP: "0.232155232155232", constantSitesP: "0.638946638946639", distinctSitesP: "0.353430353430353", brLenTotal: "0.8637" },
  { geneTranscript: "NM_018327.4", parsimonySitesP: "0.209541062801932", constantSitesP: "0.681763285024155", distinctSitesP: "0.303140096618357", brLenTotal: "0.7384" },
  { geneTranscript: "NM_018343.3", parsimonySitesP: "0.195048309178744", constantSitesP: "0.619565217391304", distinctSitesP: "0.330917874396135", brLenTotal: "0.6918" },
  { geneTranscript: "NM_018344.6", parsimonySitesP: "0.301754385964912", constantSitesP: "0.55719298245614", distinctSitesP: "0.411929824561404", brLenTotal: "1.1967" },
  { geneTranscript: "NM_018360.3", parsimonySitesP: "0.164141414141414", constantSitesP: "0.732954545454545", distinctSitesP: "0.26010101010101", brLenTotal: "0.5329" },
  { geneTranscript: "NM_018361.5", parsimonySitesP: "0.214285714285714", constantSitesP: "0.651098901098901", distinctSitesP: "0.39010989010989", brLenTotal: "0.7789" },
  { geneTranscript: "NM_018365.4", parsimonySitesP: "0.209427609427609", constantSitesP: "0.638383838383838", distinctSitesP: "0.371717171717172", brLenTotal: "0.6793" },
  { geneTranscript: "NM_018367.7", parsimonySitesP: "0.111111111111111", constantSitesP: "0.801498127340824", distinctSitesP: "0.217228464419476", brLenTotal: "0.3562" },
  { geneTranscript: "NM_018370.3", parsimonySitesP: "0.225490196078431", constantSitesP: "0.642857142857143", distinctSitesP: "0.364145658263305", brLenTotal: "0.8695" },
  { geneTranscript: "NM_018396.3", parsimonySitesP: "0.21957671957672", constantSitesP: "0.619929453262787", distinctSitesP: "0.362433862433862", brLenTotal: "0.8737" },
  { geneTranscript: "NM_018407.6", parsimonySitesP: "0.188790560471976", constantSitesP: "0.646017699115044", distinctSitesP: "0.359882005899705", brLenTotal: "0.7033" },
  { geneTranscript: "NM_018419.3", parsimonySitesP: "0.1875", constantSitesP: "0.665798611111111", distinctSitesP: "0.330729166666667", brLenTotal: "0.9047" },
  { geneTranscript: "NM_018423.3", parsimonySitesP: "0.259873617693523", constantSitesP: "0.602685624012638", distinctSitesP: "0.39652448657188", brLenTotal: "0.8329" },
  { geneTranscript: "NM_018427.5", parsimonySitesP: "0.217101894521249", constantSitesP: "0.651817716333845", distinctSitesP: "0.337429595494112", brLenTotal: "0.7541" },
  { geneTranscript: "NM_018434.6", parsimonySitesP: "0.126491646778043", constantSitesP: "0.743834526650756", distinctSitesP: "0.234685759745426", brLenTotal: "0.5672" },
  { geneTranscript: "NM_018438.6", parsimonySitesP: "0.328782707622298", constantSitesP: "0.540386803185438", distinctSitesP: "0.48122866894198", brLenTotal: "1.5074" },
  { geneTranscript: "NM_018445.6", parsimonySitesP: "0.209876543209877", constantSitesP: "0.63668430335097", distinctSitesP: "0.3668430335097", brLenTotal: "0.8092" },
  { geneTranscript: "NM_018452.6", parsimonySitesP: "0.219858156028369", constantSitesP: "0.640661938534279", distinctSitesP: "0.382978723404255", brLenTotal: "0.7577" },
  { geneTranscript: "NM_018456.6", parsimonySitesP: "0.187179487179487", constantSitesP: "0.680769230769231", distinctSitesP: "0.365384615384615", brLenTotal: "0.6094" },
  { geneTranscript: "NM_018465.4", parsimonySitesP: "0.192743764172336", constantSitesP: "0.696145124716553", distinctSitesP: "0.326530612244898", brLenTotal: "0.6565" },
  { geneTranscript: "NM_018467.4", parsimonySitesP: "0.243243243243243", constantSitesP: "0.646074646074646", distinctSitesP: "0.341055341055341", brLenTotal: "0.9220" },
  { geneTranscript: "NM_018475.5", parsimonySitesP: "0.220164609053498", constantSitesP: "0.650205761316872", distinctSitesP: "0.382716049382716", brLenTotal: "0.8622" },
  { geneTranscript: "NM_018489.3", parsimonySitesP: "0.1259559154296", constantSitesP: "0.781601439496176", distinctSitesP: "0.155982905982906", brLenTotal: "0.3690" },
  { geneTranscript: "NM_018490.5", parsimonySitesP: "0.126533473536628", constantSitesP: "0.745180511742026", distinctSitesP: "0.216614090431125", brLenTotal: "0.5106" },
  { geneTranscript: "NM_018492.4", parsimonySitesP: "0.160455486542443", constantSitesP: "0.710144927536232", distinctSitesP: "0.281573498964803", brLenTotal: "0.5685" },
  { geneTranscript: "NM_018558.4", parsimonySitesP: "0.27162447257384", constantSitesP: "0.537974683544304", distinctSitesP: "0.417721518987342", brLenTotal: "1.0295" },
  { geneTranscript: "NM_018648.4", parsimonySitesP: "0.151041666666667", constantSitesP: "0.682291666666667", distinctSitesP: "0.34375", brLenTotal: "0.5942" },
  { geneTranscript: "NM_018657.5", parsimonySitesP: "0.133879781420765", constantSitesP: "0.721311475409836", distinctSitesP: "0.234426229508197", brLenTotal: "0.4963" },
  { geneTranscript: "NM_018664.3", parsimonySitesP: "0.207349081364829", constantSitesP: "0.700787401574803", distinctSitesP: "0.338582677165354", brLenTotal: "0.7927" },
  { geneTranscript: "NM_018676.4", parsimonySitesP: "0.300469483568075", constantSitesP: "0.542253521126761", distinctSitesP: "0.410015649452269", brLenTotal: "1.0509" },
  { geneTranscript: "NM_018687.7", parsimonySitesP: "0.373737373737374", constantSitesP: "0.456228956228956", distinctSitesP: "0.555555555555556", brLenTotal: "1.5049" },
  { geneTranscript: "NM_018699.4", parsimonySitesP: "0.164021164021164", constantSitesP: "0.729100529100529", distinctSitesP: "0.255555555555556", brLenTotal: "0.5789" },
  { geneTranscript: "NM_018702.4", parsimonySitesP: "0.273342354533153", constantSitesP: "0.594948128101037", distinctSitesP: "0.385656292286874", brLenTotal: "1.3822" },
  { geneTranscript: "NM_018706.7", parsimonySitesP: "0.247733043162858", constantSitesP: "0.638012332245194", distinctSitesP: "0.349655422560754", brLenTotal: "0.9787" },
  { geneTranscript: "NM_018714.3", parsimonySitesP: "0.219727891156463", constantSitesP: "0.642176870748299", distinctSitesP: "0.306802721088435", brLenTotal: "0.7799" },
  { geneTranscript: "NM_018724.4", parsimonySitesP: "0.285984848484848", constantSitesP: "0.583333333333333", distinctSitesP: "0.428030303030303", brLenTotal: "0.8442" },
  { geneTranscript: "NM_018719.5", parsimonySitesP: "0.220998531571219", constantSitesP: "0.654919236417034", distinctSitesP: "0.34287812041116", brLenTotal: "0.8039" },
  { geneTranscript: "NM_018841.6", parsimonySitesP: "0.115740740740741", constantSitesP: "0.796296296296296", distinctSitesP: "0.212962962962963", brLenTotal: "0.4701" },
  { geneTranscript: "NM_018897.3", parsimonySitesP: "0.183151093439364", constantSitesP: "0.693257123923128", distinctSitesP: "0.255715705765408", brLenTotal: "0.6869" },
  { geneTranscript: "NM_018942.3", parsimonySitesP: "0.21551724137931", constantSitesP: "0.659961685823755", distinctSitesP: "0.332375478927203", brLenTotal: "0.9047" },
  { geneTranscript: "NM_018944.3", parsimonySitesP: "0.274678111587983", constantSitesP: "0.545064377682403", distinctSitesP: "0.463519313304721", brLenTotal: "1.0557" },
  { geneTranscript: "NM_018957.6", parsimonySitesP: "0.233475986685687", constantSitesP: "0.633856395625297", distinctSitesP: "0.348074179743224", brLenTotal: "0.9818" },
  { geneTranscript: "NM_018959.4", parsimonySitesP: "0.154791154791155", constantSitesP: "0.782964782964783", distinctSitesP: "0.224406224406224", brLenTotal: "0.7433" },
  { geneTranscript: "NM_018965.4", parsimonySitesP: "0.302898550724638", constantSitesP: "0.523188405797101", distinctSitesP: "0.48695652173913", brLenTotal: "1.2530" },
  { geneTranscript: "NM_018979.4", parsimonySitesP: "0.19983207388749", constantSitesP: "0.656591099916037", distinctSitesP: "0.274839070808844", brLenTotal: "0.6808" },
  { geneTranscript: "NM_018967.5", parsimonySitesP: "0.174081237911025", constantSitesP: "0.725983236621534", distinctSitesP: "0.279174725983237", brLenTotal: "0.7605" },
  { geneTranscript: "NM_018976.5", parsimonySitesP: "0.150856389986825", constantSitesP: "0.764163372859025", distinctSitesP: "0.257575757575758", brLenTotal: "0.4470" },
  { geneTranscript: "NM_018982.5", parsimonySitesP: "0.16557734204793", constantSitesP: "0.703703703703704", distinctSitesP: "0.289760348583878", brLenTotal: "0.5948" },
  { geneTranscript: "NM_018984.4", parsimonySitesP: "0.264378773435017", constantSitesP: "0.589768033047347", distinctSitesP: "0.370511598347633", brLenTotal: "1.1789" },
  { geneTranscript: "NM_018986.5", parsimonySitesP: "0.336826347305389", constantSitesP: "0.469810379241517", distinctSitesP: "0.498003992015968", brLenTotal: "1.5802" },
  { geneTranscript: "NM_019018.3", parsimonySitesP: "0.175093632958801", constantSitesP: "0.681647940074906", distinctSitesP: "0.290262172284644", brLenTotal: "0.6793" },
  { geneTranscript: "NM_019032.6", parsimonySitesP: "0.258535071384233", constantSitesP: "0.585350713842334", distinctSitesP: "0.36219739292365", brLenTotal: "0.9806" },
  { geneTranscript: "NM_019040.5", parsimonySitesP: "0.194182389937107", constantSitesP: "0.650157232704403", distinctSitesP: "0.349056603773585", brLenTotal: "0.6600" },
  { geneTranscript: "NM_019041.7", parsimonySitesP: "0.249122807017544", constantSitesP: "0.582456140350877", distinctSitesP: "0.403508771929825", brLenTotal: "0.9134" },
  { geneTranscript: "NM_019042.5", parsimonySitesP: "0.148764498234997", constantSitesP: "0.738779626828038", distinctSitesP: "0.254160363086233", brLenTotal: "0.5349" },
  { geneTranscript: "NM_019055.6", parsimonySitesP: "0.261171797418073", constantSitesP: "0.559086395233366", distinctSitesP: "0.361800728235684", brLenTotal: "1.0984" },
  { geneTranscript: "NM_019065.3", parsimonySitesP: "0.203799654576857", constantSitesP: "0.678756476683938", distinctSitesP: "0.36873920552677", brLenTotal: "0.9378" },
  { geneTranscript: "NM_019075.4", parsimonySitesP: "0.346540880503145", constantSitesP: "0.493710691823899", distinctSitesP: "0.525157232704403", brLenTotal: "1.7399" },
  { geneTranscript: "NM_019076.5", parsimonySitesP: "0.337735849056604", constantSitesP: "0.5", distinctSitesP: "0.521383647798742", brLenTotal: "1.7577" },
  { geneTranscript: "NM_019077.3", parsimonySitesP: "0.353459119496855", constantSitesP: "0.481132075471698", distinctSitesP: "0.536477987421384", brLenTotal: "1.8069" },
  { geneTranscript: "NM_019078.2", parsimonySitesP: "0.328339575530587", constantSitesP: "0.488139825218477", distinctSitesP: "0.546816479400749", brLenTotal: "1.6736" },
  { geneTranscript: "NM_019083.3", parsimonySitesP: "0.12959112959113", constantSitesP: "0.742896742896743", distinctSitesP: "0.250866250866251", brLenTotal: "0.4683" },
  { geneTranscript: "NM_019086.6", parsimonySitesP: "0.287037037037037", constantSitesP: "0.558024691358025", distinctSitesP: "0.43641975308642", brLenTotal: "1.0027" },
  { geneTranscript: "NM_019089.5", parsimonySitesP: "0.312138728323699", constantSitesP: "0.508670520231214", distinctSitesP: "0.497109826589595", brLenTotal: "1.2117" },
  { geneTranscript: "NM_019093.4", parsimonySitesP: "0.33083645443196", constantSitesP: "0.456928838951311", distinctSitesP: "0.539325842696629", brLenTotal: "1.6820" },
  { geneTranscript: "NM_019095.6", parsimonySitesP: "0.193798449612403", constantSitesP: "0.689922480620155", distinctSitesP: "0.321151716500554", brLenTotal: "0.5692" },
  { geneTranscript: "NM_019107.4", parsimonySitesP: "0.25626204238921", constantSitesP: "0.620423892100193", distinctSitesP: "0.385356454720617", brLenTotal: "0.9903" },
  { geneTranscript: "NM_019117.5", parsimonySitesP: "0.27205199628598", constantSitesP: "0.634633240482823", distinctSitesP: "0.296657381615599", brLenTotal: "1.0898" },
  { geneTranscript: "NM_019118.5", parsimonySitesP: "0.214285714285714", constantSitesP: "0.638095238095238", distinctSitesP: "0.352380952380952", brLenTotal: "0.7776" },
  { geneTranscript: "NM_019591.4", parsimonySitesP: "0.372107567229518", constantSitesP: "0.455284552845528", distinctSitesP: "0.489055659787367", brLenTotal: "1.8223" },
  { geneTranscript: "NM_019613.4", parsimonySitesP: "0.185077519379845", constantSitesP: "0.739341085271318", distinctSitesP: "0.257751937984496", brLenTotal: "0.7956" },
  { geneTranscript: "NM_019841.7", parsimonySitesP: "0.274805669867398", constantSitesP: "0.611339734796525", distinctSitesP: "0.375400091449474", brLenTotal: "1.1506" },
  { geneTranscript: "NM_019859.4", parsimonySitesP: "0.199025748086291", constantSitesP: "0.645789839944328", distinctSitesP: "0.314544189283229", brLenTotal: "0.7647" },
  { geneTranscript: "NM_019891.4", parsimonySitesP: "0.142041399000714", constantSitesP: "0.747323340471092", distinctSitesP: "0.256245538900785", brLenTotal: "0.5391" },
  { geneTranscript: "NM_020056.5", parsimonySitesP: "0.398692810457516", constantSitesP: "0.435294117647059", distinctSitesP: "0.6", brLenTotal: "2.4624" },
  { geneTranscript: "NM_020070.4", parsimonySitesP: "0.492957746478873", constantSitesP: "0.28169014084507", distinctSitesP: "0.74491392801252", brLenTotal: "2.8549" },
  { geneTranscript: "NM_020116.5", parsimonySitesP: "0.177095631641086", constantSitesP: "0.688311688311688", distinctSitesP: "0.288075560802834", brLenTotal: "0.7522" },
  { geneTranscript: "NM_020139.4", parsimonySitesP: "0.191836734693878", constantSitesP: "0.697959183673469", distinctSitesP: "0.302040816326531", brLenTotal: "0.7533" },
  { geneTranscript: "NM_020157.4", parsimonySitesP: "0.177083333333333", constantSitesP: "0.651041666666667", distinctSitesP: "0.3828125", brLenTotal: "0.6885" },
  { geneTranscript: "NM_020166.5", parsimonySitesP: "0.225747126436782", constantSitesP: "0.628965517241379", distinctSitesP: "0.35816091954023", brLenTotal: "0.8550" },
  { geneTranscript: "NM_020167.5", parsimonySitesP: "0.276305220883534", constantSitesP: "0.539759036144578", distinctSitesP: "0.453012048192771", brLenTotal: "1.1154" },
  { geneTranscript: "NM_020169.4", parsimonySitesP: "0.177177177177177", constantSitesP: "0.693693693693694", distinctSitesP: "0.339339339339339", brLenTotal: "0.5470" },
  { geneTranscript: "NM_020175.3", parsimonySitesP: "0.290769230769231", constantSitesP: "0.584615384615385", distinctSitesP: "0.405128205128205", brLenTotal: "1.2895" },
  { geneTranscript: "NM_020183.6", parsimonySitesP: "0.195492662473795", constantSitesP: "0.65146750524109", distinctSitesP: "0.35272536687631", brLenTotal: "0.6856" },
  { geneTranscript: "NM_020192.5", parsimonySitesP: "0.212389380530973", constantSitesP: "0.641592920353982", distinctSitesP: "0.373156342182891", brLenTotal: "0.7413" },
  { geneTranscript: "NM_020200.7", parsimonySitesP: "0.183703703703704", constantSitesP: "0.682962962962963", distinctSitesP: "0.345185185185185", brLenTotal: "0.6467" },
  { geneTranscript: "NM_020201.4", parsimonySitesP: "0.184210526315789", constantSitesP: "0.66374269005848", distinctSitesP: "0.366959064327485", brLenTotal: "0.7509" },
  { geneTranscript: "NM_020204.3", parsimonySitesP: "0.161209068010076", constantSitesP: "0.754827875734677", distinctSitesP: "0.227539882451721", brLenTotal: "0.5368" },
  { geneTranscript: "NM_020209.4", parsimonySitesP: "0.245098039215686", constantSitesP: "0.631372549019608", distinctSitesP: "0.383333333333333", brLenTotal: "1.0597" },
  { geneTranscript: "NM_020223.4", parsimonySitesP: "0.251141552511416", constantSitesP: "0.634132420091324", distinctSitesP: "0.350456621004566", brLenTotal: "1.1929" },
  { geneTranscript: "NM_020227.4", parsimonySitesP: "0.481730052199851", constantSitesP: "0.329604772557793", distinctSitesP: "0.640566741237882", brLenTotal: "3.3305" },
  { geneTranscript: "NM_020232.5", parsimonySitesP: "0.212121212121212", constantSitesP: "0.648989898989899", distinctSitesP: "0.361111111111111", brLenTotal: "0.6848" },
  { geneTranscript: "NM_020233.5", parsimonySitesP: "0.195906432748538", constantSitesP: "0.689083820662768", distinctSitesP: "0.321637426900585", brLenTotal: "0.6097" },
  { geneTranscript: "NM_020242.3", parsimonySitesP: "0.170989433237272", constantSitesP: "0.70581171950048", distinctSitesP: "0.258165225744476", brLenTotal: "0.6236" },
  { geneTranscript: "NM_020243.5", parsimonySitesP: "0.171361502347418", constantSitesP: "0.661971830985915", distinctSitesP: "0.347417840375587", brLenTotal: "0.6765" },
  { geneTranscript: "NM_020313.4", parsimonySitesP: "0.233974358974359", constantSitesP: "0.590811965811966", distinctSitesP: "0.411324786324786", brLenTotal: "0.8575" },
  { geneTranscript: "NM_020319.3", parsimonySitesP: "0.171579743008314", constantSitesP: "0.702947845804989", distinctSitesP: "0.283446712018141", brLenTotal: "0.6043" },
  { geneTranscript: "NM_020320.5", parsimonySitesP: "0.166089965397924", constantSitesP: "0.702998846597463", distinctSitesP: "0.28719723183391", brLenTotal: "0.5508" },
  { geneTranscript: "NM_020342.3", parsimonySitesP: "0.116325711993582", constantSitesP: "0.7573204973927", distinctSitesP: "0.202567188126755", brLenTotal: "0.4633" },
  { geneTranscript: "NM_020345.4", parsimonySitesP: "0.151041666666667", constantSitesP: "0.769097222222222", distinctSitesP: "0.244791666666667", brLenTotal: "0.6720" },
  { geneTranscript: "NM_020350.5", parsimonySitesP: "0.283018867924528", constantSitesP: "0.587002096436059", distinctSitesP: "0.442348008385744", brLenTotal: "1.0822" },
  { geneTranscript: "NM_020353.3", parsimonySitesP: "0.291793313069909", constantSitesP: "0.545086119554205", distinctSitesP: "0.459979736575481", brLenTotal: "0.9482" },
  { geneTranscript: "NM_020366.4", parsimonySitesP: "0.249351995852773", constantSitesP: "0.579574909279419", distinctSitesP: "0.417573872472784", brLenTotal: "0.8694" },
  { geneTranscript: "NM_020376.4", parsimonySitesP: "0.263227513227513", constantSitesP: "0.585978835978836", distinctSitesP: "0.378306878306878", brLenTotal: "1.2606" },
  { geneTranscript: "NM_020386.5", parsimonySitesP: "0.222222222222222", constantSitesP: "0.621031746031746", distinctSitesP: "0.396825396825397", brLenTotal: "0.7790" },
  { geneTranscript: "NM_020393.4", parsimonySitesP: "0.318141197497766", constantSitesP: "0.494191242180518", distinctSitesP: "0.525469168900804", brLenTotal: "1.3421" },
  { geneTranscript: "NM_020395.4", parsimonySitesP: "0.216450216450216", constantSitesP: "0.659451659451659", distinctSitesP: "0.295815295815296", brLenTotal: "0.7197" },
  { geneTranscript: "NM_020401.4", parsimonySitesP: "0.156396396396396", constantSitesP: "0.722162162162162", distinctSitesP: "0.267747747747748", brLenTotal: "0.6242" },
  { geneTranscript: "NM_020408.6", parsimonySitesP: "0.135531135531136", constantSitesP: "0.747252747252747", distinctSitesP: "0.285714285714286", brLenTotal: "0.4101" },
  { geneTranscript: "NM_020423.7", parsimonySitesP: "0.196220930232558", constantSitesP: "0.65843023255814", distinctSitesP: "0.309593023255814", brLenTotal: "0.7312" },
  { geneTranscript: "NM_020426.4", parsimonySitesP: "0.268018018018018", constantSitesP: "0.560810810810811", distinctSitesP: "0.468468468468468", brLenTotal: "1.0319" },
  { geneTranscript: "NM_020433.5", parsimonySitesP: "0.265325670498084", constantSitesP: "0.618295019157088", distinctSitesP: "0.329980842911877", brLenTotal: "1.0713" },
  { geneTranscript: "NM_020524.4", parsimonySitesP: "0.283173734610123", constantSitesP: "0.533971728226174", distinctSitesP: "0.406292749658003", brLenTotal: "1.0451" },
  { geneTranscript: "NM_020525.5", parsimonySitesP: "0.25512104283054", constantSitesP: "0.556797020484171", distinctSitesP: "0.454376163873371", brLenTotal: "1.1394" },
  { geneTranscript: "NM_020531.3", parsimonySitesP: "0.213141025641026", constantSitesP: "0.661057692307692", distinctSitesP: "0.310096153846154", brLenTotal: "0.8759" },
  { geneTranscript: "NM_020547.3", parsimonySitesP: "0.229203025014543", constantSitesP: "0.613147178592205", distinctSitesP: "0.346713205351949", brLenTotal: "0.7584" },
  { geneTranscript: "NM_020549.5", parsimonySitesP: "0.265151515151515", constantSitesP: "0.587344028520499", distinctSitesP: "0.389928698752228", brLenTotal: "1.2101" },
  { geneTranscript: "NM_020634.3", parsimonySitesP: "0.288461538461538", constantSitesP: "0.538461538461538", distinctSitesP: "0.466117216117216", brLenTotal: "1.0449" },
  { geneTranscript: "NM_020638.3", parsimonySitesP: "0.270916334661355", constantSitesP: "0.567065073041169", distinctSitesP: "0.458167330677291", brLenTotal: "1.0772" },
  { geneTranscript: "NM_020666.3", parsimonySitesP: "0.112959112959113", constantSitesP: "0.771309771309771", distinctSitesP: "0.203742203742204", brLenTotal: "0.3639" },
  { geneTranscript: "NM_020675.4", parsimonySitesP: "0.171130952380952", constantSitesP: "0.669642857142857", distinctSitesP: "0.357142857142857", brLenTotal: "0.6442" },
  { geneTranscript: "NM_020677.6", parsimonySitesP: "0.24860646599777", constantSitesP: "0.567447045707915", distinctSitesP: "0.400222965440357", brLenTotal: "1.0651" },
  { geneTranscript: "NM_020678.4", parsimonySitesP: "0.289855072463768", constantSitesP: "0.563285024154589", distinctSitesP: "0.442512077294686", brLenTotal: "1.0530" },
  { geneTranscript: "NM_020696.4", parsimonySitesP: "0.186147186147186", constantSitesP: "0.649350649350649", distinctSitesP: "0.396103896103896", brLenTotal: "0.6568" },
  { geneTranscript: "NM_020715.3", parsimonySitesP: "0.20772238514174", constantSitesP: "0.627565982404692", distinctSitesP: "0.301808406647116", brLenTotal: "0.8631" },
  { geneTranscript: "NM_020718.4", parsimonySitesP: "0.177021696252465", constantSitesP: "0.708579881656805", distinctSitesP: "0.263313609467456", brLenTotal: "0.6040" },
  { geneTranscript: "NM_020762.4", parsimonySitesP: "0.188940092165899", constantSitesP: "0.716743471582181", distinctSitesP: "0.235944700460829", brLenTotal: "0.7989" },
  { geneTranscript: "NM_020773.3", parsimonySitesP: "0.178932178932179", constantSitesP: "0.699374699374699", distinctSitesP: "0.284752284752285", brLenTotal: "0.7063" },
  { geneTranscript: "NM_020778.5", parsimonySitesP: "0.304594330400782", constantSitesP: "0.532160312805474", distinctSitesP: "0.436754643206256", brLenTotal: "1.2190" },
  { geneTranscript: "NM_020792.6", parsimonySitesP: "0.235294117647059", constantSitesP: "0.646241830065359", distinctSitesP: "0.340686274509804", brLenTotal: "0.8699" },
  { geneTranscript: "NM_020808.5", parsimonySitesP: "0.207123499806427", constantSitesP: "0.681378242353852", distinctSitesP: "0.244483159117305", brLenTotal: "0.8949" },
  { geneTranscript: "NM_020810.3", parsimonySitesP: "0.180746561886051", constantSitesP: "0.666011787819253", distinctSitesP: "0.338572364112639", brLenTotal: "0.5937" },
  { geneTranscript: "NM_020813.4", parsimonySitesP: "0.413205537806177", constantSitesP: "0.44408945686901", distinctSitesP: "0.523961661341853", brLenTotal: "2.0157" },
  { geneTranscript: "NM_020821.3", parsimonySitesP: "0.185984545696776", constantSitesP: "0.686917132960298", distinctSitesP: "0.252775557331912", brLenTotal: "0.6514" },
  { geneTranscript: "NM_020829.4", parsimonySitesP: "0.13633169360506", constantSitesP: "0.751932536893886", distinctSitesP: "0.183649566643242", brLenTotal: "0.4541" },
  { geneTranscript: "NM_020857.3", parsimonySitesP: "0.174032202809181", constantSitesP: "0.714970880438506", distinctSitesP: "0.23535457348407", brLenTotal: "0.6762" },
  { geneTranscript: "NM_020921.4", parsimonySitesP: "0.23159868729489", constantSitesP: "0.622909829660885", distinctSitesP: "0.345522737927801", brLenTotal: "0.8132" },
  { geneTranscript: "NM_020922.5", parsimonySitesP: "0.186481481481481", constantSitesP: "0.679259259259259", distinctSitesP: "0.275555555555556", brLenTotal: "0.5801" },
  { geneTranscript: "NM_020936.3", parsimonySitesP: "0.186111111111111", constantSitesP: "0.677222222222222", distinctSitesP: "0.298333333333333", brLenTotal: "0.6871" },
  { geneTranscript: "NM_020943.3", parsimonySitesP: "0.180616740088106", constantSitesP: "0.679882525697504", distinctSitesP: "0.308002936857562", brLenTotal: "0.5949" },
  { geneTranscript: "NM_020947.4", parsimonySitesP: "0.312134502923977", constantSitesP: "0.528508771929825", distinctSitesP: "0.470760233918129", brLenTotal: "1.3972" },
  { geneTranscript: "NM_020960.5", parsimonySitesP: "0.172705314009662", constantSitesP: "0.717995169082126", distinctSitesP: "0.269323671497585", brLenTotal: "0.6163" },
  { geneTranscript: "NM_020961.4", parsimonySitesP: "0.138157894736842", constantSitesP: "0.77046783625731", distinctSitesP: "0.217105263157895", brLenTotal: "0.4626" },
  { geneTranscript: "NM_020964.3", parsimonySitesP: "0.202791779759597", constantSitesP: "0.662530696652449", distinctSitesP: "0.264055835595192", brLenTotal: "0.7559" },
  { geneTranscript: "NM_020967.3", parsimonySitesP: "0.115141047783535", constantSitesP: "0.771445020149683", distinctSitesP: "0.200921128382268", brLenTotal: "0.4050" },
  { geneTranscript: "NM_020972.3", parsimonySitesP: "0.285231116121759", constantSitesP: "0.606163096580233", distinctSitesP: "0.368658399098083", brLenTotal: "1.3207" },
  { geneTranscript: "NM_020998.4", parsimonySitesP: "0.254252873563218", constantSitesP: "0.602298850574713", distinctSitesP: "0.331034482758621", brLenTotal: "0.9531" },
  { geneTranscript: "NM_021021.4", parsimonySitesP: "0.142503097893432", constantSitesP: "0.709417596034696", distinctSitesP: "0.249690210656753", brLenTotal: "0.6909" },
  { geneTranscript: "NM_021027.3", parsimonySitesP: "0.347169811320755", constantSitesP: "0.494968553459119", distinctSitesP: "0.520125786163522", brLenTotal: "1.7286" },
  { geneTranscript: "NM_021034.3", parsimonySitesP: "0.406015037593985", constantSitesP: "0.385964912280702", distinctSitesP: "0.634085213032581", brLenTotal: "1.6948" },
  { geneTranscript: "NM_021067.5", parsimonySitesP: "0.175170068027211", constantSitesP: "0.700680272108844", distinctSitesP: "0.302721088435374", brLenTotal: "0.6219" },
  { geneTranscript: "NM_021077.4", parsimonySitesP: "0.316804407713499", constantSitesP: "0.490358126721763", distinctSitesP: "0.520661157024793", brLenTotal: "1.0085" },
  { geneTranscript: "NM_021082.4", parsimonySitesP: "0.179240969364426", constantSitesP: "0.690443529949703", distinctSitesP: "0.272062185642433", brLenTotal: "0.5777" },
  { geneTranscript: "NM_021102.4", parsimonySitesP: "0.281746031746032", constantSitesP: "0.560846560846561", distinctSitesP: "0.439153439153439", brLenTotal: "1.0278" },
  { geneTranscript: "NM_021110.4", parsimonySitesP: "0.186896807720861", constantSitesP: "0.698960653303638", distinctSitesP: "0.257423904974016", brLenTotal: "0.7431" },
  { geneTranscript: "NM_021115.5", parsimonySitesP: "0.230143229166667", constantSitesP: "0.642903645833333", distinctSitesP: "0.333984375", brLenTotal: "1.0373" },
  { geneTranscript: "NM_021139.3", parsimonySitesP: "0.397727272727273", constantSitesP: "0.4375", distinctSitesP: "0.551136363636364", brLenTotal: "2.4777" },
  { geneTranscript: "NM_021143.4", parsimonySitesP: "0.519423558897243", constantSitesP: "0.305764411027569", distinctSitesP: "0.68922305764411", brLenTotal: "2.7376" },
  { geneTranscript: "NM_021136.3", parsimonySitesP: "0.234536082474227", constantSitesP: "0.603522336769759", distinctSitesP: "0.372422680412371", brLenTotal: "0.9159" },
  { geneTranscript: "NM_021151.4", parsimonySitesP: "0.189542483660131", constantSitesP: "0.679738562091503", distinctSitesP: "0.294662309368192", brLenTotal: "0.6407" },
  { geneTranscript: "NM_021167.5", parsimonySitesP: "0.147459727385378", constantSitesP: "0.742255266418835", distinctSitesP: "0.271375464684015", brLenTotal: "0.5168" },
  { geneTranscript: "NM_021194.3", parsimonySitesP: "0.13740959894806", constantSitesP: "0.728468113083498", distinctSitesP: "0.251150558842867", brLenTotal: "0.4941" },
  { geneTranscript: "NM_021197.4", parsimonySitesP: "0.233333333333333", constantSitesP: "0.636363636363636", distinctSitesP: "0.383333333333333", brLenTotal: "1.1324" },
  { geneTranscript: "NM_021205.6", parsimonySitesP: "0.165374677002584", constantSitesP: "0.701550387596899", distinctSitesP: "0.281653746770026", brLenTotal: "0.6577" },
  { geneTranscript: "NM_021219.4", parsimonySitesP: "0.1834451901566", constantSitesP: "0.696868008948546", distinctSitesP: "0.319910514541387", brLenTotal: "0.6535" },
  { geneTranscript: "NM_021226.4", parsimonySitesP: "0.26981852913085", constantSitesP: "0.572110792741165", distinctSitesP: "0.398280802292264", brLenTotal: "1.1330" },
  { geneTranscript: "NM_021233.3", parsimonySitesP: "0.228070175438596", constantSitesP: "0.584487534626039", distinctSitesP: "0.426592797783934", brLenTotal: "0.7628" },
  { geneTranscript: "NM_021250.4", parsimonySitesP: "0.528428093645485", constantSitesP: "0.269788182831661", distinctSitesP: "0.709030100334448", brLenTotal: "3.3423" },
  { geneTranscript: "NM_021257.4", parsimonySitesP: "0.163355408388521", constantSitesP: "0.710816777041943", distinctSitesP: "0.293598233995585", brLenTotal: "0.6186" },
  { geneTranscript: "NM_021258.4", parsimonySitesP: "0.313588850174216", constantSitesP: "0.5", distinctSitesP: "0.468641114982578", brLenTotal: "1.2976" },
  { geneTranscript: "NM_021267.5", parsimonySitesP: "0.238095238095238", constantSitesP: "0.667619047619048", distinctSitesP: "0.352380952380952", brLenTotal: "0.9484" },
  { geneTranscript: "NM_021616.6", parsimonySitesP: "0.378415300546448", constantSitesP: "0.451502732240437", distinctSitesP: "0.51844262295082", brLenTotal: "1.6007" },
  { geneTranscript: "NM_021626.3", parsimonySitesP: "0.245575221238938", constantSitesP: "0.631268436578171", distinctSitesP: "0.37094395280236", brLenTotal: "0.9283" },
  { geneTranscript: "NM_021629.4", parsimonySitesP: "0.184313725490196", constantSitesP: "0.706862745098039", distinctSitesP: "0.263725490196078", brLenTotal: "1.0706" },
  { geneTranscript: "NM_021646.4", parsimonySitesP: "0.357638888888889", constantSitesP: "0.440972222222222", distinctSitesP: "0.54375", brLenTotal: "2.0351" },
  { geneTranscript: "NM_021647.8", parsimonySitesP: "0.299103504482478", constantSitesP: "0.577832110839446", distinctSitesP: "0.401792991035045", brLenTotal: "1.4316" },
  { geneTranscript: "NM_021732.3", parsimonySitesP: "0.219954648526077", constantSitesP: "0.589569160997732", distinctSitesP: "0.390022675736961", brLenTotal: "0.9413" },
  { geneTranscript: "NM_021825.5", parsimonySitesP: "0.162729658792651", constantSitesP: "0.716535433070866", distinctSitesP: "0.307086614173228", brLenTotal: "0.4854" },
  { geneTranscript: "NM_021826.5", parsimonySitesP: "0.270506108202443", constantSitesP: "0.576788830715532", distinctSitesP: "0.401832460732984", brLenTotal: "0.8356" },
  { geneTranscript: "NM_021833.5", parsimonySitesP: "0.263843648208469", constantSitesP: "0.61129207383279", distinctSitesP: "0.412595005428882", brLenTotal: "1.0098" },
  { geneTranscript: "NM_021873.4", parsimonySitesP: "0.22816091954023", constantSitesP: "0.638505747126437", distinctSitesP: "0.341954022988506", brLenTotal: "0.8973" },
  { geneTranscript: "NM_021925.4", parsimonySitesP: "0.259487179487179", constantSitesP: "0.575384615384615", distinctSitesP: "0.463589743589744", brLenTotal: "0.9230" },
  { geneTranscript: "NM_021926.4", parsimonySitesP: "0.180048661800487", constantSitesP: "0.709651257096513", distinctSitesP: "0.279805352798054", brLenTotal: "0.7952" },
  { geneTranscript: "NM_021953.4", parsimonySitesP: "0.268239405854085", constantSitesP: "0.54521625163827", distinctSitesP: "0.404106596767147", brLenTotal: "1.0261" },
  { geneTranscript: "NM_021955.5", parsimonySitesP: "0.247747747747748", constantSitesP: "0.653153153153153", distinctSitesP: "0.364864864864865", brLenTotal: "0.9125" },
  { geneTranscript: "NM_021970.4", parsimonySitesP: "0.123655913978495", constantSitesP: "0.725806451612903", distinctSitesP: "0.279569892473118", brLenTotal: "0.5084" },
  { geneTranscript: "NM_021992.3", parsimonySitesP: "0.2", constantSitesP: "0.644444444444444", distinctSitesP: "0.4", brLenTotal: "0.7341" },
  { geneTranscript: "NM_022003.4", parsimonySitesP: "0.150877192982456", constantSitesP: "0.743859649122807", distinctSitesP: "0.287719298245614", brLenTotal: "0.5681" },
  { geneTranscript: "NM_022006.2", parsimonySitesP: "0.154166666666667", constantSitesP: "0.795833333333333", distinctSitesP: "0.266666666666667", brLenTotal: "0.4597" },
  { geneTranscript: "NM_022041.4", parsimonySitesP: "0.165829145728643", constantSitesP: "0.759352317141262", distinctSitesP: "0.223338916806253", brLenTotal: "0.7115" },
  { geneTranscript: "NM_022113.6", parsimonySitesP: "0.170267774699908", constantSitesP: "0.728347183748846", distinctSitesP: "0.223638042474608", brLenTotal: "0.7348" },
  { geneTranscript: "NM_022119.4", parsimonySitesP: "0.321766561514196", constantSitesP: "0.55205047318612", distinctSitesP: "0.467928496319664", brLenTotal: "1.2631" },
  { geneTranscript: "NM_022128.3", parsimonySitesP: "0.180124223602484", constantSitesP: "0.681159420289855", distinctSitesP: "0.293995859213251", brLenTotal: "0.6542" },
  { geneTranscript: "NM_022129.4", parsimonySitesP: "0.21875", constantSitesP: "0.637731481481481", distinctSitesP: "0.372685185185185", brLenTotal: "0.8059" },
  { geneTranscript: "NM_022132.5", parsimonySitesP: "0.227353463587922", constantSitesP: "0.670811130846655", distinctSitesP: "0.320307874481942", brLenTotal: "0.7636" },
  { geneTranscript: "NM_022136.5", parsimonySitesP: "0.184092940125112", constantSitesP: "0.668453976764969", distinctSitesP: "0.34763181411975", brLenTotal: "0.6270" },
  { geneTranscript: "NM_022139.4", parsimonySitesP: "0.329615861214374", constantSitesP: "0.509293680297398", distinctSitesP: "0.485749690210657", brLenTotal: "1.3504" },
  { geneTranscript: "NM_022140.5", parsimonySitesP: "0.171039844509232", constantSitesP: "0.715743440233236", distinctSitesP: "0.284742468415938", brLenTotal: "0.6369" },
  { geneTranscript: "NM_022160.3", parsimonySitesP: "0.276455026455026", constantSitesP: "0.540343915343915", distinctSitesP: "0.451719576719577", brLenTotal: "1.0702" },
  { geneTranscript: "NM_022336.4", parsimonySitesP: "0.207589285714286", constantSitesP: "0.680059523809524", distinctSitesP: "0.30952380952381", brLenTotal: "1.0484" },
  { geneTranscript: "NM_022353.3", parsimonySitesP: "0.183574879227053", constantSitesP: "0.690821256038647", distinctSitesP: "0.318840579710145", brLenTotal: "0.5729" },
  { geneTranscript: "NM_022358.4", parsimonySitesP: "0.438383838383838", constantSitesP: "0.305050505050505", distinctSitesP: "0.718181818181818", brLenTotal: "1.8351" },
  { geneTranscript: "NM_022369.4", parsimonySitesP: "0.290854572713643", constantSitesP: "0.561219390304848", distinctSitesP: "0.421289355322339", brLenTotal: "1.1353" },
  { geneTranscript: "NM_022370.4", parsimonySitesP: "0.224627224627225", constantSitesP: "0.603415103415103", distinctSitesP: "0.302308802308802", brLenTotal: "0.9252" },
  { geneTranscript: "NM_022371.4", parsimonySitesP: "0.321578505457599", constantSitesP: "0.512174643157011", distinctSitesP: "0.490344248530647", brLenTotal: "1.1814" },
  { geneTranscript: "NM_022437.3", parsimonySitesP: "0.274393263992075", constantSitesP: "0.581475978207033", distinctSitesP: "0.390787518573551", brLenTotal: "1.0405" },
  { geneTranscript: "NM_022445.4", parsimonySitesP: "0.207133058984911", constantSitesP: "0.651577503429355", distinctSitesP: "0.356652949245542", brLenTotal: "0.7508" },
  { geneTranscript: "NM_022458.4", parsimonySitesP: "0.137414965986395", constantSitesP: "0.76530612244898", distinctSitesP: "0.229251700680272", brLenTotal: "0.5279" },
  { geneTranscript: "NM_022449.4", parsimonySitesP: "0.314465408805031", constantSitesP: "0.588050314465409", distinctSitesP: "0.457547169811321", brLenTotal: "1.3501" },
  { geneTranscript: "NM_022470.4", parsimonySitesP: "0.167243367935409", constantSitesP: "0.739331026528258", distinctSitesP: "0.250288350634371", brLenTotal: "0.6424" },
  { geneTranscript: "NM_022482.5", parsimonySitesP: "0.240037505860291", constantSitesP: "0.610876699484294", distinctSitesP: "0.362400375058603", brLenTotal: "1.2446" },
  { geneTranscript: "NM_022486.5", parsimonySitesP: "0.252565818830879", constantSitesP: "0.593485051316377", distinctSitesP: "0.387773315484159", brLenTotal: "0.9174" },
  { geneTranscript: "NM_022492.6", parsimonySitesP: "0.282594733461785", constantSitesP: "0.54592164418754", distinctSitesP: "0.434810533076429", brLenTotal: "0.9557" },
  { geneTranscript: "NM_022726.4", parsimonySitesP: "0.164543524416136", constantSitesP: "0.747346072186837", distinctSitesP: "0.263269639065817", brLenTotal: "0.6128" },
  { geneTranscript: "NM_022744.4", parsimonySitesP: "0.250712250712251", constantSitesP: "0.60968660968661", distinctSitesP: "0.368233618233618", brLenTotal: "0.9491" },
  { geneTranscript: "NM_022750.4", parsimonySitesP: "0.240133143128864", constantSitesP: "0.609129814550642", distinctSitesP: "0.340941512125535", brLenTotal: "0.8581" },
  { geneTranscript: "NM_022754.7", parsimonySitesP: "0.19047619047619", constantSitesP: "0.700828157349896", distinctSitesP: "0.316770186335404", brLenTotal: "0.7778" },
  { geneTranscript: "NM_022755.6", parsimonySitesP: "0.169042769857434", constantSitesP: "0.730482009504413", distinctSitesP: "0.245756958587916", brLenTotal: "0.6466" },
  { geneTranscript: "NM_022761.3", parsimonySitesP: "0.186666666666667", constantSitesP: "0.648888888888889", distinctSitesP: "0.366666666666667", brLenTotal: "0.6806" },
  { geneTranscript: "NM_022777.4", parsimonySitesP: "0.203603603603604", constantSitesP: "0.65045045045045", distinctSitesP: "0.369369369369369", brLenTotal: "0.7486" },
  { geneTranscript: "NM_022769.5", parsimonySitesP: "0.24232633279483", constantSitesP: "0.626278944534195", distinctSitesP: "0.375336564351104", brLenTotal: "0.9401" },
  { geneTranscript: "NM_022783.4", parsimonySitesP: "0.165444172779136", constantSitesP: "0.702526487367563", distinctSitesP: "0.262428687856561", brLenTotal: "0.8632" },
  { geneTranscript: "NM_022786.3", parsimonySitesP: "0.223862238622386", constantSitesP: "0.611316113161132", distinctSitesP: "0.375153751537515", brLenTotal: "0.8629" },
  { geneTranscript: "NM_022817.3", parsimonySitesP: "0.297476759628154", constantSitesP: "0.578220451527224", distinctSitesP: "0.41062416998672", brLenTotal: "1.3210" },
  { geneTranscript: "NM_022818.5", parsimonySitesP: "0.213333333333333", constantSitesP: "0.682666666666667", distinctSitesP: "0.336", brLenTotal: "0.8431" },
  { geneTranscript: "NM_022823.3", parsimonySitesP: "0.122507122507123", constantSitesP: "0.703703703703704", distinctSitesP: "0.279202279202279", brLenTotal: "0.5600" },
  { geneTranscript: "NM_022836.4", parsimonySitesP: "0.260651629072682", constantSitesP: "0.581453634085213", distinctSitesP: "0.37218045112782", brLenTotal: "0.8427" },
  { geneTranscript: "NM_022840.5", parsimonySitesP: "0.214689265536723", constantSitesP: "0.634180790960452", distinctSitesP: "0.348870056497175", brLenTotal: "0.7367" },
  { geneTranscript: "NM_022842.5", parsimonySitesP: "0.261562998405104", constantSitesP: "0.597687400318979", distinctSitesP: "0.368421052631579", brLenTotal: "1.0942" },
  { geneTranscript: "NM_022904.3", parsimonySitesP: "0.271678206396307", constantSitesP: "0.580613254203759", distinctSitesP: "0.357072205736894", brLenTotal: "1.0292" },
  { geneTranscript: "NM_022916.6", parsimonySitesP: "0.185682326621924", constantSitesP: "0.718680089485459", distinctSitesP: "0.248881431767338", brLenTotal: "0.7994" },
  { geneTranscript: "NM_023007.3", parsimonySitesP: "0.28617106314948", constantSitesP: "0.553956834532374", distinctSitesP: "0.445243804956035", brLenTotal: "1.3568" },
  { geneTranscript: "NM_023036.6", parsimonySitesP: "0.249586776859504", constantSitesP: "0.634159779614325", distinctSitesP: "0.373553719008264", brLenTotal: "1.1114" },
  { geneTranscript: "NM_023071.4", parsimonySitesP: "0.190825688073394", constantSitesP: "0.647706422018349", distinctSitesP: "0.314984709480122", brLenTotal: "0.6321" },
  { geneTranscript: "NM_023924.5", parsimonySitesP: "0.217197096594082", constantSitesP: "0.687883863763261", distinctSitesP: "0.322724734785036", brLenTotal: "1.1222" },
  { geneTranscript: "NM_023927.4", parsimonySitesP: "0.195987654320988", constantSitesP: "0.679012345679012", distinctSitesP: "0.324074074074074", brLenTotal: "0.7058" },
  { geneTranscript: "NM_023928.5", parsimonySitesP: "0.239583333333333", constantSitesP: "0.65327380952381", distinctSitesP: "0.34672619047619", brLenTotal: "1.1262" },
  { geneTranscript: "NM_023936.2", parsimonySitesP: "0.275229357798165", constantSitesP: "0.577981651376147", distinctSitesP: "0.441896024464832", brLenTotal: "1.0954" },
  { geneTranscript: "NM_024011.4", parsimonySitesP: "0.182478632478632", constantSitesP: "0.73034188034188", distinctSitesP: "0.267948717948718", brLenTotal: "1.0028" },
  { geneTranscript: "NM_023937.4", parsimonySitesP: "0.286231884057971", constantSitesP: "0.485507246376812", distinctSitesP: "0.528985507246377", brLenTotal: "1.3513" },
  { geneTranscript: "NM_024026.5", parsimonySitesP: "0.271241830065359", constantSitesP: "0.584967320261438", distinctSitesP: "0.428104575163399", brLenTotal: "1.0610" },
  { geneTranscript: "NM_024042.4", parsimonySitesP: "0.359499431171786", constantSitesP: "0.354948805460751", distinctSitesP: "0.667804323094425", brLenTotal: "2.2468" },
  { geneTranscript: "NM_024044.4", parsimonySitesP: "0.301818181818182", constantSitesP: "0.517575757575758", distinctSitesP: "0.47030303030303", brLenTotal: "1.5632" },
  { geneTranscript: "NM_024047.5", parsimonySitesP: "0.232380952380952", constantSitesP: "0.612380952380952", distinctSitesP: "0.393333333333333", brLenTotal: "0.8121" },
  { geneTranscript: "NM_024050.6", parsimonySitesP: "0.15359477124183", constantSitesP: "0.751633986928105", distinctSitesP: "0.26797385620915", brLenTotal: "0.5959" },
  { geneTranscript: "NM_024051.4", parsimonySitesP: "0.164893617021277", constantSitesP: "0.707446808510638", distinctSitesP: "0.310283687943262", brLenTotal: "0.5492" },
  { geneTranscript: "NM_024078.3", parsimonySitesP: "0.305555555555556", constantSitesP: "0.558139534883721", distinctSitesP: "0.428940568475452", brLenTotal: "1.4276" },
  { geneTranscript: "NM_024093.3", parsimonySitesP: "0.137931034482759", constantSitesP: "0.719827586206897", distinctSitesP: "0.264367816091954", brLenTotal: "0.4517" },
  { geneTranscript: "NM_024094.3", parsimonySitesP: "0.179813401187447", constantSitesP: "0.687022900763359", distinctSitesP: "0.308736217133164", brLenTotal: "0.6758" },
  { geneTranscript: "NM_024097.4", parsimonySitesP: "0.21105527638191", constantSitesP: "0.614740368509213", distinctSitesP: "0.380234505862647", brLenTotal: "0.7516" },
  { geneTranscript: "NM_024105.4", parsimonySitesP: "0.312841530054645", constantSitesP: "0.541666666666667", distinctSitesP: "0.459699453551913", brLenTotal: "1.4695" },
  { geneTranscript: "NM_024109.4", parsimonySitesP: "0.284653465346535", constantSitesP: "0.544554455445545", distinctSitesP: "0.473597359735974", brLenTotal: "1.2324" },
  { geneTranscript: "NM_024164.6", parsimonySitesP: "0.406060606060606", constantSitesP: "0.452121212121212", distinctSitesP: "0.556363636363636", brLenTotal: "2.1549" },
  { geneTranscript: "NM_024298.5", parsimonySitesP: "0.171610169491525", constantSitesP: "0.675847457627119", distinctSitesP: "0.297316384180791", brLenTotal: "0.8232" },
  { geneTranscript: "NM_024299.4", parsimonySitesP: "0.309941520467836", constantSitesP: "0.453216374269006", distinctSitesP: "0.558479532163743", brLenTotal: "1.8365" },
  { geneTranscript: "NM_024307.3", parsimonySitesP: "0.272536687631027", constantSitesP: "0.60482180293501", distinctSitesP: "0.388888888888889", brLenTotal: "0.9277" },
  { geneTranscript: "NM_024309.4", parsimonySitesP: "0.304584304584305", constantSitesP: "0.482517482517483", distinctSitesP: "0.478632478632479", brLenTotal: "1.2698" },
  { geneTranscript: "NM_024312.5", parsimonySitesP: "0.180467091295117", constantSitesP: "0.699044585987261", distinctSitesP: "0.270700636942675", brLenTotal: "0.6247" },
  { geneTranscript: "NM_024329.6", parsimonySitesP: "0.140277777777778", constantSitesP: "0.755555555555556", distinctSitesP: "0.251388888888889", brLenTotal: "0.6594" },
  { geneTranscript: "NM_024330.4", parsimonySitesP: "0.258174719375305", constantSitesP: "0.595412396290874", distinctSitesP: "0.370424597364568", brLenTotal: "0.9473" },
  { geneTranscript: "NM_024337.4", parsimonySitesP: "0.245138888888889", constantSitesP: "0.6125", distinctSitesP: "0.366666666666667", brLenTotal: "1.3531" },
  { geneTranscript: "NM_024407.5", parsimonySitesP: "0.253521126760563", constantSitesP: "0.632237871674491", distinctSitesP: "0.386541471048513", brLenTotal: "1.2688" },
  { geneTranscript: "NM_024408.4", parsimonySitesP: "0.191690273843248", constantSitesP: "0.703224065830298", distinctSitesP: "0.2305409415891", brLenTotal: "0.6621" },
  { geneTranscript: "NM_024415.3", parsimonySitesP: "0.156077348066298", constantSitesP: "0.726979742173112", distinctSitesP: "0.26427255985267", brLenTotal: "0.4947" },
  { geneTranscript: "NM_024426.6", parsimonySitesP: "0.158365261813538", constantSitesP: "0.740740740740741", distinctSitesP: "0.252234993614304", brLenTotal: "0.6523" },
  { geneTranscript: "NM_024430.4", parsimonySitesP: "0.172654690618762", constantSitesP: "0.689620758483034", distinctSitesP: "0.335329341317365", brLenTotal: "0.6972" },
  { geneTranscript: "NM_024491.4", parsimonySitesP: "0.171970965940815", constantSitesP: "0.662758235622557", distinctSitesP: "0.337241764377443", brLenTotal: "0.6440" },
  { geneTranscript: "NM_024504.4", parsimonySitesP: "0.330998248686515", constantSitesP: "0.477524810274372", distinctSitesP: "0.490951546993579", brLenTotal: "1.1760" },
  { geneTranscript: "NM_024512.5", parsimonySitesP: "0.213836477987421", constantSitesP: "0.673854447439353", distinctSitesP: "0.336028751123091", brLenTotal: "0.7625" },
  { geneTranscript: "NM_024519.4", parsimonySitesP: "0.241181296144381", constantSitesP: "0.594476346732294", distinctSitesP: "0.353841946951053", brLenTotal: "1.0013" },
  { geneTranscript: "NM_024525.5", parsimonySitesP: "0.144961240310078", constantSitesP: "0.772480620155039", distinctSitesP: "0.213565891472868", brLenTotal: "0.5512" },
  { geneTranscript: "NM_024548.4", parsimonySitesP: "0.203853564547206", constantSitesP: "0.630443159922929", distinctSitesP: "0.338728323699422", brLenTotal: "0.6845" },
  { geneTranscript: "NM_024556.4", parsimonySitesP: "0.162393162393162", constantSitesP: "0.717948717948718", distinctSitesP: "0.265906932573599", brLenTotal: "0.6437" },
  { geneTranscript: "NM_024572.4", parsimonySitesP: "0.180555555555556", constantSitesP: "0.708937198067633", distinctSitesP: "0.253019323671498", brLenTotal: "0.7354" },
  { geneTranscript: "NM_024570.4", parsimonySitesP: "0.174145299145299", constantSitesP: "0.675213675213675", distinctSitesP: "0.331196581196581", brLenTotal: "0.6437" },
  { geneTranscript: "NM_024576.5", parsimonySitesP: "0.169253510716925", constantSitesP: "0.67479674796748", distinctSitesP: "0.359940872135994", brLenTotal: "0.6708" },
  { geneTranscript: "NM_024581.6", parsimonySitesP: "0.159649122807018", constantSitesP: "0.718713450292398", distinctSitesP: "0.240350877192982", brLenTotal: "0.5191" },
  { geneTranscript: "NM_024583.5", parsimonySitesP: "0.153301886792453", constantSitesP: "0.692610062893082", distinctSitesP: "0.299528301886792", brLenTotal: "0.5416" },
  { geneTranscript: "NM_024590.4", parsimonySitesP: "0.333333333333333", constantSitesP: "0.564273789649416", distinctSitesP: "0.369504730105732", brLenTotal: "1.3038" },
  { geneTranscript: "NM_024591.5", parsimonySitesP: "0.18407960199005", constantSitesP: "0.704809286898839", distinctSitesP: "0.310116086235489", brLenTotal: "0.7315" },
  { geneTranscript: "NM_024592.5", parsimonySitesP: "0.294549266247379", constantSitesP: "0.583857442348008", distinctSitesP: "0.442348008385744", brLenTotal: "1.1152" },
  { geneTranscript: "NM_024593.4", parsimonySitesP: "0.173775671406003", constantSitesP: "0.706161137440758", distinctSitesP: "0.344391785150079", brLenTotal: "0.6995" },
  { geneTranscript: "NM_024604.3", parsimonySitesP: "0.157393483709273", constantSitesP: "0.706766917293233", distinctSitesP: "0.27719298245614", brLenTotal: "0.5578" },
  { geneTranscript: "NM_024610.6", parsimonySitesP: "0.256147540983607", constantSitesP: "0.573087431693989", distinctSitesP: "0.394808743169399", brLenTotal: "0.9202" },
  { geneTranscript: "NM_024611.6", parsimonySitesP: "0.208078750848608", constantSitesP: "0.637135098438561", distinctSitesP: "0.341140529531568", brLenTotal: "0.7679" },
  { geneTranscript: "NM_024642.5", parsimonySitesP: "0.224899598393574", constantSitesP: "0.644865174985657", distinctSitesP: "0.348250143430866", brLenTotal: "0.9348" },
  { geneTranscript: "NM_024653.4", parsimonySitesP: "0.186594202898551", constantSitesP: "0.697463768115942", distinctSitesP: "0.315217391304348", brLenTotal: "0.6860" },
  { geneTranscript: "NM_024662.3", parsimonySitesP: "0.178861788617886", constantSitesP: "0.680975609756098", distinctSitesP: "0.272520325203252", brLenTotal: "0.8329" },
  { geneTranscript: "NM_024666.5", parsimonySitesP: "0.194708994708995", constantSitesP: "0.682539682539683", distinctSitesP: "0.312169312169312", brLenTotal: "0.6733" },
  { geneTranscript: "NM_024667.3", parsimonySitesP: "0.217543859649123", constantSitesP: "0.646783625730994", distinctSitesP: "0.319298245614035", brLenTotal: "0.9583" },
  { geneTranscript: "NM_024678.6", parsimonySitesP: "0.201956673654787", constantSitesP: "0.670859538784067", distinctSitesP: "0.320055904961565", brLenTotal: "0.6354" },
  { geneTranscript: "NM_024683.4", parsimonySitesP: "0.241666666666667", constantSitesP: "0.553703703703704", distinctSitesP: "0.450925925925926", brLenTotal: "0.8702" },
  { geneTranscript: "NM_024692.6", parsimonySitesP: "0.184397163120567", constantSitesP: "0.703073286052009", distinctSitesP: "0.304018912529551", brLenTotal: "0.8877" },
  { geneTranscript: "NM_024693.5", parsimonySitesP: "0.303630363036304", constantSitesP: "0.577557755775578", distinctSitesP: "0.443344334433443", brLenTotal: "1.2928" },
  { geneTranscript: "NM_024699.3", parsimonySitesP: "0.192786069651741", constantSitesP: "0.63681592039801", distinctSitesP: "0.371890547263682", brLenTotal: "0.6826" },
  { geneTranscript: "NM_024700.4", parsimonySitesP: "0.197811447811448", constantSitesP: "0.653198653198653", distinctSitesP: "0.341750841750842", brLenTotal: "0.7561" },
  { geneTranscript: "NM_024704.5", parsimonySitesP: "0.199696279422931", constantSitesP: "0.678056188306758", distinctSitesP: "0.296633763604151", brLenTotal: "0.8089" },
  { geneTranscript: "NM_024741.3", parsimonySitesP: "0.300462962962963", constantSitesP: "0.525925925925926", distinctSitesP: "0.424537037037037", brLenTotal: "1.1353" },
  { geneTranscript: "NM_024744.17", parsimonySitesP: "0.175172413793103", constantSitesP: "0.666206896551724", distinctSitesP: "0.336091954022989", brLenTotal: "0.5688" },
  { geneTranscript: "NM_024745.5", parsimonySitesP: "0.222718253968254", constantSitesP: "0.583333333333333", distinctSitesP: "0.381944444444444", brLenTotal: "0.8585" },
  { geneTranscript: "NM_024747.6", parsimonySitesP: "0.269677419354839", constantSitesP: "0.539354838709677", distinctSitesP: "0.343225806451613", brLenTotal: "1.0516" },
  { geneTranscript: "NM_024756.3", parsimonySitesP: "0.305584826132771", constantSitesP: "0.495258166491043", distinctSitesP: "0.417281348788198", brLenTotal: "1.2231" },
  { geneTranscript: "NM_024766.5", parsimonySitesP: "0.154798761609907", constantSitesP: "0.730650154798762", distinctSitesP: "0.283797729618163", brLenTotal: "0.5058" },
  { geneTranscript: "NM_024773.3", parsimonySitesP: "0.278846153846154", constantSitesP: "0.596153846153846", distinctSitesP: "0.443910256410256", brLenTotal: "1.1576" },
  { geneTranscript: "NM_024782.3", parsimonySitesP: "0.247491638795987", constantSitesP: "0.594202898550725", distinctSitesP: "0.408026755852843", brLenTotal: "0.8884" },
  { geneTranscript: "NM_024787.3", parsimonySitesP: "0.156989247311828", constantSitesP: "0.754838709677419", distinctSitesP: "0.266666666666667", brLenTotal: "0.6737" },
  { geneTranscript: "NM_024789.4", parsimonySitesP: "0.20180528691167", constantSitesP: "0.654416505480335", distinctSitesP: "0.288201160541586", brLenTotal: "0.9400" },
  { geneTranscript: "NM_024792.3", parsimonySitesP: "0.24254215304799", constantSitesP: "0.623865110246433", distinctSitesP: "0.38780804150454", brLenTotal: "0.8972" },
  { geneTranscript: "NM_024798.3", parsimonySitesP: "0.227979274611399", constantSitesP: "0.568221070811744", distinctSitesP: "0.40587219343696", brLenTotal: "0.8043" },
  { geneTranscript: "NM_024808.5", parsimonySitesP: "0.178890876565295", constantSitesP: "0.651759093619559", distinctSitesP: "0.348240906380441", brLenTotal: "0.6851" },
  { geneTranscript: "NM_024817.3", parsimonySitesP: "0.228552717747217", constantSitesP: "0.650622134905043", distinctSitesP: "0.31434184675835", brLenTotal: "0.9410" },
  { geneTranscript: "NM_024824.5", parsimonySitesP: "0.134510869565217", constantSitesP: "0.737771739130435", distinctSitesP: "0.228260869565217", brLenTotal: "0.5394" },
  { geneTranscript: "NM_024829.6", parsimonySitesP: "0.251356238698011", constantSitesP: "0.626280892103677", distinctSitesP: "0.366485834840265", brLenTotal: "0.8619" },
  { geneTranscript: "NM_024832.5", parsimonySitesP: "0.263282571912014", constantSitesP: "0.593231810490694", distinctSitesP: "0.367851099830795", brLenTotal: "1.2135" },
  { geneTranscript: "NM_024839.4", parsimonySitesP: "0.24025974025974", constantSitesP: "0.614718614718615", distinctSitesP: "0.4004329004329", brLenTotal: "0.8590" },
  { geneTranscript: "NM_024843.4", parsimonySitesP: "0.275058275058275", constantSitesP: "0.597902097902098", distinctSitesP: "0.442890442890443", brLenTotal: "0.9867" },
  { geneTranscript: "NM_024844.5", parsimonySitesP: "0.183943089430894", constantSitesP: "0.698678861788618", distinctSitesP: "0.290142276422764", brLenTotal: "0.6502" },
  { geneTranscript: "NM_024854.5", parsimonySitesP: "0.164666666666667", constantSitesP: "0.706", distinctSitesP: "0.284", brLenTotal: "0.6042" },
  { geneTranscript: "NM_024859.4", parsimonySitesP: "0.347305389221557", constantSitesP: "0.435129740518962", distinctSitesP: "0.569860279441118", brLenTotal: "1.3818" },
  { geneTranscript: "NM_024871.4", parsimonySitesP: "0.274706867671692", constantSitesP: "0.552763819095477", distinctSitesP: "0.495812395309883", brLenTotal: "1.0708" },
  { geneTranscript: "NM_024874.5", parsimonySitesP: "0.175405147759771", constantSitesP: "0.696536383857642", distinctSitesP: "0.260247855100095", brLenTotal: "0.6213" },
  { geneTranscript: "NM_024884.3", parsimonySitesP: "0.188624910007199", constantSitesP: "0.680345572354212", distinctSitesP: "0.311735061195104", brLenTotal: "0.6361" },
  { geneTranscript: "NM_024894.4", parsimonySitesP: "0.156007751937984", constantSitesP: "0.716085271317829", distinctSitesP: "0.25", brLenTotal: "0.6015" },
  { geneTranscript: "NM_024913.5", parsimonySitesP: "0.212475633528265", constantSitesP: "0.625406107862248", distinctSitesP: "0.382716049382716", brLenTotal: "0.7691" },
  { geneTranscript: "NM_024921.4", parsimonySitesP: "0.194680249009621", constantSitesP: "0.687040181097906", distinctSitesP: "0.315789473684211", brLenTotal: "0.5709" },
  { geneTranscript: "NM_024936.3", parsimonySitesP: "0.188434048083171", constantSitesP: "0.650422352176738", distinctSitesP: "0.366471734892788", brLenTotal: "0.6916" },
  { geneTranscript: "NM_024947.4", parsimonySitesP: "0.149748743718593", constantSitesP: "0.750418760469012", distinctSitesP: "0.209715242881072", brLenTotal: "0.5120" },
  { geneTranscript: "NM_024989.4", parsimonySitesP: "0.144613159797542", constantSitesP: "0.744396240057845", distinctSitesP: "0.243311641359364", brLenTotal: "0.4895" },
  { geneTranscript: "NM_024996.7", parsimonySitesP: "0.179316466932978", constantSitesP: "0.711939636040834", distinctSitesP: "0.271637816245007", brLenTotal: "0.5924" },
  { geneTranscript: "NM_025009.5", parsimonySitesP: "0.197368421052632", constantSitesP: "0.671637426900585", distinctSitesP: "0.318421052631579", brLenTotal: "0.6858" },
  { geneTranscript: "NM_025055.5", parsimonySitesP: "0.311258278145695", constantSitesP: "0.492273730684327", distinctSitesP: "0.477704194260486", brLenTotal: "1.2894" },
  { geneTranscript: "NM_025059.4", parsimonySitesP: "0.253146853146853", constantSitesP: "0.55990675990676", distinctSitesP: "0.414918414918415", brLenTotal: "0.8819" },
  { geneTranscript: "NM_025072.7", parsimonySitesP: "0.261715296198055", constantSitesP: "0.603890362511052", distinctSitesP: "0.380194518125553", brLenTotal: "1.1660" },
  { geneTranscript: "NM_025075.4", parsimonySitesP: "0.129084967320261", constantSitesP: "0.774509803921569", distinctSitesP: "0.251633986928105", brLenTotal: "0.4056" },
  { geneTranscript: "NM_025098.4", parsimonySitesP: "0.266467065868263", constantSitesP: "0.598802395209581", distinctSitesP: "0.406187624750499", brLenTotal: "1.0493" },
  { geneTranscript: "NM_025107.3", parsimonySitesP: "0.238297872340426", constantSitesP: "0.601418439716312", distinctSitesP: "0.452482269503546", brLenTotal: "0.8918" },
  { geneTranscript: "NM_025108.3", parsimonySitesP: "0.401847575057737", constantSitesP: "0.403387220939184", distinctSitesP: "0.602001539645881", brLenTotal: "1.6222" },
  { geneTranscript: "NM_025125.4", parsimonySitesP: "0.265582655826558", constantSitesP: "0.574525745257453", distinctSitesP: "0.447154471544715", brLenTotal: "0.8917" },
  { geneTranscript: "NM_025129.5", parsimonySitesP: "0.233652312599681", constantSitesP: "0.61244019138756", distinctSitesP: "0.378787878787879", brLenTotal: "0.8581" },
  { geneTranscript: "NM_025132.4", parsimonySitesP: "0.175608544461003", constantSitesP: "0.707650273224044", distinctSitesP: "0.273472429210134", brLenTotal: "0.6522" },
  { geneTranscript: "NM_025140.3", parsimonySitesP: "0.245720040281974", constantSitesP: "0.649546827794562", distinctSitesP: "0.392749244712991", brLenTotal: "1.0597" },
  { geneTranscript: "NM_025158.5", parsimonySitesP: "0.215630885122411", constantSitesP: "0.638888888888889", distinctSitesP: "0.343691148775895", brLenTotal: "0.9899" },
  { geneTranscript: "NM_025161.6", parsimonySitesP: "0.332198259553538", constantSitesP: "0.506999621642073", distinctSitesP: "0.442678774120318", brLenTotal: "1.4316" },
  { geneTranscript: "NM_025165.3", parsimonySitesP: "0.234256926952141", constantSitesP: "0.597816960537364", distinctSitesP: "0.381192275398825", brLenTotal: "0.7218" },
  { geneTranscript: "NM_025195.4", parsimonySitesP: "0.169354838709677", constantSitesP: "0.698028673835125", distinctSitesP: "0.247311827956989", brLenTotal: "0.8281" },
  { geneTranscript: "NM_025196.4", parsimonySitesP: "0.205837173579109", constantSitesP: "0.675883256528418", distinctSitesP: "0.33026113671275", brLenTotal: "0.8242" },
  { geneTranscript: "NM_025202.4", parsimonySitesP: "0.224546722454672", constantSitesP: "0.649930264993026", distinctSitesP: "0.357043235704324", brLenTotal: "0.8760" },
  { geneTranscript: "NM_025220.5", parsimonySitesP: "0.313243132431324", constantSitesP: "0.519475194751948", distinctSitesP: "0.462894628946289", brLenTotal: "1.2168" },
  { geneTranscript: "NM_025221.6", parsimonySitesP: "0.1", constantSitesP: "0.837333333333333", distinctSitesP: "0.170666666666667", brLenTotal: "0.3699" },
  { geneTranscript: "NM_025243.4", parsimonySitesP: "0.320564516129032", constantSitesP: "0.528897849462366", distinctSitesP: "0.45497311827957", brLenTotal: "1.5553" },
  { geneTranscript: "NM_025258.3", parsimonySitesP: "0.268612046389824", constantSitesP: "0.546576879910213", distinctSitesP: "0.385334829779274", brLenTotal: "1.0164" },
  { geneTranscript: "NM_025262.4", parsimonySitesP: "0.3", constantSitesP: "0.484444444444444", distinctSitesP: "0.537777777777778", brLenTotal: "1.0803" },
  { geneTranscript: "NM_025264.5", parsimonySitesP: "0.221338634857522", constantSitesP: "0.617627567925779", distinctSitesP: "0.390987408880053", brLenTotal: "0.7796" },
  { geneTranscript: "NM_030567.5", parsimonySitesP: "0.160583941605839", constantSitesP: "0.727493917274939", distinctSitesP: "0.274939172749392", brLenTotal: "0.5929" },
  { geneTranscript: "NM_030569.7", parsimonySitesP: "0.304317055909413", constantSitesP: "0.566525123849965", distinctSitesP: "0.447275300778485", brLenTotal: "1.3054" },
  { geneTranscript: "NM_030576.4", parsimonySitesP: "0.178477690288714", constantSitesP: "0.68241469816273", distinctSitesP: "0.293963254593176", brLenTotal: "0.8851" },
  { geneTranscript: "NM_030613.4", parsimonySitesP: "0.355748373101952", constantSitesP: "0.443239334779465", distinctSitesP: "0.49530007230658", brLenTotal: "1.8182" },
  { geneTranscript: "NM_030650.3", parsimonySitesP: "0.137071651090343", constantSitesP: "0.757009345794393", distinctSitesP: "0.249221183800623", brLenTotal: "0.4177" },
  { geneTranscript: "NM_030653.4", parsimonySitesP: "0.281824871228845", constantSitesP: "0.548197203826343", distinctSitesP: "0.426416482707873", brLenTotal: "1.1994" },
  { geneTranscript: "NM_030666.4", parsimonySitesP: "0.278803869832894", constantSitesP: "0.552330694810906", distinctSitesP: "0.4740545294635", brLenTotal: "1.1276" },
  { geneTranscript: "NM_030754.5", parsimonySitesP: "0.344262295081967", constantSitesP: "0.532786885245902", distinctSitesP: "0.508196721311475", brLenTotal: "1.9273" },
  { geneTranscript: "NM_030755.5", parsimonySitesP: "0.20952380952381", constantSitesP: "0.654761904761905", distinctSitesP: "0.373809523809524", brLenTotal: "0.7242" },
  { geneTranscript: "NM_030765.4", parsimonySitesP: "0.292768959435626", constantSitesP: "0.538800705467372", distinctSitesP: "0.458553791887125", brLenTotal: "1.1216" },
  { geneTranscript: "NM_030783.3", parsimonySitesP: "0.191649555099247", constantSitesP: "0.698836413415469", distinctSitesP: "0.293634496919918", brLenTotal: "0.9567" },
  { geneTranscript: "NM_030788.4", parsimonySitesP: "0.263829787234043", constantSitesP: "0.563829787234043", distinctSitesP: "0.433333333333333", brLenTotal: "0.9825" },
  { geneTranscript: "NM_030790.5", parsimonySitesP: "0.154139433551198", constantSitesP: "0.742919389978214", distinctSitesP: "0.272331154684096", brLenTotal: "0.5366" },
  { geneTranscript: "NM_030791.4", parsimonySitesP: "0.182161753590325", constantSitesP: "0.655328798185941", distinctSitesP: "0.325774754346183", brLenTotal: "0.6440" },
  { geneTranscript: "NM_030809.3", parsimonySitesP: "0.171884591774095", constantSitesP: "0.7268262737876", distinctSitesP: "0.242480049109883", brLenTotal: "0.5827" },
  { geneTranscript: "NM_030810.5", parsimonySitesP: "0.220679012345679", constantSitesP: "0.63966049382716", distinctSitesP: "0.381172839506173", brLenTotal: "0.9322" },
  { geneTranscript: "NM_030812.3", parsimonySitesP: "0.349726775956284", constantSitesP: "0.529143897996357", distinctSitesP: "0.524590163934426", brLenTotal: "1.4310" },
  { geneTranscript: "NM_030815.3", parsimonySitesP: "0.165413533834586", constantSitesP: "0.694235588972431", distinctSitesP: "0.305764411027569", brLenTotal: "0.5296" },
  { geneTranscript: "NM_030820.4", parsimonySitesP: "0.169627307558342", constantSitesP: "0.723789620341344", distinctSitesP: "0.270289097875305", brLenTotal: "0.6101" },
  { geneTranscript: "NM_030818.4", parsimonySitesP: "0.266835016835017", constantSitesP: "0.62037037037037", distinctSitesP: "0.381313131313131", brLenTotal: "1.1212" },
  { geneTranscript: "NM_030824.3", parsimonySitesP: "0.449760765550239", constantSitesP: "0.384370015948963", distinctSitesP: "0.585326953748006", brLenTotal: "2.5454" },
  { geneTranscript: "NM_030917.4", parsimonySitesP: "0.135802469135802", constantSitesP: "0.771604938271605", distinctSitesP: "0.211560044893378", brLenTotal: "0.5352" },
  { geneTranscript: "NM_030919.3", parsimonySitesP: "0.258119658119658", constantSitesP: "0.598290598290598", distinctSitesP: "0.419373219373219", brLenTotal: "0.9351" },
  { geneTranscript: "NM_030923.5", parsimonySitesP: "0.141868512110727", constantSitesP: "0.792387543252595", distinctSitesP: "0.224913494809689", brLenTotal: "0.5386" },
  { geneTranscript: "NM_030929.5", parsimonySitesP: "0.224780701754386", constantSitesP: "0.651315789473684", distinctSitesP: "0.334429824561404", brLenTotal: "0.9223" },
  { geneTranscript: "NM_030933.4", parsimonySitesP: "0.23226135783563", constantSitesP: "0.605410923940786", distinctSitesP: "0.383358856559469", brLenTotal: "0.8384" },
  { geneTranscript: "NM_030955.4", parsimonySitesP: "0.246758678377248", constantSitesP: "0.609368465077373", distinctSitesP: "0.359263906315349", brLenTotal: "0.9116" },
  { geneTranscript: "NM_030965.3", parsimonySitesP: "0.14781746031746", constantSitesP: "0.725198412698413", distinctSitesP: "0.282738095238095", brLenTotal: "0.5856" },
  { geneTranscript: "NM_030974.4", parsimonySitesP: "0.316968130921619", constantSitesP: "0.501291989664083", distinctSitesP: "0.46167097329888", brLenTotal: "1.3328" },
  { geneTranscript: "NM_031200.3", parsimonySitesP: "0.224932249322493", constantSitesP: "0.644986449864499", distinctSitesP: "0.328816621499548", brLenTotal: "0.9491" },
  { geneTranscript: "NM_031206.7", parsimonySitesP: "0.188010899182561", constantSitesP: "0.688010899182561", distinctSitesP: "0.287465940054496", brLenTotal: "0.6613" },
  { geneTranscript: "NM_031212.4", parsimonySitesP: "0.0961538461538462", constantSitesP: "0.797619047619048", distinctSitesP: "0.178571428571429", brLenTotal: "0.4108" },
  { geneTranscript: "NM_031283.3", parsimonySitesP: "0.159863945578231", constantSitesP: "0.723922902494331", distinctSitesP: "0.257936507936508", brLenTotal: "0.6718" },
  { geneTranscript: "NM_031290.4", parsimonySitesP: "0.298798798798799", constantSitesP: "0.496996996996997", distinctSitesP: "0.558558558558559", brLenTotal: "1.2198" },
  { geneTranscript: "NM_031301.4", parsimonySitesP: "0.214007782101167", constantSitesP: "0.667963683527886", distinctSitesP: "0.350194552529183", brLenTotal: "0.7067" },
  { geneTranscript: "NM_031307.4", parsimonySitesP: "0.203049203049203", constantSitesP: "0.644490644490644", distinctSitesP: "0.348579348579349", brLenTotal: "0.7500" },
  { geneTranscript: "NM_031370.3", parsimonySitesP: "0.063849765258216", constantSitesP: "0.850704225352113", distinctSitesP: "0.146478873239437", brLenTotal: "0.2249" },
  { geneTranscript: "NM_031420.4", parsimonySitesP: "0.275905118601748", constantSitesP: "0.565543071161049", distinctSitesP: "0.410736579275905", brLenTotal: "0.9762" },
  { geneTranscript: "NM_031433.4", parsimonySitesP: "0.225100748416811", constantSitesP: "0.627518710420265", distinctSitesP: "0.325849165227404", brLenTotal: "0.8516" },
  { geneTranscript: "NM_031446.5", parsimonySitesP: "0.231818181818182", constantSitesP: "0.568181818181818", distinctSitesP: "0.454545454545455", brLenTotal: "0.8006" },
  { geneTranscript: "NM_031452.4", parsimonySitesP: "0.228813559322034", constantSitesP: "0.612994350282486", distinctSitesP: "0.401129943502825", brLenTotal: "0.7402" },
  { geneTranscript: "NM_031454.2", parsimonySitesP: "0.29197807673144", constantSitesP: "0.570004982561036", distinctSitesP: "0.444942700548082", brLenTotal: "1.4861" },
  { geneTranscript: "NM_031460.4", parsimonySitesP: "0.296184738955823", constantSitesP: "0.55421686746988", distinctSitesP: "0.43574297188755", brLenTotal: "1.1640" },
  { geneTranscript: "NM_031474.3", parsimonySitesP: "0.354685646500593", constantSitesP: "0.488730723606168", distinctSitesP: "0.527876631079478", brLenTotal: "1.8297" },
  { geneTranscript: "NM_031480.3", parsimonySitesP: "0.180164319248826", constantSitesP: "0.689553990610329", distinctSitesP: "0.322183098591549", brLenTotal: "0.6668" },
  { geneTranscript: "NM_031481.3", parsimonySitesP: "0.248677248677249", constantSitesP: "0.627513227513228", distinctSitesP: "0.394708994708995", brLenTotal: "1.1276" },
  { geneTranscript: "NM_031491.4", parsimonySitesP: "0.22962962962963", constantSitesP: "0.617283950617284", distinctSitesP: "0.432098765432099", brLenTotal: "0.9012" },
  { geneTranscript: "NM_031899.4", parsimonySitesP: "0.258333333333333", constantSitesP: "0.603787878787879", distinctSitesP: "0.377272727272727", brLenTotal: "0.9036" },
  { geneTranscript: "NM_031901.6", parsimonySitesP: "0.28735632183908", constantSitesP: "0.436781609195402", distinctSitesP: "0.582375478927203", brLenTotal: "1.1043" },
  { geneTranscript: "NM_031903.3", parsimonySitesP: "0.283687943262411", constantSitesP: "0.549645390070922", distinctSitesP: "0.455673758865248", brLenTotal: "0.9783" },
  { geneTranscript: "NM_031912.5", parsimonySitesP: "0.262074425969913", constantSitesP: "0.564528899445764", distinctSitesP: "0.398258115597783", brLenTotal: "1.1375" },
  { geneTranscript: "NM_031913.5", parsimonySitesP: "0.202784048156509", constantSitesP: "0.651993980436418", distinctSitesP: "0.293077501881114", brLenTotal: "0.7212" },
  { geneTranscript: "NM_031938.7", parsimonySitesP: "0.217616580310881", constantSitesP: "0.635578583765112", distinctSitesP: "0.363270005757052", brLenTotal: "0.7121" },
  { geneTranscript: "NM_031939.6", parsimonySitesP: "0.259408602150538", constantSitesP: "0.563172043010753", distinctSitesP: "0.440860215053763", brLenTotal: "0.9164" },
  { geneTranscript: "NM_031941.4", parsimonySitesP: "0.370791844476055", constantSitesP: "0.42769084874348", distinctSitesP: "0.557136083451873", brLenTotal: "1.5110" },
  { geneTranscript: "NM_031955.6", parsimonySitesP: "0.24780316344464", constantSitesP: "0.577035735207967", distinctSitesP: "0.399531341534856", brLenTotal: "0.9306" },
  { geneTranscript: "NM_032019.6", parsimonySitesP: "0.309417040358744", constantSitesP: "0.537618335824614", distinctSitesP: "0.464374688589935", brLenTotal: "1.3236" },
  { geneTranscript: "NM_032020.5", parsimonySitesP: "0.24768022840828", constantSitesP: "0.613133476088508", distinctSitesP: "0.398286937901499", brLenTotal: "0.8574" },
  { geneTranscript: "NM_032026.4", parsimonySitesP: "0.139169472502806", constantSitesP: "0.745230078563412", distinctSitesP: "0.283950617283951", brLenTotal: "0.4705" },
  { geneTranscript: "NM_032108.4", parsimonySitesP: "0.228978978978979", constantSitesP: "0.655780780780781", distinctSitesP: "0.304054054054054", brLenTotal: "1.0297" },
  { geneTranscript: "NM_032117.4", parsimonySitesP: "0.144715447154472", constantSitesP: "0.692682926829268", distinctSitesP: "0.31869918699187", brLenTotal: "0.5204" },
  { geneTranscript: "NM_032119.4", parsimonySitesP: "0.218680621630193", constantSitesP: "0.625488952320541", distinctSitesP: "0.298657363357649", brLenTotal: "0.8059" },
  { geneTranscript: "NM_032120.4", parsimonySitesP: "0.248864668483197", constantSitesP: "0.579473206176203", distinctSitesP: "0.423251589464124", brLenTotal: "0.8777" },
  { geneTranscript: "NM_032122.5", parsimonySitesP: "0.211775878442545", constantSitesP: "0.638176638176638", distinctSitesP: "0.379867046533713", brLenTotal: "0.9061" },
  { geneTranscript: "NM_032124.5", parsimonySitesP: "0.145431145431145", constantSitesP: "0.697554697554698", distinctSitesP: "0.27027027027027", brLenTotal: "0.5786" },
  { geneTranscript: "NM_032131.6", parsimonySitesP: "0.213379469434833", constantSitesP: "0.632064590542099", distinctSitesP: "0.347943098808151", brLenTotal: "0.7932" },
  { geneTranscript: "NM_032143.4", parsimonySitesP: "0.191844300278035", constantSitesP: "0.668520234785295", distinctSitesP: "0.305220883534137", brLenTotal: "0.6612" },
  { geneTranscript: "NM_032151.5", parsimonySitesP: "0.269230769230769", constantSitesP: "0.564102564102564", distinctSitesP: "0.494871794871795", brLenTotal: "0.8943" },
  { geneTranscript: "NM_032169.5", parsimonySitesP: "0.212393162393162", constantSitesP: "0.629487179487179", distinctSitesP: "0.352991452991453", brLenTotal: "0.7602" },
  { geneTranscript: "NM_032178.3", parsimonySitesP: "0.280474649406688", constantSitesP: "0.55663430420712", distinctSitesP: "0.446601941747573", brLenTotal: "0.9317" },
  { geneTranscript: "NM_032184.2", parsimonySitesP: "0.213847012841988", constantSitesP: "0.608319374651033", distinctSitesP: "0.33249581239531", brLenTotal: "0.7166" },
  { geneTranscript: "NM_032193.4", parsimonySitesP: "0.329268292682927", constantSitesP: "0.477642276422764", distinctSitesP: "0.526422764227642", brLenTotal: "1.1292" },
  { geneTranscript: "NM_032194.3", parsimonySitesP: "0.171023965141612", constantSitesP: "0.632897603485839", distinctSitesP: "0.345315904139434", brLenTotal: "0.7376" },
  { geneTranscript: "NM_032221.5", parsimonySitesP: "0.190055248618785", constantSitesP: "0.682504604051565", distinctSitesP: "0.246899938612646", brLenTotal: "0.6917" },
  { geneTranscript: "NM_032230.3", parsimonySitesP: "0.221116639027087", constantSitesP: "0.616915422885572", distinctSitesP: "0.354339414040907", brLenTotal: "0.8195" },
  { geneTranscript: "NM_032251.6", parsimonySitesP: "0.277551942186089", constantSitesP: "0.559846431797651", distinctSitesP: "0.370144534778681", brLenTotal: "1.1180" },
  { geneTranscript: "NM_032257.5", parsimonySitesP: "0.209132420091324", constantSitesP: "0.657534246575342", distinctSitesP: "0.347945205479452", brLenTotal: "0.7209" },
  { geneTranscript: "NM_032279.4", parsimonySitesP: "0.214046822742475", constantSitesP: "0.653567447045708", distinctSitesP: "0.327759197324415", brLenTotal: "0.7419" },
  { geneTranscript: "NM_032286.3", parsimonySitesP: "0.153086419753086", constantSitesP: "0.775308641975309", distinctSitesP: "0.251851851851852", brLenTotal: "0.5156" },
  { geneTranscript: "NM_032303.5", parsimonySitesP: "0.181020733652313", constantSitesP: "0.711323763955343", distinctSitesP: "0.301435406698565", brLenTotal: "0.6252" },
  { geneTranscript: "NM_032310.5", parsimonySitesP: "0.191256830601093", constantSitesP: "0.657559198542805", distinctSitesP: "0.342440801457195", brLenTotal: "0.8677" },
  { geneTranscript: "NM_032313.4", parsimonySitesP: "0.296084049665712", constantSitesP: "0.527698185291309", distinctSitesP: "0.469436485195798", brLenTotal: "1.1876" },
  { geneTranscript: "NM_032324.3", parsimonySitesP: "0.243859649122807", constantSitesP: "0.615789473684211", distinctSitesP: "0.429824561403509", brLenTotal: "1.0198" },
  { geneTranscript: "NM_032329.6", parsimonySitesP: "0.183333333333333", constantSitesP: "0.7375", distinctSitesP: "0.263888888888889", brLenTotal: "1.0711" },
  { geneTranscript: "NM_032331.4", parsimonySitesP: "0.25359477124183", constantSitesP: "0.611764705882353", distinctSitesP: "0.363398692810458", brLenTotal: "0.8348" },
  { geneTranscript: "NM_032334.3", parsimonySitesP: "0.168674698795181", constantSitesP: "0.681392235609103", distinctSitesP: "0.314591700133869", brLenTotal: "0.6045" },
  { geneTranscript: "NM_032338.4", parsimonySitesP: "0.281653746770026", constantSitesP: "0.501291989664083", distinctSitesP: "0.532299741602067", brLenTotal: "1.1506" },
  { geneTranscript: "NM_032346.2", parsimonySitesP: "0.236499068901304", constantSitesP: "0.573556797020484", distinctSitesP: "0.415270018621974", brLenTotal: "0.9394" },
  { geneTranscript: "NM_032369.4", parsimonySitesP: "0.245421245421245", constantSitesP: "0.62026862026862", distinctSitesP: "0.409035409035409", brLenTotal: "1.0379" },
  { geneTranscript: "NM_032383.5", parsimonySitesP: "0.193559096945551", constantSitesP: "0.665338645418327", distinctSitesP: "0.324701195219124", brLenTotal: "0.6850" },
  { geneTranscript: "NM_032411.3", parsimonySitesP: "0.207207207207207", constantSitesP: "0.628378378378378", distinctSitesP: "0.38963963963964", brLenTotal: "1.0272" },
  { geneTranscript: "NM_032414.3", parsimonySitesP: "0.250793650793651", constantSitesP: "0.574603174603175", distinctSitesP: "0.476190476190476", brLenTotal: "0.9850" },
  { geneTranscript: "NM_032424.3", parsimonySitesP: "0.191304347826087", constantSitesP: "0.705314009661836", distinctSitesP: "0.269565217391304", brLenTotal: "0.7065" },
  { geneTranscript: "NM_032437.4", parsimonySitesP: "0.181240063593005", constantSitesP: "0.67196608373079", distinctSitesP: "0.344462109167992", brLenTotal: "0.6023" },
  { geneTranscript: "NM_032447.5", parsimonySitesP: "0.3374866500534", constantSitesP: "0.493532692535897", distinctSitesP: "0.424706301174795", brLenTotal: "1.5719" },
  { geneTranscript: "NM_032451.2", parsimonySitesP: "0.262371615312792", constantSitesP: "0.611111111111111", distinctSitesP: "0.375350140056022", brLenTotal: "1.2346" },
  { geneTranscript: "NM_032453.2", parsimonySitesP: "0.444444444444444", constantSitesP: "0.362889983579639", distinctSitesP: "0.575807334428024", brLenTotal: "1.9290" },
  { geneTranscript: "NM_032476.4", parsimonySitesP: "0.232", constantSitesP: "0.626666666666667", distinctSitesP: "0.397333333333333", brLenTotal: "0.8807" },
  { geneTranscript: "NM_032482.3", parsimonySitesP: "0.266319670353502", constantSitesP: "0.626978963348514", distinctSitesP: "0.32183908045977", brLenTotal: "1.3476" },
  { geneTranscript: "NM_032489.3", parsimonySitesP: "0.264579496623696", constantSitesP: "0.585635359116022", distinctSitesP: "0.398403928790669", brLenTotal: "1.1308" },
  { geneTranscript: "NM_032494.3", parsimonySitesP: "0.211912943871707", constantSitesP: "0.652920962199313", distinctSitesP: "0.384879725085911", brLenTotal: "0.6896" },
  { geneTranscript: "NM_032509.4", parsimonySitesP: "0.168888888888889", constantSitesP: "0.712222222222222", distinctSitesP: "0.306666666666667", brLenTotal: "0.6119" },
  { geneTranscript: "NM_032517.6", parsimonySitesP: "0.261261261261261", constantSitesP: "0.574324324324324", distinctSitesP: "0.475225225225225", brLenTotal: "1.2331" },
  { geneTranscript: "NM_032521.3", parsimonySitesP: "0.168458781362007", constantSitesP: "0.700716845878136", distinctSitesP: "0.241039426523297", brLenTotal: "0.9104" },
  { geneTranscript: "NM_032551.5", parsimonySitesP: "0.329145728643216", constantSitesP: "0.469011725293132", distinctSitesP: "0.553601340033501", brLenTotal: "1.4024" },
  { geneTranscript: "NM_032553.3", parsimonySitesP: "0.184184184184184", constantSitesP: "0.687687687687688", distinctSitesP: "0.298298298298298", brLenTotal: "0.5876" },
  { geneTranscript: "NM_032559.5", parsimonySitesP: "0.345220406141654", constantSitesP: "0.45864289252105", distinctSitesP: "0.503219415552254", brLenTotal: "1.3447" },
  { geneTranscript: "NM_032561.5", parsimonySitesP: "0.248847926267281", constantSitesP: "0.536098310291859", distinctSitesP: "0.443932411674347", brLenTotal: "0.8665" },
  { geneTranscript: "NM_032565.5", parsimonySitesP: "0.263754045307443", constantSitesP: "0.574433656957929", distinctSitesP: "0.47411003236246", brLenTotal: "0.9536" },
  { geneTranscript: "NM_032572.4", parsimonySitesP: "0.373931623931624", constantSitesP: "0.408119658119658", distinctSitesP: "0.587606837606838", brLenTotal: "1.6002" },
  { geneTranscript: "NM_032588.4", parsimonySitesP: "0.183191690273843", constantSitesP: "0.696883852691218", distinctSitesP: "0.291784702549575", brLenTotal: "0.7189" },
  { geneTranscript: "NM_032592.4", parsimonySitesP: "0.25748502994012", constantSitesP: "0.592149035262808", distinctSitesP: "0.400532268795742", brLenTotal: "0.9922" },
  { geneTranscript: "NM_032595.5", parsimonySitesP: "0.152998776009792", constantSitesP: "0.755609955120359", distinctSitesP: "0.215830273357813", brLenTotal: "0.5928" },
  { geneTranscript: "NM_032596.4", parsimonySitesP: "0.241730279898219", constantSitesP: "0.581424936386768", distinctSitesP: "0.416030534351145", brLenTotal: "0.7964" },
  { geneTranscript: "NM_032627.5", parsimonySitesP: "0.211255411255411", constantSitesP: "0.669264069264069", distinctSitesP: "0.357575757575758", brLenTotal: "0.9886" },
  { geneTranscript: "NM_032638.5", parsimonySitesP: "0.305555555555556", constantSitesP: "0.585416666666667", distinctSitesP: "0.4", brLenTotal: "1.5075" },
  { geneTranscript: "NM_032709.3", parsimonySitesP: "0.242111302352266", constantSitesP: "0.623063683304647", distinctSitesP: "0.359150889271371", brLenTotal: "1.0236" },
  { geneTranscript: "NM_032717.5", parsimonySitesP: "0.210445468509985", constantSitesP: "0.682027649769585", distinctSitesP: "0.336405529953917", brLenTotal: "0.7750" },
  { geneTranscript: "NM_032718.5", parsimonySitesP: "0.29957805907173", constantSitesP: "0.537271448663854", distinctSitesP: "0.483122362869198", brLenTotal: "1.3972" },
  { geneTranscript: "NM_032726.4", parsimonySitesP: "0.205161854768154", constantSitesP: "0.674540682414698", distinctSitesP: "0.278652668416448", brLenTotal: "0.6384" },
  { geneTranscript: "NM_032727.4", parsimonySitesP: "0.303941215764863", constantSitesP: "0.525050100200401", distinctSitesP: "0.438209752839011", brLenTotal: "1.4230" },
  { geneTranscript: "NM_032731.4", parsimonySitesP: "0.205962059620596", constantSitesP: "0.604336043360434", distinctSitesP: "0.40650406504065", brLenTotal: "0.7567" },
  { geneTranscript: "NM_032738.4", parsimonySitesP: "0.312906220984215", constantSitesP: "0.515320334261838", distinctSitesP: "0.4661095636026", brLenTotal: "1.1506" },
  { geneTranscript: "NM_032783.5", parsimonySitesP: "0.20393811533052", constantSitesP: "0.673699015471167", distinctSitesP: "0.347398030942335", brLenTotal: "0.8182" },
  { geneTranscript: "NM_032784.5", parsimonySitesP: "0.10906862745098", constantSitesP: "0.79656862745098", distinctSitesP: "0.207107843137255", brLenTotal: "0.3312" },
  { geneTranscript: "NM_032789.5", parsimonySitesP: "0.367154471544715", constantSitesP: "0.450731707317073", distinctSitesP: "0.486178861788618", brLenTotal: "1.5118" },
  { geneTranscript: "NM_032800.3", parsimonySitesP: "0.216106014271152", constantSitesP: "0.625891946992864", distinctSitesP: "0.383282364933741", brLenTotal: "0.9556" },
  { geneTranscript: "NM_032802.4", parsimonySitesP: "0.176282051282051", constantSitesP: "0.695512820512821", distinctSitesP: "0.296153846153846", brLenTotal: "0.6029" },
  { geneTranscript: "NM_032805.3", parsimonySitesP: "0.28974358974359", constantSitesP: "0.548290598290598", distinctSitesP: "0.408974358974359", brLenTotal: "1.2205" },
  { geneTranscript: "NM_032811.3", parsimonySitesP: "0.151662611516626", constantSitesP: "0.698296836982968", distinctSitesP: "0.268450932684509", brLenTotal: "0.6486" },
  { geneTranscript: "NM_032812.9", parsimonySitesP: "0.184625078764965", constantSitesP: "0.719596723377442", distinctSitesP: "0.278512917454316", brLenTotal: "0.6454" },
  { geneTranscript: "NM_032815.4", parsimonySitesP: "0.289578361177407", constantSitesP: "0.562450278440732", distinctSitesP: "0.424821002386635", brLenTotal: "1.0071" },
  { geneTranscript: "NM_032847.3", parsimonySitesP: "0.233333333333333", constantSitesP: "0.615789473684211", distinctSitesP: "0.383333333333333", brLenTotal: "0.9166" },
  { geneTranscript: "NM_032856.5", parsimonySitesP: "0.272486772486772", constantSitesP: "0.556437389770723", distinctSitesP: "0.411816578483245", brLenTotal: "0.9817" },
  { geneTranscript: "NM_032857.5", parsimonySitesP: "0.170627666057282", constantSitesP: "0.702620353443023", distinctSitesP: "0.260207190737355", brLenTotal: "0.5779" },
  { geneTranscript: "NM_032858.3", parsimonySitesP: "0.162058371735791", constantSitesP: "0.707373271889401", distinctSitesP: "0.27726574500768", brLenTotal: "0.5256" },
  { geneTranscript: "NM_032860.5", parsimonySitesP: "0.203508771929825", constantSitesP: "0.648421052631579", distinctSitesP: "0.327017543859649", brLenTotal: "0.7201" },
  { geneTranscript: "NM_032861.4", parsimonySitesP: "0.182466870540265", constantSitesP: "0.701834862385321", distinctSitesP: "0.275739041794088", brLenTotal: "0.6052" },
  { geneTranscript: "NM_032873.5", parsimonySitesP: "0.150487930148947", constantSitesP: "0.770929635336415", distinctSitesP: "0.218798151001541", brLenTotal: "0.6677" },
  { geneTranscript: "NM_032888.4", parsimonySitesP: "0.224731182795699", constantSitesP: "0.63494623655914", distinctSitesP: "0.322043010752688", brLenTotal: "0.9337" },
  { geneTranscript: "NM_032900.6", parsimonySitesP: "0.180161943319838", constantSitesP: "0.678812415654521", distinctSitesP: "0.301619433198381", brLenTotal: "0.6117" },
  { geneTranscript: "NM_032906.5", parsimonySitesP: "0.39766081871345", constantSitesP: "0.391812865497076", distinctSitesP: "0.628654970760234", brLenTotal: "1.4369" },
  { geneTranscript: "NM_032928.4", parsimonySitesP: "0.296296296296296", constantSitesP: "0.552469135802469", distinctSitesP: "0.469135802469136", brLenTotal: "1.0497" },
  { geneTranscript: "NM_032930.3", parsimonySitesP: "0.191011235955056", constantSitesP: "0.679151061173533", distinctSitesP: "0.31585518102372", brLenTotal: "0.6626" },
  { geneTranscript: "NM_032935.3", parsimonySitesP: "0.21505376344086", constantSitesP: "0.67741935483871", distinctSitesP: "0.387096774193548", brLenTotal: "0.7692" },
  { geneTranscript: "NM_032940.3", parsimonySitesP: "0.157575757575758", constantSitesP: "0.741818181818182", distinctSitesP: "0.256969696969697", brLenTotal: "0.6962" },
  { geneTranscript: "NM_032959.7", parsimonySitesP: "0.150724637681159", constantSitesP: "0.742028985507246", distinctSitesP: "0.292753623188406", brLenTotal: "0.7010" },
  { geneTranscript: "NM_032968.5", parsimonySitesP: "0.370947785201683", constantSitesP: "0.530809205642168", distinctSitesP: "0.367235832714675", brLenTotal: "1.3146" },
  { geneTranscript: "NM_033045.4", parsimonySitesP: "0.312222222222222", constantSitesP: "0.520555555555556", distinctSitesP: "0.455", brLenTotal: "1.3053" },
  { geneTranscript: "NM_033053.3", parsimonySitesP: "0.255208333333333", constantSitesP: "0.510416666666667", distinctSitesP: "0.461805555555556", brLenTotal: "1.0437" },
  { geneTranscript: "NM_033066.3", parsimonySitesP: "0.180533751962323", constantSitesP: "0.69701726844584", distinctSitesP: "0.295133437990581", brLenTotal: "0.6759" },
  { geneTranscript: "NM_033070.3", parsimonySitesP: "0.306540583136328", constantSitesP: "0.529550827423168", distinctSitesP: "0.450748620961387", brLenTotal: "1.1994" },
  { geneTranscript: "NM_033100.4", parsimonySitesP: "0.247574699262709", constantSitesP: "0.612727978269305", distinctSitesP: "0.351183546759798", brLenTotal: "1.0629" },
  { geneTranscript: "NM_033101.4", parsimonySitesP: "0.247346072186837", constantSitesP: "0.593418259023355", distinctSitesP: "0.403397027600849", brLenTotal: "0.8821" },
  { geneTranscript: "NM_033103.5", parsimonySitesP: "0.258503401360544", constantSitesP: "0.620991253644315", distinctSitesP: "0.382410106899903", brLenTotal: "1.0874" },
  { geneTranscript: "NM_033107.4", parsimonySitesP: "0.188630490956072", constantSitesP: "0.674418604651163", distinctSitesP: "0.349698535745047", brLenTotal: "0.6513" },
  { geneTranscript: "NM_033109.5", parsimonySitesP: "0.137931034482759", constantSitesP: "0.75436355896126", distinctSitesP: "0.240527884206045", brLenTotal: "0.4812" },
  { geneTranscript: "NM_033163.5", parsimonySitesP: "0.118852459016393", constantSitesP: "0.775956284153005", distinctSitesP: "0.200819672131148", brLenTotal: "0.5233" },
  { geneTranscript: "NM_033223.5", parsimonySitesP: "0.2005710206995", constantSitesP: "0.708779443254818", distinctSitesP: "0.281227694503926", brLenTotal: "0.9059" },
  { geneTranscript: "NM_033238.3", parsimonySitesP: "0.3140589569161", constantSitesP: "0.517006802721088", distinctSitesP: "0.435752078609221", brLenTotal: "1.3110" },
  { geneTranscript: "NM_033257.4", parsimonySitesP: "0.284848484848485", constantSitesP: "0.531818181818182", distinctSitesP: "0.448484848484848", brLenTotal: "1.3627" },
  { geneTranscript: "NM_033260.4", parsimonySitesP: "0.283705541770058", constantSitesP: "0.462365591397849", distinctSitesP: "0.542597187758478", brLenTotal: "1.6286" },
  { geneTranscript: "NM_033276.4", parsimonySitesP: "0.20189701897019", constantSitesP: "0.659891598915989", distinctSitesP: "0.360433604336043", brLenTotal: "0.7041" },
  { geneTranscript: "NM_033281.6", parsimonySitesP: "0.132686084142395", constantSitesP: "0.679611650485437", distinctSitesP: "0.339805825242718", brLenTotal: "0.5013" },
  { geneTranscript: "NM_033364.4", parsimonySitesP: "0.255541069100391", constantSitesP: "0.595393307257714", distinctSitesP: "0.382877009995654", brLenTotal: "0.8598" },
  { geneTranscript: "NM_033305.3", parsimonySitesP: "0.168031926065953", constantSitesP: "0.708674648183155", distinctSitesP: "0.272211720226843", brLenTotal: "0.5759" },
  { geneTranscript: "NM_033390.2", parsimonySitesP: "0.163457908644772", constantSitesP: "0.738014345035863", distinctSitesP: "0.22725556813892", brLenTotal: "0.5459" },
  { geneTranscript: "NM_033394.3", parsimonySitesP: "0.203833064660577", constantSitesP: "0.6912054451012", distinctSitesP: "0.254343542898083", brLenTotal: "0.7941" },
  { geneTranscript: "NM_033409.4", parsimonySitesP: "0.285714285714286", constantSitesP: "0.597014925373134", distinctSitesP: "0.405117270788913", brLenTotal: "1.1428" },
  { geneTranscript: "NM_033427.3", parsimonySitesP: "0.203648025656444", constantSitesP: "0.669873722188815", distinctSitesP: "0.293846462216877", brLenTotal: "0.6915" },
  { geneTranscript: "NM_033429.3", parsimonySitesP: "0.189542483660131", constantSitesP: "0.671023965141612", distinctSitesP: "0.361655773420479", brLenTotal: "0.9079" },
  { geneTranscript: "NM_033438.4", parsimonySitesP: "0.356401384083045", constantSitesP: "0.393310265282584", distinctSitesP: "0.614763552479815", brLenTotal: "1.4861" },
  { geneTranscript: "NM_033440.3", parsimonySitesP: "0.372986369268897", constantSitesP: "0.503097893432466", distinctSitesP: "0.513011152416357", brLenTotal: "1.7459" },
  { geneTranscript: "NM_033513.3", parsimonySitesP: "0.258620689655172", constantSitesP: "0.631034482758621", distinctSitesP: "0.337931034482759", brLenTotal: "0.9855" },
  { geneTranscript: "NM_033518.4", parsimonySitesP: "0.216101694915254", constantSitesP: "0.612994350282486", distinctSitesP: "0.352401129943503", brLenTotal: "0.8210" },
  { geneTranscript: "NM_033540.3", parsimonySitesP: "0.195231668915879", constantSitesP: "0.661718398560504", distinctSitesP: "0.294197031039136", brLenTotal: "0.6620" },
  { geneTranscript: "NM_033553.3", parsimonySitesP: "0.333333333333333", constantSitesP: "0.478260869565217", distinctSitesP: "0.588405797101449", brLenTotal: "1.1827" },
  { geneTranscript: "NM_033554.3", parsimonySitesP: "0.433333333333333", constantSitesP: "0.393589743589744", distinctSitesP: "0.611538461538462", brLenTotal: "2.2300" },
  { geneTranscript: "NM_033656.4", parsimonySitesP: "0.163067430586161", constantSitesP: "0.715733803437638", distinctSitesP: "0.255913030703687", brLenTotal: "0.5644" },
  { geneTranscript: "NM_052818.3", parsimonySitesP: "0.122085048010974", constantSitesP: "0.779149519890261", distinctSitesP: "0.244170096021948", brLenTotal: "0.4266" },
  { geneTranscript: "NM_052831.3", parsimonySitesP: "0.228070175438596", constantSitesP: "0.644736842105263", distinctSitesP: "0.384502923976608", brLenTotal: "0.8035" },
  { geneTranscript: "NM_052832.4", parsimonySitesP: "0.176829268292683", constantSitesP: "0.689532520325203", distinctSitesP: "0.294715447154472", brLenTotal: "0.6632" },
  { geneTranscript: "NM_052846.2", parsimonySitesP: "0.254134029590949", constantSitesP: "0.525239338555265", distinctSitesP: "0.378590078328982", brLenTotal: "1.2082" },
  { geneTranscript: "NM_052847.3", parsimonySitesP: "0.205882352941176", constantSitesP: "0.661764705882353", distinctSitesP: "0.352941176470588", brLenTotal: "0.8341" },
  { geneTranscript: "NM_052850.4", parsimonySitesP: "0.337837837837838", constantSitesP: "0.468468468468468", distinctSitesP: "0.512012012012012", brLenTotal: "1.2451" },
  { geneTranscript: "NM_052853.4", parsimonySitesP: "0.260915867944622", constantSitesP: "0.60223642172524", distinctSitesP: "0.39297124600639", brLenTotal: "1.0724" },
  { geneTranscript: "NM_052857.4", parsimonySitesP: "0.228494623655914", constantSitesP: "0.640681003584229", distinctSitesP: "0.344982078853047", brLenTotal: "0.7100" },
  { geneTranscript: "NM_052865.4", parsimonySitesP: "0.244186046511628", constantSitesP: "0.580426356589147", distinctSitesP: "0.421511627906977", brLenTotal: "0.9173" },
  { geneTranscript: "NM_052879.5", parsimonySitesP: "0.174953959484346", constantSitesP: "0.632596685082873", distinctSitesP: "0.31767955801105", brLenTotal: "0.6605" },
  { geneTranscript: "NM_052880.5", parsimonySitesP: "0.249683143219265", constantSitesP: "0.599493029150824", distinctSitesP: "0.376425855513308", brLenTotal: "0.8190" },
  { geneTranscript: "NM_052886.3", parsimonySitesP: "0.181818181818182", constantSitesP: "0.715909090909091", distinctSitesP: "0.295454545454545", brLenTotal: "0.6905" },
  { geneTranscript: "NM_052891.3", parsimonySitesP: "0.304007820136852", constantSitesP: "0.495601173020528", distinctSitesP: "0.497556207233627", brLenTotal: "1.3068" },
  { geneTranscript: "NM_052902.4", parsimonySitesP: "0.282781862745098", constantSitesP: "0.555453431372549", distinctSitesP: "0.400735294117647", brLenTotal: "1.0337" },
  { geneTranscript: "NM_052903.6", parsimonySitesP: "0.215169270833333", constantSitesP: "0.623046875", distinctSitesP: "0.344401041666667", brLenTotal: "0.8910" },
  { geneTranscript: "NM_052911.3", parsimonySitesP: "0.192063492063492", constantSitesP: "0.647619047619048", distinctSitesP: "0.330555555555556", brLenTotal: "0.6209" },
  { geneTranscript: "NM_052924.3", parsimonySitesP: "0.315422885572139", constantSitesP: "0.546766169154229", distinctSitesP: "0.449253731343284", brLenTotal: "1.6748" },
  { geneTranscript: "NM_052945.4", parsimonySitesP: "0.365942028985507", constantSitesP: "0.483695652173913", distinctSitesP: "0.528985507246377", brLenTotal: "1.3329" },
  { geneTranscript: "NM_052955.3", parsimonySitesP: "0.257276995305164", constantSitesP: "0.576995305164319", distinctSitesP: "0.385915492957746", brLenTotal: "0.9584" },
  { geneTranscript: "NM_052958.4", parsimonySitesP: "0.198265179677819", constantSitesP: "0.677199504337051", distinctSitesP: "0.332094175960347", brLenTotal: "0.8268" },
  { geneTranscript: "NM_052959.3", parsimonySitesP: "0.212585034013605", constantSitesP: "0.620748299319728", distinctSitesP: "0.312074829931973", brLenTotal: "0.9028" },
  { geneTranscript: "NM_053039.2", parsimonySitesP: "0.405797101449275", constantSitesP: "0.431001890359168", distinctSitesP: "0.560176433522369", brLenTotal: "2.5370" },
  { geneTranscript: "NM_053043.3", parsimonySitesP: "0.22022792022792", constantSitesP: "0.675783475783476", distinctSitesP: "0.306267806267806", brLenTotal: "0.9066" },
  { geneTranscript: "NM_053044.5", parsimonySitesP: "0.224429727740986", constantSitesP: "0.671081677704194", distinctSitesP: "0.320088300220751", brLenTotal: "1.0724" },
  { geneTranscript: "NM_053050.5", parsimonySitesP: "0.339285714285714", constantSitesP: "0.547619047619048", distinctSitesP: "0.470238095238095", brLenTotal: "1.0325" },
  { geneTranscript: "NM_053274.3", parsimonySitesP: "0.153759820426487", constantSitesP: "0.698092031425365", distinctSitesP: "0.295735129068462", brLenTotal: "0.5401" },
  { geneTranscript: "NM_053276.4", parsimonySitesP: "0.224627224627225", constantSitesP: "0.648869648869649", distinctSitesP: "0.353054353054353", brLenTotal: "0.8934" },
  { geneTranscript: "NM_053285.2", parsimonySitesP: "0.254385964912281", constantSitesP: "0.605263157894737", distinctSitesP: "0.409090909090909", brLenTotal: "0.9505" },
  { geneTranscript: "NM_054113.4", parsimonySitesP: "0.213903743315508", constantSitesP: "0.698752228163993", distinctSitesP: "0.336898395721925", brLenTotal: "1.0843" },
  { geneTranscript: "NM_057091.3", parsimonySitesP: "0.259090909090909", constantSitesP: "0.540909090909091", distinctSitesP: "0.463636363636364", brLenTotal: "1.0208" },
  { geneTranscript: "NM_057095.3", parsimonySitesP: "0.338634857521537", constantSitesP: "0.517561298873426", distinctSitesP: "0.499668654738237", brLenTotal: "1.6778" },
  { geneTranscript: "NM_058167.3", parsimonySitesP: "0.199485199485199", constantSitesP: "0.680823680823681", distinctSitesP: "0.298584298584299", brLenTotal: "0.8105" },
  { geneTranscript: "NM_058237.2", parsimonySitesP: "0.134784268804887", constantSitesP: "0.751431844215349", distinctSitesP: "0.221840397098129", brLenTotal: "0.4510" },
  { geneTranscript: "NM_058179.4", parsimonySitesP: "0.191891891891892", constantSitesP: "0.645945945945946", distinctSitesP: "0.311711711711712", brLenTotal: "0.8113" },
  { geneTranscript: "NM_078468.3", parsimonySitesP: "0.237791932059448", constantSitesP: "0.598726114649682", distinctSitesP: "0.41932059447983", brLenTotal: "0.8695" },
  { geneTranscript: "NM_078473.3", parsimonySitesP: "0.172897196261682", constantSitesP: "0.724299065420561", distinctSitesP: "0.266355140186916", brLenTotal: "0.6434" },
  { geneTranscript: "NM_080390.4", parsimonySitesP: "0.330396475770925", constantSitesP: "0.439060205580029", distinctSitesP: "0.568281938325991", brLenTotal: "1.9229" },
  { geneTranscript: "NM_080429.3", parsimonySitesP: "0.246954595791805", constantSitesP: "0.614617940199336", distinctSitesP: "0.378737541528239", brLenTotal: "0.7955" },
  { geneTranscript: "NM_080473.5", parsimonySitesP: "0.326616288832914", constantSitesP: "0.534844668345928", distinctSitesP: "0.481947942905122", brLenTotal: "1.5379" },
  { geneTranscript: "NM_080489.5", parsimonySitesP: "0.248858447488584", constantSitesP: "0.613013698630137", distinctSitesP: "0.397260273972603", brLenTotal: "0.9798" },
  { geneTranscript: "NM_080628.3", parsimonySitesP: "0.272868217054264", constantSitesP: "0.567441860465116", distinctSitesP: "0.443410852713178", brLenTotal: "0.9364" },
  { geneTranscript: "NM_080664.3", parsimonySitesP: "0.19047619047619", constantSitesP: "0.680555555555556", distinctSitesP: "0.349206349206349", brLenTotal: "0.6340" },
  { geneTranscript: "NM_080666.4", parsimonySitesP: "0.239448751076658", constantSitesP: "0.535745047372954", distinctSitesP: "0.445305770887166", brLenTotal: "0.9115" },
  { geneTranscript: "NM_080668.4", parsimonySitesP: "0.318783068783069", constantSitesP: "0.525132275132275", distinctSitesP: "0.503968253968254", brLenTotal: "1.0397" },
  { geneTranscript: "NM_080672.5", parsimonySitesP: "0.223017292784735", constantSitesP: "0.675014907573047", distinctSitesP: "0.329755515802027", brLenTotal: "0.9653" },
  { geneTranscript: "NM_080675.4", parsimonySitesP: "0.277044854881266", constantSitesP: "0.577836411609499", distinctSitesP: "0.434476693051891", brLenTotal: "1.0248" },
  { geneTranscript: "NM_080742.3", parsimonySitesP: "0.160990712074303", constantSitesP: "0.711042311661507", distinctSitesP: "0.268317853457172", brLenTotal: "0.7394" },
  { geneTranscript: "NM_080821.3", parsimonySitesP: "0.295138888888889", constantSitesP: "0.527777777777778", distinctSitesP: "0.517361111111111", brLenTotal: "1.0719" },
  { geneTranscript: "NM_080863.5", parsimonySitesP: "0.295069904341428", constantSitesP: "0.532008830022075", distinctSitesP: "0.402501839587932", brLenTotal: "1.3178" },
  { geneTranscript: "NM_080864.4", parsimonySitesP: "0.408450704225352", constantSitesP: "0.39906103286385", distinctSitesP: "0.661971830985915", brLenTotal: "1.6712" },
  { geneTranscript: "NM_130384.3", parsimonySitesP: "0.232616940581542", constantSitesP: "0.616519174041298", distinctSitesP: "0.333333333333333", brLenTotal: "0.8115" },
  { geneTranscript: "NM_130465.5", parsimonySitesP: "0.180344478216819", constantSitesP: "0.687943262411348", distinctSitesP: "0.309017223910841", brLenTotal: "0.7536" },
  { geneTranscript: "NM_130770.3", parsimonySitesP: "0.313944817300522", constantSitesP: "0.486950037285608", distinctSitesP: "0.488441461595824", brLenTotal: "1.3637" },
  { geneTranscript: "NM_130806.5", parsimonySitesP: "0.161803713527851", constantSitesP: "0.716622458001768", distinctSitesP: "0.285145888594164", brLenTotal: "0.5682" },
  { geneTranscript: "NM_130808.3", parsimonySitesP: "0.14721723518851", constantSitesP: "0.750448833034111", distinctSitesP: "0.210053859964093", brLenTotal: "0.8644" },
  { geneTranscript: "NM_130809.5", parsimonySitesP: "0.211985018726592", constantSitesP: "0.649438202247191", distinctSitesP: "0.332584269662921", brLenTotal: "0.8167" },
  { geneTranscript: "NM_130810.4", parsimonySitesP: "0.176190476190476", constantSitesP: "0.668253968253968", distinctSitesP: "0.305555555555556", brLenTotal: "0.6382" },
  { geneTranscript: "NM_130839.5", parsimonySitesP: "0.148700305810398", constantSitesP: "0.716360856269113", distinctSitesP: "0.251529051987768", brLenTotal: "0.5696" },
  { geneTranscript: "NM_130847.3", parsimonySitesP: "0.197350069735007", constantSitesP: "0.679916317991632", distinctSitesP: "0.27231520223152", brLenTotal: "0.9280" },
  { geneTranscript: "NM_130849.4", parsimonySitesP: "0.33693972179289", constantSitesP: "0.510561566202988", distinctSitesP: "0.478104070066976", brLenTotal: "1.4251" },
  { geneTranscript: "NM_130906.3", parsimonySitesP: "0.101449275362319", constantSitesP: "0.782608695652174", distinctSitesP: "0.244306418219462", brLenTotal: "0.3667" },
  { geneTranscript: "NM_133181.4", parsimonySitesP: "0.328274311410905", constantSitesP: "0.505902192242833", distinctSitesP: "0.500843170320405", brLenTotal: "1.1841" },
  { geneTranscript: "NM_133338.3", parsimonySitesP: "0.191542288557214", constantSitesP: "0.638805970149254", distinctSitesP: "0.353731343283582", brLenTotal: "0.7112" },
  { geneTranscript: "NM_133448.3", parsimonySitesP: "0.35062177737337", constantSitesP: "0.542917804064301", distinctSitesP: "0.43190779496512", brLenTotal: "2.0787" },
  { geneTranscript: "NM_133462.4", parsimonySitesP: "0.160606060606061", constantSitesP: "0.698268398268398", distinctSitesP: "0.277056277056277", brLenTotal: "0.5992" },
  { geneTranscript: "NM_133492.3", parsimonySitesP: "0.287878787878788", constantSitesP: "0.579545454545455", distinctSitesP: "0.414141414141414", brLenTotal: "1.3125" },
  { geneTranscript: "NM_133494.3", parsimonySitesP: "0.146799116997792", constantSitesP: "0.763796909492274", distinctSitesP: "0.246136865342163", brLenTotal: "0.5500" },
  { geneTranscript: "NM_133638.6", parsimonySitesP: "0.201428964001099", constantSitesP: "0.675460291288816", distinctSitesP: "0.283044792525419", brLenTotal: "0.6644" },
  { geneTranscript: "NM_138278.4", parsimonySitesP: "0.215686274509804", constantSitesP: "0.61437908496732", distinctSitesP: "0.364145658263305", brLenTotal: "0.7527" },
  { geneTranscript: "NM_138335.3", parsimonySitesP: "0.142512077294686", constantSitesP: "0.777777777777778", distinctSitesP: "0.219806763285024", brLenTotal: "0.5398" },
  { geneTranscript: "NM_138346.3", parsimonySitesP: "0.183491062039958", constantSitesP: "0.679284963196635", distinctSitesP: "0.278654048370137", brLenTotal: "0.8507" },
  { geneTranscript: "NM_138369.3", parsimonySitesP: "0.174774774774775", constantSitesP: "0.56036036036036", distinctSitesP: "0.412612612612613", brLenTotal: "0.8383" },
  { geneTranscript: "NM_138381.5", parsimonySitesP: "0.19017094017094", constantSitesP: "0.669871794871795", distinctSitesP: "0.346153846153846", brLenTotal: "0.8466" },
  { geneTranscript: "NM_138382.3", parsimonySitesP: "0.357615894039735", constantSitesP: "0.456953642384106", distinctSitesP: "0.58719646799117", brLenTotal: "1.2891" },
  { geneTranscript: "NM_138384.4", parsimonySitesP: "0.278443113772455", constantSitesP: "0.589820359281437", distinctSitesP: "0.408183632734531", brLenTotal: "1.1769" },
  { geneTranscript: "NM_138389.4", parsimonySitesP: "0.243931320307874", constantSitesP: "0.606867969212552", distinctSitesP: "0.366489046773239", brLenTotal: "0.9950" },
  { geneTranscript: "NM_138403.5", parsimonySitesP: "0.221238938053097", constantSitesP: "0.68141592920354", distinctSitesP: "0.334808259587021", brLenTotal: "1.8060" },
  { geneTranscript: "NM_138410.4", parsimonySitesP: "0.188571428571429", constantSitesP: "0.60952380952381", distinctSitesP: "0.392380952380952", brLenTotal: "0.8193" },
  { geneTranscript: "NM_138421.3", parsimonySitesP: "0.165963431786217", constantSitesP: "0.681434599156118", distinctSitesP: "0.293248945147679", brLenTotal: "0.5757" },
  { geneTranscript: "NM_138426.4", parsimonySitesP: "0.140158439975625", constantSitesP: "0.741011578305911", distinctSitesP: "0.249238269347959", brLenTotal: "0.5173" },
  { geneTranscript: "NM_138454.2", parsimonySitesP: "0.278301886792453", constantSitesP: "0.599056603773585", distinctSitesP: "0.411949685534591", brLenTotal: "1.0885" },
  { geneTranscript: "NM_138462.3", parsimonySitesP: "0.176211453744493", constantSitesP: "0.73568281938326", distinctSitesP: "0.274596182085169", brLenTotal: "0.6629" },
  { geneTranscript: "NM_138471.3", parsimonySitesP: "0.269466316710411", constantSitesP: "0.601049868766404", distinctSitesP: "0.3665791776028", brLenTotal: "1.1550" },
  { geneTranscript: "NM_138476.4", parsimonySitesP: "0.21780303030303", constantSitesP: "0.636363636363636", distinctSitesP: "0.376893939393939", brLenTotal: "0.7699" },
  { geneTranscript: "NM_138694.4", parsimonySitesP: "0.27262313860252", constantSitesP: "0.552691867124857", distinctSitesP: "0.402716413025691", brLenTotal: "1.0262" },
  { geneTranscript: "NM_138713.4", parsimonySitesP: "0.12029266193243", constantSitesP: "0.789326447170217", distinctSitesP: "0.16935657413385", brLenTotal: "0.3895" },
  { geneTranscript: "NM_138769.3", parsimonySitesP: "0.2680690399137", constantSitesP: "0.599784250269687", distinctSitesP: "0.388888888888889", brLenTotal: "1.4216" },
  { geneTranscript: "NM_138774.4", parsimonySitesP: "0.235074626865672", constantSitesP: "0.640547263681592", distinctSitesP: "0.364427860696517", brLenTotal: "1.0375" },
  { geneTranscript: "NM_138775.3", parsimonySitesP: "0.236947791164659", constantSitesP: "0.57781124497992", distinctSitesP: "0.402610441767068", brLenTotal: "0.9032" },
  { geneTranscript: "NM_138777.5", parsimonySitesP: "0.185750636132316", constantSitesP: "0.670483460559796", distinctSitesP: "0.333333333333333", brLenTotal: "0.6075" },
  { geneTranscript: "NM_138780.3", parsimonySitesP: "0.177625570776256", constantSitesP: "0.672146118721461", distinctSitesP: "0.301369863013699", brLenTotal: "0.6251" },
  { geneTranscript: "NM_138786.4", parsimonySitesP: "0.207296849087894", constantSitesP: "0.616915422885572", distinctSitesP: "0.384742951907131", brLenTotal: "0.7773" },
  { geneTranscript: "NM_138789.4", parsimonySitesP: "0.205291005291005", constantSitesP: "0.608465608465608", distinctSitesP: "0.412698412698413", brLenTotal: "0.6714" },
  { geneTranscript: "NM_138799.4", parsimonySitesP: "0.146794871794872", constantSitesP: "0.736538461538462", distinctSitesP: "0.262179487179487", brLenTotal: "0.4759" },
  { geneTranscript: "NM_138805.3", parsimonySitesP: "0.331845238095238", constantSitesP: "0.541666666666667", distinctSitesP: "0.485119047619048", brLenTotal: "1.3895" },
  { geneTranscript: "NM_138820.4", parsimonySitesP: "0.29559748427673", constantSitesP: "0.581761006289308", distinctSitesP: "0.459119496855346", brLenTotal: "0.9547" },
  { geneTranscript: "NM_139056.4", parsimonySitesP: "0.252450980392157", constantSitesP: "0.607570806100218", distinctSitesP: "0.363834422657952", brLenTotal: "1.1348" },
  { geneTranscript: "NM_139058.3", parsimonySitesP: "0.148279952550415", constantSitesP: "0.765124555160142", distinctSitesP: "0.252075919335706", brLenTotal: "0.7214" },
  { geneTranscript: "NM_139169.5", parsimonySitesP: "0.170009551098376", constantSitesP: "0.677172874880611", distinctSitesP: "0.306590257879656", brLenTotal: "0.6458" },
  { geneTranscript: "NM_139175.2", parsimonySitesP: "0.310283687943262", constantSitesP: "0.513297872340426", distinctSitesP: "0.485815602836879", brLenTotal: "1.1779" },
  { geneTranscript: "NM_139242.4", parsimonySitesP: "0.269065981148243", constantSitesP: "0.577549271636675", distinctSitesP: "0.401885175664096", brLenTotal: "0.9612" },
  { geneTranscript: "NM_139243.4", parsimonySitesP: "0.171875", constantSitesP: "0.6875", distinctSitesP: "0.298611111111111", brLenTotal: "0.5708" },
  { geneTranscript: "NM_139285.4", parsimonySitesP: "0.341666666666667", constantSitesP: "0.465151515151515", distinctSitesP: "0.498106060606061", brLenTotal: "1.3348" },
  { geneTranscript: "NM_139314.3", parsimonySitesP: "0.313628899835796", constantSitesP: "0.560755336617406", distinctSitesP: "0.445812807881773", brLenTotal: "1.2371" },
  { geneTranscript: "NM_139318.5", parsimonySitesP: "0.157219973009447", constantSitesP: "0.749662618083671", distinctSitesP: "0.209851551956815", brLenTotal: "0.6424" },
  { geneTranscript: "NM_144570.3", parsimonySitesP: "0.280701754385965", constantSitesP: "0.53859649122807", distinctSitesP: "0.480701754385965", brLenTotal: "1.1770" },
  { geneTranscript: "NM_144572.2", parsimonySitesP: "0.261336102457598", constantSitesP: "0.623052959501558", distinctSitesP: "0.371062651436483", brLenTotal: "1.0405" },
  { geneTranscript: "NM_144573.4", parsimonySitesP: "0.154074074074074", constantSitesP: "0.737777777777778", distinctSitesP: "0.244938271604938", brLenTotal: "0.5152" },
  { geneTranscript: "NM_144578.4", parsimonySitesP: "0.202721088435374", constantSitesP: "0.625850340136054", distinctSitesP: "0.341496598639456", brLenTotal: "0.8394" },
  { geneTranscript: "NM_144581.2", parsimonySitesP: "0.247645951035782", constantSitesP: "0.603578154425612", distinctSitesP: "0.409604519774011", brLenTotal: "0.9274" },
  { geneTranscript: "NM_144596.4", parsimonySitesP: "0.142394822006472", constantSitesP: "0.732038834951456", distinctSitesP: "0.262135922330097", brLenTotal: "0.5386" },
  { geneTranscript: "NM_144597.3", parsimonySitesP: "0.28322440087146", constantSitesP: "0.520697167755991", distinctSitesP: "0.4880174291939", brLenTotal: "1.1118" },
  { geneTranscript: "NM_144604.4", parsimonySitesP: "0.231199720181882", constantSitesP: "0.660720531654425", distinctSitesP: "0.313046519762155", brLenTotal: "1.1164" },
  { geneTranscript: "NM_144618.3", parsimonySitesP: "0.201636904761905", constantSitesP: "0.654761904761905", distinctSitesP: "0.340029761904762", brLenTotal: "0.6652" },
  { geneTranscript: "NM_144620.4", parsimonySitesP: "0.18407960199005", constantSitesP: "0.683582089552239", distinctSitesP: "0.296517412935323", brLenTotal: "0.6359" },
  { geneTranscript: "NM_144622.3", parsimonySitesP: "0.29969814575248", constantSitesP: "0.546356188012074", distinctSitesP: "0.411815437688659", brLenTotal: "1.0613" },
  { geneTranscript: "NM_144629.3", parsimonySitesP: "0.189620758483034", constantSitesP: "0.660013306719894", distinctSitesP: "0.322022621423819", brLenTotal: "0.6812" },
  { geneTranscript: "NM_144639.3", parsimonySitesP: "0.275641025641026", constantSitesP: "0.607988165680473", distinctSitesP: "0.408284023668639", brLenTotal: "1.4191" },
  { geneTranscript: "NM_144665.4", parsimonySitesP: "0.136856368563686", constantSitesP: "0.758807588075881", distinctSitesP: "0.215447154471545", brLenTotal: "0.4992" },
  { geneTranscript: "NM_144672.4", parsimonySitesP: "0.250219490781387", constantSitesP: "0.598770851624232", distinctSitesP: "0.366110623353819", brLenTotal: "0.9367" },
  { geneTranscript: "NM_144674.2", parsimonySitesP: "0.303780068728522", constantSitesP: "0.557388316151203", distinctSitesP: "0.451546391752577", brLenTotal: "1.3059" },
  { geneTranscript: "NM_144688.5", parsimonySitesP: "0.265717674970344", constantSitesP: "0.552787663107948", distinctSitesP: "0.428825622775801", brLenTotal: "0.9442" },
  { geneTranscript: "NM_144694.5", parsimonySitesP: "0.25", constantSitesP: "0.620646766169154", distinctSitesP: "0.343283582089552", brLenTotal: "0.7684" },
  { geneTranscript: "NM_144698.5", parsimonySitesP: "0.286047286047286", constantSitesP: "0.543456543456543", distinctSitesP: "0.415584415584416", brLenTotal: "1.0065" },
  { geneTranscript: "NM_144703.3", parsimonySitesP: "0.187878787878788", constantSitesP: "0.670995670995671", distinctSitesP: "0.293506493506494", brLenTotal: "0.8088" },
  { geneTranscript: "NM_144709.4", parsimonySitesP: "0.140516698172653", constantSitesP: "0.756773787019534", distinctSitesP: "0.244486452425961", brLenTotal: "0.4449" },
  { geneTranscript: "NM_144717.4", parsimonySitesP: "0.271168274383708", constantSitesP: "0.542336548767417", distinctSitesP: "0.452304394426581", brLenTotal: "1.0601" },
  { geneTranscript: "NM_144736.5", parsimonySitesP: "0.195011337868481", constantSitesP: "0.649281934996221", distinctSitesP: "0.334089191232048", brLenTotal: "0.6971" },
  { geneTranscript: "NM_144967.4", parsimonySitesP: "0.248628884826325", constantSitesP: "0.595368677635588", distinctSitesP: "0.37720901889092", brLenTotal: "0.8844" },
  { geneTranscript: "NM_144969.3", parsimonySitesP: "0.103857566765579", constantSitesP: "0.799208704253215", distinctSitesP: "0.194856577645895", brLenTotal: "0.3171" },
  { geneTranscript: "NM_144970.3", parsimonySitesP: "0.217345872518286", constantSitesP: "0.64367816091954", distinctSitesP: "0.350052246603971", brLenTotal: "0.6725" },
  { geneTranscript: "NM_144988.4", parsimonySitesP: "0.20679012345679", constantSitesP: "0.646604938271605", distinctSitesP: "0.361111111111111", brLenTotal: "0.8478" },
  { geneTranscript: "NM_144991.3", parsimonySitesP: "0.272546088689586", constantSitesP: "0.603388141504733", distinctSitesP: "0.405580468360737", brLenTotal: "1.2660" },
  { geneTranscript: "NM_144999.4", parsimonySitesP: "0.278109452736318", constantSitesP: "0.578606965174129", distinctSitesP: "0.388059701492537", brLenTotal: "1.0580" },
  { geneTranscript: "NM_145010.4", parsimonySitesP: "0.239583333333333", constantSitesP: "0.602864583333333", distinctSitesP: "0.424479166666667", brLenTotal: "0.8514" },
  { geneTranscript: "NM_145020.5", parsimonySitesP: "0.271725032425422", constantSitesP: "0.557068741893645", distinctSitesP: "0.477950713359274", brLenTotal: "0.9579" },
  { geneTranscript: "NM_145027.6", parsimonySitesP: "0.195741195741196", constantSitesP: "0.66953316953317", distinctSitesP: "0.312858312858313", brLenTotal: "0.7135" },
  { geneTranscript: "NM_145030.4", parsimonySitesP: "0.291172595520422", constantSitesP: "0.512516469038208", distinctSitesP: "0.433465085638999", brLenTotal: "1.2846" },
  { geneTranscript: "NM_145041.4", parsimonySitesP: "0.258269720101781", constantSitesP: "0.577608142493639", distinctSitesP: "0.412213740458015", brLenTotal: "0.9227" },
  { geneTranscript: "NM_145045.5", parsimonySitesP: "0.309243697478992", constantSitesP: "0.530532212885154", distinctSitesP: "0.448179271708683", brLenTotal: "1.0751" },
  { geneTranscript: "NM_145058.3", parsimonySitesP: "0.232227488151659", constantSitesP: "0.601895734597156", distinctSitesP: "0.412322274881517", brLenTotal: "0.8796" },
  { geneTranscript: "NM_145062.3", parsimonySitesP: "0.19838523644752", constantSitesP: "0.668396770472895", distinctSitesP: "0.357554786620531", brLenTotal: "0.6495" },
  { geneTranscript: "NM_145102.4", parsimonySitesP: "0.203019467620183", constantSitesP: "0.679777512912197", distinctSitesP: "0.286452125546285", brLenTotal: "0.6783" },
  { geneTranscript: "NM_145166.4", parsimonySitesP: "0.203034359660866", constantSitesP: "0.660865684962071", distinctSitesP: "0.311468094600625", brLenTotal: "1.1363" },
  { geneTranscript: "NM_145167.3", parsimonySitesP: "0.237194641449961", constantSitesP: "0.579984239558708", distinctSitesP: "0.376674546887313", brLenTotal: "0.8362" },
  { geneTranscript: "NM_145178.4", parsimonySitesP: "0.219298245614035", constantSitesP: "0.675438596491228", distinctSitesP: "0.357456140350877", brLenTotal: "0.6848" },
  { geneTranscript: "NM_145207.3", parsimonySitesP: "0.203434117207913", constantSitesP: "0.632698768197088", distinctSitesP: "0.331466965285554", brLenTotal: "0.7255" },
  { geneTranscript: "NM_145235.5", parsimonySitesP: "0.241545893719807", constantSitesP: "0.642512077294686", distinctSitesP: "0.373913043478261", brLenTotal: "0.9879" },
  { geneTranscript: "NM_145212.4", parsimonySitesP: "0.238095238095238", constantSitesP: "0.62111801242236", distinctSitesP: "0.399585921325052", brLenTotal: "0.7434" },
  { geneTranscript: "NM_145251.4", parsimonySitesP: "0.109118086696562", constantSitesP: "0.8101644245142", distinctSitesP: "0.209267563527653", brLenTotal: "0.3528" },
  { geneTranscript: "NM_145269.5", parsimonySitesP: "0.175317185697809", constantSitesP: "0.668973471741638", distinctSitesP: "0.333333333333333", brLenTotal: "0.5595" },
  { geneTranscript: "NM_145270.3", parsimonySitesP: "0.396380618797431", constantSitesP: "0.425569176882662", distinctSitesP: "0.562755399883246", brLenTotal: "1.8699" },
  { geneTranscript: "NM_145285.3", parsimonySitesP: "0.185897435897436", constantSitesP: "0.705128205128205", distinctSitesP: "0.273809523809524", brLenTotal: "0.8006" },
  { geneTranscript: "NM_145647.4", parsimonySitesP: "0.193558474046279", constantSitesP: "0.664165103189493", distinctSitesP: "0.322701688555347", brLenTotal: "0.7561" },
  { geneTranscript: "NM_145652.4", parsimonySitesP: "0.319783197831978", constantSitesP: "0.555555555555556", distinctSitesP: "0.452574525745257", brLenTotal: "1.2755" },
  { geneTranscript: "NM_145659.3", parsimonySitesP: "0.316872427983539", constantSitesP: "0.503429355281207", distinctSitesP: "0.508916323731139", brLenTotal: "1.1684" },
  { geneTranscript: "NM_145697.3", parsimonySitesP: "0.173132183908046", constantSitesP: "0.70617816091954", distinctSitesP: "0.290229885057471", brLenTotal: "0.5289" },
  { geneTranscript: "NM_145698.5", parsimonySitesP: "0.205714285714286", constantSitesP: "0.660952380952381", distinctSitesP: "0.353015873015873", brLenTotal: "0.6892" },
  { geneTranscript: "NM_145740.5", parsimonySitesP: "0.36036036036036", constantSitesP: "0.454954954954955", distinctSitesP: "0.569069069069069", brLenTotal: "1.8456" },
  { geneTranscript: "NM_145867.2", parsimonySitesP: "0.193333333333333", constantSitesP: "0.666666666666667", distinctSitesP: "0.355555555555556", brLenTotal: "0.8275" },
  { geneTranscript: "NM_145913.5", parsimonySitesP: "0.22568306010929", constantSitesP: "0.630054644808743", distinctSitesP: "0.356830601092896", brLenTotal: "0.9284" },
  { geneTranscript: "NM_147128.4", parsimonySitesP: "0.205234159779614", constantSitesP: "0.62534435261708", distinctSitesP: "0.40771349862259", brLenTotal: "0.8029" },
  { geneTranscript: "NM_147129.5", parsimonySitesP: "0.276670164393144", constantSitesP: "0.57292759706191", distinctSitesP: "0.351171738370059", brLenTotal: "1.2030" },
  { geneTranscript: "NM_147188.3", parsimonySitesP: "0.192721257237386", constantSitesP: "0.684036393713813", distinctSitesP: "0.290322580645161", brLenTotal: "0.7132" },
  { geneTranscript: "NM_147196.3", parsimonySitesP: "0.185897435897436", constantSitesP: "0.677350427350427", distinctSitesP: "0.341880341880342", brLenTotal: "0.7244" },
  { geneTranscript: "NM_147686.4", parsimonySitesP: "0.23834808259587", constantSitesP: "0.575221238938053", distinctSitesP: "0.384070796460177", brLenTotal: "0.8924" },
  { geneTranscript: "NM_148172.3", parsimonySitesP: "0.30225988700565", constantSitesP: "0.570621468926554", distinctSitesP: "0.436440677966102", brLenTotal: "1.2254" },
  { geneTranscript: "NM_148674.5", parsimonySitesP: "0.182456140350877", constantSitesP: "0.695006747638327", distinctSitesP: "0.269095816464238", brLenTotal: "0.6064" },
  { geneTranscript: "NM_148912.4", parsimonySitesP: "0.332244008714597", constantSitesP: "0.515250544662309", distinctSitesP: "0.485838779956427", brLenTotal: "1.0705" },
  { geneTranscript: "NM_148923.4", parsimonySitesP: "0.23134328358209", constantSitesP: "0.634328358208955", distinctSitesP: "0.400497512437811", brLenTotal: "0.8879" },
  { geneTranscript: "NM_148963.4", parsimonySitesP: "0.232541396688265", constantSitesP: "0.597912167026638", distinctSitesP: "0.356731461483081", brLenTotal: "0.7753" },
  { geneTranscript: "NM_148977.3", parsimonySitesP: "0.159797541576283", constantSitesP: "0.713665943600868", distinctSitesP: "0.242227042660882", brLenTotal: "0.6342" },
  { geneTranscript: "NM_152228.3", parsimonySitesP: "0.37754303599374", constantSitesP: "0.47887323943662", distinctSitesP: "0.485524256651017", brLenTotal: "1.9713" },
  { geneTranscript: "NM_152260.3", parsimonySitesP: "0.255045871559633", constantSitesP: "0.59388379204893", distinctSitesP: "0.375535168195719", brLenTotal: "0.8967" },
  { geneTranscript: "NM_152266.5", parsimonySitesP: "0.277519379844961", constantSitesP: "0.558139534883721", distinctSitesP: "0.455813953488372", brLenTotal: "1.0130" },
  { geneTranscript: "NM_152271.5", parsimonySitesP: "0.163001293661061", constantSitesP: "0.668391548081069", distinctSitesP: "0.310047434238896", brLenTotal: "0.6187" },
  { geneTranscript: "NM_152277.3", parsimonySitesP: "0.106837606837607", constantSitesP: "0.772079772079772", distinctSitesP: "0.207977207977208", brLenTotal: "0.4823" },
  { geneTranscript: "NM_152299.4", parsimonySitesP: "0.261157024793388", constantSitesP: "0.605509641873278", distinctSitesP: "0.392837465564738", brLenTotal: "0.9962" },
  { geneTranscript: "NM_152313.4", parsimonySitesP: "0.16468253968254", constantSitesP: "0.710978835978836", distinctSitesP: "0.295634920634921", brLenTotal: "0.5734" },
  { geneTranscript: "NM_152318.3", parsimonySitesP: "0.248648648648649", constantSitesP: "0.542342342342342", distinctSitesP: "0.468468468468468", brLenTotal: "0.9132" },
  { geneTranscript: "NM_152323.3", parsimonySitesP: "0.264784946236559", constantSitesP: "0.571236559139785", distinctSitesP: "0.451612903225806", brLenTotal: "0.9907" },
  { geneTranscript: "NM_152329.4", parsimonySitesP: "0.184380032206119", constantSitesP: "0.656199677938808", distinctSitesP: "0.364734299516908", brLenTotal: "0.7014" },
  { geneTranscript: "NM_152331.4", parsimonySitesP: "0.341250989707047", constantSitesP: "0.465558194774347", distinctSitesP: "0.52652414885194", brLenTotal: "1.5808" },
  { geneTranscript: "NM_152334.3", parsimonySitesP: "0.197838736492103", constantSitesP: "0.653366583541147", distinctSitesP: "0.320864505403159", brLenTotal: "0.7674" },
  { geneTranscript: "NM_152352.4", parsimonySitesP: "0.258578431372549", constantSitesP: "0.584558823529412", distinctSitesP: "0.447303921568627", brLenTotal: "0.9897" },
  { geneTranscript: "NM_152353.3", parsimonySitesP: "0.261477045908184", constantSitesP: "0.564870259481038", distinctSitesP: "0.439121756487026", brLenTotal: "0.8332" },
  { geneTranscript: "NM_152357.3", parsimonySitesP: "0.575910364145658", constantSitesP: "0.287955182072829", distinctSitesP: "0.724929971988796", brLenTotal: "3.4939" },
  { geneTranscript: "NM_152375.3", parsimonySitesP: "0.349206349206349", constantSitesP: "0.451308451308451", distinctSitesP: "0.522522522522523", brLenTotal: "1.5066" },
  { geneTranscript: "NM_152384.3", parsimonySitesP: "0.104594330400782", constantSitesP: "0.80058651026393", distinctSitesP: "0.212121212121212", brLenTotal: "0.3392" },
  { geneTranscript: "NM_152386.4", parsimonySitesP: "0.233082706766917", constantSitesP: "0.62406015037594", distinctSitesP: "0.37593984962406", brLenTotal: "0.9125" },
  { geneTranscript: "NM_152391.5", parsimonySitesP: "0.235973597359736", constantSitesP: "0.610561056105611", distinctSitesP: "0.425742574257426", brLenTotal: "0.9051" },
  { geneTranscript: "NM_152395.3", parsimonySitesP: "0.273504273504274", constantSitesP: "0.552136752136752", distinctSitesP: "0.430769230769231", brLenTotal: "1.0626" },
  { geneTranscript: "NM_152412.3", parsimonySitesP: "0.215500945179584", constantSitesP: "0.608695652173913", distinctSitesP: "0.379962192816635", brLenTotal: "0.7466" },
  { geneTranscript: "NM_152445.3", parsimonySitesP: "0.268933539412674", constantSitesP: "0.543534260690366", distinctSitesP: "0.409582689335394", brLenTotal: "0.9178" },
  { geneTranscript: "NM_152446.5", parsimonySitesP: "0.217245581962218", constantSitesP: "0.627056672760512", distinctSitesP: "0.362888482632541", brLenTotal: "0.7150" },
  { geneTranscript: "NM_152448.3", parsimonySitesP: "0.190909090909091", constantSitesP: "0.675757575757576", distinctSitesP: "0.328787878787879", brLenTotal: "0.6824" },
  { geneTranscript: "NM_152463.4", parsimonySitesP: "0.284210526315789", constantSitesP: "0.516374269005848", distinctSitesP: "0.449707602339181", brLenTotal: "0.9961" },
  { geneTranscript: "NM_152482.3", parsimonySitesP: "0.409604519774011", constantSitesP: "0.415254237288136", distinctSitesP: "0.607344632768362", brLenTotal: "1.6735" },
  { geneTranscript: "NM_152499.4", parsimonySitesP: "0.310532030401737", constantSitesP: "0.476655808903366", distinctSitesP: "0.474484256243214", brLenTotal: "1.0392" },
  { geneTranscript: "NM_152520.6", parsimonySitesP: "0.12962962962963", constantSitesP: "0.775034293552812", distinctSitesP: "0.204389574759945", brLenTotal: "0.4833" },
  { geneTranscript: "NM_152542.5", parsimonySitesP: "0.235663082437276", constantSitesP: "0.666666666666667", distinctSitesP: "0.323476702508961", brLenTotal: "0.9450" },
  { geneTranscript: "NM_152545.3", parsimonySitesP: "0.212121212121212", constantSitesP: "0.718111346018323", distinctSitesP: "0.306553911205074", brLenTotal: "0.8096" },
  { geneTranscript: "NM_152565.1", parsimonySitesP: "0.179047619047619", constantSitesP: "0.684761904761905", distinctSitesP: "0.312380952380952", brLenTotal: "0.6704" },
  { geneTranscript: "NM_152572.3", parsimonySitesP: "0.283228949199722", constantSitesP: "0.60125260960334", distinctSitesP: "0.39178844815588", brLenTotal: "1.1115" },
  { geneTranscript: "NM_152573.4", parsimonySitesP: "0.224774774774775", constantSitesP: "0.633333333333333", distinctSitesP: "0.362612612612613", brLenTotal: "0.7778" },
  { geneTranscript: "NM_152574.3", parsimonySitesP: "0.16991341991342", constantSitesP: "0.719155844155844", distinctSitesP: "0.292748917748918", brLenTotal: "0.6025" },
  { geneTranscript: "NM_152588.3", parsimonySitesP: "0.160685805422648", constantSitesP: "0.743221690590112", distinctSitesP: "0.231658692185008", brLenTotal: "0.5506" },
  { geneTranscript: "NM_152599.4", parsimonySitesP: "0.340728100113766", constantSitesP: "0.448236632536974", distinctSitesP: "0.499431171786121", brLenTotal: "1.3261" },
  { geneTranscript: "NM_152611.5", parsimonySitesP: "0.36036036036036", constantSitesP: "0.44954954954955", distinctSitesP: "0.532432432432432", brLenTotal: "1.5013" },
  { geneTranscript: "NM_152625.3", parsimonySitesP: "0.209677419354839", constantSitesP: "0.638440860215054", distinctSitesP: "0.331093189964158", brLenTotal: "0.8728" },
  { geneTranscript: "NM_152628.4", parsimonySitesP: "0.246212121212121", constantSitesP: "0.581818181818182", distinctSitesP: "0.425", brLenTotal: "0.9351" },
  { geneTranscript: "NM_152637.3", parsimonySitesP: "0.316939890710383", constantSitesP: "0.452185792349727", distinctSitesP: "0.554644808743169", brLenTotal: "1.2871" },
  { geneTranscript: "NM_152660.3", parsimonySitesP: "0.0933767643865364", constantSitesP: "0.818675352877307", distinctSitesP: "0.157437567861021", brLenTotal: "0.3478" },
  { geneTranscript: "NM_152657.4", parsimonySitesP: "0.337934560327198", constantSitesP: "0.442229038854806", distinctSitesP: "0.495398773006135", brLenTotal: "1.1947" },
  { geneTranscript: "NM_152672.6", parsimonySitesP: "0.273529411764706", constantSitesP: "0.587254901960784", distinctSitesP: "0.416666666666667", brLenTotal: "0.9734" },
  { geneTranscript: "NM_152682.4", parsimonySitesP: "0.148936170212766", constantSitesP: "0.735815602836879", distinctSitesP: "0.303191489361702", brLenTotal: "0.5413" },
  { geneTranscript: "NM_152686.4", parsimonySitesP: "0.161080074487896", constantSitesP: "0.702979515828678", distinctSitesP: "0.273743016759777", brLenTotal: "0.5554" },
  { geneTranscript: "NM_152688.4", parsimonySitesP: "0.132760267430755", constantSitesP: "0.795606494746896", distinctSitesP: "0.207258834765998", brLenTotal: "0.3978" },
  { geneTranscript: "NM_152698.3", parsimonySitesP: "0.408052651955091", constantSitesP: "0.400696864111498", distinctSitesP: "0.573751451800232", brLenTotal: "1.7619" },
  { geneTranscript: "NM_152703.5", parsimonySitesP: "0.361952861952862", constantSitesP: "0.515572390572391", distinctSitesP: "0.39793771043771", brLenTotal: "1.2136" },
  { geneTranscript: "NM_152713.5", parsimonySitesP: "0.118676122931442", constantSitesP: "0.775886524822695", distinctSitesP: "0.178723404255319", brLenTotal: "0.5661" },
  { geneTranscript: "NM_152718.2", parsimonySitesP: "0.265270506108202", constantSitesP: "0.574520069808028", distinctSitesP: "0.372076788830716", brLenTotal: "1.0705" },
  { geneTranscript: "NM_152735.4", parsimonySitesP: "0.19661733615222", constantSitesP: "0.627202255109232", distinctSitesP: "0.320648343904158", brLenTotal: "0.7433" },
  { geneTranscript: "NM_152742.3", parsimonySitesP: "0.244674726540012", constantSitesP: "0.620034542314335", distinctSitesP: "0.337363270005757", brLenTotal: "0.8539" },
  { geneTranscript: "NM_152750.5", parsimonySitesP: "0.28135593220339", constantSitesP: "0.550282485875706", distinctSitesP: "0.458757062146893", brLenTotal: "1.0098" },
  { geneTranscript: "NM_152755.2", parsimonySitesP: "0.211021505376344", constantSitesP: "0.674731182795699", distinctSitesP: "0.360215053763441", brLenTotal: "0.7424" },
  { geneTranscript: "NM_152783.5", parsimonySitesP: "0.299424184261036", constantSitesP: "0.564299424184261", distinctSitesP: "0.420345489443378", brLenTotal: "1.5445" },
  { geneTranscript: "NM_152789.4", parsimonySitesP: "0.126855600539811", constantSitesP: "0.753036437246964", distinctSitesP: "0.263157894736842", brLenTotal: "0.4466" },
  { geneTranscript: "NM_152791.5", parsimonySitesP: "0.426220806794055", constantSitesP: "0.412951167728238", distinctSitesP: "0.576963906581741", brLenTotal: "2.3011" },
  { geneTranscript: "NM_152836.3", parsimonySitesP: "0.159883720930233", constantSitesP: "0.708333333333333", distinctSitesP: "0.285852713178295", brLenTotal: "0.5527" },
  { geneTranscript: "NM_152888.3", parsimonySitesP: "0.222632226322263", constantSitesP: "0.642886428864289", distinctSitesP: "0.34009840098401", brLenTotal: "0.9835" },
  { geneTranscript: "NM_152890.7", parsimonySitesP: "0.190976273823415", constantSitesP: "0.632827693504473", distinctSitesP: "0.34830805134189", brLenTotal: "0.6682" },
  { geneTranscript: "NM_152899.2", parsimonySitesP: "0.311581422692534", constantSitesP: "0.536743092298648", distinctSitesP: "0.435038212815991", brLenTotal: "1.2127" },
  { geneTranscript: "NM_152903.5", parsimonySitesP: "0.229970326409496", constantSitesP: "0.644906033630069", distinctSitesP: "0.309594460929773", brLenTotal: "0.9447" },
  { geneTranscript: "NM_152905.4", parsimonySitesP: "0.177272727272727", constantSitesP: "0.683333333333333", distinctSitesP: "0.293939393939394", brLenTotal: "0.6419" },
  { geneTranscript: "NM_152911.4", parsimonySitesP: "0.296151337247228", constantSitesP: "0.57208088714938", distinctSitesP: "0.41617742987606", brLenTotal: "1.2926" },
  { geneTranscript: "NM_152988.3", parsimonySitesP: "0.269707207207207", constantSitesP: "0.619369369369369", distinctSitesP: "0.347972972972973", brLenTotal: "1.3411" },
  { geneTranscript: "NM_153002.3", parsimonySitesP: "0.271089271089271", constantSitesP: "0.567977067977068", distinctSitesP: "0.398853398853399", brLenTotal: "0.9060" },
  { geneTranscript: "NM_152996.4", parsimonySitesP: "0.174863387978142", constantSitesP: "0.721311475409836", distinctSitesP: "0.254644808743169", brLenTotal: "0.5737" },
  { geneTranscript: "NM_153029.4", parsimonySitesP: "0.202752976190476", constantSitesP: "0.658482142857143", distinctSitesP: "0.297247023809524", brLenTotal: "0.6555" },
  { geneTranscript: "NM_153035.3", parsimonySitesP: "0.144230769230769", constantSitesP: "0.770833333333333", distinctSitesP: "0.253205128205128", brLenTotal: "0.4212" },
  { geneTranscript: "NM_153209.4", parsimonySitesP: "0.288577154308617", constantSitesP: "0.550768203072812", distinctSitesP: "0.383433533734135", brLenTotal: "1.1836" },
  { geneTranscript: "NM_153210.5", parsimonySitesP: "0.254674977738201", constantSitesP: "0.601365390323538", distinctSitesP: "0.353517364203028", brLenTotal: "0.9650" },
  { geneTranscript: "NM_153215.3", parsimonySitesP: "0.339430894308943", constantSitesP: "0.49390243902439", distinctSitesP: "0.528455284552846", brLenTotal: "1.2760" },
  { geneTranscript: "NM_153217.3", parsimonySitesP: "0.267489711934156", constantSitesP: "0.585733882030178", distinctSitesP: "0.429355281207133", brLenTotal: "0.9697" },
  { geneTranscript: "NM_153218.4", parsimonySitesP: "0.186821705426357", constantSitesP: "0.653488372093023", distinctSitesP: "0.333333333333333", brLenTotal: "0.6855" },
  { geneTranscript: "NM_153225.4", parsimonySitesP: "0.205808080808081", constantSitesP: "0.671717171717172", distinctSitesP: "0.323232323232323", brLenTotal: "0.6845" },
  { geneTranscript: "NM_153247.4", parsimonySitesP: "0.237735849056604", constantSitesP: "0.646540880503145", distinctSitesP: "0.355345911949686", brLenTotal: "1.1648" },
  { geneTranscript: "NM_153259.4", parsimonySitesP: "0.205535924617197", constantSitesP: "0.663133097762073", distinctSitesP: "0.336866902237927", brLenTotal: "0.7528" },
  { geneTranscript: "NM_153369.4", parsimonySitesP: "0.263191763191763", constantSitesP: "0.585585585585586", distinctSitesP: "0.37966537966538", brLenTotal: "1.0590" },
  { geneTranscript: "NM_153370.3", parsimonySitesP: "0.30381569474442", constantSitesP: "0.53491720662347", distinctSitesP: "0.455723542116631", brLenTotal: "0.9860" },
  { geneTranscript: "NM_153377.5", parsimonySitesP: "0.213881441763479", constantSitesP: "0.645814715519809", distinctSitesP: "0.324694667858207", brLenTotal: "0.8772" },
  { geneTranscript: "NM_153456.4", parsimonySitesP: "0.157112526539278", constantSitesP: "0.710544939844303", distinctSitesP: "0.268223637650389", brLenTotal: "0.6322" },
  { geneTranscript: "NM_153636.3", parsimonySitesP: "0.264635603345281", constantSitesP: "0.618876941457587", distinctSitesP: "0.388888888888889", brLenTotal: "1.4376" },
  { geneTranscript: "NM_153682.3", parsimonySitesP: "0.176616915422886", constantSitesP: "0.669154228855721", distinctSitesP: "0.388059701492537", brLenTotal: "0.6727" },
  { geneTranscript: "NM_153687.4", parsimonySitesP: "0.219274977895668", constantSitesP: "0.605658709106985", distinctSitesP: "0.376657824933687", brLenTotal: "0.7339" },
  { geneTranscript: "NM_153698.2", parsimonySitesP: "0.222713864306785", constantSitesP: "0.594395280235988", distinctSitesP: "0.438053097345133", brLenTotal: "0.9282" },
  { geneTranscript: "NM_153699.2", parsimonySitesP: "0.373873873873874", constantSitesP: "0.418918918918919", distinctSitesP: "0.605105105105105", brLenTotal: "2.0534" },
  { geneTranscript: "NM_153700.2", parsimonySitesP: "0.207323943661972", constantSitesP: "0.634553990610329", distinctSitesP: "0.2818779342723", brLenTotal: "0.6926" },
  { geneTranscript: "NM_153702.4", parsimonySitesP: "0.117178612059158", constantSitesP: "0.77929465301479", distinctSitesP: "0.213879408418658", brLenTotal: "0.3873" },
  { geneTranscript: "NM_153704.6", parsimonySitesP: "0.177219430485762", constantSitesP: "0.699497487437186", distinctSitesP: "0.295477386934673", brLenTotal: "0.5549" },
  { geneTranscript: "NM_153707.4", parsimonySitesP: "0.29606188466948", constantSitesP: "0.540787623066104", distinctSitesP: "0.450773558368495", brLenTotal: "1.0041" },
  { geneTranscript: "NM_153811.3", parsimonySitesP: "0.168859649122807", constantSitesP: "0.687134502923977", distinctSitesP: "0.312134502923977", brLenTotal: "0.5613" },
  { geneTranscript: "NM_153836.4", parsimonySitesP: "0.195402298850575", constantSitesP: "0.629885057471264", distinctSitesP: "0.370114942528736", brLenTotal: "0.7825" },
  { geneTranscript: "NM_153838.5", parsimonySitesP: "0.329976019184652", constantSitesP: "0.535731414868106", distinctSitesP: "0.440287769784173", brLenTotal: "1.3543" },
  { geneTranscript: "NM_153839.7", parsimonySitesP: "0.249221183800623", constantSitesP: "0.591381100726895", distinctSitesP: "0.377466251298027", brLenTotal: "0.8516" },
  { geneTranscript: "NM_170600.3", parsimonySitesP: "0.207364341085271", constantSitesP: "0.67015503875969", distinctSitesP: "0.284496124031008", brLenTotal: "0.9034" },
  { geneTranscript: "NM_170601.5", parsimonySitesP: "0.284894837476099", constantSitesP: "0.549394518801785", distinctSitesP: "0.433397068196303", brLenTotal: "1.1883" },
  { geneTranscript: "NM_170606.3", parsimonySitesP: "0.191135546053078", constantSitesP: "0.676440643453472", distinctSitesP: "0.255345143555284", brLenTotal: "0.7350" },
  { geneTranscript: "NM_170678.3", parsimonySitesP: "0.289855072463768", constantSitesP: "0.539130434782609", distinctSitesP: "0.498550724637681", brLenTotal: "1.5919" },
  { geneTranscript: "NM_170682.4", parsimonySitesP: "0.263269639065817", constantSitesP: "0.576786978060863", distinctSitesP: "0.434536447275301", brLenTotal: "1.1972" },
  { geneTranscript: "NM_170754.4", parsimonySitesP: "0.202980837473385", constantSitesP: "0.670451857109061", distinctSitesP: "0.261651289330494", brLenTotal: "0.7867" },
  { geneTranscript: "NM_170784.3", parsimonySitesP: "0.260233918128655", constantSitesP: "0.585380116959064", distinctSitesP: "0.393567251461988", brLenTotal: "0.8877" },
  { geneTranscript: "NM_172002.5", parsimonySitesP: "0.229787234042553", constantSitesP: "0.611347517730496", distinctSitesP: "0.412765957446809", brLenTotal: "0.8315" },
  { geneTranscript: "NM_172138.2", parsimonySitesP: "0.425", constantSitesP: "0.383333333333333", distinctSitesP: "0.613333333333333", brLenTotal: "1.8021" },
  { geneTranscript: "NM_172139.4", parsimonySitesP: "0.425170068027211", constantSitesP: "0.38265306122449", distinctSitesP: "0.607142857142857", brLenTotal: "1.7561" },
  { geneTranscript: "NM_172140.2", parsimonySitesP: "0.403333333333333", constantSitesP: "0.41", distinctSitesP: "0.64", brLenTotal: "1.6988" },
  { geneTranscript: "NM_172194.1", parsimonySitesP: "0.505857294994675", constantSitesP: "0.332268370607029", distinctSitesP: "0.652822151224707", brLenTotal: "2.3525" },
  { geneTranscript: "NM_172201.2", parsimonySitesP: "0.246612466124661", constantSitesP: "0.64769647696477", distinctSitesP: "0.390243902439024", brLenTotal: "1.0573" },
  { geneTranscript: "NM_172232.4", parsimonySitesP: "0.16159155501421", constantSitesP: "0.72553796183516", distinctSitesP: "0.23832724319935", brLenTotal: "0.5025" },
  { geneTranscript: "NM_172240.3", parsimonySitesP: "0.186192468619247", constantSitesP: "0.689679218967922", distinctSitesP: "0.308926080892608", brLenTotal: "0.6314" },
  { geneTranscript: "NM_172314.1", parsimonySitesP: "0.262939958592133", constantSitesP: "0.602484472049689", distinctSitesP: "0.428571428571429", brLenTotal: "1.0077" },
  { geneTranscript: "NM_172364.5", parsimonySitesP: "0.239519202579889", constantSitesP: "0.635297566695984", distinctSitesP: "0.338610378188215", brLenTotal: "1.0447" },
  { geneTranscript: "NM_173086.5", parsimonySitesP: "0.304373522458629", constantSitesP: "0.524231678486998", distinctSitesP: "0.471631205673759", brLenTotal: "2.1220" },
  { geneTranscript: "NM_173177.3", parsimonySitesP: "0.132387706855792", constantSitesP: "0.742316784869976", distinctSitesP: "0.264775413711584", brLenTotal: "0.4937" },
  { geneTranscript: "NM_173344.3", parsimonySitesP: "0.246078431372549", constantSitesP: "0.632352941176471", distinctSitesP: "0.350980392156863", brLenTotal: "1.0685" },
  { geneTranscript: "NM_173467.5", parsimonySitesP: "0.300854700854701", constantSitesP: "0.558119658119658", distinctSitesP: "0.442735042735043", brLenTotal: "1.1301" },
  { geneTranscript: "NM_173482.3", parsimonySitesP: "0.317301269205077", constantSitesP: "0.50501002004008", distinctSitesP: "0.488309953239813", brLenTotal: "1.1504" },
  { geneTranscript: "NM_173489.5", parsimonySitesP: "0.24248159831756", constantSitesP: "0.58801261829653", distinctSitesP: "0.383175604626709", brLenTotal: "0.8385" },
  { geneTranscript: "NM_173506.7", parsimonySitesP: "0.303523035230352", constantSitesP: "0.53929539295393", distinctSitesP: "0.493224932249322", brLenTotal: "1.1390" },
  { geneTranscript: "NM_173518.5", parsimonySitesP: "0.16789035731767", constantSitesP: "0.681840430739109", distinctSitesP: "0.295154185022026", brLenTotal: "0.5497" },
  { geneTranscript: "NM_173536.4", parsimonySitesP: "0.240143369175627", constantSitesP: "0.655913978494624", distinctSitesP: "0.334767025089606", brLenTotal: "1.1848" },
  { geneTranscript: "NM_173564.4", parsimonySitesP: "0.177962742766548", constantSitesP: "0.672215616329766", distinctSitesP: "0.261989694807769", brLenTotal: "0.9596" },
  { geneTranscript: "NM_173587.4", parsimonySitesP: "0.179094964945825", constantSitesP: "0.713193116634799", distinctSitesP: "0.253027405991077", brLenTotal: "0.7121" },
  { geneTranscript: "NM_173593.4", parsimonySitesP: "0.286907147628591", constantSitesP: "0.573480293921176", distinctSitesP: "0.401135604542418", brLenTotal: "1.1478" },
  { geneTranscript: "NM_173588.4", parsimonySitesP: "0.214680744092509", constantSitesP: "0.614127702362996", distinctSitesP: "0.322775263951735", brLenTotal: "0.8394" },
  { geneTranscript: "NM_173607.5", parsimonySitesP: "0.19774011299435", constantSitesP: "0.673728813559322", distinctSitesP: "0.354519774011299", brLenTotal: "0.6589" },
  { geneTranscript: "NM_173611.4", parsimonySitesP: "0.187066974595843", constantSitesP: "0.636643571978445", distinctSitesP: "0.362586605080831", brLenTotal: "1.0072" },
  { geneTranscript: "NM_173647.4", parsimonySitesP: "0.220833333333333", constantSitesP: "0.646666666666667", distinctSitesP: "0.364166666666667", brLenTotal: "0.7538" },
  { geneTranscript: "NM_173630.4", parsimonySitesP: "0.222072476789458", constantSitesP: "0.637166816412099", distinctSitesP: "0.351003294399521", brLenTotal: "0.7845" },
  { geneTranscript: "NM_173659.5", parsimonySitesP: "0.332361516034985", constantSitesP: "0.461613216715258", distinctSitesP: "0.543245869776482", brLenTotal: "1.2463" },
  { geneTranscript: "NM_173662.4", parsimonySitesP: "0.221544715447154", constantSitesP: "0.564024390243902", distinctSitesP: "0.445121951219512", brLenTotal: "1.0173" },
  { geneTranscript: "NM_173664.6", parsimonySitesP: "0.239071038251366", constantSitesP: "0.631147540983607", distinctSitesP: "0.35655737704918", brLenTotal: "0.8353" },
  { geneTranscript: "NM_173685.4", parsimonySitesP: "0.190283400809717", constantSitesP: "0.624831309041835", distinctSitesP: "0.349527665317139", brLenTotal: "0.7307" },
  { geneTranscript: "NM_173689.7", parsimonySitesP: "0.302464332036316", constantSitesP: "0.546562905317769", distinctSitesP: "0.385473411154345", brLenTotal: "1.2562" },
  { geneTranscript: "NM_173791.5", parsimonySitesP: "0.171288272674754", constantSitesP: "0.694685153090699", distinctSitesP: "0.230213749277874", brLenTotal: "0.5847" },
  { geneTranscript: "NM_173804.5", parsimonySitesP: "0.430678466076696", constantSitesP: "0.373156342182891", distinctSitesP: "0.619469026548673", brLenTotal: "1.9574" },
  { geneTranscript: "NM_173808.3", parsimonySitesP: "0.11864406779661", constantSitesP: "0.785310734463277", distinctSitesP: "0.193973634651601", brLenTotal: "0.4844" },
  { geneTranscript: "NM_173833.6", parsimonySitesP: "0.205387205387205", constantSitesP: "0.675420875420875", distinctSitesP: "0.297643097643098", brLenTotal: "0.9079" },
  { geneTranscript: "NM_173846.5", parsimonySitesP: "0.233333333333333", constantSitesP: "0.623529411764706", distinctSitesP: "0.37843137254902", brLenTotal: "0.8453" },
  { geneTranscript: "NM_173847.5", parsimonySitesP: "0.368992248062016", constantSitesP: "0.475968992248062", distinctSitesP: "0.564341085271318", brLenTotal: "1.4024" },
  { geneTranscript: "NM_173849.3", parsimonySitesP: "0.1284046692607", constantSitesP: "0.748378728923476", distinctSitesP: "0.226977950713359", brLenTotal: "0.7864" },
  { geneTranscript: "NM_173851.3", parsimonySitesP: "0.252032520325203", constantSitesP: "0.594399277326107", distinctSitesP: "0.413730803974706", brLenTotal: "0.9848" },
  { geneTranscript: "NM_173855.5", parsimonySitesP: "0.230555555555556", constantSitesP: "0.604166666666667", distinctSitesP: "0.393055555555556", brLenTotal: "0.9484" },
  { geneTranscript: "NM_173853.4", parsimonySitesP: "0.236111111111111", constantSitesP: "0.594444444444444", distinctSitesP: "0.402777777777778", brLenTotal: "0.8998" },
  { geneTranscript: "NM_174889.5", parsimonySitesP: "0.250493096646943", constantSitesP: "0.601577909270217", distinctSitesP: "0.420118343195266", brLenTotal: "0.7712" },
  { geneTranscript: "NM_174897.2", parsimonySitesP: "0.269315673289183", constantSitesP: "0.558498896247241", distinctSitesP: "0.455481972038263", brLenTotal: "1.0705" },
  { geneTranscript: "NM_174899.5", parsimonySitesP: "0.290780141843972", constantSitesP: "0.519503546099291", distinctSitesP: "0.48936170212766", brLenTotal: "1.0137" },
  { geneTranscript: "NM_174909.5", parsimonySitesP: "0.12962962962963", constantSitesP: "0.712962962962963", distinctSitesP: "0.328703703703704", brLenTotal: "0.4522" },
  { geneTranscript: "NM_174910.3", parsimonySitesP: "0.252525252525253", constantSitesP: "0.616161616161616", distinctSitesP: "0.373737373737374", brLenTotal: "0.9751" },
  { geneTranscript: "NM_174931.4", parsimonySitesP: "0.143219264892269", constantSitesP: "0.722433460076046", distinctSitesP: "0.291508238276299", brLenTotal: "0.4829" },
  { geneTranscript: "NM_174945.3", parsimonySitesP: "0.254421768707483", constantSitesP: "0.540136054421769", distinctSitesP: "0.444897959183673", brLenTotal: "1.3468" },
  { geneTranscript: "NM_174977.4", parsimonySitesP: "0.316912972085386", constantSitesP: "0.5", distinctSitesP: "0.512315270935961", brLenTotal: "1.5943" },
  { geneTranscript: "NM_175732.3", parsimonySitesP: "0.252072968490879", constantSitesP: "0.598673300165837", distinctSitesP: "0.402985074626866", brLenTotal: "0.7116" },
  { geneTranscript: "NM_175734.5", parsimonySitesP: "0.417165668662675", constantSitesP: "0.387890884896873", distinctSitesP: "0.600798403193613", brLenTotal: "1.6397" },
  { geneTranscript: "NM_175737.4", parsimonySitesP: "0.269795657726692", constantSitesP: "0.598020434227331", distinctSitesP: "0.378671775223499", brLenTotal: "1.0617" },
  { geneTranscript: "NM_175739.4", parsimonySitesP: "0.37410071942446", constantSitesP: "0.435651478816946", distinctSitesP: "0.577937649880096", brLenTotal: "1.5618" },
  { geneTranscript: "NM_175748.4", parsimonySitesP: "0.170196078431373", constantSitesP: "0.714509803921569", distinctSitesP: "0.276078431372549", brLenTotal: "0.5659" },
  { geneTranscript: "NM_175769.3", parsimonySitesP: "0.300623052959502", constantSitesP: "0.498442367601246", distinctSitesP: "0.461059190031153", brLenTotal: "1.4543" },
  { geneTranscript: "NM_175857.4", parsimonySitesP: "0.306878306878307", constantSitesP: "0.603174603174603", distinctSitesP: "0.486772486772487", brLenTotal: "1.0900" },
  { geneTranscript: "NM_175881.5", parsimonySitesP: "0.344282238442822", constantSitesP: "0.482968369829684", distinctSitesP: "0.535279805352798", brLenTotal: "1.3573" },
  { geneTranscript: "NM_175882.3", parsimonySitesP: "0.373781676413255", constantSitesP: "0.395711500974659", distinctSitesP: "0.538011695906433", brLenTotal: "1.8953" },
  { geneTranscript: "NM_176787.5", parsimonySitesP: "0.174006444683136", constantSitesP: "0.707841031149302", distinctSitesP: "0.277837450769782", brLenTotal: "0.6107" },
  { geneTranscript: "NM_176819.4", parsimonySitesP: "0.22247882986913", constantSitesP: "0.641262509622787", distinctSitesP: "0.325635103926097", brLenTotal: "0.7355" },
  { geneTranscript: "NM_176880.6", parsimonySitesP: "0.261390887290168", constantSitesP: "0.611510791366906", distinctSitesP: "0.422062350119904", brLenTotal: "0.9168" },
  { geneTranscript: "NM_176881.2", parsimonySitesP: "0.357988165680473", constantSitesP: "0.44181459566075", distinctSitesP: "0.559171597633136", brLenTotal: "1.4700" },
  { geneTranscript: "NM_176883.2", parsimonySitesP: "0.452768729641694", constantSitesP: "0.391965255157438", distinctSitesP: "0.612377850162866", brLenTotal: "1.5306" },
  { geneTranscript: "NM_177424.3", parsimonySitesP: "0.118357487922705", constantSitesP: "0.781400966183575", distinctSitesP: "0.199275362318841", brLenTotal: "0.4407" },
  { geneTranscript: "NM_177438.3", parsimonySitesP: "0.109781477627471", constantSitesP: "0.789802289281998", distinctSitesP: "0.14758931668401", brLenTotal: "0.4517" },
  { geneTranscript: "NM_177454.4", parsimonySitesP: "0.161016949152542", constantSitesP: "0.704600484261501", distinctSitesP: "0.259483454398709", brLenTotal: "0.5937" },
  { geneTranscript: "NM_177458.3", parsimonySitesP: "0.422680412371134", constantSitesP: "0.419243986254296", distinctSitesP: "0.625429553264605", brLenTotal: "1.6058" },
  { geneTranscript: "NM_177531.6", parsimonySitesP: "0.209050200329955", constantSitesP: "0.635635163799199", distinctSitesP: "0.323120433655432", brLenTotal: "0.7611" },
  { geneTranscript: "NM_177538.3", parsimonySitesP: "0.177489177489177", constantSitesP: "0.678932178932179", distinctSitesP: "0.316017316017316", brLenTotal: "0.5837" },
  { geneTranscript: "NM_177552.4", parsimonySitesP: "0.301694915254237", constantSitesP: "0.529943502824859", distinctSitesP: "0.484745762711864", brLenTotal: "1.5123" },
  { geneTranscript: "NM_177949.4", parsimonySitesP: "0.265295358649789", constantSitesP: "0.518987341772152", distinctSitesP: "0.415084388185654", brLenTotal: "1.1236" },
  { geneTranscript: "NM_178127.5", parsimonySitesP: "0.171821305841924", constantSitesP: "0.683848797250859", distinctSitesP: "0.304982817869416", brLenTotal: "0.6213" },
  { geneTranscript: "NM_178167.4", parsimonySitesP: "0.282448377581121", constantSitesP: "0.570058997050147", distinctSitesP: "0.394542772861357", brLenTotal: "1.4058" },
  { geneTranscript: "NM_178168.1", parsimonySitesP: "0.487907465825447", constantSitesP: "0.405888538380652", distinctSitesP: "0.557308096740273", brLenTotal: "3.4181" },
  { geneTranscript: "NM_178174.4", parsimonySitesP: "0.287245444801715", constantSitesP: "0.528403001071811", distinctSitesP: "0.467309753483387", brLenTotal: "1.0157" },
  { geneTranscript: "NM_178351.4", parsimonySitesP: "0.389830508474576", constantSitesP: "0.361581920903955", distinctSitesP: "0.73728813559322", brLenTotal: "2.9803" },
  { geneTranscript: "NM_178424.2", parsimonySitesP: "0.297476759628154", constantSitesP: "0.526781761841523", distinctSitesP: "0.433820274457725", brLenTotal: "1.0530" },
  { geneTranscript: "NM_178448.4", parsimonySitesP: "0.302876480541455", constantSitesP: "0.465313028764805", distinctSitesP: "0.500846023688663", brLenTotal: "1.5012" },
  { geneTranscript: "NM_178456.3", parsimonySitesP: "0.294403892944039", constantSitesP: "0.525547445255474", distinctSitesP: "0.493917274939173", brLenTotal: "1.2356" },
  { geneTranscript: "NM_178483.3", parsimonySitesP: "0.288461538461538", constantSitesP: "0.542735042735043", distinctSitesP: "0.465811965811966", brLenTotal: "1.0518" },
  { geneTranscript: "NM_178537.5", parsimonySitesP: "0.280397818415143", constantSitesP: "0.599935835739493", distinctSitesP: "0.364452999679179", brLenTotal: "1.2728" },
  { geneTranscript: "NM_178548.4", parsimonySitesP: "0.199849170437406", constantSitesP: "0.665912518853695", distinctSitesP: "0.315233785822021", brLenTotal: "0.8400" },
  { geneTranscript: "NM_178821.3", parsimonySitesP: "0.188755020080321", constantSitesP: "0.669879518072289", distinctSitesP: "0.331726907630522", brLenTotal: "0.7929" },
  { geneTranscript: "NM_178836.4", parsimonySitesP: "0.337301587301587", constantSitesP: "0.510582010582011", distinctSitesP: "0.507936507936508", brLenTotal: "1.5252" },
  { geneTranscript: "NM_178858.6", parsimonySitesP: "0.191511387163561", constantSitesP: "0.684265010351967", distinctSitesP: "0.303312629399586", brLenTotal: "0.7996" },
  { geneTranscript: "NM_181078.3", parsimonySitesP: "0.338909541511772", constantSitesP: "0.509913258983891", distinctSitesP: "0.469021065675341", brLenTotal: "1.4363" },
  { geneTranscript: "NM_181265.4", parsimonySitesP: "0.186801766692647", constantSitesP: "0.676539360872954", distinctSitesP: "0.278254091971941", brLenTotal: "0.6749" },
  { geneTranscript: "NM_181361.3", parsimonySitesP: "0.185815602836879", constantSitesP: "0.717730496453901", distinctSitesP: "0.279432624113475", brLenTotal: "0.8842" },
  { geneTranscript: "NM_181463.3", parsimonySitesP: "0.364583333333333", constantSitesP: "0.453125", distinctSitesP: "0.557291666666667", brLenTotal: "1.6213" },
  { geneTranscript: "NM_181471.3", parsimonySitesP: "0.225047080979284", constantSitesP: "0.659133709981168", distinctSitesP: "0.370998116760829", brLenTotal: "0.9310" },
  { geneTranscript: "NM_181485.3", parsimonySitesP: "0.295499021526419", constantSitesP: "0.565557729941292", distinctSitesP: "0.4044357469015", brLenTotal: "1.3384" },
  { geneTranscript: "NM_181501.2", parsimonySitesP: "0.173876166242578", constantSitesP: "0.703986429177269", distinctSitesP: "0.263217415889172", brLenTotal: "0.6264" },
  { geneTranscript: "NM_181507.2", parsimonySitesP: "0.195157956894006", constantSitesP: "0.66607617360496", distinctSitesP: "0.291998819013877", brLenTotal: "0.6285" },
  { geneTranscript: "NM_181521.3", parsimonySitesP: "0.150641025641026", constantSitesP: "0.759615384615385", distinctSitesP: "0.243589743589744", brLenTotal: "0.5263" },
  { geneTranscript: "NM_181538.3", parsimonySitesP: "0.296296296296296", constantSitesP: "0.4778972520908", distinctSitesP: "0.538829151732378", brLenTotal: "1.1456" },
  { geneTranscript: "NM_181539.5", parsimonySitesP: "0.319088319088319", constantSitesP: "0.513532763532764", distinctSitesP: "0.454415954415954", brLenTotal: "1.4922" },
  { geneTranscript: "NM_181610.3", parsimonySitesP: "0.468253968253968", constantSitesP: "0.313492063492063", distinctSitesP: "0.738095238095238", brLenTotal: "2.1857" },
  { geneTranscript: "NM_181614.3", parsimonySitesP: "0.433862433862434", constantSitesP: "0.35978835978836", distinctSitesP: "0.708994708994709", brLenTotal: "2.4872" },
  { geneTranscript: "NM_181659.3", parsimonySitesP: "0.182584269662921", constantSitesP: "0.672050561797753", distinctSitesP: "0.265683520599251", brLenTotal: "0.7134" },
  { geneTranscript: "NM_181721.3", parsimonySitesP: "0.213470319634703", constantSitesP: "0.611872146118721", distinctSitesP: "0.367579908675799", brLenTotal: "0.8431" },
  { geneTranscript: "NM_181725.4", parsimonySitesP: "0.21957671957672", constantSitesP: "0.621693121693122", distinctSitesP: "0.35978835978836", brLenTotal: "0.8765" },
  { geneTranscript: "NM_181741.4", parsimonySitesP: "0.143730886850153", constantSitesP: "0.737003058103976", distinctSitesP: "0.275229357798165", brLenTotal: "0.4928" },
  { geneTranscript: "NM_181782.5", parsimonySitesP: "0.184359518754423", constantSitesP: "0.667728237791932", distinctSitesP: "0.299716914366596", brLenTotal: "0.6817" },
  { geneTranscript: "NM_181900.3", parsimonySitesP: "0.283255086071987", constantSitesP: "0.591549295774648", distinctSitesP: "0.444444444444444", brLenTotal: "1.0244" },
  { geneTranscript: "NM_182495.6", parsimonySitesP: "0.374478234943351", constantSitesP: "0.442456768038163", distinctSitesP: "0.546809779367919", brLenTotal: "2.1521" },
  { geneTranscript: "NM_182505.5", parsimonySitesP: "0.199575371549894", constantSitesP: "0.613588110403397", distinctSitesP: "0.399150743099788", brLenTotal: "0.7745" },
  { geneTranscript: "NM_182522.5", parsimonySitesP: "0.219047619047619", constantSitesP: "0.680952380952381", distinctSitesP: "0.35", brLenTotal: "0.9673" },
  { geneTranscript: "NM_182536.3", parsimonySitesP: "0.291666666666667", constantSitesP: "0.541666666666667", distinctSitesP: "0.521739130434783", brLenTotal: "0.9503" },
  { geneTranscript: "NM_182538.5", parsimonySitesP: "0.300130208333333", constantSitesP: "0.5625", distinctSitesP: "0.412109375", brLenTotal: "1.1995" },
  { geneTranscript: "NM_182543.5", parsimonySitesP: "0.208955223880597", constantSitesP: "0.650319829424307", distinctSitesP: "0.352523098791756", brLenTotal: "0.7074" },
  { geneTranscript: "NM_182563.4", parsimonySitesP: "0.312865497076023", constantSitesP: "0.510233918128655", distinctSitesP: "0.497076023391813", brLenTotal: "1.4464" },
  { geneTranscript: "NM_182566.3", parsimonySitesP: "0.422442244224422", constantSitesP: "0.410891089108911", distinctSitesP: "0.595709570957096", brLenTotal: "1.8337" },
  { geneTranscript: "NM_182597.3", parsimonySitesP: "0.203562340966921", constantSitesP: "0.646310432569975", distinctSitesP: "0.402035623409669", brLenTotal: "0.6592" },
  { geneTranscript: "NM_182641.4", parsimonySitesP: "0.14634703196347", constantSitesP: "0.717465753424658", distinctSitesP: "0.224086757990868", brLenTotal: "0.6342" },
  { geneTranscript: "NM_182643.3", parsimonySitesP: "0.203315881326353", constantSitesP: "0.667757417102967", distinctSitesP: "0.299301919720768", brLenTotal: "0.8551" },
  { geneTranscript: "NM_182701.1", parsimonySitesP: "0.349924585218703", constantSitesP: "0.478129713423831", distinctSitesP: "0.505279034690799", brLenTotal: "1.3973" },
  { geneTranscript: "NM_182703.6", parsimonySitesP: "0.25095785440613", constantSitesP: "0.600893997445722", distinctSitesP: "0.375478927203065", brLenTotal: "1.1549" },
  { geneTranscript: "NM_182705.2", parsimonySitesP: "0.272517321016166", constantSitesP: "0.595842956120092", distinctSitesP: "0.4364896073903", brLenTotal: "1.0659" },
  { geneTranscript: "NM_182706.5", parsimonySitesP: "0.281772406847936", constantSitesP: "0.601409869083585", distinctSitesP: "0.349848942598187", brLenTotal: "1.3630" },
  { geneTranscript: "NM_182756.4", parsimonySitesP: "0.146964856230032", constantSitesP: "0.7209797657082", distinctSitesP: "0.313099041533546", brLenTotal: "0.4826" },
  { geneTranscript: "NM_182758.4", parsimonySitesP: "0.207803992740472", constantSitesP: "0.639745916515426", distinctSitesP: "0.35783424077435", brLenTotal: "0.7794" },
  { geneTranscript: "NM_182767.6", parsimonySitesP: "0.16986301369863", constantSitesP: "0.736073059360731", distinctSitesP: "0.245662100456621", brLenTotal: "0.5590" },
  { geneTranscript: "NM_182920.2", parsimonySitesP: "0.209302325581395", constantSitesP: "0.672006890611542", distinctSitesP: "0.289577950043066", brLenTotal: "0.8150" },
  { geneTranscript: "NM_182922.4", parsimonySitesP: "0.194117647058824", constantSitesP: "0.661764705882353", distinctSitesP: "0.318627450980392", brLenTotal: "0.7385" },
  { geneTranscript: "NM_182943.3", parsimonySitesP: "0.145558487247142", constantSitesP: "0.740985048372911", distinctSitesP: "0.240105540897098", brLenTotal: "0.5144" },
  { geneTranscript: "NM_182972.3", parsimonySitesP: "0.151050539466212", constantSitesP: "0.737081203861442", distinctSitesP: "0.248154457694492", brLenTotal: "0.6720" },
  { geneTranscript: "NM_182978.4", parsimonySitesP: "0.204512372634643", constantSitesP: "0.692867540029112", distinctSitesP: "0.299854439592431", brLenTotal: "0.7978" },
  { geneTranscript: "NM_183011.2", parsimonySitesP: "0.129319955406912", constantSitesP: "0.726867335562988", distinctSitesP: "0.265328874024526", brLenTotal: "0.5288" },
  { geneTranscript: "NM_183050.4", parsimonySitesP: "0.210884353741497", constantSitesP: "0.670068027210884", distinctSitesP: "0.301020408163265", brLenTotal: "0.7602" },
  { geneTranscript: "NM_183058.3", parsimonySitesP: "0.259009009009009", constantSitesP: "0.554054054054054", distinctSitesP: "0.497747747747748", brLenTotal: "1.2144" },
  { geneTranscript: "NM_183075.3", parsimonySitesP: "0.264705882352941", constantSitesP: "0.607843137254902", distinctSitesP: "0.368872549019608", brLenTotal: "0.9374" },
  { geneTranscript: "NM_183238.4", parsimonySitesP: "0.517420696827873", constantSitesP: "0.374934997399896", distinctSitesP: "0.602704108164327", brLenTotal: "2.4418" },
  { geneTranscript: "NM_183239.2", parsimonySitesP: "0.251028806584362", constantSitesP: "0.592592592592593", distinctSitesP: "0.410150891632373", brLenTotal: "0.9241" },
  { geneTranscript: "NM_183241.3", parsimonySitesP: "0.313725490196078", constantSitesP: "0.508169934640523", distinctSitesP: "0.475490196078431", brLenTotal: "1.1248" },
  { geneTranscript: "NM_183376.3", parsimonySitesP: "0.196172248803828", constantSitesP: "0.670653907496013", distinctSitesP: "0.317384370015949", brLenTotal: "0.7178" },
  { geneTranscript: "NM_183420.2", parsimonySitesP: "0.179702048417132", constantSitesP: "0.729981378026071", distinctSitesP: "0.277467411545624", brLenTotal: "0.5894" },
  { geneTranscript: "NM_194292.3", parsimonySitesP: "0.157787924911213", constantSitesP: "0.708269913749366", distinctSitesP: "0.297311009639777", brLenTotal: "0.5766" },
  { geneTranscript: "NM_194302.4", parsimonySitesP: "0.288484848484848", constantSitesP: "0.543549783549784", distinctSitesP: "0.395324675324675", brLenTotal: "1.0954" },
  { geneTranscript: "NM_194313.4", parsimonySitesP: "0.269980506822612", constantSitesP: "0.53630604288499", distinctSitesP: "0.41130604288499", brLenTotal: "0.8913" },
  { geneTranscript: "NM_194314.3", parsimonySitesP: "0.152548588192153", constantSitesP: "0.756875687568757", distinctSitesP: "0.217455078841217", brLenTotal: "0.5070" },
  { geneTranscript: "NM_194318.4", parsimonySitesP: "0.217536813922356", constantSitesP: "0.653279785809906", distinctSitesP: "0.346720214190094", brLenTotal: "0.8178" },
  { geneTranscript: "NM_194324.4", parsimonySitesP: "0.177777777777778", constantSitesP: "0.62962962962963", distinctSitesP: "0.407407407407407", brLenTotal: "0.7454" },
  { geneTranscript: "NM_194326.4", parsimonySitesP: "0.267156862745098", constantSitesP: "0.566176470588235", distinctSitesP: "0.426470588235294", brLenTotal: "1.0254" },
  { geneTranscript: "NM_194449.4", parsimonySitesP: "0.207532517957678", constantSitesP: "0.658901184236071", distinctSitesP: "0.309066200737721", brLenTotal: "0.8685" },
  { geneTranscript: "NM_194460.3", parsimonySitesP: "0.207931404072883", constantSitesP: "0.69882100750268", distinctSitesP: "0.305466237942122", brLenTotal: "1.1153" },
  { geneTranscript: "NM_198075.4", parsimonySitesP: "0.343173431734317", constantSitesP: "0.456949569495695", distinctSitesP: "0.56150061500615", brLenTotal: "1.3920" },
  { geneTranscript: "NM_198089.3", parsimonySitesP: "0.482032218091698", constantSitesP: "0.307930607187113", distinctSitesP: "0.672862453531599", brLenTotal: "2.9540" },
  { geneTranscript: "NM_198129.4", parsimonySitesP: "0.250525052505251", constantSitesP: "0.599059905990599", distinctSitesP: "0.342834283428343", brLenTotal: "0.9554" },
  { geneTranscript: "NM_198141.3", parsimonySitesP: "0.218088986141503", constantSitesP: "0.637490882567469", distinctSitesP: "0.334062727935813", brLenTotal: "0.7016" },
  { geneTranscript: "NM_198150.3", parsimonySitesP: "0.230099502487562", constantSitesP: "0.631218905472637", distinctSitesP: "0.374378109452736", brLenTotal: "0.8019" },
  { geneTranscript: "NM_198184.2", parsimonySitesP: "0.253132832080201", constantSitesP: "0.583959899749373", distinctSitesP: "0.428571428571429", brLenTotal: "0.7901" },
  { geneTranscript: "NM_198256.4", parsimonySitesP: "0.202846975088968", constantSitesP: "0.666666666666667", distinctSitesP: "0.336892052194543", brLenTotal: "0.7280" },
  { geneTranscript: "NM_198275.3", parsimonySitesP: "0.218439716312057", constantSitesP: "0.631205673758865", distinctSitesP: "0.365957446808511", brLenTotal: "0.7614" },
  { geneTranscript: "NM_198353.3", parsimonySitesP: "0.25722339675828", constantSitesP: "0.622973925299507", distinctSitesP: "0.367159971811135", brLenTotal: "1.0346" },
  { geneTranscript: "NM_198437.3", parsimonySitesP: "0.245657568238213", constantSitesP: "0.554177005789909", distinctSitesP: "0.421009098428453", brLenTotal: "1.0310" },
  { geneTranscript: "NM_198450.6", parsimonySitesP: "0.257462686567164", constantSitesP: "0.524875621890547", distinctSitesP: "0.490049751243781", brLenTotal: "1.0895" },
  { geneTranscript: "NM_198469.4", parsimonySitesP: "0.27536231884058", constantSitesP: "0.610766045548654", distinctSitesP: "0.453416149068323", brLenTotal: "1.1571" },
  { geneTranscript: "NM_198474.4", parsimonySitesP: "0.217247097844113", constantSitesP: "0.649253731343284", distinctSitesP: "0.324212271973466", brLenTotal: "0.7305" },
  { geneTranscript: "NM_198477.3", parsimonySitesP: "0.252100840336134", constantSitesP: "0.568627450980392", distinctSitesP: "0.417366946778711", brLenTotal: "0.7623" },
  { geneTranscript: "NM_198484.5", parsimonySitesP: "0.299924069855733", constantSitesP: "0.504935459377373", distinctSitesP: "0.470766894457099", brLenTotal: "1.1309" },
  { geneTranscript: "NM_198485.4", parsimonySitesP: "0.227878787878788", constantSitesP: "0.615757575757576", distinctSitesP: "0.396363636363636", brLenTotal: "0.8205" },
  { geneTranscript: "NM_198489.3", parsimonySitesP: "0.176706827309237", constantSitesP: "0.690763052208835", distinctSitesP: "0.305220883534137", brLenTotal: "0.5787" },
  { geneTranscript: "NM_198493.3", parsimonySitesP: "0.24812030075188", constantSitesP: "0.614035087719298", distinctSitesP: "0.378446115288221", brLenTotal: "0.7557" },
  { geneTranscript: "NM_198498.3", parsimonySitesP: "0.291666666666667", constantSitesP: "0.519675925925926", distinctSitesP: "0.46875", brLenTotal: "1.2401" },
  { geneTranscript: "NM_198534.3", parsimonySitesP: "0.370957095709571", constantSitesP: "0.42046204620462", distinctSitesP: "0.574257425742574", brLenTotal: "1.4814" },
  { geneTranscript: "NM_198542.3", parsimonySitesP: "0.457013574660633", constantSitesP: "0.367269984917044", distinctSitesP: "0.644796380090498", brLenTotal: "3.1280" },
  { geneTranscript: "NM_198549.4", parsimonySitesP: "0.177742616033755", constantSitesP: "0.703586497890295", distinctSitesP: "0.29957805907173", brLenTotal: "0.6146" },
  { geneTranscript: "NM_198562.3", parsimonySitesP: "0.278401997503121", constantSitesP: "0.545568039950062", distinctSitesP: "0.478152309612984", brLenTotal: "0.8905" },
  { geneTranscript: "NM_198569.3", parsimonySitesP: "0.206666666666667", constantSitesP: "0.658133333333333", distinctSitesP: "0.309333333333333", brLenTotal: "0.7687" },
  { geneTranscript: "NM_198578.4", parsimonySitesP: "0.191795277667854", constantSitesP: "0.692520775623269", distinctSitesP: "0.276084949215143", brLenTotal: "0.6468" },
  { geneTranscript: "NM_198706.3", parsimonySitesP: "0.264568764568765", constantSitesP: "0.579254079254079", distinctSitesP: "0.414918414918415", brLenTotal: "1.0990" },
  { geneTranscript: "NM_198721.4", parsimonySitesP: "0.155963302752294", constantSitesP: "0.734964322120285", distinctSitesP: "0.261977573904179", brLenTotal: "0.5779" },
  { geneTranscript: "NM_198859.4", parsimonySitesP: "0.218009478672986", constantSitesP: "0.67693522906793", distinctSitesP: "0.272906793048973", brLenTotal: "1.0151" },
  { geneTranscript: "NM_199136.5", parsimonySitesP: "0.182326621923937", constantSitesP: "0.640939597315436", distinctSitesP: "0.335570469798658", brLenTotal: "0.6922" },
  { geneTranscript: "NM_199161.5", parsimonySitesP: "0.330601092896175", constantSitesP: "0.557377049180328", distinctSitesP: "0.478142076502732", brLenTotal: "1.8371" },
  { geneTranscript: "NM_199168.4", parsimonySitesP: "0.176029962546816", constantSitesP: "0.737827715355805", distinctSitesP: "0.284644194756554", brLenTotal: "0.4954" },
  { geneTranscript: "NM_199173.6", parsimonySitesP: "0.27", constantSitesP: "0.556666666666667", distinctSitesP: "0.476666666666667", brLenTotal: "0.9450" },
  { geneTranscript: "NM_199290.4", parsimonySitesP: "0.426356589147287", constantSitesP: "0.285271317829457", distinctSitesP: "0.702325581395349", brLenTotal: "1.8272" },
  { geneTranscript: "NM_199329.3", parsimonySitesP: "0.281059063136456", constantSitesP: "0.553971486761711", distinctSitesP: "0.450780719619823", brLenTotal: "1.1783" },
  { geneTranscript: "NM_199341.4", parsimonySitesP: "0.281159420289855", constantSitesP: "0.564130434782609", distinctSitesP: "0.414130434782609", brLenTotal: "1.1649" },
  { geneTranscript: "NM_199346.3", parsimonySitesP: "0.219638242894057", constantSitesP: "0.648578811369509", distinctSitesP: "0.372093023255814", brLenTotal: "0.6509" },
  { geneTranscript: "NM_199437.2", parsimonySitesP: "0.185697808535179", constantSitesP: "0.72116493656286", distinctSitesP: "0.250576701268743", brLenTotal: "0.8659" },
  { geneTranscript: "NM_199461.4", parsimonySitesP: "0.207762557077626", constantSitesP: "0.631278538812785", distinctSitesP: "0.377853881278539", brLenTotal: "0.8415" },
  { geneTranscript: "NM_201384.3", parsimonySitesP: "0.246609486108057", constantSitesP: "0.634997434205703", distinctSitesP: "0.273953522469027", brLenTotal: "1.2812" },
  { geneTranscript: "NM_201412.3", parsimonySitesP: "0.158131176999102", constantSitesP: "0.750224618149146", distinctSitesP: "0.230907457322552", brLenTotal: "0.5484" },
  { geneTranscript: "NM_201525.4", parsimonySitesP: "0.28190198932557", constantSitesP: "0.577874818049491", distinctSitesP: "0.396409509946628", brLenTotal: "1.1745" },
  { geneTranscript: "NM_201548.5", parsimonySitesP: "0.208020050125313", constantSitesP: "0.634711779448622", distinctSitesP: "0.37781954887218", brLenTotal: "0.6972" },
  { geneTranscript: "NM_203288.2", parsimonySitesP: "0.187028657616893", constantSitesP: "0.681749622926094", distinctSitesP: "0.343891402714932", brLenTotal: "0.7036" },
  { geneTranscript: "NM_203293.3", parsimonySitesP: "0.249184605348989", constantSitesP: "0.609262883235486", distinctSitesP: "0.371819960861057", brLenTotal: "1.1063" },
  { geneTranscript: "NM_203298.3", parsimonySitesP: "0.23728813559322", constantSitesP: "0.629943502824859", distinctSitesP: "0.395480225988701", brLenTotal: "0.6924" },
  { geneTranscript: "NM_203387.3", parsimonySitesP: "0.383947939262473", constantSitesP: "0.434562545191612", distinctSitesP: "0.560375994215474", brLenTotal: "1.8059" },
  { geneTranscript: "NM_203394.3", parsimonySitesP: "0.227954628613246", constantSitesP: "0.614343212586901", distinctSitesP: "0.375045737285035", brLenTotal: "0.8421" },
  { geneTranscript: "NM_203395.3", parsimonySitesP: "0.260668973471742", constantSitesP: "0.594002306805075", distinctSitesP: "0.449826989619377", brLenTotal: "0.9619" },
  { geneTranscript: "NM_203416.4", parsimonySitesP: "0.276538804638715", constantSitesP: "0.557537912578055", distinctSitesP: "0.383288730300327", brLenTotal: "1.2418" },
  { geneTranscript: "NM_203437.4", parsimonySitesP: "0.142450142450142", constantSitesP: "0.71011396011396", distinctSitesP: "0.249643874643875", brLenTotal: "0.4996" },
  { geneTranscript: "NM_203458.6", parsimonySitesP: "0.206214689265537", constantSitesP: "0.680790960451977", distinctSitesP: "0.334745762711864", brLenTotal: "0.6248" },
  { geneTranscript: "NM_203468.3", parsimonySitesP: "0.284175084175084", constantSitesP: "0.577104377104377", distinctSitesP: "0.386531986531987", brLenTotal: "1.2689" },
  { geneTranscript: "NM_203495.4", parsimonySitesP: "0.156862745098039", constantSitesP: "0.713725490196078", distinctSitesP: "0.317647058823529", brLenTotal: "0.6295" },
  { geneTranscript: "NM_205834.4", parsimonySitesP: "0.281752634498059", constantSitesP: "0.591236827509706", distinctSitesP: "0.398779811425402", brLenTotal: "1.0850" },
  { geneTranscript: "NM_206808.5", parsimonySitesP: "0.175490196078431", constantSitesP: "0.68921568627451", distinctSitesP: "0.332352941176471", brLenTotal: "0.6943" },
  { geneTranscript: "NM_206831.3", parsimonySitesP: "0.138211382113821", constantSitesP: "0.715447154471545", distinctSitesP: "0.292682926829268", brLenTotal: "0.4851" },
  { geneTranscript: "NM_206933.4", parsimonySitesP: "0.236639753940792", constantSitesP: "0.606561578879918", distinctSitesP: "0.31917211328976", brLenTotal: "0.8686" },
  { geneTranscript: "NM_206943.4", parsimonySitesP: "0.172961456517529", constantSitesP: "0.718574472206082", distinctSitesP: "0.236103040867713", brLenTotal: "0.6598" },
  { geneTranscript: "NM_206965.2", parsimonySitesP: "0.294516327788047", constantSitesP: "0.579174368453481", distinctSitesP: "0.410351201478743", brLenTotal: "1.3406" },
  { geneTranscript: "NM_206967.3", parsimonySitesP: "0.201754385964912", constantSitesP: "0.62280701754386", distinctSitesP: "0.425438596491228", brLenTotal: "1.0572" },
  { geneTranscript: "NM_207015.3", parsimonySitesP: "0.240670859538784", constantSitesP: "0.594968553459119", distinctSitesP: "0.409643605870021", brLenTotal: "0.9038" },
  { geneTranscript: "NM_207113.3", parsimonySitesP: "0.176113360323887", constantSitesP: "0.708502024291498", distinctSitesP: "0.288798920377868", brLenTotal: "0.7138" },
  { geneTranscript: "NM_207186.2", parsimonySitesP: "0.468783068783069", constantSitesP: "0.374603174603175", distinctSitesP: "0.571428571428571", brLenTotal: "1.9748" },
  { geneTranscript: "NM_207327.5", parsimonySitesP: "0.2710027100271", constantSitesP: "0.609756097560976", distinctSitesP: "0.417344173441734", brLenTotal: "0.9432" },
  { geneTranscript: "NM_207338.4", parsimonySitesP: "0.212815990593768", constantSitesP: "0.64021164021164", distinctSitesP: "0.320399764844209", brLenTotal: "1.0726" },
  { geneTranscript: "NM_207351.5", parsimonySitesP: "0.326877336051648", constantSitesP: "0.496772001359157", distinctSitesP: "0.44682296975875", brLenTotal: "1.1843" },
  { geneTranscript: "NM_207352.4", parsimonySitesP: "0.286984126984127", constantSitesP: "0.573333333333333", distinctSitesP: "0.446349206349206", brLenTotal: "1.1164" },
  { geneTranscript: "NM_207359.3", parsimonySitesP: "0.165706973768394", constantSitesP: "0.723608445297505", distinctSitesP: "0.261676263595649", brLenTotal: "0.6065" },
  { geneTranscript: "NM_207360.3", parsimonySitesP: "0.341555977229602", constantSitesP: "0.504111321948134", distinctSitesP: "0.494623655913978", brLenTotal: "1.4244" },
  { geneTranscript: "NM_207370.4", parsimonySitesP: "0.257799671592775", constantSitesP: "0.584564860426929", distinctSitesP: "0.375478927203065", brLenTotal: "1.1927" },
  { geneTranscript: "NM_207374.3", parsimonySitesP: "0.319125683060109", constantSitesP: "0.46448087431694", distinctSitesP: "0.512568306010929", brLenTotal: "1.1569" },
  { geneTranscript: "NM_207377.3", parsimonySitesP: "0.256578947368421", constantSitesP: "0.5", distinctSitesP: "0.528508771929825", brLenTotal: "0.8917" },
  { geneTranscript: "NM_207391.3", parsimonySitesP: "0.252482269503546", constantSitesP: "0.578723404255319", distinctSitesP: "0.377304964539007", brLenTotal: "0.9649" },
  { geneTranscript: "NM_207396.3", parsimonySitesP: "0.257097791798107", constantSitesP: "0.569400630914826", distinctSitesP: "0.388538380651945", brLenTotal: "1.1154" },
  { geneTranscript: "NM_207406.4", parsimonySitesP: "0.220973782771536", constantSitesP: "0.679775280898876", distinctSitesP: "0.327715355805243", brLenTotal: "0.9760" },
  { geneTranscript: "NM_207419.3", parsimonySitesP: "0.395502645502646", constantSitesP: "0.420634920634921", distinctSitesP: "0.55952380952381", brLenTotal: "1.9427" },
  { geneTranscript: "NM_207420.3", parsimonySitesP: "0.305989583333333", constantSitesP: "0.548177083333333", distinctSitesP: "0.440755208333333", brLenTotal: "1.4785" },
  { geneTranscript: "NM_207426.3", parsimonySitesP: "0.411949685534591", constantSitesP: "0.39727463312369", distinctSitesP: "0.615303983228512", brLenTotal: "2.0098" },
  { geneTranscript: "NM_207644.3", parsimonySitesP: "0.313227513227513", constantSitesP: "0.427513227513228", distinctSitesP: "0.57989417989418", brLenTotal: "1.4671" },
  { geneTranscript: "NM_213589.3", parsimonySitesP: "0.1808", constantSitesP: "0.715733333333333", distinctSitesP: "0.260533333333333", brLenTotal: "0.6619" },
  { geneTranscript: "NM_212554.4", parsimonySitesP: "0.258877434135166", constantSitesP: "0.562428407789233", distinctSitesP: "0.432989690721649", brLenTotal: "1.9829" },
  { geneTranscript: "NM_213595.4", parsimonySitesP: "0.199600798403194", constantSitesP: "0.660678642714571", distinctSitesP: "0.341317365269461", brLenTotal: "0.6959" },
  { geneTranscript: "NM_213601.3", parsimonySitesP: "0.163076923076923", constantSitesP: "0.701538461538462", distinctSitesP: "0.29025641025641", brLenTotal: "0.6112" },
  { geneTranscript: "NM_213602.3", parsimonySitesP: "0.294715447154472", constantSitesP: "0.552845528455285", distinctSitesP: "0.414634146341463", brLenTotal: "1.3160" },
  { geneTranscript: "NM_213604.3", parsimonySitesP: "0.321302193913659", constantSitesP: "0.543524416135881", distinctSitesP: "0.443029016277424", brLenTotal: "1.4076" },
  { geneTranscript: "NM_213609.4", parsimonySitesP: "0.160401002506266", constantSitesP: "0.754385964912281", distinctSitesP: "0.25062656641604", brLenTotal: "0.6581" },
  { geneTranscript: "NM_213656.4", parsimonySitesP: "0.348947725729803", constantSitesP: "0.473183978275628", distinctSitesP: "0.524100475220638", brLenTotal: "1.4150" },
  { geneTranscript: "NM_003482.4", parsimonySitesP: "0.188971163686714", constantSitesP: "0.690446089940401", distinctSitesP: "0.199686954427789", brLenTotal: "0.7820" },
  { geneTranscript: "NM_182961.4", parsimonySitesP: "0.20749497934902", constantSitesP: "0.661437611306885", distinctSitesP: "0.236595809177371", brLenTotal: "0.7873" }
];

export default SummaryGraphData;
