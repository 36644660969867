const baseUrl = "https://preview-api.codexome.com"; 
//const baseUrl = "http://localhost:8080";

export async function handleApiCall(gene: string, isNT: boolean, useReference: boolean, badGene: boolean) {

  const val = {
    data: undefined,
    success: true,
    errorMessage: ''
  }

  try {
    const url = badGene ? `${baseUrl}/request` : `${baseUrl}/gene`;

    const body = badGene ? `{"name": "${gene}" }` : `{"name": "${gene}", "sequence_character":"${isNT ? "nt" : "aa"}", "show_characters":"${useReference ? "dot" : "full"}"}`;
    const opts = { body: body, method: "POST" };

    const resp = await fetch(url, opts);
    const data = await resp.json();

    if(resp.ok === true) {
      val.data = data;
    }
    else {
      val.success = false;
      val.errorMessage = `Status: ${resp.status}; Status Text: ${resp.statusText}; Message Body (if any): ${data ? JSON.stringify(data) : '<none>'}`;
    }
  }
  catch(e) {
    val.success = false;
    
    //@ts-ignore
    val.errorMessage = e.toString();
  }

  return val;
}

export async function handleRegistrationApiCall(email: string, first: string, last: string, position: string, organization: string, interests: string) {

  const val = {
    success: true,
    errorMessage: ''
  }

    try {
      const url = `${baseUrl}/register`;
  
      const body = `{ "email": "${email}", "first":"${first}", "last":"${last}", "position":"${position}", "organization":"${organization}", "interests":"${interests}"}`;
      const opts = { body: body, method: "POST" };
  
      console.log("Making subscription request");
      const resp = await fetch(url, opts);
      const data = await resp.json();
  
      if(resp.ok === true) {
        console.log("Subscription request succeeded");
        console.log(data);
        val.success = true;
      }
      else {
        console.log("Subscription request failed");
        val.success = false;
        val.errorMessage = `Status: ${resp.status}; Status Text: ${resp.statusText}; Message Body (if any): ${data ? JSON.stringify(data) : '<none>'}`;
      }
    }
    catch(e) {
      val.success = false;
      
      //@ts-ignore
      val.errorMessage = e.toString();
    }
  
    return val;
}

export async function handleValidateEmailApiCall(email: string, validationID: string) {

  const val = {
    success: true,
    errorMessage: ''
  }

    try {
      const url = `${baseUrl}/validate`;
  
      const body = `{ "email": "${email}", "id":"${validationID}" }`;
      const opts = { body: body, method: "POST" };
  
      const resp = await fetch(url, opts);
      const data = await resp.json();
  
      if(resp.ok === true) {
        val.success = true;
      }
      else {
        val.success = false;
        val.errorMessage = `Status: ${resp.status}; Status Text: ${resp.statusText}; Message Body (if any): ${data ? JSON.stringify(data) : '<none>'}`;
      }
    }
    catch(e) {
      val.success = false;
      
      //@ts-ignore
      val.errorMessage = e.toString();
    }
  
    return val;
}


export async function handleAuthApiCall(password: string) {

  const val = {
    authResult: false,
    success: true,
    errorMessage: ''
  }

    try {
      const url = `${baseUrl}/auth`;
  
      const body = `{ "pwd": "${password}" }`;
      const opts = { body: body, method: "POST" };
  
      const resp = await fetch(url, opts);
      const data = await resp.json();
  
      if(resp.ok === true) {
        val.authResult = data.result ? data.result : false;
        val.success = true;
      }
      else {
        val.success = false;
        val.errorMessage = `Status: ${resp.status}; Status Text: ${resp.statusText}; Message Body (if any): ${data ? JSON.stringify(data) : '<none>'}`;
      }
    }
    catch(e) {
      val.success = false;
      
      //@ts-ignore
      val.errorMessage = e.toString();
    }
  
    return val;
}


